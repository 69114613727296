import React from "react";
import Component from "../Prices";
import bg4 from "assests/images/homesection7/newz.png";
import pub4 from "assests/images/homesection7/newz1.png";

const SlideStep4 = ({ articlePrices }) => {
    const url = "https://www.newznew.com/whats-new-with-newznew/";

    const price = articlePrices[url];
    return (
        <div className="flex w-full items-center justify-between mb-4 min-w-165 max-w-169 lg:min-w-270 lg:max-w-270 overflow-hidden rounded-xl">
            <a
                href="https://www.newznew.com/whats-new-with-newznew/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col bg-white max-w-270 items-center justify-start text-center pb-16 h-72"
                style={{ borderRadius: "18px" }}
            >
                <div
                    className="mb-3 min-h-147 w-165 lg:w-270 flex items-end justify-end relative"
                    style={{
                        backgroundImage: `url(${bg4})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                    ></div>{" "}
                    <div
                        className="w-full h-1/2 absolute"
                        style={{
                            background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    ></div>
                    {price && <Component price={price} url={url} idKey={url} />}
                </div>
                <h5 className=" text-black text-sm font-bold mb-12 lg:mb-14">
                    What’s New with NewZNew?
                </h5>
                <img src={pub4} alt="img1" className="lg:w-36 w-32 mb-3" />
            </a>
        </div>
    );
};

export default SlideStep4;
