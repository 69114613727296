import PredictionOverTime from "./Prediction";
import CategoryShift from "./categoryShift";

const FourthRow = ({ engagementData, difference }) => {
    return (
        <div className="hidden lg:flex flex-col lg:flex-row border-b border-b-[#383838]">
            <div className="flex-[2] flex flex-col border-r border-r-[#383838]">
                <div className="px-2 lg:px-11 flex-1 flex py-8 border-t border-t-[#383838]">
                    <CategoryShift difference={difference} />
                </div>
            </div>
            <div className="flex-[3] lg:flex ">
                <PredictionOverTime engagementData={engagementData} />
            </div>
        </div>
    );
};
export default FourthRow;
