import React from "react";

import bg1 from "assests/images/homesection7/newstep2bg.png";
import pub from "assests/images/homesection7/newstep2p.png";
import Component from "../Prices";

const SlideStep2 = ({ articlePrices }) => {
    const url =
        "https://ibgnews.com/2022/09/29/see-the-latest-11-deal-on-ibg-news-find-the-true-value-of-the-content-you-consume-on-ibg-news/";

    const price = articlePrices[url];

    return (
        <div className="flex w-full items-center justify-between mb-4 min-w-165 max-w-169 lg:min-w-270 lg:max-w-270 overflow-hidden rounded-xl">
            <a
                href="https://ibgnews.com/2022/09/29/see-the-latest-11-deal-on-ibg-news-find-the-true-value-of-the-content-you-consume-on-ibg-news/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col bg-white max-w-270 items-center justify-start text-center
                pb-16 h-72"
                style={{ borderRadius: "17px" }}
            >
                <div
                    className="mb-3 min-h-147 w-165 lg:w-270 flex items-end justify-end relative"
                    style={{
                        backgroundImage: `url(${bg1})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                    ></div>{" "}
                    <div
                        className="w-full h-1/2 absolute"
                        style={{
                            background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    ></div>
                    {price && <Component price={price} url={url} idKey={url} />}
                </div>

                <h5 className="text-black text-xs lg:px-3 lg:text-sm font-bold mb-2 lg:mb-4">
                    See the Latest 1+1 Deal on IBG News – Find the true value of the content you
                    consume on IBG News
                </h5>
                <img src={pub} alt="img1" className="lg:w-32 w-28 mb-3" />
            </a>
        </div>
    );
};

export default SlideStep2;
