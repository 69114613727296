import React, { useEffect, useState, useRef } from "react";

// import bg3 from "assests/images/home section5/feature3.png";
// import bg1 from "assests/images/home section5/feature1.jpg";
// import bg2 from "assests/images/home section5/2.png";
// import bg4 from "assests/images/home section5/3.png";
// import bg5 from "assests/images/home section5/feature5.jpg";

import video2 from "assests/images/home section5/feature2.mp4";
import video1 from "assests/images/home section5/feature1.mp4";
import video3 from "assests/images/home section5/feature3.mp4";
import video4 from "assests/images/home section5/feature4.mp4";
import video5 from "assests/images/home section5/feature5.mp4";

import PriceTag from "components/shared/icons/PriceTag";
import HomeFeature5 from "components/shared/icons/HomeFeature5";
import HomeFeature4 from "components/shared/icons/HomeFeature4";
import HomeFeature3 from "components/shared/icons/HomeFeature3";
import HomeFeature2 from "components/shared/icons/HomeFeature2";

import "./styles.scss";

const Features = () => {
    const [active, setActive] = useState(3);

    const delay = 3500;

    const setClass = (_step) => {
        let _class = "cursor-pointer text-gray-500";
        if (_step === active) {
            _class = "cursor-pointer text-blue2";
        }
        return _class;
    };

    const setStyles = () => {
        if (window.innerWidth < 768) {
            if (active >= 3) {
                return { transform: `translateX(-80px)` };
            } else {
                return { transform: `translateX(80px)` };
            }
        } else {
            return {};
        }
    };

    const videoRef = useRef();
    const videoRef2 = useRef();
    const videoRef3 = useRef();
    const videoRef4 = useRef();
    const videoRef5 = useRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            setActive((prevState) => prevState + 1);
            if (active === 5) {
                setActive(1);
            }
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [active]);

    return (
        <div
            className="flex w-full items-center justify-start lg:justify-center pt-5 lg:pt-0 flex-col overflow-hidden"
            style={{ minHeight: "650px" }}
        >
            {/* <h1 className="text-4xl font-bold mb-14 text-blue2 text-center w-full">Top Features</h1> */}
            <div className="options lg:w-full" style={setStyles()}>
                <div
                    className={active === 1 ? "option active" : "option"}
                    onClick={() => setActive(1)}
                    style={{
                        borderRadius: "30px",
                        overflow: "hidden !important",
                    }}
                >
                    <div
                        className="w-full h-full absolute z-10"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.3)",
                            borderRadius: "30px",
                        }}
                    ></div>
                    <video
                        ref={videoRef2}
                        controls={false}
                        autoPlay={true}
                        src={video1}
                        loop
                        muted
                        style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "30px",
                            zIndex: 0,
                        }}
                    />
                    {/* <div className="shadow"></div> */}
                    <div className="label z-20">
                        <div className="icon">
                            <PriceTag />
                        </div>
                        <div className="info">
                            <div className="main text-3xl font-semibold leading-8">
                                Reflect the real-time market value of your digital content.
                            </div>
                            {/* <div className="sub">Omuke trughte a otufta</div> */}
                        </div>
                    </div>
                </div>
                <div
                    className={active === 2 ? "option active" : "option"}
                    onClick={() => setActive(2)}
                    style={
                        {
                            // backgroundImage: `url(${bg2})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundAttachment: "scroll",
                            // backgroundSize: "cover",
                        }
                    }
                >
                    <div
                        className="w-full h-full absolute z-10"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.45)",
                        }}
                    ></div>
                    <video
                        ref={videoRef}
                        controls={false}
                        autoPlay={true}
                        src={video2}
                        loop
                        muted
                        style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "30px",
                            zIndex: 0,
                        }}
                    />
                    {/* <div className="shadow"></div> */}
                    <div className="label z-20">
                        <div className="icon">
                            <HomeFeature2 />
                        </div>
                        <div className="info">
                            <div className="main text-3xl font-semibold leading-8">
                                Fine tune your website by analysing user behaviour data.
                            </div>
                            {/* <div className="sub">Omuke trughte a otufta</div> */}
                        </div>
                    </div>
                </div>
                <div
                    className={active === 3 ? "option active" : "option"}
                    onClick={() => setActive(3)}
                    style={
                        {
                            // backgroundImage: `url(${bg3})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundAttachment: "scroll",
                            // backgroundSize: "cover",
                        }
                    }
                >
                    <div
                        className="w-full h-full absolute z-10"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                    ></div>
                    <video
                        ref={videoRef3}
                        controls={false}
                        autoPlay={true}
                        src={video3}
                        loop
                        muted
                        style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "30px",
                            zIndex: 0,
                        }}
                    />
                    {/* <div className="shadow"></div> */}
                    <div className="label z-20">
                        <div className="icon">
                            <HomeFeature3 />
                        </div>
                        <div className="info lg:top-24">
                            <div className="main text-3xl font-semibold leading-8">
                                Simplify your writing process with our guided content creation tool.
                            </div>
                            {/* <div className="sub">Omuke trughte a otufta</div> */}
                        </div>
                    </div>
                </div>
                <div
                    className={active === 4 ? "option active" : "option"}
                    onClick={() => setActive(4)}
                    style={
                        {
                            // backgroundImage: `url(${bg1})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundAttachment: "scroll",
                            // backgroundSize: "cover",
                        }
                    }
                >
                    <div
                        className="w-full h-full absolute z-10"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                    ></div>
                    <video
                        ref={videoRef4}
                        controls={false}
                        autoPlay={true}
                        src={video4}
                        loop
                        muted
                        style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "30px",
                            zIndex: 0,
                        }}
                    />
                    {/* <div className="shadow"></div> */}
                    <div className="label z-20">
                        <div className="icon">
                            <HomeFeature4 />
                        </div>
                        <div className="info">
                            <div className="main text-3xl font-semibold leading-8">
                                Follow trends closely with AI-based insights.
                                <span className="text-transparent">asdsa asd</span>
                            </div>

                            {/* <div className="sub">Omuke trughte a otufta</div> */}
                        </div>
                    </div>
                </div>
                <div
                    className={active === 5 ? "option active" : "option"}
                    onClick={() => setActive(5)}
                    style={
                        {
                            // backgroundImage: `url(${bg5})`,
                            // backgroundRepeat: "no-repeat",
                            // backgroundAttachment: "scroll",
                            // backgroundSize: "cover",
                        }
                    }
                >
                    <div
                        className="w-full h-full absolute z-10"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.45)",
                        }}
                    ></div>
                    <video
                        ref={videoRef5}
                        controls={false}
                        autoPlay={true}
                        src={video5}
                        loop
                        muted
                        style={{
                            position: "absolute",
                            top: "0px",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "30px",
                            zIndex: 0,
                        }}
                    />
                    {/* <div className="shadow"></div> */}
                    <div className="label z-20">
                        <div className="icon">
                            <HomeFeature5 />
                        </div>
                        <div className="info">
                            <div className="main text-3xl font-semibold leading-8">
                                Monetise content to maximise website revenue.
                                <span className="text-transparent">asdsa asd asd</span>
                            </div>
                            {/* <div className="sub">Omuke trughte a otufta</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="lg:hidden flex items-center justify-between w-full max-w-150 list-none text-7xl leading-3 font-extrabold my-10">
                <li onClick={() => setActive(1)} className={setClass(1)}>
                    .
                </li>
                <li onClick={() => setActive(2)} className={setClass(2)}>
                    .
                </li>
                <li onClick={() => setActive(3)} className={setClass(3)}>
                    .
                </li>
                <li onClick={() => setActive(4)} className={setClass(4)}>
                    .
                </li>
                <li onClick={() => setActive(5)} className={setClass(5)}>
                    .
                </li>
            </div>
        </div>
    );
};

export default Features;
