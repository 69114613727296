import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import Routes from "components/Router";

const App = () => {
    const [theme] = useState(localStorage.theme);

    const version = "- 1.0.2";

    console.log("version is ", version);

    useEffect(() => {
        // On page load or when changing themes, best to add inline in `head` to avoid FOUC
        if (
            localStorage.theme === "dark" ||
            (!("theme" in localStorage) &&
                window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
            document.documentElement.classList.add("dark");
            let root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
            root.setAttribute("class", "dark");
        } else {
            let root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
            root.classList.remove("dark");
        }
    }, [theme]);

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    );
};

export default App;
