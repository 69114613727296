import { useState } from "react";
import SimilarArticles from "./similar";
import ContentPerformance from "./contentPerformance";
import UserEngagement from "./userEngagement";
import Close from "components/shared/icons/Close";
import UserData from "./userData";

const MoreDetails = ({ options, selected, setSelected, selectedArticle, setSelectedSimilar }) => {
    return (
        <div
            className={
                selected !== ""
                    ? "flex-[5_10rem] flex flex-col rounded w-11/12 "
                    : "flex-[1] rounded w-11/12"
            }
        >
            <div className="flex items-center justify-between">
                <div className="flex item-center gap-4">
                    {options.map((item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                if (selected === item) {
                                    setSelected("");
                                } else {
                                    setSelected(item);
                                }
                            }}
                            className="p-2 rounded font-medium text-white"
                            style={
                                selected === item
                                    ? { backgroundColor: "#0485ff", border: "solid 1px #0485ff" }
                                    : {
                                          backgroundColor: "transparent",
                                          border: "solid 1px #0485ff",
                                      }
                            }
                        >
                            {item}
                        </button>
                    ))}
                </div>
                <button
                    onClick={() => {
                        setSelected("");
                    }}
                    className="rounded text-[#979797] text-3xl"
                >
                    <Close />
                </button>
            </div>
            <div className="flex-[1_10rem] pr-4 overflow-auto mt-4">
                {selected === "Similar Articles" && (
                    <SimilarArticles
                        selectedArticle={selectedArticle}
                        setSelectedSimilar={setSelectedSimilar}
                    />
                )}
                {selected === "Content Performance" && (
                    <ContentPerformance selectedArticle={selectedArticle} />
                )}
                {selected === "User Engagement" && (
                    <UserEngagement selectedArticle={selectedArticle} />
                )}{" "}
                {selected === "Content Metrics" && <UserData selectedArticle={selectedArticle} />}
            </div>
        </div>
    );
};
export default MoreDetails;
