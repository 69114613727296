import { devServer } from "../utils";

export const verifyAuthCode = async (code) => {
    try {
        const resp = await fetch(`${devServer}/auth/sso/callback?code=${code}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const res = await resp.json();
        return res;
    } catch (err) {
        console.log(err);
        return { ok: false };
    }
};

export const getSSODetails = async (code) => {
    try {
        const resp = await fetch(`${devServer}/auth/sso/get-details`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const res = await resp.json();
        return res;
    } catch (err) {
        console.log(err);
        return { ok: false };
    }
};
