import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";

const CalendarComponent = ({ startDate, endDate, setStartDate, setEndDate, setShowCalendar }) => {
    const [value, setValue] = useState([
        new Date(new Date(startDate).setHours(0, 0, 0, 0)),
        new Date(new Date(endDate).setHours(23, 59, 59, 999)),
    ]);
    const [error, setError] = useState("");

    useEffect(() => {
        const difference = value[1] - value[0];
        const dayDiff = Math.ceil(difference / (1000 * 60 * 60 * 24));
        if (dayDiff > 30) {
            setError("Can't select more than 30 days");
        }
    }, [value]);
    return (
        <div className="absolute top-10 w-full min-w-[18rem] -right-4 mt-4 z-[60] rounded-lg shadow-lg shadow-[rgba(80,80,80,0.2)] bg-inherit">
            <Calendar
                minDate={new Date(2022, 8, 15)}
                selectRange={true}
                onChange={(e) => {
                    setError("");
                    setValue(e);
                }}
                maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                maxRange={30}
                value={value}
                tileClassName="single-tile"
                className={
                    localStorage.theme === "light"
                        ? "monitor-calendar react-calendar pl-2"
                        : "monitor-calendar react-calendar calender-dark pl-2"
                }
            />
            {error && (
                <div className="font-medium text-sm text-center -mb-5 text-[#f04038]">{error}</div>
            )}

            <div className="text-center pt-8 pb-4">
                <button
                    disabled={value.length !== 2 || error}
                    onClick={() => {
                        setStartDate(value[0].getTime());
                        setEndDate(value[1].getTime());
                        setShowCalendar(false);
                    }}
                    className="rounded px-3 text-sm py-1 bg-[#141414] text-white font-semibold"
                >
                    Apply
                </button>
            </div>
        </div>
    );
};
export default CalendarComponent;
