import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import AOS from "aos";
import { Routes, Route, useLocation } from "react-router-dom";

import MobileModal from "components/shared/MobileModal";
// components
import OverviewPage from "components/Overview/index";
import LoginPage from "./Login";
import ResetPage from "./Reset";
import AIPage from "./AI";
import RegisterForm from "./Register";
import Conversion from "./Conversion";
import HomePage from "./Home";
import Terms from "./Terms";
import Privacy from "./Terms/Privacy";
import Disclaimers from "./Terms/Disclaimers";

import "aos/dist/aos.css";
import jwtDecode from "jwt-decode";

import MonitorMetrics from "./monitorMain/metrics";
import Overview from "./monitorMain/overview";
import RedirectSSO from "./Redirect";
import { getSSODetails } from "./shared/API/sso";

let PageRoutes = () => {
    const [modal, setModal] = useState(false);

    let mobModal = localStorage.getItem("mobileModal");

    // let accessToken = localStorage.getItem("accessToken");
    // let user;
    // if (accessToken) {
    //     user = jwtDecode(accessToken);
    // }

    mixpanel.init("0e557812af89b112a7de924a6b90d26d", { debug: true });

    const location = useLocation();

    const handleClose = () => {
        setModal(false);
        localStorage.setItem("mobileModal", "hide");
    };

    const getCallbackURL = async () => {
        const data = await getSSODetails();
        if (data.ok) {
            localStorage.setItem("redirect_uri", data.data.callbackUrl);
        }
    };

    useEffect(() => {
        getCallbackURL();
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        const innerWidth = window.innerWidth;
        if (innerWidth < 1020) {
            if (
                mobModal === "hide" ||
                location.pathname === "/" ||
                location.pathname === "/register" ||
                location.pathname === "/login"
            )
                return;
            setModal(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Routes>
                <Route path="/" element={<HomePage />} />
                {/* <Route path="/overview" element={<OverviewPage />} /> */}
                {/* <Route path="/ai" element={<AIPage />} /> */}
                {/* {user?.meta?.betaAccess === 1 && (
                    <Route path="/paywindow" element={<PaywindowSection />} />
                )} */}
                {/* {user?.meta?.betaAccess === 1 && (
                    <Route path="/conversions" element={<Conversion />} />
                )} */}
                <Route path="/login" element={<LoginPage />} />
                <Route path="/reset-password" element={<ResetPage />} />
                <Route path="/register" element={<RegisterForm />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/disclaimer" element={<Disclaimers />} />
                <Route path="/privacy" element={<Privacy />} />
                {/* <Route path="/monitor/overview" element={<MonitorOverview />} /> */}
                <Route path="/monitor2/site-analysis" element={<MonitorMetrics />} />
                <Route path="/monitor2/content-analysis" element={<Overview />} />
                <Route path="/redirect" element={<RedirectSSO />} />
                {/* <Route path="/monitor/profile" element={<ProfilePage />} /> */}
            </Routes>
            {modal && <MobileModal closeModal={() => handleClose()} />}
        </>
    );
};

export default PageRoutes;
