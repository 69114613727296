import React, { useRef, useEffect } from "react";

import { countriesWithCodes } from "./countryCodes";

const CountryCodeModal = ({ closeModal, setCountryCode, setPhoneLimit }) => {
    const modalRef = useRef();

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="absolute top-10 left-0 w-full h-full overflow-y-auto z-50 rounded-md"
            style={{
                backgroundColor: "#193148",
                color: "#fff",
                width: "280px",
                height: "300px",
            }}
            ref={modalRef}
        >
            {countriesWithCodes.map((code, index) => (
                <div
                    key={index}
                    onClick={() => {
                        setCountryCode(code.code);
                        closeModal();
                        setPhoneLimit(code.numberOfDigits);
                    }}
                    className="hover:text-white hover:bg-blue1 flex items-center w-full z-50 py-2 pl-4 pr-4"
                >
                    {code.name} <span className="ml-1">(+{code.code})</span>
                </div>
            ))}
        </div>
    );
};

export default CountryCodeModal;
