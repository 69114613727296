import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const MainHeader = () => {
    const options = [
        "CONTENT ANALYSIS",
        "SITE ANALYSIS",
        //  "TRENDS", "CUSTOMIZATIONS"
    ];
    const [selectedOption, setSelectedOption] = useState("CONTENT ANALYSIS");
    const location = useLocation();

    let user;
    const token = localStorage?.getItem("idToken");
    if (token) {
        user = jwtDecode(token);
    }
    const history = useNavigate();

    useEffect(() => {
        setSelectedOption(() => {
            return location.pathname.replace("/monitor2/", "").replace("-", " ").toUpperCase();
        });
    }, [location]);
    return (
        <div className="bg-[#212121] px-5 lg:px-11 flex items-center justify-between gap-20">
            <div className="flex overflow-hidden whitespace-nowrap capitalize items-center justify-center lg:py-8 text-3xl font-bold text-white">
                {user?.name}
            </div>
            <div className="hidden lg:flex items-center flex-1 gap-[3.75rem]">
                {options.map((item, i) => (
                    <Link key={i} to={`/monitor2/${item.replace(" ", "-").toLowerCase()}`}>
                        <button
                            key={item}
                            className={
                                selectedOption === item
                                    ? "text-white border-b-2 py-[1.875rem]"
                                    : "text-white border-b-2 border-b-transparent py-[1.875rem] opacity-70 hover:opacity-90"
                            }
                        >
                            {item}
                        </button>
                    </Link>
                ))}
            </div>
            <div className="hidden lg:block">
                <button
                    onClick={() => {
                        localStorage.removeItem("idToken");
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("refreshToken");
                        window.location.href = `https://auth.kunato.ai/login?${localStorage.getItem(
                            "redirect_uri"
                        )}&origin=${window.location.origin}&logout=true`;
                    }}
                    className="flex items-center justify-center text-white"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="2.25rem"
                        viewBox="0 -960 960 960"
                    >
                        <path
                            fill="currentColor"
                            d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
                        />
                    </svg>
                </button>
            </div>
            <div className="lg:hidden py-4">
                <button className="flex items-center justify-center">
                    <svg
                        height="2.3333rem"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.5 21V18.6667H24.5V21H3.5ZM3.5 15.1667V12.8333H24.5V15.1667H3.5ZM3.5 9.33333V7H24.5V9.33333H3.5Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};
export default MainHeader;
