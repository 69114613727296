import CountriesISO from "assests/countriesISO";
import customFetch from "components/shared/customFetch";
import InfoIcon from "components/shared/icons/InfoNew";
import { devServer } from "components/shared/utils";
import { useEffect, useState } from "react";

const UserEngagement = ({ selectedArticle }) => {
    const [data, setData] = useState();
    const [isEmpty, setIsEmpty] = useState(false);

    const getData = async () => {
        try {
            const resp = await customFetch(`${devServer}/get-user-insights`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    url: selectedArticle.urls[0],
                }),
            });
            const res = await resp.json();
            if (res.ok) {
                setData(res.data);
                if (Object.keys(res.data).length === 0) {
                    setIsEmpty(true);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (selectedArticle?.urls?.length > 0) {
            setIsEmpty(false);
            getData();
        }
    }, [selectedArticle]);

    return (
        <>
            {isEmpty ? (
                <div className="h-full flex items-center justify-center">
                    <div className="text-white text-2xl text-center">
                        No user interactions on the article detected
                    </div>
                </div>
            ) : (
                <div>
                    <div className="flex items-center">
                        <div className="border-l border-l-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] p-4  text-white flex-1">
                            <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                                User Clicks{" "}
                            </div>
                            <div className="font-bold text-4xl font-[Poppins] mt-4">
                                {data?.views ? data?.views : "N/A"}
                            </div>
                        </div>
                        <div className="border-l border-l-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] p-4  text-white flex-1">
                            <div className="flex items-center justify-between">
                                <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                                    Short Sessions
                                </div>
                                <div className="flex items-center justify-center relative text-[rgba(255,255,255,0.6)] cursor-pointer">
                                    <InfoIcon />
                                    <div className="tooltip absolute z-20 right-4 text-white p-2 bg-black border border-[#383838] w-64  top-0">
                                        Sessions which lasted for less than 5 seconds.
                                    </div>
                                </div>
                            </div>
                            <div className="font-bold text-4xl font-[Poppins] mt-4">
                                {data?.time?.length === 3 && data?.time[0]
                                    ? `${data?.time[0].toLocaleString()}`
                                    : "N/A"}
                            </div>
                        </div>{" "}
                        <div className="border-l border-l-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] p-4  text-white flex-1">
                            <div className="flex items-center justify-between">
                                <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                                    Moderate Sessions
                                </div>
                                <div className="flex items-center justify-center relative text-[rgba(255,255,255,0.6)] cursor-pointer">
                                    <InfoIcon />
                                    <div className="tooltip absolute z-20 right-4 text-white p-2 bg-black border border-[#383838] w-64  top-0">
                                        Sessions which lasted for more than 5 seconds and less than
                                        30 seconds.
                                    </div>
                                </div>
                            </div>
                            <div className="font-bold text-4xl font-[Poppins] mt-4">
                                {" "}
                                {data?.time?.length === 3 && data?.time[1]
                                    ? `${data?.time[1].toLocaleString()}`
                                    : "N/A"}
                            </div>
                        </div>{" "}
                        <div className="border-l border-l-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] border-r border-r-[#383838] p-4  text-white flex-1">
                            <div className="flex items-center justify-between">
                                <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                                    Long Sessions{" "}
                                </div>
                                <div className="flex items-center justify-center relative text-[rgba(255,255,255,0.6)] cursor-pointer">
                                    <InfoIcon />
                                    <div className="tooltip absolute z-20 right-4 text-white p-2 bg-black border border-[#383838] w-64  top-0">
                                        Sessions which lasted for more than 30 seconds.
                                    </div>
                                </div>
                            </div>
                            <div className="font-bold text-4xl font-[Poppins] mt-4">
                                {" "}
                                {data?.time?.length === 3 && data?.time[2]
                                    ? `${data?.time[2].toLocaleString()}`
                                    : "N/A"}
                            </div>
                        </div>
                    </div>
                    <div className="border-l border-l-[#383838] border-r border-r-[#383838] border-b border-b-[#383838] flex items-stretch">
                        <div className="border-r border-r-[#383838]  flex-1 p-4 flex flex-col text-white">
                            <div className="text-2xl font-bold mb-4">Geographic Spread</div>
                            <div className="py-1 bg-[rgba(255,255,255,0.05)] border-b border-b-[#383838] text-[rgba(122,122,122,1)] font-medium flex items-center ">
                                <div className="flex-[3] px-4">Location</div>
                                <div className="flex-[2] px-4">User Count</div>
                            </div>
                            {data?.countries?.map((item, i) => (
                                <div
                                    key={i}
                                    className="py-2 border-b border-b-[#383838] font-semibold flex items-center "
                                >
                                    <div className="flex-[3] px-4">
                                        {item?.country && CountriesISO[item.country]
                                            ? CountriesISO[item.country]
                                            : "N/A"}
                                    </div>
                                    <div className="flex-[2] px-4">
                                        {item.count ? item.count.toLocaleString() : "N/A"}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex-1 p-4 flex flex-col text-white">
                            <div className="text-2xl font-bold mb-4">Time Spread</div>
                            <div className="py-1 bg-[rgba(255,255,255,0.05)] border-b border-b-[#383838] text-[rgba(122,122,122,1)] font-medium flex items-center ">
                                <div className="flex-[3] px-4">Time</div>
                                <div className="flex-[2] px-4">User Count</div>
                            </div>
                            {data?.traffic?.map(
                                (item, i) =>
                                    item.users_count && (
                                        <div
                                            key={i}
                                            className="py-2 border-b border-b-[#383838] font-semibold flex items-center "
                                        >
                                            <div className="flex-[3] px-4">
                                                {item?.time
                                                    ? item.time
                                                    : item?.date
                                                    ? item.date
                                                    : "N/A"}
                                            </div>
                                            <div className="flex-[2] px-4">
                                                {item?.users_count
                                                    ? item.users_count.toLocaleString()
                                                    : "N/A"}
                                            </div>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default UserEngagement;
