const Linkedin = () => (
    <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.9113 0.750122H2.08876C1.92214 0.747808 1.75669 0.778339 1.60188 0.839971C1.44706 0.901602 1.3059 0.993128 1.18645 1.10932C1.06701 1.22551 0.971627 1.3641 0.905748 1.51716C0.83987 1.67022 0.804788 1.83475 0.802505 2.00137V16.9989C0.804788 17.1655 0.83987 17.33 0.905748 17.4831C0.971627 17.6361 1.06701 17.7747 1.18645 17.8909C1.3059 18.0071 1.44706 18.0986 1.60188 18.1603C1.75669 18.2219 1.92214 18.2524 2.08876 18.2501H16.9113C17.0779 18.2524 17.2433 18.2219 17.3981 18.1603C17.553 18.0986 17.6941 18.0071 17.8136 17.8909C17.933 17.7747 18.0284 17.6361 18.0943 17.4831C18.1601 17.33 18.1952 17.1655 18.1975 16.9989V2.00137C18.1952 1.83475 18.1601 1.67022 18.0943 1.51716C18.0284 1.3641 17.933 1.22551 17.8136 1.10932C17.6941 0.993128 17.553 0.901602 17.3981 0.839971C17.2433 0.778339 17.0779 0.747808 16.9113 0.750122V0.750122ZM6.07876 15.3976H3.45376V7.52262H6.07876V15.3976ZM4.76626 6.42012C4.40424 6.42012 4.05704 6.27631 3.80105 6.02032C3.54507 5.76434 3.40126 5.41714 3.40126 5.05512C3.40126 4.6931 3.54507 4.34591 3.80105 4.08992C4.05704 3.83393 4.40424 3.69012 4.76626 3.69012C4.95849 3.66832 5.15316 3.68737 5.33752 3.74602C5.52188 3.80467 5.69178 3.9016 5.83608 4.03046C5.98038 4.15933 6.09584 4.31722 6.17489 4.49379C6.25394 4.67037 6.29481 4.86166 6.29481 5.05512C6.29481 5.24859 6.25394 5.43987 6.17489 5.61645C6.09584 5.79303 5.98038 5.95092 5.83608 6.07978C5.69178 6.20864 5.52188 6.30557 5.33752 6.36422C5.15316 6.42287 4.95849 6.44192 4.76626 6.42012V6.42012ZM15.5463 15.3976H12.9213V11.1714C12.9213 10.1126 12.545 9.42137 11.5913 9.42137C11.2961 9.42353 11.0087 9.51612 10.7677 9.68665C10.5268 9.85718 10.3439 10.0975 10.2438 10.3751C10.1753 10.5808 10.1456 10.7974 10.1563 11.0139V15.3889H7.53126C7.53126 15.3889 7.53126 8.23137 7.53126 7.51387H10.1563V8.62512C10.3947 8.21134 10.7415 7.87045 11.1594 7.63917C11.5772 7.4079 12.0503 7.29499 12.5275 7.31262C14.2775 7.31262 15.5463 8.44137 15.5463 10.8651V15.3976Z"
            fill="#A9AFB2"
        />
    </svg>
);

export default Linkedin;
