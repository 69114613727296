import GlobeIcon from "components/shared/icons/globe";
import SettingsIcon from "components/shared/icons/settingsIcon";
import DatePicker from "./datePicker";
import jwtDecode from "jwt-decode";

const FiltersHeader = ({ startDate, endDate, setStartDate, setEndDate }) => {
    const user = jwtDecode(localStorage.getItem("accessToken"));
    return (
        <div className="flex items-center px-5 lg:px-11 py-8 text-white justify-between border-b border-b-[#383838]">
            <div className="text-[2rem] font-semibold capitalize">Metrics</div>
            <div className="hidden lg:flex items-center gap-3">
                {/* <div className="bg-[#3f3f3f] px-3 py-[0.875rem] gap-2 flex items-center rounded text-[#cacaca]">
                    <div className="text-2xl">
                        <CalendarIcon />
                    </div>
                    <div className="font-medium">Last 30 Days</div>
                </div> */}
                <DatePicker
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                />
                {/* <div className="bg-[#3f3f3f] px-3 py-[0.875rem] gap-2 flex items-center rounded text-[#cacaca]">
                    <div className="text-2xl">
                        <CategoryIcon />
                    </div>
                    <div className="font-medium">3 category selected</div>

                    <div className="text-2xl">
                        <ArrowDown />
                    </div>
                </div> */}
                <div className="bg-[#3f3f3f] px-3 py-[0.875rem] gap-2 flex items-center rounded text-[#cacaca]">
                    <div className="text-2xl">
                        <GlobeIcon />
                    </div>
                    <div className="font-medium">Worldwide</div>

                    {/* <div className="text-2xl">
                        <ArrowDown />
                    </div> */}
                </div>
                <div className="bg-[#3f3f3f] px-3 py-[0.875rem] gap-2 flex items-center rounded text-[#cacaca]">
                    <div className="text-2xl">
                        <SettingsIcon />
                    </div>
                </div>
            </div>
            <button className="flex items-center justify-center lg:hidden text-3xl rounded p-2 bg-[#3f3f3f]">
                <SettingsIcon />
            </button>
        </div>
    );
};
export default FiltersHeader;
