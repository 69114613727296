import React, { useState, useRef, useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import CountryCodeModal from "./CountryCodeModal";
import UserTypeModal from "./UserTypeModal";

import { requestAccess } from "components/shared/utils";
import ChevronDownWhite from "components/shared/icons/ChevronDownWhite";

import "./style.scss";
import CheckCircle from "components/shared/icons/CheckCircle";

const AccessForm = () => {
    const [form, setForm] = useState({
        monthlyTraffic: "",
        name: "",
        contactNumber: "",
        email: "",
        websiteUrl: "",
        countryCode: "91",
    });
    const [userModal, setUserModal] = useState(false);
    const [codeModal, setCodeModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const modalRef = useRef();

    const location = useLocation();

    const paramKey = queryString.parse(location.search).source;

    // Function to handle form input change
    const handleChange = (field, event) => {
        const regNumber = /^[0-9\b]+$/;
        let _form;
        const value = event.target.value;
        _form = { ...form };
        if (field === "contactNumber") {
            if (value === "" || regNumber.test(value)) {
                _form[field] = value;
            }
        } else {
            _form[field] = value;
        }
        setForm(_form);
    };

    const setClass = (valid, peer) => {
        let cls =
            "w-full focus:border-blue-700 cursor-pointer mb-4 relative bg-blue5 border border-blue-900 rounded p-3 focus:outline-none text-left";

        if (peer) {
            cls =
                "peer focus:border-blue-700 resize-none w-full mb-4 bg-blue5 border placeholder-transparent border-blue-900 rounded p-3 focus:outline-none";
        }

        if (valid && peer) {
            cls =
                "peer resize-none focus:border-blue-700 w-full mb-4 bg-blue5 border placeholder-transparent border-red-400 rounded p-3 focus:outline-none";
        } else if (valid) {
            cls =
                "w-full focus:border-blue-700 resize-none cursor-pointer mb-4 relative bg-blue5 border border-red-400 rounded p-3 focus:outline-none text-left";
        }
        return cls;
    };

    const [error, setError] = useState(null);
    const [nameValid, setNameValid] = useState(false);
    const [monthlyTraffic, setMonthlyTraffic] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneLimit, setPhoneLimit] = useState(10);
    const [websiteValid, setWebsiteValid] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    // Function to submit form and call API
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setNameValid(false);
        setEmailValid(false);
        setMonthlyTraffic(false);
        setPhoneValid(false);
        setWebsiteValid(false);
        if (
            form.monthlyTraffic.length < 2 ||
            form.name.length < 2 ||
            form.email.length < 2 ||
            form.contactNumber.length < phoneLimit ||
            form.websiteUrl.length < 2
        ) {
            if (form.monthlyTraffic.length < 3) {
                setMonthlyTraffic(true);
            }
            if (form.name.length < 3) {
                setNameValid(true);
            }
            if (form.email.length < 3) {
                setEmailValid(true);
            }
            if (form.contactNumber.length < phoneLimit) {
                setPhoneValid(true);
            }
            if (form.websiteUrl.length < 3) {
                setWebsiteValid(true);
            }

            // setError("Please complete all required fields.");
            // window.scroll(0, 0);
            setLoading(false);
        } else {
            // Storing response
            let _requestAccess = requestAccess;
            if (paramKey) {
                _requestAccess = requestAccess + `?source=${paramKey}`;
            }
            const response = await fetch(_requestAccess, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify(form),
            });
            // Storing data in form of JSON
            const data = await response.json();

            if (data.ok) {
                // success
                setForm({
                    monthlyTraffic: "",
                    name: "",
                    contactNumber: "",
                    email: "",
                    websiteUrl: "",
                    countryCode: "91",
                });
                setEmailSent(true);
                // setTimeout(() => {
                //     setEmailSent(false);
                // }, 7000);
            } else {
                setError(data.error.message);
                window.scroll(0, 0);
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                setUserModal(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="flex flex-col items-center text-center w-full max-w-425 px-4 pt-3 py-6 mb-10"
            style={{ backgroundColor: "#011A32" }}
        >
            {error && (
                <div
                    style={{ backgroundColor: "#1D0B13" }}
                    className="flex items-center mb-4 rounded font-semibold text-base border border-red-500 text-red-500 py-2.5 pl-4 px-4"
                >
                    <p className="w-5 h-5">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 20V15M15 10H15.0125M27.5 15C27.5 21.9036 21.9036 27.5 15 27.5C8.09644 27.5 2.5 21.9036 2.5 15C2.5 8.09644 8.09644 2.5 15 2.5C21.9036 2.5 27.5 8.09644 27.5 15Z"
                                stroke="#F34860"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </p>
                    <span className="ml-3">{error}</span>
                </div>
            )}

            {emailSent ? (
                <div className="flex w-full flex-col items-center rounded font-normal text-2xl text-white py-2.5 pl-4 px-4">
                    <span className="ml-3 max-w-270 leading-9 py-14 pb-20 mb-3">
                        Thank you for applying
                        <br /> with <b>Kunato</b>,<br /> One of our executives
                        <br /> will get back to you in
                        <br /> <b>48-72</b> hours
                    </span>
                    <button
                        type="submit"
                        className="flex items-center justify-center bg-blue7 text-white py-2 px-11 font-bold rounded text-base access-submit-btn w-full"
                    >
                        <div className="z-20 flex items-center justify-center py-1.5">
                            <CheckCircle width="20" height="20" color="#fff" />
                            <span className="ml-2">Sent</span>
                        </div>
                    </button>
                </div>
            ) : (
                <form className="w-full max-w-361" onSubmit={(e) => handleSubmit(e)}>
                    <div className="flex flex-col items-center justify-between w-full">
                        <div className="w-full lg:max-w-361 relative">
                            <input
                                id="name"
                                type="text"
                                placeholder="Full Name"
                                value={form.name}
                                onChange={(e) => handleChange("name", e)}
                                className={setClass(nameValid, true)}
                                onFocus={() => setNameValid(false)}
                            />
                            <label
                                htmlFor="name"
                                className="absolute left-1 -top-3 bg-blue5 px-2 text-gray-400 transition-all
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-3"
                            >
                                Full Name
                            </label>
                        </div>

                        <div className="w-full mb-4 lg:max-w-361 flex items-center relative">
                            <div
                                onClick={() => setCodeModal(!codeModal)}
                                className="flex text-white cursor-pointer items-center justify-between w-20 relative mr-1.5 bg-blue5 border border-blue-900 rounded p-3 focus:outline-none"
                            >
                                +{form.countryCode}
                                {/* <p className=" w-4 h-4">
                                <ChevronDownWhite color={"#78797C"} />
                            </p> */}
                                {codeModal && (
                                    <CountryCodeModal
                                        closeModal={() => setCodeModal(false)}
                                        setCountryCode={(code) =>
                                            setForm({ ...form, countryCode: code })
                                        }
                                        setPhoneLimit={setPhoneLimit}
                                    />
                                )}
                            </div>
                            <input
                                id="Number"
                                type="text"
                                placeholder="Contact Number (Optional)"
                                value={form.contactNumber}
                                onChange={(e) => handleChange("contactNumber", e)}
                                className={setClass(phoneValid, true)}
                                style={{ marginBottom: 0 }}
                                // className="peer focus:border-blue-700 placeholder-transparent w-full bg-blue5 border border-blue-900 rounded p-3 focus:outline-none"
                                // onFocus={() => setNumberValid(false)}
                            />
                            <label
                                htmlFor="Number"
                                className="absolute left-20 -top-3 bg-blue5 px-2 text-gray-400 transition-all
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-3"
                            >
                                Phone
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between w-full">
                        <div className="w-full lg:max-w-361 relative">
                            <input
                                id="email"
                                type="email"
                                placeholder="Email"
                                value={form.email}
                                onChange={(e) => handleChange("email", e)}
                                className={setClass(emailValid, true)}
                                onFocus={() => setEmailValid(false)}
                            />
                            <label
                                htmlFor="email"
                                className="absolute left-1 -top-3 bg-blue5 px-2 text-gray-400 transition-all
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-3"
                            >
                                Email
                            </label>
                        </div>{" "}
                    </div>
                    <div className="flex flex-col items-center justify-between w-full">
                        <div className="w-full lg:max-w-361 relative">
                            <input
                                id="Website URL"
                                type="text"
                                placeholder="Website"
                                value={form.websiteUrl}
                                onChange={(e) => handleChange("websiteUrl", e)}
                                className={setClass(websiteValid, true)}
                                // className="peer focus:border-blue-700 placeholder-transparent w-full mb-4 lg:max-w-361 bg-blue5 border border-blue-900 rounded p-3 focus:outline-none"
                            />
                            <label
                                htmlFor="Website URL"
                                className="absolute left-1 -top-3 bg-blue5 px-2 text-gray-400 transition-all
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-3 peer-focus:-top-3"
                            >
                                Website URL
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-between lg:flex-row w-full relative">
                        <div
                            ref={modalRef}
                            type="text"
                            placeholder="I am"
                            onClick={() => {
                                setUserModal(!userModal);
                                setMonthlyTraffic(false);
                            }}
                            style={
                                form.monthlyTraffic
                                    ? { color: "white", textTransform: "capitalize" }
                                    : { color: "rgb(156 163 175)" }
                            }
                            className={setClass(monthlyTraffic)}
                        >
                            {form.monthlyTraffic || "Monthly Users"}
                            <span
                                className={
                                    userModal
                                        ? "absolute right-7 rotate-180 top-4"
                                        : "absolute right-7"
                                }
                            >
                                <ChevronDownWhite />
                            </span>
                            {userModal && (
                                <UserTypeModal
                                    closeModal={() => setUserModal(false)}
                                    setForm={setForm}
                                    form={form}
                                />
                            )}
                        </div>
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-blue7 text-white py-3.5 px-11 font-bold rounded-lg text-base access-submit-btn w-full"
                    >
                        {loading ? (
                            <div className="flex items-center justify-center">
                                <div className="dot-falling my-3.5"></div>
                            </div>
                        ) : (
                            <div className="z-20">
                                Request Now
                                {/* <p className="text-9 leading-3">(Limited spots available)</p> */}
                            </div>
                        )}
                    </button>
                </form>
            )}
        </div>
    );
};

export default AccessForm;
