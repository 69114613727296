import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";

import eye1 from "assests/images/eye1.png";
import eye2 from "assests/images/eye2.png";
import errorImg from "assests/images/error.png";

import { userSignin } from "components/shared/utils";

const Form = () => {
    const [form, setForm] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [error, setError] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [showPwd1, setShowPwd1] = useState(false);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const history = useNavigate();

    // Function to set classname
    const getClass = () => {
        let _class;

        if (error) {
            _class =
                "peer placeholder-transparent bg-dark-inputBlue border border-red-500 text-white focus:border-white focus:outline-none text-base py-4 pl-3 rounded-md";
        } else {
            _class =
                "peer placeholder-transparent bg-dark-inputBlue border border-gray-500 text-white focus:border-white focus:outline-none text-base py-4 pl-3 rounded-md";
        }

        return _class;
    };

    // Function to handle form input change
    const handleChange = (field, event) => {
        let _form;
        _form = { ...form };
        _form[field] = event.target.value;
        setForm(_form);
    };

    // Function to submit form and call API
    const handleSubmit = async (event) => {
        event.preventDefault();
        //     // Storing response
        const response = await fetch(userSignin, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            // /make sure to serialize your JSON body
            body: JSON.stringify(form),
        });
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            //success
            mixpanel.track("Login successfull", {
                email: form.email,
            });
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
            localStorage.setItem("theme", "dark");
            history("/overview");
            window.location.reload();
        } else {
            //error
            setError(data.error.message);
        }
    };

    return (
        <div
            // style={{ backgroundColor: "#000F26", boxShadow: "0px 7px 7px 2px rgba(0, 0, 0, 0.31)" }}
            className="lg:pt-10 lg:px-6 pb-8 w-full lg:max-w-425"
        >
            <h5 className="font-bold mb-7 py-12 lg:py-0 text-white text-2xl text-center bg-blue12 lg:bg-transparent">
                Reset Password
            </h5>

            <form className="flex flex-col px-6 lg:px-0" onSubmit={(e) => handleSubmit(e)}>
                <div className="w-full relative">
                    <input
                        id="new-pwd"
                        type={showPwd ? "text" : "password"}
                        placeholder="New Password"
                        value={form.name}
                        onChange={(event) => handleChange("newPassword", event)}
                        style={{ width: "100%", backgroundColor: "#010D17", marginBottom: "20px" }}
                        className={getClass()}
                        // onFocus={() => setNameValid(false)}
                    />
                    <img
                        src={showPwd ? eye1 : eye2}
                        alt="img"
                        width={25}
                        height={17}
                        className="cursor-pointer absolute right-4 top-5"
                        onClick={() => setShowPwd(!showPwd)}
                    />
                    <label
                        htmlFor="new-pwd"
                        style={{ backgroundColor: "#010D17" }}
                        className="absolute left-1 -top-3 px-2 text-gray-400 transition-all rounded-lg
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-focus:-top-3"
                    >
                        New Password
                    </label>
                </div>

                <div className="w-full relative">
                    <input
                        type={showPwd1 ? "text" : "password"}
                        id="ConfirmPassword"
                        placeholder="Confirm Password"
                        className={getClass()}
                        value={form.password}
                        onChange={(event) => handleChange("confirmPassword", event)}
                        style={{ width: "100%", backgroundColor: "#010D17", marginBottom: "20px" }}
                    />
                    <img
                        src={showPwd1 ? eye1 : eye2}
                        alt="img"
                        width={25}
                        height={17}
                        className="cursor-pointer absolute right-4 top-5"
                        onClick={() => setShowPwd1(!showPwd1)}
                    />
                    <label
                        htmlFor="ConfirmPassword"
                        style={{ backgroundColor: "#010D17" }}
                        className="absolute left-1 -top-3 px-2 text-gray-400 transition-all rounded-lg
                             peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-focus:-top-3"
                    >
                        Confirm Password
                    </label>
                </div>

                {error && (
                    <div className="flex items-center bg-red-light p-3 rounded-md w-10/12 mt-1.5">
                        <img src={errorImg} alt="img" width={25} height={17} />
                        <p className="text-red-text ml-3">Invalid login credentials</p>
                    </div>
                )}

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-blue7 text-white py-3.5 rounded-md text-base font-bold mb-7"
                >
                    {loading ? (
                        <div className="flex items-center justify-center">
                            <div className="dot-falling my-1.5"></div>
                        </div>
                    ) : (
                        <div className="z-20">Reset Password</div>
                    )}
                </button>
            </form>
        </div>
    );
};

export default Form;
