import PriceSensitivity from "./priceSensitivity";
import PriceSensitivityPercent from "./priceSensitivityPercent";
import SimilarCategories from "./similarCategories";

const ThirdRow = () => {
    return (
        <div className="hidden lg:flex flex-col h-[36rem] lg:flex-row border-b border-b-[#383838]">
            <div className="flex-[2] flex flex-col border-r border-r-[#383838]">
                <div className="px-2 lg:px-11 flex-1 flex py-8 border-t border-t-[#383838]">
                    <SimilarCategories />
                </div>
            </div>
            <div className="flex-[3] hidden lg:flex ">
                <div className="flex-1 flex border-r border-r-[#383838]">
                    <PriceSensitivity />
                </div>
                <div className="flex-1 flex">
                    <PriceSensitivityPercent />
                </div>
            </div>
        </div>
    );
};
export default ThirdRow;
