const TiltedArrow = () => {
    return (
        <svg height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.41158 4.69531H15.3041L15.3032 10.5878M15.3032 4.69615L4.69824 15.3011"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default TiltedArrow;
