import FifthRow from "./fifthRow";
import FirstRow from "./firstRow";
import FourthRow from "./fourthRow";
import DetailsSecHeader from "./header";
import SecondRow from "./secondRow";
import ThirdRow from "./thirdRow";

const DetailedSection = ({ data, difference }) => {
    return (
        <div className="bg-[#141414] pb-20">
            <DetailsSecHeader />
            <FirstRow
                newArticles={data?.dataForCurrentPeriod?.total_new_articles}
                topArticles={data?.dataForCurrentPeriod?.top_performing_content}
                overallTopArticles={data?.dataForCurrentPeriod?.overall_top_performing_content}
                engagementData={data?.dataForCurrentPeriod?.category_engagment_per_day}
                difference={difference}
            />
            <SecondRow
                geoData={data?.dataForCurrentPeriod?.users_per_location}
                catData={data?.dataForCurrentPeriod?.category_metrics}
                newArticles={data?.dataForCurrentPeriod?.total_new_articles}
            />
            <ThirdRow />
            <FourthRow
                engagementData={data?.dataForCurrentPeriod?.category_engagment_per_day}
                difference={difference?.category_diff}
            />
            <FifthRow
                engagementData={data?.dataForCurrentPeriod?.engagement_per_day}
                topArticles={data?.dataForCurrentPeriod?.top_performing_content}
            />
        </div>
    );
};
export default DetailedSection;
