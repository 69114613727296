import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { useEffect, useState } from "react";
import countries_data from "assests/geoJSON";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import CountriesCentroid from "assests/countriesCentroid";
import citiesJson from "assests/citiesdata";

const TrafficLocation = ({ geoData }) => {
    const [graphData, setGraphData] = useState([]);

    const [options, setOptions] = useState({});
    echarts.registerMap("world", countries_data);
    const rem = parseInt(getComputedStyle(document.documentElement).fontSize.replace("px"));
    const updater = () => {
        const total = graphData.reduce((acc, curr) => {
            return acc + curr[2];
        }, 0);
        setOptions({
            title: [
                {
                    itemGap: 12,
                    right: 7 * rem,
                    backgroundColor: "#141414",
                    padding: 8,
                    text: "TOTAL TRAFFIC",
                    textStyle: {
                        color: "#7a7a7a",
                        fontSize: "0.75rem",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                    },
                    subtext: total?.toLocaleString(),
                    top: "auto",
                    subtextStyle: {
                        color: "#fff",
                        fontSize: "3rem",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                    },
                    left: "auto",
                },
            ],
            tooltip: { trigger: "item" },
            backgroundColor: "transparent",
            geo: {
                tooltip: {
                    show: false,
                },
                map: "world",
                roam: true,
                left: "5%",
                scaleLimit: { min: 1.15, max: 5 },

                itemStyle: {
                    areaColor: "#929292",
                },
                label: {
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: "white",
                    padding: 4,
                    borderRadius: 4,
                    fontSize: "0.875rem",
                },
                emphasis: {
                    label: {
                        color: "#000",
                    },
                    itemStyle: {
                        areaColor: "#ccc",
                    },
                },
            },
            visualMap: {
                type: "continuous",
                orient: "vertical",
                align: "bottom",
                max:
                    graphData.length > 0 &&
                    Math.round(graphData[0][2] / Math.pow(10, `${graphData[0][2]}`.length - 1)) *
                        Math.pow(10, `${graphData[0][2]}`.length - 1),
                calculable: true,
                right: 10 * rem,
                itemHeight: 12 * rem,
                itemWidth: 2 * rem,
                backgroundColor: "#141414cc",
                left: "auto",
                realtime: false,
                inRange: {
                    color: ["#071DF5", "#76FB4C", "#F4F450", "#F3B23E", "#EA3824"],
                },
                textStyle: {
                    color: localStorage.theme === "light" ? "#444" : "#fff",
                    fontSize: "0.75rem",
                },
            },
            series: [
                {
                    type: "heatmap",
                    coordinateSystem: "geo",
                    geoIndex: 0,
                    minOpacity: 0.07,
                    blurSize: 10,
                    // symbolSize: function (params) {
                    //     return (params[2] / 1000) * 50;
                    // },
                    // encode: {
                    //     tooltip: 2,
                    // },
                    data: graphData,
                },
            ],
        });
    };

    useEffect(() => {
        setTimeout(() => {
            updater();
        }, 150);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData]);

    useEffect(() => {
        setGraphData(() => {
            const res = [];
            geoData?.map((item) => {
                if (citiesJson[`${item.city}${item.country}`]) {
                    const a = citiesJson[`${item.city}${item.country}`];
                    res.push([a.longitude, a.latitude, item.value]);
                } else {
                    const a = CountriesCentroid.find((country) => country.iso === item.country);
                    if (a) {
                        res.push([a.longitude, a.latitude, item.value]);
                    }
                }
            });
            return res.sort((prev, curr) => {
                return curr[2] - prev[2];
            });
        });
    }, [geoData]);

    return (
        <div className="flex-1 flex">
            <div className="flex-1 p-8 flex flex-col">
                <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center text-white flex items-center gap-2">
                        <div className=" text-lg font-medium ">Traffic Location</div>
                        <div className="flex items-center justify-center text-xs text-[#999999]">
                            <InfoIcon />
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                            {" "}
                            <ExpandIcon />{" "}
                        </button>
                        <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                            <SettingsIcon />{" "}
                        </button>
                    </div>
                </div>
                {options && (
                    <ReactECharts
                        option={options}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default TrafficLocation;
