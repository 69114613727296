import FiltersHeader from "./filtersHeader";
import OverviewStats from "./overviewStats";

const TopOverview = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    totalUsers,
    uniqueUsers,
    topPerformingArticles,
    totalClicks,
    difference,
}) => {
    return (
        <div>
            <FiltersHeader
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
            />
            <OverviewStats
                totalUsers={totalUsers}
                uniqueUsers={uniqueUsers}
                topPerformingArticles={topPerformingArticles}
                totalClicks={totalClicks}
                difference={difference}
            />
        </div>
    );
};
export default TopOverview;
