const GlobeIcon = () => {
    return (
        <svg height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21.15C6.94644 21.15 2.85 17.0536 2.85 12C2.85 6.94644 6.94644 2.85 12 2.85C17.0536 2.85 21.15 6.94644 21.15 12C21.15 17.0536 17.0536 21.15 12 21.15Z"
                stroke="currentColor"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.8538 4C12.8538 4 15.4152 7.17588 15.4152 12C15.4152 16.8241 12.8538 20 12.8538 20M11.1462 20C11.1462 20 8.58484 16.8241 8.58484 12C8.58484 7.17588 11.1462 4 11.1462 4M4 14.8141H20M4 9.18593H20"
                stroke="currentColor"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default GlobeIcon;
