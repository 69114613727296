const NewKlogo = () => {
    return (
        <svg
            width="92"
            height="19"
            viewBox="0 0 189 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M57.4326 10.5918V25.2708C57.4326 29.6295 53.873 33.1364 49.5614 33.1364C45.1997 33.1364 41.6902 29.6295 41.6902 25.2708V10.5918H34.8718V25.2708C34.8718 33.3869 41.4395 39.9499 49.5614 39.9499C57.6331 39.9499 64.251 33.3869 64.251 25.2708V10.5918H57.4326Z"
                fill="white"
            />
            <path
                d="M84.9094 10.5918C76.7875 10.5918 70.2198 17.2049 70.2198 25.2708V39.9499H77.0382V25.2708C77.0382 20.9623 80.5476 17.4053 84.9094 17.4053C89.221 17.4053 92.7806 20.9623 92.7806 25.2708V39.9499H99.599V25.2708C99.599 17.2049 92.9811 10.5918 84.9094 10.5918Z"
                fill="white"
            />
            <path
                d="M119.557 10.5918C111.435 10.5918 104.867 17.2049 104.867 25.2708C104.867 33.3869 111.435 39.9499 119.557 39.9499H120.459V33.1364H119.557C115.195 33.1364 111.685 29.6295 111.685 25.2708C111.685 20.9623 115.195 17.4053 119.557 17.4053H127.428V39.9499H134.246V10.5918H119.557Z"
                fill="white"
            />
            <path
                d="M148.488 10.5918V3.0769H141.67V39.9499H148.488V17.4053H156.009V10.5918H148.488Z"
                fill="white"
            />
            <path
                d="M173.853 10.5918C165.731 10.5918 159.164 17.2049 159.164 25.2708C159.164 33.3869 165.731 39.9499 173.853 39.9499C181.925 39.9499 188.543 33.3869 188.543 25.2708C188.543 17.2049 181.925 10.5918 173.853 10.5918ZM173.853 33.1364C169.492 33.1364 165.982 29.6295 165.982 25.2708C165.982 20.9623 169.492 17.4053 173.853 17.4053C178.165 17.4053 181.725 20.9623 181.725 25.2708C181.725 29.6295 178.165 33.1364 173.853 33.1364Z"
                fill="white"
            />
            <rect width="7.65957" height="40" fill="white" />
            <path
                d="M24.0426 0.425537H31.0638V3.82979L19.5745 18.7234C19.5745 18.7234 18.6773 19.5445 18.7234 20.2128C18.7646 20.8105 19.5745 21.4894 19.5745 21.4894L31.0638 36.383V39.7872H24.0426L12.1277 23.8298C12.1277 23.8298 11.0638 21.6851 11.0638 20.2128C11.0638 18.7404 12.1277 16.5957 12.1277 16.5957L24.0426 0.425537Z"
                fill="white"
            />
        </svg>
    );
};
export default NewKlogo;
