import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import { graphic } from "echarts";

const CorrelationTrends = ({ engagementData }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (engagementData) {
            setTimeout(() => {
                const dataset = engagementData?.map((item) => [
                    +new Date(item.date),
                    item.value,
                    parseInt(item.value * 0.9),
                ]);
                setOptions({
                    legend: {
                        bottom: "5%",
                        icon: "roundRect",
                        textStyle: {
                            color: "white",
                        },
                    },
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "15%",
                        top: "5%",
                        containLabel: true,
                    },
                    tooltip: {
                        show: true,
                        trigger: "item",
                    },
                    xAxis: {
                        type: "time",
                        boundaryGap: ["5%", "0%"],

                        axisLabel: {
                            color: "#999",
                        },
                        axisLine: { show: false },
                        axisTick: { show: false },
                    },
                    yAxis: {
                        boundaryGap: ["5%", "0%"],
                        min: (value) => {
                            return Math.round(value.min * 0.85);
                        },
                        type: "value",
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                            axisLabel: {
                                color: "#999",
                                showMinLabel: false,
                            },
                        },
                    },
                    series: [
                        {
                            name: "User engagement",
                            type: "line",

                            symbolSize: 1,
                            encode: {
                                x: "time",
                                y: "User engagement",
                            },
                            areaStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    // #196099, #0009332B
                                    {
                                        offset: 0,
                                        color: "rgba(239, 204, 79)",
                                    },
                                    // {
                                    //     offset: 1,
                                    //     color: "#196099",
                                    // },
                                    {
                                        offset: 1,
                                        color: "rgba(3, 9, 38,1)",
                                    },
                                ]),
                            },
                            tooltip: {
                                backgroundColor: "#3d3d3d",
                                borderWidth: 0,
                                formatter: function (params, ticket, callback) {
                                    return `<div class="font-bold text-white">${new Date(
                                        params.value[0]
                                    ).toLocaleDateString(
                                        "en-UK"
                                    )}</div><div class="text-white font-medium"> # of users: <b>
                                        ${
                                            params.value[
                                                params.dimensionNames.findIndex(
                                                    (item) => item === `${params.seriesName}`
                                                )
                                            ]
                                        } <b></div>`;
                                },
                            },
                            lineStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#647A89b0",
                                    },
                                    {
                                        offset: 0.25,
                                        color: "#FFFFFF",
                                    },
                                    {
                                        offset: 0.75,
                                        color: "#FFFFFF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#647A89b0",
                                    },
                                ]),
                            },
                        },
                        {
                            name: "Page Views",
                            type: "line",
                            areaStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    // #196099, #0009332B
                                    {
                                        offset: 0,
                                        color: "#196099",
                                    },
                                    // {
                                    //     offset: 1,
                                    //     color: "#196099",
                                    // },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 9, 51)",
                                    },
                                ]),
                            },

                            symbolSize: 1,
                            encode: {
                                x: "time",
                                y: "Page Views",
                            },
                            tooltip: {
                                backgroundColor: "#3d3d3d",
                                borderWidth: 0,
                                formatter: function (params, ticket, callback) {
                                    return `<div class="font-bold text-white">${new Date(
                                        params.value[0]
                                    ).toLocaleDateString(
                                        "en-UK"
                                    )}</div><div class="text-white font-medium"> # of pageviews: <b>
                                        ${
                                            params.value[
                                                params.dimensionNames.findIndex(
                                                    (item) => item === `${params.seriesName}`
                                                )
                                            ]
                                        } <b></div>`;
                                },
                            },
                            lineStyle: {
                                color: new graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#647A89b0",
                                    },
                                    {
                                        offset: 0.25,
                                        color: "#FFFFFF",
                                    },
                                    {
                                        offset: 0.75,
                                        color: "#FFFFFF",
                                    },
                                    {
                                        offset: 1,
                                        color: "#647A89b0",
                                    },
                                ]),
                            },
                        },
                    ],
                    dataset: {
                        source: dataset,
                        dimensions: ["time", "User engagement", "Page Views"],
                    },
                    color: ["#a18f4a", "#3d7696"],
                });
            }, 150);
        }
    }, [engagementData]);
    return (
        <div className="flex-1 flex flex-col">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">
                        User engagement & Page Views correlation trends
                    </div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1">
                {options && (
                    <ReactECharts
                        option={options}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
                <div className="flex items-center"></div>
            </div>
        </div>
    );
};
export default CorrelationTrends;
