export const ClickBaitCategory = {
    0: "Questioning",
    1: "Reaction",
    2: "Revealing",
    3: "Numbering",
    4: "Shocking",
    5: "Hypthesis",
    6: "Reasoning",
    7: "Miscelleanous",
    8: "Forward-referencing",
};
export const RelevanceDef = {
    0: "Short term",
    1: "Long term ",
    2: "Evergreen",
};
export const RatingDef = {
    0: "Poor",
    1: "Below average",
    2: "Average",
    3: "Above average",
    4: "Excellent",
};
