import SearchIcon from "components/shared/icons/search";
import MidSection from "./midSection";

import ArrowDown from "components/shared/icons/ArrowDown";
import Reload from "components/shared/icons/Reload";

const Article = ({
    options,
    selected,
    setSelected,
    selectedIndex,
    list,
    setSelectedIndex,
    pageno,
    pageLength,
    setPageno,
    prices,
    searchKey,
    setSearchKey,
    selectedSimilar,
    setRefreshList,
}) => {
    return (
        <div className="flex-1 flex">
            <div className="bg-[#141414] pt-6 w-[20vw] h-full flex flex-col gap-6">
                <div className="text-white px-6 font-semibold text-2xl flex items-center justify-between">
                    Latest Articles
                    <button
                        onClick={() => {
                            setSearchKey("");
                            setSelectedIndex(0);
                            setPageno(0);
                            setRefreshList(true);
                        }}
                        className="flex items-center justify-center p-2 bg-[#212121] rounded"
                    >
                        <Reload width={"0.875em"} height={"0.875em"} />
                    </button>
                </div>
                <div className="flex text-white px-6 items-center gap-4">
                    <label
                        htmlFor="searchArticle"
                        className="flex-1 rounded border border-[#0485FF] px-2 py-2 h-full flex gap-2 items-center"
                    >
                        <div className="flex items-center justify-center text-xl">
                            <SearchIcon />
                        </div>
                        <input
                            id="searchArticle"
                            type="text"
                            value={searchKey}
                            onChange={(e) => {
                                setSearchKey(e.target.value);
                            }}
                            onBlur={() => {
                                setSearchKey((prevState) => prevState.trim());
                            }}
                            placeholder="Search"
                            className="border-none bg-transparent text-white font-medium flex-1 outline-none"
                        />
                    </label>

                    <button className="p-3 bg-[#0485ff] rounded text-xl text-[#fff]">
                        <SearchIcon />
                    </button>
                </div>
                <div className="flex flex-col gap-4 flex-[1_1rem] overflow-auto">
                    {list.map((item, i) => (
                        <button
                            onClick={() => {
                                setSelectedIndex(i);
                            }}
                            key={i}
                            className={
                                selectedIndex === i
                                    ? "text-white text-left cursor-pointer p-2 px-6 pr-8 bg-[#0485ff]"
                                    : "text-white text-left cursor-pointer p-2 px-6 pr-8 hover:bg-[#3e5f8b]"
                            }
                        >
                            <div className="text-base leading-6 max-h-[4.5rem] overflow-hidden">
                                {item.title}
                            </div>
                            <div className="text-base flex gap-4 items-center justify-between mt-2">
                                <div
                                    className={
                                        selectedIndex === i
                                            ? "text-[rgba(255,255,255,0.7)]"
                                            : "text-[rgb(122,122,122)]"
                                    }
                                >
                                    {new Date(item.publishedAt).toLocaleDateString()}
                                </div>
                                <div
                                    className={
                                        selectedIndex === i
                                            ? "text-white"
                                            : "text-[rgba(51,221,155,1)]"
                                    }
                                >
                                    {prices[item.urls[0]]
                                        ? `QMV $${prices[item.urls[0]].toFixed(2)}`
                                        : ""}
                                </div>
                            </div>
                        </button>
                    ))}
                    {list.length < pageLength * (pageno + 1) ? null : (
                        <button
                            disabled={list.length < pageLength * (pageno + 1)}
                            onClick={() => {
                                setPageno((prevState) => prevState + 1);
                            }}
                            className="text-[#2a8cff] px-4 py-3 flex items-center justify-end gap-1"
                        >
                            View More{" "}
                            <div className="flex items-center justify-center text-2xl">
                                <ArrowDown />
                            </div>{" "}
                        </button>
                    )}
                </div>
            </div>
            <MidSection
                options={options}
                selected={selected}
                setSelected={setSelected}
                selectedArticle={list[selectedIndex]}
                selectedSimilar={selectedSimilar}
            />
        </div>
    );
};
export default Article;
