import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";

const PredictionOverTime = ({ engagementData }) => {
    const [options, setOptions] = useState({});
    const [category, setCategory] = useState();

    const color = ["#92f6a8", "#df8bcd", "#249bed"];

    useEffect(() => {
        let data = {};
        engagementData?.forEach((item) => {
            if (!data[item.date]) {
                data[item.date] = {};
            }
            data[item.date][item.category] = item.unique_user_count;
        });
        let dimensions = ["time"];
        let graphData = Object.keys(data).map((item) => {
            let a = [+new Date(item)];
            Object.keys(data[item])
                .sort()
                .map((key) => {
                    if (!dimensions.includes(key.replaceAll("_", " "))) {
                        dimensions.push(key.replaceAll("_", " "));
                    }
                    a.push(data[item][key]);
                });
            return a;
        });
        setCategory(dimensions);
        setTimeout(() => {
            setOptions({
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: true,
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                xAxis: {
                    type: "time",
                    boundaryGap: ["5%", "0%"],

                    axisLabel: {
                        color: "#999",
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                },
                yAxis: {
                    boundaryGap: ["5%", "0%"],
                    // min: (value) => {
                    //     return Math.round(value.min * 0.85);
                    // },
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.1)",
                        },
                        axisLabel: {
                            color: "#999",
                            showMinLabel: false,
                        },
                    },
                },
                series: [
                    // {
                    //     name: "New Year",
                    //     type: "line",
                    //     symbol: "none",
                    //     encode: {
                    //         x: "time",
                    //         y: "New Year",
                    //     },
                    // },
                    // {
                    //     name: "Christmas",
                    //     type: "line",
                    //     symbol: "none",
                    //     encode: {
                    //         x: "time",
                    //         y: "Christmas",
                    //     },
                    // },
                    // {
                    //     name: "Gifts",
                    //     type: "line",
                    //     symbol: "none",
                    //     encode: {
                    //         x: "time",
                    //         y: "Gifts",
                    //     },
                    // },
                    {
                        name: dimensions[1],
                        type: "line",
                        symbolSize: 1,
                        // lineStyle: { type: [8, 10] },
                        encode: {
                            x: "time",
                            y: dimensions[1],
                        },
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of users: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                    },
                    {
                        name: dimensions[2],
                        type: "line",
                        symbolSize: 1,
                        // lineStyle: { type: [8, 10] },
                        encode: {
                            x: "time",
                            y: dimensions[2],
                        },
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of users: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                    },

                    {
                        name: dimensions[3],
                        type: "line",
                        symbolSize: 1,
                        // lineStyle: { type: [8, 10] },
                        encode: {
                            x: "time",
                            y: dimensions[3],
                        },
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of users: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                    },
                ],
                dataset: {
                    source: graphData,
                    dimensions: dimensions,
                },
                color: ["#92f6a8", "#df8bcd", "#249bed"],
            });
        }, 150);
    }, [engagementData]);

    return (
        <div className="flex-1 flex flex-col p-8">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">Prediction Over Time</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1 flex">
                <div className=" flex-[4]">
                    {options && (
                        <ReactECharts
                            option={options}
                            style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    )}
                </div>
                <div className="flex-1 flex">
                    <div className="flex-1 border-t border-t-[#383838] border-l border-l-[#383838] flex flex-col justify-between">
                        <div className="pl-8 pt-5">
                            <div className="text-xs font-semibold text-[#7a7a7a]">
                                AVG. NUMBER OF USERS
                            </div>
                            <div className="text-white mt-2 text-5xl font-[Poppins] font-semibold ">
                                {engagementData
                                    ?.reduce((acc, curr) => {
                                        return acc + curr.unique_user_count;
                                    }, 0)
                                    .toLocaleString()}
                            </div>
                        </div>
                        <div className="pl-8 pb-5 flex flex-col gap-5 capitalize text-white text-sm font-medium">
                            {category?.map(
                                (item, i) =>
                                    item !== "time" && (
                                        <div key={i} className="flex items-center gap-2">
                                            <div
                                                className={`p-2 rounded-sm`}
                                                style={{ backgroundColor: color[i - 1] }}
                                            ></div>{" "}
                                            <div>Category: {item}</div>{" "}
                                        </div>
                                    )
                            )}{" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PredictionOverTime;
