import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";

const PriceSensitivityPercent = () => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: true,
                },
                // xAxis: {
                //     data: ["2017-10-24", "2017-10-25", "2017-10-26", "2017-10-27"],
                //     axisLine: { show: false },
                //     axisTick: { show: false },
                //     axisLabel: {
                //         formatter: (value, index) => {
                //             console.log(value, index);
                //             return `${value}%`;
                //         },
                //     },
                // },
                // yAxis: {
                //     splitLine: {
                //         lineStyle: {
                //             color: "rgba(255,255,255,0.1)",
                //         },
                //         axisLabel: {
                //             color: "#999",
                //             showMinLabel: false,
                //         },
                //     },
                // },
                // series: [
                //     {
                //         type: "candlestick",
                //         barWidth: "20%",
                //         barMaxWidth: 40,
                //         data: [
                //             [20, 34, 10, 38],
                //             [40, 35, 30, 50],
                //             [31, 38, 33, 44],
                //             [38, 15, 5, 42],
                //         ],
                //         itemStyle: {
                //             color0: "#92f6a8",
                //             color: "#ea1212",
                //             borderColor0: "#92f6a8",
                //             borderColor: "#ea1212",
                //         },
                //     },
                // ],
                xAxis: {
                    type: "time",
                    boundaryGap: ["5%", "5%"],

                    axisLabel: {
                        color: "#999",
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                },
                yAxis: {
                    boundaryGap: ["5%", "0%"],
                    min: (value) => {
                        return Math.round(value.min * 0.85);
                    },
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.1)",
                        },
                        axisLabel: {
                            color: "#999",
                            showMinLabel: false,
                            formatter: (props) => {
                                console.log(props);
                                return "test";
                            },
                        },
                    },
                },
                series: [
                    {
                        type: "candlestick",
                        // colorBy: "series",
                        barWidth: "20%",
                        barMaxWidth: 40,
                        x: "date",
                        y: ["open", "close", "highest", "lowest"],
                        itemStyle: {
                            color0: "#ea1212",
                            color: "#92f6a8",
                            borderColor0: "#ea1212",
                            borderColor: "#92f6a8",
                        },
                        zlevel: 3,
                    },
                    {
                        type: "line",
                        symbol: "none",
                        encode: {
                            x: "date",
                            y: ["open"],
                        },

                        lineStyle: { color: "#666" },
                    },
                ],
                dataset: {
                    source: [
                        [+new Date(2023, 11, 1), 10.15, 10.32, 11.03, 9.15],
                        [+new Date(2023, 11, 2), 11.24, 10.5, 12.03, 9.5],
                        [+new Date(2023, 11, 3), 11.1, 12.2, 13.14, 10.1],
                        [+new Date(2023, 11, 4), 14.33, 14.92, 16.03, 13.73],
                        [+new Date(2023, 11, 5), 13.0, 13.92, 15.03, 12.4],
                        [+new Date(2023, 11, 6), 13.66, 14.22, 15.44, 13.06],
                        [+new Date(2023, 11, 7), 13.12, 12.1, 14.13, 11.1],
                        [+new Date(2023, 11, 8), 14.9, 15.2, 16.63, 14.6],
                        [+new Date(2023, 11, 9), 15.2, 15.82, 17.03, 14.9],
                        [+new Date(2023, 11, 10), 15.8, 16.22, 17.78, 15.1],
                        // [+new Date(2023, 11, 11), 16.92, 16.25, 18.13, 16.15],
                        // [+new Date(2023, 11, 12), 16.9, 17.52, 18.94, 16.4],
                        // [+new Date(2023, 11, 13), 16.95, 17.82, 19.04, 16.7],
                        // [+new Date(2023, 11, 14), 17.11, 18.02, 19.22, 17.03],
                        // [+new Date(2023, 11, 15), 17.21, 17.02, 19.22, 17.03],
                        // [+new Date(2023, 11, 16), 17.02, 16.52, 18.02, 15.53],
                    ],
                    dimensions: ["date", "open", "close", "highest", "lowest"],
                },
                // // color: ["#92f6a8", "#df8bcd", "#249bed"],
            });
        }, 150);
    }, []);

    return (
        <div className="flex-1 flex flex-col p-8">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">Price sensitivity (%)</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1">
                {options && (
                    <ReactECharts
                        option={options}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
                <div className="flex items-center"></div>
            </div>
        </div>
    );
};
export default PriceSensitivityPercent;
