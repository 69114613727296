// Create customFetch function for handling re-authorization

import { devServer } from "./utils";

// This customFetch (or any fetch you pass to the link) gets uri and options as arguments. We'll use those when we actually execute a fetch.
const customFetch = async (uri, options) => {
    // No custom logic for SSR
    if (typeof window === "undefined") {
        const accessToken = localStorage.getItem("accessToken");
        // send normal request
        options.headers.authorization = `Bearer ${accessToken}`;
        return await fetch(uri, options);
    }

    // repeat the original request with new token
    const accessToken = localStorage.getItem("accessToken");
    options.headers.authorization = `Bearer ${accessToken}`;
    const req = await fetch(uri, options);
    const json = await req.json();

    // check for unauthorized error

    let isUnauthorized;

    // isUnauthorized = json.error.statusCode === "401";
    isUnauthorized =
        json?.error?.name === "AuthenticationError" || json?.error?.name === "AuthorizationError";

    // request completed as normal
    if (!isUnauthorized) {
        return {
            json: () => new Promise((resolve) => resolve(json)),
            text: () => new Promise((resolve) => resolve(JSON.stringify(json))),
            ok: true,
        };
    }

    if (!window.localStorage) {
    }
    // `accessToken` is expired, if this executes
    // get a new token
    const refreshToken = localStorage.getItem("refreshToken");

    const refreshResp = await fetch(`${devServer}/auth/sso/get-new-credentials`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken: refreshToken }),
    });

    // const refreshResp = await fetch(uri, refreshOptions);
    const rjson = await refreshResp.json();

    // validate and set new token
    if (rjson?.ok) {
        localStorage.setItem("accessToken", rjson.data.accessToken);
        localStorage.setItem("refreshToken", rjson.data.refreshToken);
        localStorage.setItem("idToken", rjson.data.idToken);
        // repeat the original request with new token
        options.headers.authorization = `Bearer ${rjson.data.accessToken}`;

        let response = await fetch(uri, options);

        if (response.status === 401) {
            localStorage.removeItem("idToken");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.location.replace("https://beta.partner.kunato.io/login");
        }

        return response;
    } else {
        localStorage.removeItem("idToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.replace("https://beta.partner.kunato.io/login");
        // Failed to renew accessToken

        // logout the user

        // return original failed response
        return {
            json: () => new Promise((resolve) => resolve(json)),
            text: () => new Promise((resolve) => resolve(JSON.stringify(json))),
            ok: true,
        };
    }
};

export default customFetch;
