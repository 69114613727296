import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

import { techData } from "components/shared/utils";

import bg from "assests/images/home-users.png";

import "odometer/themes/odometer-theme-minimal.css";
import "./style.css";

const Section3 = () => {
    const [data, setData] = useState(null);
    // Function to convert currency
    const techDataAPI = async () => {
        const response = await fetch(techData, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        // Storing data in form of JSON
        const data = await response.json();
        setData(data.data);
    };

    useEffect(() => {
        techDataAPI();
        setInterval(() => {
            techDataAPI();
        }, 5000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data) {
        return (
            <div
                className="w-full h-full flex flex-1 items-center justify-center bg-black"
                style={{
                    minHeight: "304px",
                }}
            ></div>
        );
    }

    return (
        <div
            className="w-full h-full flex flex-1 items-center justify-center"
            style={{
                backgroundColor: "#000",
            }}
        >
            <div className="w-full text-center flex items-center justify-center flex-col md:flex-row">
                <div
                    className="flex items-center justify-start flex-col w-full relative"
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        minHeight: "650px",
                        maxWidth: "714px",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute shadow-inner"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.4)",
                        }}
                    ></div>
                    <h1
                        className="font-bold mt-8 lg:mt-24 w-full user-data z-10 text-6xl lg:text-8xl pt-10 lg:pt-0"
                        style={{ color: "#00FF84" }}
                    >
                        <Odometer value={data?.usersEngaged} format="(,ddd)" />
                        <div className="text-3xl font-bold text-white mt-2 lg:-mt-1">
                            Users Worldwide{" "}
                        </div>
                    </h1>
                    <h1
                        className="lg:hidden mt-14 z-10 lg:text-4xl text-xl text-center font-medium mb-96 pb-36 text-white w-full lg:text-left lg:ml-20 px-10 lg:px-0"
                        style={{ maxWidth: "513px" }}
                    >
                        Pursuant to our partner-first philosophy, we have earned the trust of more
                        than{" "}
                        <span
                            className="font-bold text-xl lg:text-4xl"
                            style={{ color: "#00FF84" }}
                        >
                            500{" "}
                        </span>
                        partners. Leverage the power of future readiness and transform the landscape
                        of digital content.
                        {/* <span className="partner-span text-6xl font-bold">150+</span> */}
                    </h1>
                </div>

                <h1
                    className="hidden lg:block lg:text-4xl text-xl text-center font-medium mb-20 text-white w-full lg:text-left lg:ml-20 px-10 lg:px-0"
                    style={{ maxWidth: "513px" }}
                >
                    Pursuant to our partner-first philosophy, we have earned the trust of more than{" "}
                    <span className="font-bold text-xl lg:text-4xl" style={{ color: "#00FF84" }}>
                        500
                    </span>{" "}
                    partners. Leverage the power of future readiness and transform the landscape of
                    digital content.
                    {/* <span className="partner-span text-6xl font-bold">150+</span> */}
                </h1>
            </div>
        </div>
    );
};

export default Section3;
