import "./style.scss";

const Spinner = ({ text }) => {
    return (
        <div className="w-full min-h-full flex flex-col justify-center items-center">
            <div className="loader">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
                <div className="bar4"></div>
                <div className="bar5"></div>
                <div className="bar6"></div>
            </div>
            <h4 className="font-semibold text-xs mt-4 ml-6 dark:text-white">
                {text ? text : "Fetching Reports..."}
            </h4>
        </div>
    );
};

export default Spinner;
