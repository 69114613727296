import TiltedArrow from "components/shared/icons/tiltedArrow";
import EngagementRate from "./engagementRate";
import TopArticles from "./toparticles";
import { useEffect, useState } from "react";
import { articlePriceAPI } from "components/shared/utils";

const FirstRow = ({ newArticles, topArticles, difference, overallTopArticles, engagementData }) => {
    const [avgPrice, setAvgPrice] = useState(0);

    const FetchPrice = async () => {
        const urls = topArticles.map((item) => item.url);
        try {
            const response = await fetch(articlePriceAPI, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify({
                    currency: "usd",
                    urls: [...urls],
                }),
            });

            // Storing data in form of JSON
            let data = await response.json();
            setAvgPrice(() => {
                return (
                    Object.keys(data).reduce((acc, item) => acc + data[item], 0) /
                    Object.keys(data).length
                );
            });
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        if (topArticles?.length > 0) {
            FetchPrice();
        }
    }, [topArticles]);
    return (
        <div className="flex border-b border-b-[#383838] flex-col lg:flex-row">
            <div className="flex-[2] flex flex-col border-r border-r-[#383838]">
                <div className="flex">
                    <div className="px-5 lg:px-11 py-8 border-r border-r-[#383838] flex-1 flex flex-col justify-between">
                        <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                            NEW ARTICLES PUBLISHED
                        </div>
                        <div>
                            <div className="mt-[6.5625rem] text-white text-5xl font-[Poppins] font-semibold tracking-tight">
                                {newArticles?.toLocaleString()}
                            </div>
                            {difference?.total_new_articles && (
                                <div
                                    className={
                                        difference?.total_new_articles > 0
                                            ? "mt-3 flex items-center gap-1 text-[#92f6a8]"
                                            : "mt-3 flex items-center gap-1 text-[#f46a6a]"
                                    }
                                >
                                    <div
                                        className={
                                            difference?.total_new_articles > 0
                                                ? "flex items-center justify-center"
                                                : "flex items-center justify-center rotate-90"
                                        }
                                    >
                                        <TiltedArrow />
                                    </div>
                                    <div className="font-medium tracking-tight leading-5">
                                        {difference?.total_new_articles > 0 ? "+" : ""}
                                        {Math.trunc(difference?.total_new_articles)}% from last
                                        period
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="px-5 lg:px-11 py-8 border-r border-r-[#383838] flex-1 flex flex-col justify-between">
                        <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                            AVG. PRICE OF TOP PERFORMING ARTICLES
                        </div>
                        <div>
                            <div className="mt-[6.5625rem] text-white text-5xl font-[Poppins] font-semibold tracking-tight">
                                ${avgPrice.toFixed(2)}
                            </div>
                            <div className="mt-3 h-5 flex items-center gap-1 text-[#92f6a8]">
                                {/* <div className="flex items-center justify-center">
                                    <TiltedArrow />
                                </div>
                                <div className="font-medium tracking-tight leading-5">
                                    +3% from last period
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-5 lg:px-11 flex-1 min-h-[27rem] flex py-8 border-t border-t-[#383838]">
                    <EngagementRate engagementData={engagementData} />
                </div>
            </div>
            <div className="flex-[3] flex overflow-auto">
                <TopArticles topArticles={overallTopArticles?.slice(0, 10)} />
            </div>
        </div>
    );
};
export default FirstRow;
