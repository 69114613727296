import ArrowDown from "components/shared/icons/ArrowDown";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import TiltedArrow from "components/shared/icons/tiltedArrow";

const CategoryShift = ({ difference }) => {
    return (
        <div className="flex-1 flex flex-col">
            <div className="flex items-center justify-between">
                <div className="flex items-center text-white justify-between gap-2">
                    <div className=" text-lg font-medium ">Category Shift Variations</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>{" "}
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="mt-8 overflow-auto flex-1">
                <div className="flex text-sm gap-3 items-center bg-[rgba(255,255,255,0.1)] px-4 py-[0.625rem] text-[#7a7a7a] font-medium border-b border-b-[#383838]">
                    <div className="flex-[2]">Category</div>
                    {/* <div className="flex-1">Similar to</div> */}
                    <div className="flex-1">New Articles</div>
                    <div className="flex-1">Users</div>
                    <div className="flex-1">Engagement</div>
                    <div className="flex-1">Sessions</div>
                </div>
                <div>
                    {difference?.slice(0, 7).map((item, i) => (
                        <div
                            key={i}
                            className="flex gap-3 items-center px-4 py-3 text-white font-medium border-b border-b-[#383838]"
                        >
                            <div className="flex-[2] capitalize">
                                {item.category.replaceAll("_", " ")}
                            </div>
                            {/* <div className="flex-1">{item.totalMarketValue}</div> */}
                            <div
                                className={
                                    item.new_articles < 0
                                        ? "flex-1 text-[#f46a6a]"
                                        : "flex-1 text-[#92f6a8]"
                                }
                            >
                                {Math.round(item?.new_articles)}%
                            </div>
                            <div
                                className={
                                    item.unique_users < 0
                                        ? "flex-1 text-[#f46a6a]"
                                        : "flex-1 text-[#92f6a8]"
                                }
                            >
                                {Math.round(item?.unique_users)}%
                            </div>
                            <div
                                className={
                                    item.event_count < 0
                                        ? "flex-1 text-[#f46a6a]"
                                        : "flex-1 text-[#92f6a8]"
                                }
                            >
                                {Math.round(item?.event_count)}%
                            </div>
                            <div
                                className={
                                    item.sessions < 0
                                        ? "flex-1 text-[#f46a6a]"
                                        : "flex-1 text-[#92f6a8]"
                                }
                            >
                                {Math.round(item?.sessions)}%
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-[#2a8cff] px-4 py-3 flex items-center gap-1">
                    View More{" "}
                    <div className="flex items-center justify-center text-2xl">
                        <ArrowDown />
                    </div>{" "}
                </div>
            </div>
        </div>
    );
};
export default CategoryShift;
