import React, { useEffect, useRef } from "react";

import Close from "./icons/Close";

/**
 * Mobile modal
 * @prop {Function} closeModal - Function to close modal

 */

const MobileModal = ({ closeModal }) => {
    const modalRef = useRef();

    // Logic added to remove scroll when modal is open
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                overflowY: "auto",
            }}
        >
            <div
                className="bg-theme-gray dark:bg-black rounded-2xl border border-light-blue dark:order-dark-Blue"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "366px",
                    transform: " translate(-50%, -50%)",
                }}
                // ref={modalRef}
            >
                <div className="pl-10 pr-8 py-11 relative" onClick={() => closeModal()}>
                    <p className="absolute right-4 top-4 cursor-pointer">
                        <Close />
                    </p>
                    <h1 className="text-xl font-bold mb-6 text-left dark:text-white">Important</h1>
                    <p className="text-base font-normal text-gray-500 text-left dark:text-white mb-1.5">
                        For a better viewing experience check out the desktop version.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MobileModal;
