import ArrowDown from "components/shared/icons/ArrowDown";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import TiltedArrow from "components/shared/icons/tiltedArrow";

const TopArticles = ({ topArticles }) => {
    // const dummy = [
    //     {
    //         name: " Winter Wellness: Staying Healthy During the Holidays",
    //         totalMarketValue: "$30,000",
    //         totalView: "142,660,000",
    //         lastUpdate: "09/19/2023",
    //     },
    //     {
    //         name: " Seasonal Splendor: Breathtaking Christmas Light Displays",
    //         totalMarketValue: "$22,000",
    //         totalView: "6,000,000",
    //         lastUpdate: "09/01/2023",
    //     },
    //     {
    //         name: " Festive Films: Top Christmas Movies to Watch This Season",
    //         totalMarketValue: "$19,000",
    //         totalView: "3,000,000",
    //         lastUpdate: "09/01/2023",
    //     },
    //     {
    //         name: " Santa's Helpers: The Best Christmas Shopping Apps",
    //         totalMarketValue: "$10,000",
    //         totalView: "10,000,000",
    //         lastUpdate: "12/02/2023",
    //     },
    //     {
    //         name: " The Art of Giving: Heartfelt Homemade Christmas Gifts",
    //         totalMarketValue: "$24,000",
    //         totalView: "15,000,000",
    //         lastUpdate: "12/16/2023",
    //     },
    //     {
    //         name: " Merry and Bright: Creative Christmas Lighting Ideas",
    //         totalMarketValue: "$31,000",
    //         totalView: "10,000,000",
    //         lastUpdate: "10/16/2023",
    //     },
    //     {
    //         name: " Festive Films: Top Christmas Movies to Watch This Season",
    //         totalMarketValue: "$19,000",
    //         totalView: "3,000,000",
    //         lastUpdate: "09/01/2023",
    //     },
    //     {
    //         name: " Tech the Halls: Must-Have Gadgets for Christmas",
    //         totalMarketValue: "$34,000",
    //         totalView: "18,000,000",
    //         lastUpdate: "11/16/2023",
    //     },
    //     {
    //         name: " Winter Wonderland: Top Christmas Destinations for Snow Lovers",
    //         totalMarketValue: "$41,000",
    //         totalView: "18,000,000",
    //         lastUpdate: "11/14/2023",
    //     },
    //     {
    //         name: " The Art of Giving: Heartfelt Homemade Christmas Gifts",
    //         totalMarketValue: "$24,000",
    //         totalView: "15,000,000",
    //         lastUpdate: "12/16/2023",
    //     },
    // ];
    return (
        <div className="flex-1 p-5 py-8 lg:p-8 border-t border-t-[#383838] lg:border-t-0">
            <div className="flex items-center justify-between">
                <div className="flex items-center text-white justify-between gap-2">
                    <div className=" text-lg font-medium ">Top Performing Articles</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>{" "}
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="mt-8 overflow-auto">
                <div className="flex text-sm gap-3 items-center bg-[rgba(255,255,255,0.1)] px-4 py-[0.625rem] text-[#7a7a7a] font-medium border-b border-b-[#383838]">
                    <div className="w-6">№</div>
                    <div className="flex-[7]">Name</div>
                    <div className="flex-[2]">Total Users</div>
                    <div className="flex-[2]">Total views</div>
                    {/* <div className="flex-[2]">Last update</div> */}
                    <div className="flex-1">Link</div>
                </div>
                <div>
                    {topArticles?.map((item, i) => (
                        <div
                            key={i}
                            className="flex gap-3 items-center px-4 py-3 text-white font-medium border-b border-b-[#383838]"
                        >
                            <div className="w-6">{i + 1}</div>
                            <div className="flex-[7] whitespace-nowrap text-ellipsis overflow-hidden">
                                {item?.title
                                    ? item?.title?.substring(0, 60)
                                    : item?.url?.substring(0, 60)}
                            </div>
                            <div className={"flex-[2]"}>{item?.unique_users.toLocaleString()}</div>
                            <div className="flex-[2]">{item.pageviews.toLocaleString()}</div>
                            {/* <div className="flex-[2]">{item.lastUpdate}</div> */}
                            <a
                                target="_blank"
                                href={item.url}
                                className="flex-1 flex items-center gap-1 text-[#2a8cff]"
                            >
                                Visit{" "}
                                <div>
                                    {" "}
                                    <TiltedArrow />{" "}
                                </div>{" "}
                            </a>
                        </div>
                    ))}
                </div>
                <div className="text-[#2a8cff] px-4 py-3 flex items-center gap-1">
                    View More{" "}
                    <div className="flex items-center justify-center text-2xl">
                        <ArrowDown />
                    </div>{" "}
                </div>
            </div>
        </div>
    );
};
export default TopArticles;
