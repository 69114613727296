import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { stratify, pack } from "d3-hierarchy";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";

const SimilarCategories = () => {
    const [options, setOptions] = useState({});
    const [options2, setOptions2] = useState({});
    const [circle, setCircle] = useState(false);
    const data = [
        {
            name: "Movies",
            value: 23548,
        },
        {
            name: "Theatre",
            value: 12547,
        },
        {
            name: "Actors",
            value: 20485,
        },
        {
            name: "TV Shows",
            value: 20598,
        },
        {
            name: "Music",
            value: 19587,
        },
        {
            name: "Production Houses",
            value: 19458,
        },
    ].sort((prev, curr) => {
        return prev.value < curr.value ? 1 : -1;
    });
    const color = ["#CFB1EB", "#33B4DD", "#33DD9B", "#F7DD0C", "#FF7E00", "#B1E8EB"];

    const seriesData = [
        {
            id: "Similar Categories",
            depth: 0,
            index: 0,
        },
        {
            id: "Movies",
            value: 23548,
            depth: 1,
            index: 1,
        },

        {
            id: "TV Shows",
            value: 20485,
            depth: 1,
            index: 2,
        },
        {
            id: "Actors",
            value: 20598,
            depth: 1,
            index: 3,
        },
        {
            id: "Music",
            value: 19587,
            depth: 1,
            index: 4,
        },
        {
            id: "Production Houses",
            value: 19458,
            depth: 1,
            index: 5,
        },
        {
            id: "Theatre",
            value: 12547,
            depth: 1,
            index: 6,
        },
    ].sort((prev, curr) => {
        return !curr.value ? 1 : prev.value < curr.value ? 1 : -1;
    });

    function overallLayout(params, api) {
        const context = params.context;
        const displayRoot = stratifyFn();
        function stratifyFn() {
            return stratify()
                .parentId(function (d) {
                    // return d.id.substring(0, d.id.lastIndexOf("."));
                    return d.id === "Similar Categories" ? "" : "Similar Categories";
                })(seriesData)
                .sum(function (d) {
                    return d.value || 0;
                })
                .sort(function (a, b) {
                    return b.value - a.value;
                });
        }
        pack()
            .size([api.getWidth() - 2, api.getHeight() - 2])
            .padding(3)(displayRoot);
        context.nodes = {};
        displayRoot.descendants().forEach(function (node, index) {
            context.nodes[node.id] = node;
        });
    }
    function renderItem(params, api) {
        var context = params.context;
        // Only do that layout once in each time `setOption` called.
        if (!context.layout) {
            context.layout = true;
            overallLayout(params, api);
        }
        var nodePath = api.value("id");
        var node = context.nodes[nodePath];
        if (!node) {
            // Reder nothing.
            return;
        }
        var isLeaf = !node.children || !node.children.length;
        var focus = new Uint32Array(
            node.descendants().map(function (node) {
                return node.data.index;
            })
        );
        var nodeName = isLeaf
            ? nodePath
                  .slice(nodePath.lastIndexOf(".") + 1)
                  .split(/(?=[A-Z][^A-Z])/g)
                  .join("\n")
            : "";
        var z2 = api.value("depth") * 2;
        return {
            type: "circle",
            focus: focus,
            shape: {
                cx: node.x,
                cy: node.y,
                r: node.r,
            },
            transition: ["shape"],
            z2: z2,
            textContent: {
                type: "text",
                style: {
                    // transition: isLeaf ? 'fontSize' : null,
                    text: nodeName,
                    fontFamily: "Roboto",
                    color: "#000",
                    weight: 600,
                    width: node.r * 1.3,
                    overflow: "truncate",
                    fontSize: node.r / 3,
                },
                emphasis: {
                    style: {
                        overflow: null,
                        fontSize: Math.max(node.r / 3, 12),
                    },
                },
            },
            textConfig: {
                position: "inside",
            },
            style: {
                fill: api.visual("color"),
            },
            emphasis: {
                style: {
                    fontFamily: "Roboto",
                    fontSize: "0.75rem",
                    shadowBlur: 20,
                    shadowOffsetX: 3,
                    shadowOffsetY: 5,
                    shadowColor: "rgba(0,0,0,0.3)",
                },
            },
        };
    }

    useEffect(() => {
        setTimeout(() => {
            setOptions({
                dataset: {
                    source: seriesData,
                },
                // tooltip: {},
                // visualMap: [
                //     {
                //         show: false,
                //         min: 0,
                //         max: 1,
                //         dimension: "depth",
                //         inRange: {
                //             color: ["#006edd", "#e0ffff"],
                //         },
                //     },
                // ],
                hoverLayerThreshold: Infinity,
                series: {
                    type: "custom",
                    renderItem: renderItem,
                    progressive: 0,
                    coordinateSystem: "none",
                    colorBy: "data",
                    encode: {
                        tooltip: "value",
                        itemName: "id",
                    },
                },
                color: [
                    "#141414",
                    "#CFB1EB",
                    "#33B4DD",
                    "#33DD9B",
                    "#F7DD0C",
                    "#FF7E00",
                    "#B1E8EB",
                ],
            });
        }, 150);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setOptions2({
                grid: {
                    top: "0%",
                    bottom: "0%",
                    left: "0%",
                    right: "0%",
                },
                legend: {
                    bottom: "5%",
                    icon: "roundRect",
                    textStyle: {
                        color: "white",
                    },
                },
                series: [
                    {
                        width: "100%",
                        height: "100%",
                        type: "treemap",
                        roam: "move",
                        nodeClick: false,
                        breadcrumb: {
                            show: false,
                        },
                        label: {
                            color: "black",
                            fontWeight: "600",
                            fontFamily: "Roboto",
                            fontSize: "1.35rem",
                            overflow: "break",
                        },
                        data: data,
                    },
                ],
                color: ["#CFB1EB", "#33B4DD", "#33DD9B", "#F7DD0C", "#FF7E00", "#B1E8EB"],
            });
        }, 150);
    }, []);

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">Similar Categories</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button
                        onClick={() => {
                            setCircle((prevState) => !prevState);
                        }}
                        className="flex items-center justify-center text-2xl text-[#cacaca]"
                    >
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1 flex flex-col">
                <div className="flex items-center flex-wrap gap-y-4 mb-4">
                    {data.map((item, i) => (
                        <div
                            key={i}
                            className={
                                i === 0
                                    ? "flex items-center px-4 gap-2 text-white"
                                    : "flex items-center px-4 gap-2 text-white border-l-2 border-l-[#383838]"
                            }
                        >
                            <div
                                className={`p-2 rounded`}
                                style={{ backgroundColor: `${color[i]}` }}
                            ></div>{" "}
                            <div>
                                {item.name} - {item.value}
                            </div>
                        </div>
                    ))}
                </div>
                {circle ? (
                    <div className="flex-1">
                        {options && (
                            <ReactECharts
                                option={options}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="flex-1">
                        {options2 && (
                            <ReactECharts
                                option={options2}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SimilarCategories;
