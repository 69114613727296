import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import Spinner from "../shared/spinner";
import { verifyAuthCode } from "components/shared/API/sso";

const RedirectSSO = () => {
    const codeConsumed = useRef(false);
    const paramKey = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    const code = paramKey?.authCode;

    const navigate = useNavigate();

    const redirect_uri = localStorage.getItem("redirect_uri");

    const verifyCode = async () => {
        if (!code) return;

        codeConsumed.current = true;
        const data = await verifyAuthCode(code);
        if (data.ok) {
            localStorage.setItem("accessToken", data.data.accessToken);
            localStorage.setItem("refreshToken", data.data.refreshToken);
            localStorage.setItem("idToken", data.data.idToken);
            navigate("/monitor2/content-analysis");
        } else {
            window.location.href = `https://auth.kunato.ai/redirect?${redirect_uri}&origin=${window.location.origin}`;
        }
    };

    useEffect(() => {
        if (!codeConsumed.current && !localStorage.getItem("accessToken")) {
            verifyCode();
        } else {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    return (
        <div className="flex items-center justify-center w-full min-h-screen h-full bg-[#010101]">
            <Spinner />
        </div>
    );
};

export default RedirectSSO;
