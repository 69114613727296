import CountriesISO from "assests/countriesISO";
import customFetch from "components/shared/customFetch";
import ArrowDown from "components/shared/icons/ArrowDown";
import { devServer } from "components/shared/utils";

const { useEffect, useState } = require("react");

const UserData = ({ selectedArticle }) => {
    const [data, setData] = useState([]);
    const [lastEventTime, setLastEvent] = useState("");
    const [pageno, setPageno] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);

    const pageLength = 20;
    const getData = async (extend) => {
        try {
            const resp = await customFetch(`${devServer}/get-user-events`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    url: selectedArticle.urls[0],
                    limit: pageLength,
                    from: lastEventTime,
                }),
            });
            const res = await resp.json();
            if (!res.error) {
                setData((prevState) => {
                    return extend ? [...prevState, ...res.data] : [...res.data];
                });
                if (!extend && res.data.length === 0) {
                    setIsEmpty(true);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (selectedArticle?.urls?.length > 0) {
            setData([]);
            setIsEmpty(false);
            if (lastEventTime === "") {
                getData();
                setPageno(0);
            } else {
                setLastEvent("");
                setPageno(0);
            }
        }
    }, [selectedArticle]);
    useEffect(() => {
        if (lastEventTime !== "") {
            getData(true);
        }
    }, [lastEventTime]);

    return (
        <>
            {isEmpty ? (
                <div className="h-full flex items-center justify-center">
                    <div className="text-white text-2xl text-center">
                        No user interactions on the article detected
                    </div>
                </div>
            ) : (
                <div className="flex flex-1">
                    <div className="flex-1 p-4 border border-[#383838] flex flex-col text-white">
                        <div className="text-2xl font-bold mb-4">View Events</div>
                        <div className="py-1 bg-[rgba(255,255,255,0.05)] text-[rgba(122,122,122,1)] font-semibold flex items-center border-b border-b-[#383838]">
                            <div className="flex-1 px-4">Time</div>
                            <div className="flex-1 px-4">IP</div>
                            <div className="flex-1 px-4">Duration</div>
                            <div className="flex-1 px-4">Device Type</div>
                            <div className="flex-1 px-4">OS</div>
                            <div className="flex-1 px-4">Country</div>
                            <div className="flex-1 px-4">Region</div>
                            <div className="flex-1 px-4">City</div>
                        </div>
                        {data?.map((item, i) => (
                            <div
                                key={i}
                                className="py-2 border-b border-b-[#383838] font-semibold flex items-center rounded"
                            >
                                <div className="flex-1 px-4 whitespace-nowrap">
                                    {item?.created_at
                                        ? new Date(item.created_at).toLocaleString()
                                        : "N/A"}
                                </div>
                                <div className="flex-1 px-4">{item.ip ? item.ip : "N/A"}</div>
                                <div className="flex-1 px-4">
                                    {`${item.duration}` ? `${item.duration}s` : "N/A"}
                                </div>
                                <div className="flex-1 px-4 capitalize">
                                    {item.device_type ? item.device_type : "N/A"}
                                </div>
                                <div className="flex-1 px-4 capitalize">
                                    {item.os ? item.os : "N/A"}
                                </div>
                                <div className="flex-1 px-4">
                                    {item.country ? CountriesISO[item.country] : "N/A"}
                                </div>
                                <div className="flex-1 px-4 capitalize">
                                    {item.region ? item.region : "N/A"}
                                </div>
                                <div className="flex-1 px-4 capitalize">
                                    {item.city ? item.city : "N/A"}
                                </div>
                            </div>
                        ))}

                        {data?.length < pageLength * (pageno + 1) ? null : (
                            <button
                                disabled={data?.length < pageLength * (pageno + 1)}
                                onClick={() => {
                                    setPageno((prevState) => prevState + 1);
                                    setLastEvent(data[data.length - 1].created_at);
                                }}
                                className="text-[#2a8cff] px-4 py-3 flex items-center justify-end gap-1"
                            >
                                View More{" "}
                                <div className="flex items-center justify-center text-2xl">
                                    <ArrowDown />
                                </div>{" "}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default UserData;
