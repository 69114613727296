const ExpandIcon = () => {
    return (
        <svg height="1em" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.9603 11.9211V5.52637H13.5655"
                stroke="currentColor"
                strokeWidth="2.13158"
            />
            <path
                d="M5.74982 13.3426L5.74982 19.7373L12.1446 19.7373"
                stroke="currentColor"
                strokeWidth="2.13158"
            />
            <path
                d="M19.9603 5.52637L14.2761 11.2106"
                stroke="currentColor"
                strokeWidth="2.13158"
            />
            <path
                d="M11.4339 14.0527L5.74973 19.7369"
                stroke="currentColor"
                strokeWidth="2.13158"
            />
        </svg>
    );
};
export default ExpandIcon;
