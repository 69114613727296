import CorrelationTrends from "./correlationTrends";
import PPCOverTime from "./ppcOvertime";

const FifthRow = ({ engagementData, topArticles }) => {
    return (
        <div className="hidden lg:flex flex-col lg:flex-row border-b border-b-[#383838]">
            <div className="flex-[2] flex flex-col border-r border-r-[#383838]">
                <div className="px-2 lg:px-11 flex-1 min-h-[34rem] flex py-8 border-t border-t-[#383838]">
                    <CorrelationTrends engagementData={engagementData} />
                </div>
            </div>
            <div className="flex-[3] lg:flex ">
                <PPCOverTime topArticles={topArticles} />
            </div>
        </div>
    );
};
export default FifthRow;
