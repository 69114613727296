import React from "react";
import Component from "../Prices";
import bg2 from "assests/images/homesection7/step3bg2.png";
import pub1 from "assests/images/homesection7/step3p2.png";

const SlideStep10 = ({ articlePrices }) => {
    const url =
        "https://popdiaries.com/2022/09/06/popdiaries-flashes-the-spotlight-on-ai-based-entertainment-content-valuation/";

    const price = articlePrices[url];
    return (
        <div className="flex w-full items-center justify-between mb-4 min-w-165 max-w-169 lg:min-w-270 lg:max-w-270 overflow-hidden rounded-xl">
            <a
                href="https://popdiaries.com/2022/09/06/popdiaries-flashes-the-spotlight-on-ai-based-entertainment-content-valuation/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col bg-white max-w-270 items-center justify-start text-center pb-4 h-72"
                style={{ borderRadius: "18px" }}
            >
                <div
                    className="mb-3 min-h-147 w-165 lg:w-270 flex items-end justify-end relative"
                    style={{
                        backgroundImage: `url(${bg2})`,
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "scroll",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <div
                        className="w-full h-full absolute"
                        style={{
                            backgroundColor: "rgba(0,0,0,0.2)",
                        }}
                    ></div>{" "}
                    <div
                        className="w-full h-1/2 absolute"
                        style={{
                            background: "linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)",
                        }}
                    ></div>
                    {price && <Component price={price} url={url} idKey={url} />}
                </div>
                <h5 className=" text-black text-sm font-bold mb-4 lg:mb-10 px-4 leading-4">
                    PopDiaries Flashes The Spotlight On AI-Based Entertainment Content Valuation!
                </h5>
                <img src={pub1} alt="img1" className="w-20 mb-3" />
            </a>
        </div>
    );
};

export default SlideStep10;
