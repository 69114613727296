import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";

const EngagementRate = ({ engagementData }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        let data = {};
        engagementData?.forEach((item) => {
            if (!data[item.date]) {
                data[item.date] = {};
            }
            data[item.date][item.category] = item.event_count;
        });
        let dimensions = ["time"];
        let graphData = Object.keys(data).map((item) => {
            let a = [+new Date(item)];
            Object.keys(data[item])
                .sort()
                .map((key) => {
                    if (!dimensions.includes(key.replaceAll("_", " "))) {
                        dimensions.push(key.replaceAll("_", " "));
                    }
                    a.push(data[item][key]);
                });
            return a;
        });
        setTimeout(() => {
            setOptions({
                legend: {
                    bottom: "5%",
                    icon: "roundRect",
                    textStyle: {
                        color: "white",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "15%",
                    top: "5%",
                    containLabel: true,
                },

                xAxis: {
                    type: "time",
                    boundaryGap: ["5%", "0%"],

                    axisLabel: {
                        color: "#999",
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                },
                yAxis: {
                    boundaryGap: ["5%", "0%"],
                    min: (value) => {
                        return Math.round(value.min * 0.85);
                    },
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.1)",
                        },
                        axisLabel: {
                            color: "#999",
                            showMinLabel: false,
                        },
                    },
                },
                tooltip: {
                    show: true,
                    trigger: "item",
                },
                series: [
                    {
                        name: dimensions[1],
                        type: "line",
                        symbolSize: 1,
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of engagements: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                        encode: {
                            x: "time",
                            y: dimensions[1],
                        },
                    },
                    {
                        name: dimensions[2],
                        type: "line",
                        symbolSize: 1,
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of engagements: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                        encode: {
                            x: "time",
                            y: dimensions[2],
                        },
                    },
                    {
                        name: dimensions[3],
                        type: "line",
                        symbolSize: 1,
                        tooltip: {
                            backgroundColor: "#3d3d3d",
                            borderWidth: 0,
                            formatter: function (params, ticket, callback) {
                                return `<div class="font-bold text-white">${new Date(
                                    params.value[0]
                                ).toLocaleDateString(
                                    "en-UK"
                                )}</div><div class="text-white font-medium"> # of engagements: <b> 
                                    ${
                                        params.value[
                                            params.dimensionNames.findIndex(
                                                (item) => item === `${params.seriesName}`
                                            )
                                        ]
                                    } <b></div>`;
                            },
                        },
                        encode: {
                            x: "time",
                            y: dimensions[3],
                        },
                    },
                ],
                dataset: {
                    source: graphData,

                    dimensions: dimensions,
                },
                color: ["#92f6a8", "#df8bcd", "#249bed"],
            });
        }, 150);
    }, [engagementData]);

    return (
        <div className="flex-1 flex flex-col">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">Engagement Rate</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1">
                {options && (
                    <ReactECharts
                        option={options}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
                <div className="flex items-center"></div>
            </div>
        </div>
    );
};

export default EngagementRate;
