import React, { useEffect, useState } from "react";

import SlideStep1 from "./Steps/Step1";
import SlideStep2 from "./Steps/Step2";
import SlideStep3 from "./Steps/Step3";
import SlideStep4 from "./Steps/Step4";
import SlideStep5 from "./Steps/Step5";
import SlideStep6 from "./Steps/Step6";
import SlideStep7 from "./Steps/Step7";
import SlideStep9 from "./Steps/Step9";
import SlideStep10 from "./Steps/Step10";
import SlideStep11 from "./Steps/Step11";
import SlideStep12 from "./Steps/Step12";

import ChevronLeft from "components/shared/icons/ChevronLeft";
import ChevronRight from "components/shared/icons/ChevronRight";

import { articlePriceAPI } from "components/shared/utils";

import "./style.css";

let articleURLList = [
    "https://www.hellomumbainews.com/current-news/hello-mumbai-says-hello-to-pricing/",
    "https://ibgnews.com/2022/09/29/see-the-latest-11-deal-on-ibg-news-find-the-true-value-of-the-content-you-consume-on-ibg-news/",
    "https://democracynewslive.com/technology/value-is-the-voice-of-content-1160105",
    "https://www.newznew.com/whats-new-with-newznew/",
    "https://jantaserishta.com/national/growing-steps-of-relationship-with-the-public-in-the-world-of-ai-1457499",
    "https://suspensecrime.com/Business/How-to-Leave-Suspense-Behind-with-Content-Pricing-Powered-b/cid8375039.htm",
    "https://templeknowledge.com/past-meets-future-with-the-power-of-ai/",
    // "https://www.livehyd.com/2022/08/is-this-website-powered-by-ai/",
    "https://statusmarkets.in/inside-look-into-the-latest-ai-technology/",
    "https://popdiaries.com/2022/09/06/popdiaries-flashes-the-spotlight-on-ai-based-entertainment-content-valuation/",
    "https://trend-rays.com/2022/09/guess-whats-trending-on-trend-rays.html",
    "https://www.wittyflick.com/2022/09/make-witty-move-and-use-ai-to-consume-content.html",
];

const Section6 = () => {
    const [step, setStep] = useState(0);
    const [components] = useState([
        SlideStep1,
        SlideStep2,
        SlideStep3,
        SlideStep4,
        SlideStep5,
        SlideStep6,
        SlideStep7,
        // SlideStep8,
        SlideStep9,
        SlideStep10,
        SlideStep11,
        SlideStep12,
    ]);
    const [articlePrices, setArticlePrices] = useState([]);

    const delay = 4500;

    const handleStep = (direction) => {
        if (direction === "plus") {
            if (window.innerWidth < 1024) {
                if (step <= 9) {
                    setStep((prevState) => prevState + 1);
                } else {
                    setStep(0);
                }
            } else {
                if (step <= 6) {
                    setStep((prevState) => prevState + 1);
                } else {
                    setStep(0);
                }
            }
        } else if (direction === "minus") {
            if (step >= 1) {
                setStep((prevState) => prevState - 1);
            } else {
                setStep(0);
            }
        }
    };

    // Function to get prices of the urls
    const getPrices = async () => {
        try {
            const _articleList = {};
            // Storing response
            const response = await fetch(articlePriceAPI, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify({
                    currency: "inr",
                    urls: articleURLList,
                }),
            });

            // Storing data in form of JSON
            var data = await response.json();

            articleURLList.forEach((url) => {
                _articleList[url] = data[url];
            });

            setArticlePrices(_articleList);
        } catch (err) {
            return {};
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            getPrices();
        }, 3000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStep((prevState) => prevState + 1);
            if (window.innerWidth < 1024) {
                if (step >= 9) {
                    setStep(0);
                }
            } else {
                if (step >= 7) {
                    setStep(0);
                }
            }
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [step]);

    return (
        <div
            className="w-full h-full flex flex-1 overflow-hidden"
            style={{
                minHeight: "650px",
                backgroundColor: "#000E1C",
            }}
        >
            <div
                className="w-ful h-full flex flex-1 items-center justify-center pb-5 relative"
                style={{ backgroundColor: "rgba(0,0,0,0.3)", minHeight: "650px" }}
            >
                <div
                    style={{ top: "320px" }}
                    className="flex items-center w-full justify-between absolute max-w-400 md:max-w-700 lg:max-w-1200"
                >
                    <p
                        onClick={() => handleStep("minus", step)}
                        className="cursor-pointer w-9 h-9 rounded-full text-center flex items-center justify-center text-2xl"
                    >
                        <span className="">
                            <ChevronLeft />
                        </span>
                    </p>
                    <p
                        onClick={() => handleStep("plus", step)}
                        className="cursor-pointer w-9 h-9 rounded-full text-center flex items-center justify-center text-2xl"
                    >
                        <span className="">
                            <ChevronRight />
                        </span>
                    </p>
                </div>
                <div className="w-full text-center flex flex-col items-center overflow-hidden max-w-425 md:max-w-600 lg:max-w-1160">
                    <h1 className="lg:text-4xl text-2xl font-bold mb-20 text-white w-full lg:max-w-920">
                        Media Coverage
                    </h1>

                    <div
                        className="w-full flex items-center mb-9 justify-start slide-show-pub max-w-1160"
                        style={
                            window.innerWidth < 1024
                                ? {
                                      transform: `translateX(-${step * 185}px)`,
                                  }
                                : {
                                      transform: `translateX(-${step * 290}px)`,
                                  }
                        }
                    >
                        {components.map((Comp, index) => (
                            <div className="mx-2.5" key={index}>
                                <Comp key={index} articlePrices={articlePrices} />
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-center gap-2 list-none w-full max-w-270">
                        <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step < 2 ? "bg-animation" : ""}></div>
                        </li>
                        <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step === 2 || step === 3 ? "bg-animation" : ""}></div>
                        </li>
                        <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step === 4 || step === 5 ? "bg-animation" : ""}></div>
                        </li>
                        <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step === 6 || step === 7 ? "bg-animation" : ""}></div>
                        </li>
                        {/* <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step > 7 ? "bg-animation2" : ""}></div>
                        </li> */}
                        {/* <li className="w-12 h-2 bg-blue10 rounded-xl">
                            <div className={step > 4 || step < 5 ? "bg-animation" : ""}></div>
                        </li> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section6;
