const HomeFeature5 = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="black" />
            <path
                d="M21.7173 36V32.8584C19.4636 32.7587 17.279 32.1634 16 31.4358L17.0105 27.5641C18.4234 28.3239 20.4056 29.0189 22.5933 29.0189C24.51 29.0189 25.8217 28.2912 25.8217 26.9683C25.8217 25.7098 24.7457 24.9152 22.2568 24.0879C18.6591 22.8973 16.203 21.2432 16.203 18.0342C16.203 15.1216 18.2894 12.8395 21.8875 12.1446V9H25.1814V11.9126C27.4351 12.0122 28.9493 12.4732 30.058 13.0036L29.0833 16.7434C28.2098 16.3796 26.6629 15.6173 24.2395 15.6173C22.0543 15.6173 21.3481 16.5442 21.3481 17.4711C21.3481 18.5621 22.5253 19.2575 25.3839 20.3163C29.3846 21.7067 31 23.5253 31 26.5053C31 29.4501 28.8808 31.9642 25.0147 32.6269V36H21.7173Z"
                fill="#00FFFF"
            />
        </svg>
    );
};

export default HomeFeature5;
