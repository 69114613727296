import SearchIcon from "components/shared/icons/search";
import { useState } from "react";

const DetailsSecHeader = () => {
    const options = [
        "OVERVIEW",
        "CONTENT PERFORMANCE",
        "USER INTERACTION",
        "ENGAGEMENT ANALYSIS",
        "PRICING METRICS",
        "COMPARISON",
    ];
    const [selectedOption, setSelectedOption] = useState("OVERVIEW");
    return (
        <div className="px-5 lg:px-11 border-b border-b-[#383838]">
            <div className="pt-10 flex items-center justify-between">
                <div className="text-white text-[2rem] font-semibold">Detailed Analysis</div>
                <button className="p-3 text-2xl text-[#cacaca] bg-[#3f3f3f] rounded flex items-center justify-center">
                    <SearchIcon />{" "}
                </button>
            </div>
            <div className="mt-11 overflow-auto flex items-center gap-12">
                {options.map((item) => (
                    <button
                        key={item}
                        className={
                            selectedOption === item
                                ? "text-white font-medium border-b-2 pb-4 whitespace-nowrap"
                                : "font-medium border-b-2 border-b-transparent pb-4 whitespace-nowrap text-[#999]"
                        }
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default DetailsSecHeader;
