const PriceTag = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="black" />
            <rect x="10" y="24" width="6" height="11" fill="#00FFFF" />
            <rect x="19" y="18" width="6" height="17" fill="#00FFFF" />
            <rect x="28" y="12" width="6" height="23" fill="#00FFFF" />
        </svg>
    );
};

export default PriceTag;
