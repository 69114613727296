import MoreDetails from "./moreDetails";
import PPCsection from "./ppc";

const MidSection = ({ selected, setSelected, options, selectedArticle, selectedSimilar }) => {
    return (
        <div className="flex-1 flex flex-col gap-4 items-center p-4 overflow-auto relative">
            <PPCsection
                selected={selected}
                selectedArticle={selectedArticle}
                selectedSimilar={selectedSimilar}
            />
            {/* <MoreDetails /> */}
            {selected === "" && (
                <div className="flex-1 w-11/12 px-4">
                    <div className="flex item-center gap-4">
                        {options.map((item, i) => (
                            <button
                                key={i}
                                disabled={selectedArticle ? false : true}
                                onClick={() => {
                                    if (selected === item) {
                                        setSelected("");
                                    } else {
                                        setSelected(item);
                                    }
                                }}
                                className="p-2 rounded font-medium text-white border border-[#0485ff]"
                                style={
                                    selected === item
                                        ? { backgroundColor: "#0485ff" }
                                        : { backgroundColor: "transparent" }
                                }
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
export default MidSection;
