import customFetch from "components/shared/customFetch";
import ArrowDown from "components/shared/icons/ArrowDown";
import { articlePriceAPI, devServer } from "components/shared/utils";
import { useEffect, useState } from "react";

const SimilarArticles = ({ selectedArticle, setSelectedSimilar }) => {
    const [list, setList] = useState([]);
    const [urlList, setUrlList] = useState([]);
    const [prices, setPrices] = useState({});
    const [pageno, setPageno] = useState(0);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const pageLength = 10;

    const getSimilarArticlesList = async (extend) => {
        try {
            const resp = await customFetch(`${devServer}/get-similar-articles-for-url`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    url: selectedArticle.urls[0],
                    page: pageno,
                    limit: pageLength,
                }),
            });
            const res = await resp.json();
            if (res.ok) {
                setList((prevState) => {
                    return prevState.length > pageLength * pageno
                        ? [...prevState]
                        : [...prevState, ...res.articles];
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchPrice = async () => {
        try {
            const response = await fetch(articlePriceAPI, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify({
                    currency: "usd",
                    urls: [...urlList],
                }),
            });

            // Storing data in form of JSON
            let data = await response.json();
            setPrices(data);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        setSelectedIndices([]);
        setList([]);
        if (pageno > 0) {
            setPageno(0);
        } else {
            if (selectedArticle?.urls.length > 0) {
                getSimilarArticlesList();
            }
            setPageno(0);
        }
    }, [selectedArticle]);
    useEffect(() => {
        if (selectedArticle?.urls.length > 0 && pageno > 0) {
            getSimilarArticlesList();
        }
    }, [selectedArticle, pageno]);

    useEffect(() => {
        setUrlList(() => {
            return list.map((item) => item.urls[0]);
        });
    }, [list]);

    useEffect(() => {
        FetchPrice();
    }, [urlList]);

    useEffect(() => {
        setSelectedSimilar(() => {
            return selectedIndices.map((item) => {
                return list[item];
            });
        });
    }, [selectedIndices]);

    return (
        <div className="flex flex-col">
            {list.map((item, i) => (
                <div
                    key={i}
                    className="px-5 py-3 text-white flex gap-4  items-center bg-neutral-900 opacity-90 border-t border-t-zinc-600 border-b border-b-zinc-600 rounded "
                >
                    <div>
                        <input
                            disabled={selectedIndices.length > 2 && !selectedIndices.includes(i)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    if (selectedIndices.length < 3) {
                                        setSelectedIndices((prevState) => [...prevState, i]);
                                    }
                                } else {
                                    setSelectedIndices((prevState) => {
                                        const temp = [...prevState];
                                        temp.splice(
                                            temp.findIndex((item) => item === i),
                                            1
                                        );
                                        return temp;
                                    });
                                }
                            }}
                            type="checkbox"
                            style={{ display: "block" }}
                            // checked={selectedIndices.includes(i)}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="text-lg leading-6 max-h-[4.5rem] overflow-hidden">
                            {" "}
                            <a href={item?.urls[0]} target="_blank">
                                {" "}
                                {item?._source?.title ? item._source.title : ""}
                            </a>
                        </div>
                        <div className="text-base flex gap-4 items-center mt-2">
                            <div className="text-[rgba(122,122,122)]">
                                {item?._source?.publishedAt
                                    ? new Date(item._source.publishedAt).toLocaleDateString()
                                    : ""}
                            </div>
                            {prices[item.urls[0]] && (
                                <div className="text-[rgb(51,221,155)]">
                                    QMV ${prices[item.urls[0]].toFixed(2)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            {list.length < pageLength * (pageno + 1) ? null : (
                <button
                    disabled={list.length < pageLength * (pageno + 1)}
                    onClick={() => {
                        setPageno((prevState) => prevState + 1);
                    }}
                    className="text-[#2a8cff] px-4 py-3 flex items-center justify-end gap-1"
                >
                    View More{" "}
                    <div className="flex items-center justify-center text-2xl">
                        <ArrowDown />
                    </div>{" "}
                </button>
            )}
        </div>
    );
};
export default SimilarArticles;
