const HomeFeature4 = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="black" />
            <path d="M28.1429 18.8572H17.8572V29.1429H28.1429V18.8572Z" fill="#00FFFF" />
            <path
                d="M27.7498 29.4668H18.25C17.8541 29.4668 17.533 29.1458 17.533 28.7498V19.25C17.533 18.8541 17.854 18.533 18.25 18.533H27.7498C28.1458 18.533 28.4668 18.854 28.4668 19.25V28.7498C28.4668 29.1458 28.1458 29.4668 27.7498 29.4668ZM18.967 28.0328H27.0329V19.967H18.967V28.0328Z"
                fill="#00FFFF"
            />
            <path
                d="M30.8868 31.1698H15.8301V16.8301H19.2716C19.3932 16.8301 19.5076 16.7996 19.6079 16.7463C19.9216 16.6698 20.1546 16.3876 20.1546 16.0504V12.717C20.1546 12.3211 19.8336 12 19.4376 12C19.0417 12 18.7206 12.321 18.7206 12.717V15.3962H15.1131C14.7172 15.3962 14.3961 15.7172 14.3961 16.1132V19.5547H11.717C11.321 19.5547 11 19.8758 11 20.2717C11 20.6676 11.321 20.9887 11.717 20.9887H14.3962V23.283H13.1519C12.756 23.283 12.4349 23.6041 12.4349 24C12.4349 24.396 12.7559 24.717 13.1519 24.717H14.3961V27.0114H11.717C11.321 27.0113 11 27.3324 11 27.7283C11 28.1242 11.321 28.4453 11.717 28.4453H14.3962V31.8868C14.3962 32.2827 14.7172 32.6038 15.1132 32.6038H18.5547V35.283C18.5547 35.6789 18.8758 36 19.2717 36C19.6676 36 19.9887 35.679 19.9887 35.283V32.6038H22.2831V33.8481C22.2831 34.244 22.6041 34.5651 23.0001 34.5651C23.396 34.5651 23.7171 34.2441 23.7171 33.8481V32.6038H26.0114V35.283C26.0114 35.6789 26.3325 36 26.7284 36C27.1244 36 27.4454 35.679 27.4454 35.283V32.6038H30.887C31.2829 32.6038 31.604 32.2828 31.604 31.8868C31.604 31.4909 31.2827 31.1698 30.8868 31.1698Z"
                fill="#00FFFF"
            />
            <path
                d="M34.449 27.0113H31.7698V24.7169H33.014C33.41 24.7169 33.7311 24.3959 33.7311 23.9999C33.7311 23.604 33.41 23.2829 33.014 23.2829H31.7698V20.9887H34.449C34.8449 20.9887 35.166 20.6676 35.166 20.2717C35.166 19.8758 34.8449 19.5547 34.449 19.5547H31.7698V16.1132C31.7698 15.7173 31.4487 15.3962 31.0528 15.3962H27.6112V12.717C27.6112 12.3211 27.2902 12 26.8942 12C26.4983 12 26.1773 12.3211 26.1773 12.717V15.3962H23.883V14.1519C23.883 13.756 23.5619 13.4349 23.166 13.4349C22.77 13.4349 22.449 13.756 22.449 14.1519V16.0504C22.449 16.061 22.4501 16.0714 22.4505 16.0818C22.4501 16.0923 22.449 16.1026 22.449 16.1132C22.449 16.5091 22.77 16.8302 23.166 16.8302H30.3358V27.3691C30.2684 27.4748 30.2325 27.5973 30.2325 27.7283C30.2325 28.1242 30.5536 28.4453 30.9495 28.4453H34.2829C34.6788 28.4453 34.9999 28.1243 34.9999 27.7283C34.9999 27.3324 34.6789 27.0113 34.449 27.0113Z"
                fill="#00FFFF"
            />
        </svg>
    );
};

export default HomeFeature4;
