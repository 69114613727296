import CategoryComparison from "./categoryComparison";
import TrafficLocation from "./trafficLocation";

const SecondRow = ({ geoData, catData, newArticles }) => {
    return (
        <div className="flex flex-col min-h-[33rem] lg:flex-row border-b border-b-[#383838]">
            <div className="flex-[2] flex flex-col border-r border-r-[#383838]">
                <div className="px-5 lg:px-11 flex-1 flex py-8 border-t border-t-[#383838]">
                    <CategoryComparison catData={catData} newArticles={newArticles} />
                </div>
            </div>
            <div className="flex-[3] hidden lg:flex ">
                <TrafficLocation geoData={geoData} />
            </div>
        </div>
    );
};
export default SecondRow;
