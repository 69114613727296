const citiesData = `"city","city_ascii","lat","lng","country","iso2","iso3","admin_name","capital","population","id"
"Tokyo","Tokyo","35.6897","139.6922","Japan","JP","JPN","Tōkyō","primary","37732000","1392685764"
"Jakarta","Jakarta","-6.1750","106.8275","Indonesia","ID","IDN","Jakarta","primary","33756000","1360771077"
"Delhi","Delhi","28.6100","77.2300","India","IN","IND","Delhi","admin","32226000","1356872604"
"Guangzhou","Guangzhou","23.1300","113.2600","China","CN","CHN","Guangdong","admin","26940000","1156237133"
"Mumbai","Mumbai","19.0761","72.8775","India","IN","IND","Mahārāshtra","admin","24973000","1356226629"
"Manila","Manila","14.5958","120.9772","Philippines","PH","PHL","Manila","primary","24922000","1608618140"
"Shanghai","Shanghai","31.1667","121.4667","China","CN","CHN","Shanghai","admin","24073000","1156073548"
"São Paulo","Sao Paulo","-23.5500","-46.6333","Brazil","BR","BRA","São Paulo","admin","23086000","1076532519"
"Seoul","Seoul","37.5600","126.9900","South Korea","KR","KOR","Seoul","primary","23016000","1410836482"
"Mexico City","Mexico City","19.4333","-99.1333","Mexico","MX","MEX","Ciudad de México","primary","21804000","1484247881"
"Cairo","Cairo","30.0444","31.2358","Egypt","EG","EGY","Al Qāhirah","primary","20296000","1818253931"
"New York","New York","40.6943","-73.9249","United States","US","USA","New York","","18972871","1840034016"
"Dhaka","Dhaka","23.7639","90.3889","Bangladesh","BD","BGD","Dhaka","primary","18627000","1050529279"
"Beijing","Beijing","39.9040","116.4075","China","CN","CHN","Beijing","primary","18522000","1156228865"
"Kolkāta","Kolkata","22.5675","88.3700","India","IN","IND","West Bengal","admin","18502000","1356060520"
"Bangkok","Bangkok","13.7525","100.4942","Thailand","TH","THA","Krung Thep Maha Nakhon","primary","18007000","1764068610"
"Shenzhen","Shenzhen","22.5350","114.0540","China","CN","CHN","Guangdong","minor","17619000","1156158707"
"Moscow","Moscow","55.7558","37.6178","Russia","RU","RUS","Moskva","primary","17332000","1643318494"
"Buenos Aires","Buenos Aires","-34.5997","-58.3819","Argentina","AR","ARG","Buenos Aires, Ciudad Autónoma de","primary","16710000","1032717330"
"Lagos","Lagos","6.4550","3.3841","Nigeria","NG","NGA","Lagos","minor","16637000","1566593751"
"Istanbul","Istanbul","41.0136","28.9550","Turkey","TR","TUR","İstanbul","admin","16079000","1792756324"
"Karachi","Karachi","24.8600","67.0100","Pakistan","PK","PAK","Sindh","admin","15738000","1586129469"
"Bangalore","Bangalore","12.9789","77.5917","India","IN","IND","Karnātaka","admin","15386000","1356410365"
"Ho Chi Minh City","Ho Chi Minh City","10.7756","106.7019","Vietnam","VN","VNM","Hồ Chí Minh","admin","15136000","1704774326"
"Ōsaka","Osaka","34.6939","135.5022","Japan","JP","JPN","Ōsaka","admin","15126000","1392419823"
"Chengdu","Chengdu","30.6600","104.0633","China","CN","CHN","Sichuan","admin","14645000","1156421555"
"Tehran","Tehran","35.6892","51.3889","Iran","IR","IRN","Tehrān","primary","14148000","1364305026"
"Kinshasa","Kinshasa","-4.3250","15.3222","Congo (Kinshasa)","CD","COD","Kinshasa","primary","12836000","1180000363"
"Rio de Janeiro","Rio de Janeiro","-22.9111","-43.2056","Brazil","BR","BRA","Rio de Janeiro","admin","12592000","1076887657"
"Chennai","Chennai","13.0825","80.2750","India","IN","IND","Tamil Nādu","admin","12395000","1356374944"
"Xi’an","Xi'an","34.2667","108.9000","China","CN","CHN","Shaanxi","admin","12328000","1156244079"
"Lahore","Lahore","31.5497","74.3436","Pakistan","PK","PAK","Punjab","admin","12306000","1586801463"
"Chongqing","Chongqing","29.5500","106.5069","China","CN","CHN","Chongqing","admin","12135000","1156936556"
"Los Angeles","Los Angeles","34.1141","-118.4068","United States","US","USA","California","","12121244","1840020491"
"Baoding","Baoding","38.8671","115.4845","China","CN","CHN","Hebei","","11860000","1156256829"
"London","London","51.5072","-0.1275","United Kingdom","GB","GBR","London, City of","primary","11262000","1826645935"
"Paris","Paris","48.8567","2.3522","France","FR","FRA","Île-de-France","primary","11060000","1250015082"
"Linyi","Linyi","35.1041","118.3502","China","CN","CHN","Shandong","","11018365","1156086320"
"Dongguan","Dongguan","23.0475","113.7493","China","CN","CHN","Guangdong","minor","10646000","1156478242"
"Hyderābād","Hyderabad","17.3850","78.4867","India","IN","IND","Telangāna","admin","10494000","1356871768"
"Tianjin","Tianjin","39.1467","117.2056","China","CN","CHN","Tianjin","admin","10368000","1156174046"
"Lima","Lima","-12.0600","-77.0375","Peru","PE","PER","Lima","primary","10320000","1604728603"
"Wuhan","Wuhan","30.5872","114.2881","China","CN","CHN","Hubei","admin","10251000","1156117184"
"Nanyang","Nanyang","32.9987","112.5292","China","CN","CHN","Henan","","10013600","1156192287"
"Hangzhou","Hangzhou","30.2500","120.1675","China","CN","CHN","Zhejiang","admin","9523000","1156275243"
"Foshan","Foshan","23.0292","113.1056","China","CN","CHN","Guangdong","minor","9498863","1156738403"
"Nagoya","Nagoya","35.1833","136.9000","Japan","JP","JPN","Aichi","admin","9197000","1392407472"
"Taipei","Taipei","25.0375","121.5625","Taiwan","TW","TWN","Taipei","primary","9078000","1158881289"
"Tongshan","Tongshan","34.2610","117.1859","China","CN","CHN","Jiangsu","minor","9083790","1156241678"
"Luanda","Luanda","-8.8383","13.2344","Angola","AO","AGO","Luanda","primary","9051000","1024949724"
"Zhoukou","Zhoukou","33.6250","114.6418","China","CN","CHN","Henan","minor","9026015","1156272098"
"Ganzhou","Ganzhou","25.8292","114.9336","China","CN","CHN","Jiangxi","minor","8970014","1156832475"
"Kuala Lumpur","Kuala Lumpur","3.1478","101.6953","Malaysia","MY","MYS","Kuala Lumpur","primary","8911000","1458988644"
"Heze","Heze","35.2333","115.4333","China","CN","CHN","Shandong","minor","8795939","1156225008"
"Quanzhou","Quanzhou","24.9139","118.5858","China","CN","CHN","Fujian","minor","8782285","1156829655"
"Chicago","Chicago","41.8375","-87.6866","United States","US","USA","Illinois","","8595181","1840000494"
"Nanjing","Nanjing","32.0608","118.7789","China","CN","CHN","Jiangsu","admin","8422000","1156644065"
"Jining","Jining","35.4000","116.5667","China","CN","CHN","Shandong","","8357897","1156504601"
"Hanoi","Hanoi","21.0283","105.8542","Vietnam","VN","VNM","Hà Nội","primary","8246600","1704413791"
"Pune","Pune","18.5203","73.8567","India","IN","IND","Mahārāshtra","","8231000","1356081074"
"Fuyang","Fuyang","32.8986","115.8045","China","CN","CHN","Anhui","","8200264","1156248008"
"Ahmedabad","Ahmedabad","23.0300","72.5800","India","IN","IND","Gujarāt","minor","8009000","1356304381"
"Johannesburg","Johannesburg","-26.2044","28.0456","South Africa","ZA","ZAF","Gauteng","admin","8000000","1710550792"
"Bogotá","Bogota","4.7111","-74.0722","Colombia","CO","COL","Bogotá","primary","7968095","1170483426"
"Dar es Salaam","Dar es Salaam","-6.8161","39.2803","Tanzania","TZ","TZA","Dar es Salaam","primary","7962000","1834843853"
"Shenyang","Shenyang","41.8025","123.4281","China","CN","CHN","Liaoning","admin","7964000","1156309382"
"Khartoum","Khartoum","15.5006","32.5600","Sudan","SD","SDN","Khartoum","primary","7869000","1729268475"
"Shangqiu","Shangqiu","34.4259","115.6467","China","CN","CHN","Henan","","7816831","1156805441"
"Cangzhou","Cangzhou","38.3037","116.8452","China","CN","CHN","Hebei","","7544300","1156698069"
"Hong Kong","Hong Kong","22.3000","114.2000","Hong Kong","HK","HKG","","primary","7450000","1344982653"
"Shaoyang","Shaoyang","27.2418","111.4725","China","CN","CHN","Hunan","","7370500","1156310366"
"Zhanjiang","Zhanjiang","21.1967","110.4031","China","CN","CHN","Guangdong","minor","7332000","1156502170"
"Yancheng","Yancheng","33.3936","120.1339","China","CN","CHN","Jiangsu","minor","7260240","1156995410"
"Hengyang","Hengyang","26.8968","112.5857","China","CN","CHN","Hunan","","7243400","1156696884"
"Riyadh","Riyadh","24.6333","46.7167","Saudi Arabia","SA","SAU","Ar Riyāḑ","primary","7237000","1682999334"
"Zhumadian","Zhumadian","32.9773","114.0253","China","CN","CHN","Henan","","7231234","1156184822"
"Santiago","Santiago","-33.4372","-70.6506","Chile","CL","CHL","Región Metropolitana","primary","7171000","1152554349"
"Xingtai","Xingtai","37.0659","114.4753","China","CN","CHN","Hebei","","7111106","1156294952"
"Chattogram","Chattogram","22.3350","91.8325","Bangladesh","BD","BGD","Chattogram","admin","7000000","1050830722"
"Bijie","Bijie","27.3019","105.2863","China","CN","CHN","Guizhou","","6899636","1156018927"
"Shangrao","Shangrao","28.4419","117.9633","China","CN","CHN","Jiangxi","minor","6810700","1156405492"
"Zunyi","Zunyi","27.7050","106.9336","China","CN","CHN","Guizhou","","6606675","1156539782"
"Sūrat","Surat","21.1702","72.8311","India","IN","IND","Gujarāt","","6538000","1356758738"
"Surabaya","Surabaya","-7.2458","112.7378","Indonesia","ID","IDN","Jawa Timur","admin","6499000","1360484663"
"Huanggang","Huanggang","30.4500","114.8750","China","CN","CHN","Hubei","minor","6333000","1156200037"
"Maoming","Maoming","21.6618","110.9178","China","CN","CHN","Guangdong","minor","6313200","1156568722"
"Nanchong","Nanchong","30.7991","106.0784","China","CN","CHN","Sichuan","minor","6278614","1156762337"
"Xinyang","Xinyang","32.1264","114.0672","China","CN","CHN","Henan","","6234401","1156273453"
"Madrid","Madrid","40.4169","-3.7033","Spain","ES","ESP","Madrid","primary","6211000","1724616994"
"Baghdad","Baghdad","33.3153","44.3661","Iraq","IQ","IRQ","Baghdād","primary","6183000","1368596238"
"Qujing","Qujing","25.5102","103.8029","China","CN","CHN","Yunnan","","6155400","1156747998"
"Jieyang","Jieyang","23.5533","116.3649","China","CN","CHN","Guangdong","minor","6089400","1156260378"
"Singapore","Singapore","1.3000","103.8000","Singapore","SG","SGP","","primary","5983000","1702341327"
"Prayagraj","Prayagraj","25.4358","81.8464","India","IN","IND","Uttar Pradesh","","5954391","1356718332"
"Liaocheng","Liaocheng","36.4500","115.9833","China","CN","CHN","Shandong","minor","5952128","1156006600"
"Dalian","Dalian","38.9000","121.6000","China","CN","CHN","Liaoning","","5871474","1156175472"
"Yulin","Yulin","22.6293","110.1507","China","CN","CHN","Guangxi","minor","5849700","1156901312"
"Changde","Changde","29.0397","111.6839","China","CN","CHN","Hunan","minor","5827200","1156449091"
"Qingdao","Qingdao","36.1167","120.4000","China","CN","CHN","Shandong","minor","5818255","1156112588"
"Douala","Douala","4.0500","9.7000","Cameroon","CM","CMR","Littoral","admin","5768400","1120494607"
"Miami","Miami","25.7840","-80.2101","United States","US","USA","Florida","","5711945","1840015149"
"Nangandao","Nangandao","35.2992","113.8851","China","CN","CHN","Henan","minor","5708191","1156127660"
"Pudong","Pudong","31.2231","121.5397","China","CN","CHN","Shanghai","minor","5681512","1156644508"
"Xiangyang","Xiangyang","32.0654","112.1531","China","CN","CHN","Hubei","minor","5680000","1156107325"
"Dallas","Dallas","32.7935","-96.7667","United States","US","USA","Texas","","5668165","1840019440"
"Houston","Houston","29.7860","-95.3885","United States","US","USA","Texas","","5650910","1840020925"
"Zhengzhou","Zhengzhou","34.7492","113.6605","China","CN","CHN","Henan","admin","5621593","1156183137"
"Lu’an","Lu'an","31.7542","116.5078","China","CN","CHN","Anhui","minor","5611701","1156499624"
"Dezhou","Dezhou","37.4513","116.3105","China","CN","CHN","Shandong","minor","5611194","1156524080"
"Jinan","Jinan","36.6667","116.9833","China","CN","CHN","Shandong","admin","5606374","1156972469"
"Giza","Giza","29.9870","31.2118","Egypt","EG","EGY","Al Jīzah","admin","5598402","1818925479"
"Zhaotong","Zhaotong","27.3328","103.7144","China","CN","CHN","Yunnan","","5591000","1156924687"
"Yichun","Yichun","27.8041","114.3830","China","CN","CHN","Jiangxi","minor","5573200","1156229590"
"Nairobi","Nairobi","-1.2864","36.8172","Kenya","KE","KEN","Nairobi City","primary","5545000","1404000661"
"Guadalajara","Guadalajara","20.6767","-103.3475","Mexico","MX","MEX","Jalisco","admin","5525000","1484950208"
"Philadelphia","Philadelphia","40.0077","-75.1339","United States","US","USA","Pennsylvania","","5512873","1840000673"
"Ankara","Ankara","39.9300","32.8500","Turkey","TR","TUR","Ankara","primary","5503985","1792572891"
"Tai’an","Tai'an","36.2001","117.0809","China","CN","CHN","Shandong","","5494207","1156095188"
"Dazhou","Dazhou","31.2152","107.4947","China","CN","CHN","Sichuan","minor","5468097","1156834076"
"Langfang","Langfang","39.5196","116.7006","China","CN","CHN","Hebei","","5464087","1156109017"
"Yongzhou","Yongzhou","26.4515","111.5953","China","CN","CHN","Hunan","","5452100","1156694479"
"Toronto","Toronto","43.7417","-79.3733","Canada","CA","CAN","Ontario","admin","5429524","1124279679"
"Suihua","Suihua","46.6384","126.9808","China","CN","CHN","Heilongjiang","minor","5418153","1156235493"
"Saint Petersburg","Saint Petersburg","59.9500","30.3167","Russia","RU","RUS","Sankt-Peterburg","admin","5384342","1643616350"
"Qiqihar","Qiqihar","47.3398","123.9512","China","CN","CHN","Heilongjiang","minor","5367003","1156775905"
"Suzhou","Suzhou","33.6333","116.9683","China","CN","CHN","Anhui","","5352924","1156871297"
"Monterrey","Monterrey","25.6667","-100.3000","Mexico","MX","MEX","Nuevo León","admin","5341171","1484559591"
"Belo Horizonte","Belo Horizonte","-19.9167","-43.9333","Brazil","BR","BRA","Minas Gerais","admin","5328000","1076967355"
"Weinan","Weinan","34.4996","109.4684","China","CN","CHN","Shaanxi","minor","5286077","1156903687"
"Rangoon","Rangoon","16.7950","96.1600","Myanmar","MM","MMR","Yangon","primary","5209541","1104616656"
"Zhangzhou","Zhangzhou","24.5093","117.6612","China","CN","CHN","Fujian","minor","5140000","1156241637"
"Yuncheng","Yuncheng","35.0304","110.9980","China","CN","CHN","Shanxi","","5134779","1156705644"
"Xianyang","Xianyang","34.3500","108.7167","China","CN","CHN","Shaanxi","minor","5096001","1156120117"
"Guilin","Guilin","25.2819","110.2864","China","CN","CHN","Guangxi","minor","5085500","1156235364"
"Atlanta","Atlanta","33.7628","-84.4220","United States","US","USA","Georgia","admin","5046555","1840013660"
"Taizhou","Taizhou","32.4831","119.9000","China","CN","CHN","Jiangsu","minor","5031000","1156119229"
"Kāshān","Kashan","33.9833","51.4333","Iran","IR","IRN","Eşfahān","minor","5000000","1364006067"
"Bozhou","Bozhou","33.8626","115.7742","China","CN","CHN","Anhui","","4996844","1156356860"
"Abidjan","Abidjan","5.3167","-4.0333","Côte d'Ivoire","CI","CIV","Abidjan","primary","4980000","1384207980"
"Suqian","Suqian","33.9331","118.2831","China","CN","CHN","Jiangsu","","4986192","1156212349"
"Huaihua","Huaihua","27.5494","109.9592","China","CN","CHN","Hunan","","4979600","1156353465"
"Ji’an","Ji'an","27.1172","114.9793","China","CN","CHN","Jiangxi","minor","4956600","1156278215"
"Xiaoganzhan","Xiaoganzhan","30.9273","113.9110","China","CN","CHN","Hubei","","4921000","1156002290"
"Pingdingshan","Pingdingshan","33.7350","113.2999","China","CN","CHN","Henan","","4904701","1156735124"
"Jiujiang","Jiujiang","29.7048","116.0021","China","CN","CHN","Jiangxi","minor","4896800","1156206041"
"Alexandria","Alexandria","31.1975","29.8925","Egypt","EG","EGY","Al Iskandarīyah","admin","4870000","1818695837"
"Mianyang","Mianyang","31.4669","104.7385","China","CN","CHN","Sichuan","minor","4868243","1156417758"
"Sydney","Sydney","-33.8678","151.2100","Australia","AU","AUS","New South Wales","admin","4840600","1036074917"
"Huanglongsi","Huanglongsi","34.7950","114.3450","China","CN","CHN","Henan","","4824016","1156198356"
"Washington","Washington","38.9047","-77.0163","United States","US","USA","District of Columbia","primary","4810669","1840006060"
"Barcelona","Barcelona","41.3825","2.1769","Spain","ES","ESP","Catalonia","admin","4800000","1724594040"
"Changsha","Changsha","28.1987","112.9709","China","CN","CHN","Hunan","admin","4766296","1156961497"
"Chenzhou","Chenzhou","25.7989","113.0267","China","CN","CHN","Hunan","minor","4744500","1156291915"
"Anqing","Anqing","30.5000","117.0333","China","CN","CHN","Anhui","minor","4723000","1156238875"
"Jiangmen","Jiangmen","22.5833","113.0833","China","CN","CHN","Guangdong","minor","4630300","1156105613"
"Xinpu","Xinpu","34.5906","119.1801","China","CN","CHN","Jiangsu","minor","4599360","1156035381"
"Yibin","Yibin","28.7596","104.6400","China","CN","CHN","Sichuan","minor","4588804","1156107603"
"Yangzhou","Yangzhou","32.3912","119.4363","China","CN","CHN","Jiangsu","","4559797","1156818601"
"Melbourne","Melbourne","-37.8142","144.9631","Australia","AU","AUS","Victoria","admin","4529500","1036533631"
"Berlin","Berlin","52.5200","13.4050","Germany","DE","DEU","Berlin","primary","4473101","1276451290"
"Hengshui","Hengshui","37.7348","115.6860","China","CN","CHN","Hebei","","4472000","1156803028"
"Timbío","Timbio","2.3445","-76.6839","Colombia","CO","COL","Cauca","minor","4444444","1170815311"
"Kunming","Kunming","25.0433","102.7061","China","CN","CHN","Yunnan","admin","4422686","1156477539"
"Yiyang","Yiyang","28.5833","112.3333","China","CN","CHN","Hunan","","4413800","1156218615"
"Guigang","Guigang","23.0961","109.6092","China","CN","CHN","Guangxi","minor","4409200","1156895251"
"Changchun","Changchun","43.9000","125.2000","China","CN","CHN","Jilin","admin","4408154","1156078103"
"Jiangguanchi","Jiangguanchi","34.0244","113.8201","China","CN","CHN","Henan","minor","4379998","1156235735"
"Casablanca","Casablanca","33.5333","-7.5833","Morocco","MA","MAR","Casablanca-Settat","admin","4370000","1504175315"
"Meizhou","Meizhou","24.2998","116.1191","China","CN","CHN","Guangdong","minor","4378800","1156361028"
"Zhangjiakou","Zhangjiakou","40.8108","114.8811","China","CN","CHN","Hebei","minor","4345485","1156800221"
"Chifeng","Chifeng","42.2663","118.9223","China","CN","CHN","Inner Mongolia","minor","4341245","1156277458"
"Ürümqi","Urumqi","43.8225","87.6125","China","CN","CHN","Xinjiang","admin","4335017","1156051276"
"Suzhou","Suzhou","31.3000","120.6194","China","CN","CHN","Jiangsu","minor","4330000","1156029196"
"İzmir","Izmir","38.4200","27.1400","Turkey","TR","TUR","İzmir","admin","4320519","1792725579"
"Linfen","Linfen","36.0812","111.5087","China","CN","CHN","Shanxi","","4316610","1156416074"
"Shantou","Shantou","23.3735","116.6941","China","CN","CHN","Guangdong","minor","4312192","1156457499"
"Kabul","Kabul","34.5253","69.1783","Afghanistan","AF","AFG","Kābul","primary","4273156","1004993580"
"Mogadishu","Mogadishu","2.0392","45.3419","Somalia","SO","SOM","Banaadir","primary","4249083","1706893395"
"Luzhou","Luzhou","28.8918","105.4409","China","CN","CHN","Sichuan","minor","4218427","1156582079"
"Hefei","Hefei","31.8639","117.2808","China","CN","CHN","Anhui","admin","4216940","1156332710"
"Boston","Boston","42.3188","-71.0852","United States","US","USA","Massachusetts","admin","4208580","1840000455"
"Liuzhou","Liuzhou","24.3264","109.4281","China","CN","CHN","Guangxi","minor","4157934","1156360785"
"Zhaoqing","Zhaoqing","23.0500","112.4667","China","CN","CHN","Guangdong","minor","4151700","1156170144"
"Xiaoxita","Xiaoxita","30.7083","111.2803","China","CN","CHN","Hubei","minor","4137900","1156764447"
"Shijiazhuang","Shijiazhuang","38.0422","114.5086","China","CN","CHN","Hebei","admin","4098243","1156217541"
"Ningbo","Ningbo","29.8750","121.5492","China","CN","CHN","Zhejiang","minor","4087523","1156170787"
"Fuzhou","Fuzhou","27.9814","116.3577","China","CN","CHN","Jiangxi","","4047200","1156915325"
"Phoenix","Phoenix","33.5722","-112.0892","United States","US","USA","Arizona","admin","4047095","1840020568"
"Zhuzhou","Zhuzhou","27.8407","113.1469","China","CN","CHN","Hunan","minor","4020800","1156041962"
"Amman","Amman","31.9497","35.9328","Jordan","JO","JOR","Al ‘Āşimah","primary","4007526","1400522593"
"Chuzhou","Chuzhou","32.3062","118.3115","China","CN","CHN","Anhui","","3987054","1156036420"
"Jeddah","Jeddah","21.5433","39.1728","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","3976000","1682926944"
"Qingyuan","Qingyuan","23.6842","113.0507","China","CN","CHN","Guangdong","minor","3969473","1156135890"
"Loudi","Loudi","27.7378","111.9974","China","CN","CHN","Hunan","","3931800","1156010654"
"Binzhou","Binzhou","37.3806","118.0125","China","CN","CHN","Shandong","","3928568","1156564962"
"Deyang","Deyang","31.1289","104.3950","China","CN","CHN","Sichuan","minor","3877000","1156127147"
"Taiyuan","Taiyuan","37.8733","112.5425","China","CN","CHN","Shanxi","admin","3875053","1156632014"
"Kano","Kano","12.0000","8.5167","Nigeria","NG","NGA","Kano","admin","3848885","1566422868"
"Wuhu","Wuhu","31.3340","118.3622","China","CN","CHN","Anhui","minor","3842100","1156315512"
"Nanning","Nanning","22.8192","108.3150","China","CN","CHN","Guangxi","admin","3837978","1156605439"
"Harbin","Harbin","45.7500","126.6333","China","CN","CHN","Heilongjiang","admin","3830000","1156241528"
"Abuja","Abuja","9.0667","7.4833","Nigeria","NG","NGA","Federal Capital Territory","primary","3770000","1566342270"
"Yokohama","Yokohama","35.4442","139.6381","Japan","JP","JPN","Kanagawa","admin","3757630","1392118339"
"Baojishi","Baojishi","34.3609","107.1751","China","CN","CHN","Shaanxi","","3738700","1156101096"
"Zaozhuang","Zaozhuang","34.8667","117.5500","China","CN","CHN","Shandong","","3729140","1156796454"
"Xiamen","Xiamen","24.4797","118.0819","China","CN","CHN","Fujian","minor","3707090","1156212809"
"Neijiang","Neijiang","29.5872","105.0635","China","CN","CHN","Sichuan","minor","3702847","1156516335"
"Fuzhou","Fuzhou","26.0769","119.2917","China","CN","CHN","Fujian","admin","3671192","1156188037"
"Baicheng","Baicheng","23.9010","106.6194","China","CN","CHN","Guangxi","minor","3669400","1156981113"
"Anshan","Anshan","41.1066","122.9895","China","CN","CHN","Liaoning","","3645884","1156901825"
"Medan","Medan","3.5894","98.6739","Indonesia","ID","IDN","Sumatera Utara","admin","3632000","1360543171"
"Yulinshi","Yulinshi","38.2655","109.7388","China","CN","CHN","Shaanxi","","3634750","1156280672"
"Wenzhou","Wenzhou","27.9991","120.6561","China","CN","CHN","Zhejiang","","3604446","1156188829"
"Changzhou","Changzhou","31.8122","119.9692","China","CN","CHN","Jiangsu","minor","3601079","1156185511"
"Puyang","Puyang","35.7639","115.0300","China","CN","CHN","Henan","minor","3598740","1156431924"
"Jiaozuo","Jiaozuo","35.2290","113.2304","China","CN","CHN","Henan","","3590700","1156157854"
"Nanchang","Nanchang","28.6842","115.8872","China","CN","CHN","Jiangxi","admin","3576547","1156198892"
"Ibadan","Ibadan","7.3964","3.9167","Nigeria","NG","NGA","Oyo","admin","3552000","1566366407"
"Hechi","Hechi","24.6928","108.0850","China","CN","CHN","Guangxi","minor","3545700","1156167204"
"Detroit","Detroit","42.3834","-83.1024","United States","US","USA","Michigan","","3522856","1840003971"
"Montréal","Montreal","45.5089","-73.5617","Canada","CA","CAN","Quebec","","3519595","1124586170"
"Busan","Busan","35.1800","129.0750","South Korea","KR","KOR","Busan","admin","3453198","1410601465"
"Hohhot","Hohhot","40.8151","111.6629","China","CN","CHN","Inner Mongolia","admin","3446100","1156210167"
"Seattle","Seattle","47.6211","-122.3244","United States","US","USA","Washington","","3438221","1840021117"
"Algiers","Algiers","36.7539","3.0589","Algeria","DZ","DZA","Alger","primary","3415811","1012973369"
"Hanzhong","Hanzhong","33.0794","107.0260","China","CN","CHN","Shaanxi","","3416196","1156382678"
"Tangshan","Tangshan","39.6292","118.1742","China","CN","CHN","Hebei","","3399231","1156904299"
"Shiyan","Shiyan","32.6351","110.7755","China","CN","CHN","Hubei","minor","3398000","1156383921"
"Lucknow","Lucknow","26.8500","80.9500","India","IN","IND","Uttar Pradesh","admin","3382000","1356891790"
"Siping","Siping","43.1715","124.3644","China","CN","CHN","Jilin","minor","3385156","1156063295"
"Mashhad","Mashhad","36.3000","59.6000","Iran","IR","IRN","Khorāsān-e Raẕavī","admin","3372090","1364123206"
"Boankra","Boankra","6.6944","-1.4028","Ghana","GH","GHA","Ashanti","","3348000","1288164978"
"Changzhi","Changzhi","36.1953","113.0970","China","CN","CHN","Shanxi","minor","3334565","1156057944"
"Dubai","Dubai","25.2631","55.2972","United Arab Emirates","AE","ARE","Dubayy","admin","3331420","1784736618"
"Qinzhou","Qinzhou","21.9500","108.6167","China","CN","CHN","Guangxi","minor","3304400","1156106602"
"Guiyang","Guiyang","26.5794","106.7078","China","CN","CHN","Guizhou","admin","3299724","1156932620"
"Bengbu","Bengbu","32.9354","117.3531","China","CN","CHN","Anhui","","3296408","1156440668"
"San Francisco","San Francisco","37.7558","-122.4449","United States","US","USA","California","","3290197","1840021543"
"Bazhou","Bazhou","31.8576","106.7559","China","CN","CHN","Sichuan","minor","3283148","1156271365"
"Qincheng","Qincheng","34.5809","105.7311","China","CN","CHN","Gansu","minor","3262549","1156104017"
"Suining","Suining","30.5098","105.5737","China","CN","CHN","Sichuan","minor","3252619","1156655650"
"Wuxi","Wuxi","31.5667","120.2833","China","CN","CHN","Jiangsu","minor","3245179","1156019650"
"Leshan","Leshan","29.5854","103.7575","China","CN","CHN","Sichuan","","3235759","1156203130"
"Putian","Putian","25.4394","119.0103","China","CN","CHN","Fujian","minor","3210714","1156811601"
"Zhenjiang","Zhenjiang","32.2109","119.4551","China","CN","CHN","Jiangsu","minor","3210418","1156934125"
"Faisalabad","Faisalabad","31.4167","73.0911","Pakistan","PK","PAK","Punjab","minor","3203846","1586323916"
"Guang’an","Guang'an","30.4673","106.6336","China","CN","CHN","Sichuan","minor","3205476","1156377302"
"Tongren","Tongren","27.7233","109.1885","China","CN","CHN","Guizhou","","3168800","1156803442"
"Santa Cruz","Santa Cruz","-17.7892","-63.1975","Bolivia","BO","BOL","Santa Cruz","admin","3151676","1068129363"
"Qinhuangdao","Qinhuangdao","39.9398","119.5881","China","CN","CHN","Hebei","","3146300","1156091093"
"Tongliao","Tongliao","43.6172","122.2640","China","CN","CHN","Inner Mongolia","minor","3139153","1156358686"
"Jinzhou","Jinzhou","41.1144","121.1292","China","CN","CHN","Liaoning","","3126463","1156157151"
"Heyuan","Heyuan","23.7503","114.6923","China","CN","CHN","Guangdong","minor","3093900","1156665165"
"San Diego","San Diego","32.8313","-117.1222","United States","US","USA","California","","3084174","1840021990"
"Jaipur","Jaipur","26.9000","75.8000","India","IN","IND","Rājasthān","admin","3073350","1356293537"
"Xinzhou","Xinzhou","38.4178","112.7233","China","CN","CHN","Shanxi","minor","3067501","1156617534"
"Lanzhou","Lanzhou","36.0617","103.8318","China","CN","CHN","Gansu","admin","3067141","1156280566"
"Wuzhou","Wuzhou","23.4833","111.3167","China","CN","CHN","Guangxi","minor","3061100","1156620133"
"Athens","Athens","37.9842","23.7281","Greece","GR","GRC","Attikí","primary","3041131","1300715560"
"Addis Ababa","Addis Ababa","9.0300","38.7400","Ethiopia","ET","ETH","Ādīs Ābeba","primary","3041002","1231824991"
"Chaoyang","Chaoyang","41.5757","120.4486","China","CN","CHN","Liaoning","","3044641","1156706927"
"Brasília","Brasilia","-15.7939","-47.8828","Brazil","BR","BRA","Distrito Federal","primary","3039444","1076144436"
"Taichung","Taichung","24.1439","120.6794","Taiwan","TW","TWN","Taichung","admin","3033885","1158689622"
"Kuwait City","Kuwait City","29.3697","47.9783","Kuwait","KW","KWT","Al ‘Āşimah","primary","3000000","1414102075"
"Budapest","Budapest","47.4925","19.0514","Hungary","HU","HUN","Budapest","primary","2997958","1348611435"
"Shaoguan","Shaoguan","24.8011","113.5927","China","CN","CHN","Guangdong","minor","2997600","1156617066"
"Shanwei","Shanwei","22.7664","115.3331","China","CN","CHN","Guangdong","minor","2993600","1156939010"
"Quezon City","Quezon City","14.6300","121.0300","Philippines","PH","PHL","Quezon","admin","2960048","1608974097"
"Rizhao","Rizhao","35.4164","119.4331","China","CN","CHN","Shandong","minor","2968365","1156032841"
"Kyiv","Kyiv","50.4500","30.5233","Ukraine","UA","UKR","Kyiv, Misto","primary","2963199","1804382913"
"Sanaa","Sanaa","15.3483","44.2064","Yemen","YE","YEM","Amānat al ‘Āşimah","primary","2957000","1887750814"
"Meishan","Meishan","30.0575","103.8381","China","CN","CHN","Sichuan","","2950545","1156185501"
"Incheon","Incheon","37.4833","126.6333","South Korea","KR","KOR","Incheon","admin","2936117","1410160313"
"Guatemala City","Guatemala City","14.6099","-90.5252","Guatemala","GT","GTM","Guatemala","primary","2934841","1320197916"
"Birmingham","Birmingham","52.4800","-1.9025","United Kingdom","GB","GBR","Birmingham","","2919600","1826423213"
"Zhongshan","Zhongshan","22.5333","113.3500","China","CN","CHN","Guangdong","minor","2909633","1156579621"
"Ningde","Ningde","26.6617","119.5228","China","CN","CHN","Fujian","minor","2910000","1156909802"
"Weihai","Weihai","37.5000","122.1000","China","CN","CHN","Shandong","minor","2906548","1156473300"
"Daqing","Daqing","46.5979","125.0080","China","CN","CHN","Heilongjiang","minor","2904532","1156403962"
"Bursa","Bursa","40.1833","29.0500","Turkey","TR","TUR","Bursa","admin","2901396","1792903330"
"Salvador","Salvador","-12.9747","-38.4767","Brazil","BR","BRA","Bahia","admin","2886698","1076923789"
"Rome","Rome","41.8931","12.4828","Italy","IT","ITA","Lazio","primary","2872800","1380382862"
"Haikou","Haikou","20.0200","110.3200","China","CN","CHN","Hainan","admin","2873358","1156183327"
"La Paz","La Paz","-16.5000","-68.1500","Bolivia","BO","BOL","La Paz","primary","2867504","1068000064"
"Xiangtan","Xiangtan","27.8431","112.9228","China","CN","CHN","Hunan","","2864800","1156195684"
"Pyongyang","Pyongyang","39.0194","125.7381","North Korea","KP","PRK","P’yŏngyang","primary","2863000","1408738594"
"Minneapolis","Minneapolis","44.9635","-93.2678","United States","US","USA","Minnesota","","2856952","1840007830"
"Omdurman","Omdurman","15.6500","32.4833","Sudan","SD","SDN","Khartoum","","2805396","1729912179"
"Malang","Malang","-7.9800","112.6200","Indonesia","ID","IDN","Jawa Timur","","2795209","1360141408"
"Mudanjiang","Mudanjiang","44.5861","129.5997","China","CN","CHN","Heilongjiang","minor","2798723","1156249698"
"Stuttgart","Stuttgart","48.7775","9.1800","Germany","DE","DEU","Baden-Württemberg","admin","2787724","1276171358"
"Brooklyn","Brooklyn","40.6501","-73.9496","United States","US","USA","New York","","2736074","1840034030"
"Kaohsiung","Kaohsiung","22.6150","120.2975","Taiwan","TW","TWN","Kaohsiung","admin","2733566","1158331334"
"Guayaquil","Guayaquil","-2.1900","-79.8875","Ecuador","EC","ECU","Guayas","admin","2723665","1218802178"
"Lisbon","Lisbon","38.7253","-9.1500","Portugal","PT","PRT","Lisboa","primary","2719000","1620619017"
"Longyan","Longyan","25.0881","117.0244","China","CN","CHN","Fujian","minor","2723637","1156012979"
"Tieling","Tieling","42.2841","123.8365","China","CN","CHN","Liaoning","","2717732","1156089994"
"Manchester","Manchester","53.4794","-2.2453","United Kingdom","GB","GBR","Manchester","","2705000","1826246335"
"Baotou","Baotou","40.6562","109.8345","China","CN","CHN","Inner Mongolia","","2709378","1156032751"
"Handan","Handan","36.6116","114.4894","China","CN","CHN","Hebei","minor","2708015","1156957080"
"Cawnpore","Cawnpore","26.4499","80.3319","India","IN","IND","Uttar Pradesh","","2701324","1356575238"
"Dingxi","Dingxi","35.5806","104.6263","China","CN","CHN","Gansu","minor","2698624","1156518274"
"Nanping","Nanping","26.6448","118.1728","China","CN","CHN","Fujian","minor","2690000","1156927445"
"Tampa","Tampa","27.9945","-82.4447","United States","US","USA","Florida","","2683956","1840015982"
"Zigong","Zigong","29.3498","104.7645","China","CN","CHN","Sichuan","","2678899","1156023354"
"Maracaibo","Maracaibo","10.6333","-71.6333","Venezuela","VE","VEN","Zulia","admin","2658355","1862072711"
"Chaozhou","Chaozhou","23.6700","116.6300","China","CN","CHN","Guangdong","minor","2656600","1156549118"
"Mbuji-Mayi","Mbuji-Mayi","-6.1500","23.6000","Congo (Kinshasa)","CD","COD","Kasaï Oriental","admin","2643000","1180399586"
"Denver","Denver","39.7620","-104.8758","United States","US","USA","Colorado","admin","2650725","1840018789"
"Gulou","Gulou","26.0865","119.2980","China","CN","CHN","Fujian","minor","2606000","1156680279"
"Weifang","Weifang","36.7167","119.1000","China","CN","CHN","Shandong","","2636154","1156912965"
"Huai’an","Huai'an","33.5058","119.1392","China","CN","CHN","Jiangsu","","2632788","1156634228"
"Zibo","Zibo","36.7831","118.0497","China","CN","CHN","Shandong","","2631647","1156381094"
"Ankang","Ankang","32.6877","109.0235","China","CN","CHN","Shaanxi","","2629906","1156255711"
"Baoshan","Baoshan","25.1211","99.1690","China","CN","CHN","Yunnan","","2627000","1156546994"
"Antananarivo","Antananarivo","-18.9333","47.5167","Madagascar","MG","MDG","Antananarivo","primary","2610000","1450978461"
"Huludao","Huludao","40.7094","120.8378","China","CN","CHN","Liaoning","minor","2623541","1156580218"
"Munich","Munich","48.1375","11.5750","Germany","DE","DEU","Bavaria","admin","2606021","1276692352"
"Yanjiang","Yanjiang","30.1256","104.6397","China","CN","CHN","Sichuan","minor","2593843","1156217114"
"Santo Domingo","Santo Domingo","18.4764","-69.8933","Dominican Republic","DO","DOM","Ozama","primary","2581827","1214636202"
"Sanming","Sanming","26.2658","117.6302","China","CN","CHN","Fujian","minor","2580000","1156294429"
"Tashkent","Tashkent","41.3111","69.2797","Uzbekistan","UZ","UZB","Toshkent","primary","2571668","1860331871"
"Longba","Longba","33.5350","105.3490","China","CN","CHN","Gansu","","2567718","1156633259"
"Yangjiang","Yangjiang","21.8556","111.9627","China","CN","CHN","Guangdong","minor","2555600","1156123172"
"Jiamusi","Jiamusi","46.8081","130.3653","China","CN","CHN","Heilongjiang","minor","2552097","1156350864"
"Luohe","Luohe","33.5830","114.0109","China","CN","CHN","Henan","minor","2544266","1156330876"
"Lincang","Lincang","23.8864","100.0871","China","CN","CHN","Yunnan","","2536000","1156044130"
"Medellín","Medellin","6.2308","-75.5906","Colombia","CO","COL","Antioquia","admin","2529403","1170680389"
"Xuanzhou","Xuanzhou","30.9475","118.7518","China","CN","CHN","Anhui","","2532938","1156424311"
"Yunfu","Yunfu","22.9242","112.0353","China","CN","CHN","Guangdong","minor","2526900","1156057251"
"Shaoxing","Shaoxing","30.0000","120.5833","China","CN","CHN","Zhejiang","minor","2521964","1156893449"
"Yantai","Yantai","37.3997","121.2664","China","CN","CHN","Shandong","","2511053","1156932948"
"Huizhou","Huizhou","23.1115","114.4152","China","CN","CHN","Guangdong","minor","2509243","1156203268"
"Lishui","Lishui","28.4500","119.9167","China","CN","CHN","Zhejiang","minor","2507396","1156858793"
"Mirzāpur","Mirzapur","25.1460","82.5690","India","IN","IND","Uttar Pradesh","","2496970","1356427908"
"Hamburg","Hamburg","53.5500","10.0000","Germany","DE","DEU","Hamburg","admin","2484800","1276041799"
"Guangyuan","Guangyuan","32.4353","105.8398","China","CN","CHN","Sichuan","","2484122","1156084562"
"Cali","Cali","3.4206","-76.5222","Colombia","CO","COL","Valle del Cauca","admin","2471474","1170417589"
"Lusaka","Lusaka","-15.4167","28.2833","Zambia","ZM","ZMB","Lusaka","primary","2467563","1894157390"
"Huangshi","Huangshi","30.2018","115.0326","China","CN","CHN","Hubei","minor","2471700","1156234290"
"Xining","Xining","36.6239","101.7787","China","CN","CHN","Qinghai","admin","2467965","1156081448"
"Ouagadougou","Ouagadougou","12.3686","-1.5275","Burkina Faso","BF","BFA","Centre","primary","2453496","1854029208"
"Daegu","Daegu","35.8717","128.6017","South Korea","KR","KOR","Daegu","admin","2461002","1410095252"
"Fortaleza","Fortaleza","-3.7275","-38.5275","Brazil","BR","BRA","Ceará","admin","2452185","1076567885"
"Yaoundé","Yaounde","3.8667","11.5167","Cameroon","CM","CMR","Centre","primary","2440462","1120298240"
"Jilin","Jilin","43.8519","126.5481","China","CN","CHN","Jilin","","2396000","1156646448"
"Dandong","Dandong","40.1167","124.3833","China","CN","CHN","Liaoning","","2444697","1156178316"
"Zhuhai","Zhuhai","22.2769","113.5678","China","CN","CHN","Guangdong","minor","2439585","1156722242"
"Lianshan","Lianshan","40.7523","120.8280","China","CN","CHN","Liaoning","minor","2426000","1156046704"
"Yingkou","Yingkou","40.6653","122.2297","China","CN","CHN","Liaoning","minor","2428534","1156257074"
"Antalya","Antalya","36.8874","30.7075","Turkey","TR","TUR","Antalya","admin","2426356","1792988434"
"Nāgpur","Nagpur","21.1497","79.0806","India","IN","IND","Mahārāshtra","admin","2405665","1356365743"
"Queens","Queens","40.7498","-73.7976","United States","US","USA","New York","","2405464","1840034002"
"Accra","Accra","5.5500","-0.2000","Ghana","GH","GHA","Greater Accra","primary","2388000","1288299415"
"Bekasi","Bekasi","-6.2349","106.9923","Indonesia","ID","IDN","Jawa Barat","","2381053","1360006015"
"Ghāziābād","Ghaziabad","28.6700","77.4200","India","IN","IND","Uttar Pradesh","","2375820","1356691871"
"Yuxi","Yuxi","24.3495","102.5432","China","CN","CHN","Yunnan","minor","2386000","1156682309"
"Luoyang","Luoyang","34.6587","112.4245","China","CN","CHN","Henan","","2372571","1156069315"
"Brisbane","Brisbane","-27.4678","153.0281","Australia","AU","AUS","Queensland","admin","2360241","1036192929"
"Anshun","Anshun","26.2456","105.9340","China","CN","CHN","Guizhou","","2353100","1156415948"
"Depok","Depok","-6.3940","106.8225","Indonesia","ID","IDN","Jawa Barat","","2330333","1360962899"
"Shangzhou","Shangzhou","33.8680","109.9244","China","CN","CHN","Shaanxi","","2341742","1156091241"
"Huainan","Huainan","32.4831","117.0164","China","CN","CHN","Anhui","","2333896","1156877299"
"Colombo","Colombo","6.9344","79.8428","Sri Lanka","LK","LKA","Western","primary","2323826","1144251314"
"Kuaidamao","Kuaidamao","41.7302","125.9471","China","CN","CHN","Jilin","minor","2324439","1156106507"
"Baku","Baku","40.3667","49.8352","Azerbaijan","AZ","AZE","Bakı","primary","2300500","1031946365"
"Fukuoka","Fukuoka","33.5833","130.4000","Japan","JP","JPN","Fukuoka","admin","2286000","1392576294"
"Yan’an","Yan'an","36.5952","109.4863","China","CN","CHN","Shaanxi","","2282581","1156546342"
"Jincheng","Jincheng","35.4906","112.8483","China","CN","CHN","Shanxi","minor","2279146","1156163376"
"Vancouver","Vancouver","49.2500","-123.1000","Canada","CA","CAN","British Columbia","","2264823","1124825478"
"Nantong","Nantong","31.9829","120.8873","China","CN","CHN","Jiangsu","minor","2261382","1156045365"
"Tangerang","Tangerang","-6.1783","106.6319","Indonesia","ID","IDN","Jawa Barat","","2237006","1360002844"
"Caracas","Caracas","10.4806","-66.9036","Venezuela","VE","VEN","Distrito Capital","primary","2245744","1862748204"
"Sanmenxia","Sanmenxia","34.7736","111.1950","China","CN","CHN","Henan","","2234018","1156529682"
"Laibin","Laibin","23.7333","109.2333","China","CN","CHN","Guangxi","minor","2233900","1156925734"
"Konya","Konya","37.8667","32.4833","Turkey","TR","TUR","Konya","admin","2232374","1792014004"
"Manaus","Manaus","-3.1000","-60.0167","Brazil","BR","BRA","Amazonas","admin","2219580","1076607274"
"Eşfahān","Esfahan","32.6447","51.6675","Iran","IR","IRN","Eşfahān","admin","2219343","1364023865"
"Qinbaling","Qinbaling","35.7278","107.6400","China","CN","CHN","Gansu","","2211191","1156032582"
"Baltimore","Baltimore","39.3051","-76.6144","United States","US","USA","Maryland","","2205092","1840001592"
"Ma’anshan","Ma'anshan","31.6858","118.5101","China","CN","CHN","Anhui","minor","2202899","1156847452"
"Shengli","Shengli","37.4500","118.4667","China","CN","CHN","Shandong","minor","2193518","1156672254"
"Gaoping","Gaoping","30.7824","106.1281","China","CN","CHN","Sichuan","minor","2174000","1156522457"
"Taizhou","Taizhou","28.6583","121.4221","China","CN","CHN","Zhejiang","minor","2162461","1156322307"
"Harare","Harare","-17.8292","31.0522","Zimbabwe","ZW","ZWE","Harare","primary","2150000","1716196799"
"Kowloon","Kowloon","22.3167","114.1833","Hong Kong","HK","HKG","","","2108419","1344538284"
"Las Vegas","Las Vegas","36.2333","-115.2654","United States","US","USA","Nevada","","2150373","1840020364"
"Havana","Havana","23.1367","-82.3589","Cuba","CU","CUB","La Habana","primary","2141652","1192752771"
"Perth","Perth","-31.9559","115.8606","Australia","AU","AUS","Western Australia","admin","2141834","1036178956"
"Phnom Penh","Phnom Penh","11.5694","104.9211","Cambodia","KH","KHM","Phnom Penh","primary","2129371","1116260534"
"Puning","Puning","23.2993","116.1586","China","CN","CHN","Guangdong","","2118200","1156756494"
"Huaibei","Huaibei","33.9562","116.7890","China","CN","CHN","Anhui","","2114276","1156142626"
"Qingyang","Qingyang","24.8141","118.5792","China","CN","CHN","Fujian","minor","2111000","1156273372"
"Haiphong","Haiphong","20.8651","106.6838","Vietnam","VN","VNM","Hải Phòng","admin","2103500","1704000623"
"Chongzuo","Chongzuo","22.4167","107.3667","China","CN","CHN","Guangxi","minor","2099400","1156438670"
"Rawalpindi","Rawalpindi","33.6000","73.0333","Pakistan","PK","PAK","Punjab","minor","2098231","1586344001"
"Yushan","Yushan","31.3867","120.9766","China","CN","CHN","Jiangsu","minor","2092496","1156245000"
"St. Louis","St. Louis","38.6359","-90.2451","United States","US","USA","Missouri","","2092481","1840001651"
"Kumasi","Kumasi","6.6667","-1.6167","Ghana","GH","GHA","Ashanti","admin","2069350","1288181103"
"Vadodara","Vadodara","22.3000","73.2000","India","IN","IND","Gujarāt","","2065771","1356705249"
"Hezhou","Hezhou","24.4164","111.5478","China","CN","CHN","Guangxi","minor","2072600","1156459799"
"Pingliang","Pingliang","35.5412","106.6819","China","CN","CHN","Gansu","minor","2068033","1156216904"
"Portland","Portland","45.5371","-122.6500","United States","US","USA","Oregon","","2036875","1840019941"
"Vishākhapatnam","Vishakhapatnam","17.7042","83.2978","India","IN","IND","Andhra Pradesh","","2035922","1356692563"
"Gujranwala","Gujranwala","32.1567","74.1900","Pakistan","PK","PAK","Punjab","minor","2027001","1586400472"
"Baicheng","Baicheng","45.6148","122.8320","China","CN","CHN","Jilin","minor","2032356","1156435764"
"Gaziantep","Gaziantep","37.0628","37.3792","Turkey","TR","TUR","Gaziantep","admin","2028563","1792911843"
"Fushun","Fushun","41.8708","123.8917","China","CN","CHN","Liaoning","","2024000","1156857743"
"Riverside","Riverside","33.9381","-117.3949","United States","US","USA","California","","2022285","1840020551"
"Bamako","Bamako","12.6458","-7.9922","Mali","ML","MLI","Bamako","primary","2009109","1466965925"
"Quito","Quito","-0.2200","-78.5125","Ecuador","EC","ECU","Pichincha","primary","2011388","1218441993"
"Minsk","Minsk","53.9000","27.5667","Belarus","BY","BLR","Minsk","primary","2009786","1112348503"
"Tijuana","Tijuana","32.5250","-117.0333","Mexico","MX","MEX","Baja California","minor","2002000","1484708778"
"Bamenda","Bamenda","5.9614","10.1517","Cameroon","CM","CMR","North-West","admin","2000000","1120909610"
"Boosaaso","Boosaaso","11.2886","49.1819","Somalia","SO","SOM","Bari","admin","2000000","1706054548"
"Indore","Indore","22.7167","75.8472","India","IN","IND","Madhya Pradesh","","1994397","1356402812"
"Şanlıurfa","Sanliurfa","37.1583","38.7917","Turkey","TR","TUR","Şanlıurfa","admin","1985753","1792937570"
"Vienna","Vienna","48.2083","16.3725","Austria","AT","AUT","Wien","primary","1973403","1040261752"
"Karaj","Karaj","35.8272","50.9489","Iran","IR","IRN","Alborz","admin","1973470","1364258511"
"Kananga","Kananga","-5.8970","22.4488","Congo (Kinshasa)","CD","COD","Kasaï Central","admin","1971704","1180172460"
"Peshawar","Peshawar","34.0144","71.5675","Pakistan","PK","PAK","Khyber Pakhtunkhwa","admin","1970042","1586851102"
"Sapporo","Sapporo","43.0667","141.3500","Japan","JP","JPN","Hokkaidō","admin","1961690","1392000195"
"Ecatepec","Ecatepec","19.6097","-99.0600","Mexico","MX","MEX","México","minor","1929926","1484003694"
"Pingxiang","Pingxiang","27.6333","113.8500","China","CN","CHN","Jiangxi","minor","1933200","1156066465"
"Orlando","Orlando","28.4773","-81.3370","United States","US","USA","Florida","","1927699","1840015099"
"Aleppo","Aleppo","36.2000","37.1600","Syria","SY","SYR","Ḩalab","admin","1916781","1760366651"
"Sacramento","Sacramento","38.5677","-121.4685","United States","US","USA","California","admin","1924167","1840021491"
"Almaty","Almaty","43.2775","76.8958","Kazakhstan","KZ","KAZ","","admin","1916822","1398351701"
"San Juan","San Juan","18.3985","-66.0610","Puerto Rico","PR","PRI","Puerto Rico","primary","1915105","1630035577"
"San Antonio","San Antonio","29.4632","-98.5238","United States","US","USA","Texas","","1910785","1840022220"
"Yinchuan","Yinchuan","38.4795","106.2254","China","CN","CHN","Ningxia","admin","1901793","1156359072"
"Thāne","Thane","19.1972","72.9722","India","IN","IND","Mahārāshtra","","1886941","1356681152"
"Santos","Santos","-23.9369","-46.3250","Brazil","BR","BRA","São Paulo","","1897551","1076033299"
"Blantyre","Blantyre","-15.7861","35.0058","Malawi","MW","MWI","Blantyre","admin","1895973","1454145012"
"Bucharest","Bucharest","44.4325","26.1039","Romania","RO","ROU","Bucureşti","primary","1883425","1642414442"
"Curitiba","Curitiba","-25.4297","-49.2711","Brazil","BR","BRA","Paraná","admin","1879355","1076701712"
"Multan","Multan","30.1978","71.4697","Pakistan","PK","PAK","Punjab","minor","1871843","1586256291"
"Tainan","Tainan","22.9833","120.1833","Taiwan","TW","TWN","Tainan","admin","1874686","1158061376"
"Xiping","Xiping","40.0820","113.2981","China","CN","CHN","Shanxi","minor","1873000","1156898522"
"Port Harcourt","Port Harcourt","4.8242","7.0336","Nigeria","NG","NGA","Rivers","admin","1865000","1566070649"
"Warsaw","Warsaw","52.2300","21.0111","Poland","PL","POL","Mazowieckie","primary","1860281","1616024847"
"Jixi","Jixi","45.2937","130.9650","China","CN","CHN","Heilongjiang","minor","1862165","1156393765"
"Saidu Sharif","Saidu Sharif","34.7500","72.3572","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","1860310","1586619596"
"Liaoyang","Liaoyang","41.2643","123.1772","China","CN","CHN","Liaoning","","1858768","1156202749"
"Beihai","Beihai","21.4667","109.1000","China","CN","CHN","Guangxi","minor","1853227","1156421449"
"Meru","Meru","0.0500","37.6500","Kenya","KE","KEN","Meru","admin","1833000","1404588252"
"Brazzaville","Brazzaville","-4.2694","15.2714","Congo (Brazzaville)","CG","COG","Brazzaville","primary","1827000","1178340306"
"Fuxin","Fuxin","42.0127","121.6486","China","CN","CHN","Liaoning","","1819339","1156141954"
"Wuwei","Wuwei","37.9278","102.6329","China","CN","CHN","Gansu","minor","1815059","1156760291"
"Mersin","Mersin","36.8000","34.6333","Turkey","TR","TUR","Mersin","admin","1814468","1792520282"
"Bhopāl","Bhopal","23.2500","77.4167","India","IN","IND","Madhya Pradesh","admin","1798218","1356734039"
"Lubumbashi","Lubumbashi","-11.6647","27.4794","Congo (Kinshasa)","CD","COD","Haut-Katanga","admin","1786397","1180506234"
"Denpasar","Denpasar","-8.6500","115.2167","Indonesia","ID","IDN","Bali","admin","1779122","1360848527"
"Davao","Davao","7.0667","125.6000","Philippines","PH","PHL","Davao","admin","1776949","1608906877"
"Shuyangzha","Shuyangzha","34.1299","118.7734","China","CN","CHN","Jiangsu","","1770000","1156051957"
"Adana","Adana","37.0000","35.3213","Turkey","TR","TUR","Adana","admin","1765981","1792783772"
"Damascus","Damascus","33.5131","36.2919","Syria","SY","SYR","Dimashq","primary","1754000","1760685964"
"Brussels","Brussels","50.8467","4.3525","Belgium","BE","BEL","Brussels-Capital Region","primary","1743000","1056469830"
"Hyderabad City","Hyderabad City","25.3792","68.3683","Pakistan","PK","PAK","Sindh","minor","1732693","1586185019"
"Diyarbakır","Diyarbakir","37.9100","40.2400","Turkey","TR","TUR","Diyarbakır","admin","1732396","1792536241"
"San Jose","San Jose","37.3012","-121.8480","United States","US","USA","California","","1729879","1840021570"
"Chinchvad","Chinchvad","18.6186","73.8037","India","IN","IND","Mahārāshtra","","1729320","1356475483"
"Montevideo","Montevideo","-34.8836","-56.1819","Uruguay","UY","URY","Montevideo","primary","1719453","1858107000"
"Pittsburgh","Pittsburgh","40.4397","-79.9763","United States","US","USA","Pennsylvania","","1720279","1840001254"
"Shuozhou","Shuozhou","39.3408","112.4292","China","CN","CHN","Shanxi","minor","1714857","1156227167"
"Cincinnati","Cincinnati","39.1413","-84.5060","United States","US","USA","Ohio","","1712287","1840003814"
"Benxi","Benxi","41.2920","123.7610","China","CN","CHN","Liaoning","","1709538","1156097909"
"Baiyin","Baiyin","36.5448","104.1766","China","CN","CHN","Gansu","minor","1708751","1156140738"
"Mosul","Mosul","36.3400","43.1300","Iraq","IQ","IRQ","Nīnawá","admin","1694000","1368194914"
"Manhattan","Manhattan","40.7834","-73.9662","United States","US","USA","New York","","1694263","1840034000"
"Caloocan City","Caloocan City","14.6500","120.9700","Philippines","PH","PHL","Caloocan","admin","1661584","1608897690"
"Kampala","Kampala","0.3136","32.5811","Uganda","UG","UGA","Kampala","primary","1680600","1800406299"
"Patna","Patna","25.6000","85.1000","India","IN","IND","Bihār","admin","1684222","1356821429"
"Tegucigalpa","Tegucigalpa","14.1000","-87.2167","Honduras","HN","HND","Francisco Morazán","primary","1682725","1340344059"
"Cleveland","Cleveland","41.4764","-81.6805","United States","US","USA","Ohio","","1683059","1840000596"
"Sanzhou","Sanzhou","30.8200","108.4000","China","CN","CHN","Chongqing","","1680000","1156025536"
"Changshu","Changshu","31.6500","120.7333","China","CN","CHN","Jiangsu","","1677050","1156901538"
"Mecca","Mecca","21.4225","39.8233","Saudi Arabia","SA","SAU","Makkah al Mukarramah","admin","1675368","1682169241"
"Heihe","Heihe","50.2458","127.4886","China","CN","CHN","Heilongjiang","minor","1673899","1156339225"
"Jingdezhen","Jingdezhen","29.2942","117.2036","China","CN","CHN","Jiangxi","minor","1673200","1156038165"
"Conakry","Conakry","9.5092","-13.7122","Guinea","GN","GIN","Conakry","primary","1667864","1324568159"
"Recife","Recife","-8.0500","-34.9000","Brazil","BR","BRA","Pernambuco","admin","1653461","1076137337"
"Indianapolis","Indianapolis","39.7771","-86.1458","United States","US","USA","Indiana","admin","1659305","1840030084"
"Austin","Austin","30.3005","-97.7522","United States","US","USA","Texas","admin","1659251","1840019590"
"Sangereng","Sangereng","-6.2889","106.7181","Indonesia","ID","IDN","Jawa Barat","","1644899","1360029147"
"Kansas City","Kansas City","39.1238","-94.5541","United States","US","USA","Missouri","","1644497","1840008535"
"Zhongli","Zhongli","24.9650","121.2168","Taiwan","TW","TWN","Taoyuan","","1632616","1158025380"
"Novosibirsk","Novosibirsk","55.0333","82.9167","Russia","RU","RUS","Novosibirskaya Oblast’","admin","1625631","1643399240"
"Bilāspur","Bilaspur","22.0900","82.1500","India","IN","IND","Chhattīsgarh","","1625502","1356820491"
"Semarang","Semarang","-6.9667","110.4167","Indonesia","ID","IDN","Jawa Tengah","admin","1621384","1360745537"
"Ludhiāna","Ludhiana","30.9100","75.8500","India","IN","IND","Punjab","","1618879","1356929498"
"Nārāyanganj","Narayanganj","23.6200","90.5000","Bangladesh","BD","BGD","Dhaka","","1572386","1050424421"
"Stockholm","Stockholm","59.3294","18.0686","Sweden","SE","SWE","Stockholm","primary","1611776","1752425602"
"Chengtangcun","Chengtangcun","35.0833","117.1500","China","CN","CHN","Shandong","","1603659","1156883901"
"Āgra","Agra","27.1800","78.0200","India","IN","IND","Uttar Pradesh","","1585704","1356187395"
"Balandougou","Balandougou","13.3558","-9.5517","Mali","ML","MLI","Kayes","","1596882","1466367944"
"Agwār","Agwar","27.1800","78.0200","India","IN","IND","Uttar Pradesh","","1585705","1356151564"
"León de los Aldama","Leon de los Aldama","21.1167","-101.6833","Mexico","MX","MEX","Guanajuato","minor","1579803","1484811199"
"Yopougon","Yopougon","5.3167","-4.0667","Côte d'Ivoire","CI","CIV","Abidjan","","1571065","1384759584"
"Puebla","Puebla","19.0333","-98.1833","Mexico","MX","MEX","Puebla","admin","1576259","1484416394"
"Madurai","Madurai","9.9252","78.1198","India","IN","IND","Tamil Nādu","minor","1561129","1356257289"
"Hebi","Hebi","35.7497","114.2887","China","CN","CHN","Henan","","1569208","1156114258"
"Córdoba","Cordoba","-31.4167","-64.1833","Argentina","AR","ARG","Córdoba","admin","1565112","1032803249"
"Shīrāz","Shiraz","29.6100","52.5425","Iran","IR","IRN","Fārs","admin","1565572","1364432238"
"Jamshedpur","Jamshedpur","22.7925","86.1842","India","IN","IND","Jhārkhand","","1558000","1356501259"
"Tabrīz","Tabriz","38.0814","46.3006","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","admin","1558693","1364141756"
"Huzhou","Huzhou","30.8925","120.0875","China","CN","CHN","Zhejiang","minor","1558826","1156335543"
"Columbus","Columbus","39.9862","-82.9855","United States","US","USA","Ohio","admin","1556848","1840003760"
"Sofia","Sofia","42.7000","23.3300","Bulgaria","BG","BGR","Sofia-Grad","primary","1547779","1100762037"
"Kawasaki","Kawasaki","35.5167","139.7000","Japan","JP","JPN","Kanagawa","","1539522","1392003356"
"San José","San Jose","9.9325","-84.0800","Costa Rica","CR","CRI","San José","primary","1543000","1188749877"
"Aba","Aba","5.1167","7.3667","Nigeria","NG","NGA","Abia","minor","1530000","1566262000"
"Palembang","Palembang","-2.9861","104.7556","Indonesia","ID","IDN","Sumatera Selatan","admin","1535952","1360902897"
"Zhangjiajie","Zhangjiajie","29.1255","110.4844","China","CN","CHN","Hunan","minor","1537900","1156072884"
"Kōbe","Kobe","34.6900","135.1956","Japan","JP","JPN","Hyōgo","admin","1521707","1392978082"
"Jiaxing","Jiaxing","30.7522","120.7500","China","CN","CHN","Zhejiang","minor","1518654","1156491661"
"Charlotte","Charlotte","35.2083","-80.8303","United States","US","USA","North Carolina","","1516107","1840014557"
"Guiping","Guiping","23.4000","110.0833","China","CN","CHN","Guangxi","minor","1511011","1156165517"
"Lianjiang","Lianjiang","21.6146","110.2794","China","CN","CHN","Guangdong","","1509400","1156944106"
"Ximeicun","Ximeicun","24.9633","118.3811","China","CN","CHN","Fujian","","1508000","1156173813"
"Jianguang","Jianguang","28.1958","115.7833","China","CN","CHN","Jiangxi","","1507402","1156545200"
"Yucheng","Yucheng","29.9888","103.0007","China","CN","CHN","Sichuan","minor","1507258","1156817119"
"Panama City","Panama City","8.9833","-79.5167","Panama","PA","PAN","Panamá","primary","1500189","1591672475"
"Xushan","Xushan","30.1697","121.2665","China","CN","CHN","Zhejiang","minor","1502000","1156046916"
"Belém","Belem","-1.4558","-48.5039","Brazil","BR","BRA","Pará","admin","1499641","1076646823"
"Virginia Beach","Virginia Beach","36.7335","-76.0435","United States","US","USA","Virginia","","1500764","1840003871"
"Leizhou","Leizhou","20.9147","110.0806","China","CN","CHN","Guangdong","minor","1494700","1156863840"
"Gwangju","Gwangju","35.1653","126.8486","South Korea","KR","KOR","Gwangju","admin","1490092","1410000087"
"Nāsik","Nasik","20.0000","73.7800","India","IN","IND","Mahārāshtra","","1486053","1356562598"
"Porto Alegre","Porto Alegre","-30.0331","-51.2300","Brazil","BR","BRA","Rio Grande do Sul","admin","1484941","1076658221"
"Valencia","Valencia","10.1667","-68.0000","Venezuela","VE","VEN","Carabobo","admin","1484430","1862309871"
"Onitsha","Onitsha","6.1667","6.7833","Nigeria","NG","NGA","Anambra","minor","1483000","1566593483"
"Abu Dhabi","Abu Dhabi","24.4667","54.3667","United Arab Emirates","AE","ARE","Abū Z̧aby","primary","1483000","1784176710"
"Daejeon","Daejeon","36.3510","127.3850","South Korea","KR","KOR","Daejeon","admin","1475221","1410993875"
"Zapopan","Zapopan","20.7167","-103.4000","Mexico","MX","MEX","Jalisco","minor","1476491","1484198680"
"Bronx","Bronx","40.8501","-73.8662","United States","US","USA","New York","","1472654","1840033999"
"Yekaterinburg","Yekaterinburg","56.8356","60.6128","Russia","RU","RUS","Sverdlovskaya Oblast’","admin","1468833","1643582706"
"Huazhou","Huazhou","32.6832","112.0790","China","CN","CHN","Henan","minor","1468061","1156796734"
"Kyōto","Kyoto","35.0117","135.7683","Japan","JP","JPN","Kyōto","admin","1464890","1392622735"
"Jinhua","Jinhua","29.1046","119.6494","China","CN","CHN","Zhejiang","minor","1463990","1156547188"
"Amsterdam","Amsterdam","52.3728","4.8936","Netherlands","NL","NLD","Noord-Holland","primary","1459402","1528355309"
"Shuangyashan","Shuangyashan","46.6388","131.1545","China","CN","CHN","Heilongjiang","minor","1462626","1156245904"
"Pizhou","Pizhou","34.3422","118.0097","China","CN","CHN","Jiangsu","","1458038","1156114238"
"El Kelaa des Srarhna","El Kelaa des Srarhna","32.0481","-7.4083","Morocco","MA","MAR","Marrakech-Safi","","1450021","1504846488"
"Dakar","Dakar","14.6928","-17.4467","Senegal","SN","SEN","Dakar","primary","1438725","1686604760"
"Kharkiv","Kharkiv","49.9925","36.2311","Ukraine","UA","UKR","Kharkivska Oblast","admin","1446107","1804588111"
"Yangshe","Yangshe","31.8775","120.5512","China","CN","CHN","Jiangsu","minor","1432044","1156203000"
"Guyuan","Guyuan","36.0080","106.2782","China","CN","CHN","Ningxia","","1426000","1156817541"
"Rui’an","Rui'an","27.7780","120.6526","China","CN","CHN","Zhejiang","","1424667","1156993705"
"Khulna","Khulna","22.8167","89.5500","Bangladesh","BD","BGD","Khulna","admin","1400689","1050667339"
"Muscat","Muscat","23.6139","58.5922","Oman","OM","OMN","Masqaţ","primary","1421409","1512035506"
"Wenling","Wenling","28.3797","121.3718","China","CN","CHN","Zhejiang","","1416199","1156052493"
"Gaozhou","Gaozhou","21.9135","110.8481","China","CN","CHN","Guangdong","minor","1414100","1156785303"
"Farīdābād","Faridabad","28.4167","77.3000","India","IN","IND","Haryāna","","1404653","1356013205"
"Chizhou","Chizhou","30.6583","117.4849","China","CN","CHN","Anhui","minor","1402518","1156131504"
"Tel Aviv-Yafo","Tel Aviv-Yafo","32.08","34.78","Israel","IL","ISR","Tel Aviv","admin","1388400","1376401542"
"Ulaanbaatar","Ulaanbaatar","47.9203","106.9172","Mongolia","MN","MNG","Ulaanbaatar","primary","1396288","1496024767"
"Goiânia","Goiania","-16.6667","-49.2500","Brazil","BR","BRA","Goiás","admin","1393575","1076034054"
"Fuqing","Fuqing","25.7232","119.3735","China","CN","CHN","Fujian","minor","1390487","1156893569"
"Kayseri","Kayseri","38.7225","35.4875","Turkey","TR","TUR","Kayseri","admin","1389680","1792945815"
"Wuzhong","Wuzhong","37.9874","106.1919","China","CN","CHN","Ningxia","minor","1382713","1156139322"
"Belgrade","Belgrade","44.8200","20.4600","Serbia","RS","SRB","Beograd","primary","1378682","1688374696"
"Pingdu","Pingdu","36.7833","119.9556","China","CN","CHN","Shandong","","1378900","1156157078"
"Milan","Milan","45.4669","9.1900","Italy","IT","ITA","Lombardy","admin","1366180","1380724377"
"Aurangābād","Aurangabad","19.8800","75.3200","India","IN","IND","Mahārāshtra","","1371330","1356840670"
"Copenhagen","Copenhagen","55.6761","12.5683","Denmark","DK","DNK","Hovedstaden","primary","1366301","1208763942"
"Yangquan","Yangquan","37.8576","113.5762","China","CN","CHN","Shanxi","minor","1368502","1156157844"
"Yutan","Yutan","28.3147","112.5540","China","CN","CHN","Hunan","minor","1368117","1156592042"
"Huangshan","Huangshan","29.7132","118.3151","China","CN","CHN","Anhui","minor","1358980","1156527426"
"Auckland","Auckland","-36.8406","174.7400","New Zealand","NZ","NZL","Auckland","admin","1346091","1554435911"
"Makassar","Makassar","-5.1619","119.4362","Indonesia","ID","IDN","Sulawesi Selatan","admin","1338663","1360051337"
"Santiago","Santiago","19.4572","-70.6889","Dominican Republic","DO","DOM","Cibao Norte","admin","1343423","1214985348"
"Milwaukee","Milwaukee","43.0642","-87.9675","United States","US","USA","Wisconsin","","1340981","1840003046"
"Rājkot","Rajkot","22.3000","70.7833","India","IN","IND","Gujarāt","","1335397","1356317190"
"Prague","Prague","50.0875","14.4214","Czechia","CZ","CZE","Praha","primary","1335084","1203744823"
"Samsun","Samsun","41.2903","36.3336","Turkey","TR","TUR","Samsun","admin","1335716","1792169057"
"Liangshi","Liangshi","27.2578","111.7351","China","CN","CHN","Hunan","","1335900","1156366507"
"Barranquilla","Barranquilla","10.9833","-74.8019","Colombia","CO","COL","Atlántico","admin","1326588","1170179113"
"Saitama","Saitama","35.8614","139.6456","Japan","JP","JPN","Saitama","admin","1325843","1392017719"
"Guarulhos","Guarulhos","-23.4628","-46.5328","Brazil","BR","BRA","São Paulo","","1324781","1076730420"
"Al Başrah","Al Basrah","30.5150","47.8100","Iraq","IQ","IRQ","Al Başrah","admin","1326564","1368714645"
"Mandalay","Mandalay","21.9831","96.0844","Myanmar","MM","MMR","Mandalay","admin","1319452","1104468217"
"Juárez","Juarez","31.7386","-106.4870","Mexico","MX","MEX","Chihuahua","minor","1321004","1484840083"
"Xintai","Xintai","35.9100","117.7800","China","CN","CHN","Shandong","","1315942","1156761230"
"Wusong","Wusong","30.9333","117.7667","China","CN","CHN","Anhui","minor","1311726","1156264408"
"Meerut","Meerut","28.9800","77.7100","India","IN","IND","Uttar Pradesh","","1305429","1356390476"
"Yushu","Yushu","44.8249","126.5451","China","CN","CHN","Jilin","minor","1304436","1156326773"
"Rongcheng","Rongcheng","26.2312","104.0966","China","CN","CHN","Yunnan","","1302891","1156122888"
"Huazhou","Huazhou","21.6540","110.6294","China","CN","CHN","Guangdong","","1302100","1156282637"
"Adelaide","Adelaide","-34.9275","138.6000","Australia","AU","AUS","South Australia","admin","1295714","1036538171"
"Baishan","Baishan","41.9377","126.4179","China","CN","CHN","Jilin","minor","1296127","1156960624"
"Dayan","Dayan","26.8808","100.2208","China","CN","CHN","Yunnan","","1296000","1156828380"
"Haicheng","Haicheng","40.8523","122.7474","China","CN","CHN","Liaoning","minor","1293877","1156052713"
"Tripoli","Tripoli","32.8872","13.1914","Libya","LY","LBY","Ţarābulus","primary","1293016","1434201852"
"Jiangyin","Jiangyin","31.9087","120.2653","China","CN","CHN","Jiangsu","","1285785","1156033234"
"Yicheng","Yicheng","31.3697","119.8239","China","CN","CHN","Jiangsu","minor","1285785","1156292419"
"Huaiyin","Huaiyin","33.5819","119.0280","China","CN","CHN","Jiangsu","","1264000","1156662372"
"Porto","Porto","41.1621","-8.6220","Portugal","PT","PRT","Porto","admin","1278210","1620356810"
"Cacuaco","Cacuaco","-8.8053","13.2444","Angola","AO","AGO","Luanda","","1279488","1024559448"
"Soweto","Soweto","-26.2678","27.8585","South Africa","ZA","ZAF","Gauteng","","1271628","1710627007"
"Rosario","Rosario","-32.9575","-60.6394","Argentina","AR","ARG","Santa Fe","minor","1276000","1032770677"
"Canagatan","Canagatan","18.0000","121.8000","Philippines","PH","PHL","Cagayan","","1273219","1608697675"
"Helsinki","Helsinki","60.1708","24.9375","Finland","FI","FIN","Uusimaa","primary","1268296","1246177997"
"Jabalpur","Jabalpur","23.1667","79.9333","India","IN","IND","Madhya Pradesh","","1267564","1356115460"
"Providence","Providence","41.8230","-71.4187","United States","US","USA","Rhode Island","admin","1270149","1840003289"
"Rucheng","Rucheng","32.3852","120.5634","China","CN","CHN","Jiangsu","minor","1267066","1156323529"
"Nizhniy Novgorod","Nizhniy Novgorod","56.3269","44.0075","Russia","RU","RUS","Nizhegorodskaya Oblast’","admin","1264075","1643012126"
"Ahvāz","Ahvaz","31.3203","48.6692","Iran","IR","IRN","Khūzestān","admin","1261042","1364350188"
"Jepara","Jepara","-6.5333","110.6667","Indonesia","ID","IDN","Jawa Tengah","minor","1257912","1360703818"
"Shaoyang","Shaoyang","32.9387","119.8404","China","CN","CHN","Jiangsu","minor","1253548","1156668366"
"Comayagüela","Comayaguela","14.0981","-87.2097","Honduras","HN","HND","Francisco Morazán","","1250000","1340762485"
"Laiwu","Laiwu","36.1833","117.6667","China","CN","CHN","Shandong","","1248636","1156207332"
"Sharjah","Sharjah","25.3575","55.3908","United Arab Emirates","AE","ARE","Ash Shāriqah","admin","1247749","1784152993"
"Kalamboli","Kalamboli","19.2333","73.1333","India","IN","IND","Mahārāshtra","","1246381","1356537020"
"Jingling","Jingling","30.6667","113.1667","China","CN","CHN","Hubei","","1247400","1156060544"
"Kazan","Kazan","55.7964","49.1089","Russia","RU","RUS","Tatarstan","admin","1243500","1643101260"
"Suwon","Suwon","37.2667","127.0167","South Korea","KR","KOR","Gyeonggi","admin","1234300","1410679777"
"Yongcheng","Yongcheng","33.9317","116.4459","China","CN","CHN","Henan","","1240382","1156841802"
"Sumedang","Sumedang","-6.8400","107.9208","Indonesia","ID","IDN","Jawa Barat","","1240000","1360564479"
"Calgary","Calgary","51.0500","-114.0667","Canada","CA","CAN","Alberta","","1239220","1124690423"
"Cần Thơ","Can Tho","10.0333","105.7833","Vietnam","VN","VNM","Cần Thơ","admin","1237300","1704783472"
"Yiwu","Yiwu","29.3081","120.0698","China","CN","CHN","Zhejiang","minor","1234015","1156078044"
"Bagam","Bagam","1.0833","104.0333","Indonesia","ID","IDN","Kepulauan Riau","","1230097","1360405604"
"Beidao","Beidao","34.6020","105.9180","China","CN","CHN","Gansu","minor","1225000","1156137209"
"Vasai","Vasai","19.4700","72.8000","India","IN","IND","Mahārāshtra","","1222390","1356707797"
"Xiangshui","Xiangshui","26.5964","104.8314","China","CN","CHN","Guizhou","","1221000","1156328867"
"Jacksonville","Jacksonville","30.3322","-81.6749","United States","US","USA","Florida","","1220191","1840015031"
"Akçaabat","Akcaabat","41.0167","39.5500","Turkey","TR","TUR","Trabzon","minor","1215351","1792536331"
"Campinas","Campinas","-22.9058","-47.0608","Brazil","BR","BRA","São Paulo","","1213792","1076145766"
"Dadukou","Dadukou","26.5849","101.7149","China","CN","CHN","Sichuan","minor","1214121","1156901342"
"Mombasa","Mombasa","-4.0500","39.6667","Kenya","KE","KEN","Mombasa","admin","1208333","1404512373"
"Lingcheng","Lingcheng","22.7000","110.3500","China","CN","CHN","Guangxi","","1211637","1156202191"
"Najafgarh","Najafgarh","28.6092","76.9798","India","IN","IND","Delhi","","1203180","1356180206"
"Vila Velha","Vila Velha","3.2167","-51.2167","Brazil","BR","BRA","Amapá","","1209091","1076575691"
"Gāzipura","Gazipura","23.9889","90.3750","Bangladesh","BD","BGD","Dhaka","","1199215","1050449249"
"Chelyabinsk","Chelyabinsk","55.1500","61.4000","Russia","RU","RUS","Chelyabinskaya Oblast’","admin","1202371","1643224011"
"Vārānasi","Varanasi","25.3189","83.0128","India","IN","IND","Uttar Pradesh","","1198491","1356145782"
"Xinyu","Xinyu","27.7950","114.9242","China","CN","CHN","Jiangxi","minor","1202499","1156138735"
"Qom","Qom","34.6400","50.8764","Iran","IR","IRN","Qom","admin","1201158","1364701633"
"Hargeysa","Hargeysa","9.5631","44.0675","Somalia","SO","SOM","Woqooyi Galbeed","admin","1200000","1706832021"
"Zhangye","Zhangye","38.9355","100.4553","China","CN","CHN","Gansu","minor","1199515","1156180353"
"Hiroshima","Hiroshima","34.3914","132.4519","Japan","JP","JPN","Hiroshima","admin","1198021","1392373695"
"Maiduguri","Maiduguri","11.8333","13.1500","Nigeria","NG","NGA","Borno","admin","1197497","1566540296"
"Chiang Mai","Chiang Mai","18.7953","98.9986","Thailand","TH","THA","Chiang Mai","admin","1198000","1764662886"
"Doha","Doha","25.2867","51.5333","Qatar","QA","QAT","Ad Dawḩah","primary","1186023","1634459660"
"Maputo","Maputo","-25.9667","32.5833","Mozambique","MZ","MOZ","Maputo","primary","1191613","1508074843"
"Mbandaka","Mbandaka","0.0478","18.2558","Congo (Kinshasa)","CD","COD","Équateur","admin","1187837","1180720887"
"Pikine","Pikine","14.7500","-17.4000","Senegal","SN","SEN","Dakar","","1170791","1686165071"
"Medina","Medina","24.4700","39.6100","Saudi Arabia","SA","SAU","Al Madīnah al Munawwarah","admin","1180770","1682345785"
"Srīnagar","Srinagar","34.0900","74.7900","India","IN","IND","Jammu and Kashmīr","admin","1180570","1356442950"
"Omsk","Omsk","54.9667","73.3833","Russia","RU","RUS","Omskaya Oblast’","admin","1178391","1643360863"
"Dublin","Dublin","53.3500","-6.2603","Ireland","IE","IRL","Dublin","primary","1173179","1372595407"
"Liaoyuan","Liaoyuan","42.8976","125.1381","China","CN","CHN","Jilin","","1176239","1156249772"
"Cilacap","Cilacap","-7.7167","109.0170","Indonesia","ID","IDN","Jawa Tengah","minor","1174964","1360503809"
"Yingtan","Yingtan","28.2333","117.0000","China","CN","CHN","Jiangxi","","1175000","1156301061"
"Bandar Lampung","Bandar Lampung","-5.4500","105.2667","Indonesia","ID","IDN","Lampung","admin","1166761","1360243491"
"Samara","Samara","53.2028","50.1408","Russia","RU","RUS","Samarskaya Oblast’","admin","1169719","1643205757"
"Guankou","Guankou","28.1417","113.6250","China","CN","CHN","Hunan","minor","1168056","1156249346"
"Ulsan","Ulsan","35.5500","129.3167","South Korea","KR","KOR","Ulsan","admin","1166033","1410134602"
"Dhanbād","Dhanbad","23.7998","86.4305","India","IN","IND","Jhārkhand","","1162472","1356103392"
"Dingzhou","Dingzhou","38.5158","114.9845","China","CN","CHN","Hebei","","1165182","1156656582"
"Lianyuan","Lianyuan","27.6961","111.6659","China","CN","CHN","Hunan","minor","1162928","1156490109"
"Rongcheng","Rongcheng","29.8239","112.9019","China","CN","CHN","Hubei","minor","1162770","1156102432"
"Kaiyuan","Kaiyuan","36.0656","113.8153","China","CN","CHN","Henan","minor","1160000","1156441554"
"Nay Pyi Taw","Nay Pyi Taw","19.7475","96.1150","Myanmar","MM","MMR","Nay Pyi Taw","primary","1160242","1104838105"
"Zhuji","Zhuji","29.7169","120.2314","China","CN","CHN","Zhejiang","minor","1157938","1156504227"
"Kigali","Kigali","-1.9536","30.0606","Rwanda","RW","RWA","Kigali","primary","1156663","1646923541"
"Bukavu","Bukavu","-2.5000","28.8667","Congo (Kinshasa)","CD","COD","Sud-Kivu","admin","1133000","1180475821"
"Leiyang","Leiyang","26.4179","112.8457","China","CN","CHN","Hunan","minor","1151554","1156057789"
"Bafoussam","Bafoussam","5.4667","10.4167","Cameroon","CM","CMR","Ouest","admin","1146000","1120435460"
"Yichun","Yichun","47.7235","128.8893","China","CN","CHN","Heilongjiang","minor","1148126","1156757274"
"Benin City","Benin City","6.3333","5.6222","Nigeria","NG","NGA","Edo","admin","1147188","1566080151"
"Rostov","Rostov","47.2333","39.7000","Russia","RU","RUS","Rostovskaya Oblast’","admin","1137704","1643013518"
"Xiantao","Xiantao","30.3833","113.4000","China","CN","CHN","Hubei","","1140100","1156216927"
"Amritsar","Amritsar","31.6400","74.8600","India","IN","IND","Punjab","","1132383","1356926507"
"Callao","Callao","-12.0333","-77.1333","Peru","PE","PER","Callao","admin","1129854","1604422141"
"Salt Lake City","Salt Lake City","40.7776","-111.9311","United States","US","USA","Utah","admin","1135344","1840021383"
"Alīgarh","Aligarh","27.8800","78.0800","India","IN","IND","Uttar Pradesh","","1131160","1356076391"
"Shagamu","Shagamu","6.8333","3.6500","Nigeria","NG","NGA","Ogun","minor","1132270","1566202131"
"Yingchuan","Yingchuan","34.1511","113.4733","China","CN","CHN","Henan","minor","1131896","1156748426"
"Ciudad Nezahualcóyotl","Ciudad Nezahualcoyotl","19.4081","-99.0186","Mexico","MX","MEX","México","","1109363","1484016661"
"Tbilisi","Tbilisi","41.7225","44.7925","Georgia","GE","GEO","Tbilisi","primary","1118035","1268203191"
"Guwāhāti","Guwahati","26.1722","91.7458","India","IN","IND","Assam","","1116267","1356593800"
"Ufa","Ufa","54.7261","55.9475","Russia","RU","RUS","Bashkortostan","admin","1115560","1643722928"
"Fès","Fes","34.0433","-5.0033","Morocco","MA","MAR","Fès-Meknès","admin","1112072","1504861997"
"São Luís","Sao Luis","-2.5283","-44.3044","Brazil","BR","BRA","Maranhão","admin","1108975","1076819308"
"Biên Hòa","Bien Hoa","10.9500","106.8167","Vietnam","VN","VNM","Đồng Nai","admin","1104000","1704863046"
"Sevilla","Sevilla","37.2400","-5.9900","Spain","ES","ESP","Andalusia","admin","1100000","1724991838"
"N’Djamena","N'Djamena","12.1100","15.0500","Chad","TD","TCD","N’Djamena","primary","1092066","1148708596"
"Mexicali","Mexicali","32.6633","-115.4678","Mexico","MX","MEX","Baja California","admin","1102342","1484788702"
"Nezahualcóyotl","Nezahualcoyotl","19.4006","-99.0148","Mexico","MX","MEX","México","minor","1077208","1484074285"
"Ikare","Ikare","7.5167","5.7500","Nigeria","NG","NGA","Ondo","minor","1099931","1566683694"
"Nashville","Nashville","36.1715","-86.7842","United States","US","USA","Tennessee","admin","1098486","1840036155"
"Tamale","Tamale","9.4075","-0.8533","Ghana","GH","GHA","Northern","admin","1095808","1288890688"
"Xibeijie","Xibeijie","39.7370","98.5049","China","CN","CHN","Gansu","minor","1095947","1156960560"
"Yuyao","Yuyao","30.0372","121.1546","China","CN","CHN","Zhejiang","","1095000","1156221984"
"Hāora","Haora","22.5800","88.3294","India","IN","IND","West Bengal","","1077075","1356986516"
"Hanchuan","Hanchuan","30.6520","113.8274","China","CN","CHN","Hubei","","1092700","1156149248"
"Gongzhuling","Gongzhuling","43.5036","124.8088","China","CN","CHN","Jilin","minor","1092692","1156696810"
"Krasnoyarsk","Krasnoyarsk","56.0089","92.8719","Russia","RU","RUS","Krasnoyarskiy Kray","admin","1083865","1643544975"
"Cologne","Cologne","50.9364","6.9528","Germany","DE","DEU","North Rhine-Westphalia","","1083498","1276015998"
"Bujumbura","Bujumbura","-3.3833","29.3667","Burundi","BI","BDI","Bujumbura Mairie","primary","1075000","1108101689"
"Bishkek","Bishkek","42.8747","74.6122","Kyrgyzstan","KG","KGZ","Bishkek","primary","1074075","1417191971"
"Zhufeng","Zhufeng","36.0000","119.4167","China","CN","CHN","Shandong","","1081960","1156522825"
"São Gonçalo","Sao Goncalo","-22.8269","-43.0539","Brazil","BR","BRA","Rio de Janeiro","","1077687","1076485783"
"Yerevan","Yerevan","40.1814","44.5144","Armenia","AM","ARM","Yerevan","primary","1075800","1051074169"
"Ezhou","Ezhou","30.3972","114.8842","China","CN","CHN","Hubei","minor","1079353","1156414637"
"Nur-Sultan","Nur-Sultan","51.1472","71.4222","Kazakhstan","KZ","KAZ","","primary","1078362","1398516045"
"Tongjin","Tongjin","37.2333","127.2000","South Korea","KR","KOR","Gyeonggi","","1076369","1410160862"
"Nouakchott","Nouakchott","18.0858","-15.9785","Mauritania","MR","MRT","Nouakchott Ouest","primary","1077169","1478414984"
"Xiashi","Xiashi","30.5333","120.6833","China","CN","CHN","Zhejiang","minor","1076199","1156080077"
"Rānchi","Ranchi","23.3600","85.3300","India","IN","IND","Jhārkhand","admin","1073440","1356110588"
"Taixing","Taixing","32.1724","120.0142","China","CN","CHN","Jiangsu","minor","1073921","1156331200"
"Vereeniging","Vereeniging","-26.6736","27.9319","South Africa","ZA","ZAF","Gauteng","","1074000","1710271883"
"Gwalior","Gwalior","26.2215","78.1780","India","IN","IND","Madhya Pradesh","","1069276","1356363967"
"Zhongwei","Zhongwei","37.5139","105.1884","China","CN","CHN","Ningxia","minor","1067336","1156956616"
"Goyang","Goyang","37.6500","126.8000","South Korea","KR","KOR","Gyeonggi","minor","1061929","1410511255"
"Oslo","Oslo","59.9133","10.7389","Norway","NO","NOR","Oslo","primary","1064235","1578324706"
"Vijayavāda","Vijayawada","16.5193","80.6305","India","IN","IND","Andhra Pradesh","","1048240","1356807575"
"Chandīgarh","Chandigarh","30.7500","76.7800","India","IN","IND","Chandīgarh","admin","1055450","1356739179"
"Edmonton","Edmonton","53.5344","-113.4903","Canada","CA","CAN","Alberta","admin","1062643","1124290735"
"Sendai","Sendai","38.2682","140.8694","Japan","JP","JPN","Miyagi","admin","1061177","1392457903"
"Raleigh","Raleigh","35.8324","-78.6429","United States","US","USA","North Carolina","admin","1062018","1840014497"
"Mizhou","Mizhou","35.9900","119.3801","China","CN","CHN","Shandong","minor","1060000","1156935002"
"Tunis","Tunis","36.8064","10.1817","Tunisia","TN","TUN","Tunis","primary","1056247","1788742103"
"Xishan","Xishan","27.6609","113.4946","China","CN","CHN","Hunan","minor","1060000","1156155575"
"Barquisimeto","Barquisimeto","10.0678","-69.3467","Venezuela","VE","VEN","Lara","admin","1059092","1862860739"
"Hegang","Hegang","47.3139","130.2775","China","CN","CHN","Heilongjiang","minor","1058665","1156541759"
"Voronezh","Voronezh","51.6717","39.2106","Russia","RU","RUS","Voronezhskaya Oblast’","admin","1050602","1643811064"
"Perm","Perm","58.0139","56.2489","Russia","RU","RUS","Permskiy Kray","admin","1048005","1643294442"
"Changwon","Changwon","35.2708","128.6631","South Korea","KR","KOR","Gyeongnam","admin","1046054","1410592618"
"Fangchenggang","Fangchenggang","21.6000","108.3000","China","CN","CHN","Guangxi","minor","1046068","1156532454"
"Shouguang","Shouguang","36.8833","118.7333","China","CN","CHN","Shandong","","1039205","1156787809"
"Bogor","Bogor","-6.5966","106.7972","Indonesia","ID","IDN","Jawa Barat","","1030720","1360771925"
"Cartagena","Cartagena","10.4000","-75.5000","Colombia","CO","COL","Bolívar","admin","1036412","1170886102"
"Matola","Matola","-25.9667","32.4667","Mozambique","MZ","MOZ","Maputo","","1032197","1508768577"
"Jodhpur","Jodhpur","26.2800","73.0200","India","IN","IND","Rājasthān","","1033918","1356727084"
"Memphis","Memphis","35.1087","-89.9663","United States","US","USA","Tennessee","","1034498","1840015457"
"Ogbomoso","Ogbomoso","8.1333","4.2500","Nigeria","NG","NGA","Oyo","minor","1032000","1566373448"
"Rangapukur","Rangapukur","25.5600","89.2500","Bangladesh","BD","BGD","Rangpur","","1031388","1050032005"
"Managua","Managua","12.1544","-86.2738","Nicaragua","NI","NIC","Managua","primary","1028808","1558296252"
"Sanya","Sanya","18.2533","109.5036","China","CN","CHN","Hainan","minor","1031396","1156373900"
"Shymkent","Shymkent","42.3167","69.5958","Kazakhstan","KZ","KAZ","","admin","1028673","1398791401"
"Wutong","Wutong","30.6326","120.5474","China","CN","CHN","Zhejiang","minor","1029754","1156182309"
"Niamey","Niamey","13.5150","2.1175","Niger","NE","NER","Niamey","primary","1026848","1562932886"
"Shubrā al Khaymah","Shubra al Khaymah","30.1286","31.2422","Egypt","EG","EGY","Al Qalyūbīyah","","1025569","1818909612"
"Linhai","Linhai","28.8523","121.1409","China","CN","CHN","Zhejiang","","1028813","1156451663"
"Denizli","Denizli","37.7667","29.0833","Turkey","TR","TUR","Denizli","admin","1027782","1792161179"
"Maceió","Maceio","-9.6658","-35.7350","Brazil","BR","BRA","Alagoas","admin","1025360","1076440479"
"Monrovia","Monrovia","6.3133","-10.8014","Liberia","LR","LBR","Montserrado","primary","1021762","1430477826"
"Wafangdian","Wafangdian","39.6271","121.9972","China","CN","CHN","Liaoning","","1024876","1156476417"
"Zhongxiang","Zhongxiang","31.1690","112.5853","China","CN","CHN","Hubei","","1022514","1156631720"
"Louisville","Louisville","38.1663","-85.6485","United States","US","USA","Kentucky","","1022630","1840030815"
"Odesa","Odesa","46.4775","30.7326","Ukraine","UA","UKR","Odeska Oblast","admin","1017699","1804178102"
"Thủ Đức","Thu Duc","10.8266","106.7609","Vietnam","VN","VNM","Hồ Chí Minh","minor","1013795","1704361621"
"Volgograd","Volgograd","48.7086","44.5147","Russia","RU","RUS","Volgogradskaya Oblast’","admin","1015586","1643577201"
"Islamabad","Islamabad","33.6931","73.0639","Pakistan","PK","PAK","Islamabad","primary","1014825","1586306717"
"Port-au-Prince","Port-au-Prince","18.5333","-72.3333","Haiti","HT","HTI","Ouest","primary","987310","1332401940"
"Xinyi","Xinyi","22.3559","110.9369","China","CN","CHN","Guangdong","","1013900","1156936644"
"Raipur","Raipur","21.2500","81.6300","India","IN","IND","Chhattīsgarh","admin","1010087","1356374983"
"Arequipa","Arequipa","-16.4000","-71.5333","Peru","PE","PER","Arequipa","admin","1008290","1604723954"
"Richmond","Richmond","37.5295","-77.4756","United States","US","USA","Virginia","admin","1008069","1840001698"
"Zaoyang","Zaoyang","32.1287","112.7581","China","CN","CHN","Hubei","","1004741","1156197076"
"Buffalo","Buffalo","42.9018","-78.8487","United States","US","USA","New York","","1004655","1840000386"
"Shuizhai","Shuizhai","33.4433","114.8994","China","CN","CHN","Henan","","1003698","1156462734"
"Xingyi","Xingyi","25.0910","104.9011","China","CN","CHN","Guizhou","","1004132","1156255316"
"Kota","Kota","25.1800","75.8300","India","IN","IND","Rājasthān","","1001694","1356820310"
"Quetta","Quetta","30.1833","67.0000","Pakistan","PK","PAK","Balochistan","admin","1001205","1586556108"
"Kathmandu","Kathmandu","27.7172","85.3240","Nepal","NP","NPL","","primary","975453","1524589448"
"Ottawa","Ottawa","45.4247","-75.6950","Canada","CA","CAN","Ontario","primary","989567","1124399363"
"Lilongwe","Lilongwe","-13.9833","33.7833","Malawi","MW","MWI","Lilongwe","primary","989318","1454688499"
"Asmara","Asmara","15.3228","38.9250","Eritrea","ER","ERI","Ma’ĭkel","primary","963000","1232791236"
"Freetown","Freetown","8.4844","-13.2344","Sierra Leone","SL","SLE","Western Area","primary","951000","1694085071"
"Vientiane","Vientiane","17.9667","102.6000","Laos","LA","LAO","Viangchan","primary","948487","1418732714"
"Jerusalem","Jerusalem","31.7784","35.2066","Israel","IL","ISR","Jerusalem","primary","919438","1376261644"
"Riga","Riga","56.9489","24.1064","Latvia","LV","LVA","Rīga","primary","920643","1428586192"
"Bangui","Bangui","4.3733","18.5628","Central African Republic","CF","CAF","Bangui","primary","889231","1140080881"
"Dushanbe","Dushanbe","38.5367","68.7800","Tajikistan","TJ","TJK","Dushanbe","primary","863400","1762930616"
"Lomé","Lome","6.1319","1.2228","Togo","TG","TGO","Maritime","primary","837437","1768409132"
"Ashgabat","Ashgabat","37.9500","58.3833","Turkmenistan","TM","TKM","Aşgabat","primary","828100","1795049992"
"Zagreb","Zagreb","45.8167","15.9833","Croatia","HR","HRV","Zagreb, Grad","primary","809268","1191233290"
"Libreville","Libreville","0.3903","9.4542","Gabon","GA","GAB","Estuaire","primary","797003","1266952885"
"Cotonou","Cotonou","6.3667","2.4333","Benin","BJ","BEN","Littoral","primary","762000","1204955174"
"Pretoria","Pretoria","-25.7461","28.1881","South Africa","ZA","ZAF","Gauteng","primary","741651","1710176249"
"Vilnius","Vilnius","54.6872","25.2800","Lithuania","LT","LTU","Vilniaus Miestas","primary","708203","1440887149"
"Winnipeg","Winnipeg","49.8844","-97.1464","Canada","CA","CAN","Manitoba","admin","705244","1124224963"
"Quebec City","Quebec City","46.8139","-71.2081","Canada","CA","CAN","Quebec","admin","705103","1124823933"
"Chisinau","Chisinau","47.0228","28.8353","Moldova","MD","MDA","Chişinău","primary","702300","1498011437"
"Port Moresby","Port Moresby","-9.4789","147.1494","Papua New Guinea","PG","PNG","National Capital","primary","700000","1598685395"
"Skopje","Skopje","41.9961","21.4317","Macedonia","MK","MKD","Skopje","primary","640000","1807600615"
"Djibouti","Djibouti","11.5883","43.1450","Djibouti","DJ","DJI","Djibouti","primary","603900","1262028958"
"Gaza","Gaza","31.5069","34.4560","Gaza Strip","XG","XGZ","","primary","590481","1916116052"
"Kingston","Kingston","17.9714","-76.7931","Jamaica","JM","JAM","Kingston","primary","580000","1388709177"
"Rabat","Rabat","34.0209","-6.8416","Morocco","MA","MAR","Rabat-Salé-Kénitra","primary","572717","1504023252"
"San Salvador","San Salvador","13.6989","-89.1914","El Salvador","SV","SLV","San Salvador","primary","567698","1222647454"
"The Hague","The Hague","52.0800","4.3100","Netherlands","NL","NLD","Zuid-Holland","primary","548320","1528799905"
"Asunción","Asuncion","-25.3000","-57.6333","Paraguay","PY","PRY","Asunción","primary","524190","1600057911"
"Juba","Juba","4.8500","31.6000","South Sudan","SS","SSD","Central Equatoria","primary","525953","1728444337"
"Maseru","Maseru","-29.3100","27.4800","Lesotho","LS","LSO","Maseru","primary","519186","1426977668"
"Bissau","Bissau","11.8500","-15.5667","Guinea-Bissau","GW","GNB","Bissau","primary","492004","1624168850"
"Valletta","Valletta","35.8983","14.5125","Malta","MT","MLT","Valletta","primary","480134","1470574399"
"Bratislava","Bratislava","48.1439","17.1097","Slovakia","SK","SVK","Bratislava","primary","475503","1703195001"
"Kitchener","Kitchener","43.4186","-80.4728","Canada","CA","CAN","Ontario","","470015","1124158530"
"Manama","Manama","26.2250","50.5775","Bahrain","BH","BHR","Al ‘Āşimah","primary","436000","1048989486"
"Tallinn","Tallinn","59.4372","24.7453","Estonia","EE","EST","Tallinn","primary","438341","1233260021"
"Beirut","Beirut","33.8869","35.5131","Lebanon","LB","LBN","Beyrouth","primary","361366","1422847713"
"Cape Town","Cape Town","-33.9253","18.4239","South Africa","ZA","ZAF","Western Cape","primary","433688","1710680650"
"Tirana","Tirana","41.3289","19.8178","Albania","AL","ALB","Tiranë","primary","418495","1008162156"
"Sarajevo","Sarajevo","43.8564","18.4131","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","primary","419957","1070966777"
"Wellington","Wellington","-41.2889","174.7772","New Zealand","NZ","NZL","Wellington","primary","418500","1554772152"
"Banjul","Banjul","13.4531","-16.5775","The Gambia","GM","GMB","Banjul","primary","413397","1270723713"
"Halifax","Halifax","44.6475","-63.5906","Canada","CA","CAN","Nova Scotia","admin","403131","1124130981"
"Canberra","Canberra","-35.2931","149.1269","Australia","AU","AUS","Australian Capital Territory","primary","381488","1036142029"
"Yamoussoukro","Yamoussoukro","6.8161","-5.2742","Côte d'Ivoire","CI","CIV","Yamoussoukro","primary","355573","1384683557"
"Victoria","Victoria","48.4283","-123.3647","Canada","CA","CAN","British Columbia","admin","335696","1124147219"
"Nicosia","Nicosia","35.1725","33.3650","Cyprus","CY","CYP","Lefkosía","primary","330000","1196944155"
"Windhoek","Windhoek","-22.5700","17.0836","Namibia","NA","NAM","Khomas","primary","322500","1516802003"
"Saint-Denis","Saint-Denis","-20.8789","55.4481","Reunion","RE","REU","","primary","315080","1638024662"
"Porto-Novo","Porto-Novo","6.4972","2.6050","Benin","BJ","BEN","Ouémé","primary","300000","1204172060"
"Sucre","Sucre","-19.0475","-65.2600","Bolivia","BO","BOL","Chuquisaca","primary","300000","1068823873"
"Ljubljana","Ljubljana","46.0514","14.5061","Slovenia","SI","SVN","Ljubljana","primary","286745","1705917455"
"Nassau","Nassau","25.0781","-77.3386","The Bahamas","BS","BHS","","primary","274400","1044318561"
"Bloemfontein","Bloemfontein","-29.1167","26.2167","South Africa","ZA","ZAF","Free State","primary","256185","1710495933"
"Fort-de-France","Fort-de-France","14.6000","-61.0667","Martinique","MQ","MTQ","","primary","253995","1474969110"
"New Delhi","New Delhi","28.6139","77.2090","India","IN","IND","Delhi","primary","249998","1356215164"
"Gaborone","Gaborone","-24.6581","25.9122","Botswana","BW","BWA","Gaborone","primary","231626","1072756768"
"Paramaribo","Paramaribo","5.8522","-55.2039","Suriname","SR","SUR","Paramaribo","primary","223757","1740518660"
"Dili","Dili","-8.5536","125.5783","Timor-Leste","TL","TLS","Díli","primary","222323","1626308942"
"Dodoma","Dodoma","-6.1731","35.7419","Tanzania","TZ","TZA","Dodoma","primary","213636","1834288497"
"Georgetown","Georgetown","6.8058","-58.1508","Guyana","GY","GUY","Demerara-Mahaica","primary","200500","1328160906"
"Gibraltar","Gibraltar","36.1333","-5.3500","Gibraltar","GI","GIB","","primary","187083","1292385245"
"Malabo","Malabo","3.7500","8.7833","Equatorial Guinea","GQ","GNQ","Bioko Norte","primary","187302","1226861333"
"Suva","Suva","-18.1416","178.4419","Fiji","FJ","FJI","Rewa","primary","185913","1242615095"
"Nouméa","Noumea","-22.2758","166.4580","New Caledonia","NC","NCL","Province Sud","primary","182341","1540958092"
"Pristina","Pristina","42.6633","21.1622","Kosovo","XK","XKS","Prishtinë","primary","161751","1901760068"
"Male","Male","4.1753","73.5089","Maldives","MV","MDV","Maale","primary","133019","1462441685"
"Port Louis","Port Louis","-20.1644","57.5042","Mauritius","MU","MUS","Port Louis","primary","149194","1480131261"
"Podgorica","Podgorica","42.4413","19.2629","Montenegro","ME","MNE","Podgorica","primary","150977","1499454516"
"Willemstad","Willemstad","12.1080","-68.9350","Curaçao","CW","CUW","","primary","150000","1531000000"
"Bern","Bern","46.9481","7.4475","Switzerland","CH","CHE","Bern","primary","133883","1756374318"
"Gitega","Gitega","-3.4283","29.9250","Burundi","BI","BDI","Gitega","primary","135467","1108778000"
"Reykjavík","Reykjavik","64.1467","-21.9400","Iceland","IS","ISL","Reykjavík","primary","135688","1352327190"
"Luxembourg","Luxembourg","49.6117","6.1319","Luxembourg","LU","LUX","Luxembourg","primary","132780","1442262731"
"Papeete","Papeete","-17.5334","-149.5667","French Polynesia","PF","PYF","Îles du Vent","primary","131695","1258907380"
"Praia","Praia","14.9180","-23.5090","Cabo Verde","CV","CPV","Praia","primary","127832","1132398770"
"Sri Jayewardenepura Kotte","Sri Jayewardenepura Kotte","6.9108","79.8878","Sri Lanka","LK","LKA","Western","primary","115826","1144239330"
"Bridgetown","Bridgetown","13.0975","-59.6167","Barbados","BB","BRB","Saint Michael","primary","110000","1052274244"
"Moroni","Moroni","-11.6990","43.2560","Comoros","KM","COM","Grande Comore","primary","111329","1174793581"
"Thimphu","Thimphu","27.4722","89.6361","Bhutan","BT","BTN","Thimphu","primary","104000","1064010361"
"Mbabane","Mbabane","-26.3208","31.1617","Swaziland","SZ","SWZ","Hhohho","primary","94874","1748811945"
"Honiara","Honiara","-9.4319","159.9556","Solomon Islands","SB","SLB","Honiara","primary","84520","1090737486"
"Port of Spain","Port of Spain","10.6667","-61.5167","Trinidad and Tobago","TT","TTO","Port of Spain","primary","81142","1780670676"
"Castries","Castries","14.0167","-60.9833","Saint Lucia","LC","LCA","Castries","primary","70000","1662922505"
"Putrajaya","Putrajaya","2.9300","101.6900","Malaysia","MY","MYS","Putrajaya","primary","67964","1458000206"
"Cayenne","Cayenne","4.9330","-52.3300","French Guiana","GF","GUF","","primary","61550","1254304969"
"São Tomé","Sao Tome","0.3361","6.7306","Sao Tome and Principe","ST","STP","Água Grande","primary","56166","1678301324"
"Port-Vila","Port-Vila","-17.7333","168.3167","Vanuatu","VU","VUT","Shefa","primary","51437","1548805075"
"Bandar Seri Begawan","Bandar Seri Begawan","4.8903","114.9422","Brunei","BN","BRN","Brunei dan Muara","primary","50000","1096465895"
"Monaco","Monaco","43.7333","7.4167","Monaco","MC","MCO","","primary","36371","1492854256"
"Apia","Apia","-13.8333","-171.7500","Samoa","WS","WSM","Tuamasaga","primary","37708","1882489296"
"Tarawa","Tarawa","1.3382","173.0176","Kiribati","KI","KIR","","primary","28802","1296152641"
"Oranjestad","Oranjestad","12.5186","-70.0358","Aruba","AW","ABW","","primary","34980","1533513057"
"Saint Helier","Saint Helier","49.1858","-2.1100","Jersey","JE","JEY","","primary","33522","1832490253"
"Mamoudzou","Mamoudzou","-12.7794","45.2272","Mayotte","YT","MYT","","primary","32057","1175099654"
"Majuro","Majuro","7.0833","171.3833","Marshall Islands","MH","MHL","Majuro","primary","30000","1584000000"
"Douglas","Douglas","54.1450","-4.4820","Isle Of Man","IM","IMN","","primary","27938","1833688345"
"George Town","George Town","19.2866","-81.3744","Cayman Islands","KY","CYM","","primary","27704","1136159124"
"Victoria","Victoria","-4.6167","55.4500","Seychelles","SC","SYC","Mont Buxton","primary","26450","1690193579"
"Kingstown","Kingstown","13.1578","-61.2250","Saint Vincent and the Grenadines","VC","VCT","Saint George","primary","25000","1670376659"
"Andorra la Vella","Andorra la Vella","42.5000","1.5000","Andorra","AD","AND","Andorra la Vella","primary","22615","1020828846"
"Saint John’s","Saint John's","17.1167","-61.8500","Antigua and Barbuda","AG","ATG","Saint John","primary","22219","1028912067"
"Nuku‘alofa","Nuku\`alofa","-21.1333","-175.2000","Tonga","TO","TON","Tongatapu","primary","23221","1776737461"
"Nuuk","Nuuk","64.1814","-51.6942","Greenland","GL","GRL","Sermersooq","primary","18326","1304028354"
"Belmopan","Belmopan","17.2514","-88.7669","Belize","BZ","BLZ","Cayo","primary","17222","1084586375"
"Roseau","Roseau","15.3014","-61.3883","Dominica","DM","DMA","Saint George","primary","16582","1212060440"
"Basseterre","Basseterre","17.3000","-62.7333","Saint Kitts and Nevis","KN","KNA","Saint George Basseterre","primary","13220","1659198919"
"Tórshavn","Torshavn","62.0000","-6.7833","Faroe Islands","FO","FRO","Tórshavn","primary","13326","1234904517"
"Road Town","Road Town","18.4167","-64.6167","British Virgin Islands","VG","VGB","","primary","12603","1092732412"
"Pago Pago","Pago Pago","-14.2740","-170.7046","American Samoa","AS","ASM","","primary","12576","1016976740"
"Grand Turk","Grand Turk","21.4664","-71.1360","Turks and Caicos Islands","TC","TCA","","primary","5801","1796965313"
"Marigot","Marigot","18.0706","-63.0847","Saint Martin","MF","MAF","","primary","5700","1663133172"
"Palikir","Palikir","6.9178","158.1850","Federated States of Micronesia","FM","FSM","Pohnpei","primary","6227","1583008885"
"Funafuti","Funafuti","-8.5167","179.2000","Tuvalu","TV","TUV","Funafuti","primary","6025","1798251391"
"Vaduz","Vaduz","47.1410","9.5210","Liechtenstein","LI","LIE","Vaduz","primary","5668","1438317747"
"Lobamba","Lobamba","-26.4465","31.2064","Swaziland","SZ","SWZ","Hhohho","primary","5800","1748963140"
"Avarua","Avarua","-21.2070","-159.7710","Cook Islands","CK","COK","","primary","5445","1184217570"
"Saint George’s","Saint George's","12.0500","-61.7500","Grenada","GD","GRD","Saint George","primary","4315","1308891766"
"San Marino","San Marino","43.9346","12.4473","San Marino","SM","SMR","San Marino Città","primary","4040","1674741947"
"Tifariti","Tifariti","26.1580","-10.5670","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","primary","3000","1504999990"
"Philipsburg","Philipsburg","18.0237","-63.0458","Sint Maarten","SX","SXM","","primary","1894","1534859858"
"Capitol Hill","Capitol Hill","15.2137","145.7546","Northern Mariana Islands","MP","MNP","","primary","2500","1580556972"
"Stanley","Stanley","-51.7000","-57.8500","Falkland Islands (Islas Malvinas)","FK","FLK","","primary","2213","1238780509"
"Hamilton","Hamilton","32.2942","-64.7839","Bermuda","BM","BMU","Hamilton","primary","854","1060000000"
"Vatican City","Vatican City","41.9040","12.4530","Vatican City","VA","VAT","","primary","825","1336000000"
"Alofi","Alofi","-19.0560","-169.9210","Niue","NU","NIU","","primary","1611","1570882835"
"Basse-Terre","Basse-Terre","16.0104","-61.7055","Guadeloupe","GP","GLP","","primary","307","1312938008"
"Hagåtña","Hagta","13.4745","144.7504","Guam","GU","GUM","","primary","1051","1316937540"
"Jamestown","Jamestown","-15.9251","-5.7179","Saint Helena, Ascension, and Tristan da Cunha","SH","SHN","Saint Helena","primary","714","1654761576"
"Brades","Brades","16.7928","-62.2106","Montserrat","MS","MSR","Saint Peter","primary","391","1500971198"
"Yaren","Yaren","-0.5477","166.9209","Nauru","NR","NRU","Yaren","primary","","1520790128"
"Gustavia","Gustavia","17.8958","-62.8508","Saint Barthelemy","BL","BLM","","primary","","1652681565"
"Ngerulmud","Ngerulmud","7.5006","134.6242","Palau","PW","PLW","Melekeok","primary","271","1585525081"
"Saint-Pierre","Saint-Pierre","46.7811","-56.1764","Saint Pierre and Miquelon","PM","SPM","","primary","","1666340272"
"The Valley","The Valley","18.2167","-63.0500","Anguilla","AI","AIA","","primary","","1660698387"
"Mata-Utu","Mata-Utu","-13.2825","-176.1736","Wallis and Futuna","WF","WLF","Uvea","primary","","1876784844"
"Kingston","Kingston","-29.0569","167.9617","Norfolk Island","NF","NFK","","primary","","1574121434"
"Longyearbyen","Longyearbyen","78.2167","15.6333","Svalbard","XR","XSV","","primary","","1930654114"
"Adamstown","Adamstown","-25.0667","-130.0833","Pitcairn Islands","PN","PCN","","primary","","1612000000"
"Flying Fish Cove","Flying Fish Cove","-10.4167","105.7167","Christmas Island","CX","CXR","","primary","","1162889600"
"King Edward Point","King Edward Point","-54.2833","-36.5000","South Georgia And South Sandwich Islands","GS","SGS","","primary","","1239048838"
"Bareilly","Bareilly","28.3640","79.4150","India","IN","IND","Uttar Pradesh","","1000000","1356698199"
"Quảng Hà","Quang Ha","15.9333","108.2667","Vietnam","VN","VNM","Quảng Nam","","1000000","1704966442"
"Domaa-Ahenkro","Domaa-Ahenkro","7.2833","-2.8833","Ghana","GH","GHA","Bono","","1000000","1288459533"
"Oklahoma City","Oklahoma City","35.4676","-97.5136","United States","US","USA","Oklahoma","admin","994284","1840020428"
"Xingcheng","Xingcheng","24.1681","115.6669","China","CN","CHN","Guangdong","minor","993000","1156913008"
"Dongtai","Dongtai","32.8534","120.3037","China","CN","CHN","Jiangsu","minor","990306","1156536785"
"Yingcheng","Yingcheng","24.1878","113.4042","China","CN","CHN","Guangdong","minor","986400","1156012356"
"Chiba","Chiba","35.6073","140.1064","Japan","JP","JPN","Chiba","admin","981738","1392107144"
"Al Mijlad","Al Mijlad","11.0339","27.7400","Sudan","SD","SDN","West Kordofan","","985000","1729768355"
"Pekanbaru","Pekanbaru","0.5092","101.4453","Indonesia","ID","IDN","Riau","admin","983356","1360422679"
"Luocheng","Luocheng","22.7645","111.5745","China","CN","CHN","Guangdong","minor","984100","1156138895"
"Dnipro","Dnipro","48.4675","35.0400","Ukraine","UA","UKR","Dnipropetrovska Oblast","admin","980948","1804152933"
"Danyang","Danyang","31.9948","119.5750","China","CN","CHN","Jiangsu","","980662","1156578273"
"Godē","Gode","5.9527","43.5516","Ethiopia","ET","ETH","Sumalē","","980782","1231128743"
"Natal","Natal","-6.9838","-60.2699","Brazil","BR","BRA","Amazonas","","980588","1076336975"
"Nada","Nada","19.5000","109.5833","China","CN","CHN","Hainan","minor","977700","1156911678"
"Zamboanga City","Zamboanga City","6.9042","122.0761","Philippines","PH","PHL","Zamboanga","admin","977234","1608189351"
"Kirkuk","Kirkuk","35.4667","44.3167","Iraq","IQ","IRQ","Karkūk","admin","975000","1368344172"
"Bridgeport","Bridgeport","41.1918","-73.1954","United States","US","USA","Connecticut","","975078","1840004836"
"Naples","Naples","40.8333","14.2500","Italy","IT","ITA","Campania","admin","966144","1380646673"
"Wuchuan","Wuchuan","21.4283","110.7749","China","CN","CHN","Guangdong","","972400","1156198103"
"Huilong","Huilong","31.8131","121.6574","China","CN","CHN","Jiangsu","minor","972525","1156485081"
"Morelia","Morelia","19.7683","-101.1894","Mexico","MX","MEX","Michoacán","","962555","1484669072"
"Málaga","Malaga","36.7194","-4.4200","Spain","ES","ESP","Andalusia","minor","967250","1724397372"
"Cebu City","Cebu City","10.3200","123.7500","Philippines","PH","PHL","Cebu","admin","964169","1608136151"
"Al Manşūrah","Al Mansurah","31.0500","31.3833","Egypt","EG","EGY","Ad Daqahlīyah","admin","960423","1818255319"
"Coimbatore","Coimbatore","11.0168","76.9558","India","IN","IND","Tamil Nādu","","959823","1356999503"
"Santo Domingo Este","Santo Domingo Este","18.4855","-69.8734","Dominican Republic","DO","DOM","Ozama","admin","948855","1214664832"
"Setagaya","Setagaya","35.6466","139.6532","Japan","JP","JPN","Tōkyō","","940509","1392792380"
"Sŏngnam","Songnam","37.4333","127.1500","South Korea","KR","KOR","Gyeonggi","","948757","1410622472"
"Taishan","Taishan","22.2486","112.7850","China","CN","CHN","Guangdong","","953900","1156105450"
"Teresina","Teresina","-5.0949","-42.8042","Brazil","BR","BRA","Piauí","admin","953172","1076727667"
"Solāpur","Solapur","17.6800","75.9200","India","IN","IND","Mahārāshtra","","951558","1356314385"
"Tangier","Tangier","35.7767","-5.8039","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","admin","947952","1504861504"
"Kermānshāh","Kermanshah","34.3142","47.0650","Iran","IR","IRN","Kermānshāh","admin","952285","1364535690"
"Krasnodar","Krasnodar","45.0333","38.9667","Russia","RU","RUS","Krasnodarskiy Kray","admin","948827","1643438227"
"Baidoa","Baidoa","3.1167","43.6500","Somalia","SO","SOM","Bay","admin","950000","1706563100"
"Gaalkacyo","Gaalkacyo","6.7697","47.4308","Somalia","SO","SOM","Mudug","admin","950000","1706067930"
"Anqiu","Anqiu","36.3619","119.1072","China","CN","CHN","Shandong","","947723","1156592047"
"Feicheng","Feicheng","36.1860","116.7720","China","CN","CHN","Shandong","","946627","1156303834"
"Seberang Jaya","Seberang Jaya","5.4000","100.4667","Malaysia","MY","MYS","Pulau Pinang","","946092","1458417183"
"El Alto","El Alto","-16.5047","-68.1633","Bolivia","BO","BOL","La Paz","","943000","1068007058"
"Kitakyūshū","Kitakyushu","33.8833","130.8833","Japan","JP","JPN","Fukuoka","","935084","1392003140"
"Meishan","Meishan","34.1736","112.8390","China","CN","CHN","Henan","minor","936000","1156023073"
"Khartoum North","Khartoum North","15.6333","32.6333","Sudan","SD","SDN","Khartoum","","936349","1729318628"
"Kisangani","Kisangani","0.5153","25.1911","Congo (Kinshasa)","CD","COD","Tshopo","admin","935977","1180478201"
"Aguascalientes","Aguascalientes","21.8760","-102.2960","Mexico","MX","MEX","Aguascalientes","admin","934424","1484666646"
"Marrakech","Marrakech","31.6300","-8.0089","Morocco","MA","MAR","Marrakech-Safi","admin","928850","1504172585"
"Donetsk","Donetsk","48.0028","37.8053","Ukraine","UA","UKR","Donetska Oblast","admin","929063","1804963805"
"Trujillo","Trujillo","-8.1120","-79.0288","Peru","PE","PER","La Libertad","admin","919899","1604613706"
"New Orleans","New Orleans","30.0687","-89.9288","United States","US","USA","Louisiana","","925443","1840001839"
"Taihe","Taihe","30.8706","105.3784","China","CN","CHN","Sichuan","minor","924531","1156281983"
"Trichinopoly","Trichinopoly","10.7903","78.7047","India","IN","IND","Tamil Nādu","","916857","1356884472"
"Xin’an","Xin'an","34.3662","118.3369","China","CN","CHN","Jiangsu","","920628","1156236053"
"Taihecun","Taihecun","45.7680","131.0063","China","CN","CHN","Heilongjiang","minor","920471","1156875484"
"Kashgar","Kashgar","39.4681","75.9938","China","CN","CHN","Xinjiang","minor","920000","1156113193"
"Naucalpan de Juárez","Naucalpan de Juarez","19.4753","-99.2378","Mexico","MX","MEX","México","minor","913681","1484850125"
"Çankaya","Cankaya","39.9244","32.8856","Turkey","TR","TUR","Ankara","minor","914501","1792458894"
"Santiago de Cuba","Santiago de Cuba","20.0217","-75.8294","Cuba","CU","CUB","Santiago de Cuba","admin","917340","1192766111"
"Owerri","Owerri","5.4833","7.0333","Nigeria","NG","NGA","Imo","admin","908109","1566679132"
"Padang","Padang","-0.9500","100.3531","Indonesia","ID","IDN","Sumatera Barat","admin","914970","1360900986"
"Qingzhou","Qingzhou","36.6967","118.4797","China","CN","CHN","Shandong","","914047","1156874864"
"Lichuan","Lichuan","30.2965","108.9378","China","CN","CHN","Hubei","","913700","1156302762"
"Santiago del Estero","Santiago del Estero","-27.7833","-64.2667","Argentina","AR","ARG","Santiago del Estero","admin","911506","1032492280"
"Daye","Daye","30.1003","114.9699","China","CN","CHN","Hubei","","909724","1156255811"
"Hengzhou","Hengzhou","22.6896","109.2674","China","CN","CHN","Guangxi","minor","908400","1156041202"
"Fort Worth","Fort Worth","32.7817","-97.3474","United States","US","USA","Texas","","908469","1840020696"
"Hartford","Hartford","41.7661","-72.6834","United States","US","USA","Connecticut","admin","907046","1840004773"
"Esenyurt","Esenyurt","41.0470","28.6580","Turkey","TR","TUR","İstanbul","","891120","1792759121"
"Campo Grande","Campo Grande","-20.4839","-54.6150","Brazil","BR","BRA","Mato Grosso do Sul","admin","906092","1076660445"
"Zhuanghe","Zhuanghe","39.6896","122.9664","China","CN","CHN","Liaoning","","905852","1156410076"
"Bobo-Dioulasso","Bobo-Dioulasso","11.1833","-4.2833","Burkina Faso","BF","BFA","Hauts-Bassins","admin","903887","1854348796"
"Ad Dammām","Ad Dammam","26.4333","50.1000","Saudi Arabia","SA","SAU","Ash Sharqīyah","admin","903312","1682452296"
"Quzhou","Quzhou","28.9545","118.8763","China","CN","CHN","Zhejiang","","902767","1156180376"
"Lhasa","Lhasa","29.6534","91.1719","China","CN","CHN","Tibet","admin","902500","1156369972"
"Jiaozhou","Jiaozhou","36.2481","119.9625","China","CN","CHN","Shandong","","900500","1156752602"
"Bunia","Bunia","1.5667","30.2500","Congo (Kinshasa)","CD","COD","Ituri","admin","900666","1180006418"
"Taguig City","Taguig City","14.5200","121.0500","Philippines","PH","PHL","Taguig","admin","886722","1608766695"
"Cancún","Cancun","21.1606","-86.8475","Mexico","MX","MEX","Quintana Roo","minor","888797","1484010310"
"Mérida","Merida","20.9700","-89.6200","Mexico","MX","MEX","Yucatán","admin","892363","1484396779"
"Yangchun","Yangchun","22.1717","111.7846","China","CN","CHN","Guangdong","","891800","1156787307"
"Dengtalu","Dengtalu","36.1386","114.1066","China","CN","CHN","Henan","minor","887000","1156202014"
"Morādābād","Moradabad","28.8389","78.7769","India","IN","IND","Uttar Pradesh","","887871","1356269375"
"Antipolo","Antipolo","14.5842","121.1763","Philippines","PH","PHL","Rizal","admin","887399","1608824345"
"Abeokuta","Abeokuta","7.1608","3.3483","Nigeria","NG","NGA","Ogun","admin","888924","1566979497"
"Bucheon","Bucheon","37.5000","126.7833","South Korea","KR","KOR","Gyeonggi","minor","867678","1410428222"
"Zhoushan","Zhoushan","29.9887","122.2049","China","CN","CHN","Zhejiang","minor","882932","1156200283"
"Tiruppūr","Tiruppur","11.1085","77.3411","India","IN","IND","Tamil Nādu","","877778","1356539761"
"Natal","Natal","-5.7833","-35.2000","Brazil","BR","BRA","Rio Grande do Norte","admin","877662","1076697777"
"Chihuahua","Chihuahua","28.6353","-106.0889","Mexico","MX","MEX","Chihuahua","admin","878062","1484335603"
"Klang","Klang","3.0333","101.4500","Malaysia","MY","MYS","Selangor","","878000","1458509635"
"As Sulaymānīyah","As Sulaymaniyah","35.5572","45.4356","Iraq","IQ","IRQ","As Sulaymānīyah","admin","878146","1368735317"
"Gurgaon","Gurgaon","28.4560","77.0290","India","IN","IND","Haryāna","","876824","1356836043"
"Turin","Turin","45.0792","7.6761","Italy","IT","ITA","Piedmont","admin","870952","1380244627"
"Tucson","Tucson","32.1541","-110.8787","United States","US","USA","Arizona","","875284","1840022101"
"Hai’an","Hai'an","32.5320","120.4604","China","CN","CHN","Jiangsu","minor","874334","1156332302"
"Ar Ramādī","Ar Ramadi","33.4258","43.2992","Iraq","IQ","IRQ","Al Anbār","admin","874543","1368838855"
"Laiyang","Laiyang","36.9758","120.7136","China","CN","CHN","Shandong","","874127","1156207330"
"Sale","Sale","34.0333","-6.8000","Morocco","MA","MAR","Rabat-Salé-Kénitra","","850403","1504989034"
"Jalandhar","Jalandhar","31.2569","75.4432","India","IN","IND","Punjab","","873725","1356494816"
"Marseille","Marseille","43.2964","5.3700","France","FR","FRA","Provence-Alpes-Côte d’Azur","admin","870731","1250774071"
"Bucaramanga","Bucaramanga","7.1333","-73.0000","Colombia","CO","COL","Santander","admin","870752","1170940590"
"Kaifeng Chengguanzhen","Kaifeng Chengguanzhen","34.8519","114.3481","China","CN","CHN","Henan","minor","872000","1156686574"
"Ikeja","Ikeja","6.6186","3.3426","Nigeria","NG","NGA","Lagos","admin","861300","1566486388"
"Eskişehir","Eskisehir","39.7767","30.5206","Turkey","TR","TUR","Eskişehir","admin","871187","1792933981"
"Saltillo","Saltillo","25.4231","-100.9919","Mexico","MX","MEX","Coahuila","admin","864431","1484107245"
"Gaomi","Gaomi","36.3833","119.7500","China","CN","CHN","Shandong","","868715","1156673331"
"Liverpool","Liverpool","53.4075","-2.9919","United Kingdom","GB","GBR","Liverpool","","864122","1826558678"
"Ipoh","Ipoh","4.5972","101.0750","Malaysia","MY","MYS","Perak","admin","866772","1458423505"
"Oran","Oran","35.6969","-0.6331","Algeria","DZ","DZA","Oran","admin","852000","1012126319"
"Portsmouth","Portsmouth","50.8058","-1.0872","United Kingdom","GB","GBR","Portsmouth","","855679","1826964096"
"Southampton","Southampton","50.9025","-1.4042","United Kingdom","GB","GBR","Southampton","","855569","1826602892"
"Hermosillo","Hermosillo","29.0989","-110.9542","Mexico","MX","MEX","Sonora","admin","855563","1484015319"
"Cochabamba","Cochabamba","-17.3833","-66.1667","Bolivia","BO","BOL","Cochabamba","admin","856198","1068793894"
"Weichanglu","Weichanglu","37.1792","119.9333","China","CN","CHN","Shandong","minor","859424","1156194600"
"Shache","Shache","38.4261","77.2500","China","CN","CHN","Xinjiang","","851374","1156278934"
"Wuxi","Wuxi","26.5895","111.8481","China","CN","CHN","Hunan","minor","853197","1156698595"
"Leping","Leping","28.9632","117.1203","China","CN","CHN","Jiangxi","minor","852800","1156705082"
"Hailun","Hailun","47.4585","126.9632","China","CN","CHN","Heilongjiang","minor","850000","1156804488"
"Macheng","Macheng","31.1817","115.0189","China","CN","CHN","Hubei","","849090","1156949639"
"Akure","Akure","7.2500","5.1950","Nigeria","NG","NGA","Ondo","admin","847903","1566568277"
"Ilorin","Ilorin","8.5000","4.5500","Nigeria","NG","NGA","Kwara","admin","847582","1566480168"
"Yuci","Yuci","37.6823","112.7281","China","CN","CHN","Shanxi","","840000","1156177817"
"Saratov","Saratov","51.5333","46.0167","Russia","RU","RUS","Saratovskaya Oblast’","admin","845300","1643556540"
"Erbil","Erbil","36.1912","44.0092","Iraq","IQ","IRQ","Arbīl","admin","846000","1368578192"
"Iguaçu","Iguacu","-22.7400","-43.4700","Brazil","BR","BRA","Rio de Janeiro","","844583","1076339714"
"Pasig City","Pasig City","14.5750","121.0833","Philippines","PH","PHL","Pasig","admin","803159","1608278212"
"Dehui","Dehui","44.5333","125.7000","China","CN","CHN","Jilin","","839786","1156898134"
"Kālkāji Devi","Kalkaji Devi","28.5485","77.2513","India","IN","IND","Delhi","","824177","1356264482"
"Bhubaneshwar","Bhubaneshwar","20.2700","85.8400","India","IN","IND","Odisha","admin","837737","1356140143"
"Honolulu","Honolulu","21.3294","-157.8460","United States","US","USA","Hawaii","admin","835291","1840013305"
"Tongchuan","Tongchuan","34.9057","108.9422","China","CN","CHN","Shaanxi","minor","834437","1156849608"
"Cheongju","Cheongju","36.6333","127.4833","South Korea","KR","KOR","Chungbuk","admin","833276","1410964361"
"Chengxiang","Chengxiang","31.4515","121.1043","China","CN","CHN","Jiangsu","minor","831113","1156254788"
"Thessaloníki","Thessaloniki","40.6403","22.9347","Greece","GR","GRC","Kentrikí Makedonía","admin","824676","1300397999"
"Warri","Warri","5.5167","5.7500","Nigeria","NG","NGA","Delta","minor","830106","1566325734"
"Sakai","Sakai","34.5733","135.4831","Japan","JP","JPN","Ōsaka","","824408","1392003291"
"Pointe-Noire","Pointe-Noire","-4.8000","11.8500","Congo (Brazzaville)","CG","COG","Pointe-Noire","admin","829134","1178411563"
"Rongjiawan","Rongjiawan","29.1409","113.1087","China","CN","CHN","Hunan","minor","826000","1156795164"
"Mediouna","Mediouna","33.4500","-7.5100","Morocco","MA","MAR","Casablanca-Settat","","822851","1504371270"
"Butterworth","Butterworth","5.3992","100.3639","Malaysia","MY","MYS","Pulau Pinang","","821652","1458469233"
"Renqiu","Renqiu","38.7094","116.1008","China","CN","CHN","Hebei","","822455","1156943939"
"Xindi","Xindi","29.8182","113.4653","China","CN","CHN","Hubei","","819446","1156263165"
"Bhayandar","Bhayandar","19.2900","72.8500","India","IN","IND","Mahārāshtra","","809378","1356019118"
"Wu’an","Wu'an","36.6941","114.1847","China","CN","CHN","Hebei","","819000","1156729960"
"São Bernardo do Campo","Sao Bernardo do Campo","-23.7000","-46.5500","Brazil","BR","BRA","São Paulo","","816925","1076793227"
"Māndvi","Mandvi","19.3000","72.8500","India","IN","IND","Mahārāshtra","","814655","1356843310"
"Barinas","Barinas","8.6333","-70.2000","Venezuela","VE","VEN","Barinas","admin","816264","1862647790"
"Zijinglu","Zijinglu","34.7513","112.9854","China","CN","CHN","Henan","minor","807857","1156086381"
"Gaoyou","Gaoyou","32.7847","119.4432","China","CN","CHN","Jiangsu","minor","811800","1156235854"
"Culiacán","Culiacan","24.8069","-107.3939","Mexico","MX","MEX","Sinaloa","admin","808416","1484516810"
"Hejian","Hejian","38.4451","116.0897","China","CN","CHN","Hebei","","810306","1156405731"
"Yiyang","Yiyang","26.4103","112.3913","China","CN","CHN","Hunan","minor","810447","1156142520"
"Puxi","Puxi","35.2125","114.7350","China","CN","CHN","Henan","minor","809535","1156046618"
"Androtsy","Androtsy","-24.1000","46.3000","Madagascar","MG","MDG","Toliara","","809313","1450017816"
"McAllen","McAllen","26.2252","-98.2467","United States","US","USA","Texas","","809002","1840021024"
"Qingping","Qingping","34.5380","113.3796","China","CN","CHN","Henan","minor","797200","1156033855"
"Omaha","Omaha","41.2627","-96.0529","United States","US","USA","Nebraska","","806485","1840009315"
"João Pessoa","Joao Pessoa","-7.1200","-34.8800","Brazil","BR","BRA","Paraíba","admin","801718","1076922014"
"Dongyang","Dongyang","29.2785","120.2282","China","CN","CHN","Zhejiang","minor","804398","1156259752"
"Querétaro","Queretaro","20.5875","-100.3928","Mexico","MX","MEX","Querétaro","admin","801940","1484604775"
"Kraków","Krakow","50.0614","19.9372","Poland","PL","POL","Małopolskie","admin","800653","1616172264"
"George Town","George Town","5.4144","100.3292","Malaysia","MY","MYS","Pulau Pinang","admin","794313","1458236750"
"Abaeté","Abaete","-19.1583","-45.4522","Brazil","BR","BRA","Minas Gerais","","800000","1076413528"
"Palermo","Palermo","2.8917","-75.4375","Colombia","CO","COL","Huila","minor","800000","1170614290"
"Valencia","Valencia","39.4700","-0.3764","Spain","ES","ESP","Valencia","admin","792492","1724981666"
"Xigazê","Xigaze","29.2500","88.8833","China","CN","CHN","Tibet","minor","798153","1156107208"
"El Paso","El Paso","31.8476","-106.4300","United States","US","USA","Texas","","794344","1840023252"
"Seyhan","Seyhan","36.9831","35.3328","Turkey","TR","TUR","Adana","minor","792536","1792655353"
"Niigata","Niigata","37.9161","139.0364","Japan","JP","JPN","Niigata","admin","790646","1392913753"
"Hempstead","Hempstead","40.6629","-73.6089","United States","US","USA","New York","","791018","1840005293"
"Leeds","Leeds","53.8003","-1.5497","United Kingdom","GB","GBR","Leeds","","789194","1826343963"
"Hamamatsu","Hamamatsu","34.7108","137.7275","Japan","JP","JPN","Shizuoka","","788211","1392174500"
"Bağcılar","Bagcilar","41.0344","28.8564","Turkey","TR","TUR","İstanbul","","734369","1792374908"
"Xiangxiang","Xiangxiang","27.7389","112.5223","China","CN","CHN","Hunan","","787216","1156524101"
"Bilbao","Bilbao","43.2569","-2.9236","Spain","ES","ESP","Basque Country","minor","775000","1724331267"
"Chaohucun","Chaohucun","31.6783","117.7353","China","CN","CHN","Anhui","","782000","1156671495"
"Fuyang","Fuyang","30.0553","119.9500","China","CN","CHN","Zhejiang","minor","771000","1156340393"
"Homs","Homs","34.7333","36.7167","Syria","SY","SYR","Ḩimş","admin","775404","1760013934"
"Küçükçekmece","Kucukcekmece","41.0000","28.8000","Turkey","TR","TUR","İstanbul","minor","770317","1792992349"
"Lubango","Lubango","-14.9167","13.5000","Angola","AO","AGO","Huíla","admin","776249","1024669127"
"Zouping","Zouping","36.8625","117.7424","China","CN","CHN","Shandong","","774517","1156817518"
"Khŭjand","Khujand","40.2833","69.6333","Tajikistan","TJ","TJK","Sughd","admin","770000","1762372787"
"San Luis Potosí","San Luis Potosi","22.1511","-100.9761","Mexico","MX","MEX","San Luis Potosí","admin","772828","1484583620"
"Nottingham","Nottingham","52.9533","-1.1500","United Kingdom","GB","GBR","Nottingham","","768638","1826122597"
"Cencheng","Cencheng","22.9297","111.0186","China","CN","CHN","Guangxi","minor","772113","1156000460"
"Dali","Dali","25.6808","100.3003","China","CN","CHN","Yunnan","","771128","1156840276"
"Hamhŭng","Hamhung","39.9167","127.5333","North Korea","KP","PRK","Hamnam","admin","768551","1408482040"
"Korla","Korla","41.7259","86.1746","China","CN","CHN","Xinjiang","minor","770000","1156965620"
"Rājshāhi","Rajshahi","24.3667","88.6000","Bangladesh","BD","BGD","Rājshāhi","admin","763580","1050547831"
"Erzurum","Erzurum","39.9086","41.2769","Turkey","TR","TUR","Erzurum","admin","767848","1792511957"
"Frankfurt","Frankfurt","50.1106","8.6822","Germany","DE","DEU","Hesse","minor","764104","1276054552"
"Al ‘Ayn","Al \`Ayn","24.2075","55.7447","United Arab Emirates","AE","ARE","Abū Z̧aby","","766936","1784674685"
"Songzi","Songzi","30.1772","111.7732","China","CN","CHN","Hubei","","765911","1156837705"
"Albuquerque","Albuquerque","35.1054","-106.6465","United States","US","USA","New Mexico","","765693","1840019176"
"Patiāla","Patiala","30.3400","76.3800","India","IN","IND","Punjab","","763280","1356458416"
"Laixi","Laixi","36.8667","120.5333","China","CN","CHN","Shandong","","762900","1156973102"
"Bahawalpur","Bahawalpur","29.3956","71.6836","Pakistan","PK","PAK","Punjab","minor","762111","1586038700"
"Zhongba","Zhongba","31.7761","104.7406","China","CN","CHN","Sichuan","minor","762140","1156515985"
"Kaduna","Kaduna","10.5167","7.4333","Nigeria","NG","NGA","Kaduna","admin","760084","1566368729"
"Qingnian","Qingnian","36.8494","115.7061","China","CN","CHN","Shandong","minor","760781","1156689964"
"San Pedro Sula","San Pedro Sula","15.5000","-88.0333","Honduras","HN","HND","Cortés","admin","760182","1340568412"
"Qamdo","Qamdo","31.1375","97.1777","China","CN","CHN","Tibet","minor","760966","1156277802"
"Xinhualu","Xinhualu","34.3962","113.7249","China","CN","CHN","Henan","minor","758079","1156544851"
"Nerima","Nerima","35.7355","139.6517","Japan","JP","JPN","Tōkyō","","742463","1392093402"
"Guangshui","Guangshui","31.6189","113.8229","China","CN","CHN","Hubei","","755910","1156267419"
"Pietermaritzburg","Pietermaritzburg","-29.6167","30.3833","South Africa","ZA","ZAF","KwaZulu-Natal","admin","750845","1710690280"
"Baardheere","Baardheere","2.3333","42.2833","Somalia","SO","SOM","Gedo","","755500","1706822684"
"Tlajomulco de Zúñiga","Tlajomulco de Zuniga","20.4736","-103.4431","Mexico","MX","MEX","Jalisco","minor","727750","1484017493"
"Samarinda","Samarinda","-0.5000","117.1378","Indonesia","ID","IDN","Kalimantan Timur","admin","753370","1360684979"
"Changhua","Changhua","24.0667","120.5333","Taiwan","TW","TWN","Changhua","admin","750000","1158439005"
"Sizhan","Sizhan","39.0099","106.3694","China","CN","CHN","Ningxia","","751389","1156450911"
"Ciudad Guayana","Ciudad Guayana","8.3667","-62.6500","Venezuela","VE","VEN","Bolívar","minor","751331","1862889027"
"Cúcuta","Cucuta","7.8942","-72.5039","Colombia","CO","COL","Norte de Santander","admin","750000","1170743755"
"Dhūlia","Dhulia","20.8997","74.7697","India","IN","IND","Mahārāshtra","","750000","1356224695"
"Burco","Burco","9.5279","45.5345","Somalia","SO","SOM","Togdheer","admin","750211","1706322754"
"Licheng","Licheng","31.4174","119.4786","China","CN","CHN","Jiangsu","minor","749522","1156933354"
"Ōta-ku","Ota-ku","35.5613","139.7160","Japan","JP","JPN","Tōkyō","","737187","1392003511"
"Thiruvananthapuram","Thiruvananthapuram","8.4875","76.9525","India","IN","IND","Kerala","admin","743691","1356024354"
"Tyumen","Tyumen","57.1500","65.5333","Russia","RU","RUS","Tyumenskaya Oblast’","admin","744554","1643040435"
"Nampula","Nampula","-15.1167","39.2667","Mozambique","MZ","MOZ","Nampula","admin","743000","1508958445"
"Zaporizhzhia","Zaporizhzhia","47.8500","35.1175","Ukraine","UA","UKR","Zaporizka Oblast","admin","741717","1804644752"
"Kyaukse","Kyaukse","21.6131","96.1303","Myanmar","MM","MMR","Mandalay","","741071","1104814217"
"Chengguan","Chengguan","35.5256","113.6976","China","CN","CHN","Henan","minor","740435","1156866870"
"Kumamoto","Kumamoto","32.8031","130.7078","Japan","JP","JPN","Kumamoto","admin","738385","1392106283"
"Nehe","Nehe","48.4800","124.8738","China","CN","CHN","Heilongjiang","minor","740000","1156609562"
"Osogbo","Osogbo","7.7667","4.5667","Nigeria","NG","NGA","Osun","admin","731000","1566395088"
"Cabinda","Cabinda","-5.5600","12.1900","Angola","AO","AGO","Cabinda","admin","739182","1024474012"
"Kermān","Kerman","30.2833","57.0833","Iran","IR","IRN","Kermān","admin","738374","1364554896"
"Zunhua","Zunhua","40.1881","117.9593","China","CN","CHN","Hebei","minor","737011","1156756935"
"Rochester","Rochester","43.1680","-77.6162","United States","US","USA","New York","","737309","1840000373"
"Valenzuela","Valenzuela","14.7000","120.9800","Philippines","PH","PHL","Valenzuela","admin","714978","1608492120"
"Orūmīyeh","Orumiyeh","37.5486","45.0675","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","admin","736224","1364158221"
"Wugang","Wugang","26.7345","110.6293","China","CN","CHN","Hunan","","734870","1156089788"
"Shuangqiao","Shuangqiao","35.0833","112.5833","China","CN","CHN","Henan","minor","733000","1156968311"
"Tshikapa","Tshikapa","-6.4167","20.8000","Congo (Kinshasa)","CD","COD","Kasaï","","732000","1180581239"
"São José dos Campos","Sao Jose dos Campos","-23.1789","-45.8869","Brazil","BR","BRA","São Paulo","","729737","1076783887"
"Comodoro Rivadavia","Comodoro Rivadavia","-45.8647","-67.4808","Argentina","AR","ARG","Chubut","minor","730266","1032042035"
"Cagayan de Oro","Cagayan de Oro","8.4833","124.6500","Philippines","PH","PHL","Cagayan de Oro","admin","728402","1608831546"
"Tondo","Tondo","14.6170","120.9670","Philippines","PH","PHL","Manila","","654220","1608204201"
"Langzhong","Langzhong","31.5504","105.9938","China","CN","CHN","Sichuan","minor","728935","1156930148"
"Qian’an","Qian'an","40.0059","118.6973","China","CN","CHN","Hebei","","728160","1156315498"
"Lviv","Lviv","49.8425","24.0322","Ukraine","UA","UKR","Lvivska Oblast","admin","724314","1804939658"
"Sarasota","Sarasota","27.3387","-82.5432","United States","US","USA","Florida","","727388","1840015988"
"Reynosa","Reynosa","26.0922","-98.2778","Mexico","MX","MEX","Tamaulipas","minor","725793","1484737671"
"Santo André","Santo Andre","-23.6572","-46.5333","Brazil","BR","BRA","São Paulo","","721368","1076625886"
"An Najaf","An Najaf","32.0000","44.3300","Iraq","IQ","IRQ","An Najaf","admin","724700","1368003357"
"Sagamihara","Sagamihara","35.5667","139.3667","Japan","JP","JPN","Kanagawa","","722973","1392967248"
"Guli","Guli","28.9008","120.0284","China","CN","CHN","Zhejiang","","723490","1156383681"
"Mississauga","Mississauga","43.6000","-79.6500","Canada","CA","CAN","Ontario","","721599","1124112672"
"Concepción","Concepcion","-36.8282","-73.0514","Chile","CL","CHL","Biobío","admin","719944","1152838958"
"Okayama","Okayama","34.6500","133.9167","Japan","JP","JPN","Okayama","admin","720043","1392954750"
"Anlu","Anlu","31.2575","113.6783","China","CN","CHN","Hubei","minor","720000","1156406202"
"Mwanza","Mwanza","-2.5167","32.9000","Tanzania","TZ","TZA","Mwanza","admin","706453","1834011288"
"Fresno","Fresno","36.7830","-119.7939","United States","US","USA","California","","719558","1840020319"
"Changsha","Changsha","22.3762","112.6877","China","CN","CHN","Guangdong","minor","717700","1156011120"
"Torreón","Torreon","25.5394","-103.4486","Mexico","MX","MEX","Coahuila","minor","716672","1484215762"
"Shihezi","Shihezi","44.3054","86.0804","China","CN","CHN","Xinjiang","minor","717000","1156232270"
"Enugu","Enugu","6.4528","7.5111","Nigeria","NG","NGA","Enugu","admin","715774","1566212729"
"Jaboatão","Jaboatao","-8.1803","-35.0014","Brazil","BR","BRA","Pernambuco","","702621","1076000699"
"Tulsa","Tulsa","36.1283","-95.9042","United States","US","USA","Oklahoma","","715983","1840021672"
"Yanggok","Yanggok","37.6333","127.2167","South Korea","KR","KOR","Gyeonggi","","713321","1410188674"
"Yatou","Yatou","37.1653","122.4867","China","CN","CHN","Shandong","minor","714211","1156009046"
"Edogawa","Edogawa","35.7066","139.8683","Japan","JP","JPN","Chiba","","697932","1392135698"
"Allentown","Allentown","40.5961","-75.4756","United States","US","USA","Pennsylvania","","714136","1840001044"
"Ribeirão Prêto","Ribeirao Preto","-21.1783","-47.8067","Brazil","BR","BRA","São Paulo","","711825","1076524478"
"Xichang","Xichang","27.8983","102.2706","China","CN","CHN","Sichuan","minor","712434","1156116456"
"Latakia","Latakia","35.5167","35.7833","Syria","SY","SYR","Al Lādhiqīyah","admin","700000","1760555544"
"Bhiwandi","Bhiwandi","19.2967","73.0631","India","IN","IND","Mahārāshtra","","709665","1356763571"
"City of Parañaque","City of Paranaque","14.4700","121.0200","Philippines","PH","PHL","Parañaque","admin","689992","1608379532"
"Dasmariñas","Dasmarinas","14.3294","120.9367","Philippines","PH","PHL","Cavite","","703141","1608418544"
"Dayton","Dayton","39.7805","-84.2003","United States","US","USA","Ohio","","709300","1840034249"
"Sahāranpur","Saharanpur","29.9640","77.5460","India","IN","IND","Uttar Pradesh","","705478","1356139858"
"Warangal","Warangal","17.9689","79.5941","India","IN","IND","Andhra Pradesh","","704570","1356851618"
"Soledad","Soledad","10.9167","-74.7500","Colombia","CO","COL","Atlántico","minor","698852","1170765625"
"Osasco","Osasco","-23.5328","-46.7919","Brazil","BR","BRA","São Paulo","","694844","1076786452"
"Dashiqiao","Dashiqiao","40.6328","122.5021","China","CN","CHN","Liaoning","minor","704891","1156141962"
"Birmingham","Birmingham","33.5279","-86.7971","United States","US","USA","Alabama","","704676","1840006507"
"Nampo","Nampo","38.7333","125.4000","North Korea","KP","PRK","Namp’o","","703317","1408358410"
"Shiliguri","Shiliguri","26.7100","88.4300","India","IN","IND","West Bengal","","701489","1356724691"
"Banjarmasin","Banjarmasin","-3.3200","114.5925","Indonesia","ID","IDN","Kalimantan Selatan","admin","692793","1360685223"
"Sha Tin","Sha Tin","22.3802","114.1898","Hong Kong","HK","HKG","","","640000","1344745541"
"Salem","Salem","11.6500","78.1500","India","IN","IND","Tamil Nādu","","693236","1356567087"
"General Santos","General Santos","6.1167","125.1667","Philippines","PH","PHL","General Santos","admin","697315","1608171585"
"Cocody","Cocody","5.3500","-3.9667","Côte d'Ivoire","CI","CIV","Abidjan","","692583","1384351512"
"Ḩamāh","Hamah","35.1333","36.7500","Syria","SY","SYR","Ḩamāh","admin","696863","1760197502"
"Pendik","Pendik","40.8747","29.2350","Turkey","TR","TUR","İstanbul","minor","693599","1792824929"
"Bacoor","Bacoor","14.4624","120.9645","Philippines","PH","PHL","Cavite","","664625","1608869358"
"Vellore","Vellore","12.9165","79.1325","India","IN","IND","Tamil Nādu","","696110","1356977661"
"Shishi","Shishi","24.7355","118.6434","China","CN","CHN","Fujian","minor","693000","1156077046"
"Guadalupe","Guadalupe","25.6775","-100.2597","Mexico","MX","MEX","Nuevo León","minor","691931","1484002410"
"Adachi","Adachi","35.7749","139.8044","Japan","JP","JPN","Tōkyō","","682326","1392464568"
"Qianxi","Qianxi","27.0284","106.0327","China","CN","CHN","Guizhou","","695947","1156919716"
"Tolyatti","Tolyatti","53.5089","49.4222","Russia","RU","RUS","Samarskaya Oblast’","","693072","1643256295"
"Macau","Macau","22.2006","113.5461","Macau","MO","MAC","","","568700","1446227359"
"Bauchi","Bauchi","10.3158","9.8442","Nigeria","NG","NGA","Bauchi","admin","693700","1566483284"
"Hamilton","Hamilton","43.2567","-79.8692","Canada","CA","CAN","Ontario","","693645","1124567288"
"Łódź","Lodz","51.7769","19.4547","Poland","PL","POL","Łódzkie","admin","690422","1616832750"
"Miluo Chengguanzhen","Miluo Chengguanzhen","28.8049","113.0745","China","CN","CHN","Hunan","minor","692080","1156690651"
"Gaizhou","Gaizhou","40.4019","122.3499","China","CN","CHN","Liaoning","minor","691595","1156145272"
"Karbalā’","Karbala'","32.6167","44.0333","Iraq","IQ","IRQ","Karbalā’","admin","690100","1368597794"
"Leling","Leling","37.7333","117.2167","China","CN","CHN","Shandong","minor","689867","1156044041"
"Jianshe","Jianshe","34.2189","113.7693","China","CN","CHN","Henan","minor","687130","1156444449"
"Shizuoka","Shizuoka","34.9756","138.3828","Japan","JP","JPN","Shizuoka","admin","685589","1392235505"
"Charleston","Charleston","32.8168","-79.9687","United States","US","USA","South Carolina","","685517","1840015163"
"Jingcheng","Jingcheng","32.0058","120.2547","China","CN","CHN","Jiangsu","minor","684360","1156033208"
"Kochi","Kochi","9.9700","76.2800","India","IN","IND","Kerala","","677381","1356707865"
"Mar del Plata","Mar del Plata","-38.0000","-57.5500","Argentina","AR","ARG","Buenos Aires","minor","682605","1032456103"
"Cape Coral","Cape Coral","26.6443","-81.9957","United States","US","USA","Florida","","682773","1840015130"
"Tasikmalaya","Tasikmalaya","-7.3161","108.1975","Indonesia","ID","IDN","Jawa Barat","","678027","1360027771"
"Rasht","Rasht","37.2808","49.5831","Iran","IR","IRN","Gīlān","admin","679995","1364630382"
"Xinmin","Xinmin","41.9952","122.8224","China","CN","CHN","Liaoning","","680000","1156368551"
"Shanhu","Shanhu","29.5908","120.8176","China","CN","CHN","Zhejiang","minor","679762","1156029691"
"Zhongshu","Zhongshu","27.8116","106.4133","China","CN","CHN","Guizhou","","679624","1156658422"
"Gorakhpur","Gorakhpur","26.7637","83.4039","India","IN","IND","Uttar Pradesh","","673446","1356190221"
"Zaragoza","Zaragoza","41.6500","-0.8833","Spain","ES","ESP","Aragon","admin","675301","1724907391"
"Wrocław","Wroclaw","51.1100","17.0325","Poland","PL","POL","Dolnośląskie","admin","672929","1616108520"
"Acapulco de Juárez","Acapulco de Juarez","16.8636","-99.8825","Mexico","MX","MEX","Guerrero","minor","673478","1484463183"
"Bahār","Bahar","34.9072","48.4414","Iran","IR","IRN","Hamadān","minor","673405","1364891653"
"Kumul","Kumul","42.8193","93.5151","China","CN","CHN","Xinjiang","minor","673383","1156131545"
"Murcia","Murcia","37.9861","-1.1303","Spain","ES","ESP","Murcia","admin","672773","1724107739"
"Pinghu","Pinghu","30.7005","121.0104","China","CN","CHN","Zhejiang","","671834","1156720536"
"Palermo","Palermo","38.1157","13.3613","Italy","IT","ITA","Sicilia","admin","668405","1380428434"
"Guankou","Guankou","30.9933","103.6240","China","CN","CHN","Sichuan","","671200","1156503907"
"Tlaquepaque","Tlaquepaque","20.6167","-103.3167","Mexico","MX","MEX","Jalisco","minor","664193","1484087945"
"Songyang","Songyang","34.4603","113.0280","China","CN","CHN","Henan","minor","668592","1156143149"
"Ch’ŏngjin","Ch'ongjin","41.7833","129.7667","North Korea","KP","PRK","Hambuk","admin","667929","1408498177"
"Puyang Chengguanzhen","Puyang Chengguanzhen","35.7004","114.9800","China","CN","CHN","Henan","minor","666322","1156103317"
"Qionghu","Qionghu","28.8417","112.3595","China","CN","CHN","Hunan","minor","667104","1156678111"
"Zhaodong","Zhaodong","46.0635","125.9773","China","CN","CHN","Heilongjiang","minor","666532","1156601489"
"Narela","Narela","28.8527","77.0929","India","IN","IND","Delhi","","661994","1356542019"
"Huambo","Huambo","-12.7767","15.7347","Angola","AO","AGO","Huambo","admin","665564","1024079828"
"Wenchang","Wenchang","31.0540","116.9507","China","CN","CHN","Anhui","minor","664455","1156574542"
"Villavicencio","Villavicencio","4.1500","-73.6333","Colombia","CO","COL","Meta","admin","664148","1170426927"
"Shulan","Shulan","44.4079","126.9429","China","CN","CHN","Jilin","minor","663403","1156989733"
"Tlalnepantla","Tlalnepantla","19.5367","-99.1947","Mexico","MX","MEX","México","minor","653410","1484861603"
"Catia La Mar","Catia La Mar","10.6000","-67.0333","Venezuela","VE","VEN","La Guaira","minor","661897","1862657837"
"Sargodha","Sargodha","32.0836","72.6711","Pakistan","PK","PAK","Punjab","minor","659862","1586696588"
"Al Hufūf","Al Hufuf","25.3833","49.5833","Saudi Arabia","SA","SAU","Ash Sharqīyah","","660788","1682958157"
"Durango","Durango","24.0250","-104.6675","Mexico","MX","MEX","Durango","admin","654876","1484854616"
"Jalingo","Jalingo","8.9195","11.3264","Nigeria","NG","NGA","Taraba","admin","660213","1566732241"
"Bouaké","Bouake","7.6833","-5.0167","Côte d'Ivoire","CI","CIV","Vallée du Bandama","admin","659233","1384983838"
"Mazatlán","Mazatlan","23.2167","-106.4167","Mexico","MX","MEX","Sinaloa","minor","658354","1484841466"
"San Jose del Monte","San Jose del Monte","14.8139","121.0453","Philippines","PH","PHL","Bulacan","","651813","1608850554"
"Ansan","Ansan","37.3167","126.8333","South Korea","KR","KOR","Gyeonggi","","652800","1410585171"
"Abomey-Calavi","Abomey-Calavi","6.4486","2.3556","Benin","BJ","BEN","Atlantique","","655965","1204016569"
"Lingbao Chengguanzhen","Lingbao Chengguanzhen","34.5221","110.8786","China","CN","CHN","Henan","minor","656571","1156837145"
"Hwasu-dong","Hwasu-dong","37.1997","126.8314","South Korea","KR","KOR","Gyeonggi","","655350","1410037247"
"Sialkot City","Sialkot City","32.4925","74.5311","Pakistan","PK","PAK","Punjab","minor","655852","1586141444"
"Huế","Hue","16.4667","107.5792","Vietnam","VN","VNM","Thừa Thiên-Huế","admin","652572","1704016023"
"Bulawayo","Bulawayo","-20.1700","28.5800","Zimbabwe","ZW","ZWE","Bulawayo","admin","653337","1716032632"
"Xiping","Xiping","25.6005","103.8166","China","CN","CHN","Yunnan","minor","652604","1156207794"
"Sanhe","Sanhe","39.9808","117.0701","China","CN","CHN","Hebei","","652042","1156476348"
"Ch’ŏnan","Ch'onan","36.8167","127.1667","South Korea","KR","KOR","Chungnam","","651661","1410020455"
"Contagem","Contagem","-19.9319","-44.0539","Brazil","BR","BRA","Minas Gerais","","648766","1076845191"
"Jieshou","Jieshou","33.2605","115.3618","China","CN","CHN","Anhui","","650870","1156304827"
"Selçuklu","Selcuklu","37.8814","32.4922","Turkey","TR","TUR","Konya","minor","648850","1792683478"
"Ērer Sātā","Erer Sata","9.5667","41.3833","Ethiopia","ET","ETH","Sumalē","","649000","1231563638"
"Honchō","Honcho","35.6946","139.9826","Japan","JP","JPN","Chiba","","641499","1392505502"
"Düsseldorf","Dusseldorf","51.2333","6.7833","Germany","DE","DEU","North Rhine-Westphalia","admin","645923","1276615258"
"Izhevsk","Izhevsk","56.8500","53.2167","Russia","RU","RUS","Udmurtiya","admin","646277","1643983138"
"Guntūr","Guntur","16.3008","80.4428","India","IN","IND","Andhra Pradesh","","647508","1356374905"
"Guixi","Guixi","28.2861","117.2083","China","CN","CHN","Jiangxi","minor","647240","1156259342"
"Sorocaba","Sorocaba","-23.5017","-47.4581","Brazil","BR","BRA","São Paulo","","644919","1076758708"
"Yıldırım","Yildirim","40.1100","29.0821","Turkey","TR","TUR","Bursa","minor","643681","1792764051"
"Petaling Jaya","Petaling Jaya","3.0972","101.6444","Malaysia","MY","MYS","Selangor","","638516","1458989343"
"Tengyue","Tengyue","25.0208","98.4972","China","CN","CHN","Yunnan","minor","644765","1156832866"
"Wuxue","Wuxue","29.8518","115.5539","China","CN","CHN","Hubei","minor","644247","1156027100"
"Rasūlpur","Rasulpur","28.5700","77.3200","India","IN","IND","Uttar Pradesh","","637272","1356018224"
"Bhāvnagar","Bhavnagar","21.7600","72.1500","India","IN","IND","Gujarāt","","643365","1356982375"
"Brahmapur","Brahmapur","19.3150","84.7941","India","IN","IND","Odisha","","634220","1356569354"
"Qufu","Qufu","35.6000","116.9833","China","CN","CHN","Shandong","","640498","1156490037"
"Gaobeidian","Gaobeidian","39.3257","115.8678","China","CN","CHN","Hebei","","640280","1156487293"
"Ruiming","Ruiming","25.8833","116.0333","China","CN","CHN","Jiangxi","","640600","1156104573"
"Columbia","Columbia","34.0378","-80.9036","United States","US","USA","South Carolina","admin","640502","1840014730"
"Concord","Concord","37.9722","-122.0016","United States","US","USA","California","","640270","1840018905"
"Noida","Noida","28.57","77.32","India","IN","IND","Uttar Pradesh","","637272","1356002491"
"Gold Coast","Gold Coast","-28.0167","153.4000","Australia","AU","AUS","Queensland","","638090","1036153217"
"Xinshi","Xinshi","31.0236","113.1079","China","CN","CHN","Hubei","minor","636776","1156422214"
"Ta‘izz","Ta\`izz","13.5789","44.0219","Yemen","YE","YEM","Ta‘izz","admin","615467","1887324526"
"Aracaju","Aracaju","-10.9167","-37.0500","Brazil","BR","BRA","Sergipe","admin","632744","1076527830"
"Jeonju","Jeonju","35.8167","127.1500","South Korea","KR","KOR","Jeonbuk","admin","631532","1410494847"
"Bhāngar","Bhangar","22.5800","88.4200","India","IN","IND","West Bengal","","632107","1356177796"
"Jin’e","Jin'e","29.3395","105.2868","China","CN","CHN","Sichuan","minor","633210","1156661253"
"Barnaul","Barnaul","53.3333","83.7500","Russia","RU","RUS","Altayskiy Kray","admin","633301","1643318931"
"Makati City","Makati City","14.5567","121.0214","Philippines","PH","PHL","Makati","admin","629616","1608725647"
"Rotterdam","Rotterdam","51.9167","4.5000","Netherlands","NL","NLD","Zuid-Holland","minor","631155","1528892850"
"Changping","Changping","40.2248","116.1944","China","CN","CHN","Beijing","","614821","1156166828"
"Benghazi","Benghazi","32.1167","20.0667","Libya","LY","LBY","Banghāzī","admin","631555","1434915572"
"Kryvyi Rih","Kryvyi Rih","47.9086","33.3433","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","629695","1804544901"
"Ḩalwān","Halwan","29.8453","31.3333","Egypt","EG","EGY","Al Qāhirah","","619293","1818000039"
"Colorado Springs","Colorado Springs","38.8674","-104.7605","United States","US","USA","Colorado","","628808","1840018825"
"Suohe","Suohe","34.7879","113.3920","China","CN","CHN","Henan","minor","626700","1156236948"
"Chimalhuacán","Chimalhuacan","19.4167","-98.9000","Mexico","MX","MEX","México","minor","614453","1484504936"
"Xinxing","Xinxing","39.8734","124.1453","China","CN","CHN","Liaoning","minor","627475","1156258586"
"Bhilai","Bhilai","21.3667","81.7167","India","IN","IND","Chhattīsgarh","","625700","1356098574"
"Mangalore","Mangalore","12.9020","74.8253","India","IN","IND","Karnātaka","","623841","1356972351"
"Port-Bouët","Port-Bouet","5.2667","-3.9000","Côte d'Ivoire","CI","CIV","Abidjan","","618795","1384512844"
"Zhuangyuan","Zhuangyuan","37.3056","120.8275","China","CN","CHN","Shandong","minor","626683","1156263400"
"Glasgow","Glasgow","55.8611","-4.2500","United Kingdom","GB","GBR","Glasgow City","","626410","1826836462"
"Namangan","Namangan","41.0011","71.6683","Uzbekistan","UZ","UZB","Namangan","admin","626120","1860894033"
"Ulyanovsk","Ulyanovsk","54.3167","48.3667","Russia","RU","RUS","Ul’yanovskaya Oblast’","admin","624518","1643668944"
"Tân An","Tan An","10.9050","106.6994","Vietnam","VN","VNM","Hồ Chí Minh","","618984","1704599287"
"Irkutsk","Irkutsk","52.2833","104.2833","Russia","RU","RUS","Irkutskaya Oblast’","admin","623736","1643439037"
"Bhilai","Bhilai","21.2100","81.3800","India","IN","IND","Chhattīsgarh","","624700","1356071083"
"Nāgercoil","Nagercoil","8.1833","77.4119","India","IN","IND","Tamil Nādu","","622759","1356051733"
"Jos","Jos","9.9167","8.8903","Nigeria","NG","NGA","Plateau","admin","622802","1566773679"
"Pontianak","Pontianak","-0.0206","109.3414","Indonesia","ID","IDN","Kalimantan Barat","admin","618388","1360992394"
"Bazhou","Bazhou","39.1235","116.3860","China","CN","CHN","Hebei","","622975","1156127276"
"Turpan","Turpan","42.9512","89.1895","China","CN","CHN","Xinjiang","minor","622903","1156573684"
"Villa Nueva","Villa Nueva","14.5314","-90.5964","Guatemala","GT","GTM","Guatemala","minor","618397","1320353009"
"Las Piñas City","Las Pinas City","14.4500","120.9800","Philippines","PH","PHL","Las Piñas","","606293","1608544537"
"Springfield","Springfield","42.1155","-72.5395","United States","US","USA","Massachusetts","","620494","1840000466"
"Knoxville","Knoxville","35.9692","-83.9496","United States","US","USA","Tennessee","","619925","1840014486"
"Meihekou","Meihekou","42.5279","125.6780","China","CN","CHN","Jilin","minor","618251","1156474288"
"Jurong","Jurong","31.9579","119.1595","China","CN","CHN","Jiangsu","","617706","1156897463"
"Feira de Santana","Feira de Santana","-12.2500","-38.9500","Brazil","BR","BRA","Bahia","","617528","1076892244"
"Khabarovsk","Khabarovsk","48.4833","135.0667","Russia","RU","RUS","Khabarovskiy Kray","admin","616242","1643175363"
"Zhugang","Zhugang","28.1277","121.2288","China","CN","CHN","Zhejiang","minor","616346","1156077330"
"Leipzig","Leipzig","51.3400","12.3750","Germany","DE","DEU","Saxony","minor","615081","1276563678"
"Xinji","Xinji","37.9423","115.2118","China","CN","CHN","Hebei","","615919","1156456552"
"Serang","Serang","-6.1200","106.1503","Indonesia","ID","IDN","Banten","admin","613356","1360329102"
"Jambi","Jambi","-1.5900","103.6100","Indonesia","ID","IDN","Jambi","admin","612162","1360645580"
"Kandahār","Kandahar","31.6200","65.7158","Afghanistan","AF","AFG","Kandahār","admin","614254","1004003059"
"San Miguel de Tucumán","San Miguel de Tucuman","-26.8167","-65.2167","Argentina","AR","ARG","Tucumán","admin","605767","1032233996"
"Gothenburg","Gothenburg","57.7075","11.9675","Sweden","SE","SWE","Västra Götaland","admin","607882","1752059374"
"Yaroslavl","Yaroslavl","57.6167","39.8500","Russia","RU","RUS","Yaroslavskaya Oblast’","admin","608079","1643338528"
"Baton Rouge","Baton Rouge","30.4420","-91.1311","United States","US","USA","Louisiana","admin","610751","1840013941"
"Zhangshu","Zhangshu","28.0667","115.5375","China","CN","CHN","Jiangxi","","610424","1156996913"
"Vladivostok","Vladivostok","43.1333","131.9000","Russia","RU","RUS","Primorskiy Kray","admin","606589","1643832017"
"Ogden","Ogden","41.2279","-111.9682","United States","US","USA","Utah","","608259","1840020135"
"Kuantan","Kuantan","3.8167","103.3333","Malaysia","MY","MYS","Pahang","admin","607778","1458763489"
"Raurkela","Raurkela","22.2492","84.8828","India","IN","IND","Odisha","","600000","1356096816"
"Cuttack","Cuttack","20.5236","85.7881","India","IN","IND","Odisha","","606007","1356872061"
"Ar Rayyān","Ar Rayyan","25.2500","51.4000","Qatar","QA","QAT","Ar Rayyān","admin","605712","1634097551"
"Haifa","Haifa","32.8192","34.9992","Israel","IL","ISR","Haifa","admin","600000","1376133727"
"Malanje","Malanje","-9.5333","16.3500","Angola","AO","AGO","Malanje","admin","604215","1024774947"
"Bacolod","Bacolod","10.6765","122.9509","Philippines","PH","PHL","Bacolod","admin","600783","1608244137"
"Zhuozhou","Zhuozhou","39.4887","115.9918","China","CN","CHN","Hebei","","603535","1156045160"
"Grand Rapids","Grand Rapids","42.9619","-85.6562","United States","US","USA","Michigan","","604311","1840002928"
"Uberlândia","Uberlandia","-18.9231","-48.2886","Brazil","BR","BRA","Minas Gerais","","604013","1076495001"
"Albany","Albany","42.6664","-73.7987","United States","US","USA","New York","admin","604077","1840000417"
"Tianchang","Tianchang","32.6853","119.0041","China","CN","CHN","Anhui","minor","602840","1156770851"
"Kawaguchi","Kawaguchi","35.8077","139.7241","Japan","JP","JPN","Saitama","","593353","1392975133"
"Itabashi","Itabashi","35.7512","139.7093","Japan","JP","JPN","Tōkyō","","581337","1392836404"
"Suginami-ku","Suginami-ku","35.6995","139.6364","Japan","JP","JPN","Tōkyō","","583609","1392003090"
"Cimahi","Cimahi","-6.8712","107.5548","Indonesia","ID","IDN","Jawa Barat","","586580","1360181436"
"Tuxtla","Tuxtla","16.7528","-93.1167","Mexico","MX","MEX","Chiapas","admin","598710","1484832519"
"Garoua","Garoua","9.3000","13.4000","Cameroon","CM","CMR","Nord","admin","600000","1120803808"
"Ḩafr al Bāţin","Hafr al Batin","28.4342","45.9636","Saudi Arabia","SA","SAU","Ash Sharqīyah","","600000","1682394909"
"Tumkūr","Tumkur","13.3400","77.1000","India","IN","IND","Karnātaka","","599078","1356033724"
"Balikpapan","Balikpapan","-1.2768","116.8277","Indonesia","ID","IDN","Kalimantan Timur","","598043","1360500922"
"Durban","Durban","-29.8833","31.0500","South Africa","ZA","ZAF","KwaZulu-Natal","","595061","1710149138"
"Kagoshima","Kagoshima","31.6000","130.5500","Japan","JP","JPN","Kagoshima","admin","593754","1392120675"
"Al Ḩillah","Al Hillah","23.4895","46.7564","Saudi Arabia","SA","SAU","Ar Riyāḑ","","594605","1682494753"
"Makhachkala","Makhachkala","42.9833","47.4833","Russia","RU","RUS","Dagestan","admin","592976","1643580480"
"Sihui","Sihui","23.3448","112.6956","China","CN","CHN","Guangdong","","593400","1156609451"
"Irapuato","Irapuato","20.6667","-101.3500","Mexico","MX","MEX","Guanajuato","minor","592953","1484625454"
"Brampton","Brampton","43.6833","-79.7667","Canada","CA","CAN","Ontario","","593638","1124625989"
"Luocheng","Luocheng","30.9793","104.2800","China","CN","CHN","Sichuan","minor","591115","1156180482"
"Mbale","Mbale","1.0806","34.1750","Uganda","UG","UGA","Mbale","admin","586300","1800265900"
"Pereira","Pereira","4.8143","-75.6946","Colombia","CO","COL","Risaralda","admin","590554","1170386718"
"Bakersfield","Bakersfield","35.3529","-119.0359","United States","US","USA","California","","590845","1840019148"
"Uvira","Uvira","-3.4000","29.1500","Congo (Kinshasa)","CD","COD","Sud-Kivu","","590000","1180205443"
"Dortmund","Dortmund","51.5167","7.4667","Germany","DE","DEU","North Rhine-Westphalia","minor","587696","1276562886"
"Chuxiong","Chuxiong","25.0461","101.5436","China","CN","CHN","Yunnan","","588620","1156225985"
"Mission Viejo","Mission Viejo","33.6096","-117.6551","United States","US","USA","California","","588540","1840020580"
"Zāhedān","Zahedan","29.4964","60.8628","Iran","IR","IRN","Sīstān va Balūchestān","admin","587730","1364072353"
"New Haven","New Haven","41.3113","-72.9246","United States","US","USA","Connecticut","","587648","1840004850"
"Shah Alam","Shah Alam","3.0722","101.5167","Malaysia","MY","MYS","Selangor","admin","584340","1458194263"
"Wenlan","Wenlan","23.3989","103.3631","China","CN","CHN","Yunnan","minor","585976","1156385754"
"Cuiabá","Cuiaba","-15.5958","-56.0969","Brazil","BR","BRA","Mato Grosso","admin","585367","1076112309"
"Shangzhi","Shangzhi","45.2116","127.9620","China","CN","CHN","Heilongjiang","minor","585386","1156107332"
"Essen","Essen","51.4508","7.0131","Germany","DE","DEU","North Rhine-Westphalia","minor","582415","1276769247"
"Botou","Botou","38.0740","116.5700","China","CN","CHN","Hebei","","584308","1156018338"
"Mymensingh","Mymensingh","24.7539","90.4031","Bangladesh","BD","BGD","Mymensingh","admin","576722","1050399881"
"Anyang","Anyang","37.3833","126.9333","South Korea","KR","KOR","Gyeonggi","","573296","1410581095"
"Genoa","Genoa","44.4111","8.9328","Italy","IT","ITA","Liguria","admin","580097","1380716540"
"Durgāpur","Durgapur","23.5500","87.3200","India","IN","IND","West Bengal","","581409","1356045686"
"Port Sudan","Port Sudan","19.6167","37.2167","Sudan","SD","SDN","Red Sea","admin","579942","1729710259"
"Al Mafraq","Al Mafraq","32.3399","36.2052","Jordan","JO","JOR","Al Mafraq","admin","580000","1400040712"
"Puente Alto","Puente Alto","-33.6167","-70.5667","Chile","CL","CHL","Región Metropolitana","minor","573935","1152516346"
"Dehra Dūn","Dehra Dun","30.3450","78.0290","India","IN","IND","Uttarākhand","admin","578420","1356970407"
"Aţ Ţā’if","At Ta'if","21.2751","40.4062","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","579970","1682956102"
"Wancheng","Wancheng","18.8000","110.4000","China","CN","CHN","Hainan","minor","578600","1156017483"
"Hachiōji","Hachioji","35.6664","139.3160","Japan","JP","JPN","Tōkyō","","575721","1392172935"
"Xiulin","Xiulin","29.7211","112.4037","China","CN","CHN","Hubei","","577022","1156284109"
"Fu’an","Fu'an","27.1000","119.6333","China","CN","CHN","Fujian","minor","577000","1156244234"
"Tomsk","Tomsk","56.4886","84.9522","Russia","RU","RUS","Tomskaya Oblast’","admin","572740","1643258928"
"Tonalá","Tonala","20.6167","-103.2333","Mexico","MX","MEX","Jalisco","minor","569913","1484750897"
"Juiz de Fora","Juiz de Fora","-21.7619","-43.3494","Brazil","BR","BRA","Minas Gerais","","573285","1076006144"
"Worcester","Worcester","42.2705","-71.8079","United States","US","USA","Massachusetts","","573573","1840000434"
"Kolwezi","Kolwezi","-10.7167","25.4667","Congo (Kinshasa)","CD","COD","Lualaba","admin","572942","1180380965"
"Chāpra","Chapra","25.7848","84.7274","India","IN","IND","Bihār","","567123","1356147120"
"Bristol","Bristol","51.4536","-2.5975","United Kingdom","GB","GBR","Bristol, City of","","567111","1826921049"
"Luofeng","Luofeng","37.3590","120.3960","China","CN","CHN","Shandong","minor","570898","1156158643"
"Lingyuan","Lingyuan","41.2407","119.3957","China","CN","CHN","Liaoning","","570660","1156184681"
"Irbid","Irbid","32.5500","35.8500","Jordan","JO","JOR","Irbid","admin","569068","1400221568"
"Al Maḩallah al Kubrá","Al Mahallah al Kubra","30.9686","31.1636","Egypt","EG","EGY","Al Gharbīyah","","535278","1818406471"
"Wencheng","Wencheng","19.6167","110.7500","China","CN","CHN","Hainan","minor","568900","1156985313"
"Bremen","Bremen","53.0833","8.8000","Germany","DE","DEU","Bremen","admin","566573","1276376509"
"Ciudad Bolívar","Ciudad Bolivar","8.1219","-63.5500","Venezuela","VE","VEN","Bolívar","admin","567953","1862318264"
"Orenburg","Orenburg","51.7667","55.1000","Russia","RU","RUS","Orenburgskaya Oblast’","admin","564773","1643685056"
"Shenzhou","Shenzhou","38.0005","115.5541","China","CN","CHN","Hebei","","566087","1156921843"
"Āsansol","Asansol","23.6800","86.9900","India","IN","IND","West Bengal","","563917","1356093694"
"Kuiju","Kuiju","36.8528","119.3904","China","CN","CHN","Shandong","minor","564501","1156174997"
"Zhenzhou","Zhenzhou","32.2739","119.1619","China","CN","CHN","Jiangsu","minor","564021","1156634387"
"Surakarta","Surakarta","-7.5667","110.8167","Indonesia","ID","IDN","Jawa Tengah","","552118","1360853933"
"Dresden","Dresden","51.0500","13.7400","Germany","DE","DEU","Saxony","admin","561922","1276561036"
"Kolhāpur","Kolhapur","16.6917","74.2333","India","IN","IND","Mahārāshtra","","561489","1356110593"
"Benguela","Benguela","-12.5500","13.4167","Angola","AO","AGO","Benguela","admin","561775","1024141961"
"Ensenada","Ensenada","31.8578","-116.6058","Mexico","MX","MEX","Baja California","minor","557430","1484714297"
"Kemerovo","Kemerovo","55.3667","86.0667","Russia","RU","RUS","Kemerovskaya Oblast’","admin","558973","1643216311"
"Lanxi","Lanxi","29.2167","119.4722","China","CN","CHN","Zhejiang","","560514","1156686465"
"Dangyang","Dangyang","30.8258","111.7910","China","CN","CHN","Hubei","","560000","1156585505"
"Farīdpur","Faridpur","23.6020","89.8330","Bangladesh","BD","BGD","Dhaka","","557632","1050187264"
"Herāt","Herat","34.3419","62.2031","Afghanistan","AF","AFG","Herāt","admin","556205","1004237782"
"Luanzhou","Luanzhou","39.7396","118.6978","China","CN","CHN","Hebei","minor","554315","1156092104"
"Hamadān","Hamadan","34.8064","48.5161","Iran","IR","IRN","Hamadān","admin","554406","1364126377"
"Novokuznetsk","Novokuznetsk","53.7667","87.1333","Russia","RU","RUS","Kemerovskaya Oblast’","","552105","1643628717"
"Nellore","Nellore","14.4500","79.9900","India","IN","IND","Andhra Pradesh","","547621","1356280972"
"Palma","Palma","39.5667","2.6500","Spain","ES","ESP","Balearic Islands","admin","550000","1724728111"
"Chiclayo","Chiclayo","-6.7630","-79.8366","Peru","PE","PER","Lambayeque","admin","552508","1604799615"
"Nānded","Nanded","19.1500","77.3000","India","IN","IND","Mahārāshtra","","550439","1356722310"
"Ajmer","Ajmer","26.4499","74.6399","India","IN","IND","Rājasthān","","551101","1356058258"
"Al Ḩudaydah","Al Hudaydah","14.8022","42.9511","Yemen","YE","YEM","Al Ḩudaydah","admin","548433","1887984890"
"Kimhae","Kimhae","35.2342","128.8811","South Korea","KR","KOR","Gyeongnam","","550758","1410836094"
"Provo","Provo","40.2457","-111.6457","United States","US","USA","Utah","","551645","1840020174"
"Rustenburg","Rustenburg","-25.6667","27.2428","South Africa","ZA","ZAF","North West","","549575","1710326039"
"Amrāvati","Amravati","20.9258","77.7647","India","IN","IND","Mahārāshtra","","549370","1356462099"
"Poznań","Poznan","52.4083","16.9336","Poland","PL","POL","Wielkopolskie","admin","546859","1616725607"
"Huanghua","Huanghua","38.3710","117.3329","China","CN","CHN","Hebei","minor","548507","1156131099"
"Muntinlupa City","Muntinlupa City","14.3800","121.0500","Philippines","PH","PHL","Muntinlupa","admin","543445","1608473406"
"Londrina","Londrina","-23.3100","-51.1628","Brazil","BR","BRA","Paraná","","548249","1076723241"
"Nansana","Nansana","0.3639","32.5286","Uganda","UG","UGA","Wakiso","","532800","1800032516"
"Tabūk","Tabuk","28.3972","36.5789","Saudi Arabia","SA","SAU","Tabūk","admin","547957","1682590992"
"Heroica Matamoros","Heroica Matamoros","25.8797","-97.5042","Mexico","MX","MEX","Tamaulipas","minor","541979","1484614906"
"Akron","Akron","41.0798","-81.5219","United States","US","USA","Ohio","","546549","1840000791"
"Samarkand","Samarkand","39.6547","66.9758","Uzbekistan","UZ","UZB","Samarqand","admin","546303","1860388706"
"Xingcheng","Xingcheng","40.6189","120.7205","China","CN","CHN","Liaoning","","546176","1156705922"
"Kaiyuan","Kaiyuan","42.5380","124.0371","China","CN","CHN","Liaoning","","545624","1156081841"
"Hannover","Hannover","52.3667","9.7167","Germany","DE","DEU","Lower Saxony","admin","542668","1276457707"
"Valledupar","Valledupar","10.4833","-73.2500","Colombia","CO","COL","Cesar","admin","544134","1170481949"
"Fengcheng","Fengcheng","40.4543","124.0646","China","CN","CHN","Liaoning","","543933","1156519626"
"Ixtapaluca","Ixtapaluca","19.3186","-98.8822","Mexico","MX","MEX","México","minor","542211","1484452937"
"Ghulja","Ghulja","43.9000","81.3500","China","CN","CHN","Xinjiang","minor","542507","1156064979"
"City of Calamba","City of Calamba","14.2167","121.1667","Philippines","PH","PHL","Laguna","","539671","1608985581"
"Ananindeua","Ananindeua","-1.3658","-48.3719","Brazil","BR","BRA","Pará","","540410","1076025295"
"Xiangyang","Xiangyang","34.2999","108.4816","China","CN","CHN","Shaanxi","","541554","1156215566"
"Fuding","Fuding","27.2000","120.2000","China","CN","CHN","Fujian","minor","542000","1156530120"
"An Nāşirīyah","An Nasiriyah","31.0439","46.2575","Iraq","IQ","IRQ","Dhī Qār","admin","541600","1368273311"
"Al Ḩillah","Al Hillah","32.4833","44.4333","Iraq","IQ","IRQ","Bābil","admin","541034","1368916692"
"Ibagué","Ibague","4.4333","-75.2333","Colombia","CO","COL","Tolima","admin","541101","1170245063"
"Ryazan","Ryazan","54.6300","39.7425","Russia","RU","RUS","Ryazanskaya Oblast’","admin","537622","1643205787"
"Kassala","Kassala","15.4500","36.4000","Sudan","SD","SDN","Kassala","admin","536009","1729157224"
"Tripoli","Tripoli","34.4367","35.8344","Lebanon","LB","LBN","Liban-Nord","admin","530000","1422886065"
"Chang’an","Chang'an","29.4761","113.4480","China","CN","CHN","Hunan","minor","537500","1156139784"
"Kōtō-ku","Koto-ku","35.6728","139.8173","Japan","JP","JPN","Tōkyō","","523117","1392003346"
"Naberezhnyye Chelny","Naberezhnyye Chelny","55.7000","52.3333","Russia","RU","RUS","Tatarstan","","533839","1643008493"
"Aksu","Aksu","41.1850","80.2904","China","CN","CHN","Xinjiang","minor","535657","1156436913"
"Salta","Salta","-24.7833","-65.4167","Argentina","AR","ARG","Salta","admin","535303","1032926124"
"Astrakhan","Astrakhan","46.3500","48.0350","Russia","RU","RUS","Astrakhanskaya Oblast’","admin","532504","1643407929"
"Gulbarga","Gulbarga","17.3290","76.8250","India","IN","IND","Karnātaka","","533587","1356091187"
"Cadaado","Cadaado","6.1403","46.6256","Somalia","SO","SOM","Galguduud","","534023","1706406560"
"Lyon","Lyon","45.7600","4.8400","France","FR","FRA","Auvergne-Rhône-Alpes","admin","522969","1250196189"
"Wuhai","Wuhai","39.6844","106.8158","China","CN","CHN","Inner Mongolia","minor","532902","1156575615"
"Mingguang","Mingguang","32.7816","117.9899","China","CN","CHN","Anhui","","532732","1156864248"
"Antwerp","Antwerp","51.2178","4.4003","Belgium","BE","BEL","Flanders","minor","529247","1056168623"
"Yazd","Yazd","31.8822","54.3397","Iran","IR","IRN","Yazd","admin","529673","1364429875"
"Touba","Touba","14.8667","-15.8833","Senegal","SN","SEN","Diourbel","","529176","1686956738"
"Ardabīl","Ardabil","38.2425","48.2900","Iran","IR","IRN","Ardabīl","admin","529374","1364437616"
"Bīkaner","Bikaner","28.0167","73.3119","India","IN","IND","Rājasthān","","529007","1356026593"
"Agartala","Agartala","23.8333","91.2833","India","IN","IND","Tripura","admin","522613","1356148992"
"Ndola","Ndola","-12.9667","28.6333","Zambia","ZM","ZMB","Copperbelt","admin","528330","1894032745"
"Himeji","Himeji","34.8167","134.6833","Japan","JP","JPN","Hyōgo","","527596","1392003149"
"Palm Bay","Palm Bay","27.9631","-80.6593","United States","US","USA","Florida","","528322","1840015094"
"Tultitlán de Mariano Escobedo","Tultitlan de Mariano Escobedo","19.6450","-99.1694","Mexico","MX","MEX","México","minor","520557","1484562374"
"Ailan Mubage","Ailan Mubage","43.9080","81.2777","China","CN","CHN","Xinjiang","minor","526745","1156279608"
"Bandar ‘Abbās","Bandar \`Abbas","27.1833","56.2667","Iran","IR","IRN","Hormozgān","admin","526648","1364120623"
"Bắc Ninh","Bac Ninh","21.1833","106.0500","Vietnam","VN","VNM","Bắc Ninh","admin","520000","1704025181"
"Ciudad Apodaca","Ciudad Apodaca","25.7817","-100.1886","Mexico","MX","MEX","Nuevo León","minor","523370","1484855080"
"Santa Teresa del Tuy","Santa Teresa del Tuy","10.2333","-66.6500","Venezuela","VE","VEN","Miranda","minor","525321","1862528581"
"Maipú","Maipu","-33.5167","-70.7667","Chile","CL","CHL","Región Metropolitana","","521627","1152583016"
"Penza","Penza","53.2000","45.0000","Russia","RU","RUS","Penzenskaya Oblast’","admin","523726","1643492830"
"Soacha","Soacha","4.5872","-74.2214","Colombia","CO","COL","Cundinamarca","minor","522442","1170512000"
"Al Qaţīf","Al Qatif","26.5560","49.9960","Saudi Arabia","SA","SAU","Ash Sharqīyah","","524182","1682001245"
"Port Said","Port Said","31.2625","32.3061","Egypt","EG","EGY","Būr Sa‘īd","admin","524433","1818628574"
"Loni","Loni","28.7500","77.2800","India","IN","IND","Uttar Pradesh","","516082","1356528413"
"Yucheng","Yucheng","36.9329","116.6403","China","CN","CHN","Shandong","minor","522878","1156530681"
"Sevastopol","Sevastopol","44.6050","33.5225","Ukraine","UA","UKR","Sevastopol, Misto","admin","522057","1804806030"
"Meknès","Meknes","33.8950","-5.5547","Morocco","MA","MAR","Fès-Meknès","","520428","1504333193"
"Mixco","Mixco","14.6338","-90.6146","Guatemala","GT","GTM","Guatemala","minor","517505","1320420152"
"Arāk","Arak","34.0917","49.6892","Iran","IR","IRN","Markazī","admin","520944","1364624691"
"Pohang","Pohang","36.0322","129.3650","South Korea","KR","KOR","Gyeongbuk","minor","520305","1410983252"
"Longzhou","Longzhou","30.3200","112.2300","China","CN","CHN","Hubei","","520000","1156863928"
"Anda","Anda","46.4078","125.3252","China","CN","CHN","Heilongjiang","minor","520000","1156142150"
"Jinghong","Jinghong","22.0057","100.7948","China","CN","CHN","Yunnan","","519935","1156181533"
"Sheffield","Sheffield","53.3808","-1.4703","United Kingdom","GB","GBR","Sheffield","","518090","1826289852"
"Utsunomiya","Utsunomiya","36.5551","139.8826","Japan","JP","JPN","Tochigi","admin","518197","1392506125"
"Suez","Suez","29.9667","32.5500","Egypt","EG","EGY","As Suways","admin","516959","1818495972"
"Nuremberg","Nuremberg","49.4539","11.0775","Germany","DE","DEU","Bavaria","minor","515543","1276166609"
"Surrey","Surrey","49.1900","-122.8489","Canada","CA","CAN","British Columbia","","517887","1124001454"
"Heshan","Heshan","22.7697","112.9578","China","CN","CHN","Guangdong","","516500","1156013872"
"Ujjain","Ujjain","23.1700","75.7900","India","IN","IND","Madhya Pradesh","","515215","1356050735"
"Jiaji","Jiaji","19.2431","110.4642","China","CN","CHN","Hainan","minor","515700","1156658217"
"Santa Marta","Santa Marta","11.2419","-74.2053","Colombia","CO","COL","Magdalena","admin","515556","1170715594"
"Joinvile","Joinvile","-26.3204","-48.8437","Brazil","BR","BRA","Santa Catarina","","515288","1076050637"
"Beining","Beining","41.5961","121.7928","China","CN","CHN","Liaoning","","514898","1156424678"
"Des Moines","Des Moines","41.5725","-93.6105","United States","US","USA","Iowa","admin","514654","1840007069"
"Hải Dương","Hai Duong","20.9397","106.3306","Vietnam","VN","VNM","Hải Dương","admin","507469","1704909566"
"Carrefour","Carrefour","18.5344","-72.4094","Haiti","HT","HTI","Ouest","","511345","1332000098"
"Maturín","Maturin","9.7423","-63.1889","Venezuela","VE","VEN","Monagas","admin","514046","1862546949"
"Homyel’","Homyel'","52.4453","30.9842","Belarus","BY","BLR","Homyel’skaya Voblasts’","admin","510300","1112580227"
"Leicester","Leicester","52.6344","-1.1319","United Kingdom","GB","GBR","Leicester","","508916","1826431946"
"Yanji","Yanji","42.9044","129.5067","China","CN","CHN","Jilin","minor","513072","1156405468"
"Macapá","Macapa","0.0330","-51.0653","Brazil","BR","BRA","Amapá","admin","512902","1076189884"
"Yicheng","Yicheng","31.7117","112.2551","China","CN","CHN","Hubei","minor","512530","1156742818"
"Ulhāsnagar","Ulhasnagar","19.2200","73.1500","India","IN","IND","Mahārāshtra","","506098","1356375341"
"Al ‘Āshir min Ramaḑān","Al \`Ashir min Ramadan","30.3065","31.7415","Egypt","EG","EGY","Ash Sharqīyah","","511987","1818297014"
"Lipetsk","Lipetsk","52.6167","39.6000","Russia","RU","RUS","Lipetskaya Oblast’","admin","510439","1643772906"
"Al ‘Amārah","Al \`Amarah","31.8333","47.1500","Iraq","IQ","IRQ","Maysān","admin","511500","1368336235"
"Bhīlwāra","Bhilwara","25.3500","74.6300","India","IN","IND","Rājasthān","","509483","1356077831"
"Encheng","Encheng","22.1879","112.3131","China","CN","CHN","Guangdong","minor","509700","1156653230"
"Murrieta","Murrieta","33.5719","-117.1909","United States","US","USA","California","","509526","1840020553"
"Aden","Aden","12.8000","45.0333","Yemen","YE","YEM","‘Adan","admin","507355","1887867886"
"Kitwe","Kitwe","-12.8167","28.2000","Zambia","ZM","ZMB","Copperbelt","","504194","1894321752"
"Matsuyama","Matsuyama","33.8333","132.7667","Japan","JP","JPN","Ehime","admin","506743","1392220893"
"Jhānsi","Jhansi","25.4486","78.5696","India","IN","IND","Uttar Pradesh","","507000","1356712664"
"Pingtung","Pingtung","22.6761","120.4942","Taiwan","TW","TWN","Pingtung","admin","503530","1158209038"
"Lapu-Lapu City","Lapu-Lapu City","10.3127","123.9488","Philippines","PH","PHL","Lapu-Lapu","admin","497604","1608430676"
"Matsudo","Matsudo","35.7876","139.9032","Japan","JP","JPN","Chiba","","497514","1392642525"
"Montería","Monteria","8.7500","-75.8833","Colombia","CO","COL","Córdoba","admin","505334","1170855299"
"Ichikawa","Ichikawa","35.7219","139.9311","Japan","JP","JPN","Chiba","","496657","1392076347"
"Nagqu","Nagqu","31.4766","92.0569","China","CN","CHN","Tibet","minor","504838","1156260844"
"Imus","Imus","14.4297","120.9367","Philippines","PH","PHL","Cavite","","496794","1608802508"
"Hsinchu","Hsinchu","24.8167","120.9833","Taiwan","TW","TWN","Hsinchu","admin","499348","1158276420"
"Kimberley","Kimberley","-28.7383","24.7639","South Africa","ZA","ZAF","Northern Cape","admin","500757","1710282622"
"Jammu","Jammu","32.7300","74.8700","India","IN","IND","Jammu and Kashmīr","admin","502197","1356491738"
"Belas","Belas","-8.9983","13.2650","Angola","AO","AGO","Luanda","","500000","1024196826"
"Talatona","Talatona","-8.9167","13.1833","Angola","AO","AGO","Luanda","","500000","1024988332"
"Ile-Ife","Ile-Ife","7.4667","4.5667","Nigeria","NG","NGA","Osun","minor","501952","1566756897"
"Purnea","Purnea","25.7780","87.4760","India","IN","IND","Bihār","","496830","1356486511"
"Kirov","Kirov","58.6000","49.6500","Russia","RU","RUS","Kirovskaya Oblast’","admin","501468","1643106064"
"Mykolaiv","Mykolaiv","46.9750","31.9950","Ukraine","UA","UKR","Mykolaivska Oblast","admin","498748","1804398395"
"Meicheng","Meicheng","30.6412","116.5689","China","CN","CHN","Anhui","minor","500292","1156136207"
"Tinnevelly","Tinnevelly","8.7136","77.7567","India","IN","IND","Tamil Nādu","","497826","1356037155"
"Niterói","Niteroi","-22.8833","-43.1036","Brazil","BR","BRA","Rio de Janeiro","","496696","1076501425"
"Farāh","Farah","32.3436","62.1194","Afghanistan","AF","AFG","Farāh","admin","500000","1004523791"
"Sukkur","Sukkur","27.7061","68.8483","Pakistan","PK","PAK","Sindh","minor","499900","1586383591"
"Oujda-Angad","Oujda-Angad","34.6867","-1.9114","Morocco","MA","MAR","Oriental","admin","494252","1504891036"
"Taozhou","Taozhou","30.8956","119.4110","China","CN","CHN","Anhui","minor","499132","1156438524"
"Marka","Marka","1.6833","44.7500","Somalia","SO","SOM","Shabeellaha Hoose","admin","499000","1706165964"
"Higashi-ōsaka","Higashi-osaka","34.6794","135.6008","Japan","JP","JPN","Ōsaka","","490819","1392117396"
"Johor Bahru","Johor Bahru","1.4556","103.7611","Malaysia","MY","MYS","Johor","admin","497097","1458747615"
"Hongjiang","Hongjiang","27.1167","109.9500","China","CN","CHN","Hunan","","498100","1156039152"
"Bello","Bello","6.3333","-75.5667","Colombia","CO","COL","Antioquia","minor","495483","1170423489"
"Chimbote","Chimbote","-9.0745","-78.5936","Peru","PE","PER","Ancash","","497875","1604199858"
"Duisburg","Duisburg","51.4347","6.7625","Germany","DE","DEU","North Rhine-Westphalia","minor","495885","1276977172"
"Qaraghandy","Qaraghandy","49.8028","73.1056","Kazakhstan","KZ","KAZ","","admin","497777","1398274508"
"Mesa","Mesa","33.4015","-111.7178","United States","US","USA","Arizona","","497752","1840020566"
"Toulouse","Toulouse","43.6045","1.4440","France","FR","FRA","Occitanie","admin","493465","1250258110"
"Likasi","Likasi","-10.9833","26.7333","Congo (Kinshasa)","CD","COD","Haut-Katanga","","495000","1180978192"
"Dengtacun","Dengtacun","41.4237","123.3203","China","CN","CHN","Liaoning","","496122","1156096868"
"Lembok","Lembok","-8.5650","116.3510","Indonesia","ID","IDN","Nusa Tenggara Barat","","495651","1360013261"
"Zhijiang","Zhijiang","30.4271","111.7506","China","CN","CHN","Hubei","","495995","1156769646"
"Chengjiao","Chengjiao","35.4043","114.0593","China","CN","CHN","Henan","minor","495744","1156571332"
"Beipiao","Beipiao","41.8020","120.7621","China","CN","CHN","Liaoning","","496164","1156378599"
"Suoluntun","Suoluntun","45.2279","124.8432","China","CN","CHN","Jilin","","495900","1156764587"
"Staten Island","Staten Island","40.5834","-74.1496","United States","US","USA","New York","","495747","1840034032"
"Kota Bharu","Kota Bharu","6.1333","102.2500","Malaysia","MY","MYS","Kelantan","admin","491237","1458150101"
"Ciudad López Mateos","Ciudad Lopez Mateos","19.5500","-99.2833","Mexico","MX","MEX","México","minor","489937","1484363879"
"Celaya","Celaya","20.5222","-100.8122","Mexico","MX","MEX","Guanajuato","minor","494304","1484979555"
"Vinh","Vinh","18.6667","105.6667","Vietnam","VN","VNM","Nghệ An","admin","490000","1704960059"
"Duyun","Duyun","26.2672","107.5143","China","CN","CHN","Guizhou","","493574","1156084618"
"Los Mochis","Los Mochis","25.7835","-108.9937","Mexico","MX","MEX","Sinaloa","minor","490000","1484443585"
"‘Ajmān","\`Ajman","25.4136","55.4456","United Arab Emirates","AE","ARE","‘Ajmān","admin","490035","1784337875"
"Nyala","Nyala","12.0500","24.8833","Sudan","SD","SDN","South Darfur","admin","492984","1729833248"
"Larkana","Larkana","27.5583","68.2111","Pakistan","PK","PAK","Sindh","minor","490508","1586678302"
"Wichita","Wichita","37.6895","-97.3443","United States","US","USA","Kansas","","491916","1840001686"
"Nishinomiya-hama","Nishinomiya-hama","34.7376","135.3416","Japan","JP","JPN","Hyōgo","","487010","1392114116"
"Cheboksary","Cheboksary","56.1333","47.2500","Russia","RU","RUS","Chuvashiya","admin","489498","1643163382"
"Yuanping","Yuanping","38.7299","112.7134","China","CN","CHN","Shanxi","","491213","1156294277"
"Toledo","Toledo","41.6638","-83.5827","United States","US","USA","Ohio","","490832","1840000572"
"Yueqing","Yueqing","28.1188","120.9621","China","CN","CHN","Zhejiang","","488980","1156900058"
"Edinburgh","Edinburgh","55.9533","-3.1892","United Kingdom","GB","GBR","Edinburgh, City of","","488050","1826492520"
"Belgaum","Belgaum","15.8500","74.5000","India","IN","IND","Karnātaka","","488157","1356390535"
"Tula","Tula","54.2000","37.6167","Russia","RU","RUS","Tul’skaya Oblast’","admin","485221","1643255206"
"Gdańsk","Gdansk","54.3475","18.6453","Poland","PL","POL","Pomorskie","admin","486022","1616406372"
"Shahe","Shahe","36.8622","114.5020","China","CN","CHN","Hebei","","486329","1156690538"
"Serra","Serra","-20.1289","-40.3078","Brazil","BR","BRA","Espírito Santo","","485376","1076191325"
"Brookhaven","Brookhaven","40.8320","-72.9517","United States","US","USA","New York","","485984","1840057464"
"Gaoping","Gaoping","35.7911","112.9259","China","CN","CHN","Shanxi","","484862","1156238773"
"Dunhua","Dunhua","43.3667","128.2333","China","CN","CHN","Jilin","minor","483811","1156058709"
"Az Zarqā’","Az Zarqa'","32.0833","36.1000","Jordan","JO","JOR","Az Zarqā’","admin","481300","1400161837"
"Sylhet","Sylhet","24.9000","91.8667","Bangladesh","BD","BGD","Sylhet","admin","479837","1050646703"
"Kaihua","Kaihua","23.3715","104.2437","China","CN","CHN","Yunnan","minor","481505","1156017759"
"Caerdydd","Caerdydd","51.4817","-3.1792","United Kingdom","GB","GBR","Cardiff","","479000","1826362422"
"Jāmnagar","Jamnagar","22.4700","70.0700","India","IN","IND","Gujarāt","","479920","1356104995"
"Fuyuan","Fuyuan","48.3614","134.2875","China","CN","CHN","Heilongjiang","minor","480000","1156196485"
"Gaya","Gaya","24.7500","85.0100","India","IN","IND","Bihār","","474093","1356045727"
"Florianópolis","Florianopolis","-27.6122","-48.4853","Brazil","BR","BRA","Santa Catarina","admin","477798","1076190259"
"Chiniot","Chiniot","31.7194","72.9842","Pakistan","PK","PAK","Punjab","minor","477781","1586413435"
"Jiannan","Jiannan","31.3446","104.1994","China","CN","CHN","Sichuan","minor","477868","1156155414"
"Ōita","Oita","33.2333","131.6067","Japan","JP","JPN","Ōita","admin","477186","1392081193"
"Berbera","Berbera","10.4356","45.0164","Somalia","SO","SOM","Woqooyi Galbeed","","478000","1706893125"
"Kaliningrad","Kaliningrad","54.7003","20.4531","Russia","RU","RUS","Kaliningradskaya Oblast’","admin","475056","1643178106"
"Mbeya","Mbeya","-8.9000","33.4500","Tanzania","TZ","TZA","Mbeya","admin","467000","1834396594"
"Nangong","Nangong","37.3606","115.3803","China","CN","CHN","Hebei","","476096","1156426803"
"Ambattūr","Ambattur","13.1143","80.1548","India","IN","IND","Tamil Nādu","","466205","1356685959"
"Katlehong","Katlehong","-26.3333","28.1500","South Africa","ZA","ZAF","Gauteng","","467890","1710975690"
"Kurashiki","Kurashiki","34.5833","133.7667","Japan","JP","JPN","Okayama","","474862","1392003246"
"Barura","Barura","23.3750","91.0583","Bangladesh","BD","BGD","Chattogram","","473590","1050831924"
"San Juan","San Juan","-31.5342","-68.5261","Argentina","AR","ARG","San Juan","admin","471389","1032869625"
"Vila Velha","Vila Velha","-20.3364","-40.2936","Brazil","BR","BRA","Espírito Santo","","472762","1076404077"
"Mazār-e Sharīf","Mazar-e Sharif","36.7000","67.1167","Afghanistan","AF","AFG","Balkh","admin","469247","1004436363"
"Shekhupura","Shekhupura","31.7111","73.9878","Pakistan","PK","PAK","Punjab","minor","473129","1586291726"
"Worthing","Worthing","50.8147","-0.3714","United Kingdom","GB","GBR","West Sussex","","474485","1826936965"
"Piura","Piura","-5.2000","-80.6333","Peru","PE","PER","Piura","admin","473025","1604331641"
"Mandaluyong City","Mandaluyong City","14.5800","121.0300","Philippines","PH","PHL","Mandaluyong","admin","425758","1608166320"
"Kartal","Kartal","40.8872","29.1900","Turkey","TR","TUR","İstanbul","minor","461155","1792988658"
"Jiaojiangcun","Jiaojiangcun","28.6804","121.4500","China","CN","CHN","Zhejiang","","471500","1156812073"
"Harrisburg","Harrisburg","40.2752","-76.8843","United States","US","USA","Pennsylvania","admin","472261","1840001288"
"Laohekou","Laohekou","32.3849","111.6695","China","CN","CHN","Hubei","","471482","1156791804"
"Kâğıthane","Kagithane","41.0719","28.9664","Turkey","TR","TUR","İstanbul","","437026","1792526380"
"Agege","Agege","6.6219","3.3258","Nigeria","NG","NGA","Lagos","minor","459939","1566564887"
"Angeles City","Angeles City","15.1472","120.5847","Philippines","PH","PHL","Angeles","admin","462928","1608036027"
"São José do Rio Prêto","Sao Jose do Rio Preto","-20.8081","-49.3811","Brazil","BR","BRA","São Paulo","","469173","1076760827"
"Pasay City","Pasay City","14.5500","121.0000","Philippines","PH","PHL","Pasay","admin","440656","1608448280"
"Beian","Beian","48.2395","126.5037","China","CN","CHN","Heilongjiang","minor","470000","1156084414"
"Fujin","Fujin","47.2489","132.0341","China","CN","CHN","Heilongjiang","minor","470000","1156404715"
"Salt Lake City","Salt Lake City","22.6100","88.4000","India","IN","IND","West Bengal","","403316","1356720205"
"Balashikha","Balashikha","55.8167","37.9667","Russia","RU","RUS","Moskovskaya Oblast’","","468221","1643989845"
"Xiaoyi","Xiaoyi","37.1449","111.7718","China","CN","CHN","Shanxi","","468770","1156910270"
"Port St. Lucie","Port St. Lucie","27.2796","-80.3884","United States","US","USA","Florida","","468979","1840015119"
"Lanús","Lanus","-34.7000","-58.4000","Argentina","AR","ARG","Buenos Aires","minor","459263","1032009573"
"Qingzhen","Qingzhen","26.5555","106.4646","China","CN","CHN","Guizhou","","467992","1156587033"
"Jiangshan","Jiangshan","28.7412","118.6225","China","CN","CHN","Zhejiang","","467862","1156346497"
"Ba‘qūbah","Ba\`qubah","33.7500","44.6333","Iraq","IQ","IRQ","Diyālá","admin","467900","1368444902"
"Katsushika-ku","Katsushika-ku","35.7434","139.8472","Japan","JP","JPN","Tōkyō","","453410","1392003368"
"Buraydah","Buraydah","26.3333","43.9667","Saudi Arabia","SA","SAU","Al Qaşīm","admin","467410","1682470726"
"Longjiang","Longjiang","27.5820","120.5488","China","CN","CHN","Zhejiang","","464695","1156034166"
"Tân Uyên","Tan Uyen","11.0508","106.7636","Vietnam","VN","VNM","Bình Dương","minor","466053","1704985934"
"Long Beach","Long Beach","33.7977","-118.1670","United States","US","USA","California","","466565","1840020490"
"Marikina City","Marikina City","14.6500","121.1000","Philippines","PH","PHL","Marikina","admin","456059","1608000077"
"Manado","Manado","1.4931","124.8413","Indonesia","ID","IDN","Sulawesi Utara","admin","461636","1360771278"
"Campos","Campos","-21.7539","-41.3239","Brazil","BR","BRA","Rio de Janeiro","","463731","1076606365"
"Kanazawa","Kanazawa","36.5611","136.6564","Japan","JP","JPN","Ishikawa","admin","462479","1392684679"
"Iloilo","Iloilo","10.7167","122.5667","Philippines","PH","PHL","Iloilo","admin","457626","1608875987"
"Reno","Reno","39.5497","-119.8483","United States","US","USA","Nevada","","463328","1840020121"
"Calabar","Calabar","4.9767","8.3383","Nigeria","NG","NGA","Cross River","admin","461796","1566495489"
"Kuqa","Kuqa","41.7156","82.9322","China","CN","CHN","Xinjiang","minor","462588","1156894895"
"Piraeus","Piraeus","37.9430","23.6469","Greece","GR","GRC","Attikí","minor","448997","1300458367"
"Madison","Madison","43.0822","-89.3930","United States","US","USA","Wisconsin","admin","461778","1840002915"
"Jalgaon","Jalgaon","21.0040","75.5680","India","IN","IND","Mahārāshtra","","460468","1356734842"
"Cranbourne","Cranbourne","-38.0996","145.2834","Australia","AU","AUS","Victoria","","460491","1036685862"
"Kowloon City","Kowloon City","22.3282","114.1916","Hong Kong","HK","HKG","","","418732","1344000061"
"Mauá","Maua","-23.6678","-46.4608","Brazil","BR","BRA","São Paulo","","453286","1076477498"
"Fukuyama","Fukuyama","34.4833","133.3667","Japan","JP","JPN","Hiroshima","","459576","1392551602"
"Xicheng","Xicheng","23.3629","103.1545","China","CN","CHN","Yunnan","minor","459781","1156161838"
"Kikwit","Kikwit","-5.0386","18.8181","Congo (Kinshasa)","CD","COD","Kwilu","","458000","1180518247"
"Amagasaki","Amagasaki","34.7333","135.4000","Japan","JP","JPN","Hyōgo","","450506","1392003182"
"Pyeongtaek","Pyeongtaek","36.9922","127.1128","South Korea","KR","KOR","Gyeonggi","","457873","1410641095"
"Kurnool","Kurnool","15.8300","78.0500","India","IN","IND","Andhra Pradesh","","457633","1356058372"
"Little Rock","Little Rock","34.7256","-92.3577","United States","US","USA","Arkansas","admin","457379","1840015509"
"Melaka","Melaka","2.1944","102.2486","Malaysia","MY","MYS","Melaka","admin","455300","1458467506"
"General Trias","General Trias","14.3833","120.8833","Philippines","PH","PHL","Cavite","","450583","1608826995"
"Jian’ou","Jian'ou","27.0387","118.3215","China","CN","CHN","Fujian","minor","456000","1156278784"
"Esenler","Esenler","41.0339","28.8903","Turkey","TR","TUR","İstanbul","","444561","1792000667"
"Huadian","Huadian","42.9633","126.7478","China","CN","CHN","Jilin","","455339","1156018839"
"Ţanţā","Tanta","30.7833","31.0000","Egypt","EG","EGY","Al Gharbīyah","admin","429503","1818501770"
"Kota Kinabalu","Kota Kinabalu","5.9750","116.0725","Malaysia","MY","MYS","Sabah","admin","452058","1458441859"
"Kursk","Kursk","51.7167","36.1833","Russia","RU","RUS","Kurskaya Oblast’","admin","450977","1643011077"
"Minzhu","Minzhu","43.7192","127.3370","China","CN","CHN","Jilin","minor","453265","1156295044"
"Stavropol","Stavropol","45.0500","41.9833","Russia","RU","RUS","Stavropol’skiy Kray","admin","450680","1643947228"
"Udaipur","Udaipur","24.5800","73.6800","India","IN","IND","Rājasthān","","451100","1356266094"
"Mogi das Cruzes","Mogi das Cruzes","-23.5228","-46.1878","Brazil","BR","BRA","São Paulo","","450785","1076857883"
"Mariupol","Mariupol","47.0958","37.5494","Ukraine","UA","UKR","Donetska Oblast","minor","449498","1804630021"
"Eslāmshahr","Eslamshahr","35.5606","51.2347","Iran","IR","IRN","Tehrān","minor","448129","1364870126"
"San Nicolás de los Garza","San Nicolas de los Garza","25.7500","-100.2833","Mexico","MX","MEX","Nuevo León","minor","443273","1484264138"
"Greenville","Greenville","34.8354","-82.3646","United States","US","USA","South Carolina","","450487","1840013501"
"Sham Shui Po","Sham Shui Po","22.3307","114.1622","Hong Kong","HK","HKG","","","405869","1344000058"
"Barcelona","Barcelona","10.1333","-64.6833","Venezuela","VE","VEN","Anzoátegui","admin","448016","1862418619"
"Constantine","Constantine","36.3650","6.6147","Algeria","DZ","DZA","Constantine","admin","448374","1012275076"
"Tanbei","Tanbei","35.0907","112.9317","China","CN","CHN","Henan","minor","447701","1156690688"
"Ado-Ekiti","Ado-Ekiti","7.6167","5.2167","Nigeria","NG","NGA","Ekiti","admin","446749","1566196391"
"Batman","Batman","37.8870","41.1320","Turkey","TR","TUR","Batman","admin","447106","1792630786"
"Bandung","Bandung","-6.9120","107.6097","Indonesia","ID","IDN","Jawa Barat","admin","432557","1360313023"
"Baisha","Baisha","29.4774","119.2853","China","CN","CHN","Zhejiang","","446000","1156988315"
"Avcılar","Avcilar","40.9792","28.7214","Turkey","TR","TUR","İstanbul","","435625","1792144759"
"Denton","Denton","33.2175","-97.1418","United States","US","USA","Texas","","445814","1840019390"
"Rodriguez","Rodriguez","14.7167","121.1167","Philippines","PH","PHL","Rizal","","443954","1608543530"
"Taoyuan District","Taoyuan District","24.9913","121.3143","Taiwan","TW","TWN","Taoyuan","admin","443273","1158127875"
"Yongji","Yongji","34.8670","110.4417","China","CN","CHN","Shanxi","","444724","1156001205"
"Chauddagram","Chauddagram","23.2283","91.3133","Bangladesh","BD","BGD","Chattogram","","443648","1050749168"
"Danjiangkou","Danjiangkou","32.5408","111.5098","China","CN","CHN","Hubei","","443755","1156347994"
"Kahramanmaraş","Kahramanmaras","37.5833","36.9333","Turkey","TR","TUR","Kahramanmaraş","admin","443575","1792726797"
"Fujisawa","Fujisawa","35.3500","139.4667","Japan","JP","JPN","Kanagawa","","436744","1392640771"
"Andijon","Andijon","40.7833","72.3333","Uzbekistan","UZ","UZB","Andijon","admin","441700","1860445095"
"Mathura","Mathura","27.4925","77.6736","India","IN","IND","Uttar Pradesh","","441894","1356066081"
"Zürich","Zurich","47.3744","8.5411","Switzerland","CH","CHE","Zürich","admin","436332","1756539143"
"Diadema","Diadema","-23.6861","-46.6106","Brazil","BR","BRA","São Paulo","","426757","1076867279"
"Machida","Machida","35.7106","139.4386","Japan","JP","JPN","Tōkyō","","434414","1392788615"
"Ulan-Ude","Ulan-Ude","51.8272","107.6064","Russia","RU","RUS","Buryatiya","admin","439128","1643774900"
"Spokane","Spokane","47.6671","-117.4330","United States","US","USA","Washington","","440270","1840021093"
"Ning’an","Ning'an","44.3439","129.4656","China","CN","CHN","Heilongjiang","minor","440000","1156629205"
"Zhangjiakou Shi Xuanhua Qu","Zhangjiakou Shi Xuanhua Qu","40.5944","115.0243","China","CN","CHN","Hebei","minor","409745","1156240430"
"Sunch’ŏn","Sunch'on","39.4167","125.9333","North Korea","KP","PRK","P’yŏngnam","","437000","1408510801"
"Jinchang","Jinchang","38.5168","102.1866","China","CN","CHN","Gansu","minor","438026","1156184861"
"Kashiwa","Kashiwa","35.8676","139.9758","Japan","JP","JPN","Chiba","","433941","1392133166"
"Guangming","Guangming","45.3357","122.7765","China","CN","CHN","Jilin","minor","437559","1156955625"
"Sāngli","Sangli","16.8667","74.5667","India","IN","IND","Mahārāshtra","","436639","1356921375"
"Oakland","Oakland","37.7904","-122.2166","United States","US","USA","California","","437548","1840020296"
"Beira","Beira","-19.8333","34.8500","Mozambique","MZ","MOZ","Sofala","admin","436240","1508407369"
"Kupang","Kupang","-10.1702","123.6077","Indonesia","ID","IDN","Nusa Tenggara Timur","admin","433970","1360696757"
"Jeju","Jeju","33.5097","126.5219","South Korea","KR","KOR","Jeju","admin","435413","1410364424"
"Davangere","Davangere","14.4666","75.9242","India","IN","IND","Karnātaka","","435125","1356445061"
"Caxias do Sul","Caxias do Sul","-29.1667","-51.1833","Brazil","BR","BRA","Rio Grande do Sul","","435564","1076809957"
"Manizales","Manizales","5.1000","-75.5500","Colombia","CO","COL","Caldas","admin","434403","1170930654"
"Yogyakarta","Yogyakarta","-7.8014","110.3644","Indonesia","ID","IDN","Yogyakarta","admin","422732","1360384833"
"Calicut","Calicut","11.2500","75.7700","India","IN","IND","Kerala","minor","431560","1356010401"
"Koumassi","Koumassi","5.3000","-3.9500","Côte d'Ivoire","CI","CIV","Abidjan","","412282","1384863297"
"Veracruz","Veracruz","19.1903","-96.1533","Mexico","MX","MEX","Veracruz","minor","428323","1484992153"
"Zanjān","Zanjan","36.6789","48.5056","Iran","IR","IRN","Zanjān","admin","433475","1364312712"
"Welkom","Welkom","-27.9831","26.7208","South Africa","ZA","ZAF","Free State","","431944","1710480365"
"Shinagawa-ku","Shinagawa-ku","35.6092","139.7302","Japan","JP","JPN","Tōkyō","","415729","1392003304"
"Kenitra","Kenitra","34.2500","-6.5833","Morocco","MA","MAR","Rabat-Salé-Kénitra","","431282","1504534876"
"Agadir","Agadir","30.4333","-9.6000","Morocco","MA","MAR","Souss-Massa","admin","421844","1504116858"
"Somolu","Somolu","6.5408","3.3872","Nigeria","NG","NGA","Lagos","minor","403569","1566609760"
"Uyo","Uyo","5.0500","7.9333","Nigeria","NG","NGA","Akwa Ibom","admin","429900","1566061694"
"Sokoto","Sokoto","13.0622","5.2339","Nigeria","NG","NGA","Sokoto","admin","427760","1566574518"
"Lancaster","Lancaster","40.0420","-76.3012","United States","US","USA","Pennsylvania","","430621","1840003718"
"Masan","Masan","35.1833","128.5500","South Korea","KR","KOR","Gyeongnam","","429612","1410394650"
"Huili Chengguanzhen","Huili Chengguanzhen","26.6614","102.2469","China","CN","CHN","Sichuan","minor","430066","1156149320"
"Sidi Bouzid","Sidi Bouzid","35.0381","9.4858","Tunisia","TN","TUN","Sidi Bouzid","admin","429912","1788196439"
"Cusco","Cusco","-13.5250","-71.9722","Peru","PE","PER","Cusco","admin","428450","1604162901"
"Porto Velho","Porto Velho","-8.7619","-63.9039","Brazil","BR","BRA","Rondônia","admin","428527","1076045591"
"Sihŭng","Sihung","37.3799","126.8029","South Korea","KR","KOR","Gyeonggi","","425184","1410313432"
"Xalapa","Xalapa","19.5400","-96.9275","Mexico","MX","MEX","Veracruz","admin","424755","1484334948"
"Florencio Varela","Florencio Varela","-34.8167","-58.2833","Argentina","AR","ARG","Buenos Aires","","426005","1032730758"
"Tver","Tver","56.8578","35.9219","Russia","RU","RUS","Tverskaya Oblast’","admin","424969","1643456559"
"Trabzon","Trabzon","41.0050","39.7225","Turkey","TR","TUR","Trabzon","admin","426882","1792019202"
"Malatya","Malatya","38.3486","38.3194","Turkey","TR","TUR","Malatya","admin","426381","1792485155"
"Xunyang","Xunyang","32.8357","109.3590","China","CN","CHN","Shaanxi","","426677","1156656651"
"Uijeongbu","Uijeongbu","37.7486","127.0389","South Korea","KR","KOR","Gyeonggi","","421579","1410888678"
"Akola","Akola","20.7000","77.0000","India","IN","IND","Mahārāshtra","","425817","1356089496"
"Aomori","Aomori","40.8223","140.7307","Japan","JP","JPN","Aomori","admin","425962","1392083898"
"Yan’an Beilu","Yan'an Beilu","44.0144","87.2674","China","CN","CHN","Xinjiang","minor","426253","1156048097"
"Owo","Owo","7.1961","5.5864","Nigeria","NG","NGA","Ondo","minor","425700","1566833788"
"Aves","Aves","41.4400","-8.3000","Portugal","PT","PRT","Porto","","425411","1620507201"
"Buenaventura","Buenaventura","3.8772","-77.0267","Colombia","CO","COL","Valle del Cauca","minor","423927","1170287988"
"Poughkeepsie","Poughkeepsie","41.6950","-73.9211","United States","US","USA","New York","","423386","1840000500"
"Laval","Laval","45.5833","-73.7500","Canada","CA","CAN","Quebec","","422993","1124922301"
"Boise","Boise","43.6005","-116.2308","United States","US","USA","Idaho","admin","422665","1840027142"
"Toyota","Toyota","35.0824","137.1563","Japan","JP","JPN","Aichi","","422106","1392498272"
"Santa Rosa","Santa Rosa","14.3167","121.1167","Philippines","PH","PHL","Laguna","","414812","1608571683"
"Daloa","Daloa","6.8833","-6.4500","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","admin","421871","1384564974"
"Elazığ","Elazig","38.6744","39.2228","Turkey","TR","TUR","Elazığ","admin","421726","1792156766"
"Hpa-An","Hpa-An","16.8906","97.6333","Myanmar","MM","MMR","Kayin State","admin","421525","1104494124"
"Rahimyar Khan","Rahimyar Khan","28.4200","70.3000","Pakistan","PK","PAK","Punjab","","420419","1586136399"
"Bettiah","Bettiah","26.8014","84.5028","India","IN","IND","Bihār","","414453","1356590604"
"Ugep","Ugep","5.8000","8.0833","Nigeria","NG","NGA","Cross River","minor","420000","1566000091"
"Winston-Salem","Winston-Salem","36.1029","-80.2610","United States","US","USA","North Carolina","","420400","1840015324"
"Kushtia","Kushtia","23.9101","89.1231","Bangladesh","BD","BGD","Khulna","","418312","1050000610"
"Mishan","Mishan","45.5420","131.8666","China","CN","CHN","Heilongjiang","minor","420000","1156267144"
"Hailin","Hailin","44.5735","129.3825","China","CN","CHN","Heilongjiang","minor","420000","1156267191"
"Seremban","Seremban","2.7222","101.9417","Malaysia","MY","MYS","Negeri Sembilan","admin","419536","1458051507"
"Boa Vista","Boa Vista","2.8194","-60.6714","Brazil","BR","BRA","Roraima","admin","419652","1076621300"
"Lecheng","Lecheng","25.1307","113.3472","China","CN","CHN","Guangdong","minor","419500","1156612198"
"Zhengjiatun","Zhengjiatun","43.5130","123.5003","China","CN","CHN","Jilin","minor","419529","1156759747"
"Luhansk","Luhansk","48.5667","39.3333","Ukraine","UA","UKR","Luhanska Oblast","admin","417990","1804688214"
"Pencheng","Pencheng","29.6792","115.6611","China","CN","CHN","Jiangxi","","419047","1156147187"
"Magnitogorsk","Magnitogorsk","53.3833","59.0333","Russia","RU","RUS","Chelyabinskaya Oblast’","","418241","1643987830"
"Thủ Dầu Một","Thu Dau Mot","10.9667","106.6500","Vietnam","VN","VNM","Bình Dương","admin","417000","1704890399"
"Takamatsu","Takamatsu","34.3500","134.0500","Japan","JP","JPN","Kagawa","admin","417814","1392427161"
"Syracuse","Syracuse","43.0407","-76.1437","United States","US","USA","New York","","418875","1840000378"
"El Obeid","El Obeid","13.1833","30.2167","Sudan","SD","SDN","North Kordofan","admin","418280","1729215531"
"Da’an","Da'an","45.5050","124.2863","China","CN","CHN","Jilin","minor","418232","1156577983"
"Xingren","Xingren","25.4352","105.1907","China","CN","CHN","Guizhou","","417919","1156533633"
"Biñan","Binan","14.3333","121.0833","Philippines","PH","PHL","Laguna","","407437","1608039268"
"Arusha","Arusha","-3.3667","36.6833","Tanzania","TZ","TZA","Arusha","admin","416442","1834484937"
"Fenyang","Fenyang","37.2647","111.7775","China","CN","CHN","Shanxi","","416212","1156455704"
"Ajdābiyā","Ajdabiya","30.7556","20.2253","Libya","LY","LBY","Al Wāḩāt","admin","416000","1434401280"
"Paju","Paju","37.8667","126.8000","South Korea","KR","KOR","Gyeonggi","minor","415345","1410365324"
"Mataram","Mataram","-8.5833","116.1167","Indonesia","ID","IDN","Nusa Tenggara Barat","admin","408900","1360021952"
"Carapicuíba","Carapicuiba","-23.5192","-46.8358","Brazil","BR","BRA","São Paulo","","403183","1076874103"
"Jhang City","Jhang City","31.2694","72.3161","Pakistan","PK","PAK","Punjab","minor","414131","1586288677"
"Tepic","Tepic","21.5083","-104.8931","Mexico","MX","MEX","Nayarit","admin","413609","1484433877"
"Jayapura","Jayapura","-2.5330","140.7170","Indonesia","ID","IDN","Papua","admin","413283","1360517692"
"Rio Branco","Rio Branco","-9.9747","-67.8100","Brazil","BR","BRA","Acre","admin","413418","1076391263"
"Toyama","Toyama","36.6959","137.2137","Japan","JP","JPN","Toyama","admin","413028","1392000560"
"Fangting","Fangting","31.1282","104.1695","China","CN","CHN","Sichuan","minor","412758","1156384608"
"Sanandaj","Sanandaj","35.3144","46.9922","Iran","IR","IRN","Kordestān","admin","412767","1364178783"
"Delhi Cantonment","Delhi Cantonment","28.6000","77.1333","India","IN","IND","Delhi","","405633","1356526853"
"Linghai","Linghai","41.1676","121.3558","China","CN","CHN","Liaoning","","412513","1156081300"
"Shorāpur","Shorapur","16.5200","76.7600","India","IN","IND","Karnātaka","","412291","1356512373"
"Toyonaka","Toyonaka","34.7833","135.4667","Japan","JP","JPN","Ōsaka","","401233","1392003256"
"Sochi","Sochi","43.5853","39.7203","Russia","RU","RUS","Krasnodarskiy Kray","","411524","1643009782"
"Bhāgalpur","Bhagalpur","25.2500","87.0000","India","IN","IND","Bihār","","410210","1356760990"
"Bellary","Bellary","15.1000","76.9167","India","IN","IND","Karnātaka","","410445","1356209152"
"Ivanovo","Ivanovo","57.0000","41.0000","Russia","RU","RUS","Ivanovskaya Oblast’","admin","406933","1643297945"
"Kisumu","Kisumu","-0.0833","34.7667","Kenya","KE","KEN","Kisumu","admin","409928","1404511920"
"Augusta","Augusta","33.3645","-82.0708","United States","US","USA","Georgia","","409668","1840029462"
"Türkmenabat","Turkmenabat","39.0833","63.5667","Turkmenistan","TM","TKM","Lebap","admin","408906","1795079064"
"Zaria","Zaria","11.0667","7.7000","Nigeria","NG","NGA","Kaduna","minor","408198","1566287704"
"Bryansk","Bryansk","53.2500","34.3667","Russia","RU","RUS","Bryanskaya Oblast’","admin","406553","1643208827"
"Kumi","Kumi","36.1195","128.3444","South Korea","KR","KOR","Gyeongbuk","","407880","1410638233"
"Asyūţ","Asyut","27.1833","31.1667","Egypt","EG","EGY","Asyūţ","admin","389307","1818456592"
"Wanyuan","Wanyuan","32.0691","108.0351","China","CN","CHN","Sichuan","minor","407594","1156571429"
"Maracay","Maracay","10.2469","-67.5958","Venezuela","VE","VEN","Aragua","admin","407109","1862284350"
"Padiāla","Padiala","30.5667","76.6333","India","IN","IND","Punjab","","406192","1356667176"
"Yüreğir","Yuregir","36.9981","35.3439","Turkey","TR","TUR","Adana","minor","407054","1792234391"
"Jiexiu","Jiexiu","37.0282","111.9103","China","CN","CHN","Shanxi","","406517","1156275703"
"Stockton","Stockton","37.9765","-121.3109","United States","US","USA","California","","406822","1840021517"
"Bogra","Bogra","24.8500","89.3667","Bangladesh","BD","BGD","Rājshāhi","","400983","1050833664"
"Santa Fe","Santa Fe","-31.6333","-60.7000","Argentina","AR","ARG","Santa Fe","admin","405683","1032817575"
"Campina Grande","Campina Grande","-7.2306","-35.8811","Brazil","BR","BRA","Paraíba","","405072","1076218697"
"Nagasaki","Nagasaki","32.7447","129.8736","Japan","JP","JPN","Nagasaki","admin","404656","1392162566"
"Szczecin","Szczecin","53.4325","14.5481","Poland","PL","POL","Zachodniopomorskie","admin","403833","1616256546"
"Maringá","Maringa","-23.4000","-51.9167","Brazil","BR","BRA","Paraná","","403063","1076013378"
"Palm Coast","Palm Coast","29.5389","-81.2457","United States","US","USA","Florida","","403834","1840015064"
"Chattanooga","Chattanooga","35.0660","-85.2481","United States","US","USA","Tennessee","","403233","1840014569"
"Qazvīn","Qazvin","36.2667","50.0000","Iran","IR","IRN","Qazvīn","admin","402748","1364073420"
"Quilon","Quilon","8.8800","76.6000","India","IN","IND","Kerala","","397419","1356304110"
"Jundiaí","Jundiai","-23.1858","-46.8839","Brazil","BR","BRA","São Paulo","","401896","1076998392"
"Hirakata","Hirakata","34.8167","135.6500","Japan","JP","JPN","Ōsaka","","396694","1392999987"
"Gifu","Gifu","35.4232","136.7608","Japan","JP","JPN","Gifu","admin","400118","1392097787"
"Khayelitsha","Khayelitsha","-34.0403","18.6778","South Africa","ZA","ZAF","Western Cape","","391749","1710736316"
"Kūkatpalli","Kukatpalli","17.4849","78.4138","India","IN","IND","Andhra Pradesh","","385821","1356553747"
"Jiangjiafan","Jiangjiafan","31.0238","111.9709","China","CN","CHN","Hubei","","400000","1156352568"
"Maungdaw","Maungdaw","20.8167","92.3667","Myanmar","MM","MMR","Rakhine State","","400000","1104000431"
"Kissimmee","Kissimmee","28.3041","-81.4169","United States","US","USA","Florida","","399929","1840015109"
"Durham","Durham","35.9792","-78.9022","United States","US","USA","North Carolina","","399762","1840013364"
"Dera Ghazi Khan","Dera Ghazi Khan","30.0331","70.6400","Pakistan","PK","PAK","Punjab","minor","399064","1586002238"
"Miyazaki","Miyazaki","31.9167","131.4167","Japan","JP","JPN","Miyazaki","admin","397476","1392216224"
"Bhātpāra","Bhatpara","22.8700","88.4100","India","IN","IND","West Bengal","","386019","1356022456"
"Hazāribāgh","Hazaribagh","23.9800","85.3500","India","IN","IND","Jhārkhand","","394532","1356960055"
"Sandakan","Sandakan","5.8388","118.1173","Malaysia","MY","MYS","Sabah","","396290","1458405180"
"Taytay","Taytay","14.5692","121.1325","Philippines","PH","PHL","Rizal","","386451","1608530771"
"Hejin","Hejin","35.5914","110.7060","China","CN","CHN","Shanxi","","395527","1156225234"
"Olinda","Olinda","-8.0000","-34.8833","Brazil","BR","BRA","Pernambuco","","393115","1076784718"
"Thanh Hóa","Thanh Hoa","19.8075","105.7764","Vietnam","VN","VNM","Thanh Hóa","admin","393294","1704016079"
"Surgut","Surgut","61.2500","73.4333","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","395900","1643007475"
"Nnewi","Nnewi","6.0167","6.9167","Nigeria","NG","NGA","Anambra","minor","391227","1566097410"
"Minamisuita","Minamisuita","34.7594","135.5169","Japan","JP","JPN","Ōsaka","","384953","1392948850"
"Thiès","Thies","14.7833","-16.9167","Senegal","SN","SEN","Thiès","admin","394544","1686527948"
"Betsiboka","Betsiboka","-16.9500","46.8300","Madagascar","MG","MDG","Mahajanga","","394561","1450524066"
"Belgorod","Belgorod","50.6000","36.6000","Russia","RU","RUS","Belgorodskaya Oblast’","admin","391702","1643407900"
"Tāngāil","Tangail","24.2644","89.9181","Bangladesh","BD","BGD","Dhaka","","392300","1050628940"
"Yokosuka","Yokosuka","35.2500","139.6667","Japan","JP","JPN","Kanagawa","","390275","1392003389"
"Nha Trang","Nha Trang","12.2450","109.1917","Vietnam","VN","VNM","Khánh Hòa","admin","392279","1704497901"
"Malabon","Malabon","14.6600","120.9600","Philippines","PH","PHL","Malabon","admin","380522","1608045841"
"Al Jahrā’","Al Jahra'","29.3500","47.6833","Kuwait","KW","KWT","Al Jahrā’","","393432","1414182001"
"Yola","Yola","9.2300","12.4600","Nigeria","NG","NGA","Adamawa","admin","392854","1566782065"
"Pasto","Pasto","1.2078","-77.2772","Colombia","CO","COL","Nariño","admin","392930","1170320545"
"Lobito","Lobito","-12.3597","13.5308","Angola","AO","AGO","Benguela","","393079","1024128946"
"Al Jubayl","Al Jubayl","27.0000","49.6544","Saudi Arabia","SA","SAU","Ash Sharqīyah","","392948","1682564079"
"Saurimo","Saurimo","-9.6500","20.4000","Angola","AO","AGO","Lunda-Sul","admin","393000","1024326839"
"Bologna","Bologna","44.4939","11.3428","Italy","IT","ITA","Emilia-Romagna","admin","392564","1380202039"
"Arlington","Arlington","32.6998","-97.1250","United States","US","USA","Texas","","392304","1840019422"
"Gujrat","Gujrat","32.5739","74.0789","Pakistan","PK","PAK","Punjab","minor","390533","1586006990"
"Ad Dīwānīyah","Ad Diwaniyah","31.9892","44.9247","Iraq","IQ","IRQ","Al Qādisīyah","admin","391600","1368864006"
"Piracicaba","Piracicaba","-22.7253","-47.6492","Brazil","BR","BRA","São Paulo","","391449","1076166252"
"Hancheng","Hancheng","35.4768","110.4419","China","CN","CHN","Shaanxi","","391164","1156265838"
"Karamay","Karamay","45.5799","84.8892","China","CN","CHN","Xinjiang","minor","391008","1156892128"
"Kākināda","Kakinada","16.9661","82.2550","India","IN","IND","Andhra Pradesh","","384182","1356573609"
"San-Pédro","San-Pedro","4.7500","-6.6333","Côte d'Ivoire","CI","CIV","Bas-Sassandra","admin","390654","1384436547"
"Tieli","Tieli","46.9838","128.0400","China","CN","CHN","Heilongjiang","minor","390000","1156769379"
"Cilegon","Cilegon","-6.0027","106.0112","Indonesia","ID","IDN","Banten","","387543","1360643410"
"Darbhanga","Darbhanga","26.1700","85.9000","India","IN","IND","Bihār","","380125","1356846195"
"Victorville","Victorville","34.5277","-117.3536","United States","US","USA","California","","389060","1840021731"
"Gwoza","Gwoza","11.0861","13.6914","Nigeria","NG","NGA","Borno","minor","388600","1566834535"
"Saki","Saki","8.6667","3.4000","Nigeria","NG","NGA","Oyo","minor","388225","1566404680"
"Tétouan","Tetouan","35.5667","-5.3667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","380787","1504709141"
"Aqtöbe","Aqtobe","50.2836","57.2297","Kazakhstan","KZ","KAZ","","admin","387807","1398602716"
"Bibā","Biba","28.9218","30.9844","Egypt","EG","EGY","Banī Suwayf","","385528","1818994454"
"Plovdiv","Plovdiv","42.1500","24.7500","Bulgaria","BG","BGR","Plovdiv","admin","383540","1100436706"
"Oyo","Oyo","7.8500","3.9330","Nigeria","NG","NGA","Oyo","minor","386723","1566930579"
"Ilesa","Ilesa","7.6167","4.7167","Nigeria","NG","NGA","Osun","minor","384334","1566937414"
"Tarlac City","Tarlac City","15.4869","120.5900","Philippines","PH","PHL","Tarlac","admin","385398","1608237027"
"Okazaki","Okazaki","34.9543","137.1744","Japan","JP","JPN","Aichi","","385376","1392862345"
"Sirūr","Sirur","18.8300","74.3800","India","IN","IND","Mahārāshtra","","385414","1356926718"
"Cainta","Cainta","14.5667","121.1167","Philippines","PH","PHL","Rizal","","376933","1608314665"
"Ḩā’il","Ha'il","27.5167","41.6833","Saudi Arabia","SA","SAU","Ḩā’il","admin","385257","1682249750"
"Garoowe","Garoowe","8.4000","48.4667","Somalia","SO","SOM","Nugaal","admin","385000","1706512367"
"Olomouc","Olomouc","49.5939","17.2508","Czechia","CZ","CZE","Olomoucký Kraj","admin","384000","1203328061"
"Yidu","Yidu","30.3880","111.4505","China","CN","CHN","Hubei","","384598","1156230664"
"Lianzhou","Lianzhou","24.7868","112.3735","China","CN","CHN","Guangdong","minor","384700","1156020454"
"Ceel Baraf","Ceel Baraf","3.2073","45.7587","Somalia","SO","SOM","Shabeellaha Dhexe","","384630","1706959191"
"Florence","Florence","43.7714","11.2542","Italy","IT","ITA","Tuscany","admin","380948","1380726561"
"Christchurch","Christchurch","-43.5310","172.6365","New Zealand","NZ","NZL","Canterbury","admin","383200","1554377228"
"Nuevo Laredo","Nuevo Laredo","27.4861","-99.5069","Mexico","MX","MEX","Tamaulipas","minor","384033","1484597234"
"Brno","Brno","49.1925","16.6083","Czechia","CZ","CZE","Jihomoravský Kraj","admin","382405","1203676514"
"Bawshar","Bawshar","23.5333","58.3833","Oman","OM","OMN","Masqaţ","","383257","1512506161"
"London","London","42.9836","-81.2497","Canada","CA","CAN","Ontario","","383822","1124469960"
"Novi Sad","Novi Sad","45.2542","19.8425","Serbia","RS","SRB","Novi Sad","admin","380000","1688169087"
"Aurora","Aurora","39.7083","-104.7237","United States","US","USA","Colorado","","383496","1840018794"
"Gusau","Gusau","12.1500","6.6667","Nigeria","NG","NGA","Zamfara","admin","383162","1566383682"
"Modesto","Modesto","37.6375","-121.0028","United States","US","USA","California","","382994","1840020287"
"Kaunas","Kaunas","54.8972","23.8861","Lithuania","LT","LTU","Kauno Miestas","admin","381007","1440302852"
"Sardārshahr","Sardarshahr","28.4401","74.4908","India","IN","IND","Rājasthān","","382474","1356932502"
"Zêtang","Zetang","29.2380","91.7710","China","CN","CHN","Tibet","","382610","1156561299"
"Las Palmas","Las Palmas","28.1272","-15.4314","Spain","ES","ESP","Canary Islands","admin","378797","1724501748"
"Ichinomiya","Ichinomiya","35.3039","136.8031","Japan","JP","JPN","Aichi","","378681","1392379530"
"Taunggyi","Taunggyi","20.7836","97.0354","Myanmar","MM","MMR","Shan State","admin","381639","1104364374"
"Lancaster","Lancaster","34.6935","-118.1753","United States","US","USA","California","","381732","1840020516"
"Fayetteville","Fayetteville","36.0714","-94.1661","United States","US","USA","Arkansas","","381636","1840013368"
"Cantonment","Cantonment","31.5167","74.3833","Pakistan","PK","PAK","Punjab","","374872","1586338974"
"Pānihāti","Panihati","22.6900","88.3700","India","IN","IND","West Bengal","","377347","1356118632"
"Huancayo","Huancayo","-12.0667","-75.2167","Peru","PE","PER","Junín","admin","378203","1604249985"
"Betim","Betim","-19.9678","-44.1978","Brazil","BR","BRA","Minas Gerais","","378089","1076953384"
"Usulután","Usulutan","13.3500","-88.4500","El Salvador","SV","SLV","Usulután","admin","378606","1222984157"
"Sintra","Sintra","38.7992","-9.3883","Portugal","PT","PRT","Lisboa","minor","377835","1620848791"
"Chitungwiza","Chitungwiza","-17.9939","31.0481","Zimbabwe","ZW","ZWE","Harare","","371244","1716688236"
"Hatay","Hatay","36.2025","36.1606","Turkey","TR","TUR","Hatay","admin","377793","1792115763"
"Iquitos","Iquitos","-3.7500","-73.2500","Peru","PE","PER","Loreto","admin","377609","1604493809"
"Sivas","Sivas","39.7500","37.0167","Turkey","TR","TUR","Sivas","admin","377561","1792938326"
"Helixi","Helixi","30.6267","118.9861","China","CN","CHN","Anhui","minor","376857","1156734041"
"Scranton","Scranton","41.4044","-75.6649","United States","US","USA","Pennsylvania","","376974","1840003389"
"Mamou","Mamou","10.3833","-12.0833","Guinea","GN","GIN","Mamou","admin","376269","1324990909"
"Al Kharj","Al Kharj","24.1483","47.3050","Saudi Arabia","SA","SAU","Ar Riyāḑ","","376325","1682947408"
"Manukau City","Manukau City","-37.0000","174.8850","New Zealand","NZ","NZL","Auckland","","375600","1554797148"
"Stoke-on-Trent","Stoke-on-Trent","53.0000","-2.1833","United Kingdom","GB","GBR","Stoke-on-Trent","","372775","1826799578"
"Cumaná","Cumana","10.4500","-64.1667","Venezuela","VE","VEN","Sucre","admin","374706","1862583279"
"Rohtak","Rohtak","28.8909","76.5796","India","IN","IND","Haryāna","","374292","1356162915"
"Vinnytsia","Vinnytsia","49.2333","28.4833","Ukraine","UA","UKR","Vinnytska Oblast","admin","371855","1804658571"
"Māler Kotla","Maler Kotla","30.5167","75.8833","India","IN","IND","Punjab","","374000","1356847218"
"Lipa City","Lipa City","13.9411","121.1622","Philippines","PH","PHL","Batangas","","372931","1608615113"
"Mandaue City","Mandaue City","10.3333","123.9333","Philippines","PH","PHL","Mandaue","admin","364116","1608794590"
"Bhawana","Bhawana","31.5661","72.6461","Pakistan","PK","PAK","Punjab","","373841","1586746564"
"Khorramābād","Khorramabad","33.4878","48.3558","Iran","IR","IRN","Lorestān","admin","373416","1364548806"
"Ambon","Ambon","-3.7000","128.1667","Indonesia","ID","IDN","Maluku","admin","372249","1360756802"
"Takasaki","Takasaki","36.3219","139.0033","Japan","JP","JPN","Gunma","","372639","1392000529"
"Butuan","Butuan","8.9534","125.5288","Philippines","PH","PHL","Butuan","admin","372910","1608000722"
"Toyohashi","Toyohashi","34.7692","137.3915","Japan","JP","JPN","Aichi","","371507","1392482960"
"Keelung","Keelung","25.1333","121.7333","Taiwan","TW","TWN","Keelung","admin","370155","1158820647"
"Baguio City","Baguio City","16.4119","120.5933","Philippines","PH","PHL","Baguio","admin","366358","1608164766"
"La Florida","La Florida","-33.5333","-70.5833","Chile","CL","CHL","Región Metropolitana","","366916","1152939649"
"Gebze","Gebze","40.8000","29.4333","Turkey","TR","TUR","Kocaeli","minor","371000","1792650082"
"Lengshuijiang","Lengshuijiang","27.6858","111.4279","China","CN","CHN","Hunan","","370300","1156002852"
"Petare","Petare","10.4833","-66.8167","Venezuela","VE","VEN","Miranda","minor","369000","1862595613"
"Anguo","Anguo","38.4177","115.3204","China","CN","CHN","Hebei","","370317","1156172518"
"Kita-ku","Kita-ku","35.7528","139.7336","Japan","JP","JPN","Tōkyō","","353058","1392003263"
"Madan","Madan","30.3392","48.3042","Iran","IR","IRN","Khūzestān","","370180","1364708556"
"Panshi","Panshi","42.9392","126.0561","China","CN","CHN","Jilin","","370238","1156659612"
"Bharatpur","Bharatpur","27.6833","84.4333","Nepal","NP","NPL","","","369377","1524780273"
"Az Zubayr","Az Zubayr","30.3833","47.7000","Iraq","IQ","IRQ","Al Başrah","minor","370000","1368147923"
"Caucaia","Caucaia","-3.7167","-38.6500","Brazil","BR","BRA","Ceará","","368918","1076870058"
"Vitsyebsk","Vitsyebsk","55.1917","30.2056","Belarus","BY","BLR","Vitsyebskaya Voblasts’","admin","366299","1112480679"
"Shinjuku","Shinjuku","35.6939","139.7034","Japan","JP","JPN","Tōkyō","","349385","1392536374"
"Nicolás Romero","Nicolas Romero","19.5833","-99.3667","Mexico","MX","MEX","México","minor","366602","1484589606"
"Huichang","Huichang","34.9136","112.7852","China","CN","CHN","Henan","minor","367113","1156035769"
"Nagano","Nagano","36.6486","138.1947","Japan","JP","JPN","Nagano","admin","367184","1392603439"
"Bauru","Bauru","-22.3150","-49.0608","Brazil","BR","BRA","São Paulo","","366992","1076905338"
"Yanjiao","Yanjiao","39.9432","116.8036","China","CN","CHN","Hebei","","363809","1156470847"
"Bochum","Bochum","51.4819","7.2158","Germany","DE","DEU","North Rhine-Westphalia","minor","364454","1276227288"
"Tecámac","Tecamac","19.7131","-98.9683","Mexico","MX","MEX","México","minor","364579","1484363321"
"Anápolis","Anapolis","-16.3339","-48.9519","Brazil","BR","BRA","Goiás","","366491","1076208869"
"Coventry","Coventry","52.4081","-1.5106","United Kingdom","GB","GBR","Coventry","","362690","1826979198"
"Oxnard","Oxnard","34.1964","-119.1815","United States","US","USA","California","","366330","1840020474"
"Korba","Korba","22.3500","82.6800","India","IN","IND","Chhattīsgarh","","365253","1356093877"
"Cibinong","Cibinong","-6.4850","106.8420","Indonesia","ID","IDN","Jawa Barat","minor","363424","1360730847"
"Dāsarhalli","Dasarhalli","13.0465","77.5130","India","IN","IND","Karnātaka","","349720","1356103194"
"Qardho","Qardho","9.5000","49.0833","Somalia","SO","SOM","Bari","","365000","1706405003"
"Wŏnsan","Wonsan","39.1475","127.4461","North Korea","KP","PRK","Kangwŏn","admin","363127","1408692125"
"Kocaeli","Kocaeli","40.7625","29.9175","Turkey","TR","TUR","Kocaeli","admin","363416","1792965114"
"Newcastle","Newcastle","-27.7464","29.9328","South Africa","ZA","ZAF","KwaZulu-Natal","","363236","1710031016"
"Iligan","Iligan","8.2300","124.2500","Philippines","PH","PHL","Iligan","admin","363115","1608000268"
"Youngstown","Youngstown","41.0993","-80.6463","United States","US","USA","Ohio","","363541","1840003563"
"Cabuyao","Cabuyao","14.2750","121.1250","Philippines","PH","PHL","Laguna","","355330","1608604441"
"Kayapınar","Kayapinar","37.9400","40.1900","Turkey","TR","TUR","Diyarbakır","minor","362407","1792910994"
"Nakano","Nakano","35.7073","139.6637","Japan","JP","JPN","Tōkyō","","341014","1392891845"
"Utrecht","Utrecht","52.0833","5.1167","Netherlands","NL","NLD","Utrecht","admin","359370","1528871692"
"Bengkulu","Bengkulu","-3.7956","102.2592","Indonesia","ID","IDN","Bengkulu","admin","360495","1360191448"
"Orizaba","Orizaba","18.8500","-97.1000","Mexico","MX","MEX","Veracruz","minor","355333","1484766767"
"Blumenau","Blumenau","-26.9333","-49.0500","Brazil","BR","BRA","Santa Catarina","","361855","1076618665"
"Montes Claros","Montes Claros","-16.7306","-43.8639","Brazil","BR","BRA","Minas Gerais","","361915","1076113275"
"Indio","Indio","33.7346","-116.2346","United States","US","USA","California","","361831","1840019304"
"Pétion-Ville","Petion-Ville","18.5128","-72.2864","Haiti","HT","HTI","Ouest","","359615","1332051873"
"Tungi","Tungi","23.9000","90.4100","Bangladesh","BD","BGD","Dhaka","","350000","1050143008"
"Buurhakaba","Buurhakaba","2.7833","44.0833","Somalia","SO","SOM","Bay","minor","360795","1706176225"
"Shuanghe","Shuanghe","30.3866","106.7756","China","CN","CHN","Sichuan","minor","360000","1156573894"
"Konak","Konak","38.4189","27.1285","Turkey","TR","TUR","İzmir","minor","356563","1792668647"
"Ash Shuhadā’","Ash Shuhada'","30.6039","30.9021","Egypt","EG","EGY","Al Minūfīyah","","358486","1818906012"
"Pensacola","Pensacola","30.4413","-87.1911","United States","US","USA","Florida","","360711","1840015005"
"Umuahia","Umuahia","5.5333","7.4833","Nigeria","NG","NGA","Abia","admin","359230","1566217980"
"Gedaref","Gedaref","14.0333","35.3833","Sudan","SD","SDN","Gedaref","admin","354927","1729215057"
"Palu","Palu","-0.8950","119.8594","Indonesia","ID","IDN","Sulawesi Tengah","admin","359350","1360518272"
"Mardan","Mardan","34.2012","72.0258","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","358604","1586001546"
"Pokhara","Pokhara","28.2083","83.9889","Nepal","NP","NPL","Gaṇḍakī","admin","353841","1524416084"
"Mahilyow","Mahilyow","53.9167","30.3500","Belarus","BY","BLR","Mahilyowskaya Voblasts’","admin","357100","1112518993"
"Wudalianchi","Wudalianchi","48.6433","126.1408","China","CN","CHN","Heilongjiang","minor","360000","1156059331"
"Hrodna","Hrodna","53.6667","23.8333","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","admin","357493","1112187197"
"Sungai Petani","Sungai Petani","5.6500","100.4800","Malaysia","MY","MYS","Kedah","","358499","1458639918"
"Linxia Chengguanzhen","Linxia Chengguanzhen","35.6000","103.2167","China","CN","CHN","Gansu","minor","355968","1156043522"
"Sīkar","Sikar","27.6200","75.1500","India","IN","IND","Rājasthān","","359293","1356163729"
"Nam Định","Nam Dinh","20.4200","106.1683","Vietnam","VN","VNM","Nam Định","admin","352108","1704906277"
"Vitória","Vitoria","-20.2889","-40.3083","Brazil","BR","BRA","Espírito Santo","admin","355875","1076376829"
"Sasarām","Sasaram","24.9500","84.0300","India","IN","IND","Bihār","","358283","1356061200"
"Karūr","Karur","10.9601","78.0766","India","IN","IND","Tamil Nādu","","358468","1356837900"
"Franca","Franca","-20.5389","-47.4008","Brazil","BR","BRA","São Paulo","","358539","1076740256"
"Vladimir","Vladimir","56.1286","40.4058","Russia","RU","RUS","Vladimirskaya Oblast’","admin","356168","1643712167"
"Karnāl","Karnal","29.6860","76.9890","India","IN","IND","Haryāna","","357334","1356145204"
"Kismaayo","Kismaayo","-0.3581","42.5453","Somalia","SO","SOM","Jubbada Hoose","admin","358000","1706091701"
"São Vicente","Sao Vicente","-23.9633","-46.3922","Brazil","BR","BRA","São Paulo","","355542","1076857089"
"Kawagoe","Kawagoe","35.9251","139.4858","Japan","JP","JPN","Saitama","","354598","1392825080"
"Villahermosa","Villahermosa","17.9892","-92.9281","Mexico","MX","MEX","Tabasco","admin","353577","1484460894"
"Taraz","Taraz","42.9000","71.3667","Kazakhstan","KZ","KAZ","","admin","357795","1398626276"
"Cubal","Cubal","-13.1117","14.3672","Angola","AO","AGO","Benguela","","357618","1024580158"
"Luena","Luena","-11.7918","19.9062","Angola","AO","AGO","Moxico","admin","357413","1024283760"
"Ibb","Ibb","13.9667","44.1667","Yemen","YE","YEM","Ibb","admin","350864","1887321491"
"Yong’an","Yong'an","25.9733","117.3593","China","CN","CHN","Fujian","minor","357000","1156185328"
"Wuppertal","Wuppertal","51.2667","7.1833","Germany","DE","DEU","North Rhine-Westphalia","minor","355004","1276004611"
"Manisa","Manisa","38.6306","27.4222","Turkey","TR","TUR","Manisa","admin","356702","1792804328"
"San Fernando","San Fernando","15.0333","120.6833","Philippines","PH","PHL","Pampanga","admin","354666","1608862124"
"Chānda","Chanda","19.9500","79.2970","India","IN","IND","Mahārāshtra","","355739","1356592607"
"Minatitlán","Minatitlan","17.9833","-94.5500","Mexico","MX","MEX","Veracruz","minor","356020","1484735713"
"Malmö","Malmo","55.6058","13.0358","Sweden","SE","SWE","Skåne","admin","351749","1752705818"
"Nizhniy Tagil","Nizhniy Tagil","57.9167","59.9667","Russia","RU","RUS","Sverdlovskaya Oblast’","","355693","1643992310"
"San Pedro","San Pedro","14.3583","121.0583","Philippines","PH","PHL","Laguna","","326001","1608848483"
"Cuito","Cuito","-12.3833","16.9333","Angola","AO","AGO","Bié","admin","355423","1024939858"
"Hongzhai","Hongzhai","34.9857","104.5446","China","CN","CHN","Gansu","","355037","1156905289"
"Guédiawaye","Guediawaye","14.7833","-17.4000","Senegal","SN","SEN","Dakar","","329659","1686908896"
"Wakayama","Wakayama","34.2333","135.1667","Japan","JP","JPN","Wakayama","admin","353299","1392907296"
"Tin Shui Wai","Tin Shui Wai","22.4608","114.0012","Hong Kong","HK","HKG","","","286232","1344389641"
"Pavlodar","Pavlodar","52.3000","76.9500","Kazakhstan","KZ","KAZ","","admin","353930","1398790087"
"Gimpo","Gimpo","37.7000","126.6000","South Korea","KR","KOR","Gyeonggi","minor","352683","1410797548"
"Itaquaquecetuba","Itaquaquecetuba","-23.4864","-46.3486","Brazil","BR","BRA","São Paulo","","352801","1076942224"
"Nara","Nara","34.6844","135.8050","Japan","JP","JPN","Nara","admin","352377","1392520831"
"Van","Van","38.4942","43.3800","Turkey","TR","TUR","Van","admin","353419","1792940471"
"Corrientes","Corrientes","-27.4833","-58.8167","Argentina","AR","ARG","Corrientes","admin","352646","1032173097"
"Neiva","Neiva","2.9345","-75.2809","Colombia","CO","COL","Huila","admin","353033","1170159963"
"Arkhangelsk","Arkhangelsk","64.5500","40.5333","Russia","RU","RUS","Arkhangel’skaya Oblast’","admin","351488","1643797198"
"Batangas","Batangas","13.8300","121.0000","Philippines","PH","PHL","Batangas","admin","351437","1608417616"
"Licheng","Licheng","24.4935","110.3902","China","CN","CHN","Guangxi","minor","352472","1156208986"
"Koshigaya","Koshigaya","35.8911","139.7909","Japan","JP","JPN","Saitama","","346768","1392606124"
"Sinŭiju","Sinuiju","40.1000","124.4000","North Korea","KP","PRK","P’yŏngbuk","admin","352000","1408740449"
"Cabimas","Cabimas","10.4000","-71.4333","Venezuela","VE","VEN","Zulia","minor","351735","1862398719"
"Yakeshi","Yakeshi","49.2842","120.7283","China","CN","CHN","Inner Mongolia","minor","352173","1156666223"
"Oruro","Oruro","-17.9667","-67.1167","Bolivia","BO","BOL","Oruro","","351802","1068626742"
"Ahmadnagar","Ahmadnagar","19.0800","74.7300","India","IN","IND","Mahārāshtra","","350859","1356972679"
"Āvadi","Avadi","13.1097","80.0972","India","IN","IND","Tamil Nādu","","345996","1356009111"
"Sarai Alamgir","Sarai Alamgir","32.9000","73.7500","Pakistan","PK","PAK","Punjab","","350288","1586338206"
"Varna","Varna","43.2167","27.9167","Bulgaria","BG","BGR","Varna","admin","348668","1100789115"
"Gorgān","Gorgan","36.8386","54.4347","Iran","IR","IRN","Golestān","admin","350676","1364802450"
"Takatsuki","Takatsuki","34.8500","135.6167","Japan","JP","JPN","Ōsaka","","347496","1392003061"
"Holguín","Holguin","20.8869","-76.2592","Cuba","CU","CUB","Holguín","admin","350191","1192920574"
"Qo‘qon","Qo\`qon","40.5286","70.9425","Uzbekistan","UZ","UZB","Farg‘ona","","350000","1860403462"
"Semey","Semey","50.4333","80.2667","Kazakhstan","KZ","KAZ","","admin","350201","1398405657"
"Yingmen","Yingmen","39.9487","97.4395","China","CN","CHN","Gansu","","350000","1156386711"
"Cariacica","Cariacica","-20.2639","-40.4200","Brazil","BR","BRA","Espírito Santo","","348738","1076479479"
"Palmira","Palmira","3.5833","-76.2500","Colombia","CO","COL","Valle del Cauca","minor","349294","1170969619"
"Tapachula","Tapachula","14.9000","-92.2667","Mexico","MX","MEX","Chiapas","minor","348156","1484997178"
"Bydgoszcz","Bydgoszcz","53.1219","18.0003","Poland","PL","POL","Kujawsko-Pomorskie","admin","346739","1616454014"
"Antofagasta","Antofagasta","-23.6500","-70.4000","Chile","CL","CHL","Antofagasta","admin","348517","1152734289"
"Anaheim","Anaheim","33.8390","-117.8574","United States","US","USA","California","","348204","1840019322"
"Rājahmundry","Rajahmundry","16.9800","81.7800","India","IN","IND","Andhra Pradesh","","341831","1356831716"
"Chita","Chita","52.0333","113.5000","Russia","RU","RUS","Zabaykal’skiy Kray","admin","347088","1643036673"
"Bonita Springs","Bonita Springs","26.3558","-81.7859","United States","US","USA","Florida","","347581","1840014227"
"Caruaru","Caruaru","-8.2828","-35.9758","Brazil","BR","BRA","Pernambuco","","347088","1076397475"
"Nice","Nice","43.7034","7.2663","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","342669","1250774553"
"Shāhjānpur","Shahjanpur","27.8800","79.9100","India","IN","IND","Uttar Pradesh","","346103","1356415439"
"António Enes","Antonio Enes","-16.2333","39.9167","Mozambique","MZ","MOZ","Nampula","","347175","1508591443"
"Central Coast","Central Coast","-33.3000","151.2000","Australia","AU","AUS","New South Wales","","346596","1036067845"
"Pamukkale","Pamukkale","37.9167","29.1167","Turkey","TR","TUR","Denizli","","346625","1792749284"
"Wad Medani","Wad Medani","14.4000","33.5167","Sudan","SD","SDN","Gezira","admin","345290","1729581572"
"Öskemen","Oskemen","49.9833","82.6167","Kazakhstan","KZ","KAZ","","admin","346127","1398371414"
"Tanch’ŏn","Tanch'on","40.4580","128.9110","North Korea","KP","PRK","Hamnam","","345876","1408940912"
"Tokorozawa","Tokorozawa","35.7996","139.4686","Japan","JP","JPN","Saitama","","341289","1392340764"
"Hosūr","Hosur","12.7409","77.8253","India","IN","IND","Tamil Nādu","","345000","1356829362"
"Serekunda","Serekunda","13.4333","-16.6667","The Gambia","GM","GMB","Kanifing","","340000","1270640044"
"Greensboro","Greensboro","36.0956","-79.8271","United States","US","USA","North Carolina","","345270","1840013356"
"Kūstī","Kusti","13.1667","32.6667","Sudan","SD","SDN","White Nile","","345068","1729229371"
"Cuddapah","Cuddapah","14.4700","78.8200","India","IN","IND","Andhra Pradesh","","344893","1356360140"
"Simferopol","Simferopol","44.9484","34.1000","Ukraine","UA","UKR","Krym, Avtonomna Respublika","admin","341799","1804665958"
"Sarıyer","Sariyer","41.1669","29.0572","Turkey","TR","TUR","İstanbul","minor","342503","1792425356"
"Sumqayıt","Sumqayit","40.5917","49.6397","Azerbaijan","AZ","AZE","Sumqayıt","admin","341200","1031630283"
"Ōtsu","Otsu","35.0167","135.8500","Japan","JP","JPN","Shiga","admin","342607","1392164106"
"Vitória da Conquista","Vitoria da Conquista","-14.8658","-40.8389","Brazil","BR","BRA","Bahia","","343230","1076812020"
"Makiivka","Makiivka","48.0556","37.9611","Ukraine","UA","UKR","Donetska Oblast","minor","343158","1804926154"
"Vũng Tàu","Vung Tau","10.3833","107.1167","Vietnam","VN","VNM","Bà Rịa-Vũng Tàu","admin","341552","1704581438"
"Brest","Brest","52.1347","23.6569","Belarus","BY","BLR","Brestskaya Voblasts’","admin","340723","1112896927"
"Uruapan","Uruapan","19.4208","-102.0628","Mexico","MX","MEX","Michoacán","minor","334749","1484051478"
"Gómez Palacio","Gomez Palacio","25.5611","-103.4983","Mexico","MX","MEX","Durango","minor","342286","1484092318"
"Yeosu","Yeosu","34.7333","127.7333","South Korea","KR","KOR","Jeonnam","minor","341994","1410002025"
"Kaluga","Kaluga","54.5333","36.2667","Russia","RU","RUS","Kaluzhskaya Oblast’","admin","341892","1643161747"
"Meram","Meram","37.8364","32.4383","Turkey","TR","TUR","Konya","minor","342315","1792895883"
"Muzaffarpur","Muzaffarpur","26.1225","85.3906","India","IN","IND","Bihār","","333200","1356897425"
"Alwar","Alwar","27.5498","76.6355","India","IN","IND","Rājasthān","","341422","1356570855"
"Lublin","Lublin","51.2500","22.5667","Poland","PL","POL","Lubelskie","admin","339850","1616953501"
"Lianran","Lianran","24.9211","102.4778","China","CN","CHN","Yunnan","minor","341341","1156332794"
"Buôn Ma Thuột","Buon Ma Thuot","12.6667","108.0500","Vietnam","VN","VNM","Đắk Lắk","admin","340000","1704542086"
"Cuernavaca","Cuernavaca","18.9186","-99.2342","Mexico","MX","MEX","Morelos","admin","338650","1484926471"
"Alicante","Alicante","38.3453","-0.4831","Spain","ES","ESP","Valencia","minor","338577","1724923163"
"East London","East London","-33.0175","27.9047","South Africa","ZA","ZAF","Eastern Cape","","338627","1710178149"
"Tarsus","Tarsus","36.9165","34.8951","Turkey","TR","TUR","Mersin","minor","339676","1792003860"
"Matadi","Matadi","-5.8167","13.4833","Congo (Kinshasa)","CD","COD","Kongo Central","admin","337000","1180529604"
"Islip","Islip","40.7385","-73.1888","United States","US","USA","New York","","339596","1840058203"
"Cimanggis","Cimanggis","-6.3645","106.8591","Indonesia","ID","IDN","Jawa Barat","","324343","1360945721"
"Kaesŏng","Kaesong","37.9667","126.5500","North Korea","KP","PRK","Kaesŏng","admin","338155","1408725697"
"Beni","Beni","0.5000","29.4667","Congo (Kinshasa)","CD","COD","Nord-Kivu","","332903","1180325051"
"Sultanbeyli","Sultanbeyli","40.9683","29.2617","Turkey","TR","TUR","İstanbul","","327798","1792744270"
"Huntsville","Huntsville","34.6981","-86.6412","United States","US","USA","Alabama","","339136","1840005061"
"Randburg","Randburg","-26.0936","28.0064","South Africa","ZA","ZAF","Gauteng","","337053","1710279034"
"Jitpur","Jitpur","27.6666","85.3333","Nepal","NP","NPL","","","337785","1524593184"
"Beylikdüzü","Beylikduzu","41.0011","28.6419","Turkey","TR","TUR","İstanbul","","331525","1792504666"
"Petrolina","Petrolina","-9.3928","-40.5078","Brazil","BR","BRA","Pernambuco","","337683","1076843988"
"Chinju","Chinju","35.2000","128.0833","South Korea","KR","KOR","Gyeongnam","","337230","1410560599"
"Tangdong","Tangdong","25.9755","113.2302","China","CN","CHN","Hunan","minor","337495","1156682620"
"Reading","Reading","51.4542","-0.9731","United Kingdom","GB","GBR","Reading","","337108","1826869043"
"Sambalpur","Sambalpur","21.4700","83.9700","India","IN","IND","Odisha","","335761","1356772339"
"Hangu","Hangu","39.2320","117.7770","China","CN","CHN","Tianjin","","332793","1156202457"
"Belfast","Belfast","54.5964","-5.9300","United Kingdom","GB","GBR","Belfast","","333871","1826325087"
"Shah Latif Town","Shah Latif Town","24.8806","67.1625","Pakistan","PK","PAK","Sindh","","335823","1586521586"
"Iwaki","Iwaki","37.0505","140.8877","Japan","JP","JPN","Fukushima","","336111","1392158088"
"Salamanca","Salamanca","20.5703","-101.1972","Mexico","MX","MEX","Guanajuato","minor","327169","1484741627"
"Yingzhong","Yingzhong","32.2381","119.8133","China","CN","CHN","Jiangsu","","334977","1156969537"
"Viña del Mar","Vina del Mar","-33.0244","-71.5517","Chile","CL","CHL","Valparaíso","","332875","1152898644"
"Hirosaki","Hirosaki","40.6031","140.4638","Japan","JP","JPN","Aomori","","335203","1392003311"
"Bielefeld","Bielefeld","52.0211","8.5347","Germany","DE","DEU","North Rhine-Westphalia","minor","334002","1276112885"
"Az Zaqāzīq","Az Zaqaziq","30.5667","31.5000","Egypt","EG","EGY","Ash Sharqīyah","admin","302840","1818017750"
"Nazrēt","Nazret","8.5414","39.2689","Ethiopia","ET","ETH","Oromīya","","324000","1231826873"
"Cuenca","Cuenca","-2.8974","-79.0045","Ecuador","EC","ECU","Azuay","admin","329928","1218041020"
"Tampere","Tampere","61.4981","23.7600","Finland","FI","FIN","Pirkanmaa","admin","334112","1246964318"
"Bonn","Bonn","50.7333","7.1000","Germany","DE","DEU","North Rhine-Westphalia","minor","331885","1276807850"
"Al Fayyūm","Al Fayyum","29.3084","30.8441","Egypt","EG","EGY","Al Fayyūm","admin","315940","1818281002"
"Blida","Blida","36.4722","2.8333","Algeria","DZ","DZA","Blida","admin","331779","1012045589"
"Ciudad Obregón","Ciudad Obregon","27.4939","-109.9389","Mexico","MX","MEX","Sonora","minor","329404","1484887986"
"Uberaba","Uberaba","-19.7478","-47.9319","Brazil","BR","BRA","Minas Gerais","","333783","1076230331"
"Corpus Christi","Corpus Christi","27.7254","-97.3767","United States","US","USA","Texas","","333764","1840019718"
"Asan","Asan","36.7833","126.9833","South Korea","KR","KOR","Chungnam","","332790","1410521090"
"Ribeirão das Neves","Ribeirao das Neves","-19.7669","-44.0869","Brazil","BR","BRA","Minas Gerais","","331045","1076482220"
"Soledad de Graciano Sánchez","Soledad de Graciano Sanchez","22.1833","-100.9333","Mexico","MX","MEX","San Luis Potosí","minor","332072","1484610487"
"Maebashi","Maebashi","36.3895","139.0634","Japan","JP","JPN","Gunma","admin","331849","1392333295"
"Kāmārhāti","Kamarhati","22.6686","88.3704","India","IN","IND","West Bengal","","330211","1356676559"
"Gəncə","Ganca","40.6828","46.3606","Azerbaijan","AZ","AZE","Gəncə","admin","331400","1031197327"
"Kendari","Kendari","-3.9907","122.5086","Indonesia","ID","IDN","Sulawesi Tenggara","admin","331013","1360992102"
"Balıkesir","Balikesir","39.6333","27.8833","Turkey","TR","TUR","Balıkesir","admin","331788","1792020804"
"Thái Nguyên","Thai Nguyen","21.6000","105.8500","Vietnam","VN","VNM","Thái Nguyên","admin","330000","1704613715"
"Asahikawa","Asahikawa","43.7706","142.3650","Japan","JP","JPN","Hokkaidō","","331392","1392380358"
"Smolensk","Smolensk","54.7828","32.0453","Russia","RU","RUS","Smolenskaya Oblast’","admin","329853","1643627051"
"Dahūk","Dahuk","36.8667","43.0000","Iraq","IQ","IRQ","Dahūk","admin","330600","1368137126"
"Wŏnju","Wonju","37.3417","127.9208","South Korea","KR","KOR","Gangwon","","330854","1410782685"
"Nakuru","Nakuru","-0.3000","36.0667","Kenya","KE","KEN","Nakuru","admin","307990","1404140179"
"Lafia","Lafia","8.5000","8.5167","Nigeria","NG","NGA","Nasarawa","admin","330712","1566042542"
"Fort Wayne","Fort Wayne","41.0888","-85.1436","United States","US","USA","Indiana","","330305","1840008261"
"Güngören","Gungoren","41.0225","28.8717","Turkey","TR","TUR","İstanbul","","289331","1792090978"
"Koriyama","Koriyama","37.4004","140.3597","Japan","JP","JPN","Fukushima","","329706","1392003178"
"Bamiantong","Bamiantong","44.9164","130.5212","China","CN","CHN","Heilongjiang","minor","330000","1156971194"
"Taourirt","Taourirt","34.4169","-2.8850","Morocco","MA","MAR","Oriental","","321876","1504648957"
"Rāmpur","Rampur","28.8000","79.0000","India","IN","IND","Uttar Pradesh","","325248","1356666569"
"Debrecen","Debrecen","47.5300","21.6392","Hungary","HU","HUN","Hajdú-Bihar","admin","328642","1348460698"
"Cabanatuan City","Cabanatuan City","15.4908","120.9678","Philippines","PH","PHL","Nueva Ecija","","327325","1608247694"
"Markham","Markham","43.8767","-79.2633","Canada","CA","CAN","Ontario","","328966","1124272698"
"Fayetteville","Fayetteville","35.0850","-78.9772","United States","US","USA","North Carolina","","328827","1840013490"
"Pelotas","Pelotas","-31.7719","-52.3425","Brazil","BR","BRA","Rio Grande do Sul","","328275","1076815692"
"Roodepoort","Roodepoort","-26.1625","27.8725","South Africa","ZA","ZAF","Gauteng","","326416","1710090212"
"Volzhskiy","Volzhskiy","48.8056","44.7417","Russia","RU","RUS","Volgogradskaya Oblast’","","326055","1643875527"
"Ann Arbor","Ann Arbor","42.2759","-83.7310","United States","US","USA","Michigan","","327814","1840003172"
"Bijāpur","Bijapur","16.8300","75.7100","India","IN","IND","Karnātaka","","327427","1356499139"
"Sukabumi","Sukabumi","-6.9181","106.9315","Indonesia","ID","IDN","Jawa Barat","","320970","1360447543"
"Tharād","Tharad","24.3926","71.6248","India","IN","IND","Gujarāt","","327289","1356055796"
"Ratnāgiri","Ratnagiri","16.9944","73.3000","India","IN","IND","Mahārāshtra","","327120","1356990175"
"Ulanhot","Ulanhot","46.0726","122.0719","China","CN","CHN","Inner Mongolia","","327081","1156182060"
"Yunzhong","Yunzhong","39.8143","113.0946","China","CN","CHN","Shanxi","minor","326849","1156589240"
"Jackson","Jackson","32.3157","-90.2125","United States","US","USA","Mississippi","admin","326977","1840014895"
"Cotabato","Cotabato","7.2200","124.2500","Philippines","PH","PHL","Cotabato","admin","325079","1608753128"
"Al Fallūjah","Al Fallujah","33.3500","43.7833","Iraq","IQ","IRQ","Al Anbār","minor","326471","1368081888"
"Kōchi","Kochi","33.5589","133.5314","Japan","JP","JPN","Kōchi","admin","325560","1392086071"
"Minna","Minna","9.6139","6.5569","Nigeria","NG","NGA","Niger","admin","322163","1566285237"
"Bielsko-Biała","Bielsko-Biala","49.8225","19.0444","Poland","PL","POL","Śląskie","minor","325000","1616302636"
"Canoas","Canoas","-29.9200","-51.1800","Brazil","BR","BRA","Rio Grande do Sul","","323827","1076009922"
"Gwangmyeongni","Gwangmyeongni","37.4760","126.8660","South Korea","KR","KOR","Gyeonggi","","318021","1410103113"
"Cluj-Napoca","Cluj-Napoca","46.7667","23.5833","Romania","RO","ROU","Cluj","admin","324576","1642503974"
"Bari","Bari","41.1253","16.8667","Italy","IT","ITA","Puglia","admin","323370","1380991126"
"Pucallpa","Pucallpa","-8.3833","-74.5500","Peru","PE","PER","Ucayali","admin","326040","1604478712"
"Kuching","Kuching","1.5575","110.3439","Malaysia","MY","MYS","Sarawak","admin","325132","1458462262"
"Gonder","Gonder","12.6075","37.4592","Ethiopia","ET","ETH","Āmara","","323900","1231234833"
"Kikuyu","Kikuyu","-1.2500","36.6667","Kenya","KE","KEN","Kiambu","","323881","1404116834"
"Mekele","Mekele","13.4969","39.4769","Ethiopia","ET","ETH","Tigray","admin","323700","1231468531"
"Gonaïves","Gonaives","19.4456","-72.6883","Haiti","HT","HTI","Artibonite","admin","324043","1332384588"
"Guasave","Guasave","25.5744","-108.4706","Mexico","MX","MEX","Sinaloa","minor","320000","1484450749"
"Naha","Naha","26.2122","127.6792","Japan","JP","JPN","Okinawa","admin","316048","1392583118"
"Nantes","Nantes","47.2181","-1.5528","France","FR","FRA","Pays de la Loire","admin","318808","1250091495"
"Binxian","Binxian","35.0364","108.0764","China","CN","CHN","Shaanxi","","323256","1156167534"
"Shimoga","Shimoga","13.9333","75.5667","India","IN","IND","Karnātaka","","322650","1356016156"
"Hotan","Hotan","37.1000","80.0167","China","CN","CHN","Xinjiang","minor","322300","1156111844"
"Kaiyuan","Kaiyuan","23.7147","103.2585","China","CN","CHN","Yunnan","","322693","1156888008"
"Ciudad del Este","Ciudad del Este","-25.5167","-54.6167","Paraguay","PY","PRY","Alto Paraná","admin","320782","1600265252"
"Temara","Temara","33.9267","-6.9122","Morocco","MA","MAR","Rabat-Salé-Kénitra","","313510","1504016606"
"Antioch","Antioch","37.9787","-121.7960","United States","US","USA","California","","322796","1840018903"
"Uíge","Uige","-7.6167","15.0500","Angola","AO","AGO","Uíge","admin","322531","1024666275"
"Osh","Osh","40.5333","72.7833","Kyrgyzstan","KG","KGZ","Osh","admin","322164","1417797596"
"Córdoba","Cordoba","37.8845","-4.7796","Spain","ES","ESP","Andalusia","minor","322071","1724706440"
"Camagüey","Camaguey","21.3839","-77.9075","Cuba","CU","CUB","Camagüey","admin","321992","1192667983"
"Mobile","Mobile","30.6782","-88.1162","United States","US","USA","Alabama","","322079","1840006009"
"San Salvador de Jujuy","San Salvador de Jujuy","-24.1833","-65.3000","Argentina","AR","ARG","Jujuy","admin","321789","1032815808"
"Al Kūt","Al Kut","32.4907","45.8304","Iraq","IQ","IRQ","Wāsiţ","admin","321521","1368569694"
"Cherepovets","Cherepovets","59.1167","37.9000","Russia","RU","RUS","Vologodskaya Oblast’","","318856","1643937680"
"Lexington","Lexington","38.0423","-84.4587","United States","US","USA","Kentucky","","321354","1840015211"
"San Miguelito","San Miguelito","9.0330","-79.5000","Panama","PA","PAN","Panamá","minor","315019","1591394184"
"Okene","Okene","7.5500","6.2333","Nigeria","NG","NGA","Kogi","minor","320260","1566909987"
"Timişoara","Timisoara","45.7597","21.2300","Romania","RO","ROU","Timiş","admin","319279","1642603121"
"Menongue","Menongue","-14.6556","17.6842","Angola","AO","AGO","Cuando Cubango","admin","320914","1024725650"
"Jūnāgadh","Junagadh","21.5200","70.4630","India","IN","IND","Gujarāt","","320250","1356057451"
"Katsina","Katsina","12.9889","7.6008","Nigeria","NG","NGA","Katsina","admin","318459","1566777297"
"Poltava","Poltava","49.5894","34.5514","Ukraine","UA","UKR","Poltavska Oblast","admin","317847","1804547489"
"Maroua","Maroua","10.5971","14.3157","Cameroon","CM","CMR","Extrême-Nord","admin","319941","1120956931"
"Xiaoli","Xiaoli","22.6782","113.2313","China","CN","CHN","Guangdong","","315820","1156561711"
"Kaech’ŏn","Kaech'on","39.6986","125.9061","North Korea","KP","PRK","P’yŏngnam","","319554","1408781295"
"Asan","Asan","36.3500","126.9167","South Korea","KR","KOR","Chungnam","","319929","1410904940"
"Tehuacán","Tehuacan","18.4617","-97.3928","Mexico","MX","MEX","Puebla","minor","319375","1484817336"
"Aksaray","Aksaray","38.3742","34.0289","Turkey","TR","TUR","Aksaray","admin","319865","1792650390"
"Attiecoubé","Attiecoube","5.3333","-4.0333","Côte d'Ivoire","CI","CIV","Abidjan","","313135","1384919817"
"Coatzacoalcos","Coatzacoalcos","18.1500","-94.4333","Mexico","MX","MEX","Veracruz","minor","319187","1484016854"
"Saransk","Saransk","54.1833","45.1833","Russia","RU","RUS","Mordoviya","admin","318841","1643003229"
"Nukus","Nukus","42.4667","59.6000","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","admin","319583","1860620079"
"Puerto Plata","Puerto Plata","19.8000","-70.6833","Dominican Republic","DO","DOM","Cibao Norte","minor","318419","1214279422"
"Hāpur","Hapur","28.7309","77.7757","India","IN","IND","Uttar Pradesh","","317004","1356820012"
"Zalantun","Zalantun","48.0033","122.7365","China","CN","CHN","Inner Mongolia","minor","318933","1156280237"
"Münster","Munster","51.9625","7.6256","Germany","DE","DEU","North Rhine-Westphalia","minor","317713","1276714504"
"Trichūr","Trichur","10.5200","76.2100","India","IN","IND","Kerala","","315596","1356589298"
"An Nhơn","An Nhon","13.9170","109.0830","Vietnam","VN","VNM","Bình Định","minor","317620","1704548208"
"Taubaté","Taubate","-23.0250","-45.5556","Brazil","BR","BRA","São Paulo","","317915","1076146252"
"Binangonan","Binangonan","14.4514","121.1919","Philippines","PH","PHL","Rizal","","313631","1608102511"
"Cirebon","Cirebon","-6.7071","108.5574","Indonesia","ID","IDN","Jawa Barat","","316126","1360011375"
"Safi","Safi","32.2833","-9.2333","Morocco","MA","MAR","Marrakech-Safi","","308508","1504000717"
"Mitchells Plain","Mitchells Plain","-34.0506","18.6181","South Africa","ZA","ZAF","Western Cape","","310485","1710549821"
"Boaco","Boaco","12.4719","-85.6594","Nicaragua","NI","NIC","Boaco","admin","317000","1558023610"
"Asheville","Asheville","35.5707","-82.5537","United States","US","USA","North Carolina","","316694","1840013411"
"Cà Mau","Ca Mau","9.1833","105.1500","Vietnam","VN","VNM","Cà Mau","admin","315270","1704320474"
"Trenton","Trenton","40.2237","-74.7641","United States","US","USA","New Jersey","admin","316436","1840001383"
"Toshima","Toshima","35.7261","139.7167","Japan","JP","JPN","Tōkyō","","297954","1392793312"
"Vologda","Vologda","59.2167","39.9000","Russia","RU","RUS","Vologodskaya Oblast’","admin","313012","1643013685"
"Barddhamān","Barddhaman","23.2333","87.8667","India","IN","IND","West Bengal","","314265","1356062166"
"Tanza","Tanza","14.3944","120.8531","Philippines","PH","PHL","Cavite","","312116","1608010777"
"Bor","Bor","6.2125","31.5608","South Sudan","SS","SSD","Jonglei","admin","315351","1728186782"
"Kasur","Kasur","31.1167","74.4500","Pakistan","PK","PAK","Punjab","minor","314617","1586858135"
"Yakou","Yakou","33.2937","113.5203","China","CN","CHN","Henan","minor","313828","1156326854"
"Santa Rosa","Santa Rosa","38.4458","-122.7067","United States","US","USA","California","","314196","1840021488"
"Orël","Orel","52.9686","36.0694","Russia","RU","RUS","Orlovskaya Oblast’","admin","311625","1643703818"
"Shahr-e Qods","Shahr-e Qods","35.7214","51.1089","Iran","IR","IRN","Tehrān","minor","309605","1364272235"
"Kingston upon Hull","Kingston upon Hull","53.7444","-0.3325","United Kingdom","GB","GBR","Kingston upon Hull, City of","","314018","1826028894"
"Mannheim","Mannheim","49.4878","8.4661","Germany","DE","DEU","Baden-Württemberg","minor","311831","1276150104"
"Santa Ana","Santa Ana","33.7367","-117.8819","United States","US","USA","California","","313818","1840021964"
"Guarujá","Guaruja","-23.9936","-46.2564","Brazil","BR","BRA","São Paulo","","311230","1076832747"
"Playa del Carmen","Playa del Carmen","20.6281","-87.0755","Mexico","MX","MEX","Quintana Roo","minor","304942","1484559458"
"Pasir Gudang","Pasir Gudang","1.5028","103.9356","Malaysia","MY","MYS","Johor","","312499","1458548800"
"Datang","Datang","22.9481","113.9276","China","CN","CHN","Guangdong","","310889","1156511075"
"Panvel","Panvel","18.9944","73.1139","India","IN","IND","Mahārāshtra","","311434","1356424574"
"Lansing","Lansing","42.7142","-84.5601","United States","US","USA","Michigan","admin","312829","1840003102"
"Zarzal","Zarzal","4.3983","-76.0772","Colombia","CO","COL","Valle del Cauca","minor","312599","1170495189"
"La Victoria","La Victoria","18.5500","-69.9000","Dominican Republic","DO","DOM","Ozama","","312345","1214692077"
"Port Elizabeth","Port Elizabeth","-33.9581","25.6000","South Africa","ZA","ZAF","Eastern Cape","","312392","1710918997"
"Alanya","Alanya","36.5500","32.0000","Turkey","TR","TUR","Antalya","minor","312319","1792233416"
"Nizāmābād","Nizamabad","18.6720","78.0940","India","IN","IND","Andhra Pradesh","","311152","1356242168"
"Al ‘Ajamī","Al \`Ajami","31.0959","29.7604","Egypt","EG","EGY","Al Iskandarīyah","","300000","1818001525"
"Quy Nhơn","Quy Nhon","13.7667","109.2333","Vietnam","VN","VNM","Bình Định","admin","311000","1704056461"
"Yakutsk","Yakutsk","62.0272","129.7319","Russia","RU","RUS","Sakha (Yakutiya)","admin","311760","1643163546"
"Yokkaichi","Yokkaichi","34.9650","136.6244","Japan","JP","JPN","Mie","","310263","1392198395"
"Ponta Grossa","Ponta Grossa","-25.0994","-50.1583","Brazil","BR","BRA","Paraná","","311611","1076716439"
"Catania","Catania","37.5000","15.0903","Italy","IT","ITA","Sicilia","minor","311584","1380562508"
"Chalco","Chalco","19.2647","-98.8975","Mexico","MX","MEX","México","minor","310130","1484834149"
"Henderson","Henderson","36.0133","-115.0381","United States","US","USA","Nevada","","311250","1840020361"
"Gwangju","Gwangju","37.3667","127.2833","South Korea","KR","KOR","Gyeonggi","","310278","1410978753"
"Longquan","Longquan","25.9106","114.7860","China","CN","CHN","Jiangxi","","310700","1156262367"
"Karlsruhe","Karlsruhe","49.0092","8.4040","Germany","DE","DEU","Baden-Württemberg","minor","308436","1276923210"
"Shahrīār","Shahriar","35.6597","51.0592","Iran","IR","IRN","Tehrān","minor","309607","1364482289"
"Kurgan","Kurgan","55.4408","65.3411","Russia","RU","RUS","Kurganskaya Oblast’","admin","309285","1643201853"
"Kasugai","Kasugai","35.2475","136.9722","Japan","JP","JPN","Aichi","","306449","1392431449"
"St. Paul","St. Paul","44.9478","-93.1039","United States","US","USA","Minnesota","admin","309751","1840008940"
"Sariwŏn","Sariwon","38.5064","125.7597","North Korea","KP","PRK","Hwangbuk","admin","307764","1408018673"
"St. Catharines","St. Catharines","43.1833","-79.2333","Canada","CA","CAN","Ontario","","309319","1124140229"
"Karawang","Karawang","-6.3125","107.2950","Indonesia","ID","IDN","Jawa Barat","minor","307880","1360007357"
"Lakeland","Lakeland","28.0557","-81.9545","United States","US","USA","Florida","","308994","1840015108"
"Niagara Falls","Niagara Falls","43.0600","-79.1067","Canada","CA","CAN","Ontario","","308596","1124704011"
"Oulgaret","Oulgaret","11.9570","79.7737","India","IN","IND","Puducherry","","300104","1356242623"
"Taboão da Serra","Taboao da Serra","-23.5328","-46.7994","Brazil","BR","BRA","São Paulo","","293652","1076000585"
"Vladikavkaz","Vladikavkaz","43.0400","44.6775","Russia","RU","RUS","North Ossetia","admin","306978","1643438348"
"Nghi Sơn","Nghi Son","19.4170","105.7500","Vietnam","VN","VNM","Thanh Hóa","","307304","1704908796"
"Juliaca","Juliaca","-15.4833","-70.1333","Peru","PE","PER","Puno","","307417","1604174464"
"Parbhani","Parbhani","19.2700","76.7800","India","IN","IND","Mahārāshtra","","307170","1356632842"
"Hisar","Hisar","29.1500","75.7000","India","IN","IND","Haryāna","","307024","1356004790"
"Puerto La Cruz","Puerto La Cruz","10.2000","-64.6333","Venezuela","VE","VEN","Anzoátegui","minor","306261","1862856141"
"Puerto Princesa","Puerto Princesa","9.7500","118.7500","Philippines","PH","PHL","Puerto Princesa","admin","307079","1608455700"
"Podolsk","Podolsk","55.4311","37.5456","Russia","RU","RUS","Moskovskaya Oblast’","","299660","1643693800"
"Meguro","Meguro","35.6415","139.6983","Japan","JP","JPN","Tōkyō","","286905","1392876044"
"Ciudad Victoria","Ciudad Victoria","23.7389","-99.1431","Mexico","MX","MEX","Tamaulipas","admin","305155","1484616519"
"Ciudad Santa Catarina","Ciudad Santa Catarina","25.6833","-100.4500","Mexico","MX","MEX","Nuevo León","minor","306322","1484858068"
"Santarém","Santarem","-2.4300","-54.7200","Brazil","BR","BRA","Pará","","306480","1076256494"
"Newark","Newark","40.7245","-74.1725","United States","US","USA","New Jersey","","306247","1840002791"
"Vaughan","Vaughan","43.8333","-79.5000","Canada","CA","CAN","Ontario","","306233","1124000141"
"Āwasa","Awasa","7.0500","38.4667","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","admin","300100","1231663122"
"Ōakashichō","Oakashicho","34.6500","135.0000","Japan","JP","JPN","Hyōgo","","299699","1392000137"
"Yeşilyurt","Yesilyurt","38.2961","38.2489","Turkey","TR","TUR","Malatya","minor","304839","1792143860"
"Pekalongan","Pekalongan","-6.8833","109.6667","Indonesia","ID","IDN","Jawa Tengah","","298386","1360039434"
"Pondokaren","Pondokaren","-6.2811","106.7019","Indonesia","ID","IDN","Jawa Barat","","294996","1360008284"
"Adıyaman","Adiyaman","37.7639","38.2778","Turkey","TR","TUR","Adıyaman","admin","304615","1792686663"
"Kurume","Kurume","33.3167","130.5167","Japan","JP","JPN","Fukuoka","","302858","1392003184"
"Vila Nova de Gaia","Vila Nova de Gaia","41.1333","-8.6167","Portugal","PT","PRT","Porto","minor","302295","1620040225"
"Paulista","Paulista","-7.9408","-34.8728","Brazil","BR","BRA","Pernambuco","","300466","1076183876"
"Oaxaca","Oaxaca","17.0606","-96.7253","Mexico","MX","MEX","Oaxaca","admin","300050","1484301467"
"Armenia","Armenia","4.5300","-75.6800","Colombia","CO","COL","Quindío","admin","301226","1170398459"
"Akita","Akita","39.7200","140.1026","Japan","JP","JPN","Akita","admin","302984","1392129688"
"Awka","Awka","6.2000","7.0667","Nigeria","NG","NGA","Anambra","admin","301657","1566560581"
"San Bernardo","San Bernardo","-33.5833","-70.7000","Chile","CL","CHL","Región Metropolitana","minor","301313","1152050361"
"Curepipe","Curepipe","-20.3188","57.5263","Mauritius","MU","MUS","Plaines Wilhems","","299975","1480343099"
"Iksan","Iksan","35.9439","126.9544","South Korea","KR","KOR","Jeonbuk","","302310","1410134391"
"Pachuca","Pachuca","20.1000","-98.7500","Mexico","MX","MEX","Hidalgo","admin","297848","1484575026"
"Al Maţarīyah","Al Matariyah","31.1833","32.0333","Egypt","EG","EGY","Ad Daqahlīyah","","300000","1818621008"
"Sóc Trăng","Soc Trang","9.6028","105.9736","Vietnam","VN","VNM","Sóc Trăng","admin","300000","1704758756"
"Bahía Blanca","Bahia Blanca","-38.7167","-62.2667","Argentina","AR","ARG","Buenos Aires","minor","301572","1032795766"
"Coacalco","Coacalco","19.6333","-99.0931","Mexico","MX","MEX","México","minor","293444","1484929782"
"San Juan del Río","San Juan del Rio","20.3833","-99.9833","Mexico","MX","MEX","Querétaro","minor","301237","1484890205"
"Fort Collins","Fort Collins","40.5477","-105.0656","United States","US","USA","Colorado","","301596","1840020151"
"Limeira","Limeira","-22.5650","-47.4019","Brazil","BR","BRA","São Paulo","","300911","1076508566"
"Popayán","Popayan","2.4542","-76.6092","Colombia","CO","COL","Cauca","admin","300837","1170764265"
"Praia Grande","Praia Grande","-24.0061","-46.4028","Brazil","BR","BRA","São Paulo","","299261","1076548222"
"Qianzhou","Qianzhou","28.3185","109.7318","China","CN","CHN","Hunan","minor","301000","1156103756"
"Newcastle","Newcastle","54.9800","-1.6100","United Kingdom","GB","GBR","Newcastle upon Tyne","","300196","1826292220"
"Māldah","Maldah","25.0119","88.1433","India","IN","IND","West Bengal","","300088","1356350335"
"Ciudad General Escobedo","Ciudad General Escobedo","25.7933","-100.1583","Mexico","MX","MEX","Nuevo León","minor","299364","1484345554"
"Oyster Bay","Oyster Bay","40.7846","-73.5140","United States","US","USA","New York","","300743","1840058379"
"Montpellier","Montpellier","43.6119","3.8772","France","FR","FRA","Occitanie","minor","295542","1250819356"
"Phan Thiết","Phan Thiet","10.9333","108.1000","Vietnam","VN","VNM","Bình Thuận","admin","299680","1704221456"
"Stockport","Stockport","53.4083","-2.1494","United Kingdom","GB","GBR","Stockport","","295200","1826228303"
"Al Qunfudhah","Al Qunfudhah","19.1264","41.0789","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","300516","1682434353"
"Bada Barabīl","Bada Barabil","22.1200","85.4000","India","IN","IND","Odisha","","300000","1356293872"
"Tampico","Tampico","22.2553","-97.8686","Mexico","MX","MEX","Tamaulipas","minor","297284","1484060031"
"Białystok","Bialystok","53.1353","23.1456","Poland","PL","POL","Podlaskie","admin","297554","1616443575"
"Coatepeque","Coatepeque","14.7000","-91.8667","Guatemala","GT","GTM","Quetzaltenango","minor","300000","1320672314"
"Ash Shāmīyah","Ash Shamiyah","31.9636","44.5989","Iraq","IQ","IRQ","Al Qādisīyah","minor","300000","1368487355"
"Mérida","Merida","8.4800","-71.1900","Venezuela","VE","VEN","Mérida","admin","300000","1862668122"
"Chakwal","Chakwal","32.9303","72.8556","Pakistan","PK","PAK","Punjab","minor","299751","1586412908"
"Murmansk","Murmansk","68.9706","33.0750","Russia","RU","RUS","Murmanskaya Oblast’","admin","298096","1643755251"
"Ar Raqqah","Ar Raqqah","35.9500","39.0100","Syria","SY","SYR","Ar Raqqah","admin","299824","1760035911"
"Afyonkarahisar","Afyonkarahisar","38.7578","30.5386","Turkey","TR","TUR","Afyonkarahisar","admin","299673","1792855788"
"Bihār","Bihar","25.1970","85.5180","India","IN","IND","Bihār","","297268","1356002333"
"Jember","Jember","-8.1727","113.6873","Indonesia","ID","IDN","Jawa Timur","minor","298585","1360316553"
"Valladolid","Valladolid","41.6528","-4.7236","Spain","ES","ESP","Castille-Leon","admin","297775","1724133771"
"Reading","Reading","40.3400","-75.9267","United States","US","USA","Pennsylvania","","299068","1840001185"
"Springfield","Springfield","37.1943","-93.2916","United States","US","USA","Missouri","","298722","1840009904"
"İskenderun","Iskenderun","36.5817","36.1650","Turkey","TR","TUR","Hatay","minor","297943","1792962911"
"Al Mubarraz","Al Mubarraz","25.4416","49.6642","Saudi Arabia","SA","SAU","Ash Sharqīyah","","298562","1682484039"
"Petrópolis","Petropolis","-22.5050","-43.1789","Brazil","BR","BRA","Rio de Janeiro","","298142","1076352504"
"Augsburg","Augsburg","48.3689","10.8978","Germany","DE","DEU","Bavaria","minor","296478","1276314100"
"Navotas","Navotas","14.6667","120.9417","Philippines","PH","PHL","Navotas","admin","247543","1608538447"
"Chernihiv","Chernihiv","51.4939","31.2947","Ukraine","UA","UKR","Chernihivska Oblast","admin","294522","1804565996"
"Yangsan","Yangsan","35.3333","129.0333","South Korea","KR","KOR","Gyeongnam","minor","297532","1410795549"
"Comilla","Comilla","23.4500","91.2000","Bangladesh","BD","BGD","Chattogram","","296010","1050609330"
"Irvine","Irvine","33.6772","-117.7738","United States","US","USA","California","","297868","1840019325"
"Bradford","Bradford","53.8000","-1.7500","United Kingdom","GB","GBR","Bradford","","293277","1826687116"
"Tagum","Tagum","7.4478","125.8078","Philippines","PH","PHL","Davao del Norte","admin","296202","1608755366"
"Espoo","Espoo","60.2056","24.6556","Finland","FI","FIN","Uusimaa","minor","297132","1246357316"
"Valparaíso","Valparaiso","-33.0461","-71.6197","Chile","CL","CHL","Valparaíso","admin","296655","1152337970"
"Silang","Silang","14.2306","120.9750","Philippines","PH","PHL","Cavite","","295644","1608301996"
"Finglas","Finglas","53.4597","-6.2181","Ireland","IE","IRL","Dublin","","296214","1372023005"
"Rangpur","Rangpur","25.7500","89.2444","Bangladesh","BD","BGD","Rangpur","admin","294265","1050667437"
"Vigo","Vigo","42.2314","-8.7124","Spain","ES","ESP","Galicia","","293837","1724045676"
"Sikandarābād","Sikandarabad","28.4512","77.6955","India","IN","IND","Uttar Pradesh","","294858","1356989226"
"Battalgazi","Battalgazi","38.4228","38.3656","Turkey","TR","TUR","Malatya","minor","295821","1792965811"
"Bāli","Bali","22.6500","88.3400","India","IN","IND","West Bengal","","293373","1356113138"
"Çorum","Corum","40.5455","34.9570","Turkey","TR","TUR","Çorum","admin","294807","1792065200"
"Ismailia","Ismailia","30.5833","32.2667","Egypt","EG","EGY","Al Ismā‘īlīyah","admin","293184","1818532766"
"Vila Teixeira da Silva","Vila Teixeira da Silva","-12.0072","15.7792","Angola","AO","AGO","Huambo","","294494","1024863343"
"Pānīpat","Panipat","29.3875","76.9700","India","IN","IND","Haryāna","","294292","1356526780"
"Delmas","Delmas","18.5500","-72.3000","Haiti","HT","HTI","Ouest","","284079","1332257532"
"Strasbourg","Strasbourg","48.5833","7.7458","France","FR","FRA","Grand Est","admin","290576","1250789140"
"Mabalacat","Mabalacat","15.2167","120.5833","Philippines","PH","PHL","Pampanga","","293244","1608402560"
"Batna","Batna","35.5500","6.1667","Algeria","DZ","DZA","Batna","admin","290645","1012093229"
"Āīzawl","Aizawl","23.7272","92.7178","India","IN","IND","Mizoram","admin","293416","1356342768"
"Tambov","Tambov","52.7167","41.4333","Russia","RU","RUS","Tambovskaya Oblast’","admin","290365","1643802687"
"Dexing","Dexing","28.9580","117.7597","China","CN","CHN","Jiangxi","minor","293201","1156895348"
"Iaşi","Iasi","47.1622","27.5889","Romania","RO","ROU","Iaşi","admin","290422","1642367695"
"Kunp’o","Kunp'o","37.3500","126.9500","South Korea","KR","KOR","Gyeonggi","","285721","1410672540"
"San Lorenzo","San Lorenzo","-25.3400","-57.5200","Paraguay","PY","PRY","Central","","287977","1600667303"
"Santa Maria","Santa Maria","14.8183","120.9563","Philippines","PH","PHL","Bulacan","","289820","1608186208"
"Sonpur","Sonpur","25.7000","85.1800","India","IN","IND","Bihār","","288102","1356149014"
"Groznyy","Groznyy","43.3125","45.6986","Russia","RU","RUS","Chechnya","admin","291687","1643049853"
"Kherson","Kherson","46.6425","32.6250","Ukraine","UA","UKR","Khersonska Oblast","admin","291428","1804514036"
"Anchorage","Anchorage","61.1508","-149.1091","United States","US","USA","Alaska","","292545","1840023385"
"The Woodlands","The Woodlands","30.1738","-95.5134","United States","US","USA","Texas","","292380","1840019585"
"Hong’an","Hong'an","47.2100","123.6100","China","CN","CHN","Heilongjiang","minor","289999","1156999688"
"Resistencia","Resistencia","-27.4514","-58.9867","Argentina","AR","ARG","Chaco","admin","291720","1032720891"
"Graz","Graz","47.0708","15.4386","Austria","AT","AUT","Steiermark","admin","289440","1040184559"
"Karīmnagar","Karimnagar","18.4386","79.1288","India","IN","IND","Andhra Pradesh","","289821","1356578937"
"Braşov","Brasov","45.6667","25.6167","Romania","RO","ROU","Braşov","admin","290743","1642328429"
"Sumida","Sumida","35.7107","139.8015","Japan","JP","JPN","Tōkyō","","270823","1392075752"
"Sekondi","Sekondi","4.9433","-1.7040","Ghana","GH","GHA","Western","admin","286248","1288186231"
"South Bend","South Bend","41.6767","-86.2696","United States","US","USA","Indiana","","290915","1840009241"
"Morioka","Morioka","39.7021","141.1545","Japan","JP","JPN","Iwate","admin","290553","1392981957"
"Sonīpat","Sonipat","28.9900","77.0220","India","IN","IND","Haryāna","","289333","1356648808"
"Sétif","Setif","36.1900","5.4100","Algeria","DZ","DZA","Sétif","admin","288461","1012981116"
"Atyraū","Atyrau","47.1167","51.8833","Kazakhstan","KZ","KAZ","","admin","290700","1398169063"
"Ipswich","Ipswich","52.0594","1.1556","United Kingdom","GB","GBR","Suffolk","","290000","1826629486"
"Dewās","Dewas","22.9600","76.0600","India","IN","IND","Madhya Pradesh","","289550","1356065731"
"Hulin","Hulin","45.7671","132.9646","China","CN","CHN","Heilongjiang","minor","290000","1156161638"
"Rockford","Rockford","42.2596","-89.0640","United States","US","USA","Illinois","","289763","1840009132"
"Savannah","Savannah","32.0286","-81.1821","United States","US","USA","Georgia","","289607","1840015830"
"Itagüí","Itagui","6.1667","-75.6167","Colombia","CO","COL","Antioquia","minor","276744","1170708072"
"Croix-des-Bouquets","Croix-des-Bouquets","18.5761","-72.2269","Haiti","HT","HTI","Ouest","","284812","1332000065"
"Farg‘ona","Farg\`ona","40.3864","71.7864","Uzbekistan","UZ","UZB","Farg‘ona","admin","288850","1860479627"
"Magway","Magway","20.1500","94.9500","Myanmar","MM","MMR","Magway","admin","289247","1104246517"
"Huixquilucan","Huixquilucan","19.3611","-99.3508","Mexico","MX","MEX","México","minor","284965","1484187110"
"Lincoln","Lincoln","40.8099","-96.6784","United States","US","USA","Nebraska","admin","289136","1840009357"
"Ichalkaranji","Ichalkaranji","16.7000","74.4700","India","IN","IND","Mahārāshtra","","287570","1356651502"
"Punto Fijo","Punto Fijo","11.7167","-70.1833","Venezuela","VE","VEN","Falcón","minor","287558","1862535644"
"Várzea Grande","Varzea Grande","-15.6500","-56.1333","Brazil","BR","BRA","Mato Grosso","","287526","1076359879"
"Sincelejo","Sincelejo","9.2950","-75.3961","Colombia","CO","COL","Sucre","admin","286716","1170364198"
"Ibaraki","Ibaraki","34.8164","135.5686","Japan","JP","JPN","Ōsaka","","283835","1392003421"
"Katowice","Katowice","50.2667","19.0167","Poland","PL","POL","Śląskie","admin","285711","1616798495"
"Ar Ruşayfah","Ar Rusayfah","32.0178","36.0464","Jordan","JO","JOR","Az Zarqā’","minor","280000","1400369457"
"Camaçari","Camacari","-12.6978","-38.3239","Brazil","BR","BRA","Bahia","","286919","1076610862"
"Jersey City","Jersey City","40.7184","-74.0686","United States","US","USA","New Jersey","","287146","1840003600"
"San Cristóbal","San Cristobal","7.7682","-72.2322","Venezuela","VE","VEN","Táchira","admin","285872","1862252264"
"Round Lake Beach","Round Lake Beach","42.3791","-88.0811","United States","US","USA","Illinois","","287012","1840011171"
"Bolton","Bolton","53.5780","-2.4290","United Kingdom","GB","GBR","Bolton","","285372","1826628353"
"San Pablo","San Pablo","14.0700","121.3250","Philippines","PH","PHL","Laguna","","285348","1608801564"
"Suzano","Suzano","-23.5428","-46.3108","Brazil","BR","BRA","São Paulo","","285280","1076830201"
"Korhogo","Korhogo","9.4167","-5.6167","Côte d'Ivoire","CI","CIV","Savanes","admin","286071","1384205840"
"Bhatinda","Bhatinda","30.2300","74.9519","India","IN","IND","Punjab","","285813","1356343054"
"Cascavel","Cascavel","-24.9556","-53.4553","Brazil","BR","BRA","Paraná","","286205","1076509946"
"Flint","Flint","43.0236","-83.6921","United States","US","USA","Michigan","","286324","1840002949"
"Ostrava","Ostrava","49.8356","18.2925","Czechia","CZ","CZE","Moravskoslezský Kraj","admin","284982","1203687206"
"Tacna","Tacna","-18.0147","-70.2489","Peru","PE","PER","Tacna","admin","286240","1604388134"
"Shreveport","Shreveport","32.4653","-93.7955","United States","US","USA","Louisiana","","286200","1840015768"
"Al Qurnah","Al Qurnah","31.0158","47.4306","Iraq","IQ","IRQ","Al Başrah","minor","286073","1368380047"
"Jālna","Jalna","19.8410","75.8864","India","IN","IND","Mahārāshtra","","285577","1356730545"
"Aarhus","Aarhus","56.1572","10.2107","Denmark","DK","DNK","Midtjylland","minor","285273","1208841031"
"Constanţa","Constanta","44.1667","28.6333","Romania","RO","ROU","Constanţa","admin","283872","1642851858"
"San Juan Sacatepéquez","San Juan Sacatepequez","14.7189","-90.6442","Guatemala","GT","GTM","Guatemala","minor","284798","1320451247"
"Myeik","Myeik","12.4333","98.6000","Myanmar","MM","MMR","Tanintharyi","","284498","1104715655"
"Fukushima","Fukushima","37.7608","140.4747","Japan","JP","JPN","Fukushima","admin","284282","1392570027"
"Coro","Coro","11.3950","-69.6816","Venezuela","VE","VEN","Falcón","admin","284266","1862429150"
"Bago","Bago","17.3333","96.4833","Myanmar","MM","MMR","Bago","admin","284179","1104027443"
"Pasarkemis","Pasarkemis","-6.1703","106.5300","Indonesia","ID","IDN","Jawa Barat","","273659","1360028667"
"Fuquan","Fuquan","26.7039","107.5087","China","CN","CHN","Guizhou","","283904","1156672189"
"Tongchuanshi","Tongchuanshi","35.0800","109.0300","China","CN","CHN","Shaanxi","","282258","1156606433"
"Sannai","Sannai","24.5984","80.8281","India","IN","IND","Madhya Pradesh","","283004","1356009968"
"Huozhou","Huozhou","36.5726","111.7176","China","CN","CHN","Shanxi","","282905","1156917368"
"Temuco","Temuco","-38.7333","-72.6667","Chile","CL","CHL","Araucanía","admin","282415","1152318955"
"Ihosy","Ihosy","-22.4036","46.1258","Madagascar","MG","MDG","Fianarantsoa","minor","283047","1450600411"
"Sterlitamak","Sterlitamak","53.6333","55.9500","Russia","RU","RUS","Bashkortostan","","280233","1643812452"
"Tegal","Tegal","-6.8667","109.1333","Indonesia","ID","IDN","Jawa Tengah","","275789","1360035814"
"Ica","Ica","-14.0667","-75.7333","Peru","PE","PER","Ica","admin","282407","1604607907"
"Lucena","Lucena","13.9333","121.6167","Philippines","PH","PHL","Lucena","admin","278924","1608000461"
"Plano","Plano","33.0502","-96.7486","United States","US","USA","Texas","","282181","1840020662"
"Jining","Jining","41.0300","113.0800","China","CN","CHN","Inner Mongolia","","281716","1156261326"
"Chuncheon","Chuncheon","37.8667","127.7333","South Korea","KR","KOR","Gangwon","admin","281596","1410231130"
"Malārd","Malard","35.6658","50.9767","Iran","IR","IRN","Tehrān","minor","281027","1364376065"
"Pematangsiantar","Pematangsiantar","2.9600","99.0600","Indonesia","ID","IDN","Sumatera Utara","","278055","1360769496"
"Satna","Satna","24.6005","80.8322","India","IN","IND","Madhya Pradesh","","280222","1356008295"
"Long Xuyên","Long Xuyen","10.3736","105.4458","Vietnam","VN","VNM","An Giang","admin","278658","1704453892"
"Myrtle Beach","Myrtle Beach","33.7094","-78.8844","United States","US","USA","South Carolina","","281180","1840014717"
"Governador Valadares","Governador Valadares","-18.8500","-41.9333","Brazil","BR","BRA","Minas Gerais","","281046","1076271003"
"Petrozavodsk","Petrozavodsk","61.7833","34.3500","Russia","RU","RUS","Kareliya","admin","278551","1643319966"
"Santa Ana","Santa Ana","13.9950","-89.5561","El Salvador","SV","SLV","Santa Ana","admin","280000","1222021362"
"Mau","Mau","25.9417","83.5611","India","IN","IND","Uttar Pradesh","","278745","1356238859"
"Companiganj","Companiganj","22.8750","91.2833","Bangladesh","BD","BGD","Chattogram","","279870","1050000075"
"Wiesbaden","Wiesbaden","50.0825","8.2400","Germany","DE","DEU","Hesse","admin","278950","1276652118"
"Mingaora","Mingaora","34.7717","72.3600","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","279914","1586782526"
"Davenport","Davenport","41.5565","-90.6053","United States","US","USA","Iowa","","280282","1840007081"
"Bukhara","Bukhara","39.7667","64.4333","Uzbekistan","UZ","UZB","Buxoro","admin","280187","1860523678"
"Sumbe","Sumbe","-11.2053","13.8417","Angola","AO","AGO","Cuanza-Sul","admin","279968","1024297937"
"Việt Trì","Viet Tri","21.3000","105.4333","Vietnam","VN","VNM","Phú Thọ","admin","277539","1704332956"
"Sunderland","Sunderland","54.9060","-1.3810","United Kingdom","GB","GBR","Sunderland","","277417","1826736387"
"Bārāsat","Barasat","22.7200","88.4800","India","IN","IND","West Bengal","","278435","1356644721"
"Cherkasy","Cherkasy","49.4444","32.0597","Ukraine","UA","UKR","Cherkaska Oblast","admin","276360","1804459212"
"Maastricht","Maastricht","50.8500","5.6833","Netherlands","NL","NLD","Limburg","admin","277721","1528563623"
"Çorlu","Corlu","41.1500","27.8000","Turkey","TR","TUR","Tekirdağ","minor","279251","1792855069"
"Kostroma","Kostroma","57.7681","40.9269","Russia","RU","RUS","Kostromskaya Oblast’","admin","277648","1643869304"
"Gyeongsan","Gyeongsan","35.8167","128.7333","South Korea","KR","KOR","Gyeongbuk","","278500","1410545168"
"Ciego de Ávila","Ciego de Avila","21.8481","-78.7631","Cuba","CU","CUB","Ciego de Ávila","admin","278672","1192302618"
"Mamuju","Mamuju","-2.6833","118.9000","Indonesia","ID","IDN","Sulawesi Barat","admin","278764","1360687242"
"Los Alcarrizos","Los Alcarrizos","18.5167","-70.0167","Dominican Republic","DO","DOM","Ozama","","272776","1214807042"
"Nizhnevartovsk","Nizhnevartovsk","60.9500","76.6000","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","278725","1643786132"
"San Mateo","San Mateo","14.6969","121.1219","Philippines","PH","PHL","Rizal","","273306","1608557779"
"Shaowu","Shaowu","27.3417","117.4869","China","CN","CHN","Fujian","minor","278000","1156978131"
"Canton","Canton","40.8078","-81.3676","United States","US","USA","Ohio","","277741","1840000963"
"Sfax","Sfax","34.7400","10.7600","Tunisia","TN","TUN","Sfax","admin","272801","1788741658"
"Dinājpur","Dinajpur","25.6167","88.6500","Bangladesh","BD","BGD","Rangpur","","276000","1050817445"
"Dire Dawa","Dire Dawa","9.6000","41.8667","Ethiopia","ET","ETH","Dirē Dawa","admin","277000","1231089714"
"Gagnoa","Gagnoa","6.1333","-5.9333","Côte d'Ivoire","CI","CIV","Gôh-Djiboua","admin","277044","1384138010"
"Khmelnytskyi","Khmelnytskyi","49.4167","27.0000","Ukraine","UA","UKR","Khmelnytska Oblast","admin","274176","1804347108"
"Farrukhābād","Farrukhabad","27.3900","79.5810","India","IN","IND","Uttar Pradesh","","275754","1356776384"
"Lashkar Gāh","Lashkar Gah","31.5831","64.3692","Afghanistan","AF","AFG","Helmand","admin","276831","1004765445"
"Posadas","Posadas","-27.3667","-55.9000","Argentina","AR","ARG","Misiones","admin","275988","1032970696"
"Gatineau","Gatineau","45.4833","-75.6500","Canada","CA","CAN","Quebec","","276245","1124722129"
"Windsor","Windsor","42.2833","-83.0000","Canada","CA","CAN","Ontario","","276165","1124261024"
"Santa Clarita","Santa Clarita","34.4175","-118.4964","United States","US","USA","California","","275230","1840021864"
"Kunsan","Kunsan","35.9833","126.7167","South Korea","KR","KOR","Jeonbuk","","275155","1410913202"
"Minato","Minato","35.6580","139.7515","Japan","JP","JPN","Tōkyō","","260486","1392294093"
"Qarshi","Qarshi","38.8667","65.8000","Uzbekistan","UZ","UZB","Qashqadaryo","admin","274891","1860343319"
"Chula Vista","Chula Vista","32.6281","-117.0144","United States","US","USA","California","","274818","1840019350"
"Saugor","Saugor","23.8300","78.7100","India","IN","IND","Madhya Pradesh","","274556","1356781800"
"Kafr ad Dawwār","Kafr ad Dawwar","31.1311","30.1300","Egypt","EG","EGY","Al Buḩayrah","","262748","1818347704"
"Ratlām","Ratlam","23.3340","75.0370","India","IN","IND","Madhya Pradesh","","273892","1356241663"
"Yeosu","Yeosu","34.7607","127.6622","South Korea","KR","KOR","Jeonnam","minor","273761","1410698161"
"Crato","Crato","-7.4639","-63.0400","Brazil","BR","BRA","Amazonas","","273883","1076425706"
"Mỹ Tho","My Tho","10.3500","106.3500","Vietnam","VN","VNM","Tiền Giang","admin","270700","1704000376"
"Volta Redonda","Volta Redonda","-22.5228","-44.1039","Brazil","BR","BRA","Rio de Janeiro","","273988","1076343513"
"Eugene","Eugene","44.0564","-123.1174","United States","US","USA","Oregon","","273904","1840020007"
"Tsu","Tsu","34.7184","136.5057","Japan","JP","JPN","Mie","admin","273267","1392082102"
"Novorossiysk","Novorossiysk","44.7167","37.7500","Russia","RU","RUS","Krasnodarskiy Kray","","273278","1643087180"
"Bijiao","Bijiao","22.9311","113.2018","China","CN","CHN","Guangdong","","270310","1156211724"
"Székesfehérvár","Szekesfehervar","47.1956","18.4089","Hungary","HU","HUN","Fejér","admin","272474","1348968301"
"Soubré","Soubre","5.7833","-6.6000","Côte d'Ivoire","CI","CIV","Bas-Sassandra","minor","272773","1384745470"
"Palmas","Palmas","-10.1844","-48.3336","Brazil","BR","BRA","Tocantins","admin","272726","1076052802"
"Majene","Majene","-3.5403","118.9707","Indonesia","ID","IDN","Sulawesi Barat","minor","272377","1360498576"
"Sorong","Sorong","-0.8667","131.2500","Indonesia","ID","IDN","Papua Barat","","272349","1360018780"
"Chandler","Chandler","33.2825","-111.8514","United States","US","USA","Arizona","","272439","1840019309"
"Craiova","Craiova","44.3333","23.8167","Romania","RO","ROU","Dolj","admin","269506","1642859458"
"Thái Bình","Thai Binh","20.4461","106.3422","Vietnam","VN","VNM","Thái Bình","admin","268167","1704716027"
"Binjai","Binjai","3.5986","98.4803","Indonesia","ID","IDN","Sumatera Utara","","269053","1360884608"
"Fuchū","Fuchu","35.6689","139.4776","Japan","JP","JPN","Tōkyō","","263093","1392370709"
"Gabela","Gabela","-10.8500","14.3667","Angola","AO","AGO","Cuanza-Sul","","271843","1024159015"
"Sakarya","Sakarya","40.7833","30.4000","Turkey","TR","TUR","Sakarya","admin","271515","1792191587"
"Dayr az Zawr","Dayr az Zawr","35.3333","40.1500","Syria","SY","SYR","Dayr az Zawr","admin","271800","1760792603"
"Brāhmanbāria","Brahmanbaria","23.9656","91.1080","Bangladesh","BD","BGD","Chattogram","","268279","1050193121"
"Mito","Mito","36.3658","140.4712","Japan","JP","JPN","Ibaraki","admin","270445","1392665227"
"Sartā","Sarta","36.4491","53.3234","Iran","IR","IRN","Māzandarān","","271467","1364143380"
"Plymouth","Plymouth","50.3714","-4.1422","United Kingdom","GB","GBR","Plymouth","","267918","1826947183"
"Santo Domingo de los Colorados","Santo Domingo de los Colorados","-0.2542","-79.1719","Ecuador","EC","ECU","Santo Domingo de los Tsáchilas","admin","270875","1218148017"
"Arnavutköy","Arnavutkoy","41.1856","28.7406","Turkey","TR","TUR","İstanbul","","270549","1792049367"
"Nguru","Nguru","12.8792","10.4525","Nigeria","NG","NGA","Yobe","minor","270632","1566737779"
"Maradi","Maradi","13.4833","7.1000","Niger","NE","NER","Maradi","admin","267249","1562200314"
"Gombe","Gombe","10.2904","11.1700","Nigeria","NG","NGA","Gombe","admin","270366","1566855445"
"Gijón","Gijon","43.5333","-5.7000","Spain","ES","ESP","Asturias","","268896","1724170659"
"Drug","Drug","21.1900","81.2800","India","IN","IND","Chhattīsgarh","","268806","1356910418"
"Floridablanca","Floridablanca","7.2167","-73.0667","Colombia","CO","COL","Santander","minor","267170","1170331200"
"Handwāra","Handwara","34.4000","74.2800","India","IN","IND","Jammu and Kashmīr","","269311","1356080179"
"Quilmes","Quilmes","-34.7167","-58.2667","Argentina","AR","ARG","Buenos Aires","minor","262379","1032880202"
"Talisay","Talisay","10.2500","123.8333","Philippines","PH","PHL","Cebu","","263048","1608040297"
"Kunduz","Kunduz","36.7286","68.8681","Afghanistan","AF","AFG","Kunduz","admin","268893","1004227517"
"Yoshkar-Ola","Yoshkar-Ola","56.6328","47.8958","Russia","RU","RUS","Mariy-El","admin","266675","1643701904"
"Nalchik","Nalchik","43.4833","43.6167","Russia","RU","RUS","Kabardino-Balkariya","admin","265162","1643746064"
"Aswān","Aswan","24.0889","32.8997","Egypt","EG","EGY","Aswān","admin","267913","1818262512"
"Ichihara","Ichihara","35.4981","140.1154","Japan","JP","JPN","Chiba","","268038","1392167256"
"Salem","Salem","44.9233","-123.0244","United States","US","USA","Oregon","admin","268634","1840021224"
"Zhytomyr","Zhytomyr","50.2500","28.6667","Ukraine","UA","UKR","Zhytomyrska Oblast","admin","264452","1804540656"
"Imphāl","Imphal","24.8074","93.9384","India","IN","IND","Manipur","admin","264986","1356107172"
"İnegöl","Inegol","40.0806","29.5097","Turkey","TR","TUR","Bursa","minor","268155","1792592205"
"Chernivtsi","Chernivtsi","48.3000","25.9333","Ukraine","UA","UKR","Chernivetska Oblast","admin","266550","1804652872"
"Tarija","Tarija","-21.5333","-64.7333","Bolivia","BO","BOL","Tarija","admin","268000","1068356085"
"Siddhirganj","Siddhirganj","23.6833","90.5167","Bangladesh","BD","BGD","Dhaka","","256760","1050651716"
"Bimo","Bimo","4.2558","18.4042","Central African Republic","CF","CAF","Ombella-Mpoko","","267859","1140873996"
"Heroica Nogales","Heroica Nogales","31.3186","-110.9458","Mexico","MX","MEX","Sonora","minor","261137","1484364591"
"Bergen","Bergen","60.3894","5.3300","Norway","NO","NOR","Vestland","admin","267117","1578465701"
"Sumaré","Sumare","-22.8219","-47.2669","Brazil","BR","BRA","São Paulo","","265955","1076684312"
"Feni","Feni","23.0183","91.4100","Bangladesh","BD","BGD","Chattogram","","265000","1050761871"
"Sumy","Sumy","50.9167","34.7500","Ukraine","UA","UKR","Sumska Oblast","admin","265758","1804489982"
"Anantapur","Anantapur","14.6800","77.5990","India","IN","IND","Andhra Pradesh","","267161","1356296545"
"Westminster","Westminster","51.4947","-0.1353","United Kingdom","GB","GBR","Westminster","","255324","1826759011"
"Xiangkhoang","Xiangkhoang","19.4167","103.5000","Laos","LA","LAO","Xiangkhouang","","267172","1418829021"
"Marabá","Maraba","-5.3500","-49.1167","Brazil","BR","BRA","Pará","","266932","1076974144"
"Kütahya","Kutahya","39.4167","29.9833","Turkey","TR","TUR","Kütahya","admin","266784","1792671735"
"Gent","Gent","51.0536","3.7253","Belgium","BE","BEL","Flanders","minor","265086","1056062897"
"Yao","Yao","34.6269","135.6010","Japan","JP","JPN","Nara","","264913","1392943074"
"Djelfa","Djelfa","34.6667","3.2500","Algeria","DZ","DZA","Djelfa","admin","265833","1012268984"
"Barueri","Barueri","-23.5111","-46.8764","Brazil","BR","BRA","São Paulo","","262275","1076211114"
"Bordeaux","Bordeaux","44.8400","-0.5800","France","FR","FRA","Nouvelle-Aquitaine","admin","260958","1250449238"
"Nagaoka","Nagaoka","37.4462","138.8512","Japan","JP","JPN","Niigata","","265868","1392003404"
"Suncheon","Suncheon","34.9506","127.4875","South Korea","KR","KOR","Jeonnam","minor","265390","1410109654"
"Jalālābād","Jalalabad","34.4342","70.4478","Afghanistan","AF","AFG","Nangarhār","admin","263312","1004315012"
"Engels","Engels","51.5017","46.1222","Russia","RU","RUS","Saratovskaya Oblast’","","264990","1643653861"
"Malolos","Malolos","14.8433","120.8114","Philippines","PH","PHL","Bulacan","admin","261189","1608358783"
"Nogales","Nogales","31.1833","-111.0000","Mexico","MX","MEX","Sonora","","264782","1484793569"
"Dezfūl","Dezful","32.3825","48.4019","Iran","IR","IRN","Khūzestān","minor","264709","1364663520"
"Ed Daein","Ed Daein","11.4608","26.1283","Sudan","SD","SDN","East Darfur","admin","264734","1729512354"
"Luque","Luque","-25.2700","-57.4872","Paraguay","PY","PRY","Central","","263604","1600773205"
"Potosí","Potosi","-19.5892","-65.7533","Bolivia","BO","BOL","Potosí","admin","264402","1068933987"
"Concord","Concord","35.3933","-80.6369","United States","US","USA","North Carolina","","264492","1840014559"
"São José dos Pinhais","Sao Jose dos Pinhais","-25.5350","-49.2058","Brazil","BR","BRA","Paraná","","264210","1076174955"
"Osmaniye","Osmaniye","37.0750","36.2500","Turkey","TR","TUR","Osmaniye","admin","264373","1792340207"
"Arrah","Arrah","25.5514","84.6603","India","IN","IND","Bihār","","261430","1356085605"
"Kyŏngju","Kyongju","35.8500","129.2167","South Korea","KR","KOR","Gyeongbuk","","264091","1410173146"
"Dumai","Dumai","1.6667","101.4500","Indonesia","ID","IDN","Riau","","264084","1360153292"
"Tāluqān","Taluqan","36.7167","69.5167","Afghanistan","AF","AFG","Takhār","admin","263800","1004687194"
"Damanhūr","Damanhur","31.0361","30.4694","Egypt","EG","EGY","Al Buḩayrah","admin","244043","1818708838"
"El Fasher","El Fasher","13.6306","25.3500","Sudan","SD","SDN","North Darfur","admin","263243","1729372385"
"Malkājgiri","Malkajgiri","17.4519","78.5362","India","IN","IND","Andhra Pradesh","","255899","1356092535"
"Nawabshah","Nawabshah","26.2442","68.4100","Pakistan","PK","PAK","Sindh","minor","263102","1586294005"
"Tchibota","Tchibota","-4.1794","11.3731","Congo (Brazzaville)","CG","COG","Kouilou","","262931","1178731596"
"Annaba","Annaba","36.9000","7.7667","Algeria","DZ","DZA","Annaba","admin","257359","1012600001"
"Gelsenkirchen","Gelsenkirchen","51.5167","7.1000","Germany","DE","DEU","North Rhine-Westphalia","minor","260126","1276788537"
"Mönchengladbach","Monchengladbach","51.2000","6.4333","Germany","DE","DEU","North Rhine-Westphalia","minor","261001","1276982173"
"Kakogawachō-honmachi","Kakogawacho-honmachi","34.7500","134.8333","Japan","JP","JPN","Hyōgo","","260595","1392189230"
"Wollongong","Wollongong","-34.4331","150.8831","Australia","AU","AUS","New South Wales","","261896","1036502269"
"Gilbert","Gilbert","33.3100","-111.7463","United States","US","USA","Arizona","","262249","1840021947"
"Nonthaburi","Nonthaburi","13.8667","100.5167","Thailand","TH","THA","Nonthaburi","admin","255671","1764667935"
"Columbus","Columbus","32.5100","-84.8771","United States","US","USA","Georgia","","262061","1840014887"
"Marilao","Marilao","14.7581","120.9481","Philippines","PH","PHL","Bulacan","","254453","1608371779"
"Fukui","Fukui","36.0641","136.2196","Japan","JP","JPN","Fukui","admin","261474","1392897510"
"Venice","Venice","45.4397","12.3319","Italy","IT","ITA","Veneto","admin","261321","1380660414"
"Boksburg","Boksburg","-26.2125","28.2625","South Africa","ZA","ZAF","Gauteng","","260321","1710932183"
"Quảng Ngãi","Quang Ngai","15.1167","108.8000","Vietnam","VN","VNM","Quảng Ngãi","admin","260252","1704029499"
"Olongapo","Olongapo","14.8300","120.2800","Philippines","PH","PHL","Olongapo","admin","260317","1608001640"
"Ciudad Benito Juárez","Ciudad Benito Juarez","25.6500","-100.0833","Mexico","MX","MEX","Nuevo León","minor","260683","1484140727"
"Hiratsuka","Hiratsuka","35.3167","139.3500","Japan","JP","JPN","Kanagawa","","257662","1392311050"
"Tiruvottiyūr","Tiruvottiyur","13.1600","80.3000","India","IN","IND","Tamil Nādu","","249446","1356964110"
"Oumé","Oume","6.3833","-5.4167","Côte d'Ivoire","CI","CIV","Gôh-Djiboua","minor","260786","1384920146"
"Sōka","Soka","35.8254","139.8053","Japan","JP","JPN","Saitama","","251390","1392354483"
"Bunkyō-ku","Bunkyo-ku","35.7080","139.7523","Japan","JP","JPN","Tōkyō","","240069","1392003059"
"Aguadilla","Aguadilla","18.4382","-67.1537","Puerto Rico","PR","PRI","Puerto Rico","","260331","1630035605"
"Al Ḩamzah","Al Hamzah","31.7339","45.0086","Iraq","IQ","IRQ","Al Qādisīyah","minor","260000","1368300397"
"Lubbock","Lubbock","33.5657","-101.8879","United States","US","USA","Texas","","259946","1840020604"
"Mossoró","Mossoro","-5.1878","-37.3439","Brazil","BR","BRA","Rio Grande do Norte","","259815","1076461194"
"Kotri","Kotri","25.3660","68.3122","Pakistan","PK","PAK","Sindh","","259358","1586308347"
"North Las Vegas","North Las Vegas","36.2883","-115.0888","United States","US","USA","Nevada","","259638","1840020363"
"Tuzla","Tuzla","40.8161","29.3006","Turkey","TR","TUR","İstanbul","","255468","1792174991"
"Singa","Singa","13.1500","33.9333","Sudan","SD","SDN","Sennar","admin","259000","1729951108"
"Mişrātah","Misratah","32.3775","15.0920","Libya","LY","LBY","Mişrātah","admin","259056","1434518407"
"Isparta","Isparta","37.7647","30.5567","Turkey","TR","TUR","Isparta","admin","258375","1792105249"
"Derby","Derby","52.9217","-1.4767","United Kingdom","GB","GBR","Derby","","255394","1826333435"
"Verona","Verona","45.4386","10.9928","Italy","IT","ITA","Veneto","minor","257275","1380324879"
"Huayin","Huayin","34.5664","110.0866","China","CN","CHN","Shaanxi","","258113","1156156462"
"Siverek","Siverek","37.7500","39.3167","Turkey","TR","TUR","Şanlıurfa","minor","258265","1792479307"
"St. Petersburg","St. Petersburg","27.7931","-82.6652","United States","US","USA","Florida","","258245","1840015977"
"Ndulo","Ndulo","-11.4833","15.8333","Angola","AO","AGO","Huambo","","258161","1024783572"
"Rāniyah","Raniyah","36.2550","44.8836","Iraq","IQ","IRQ","As Sulaymānīyah","minor","257577","1368214852"
"Germiston","Germiston","-26.2178","28.1672","South Africa","ZA","ZAF","Gauteng","","255863","1710366382"
"Tallahassee","Tallahassee","30.4551","-84.2527","United States","US","USA","Florida","admin","257648","1840015913"
"Mount Lavinia","Mount Lavinia","6.8731","79.8758","Sri Lanka","LK","LKA","Western","","245974","1144684385"
"Etāwah","Etawah","26.7700","79.0300","India","IN","IND","Uttar Pradesh","","256838","1356241312"
"Horlivka","Horlivka","48.3336","38.0925","Ukraine","UA","UKR","Donetska Oblast","minor","256714","1804235146"
"Antsirabe","Antsirabe","-19.8667","47.0333","Madagascar","MG","MDG","Antananarivo","minor","257163","1450791699"
"Ondo","Ondo","7.0833","4.8333","Nigeria","NG","NGA","Ondo","minor","257005","1566993724"
"Indaiatuba","Indaiatuba","-23.0903","-47.2181","Brazil","BR","BRA","São Paulo","","256223","1076347789"
"Puducherry","Puducherry","11.9167","79.8167","India","IN","IND","Puducherry","admin","244377","1356368903"
"Laredo","Laredo","27.5625","-99.4874","United States","US","USA","Texas","","256748","1840021002"
"Ấp Đa Lợi","Ap Da Loi","11.9333","108.4667","Vietnam","VN","VNM","Lâm Đồng","","256019","1704613422"
"Foz do Iguaçu","Foz do Iguacu","-25.5400","-54.5875","Brazil","BR","BRA","Paraná","","256088","1076025805"
"Khimki","Khimki","55.8892","37.4450","Russia","RU","RUS","Moskva","","254171","1643601857"
"Sab‘ al Būr","Sab\` al Bur","33.4644","44.1536","Iraq","IQ","IRQ","Baghdād","","254000","1368355296"
"Gravataí","Gravatai","-29.9333","-50.9833","Brazil","BR","BRA","Rio Grande do Sul","","255660","1076919841"
"Chiayi","Chiayi","23.4800","120.4497","Taiwan","TW","TWN","Chiayi","admin","251938","1158867217"
"Kediri","Kediri","-7.8111","112.0047","Indonesia","ID","IDN","Jawa Timur","","252000","1360498846"
"Damaturu","Damaturu","11.7444","11.9611","Nigeria","NG","NGA","Yobe","admin","255895","1566897107"
"Tokushima","Tokushima","34.0703","134.5549","Japan","JP","JPN","Tokushima","admin","254510","1392795984"
"Arua","Arua","3.0353","30.9108","Uganda","UG","UGA","Arua","admin","250000","1800812511"
"Peoria","Peoria","40.7520","-89.6154","United States","US","USA","Illinois","","255718","1840009373"
"Turmero","Turmero","10.2283","-67.4753","Venezuela","VE","VEN","Aragua","minor","254880","1862335549"
"Pointe-à-Pitre","Pointe-a-Pitre","16.2411","-61.5331","Guadeloupe","GP","GLP","","","250952","1312460524"
"Kuala Terengganu","Kuala Terengganu","5.3292","103.1361","Malaysia","MY","MYS","Terengganu","admin","255109","1458475563"
"Campeche","Campeche","19.8500","-90.5306","Mexico","MX","MEX","Campeche","admin","249623","1484431512"
"Byatarayanpur","Byatarayanpur","13.0659","77.5922","India","IN","IND","Karnātaka","","239902","1356590852"
"Mawlamyine","Mawlamyine","16.4847","97.6258","Myanmar","MM","MMR","Mon State","admin","253734","1104530702"
"Godomè","Godome","6.3667","2.3500","Benin","BJ","BEN","Atlantique","","253262","1204027999"
"San Pedro Carchá","San Pedro Carcha","15.4768","-90.3120","Guatemala","GT","GTM","Alta Verapaz","minor","255006","1320220558"
"Moçâmedes","Mocamedes","-15.1953","12.1508","Angola","AO","AGO","Namibe","admin","255000","1024785067"
"La Paz","La Paz","24.1422","-110.3108","Mexico","MX","MEX","Baja California Sur","admin","250141","1484005187"
"Central District","Central District","22.2867","114.1550","Hong Kong","HK","HKG","","","235953","1344000057"
"São Carlos","Sao Carlos","-22.0000","-47.8908","Brazil","BR","BRA","São Paulo","","254484","1076673445"
"Vitoria-Gasteiz","Vitoria-Gasteiz","42.8500","-2.6833","Spain","ES","ESP","Basque Country","admin","253672","1724975338"
"Wolverhampton","Wolverhampton","52.5833","-2.1333","United Kingdom","GB","GBR","Wolverhampton","","250970","1826636841"
"Avondale","Avondale","33.3873","-112.3235","United States","US","USA","Arizona","","254537","1840019310"
"Lafayette","Lafayette","30.2082","-92.0325","United States","US","USA","Louisiana","","254286","1840015044"
"Ash Shaţrah","Ash Shatrah","31.4097","46.1717","Iraq","IQ","IRQ","Dhī Qār","minor","254000","1368920164"
"Irving","Irving","32.8583","-96.9702","United States","US","USA","Texas","","254184","1840019438"
"Rishon LeẔiyyon","Rishon LeZiyyon","31.95","34.8","Israel","IL","ISR","Central","","249860","1376642268"
"Montgomery","Montgomery","32.3482","-86.2668","United States","US","USA","Alabama","admin","253711","1840008353"
"Çekme","Cekme","41.0369","29.1786","Turkey","TR","TUR","İstanbul","","251937","1792015771"
"Jhenida","Jhenida","23.5417","89.1833","Bangladesh","BD","BGD","Khulna","","252500","1050491250"
"Bharatpur","Bharatpur","27.2200","77.4800","India","IN","IND","Rājasthān","","252342","1356168278"
"Gadda Madiral","Gadda Madiral","18.7519","79.5133","India","IN","IND","Andhra Pradesh","","252693","1356055274"
"Shinozaki","Shinozaki","33.9500","130.9333","Japan","JP","JPN","Fukuoka","","252844","1392516557"
"Goma","Goma","-1.6794","29.2336","Congo (Kinshasa)","CD","COD","Nord-Kivu","admin","249862","1180805022"
"Tacloban","Tacloban","11.2444","125.0039","Philippines","PH","PHL","Tacloban","admin","251881","1608710592"
"Turku","Turku","60.4500","22.2667","Finland","FI","FIN","Varsinais-Suomi","admin","252468","1246456609"
"Rạch Giá","Rach Gia","10.0167","105.0833","Vietnam","VN","VNM","Kiến Giang","admin","250660","1704872008"
"Kanggye","Kanggye","40.9667","126.6000","North Korea","KP","PRK","Chagang","admin","251971","1408728939"
"Taganrog","Taganrog","47.2167","38.9167","Russia","RU","RUS","Rostovskaya Oblast’","","250287","1643228316"
"Kızıltepe","Kiziltepe","37.1939","40.5861","Turkey","TR","TUR","Mardin","minor","252656","1792229490"
"El Geneina","El Geneina","13.4500","22.4500","Sudan","SD","SDN","West Darfur","admin","252744","1729642412"
"Las Condes","Las Condes","-33.4117","-70.5500","Chile","CL","CHL","Región Metropolitana","","249893","1152858037"
"Hakodate","Hakodate","41.7686","140.7289","Japan","JP","JPN","Hokkaidō","","251942","1392708558"
"Uşak","Usak","38.6778","29.4042","Turkey","TR","TUR","Uşak","admin","252044","1792360822"
"Jūnāgarh","Junagarh","19.8599","82.9338","India","IN","IND","Chhattīsgarh","","251842","1356057455"
"Begusarai","Begusarai","25.4200","86.1300","India","IN","IND","Bihār","","252008","1356619346"
"São José","Sao Jose","-27.6000","-48.6167","Brazil","BR","BRA","Santa Catarina","","250181","1076689151"
"A Coruña","A Coruna","43.3667","-8.3833","Spain","ES","ESP","Galicia","minor","245468","1724417375"
"Los Teques","Los Teques","10.3411","-67.0406","Venezuela","VE","VEN","Miranda","admin","251200","1862066931"
"Jinshi","Jinshi","29.6334","111.8746","China","CN","CHN","Hunan","minor","251064","1156062960"
"Chōfugaoka","Chofugaoka","35.6506","139.5407","Japan","JP","JPN","Tōkyō","","240359","1392692794"
"Tsing Yi Town","Tsing Yi Town","22.3456","114.1000","Hong Kong","HK","HKG","","","191500","1344057832"
"Higüey","Higuey","18.6167","-68.7000","Dominican Republic","DO","DOM","Yuma","minor","251243","1214313712"
"Juazeiro do Norte","Juazeiro do Norte","-7.2000","-39.3333","Brazil","BR","BRA","Ceará","","249939","1076912078"
"Bata","Bata","1.8650","9.7700","Equatorial Guinea","GQ","GNQ","Litoral","admin","250770","1226528087"
"Al Minyā","Al Minya","28.1194","30.7444","Egypt","EG","EGY","Al Minyā","admin","236043","1818607968"
"Aachen","Aachen","50.7756","6.0836","Germany","DE","DEU","North Rhine-Westphalia","minor","249070","1276805572"
"Bābol","Babol","36.5514","52.6789","Iran","IR","IRN","Māzandarān","minor","250217","1364476070"
"Komsomol’sk-na-Amure","Komsomol'sk-na-Amure","50.5667","137.0000","Russia","RU","RUS","Khabarovskiy Kray","minor","249810","1643862307"
"Iwo","Iwo","7.6333","4.1833","Nigeria","NG","NGA","Osun","minor","250443","1566462707"
"Rivne","Rivne","50.6192","26.2519","Ukraine","UA","UKR","Rivnenska Oblast","admin","246574","1804144712"
"Galaţi","Galati","45.4233","28.0425","Romania","RO","ROU","Galaţi","admin","249432","1642571620"
"Natogyi","Natogyi","21.4167","95.6500","Myanmar","MM","MMR","Mandalay","","250000","1104249540"
"Braunschweig","Braunschweig","52.2667","10.5167","Germany","DE","DEU","Lower Saxony","minor","248823","1276941443"
"Kalār","Kalar","34.6292","45.3222","Iraq","IQ","IRQ","As Sulaymānīyah","minor","250000","1368403789"
"Al Bayḑā’","Al Bayda'","32.7664","21.7417","Libya","LY","LBY","Al Jabal al Akhḑar","admin","250000","1434263017"
"Yanbu","Yanbu","24.0883","38.0582","Saudi Arabia","SA","SAU","Al Madīnah al Munawwarah","","250000","1682000044"
"Kurmuk","Kurmuk","10.5500","34.2833","Sudan","SD","SDN","Blue Nile","","250000","1729180375"
"Shibuya-ku","Shibuya-ku","35.6637","139.6977","Japan","JP","JPN","Tōkyō","","235193","1392003273"
"Gāndhīdhām","Gandhidham","23.0800","70.1300","India","IN","IND","Gujarāt","","248705","1356140913"
"Manzhouli","Manzhouli","49.5881","117.4525","China","CN","CHN","Inner Mongolia","","249472","1156354568"
"Visalia","Visalia","36.3276","-119.3269","United States","US","USA","California","","249804","1840021639"
"Gdynia","Gdynia","54.5000","18.5333","Poland","PL","POL","Pomorskie","minor","247672","1616385189"
"Rāmnagar","Ramnagar","27.1700","84.3200","India","IN","IND","Bihār","","249102","1356673115"
"Palangkaraya","Palangkaraya","-2.2100","113.9200","Indonesia","ID","IDN","Kalimantan Tengah","admin","249434","1360268553"
"Chemnitz","Chemnitz","50.8333","12.9167","Germany","DE","DEU","Saxony","minor","248042","1276519956"
"Chigasaki","Chigasaki","35.3333","139.4000","Japan","JP","JPN","Kanagawa","","242347","1392346493"
"Büyükçekmece","Buyukcekmece","41.0200","28.5775","Turkey","TR","TUR","İstanbul","minor","247736","1792118443"
"Khomeynī Shahr","Khomeyni Shahr","32.7003","51.5211","Iran","IR","IRN","Eşfahān","minor","247128","1364403465"
"Carmen","Carmen","18.4167","-91.6333","Mexico","MX","MEX","Campeche","","248845","1484620016"
"McKinney","McKinney","33.2016","-96.6670","United States","US","USA","Texas","","248840","1840020657"
"Sahiwal","Sahiwal","30.6611","73.1083","Pakistan","PK","PAK","Punjab","minor","247706","1586672083"
"Qā’em Shahr","Qa'em Shahr","36.4631","52.8600","Iran","IR","IRN","Māzandarān","","247953","1364170406"
"Banikoara","Banikoara","11.3000","2.4333","Benin","BJ","BEN","Alibori","","248621","1204495680"
"Kiel","Kiel","54.3233","10.1394","Germany","DE","DEU","Schleswig-Holstein","admin","246601","1276305306"
"Sibu","Sibu","2.3000","111.8167","Malaysia","MY","MYS","Sarawak","","247995","1458970454"
"Yato","Yato","35.4833","139.4500","Japan","JP","JPN","Kanagawa","","239146","1392469032"
"Paraná","Parana","-31.7331","-60.5297","Argentina","AR","ARG","Entre Ríos","admin","247863","1032266693"
"Santa Clara","Santa Clara","22.4069","-79.9649","Cuba","CU","CUB","Villa Clara","admin","247436","1192329773"
"Yamagata","Yamagata","38.2554","140.3396","Japan","JP","JPN","Yamagata","admin","247234","1392300081"
"Katihār","Katihar","25.5300","87.5800","India","IN","IND","Bihār","","240565","1356099841"
"Beykoz","Beykoz","41.1342","29.0922","Turkey","TR","TUR","İstanbul","minor","246700","1792084939"
"Imperatriz","Imperatriz","-5.5333","-47.4833","Brazil","BR","BRA","Maranhão","","247505","1076486025"
"Oruro","Oruro","-17.9799","-67.1300","Bolivia","BO","BOL","Oruro","admin","246501","1068271533"
"Merlo","Merlo","-34.6653","-58.7275","Argentina","AR","ARG","Buenos Aires","minor","244168","1032232613"
"Tsukuba-kenkyūgakuen-toshi","Tsukuba-kenkyugakuen-toshi","36.0835","140.0764","Japan","JP","JPN","Ibaraki","","246647","1392112466"
"Wilmington","Wilmington","34.2099","-77.8866","United States","US","USA","North Carolina","","247372","1840015576"
"Kesbewa","Kesbewa","6.7953","79.9386","Sri Lanka","LK","LKA","Western","","243842","1144646065"
"Barnsley","Barnsley","53.5547","-1.4791","United Kingdom","GB","GBR","Barnsley","","245199","1826597621"
"Chesapeake","Chesapeake","36.6778","-76.3023","United States","US","USA","Virginia","","247172","1840003874"
"Syktyvkar","Syktyvkar","61.6667","50.8167","Russia","RU","RUS","Komi","admin","245313","1643573386"
"Győr","Gyor","47.6842","17.6344","Hungary","HU","HUN","Győr-Moson-Sopron","admin","246159","1348507878"
"Birāṭnagar","Biratnagar","26.4542","87.2797","Nepal","NP","NPL","Province Number One","admin","242548","1524520011"
"Hafizabad","Hafizabad","32.0714","73.6878","Pakistan","PK","PAK","Punjab","minor","245784","1586945951"
"Saskatoon","Saskatoon","52.1333","-106.6833","Canada","CA","CAN","Saskatchewan","","246376","1124612546"
"Krishnarājpur","Krishnarajpur","13.0120","77.6951","India","IN","IND","Karnātaka","","245797","1356007149"
"Abertawe","Abertawe","51.6167","-3.9500","United Kingdom","GB","GBR","Swansea","","245508","1826574888"
"Americana","Americana","-22.7386","-47.3311","Brazil","BR","BRA","São Paulo","","244270","1076922153"
"Mahajanga","Mahajanga","-15.7167","46.3167","Madagascar","MG","MDG","Mahajanga","admin","246022","1450421961"
"Long Khánh","Long Khanh","10.9170","107.1670","Vietnam","VN","VNM","Đồng Nai","minor","245040","1704475292"
"Nelamangala","Nelamangala","13.1020","77.3740","India","IN","IND","Karnātaka","","245624","1356943451"
"Jessore","Jessore","23.1704","89.2000","Bangladesh","BD","BGD","Khulna","","243987","1050244487"
"Beichengqu","Beichengqu","40.4348","113.1565","China","CN","CHN","Inner Mongolia","minor","245608","1156401666"
"Glendale","Glendale","33.5791","-112.2311","United States","US","USA","Arizona","","245685","1840020563"
"Tuy Hòa","Tuy Hoa","13.0819","109.2950","Vietnam","VN","VNM","Phú Yên","admin","242840","1704697424"
"Garland","Garland","32.9100","-96.6304","United States","US","USA","Texas","","245075","1840020707"
"Singrauliya","Singrauliya","24.1100","82.3900","India","IN","IND","Madhya Pradesh","","244450","1356985698"
"Fuji","Fuji","35.1613","138.6763","Japan","JP","JPN","Shizuoka","","243739","1392249213"
"Mokpo","Mokpo","34.7589","126.3800","South Korea","KR","KOR","Jeonnam","minor","239524","1410279439"
"Kabinda","Kabinda","-6.1300","24.4800","Congo (Kinshasa)","CD","COD","Lomami","admin","244000","1180751831"
"Tanga","Tanga","-5.0667","39.1000","Tanzania","TZ","TZA","Tanga","admin","243580","1834004568"
"Santa Maria","Santa Maria","-29.6833","-53.8000","Brazil","BR","BRA","Rio Grande do Sul","","243611","1076178738"
"Sabzevār","Sabzevar","36.2125","57.6819","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","243700","1364379130"
"Bānchpār","Banchpar","23.2000","90.8000","Bangladesh","BD","BGD","Chattogram","","241799","1050224894"
"Rāmgundam","Ramgundam","18.7639","79.4750","India","IN","IND","Andhra Pradesh","","242979","1356590864"
"Tarapoto","Tarapoto","-6.4833","-76.3667","Peru","PE","PER","San Martín","","240453","1604622202"
"Bahir Dar","Bahir Dar","11.6000","37.3833","Ethiopia","ET","ETH","Āmara","admin","243300","1231784118"
"Baruta","Baruta","10.4322","-66.8739","Venezuela","VE","VEN","Miranda","minor","240755","1862630716"
"Sapele","Sapele","5.8261","5.6536","Nigeria","NG","NGA","Delta","minor","242652","1566310893"
"Sasebo","Sasebo","33.1800","129.7150","Japan","JP","JPN","Nagasaki","","242664","1392003180"
"Myitkyina","Myitkyina","25.3833","97.4000","Myanmar","MM","MMR","Kachin State","admin","243031","1104327329"
"Pandharpur","Pandharpur","17.6778","75.3278","India","IN","IND","Mahārāshtra","","242515","1356852072"
"Sakākā","Sakaka","29.9697","40.2000","Saudi Arabia","SA","SAU","Al Jawf","admin","242813","1682232132"
"Haeju","Haeju","38.0333","125.7167","North Korea","KP","PRK","Hwangnam","admin","241599","1408348983"
"Petaẖ Tiqwa","Petah Tiqwa","32.0889","34.8864","Israel","IL","ISR","Central","","236169","1376362310"
"González Catán","Gonzalez Catan","-34.7667","-58.6167","Argentina","AR","ARG","Buenos Aires","","238067","1032883563"
"Masaurhi Buzurg","Masaurhi Buzurg","25.3500","85.0200","India","IN","IND","Bihār","","241216","1356191575"
"Qyzylorda","Qyzylorda","44.8500","65.5167","Kazakhstan","KZ","KAZ","","admin","242462","1398248334"
"Diaobingshancun","Diaobingshancun","42.4391","123.5426","China","CN","CHN","Liaoning","","241431","1156893347"
"Man","Man","7.4000","-7.5500","Côte d'Ivoire","CI","CIV","Montagnes","admin","241969","1384112524"
"Zhangping","Zhangping","25.2938","117.4148","China","CN","CHN","Fujian","minor","242000","1156352113"
"Dagana","Dagana","16.4833","-15.6000","Senegal","SN","SEN","Saint-Louis","","241695","1686270478"
"Bạc Liêu","Bac Lieu","9.2833","105.7167","Vietnam","VN","VNM","Bạc Liêu","admin","240045","1704298745"
"Kennewick","Kennewick","46.1978","-119.1732","United States","US","USA","Washington","","241230","1840018481"
"Lille","Lille","50.6278","3.0583","France","FR","FRA","Hauts-de-France","admin","234475","1250071961"
"Düzce","Duzce","40.8417","31.1583","Turkey","TR","TUR","Düzce","admin","240633","1792999306"
"Ipatinga","Ipatinga","-19.5000","-42.5333","Brazil","BR","BRA","Minas Gerais","","239468","1076484921"
"Anju","Anju","39.6200","125.6600","North Korea","KP","PRK","P’yŏngnam","","240117","1408625970"
"Marília","Marilia","-22.2139","-49.9458","Brazil","BR","BRA","São Paulo","","240590","1076042151"
"Winter Haven","Winter Haven","28.0118","-81.7014","United States","US","USA","Florida","","240768","1840015970"
"Monclova","Monclova","26.9103","-101.4222","Mexico","MX","MEX","Coahuila","minor","237169","1484281759"
"Stavanger","Stavanger","58.9700","5.7314","Norway","NO","NOR","Rogaland","admin","237369","1578801684"
"Abī al Khaşīb","Abi al Khasib","30.4411","47.9725","Iraq","IQ","IRQ","Al Başrah","minor","240300","1368259214"
"Atushi","Atushi","39.7162","76.1684","China","CN","CHN","Xinjiang","minor","240368","1156047949"
"Chimoio","Chimoio","-19.1167","33.4500","Mozambique","MZ","MOZ","Manica","admin","238976","1508460246"
"Limassol","Limassol","34.6747","33.0442","Cyprus","CY","CYP","Lemesós","admin","235056","1196136222"
"Ivano-Frankivsk","Ivano-Frankivsk","48.9228","24.7106","Ukraine","UA","UKR","Ivano-Frankivska Oblast","admin","238196","1804208152"
"Novo Hamburgo","Novo Hamburgo","-29.6778","-51.1308","Brazil","BR","BRA","Rio Grande do Sul","","238940","1076221386"
"Halle","Halle","51.4828","11.9697","Germany","DE","DEU","Saxony-Anhalt","minor","238061","1276167077"
"Arakawa","Arakawa","35.7361","139.7834","Japan","JP","JPN","Tōkyō","","217891","1392229262"
"Longueuil","Longueuil","45.5333","-73.5167","Canada","CA","CAN","Quebec","","239700","1124122753"
"Szeged","Szeged","46.2550","20.1450","Hungary","HU","HUN","Csongrád-Csanád","admin","239025","1348655615"
"Viamão","Viamao","-30.0808","-51.0228","Brazil","BR","BRA","Rio Grande do Sul","","239384","1076314777"
"Košice","Kosice","48.7167","21.2500","Slovakia","SK","SVK","Košice","admin","238593","1703452690"
"Matsumoto","Matsumoto","36.2380","137.9720","Japan","JP","JPN","Nagano","","239115","1392003338"
"Tuticorin","Tuticorin","8.7642","78.1348","India","IN","IND","Tamil Nādu","","237830","1356751023"
"Banda Aceh","Banda Aceh","5.5500","95.3175","Indonesia","ID","IDN","Aceh","admin","235305","1360353144"
"Shenmu","Shenmu","38.8270","110.4990","China","CN","CHN","Shaanxi","","237846","1156106796"
"Springs","Springs","-26.2547","28.4428","South Africa","ZA","ZAF","Gauteng","","236083","1710058382"
"Taiping","Taiping","4.8500","100.7333","Malaysia","MY","MYS","Perak","","237095","1458446120"
"Zinder","Zinder","13.8053","8.9883","Niger","NE","NER","Zinder","admin","235605","1562758258"
"Gangānagar","Ganganagar","29.9200","73.8800","India","IN","IND","Rājasthān","","237780","1356431302"
"As Sīb","As Sib","23.6703","58.1891","Oman","OM","OMN","Masqaţ","","237816","1512461161"
"P’yŏng-dong","P'yong-dong","39.2500","125.8500","North Korea","KP","PRK","P’yŏngyang","","236583","1408184982"
"Scottsdale","Scottsdale","33.6872","-111.8651","United States","US","USA","Arizona","","238685","1840021940"
"Āmol","Amol","36.4697","52.3508","Iran","IR","IRN","Māzandarān","minor","238528","1364107935"
"Norfolk","Norfolk","36.8945","-76.2590","United States","US","USA","Virginia","","238556","1840003869"
"Nyíregyháza","Nyiregyhaza","47.9531","21.7271","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","admin","238020","1348653343"
"Eindhoven","Eindhoven","51.4333","5.4833","Netherlands","NL","NLD","Noord-Brabant","minor","235691","1528636212"
"Killeen","Killeen","31.0753","-97.7297","United States","US","USA","Texas","","238288","1840020854"
"Wuyishan","Wuyishan","27.7562","118.0267","China","CN","CHN","Fujian","minor","238000","1156519625"
"Mirpur Khas","Mirpur Khas","25.5269","69.0111","Pakistan","PK","PAK","Sindh","minor","236961","1586156251"
"Sandnes","Sandnes","58.8517","5.7361","Norway","NO","NOR","Rogaland","minor","237369","1578525190"
"Beji","Beji","-6.3704","106.8176","Indonesia","ID","IDN","Jawa Barat","","222372","1360401131"
"Pathein","Pathein","16.7778","94.7369","Myanmar","MM","MMR","Ayeyarwady","admin","237089","1104832430"
"Nizhnekamsk","Nizhnekamsk","55.6333","51.8167","Russia","RU","RUS","Tatarstan","","237250","1643493938"
"Magdeburg","Magdeburg","52.1317","11.6392","Germany","DE","DEU","Saxony-Anhalt","admin","236188","1276418236"
"Neya","Neya","34.7667","135.6333","Japan","JP","JPN","Ōsaka","","227997","1392993634"
"Rewa","Rewa","24.5300","81.3000","India","IN","IND","Madhya Pradesh","","236519","1356642255"
"Chishui","Chishui","28.5902","105.6946","China","CN","CHN","Guizhou","","237052","1156325764"
"Centurion","Centurion","-25.8603","28.1894","South Africa","ZA","ZAF","Gauteng","","236580","1710138774"
"Uluberiya","Uluberiya","22.4700","88.1100","India","IN","IND","West Bengal","","235345","1356354610"
"Djougou","Djougou","9.7000","1.6667","Benin","BJ","BEN","Donga","admin","237040","1204972666"
"Pākdasht","Pakdasht","35.4817","51.6803","Iran","IR","IRN","Tehrān","minor","236319","1364370088"
"Shakhty","Shakhty","47.7000","40.2333","Russia","RU","RUS","Rostovskaya Oblast’","","235492","1643542365"
"North Hempstead","North Hempstead","40.7912","-73.6688","United States","US","USA","New York","","236910","1840087737"
"York","York","39.9651","-76.7315","United States","US","USA","Pennsylvania","","236818","1840001472"
"Atlantic City","Atlantic City","39.3797","-74.4527","United States","US","USA","New Jersey","","236314","1840003798"
"Ibb","Ibb","13.9759","44.1709","Yemen","YE","YEM","Ibb","","234837","1887036494"
"Elche","Elche","38.2669","-0.6983","Spain","ES","ESP","Valencia","","235580","1724611437"
"Abhā","Abha","18.2169","42.5053","Saudi Arabia","SA","SAU","‘Asīr","admin","236157","1682759151"
"Bulandshahr","Bulandshahr","28.4069","77.8497","India","IN","IND","Uttar Pradesh","","235310","1356067648"
"Groningen","Groningen","53.2167","6.5667","Netherlands","NL","NLD","Groningen","admin","233273","1528295161"
"Nashua","Nashua","42.7491","-71.4910","United States","US","USA","New Hampshire","","235982","1840002984"
"Jacareí","Jacarei","-23.3053","-45.9658","Brazil","BR","BRA","São Paulo","","235416","1076592769"
"Arlington","Arlington","38.8786","-77.1011","United States","US","USA","Virginia","","235764","1840006112"
"Brownsville","Brownsville","25.9975","-97.4580","United States","US","USA","Texas","","235735","1840019743"
"Bole","Bole","44.8539","82.0514","China","CN","CHN","Xinjiang","","235585","1156784761"
"Ganda","Ganda","-13.0858","14.7417","Angola","AO","AGO","Benguela","","235486","1024320952"
"Cannanore","Cannanore","11.8689","75.3555","India","IN","IND","Kerala","","232486","1356147157"
"Najafābād","Najafabad","32.6347","51.3653","Iran","IR","IRN","Eşfahān","minor","235281","1364053850"
"Bayamo","Bayamo","20.3817","-76.6428","Cuba","CU","CUB","Granma","admin","235107","1192297857"
"Kamianske","Kamianske","48.5167","34.6167","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","235066","1804033242"
"Borūjerd","Borujerd","33.8972","48.9017","Iran","IR","IRN","Lorestān","minor","234997","1364855573"
"Singaraja","Singaraja","-8.1167","115.0833","Indonesia","ID","IDN","Bali","minor","234468","1360426282"
"Macaé","Macae","-22.3708","-41.7869","Brazil","BR","BRA","Rio de Janeiro","","234628","1076074287"
"Miri","Miri","4.3925","113.9861","Malaysia","MY","MYS","Sarawak","","234541","1458079883"
"Machala","Machala","-3.2667","-79.9667","Ecuador","EC","ECU","El Oro","admin","231260","1218378191"
"Kasulu","Kasulu","-4.5800","30.1000","Tanzania","TZ","TZA","Kigoma","","234452","1834918111"
"Longquan","Longquan","28.0733","119.1277","China","CN","CHN","Zhejiang","","234626","1156962780"
"Granada","Granada","37.1781","-3.6008","Spain","ES","ESP","Andalusia","minor","231775","1724929320"
"Bo","Bo","7.9564","-11.7400","Sierra Leone","SL","SLE","Southern","admin","233684","1694651111"
"Messina","Messina","38.1936","15.5542","Italy","IT","ITA","Sicilia","minor","234293","1380622386"
"Oral","Oral","51.2225","51.3725","Kazakhstan","KZ","KAZ","","admin","234184","1398593972"
"Badalona","Badalona","41.4333","2.2333","Spain","ES","ESP","Catalonia","","223506","1724090334"
"Formosa","Formosa","-26.1833","-58.1833","Argentina","AR","ARG","Formosa","admin","234000","1032802536"
"San Cristóbal","San Cristobal","18.4167","-70.1056","Dominican Republic","DO","DOM","Valdesia","admin","232769","1214335207"
"Arapiraca","Arapiraca","-9.7519","-36.6608","Brazil","BR","BRA","Alagoas","","233047","1076615737"
"Mambéré","Mambere","5.5000","15.5000","Central African Republic","CF","CAF","Nana-Mambéré","","233666","1140081585"
"Damboa","Damboa","11.1500","12.7500","Nigeria","NG","NGA","Borno","minor","233500","1566264329"
"Okara","Okara","30.8092","73.4536","Pakistan","PK","PAK","Punjab","minor","232386","1586880900"
"Saidpur","Saidpur","25.8004","89.0000","Bangladesh","BD","BGD","Rangpur","","232209","1050512022"
"Freiburg im Breisgau","Freiburg im Breisgau","47.9950","7.8500","Germany","DE","DEU","Baden-Württemberg","minor","231848","1276371228"
"Khénifra","Khenifra","32.9394","-5.6675","Morocco","MA","MAR","Béni Mellal-Khénifra","","228567","1504143530"
"Saga","Saga","33.2667","130.3000","Japan","JP","JPN","Saga","admin","232359","1392618841"
"Rāichūr","Raichur","16.2000","77.3700","India","IN","IND","Karnātaka","","232456","1356217280"
"Burnaby","Burnaby","49.2667","-122.9667","Canada","CA","CAN","British Columbia","","232755","1124817304"
"Meycauayan","Meycauayan","14.7333","120.9500","Philippines","PH","PHL","Bulacan","","225673","1608553352"
"Sơn Tây","Son Tay","21.1333","105.5000","Vietnam","VN","VNM","Hà Nội","minor","230577","1704714941"
"Nazipur","Nazipur","25.0415","88.7605","Bangladesh","BD","BGD","Rājshāhi","","231900","1050310011"
"Rancagua","Rancagua","-34.1667","-70.7500","Chile","CL","CHL","Libertador General Bernardo O’Higgins","admin","231370","1152672356"
"Kasukabe","Kasukabe","35.9753","139.7523","Japan","JP","JPN","Saitama","","228975","1392430282"
"Dzerzhinsk","Dzerzhinsk","56.2333","43.4500","Russia","RU","RUS","Nizhegorodskaya Oblast’","","231797","1643076798"
"Phú Yên","Phu Yen","21.4156","105.8739","Vietnam","VN","VNM","Thái Nguyên","","231363","1704357396"
"Bratsk","Bratsk","56.1167","101.6000","Russia","RU","RUS","Irkutskaya Oblast’","","231602","1643485535"
"Barishal","Barishal","22.7000","90.3667","Bangladesh","BD","BGD","Barishal","admin","230000","1050297281"
"Jiayuguan","Jiayuguan","39.8112","98.2862","China","CN","CHN","Gansu","minor","231853","1156177210"
"Santa Ana","Santa Ana","14.5800","121.0120","Philippines","PH","PHL","Manila","","203598","1608404042"
"Eloy Alfaro","Eloy Alfaro","-2.1733","-79.8311","Ecuador","EC","ECU","Guayas","","230839","1218127217"
"Envigado","Envigado","6.1667","-75.5667","Colombia","CO","COL","Antioquia","minor","228848","1170785909"
"Banfield","Banfield","-34.7500","-58.3833","Argentina","AR","ARG","Buenos Aires","","223898","1032997685"
"Guadalupe","Guadalupe","22.7528","-102.5078","Mexico","MX","MEX","Zacatecas","minor","215000","1484781342"
"Ageoshimo","Ageoshimo","35.9774","139.5932","Japan","JP","JPN","Saitama","","226616","1392046664"
"Fremont","Fremont","37.5265","-121.9843","United States","US","USA","California","","231502","1840020292"
"Qarchak","Qarchak","35.4394","51.5689","Iran","IR","IRN","Tehrān","","231075","1364173799"
"Gulfport","Gulfport","30.4274","-89.0704","United States","US","USA","Mississippi","","231423","1840013947"
"Purwokerto","Purwokerto","-7.4278","109.2417","Indonesia","ID","IDN","Jawa Tengah","minor","230235","1360455408"
"Puerto Vallarta","Puerto Vallarta","20.6458","-105.2222","Mexico","MX","MEX","Jalisco","minor","224166","1484408906"
"Toluca","Toluca","19.2925","-99.6569","Mexico","MX","MEX","México","admin","223876","1484124846"
"Ormoc","Ormoc","11.0106","124.6075","Philippines","PH","PHL","Ormoc","admin","230998","1608269612"
"Neuquén","Neuquen","-38.9525","-68.0642","Argentina","AR","ARG","Neuquén","admin","231198","1032525768"
"Fresnillo","Fresnillo","23.1750","-102.8675","Mexico","MX","MEX","Zacatecas","minor","230865","1484014035"
"Singkawang","Singkawang","0.9000","108.9833","Indonesia","ID","IDN","Kalimantan Barat","","230376","1360906363"
"Orsk","Orsk","51.2000","58.5667","Russia","RU","RUS","Orenburgskaya Oblast’","","230414","1643003114"
"Vizianagaram","Vizianagaram","18.1159","83.4060","India","IN","IND","Andhra Pradesh","","228720","1356212761"
"Manavgat","Manavgat","36.7833","31.4333","Turkey","TR","TUR","Antalya","minor","230597","1792832111"
"Cotia","Cotia","-23.6042","-46.9194","Brazil","BR","BRA","São Paulo","","229548","1076098590"
"Pāli","Pali","25.7700","73.3300","India","IN","IND","Rājasthān","","229956","1356212172"
"Songadh","Songadh","21.1670","73.5640","India","IN","IND","Gujarāt","","229782","1356550633"
"Noginsk","Noginsk","64.4833","91.2333","Russia","RU","RUS","Krasnoyarskiy Kray","","229731","1643129651"
"Kropyvnytskyi","Kropyvnytskyi","48.5000","32.2667","Ukraine","UA","UKR","Kirovohradska Oblast","admin","227413","1804374492"
"Haridwār","Haridwar","29.9450","78.1630","India","IN","IND","Uttarākhand","","228832","1356090741"
"Ordu","Ordu","40.9833","37.8833","Turkey","TR","TUR","Ordu","admin","229214","1792540951"
"Taitō","Taito","35.7126","139.7800","Japan","JP","JPN","Tōkyō","","210528","1392662678"
"Ternopil","Ternopil","49.5667","25.6000","Ukraine","UA","UKR","Ternopilska Oblast","admin","225238","1804000146"
"Bojnūrd","Bojnurd","37.4750","57.3333","Iran","IR","IRN","Khorāsān-e Shomālī","admin","228931","1364981174"
"Rondonópolis","Rondonopolis","-16.4667","-54.6333","Brazil","BR","BRA","Mato Grosso","","228857","1076627175"
"Cobán","Coban","15.4833","-90.3667","Guatemala","GT","GTM","Alta Verapaz","admin","228664","1320020813"
"Guantánamo","Guantanamo","20.1367","-75.2139","Cuba","CU","CUB","Guantánamo","admin","228436","1192866062"
"Krefeld","Krefeld","51.3333","6.5667","Germany","DE","DEU","North Rhine-Westphalia","minor","227050","1276149829"
"Pāthardi","Pathardi","19.1700","75.1800","India","IN","IND","Mahārāshtra","","228279","1356126088"
"Şabyā","Sabya","17.1489","42.6258","Saudi Arabia","SA","SAU","Jāzān","","228375","1682601560"
"Kolpino","Kolpino","59.7333","30.6000","Russia","RU","RUS","Sankt-Peterburg","","225801","1643448070"
"Petlād","Petlad","22.4700","72.8000","India","IN","IND","Gujarāt","","227031","1356943594"
"Khanewal","Khanewal","30.3000","71.9333","Pakistan","PK","PAK","Punjab","minor","227059","1586883649"
"Magé","Mage","-22.6528","-43.0408","Brazil","BR","BRA","Rio de Janeiro","","227322","1076385139"
"Ninh Hòa","Ninh Hoa","12.4917","109.1258","Vietnam","VN","VNM","Khánh Hòa","minor","227630","1704108515"
"Khatīma","Khatima","28.9200","79.9700","India","IN","IND","Uttarākhand","","227226","1356442679"
"Apapa","Apapa","6.4500","3.3667","Nigeria","NG","NGA","Lagos","minor","217362","1566625487"
"Choloma","Choloma","15.6136","-87.9511","Honduras","HN","HND","Cortés","","227535","1340125981"
"Capiatá","Capiata","-25.3500","-57.4200","Paraguay","PY","PRY","Central","","224152","1600122166"
"Pallāvaram","Pallavaram","12.9675","80.1491","India","IN","IND","Tamil Nādu","","215417","1356057661"
"Nadiād","Nadiad","22.6900","72.8600","India","IN","IND","Gujarāt","","225132","1356992385"
"Tarrasa","Tarrasa","41.5611","2.0081","Spain","ES","ESP","Catalonia","","224114","1724377964"
"Daşoguz","Dasoguz","41.8333","59.9667","Turkmenistan","TM","TKM","Daşoguz","admin","227184","1795167397"
"Probolinggo","Probolinggo","-7.7500","113.2167","Indonesia","ID","IDN","Jawa Timur","","223159","1360138476"
"Cikupa","Cikupa","-6.4947","106.6511","Indonesia","ID","IDN","Jawa Barat","","224678","1360462500"
"Evansville","Evansville","37.9881","-87.5341","United States","US","USA","Indiana","","226788","1840013730"
"Araraquara","Araraquara","-21.7939","-48.1758","Brazil","BR","BRA","São Paulo","","226508","1076251019"
"Quetzaltenango","Quetzaltenango","14.8333","-91.5167","Guatemala","GT","GTM","Quetzaltenango","admin","225000","1320083599"
"Angarsk","Angarsk","52.5500","103.9000","Russia","RU","RUS","Irkutskaya Oblast’","","226374","1643989118"
"Sousse","Sousse","35.8333","10.6333","Tunisia","TN","TUN","Sousse","admin","221530","1788927583"
"Nyanza","Nyanza","-2.3500","29.7333","Rwanda","RW","RWA","Southern Province","admin","225209","1646257566"
"Cox’s Bāzār","Cox's Bazar","21.4272","92.0050","Bangladesh","BD","BGD","Chattogram","","223522","1050836471"
"Takarazuka","Takarazuka","34.8114","135.3406","Japan","JP","JPN","Hyōgo","","224055","1392999976"
"Atsugichō","Atsugicho","35.4333","139.3667","Japan","JP","JPN","Kanagawa","","223815","1392522298"
"Itapevi","Itapevi","-23.5489","-46.9342","Brazil","BR","BRA","São Paulo","","223404","1076008789"
"Marcory","Marcory","5.3000","-3.9833","Côte d'Ivoire","CI","CIV","Abidjan","","214061","1384405403"
"Toamasina","Toamasina","-18.1500","49.4167","Madagascar","MG","MDG","Toamasina","admin","225116","1450540529"
"Padangsidempuan","Padangsidempuan","1.3786","99.2722","Indonesia","ID","IDN","Sumatera Utara","","225544","1360506609"
"Varāmīn","Varamin","35.3242","51.6458","Iran","IR","IRN","Tehrān","minor","225628","1364275103"
"Mutare","Mutare","-18.9667","32.6333","Zimbabwe","ZW","ZWE","Manicaland","admin","224802","1716060405"
"Mubi","Mubi","10.2604","13.2606","Nigeria","NG","NGA","Adamawa","minor","225705","1566265170"
"Chuādānga","Chuadanga","23.6440","88.8556","Bangladesh","BD","BGD","Khulna","","224576","1050405233"
"Korolëv","Korolev","55.9167","37.8167","Russia","RU","RUS","Moskovskaya Oblast’","","221797","1643323167"
"Hunchun","Hunchun","42.8679","130.3585","China","CN","CHN","Jilin","minor","225454","1156644593"
"Blagoveshchensk","Blagoveshchensk","50.2578","127.5364","Russia","RU","RUS","Amurskaya Oblast’","admin","224419","1643308239"
"Velikiy Novgorod","Velikiy Novgorod","58.5210","31.2758","Russia","RU","RUS","Novgorodskaya Oblast’","admin","222594","1643774241"
"Longjin","Longjin","22.8711","113.0684","China","CN","CHN","Guangdong","","221881","1156526154"
"Rennes","Rennes","48.1147","-1.6794","France","FR","FRA","Bretagne","admin","220488","1250363682"
"Ashdod","Ashdod","31.8","34.65","Israel","IL","ISR","Southern","","220174","1376458766"
"Częstochowa","Czestochowa","50.8000","19.1167","Poland","PL","POL","Śląskie","minor","223322","1616881632"
"Carlos Manuel de Céspedes","Carlos Manuel de Cespedes","21.5767","-78.2775","Cuba","CU","CUB","Camagüey","minor","224488","1192400731"
"Chapecó","Chapeco","-27.0958","-52.6178","Brazil","BR","BRA","Santa Catarina","","224013","1076258255"
"Hialeah","Hialeah","25.8696","-80.3045","United States","US","USA","Florida","","224362","1840015157"
"Ōta","Ota","36.2911","139.3754","Japan","JP","JPN","Gunma","","222806","1392003546"
"Sandton","Sandton","-26.1070","28.0517","South Africa","ZA","ZAF","Gauteng","","222415","1710113163"
"Navarre","Navarre","30.4174","-86.8907","United States","US","USA","Florida","","223951","1840027017"
"Ijebu-Ode","Ijebu-Ode","6.8208","3.9208","Nigeria","NG","NGA","Ogun","minor","222653","1566495747"
"Tilburg","Tilburg","51.5500","5.0833","Netherlands","NL","NLD","Noord-Brabant","minor","221947","1528817379"
"Cork","Cork","51.8972","-8.4700","Ireland","IE","IRL","Cork","admin","222333","1372221853"
"Tanjore","Tanjore","10.7870","79.1378","India","IN","IND","Tamil Nādu","","222943","1356599194"
"Bandar-e Būshehr","Bandar-e Bushehr","28.9264","50.8514","Iran","IR","IRN","Būshehr","admin","223504","1364483819"
"Hachinohe","Hachinohe","40.5123","141.4884","Japan","JP","JPN","Aomori","","222799","1392000706"
"Staryy Oskol","Staryy Oskol","51.2981","37.8350","Russia","RU","RUS","Belgorodskaya Oblast’","","223360","1643807951"
"Kichha","Kichha","28.9200","79.5000","India","IN","IND","Uttarākhand","","221965","1356016973"
"Secunderābād","Secunderabad","17.4399","78.4983","India","IN","IND","Andhra Pradesh","","213698","1356779437"
"Tiptūr","Tiptur","13.2600","76.4800","India","IN","IND","Karnātaka","","222749","1356059008"
"Ji’an Shi","Ji'an Shi","41.1231","126.1815","China","CN","CHN","Jilin","minor","222938","1156207117"
"Mallawī","Mallawi","27.7333","30.8333","Egypt","EG","EGY","Al Minyā","","212628","1818284236"
"José C. Paz","Jose C. Paz","-34.5167","-58.7667","Argentina","AR","ARG","Buenos Aires","minor","216637","1032741145"
"Bel Air South","Bel Air South","39.5022","-76.3113","United States","US","USA","Maryland","","222834","1840073592"
"Rufisque","Rufisque","14.7167","-17.2667","Senegal","SN","SEN","Dakar","","221066","1686348669"
"Presidente Prudente","Presidente Prudente","-22.1256","-51.3889","Brazil","BR","BRA","São Paulo","","222192","1076239965"
"Barra do Dande","Barra do Dande","-8.4728","13.3731","Angola","AO","AGO","Bengo","","222528","1024937810"
"Hobart","Hobart","-42.8806","147.3250","Australia","AU","AUS","Tasmania","admin","222356","1036679838"
"Swindon","Swindon","51.5600","-1.7800","United Kingdom","GB","GBR","Swindon","","222193","1826498106"
"Lutsk","Lutsk","50.7500","25.3358","Ukraine","UA","UKR","Volynska Oblast","admin","217197","1804014744"
"Katri","Katri","23.4800","80.4000","India","IN","IND","Madhya Pradesh","","221875","1356636912"
"Sambhal","Sambhal","28.5800","78.5500","India","IN","IND","Uttar Pradesh","","220813","1356376216"
"Guéckédou","Gueckedou","8.5667","-10.1333","Guinea","GN","GIN","N’Zérékoré","minor","221715","1324580681"
"Sabadell","Sabadell","41.5483","2.1075","Spain","ES","ESP","Catalonia","","216204","1724348384"
"Neyshābūr","Neyshabur","36.2200","58.8200","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","221700","1364706573"
"North Port","North Port","27.0576","-82.1977","United States","US","USA","Florida","","221616","1840015120"
"Radom","Radom","51.4036","21.1567","Poland","PL","POL","Mazowieckie","minor","219703","1616327727"
"Santa Luzia","Santa Luzia","-19.7700","-43.8506","Brazil","BR","BRA","Minas Gerais","","220444","1076123204"
"Polokwane","Polokwane","-23.9000","29.4500","South Africa","ZA","ZAF","Limpopo","admin","220045","1710908010"
"Chí Linh","Chi Linh","21.1330","106.3830","Vietnam","VN","VNM","Hải Dương","","220421","1704328235"
"Tchaourou","Tchaourou","8.8833","2.6000","Benin","BJ","BEN","Borgou","","221108","1204695000"
"Duekoué","Duekoue","6.7333","-7.3500","Côte d'Ivoire","CI","CIV","Montagnes","minor","220953","1384429839"
"San Felipe","San Felipe","10.3353","-68.7458","Venezuela","VE","VEN","Yaracuy","admin","220786","1862716082"
"Sāveh","Saveh","35.0214","50.3567","Iran","IR","IRN","Markazī","minor","220762","1364844111"
"San Bernardino","San Bernardino","34.1416","-117.2943","United States","US","USA","California","","220821","1840021728"
"Portland","Portland","43.6773","-70.2715","United States","US","USA","Maine","","220807","1840000327"
"Santo Tomas","Santo Tomas","14.0833","121.1833","Philippines","PH","PHL","Batangas","","218500","1608845898"
"Osisioma","Osisioma","5.1497","7.3303","Nigeria","NG","NGA","Abia","minor","219632","1566203950"
"La Vega","La Vega","19.2200","-70.5300","Dominican Republic","DO","DOM","Cibao Sur","admin","220279","1214691060"
"Nishitōkyō","Nishitokyo","35.7256","139.5383","Japan","JP","JPN","Tōkyō","","207436","1392001244"
"Damietta","Damietta","31.4167","31.8214","Egypt","EG","EGY","Dumyāţ","admin","206664","1818333966"
"Pangkalpinang","Pangkalpinang","-2.1333","106.1167","Indonesia","ID","IDN","Kepulauan Bangka Belitung","admin","218569","1360901919"
"Juazeiro","Juazeiro","-9.4306","-40.5028","Brazil","BR","BRA","Bahia","","220253","1076632040"
"Kremenchuk","Kremenchuk","49.0631","33.4039","Ukraine","UA","UKR","Poltavska Oblast","minor","220174","1804343754"
"Itabuna","Itabuna","-14.7858","-39.2800","Brazil","BR","BRA","Bahia","","219680","1076003792"
"Ānand","Anand","22.5560","72.9510","India","IN","IND","Gujarāt","","218486","1356044073"
"Ciputat","Ciputat","-6.3111","106.7619","Indonesia","ID","IDN","Jawa Barat","","208722","1360204080"
"Al Khubar","Al Khubar","26.2833","50.2000","Saudi Arabia","SA","SAU","Ash Sharqīyah","","219679","1682059414"
"Peñalolén","Penalolen","-33.4833","-70.5500","Chile","CL","CHL","Región Metropolitana","","216060","1152224242"
"Islington","Islington","51.5440","-0.1027","United Kingdom","GB","GBR","Islington","","206125","1826267810"
"Mainz","Mainz","49.9833","8.2667","Germany","DE","DEU","Rhineland-Palatinate","admin","217556","1276801317"
"Konibodom","Konibodom","40.2833","70.4167","Tajikistan","TJ","TJK","Sughd","minor","219000","1762140294"
"Zākhū","Zakhu","37.1500","42.6833","Iraq","IQ","IRQ","Dahūk","minor","219006","1368905231"
"Enterprise","Enterprise","36.0091","-115.2278","United States","US","USA","Nevada","","219566","1840033827"
"Bremerton","Bremerton","47.5436","-122.7121","United States","US","USA","Washington","","219538","1840018410"
"Córdoba","Cordoba","18.8942","-96.9347","Mexico","MX","MEX","Veracruz","minor","218153","1484179679"
"Green Bay","Green Bay","44.5148","-87.9895","United States","US","USA","Wisconsin","","219472","1840002344"
"Tuluá","Tulua","4.0833","-76.2000","Colombia","CO","COL","Valle del Cauca","minor","219138","1170721938"
"Pleiku","Pleiku","13.9833","108.0000","Vietnam","VN","VNM","Gia Lai","admin","218765","1704220080"
"Hortolândia","Hortolandia","-22.8583","-47.2200","Brazil","BR","BRA","São Paulo","","215819","1076591779"
"Bitung","Bitung","1.4472","125.1978","Indonesia","ID","IDN","Sulawesi Utara","","218520","1360695939"
"Naihāti","Naihati","22.8900","88.4200","India","IN","IND","West Bengal","","217900","1356715598"
"Petrel","Petrel","38.4789","-0.7967","Spain","ES","ESP","Valencia","","218843","1724000064"
"Kafue","Kafue","-15.7667","28.1833","Zambia","ZM","ZMB","Lusaka","","219000","1894930695"
"Jiutepec","Jiutepec","18.8833","-99.1667","Mexico","MX","MEX","Morelos","minor","215357","1484001709"
"Manta","Manta","-0.9500","-80.7162","Ecuador","EC","ECU","Manabí","","217553","1218160109"
"Osan","Osan","37.1498","127.0771","South Korea","KR","KOR","Gyeonggi","","213840","1410606985"
"San Miguel","San Miguel","13.4814","-88.1775","El Salvador","SV","SLV","San Miguel","admin","218410","1222009747"
"Butembo","Butembo","0.1500","29.2833","Congo (Kinshasa)","CD","COD","Nord-Kivu","","217625","1180446432"
"Netanya","Netanya","32.3286","34.8567","Israel","IL","ISR","Central","","217244","1376203187"
"Oviedo","Oviedo","43.3600","-5.8450","Spain","ES","ESP","Asturias","admin","217552","1724090743"
"Itaboraí","Itaborai","-22.7439","-42.8589","Brazil","BR","BRA","Rio de Janeiro","","218008","1076964860"
"Appleton","Appleton","44.2780","-88.3892","United States","US","USA","Wisconsin","","218448","1840002400"
"Zamora","Zamora","19.9833","-102.2833","Mexico","MX","MEX","Michoacán","","207860","1484229977"
"Alor Setar","Alor Setar","6.1183","100.3694","Malaysia","MY","MYS","Kedah","admin","217000","1458273978"
"Luton","Luton","51.8783","-0.4147","United Kingdom","GB","GBR","Luton","","213052","1826630574"
"Babylon","Babylon","40.6925","-73.3586","United States","US","USA","New York","","217796","1840005142"
"Laâyoune","Laayoune","27.1536","-13.2033","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","","217732","1504000319"
"Shashemenē","Shashemene","7.2000","38.6000","Ethiopia","ET","ETH","Oromīya","","208368","1231110370"
"Tân An","Tan An","10.5333","106.4167","Vietnam","VN","VNM","Long An","admin","215250","1704199290"
"Spring Valley","Spring Valley","36.0952","-115.2636","United States","US","USA","Nevada","","217441","1840033832"
"Denov","Denov","38.2667","67.9000","Uzbekistan","UZ","UZB","Surxondaryo","","217274","1860150082"
"Cartagena","Cartagena","37.6000","-0.9819","Spain","ES","ESP","Murcia","","216961","1724143241"
"Tacoma","Tacoma","47.2431","-122.4531","United States","US","USA","Washington","","217332","1840021129"
"Yamunānagar","Yamunanagar","30.1330","77.2880","India","IN","IND","Haryāna","","216628","1356846120"
"Lübeck","Lubeck","53.8697","10.6864","Germany","DE","DEU","Schleswig-Holstein","minor","216277","1276852342"
"Zielona Góra","Zielona Gora","51.9333","15.5000","Poland","PL","POL","Lubuskie","admin","216781","1616050904"
"Maţraḩ","Matrah","23.6167","58.5667","Oman","OM","OMN","Masqaţ","","214901","1512325823"
"Qostanay","Qostanay","53.2000","63.6200","Kazakhstan","KZ","KAZ","","admin","217135","1398455461"
"Banjarbaru","Banjarbaru","-3.4425","114.8325","Indonesia","ID","IDN","Kalimantan Selatan","","216600","1360085654"
"Sa Đéc","Sa Dec","10.3000","105.7667","Vietnam","VN","VNM","Đồng Tháp","minor","213610","1704983576"
"Wayaobu","Wayaobu","37.1398","109.6711","China","CN","CHN","Shaanxi","","216910","1156408502"
"Valencia","Valencia","7.9042","125.0928","Philippines","PH","PHL","Bukidnon","","216546","1608418474"
"Norwich","Norwich","52.6286","1.2928","United Kingdom","GB","GBR","Norfolk","","213166","1826688051"
"Gujiao","Gujiao","37.9069","112.1700","China","CN","CHN","Shanxi","","216723","1156571525"
"Ayacucho","Ayacucho","-13.1631","-74.2244","Peru","PE","PER","Ayacucho","admin","216444","1604300430"
"Petropavl","Petropavl","54.8833","69.1667","Kazakhstan","KZ","KAZ","","admin","216406","1398252858"
"Madiun","Madiun","-7.6300","111.5231","Indonesia","ID","IDN","Jawa Timur","","210115","1360921516"
"Dongxing","Dongxing","21.5833","108.0500","China","CN","CHN","Guangxi","minor","216053","1156419338"
"Zhubei","Zhubei","24.8333","121.0119","Taiwan","TW","TWN","Hsinchu","admin","211746","1158417233"
"São Leopoldo","Sao Leopoldo","-29.7600","-51.1469","Brazil","BR","BRA","Rio Grande do Sul","","214087","1076834293"
"Hebron","Hebron","31.5333","35.0950","West Bank","XW","XWB","","","215452","1934166495"
"Spartanburg","Spartanburg","34.9442","-81.9251","United States","US","USA","South Carolina","","216021","1840015482"
"Marg‘ilon","Marg\`ilon","40.4711","71.7247","Uzbekistan","UZ","UZB","Farg‘ona","","215400","1860419224"
"Ar Ruseris","Ar Ruseris","11.8500","34.3833","Sudan","SD","SDN","Blue Nile","","215857","1729614147"
"Trece Martires City","Trece Martires City","14.2833","120.8667","Philippines","PH","PHL","Cavite","admin","210503","1608990723"
"Gangneung","Gangneung","37.7500","128.9000","South Korea","KR","KOR","Gangwon","minor","215677","1410450550"
"Roanoke","Roanoke","37.2785","-79.9581","United States","US","USA","Virginia","","215781","1840003858"
"Cúa","Cua","10.1667","-66.8833","Venezuela","VE","VEN","Miranda","minor","214823","1862770169"
"Almere","Almere","52.3667","5.2167","Netherlands","NL","NLD","Flevoland","minor","214715","1528683566"
"As Samāwah","As Samawah","31.3167","45.2833","Iraq","IQ","IRQ","Al Muthanná","admin","215000","1368246912"
"Monghyr","Monghyr","25.3810","86.4650","India","IN","IND","Bihār","","213101","1356280102"
"Vantaa","Vantaa","60.2944","25.0403","Finland","FI","FIN","Uusimaa","minor","214605","1246957545"
"Kāpra","Kapra","17.4859","78.5651","India","IN","IND","Andhra Pradesh","","209882","1356304297"
"Golmud","Golmud","36.4072","94.9283","China","CN","CHN","Qinghai","minor","215214","1156083724"
"Comalcalco","Comalcalco","18.2801","-93.2018","Mexico","MX","MEX","Tabasco","minor","214877","1484317909"
"Geneva","Geneva","46.2017","6.1469","Switzerland","CH","CHE","Genève","admin","201818","1756810813"
"Regina","Regina","50.4547","-104.6067","Canada","CA","CAN","Saskatchewan","admin","215106","1124342541"
"La Victoria","La Victoria","10.2278","-67.3336","Venezuela","VE","VEN","Aragua","minor","214492","1862054455"
"Shimla","Shimla","31.1033","77.1722","India","IN","IND","Himāchal Pradesh","admin","206575","1356962932"
"Northampton","Northampton","52.2304","-0.8938","United Kingdom","GB","GBR","Northamptonshire","","212100","1826806747"
"Babruysk","Babruysk","53.1500","29.2333","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","212200","1112573808"
"Mohammedia","Mohammedia","33.6833","-7.3833","Morocco","MA","MAR","Casablanca-Settat","","208612","1504542634"
"Erfurt","Erfurt","50.9833","11.0333","Germany","DE","DEU","Thuringia","admin","213835","1276924655"
"Sete Lagoas","Sete Lagoas","-19.4658","-44.2469","Brazil","BR","BRA","Minas Gerais","","214152","1076863850"
"Sikasso","Sikasso","11.3167","-5.6667","Mali","ML","MLI","Sikasso","admin","213977","1466666150"
"Warnes","Warnes","-17.5167","-63.1667","Bolivia","BO","BOL","Santa Cruz","","214216","1068890339"
"Móstoles","Mostoles","40.3333","-3.8667","Spain","ES","ESP","Madrid","","209639","1724412875"
"Quilicura","Quilicura","-33.3667","-70.7333","Chile","CL","CHL","Región Metropolitana","","210410","1152402180"
"Chilas","Chilas","35.4194","74.0944","Pakistan","PK","PAK","Gilgit-Baltistan","minor","214000","1586064350"
"Cao Lãnh","Cao Lanh","10.4672","105.6303","Vietnam","VN","VNM","Đồng Tháp","admin","211912","1704276832"
"El Tigre","El Tigre","8.8858","-64.2611","Venezuela","VE","VEN","Anzoátegui","minor","213524","1862428934"
"Gokāk","Gokak","16.1667","74.8333","India","IN","IND","Karnātaka","","213000","1356269894"
"Divinópolis","Divinopolis","-20.1389","-44.8839","Brazil","BR","BRA","Minas Gerais","","213016","1076716552"
"Gainesville","Gainesville","29.6804","-82.3459","United States","US","USA","Florida","","213287","1840014022"
"Colombo","Colombo","-25.2919","-49.2239","Brazil","BR","BRA","Paraná","","212967","1076976439"
"Jerez de la Frontera","Jerez de la Frontera","36.6817","-6.1378","Spain","ES","ESP","Andalusia","","212801","1724866806"
"Puri","Puri","19.8106","85.8314","India","IN","IND","Odisha","","201026","1356558409"
"Sidi Bel Abbès","Sidi Bel Abbes","35.2000","-0.6333","Algeria","DZ","DZA","Sidi Bel Abbès","admin","212935","1012123414"
"Nandyāl","Nandyal","15.4800","78.4800","India","IN","IND","Andhra Pradesh","","211424","1356671879"
"Limbe","Limbe","4.0167","9.2167","Cameroon","CM","CMR","South-West","","212474","1120635273"
"Kure","Kure","34.2500","132.5667","Japan","JP","JPN","Hiroshima","","212159","1392991987"
"Tumaco","Tumaco","1.8067","-78.7647","Colombia","CO","COL","Nariño","minor","212692","1170504978"
"Padova","Padova","45.4064","11.8778","Italy","IT","ITA","Veneto","minor","210440","1380964615"
"Irákleio","Irakleio","35.3403","25.1344","Greece","GR","GRC","Kríti","admin","211370","1300777854"
"Deltona","Deltona","28.9050","-81.2137","United States","US","USA","Florida","","212616","1840015072"
"Arroyo Naranjo","Arroyo Naranjo","23.0436","-82.3328","Cuba","CU","CUB","La Habana","","210053","1192534571"
"Bhadrāvati","Bhadravati","13.8485","75.7050","India","IN","IND","Karnātaka","","212073","1356466241"
"Colón","Colon","9.3572","-79.8986","Panama","PA","PAN","Colón","admin","204000","1591599922"
"Linz","Linz","48.3058","14.2864","Austria","AT","AUT","Oberösterreich","admin","210165","1040261171"
"Oberhausen","Oberhausen","51.4967","6.8706","Germany","DE","DEU","North Rhine-Westphalia","minor","209566","1276448936"
"Criciúma","Criciuma","-28.6775","-49.3697","Brazil","BR","BRA","Santa Catarina","","211369","1076000206"
"Kuje","Kuje","8.8822","7.2203","Nigeria","NG","NGA","Federal Capital Territory","minor","212100","1566591024"
"Mytishchi","Mytishchi","55.9167","37.7667","Russia","RU","RUS","Moskovskaya Oblast’","","211606","1643322494"
"Lyubertsy","Lyubertsy","55.6783","37.8944","Russia","RU","RUS","Moskovskaya Oblast’","","207349","1643024284"
"Pamplona","Pamplona","42.8167","-1.6500","Spain","ES","ESP","Navarre","admin","203418","1724483329"
"Panchkula","Panchkula","30.7400","76.8000","India","IN","IND","Haryāna","minor","211355","1356152025"
"Kalamazoo","Kalamazoo","42.2749","-85.5882","United States","US","USA","Michigan","","212033","1840003185"
"Pskov","Pskov","57.8167","28.3333","Russia","RU","RUS","Pskovskaya Oblast’","admin","209840","1643877414"
"Bila Tserkva","Bila Tserkva","49.7956","30.1167","Ukraine","UA","UKR","Kyivska Oblast","minor","208944","1804758853"
"‘Aqrah","\`Aqrah","36.7414","43.8933","Iraq","IQ","IRQ","Nīnawá","minor","212000","1368123730"
"Ploieşti","Ploiesti","44.9411","26.0225","Romania","RO","ROU","Prahova","admin","209945","1642169731"
"Isesaki","Isesaki","36.3114","139.1968","Japan","JP","JPN","Gunma","","210332","1392003456"
"Naga City","Naga City","13.6244","123.1864","Philippines","PH","PHL","Naga","admin","209170","1608775993"
"Burgas","Burgas","42.5030","27.4702","Bulgaria","BG","BGR","Burgas","admin","210813","1100365645"
"Burhānpur","Burhanpur","21.3000","76.2300","India","IN","IND","Madhya Pradesh","","210891","1356857493"
"Chungju","Chungju","36.9667","127.9500","South Korea","KR","KOR","Chungbuk","","211005","1410333057"
"Maracanaú","Maracanau","-3.8667","-38.6333","Brazil","BR","BRA","Ceará","","209057","1076848743"
"Thousand Oaks","Thousand Oaks","34.1914","-118.8756","United States","US","USA","California","","211045","1840021844"
"Hickory","Hickory","35.7410","-81.3223","United States","US","USA","North Carolina","","211042","1840014524"
"Pagadian","Pagadian","7.8272","123.4364","Philippines","PH","PHL","Zamboanga del Sur","admin","210452","1608329397"
"Legazpi City","Legazpi City","13.1333","123.7333","Philippines","PH","PHL","Albay","admin","209533","1608693683"
"Barrancabermeja","Barrancabermeja","7.0667","-73.8500","Colombia","CO","COL","Santander","minor","210729","1170362340"
"Bukit Mertajam","Bukit Mertajam","5.3631","100.4667","Malaysia","MY","MYS","Pulau Pinang","","210000","1458619046"
"Kirātot","Kiratot","32.8376","76.2296","India","IN","IND","Himāchal Pradesh","","210406","1356118618"
"Ashaiman","Ashaiman","5.7000","-0.0333","Ghana","GH","GHA","Greater Accra","","208060","1288638865"
"Taisheng","Taisheng","23.2938","113.3530","China","CN","CHN","Guangdong","","208589","1156077513"
"Sidon","Sidon","33.5606","35.3758","Lebanon","LB","LBN","Liban-Sud","admin","200000","1422897920"
"Rostock","Rostock","54.0833","12.1333","Germany","DE","DEU","Mecklenburg-Western Pomerania","","209061","1276210265"
"Guarenas","Guarenas","10.4739","-66.5383","Venezuela","VE","VEN","Miranda","minor","208663","1862083525"
"Santa Cruz","Santa Cruz","28.4667","-16.2500","Spain","ES","ESP","Canary Islands","admin","208688","1724813158"
"Panabo","Panabo","7.3000","125.6833","Philippines","PH","PHL","Davao del Norte","","209230","1608577408"
"Dongning","Dongning","44.0608","131.1187","China","CN","CHN","Heilongjiang","minor","210000","1156856366"
"Borj Hammoud","Borj Hammoud","33.8936","35.5403","Lebanon","LB","LBN","Mont-Liban","","150000","1422255275"
"Kasangati","Kasangati","0.4378","32.6025","Uganda","UG","UGA","Wakiso","","207800","1800208674"
"Solihull","Solihull","52.4130","-1.7780","United Kingdom","GB","GBR","Solihull","","206674","1826720500"
"Burutu","Burutu","5.3500","5.5167","Nigeria","NG","NGA","Delta","minor","209666","1566686777"
"Nasīm Shahr","Nasim Shahr","35.5644","51.1647","Iran","IR","IRN","Tehrān","minor","200393","1364571196"
"Moratuwa","Moratuwa","6.7991","79.8767","Sri Lanka","LK","LKA","Western","","200000","1144324309"
"Yonkers","Yonkers","40.9466","-73.8673","United States","US","USA","New York","","209730","1840003478"
"Ich’ŏn","Ich'on","37.2667","127.4333","South Korea","KR","KOR","Gyeonggi","","209003","1410736435"
"Milton Keynes","Milton Keynes","52.0400","-0.7600","United Kingdom","GB","GBR","Milton Keynes","","207057","1826291745"
"Marawi City","Marawi City","8.0000","124.3000","Philippines","PH","PHL","Lanao del Sur","admin","207010","1608812117"
"Puerto Cabello","Puerto Cabello","10.4667","-68.0167","Venezuela","VE","VEN","Carabobo","minor","209080","1862031193"
"Beersheba","Beersheba","31.2589","34.7997","Israel","IL","ISR","Southern","admin","209000","1376023307"
"Huangyan","Huangyan","28.6500","121.2500","China","CN","CHN","Zhejiang","","198713","1156149186"
"Oulu","Oulu","65.0142","25.4719","Finland","FI","FIN","Pohjois-Pohjanmaa","admin","208939","1246093273"
"Santo Agostinho","Santo Agostinho","-8.2897","-35.0300","Brazil","BR","BRA","Pernambuco","","208944","1076363089"
"Lubuklinggau","Lubuklinggau","-3.2967","102.8617","Indonesia","ID","IDN","Sumatera Selatan","","208225","1360359449"
"Mary","Mary","37.6000","61.8333","Turkmenistan","TM","TKM","Mary","admin","208682","1795413265"
"Sunyani","Sunyani","7.3333","-2.3333","Ghana","GH","GHA","Bono","admin","208496","1288679588"
"Cascais","Cascais","38.7000","-9.4167","Portugal","PT","PRT","Lisboa","minor","206479","1620824685"
"Northcote","Northcote","-36.8019","174.7494","New Zealand","NZ","NZL","Auckland","","205605","1554717659"
"Moreno Valley","Moreno Valley","33.9244","-117.2045","United States","US","USA","California","","208371","1840020552"
"Qinā","Qina","26.1667","32.7167","Egypt","EG","EGY","Qinā","admin","201191","1818868450"
"Toledo","Toledo","10.3833","123.6500","Philippines","PH","PHL","Cebu","","207314","1608665464"
"Kharagpur","Kharagpur","22.3302","87.3237","India","IN","IND","West Bengal","","207604","1356305803"
"Waitakere","Waitakere","-36.8500","174.5430","New Zealand","NZ","NZL","Auckland","","208100","1554034359"
"Fontana","Fontana","34.0968","-117.4599","United States","US","USA","California","","208087","1840020402"
"Monywa","Monywa","22.1083","95.1417","Myanmar","MM","MMR","Sagaing","","207489","1104777345"
"Dindigul","Dindigul","10.3500","77.9500","India","IN","IND","Tamil Nādu","","207327","1356106153"
"Robertsonpet","Robertsonpet","12.9563","78.2754","India","IN","IND","Karnātaka","","207350","1356245899"
"Morogoro","Morogoro","-6.8242","37.6633","Tanzania","TZ","TZA","Morogoro","admin","207000","1834185429"
"Pingzhen","Pingzhen","24.9439","121.2161","Taiwan","TW","TWN","Taoyuan","","201632","1158047789"
"Banī Suwayf","Bani Suwayf","29.0667","31.0833","Egypt","EG","EGY","Banī Suwayf","admin","193048","1818902849"
"College Station","College Station","30.5852","-96.2959","United States","US","USA","Texas","","207514","1840019570"
"Bà Rịa","Ba Ria","10.4992","107.1675","Vietnam","VN","VNM","Bà Rịa-Vũng Tàu","","205192","1704455087"
"Cidade de Nacala","Cidade de Nacala","-14.5428","40.6728","Mozambique","MZ","MOZ","Nampula","","206449","1508752825"
"Talca","Talca","-35.4269","-71.6656","Chile","CL","CHL","Maule","admin","206069","1152736946"
"Fargo","Fargo","46.8651","-96.8292","United States","US","USA","North Dakota","","207310","1840000177"
"Portoviejo","Portoviejo","-1.0561","-80.4553","Ecuador","EC","ECU","Manabí","admin","206682","1218169586"
"Parakou","Parakou","9.3500","2.6167","Benin","BJ","BEN","Borgou","admin","206667","1204807468"
"Hospet","Hospet","15.2689","76.3909","India","IN","IND","Karnātaka","","206167","1356241463"
"Trieste","Trieste","45.6503","13.7703","Italy","IT","ITA","Friuli Venezia Giulia","admin","204338","1380102581"
"San Carlos City","San Carlos City","15.9281","120.3489","Philippines","PH","PHL","Pangasinan","","205424","1608542323"
"Kodaira","Kodaira","35.7285","139.4774","Japan","JP","JPN","Tōkyō","","196959","1392003213"
"Itami","Itami","34.7833","135.4000","Japan","JP","JPN","Hyōgo","","198522","1392384479"
"Lingampalli","Lingampalli","17.4865","78.3170","India","IN","IND","Andhra Pradesh","","202440","1356038214"
"Kalemie","Kalemie","-5.9128","29.1906","Congo (Kinshasa)","CD","COD","Tanganyika","admin","206257","1180574233"
"Kamirenjaku","Kamirenjaku","35.6836","139.5595","Japan","JP","JPN","Tōkyō","","194460","1392889022"
"Loures","Loures","38.8333","-9.1667","Portugal","PT","PRT","Lisboa","minor","205054","1620042113"
"Biskra","Biskra","34.8500","5.7333","Algeria","DZ","DZA","Biskra","admin","204661","1012947247"
"Ingrāj Bāzār","Ingraj Bazar","25.0044","88.1458","India","IN","IND","West Bengal","","205521","1356142829"
"Phủ Từ Sơn","Phu Tu Son","21.1189","105.9611","Vietnam","VN","VNM","Bắc Ninh","","202874","1704080874"
"La Ceiba","La Ceiba","15.7792","-86.7931","Honduras","HN","HND","Atlántida","admin","205775","1340870189"
"Bené Beraq","Bene Beraq","32.0833","34.8333","Israel","IL","ISR","Tel Aviv","","193774","1376944837"
"Nagareyama","Nagareyama","35.8563","139.9029","Japan","JP","JPN","Chiba","","200298","1392398873"
"Ongole","Ongole","15.5060","80.0490","India","IN","IND","Andhra Pradesh","","204746","1356385136"
"Zanzibar","Zanzibar","-6.1650","39.1990","Tanzania","TZ","TZA","Zanzibar Urban/West","admin","205870","1834823848"
"Jiutai","Jiutai","44.1447","125.8443","China","CN","CHN","Jilin","","205399","1156061722"
"Bolu","Bolu","40.7347","31.6075","Turkey","TR","TUR","Bolu","admin","205525","1792807068"
"Ziguinchor","Ziguinchor","12.5833","-16.2667","Senegal","SN","SEN","Ziguinchor","admin","205294","1686273643"
"Ternate","Ternate","0.7800","127.3819","Indonesia","ID","IDN","Maluku Utara","","204215","1360041663"
"Puqi","Puqi","29.7204","113.8800","China","CN","CHN","Hubei","","205164","1156189796"
"Qal‘at Bīshah","Qal\`at Bishah","20.0000","42.6000","Saudi Arabia","SA","SAU","‘Asīr","","205346","1682202412"
"Ellore","Ellore","16.7117","81.1031","India","IN","IND","Andhra Pradesh","","203780","1356931882"
"Betigeri","Betigeri","15.4430","75.6442","India","IN","IND","Karnātaka","","204576","1356503334"
"Passo Fundo","Passo Fundo","-28.2500","-52.4000","Brazil","BR","BRA","Rio Grande do Sul","","204722","1076763340"
"Banjar","Banjar","-7.3667","108.5333","Indonesia","ID","IDN","Jawa Barat","minor","203417","1360289512"
"Kinh Môn","Kinh Mon","21.0330","106.5000","Vietnam","VN","VNM","Hải Dương","minor","203638","1704502553"
"Bingerville","Bingerville","5.3500","-3.9000","Côte d'Ivoire","CI","CIV","Abidjan","minor","204656","1384754377"
"Bắc Giang","Bac Giang","21.2667","106.2000","Vietnam","VN","VNM","Bắc Giang","admin","201595","1704256754"
"Sejong","Sejong","36.4870","127.2822","South Korea","KR","KOR","Sejong","admin","204088","1410002592"
"Yachiyo","Yachiyo","35.7224","140.0999","Japan","JP","JPN","Chiba","","200538","1392929073"
"Amarillo","Amarillo","35.1984","-101.8316","United States","US","USA","Texas","","204357","1840019156"
"Mandi Burewala","Mandi Burewala","30.1500","72.6833","Pakistan","PK","PAK","Punjab","","203454","1586997587"
"Vĩnh Long","Vinh Long","10.2500","105.9667","Vietnam","VN","VNM","Vĩnh Long","admin","200120","1704262419"
"Mokameh","Mokameh","25.3898","85.9191","India","IN","IND","Bihār","","202411","1356053965"
"Coquimbo","Coquimbo","-29.9531","-71.3433","Chile","CL","CHL","Coquimbo","minor","204068","1152038253"
"Tekirdağ","Tekirdag","40.9778","27.5153","Turkey","TR","TUR","Tekirdağ","admin","204001","1792519395"
"Huntington","Huntington","40.8521","-73.3823","United States","US","USA","New York","","204147","1840058192"
"Parnamirim","Parnamirim","-5.9167","-35.2500","Brazil","BR","BRA","Rio Grande do Norte","","202456","1076600360"
"Holon","Holon","32.0167","34.7667","Israel","IL","ISR","Tel Aviv","","194300","1376222772"
"Kukawa","Kukawa","12.9167","13.5667","Nigeria","NG","NGA","Borno","minor","203864","1566891739"
"Biysk","Biysk","52.5333","85.2167","Russia","RU","RUS","Altayskiy Kray","","203108","1643652314"
"Charleroi","Charleroi","50.4000","4.4333","Belgium","BE","BEL","Wallonia","minor","201816","1056263311"
"Aberdeen","Aberdeen","57.1500","-2.1100","United Kingdom","GB","GBR","Aberdeen City","","200680","1826324306"
"Deoghar","Deoghar","24.4800","86.7000","India","IN","IND","Jhārkhand","","203116","1356054191"
"La Guaira","La Guaira","10.6000","-66.9331","Venezuela","VE","VEN","La Guaira","admin","203520","1862282663"
"Bīrjand","Birjand","32.8667","59.2167","Iran","IR","IRN","Khorāsān-e Jonūbī","admin","203636","1364897698"
"Chhindwāra","Chhindwara","22.0570","78.9396","India","IN","IND","Madhya Pradesh","","202688","1356167692"
"Timayy al Imdīd","Timayy al Imdid","30.9438","31.5293","Egypt","EG","EGY","Ad Daqahlīyah","","201956","1818481287"
"Mazabuka","Mazabuka","-15.8467","27.7475","Zambia","ZM","ZMB","Southern","","203219","1894390715"
"Poza Rica de Hidalgo","Poza Rica de Hidalgo","20.5333","-97.4500","Mexico","MX","MEX","Veracruz","minor","200119","1484808798"
"Toruń","Torun","53.0222","18.6111","Poland","PL","POL","Kujawsko-Pomorskie","admin","201447","1616267221"
"Tanjungpinang","Tanjungpinang","0.9188","104.4554","Indonesia","ID","IDN","Kepulauan Riau","admin","203008","1360990589"
"Kassel","Kassel","51.3158","9.4979","Germany","DE","DEU","Hesse","minor","201048","1276791686"
"Haldia","Haldia","22.0667","88.0698","India","IN","IND","West Bengal","","200827","1356491171"
"Luxor","Luxor","25.6833","32.6500","Egypt","EG","EGY","Al Uqşur","admin","202232","1818948225"
"Lạng Sơn","Lang Son","21.8478","106.7578","Vietnam","VN","VNM","Lạng Sơn","admin","200108","1704004349"
"At Tājī","At Taji","33.5092","44.2342","Iraq","IQ","IRQ","Baghdād","","200000","1368059456"
"Tarakan","Tarakan","3.3000","117.6333","Indonesia","ID","IDN","Kalimantan Utara","","201635","1360080072"
"Las Tunas","Las Tunas","20.9597","-76.9544","Cuba","CU","CUB","Las Tunas","admin","202105","1192626319"
"Al Khawr","Al Khawr","25.6900","51.5100","Qatar","QA","QAT","Al Khawr wa adh Dhakhīrah","admin","202031","1634786937"
"Matsue","Matsue","35.4708","133.0519","Japan","JP","JPN","Shimane","admin","201802","1392692238"
"Arica","Arica","-18.4667","-70.3167","Chile","CL","CHL","Arica y Parinacota","admin","202131","1152531089"
"Hà Tĩnh","Ha Tinh","18.3333","105.9000","Vietnam","VN","VNM","Hà Tĩnh","admin","202062","1704544061"
"Cosenza","Cosenza","39.3000","16.2500","Italy","IT","ITA","Calabria","minor","200257","1380732659"
"Al Khums","Al Khums","32.6497","14.2644","Libya","LY","LBY","Al Marqab","","201943","1434172839"
"Huacho","Huacho","-11.1000","-77.6000","Peru","PE","PER","Lima","admin","200585","1604316784"
"El Jadid","El Jadid","33.2333","-8.5000","Morocco","MA","MAR","Casablanca-Settat","","194934","1504964063"
"Cajamarca","Cajamarca","-7.1667","-78.5167","Peru","PE","PER","Cajamarca","admin","201329","1604091119"
"Norwich","Norwich","41.5495","-72.0882","United States","US","USA","Connecticut","","201726","1840004828"
"Ciudad Madero","Ciudad Madero","22.2500","-97.8333","Mexico","MX","MEX","Tamaulipas","minor","197216","1484185237"
"Mai’Adua","Mai'Adua","13.1906","8.2117","Nigeria","NG","NGA","Katsina","minor","201178","1566695464"
"Jacobabad","Jacobabad","28.2769","68.4514","Pakistan","PK","PAK","Sindh","minor","200815","1586964444"
"Almería","Almeria","36.8403","-2.4681","Spain","ES","ESP","Andalusia","minor","200753","1724745640"
"Tokat","Tokat","40.3139","36.5542","Turkey","TR","TUR","Tokat","admin","201294","1792534905"
"Sātkhira","Satkhira","22.7188","89.0713","Bangladesh","BD","BGD","Khulna","","200000","1050784467"
"Néma","Nema","16.6167","-7.2500","Mauritania","MR","MRT","Hodh ech Chargui","admin","200000","1478009046"
"Khandwa","Khandwa","21.8200","76.3500","India","IN","IND","Madhya Pradesh","","200738","1356696980"
"Pulimaddi","Pulimaddi","15.4800","78.4800","India","IN","IND","Andhra Pradesh","","200516","1356431352"
"Morena","Morena","26.5000","78.0000","India","IN","IND","Madhya Pradesh","","200483","1356019851"
"Soio","Soio","-6.1333","12.3667","Angola","AO","AGO","Zaire","","200920","1024903782"
"Guacara","Guacara","10.2333","-67.8833","Venezuela","VE","VEN","Carabobo","minor","200212","1862573240"
"Olympia","Olympia","47.0417","-122.8959","United States","US","USA","Washington","admin","200836","1840019865"
"Kabankalan","Kabankalan","9.9833","122.8167","Philippines","PH","PHL","Negros Occidental","","200198","1608697923"
"Isidro Casanova","Isidro Casanova","-34.7000","-58.5833","Argentina","AR","ARG","Buenos Aires","","190696","1032715964"
"Rio Claro","Rio Claro","-22.4108","-47.5608","Brazil","BR","BRA","São Paulo","","199961","1076411428"
"Santa Tecla","Santa Tecla","13.6731","-89.2406","El Salvador","SV","SLV","La Libertad","admin","199103","1222613306"
"Isanlu","Isanlu","8.1667","5.8000","Nigeria","NG","NGA","Kogi","minor","200000","1566562770"
"Az Zāwīyah","Az Zawiyah","32.7522","12.7278","Libya","LY","LBY","Az Zāwiyah","admin","200000","1434421178"
"Kasama","Kasama","-10.2117","31.1783","Zambia","ZM","ZMB","Northern","admin","200000","1894305215"
"Maharagama","Maharagama","6.8494","79.9236","Sri Lanka","LK","LKA","Western","","195355","1144551969"
"Kouribga","Kouribga","32.8833","-6.9167","Morocco","MA","MAR","Béni Mellal-Khénifra","","196196","1504325928"
"Amroha","Amroha","28.9044","78.4675","India","IN","IND","Uttar Pradesh","","198471","1356146062"
"Lahad Datu","Lahad Datu","5.0300","118.3400","Malaysia","MY","MYS","Sabah","","199830","1458472057"
"Chilpancingo","Chilpancingo","17.5500","-99.5000","Mexico","MX","MEX","Guerrero","admin","187251","1484290575"
"Drabar","Drabar","33.4300","75.0900","India","IN","IND","Jammu and Kashmīr","","199429","1356426484"
"Waterbury","Waterbury","41.5582","-73.0361","United States","US","USA","Connecticut","","199552","1840004851"
"Liège","Liege","50.6397","5.5706","Belgium","BE","BEL","Wallonia","minor","196623","1056513284"
"Banja Luka","Banja Luka","44.7725","17.1925","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","admin","199191","1070859064"
"Salinas","Salinas","36.6883","-121.6317","United States","US","USA","California","","199290","1840021632"
"Huntington","Huntington","38.4109","-82.4345","United States","US","USA","West Virginia","","199133","1840006211"
"Alcalá de Henares","Alcala de Henares","40.4818","-3.3643","Spain","ES","ESP","Madrid","","196888","1724663253"
"Taranto","Taranto","40.4711","17.2431","Italy","IT","ITA","Puglia","minor","198283","1380707445"
"Khowy","Khowy","38.5519","44.9536","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","198845","1364186788"
"Uripa","Uripa","11.9167","-72.0000","Colombia","CO","COL","La Guajira","","198890","1170417561"
"Kankan","Kankan","10.3833","-9.3000","Guinea","GN","GIN","Kankan","admin","198013","1324495325"
"Huntington Beach","Huntington Beach","33.6960","-118.0018","United States","US","USA","California","","198735","1840020578"
"Clarksville","Clarksville","36.5692","-87.3413","United States","US","USA","Tennessee","","198689","1840014421"
"Martapura","Martapura","-3.4500","114.8333","Indonesia","ID","IDN","Kalimantan Selatan","minor","198239","1360139854"
"Ciudad del Carmen","Ciudad del Carmen","18.6333","-91.8333","Mexico","MX","MEX","Campeche","minor","191238","1484192447"
"Bhind","Bhind","26.5587","78.7871","India","IN","IND","Madhya Pradesh","","197585","1356023125"
"Chakradharpur","Chakradharpur","22.7000","85.6300","India","IN","IND","Jhārkhand","","197953","1356082013"
"Tocuyito","Tocuyito","10.0889","-68.0922","Venezuela","VE","VEN","Carabobo","minor","197446","1862659974"
"Alvorada","Alvorada","-29.9897","-51.0833","Brazil","BR","BRA","Rio Grande do Sul","","195673","1076999998"
"Richmond","Richmond","49.1667","-123.1333","Canada","CA","CAN","British Columbia","","198309","1124121940"
"Araçatuba","Aracatuba","-21.2089","-50.4328","Brazil","BR","BRA","São Paulo","","198129","1076630308"
"Santa Barbara","Santa Barbara","34.4285","-119.7202","United States","US","USA","California","","198240","1840021829"
"Gemena","Gemena","3.2500","19.7667","Congo (Kinshasa)","CD","COD","Sud-Ubangi","admin","198056","1180000163"
"Oradea","Oradea","47.0722","21.9211","Romania","RO","ROU","Bihor","admin","196367","1642066626"
"Desē","Dese","11.1333","39.6333","Ethiopia","ET","ETH","Āmara","","187900","1231359719"
"Helong","Helong","42.5404","129.0039","China","CN","CHN","Jilin","minor","197885","1156369451"
"Croydon","Croydon","51.3727","-0.1099","United Kingdom","GB","GBR","Croydon","","192064","1826126675"
"Béni Mellal","Beni Mellal","32.3394","-6.3608","Morocco","MA","MAR","Béni Mellal-Khénifra","admin","192676","1504131639"
"Madhyamgram","Madhyamgram","22.7000","88.4500","India","IN","IND","West Bengal","","196127","1356501701"
"Mwene-Ditu","Mwene-Ditu","-7.0000","23.4500","Congo (Kinshasa)","CD","COD","Lomami","","195622","1180134137"
"Khammam","Khammam","17.2473","80.1514","India","IN","IND","Andhra Pradesh","","196283","1356007170"
"Bhiwāni","Bhiwani","28.7800","76.1300","India","IN","IND","Haryāna","","197662","1356780092"
"Higashi-Hiroshima","Higashi-Hiroshima","34.4167","132.7333","Japan","JP","JPN","Hiroshima","","197347","1392464732"
"N’Zérékoré","N'Zerekore","7.7500","-8.8167","Guinea","GN","GIN","N’Zérékoré","admin","195027","1324336588"
"Huánuco","Huanuco","-9.9295","-76.2397","Peru","PE","PER","Huánuco","admin","196627","1604589102"
"Rzeszów","Rzeszow","50.0333","22.0000","Poland","PL","POL","Podkarpackie","admin","195871","1616879570"
"Eldoret","Eldoret","0.5167","35.2833","Kenya","KE","KEN","Uasin Gishu","admin","193830","1404676289"
"Mbanza-Ngungu","Mbanza-Ngungu","-5.2500","14.8667","Congo (Kinshasa)","CD","COD","Kongo Central","","197545","1180474388"
"Shrīrāmpur","Shrirampur","22.7500","88.3400","India","IN","IND","West Bengal","","181842","1356021505"
"Hino","Hino","35.6713","139.3951","Japan","JP","JPN","Tōkyō","","190598","1392686410"
"Zipaquirá","Zipaquira","5.0333","-74.0000","Colombia","CO","COL","Cundinamarca","minor","196409","1170798590"
"Rio Grande","Rio Grande","-32.0350","-52.0986","Brazil","BR","BRA","Rio Grande do Sul","","197228","1076567920"
"Prokopyevsk","Prokopyevsk","53.8833","86.7167","Russia","RU","RUS","Kemerovskaya Oblast’","","196406","1643653477"
"Rajin","Rajin","42.3444","130.3844","North Korea","KP","PRK","Rasŏn","admin","196954","1408449973"
"Fuenlabrada","Fuenlabrada","40.2833","-3.8000","Spain","ES","ESP","Madrid","","192233","1724000348"
"Ghāndīnagar","Ghandinagar","23.2230","72.6500","India","IN","IND","Gujarāt","admin","195891","1356140914"
"Brescia","Brescia","45.5389","10.2203","Italy","IT","ITA","Lombardy","minor","196745","1380432555"
"Kusŏng","Kusong","39.9667","125.1667","North Korea","KP","PRK","P’yŏngbuk","","196515","1408227006"
"Baharampur","Baharampur","24.1000","88.2500","India","IN","IND","West Bengal","","195223","1356612685"
"Sūhāj","Suhaj","26.5500","31.7000","Egypt","EG","EGY","Sūhāj","admin","193931","1818460403"
"Acarigua","Acarigua","9.5597","-69.2019","Venezuela","VE","VEN","Portuguesa","minor","195637","1862134462"
"Hugli","Hugli","22.9089","88.3967","India","IN","IND","West Bengal","","179931","1356109548"
"San Pedro de Macorís","San Pedro de Macoris","18.4500","-69.3000","Dominican Republic","DO","DOM","Higuamo","admin","195307","1214233006"
"Glendale","Glendale","34.1819","-118.2468","United States","US","USA","California","","196512","1840020483"
"Mbarara","Mbarara","-0.6133","30.6583","Uganda","UG","UGA","Mbarara","admin","195013","1800554483"
"Parma","Parma","44.8015","10.3280","Italy","IT","ITA","Emilia-Romagna","minor","195687","1380655938"
"Sunrise Manor","Sunrise Manor","36.1783","-115.0487","United States","US","USA","Nevada","","196411","1840033833"
"Kenema","Kenema","7.8758","-11.1908","Sierra Leone","SL","SLE","Eastern","admin","188463","1694780903"
"Brikama","Brikama","13.2667","-16.6500","The Gambia","GM","GMB","West Coast","admin","195136","1270953861"
"Suzuka","Suzuka","34.8820","136.5842","Japan","JP","JPN","Mie","","195250","1392913924"
"Kajo Kaji","Kajo Kaji","3.8492","31.6578","South Sudan","SS","SSD","Central Equatoria","","196000","1728764691"
"Koronadal","Koronadal","6.5000","124.8500","Philippines","PH","PHL","South Cotabato","","195398","1608000651"
"Cuautla","Cuautla","18.8167","-98.9500","Mexico","MX","MEX","Morelos","minor","194786","1484359683"
"Grand Prairie","Grand Prairie","32.6871","-97.0208","United States","US","USA","Texas","","195992","1840020709"
"Viranşehir","Viransehir","37.2306","39.7653","Turkey","TR","TUR","Şanlıurfa","minor","195910","1792566461"
"Iskandar","Iskandar","41.5581","69.7047","Uzbekistan","UZ","UZB","Toshkent","","195633","1860285858"
"Karaköprü","Karakopru","37.1847","38.7908","Turkey","TR","TUR","Şanlıurfa","minor","195552","1792525360"
"Sosnowiec","Sosnowiec","50.2833","19.1333","Poland","PL","POL","Śląskie","minor","193660","1616057255"
"Tanauan","Tanauan","14.0833","121.1500","Philippines","PH","PHL","Batangas","","193936","1608053818"
"Myawadi","Myawadi","16.6878","98.5083","Myanmar","MM","MMR","Kayin State","","195624","1104432237"
"La Serena","La Serena","-29.9000","-71.2500","Chile","CL","CHL","Coquimbo","admin","195382","1152155065"
"Sodo","Sodo","6.8550","37.7808","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","194977","1231130720"
"Trondheim","Trondheim","63.4297","10.3933","Norway","NO","NOR","Trøndelag","minor","194860","1578633461"
"Morbi","Morbi","22.8200","70.8300","India","IN","IND","Gujarāt","","194947","1356746232"
"Ārba Minch’","Arba Minch'","6.0333","37.5500","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","192043","1231302134"
"Prato","Prato","43.8808","11.0966","Italy","IT","ITA","Tuscany","minor","193325","1380751753"
"Overland Park","Overland Park","38.8870","-94.6870","United States","US","USA","Kansas","","195249","1840003834"
"Tchitato","Tchitato","-7.3450","20.8198","Angola","AO","AGO","Lunda-Norte","","195136","1024883433"
"Malema","Malema","-14.9486","37.4144","Mozambique","MZ","MOZ","Nampula","","195077","1508782895"
"Anseong","Anseong","37.0078","127.2797","South Korea","KR","KOR","Gyeonggi","","194765","1410960903"
"Yuzhno-Sakhalinsk","Yuzhno-Sakhalinsk","46.9500","142.7333","Russia","RU","RUS","Sakhalinskaya Oblast’","admin","194882","1643703767"
"Richmond Hill","Richmond Hill","43.8667","-79.4333","Canada","CA","CAN","Ontario","","195022","1124364273"
"Narsingdi","Narsingdi","23.9167","90.7167","Bangladesh","BD","BGD","Dhaka","","185128","1050904616"
"Gorontalo","Gorontalo","0.5333","123.0667","Indonesia","ID","IDN","Gorontalo","admin","191897","1360408580"
"Guanajuato","Guanajuato","21.0178","-101.2567","Mexico","MX","MEX","Guanajuato","admin","194500","1484185068"
"Hagerstown","Hagerstown","39.6401","-77.7217","United States","US","USA","Maryland","","194602","1840005613"
"Peterborough","Peterborough","52.5662","-0.2366","United Kingdom","GB","GBR","Peterborough","","194000","1826626144"
"Nampa","Nampa","43.5844","-116.5638","United States","US","USA","Idaho","","194547","1840020041"
"Quelimane","Quelimane","-17.8764","36.8872","Mozambique","MZ","MOZ","Zambézia","admin","192876","1508396687"
"Tébessa","Tebessa","35.4000","8.1167","Algeria","DZ","DZA","Tébessa","admin","194461","1012196375"
"Kumagaya","Kumagaya","36.1474","139.3886","Japan","JP","JPN","Saitama","","193190","1392500750"
"San Fernando","San Fernando","7.8940","-67.4730","Venezuela","VE","VEN","Apure","admin","194274","1862404343"
"Rijeka","Rijeka","45.3272","14.4411","Croatia","HR","HRV","Primorsko-Goranska Županija","admin","191293","1191949063"
"Gastonia","Gastonia","35.2494","-81.1854","United States","US","USA","North Carolina","","194190","1840013468"
"Mahbūbnagar","Mahbubnagar","16.7488","78.0035","India","IN","IND","Andhra Pradesh","","190400","1356014528"
"Karaman","Karaman","37.1819","33.2181","Turkey","TR","TUR","Karaman","admin","194018","1792285496"
"La Plata","La Plata","-34.9211","-57.9544","Argentina","AR","ARG","Buenos Aires","admin","193144","1032403831"
"Fatehpur","Fatehpur","25.9300","80.8000","India","IN","IND","Uttar Pradesh","","193193","1356059135"
"Pāndhurnā","Pandhurna","21.6000","78.5200","India","IN","IND","Madhya Pradesh","","193818","1356533618"
"Yamaguchi","Yamaguchi","34.1833","131.4667","Japan","JP","JPN","Yamaguchi","admin","193761","1392887215"
"Lae","Lae","-6.7303","147.0008","Papua New Guinea","PG","PNG","Morobe","admin","193000","1598466091"
"Muar","Muar","2.0500","102.5600","Malaysia","MY","MYS","Johor","","191346","1458170112"
"Cap-Haïtien","Cap-Haitien","19.7600","-72.2000","Haiti","HT","HTI","Nord","admin","190289","1332333795"
"Oakville","Oakville","43.4500","-79.6833","Canada","CA","CAN","Ontario","","193832","1124080468"
"Godoy Cruz","Godoy Cruz","-32.9167","-68.8333","Argentina","AR","ARG","Mendoza","minor","191299","1032263200"
"Būkān","Bukan","36.5172","46.2114","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","193501","1364530447"
"Muş","Mus","38.7333","41.4911","Turkey","TR","TUR","Muş","admin","193394","1792530937"
"Hyesan","Hyesan","41.4000","128.1833","North Korea","KP","PRK","Ryanggang","admin","192680","1408642146"
"Kielce","Kielce","50.8742","20.6333","Poland","PL","POL","Świętokrzyskie","admin","191605","1616176351"
"Frisco","Frisco","33.1560","-96.8216","United States","US","USA","Texas","","193140","1840020654"
"Kırıkkale","Kirikkale","39.8417","33.5139","Turkey","TR","TUR","Kırıkkale","admin","193093","1792859558"
"Jalapa","Jalapa","14.6379","-89.9904","Guatemala","GT","GTM","Jalapa","admin","192676","1320108089"
"Rājendranagar","Rajendranagar","17.3198","78.4031","India","IN","IND","Andhra Pradesh","","189076","1356684638"
"Castanhal","Castanhal","-1.2969","-47.9219","Brazil","BR","BRA","Pará","","192571","1076216938"
"Guanare","Guanare","9.0436","-69.7489","Venezuela","VE","VEN","Portuguesa","admin","192644","1862645590"
"Cedar Rapids","Cedar Rapids","41.9662","-91.6783","United States","US","USA","Iowa","","192714","1840000471"
"Basildon","Basildon","51.5800","0.4900","United Kingdom","GB","GBR","Essex","","185900","1826481004"
"Fernando de la Mora","Fernando de la Mora","-25.3200","-57.5400","Paraguay","PY","PRY","Central","","183390","1600276744"
"Leganés","Leganes","40.3281","-3.7644","Spain","ES","ESP","Madrid","","187762","1724900225"
"Pābna","Pabna","24.0167","89.2167","Bangladesh","BD","BGD","Rājshāhi","","190317","1050424286"
"Rāe Bareli","Rae Bareli","26.2236","81.2403","India","IN","IND","Uttar Pradesh","","191316","1356437243"
"Florencia","Florencia","1.6139","-75.6128","Colombia","CO","COL","Caquetá","admin","191867","1170606634"
"General Mariano Alvarez","General Mariano Alvarez","14.3000","121.0000","Philippines","PH","PHL","Cavite","","172433","1608815735"
"Fianarantsoa","Fianarantsoa","-21.4536","47.0858","Madagascar","MG","MDG","Fianarantsoa","admin","191776","1450070223"
"San Cristóbal","San Cristobal","16.7370","-92.6376","Mexico","MX","MEX","Chiapas","minor","183509","1484451215"
"Bago","Bago","10.5388","122.8384","Philippines","PH","PHL","Negros Occidental","","191210","1608943172"
"Valera","Valera","9.3206","-70.6078","Venezuela","VE","VEN","Trujillo","minor","191167","1862735261"
"Bournemouth","Bournemouth","50.7200","-1.8800","United Kingdom","GB","GBR","","","187503","1826769743"
"Pasuruan","Pasuruan","-7.6406","112.9065","Indonesia","ID","IDN","Jawa Timur","","186262","1360084806"
"Armavir","Armavir","45.0000","41.1167","Russia","RU","RUS","Krasnodarskiy Kray","","190709","1643809754"
"Balakovo","Balakovo","52.0333","47.7833","Russia","RU","RUS","Saratovskaya Oblast’","","191260","1643480033"
"Jhelum","Jhelum","32.9425","73.7256","Pakistan","PK","PAK","Punjab","minor","190425","1586164490"
"Donostia","Donostia","43.3200","-1.9800","Spain","ES","ESP","Basque Country","minor","188102","1724910555"
"Hemet","Hemet","33.7341","-116.9969","United States","US","USA","California","","191170","1840020550"
"Aydın","Aydin","37.8481","27.8453","Turkey","TR","TUR","Aydın","admin","191037","1792696058"
"Batu","Batu","-7.8720","112.5250","Indonesia","ID","IDN","Jawa Timur","","190184","1360427259"
"Orai","Orai","25.9800","79.4700","India","IN","IND","Uttar Pradesh","","190575","1356928689"
"Manzanillo","Manzanillo","19.0522","-104.3158","Mexico","MX","MEX","Colima","minor","191031","1484223913"
"Anjōmachi","Anjomachi","34.9587","137.0803","Japan","JP","JPN","Aichi","","188801","1392081830"
"Lam Tin","Lam Tin","22.3094","114.2361","Hong Kong","HK","HKG","","","131000","1344141583"
"Malaybalay","Malaybalay","8.1564","125.1333","Philippines","PH","PHL","Bukidnon","admin","190712","1608859265"
"Ferraz de Vasconcelos","Ferraz de Vasconcelos","-23.5411","-46.3689","Brazil","BR","BRA","São Paulo","","184700","1076173995"
"Santa Bárbara d’Oeste","Santa Barbara d'Oeste","-22.7544","-47.4142","Brazil","BR","BRA","São Paulo","","190139","1076199404"
"Chibia","Chibia","-15.1833","13.7000","Angola","AO","AGO","Huíla","","190670","1024017930"
"Odawara","Odawara","35.2500","139.1500","Japan","JP","JPN","Kanagawa","","189038","1392003516"
"Kishiwada","Kishiwada","34.4667","135.3667","Japan","JP","JPN","Ōsaka","","188015","1392003372"
"Saddiqabad","Saddiqabad","28.3006","70.1302","Pakistan","PK","PAK","Punjab","","189876","1586378758"
"Shahr-e Kord","Shahr-e Kord","32.3311","50.8594","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","admin","190441","1364643377"
"Rybinsk","Rybinsk","58.0500","38.8333","Russia","RU","RUS","Yaroslavskaya Oblast’","","190429","1643113701"
"Abū Ghurayb","Abu Ghurayb","33.2919","44.0656","Iraq","IQ","IRQ","Baghdād","minor","189000","1368635737"
"Chŏngju","Chongju","39.6500","125.3333","North Korea","KP","PRK","P’yŏngbuk","","189742","1408650871"
"Cachoeiro de Itapemirim","Cachoeiro de Itapemirim","-20.8489","-41.1128","Brazil","BR","BRA","Espírito Santo","","189889","1076536175"
"Hagen","Hagen","51.3667","7.4833","Germany","DE","DEU","North Rhine-Westphalia","minor","188713","1276389303"
"Paradise","Paradise","36.0872","-115.1355","United States","US","USA","Nevada","","189852","1840033743"
"Ngaoundéré","Ngaoundere","7.3167","13.5833","Cameroon","CM","CMR","Adamaoua","admin","189800","1120011020"
"Pak Kret","Pak Kret","13.9125","100.4978","Thailand","TH","THA","Nonthaburi","minor","184501","1764165203"
"Donghua","Donghua","35.2175","106.6545","China","CN","CHN","Gansu","minor","189333","1156975773"
"Braga","Braga","41.5503","-8.4200","Portugal","PT","PRT","Braga","admin","181494","1620949401"
"Sioux Falls","Sioux Falls","43.5396","-96.7311","United States","US","USA","South Dakota","","189258","1840002648"
"Las Heras","Las Heras","-32.8500","-68.8167","Argentina","AR","ARG","Mendoza","minor","189067","1032781204"
"Araure","Araure","9.5667","-69.2167","Venezuela","VE","VEN","Portuguesa","minor","188800","1862226710"
"Digos","Digos","6.7500","125.3500","Philippines","PH","PHL","Davao del Sur","admin","188376","1608635653"
"Pinar del Río","Pinar del Rio","22.4122","-83.6719","Cuba","CU","CUB","Pinar del Río","admin","188614","1192216820"
"El Progreso","El Progreso","15.4000","-87.8000","Honduras","HN","HND","Yoro","","188366","1340231948"
"Numazu","Numazu","35.0956","138.8634","Japan","JP","JPN","Shizuoka","","187653","1392003112"
"Rāiganj","Raiganj","25.6200","88.1200","India","IN","IND","West Bengal","","183612","1356159126"
"Tachikawa","Tachikawa","35.6942","139.4197","Japan","JP","JPN","Tōkyō","","181115","1392042507"
"Prabumulih","Prabumulih","-3.4328","104.2356","Indonesia","ID","IDN","Sumatera Selatan","minor","188082","1360148993"
"Angra dos Reis","Angra dos Reis","-23.0067","-44.3181","Brazil","BR","BRA","Rio de Janeiro","","188276","1076039737"
"Bhusāval","Bhusaval","21.0500","75.7700","India","IN","IND","Mahārāshtra","","187421","1356338789"
"San Francisco de Macorís","San Francisco de Macoris","19.3000","-70.2500","Dominican Republic","DO","DOM","Cibao Nordeste","admin","188118","1214710545"
"Vancouver","Vancouver","45.6366","-122.5967","United States","US","USA","Washington","","188331","1840021189"
"Sobral","Sobral","-3.6739","-40.2389","Brazil","BR","BRA","Ceará","","188233","1076577102"
"Al Ḩasakah","Al Hasakah","36.5117","40.7422","Syria","SY","SYR","Al Ḩasakah","admin","188160","1760476337"
"Klerksdorp","Klerksdorp","-26.8667","26.6667","South Africa","ZA","ZAF","North West","","186515","1710416691"
"Kecskemét","Kecskemet","46.9061","19.6897","Hungary","HU","HUN","Bács-Kiskun","admin","187835","1348900171"
"Guatire","Guatire","10.4717","-66.5406","Venezuela","VE","VEN","Miranda","minor","187262","1862199442"
"Iquique","Iquique","-20.2167","-70.1500","Chile","CL","CHL","Tarapacá","admin","188003","1152234625"
"El Bosque","El Bosque","-33.5667","-70.6750","Chile","CL","CHL","Región Metropolitana","","175594","1152734764"
"Kōfu","Kofu","35.6621","138.5682","Japan","JP","JPN","Yamanashi","admin","187144","1392307565"
"San Diego","San Diego","10.2558","-67.9539","Venezuela","VE","VEN","Carabobo","minor","187215","1862530883"
"Gharyān","Gharyan","32.1697","13.0167","Libya","LY","LBY","Al Jabal al Gharbī","admin","187854","1434507846"
"Thingangyun","Thingangyun","16.8281","96.1842","Myanmar","MM","MMR","Yangon","","170000","1104747384"
"Peoria","Peoria","33.7843","-112.2989","United States","US","USA","Arizona","","187733","1840020567"
"Bārākpur","Barakpur","22.7642","88.3776","India","IN","IND","West Bengal","","152783","1356768708"
"Padalarang","Padalarang","-6.8435","107.4779","Indonesia","ID","IDN","Jawa Barat","","184182","1360652847"
"Tottori","Tottori","35.5000","134.2333","Japan","JP","JPN","Tottori","","187323","1392416969"
"Chaedŏk","Chaedok","40.7988","129.3602","North Korea","KP","PRK","Hambuk","","187270","1408001232"
"Jōetsu","Joetsu","37.1479","138.2361","Japan","JP","JPN","Niigata","","187291","1392040772"
"Bahraigh","Bahraigh","27.5750","81.5940","India","IN","IND","Uttar Pradesh","","186223","1356833304"
"Ucu Seles","Ucu Seles","-11.4047","14.3006","Angola","AO","AGO","Cuanza-Sul","","187127","1024868244"
"Calbayog City","Calbayog City","12.0667","124.6000","Philippines","PH","PHL","Samar","","186960","1608088739"
"Waco","Waco","31.5599","-97.1882","United States","US","USA","Texas","","187172","1840022140"
"Kairouan","Kairouan","35.6772","10.1008","Tunisia","TN","TUN","Kairouan","admin","186653","1788844707"
"Tambaram","Tambaram","12.8310","80.0459","India","IN","IND","Tamil Nādu","","182071","1356045454"
"Cabo Frio","Cabo Frio","-22.8789","-42.0189","Brazil","BR","BRA","Rio de Janeiro","","186227","1076449553"
"Shendi","Shendi","16.6833","33.4333","Sudan","SD","SDN","River Nile","","186611","1729244380"
"Lorain","Lorain","41.4409","-82.1840","United States","US","USA","Ohio","","186500","1840000644"
"Erie","Erie","42.1167","-80.0733","United States","US","USA","Pennsylvania","","186489","1840000478"
"Shibīn al Kawm","Shibin al Kawm","30.5586","31.0100","Egypt","EG","EGY","Al Minūfīyah","admin","177112","1818787044"
"Ed Damazin","Ed Damazin","11.7667","34.3500","Sudan","SD","SDN","Blue Nile","admin","186051","1729684147"
"Umreth","Umreth","22.6986","73.1156","India","IN","IND","Gujarāt","","185320","1356307988"
"Reims","Reims","49.2628","4.0347","France","FR","FRA","Grand Est","minor","182211","1250608730"
"Dunhuang","Dunhuang","40.1411","94.6639","China","CN","CHN","Gansu","","186027","1156068377"
"Mahesāna","Mahesana","23.6000","72.4000","India","IN","IND","Gujarāt","","184991","1356719082"
"Nossa Senhora do Socorro","Nossa Senhora do Socorro","-10.8550","-37.0592","Brazil","BR","BRA","Sergipe","","185706","1076881156"
"Breda","Breda","51.5889","4.7758","Netherlands","NL","NLD","Noord-Brabant","minor","184126","1528921549"
"Getafe","Getafe","40.3047","-3.7311","Spain","ES","ESP","Madrid","","183219","1724750196"
"Modena","Modena","44.6458","10.9257","Italy","IT","ITA","Emilia-Romagna","minor","185273","1380306270"
"Guri","Guri","37.6000","127.1500","South Korea","KR","KOR","Gyeonggi","","180063","1410478813"
"Jamaame","Jamaame","0.0667","42.7500","Somalia","SO","SOM","Jubbada Hoose","","185270","1706288768"
"Basel","Basel","47.5547","7.5906","Switzerland","CH","CHE","Basel-Stadt","admin","177827","1756731313"
"Tân Châu","Tan Chau","10.7739","105.2369","Vietnam","VN","VNM","An Giang","minor","184129","1704250945"
"Dinapore","Dinapore","25.6225","85.0417","India","IN","IND","Bihār","","182241","1356729186"
"Semnān","Semnan","35.5728","53.3972","Iran","IR","IRN","Semnān","admin","185129","1364417512"
"Temirtaū","Temirtau","50.0500","72.9500","Kazakhstan","KZ","KAZ","","","185082","1398081646"
"Toyokawa","Toyokawa","34.8268","137.3759","Japan","JP","JPN","Aichi","","183965","1392297078"
"Newport News","Newport News","37.1051","-76.5185","United States","US","USA","Virginia","","185069","1840003862"
"Yei","Yei","4.0904","30.6800","South Sudan","SS","SSD","Central Equatoria","","185000","1728607274"
"Alasandigutta","Alasandigutta","15.6300","77.2800","India","IN","IND","Andhra Pradesh","","184625","1356256634"
"Luzhang","Luzhang","25.8519","98.8562","China","CN","CHN","Yunnan","minor","184835","1156305822"
"Al Qāmishlī","Al Qamishli","37.0500","41.2200","Syria","SY","SYR","Al Ḩasakah","minor","184231","1760560461"
"Fardīs","Fardis","35.7167","50.9833","Iran","IR","IRN","Tehrān","","181174","1364967800"
"Chūō-ku","Chuo-ku","35.6706","139.7720","Japan","JP","JPN","Tōkyō","","169995","1392003401"
"Geelong","Geelong","-38.1500","144.3500","Australia","AU","AUS","Victoria","","184182","1036870987"
"Vĩnh Châu","Vinh Chau","9.3330","106.0000","Vietnam","VN","VNM","Sóc Trăng","minor","183918","1704458161"
"Siguiri","Siguiri","11.4189","-9.1644","Guinea","GN","GIN","Kankan","minor","183875","1324494896"
"Severodvinsk","Severodvinsk","64.5667","39.8667","Russia","RU","RUS","Arkhangel’skaya Oblast’","","183996","1643427671"
"Batāla","Batala","31.8186","75.2028","India","IN","IND","Punjab","","183314","1356786750"
"Potsdam","Potsdam","52.4000","13.0667","Germany","DE","DEU","Brandenburg","admin","183154","1276395100"
"Kottayam","Kottayam","9.5947","76.4856","India","IN","IND","Kerala","","182927","1356307269"
"Itajaí","Itajai","-26.9000","-48.6667","Brazil","BR","BRA","Santa Catarina","","183373","1076348188"
"Trảng Bàng","Trang Bang","11.0330","106.3670","Vietnam","VN","VNM","Tây Ninh","minor","183385","1704911723"
"Cuango","Cuango","-9.1444","18.0464","Angola","AO","AGO","Lunda-Norte","","183767","1024514740"
"Aqtaū","Aqtau","43.6525","51.1575","Kazakhstan","KZ","KAZ","","admin","183350","1398046759"
"Ongata Rongai","Ongata Rongai","-1.4000","36.7700","Kenya","KE","KEN","Kajiado","","172570","1404422353"
"Niš","Nis","43.3192","21.8961","Serbia","RS","SRB","Niš","admin","183164","1688661068"
"Cuauhtémoc","Cuauhtemoc","28.4050","-106.8667","Mexico","MX","MEX","Chihuahua","minor","180638","1484365643"
"Aurora","Aurora","41.7638","-88.2902","United States","US","USA","Illinois","","183447","1840007034"
"Narashino","Narashino","35.6804","140.0265","Japan","JP","JPN","Chiba","","174990","1392933042"
"Abakan","Abakan","53.7167","91.4667","Russia","RU","RUS","Khakasiya","admin","181709","1643946203"
"Burlington","Burlington","43.3167","-79.8000","Canada","CA","CAN","Ontario","","183314","1124955083"
"Cuautitlán","Cuautitlan","19.6833","-99.1833","Mexico","MX","MEX","México","minor","178847","1484256075"
"Odense","Odense","55.3958","10.3886","Denmark","DK","DNK","Syddanmark","minor","180863","1208194249"
"La Pintana","La Pintana","-33.5833","-70.6333","Chile","CL","CHL","Región Metropolitana","","177335","1152999558"
"Pinrang","Pinrang","-3.7857","119.6522","Indonesia","ID","IDN","Sulawesi Selatan","minor","182731","1360941841"
"Isiro","Isiro","2.7833","27.6167","Congo (Kinshasa)","CD","COD","Haut-Uélé","admin","182900","1180183850"
"Toulon","Toulon","43.1258","5.9306","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","178745","1250541629"
"Sorsogon","Sorsogon","12.9742","124.0058","Philippines","PH","PHL","Sorsogon","admin","182237","1608265953"
"Lagos de Moreno","Lagos de Moreno","21.3567","-101.9378","Mexico","MX","MEX","Jalisco","minor","172402","1484759605"
"Sirsa","Sirsa","29.5333","75.0167","India","IN","IND","Haryāna","","182534","1356966764"
"Dourados","Dourados","-22.2208","-54.8058","Brazil","BR","BRA","Mato Grosso do Sul","","182747","1076544482"
"Amadora","Amadora","38.7333","-9.2333","Portugal","PT","PRT","Lisboa","minor","175136","1620896557"
"Kāraikkudi","Karaikkudi","10.0735","78.7732","India","IN","IND","Tamil Nādu","","181851","1356512763"
"Brăila","Braila","45.2692","27.9575","Romania","RO","ROU","Brăila","admin","180302","1642286806"
"Uji","Uji","34.8844","135.7997","Japan","JP","JPN","Kyōto","","179626","1392148678"
"Nova Friburgo","Nova Friburgo","-22.2819","-42.5311","Brazil","BR","BRA","Rio de Janeiro","","182082","1076922983"
"Fort Lauderdale","Fort Lauderdale","26.1412","-80.1464","United States","US","USA","Florida","","182247","1840014236"
"Reggio di Calabria","Reggio di Calabria","38.1144","15.6500","Italy","IT","ITA","Calabria","minor","181447","1380630212"
"Berazategui","Berazategui","-34.7167","-58.2500","Argentina","AR","ARG","Buenos Aires","minor","180523","1032535314"
"Phan Rang-Tháp Chàm","Phan Rang-Thap Cham","11.5667","108.9833","Vietnam","VN","VNM","Ninh Thuận","admin","179773","1704094609"
"Vallejo","Vallejo","38.1125","-122.2342","United States","US","USA","California","","181882","1840021499"
"Dosquebradas","Dosquebradas","4.8333","-75.6833","Colombia","CO","COL","Risaralda","minor","179301","1170184135"
"Thanhlyin","Thanhlyin","16.7333","96.2500","Myanmar","MM","MMR","Yangon","","181000","1104911762"
"York","York","53.9601","-1.0780","United Kingdom","GB","GBR","York","","181131","1826149980"
"Petropavlovsk-Kamchatskiy","Petropavlovsk-Kamchatskiy","53.0167","158.6500","Russia","RU","RUS","Kamchatskiy Kray","admin","181216","1643129247"
"Himatnagar","Himatnagar","23.6000","72.9500","India","IN","IND","Gujarāt","","181137","1356123042"
"Alleppey","Alleppey","9.4900","76.3300","India","IN","IND","Kerala","","177079","1356293762"
"Epe","Epe","6.5573","3.9912","Nigeria","NG","NGA","Lagos","minor","181409","1566461573"
"Sittwe","Sittwe","20.1670","92.7850","Myanmar","MM","MMR","Rakhine State","admin","181000","1104269305"
"Dam Dam","Dam Dam","22.6200","88.4200","India","IN","IND","West Bengal","","114786","1356051452"
"Jaunpur","Jaunpur","25.7300","82.6800","India","IN","IND","Uttar Pradesh","","180362","1356646586"
"Murfreesboro","Murfreesboro","35.8490","-86.4121","United States","US","USA","Tennessee","","181450","1840014495"
"Jīma","Jima","7.6667","36.8333","Ethiopia","ET","ETH","Oromīya","","177900","1231214366"
"Sucúa","Sucua","-2.4600","-78.1700","Ecuador","EC","ECU","Morona-Santiago","","181318","1218279880"
"Kindia","Kindia","10.0497","-12.8542","Guinea","GN","GIN","Kindia","admin","181126","1324348910"
"Curug","Curug","-6.3711","106.8000","Indonesia","ID","IDN","Jawa Barat","","174867","1360625815"
"Guna","Guna","24.6500","77.3200","India","IN","IND","Madhya Pradesh","","180935","1356056608"
"Lhokseumawe","Lhokseumawe","5.1881","97.1403","Indonesia","ID","IDN","Aceh","","180200","1360418625"
"Roxas City","Roxas City","11.5894","122.7511","Philippines","PH","PHL","Capiz","admin","179292","1608344583"
"Funtua","Funtua","11.5204","7.3200","Nigeria","NG","NGA","Katsina","minor","180475","1566877863"
"Boma","Boma","-5.8500","13.0500","Congo (Kinshasa)","CD","COD","Kongo Central","","178638","1180611452"
"Turbo","Turbo","8.1000","-76.7333","Colombia","CO","COL","Antioquia","minor","181000","1170426476"
"Hinthada","Hinthada","17.6461","95.4603","Myanmar","MM","MMR","Ayeyarwady","","180728","1104524588"
"Çanakkale","Canakkale","40.1519","26.4056","Turkey","TR","TUR","Çanakkale","admin","180823","1792403093"
"La Rioja","La Rioja","-29.4125","-66.8542","Argentina","AR","ARG","La Rioja","admin","180995","1032864257"
"Madanapalle","Madanapalle","13.5500","78.5000","India","IN","IND","Andhra Pradesh","","180180","1356232508"
"Palopo","Palopo","-3.0000","120.2000","Indonesia","ID","IDN","Sulawesi Selatan","","180130","1360740288"
"Njeru","Njeru","0.4311","33.1478","Uganda","UG","UGA","Buikwe","","178800","1800578691"
"Obuase","Obuase","6.2000","-1.6833","Ghana","GH","GHA","Ashanti","","180460","1288615192"
"Nijmegen","Nijmegen","51.8475","5.8625","Netherlands","NL","NLD","Gelderland","minor","177659","1528136727"
"Saarbrücken","Saarbrucken","49.2333","7.0000","Germany","DE","DEU","Saarland","admin","179634","1276419711"
"San Luis Río Colorado","San Luis Rio Colorado","32.4767","-114.7625","Mexico","MX","MEX","Sonora","minor","176685","1484573106"
"Edirne","Edirne","41.6769","26.5556","Turkey","TR","TUR","Edirne","admin","180327","1792102607"
"Tiaret","Tiaret","35.3667","1.3167","Algeria","DZ","DZA","Tiaret","admin","178915","1012785868"
"Mahād","Mahad","18.0830","73.4170","India","IN","IND","Mahārāshtra","","180191","1356857934"
"Purwakarta","Purwakarta","-6.5533","107.4472","Indonesia","ID","IDN","Jawa Barat","minor","179233","1360967092"
"Longjing","Longjing","42.7700","129.4197","China","CN","CHN","Jilin","minor","180307","1156772152"
"Split","Split","43.5100","16.4400","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","admin","178102","1191440208"
"Ilhéus","Ilheus","-14.7889","-39.0489","Brazil","BR","BRA","Bahia","","180213","1076021121"
"Hosa’ina","Hosa'ina","7.5500","37.8500","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","179761","1231400775"
"Barra Mansa","Barra Mansa","-22.5439","-44.1708","Brazil","BR","BRA","Rio de Janeiro","","179915","1076686083"
"Shivpuri","Shivpuri","25.4300","77.6500","India","IN","IND","Madhya Pradesh","","179977","1356583527"
"Bhālswa Jahangirpur","Bhalswa Jahangirpur","28.7354","77.1638","India","IN","IND","Delhi","","151427","1356078316"
"Hamm","Hamm","51.6833","7.8167","Germany","DE","DEU","North Rhine-Westphalia","minor","179238","1276808737"
"Tongjiang","Tongjiang","47.6502","132.5030","China","CN","CHN","Heilongjiang","minor","180000","1156480258"
"Almada","Almada","38.6803","-9.1583","Portugal","PT","PRT","Setúbal","minor","177268","1620388276"
"Norilsk","Norilsk","69.3333","88.2167","Russia","RU","RUS","Krasnoyarskiy Kray","","179554","1643832764"
"Spring Hill","Spring Hill","28.4798","-82.5300","United States","US","USA","Florida","","179668","1840014105"
"Mongu","Mongu","-15.2775","23.1319","Zambia","ZM","ZMB","Western","admin","179585","1894761291"
"Al ‘Arīsh","Al \`Arish","31.1249","33.8006","Egypt","EG","EGY","Shamāl Sīnā’","admin","178651","1818291145"
"Divo","Divo","5.8333","-5.3667","Côte d'Ivoire","CI","CIV","Gôh-Djiboua","minor","179455","1384831436"
"Urayasu","Urayasu","35.6539","139.9022","Japan","JP","JPN","Chiba","","169749","1392003082"
"Bida","Bida","9.0804","6.0100","Nigeria","NG","NGA","Niger","minor","173849","1566468363"
"Bade","Bade","24.9575","121.2989","Taiwan","TW","TWN","Taoyuan","","172065","1158126203"
"Paita","Paita","-5.0911","-81.1064","Peru","PE","PER","Piura","","179346","1604588759"
"Torbalı","Torbali","38.1619","27.3583","Turkey","TR","TUR","İzmir","minor","178772","1792319298"
"Townsville","Townsville","-19.2500","146.8167","Australia","AU","AUS","Queensland","","178649","1036500020"
"Langsa","Langsa","4.4800","97.9633","Indonesia","ID","IDN","Aceh","","178334","1360337304"
"Dagupan City","Dagupan City","16.0430","120.3340","Philippines","PH","PHL","Dagupan","admin","174302","1608530964"
"Teziutlan","Teziutlan","19.8178","-97.3667","Mexico","MX","MEX","Puebla","","177796","1484709570"
"Tempe","Tempe","33.3881","-111.9319","United States","US","USA","Arizona","","178862","1840021942"
"High Point","High Point","35.9910","-79.9937","United States","US","USA","North Carolina","","178800","1840014479"
"Sultānpur Mazra","Sultanpur Mazra","28.6981","77.0689","India","IN","IND","Delhi","","163716","1356062549"
"Unnāo","Unnao","26.5500","80.4900","India","IN","IND","Uttar Pradesh","","177658","1356012340"
"Şalālah","Salalah","17.0197","54.0897","Oman","OM","OMN","Z̧ufār","admin","178469","1512995148"
"Medford","Medford","42.3372","-122.8540","United States","US","USA","Oregon","","178457","1840020076"
"Salatiga","Salatiga","-7.3247","110.5444","Indonesia","ID","IDN","Jawa Tengah","","175288","1360271289"
"Matosinhos","Matosinhos","41.1833","-8.7000","Portugal","PT","PRT","Porto","minor","175478","1620049442"
"Hamilton","Hamilton","-37.7833","175.2833","New Zealand","NZ","NZL","Waikato","admin","176500","1554873040"
"Lin’an","Lin'an","23.6236","102.8097","China","CN","CHN","Yunnan","minor","177303","1156181740"
"Chicoloapan","Chicoloapan","19.4167","-98.9000","Mexico","MX","MEX","México","minor","175053","1484980825"
"Kandi","Kandi","11.1286","2.9369","Benin","BJ","BEN","Alibori","admin","177683","1204875413"
"Danbury","Danbury","41.4015","-73.4709","United States","US","USA","Connecticut","","177722","1840004837"
"Meiktila","Meiktila","20.8833","95.8833","Myanmar","MM","MMR","Mandalay","","177442","1104916201"
"Dundo","Dundo","-7.3801","20.8351","Angola","AO","AGO","Lunda-Norte","admin","177604","1024121799"
"Bejaïa","Bejaia","36.7511","5.0642","Algeria","DZ","DZA","Bejaïa","admin","176139","1012140298"
"Titāgarh","Titagarh","22.7400","88.3700","India","IN","IND","West Bengal","","116541","1356590173"
"Loum","Loum","4.7180","9.7300","Cameroon","CM","CMR","Littoral","","177429","1120110128"
"Baranavichy","Baranavichy","53.1333","26.0167","Belarus","BY","BLR","Brestskaya Voblasts’","minor","174183","1112421165"
"Santa María Texmelucan","Santa Maria Texmelucan","19.2833","-98.4333","Mexico","MX","MEX","Puebla","","175518","1484127851"
"Kamakurayama","Kamakurayama","35.3197","139.5525","Japan","JP","JPN","Kanagawa","","172929","1392220561"
"Yangmei","Yangmei","24.9167","121.1500","Taiwan","TW","TWN","Taoyuan","","175000","1158247880"
"Ilford","Ilford","51.5575","0.0858","United Kingdom","GB","GBR","Redbridge","","168168","1826381000"
"Les Cayes","Les Cayes","18.2000","-73.7500","Haiti","HT","HTI","Sud","admin","175457","1332587854"
"Ciudad Valles","Ciudad Valles","21.9833","-99.0167","Mexico","MX","MEX","San Luis Potosí","minor","177022","1484904610"
"Santander","Santander","43.4628","-3.8050","Spain","ES","ESP","Cantabria","admin","172221","1724247413"
"Kadugli","Kadugli","11.0167","29.7167","Sudan","SD","SDN","South Kordofan","admin","176931","1729757472"
"Linhares","Linhares","-19.3894","-40.0678","Brazil","BR","BRA","Espírito Santo","","176688","1076355611"
"Letpandan","Letpandan","17.7866","95.7507","Myanmar","MM","MMR","Bago","","176571","1104882792"
"Krasnogorsk","Krasnogorsk","55.8217","37.3386","Russia","RU","RUS","Moskovskaya Oblast’","","175812","1643674336"
"Wārāseonī","Waraseoni","21.7647","80.0494","India","IN","IND","Madhya Pradesh","","176291","1356634180"
"Mauli","Mauli","30.6990","76.6930","India","IN","IND","Punjab","","176152","1356181198"
"Saint-Louis","Saint-Louis","16.0333","-16.5000","Senegal","SN","SEN","Saint-Louis","admin","176000","1686542859"
"Rio Verde","Rio Verde","-17.7450","-50.9167","Brazil","BR","BRA","Goiás","","176424","1076844683"
"Plzeň","Plzen","49.7475","13.3775","Czechia","CZ","CZE","Plzeňský Kraj","admin","175219","1203402469"
"Biu","Biu","10.6111","12.1950","Nigeria","NG","NGA","Borno","minor","176072","1566675681"
"Ait Melloul","Ait Melloul","30.3342","-9.4972","Morocco","MA","MAR","Souss-Massa","","171847","1504134516"
"Comitán","Comitan","16.2511","-92.1342","Mexico","MX","MEX","Chiapas","minor","170000","1484955658"
"Saint-Étienne","Saint-Etienne","45.4347","4.3903","France","FR","FRA","Auvergne-Rhône-Alpes","minor","173821","1250000540"
"Orekhovo-Borisovo Yuzhnoye","Orekhovo-Borisovo Yuzhnoye","55.6031","37.7331","Russia","RU","RUS","Moskva","","147713","1643411150"
"Oeiras","Oeiras","38.6833","-9.3167","Portugal","PT","PRT","Lisboa","minor","172120","1620375757"
"Habaswein","Habaswein","1.0100","39.4900","Kenya","KE","KEN","Wajir","","174134","1404012344"
"Catumbela","Catumbela","-12.4167","13.5333","Angola","AO","AGO","Benguela","","175805","1024821574"
"Santa Cruz","Santa Cruz","14.5998","120.9802","Philippines","PH","PHL","Manila","","126735","1608318207"
"Ereğli","Eregli","41.2792","31.4208","Turkey","TR","TUR","Zonguldak","minor","175605","1792160689"
"Sầm Sơn","Sam Son","19.7333","105.9000","Vietnam","VN","VNM","Thanh Hóa","","172350","1704851701"
"Harar","Harar","9.3111","42.1278","Ethiopia","ET","ETH","Hārerī Hizb","admin","174994","1231785978"
"Shibirghān","Shibirghan","36.6650","65.7520","Afghanistan","AF","AFG","Jowzjān","admin","175599","1004805783"
"Burgos","Burgos","42.3408","-3.6997","Spain","ES","ESP","Castille-Leon","minor","174051","1724993285"
"Chandannagar","Chandannagar","22.8671","88.3674","India","IN","IND","West Bengal","","166867","1356174802"
"Cuddalore","Cuddalore","11.7500","79.7500","India","IN","IND","Tamil Nādu","","173676","1356544008"
"Olsztyn","Olsztyn","53.7778","20.4792","Poland","PL","POL","Warmińsko-Mazurskie","admin","173599","1616383275"
"Elk Grove","Elk Grove","38.4161","-121.3842","United States","US","USA","California","","175510","1840020245"
"Kotdwāra","Kotdwara","29.7500","78.5300","India","IN","IND","Uttarākhand","","175232","1356826143"
"Marāgheh","Maragheh","37.3906","46.2403","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","175255","1364532780"
"Thành Phố Uông Bí","Thanh Pho Uong Bi","21.0356","106.7644","Vietnam","VN","VNM","Quảng Ninh","","174678","1704196737"
"Gliwice","Gliwice","50.2833","18.6667","Poland","PL","POL","Śląskie","minor","174016","1616311376"
"Bodrum","Bodrum","37.0378","27.4242","Turkey","TR","TUR","Muğla","minor","175000","1792047777"
"Tebingtinggi","Tebingtinggi","3.3283","99.1625","Indonesia","ID","IDN","Sumatera Utara","","169786","1360386781"
"Cianjur","Cianjur","-6.8200","107.1408","Indonesia","ID","IDN","Jawa Barat","minor","174587","1360633374"
"Alcorcón","Alcorcon","40.3500","-3.8333","Spain","ES","ESP","Madrid","","170817","1724318578"
"Ontario","Ontario","34.0393","-117.6064","United States","US","USA","California","","175223","1840020410"
"Chetumal","Chetumal","18.5036","-88.3053","Mexico","MX","MEX","Quintana Roo","admin","169028","1484338031"
"Khānaqīn","Khanaqin","34.3333","45.3833","Iraq","IQ","IRQ","Diyālá","minor","175000","1368075472"
"Frederick","Frederick","39.4337","-77.4141","United States","US","USA","Maryland","","175032","1840005710"
"Sīrjān","Sirjan","29.4370","55.6802","Iran","IR","IRN","Kermān","minor","175000","1364204506"
"Tyre","Tyre","33.2708","35.1961","Lebanon","LB","LBN","Liban-Sud","minor","160000","1422316734"
"Mexico","Mexico","15.0667","120.7167","Philippines","PH","PHL","Pampanga","","173403","1608191667"
"Menemen","Menemen","38.6000","27.0667","Turkey","TR","TUR","İzmir","minor","174564","1792699814"
"Ludwigshafen","Ludwigshafen","49.4811","8.4353","Germany","DE","DEU","Rhineland-Palatinate","minor","172557","1276912330"
"Vero Beach South","Vero Beach South","27.6132","-80.4163","United States","US","USA","Florida","","174748","1840073853"
"Syzran","Syzran","53.1667","48.4667","Russia","RU","RUS","Samarskaya Oblast’","","173260","1643711617"
"Carúpano","Carupano","10.6722","-63.2403","Venezuela","VE","VEN","Sucre","minor","173877","1862777685"
"Ocala","Ocala","29.1780","-82.1511","United States","US","USA","Florida","","174618","1840015067"
"Lubao","Lubao","14.9333","120.6000","Philippines","PH","PHL","Pampanga","","173502","1608215915"
"Luziânia","Luziania","-16.2528","-47.9500","Brazil","BR","BRA","Goiás","","174531","1076073549"
"Oceanside","Oceanside","33.2247","-117.3083","United States","US","USA","California","","174461","1840020623"
"Chīrāla","Chirala","15.8246","80.3521","India","IN","IND","Andhra Pradesh","","172826","1356100300"
"Ratnapura","Ratnapura","6.6806","80.4022","Sri Lanka","LK","LKA","Sabaragamuwa","admin","165998","1144739208"
"León","Leon","12.4344","-86.8775","Nicaragua","NI","NIC","León","admin","174051","1558654236"
"San Andrés Tuxtla","San Andres Tuxtla","18.4487","-95.2126","Mexico","MX","MEX","Veracruz","minor","164834","1484871648"
"Tiantoujiao","Tiantoujiao","23.0236","114.0927","China","CN","CHN","Guangdong","","166774","1156753422"
"Castellón de la Plana","Castellon de la Plana","39.9831","-0.0331","Spain","ES","ESP","Valencia","minor","172589","1724579885"
"Piedras Negras","Piedras Negras","28.7000","-100.5231","Mexico","MX","MEX","Coahuila","minor","173959","1484000400"
"Banhā","Banha","30.4667","31.1833","Egypt","EG","EGY","Al Qalyūbīyah","admin","157701","1818596265"
"Linjiang","Linjiang","41.8471","126.9301","China","CN","CHN","Jilin","minor","173903","1156357534"
"Rancho Cucamonga","Rancho Cucamonga","34.1247","-117.5667","United States","US","USA","California","","173946","1840020411"
"Hạ Long","Ha Long","20.9500","107.0667","Vietnam","VN","VNM","Quảng Ninh","admin","172915","1704379547"
"Deo","Deo","24.6561","84.4356","India","IN","IND","Bihār","","173216","1356131438"
"Silchar","Silchar","24.8200","92.8000","India","IN","IND","Assam","","172830","1356102467"
"Tlemcen","Tlemcen","34.8828","-1.3167","Algeria","DZ","DZA","Tlemcen","admin","173531","1012978325"
"Hitachi","Hitachi","36.5991","140.6515","Japan","JP","JPN","Ibaraki","","172709","1392145167"
"Kindu","Kindu","-2.9500","25.9500","Congo (Kinshasa)","CD","COD","Maniema","admin","172321","1180106925"
"Shāhīn Shahr","Shahin Shahr","32.8667","51.5667","Iran","IR","IRN","Eşfahān","minor","173329","1364921034"
"Le Havre","Le Havre","49.4900","0.1000","France","FR","FRA","Normandie","minor","169733","1250775297"
"Gadag","Gadag","15.4167","75.6167","India","IN","IND","Karnātaka","","172813","1356659359"
"Akhisar","Akhisar","38.9167","27.8333","Turkey","TR","TUR","Manisa","minor","173026","1792301869"
"Polomolok","Polomolok","6.2167","125.0667","Philippines","PH","PHL","South Cotabato","","172605","1608490461"
"Mang La","Mang La","14.3833","107.9833","Vietnam","VN","VNM","Kon Tum","","172712","1704178922"
"Sirājganj","Sirajganj","24.4500","89.7500","Bangladesh","BD","BGD","Rājshāhi","","167200","1050687094"
"Tiruvannāmalai","Tiruvannamalai","12.1300","79.0700","India","IN","IND","Tamil Nādu","","172348","1356561184"
"Rantau Prapat","Rantau Prapat","2.3333","100.0500","Indonesia","ID","IDN","Sumatera Utara","minor","172862","1360981676"
"Banyuwangi","Banyuwangi","-8.2186","114.3669","Indonesia","ID","IDN","Jawa Timur","minor","172424","1360008480"
"Albacete","Albacete","38.9956","-1.8558","Spain","ES","ESP","Castille-La Mancha","minor","172722","1724000069"
"Bīdar","Bidar","17.9120","77.5200","India","IN","IND","Karnātaka","","172298","1356933453"
"San Miguel","San Miguel","15.1458","120.9783","Philippines","PH","PHL","Bulacan","","172073","1608046677"
"Mülheim","Mulheim","51.4275","6.8825","Germany","DE","DEU","North Rhine-Westphalia","minor","170921","1276676856"
"Sacaba","Sacaba","-17.4042","-66.0408","Bolivia","BO","BOL","Cochabamba","","172466","1068288609"
"Garden Grove","Garden Grove","33.7787","-117.9601","United States","US","USA","California","","172708","1840020577"
"Reggio Emilia","Reggio Emilia","44.7000","10.6333","Italy","IT","ITA","Emilia-Romagna","minor","171944","1380487033"
"Barrie","Barrie","44.3711","-79.6769","Canada","CA","CAN","Ontario","","172657","1124340223"
"Kaolack","Kaolack","14.1389","-16.0764","Senegal","SN","SEN","Kaolack","admin","172305","1686256343"
"Moca","Moca","19.3833","-70.5167","Dominican Republic","DO","DOM","Cibao Norte","minor","172294","1214863528"
"São Caetano do Sul","Sao Caetano do Sul","-23.7000","-46.5500","Brazil","BR","BRA","São Paulo","","161957","1076527920"
"Cape Coast","Cape Coast","5.1000","-1.2500","Ghana","GH","GHA","Central","admin","169894","1288506097"
"Izumo","Izumo","35.3667","132.7667","Japan","JP","JPN","Shimane","","171995","1392299364"
"Baliuag","Baliuag","14.9540","120.9010","Philippines","PH","PHL","Bulacan","","168470","1608703693"
"Oldenburg","Oldenburg","53.1439","8.2139","Germany","DE","DEU","Lower Saxony","minor","170389","1276946953"
"Sawangan","Sawangan","-6.4086","106.7647","Indonesia","ID","IDN","Jawa Barat","","165631","1360017411"
"Petite Rivière de l’Artibonite","Petite Riviere de l'Artibonite","19.1333","-72.4833","Haiti","HT","HTI","Artibonite","","170740","1332451568"
"Niiza","Niiza","35.7935","139.5653","Japan","JP","JPN","Saitama","","164653","1392772700"
"Cazanga","Cazanga","-9.3000","14.9500","Angola","AO","AGO","Cuanza-Norte","","171743","1024657411"
"Surigao","Surigao","9.7897","125.4958","Philippines","PH","PHL","Surigao del Norte","admin","171107","1608611556"
"Batumi","Batumi","41.6458","41.6417","Georgia","GE","GEO","Ajaria","admin","169095","1268193611"
"Francisco Morato","Francisco Morato","-23.2817","-46.7425","Brazil","BR","BRA","São Paulo","","168243","1076808330"
"Cary","Cary","35.7819","-78.8195","United States","US","USA","North Carolina","","171603","1840016196"
"Volgodonsk","Volgodonsk","47.5167","42.1500","Russia","RU","RUS","Rostovskaya Oblast’","","171471","1643784914"
"Bảo Lộc","Bao Loc","11.5481","107.8075","Vietnam","VN","VNM","Lâm Đồng","minor","170920","1704551621"
"Ramat Gan","Ramat Gan","32.07","34.8236","Israel","IL","ISR","Tel Aviv","","159200","1376357911"
"Petarukan","Petarukan","-6.8961","109.4514","Indonesia","ID","IDN","Jawa Tengah","","169272","1360979518"
"Kohat","Kohat","33.5833","71.4333","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","170800","1586349992"
"Makurdi","Makurdi","7.7306","8.5361","Nigeria","NG","NGA","Benue","admin","170925","1566873994"
"Kamensk-Ural’skiy","Kamensk-Ural'skiy","56.4000","61.9333","Russia","RU","RUS","Sverdlovskaya Oblast’","","169929","1643640451"
"Loja","Loja","-3.9833","-79.2000","Ecuador","EC","ECU","Loja","admin","170280","1218739896"
"Valsād","Valsad","20.6100","72.9260","India","IN","IND","Gujarāt","","170060","1356306193"
"Escuintla","Escuintla","14.2978","-90.7869","Guatemala","GT","GTM","Escuintla","admin","170280","1320838903"
"Ussuriysk","Ussuriysk","43.8000","131.9667","Russia","RU","RUS","Primorskiy Kray","","170660","1643001263"
"Sakura","Sakura","35.7167","140.2167","Japan","JP","JPN","Chiba","","169059","1392611314"
"Concepcion","Concepcion","15.3249","120.6554","Philippines","PH","PHL","Tarlac","","169953","1608682956"
"Machilīpatnam","Machilipatnam","16.1700","81.1300","India","IN","IND","Andhra Pradesh","","170000","1356067784"
"Uppsala","Uppsala","59.8581","17.6447","Sweden","SE","SWE","Uppsala","admin","166698","1752953686"
"Tomakomai","Tomakomai","42.6341","141.6055","Japan","JP","JPN","Hokkaidō","","170223","1392999977"
"Portmore","Portmore","17.9500","-76.8799","Jamaica","JM","JAM","Saint Catherine","","170000","1388926551"
"Bordj Bou Arreridj","Bordj Bou Arreridj","36.0667","4.7667","Algeria","DZ","DZA","Bordj Bou Arréridj","admin","168346","1012016677"
"Aplahoué","Aplahoue","6.9333","1.6833","Benin","BJ","BEN","Couffo","","170069","1204958955"
"Pembroke Pines","Pembroke Pines","26.0128","-80.3382","United States","US","USA","Florida","","170346","1840015141"
"Malāyer","Malayer","34.2969","48.8236","Iran","IR","IRN","Hamadān","minor","170237","1364030346"
"Kluang","Kluang","2.0336","103.3194","Malaysia","MY","MYS","Johor","","169828","1458692921"
"As Suwayq","As Suwayq","23.8494","57.4386","Oman","OM","OMN","Shamāl al Bāţinah","","170000","1512745539"
"Novocherkassk","Novocherkassk","47.4222","40.0939","Russia","RU","RUS","Rostovskaya Oblast’","","168766","1643990727"
"Métouia","Metouia","33.9667","10.0000","Tunisia","TN","TUN","Gabès","","170000","1788442482"
"Magwe","Magwe","4.1333","32.3000","South Sudan","SS","SSD","Eastern Equatoria","","169826","1728085840"
"Nishio","Nishio","34.8333","137.0667","Japan","JP","JPN","Aichi","","168992","1392425838"
"Bayamón","Bayamon","18.3794","-66.1635","Puerto Rico","PR","PRI","Puerto Rico","","170028","1630035622"
"San Luis","San Luis","-33.3000","-66.3333","Argentina","AR","ARG","San Luis","admin","169947","1032547434"
"Puerto Montt","Puerto Montt","-41.4667","-72.9333","Chile","CL","CHL","Los Lagos","admin","169736","1152443957"
"Medinīpur","Medinipur","22.4240","87.3190","India","IN","IND","West Bengal","","169264","1356432910"
"Fenglu","Fenglu","24.6728","102.9149","China","CN","CHN","Yunnan","minor","169366","1156317766"
"Saḩāb","Sahab","31.8667","36.0000","Jordan","JO","JOR","Al ‘Āşimah","minor","169434","1400064688"
"Al Marj","Al Marj","32.5005","20.8300","Libya","LY","LBY","Al Marj","admin","169540","1434742923"
"Caluquembe","Caluquembe","-13.9208","14.5347","Angola","AO","AGO","Huíla","","169420","1024240126"
"Sŏsan","Sosan","36.7817","126.4522","South Korea","KR","KOR","Chungnam","","169221","1410171679"
"Damoh","Damoh","23.8331","79.4419","India","IN","IND","Madhya Pradesh","","168916","1356070737"
"Toliara","Toliara","-23.3500","43.6667","Madagascar","MG","MDG","Toliara","admin","168756","1450593748"
"Warrington","Warrington","53.3900","-2.5900","United Kingdom","GB","GBR","Warrington","","165456","1826261547"
"Gondomar","Gondomar","41.1500","-8.5333","Portugal","PT","PRT","Porto","minor","168027","1620506259"
"Minbya","Minbya","20.3667","93.2667","Myanmar","MM","MMR","Rakhine State","","169208","1104858010"
"Jamālpur","Jamalpur","24.9004","89.9500","Bangladesh","BD","BGD","Mymensingh","","167900","1050233680"
"Santa Rita","Santa Rita","10.2003","-67.5514","Venezuela","VE","VEN","Aragua","minor","169158","1862408122"
"Neyveli","Neyveli","11.6088","79.4994","India","IN","IND","Tamil Nādu","","168368","1356265572"
"Bāramūla","Baramula","34.2000","74.3400","India","IN","IND","Jammu and Kashmīr","","167986","1356671176"
"Kalalé","Kalale","10.2953","3.3786","Benin","BJ","BEN","Borgou","","168882","1204531108"
"Oyama","Oyama","36.3146","139.8002","Japan","JP","JPN","Tochigi","","167874","1392122083"
"Wāpi","Wapi","20.3720","72.9170","India","IN","IND","","","163630","1356248785"
"Ambato","Ambato","-1.2417","-78.6197","Ecuador","EC","ECU","Tungurahua","admin","165185","1218046148"
"Calabozo","Calabozo","8.9219","-67.4283","Venezuela","VE","VEN","Guárico","minor","168605","1862623441"
"Hŭich’ŏn","Huich'on","40.1707","126.2761","North Korea","KP","PRK","Chagang","","168180","1408445793"
"Mahābād","Mahabad","36.7678","45.7339","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","","168393","1364192213"
"Itapecerica da Serra","Itapecerica da Serra","-23.7172","-46.8494","Brazil","BR","BRA","São Paulo","","167236","1076163359"
"Villa Canales","Villa Canales","14.4816","-90.5340","Guatemala","GT","GTM","Guatemala","minor","167779","1320213771"
"Basuo","Basuo","19.0920","108.6710","China","CN","CHN","Hainan","minor","167636","1156260069"
"Zlatoust","Zlatoust","55.1667","59.6667","Russia","RU","RUS","Chelyabinskaya Oblast’","","167978","1643000102"
"Malanville","Malanville","11.8667","3.3833","Benin","BJ","BEN","Atacora","","168006","1204159179"
"Jīnd","Jind","29.3167","76.3167","India","IN","IND","Haryāna","","167592","1356620188"
"Palmdale","Palmdale","34.5944","-118.1057","United States","US","USA","California","","167987","1840020502"
"Haarlem","Haarlem","52.3833","4.6333","Netherlands","NL","NLD","Noord-Holland","admin","162902","1528822178"
"Pátra","Patra","38.2500","21.7333","Greece","GR","GRC","Dytikí Elláda","admin","167446","1300201099"
"Ríohacha","Riohacha","11.5442","-72.9069","Colombia","CO","COL","La Guajira","admin","167865","1170903069"
"Catape","Catape","-13.7667","15.0833","Angola","AO","AGO","Huíla","","167820","1024321158"
"Talas","Talas","38.6833","35.5667","Turkey","TR","TUR","Kayseri","minor","165127","1792443013"
"Katha","Katha","24.1822","96.3306","Myanmar","MM","MMR","Sagaing","","167700","1104074390"
"Manchester","Manchester","42.9848","-71.4447","United States","US","USA","New Hampshire","","167568","1840002983"
"Tuguegarao","Tuguegarao","17.6133","121.7303","Philippines","PH","PHL","Cagayan","admin","166334","1608110634"
"Guarapuava","Guarapuava","-25.3833","-51.4500","Brazil","BR","BRA","Paraná","","167328","1076415327"
"Itu","Itu","-23.2642","-47.2992","Brazil","BR","BRA","São Paulo","","167095","1076622789"
"Jutiapa","Jutiapa","14.2917","-89.8958","Guatemala","GT","GTM","Jutiapa","admin","167049","1320264119"
"Çerkezköy","Cerkezkoy","41.2833","28.0000","Turkey","TR","TUR","Tekirdağ","minor","166789","1792345398"
"Mogok","Mogok","22.9167","96.5000","Myanmar","MM","MMR","Mandalay","","167149","1104205175"
"Mukono","Mukono","0.3533","32.7553","Uganda","UG","UGA","Mukono","admin","161996","1800864322"
"Al Kūfah","Al Kufah","32.0300","44.4000","Iraq","IQ","IRQ","An Najaf","minor","166100","1368929021"
"Ādoni","Adoni","15.6244","77.2731","India","IN","IND","Andhra Pradesh","","166344","1356357749"
"Grenoble","Grenoble","45.1715","5.7224","France","FR","FRA","Auvergne-Rhône-Alpes","minor","158198","1250071647"
"Tabora","Tabora","-5.0167","32.8000","Tanzania","TZ","TZA","Tabora","admin","160608","1834712235"
"Sampit","Sampit","-2.5333","112.9500","Indonesia","ID","IDN","Kalimantan Tengah","minor","166773","1360020231"
"Lafayette","Lafayette","40.3991","-86.8593","United States","US","USA","Indiana","","166793","1840008354"
"Tenāli","Tenali","16.2390","80.6450","India","IN","IND","Andhra Pradesh","","164937","1356831482"
"Iwata","Iwata","34.7179","137.8515","Japan","JP","JPN","Shizuoka","","165688","1392088261"
"Takaoka","Takaoka","36.7541","137.0257","Japan","JP","JPN","Toyama","","165880","1392754231"
"Tam Kỳ","Tam Ky","15.5667","108.4833","Vietnam","VN","VNM","Quảng Nam","admin","165240","1704410655"
"Skikda","Skikda","36.8667","6.9000","Algeria","DZ","DZA","Skikda","admin","163318","1012619903"
"Siirt","Siirt","37.9250","41.9458","Turkey","TR","TUR","Siirt","admin","166332","1792190820"
"Osnabrück","Osnabruck","52.2833","8.0500","Germany","DE","DEU","Lower Saxony","minor","165034","1276554949"
"Kuytun","Kuytun","44.4264","84.9032","China","CN","CHN","Xinjiang","minor","166261","1156287095"
"Ipiales","Ipiales","0.8303","-77.6444","Colombia","CO","COL","Nariño","minor","166079","1170309931"
"Fyzābād","Fyzabad","26.7730","82.1460","India","IN","IND","Uttar Pradesh","","165228","1356032860"
"Körfez","Korfez","40.7833","29.7333","Turkey","TR","TUR","Kocaeli","minor","165503","1792917174"
"Perugia","Perugia","43.1122","12.3889","Italy","IT","ITA","Umbria","admin","165683","1380939013"
"Udipi","Udipi","13.3389","74.7451","India","IN","IND","Karnātaka","","165401","1356145399"
"Oshawa","Oshawa","43.9000","-78.8500","Canada","CA","CAN","Ontario","","166000","1124541904"
"Tanjungbalai","Tanjungbalai","2.9700","99.8000","Indonesia","ID","IDN","Sumatera Utara","","165763","1360992709"
"Obihiro","Obihiro","42.9167","143.2000","Japan","JP","JPN","Hokkaidō","","165684","1392003095"
"Santa Cruz","Santa Cruz","36.9789","-122.0346","United States","US","USA","California","","165933","1840021579"
"Leverkusen","Leverkusen","51.0333","6.9833","Germany","DE","DEU","North Rhine-Westphalia","minor","163851","1276002465"
"Klaipėda","Klaipeda","55.7500","21.1667","Lithuania","LT","LTU","Klaipėdos Miestas","admin","164310","1440897790"
"Hadano","Hadano","35.3667","139.2167","Japan","JP","JPN","Kanagawa","","164292","1392003173"
"Kushiro","Kushiro","42.9833","144.3833","Japan","JP","JPN","Hokkaidō","","165699","1392003151"
"Midsayap","Midsayap","7.1917","124.5333","Philippines","PH","PHL","Cotabato","","165376","1608454598"
"Idlib","Idlib","35.9333","36.6333","Syria","SY","SYR","Idlib","admin","165000","1760305230"
"Arnhem","Arnhem","51.9833","5.9167","Netherlands","NL","NLD","Gelderland","admin","164096","1528862562"
"Kingswood","Kingswood","51.4600","-2.5050","United Kingdom","GB","GBR","South Gloucestershire","","160260","1826616515"
"Béchar","Bechar","31.6333","-2.2000","Algeria","DZ","DZA","Béchar","admin","165627","1012371820"
"Conjeeveram","Conjeeveram","12.8308","79.7078","India","IN","IND","Tamil Nādu","","164384","1356148941"
"Kandy","Kandy","7.2964","80.6350","Sri Lanka","LK","LKA","Central","admin","161000","1144308408"
"Proddatūr","Proddatur","14.7300","78.5500","India","IN","IND","Andhra Pradesh","","163970","1356025913"
"Cienfuegos","Cienfuegos","22.1456","-80.4364","Cuba","CU","CUB","Cienfuegos","admin","164924","1192020054"
"Nador","Nador","35.1667","-2.9333","Morocco","MA","MAR","Oriental","","161726","1504811178"
"Saqqez","Saqqez","36.2464","46.2664","Iran","IR","IRN","Kordestān","minor","165258","1364098155"
"Zhanlicun","Zhanlicun","23.2881","116.2594","China","CN","CHN","Guangdong","","161439","1156184947"
"Ebo","Ebo","-11.0000","14.6667","Angola","AO","AGO","Cuanza-Sul","","165129","1024334702"
"Kökshetaū","Kokshetau","53.2833","69.3833","Kazakhstan","KZ","KAZ","","admin","165153","1398375387"
"Nāngloi Jāt","Nangloi Jat","28.6833","77.0667","India","IN","IND","Delhi","","150371","1356389587"
"Fukang","Fukang","44.1646","87.9536","China","CN","CHN","Xinjiang","minor","165006","1156761099"
"Türkistan","Turkistan","43.3019","68.2692","Kazakhstan","KZ","KAZ","","admin","164899","1398398278"
"Pikit","Pikit","7.0500","124.6667","Philippines","PH","PHL","Cotabato","","164646","1608218990"
"Huddersfield","Huddersfield","53.6450","-1.7798","United Kingdom","GB","GBR","Kirklees","","162949","1826672445"
"Bocoio","Bocoio","-12.4667","14.1333","Angola","AO","AGO","Benguela","","164685","1024922883"
"Chillán","Chillan","-36.6067","-72.1033","Chile","CL","CHL","Ñuble","admin","164270","1152082093"
"Marysville","Marysville","48.0809","-122.1561","United States","US","USA","Washington","","164655","1840019789"
"Abengourou","Abengourou","6.7333","-3.4833","Côte d'Ivoire","CI","CIV","Comoé","admin","164424","1384295898"
"Abū Ḩulayfah","Abu Hulayfah","29.1322","48.1261","Kuwait","KW","KWT","Al Aḩmadī","","164212","1414456826"
"Muridke","Muridke","31.8020","74.2550","Pakistan","PK","PAK","Punjab","","163268","1586205456"
"Tema","Tema","5.6667","-0.0167","Ghana","GH","GHA","Greater Accra","","161612","1288205885"
"Metro","Metro","-5.1167","105.3000","Indonesia","ID","IDN","Lampung","","161799","1360659228"
"Muskegon","Muskegon","43.2281","-86.2562","United States","US","USA","Michigan","","164136","1840002870"
"Ipswich","Ipswich","-27.6167","152.7667","Australia","AU","AUS","Queensland","","163000","1036244388"
"Myingyan","Myingyan","21.4600","95.3883","Myanmar","MM","MMR","Mandalay","","163812","1104162015"
"Quibala","Quibala","-10.7333","14.9833","Angola","AO","AGO","Cuanza-Sul","","163991","1024007950"
"Teresópolis","Teresopolis","-22.4119","-42.9658","Brazil","BR","BRA","Rio de Janeiro","","163746","1076000296"
"Ciudad Acuña","Ciudad Acuna","29.3242","-100.9317","Mexico","MX","MEX","Coahuila","minor","160225","1484828397"
"Tunja","Tunja","5.5333","-73.3667","Colombia","CO","COL","Boyacá","admin","163894","1170843027"
"‘Unayzah","\`Unayzah","26.0906","43.9875","Saudi Arabia","SA","SAU","Al Qaşīm","","163729","1682546519"
"San Marcos","San Marcos","10.6204","-84.5120","Costa Rica","CR","CRI","Alajuela","","163745","1188000770"
"Turgutlu","Turgutlu","38.5000","27.7000","Turkey","TR","TUR","Manisa","minor","163223","1792010631"
"Pocheon","Pocheon","37.8947","127.2002","South Korea","KR","KOR","Gyeonggi","minor","163388","1410780187"
"Ninh Bình","Ninh Binh","20.2539","105.9750","Vietnam","VN","VNM","Ninh Bình","admin","160166","1704442748"
"São José de Ribamar","Sao Jose de Ribamar","-2.5500","-44.0500","Brazil","BR","BRA","Maranhão","","163045","1076976057"
"Wau","Wau","7.7000","28.0000","South Sudan","SS","SSD","Western Bahr el Ghazal","admin","163442","1728877501"
"Ube","Ube","33.9500","131.2500","Japan","JP","JPN","Yamaguchi","","162873","1392198814"
"Gölcük","Golcuk","40.6667","29.8333","Turkey","TR","TUR","Kocaeli","minor","162584","1792590945"
"Muzaffargarh","Muzaffargarh","30.0694","71.1942","Pakistan","PK","PAK","Punjab","minor","163268","1586492401"
"Kebili","Kebili","33.7050","8.9650","Tunisia","TN","TUN","Kébili","admin","163257","1788285135"
"Jizzax","Jizzax","40.1158","67.8422","Uzbekistan","UZ","UZB","Jizzax","admin","163200","1860218891"
"Kyaunggon","Kyaunggon","17.1000","95.1833","Myanmar","MM","MMR","Ayeyarwady","","163035","1104893206"
"Simao","Simao","22.7807","100.9782","China","CN","CHN","Yunnan","minor","162725","1156008347"
"San Martin Texmelucan de Labastida","San Martin Texmelucan de Labastida","19.2833","-98.4333","Mexico","MX","MEX","Puebla","minor","152051","1484481265"
"Brahmanpara","Brahmanpara","23.6167","91.1083","Bangladesh","BD","BGD","Chattogram","","161906","1050744970"
"Naic","Naic","14.3167","120.7667","Philippines","PH","PHL","Cavite","","160987","1608196842"
"Jinggang","Jinggang","23.2723","113.2182","China","CN","CHN","Guangdong","","161343","1156036643"
"Bandar-e Māhshahr","Bandar-e Mahshahr","30.5589","49.1981","Iran","IR","IRN","Khūzestān","minor","162797","1364956793"
"Godhra","Godhra","22.7772","73.6203","India","IN","IND","Gujarāt","","161925","1356386046"
"Anaco","Anaco","9.4333","-64.4667","Venezuela","VE","VEN","Anzoátegui","minor","162704","1862074247"
"Sullana","Sullana","-4.9000","-80.6833","Peru","PE","PER","Piura","","162434","1604666111"
"Villeurbanne","Villeurbanne","45.7667","4.8803","France","FR","FRA","Auvergne-Rhône-Alpes","","152212","1250002615"
"Zemun","Zemun","44.8500","20.4000","Serbia","RS","SRB","Beograd","minor","161596","1688453076"
"Sariaya","Sariaya","13.9667","121.5333","Philippines","PH","PHL","Quezon","","161868","1608396060"
"Tuxtepec","Tuxtepec","18.1000","-96.1167","Mexico","MX","MEX","Oaxaca","","162511","1484448356"
"Springfield","Springfield","39.7709","-89.6540","United States","US","USA","Illinois","admin","162588","1840009517"
"Musashino","Musashino","35.7177","139.5661","Japan","JP","JPN","Tōkyō","","148971","1392003488"
"Bhisho","Bhisho","-32.8494","27.4381","South Africa","ZA","ZAF","Eastern Cape","admin","160997","1710672530"
"Benoni","Benoni","-26.1883","28.3206","South Africa","ZA","ZAF","Gauteng","","158777","1710880986"
"Chitaldrug","Chitaldrug","14.2333","76.4000","India","IN","IND","Karnātaka","","161966","1356668514"
"Andong","Andong","36.5592","128.7289","South Korea","KR","KOR","Gyeongbuk","admin","162180","1410095935"
"Hayward","Hayward","37.6328","-122.0766","United States","US","USA","California","","162254","1840020293"
"Rājapālaiyam","Rajapalaiyam","9.4204","77.5800","India","IN","IND","Tamil Nādu","","161445","1356110350"
"Guadalajara","Guadalajara","40.6337","-3.1674","Spain","ES","ESP","Castille-La Mancha","minor","161683","1724317859"
"Multai","Multai","21.7700","78.2500","India","IN","IND","Madhya Pradesh","","161819","1356137691"
"Rafsanjān","Rafsanjan","30.4067","55.9939","Iran","IR","IRN","Kermān","minor","161909","1364354751"
"Dijon","Dijon","47.3167","5.0167","France","FR","FRA","Bourgogne-Franche-Comté","admin","158002","1250505606"
"Al Ghardaqah","Al Ghardaqah","27.2578","33.8117","Egypt","EG","EGY","Al Baḩr al Aḩmar","admin","160901","1818666245"
"Bontang","Bontang","0.1333","117.5000","Indonesia","ID","IDN","Kalimantan Timur","","161413","1360692406"
"Tulancingo","Tulancingo","20.0833","-98.3667","Mexico","MX","MEX","Hidalgo","minor","161069","1484419416"
"Chittoor","Chittoor","13.2160","79.0980","India","IN","IND","Andhra Pradesh","","160722","1356026391"
"Salihli","Salihli","38.4811","28.1392","Turkey","TR","TUR","Manisa","minor","161562","1792382417"
"Ndalatando","Ndalatando","-9.3000","14.9167","Angola","AO","AGO","Cuanza-Norte","admin","161584","1024645515"
"Elektrostal","Elektrostal","55.7833","38.4667","Russia","RU","RUS","Moskovskaya Oblast’","","158508","1643790299"
"Jequié","Jequie","-13.8630","-40.0843","Brazil","BR","BRA","Bahia","","161528","1076558785"
"Apeldoorn","Apeldoorn","52.2167","5.9667","Netherlands","NL","NLD","Gelderland","minor","161156","1528057552"
"Trà Vinh","Tra Vinh","9.9333","106.3500","Vietnam","VN","VNM","Trà Vinh","admin","160310","1704929966"
"Đồng Hới","Dong Hoi","17.4831","106.5997","Vietnam","VN","VNM","Quảng Bình","admin","160325","1704323043"
"Krishnanagar","Krishnanagar","23.4000","88.5000","India","IN","IND","West Bengal","","153062","1356128689"
"Sherbrooke","Sherbrooke","45.4000","-71.9000","Canada","CA","CAN","Quebec","","161323","1124559506"
"Ash Shīḩānīyah","Ash Shihaniyah","25.3722","51.2047","Qatar","QA","QAT","Ash Shīḩānīyah","admin","161240","1634539520"
"Kidapawan","Kidapawan","7.0083","125.0894","Philippines","PH","PHL","Cotabato","admin","160791","1608883158"
"San Miguel","San Miguel","-34.5333","-58.7167","Argentina","AR","ARG","Buenos Aires","","157532","1032038706"
"Papantla de Olarte","Papantla de Olarte","20.4478","-97.3200","Mexico","MX","MEX","Veracruz","minor","161097","1484844355"
"Dhamār","Dhamar","14.5500","44.4017","Yemen","YE","YEM","Dhamār","admin","160114","1887135215"
"Tân Phú","Tan Phu","11.2720","107.4367","Vietnam","VN","VNM","Đồng Nai","minor","161000","1704582407"
"José María Ezeiza","Jose Maria Ezeiza","-34.8333","-58.5167","Argentina","AR","ARG","Buenos Aires","minor","160219","1032337881"
"Southend","Southend","51.5500","0.7100","United Kingdom","GB","GBR","Southend-on-Sea","","160257","1826524208"
"Ibirité","Ibirite","-20.0219","-44.0589","Brazil","BR","BRA","Minas Gerais","","158954","1076368194"
"Solingen","Solingen","51.1667","7.0833","Germany","DE","DEU","North Rhine-Westphalia","minor","159360","1276728190"
"Tacheng","Tacheng","46.7517","82.9869","China","CN","CHN","Xinjiang","minor","161037","1156191514"
"Zaranj","Zaranj","30.9600","61.8600","Afghanistan","AF","AFG","Nīmrōz","admin","160902","1004504986"
"General Roca","General Roca","-39.0333","-67.5833","Argentina","AR","ARG","Río Negro","minor","160954","1032829704"
"Bragança Paulista","Braganca Paulista","-22.9531","-46.5422","Brazil","BR","BRA","São Paulo","","160665","1076245528"
"Darmstadt","Darmstadt","49.8722","8.6528","Germany","DE","DEU","Hesse","minor","159631","1276607395"
"Zhengding","Zhengding","38.1522","114.5771","China","CN","CHN","Hebei","","158599","1156000451"
"Pindamonhangaba","Pindamonhangaba","-22.9239","-45.4617","Brazil","BR","BRA","São Paulo","","160614","1076937468"
"Enschede","Enschede","52.2167","6.9000","Netherlands","NL","NLD","Overijssel","minor","159703","1528181808"
"Girón","Giron","7.0731","-73.1681","Colombia","CO","COL","Santander","minor","160403","1170575968"
"Uttarpāra","Uttarpara","22.6700","88.3500","India","IN","IND","West Bengal","","159147","1356370311"
"Heidelberg","Heidelberg","49.4167","8.7167","Germany","DE","DEU","Baden-Württemberg","minor","159245","1276519742"
"Khōst","Khost","33.3331","69.9169","Afghanistan","AF","AFG","Khōst","admin","160214","1004919977"
"Ceyhan","Ceyhan","37.0289","35.8125","Turkey","TR","TUR","Adana","minor","160474","1792949917"
"Koudougou","Koudougou","12.2500","-2.3667","Burkina Faso","BF","BFA","Centre-Ouest","admin","160207","1854839075"
"Saint-Marc","Saint-Marc","19.1167","-72.7000","Haiti","HT","HTI","Artibonite","","160181","1332084366"
"Newport","Newport","51.5886","-2.9978","United Kingdom","GB","GBR","Newport","","159600","1826018889"
"Khanpur","Khanpur","28.6453","70.6567","Pakistan","PK","PAK","Punjab","","160308","1586169401"
"Ede","Ede","7.7389","4.4361","Nigeria","NG","NGA","Osun","minor","159866","1566336253"
"Huaycan","Huaycan","-12.0181","-76.8139","Peru","PE","PER","Lima","","160000","1604578883"
"Arad","Arad","46.1750","21.3125","Romania","RO","ROU","Arad","admin","159074","1642996909"
"Moga","Moga","30.8220","75.1740","India","IN","IND","Punjab","","159897","1356017151"
"Ferkessédougou","Ferkessedougou","9.5833","-5.2000","Côte d'Ivoire","CI","CIV","Savanes","minor","160267","1384974005"
"Zabrze","Zabrze","50.3025","18.7781","Poland","PL","POL","Śląskie","minor","158307","1616784915"
"Bingöl","Bingol","38.8861","40.5017","Turkey","TR","TUR","Bingöl","admin","160165","1792920183"
"San Nicolás de los Arroyos","San Nicolas de los Arroyos","-33.3333","-60.2167","Argentina","AR","ARG","Buenos Aires","minor","160000","1032067887"
"Budaun","Budaun","28.0500","79.1200","India","IN","IND","Uttar Pradesh","","159221","1356639213"
"Miyakonojō","Miyakonojo","31.7167","131.0667","Japan","JP","JPN","Miyazaki","","159878","1392003484"
"Pandi","Pandi","14.8667","120.9500","Philippines","PH","PHL","Bulacan","","155115","1608700526"
"Kramatorsk","Kramatorsk","48.7392","37.5839","Ukraine","UA","UKR","Donetska Oblast","minor","159445","1804461469"
"Amersfoort","Amersfoort","52.1500","5.3833","Netherlands","NL","NLD","Utrecht","minor","157462","1528206992"
"Seixal","Seixal","38.6500","-9.1000","Portugal","PT","PRT","Setúbal","minor","158269","1620870397"
"Livorno","Livorno","43.5519","10.3083","Italy","IT","ITA","Tuscany","minor","158371","1380159305"
"Ekibastuz","Ekibastuz","51.6667","75.3667","Kazakhstan","KZ","KAZ","","minor","159760","1398583266"
"Hat Yai","Hat Yai","7.0167","100.4667","Thailand","TH","THA","Songkhla","minor","159130","1764161022"
"Herne","Herne","51.5500","7.2167","Germany","DE","DEU","North Rhine-Westphalia","minor","156621","1276656117"
"Chirchiq","Chirchiq","41.4667","69.5833","Uzbekistan","UZ","UZB","Toshkent","","159400","1860343195"
"La Laguna","La Laguna","28.4853","-16.3167","Spain","ES","ESP","Canary Islands","","158010","1724625025"
"Catamarca","Catamarca","-28.4667","-65.7833","Argentina","AR","ARG","Catamarca","admin","159139","1032260908"
"Angers","Angers","47.4736","-0.5542","France","FR","FRA","Pays de la Loire","minor","155850","1250962174"
"Jijiga","Jijiga","9.3500","42.8000","Ethiopia","ET","ETH","Sumalē","admin","159300","1231737909"
"George","George","-33.9667","22.4500","South Africa","ZA","ZAF","Western Cape","","157394","1710164136"
"Ravenna","Ravenna","44.4161","12.2017","Italy","IT","ITA","Emilia-Romagna","minor","159115","1380964180"
"Higashimurayama","Higashimurayama","35.7546","139.4685","Japan","JP","JPN","Tōkyō","","150458","1392034645"
"’s-Hertogenbosch","'s-Hertogenbosch","51.6833","5.3000","Netherlands","NL","NLD","Noord-Brabant","admin","157486","1528012333"
"Bharūch","Bharuch","21.6948","72.9805","India","IN","IND","Gujarāt","","158253","1356981799"
"Ōgaki","Ogaki","35.3594","136.6129","Japan","JP","JPN","Gifu","","158386","1392727505"
"Nan Zhuang","Nan Zhuang","22.9839","113.0139","China","CN","CHN","Guangdong","","156902","1156745569"
"Quipungo","Quipungo","-14.8167","14.5167","Angola","AO","AGO","Huíla","","158918","1024929138"
"Cadiz","Cadiz","10.9500","123.3000","Philippines","PH","PHL","Negros Occidental","","158544","1608224037"
"Molo","Molo","-0.2500","35.7333","Kenya","KE","KEN","Nakuru","","156732","1404340216"
"Châu Đốc","Chau Doc","10.7000","105.1167","Vietnam","VN","VNM","An Giang","","157298","1704020910"
"Zaanstad","Zaanstad","52.4697","4.7767","Netherlands","NL","NLD","Noord-Holland","","156901","1528395473"
"Guimarães","Guimaraes","41.4500","-8.3000","Portugal","PT","PRT","Braga","minor","158124","1620771539"
"Tigaraksa","Tigaraksa","-6.2667","106.4667","Indonesia","ID","IDN","Jawa Barat","","155557","1360755390"
"Matsuzaka","Matsuzaka","34.5779","136.5276","Japan","JP","JPN","Mie","","158472","1392893621"
"Macuspana","Macuspana","17.7667","-92.6000","Mexico","MX","MEX","Tabasco","minor","158601","1484086926"
"Aral","Aral","40.5480","81.2810","China","CN","CHN","Xinjiang","minor","158593","1156703340"
"Hoofddorp","Hoofddorp","52.3061","4.6907","Netherlands","NL","NLD","Noord-Holland","minor","157789","1528519634"
"Sāmarrā’","Samarra'","34.1983","43.8742","Iraq","IQ","IRQ","Şalāḩ ad Dīn","minor","158508","1368265789"
"Ilagan","Ilagan","17.1489","121.8894","Philippines","PH","PHL","Isabela","admin","158218","1608190610"
"New Bedford","New Bedford","41.6697","-70.9428","United States","US","USA","Massachusetts","","158353","1840003219"
"Rafael Castillo","Rafael Castillo","-34.7167","-58.6167","Argentina","AR","ARG","Buenos Aires","","147965","1032812310"
"Río Cuarto","Rio Cuarto","-33.1333","-64.3500","Argentina","AR","ARG","Córdoba","minor","158298","1032552145"
"Rafaḩ","Rafah","31.2886","34.2519","Gaza Strip","XG","XGZ","","","152950","1916824868"
"Bariadi","Bariadi","-2.7919","33.9894","Tanzania","TZ","TZA","Simiyu","admin","155620","1834546025"
"Alexandria","Alexandria","38.8185","-77.0861","United States","US","USA","Virginia","","158185","1840003837"
"Enfield","Enfield","51.6522","-0.0808","United Kingdom","GB","GBR","Enfield","","156858","1826362409"
"Daiwanishi","Daiwanishi","34.8333","135.4167","Japan","JP","JPN","Hyōgo","","155165","1392478155"
"Gojra","Gojra","31.1500","72.6833","Pakistan","PK","PAK","Punjab","","157863","1586904918"
"Teixeira de Freitas","Teixeira de Freitas","-17.5403","-39.7437","Brazil","BR","BRA","Bahia","","157804","1076918835"
"Paterson","Paterson","40.9147","-74.1624","United States","US","USA","New Jersey","","157927","1840000838"
"Camarajibe","Camarajibe","-8.0219","-34.9808","Brazil","BR","BRA","Pernambuco","","155054","1076738662"
"Corona","Corona","33.8616","-117.5649","United States","US","USA","California","","157844","1840019305"
"Fethiye","Fethiye","36.6514","29.1231","Turkey","TR","TUR","Muğla","minor","157745","1792001223"
"Mahlaing","Mahlaing","21.0900","95.6400","Myanmar","MM","MMR","Mandalay","","157674","1104117085"
"Ŭiwang","Uiwang","37.3448","126.9683","South Korea","KR","KOR","Gyeonggi","","154879","1410211816"
"Saharsa","Saharsa","25.8800","86.6000","India","IN","IND","Bihār","","156540","1356492911"
"Cinere","Cinere","-6.3333","106.7833","Indonesia","ID","IDN","Jawa Barat","","144038","1360008330"
"Merced","Merced","37.3057","-120.4779","United States","US","USA","California","","157617","1840020313"
"Danao","Danao","10.5333","123.9333","Philippines","PH","PHL","Cebu","","156321","1608547808"
"Calama","Calama","-22.4667","-68.9333","Chile","CL","CHL","Antofagasta","minor","157575","1152168074"
"Chaoshan","Chaoshan","23.0701","113.8693","China","CN","CHN","Guangdong","","156522","1156193615"
"Nāblus","Nablus","32.2222","35.2611","West Bank","XW","XWB","","","156906","1934360634"
"Erzincan","Erzincan","39.7464","39.4914","Turkey","TR","TUR","Erzincan","admin","157452","1792742754"
"Warner Robins","Warner Robins","32.5961","-83.6527","United States","US","USA","Georgia","","157416","1840015800"
"Salzburg","Salzburg","47.8000","13.0450","Austria","AT","AUT","Salzburg","admin","155021","1040312147"
"Mandi Bahauddin","Mandi Bahauddin","32.5797","73.4814","Pakistan","PK","PAK","Punjab","minor","157352","1586202018"
"El Eulma","El Eulma","36.1528","5.6900","Algeria","DZ","DZA","Sétif","","155038","1012292620"
"Itapetininga","Itapetininga","-23.5917","-48.0531","Brazil","BR","BRA","São Paulo","","157016","1076425970"
"Xiaping","Xiaping","26.7646","114.3093","China","CN","CHN","Jiangxi","minor","157000","1156122424"
"Port Arthur","Port Arthur","29.8554","-93.9264","United States","US","USA","Texas","","156988","1840020919"
"Chlef","Chlef","36.1647","1.3317","Algeria","DZ","DZA","Chlef","admin","155134","1012772239"
"Guaymas","Guaymas","28.1667","-110.6667","Mexico","MX","MEX","Sonora","","156863","1484822562"
"Pathānkot","Pathankot","32.2668","75.6000","India","IN","IND","Punjab","","155909","1356878237"
"Nazilli","Nazilli","37.9125","28.3206","Turkey","TR","TUR","Aydın","minor","156748","1792517713"
"Kamina","Kamina","-8.7386","24.9906","Congo (Kinshasa)","CD","COD","Haut-Lomami","admin","156761","1180363197"
"Lajes","Lajes","-27.8158","-50.3258","Brazil","BR","BRA","Santa Catarina","","156727","1076517475"
"Macon","Macon","32.8065","-83.6974","United States","US","USA","Georgia","","156711","1840043455"
"Piteşti","Pitesti","44.8606","24.8678","Romania","RO","ROU","Argeş","admin","155383","1642781938"
"Kétou","Ketou","7.3581","2.6075","Benin","BJ","BEN","Plateau","","156497","1204020661"
"Adzopé","Adzope","6.1667","-3.9833","Côte d'Ivoire","CI","CIV","Lagunes","minor","156488","1384370507"
"Poblacion","Poblacion","10.4167","123.9667","Philippines","PH","PHL","Cebu","","153197","1608456191"
"Hanam","Hanam","37.5167","127.2000","South Korea","KR","KOR","Gyeonggi","","154838","1410241338"
"Capas","Capas","15.3372","120.5900","Philippines","PH","PHL","Tarlac","","156056","1608379387"
"Ghorāhī","Ghorahi","28.0333","82.4833","Nepal","NP","NPL","","","156164","1524494654"
"Vidisha","Vidisha","23.5239","77.8061","India","IN","IND","Madhya Pradesh","","155959","1356299932"
"Cẩm Phả","Cam Pha","21.0167","107.3167","Vietnam","VN","VNM","Quảng Ninh","minor","156000","1704985016"
"Esmeraldas","Esmeraldas","0.9667","-79.6528","Ecuador","EC","ECU","Esmeraldas","admin","154035","1218601363"
"Ar Ramthā","Ar Ramtha","32.5589","36.0147","Jordan","JO","JOR","Irbid","minor","155693","1400675695"
"Kariya","Kariya","34.9893","137.0021","Japan","JP","JPN","Aichi","","153178","1392620119"
"Al Miqdādīyah","Al Miqdadiyah","33.9786","44.9369","Iraq","IQ","IRQ","Diyālá","minor","155968","1368045951"
"Almetyevsk","Almetyevsk","54.9000","52.3000","Russia","RU","RUS","Tatarstan","","155988","1643421011"
"Danzao","Danzao","23.0427","112.9145","China","CN","CHN","Guangdong","","154955","1156576022"
"Cagliari","Cagliari","39.2278","9.1111","Italy","IT","ITA","Sardegna","admin","154106","1380685705"
"Hitachi-Naka","Hitachi-Naka","36.3967","140.5347","Japan","JP","JPN","Ibaraki","","154311","1392134860"
"Vĩnh Yên","Vinh Yen","21.3100","105.5967","Vietnam","VN","VNM","Vĩnh Phúc","admin","152801","1704905946"
"Nalgonda","Nalgonda","17.0500","79.2700","India","IN","IND","Andhra Pradesh","","154326","1356414359"
"Rudarpur","Rudarpur","28.9708","79.3972","India","IN","IND","Uttarākhand","","154485","1356437436"
"Kansas City","Kansas City","39.1235","-94.7443","United States","US","USA","Kansas","","155745","1840001626"
"Kisi","Kisi","9.0833","3.8500","Nigeria","NG","NGA","Oyo","minor","155510","1566512059"
"Thānesar","Thanesar","29.9667","76.8167","India","IN","IND","Haryāna","","154962","1356380934"
"El Minié","El Minie","34.4470","35.8178","Lebanon","LB","LBN","Liban-Nord","","150000","1422845769"
"Lakewood","Lakewood","39.6977","-105.1172","United States","US","USA","Colorado","","155608","1840020198"
"Borāzjān","Borazjan","29.2694","51.2200","Iran","IR","IRN","Būshehr","minor","155567","1364720199"
"Gulu","Gulu","2.7817","32.2992","Uganda","UG","UGA","Gulu","admin","152276","1800406007"
"Sunnyvale","Sunnyvale","37.3836","-122.0255","United States","US","USA","California","","155550","1840021573"
"Timon","Timon","-5.0939","-42.8369","Brazil","BR","BRA","Maranhão","","155460","1076758286"
"Palo Negro","Palo Negro","10.1600","-67.5582","Venezuela","VE","VEN","Aragua","minor","153706","1862061949"
"Bytom","Bytom","50.3483","18.9156","Poland","PL","POL","Śląskie","minor","153274","1616740907"
"Souk Ahras","Souk Ahras","36.2864","7.9511","Algeria","DZ","DZA","Souk Ahras","admin","155259","1012419619"
"Middelburg","Middelburg","-25.7684","29.4783","South Africa","ZA","ZAF","Mpumalanga","","154706","1710495632"
"Horad Barysaw","Horad Barysaw","54.2279","28.5050","Belarus","BY","BLR","Minskaya Voblasts’","minor","155389","1112659163"
"Regensburg","Regensburg","49.0167","12.0833","Germany","DE","DEU","Bavaria","minor","153542","1276000325"
"Fredericksburg","Fredericksburg","38.2992","-77.4872","United States","US","USA","Virginia","","155414","1840003845"
"Jorhāt","Jorhat","26.7500","94.2200","India","IN","IND","Assam","","153249","1356638741"
"Oxford","Oxford","51.7519","-1.2578","United Kingdom","GB","GBR","Oxfordshire","","152000","1826689568"
"Neuss","Neuss","51.2000","6.7000","Germany","DE","DEU","North Rhine-Westphalia","minor","153796","1276075502"
"Hājīpur","Hajipur","25.6925","85.2084","India","IN","IND","Bihār","","147688","1356014808"
"Caxias","Caxias","-4.8589","-43.3558","Brazil","BR","BRA","Maranhão","","155129","1076325693"
"Bartın","Bartin","41.6344","32.3375","Turkey","TR","TUR","Bartın","admin","155016","1792863603"
"San Juan","San Juan","14.6000","121.0300","Philippines","PH","PHL","San Juan","admin","126347","1608817515"
"Melitopol","Melitopol","46.8489","35.3675","Ukraine","UA","UKR","Zaporizka Oblast","minor","154992","1804906240"
"Zango","Zango","12.9333","8.5333","Nigeria","NG","NGA","Katsina","minor","154743","1566483135"
"Sasolburg","Sasolburg","-26.8142","27.8286","South Africa","ZA","ZAF","Free State","","153038","1710432260"
"Kafr ash Shaykh","Kafr ash Shaykh","31.1117","30.9458","Egypt","EG","EGY","Kafr ash Shaykh","admin","147393","1818743040"
"Kitenkela","Kitenkela","-1.5167","36.8500","Kenya","KE","KEN","Kajiado","","154436","1404000624"
"Bālurghāt","Balurghat","25.2200","88.7600","India","IN","IND","West Bengal","","153279","1356496150"
"Madīnat as Sādis min Uktūbar","Madinat as Sadis min Uktubar","29.9361","30.9269","Egypt","EG","EGY","Al Jīzah","","154093","1818735651"
"Dibrugarh","Dibrugarh","27.4800","95.0000","India","IN","IND","Assam","","154019","1356100193"
"Verāval","Veraval","20.9000","70.3700","India","IN","IND","Gujarāt","","153696","1356346826"
"Alagoinhas","Alagoinhas","-12.1358","-38.4189","Brazil","BR","BRA","Bahia","","154495","1076155953"
"Edremit","Edremit","39.5922","27.0200","Turkey","TR","TUR","Balıkesir","minor","154487","1792678708"
"Bandırma","Bandirma","40.3500","27.9667","Turkey","TR","TUR","Balıkesir","minor","154359","1792423107"
"Salavat","Salavat","53.3667","55.9333","Russia","RU","RUS","Bashkortostan","","153181","1643996483"
"Gandajika","Gandajika","-6.7500","23.9667","Congo (Kinshasa)","CD","COD","Lomami","","154425","1180466776"
"Lucapa","Lucapa","-8.4228","20.7393","Angola","AO","AGO","Lunda-Norte","","154305","1024056286"
"Leesburg","Leesburg","28.7672","-81.8962","United States","US","USA","Florida","","154301","1840015083"
"Tama","Tama","35.6369","139.4463","Japan","JP","JPN","Tōkyō","","147252","1392092333"
"Ras Tanura","Ras Tanura","26.6500","50.1667","Saudi Arabia","SA","SAU","Ash Sharqīyah","","153933","1682997537"
"Hòa Thành","Hoa Thanh","11.2831","106.1297","Vietnam","VN","VNM","Tây Ninh","minor","152339","1704970547"
"Harrow","Harrow","51.5836","-0.3464","United Kingdom","GB","GBR","Harrow","","149246","1826648099"
"Seogwipo","Seogwipo","33.2497","126.5600","South Korea","KR","KOR","Jeju","","153861","1410542763"
"Tochigi","Tochigi","36.3813","139.7303","Japan","JP","JPN","Tochigi","","153508","1392660923"
"Moanda","Moanda","-5.9342","12.3494","Congo (Kinshasa)","CD","COD","Kongo Central","","153915","1180108441"
"Colima","Colima","19.2433","-103.7247","Mexico","MX","MEX","Colima","admin","150673","1484585697"
"Barreiras","Barreiras","-12.1483","-44.9925","Brazil","BR","BRA","Bahia","","153918","1076854674"
"Naogaon","Naogaon","24.8000","88.9333","Bangladesh","BD","BGD","Rājshāhi","minor","150025","1050668499"
"Tete","Tete","-16.1667","33.6000","Mozambique","MZ","MOZ","Tete","admin","152909","1508476900"
"Villanueva","Villanueva","15.3167","-88.0167","Honduras","HN","HND","Cortés","","153733","1340079427"
"Noda","Noda","35.9500","139.8667","Japan","JP","JPN","Chiba","","152227","1392224794"
"Kanasín","Kanasin","20.9344","-89.5578","Mexico","MX","MEX","Yucatán","minor","139753","1484390572"
"Ixtlahuaca","Ixtlahuaca","19.5689","-99.7669","Mexico","MX","MEX","México","minor","153184","1484837068"
"Hoeryŏng","Hoeryong","42.4333","129.7500","North Korea","KP","PRK","Hambuk","minor","153532","1408656165"
"Iringa","Iringa","-7.7700","35.6900","Tanzania","TZ","TZA","Iringa","admin","151345","1834453478"
"San Jose","San Jose","12.3528","121.0676","Philippines","PH","PHL","Occidental Mindoro","","153267","1608355709"
"Kırşehir","Kirsehir","39.1456","34.1608","Turkey","TR","TUR","Kırşehir","admin","153511","1792825471"
"Newcastle","Newcastle","-32.9167","151.7500","Australia","AU","AUS","New South Wales","","152984","1036468001"
"Sievierodonetsk","Sievierodonetsk","48.9481","38.4933","Ukraine","UA","UKR","Luhanska Oblast","minor","150000","1804577024"
"Sinpo","Sinpo","40.0347","128.1856","North Korea","KP","PRK","Hamnam","","152759","1408269466"
"Paderborn","Paderborn","51.7181","8.7542","Germany","DE","DEU","North Rhine-Westphalia","minor","152531","1276339087"
"Nevşehir","Nevsehir","38.6264","34.7139","Turkey","TR","TUR","Nevşehir","admin","153117","1792583760"
"Dutse","Dutse","11.8283","9.3158","Nigeria","NG","NGA","Jigawa","admin","153000","1566855083"
"Minglanilla","Minglanilla","10.2450","123.7964","Philippines","PH","PHL","Cebu","","151002","1608573791"
"Champaign","Champaign","40.1142","-88.2737","United States","US","USA","Illinois","","153278","1840007239"
"Kiambu","Kiambu","-1.1667","36.8167","Kenya","KE","KEN","Kiambu","admin","147870","1404390868"
"Ueda","Ueda","36.4019","138.2491","Japan","JP","JPN","Nagano","","152948","1392438902"
"Dahuaishu","Dahuaishu","36.2601","111.6743","China","CN","CHN","Shanxi","minor","151037","1156096615"
"Amatitlán","Amatitlan","14.4741","-90.6247","Guatemala","GT","GTM","Guatemala","minor","152296","1320637423"
"Singida","Singida","-4.8167","34.7500","Tanzania","TZ","TZA","Singida","admin","150379","1834418526"
"Shāntipur","Shantipur","23.2500","88.4300","India","IN","IND","West Bengal","","151777","1356584320"
"Moriguchi","Moriguchi","34.7375","135.5642","Japan","JP","JPN","Ōsaka","","141849","1392003269"
"Yilan","Yilan","24.7500","121.7500","Taiwan","TW","TWN","Yilan","admin","150000","1158534780"
"Siem Reap","Siem Reap","13.3622","103.8597","Cambodia","KH","KHM","Siem Reap","admin","147866","1116263015"
"Golmeh","Golmeh","33.7842","46.5950","Iran","IR","IRN","Īlām","","152894","1364909317"
"Fengyicun","Fengyicun","23.6636","116.6028","China","CN","CHN","Guangdong","","150138","1156702015"
"Hindupur","Hindupur","13.8300","77.4900","India","IN","IND","Andhra Pradesh","","151677","1356805611"
"Kawashiri","Kawashiri","34.8301","135.4173","Japan","JP","JPN","Ōsaka","","152076","1392471189"
"Poços de Caldas","Pocos de Caldas","-21.7878","-46.5608","Brazil","BR","BRA","Minas Gerais","","152435","1076336113"
"Peristéri","Peristeri","38.0167","23.6833","Greece","GR","GRC","Attikí","minor","139981","1300302779"
"Logroño","Logrono","42.4650","-2.4456","Spain","ES","ESP","La Rioja","admin","150808","1724721513"
"Dīla","Dila","6.4083","38.3083","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","151682","1231393325"
"San Andres","San Andres","14.5739","121.0039","Philippines","PH","PHL","Rizal","","128499","1608862371"
"Ciudad Choluteca","Ciudad Choluteca","13.3011","-87.1842","Honduras","HN","HND","Choluteca","admin","152519","1340256619"
"Bohicon","Bohicon","7.2000","2.0667","Benin","BJ","BEN","Zou","","149271","1204200335"
"Baubau","Baubau","-5.4667","122.6330","Indonesia","ID","IDN","Sulawesi Tenggara","","152143","1360789994"
"Erode","Erode","11.3409","77.7171","India","IN","IND","Tamil Nādu","","151184","1356040695"
"Ramu","Ramu","3.9375","41.2203","Kenya","KE","KEN","Mandera","","143850","1404221019"
"Hollywood","Hollywood","26.0293","-80.1679","United States","US","USA","Florida","","152597","1840015144"
"Mudon","Mudon","16.2578","97.7164","Myanmar","MM","MMR","Mon State","","152300","1104174785"
"Taza","Taza","34.2167","-4.0167","Morocco","MA","MAR","Fès-Meknès","","148456","1504405154"
"Nek’emtē","Nek'emte","9.0833","36.5500","Ethiopia","ET","ETH","Oromīya","","148613","1231437227"
"Tauranga","Tauranga","-37.6833","176.1667","New Zealand","NZ","NZL","Bay of Plenty","","151300","1554759867"
"Gwangyang","Gwangyang","34.9333","127.6833","South Korea","KR","KOR","Jeonnam","minor","151834","1410398748"
"Marbella","Marbella","36.5167","-4.8833","Spain","ES","ESP","Andalusia","","150725","1724685705"
"Beāwar","Beawar","26.1011","74.3203","India","IN","IND","Rājasthān","","151472","1356028963"
"Abaetetuba","Abaetetuba","-1.7178","-48.8828","Brazil","BR","BRA","Pará","","151934","1076974632"
"Kukichūō","Kukichuo","36.0621","139.6668","Japan","JP","JPN","Saitama","","150197","1392642279"
"Consolacion","Consolacion","10.4000","123.9500","Philippines","PH","PHL","Cebu","","148012","1608199695"
"Gonbad-e Kāvūs","Gonbad-e Kavus","37.2500","55.1672","Iran","IR","IRN","Golestān","minor","151910","1364336980"
"Al Manāqil","Al Manaqil","14.2500","32.9833","Sudan","SD","SDN","Gezira","","151827","1729742500"
"Tororo","Tororo","0.6928","34.1811","Uganda","UG","UGA","Tororo","admin","150000","1800214991"
"Pasadena","Pasadena","29.6575","-95.1498","United States","US","USA","Texas","","151964","1840020930"
"Miass","Miass","55.0000","60.1000","Russia","RU","RUS","Chelyabinskaya Oblast’","","151856","1643043679"
"Mzuzu","Mzuzu","-11.4581","34.0151","Malawi","MW","MWI","Mzimba","","150100","1454525586"
"Delicias","Delicias","28.1931","-105.4717","Mexico","MX","MEX","Chihuahua","minor","150506","1484405772"
"Nakhodka","Nakhodka","42.8167","132.8833","Russia","RU","RUS","Primorskiy Kray","","151420","1643144321"
"Quevedo","Quevedo","-1.0333","-79.4500","Ecuador","EC","ECU","Los Ríos","","150827","1218928435"
"Asaka","Asaka","35.7972","139.5939","Japan","JP","JPN","Saitama","","143915","1392003253"
"San Jose","San Jose","15.7919","120.9900","Philippines","PH","PHL","Nueva Ecija","","150917","1608000478"
"Foggia","Foggia","41.4642","15.5461","Italy","IT","ITA","Puglia","minor","151372","1380084447"
"Pomona","Pomona","34.0585","-117.7626","United States","US","USA","California","","151592","1840020507"
"Sayama","Sayama","35.8530","139.4122","Japan","JP","JPN","Saitama","","148474","1392752018"
"Aş Şuwayḩirah as Sāḩil","As Suwayhirah as Sahil","24.3620","56.7344","Oman","OM","OMN","Shamāl al Bāţinah","","151349","1512145575"
"Escondido","Escondido","33.1348","-117.0723","United States","US","USA","California","","151443","1840020620"
"Jaranwala","Jaranwala","31.3342","73.4194","Pakistan","PK","PAK","Punjab","","150380","1586829520"
"Đức Phổ","Duc Pho","14.8100","108.9600","Vietnam","VN","VNM","Quảng Ngãi","minor","150927","1704354008"
"Miskolc","Miskolc","48.0833","20.6667","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","admin","150695","1348668815"
"Clermont-Ferrand","Clermont-Ferrand","45.7831","3.0824","France","FR","FRA","Auvergne-Rhône-Alpes","minor","147865","1250135509"
"Florencio Varela","Florencio Varela","-34.8167","-58.3833","Argentina","AR","ARG","Buenos Aires","minor","146704","1032021324"
"Chichicastenango","Chichicastenango","14.9442","-91.1105","Guatemala","GT","GTM","Quiché","minor","150932","1320948769"
"Manzanillo","Manzanillo","20.3397","-77.1086","Cuba","CU","CUB","Granma","minor","150999","1192805452"
"Vryheid","Vryheid","-27.7669","30.8000","South Africa","ZA","ZAF","KwaZulu-Natal","","150012","1710716158"
"Talcahuano","Talcahuano","-36.7167","-73.1219","Chile","CL","CHL","Biobío","","147831","1152023044"
"Kerch","Kerch","45.3619","36.4711","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","149566","1804437456"
"M’Sila","M'Sila","35.7058","4.5419","Algeria","DZ","DZA","M’sila","admin","150000","1012857730"
"Patos de Minas","Patos de Minas","-18.5817","-46.5147","Brazil","BR","BRA","Minas Gerais","","150833","1076100026"
"Kolār","Kolar","13.1333","78.1333","India","IN","IND","Karnātaka","","150357","1356953848"
"Mariveles","Mariveles","14.4333","120.4833","Philippines","PH","PHL","Bataan","","149879","1608735167"
"Copiapó","Copiapo","-27.3664","-70.3331","Chile","CL","CHL","Atacama","admin","150804","1152406965"
"Kragujevac","Kragujevac","44.0142","20.9394","Serbia","RS","SRB","Kragujevac","admin","150623","1688832387"
"Pôrto Seguro","Porto Seguro","-16.4333","-39.0833","Brazil","BR","BRA","Bahia","","150658","1076932180"
"Badajoz","Badajoz","38.8803","-6.9753","Spain","ES","ESP","Extremadura","minor","150610","1724759742"
"Soro","Soro","21.2900","86.6900","India","IN","IND","Odisha","","150000","1356411349"
"Lalian","Lalian","31.8253","72.8027","Pakistan","PK","PAK","Punjab","","150000","1586600950"
"Đồng Xoài","Dong Xoai","11.5169","106.8392","Vietnam","VN","VNM","Bình Phước","admin","150052","1704845487"
"Nîmes","Nimes","43.8380","4.3610","France","FR","FRA","Occitanie","minor","149633","1250417467"
"Sumbawanga","Sumbawanga","-7.9667","31.6167","Tanzania","TZ","TZA","Rukwa","admin","147483","1834684278"
"Araguaína","Araguaina","-7.1908","-48.2069","Brazil","BR","BRA","Tocantins","","150484","1076632868"
"Chauk Azam","Chauk Azam","30.9648","71.2170","Pakistan","PK","PAK","Punjab","","150000","1586262687"
"Rimini","Rimini","44.0594","12.5683","Italy","IT","ITA","Emilia-Romagna","minor","149403","1380527853"
"Gubeng","Gubeng","-7.2729","112.7493","Indonesia","ID","IDN","Jawa Timur","","133846","1360012184"
"Ocumare del Tuy","Ocumare del Tuy","10.1136","-66.7814","Venezuela","VE","VEN","Miranda","minor","150000","1862015576"
"Joliet","Joliet","41.5188","-88.1499","United States","US","USA","Illinois","","150323","1840008192"
"Auchi","Auchi","7.0667","6.2667","Nigeria","NG","NGA","Edo","minor","150000","1566873539"
"Villa de Álvarez","Villa de Alvarez","19.2500","-103.7333","Mexico","MX","MEX","Colima","minor","149762","1484653806"
"Urganch","Urganch","41.5500","60.6333","Uzbekistan","UZ","UZB","Xorazm","admin","150000","1860546433"
"Komaki","Komaki","35.2910","136.9121","Japan","JP","JPN","Aichi","","147897","1392770958"
"Valdivia","Valdivia","-39.8139","-73.2458","Chile","CL","CHL","Los Ríos","admin","150048","1152201132"
"Valle de Santiago","Valle de Santiago","20.3928","-101.1969","Mexico","MX","MEX","Guanajuato","minor","150054","1484060538"
"Toda","Toda","35.8176","139.6779","Japan","JP","JPN","Saitama","","142352","1392003295"
"Shāhrūd","Shahrud","36.4181","54.9764","Iran","IR","IRN","Semnān","minor","150129","1364363854"
"Kutaisi","Kutaisi","42.2500","42.7000","Georgia","GE","GEO","Imereti","admin","147900","1268369835"
"Kashikishi","Kashikishi","-9.3000","28.7167","Zambia","ZM","ZMB","Luapula","","150000","1894795016"
"Lake Charles","Lake Charles","30.2010","-93.2111","United States","US","USA","Louisiana","","150036","1840015039"
"Pénjamo","Penjamo","20.4311","-101.7222","Mexico","MX","MEX","Guanajuato","minor","149936","1484131107"
"Odivelas","Odivelas","38.7903","-9.1797","Portugal","PT","PRT","Lisboa","minor","144549","1620010482"
"Mesquite","Mesquite","32.7602","-96.5865","United States","US","USA","Texas","","149848","1840020713"
"Saumlaki","Saumlaki","-7.9750","131.3075","Indonesia","ID","IDN","Maluku","minor","149790","1360861988"
"Gloucester","Gloucester","51.8644","-2.2444","United Kingdom","GB","GBR","Gloucestershire","","145563","1826932068"
"Maīmanah","Maimanah","35.9333","64.7500","Afghanistan","AF","AFG","Fāryāb","admin","149040","1004622920"
"Mauldin","Mauldin","34.7821","-82.3032","United States","US","USA","South Carolina","","149687","1840015476"
"Potchefstroom","Potchefstroom","-26.7150","27.1033","South Africa","ZA","ZAF","North West","","148804","1710838885"
"Imabari","Imabari","34.0667","133.0000","Japan","JP","JPN","Ehime","","149209","1392801732"
"Abbottabad","Abbottabad","34.1558","73.2194","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","148587","1586788758"
"Concordia","Concordia","-31.4000","-58.0333","Argentina","AR","ARG","Entre Ríos","minor","149450","1032923957"
"West Bromwich","West Bromwich","52.5190","-1.9950","United Kingdom","GB","GBR","Sandwell","","146386","1826231773"
"Panama City","Panama City","30.1995","-85.6003","United States","US","USA","Florida","","149366","1840015034"
"Bellevue","Bellevue","47.5951","-122.1535","United States","US","USA","Washington","","149365","1840018417"
"Centro Habana","Centro Habana","23.1333","-82.3833","Cuba","CU","CUB","La Habana","","140234","1192000782"
"Sagay","Sagay","10.9000","123.4167","Philippines","PH","PHL","Negros Occidental","","148894","1608284971"
"Ağrı","Agri","39.7186","43.0508","Turkey","TR","TUR","Ağrı","admin","149188","1792385654"
"Hưng Yên","Hung Yen","20.6500","106.0667","Vietnam","VN","VNM","Hưng Yên","admin","147275","1704000203"
"‘Ajlūn","\`Ajlun","32.3325","35.7517","Jordan","JO","JOR","‘Ajlūn","admin","148870","1400775371"
"Amasya","Amasya","40.6500","35.8331","Turkey","TR","TUR","Amasya","admin","149084","1792344167"
"Santiago","Santiago","16.6833","121.5500","Philippines","PH","PHL","Santiago","admin","148580","1608477470"
"Bandar-e Anzalī","Bandar-e Anzali","37.4728","49.4622","Iran","IR","IRN","Gīlān","minor","148564","1364759228"
"Iruma","Iruma","35.8358","139.3911","Japan","JP","JPN","Saitama","","145778","1392661533"
"Kastamonu","Kastamonu","41.3764","33.7764","Turkey","TR","TUR","Kastamonu","admin","148931","1792386602"
"Naperville","Naperville","41.7480","-88.1659","United States","US","USA","Illinois","","149013","1840008143"
"Dongsheng","Dongsheng","22.8869","113.4563","China","CN","CHN","Guangdong","","147228","1156418808"
"Sitārganj","Sitarganj","28.9300","79.7000","India","IN","IND","Uttarākhand","","148266","1356157911"
"Marvdasht","Marvdasht","29.8742","52.8025","Iran","IR","IRN","Fārs","minor","148858","1364881808"
"Chingola","Chingola","-12.5333","27.8500","Zambia","ZM","ZMB","Copperbelt","","147448","1894202007"
"Riobamba","Riobamba","-1.6731","-78.6483","Ecuador","EC","ECU","Chimborazo","admin","146324","1218552075"
"Szombathely","Szombathely","47.2351","16.6219","Hungary","HU","HUN","Vas","admin","147920","1348565183"
"Jean-Rabel","Jean-Rabel","19.8500","-73.2000","Haiti","HT","HTI","Nord-Ouest","","148416","1332096865"
"Hābra","Habra","22.8300","88.6300","India","IN","IND","West Bengal","","147221","1356091822"
"Mostaganem","Mostaganem","35.9333","0.0833","Algeria","DZ","DZA","Mostaganem","admin","145696","1012061703"
"Harlingen","Harlingen","26.1916","-97.6977","United States","US","USA","Texas","","148545","1840021036"
"Tuscaloosa","Tuscaloosa","33.2348","-87.5268","United States","US","USA","Alabama","","148523","1840005563"
"Al ‘Aqabah","Al \`Aqabah","29.5319","35.0056","Jordan","JO","JOR","Al ‘Aqabah","admin","148398","1400618058"
"Pemba","Pemba","-12.9667","40.5500","Mozambique","MZ","MOZ","Cabo Delgado","admin","141316","1508872233"
"Nowgong","Nowgong","26.3492","92.6861","India","IN","IND","Assam","","147137","1356273589"
"Quilpué","Quilpue","-33.0500","-71.4500","Chile","CL","CHL","Valparaíso","minor","147991","1152063222"
"Sibiu","Sibiu","45.7928","24.1519","Romania","RO","ROU","Sibiu","admin","147245","1642393086"
"Yonago","Yonago","35.4333","133.3333","Japan","JP","JPN","Tottori","","147210","1392391989"
"Dundee","Dundee","56.4620","-2.9707","United Kingdom","GB","GBR","Dundee City","","148280","1826581481"
"Disūq","Disuq","31.1422","30.6450","Egypt","EG","EGY","Kafr ash Shaykh","","143404","1818106054"
"Kopeysk","Kopeysk","55.1000","61.6167","Russia","RU","RUS","Chelyabinskaya Oblast’","","147573","1643343511"
"Lüleburgaz","Luleburgaz","41.4056","27.3569","Turkey","TR","TUR","Kırklareli","minor","148037","1792000752"
"Al Ḩawīyah","Al Hawiyah","21.4411","40.4975","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","148151","1682803387"
"Salamanca","Salamanca","40.9650","-5.6642","Spain","ES","ESP","Castille-Leon","minor","143269","1724960825"
"Mbanza Kongo","Mbanza Kongo","-6.2667","14.2500","Angola","AO","AGO","Zaire","admin","148000","1024532464"
"Nchelenge","Nchelenge","-9.3533","28.7403","Zambia","ZM","ZMB","Luapula","","147927","1894890604"
"Zhangaözen","Zhangaozen","43.3378","52.8553","Kazakhstan","KZ","KAZ","","","147962","1398274968"
"Turbat","Turbat","26.0031","63.0544","Pakistan","PK","PAK","Balochistan","minor","147791","1586802806"
"Mati","Mati","6.9483","126.2272","Philippines","PH","PHL","Davao Oriental","admin","147547","1608000731"
"Mangghystaū","Mangghystau","43.6905","51.1417","Kazakhstan","KZ","KAZ","","","147443","1398790140"
"Malakal","Malakal","9.5500","31.6500","South Sudan","SS","SSD","Upper Nile","admin","147450","1728299711"
"Elkhart","Elkhart","41.6916","-85.9627","United States","US","USA","Indiana","","147679","1840008187"
"Osorno","Osorno","-40.5725","-73.1353","Chile","CL","CHL","Los Lagos","minor","147460","1152866014"
"Al Qurayyāt","Al Qurayyat","31.3167","37.3667","Saudi Arabia","SA","SAU","Al Jawf","","147550","1682805250"
"Vólos","Volos","39.3667","22.9333","Greece","GR","GRC","Thessalía","minor","144449","1300109851"
"Bacău","Bacau","46.5833","26.9167","Romania","RO","ROU","Bacău","admin","144307","1642838304"
"Mogi Guaçu","Mogi Guacu","-22.3719","-46.9419","Brazil","BR","BRA","São Paulo","","147233","1076749992"
"Pyatigorsk","Pyatigorsk","44.0500","43.0667","Russia","RU","RUS","Stavropol’skiy Kray","","145836","1643815630"
"Moshi","Moshi","-3.3349","37.3404","Tanzania","TZ","TZA","Kilimanjaro","admin","144739","1834217168"
"Payatas","Payatas","14.7099","121.0997","Philippines","PH","PHL","Quezon","","130333","1608905832"
"Torrance","Torrance","33.8346","-118.3416","United States","US","USA","California","","147156","1840021873"
"Dar‘ā","Dar\`a","32.6189","36.1021","Syria","SY","SYR","Dar‘ā","admin","146481","1760427038"
"Ramapo","Ramapo","41.1404","-74.1072","United States","US","USA","New York","","147119","1840058431"
"Cairns","Cairns","-16.9200","145.7800","Australia","AU","AUS","Queensland","","146778","1036016939"
"Bīr","Bir","18.9833","75.7667","India","IN","IND","Mahārāshtra","","146709","1356753116"
"Poole","Poole","50.7167","-1.9833","United Kingdom","GB","GBR","","","144800","1826379738"
"Rubtsovsk","Rubtsovsk","51.5167","81.2000","Russia","RU","RUS","Altayskiy Kray","","145333","1643450182"
"Negombo","Negombo","7.2111","79.8386","Sri Lanka","LK","LKA","Western","","142136","1144271397"
"Cam Ranh","Cam Ranh","11.9020","109.2207","Vietnam","VN","VNM","Khánh Hòa","","146771","1704104189"
"Maquela do Zombo","Maquela do Zombo","-6.0500","15.1167","Angola","AO","AGO","Uíge","","146908","1024582197"
"Wamba","Wamba","2.1442","27.9929","Congo (Kinshasa)","CD","COD","Haut-Uélé","","146871","1180866253"
"Binghamton","Binghamton","42.1014","-75.9093","United States","US","USA","New York","","146870","1840000460"
"Odintsovo","Odintsovo","55.6667","37.2667","Russia","RU","RUS","Moskovskaya Oblast’","","146574","1643102939"
"Dadu","Dadu","26.7319","67.7750","Pakistan","PK","PAK","Sindh","minor","146179","1586791198"
"Franco da Rocha","Franco da Rocha","-23.3286","-46.7244","Brazil","BR","BRA","São Paulo","","145755","1076071582"
"Houma","Houma","29.5800","-90.7059","United States","US","USA","Louisiana","","146665","1840015063"
"Misato","Misato","35.8301","139.8723","Japan","JP","JPN","Chiba","","141913","1392464033"
"Le Mans","Le Mans","48.0077","0.1984","France","FR","FRA","Pays de la Loire","minor","143847","1250829671"
"Etah","Etah","27.6300","78.6700","India","IN","IND","Uttar Pradesh","","145601","1356096916"
"Keren","Keren","15.7778","38.4581","Eritrea","ER","ERI","‘Anseba","admin","146500","1232693598"
"Topeka","Topeka","39.0346","-95.6955","United States","US","USA","Kansas","admin","146473","1840001621"
"Nārnaul","Narnaul","28.0444","76.1056","India","IN","IND","Haryāna","","145897","1356512084"
"Calapan","Calapan","13.4140","121.1800","Philippines","PH","PHL","Oriental Mindoro","admin","145786","1608578259"
"Colina","Colina","-33.2017","-70.6703","Chile","CL","CHL","Región Metropolitana","minor","146207","1152944896"
"Kolomna","Kolomna","55.0833","38.7833","Russia","RU","RUS","Moskovskaya Oblast’","","144125","1643196698"
"Lárisa","Larisa","39.6417","22.4167","Greece","GR","GRC","Thessalía","admin","144651","1300003141"
"Yima","Yima","34.7473","111.8609","China","CN","CHN","Henan","","144779","1156383448"
"Bhāndāria","Bhandaria","22.4883","90.0733","Bangladesh","BD","BGD","Barishal","","145233","1050979013"
"Pauktaw","Pauktaw","20.1500","92.9000","Myanmar","MM","MMR","Rakhine State","","145957","1104903555"
"Abohar","Abohar","30.1334","74.2001","India","IN","IND","Punjab","","145302","1356846795"
"Parnaíba","Parnaiba","-2.9050","-41.7769","Brazil","BR","BRA","Piauí","","145705","1076568871"
"García","Garcia","25.8167","-100.5833","Mexico","MX","MEX","Nuevo León","minor","145867","1484753599"
"Taungdwingyi","Taungdwingyi","20.0017","95.5461","Myanmar","MM","MMR","Magway","","145909","1104468571"
"Urdaneta","Urdaneta","15.9761","120.5711","Philippines","PH","PHL","Pangasinan","","144577","1608117383"
"Aalborg","Aalborg","57.0500","9.9167","Denmark","DK","DNK","Nordjylland","admin","143598","1208789278"
"Barrechid","Barrechid","33.2667","-7.5833","Morocco","MA","MAR","Casablanca-Settat","","136634","1504194885"
"Arayat","Arayat","15.1493","120.7692","Philippines","PH","PHL","Pampanga","","144875","1608482411"
"Settat","Settat","33.0000","-7.6167","Morocco","MA","MAR","Casablanca-Settat","","142250","1504720478"
"Khairpur Mir’s","Khairpur Mir's","27.5333","68.7667","Pakistan","PK","PAK","Sindh","minor","144949","1586259149"
"Aix-en-Provence","Aix-en-Provence","43.5263","5.4454","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","145133","1250001687"
"Bocaue","Bocaue","14.8000","120.9333","Philippines","PH","PHL","Bulacan","","141412","1608457468"
"Baní","Bani","18.2900","-70.3300","Dominican Republic","DO","DOM","Valdesia","minor","145595","1214720035"
"Xintang","Xintang","22.7824","113.1514","China","CN","CHN","Guangdong","","144537","1156557448"
"Matanzas","Matanzas","23.0511","-81.5753","Cuba","CU","CUB","Matanzas","admin","145246","1192888416"
"Roseville","Roseville","38.7703","-121.3196","United States","US","USA","California","","145687","1840020233"
"San Justo","San Justo","-34.6833","-58.5500","Argentina","AR","ARG","Buenos Aires","minor","136604","1032899295"
"Qūchān","Quchan","37.1060","58.5095","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","145531","1364255116"
"Yalova","Yalova","40.6556","29.2750","Turkey","TR","TUR","Yalova","admin","144407","1792838138"
"Berezniki","Berezniki","59.4167","56.7833","Russia","RU","RUS","Permskiy Kray","","145115","1643757810"
"Kaithal","Kaithal","29.8015","76.3998","India","IN","IND","Haryāna","","144915","1356810758"
"Shillong","Shillong","25.5744","91.8789","India","IN","IND","Meghālaya","admin","143229","1356533936"
"Thanatpin","Thanatpin","17.2942","96.5800","Myanmar","MM","MMR","Bago","","145287","1104961334"
"Ereğli","Eregli","37.5167","34.0500","Turkey","TR","TUR","Konya","minor","145389","1792110470"
"Villa de Cura","Villa de Cura","10.0383","-67.4892","Venezuela","VE","VEN","Aragua","minor","145098","1862979204"
"Kusatsu","Kusatsu","35.0167","135.9667","Japan","JP","JPN","Shiga","","143230","1392082200"
"David","David","8.4333","-82.4333","Panama","PA","PAN","Chiriquí","admin","144858","1591487342"
"Kakamigahara","Kakamigahara","35.3988","136.8484","Japan","JP","JPN","Gifu","","143641","1392578597"
"Khān Yūnis","Khan Yunis","31.3444","34.3031","Gaza Strip","XG","XGZ","","","142637","1916583637"
"Fairfield","Fairfield","38.2583","-122.0335","United States","US","USA","California","","145163","1840020253"
"Cuautitlán Izcalli","Cuautitlan Izcalli","19.6500","-99.2500","Mexico","MX","MEX","México","minor","140059","1484282338"
"Al Mukallā","Al Mukalla","14.5333","49.1333","Yemen","YE","YEM","Ḩaḑramawt","admin","144137","1887202594"
"Santamesa","Santamesa","14.6000","121.0167","Philippines","PH","PHL","Manila","","110073","1608043477"
"Comayagua","Comayagua","14.4522","-87.6383","Honduras","HN","HND","Comayagua","admin","144785","1340015594"
"Okinawa","Okinawa","26.3342","127.8056","Japan","JP","JPN","Okinawa","","142094","1392003477"
"Flores","Flores","16.9333","-89.8833","Guatemala","GT","GTM","Petén","admin","144932","1320651129"
"Barranca","Barranca","-10.7541","-77.7610","Peru","PE","PER","Lima","","144381","1604547675"
"Khasavyurt","Khasavyurt","43.2500","46.5833","Russia","RU","RUS","Dagestan","minor","141259","1643734694"
"Saguenay","Saguenay","48.4167","-71.0667","Canada","CA","CAN","Quebec","","144746","1124001930"
"Gisenyi","Gisenyi","-1.7000","29.2500","Rwanda","RW","RWA","Western Province","","136830","1646226936"
"Kumba","Kumba","4.6333","9.4500","Cameroon","CM","CMR","South-West","","144268","1120492761"
"Mehtar Lām","Mehtar Lam","34.6683","70.2089","Afghanistan","AF","AFG","Laghmān","admin","144162","1004590769"
"Chhatarpur","Chhatarpur","24.9177","79.5887","India","IN","IND","Madhya Pradesh","","143982","1356776353"
"Bizerte","Bizerte","37.2778","9.8639","Tunisia","TN","TUN","Bizerte","admin","142966","1788330365"
"Ruse","Ruse","43.8231","25.9539","Bulgaria","BG","BGR","Ruse","admin","143931","1100854249"
"Malasiqui","Malasiqui","15.9167","120.4167","Philippines","PH","PHL","Pangasinan","","143094","1608316754"
"Debre Birhan","Debre Birhan","9.6833","39.5333","Ethiopia","ET","ETH","Āmara","","139724","1231777741"
"Bandundu","Bandundu","-3.3167","17.3667","Congo (Kinshasa)","CD","COD","Kwilu","admin","143435","1180219776"
"Koiridih","Koiridih","24.1800","86.3000","India","IN","IND","Jhārkhand","","143529","1356249967"
"Tyler","Tyler","32.3184","-95.3062","United States","US","USA","Texas","","143895","1840022076"
"Qabr as Sitt","Qabr as Sitt","33.4472","36.3361","Syria","SY","SYR","Rīf Dimashq","","136427","1760060975"
"Coimbra","Coimbra","40.2111","-8.4292","Portugal","PT","PRT","Coimbra","admin","143396","1620166724"
"Calabayan","Calabayan","16.7667","121.7833","Philippines","PH","PHL","Isabela","","143403","1608955576"
"Nawābganj","Nawabganj","24.6000","88.2667","Bangladesh","BD","BGD","Rājshāhi","","142361","1050253103"
"Rewāri","Rewari","28.1800","76.6200","India","IN","IND","Haryāna","","143021","1356557800"
"Birkenhead","Birkenhead","53.3930","-3.0140","United Kingdom","GB","GBR","Wirral","","142968","1826080662"
"Altay","Altay","47.8666","88.1166","China","CN","CHN","Xinjiang","minor","142000","1156855629"
"Ashikaga","Ashikaga","36.3402","139.4497","Japan","JP","JPN","Tochigi","","142882","1392003201"
"Ghaznī","Ghazni","33.5492","68.4233","Afghanistan","AF","AFG","Ghaznī","admin","143379","1004167490"
"Yopal","Yopal","5.3500","-72.4100","Colombia","CO","COL","Casanare","admin","143619","1170295059"
"Bilbays","Bilbays","30.4167","31.5667","Egypt","EG","EGY","Ash Sharqīyah","","141285","1818863783"
"Jaú","Jau","-22.2958","-48.5578","Brazil","BR","BRA","São Paulo","","143283","1076312559"
"Huelva","Huelva","37.2500","-6.9500","Spain","ES","ESP","Andalusia","minor","142538","1724420824"
"Kumbakonam","Kumbakonam","10.9602","79.3845","India","IN","IND","Tamil Nādu","","140156","1356960436"
"Lévis","Levis","46.8000","-71.1833","Canada","CA","CAN","Quebec","","143414","1124958950"
"Bhīmavaram","Bhimavaram","16.5430","81.5230","India","IN","IND","Andhra Pradesh","","142184","1356195520"
"Jaraguá do Sul","Jaragua do Sul","-26.4833","-49.0667","Brazil","BR","BRA","Santa Catarina","","143123","1076588128"
"Jinjiang","Jinjiang","19.7386","110.0058","China","CN","CHN","Hainan","minor","142948","1156149547"
"Blackpool","Blackpool","53.8142","-3.0503","United Kingdom","GB","GBR","Blackpool","","139305","1826093175"
"Cizre","Cizre","37.3320","42.1870","Turkey","TR","TUR","Şırnak","minor","143124","1792431845"
"Paghmān","Paghman","34.5833","68.9500","Afghanistan","AF","AFG","Kābul","","143000","1004474181"
"Los Ángeles","Los Angeles","-37.4667","-72.3500","Chile","CL","CHL","Biobío","minor","143023","1152810305"
"Sannār","Sannar","13.5500","33.5833","Sudan","SD","SDN","Sennar","","143059","1729986512"
"Elbistan","Elbistan","38.2014","37.1883","Turkey","TR","TUR","Kahramanmaraş","minor","143000","1792888538"
"Boca Chica","Boca Chica","18.4539","-69.6064","Dominican Republic","DO","DOM","Ozama","","142019","1214362164"
"Telford","Telford","52.6766","-2.4469","United Kingdom","GB","GBR","Telford and Wrekin","","142723","1826968934"
"Fullerton","Fullerton","33.8841","-117.9279","United States","US","USA","California","","142964","1840020576"
"Kamālshahr","Kamalshahr","35.8653","50.8814","Iran","IR","IRN","Alborz","","141669","1364102350"
"Preston","Preston","53.7590","-2.6990","United Kingdom","GB","GBR","Lancashire","","141801","1826018478"
"Lichinga","Lichinga","-13.3167","35.2333","Mozambique","MZ","MOZ","Niassa","admin","142253","1508041628"
"Brest","Brest","48.3900","-4.4900","France","FR","FRA","Bretagne","minor","139926","1250882453"
"Pueblo","Pueblo","38.2701","-104.6114","United States","US","USA","Colorado","","142727","1840020255"
"Teluknaga","Teluknaga","-6.0989","106.6381","Indonesia","ID","IDN","Jawa Barat","","138330","1360011332"
"Moundou","Moundou","8.5667","16.0833","Chad","TD","TCD","Logone Occidental","admin","142462","1148369114"
"Tetovo","Tetovo","42.0103","20.9714","Macedonia","MK","MKD","Tetovo","admin","142030","1807020286"
"Nizip","Nizip","37.0100","37.7972","Turkey","TR","TUR","Gaziantep","minor","142389","1792748330"
"Lausanne","Lausanne","46.5198","6.6335","Switzerland","CH","CHE","Vaud","admin","139111","1756055099"
"Bahawalnagar","Bahawalnagar","29.9928","73.2536","Pakistan","PK","PAK","Punjab","minor","141935","1586152082"
"Maykop","Maykop","44.6000","40.0833","Russia","RU","RUS","Adygeya","admin","141970","1643584159"
"Fukayachō","Fukayacho","36.1975","139.2815","Japan","JP","JPN","Saitama","","141336","1392571441"
"Athens","Athens","33.9508","-83.3689","United States","US","USA","Georgia","","142277","1840029463"
"Santa Maria","Santa Maria","34.9333","-120.4431","United States","US","USA","California","","142230","1840021830"
"Dehri","Dehri","24.9100","84.1800","India","IN","IND","Bihār","","137231","1356452600"
"Gweru","Gweru","-19.4614","29.8022","Zimbabwe","ZW","ZWE","Midlands","admin","141260","1716621481"
"Tabaco","Tabaco","13.3500","123.7333","Philippines","PH","PHL","Albay","","140961","1608252596"
"Kelowna","Kelowna","49.8881","-119.4956","Canada","CA","CAN","British Columbia","","142146","1124080626"
"Kisaran","Kisaran","2.9833","99.6125","Indonesia","ID","IDN","Sumatera Utara","minor","141915","1360782288"
"Namacunde","Namacunde","-17.3000","15.8500","Angola","AO","AGO","Cunene","","142047","1024843393"
"Mandsaur","Mandsaur","24.0300","75.0800","India","IN","IND","Madhya Pradesh","","141667","1356467529"
"Boulogne-Billancourt","Boulogne-Billancourt","48.8352","2.2409","France","FR","FRA","Île-de-France","minor","121583","1250978859"
"Rybnik","Rybnik","50.0833","18.5500","Poland","PL","POL","Śląskie","minor","141000","1616092098"
"Surprise","Surprise","33.6815","-112.4515","United States","US","USA","Arizona","","141875","1840021941"
"Nong’an","Nong'an","44.4190","125.1702","China","CN","CHN","Jilin","minor","141482","1156278589"
"Inezgane","Inezgane","30.3658","-9.5381","Morocco","MA","MAR","Souss-Massa","","130333","1504786674"
"Rize","Rize","41.0247","40.5222","Turkey","TR","TUR","Rize","admin","141143","1792024731"
"Rio das Ostras","Rio das Ostras","-22.5269","-41.9450","Brazil","BR","BRA","Rio de Janeiro","","141117","1076148122"
"Vlorë","Vlore","40.4667","19.4833","Albania","AL","ALB","Vlorë","admin","130827","1008276050"
"Bondoukou","Bondoukou","8.0333","-2.8000","Côte d'Ivoire","CI","CIV","Zanzan","admin","141568","1384649653"
"Fujita","Fujita","34.8674","138.2577","Japan","JP","JPN","Shizuoka","","140947","1392223322"
"Jahrom","Jahrom","28.5000","53.5606","Iran","IR","IRN","Fārs","minor","141634","1364271412"
"Charleston","Charleston","38.3484","-81.6322","United States","US","USA","West Virginia","admin","141640","1840006196"
"Jyväskylä","Jyvaskyla","62.2417","25.7417","Finland","FI","FIN","Keski-Suomi","admin","141441","1246938345"
"Lleida","Lleida","41.6167","0.6333","Spain","ES","ESP","Catalonia","minor","140797","1724114678"
"Middlesbrough","Middlesbrough","54.5767","-1.2355","United Kingdom","GB","GBR","Redcar and Cleveland","","140545","1826407067"
"Khuzdar","Khuzdar","27.8000","66.6167","Pakistan","PK","PAK","Balochistan","","141395","1586530458"
"Tepatitlán de Morelos","Tepatitlan de Morelos","20.8170","-102.7330","Mexico","MX","MEX","Jalisco","minor","141322","1484777621"
"Las Cruces","Las Cruces","32.3265","-106.7893","United States","US","USA","New Mexico","","141399","1840020683"
"Abbotsford","Abbotsford","49.0500","-122.3167","Canada","CA","CAN","British Columbia","","141397","1124808029"
"Batu Pahat","Batu Pahat","1.8500","102.9300","Malaysia","MY","MYS","Johor","","140000","1458310769"
"Krugersdorp","Krugersdorp","-26.1000","27.7667","South Africa","ZA","ZAF","Gauteng","","140643","1710772829"
"Porac","Porac","15.0719","120.5419","Philippines","PH","PHL","Pampanga","","140751","1608946840"
"Ozamiz City","Ozamiz City","8.1500","123.8500","Philippines","PH","PHL","Misamis Occidental","","140334","1608100412"
"Pécs","Pecs","46.0708","18.2331","Hungary","HU","HUN","Baranya","admin","140237","1348611992"
"Las Margaritas","Las Margaritas","16.3667","-91.7333","Mexico","MX","MEX","Chiapas","minor","141027","1484007496"
"Tours","Tours","47.3936","0.6892","France","FR","FRA","Centre-Val de Loire","minor","137087","1250635972"
"Kovrov","Kovrov","56.3683","41.3108","Russia","RU","RUS","Vladimirskaya Oblast’","","138552","1643110693"
"Shizhaobi","Shizhaobi","23.9210","115.7774","China","CN","CHN","Guangdong","","140493","1156898106"
"Kokubunji","Kokubunji","35.7109","139.4622","Japan","JP","JPN","Tōkyō","","129619","1392193917"
"Tottenham","Tottenham","51.5975","-0.0681","United Kingdom","GB","GBR","Haringey","","129237","1826538674"
"Mejicanos","Mejicanos","13.7333","-89.2167","El Salvador","SV","SLV","San Salvador","","140751","1222243504"
"Ebina","Ebina","35.4464","139.3908","Japan","JP","JPN","Kanagawa","","135557","1392003085"
"Paranaguá","Paranagua","-25.5208","-48.5094","Brazil","BR","BRA","Paraná","","140469","1076265266"
"Iguala de la Independencia","Iguala de la Independencia","18.3450","-99.5383","Mexico","MX","MEX","Guerrero","minor","140363","1484280781"
"Metairie","Metairie","29.9977","-90.1780","United States","US","USA","Louisiana","","140590","1840013994"
"Thornton","Thornton","39.9197","-104.9438","United States","US","USA","Colorado","","140538","1840021422"
"Masaya","Masaya","11.9764","-86.0875","Nicaragua","NI","NIC","Masaya","admin","139582","1558765329"
"Médéa","Medea","36.2675","2.7500","Algeria","DZ","DZA","Médéa","admin","138355","1012758282"
"Yaritagua","Yaritagua","10.0753","-69.1283","Venezuela","VE","VEN","Yaracuy","minor","140256","1862905123"
"Xiangcheng","Xiangcheng","25.4775","100.5555","China","CN","CHN","Yunnan","minor","140010","1156812271"
"Fusagasugá","Fusagasuga","4.3452","-74.3618","Colombia","CO","COL","Cundinamarca","minor","139805","1170322047"
"Olathe","Olathe","38.8833","-94.8200","United States","US","USA","Kansas","","140339","1840003833"
"Nāḩiyat Ghammās","Nahiyat Ghammas","31.7431","44.6194","Iraq","IQ","IRQ","Al Qādisīyah","","140000","1368372842"
"La Romana","La Romana","18.4300","-68.9700","Dominican Republic","DO","DOM","Yuma","admin","139671","1214760518"
"Giá Rai","Gia Rai","9.2500","105.4170","Vietnam","VN","VNM","Bạc Liêu","","139748","1704175422"
"Tanay","Tanay","14.4972","121.2864","Philippines","PH","PHL","Rizal","","139420","1608260734"
"Medina Estates","Medina Estates","5.6833","-0.1667","Ghana","GH","GHA","Greater Accra","","137162","1288850745"
"Ituzaingó","Ituzaingo","-34.6667","-58.6667","Argentina","AR","ARG","Buenos Aires","minor","135275","1032997137"
"Torbat-e Ḩeydarīyeh","Torbat-e Heydariyeh","35.2739","59.2194","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","140019","1364398113"
"Quảng Yên","Quang Yen","20.9170","106.8330","Vietnam","VN","VNM","Quảng Ninh","minor","139596","1704642598"
"Momostenango","Momostenango","15.0444","-91.4083","Guatemala","GT","GTM","Totonicapán","minor","139552","1320482609"
"Dawei","Dawei","14.0833","98.2000","Myanmar","MM","MMR","Tanintharyi","admin","139900","1104373474"
"Kombolcha","Kombolcha","11.0867","39.7367","Ethiopia","ET","ETH","Āmara","","125654","1231476399"
"Kuwana","Kuwana","35.0667","136.6833","Japan","JP","JPN","Mie","","138798","1392018722"
"Subang","Subang","-6.5714","107.7614","Indonesia","ID","IDN","Jawa Barat","minor","139046","1360943203"
"Atbara","Atbara","17.6972","33.9783","Sudan","SD","SDN","River Nile","","139768","1729042522"
"Norrköping","Norrkoping","58.6000","16.2000","Sweden","SE","SWE","Östergötland","minor","137326","1752803583"
"Carolina","Carolina","18.4054","-65.9792","Puerto Rico","PR","PRI","Puerto Rico","","139735","1630035637"
"Poblacion","Poblacion","14.3854","121.0290","Philippines","PH","PHL","Muntinlupa","","120115","1608197194"
"Pakpattan","Pakpattan","30.3500","73.4000","Pakistan","PK","PAK","Punjab","minor","139525","1586409848"
"Chicacole","Chicacole","18.3000","83.9000","India","IN","IND","Andhra Pradesh","","137944","1356141471"
"Jicheon","Jicheon","36.1333","128.1000","South Korea","KR","KOR","Gyeongbuk","","139477","1410269252"
"Botucatu","Botucatu","-22.8858","-48.4450","Brazil","BR","BRA","São Paulo","","139483","1076113865"
"Gainesville","Gainesville","34.2902","-83.8294","United States","US","USA","Georgia","","139507","1840013612"
"Minō","Mino","34.8269","135.4704","Japan","JP","JPN","Ōsaka","","136976","1392003271"
"Shizuishan","Shizuishan","39.2333","106.7690","China","CN","CHN","Ningxia","","136570","1156361664"
"La Trinidad","La Trinidad","16.4621","120.5877","Philippines","PH","PHL","Benguet","admin","137404","1608000107"
"West Valley City","West Valley City","40.6886","-112.0123","United States","US","USA","Utah","","139361","1840021389"
"Koga","Koga","36.1782","139.7554","Japan","JP","JPN","Ibaraki","","138237","1392720735"
"Sale","Sale","53.4240","-2.3220","United Kingdom","GB","GBR","Trafford","","134022","1826016049"
"Coquitlam","Coquitlam","49.2839","-122.7919","Canada","CA","CAN","British Columbia","","139284","1124000500"
"Orange","Orange","33.8038","-117.8218","United States","US","USA","California","","139195","1840020582"
"San José del Cabo","San Jose del Cabo","23.0614","-109.7081","Mexico","MX","MEX","Baja California Sur","minor","136285","1484157742"
"Jacmel","Jacmel","18.2353","-72.5367","Haiti","HT","HTI","Sud-Est","admin","137966","1332314393"
"Ishizaki","Ishizaki","38.4176","141.3027","Japan","JP","JPN","Miyagi","","138856","1392384377"
"Al Khmissat","Al Khmissat","33.8167","-6.0667","Morocco","MA","MAR","Rabat-Salé-Kénitra","","131542","1504079907"
"Ingolstadt","Ingolstadt","48.7631","11.4250","Germany","DE","DEU","Bavaria","minor","138016","1276803150"
"Çarşamba","Carsamba","41.1992","36.7275","Turkey","TR","TUR","Samsun","minor","138840","1792437640"
"Renca","Renca","-33.4000","-70.7167","Chile","CL","CHL","Región Metropolitana","","133518","1152882154"
"Warren","Warren","42.4934","-83.0270","United States","US","USA","Michigan","","138996","1840002418"
"Bat Yam","Bat Yam","32.0167","34.75","Israel","IL","ISR","Tel Aviv","","128800","1376837517"
"Candelaria","Candelaria","13.9311","121.4233","Philippines","PH","PHL","Quezon","minor","137881","1608579689"
"Tsuchiura","Tsuchiura","36.0667","140.2000","Japan","JP","JPN","Ibaraki","","137825","1392962266"
"Pīrānshahr","Piranshahr","36.6956","45.1467","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","138864","1364699746"
"Umtata","Umtata","-31.5800","28.7900","South Africa","ZA","ZAF","Eastern Cape","","137772","1710651314"
"Pasadena","Pasadena","34.1597","-118.1390","United States","US","USA","California","","138771","1840020505"
"Dipolog","Dipolog","8.5872","123.3408","Philippines","PH","PHL","Zamboanga del Norte","admin","138141","1608664300"
"Sancti Spíritus","Sancti Spiritus","21.9339","-79.4439","Cuba","CU","CUB","Sancti Spíritus","admin","138504","1192600260"
"Bukoba","Bukoba","-1.3333","31.8167","Tanzania","TZ","TZA","Kagera","admin","128796","1834433264"
"Koganei","Koganei","35.6995","139.5030","Japan","JP","JPN","Tōkyō","","127226","1392003171"
"Tultepec","Tultepec","19.6850","-99.1281","Mexico","MX","MEX","México","minor","131567","1484818856"
"Dos Hermanas","Dos Hermanas","37.2836","-5.9222","Spain","ES","ESP","Andalusia","","137561","1724272711"
"Jolo","Jolo","6.0000","121.0000","Philippines","PH","PHL","Sulu","admin","137266","1608685440"
"Montero","Montero","-17.3422","-63.2558","Bolivia","BO","BOL","Santa Cruz","","137931","1068836323"
"Boca del Rio","Boca del Rio","19.1056","-96.1078","Mexico","MX","MEX","Veracruz","minor","138058","1484658980"
"Dabou","Dabou","5.3167","-4.3833","Côte d'Ivoire","CI","CIV","Lagunes","admin","138083","1384274793"
"Phủ Lý","Phu Ly","20.5411","105.9139","Vietnam","VN","VNM","Hà Nam","admin","136654","1704841896"
"Zama","Zama","35.4833","139.4000","Japan","JP","JPN","Kanagawa","","130753","1392313741"
"Brusque","Brusque","-27.0949","-48.9148","Brazil","BR","BRA","Santa Catarina","","137689","1076418225"
"Shūnan","Shunan","34.0500","131.8000","Japan","JP","JPN","Yamaguchi","","137899","1392003434"
"Dumaguete City","Dumaguete City","9.3103","123.3081","Philippines","PH","PHL","Negros Oriental","admin","134103","1608682936"
"Mojokerto","Mojokerto","-7.4722","112.4336","Indonesia","ID","IDN","Jawa Timur","","130196","1360053101"
"Darwin","Darwin","-12.4381","130.8411","Australia","AU","AUS","Northern Territory","admin","136828","1036497565"
"Silivri","Silivri","41.0736","28.2478","Turkey","TR","TUR","İstanbul","minor","137861","1792110769"
"Mandya","Mandya","12.5242","76.8958","India","IN","IND","Karnātaka","","137358","1356039212"
"Ch’ungmu","Ch'ungmu","34.8333","128.4167","South Korea","KR","KOR","Gyeongnam","","137208","1410257662"
"Tarragona","Tarragona","41.1187","1.2453","Spain","ES","ESP","Catalonia","minor","135436","1724296961"
"Birgañj","Birganj","27.0000","84.8667","Nepal","NP","NPL","","","135904","1524676691"
"Iğdır","Igdir","39.9208","44.0444","Turkey","TR","TUR","Iğdır","admin","137613","1792813007"
"Palhoça","Palhoca","-27.6444","-48.6678","Brazil","BR","BRA","Santa Catarina","","137334","1076000559"
"Lira","Lira","2.2472","32.9000","Uganda","UG","UGA","Lira","admin","135445","1800877735"
"Carcar","Carcar","10.1167","123.6333","Philippines","PH","PHL","Cebu","","136453","1608778175"
"Negage","Negage","-7.7667","15.2667","Angola","AO","AGO","Uíge","","137559","1024978551"
"Gunungsitoli","Gunungsitoli","1.2833","97.6167","Indonesia","ID","IDN","Sumatera Utara","minor","137104","1360175198"
"Beaumont","Beaumont","30.0849","-94.1451","United States","US","USA","Texas","","137549","1840019612"
"Yunxian Chengguanzhen","Yunxian Chengguanzhen","32.8082","110.8136","China","CN","CHN","Hubei","minor","133558","1156455844"
"Cunduacán","Cunduacan","18.0667","-93.1667","Mexico","MX","MEX","Tabasco","minor","137257","1484076708"
"Atibaia","Atibaia","-23.1172","-46.5506","Brazil","BR","BRA","São Paulo","","137187","1076042485"
"Rangkasbitung","Rangkasbitung","-6.3667","106.2500","Indonesia","ID","IDN","Banten","minor","137041","1360558850"
"Amiens","Amiens","49.8920","2.2990","France","FR","FRA","Hauts-de-France","minor","134706","1250794759"
"Bānkura","Bankura","23.2500","87.0667","India","IN","IND","West Bengal","","137386","1356582158"
"Kigoma","Kigoma","-4.8833","29.6333","Tanzania","TZ","TZA","Kigoma","admin","135234","1834583327"
"Vila Franca de Xira","Vila Franca de Xira","38.9500","-8.9833","Portugal","PT","PRT","Lisboa","minor","136886","1620859041"
"Quillacollo","Quillacollo","-17.4000","-66.2833","Bolivia","BO","BOL","Cochabamba","","137182","1068265899"
"Myebon","Myebon","20.0500","93.3667","Myanmar","MM","MMR","Rakhine State","","137193","1104625729"
"Garanhuns","Garanhuns","-8.8903","-36.4928","Brazil","BR","BRA","Pernambuco","","136949","1076673653"
"Minoo","Minoo","34.8333","135.4667","Japan","JP","JPN","Ōsaka","","134435","1392496071"
"Bima","Bima","-8.4600","118.7267","Indonesia","ID","IDN","Nusa Tenggara Barat","","136492","1360521866"
"Kisarazu","Kisarazu","35.3760","139.9168","Japan","JP","JPN","Chiba","","136118","1392821453"
"Burlington","Burlington","36.0760","-79.4685","United States","US","USA","North Carolina","","137096","1840013362"
"Livingstone","Livingstone","-17.8500","25.8667","Zambia","ZM","ZMB","Southern","","136897","1894634581"
"Curicó","Curico","-34.9833","-71.2333","Chile","CL","CHL","Maule","minor","136954","1152564798"
"Porto Amboim","Porto Amboim","-10.7183","13.7500","Angola","AO","AGO","Cuanza-Sul","","137000","1024818756"
"Parla","Parla","40.2372","-3.7742","Spain","ES","ESP","Madrid","","131689","1724153608"
"Nasugbu","Nasugbu","14.0667","120.6333","Philippines","PH","PHL","Batangas","","136524","1608413633"
"Siwān","Siwan","26.2200","84.3600","India","IN","IND","Bihār","","135066","1356238764"
"Long Bình","Long Binh","10.9458","106.8775","Vietnam","VN","VNM","Đồng Nai","","133206","1704097559"
"Igboho","Igboho","8.8333","3.7500","Nigeria","NG","NGA","Oyo","minor","136772","1566640824"
"Gingoog","Gingoog","8.8167","125.1000","Philippines","PH","PHL","Misamis Oriental","","136698","1608630986"
"Maia","Maia","41.2333","-8.6167","Portugal","PT","PRT","Porto","minor","135306","1620000072"
"Uppsala","Uppsala","59.8601","17.6400","Sweden","SE","SWE","Stockholm","","133117","1752330831"
"Yaizu","Yaizu","34.8669","138.3247","Japan","JP","JPN","Shizuoka","","136028","1392569740"
"Khargone","Khargone","21.8229","75.6139","India","IN","IND","Madhya Pradesh","","136551","1356728712"
"Hampton","Hampton","37.0551","-76.3630","United States","US","USA","Virginia","","136748","1840003866"
"Marīvān","Marivan","35.5269","46.1764","Iran","IR","IRN","Kordestān","minor","136654","1364680490"
"Columbia","Columbia","38.9472","-92.3268","United States","US","USA","Missouri","","136736","1840007391"
"Huehuetenango","Huehuetenango","15.3147","-91.4761","Guatemala","GT","GTM","Huehuetenango","admin","136639","1320742269"
"Inazawa","Inazawa","35.2647","136.7969","Japan","JP","JPN","Aichi","","134938","1392019493"
"Stara Zagora","Stara Zagora","42.4333","25.6500","Bulgaria","BG","BGR","Stara Zagora","admin","136475","1100903924"
"Quimbele","Quimbele","-6.5167","16.2167","Angola","AO","AGO","Uíge","","136496","1024357792"
"Chech’ŏn","Chech'on","37.1333","128.2167","South Korea","KR","KOR","Chungbuk","","136350","1410470747"
"Norzagaray","Norzagaray","14.9167","121.0500","Philippines","PH","PHL","Bulacan","","136064","1608520209"
"Port-Gentil","Port-Gentil","-0.7167","8.7833","Gabon","GA","GAB","Ogooué-Maritime","admin","136462","1266383028"
"Tiraspol","Tiraspol","46.8403","29.6433","Moldova","MD","MDA","Stînga Nistrului","admin","133807","1498104958"
"Pageralam","Pageralam","-4.0217","103.2522","Indonesia","ID","IDN","Sumatera Selatan","minor","136244","1360027360"
"Debre Mark’os","Debre Mark'os","10.3333","37.7167","Ethiopia","ET","ETH","Āmara","","133810","1231943444"
"Tizi Ouzou","Tizi Ouzou","36.7169","4.0497","Algeria","DZ","DZA","Tizi Ouzou","admin","135088","1012126997"
"Tây Ninh","Tay Ninh","11.3678","106.1189","Vietnam","VN","VNM","Tây Ninh","admin","135254","1704250951"
"Giresun","Giresun","40.9153","38.3894","Turkey","TR","TUR","Giresun","admin","135920","1792486859"
"Maijdi","Maijdi","22.8333","91.1000","Bangladesh","BD","BGD","Chattogram","","130842","1050060514"
"Ksar El Kebir","Ksar El Kebir","35.0090","-5.9000","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","126617","1504230428"
"Torrejón de Ardoz","Torrejon de Ardoz","40.4614","-3.4978","Spain","ES","ESP","Madrid","","134733","1724711203"
"Termiz","Termiz","37.2167","67.2833","Uzbekistan","UZ","UZB","Surxondaryo","admin","136200","1860617243"
"Zinacantepec","Zinacantepec","19.2833","-99.7333","Mexico","MX","MEX","México","minor","136167","1484976252"
"Mabacun","Mabacun","24.6807","113.5936","China","CN","CHN","Guangdong","","134396","1156313617"
"Floridablanca","Floridablanca","14.9333","120.5000","Philippines","PH","PHL","Pampanga","","135542","1608344732"
"Salerno","Salerno","40.6806","14.7597","Italy","IT","ITA","Campania","minor","133970","1380863448"
"Rionegro","Rionegro","6.1535","-75.3743","Colombia","CO","COL","Antioquia","minor","135465","1170000361"
"Ağcabədi","Agcabadi","40.0528","47.4614","Azerbaijan","AZ","AZE","Ağcabədi","admin","136000","1031860893"
"Blitar","Blitar","-8.1000","112.1500","Indonesia","ID","IDN","Jawa Timur","","132018","1360011677"
"Târgu-Mureş","Targu-Mures","46.5456","24.5625","Romania","RO","ROU","Mureş","admin","134290","1642875714"
"Yuma","Yuma","32.5995","-114.5491","United States","US","USA","Arizona","","135933","1840021998"
"Brighton","Brighton","50.8208","-0.1375","United Kingdom","GB","GBR","Brighton and Hove","","134293","1826609057"
"Toowoomba","Toowoomba","-27.5667","151.9500","Australia","AU","AUS","Queensland","","135631","1036765315"
"Tobruk","Tobruk","32.0761","23.9614","Libya","LY","LBY","Al Buţnān","admin","135832","1434068493"
"Srīpur","Sripur","24.2011","90.4843","Bangladesh","BD","BGD","Dhaka","","132948","1050643433"
"Elizabeth","Elizabeth","40.6658","-74.1913","United States","US","USA","New Jersey","","135772","1840003613"
"Raigarh","Raigarh","21.8000","83.3000","India","IN","IND","Chhattīsgarh","","135363","1356458234"
"Sukrah","Sukrah","36.8833","10.2500","Tunisia","TN","TUN","L’Ariana","","129693","1788614141"
"Pyay","Pyay","18.8167","95.2167","Myanmar","MM","MMR","Bago","","135308","1104978348"
"Tabarre","Tabarre","18.5833","-72.2667","Haiti","HT","HTI","Ouest","","130283","1332354236"
"Ruda Śląska","Ruda Slaska","50.2628","18.8536","Poland","PL","POL","Śląskie","minor","133793","1616148566"
"Shuangcheng","Shuangcheng","45.3503","126.2800","China","CN","CHN","Heilongjiang","minor","130710","1156257611"
"Angono","Angono","14.5234","121.1536","Philippines","PH","PHL","Rizal","","130494","1608373503"
"Cartago","Cartago","4.7000","-75.9167","Colombia","CO","COL","Valle del Cauca","minor","134972","1170225267"
"Yelahanka","Yelahanka","13.1007","77.5963","India","IN","IND","Karnātaka","","119891","1356308936"
"Paco","Paco","14.5830","120.9830","Philippines","PH","PHL","Manila","","79839","1608451394"
"May Pen","May Pen","17.9650","-77.2450","Jamaica","JM","JAM","Clarendon","admin","135142","1388671677"
"Racine","Racine","42.7274","-87.8135","United States","US","USA","Wisconsin","","135352","1840002493"
"Zafarwal","Zafarwal","32.3463","74.8999","Pakistan","PK","PAK","Punjab","","134275","1586796643"
"Greeley","Greeley","40.4152","-104.7706","United States","US","USA","Colorado","","135270","1840020149"
"Andīmeshk","Andimeshk","32.4600","48.3592","Iran","IR","IRN","Khūzestān","minor","135116","1364453657"
"Ipetumodu","Ipetumodu","7.5070","4.4447","Nigeria","NG","NGA","Osun","minor","135000","1566155015"
"Conchalí","Conchali","-33.3833","-70.6750","Chile","CL","CHL","Región Metropolitana","","133256","1152123561"
"Agboville","Agboville","5.9333","-4.2167","Côte d'Ivoire","CI","CIV","Lagunes","minor","135082","1384823872"
"Djakotomé","Djakotome","6.9000","1.7167","Benin","BJ","BEN","Couffo","","134704","1204961382"
"Santa Rita","Santa Rita","-7.1139","-34.9778","Brazil","BR","BRA","Paraíba","","134940","1076661082"
"Silopi","Silopi","37.2486","42.4703","Turkey","TR","TUR","Şırnak","minor","134754","1792772640"
"Daraga","Daraga","13.1619","123.6939","Philippines","PH","PHL","Albay","","133893","1608571209"
"Shahreẕā","Shahreza","32.0089","51.8667","Iran","IR","IRN","Eşfahān","minor","134952","1364242660"
"Milagro","Milagro","-2.1347","-79.5872","Ecuador","EC","ECU","Guayas","","133508","1218568564"
"Nakhon Ratchasima","Nakhon Ratchasima","14.9750","102.1000","Thailand","TH","THA","Nakhon Ratchasima","admin","134440","1764752197"
"Zābol","Zabol","31.0286","61.5011","Iran","IR","IRN","Sīstān va Balūchestān","minor","134950","1364485195"
"El Oued","El Oued","33.3683","6.8675","Algeria","DZ","DZA","El Oued","admin","134699","1012148937"
"Kent","Kent","47.3887","-122.2128","United States","US","USA","Washington","","134890","1840018424"
"Ashqelon","Ashqelon","31.6667","34.5667","Israel","IL","ISR","Southern","","134454","1376324059"
"Mataró","Mataro","41.5333","2.4500","Spain","ES","ESP","Catalonia","","129120","1724901780"
"Ba Đồn","Ba Don","17.7547","106.4231","Vietnam","VN","VNM","Quảng Bình","minor","134000","1704105160"
"Stamford","Stamford","41.1039","-73.5585","United States","US","USA","Connecticut","","134820","1840004841"
"Odessa","Odessa","31.8801","-102.3448","United States","US","USA","Texas","","134793","1840020794"
"Teófilo Otoni","Teofilo Otoni","-17.8578","-41.5050","Brazil","BR","BRA","Minas Gerais","","134745","1076465745"
"Hagonoy","Hagonoy","14.8333","120.7333","Philippines","PH","PHL","Bulacan","","133448","1608693751"
"Laghouat","Laghouat","33.8000","2.8650","Algeria","DZ","DZA","Laghouat","admin","134372","1012233848"
"Mörön","Moron","49.6356","100.1556","Mongolia","MN","MNG","Hövsgöl","admin","134530","1496482400"
"Apatzingan de la Constitucion","Apatzingan de la Constitucion","19.0886","-102.3508","Mexico","MX","MEX","Michoacán","","128250","1484693242"
"Aaley","Aaley","33.8000","35.6000","Lebanon","LB","LBN","Mont-Liban","minor","130000","1422055581"
"Famalicão","Famalicao","41.4167","-8.5167","Portugal","PT","PRT","Braga","minor","133832","1620202480"
"Naga","Naga","10.2167","123.7500","Philippines","PH","PHL","Cebu","","133184","1608928299"
"Trois-Rivières","Trois-Rivieres","46.3500","-72.5500","Canada","CA","CAN","Quebec","","134413","1124407487"
"Bloomington","Bloomington","40.4757","-88.9703","United States","US","USA","Illinois","","134357","1840007207"
"Veszprém","Veszprem","47.0930","17.9138","Hungary","HU","HUN","Veszprém","admin","133880","1348279762"
"Zhangmu Touwei","Zhangmu Touwei","22.9078","114.0603","China","CN","CHN","Guangdong","","132816","1156040158"
"Miramar","Miramar","25.9773","-80.3351","United States","US","USA","Florida","","134306","1840015137"
"Londuimbali","Londuimbali","-12.2186","15.2850","Angola","AO","AGO","Huambo","","134254","1024280825"
"Reẖovot","Rehovot","31.8981","34.8081","Israel","IL","ISR","Central","","132671","1376684821"
"Navoiy","Navoiy","40.0844","65.3792","Uzbekistan","UZ","UZB","Navoiy","admin","134100","1860291734"
"Grand Junction","Grand Junction","39.0877","-108.5673","United States","US","USA","Colorado","","134074","1840020230"
"Sterling Heights","Sterling Heights","42.5809","-83.0305","United States","US","USA","Michigan","","134062","1840003094"
"Batu Gajah","Batu Gajah","4.4667","101.0500","Malaysia","MY","MYS","Perak","","133422","1458671961"
"Tando Allahyar","Tando Allahyar","25.4667","68.7167","Pakistan","PK","PAK","Sindh","minor","133487","1586001015"
"Luuq","Luuq","3.6981","42.5972","Somalia","SO","SOM","Gedo","","134000","1706505283"
"Idfū","Idfu","24.9778","32.8733","Egypt","EG","EGY","Aswān","","133772","1818755693"
"Ségou","Segou","13.4500","-6.2667","Mali","ML","MLI","Ségou","admin","133501","1466684950"
"Isahaya","Isahaya","32.8500","130.0667","Japan","JP","JPN","Nagasaki","","133512","1392443480"
"Chikmagalūr","Chikmagalur","13.3167","75.7833","India","IN","IND","Karnātaka","","133651","1356137222"
"Innsbruck","Innsbruck","47.2683","11.3933","Austria","AT","AUT","Tirol","admin","132493","1040038924"
"Ōme","Ome","35.7880","139.2758","Japan","JP","JPN","Tōkyō","","132436","1392575345"
"Coral Springs","Coral Springs","26.2702","-80.2591","United States","US","USA","Florida","","133693","1840015134"
"Marituba","Marituba","-10.2833","-36.4000","Brazil","BR","BRA","Alagoas","","133685","1076858443"
"Matagalpa","Matagalpa","12.9286","-85.9178","Nicaragua","NI","NIC","Matagalpa","admin","133416","1558728443"
"Jijel","Jijel","36.8206","5.7667","Algeria","DZ","DZA","Jijel","admin","131513","1012782547"
"Jaramānā","Jaramana","33.4833","36.3500","Syria","SY","SYR","Rīf Dimashq","minor","114363","1760709370"
"Jandira","Jandira","-23.5278","-46.9028","Brazil","BR","BRA","São Paulo","","126356","1076873964"
"Ferfer","Ferfer","5.0833","45.0833","Ethiopia","ET","ETH","Sumalē","","133521","1231764818"
"Gondiā","Gondia","21.4500","80.2000","India","IN","IND","Mahārāshtra","","132821","1356105759"
"Thandwe","Thandwe","18.4667","94.3667","Myanmar","MM","MMR","Rakhine State","","133484","1104527465"
"Hassan","Hassan","13.0050","76.1028","India","IN","IND","Karnātaka","","133436","1356007455"
"Pitalito","Pitalito","1.8989","-76.0419","Colombia","CO","COL","Huila","minor","133205","1170820158"
"Bibémi","Bibemi","9.3167","13.8833","Cameroon","CM","CMR","Nord","","133191","1120285705"
"Abiko","Abiko","35.8667","140.0333","Japan","JP","JPN","Chiba","","130290","1392000342"
"Talavera","Talavera","15.5839","120.9189","Philippines","PH","PHL","Nueva Ecija","","132338","1608474974"
"Khorramshahr","Khorramshahr","30.4397","48.1664","Iran","IR","IRN","Khūzestān","minor","133097","1364111149"
"Limoges","Limoges","45.8353","1.2625","France","FR","FRA","Nouvelle-Aquitaine","minor","131479","1250736774"
"Crato","Crato","-7.2339","-39.4089","Brazil","BR","BRA","Ceará","","133031","1076894967"
"Ouargla","Ouargla","31.9500","5.3167","Algeria","DZ","DZA","Ouargla","admin","133024","1012425687"
"Ar Rass","Ar Rass","25.8667","43.5000","Saudi Arabia","SA","SAU","Al Qaşīm","","133000","1682666279"
"Sinop","Sinop","-11.8481","-55.6492","Brazil","BR","BRA","Mato Grosso","","132934","1076876295"
"San Carlos","San Carlos","10.4929","123.4095","Philippines","PH","PHL","Negros Occidental","","132650","1608048026"
"Kipushi","Kipushi","-11.7625","27.2500","Congo (Kinshasa)","CD","COD","Haut-Katanga","","132861","1180810333"
"Lincoln","Lincoln","53.2283","-0.5389","United Kingdom","GB","GBR","Lincolnshire","","130200","1826312785"
"Palwal","Palwal","28.1430","77.3290","India","IN","IND","Haryāna","","131926","1356128481"
"Annecy","Annecy","45.9160","6.1330","France","FR","FRA","Auvergne-Rhône-Alpes","minor","130721","1250551848"
"Mogaung","Mogaung","25.3014","96.9417","Myanmar","MM","MMR","Kachin State","","132608","1104504021"
"Ödemiş","Odemis","38.2311","27.9719","Turkey","TR","TUR","İzmir","minor","132511","1792140925"
"Kamëz","Kamez","41.3833","19.7667","Albania","AL","ALB","Tiranë","","126777","1008148968"
"Zagnanado","Zagnanado","7.2667","2.3500","Benin","BJ","BEN","Zou","","132401","1204320194"
"Cametá","Cameta","-2.2439","-49.4958","Brazil","BR","BRA","Pará","","132515","1076010069"
"Cubatão","Cubatao","-23.8953","-46.4256","Brazil","BR","BRA","São Paulo","","131626","1076384228"
"Ibarra","Ibarra","0.3627","-78.1307","Ecuador","EC","ECU","Imbabura","admin","131856","1218479054"
"San Luis","San Luis","16.2000","-89.4400","Guatemala","GT","GTM","Petén","minor","132470","1320642245"
"Palenque","Palenque","17.4333","-91.8000","Mexico","MX","MEX","Chiapas","minor","132265","1484011438"
"Ferrara","Ferrara","44.8333","11.6167","Italy","IT","ITA","Emilia-Romagna","minor","132278","1380682411"
"San Juan","San Juan","18.8100","-71.2300","Dominican Republic","DO","DOM","El Valle","admin","132177","1214608846"
"Āwarē","Aware","8.2667","44.1500","Ethiopia","ET","ETH","Sumalē","","132149","1231934049"
"Karabük","Karabuk","41.1986","32.6264","Turkey","TR","TUR","Karabük","admin","131989","1792834766"
"Johnson City","Johnson City","36.3406","-82.3806","United States","US","USA","Tennessee","","131902","1840014462"
"Saidpur","Saidpur","25.7781","88.8975","Bangladesh","BD","BGD","Rangpur","","127104","1050464696"
"Chicomba","Chicomba","-14.1333","14.9167","Angola","AO","AGO","Huíla","","131807","1024892795"
"Pālghāt","Palghat","10.7792","76.6547","India","IN","IND","Kerala","","130955","1356115164"
"Guelph","Guelph","43.5500","-80.2500","Canada","CA","CAN","Ontario","","131794","1124968815"
"Buea","Buea","4.1667","9.2333","Cameroon","CM","CMR","South-West","admin","131325","1120497849"
"Los Guayos","Los Guayos","10.1833","-67.9333","Venezuela","VE","VEN","Carabobo","minor","130345","1862264170"
"Nanqiaotou","Nanqiaotou","22.7217","113.2926","China","CN","CHN","Guangdong","","130370","1156513218"
"Ji-Paraná","Ji-Parana","-10.8853","-61.9517","Brazil","BR","BRA","Rondônia","","131560","1076313540"
"Batang","Batang","-6.9081","109.7304","Indonesia","ID","IDN","Jawa Tengah","minor","127832","1360024582"
"Guagua","Guagua","14.9667","120.6333","Philippines","PH","PHL","Pampanga","","128893","1608453085"
"Spanish Town","Spanish Town","17.9959","-76.9551","Jamaica","JM","JAM","Saint Catherine","admin","131056","1388689196"
"Carrollton","Carrollton","32.9890","-96.8999","United States","US","USA","Texas","","131388","1840019436"
"Chittandikavundanūr","Chittandikavundanur","10.6931","76.7188","India","IN","IND","Tamil Nādu","","130736","1356162407"
"Surat Thani","Surat Thani","9.1397","99.3306","Thailand","TH","THA","Surat Thani","admin","130703","1764338230"
"Narita","Narita","35.7767","140.3183","Japan","JP","JPN","Chiba","","130689","1392216588"
"Apopa","Apopa","13.8000","-89.1833","El Salvador","SV","SLV","San Salvador","","131286","1222101845"
"Relizane","Relizane","35.7372","0.5558","Algeria","DZ","DZA","Relizane","admin","130094","1012939885"
"Fürth","Furth","49.4667","11.0000","Germany","DE","DEU","Bavaria","minor","129122","1276580165"
"Lào Cai","Lao Cai","22.4806","103.9750","Vietnam","VN","VNM","Lào Cai","admin","130671","1704290986"
"Antsiranana","Antsiranana","-12.3000","49.2833","Madagascar","MG","MDG","Antsiranana","admin","129320","1450106928"
"Lashio","Lashio","22.9333","97.7500","Myanmar","MM","MMR","Shan State","","131000","1104144745"
"Silay","Silay","10.8000","122.9667","Philippines","PH","PHL","Negros Occidental","","130478","1608362038"
"Parepare","Parepare","-4.0167","119.6236","Indonesia","ID","IDN","Sulawesi Selatan","","129542","1360021476"
"City of Isabela","City of Isabela","6.7000","121.9667","Philippines","PH","PHL","Basilan","admin","130379","1608621038"
"Thaton","Thaton","16.9333","97.3667","Myanmar","MM","MMR","Mon State","","130763","1104227154"
"Zwolle","Zwolle","52.5167","6.1000","Netherlands","NL","NLD","Overijssel","admin","129840","1528690076"
"Nueva Concepción","Nueva Concepcion","14.1997","-91.2997","Guatemala","GT","GTM","Escuintla","minor","130754","1320214238"
"Marand","Marand","38.4331","45.7686","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","130825","1364567639"
"Kānchrāpāra","Kanchrapara","22.9456","88.4332","India","IN","IND","West Bengal","","129576","1356022687"
"Quibdó","Quibdo","5.6922","-76.6581","Colombia","CO","COL","Chocó","admin","130825","1170186734"
"Girardot","Girardot","4.3050","-74.8017","Colombia","CO","COL","Cundinamarca","minor","129834","1170838212"
"Pouso Alegre","Pouso Alegre","-22.2281","-45.9336","Brazil","BR","BRA","Minas Gerais","","130615","1076082333"
"Tychy","Tychy","50.1236","18.9867","Poland","PL","POL","Śląskie","minor","129322","1616396493"
"Rustavi","Rustavi","41.5472","45.0111","Georgia","GE","GEO","Kvemo Kartli","admin","128680","1268562807"
"Bassila","Bassila","9.0167","1.6667","Benin","BJ","BEN","Donga","","130770","1204259496"
"Midland","Midland","32.0243","-102.1147","United States","US","USA","Texas","","130765","1840020792"
"Cholula de Rivadabia","Cholula de Rivadabia","19.0633","-98.3064","Mexico","MX","MEX","Puebla","","129032","1484237822"
"Trinidad","Trinidad","-14.8292","-64.9014","Bolivia","BO","BOL","El Beni","admin","130657","1068929509"
"Onomichi","Onomichi","34.4167","133.2000","Japan","JP","JPN","Hiroshima","","130143","1392886423"
"Kislovodsk","Kislovodsk","43.9167","42.7167","Russia","RU","RUS","Stavropol’skiy Kray","","128779","1643705192"
"Kozan","Kozan","37.4500","35.8000","Turkey","TR","TUR","Adana","minor","130495","1792567030"
"Udon Thani","Udon Thani","17.4167","102.7500","Thailand","TH","THA","Udon Thani","admin","130274","1764676047"
"Jīroft","Jiroft","28.6781","57.7406","Iran","IR","IRN","Kermān","minor","130429","1364826721"
"Batticaloa","Batticaloa","7.7167","81.7000","Sri Lanka","LK","LKA","Eastern","","129222","1144464659"
"Chās","Chas","23.6300","86.1700","India","IN","IND","Jhārkhand","","128331","1356317326"
"Yakima","Yakima","46.5923","-120.5496","United States","US","USA","Washington","","130440","1840021154"
"Bongaigaon","Bongaigaon","26.4769","90.5583","India","IN","IND","Assam","","129894","1356037877"
"Battambang","Battambang","13.1000","103.2000","Cambodia","KH","KHM","Battambang","admin","130000","1116106237"
"Vitória de Santo Antão","Vitoria de Santo Antao","-8.1264","-35.3075","Brazil","BR","BRA","Pernambuco","","129974","1076843224"
"Mukeriān","Mukerian","31.9500","75.6200","India","IN","IND","Punjab","","129841","1356427915"
"Malambo","Malambo","10.8500","-74.7500","Colombia","CO","COL","Atlántico","minor","129148","1170794204"
"Leiden","Leiden","52.1600","4.4900","Netherlands","NL","NLD","Zuid-Holland","minor","124899","1528966223"
"Huejutla de Reyes","Huejutla de Reyes","21.1333","-98.4167","Mexico","MX","MEX","Hidalgo","","129919","1484746079"
"Zhaozhou","Zhaozhou","37.7527","114.7775","China","CN","CHN","Hebei","","127545","1156843490"
"Lakewood","Lakewood","40.0763","-74.2031","United States","US","USA","New Jersey","","130221","1840081607"
"Ergani","Ergani","38.2692","39.7617","Turkey","TR","TUR","Diyarbakır","minor","130105","1792939849"
"Lucheng","Lucheng","30.0553","101.9648","China","CN","CHN","Sichuan","minor","130142","1156314517"
"Hanumāngarh","Hanumangarh","29.5833","74.3167","India","IN","IND","Rājasthān","","129654","1356133715"
"Çayırova","Cayirova","40.8265","29.3745","Turkey","TR","TUR","Kocaeli","minor","129655","1792546631"
"Saïda","Saida","34.8303","0.1517","Algeria","DZ","DZA","Saïda","admin","128413","1012285874"
"Taldyqorghan","Taldyqorghan","45.0167","78.3667","Kazakhstan","KZ","KAZ","","admin","129960","1398112507"
"Ñemby","Nemby","-25.3935","-57.5443","Paraguay","PY","PRY","Central","","126817","1600221007"
"Cambridge","Cambridge","43.3972","-80.3114","Canada","CA","CAN","Ontario","","129920","1124113576"
"Fengcheng","Fengcheng","37.4313","112.0270","China","CN","CHN","Shanxi","minor","128571","1156054333"
"Ponnagyun","Ponnagyun","20.3333","93.0167","Myanmar","MM","MMR","Rakhine State","","129753","1104261097"
"Mothīhāri","Mothihari","26.6500","84.9167","India","IN","IND","Bihār","","126158","1356540032"
"Serpukhov","Serpukhov","54.9167","37.4000","Russia","RU","RUS","Moskovskaya Oblast’","","125817","1643395657"
"Mīt Ghamr","Mit Ghamr","30.7167","31.2500","Egypt","EG","EGY","Ad Daqahlīyah","","121039","1818400389"
"Bellingham","Bellingham","48.7548","-122.4690","United States","US","USA","Washington","","129601","1840018346"
"Yuba City","Yuba City","39.1357","-121.6383","United States","US","USA","California","","129536","1840021463"
"Villa Alemana","Villa Alemana","-33.0422","-71.3733","Chile","CL","CHL","Valparaíso","","125140","1152839066"
"Ciénaga","Cienaga","11.0069","-74.2500","Colombia","CO","COL","Magdalena","minor","129414","1170825751"
"Táriba","Tariba","7.8167","-72.2167","Venezuela","VE","VEN","Táchira","minor","128590","1862780259"
"Kibaha","Kibaha","-6.7667","38.9167","Tanzania","TZ","TZA","Coast","admin","128488","1834065400"
"Chinguar","Chinguar","-12.5500","16.3333","Angola","AO","AGO","Bié","","129370","1024191137"
"Västerås","Vasteras","59.6161","16.5528","Sweden","SE","SWE","Västmanland","admin","128660","1752826955"
"Würzburg","Wurzburg","49.7833","9.9333","Germany","DE","DEU","Bavaria","minor","127880","1276233514"
"Mansa","Mansa","-11.2000","28.8833","Zambia","ZM","ZMB","Luapula","admin","129185","1894038366"
"Alwāl","Alwal","17.5047","78.5038","India","IN","IND","Andhra Pradesh","","123031","1356947057"
"Songnim","Songnim","38.7542","125.6450","North Korea","KP","PRK","Hwangbuk","","128831","1408214405"
"Santo Tomas","Santo Tomas","7.5333","125.6167","Philippines","PH","PHL","Davao del Norte","","128667","1608792041"
"Koidu","Koidu","8.6439","-10.9717","Sierra Leone","SL","SLE","Eastern","","124662","1694710002"
"Hoshangābād","Hoshangabad","22.7475","77.7274","India","IN","IND","Madhya Pradesh","","128599","1356003812"
"Opole","Opole","50.6667","17.9333","Poland","PL","POL","Opolskie","admin","127792","1616096837"
"Novocheboksarsk","Novocheboksarsk","56.1333","47.5000","Russia","RU","RUS","Chuvashiya","","126626","1643839131"
"Araras","Araras","-22.3572","-47.3844","Brazil","BR","BRA","São Paulo","","128895","1076656148"
"Khanna","Khanna","30.7000","76.2200","India","IN","IND","Punjab","","128137","1356905575"
"Puno","Puno","-15.8433","-70.0236","Peru","PE","PER","Puno","admin","128637","1604227042"
"Koforidua","Koforidua","6.0833","-0.2500","Ghana","GH","GHA","Eastern","admin","127334","1288961962"
"Ahmadpur East","Ahmadpur East","29.1439","71.2592","Pakistan","PK","PAK","Punjab","","128112","1586824484"
"Rosario","Rosario","13.8460","121.2060","Philippines","PH","PHL","Batangas","","128352","1608786784"
"Avrankou","Avrankou","6.5500","2.6667","Benin","BJ","BEN","Ouémé","","128050","1204861603"
"Salto","Salto","-31.3833","-57.9500","Uruguay","UY","URY","Salto","admin","124878","1858575950"
"Mosquera","Mosquera","4.7078","-74.2328","Colombia","CO","COL","Cundinamarca","minor","128012","1170751552"
"Missérété","Misserete","6.5625","2.5853","Benin","BJ","BEN","Ouémé","","127249","1204481663"
"Vihari","Vihari","30.0419","72.3528","Pakistan","PK","PAK","Punjab","minor","128034","1586888846"
"Amherst","Amherst","43.0117","-78.7570","United States","US","USA","New York","","128783","1840057194"
"Örebro","Orebro","59.2739","15.2075","Sweden","SE","SWE","Örebro","admin","126604","1752223019"
"Temperley","Temperley","-34.7667","-58.3833","Argentina","AR","ARG","Buenos Aires","","121451","1032183944"
"Baigou","Baigou","39.1098","116.0139","China","CN","CHN","Hebei","","124274","1156195365"
"Tukuyu","Tukuyu","-9.2500","33.6500","Tanzania","TZ","TZA","Mbeya","","127570","1834960117"
"Shiyan","Shiyan","23.1251","113.8633","China","CN","CHN","Guangdong","","122802","1156157858"
"Zoetermeer","Zoetermeer","52.0667","4.5000","Netherlands","NL","NLD","Zuid-Holland","minor","125267","1528416914"
"Ban Bang Pu Mai","Ban Bang Pu Mai","13.5441","100.6175","Thailand","TH","THA","Samut Prakan","","120058","1764009212"
"Iwakuni","Iwakuni","34.1664","132.2189","Japan","JP","JPN","Yamaguchi","","128401","1392032573"
"Sinnūris","Sinnuris","29.4072","30.8667","Egypt","EG","EGY","Al Fayyūm","","125905","1818442898"
"Garut","Garut","-7.2167","107.9000","Indonesia","ID","IDN","Jawa Barat","minor","127583","1360030509"
"Seto","Seto","35.2236","137.0842","Japan","JP","JPN","Aichi","","127327","1392028874"
"Bataysk","Bataysk","47.1667","39.7333","Russia","RU","RUS","Rostovskaya Oblast’","","126769","1643522051"
"Whitby","Whitby","43.8833","-78.9417","Canada","CA","CAN","Ontario","","128377","1124112077"
"Buôn Hồ","Buon Ho","12.8544","108.2703","Vietnam","VN","VNM","Đắk Lắk","","127920","1704467576"
"Romford","Romford","51.5768","0.1801","United Kingdom","GB","GBR","Havering","","122854","1826695421"
"Pinsk","Pinsk","52.1153","26.1031","Belarus","BY","BLR","Brestskaya Voblasts’","minor","125900","1112209279"
"Tumen","Tumen","42.9627","129.8413","China","CN","CHN","Jilin","minor","128212","1156872358"
"Ramos Mejía","Ramos Mejia","-34.6500","-58.5667","Argentina","AR","ARG","Buenos Aires","","120346","1032425368"
"Rudnyy","Rudnyy","52.9667","63.1333","Kazakhstan","KZ","KAZ","","minor","128200","1398701954"
"Guimba","Guimba","15.6606","120.7650","Philippines","PH","PHL","Nueva Ecija","","127653","1608954884"
"Miramar","Miramar","22.3375","-97.8694","Mexico","MX","MEX","Tamaulipas","","118614","1484353090"
"Nefteyugansk","Nefteyugansk","61.0833","72.7000","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","128159","1643009656"
"Nāḩiyat Khān Banī Sa‘d","Nahiyat Khan Bani Sa\`d","33.5700","44.5411","Iraq","IQ","IRQ","Diyālá","","127000","1368309131"
"La Granja","La Granja","-33.5333","-70.6250","Chile","CL","CHL","Región Metropolitana","","116571","1152785745"
"South Lyon","South Lyon","42.4614","-83.6526","United States","US","USA","Michigan","","128071","1840002432"
"Al Ḩajar al Aswad","Al Hajar al Aswad","33.4640","36.3044","Syria","SY","SYR","Rīf Dimashq","minor","101827","1760587307"
"Guasdualito","Guasdualito","7.2467","-70.7292","Venezuela","VE","VEN","Apure","minor","128025","1862834503"
"Domodedovo","Domodedovo","55.4400","37.7619","Russia","RU","RUS","Moskovskaya Oblast’","","127850","1643918019"
"Ulm","Ulm","48.4000","9.9833","Germany","DE","DEU","Baden-Württemberg","minor","126949","1276002212"
"Darnah","Darnah","32.7667","22.6333","Libya","LY","LBY","Darnah","admin","127974","1434920152"
"Santa Lucía Cotzumalguapa","Santa Lucia Cotzumalguapa","14.3333","-91.0167","Guatemala","GT","GTM","Escuintla","minor","127683","1320110674"
"Nagda","Nagda","23.4564","75.4131","India","IN","IND","Madhya Pradesh","","127484","1356765829"
"Kadoma","Kadoma","34.7333","135.5833","Japan","JP","JPN","Ōsaka","","118319","1392702415"
"Salmās","Salmas","38.2017","44.7686","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","127864","1364204147"
"Santa Clara","Santa Clara","37.3646","-121.9680","United States","US","USA","California","","127922","1840021571"
"St. George","St. George","37.0758","-113.5752","United States","US","USA","Utah","","127890","1840021559"
"Allada","Allada","6.6500","2.1500","Benin","BJ","BEN","Atlantique","","127512","1204001429"
"Jāzān","Jazan","16.8892","42.5611","Saudi Arabia","SA","SAU","Jāzān","admin","127743","1682515652"
"Maricá","Marica","-22.9189","-42.8189","Brazil","BR","BRA","Rio de Janeiro","","127461","1076927018"
"Dessalines","Dessalines","19.2833","-72.5000","Haiti","HT","HTI","Artibonite","","127529","1332184774"
"Kaspiysk","Kaspiysk","42.8803","47.6383","Russia","RU","RUS","Dagestan","","123988","1643020514"
"Heilbronn","Heilbronn","49.1500","9.2167","Germany","DE","DEU","Baden-Württemberg","minor","126458","1276377359"
"Anderlecht","Anderlecht","50.8333","4.3333","Belgium","BE","BEL","Brussels-Capital Region","","120887","1056297471"
"Fort Smith","Fort Smith","35.3495","-94.3695","United States","US","USA","Arkansas","","127699","1840013456"
"Neftekamsk","Neftekamsk","56.1417","54.4667","Russia","RU","RUS","Bashkortostan","","126805","1643718660"
"Ōmiyachō","Omiyacho","35.2221","138.6216","Japan","JP","JPN","Shizuoka","","127252","1392347988"
"San Ignacio","San Ignacio","-5.1456","-79.0017","Peru","PE","PER","Cajamarca","","127523","1604203555"
"Bajos de Haina","Bajos de Haina","18.4167","-70.0333","Dominican Republic","DO","DOM","Valdesia","","124193","1214790716"
"Bam","Bam","29.1061","58.3569","Iran","IR","IRN","Kermān","minor","127396","1364697653"
"High Wycombe","High Wycombe","51.6287","-0.7482","United Kingdom","GB","GBR","Buckinghamshire","","120256","1826609342"
"Monza","Monza","45.5836","9.2736","Italy","IT","ITA","Lombardy","minor","123598","1380622727"
"Pforzheim","Pforzheim","48.8950","8.7050","Germany","DE","DEU","Baden-Württemberg","minor","126016","1276732622"
"Ōsaki","Osaki","38.5771","140.9556","Japan","JP","JPN","Miyagi","","127135","1392003337"
"Jhārsugra","Jharsugra","21.8500","84.0300","India","IN","IND","Odisha","","124500","1356155035"
"Balneário de Camboriú","Balneario de Camboriu","-26.9953","-48.6356","Brazil","BR","BRA","Santa Catarina","","124557","1076385564"
"Santana de Parnaíba","Santana de Parnaiba","-23.4439","-46.9178","Brazil","BR","BRA","São Paulo","","126574","1076401391"
"Palni","Palni","10.4500","77.5161","India","IN","IND","Tamil Nādu","","126751","1356384365"
"Rosetta","Rosetta","31.4044","30.4164","Egypt","EG","EGY","Al Buḩayrah","","125778","1818868679"
"Payakumbuh","Payakumbuh","-0.2333","100.6333","Indonesia","ID","IDN","Sumatera Barat","","125608","1360091636"
"Simi Valley","Simi Valley","34.2663","-118.7490","United States","US","USA","California","","127158","1840021843"
"Pakokku","Pakokku","21.3320","95.0866","Myanmar","MM","MMR","Magway","","126938","1104161315"
"Angren","Angren","41.0167","70.1436","Uzbekistan","UZ","UZB","Toshkent","","126962","1860045958"
"Basīrhat","Basirhat","22.6612","88.8660","India","IN","IND","West Bengal","","125254","1356109985"
"Duitama","Duitama","5.8333","-73.0167","Colombia","CO","COL","Boyacá","minor","126670","1170695532"
"Leiria","Leiria","39.7500","-8.8000","Portugal","PT","PRT","Leiria","admin","126897","1620304777"
"Larache","Larache","35.1833","-6.1500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","125008","1504637172"
"Navadwīp","Navadwip","23.4088","88.3657","India","IN","IND","West Bengal","","125543","1356449184"
"Latina","Latina","41.4672","12.9036","Italy","IT","ITA","Lazio","minor","126470","1380947157"
"Cambridge","Cambridge","52.2053","0.1192","United Kingdom","GB","GBR","Cambridgeshire","","123867","1826229713"
"Ünye","Unye","41.1333","37.2833","Turkey","TR","TUR","Ordu","minor","126702","1792053028"
"San Fernando","San Fernando","16.6200","120.3200","Philippines","PH","PHL","La Union","admin","125640","1608635527"
"Exeter","Exeter","50.7256","-3.5269","United Kingdom","GB","GBR","Devon","","124180","1826840903"
"Gurabo al Medio","Gurabo al Medio","19.4739","-70.6727","Dominican Republic","DO","DOM","Cibao Norte","","126668","1214621911"
"Santa Cruz","Santa Cruz","14.2833","121.4167","Philippines","PH","PHL","Laguna","","123574","1608971699"
"Guntakal","Guntakal","15.1700","77.3800","India","IN","IND","Andhra Pradesh","","126270","1356140724"
"Pithampur","Pithampur","22.6197","75.6933","India","IN","IND","Madhya Pradesh","","126099","1356912433"
"Iizuka","Iizuka","33.6500","130.6833","Japan","JP","JPN","Fukuoka","","126136","1392365784"
"Sabará","Sabara","-19.8858","-43.8069","Brazil","BR","BRA","Minas Gerais","","126269","1076006379"
"Catabola","Catabola","-12.1167","17.3000","Angola","AO","AGO","Bié","","126631","1024994288"
"Suhum","Suhum","6.0333","-0.4500","Ghana","GH","GHA","Eastern","","126403","1288836102"
"Bagong Silangan","Bagong Silangan","14.7094","121.1000","Philippines","PH","PHL","Quezon","","106886","1608517154"
"Barbacena","Barbacena","-21.2167","-43.7667","Brazil","BR","BRA","Minas Gerais","","126284","1076877003"
"Bến Tre","Ben Tre","10.2333","106.3833","Vietnam","VN","VNM","Bến Tre","admin","124449","1704108909"
"Hālīsahar","Halisahar","22.9500","88.4200","India","IN","IND","West Bengal","","124939","1356242644"
"Matamoros","Matamoros","25.5330","-103.2500","Mexico","MX","MEX","Coahuila","minor","118337","1484930279"
"Western Bicutan","Western Bicutan","14.5094","121.0381","Philippines","PH","PHL","Taguig","","91158","1608357652"
"Rishra","Rishra","22.7100","88.3500","India","IN","IND","West Bengal","","124577","1356339732"
"Hoima","Hoima","1.4319","31.3525","Uganda","UG","UGA","Hoima","admin","122700","1800806239"
"Drohobych","Drohobych","49.3500","23.5000","Ukraine","UA","UKR","Lvivska Oblast","minor","124269","1804782563"
"Panevėžys","Panevezys","55.7333","24.3500","Lithuania","LT","LTU","Panevėžio Miestas","admin","124412","1440120065"
"Leuwiliang","Leuwiliang","-6.5742","106.6320","Indonesia","ID","IDN","Jawa Barat","","124670","1360771785"
"Magelang","Magelang","-7.4667","110.2167","Indonesia","ID","IDN","Jawa Tengah","","124912","1360065728"
"Jizhou","Jizhou","37.5455","115.5663","China","CN","CHN","Hebei","","124716","1156346717"
"Kampong Cham","Kampong Cham","11.9870","105.4620","Cambodia","KH","KHM","Kampong Cham","admin","118242","1116115849"
"Gashua","Gashua","12.8681","11.0464","Nigeria","NG","NGA","Yobe","minor","125817","1566925429"
"Shchelkovo","Shchelkovo","55.9167","38.0000","Russia","RU","RUS","Moskovskaya Oblast’","","125634","1643006516"
"Dover","Dover","39.1610","-75.5202","United States","US","USA","Delaware","admin","125931","1840005810"
"Chinautla","Chinautla","14.7029","-90.5000","Guatemala","GT","GTM","Guatemala","minor","124365","1320450064"
"Pātan","Patan","23.8500","72.1167","India","IN","IND","Gujarāt","","125497","1356500905"
"Novomoskovsk","Novomoskovsk","54.0833","38.2167","Russia","RU","RUS","Tul’skaya Oblast’","","125647","1643119962"
"Norman","Norman","35.2335","-97.3471","United States","US","USA","Oklahoma","","125745","1840020451"
"Cam Ranh","Cam Ranh","11.9136","109.1369","Vietnam","VN","VNM","Khánh Hòa","minor","125311","1704516746"
"Kumbo","Kumbo","6.2000","10.6600","Cameroon","CM","CMR","North-West","","125486","1120679288"
"Sai Wan Ho","Sai Wan Ho","22.2877","114.1365","Hong Kong","HK","HKG","","","12985","1344000086"
"Giugliano in Campania","Giugliano in Campania","40.9319","14.1956","Italy","IT","ITA","Campania","","124361","1380386690"
"Cagua","Cagua","10.1875","-67.4611","Venezuela","VE","VEN","Aragua","minor","122571","1862805374"
"Yuanlin","Yuanlin","23.9611","120.5736","Taiwan","TW","TWN","Changhua","","122518","1158630209"
"Indramayu","Indramayu","-6.3528","108.3242","Indonesia","ID","IDN","Jawa Barat","minor","123263","1360004807"
"Colchester","Colchester","51.8917","0.9030","United Kingdom","GB","GBR","Essex","","121859","1826545433"
"Gateshead","Gateshead","54.9500","-1.6000","United Kingdom","GB","GBR","Gateshead","","120046","1826635849"
"Pakxé","Pakxe","15.1167","105.7833","Laos","LA","LAO","Champasak","admin","119848","1418863956"
"Bonao","Bonao","18.9500","-70.4100","Dominican Republic","DO","DOM","Cibao Sur","minor","125338","1214793175"
"Honchō","Honcho","35.7580","139.5299","Japan","JP","JPN","Saitama","","116477","1392994259"
"Abilene","Abilene","32.4543","-99.7384","United States","US","USA","Texas","","125474","1840019476"
"Magalang","Magalang","15.2167","120.6667","Philippines","PH","PHL","Pampanga","minor","124188","1608172208"
"Uruguaiana","Uruguaiana","-29.7550","-57.0878","Brazil","BR","BRA","Rio Grande do Sul","","125435","1076680267"
"Porlamar","Porlamar","10.9556","-63.8478","Venezuela","VE","VEN","Nueva Esparta","minor","120924","1862693736"
"Resende","Resende","-22.4689","-44.4469","Brazil","BR","BRA","Rio de Janeiro","","125214","1076678781"
"Daitōchō","Daitocho","34.7167","135.6167","Japan","JP","JPN","Ōsaka","","118812","1392727709"
"Móng Cái","Mong Cai","21.5333","107.9667","Vietnam","VN","VNM","Quảng Ninh","minor","125000","1704988589"
"Sawrān","Sawran","36.6606","44.5508","Iraq","IQ","IRQ","Arbīl","minor","125000","1368321779"
"León","Leon","42.6056","-5.5700","Spain","ES","ESP","Castille-Leon","minor","122051","1724907542"
"Sunām","Sunam","30.1300","75.8000","India","IN","IND","Punjab","","124590","1356036724"
"Central Signal Village","Central Signal Village","14.5106","121.0576","Philippines","PH","PHL","Taguig","","39674","1608571433"
"Polatlı","Polatli","39.5842","32.1472","Turkey","TR","TUR","Ankara","minor","125075","1792124981"
"Jirjā","Jirja","26.3333","31.9000","Egypt","EG","EGY","Sūhāj","","102597","1818327836"
"Fugu","Fugu","39.0259","111.0683","China","CN","CHN","Shaanxi","","124002","1156766749"
"Jilib","Jilib","0.5000","42.7667","Somalia","SO","SOM","Jubbada Dhexe","","125000","1706018650"
"Az Zulfī","Az Zulfi","26.2833","44.8000","Saudi Arabia","SA","SAU","Ar Riyāḑ","","125000","1682476550"
"Billings","Billings","45.7891","-108.5526","United States","US","USA","Montana","","124973","1840018496"
"Leeuwarden","Leeuwarden","53.2000","5.7833","Netherlands","NL","NLD","Fryslân","admin","124481","1528992603"
"Ouahigouya","Ouahigouya","13.5833","-2.4167","Burkina Faso","BF","BFA","Nord","admin","124580","1854011699"
"Dūmā","Duma","33.5711","36.4011","Syria","SY","SYR","Rīf Dimashq","minor","123494","1760821818"
"Juticalpa","Juticalpa","14.6664","-86.2186","Honduras","HN","HND","Olancho","admin","124828","1340411203"
"Zhaxi","Zhaxi","27.8440","105.0451","China","CN","CHN","Yunnan","minor","124458","1156414076"
"Gexianzhuang","Gexianzhuang","37.0694","115.6591","China","CN","CHN","Hebei","","122917","1156124522"
"Wolfsburg","Wolfsburg","52.4231","10.7872","Germany","DE","DEU","Lower Saxony","minor","124151","1276033981"
"Savannakhet","Savannakhet","16.5500","104.7500","Laos","LA","LAO","Savannakhét","admin","124000","1418179232"
"Gorzów Wielkopolski","Gorzow Wielkopolski","52.7333","15.2500","Poland","PL","POL","Lubuskie","admin","123341","1616788025"
"Ponce","Ponce","18.0127","-66.6212","Puerto Rico","PR","PRI","Puerto Rico","","124719","1630023669"
"Cai Lậy","Cai Lay","10.4170","106.0830","Vietnam","VN","VNM","Tiền Giang","","123775","1704031131"
"Gyumri","Gyumri","40.7894","43.8475","Armenia","AM","ARM","Shirak","admin","121976","1051341374"
"Glazoué","Glazoue","7.9736","2.2400","Benin","BJ","BEN","Collines","","124431","1204842273"
"Pervouralsk","Pervouralsk","56.9167","59.9500","Russia","RU","RUS","Sverdlovskaya Oblast’","","124447","1643845220"
"New Mirpur","New Mirpur","33.1490","73.7485","Pakistan","PK","PAK","Azad Kashmir","minor","124352","1586007078"
"Noksan","Noksan","36.2039","127.0847","South Korea","KR","KOR","Chungnam","","124246","1410179693"
"San Pedro Garza García","San Pedro Garza Garcia","25.6667","-100.3000","Mexico","MX","MEX","Nuevo León","minor","122659","1484228590"
"Montreuil","Montreuil","48.8611","2.4436","France","FR","FRA","Île-de-France","","111240","1250689747"
"Serik","Serik","36.9167","31.1000","Turkey","TR","TUR","Antalya","minor","124335","1792809548"
"Algeciras","Algeciras","36.1275","-5.4539","Spain","ES","ESP","Andalusia","","122982","1724355681"
"Pāli","Pali","23.3500","81.0500","India","IN","IND","Madhya Pradesh","","124175","1356107944"
"Bimbo","Bimbo","4.3313","18.5163","Central African Republic","CF","CAF","Ombella-Mpoko","admin","124176","1140257478"
"Hội An","Hoi An","15.8833","108.3333","Vietnam","VN","VNM","Quảng Nam","","121716","1704897050"
"Setúbal","Setubal","38.5243","-8.8926","Portugal","PT","PRT","Setúbal","admin","123684","1620174557"
"Matsubara","Matsubara","34.5833","135.5500","Japan","JP","JPN","Ōsaka","","117186","1392003470"
"Baia Mare","Baia Mare","47.6667","23.5833","Romania","RO","ROU","Maramureş","admin","123738","1642459769"
"Kadirli","Kadirli","37.3697","36.1000","Turkey","TR","TUR","Osmaniye","minor","124053","1792113133"
"Khrustalnyi","Khrustalnyi","48.1214","38.9453","Ukraine","UA","UKR","Luhanska Oblast","","124000","1804368412"
"Cherkessk","Cherkessk","44.2222","42.0575","Russia","RU","RUS","Karachayevo-Cherkesiya","admin","122395","1643955713"
"Malindi","Malindi","-3.2236","40.1300","Kenya","KE","KEN","Kilifi","","119859","1404380224"
"Pobé","Pobe","6.9667","2.6833","Benin","BJ","BEN","Plateau","","123740","1204955158"
"Bergamo","Bergamo","45.6950","9.6700","Italy","IT","ITA","Lombardy","minor","120923","1380253521"
"Kirishima","Kirishima","31.7406","130.7631","Japan","JP","JPN","Kagoshima","","123812","1392003529"
"Gapan","Gapan","15.3122","120.9489","Philippines","PH","PHL","Nueva Ecija","","122968","1608386821"
"Magangué","Magangue","9.2500","-74.7667","Colombia","CO","COL","Bolívar","minor","123906","1170903510"
"Jimaguayú","Jimaguayu","21.2667","-77.8303","Cuba","CU","CUB","Camagüey","minor","123954","1192264098"
"Debre Tabor","Debre Tabor","11.8500","38.0167","Ethiopia","ET","ETH","Āmara","","119176","1231557860"
"Maicao","Maicao","11.3778","-72.2389","Colombia","CO","COL","La Guajira","minor","123757","1170370871"
"Delgado","Delgado","13.7167","-89.1667","El Salvador","SV","SLV","San Salvador","","120200","1222858341"
"Uruma","Uruma","26.3792","127.8575","Japan","JP","JPN","Okinawa","","122371","1392003417"
"Lehigh Acres","Lehigh Acres","26.6120","-81.6388","United States","US","USA","Florida","","123751","1840014220"
"Derbent","Derbent","42.0500","48.3000","Russia","RU","RUS","Dagestan","minor","123720","1643003556"
"Melipilla","Melipilla","-33.6253","-71.2711","Chile","CL","CHL","Región Metropolitana","minor","123627","1152483678"
"Pati","Pati","-6.7415","111.0347","Indonesia","ID","IDN","Jawa Tengah","minor","122785","1360407556"
"Cádiz","Cadiz","36.5350","-6.2975","Spain","ES","ESP","Andalusia","minor","114244","1724502822"
"Djidja","Djidja","7.3333","1.9333","Benin","BJ","BEN","Zou","","123542","1204250731"
"Longtian","Longtian","24.3512","114.1293","China","CN","CHN","Guangdong","","123469","1156115403"
"St. Cloud","St. Cloud","45.5340","-94.1718","United States","US","USA","Minnesota","","123569","1840008881"
"Nāndgaon","Nandgaon","20.3070","74.6570","India","IN","IND","Mahārāshtra","","123191","1356282779"
"Arecibo","Arecibo","18.4491","-66.7387","Puerto Rico","PR","PRI","Puerto Rico","","123499","1630023566"
"Munūf","Munuf","30.4658","30.9308","Egypt","EG","EGY","Al Minūfīyah","","117240","1818310821"
"Punta Arenas","Punta Arenas","-53.1667","-70.9333","Chile","CL","CHL","Magallanes y de la Antártica Chilena","admin","123403","1152262933"
"Varginha","Varginha","-21.5517","-45.4300","Brazil","BR","BRA","Minas Gerais","","123081","1076731794"
"Munch’ŏn","Munch'on","39.2590","127.3560","North Korea","KP","PRK","Kangwŏn","","122934","1408134393"
"Kilifi","Kilifi","-3.6333","39.8500","Kenya","KE","KEN","Kilifi","","122899","1404002930"
"Bhadrakh","Bhadrakh","21.0545","86.5156","India","IN","IND","Odisha","","122119","1356245799"
"Zacatecas","Zacatecas","22.7736","-102.5736","Mexico","MX","MEX","Zacatecas","admin","122889","1484118312"
"Santana","Santana","-0.0350","-51.1750","Brazil","BR","BRA","Amapá","","123096","1076974952"
"Kaya","Kaya","13.0833","-1.0833","Burkina Faso","BF","BFA","Centre-Nord","admin","122949","1854170035"
"Alberton","Alberton","-26.2672","28.1219","South Africa","ZA","ZAF","Gauteng","","121536","1710738396"
"Kenosha","Kenosha","42.5865","-87.8773","United States","US","USA","Wisconsin","","123061","1840003160"
"Ise","Ise","34.4833","136.7167","Japan","JP","JPN","Mie","","122432","1392000398"
"Lianhe","Lianhe","47.1414","129.2577","China","CN","CHN","Heilongjiang","minor","121367","1156101860"
"Brits","Brits","-25.6344","27.7811","South Africa","ZA","ZAF","North West","","122497","1710468143"
"Itatiba","Itatiba","-23.0058","-46.8389","Brazil","BR","BRA","São Paulo","","122581","1076747401"
"Dimāpur","Dimapur","25.9200","93.7300","India","IN","IND","Assam","","122834","1356155429"
"Ed Damer","Ed Damer","17.5900","33.9600","Sudan","SD","SDN","River Nile","admin","122944","1729791168"
"Bragança","Braganca","-1.0628","-46.7728","Brazil","BR","BRA","Pará","","122881","1076855072"
"Patnos","Patnos","39.2358","42.8686","Turkey","TR","TUR","Ağrı","minor","122833","1792386113"
"Bayawan","Bayawan","9.3667","122.8000","Philippines","PH","PHL","Negros Oriental","","122747","1608189345"
"Greenville","Greenville","35.5943","-77.3760","United States","US","USA","North Carolina","","122921","1840013407"
"Barretos","Barretos","-20.5569","-48.5678","Brazil","BR","BRA","São Paulo","","122833","1076042195"
"Arvada","Arvada","39.8320","-105.1510","United States","US","USA","Colorado","","122903","1840018788"
"Kâhta","Kahta","37.7803","38.6217","Turkey","TR","TUR","Adıyaman","minor","122774","1792788928"
"Blackburn","Blackburn","53.7480","-2.4820","United Kingdom","GB","GBR","Blackburn with Darwen","","117963","1826802533"
"Machiques","Machiques","10.0667","-72.5667","Venezuela","VE","VEN","Zulia","minor","122734","1862003182"
"Ciudad Hidalgo","Ciudad Hidalgo","19.6923","-100.5574","Mexico","MX","MEX","Michoacán","minor","122619","1484580926"
"Slough","Slough","51.5084","-0.5881","United Kingdom","GB","GBR","Slough","","119070","1826807636"
"Pescara","Pescara","42.4643","14.2142","Italy","IT","ITA","Abruzzo","minor","119217","1380000966"
"Guelma","Guelma","36.4619","7.4258","Algeria","DZ","DZA","Guelma","admin","120004","1012266321"
"Behbahān","Behbahan","30.5958","50.2417","Iran","IR","IRN","Khūzestān","minor","122604","1364393434"
"Pearland","Pearland","29.5581","-95.3218","United States","US","USA","Texas","","122609","1840020979"
"Baidyabāti","Baidyabati","22.7900","88.3200","India","IN","IND","West Bengal","","121110","1356079081"
"Kotamobagu","Kotamobagu","0.7333","124.3167","Indonesia","ID","IDN","Sulawesi Utara","","120597","1360015626"
"Mufulira","Mufulira","-12.5356","28.2419","Zambia","ZM","ZMB","Copperbelt","","122336","1894907880"
"Puerto Cortés","Puerto Cortes","15.8833","-87.9500","Honduras","HN","HND","Cortés","","122426","1340000062"
"Maina","Maina","13.4692","144.7332","Guam","GU","GUM","","","122411","1316874021"
"Dharmavaram","Dharmavaram","14.4300","77.7200","India","IN","IND","Andhra Pradesh","","121874","1356879569"
"Edéa","Edea","3.8000","10.1333","Cameroon","CM","CMR","Littoral","","122300","1120546542"
"Honmachi","Honmachi","32.5000","130.6000","Japan","JP","JPN","Kumamoto","","122134","1392126782"
"Ciudad Ojeda","Ciudad Ojeda","10.2000","-71.3000","Venezuela","VE","VEN","Zulia","minor","122124","1862967909"
"Bento Gonçalves","Bento Goncalves","-29.1708","-51.5189","Brazil","BR","BRA","Rio Grande do Sul","","121803","1076492877"
"Independence","Independence","39.0871","-94.3501","United States","US","USA","Missouri","","122230","1840008534"
"Fier","Fier","40.7250","19.5572","Albania","AL","ALB","Fier","admin","120655","1008293317"
"Kamalia","Kamalia","30.7258","72.6447","Pakistan","PK","PAK","Punjab","","121401","1586927428"
"Ghazīpur","Ghazipur","25.5833","83.5667","India","IN","IND","Uttar Pradesh","","121020","1356773744"
"Orekhovo-Zuyevo","Orekhovo-Zuyevo","55.8000","38.9667","Russia","RU","RUS","Moskovskaya Oblast’","","118822","1643381342"
"Texas City","Texas City","29.4154","-94.9682","United States","US","USA","Texas","","122017","1840022234"
"Saint-Denis","Saint-Denis","48.9356","2.3539","France","FR","FRA","Île-de-France","minor","112852","1250935115"
"Chinandega","Chinandega","12.6297","-87.1283","Nicaragua","NI","NIC","Chinandega","admin","121793","1558172276"
"Puruliya","Puruliya","23.3333","86.3667","India","IN","IND","West Bengal","","121067","1356350196"
"Kashiwara","Kashiwara","34.5167","135.8000","Japan","JP","JPN","Nara","","121604","1392686221"
"Fāqūs","Faqus","30.7282","31.7970","Egypt","EG","EGY","Ash Sharqīyah","","111532","1818728828"
"Urasoe","Urasoe","26.2458","127.7219","Japan","JP","JPN","Okinawa","","115855","1392003314"
"Dorūd","Dorud","33.4986","49.0539","Iran","IR","IRN","Lorestān","minor","121608","1364662084"
"Guarapari","Guarapari","-20.6500","-40.5000","Brazil","BR","BRA","Espírito Santo","","121506","1076076726"
"Hòa Bình","Hoa Binh","20.8133","105.3383","Vietnam","VN","VNM","Hòa Bình","admin","121309","1704237072"
"Kuopio","Kuopio","62.8925","27.6783","Finland","FI","FIN","Pohjois-Savo","admin","121557","1246334067"
"Ribeirão Pires","Ribeirao Pires","-23.7153","-46.4194","Brazil","BR","BRA","São Paulo","","120396","1076050419"
"Ondjiva","Ondjiva","-17.0667","15.7333","Angola","AO","AGO","Cunene","admin","121537","1024504085"
"Port-de-Paix","Port-de-Paix","19.9500","-72.8333","Haiti","HT","HTI","Nord-Ouest","admin","121220","1332536650"
"Shuixi","Shuixi","22.5111","113.3161","China","CN","CHN","Guangdong","","119517","1156805889"
"Wuling","Wuling","39.4421","114.2300","China","CN","CHN","Shanxi","minor","121110","1156122729"
"Samandağ","Samandag","36.0850","35.9806","Turkey","TR","TUR","Hatay","minor","121109","1792813864"
"Redding","Redding","40.5698","-122.3650","United States","US","USA","California","","121498","1840020141"
"Tsuruoka","Tsuruoka","38.7272","139.8267","Japan","JP","JPN","Yamagata","","121371","1392935450"
"Soreang","Soreang","-7.0372","107.5167","Indonesia","ID","IDN","Jawa Barat","minor","116780","1360684486"
"Ciudad Guzmán","Ciudad Guzman","19.7000","-103.4667","Mexico","MX","MEX","Jalisco","minor","115000","1484316056"
"Gudiyāttam","Gudiyattam","12.9476","78.8700","India","IN","IND","Tamil Nādu","","120716","1356530159"
"Upington","Upington","-28.4572","21.2425","South Africa","ZA","ZAF","Northern Cape","","121189","1710210449"
"Chơn Thành","Chon Thanh","11.4292","106.6572","Vietnam","VN","VNM","Bình Phước","minor","121083","1704230089"
"Doğubayazıt","Dogubayazit","39.5472","44.0833","Turkey","TR","TUR","Ağrı","minor","121263","1792572929"
"Metz","Metz","49.1203","6.1778","France","FR","FRA","Grand Est","minor","118489","1250778717"
"Płock","Plock","52.5500","19.7000","Poland","PL","POL","Mazowieckie","minor","120000","1616954507"
"Bagaha","Bagaha","27.0992","84.0900","India","IN","IND","Bihār","","120736","1356397208"
"Gurdāspur","Gurdaspur","32.0333","75.4000","India","IN","IND","Punjab","","120564","1356120401"
"Tabuk","Tabuk","17.4069","121.4425","Philippines","PH","PHL","Kalinga","","121033","1608831439"
"Apartadó","Apartado","7.8833","-76.6333","Colombia","CO","COL","Antioquia","minor","121003","1170483593"
"Siracusa","Siracusa","37.0692","15.2875","Italy","IT","ITA","Sicilia","minor","121171","1380071816"
"Apucarana","Apucarana","-23.5508","-51.4608","Brazil","BR","BRA","Paraná","","120919","1076256387"
"Perpignan","Perpignan","42.6986","2.8956","France","FR","FRA","Occitanie","minor","119344","1250102680"
"Valinhos","Valinhos","-22.9706","-46.9958","Brazil","BR","BRA","São Paulo","minor","120258","1076809155"
"Chilapa de Álvarez","Chilapa de Alvarez","17.5944","-99.1778","Mexico","MX","MEX","Guerrero","minor","120790","1484562441"
"Cachoeirinha","Cachoeirinha","-29.9508","-51.0939","Brazil","BR","BRA","Rio Grande do Sul","","118278","1076573967"
"Guelmim","Guelmim","28.9833","-10.0667","Morocco","MA","MAR","Guelmim-Oued Noun","admin","118318","1504110861"
"Navojoa","Navojoa","27.0813","-109.4461","Mexico","MX","MEX","Sonora","minor","120926","1484166303"
"Kot Addu","Kot Addu","30.4700","70.9644","Pakistan","PK","PAK","Punjab","","120479","1586130042"
"Lynchburg","Lynchburg","37.4003","-79.1909","United States","US","USA","Virginia","","120877","1840003855"
"Zonguldak","Zonguldak","41.4564","31.7986","Turkey","TR","TUR","Zonguldak","admin","120395","1792410633"
"Boulder","Boulder","40.0248","-105.2524","United States","US","USA","Colorado","","120818","1840018774"
"Göttingen","Gottingen","51.5339","9.9356","Germany","DE","DEU","Lower Saxony","minor","119801","1276211234"
"Unwana","Unwana","5.8625","7.9486","Nigeria","NG","NGA","Abia","","120000","1566334848"
"Vihiga","Vihiga","0.0500","34.7250","Kenya","KE","KEN","Vihiga","","118696","1404002123"
"Catchiungo","Catchiungo","-12.7864","16.3233","Angola","AO","AGO","Huambo","","120677","1024414170"
"Barcelos","Barcelos","41.5167","-8.6167","Portugal","PT","PRT","Braga","minor","120391","1620850431"
"Ilobu","Ilobu","7.8400","4.4860","Nigeria","NG","NGA","Osun","minor","118097","1566604971"
"Kỳ Anh","Ky Anh","18.0678","106.2967","Vietnam","VN","VNM","Hà Tĩnh","minor","120518","1704418258"
"Sātāra","Satara","17.6833","73.9833","India","IN","IND","Mahārāshtra","","120079","1356054526"
"Kallithéa","Kallithea","37.9500","23.7000","Greece","GR","GRC","Attikí","minor","100641","1300675902"
"Tizayuca","Tizayuca","19.8333","-98.9833","Mexico","MX","MEX","Morelos","minor","119442","1484244950"
"Darjeeling","Darjeeling","27.0375","88.2631","India","IN","IND","West Bengal","","118805","1356267176"
"Moḩammad Shahr","Mohammad Shahr","35.7483","50.9025","Iran","IR","IRN","Alborz","","119418","1364247384"
"Al Manşūrah","Al Mansurah","12.8531","44.9722","Yemen","YE","YEM","‘Adan","minor","114931","1887579179"
"Calumpit","Calumpit","14.9167","120.7667","Philippines","PH","PHL","Bulacan","","118471","1608584009"
"Orléans","Orleans","47.9025","1.9090","France","FR","FRA","Centre-Val de Loire","admin","116269","1250441405"
"Bāgalkot","Bagalkot","16.1833","75.7000","India","IN","IND","Karnātaka","","120104","1356504504"
"Ebetsu","Ebetsu","43.1037","141.5362","Japan","JP","JPN","Hokkaidō","","119819","1392987572"
"Fujimino","Fujimino","35.8795","139.5198","Japan","JP","JPN","Saitama","","112745","1392078110"
"Đông Hòa","Dong Hoa","12.9931","109.3314","Vietnam","VN","VNM","Phú Yên","","119991","1704439726"
"Sertãozinho","Sertaozinho","-21.1381","-47.9906","Brazil","BR","BRA","São Paulo","","120152","1076989194"
"Söke","Soke","37.7508","27.4103","Turkey","TR","TUR","Aydın","minor","120217","1792512916"
"Dordrecht","Dordrecht","51.7958","4.6783","Netherlands","NL","NLD","Zuid-Holland","minor","119115","1528952691"
"Poá","Poa","-23.5286","-46.3450","Brazil","BR","BRA","São Paulo","","113793","1076905631"
"Lalo","Lalo","6.9167","1.8833","Benin","BJ","BEN","Couffo","","119926","1204461381"
"Ghardaïa","Ghardaia","32.4833","3.6667","Algeria","DZ","DZA","Ghardaïa","admin","120000","1012074690"
"Candaba","Candaba","15.0933","120.8283","Philippines","PH","PHL","Pampanga","","119497","1608001625"
"Nokha","Nokha","27.6000","73.4200","India","IN","IND","Rājasthān","","120000","1356674096"
"San Carlos","San Carlos","9.6500","-68.5833","Venezuela","VE","VEN","Cojedes","admin","120000","1862109303"
"Besançon","Besancon","47.2400","6.0200","France","FR","FRA","Bourgogne-Franche-Comté","minor","118258","1250182013"
"Baraka","Baraka","-4.1041","29.0940","Congo (Kinshasa)","CD","COD","Sud-Kivu","","120000","1180665112"
"Ar Rustāq","Ar Rustaq","23.3908","57.4244","Oman","OM","OMN","Janūb al Bāţinah","admin","120000","1512376854"
"Handa","Handa","34.8919","136.9381","Japan","JP","JPN","Aichi","","117544","1392363594"
"Iowa City","Iowa City","41.6559","-91.5303","United States","US","USA","Iowa","","120015","1840000532"
"Várzea Paulista","Varzea Paulista","-23.2114","-46.8283","Brazil","BR","BRA","São Paulo","","116601","1076046083"
"Ādīgrat","Adigrat","14.2667","39.4500","Ethiopia","ET","ETH","Tigray","","116193","1231492773"
"Catanduva","Catanduva","-21.1378","-48.9728","Brazil","BR","BRA","São Paulo","","119480","1076772178"
"Nouadhibou","Nouadhibou","20.9333","-17.0333","Mauritania","MR","MRT","Dakhlet Nouadhibou","admin","118000","1478176283"
"Chelmsford","Chelmsford","51.7300","0.4800","United Kingdom","GB","GBR","Essex","","115369","1826876670"
"Gudivāda","Gudivada","16.4300","80.9900","India","IN","IND","Andhra Pradesh","","118167","1356068699"
"Yüksekova","Yuksekova","37.5690","44.2830","Turkey","TR","TUR","Hakkâri","minor","119760","1792812119"
"Naz̧arābād","Nazarabad","35.9583","50.6050","Iran","IR","IRN","Alborz","minor","119512","1364796337"
"Pleven","Pleven","43.4078","24.6203","Bulgaria","BG","BGR","Pleven","admin","119596","1100973719"
"Rochester","Rochester","44.0154","-92.4780","United States","US","USA","Minnesota","","119732","1840008987"
"An","An","19.7833","94.0333","Myanmar","MM","MMR","Rakhine State","","119714","1104637807"
"Ajax","Ajax","43.8583","-79.0364","Canada","CA","CAN","Ontario","","119677","1124382916"
"Alcobendas","Alcobendas","40.5333","-3.6333","Spain","ES","ESP","Madrid","","117041","1724354998"
"Berkeley","Berkeley","37.8722","-122.2760","United States","US","USA","California","","119607","1840018914"
"Xuqiaocun","Xuqiaocun","30.4355","120.3645","China","CN","CHN","Zhejiang","","118263","1156372212"
"Dinalupihan","Dinalupihan","14.8833","120.4667","Philippines","PH","PHL","Bataan","","118209","1608149999"
"Dongsheng","Dongsheng","22.6199","113.2895","China","CN","CHN","Guangdong","","117976","1156117107"
"Sopur","Sopur","34.3000","74.4700","India","IN","IND","Jammu and Kashmīr","","118608","1356978065"
"Waldorf","Waldorf","38.6085","-76.9194","United States","US","USA","Maryland","","119442","1840006175"
"Toledo","Toledo","-24.7139","-53.7428","Brazil","BR","BRA","Paraná","","119313","1076312600"
"Elbląg","Elblag","54.1667","19.4000","Poland","PL","POL","Warmińsko-Mazurskie","minor","119317","1616395994"
"Nazran","Nazran","43.2167","44.7667","Russia","RU","RUS","Ingushetiya","","117936","1643123780"
"Banco Filipino International Village","Banco Filipino International Village","14.4499","120.9996","Philippines","PH","PHL","Las Piñas","","81739","1608857586"
"Araucária","Araucaria","-25.5928","-49.4100","Brazil","BR","BRA","Paraná","","119123","1076784908"
"Bruges","Bruges","51.2089","3.2242","Belgium","BE","BEL","Flanders","minor","118509","1056953902"
"Silifke","Silifke","36.3761","33.9322","Turkey","TR","TUR","Mersin","minor","119303","1792517279"
"Totonicapán","Totonicapan","14.9108","-91.3606","Guatemala","GT","GTM","Totonicapán","admin","118960","1320223386"
"The Villages","The Villages","28.9034","-81.9887","United States","US","USA","Florida","","119296","1840014065"
"Paulo Afonso","Paulo Afonso","-9.4000","-38.2250","Brazil","BR","BRA","Bahia","","119214","1076084498"
"Guaratinguetá","Guaratingueta","-22.8167","-45.2278","Brazil","BR","BRA","São Paulo","","119073","1076125544"
"Huaraz","Huaraz","-9.5333","-77.5333","Peru","PE","PER","Ancash","admin","118836","1604026500"
"Ţūz Khūrmātū","Tuz Khurmatu","34.8772","44.6383","Iraq","IQ","IRQ","Şalāḩ ad Dīn","minor","119000","1368226016"
"Kasuga","Kasuga","33.5333","130.4667","Japan","JP","JPN","Fukuoka","","111144","1392971052"
"Aw Dheegle","Aw Dheegle","1.9667","44.8333","Somalia","SO","SOM","Shabeellaha Hoose","","119000","1706678463"
"Apalit","Apalit","14.9496","120.7587","Philippines","PH","PHL","Pampanga","","117160","1608288417"
"Bet Shemesh","Bet Shemesh","31.7456","34.9867","Israel","IL","ISR","Jerusalem","","118700","1376846832"
"Gabès","Gabes","33.8833","10.1167","Tunisia","TN","TUN","Gabès","admin","116323","1788064276"
"Bwana Mkubwa","Bwana Mkubwa","-12.9833","28.7000","Zambia","ZM","ZMB","Copperbelt","","118464","1894858787"
"Charallave","Charallave","10.2431","-66.8622","Venezuela","VE","VEN","Miranda","minor","117707","1862753487"
"Duluth","Duluth","46.7756","-92.1393","United States","US","USA","Minnesota","","118975","1840007685"
"East Los Angeles","East Los Angeles","34.0326","-118.1691","United States","US","USA","California","","118964","1840019206"
"Cheltenham","Cheltenham","51.9000","-2.0667","United Kingdom","GB","GBR","Gloucestershire","","116447","1826524974"
"Nevinnomyssk","Nevinnomyssk","44.6333","41.9333","Russia","RU","RUS","Stavropol’skiy Kray","","117446","1643869152"
"Lahti","Lahti","60.9833","25.6500","Finland","FI","FIN","Päijät-Häme","admin","118119","1246538847"
"Saginaw","Saginaw","43.4199","-83.9501","United States","US","USA","Michigan","","118845","1840002813"
"Dimitrovgrad","Dimitrovgrad","54.1833","49.5833","Russia","RU","RUS","Ul’yanovskaya Oblast’","","116055","1643039639"
"Giyon","Giyon","8.5333","37.9667","Ethiopia","ET","ETH","Oromīya","","114534","1231961383"
"Phagwāra","Phagwara","31.2200","75.7700","India","IN","IND","Punjab","","117954","1356342384"
"Trento","Trento","46.0667","11.1167","Italy","IT","ITA","Trentino-Alto Adige","admin","117997","1380953307"
"Ikoma","Ikoma","34.7000","135.7000","Japan","JP","JPN","Nara","","116518","1392390581"
"Yongqing","Yongqing","39.2958","116.4897","China","CN","CHN","Hebei","","118494","1156904006"
"Pandacan","Pandacan","14.5940","121.0070","Philippines","PH","PHL","Manila","","76134","1608115179"
"Nowshera","Nowshera","34.0153","71.9747","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","118594","1586290729"
"Butwāl","Butwal","27.7000","83.4660","Nepal","NP","NPL","Lumbinī","admin","118462","1524843250"
"Barcarena Nova","Barcarena Nova","-1.5058","-48.6258","Brazil","BR","BRA","Pará","","118537","1076791102"
"Martínez de la Torre","Martinez de la Torre","20.0667","-97.0500","Mexico","MX","MEX","Veracruz","minor","110415","1484150240"
"Akishima","Akishima","35.7057","139.3535","Japan","JP","JPN","Tōkyō","","112110","1392041019"
"Birigui","Birigui","-21.2886","-50.3400","Brazil","BR","BRA","São Paulo","","118352","1076546798"
"Ligao","Ligao","13.2167","123.5167","Philippines","PH","PHL","Albay","","118096","1608907106"
"Pudukkottai","Pudukkottai","10.3833","78.8001","India","IN","IND","Tamil Nādu","","117745","1356619182"
"Bottrop","Bottrop","51.5247","6.9228","Germany","DE","DEU","North Rhine-Westphalia","minor","117383","1276473363"
"Santa Cruz do Sul","Santa Cruz do Sul","-29.7178","-52.4258","Brazil","BR","BRA","Rio Grande do Sul","","118374","1076862896"
"Kōnosu","Konosu","36.0659","139.5222","Japan","JP","JPN","Saitama","","116777","1392326410"
"Clovis","Clovis","36.8278","-119.6830","United States","US","USA","California","","118488","1840018944"
"Karatepe","Karatepe","40.7333","29.9667","Turkey","TR","TUR","Kocaeli","","118066","1792291745"
"Votorantim","Votorantim","-23.5469","-47.4378","Brazil","BR","BRA","São Paulo","","117794","1076604064"
"Linköping","Linkoping","58.4158","15.6253","Sweden","SE","SWE","Östergötland","admin","115682","1752963378"
"Yulu","Yulu","23.5193","116.4055","China","CN","CHN","Guangdong","","117110","1156335037"
"Forlì","Forli","44.2333","12.0500","Italy","IT","ITA","Emilia-Romagna","minor","117863","1380594352"
"Malita","Malita","6.4000","125.6000","Philippines","PH","PHL","Davao Occidental","admin","118197","1608802375"
"La Asunción","La Asuncion","11.0333","-63.8628","Venezuela","VE","VEN","Nueva Esparta","admin","117513","1862490314"
"Niğde","Nigde","37.9667","34.6792","Turkey","TR","TUR","Niğde","admin","118186","1792867993"
"Aizuwakamatsu","Aizuwakamatsu","37.4948","139.9298","Japan","JP","JPN","Fukushima","","117924","1392003083"
"Aïn Beïda","Ain Beida","35.7964","7.3928","Algeria","DZ","DZA","Oum el Bouaghi","","116064","1012021090"
"Leominster","Leominster","42.5209","-71.7717","United States","US","USA","Massachusetts","","118190","1840000437"
"Lomas de Zamora","Lomas de Zamora","-34.7667","-58.4000","Argentina","AR","ARG","Buenos Aires","minor","112580","1032623086"
"Bama","Bama","11.5189","13.6842","Nigeria","NG","NGA","Borno","minor","118121","1566590565"
"Codó","Codo","-4.4550","-43.8858","Brazil","BR","BRA","Maranhão","","118038","1076537068"
"San Rafael","San Rafael","-34.6000","-68.3333","Argentina","AR","ARG","Mendoza","minor","118009","1032221516"
"Chimaltenango","Chimaltenango","14.6622","-90.8208","Guatemala","GT","GTM","Chimaltenango","admin","116642","1320147012"
"Petržalka","Petrzalka","48.1333","17.1167","Slovakia","SK","SVK","Bratislava","","114000","1703430611"
"Plaridel","Plaridel","14.8869","120.8569","Philippines","PH","PHL","Bulacan","","114432","1608000273"
"Kabwe","Kabwe","-14.4333","28.4500","Zambia","ZM","ZMB","Central","admin","117517","1894711746"
"Tahoua","Tahoua","14.8903","5.2642","Niger","NE","NER","Tahoua","admin","117826","1562214493"
"Nobeoka","Nobeoka","32.5833","131.6667","Japan","JP","JPN","Miyazaki","","117711","1392666259"
"Gogounou","Gogounou","10.8386","2.8361","Benin","BJ","BEN","Alibori","","117793","1204602986"
"Reutlingen","Reutlingen","48.4833","9.2167","Germany","DE","DEU","Baden-Württemberg","minor","116456","1276256795"
"Reutov","Reutov","55.7622","37.8567","Russia","RU","RUS","Moskovskaya Oblast’","","106990","1643138327"
"Agadez","Agadez","16.9959","7.9828","Niger","NE","NER","Agadez","admin","117770","1562789067"
"Uzhhorod","Uzhhorod","48.6239","22.2950","Ukraine","UA","UKR","Zakarpatska Oblast","admin","114897","1804851697"
"Round Rock","Round Rock","30.5270","-97.6642","United States","US","USA","Texas","","117735","1840020879"
"Adilābād","Adilabad","19.6667","78.5333","India","IN","IND","Andhra Pradesh","","117167","1356085840"
"Luanshya","Luanshya","-13.1333","28.4000","Zambia","ZM","ZMB","Copperbelt","","117579","1894410334"
"Kapaklı","Kapakli","41.3333","27.9667","Turkey","TR","TUR","Tekirdağ","minor","116882","1792061507"
"Obninsk","Obninsk","55.0931","36.6106","Russia","RU","RUS","Kaluzhskaya Oblast’","","115029","1643426054"
"Uribia","Uribia","11.7139","-72.2658","Colombia","CO","COL","La Guajira","minor","117674","1170046204"
"Piedecuesta","Piedecuesta","7.0833","-73.0000","Colombia","CO","COL","Santander","minor","117364","1170759043"
"Salmān Bāk","Salman Bak","33.1000","44.5833","Iraq","IQ","IRQ","Baghdād","","117500","1368786572"
"Tangjin","Tangjin","36.8931","126.6281","South Korea","KR","KOR","Chungnam","","117409","1410304730"
"Rouen","Rouen","49.4428","1.0886","France","FR","FRA","Normandie","admin","112321","1250372069"
"Dąbrowa Górnicza","Dabrowa Gornicza","50.3214","19.1872","Poland","PL","POL","Śląskie","minor","116930","1616312023"
"Guiguinto","Guiguinto","14.8333","120.8833","Philippines","PH","PHL","Bulacan","","113415","1608949348"
"Huanren","Huanren","41.2671","125.3529","China","CN","CHN","Liaoning","","116621","1156918189"
"Machakos","Machakos","-1.5167","37.2667","Kenya","KE","KEN","Machakos","admin","114109","1404746781"
"Fujimi","Fujimi","35.8566","139.5492","Japan","JP","JPN","Saitama","","111858","1392003257"
"Catacamas","Catacamas","14.8486","-85.8942","Honduras","HN","HND","Olancho","","117493","1340898308"
"Argenteuil","Argenteuil","48.9500","2.2500","France","FR","FRA","Île-de-France","minor","111038","1250226887"
"Los Baños","Los Banos","14.1667","121.2167","Philippines","PH","PHL","Laguna","","115353","1608920078"
"San Martín","San Martin","-33.0806","-68.4706","Argentina","AR","ARG","Mendoza","minor","117399","1032297325"
"Xai-Xai","Xai-Xai","-25.0500","33.6500","Mozambique","MZ","MOZ","Gaza","admin","116343","1508689070"
"Rochdale","Rochdale","53.6136","-2.1610","United Kingdom","GB","GBR","Rochdale","","110194","1826578106"
"Banfora","Banfora","10.6308","-4.7589","Burkina Faso","BF","BFA","Cascades","admin","117200","1854763822"
"Orsha","Orsha","54.5092","30.4258","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","117225","1112188007"
"Wałbrzych","Walbrzych","50.7667","16.2833","Poland","PL","POL","Dolnośląskie","minor","116069","1616162263"
"Langley","Langley","49.1044","-122.5827","Canada","CA","CAN","British Columbia","","117285","1124000480"
"Narasaraopet","Narasaraopet","16.2500","80.0700","India","IN","IND","Andhra Pradesh","","116250","1356923354"
"Temixco","Temixco","18.8500","-99.2333","Mexico","MX","MEX","Morelos","minor","116143","1484273506"
"Heroica Guaymas","Heroica Guaymas","27.9183","-110.8989","Mexico","MX","MEX","Sonora","minor","117253","1484689704"
"Kyzyl","Kyzyl","51.7167","94.4500","Russia","RU","RUS","Tyva","admin","116015","1643780385"
"Monroe","Monroe","32.5185","-92.0774","United States","US","USA","Louisiana","","117197","1840014881"
"Pollāchi","Pollachi","10.6590","77.0080","India","IN","IND","Tamil Nādu","","116558","1356420688"
"Mendoza","Mendoza","-32.8833","-68.8167","Argentina","AR","ARG","Mendoza","admin","115041","1032433516"
"Nkongsamba","Nkongsamba","4.9500","9.9333","Cameroon","CM","CMR","Littoral","","117063","1120972098"
"Samal","Samal","7.0500","125.7333","Philippines","PH","PHL","Davao del Norte","","116771","1608425228"
"Facatativá","Facatativa","4.8167","-74.3667","Colombia","CO","COL","Cundinamarca","minor","117133","1170830205"
"Guadalajara de Buga","Guadalajara de Buga","3.9000","-76.3019","Colombia","CO","COL","Valle del Cauca","minor","116893","1170472765"
"Cassongue","Cassongue","-11.8333","15.0000","Angola","AO","AGO","Cuanza-Sul","","117000","1024511668"
"Barnāla","Barnala","30.3700","75.5400","India","IN","IND","Punjab","","116450","1356892286"
"Manpo","Manpo","41.1570","126.2900","North Korea","KP","PRK","Chagang","","116760","1408334845"
"Tandil","Tandil","-37.3167","-59.1333","Argentina","AR","ARG","Buenos Aires","minor","116917","1032075057"
"Yavatmāl","Yavatmal","20.4000","78.1333","India","IN","IND","Mahārāshtra","","116551","1356201218"
"Beppu","Beppu","33.2795","131.5000","Japan","JP","JPN","Ōita","","115992","1392115605"
"Araguari","Araguari","-18.6489","-48.1869","Brazil","BR","BRA","Minas Gerais","","116871","1076402865"
"Tatuí","Tatui","-23.3556","-47.8569","Brazil","BR","BRA","São Paulo","","116682","1076587962"
"Cambridge","Cambridge","42.3759","-71.1185","United States","US","USA","Massachusetts","","116892","1840000429"
"Shibuya","Shibuya","35.6536","139.7092","Japan","JP","JPN","Tōkyō","","102056","1392000470"
"Sassari","Sassari","40.7267","8.5592","Italy","IT","ITA","Sardegna","minor","116641","1380371433"
"Conselheiro Lafaiete","Conselheiro Lafaiete","-20.6600","-43.7858","Brazil","BR","BRA","Minas Gerais","","116512","1076456009"
"Bagé","Bage","-31.3308","-54.1069","Brazil","BR","BRA","Rio Grande do Sul","","116794","1076515015"
"Chittaurgarh","Chittaurgarh","24.8894","74.6239","India","IN","IND","Rājasthān","","116406","1356110002"
"Berkane","Berkane","34.9167","-2.3167","Morocco","MA","MAR","Oriental","","109237","1504282363"
"Buzău","Buzau","45.1531","26.8208","Romania","RO","ROU","Buzău","admin","115494","1642794918"
"Santander de Quilichao","Santander de Quilichao","3.0167","-76.4833","Colombia","CO","COL","Cauca","minor","116535","1170277699"
"Helsingborg","Helsingborg","56.0500","12.7167","Sweden","SE","SWE","Skåne","minor","113828","1752789933"
"Coronel","Coronel","-37.0167","-73.2167","Chile","CL","CHL","Biobío","","116262","1152234834"
"Kasese","Kasese","0.1867","30.0881","Uganda","UG","UGA","Kasese","admin","115400","1800924936"
"Clearwater","Clearwater","27.9790","-82.7623","United States","US","USA","Florida","","116616","1840015110"
"Dharān","Dharan","26.8167","87.2833","Nepal","NP","NPL","","","116181","1524888375"
"Lugazi","Lugazi","0.3833","32.9242","Uganda","UG","UGA","Buikwe","","114163","1800606876"
"Tarogong","Tarogong","-7.2150","107.8856","Indonesia","ID","IDN","Jawa Barat","minor","115660","1360293005"
"Boké","Boke","10.9400","-14.3000","Guinea","GN","GIN","Boké","admin","116270","1324037897"
"Smithtown","Smithtown","40.8663","-73.2164","United States","US","USA","New York","","116560","1840058498"
"Himamaylan","Himamaylan","10.1000","122.8667","Philippines","PH","PHL","Negros Occidental","","116240","1608765297"
"San Antonio Enchisi","San Antonio Enchisi","19.7072","-99.7867","Mexico","MX","MEX","México","","110560","1484770456"
"Joünié","Jounie","33.9697","35.6156","Lebanon","LB","LBN","Mont-Liban","minor","102221","1422032780"
"Tecomán","Tecoman","18.9089","-103.8747","Mexico","MX","MEX","Colima","minor","116305","1484348795"
"Bongao","Bongao","5.0292","119.7731","Philippines","PH","PHL","Tawi-Tawi","admin","116118","1608490585"
"Fatsa","Fatsa","41.0333","37.5000","Turkey","TR","TUR","Ordu","minor","116154","1792778914"
"Phatthaya","Phatthaya","12.9357","100.8890","Thailand","TH","THA","Chon Buri","","115840","1764492695"
"Swabi","Swabi","34.1202","72.4702","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","115018","1586628764"
"Qiaotou","Qiaotou","36.9350","101.6736","China","CN","CHN","Qinghai","minor","114712","1156599423"
"Khon Kaen","Khon Kaen","16.4333","102.8333","Thailand","TH","THA","Khon Kaen","admin","115928","1764003406"
"Karatsu","Karatsu","33.4500","129.9667","Japan","JP","JPN","Saga","","116052","1392003116"
"Ramenskoye","Ramenskoye","55.5667","38.2167","Russia","RU","RUS","Moskovskaya Oblast’","","116077","1643438526"
"Ra’s al Khaymah","Ra's al Khaymah","25.7667","55.9500","United Arab Emirates","AE","ARE","Ra’s al Khaymah","admin","115949","1784053892"
"Kilis","Kilis","36.7167","37.1167","Turkey","TR","TUR","Kilis","admin","116034","1792866362"
"Seaside","Seaside","36.6224","-121.8199","United States","US","USA","California","","116189","1840021634"
"Sandvika","Sandvika","59.8833","10.5167","Norway","NO","NOR","Viken","minor","115543","1578704639"
"Adjaouèrè","Adjaouere","7.0000","2.6167","Benin","BJ","BEN","Plateau","","115953","1204225827"
"Itapipoca","Itapipoca","-3.4939","-39.5789","Brazil","BR","BRA","Ceará","","116065","1076980375"
"Guanabacoa","Guanabacoa","23.1252","-82.3007","Cuba","CU","CUB","La Habana","","115180","1192000780"
"West Jordan","West Jordan","40.6024","-112.0008","United States","US","USA","Utah","","116045","1840021388"
"Sabhā","Sabha","27.0389","14.4264","Libya","LY","LBY","Sabhā","admin","116016","1434044820"
"Shacheng","Shacheng","40.4027","115.5126","China","CN","CHN","Hebei","minor","114569","1156278933"
"Sayhāt","Sayhat","26.4750","50.0417","Saudi Arabia","SA","SAU","Ash Sharqīyah","","115000","1682602908"
"Kars","Kars","40.6078","43.0958","Turkey","TR","TUR","Kars","admin","115891","1792000464"
"Valle de La Pascua","Valle de La Pascua","9.2033","-66.0103","Venezuela","VE","VEN","Guárico","minor","115902","1862904072"
"Parachinar","Parachinar","33.9000","70.1000","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","115502","1586337795"
"Richardson","Richardson","32.9716","-96.7093","United States","US","USA","Texas","","115904","1840020710"
"San Ildefonso","San Ildefonso","15.0789","120.9419","Philippines","PH","PHL","Bulacan","","115713","1608068184"
"Kovilpatti","Kovilpatti","9.1744","77.8688","India","IN","IND","Tamil Nādu","","115434","1356026173"
"West Palm Beach","West Palm Beach","26.7469","-80.1320","United States","US","USA","Florida","","115877","1840015993"
"Temple","Temple","31.1068","-97.3891","United States","US","USA","Texas","","115809","1840022154"
"Nasushiobara","Nasushiobara","36.9617","140.0461","Japan","JP","JPN","Tochigi","","115602","1392841957"
"Tōkai","Tokai","35.0231","136.9022","Japan","JP","JPN","Aichi","","113144","1392003126"
"Durrës","Durres","41.3111","19.4392","Albania","AL","ALB","Durrës","admin","113249","1008173690"
"Burlington","Burlington","44.4876","-73.2316","United States","US","USA","Vermont","","115682","1840002310"
"Richmond","Richmond","37.9477","-122.3389","United States","US","USA","California","","115677","1840020277"
"Turbaco","Turbaco","10.3500","-75.3333","Colombia","CO","COL","Bolívar","minor","115000","1170194197"
"Pedro Juan Caballero","Pedro Juan Caballero","-22.5300","-55.7500","Paraguay","PY","PRY","Amambay","admin","115583","1600089732"
"Rawasari","Rawasari","-7.5800","108.8828","Indonesia","ID","IDN","Jawa Tengah","","114869","1360675211"
"Três Lagoas","Tres Lagoas","-20.7511","-51.6783","Brazil","BR","BRA","Mato Grosso do Sul","","115561","1076281782"
"Berdiansk","Berdiansk","46.7556","36.7889","Ukraine","UA","UKR","Zaporizka Oblast","minor","114205","1804599785"
"Westminster","Westminster","39.8837","-105.0624","United States","US","USA","Colorado","","115535","1840021423"
"Kōenchō","Koencho","43.8028","143.8944","Japan","JP","JPN","Hokkaidō","","115441","1392099278"
"Mangaldan","Mangaldan","16.0700","120.4025","Philippines","PH","PHL","Pangasinan","","113185","1608105622"
"Niihama","Niihama","33.9667","133.2833","Japan","JP","JPN","Ehime","","114971","1392682431"
"Pottstown","Pottstown","40.2508","-75.6445","United States","US","USA","Pennsylvania","","115413","1840001373"
"Bānsbāria","Bansbaria","22.9700","88.4000","India","IN","IND","West Bengal","","103920","1356897672"
"Puerto Madryn","Puerto Madryn","-42.7667","-65.0500","Argentina","AR","ARG","Chubut","minor","115353","1032351657"
"Brandon","Brandon","27.9367","-82.3000","United States","US","USA","Florida","","115330","1840014151"
"Sliven","Sliven","42.6833","26.3333","Bulgaria","BG","BGR","Sliven","admin","115241","1100110215"
"Yessentuki","Yessentuki","44.0333","42.8500","Russia","RU","RUS","Stavropol’skiy Kray","","113056","1643737725"
"Rock Hill","Rock Hill","34.9415","-81.0241","United States","US","USA","South Carolina","","115292","1840014618"
"Iriga City","Iriga City","13.4231","123.4122","Philippines","PH","PHL","Camarines Sur","","114457","1608112592"
"Jönköping","Jonkoping","57.7828","14.1606","Sweden","SE","SWE","Jönköping","admin","112766","1752079041"
"Meridian","Meridian","43.6116","-116.3977","United States","US","USA","Idaho","","115227","1840020046"
"Sano","Sano","36.3145","139.5783","Japan","JP","JPN","Tochigi","","114842","1392791927"
"Ariana","Ariana","36.8625","10.1956","Tunisia","TN","TUN","L’Ariana","admin","114486","1788682546"
"Sloviansk","Sloviansk","48.8533","37.6059","Ukraine","UA","UKR","Donetska Oblast","","113196","1804877044"
"Koblenz","Koblenz","50.3597","7.5978","Germany","DE","DEU","Rhineland-Palatinate","minor","114024","1276605495"
"Oktyabr’skiy","Oktyabr'skiy","54.4667","53.4667","Russia","RU","RUS","Bashkortostan","","113929","1643301615"
"Al Muḑaybī","Al Mudaybi","22.5667","58.1167","Oman","OM","OMN","Shamāl ash Sharqīyah","","115040","1512883829"
"Sogamoso","Sogamoso","5.7167","-72.9208","Colombia","CO","COL","Boyacá","minor","114486","1170000109"
"Salto","Salto","-23.2008","-47.2869","Brazil","BR","BRA","São Paulo","","114171","1076427264"
"Zhijiang","Zhijiang","27.4367","109.6780","China","CN","CHN","Hunan","minor","113907","1156128936"
"Hatsukaichi","Hatsukaichi","34.3500","132.3333","Japan","JP","JPN","Hiroshima","","114699","1392723053"
"Sakiet ed Daier","Sakiet ed Daier","34.8000","10.7800","Tunisia","TN","TUN","Sfax","","113776","1788449518"
"Coeur d'Alene","Coeur d'Alene","47.7040","-116.7936","United States","US","USA","Idaho","","114880","1840018402"
"Carlsbad","Carlsbad","33.1246","-117.2834","United States","US","USA","California","","114858","1840019352"
"Las Delicias","Las Delicias","28.2000","-105.5000","Mexico","MX","MEX","Chihuahua","","114783","1484513329"
"Sach’on","Sach'on","35.0667","128.0833","South Korea","KR","KOR","Gyeongnam","","114556","1410555312"
"Bijeljina","Bijeljina","44.7500","19.2167","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","114663","1070522751"
"Lowell","Lowell","42.6389","-71.3217","United States","US","USA","Massachusetts","","114804","1840000426"
"Heshan","Heshan","23.8163","108.8847","China","CN","CHN","Guangxi","minor","114496","1156525318"
"Erlangen","Erlangen","49.5833","11.0167","Germany","DE","DEU","Bavaria","minor","113292","1276339482"
"Bremerhaven","Bremerhaven","53.5500","8.5833","Germany","DE","DEU","Bremen","","113634","1276991229"
"Ciudad de la Costa","Ciudad de la Costa","-34.8167","-55.9500","Uruguay","UY","URY","Canelones","","112449","1858042396"
"Waterloo","Waterloo","42.4918","-92.3522","United States","US","USA","Iowa","","114594","1840000440"
"Kaikkudi","Kaikkudi","9.8930","78.7313","India","IN","IND","Tamil Nādu","","114306","1356219581"
"Bernal","Bernal","-34.7000","-58.2833","Argentina","AR","ARG","Buenos Aires","","109914","1032030395"
"Kamagaya","Kamagaya","35.7768","140.0008","Japan","JP","JPN","Chiba","","109370","1392541992"
"North Charleston","North Charleston","32.9067","-80.0722","United States","US","USA","South Carolina","","114542","1840015164"
"Alphen aan den Rijn","Alphen aan den Rijn","52.1333","4.6667","Netherlands","NL","NLD","Zuid-Holland","minor","112587","1528593016"
"La Piedad","La Piedad","20.3333","-102.0167","Mexico","MX","MEX","Michoacán","minor","106490","1484779296"
"Hammersmith","Hammersmith","51.4928","-0.2229","United Kingdom","GB","GBR","Hammersmith and Fulham","","95996","1826090130"
"Putatan","Putatan","14.3984","121.0364","Philippines","PH","PHL","Muntinlupa","","99725","1608160137"
"Esteban Echeverría","Esteban Echeverria","-34.8167","-58.4667","Argentina","AR","ARG","Buenos Aires","","109644","1032069640"
"Ambovombe","Ambovombe","-25.1764","46.0833","Madagascar","MG","MDG","Toliara","minor","114230","1450135666"
"San Juan","San Juan","13.8260","121.3960","Philippines","PH","PHL","Batangas","minor","114068","1608474998"
"Sakété","Sakete","6.7364","2.6581","Benin","BJ","BEN","Plateau","admin","114207","1204601704"
"Espejo","Espejo","-33.5167","-70.6917","Chile","CL","CHL","Región Metropolitana","","98804","1152487376"
"Gilroy","Gilroy","37.0046","-121.5854","United States","US","USA","California","","114461","1840020327"
"Bāgh-e Malek","Bagh-e Malek","31.5231","49.8861","Iran","IR","IRN","Khūzestān","minor","114343","1364455619"
"Rotherham","Rotherham","53.4300","-1.3570","United Kingdom","GB","GBR","Rotherham","","109691","1826763937"
"Kakegawa","Kakegawa","34.7687","137.9984","Japan","JP","JPN","Shizuoka","","113954","1392003270"
"Downey","Downey","33.9379","-118.1311","United States","US","USA","California","","114293","1840019245"
"Ifanhim","Ifanhim","6.6667","2.7167","Benin","BJ","BEN","Plateau","","113749","1204693168"
"Gresham","Gresham","45.5021","-122.4413","United States","US","USA","Oregon","","114164","1840019939"
"Bintulu","Bintulu","3.1700","113.0300","Malaysia","MY","MYS","Sarawak","","114058","1458164361"
"Elgin","Elgin","42.0383","-88.3240","United States","US","USA","Illinois","","114156","1840008134"
"Saanich","Saanich","48.4840","-123.3810","Canada","CA","CAN","British Columbia","","114148","1124000949"
"Daet","Daet","14.1142","122.9564","Philippines","PH","PHL","Camarines Norte","admin","111700","1608562606"
"Āsela","Asela","7.9500","39.1167","Ethiopia","ET","ETH","Oromīya","","110088","1231347519"
"Matéri","Materi","10.6978","1.0633","Benin","BJ","BEN","Atacora","","113958","1204459888"
"Kuşadası","Kusadasi","37.8597","27.2597","Turkey","TR","TUR","İzmir","minor","113580","1792759260"
"Kristiansand","Kristiansand","58.1472","7.9972","Norway","NO","NOR","Agder","minor","113737","1578642977"
"Pul-e Khumrī","Pul-e Khumri","35.9500","68.7000","Afghanistan","AF","AFG","Baghlān","admin","113500","1004123527"
"Recklinghausen","Recklinghausen","51.5850","7.1619","Germany","DE","DEU","North Rhine-Westphalia","minor","112267","1276179876"
"Nagahama","Nagahama","35.3833","136.2833","Japan","JP","JPN","Shiga","","113740","1392190380"
"Tawau","Tawau","4.2448","117.8911","Malaysia","MY","MYS","Sabah","","113809","1458745722"
"Walthamstow","Walthamstow","51.5840","-0.0210","United Kingdom","GB","GBR","Waltham Forest","","109424","1826009890"
"Quilengues","Quilengues","-14.0814","14.0764","Angola","AO","AGO","Huíla","","113825","1024895987"
"Mulhouse","Mulhouse","47.7500","7.3400","France","FR","FRA","Grand Est","minor","108942","1250218452"
"Mazhang","Mazhang","21.2757","110.3221","China","CN","CHN","Guangdong","minor","107819","1156411606"
"Paarl","Paarl","-33.7242","18.9558","South Africa","ZA","ZAF","Western Cape","","112045","1710440011"
"Hōfu","Hofu","34.0500","131.5667","Japan","JP","JPN","Yamaguchi","","113170","1392985430"
"Hikone","Hikone","35.2667","136.2667","Japan","JP","JPN","Shiga","","113191","1392717059"
"Īrānshahr","Iranshahr","27.2025","60.6847","Iran","IR","IRN","Sīstān va Balūchestān","minor","113750","1364413748"
"Maranguape","Maranguape","-3.8900","-38.6858","Brazil","BR","BRA","Ceará","","113561","1076845536"
"Rosario","Rosario","14.4167","120.8500","Philippines","PH","PHL","Cavite","","110807","1608718800"
"Ndali","Ndali","9.8608","2.7181","Benin","BJ","BEN","Borgou","","113604","1204045508"
"Ubá","Uba","-21.1200","-42.9428","Brazil","BR","BRA","Minas Gerais","","113300","1076389529"
"Bukittinggi","Bukittinggi","-0.3097","100.3753","Indonesia","ID","IDN","Sumatera Barat","","113326","1360552560"
"Caraguatatuba","Caraguatatuba","-23.6200","-45.4128","Brazil","BR","BRA","São Paulo","","113317","1076107372"
"Manolo Fortich","Manolo Fortich","8.3675","124.8656","Philippines","PH","PHL","Bukidnon","","113200","1608811201"
"Midyat","Midyat","37.4167","41.3697","Turkey","TR","TUR","Mardin","minor","113367","1792815153"
"Mungo","Mungo","-11.6667","16.1667","Angola","AO","AGO","Huambo","","113417","1024065318"
"Arafat","Arafat","18.0464","-15.9719","Mauritania","MR","MRT","Nouakchott Sud","admin","102169","1478174698"
"Tecpán Guatemala","Tecpan Guatemala","14.7667","-91.0000","Guatemala","GT","GTM","Chimaltenango","minor","112864","1320835574"
"Jendouba","Jendouba","36.5000","8.7833","Tunisia","TN","TUN","Jendouba","admin","113116","1788952846"
"Alkmaar","Alkmaar","52.6333","4.7500","Netherlands","NL","NLD","Noord-Holland","minor","109896","1528566376"
"Sa-ch’on","Sa-ch'on","35.2347","128.3575","South Korea","KR","KOR","Gyeongnam","","113335","1410369367"
"Hualien","Hualien","23.9722","121.6064","Taiwan","TW","TWN","Hualien","admin","110000","1158174293"
"Ioánnina","Ioannina","39.6636","20.8522","Greece","GR","GRC","Ípeiros","admin","113094","1300317204"
"Akçakale","Akcakale","36.7108","38.9478","Turkey","TR","TUR","Şanlıurfa","minor","113194","1792050087"
"Libmanan","Libmanan","13.6964","123.0600","Philippines","PH","PHL","Camarines Sur","","112994","1608310701"
"Bergisch Gladbach","Bergisch Gladbach","50.9918","7.1303","Germany","DE","DEU","North Rhine-Westphalia","minor","111966","1276074373"
"Euriápolis","Euriapolis","-16.3778","-39.5800","Brazil","BR","BRA","Bahia","","113191","1076770753"
"Osmānābād","Osmanabad","18.1667","76.0500","India","IN","IND","Mahārāshtra","","112085","1356005329"
"Remscheid","Remscheid","51.1833","7.2000","Germany","DE","DEU","North Rhine-Westphalia","minor","111770","1276043920"
"Novyy Urengoy","Novyy Urengoy","66.0833","76.6833","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","113254","1643427847"
"Mostar","Mostar","43.3436","17.8075","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","113169","1070000220"
"Madrid","Madrid","4.7344","-74.2683","Colombia","CO","COL","Cundinamarca","minor","112254","1170693388"
"Burdur","Burdur","37.7194","30.2833","Turkey","TR","TUR","Burdur","admin","113077","1792413556"
"La Gi","La Gi","10.6600","107.7719","Vietnam","VN","VNM","Bình Thuận","","112558","1704637606"
"Hosan","Hosan","36.2000","127.0833","South Korea","KR","KOR","Chungnam","","112939","1410009966"
"Tayabas","Tayabas","14.0167","121.5833","Philippines","PH","PHL","Quezon","","112658","1608195528"
"Mopti","Mopti","14.4900","-4.1800","Mali","ML","MLI","Mopti","admin","108456","1466352252"
"Sokodé","Sokode","8.9833","1.1333","Togo","TG","TGO","Centrale","admin","113000","1768875145"
"Castelar","Castelar","-34.6667","-58.6667","Argentina","AR","ARG","Buenos Aires","","107786","1032123891"
"Sāhibganj","Sahibganj","25.2500","87.6500","India","IN","IND","Jhārkhand","","111954","1356080912"
"Khenchela","Khenchela","35.4167","7.1333","Algeria","DZ","DZA","Khenchela","admin","108580","1012495148"
"Vicenza","Vicenza","45.5500","11.5500","Italy","IT","ITA","Veneto","minor","111620","1380072199"
"Balāngīr","Balangir","20.7200","83.4800","India","IN","IND","Odisha","","112544","1356034887"
"Utica","Utica","43.0962","-75.2261","United States","US","USA","New York","","112948","1840002746"
"San Carlos de Bariloche","San Carlos de Bariloche","-41.1500","-71.3000","Argentina","AR","ARG","Río Negro","minor","112887","1032111842"
"Otaru","Otaru","43.1907","140.9944","Japan","JP","JPN","Hokkaidō","","112452","1392240421"
"Goth Tando Sumro","Goth Tando Sumro","25.4500","68.7167","Pakistan","PK","PAK","Sindh","","112335","1586855342"
"Rājpura","Rajpura","30.4840","76.5940","India","IN","IND","Punjab","","112193","1356280770"
"Kaposvár","Kaposvar","46.3638","17.7823","Hungary","HU","HUN","Somogy","admin","112253","1348114190"
"Broken Arrow","Broken Arrow","36.0380","-95.7806","United States","US","USA","Oklahoma","","112751","1840019059"
"Higashiōmi","Higashiomi","35.1167","136.2000","Japan","JP","JPN","Shiga","","112459","1392117204"
"Brovary","Brovary","50.5111","30.7900","Ukraine","UA","UKR","Kyivska Oblast","minor","109473","1804386597"
"Santa Lucía","Santa Lucia","10.2606","-66.6639","Venezuela","VE","VEN","Miranda","minor","112357","1862614387"
"Mawanella","Mawanella","7.2534","80.4466","Sri Lanka","LK","LKA","Sabaragamuwa","","111727","1144039144"
"Kuningan","Kuningan","-6.9764","108.4844","Indonesia","ID","IDN","Jawa Barat","minor","111742","1360454767"
"Carora","Carora","10.1692","-70.0783","Venezuela","VE","VEN","Lara","minor","112600","1862485109"
"Madhavaram","Madhavaram","13.1482","80.2314","India","IN","IND","Tamil Nādu","","100442","1356330429"
"Laoag","Laoag","18.1978","120.5936","Philippines","PH","PHL","Ilocos Norte","admin","111651","1608043510"
"Tangjia","Tangjia","22.3566","113.5919","China","CN","CHN","Guangdong","","109152","1156508113"
"Champdani","Champdani","22.8000","88.3700","India","IN","IND","West Bengal","","111251","1356132208"
"Ōshū","Oshu","39.1445","141.1391","Japan","JP","JPN","Iwate","","112402","1392826391"
"Zahlé","Zahle","33.8333","35.9167","Lebanon","LB","LBN","Béqaa","admin","100000","1422031285"
"Mahāsamund","Mahasamund","21.1100","82.1000","India","IN","IND","Chhattīsgarh","","112228","1356258690"
"Habikino","Habikino","34.5500","135.6000","Japan","JP","JPN","Ōsaka","","108373","1392918777"
"Kamyshin","Kamyshin","50.0833","45.4000","Russia","RU","RUS","Volgogradskaya Oblast’","","111100","1643117920"
"Campo Largo","Campo Largo","-25.4589","-49.5278","Brazil","BR","BRA","Paraná","","112377","1076072105"
"Shujālpur","Shujalpur","23.4000","76.7200","India","IN","IND","Madhya Pradesh","","112000","1356623938"
"Doncaster","Doncaster","53.5231","-1.1339","United Kingdom","GB","GBR","Doncaster","","109805","1826979494"
"Dolgoprudnyy","Dolgoprudnyy","55.9333","37.5000","Russia","RU","RUS","Moskovskaya Oblast’","","108861","1643283176"
"Shkodër","Shkoder","42.0681","19.5119","Albania","AL","ALB","Shkodër","admin","112276","1008977725"
"Jena","Jena","50.9272","11.5861","Germany","DE","DEU","Thuringia","minor","111407","1276659978"
"Trincomalee","Trincomalee","8.5667","81.2333","Sri Lanka","LK","LKA","Eastern","admin","99135","1144417074"
"Subic","Subic","14.8769","120.2328","Philippines","PH","PHL","Zambales","","111912","1608633194"
"Baleraja","Baleraja","-6.5167","107.9169","Indonesia","ID","IDN","Jawa Barat","","111475","1360384725"
"Olmaliq","Olmaliq","40.8500","69.6000","Uzbekistan","UZ","UZB","Toshkent","","112078","1860946502"
"Kawit","Kawit","14.4333","120.9000","Philippines","PH","PHL","Cavite","","107535","1608411157"
"Bou Saâda","Bou Saada","35.2083","4.1739","Algeria","DZ","DZA","M’sila","","111787","1012931222"
"Pochuta","Pochuta","14.5500","-91.0833","Guatemala","GT","GTM","Chimaltenango","minor","112139","1320115832"
"Hezuo","Hezuo","34.9984","102.9100","China","CN","CHN","Gansu","minor","112173","1156739933"
"Estelí","Esteli","13.0933","-86.3547","Nicaragua","NI","NIC","Estelí","admin","112084","1558801854"
"Jaén","Jaen","37.7667","-3.7711","Spain","ES","ESP","Andalusia","minor","111932","1724699771"
"Araruama","Araruama","-22.8728","-42.3428","Brazil","BR","BRA","Rio de Janeiro","","112008","1076900900"
"Dassa-Zoumé","Dassa-Zoume","7.7500","2.1833","Benin","BJ","BEN","Collines","","112118","1204114587"
"Costa Mesa","Costa Mesa","33.6667","-117.9135","United States","US","USA","California","","112148","1840019326"
"Chico","Chico","39.7578","-121.8161","United States","US","USA","California","","112132","1840018778"
"Baybay","Baybay","10.6833","124.8000","Philippines","PH","PHL","Leyte","","111848","1608960920"
"Corumbá","Corumba","-19.0089","-57.6528","Brazil","BR","BRA","Mato Grosso do Sul","","112058","1076131775"
"Nancy","Nancy","48.6936","6.1846","France","FR","FRA","Grand Est","minor","105058","1250925250"
"Kouandé","Kouande","10.3317","1.6914","Benin","BJ","BEN","Atacora","","112014","1204029400"
"Zhukovskiy","Zhukovskiy","55.5972","38.1203","Russia","RU","RUS","Moskovskaya Oblast’","","107994","1643012071"
"Nsukka","Nsukka","6.8567","7.3958","Nigeria","NG","NGA","Enugu","minor","111017","1566882360"
"Sioux City","Sioux City","42.4959","-96.3901","United States","US","USA","Iowa","","111924","1840000447"
"Ichinoseki","Ichinoseki","38.9347","141.1266","Japan","JP","JPN","Iwate","","111824","1392003094"
"Funchal","Funchal","32.6500","-16.9167","Portugal","PT","PRT","Madeira","admin","111892","1620995356"
"Wythenshawe","Wythenshawe","53.3920","-2.2640","United Kingdom","GB","GBR","Manchester","","110000","1826781713"
"Colatina","Colatina","-19.5389","-40.6308","Brazil","BR","BRA","Espírito Santo","","111788","1076751743"
"Maidstone","Maidstone","51.2720","0.5290","United Kingdom","GB","GBR","Kent","","107627","1826000009"
"Sutton Coldfield","Sutton Coldfield","52.5630","-1.8220","United Kingdom","GB","GBR","Birmingham","","109899","1826034374"
"League City","League City","29.4874","-95.1087","United States","US","USA","Texas","","111847","1840020973"
"Qalyūb","Qalyub","30.1997","31.2053","Egypt","EG","EGY","Al Qalyūbīyah","","106804","1818824672"
"Parral","Parral","26.9333","-105.6667","Mexico","MX","MEX","Chihuahua","minor","104836","1484607603"
"Terni","Terni","42.5619","12.6414","Italy","IT","ITA","Umbria","minor","111189","1380323915"
"Miami Gardens","Miami Gardens","25.9433","-80.2426","United States","US","USA","Florida","","111706","1840015151"
"Tatalon","Tatalon","14.6242","121.0153","Philippines","PH","PHL","Quezon","","63129","1608367432"
"Parintins","Parintins","-2.6278","-56.7358","Brazil","BR","BRA","Amazonas","","111575","1076090375"
"Trier","Trier","49.7567","6.6414","Germany","DE","DEU","Rhineland-Palatinate","minor","110636","1276211430"
"Terrebonne","Terrebonne","45.7000","-73.6333","Canada","CA","CAN","Quebec","","111575","1124993674"
"Namur","Namur","50.4667","4.8667","Belgium","BE","BEL","Wallonia","admin","110939","1056954156"
"Changbang","Changbang","30.4555","120.4433","China","CN","CHN","Zhejiang","","110352","1156755405"
"Pulilan","Pulilan","14.9020","120.8490","Philippines","PH","PHL","Bulacan","","108836","1608740996"
"Murom","Murom","55.5667","42.0333","Russia","RU","RUS","Vladimirskaya Oblast’","","109072","1643769134"
"Kalmunai","Kalmunai","7.4167","81.8167","Sri Lanka","LK","LKA","Eastern","","106780","1144059288"
"Sabara Bangou","Sabara Bangou","15.1177","1.9960","Niger","NE","NER","Tillabéri","","111490","1562582841"
"Manzini","Manzini","-26.4833","31.3667","Swaziland","SZ","SWZ","Manzini","admin","110508","1748177431"
"Tondabayashichō","Tondabayashicho","34.5000","135.6000","Japan","JP","JPN","Ōsaka","","108735","1392173163"
"Pingquan","Pingquan","40.9937","118.6735","China","CN","CHN","Hebei","minor","110646","1156442510"
"Kazo","Kazo","36.1314","139.6018","Japan","JP","JPN","Saitama","","110622","1392384497"
"Pompano Beach","Pompano Beach","26.2428","-80.1312","United States","US","USA","Florida","","111423","1840015143"
"Khardah","Khardah","22.7200","88.3800","India","IN","IND","West Bengal","","108496","1356765227"
"Ōmuta","Omuta","33.0333","130.4500","Japan","JP","JPN","Fukuoka","","110054","1392003164"
"Villa Mercedes","Villa Mercedes","-33.6667","-65.4667","Argentina","AR","ARG","San Luis","minor","111391","1032858021"
"Jawhar","Jawhar","2.7833","45.5000","Somalia","SO","SOM","Shabeellaha Dhexe","admin","111308","1706737655"
"Winterthur","Winterthur","47.4989","8.7286","Switzerland","CH","CHE","Zürich","minor","109775","1756362130"
"Tuzla","Tuzla","44.5381","18.6761","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","110979","1070117835"
"Tarnów","Tarnow","50.0125","20.9886","Poland","PL","POL","Małopolskie","minor","110956","1616375879"
"Gafsa","Gafsa","34.4167","8.7833","Tunisia","TN","TUN","Gafsa","admin","111170","1788454569"
"Ash Shaykh ‘Uthmān","Ash Shaykh \`Uthman","12.8866","45.0279","Yemen","YE","YEM","‘Adan","minor","105248","1887195126"
"Nkpor","Nkpor","6.1500","6.8333","Nigeria","NG","NGA","Anambra","","109377","1566172737"
"Ḩaraḑ","Harad","24.1456","49.0653","Saudi Arabia","SA","SAU","Ash Sharqīyah","","111214","1682802077"
"Khushab","Khushab","32.2986","72.3508","Pakistan","PK","PAK","Punjab","minor","110868","1586738716"
"Nawāda","Nawada","24.8800","85.5300","India","IN","IND","Bihār","","109141","1356092302"
"Muktsar","Muktsar","30.4743","74.5166","India","IN","IND","Punjab","","110424","1356134091"
"Basingstoke","Basingstoke","51.2667","-1.0876","United Kingdom","GB","GBR","Hampshire","","107355","1826924980"
"Kintampo","Kintampo","8.0522","-1.7347","Ghana","GH","GHA","Bono East","","111000","1288911745"
"Catalão","Catalao","-18.1700","-47.9419","Brazil","BR","BRA","Goiás","","110983","1076169871"
"Hounslow","Hounslow","51.4668","-0.3750","United Kingdom","GB","GBR","Hounslow","","103337","1826943293"
"Ahuachapán","Ahuachapan","13.9167","-89.8500","El Salvador","SV","SLV","Ahuachapán","admin","110511","1222480039"
"Al Aḩad al Masāriḩah","Al Ahad al Masarihah","16.7097","42.9550","Saudi Arabia","SA","SAU","Jāzān","","110710","1682620364"
"Puerto Barrios","Puerto Barrios","15.7300","-88.6000","Guatemala","GT","GTM","Izabal","admin","110846","1320517532"
"Fasā","Fasa","28.9383","53.6483","Iran","IR","IRN","Fārs","minor","110825","1364549592"
"As Salamīyah","As Salamiyah","35.0118","37.0525","Syria","SY","SYR","Ḩamāh","minor","110683","1760391067"
"Pelabuhanratu","Pelabuhanratu","-6.9878","106.5439","Indonesia","ID","IDN","Jawa Barat","minor","109523","1360747715"
"Los Minas","Los Minas","18.5000","-69.9833","Dominican Republic","DO","DOM","Ozama","","104846","1214457470"
"Shinyanga","Shinyanga","-3.6619","33.4231","Tanzania","TZ","TZA","Shinyanga","admin","107362","1834972707"
"Sonsonate","Sonsonate","13.7167","-89.7167","El Salvador","SV","SLV","Sonsonate","admin","110501","1222380208"
"Masindi","Masindi","1.6836","31.7222","Uganda","UG","UGA","Masindi","admin","110500","1800409287"
"Kiffa","Kiffa","16.6300","-11.4000","Mauritania","MR","MRT","Assaba","admin","110714","1478465771"
"Betūl Bazār","Betul Bazar","21.9200","77.9000","India","IN","IND","Madhya Pradesh","","110513","1356866032"
"Nikopol","Nikopol","47.5667","34.4000","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","110669","1804000135"
"Ferozepore","Ferozepore","30.9166","74.6000","India","IN","IND","Punjab","","110313","1356060515"
"Dali","Dali","34.7953","109.9378","China","CN","CHN","Shaanxi","minor","109696","1156708054"
"Yenangyaung","Yenangyaung","20.4597","94.8742","Myanmar","MM","MMR","Magway","","110553","1104118838"
"Quíbor","Quibor","9.9281","-69.5778","Venezuela","VE","VEN","Lara","minor","110536","1862178539"
"Ourinhos","Ourinhos","-22.9744","-49.8719","Brazil","BR","BRA","São Paulo","","110282","1076551408"
"Franceville","Franceville","-1.6333","13.5833","Gabon","GA","GAB","Haut-Ogooué","admin","110568","1266537692"
"San Buenaventura","San Buenaventura","34.2741","-119.2314","United States","US","USA","California","","110572","1840037414"
"Socopó","Socopo","8.2322","-70.8206","Venezuela","VE","VEN","Barinas","minor","110512","1862619610"
"Mascara","Mascara","35.4000","0.1333","Algeria","DZ","DZA","Mascara","admin","108587","1012738269"
"Genhe","Genhe","50.7783","121.5213","China","CN","CHN","Inner Mongolia","minor","110438","1156133257"
"Everett","Everett","47.9525","-122.1669","United States","US","USA","Washington","","110438","1840019785"
"Montego Bay","Montego Bay","18.4667","-77.9167","Jamaica","JM","JAM","Saint James","admin","110115","1388955058"
"El Centro","El Centro","32.7865","-115.5595","United States","US","USA","California","","110434","1840020630"
"Ciamis","Ciamis","-7.3281","108.3336","Indonesia","ID","IDN","Jawa Barat","minor","109839","1360022009"
"Caen","Caen","49.1800","-0.3700","France","FR","FRA","Normandie","minor","106230","1250001272"
"Jeonghae","Jeonghae","35.5667","126.8500","South Korea","KR","KOR","Jeonbuk","","110194","1410005963"
"Msaken","Msaken","35.7333","10.5833","Tunisia","TN","TUN","Sousse","","110000","1788688595"
"Bāneh","Baneh","35.9975","45.8853","Iran","IR","IRN","Kordestān","minor","110218","1364786972"
"Shirayamamachi","Shirayamamachi","36.5166","136.5656","Japan","JP","JPN","Ishikawa","","110135","1392840575"
"Itele","Itele","6.7667","4.0625","Nigeria","NG","NGA","Ogun","","110196","1566450486"
"Sugar Land","Sugar Land","29.5935","-95.6358","United States","US","USA","Texas","","110272","1840022217"
"Tinaquillo","Tinaquillo","9.9167","-68.3000","Venezuela","VE","VEN","Cojedes","minor","110000","1862118872"
"Xishancun","Xishancun","23.6014","116.3257","China","CN","CHN","Guangdong","","108592","1156055006"
"Drammen","Drammen","59.7378","10.2050","Norway","NO","NOR","Viken","minor","109416","1578937195"
"El Monte","El Monte","34.0739","-118.0291","United States","US","USA","California","","110144","1840020480"
"Marugame","Marugame","34.2833","133.8000","Japan","JP","JPN","Kagawa","","109165","1392003524"
"Bangaon","Bangaon","23.0435","88.8318","India","IN","IND","West Bengal","","108864","1356624441"
"Qal‘at Sukkar","Qal\`at Sukkar","31.8589","46.0772","Iraq","IQ","IRQ","Dhī Qār","","110000","1368815122"
"Milton","Milton","43.5083","-79.8833","Canada","CA","CAN","Ontario","","110128","1124001426"
"Yŏju","Yoju","37.3000","127.6333","South Korea","KR","KOR","Gyeonggi","","109937","1410007012"
"Lewisville","Lewisville","33.0454","-96.9815","United States","US","USA","Texas","","110077","1840020639"
"Retalhuleu","Retalhuleu","14.5333","-91.6833","Guatemala","GT","GTM","Retalhuleu","admin","109935","1320999503"
"Tacurong","Tacurong","6.6800","124.6700","Philippines","PH","PHL","Sultan Kudarat","","109319","1608479806"
"Navapolatsk","Navapolatsk","55.5333","28.6500","Belarus","BY","BLR","Vitsyebskaya Voblasts’","","108000","1112965860"
"Pisco","Pisco","-13.7167","-76.2000","Peru","PE","PER","Ica","","109965","1604438126"
"Dera Ismail Khan","Dera Ismail Khan","31.8314","70.9019","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","109686","1586008360"
"Labé","Labe","11.3167","-12.2833","Guinea","GN","GIN","Labé","admin","107695","1324900784"
"Altamira","Altamira","-3.2028","-52.2058","Brazil","BR","BRA","Pará","","109938","1076710878"
"Cavite City","Cavite City","14.4833","120.9000","Philippines","PH","PHL","Cavite","","100674","1608639398"
"Yevpatoriia","Yevpatoriia","45.1939","33.3681","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","108248","1804279364"
"Taitung","Taitung","22.7583","121.1444","Taiwan","TW","TWN","Taitung","admin","108905","1158804050"
"Itabira","Itabira","-19.6189","-43.2269","Brazil","BR","BRA","Minas Gerais","","109783","1076783407"
"Malacatán","Malacatan","14.9106","-92.0581","Guatemala","GT","GTM","San Marcos","minor","109318","1320759841"
"Al Fqih Ben Çalah","Al Fqih Ben Calah","32.5000","-6.5333","Morocco","MA","MAR","Béni Mellal-Khénifra","","102019","1504975687"
"Naujan","Naujan","13.3233","121.3028","Philippines","PH","PHL","Oriental Mindoro","","109587","1608362602"
"Quezon","Quezon","7.7306","125.1000","Philippines","PH","PHL","Bukidnon","","109624","1608600962"
"Sandachō","Sandacho","34.8833","135.2333","Japan","JP","JPN","Hyōgo","","109243","1392493731"
"Uitenhage","Uitenhage","-33.7500","25.4000","South Africa","ZA","ZAF","Eastern Cape","","103639","1710276157"
"Aguachica","Aguachica","8.3167","-73.6333","Colombia","CO","COL","Cesar","minor","109621","1170152591"
"Glan","Glan","5.8167","125.2000","Philippines","PH","PHL","Sarangani","","109547","1608947570"
"Carmona","Carmona","14.3167","121.0500","Philippines","PH","PHL","Cavite","","106256","1608216694"
"Bayugan","Bayugan","8.7100","125.7500","Philippines","PH","PHL","Agusan del Sur","","109499","1608483872"
"Datia","Datia","25.6700","78.4700","India","IN","IND","Madhya Pradesh","","109236","1356903057"
"K’ebrī Dehar","K'ebri Dehar","6.7333","44.2667","Ethiopia","ET","ETH","Sumalē","","109571","1231393469"
"Sehore","Sehore","23.2000","77.0800","India","IN","IND","Madhya Pradesh","","109118","1356587614"
"Medenine","Medenine","33.3547","10.5053","Tunisia","TN","TUN","Médenine","admin","109409","1788240639"
"Kasserine","Kasserine","35.1667","8.8333","Tunisia","TN","TUN","Kasserine","admin","108794","1788047794"
"Taoyang","Taoyang","35.3754","103.8612","China","CN","CHN","Gansu","minor","109300","1156695837"
"Gualeguaychú","Gualeguaychu","-33.0167","-58.5167","Argentina","AR","ARG","Entre Ríos","minor","109461","1032523704"
"Béja","Beja","36.7333","9.1833","Tunisia","TN","TUN","Béja","admin","109299","1788225711"
"Talisay","Talisay","10.7333","122.9667","Philippines","PH","PHL","Negros Occidental","","108909","1608509888"
"Lingayen","Lingayen","16.0167","120.2333","Philippines","PH","PHL","Pangasinan","admin","107728","1608464071"
"Labo","Labo","14.1561","122.8294","Philippines","PH","PHL","Camarines Norte","","109245","1608735236"
"Yŏngju","Yongju","36.8058","128.6239","South Korea","KR","KOR","Gyeongbuk","","109266","1410990908"
"West Covina","West Covina","34.0555","-117.9113","United States","US","USA","California","","109396","1840021876"
"Paleng","Paleng","-1.4000","123.1667","Indonesia","ID","IDN","Sulawesi Tengah","","109319","1360144781"
"Temecula","Temecula","33.4928","-117.1315","United States","US","USA","California","","109376","1840021937"
"Bagu Na Mohra","Bagu Na Mohra","33.2200","73.2100","Pakistan","PK","PAK","Punjab","","108784","1586067919"
"Witbank","Witbank","-25.8770","29.2010","South Africa","ZA","ZAF","Mpumalanga","","108673","1710724527"
"Maxixe","Maxixe","-23.8667","35.3500","Mozambique","MZ","MOZ","Inhambane","","108824","1508002342"
"Ban Mangkon","Ban Mangkon","13.6138","100.6104","Thailand","TH","THA","Samut Prakan","","102291","1764800579"
"Bend","Bend","44.0563","-121.3095","United States","US","USA","Oregon","","109224","1840018618"
"Mineshita","Mineshita","35.1185","138.9186","Japan","JP","JPN","Shizuoka","","107416","1392924607"
"Túxpam de Rodríguez Cano","Tuxpam de Rodriguez Cano","20.9500","-97.4000","Mexico","MX","MEX","Veracruz","minor","109049","1484316468"
"Soma","Soma","39.1883","27.6089","Turkey","TR","TUR","Manisa","minor","108981","1792461536"
"Novoshakhtinsk","Novoshakhtinsk","47.7667","39.9167","Russia","RU","RUS","Rostovskaya Oblast’","","108345","1643535469"
"Acharnés","Acharnes","38.0833","23.7333","Greece","GR","GRC","Attikí","minor","108130","1300526257"
"Douliu","Douliu","23.7075","120.5439","Taiwan","TW","TWN","Yunlin","admin","107924","1158732750"
"São Mateus","Sao Mateus","-18.7158","-39.8589","Brazil","BR","BRA","Espírito Santo","","109028","1076829621"
"Botoşani","Botosani","47.7486","26.6694","Romania","RO","ROU","Botoşani","admin","106847","1642183791"
"Žilina","Zilina","49.2228","18.7400","Slovakia","SK","SVK","Žilina","admin","108000","1703289183"
"Balombo","Balombo","-12.3500","14.7667","Angola","AO","AGO","Benguela","","108965","1024251027"
"Salisbury","Salisbury","38.3756","-75.5865","United States","US","USA","Maryland","","108982","1840006219"
"Crawley","Crawley","51.1092","-0.1872","United Kingdom","GB","GBR","West Sussex","","106597","1826470693"
"Nantang","Nantang","22.4917","113.5374","China","CN","CHN","Guangdong","","108190","1156564448"
"Ferizaj","Ferizaj","42.3667","21.1667","Kosovo","XK","XKS","Ferizaj","admin","108610","1901875540"
"Ikeda","Ikeda","34.8167","135.4333","Japan","JP","JPN","Hyōgo","","104148","1392188564"
"Maţrūḩ","Matruh","31.3500","27.2333","Egypt","EG","EGY","Maţrūḩ","","108774","1818661611"
"St. John's","St. John's","47.4817","-52.7971","Canada","CA","CAN","Newfoundland and Labrador","admin","108860.00","1124741456"
"Sītāmarhi","Sitamarhi","26.6000","85.4800","India","IN","IND","Bihār","","106093","1356186196"
"Salford","Salford","53.4830","-2.2931","United Kingdom","GB","GBR","Salford","","103886","1826868550"
"Mungeli","Mungeli","22.0700","81.6800","India","IN","IND","Chhattīsgarh","","108387","1356114358"
"Reus","Reus","41.1549","1.1087","Spain","ES","ESP","Catalonia","","106741","1724192207"
"Moortebeek","Moortebeek","50.8547","4.3386","Belgium","BE","BEL","Flanders","","97979","1056103437"
"Taungoo","Taungoo","18.9333","96.4333","Myanmar","MM","MMR","Bago","","108569","1104269989"
"Cawayan","Cawayan","9.9667","122.6167","Philippines","PH","PHL","Negros Occidental","","108480","1608006603"
"Gò Công","Go Cong","10.3667","106.6667","Vietnam","VN","VNM","Tiền Giang","","107600","1704078032"
"Tādpatri","Tadpatri","14.9200","78.0200","India","IN","IND","Andhra Pradesh","","108171","1356318314"
"Moncton","Moncton","46.1328","-64.7714","Canada","CA","CAN","New Brunswick","","108620","1124521303"
"Yāsūj","Yasuj","30.6683","51.5881","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","admin","108505","1364652740"
"Tipitapa","Tipitapa","12.1964","-86.0969","Nicaragua","NI","NIC","Managua","minor","108457","1558753915"
"Alto Hospicio","Alto Hospicio","-20.2500","-70.1167","Chile","CL","CHL","Tarapacá","","108375","1152536354"
"Jacksonville","Jacksonville","34.7289","-77.3941","United States","US","USA","North Carolina","","108549","1840014653"
"Islāmābād","Islamabad","33.7300","75.1500","India","IN","IND","Jammu and Kashmīr","","108505","1356324247"
"Maramag","Maramag","7.7631","125.0050","Philippines","PH","PHL","Bukidnon","","108293","1608275407"
"Jalpāiguri","Jalpaiguri","26.5167","88.7333","India","IN","IND","West Bengal","","107341","1356763479"
"Birnin Kebbi","Birnin Kebbi","12.4504","4.1999","Nigeria","NG","NGA","Kebbi","admin","108164","1566191812"
"Gharbara","Gharbara","28.4962","77.5360","India","IN","IND","Uttar Pradesh","","107676","1356603709"
"Shāmli","Shamli","29.4500","77.3200","India","IN","IND","Uttar Pradesh","","107233","1356018667"
"Šiauliai","Siauliai","55.9333","23.3167","Lithuania","LT","LTU","Šiaulių Miestas","admin","107086","1440593416"
"Khemis Sahel","Khemis Sahel","35.2500","-6.0833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","107371","1504000482"
"Seversk","Seversk","56.6000","84.8500","Russia","RU","RUS","Tomskaya Oblast’","","107922","1643818456"
"Dagenham","Dagenham","51.5397","0.1422","United Kingdom","GB","GBR","Barking and Dagenham","","106247","1826363543"
"El Limón","El Limon","10.3003","-67.6336","Venezuela","VE","VEN","Aragua","minor","106206","1862210475"
"Malate","Malate","14.5642","120.9913","Philippines","PH","PHL","Manila","","78132","1608713259"
"Inglewood","Inglewood","33.9566","-118.3444","United States","US","USA","California","","108206","1840019237"
"Villa Krause","Villa Krause","-31.5833","-68.5333","Argentina","AR","ARG","San Juan","minor","107778","1032771655"
"Wembley","Wembley","51.5528","-0.2979","United Kingdom","GB","GBR","Brent","","102856","1826635683"
"Sarh","Sarh","9.1500","18.3833","Chad","TD","TCD","Moyen-Chari","admin","108061","1148442794"
"An Nuhūd","An Nuhud","12.7000","28.4333","Sudan","SD","SDN","West Kordofan","","108008","1729223651"
"Kotmale","Kotmale","7.0167","80.5833","Sri Lanka","LK","LKA","Central","","107523","1144416345"
"Joyabaj","Joyabaj","14.9950","-90.8075","Guatemala","GT","GTM","Quiché","minor","107634","1320625420"
"Dearborn","Dearborn","42.3127","-83.2130","United States","US","USA","Michigan","","107985","1840003969"
"Centennial","Centennial","39.5926","-104.8673","United States","US","USA","Colorado","","107972","1840018792"
"Koszalin","Koszalin","54.2000","16.1833","Poland","PL","POL","Zachodniopomorskie","minor","106880","1616258002"
"Tajimi","Tajimi","35.3328","137.1316","Japan","JP","JPN","Gifu","","106746","1392899702"
"Thunder Bay","Thunder Bay","48.3822","-89.2461","Canada","CA","CAN","Ontario","","107909","1124398712"
"Delft","Delft","52.0117","4.3592","Netherlands","NL","NLD","Zuid-Holland","minor","103581","1528448452"
"Baía Farta","Baia Farta","-12.6128","13.1987","Angola","AO","AGO","Benguela","","107841","1024743438"
"Tagbilaran City","Tagbilaran City","9.6500","123.8500","Philippines","PH","PHL","Bohol","minor","104976","1608573250"
"Courbevoic","Courbevoic","48.8978","2.2531","France","FR","FRA","Île-de-France","","82198","1250060303"
"Pavlohrad","Pavlohrad","48.5200","35.8700","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","106082","1804162854"
"Emmen","Emmen","52.7833","6.9000","Netherlands","NL","NLD","Drenthe","minor","107471","1528332075"
"Queenstown","Queenstown","-31.9000","26.8833","South Africa","ZA","ZAF","Eastern Cape","","105309","1710110028"
"Chaman","Chaman","30.9210","66.4597","Pakistan","PK","PAK","Balochistan","","107660","1586640695"
"Pateros","Pateros","14.5417","121.0667","Philippines","PH","PHL","","","65227","1608860224"
"Umm Qaşr","Umm Qasr","30.0342","47.9294","Iraq","IQ","IRQ","Al Başrah","","107620","1368478549"
"Musoma","Musoma","-1.5000","33.8000","Tanzania","TZ","TZA","Mara","admin","103497","1834100025"
"Carmen","Carmen","7.2000","124.7833","Philippines","PH","PHL","Cotabato","","107603","1608666262"
"Suriāpet","Suriapet","17.1415","79.6236","India","IN","IND","Andhra Pradesh","","106805","1356067301"
"Charsadda","Charsadda","34.1500","71.7333","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","105414","1586928614"
"Kogon Shahri","Kogon Shahri","39.7275","64.5547","Uzbekistan","UZ","UZB","Buxoro","minor","107566","1860234026"
"Shikohābād","Shikohabad","27.1000","78.6000","India","IN","IND","Uttar Pradesh","","107404","1356626392"
"Kefar Sava","Kefar Sava","32.1714","34.9083","Israel","IL","ISR","Central","","100800","1376008883"
"Alchevsk","Alchevsk","48.4778","38.7978","Ukraine","UA","UKR","Luhanska Oblast","minor","105398","1804025187"
"Toufen","Toufen","24.6832","120.9072","Taiwan","TW","TWN","Miaoli","","105513","1158996229"
"Morales","Morales","15.4725","-88.8414","Guatemala","GT","GTM","Izabal","minor","107400","1320701049"
"Burbank","Burbank","34.1879","-118.3235","United States","US","USA","California","","107364","1840019233"
"Manokwari","Manokwari","-0.8667","134.0833","Indonesia","ID","IDN","Papua Barat","admin","107325","1360451991"
"Bolzano","Bolzano","46.4981","11.3548","Italy","IT","ITA","Trentino-Alto Adige","minor","107317","1380677819"
"Chorzów","Chorzow","50.3000","18.9500","Poland","PL","POL","Śląskie","minor","104193","1616517871"
"Erdenet","Erdenet","49.0278","104.0444","Mongolia","MN","MNG","Orhon","admin","106810","1496374417"
"Wa","Wa","10.0667","-2.5000","Ghana","GH","GHA","Upper West","admin","107214","1288765586"
"Launceston","Launceston","-41.4419","147.1450","Australia","AU","AUS","Tasmania","","106153","1036170383"
"Ejido","Ejido","8.5466","-71.2409","Venezuela","VE","VEN","Mérida","minor","107056","1862153927"
"Chongshan","Chongshan","18.7787","109.5117","China","CN","CHN","Hainan","minor","107100","1156147494"
"Idkū","Idku","31.3000","30.3000","Egypt","EG","EGY","Al Buḩayrah","","105875","1818383026"
"Kishanganj","Kishanganj","26.0794","87.9372","India","IN","IND","Bihār","","105782","1356127495"
"Ilebo","Ilebo","-4.3167","20.6000","Congo (Kinshasa)","CD","COD","Kasaï","","107093","1180788280"
"Namhkam","Namhkam","23.8333","97.6833","Myanmar","MM","MMR","Shan State","","107034","1104658655"
"Dieppe","Dieppe","46.0989","-64.7242","Canada","CA","CAN","New Brunswick","","107068","1124195431"
"Luján","Lujan","-34.5667","-59.1000","Argentina","AR","ARG","Buenos Aires","minor","106899","1032704827"
"Arzamas","Arzamas","55.4000","43.8167","Russia","RU","RUS","Nizhegorodskaya Oblast’","","104547","1643088735"
"Morón","Moron","-34.6500","-58.6167","Argentina","AR","ARG","Buenos Aires","minor","99066","1032773254"
"Banté","Bante","8.4167","1.8833","Benin","BJ","BEN","Collines","","106945","1204394009"
"Longjiang","Longjiang","47.3404","123.1800","China","CN","CHN","Heilongjiang","minor","106384","1156263974"
"Maipú","Maipu","-32.9667","-68.7500","Argentina","AR","ARG","Mendoza","minor","106662","1032731844"
"Edison","Edison","40.5360","-74.3697","United States","US","USA","New Jersey","","106909","1840081696"
"Artëm","Artem","43.3667","132.2000","Russia","RU","RUS","Primorskiy Kray","","106692","1643978240"
"Sparks","Sparks","39.5736","-119.7161","United States","US","USA","Nevada","","106900","1840021337"
"Tiaong","Tiaong","13.9500","121.3167","Philippines","PH","PHL","Quezon","minor","106265","1608344411"
"Erechim","Erechim","-27.6339","-52.2739","Brazil","BR","BRA","Rio Grande do Sul","","106633","1076359548"
"Noyabrsk","Noyabrsk","63.2017","75.4517","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","106879","1643340196"
"Monastir","Monastir","35.7694","10.8194","Tunisia","TN","TUN","Monastir","admin","104535","1788553334"
"Catbalogan","Catbalogan","11.7833","124.8833","Philippines","PH","PHL","Samar","admin","106440","1608878798"
"As Safīrah","As Safirah","36.0778","37.3733","Syria","SY","SYR","Ḩalab","minor","106460","1760134354"
"Chābahār","Chabahar","25.2919","60.6431","Iran","IR","IRN","Sīstān va Balūchestān","minor","106739","1364525124"
"Nakhon Si Thammarat","Nakhon Si Thammarat","8.4364","99.9631","Thailand","TH","THA","Nakhon Si Thammarat","admin","106322","1764409111"
"Rānībennur","Ranibennur","14.6167","75.6167","India","IN","IND","Karnātaka","","106365","1356823654"
"Kŭlob","Kulob","37.9092","69.7819","Tajikistan","TJ","TJK","Khatlon","minor","106300","1762798596"
"Salihorsk","Salihorsk","52.8000","27.5333","Belarus","BY","BLR","Minskaya Voblasts’","minor","106627","1112250470"
"Ad Dakhla","Ad Dakhla","23.7081","-15.9456","Morocco","MA","MAR","Dakhla-Oued Ed-Dahab","","106277","1504999983"
"Jincheng","Jincheng","39.5529","113.1933","China","CN","CHN","Shanxi","minor","105038","1156528474"
"Sandy Springs","Sandy Springs","33.9366","-84.3703","United States","US","USA","Georgia","","106605","1840015609"
"Raba","Raba","-8.4614","118.7469","Indonesia","ID","IDN","Nusa Tenggara Barat","minor","106101","1360014952"
"Patos","Patos","-7.0244","-37.2800","Brazil","BR","BRA","Paraíba","","106314","1076232522"
"Mmabatho","Mmabatho","-25.8500","25.6333","South Africa","ZA","ZAF","North West","","104428","1710524815"
"Lokossa","Lokossa","6.6333","1.7167","Benin","BJ","BEN","Mono","admin","106081","1204036358"
"La Banda","La Banda","-27.7333","-64.2500","Argentina","AR","ARG","Santiago del Estero","minor","106441","1032317566"
"Kemalpaşa","Kemalpasa","38.4278","27.4161","Turkey","TR","TUR","İzmir","minor","106298","1792611875"
"Jamālpur","Jamalpur","25.3000","86.5000","India","IN","IND","Bihār","","105221","1356530461"
"Sơn La","Son La","21.3270","103.9141","Vietnam","VN","VNM","Sơn La","admin","106052","1704957010"
"Passos","Passos","-20.7189","-46.6100","Brazil","BR","BRA","Minas Gerais","","106290","1076782492"
"Nautanwa","Nautanwa","27.4300","83.4200","India","IN","IND","Uttar Pradesh","","105345","1356061939"
"Akhmīm","Akhmim","26.5667","31.7500","Egypt","EG","EGY","Sūhāj","","101509","1818295057"
"Bloomington","Bloomington","39.1637","-86.5257","United States","US","USA","Indiana","","106327","1840007376"
"Logan","Logan","41.7399","-111.8422","United States","US","USA","Utah","","106319","1840020108"
"Komatsu","Komatsu","36.4083","136.4455","Japan","JP","JPN","Ishikawa","","106023","1392003448"
"Aihua","Aihua","24.4629","100.1350","China","CN","CHN","Yunnan","minor","106054","1156317956"
"Roubaix","Roubaix","50.6901","3.1817","France","FR","FRA","Hauts-de-France","","98828","1250000779"
"Temoaya","Temoaya","19.4686","-99.5933","Mexico","MX","MEX","Morelos","minor","105766","1484890875"
"Achinsk","Achinsk","56.2817","90.5039","Russia","RU","RUS","Krasnoyarskiy Kray","","105264","1643890929"
"Kailua","Kailua","21.3920","-157.7396","United States","US","USA","Hawaii","","106260","1840023244"
"Mingəçevir","Mingacevir","40.7700","47.0489","Azerbaijan","AZ","AZE","Mingəçevir","admin","106100","1031509213"
"Contramaestre","Contramaestre","20.3000","-76.2506","Cuba","CU","CUB","Santiago de Cuba","minor","106002","1192844279"
"Ciudad Río Bravo","Ciudad Rio Bravo","25.9861","-98.0889","Mexico","MX","MEX","Tamaulipas","minor","101481","1484549731"
"El Cajon","El Cajon","32.8017","-116.9604","United States","US","USA","California","","106043","1840020618"
"Vespasiano","Vespasiano","-19.6919","-43.9228","Brazil","BR","BRA","Minas Gerais","","104527","1076342199"
"Atebubu","Atebubu","7.7500","-0.9833","Ghana","GH","GHA","Bono East","","105938","1288881078"
"Khanty-Mansiysk","Khanty-Mansiysk","61.0000","69.0000","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","admin","105995","1643001221"
"Nusaybin","Nusaybin","37.0750","41.2153","Turkey","TR","TUR","Mardin","minor","105856","1792327949"
"Lo Barnechea","Lo Barnechea","-33.3500","-70.5167","Chile","CL","CHL","Región Metropolitana","","105833","1152490874"
"Azare","Azare","11.6742","10.1911","Nigeria","NG","NGA","Bauchi","minor","105687","1566322480"
"Ariquemes","Ariquemes","-9.9161","-63.0408","Brazil","BR","BRA","Rondônia","","105896","1076442639"
"Paço do Lumiar","Paco do Lumiar","-2.5319","-44.1078","Brazil","BR","BRA","Maranhão","","105121","1076632637"
"Gangāwati","Gangawati","15.4300","76.5300","India","IN","IND","Karnātaka","","105529","1356899767"
"Hillsboro","Hillsboro","45.5273","-122.9360","United States","US","USA","Oregon","","105909","1840019932"
"Koutiala","Koutiala","12.3833","-5.4667","Mali","ML","MLI","Sikasso","minor","104927","1466253368"
"Kiryū","Kiryu","36.4052","139.4973","Japan","JP","JPN","Gunma","","105465","1392003445"
"Pushkino","Pushkino","56.0167","37.8333","Russia","RU","RUS","Moskovskaya Oblast’","","105479","1643799484"
"Yelets","Yelets","52.6167","38.4667","Russia","RU","RUS","Lipetskaya Oblast’","","104349","1643014014"
"Ourense","Ourense","42.3364","-7.8633","Spain","ES","ESP","Galicia","minor","104596","1724571895"
"Ballia","Ballia","25.7604","84.1471","India","IN","IND","Uttar Pradesh","","104424","1356194087"
"Bình Long","Binh Long","11.6527","106.6093","Vietnam","VN","VNM","Bình Phước","","105520","1704953411"
"Tikrīt","Tikrit","34.6100","43.6800","Iraq","IQ","IRQ","Şalāḩ ad Dīn","admin","105700","1368199624"
"Bulan","Bulan","12.6697","123.8758","Philippines","PH","PHL","Sorsogon","","105190","1608516439"
"Miriālgūda","Mirialguda","16.8667","79.5833","India","IN","IND","Andhra Pradesh","","104918","1356570698"
"Playas de Rosarito","Playas de Rosarito","32.3636","-117.0544","Mexico","MX","MEX","Baja California","minor","100660","1484522993"
"Lerma","Lerma","19.2847","-99.5119","Mexico","MX","MEX","México","minor","105578","1484402392"
"Tarīm","Tarim","16.0500","49.0000","Yemen","YE","YEM","Ḩaḑramawt","minor","105552","1887801013"
"Maluñgun","Malungun","6.2667","125.2833","Philippines","PH","PHL","Sarangani","","105465","1608972592"
"Mityana","Mityana","0.4006","32.0422","Uganda","UG","UGA","Mityana","admin","105200","1800284151"
"Presidente Franco","Presidente Franco","-25.5333","-54.6167","Paraguay","PY","PRY","Alto Paraná","","104677","1600286603"
"South Fulton","South Fulton","33.6273","-84.5800","United States","US","USA","Georgia","","105551","1840029465"
"Mazyr","Mazyr","52.0500","29.2667","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","105439","1112704173"
"Robāţ Karīm","Robat Karim","35.4847","51.0828","Iran","IR","IRN","Tehrān","minor","105393","1364282464"
"Kroonstad","Kroonstad","-27.6456","27.2317","South Africa","ZA","ZAF","Free State","","103992","1710508288"
"Konotop","Konotop","51.2369","33.2027","Ukraine","UA","UKR","Sumska Oblast","minor","103547","1804110853"
"Kandhkot","Kandhkot","28.4000","69.3000","Pakistan","PK","PAK","Sindh","","105011","1586311788"
"Saint Helens","Saint Helens","53.4500","-2.7500","United Kingdom","GB","GBR","St. Helens","","102629","1826775771"
"Toride","Toride","35.9115","140.0504","Japan","JP","JPN","Ibaraki","","103908","1392922950"
"Ōnojō","Onojo","33.5333","130.4833","Japan","JP","JPN","Fukuoka","","101603","1392003262"
"Makrāna","Makrana","27.0500","74.7200","India","IN","IND","Rājasthān","","105080","1356115611"
"Granada","Granada","11.9347","-85.9589","Nicaragua","NI","NIC","Granada","admin","105171","1558101678"
"Assis","Assis","-22.6619","-50.4119","Brazil","BR","BRA","São Paulo","","105087","1076782502"
"Renton","Renton","47.4784","-122.1918","United States","US","USA","Washington","","105313","1840019827"
"Girona","Girona","41.9833","2.8167","Spain","ES","ESP","Catalonia","minor","102666","1724386661"
"Kongolo","Kongolo","-5.4000","27.0000","Congo (Kinshasa)","CD","COD","Tanganyika","","105202","1180814807"
"Sheopur","Sheopur","25.6700","76.7000","India","IN","IND","Madhya Pradesh","","105026","1356713793"
"Sultan Kudarat","Sultan Kudarat","7.2333","124.2500","Philippines","PH","PHL","Maguindanao","","105121","1608836609"
"Moers","Moers","51.4592","6.6197","Germany","DE","DEU","North Rhine-Westphalia","","103725","1276257182"
"Alaşehir","Alasehir","38.3500","28.5167","Turkey","TR","TUR","Manisa","minor","105145","1792803121"
"Liberec","Liberec","50.7667","15.0667","Czechia","CZ","CZE","Liberecký Kraj","admin","104261","1203577727"
"Yozgat","Yozgat","39.8208","34.8083","Turkey","TR","TUR","Yozgat","admin","105167","1792862455"
"Texcoco","Texcoco","19.5200","-98.8800","Mexico","MX","MEX","México","minor","105165","1484653265"
"Novara","Novara","45.4500","8.6167","Italy","IT","ITA","Piedmont","minor","104183","1380911437"
"Tam Điệp","Tam Diep","20.1556","105.9181","Vietnam","VN","VNM","Ninh Bình","minor","104175","1704023759"
"Tourcoing","Tourcoing","50.7239","3.1612","France","FR","FRA","Hauts-de-France","","98656","1250711716"
"Al Fāw","Al Faw","29.9758","48.4722","Iraq","IQ","IRQ","Al Başrah","minor","105080","1368130668"
"Balanga","Balanga","14.6800","120.5300","Philippines","PH","PHL","Bataan","admin","104173","1608467104"
"Ndjamba","Ndjamba","-14.7000","16.0667","Angola","AO","AGO","Huíla","","105090","1024911597"
"Mandeville","Mandeville","30.3751","-90.0904","United States","US","USA","Louisiana","","105097","1840015909"
"San Mateo","San Mateo","37.5522","-122.3121","United States","US","USA","California","","105087","1840021551"
"Columbia","Columbia","39.2004","-76.8590","United States","US","USA","Maryland","","105086","1840005803"
"Masbate","Masbate","12.2700","123.5800","Philippines","PH","PHL","Masbate","admin","104522","1608802236"
"Salzgitter","Salzgitter","52.1500","10.3333","Germany","DE","DEU","Lower Saxony","minor","104948","1276613066"
"Tezpur","Tezpur","26.6300","92.8000","India","IN","IND","Assam","","102505","1356299437"
"Berdsk","Berdsk","54.7500","83.1000","Russia","RU","RUS","Novosibirskaya Oblast’","","103578","1643751718"
"Kurichchi","Kurichchi","10.9609","76.9738","India","IN","IND","Tamil Nādu","","102765","1356159322"
"Ngong","Ngong","-1.3667","36.6333","Kenya","KE","KEN","Kajiado","","102323","1404947852"
"Waterloo","Waterloo","43.4667","-80.5167","Canada","CA","CAN","Ontario","","104986","1124321390"
"Worcester","Worcester","52.1911","-2.2206","United Kingdom","GB","GBR","Worcestershire","","101891","1826393854"
"Daly City","Daly City","37.6862","-122.4685","United States","US","USA","California","","104914","1840018926"
"Tādepallegūdem","Tadepallegudem","16.8150","81.5260","India","IN","IND","Andhra Pradesh","","104032","1356576541"
"Bălţi","Balti","47.7667","27.9167","Moldova","MD","MDA","Bălţi","admin","102457","1498172131"
"Włocławek","Wloclawek","52.6592","19.0681","Poland","PL","POL","Kujawsko-Pomorskie","minor","104705","1616143097"
"Maumere","Maumere","-8.6222","122.2122","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","104285","1360776014"
"Davie","Davie","26.0789","-80.2870","United States","US","USA","Florida","","104782","1840017272"
"Masaka","Masaka","-0.3411","31.7361","Uganda","UG","UGA","Masaka","admin","103829","1800731885"
"Nanterre","Nanterre","48.8988","2.1969","France","FR","FRA","Île-de-France","minor","96807","1250886409"
"Sergiyev Posad","Sergiyev Posad","56.3000","38.1333","Russia","RU","RUS","Moskovskaya Oblast’","","104579","1643900929"
"Jurupa Valley","Jurupa Valley","34.0010","-117.4706","United States","US","USA","California","","104684","1840043419"
"Francistown","Francistown","-21.1736","27.5125","Botswana","BW","BWA","Francistown","admin","103417","1072124168"
"Fugangcun","Fugangcun","23.5899","116.5924","China","CN","CHN","Guangdong","","102012","1156010739"
"Olanchito","Olanchito","15.4833","-86.5833","Honduras","HN","HND","Yoro","","104609","1340362964"
"Trindade","Trindade","-16.6581","-49.4836","Brazil","BR","BRA","Goiás","","104488","1076815881"
"Leme","Leme","-22.1861","-47.3906","Brazil","BR","BRA","São Paulo","","104346","1076542731"
"Roquetas de Mar","Roquetas de Mar","36.7642","-2.6147","Spain","ES","ESP","Andalusia","","102881","1724749177"
"Inzai","Inzai","35.8333","140.1500","Japan","JP","JPN","Chiba","","103677","1392272573"
"Khāk-e ‘Alī","Khak-e \`Ali","36.1283","50.1764","Iran","IR","IRN","Qazvīn","","104417","1364217752"
"Maldonado","Maldonado","-34.9000","-54.9500","Uruguay","UY","URY","Maldonado","admin","104032","1858958595"
"Fulgāzi","Fulgazi","23.1333","91.4333","Bangladesh","BD","BGD","Chattogram","","103426","1050136232"
"Suruç","Suruc","36.9764","38.4269","Turkey","TR","TUR","Şanlıurfa","minor","104302","1792909050"
"Techiman","Techiman","7.5772","-1.9292","Ghana","GH","GHA","Bono East","admin","104212","1288222236"
"Brājarājnagar","Brajarajnagar","21.8167","83.9167","India","IN","IND","Odisha","","101585","1356019758"
"Arapongas","Arapongas","-23.4189","-51.4239","Brazil","BR","BRA","Paraná","","104150","1076623771"
"Villa Luzuriaga","Villa Luzuriaga","-34.6667","-58.5833","Argentina","AR","ARG","Buenos Aires","","94403","1032092344"
"Aquin","Aquin","18.2833","-73.4000","Haiti","HT","HTI","Sud","","104216","1332922439"
"Chikushino","Chikushino","33.5000","130.5167","Japan","JP","JPN","Fukuoka","","103185","1392003463"
"Caseros","Caseros","-34.6106","-58.5625","Argentina","AR","ARG","Buenos Aires","minor","95785","1032500630"
"San Vicente de Baracaldo","San Vicente de Baracaldo","43.2972","-2.9917","Spain","ES","ESP","Basque Country","","100907","1724451934"
"Gillingham","Gillingham","51.3850","0.5500","United Kingdom","GB","GBR","Medway","","104157","1826642243"
"Ginowan","Ginowan","26.2817","127.7783","Japan","JP","JPN","Okinawa","","99256","1392613742"
"Tula de Allende","Tula de Allende","20.0500","-99.3500","Mexico","MX","MEX","Hidalgo","","103919","1484845374"
"Brockton","Brockton","42.0821","-71.0242","United States","US","USA","Massachusetts","","104216","1840000467"
"Kalamariá","Kalamaria","40.5833","22.9500","Greece","GR","GRC","Kentrikí Makedonía","minor","91279","1300268108"
"Sindangan","Sindangan","8.2386","122.9986","Philippines","PH","PHL","Zamboanga del Norte","","103952","1608537090"
"Lower Hutt","Lower Hutt","-41.2167","174.9167","New Zealand","NZ","NZL","Wellington","","103400","1554623632"
"Coronel Fabriciano","Coronel Fabriciano","-19.5189","-42.6289","Brazil","BR","BRA","Minas Gerais","","103694","1076563450"
"Kwai Chung","Kwai Chung","22.3674","114.1273","Hong Kong","HK","HKG","","","38674","1344773013"
"Kamianets-Podilskyi","Kamianets-Podilskyi","48.6806","26.5806","Ukraine","UA","UKR","Khmelnytska Oblast","minor","100462","1804779341"
"Açailandia","Acailandia","-4.9469","-47.5050","Brazil","BR","BRA","Tocantins","","104047","1076993685"
"Mubende","Mubende","0.5575","31.3950","Uganda","UG","UGA","Mubende","admin","103473","1800105368"
"Natitingou","Natitingou","10.3000","1.3667","Benin","BJ","BEN","Atacora","admin","104010","1204752595"
"Séguéla","Seguela","7.9667","-6.6667","Côte d'Ivoire","CI","CIV","Woroba","admin","103980","1384639807"
"Longmont","Longmont","40.1686","-105.1005","United States","US","USA","Colorado","","104035","1840020178"
"Elista","Elista","46.3167","44.2667","Russia","RU","RUS","Kalmykiya","admin","103535","1643581064"
"Kalyani","Kalyani","22.9750","88.4344","India","IN","IND","West Bengal","","100575","1356108133"
"Kilinochchi","Kilinochchi","9.4004","80.3999","Sri Lanka","LK","LKA","North Central","","103717","1144468936"
"Paniqui","Paniqui","15.6681","120.5789","Philippines","PH","PHL","Tarlac","","103003","1608244278"
"Saijō","Saijo","33.9167","133.1833","Japan","JP","JPN","Ehime","","103760","1392903707"
"Isehara","Isehara","35.3833","139.3000","Japan","JP","JPN","Kanagawa","","102088","1392928065"
"Dolisie","Dolisie","-4.2000","12.6667","Congo (Brazzaville)","CG","COG","Niari","admin","103894","1178045241"
"Negapatam","Negapatam","10.7667","79.8333","India","IN","IND","Tamil Nādu","","102905","1356879770"
"Eastbourne","Eastbourne","50.7700","0.2800","United Kingdom","GB","GBR","East Sussex","","101547","1826356320"
"Pazardzhik","Pazardzhik","42.2000","24.3333","Bulgaria","BG","BGR","Pazardzhik","admin","103681","1100273248"
"Wigan","Wigan","53.5448","-2.6318","United Kingdom","GB","GBR","Wigan","","103608","1826902223"
"Rialto","Rialto","34.1175","-117.3892","United States","US","USA","California","","103799","1840020407"
"Libertad","Libertad","-34.6833","-58.6833","Argentina","AR","ARG","Buenos Aires","","100324","1032241314"
"San Rafael","San Rafael","14.9500","120.9667","Philippines","PH","PHL","Bulacan","","103097","1608373072"
"Thika","Thika","-1.0396","37.0900","Kenya","KE","KEN","Kiambu","","99322","1404482650"
"Telde","Telde","27.9985","-15.4167","Spain","ES","ESP","Canary Islands","","102769","1724281666"
"Yunfu","Yunfu","28.6331","104.4181","China","CN","CHN","Yunnan","","103532","1156353293"
"Buxar","Buxar","25.5605","83.9805","India","IN","IND","Bihār","","102861","1356331714"
"Dongguazhen","Dongguazhen","25.0790","101.5020","China","CN","CHN","Yunnan","","103115","1156405666"
"San Germán","San German","18.0827","-67.0460","Puerto Rico","PR","PRI","Puerto Rico","","103747","1630023526"
"Siegen","Siegen","50.8833","8.0167","Germany","DE","DEU","North Rhine-Westphalia","minor","102836","1276268768"
"Tantoyuca","Tantoyuca","21.3500","-98.2333","Mexico","MX","MEX","Veracruz","minor","103616","1484205572"
"Vitry-sur-Seine","Vitry-sur-Seine","48.7875","2.3928","France","FR","FRA","Île-de-France","","95510","1250115605"
"Biak","Biak","-1.1800","136.0800","Indonesia","ID","IDN","Papua","minor","103610","1360911386"
"Zomba","Zomba","-15.3833","35.3333","Malawi","MW","MWI","Zomba","admin","101140","1454072947"
"Yishi","Yishi","35.1379","110.7640","China","CN","CHN","Shanxi","minor","102090","1156025883"
"Songea","Songea","-10.6833","35.6500","Tanzania","TZ","TZA","Ruvuma","admin","99961","1834461509"
"Hikkaduwa","Hikkaduwa","6.1472","80.1103","Sri Lanka","LK","LKA","Southern","","101342","1144660740"
"Eau Claire","Eau Claire","44.8197","-91.4948","United States","US","USA","Wisconsin","","103586","1840002296"
"Bouskoura","Bouskoura","33.4489","-7.6486","Morocco","MA","MAR","Casablanca-Settat","","103026","1504503306"
"Lida","Lida","53.8833","25.3000","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","","103479","1112110662"
"Būndi","Bundi","25.4383","75.6372","India","IN","IND","Rājasthān","","103286","1356402465"
"Mazatenango","Mazatenango","14.5333","-91.5000","Guatemala","GT","GTM","Suchitepéquez","admin","103276","1320123250"
"Hove","Hove","50.8352","-0.1758","United Kingdom","GB","GBR","Brighton and Hove","","91900","1826419818"
"Nyeri","Nyeri","-0.4167","36.9500","Kenya","KE","KEN","Nyeri","admin","98908","1404924901"
"Amarāvati","Amaravati","16.5131","80.5165","India","IN","IND","Andhra Pradesh","admin","103000","1356002501"
"Messaad","Messaad","34.1542","3.4969","Algeria","DZ","DZA","Djelfa","","102453","1012278908"
"Bumba","Bumba","2.1844","22.4703","Congo (Kinshasa)","CD","COD","Mongala","","103328","1180549980"
"Araxá","Araxa","-19.5928","-46.9408","Brazil","BR","BRA","Minas Gerais","","103287","1076259402"
"Turlock","Turlock","37.5053","-120.8588","United States","US","USA","California","","103359","1840021533"
"Woodbridge","Woodbridge","40.5611","-74.2943","United States","US","USA","New Jersey","","103353","1840081695"
"Norwalk","Norwalk","33.9069","-118.0829","United States","US","USA","California","","103330","1840020501"
"Almirante Tamandaré","Almirante Tamandare","-25.3250","-49.3100","Brazil","BR","BRA","Paraná","","103204","1076372357"
"Sakado","Sakado","35.9573","139.4030","Japan","JP","JPN","Saitama","","100829","1392771396"
"São Lourenço da Mata","Sao Lourenco da Mata","-8.0019","-35.0178","Brazil","BR","BRA","Pernambuco","","102895","1076714150"
"Yilong","Yilong","23.7081","102.4960","China","CN","CHN","Yunnan","minor","102923","1156987450"
"Itanhaém","Itanhaem","-24.1806","-46.7828","Brazil","BR","BRA","São Paulo","","103102","1076274068"
"Novokuybyshevsk","Novokuybyshevsk","53.1000","49.9333","Russia","RU","RUS","Samarskaya Oblast’","","102075","1643467643"
"Bergama","Bergama","39.1167","27.1833","Turkey","TR","TUR","İzmir","minor","103185","1792434888"
"Highlands Ranch","Highlands Ranch","39.5419","-104.9708","United States","US","USA","Colorado","","103238","1840028579"
"Soasio","Soasio","0.6833","127.4000","Indonesia","ID","IDN","Maluku Utara","","103171","1360827216"
"Piacenza","Piacenza","45.0500","9.7000","Italy","IT","ITA","Emilia-Romagna","minor","103082","1380821832"
"Miryang","Miryang","35.5000","128.7500","South Korea","KR","KOR","Gyeongnam","","103069","1410001039"
"Rishīkesh","Rishikesh","30.1083","78.2972","India","IN","IND","Uttarākhand","","102138","1356965119"
"Tanjungpandan","Tanjungpandan","-2.7500","107.7500","Indonesia","ID","IDN","Kepulauan Bangka Belitung","minor","103062","1360732632"
"Houzhuang","Houzhuang","35.6390","111.2324","China","CN","CHN","Shanxi","","102400","1156080869"
"Mengdingjie","Mengdingjie","23.5517","99.0653","China","CN","CHN","Yunnan","","102992","1156696271"
"Hildesheim","Hildesheim","52.1500","9.9500","Germany","DE","DEU","Lower Saxony","minor","101990","1276244190"
"Xırdalan","Xirdalan","40.4486","49.7564","Azerbaijan","AZ","AZE","Abşeron","admin","100900","1031145251"
"Parang","Parang","7.3744","124.2686","Philippines","PH","PHL","Maguindanao","","102914","1608326061"
"Satu Mare","Satu Mare","47.7900","22.8900","Romania","RO","ROU","Satu Mare","admin","102411","1642046798"
"Nantou","Nantou","23.9167","120.6833","Taiwan","TW","TWN","Nantou","admin","101606","1158112865"
"Coatepec","Coatepec","19.4522","-96.9614","Mexico","MX","MEX","Veracruz","minor","92127","1484934939"
"Sangju","Sangju","36.4400","128.1700","South Korea","KR","KOR","Gyeongbuk","","102892","1410127055"
"Santa Rosa","Santa Rosa","-36.6167","-64.2833","Argentina","AR","ARG","La Pampa","admin","102880","1032215828"
"Mumias","Mumias","0.3333","34.4833","Kenya","KE","KEN","Kakamega","","99987","1404232773"
"Bhadreswar","Bhadreswar","22.8200","88.3500","India","IN","IND","West Bengal","","101477","1356320019"
"Guihulñgan","Guihulngan","10.1167","123.2667","Philippines","PH","PHL","Negros Oriental","","102656","1608628412"
"Hinche","Hinche","19.1500","-72.0167","Haiti","HT","HTI","Centre","admin","102745","1332748356"
"Noginsk","Noginsk","55.8500","38.4333","Russia","RU","RUS","Moskovskaya Oblast’","","102592","1643001706"
"Leuven","Leuven","50.8833","4.7000","Belgium","BE","BEL","Flanders","minor","101032","1056866572"
"Bethal","Bethal","-26.4500","29.4500","South Africa","ZA","ZAF","Mpumalanga","","101919","1710328253"
"Allen","Allen","33.1088","-96.6735","United States","US","USA","Texas","","102778","1840019396"
"Hengnan","Hengnan","22.5337","113.2485","China","CN","CHN","Guangdong","","101415","1156808087"
"San Felipe del Progreso","San Felipe del Progreso","19.7125","-99.9511","Mexico","MX","MEX","México","minor","100201","1484781559"
"Níkaia","Nikaia","37.9667","23.6333","Greece","GR","GRC","Attikí","minor","89380","1300155901"
"Chaguanas","Chaguanas","10.5167","-61.4000","Trinidad and Tobago","TT","TTO","Chaguanas","admin","101297","1780795054"
"Bahrain","Bahrain","35.2078","72.5483","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","102253","1586382775"
"Burzaco","Burzaco","-34.8167","-58.3667","Argentina","AR","ARG","Buenos Aires","","98859","1032073218"
"Sundarnagar","Sundarnagar","31.5300","76.8800","India","IN","IND","Himāchal Pradesh","","102401","1356852734"
"Seoni","Seoni","22.0800","79.5300","India","IN","IND","Madhya Pradesh","","102343","1356071437"
"Wichita Falls","Wichita Falls","33.9072","-98.5291","United States","US","USA","Texas","","102563","1840021923"
"Calasiao","Calasiao","16.0167","120.3667","Philippines","PH","PHL","Pangasinan","","100471","1608768907"
"Paoy Paet","Paoy Paet","13.6500","102.5667","Cambodia","KH","KHM","Banteay Meanchey","","98934","1116750944"
"Ngã Bảy","Nga Bay","9.8164","105.8197","Vietnam","VN","VNM","Hậu Giang","minor","101192","1704893699"
"Mayarí","Mayari","20.6592","-75.6781","Cuba","CU","CUB","Holguín","minor","102354","1192527369"
"Kapūrthala","Kapurthala","31.3800","75.3800","India","IN","IND","Punjab","","101854","1356092924"
"Rio Rancho","Rio Rancho","35.2873","-106.6982","United States","US","USA","New Mexico","","102403","1840020387"
"Mositai","Mositai","45.5266","119.6506","China","CN","CHN","Inner Mongolia","minor","102214","1156252271"
"Sabanalarga","Sabanalarga","6.8500","-75.8167","Colombia","CO","COL","Antioquia","minor","102334","1170819531"
"Dhangaḍhi̇̄","Dhangadhi","28.7136","80.5692","Nepal","NP","NPL","","","101970","1524561456"
"Igarassu","Igarassu","-7.8342","-34.9064","Brazil","BR","BRA","Pernambuco","","102021","1076739866"
"Kāshmar","Kashmar","35.2383","58.4656","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","102282","1364144807"
"Aurangābād","Aurangabad","24.7000","84.3500","India","IN","IND","Bihār","","102244","1356920493"
"Vacaville","Vacaville","38.3587","-121.9673","United States","US","USA","California","","102256","1840021498"
"Klagenfurt","Klagenfurt","46.6167","14.3000","Austria","AT","AUT","Kärnten","admin","101403","1040149651"
"Delta","Delta","49.0847","-123.0586","Canada","CA","CAN","British Columbia","","102238","1124001200"
"Chilakalūrupet","Chilakalurupet","16.0892","80.1672","India","IN","IND","Andhra Pradesh","","101398","1356065865"
"Vāsco Da Gāma","Vasco Da Gama","15.3981","73.8111","India","IN","IND","Goa","","100000","1356688186"
"Spokane Valley","Spokane Valley","47.6626","-117.2346","United States","US","USA","Washington","","102178","1840021094"
"Charlottesville","Charlottesville","38.0375","-78.4855","United States","US","USA","Virginia","","102164","1840001678"
"Chishtian","Chishtian","29.8000","72.8333","Pakistan","PK","PAK","Punjab","","101659","1586451544"
"Şabrātah","Sabratah","32.7922","12.4842","Libya","LY","LBY","An Nuqāţ al Khams","","102038","1434908655"
"Tota","Tota","6.8000","1.7833","Benin","BJ","BEN","Couffo","","101870","1204352038"
"Gütersloh","Gutersloh","51.9000","8.3833","Germany","DE","DEU","North Rhine-Westphalia","minor","101158","1276587494"
"Al Jumayl","Al Jumayl","32.8528","12.0611","Libya","LY","LBY","An Nuqāţ al Khams","","102000","1434494324"
"Boryeong","Boryeong","36.3333","126.6167","South Korea","KR","KOR","Chungnam","minor","101852","1410204364"
"Dhamtari","Dhamtari","20.7100","81.5500","India","IN","IND","Chhattīsgarh","","101677","1356754128"
"Jingping","Jingping","39.5189","112.2837","China","CN","CHN","Shanxi","minor","101297","1156801687"
"Deventer","Deventer","52.2500","6.1500","Netherlands","NL","NLD","Overijssel","minor","101236","1528681564"
"Shūshtar","Shushtar","32.0456","48.8567","Iran","IR","IRN","Khūzestān","minor","101878","1364856875"
"Tavşanlı","Tavsanli","39.5333","29.4833","Turkey","TR","TUR","Kütahya","minor","101903","1792642994"
"Teluk Intan","Teluk Intan","4.0259","101.0213","Malaysia","MY","MYS","Perak","","101659","1458126062"
"Santo Antônio de Jesus","Santo Antonio de Jesus","-12.9692","-39.2611","Brazil","BR","BRA","Bahia","","101548","1076231231"
"Hồng Ngự","Hong Ngu","10.8330","105.2830","Vietnam","VN","VNM","Đồng Tháp","","101155","1704773710"
"Oued Zem","Oued Zem","32.8667","-6.5667","Morocco","MA","MAR","Béni Mellal-Khénifra","","95267","1504098746"
"Sūjāngarh","Sujangarh","27.7000","74.4700","India","IN","IND","Rājasthān","","101528","1356081471"
"Jeypore","Jeypore","18.8563","82.5716","India","IN","IND","Odisha","","101145","1356121332"
"Chatham","Chatham","42.4229","-82.1324","Canada","CA","CAN","Ontario","","101647.00","1124393373"
"‘Ibrī","\`Ibri","23.2325","56.4973","Oman","OM","OMN","Az̧ Z̧āhirah","admin","101640","1512077267"
"Bỉm Sơn","Bim Son","20.0781","105.8603","Vietnam","VN","VNM","Thanh Hóa","","100820","1704331479"
"Zheleznogorsk","Zheleznogorsk","52.3333","35.3667","Russia","RU","RUS","Kurskaya Oblast’","","100740","1643006066"
"Sungai Penuh","Sungai Penuh","-2.0589","101.3914","Indonesia","ID","IDN","Jambi","","101325","1360337679"
"Yên Bái","Yen Bai","21.7000","104.8667","Vietnam","VN","VNM","Yên Bái","admin","100631","1704249231"
"Itauguá","Itaugua","-25.3833","-57.3333","Paraguay","PY","PRY","Central","","100456","1600707082"
"Balsas","Balsas","-7.5328","-46.0358","Brazil","BR","BRA","Maranhão","","101456","1076067021"
"Santa Cruz","Santa Cruz","6.8333","125.4167","Philippines","PH","PHL","Davao del Sur","","101125","1608664930"
"San Luis de la Paz","San Luis de la Paz","21.3000","-100.5167","Mexico","MX","MEX","Guanajuato","minor","101370","1484137795"
"Aïn Oussera","Ain Oussera","35.4489","2.9044","Algeria","DZ","DZA","Djelfa","","101239","1012469007"
"Créteil","Creteil","48.7911","2.4628","France","FR","FRA","Île-de-France","minor","93246","1250710438"
"Liancheng","Liancheng","24.0515","105.0573","China","CN","CHN","Yunnan","minor","101221","1156559810"
"Kawachinagano","Kawachinagano","34.4667","135.5667","Japan","JP","JPN","Ōsaka","","100415","1392003443"
"Idaho Falls","Idaho Falls","43.4871","-112.0362","United States","US","USA","Idaho","","101326","1840018644"
"Kousséri","Kousseri","12.0833","15.0333","Cameroon","CM","CMR","Extrême-Nord","","101246","1120996697"
"Mestre","Mestre","45.4906","12.2381","Italy","IT","ITA","Veneto","","89376","1380275919"
"Radès","Rades","36.7667","10.2833","Tunisia","TN","TUN","Ben Arous","","97588","1788930929"
"Hāgere Hiywet","Hagere Hiywet","8.9833","37.8500","Ethiopia","ET","ETH","Oromīya","","94342","1231809487"
"Daoukro","Daoukro","7.0500","-3.9667","Côte d'Ivoire","CI","CIV","Lacs","minor","101136","1384963890"
"Weifen","Weifen","38.4633","111.1203","China","CN","CHN","Shanxi","minor","100615","1156185980"
"Kanoya","Kanoya","31.3831","130.8519","Japan","JP","JPN","Kagoshima","","100880","1392003144"
"Menifee","Menifee","33.6909","-117.1849","United States","US","USA","California","","101089","1840022903"
"Oum el Bouaghi","Oum el Bouaghi","35.8706","7.1150","Algeria","DZ","DZA","Oum el Bouaghi","admin","100821","1012958880"
"Ancona","Ancona","43.6169","13.5167","Italy","IT","ITA","Marche","admin","100924","1380597324"
"Mporokoso","Mporokoso","-9.3833","30.1000","Zambia","ZM","ZMB","Northern","","100993","1894882927"
"Sololá","Solola","14.7667","-91.1833","Guatemala","GT","GTM","Sololá","admin","99934","1320747500"
"Port Blair","Port Blair","11.6683","92.7378","India","IN","IND","Andaman and Nicobar Islands","admin","100608","1356161713"
"Vimmerby","Vimmerby","57.6667","15.8500","Sweden","SE","SWE","Kalmar","minor","100934","1752144282"
"Aboisso","Aboisso","5.4667","-3.2000","Côte d'Ivoire","CI","CIV","Comoé","minor","100903","1384385857"
"Chanwari","Chanwari","23.2000","82.3500","India","IN","IND","Madhya Pradesh","","100800","1356321736"
"Muriaé","Muriae","-21.1306","-42.3664","Brazil","BR","BRA","Minas Gerais","","100765","1076003733"
"Sungailiat","Sungailiat","-1.8561","106.1192","Indonesia","ID","IDN","Kepulauan Bangka Belitung","minor","100750","1360351006"
"New Kru Town","New Kru Town","6.3733","-10.7928","Liberia","LR","LBR","Montserrado","","84399","1430152542"
"Kpalimé","Kpalime","6.9000","0.6333","Togo","TG","TGO","Plateaux","","100479","1768253626"
"Leer","Leer","8.2979","30.1475","South Sudan","SS","SSD","Unity","","100722","1728110399"
"Lee's Summit","Lee's Summit","38.9171","-94.3816","United States","US","USA","Missouri","","100772","1840032155"
"Umuarama","Umuarama","-23.7658","-53.3250","Brazil","BR","BRA","Paraná","","100676","1076793612"
"Nāḩiyat al Iskandarīyah","Nahiyat al Iskandariyah","32.9000","44.3500","Iraq","IQ","IRQ","Bābil","","100600","1368051501"
"Kaiserslautern","Kaiserslautern","49.4447","7.7689","Germany","DE","DEU","Rhineland-Palatinate","minor","100030","1276674534"
"Gangtok","Gangtok","27.3300","88.6200","India","IN","IND","Sikkim","admin","100286","1356689564"
"Diourbel","Diourbel","14.6550","-16.2314","Senegal","SN","SEN","Diourbel","admin","100445","1686087214"
"Ahar","Ahar","38.4847","47.0678","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","100641","1364602437"
"Kōnan","Konan","35.3321","136.8707","Japan","JP","JPN","Aichi","","97435","1392978695"
"Chust","Chust","40.9978","71.2403","Uzbekistan","UZ","UZB","Namangan","","100200","1860392637"
"Quincy","Quincy","42.2506","-71.0187","United States","US","USA","Massachusetts","","100544","1840000461"
"Masjed Soleymān","Masjed Soleyman","31.9364","49.3039","Iran","IR","IRN","Khūzestān","minor","100497","1364152430"
"Geita","Geita","-2.8714","32.2294","Tanzania","TZ","TZA","Geita","admin","99795","1834469543"
"Sumber","Sumber","-6.7544","108.4858","Indonesia","ID","IDN","Jawa Barat","minor","96725","1360755961"
"Mairiporã","Mairipora","-23.3189","-46.5869","Brazil","BR","BRA","São Paulo","","100179","1076161359"
"Torbat-e Jām","Torbat-e Jam","35.2439","60.6225","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","100449","1364064948"
"Lamitan","Lamitan","6.6500","122.1333","Philippines","PH","PHL","Basilan","","100150","1608647250"
"Rincón de Romos","Rincon de Romos","22.2333","-102.3167","Mexico","MX","MEX","Aguascalientes","minor","94978","1484371427"
"Red Deer","Red Deer","52.2681","-113.8111","Canada","CA","CAN","Alberta","","100418","1124404130"
"Shahrisabz","Shahrisabz","39.0500","66.8333","Uzbekistan","UZ","UZB","Qashqadaryo","","100300","1860513278"
"Aubervilliers","Aubervilliers","48.9131","2.3831","France","FR","FRA","Île-de-France","","88948","1250470371"
"Talipao","Talipao","5.9760","121.1087","Philippines","PH","PHL","Sulu","minor","100088","1608300985"
"San Angelo","San Angelo","31.4424","-100.4504","United States","US","USA","Texas","","100330","1840022146"
"Santa Cruz del Quiché","Santa Cruz del Quiche","15.0500","-91.2500","Guatemala","GT","GTM","Quiché","admin","99479","1320642159"
"Lynn","Lynn","42.4781","-70.9664","United States","US","USA","Massachusetts","","100233","1840000409"
"Holland","Holland","42.7677","-86.0984","United States","US","USA","Michigan","","100202","1840002996"
"Zelënodol’sk","Zelenodol'sk","55.8500","48.5167","Russia","RU","RUS","Tatarstan","","100009","1643223486"
"Formosa","Formosa","-15.5369","-47.3339","Brazil","BR","BRA","Goiás","","100085","1076601172"
"Nanxicun","Nanxicun","23.4976","116.2435","China","CN","CHN","Guangdong","","97747","1156911168"
"Bacabal","Bacabal","-4.2250","-44.7800","Brazil","BR","BRA","Maranhão","","100014","1076125117"
"Pyinmana","Pyinmana","19.7500","96.2000","Myanmar","MM","MMR","Nay Pyi Taw","","100001","1104492104"
"Bāmyān","Bamyan","34.8250","67.8333","Afghanistan","AF","AFG","Bāmyān","admin","100000","1004274041"
"Kamloops","Kamloops","50.6761","-120.3408","Canada","CA","CAN","British Columbia","","100046","1124735582"
"Rayleigh","Rayleigh","51.5864","0.6049","United Kingdom","GB","GBR","Essex","","95580","1826491250"
"Dunedin","Dunedin","-45.8742","170.5036","New Zealand","NZ","NZL","Otago","admin","99885","1554475997"
"Nabire","Nabire","-3.3682","135.4964","Indonesia","ID","IDN","Papua Tengah","admin","99848","1360322221"
"Râmnicu Vâlcea","Ramnicu Valcea","45.1047","24.3756","Romania","RO","ROU","Vâlcea","admin","98776","1642348361"
"Ocotlán","Ocotlan","20.3514","-102.7681","Mexico","MX","MEX","Jalisco","minor","94978","1484159296"
"La Libertad","La Libertad","-2.2333","-80.9000","Ecuador","EC","ECU","Guayas","","95942","1218254479"
"Sisophon","Sisophon","13.5833","102.9833","Cambodia","KH","KHM","Banteay Meanchey","admin","99019","1116276411"
"Jinotega","Jinotega","13.0908","-86.0006","Nicaragua","NI","NIC","Jinotega","admin","99382","1558697688"
"Viseu","Viseu","40.6667","-7.9167","Portugal","PT","PRT","Viseu","admin","99274","1620291266"
"Santa Fe","Santa Fe","35.6619","-105.9819","United States","US","USA","New Mexico","admin","99439","1840021707"
"Maribor","Maribor","46.5575","15.6456","Slovenia","SI","SVN","Maribor","admin","97019","1705454394"
"Ashiya","Ashiya","34.7278","135.3033","Japan","JP","JPN","Hyōgo","","94116","1392003382"
"Dhuliān","Dhulian","24.6800","87.9700","India","IN","IND","West Bengal","","95706","1356241829"
"Bayeux","Bayeux","-7.1333","-34.9333","Brazil","BR","BRA","Paraíba","","96140","1076607187"
"Kohīma","Kohima","25.6700","94.1000","India","IN","IND","Nāgāland","admin","99039","1356332206"
"Hemel Hempstead","Hemel Hempstead","51.7526","-0.4692","United Kingdom","GB","GBR","Hertfordshire","","94932","1826374836"
"Sopron","Sopron","47.6849","16.5831","Hungary","HU","HUN","Sopron","admin","98479","1348988570"
"Galle","Galle","6.0536","80.2117","Sri Lanka","LK","LKA","Southern","admin","93118","1144191486"
"Zhudong","Zhudong","24.7366","121.0916","Taiwan","TW","TWN","Hsinchu","","96518","1158713693"
"Ciudad General Belgrano","Ciudad General Belgrano","-34.7167","-58.5333","Argentina","AR","ARG","Buenos Aires","","92957","1032336348"
"Vista","Vista","33.1896","-117.2386","United States","US","USA","California","","98651","1840021994"
"Santiago de Compostela","Santiago de Compostela","42.8778","-8.5444","Spain","ES","ESP","Galicia","admin","98179","1724547778"
"Chipata","Chipata","-13.6453","32.6464","Zambia","ZM","ZMB","Eastern","admin","98416","1894816262"
"San Ramón","San Ramon","-33.5333","-70.6417","Chile","CL","CHL","Región Metropolitana","","94906","1152877656"
"Yashio","Yashio","35.8225","139.8392","Japan","JP","JPN","Saitama","","93283","1392563124"
"Herẕliyya","Herzliyya","32.1653","34.8458","Israel","IL","ISR","Tel Aviv","","93989","1376303805"
"Catarman","Catarman","12.4994","124.6328","Philippines","PH","PHL","Northern Samar","admin","97879","1608432162"
"Bath","Bath","51.3800","-2.3600","United Kingdom","GB","GBR","Bath and North East Somerset","","94782","1826611282"
"Janakpur","Janakpur","26.7286","85.9250","Nepal","NP","NPL","Madhesh","admin","97776","1524563587"
"Tenancingo","Tenancingo","18.9608","-99.5906","Mexico","MX","MEX","México","minor","90946","1484006247"
"San Fernando","San Fernando","36.4667","-6.2000","Spain","ES","ESP","Andalusia","","94867","1724235367"
"Half Way Tree","Half Way Tree","18.0106","-76.7847","Jamaica","JM","JAM","Saint Andrew","admin","96494","1388715163"
"Inagi","Inagi","35.6379","139.5046","Japan","JP","JPN","Tōkyō","","92604","1392003400"
"Vị Thanh","Vi Thanh","9.7833","105.4708","Vietnam","VN","VNM","Hậu Giang","admin","97200","1704210026"
"San Joaquín","San Joaquin","-33.4792","-70.6413","Chile","CL","CHL","Región Metropolitana","","94492","1152095800"
"Isulan","Isulan","6.6333","124.6000","Philippines","PH","PHL","Sultan Kudarat","admin","97490","1608085930"
"Colombes","Colombes","48.9236","2.2522","France","FR","FRA","Île-de-France","","86534","1250390247"
"Petapa","Petapa","14.4962","-90.5525","Guatemala","GT","GTM","Guatemala","minor","94228","1320957558"
"Pernik","Pernik","42.6000","23.0333","Bulgaria","BG","BGR","Pernik","admin","97181","1100616332"
"Sidi Slimane","Sidi Slimane","34.2600","-5.9200","Morocco","MA","MAR","Rabat-Salé-Kénitra","","92989","1504101128"
"Wangqing","Wangqing","43.3126","129.7572","China","CN","CHN","Jilin","minor","88732","1156184335"
"Darlington","Darlington","54.5270","-1.5526","United Kingdom","GB","GBR","Darlington","","92363","1826453946"
"Gävle","Gavle","60.6747","17.1417","Sweden","SE","SWE","Gävleborg","admin","96969","1752586931"
"Sibolga","Sibolga","1.7425","98.7792","Indonesia","ID","IDN","Sumatera Utara","","94971","1360021193"
"Fulham","Fulham","51.4828","-0.1950","United Kingdom","GB","GBR","Hammersmith and Fulham","","87161","1826877782"
"Tumbes","Tumbes","-3.5708","-80.4597","Peru","PE","PER","Tumbes","admin","96946","1604505281"
"Arauca","Arauca","7.0903","-70.7617","Colombia","CO","COL","Arauca","admin","96814","1170322340"
"Levallois-Perret","Levallois-Perret","48.8950","2.2872","France","FR","FRA","Île-de-France","","66082","1250809881"
"Chārīkār","Charikar","35.0131","69.1689","Afghanistan","AF","AFG","Parwān","admin","96093","1004817775"
"La Reina","La Reina","-33.4500","-70.5500","Chile","CL","CHL","Región Metropolitana","","92787","1152154801"
"Orem","Orem","40.2981","-111.6994","United States","US","USA","Utah","","96734","1840020171"
"Sepatan","Sepatan","-6.1143","106.5784","Indonesia","ID","IDN","Jawa Barat","","92353","1360668571"
"Komae","Komae","35.6348","139.5787","Japan","JP","JPN","Tōkyō","","83677","1392000109"
"La Marsa","La Marsa","36.8764","10.3253","Tunisia","TN","TUN","Tunis","","92987","1788911754"
"Yishui","Yishui","35.7904","118.6200","China","CN","CHN","Shandong","","94115","1156736933"
"Marmagao","Marmagao","15.4020","73.8000","India","IN","IND","Goa","","94393","1356764529"
"San Francisco Solano","San Francisco Solano","-34.7667","-58.3167","Argentina","AR","ARG","Buenos Aires","","91322","1032776804"
"Ziftá","Zifta","30.7142","31.2443","Egypt","EG","EGY","Al Gharbīyah","","93740","1818759149"
"Kakamega","Kakamega","0.2822","34.7540","Kenya","KE","KEN","Kakamega","admin","91778","1404614327"
"Schwerin","Schwerin","53.6333","11.4167","Germany","DE","DEU","Mecklenburg-Western Pomerania","admin","95818","1276221707"
"Sunrise","Sunrise","26.1547","-80.2997","United States","US","USA","Florida","","96220","1840016000"
"Labuan","Labuan","5.3000","115.2200","Malaysia","MY","MYS","Labuan","admin","95120","1458460967"
"Guercif","Guercif","34.2333","-3.3667","Morocco","MA","MAR","Oriental","","90880","1504842310"
"Compton","Compton","33.8930","-118.2274","United States","US","USA","California","","96083","1840019240"
"Çankırı","Cankiri","40.5986","33.6192","Turkey","TR","TUR","Çankırı","admin","96025","1792584248"
"Errachidia","Errachidia","31.9319","-4.4244","Morocco","MA","MAR","Drâa-Tafilalet","admin","92374","1504005759"
"Lingtang","Lingtang","23.6032","113.0740","China","CN","CHN","Guangdong","","93835","1156920212"
"Yotsukaidō","Yotsukaido","35.6698","140.1679","Japan","JP","JPN","Chiba","","93233","1392512633"
"České Budějovice","Ceske Budejovice","48.9747","14.4747","Czechia","CZ","CZE","Jihočeský Kraj","admin","94229","1203398046"
"Arden-Arcade","Arden-Arcade","38.6017","-121.3845","United States","US","USA","California","","95909","1840036690"
"Río Gallegos","Rio Gallegos","-51.6233","-69.2161","Argentina","AR","ARG","Santa Cruz","admin","95796","1032471024"
"Fenggang","Fenggang","23.6283","116.5718","China","CN","CHN","Guangdong","","93148","1156163523"
"Tataouine","Tataouine","32.9306","10.4500","Tunisia","TN","TUN","Tataouine","admin","95775","1788300169"
"Tripunittura","Tripunittura","9.9528","76.3387","India","IN","IND","Kerala","","92522","1356068495"
"Ludwigsburg","Ludwigsburg","48.8975","9.1922","Germany","DE","DEU","Baden-Württemberg","minor","93499","1276803720"
"Wajir","Wajir","1.7500","40.0500","Kenya","KE","KEN","Wajir","admin","90116","1404067343"
"Ealing","Ealing","51.5175","-0.2988","United Kingdom","GB","GBR","Ealing","","85014","1826730060"
"Léogâne","Leogane","18.5108","-72.6339","Haiti","HT","HTI","Ouest","","90000","1332045113"
"Nisshin","Nisshin","35.1320","137.0394","Japan","JP","JPN","Aichi","","92823","1392999336"
"Xiancun","Xiancun","23.2374","116.3152","China","CN","CHN","Guangdong","","91109","1156479306"
"Ōbu","Obu","35.0117","136.9637","Japan","JP","JPN","Aichi","","92562","1392870664"
"Al Badrashayn","Al Badrashayn","29.8520","31.2760","Egypt","EG","EGY","Al Jīzah","","90000","1818909949"
"Naxçıvan","Naxcivan","39.2089","45.4122","Azerbaijan","AZ","AZE","Naxçıvan","admin","95100","1031943755"
"Wimbledon","Wimbledon","51.4220","-0.2080","United Kingdom","GB","GBR","Merton","","92765","1826386301"
"Đông Hà","Dong Ha","16.8303","107.0972","Vietnam","VN","VNM","Quảng Trị","admin","93800","1704264596"
"Ílion","Ilion","38.0333","23.7000","Greece","GR","GRC","Attikí","minor","84793","1300000001"
"Netrakona","Netrakona","24.8819","90.7275","Bangladesh","BD","BGD","Mymensingh","","91936","1050803025"
"Ciudad Lázaro Cárdenas","Ciudad Lazaro Cardenas","17.9561","-102.1922","Mexico","MX","MEX","Michoacán","","79200","1484804575"
"Mtwara","Mtwara","-10.2736","40.1828","Tanzania","TZ","TZA","Mtwara","admin","92602","1834044142"
"Ţalkhā","Talkha","31.0547","31.3756","Egypt","EG","EGY","Ad Daqahlīyah","","92084","1818821824"
"Gjakovë","Gjakove","42.3833","20.4333","Kosovo","XK","XKS","Gjakovë","admin","94556","1901089874"
"Prizren","Prizren","42.2128","20.7392","Kosovo","XK","XKS","Prizren","admin","94517","1901360309"
"Bình Hòa","Binh Hoa","10.9061","106.7308","Vietnam","VN","VNM","Bình Dương","","88500","1704063856"
"Ben Arous","Ben Arous","36.7472","10.3333","Tunisia","TN","TUN","Ben Arous","admin","88322","1788439087"
"Watford","Watford","51.6550","-0.3957","United Kingdom","GB","GBR","Hertfordshire","","90301","1826570072"
"Monte Chingolo","Monte Chingolo","-34.7333","-58.3500","Argentina","AR","ARG","Buenos Aires","","85060","1032641177"
"San Isidro","San Isidro","14.4685","121.0118","Philippines","PH","PHL","Parañaque","","69035","1608329833"
"Ben Guerir","Ben Guerir","32.2300","-7.9500","Morocco","MA","MAR","Marrakech-Safi","","88626","1504909564"
"Vanadzor","Vanadzor","40.8128","44.4883","Armenia","AM","ARM","Lorri","admin","90525","1051691929"
"Cisauk","Cisauk","-6.3333","106.6500","Indonesia","ID","IDN","Jawa Barat","","90846","1360929502"
"Hastings","Hastings","50.8500","0.5700","United Kingdom","GB","GBR","East Sussex","","91053","1826499763"
"Drobeta-Turnu Severin","Drobeta-Turnu Severin","44.6333","22.6500","Romania","RO","ROU","Mehedinţi","admin","92617","1642729144"
"Al Fujayrah","Al Fujayrah","25.1223","56.3342","United Arab Emirates","AE","ARE","Al Fujayrah","admin","93673","1784909196"
"Los Cerrillos","Los Cerrillos","-33.5000","-70.7167","Chile","CL","CHL","Región Metropolitana","","89520","1152504242"
"Tartu","Tartu","58.3833","26.7167","Estonia","EE","EST","Tartu","admin","91407","1233809410"
"Makeni","Makeni","8.8817","-12.0442","Sierra Leone","SL","SLE","Northern","admin","87679","1694028641"
"Suceava","Suceava","47.6514","26.2556","Romania","RO","ROU","Suceava","admin","92121","1642721443"
"Amstelveen","Amstelveen","52.3000","4.8500","Netherlands","NL","NLD","Noord-Holland","minor","91675","1528243202"
"Néa Smýrni","Nea Smyrni","37.9500","23.7167","Greece","GR","GRC","Attikí","minor","73076","1300609999"
"Hradec Králové","Hradec Kralove","50.2092","15.8322","Czechia","CZ","CZE","Královéhradecký Kraj","admin","92683","1203430618"
"Nuneaton","Nuneaton","52.5230","-1.4680","United Kingdom","GB","GBR","Warwickshire","","86552","1826240370"
"Şırnak","Sirnak","37.5200","42.4590","Turkey","TR","TUR","Şırnak","admin","93431","1792867633"
"Miaoli","Miaoli","24.5700","120.8200","Taiwan","TW","TWN","Miaoli","admin","90963","1158446494"
"South Gate","South Gate","33.9447","-118.1926","United States","US","USA","California","","93308","1840021870"
"Ciudad de Melilla","Ciudad de Melilla","35.2825","-2.9475","Spain","ES","ESP","Melilla","","86261","1724144891"
"Stevenage","Stevenage","51.9017","-0.2019","United Kingdom","GB","GBR","Hertfordshire","","89663","1826117667"
"Ústí nad Labem","Usti nad Labem","50.6583","14.0417","Czechia","CZ","CZE","Ústecký Kraj","admin","91982","1203734193"
"Orpington","Orpington","51.3741","0.0986","United Kingdom","GB","GBR","Bromley","","90315","1826667369"
"Magadan","Magadan","59.5667","150.8000","Russia","RU","RUS","Magadanskaya Oblast’","admin","92782","1643216966"
"Santa Monica","Santa Monica","34.0235","-118.4810","United States","US","USA","California","","92828","1840021866"
"Pardubice","Pardubice","50.0386","15.7792","Czechia","CZ","CZE","Pardubický Kraj","admin","91755","1203857442"
"Bender","Bender","46.8333","29.4833","Moldova","MD","MDA","Bender","admin","91882","1498257678"
"Oulad Teïma","Oulad Teima","30.4000","-9.2167","Morocco","MA","MAR","Souss-Massa","","89387","1504278611"
"Jaffna","Jaffna","9.6647","80.0167","Sri Lanka","LK","LKA","North Central","admin","88138","1144005506"
"Queluz","Queluz","38.7514","-9.2664","Portugal","PT","PRT","Lisboa","","78273","1620896457"
"Aulnay-sous-Bois","Aulnay-sous-Bois","48.9386","2.4906","France","FR","FRA","Île-de-France","","86969","1250420439"
"Umeå","Umea","63.8250","20.2639","Sweden","SE","SWE","Västerbotten","admin","91916","1752273881"
"Masvingo","Masvingo","-20.0744","30.8328","Zimbabwe","ZW","ZWE","Masvingo","admin","90286","1716049475"
"Brixton","Brixton","51.4575","-0.1175","United Kingdom","GB","GBR","Lambeth","","78536","1826058068"
"Edmonton","Edmonton","51.6154","-0.0708","United Kingdom","GB","GBR","Enfield","","82472","1826905865"
"Settsu","Settsu","34.7772","135.5622","Japan","JP","JPN","Ōsaka","","86168","1392003111"
"Ouidah","Ouidah","6.3667","2.0833","Benin","BJ","BEN","Atlantique","admin","91688","1204541753"
"Hartlepool","Hartlepool","54.6900","-1.2100","United Kingdom","GB","GBR","Hartlepool","","88855","1826069842"
"Wakō","Wako","35.7812","139.6057","Japan","JP","JPN","Saitama","","84120","1392003192"
"Upper Bicutan","Upper Bicutan","14.4873","121.0503","Philippines","PH","PHL","Taguig","","41216","1608556218"
"Cadereyta Jiménez","Cadereyta Jimenez","25.6000","-100.0000","Mexico","MX","MEX","Nuevo León","minor","86445","1484839099"
"Poitiers","Poitiers","46.5800","0.3400","France","FR","FRA","Nouvelle-Aquitaine","minor","89212","1250812174"
"Jabālyā","Jabalya","31.5281","34.4831","Gaza Strip","XG","XGZ","","","82877","1916175096"
"Westminster","Westminster","33.7523","-117.9938","United States","US","USA","California","","91267","1840021969"
"Fuengirola","Fuengirola","36.5417","-4.6250","Spain","ES","ESP","Andalusia","","83226","1724094287"
"Chester","Chester","53.1900","-2.8900","United Kingdom","GB","GBR","Cheshire West and Chester","","87507","1826441668"
"Lobnya","Lobnya","56.0167","37.4833","Russia","RU","RUS","Moskovskaya Oblast’","","88220","1643040713"
"San Leandro","San Leandro","37.7074","-122.1599","United States","US","USA","California","","91176","1840021538"
"Hemei","Hemei","24.1167","120.5000","Taiwan","TW","TWN","Changhua","","88740","1158367413"
"Solwezi","Solwezi","-12.1433","26.3858","Zambia","ZM","ZMB","North-Western","admin","90856","1894777820"
"Grand Bourg","Grand Bourg","-34.4833","-58.7167","Argentina","AR","ARG","Buenos Aires","","85189","1032455832"
"Kalibo","Kalibo","11.7072","122.3700","Philippines","PH","PHL","Aklan","admin","89127","1608268539"
"San Antonio","San Antonio","14.4656","121.0311","Philippines","PH","PHL","Parañaque","","67401","1608229793"
"Ama","Ama","35.2004","136.7832","Japan","JP","JPN","Aichi","","87668","1392003383"
"Abomey","Abomey","7.1856","1.9881","Benin","BJ","BEN","Zou","admin","90195","1204597697"
"Zhunan","Zhunan","24.6833","120.8833","Taiwan","TW","TWN","Miaoli","","88465","1158606269"
"Glyfáda","Glyfada","37.8667","23.7500","Greece","GR","GRC","Attikí","minor","87305","1300003373"
"Kitanagoya","Kitanagoya","35.2456","136.8659","Japan","JP","JPN","Aichi","","86060","1392360419"
"Remedios de Escalada","Remedios de Escalada","-34.7167","-58.4000","Argentina","AR","ARG","Buenos Aires","","81465","1032150292"
"Babahoyo","Babahoyo","-1.8167","-79.5167","Ecuador","EC","ECU","Los Ríos","admin","90191","1218156392"
"Jangipur","Jangipur","24.4700","88.0700","India","IN","IND","West Bengal","","88165","1356655968"
"Acayucan","Acayucan","17.9422","-94.9103","Mexico","MX","MEX","Veracruz","minor","83817","1484349924"
"Zhezqazghan","Zhezqazghan","47.7833","67.7000","Kazakhstan","KZ","KAZ","","admin","90661","1398121545"
"Dobrich","Dobrich","43.5667","27.8333","Bulgaria","BG","BGR","Dobrich","admin","90419","1100364404"
"Xicotepec de Juárez","Xicotepec de Juarez","20.3000","-97.9667","Mexico","MX","MEX","Puebla","","81455","1484427246"
"Ţarţūs","Tartus","34.8833","35.8833","Syria","SY","SYR","Ţarţūs","admin","89457","1760695473"
"Higashiyamato","Higashiyamato","35.7454","139.4265","Japan","JP","JPN","Saitama","","84260","1392233405"
"Catanzaro","Catanzaro","38.9000","16.6000","Italy","IT","ITA","Calabria","admin","89718","1380650072"
"East Ham","East Ham","51.5323","0.0554","United Kingdom","GB","GBR","Newham","","76186","1826587417"
"Kennedy Town","Kennedy Town","22.2800","114.1250","Hong Kong","HK","HKG","","","54000","1344000050"
"Wakiso","Wakiso","0.3981","32.4781","Uganda","UG","UGA","Wakiso","admin","87900","1800102352"
"Valjevo","Valjevo","44.2667","19.8833","Serbia","RS","SRB","Valjevo","admin","90312","1688926375"
"Bromley","Bromley","51.4070","0.0210","United Kingdom","GB","GBR","Bromley","","87889","1826385764"
"Hayes","Hayes","51.5127","-0.4211","United Kingdom","GB","GBR","Hillingdon","","83564","1826539158"
"Germantown","Germantown","39.1755","-77.2643","United States","US","USA","Maryland","","90235","1840005840"
"Jalal-Abad","Jalal-Abad","40.9333","73.0000","Kyrgyzstan","KG","KGZ","Jalal-Abad","admin","89004","1417653652"
"Bhola","Bhola","22.6863","90.6461","Bangladesh","BD","BGD","Barishal","","87243","1050857393"
"Tala","Tala","20.6525","-103.7028","Mexico","MX","MEX","Jalisco","minor","80365","1484003075"
"Yoro","Yoro","15.1333","-87.1333","Honduras","HN","HND","Yoro","admin","86665","1340638121"
"Prešov","Presov","49.0017","21.2394","Slovakia","SK","SVK","Prešov","admin","88680","1703231427"
"Bismarck","Bismarck","46.8143","-100.7694","United States","US","USA","North Dakota","admin","89825","1840001926"
"Warabi","Warabi","35.8256","139.6797","Japan","JP","JPN","Saitama","","74996","1392003138"
"Balkanabat","Balkanabat","39.5167","54.3667","Turkmenistan","TM","TKM","Balkan","admin","89785","1795885709"
"Ipil","Ipil","7.7822","122.5867","Philippines","PH","PHL","Zamboanga Sibugay","admin","89401","1608307222"
"Takasagochō-takasemachi","Takasagocho-takasemachi","34.7667","134.7833","Japan","JP","JPN","Hyōgo","","87220","1392922621"
"Perintalmanna","Perintalmanna","10.9765","76.2269","India","IN","IND","Kerala","","87356","1356811920"
"Mechelen","Mechelen","51.0278","4.4806","Belgium","BE","BEL","Flanders","minor","86921","1056687706"
"As Salţ","As Salt","32.0333","35.7333","Jordan","JO","JOR","Al Balqā’","admin","88900","1400470384"
"Clifton","Clifton","40.8630","-74.1575","United States","US","USA","New Jersey","","89460","1840003511"
"Mukacheve","Mukacheve","48.4414","22.7136","Ukraine","UA","UKR","Zakarpatska Oblast","minor","86257","1804401780"
"Tangxing","Tangxing","35.7261","111.7108","China","CN","CHN","Shanxi","minor","86862","1156031027"
"Chinnachauku","Chinnachauku","14.4732","78.8451","India","IN","IND","Andhra Pradesh","","85442","1356975526"
"Nabatîyé","Nabatiye","33.3833","35.4500","Lebanon","LB","LBN","Nabatîyé","admin","80000","1422269278"
"Daxincun","Daxincun","38.4427","106.3110","China","CN","CHN","Ningxia","","76536","1156044428"
"Al Wakrah","Al Wakrah","25.1800","51.6100","Qatar","QA","QAT","Al Wakrah","admin","87970","1634261569"
"Bayanan","Bayanan","14.4078","121.0500","Philippines","PH","PHL","Muntinlupa","","39150","1608397045"
"Viana do Castelo","Viana do Castelo","41.7000","-8.8333","Portugal","PT","PRT","Viana do Castelo","admin","88725","1620680440"
"Apizaco","Apizaco","19.4167","-98.1333","Mexico","MX","MEX","Tlaxcala","minor","81565","1484021647"
"Aylesbury","Aylesbury","51.8168","-0.8124","United Kingdom","GB","GBR","Buckinghamshire","","83407","1826391298"
"Sesto San Giovanni","Sesto San Giovanni","45.5333","9.2333","Italy","IT","ITA","Lombardy","","81773","1380068715"
"Podujevë","Podujeve","42.9167","21.2000","Kosovo","XK","XKS","Podujevë","admin","88499","1901550082"
"State College","State College","40.7909","-77.8567","United States","US","USA","Pennsylvania","","88526","1840000830"
"Prosperidad","Prosperidad","8.6057","125.9153","Philippines","PH","PHL","Agusan del Sur","admin","88321","1608461552"
"Bertoua","Bertoua","4.5833","13.6833","Cameroon","CM","CMR","Est","admin","88462","1120406967"
"Alabel","Alabel","6.1023","125.2868","Philippines","PH","PHL","Sarangani","admin","88294","1608579431"
"Versailles","Versailles","48.8053","2.1350","France","FR","FRA","Île-de-France","minor","85205","1250143827"
"Antehiroka","Antehiroka","-18.8500","47.4833","Madagascar","MG","MDG","Antananarivo","","80360","1450838187"
"Coronel Oviedo","Coronel Oviedo","-25.4167","-56.4500","Paraguay","PY","PRY","Caaguazú","admin","88101","1600075989"
"Dārayyā","Darayya","33.4500","36.2500","Syria","SY","SYR","Rīf Dimashq","minor","84044","1760409750"
"Ayase","Ayase","35.4333","139.4333","Japan","JP","JPN","Kanagawa","","84257","1392003387"
"Ciudad de Ceuta","Ciudad de Ceuta","35.8867","-5.3000","Spain","ES","ESP","Ceuta","","83517","1724324416"
"Lower Bicutan","Lower Bicutan","14.5033","121.0642","Philippines","PH","PHL","Taguig","","49829","1608620171"
"Ébolowa","Ebolowa","2.9167","11.1500","Cameroon","CM","CMR","Sud","admin","87875","1120924207"
"Sabaneta","Sabaneta","6.1500","-75.6000","Colombia","CO","COL","Antioquia","minor","82375","1170000611"
"Clichy","Clichy","48.9044","2.3064","France","FR","FRA","Île-de-France","","63089","1250104726"
"Torre del Greco","Torre del Greco","40.7853","14.3953","Italy","IT","ITA","Campania","","85332","1380905401"
"Hawthorne","Hawthorne","33.9147","-118.3476","United States","US","USA","California","","87863","1840020486"
"Maasin","Maasin","10.1300","124.8500","Philippines","PH","PHL","Southern Leyte","admin","87446","1608244406"
"San Juan de los Morros","San Juan de los Morros","9.9010","-67.3540","Venezuela","VE","VEN","Guárico","admin","87739","1862865294"
"Lawrence","Lawrence","42.7002","-71.1626","United States","US","USA","Massachusetts","","87798","1840000408"
"Salina Cruz","Salina Cruz","16.1833","-95.2000","Mexico","MX","MEX","Oaxaca","","81063","1484364230"
"San Baudilio de Llobregat","San Baudilio de Llobregat","41.3458","2.0417","Spain","ES","ESP","Catalonia","","83755","1724214083"
"Citrus Heights","Citrus Heights","38.6948","-121.2880","United States","US","USA","California","","87521","1840018845"
"Athurugiriya","Athurugiriya","6.8922","79.9428","Sri Lanka","LK","LKA","Western","","84278","1144399714"
"Edgware","Edgware","51.6185","-0.2729","United Kingdom","GB","GBR","Barnet","","76506","1826048876"
"Burnley","Burnley","53.7890","-2.2480","United Kingdom","GB","GBR","Lancashire","","82002","1826565522"
"Whittier","Whittier","33.9678","-118.0188","United States","US","USA","California","","87184","1840021878"
"Mardin","Mardin","37.3131","40.7350","Turkey","TR","TUR","Mardin","admin","86948","1792546179"
"Xishancun","Xishancun","23.2589","116.2388","China","CN","CHN","Guangdong","","83822","1156026460"
"Deurne","Deurne","51.2247","4.4625","Belgium","BE","BEL","Flanders","","80781","1056636125"
"Mpanda","Mpanda","-6.3500","31.0667","Tanzania","TZ","TZA","Katavi","admin","81540","1834127573"
"Ezpeleta","Ezpeleta","-34.7517","-58.2344","Argentina","AR","ARG","Buenos Aires","","79557","1032462589"
"Larnaca","Larnaca","34.9167","33.6333","Cyprus","CY","CYP","Lárnaka","admin","84900","1196804795"
"Mingxing","Mingxing","37.4264","112.5442","China","CN","CHN","Shanxi","minor","82981","1156050784"
"Mādabā","Madaba","31.7167","35.8000","Jordan","JO","JOR","Mādabā","admin","84600","1400962992"
"Nakhon Sawan","Nakhon Sawan","15.7133","100.1353","Thailand","TH","THA","Nakhon Sawan","admin","86439","1764821078"
"Saint Albans","Saint Albans","51.7550","-0.3360","United Kingdom","GB","GBR","Hertfordshire","","82146","1826053841"
"Villa Celina","Villa Celina","-34.7006","-58.4825","Argentina","AR","ARG","Buenos Aires, Ciudad Autónoma de","","78699","1032320584"
"Issy-les-Moulineaux","Issy-les-Moulineaux","48.8239","2.2700","France","FR","FRA","Île-de-France","","68260","1250923546"
"Loznica","Loznica","44.5333","19.2258","Serbia","RS","SRB","Loznica","admin","86413","1688071221"
"Tucupita","Tucupita","9.0575","-62.0452","Venezuela","VE","VEN","Delta Amacuro","admin","86487","1862849758"
"Ath Thawrah","Ath Thawrah","35.8367","38.5481","Syria","SY","SYR","Ar Raqqah","minor","84000","1760605225"
"Tacámbaro de Codallos","Tacambaro de Codallos","19.2356","-101.4569","Mexico","MX","MEX","Michoacán","minor","76661","1484508699"
"Odienné","Odienne","9.5000","-7.5667","Côte d'Ivoire","CI","CIV","Denguélé","admin","86279","1384967993"
"Keratsíni","Keratsini","37.9667","23.6167","Greece","GR","GRC","Attikí","minor","77077","1300341321"
"Ciudad Lerdo","Ciudad Lerdo","25.5500","-103.5167","Mexico","MX","MEX","Durango","minor","79669","1484980436"
"Owariasahi","Owariasahi","35.2165","137.0354","Japan","JP","JPN","Aichi","","82336","1392703321"
"Piatra Neamţ","Piatra Neamt","46.9275","26.3708","Romania","RO","ROU","Neamţ","admin","85055","1642444635"
"Deerfield Beach","Deerfield Beach","26.3050","-80.1277","United States","US","USA","Florida","","86062","1840015136"
"San Fernando","San Fernando","10.2833","-61.4667","Trinidad and Tobago","TT","TTO","San Fernando","admin","82997","1780361077"
"Kouvola","Kouvola","60.8681","26.7042","Finland","FI","FIN","Kymenlaakso","admin","85855","1246167296"
"Kuznetsk","Kuznetsk","53.1167","46.6000","Russia","RU","RUS","Penzenskaya Oblast’","","82276","1643787798"
"Toledo","Toledo","39.8567","-4.0244","Spain","ES","ESP","Castille-La Mancha","admin","85449","1724171411"
"Busto Arsizio","Busto Arsizio","45.6120","8.8518","Italy","IT","ITA","Lombardy","","83045","1380033378"
"El Bayadh","El Bayadh","33.6831","1.0192","Algeria","DZ","DZA","El Bayadh","admin","85577","1012906892"
"Ponnāni","Ponnani","10.7700","75.9000","India","IN","IND","Kerala","","83704","1356710596"
"Karakol","Karakol","42.4903","78.3917","Kyrgyzstan","KG","KGZ","Ysyk-Köl","admin","85588","1417269527"
"Como","Como","45.8103","9.0861","Italy","IT","ITA","Lombardy","minor","83320","1380994905"
"Shumen","Shumen","43.2833","26.9333","Bulgaria","BG","BGR","Shumen","admin","85410","1100000004"
"Xuddur","Xuddur","4.1200","43.8878","Somalia","SO","SOM","Bakool","admin","85500","1706568226"
"Ágios Dimítrios","Agios Dimitrios","37.9333","23.7333","Greece","GR","GRC","Attikí","minor","71294","1300159419"
"Pori","Pori","61.4833","21.8000","Finland","FI","FIN","Satakunta","admin","85363","1246259412"
"Manfalūţ","Manfalut","27.3167","30.9667","Egypt","EG","EGY","Asyūţ","","82585","1818076217"
"Mitrovicë","Mitrovice","42.8833","20.8667","Kosovo","XK","XKS","","admin","85360","1901138430"
"Baj Baj","Baj Baj","22.4828","88.1818","India","IN","IND","West Bengal","","76837","1356102140"
"Cozumel","Cozumel","20.5104","-86.9493","Mexico","MX","MEX","Quintana Roo","minor","77236","1484935305"
"Louga","Louga","15.6167","-16.2167","Senegal","SN","SEN","Louga","admin","85075","1686269931"
"Atakpamé","Atakpame","7.5269","1.1267","Togo","TG","TGO","Plateaux","admin","84979","1768070717"
"Puerto Maldonado","Puerto Maldonado","-12.6000","-69.1833","Peru","PE","PER","Madre de Dios","admin","85024","1604871130"
"Heṭauḍā","Hetauda","27.4167","85.0333","Nepal","NP","NPL","Bāgmatī","admin","84671","1524551914"
"Upper Darby","Upper Darby","39.9490","-75.2891","United States","US","USA","Pennsylvania","","84986","1840035234"
"Cicero","Cicero","41.8445","-87.7593","United States","US","USA","Illinois","","84905","1840009186"
"Veliko Tarnovo","Veliko Tarnovo","43.0778","25.6167","Bulgaria","BG","BGR","Veliko Tarnovo","admin","84801","1100134696"
"Dar el Beïda","Dar el Beida","36.7142","3.2125","Algeria","DZ","DZA","Alger","","81509","1012987727"
"Purmerend","Purmerend","52.5000","4.9500","Netherlands","NL","NLD","Noord-Holland","minor","81515","1528918720"
"Nazareth","Nazareth","32.7019","35.3033","Israel","IL","ISR","Northern","admin","83400","1376505625"
"Uman","Uman","48.7500","30.2167","Ukraine","UA","UKR","Cherkaska Oblast","minor","82762","1804670442"
"Nabunturan","Nabunturan","7.6008","125.9656","Philippines","PH","PHL","Davao de Oro","admin","84340","1608879246"
"Nagaoka","Nagaoka","34.9333","135.7000","Japan","JP","JPN","Kyōto","","80423","1392219533"
"Osijek","Osijek","45.5556","18.6944","Croatia","HR","HRV","Osječko-Baranjska Županija","admin","84104","1191917921"
"Chervonohrad","Chervonohrad","50.3822","24.2275","Ukraine","UA","UKR","Lvivska Oblast","minor","82395","1804958186"
"Lucerne","Lucerne","47.0500","8.3000","Switzerland","CH","CHE","Luzern","admin","81691","1756544536"
"Būmahen","Bumahen","35.7297","51.8619","Iran","IR","IRN","Tehrān","","79034","1364656518"
"Kunitachi","Kunitachi","35.6839","139.4414","Japan","JP","JPN","Tōkyō","","75133","1392309515"
"Shek Tong Tsui","Shek Tong Tsui","22.2871","114.1357","Hong Kong","HK","HKG","","","17170","1344000053"
"Pine Hills","Pine Hills","28.5818","-81.4692","United States","US","USA","Florida","","84289","1840014098"
"Tecate","Tecate","32.5728","-116.6267","Mexico","MX","MEX","Baja California","minor","81059","1484547809"
"Newmarket","Newmarket","44.0500","-79.4667","Canada","CA","CAN","Ontario","","84224","1124400266"
"Ilioúpoli","Ilioupoli","37.9333","23.7500","Greece","GR","GRC","Attikí","minor","78153","1300079206"
"Barahona","Barahona","18.2000","-71.1000","Dominican Republic","DO","DOM","Enriquillo","admin","83619","1214900129"
"Ho","Ho","6.6119","0.4703","Ghana","GH","GHA","Volta","admin","83715","1288938914"
"Phuket","Phuket","7.8881","98.3975","Thailand","TH","THA","Phuket","admin","77610","1764327831"
"Le Bardo","Le Bardo","36.8092","10.1406","Tunisia","TN","TUN","Tunis","","73953","1788939893"
"Buena Park","Buena Park","33.8572","-118.0046","United States","US","USA","California","","83974","1840019324"
"Kayes","Kayes","14.4500","-11.4167","Mali","ML","MLI","Kayes","admin","77207","1466546429"
"Champigny-sur-Marne","Champigny-sur-Marne","48.8172","2.5156","France","FR","FRA","Île-de-France","","77039","1250631569"
"Haskovo","Haskovo","41.9333","25.5667","Bulgaria","BG","BGR","Haskovo","admin","83682","1100186247"
"Chatham","Chatham","51.3700","0.5200","United Kingdom","GB","GBR","Medway","","76792","1826765672"
"Batley","Batley","53.7167","-1.6356","United Kingdom","GB","GBR","Kirklees","","80485","1826037459"
"Esteio","Esteio","-29.8608","-51.1789","Brazil","BR","BRA","Rio Grande do Sul","","80755","1076924823"
"Reşiţa","Resita","45.3008","21.8892","Romania","RO","ROU","Caraş-Severin","admin","83324","1642047399"
"Rueil-Malmaison","Rueil-Malmaison","48.8760","2.1810","France","FR","FRA","Île-de-France","","78317","1250924511"
"Shinkai","Shinkai","35.8367","139.5803","Japan","JP","JPN","Saitama","","75247","1392023081"
"Miami Beach","Miami Beach","25.8171","-80.1396","United States","US","USA","Florida","","83469","1840015150"
"Kirtipur","Kirtipur","27.6667","85.2833","Nepal","NP","NPL","","","65602","1524402867"
"Casoria","Casoria","40.9000","14.3000","Italy","IT","ITA","Campania","","77087","1380040521"
"Tallaght","Tallaght","53.2886","-6.3572","Ireland","IE","IRL","South Dublin","admin","80339","1372547040"
"West Rembo","West Rembo","14.5667","121.0667","Philippines","PH","PHL","Makati","","29649","1608832412"
"Scunthorpe","Scunthorpe","53.5809","-0.6502","United Kingdom","GB","GBR","North Lincolnshire","","79977","1826817883"
"Schiedam","Schiedam","51.9167","4.4000","Netherlands","NL","NLD","Zuid-Holland","minor","79279","1528910170"
"Dudley","Dudley","52.5080","-2.0890","United Kingdom","GB","GBR","Dudley","","79379","1826052828"
"Alhambra","Alhambra","34.0840","-118.1355","United States","US","USA","California","","83174","1840019222"
"Ban Talat Rangsit","Ban Talat Rangsit","13.9833","100.6167","Thailand","TH","THA","Pathum Thani","","79319","1764039814"
"Silao","Silao","20.9478","-101.4281","Mexico","MX","MEX","Guanajuato","minor","74242","1484276023"
"Târgu Jiu","Targu Jiu","45.0342","23.2747","Romania","RO","ROU","Gorj","admin","82504","1642174891"
"El Palomar","El Palomar","-34.6167","-58.5833","Argentina","AR","ARG","Buenos Aires","","74757","1032243275"
"Al Khānkah","Al Khankah","30.1601","31.3133","Egypt","EG","EGY","Al Qalyūbīyah","","78185","1818549245"
"Kiyose","Kiyose","35.7857","139.5265","Japan","JP","JPN","Saitama","","75389","1392003108"
"Owendo","Owendo","0.2833","9.5000","Gabon","GA","GAB","Estuaire","","79300","1266006431"
"Tanuku","Tanuku","16.7500","81.7000","India","IN","IND","Andhra Pradesh","","77962","1356546052"
"Silver Spring","Silver Spring","39.0028","-77.0207","United States","US","USA","Maryland","","82472","1840005845"
"Lakewood","Lakewood","33.8471","-118.1222","United States","US","USA","California","","82419","1840020515"
"Mountain View","Mountain View","37.4001","-122.0796","United States","US","USA","California","","82409","1840020332"
"Juchitán de Zaragoza","Juchitan de Zaragoza","16.4333","-95.0194","Mexico","MX","MEX","Oaxaca","minor","74825","1484386317"
"White Rock","White Rock","49.0250","-122.8028","Canada","CA","CAN","British Columbia","","82368","1124260555"
"Elbasan","Elbasan","41.1111","20.0806","Albania","AL","ALB","Elbasan","admin","78703","1008409270"
"Campo Formoso","Campo Formoso","-10.5089","-40.3208","Brazil","BR","BRA","Bahia","","72271","1076847580"
"Évosmos","Evosmos","40.6689","22.9083","Greece","GR","GRC","Kentrikí Makedonía","minor","74686","1300891029"
"Weston-super-Mare","Weston-super-Mare","51.3460","-2.9770","United Kingdom","GB","GBR","North Somerset","","76143","1826189795"
"Néa Ionía","Nea Ionia","38.0333","23.7500","Greece","GR","GRC","Attikí","minor","67134","1300842760"
"Zaandam","Zaandam","52.4333","4.8333","Netherlands","NL","NLD","Noord-Holland","minor","78682","1528041299"
"Paisley","Paisley","55.8456","-4.4239","United Kingdom","GB","GBR","Renfrewshire","","77220","1826549531"
"South Shields","South Shields","54.9950","-1.4300","United Kingdom","GB","GBR","South Tyneside","","75337","1826396004"
"Saint-Maur-des-Fossés","Saint-Maur-des-Fosses","48.7994","2.4997","France","FR","FRA","Île-de-France","","75298","1250687119"
"Bilecik","Bilecik","40.1431","29.9792","Turkey","TR","TUR","Bilecik","admin","81723","1792408667"
"Huolu","Huolu","38.0874","114.3159","China","CN","CHN","Hebei","minor","78250","1156486055"
"Handeni","Handeni","-5.4242","38.0194","Tanzania","TZ","TZA","Tanga","","79056","1834479617"
"Bel-Air","Bel-Air","14.5639","121.0263","Philippines","PH","PHL","Makati","","36007","1608102267"
"Banī Mazār","Bani Mazar","28.5000","30.8000","Egypt","EG","EGY","Al Minyā","","79553","1818729843"
"Daugavpils","Daugavpils","55.8750","26.5356","Latvia","LV","LVA","Augšdaugava","admin","80627","1428924928"
"Ivanteyevka","Ivanteyevka","55.9833","37.9333","Russia","RU","RUS","Moskovskaya Oblast’","","76612","1643986494"
"Drancy","Drancy","48.9300","2.4500","France","FR","FRA","Île-de-France","","72376","1250144343"
"Los Reyes de Salgado","Los Reyes de Salgado","19.5833","-102.4667","Mexico","MX","MEX","Michoacán","","69723","1484279186"
"Cinisello Balsamo","Cinisello Balsamo","45.5500","9.2167","Italy","IT","ITA","Lombardy","","75723","1380556172"
"Kashiba","Kashiba","34.5333","135.7000","Japan","JP","JPN","Nara","","78346","1392003075"
"Hakkari","Hakkari","37.5770","43.7390","Turkey","TR","TUR","Hakkâri","admin","81424","1792629133"
"Rubí","Rubi","41.4933","2.0325","Spain","ES","ESP","Catalonia","","79007","1724552367"
"Bury","Bury","53.5930","-2.2980","United Kingdom","GB","GBR","Bury","","78729","1826242195"
"Le Kram","Le Kram","36.8333","10.3167","Tunisia","TN","TUN","Tunis","","74132","1788996070"
"Arāria","Araria","26.1500","87.5200","India","IN","IND","Bihār","","79021","1356297542"
"Dome","Dome","5.6550","-0.2350","Ghana","GH","GHA","Greater Accra","","78785","1288419315"
"Chalándri","Chalandri","38.0167","23.8000","Greece","GR","GRC","Attikí","minor","74192","1300443208"
"Târgovişte","Targoviste","44.9244","25.4572","Romania","RO","ROU","Dâmboviţa","admin","79610","1642534646"
"Galway","Galway","53.2719","-9.0489","Ireland","IE","IRL","Galway","admin","79504","1372499968"
"Barreiro","Barreiro","38.6667","-9.0667","Portugal","PT","PRT","Setúbal","minor","78764","1620760195"
"Paysandú","Paysandu","-32.3214","-58.0756","Uruguay","UY","URY","Paysandú","admin","76429","1858756515"
"Lomas del Mirador","Lomas del Mirador","-34.6667","-58.5297","Argentina","AR","ARG","Buenos Aires","","71479","1032639381"
"Cabadbaran","Cabadbaran","9.1228","125.5346","Philippines","PH","PHL","Agusan del Norte","admin","80354","1608889253"
"New Rochelle","New Rochelle","40.9304","-73.7836","United States","US","USA","New York","","80685","1840000782"
"Tejupilco","Tejupilco","18.9058","-100.1528","Mexico","MX","MEX","México","minor","71077","1484506998"
"Ciudadela","Ciudadela","-34.6333","-58.5333","Argentina","AR","ARG","Buenos Aires","","73155","1032060423"
"Aigáleo","Aigaleo","37.9920","23.6780","Greece","GR","GRC","Attikí","minor","69946","1300067899"
"Viedma","Viedma","-40.8000","-63.0000","Argentina","AR","ARG","Río Negro","admin","80632","1032613063"
"Ragusa","Ragusa","36.9250","14.7306","Italy","IT","ITA","Sicilia","minor","73638","1380117871"
"Lebanon","Lebanon","40.3412","-76.4228","United States","US","USA","Pennsylvania","","80620","1840001347"
"Somerville","Somerville","42.3908","-71.1014","United States","US","USA","Massachusetts","","80608","1840003123"
"Focşani","Focsani","45.7000","27.1797","Romania","RO","ROU","Vrancea","admin","79315","1642736232"
"Zuwārah","Zuwarah","32.9333","12.0833","Libya","LY","LBY","An Nuqāţ al Khams","admin","80310","1434206265"
"Quatre Bornes","Quatre Bornes","-20.2654","57.4791","Mauritius","MU","MUS","Plaines Wilhems","","77308","1480144531"
"Yauco","Yauco","18.0344","-66.8615","Puerto Rico","PR","PRI","Puerto Rico","","80307","1630035600"
"Shancheng","Shancheng","34.7904","116.0800","China","CN","CHN","Shandong","","74459","1156514157"
"Kuniyamuttūr","Kuniyamuttur","10.9638","76.9525","India","IN","IND","Tamil Nādu","","77748","1356159324"
"Fnidq","Fnidq","35.8500","-5.3500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","77436","1504670555"
"Sremska Mitrovica","Sremska Mitrovica","44.9700","19.6125","Serbia","RS","SRB","Sremska Mitrovica","admin","79940","1688974283"
"Puerto Ayacucho","Puerto Ayacucho","5.6631","-67.6264","Venezuela","VE","VEN","Amazonas","admin","80000","1862027409"
"Chiquimula","Chiquimula","14.7833","-89.5333","Guatemala","GT","GTM","Chiquimula","admin","79815","1320041079"
"Sidi Qacem","Sidi Qacem","34.2167","-5.7000","Morocco","MA","MAR","Rabat-Salé-Kénitra","","75672","1504300626"
"Kirdāsah","Kirdasah","30.0320","31.1104","Egypt","EG","EGY","Al Jīzah","","69317","1818864361"
"La Rochelle","La Rochelle","46.1600","-1.1500","France","FR","FRA","Nouvelle-Aquitaine","minor","77205","1250623782"
"Izumiōtsu","Izumiotsu","34.5000","135.4000","Japan","JP","JPN","Ōsaka","","74175","1392003429"
"Lelystad","Lelystad","52.5000","5.4833","Netherlands","NL","NLD","Flevoland","admin","79811","1528990721"
"Tustin","Tustin","33.7311","-117.8107","United States","US","USA","California","","79895","1840021967"
"Alabang","Alabang","14.4184","121.0385","Philippines","PH","PHL","Muntinlupa","","71075","1608950948"
"Mansfield","Mansfield","53.1444","-1.1964","United Kingdom","GB","GBR","Nottinghamshire","","77551","1826359576"
"Bracknell","Bracknell","51.4160","-0.7490","United Kingdom","GB","GBR","Bracknell Forest","","77256","1826880896"
"Balagtas","Balagtas","14.8145","120.9085","Philippines","PH","PHL","Bulacan","","77018","1608000709"
"Avilés","Aviles","43.5561","-5.9083","Spain","ES","ESP","Asturias","","76874","1724815050"
"Nakhon Pathom","Nakhon Pathom","13.8206","100.0625","Thailand","TH","THA","Nakhon Pathom","admin","78780","1764251992"
"Mahdia","Mahdia","35.5000","11.0667","Tunisia","TN","TUN","Mahdia","admin","79545","1788440786"
"Milpitas","Milpitas","37.4336","-121.8921","United States","US","USA","California","","79593","1840020329"
"Ubon Ratchathani","Ubon Ratchathani","15.2281","104.8594","Thailand","TH","THA","Ubon Ratchathani","admin","79023","1764611048"
"Bistriţa","Bistrita","47.1333","24.5000","Romania","RO","ROU","Bistriţa-Năsăud","admin","78877","1642633889"
"Weligama","Weligama","5.9739","80.4294","Sri Lanka","LK","LKA","Southern","","72511","1144776498"
"Carlisle","Carlisle","54.8910","-2.9440","United Kingdom","GB","GBR","Cumbria","","75399","1826843688"
"Garissa","Garissa","-0.4569","39.6583","Kenya","KE","KEN","Garissa","admin","65881","1404464692"
"Lisala","Lisala","2.1486","21.5136","Congo (Kinshasa)","CD","COD","Mongala","admin","79235","1180477995"
"Nitra","Nitra","48.3069","18.0864","Slovakia","SK","SVK","Nitra","admin","78489","1703890031"
"Zográfos","Zografos","37.9783","23.7717","Greece","GR","GRC","Attikí","minor","71026","1300851737"
"East Kilbride","East Kilbride","55.7644","-4.1769","United Kingdom","GB","GBR","South Lanarkshire","","75120","1826488452"
"Bellflower","Bellflower","33.8880","-118.1271","United States","US","USA","California","","79070","1840019230"
"Frontera","Frontera","26.9260","-101.4490","Mexico","MX","MEX","Coahuila","minor","75242","1484811972"
"Banská Bystrica","Banska Bystrica","48.7353","19.1453","Slovakia","SK","SVK","Banská Bystrica","admin","78327","1703015198"
"Katano","Katano","34.7833","135.6833","Japan","JP","JPN","Ōsaka","","76002","1392003379"
"Ra‘ananna","Ra\`ananna","32.1833","34.8667","Israel","IL","ISR","Central","","74000","1376791991"
"Perote","Perote","19.5620","-97.2420","Mexico","MX","MEX","Veracruz","minor","72795","1484668149"
"Kafr az Zayyāt","Kafr az Zayyat","30.8247","30.8153","Egypt","EG","EGY","Al Gharbīyah","","76413","1818335276"
"Aveiro","Aveiro","40.6333","-8.6500","Portugal","PT","PRT","Aveiro","admin","78450","1620555869"
"Tambacounda","Tambacounda","13.7689","-13.6672","Senegal","SN","SEN","Tambacounda","admin","78800","1686195160"
"Burton upon Trent","Burton upon Trent","52.8019","-1.6367","United Kingdom","GB","GBR","Staffordshire","","75074","1826585602"
"Pau","Pau","43.3000","-0.3700","France","FR","FRA","Nouvelle-Aquitaine","minor","76275","1250041150"
"Ungoofaaru","Ungoofaaru","5.6681","73.0306","Maldives","MV","MDV","Maalhosmadulu Uthuruburi","admin","2501","1462941721"
"Evanston","Evanston","42.0464","-87.6943","United States","US","USA","Illinois","","78454","1840008129"
"Kensington","Kensington","51.5000","-0.1900","United Kingdom","GB","GBR","Kensington and Chelsea","","64681","1826583042"
"Thornton Heath","Thornton Heath","51.4002","-0.1086","United Kingdom","GB","GBR","Croydon","","65812","1826002522"
"Faranah","Faranah","10.0333","-10.7333","Guinea","GN","GIN","Faranah","admin","78108","1324603099"
"Bokhtar","Bokhtar","37.8364","68.7803","Tajikistan","TJ","TJK","Khatlon","admin","75450","1762998971"
"Moulay Abdallah","Moulay Abdallah","33.1978","-8.5883","Morocco","MA","MAR","Casablanca-Settat","","74671","1504563452"
"Cannes","Cannes","43.5513","7.0128","France","FR","FRA","Provence-Alpes-Côte d’Azur","","74545","1250487828"
"Wang Tau Hom","Wang Tau Hom","22.3408","114.1871","Hong Kong","HK","HKG","","","17116","1344000105"
"Alameda","Alameda","37.7668","-122.2670","United States","US","USA","California","","78320","1840018913"
"Al Muḩarraq","Al Muharraq","26.2500","50.6167","Bahrain","BH","BHR","Al Muḩarraq","","75000","1048992336"
"Godāwari̇̄","Godawari","28.9100","80.5900","Nepal","NP","NPL","Sudūrpashchim","admin","78018","1524020578"
"Maghāghah","Maghaghah","28.6500","30.8500","Egypt","EG","EGY","Al Minyā","","75657","1818762526"
"Kuacjok","Kuacjok","8.3100","27.9900","South Sudan","SS","SSD","Warrap","admin","78111","1728713745"
"Madhubani","Madhubani","26.3700","86.0800","India","IN","IND","Bihār","","75736","1356042500"
"Malappuram","Malappuram","11.0588","76.0693","India","IN","IND","Kerala","","75740","1356088136"
"Sucat","Sucat","14.4600","121.0500","Philippines","PH","PHL","Muntinlupa","","56354","1608931938"
"Crewe","Crewe","53.0990","-2.4400","United Kingdom","GB","GBR","Cheshire East","","73241","1826086541"
"Cửa Lô","Cua Lo","18.8167","105.7167","Vietnam","VN","VNM","Nghệ An","","75260","1704860158"
"Gladbeck","Gladbeck","51.5667","6.9667","Germany","DE","DEU","North Rhine-Westphalia","","75687","1276216238"
"San Miguel de Allende","San Miguel de Allende","20.9142","-100.7436","Mexico","MX","MEX","Guanajuato","minor","69811","1484007926"
"Sankt Gallen","Sankt Gallen","47.4242","9.3708","Switzerland","CH","CHE","Sankt Gallen","admin","75833","1756343098"
"Gouda","Gouda","52.0111","4.7111","Netherlands","NL","NLD","Zuid-Holland","minor","73681","1528695192"
"Kargilik","Kargilik","37.8850","77.4131","China","CN","CHN","Xinjiang","minor","75730","1156790418"
"Maroúsi","Marousi","38.0500","23.8000","Greece","GR","GRC","Attikí","minor","72333","1300017523"
"Montrouge","Montrouge","48.8172","2.3219","France","FR","FRA","Île-de-France","","48734","1250189603"
"Limbé","Limbe","19.7056","-72.4147","Haiti","HT","HTI","Nord","","69256","1332168406"
"Newcastle under Lyme","Newcastle under Lyme","53.0109","-2.2278","United Kingdom","GB","GBR","Staffordshire","","75082","1826124765"
"Chingford","Chingford","51.6230","0.0090","United Kingdom","GB","GBR","Waltham Forest","","70583","1826861021"
"Korydallós","Korydallos","37.9833","23.6500","Greece","GR","GRC","Attikí","minor","63445","1300198192"
"Harrogate","Harrogate","53.9919","-1.5378","United Kingdom","GB","GBR","North Yorkshire","","73576","1826765513"
"Caxito","Caxito","-8.5800","13.6642","Angola","AO","AGO","Bengo","admin","77276","1024050171"
"Mao","Mao","19.5667","-71.0833","Dominican Republic","DO","DOM","Cibao Noroeste","admin","76863","1214217168"
"Okegawa","Okegawa","36.0057","139.5427","Japan","JP","JPN","Saitama","","74115","1392003539"
"Virac","Virac","13.5833","124.2333","Philippines","PH","PHL","Catanduanes","admin","76520","1608265120"
"Las Piedras","Las Piedras","-34.7167","-56.2167","Uruguay","UY","URY","Canelones","","71268","1858563314"
"Rugby","Rugby","52.3700","-1.2600","United Kingdom","GB","GBR","Warwickshire","","70627","1826969205"
"Berbérati","Berberati","4.2614","15.7894","Central African Republic","CF","CAF","Mambéré-Kadéï","admin","76918","1140239733"
"Tamazunchale","Tamazunchale","21.2667","-98.7833","Mexico","MX","MEX","San Luis Potosí","minor","72685","1484802864"
"Zrenjanin","Zrenjanin","45.3833","20.3894","Serbia","RS","SRB","Zrenjanin","admin","76511","1688827840"
"Fouchana","Fouchana","36.7000","10.1667","Tunisia","TN","TUN","Ben Arous","","74868","1788968797"
"Surt","Surt","31.2050","16.5886","Libya","LY","LBY","Surt","admin","76788","1434563640"
"Pančevo","Pancevo","44.8706","20.6403","Serbia","RS","SRB","Pančevo","admin","76203","1688356974"
"Vlaardingen","Vlaardingen","51.9000","4.3500","Netherlands","NL","NLD","Zuid-Holland","minor","73924","1528137355"
"Nerkunram","Nerkunram","13.0667","80.2833","India","IN","IND","Tamil Nādu","","52570","1356858169"
"Jōyō","Joyo","34.8531","135.7800","Japan","JP","JPN","Kyōto","","74356","1392003353"
"Luodong","Luodong","24.6767","121.7669","Taiwan","TW","TWN","Yilan","","70162","1158251407"
"Abéché","Abeche","13.8331","20.8347","Chad","TD","TCD","Ouaddaï","admin","76492","1148461151"
"Aïn Temouchent","Ain Temouchent","35.3044","-1.1400","Algeria","DZ","DZA","Aïn Temouchent","admin","75558","1012763655"
"Palmerston North","Palmerston North","-40.3550","175.6117","New Zealand","NZ","NZL","Manawatu-Wanganui","admin","76236","1554138183"
"Cheyenne","Cheyenne","41.1350","-104.7902","United States","US","USA","Wyoming","admin","76456","1840018713"
"Palaió Fáliro","Palaio Faliro","37.9333","23.7000","Greece","GR","GRC","Attikí","minor","64021","1300258604"
"Tamworth","Tamworth","52.6330","-1.6950","United Kingdom","GB","GBR","Staffordshire","","73924","1826336439"
"Chiryū","Chiryu","35.0014","137.0506","Japan","JP","JPN","Aichi","","71907","1392192403"
"Watsonville","Watsonville","36.9206","-121.7708","United States","US","USA","California","","76285","1840021581"
"Antibes","Antibes","43.5808","7.1239","France","FR","FRA","Provence-Alpes-Côte d’Azur","","73438","1250213494"
"Karlstad","Karlstad","59.3783","13.5042","Sweden","SE","SWE","Värmland","admin","74141","1752502445"
"Nandi Hills","Nandi Hills","0.1003","35.1764","Kenya","KE","KEN","Nandi","","73626","1404700660"
"Ramla","Ramla","31.9275","34.8625","Israel","IL","ISR","Central","admin","75500","1376321361"
"Jinja","Jinja","0.4233","33.2039","Uganda","UG","UGA","Jinja","admin","72931","1800632405"
"Tamanrasset","Tamanrasset","22.7850","5.5228","Algeria","DZ","DZA","Tamanrasset","admin","76000","1012765474"
"Zadar","Zadar","44.1194","15.2319","Croatia","HR","HRV","Zadarska Županija","admin","75082","1191160875"
"Ixtaczoquitlán","Ixtaczoquitlan","18.8500","-97.0667","Mexico","MX","MEX","Veracruz","minor","68823","1484464346"
"Benalmádena","Benalmadena","36.6000","-4.5167","Spain","ES","ESP","Andalusia","","73160","1724108022"
"Musashimurayama","Musashimurayama","35.7548","139.3874","Japan","JP","JPN","Tōkyō","","71183","1392092262"
"Bella Vista","Bella Vista","-34.5333","-58.6667","Argentina","AR","ARG","Buenos Aires","","72000","1032720412"
"Arta","Arta","11.5236","42.8472","Djibouti","DJ","DJI","Arta","admin","75654","1262889858"
"Vincennes","Vincennes","48.8478","2.4392","France","FR","FRA","Île-de-France","","49788","1250087809"
"Tafo","Tafo","6.7358","-1.6081","Ghana","GH","GHA","Ashanti","","60919","1288961345"
"Tissemsilt","Tissemsilt","35.6072","1.8106","Algeria","DZ","DZA","Tissemsilt","admin","75197","1012743339"
"Joensuu","Joensuu","62.6000","29.7500","Finland","FI","FIN","Pohjois-Karjala","admin","75514","1246489056"
"Rioverde","Rioverde","21.9300","-99.9800","Mexico","MX","MEX","San Luis Potosí","minor","69613","1484341437"
"Matehuala","Matehuala","23.6528","-100.6444","Mexico","MX","MEX","San Luis Potosí","minor","67717","1484682498"
"Davis","Davis","38.5553","-121.7370","United States","US","USA","California","","75468","1840018833"
"Darhan","Darhan","49.4689","105.9575","Mongolia","MN","MNG","Darhan-Uul","admin","74738","1496235602"
"Teyateyaneng","Teyateyaneng","-29.1511","27.7425","Lesotho","LS","LSO","Berea","admin","75115","1426159118"
"Zacatecoluca","Zacatecoluca","13.5000","-88.8667","El Salvador","SV","SLV","La Paz","admin","75100","1222647953"
"Pawtucket","Pawtucket","41.8744","-71.3743","United States","US","USA","Rhode Island","","75200","1840003285"
"Zlín","Zlin","49.2331","17.6669","Czechia","CZ","CZE","Zlínský Kraj","admin","74478","1203582141"
"Spijkenisse","Spijkenisse","51.8500","4.3333","Netherlands","NL","NLD","Zuid-Holland","minor","72740","1528234095"
"Avaniyāpuram","Avaniyapuram","9.8818","78.1125","India","IN","IND","Tamil Nādu","","69967","1356236929"
"Calais","Calais","50.9481","1.8564","France","FR","FRA","Hauts-de-France","minor","72929","1250081128"
"Jinsha","Jinsha","23.5287","116.6149","China","CN","CHN","Guangdong","","71240","1156108304"
"Kiyosu","Kiyosu","35.1998","136.8529","Japan","JP","JPN","Aichi","","69809","1392439011"
"Siuri","Siuri","23.9100","87.5270","India","IN","IND","West Bengal","","67864","1356042495"
"Prey Veng","Prey Veng","11.4833","105.3167","Cambodia","KH","KHM","Prey Veng","admin","74000","1116378797"
"Dazaifu","Dazaifu","33.5167","130.5167","Japan","JP","JPN","Fukuoka","","72430","1392003236"
"Inowrocław","Inowroclaw","52.7931","18.2611","Poland","PL","POL","Kujawsko-Pomorskie","minor","72561","1616606592"
"Bitola","Bitola","41.0319","21.3347","Macedonia","MK","MKD","Bitola","admin","74550","1807488567"
"San Andrés","San Andres","12.5847","-81.7006","Colombia","CO","COL","San Andrés y Providencia","admin","71946","1170563968"
"Poblacion","Poblacion","10.2500","123.9500","Philippines","PH","PHL","Cebu","","70595","1608772527"
"Tangalla","Tangalla","6.0167","80.7833","Sri Lanka","LK","LKA","Southern","","72500","1144402312"
"Lowestoft","Lowestoft","52.4800","1.7500","United Kingdom","GB","GBR","Suffolk","","70945","1826419858"
"Southall","Southall","51.5121","-0.3779","United Kingdom","GB","GBR","Ealing","","69857","1826336436"
"Longkoucun","Longkoucun","23.5726","116.7238","China","CN","CHN","Guangdong","","72421","1156223355"
"Caguas","Caguas","18.2319","-66.0388","Puerto Rico","PR","PRI","Puerto Rico","","74502","1630023540"
"Hekinan","Hekinan","34.8847","136.9934","Japan","JP","JPN","Aichi","","72480","1392819275"
"Yoshikawa","Yoshikawa","35.8939","139.8414","Japan","JP","JPN","Saitama","","72139","1392319161"
"Assab","Assab","13.0167","42.7333","Eritrea","ER","ERI","Debubawi K’eyyĭḥ Baḥri","admin","74405","1232396238"
"Bagumbayan","Bagumbayan","14.4744","121.0569","Philippines","PH","PHL","Taguig","","49319","1608516685"
"Gosport","Gosport","50.7948","-1.1243","United Kingdom","GB","GBR","Hampshire","","71529","1826330438"
"Nabeul","Nabeul","36.4542","10.7347","Tunisia","TN","TUN","Nabeul","admin","73128","1788902612"
"Cao Bằng","Cao Bang","22.6667","106.2583","Vietnam","VN","VNM","Cao Bằng","admin","73549","1704552735"
"Ivry-sur-Seine","Ivry-sur-Seine","48.8078","2.3747","France","FR","FRA","Île-de-France","","63748","1250904640"
"Uxbridge","Uxbridge","51.5404","-0.4778","United Kingdom","GB","GBR","Hillingdon","","70560","1826505308"
"Birobidzhan","Birobidzhan","48.8000","132.9333","Russia","RU","RUS","Yevreyskaya Avtonomnaya Oblast’","admin","73623","1643548797"
"Grays","Grays","51.4750","0.3300","United Kingdom","GB","GBR","Thurrock","","66843","1826003493"
"Tsurugashima","Tsurugashima","35.9345","139.3931","Japan","JP","JPN","Saitama","","70067","1392196288"
"Tulcea","Tulcea","45.1900","28.8000","Romania","RO","ROU","Tulcea","admin","73707","1642854000"
"New Britain","New Britain","41.6759","-72.7862","United States","US","USA","Connecticut","","74022","1840004774"
"Lauderhill","Lauderhill","26.1605","-80.2241","United States","US","USA","Florida","","73935","1840015146"
"El Kef","El Kef","36.1822","8.7147","Tunisia","TN","TUN","Kef","admin","73706","1788314833"
"‘Izbat al Burj","\`Izbat al Burj","31.5031","31.8411","Egypt","EG","EGY","Ad Daqahlīyah","","70000","1818379501"
"Gerli","Gerli","-34.6833","-58.3667","Argentina","AR","ARG","Buenos Aires","","64640","1032212619"
"Salima","Salima","-13.7833","34.4333","Malawi","MW","MWI","Salima","admin","71181","1454747771"
"Saint-Louis du Nord","Saint-Louis du Nord","19.9333","-72.7167","Haiti","HT","HTI","Nord-Ouest","","69592","1332427646"
"Blagoevgrad","Blagoevgrad","42.0119","23.0897","Bulgaria","BG","BGR","Blagoevgrad","admin","73463","1100214416"
"Şa‘dah","Sa\`dah","16.9400","43.7592","Yemen","YE","YEM","Şa‘dah","admin","70203","1887513055"
"Maharlika Village","Maharlika Village","14.4989","121.0533","Philippines","PH","PHL","Taguig","","22944","1608284292"
"Walton upon Thames","Walton upon Thames","51.3868","-0.4133","United Kingdom","GB","GBR","Surrey","","66566","1826528871"
"Neuilly-sur-Seine","Neuilly-sur-Seine","48.8881","2.2686","France","FR","FRA","Île-de-France","","59940","1250765188"
"Busia","Busia","0.4669","34.0900","Uganda","UG","UGA","Busia","admin","64900","1800460306"
"Noisy-le-Grand","Noisy-le-Grand","48.8478","2.5528","France","FR","FRA","Île-de-France","","68126","1250182208"
"Galátsi","Galatsi","38.0167","23.7500","Greece","GR","GRC","Attikí","minor","59345","1300322310"
"Şirvan","Sirvan","39.9323","48.9203","Azerbaijan","AZ","AZE","Şirvan","","70684","1031478213"
"Kodungallūr","Kodungallur","10.2338","76.1946","India","IN","IND","Kerala","","70868","1356492886"
"Kamuli","Kamuli","0.9450","33.1250","Uganda","UG","UGA","Kamuli","admin","67800","1800849421"
"Fada Ngourma","Fada Ngourma","12.0500","0.3667","Burkina Faso","BF","BFA","Est","admin","73200","1854057074"
"Yawata-shimizui","Yawata-shimizui","34.8667","135.7000","Japan","JP","JPN","Kyōto","","70373","1392797096"
"San José de las Lajas","San Jose de las Lajas","22.9678","-82.1558","Cuba","CU","CUB","Mayabeque","admin","73136","1192731332"
"Thakhèk","Thakhek","17.4000","104.8000","Laos","LA","LAO","Khammouan","admin","70000","1418367677"
"Cuscatancingo","Cuscatancingo","13.7333","-89.1833","El Salvador","SV","SLV","San Salvador","","66400","1222934169"
"San Dionisio","San Dionisio","14.4839","120.9925","Philippines","PH","PHL","Parañaque","","63506","1608848150"
"Centreville","Centreville","38.8390","-77.4389","United States","US","USA","Virginia","","73064","1840006015"
"Feltham","Feltham","51.4496","-0.4089","United Kingdom","GB","GBR","Hounslow","","63368","1826165560"
"San Antonio","San Antonio","-25.3797","-57.6097","Paraguay","PY","PRY","Central","","69976","1600088413"
"Pavia","Pavia","10.7750","122.5417","Philippines","PH","PHL","Iloilo","","70388","1608401350"
"Lappeenranta","Lappeenranta","61.0667","28.1833","Finland","FI","FIN","Etelä-Karjala","admin","72875","1246492910"
"Gaura","Gaura","25.4961","85.9709","India","IN","IND","Bihār","","70081","1356861053"
"Mount Vernon","Mount Vernon","40.9136","-73.8291","United States","US","USA","New York","","72855","1840000781"
"Baldwin Park","Baldwin Park","34.0829","-117.9722","United States","US","USA","California","","72813","1840019227"
"Chiyoda-ku","Chiyoda-ku","35.6940","139.7536","Japan","JP","JPN","Tōkyō","","67036","1392003134"
"Cabo San Lucas","Cabo San Lucas","22.8897","-109.9156","Mexico","MX","MEX","Baja California Sur","","68463","1484694325"
"Kolonnawa","Kolonnawa","6.9283","79.8950","Sri Lanka","LK","LKA","Western","","64887","1144205591"
"Oroquieta","Oroquieta","8.4833","123.8000","Philippines","PH","PHL","Misamis Occidental","admin","72301","1608239509"
"Wandsworth","Wandsworth","51.4550","-0.1920","United Kingdom","GB","GBR","Wandsworth","","61594","1826844924"
"Castelldefels","Castelldefels","41.2800","1.9767","Spain","ES","ESP","Catalonia","","67307","1724000280"
"Karakax","Karakax","37.2714","79.7267","China","CN","CHN","Xinjiang","minor","66541","1156181413"
"Abancay","Abancay","-13.6333","-72.8833","Peru","PE","PER","Apurímac","admin","72277","1604782074"
"Camden","Camden","39.9361","-75.1073","United States","US","USA","New Jersey","","72381","1840001507"
"Toyoake","Toyoake","35.0509","137.0128","Japan","JP","JPN","Aichi","","69389","1392203165"
"Wilde","Wilde","-34.7000","-58.3167","Argentina","AR","ARG","Buenos Aires","","65881","1032256650"
"Havířov","Havirov","49.7831","18.4228","Czechia","CZ","CZE","Moravskoslezský Kraj","","70165","1203173875"
"Torremolinos","Torremolinos","36.6218","-4.5003","Spain","ES","ESP","Andalusia","","68819","1724424815"
"Ajaccio","Ajaccio","41.9267","8.7369","France","FR","FRA","Corsica","admin","71361","1250622666"
"Stara Pazova","Stara Pazova","44.9833","20.1667","Serbia","RS","SRB","Stara Pazova","admin","71931","1688866909"
"Borongan","Borongan","11.6094","125.4361","Philippines","PH","PHL","Eastern Samar","admin","71961","1608990192"
"Matara","Matara","5.9500","80.5333","Sri Lanka","LK","LKA","Southern","","68244","1144127163"
"Nizwá","Nizwa","22.9333","57.5333","Oman","OM","OMN","Ad Dākhilīyah","admin","72076","1512180300"
"Najrān","Najran","17.4917","44.1322","Saudi Arabia","SA","SAU","Najrān","admin","72038","1682069716"
"Víctor Larco Herrera","Victor Larco Herrera","-8.1333","-79.0500","Peru","PE","PER","La Libertad","","68506","1604289089"
"Čačak","Cacak","43.8914","20.3497","Serbia","RS","SRB","Čačak","admin","71883","1688216463"
"Walsall","Walsall","52.5800","-1.9800","United Kingdom","GB","GBR","Walsall","","67594","1826655696"
"Cergy","Cergy","49.0361","2.0631","France","FR","FRA","Île-de-France","minor","66322","1250553439"
"Sihanoukville","Sihanoukville","10.6333","103.5000","Cambodia","KH","KHM","Preah Sihanouk","admin","71082","1116098692"
"Neyyāttinkara","Neyyattinkara","8.4000","77.0800","India","IN","IND","Kerala","","69449","1356391337"
"La Chorrera","La Chorrera","8.8792","-79.7822","Panama","PA","PAN","Panamá Oeste","admin","68896","1591982720"
"Al Qūşīyah","Al Qusiyah","27.4143","30.8216","Egypt","EG","EGY","Asyūţ","","69388","1818093562"
"Varisshiyakuni","Varisshiyakuni","11.6400","75.5870","India","IN","IND","Kerala","","68970","1356864441"
"Kireka","Kireka","0.3467","32.5000","Uganda","UG","UGA","Wakiso","","69000","1800730465"
"Pantin","Pantin","48.8966","2.4017","France","FR","FRA","Île-de-France","","59846","1250209071"
"Halmstad","Halmstad","56.6739","12.8572","Sweden","SE","SWE","Halland","admin","71422","1752392511"
"Capelle aan den IJssel","Capelle aan den IJssel","51.9333","4.5833","Netherlands","NL","NLD","Zuid-Holland","minor","67319","1528883195"
"Vénissieux","Venissieux","45.6978","4.8867","France","FR","FRA","Auvergne-Rhône-Alpes","","67285","1250755963"
"Tarīn Kōṯ","Tarin Kot","32.6267","65.8733","Afghanistan","AF","AFG","Uruzgān","admin","71604","1004893931"
"Givatayim","Givatayim","32.0714","34.81","Israel","IL","ISR","Tel Aviv","","59518","1376739486"
"Youssoufia","Youssoufia","32.2500","-8.5333","Morocco","MA","MAR","Marrakech-Safi","","67628","1504459034"
"Mitcham","Mitcham","51.4009","-0.1517","United Kingdom","GB","GBR","Merton","","63393","1826947069"
"Misantla","Misantla","19.9333","-96.8500","Mexico","MX","MEX","Veracruz","minor","64249","1484744150"
"Salamá","Salama","15.1052","-90.3160","Guatemala","GT","GTM","Baja Verapaz","admin","71478","1320223205"
"Yambol","Yambol","42.4833","26.5000","Bulgaria","BG","BGR","Yambol","admin","70782","1100748067"
"Bitlis","Bitlis","38.4000","42.1083","Turkey","TR","TUR","Bitlis","admin","71501","1792490436"
"Martínez","Martinez","-34.4833","-58.5000","Argentina","AR","ARG","Buenos Aires","","65859","1032000014"
"Slatina","Slatina","44.4297","24.3642","Romania","RO","ROU","Olt","admin","70293","1642577360"
"Ramos Arizpe","Ramos Arizpe","25.5500","-100.9667","Mexico","MX","MEX","Coahuila","minor","66554","1484685820"
"Phitsanulok","Phitsanulok","16.8158","100.2636","Thailand","TH","THA","Phitsanulok","admin","70871","1764140981"
"Samraong","Samraong","14.2500","103.5833","Cambodia","KH","KHM","Oddar Meanchey","admin","70944","1116989175"
"Zinjibār","Zinjibar","13.1283","45.3803","Yemen","YE","YEM","Abyan","admin","70801","1887645956"
"Hatogaya-honchō","Hatogaya-honcho","35.8267","139.7412","Japan","JP","JPN","Saitama","","61379","1392167019"
"Tamarac","Tamarac","26.2056","-80.2539","United States","US","USA","Florida","","71140","1840016001"
"Điện Biên Phủ","Dien Bien Phu","21.3833","103.0167","Vietnam","VN","VNM","Điện Biên","admin","70000","1704081536"
"Borj el Qoblé","Borj el Qoble","33.2631","35.2389","Lebanon","LB","LBN","Liban-Sud","","61973","1422937881"
"Redondo Beach","Redondo Beach","33.8577","-118.3765","United States","US","USA","California","","70998","1840020509"
"New Westminster","New Westminster","49.2069","-122.9111","Canada","CA","CAN","British Columbia","","70996","1124196524"
"Kumanovo","Kumanovo","42.1322","21.7144","Macedonia","MK","MKD","Kumanovo","admin","70842","1807301219"
"Valle Hermoso","Valle Hermoso","25.6736","-97.8144","Mexico","MX","MEX","Tamaulipas","minor","68573","1484479299"
"Wilmington","Wilmington","39.7415","-75.5416","United States","US","USA","Delaware","","70926","1840005579"
"Novi Pazar","Novi Pazar","43.1500","20.5167","Serbia","RS","SRB","Novi Pazar","admin","66527","1688435541"
"Mindelo","Mindelo","16.8860","-24.9880","Cabo Verde","CV","CPV","São Vicente","admin","70468","1132180389"
"Gardēz","Gardez","33.6000","69.2169","Afghanistan","AF","AFG","Paktiyā","admin","70641","1004468894"
"Chiang Rai","Chiang Rai","19.9094","99.8275","Thailand","TH","THA","Chiang Rai","admin","70610","1764748484"
"Tynemouth","Tynemouth","55.0170","-1.4230","United Kingdom","GB","GBR","North Tyneside","","68202","1826445971"
"Abnūb","Abnub","27.2667","31.1500","Egypt","EG","EGY","Asyūţ","","67526","1818739665"
"Paignton","Paignton","50.4353","-3.5625","United Kingdom","GB","GBR","Devon","","64410","1826935894"
"Āksum","Aksum","14.1208","38.7278","Ethiopia","ET","ETH","Tigray","","66800","1231573651"
"Jinxing","Jinxing","37.9869","106.2027","China","CN","CHN","Ningxia","","60086","1156547623"
"Huauchinango","Huauchinango","20.1767","-98.0528","Mexico","MX","MEX","Puebla","minor","58957","1484856743"
"Huatusco","Huatusco","19.1489","-96.9661","Mexico","MX","MEX","Veracruz","minor","60674","1484463389"
"Bayonne","Bayonne","40.6668","-74.1158","United States","US","USA","New Jersey","","70553","1840003595"
"Guaynabo","Guaynabo","18.3832","-66.1134","Puerto Rico","PR","PRI","Puerto Rico","","70542","1630023575"
"Fujiidera","Fujiidera","34.5667","135.6000","Japan","JP","JPN","Ōsaka","","63338","1392763646"
"Aïn Harrouda","Ain Harrouda","33.6372","-7.4483","Morocco","MA","MAR","Casablanca-Settat","","62420","1504718611"
"Kashiwara","Kashiwara","34.5833","135.6333","Japan","JP","JPN","Ōsaka","","67757","1392003287"
"Ţūkh","Tukh","30.3539","31.2007","Egypt","EG","EGY","Al Qalyūbīyah","","67599","1818411967"
"Dimbokro","Dimbokro","6.6500","-4.7000","Côte d'Ivoire","CI","CIV","Lacs","admin","70198","1384235566"
"Passaic","Passaic","40.8574","-74.1282","United States","US","USA","New Jersey","","70308","1840000837"
"Veenendaal","Veenendaal","52.0167","5.5500","Netherlands","NL","NLD","Utrecht","minor","66912","1528062494"
"Agía Paraskeví","Agia Paraskevi","38.0117","23.8200","Greece","GR","GRC","Attikí","minor","62717","1300253630"
"Sagaing","Sagaing","21.8822","95.9786","Myanmar","MM","MMR","Sagaing","admin","69917","1104151403"
"Villanueva y Geltrú","Villanueva y Geltru","41.2243","1.7259","Spain","ES","ESP","Catalonia","","68152","1724482160"
"Szolnok","Szolnok","47.1747","20.1965","Hungary","HU","HUN","Jász-Nagykun-Szolnok","admin","69725","1348287339"
"Encarnación","Encarnacion","-27.3333","-55.8667","Paraguay","PY","PRY","Itapúa","admin","69868","1600377819"
"Viladecáns","Viladecans","41.3158","2.0194","Spain","ES","ESP","Catalonia","","66720","1724069372"
"Rochester","Rochester","51.3750","0.5000","United Kingdom","GB","GBR","Medway","","62982","1826559634"
"Vushtrri","Vushtrri","42.8222","20.9694","Kosovo","XK","XKS","Vushtrri","admin","69870","1901107642"
"Moquegua","Moquegua","-17.2000","-70.9333","Peru","PE","PER","Moquegua","admin","69882","1604230427"
"Heroica Caborca","Heroica Caborca","30.7167","-112.1500","Mexico","MX","MEX","Sonora","minor","67604","1484993432"
"Washington","Washington","54.9000","-1.5200","United Kingdom","GB","GBR","Sunderland","","67158","1826149081"
"Rivera","Rivera","-30.9025","-55.5506","Uruguay","UY","URY","Rivera","admin","64465","1858819782"
"Ashford","Ashford","51.1465","0.8676","United Kingdom","GB","GBR","Kent","","67528","1826045748"
"Georgiyevsk","Georgiyevsk","44.1500","43.4667","Russia","RU","RUS","Stavropol’skiy Kray","","67054","1643279802"
"Assen","Assen","53.0000","6.5667","Netherlands","NL","NLD","Drenthe","admin","68836","1528917714"
"Castellammare di Stabia","Castellammare di Stabia","40.6947","14.4803","Italy","IT","ITA","Campania","","65922","1380725803"
"L’Aquila","L'Aquila","42.3500","13.4000","Italy","IT","ITA","Abruzzo","admin","69439","1380218474"
"Cortazar","Cortazar","20.4830","-100.9330","Mexico","MX","MEX","Guanajuato","minor","61658","1484238779"
"Eskilstuna","Eskilstuna","59.3708","16.5097","Sweden","SE","SWE","Södermanland","minor","67359","1752784934"
"Antony","Antony","48.7539","2.2975","France","FR","FRA","Île-de-France","minor","62858","1250570123"
"Finchley","Finchley","51.5990","-0.1870","United Kingdom","GB","GBR","Barnet","","65812","1826882560"
"Hornchurch","Hornchurch","51.5565","0.2128","United Kingdom","GB","GBR","Havering","","63583","1826813082"
"Liepāja","Liepaja","56.5117","21.0139","Latvia","LV","LVA","Liepāja","admin","67964","1428511842"
"Acton","Acton","51.5135","-0.2707","United Kingdom","GB","GBR","Ealing","","62480","1826793320"
"Kati","Kati","12.7504","-8.0800","Mali","ML","MLI","Koulikoro","minor","66895","1466006801"
"Bouira","Bouira","36.3800","3.9014","Algeria","DZ","DZA","Bouira","admin","68545","1012006499"
"Portici","Portici","40.8197","14.3411","Italy","IT","ITA","Campania","","54577","1380276866"
"Tamlūk","Tamluk","22.3000","87.9200","India","IN","IND","West Bengal","","65306","1356893735"
"East Orange","East Orange","40.7651","-74.2117","United States","US","USA","New Jersey","","68918","1840002792"
"San José del Guaviare","San Jose del Guaviare","2.5667","-72.6333","Colombia","CO","COL","Guaviare","admin","68878","1170839384"
"Taman Johor Jaya","Taman Johor Jaya","1.5333","103.8167","Malaysia","MY","MYS","Johor","","66000","1458837224"
"Gaithersburg","Gaithersburg","39.1346","-77.2131","United States","US","USA","Maryland","","68827","1840005848"
"Ponta Delgada","Ponta Delgada","37.7400","-25.6700","Portugal","PT","PRT","Azores","admin","68809","1620456537"
"Hihyā","Hihya","30.6687","31.5904","Egypt","EG","EGY","Ash Sharqīyah","","66702","1818905246"
"Al Minshāh","Al Minshah","26.4833","31.8000","Egypt","EG","EGY","Sūhāj","","65484","1818211426"
"Villa Domínico","Villa Dominico","-34.6917","-58.3333","Argentina","AR","ARG","Buenos Aires","","62315","1032866743"
"Sabinas","Sabinas","27.8489","-101.1200","Mexico","MX","MEX","Coahuila","minor","64811","1484526560"
"Pruszków","Pruszkow","52.1667","20.8000","Poland","PL","POL","Mazowieckie","minor","65283","1616154673"
"Merthyr Tudful","Merthyr Tudful","51.7430","-3.3780","United Kingdom","GB","GBR","Merthyr Tydfil","","63546","1826737367"
"Eastvale","Eastvale","33.9617","-117.5803","United States","US","USA","California","","68539","1840028421"
"Al Buraymī","Al Buraymi","24.2592","55.7839","Oman","OM","OMN","Al Buraymī","admin","67963","1512959921"
"Kitamoto","Kitamoto","36.0269","139.5302","Japan","JP","JPN","Saitama","","65191","1392754858"
"Şəki","Saki","41.2000","47.1667","Azerbaijan","AZ","AZE","Şəki","admin","68360","1031619846"
"Afragola","Afragola","40.9167","14.3167","Italy","IT","ITA","Campania","","64817","1380524178"
"Horad Zhodzina","Horad Zhodzina","54.1000","28.3500","Belarus","BY","BLR","Minskaya Voblasts’","minor","65451","1112402017"
"Arima","Arima","10.6333","-61.2833","Trinidad and Tobago","TT","TTO","Arima","admin","65623","1780753308"
"Adrar","Adrar","27.8742","-0.2939","Algeria","DZ","DZA","Adrar","admin","68276","1012462113"
"Al Qurayn","Al Qurayn","30.6161","31.7353","Egypt","EG","EGY","Ash Sharqīyah","","64453","1818960742"
"Waterlooville","Waterlooville","50.8800","-1.0300","United Kingdom","GB","GBR","Hampshire","","64350","1826290265"
"Valle de Bravo","Valle de Bravo","19.1925","-100.1306","Mexico","MX","MEX","México","minor","61559","1484105173"
"Karlskrona","Karlskrona","56.1608","15.5861","Sweden","SE","SWE","Blekinge","admin","66675","1752316086"
"Kāyankulam","Kayankulam","9.1720","76.5010","India","IN","IND","Kerala","","65299","1356157010"
"Växjö","Vaxjo","56.8769","14.8092","Sweden","SE","SWE","Kronoberg","admin","66275","1752263370"
"Apac","Apac","1.9850","32.5350","Uganda","UG","UGA","Apac","admin","67700","1800174030"
"Tomigusuku","Tomigusuku","26.1611","127.6689","Japan","JP","JPN","Okinawa","","64850","1392091635"
"Bayombong","Bayombong","16.4833","121.1500","Philippines","PH","PHL","Nueva Vizcaya","admin","67714","1608066874"
"Bignay","Bignay","14.7456","120.9961","Philippines","PH","PHL","Caloocan","","49716","1608750105"
"Výronas","Vyronas","37.9617","23.7533","Greece","GR","GRC","Attikí","minor","61535","1300508500"
"Buynaksk","Buynaksk","42.8167","47.1167","Russia","RU","RUS","Dagestan","minor","65080","1643980922"
"Bankra","Bankra","22.6300","88.3000","India","IN","IND","West Bengal","","63957","1356096653"
"Ban Suan","Ban Suan","13.3616","100.9795","Thailand","TH","THA","Chon Buri","","66092","1764305340"
"Areguá","Aregua","-25.2953","-57.4147","Paraguay","PY","PRY","Central","admin","67487","1600141362"
"Katwijk","Katwijk","52.2000","4.4167","Netherlands","NL","NLD","Zuid-Holland","minor","65995","1528804942"
"Boston","Boston","52.9740","-0.0214","United Kingdom","GB","GBR","Lincolnshire","","64600","1826233090"
"Banda del Río Salí","Banda del Rio Sali","-26.8500","-65.1667","Argentina","AR","ARG","Tucumán","minor","63226","1032189775"
"Hämeenlinna","Hameenlinna","60.9944","24.4667","Finland","FI","FIN","Kanta-Häme","admin","68011","1246026891"
"Vaasa","Vaasa","63.1000","21.6167","Finland","FI","FIN","Pohjanmaa","admin","67619","1246734710"
"Bayt Lāhyā","Bayt Lahya","31.5539","34.5014","Gaza Strip","XG","XGZ","","","59540","1916827259"
"Scarborough","Scarborough","54.2773","-0.4017","United Kingdom","GB","GBR","North Yorkshire","","61749","1826723231"
"Union City","Union City","40.7675","-74.0323","United States","US","USA","New Jersey","","67903","1840001034"
"Chiapa de Corzo","Chiapa de Corzo","16.7080","-93.0160","Mexico","MX","MEX","Chiapas","minor","55931","1484314420"
"Cupang","Cupang","14.4315","121.0486","Philippines","PH","PHL","Muntinlupa","","57196","1608273325"
"Tunasan","Tunasan","14.3725","121.0364","Philippines","PH","PHL","Muntinlupa","","61374","1608804779"
"Täby","Taby","59.4333","18.0833","Sweden","SE","SWE","Stockholm","minor","65364","1752810519"
"Streatham","Streatham","51.4279","-0.1235","United Kingdom","GB","GBR","Lambeth","","58055","1826845701"
"Farnborough","Farnborough","51.2900","-0.7500","United Kingdom","GB","GBR","Hampshire","","65034","1826383788"
"Cabedelo","Cabedelo","-6.9808","-34.8339","Brazil","BR","BRA","Paraíba","","65634","1076791382"
"Barking","Barking","51.5400","0.0800","United Kingdom","GB","GBR","Barking and Dagenham","","59068","1826566262"
"Molepolole","Molepolole","-24.4067","25.4950","Botswana","BW","BWA","Kweneng","admin","67598","1072560529"
"Potenza","Potenza","40.6333","15.8000","Italy","IT","ITA","Basilicata","admin","67211","1380649430"
"El Prat de Llobregat","El Prat de Llobregat","41.3246","2.0953","Spain","ES","ESP","Catalonia","","65532","1724139875"
"Teresa","Teresa","14.5586","121.2083","Philippines","PH","PHL","Rizal","","64072","1608949971"
"Lynwood","Lynwood","33.9240","-118.2016","United States","US","USA","California","","67497","1840020492"
"Skokie","Skokie","42.0360","-87.7400","United States","US","USA","Illinois","","67444","1840011321"
"Patuakhāli","Patuakhali","22.3542","90.3181","Bangladesh","BD","BGD","Khulna","","65000","1050608887"
"Kozáni","Kozani","40.3000","21.7833","Greece","GR","GRC","Dytikí Makedonía","admin","67161","1300407295"
"Arrecife","Arrecife","28.9625","-13.5506","Spain","ES","ESP","Canary Islands","","64497","1724630905"
"Altamira","Altamira","22.3375","-97.8694","Mexico","MX","MEX","Tamaulipas","minor","59536","1484764524"
"Guamúchil","Guamuchil","25.4639","-108.0794","Mexico","MX","MEX","Sinaloa","minor","63743","1484709566"
"Siemianowice Śląskie","Siemianowice Slaskie","50.2758","18.9858","Poland","PL","POL","Śląskie","minor","64676","1616000741"
"Stourbridge","Stourbridge","52.4575","-2.1479","United Kingdom","GB","GBR","Dudley","","63298","1826879996"
"Twickenham","Twickenham","51.4490","-0.3370","United Kingdom","GB","GBR","Richmond upon Thames","","62148","1826827915"
"Fryazino","Fryazino","55.9500","38.0500","Russia","RU","RUS","Moskovskaya Oblast’","","60437","1643988572"
"Acámbaro","Acambaro","20.0361","-100.7314","Mexico","MX","MEX","Guanajuato","minor","57972","1484524073"
"Maisons-Alfort","Maisons-Alfort","48.8058","2.4378","France","FR","FRA","Île-de-France","","56483","1250000462"
"Lechang","Lechang","35.6415","111.4633","China","CN","CHN","Shanxi","minor","64587","1156327170"
"Bolgatanga","Bolgatanga","10.7833","-0.8500","Ghana","GH","GHA","Upper East","admin","66685","1288943371"
"Martil","Martil","35.6167","-5.2667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","64355","1504341023"
"Petroúpoli","Petroupoli","38.0333","23.6833","Greece","GR","GRC","Attikí","minor","58979","1300960002"
"Laeken","Laeken","50.8778","4.3558","Belgium","BE","BEL","Brussels-Capital Region","","60295","1056324535"
"Schenectady","Schenectady","42.8025","-73.9275","United States","US","USA","New York","","66809","1840000395"
"Kraljevo","Kraljevo","43.7234","20.6870","Serbia","RS","SRB","Kraljevo","admin","66688","1688625234"
"Épinay-sur-Seine","Epinay-sur-Seine","48.9553","2.3092","France","FR","FRA","Île-de-France","","54771","1250904959"
"Hereford","Hereford","52.0560","-2.7160","United Kingdom","GB","GBR","Herefordshire","","63024","1826996583"
"Khagaul","Khagaul","25.5790","85.0456","India","IN","IND","Bihār","","63763","1356155902"
"Troyes","Troyes","48.2997","4.0792","France","FR","FRA","Grand Est","minor","61996","1250800856"
"Collado-Villalba","Collado-Villalba","40.6333","-4.0083","Spain","ES","ESP","Madrid","","64263","1724645759"
"Bayburt","Bayburt","40.2597","40.2278","Turkey","TR","TUR","Bayburt","admin","66633","1792400872"
"Granollers","Granollers","41.6083","2.2889","Spain","ES","ESP","Catalonia","","62475","1724011647"
"Nālūt","Nalut","31.8685","10.9812","Libya","LY","LBY","Nālūt","admin","66609","1434550258"
"Zacapa","Zacapa","14.9667","-89.5333","Guatemala","GT","GTM","Zacapa","admin","66423","1320977472"
"Los Polvorines","Los Polvorines","-34.5000","-58.7000","Argentina","AR","ARG","Buenos Aires","minor","60867","1032236823"
"San Jose","San Jose","10.7433","121.9411","Philippines","PH","PHL","Antique","admin","65140","1608138574"
"Gampaha","Gampaha","7.0917","79.9997","Sri Lanka","LK","LKA","Western","","62797","1144612731"
"South San Francisco","South San Francisco","37.6538","-122.4196","United States","US","USA","California","","66331","1840021552"
"Prilep","Prilep","41.3464","21.5542","Macedonia","MK","MKD","Prilep","admin","66246","1807551778"
"Trnava","Trnava","48.3775","17.5883","Slovakia","SK","SVK","Trnava","admin","65382","1703497997"
"Sarcelles","Sarcelles","48.9956","2.3808","France","FR","FRA","Île-de-France","minor","59196","1250985562"
"Dewsbury","Dewsbury","53.6910","-1.6330","United Kingdom","GB","GBR","Kirklees","","62945","1826354283"
"Sundapālaiyam","Sundapalaiyam","11.0014","76.8732","India","IN","IND","Tamil Nādu","","63724","1356463067"
"Bilwi","Bilwi","14.0297","-83.3833","Nicaragua","NI","NIC","Costa Caribe Norte","admin","66169","1558656214"
"Goz-Beida","Goz-Beida","12.2236","21.4144","Chad","TD","TCD","Sila","admin","66107","1148101824"
"Loughborough","Loughborough","52.7700","-1.2000","United Kingdom","GB","GBR","Leicestershire","","59932","1826194258"
"Brentwood","Brentwood","40.7839","-73.2522","United States","US","USA","New York","","66035","1840005021"
"Wrecsam","Wrecsam","53.0460","-2.9930","United Kingdom","GB","GBR","Wrexham","","61603","1826879983"
"San Marcos","San Marcos","14.9653","-91.7958","Guatemala","GT","GTM","San Marcos","admin","65571","1320960166"
"Samut Sakhon","Samut Sakhon","13.5486","100.2775","Thailand","TH","THA","Samut Sakhon","admin","60103","1764502057"
"Pervomaisk","Pervomaisk","48.0500","30.8500","Ukraine","UA","UKR","Mykolaivska Oblast","minor","63377","1804620055"
"Don Bosco","Don Bosco","14.4817","121.0258","Philippines","PH","PHL","Parañaque","","52297","1608303018"
"Tatabánya","Tatabanya","47.5862","18.3949","Hungary","HU","HUN","Komárom-Esztergom","admin","65145","1348450358"
"La Seyne-sur-Mer","La Seyne-sur-Mer","43.1000","5.8830","France","FR","FRA","Provence-Alpes-Côte d’Azur","","62987","1250392648"
"La Línea de la Concepción","La Linea de la Concepcion","36.1611","-5.3486","Spain","ES","ESP","Andalusia","","63365","1724170346"
"Daljā","Dalja","27.6500","30.7000","Egypt","EG","EGY","Al Minyā","","63751","1818235648"
"Kettering","Kettering","52.3931","-0.7229","United Kingdom","GB","GBR","Northamptonshire","","56226","1826968679"
"Forest","Forest","50.8131","4.3247","Belgium","BE","BEL","Brussels-Capital Region","","56581","1056640531"
"Călăraşi","Calarasi","44.2000","27.3333","Romania","RO","ROU","Călăraşi","admin","65181","1642445982"
"Magong","Magong","23.5667","119.5833","Taiwan","TW","TWN","Penghu","admin","63729","1158297836"
"Malden","Malden","42.4305","-71.0576","United States","US","USA","Massachusetts","","65602","1840000430"
"Villejuif","Villejuif","48.7919","2.3636","France","FR","FRA","Île-de-France","","55208","1250637012"
"Ameca","Ameca","20.5486","-104.0431","Mexico","MX","MEX","Jalisco","minor","60386","1484716155"
"Rundu","Rundu","-17.9167","19.7667","Namibia","NA","NAM","Kavango East","admin","63431","1516885937"
"Akurana","Akurana","7.3650","80.6172","Sri Lanka","LK","LKA","Central","","63397","1144063686"
"Ambarawa","Ambarawa","-7.2667","110.4000","Indonesia","ID","IDN","Jawa Tengah","","63193","1360693146"
"Hurlingham","Hurlingham","-34.5883","-58.6392","Argentina","AR","ARG","Buenos Aires","minor","60000","1032992892"
"Kitale","Kitale","1.0167","35.0000","Kenya","KE","KEN","Trans Nzoia","admin","63245","1404890081"
"Nagakute","Nagakute","35.1840","137.0487","Japan","JP","JPN","Aichi","","62473","1392003238"
"Scheveningen","Scheveningen","52.1081","4.2731","Netherlands","NL","NLD","Zuid-Holland","","58850","1528986041"
"Paphos","Paphos","34.7667","32.4167","Cyprus","CY","CYP","Páfos","admin","63600","1196607408"
"Guayama","Guayama","17.9743","-66.1104","Puerto Rico","PR","PRI","Puerto Rico","","65343","1630035675"
"Karonga","Karonga","-9.9333","33.9333","Malawi","MW","MWI","Karonga","admin","61609","1454358935"
"Etterbeek","Etterbeek","50.8333","4.3833","Belgium","BE","BEL","Brussels-Capital Region","","48473","1056267205"
"Songkhla","Songkhla","7.2061","100.5967","Thailand","TH","THA","Songkhla","admin","63834","1764543650"
"Kresek","Kresek","-6.1314","106.3800","Indonesia","ID","IDN","Jawa Barat","","60735","1360535530"
"Soroti","Soroti","1.7150","33.6111","Uganda","UG","UGA","Soroti","admin","60900","1800434608"
"Ouezzane","Ouezzane","34.8000","-5.5833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","59606","1504736892"
"Tagajō","Tagajo","38.2938","141.0043","Japan","JP","JPN","Miyagi","","61937","1392003360"
"As Suwaydā’","As Suwayda'","32.7125","36.5667","Syria","SY","SYR","As Suwaydā’","admin","64730","1760680757"
"Euclides da Cunha","Euclides da Cunha","-10.5078","-39.0139","Brazil","BR","BRA","Bahia","","60666","1076277178"
"Wamena","Wamena","-4.0975","138.9511","Indonesia","ID","IDN","Papua Pegunungan","admin","64967","1360024872"
"Popondetta","Popondetta","-8.7656","148.2347","Papua New Guinea","PG","PNG","Northern","admin","65000","1598887925"
"Ełk","Elk","53.8214","22.3622","Poland","PL","POL","Warmińsko-Mazurskie","minor","62006","1616783003"
"Faro","Faro","37.0161","-7.9350","Portugal","PT","PRT","Faro","admin","64560","1620330407"
"Faīẕābād","Faizabad","37.1166","70.5800","Afghanistan","AF","AFG","Badakhshān","admin","64704","1004452653"
"Alba Iulia","Alba Iulia","46.0669","23.5700","Romania","RO","ROU","Alba","admin","64227","1642449522"
"Cosamaloapan","Cosamaloapan","18.3667","-95.8000","Mexico","MX","MEX","Veracruz","minor","57147","1484307969"
"Sokhumi","Sokhumi","43.0000","41.0167","Georgia","GE","GEO","Abkhazia","admin","64441","1268394475"
"Ellesmere Port","Ellesmere Port","53.2790","-2.8970","United Kingdom","GB","GBR","Cheshire West and Chester","","61090","1826938401"
"Cherbourg","Cherbourg","49.6333","-1.6000","France","FR","FRA","Normandie","minor","60991","1250026751"
"Le Blanc-Mesnil","Le Blanc-Mesnil","48.9387","2.4614","France","FR","FRA","Île-de-France","","57498","1250383006"
"Bondy","Bondy","48.9022","2.4828","France","FR","FRA","Île-de-France","","54587","1250274671"
"Yamatotakada","Yamatotakada","34.5167","135.7333","Japan","JP","JPN","Nara","","60791","1392003391"
"Beccar","Beccar","-34.4667","-58.5167","Argentina","AR","ARG","Buenos Aires","","58811","1032780556"
"Manouba","Manouba","36.8078","10.1011","Tunisia","TN","TUN","Manouba","admin","58792","1788608864"
"Nagari","Nagari","13.3214","79.5856","India","IN","IND","Andhra Pradesh","","62253","1356993418"
"Bangor","Bangor","54.6600","-5.6700","United Kingdom","GB","GBR","Ards and North Down","","61011","1826282262"
"Inhambane","Inhambane","-23.8650","35.3833","Mozambique","MZ","MOZ","Inhambane","admin","63837","1508945412"
"Qal‘ah-ye Now","Qal\`ah-ye Now","34.9867","63.1292","Afghanistan","AF","AFG","Bādghīs","admin","64125","1004947440"
"Runcorn","Runcorn","53.3419","-2.7336","United Kingdom","GB","GBR","Halton","","61789","1826349439"
"Seinäjoki","Seinajoki","62.7917","22.8417","Finland","FI","FIN","Etelä-Pohjanmaa","admin","64150","1246118146"
"Taunton","Taunton","51.0190","-3.1000","United Kingdom","GB","GBR","Somerset","","60479","1826959489"
"Littlehampton","Littlehampton","50.8094","-0.5409","United Kingdom","GB","GBR","West Sussex","","58357","1826096073"
"Florence-Graham","Florence-Graham","33.9682","-118.2447","United States","US","USA","California","","64109","1840037057"
"Latacunga","Latacunga","-0.9319","-78.6161","Ecuador","EC","ECU","Cotopaxi","admin","63842","1218947988"
"Twin Rivers","Twin Rivers","40.2631","-74.4917","United States","US","USA","New Jersey","","64012","1840005474"
"Vaslui","Vaslui","46.6383","27.7292","Romania","RO","ROU","Vaslui","admin","63035","1642644428"
"Choi Hung","Choi Hung","22.3350","114.2067","Hong Kong","HK","HKG","","","18435","1344000072"
"Gorno-Altaysk","Gorno-Altaysk","51.9600","85.9600","Russia","RU","RUS","Altay","admin","63214","1643549691"
"Lalmanirhat","Lalmanirhat","25.9167","89.4500","Bangladesh","BD","BGD","Rangpur","","60322","1050802394"
"Hasuda","Hasuda","35.9945","139.6624","Japan","JP","JPN","Saitama","","61473","1392294890"
"Marano di Napoli","Marano di Napoli","40.9000","14.1833","Italy","IT","ITA","Campania","","59862","1380179165"
"Dédougou","Dedougou","12.4667","-3.4667","Burkina Faso","BF","BFA","Boucle du Mouhoun","admin","63617","1854743311"
"Legnano","Legnano","45.5781","8.9183","Italy","IT","ITA","Lombardy","","60177","1380045698"
"Ruislip","Ruislip","51.5760","-0.4330","United Kingdom","GB","GBR","Hillingdon","","58217","1826571723"
"Ōsakasayama","Osakasayama","34.5000","135.5500","Japan","JP","JPN","Ōsaka","","58593","1392003332"
"Taal","Taal","13.8833","120.9333","Philippines","PH","PHL","Batangas","","61460","1608961915"
"Andoharanofotsy","Andoharanofotsy","-18.9750","47.5333","Madagascar","MG","MDG","Antananarivo","","58878","1450381707"
"Mukōchō","Mukocho","34.9500","135.7000","Japan","JP","JPN","Kyōto","","56152","1392146541"
"Nkhotakota","Nkhotakota","-12.9167","34.3000","Malawi","MW","MWI","Nkhotakota","admin","59854","1454281420"
"Suresnes","Suresnes","48.8700","2.2200","France","FR","FRA","Île-de-France","","49311","1250405027"
"Bellevue","Bellevue","48.8710","2.3850","France","FR","FRA","Île-de-France","","57699","1250712822"
"Tsushima","Tsushima","35.1771","136.7413","Japan","JP","JPN","Aichi","","60908","1392244952"
"La Habra","La Habra","33.9282","-117.9515","United States","US","USA","California","","63287","1840020585"
"Anuradhapura","Anuradhapura","8.3350","80.4108","Sri Lanka","LK","LKA","Northern","admin","63208","1144764835"
"Kratie","Kratie","12.4800","106.0300","Cambodia","KH","KHM","Kratie","admin","60000","1116453792"
"Ban Rangsit","Ban Rangsit","14.0167","100.7500","Thailand","TH","THA","Pathum Thani","","58838","1764251181"
"Beledweyne","Beledweyne","4.7358","45.2036","Somalia","SO","SOM","Hiiraan","admin","62945","1706018197"
"Maduraivayal","Maduraivayal","13.0631","80.1719","India","IN","IND","Tamil Nādu","","57563","1356036953"
"Wallasey","Wallasey","53.4158","-3.0233","United Kingdom","GB","GBR","Wirral","","60284","1826741418"
"Jette","Jette","50.8667","4.3333","Belgium","BE","BEL","Brussels-Capital Region","","52728","1056315355"
"Tandag","Tandag","9.0789","126.1986","Philippines","PH","PHL","Surigao del Sur","admin","62669","1608155856"
"Dar Naim","Dar Naim","18.0333","-15.9667","Mauritania","MR","MRT","Nouakchott Nord","admin","61089","1478696248"
"Khartsyzk","Khartsyzk","48.0333","38.1500","Ukraine","UA","UKR","Donetska Oblast","","59763","1804780082"
"Montebello","Montebello","34.0155","-118.1108","United States","US","USA","California","","62828","1840020499"
"Iganga","Iganga","0.6150","33.4850","Uganda","UG","UGA","Iganga","admin","53870","1800987671"
"Barrow in Furness","Barrow in Furness","54.1108","-3.2261","United Kingdom","GB","GBR","Cumbria","","56745","1826352599"
"North Bergen","North Bergen","40.7938","-74.0242","United States","US","USA","New Jersey","","62686","1840081753"
"Saraburi","Saraburi","14.5286","100.9114","Thailand","TH","THA","Saraburi","admin","61750","1764111243"
"Brookline","Brookline","42.3243","-71.1408","United States","US","USA","Massachusetts","","62620","1840053672"
"Rayong","Rayong","12.6742","101.2789","Thailand","TH","THA","Rayong","admin","61902","1764920057"
"Stryi","Stryi","49.2500","23.8500","Ukraine","UA","UKR","Lvivska Oblast","minor","59325","1804862811"
"Giurgiu","Giurgiu","43.9008","25.9739","Romania","RO","ROU","Giurgiu","admin","61353","1642984994"
"Glew","Glew","-34.8833","-58.3833","Argentina","AR","ARG","Buenos Aires","","57878","1032691083"
"Fort Portal","Fort Portal","0.6544","30.2744","Uganda","UG","UGA","Kabarole","admin","60800","1800139086"
"Saint-Ouen","Saint-Ouen","48.9123","2.3342","France","FR","FRA","Île-de-France","","50697","1250000037"
"Rovaniemi","Rovaniemi","66.5000","25.7333","Finland","FI","FIN","Lappi","admin","62420","1246718173"
"Bobigny","Bobigny","48.9106","2.4397","France","FR","FRA","Île-de-France","minor","54363","1250306149"
"Pico Rivera","Pico Rivera","33.9901","-118.0888","United States","US","USA","California","","62383","1840020506"
"Kolda","Kolda","12.8833","-14.9500","Senegal","SN","SEN","Kolda","admin","62258","1686219536"
"Santarém","Santarem","39.2339","-8.6861","Portugal","PT","PRT","Santarém","admin","62200","1620315932"
"Fussa","Fussa","35.7333","139.3333","Japan","JP","JPN","Tōkyō","","56713","1392749897"
"Napier","Napier","-39.4903","176.9178","New Zealand","NZ","NZL","Hawke’s Bay","admin","62241","1554064936"
"Linares","Linares","24.8597","-99.5647","Mexico","MX","MEX","Nuevo León","minor","57731","1484504671"
"Chornomorsk","Chornomorsk","46.3017","30.6569","Ukraine","UA","UKR","Odeska Oblast","","59800","1804199117"
"Madang","Madang","-5.2167","145.8000","Papua New Guinea","PG","PNG","Madang","admin","62023","1598116558"
"Aş Şaff","As Saff","29.5772","31.2906","Egypt","EG","EGY","Al Jīzah","","59348","1818181272"
"Lytkarino","Lytkarino","55.5833","37.9000","Russia","RU","RUS","Moskovskaya Oblast’","","58606","1643669152"
"Bebington","Bebington","53.3500","-3.0030","United Kingdom","GB","GBR","Wirral","","57336","1826464817"
"Tirur","Tirur","10.9000","75.9200","India","IN","IND","Kerala","","58490","1356646583"
"Kırklareli","Kirklareli","41.7347","27.2253","Turkey","TR","TUR","Kırklareli","admin","61880","1792182918"
"Berriozábal","Berriozabal","16.8003","-93.2733","Mexico","MX","MEX","Chiapas","minor","51722","1484051098"
"Ruhengeri","Ruhengeri","-1.5000","29.6333","Rwanda","RW","RWA","Northern Province","","59333","1646793543"
"Macclesfield","Macclesfield","53.2500","-2.1300","United Kingdom","GB","GBR","Cheshire East","","56581","1826522277"
"Don Galo","Don Galo","14.5072","120.9844","Philippines","PH","PHL","Parañaque","","11645","1608536522"
"Vratsa","Vratsa","43.2000","23.5500","Bulgaria","BG","BGR","Vratsa","admin","61702","1100261281"
"Yala","Yala","6.5425","101.2831","Thailand","TH","THA","Yala","admin","61250","1764430323"
"Puerto Limón","Puerto Limon","10.0022","-83.0840","Costa Rica","CR","CRI","Limón","admin","60710","1188214393"
"Chambéry","Chambery","45.5700","5.9118","France","FR","FRA","Auvergne-Rhône-Alpes","minor","58917","1250283861"
"Braintree","Braintree","51.8780","0.5500","United Kingdom","GB","GBR","Essex","","55792","1826745611"
"Gia Nghĩa","Gia Nghia","11.9833","107.7000","Vietnam","VN","VNM","Đắk Nông","admin","61413","1704361005"
"Fontenay-sous-Bois","Fontenay-sous-Bois","48.8517","2.4772","France","FR","FRA","Île-de-France","","52256","1250384578"
"Portugalete","Portugalete","43.3194","-3.0194","Spain","ES","ESP","Basque Country","","45285","1724017694"
"Suileng","Suileng","47.2460","127.1060","China","CN","CHN","Heilongjiang","minor","57789","1156128173"
"Poonamallee","Poonamallee","13.0465","80.1100","India","IN","IND","Tamil Nādu","","56237","1356180476"
"The Hammocks","The Hammocks","25.6700","-80.4482","United States","US","USA","Florida","","61461","1840029009"
"Koja","Koja","26.3344","127.8057","Japan","JP","JPN","Okinawa","","58658","1392299085"
"Pinagkaisahan","Pinagkaisahan","14.5229","121.0555","Philippines","PH","PHL","Makati","","57343","1608216406"
"Nonoichi","Nonoichi","36.5194","136.6098","Japan","JP","JPN","Ishikawa","","57090","1392003342"
"Santa Rosa de Copán","Santa Rosa de Copan","14.7667","-88.7833","Honduras","HN","HND","Copán","admin","61083","1340050563"
"Coyhaique","Coyhaique","-45.5667","-72.0667","Chile","CL","CHL","Aysén","admin","61210","1152541137"
"Jidd Ḩafş","Jidd Hafs","26.2190","50.5380","Bahrain","BH","BHR","Al ‘Āşimah","","44769","1048383141"
"Monterey Park","Monterey Park","34.0497","-118.1326","United States","US","USA","California","","61153","1840020500"
"Limerick","Limerick","52.6653","-8.6238","Ireland","IE","IRL","Limerick","admin","58319","1372126011"
"Leskovac","Leskovac","42.9981","21.9461","Serbia","RS","SRB","Leskovac","admin","60288","1688752541"
"Mislata","Mislata","39.4750","-0.4156","Spain","ES","ESP","Valencia","","44320","1724001899"
"Sidi Bennour","Sidi Bennour","32.6500","-8.4333","Morocco","MA","MAR","Casablanca-Settat","","55815","1504893162"
"Longxing","Longxing","35.6091","111.2304","China","CN","CHN","Shanxi","minor","56381","1156152527"
"Raharpur","Raharpur","24.8194","88.3250","Bangladesh","BD","BGD","Rājshāhi","","58624","1050222103"
"Hunedoara","Hunedoara","45.7697","22.9203","Romania","RO","ROU","Hunedoara","admin","60525","1642078107"
"Rafael Calzada","Rafael Calzada","-34.7833","-58.3667","Argentina","AR","ARG","Buenos Aires","","56419","1032898708"
"La Mesa","La Mesa","32.7703","-117.0204","United States","US","USA","California","","61040","1840020625"
"Royal Tunbridge Wells","Royal Tunbridge Wells","51.1320","0.2630","United Kingdom","GB","GBR","Kent","","57772","1826011948"
"At Tawāhī","At Tawahi","12.7833","44.9833","Yemen","YE","YEM","‘Adan","minor","52984","1887058366"
"Trang","Trang","7.5575","99.6103","Thailand","TH","THA","Trang","admin","60591","1764343817"
"Gardena","Gardena","33.8943","-118.3072","United States","US","USA","California","","60917","1840020482"
"Ban Bang Kaeo","Ban Bang Kaeo","13.6371","100.6636","Thailand","TH","THA","Samut Prakan","","56949","1764813354"
"Takaishi","Takaishi","34.5167","135.4500","Japan","JP","JPN","Ōsaka","","55910","1392000468"
"Puteaux","Puteaux","48.8850","2.2389","France","FR","FRA","Île-de-France","","45157","1250407425"
"Antsinanantsena","Antsinanantsena","-18.8333","47.5500","Madagascar","MG","MDG","Antananarivo","","57363","1450343987"
"Kyustendil","Kyustendil","42.2833","22.6833","Bulgaria","BG","BGR","Kyustendil","admin","60681","1100543134"
"Revere","Revere","42.4189","-71.0040","United States","US","USA","Massachusetts","","60720","1840000457"
"Medford","Medford","42.4234","-71.1087","United States","US","USA","Massachusetts","","60708","1840000431"
"Oyem","Oyem","1.6000","11.5667","Gabon","GA","GAB","Woleu-Ntem","admin","60685","1266867613"
"Cupertino","Cupertino","37.3168","-122.0466","United States","US","USA","California","","60681","1840018950"
"Lokoja","Lokoja","7.8019","6.7442","Nigeria","NG","NGA","Kogi","admin","60579","1566772925"
"Juigalpa","Juigalpa","12.1083","-85.3694","Nicaragua","NI","NIC","Chontales","admin","60583","1558986603"
"Trujillo","Trujillo","15.9186","-85.9533","Honduras","HN","HND","Colón","admin","60558","1340756106"
"Tczew","Tczew","54.0875","18.7972","Poland","PL","POL","Pomorskie","minor","57990","1616578037"
"Wellingborough","Wellingborough","52.2939","-0.6964","United Kingdom","GB","GBR","Northamptonshire","","56564","1826752476"
"Lorient","Lorient","47.7500","-3.3600","France","FR","FRA","Bretagne","minor","57246","1250615711"
"North Miami","North Miami","25.9008","-80.1686","United States","US","USA","Florida","","60449","1840015154"
"Chakapara","Chakapara","22.6300","88.3500","India","IN","IND","West Bengal","","35282","1356000447"
"Temascalcingo","Temascalcingo","19.9147","-100.0036","Mexico","MX","MEX","México","minor","58169","1484038182"
"Tezonapa","Tezonapa","18.6058","-96.6875","Mexico","MX","MEX","Veracruz","minor","56956","1484617215"
"Vejle","Vejle","55.7167","9.5333","Denmark","DK","DNK","Syddanmark","admin","60231","1208585023"
"Nausori","Nausori","-18.0244","178.5454","Fiji","FJ","FJI","Tailevu","","57882","1242640119"
"Taylorsville","Taylorsville","40.6569","-111.9493","United States","US","USA","Utah","","60295","1840021387"
"Zushi","Zushi","35.2833","139.5833","Japan","JP","JPN","Kanagawa","","56996","1392442008"
"Świdnica","Swidnica","50.8500","16.4833","Poland","PL","POL","Dolnośląskie","minor","57761","1616002338"
"Irvington","Irvington","40.7243","-74.2317","United States","US","USA","New Jersey","","60224","1840080906"
"West Allis","West Allis","43.0068","-88.0296","United States","US","USA","Wisconsin","","60220","1840003034"
"Bungoma","Bungoma","0.5667","34.5667","Kenya","KE","KEN","Bungoma","admin","55857","1404689847"
"Pinotepa","Pinotepa","16.3412","-98.0537","Mexico","MX","MEX","Oaxaca","minor","53148","1484150923"
"Sidi Yahya Zaer","Sidi Yahya Zaer","33.7105","-6.8831","Morocco","MA","MAR","Rabat-Salé-Kénitra","","57790","1504365068"
"Alfortville","Alfortville","48.8050","2.4239","France","FR","FRA","Île-de-France","","44805","1250857642"
"Évry","Evry","48.6239","2.4294","France","FR","FRA","Île-de-France","minor","53641","1250675373"
"Midalt","Midalt","32.6800","-4.7300","Morocco","MA","MAR","Drâa-Tafilalet","","55304","1504279379"
"Irákleio","Irakleio","38.0500","23.7667","Greece","GR","GRC","Attikí","minor","49642","1300476566"
"Itānagar","Itanagar","27.1000","93.6200","India","IN","IND","Arunāchal Pradesh","admin","59490","1356023817"
"Užice","Uzice","43.8500","19.8500","Serbia","RS","SRB","Užice","admin","59747","1688591624"
"Union","Union","40.6953","-74.2697","United States","US","USA","New Jersey","","59825","1840056367"
"Samut Prakan","Samut Prakan","13.5897","100.6386","Thailand","TH","THA","Samut Prakan","admin","51309","1764159228"
"Antigua Guatemala","Antigua Guatemala","14.5667","-90.7333","Guatemala","GT","GTM","Sacatepéquez","admin","59091","1320020808"
"Suharekë","Suhareke","42.3800","20.8219","Kosovo","XK","XKS","Suharekë","admin","59722","1901918563"
"Azrou","Azrou","33.4417","-5.2247","Morocco","MA","MAR","Fès-Meknès","","54350","1504585311"
"Klimovsk","Klimovsk","55.3667","37.5333","Russia","RU","RUS","Moskovskaya Oblast’","","56239","1643385248"
"Samannūd","Samannud","30.9622","31.2425","Egypt","EG","EGY","Al Gharbīyah","","57177","1818038230"
"Kasungu","Kasungu","-13.0333","33.4833","Malawi","MW","MWI","Kasungu","admin","58653","1454117674"
"Hod HaSharon","Hod HaSharon","32.15","34.8833","Israel","IL","ISR","Central","","56659","1376159880"
"Ródos","Rodos","36.4412","28.2225","Greece","GR","GRC","Nótio Aigaío","minor","56969","1300944675"
"Stavroúpoli","Stavroupoli","40.6667","22.9333","Greece","GR","GRC","Kentrikí Makedonía","minor","46008","1300659280"
"Tacuarembó","Tacuarembo","-31.7333","-55.9833","Uruguay","UY","URY","Tacuarembó","admin","54755","1858952595"
"Kronjo","Kronjo","-6.0667","106.4167","Indonesia","ID","IDN","Jawa Barat","","55152","1360000841"
"Folkestone","Folkestone","51.0810","1.1660","United Kingdom","GB","GBR","Kent","","51337","1826257230"
"Mérida","Merida","38.9000","-6.3333","Spain","ES","ESP","Extremadura","admin","59424","1724941889"
"Al Hoceïma","Al Hoceima","35.2472","-3.9322","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","56716","1504990575"
"Meaux","Meaux","48.9603","2.8883","France","FR","FRA","Île-de-France","minor","55750","1250827686"
"Ocosingo","Ocosingo","16.9072","-92.0961","Mexico","MX","MEX","Chiapas","minor","47688","1484937914"
"White Plains","White Plains","41.0220","-73.7547","United States","US","USA","New York","","59452","1840003477"
"Hamura","Hamura","35.7672","139.3109","Japan","JP","JPN","Saitama","","53970","1392153436"
"Hoboken","Hoboken","40.7452","-74.0281","United States","US","USA","New Jersey","","59369","1840003599"
"Pursat","Pursat","12.5333","103.9167","Cambodia","KH","KHM","Pursat","admin","58255","1116566865"
"Zumpango","Zumpango","19.7969","-99.0992","Mexico","MX","MEX","México","minor","50742","1484625824"
"Royal Leamington Spa","Royal Leamington Spa","52.2920","-1.5370","United Kingdom","GB","GBR","Warwickshire","","55733","1826633006"
"Dzerzhinskiy","Dzerzhinskiy","55.6333","37.8500","Russia","RU","RUS","Moskovskaya Oblast’","","55669","1643369563"
"Artemisa","Artemisa","22.8136","-82.7633","Cuba","CU","CUB","Artemisa","admin","59130","1192325408"
"Crosby","Crosby","53.4872","-3.0343","United Kingdom","GB","GBR","Sefton","","51789","1826899809"
"Karīmganj","Karimganj","24.8700","92.3500","India","IN","IND","","","56854","1356078103"
"Yevlax","Yevlax","40.6172","47.1500","Azerbaijan","AZ","AZE","Yevlax","admin","59036","1031581655"
"Mūndka","Mundka","28.6794","77.0284","India","IN","IND","Delhi","","54541","1356773697"
"Cerro de Pasco","Cerro de Pasco","-10.6864","-76.2625","Peru","PE","PER","Pasco","admin","58899","1604446908"
"Liuhu","Liuhu","35.5449","106.6801","China","CN","CHN","Gansu","","47442","1156989557"
"Rijswijk","Rijswijk","52.0456","4.3300","Netherlands","NL","NLD","Zuid-Holland","minor","55220","1528456884"
"Sartrouville","Sartrouville","48.9372","2.1644","France","FR","FRA","Île-de-France","","52774","1250494022"
"Clamart","Clamart","48.8014","2.2628","France","FR","FRA","Île-de-France","","52926","1250130113"
"Stratford","Stratford","51.5423","-0.0026","United Kingdom","GB","GBR","Newham","","51387","1826465421"
"Huamantla","Huamantla","19.3133","-97.9228","Mexico","MX","MEX","Tlaxcala","minor","51996","1484180362"
"Sevran","Sevran","48.9333","2.5333","France","FR","FRA","Île-de-France","","51734","1250179512"
"Kruševac","Krusevac","43.5833","21.3267","Serbia","RS","SRB","Kruševac","admin","58745","1688595236"
"South Whittier","South Whittier","33.9336","-118.0312","United States","US","USA","California","","58800","1840019209"
"Mineiros","Mineiros","-17.5689","-52.5508","Brazil","BR","BRA","Goiás","","52935","1076000916"
"Qiryat Ata","Qiryat Ata","32.8","35.1","Israel","IL","ISR","Haifa","","55464","1376676929"
"Aversa","Aversa","40.9667","14.2000","Italy","IT","ITA","Campania","","52794","1380486559"
"Tenkodogo","Tenkodogo","11.7833","-0.3667","Burkina Faso","BF","BFA","Centre-Est","admin","58600","1854646157"
"Dosso","Dosso","13.0500","3.2000","Niger","NE","NER","Dosso","admin","58671","1562574611"
"Naval","Naval","11.5833","124.4500","Philippines","PH","PHL","Biliran","admin","58187","1608929611"
"Bāruipur","Baruipur","22.3654","88.4325","India","IN","IND","West Bengal","","53128","1356275520"
"Boudouaou","Boudouaou","36.7300","3.4100","Algeria","DZ","DZA","Boumerdes","","56398","1012554481"
"Mandapeta","Mandapeta","16.8700","81.9300","India","IN","IND","Andhra Pradesh","","56063","1356648879"
"Chelles","Chelles","48.8833","2.6000","France","FR","FRA","Île-de-France","","55154","1250001485"
"Port Coquitlam","Port Coquitlam","49.2625","-122.7811","Canada","CA","CAN","British Columbia","","58612","1124473757"
"Rosh Ha‘Ayin","Rosh Ha\`Ayin","32.0956","34.9567","Israel","IL","ISR","Central","","56300","1376619380"
"Gllogovc","Gllogovc","42.6236","20.8939","Kosovo","XK","XKS","Gllogovc","admin","58531","1901031751"
"Kitgum","Kitgum","3.2889","32.8778","Uganda","UG","UGA","Kitgum","admin","56891","1800380883"
"Bârlad","Barlad","46.2167","27.6667","Romania","RO","ROU","Vaslui","","55837","1642043593"
"Dapaong","Dapaong","10.8667","0.2000","Togo","TG","TGO","Savanes","admin","58071","1768366976"
"Caridad","Caridad","14.4828","120.8958","Philippines","PH","PHL","Cavite","","27891","1608839244"
"Novohrad-Volynskyi","Novohrad-Volynskyi","50.5833","27.6333","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","56288","1804059567"
"Babīlā","Babila","33.4728","36.3344","Syria","SY","SYR","Rīf Dimashq","minor","50880","1760722268"
"Margate","Margate","26.2466","-80.2119","United States","US","USA","Florida","","58331","1840015999"
"Békéscsaba","Bekescsaba","46.6790","21.0910","Hungary","HU","HUN","Békés","admin","58002","1348329493"
"Chaniá","Chania","35.5167","24.0167","Greece","GR","GRC","Kríti","minor","53910","1300233408"
"Dayr al Balaḩ","Dayr al Balah","31.4189","34.3517","Gaza Strip","XG","XGZ","","","54439","1916770606"
"Shijōnawate","Shijonawate","34.7400","135.6394","Japan","JP","JPN","Ōsaka","","55094","1392003452"
"Oak Lawn","Oak Lawn","41.7139","-87.7528","United States","US","USA","Illinois","","58032","1840011306"
"Legionowo","Legionowo","52.4000","20.8833","Poland","PL","POL","Mazowieckie","minor","53886","1616994284"
"Bhīmunipatnam","Bhimunipatnam","17.8846","83.4393","India","IN","IND","Andhra Pradesh","","55082","1356889289"
"Carson City","Carson City","39.1511","-119.7476","United States","US","USA","Nevada","admin","57957","1840003826"
"Fountainebleau","Fountainebleau","25.7723","-80.3460","United States","US","USA","Florida","","57957","1840028983"
"Slavonski Brod","Slavonski Brod","45.1667","18.0167","Croatia","HR","HRV","Brodsko-Posavska Županija","admin","56769","1191097030"
"Hilden","Hilden","51.1714","6.9394","Germany","DE","DEU","North Rhine-Westphalia","","55764","1276577152"
"Ampelókipoi","Ampelokipoi","40.6500","22.9167","Greece","GR","GRC","Kentrikí Makedonía","minor","37381","1300184728"
"Nuevo Casas Grandes","Nuevo Casas Grandes","30.4167","-107.9167","Mexico","MX","MEX","Chihuahua","minor","55553","1484004002"
"Acatzingo","Acatzingo","18.9817","-97.7822","Mexico","MX","MEX","Puebla","minor","52078","1484022961"
"Umm el Faḥm","Umm el Fahm","32.5194","35.1536","Israel","IL","ISR","Haifa","","55300","1376814378"
"Biel/Bienne","Biel/Bienne","47.1333","7.2500","Switzerland","CH","CHE","Bern","","55159","1756649723"
"Kidderminster","Kidderminster","52.3885","-2.2490","United Kingdom","GB","GBR","Worcestershire","","55530","1826052030"
"Lipjan","Lipjan","42.5300","21.1386","Kosovo","XK","XKS","Lipjan","admin","57605","1901682048"
"Coconut Creek","Coconut Creek","26.2803","-80.1842","United States","US","USA","Florida","","57553","1840015132"
"Karuhatan","Karuhatan","14.6883","120.9761","Philippines","PH","PHL","Valenzuela","","37748","1608392998"
"Boac","Boac","13.4500","121.8333","Philippines","PH","PHL","Marinduque","admin","57283","1608972476"
"Mariano Acosta","Mariano Acosta","-34.7167","-58.7833","Argentina","AR","ARG","Buenos Aires","","54081","1032455258"
"Altrincham","Altrincham","53.3838","-2.3547","United Kingdom","GB","GBR","Trafford","","52419","1826280426"
"Nacaome","Nacaome","13.5333","-87.4833","Honduras","HN","HND","Valle","admin","57345","1340964186"
"Mafeteng","Mafeteng","-29.8167","27.2500","Lesotho","LS","LSO","Mafeteng","admin","57059","1426506047"
"Pansol","Pansol","14.6514","121.0800","Philippines","PH","PHL","Quezon","","34240","1608991025"
"Elenga","Elenga","24.3386","89.9219","Bangladesh","BD","BGD","Dhaka","","55000","1050181953"
"Chinhoyi","Chinhoyi","-17.3497","30.1944","Zimbabwe","ZW","ZWE","Mashonaland West","admin","56794","1716523245"
"My Drarga","My Drarga","30.3800","-9.4800","Morocco","MA","MAR","Souss-Massa","","50946","1504399163"
"Weymouth","Weymouth","50.6130","-2.4570","United Kingdom","GB","GBR","Dorset","","54539","1826316742"
"Gümüşhane","Gumushane","40.4597","39.4778","Turkey","TR","TUR","Gümüşhane","admin","57269","1792559945"
"Pithāpuram","Pithapuram","17.1167","82.2667","India","IN","IND","Andhra Pradesh","","54859","1356440541"
"Caacupé","Caacupe","-25.3861","-57.1408","Paraguay","PY","PRY","Cordillera","admin","56864","1600095784"
"Melo","Melo","-32.3667","-54.1833","Uruguay","UY","URY","Cerro Largo","admin","51830","1858600801"
"Barri","Barri","51.4050","-3.2700","United Kingdom","GB","GBR","Vale of Glamorgan, The","","51502","1826605492"
"Villa Hayes","Villa Hayes","-25.1000","-57.5667","Paraguay","PY","PRY","Presidente Hayes","admin","57217","1600405064"
"Belize City","Belize City","17.4986","-88.1886","Belize","BZ","BLZ","Belize","admin","57169","1084150679"
"Catemaco","Catemaco","18.4167","-95.1167","Mexico","MX","MEX","Veracruz","minor","49451","1484469922"
"Miragoâne","Miragoane","18.4458","-73.0900","Haiti","HT","HTI","Nippes","admin","56864","1332665283"
"Fountain Valley","Fountain Valley","33.7105","-117.9514","United States","US","USA","California","","57139","1840020575"
"Maun","Maun","-19.9833","23.4167","Botswana","BW","BWA","North West","admin","57067","1072985345"
"Nikšić","Niksic","42.7778","18.9500","Montenegro","ME","MNE","Nikšić","admin","56970","1499844133"
"Ushuaia","Ushuaia","-54.8019","-68.3031","Argentina","AR","ARG","Tierra del Fuego, Antártida e Islas del Atlántico Sur","admin","56956","1032847885"
"Berwyn","Berwyn","41.8433","-87.7909","United States","US","USA","Illinois","","56960","1840007027"
"Pijijiapan","Pijijiapan","15.6917","-93.2093","Mexico","MX","MEX","Chiapas","minor","53525","1484994799"
"Bagong Pag-Asa","Bagong Pag-Asa","14.6622","121.0331","Philippines","PH","PHL","Quezon","","29389","1608625000"
"Leith","Leith","55.9800","-3.1700","United Kingdom","GB","GBR","Edinburgh, City of","","50030","1826964931"
"San Pedro","San Pedro","25.7578","-102.9831","Mexico","MX","MEX","Coahuila","minor","49490","1484673086"
"Esplugas de Llobregat","Esplugas de Llobregat","41.3767","2.0858","Spain","ES","ESP","Catalonia","","46777","1724352928"
"National City","National City","32.6654","-117.0983","United States","US","USA","California","","56846","1840020622"
"Wote","Wote","-1.7833","37.6333","Kenya","KE","KEN","Makueni","admin","56419","1404304549"
"Zalău","Zalau","47.1911","23.0572","Romania","RO","ROU","Sălaj","admin","56202","1642791988"
"Tirupparangunram","Tirupparangunram","9.8815","78.0731","India","IN","IND","Tamil Nādu","","51543","1356018714"
"Arcadia","Arcadia","34.1342","-118.0373","United States","US","USA","California","","56697","1840019223"
"Sfântu-Gheorghe","Sfantu-Gheorghe","45.8636","25.7875","Romania","RO","ROU","Covasna","admin","56006","1642189178"
"Abū Qīr","Abu Qir","31.3167","30.0667","Egypt","EG","EGY","Al Iskandarīyah","","37997","1818093442"
"Muğla","Mugla","37.2167","28.3667","Turkey","TR","TUR","Muğla","admin","56619","1792992104"
"Évora","Evora","38.5667","-7.9000","Portugal","PT","PRT","Évora","admin","56596","1620456226"
"Villarrica","Villarrica","-25.7500","-56.4333","Paraguay","PY","PRY","Guairá","admin","56385","1600446518"
"Madhipura","Madhipura","25.9200","86.7920","India","IN","IND","Bihār","","54472","1356220190"
"Phra Nakhon Si Ayutthaya","Phra Nakhon Si Ayutthaya","14.3478","100.5606","Thailand","TH","THA","Phra Nakhon Si Ayutthaya","admin","52952","1764002329"
"Moyobamba","Moyobamba","-6.0333","-76.9667","Peru","PE","PER","San Martín","admin","56452","1604635600"
"Mocoa","Mocoa","1.1500","-76.6475","Colombia","CO","COL","Putumayo","admin","56398","1170970726"
"Greenford","Greenford","51.5299","-0.3488","United Kingdom","GB","GBR","Ealing","","46787","1826685341"
"Zouerate","Zouerate","22.7333","-12.4667","Mauritania","MR","MRT","Tiris Zemmour","admin","56345","1478799785"
"Trenčín","Trencin","48.8919","18.0367","Slovakia","SK","SVK","Trenčin","admin","55593","1703029695"
"Rahovec","Rahovec","42.3994","20.6547","Kosovo","XK","XKS","Rahovec","admin","56208","1901336358"
"Mendi","Mendi","-6.1478","143.6572","Papua New Guinea","PG","PNG","Southern Highlands","admin","56055","1598467297"
"Jelgava","Jelgava","56.6483","23.7139","Latvia","LV","LVA","Jelgava","admin","55336","1428370571"
"Saint-Quentin","Saint-Quentin","49.8486","3.2864","France","FR","FRA","Hauts-de-France","minor","53856","1250806002"
"Francisco I. Madero","Francisco I. Madero","25.7753","-103.2731","Mexico","MX","MEX","Coahuila","minor","50084","1484764993"
"Castelo Branco","Castelo Branco","39.8167","-7.5000","Portugal","PT","PRT","Castelo Branco","admin","56109","1620277400"
"Rio Tinto","Rio Tinto","41.1780","-8.5600","Portugal","PT","PRT","Porto","","50713","1620258719"
"San Giorgio a Cremano","San Giorgio a Cremano","40.8333","14.3333","Italy","IT","ITA","Campania","","45122","1380360808"
"Quiapo","Quiapo","14.6000","120.9830","Philippines","PH","PHL","Manila","","23138","1608465903"
"Vigan","Vigan","17.5747","120.3869","Philippines","PH","PHL","Ilocos Sur","admin","53935","1608368724"
"Mollet","Mollet","41.5356","2.2107","Spain","ES","ESP","Catalonia","","51294","1724443143"
"Sankt Pölten","Sankt Polten","48.2000","15.6167","Austria","AT","AUT","Niederösterreich","admin","55514","1040313109"
"Massy","Massy","48.7309","2.2713","France","FR","FRA","Île-de-France","","50644","1250651220"
"Zalaegerszeg","Zalaegerszeg","46.8392","16.8511","Hungary","HU","HUN","Zala","admin","55470","1348293073"
"Fuchū","Fuchu","34.3926","132.5045","Japan","JP","JPN","Hiroshima","","51096","1392003519"
"Dunfermline","Dunfermline","56.0719","-3.4393","United Kingdom","GB","GBR","Fife","","53100","1826424354"
"Gallarate","Gallarate","45.6649","8.7914","Italy","IT","ITA","Lombardy","","53425","1380947310"
"Hà Giang","Ha Giang","22.8333","104.9833","Vietnam","VN","VNM","Hà Giang","admin","55559","1704495953"
"Iba","Iba","15.3333","119.9833","Philippines","PH","PHL","Zambales","admin","55581","1608986957"
"Rowley Regis","Rowley Regis","52.4880","-2.0500","United Kingdom","GB","GBR","Sandwell","","50257","1826180390"
"Ohrid","Ohrid","41.1169","20.8019","Macedonia","MK","MKD","Ohrid","admin","55749","1807586875"
"Corbeil-Essonnes","Corbeil-Essonnes","48.6139","2.4820","France","FR","FRA","Île-de-France","","51234","1250742467"
"Florida","Florida","-34.5167","-58.5000","Argentina","AR","ARG","Buenos Aires","","48158","1032721967"
"Guliston","Guliston","40.4833","68.7833","Uzbekistan","UZ","UZB","Sirdaryo","admin","55600","1860505287"
"Madīnat Ḩamad","Madinat Hamad","26.1128","50.5139","Bahrain","BH","BHR","Ash Shamālīyah","","52700","1048909146"
"New Brunswick","New Brunswick","40.4870","-74.4450","United States","US","USA","New Jersey","","55671","1840001339"
"Neath","Neath","51.6600","-3.8100","United Kingdom","GB","GBR","Neath Port Talbot","","50658","1826679432"
"M.Ə. Rəsulzadə","M.A. Rasulzada","40.4344","49.8336","Azerbaijan","AZ","AZE","Bakı","","51800","1031204213"
"Bootle","Bootle","53.4457","-2.9891","United Kingdom","GB","GBR","Sefton","","51394","1826320670"
"Skien","Skien","59.2081","9.5528","Norway","NO","NOR","Vestfold og Telemark","admin","55513","1578807356"
"Hamilton","Hamilton","55.7770","-4.0390","United Kingdom","GB","GBR","South Lanarkshire","","53188","1826692620"
"Marikina Heights","Marikina Heights","14.6534","121.1187","Philippines","PH","PHL","Marikina","","40967","1608286421"
"Lautoka","Lautoka","-17.6242","177.4528","Fiji","FJ","FJI","Ba","","52220","1242457077"
"Huntington Park","Huntington Park","33.9800","-118.2167","United States","US","USA","California","","55439","1840019235"
"Ercolano","Ercolano","40.8000","14.3500","Italy","IT","ITA","Campania","","52763","1380009957"
"Cakung","Cakung","-6.2507","106.8685","Indonesia","ID","IDN","Jakarta","","39126","1360997891"
"Buta","Buta","2.8000","24.7333","Congo (Kinshasa)","CD","COD","Bas-Uélé","admin","55313","1180712204"
"Vaulx-en-Velin","Vaulx-en-Velin","45.7768","4.9186","France","FR","FRA","Auvergne-Rhône-Alpes","","52795","1250814472"
"Amalāpuram","Amalapuram","16.5787","82.0061","India","IN","IND","Andhra Pradesh","","53231","1356997622"
"Lancaster","Lancaster","54.0489","-2.8014","United Kingdom","GB","GBR","Lancashire","","52234","1826197341"
"Ibiza","Ibiza","38.9089","1.4328","Spain","ES","ESP","Balearic Islands","","50715","1724123244"
"Lampang","Lampang","18.3000","99.5000","Thailand","TH","THA","Lampang","admin","55044","1764081847"
"Vranje","Vranje","42.5542","21.8972","Serbia","RS","SRB","Vranje","admin","55138","1688474456"
"Tanjombato","Tanjombato","-18.9500","47.5167","Madagascar","MG","MDG","Antananarivo","","50373","1450363732"
"Eltham","Eltham","51.4510","0.0520","United Kingdom","GB","GBR","Greenwich","","48964","1826707596"
"Cagnes-sur-Mer","Cagnes-sur-Mer","43.6644","7.1489","France","FR","FRA","Provence-Alpes-Côte d’Azur","","52178","1250261503"
"Perth Amboy","Perth Amboy","40.5202","-74.2724","United States","US","USA","New Jersey","","55046","1840001340"
"Bādurpalle","Badurpalle","17.5468","78.3650","India","IN","IND","Andhra Pradesh","","48835","1356883509"
"Shiogama","Shiogama","38.3144","141.0220","Japan","JP","JPN","Miyagi","","51999","1392003536"
"Al Karnak","Al Karnak","25.7186","32.6586","Egypt","EG","EGY","Al Uqşur","","26488","1818862945"
"Cintalapa de Figueroa","Cintalapa de Figueroa","16.6978","-93.7203","Mexico","MX","MEX","Chiapas","minor","49201","1484563700"
"Kyaliwajjala","Kyaliwajjala","0.3800","32.6467","Uganda","UG","UGA","Wakiso","","50000","1800975843"
"Gabrovo","Gabrovo","42.8667","25.3333","Bulgaria","BG","BGR","Gabrovo","admin","54608","1100696135"
"Gutao","Gutao","37.1989","112.1767","China","CN","CHN","Shanxi","minor","50000","1156151766"
"Padangpanjang","Padangpanjang","-0.4500","100.4167","Indonesia","ID","IDN","Sumatera Barat","","52422","1360231049"
"Mikkeli","Mikkeli","61.6833","27.2667","Finland","FI","FIN","Etelä-Savo","admin","54665","1246875185"
"Chake Chake","Chake Chake","-5.2395","39.7700","Tanzania","TZ","TZA","Pemba South","admin","49959","1834409018"
"Morden","Morden","51.4015","-0.1949","United Kingdom","GB","GBR","Merton","","48233","1826667903"
"Cumbernauld","Cumbernauld","55.9450","-3.9940","United Kingdom","GB","GBR","North Lanarkshire","","50920","1826862515"
"Choisy-le-Roi","Choisy-le-Roi","48.7630","2.4090","France","FR","FRA","Île-de-France","","46154","1250504037"
"Brentwood","Brentwood","51.6200","0.3050","United Kingdom","GB","GBR","Essex","","52586","1826743524"
"Muban Saeng Bua Thong","Muban Saeng Bua Thong","13.9424","100.3913","Thailand","TH","THA","Nonthaburi","","51932","1764003021"
"Tamiami","Tamiami","25.7556","-80.4016","United States","US","USA","Florida","","54525","1840029085"
"Arwal","Arwal","25.2500","84.6800","India","IN","IND","Bihār","","51849","1356697722"
"Ruma","Ruma","45.0000","19.8333","Serbia","RS","SRB","Ruma","admin","54339","1688037789"
"Gjilan","Gjilan","42.4647","21.4669","Kosovo","XK","XKS","Gjilan","admin","54239","1901235642"
"Bayonne","Bayonne","43.4900","-1.4800","France","FR","FRA","Nouvelle-Aquitaine","minor","51894","1250223048"
"Willenhall","Willenhall","52.5798","-2.0605","United Kingdom","GB","GBR","Walsall","","51429","1826751733"
"Westchester","Westchester","25.7471","-80.3523","United States","US","USA","Florida","","54238","1840014241"
"La Jagua de Ibirico","La Jagua de Ibirico","9.5667","-73.3333","Colombia","CO","COL","Cesar","minor","46722","1170526836"
"Louangphabang","Louangphabang","19.8833","102.1333","Laos","LA","LAO","Louangphabang","admin","53792","1418530759"
"Shiraoka","Shiraoka","36.0191","139.6769","Japan","JP","JPN","Saitama","","52103","1392337140"
"Andover","Andover","51.2080","-1.4800","United Kingdom","GB","GBR","Hampshire","","50887","1826277987"
"Plainfield","Plainfield","40.6154","-74.4157","United States","US","USA","New Jersey","","54183","1840001090"
"Prachuap Khiri Khan","Prachuap Khiri Khan","11.8167","99.8000","Thailand","TH","THA","Prachuap Khiri Khan","admin","54018","1764877766"
"Oak Park","Oak Park","41.8872","-87.7899","United States","US","USA","Illinois","","54100","1840011307"
"Gao","Gao","16.2667","-0.0500","Mali","ML","MLI","Gao","admin","49483","1466571211"
"Tulcán","Tulcan","0.8117","-77.7186","Ecuador","EC","ECU","Carchi","admin","53558","1218976878"
"Korçë","Korce","40.6167","20.7667","Albania","AL","ALB","Korçë","admin","51152","1008361921"
"Paramount","Paramount","33.8977","-118.1651","United States","US","USA","California","","53904","1840020504"
"Muktāgācha","Muktagacha","24.7662","90.2562","Bangladesh","BD","BGD","Mymensingh","","49915","1050147298"
"Al Ma‘allā’","Al Ma\`alla'","12.7897","45.0064","Yemen","YE","YEM","‘Adan","minor","49891","1887620650"
"Aspen Hill","Aspen Hill","39.0927","-77.0826","United States","US","USA","Maryland","","53850","1840005825"
"Sherpur","Sherpur","24.6650","89.4190","Bangladesh","BD","BGD","Rājshāhi","","51750","1050871216"
"Rosny-sous-Bois","Rosny-sous-Bois","48.8667","2.4833","France","FR","FRA","Île-de-France","","46043","1250643229"
"Ampitatafika","Ampitatafika","-18.9333","47.4750","Madagascar","MG","MDG","Fianarantsoa","","50373","1450438823"
"Sakon Nakhon","Sakon Nakhon","17.1564","104.1456","Thailand","TH","THA","Sakon Nakhon","admin","53618","1764476362"
"Alajuela","Alajuela","10.1640","-84.2645","Costa Rica","CR","CRI","Alajuela","admin","48326","1188394508"
"Qalqīlyah","Qalqilyah","32.1903","34.9686","West Bank","XW","XWB","","","51683","1934513064"
"Kabale","Kabale","-1.2500","29.9900","Uganda","UG","UGA","Kabale","admin","53200","1800408751"
"Liberia","Liberia","10.6333","-85.4333","Costa Rica","CR","CRI","Guanacaste","admin","53382","1188743090"
"Marondera","Marondera","-18.1897","31.5467","Zimbabwe","ZW","ZWE","Mashonaland East","admin","52283","1716375676"
"Yeovil","Yeovil","50.9452","-2.6370","United Kingdom","GB","GBR","Somerset","","49698","1826177078"
"Moskovskiy","Moskovskiy","55.6000","37.3500","Russia","RU","RUS","Moskovskaya Oblast’","","50167","1643344934"
"Nes Ẕiyyona","Nes Ziyyona","31.9333","34.8","Israel","IL","ISR","Central","","50200","1376745785"
"San Vicente","San Vicente","13.6453","-88.7842","El Salvador","SV","SLV","San Vicente","admin","53213","1222913273"
"Cologno Monzese","Cologno Monzese","45.5286","9.2783","Italy","IT","ITA","Lombardy","","47720","1380466267"
"Kendale Lakes","Kendale Lakes","25.7081","-80.4078","United States","US","USA","Florida","","53392","1840029045"
"Sutton in Ashfield","Sutton in Ashfield","53.1250","-1.2610","United Kingdom","GB","GBR","Nottinghamshire","","48527","1826247882"
"El Ghâzîyé","El Ghaziye","33.5186","35.3661","Lebanon","LB","LBN","Liban-Sud","","50000","1422216263"
"Santo Cristo","Santo Cristo","14.6603","121.0300","Philippines","PH","PHL","Quezon","","25783","1608708630"
"Noisy-le-Sec","Noisy-le-Sec","48.8894","2.4503","France","FR","FRA","Île-de-France","","44463","1250727880"
"Scafati","Scafati","40.7536","14.5253","Italy","IT","ITA","Campania","","50686","1380992516"
"Rho","Rho","45.5333","9.0333","Italy","IT","ITA","Lombardy","","50904","1380877337"
"Hrazdan","Hrazdan","40.5000","44.7667","Armenia","AM","ARM","Kotayk’","admin","52808","1051566733"
"Targovishte","Targovishte","43.2500","26.5833","Bulgaria","BG","BGR","Targovishte","admin","53041","1100987486"
"Buena Vista Tomatlán","Buena Vista Tomatlan","19.2102","-102.5869","Mexico","MX","MEX","Michoacán","","47498","1484801408"
"Maha Sarakham","Maha Sarakham","16.1772","103.3008","Thailand","TH","THA","Maha Sarakham","admin","52866","1764568961"
"Valladolid","Valladolid","20.6894","-88.2017","Mexico","MX","MEX","Yucatán","","48973","1484332013"
"Jiménez","Jimenez","27.1300","-104.9067","Mexico","MX","MEX","Chihuahua","minor","48859","1484452730"
"Boujad","Boujad","32.7667","-6.4000","Morocco","MA","MAR","Béni Mellal-Khénifra","","46893","1504259447"
"Chahār Dangeh","Chahar Dangeh","35.6031","51.3097","Iran","IR","IRN","Tehrān","","49950","1364382390"
"Takeo","Takeo","10.9833","104.7833","Cambodia","KH","KHM","Takeo","admin","52000","1116674518"
"Alytus","Alytus","54.4014","24.0492","Lithuania","LT","LTU","Alytaus Miestas","admin","51856","1440273296"
"Bodø","Bodo","67.2827","14.3751","Norway","NO","NOR","Nordland","admin","52803","1578023501"
"Santurce-Antiguo","Santurce-Antiguo","43.3303","-3.0314","Spain","ES","ESP","Basque Country","","46085","1724220415"
"Tam Hiệp","Tam Hiep","10.9497","106.8575","Vietnam","VN","VNM","Đồng Nai","","35747","1704536698"
"Bang Bua Thong","Bang Bua Thong","13.9099","100.4263","Thailand","TH","THA","Nonthaburi","minor","50141","1764694888"
"Aloha","Aloha","45.4920","-122.8725","United States","US","USA","Oregon","","52704","1840017414"
"Gennevilliers","Gennevilliers","48.9333","2.3000","France","FR","FRA","Île-de-France","","48530","1250529722"
"Chong Nonsi","Chong Nonsi","13.6965","100.5427","Thailand","TH","THA","Krung Thep Maha Nakhon","","47872","1764522683"
"Catford","Catford","51.4452","-0.0207","United Kingdom","GB","GBR","Lewisham","","44905","1826259011"
"Myrnohrad","Myrnohrad","48.2911","37.2681","Ukraine","UA","UKR","Donetska Oblast","","50360","1804762140"
"Iwakura","Iwakura","35.2794","136.8714","Japan","JP","JPN","Aichi","","47975","1392765544"
"Levittown","Levittown","40.7241","-73.5125","United States","US","USA","New York","","52540","1840005270"
"Elmshorn","Elmshorn","53.7519","9.6511","Germany","DE","DEU","Schleswig-Holstein","","50141","1276091723"
"West New York","West New York","40.7857","-74.0094","United States","US","USA","New Jersey","","52485","1840001035"
"Noveleta","Noveleta","14.4333","120.8833","Philippines","PH","PHL","Cavite","","49452","1608459830"
"Takahama","Takahama","34.9275","136.9878","Japan","JP","JPN","Aichi","","48685","1392860759"
"Bloomfield","Bloomfield","40.8098","-74.1869","United States","US","USA","New Jersey","","52391","1840131592"
"Collegno","Collegno","45.0775","7.5724","Italy","IT","ITA","Piedmont","","49597","1380000029"
"Gopālpur","Gopalpur","24.5583","89.9167","Bangladesh","BD","BGD","Dhaka","","50160","1050090584"
"Jinotepe","Jinotepe","11.8472","-86.1997","Nicaragua","NI","NIC","Carazo","admin","52175","1558246417"
"Ain El Aouda","Ain El Aouda","33.8111","-6.7922","Morocco","MA","MAR","Rabat-Salé-Kénitra","","49816","1504400263"
"La Garenne-Colombes","La Garenne-Colombes","48.9056","2.2445","France","FR","FRA","Île-de-France","","29642","1250897232"
"Suzukawa","Suzukawa","35.3730","139.3839","Japan","JP","JPN","Kanagawa","","48532","1392676251"
"Lamía","Lamia","38.9000","22.4333","Greece","GR","GRC","Stereá Elláda","admin","52006","1300400935"
"Mangochi","Mangochi","-14.4667","35.2667","Malawi","MW","MWI","Mangochi","admin","51429","1454766726"
"Qiryat Ono","Qiryat Ono","32.0636","34.8553","Israel","IL","ISR","Tel Aviv","","41900","1376929399"
"Lənkəran","Lankaran","38.7536","48.8511","Azerbaijan","AZ","AZE","Lənkəran","admin","51300","1031218031"
"Vila Real","Vila Real","41.2958","-7.7461","Portugal","PT","PRT","Vila Real","admin","51850","1620292123"
"Kardzhali","Kardzhali","41.6500","25.3667","Bulgaria","BG","BGR","Kardzhali","admin","51841","1100473094"
"Loreto","Loreto","22.2667","-101.9833","Mexico","MX","MEX","Zacatecas","minor","43411","1484384276"
"Placentia","Placentia","33.8807","-117.8553","United States","US","USA","California","","51951","1840020583"
"Aliso Viejo","Aliso Viejo","33.5792","-117.7289","United States","US","USA","California","","51943","1840019321"
"Choma","Choma","-16.7711","26.9922","Zambia","ZM","ZMB","Southern","admin","51842","1894419971"
"Pen-y-Bont ar Ogwr","Pen-y-Bont ar Ogwr","51.5072","-3.5784","United Kingdom","GB","GBR","Bridgend","","49404","1826587515"
"Cojutepeque","Cojutepeque","13.7167","-88.9333","El Salvador","SV","SLV","Cuscatlán","admin","50315","1222979186"
"Livry-Gargan","Livry-Gargan","48.9192","2.5361","France","FR","FRA","Île-de-France","","45692","1250653520"
"Wheaton","Wheaton","39.0492","-77.0573","United States","US","USA","Maryland","","51836","1840031295"
"Ksar Hellal","Ksar Hellal","35.6429","10.8911","Tunisia","TN","TUN","Monastir","","49376","1788303496"
"Teplice","Teplice","50.6444","13.8319","Czechia","CZ","CZE","Ústecký Kraj","","49705","1203801370"
"Edenvale","Edenvale","-26.1411","28.1528","South Africa","ZA","ZAF","Gauteng","","49292","1710512085"
"Eger","Eger","47.8990","20.3747","Hungary","HU","HUN","Heves","admin","51168","1348559499"
"Ḩarastā","Harasta","33.5667","36.3667","Syria","SY","SYR","Rīf Dimashq","minor","45974","1760273732"
"Rosemead","Rosemead","34.0689","-118.0823","United States","US","USA","California","","51698","1840020495"
"Kunnamkulam","Kunnamkulam","10.6500","76.0800","India","IN","IND","Kerala","","49230","1356769956"
"Jihlava","Jihlava","49.4003","15.5906","Czechia","CZ","CZE","Vysočina","admin","51125","1203810604"
"Whangarei","Whangarei","-35.7250","174.3236","New Zealand","NZ","NZL","Northland","admin","50784","1554000636"
"Sombor","Sombor","45.7833","19.1167","Serbia","RS","SRB","Sombor","admin","51471","1688575779"
"Qasbat Tadla","Qasbat Tadla","32.6000","-6.2667","Morocco","MA","MAR","Béni Mellal-Khénifra","","47343","1504612704"
"Florin","Florin","38.4832","-121.4043","United States","US","USA","California","","51568","1840017581"
"Upplands Väsby","Upplands Vasby","59.5167","17.9167","Sweden","SE","SWE","Stockholm","minor","48907","1752271400"
"Binondo","Binondo","14.6000","120.9670","Philippines","PH","PHL","Manila","","20491","1608318528"
"Tizimín","Tizimin","21.1425","-88.1647","Mexico","MX","MEX","Yucatán","minor","46971","1484254957"
"Kendrāparha","Kendraparha","20.5000","86.4200","India","IN","IND","Odisha","","41404","1356476101"
"Surbiton","Surbiton","51.3940","-0.3070","United Kingdom","GB","GBR","Kingston upon Thames","","45132","1826418537"
"Tlapa de Comonfort","Tlapa de Comonfort","17.5461","-98.5764","Mexico","MX","MEX","Guerrero","minor","37975","1484110723"
"Kasuya","Kasuya","33.6108","130.4806","Japan","JP","JPN","Fukuoka","","47981","1392003430"
"Guozhen","Guozhen","34.3668","107.1980","China","CN","CHN","Shaanxi","minor","49100","1156084652"
"Puttalam","Puttalam","8.0330","79.8260","Sri Lanka","LK","LKA","North Western","","45661","1144219740"
"Stretford","Stretford","53.4466","-2.3086","United Kingdom","GB","GBR","Trafford","","46910","1826645692"
"Cuetzalan","Cuetzalan","20.0333","-97.5167","Mexico","MX","MEX","Puebla","minor","47983","1484831454"
"Salekhard","Salekhard","66.5333","66.6333","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","admin","51188","1643083395"
"Sar-e Pul","Sar-e Pul","36.2214","65.9278","Afghanistan","AF","AFG","Sar-e Pul","admin","51075","1004047427"
"Evere","Evere","50.8667","4.4000","Belgium","BE","BEL","Brussels-Capital Region","","42656","1056731697"
"Garges-lès-Gonesse","Garges-les-Gonesse","48.9728","2.4008","France","FR","FRA","Île-de-France","","43239","1250181473"
"Jūrmala","Jurmala","56.9681","23.7703","Latvia","LV","LVA","Jūrmala","admin","50561","1428952300"
"Cheshunt","Cheshunt","51.7020","-0.0350","United Kingdom","GB","GBR","Hertfordshire","","45832","1826870758"
"Komotiní","Komotini","41.1000","25.4167","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","admin","50990","1300373048"
"Country Club","Country Club","25.9407","-80.3102","United States","US","USA","Florida","","51105","1840029029"
"Rosso","Rosso","16.5128","-15.8050","Mauritania","MR","MRT","Trarza","admin","51026","1478524903"
"Covina","Covina","34.0903","-117.8818","United States","US","USA","California","","51061","1840019241"
"Levakant","Levakant","37.8667","68.9167","Tajikistan","TJ","TJK","Khatlon","minor","48300","1762807871"
"La Courneuve","La Courneuve","48.9322","2.3967","France","FR","FRA","Île-de-France","","45053","1250362239"
"Halle-Neustadt","Halle-Neustadt","51.4789","11.9214","Germany","DE","DEU","Saxony-Anhalt","","46280","1276281757"
"Bastia","Bastia","42.7008","9.4503","France","FR","FRA","Corsica","","48503","1250664815"
"Changanācheri","Changanacheri","9.4667","76.5500","India","IN","IND","Kerala","","47485","1356848735"
"Bodupāl","Bodupal","17.4139","78.5783","India","IN","IND","Andhra Pradesh","","43692","1356074103"
"Longchamps","Longchamps","-34.8500","-58.3833","Argentina","AR","ARG","Buenos Aires","","47622","1032203390"
"Plainview","Plainview","14.5777","121.0337","Philippines","PH","PHL","Mandaluyong","","26557","1608416217"
"Barendrecht","Barendrecht","51.8500","4.5333","Netherlands","NL","NLD","Zuid-Holland","minor","48673","1528438292"
"Skenderaj","Skenderaj","42.7467","20.7886","Kosovo","XK","XKS","Skenderaj","admin","50858","1901259570"
"Jarash","Jarash","32.2723","35.8914","Jordan","JO","JOR","Jarash","admin","50745","1400505859"
"Bangued","Bangued","17.5965","120.6179","Philippines","PH","PHL","Abra","admin","50382","1608442037"
"Lakewood","Lakewood","41.4822","-81.8008","United States","US","USA","Ohio","","50841","1840000608"
"Beni Yakhlef","Beni Yakhlef","33.6555","-7.3221","Morocco","MA","MAR","Casablanca-Settat","","48338","1504512130"
"La Goulette","La Goulette","36.8181","10.3050","Tunisia","TN","TUN","Tunis","","45711","1788529784"
"Barnet","Barnet","51.6444","-0.1997","United Kingdom","GB","GBR","Barnet","","47359","1826246190"
"Gori","Gori","41.9817","44.1124","Georgia","GE","GEO","Shida Kartli","admin","48143","1268442763"
"Kirkcaldy","Kirkcaldy","56.1107","-3.1674","United Kingdom","GB","GBR","Fife","","48108","1826174957"
"Abovyan","Abovyan","40.2739","44.6256","Armenia","AM","ARM","Kotayk’","","44400","1051509043"
"Colcapirhua","Colcapirhua","-17.4167","-66.2500","Bolivia","BO","BOL","Cochabamba","","48631","1068759261"
"Bagneux","Bagneux","48.7983","2.3137","France","FR","FRA","Île-de-France","","40936","1250888157"
"Clondalkin","Clondalkin","53.3203","-6.3947","Ireland","IE","IRL","South Dublin","","47905","1372645698"
"North Bethesda","North Bethesda","39.0393","-77.1191","United States","US","USA","Maryland","","50695","1840031494"
"Azemmour","Azemmour","33.2878","-8.3422","Morocco","MA","MAR","Casablanca-Settat","","40920","1504675793"
"Sutton","Sutton","51.3656","-0.1963","United Kingdom","GB","GBR","Sutton","","41483","1826827095"
"Gbadolite","Gbadolite","4.2750","21.0000","Congo (Kinshasa)","CD","COD","Nord-Ubangi","admin","50493","1180674224"
"West Bridgford","West Bridgford","52.9320","-1.1270","United Kingdom","GB","GBR","Nottinghamshire","","47109","1826779157"
"Maḩmūd-e Rāqī","Mahmud-e Raqi","35.0206","69.3306","Afghanistan","AF","AFG","Kāpīsā","admin","50490","1004151943"
"Guadalupe Nuevo","Guadalupe Nuevo","14.5587","121.0446","Philippines","PH","PHL","Makati","","18341","1608843390"
"Meudon","Meudon","48.8123","2.2382","France","FR","FRA","Île-de-France","","45818","1250863022"
"Lissone","Lissone","45.6167","9.2500","Italy","IT","ITA","Lombardy","","45535","1380370266"
"Colonia del Sol","Colonia del Sol","22.9125","-109.9208","Mexico","MX","MEX","Baja California Sur","","48032","1484445555"
"Villa Alsina","Villa Alsina","-34.6667","-58.4167","Argentina","AR","ARG","Buenos Aires","","41155","1032506308"
"Beckenham","Beckenham","51.4080","-0.0220","United Kingdom","GB","GBR","Bromley","","46844","1826520605"
"Luwero","Luwero","0.8331","32.4994","Uganda","UG","UGA","Luwero","admin","49100","1800067821"
"Vidin","Vidin","44.0000","22.8667","Bulgaria","BG","BGR","Vidin","admin","50274","1100463536"
"Ma‘ān","Ma\`an","30.1962","35.7341","Jordan","JO","JOR","Ma‘ān","admin","50350","1400670443"
"Gracias","Gracias","14.5833","-88.5833","Honduras","HN","HND","Lempira","admin","50256","1340141391"
"Östersund","Ostersund","63.1792","14.6358","Sweden","SE","SWE","Jämtland","admin","49806","1752026711"
"Selibe Phikwe","Selibe Phikwe","-21.9758","27.8400","Botswana","BW","BWA","Selibe Phikwe","admin","49724","1072308184"
"Al Balyanā","Al Balyana","26.2333","32.0000","Egypt","EG","EGY","Sūhāj","","46997","1818610123"
"Tlalmanalco","Tlalmanalco","19.2044","-98.8025","Mexico","MX","MEX","México","minor","46130","1484054902"
"Cypress","Cypress","33.8171","-118.0386","United States","US","USA","California","","50279","1840019327"
"Tubod","Tubod","8.0500","123.8000","Philippines","PH","PHL","Lanao del Norte","admin","50073","1608442374"
"Bagnolet","Bagnolet","48.8692","2.4181","France","FR","FRA","Île-de-France","","36060","1250335183"
"Campobasso","Campobasso","41.5667","14.6667","Italy","IT","ITA","Molise","admin","49262","1380959273"
"Merksem","Merksem","51.2428","4.4414","Belgium","BE","BEL","Flanders","","44808","1056201097"
"Al Khārjah","Al Kharjah","25.4400","30.5500","Egypt","EG","EGY","Al Wādī al Jadīd","admin","49991","1818872500"
"Ashton","Ashton","53.4897","-2.0952","United Kingdom","GB","GBR","Tameside","","45198","1826027715"
"Kotelniki","Kotelniki","55.6617","37.8672","Russia","RU","RUS","Moskovskaya Oblast’","","46763","1643514399"
"Nichelino","Nichelino","44.9955","7.6466","Italy","IT","ITA","Piedmont","","47721","1380201460"
"Semera","Semera","11.7922","41.0086","Ethiopia","ET","ETH","Āfar","admin","50000","1231617820"
"Paderno Dugnano","Paderno Dugnano","45.5719","9.1692","Italy","IT","ITA","Lombardy","","46701","1380402852"
"El‘ad","El\`ad","32.0522","34.9511","Israel","IL","ISR","Central","","46896","1376486332"
"Świętochłowice","Swietochlowice","50.2919","18.9178","Poland","PL","POL","Śląskie","minor","46494","1616910155"
"Pākaur","Pakaur","24.6300","87.8500","India","IN","IND","Jhārkhand","","45840","1356035089"
"Mairena del Aljarafe","Mairena del Aljarafe","37.3333","-6.0667","Spain","ES","ESP","Andalusia","","47161","1724732496"
"Yumbe","Yumbe","3.4650","31.2450","Uganda","UG","UGA","Yumbe","admin","47600","1800297508"
"Mpigi","Mpigi","0.2300","32.3300","Uganda","UG","UGA","Mpigi","admin","49500","1800370948"
"Kangar","Kangar","6.4333","100.2000","Malaysia","MY","MYS","Perlis","admin","48898","1458939333"
"Manali","Manali","13.1667","80.2667","India","IN","IND","Tamil Nādu","","37748","1356866268"
"Belfort","Belfort","47.6400","6.8500","France","FR","FRA","Bourgogne-Franche-Comté","minor","46954","1250108238"
"Huancavelica","Huancavelica","-12.7864","-74.9756","Peru","PE","PER","Huancavelica","admin","49570","1604876709"
"Dollard-des-Ormeaux","Dollard-des-Ormeaux","45.4833","-73.8167","Canada","CA","CAN","Quebec","","49637","1124902278"
"Cerritos","Cerritos","33.8678","-118.0686","United States","US","USA","California","","49630","1840019215"
"Jbaïl","Jbail","34.1236","35.6511","Lebanon","LB","LBN","Mont-Liban","minor","40000","1422678909"
"Strood","Strood","51.3930","0.4780","United Kingdom","GB","GBR","Medway","","40000","1826719630"
"Luleå","Lulea","65.5844","22.1539","Sweden","SE","SWE","Norrbotten","admin","49123","1752765449"
"Couva","Couva","10.4167","-61.4500","Trinidad and Tobago","TT","TTO","Couva/Tabaquite/Talparo","admin","48858","1780835692"
"Inverness","Inverness","57.4778","-4.2247","United Kingdom","GB","GBR","Highland","","47290","1826161019"
"Waterford","Waterford","52.2567","-7.1292","Ireland","IE","IRL","Waterford","admin","48369","1372267114"
"Figueras","Figueras","42.2667","2.9650","Spain","ES","ESP","Catalonia","","47088","1724895039"
"San Felíu de Llobregat","San Feliu de Llobregat","41.3833","2.0439","Spain","ES","ESP","Catalonia","","45642","1724279247"
"Salisbury","Salisbury","51.0700","-1.7900","United Kingdom","GB","GBR","Wiltshire","","45477","1826304328"
"Ramat HaSharon","Ramat HaSharon","32.15","34.8333","Israel","IL","ISR","Tel Aviv","","46700","1376215427"
"Kaédi","Kaedi","16.1506","-13.5056","Mauritania","MR","MRT","Gorgol","admin","49152","1478607377"
"Ayr","Ayr","55.4580","-4.6290","United Kingdom","GB","GBR","South Ayrshire","","46780","1826430700"
"Meoqui","Meoqui","28.2722","-105.4819","Mexico","MX","MEX","Chihuahua","minor","44853","1484867296"
"San Isidro","San Isidro","-34.4667","-58.5167","Argentina","AR","ARG","Buenos Aires","minor","43595","1032862666"
"Cantel","Cantel","14.8112","-91.4555","Guatemala","GT","GTM","Quetzaltenango","minor","47087","1320112635"
"Isla","Isla","18.0292","-95.5264","Mexico","MX","MEX","Veracruz","minor","43349","1484843047"
"Corroios","Corroios","38.6147","-9.1508","Portugal","PT","PRT","Setúbal","","46475","1620361953"
"Châtillon","Chatillon","48.8000","2.2900","France","FR","FRA","Île-de-France","","37010","1250813874"
"Xam Nua","Xam Nua","20.4150","104.0480","Laos","LA","LAO","Houaphan","admin","46800","1418975079"
"José Mármol","Jose Marmol","-34.7833","-58.3667","Argentina","AR","ARG","Buenos Aires","","41109","1032126414"
"Wokingham","Wokingham","51.4100","-0.8400","United Kingdom","GB","GBR","Wokingham","","46745","1826878333"
"Karlovy Vary","Karlovy Vary","50.2306","12.8725","Czechia","CZ","CZE","Karlovarský Kraj","admin","48319","1203518899"
"Banbury","Banbury","52.0610","-1.3360","United Kingdom","GB","GBR","Oxfordshire","","46853","1826594194"
"Talence","Talence","44.8000","-0.5840","France","FR","FRA","Nouvelle-Aquitaine","","43820","1250490677"
"Bihāt","Bihat","25.4253","86.0208","India","IN","IND","Bihār","","46012","1356815038"
"Pejë","Peje","42.6603","20.2917","Kosovo","XK","XKS","Pejë","admin","48962","1901339694"
"Rye","Rye","41.0075","-73.6872","United States","US","USA","New York","","49043","1840000784"
"Santo Niño","Santo Nino","14.5033","121.0031","Philippines","PH","PHL","Parañaque","","34860","1608225111"
"Schagen","Schagen","52.7833","4.8000","Netherlands","NL","NLD","Noord-Holland","minor","46553","1528010358"
"Mantes-la-Jolie","Mantes-la-Jolie","48.9908","1.7172","France","FR","FRA","Île-de-France","minor","44227","1250666731"
"Rovenky","Rovenky","48.0833","39.3667","Ukraine","UA","UKR","Luhanska Oblast","","46734","1804938726"
"Asadābād","Asadabad","34.8742","71.1528","Afghanistan","AF","AFG","Kunaṟ","admin","48400","1004251962"
"Biləcəri","Bilacari","40.4314","49.8081","Azerbaijan","AZ","AZE","Bakı","","45008","1031718088"
"Nellikkuppam","Nellikkuppam","11.7667","79.6833","India","IN","IND","Tamil Nādu","","46679","1356710287"
"Middelburg","Middelburg","51.5000","3.6167","Netherlands","NL","NLD","Zeeland","admin","47939","1528241984"
"Tulum","Tulum","20.2119","-87.4658","Mexico","MX","MEX","Quintana Roo","minor","33374","1484002889"
"Gobārdānga","Gobardanga","22.8700","88.7600","India","IN","IND","West Bengal","","45377","1356963190"
"Krasnoznamensk","Krasnoznamensk","55.6008","37.0358","Russia","RU","RUS","Moskovskaya Oblast’","","41769","1643985194"
"Llavallol","Llavallol","-34.7667","-58.3833","Argentina","AR","ARG","Buenos Aires","","41463","1032288016"
"Nueva Loja","Nueva Loja","0.0847","-76.8828","Ecuador","EC","ECU","Sucumbíos","admin","48562","1218895755"
"Shertallai","Shertallai","9.6869","76.3361","India","IN","IND","Kerala","","45827","1356986621"
"Seregno","Seregno","45.6500","9.2000","Italy","IT","ITA","Lombardy","","45131","1380007396"
"Kita","Kita","13.0504","-9.4833","Mali","ML","MLI","Kayes","minor","46435","1466078415"
"Serowe","Serowe","-22.3833","26.7167","Botswana","BW","BWA","Central","admin","48573","1072708388"
"Mandeville","Mandeville","18.0333","-77.5000","Jamaica","JM","JAM","Manchester","admin","48317","1388493847"
"Birendranagar","Birendranagar","28.6000","81.6333","Nepal","NP","NPL","Karṇālī","admin","47914","1524574121"
"Bakau","Bakau","13.4833","-16.6833","The Gambia","GM","GMB","Kanifing","","43098","1270091879"
"Nong Khai","Nong Khai","17.8681","102.7444","Thailand","TH","THA","Nong Khai","admin","48274","1764279113"
"Bindura","Bindura","-17.3000","31.3333","Zimbabwe","ZW","ZWE","Mashonaland Central","admin","46275","1716331687"
"North Highlands","North Highlands","38.6713","-121.3721","United States","US","USA","California","","48424","1840018841"
"Isiolo","Isiolo","0.3500","37.5833","Kenya","KE","KEN","Isiolo","admin","45989","1404771203"
"Antelope","Antelope","38.7153","-121.3610","United States","US","USA","California","","48421","1840022490"
"Mercedes","Mercedes","-33.2500","-58.0333","Uruguay","UY","URY","Soriano","admin","41974","1858182538"
"Everett","Everett","42.4064","-71.0545","United States","US","USA","Massachusetts","","48368","1840000425"
"University","University","28.0771","-82.4335","United States","US","USA","Florida","","48367","1840073852"
"Sijua","Sijua","23.7692","86.1673","India","IN","IND","Jhārkhand","","39372","1356762860"
"Borgerhout","Borgerhout","51.2117","4.4407","Belgium","BE","BEL","Flanders","","45769","1056235367"
"Malakoff","Malakoff","48.8169","2.2944","France","FR","FRA","Île-de-France","","30950","1250358877"
"Urmston","Urmston","53.4487","-2.3747","United Kingdom","GB","GBR","Trafford","","41825","1826636761"
"Madīnat ‘Īsá","Madinat \`Isa","26.1736","50.5478","Bahrain","BH","BHR","Al Janūbīyah","","40000","1048219300"
"Villa de Zaachila","Villa de Zaachila","16.9508","-96.7492","Mexico","MX","MEX","Oaxaca","minor","43279","1484472329"
"Inuma","Inuma","36.0001","139.6242","Japan","JP","JPN","Saitama","","45065","1392163993"
"Álimos","Alimos","37.9167","23.7167","Greece","GR","GRC","Attikí","minor","41720","1300088630"
"Faya","Faya","17.9169","19.1167","Chad","TD","TCD","Borkou","admin","48090","1148095016"
"Havant","Havant","50.8517","-0.9842","United Kingdom","GB","GBR","Hampshire","","45826","1826912407"
"Kanye","Kanye","-24.9833","25.3500","Botswana","BW","BWA","Southern","admin","48028","1072506645"
"Torre Annunziata","Torre Annunziata","40.7569","14.4444","Italy","IT","ITA","Campania","","42406","1380017105"
"Chalon-sur-Saône","Chalon-sur-Saone","46.7806","4.8528","France","FR","FRA","Bourgogne-Franche-Comté","minor","45056","1250630825"
"Leribe","Leribe","-28.8734","28.0416","Lesotho","LS","LSO","Leribe","admin","47675","1426721246"
"Hinckley","Hinckley","52.5413","-1.3725","United Kingdom","GB","GBR","Leicestershire","","45249","1826014873"
"Njombe","Njombe","-9.3333","34.7667","Tanzania","TZ","TZA","Njombe","admin","46724","1834328362"
"La Mirada","La Mirada","33.9025","-118.0093","United States","US","USA","California","","47957","1840020512"
"Ripollet","Ripollet","41.4969","2.1574","Spain","ES","ESP","Catalonia","","38347","1724013514"
"Kanash","Kanash","55.5069","47.4914","Russia","RU","RUS","Chuvashiya","","45482","1643340791"
"Melito di Napoli","Melito di Napoli","40.9167","14.2333","Italy","IT","ITA","Campania","","37943","1380717035"
"Chalungalpādam","Chalungalpadam","9.6896","76.3388","India","IN","IND","Kerala","","45102","1356162984"
"Mamburao","Mamburao","13.2233","120.5960","Philippines","PH","PHL","Occidental Mindoro","admin","47705","1608912012"
"Ksar","Ksar","18.1022","-15.9550","Mauritania","MR","MRT","Nouakchott Ouest","","43531","1478489289"
"Worksop","Worksop","53.3042","-1.1244","United Kingdom","GB","GBR","Nottinghamshire","","44733","1826731609"
"Morley","Morley","53.7492","-1.6023","United Kingdom","GB","GBR","Leeds","","44440","1826138121"
"Invercargill","Invercargill","-46.4131","168.3475","New Zealand","NZ","NZL","Southland","admin","47625","1554148942"
"Welling","Welling","51.4594","0.1097","United Kingdom","GB","GBR","Bexley","","41000","1826992580"
"Al ‘Ayyāţ","Al \`Ayyat","29.6167","31.2500","Egypt","EG","EGY","Al Jīzah","","44171","1818970006"
"Kerkrade","Kerkrade","50.8667","6.0667","Netherlands","NL","NLD","Limburg","minor","45642","1528289295"
"Ząbki","Zabki","52.2928","21.1161","Poland","PL","POL","Mazowieckie","","43740","1616088121"
"Karmiel","Karmiel","32.9136","35.2961","Israel","IL","ISR","Northern","","45300","1376615005"
"New Amsterdam","New Amsterdam","6.2500","-57.5167","Guyana","GY","GUY","East Berbice-Corentyne","admin","46874","1328195392"
"Ḩajjah","Hajjah","15.6950","43.5975","Yemen","YE","YEM","Ḩajjah","admin","46568","1887522445"
"Charenton-le-Pont","Charenton-le-Pont","48.8265","2.4050","France","FR","FRA","Île-de-France","","30053","1250389807"
"Kokkola","Kokkola","63.8367","23.1333","Finland","FI","FIN","Keski-Pohjanmaa","admin","47570","1246041998"
"Tepalcatepec","Tepalcatepec","19.1833","-102.8500","Mexico","MX","MEX","Michoacán","minor","42879","1484817788"
"Inđija","Indija","45.0500","20.0833","Serbia","RS","SRB","Inđija","admin","47433","1688349740"
"Puerto Lempira","Puerto Lempira","15.2653","-83.7744","Honduras","HN","HND","Gracias a Dios","admin","47528","1340110055"
"Hammam-Lif","Hammam-Lif","36.7333","10.3333","Tunisia","TN","TUN","Ben Arous","","42518","1788380846"
"Caluire-et-Cuire","Caluire-et-Cuire","45.7953","4.8472","France","FR","FRA","Auvergne-Rhône-Alpes","","43294","1250073067"
"Bang Kruai","Bang Kruai","13.8042","100.4755","Thailand","TH","THA","Nonthaburi","minor","42390","1764357378"
"Dock Sur","Dock Sur","-34.6417","-58.3478","Argentina","AR","ARG","Buenos Aires","","43168","1032579921"
"Le Cannet","Le Cannet","43.5769","7.0194","France","FR","FRA","Provence-Alpes-Côte d’Azur","","41887","1250292202"
"Chitré","Chitre","7.9667","-80.4333","Panama","PA","PAN","Herrera","admin","46191","1591257375"
"Badulla","Badulla","6.9847","81.0564","Sri Lanka","LK","LKA","Uva","admin","42923","1144962750"
"Nagykanizsa","Nagykanizsa","46.4550","16.9925","Hungary","HU","HUN","Nagykanizsa","admin","46866","1348509592"
"Naj‘ Ḩammādī","Naj\` Hammadi","26.0500","32.2500","Egypt","EG","EGY","Qinā","","45038","1818262231"
"Cuilapa","Cuilapa","14.2783","-90.2992","Guatemala","GT","GTM","Santa Rosa","admin","46974","1320810416"
"San Adrián de Besós","San Adrian de Besos","41.4305","2.2183","Spain","ES","ESP","Catalonia","","37283","1724799974"
"Santiago","Santiago","8.1004","-80.9833","Panama","PA","PAN","Veraguas","admin","45955","1591231138"
"Lindi","Lindi","-9.9969","39.7144","Tanzania","TZ","TZA","Lindi","admin","41549","1834550750"
"Al Badārī","Al Badari","26.9925","31.4156","Egypt","EG","EGY","Asyūţ","","42770","1818459800"
"Rāmachandrapuram","Ramachandrapuram","16.8500","82.0200","India","IN","IND","Andhra Pradesh","","43657","1356290874"
"Mrirt","Mrirt","33.1667","-5.5667","Morocco","MA","MAR","Béni Mellal-Khénifra","","42730","1504917091"
"Fleet","Fleet","51.2834","-0.8456","United Kingdom","GB","GBR","Hampshire","","42835","1826039041"
"Middleton","Middleton","53.5550","-2.1870","United Kingdom","GB","GBR","Rochdale","","42972","1826160201"
"Aweil","Aweil","8.7800","27.4000","South Sudan","SS","SSD","Northern Bahr el Ghazal","admin","46705","1728097802"
"Bhārella","Bharella","23.5506","91.0566","Bangladesh","BD","BGD","Chattogram","","44656","1050643387"
"Bois-Colombes","Bois-Colombes","48.9175","2.2683","France","FR","FRA","Île-de-France","","28841","1250698978"
"Murshidābād","Murshidabad","24.1800","88.2700","India","IN","IND","West Bengal","","44019","1356366405"
"Nelson","Nelson","-41.2708","173.2839","New Zealand","NZ","NZL","Nelson","admin","46437","1554017097"
"Dori","Dori","14.0300","-0.0300","Burkina Faso","BF","BFA","Sahel","admin","46521","1854096946"
"Valenciennes","Valenciennes","50.3581","3.5233","France","FR","FRA","Hauts-de-France","minor","43405","1250025032"
"Tōgō","Togo","35.0966","137.0525","Japan","JP","JPN","Aichi","","44065","1392003115"
"Yuchengcun","Yuchengcun","23.5633","116.2691","China","CN","CHN","Guangdong","","43983","1156756753"
"Artigas","Artigas","-30.4667","-56.4667","Uruguay","UY","URY","Artigas","admin","40658","1858646839"
"Coatbridge","Coatbridge","55.8625","-4.0266","United Kingdom","GB","GBR","North Lanarkshire","","43960","1826462023"
"Šibenik","Sibenik","43.7350","15.8906","Croatia","HR","HRV","Šibensko-Kninska Županija","admin","46332","1191444936"
"Daijiazhuang","Daijiazhuang","38.1345","114.3906","China","CN","CHN","Hebei","","44111","1156097347"
"Cobija","Cobija","-11.0333","-68.7333","Bolivia","BO","BOL","Pando","admin","46267","1068395023"
"Bron","Bron","45.7394","4.9139","France","FR","FRA","Auvergne-Rhône-Alpes","","42244","1250002616"
"Chorley","Chorley","53.6530","-2.6320","United Kingdom","GB","GBR","Lancashire","","38420","1826477913"
"Hammam Sousse","Hammam Sousse","35.8589","10.5939","Tunisia","TN","TUN","Sousse","","42691","1788113180"
"Farim","Farim","12.4833","-15.2167","Guinea-Bissau","GW","GNB","Oio","admin","46268","1624753622"
"Ben Zakkay","Ben Zakkay","31.8558","34.73","Israel","IL","ISR","Central","","42314","1376946236"
"Vanves","Vanves","48.8208","2.2897","France","FR","FRA","Île-de-France","","27846","1250321790"
"Slobozia","Slobozia","44.5639","27.3661","Romania","RO","ROU","Ialomiţa","admin","45891","1642971618"
"Mafamude","Mafamude","41.1152","-8.6036","Portugal","PT","PRT","Porto","","38940","1620550022"
"Kumatori","Kumatori","34.4014","135.3558","Japan","JP","JPN","Ōsaka","","43570","1392003543"
"Santa María Atzompa","Santa Maria Atzompa","17.0794","-96.7869","Mexico","MX","MEX","Oaxaca","","41921","1484411990"
"Vilvoorde","Vilvoorde","50.9281","4.4245","Belgium","BE","BEL","Flanders","minor","44015","1056838834"
"Donggangli","Donggangli","39.9733","119.6406","China","CN","CHN","Hebei","","24605","1156087114"
"Arlington","Arlington","42.4187","-71.1639","United States","US","USA","Massachusetts","","46045","1840053631"
"Rezé","Reze","47.1917","-1.5694","France","FR","FRA","Pays de la Loire","","42919","1250886805"
"Kafr al Kurdī","Kafr al Kurdi","31.1429","31.7826","Egypt","EG","EGY","Ad Daqahlīyah","","42651","1818217629"
"Fareham","Fareham","50.8500","-1.1800","United Kingdom","GB","GBR","Hampshire","","42210","1826345366"
"Melun","Melun","48.5406","2.6600","France","FR","FRA","Île-de-France","minor","40844","1250913458"
"Rozzano","Rozzano","45.3833","9.1500","Italy","IT","ITA","Lombardy","","42442","1380496948"
"Igualada","Igualada","41.5814","1.6208","Spain","ES","ESP","Catalonia","","40875","1724444786"
"Gbarnga","Gbarnga","6.9980","-9.4730","Liberia","LR","LBR","Bong","admin","45835","1430269323"
"Jerada","Jerada","34.3117","-2.1636","Morocco","MA","MAR","Oriental","","43506","1504230609"
"Alexandria","Alexandria","43.9686","25.3333","Romania","RO","ROU","Teleorman","admin","45434","1642953190"
"Diamond Harbour","Diamond Harbour","22.1910","88.1905","India","IN","IND","West Bengal","","41802","1356247896"
"Jáltipan de Morelos","Jaltipan de Morelos","17.9703","-94.7144","Mexico","MX","MEX","Veracruz","minor","41644","1484054405"
"Palayan City","Palayan City","15.5422","121.0844","Philippines","PH","PHL","Nueva Ecija","admin","45383","1608192136"
"Castleford","Castleford","53.7160","-1.3560","United Kingdom","GB","GBR","Wakefield","","40210","1826827675"
"Claypole","Claypole","-34.8000","-58.3333","Argentina","AR","ARG","Buenos Aires","","41176","1032211279"
"Thun","Thun","46.7667","7.6333","Switzerland","CH","CHE","Bern","minor","43743","1756018756"
"Stains","Stains","48.9500","2.3833","France","FR","FRA","Île-de-France","","38666","1250114874"
"Baclaran","Baclaran","14.5319","120.9942","Philippines","PH","PHL","Parañaque","","33850","1608042950"
"Hackensack","Hackensack","40.8891","-74.0462","United States","US","USA","New Jersey","","45723","1840003556"
"Yambio","Yambio","4.5650","28.3750","South Sudan","SS","SSD","Western Equatoria","admin","45685","1728463035"
"Kingston upon Thames","Kingston upon Thames","51.4103","-0.2995","United Kingdom","GB","GBR","Kingston upon Thames","","43013","1826345299"
"Berchem","Berchem","51.1833","4.4167","Belgium","BE","BEL","Flanders","","43180","1056254285"
"Arendal","Arendal","58.4608","8.7664","Norway","NO","NOR","Agder","admin","45509","1578533046"
"Pinneberg","Pinneberg","53.6333","9.8000","Germany","DE","DEU","Schleswig-Holstein","minor","43603","1276865707"
"Nueva Gerona","Nueva Gerona","21.8847","-82.8011","Cuba","CU","CUB","Isla de la Juventud","admin","45577","1192344354"
"Tindouf","Tindouf","27.6753","-8.1286","Algeria","DZ","DZA","Tindouf","admin","45610","1012450434"
"Sykiés","Sykies","40.6500","22.9500","Greece","GR","GRC","Kentrikí Makedonía","minor","37753","1300810764"
"Bluefields","Bluefields","12.0139","-83.7644","Nicaragua","NI","NIC","Costa Caribe Sur","admin","45547","1558301368"
"Tarbes","Tarbes","43.2300","0.0700","France","FR","FRA","Occitanie","minor","42758","1250001722"
"Bellinzona","Bellinzona","46.1954","9.0297","Switzerland","CH","CHE","Ticino","","43220","1756130026"
"Pattani","Pattani","6.8664","101.2508","Thailand","TH","THA","Pattani","admin","44234","1764277891"
"Gagny","Gagny","48.8833","2.5333","France","FR","FRA","Île-de-France","","39661","1250000251"
"Lecherías","Lecherias","10.1889","-64.6951","Venezuela","VE","VEN","Anzoátegui","minor","36450","1862961933"
"Boulogne-sur-Mer","Boulogne-sur-Mer","50.7264","1.6147","France","FR","FRA","Hauts-de-France","minor","40664","1250693185"
"Trelleborg","Trelleborg","55.3667","13.1667","Sweden","SE","SWE","Skåne","minor","43359","1752823325"
"Actopan","Actopan","19.5036","-96.6192","Mexico","MX","MEX","Veracruz","minor","43388","1484594634"
"Dhāka","Dhaka","26.7200","85.3300","India","IN","IND","Bihār","","43074","1356006454"
"Bắc Kạn","Bac Kan","22.1333","105.8333","Vietnam","VN","VNM","Bắc Kạn","admin","45036","1704000217"
"Alcantarilla","Alcantarilla","37.9722","-1.2094","Spain","ES","ESP","Murcia","","42630","1724229842"
"Heroica Ciudad de Tlaxiaco","Heroica Ciudad de Tlaxiaco","17.2077","-97.6798","Mexico","MX","MEX","Oaxaca","minor","40123","1484201826"
"Arras","Arras","50.2920","2.7800","France","FR","FRA","Hauts-de-France","minor","41694","1250447540"
"Cleveland Heights","Cleveland Heights","41.5113","-81.5636","United States","US","USA","Ohio","","45267","1840000597"
"Newbury","Newbury","51.4010","-1.3230","United Kingdom","GB","GBR","West Berkshire","","41075","1826103829"
"Coyotepec","Coyotepec","19.7756","-99.2056","Mexico","MX","MEX","Morelos","minor","41810","1484352296"
"Inongo","Inongo","-1.9500","18.2667","Congo (Kinshasa)","CD","COD","Mai-Ndombe","admin","45159","1180194164"
"Bridgwater","Bridgwater","51.1280","-2.9930","United Kingdom","GB","GBR","Somerset","","41276","1826346861"
"Hoboken","Hoboken","51.1667","4.3667","Belgium","BE","BEL","Flanders","","41220","1056996414"
"Ādwa","Adwa","14.1667","38.9000","Ethiopia","ET","ETH","Tigray","","40500","1231086312"
"Teoloyucan","Teoloyucan","19.7442","-99.1811","Mexico","MX","MEX","México","minor","41333","1484003827"
"Concepción","Concepcion","-23.4000","-57.4300","Paraguay","PY","PRY","Concepción","admin","45068","1600557615"
"Whitney","Whitney","36.1008","-115.0379","United States","US","USA","Nevada","","45105","1840033834"
"Pinyahan","Pinyahan","14.6400","121.0461","Philippines","PH","PHL","Quezon","","28129","1608055244"
"Varkkallai","Varkkallai","8.7340","76.7256","India","IN","IND","Kerala","","42273","1356117903"
"Port Loko","Port Loko","8.7667","-12.7875","Sierra Leone","SL","SLE","North Western","admin","44900","1694778676"
"Dunaújváros","Dunaujvaros","46.9806","18.9127","Hungary","HU","HUN","Dunaújváros","admin","44200","1348928583"
"Wan Tau Tong","Wan Tau Tong","22.4423","114.1670","Hong Kong","HK","HKG","","","10860","1344475815"
"Nogent-sur-Marne","Nogent-sur-Marne","48.8375","2.4833","France","FR","FRA","Île-de-France","minor","34042","1250793808"
"Razgrad","Razgrad","43.5333","26.5167","Bulgaria","BG","BGR","Razgrad","admin","44894","1100143131"
"Desio","Desio","45.6167","9.2167","Italy","IT","ITA","Lombardy","","42079","1380553893"
"Salvatierra","Salvatierra","20.2156","-100.8961","Mexico","MX","MEX","Guanajuato","minor","37203","1484878589"
"Zugdidi","Zugdidi","42.5083","41.8667","Georgia","GE","GEO","Samegrelo-Zemo Svaneti","admin","42998","1268300325"
"Mochudi","Mochudi","-24.4167","26.1500","Botswana","BW","BWA","Kgatleng","admin","44815","1072876960"
"Qiryat Bialik","Qiryat Bialik","32.8333","35.0833","Israel","IL","ISR","Haifa","","39900","1376833983"
"El Aïoun","El Aioun","34.5852","-2.5056","Morocco","MA","MAR","Oriental","","41832","1504312724"
"Bakhtiyārpur","Bakhtiyarpur","25.4667","85.5167","India","IN","IND","Bihār","","42626","1356003710"
"North Lauderdale","North Lauderdale","26.2113","-80.2209","United States","US","USA","Florida","","44579","1840015138"
"Boumerdes","Boumerdes","36.7603","3.4724","Algeria","DZ","DZA","Boumerdes","admin","41685","1012627486"
"Tinnanūr","Tinnanur","13.1145","80.0271","India","IN","IND","Tamil Nādu","","38718","1356411606"
"Umm al Qaywayn","Umm al Qaywayn","25.5533","55.5475","United Arab Emirates","AE","ARE","Umm al Qaywayn","admin","44411","1784525230"
"Xonobod","Xonobod","40.8000","73.0000","Uzbekistan","UZ","UZB","Andijon","","42301","1860608604"
"Ágioi Anárgyroi","Agioi Anargyroi","38.0267","23.7183","Greece","GR","GRC","Attikí","minor","34168","1300220121"
"Salem","Salem","42.5129","-70.9021","United States","US","USA","Massachusetts","","44452","1840000410"
"Duncan","Duncan","48.7787","-123.7079","Canada","CA","CAN","British Columbia","","44451","1124316061"
"Iten","Iten","0.6731","35.5083","Kenya","KE","KEN","Elgeyo/Marakwet","admin","42312","1404000102"
"Laindon","Laindon","51.5740","0.4181","United Kingdom","GB","GBR","Essex","","37175","1826564891"
"Rohnert Park","Rohnert Park","38.3479","-122.6965","United States","US","USA","California","","44420","1840020243"
"Palladam","Palladam","10.9900","77.2000","India","IN","IND","Tamil Nādu","","42225","1356233434"
"Kapan","Kapan","39.2011","46.4150","Armenia","AM","ARM","Syunik’","admin","43190","1051527958"
"Paracho de Verduzco","Paracho de Verduzco","19.6500","-102.0667","Mexico","MX","MEX","Michoacán","","37464","1484536308"
"Villaflores","Villaflores","16.2355","-93.2677","Mexico","MX","MEX","Chiapas","minor","37546","1484602239"
"Empalme","Empalme","27.9617","-110.8125","Mexico","MX","MEX","Sonora","minor","38886","1484366250"
"Freeport","Freeport","40.6515","-73.5850","United States","US","USA","New York","","44328","1840005288"
"Katsuren-haebaru","Katsuren-haebaru","26.1911","127.7285","Japan","JP","JPN","Okinawa","","40541","1392007461"
"Caerphilly","Caerphilly","51.5780","-3.2180","United Kingdom","GB","GBR","Caerphilly","","41402","1826322639"
"Llanelli","Llanelli","51.6840","-4.1630","United Kingdom","GB","GBR","Carmarthenshire","","37050","1826934854"
"Baler","Baler","15.7583","121.5625","Philippines","PH","PHL","Aurora","admin","43785","1608495530"
"Trujillo Alto","Trujillo Alto","18.3599","-66.0096","Puerto Rico","PR","PRI","Puerto Rico","","44203","1630023656"
"Ingeniero Pablo Nogués","Ingeniero Pablo Nogues","-34.4667","-58.7667","Argentina","AR","ARG","Buenos Aires","","38470","1032949946"
"Nueva Rosita","Nueva Rosita","27.9390","-101.2180","Mexico","MX","MEX","Coahuila","","39058","1484540557"
"La Paz","La Paz","14.3169","-87.6831","Honduras","HN","HND","La Paz","admin","43980","1340290947"
"Krus na Ligas","Krus na Ligas","14.6442","121.0636","Philippines","PH","PHL","Quezon","","21513","1608179113"
"Desamparados","Desamparados","9.8967","-84.0706","Costa Rica","CR","CRI","San José","","33866","1188280055"
"Oakland Park","Oakland Park","26.1780","-80.1528","United States","US","USA","Florida","","44092","1840015139"
"Kalmar","Kalmar","56.6614","16.3628","Sweden","SE","SWE","Kalmar","admin","41852","1752640618"
"Wilkes-Barre","Wilkes-Barre","41.2469","-75.8760","United States","US","USA","Pennsylvania","","44086","1840000777"
"Sukuta","Sukuta","13.4167","-16.7000","The Gambia","GM","GMB","West Coast","","40675","1270665671"
"Bushenyi","Bushenyi","-0.5417","30.1878","Uganda","UG","UGA","Buhweju","admin","43700","1800740334"
"Caloundra","Caloundra","-26.7986","153.1289","Australia","AU","AUS","Queensland","","41293","1036266942"
"Guruvāyūr","Guruvayur","10.5947","76.0403","India","IN","IND","Kerala","","38138","1356146032"
"Ōizumi","Oizumi","36.2478","139.4049","Japan","JP","JPN","Gunma","","41681","1392003190"
"Temsia","Temsia","30.3600","-9.4140","Morocco","MA","MAR","Souss-Massa","","40780","1504419242"
"Kirkby","Kirkby","53.4800","-2.8900","United Kingdom","GB","GBR","Knowsley","","40472","1826773530"
"Campbell","Campbell","37.2802","-121.9539","United States","US","USA","California","","43954","1840018949"
"Wattrelos","Wattrelos","50.7000","3.2170","France","FR","FRA","Hauts-de-France","","40898","1250832962"
"Annemasse","Annemasse","46.1958","6.2364","France","FR","FRA","Auvergne-Rhône-Alpes","","36582","1250122238"
"Playa Vicente","Playa Vicente","17.8333","-95.8167","Mexico","MX","MEX","Veracruz","minor","39205","1484068720"
"Beverwijk","Beverwijk","52.4833","4.6500","Netherlands","NL","NLD","Noord-Holland","minor","41863","1528896975"
"Matale","Matale","7.4667","80.6167","Sri Lanka","LK","LKA","Central","","36451","1144554963"
"Ermezinde","Ermezinde","41.2170","-8.5500","Portugal","PT","PRT","Porto","","38798","1620357803"
"Buli","Buli","14.4430","121.0510","Philippines","PH","PHL","Muntinlupa","","13341","1608451344"
"Le Kremlin-Bicêtre","Le Kremlin-Bicetre","48.8100","2.3581","France","FR","FRA","Île-de-France","","24971","1250286015"
"Veles","Veles","41.7153","21.7753","Macedonia","MK","MKD","Veles","admin","43716","1807468155"
"Or Yehuda","Or Yehuda","32.0333","34.85","Israel","IL","ISR","Tel Aviv","","36706","1376356429"
"Haedo","Haedo","-34.6500","-58.6000","Argentina","AR","ARG","Buenos Aires","","37745","1032803323"
"San Bruno","San Bruno","37.6256","-122.4313","United States","US","USA","California","","43775","1840021549"
"Gambēla","Gambela","8.2500","34.5833","Ethiopia","ET","ETH","Gambēla Hizboch","admin","39022","1231698892"
"Štip","Stip","41.7358","22.1914","Macedonia","MK","MKD","Štip","admin","43652","1807614061"
"Murzuq","Murzuq","25.9000","13.9000","Libya","LY","LBY","Murzuq","admin","43732","1434910333"
"Drogheda","Drogheda","53.7150","-6.3525","Ireland","IE","IRL","Louth","","40956","1372750233"
"Shefar‘am","Shefar\`am","32.8056","35.1694","Israel","IL","ISR","Northern","","41600","1376191471"
"Cacém","Cacem","38.7704","-9.3081","Portugal","PT","PRT","Lisboa","","21289","1620147105"
"Concord","Concord","43.2305","-71.5595","United States","US","USA","New Hampshire","admin","43552","1840002747"
"Mińsk Mazowiecki","Minsk Mazowiecki","52.1833","21.5667","Poland","PL","POL","Mazowieckie","minor","40467","1616444517"
"Munro","Munro","-34.5333","-58.5167","Argentina","AR","ARG","Buenos Aires","","35844","1032318811"
"Sagauli","Sagauli","26.7833","84.7333","India","IN","IND","Bihār","","41490","1356836241"
"Greenacres","Greenacres","26.6270","-80.1371","United States","US","USA","Florida","","43482","1840036057"
"Koboko","Koboko","3.4100","30.9600","Uganda","UG","UGA","Koboko","admin","37825","1800307801"
"Lai Châu","Lai Chau","22.3992","103.4392","Vietnam","VN","VNM","Lai Châu","admin","42973","1704983526"
"Franconville","Franconville","48.9889","2.2314","France","FR","FRA","Île-de-France","","37394","1250716454"
"Ramsgate","Ramsgate","51.3360","1.4160","United Kingdom","GB","GBR","Kent","","40408","1826278899"
"Porur","Porur","13.0356","80.1582","India","IN","IND","Tamil Nādu","","38179","1356112242"
"Hódmezővásárhely","Hodmezovasarhely","46.4304","20.3188","Hungary","HU","HUN","Hódmezővásárhely","admin","43311","1348676241"
"Small Heath","Small Heath","52.4629","-1.8542","United Kingdom","GB","GBR","Birmingham","","36898","1826945156"
"Kampong Chhnang","Kampong Chhnang","12.2500","104.6667","Cambodia","KH","KHM","Kampong Chhnang","admin","41700","1116735270"
"North Miami Beach","North Miami Beach","25.9302","-80.1659","United States","US","USA","Florida","","43357","1840015155"
"Pallisa","Pallisa","1.1675","33.7100","Uganda","UG","UGA","Pallisa","admin","42300","1800214906"
"Uman","Uman","20.8833","-89.7500","Mexico","MX","MEX","Yucatán","minor","39611","1484009408"
"Silistra","Silistra","44.1172","27.2606","Bulgaria","BG","BGR","Silistra","admin","43265","1100458181"
"Juan Rodríguez Clara","Juan Rodriguez Clara","18.0000","-95.4000","Mexico","MX","MEX","Veracruz","minor","38895","1484000401"
"Aziylal","Aziylal","31.9669","-6.5694","Morocco","MA","MAR","Béni Mellal-Khénifra","","38520","1504084050"
"Kalutara","Kalutara","6.5869","79.9603","Sri Lanka","LK","LKA","Western","","37081","1144948559"
"Pomigliano d’Arco","Pomigliano d'Arco","40.9167","14.4000","Italy","IT","ITA","Campania","","39819","1380104019"
"Svay Rieng","Svay Rieng","11.0833","105.8000","Cambodia","KH","KHM","Svay Rieng","admin","41424","1116432743"
"Armavir","Armavir","40.1500","44.0400","Armenia","AM","ARM","Armavir","admin","38635","1051878002"
"Kwīhā","Kwiha","13.4769","39.5450","Ethiopia","ET","ETH","Tigray","","12543","1231541994"
"Cleethorpes","Cleethorpes","53.5533","-0.0215","United Kingdom","GB","GBR","North East Lincolnshire","","38996","1826584652"
"Kula","Kula","45.6000","19.5333","Serbia","RS","SRB","Kula","admin","43101","1688477275"
"Minas","Minas","-34.3667","-55.2333","Uruguay","UY","URY","Lavalleja","admin","38446","1858724823"
"Hicksville","Hicksville","40.7637","-73.5245","United States","US","USA","New York","","43107","1840005266"
"Jefferson City","Jefferson City","38.5676","-92.1759","United States","US","USA","Missouri","admin","43076","1840008615"
"Woonsocket","Woonsocket","42.0010","-71.4993","United States","US","USA","Rhode Island","","43044","1840003290"
"Bishops Stortford","Bishops Stortford","51.8720","0.1725","United Kingdom","GB","GBR","Hertfordshire","","38202","1826440705"
"Garbahaarrey","Garbahaarrey","3.3500","42.2667","Somalia","SO","SOM","Gedo","admin","43000","1706488487"
"Balintawak","Balintawak","14.6506","121.0014","Philippines","PH","PHL","Quezon","","19260","1608021022"
"Ban Sai Ma Tai","Ban Sai Ma Tai","13.8444","100.4829","Thailand","TH","THA","Nonthaburi","","30750","1764698508"
"Coalville","Coalville","52.7240","-1.3690","United Kingdom","GB","GBR","Leicestershire","","37661","1826533599"
"Blyth","Blyth","55.1260","-1.5140","United Kingdom","GB","GBR","Northumberland","","37339","1826333350"
"Saronno","Saronno","45.6255","9.0370","Italy","IT","ITA","Lombardy","","39419","1380012817"
"Phulwāria","Phulwaria","25.4697","85.9847","India","IN","IND","Bihār","","40122","1356889067"
"Dubrovnik","Dubrovnik","42.6403","18.1083","Croatia","HR","HRV","Dubrovačko-Neretvanska Županija","admin","42615","1191004286"
"Blanes","Blanes","41.6740","2.7921","Spain","ES","ESP","Catalonia","","40579","1724143581"
"Ciudad Melchor Múzquiz","Ciudad Melchor Muzquiz","27.8775","-101.5164","Mexico","MX","MEX","Coahuila","minor","38992","1484736113"
"Quinhámel","Quinhamel","11.8833","-15.8500","Guinea-Bissau","GW","GNB","Biombo","admin","42659","1624877378"
"Dayr Mawās","Dayr Mawas","27.6333","30.8500","Egypt","EG","EGY","Al Minyā","","40640","1818973684"
"Melmadai","Melmadai","9.9264","78.1503","India","IN","IND","Tamil Nādu","","38409","1356234550"
"Tonalá","Tonala","16.0914","-93.7518","Mexico","MX","MEX","Chiapas","minor","35322","1484944309"
"Temascal","Temascal","18.2394","-96.4031","Mexico","MX","MEX","Oaxaca","","39680","1484505986"
"Guarda","Guarda","40.5333","-7.3333","Portugal","PT","PRT","Guarda","admin","42541","1620174984"
"Si Sa Ket","Si Sa Ket","15.1069","104.3294","Thailand","TH","THA","Si Sa Ket","admin","42405","1764002764"
"Fribourg","Fribourg","46.8000","7.1500","Switzerland","CH","CHE","Fribourg","admin","38365","1756145667"
"Saint-Martin-d’Hères","Saint-Martin-d'Heres","45.1672","5.7653","France","FR","FRA","Auvergne-Rhône-Alpes","","38398","1250862617"
"Kenton","Kenton","51.5878","-0.3086","United Kingdom","GB","GBR","Brent","","35600","1826411445"
"Leighton Buzzard","Leighton Buzzard","51.9165","-0.6617","United Kingdom","GB","GBR","Central Bedfordshire","","37469","1826486102"
"Kampot","Kampot","10.6000","104.1667","Cambodia","KH","KHM","Kampot","admin","39186","1116966757"
"Arnold","Arnold","53.0050","-1.1270","United Kingdom","GB","GBR","Nottinghamshire","","37768","1826647385"
"Miercurea-Ciuc","Miercurea-Ciuc","46.3594","25.8017","Romania","RO","ROU","Harghita","admin","42029","1642603145"
"Berat","Berat","40.7000","19.9500","Albania","AL","ALB","Berat","admin","36496","1008955153"
"Ait Ourir","Ait Ourir","31.5644","-7.6628","Morocco","MA","MAR","Marrakech-Safi","","39108","1504763448"
"Le Perreux-Sur-Marne","Le Perreux-Sur-Marne","48.8422","2.5036","France","FR","FRA","Île-de-France","","33793","1250033970"
"Myrhorod","Myrhorod","49.9667","33.6000","Ukraine","UA","UKR","Poltavska Oblast","minor","40160","1804138671"
"Bambari","Bambari","5.7653","20.6742","Central African Republic","CF","CAF","Ouaka","admin","41356","1140304277"
"Ardahan","Ardahan","41.1111","42.7022","Turkey","TR","TUR","Ardahan","admin","42226","1792379425"
"Zagora","Zagora","30.3306","-5.8381","Morocco","MA","MAR","Drâa-Tafilalet","","40067","1504579638"
"Le Pré-Saint-Gervais","Le Pre-Saint-Gervais","48.8850","2.4039","France","FR","FRA","Île-de-France","","17485","1250549282"
"Qapshaghay","Qapshaghay","43.8844","77.0687","Kazakhstan","KZ","KAZ","","admin","42167","1398594098"
"Nebbi","Nebbi","2.4792","31.0900","Uganda","UG","UGA","Nebbi","admin","41400","1800598019"
"Ilkeston","Ilkeston","52.9710","-1.3090","United Kingdom","GB","GBR","Derbyshire","","38640","1826289943"
"Poissy","Poissy","48.9294","2.0456","France","FR","FRA","Île-de-France","","39187","1250776265"
"Żyrardów","Zyrardow","52.0500","20.4333","Poland","PL","POL","Mazowieckie","minor","39374","1616145588"
"Nakama","Nakama","33.8208","130.7083","Japan","JP","JPN","Fukuoka","","39634","1392968664"
"San José","San Jose","-34.3333","-56.7167","Uruguay","UY","URY","San José","admin","36747","1858927597"
"Villafranca del Panadés","Villafranca del Panades","41.3447","1.6994","Spain","ES","ESP","Catalonia","","40056","1724718493"
"Matoupu","Matoupu","38.3198","114.7207","China","CN","CHN","Hebei","","40010","1156275236"
"Aberdare","Aberdare","51.7130","-3.4450","United Kingdom","GB","GBR","Rhondda Cynon Taff","","39550","1826427395"
"Viborg","Viborg","56.4333","9.4000","Denmark","DK","DNK","Midtjylland","admin","41239","1208606810"
"Xico","Xico","19.4170","-97.0000","Mexico","MX","MEX","Veracruz","minor","38198","1484011085"
"Lālganj","Lalganj","25.8700","85.1800","India","IN","IND","Bihār","","39432","1356027316"
"Kahama","Kahama","-3.8375","32.6000","Tanzania","TZ","TZA","Shinyanga","","36014","1834043034"
"Cesano Maderno","Cesano Maderno","45.6307","9.1456","Italy","IT","ITA","Lombardy","","38637","1380045359"
"Cachan","Cachan","48.7919","2.3319","France","FR","FRA","Île-de-France","","30884","1250259069"
"Madipakkam","Madipakkam","12.9623","80.1986","India","IN","IND","Tamil Nādu","","20523","1356832025"
"Savigny-sur-Orge","Savigny-sur-Orge","48.6797","2.3457","France","FR","FRA","Île-de-France","","36734","1250549576"
"Douai","Douai","50.3714","3.0800","France","FR","FRA","Hauts-de-France","minor","39634","1250026390"
"Herne Bay","Herne Bay","51.3700","1.1300","United Kingdom","GB","GBR","Kent","","38385","1826454069"
"Santa Bárbara","Santa Barbara","14.9167","-88.2333","Honduras","HN","HND","Santa Bárbara","admin","41736","1340312026"
"Annandale","Annandale","38.8324","-77.1960","United States","US","USA","Virginia","","41869","1840006011"
"Placilla de Peñuelas","Placilla de Penuelas","-33.1156","-71.5678","Chile","CL","CHL","Valparaíso","","39344","1152562274"
"Amudālavalasa","Amudalavalasa","18.4167","83.9000","India","IN","IND","Andhra Pradesh","","39799","1356850881"
"Embu","Embu","-0.5389","37.4583","Kenya","KE","KEN","Embu","admin","41092","1404376456"
"Puntarenas","Puntarenas","9.9667","-84.8333","Costa Rica","CR","CRI","Puntarenas","admin","41528","1188639267"
"Biougra","Biougra","30.2144","-9.3708","Morocco","MA","MAR","Souss-Massa","","37933","1504630552"
"Narathiwat","Narathiwat","6.4167","101.8167","Thailand","TH","THA","Narathiwat","admin","41342","1764748532"
"Mugnano di Napoli","Mugnano di Napoli","40.9094","14.2098","Italy","IT","ITA","Campania","","34996","1380872508"
"Échirolles","Echirolles","45.1436","5.7183","France","FR","FRA","Auvergne-Rhône-Alpes","","36961","1250068443"
"Kenge","Kenge","-4.8056","17.0417","Congo (Kinshasa)","CD","COD","Kwango","admin","41612","1180158365"
"Tuvāgudi","Tuvagudi","10.7564","78.8011","India","IN","IND","Tamil Nādu","","38887","1356229997"
"Kānkuria","Kankuria","24.6580","87.9794","India","IN","IND","West Bengal","","36925","1356994427"
"Mambajao","Mambajao","9.2500","124.7167","Philippines","PH","PHL","Camiguin","admin","41094","1608281273"
"Zghartā","Zgharta","34.4000","35.9000","Lebanon","LB","LBN","Liban-Nord","minor","30000","1422751344"
"Ciampino","Ciampino","41.8000","12.6000","Italy","IT","ITA","Lazio","","38645","1380847693"
"Zacatelco","Zacatelco","19.2167","-98.2333","Mexico","MX","MEX","Tlaxcala","minor","38654","1484460521"
"Pujali","Pujali","22.4679","88.1452","India","IN","IND","West Bengal","","37047","1356010831"
"Morshansk","Morshansk","53.4500","41.8167","Russia","RU","RUS","Tambovskaya Oblast’","","39362","1643010235"
"Villa Adelina","Villa Adelina","-34.5175","-58.5475","Argentina","AR","ARG","Buenos Aires","","35307","1032929712"
"Nola","Nola","3.5333","16.0667","Central African Republic","CF","CAF","Sangha-Mbaéré","admin","41462","1140236347"
"Arzano","Arzano","40.9167","14.2667","Italy","IT","ITA","Campania","","34217","1380852442"
"Lusambo","Lusambo","-4.9729","23.4368","Congo (Kinshasa)","CD","COD","Sankuru","admin","41416","1180127290"
"Sayula de Alemán","Sayula de Aleman","17.8833","-94.9500","Mexico","MX","MEX","Veracruz","minor","32721","1484073387"
"Villepinte","Villepinte","48.9550","2.5410","France","FR","FRA","Île-de-France","","37713","1250274181"
"Ngozi","Ngozi","-2.9083","29.8269","Burundi","BI","BDI","Ngozi","admin","39884","1108753930"
"Burjasot","Burjasot","39.5064","-0.4067","Spain","ES","ESP","Valencia","","38880","1724238629"
"Teaneck","Teaneck","40.8900","-74.0107","United States","US","USA","New Jersey","","41335","1840081757"
"Bicester","Bicester","51.9000","-1.1500","United Kingdom","GB","GBR","Oxfordshire","","37020","1826236942"
"Marcq-en-Baroeul","Marcq-en-Baroeul","50.6711","3.0972","France","FR","FRA","Hauts-de-France","","38570","1250045997"
"Kisii","Kisii","-0.6833","34.7667","Kenya","KE","KEN","Kisii","admin","37531","1404617601"
"Chittaranjan","Chittaranjan","23.8700","86.8700","India","IN","IND","West Bengal","","39098","1356842137"
"Rivas","Rivas","11.4386","-85.8286","Nicaragua","NI","NIC","Rivas","admin","41080","1558146594"
"Corsico","Corsico","45.4333","9.1167","Italy","IT","ITA","Lombardy","","34727","1380973282"
"Vredenburg","Vredenburg","-32.9000","17.9833","South Africa","ZA","ZAF","Western Cape","","38382","1710121734"
"Neuilly-sur-Marne","Neuilly-sur-Marne","48.8537","2.5490","France","FR","FRA","Île-de-France","","35907","1250377296"
"Wilrijk","Wilrijk","51.1667","4.3833","Belgium","BE","BEL","Flanders","","38319","1056856588"
"Oshakati","Oshakati","-17.7833","15.6995","Namibia","NA","NAM","Oshana","admin","39679","1516866759"
"Panaji","Panaji","15.4989","73.8278","India","IN","IND","Goa","admin","40017","1356909212"
"Malbork","Malbork","54.0333","19.0333","Poland","PL","POL","Pomorskie","minor","38895","1616964352"
"Calpulalpan","Calpulalpan","19.5869","-98.5683","Mexico","MX","MEX","Hidalgo","minor","37752","1484814346"
"Wood Green","Wood Green","51.5981","-0.1149","United Kingdom","GB","GBR","Haringey","","28453","1826902664"
"Famagusta","Famagusta","35.1250","33.9417","Cyprus","CY","CYP","Ammóchostos","admin","40920","1196128852"
"Romblon","Romblon","12.5500","122.2800","Philippines","PH","PHL","Romblon","admin","40554","1608428749"
"Denton","Denton","53.4554","-2.1122","United Kingdom","GB","GBR","Tameside","","36591","1826948278"
"Puerto Francisco de Orellana","Puerto Francisco de Orellana","-0.4625","-76.9842","Ecuador","EC","ECU","Orellana","admin","40730","1218387446"
"Hallandale Beach","Hallandale Beach","25.9854","-80.1423","United States","US","USA","Florida","","41004","1840014237"
"Zacatlán","Zacatlan","19.9319","-97.9600","Mexico","MX","MEX","Puebla","minor","33736","1484132726"
"Binəqədi","Binaqadi","40.4661","49.8278","Azerbaijan","AZ","AZE","Bakı","","37100","1031506159"
"Nallūr","Nallur","11.1003","77.3914","India","IN","IND","Tamil Nādu","","38933","1356163914"
"Highbury","Highbury","51.5520","-0.0970","United Kingdom","GB","GBR","Islington","","26664","1826640859"
"Walkden","Walkden","53.5239","-2.3991","United Kingdom","GB","GBR","Salford","","35616","1826067450"
"Miyoshidai","Miyoshidai","35.8284","139.5265","Japan","JP","JPN","Saitama","","38380","1392859111"
"Shengli","Shengli","37.9842","106.1967","China","CN","CHN","Ningxia","minor","36937","1156929184"
"Cananea","Cananea","30.9819","-110.3006","Mexico","MX","MEX","Sonora","minor","38113","1484752107"
"Al Bayḑā’","Al Bayda'","13.9790","45.5740","Yemen","YE","YEM","Al Bayḑā’","admin","37821","1887788283"
"Chartres","Chartres","48.4560","1.4840","France","FR","FRA","Centre-Val de Loire","minor","38534","1250325561"
"Pärnu","Parnu","58.3833","24.5000","Estonia","EE","EST","Pärnu","admin","39605","1233955054"
"Şəmkir","Samkir","40.8297","46.0189","Azerbaijan","AZ","AZE","Şəmkir","admin","40600","1031776445"
"Whitley Bay","Whitley Bay","55.0456","-1.4443","United Kingdom","GB","GBR","North Tyneside","","36623","1826742465"
"Koekelberg","Koekelberg","50.8667","4.3333","Belgium","BE","BEL","Brussels-Capital Region","","21959","1056006015"
"Ez Zahra","Ez Zahra","36.7439","10.3083","Tunisia","TN","TUN","Ben Arous","","34962","1788800357"
"Bletchley","Bletchley","51.9940","-0.7320","United Kingdom","GB","GBR","Milton Keynes","","37520","1826878705"
"Impfondo","Impfondo","1.6333","18.0667","Congo (Brazzaville)","CG","COG","Likouala","admin","40654","1178021926"
"Paravūrkambolam","Paravurkambolam","8.8168","76.6719","India","IN","IND","Kerala","","38649","1356164027"
"Culver City","Culver City","34.0058","-118.3968","United States","US","USA","California","","40640","1840019243"
"Talas","Talas","42.5167","72.2333","Kyrgyzstan","KG","KGZ","Talas","admin","40308","1417698287"
"Ban Na Pa","Ban Na Pa","13.3956","101.0232","Thailand","TH","THA","Chon Buri","","38548","1764865021"
"Dún Dealgan","Dun Dealgan","54.0090","-6.4049","Ireland","IE","IRL","Louth","admin","39004","1372735852"
"Annapolis","Annapolis","38.9706","-76.5047","United States","US","USA","Maryland","admin","40581","1840005928"
"Billingham","Billingham","54.6100","-1.2700","United Kingdom","GB","GBR","Stockton-on-Tees","","35165","1826138343"
"Mariano Escobedo","Mariano Escobedo","18.9167","-97.1333","Mexico","MX","MEX","Veracruz","minor","37285","1484215926"
"Lovech","Lovech","43.1347","24.7172","Bulgaria","BG","BGR","Lovech","admin","40516","1100374163"
"Montclair","Montclair","40.8253","-74.2111","United States","US","USA","New Jersey","","40520","1840131597"
"Airdrie","Airdrie","55.8600","-3.9800","United Kingdom","GB","GBR","North Lanarkshire","","37410","1826889162"
"Bjelovar","Bjelovar","45.9000","16.8333","Croatia","HR","HRV","Bjelovarsko-Bilogorska Županija","admin","40276","1191340640"
"Sensuntepeque","Sensuntepeque","13.8667","-88.6333","El Salvador","SV","SLV","Cabañas","admin","40332","1222098918"
"Grugliasco","Grugliasco","45.0680","7.5776","Italy","IT","ITA","Piedmont","","37592","1380580243"
"Beeston","Beeston","52.9270","-1.2150","United Kingdom","GB","GBR","Nottinghamshire","","37000","1826483601"
"Bouar","Bouar","5.9500","15.6000","Central African Republic","CF","CAF","Nana-Mambéré","admin","40353","1140144321"
"Soccorro","Soccorro","14.6179","121.0558","Philippines","PH","PHL","Quezon","","25073","1608135320"
"Long Eaton","Long Eaton","52.8980","-1.2710","United Kingdom","GB","GBR","Derbyshire","","37760","1826989609"
"Montana","Montana","43.4075","23.2217","Bulgaria","BG","BGR","Montana","admin","40295","1100946651"
"Camberley","Camberley","51.3350","-0.7420","United Kingdom","GB","GBR","Surrey","","38038","1826909512"
"Valley Stream","Valley Stream","40.6647","-73.7043","United States","US","USA","New York","","40304","1840005331"
"Agualva","Agualva","38.7700","-9.2988","Portugal","PT","PRT","Lisboa","","35824","1620936507"
"L’Haÿ-les-Roses","L'Hay-les-Roses","48.7800","2.3374","France","FR","FRA","Île-de-France","minor","32071","1250707197"
"Sidi Yahia El Gharb","Sidi Yahia El Gharb","34.3058","-6.3058","Morocco","MA","MAR","Rabat-Salé-Kénitra","","37979","1504994850"
"Dunstable","Dunstable","51.8860","-0.5210","United Kingdom","GB","GBR","Central Bedfordshire","","35000","1826601477"
"Houilles","Houilles","48.9261","2.1892","France","FR","FRA","Île-de-France","","32801","1250321161"
"El Golea","El Golea","30.6000","2.9000","Algeria","DZ","DZA","El Meniaa","admin","40195","1012834851"
"Luebo","Luebo","-5.3500","21.4167","Congo (Kinshasa)","CD","COD","Kasaï","admin","40115","1180012250"
"Swords","Swords","53.4597","-6.2181","Ireland","IE","IRL","Fingal","admin","36924","1372755688"
"Port-Margot","Port-Margot","19.7500","-72.4333","Haiti","HT","HTI","Nord","","36937","1332999568"
"Villefranche-sur-Saône","Villefranche-sur-Saone","45.9833","4.7167","France","FR","FRA","Auvergne-Rhône-Alpes","minor","36291","1250969422"
"Kanie","Kanie","35.1322","136.7868","Japan","JP","JPN","Aichi","","36782","1392146454"
"Chachoengsao","Chachoengsao","13.6903","101.0703","Thailand","TH","THA","Chachoengsao","admin","39570","1764632382"
"Phônsavan","Phonsavan","19.4600","103.1800","Laos","LA","LAO","Xiangkhouang","admin","37507","1418378289"
"Xaçmaz","Xacmaz","41.4708","48.8097","Azerbaijan","AZ","AZE","Xaçmaz","admin","39900","1031335433"
"Chelsea","Chelsea","42.3959","-71.0325","United States","US","USA","Massachusetts","","40025","1840000456"
"North Shields","North Shields","55.0097","-1.4448","United Kingdom","GB","GBR","North Tyneside","","34423","1826426225"
"Brčko","Brcko","44.8783","18.8092","Bosnia and Herzegovina","BA","BIH","Brcko District","admin","39893","1070370826"
"Tumba","Tumba","59.2000","17.8167","Sweden","SE","SWE","Stockholm","minor","37852","1752693397"
"Bugiri","Bugiri","0.5694","33.7486","Uganda","UG","UGA","Bugiri","admin","36000","1800917331"
"Waipahu","Waipahu","21.3859","-158.0101","United States","US","USA","Hawaii","","39927","1840023258"
"Pierrefitte-sur-Seine","Pierrefitte-sur-Seine","48.9656","2.3614","France","FR","FRA","Île-de-France","","30861","1250320498"
"Samrong","Samrong","13.6421","100.6039","Thailand","TH","THA","Samut Prakan","","30382","1764003521"
"Koumra","Koumra","8.9100","17.5500","Chad","TD","TCD","Mandoul","admin","39852","1148599298"
"Surin","Surin","14.8864","103.4932","Thailand","TH","THA","Surin","admin","39728","1764813179"
"Sainte-Geneviève-des-Bois","Sainte-Genevieve-des-Bois","48.6369","2.3403","France","FR","FRA","Île-de-France","","36015","1250428869"
"Jordan","Jordan","10.6000","122.6000","Philippines","PH","PHL","Guimaras","admin","39566","1608769914"
"Bell Gardens","Bell Gardens","33.9663","-118.1550","United States","US","USA","California","","39870","1840019229"
"Bentota","Bentota","6.4200","80.0000","Sri Lanka","LK","LKA","Southern","","37000","1144048882"
"Massawa","Massawa","15.6097","39.4500","Eritrea","ER","ERI","Semienawi K’eyyĭḥ Baḥri","admin","39758","1232344222"
"Athis-Mons","Athis-Mons","48.7074","2.3889","France","FR","FRA","Île-de-France","","35670","1250955386"
"San Roque","San Roque","14.4800","120.9011","Philippines","PH","PHL","Cavite","","19335","1608320897"
"Nueva Italia de Ruiz","Nueva Italia de Ruiz","19.0194","-102.1089","Mexico","MX","MEX","Michoacán","","32467","1484876293"
"Effia-Kuma","Effia-Kuma","4.9167","-1.7500","Ghana","GH","GHA","Western","","35094","1288001558"
"Santa Elena","Santa Elena","-2.2267","-80.8583","Ecuador","EC","ECU","Santa Elena","admin","39681","1218296446"
"Lincoln Park","Lincoln Park","42.2432","-83.1811","United States","US","USA","Michigan","","39741","1840003984"
"Pioltello","Pioltello","45.5000","9.3333","Italy","IT","ITA","Lombardy","","36920","1380525862"
"Aventura","Aventura","25.9566","-80.1371","United States","US","USA","Florida","","39734","1840014243"
"Fort Lee","Fort Lee","40.8509","-73.9713","United States","US","USA","New Jersey","","39701","1840003552"
"Ālamat’ā","Alamat'a","12.4167","39.5500","Ethiopia","ET","ETH","Tigray","","33214","1231954520"
"San Gabriel","San Gabriel","34.0949","-118.0990","United States","US","USA","California","","39673","1840021862"
"Vernier","Vernier","46.2000","6.1000","Switzerland","CH","CHE","Genève","","35132","1756335917"
"Les Lilas","Les Lilas","48.8800","2.4200","France","FR","FRA","Île-de-France","","23447","1250804499"
"Sant’Antimo","Sant'Antimo","40.9422","14.2348","Italy","IT","ITA","Campania","","33892","1380853307"
"Wickford","Wickford","51.6114","0.5207","United Kingdom","GB","GBR","Essex","","33486","1826313893"
"Āzezo","Azezo","12.5586","37.4308","Ethiopia","ET","ETH","Āmara","","33719","1231317573"
"San Juan Despí","San Juan Despi","41.3668","2.0570","Spain","ES","ESP","Catalonia","","34130","1724606295"
"Touggourt","Touggourt","33.1000","6.0667","Algeria","DZ","DZA","Touggourt","admin","39409","1012546670"
"Kālihāti","Kalihati","24.3833","89.9833","Bangladesh","BD","BGD","Rājshāhi","","37038","1050684659"
"Dáfni","Dafni","37.9500","23.7333","Greece","GR","GRC","Attikí","minor","22913","1300695604"
"Miahuatlán de Porfirio Díaz","Miahuatlan de Porfirio Diaz","16.3327","-96.5956","Mexico","MX","MEX","Oaxaca","minor","35517","1484776246"
"Park Ridge","Park Ridge","42.0125","-87.8436","United States","US","USA","Illinois","","39562","1840009183"
"Florida","Florida","-34.1000","-56.2167","Uruguay","UY","URY","Florida","admin","33639","1858077099"
"Paravūr Tekkumbhāgam","Paravur Tekkumbhagam","8.8110","76.6690","India","IN","IND","Kerala","","37245","1356176530"
"Celje","Celje","46.2358","15.2675","Slovenia","SI","SVN","Celje","admin","37872","1705563326"
"Cabaret","Cabaret","18.7343","-72.4159","Haiti","HT","HTI","Ouest","","36120","1332584199"
"Sareh Mowndeh","Sareh Mowndeh","13.4000","-16.6833","The Gambia","GM","GMB","West Coast","","36134","1270781020"
"Châtenay-Malabry","Chatenay-Malabry","48.7653","2.2781","France","FR","FRA","Île-de-France","","34170","1250512323"
"Redcar","Redcar","54.6180","-1.0690","United Kingdom","GB","GBR","Redcar and Cleveland","","37073","1826929580"
"Kaffrine","Kaffrine","14.1014","-15.5432","Senegal","SN","SEN","Kaffrine","admin","39357","1686807341"
"Pánuco","Panuco","22.0500","-98.1833","Mexico","MX","MEX","Veracruz","minor","33122","1484059135"
"Creil","Creil","49.2583","2.4833","France","FR","FRA","Hauts-de-France","","36169","1250367571"
"Lydenburg","Lydenburg","-25.0960","30.4460","South Africa","ZA","ZAF","Mpumalanga","","37258","1710237640"
"Northolt","Northolt","51.5467","-0.3700","United Kingdom","GB","GBR","Ealing","","30304","1826456422"
"Herohalli","Herohalli","12.9911","77.4873","India","IN","IND","Karnātaka","","23851","1356020701"
"Bridlington","Bridlington","54.0819","-0.1923","United Kingdom","GB","GBR","East Riding of Yorkshire","","35264","1826640623"
"Olteniţa","Oltenita","44.0867","26.6367","Romania","RO","ROU","Călăraşi","","24822","1642326202"
"Hanwell","Hanwell","51.5090","-0.3380","United Kingdom","GB","GBR","Ealing","","28768","1826742619"
"Istog","Istog","42.7833","20.4833","Kosovo","XK","XKS","Istog","admin","39289","1901248770"
"Bezons","Bezons","48.9261","2.2178","France","FR","FRA","Île-de-France","","31671","1250139521"
"Taibao","Taibao","23.4500","120.3333","Taiwan","TW","TWN","Chiayi","admin","38696","1158657404"
"Premiá de Mar","Premia de Mar","41.4920","2.3620","Spain","ES","ESP","Catalonia","","28523","1724296865"
"Bollate","Bollate","45.5500","9.1167","Italy","IT","ITA","Lombardy","","36486","1380424461"
"Conflans-Sainte-Honorine","Conflans-Sainte-Honorine","48.9992","2.0983","France","FR","FRA","Île-de-France","","35656","1250363572"
"Durazno","Durazno","-33.3667","-56.5167","Uruguay","UY","URY","Durazno","admin","34368","1858006236"
"El Hajeb","El Hajeb","33.6928","-5.3711","Morocco","MA","MAR","Fès-Meknès","","35282","1504718907"
"Ban Doi Suthep","Ban Doi Suthep","18.7944","98.9142","Thailand","TH","THA","Chiang Mai","","36952","1764508260"
"Varaždin","Varazdin","46.3081","16.3378","Croatia","HR","HRV","Varaždinska Županija","admin","38839","1191291148"
"Keizer","Keizer","45.0028","-123.0243","United States","US","USA","Oregon","","39119","1840018581"
"Falkirk","Falkirk","56.0011","-3.7835","United Kingdom","GB","GBR","Falkirk","","35850","1826502864"
"Bugembe","Bugembe","0.4675","33.2414","Uganda","UG","UGA","Jinja","","33100","1800191347"
"Fāraskūr","Faraskur","31.3297","31.7147","Egypt","EG","EGY","Dumyāţ","","36850","1818275538"
"Tromsø","Tromso","69.6828","18.9428","Norway","NO","NOR","Troms og Finnmark","admin","38980","1578188822"
"Zinacantán","Zinacantan","16.7601","-92.7236","Mexico","MX","MEX","Chiapas","minor","36489","1484308293"
"Nyköping","Nykoping","58.7531","17.0086","Sweden","SE","SWE","Södermanland","admin","38780","1752377083"
"Abasolo","Abasolo","20.4511","-101.5289","Mexico","MX","MEX","Guanajuato","minor","29002","1484064616"
"Villeneuve-Saint-Georges","Villeneuve-Saint-Georges","48.7325","2.4497","France","FR","FRA","Île-de-France","","34890","1250985976"
"Ayutuxtepeque","Ayutuxtepeque","13.7356","-89.2017","El Salvador","SV","SLV","San Salvador","","34710","1222570043"
"Romainville","Romainville","48.8840","2.4350","France","FR","FRA","Île-de-France","","30087","1250311855"
"Le Plessis-Robinson","Le Plessis-Robinson","48.7811","2.2633","France","FR","FRA","Île-de-France","","30061","1250572876"
"Zaïo","Zaio","34.9333","-2.7333","Morocco","MA","MAR","Oriental","","35806","1504831853"
"Lambaréné","Lambarene","-0.6883","10.2319","Gabon","GA","GAB","Moyen-Ogooué","admin","38775","1266875472"
"Mission Bend","Mission Bend","29.6948","-95.6658","United States","US","USA","Texas","","38772","1840019643"
"Esch-sur-Alzette","Esch-sur-Alzette","49.4969","5.9806","Luxembourg","LU","LUX","Esch-sur-Alzette","admin","36228","1442192663"
"Tuyên Quang","Tuyen Quang","21.8167","105.2167","Vietnam","VN","VNM","Tuyên Quang","admin","36430","1704662313"
"Vrilíssia","Vrilissia","38.0333","23.8333","Greece","GR","GRC","Attikí","minor","30741","1300600750"
"Cholargós","Cholargos","38.0000","23.8000","Greece","GR","GRC","Attikí","minor","30840","1300207995"
"Adjumani","Adjumani","3.3772","31.7906","Uganda","UG","UGA","Adjumani","admin","37100","1800855823"
"Bərdə","Barda","40.3744","47.1267","Azerbaijan","AZ","AZE","Bərdə","admin","38500","1031495701"
"Palaiseau","Palaiseau","48.7145","2.2457","France","FR","FRA","Île-de-France","minor","35590","1250731865"
"Casalecchio di Reno","Casalecchio di Reno","44.4833","11.2833","Italy","IT","ITA","Emilia-Romagna","","36456","1380710953"
"Dover","Dover","51.1295","1.3089","United Kingdom","GB","GBR","Kent","","31022","1826963129"
"Riacho de Santana","Riacho de Santana","-13.6089","-42.9389","Brazil","BR","BRA","Bahia","","36039","1076808220"
"Tunceli","Tunceli","39.1064","39.5472","Turkey","TR","TUR","Tunceli","admin","38504","1792507574"
"Klinë","Kline","42.6167","20.5667","Kosovo","XK","XKS","Klinë","admin","38496","1901230162"
"Bucha","Bucha","50.5486","30.2208","Ukraine","UA","UKR","Kyivska Oblast","minor","35162","1804587935"
"La Presa","La Presa","32.7110","-117.0027","United States","US","USA","California","","38492","1840018023"
"Agía Varvára","Agia Varvara","38.0000","23.6500","Greece","GR","GRC","Attikí","minor","26550","1300465012"
"Schiltigheim","Schiltigheim","48.6078","7.7500","France","FR","FRA","Grand Est","","33993","1250916117"
"La Puente","La Puente","34.0323","-117.9533","United States","US","USA","California","","38447","1840020513"
"Wołomin","Wolomin","52.3500","21.2500","Poland","PL","POL","Mazowieckie","minor","36311","1616965427"
"Tomatlán","Tomatlan","19.9333","-105.2333","Mexico","MX","MEX","Jalisco","minor","35824","1484507470"
"Escuinapa","Escuinapa","22.9822","-105.7031","Mexico","MX","MEX","Sinaloa","minor","30790","1484295544"
"Argyroúpoli","Argyroupoli","37.9000","23.7500","Greece","GR","GRC","Attikí","minor","34097","1300735599"
"Choybalsan","Choybalsan","48.0783","114.5350","Mongolia","MN","MNG","Dornod","admin","38150","1496921469"
"Hitchin","Hitchin","51.9470","-0.2830","United Kingdom","GB","GBR","Hertfordshire","","34266","1826093917"
"Shangzhuangcun","Shangzhuangcun","23.5226","116.7134","China","CN","CHN","Guangdong","","35570","1156095214"
"Am-Timan","Am-Timan","11.0333","20.2833","Chad","TD","TCD","Salamat","admin","38261","1148823427"
"Rainham","Rainham","51.3600","0.6100","United Kingdom","GB","GBR","Medway","","34088","1826039806"
"Massamá","Massama","38.7568","-9.2748","Portugal","PT","PRT","Lisboa","","28112","1620579552"
"Brugherio","Brugherio","45.5508","9.3011","Italy","IT","ITA","Lombardy","","34868","1380188289"
"Trujillo","Trujillo","9.4170","-70.5000","Venezuela","VE","VEN","Trujillo","admin","38110","1862212549"
"Kranj","Kranj","46.2333","14.3667","Slovenia","SI","SVN","Kranj","admin","37941","1705842003"
"Spalding","Spalding","52.7858","-0.1529","United Kingdom","GB","GBR","Lincolnshire","","34613","1826335951"
"Stanton","Stanton","33.8003","-117.9935","United States","US","USA","California","","38167","1840021966"
"Attingal","Attingal","8.6800","76.8300","India","IN","IND","Kerala","","35648","1356261188"
"Dighwāra","Dighwara","25.7443","85.0100","India","IN","IND","Bihār","","36124","1356011381"
"Kikinda","Kikinda","45.8333","20.4500","Serbia","RS","SRB","Kikinda","admin","38065","1688358284"
"Zeghanghane","Zeghanghane","35.1500","-3.0000","Morocco","MA","MAR","Oriental","","34025","1504260154"
"East Meadow","East Meadow","40.7197","-73.5604","United States","US","USA","New York","","38078","1840005236"
"Brzeg","Brzeg","50.8667","17.4833","Poland","PL","POL","Opolskie","minor","35890","1616000502"
"Thonon-les-Bains","Thonon-les-Bains","46.3627","6.4750","France","FR","FRA","Auvergne-Rhône-Alpes","minor","35826","1250907646"
"La Huacana","La Huacana","18.9625","-101.8069","Mexico","MX","MEX","Michoacán","minor","34243","1484078900"
"Ali Sabieh","Ali Sabieh","11.1500","42.7167","Djibouti","DJ","DJI","Ali Sabieh","admin","37939","1262104228"
"Limbiate","Limbiate","45.5972","9.1300","Italy","IT","ITA","Lombardy","","35141","1380489248"
"Yihezhuang","Yihezhuang","39.1373","116.0744","China","CN","CHN","Hebei","","34359","1156022705"
"Villemomble","Villemomble","48.8833","2.5000","France","FR","FRA","Île-de-France","","30405","1250831662"
"Belleville","Belleville","40.7950","-74.1617","United States","US","USA","New Jersey","","37914","1840131591"
"Northglenn","Northglenn","39.9108","-104.9783","United States","US","USA","Colorado","","37899","1840020192"
"Hoogvliet","Hoogvliet","51.8667","4.3500","Netherlands","NL","NLD","Zuid-Holland","","35575","1528815717"
"Montclair","Montclair","34.0715","-117.6981","United States","US","USA","California","","37871","1840020408"
"Mannar","Mannar","8.9772","79.9138","Sri Lanka","LK","LKA","North Central","","35000","1144238069"
"Reforma","Reforma","17.8658","-93.1472","Mexico","MX","MEX","Chiapas","minor","29018","1484640217"
"Grantham","Grantham","52.9180","-0.6380","United Kingdom","GB","GBR","Lincolnshire","","34592","1826040909"
"Pandaul","Pandaul","26.2517","86.0747","India","IN","IND","Bihār","","35113","1356870725"
"Falun","Falun","60.6072","15.6311","Sweden","SE","SWE","Dalarna","admin","37291","1752939220"
"Kajaani","Kajaani","64.2311","27.7194","Finland","FI","FIN","Kainuu","admin","37622","1246377765"
"Tenosique","Tenosique","17.4756","-91.4225","Mexico","MX","MEX","Tabasco","minor","34946","1484303350"
"Salto del Guairá","Salto del Guaira","-24.0200","-54.3400","Paraguay","PY","PRY","Canindeyú","admin","37600","1600283545"
"Richmond West","Richmond West","25.6105","-80.4297","United States","US","USA","Florida","","37626","1840073270"
"Villagrán","Villagran","20.5170","-100.9830","Mexico","MX","MEX","Guanajuato","minor","27079","1484237393"
"Soteapan","Soteapan","18.2333","-94.8667","Mexico","MX","MEX","Veracruz","minor","35155","1484716801"
"Gabú","Gabu","12.2833","-14.2167","Guinea-Bissau","GW","GNB","Gabú","admin","37525","1624988048"
"San Carlos","San Carlos","11.1236","-84.7780","Nicaragua","NI","NIC","Río San Juan","admin","37461","1558170572"
"Hanover Park","Hanover Park","41.9818","-88.1446","United States","US","USA","Illinois","","37545","1840011251"
"Ōharu","Oharu","35.1751","136.8201","Japan","JP","JPN","Aichi","","32590","1392003375"
"Carshalton","Carshalton","51.3652","-0.1676","United Kingdom","GB","GBR","Sutton","","29917","1826112944"
"Rukungiri","Rukungiri","-0.7900","29.9250","Uganda","UG","UGA","Rukungiri","admin","37200","1800982268"
"Harima","Harima","34.7167","134.8667","Japan","JP","JPN","Hyōgo","","33822","1392000863"
"Schaffhausen","Schaffhausen","47.7000","8.6333","Switzerland","CH","CHE","Schaffhausen","admin","36587","1756849233"
"Bửu Long","Buu Long","10.9600","106.7967","Vietnam","VN","VNM","Đồng Nai","","31861","1704059272"
"Salt","Salt","41.9761","2.7881","Spain","ES","ESP","Catalonia","","32517","1724677327"
"Pāppinisshēri","Pappinissheri","11.9500","75.3500","India","IN","IND","Kerala","","35134","1356597627"
"Nanchital de Lázaro Cárdenas del Río","Nanchital de Lazaro Cardenas del Rio","18.0667","-94.4167","Mexico","MX","MEX","Veracruz","minor","30039","1484990539"
"Aizumi","Aizumi","34.1266","134.4951","Japan","JP","JPN","Tokushima","","35269","1392003339"
"Foothill Farms","Foothill Farms","38.6867","-121.3475","United States","US","USA","California","","37432","1840017582"
"Tozeur","Tozeur","33.9167","8.1333","Tunisia","TN","TUN","Tozeur","admin","37365","1788636202"
"Bečej","Becej","45.6167","20.0333","Serbia","RS","SRB","Bečej","admin","37351","1688492797"
"Madukkarai","Madukkarai","10.9057","76.9634","India","IN","IND","Tamil Nādu","","34902","1356745410"
"Ewell","Ewell","51.3500","-0.2490","United Kingdom","GB","GBR","Surrey","","34872","1826318947"
"Ataq","Ataq","14.5364","46.8331","Yemen","YE","YEM","Shabwah","admin","37315","1887172893"
"Fusō","Fuso","35.3591","136.9132","Japan","JP","JPN","Aichi","","34268","1392003320"
"Colwyn Bay","Colwyn Bay","53.2900","-3.7000","United Kingdom","GB","GBR","Conwy","","34284","1826306536"
"Mabole","Mabole","7.0000","79.9000","Sri Lanka","LK","LKA","Western","","29880","1144304678"
"Banī Suhaylā","Bani Suhayla","31.3428","34.3253","Gaza Strip","XG","XGZ","","","33767","1916052157"
"Huyton","Huyton","53.4111","-2.8403","United Kingdom","GB","GBR","Knowsley","","33193","1826682901"
"Bossangoa","Bossangoa","6.4833","17.4500","Central African Republic","CF","CAF","Ouham","admin","36478","1140523604"
"Bibhutpur","Bibhutpur","25.6878","85.9992","India","IN","IND","Bihār","","35105","1356475713"
"Calumpang","Calumpang","14.6249","121.0865","Philippines","PH","PHL","Marikina","","15602","1608980243"
"Saint-Mandé","Saint-Mande","48.8422","2.4186","France","FR","FRA","Île-de-France","","22377","1250000869"
"Alacuás","Alacuas","39.4583","-0.4628","Spain","ES","ESP","Valencia","","29649","1724760114"
"Putla Villa de Guerrero","Putla Villa de Guerrero","17.0321","-97.9293","Mexico","MX","MEX","Oaxaca","minor","32640","1484503444"
"Senhora da Hora","Senhora da Hora","41.1860","-8.6510","Portugal","PT","PRT","Porto","","29000","1620553414"
"Prijepolje","Prijepolje","43.5439","19.6514","Serbia","RS","SRB","Prijepolje","admin","37059","1688287067"
"Pinner","Pinner","51.5932","-0.3894","United Kingdom","GB","GBR","Harrow","","31130","1826466478"
"Al Qunayţirah","Al Qunaytirah","33.1256","35.8239","Syria","SY","SYR","Al Qunayţirah","admin","37022","1760132692"
"Nishihara","Nishihara","26.2228","127.7589","Japan","JP","JPN","Okinawa","","34802","1392591354"
"Chaiyaphum","Chaiyaphum","15.8056","102.0311","Thailand","TH","THA","Chaiyaphum","admin","36923","1764784757"
"Riccione Marina","Riccione Marina","44.0000","12.6500","Italy","IT","ITA","Emilia-Romagna","","35003","1380177651"
"Deal","Deal","51.2226","1.4006","United Kingdom","GB","GBR","Kent","","30917","1826000065"
"Kendall West","Kendall West","25.7065","-80.4388","United States","US","USA","Florida","","36964","1840035805"
"Pāppākurichchi","Pappakurichchi","10.8137","78.7481","India","IN","IND","Tamil Nādu","","26889","1356222044"
"Motozintla","Motozintla","15.3632","-92.2477","Mexico","MX","MEX","Chiapas","minor","27815","1484046011"
"Nzega","Nzega","-4.2169","33.1864","Tanzania","TZ","TZA","Tabora","","34744","1834034762"
"Pontoise","Pontoise","49.0516","2.1017","France","FR","FRA","Île-de-France","","32405","1250247527"
"Letchworth","Letchworth","51.9780","-0.2300","United Kingdom","GB","GBR","Hertfordshire","","33249","1826971219"
"Cernusco sul Naviglio","Cernusco sul Naviglio","45.5167","9.3333","Italy","IT","ITA","Lombardy","","34341","1380429840"
"Richfield","Richfield","44.8763","-93.2829","United States","US","USA","Minnesota","","36899","1840008930"
"Marijampolė","Marijampole","54.5472","23.3500","Lithuania","LT","LTU","Marijampolė","admin","36807","1440180524"
"Maassluis","Maassluis","51.9333","4.2333","Netherlands","NL","NLD","Zuid-Holland","minor","33567","1528829387"
"Ometepec","Ometepec","16.6833","-98.4167","Mexico","MX","MEX","Guerrero","minor","30271","1484936909"
"Ticul","Ticul","20.3953","-89.5339","Mexico","MX","MEX","Yucatán","","32796","1484056090"
"Kearns","Kearns","40.6519","-112.0095","United States","US","USA","Utah","","36842","1840017500"
"Rumonge","Rumonge","-3.9667","29.4333","Burundi","BI","BDI","Rumonge","admin","35931","1108641885"
"Pak Tin Pa","Pak Tin Pa","22.3364","114.1667","Hong Kong","HK","HKG","","","21902","1344000101"
"Hyde","Hyde","53.4474","-2.0820","United Kingdom","GB","GBR","Tameside","","34003","1826480324"
"Parras de la Fuente","Parras de la Fuente","25.4403","-102.1792","Mexico","MX","MEX","Coahuila","minor","33817","1484273682"
"Abingdon","Abingdon","51.6670","-1.2830","United Kingdom","GB","GBR","Oxfordshire","","33130","1826918722"
"Fresnes","Fresnes","48.7550","2.3221","France","FR","FRA","Île-de-France","","28710","1250376205"
"San Vicente","San Vicente","9.9636","-84.0454","Costa Rica","CR","CRI","San José","","30998","1188615727"
"Ratchaburi","Ratchaburi","13.5356","99.8133","Thailand","TH","THA","Ratchaburi","admin","36339","1764773366"
"Elmont","Elmont","40.7033","-73.7078","United States","US","USA","New York","","36741","1840005226"
"Paso del Macho","Paso del Macho","18.9667","-96.7167","Mexico","MX","MEX","Veracruz","minor","31890","1484203573"
"Paso de Ovejas","Paso de Ovejas","19.2850","-96.4400","Mexico","MX","MEX","Veracruz","minor","33392","1484005936"
"Pardigūda","Pardiguda","17.3974","78.5783","India","IN","IND","Andhra Pradesh","","32586","1356530500"
"Borehamwood","Borehamwood","51.6578","-0.2723","United Kingdom","GB","GBR","Hertfordshire","","31955","1826433403"
"Tit Mellil","Tit Mellil","33.5533","-7.4822","Morocco","MA","MAR","Casablanca-Settat","","32782","1504892729"
"Zapotiltic","Zapotiltic","19.6270","-103.4170","Mexico","MX","MEX","Jalisco","minor","29190","1484535008"
"Trowbridge","Trowbridge","51.3200","-2.2080","United Kingdom","GB","GBR","Wiltshire","","33108","1826965204"
"Wāris Alīganj","Waris Aliganj","25.0100","85.6300","India","IN","IND","Bihār","","34056","1356061108"
"Angri","Angri","40.7333","14.5667","Italy","IT","ITA","Campania","","34126","1380361443"
"Ebebiyín","Ebebiyin","2.1500","11.3167","Equatorial Guinea","GQ","GNQ","Kié-Ntem","admin","36565","1226449195"
"Earley","Earley","51.4330","-0.9330","United Kingdom","GB","GBR","Wokingham","","32036","1826312620"
"Temple City","Temple City","34.1022","-118.0579","United States","US","USA","California","","36526","1840021872"
"Rutherglen","Rutherglen","55.8280","-4.2140","United Kingdom","GB","GBR","Glasgow City","","31190","1826453586"
"Wigston Magna","Wigston Magna","52.5812","-1.0930","United Kingdom","GB","GBR","Leicestershire","","32321","1826846225"
"Chillum","Chillum","38.9666","-76.9791","United States","US","USA","Maryland","","36428","1840005948"
"Butajīra","Butajira","8.1208","38.3792","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","33406","1231050211"
"Ixtapan de la Sal","Ixtapan de la Sal","18.8333","-99.6833","Mexico","MX","MEX","México","minor","30073","1484821151"
"Göyçay","Goycay","40.6531","47.7406","Azerbaijan","AZ","AZE","Göyçay","admin","36200","1031227222"
"Póvoa de Santa Iria","Povoa de Santa Iria","38.8620","-9.0690","Portugal","PT","PRT","Lisboa","","29348","1620229335"
"Bangaon","Bangaon","25.8673","86.5115","India","IN","IND","Bihār","","33770","1356596247"
"Hillerød","Hillerod","55.9333","12.3167","Denmark","DK","DNK","Hovedstaden","admin","35357","1208278269"
"San Miguel","San Miguel","14.6000","121.0000","Philippines","PH","PHL","Manila","","18599","1608270995"
"Winchester","Winchester","36.1365","-115.1370","United States","US","USA","Nevada","","36307","1840033770"
"Purral","Purral","9.9594","-84.0165","Costa Rica","CR","CRI","San José","","27464","1188161276"
"Buchanan","Buchanan","5.8808","-10.0467","Liberia","LR","LBR","Grand Bassa","admin","34270","1430478203"
"Tancítaro","Tancitaro","19.3384","-102.3626","Mexico","MX","MEX","Michoacán","","31100","1484944433"
"Chur","Chur","46.8500","9.5333","Switzerland","CH","CHE","Graubünden","admin","35038","1756471395"
"Clichy-sous-Bois","Clichy-sous-Bois","48.9102","2.5532","France","FR","FRA","Île-de-France","","28992","1250123655"
"Chatou","Chatou","48.8897","2.1573","France","FR","FRA","Île-de-France","","30330","1250855196"
"Yehud","Yehud","32.0333","34.8833","Israel","IL","ISR","Central","","29146","1376760246"
"San Juan Evangelista","San Juan Evangelista","17.8833","-95.1333","Mexico","MX","MEX","Veracruz","minor","33929","1484667360"
"Amecameca de Juárez","Amecameca de Juarez","19.1238","-98.7665","Mexico","MX","MEX","México","minor","31687","1484353804"
"Chāvakkād","Chavakkad","10.5890","76.0167","India","IN","IND","Kerala","","33543","1356065021"
"South Miami Heights","South Miami Heights","25.5886","-80.3862","United States","US","USA","Florida","","36239","1840014240"
"Lichfield","Lichfield","52.6820","-1.8290","United Kingdom","GB","GBR","Staffordshire","","33816","1826803495"
"Mangalam","Mangalam","10.8450","75.9190","India","IN","IND","Kerala","","33442","1356458902"
"Prestwich","Prestwich","53.5333","-2.2833","United Kingdom","GB","GBR","Bury","","31693","1826921530"
"Boende","Boende","-0.2810","20.8760","Congo (Kinshasa)","CD","COD","Tshuapa","admin","36158","1180688906"
"Krong Kep","Krong Kep","10.4875","104.3208","Cambodia","KH","KHM","Kep","admin","35990","1116392544"
"Egypt Lake-Leto","Egypt Lake-Leto","28.0177","-82.5062","United States","US","USA","Florida","","36157","1840073269"
"Minamishiro","Minamishiro","36.0227","139.7227","Japan","JP","JPN","Saitama","","34012","1392934140"
"Kornwestheim","Kornwestheim","48.8598","9.1852","Germany","DE","DEU","Baden-Württemberg","","33803","1276918130"
"Shanawān","Shanawan","30.5031","31.0058","Egypt","EG","EGY","Al Minūfīyah","","33285","1818140562"
"Rodolfo Sánchez Taboada","Rodolfo Sanchez Taboada","31.7958","-116.5911","Mexico","MX","MEX","Baja California","","31006","1484409365"
"Kamenicë","Kamenice","42.5839","21.5750","Kosovo","XK","XKS","Kamenicë","admin","36085","1901851592"
"Roanne","Roanne","46.0367","4.0689","France","FR","FRA","Auvergne-Rhône-Alpes","minor","34004","1250674978"
"Ermont","Ermont","48.9922","2.2603","France","FR","FRA","Île-de-France","","29079","1250549360"
"Gostivar","Gostivar","41.8000","20.9167","Macedonia","MK","MKD","Gostivar","admin","35847","1807901060"
"Byumba","Byumba","-1.5761","30.0675","Rwanda","RW","RWA","Northern Province","admin","34544","1646868980"
"Didcot","Didcot","51.6060","-1.2410","United Kingdom","GB","GBR","Oxfordshire","","32183","1826515635"
"Les Mureaux","Les Mureaux","48.9875","1.9172","France","FR","FRA","Île-de-France","","33203","1250306722"
"Viry-Châtillon","Viry-Chatillon","48.6713","2.3750","France","FR","FRA","Île-de-France","","30865","1250498232"
"Buenaventura Lakes","Buenaventura Lakes","28.3349","-81.3539","United States","US","USA","Florida","","35922","1840028974"
"Westmont","Westmont","33.9417","-118.3018","United States","US","USA","California","","35913","1840028408"
"Dāganbhuiya","Daganbhuiya","22.9127","91.3181","Bangladesh","BD","BGD","Chattogram","","33574","1050791657"
"Lauderdale Lakes","Lauderdale Lakes","26.1682","-80.2017","United States","US","USA","Florida","","35898","1840015145"
"Ecclesfield","Ecclesfield","53.4429","-1.4698","United Kingdom","GB","GBR","Sheffield","","31609","1826382276"
"Beja","Beja","38.0333","-7.8833","Portugal","PT","PRT","Beja","admin","35854","1620050458"
"Vršac","Vrsac","45.1167","21.3033","Serbia","RS","SRB","Vršac","admin","35701","1688560040"
"Cradock","Cradock","-32.1833","25.6167","South Africa","ZA","ZAF","Eastern Cape","","32898","1710933404"
"Agen","Agen","44.2049","0.6212","France","FR","FRA","Nouvelle-Aquitaine","minor","33012","1250059626"
"Sint-Joost-ten-Node","Sint-Joost-ten-Node","50.8500","4.3833","Belgium","BE","BEL","Brussels-Capital Region","","27497","1056751248"
"Champotón","Champoton","19.3500","-90.7167","Mexico","MX","MEX","Campeche","minor","30881","1484959684"
"Ambano","Ambano","-19.8000","47.0333","Madagascar","MG","MDG","Antananarivo","","32000","1450997295"
"Turkauliyā","Turkauliya","26.6079","84.8296","India","IN","IND","Bihār","","32986","1356329131"
"Kaippakanchēri","Kaippakancheri","10.9380","75.9870","India","IN","IND","Kerala","","33721","1356036784"
"Villaricca","Villaricca","40.9167","14.2000","Italy","IT","ITA","Campania","","31223","1380472958"
"Darwen","Darwen","53.6980","-2.4610","United Kingdom","GB","GBR","Blackburn with Darwen","","31570","1826810811"
"Chuhuiv","Chuhuiv","49.8372","36.6899","Ukraine","UA","UKR","Kharkivska Oblast","minor","33144","1804354882"
"Aosta","Aosta","45.7333","7.3167","Italy","IT","ITA","Valle d’Aosta","admin","34082","1380291310"
"Fontenay-aux-Roses","Fontenay-aux-Roses","48.7893","2.2888","France","FR","FRA","Île-de-France","","25531","1250066096"
"Saintard","Saintard","18.8240","-72.5500","Haiti","HT","HTI","Ouest","","32906","1332903429"
"Kōryō","Koryo","34.5500","135.7500","Japan","JP","JPN","Nara","","33617","1392003177"
"West Hollywood","West Hollywood","34.0883","-118.3719","United States","US","USA","California","","35678","1840028098"
"Suong","Suong","11.9167","105.6500","Cambodia","KH","KHM","Tbong Khmum","admin","35054","1116319433"
"Paidha","Paidha","2.4167","30.9875","Uganda","UG","UGA","Zombo","","33426","1800599761"
"Project Six","Project Six","14.6561","121.0408","Philippines","PH","PHL","Quezon","","15255","1608051707"
"Ganshoren","Ganshoren","50.8667","4.3167","Belgium","BE","BEL","Brussels-Capital Region","","25234","1056018953"
"Norristown","Norristown","40.1225","-75.3398","United States","US","USA","Pennsylvania","","35607","1840003705"
"Manhattan Beach","Manhattan Beach","33.8894","-118.3967","United States","US","USA","California","","35585","1840020496"
"Barangka","Barangka","14.6297","121.0803","Philippines","PH","PHL","Marikina","","16639","1608277849"
"Timbuktu","Timbuktu","16.7733","-2.9994","Mali","ML","MLI","Tombouctou","admin","35330","1466595315"
"Villiers-sur-Marne","Villiers-sur-Marne","48.8275","2.5447","France","FR","FRA","Île-de-France","","28895","1250966526"
"Villanueva","Villanueva","22.3536","-102.8831","Mexico","MX","MEX","Zacatecas","minor","32835","1484244113"
"Pabellón de Arteaga","Pabellon de Arteaga","22.1500","-102.2667","Mexico","MX","MEX","Aguascalientes","minor","28633","1484799033"
"Luboń","Lubon","52.3333","16.8833","Poland","PL","POL","Wielkopolskie","","33024","1616642403"
"Roi Et","Roi Et","16.0531","103.6511","Thailand","TH","THA","Roi Et","admin","35219","1764216668"
"Mỹ Hòa","My Hoa","10.3655","105.4011","Vietnam","VN","VNM","An Giang","","33413","1704017086"
"Thiais","Thiais","48.7650","2.3923","France","FR","FRA","Île-de-France","","30676","1250126108"
"Laḩij","Lahij","13.0500","44.8833","Yemen","YE","YEM","Laḩij","admin","30661","1887871834"
"Savalou","Savalou","7.9333","1.9667","Benin","BJ","BEN","Collines","admin","35433","1204628914"
"Gisborne","Gisborne","-38.6625","178.0178","New Zealand","NZ","NZL","Gisborne","admin","34527","1554359840"
"Sestao","Sestao","43.3108","-3.0056","Spain","ES","ESP","Basque Country","","27533","1724738779"
"Metamórfosi","Metamorfosi","38.0500","23.7500","Greece","GR","GRC","Attikí","minor","29891","1300945079"
"Kaisarianí","Kaisariani","37.9683","23.7583","Greece","GR","GRC","Attikí","minor","26458","1300742537"
"Bragança","Braganca","41.8067","-6.7589","Portugal","PT","PRT","Bragança","admin","35341","1620281131"
"Phatthalung","Phatthalung","7.5000","100.0000","Thailand","TH","THA","Phatthalung","admin","35039","1764240863"
"Sānkrāil","Sankrail","22.5700","88.2400","India","IN","IND","West Bengal","","29114","1356477305"
"Montigny-le-Bretonneux","Montigny-le-Bretonneux","48.7711","2.0333","France","FR","FRA","Île-de-France","","32575","1250533450"
"Neuchâtel","Neuchatel","47.0000","6.9333","Switzerland","CH","CHE","Neuchâtel","admin","33475","1756726571"
"Midvale","Midvale","40.6148","-111.8927","United States","US","USA","Utah","","35313","1840020158"
"Kartārpur","Kartarpur","31.4427","75.4985","India","IN","IND","Punjab","","33207","1356753933"
"Frattamaggiore","Frattamaggiore","40.9333","14.2833","Italy","IT","ITA","Campania","","29738","1380074125"
"Papendrecht","Papendrecht","51.8333","4.6833","Netherlands","NL","NLD","Zuid-Holland","minor","32290","1528603261"
"Gentilly","Gentilly","48.8133","2.3444","France","FR","FRA","Île-de-France","","18815","1250539371"
"Ban Bang Krang","Ban Bang Krang","13.8422","100.4539","Thailand","TH","THA","Nonthaburi","","23010","1764797592"
"Mechraa Bel Ksiri","Mechraa Bel Ksiri","34.5600","-5.9500","Morocco","MA","MAR","Rabat-Salé-Kénitra","","31497","1504646910"
"Pallijkarani","Pallijkarani","12.9333","80.2022","India","IN","IND","Tamil Nādu","","29132","1356329874"
"San Salvador El Seco","San Salvador El Seco","19.1333","-97.6500","Mexico","MX","MEX","Puebla","","30303","1484262709"
"Qazax","Qazax","41.0933","45.3661","Azerbaijan","AZ","AZE","Qazax","admin","35102","1031240282"
"Bria","Bria","6.5369","21.9919","Central African Republic","CF","CAF","Haute-Kotto","admin","35204","1140582053"
"San Donato Milanese","San Donato Milanese","45.4167","9.2667","Italy","IT","ITA","Lombardy","","32664","1380781969"
"Watertown Town","Watertown Town","42.3700","-71.1774","United States","US","USA","Massachusetts","","35171","1840031198"
"Elesvaram","Elesvaram","17.2833","82.1000","India","IN","IND","Andhra Pradesh","","32957","1356080600"
"Magdalena de Kino","Magdalena de Kino","30.6167","-111.0500","Mexico","MX","MEX","Sonora","minor","30429","1484799240"
"Bexleyheath","Bexleyheath","51.4590","0.1380","United Kingdom","GB","GBR","Bexley","","31929","1826858601"
"Bussum","Bussum","52.2833","5.1667","Netherlands","NL","NLD","Noord-Holland","minor","32410","1528731782"
"Ban Ang Sila","Ban Ang Sila","13.3364","100.9278","Thailand","TH","THA","Chon Buri","","33053","1764931523"
"Maīdān Shahr","Maidan Shahr","34.3972","68.8697","Afghanistan","AF","AFG","Wardak","admin","35008","1004798735"
"Artvin","Artvin","41.1833","41.8181","Turkey","TR","TUR","Artvin","admin","35081","1792320984"
"Giv‘at Shemu’él","Giv\`at Shemu'el","32.0781","34.8475","Israel","IL","ISR","Central","","25298","1376429803"
"Néa Filadélfeia","Nea Filadelfeia","38.0367","23.7367","Greece","GR","GRC","Attikí","minor","25734","1300810075"
"Barguna","Barguna","22.1500","90.1167","Bangladesh","BD","BGD","Barishal","","33000","1050705630"
"Trappes","Trappes","48.7775","2.0025","France","FR","FRA","Île-de-France","","32645","1250762117"
"Vigneux-sur-Seine","Vigneux-sur-Seine","48.7001","2.4170","France","FR","FRA","Île-de-France","","31463","1250005644"
"San Pedro de Ycuamandiyú","San Pedro de Ycuamandiyu","-24.1000","-57.0833","Paraguay","PY","PRY","San Pedro","admin","35021","1600189153"
"Ibrā’","Ibra'","22.6833","58.5500","Oman","OM","OMN","Shamāl ash Sharqīyah","admin","35000","1512341564"
"Dikhil","Dikhil","11.1167","42.3667","Djibouti","DJ","DJI","Dikhil","admin","35000","1262950337"
"Awbārī","Awbari","26.5833","12.7667","Libya","LY","LBY","Wādī al Ḩayāt","admin","35000","1434431378"
"Pinhal Novo","Pinhal Novo","38.6310","-8.9140","Portugal","PT","PRT","Setúbal","","25000","1620447545"
"Ocotal","Ocotal","13.6336","-86.4753","Nicaragua","NI","NIC","Nueva Segovia","admin","34580","1558006000"
"Beidaying","Beidaying","39.9686","119.5515","China","CN","CHN","Hebei","","31139","1156141116"
"Brighouse","Brighouse","53.7070","-1.7940","United Kingdom","GB","GBR","Calderdale","","32360","1826688359"
"College Park","College Park","38.9960","-76.9337","United States","US","USA","Maryland","","34961","1840005971"
"Saint Neots","Saint Neots","52.2280","-0.2700","United Kingdom","GB","GBR","Cambridgeshire","","31165","1826441495"
"Santa Maria Capua Vetere","Santa Maria Capua Vetere","41.0833","14.2500","Italy","IT","ITA","Campania","","32893","1380894593"
"University City","University City","38.6657","-90.3315","United States","US","USA","Missouri","","34953","1840010739"
"Motherwell","Motherwell","55.7839","-3.9852","United Kingdom","GB","GBR","North Lanarkshire","","32590","1826854355"
"Ciudad Sabinas Hidalgo","Ciudad Sabinas Hidalgo","26.5000","-100.1833","Mexico","MX","MEX","Nuevo León","minor","32047","1484159329"
"San Rafael Abajo","San Rafael Abajo","9.8943","-84.0837","Costa Rica","CR","CRI","San José","","23283","1188603380"
"Dragash","Dragash","42.0611","20.6528","Kosovo","XK","XKS","Dragash","admin","34827","1901112530"
"Naryn","Naryn","41.4333","76.0000","Kyrgyzstan","KG","KGZ","Naryn","admin","34822","1417466970"
"Fair Lawn","Fair Lawn","40.9359","-74.1177","United States","US","USA","New Jersey","","34820","1840003548"
"Long Beach","Long Beach","40.5887","-73.6660","United States","US","USA","New York","","34820","1840000988"
"San Martin De Porres","San Martin De Porres","14.4817","121.0425","Philippines","PH","PHL","Parañaque","","21181","1608242946"
"Qornet Chahouâne","Qornet Chahouane","33.9200","35.6700","Lebanon","LB","LBN","Mont-Liban","","28000","1422333947"
"Escárcega","Escarcega","18.6067","-90.7344","Mexico","MX","MEX","Campeche","minor","29477","1484800970"
"Metepec","Metepec","19.2511","-99.6047","Mexico","MX","MEX","Morelos","minor","28205","1484782768"
"Aldaya","Aldaya","39.4639","-0.4628","Spain","ES","ESP","Valencia","","32656","1724676507"
"Pontypridd","Pontypridd","51.6020","-3.3420","United Kingdom","GB","GBR","Rhondda Cynon Taff","","31206","1826842541"
"Busia","Busia","0.4633","34.1053","Kenya","KE","KEN","Busia","admin","30777","1404250734"
"Kendal","Kendal","54.3260","-2.7450","United Kingdom","GB","GBR","Cumbria","","29593","1826195534"
"Kalasin","Kalasin","16.4342","103.5092","Thailand","TH","THA","Kalasin","admin","34437","1764060973"
"Fort Liberté","Fort Liberte","19.6678","-71.8397","Haiti","HT","HTI","Nord-Est","admin","34434","1332603160"
"Chamtha","Chamtha","25.5789","85.7734","India","IN","IND","Bihār","","32371","1356553756"
"Huatabampo","Huatabampo","26.8275","-109.6422","Mexico","MX","MEX","Sonora","minor","30324","1484335657"
"Chichester","Chichester","50.8365","-0.7792","United Kingdom","GB","GBR","West Sussex","","30925","1826696164"
"Smolyan","Smolyan","41.5833","24.7000","Bulgaria","BG","BGR","Smolyan","admin","34465","1100429166"
"Isingiro","Isingiro","-0.7950","30.8153","Uganda","UG","UGA","Isingiro","admin","34300","1800843586"
"Swakopmund","Swakopmund","-22.6833","14.5333","Namibia","NA","NAM","Erongo","admin","34300","1516214290"
"Montgomery Village","Montgomery Village","39.1788","-77.1954","United States","US","USA","Maryland","","34489","1840005834"
"Homa Bay","Homa Bay","-0.5167","34.4500","Kenya","KE","KEN","Homa Bay","admin","32174","1404321159"
"Fair Oaks","Fair Oaks","38.8653","-77.3586","United States","US","USA","Virginia","","34437","1840024568"
"Merauke","Merauke","-8.4932","140.4018","Indonesia","ID","IDN","Papua Selatan","admin","34412","1360904439"
"Nu‘ayjah","Nu\`ayjah","25.2525","51.5592","Qatar","QA","QAT","Ad Dawḩah","","29703","1634400511"
"Wishaw","Wishaw","55.7742","-3.9183","United Kingdom","GB","GBR","North Lanarkshire","","30290","1826026639"
"Azogues","Azogues","-2.7333","-78.8333","Ecuador","EC","ECU","Cañar","admin","33848","1218651012"
"Ostróda","Ostroda","53.7000","19.9667","Poland","PL","POL","Warmińsko-Mazurskie","minor","32052","1616636172"
"Perry Barr","Perry Barr","52.5249","-1.9042","United Kingdom","GB","GBR","Birmingham","","23652","1826464543"
"Lens","Lens","50.4322","2.8333","France","FR","FRA","Hauts-de-France","minor","31606","1250108513"
"La Unión","La Union","13.3369","-87.8439","El Salvador","SV","SLV","La Unión","admin","34045","1222307415"
"West Ham","West Ham","51.5340","0.0080","United Kingdom","GB","GBR","Newham","","15551","1826369701"
"Swadlincote","Swadlincote","52.7740","-1.5570","United Kingdom","GB","GBR","Derbyshire","","32219","1826055646"
"Bulwell","Bulwell","53.0010","-1.1970","United Kingdom","GB","GBR","Nottingham","","29771","1826933370"
"Šid","Sid","45.1167","19.2333","Serbia","RS","SRB","Šid","admin","34188","1688899483"
"Sliema","Sliema","35.9122","14.5042","Malta","MT","MLT","Sliema","admin","16854","1470229986"
"Singia","Singia","25.8424","86.1622","India","IN","IND","Bihār","","31760","1356596014"
"Le Chesnay","Le Chesnay","48.8203","2.1303","France","FR","FRA","Île-de-France","","27683","1250500651"
"Kararān","Kararan","30.7720","76.7959","India","IN","IND","Punjab","","26876","1356076825"
"Eastchester","Eastchester","40.9536","-73.8133","United States","US","USA","New York","","34207","1840058058"
"Bor","Bor","44.1303","22.1036","Serbia","RS","SRB","Bor","admin","34159","1688966590"
"Vandiyūr","Vandiyur","9.9092","78.1609","India","IN","IND","Tamil Nādu","","29009","1356234558"
"Fushë Kosovë","Fushe Kosove","42.6300","21.1200","Kosovo","XK","XKS","Fushë Kosovë","admin","33997","1901134407"
"Eastpointe","Eastpointe","42.4657","-82.9461","United States","US","USA","Michigan","","34148","1840002420"
"Câmpulung","Campulung","45.2678","25.0464","Romania","RO","ROU","Argeş","","31767","1642199669"
"Frome","Frome","51.2279","-2.3215","United Kingdom","GB","GBR","Somerset","","28559","1826730210"
"Uttaradit","Uttaradit","17.6231","100.0958","Thailand","TH","THA","Uttaradit","admin","33930","1764146253"
"Barros Blancos","Barros Blancos","-34.7542","-56.0042","Uruguay","UY","URY","Canelones","","31650","1858612587"
"Lidingö","Lidingo","59.3667","18.1500","Sweden","SE","SWE","Stockholm","minor","31561","1752051674"
"Zumpango del Río","Zumpango del Rio","17.6500","-99.5000","Mexico","MX","MEX","Guerrero","minor","24719","1484750477"
"Shariff Aguak","Shariff Aguak","6.8647","124.4417","Philippines","PH","PHL","Maguindanao","admin","33982","1608346338"
"Keşlə","Kesla","40.3978","49.8986","Azerbaijan","AZ","AZE","Bakı","","25626","1031163051"
"Grigny","Grigny","48.6562","2.3849","France","FR","FRA","Île-de-France","","28265","1250191688"
"Zhaoyu","Zhaoyu","37.3512","112.3193","China","CN","CHN","Shanxi","minor","31685","1156254219"
"Huntington Station","Huntington Station","40.8446","-73.4050","United States","US","USA","New York","","34050","1840005081"
"Kampong Speu","Kampong Speu","11.4520","104.5190","Cambodia","KH","KHM","Kampong Speu","admin","33231","1116446492"
"Kakata","Kakata","6.5300","-10.3517","Liberia","LR","LBR","Margibi","admin","33945","1430656307"
"Moscháto","Moschato","37.9500","23.6667","Greece","GR","GRC","Attikí","minor","25441","1300869464"
"Bresso","Bresso","45.5333","9.1833","Italy","IT","ITA","Lombardy","","26259","1380154563"
"Ventspils","Ventspils","57.3906","21.5733","Latvia","LV","LVA","Ventspils","admin","33372","1428112098"
"Saint-Cloud","Saint-Cloud","48.8400","2.2200","France","FR","FRA","Île-de-France","","30038","1250160957"
"Tambo","Tambo","14.5164","120.9889","Philippines","PH","PHL","Parañaque","","25699","1608929158"
"Sidi Smai’il","Sidi Smai'il","32.8167","-8.5000","Morocco","MA","MAR","Casablanca-Settat","","28733","1504182456"
"Dandenong","Dandenong","-37.9810","145.2150","Australia","AU","AUS","Victoria","","29906","1036214023"
"Ciudad Sahagun","Ciudad Sahagun","19.7714","-98.5803","Mexico","MX","MEX","Hidalgo","","28609","1484112463"
"Puyo","Puyo","-1.4861","-78.0028","Ecuador","EC","ECU","Pastaza","admin","33557","1218537461"
"Lohāgāra","Lohagara","22.0181","92.0974","Bangladesh","BD","BGD","Chattogram","","30815","1050428352"
"North Providence","North Providence","41.8616","-71.4575","United States","US","USA","Rhode Island","","33935","1840106198"
"Golden Glades","Golden Glades","25.9129","-80.2013","United States","US","USA","Florida","","33930","1840029039"
"Bell","Bell","33.9801","-118.1798","United States","US","USA","California","","33915","1840019228"
"City of Orange","City of Orange","40.7681","-74.2348","United States","US","USA","New Jersey","","33906","1840131615"
"Hendrik-Ido-Ambacht","Hendrik-Ido-Ambacht","51.8500","4.6333","Netherlands","NL","NLD","Zuid-Holland","minor","31258","1528804375"
"Somoto","Somoto","13.4842","-86.5817","Nicaragua","NI","NIC","Madriz","admin","33788","1558605178"
"Balangkas","Balangkas","14.7381","120.9978","Philippines","PH","PHL","Bataan","","14318","1608211994"
"Mount Lebanon","Mount Lebanon","40.3752","-80.0494","United States","US","USA","Pennsylvania","","33832","1840133089"
"Kovin","Kovin","44.7500","20.9667","Serbia","RS","SRB","Kovin","admin","33722","1688830508"
"Koh Kong","Koh Kong","11.6167","102.9833","Cambodia","KH","KHM","Koh Kong","admin","33591","1116924669"
"Goussainville","Goussainville","49.0325","2.4747","France","FR","FRA","Île-de-France","","31068","1250333047"
"Nauāgarhi","Nauagarhi","25.3443","86.5358","India","IN","IND","Bihār","","31426","1356254225"
"Nagtala","Nagtala","22.3693","88.6071","India","IN","IND","West Bengal","","10419","1356265513"
"Bègles","Begles","44.8086","-0.5478","France","FR","FRA","Nouvelle-Aquitaine","","30642","1250300357"
"San Giuseppe Vesuviano","San Giuseppe Vesuviano","40.8333","14.5000","Italy","IT","ITA","Campania","","31612","1380589632"
"Chumphon","Chumphon","10.4939","99.1800","Thailand","TH","THA","Chumphon","admin","33516","1764012939"
"Cabarroguis","Cabarroguis","16.5103","121.5222","Philippines","PH","PHL","Quirino","admin","33533","1608312278"
"Sursand","Sursand","26.6500","85.7167","India","IN","IND","Bihār","","31553","1356303686"
"Wik’ro","Wik'ro","13.7833","39.6000","Ethiopia","ET","ETH","Tigray","","30210","1231223279"
"Kericho","Kericho","-0.3692","35.2839","Kenya","KE","KEN","Kericho","admin","30023","1404338562"
"Banlung","Banlung","13.7468","107.0045","Cambodia","KH","KHM","Ratanakiri","admin","30399","1116605438"
"Diemen","Diemen","52.3333","4.9667","Netherlands","NL","NLD","Noord-Holland","minor","31334","1528749595"
"Longton","Longton","52.9877","-2.1327","United Kingdom","GB","GBR","Stoke-on-Trent","","27214","1826288054"
"Mandera","Mandera","3.9167","41.8333","Kenya","KE","KEN","Mandera","admin","30433","1404192737"
"Foster City","Foster City","37.5553","-122.2659","United States","US","USA","California","","33511","1840020302"
"Englewood","Englewood","39.6468","-104.9942","United States","US","USA","Colorado","","33500","1840020211"
"Curridabat","Curridabat","9.9160","-84.0348","Costa Rica","CR","CRI","San José","","28817","1188145799"
"Kaçanik","Kacanik","42.2467","21.2553","Kosovo","XK","XKS","Kaçanik","admin","33409","1901200321"
"Ziniaré","Ziniare","12.5833","-1.3000","Burkina Faso","BF","BFA","Plateau-Central","admin","33296","1854052758"
"Glendale Heights","Glendale Heights","41.9196","-88.0785","United States","US","USA","Illinois","","33443","1840011406"
"Sisak","Sisak","45.4872","16.3761","Croatia","HR","HRV","Sisačko-Moslavačka Županija","admin","33322","1191526652"
"Bromsgrove","Bromsgrove","52.3353","-2.0579","United Kingdom","GB","GBR","Worcestershire","","29237","1826356095"
"Paiporta","Paiporta","39.4278","-0.4183","Spain","ES","ESP","Valencia","","26567","1724055183"
"Birkirkara","Birkirkara","35.8967","14.4625","Malta","MT","MLT","Birkirkara","admin","24356","1470754290"
"Gérakas","Gerakas","38.0333","23.8500","Greece","GR","GRC","Attikí","minor","29939","1300462849"
"Baldwin","Baldwin","40.6511","-73.6075","United States","US","USA","New York","","33352","1840005228"
"Pont-y-pŵl","Pont-y-pwl","51.7030","-3.0410","United Kingdom","GB","GBR","Torfaen","","28970","1826931206"
"Dana Point","Dana Point","33.4733","-117.6967","United States","US","USA","California","","33327","1840019328"
"Kyrenia","Kyrenia","35.3403","33.3192","Cyprus","CY","CYP","Kerýneia","admin","33207","1196334499"
"Bent Jbaïl","Bent Jbail","33.1258","35.4428","Lebanon","LB","LBN","Nabatîyé","minor","30000","1422323141"
"Mažeikiai","Mazeikiai","56.3167","22.3333","Lithuania","LT","LTU","Mažeikiai","admin","33249","1440746512"
"West Little River","West Little River","25.8571","-80.2367","United States","US","USA","Florida","","33284","1840029096"
"Mukdahan","Mukdahan","16.5431","104.7228","Thailand","TH","THA","Mukdahan","admin","33102","1764994534"
"Adrogue","Adrogue","-34.8000","-58.3833","Argentina","AR","ARG","Buenos Aires","","28265","1032827397"
"Vandœuvre-lès-Nancy","Vandoeuvre-les-Nancy","48.6567","6.1683","France","FR","FRA","Grand Est","","30009","1250981193"
"Ris-Orangis","Ris-Orangis","48.6537","2.4161","France","FR","FRA","Île-de-France","","29745","1250798582"
"Dedza","Dedza","-14.3667","34.3333","Malawi","MW","MWI","Dedza","admin","30928","1454412462"
"Agblangandan","Agblangandan","6.3667","2.5167","Benin","BJ","BEN","Ouémé","","30716","1204834123"
"Pothuhera","Pothuhera","7.4199","80.3274","Sri Lanka","LK","LKA","North Western","","30315","1144415368"
"Kurunegala","Kurunegala","7.4833","80.3667","Sri Lanka","LK","LKA","North Western","admin","30315","1144602660"
"San Juan","San Juan","9.9609","-84.0731","Costa Rica","CR","CRI","San José","","25866","1188421709"
"Périgueux","Perigueux","45.1929","0.7217","France","FR","FRA","Nouvelle-Aquitaine","minor","30060","1250864403"
"Timimoun","Timimoun","29.2500","0.2333","Algeria","DZ","DZA","Timimoun","admin","33060","1012351010"
"Montfermeil","Montfermeil","48.9000","2.5667","France","FR","FRA","Île-de-France","","27928","1250655335"
"Wahga","Wahga","31.6047","74.5731","Pakistan","PK","PAK","Punjab","","26900","1586031751"
"Bang Phongphang","Bang Phongphang","13.6791","100.5291","Thailand","TH","THA","Samut Prakan","","28692","1764502075"
"Tarakeswar","Tarakeswar","22.8900","88.0200","India","IN","IND","West Bengal","","30947","1356820799"
"Sotteville-lès-Rouen","Sotteville-les-Rouen","49.4092","1.0900","France","FR","FRA","Normandie","","29068","1250313503"
"Spring Valley","Spring Valley","41.1151","-74.0486","United States","US","USA","New York","","32953","1840004986"
"Pilar","Pilar","-26.8700","-58.3000","Paraguay","PY","PRY","Ñeembucú","admin","32810","1600377954"
"San Carlos","San Carlos","-34.8000","-54.9167","Uruguay","UY","URY","Maldonado","","27471","1858085265"
"Dagestanskiye Ogni","Dagestanskiye Ogni","42.1167","48.1833","Russia","RU","RUS","Dagestan","","29716","1643307601"
"Welk’īt’ē","Welk'it'e","8.2833","37.7833","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","28866","1231083511"
"Beverly Hills","Beverly Hills","34.0786","-118.4021","United States","US","USA","California","","32903","1840019231"
"Aix-les-Bains","Aix-les-Bains","45.6885","5.9153","France","FR","FRA","Auvergne-Rhône-Alpes","","30463","1250456565"
"Aci Catena","Aci Catena","37.6000","15.1333","Italy","IT","ITA","Sicilia","","29418","1380362156"
"Harper","Harper","4.3667","-7.7167","Liberia","LR","LBR","Maryland","admin","32661","1430540258"
"Vilāngudi","Vilangudi","9.9458","78.0935","India","IN","IND","Tamil Nādu","","27940","1356235420"
"Chester","Chester","39.8456","-75.3718","United States","US","USA","Pennsylvania","","32819","1840000690"
"Rillieux-la-Pape","Rillieux-la-Pape","45.8214","4.8983","France","FR","FRA","Auvergne-Rhône-Alpes","","30697","1250128916"
"Kamateró","Kamatero","38.0597","23.7120","Greece","GR","GRC","Attikí","","28361","1300483452"
"Uniondale","Uniondale","40.7176","-73.5947","United States","US","USA","New York","","32791","1840005279"
"Cramlington","Cramlington","55.0820","-1.5850","United Kingdom","GB","GBR","Northumberland","","29405","1826152039"
"Barañáin","Baranain","42.8000","-1.6667","Spain","ES","ESP","Navarre","","19853","1724436657"
"Liévin","Lievin","50.4228","2.7786","France","FR","FRA","Hauts-de-France","","30423","1250072003"
"Arfoud","Arfoud","31.4361","-4.2328","Morocco","MA","MAR","Drâa-Tafilalet","","29279","1504190665"
"Krimpen aan den IJssel","Krimpen aan den IJssel","51.9167","4.5833","Netherlands","NL","NLD","Zuid-Holland","minor","29410","1528654636"
"Menton","Menton","43.7750","7.5000","France","FR","FRA","Provence-Alpes-Côte d’Azur","","30525","1250391575"
"Boscombe","Boscombe","50.7250","-1.8400","United Kingdom","GB","GBR","","","20719","1826070994"
"Narapalli","Narapalli","17.4875","78.6012","India","IN","IND","Andhra Pradesh","","30502","1356002369"
"Durango","Durango","43.1689","-2.6300","Spain","ES","ESP","Basque Country","","29935","1724494232"
"Kitui","Kitui","-1.3667","38.0167","Kenya","KE","KEN","Kitui","admin","29062","1404548296"
"Nethirimangalam","Nethirimangalam","10.8000","76.1400","India","IN","IND","Kerala","","30056","1356593739"
"Salgótarján","Salgotarjan","48.1040","19.8075","Hungary","HU","HUN","Nógrád","admin","32304","1348290189"
"Oullins","Oullins","45.7150","4.8083","France","FR","FRA","Auvergne-Rhône-Alpes","","26583","1250831015"
"Harpenden","Harpenden","51.8175","-0.3524","United Kingdom","GB","GBR","Hertfordshire","","30240","1826679950"
"Sabalpur","Sabalpur","25.6053","85.1835","India","IN","IND","Bihār","","18810","1356281848"
"Hertford","Hertford","51.7966","-0.0774","United Kingdom","GB","GBR","Hertfordshire","","28689","1826912872"
"Kokhma","Kokhma","56.9311","41.0889","Russia","RU","RUS","Ivanovskaya Oblast’","","30161","1643786528"
"I-n-Salah","I-n-Salah","27.1936","2.4606","Algeria","DZ","DZA","In Salah","admin","32518","1012201146"
"Soledad de Doblado","Soledad de Doblado","19.0447","-96.4233","Mexico","MX","MEX","Veracruz","minor","27770","1484007805"
"Kiryas Joel","Kiryas Joel","41.3411","-74.1667","United States","US","USA","New York","","32498","1840004872"
"Savigny-le-Temple","Savigny-le-Temple","48.5841","2.5832","France","FR","FRA","Île-de-France","","29987","1250790534"
"Garfield","Garfield","40.8791","-74.1085","United States","US","USA","New Jersey","","32481","1840003554"
"Leticia","Leticia","-4.2167","-69.9333","Colombia","CO","COL","Amazonas","admin","32450","1170548641"
"Rury","Rury","51.2386","22.5164","Poland","PL","POL","Lubelskie","","30093","1616795231"
"Côte-Saint-Luc","Cote-Saint-Luc","45.4687","-73.6673","Canada","CA","CAN","Quebec","","32448.00","1124563603"
"Demnat","Demnat","31.7311","-7.0361","Morocco","MA","MAR","Béni Mellal-Khénifra","","29504","1504747424"
"Yverdon-les-Bains","Yverdon-les-Bains","46.7785","6.6408","Switzerland","CH","CHE","Vaud","minor","30157","1756916105"
"Franklin Square","Franklin Square","40.7002","-73.6775","United States","US","USA","New York","","32381","1840005256"
"Navan","Navan","53.6528","-6.6814","Ireland","IE","IRL","Meath","","30173","1372720842"
"Bourg-la-Reine","Bourg-la-Reine","48.7796","2.3151","France","FR","FRA","Île-de-France","","21050","1250693512"
"Bou Arfa","Bou Arfa","32.5309","-1.9650","Morocco","MA","MAR","Oriental","","28846","1504454457"
"Álamo","Alamo","20.9167","-97.6750","Mexico","MX","MEX","Veracruz","minor","25159","1484161565"
"Darlaston","Darlaston","52.5708","-2.0457","United Kingdom","GB","GBR","Walsall","","27821","1826133238"
"Marhaura","Marhaura","25.9700","84.8700","India","IN","IND","Bihār","","29932","1356757677"
"Bangassou","Bangassou","4.7374","22.8195","Central African Republic","CF","CAF","Mbomou","admin","31453","1140539356"
"Anosipatrana","Anosipatrana","-18.9333","47.5000","Madagascar","MG","MDG","Antananarivo","","26107","1450506869"
"Yerres","Yerres","48.7171","2.4881","France","FR","FRA","Île-de-France","","29338","1250114629"
"Villiers-le-Bel","Villiers-le-Bel","49.0094","2.3911","France","FR","FRA","Île-de-France","","28423","1250001397"
"Juneau","Juneau","58.4546","-134.1739","United States","US","USA","Alaska","admin","32240","1840023306"
"Chachapoyas","Chachapoyas","-6.2167","-77.8500","Peru","PE","PER","Amazonas","admin","32026","1604650674"
"Cuitzeo del Porvenir","Cuitzeo del Porvenir","19.9686","-101.1394","Mexico","MX","MEX","Michoacán","minor","28227","1484718007"
"Kulat","Kulat","-8.8243","115.0983","Indonesia","ID","IDN","Bali","","30012","1360003468"
"Rumbek","Rumbek","6.8060","29.6760","South Sudan","SS","SSD","Lakes","admin","32100","1728842249"
"Les Pavillons-sous-Bois","Les Pavillons-sous-Bois","48.9000","2.5000","France","FR","FRA","Île-de-France","","23960","1250984996"
"El Salto","El Salto","23.7823","-105.3585","Mexico","MX","MEX","Durango","minor","24241","1484463833"
"Sannois","Sannois","48.9722","2.2578","France","FR","FRA","Île-de-France","","26565","1250650651"
"Kiryandongo","Kiryandongo","1.9525","32.1389","Uganda","UG","UGA","Kiryandongo","admin","31610","1800154303"
"San Andrés de la Barca","San Andres de la Barca","41.4478","1.9769","Spain","ES","ESP","Catalonia","","27175","1724606046"
"Helena","Helena","46.5965","-112.0202","United States","US","USA","Montana","admin","32060","1840019820"
"Saint-Laurent-du-Var","Saint-Laurent-du-Var","43.6680","7.1880","France","FR","FRA","Provence-Alpes-Côte d’Azur","","29169","1250141244"
"Maranga","Maranga","25.7592","87.4665","India","IN","IND","Bihār","","29549","1356063376"
"Lawndale","Lawndale","33.8884","-118.3531","United States","US","USA","California","","32035","1840020517"
"Chon Buri","Chon Buri","13.3611","100.9850","Thailand","TH","THA","Chon Buri","admin","29961","1764202077"
"Nibria","Nibria","22.6100","88.2500","India","IN","IND","West Bengal","","27818","1356924997"
"Kampong Thom","Kampong Thom","12.7000","104.9000","Cambodia","KH","KHM","Kampong Thom","admin","31871","1116471297"
"Molde","Molde","62.7375","7.1591","Norway","NO","NOR","Møre og Romsdal","admin","32002","1578540727"
"Stepney","Stepney","51.5152","-0.0462","United Kingdom","GB","GBR","Tower Hamlets","","16238","1826642497"
"Actopan","Actopan","20.2681","-98.9442","Mexico","MX","MEX","Hidalgo","","29223","1484903562"
"San Pablo","San Pablo","37.9629","-122.3426","United States","US","USA","California","","31975","1840021529"
"Teapa","Teapa","17.5483","-92.9530","Mexico","MX","MEX","Tabasco","minor","29068","1484729901"
"Ulundi","Ulundi","-28.3350","31.4161","South Africa","ZA","ZAF","KwaZulu-Natal","","29132","1710823263"
"Pompeu","Pompeu","-19.2239","-44.9350","Brazil","BR","BRA","Minas Gerais","","29105","1076507348"
"Zug","Zug","47.1681","8.5169","Switzerland","CH","CHE","Zug","admin","30542","1756703208"
"Kaita","Kaita","34.3722","132.5360","Japan","JP","JPN","Hiroshima","","29793","1392868298"
"Mirzāpur","Mirzapur","24.1083","90.0917","Bangladesh","BD","BGD","Dhaka","","28602","1050315197"
"Benetúser","Benetuser","39.4250","-0.3961","Spain","ES","ESP","Valencia","","15493","1724800602"
"Lambersart","Lambersart","50.6500","3.0250","France","FR","FRA","Hauts-de-France","","27415","1250198806"
"Limeil-Brévannes","Limeil-Brevannes","48.7464","2.4883","France","FR","FRA","Île-de-France","","27833","1250943881"
"Navolato","Navolato","24.7656","-107.7019","Mexico","MX","MEX","Sinaloa","minor","28676","1484515879"
"Ithaca","Ithaca","42.4442","-76.5032","United States","US","USA","New York","","31819","1840000442"
"Rumuruti","Rumuruti","0.2600","36.5363","Kenya","KE","KEN","Laikipia","admin","31649","1404005234"
"Atlatlahucan","Atlatlahucan","18.9350","-98.9000","Mexico","MX","MEX","Morelos","minor","25232","1484784884"
"Jérémie","Jeremie","18.6500","-74.1167","Haiti","HT","HTI","Grand’Anse","admin","30917","1332612037"
"Daventry","Daventry","52.2578","-1.1628","United Kingdom","GB","GBR","Northamptonshire","","28123","1826794956"
"Babati","Babati","-4.2167","35.7500","Tanzania","TZ","TZA","Manyara","admin","30975","1834081851"
"Shumerlya","Shumerlya","55.5333","46.3833","Russia","RU","RUS","Chuvashiya","","29553","1643065597"
"Guyancourt","Guyancourt","48.7714","2.0739","France","FR","FRA","Île-de-France","","29471","1250611079"
"Long Branch","Long Branch","40.2965","-73.9915","United States","US","USA","New Jersey","","31734","1840003673"
"Santa Ana","Santa Ana","14.5277","121.0766","Philippines","PH","PHL","Taguig","","18057","1608461883"
"Mehdya","Mehdya","34.2597","-6.6500","Morocco","MA","MAR","Rabat-Salé-Kénitra","","28636","1504438304"
"Mchinji","Mchinji","-13.8167","32.9000","Malawi","MW","MWI","Mchinji","admin","28011","1454607053"
"Cambuslang","Cambuslang","55.8190","-4.1671","United Kingdom","GB","GBR","South Lanarkshire","","29100","1826755066"
"Giżycko","Gizycko","54.0400","21.7589","Poland","PL","POL","Warmińsko-Mazurskie","minor","29642","1616807901"
"Villeneuve-la-Garenne","Villeneuve-la-Garenne","48.9372","2.3278","France","FR","FRA","Île-de-France","","24097","1250937070"
"Ibanda","Ibanda","-0.1347","30.4950","Uganda","UG","UGA","Ibanda","admin","31316","1800636734"
"Contla","Contla","19.3333","-98.1667","Mexico","MX","MEX","Tlaxcala","minor","27610","1484059318"
"Khorugh","Khorugh","37.4833","71.5500","Tajikistan","TJ","TJK","Kŭhistoni Badakhshon","admin","30300","1762114045"
"Vleuten","Vleuten","52.1081","5.0150","Netherlands","NL","NLD","Utrecht","","27815","1528752483"
"Kuli","Kuli","24.7366","87.9426","India","IN","IND","West Bengal","","28092","1356864766"
"Banqiao","Banqiao","25.0143","121.4672","Taiwan","TW","TWN","New Taipei","admin","","1158987347"
"Chato","Chato","-2.6378","31.7669","Tanzania","TZ","TZA","Geita","","27776","1834766071"
"Ipu","Ipu","-4.3257","-40.7103","Brazil","BR","BRA","Ceará","","26678","1076972725"
"Shek Wai Kok","Shek Wai Kok","22.3753","114.1237","Hong Kong","HK","HKG","","","16605","1344833550"
"Voúla","Voula","37.8500","23.7500","Greece","GR","GRC","Attikí","minor","28364","1300243686"
"Bay Shore","Bay Shore","40.7288","-73.2495","United States","US","USA","New York","","31488","1840005017"
"Beldānga","Beldanga","23.9300","88.2500","India","IN","IND","West Bengal","","29205","1356073095"
"Saranga","Saranga","22.5400","88.2100","India","IN","IND","West Bengal","","25200","1356064506"
"Zaqatala","Zaqatala","41.6336","46.6433","Azerbaijan","AZ","AZE","Zaqatala","admin","31300","1031500858"
"Pando","Pando","-34.7167","-55.9583","Uruguay","UY","URY","Canelones","","25949","1858276269"
"Blackrock","Blackrock","53.3015","-6.1778","Ireland","IE","IRL","Dún Laoghaire-Rathdown","","28557","1372203613"
"San Pablo","San Pablo","9.9918","-84.0937","Costa Rica","CR","CRI","Heredia","","27671","1188253448"
"İmişli","Imisli","39.8697","48.0600","Azerbaijan","AZ","AZE","İmişli","admin","31310","1031686599"
"Siliana","Siliana","36.0819","9.3747","Tunisia","TN","TUN","Siliana","admin","31251","1788057426"
"Port Chester","Port Chester","41.0051","-73.6680","United States","US","USA","New York","","31317","1840004941"
"San Fernando","San Fernando","24.8504","-98.1600","Mexico","MX","MEX","Tamaulipas","minor","29171","1484830415"
"Szekszárd","Szekszard","46.3560","18.7038","Hungary","HU","HUN","Tolna","admin","30963","1348026284"
"Rhyl","Rhyl","53.3210","-3.4800","United Kingdom","GB","GBR","Denbighshire","","25149","1826387077"
"Bir Jdid","Bir Jdid","33.3737","-8.0002","Morocco","MA","MAR","Casablanca-Settat","","24136","1504415482"
"Cheung Chau","Cheung Chau","22.2106","114.0289","Hong Kong","HK","HKG","","","22740","1344565945"
"Castaños","Castanos","26.7833","-101.4167","Mexico","MX","MEX","Coahuila","minor","27314","1484472740"
"Wete","Wete","-5.0567","39.7281","Tanzania","TZ","TZA","Pemba North","admin","26450","1834217491"
"Péfki","Pefki","38.0667","23.8000","Greece","GR","GRC","Attikí","minor","21415","1300609636"
"Sterling","Sterling","39.0052","-77.4050","United States","US","USA","Virginia","","31202","1840024508"
"San Vicente dels Horts","San Vicente dels Horts","41.3932","2.0097","Spain","ES","ESP","Catalonia","","28115","1724724364"
"Nāravārikuppam","Naravarikuppam","13.1913","80.1847","India","IN","IND","Tamil Nādu","","24196","1356579086"
"Koprivnica","Koprivnica","46.1500","16.8167","Croatia","HR","HRV","Koprivničko-Križevačka Županija","admin","30854","1191924250"
"Břevnov","Brevnov","50.0833","14.3579","Czechia","CZ","CZE","Středočeský Kraj","","26275","1203462593"
"Oceanside","Oceanside","40.6328","-73.6364","United States","US","USA","New York","","31182","1840005246"
"Leyton","Leyton","51.5700","-0.0150","United Kingdom","GB","GBR","Waltham Forest","","14184","1826843295"
"Tuxpan","Tuxpan","19.5661","-100.4625","Mexico","MX","MEX","Michoacán","minor","27371","1484359180"
"Burlingame","Burlingame","37.5859","-122.3667","United States","US","USA","California","","31142","1840018925"
"Arcueil","Arcueil","48.8075","2.3361","France","FR","FRA","Île-de-France","","21788","1250317790"
"Ystad","Ystad","55.4167","13.8333","Sweden","SE","SWE","Skåne","minor","28985","1752657171"
"Ashington","Ashington","55.1810","-1.5680","United Kingdom","GB","GBR","Northumberland","","27764","1826789902"
"Congleton","Congleton","53.1620","-2.2170","United Kingdom","GB","GBR","Cheshire East","","28497","1826471602"
"Eaubonne","Eaubonne","48.9922","2.2806","France","FR","FRA","Île-de-France","","25335","1250854033"
"Soissons","Soissons","49.3817","3.3236","France","FR","FRA","Hauts-de-France","minor","28712","1250109043"
"Mirganj","Mirganj","26.3638","84.3640","India","IN","IND","Bihār","","26240","1356157202"
"Aïn Taoujdat","Ain Taoujdat","33.9333","-5.2167","Morocco","MA","MAR","Fès-Meknès","","28288","1504497923"
"West Falls Church","West Falls Church","38.8648","-77.1878","United States","US","USA","Virginia","","31023","1840006117"
"Diffa","Diffa","13.3171","12.6089","Niger","NE","NER","Diffa","admin","30988","1562050464"
"Lomme","Lomme","50.6455","2.9876","France","FR","FRA","Hauts-de-France","","28006","1250753691"
"Ixhuatlancillo","Ixhuatlancillo","18.9000","-97.1500","Mexico","MX","MEX","Veracruz","minor","24896","1484287458"
"Dieppe","Dieppe","49.9200","1.0800","France","FR","FRA","Normandie","minor","28561","1250006284"
"Desnogorsk","Desnogorsk","54.1500","33.2833","Russia","RU","RUS","Smolenskaya Oblast’","","27771","1643598979"
"Udomlya","Udomlya","57.8833","35.0000","Russia","RU","RUS","Tverskaya Oblast’","","28119","1643942215"
"Sabirabad","Sabirabad","40.0128","48.4789","Azerbaijan","AZ","AZE","Sabirabad","admin","30918","1031302623"
"Kayunga","Kayunga","0.7033","32.9036","Uganda","UG","UGA","Kayunga","admin","30000","1800678001"
"Martorell","Martorell","41.4744","1.9305","Spain","ES","ESP","Catalonia","","28684","1724754042"
"Ridley","Ridley","39.8854","-75.3280","United States","US","USA","Pennsylvania","","30917","1840142587"
"Mégrine","Megrine","36.7667","10.2333","Tunisia","TN","TUN","Ben Arous","","26720","1788569609"
"Tung Tau Tsuen","Tung Tau Tsuen","22.3334","114.1924","Hong Kong","HK","HKG","","","15562","1344000062"
"Ungheni","Ungheni","47.2167","27.8167","Moldova","MD","MDA","Ungheni","admin","30804","1498687533"
"Trípoli","Tripoli","37.5167","22.3833","Greece","GR","GRC","Pelopónnisos","admin","30866","1300131630"
"Catarroja","Catarroja","39.4028","-0.4044","Spain","ES","ESP","Valencia","","28679","1724221843"
"Čakovec","Cakovec","46.3858","16.4333","Croatia","HR","HRV","Međimurska Županija","admin","30455","1191424625"
"Vīrapāndi","Virapandi","11.0625","77.3508","India","IN","IND","Tamil Nādu","","28839","1356165597"
"Longbridge","Longbridge","52.3950","-1.9790","United Kingdom","GB","GBR","Birmingham","","25410","1826461714"
"Bregenz","Bregenz","47.5050","9.7492","Austria","AT","AUT","Vorarlberg","admin","29806","1040174258"
"Bearsden","Bearsden","55.9192","-4.3332","United Kingdom","GB","GBR","East Dunbartonshire","","28120","1826065116"
"Parkville","Parkville","39.3832","-76.5519","United States","US","USA","Maryland","","30783","1840005685"
"Siraha","Siraha","26.6528","86.2069","Nepal","NP","NPL","","","28442","1524369856"
"San Carlos","San Carlos","37.4982","-122.2677","United States","US","USA","California","","30754","1840021550"
"Melton Mowbray","Melton Mowbray","52.7661","-0.8860","United Kingdom","GB","GBR","Leicestershire","","27158","1826081366"
"Avellaneda","Avellaneda","-34.6625","-58.3678","Argentina","AR","ARG","Buenos Aires","minor","24313","1032478683"
"Santa Paula","Santa Paula","34.3545","-119.0656","United States","US","USA","California","","30741","1840021842"
"Moyale","Moyale","3.5270","39.0560","Kenya","KE","KEN","Marsabit","","25000","1404689643"
"Qiman al ‘Arūs","Qiman al \`Arus","29.3005","31.1683","Egypt","EG","EGY","Banī Suwayf","","28246","1818279617"
"Jamay","Jamay","20.2944","-102.7097","Mexico","MX","MEX","Jalisco","minor","24753","1484510036"
"Farnworth","Farnworth","53.5452","-2.3999","United Kingdom","GB","GBR","Bolton","","26939","1826257074"
"Tuxpan","Tuxpan","21.8667","-105.4500","Mexico","MX","MEX","Nayarit","minor","26115","1484274950"
"Simri Bakhriārpur","Simri Bakhriarpur","25.7216","86.5937","India","IN","IND","Bihār","","28588","1356016795"
"Krabi","Krabi","8.0592","98.9189","Thailand","TH","THA","Krabi","admin","30499","1764587855"
"Carouge","Carouge","46.1833","6.1333","Switzerland","CH","CHE","Genève","","22336","1756165206"
"Miami Lakes","Miami Lakes","25.9125","-80.3214","United States","US","USA","Florida","","30582","1840017275"
"Puerto Escondido","Puerto Escondido","15.8619","-97.0672","Mexico","MX","MEX","Oaxaca","","25902","1484644846"
"Lamu","Lamu","-2.2694","40.9022","Kenya","KE","KEN","Lamu","admin","25385","1404163131"
"Kefar Yona","Kefar Yona","32.3171","34.9358","Israel","IL","ISR","Central","","28025","1376924544"
"Ninomiya","Ninomiya","35.2995","139.2555","Japan","JP","JPN","Kanagawa","","27536","1392269744"
"Āsosa","Asosa","10.0667","34.5167","Ethiopia","ET","ETH","Bīnshangul Gumuz","admin","30512","1231491184"
"Nāsriganj","Nasriganj","25.0500","84.3333","India","IN","IND","Bihār","","27490","1356240406"
"Salyan","Salyan","39.5950","48.9792","Azerbaijan","AZ","AZE","Salyan","admin","30396","1031301869"
"Shenley Brook End","Shenley Brook End","52.0090","-0.7890","United Kingdom","GB","GBR","Milton Keynes","","25828","1826086234"
"Tomares","Tomares","37.3764","-6.0458","Spain","ES","ESP","Andalusia","","25370","1724916166"
"Tecax","Tecax","20.2019","-89.2881","Mexico","MX","MEX","Yucatán","","25751","1484782521"
"San Lorenzo","San Lorenzo","37.6733","-122.1332","United States","US","USA","California","","30420","1840018912"
"Heemstede","Heemstede","52.3500","4.6167","Netherlands","NL","NLD","Noord-Holland","minor","27545","1528827638"
"Point Fortin","Point Fortin","10.1667","-61.6667","Trinidad and Tobago","TT","TTO","Point Fortin","admin","29579","1780702349"
"Boscoreale","Boscoreale","40.7667","14.4833","Italy","IT","ITA","Campania","","27927","1380991443"
"Camaligan","Camaligan","13.6208","123.1656","Philippines","PH","PHL","Camarines Sur","","25036","1608478686"
"Kantai","Kantai","26.2142","85.2975","India","IN","IND","Bihār","","27549","1356137612"
"Kętrzyn","Ketrzyn","54.0833","21.3833","Poland","PL","POL","Warmińsko-Mazurskie","minor","27827","1616659048"
"Jaynagar-Majilpur","Jaynagar-Majilpur","22.1752","88.4201","India","IN","IND","West Bengal","","25922","1356557030"
"Mizumaki","Mizumaki","33.8548","130.6950","Japan","JP","JPN","Fukuoka","","27822","1392003278"
"Consett","Consett","54.8500","-1.8300","United Kingdom","GB","GBR","Durham","","25812","1826586872"
"Bilston","Bilston","52.5660","-2.0728","United Kingdom","GB","GBR","Wolverhampton","","25576","1826253660"
"Miacatlán","Miacatlan","18.7722","-99.3547","Mexico","MX","MEX","Morelos","minor","26713","1484216622"
"Mount Hagen","Mount Hagen","-5.8600","144.2400","Papua New Guinea","PG","PNG","Western Highlands","admin","29765","1598400611"
"Los Reyes de Juárez","Los Reyes de Juarez","18.9267","-97.7983","Mexico","MX","MEX","Puebla","minor","27454","1484937929"
"Daulatkhān","Daulatkhan","22.6000","90.7472","Bangladesh","BD","BGD","Barishal","","27514","1050010402"
"Nogales","Nogales","18.8167","-97.1667","Mexico","MX","MEX","Veracruz","minor","21113","1484829832"
"Pakwach","Pakwach","2.4619","31.4983","Uganda","UG","UGA","Pakwach","admin","28700","1800204374"
"Golo-Djigbé","Golo-Djigbe","6.5403","2.3253","Benin","BJ","BEN","Atlantique","","28103","1204048164"
"Perunkalattu","Perunkalattu","12.9182","80.0824","India","IN","IND","Tamil Nādu","","25864","1356132023"
"Kafr Baţnā","Kafr Batna","33.5000","36.3667","Syria","SY","SYR","Rīf Dimashq","minor","22535","1760528656"
"Armilla","Armilla","37.1500","-3.6167","Spain","ES","ESP","Andalusia","","24629","1724964261"
"Chatan","Chatan","26.3200","127.7639","Japan","JP","JPN","Okinawa","","28167","1392411015"
"Garbagnate Milanese","Garbagnate Milanese","45.5771","9.0814","Italy","IT","ITA","Lombardy","","27155","1380031147"
"As Sarw","As Sarw","31.2387","31.6538","Egypt","EG","EGY","Dumyāţ","","27743","1818966040"
"Mutsamudu","Mutsamudu","-12.1675","44.3939","Comoros","KM","COM","Anjouan","admin","30000","1174036583"
"Cahul","Cahul","45.9167","28.1836","Moldova","MD","MDA","Cahul","admin","30018","1498020356"
"Cenon","Cenon","44.8578","-0.5317","France","FR","FRA","Nouvelle-Aquitaine","","25496","1250260659"
"Bloxwich","Bloxwich","52.6140","-2.0040","United Kingdom","GB","GBR","Walsall","","25401","1826492004"
"Geldrop","Geldrop","51.4222","5.5578","Netherlands","NL","NLD","Noord-Brabant","minor","28001","1528955608"
"Tahla","Tahla","34.0500","-4.4200","Morocco","MA","MAR","Fès-Meknès","","27729","1504092591"
"Pānchla","Panchla","22.5400","88.1400","India","IN","IND","West Bengal","","26432","1356068400"
"Vicente López","Vicente Lopez","-34.5333","-58.4750","Argentina","AR","ARG","Buenos Aires","","22419","1032549688"
"Bournville","Bournville","52.4299","-1.9355","United Kingdom","GB","GBR","Birmingham","","25938","1826130836"
"Billericay","Billericay","51.6280","0.4184","United Kingdom","GB","GBR","Essex","","27998","1826245369"
"Middletown","Middletown","41.4459","-74.4236","United States","US","USA","New York","","30086","1840000585"
"Otjiwarongo","Otjiwarongo","-20.4642","16.6528","Namibia","NA","NAM","Otjozondjupa","admin","28249","1516719545"
"Dübendorf","Dubendorf","47.4167","8.6167","Switzerland","CH","CHE","Zürich","","28002","1756017706"
"Charentsavan","Charentsavan","40.4097","44.6431","Armenia","AM","ARM","Kotayk’","","25039","1051304472"
"Sai Mai","Sai Mai","13.8882","100.4620","Thailand","TH","THA","Krung Thep Maha Nakhon","minor","23316","1764423311"
"Subotica","Subotica","46.1003","19.6656","Serbia","RS","SRB","Subotica","admin","29919","1688081964"
"Änew","Anew","37.8833","58.5333","Turkmenistan","TM","TKM","Ahal","admin","30000","1795817680"
"Treinta y Tres","Treinta y Tres","-33.2333","-54.3833","Uruguay","UY","URY","Treinta y Tres","admin","25477","1858879204"
"Gorleston-on-Sea","Gorleston-on-Sea","52.5757","1.7235","United Kingdom","GB","GBR","Norfolk","","24785","1826692101"
"Nutley","Nutley","40.8192","-74.1571","United States","US","USA","New Jersey","","29931","1840131593"
"Stung Treng","Stung Treng","13.5167","105.9667","Cambodia","KH","KHM","Stung Treng","admin","29665","1116286673"
"Muñiz","Muniz","-34.5333","-58.7000","Argentina","AR","ARG","Buenos Aires","","26221","1032339781"
"Aldridge","Aldridge","52.6060","-1.9179","United Kingdom","GB","GBR","Walsall","","26988","1826685347"
"East Palo Alto","East Palo Alto","37.4671","-122.1352","United States","US","USA","California","","29888","1840020301"
"Newton Aycliffe","Newton Aycliffe","54.6200","-1.5800","United Kingdom","GB","GBR","Durham","","26633","1826956989"
"Saint-Sébastien-sur-Loire","Saint-Sebastien-sur-Loire","47.2081","-1.5014","France","FR","FRA","Pays de la Loire","","27493","1250158180"
"Dietikon","Dietikon","47.4000","8.4000","Switzerland","CH","CHE","Zürich","","26936","1756546516"
"Miahuatlán","Miahuatlan","18.5667","-97.4333","Mexico","MX","MEX","Puebla","minor","25228","1484649521"
"Hakha","Hakha","22.6455","93.6050","Myanmar","MM","MMR","Chin State","admin","29800","1104739581"
"Arapoti","Arapoti","-24.1578","-49.8269","Brazil","BR","BRA","Paraná","","25855","1076793835"
"Ambohijanaka","Ambohijanaka","-18.9833","47.5500","Madagascar","MG","MDG","Antananarivo","","27088","1450475347"
"Douglas","Douglas","51.8764","-8.4358","Ireland","IE","IRL","Cork","","26883","1372287368"
"Villeparisis","Villeparisis","48.9503","2.6025","France","FR","FRA","Île-de-France","","26580","1250133123"
"Camas","Camas","37.4020","-6.0332","Spain","ES","ESP","Andalusia","","27490","1724068126"
"Sucy-en-Brie","Sucy-en-Brie","48.7697","2.5228","France","FR","FRA","Île-de-France","","27157","1250757045"
"Colonia del Sacramento","Colonia del Sacramento","-34.4714","-57.8442","Uruguay","UY","URY","Colonia","admin","26231","1858338569"
"Upminster","Upminster","51.5557","0.2512","United Kingdom","GB","GBR","Havering","","25361","1826720044"
"Dalsingh Sarai","Dalsingh Sarai","25.6680","85.8364","India","IN","IND","Bihār","","26658","1356189011"
"Kyegegwa","Kyegegwa","0.4803","31.0547","Uganda","UG","UGA","Kyegegwa","admin","29400","1800719333"
"Doba","Doba","8.6600","16.8500","Chad","TD","TCD","Logone Oriental","admin","29597","1148367091"
"Ağdaş","Agdas","40.6500","47.4761","Azerbaijan","AZ","AZE","Ağdaş","admin","29600","1031916654"
"Oak Park","Oak Park","42.4649","-83.1824","United States","US","USA","Michigan","","29677","1840002424"
"Westhoughton","Westhoughton","53.5490","-2.5290","United Kingdom","GB","GBR","Bolton","","24974","1826134453"
"La Madeleine","La Madeleine","50.6558","3.0710","France","FR","FRA","Hauts-de-France","","21968","1250109731"
"Northfield","Northfield","52.4080","-1.9630","United Kingdom","GB","GBR","Birmingham","","25707","1826476476"
"Hindley","Hindley","53.5355","-2.5658","United Kingdom","GB","GBR","Wigan","","25001","1826824753"
"Chiavari","Chiavari","44.3167","9.3333","Italy","IT","ITA","Liguria","","27410","1380138569"
"Cheadle Hulme","Cheadle Hulme","53.3761","-2.1897","United Kingdom","GB","GBR","Stockport","","26479","1826077491"
"Cardito","Cardito","40.9500","14.3000","Italy","IT","ITA","Campania","","22601","1380120364"
"Leiderdorp","Leiderdorp","52.1667","4.5333","Netherlands","NL","NLD","Zuid-Holland","minor","27377","1528990353"
"Mzimba","Mzimba","-11.9000","33.6000","Malawi","MW","MWI","Mzimba","admin","26096","1454007077"
"Nuwara Eliya","Nuwara Eliya","6.9667","80.7667","Sri Lanka","LK","LKA","Central","","27500","1144687416"
"Māmidālapādu","Mamidalapadu","15.8310","78.0500","India","IN","IND","Andhra Pradesh","","26694","1356391664"
"Rahway","Rahway","40.6077","-74.2807","United States","US","USA","New Jersey","","29582","1840001091"
"Melrose","Melrose","42.4556","-71.0590","United States","US","USA","Massachusetts","","29567","1840000432"
"Katima Mulilo","Katima Mulilo","-17.5039","24.2750","Namibia","NA","NAM","Zambezi","admin","28699","1516068788"
"Saint-Gratien","Saint-Gratien","48.9719","2.2828","France","FR","FRA","Île-de-France","","20895","1250437632"
"Mortsel","Mortsel","51.1667","4.4667","Belgium","BE","BEL","Flanders","","26157","1056283690"
"Paracuaro","Paracuaro","19.1464","-102.2194","Mexico","MX","MEX","Michoacán","","26789","1484022148"
"Haverhill","Haverhill","52.0800","0.4400","United Kingdom","GB","GBR","Suffolk","","27041","1826015750"
"Fenoarivo","Fenoarivo","-18.9333","47.4333","Madagascar","MG","MDG","Antananarivo","","25675","1450445815"
"Kings Norton","Kings Norton","52.4072","-1.9272","United Kingdom","GB","GBR","Birmingham","","24380","1826743792"
"Bačka Palanka","Backa Palanka","45.2500","19.4000","Serbia","RS","SRB","Bačka Palanka","admin","29449","1688918464"
"Chalatenango","Chalatenango","14.0333","-88.9333","El Salvador","SV","SLV","Chalatenango","admin","29271","1222515741"
"Sèvres","Sevres","48.8239","2.2117","France","FR","FRA","Île-de-France","","23463","1250240744"
"Vallauris","Vallauris","43.5805","7.0538","France","FR","FRA","Provence-Alpes-Côte d’Azur","","27364","1250016351"
"Balham","Balham","51.4434","-0.1525","United Kingdom","GB","GBR","Wandsworth","","14751","1826329398"
"Pūnch","Punch","33.7703","74.0925","India","IN","IND","Jammu and Kashmīr","","26854","1356708972"
"Laurel","Laurel","39.0949","-76.8622","United States","US","USA","Maryland","","29438","1840005976"
"Taverny","Taverny","49.0264","2.2275","France","FR","FRA","Île-de-France","","26872","1250852016"
"Suisun City","Suisun City","38.2473","-122.0091","United States","US","USA","California","","29436","1840028115"
"Linden","Linden","6.0000","-58.3000","Guyana","GY","GUY","Upper Demerara-Berbice","admin","29298","1328531190"
"Estoril","Estoril","38.7042","-9.3983","Portugal","PT","PRT","Lisboa","","26399","1620528384"
"Rocha","Rocha","-34.4833","-54.3500","Uruguay","UY","URY","Rocha","admin","25422","1858936449"
"Kamphaeng Phet","Kamphaeng Phet","16.4811","99.5222","Thailand","TH","THA","Kamphaeng Phet","admin","29178","1764367755"
"Coulsdon","Coulsdon","51.3211","-0.1386","United Kingdom","GB","GBR","Croydon","","25695","1826373748"
"Guadalupe","Guadalupe","9.9494","-84.0512","Costa Rica","CR","CRI","San José","","20663","1188603819"
"Rafael Delgado","Rafael Delgado","18.8167","-97.0667","Mexico","MX","MEX","Veracruz","minor","23112","1484016423"
"Nokha","Nokha","25.1015","84.1228","India","IN","IND","Bihār","","27302","1356610662"
"Newton Mearns","Newton Mearns","55.7716","-4.3347","United Kingdom","GB","GBR","East Renfrewshire","","26600","1826713585"
"Burbank","Burbank","41.7444","-87.7686","United States","US","USA","Illinois","","29312","1840007022"
"Golden Gate","Golden Gate","26.1844","-81.7031","United States","US","USA","Florida","","29271","1840014228"
"Hackney","Hackney","51.5414","-0.0266","United Kingdom","GB","GBR","Hackney","","11734","1826413202"
"Vichy","Vichy","46.1278","3.4267","France","FR","FRA","Auvergne-Rhône-Alpes","minor","24980","1250084403"
"Siquijor","Siquijor","9.1800","123.5800","Philippines","PH","PHL","Siquijor","admin","28915","1608692949"
"Hunucmá","Hunucma","21.0153","-89.8744","Mexico","MX","MEX","Yucatán","minor","24910","1484800428"
"Qualiano","Qualiano","40.9167","14.1500","Italy","IT","ITA","Campania","","25755","1380575538"
"Brunoy","Brunoy","48.6979","2.5044","France","FR","FRA","Île-de-France","","25354","1250815725"
"Penonomé","Penonome","8.5187","-80.3553","Panama","PA","PAN","Coclé","admin","28766","1591602960"
"Ghatāro Chaturbhuj","Ghataro Chaturbhuj","25.8146","85.1862","India","IN","IND","Bihār","","26594","1356014177"
"Qahā","Qaha","30.2833","31.2000","Egypt","EG","EGY","Al Qalyūbīyah","","26694","1818255123"
"Mponela","Mponela","-13.5167","33.7167","Malawi","MW","MWI","Dowa","","24543","1454096426"
"Champs-Sur-Marne","Champs-Sur-Marne","48.8529","2.6027","France","FR","FRA","Île-de-France","","25654","1250001308"
"Englewood","Englewood","40.8917","-73.9736","United States","US","USA","New Jersey","","29129","1840003546"
"Samut Songkhram","Samut Songkhram","13.4097","100.0017","Thailand","TH","THA","Samut Songkhram","admin","28493","1764305010"
"East Niles","East Niles","35.3683","-118.9225","United States","US","USA","California","","29110","1840150304"
"Ryde","Ryde","50.7271","-1.1618","United Kingdom","GB","GBR","Isle of Wight","","24847","1826600816"
"San Pedro","San Pedro","9.9332","-84.0470","Costa Rica","CR","CRI","San José","","23977","1188342624"
"Sogrāha","Sograha","25.4798","85.9992","India","IN","IND","Bihār","","26205","1356350052"
"Loyola Heights","Loyola Heights","14.6403","121.0744","Philippines","PH","PHL","Quezon","","18884","1608400351"
"Koelwār","Koelwar","25.5805","84.7975","India","IN","IND","Bihār","","26304","1356937599"
"Ginan","Ginan","35.3896","136.7825","Japan","JP","JPN","Gifu","","25752","1392003216"
"Bishop Auckland","Bishop Auckland","54.6630","-1.6760","United Kingdom","GB","GBR","Durham","","25455","1826422730"
"Apatin","Apatin","45.6667","18.9833","Serbia","RS","SRB","Apatin","admin","28929","1688081748"
"Bāruni","Baruni","25.4751","85.9681","India","IN","IND","Bihār","","26117","1356554204"
"Louang Namtha","Louang Namtha","20.9500","101.4000","Laos","LA","LAO","Louangnamtha","admin","28551","1418920136"
"Armentières","Armentieres","50.6881","2.8811","France","FR","FRA","Hauts-de-France","","24909","1250737647"
"Arriaga","Arriaga","16.2361","-93.8995","Mexico","MX","MEX","Chiapas","minor","25366","1484409481"
"Pakxan","Pakxan","18.3964","103.6558","Laos","LA","LAO","Bolikhamxai","admin","27404","1418088090"
"Manga","Manga","11.6667","-1.0667","Burkina Faso","BF","BFA","Centre-Sud","admin","28612","1854485968"
"Wellington","Wellington","52.7001","-2.5157","United Kingdom","GB","GBR","Telford and Wrekin","","25554","1826979862"
"Altepexi","Altepexi","18.3676","-97.3004","Mexico","MX","MEX","Puebla","minor","21100","1484498863"
"Buri Ram","Buri Ram","14.9942","103.1022","Thailand","TH","THA","Buri Ram","admin","28613","1764141031"
"Ölgiy","Olgiy","48.9683","89.9686","Mongolia","MN","MNG","Bayan-Ölgiy","admin","28496","1496290857"
"Comalapa","Comalapa","15.6602","-92.1411","Mexico","MX","MEX","Chiapas","minor","21727","1484741837"
"Clydebank","Clydebank","55.8997","-4.4006","United Kingdom","GB","GBR","West Dunbartonshire","","26320","1826705684"
"Tixtla de Guerrero","Tixtla de Guerrero","17.5667","-99.4000","Mexico","MX","MEX","Guerrero","minor","21720","1484176714"
"Mons-en-Baroeul","Mons-en-Baroeul","50.6369","3.1103","France","FR","FRA","Hauts-de-France","","21277","1250409231"
"Iheddadene","Iheddadene","35.1500","-2.9667","Morocco","MA","MAR","Oriental","","26582","1504836101"
"Gosforth","Gosforth","55.0070","-1.6230","United Kingdom","GB","GBR","Newcastle upon Tyne","","23620","1826878501"
"Élancourt","Elancourt","48.7847","1.9589","France","FR","FRA","Île-de-France","","25624","1250293935"
"Betio","Betio","1.3500","172.9333","Kiribati","KI","KIR","","","17356","1296879625"
"Jālhalli","Jalhalli","13.0333","77.5500","India","IN","IND","Karnātaka","","13063","1356033556"
"Missour","Missour","33.0500","-3.9833","Morocco","MA","MAR","Fès-Meknès","","25584","1504694053"
"Mendefera","Mendefera","14.8833","38.8167","Eritrea","ER","ERI","Debub","admin","28492","1232751882"
"Barnstaple","Barnstaple","51.0800","-4.0600","United Kingdom","GB","GBR","Devon","","23976","1826606982"
"Drexel Hill","Drexel Hill","39.9495","-75.3038","United States","US","USA","Pennsylvania","","28538","1840005550"
"Dunleary","Dunleary","53.3000","-6.1400","Ireland","IE","IRL","Dún Laoghaire-Rathdown","admin","23857","1372038958"
"Salua","Salua","22.6100","88.2700","India","IN","IND","West Bengal","","15171","1356550174"
"Melíssia","Melissia","38.0500","23.8333","Greece","GR","GRC","Attikí","minor","22741","1300481194"
"Princes Town","Princes Town","10.2667","-61.3833","Trinidad and Tobago","TT","TTO","Princes Town","admin","28335","1780714817"
"Māvelikara","Mavelikara","9.2670","76.5500","India","IN","IND","Kerala","","26421","1356198372"
"Gedera","Gedera","31.8139","34.7783","Israel","IL","ISR","Central","","26217","1376689197"
"Frankfort","Frankfort","38.1924","-84.8643","United States","US","USA","Kentucky","admin","28479","1840013210"
"Wallington","Wallington","51.3647","-0.1403","United Kingdom","GB","GBR","Sutton","","20850","1826952560"
"Koulikoro","Koulikoro","12.8833","-7.5500","Mali","ML","MLI","Koulikoro","admin","23919","1466411645"
"Le Bouscat","Le Bouscat","44.8651","-0.5996","France","FR","FRA","Nouvelle-Aquitaine","","23924","1250100380"
"Tapiales","Tapiales","-34.7058","-58.5047","Argentina","AR","ARG","Buenos Aires","","23586","1032831289"
"Market Harborough","Market Harborough","52.4775","-0.9206","United Kingdom","GB","GBR","Leicestershire","","22911","1826292544"
"Ocatlán","Ocatlan","19.3167","-98.2283","Mexico","MX","MEX","Tlaxcala","","22082","1484715262"
"Kočani","Kocani","41.9167","22.4125","Macedonia","MK","MKD","Kočani","admin","28330","1807422302"
"Cartago","Cartago","9.8667","-83.9167","Costa Rica","CR","CRI","Cartago","admin","22775","1188911724"
"Eṭ Ṭīra","Et Tira","32.2322","34.9483","Israel","IL","ISR","Central","","26200","1376315793"
"Temerin","Temerin","45.4167","19.8833","Serbia","RS","SRB","Temerin","admin","28287","1688171551"
"Cormeilles-en-Parisis","Cormeilles-en-Parisis","48.9739","2.2014","France","FR","FRA","Île-de-France","","25406","1250599649"
"Zawyat ech Cheïkh","Zawyat ech Cheikh","32.6414","-5.9206","Morocco","MA","MAR","Béni Mellal-Khénifra","","25388","1504071186"
"Shīshgarh","Shishgarh","28.7200","79.3200","India","IN","IND","Uttar Pradesh","","25815","1356494610"
"Mitú","Mitu","1.1983","-70.1733","Colombia","CO","COL","Vaupés","admin","28382","1170846096"
"Rēzekne","Rezekne","56.5127","27.3427","Latvia","LV","LVA","Rēzekne","admin","26839","1428895373"
"Karunāgapalli","Karunagapalli","9.0544","76.5353","India","IN","IND","Kerala","","25336","1356160595"
"Droylsden","Droylsden","53.4828","-2.1582","United Kingdom","GB","GBR","Tameside","","23689","1826178927"
"Buwenge","Buwenge","0.6503","33.1703","Uganda","UG","UGA","Jinja","","24200","1800722293"
"Orly","Orly","48.7439","2.3928","France","FR","FRA","Île-de-France","","24627","1250660007"
"Le Grand-Quevilly","Le Grand-Quevilly","49.4072","1.0531","France","FR","FRA","Normandie","","25963","1250398185"
"Oegstgeest","Oegstgeest","52.1833","4.4667","Netherlands","NL","NLD","Zuid-Holland","minor","25064","1528455119"
"Alençon","Alencon","48.4306","0.0931","France","FR","FRA","Normandie","minor","25870","1250000395"
"Bergenfield","Bergenfield","40.9236","-73.9982","United States","US","USA","New Jersey","","28282","1840000893"
"Thornaby on Tees","Thornaby on Tees","54.5585","-1.3025","United Kingdom","GB","GBR","Stockton-on-Tees","","24741","1826706023"
"Vevey","Vevey","46.4667","6.8500","Switzerland","CH","CHE","Vaud","","19891","1756579162"
"Zaouiet Sousse","Zaouiet Sousse","35.7833","10.6333","Tunisia","TN","TUN","Sousse","","25000","1788010481"
"Vilāchcheri","Vilachcheri","9.8937","78.0603","India","IN","IND","Tamil Nādu","","23077","1356236924"
"Clifton","Clifton","52.9040","-1.1770","United Kingdom","GB","GBR","Nottingham","","22749","1826911348"
"Erdington","Erdington","52.5236","-1.8378","United Kingdom","GB","GBR","Birmingham","","22828","1826744205"
"Losino-Petrovskiy","Losino-Petrovskiy","55.8744","38.2017","Russia","RU","RUS","Moskovskaya Oblast’","","25424","1643294187"
"Joinville-le-Pont","Joinville-le-Pont","48.8214","2.4728","France","FR","FRA","Île-de-France","","19652","1250524493"
"Dharmapuram","Dharmapuram","8.3839","77.1284","India","IN","IND","Tamil Nādu","","25982","1356218555"
"Kathu","Kathu","7.9112","98.3475","Thailand","TH","THA","Phuket","minor","26078","1764733770"
"Ouésso","Ouesso","1.6136","16.0517","Congo (Brazzaville)","CG","COG","Sangha","admin","28179","1178364266"
"Alboraya","Alboraya","39.5000","-0.3522","Spain","ES","ESP","Valencia","","25149","1724241743"
"Plymstock","Plymstock","50.3569","-4.0900","United Kingdom","GB","GBR","Plymouth","","24103","1826596908"
"Sāndi","Sandi","27.3000","79.9500","India","IN","IND","Uttar Pradesh","","26007","1356554804"
"Sitrah","Sitrah","26.1200","50.6500","Bahrain","BH","BHR","Al ‘Āşimah","","20000","1048449399"
"Phulpur","Phulpur","24.9550","90.3573","Bangladesh","BD","BGD","Mymensingh","","25628","1050550788"
"Stratton Saint Margaret","Stratton Saint Margaret","51.5860","-1.7620","United Kingdom","GB","GBR","Swindon","","22698","1826478043"
"Don Bosco","Don Bosco","-34.7000","-58.2833","Argentina","AR","ARG","Buenos Aires","","20876","1032701626"
"Mytilíni","Mytilini","39.1000","26.5500","Greece","GR","GRC","Vóreio Aigaío","admin","27871","1300101706"
"Desamparados","Desamparados","10.0315","-84.1877","Costa Rica","CR","CRI","Alajuela","","26109","1188458855"
"Ewa Gentry","Ewa Gentry","21.3344","-158.0262","United States","US","USA","Hawaii","","28125","1840029577"
"Belmont","Belmont","37.5154","-122.2953","United States","US","USA","California","","28109","1840018924"
"Swinton","Swinton","53.5122","-2.3412","United Kingdom","GB","GBR","Salford","","22931","1826572295"
"Deuil-la-Barre","Deuil-la-Barre","48.9767","2.3272","France","FR","FRA","Île-de-France","","22165","1250595652"
"Hamar","Hamar","60.7945","11.0679","Norway","NO","NOR","Innlandet","admin","27947","1578111692"
"Droitwich","Droitwich","52.2670","-2.1530","United Kingdom","GB","GBR","Worcestershire","","25027","1826540486"
"Arcahaie","Arcahaie","18.7718","-72.5123","Haiti","HT","HTI","Ouest","","24600","1332347269"
"Masrakh","Masrakh","26.1054","84.8026","India","IN","IND","Bihār","","25936","1356006359"
"Biarritz","Biarritz","43.4800","-1.5600","France","FR","FRA","Nouvelle-Aquitaine","","25787","1250324713"
"Renens","Renens","46.5333","6.5833","Switzerland","CH","CHE","Vaud","minor","20927","1756264043"
"Simaria","Simaria","25.4221","86.0060","India","IN","IND","Bihār","","24998","1356745818"
"Sonzacate","Sonzacate","13.7356","-89.7161","El Salvador","SV","SLV","Sonsonate","","25005","1222944408"
"Sāhibpur Kamāl","Sahibpur Kamal","25.4167","86.3901","India","IN","IND","Bihār","","25128","1356099280"
"Ramotswa","Ramotswa","-24.8667","25.8167","Botswana","BW","BWA","South East","admin","27760","1072070629"
"Mililani Town","Mililani Town","21.4465","-158.0147","United States","US","USA","Hawaii","","27974","1840023249"
"Harborne","Harborne","52.4600","-1.9500","United Kingdom","GB","GBR","Birmingham","","23001","1826937093"
"Nørresundby","Norresundby","57.0667","9.9167","Denmark","DK","DNK","Nordjylland","","23718","1208465908"
"Lobatse","Lobatse","-25.2167","25.6667","Botswana","BW","BWA","Lobatse","admin","26900","1072740062"
"Penarth","Penarth","51.4300","-3.1700","United Kingdom","GB","GBR","Vale of Glamorgan, The","","22083","1826447166"
"Reisterstown","Reisterstown","39.4550","-76.8140","United States","US","USA","Maryland","","27917","1840005689"
"Cherán","Cheran","19.6833","-101.9500","Mexico","MX","MEX","Michoacán","minor","19081","1484573730"
"Sombrerete","Sombrerete","23.6333","-103.6397","Mexico","MX","MEX","Zacatecas","minor","21702","1484007324"
"Muggiò","Muggio","45.6000","9.2333","Italy","IT","ITA","Lombardy","","23581","1380948758"
"Batken","Batken","40.0667","70.8167","Kyrgyzstan","KG","KGZ","Batken","admin","27730","1417022726"
"Ban Bang Phun","Ban Bang Phun","13.9968","100.5891","Thailand","TH","THA","Pathum Thani","","24625","1764792338"
"San Francisco","San Francisco","9.9083","-84.0582","Costa Rica","CR","CRI","San José","","20209","1188068837"
"Voorschoten","Voorschoten","52.1333","4.4500","Netherlands","NL","NLD","Zuid-Holland","minor","25650","1528045631"
"Easton","Easton","40.6858","-75.2209","United States","US","USA","Pennsylvania","","27860","1840000970"
"Béthune","Bethune","50.5303","2.6408","France","FR","FRA","Hauts-de-France","minor","25193","1250837552"
"Bongor","Bongor","10.2806","15.3722","Chad","TD","TCD","Mayo-Kebbi-Est","admin","27770","1148833900"
"Saint-Nicolas","Saint-Nicolas","50.6333","5.5333","Belgium","BE","BEL","Wallonia","","24251","1056730951"
"Mongo","Mongo","12.1837","18.7000","Chad","TD","TCD","Guéra","admin","27763","1148891648"
"Portishead","Portishead","51.4840","-2.7626","United Kingdom","GB","GBR","North Somerset","","25000","1826099661"
"Koper","Koper","45.5500","13.7333","Slovenia","SI","SVN","Koper","admin","25753","1705478862"
"Xoxocotla","Xoxocotla","18.6850","-99.2439","Mexico","MX","MEX","Morelos","","21074","1484012470"
"Poranki","Poranki","16.4743","80.7128","India","IN","IND","Andhra Pradesh","","25545","1356863929"
"Chapala","Chapala","20.2961","-103.1911","Mexico","MX","MEX","Jalisco","minor","21596","1484011650"
"San Juan de Aznalfarache","San Juan de Aznalfarache","37.3667","-6.0167","Spain","ES","ESP","Andalusia","","22138","1724867783"
"Velenje","Velenje","46.3625","15.1144","Slovenia","SI","SVN","Velenje","admin","25594","1705929589"
"Genet","Genet","9.0500","38.5000","Ethiopia","ET","ETH","Oromīya","","25593","1231301779"
"Pānapur","Panapur","25.6729","85.0715","India","IN","IND","Bihār","","24678","1356470796"
"Guastatoya","Guastatoya","14.8539","-90.0686","Guatemala","GT","GTM","El Progreso","admin","27407","1320849830"
"Ciudad Altamirano","Ciudad Altamirano","18.3583","-100.6667","Mexico","MX","MEX","Guerrero","minor","24533","1484274706"
"Ashtown","Ashtown","53.3754","-6.3326","Ireland","IE","IRL","Dublin","","22740","1372691515"
"Hamtramck","Hamtramck","42.3954","-83.0559","United States","US","USA","Michigan","","27548","1840003980"
"Lemon Grove","Lemon Grove","32.7331","-117.0344","United States","US","USA","California","","27513","1840020621"
"Madingou","Madingou","-4.1536","13.5500","Congo (Brazzaville)","CG","COG","Bouenza","admin","27462","1178057279"
"Caversham","Caversham","51.4670","-0.9730","United Kingdom","GB","GBR","Reading","","23885","1826445684"
"Mahārājgani","Maharajgani","26.1075","84.5025","India","IN","IND","Bihār","","24282","1356944079"
"Alwaye","Alwaye","10.1167","76.3500","India","IN","IND","Kerala","","24110","1356109728"
"Arar","Arar","30.9833","41.0167","Saudi Arabia","SA","SAU","Al Ḩudūd ash Shamālīyah","admin","27449","1682664517"
"Martín Coronado","Martin Coronado","-34.6000","-58.5500","Argentina","AR","ARG","Buenos Aires","","19121","1032260556"
"Nsanje","Nsanje","-16.9167","35.2667","Malawi","MW","MWI","Nsanje","admin","26844","1454428076"
"Al Ghayz̧ah","Al Ghayzah","16.2106","52.1711","Yemen","YE","YEM","Al Mahrah","admin","27404","1887000479"
"Cerro Azul","Cerro Azul","21.2000","-97.7331","Mexico","MX","MEX","Veracruz","minor","23573","1484720981"
"Wednesfield","Wednesfield","52.5998","-2.0827","United Kingdom","GB","GBR","Wolverhampton","","22646","1826477782"
"Seriate","Seriate","45.6847","9.7178","Italy","IT","ITA","Lombardy","","25358","1380929157"
"La Huerta","La Huerta","14.4972","120.9953","Philippines","PH","PHL","Parañaque","","9569","1608905252"
"Shtime","Shtime","42.4333","21.0333","Kosovo","XK","XKS","Shtime","admin","27324","1901598505"
"Itambé","Itambe","-15.2450","-40.6239","Brazil","BR","BRA","Bahia","","23327","1076307665"
"Monsey","Monsey","41.1181","-74.0682","United States","US","USA","New York","","27359","1840004961"
"Whitefield","Whitefield","53.5521","-2.2992","United Kingdom","GB","GBR","Bury","","23283","1826701535"
"Le Petit-Quevilly","Le Petit-Quevilly","49.4311","1.0539","France","FR","FRA","Normandie","","22291","1250526109"
"Stalybridge","Stalybridge","53.4834","-2.0400","United Kingdom","GB","GBR","Tameside","","23731","1826524843"
"Garhara","Garhara","25.4407","85.9983","India","IN","IND","Bihār","","24397","1356014568"
"Piastów","Piastow","52.1833","20.8500","Poland","PL","POL","Mazowieckie","","23281","1616118197"
"Zalingei","Zalingei","12.9000","23.4833","Sudan","SD","SDN","Central Darfur","admin","27258","1729805075"
"Camborne","Camborne","50.2130","-5.3000","United Kingdom","GB","GBR","Cornwall","","22074","1826570775"
"Kingswinford","Kingswinford","52.4981","-2.1657","United Kingdom","GB","GBR","Staffordshire","","25191","1826547485"
"San Juan Zitlaltepec","San Juan Zitlaltepec","19.7167","-99.0500","Mexico","MX","MEX","Morelos","","19600","1484882196"
"Weingarten","Weingarten","47.8092","9.6444","Germany","DE","DEU","Baden-Württemberg","","25168","1276346018"
"Pompei","Pompei","40.7500","14.5000","Italy","IT","ITA","Campania","","25207","1380397251"
"Simria","Simria","25.9663","85.8324","India","IN","IND","Bihār","","25017","1356112895"
"Kimbe","Kimbe","-5.5575","150.1550","Papua New Guinea","PG","PNG","West New Britain","admin","27191","1598372456"
"Oulad Tayeb","Oulad Tayeb","33.9598","-4.9954","Morocco","MA","MAR","Fès-Meknès","","24594","1504027412"
"Zabrat","Zabrat","40.4872","49.9525","Azerbaijan","AZ","AZE","Bakı","","22497","1031886161"
"Jonava","Jonava","55.0722","24.2806","Lithuania","LT","LTU","Jonava","admin","27134","1440040612"
"Lindenhurst","Lindenhurst","40.6858","-73.3710","United States","US","USA","New York","","27169","1840005122"
"San Buenaventura","San Buenaventura","27.0625","-101.5467","Mexico","MX","MEX","Coahuila","minor","23413","1484100040"
"Ōji","Oji","34.5947","135.7066","Japan","JP","JPN","Nara","","23731","1392003206"
"La Paz","La Paz","-34.7617","-56.2233","Uruguay","UY","URY","Canelones","","20524","1858113046"
"Maisons-Laffitte","Maisons-Laffitte","48.9469","2.1456","France","FR","FRA","Île-de-France","","23611","1250804380"
"Bailleston","Bailleston","55.8474","-4.1147","United Kingdom","GB","GBR","Glasgow City","","21663","1826233581"
"Ennis","Ennis","52.8463","-8.9807","Ireland","IE","IRL","Clare","admin","25276","1372255646"
"Belmont","Belmont","42.3960","-71.1795","United States","US","USA","Massachusetts","","27056","1840053474"
"Maychew","Maychew","12.7833","39.5333","Ethiopia","ET","ETH","Tigray","","23419","1231612323"
"Chester-le-Street","Chester-le-Street","54.8594","-1.5699","United Kingdom","GB","GBR","Durham","","24227","1826470602"
"Catió","Catio","11.2833","-15.2500","Guinea-Bissau","GW","GNB","Tombali","admin","26999","1624933572"
"Neuilly-Plaisance","Neuilly-Plaisance","48.8619","2.5064","France","FR","FRA","Île-de-France","","20915","1250086555"
"Hirrīyat Raznah","Hirriyat Raznah","30.6028","31.5372","Egypt","EG","EGY","Ash Sharqīyah","","24359","1818867916"
"Arbroath","Arbroath","56.5610","-2.5860","United Kingdom","GB","GBR","Angus","","23940","1826263581"
"Sint-Amandsberg","Sint-Amandsberg","51.0539","3.7492","Belgium","BE","BEL","Flanders","","23143","1056712936"
"San Juan de Alicante","San Juan de Alicante","38.4014","-0.4367","Spain","ES","ESP","Valencia","","24450","1724403338"
"Farnley","Farnley","53.7876","-1.6159","United Kingdom","GB","GBR","Leeds","","24213","1826992459"
"Tamiahua","Tamiahua","21.2788","-97.4462","Mexico","MX","MEX","Veracruz","minor","23120","1484634319"
"Montigny-lès-Cormeilles","Montigny-les-Cormeilles","48.9944","2.1958","France","FR","FRA","Île-de-France","","21638","1250292359"
"Oadby","Oadby","52.5987","-1.0763","United Kingdom","GB","GBR","Leicestershire","","23849","1826422379"
"Carlos A. Carrillo","Carlos A. Carrillo","18.3667","-95.7500","Mexico","MX","MEX","Veracruz","minor","24565","1484904757"
"Frontera","Frontera","18.5336","-92.6469","Mexico","MX","MEX","Tabasco","minor","23024","1484000527"
"Sirkhandi Bhitha","Sirkhandi Bhitha","26.6244","85.7787","India","IN","IND","Bihār","","24525","1356275032"
"Texistepec","Texistepec","17.9000","-94.8167","Mexico","MX","MEX","Veracruz","minor","20887","1484059063"
"South Pasadena","South Pasadena","34.1103","-118.1573","United States","US","USA","California","","26837","1840021871"
"Kościan","Koscian","52.0833","16.6500","Poland","PL","POL","Wielkopolskie","minor","24086","1616047191"
"Maracena","Maracena","37.2000","-3.6333","Spain","ES","ESP","Andalusia","","22358","1724029236"
"Mairwa","Mairwa","26.2322","84.1635","India","IN","IND","Bihār","","24697","1356004737"
"Litherland","Litherland","53.4727","-2.9990","United Kingdom","GB","GBR","Sefton","","22242","1826594706"
"Sundararaopeta","Sundararaopeta","16.8031","81.6419","India","IN","IND","Andhra Pradesh","","24112","1356283197"
"Stanmore","Stanmore","51.6180","-0.3140","United Kingdom","GB","GBR","Harrow","","23700","1826094735"
"Acomb","Acomb","53.9550","-1.1260","United Kingdom","GB","GBR","York","","22215","1826310349"
"Shark","Shark","40.5569","72.8264","Kyrgyzstan","KG","KGZ","Osh","","21120","1417396850"
"Saint-Ouen-l’Aumône","Saint-Ouen-l'Aumone","49.0447","2.1111","France","FR","FRA","Île-de-France","","24674","1250836014"
"Bougado","Bougado","41.3370","-8.5590","Portugal","PT","PRT","Porto","","21585","1620755688"
"Leisure City","Leisure City","25.4935","-80.4363","United States","US","USA","Florida","","26667","1840014245"
"Rodez","Rodez","44.3506","2.5750","France","FR","FRA","Occitanie","minor","24475","1250807687"
"Bayanhongor","Bayanhongor","46.1917","100.7178","Mongolia","MN","MNG","Bayanhongor","admin","26252","1496815184"
"Cazones de Herrera","Cazones de Herrera","20.7000","-97.3000","Mexico","MX","MEX","Veracruz","minor","23675","1484665627"
"Fontaine","Fontaine","45.1939","5.6856","France","FR","FRA","Auvergne-Rhône-Alpes","","23211","1250760335"
"Blenheim","Blenheim","-41.5167","173.9500","New Zealand","NZ","NZL","Marlborough","admin","26400","1554001426"
"Bridgeton","Bridgeton","39.4286","-75.2281","United States","US","USA","New Jersey","","26643","1840016610"
"Seveso","Seveso","45.6434","9.1374","Italy","IT","ITA","Lombardy","","23456","1380704433"
"Chaville","Chaville","48.8086","2.1886","France","FR","FRA","Île-de-France","","20771","1250122168"
"Voinjama","Voinjama","8.4167","-9.7500","Liberia","LR","LBR","Lofa","admin","26594","1430858471"
"Palm Springs","Palm Springs","26.6348","-80.0969","United States","US","USA","Florida","","26611","1840018308"
"Sheldon","Sheldon","52.4500","-1.7666","United Kingdom","GB","GBR","Solihull","","21817","1826299810"
"Nakhon Phanom","Nakhon Phanom","17.4069","104.7808","Thailand","TH","THA","Nakhon Phanom","admin","26437","1764103547"
"Meyrin","Meyrin","46.2167","6.0667","Switzerland","CH","CHE","Genève","","24144","1756962454"
"Billinghurst","Billinghurst","-34.5667","-58.5333","Argentina","AR","ARG","Buenos Aires","","19138","1032893380"
"Ormskirk","Ormskirk","53.5665","-2.8869","United Kingdom","GB","GBR","Lancashire","","24073","1826066553"
"Failsworth","Failsworth","53.5102","-2.1575","United Kingdom","GB","GBR","Oldham","","20680","1826332679"
"Ankaraobato","Ankaraobato","-18.9000","47.4667","Madagascar","MG","MDG","Antananarivo","","20969","1450653846"
"Bedēsa","Bedesa","8.9000","40.7833","Ethiopia","ET","ETH","Oromīya","","18187","1231037182"
"Eysines","Eysines","44.8853","-0.6500","France","FR","FRA","Nouvelle-Aquitaine","","24488","1250067454"
"Utena","Utena","55.5000","25.6000","Lithuania","LT","LTU","Utena","admin","26491","1440298742"
"General Emilio Aguinaldo","General Emilio Aguinaldo","14.1833","120.8000","Philippines","PH","PHL","Cavite","","23973","1608472178"
"Oxkutzkab","Oxkutzkab","20.3028","-89.4183","Mexico","MX","MEX","Yucatán","","23096","1484480343"
"Malinalco","Malinalco","18.9483","-99.4947","Mexico","MX","MEX","México","minor","22970","1484454332"
"Ukkāyapalle","Ukkayapalle","14.4898","78.8927","India","IN","IND","Andhra Pradesh","","24165","1356294356"
"Suphan Buri","Suphan Buri","14.4675","100.1169","Thailand","TH","THA","Suphan Buri","admin","26164","1764759549"
"Sélibaby","Selibaby","15.1592","-12.1833","Mauritania","MR","MRT","Guidimaka","admin","26420","1478575433"
"Dongola","Dongola","19.1698","30.4749","Sudan","SD","SDN","Northern","admin","26404","1729300155"
"Bishopbriggs","Bishopbriggs","55.9046","-4.2250","United Kingdom","GB","GBR","East Dunbartonshire","","23540","1826547421"
"Littleover","Littleover","52.9060","-1.5050","United Kingdom","GB","GBR","Derby","","23958","1826553059"
"Newquay","Newquay","50.4120","-5.0757","United Kingdom","GB","GBR","Cornwall","","22074","1826417367"
"Suitland","Suitland","38.8492","-76.9225","United States","US","USA","Maryland","","26375","1840031466"
"Imperial Beach","Imperial Beach","32.5693","-117.1180","United States","US","USA","California","","26375","1840019353"
"Belper","Belper","53.0290","-1.4750","United Kingdom","GB","GBR","Derbyshire","","21822","1826949815"
"Mahādebnagar","Mahadebnagar","24.6896","87.9384","India","IN","IND","West Bengal","","22876","1356296868"
"San Juan de Vilasar","San Juan de Vilasar","41.5053","2.3928","Spain","ES","ESP","Catalonia","","21067","1724259439"
"Lormont","Lormont","44.8792","-0.5217","France","FR","FRA","Nouvelle-Aquitaine","","23181","1250006251"
"Ulaangom","Ulaangom","49.9833","92.0667","Mongolia","MN","MNG","Uvs","admin","26319","1496088351"
"Mīt Namā","Mit Nama","30.1453","31.2343","Egypt","EG","EGY","Al Qalyūbīyah","","21321","1818236242"
"Karian","Karian","25.8594","86.0104","India","IN","IND","Bihār","","23873","1356125443"
"Kraskovo","Kraskovo","55.6586","37.9869","Russia","RU","RUS","Moskovskaya Oblast’","","23910","1643651560"
"Meda","Meda","45.6667","9.1667","Italy","IT","ITA","Lombardy","","23463","1380159582"
"West Whittier-Los Nietos","West Whittier-Los Nietos","33.9759","-118.0689","United States","US","USA","California","","26275","1840074745"
"Clevedon","Clevedon","51.4380","-2.8540","United Kingdom","GB","GBR","North Somerset","","21281","1826157550"
"Napindan","Napindan","14.5398","121.0966","Philippines","PH","PHL","Taguig","","19346","1608393325"
"Diego Martin","Diego Martin","10.7167","-61.5667","Trinidad and Tobago","TT","TTO","Diego Martin","admin","25370","1780534918"
"Tysons","Tysons","38.9215","-77.2273","United States","US","USA","Virginia","","26171","1840006040"
"Loos","Loos","50.6128","3.0144","France","FR","FRA","Hauts-de-France","","22866","1250834480"
"Chandlers Ford","Chandlers Ford","50.9840","-1.3792","United Kingdom","GB","GBR","Hampshire","","21436","1826991193"
"Newton in Makerfield","Newton in Makerfield","53.4500","-2.6330","United Kingdom","GB","GBR","St. Helens","","22114","1826213063"
"Holborn","Holborn","51.5204","-0.1136","United Kingdom","GB","GBR","Camden","","13023","1826657058"
"Kemisē","Kemise","10.7167","39.8667","Ethiopia","ET","ETH","Āmara","","19420","1231665461"
"Bang Sao Thong","Bang Sao Thong","13.5812","100.7957","Thailand","TH","THA","Samut Prakan","minor","23630","1764260674"
"Motul","Motul","21.1667","-89.4667","Mexico","MX","MEX","Yucatán","","23240","1484505595"
"Atoyac de Álvarez","Atoyac de Alvarez","17.2000","-100.4333","Mexico","MX","MEX","Guerrero","minor","20515","1484657833"
"Bajina Bašta","Bajina Basta","43.9731","19.5597","Serbia","RS","SRB","Bajina Bašta","admin","26022","1688643521"
"Penwortham","Penwortham","53.7400","-2.7200","United Kingdom","GB","GBR","Lancashire","","23047","1826104107"
"Torcy","Torcy","48.8502","2.6508","France","FR","FRA","Île-de-France","minor","22361","1250780004"
"Sainte-Thérèse","Sainte-Therese","45.6333","-73.8500","Canada","CA","CAN","Quebec","","26025","1124190411"
"Hovd","Hovd","48.0042","91.6406","Mongolia","MN","MNG","Hovd","admin","26023","1496382421"
"San Juan de Dios","San Juan de Dios","9.8800","-84.0914","Costa Rica","CR","CRI","San José","","19481","1188722628"
"Krathum Baen","Krathum Baen","13.6631","100.2572","Thailand","TH","THA","Samut Sakhon","minor","21904","1764425264"
"Cəlilabad","Calilabad","39.2089","48.4972","Azerbaijan","AZ","AZE","Cəlilabad","admin","25846","1031242117"
"Lodi","Lodi","40.8784","-74.0814","United States","US","USA","New Jersey","","25983","1840003535"
"Castilleja de la Cuesta","Castilleja de la Cuesta","37.3833","-6.0500","Spain","ES","ESP","Andalusia","","17366","1724430426"
"March","March","52.5510","0.0880","United Kingdom","GB","GBR","Cambridgeshire","","22298","1826775634"
"Oak Ridge","Oak Ridge","28.4727","-81.4169","United States","US","USA","Florida","","25973","1840029062"
"Farsley","Farsley","53.8116","-1.6718","United Kingdom","GB","GBR","Leeds","","22594","1826622627"
"Amnat Charoen","Amnat Charoen","15.8500","104.6333","Thailand","TH","THA","Amnat Charoen","admin","25858","1764038011"
"Sanga","Sanga","34.6002","135.6955","Japan","JP","JPN","Ōsaka","","23264","1392764326"
"El Cerrito","El Cerrito","37.9196","-122.3025","United States","US","USA","California","","25898","1840020275"
"Montgeron","Montgeron","48.7039","2.4605","France","FR","FRA","Île-de-France","","23775","1250766213"
"El Tarf","El Tarf","36.7669","8.3136","Algeria","DZ","DZA","El Tarf","admin","25594","1012205687"
"Friern Barnet","Friern Barnet","51.6126","-0.1584","United Kingdom","GB","GBR","Barnet","","17250","1826718744"
"Ottobrunn","Ottobrunn","48.0667","11.6667","Germany","DE","DEU","Bavaria","","21719","1276842192"
"Kafr Shukr","Kafr Shukr","30.5470","31.2673","Egypt","EG","EGY","Al Minūfīyah","","23390","1818036288"
"Coral Terrace","Coral Terrace","25.7464","-80.3049","United States","US","USA","Florida","","25869","1840029028"
"Singhāra Buzurg","Singhara Buzurg","25.7964","85.4539","India","IN","IND","Bihār","","23109","1356062705"
"Huntingdon","Huntingdon","52.3364","-0.1717","United Kingdom","GB","GBR","Cambridgeshire","","23732","1826796900"
"Bispham","Bispham","53.8520","-3.0410","United Kingdom","GB","GBR","Blackpool","","20001","1826538665"
"Göygöl","Goygol","40.5905","46.3239","Azerbaijan","AZ","AZE","Göygöl","admin","25758","1031823379"
"Sidi Lmokhtar","Sidi Lmokhtar","31.5700","-9.0100","Morocco","MA","MAR","Marrakech-Safi","","22714","1504925399"
"Nar’yan-Mar","Nar'yan-Mar","67.6333","53.0500","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","admin","24775","1643685127"
"Kidsgrove","Kidsgrove","53.0874","-2.2478","United Kingdom","GB","GBR","Staffordshire","","23756","1826529951"
"Bambang","Bambang","14.5228","121.0689","Philippines","PH","PHL","Taguig","","13949","1608593645"
"Croix","Croix","50.6781","3.1508","France","FR","FRA","Hauts-de-France","","21087","1250218619"
"Belgrave","Belgrave","52.6566","-1.1262","United Kingdom","GB","GBR","Leicester","","11558","1826809657"
"Northwood","Northwood","51.6010","-0.4176","United Kingdom","GB","GBR","Hillingdon","","22047","1826980965"
"Kanchanaburi","Kanchanaburi","14.0194","99.5311","Thailand","TH","THA","Kanchanaburi","admin","25651","1764455558"
"Montmorency","Montmorency","48.9906","2.3228","France","FR","FRA","Île-de-France","","21723","1250939076"
"Rockville Centre","Rockville Centre","40.6643","-73.6383","United States","US","USA","New York","","25768","1840005320"
"Santiago Ixcuintla","Santiago Ixcuintla","21.8110","-105.2079","Mexico","MX","MEX","Nayarit","minor","18365","1484248235"
"Kibuku","Kibuku","1.0375","33.8400","Uganda","UG","UGA","Kibuku","admin","25000","1800098358"
"Onex","Onex","46.1833","6.1000","Switzerland","CH","CHE","Genève","","18977","1756617916"
"Zwedru","Zwedru","6.0667","-8.1333","Liberia","LR","LBR","Grand Gedeh","admin","25678","1430130578"
"Tipasa","Tipasa","36.5942","2.4430","Algeria","DZ","DZA","Tipaza","admin","25225","1012866308"
"Guiseley","Guiseley","53.8750","-1.7060","United Kingdom","GB","GBR","Leeds","","22347","1826210408"
"Ives Estates","Ives Estates","25.9632","-80.1830","United States","US","USA","Florida","","25689","1840028705"
"Vélizy-Villacoublay","Velizy-Villacoublay","48.7834","2.1834","France","FR","FRA","Île-de-France","","23092","1250075648"
"Longjumeau","Longjumeau","48.6943","2.2958","France","FR","FRA","Île-de-France","","21269","1250686935"
"Fray Bentos","Fray Bentos","-33.1333","-58.3000","Uruguay","UY","URY","Río Negro","admin","24406","1858727525"
"De Meern","De Meern","52.0781","5.0281","Netherlands","NL","NLD","Utrecht","","21815","1528629821"
"Cloverleaf","Cloverleaf","29.7882","-95.1724","United States","US","USA","Texas","","25576","1840018248"
"Sceaux","Sceaux","48.7786","2.2906","France","FR","FRA","Île-de-France","","20004","1250623371"
"San Giovanni la Punta","San Giovanni la Punta","37.5833","15.1000","Italy","IT","ITA","Sicilia","","23399","1380384622"
"Visby","Visby","57.6290","18.3071","Sweden","SE","SWE","Gotland","admin","24951","1752027172"
"Penistone","Penistone","53.5250","-1.6290","United Kingdom","GB","GBR","Barnsley","","22909","1826205951"
"Mill Creek East","Mill Creek East","47.8361","-122.1877","United States","US","USA","Washington","","25528","1840041996"
"Cliffside Park","Cliffside Park","40.8222","-73.9879","United States","US","USA","New Jersey","","25515","1840003537"
"Ossett","Ossett","53.6800","-1.5800","United Kingdom","GB","GBR","Wakefield","","21231","1826407870"
"Maywood","Maywood","33.9886","-118.1877","United States","US","USA","California","","25477","1840020497"
"Bāgalūr","Bagalur","13.1328","77.6685","India","IN","IND","Karnātaka","","9925","1356157377"
"Santa Iria da Azóia","Santa Iria da Azoia","38.8464","-9.0881","Portugal","PT","PRT","Lisboa","","18240","1620729043"
"Chocamán","Chocaman","18.9978","-97.0430","Mexico","MX","MEX","Veracruz","minor","21102","1484659925"
"Sabunçu","Sabuncu","40.4425","49.9481","Azerbaijan","AZ","AZE","Bakı","","22344","1031621781"
"Kérkyra","Kerkyra","39.6239","19.9214","Greece","GR","GRC","Ionía Nísia","admin","24838","1300093403"
"Charo","Charo","19.7500","-101.0500","Mexico","MX","MEX","Michoacán","","21784","1484226531"
"Heredia","Heredia","9.9985","-84.1169","Costa Rica","CR","CRI","Heredia","admin","18988","1188210837"
"Norton","Norton","54.5890","-1.3157","United Kingdom","GB","GBR","Stockton-on-Tees","","20829","1826731009"
"Sainte-Foy-lès-Lyon","Sainte-Foy-les-Lyon","45.7300","4.8000","France","FR","FRA","Auvergne-Rhône-Alpes","","22175","1250537092"
"Maplewood","Maplewood","40.7330","-74.2712","United States","US","USA","New Jersey","","25420","1840081750"
"Melrose Park","Melrose Park","41.9030","-87.8636","United States","US","USA","Illinois","","25417","1840011296"
"Rumphi","Rumphi","-11.0167","33.8667","Malawi","MW","MWI","Rumphi","admin","22358","1454325221"
"Terrytown","Terrytown","29.9014","-90.0279","United States","US","USA","Louisiana","","25388","1840013990"
"Jethuli","Jethuli","25.5378","85.2841","India","IN","IND","Bihār","","11572","1356530279"
"Dip","Dip","26.2369","86.3107","India","IN","IND","Bihār","","22673","1356354332"
"Vellalūr","Vellalur","10.9775","77.0260","India","IN","IND","Tamil Nādu","","22888","1356551522"
"Chiconcuac","Chiconcuac","19.5500","-98.9000","Mexico","MX","MEX","México","minor","19656","1484345092"
"Tlacolula de Matamoros","Tlacolula de Matamoros","16.9542","-96.4792","Mexico","MX","MEX","Oaxaca","minor","22545","1484076523"
"Nuevo San Juan Parangaricutiro","Nuevo San Juan Parangaricutiro","19.4000","-102.1333","Mexico","MX","MEX","Michoacán","minor","16745","1484014213"
"Fânzeres","Fanzeres","41.1667","-8.5333","Portugal","PT","PRT","Porto","","23108","1620702776"
"Peekskill","Peekskill","41.2884","-73.9227","United States","US","USA","New York","","25304","1840000783"
"Mahthi","Mahthi","25.7281","86.0093","India","IN","IND","Bihār","","23121","1356868461"
"Maghull","Maghull","53.5174","-2.9449","United Kingdom","GB","GBR","Sefton","","20444","1826082081"
"Ojinaga","Ojinaga","29.5644","-104.4164","Mexico","MX","MEX","Chihuahua","minor","22744","1484761843"
"Hunasamaranhalli","Hunasamaranhalli","13.1435","77.6200","India","IN","IND","Karnātaka","","9741","1356150700"
"Emiliano Zapata","Emiliano Zapata","17.7446","-91.7651","Mexico","MX","MEX","Chiapas","minor","22469","1484000334"
"Tassin-la-Demi-Lune","Tassin-la-Demi-Lune","45.7640","4.7800","France","FR","FRA","Auvergne-Rhône-Alpes","","22403","1250210852"
"Kitajima","Kitajima","34.1256","134.5470","Japan","JP","JPN","Tokushima","","22670","1392003373"
"Atherton","Atherton","53.5230","-2.4950","United Kingdom","GB","GBR","Wigan","","22000","1826022663"
"Juvisy-sur-Orge","Juvisy-sur-Orge","48.6883","2.3775","France","FR","FRA","Île-de-France","","17454","1250410210"
"Hacı Zeynalabdin","Haci Zeynalabdin","40.6233","49.5586","Azerbaijan","AZ","AZE","Sumqayıt","","22000","1031256738"
"Vaikam","Vaikam","9.7667","76.4000","India","IN","IND","Kerala","","22637","1356130026"
"Cacahoatán","Cacahoatan","14.9898","-92.1681","Mexico","MX","MEX","Chiapas","minor","19108","1484991082"
"Mohale’s Hoek","Mohale's Hoek","-30.1500","27.4667","Lesotho","LS","LSO","Mohale’s Hoek","admin","24992","1426343192"
"Tiverton","Tiverton","50.9030","-3.4880","United Kingdom","GB","GBR","Devon","","22291","1826587697"
"Kānke","Kanke","23.4348","85.3206","India","IN","IND","Jhārkhand","","21650","1356589149"
"Morsang-sur-Orge","Morsang-sur-Orge","48.6618","2.3461","France","FR","FRA","Île-de-France","","20619","1250633800"
"Zapote","Zapote","9.9203","-84.0592","Costa Rica","CR","CRI","San José","","18679","1188568386"
"Vukovar","Vukovar","45.3444","19.0025","Croatia","HR","HRV","Vukovarsko-Srijemska Županija","admin","24938","1191345427"
"Wewak","Wewak","-3.5500","143.6333","Papua New Guinea","PG","PNG","East Sepik","admin","25143","1598405100"
"Plumstead","Plumstead","51.4900","0.0900","United Kingdom","GB","GBR","Greenwich","","16736","1826276603"
"Mīzan Teferī","Mizan Teferi","7.0000","35.5833","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","23144","1231330466"
"Montigny-lès-Metz","Montigny-les-Metz","49.1006","6.1539","France","FR","FRA","Grand Est","","21879","1250863295"
"Horwich","Horwich","53.5920","-2.5400","United Kingdom","GB","GBR","Bolton","","20067","1826931583"
"Tbeng Meanchey","Tbeng Meanchey","13.8167","104.9667","Cambodia","KH","KHM","Preah Vihear","admin","24380","1116207762"
"Soledad","Soledad","36.4432","-121.3426","United States","US","USA","California","","25102","1840021635"
"Maesteg","Maesteg","51.6100","-3.6500","United Kingdom","GB","GBR","Bridgend","","20612","1826221040"
"Bathgate","Bathgate","55.9024","-3.6431","United Kingdom","GB","GBR","West Lothian","","22920","1826881376"
"Carteret","Carteret","40.5849","-74.2284","United States","US","USA","New Jersey","","25077","1840001331"
"Szczytno","Szczytno","53.5667","20.9833","Poland","PL","POL","Warmińsko-Mazurskie","minor","22813","1616017427"
"Kajiado","Kajiado","-1.8500","36.7833","Kenya","KE","KEN","Kajiado","admin","24678","1404265412"
"Kaga Bandoro","Kaga Bandoro","7.0000","19.1833","Central African Republic","CF","CAF","Nana-Grébizi","admin","24661","1140658026"
"University Park","University Park","32.8506","-96.7937","United States","US","USA","Texas","","25029","1840022055"
"Truro","Truro","50.2600","-5.0510","United Kingdom","GB","GBR","Cornwall","","21555","1826586304"
"Hesarghatta","Hesarghatta","13.1391","77.4783","India","IN","IND","Karnātaka","","9485","1356756215"
"Bartoszyce","Bartoszyce","54.2500","20.8000","Poland","PL","POL","Warmińsko-Mazurskie","minor","22984","1616326744"
"Rāja Pākar","Raja Pakar","25.7350","85.3376","India","IN","IND","Bihār","","21644","1356105062"
"Ekeren","Ekeren","51.2833","4.4167","Belgium","BE","BEL","Flanders","","22874","1056130684"
"Le Mée-sur-Seine","Le Mee-sur-Seine","48.5333","2.6289","France","FR","FRA","Île-de-France","","21071","1250420888"
"Portalegre","Portalegre","39.3167","-7.4167","Portugal","PT","PRT","Portalegre","admin","24930","1620475518"
"Kondalāmpatti","Kondalampatti","11.6262","78.1189","India","IN","IND","Tamil Nādu","","22186","1356227197"
"South Bradenton","South Bradenton","27.4612","-82.5822","United States","US","USA","Florida","","24972","1840029076"
"Yate","Yate","51.5402","-2.4110","United Kingdom","GB","GBR","South Gloucestershire","","21789","1826350658"
"Degollado","Degollado","20.4667","-102.1500","Mexico","MX","MEX","Jalisco","minor","21226","1484086425"
"North Guwāhāti","North Guwahati","26.1900","91.7100","India","IN","IND","Assam","","21497","1356745120"
"Valle Nacional","Valle Nacional","17.7667","-96.3000","Mexico","MX","MEX","Oaxaca","minor","22699","1484002795"
"Novo Mesto","Novo Mesto","45.8000","15.1667","Slovenia","SI","SVN","Novo Mesto","admin","24183","1705849587"
"Cotija de la Paz","Cotija de la Paz","19.8100","-102.7047","Mexico","MX","MEX","Michoacán","minor","19018","1484490839"
"Southbourne","Southbourne","50.7220","-1.7980","United Kingdom","GB","GBR","","","18960","1826138490"
"Cusano Milanino","Cusano Milanino","45.5500","9.1833","Italy","IT","ITA","Lombardy","","18797","1380658560"
"Stoke Gifford","Stoke Gifford","51.5170","-2.5480","United Kingdom","GB","GBR","South Gloucestershire","","19794","1826740275"
"Fortín de las Flores","Fortin de las Flores","18.9000","-97.0000","Mexico","MX","MEX","Veracruz","minor","21370","1484333240"
"Chinnasekkadu","Chinnasekkadu","13.1609","80.2573","India","IN","IND","Tamil Nādu","","12854","1356390473"
"Leek","Leek","53.1080","-2.0234","United Kingdom","GB","GBR","Staffordshire","","20768","1826058750"
"Edegem","Edegem","51.1500","4.4500","Belgium","BE","BEL","Flanders","","22261","1056844359"
"Shārūnah","Sharunah","28.5667","30.8500","Egypt","EG","EGY","Al Minyā","","22221","1818302173"
"Bāzārak","Bazarak","35.3128","69.5158","Afghanistan","AF","AFG","Panjshir","admin","24723","1004374554"
"Tequixquiac","Tequixquiac","19.9097","-99.1417","Mexico","MX","MEX","Morelos","minor","22676","1484908675"
"Hérouville-Saint-Clair","Herouville-Saint-Clair","49.2044","-0.3253","France","FR","FRA","Normandie","","22638","1250401082"
"Chevilly-Larue","Chevilly-Larue","48.7664","2.3533","France","FR","FRA","Île-de-France","","19988","1250043799"
"Sonāgāzi","Sonagazi","22.8492","91.3928","Bangladesh","BD","BGD","Chattogram","","20810","1050889149"
"Riverbank","Riverbank","37.7260","-120.9433","United States","US","USA","California","","24714","1840020286"
"Balasore","Balasore","21.4942","86.9317","India","IN","IND","Odisha","","13869","1356515589"
"Kirkstall","Kirkstall","53.8160","-1.6020","United Kingdom","GB","GBR","Leeds","","20673","1826151628"
"Abū Şīr Banā","Abu Sir Bana","30.9000","31.2347","Egypt","EG","EGY","Al Gharbīyah","","22214","1818635025"
"Dongta","Dongta","38.0824","106.3390","China","CN","CHN","Ningxia","minor","22040","1156958987"
"Dronfield","Dronfield","53.3024","-1.4664","United Kingdom","GB","GBR","Derbyshire","","21261","1826648427"
"Buxton","Buxton","53.2590","-1.9110","United Kingdom","GB","GBR","Derbyshire","","22215","1826191966"
"Sühbaatar","Suhbaatar","50.2364","106.2064","Mongolia","MN","MNG","Selenge","admin","24235","1496145017"
"Rawson","Rawson","-43.3000","-65.1000","Argentina","AR","ARG","Chubut","admin","24616","1032302344"
"Royton","Royton","53.5660","-2.1210","United Kingdom","GB","GBR","Oldham","","21284","1826073923"
"Baalbek","Baalbek","34.0063","36.2073","Lebanon","LB","LBN","Baalbek-Hermel","admin","24000","1422694451"
"Rothwell","Rothwell","53.7485","-1.4780","United Kingdom","GB","GBR","Leeds","","20354","1826415325"
"Le Plessis-Trévise","Le Plessis-Trevise","48.8111","2.5717","France","FR","FRA","Île-de-France","","20025","1250710876"
"Abim","Abim","2.7019","33.6600","Uganda","UG","UGA","Abim","admin","24400","1800199589"
"Bundibugyo","Bundibugyo","0.7125","30.0600","Uganda","UG","UGA","Bundibugyo","admin","22300","1800500326"
"Hornsey","Hornsey","51.5870","-0.1180","United Kingdom","GB","GBR","Haringey","","12659","1826443938"
"Falmouth","Falmouth","50.1500","-5.0700","United Kingdom","GB","GBR","Cornwall","","21797","1826117124"
"Kasamatsuchō","Kasamatsucho","35.3672","136.7632","Japan","JP","JPN","Gifu","","22415","1392337002"
"Kanmaki","Kanmaki","34.5627","135.7167","Japan","JP","JPN","Nara","","21145","1392530261"
"Herndon","Herndon","38.9699","-77.3867","United States","US","USA","Virginia","","24574","1840006037"
"Chausa","Chausa","25.5283","87.0322","India","IN","IND","Bihār","","22253","1356288709"
"North Lynnwood","North Lynnwood","47.8533","-122.2762","United States","US","USA","Washington","","24562","1840037895"
"Falagueira","Falagueira","38.7589","-9.2261","Portugal","PT","PRT","Lisboa","","14530","1620164302"
"Castelnau-le-Lez","Castelnau-le-Lez","43.6369","3.9019","France","FR","FRA","Occitanie","","22534","1250544217"
"Lop Buri","Lop Buri","14.8000","100.6269","Thailand","TH","THA","Lop Buri","admin","24166","1764574750"
"Panniyannūr","Panniyannur","11.7538","75.5528","India","IN","IND","Kerala","","22308","1356670967"
"Cibitoke","Cibitoke","-2.8886","29.1200","Burundi","BI","BDI","Cibitoke","admin","23885","1108176356"
"Allende","Allende","28.3333","-100.8333","Mexico","MX","MEX","Coahuila","minor","21376","1484737223"
"Elmwood Park","Elmwood Park","41.9225","-87.8163","United States","US","USA","Illinois","","24517","1840011272"
"Landover","Landover","38.9241","-76.8875","United States","US","USA","Maryland","","24508","1840005956"
"Gan Yavne","Gan Yavne","31.7886","34.7053","Israel","IL","ISR","Central","","22453","1376546526"
"Bailey's Crossroads","Bailey's Crossroads","38.8477","-77.1305","United States","US","USA","Virginia","","24501","1840037327"
"Xingangli","Xingangli","39.9101","119.5468","China","CN","CHN","Hebei","","19751","1156022859"
"Cormano","Cormano","45.5500","9.1667","Italy","IT","ITA","Lombardy","","20019","1380158299"
"Carlow","Carlow","52.8306","-6.9317","Ireland","IE","IRL","Carlow","admin","24272","1372096982"
"Butaleja","Butaleja","0.9250","33.9450","Uganda","UG","UGA","Butaleja","admin","24000","1800859767"
"Chitipa","Chitipa","-9.7019","33.2700","Malawi","MW","MWI","Chitipa","admin","17743","1454611219"
"Saint-Pol-sur-Mer","Saint-Pol-sur-Mer","51.0314","2.3439","France","FR","FRA","Hauts-de-France","","20479","1250034563"
"Woodlesford","Woodlesford","53.7567","-1.4530","United Kingdom","GB","GBR","Leeds","","21010","1826564728"
"São João da Madeira","Sao Joao da Madeira","40.8972","-8.4889","Portugal","PT","PRT","Aveiro","minor","21713","1620177884"
"Tralee","Tralee","52.2675","-9.6962","Ireland","IE","IRL","Kerry","admin","23691","1372403494"
"Garches","Garches","48.8456","2.1869","France","FR","FRA","Île-de-France","","17795","1250000952"
"Fatick","Fatick","14.3333","-16.4069","Senegal","SN","SEN","Fatick","admin","24243","1686096548"
"Tuktukan","Tuktukan","14.5280","121.0718","Philippines","PH","PHL","Taguig","","10730","1608288559"
"La Celle-Saint-Cloud","La Celle-Saint-Cloud","48.8411","2.1344","France","FR","FRA","Île-de-France","","20830","1250819039"
"Saint-Michel-sur-Orge","Saint-Michel-sur-Orge","48.6325","2.3028","France","FR","FRA","Île-de-France","","20484","1250540088"
"Kafr Qāsim","Kafr Qasim","32.1151","34.9751","Israel","IL","ISR","Central","","21848","1376925215"
"Rosemont","Rosemont","38.5478","-121.3553","United States","US","USA","California","","24343","1840018844"
"Dammarie-lè-Lys","Dammarie-le-Lys","48.5177","2.6402","France","FR","FRA","Île-de-France","","22177","1250036813"
"Villa Sarmiento","Villa Sarmiento","-34.6333","-58.5667","Argentina","AR","ARG","Buenos Aires","","17481","1032961945"
"Decatur","Decatur","33.7711","-84.2963","United States","US","USA","Georgia","","24334","1840014777"
"Chanthaburi","Chanthaburi","12.6086","102.1039","Thailand","TH","THA","Chanthaburi","admin","23835","1764151688"
"Baabda","Baabda","33.8333","35.5333","Lebanon","LB","LBN","Mont-Liban","admin","9000","1422639397"
"Gjirokastër","Gjirokaster","40.0667","20.1333","Albania","AL","ALB","Gjirokastër","admin","23437","1008368062"
"Villeneuve-le-Roi","Villeneuve-le-Roi","48.7333","2.4167","France","FR","FRA","Île-de-France","","21679","1250314073"
"Mantes-la-Ville","Mantes-la-Ville","48.9750","1.7117","France","FR","FRA","Île-de-France","","20818","1250672481"
"Dollis Hill","Dollis Hill","51.5641","-0.2341","United Kingdom","GB","GBR","Brent","","14425","1826669520"
"Frattaminore","Frattaminore","40.9500","14.2667","Italy","IT","ITA","Campania","","16131","1380752236"
"Valmiera","Valmiera","57.5500","25.4000","Latvia","LV","LVA","Valmiera","admin","22971","1428524787"
"Huskvarna","Huskvarna","57.7919","14.2756","Sweden","SE","SWE","Jönköping","","22000","1752552539"
"Bakhri","Bakhri","25.5989","86.2607","India","IN","IND","Bihār","","22179","1356552676"
"Vrbas","Vrbas","45.5667","19.6500","Serbia","RS","SRB","Vrbas","admin","24112","1688145915"
"Bontoc","Bontoc","17.0900","120.9772","Philippines","PH","PHL","Mountain","admin","24104","1608526537"
"Atar","Atar","20.5167","-13.0500","Mauritania","MR","MRT","Adrar","admin","24021","1478064510"
"Banī Murr","Bani Murr","27.2272","31.1944","Egypt","EG","EGY","Asyūţ","","20065","1818556807"
"Al Jawf","Al Jawf","24.2167","23.3000","Libya","LY","LBY","Al Kufrah","admin","24132","1434996940"
"Cottingham","Cottingham","53.7822","-0.4136","United Kingdom","GB","GBR","Kingston upon Hull, City of","","17164","1826515818"
"Saint-Maurice","Saint-Maurice","48.8183","2.4347","France","FR","FRA","Île-de-France","","14189","1250113303"
"San Fernando","San Fernando","34.2886","-118.4362","United States","US","USA","California","","24086","1840021861"
"Caazapá","Caazapa","-26.2000","-56.3800","Paraguay","PY","PRY","Caazapá","admin","23996","1600103814"
"Renfrew","Renfrew","55.8780","-4.3890","United Kingdom","GB","GBR","Renfrewshire","","21854","1826588626"
"Tchibanga","Tchibanga","-2.9333","11.0000","Gabon","GA","GAB","Nyanga","admin","24000","1266060335"
"Willowbrook","Willowbrook","33.9209","-118.2356","United States","US","USA","California","","24006","1840019220"
"Suchiapa","Suchiapa","16.6220","-93.1048","Mexico","MX","MEX","Chiapas","minor","19571","1484835465"
"Senago","Senago","45.5833","9.1333","Italy","IT","ITA","Lombardy","","21500","1380425008"
"Qalansuwa","Qalansuwa","32.2822","34.9833","Israel","IL","ISR","Central","","21451","1376958417"
"Mecayapan","Mecayapan","18.2167","-94.8333","Mexico","MX","MEX","Veracruz","minor","17312","1484664655"
"Stamford","Stamford","52.6560","-0.4840","United Kingdom","GB","GBR","Lincolnshire","","19701","1826765558"
"Hellemmes-Lille","Hellemmes-Lille","50.6283","3.1100","France","FR","FRA","Hauts-de-France","","18437","1250755525"
"Owando","Owando","-0.4833","15.9000","Congo (Brazzaville)","CG","COG","Cuvette","admin","23952","1178298042"
"Stellenbosch","Stellenbosch","-33.9367","18.8614","South Africa","ZA","ZAF","Western Cape","","21799","1710221701"
"Celbridge","Celbridge","53.3380","-6.5388","Ireland","IE","IRL","Kildare","","20288","1372142356"
"Kudrovo","Kudrovo","59.9000","30.5167","Russia","RU","RUS","Leningradskaya Oblast’","","13501","1643440857"
"Guaranda","Guaranda","-1.6000","-79.0000","Ecuador","EC","ECU","Bolívar","admin","23874","1218581593"
"Hebburn","Hebburn","54.9720","-1.5130","United Kingdom","GB","GBR","South Tyneside","","19148","1826146139"
"Fengdeng","Fengdeng","38.5515","106.2465","China","CN","CHN","Ningxia","","12922","1156185967"
"Wasquehal","Wasquehal","50.6694","3.1308","France","FR","FRA","Hauts-de-France","","20860","1250237697"
"Port Laoise","Port Laoise","53.0308","-7.3008","Ireland","IE","IRL","Laois","admin","22050","1372726111"
"Mapastepec","Mapastepec","15.4411","-92.8916","Mexico","MX","MEX","Chiapas","minor","19271","1484386885"
"Tabount","Tabount","30.8800","-6.9100","Morocco","MA","MAR","Drâa-Tafilalet","","21198","1504000158"
"Leigh-on-Sea","Leigh-on-Sea","51.5425","0.6535","United Kingdom","GB","GBR","Southend-on-Sea","","20737","1826850305"
"Nkhata Bay","Nkhata Bay","-11.6000","34.3000","Malawi","MW","MWI","Nkhata Bay","admin","22108","1454517378"
"Achères","Acheres","48.9602","2.0684","France","FR","FRA","Île-de-France","","21529","1250623309"
"Bariārpur","Bariarpur","25.6860","86.0295","India","IN","IND","Bihār","","21446","1356234305"
"Fada","Fada","17.1833","21.5833","Chad","TD","TCD","Ennedi-Ouest","admin","23786","1148787282"
"Le Bourget","Le Bourget","48.9344","2.4244","France","FR","FRA","Île-de-France","","16139","1250722122"
"Kyenjojo","Kyenjojo","0.6100","30.6442","Uganda","UG","UGA","Kyenjojo","admin","23467","1800420914"
"Calne","Calne","51.4380","-2.0050","United Kingdom","GB","GBR","Wiltshire","","19074","1826645118"
"Sibiti","Sibiti","-3.6858","13.3458","Congo (Brazzaville)","CG","COG","Lékoumou","admin","23752","1178867178"
"Behat","Behat","26.2435","86.2799","India","IN","IND","Bihār","","21059","1356013089"
"Satun","Satun","6.6147","100.0681","Thailand","TH","THA","Satun","admin","23612","1764836023"
"Saint-Cyr-l’École","Saint-Cyr-l'Ecole","48.8003","2.0625","France","FR","FRA","Île-de-France","","19792","1250975407"
"Phetchaburi","Phetchaburi","13.1119","99.9458","Thailand","TH","THA","Phetchaburi","admin","23235","1764000534"
"Al Madāmūd","Al Madamud","25.7333","32.7125","Egypt","EG","EGY","Al Uqşur","","15091","1818431308"
"Bāuria","Bauria","22.4521","88.1853","India","IN","IND","West Bengal","","16764","1356688611"
"Tohoué","Tohoue","6.3967","2.5853","Benin","BJ","BEN","Ouémé","","17652","1204089582"
"Hythe","Hythe","50.8690","-1.3990","United Kingdom","GB","GBR","Hampshire","","20526","1826893817"
"Nakasi","Nakasi","-18.0667","178.5167","Fiji","FJ","FJI","Naitasiri","","18919","1242908509"
"Novate Milanese","Novate Milanese","45.5333","9.1333","Italy","IT","ITA","Lombardy","","20003","1380064390"
"Peto","Peto","20.1256","-88.9214","Mexico","MX","MEX","Yucatán","","19821","1484013411"
"Peterlee","Peterlee","54.7600","-1.3300","United Kingdom","GB","GBR","Durham","","20164","1826513639"
"Watauga","Watauga","32.8719","-97.2515","United States","US","USA","Texas","","23649","1840022049"
"Ciudad Miguel Alemán","Ciudad Miguel Aleman","26.4003","-99.0253","Mexico","MX","MEX","Tamaulipas","minor","19998","1484636817"
"Ashland","Ashland","37.6942","-122.1159","United States","US","USA","California","","23640","1840017619"
"Nerupperichchal","Nerupperichchal","11.1610","77.3740","India","IN","IND","Tamil Nādu","","21611","1356163852"
"Seaham","Seaham","54.8400","-1.3400","United Kingdom","GB","GBR","Durham","","20172","1826110918"
"Tanki Leendert","Tanki Leendert","12.5418","-70.0220","Aruba","AW","ABW","","","21495","1533786612"
"Alfafar","Alfafar","39.4222","-0.3906","Spain","ES","ESP","Valencia","","21480","1724290706"
"Al ‘Azīzīyah","Al \`Aziziyah","32.5308","13.0211","Libya","LY","LBY","Al Jafārah","admin","23399","1434450111"
"Shoreham-by-Sea","Shoreham-by-Sea","50.8340","-0.2730","United Kingdom","GB","GBR","West Sussex","","20547","1826260470"
"Aţ Ţafīlah","At Tafilah","30.8400","35.6000","Jordan","JO","JOR","Aţ Ţafīlah","admin","23512","1400134505"
"Nogent-sur-Oise","Nogent-sur-Oise","49.2756","2.4683","France","FR","FRA","Hauts-de-France","","20780","1250139539"
"Montereau-faut-Yonne","Montereau-faut-Yonne","48.3853","2.9508","France","FR","FRA","Île-de-France","","21229","1250284749"
"Pakri","Pakri","25.5876","85.1580","India","IN","IND","Bihār","","9768","1356095543"
"Copiague","Copiague","40.6707","-73.3922","United States","US","USA","New York","","23552","1840005031"
"Maywood","Maywood","41.8798","-87.8442","United States","US","USA","Illinois","","23547","1840011294"
"Yonabaru","Yonabaru","26.1995","127.7548","Japan","JP","JPN","Okinawa","","19735","1392279515"
"Kiboga","Kiboga","0.9200","31.7600","Uganda","UG","UGA","Kiboga","admin","22400","1800749650"
"Goodmayes","Goodmayes","51.5631","0.1133","United Kingdom","GB","GBR","Redbridge","","13069","1826397253"
"Allschwil","Allschwil","47.5500","7.5333","Switzerland","CH","CHE","Basel-Landschaft","","21150","1756684864"
"Palmers Green","Palmers Green","51.6178","-0.1092","United Kingdom","GB","GBR","Enfield","","15162","1826526694"
"La Lucila","La Lucila","-34.4833","-58.4833","Argentina","AR","ARG","Buenos Aires","","15844","1032447032"
"Kėdainiai","Kedainiai","55.2833","23.9667","Lithuania","LT","LTU","Kėdainiai","admin","23447","1440351434"
"Mohiuddīnnagar","Mohiuddinnagar","25.7428","86.0812","India","IN","IND","Bihār","","21386","1356273490"
"Sukhodilsk","Sukhodilsk","48.3500","39.7167","Ukraine","UA","UKR","Luhanska Oblast","","21324","1804091595"
"Kagadi","Kagadi","0.9411","30.8108","Uganda","UG","UGA","Kagadi","admin","22813","1800935733"
"Biddulph","Biddulph","53.1200","-2.1700","United Kingdom","GB","GBR","Staffordshire","","19892","1826603209"
"Worcester Park","Worcester Park","51.3752","-0.2390","United Kingdom","GB","GBR","Sutton","","16031","1826854793"
"Chilly-Mazarin","Chilly-Mazarin","48.7025","2.3125","France","FR","FRA","Île-de-France","","19858","1250986253"
"Killingworth","Killingworth","55.0318","-1.5557","United Kingdom","GB","GBR","North Tyneside","","20079","1826551869"
"Opfikon","Opfikon","47.4333","8.5667","Switzerland","CH","CHE","Zürich","","19864","1756222668"
"Tena","Tena","-0.9890","-77.8159","Ecuador","EC","ECU","Napo","admin","23307","1218759537"
"Naas","Naas","53.2158","-6.6669","Ireland","IE","IRL","Kildare","admin","21393","1372985945"
"Jasmine Estates","Jasmine Estates","28.2930","-82.6907","United States","US","USA","Florida","","23334","1840014116"
"Sacavém","Sacavem","38.7944","-9.1053","Portugal","PT","PRT","Lisboa","","18469","1620849212"
"Teopisca","Teopisca","16.5425","-92.4719","Mexico","MX","MEX","Chiapas","minor","20044","1484755833"
"Sunbāţ","Sunbat","30.8057","31.2070","Egypt","EG","EGY","Al Gharbīyah","","20599","1818102859"
"Hidalgotitlán","Hidalgotitlan","17.7833","-94.6333","Mexico","MX","MEX","Veracruz","minor","19587","1484749313"
"Lingolsheim","Lingolsheim","48.5575","7.6831","France","FR","FRA","Grand Est","","19797","1250119295"
"Caterham","Caterham","51.2803","-0.0816","United Kingdom","GB","GBR","Surrey","","21030","1826811520"
"Nyon","Nyon","46.3833","6.2333","Switzerland","CH","CHE","Vaud","","20272","1756289111"
"Hatton","Hatton","6.8897","80.5981","Sri Lanka","LK","LKA","Central","","15073","1144603282"
"Aarau","Aarau","47.4000","8.0500","Switzerland","CH","CHE","Aargau","admin","21503","1756722157"
"Mosta","Mosta","35.9097","14.4261","Malta","MT","MLT","Mosta","admin","20241","1470176983"
"Selby","Selby","53.7818","-1.0703","United Kingdom","GB","GBR","North Yorkshire","","19760","1826504320"
"Coudekerque-Branche","Coudekerque-Branche","51.0253","2.3917","France","FR","FRA","Hauts-de-France","","20925","1250526010"
"Buyende","Buyende","1.1475","33.1611","Uganda","UG","UGA","Buyende","admin","23039","1800567800"
"Fleury-les-Aubrais","Fleury-les-Aubrais","47.9312","1.9210","France","FR","FRA","Centre-Val de Loire","","21070","1250087554"
"Shōwa","Showa","35.6279","138.5351","Japan","JP","JPN","Yamanashi","","20857","1392003446"
"Kayanza","Kayanza","-2.9167","29.6167","Burundi","BI","BDI","Kayanza","admin","21767","1108413426"
"Drapetsóna","Drapetsona","37.9467","23.6250","Greece","GR","GRC","Attikí","","13968","1300537061"
"Millbrae","Millbrae","37.5994","-122.4023","United States","US","USA","California","","23083","1840020306"
"Douar Toulal","Douar Toulal","33.8951","-5.6021","Morocco","MA","MAR","Fès-Meknès","","19077","1504858900"
"Gröbenzell","Grobenzell","48.2000","11.3667","Germany","DE","DEU","Bavaria","","19967","1276398825"
"Abbots Langley","Abbots Langley","51.7010","-0.4160","United Kingdom","GB","GBR","Hertfordshire","","19574","1826455551"
"Bonga","Bonga","7.2667","36.2333","Ethiopia","ET","ETH","YeDebub M‘irab Ītyop’iya Hizboch","admin","20858","1231409607"
"Peterhead","Peterhead","57.5091","-1.7832","United Kingdom","GB","GBR","Aberdeenshire","","19270","1826812445"
"Ronchin","Ronchin","50.6047","3.0878","France","FR","FRA","Hauts-de-France","","19477","1250877537"
"Pul-e ‘Alam","Pul-e \`Alam","33.9808","69.0350","Afghanistan","AF","AFG","Lōgar","admin","22914","1004180853"
"Ogre","Ogre","56.8186","24.6056","Latvia","LV","LVA","Ogre","admin","22978","1428832851"
"Kilkenny","Kilkenny","52.6477","-7.2561","Ireland","IE","IRL","Kilkenny","admin","22179","1372926521"
"Cudahy","Cudahy","33.9631","-118.1830","United States","US","USA","California","","23003","1840019242"
"West Puente Valley","West Puente Valley","34.0513","-117.9681","United States","US","USA","California","","23003","1840028409"
"Lami","Lami","-18.1167","178.4167","Fiji","FJ","FJI","Rewa","","20529","1242263735"
"Kotido","Kotido","3.0061","34.1125","Uganda","UG","UGA","Kotido","admin","22900","1800371285"
"Bellshill","Bellshill","55.8160","-4.0260","United Kingdom","GB","GBR","North Lanarkshire","","20290","1826995667"
"East San Gabriel","East San Gabriel","34.1157","-118.0791","United States","US","USA","California","","22941","1840028311"
"Blue Island","Blue Island","41.6578","-87.6811","United States","US","USA","Illinois","","22934","1840007028"
"Zimatlán de Álvarez","Zimatlan de Alvarez","16.8667","-96.7833","Mexico","MX","MEX","Oaxaca","minor","19849","1484989287"
"Ntcheu","Ntcheu","-14.8333","34.6667","Malawi","MW","MWI","Ntcheu","admin","21241","1454008976"
"Davyhulme","Davyhulme","53.4559","-2.3683","United Kingdom","GB","GBR","Trafford","","19634","1826976833"
"Hialeah Gardens","Hialeah Gardens","25.8878","-80.3569","United States","US","USA","Florida","","22917","1840015158"
"Tepatlaxco","Tepatlaxco","19.0667","-97.9667","Mexico","MX","MEX","Puebla","minor","17472","1484624680"
"Moyo","Moyo","3.6504","31.7200","Uganda","UG","UGA","Moyo","admin","22434","1800294178"
"Biləsuvar","Bilasuvar","39.4583","48.5450","Azerbaijan","AZ","AZE","Biləsuvar","admin","22800","1031149283"
"Itaosy","Itaosy","-18.9167","47.4667","Madagascar","MG","MDG","Antananarivo","","19041","1450526917"
"Bedelē","Bedele","8.4500","36.3500","Ethiopia","ET","ETH","Oromīya","","19517","1231583728"
"Llandudno","Llandudno","53.3250","-3.8260","United Kingdom","GB","GBR","Conwy","","20701","1826130998"
"Saint-Fons","Saint-Fons","45.7086","4.8533","France","FR","FRA","Auvergne-Rhône-Alpes","","19617","1250455159"
"Monserrato","Monserrato","39.2500","9.1500","Italy","IT","ITA","Sardegna","","19771","1380813472"
"Quedgeley","Quedgeley","51.8250","-2.2800","United Kingdom","GB","GBR","Gloucestershire","","17519","1826737414"
"Phra Pradaeng","Phra Pradaeng","13.6592","100.5331","Thailand","TH","THA","Samut Prakan","minor","10190","1764849819"
"Trentola","Trentola","40.9762","14.1773","Italy","IT","ITA","Campania","","19844","1380538043"
"Southgate","Southgate","51.6316","-0.1265","United Kingdom","GB","GBR","Enfield","","14454","1826679961"
"Oulad Fraj","Oulad Fraj","32.9667","-8.2333","Morocco","MA","MAR","Casablanca-Settat","","19752","1504478947"
"Sibut","Sibut","5.7333","19.0833","Central African Republic","CF","CAF","Kémo","admin","22419","1140802055"
"Al Qays","Al Qays","28.4833","30.7833","Egypt","EG","EGY","Al Minyā","","20603","1818728041"
"Burnham-on-Sea","Burnham-on-Sea","51.2376","-2.9935","United Kingdom","GB","GBR","Somerset","","19576","1826774617"
"Savanna-la-Mar","Savanna-la-Mar","18.2167","-78.1333","Jamaica","JM","JAM","Westmoreland","admin","22600","1388690384"
"Sancoale","Sancoale","15.4670","73.8330","India","IN","IND","Goa","","20597","1356247740"
"Prestatyn","Prestatyn","53.3310","-3.4050","United Kingdom","GB","GBR","Denbighshire","","19085","1826727642"
"Soisy-sous-Montmorency","Soisy-sous-Montmorency","48.9878","2.2997","France","FR","FRA","Île-de-France","","18243","1250471456"
"Bry-sur-Marne","Bry-sur-Marne","48.8411","2.5222","France","FR","FRA","Île-de-France","","17525","1250187381"
"Mulanje","Mulanje","-16.0258","35.5081","Malawi","MW","MWI","Mulanje","admin","20870","1454459215"
"José Cardel","Jose Cardel","19.3667","-96.3667","Mexico","MX","MEX","Veracruz","minor","19341","1484785956"
"Ciudad Hidalgo","Ciudad Hidalgo","14.6792","-92.1497","Mexico","MX","MEX","Chiapas","minor","17485","1484360693"
"North Plainfield","North Plainfield","40.6209","-74.4386","United States","US","USA","New Jersey","","22635","1840001059"
"Dumjor","Dumjor","22.6400","88.2200","India","IN","IND","West Bengal","","18433","1356922708"
"Dukinfield","Dukinfield","53.4739","-2.0828","United Kingdom","GB","GBR","Tameside","","19306","1826493553"
"Jēkabpils","Jekabpils","56.4994","25.8783","Latvia","LV","LVA","Jēkabpils","admin","21629","1428885452"
"Mödling","Modling","48.0856","16.2831","Austria","AT","AUT","Niederösterreich","minor","20555","1040024396"
"Woodlawn","Woodlawn","38.7332","-77.1149","United States","US","USA","Virginia","","22590","1840145210"
"Ischia","Ischia","40.7500","13.9500","Italy","IT","ITA","Campania","","20118","1380746538"
"Kannānkurichchi","Kannankurichchi","11.6969","78.1794","India","IN","IND","Tamil Nādu","","19792","1356228888"
"Antrim","Antrim","54.7173","-6.2055","United Kingdom","GB","GBR","Antrim and Newtownabbey","","19986","1826477610"
"Jarājūs","Jarajus","25.8681","32.7566","Egypt","EG","EGY","Qinā","","20427","1818408605"
"Bafatá","Bafata","12.1719","-14.6575","Guinea-Bissau","GW","GNB","Bafatá","admin","22501","1624735310"
"Ecclesall","Ecclesall","53.3620","-1.4980","United Kingdom","GB","GBR","Sheffield","","19211","1826986185"
"‘Abasān al Kabīrah","\`Abasan al Kabirah","31.3237","34.3476","Gaza Strip","XG","XGZ","","","19000","1916769088"
"Ahfir","Ahfir","34.9514","-2.1025","Morocco","MA","MAR","Oriental","","19630","1504027251"
"Roselle","Roselle","40.6527","-74.2599","United States","US","USA","New Jersey","","22511","1840001092"
"Požega","Pozega","45.3331","17.6736","Croatia","HR","HRV","Požeško-Slavonska Županija","admin","22364","1191353181"
"Saint Paul’s Bay","Saint Paul's Bay","35.9483","14.4017","Malta","MT","MLT","San Pawl il-Baħar","admin","21046","1470879974"
"Phichit","Phichit","16.4431","100.3467","Thailand","TH","THA","Phichit","admin","22299","1764024236"
"Khizrpur","Khizrpur","30.5843","76.7572","India","IN","IND","Punjab","","19817","1356197619"
"Mouila","Mouila","-1.8639","11.0575","Gabon","GA","GAB","Ngounié","admin","22469","1266074474"
"Artashat","Artashat","39.9539","44.5506","Armenia","AM","ARM","Ararat","admin","21300","1051969157"
"Mīrpeta","Mirpeta","17.3200","78.5200","India","IN","IND","Andhra Pradesh","","17074","1356216861"
"Krommenie","Krommenie","52.5000","4.7667","Netherlands","NL","NLD","Noord-Holland","","18955","1528703379"
"Aldama","Aldama","28.8386","-105.9111","Mexico","MX","MEX","Chihuahua","minor","18642","1484197521"
"Mahiari","Mahiari","22.5900","88.2400","India","IN","IND","West Bengal","","18223","1356110820"
"Frimley","Frimley","51.3143","-0.7387","United Kingdom","GB","GBR","Surrey","","19094","1826062895"
"Loei","Loei","17.4853","101.7303","Thailand","TH","THA","Loei","admin","22339","1764996883"
"Bhopatpur","Bhopatpur","26.4495","84.8545","India","IN","IND","Bihār","","20036","1356108311"
"Bingley","Bingley","53.8460","-1.8360","United Kingdom","GB","GBR","Bradford","","18294","1826460602"
"Svay Pak","Svay Pak","11.6467","104.8715","Cambodia","KH","KHM","Phnom Penh","","19042","1116000411"
"Al Bāḩah","Al Bahah","20.0125","41.4653","Saudi Arabia","SA","SAU","Al Bāḩah","admin","21983","1682121855"
"Palau","Palau","27.9167","-101.4167","Mexico","MX","MEX","Coahuila","","17594","1484142037"
"Pahsara","Pahsara","25.5482","86.1667","India","IN","IND","Bihār","","19396","1356908730"
"The Crossings","The Crossings","25.6708","-80.4018","United States","US","USA","Florida","","22382","1840029008"
"Dover","Dover","40.0019","-76.8698","United States","US","USA","Pennsylvania","","22377","1840001442"
"Santa Catarina Juquila","Santa Catarina Juquila","16.2379","-97.2914","Mexico","MX","MEX","Oaxaca","","18654","1484108848"
"Sant Just Desvern","Sant Just Desvern","41.3833","2.0750","Spain","ES","ESP","Catalonia","","19806","1724188832"
"Vandalūr","Vandalur","12.8924","80.0808","India","IN","IND","Tamil Nādu","","17655","1356485818"
"Molesey","Molesey","51.4010","-0.3630","United Kingdom","GB","GBR","Surrey","","19088","1826852866"
"Mbaïki","Mbaiki","3.8833","18.0000","Central African Republic","CF","CAF","Lobaye","admin","22166","1140040245"
"Hillside","Hillside","40.6961","-74.2286","United States","US","USA","New Jersey","","22319","1840081741"
"Tatahuicapan","Tatahuicapan","18.2500","-94.7667","Mexico","MX","MEX","Veracruz","minor","15614","1484402622"
"Connahs Quay","Connahs Quay","53.2180","-3.0570","United Kingdom","GB","GBR","Flintshire","","16774","1826159536"
"Soroca","Soroca","48.1667","28.3000","Moldova","MD","MDA","Soroca","admin","22196","1498912060"
"Arese","Arese","45.5500","9.0667","Italy","IT","ITA","Lombardy","","19347","1380500358"
"Telšiai","Telsiai","55.9833","22.2500","Lithuania","LT","LTU","Telšiai","admin","22261","1440908000"
"Fgura","Fgura","35.8725","14.5228","Malta","MT","MLT","Fgura","admin","11670","1470252842"
"Ayutla de los Libres","Ayutla de los Libres","16.9000","-99.2167","Mexico","MX","MEX","Guerrero","minor","15370","1484235149"
"Great Linford","Great Linford","52.0680","-0.7637","United Kingdom","GB","GBR","Milton Keynes","","19350","1826297411"
"Cercola","Cercola","40.8667","14.3500","Italy","IT","ITA","Campania","","18007","1380479578"
"Massapequa","Massapequa","40.6676","-73.4706","United States","US","USA","New York","","22249","1840005274"
"Houghton Regis","Houghton Regis","51.9039","-0.5247","United Kingdom","GB","GBR","Central Bedfordshire","","17280","1826997690"
"Zāwiyat Razīn","Zawiyat Razin","30.4122","30.8536","Egypt","EG","EGY","Al Minūfīyah","","19898","1818480394"
"Paraguarí","Paraguari","-25.6333","-57.1500","Paraguay","PY","PRY","Paraguarí","admin","22154","1600581132"
"Wexford","Wexford","52.3342","-6.4575","Ireland","IE","IRL","Wexford","admin","20188","1372349451"
"Sunny Isles Beach","Sunny Isles Beach","25.9385","-80.1246","United States","US","USA","Florida","","22234","1840016007"
"São Filipe","Sao Filipe","14.8950","-24.4980","Cabo Verde","CV","CPV","São Filipe","admin","22227","1132693257"
"Uliastay","Uliastay","47.7428","96.8433","Mongolia","MN","MNG","Dzavhan","admin","22006","1496103841"
"Vista Hermosa de Negrete","Vista Hermosa de Negrete","20.2717","-102.4749","Mexico","MX","MEX","Michoacán","minor","18995","1484012642"
"Phetchabun","Phetchabun","16.4169","101.1533","Thailand","TH","THA","Phetchabun","admin","22121","1764518331"
"Mullingar","Mullingar","53.5224","-7.3378","Ireland","IE","IRL","Westmeath","admin","20928","1372969205"
"Liversedge","Liversedge","53.7067","-1.6900","United Kingdom","GB","GBR","Kirklees","","19420","1826334661"
"Central Falls","Central Falls","41.8901","-71.3934","United States","US","USA","Rhode Island","","22192","1840003286"
"Sant’Antonio Abate","Sant'Antonio Abate","40.7333","14.5500","Italy","IT","ITA","Campania","","19704","1380518961"
"Maltby","Maltby","53.4260","-1.2100","United Kingdom","GB","GBR","Rotherham","","16856","1826798518"
"Éragny","Eragny","49.0172","2.0914","France","FR","FRA","Île-de-France","","18296","1250909117"
"Cran-Gévrier","Cran-Gevrier","45.9036","6.1039","France","FR","FRA","Auvergne-Rhône-Alpes","","18330","1250158129"
"Keynsham","Keynsham","51.4135","-2.4968","United Kingdom","GB","GBR","Bath and North East Somerset","","16641","1826380949"
"Tapalpa","Tapalpa","19.9445","-103.7585","Mexico","MX","MEX","Jalisco","minor","19506","1484280338"
"Aḑ Ḑāli‘","Ad Dali\`","13.6967","44.7308","Yemen","YE","YEM","Aḑ Ḑāli‘","admin","21783","1887607369"
"Alblasserdam","Alblasserdam","51.8702","4.6667","Netherlands","NL","NLD","Zuid-Holland","minor","20136","1528038126"
"Fâches-Thumesnil","Faches-Thumesnil","50.5989","3.0736","France","FR","FRA","Hauts-de-France","","18191","1250518301"
"Acala","Acala","16.5533","-92.8069","Mexico","MX","MEX","Chiapas","minor","15001","1484788928"
"Kew Green","Kew Green","51.5308","-0.2248","United Kingdom","GB","GBR","Richmond upon Thames","","14915","1826123345"
"Sarso","Sarso","26.2333","86.1768","India","IN","IND","Bihār","","18969","1356140387"
"Bourne","Bourne","52.7684","-0.3775","United Kingdom","GB","GBR","Lincolnshire","","17981","1826267550"
"Bella Unión","Bella Union","-30.2500","-57.5833","Uruguay","UY","URY","Artigas","","19100","1858883224"
"Assebroek","Assebroek","51.2000","3.2500","Belgium","BE","BEL","Flanders","","19737","1056722855"
"Ashtarak","Ashtarak","40.2975","44.3617","Armenia","AM","ARM","Aragatsotn","admin","21600","1051714712"
"Broughty Ferry","Broughty Ferry","56.4672","-2.8699","United Kingdom","GB","GBR","Dundee City","","18659","1826000803"
"West Carson","West Carson","33.8229","-118.2931","United States","US","USA","California","","22024","1840028405"
"West Rancho Dominguez","West Rancho Dominguez","33.9057","-118.2683","United States","US","USA","California","","22007","1840028406"
"Ghāt","Ghat","24.9644","10.1781","Libya","LY","LBY","Ghāt","admin","22000","1434971028"
"Dumra","Dumra","26.5671","85.5204","India","IN","IND","Bihār","","19186","1356154118"
"Bedlington","Bedlington","55.1330","-1.5830","United Kingdom","GB","GBR","Northumberland","","18470","1826713966"
"Al Karak","Al Karak","31.1833","35.7000","Jordan","JO","JOR","Al Karak","admin","21678","1400073941"
"Thalwil","Thalwil","47.2833","8.5667","Switzerland","CH","CHE","Zürich","","18525","1756199955"
"Hayesville","Hayesville","44.9793","-122.9738","United States","US","USA","Oregon","","21887","1840034726"
"Tonypandy","Tonypandy","51.6223","-3.4512","United Kingdom","GB","GBR","Rhondda Cynon Taff","","17789","1826417791"
"Reinach","Reinach","47.4833","7.5833","Switzerland","CH","CHE","Basel-Landschaft","","19144","1756011617"
"Coalcomán de Vázquez Pallares","Coalcoman de Vazquez Pallares","18.7775","-103.1600","Mexico","MX","MEX","Michoacán","minor","18444","1484699271"
"Goroka","Goroka","-6.0833","145.3833","Papua New Guinea","PG","PNG","Eastern Highlands","admin","21507","1598928169"
"Saint-Lambert","Saint-Lambert","45.5000","-73.5167","Canada","CA","CAN","Quebec","","21861","1124174363"
"Chamalières","Chamalieres","45.7736","3.0669","France","FR","FRA","Auvergne-Rhône-Alpes","","17276","1250611358"
"Acatlán de Osorio","Acatlan de Osorio","18.2086","-98.0575","Mexico","MX","MEX","Puebla","","18879","1484340141"
"Opuwo","Opuwo","-18.0556","13.8406","Namibia","NA","NAM","Kunene","admin","20000","1516958242"
"Aldo Bonzi","Aldo Bonzi","-34.7083","-58.5181","Argentina","AR","ARG","Buenos Aires","","18175","1032281187"
"Kitagata","Kitagata","35.4358","136.6844","Japan","JP","JPN","Gifu","","18308","1392622247"
"Carmelo","Carmelo","-34.0000","-58.2833","Uruguay","UY","URY","Colonia","","18041","1858670999"
"Melegnano","Melegnano","45.3588","9.3238","Italy","IT","ITA","Lombardy","","18127","1380806046"
"Valinda","Valinda","34.0400","-117.9300","United States","US","USA","California","","21811","1840019213"
"Moulins","Moulins","46.5647","3.3325","France","FR","FRA","Auvergne-Rhône-Alpes","minor","19563","1250001025"
"Ijevan","Ijevan","40.8792","45.1472","Armenia","AM","ARM","Tavush","admin","20509","1051007129"
"Mont-Saint-Aignan","Mont-Saint-Aignan","49.4625","1.0872","France","FR","FRA","Normandie","","19357","1250657039"
"Salinas de Hidalgo","Salinas de Hidalgo","22.6280","-101.7156","Mexico","MX","MEX","San Luis Potosí","minor","16821","1484391139"
"Calella","Calella","41.6169","2.6642","Spain","ES","ESP","Catalonia","","19363","1724567421"
"Dayr Abū Ḩinnis","Dayr Abu Hinnis","27.7864","30.9050","Egypt","EG","EGY","Al Minyā","","19638","1818016476"
"Morwa","Morwa","25.8030","85.6861","India","IN","IND","Bihār","","19555","1356948753"
"Four Square Mile","Four Square Mile","39.6808","-104.8880","United States","US","USA","Colorado","","21723","1840153139"
"Mayahaura","Mayahaura","22.1834","88.4998","India","IN","IND","West Bengal","","19604","1356113261"
"Hadleigh","Hadleigh","51.5535","0.6095","United Kingdom","GB","GBR","Essex","","18300","1826599485"
"Mānjha","Manjha","26.4061","84.5002","India","IN","IND","Bihār","","19011","1356303015"
"Nong Bua Lamphu","Nong Bua Lamphu","17.2042","102.4444","Thailand","TH","THA","Nong Bua Lamphu","admin","21528","1764333285"
"East Barnet","East Barnet","51.6430","-0.1630","United Kingdom","GB","GBR","Barnet","","16137","1826125584"
"Columbia Heights","Columbia Heights","45.0484","-93.2472","United States","US","USA","Minnesota","","21612","1840006712"
"Moston","Moston","53.5156","-2.1848","United Kingdom","GB","GBR","Manchester","","14518","1826000013"
"Schlieren","Schlieren","47.4000","8.4500","Switzerland","CH","CHE","Zürich","","18731","1756013633"
"Bozoum","Bozoum","6.3172","16.3783","Central African Republic","CF","CAF","Ouham-Pendé","admin","20665","1140416562"
"Hampton","Hampton","51.4220","-0.3670","United Kingdom","GB","GBR","Richmond upon Thames","","19372","1826380138"
"Chennevières-sur-Marne","Chennevieres-sur-Marne","48.7983","2.5339","France","FR","FRA","Île-de-France","","18142","1250458214"
"Viroflay","Viroflay","48.8000","2.1722","France","FR","FRA","Île-de-France","","16752","1250130544"
"Tauragė","Taurage","55.2522","22.2897","Lithuania","LT","LTU","Tauragė","admin","21516","1440115384"
"Highgate","Highgate","51.5716","-0.1448","United Kingdom","GB","GBR","Camden","","10955","1826674604"
"Hacıqabul","Haciqabul","40.0433","48.9356","Azerbaijan","AZ","AZE","Hacıqabul","admin","21504","1031015845"
"North Bellmore","North Bellmore","40.6904","-73.5390","United States","US","USA","New York","","21535","1840005240"
"Leicester","Leicester","8.4500","-13.2167","Sierra Leone","SL","SLE","Western Area","","18678","1694657802"
"Lennox","Lennox","33.9380","-118.3586","United States","US","USA","California","","21514","1840017923"
"Colne","Colne","53.8554","-2.1756","United Kingdom","GB","GBR","Lancashire","","17855","1826230570"
"Ghosāi","Ghosai","25.5562","87.0304","India","IN","IND","Bihār","","19229","1356287929"
"Le Pecq","Le Pecq","48.8967","2.1061","France","FR","FRA","Île-de-France","","15949","1250075642"
"Ymittós","Ymittos","37.9500","23.7333","Greece","GR","GRC","Attikí","","10715","1300545458"
"Westbury","Westbury","51.2600","-2.1910","United Kingdom","GB","GBR","Wiltshire","","16414","1826000067"
"Nilaiyūr","Nilaiyur","9.8572","78.0598","India","IN","IND","Tamil Nādu","","16418","1356238317"
"Chemax","Chemax","20.6550","-87.9372","Mexico","MX","MEX","Yucatán","","14885","1484867017"
"Maralal","Maralal","1.1000","36.7000","Kenya","KE","KEN","Samburu","admin","20841","1404895664"
"Clitheroe","Clitheroe","53.8711","-2.3916","United Kingdom","GB","GBR","Lancashire","","17141","1826660316"
"Pully","Pully","46.5167","6.6667","Switzerland","CH","CHE","Vaud","","18313","1756456471"
"Ghanzi","Ghanzi","-21.7000","21.6500","Botswana","BW","BWA","Ghanzi","admin","21420","1072132620"
"Virovitica","Virovitica","45.8333","17.3833","Croatia","HR","HRV","Virovitičko-Podravska Županija","admin","21291","1191128636"
"San Francisco","San Francisco","13.7000","-88.1000","El Salvador","SV","SLV","Morazán","admin","21049","1222399599"
"Redhill","Redhill","51.2393","-0.1726","United Kingdom","GB","GBR","Surrey","","18163","1826029487"
"Bonneuil-sur-Marne","Bonneuil-sur-Marne","48.7742","2.4875","France","FR","FRA","Île-de-France","","18062","1250498195"
"Vauréal","Vaureal","49.0300","2.0214","France","FR","FRA","Île-de-France","","16575","1250591118"
"Warminster","Warminster","51.2050","-2.1810","United Kingdom","GB","GBR","Wiltshire","","18173","1826602244"
"Harihans","Harihans","26.1457","84.3476","India","IN","IND","Bihār","","18740","1356591124"
"Senguio","Senguio","19.7539","-100.3377","Mexico","MX","MEX","Michoacán","minor","19146","1484696811"
"Bischheim","Bischheim","48.6139","7.7519","France","FR","FRA","Grand Est","","17353","1250611667"
"Canelones","Canelones","-34.5167","-56.2833","Uruguay","UY","URY","Canelones","admin","19865","1858741797"
"Harpur","Harpur","26.4527","86.0710","India","IN","IND","Bihār","","19099","1356612986"
"Wellington","Wellington","50.9755","-3.2243","United Kingdom","GB","GBR","Somerset","","16669","1826413119"
"Malgrat de Mar","Malgrat de Mar","41.6456","2.7428","Spain","ES","ESP","Catalonia","","19093","1724653510"
"Ukmergė","Ukmerge","55.2500","24.7500","Lithuania","LT","LTU","Ukmergė","admin","21226","1440784663"
"Técpan de Galeana","Tecpan de Galeana","17.2500","-100.6833","Mexico","MX","MEX","Guerrero","minor","14638","1484708548"
"Elmwood Park","Elmwood Park","40.9049","-74.1201","United States","US","USA","New Jersey","","21239","1840003544"
"Santa Lucía","Santa Lucia","-34.4525","-56.3964","Uruguay","UY","URY","Canelones","","16742","1858287723"
"Utazu","Utazu","34.3106","133.8256","Japan","JP","JPN","Kagawa","","18897","1392003469"
"Obock","Obock","11.9667","43.2833","Djibouti","DJ","DJI","Obock","admin","21200","1262002221"
"Pajapan","Pajapan","18.2667","-94.6833","Mexico","MX","MEX","Veracruz","minor","17480","1484768670"
"Lealman","Lealman","27.8197","-82.6847","United States","US","USA","Florida","","21213","1840025161"
"Le Raincy","Le Raincy","48.8992","2.5231","France","FR","FRA","Île-de-France","minor","14708","1250257678"
"Mountlake Terrace","Mountlake Terrace","47.7921","-122.3077","United States","US","USA","Washington","","21210","1840019792"
"Alajuelita","Alajuelita","9.9035","-84.0996","Costa Rica","CR","CRI","San José","","11988","1188793732"
"Távros","Tavros","37.9667","23.6950","Greece","GR","GRC","Attikí","","14972","1300889563"
"Devizes","Devizes","51.3528","-1.9958","United Kingdom","GB","GBR","Wiltshire","","16834","1826715932"
"Écully","Ecully","45.7744","4.7775","France","FR","FRA","Auvergne-Rhône-Alpes","","18948","1250000295"
"Orhei","Orhei","47.3833","28.8167","Moldova","MD","MDA","Orhei","admin","21065","1498680500"
"Dumri","Dumri","25.4014","86.1165","India","IN","IND","Bihār","","17676","1356500266"
"Adliswil","Adliswil","47.3167","8.5333","Switzerland","CH","CHE","Zürich","","18731","1756221733"
"Sakri","Sakri","26.2197","86.0853","India","IN","IND","Bihār","","18002","1356889301"
"Wibsey","Wibsey","53.7672","-1.7728","United Kingdom","GB","GBR","Bradford","","14671","1826433552"
"Murugampālaiyam","Murugampalaiyam","11.0806","77.3238","India","IN","IND","Tamil Nādu","","19060","1356165578"
"La Esperanza","La Esperanza","14.3000","-88.1833","Honduras","HN","HND","Intibucá","admin","21000","1340614286"
"Dingle","Dingle","53.3774","-2.9613","United Kingdom","GB","GBR","Liverpool","","13246","1826286712"
"Boom","Boom","51.0833","4.3667","Belgium","BE","BEL","Flanders","","18555","1056928401"
"Wolfratshausen","Wolfratshausen","47.9133","11.4278","Germany","DE","DEU","Bavaria","","18984","1276706639"
"Horsforth","Horsforth","53.8370","-1.6430","United Kingdom","GB","GBR","Leeds","","18895","1826006259"
"Ulao","Ulao","25.4165","86.0936","India","IN","IND","Bihār","","18020","1356574919"
"Al Hamalah","Al Hamalah","26.1497","50.4653","Bahrain","BH","BHR","Ash Shamālīyah","","18000","1048095735"
"Ad Dirāz","Ad Diraz","26.2186","50.4756","Bahrain","BH","BHR","Ash Shamālīyah","","18000","1048063693"
"El Astillero","El Astillero","43.4017","-3.8194","Spain","ES","ESP","Cantabria","","18153","1724056681"
"El Rosario","El Rosario","22.9922","-105.8572","Mexico","MX","MEX","Sinaloa","minor","16001","1484373649"
"McNair","McNair","38.9513","-77.4115","United States","US","USA","Virginia","","20980","1840041794"
"Lauālāgaon","Laualagaon","25.4940","87.0412","India","IN","IND","Bihār","","18646","1356329867"
"El Dorado","El Dorado","24.3228","-107.3630","Mexico","MX","MEX","Sinaloa","","17365","1484719986"
"Moreton","Moreton","53.4010","-3.1110","United Kingdom","GB","GBR","Wirral","","17670","1826866856"
"Mahisi","Mahisi","25.8540","86.4690","India","IN","IND","Bihār","","18358","1356260002"
"Öndörhaan","Ondorhaan","47.3233","110.6611","Mongolia","MN","MNG","Hentiy","admin","20935","1496719699"
"Cowley","Cowley","51.7330","-1.2150","United Kingdom","GB","GBR","Oxfordshire","","16500","1826013933"
"Néa Erythraía","Nea Erythraia","38.0833","23.8167","Greece","GR","GRC","Attikí","","17379","1300372430"
"Bonnyrigg","Bonnyrigg","55.8747","-3.1031","United Kingdom","GB","GBR","Midlothian","","17530","1826693791"
"Lomita","Lomita","33.7933","-118.3175","United States","US","USA","California","","20884","1840020489"
"Fern Down","Fern Down","50.8100","-1.9000","United Kingdom","GB","GBR","Dorset","","17650","1826033048"
"Amdjarass","Amdjarass","16.0658","22.8431","Chad","TD","TCD","Ennedi-Est","admin","20850","1148268495"
"Gavarr","Gavarr","40.3589","45.1267","Armenia","AM","ARM","Geghark’unik’","admin","20765","1051983153"
"Quba","Quba","41.3597","48.5125","Azerbaijan","AZ","AZE","Quba","admin","20791","1031078072"
"Neder-Over-Heembeek","Neder-Over-Heembeek","50.9000","4.3833","Belgium","BE","BEL","Brussels-Capital Region","","17595","1056378627"
"Mineola","Mineola","40.7470","-73.6394","United States","US","USA","New York","","20837","1840005310"
"Staines-upon-Thames","Staines-upon-Thames","51.4340","-0.5110","United Kingdom","GB","GBR","Surrey","","18484","1826889783"
"Dolores","Dolores","-33.5333","-58.2167","Uruguay","UY","URY","Soriano","","15753","1858369530"
"Tadaoka-higashi","Tadaoka-higashi","34.4871","135.4014","Japan","JP","JPN","Ōsaka","","16628","1392366184"
"Dawlish","Dawlish","50.5810","-3.4660","United Kingdom","GB","GBR","Devon","","15257","1826164730"
"Hyattsville","Hyattsville","38.9613","-76.9548","United States","US","USA","Maryland","","20790","1840005975"
"Langley Park","Langley Park","38.9897","-76.9808","United States","US","USA","Maryland","","20769","1840005959"
"Galhinna","Galhinna","7.4161","80.5688","Sri Lanka","LK","LKA","Central","","18507","1144327642"
"Basatpur","Basatpur","26.0011","85.0643","India","IN","IND","Bihār","","18639","1356081844"
"Erumāpālaiyam","Erumapalaiyam","11.6324","78.1695","India","IN","IND","Tamil Nādu","","17953","1356226438"
"Ostermundigen","Ostermundigen","46.9550","7.4894","Switzerland","CH","CHE","Bern","","17751","1756779389"
"West Hempstead","West Hempstead","40.6959","-73.6507","United States","US","USA","New York","","20729","1840005282"
"Rathfarnham","Rathfarnham","53.2988","-6.2854","Ireland","IE","IRL","South Dublin","","17333","1372740372"
"Pachmīr","Pachmir","25.4077","86.3803","India","IN","IND","Bihār","","17862","1356099274"
"İsmayıllı","Ismayilli","40.7900","48.1519","Azerbaijan","AZ","AZE","İsmayıllı","admin","20660","1031380246"
"Ati","Ati","13.2133","18.3381","Chad","TD","TCD","Batha","admin","20695","1148642108"
"Nan","Nan","18.7833","100.7833","Thailand","TH","THA","Nan","admin","20595","1764061223"
"New Brighton","New Brighton","53.4320","-3.0490","United Kingdom","GB","GBR","Wirral","","15149","1826841065"
"Shirley","Shirley","51.3813","-0.0543","United Kingdom","GB","GBR","Croydon","","14296","1826702063"
"Cosham","Cosham","50.8424","-1.0660","United Kingdom","GB","GBR","Portsmouth","","13830","1826628541"
"Ćuprija","Cuprija","43.9231","21.3686","Serbia","RS","SRB","Ćuprija","admin","20585","1688642855"
"Peraía","Peraia","40.5000","22.9250","Greece","GR","GRC","Kentrikí Makedonía","minor","18546","1300107891"
"High Blantyre","High Blantyre","55.7930","-4.0970","United Kingdom","GB","GBR","South Lanarkshire","","16900","1826928486"
"Nailsea","Nailsea","51.4300","-2.7600","United Kingdom","GB","GBR","North Somerset","","15630","1826659439"
"Yasothon","Yasothon","15.7972","104.1431","Thailand","TH","THA","Yasothon","admin","20414","1764520637"
"Enfield Lock","Enfield Lock","51.6686","-0.0260","United Kingdom","GB","GBR","Enfield","","16469","1826581952"
"Cleckheaton","Cleckheaton","53.7250","-1.7190","United Kingdom","GB","GBR","Kirklees","","16622","1826679048"
"Mānikpur","Manikpur","25.9100","86.8210","India","IN","IND","Bihār","","18110","1356741877"
"Beinasco","Beinasco","45.0167","7.5833","Italy","IT","ITA","Piedmont","","17856","1380688508"
"Gobabis","Gobabis","-22.4333","18.9667","Namibia","NA","NAM","Omaheke","admin","19101","1516128715"
"Paso de Carrasco","Paso de Carrasco","-34.8714","-56.0272","Uruguay","UY","URY","Canelones","","15908","1858805647"
"Kokopo","Kokopo","-4.3500","152.2736","Papua New Guinea","PG","PNG","East New Britain","admin","20262","1598823962"
"Zaghouan","Zaghouan","36.4000","10.1500","Tunisia","TN","TUN","Zaghouan","admin","20387","1788008309"
"Basford","Basford","52.9780","-1.1690","United Kingdom","GB","GBR","Nottingham","","16207","1826063569"
"Bhogpur","Bhogpur","31.5500","75.6300","India","IN","IND","Punjab","","18373","1356706771"
"Porthcawl","Porthcawl","51.4800","-3.6900","United Kingdom","GB","GBR","Bridgend","","15640","1826674732"
"Banī Ḩasan ash Shurūq","Bani Hasan ash Shuruq","27.9314","30.8753","Egypt","EG","EGY","Al Minyā","","18218","1818622612"
"Ban Rawai","Ban Rawai","7.7707","98.3185","Thailand","TH","THA","Phuket","","16988","1764734104"
"Bubanza","Bubanza","-3.0833","29.4000","Burundi","BI","BDI","Bubanza","admin","20031","1108018348"
"Milton","Milton","53.0500","-2.1420","United Kingdom","GB","GBR","Stoke-on-Trent","","17250","1826543368"
"Les Clayes-sous-Bois","Les Clayes-sous-Bois","48.8206","1.9836","France","FR","FRA","Île-de-France","","17560","1250064051"
"Mālancha","Malancha","24.6660","87.9220","India","IN","IND","West Bengal","","16918","1356456699"
"Arājpur","Arajpur","25.5197","87.0808","India","IN","IND","Bihār","","18073","1356406661"
"Senta","Senta","45.9314","20.0900","Serbia","RS","SRB","Senta","admin","20302","1688368364"
"Keota","Keota","25.6440","85.8287","India","IN","IND","Bihār","","17293","1356477994"
"Harpur Pūsa","Harpur Pusa","25.9667","85.6667","India","IN","IND","Bihār","","18309","1356042020"
"Kimwanyi","Kimwanyi","0.4533","32.6347","Uganda","UG","UGA","Wakiso","","15700","1800179354"
"Szigethalom","Szigethalom","47.3154","19.0078","Hungary","HU","HUN","Pest","","18352","1348568337"
"Hilsea","Hilsea","50.8300","-1.0700","United Kingdom","GB","GBR","Portsmouth","","13552","1826746556"
"Greystones","Greystones","53.1440","-6.0720","Ireland","IE","IRL","Wicklow","","18140","1372569009"
"Eldama Ravine","Eldama Ravine","0.0500","35.7167","Kenya","KE","KEN","Baringo","","17581","1404468256"
"Lynbrook","Lynbrook","40.6579","-73.6742","United States","US","USA","New York","","20325","1840005304"
"Usmānpur","Usmanpur","24.9218","88.0124","India","IN","IND","West Bengal","","17085","1356521277"
"Palisades Park","Palisades Park","40.8472","-73.9966","United States","US","USA","New Jersey","","20291","1840000915"
"Łomianki","Lomianki","52.3333","20.8833","Poland","PL","POL","Mazowieckie","","18124","1616717475"
"Floirac","Floirac","44.8364","-0.5258","France","FR","FRA","Nouvelle-Aquitaine","","18181","1250421727"
"Zinvié","Zinvie","6.6167","2.3500","Benin","BJ","BEN","Atlantique","","18157","1204106178"
"Ağsu","Agsu","40.5692","48.4008","Azerbaijan","AZ","AZE","Ağsu","admin","20200","1031748204"
"Ağstafa","Agstafa","41.1189","45.4539","Azerbaijan","AZ","AZE","Ağstafa","admin","20200","1031889683"
"Dhobauli","Dhobauli","25.4008","86.1885","India","IN","IND","Bihār","","16764","1356044824"
"Kilwinning","Kilwinning","55.6550","-4.7030","United Kingdom","GB","GBR","North Ayrshire","","16460","1826458707"
"Heckmondwike","Heckmondwike","53.7080","-1.6700","United Kingdom","GB","GBR","Kirklees","","16986","1826586883"
"Woodhouse","Woodhouse","53.3580","-1.3730","United Kingdom","GB","GBR","Sheffield","","17450","1826566992"
"Luganville","Luganville","-15.5333","167.1667","Vanuatu","VU","VUT","Sanma","admin","18062","1548569362"
"Baikatpur","Baikatpur","25.4939","85.3792","India","IN","IND","Bihār","","16919","1356618946"
"Raghudebbati","Raghudebbati","22.5300","88.2000","India","IN","IND","West Bengal","","14165","1356255292"
"Tyldesley","Tyldesley","53.5166","-2.4667","United Kingdom","GB","GBR","Wigan","","16142","1826559568"
"Lakhnaur","Lakhnaur","26.2020","86.3090","India","IN","IND","Bihār","","17442","1356600590"
"Comrat","Comrat","46.3167","28.6667","Moldova","MD","MDA","Găgăuzia","admin","20113","1498677351"
"Pariharpur","Pariharpur","26.7114","85.6797","India","IN","IND","Bihār","","17810","1356334490"
"Tecuala","Tecuala","22.4004","-105.4600","Mexico","MX","MEX","Nayarit","minor","14921","1484955369"
"San Josecito","San Josecito","10.0126","-84.1119","Costa Rica","CR","CRI","Heredia","","11579","1188695402"
"Kirkland","Kirkland","45.4500","-73.8667","Canada","CA","CAN","Quebec","","20151","1124000941"
"La Crescenta-Montrose","La Crescenta-Montrose","34.2322","-118.2353","United States","US","USA","California","","20149","1840074182"
"Maurepas","Maurepas","48.7628","1.9456","France","FR","FRA","Île-de-France","","18019","1250552160"
"Upton","Upton","53.3850","-3.0990","United Kingdom","GB","GBR","Wirral","","16130","1826951403"
"Qormi","Qormi","35.8794","14.4722","Malta","MT","MLT","Qormi","admin","16779","1470879234"
"Haslingden","Haslingden","53.7050","-2.3280","United Kingdom","GB","GBR","Lancashire","","16849","1826295809"
"Arvayheer","Arvayheer","46.2661","102.7778","Mongolia","MN","MNG","Övörhangay","admin","20000","1496580180"
"Ciudad de Huitzuco","Ciudad de Huitzuco","18.3000","-99.3500","Mexico","MX","MEX","Guerrero","minor","16025","1484912284"
"Biyahmū","Biyahmu","29.3675","30.8528","Egypt","EG","EGY","Al Fayyūm","","17486","1818316929"
"Tepoztlán","Tepoztlan","18.9853","-99.0997","Mexico","MX","MEX","Morelos","minor","14130","1484948191"
"Tlaxcala","Tlaxcala","19.3151","-98.2362","Mexico","MX","MEX","Tlaxcala","admin","14692","1484359502"
"Kidbrooke","Kidbrooke","51.4621","0.0273","United Kingdom","GB","GBR","Greenwich","","14300","1826659843"
"Santiago Tulantepec","Santiago Tulantepec","20.0397","-98.3575","Mexico","MX","MEX","Hidalgo","minor","16078","1484221851"
"Dokolo","Dokolo","1.9186","33.1700","Uganda","UG","UGA","Dokolo","admin","19810","1800478941"
"Sherrelwood","Sherrelwood","39.8390","-105.0014","United States","US","USA","Colorado","","20069","1840028581"
"Mohan Eghu","Mohan Eghu","25.4002","86.1450","India","IN","IND","Bihār","","16572","1356053654"
"Mwanza","Mwanza","-15.5986","34.5178","Malawi","MW","MWI","Mwanza","admin","18039","1454451365"
"Cattolica","Cattolica","43.9667","12.7333","Italy","IT","ITA","Emilia-Romagna","","17177","1380510857"
"Kotsyubyns’ke","Kotsyubyns'ke","50.4883","30.3331","Ukraine","UA","UKR","Kyiv, Misto","","16337","1804009673"
"Nahāzāri","Nahazari","22.4347","88.2487","India","IN","IND","West Bengal","","17422","1356632745"
"San Rafael Arriba","San Rafael Arriba","9.8778","-84.0708","Costa Rica","CR","CRI","San José","","15262","1188271296"
"Maldon","Maldon","51.7318","0.6758","United Kingdom","GB","GBR","Essex","","14220","1826363463"
"Fazakerley","Fazakerley","53.4676","-2.9408","United Kingdom","GB","GBR","Liverpool","","16786","1826528845"
"Morristown","Morristown","40.7967","-74.4772","United States","US","USA","New Jersey","","20003","1840003577"
"Ulcinj","Ulcinj","41.9200","19.2000","Montenegro","ME","MNE","Ulcinj","admin","19921","1499867182"
"Petit-Goâve","Petit-Goave","18.4314","-72.8669","Haiti","HT","HTI","Ouest","","12000","1332487207"
"Seacroft","Seacroft","53.8222","-1.4599","United Kingdom","GB","GBR","Leeds","","14246","1826045634"
"Tidjikja","Tidjikja","18.5500","-11.4333","Mauritania","MR","MRT","Tagant","admin","19981","1478594780"
"Ciudad Guadalupe Victoria","Ciudad Guadalupe Victoria","24.4497","-104.1225","Mexico","MX","MEX","Durango","minor","16506","1484654052"
"Albany","Albany","37.8897","-122.3018","United States","US","USA","California","","19958","1840028140"
"Mahibadhoo","Mahibadhoo","3.7575","72.9686","Maldives","MV","MDV","Ariatholhu Dhekunuburi","admin","3000","1462235059"
"Leagrave","Leagrave","51.9030","-0.4660","United Kingdom","GB","GBR","Luton","","12910","1826319019"
"Abdullahnagar","Abdullahnagar","25.7683","87.5557","India","IN","IND","Bihār","","17446","1356951045"
"Rāmpur Jalālpur","Rampur Jalalpur","25.6712","85.8606","India","IN","IND","Bihār","","16856","1356241431"
"Westmount","Westmount","45.4833","-73.6000","Canada","CA","CAN","Quebec","","19931","1124878078"
"Attapu","Attapu","14.8000","106.8333","Laos","LA","LAO","Attapu","admin","19200","1418894454"
"Fomboni","Fomboni","-12.2800","43.7425","Comoros","KM","COM","Mohéli","admin","18277","1174539766"
"Visaginas","Visaginas","55.5980","26.4380","Lithuania","LT","LTU","Visaginas","admin","19586","1440984232"
"Baguley","Baguley","53.3990","-2.2760","United Kingdom","GB","GBR","Manchester","","14794","1826354552"
"Morges","Morges","46.5167","6.5000","Switzerland","CH","CHE","Vaud","","15838","1756647288"
"Saynshand","Saynshand","44.8917","110.1367","Mongolia","MN","MNG","Dornogovĭ","admin","19891","1496862229"
"Evergreen Park","Evergreen Park","41.7213","-87.7013","United States","US","USA","Illinois","","19884","1840011273"
"Kapsabet","Kapsabet","0.3333","35.1667","Kenya","KE","KEN","Nandi","admin","17918","1404814363"
"Kavieng","Kavieng","-2.5667","150.8000","Papua New Guinea","PG","PNG","New Ireland","admin","19849","1598946326"
"Clayton","Clayton","53.7820","-1.8135","United Kingdom","GB","GBR","Bradford","","16982","1826432335"
"Kfar Aabîda","Kfar Aabida","34.2264","35.6600","Lebanon","LB","LBN","Liban-Nord","","9613","1422225745"
"South El Monte","South El Monte","34.0493","-118.0484","United States","US","USA","California","","19860","1840021869"
"San Isidro","San Isidro","9.9737","-83.9861","Costa Rica","CR","CRI","San José","","16625","1188070945"
"Kalyānpur Bamaiya","Kalyanpur Bamaiya","25.7140","85.9212","India","IN","IND","Bihār","","17689","1356924823"
"Wondelgem","Wondelgem","51.0889","3.7131","Belgium","BE","BEL","Flanders","","16932","1056981406"
"Khusropur","Khusropur","25.4817","85.3849","India","IN","IND","Bihār","","16109","1356005731"
"Shipley","Shipley","53.8330","-1.7770","United Kingdom","GB","GBR","Bradford","","15483","1826056604"
"West Wickham","West Wickham","51.3765","-0.0193","United Kingdom","GB","GBR","Bromley","","14884","1826669089"
"Giria","Giria","24.5167","88.0667","India","IN","IND","West Bengal","","17131","1356054875"
"Sligo","Sligo","54.2667","-8.4833","Ireland","IE","IRL","Sligo","admin","19199","1372185807"
"Inírida","Inirida","3.8653","-67.9239","Colombia","CO","COL","Guainía","admin","19816","1170563591"
"North Amityville","North Amityville","40.7005","-73.4119","United States","US","USA","New York","","19817","1840005053"
"Pupri","Pupri","26.4708","85.7031","India","IN","IND","Bihār","","17632","1356375658"
"Majītha","Majitha","31.7571","74.9589","India","IN","IND","Punjab","","17149","1356270631"
"West Drayton","West Drayton","51.5043","-0.4646","United Kingdom","GB","GBR","Hillingdon","","14370","1826574232"
"Dhamaun","Dhamaun","25.5999","85.5479","India","IN","IND","Bihār","","17775","1356001435"
"Néo Psychikó","Neo Psychiko","38.0000","23.7833","Greece","GR","GRC","Attikí","","10137","1300242593"
"Sironko","Sironko","1.2306","34.2481","Uganda","UG","UGA","Sironko","admin","18900","1800419144"
"Ellinikó","Elliniko","37.8667","23.7333","Greece","GR","GRC","Attikí","","17259","1300674194"
"Hermosa Beach","Hermosa Beach","33.8653","-118.3962","United States","US","USA","California","","19787","1840020487"
"Ḩawsh al Baḩdalīyah","Hawsh al Bahdaliyah","33.4292","36.3611","Syria","SY","SYR","Rīf Dimashq","","12330","1760272131"
"Glanerbrug","Glanerbrug","52.2150","6.9742","Netherlands","NL","NLD","Overijssel","","16715","1528488534"
"Pećinci","Pecinci","44.9000","19.9667","Serbia","RS","SRB","Pećinci","admin","19720","1688856329"
"South San Jose Hills","South San Jose Hills","34.0123","-117.9043","United States","US","USA","California","","19750","1840019208"
"Karahia","Karahia","26.4054","86.1490","India","IN","IND","Bihār","","17409","1356075952"
"Cárdenas","Cardenas","22.0103","-99.6522","Mexico","MX","MEX","San Luis Potosí","minor","15331","1484956696"
"Izamal","Izamal","20.9314","-89.0178","Mexico","MX","MEX","Yucatán","","16195","1484567751"
"Larkhall","Larkhall","55.7370","-3.9720","United Kingdom","GB","GBR","South Lanarkshire","","14740","1826423474"
"Bayshore Gardens","Bayshore Gardens","27.4345","-82.5793","United States","US","USA","Florida","","19720","1840014168"
"Lezhë","Lezhe","41.7819","19.6444","Albania","AL","ALB","Lezhë","admin","15510","1008889153"
"Hatch End","Hatch End","51.6010","-0.3743","United Kingdom","GB","GBR","Harrow","","10693","1826801668"
"Shankar Saraiyā","Shankar Saraiya","26.5967","84.8526","India","IN","IND","Bihār","","16983","1356572965"
"Kahhalé","Kahhale","33.8219","35.5875","Lebanon","LB","LBN","Mont-Liban","","13000","1422675912"
"Ajā","Aja","30.9414","31.2942","Egypt","EG","EGY","Ad Daqahlīyah","","17580","1818731296"
"Hednesford","Hednesford","52.7115","-2.0006","United Kingdom","GB","GBR","Staffordshire","","17343","1826749949"
"Deysbrook","Deysbrook","53.4290","-2.9340","United Kingdom","GB","GBR","Liverpool","","14490","1826441033"
"Telavi","Telavi","41.9167","45.4833","Georgia","GE","GEO","K’akheti","admin","19629","1268806987"
"La Crucecita","La Crucecita","15.7753","-96.1425","Mexico","MX","MEX","Oaxaca","","13044","1484030886"
"Korem","Korem","12.5000","39.5167","Ethiopia","ET","ETH","Tigray","","16856","1231332352"
"Whickham","Whickham","54.9456","-1.6726","United Kingdom","GB","GBR","Gateshead","","16652","1826888408"
"Noisiel","Noisiel","48.8547","2.6289","France","FR","FRA","Île-de-France","","15960","1250025430"
"Moroto","Moroto","2.5300","34.6700","Uganda","UG","UGA","Moroto","admin","16300","1800980026"
"Herceg Novi","Herceg Novi","42.4530","18.5312","Montenegro","ME","MNE","Herceg Novi","admin","19536","1499673791"
"Baharu","Baharu","22.2040","88.4283","India","IN","IND","West Bengal","","16155","1356142669"
"Lons-le-Saunier","Lons-le-Saunier","46.6744","5.5539","France","FR","FRA","Bourgogne-Franche-Comté","minor","17320","1250829703"
"Seabrook","Seabrook","38.9802","-76.8502","United States","US","USA","Maryland","","19558","1840024547"
"Bredbury","Bredbury","53.4200","-2.1130","United Kingdom","GB","GBR","Stockport","","16721","1826343069"
"Baildon","Baildon","53.8510","-1.7630","United Kingdom","GB","GBR","Bradford","","15360","1826493288"
"La Cruz","La Cruz","23.9214","-106.8919","Mexico","MX","MEX","Sinaloa","minor","15657","1484243519"
"Mau Dhaneshpur","Mau Dhaneshpur","25.5899","85.8337","India","IN","IND","Bihār","","17328","1356614082"
"Brookfield","Brookfield","41.8245","-87.8470","United States","US","USA","Illinois","","19517","1840010142"
"Montmagny","Montmagny","48.9736","2.3458","France","FR","FRA","Île-de-France","","14523","1250194364"
"Hawthorne","Hawthorne","40.9579","-74.1582","United States","US","USA","New Jersey","","19507","1840003513"
"Mont-Royal","Mont-Royal","45.5161","-73.6431","Canada","CA","CAN","Quebec","","19503","1124001920"
"Raiyam","Raiyam","26.2702","86.2071","India","IN","IND","Bihār","","17204","1356921293"
"Ágios Ioánnis Réntis","Agios Ioannis Rentis","37.9667","23.6667","Greece","GR","GRC","Attikí","","16050","1300655205"
"Solothurn","Solothurn","47.2167","7.5333","Switzerland","CH","CHE","Solothurn","admin","16777","1756650126"
"Kamwenge","Kamwenge","0.1861","30.4539","Uganda","UG","UGA","Kamwenge","admin","19240","1800010095"
"Baro","Baro","25.4487","85.9924","India","IN","IND","Bihār","","16407","1356359394"
"Sweetwater","Sweetwater","25.7785","-80.3760","United States","US","USA","Florida","","19426","1840016008"
"Cheam","Cheam","51.3600","-0.2100","United Kingdom","GB","GBR","Sutton","","10285","1826017216"
"Ferndale","Ferndale","42.4592","-83.1313","United States","US","USA","Michigan","","19414","1840002443"
"Grajales","Grajales","19.2500","-97.7833","Mexico","MX","MEX","Puebla","minor","14159","1484035729"
"Laï","Lai","9.4000","16.3000","Chad","TD","TCD","Tandjilé","admin","19382","1148360693"
"Netherton","Netherton","52.4908","-2.0835","United Kingdom","GB","GBR","Dudley","","15017","1826114854"
"Saltash","Saltash","50.4080","-4.2120","United Kingdom","GB","GBR","Cornwall","","16830","1826455741"
"Budva","Budva","42.2881","18.8425","Montenegro","ME","MNE","Budva","admin","19218","1499564238"
"Canovellas","Canovellas","41.6176","2.2814","Spain","ES","ESP","Catalonia","","16872","1724734999"
"West Derby","West Derby","53.4338","-2.9070","United Kingdom","GB","GBR","Liverpool","","14382","1826890297"
"Arnouville-lès-Gonesse","Arnouville-les-Gonesse","48.9872","2.4167","France","FR","FRA","Île-de-France","","14329","1250419168"
"Macas","Macas","-2.3667","-78.1333","Ecuador","EC","ECU","Morona-Santiago","admin","18984","1218176372"
"Partick","Partick","55.8699","-4.3125","United Kingdom","GB","GBR","Glasgow City","","8884","1826598697"
"Mālhīpur","Malhipur","25.4069","86.0234","India","IN","IND","Bihār","","16291","1356075329"
"Tillabéri","Tillaberi","14.2120","1.4531","Niger","NE","NER","Tillabéri","admin","19262","1562317955"
"Akassato","Akassato","6.5000","2.3667","Benin","BJ","BEN","Atlantique","","17197","1204097389"
"South Hayling","South Hayling","50.7876","-0.9770","United Kingdom","GB","GBR","Hampshire","","15948","1826060826"
"Mukhtārpur Salkani","Mukhtarpur Salkani","25.6643","85.7784","India","IN","IND","Bihār","","16237","1356259046"
"Ban Houayxay","Ban Houayxay","20.2631","100.4336","Laos","LA","LAO","Bokèo","admin","17687","1418944950"
"Kisoro","Kisoro","-1.2850","29.6850","Uganda","UG","UGA","Kisoro","admin","17561","1800685460"
"Scarborough","Scarborough","11.1833","-60.7375","Trinidad and Tobago","TT","TTO","Tobago","admin","17537","1780875399"
"Brusciano","Brusciano","40.9167","14.4167","Italy","IT","ITA","Campania","","16371","1380728572"
"Clifton","Clifton","53.9721","-1.0979","United Kingdom","GB","GBR","York","","13548","1826512283"
"Jagdispur","Jagdispur","22.6500","88.2900","India","IN","IND","West Bengal","","16259","1356912324"
"Pemberton","Pemberton","53.5360","-2.6738","United Kingdom","GB","GBR","Wigan","","13982","1826584870"
"Johnstone","Johnstone","55.8346","-4.5027","United Kingdom","GB","GBR","Renfrewshire","","16090","1826315811"
"Octeville","Octeville","49.6269","-1.6431","France","FR","FRA","Normandie","","16948","1250443329"
"Hargāwān","Hargawan","25.1428","85.5652","India","IN","IND","Bihār","","14391","1356006462"
"Morriston","Morriston","51.6647","-3.9389","United Kingdom","GB","GBR","Swansea","","16928","1826268056"
"Əhmədli","Ahmadli","40.3839","49.9475","Azerbaijan","AZ","AZE","Bakı","","10974","1031512571"
"Kulhudhuffushi","Kulhudhuffushi","6.6225","73.0703","Maldives","MV","MDV","Thiladhunmathee Dhekunuburi","admin","15875","1462969197"
"Ramnagar","Ramnagar","22.3245","88.4940","India","IN","IND","West Bengal","","17053","1356026794"
"Saint-Leu-la-Forêt","Saint-Leu-la-Foret","49.0169","2.2464","France","FR","FRA","Île-de-France","","16130","1250926315"
"Carrigaline","Carrigaline","51.8166","-8.3905","Ireland","IE","IRL","Cork","","15770","1372623421"
"Binningen","Binningen","47.5333","7.5667","Switzerland","CH","CHE","Basel-Landschaft","","15675","1756488918"
"Mariana","Mariana","14.6194","121.0347","Philippines","PH","PHL","Quezon","","11967","1608248744"
"Jouy-le-Moutier","Jouy-le-Moutier","49.0108","2.0386","France","FR","FRA","Île-de-France","","16741","1250589854"
"Sant’Arpino","Sant'Arpino","40.9575","14.2492","Italy","IT","ITA","Campania","","14606","1380239503"
"Pljevlja","Pljevlja","43.3567","19.3583","Montenegro","ME","MNE","Pljevlja","admin","19136","1499255431"
"Royston","Royston","52.0471","-0.0202","United Kingdom","GB","GBR","Hertfordshire","","15781","1826165038"
"Addlestone","Addlestone","51.3695","-0.4901","United Kingdom","GB","GBR","Surrey","","15883","1826253359"
"Buikwe","Buikwe","0.3442","33.0300","Uganda","UG","UGA","Buikwe","admin","18500","1800745891"
"Saint-Jean-de-la-Ruelle","Saint-Jean-de-la-Ruelle","47.9131","1.8733","France","FR","FRA","Centre-Val de Loire","","16445","1250742662"
"Kundal","Kundal","25.7620","86.2081","India","IN","IND","Bihār","","16668","1356126969"
"Le Vésinet","Le Vesinet","48.8939","2.1322","France","FR","FRA","Île-de-France","","15943","1250736749"
"Albal","Albal","39.3972","-0.4156","Spain","ES","ESP","Valencia","","16845","1724860489"
"Bububu","Bububu","-6.1658","39.1992","Tanzania","TZ","TZA","Zanzibar Urban/West","","15666","1834932235"
"Barharwa Kalān","Barharwa Kalan","26.5434","84.8286","India","IN","IND","Bihār","","16288","1356567958"
"Kelandis","Kelandis","-8.5616","115.1366","Indonesia","ID","IDN","Bali","","10353","1360695846"
"Hendaye","Hendaye","43.3586","-1.7744","France","FR","FRA","Nouvelle-Aquitaine","","16967","1250942695"
"Young","Young","-32.7000","-57.6333","Uruguay","UY","URY","Río Negro","","16756","1858448729"
"Brackley","Brackley","52.0320","-1.1470","United Kingdom","GB","GBR","Northamptonshire","","16195","1826334139"
"Winthrop","Winthrop","42.3761","-70.9846","United States","US","USA","Massachusetts","","19088","1840132450"
"Merrifield","Merrifield","38.8731","-77.2426","United States","US","USA","Virginia","","19075","1840006019"
"Kawai","Kawai","34.5783","135.7367","Japan","JP","JPN","Nara","","16983","1392003185"
"Tlaltetela","Tlaltetela","19.3167","-96.9000","Mexico","MX","MEX","Veracruz","minor","15818","1484184075"
"Dhūmnagar","Dhumnagar","26.7560","84.5233","India","IN","IND","Bihār","","15788","1356172124"
"Ribeira Grande","Ribeira Grande","17.1830","-25.0650","Cabo Verde","CV","CPV","Ribeira Grande","admin","18890","1132061543"
"Mao","Mao","14.1194","15.3133","Chad","TD","TCD","Kanem","admin","19004","1148740154"
"Braunstone","Braunstone","52.6160","-1.1750","United Kingdom","GB","GBR","Leicestershire","","16850","1826381597"
"Jaypul","Jaypul","22.7833","88.5695","India","IN","IND","West Bengal","","16134","1356322402"
"East Massapequa","East Massapequa","40.6742","-73.4359","United States","US","USA","New York","","19013","1840034047"
"Cedar Mill","Cedar Mill","45.5355","-122.8006","United States","US","USA","Oregon","","18987","1840034678"
"Norwood","Norwood","39.1605","-84.4535","United States","US","USA","Ohio","","18983","1840001610"
"Lagawe","Lagawe","16.7975","121.1228","Philippines","PH","PHL","Ifugao","admin","18876","1608396110"
"Carrières-sous-Poissy","Carrieres-sous-Poissy","48.9478","2.0386","France","FR","FRA","Île-de-France","","16641","1250534594"
"Meddappakkam","Meddappakkam","12.9166","80.1939","India","IN","IND","Tamil Nādu","","12837","1356370271"
"Tengampudūr","Tengampudur","8.1158","77.4579","India","IN","IND","Tamil Nādu","","16731","1356225907"
"Hazel Grove","Hazel Grove","53.3750","-2.1110","United Kingdom","GB","GBR","Stockport","","15265","1826167274"
"Marly-le-Roi","Marly-le-Roi","48.8669","2.0942","France","FR","FRA","Île-de-France","","16385","1250260531"
"Loreto","Loreto","26.0128","-111.3433","Mexico","MX","MEX","Baja California Sur","minor","14724","1484876942"
"Hūn","Hun","29.1268","15.9477","Libya","LY","LBY","Al Jufrah","admin","18878","1434396501"
"Kédougou","Kedougou","12.5500","-12.1833","Senegal","SN","SEN","Kédougou","admin","18860","1686320341"
"Ambohidrapeto","Ambohidrapeto","-18.9000","47.4667","Madagascar","MG","MDG","Antananarivo","","15965","1450249621"
"Oteapan","Oteapan","18.0000","-94.6667","Mexico","MX","MEX","Veracruz","minor","16222","1484584805"
"Bou Djeniba","Bou Djeniba","32.9000","-6.7833","Morocco","MA","MAR","Béni Mellal-Khénifra","","16030","1504676510"
"Wallisellen","Wallisellen","47.4150","8.5967","Switzerland","CH","CHE","Zürich","","16315","1756960820"
"Gopālnagar","Gopalnagar","24.8063","88.0796","India","IN","IND","West Bengal","","15371","1356042822"
"Puréparo de Echaíz","Pureparo de Echaiz","19.9092","-102.0036","Mexico","MX","MEX","Michoacán","","15255","1484222410"
"Masamagrell","Masamagrell","39.5703","-0.3300","Spain","ES","ESP","Valencia","","16223","1724682871"
"Point Pleasant","Point Pleasant","40.0772","-74.0702","United States","US","USA","New Jersey","","18839","1840001485"
"Huixcolotla","Huixcolotla","18.9219","-97.7708","Mexico","MX","MEX","Puebla","minor","14810","1484332923"
"Greenfield","Greenfield","36.3242","-121.2428","United States","US","USA","California","","18833","1840020356"
"Itāhri","Itahri","25.3130","86.5459","India","IN","IND","Bihār","","16183","1356254245"
"Mansa Konko","Mansa Konko","13.4667","-15.5500","The Gambia","GM","GMB","Lower River","admin","18672","1270071162"
"Harrison","Harrison","40.7431","-74.1531","United States","US","USA","New Jersey","","18824","1840003598"
"Wombwell","Wombwell","53.5160","-1.4000","United Kingdom","GB","GBR","Barnsley","","15316","1826512301"
"Härnösand","Harnosand","62.6361","17.9411","Sweden","SE","SWE","Västernorrland","admin","18508","1752644953"
"Prestwick","Prestwick","55.4956","-4.6142","United Kingdom","GB","GBR","South Ayrshire","","14720","1826710421"
"Lelydorp","Lelydorp","5.6967","-55.2167","Suriname","SR","SUR","Wanica","admin","18663","1740222585"
"Jwaneng","Jwaneng","-24.6019","24.7283","Botswana","BW","BWA","Jwaneng","admin","18784","1072118187"
"Rutherford","Rutherford","40.8203","-74.1057","United States","US","USA","New Jersey","","18781","1840000902"
"Bellwood","Bellwood","41.8829","-87.8762","United States","US","USA","Illinois","","18778","1840011248"
"Clonmel","Clonmel","52.3539","-7.7116","Ireland","IE","IRL","Tipperary","admin","17140","1372974181"
"North Babylon","North Babylon","40.7314","-73.3248","United States","US","USA","New York","","18776","1840005054"
"Pedreiras","Pedreiras","-4.5696","-44.6700","Brazil","BR","BRA","Maranhão","","13638","1076527878"
"Buckley","Buckley","53.1720","-3.0860","United Kingdom","GB","GBR","Flintshire","","15665","1826585914"
"Yiewsley","Yiewsley","51.5130","-0.4710","United Kingdom","GB","GBR","Hillingdon","","12979","1826369645"
"The Mumbles","The Mumbles","51.5730","-3.9992","United Kingdom","GB","GBR","Swansea","","16600","1826077083"
"Mangrauni","Mangrauni","26.3667","86.0833","India","IN","IND","Bihār","","16612","1356236350"
"Vicente Guerrero","Vicente Guerrero","23.7500","-103.9833","Mexico","MX","MEX","Durango","minor","15982","1484862775"
"Hostomel","Hostomel","50.5893","30.2584","Ukraine","UA","UKR","Kyivska Oblast","","16190","1804995885"
"Pālda","Palda","22.6800","75.8939","India","IN","IND","Madhya Pradesh","","14329","1356085912"
"Cerritos","Cerritos","22.4275","-100.2783","Mexico","MX","MEX","San Luis Potosí","minor","14804","1484001904"
"Banstead","Banstead","51.3220","-0.2040","United Kingdom","GB","GBR","Surrey","","16666","1826738493"
"Juchique de Ferrer","Juchique de Ferrer","19.8333","-96.7000","Mexico","MX","MEX","Veracruz","minor","16360","1484992524"
"Whittlesey","Whittlesey","52.5580","-0.1300","United Kingdom","GB","GBR","Cambridgeshire","","16058","1826174652"
"Siyəzən","Siyazan","41.0761","49.1139","Azerbaijan","AZ","AZE","Siyəzən","admin","18655","1031277547"
"Ōyamazaki","Oyamazaki","34.9028","135.6885","Japan","JP","JPN","Kyōto","","15991","1392003325"
"Ptuj","Ptuj","46.4194","15.8694","Slovenia","SI","SVN","Ptuj","admin","17959","1705072535"
"Bromborough","Bromborough","53.3360","-2.9780","United Kingdom","GB","GBR","Wirral","","14850","1826223984"
"Verwood","Verwood","50.8815","-1.8785","United Kingdom","GB","GBR","Dorset","","15196","1826274475"
"Chertsey","Chertsey","51.3902","-0.5074","United Kingdom","GB","GBR","Surrey","","15967","1826578278"
"Leposaviq","Leposaviq","43.1000","20.8000","Kosovo","XK","XKS","Leposaviq","admin","18600","1901974597"
"Country Walk","Country Walk","25.6330","-80.4353","United States","US","USA","Florida","","18627","1840028979"
"Sudley","Sudley","38.7878","-77.4961","United States","US","USA","Virginia","","18613","1840006104"
"Lognes","Lognes","48.8361","2.6278","France","FR","FRA","Île-de-France","","14353","1250582714"
"Basse Santa Su","Basse Santa Su","13.3167","-14.2167","The Gambia","GM","GMB","Upper River","admin","18414","1270820759"
"West Chester","West Chester","39.9601","-75.6058","United States","US","USA","Pennsylvania","","18592","1840001436"
"North Massapequa","North Massapequa","40.7031","-73.4678","United States","US","USA","New York","","18577","1840005241"
"Hythe","Hythe","51.0716","1.0840","United Kingdom","GB","GBR","Kent","","14170","1826972409"
"Melksham","Melksham","51.3710","-2.1380","United Kingdom","GB","GBR","Wiltshire","","14677","1826642248"
"Progreso","Progreso","-34.6650","-56.2194","Uruguay","UY","URY","Canelones","","16244","1858245447"
"Tarrafal","Tarrafal","15.2780","-23.7520","Cabo Verde","CV","CPV","Tarrafal","admin","18561","1132834308"
"Tsetserleg","Tsetserleg","47.4769","101.4503","Mongolia","MN","MNG","Arhangay","admin","18519","1496397451"
"Biały Kamień","Bialy Kamien","50.7814","16.2539","Poland","PL","POL","Dolnośląskie","","14278","1616208893"
"Casandrino","Casandrino","40.9333","14.2500","Italy","IT","ITA","Campania","","14125","1380625213"
"Rwamagana","Rwamagana","-1.9500","30.4333","Rwanda","RW","RWA","Eastern Province","admin","18009","1646996571"
"Goulmima","Goulmima","31.6944","-4.9592","Morocco","MA","MAR","Drâa-Tafilalet","","16419","1504139417"
"Arenys de Mar","Arenys de Mar","41.5819","2.5503","Spain","ES","ESP","Catalonia","","16155","1724290074"
"North Bay Shore","North Bay Shore","40.7602","-73.2617","United States","US","USA","New York","","18524","1840005055"
"Lansdale","Lansdale","40.2417","-75.2812","United States","US","USA","Pennsylvania","","18509","1840003703"
"Acasusso","Acasusso","-34.4667","-58.5167","Argentina","AR","ARG","Buenos Aires","","12842","1032071091"
"Mésa Geitoniá","Mesa Geitonia","34.7024","33.0453","Cyprus","CY","CYP","Lemesós","","14477","1196704525"
"Straşeni","Straseni","47.1333","28.6167","Moldova","MD","MDA","Străşeni","admin","18376","1498773145"
"Kanhauli Manohar","Kanhauli Manohar","25.9755","84.6853","India","IN","IND","Bihār","","16187","1356255350"
"Singhwara","Singhwara","26.1842","85.7368","India","IN","IND","Bihār","","16204","1356777436"
"Pendlebury","Pendlebury","53.5075","-2.3154","United Kingdom","GB","GBR","Salford","","13069","1826555461"
"Dover","Dover","40.8859","-74.5597","United States","US","USA","New Jersey","","18418","1840003581"
"Hybla Valley","Hybla Valley","38.7484","-77.0821","United States","US","USA","Virginia","","18410","1840006031"
"Nantwich","Nantwich","53.0670","-2.5220","United Kingdom","GB","GBR","Cheshire East","","14045","1826640186"
"Santa Rosalía","Santa Rosalia","27.3389","-112.2669","Mexico","MX","MEX","Baja California Sur","minor","14357","1484950775"
"Garhpura","Garhpura","25.6638","86.1818","India","IN","IND","Bihār","","14741","1356108641"
"Mayuge","Mayuge","0.4578","33.4800","Uganda","UG","UGA","Mayuge","admin","17151","1800300606"
"Kingstowne","Kingstowne","38.7625","-77.1444","United States","US","USA","Virginia","","18369","1840041770"
"Péfka","Pefka","40.6500","22.9833","Greece","GR","GRC","Kentrikí Makedonía","","13052","1300266645"
"Beausoleil","Beausoleil","43.7419","7.4236","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13576","1250505648"
"Palanga","Palanga","55.9167","21.0667","Lithuania","LT","LTU","Palangos Miestas","admin","18132","1440868798"
"North Valley Stream","North Valley Stream","40.6840","-73.7077","United States","US","USA","New York","","18345","1840005244"
"Amblecote","Amblecote","52.4600","-2.1600","United Kingdom","GB","GBR","Dudley","","13393","1826685308"
"Montargis","Montargis","47.9969","2.7325","France","FR","FRA","Centre-Val de Loire","minor","14976","1250190345"
"Ermúa","Ermua","43.1875","-2.5008","Spain","ES","ESP","Basque Country","","15791","1724922522"
"Churriana de la Vega","Churriana de la Vega","37.1500","-3.6500","Spain","ES","ESP","Andalusia","","16026","1724576958"
"Bidston","Bidston","53.4020","-3.0780","United Kingdom","GB","GBR","Wirral","","15216","1826626467"
"Żabbar","Zabbar","35.8772","14.5381","Malta","MT","MLT","Żabbar","admin","15404","1470317969"
"Franconia","Franconia","38.7682","-77.1587","United States","US","USA","Virginia","","18310","1840006027"
"Toyoyama","Toyoyama","35.2505","136.9121","Japan","JP","JPN","Aichi","","15729","1392003523"
"Pūluvappatti","Puluvappatti","10.9630","76.8158","India","IN","IND","Tamil Nādu","","15792","1356159468"
"Schofield Barracks","Schofield Barracks","21.4936","-158.0617","United States","US","USA","Hawaii","","18255","1840075052"
"Gornalwood","Gornalwood","52.5230","-2.1240","United Kingdom","GB","GBR","Dudley","","12992","1826716353"
"Billapādu","Billapadu","16.6364","80.9661","India","IN","IND","Andhra Pradesh","","15223","1356125367"
"San Fernando","San Fernando","16.8717","-93.2063","Mexico","MX","MEX","Chiapas","minor","11867","1484329029"
"Lymington","Lymington","50.7500","-1.5500","United Kingdom","GB","GBR","Hampshire","","15726","1826325282"
"Sangre Grande","Sangre Grande","10.5667","-61.1333","Trinidad and Tobago","TT","TTO","Sangre Grande","admin","18127","1780469686"
"Xaignabouli","Xaignabouli","19.2500","101.7500","Laos","LA","LAO","Xaignabouli","admin","16200","1418760593"
"Eilendorf","Eilendorf","50.7794","6.1625","Germany","DE","DEU","North Rhine-Westphalia","","15892","1276960521"
"Hessle","Hessle","53.7239","-0.4319","United Kingdom","GB","GBR","East Riding of Yorkshire","","15000","1826433714"
"Filadelfia","Filadelfia","-22.3400","-60.0300","Paraguay","PY","PRY","Boquerón","admin","18210","1600948743"
"Carrières-sur-Seine","Carrieres-sur-Seine","48.9108","2.2889","France","FR","FRA","Île-de-France","","15178","1250595706"
"Sidi Zouine","Sidi Zouine","31.6706","-8.3508","Morocco","MA","MAR","Marrakech-Safi","","14954","1504333871"
"Llantrisant","Llantrisant","51.5420","-3.3750","United Kingdom","GB","GBR","Rhondda Cynon Taff","","15313","1826229863"
"Rosyth","Rosyth","56.0339","-3.4323","United Kingdom","GB","GBR","Fife","","13780","1826173931"
"Le Hochet","Le Hochet","-20.1350","57.5211","Mauritius","MU","MUS","Pamplemousses","","15034","1480875761"
"Lyantonde","Lyantonde","-0.4069","31.1575","Uganda","UG","UGA","Lyantonde","admin","16300","1800896564"
"Tarxien","Tarxien","35.8658","14.5119","Malta","MT","MLT","Tarxien","admin","8583","1470230507"
"Prachin Buri","Prachin Buri","14.0567","101.3739","Thailand","TH","THA","Prachin Buri","admin","17768","1764262472"
"Hasanpur Juned","Hasanpur Juned","25.5915","85.5238","India","IN","IND","Bihār","","14764","1356120153"
"Rock Ferry","Rock Ferry","53.3730","-3.0080","United Kingdom","GB","GBR","Wirral","","14298","1826250027"
"Erongarícuaro","Erongaricuaro","19.5833","-101.7167","Mexico","MX","MEX","Michoacán","minor","15291","1484012062"
"Salaspils","Salaspils","56.8667","24.3500","Latvia","LV","LVA","Salaspils","admin","17905","1428270898"
"Petite-Synthe","Petite-Synthe","51.0231","2.3528","France","FR","FRA","Hauts-de-France","","15911","1250072351"
"Saffron Walden","Saffron Walden","52.0220","0.2430","United Kingdom","GB","GBR","Essex","","15504","1826424344"
"Dogāchi","Dogachi","24.6195","87.9221","India","IN","IND","West Bengal","","13848","1356586621"
"Bois-d’Arcy","Bois-d'Arcy","48.8014","2.0317","France","FR","FRA","Île-de-France","","15325","1250253969"
"Suchindram","Suchindram","8.1544","77.4670","India","IN","IND","Tamil Nādu","","15908","1356095431"
"Sing Buri","Sing Buri","14.8911","100.4031","Thailand","TH","THA","Sing Buri","admin","17843","1764482111"
"Barton upon Irwell","Barton upon Irwell","53.4760","-2.3600","United Kingdom","GB","GBR","Salford","","12462","1826910370"
"Burke Centre","Burke Centre","38.7903","-77.2999","United States","US","USA","Virginia","","18113","1840041683"
"Ait Yaazem","Ait Yaazem","33.7333","-5.5833","Morocco","MA","MAR","Fès-Meknès","","16091","1504697698"
"Bostonia","Bostonia","32.8189","-116.9480","United States","US","USA","California","","18074","1840018018"
"Sainte-Marthe-sur-le-Lac","Sainte-Marthe-sur-le-Lac","45.5300","-73.9300","Canada","CA","CAN","Quebec","","18074","1124001153"
"Torit","Torit","4.4081","32.5750","South Sudan","SS","SSD","Eastern Equatoria","admin","17957","1728698486"
"Vynnyky","Vynnyky","49.8156","24.1297","Ukraine","UA","UKR","Lvivska Oblast","","15723","1804831750"
"Thornbury","Thornbury","51.6094","-2.5249","United Kingdom","GB","GBR","South Gloucestershire","","14496","1826096107"
"Madera","Madera","29.1900","-108.1414","Mexico","MX","MEX","Chihuahua","minor","15447","1484958144"
"Tunapuna","Tunapuna","10.6333","-61.3833","Trinidad and Tobago","TT","TTO","Tunapuna/Piarco","admin","17650","1780508155"
"Eppelheim","Eppelheim","49.4000","8.6333","Germany","DE","DEU","Baden-Württemberg","","15359","1276579726"
"South Orange Village","South Orange Village","40.7491","-74.2602","United States","US","USA","New Jersey","","18049","1840081372"
"Porto Novo","Porto Novo","17.0190","-25.0640","Cabo Verde","CV","CPV","Porto Novo","admin","18028","1132233840"
"Prilly","Prilly","46.5333","6.6000","Switzerland","CH","CHE","Vaud","","12399","1756590980"
"Gines","Gines","37.3875","-6.0781","Spain","ES","ESP","Andalusia","","13529","1724319564"
"Tanakkangulam","Tanakkangulam","9.8877","78.0397","India","IN","IND","Tamil Nādu","","12867","1356236922"
"Viljandi","Viljandi","58.3667","25.6000","Estonia","EE","EST","Viljandi","admin","16875","1233067368"
"Roosevelt","Roosevelt","40.6797","-73.5837","United States","US","USA","New York","","18019","1840005253"
"Orange Walk","Orange Walk","18.0750","-88.5583","Belize","BZ","BLZ","Orange Walk","admin","18000","1084547220"
"Stapleford","Stapleford","52.9290","-1.2740","United Kingdom","GB","GBR","Nottinghamshire","","15241","1826644047"
"Saatlı","Saatli","39.9311","48.3697","Azerbaijan","AZ","AZE","Saatlı","admin","17900","1031713436"
"Lākho","Lakho","25.4113","86.1792","India","IN","IND","Bihār","","14493","1356041399"
"Redruth","Redruth","50.2330","-5.2240","United Kingdom","GB","GBR","Cornwall","","15169","1826258602"
"Lucé","Luce","48.4383","1.4650","France","FR","FRA","Centre-Val de Loire","","15435","1250521642"
"Mārupe","Marupe","56.9069","24.0583","Latvia","LV","LVA","Mārupe","admin","17796","1428162714"
"Dumont","Dumont","40.9452","-73.9923","United States","US","USA","New Jersey","","17965","1840003541"
"San Pablo Atlazalpan","San Pablo Atlazalpan","19.2172","-98.9090","Mexico","MX","MEX","México","","11236","1484948651"
"Perivale","Perivale","51.5383","-0.3192","United Kingdom","GB","GBR","Ealing","","15339","1826877417"
"Tabernes Blanques","Tabernes Blanques","39.5064","-0.3626","Spain","ES","ESP","Valencia","","9222","1724315090"
"Puerto Ayora","Puerto Ayora","-0.7500","-90.3167","Ecuador","EC","ECU","Galápagos","","10999","1218585265"
"Enghien-les-Bains","Enghien-les-Bains","48.9697","2.3081","France","FR","FRA","Île-de-France","","11470","1250694122"
"Sa Kaeo","Sa Kaeo","13.8206","102.0589","Thailand","TH","THA","Sa Kaeo","admin","17864","1764207048"
"Kidlington","Kidlington","51.8230","-1.2910","United Kingdom","GB","GBR","Oxfordshire","","13723","1826182197"
"Gundūr","Gundur","10.7339","78.7184","India","IN","IND","Tamil Nādu","","8587","1356211280"
"Ngora","Ngora","1.4575","33.7764","Uganda","UG","UGA","Ngora","admin","17700","1800020551"
"Huitzilan","Huitzilan","19.9667","-97.6833","Mexico","MX","MEX","Puebla","minor","15096","1484786185"
"Villa Unión","Villa Union","23.1883","-106.2158","Mexico","MX","MEX","Sinaloa","","13404","1484310975"
"Valparaíso","Valparaiso","22.7667","-103.5667","Mexico","MX","MEX","Zacatecas","minor","12919","1484885454"
"Papágos","Papagos","37.9900","23.7933","Greece","GR","GRC","Attikí","","13699","1300697009"
"Timperley","Timperley","53.3870","-2.3280","United Kingdom","GB","GBR","Trafford","","11061","1826838996"
"Armthorpe","Armthorpe","53.5352","-1.0522","United Kingdom","GB","GBR","Doncaster","","14457","1826961585"
"Baynāla","Baynala","22.5277","88.5580","India","IN","IND","West Bengal","","15582","1356212214"
"Al Mālikīyah","Al Malikiyah","26.1008","50.4878","Bahrain","BH","BHR","Ash Shamālīyah","","14800","1048201058"
"Little Hulton","Little Hulton","53.5300","-2.4180","United Kingdom","GB","GBR","Salford","","13469","1826921143"
"Puttalam","Puttalam","8.0981","77.4640","India","IN","IND","Tamil Nādu","","15622","1356226752"
"Lys-lès-Lannoy","Lys-les-Lannoy","50.6714","3.2144","France","FR","FRA","Hauts-de-France","","13643","1250459927"
"Sidlice","Sidlice","54.3471","18.6171","Poland","PL","POL","Pomorskie","","12842","1616658059"
"Thônex","Thonex","46.1833","6.1833","Switzerland","CH","CHE","Genève","","14122","1756784451"
"Valbom","Valbom","41.1333","-8.5667","Portugal","PT","PRT","Porto","","14407","1620089620"
"Khaşab","Khasab","26.1833","56.2500","Oman","OM","OMN","Musandam","admin","17777","1512236141"
"Anosy Avaratra","Anosy Avaratra","-18.8000","47.5500","Madagascar","MG","MDG","Antananarivo","","15243","1450108069"
"Kenley","Kenley","51.3242","-0.0969","United Kingdom","GB","GBR","Croydon","","14966","1826081355"
"Rottingdean","Rottingdean","50.8150","-0.0650","United Kingdom","GB","GBR","Brighton and Hove","","14324","1826363902"
"Marlow","Marlow","51.5700","-0.7800","United Kingdom","GB","GBR","Buckinghamshire","","14004","1826308165"
"Brierley Hill","Brierley Hill","52.4795","-2.1245","United Kingdom","GB","GBR","Dudley","","13935","1826736582"
"Whitby","Whitby","54.4858","-0.6206","United Kingdom","GB","GBR","North Yorkshire","","13594","1826806363"
"Juan Aldama","Juan Aldama","24.2910","-103.3940","Mexico","MX","MEX","Zacatecas","minor","14162","1484898373"
"Brownsville","Brownsville","25.8216","-80.2417","United States","US","USA","Florida","","17726","1840029024"
"Swinton","Swinton","53.4877","-1.3149","United Kingdom","GB","GBR","Rotherham","","15559","1826312183"
"Sāsan","Sasan","25.6883","86.1907","India","IN","IND","Bihār","","14054","1356090958"
"Kukës","Kukes","42.0833","20.4167","Albania","AL","ALB","Kukës","admin","16719","1008649172"
"Hiệp Hòa","Hiep Hoa","10.9289","106.8378","Vietnam","VN","VNM","Đồng Nai","","15468","1704506831"
"Pathum Thani","Pathum Thani","14.0500","100.4833","Thailand","TH","THA","Pathum Thani","admin","13987","1764434288"
"Harrow Weald","Harrow Weald","51.6040","-0.3390","United Kingdom","GB","GBR","Harrow","","11376","1826880341"
"Ezequiel Montes","Ezequiel Montes","20.6667","-99.8833","Mexico","MX","MEX","Querétaro","minor","14053","1484350732"
"Idylwood","Idylwood","38.8896","-77.2056","United States","US","USA","Virginia","","17687","1840006032"
"Valenton","Valenton","48.7450","2.4672","France","FR","FRA","Île-de-France","","14883","1250188744"
"Iselin","Iselin","40.5697","-74.3172","United States","US","USA","New Jersey","","17684","1840005418"
"Esquimalt","Esquimalt","48.4306","-123.4147","Canada","CA","CAN","British Columbia","","17655","1124990218"
"Sha Kok Mei","Sha Kok Mei","22.3784","114.1941","Hong Kong","HK","HKG","","","14522","1344631625"
"Wanstead","Wanstead","51.5778","0.0286","United Kingdom","GB","GBR","Redbridge","","11543","1826002020"
"La Llagosta","La Llagosta","41.5156","2.1928","Spain","ES","ESP","Catalonia","","13259","1724195852"
"Kaliro","Kaliro","0.8944","33.4994","Uganda","UG","UGA","Kaliro","admin","16796","1800764757"
"Guachochi","Guachochi","26.8194","-107.0700","Mexico","MX","MEX","Chihuahua","minor","14513","1484533430"
"Tak","Tak","16.8711","99.1250","Thailand","TH","THA","Tak","admin","17565","1764055292"
"Balaungi","Balaungi","30.7306","76.6937","India","IN","IND","Punjab","","10293","1356816825"
"Bryn Mawr-Skyway","Bryn Mawr-Skyway","47.4949","-122.2410","United States","US","USA","Washington","","17620","1840037012"
"Rustington","Rustington","50.8102","-0.5052","United Kingdom","GB","GBR","West Sussex","","13883","1826971079"
"Kamrāwān","Kamrawan","25.6956","85.8380","India","IN","IND","Bihār","","14768","1356728932"
"Takoma Park","Takoma Park","38.9810","-77.0028","United States","US","USA","Maryland","","17610","1840005850"
"Babhani Bholwa","Babhani Bholwa","26.0876","86.7456","India","IN","IND","Bihār","","15092","1356256590"
"Rossington","Rossington","53.4759","-1.0613","United Kingdom","GB","GBR","Doncaster","","13557","1826906333"
"Sudbury","Sudbury","51.5537","-0.3199","United Kingdom","GB","GBR","Ealing","","14950","1826460632"
"Füzuli","Fuzuli","39.6003","47.1431","Azerbaijan","AZ","AZE","Füzuli","admin","17500","1031872322"
"Phayao","Phayao","19.1653","99.9036","Thailand","TH","THA","Phayao","admin","17467","1764291128"
"Fatehpur","Fatehpur","25.5089","85.1939","India","IN","IND","Bihār","","13279","1356353116"
"Ilkley","Ilkley","53.9250","-1.8220","United Kingdom","GB","GBR","Bradford","","14809","1826221289"
"Deux-Montagnes","Deux-Montagnes","45.5333","-73.8833","Canada","CA","CAN","Quebec","","17553","1124980214"
"Saint-Brice-sous-Forêt","Saint-Brice-sous-Foret","48.9986","2.3569","France","FR","FRA","Île-de-France","","15043","1250836215"
"Miahuatlán","Miahuatlan","18.2833","-97.2833","Mexico","MX","MEX","Puebla","minor","13159","1484451722"
"Haubourdin","Haubourdin","50.6092","2.9869","France","FR","FRA","Hauts-de-France","","14775","1250525131"
"Manafwa","Manafwa","0.9564","34.2844","Uganda","UG","UGA","Manafwa","admin","16000","1800257696"
"Longwy","Longwy","49.5197","5.7606","France","FR","FRA","Grand Est","","14774","1250757730"
"Ovenden","Ovenden","53.7432","-1.8779","United Kingdom","GB","GBR","Calderdale","","12351","1826436931"
"Breyten","Breyten","-26.3000","29.9833","South Africa","ZA","ZAF","Mpumalanga","","14347","1710458798"
"Lowton","Lowton","53.4710","-2.5690","United Kingdom","GB","GBR","Wigan","","14605","1826374228"
"Patcham","Patcham","50.8640","-0.1500","United Kingdom","GB","GBR","Brighton and Hove","","14277","1826835720"
"Canet de Mar","Canet de Mar","41.5911","2.5828","Spain","ES","ESP","Catalonia","","14845","1724398623"
"Guerrero Negro","Guerrero Negro","27.9589","-114.0561","Mexico","MX","MEX","Baja California Sur","","13596","1484879768"
"Childwall","Childwall","53.3950","-2.8810","United Kingdom","GB","GBR","Liverpool","","14085","1826133113"
"Wattignies","Wattignies","50.5850","3.0431","France","FR","FRA","Hauts-de-France","","15075","1250089700"
"Shenley Church End","Shenley Church End","52.0220","-0.7880","United Kingdom","GB","GBR","Milton Keynes","","12961","1826650494"
"Busembatia","Busembatia","0.7750","33.6250","Uganda","UG","UGA","Bugweri","","14431","1800802251"
"Chāndi","Chandi","25.7296","87.5195","India","IN","IND","Bihār","","14933","1356259583"
"Glassmanor","Glassmanor","38.8181","-76.9833","United States","US","USA","Maryland","","17426","1840024545"
"Plungė","Plunge","55.9167","21.8500","Lithuania","LT","LTU","Plungė","admin","17385","1440542185"
"Belsandi Tāra","Belsandi Tara","25.7722","85.9608","India","IN","IND","Bihār","","13949","1356511922"
"Matam","Matam","15.6167","-13.3333","Senegal","SN","SEN","Matam","admin","17324","1686919750"
"Rāje","Raje","26.2216","86.1454","India","IN","IND","Bihār","","14248","1356003418"
"Newport Pagnell","Newport Pagnell","52.0870","-0.7220","United Kingdom","GB","GBR","Milton Keynes","","15118","1826046510"
"Tân Vạn","Tan Van","10.9119","106.8261","Vietnam","VN","VNM","Bình Dương","","14086","1704433068"
"Wahiawa","Wahiawa","21.5012","-158.0178","United States","US","USA","Hawaii","","17357","1840029550"
"San Ġwann","San Gwann","35.9094","14.4786","Malta","MT","MLT","San Ġwann","admin","12523","1470073144"
"Mugalivakkam","Mugalivakkam","13.0205","80.1653","India","IN","IND","Tamil Nādu","","12083","1356119465"
"Saint-André","Saint-Andre","50.6603","3.0439","France","FR","FRA","Hauts-de-France","","13151","1250188482"
"Ijra","Ijra","26.3119","86.0037","India","IN","IND","Bihār","","14745","1356811927"
"Birqāsh","Birqash","30.1692","31.0417","Egypt","EG","EGY","Al Jīzah","","14172","1818471185"
"Shiremoor","Shiremoor","55.0366","-1.5048","United Kingdom","GB","GBR","North Tyneside","","13300","1826653309"
"Boskoop","Boskoop","52.0667","4.6500","Netherlands","NL","NLD","Zuid-Holland","minor","15045","1528548722"
"Marsabit","Marsabit","2.3333","37.9833","Kenya","KE","KEN","Marsabit","admin","17127","1404055253"
"Udelnaya","Udelnaya","55.6333","38.0333","Russia","RU","RUS","Moskovskaya Oblast’","","14870","1643862855"
"Djambala","Djambala","-2.5500","14.7500","Congo (Brazzaville)","CG","COG","Plateaux","admin","17265","1178834249"
"Woodmere","Woodmere","40.6375","-73.7219","United States","US","USA","New York","","17267","1840005284"
"Bellaire","Bellaire","29.7040","-95.4621","United States","US","USA","Texas","","17262","1840019617"
"San Nicolás de los Ranchos","San Nicolas de los Ranchos","19.0667","-98.4833","Mexico","MX","MEX","Puebla","minor","11734","1484875886"
"Tabhka Khās","Tabhka Khas","25.6518","85.9426","India","IN","IND","Bihār","","15084","1356969970"
"Illizi","Illizi","26.5080","8.4829","Algeria","DZ","DZA","Illizi","admin","17252","1012499058"
"Kretinga","Kretinga","55.8900","21.2422","Lithuania","LT","LTU","Kretinga","admin","17207","1440720895"
"Petersfield","Petersfield","51.0038","-0.9374","United Kingdom","GB","GBR","Hampshire","","14974","1826282977"
"Trstenik","Trstenik","43.6186","20.9972","Serbia","RS","SRB","Trstenik","admin","17180","1688148503"
"Chaddesden","Chaddesden","52.9301","-1.4383","United Kingdom","GB","GBR","Derby","","13413","1826935490"
"Anta","Anta","41.0073","-8.6250","Portugal","PT","PRT","Aveiro","","14800","1620706972"
"Calkiní","Calkini","20.3667","-90.0500","Mexico","MX","MEX","Campeche","minor","14934","1484329915"
"Bhatranha","Bhatranha","25.9846","86.6348","India","IN","IND","Bihār","","15090","1356565056"
"Avon","Avon","48.4089","2.7250","France","FR","FRA","Île-de-France","","13662","1250308885"
"Tirumalaiyampālaiyam","Tirumalaiyampalaiyam","10.8790","76.9295","India","IN","IND","Tamil Nādu","","14699","1356159709"
"Marib","Marib","15.4606","45.3261","Yemen","YE","YEM","Ma’rib","admin","16794","1887153216"
"Massapequa Park","Massapequa Park","40.6817","-73.4496","United States","US","USA","New York","","17149","1840005307"
"Rive-de-Gier","Rive-de-Gier","45.5294","4.6169","France","FR","FRA","Auvergne-Rhône-Alpes","","15105","1250689659"
"Vicente Guerrero","Vicente Guerrero","30.7264","-115.9903","Mexico","MX","MEX","Baja California","","12829","1484159343"
"Egg Buckland","Egg Buckland","50.4006","-4.1136","United Kingdom","GB","GBR","Plymouth","","13351","1826735321"
"Ermita","Ermita","14.5830","120.9830","Philippines","PH","PHL","Manila","","10523","1608901388"
"East Riverdale","East Riverdale","38.9600","-76.9109","United States","US","USA","Maryland","","17136","1840031482"
"Tempoal de Sánchez","Tempoal de Sanchez","21.5167","-98.3833","Mexico","MX","MEX","Veracruz","minor","12237","1484011037"
"Primrose","Primrose","-26.1833","28.1667","South Africa","ZA","ZAF","Gauteng","","14024","1710333435"
"Pinewood","Pinewood","25.8697","-80.2174","United States","US","USA","Florida","","17110","1840029066"
"Nacozari de García","Nacozari de Garcia","30.3833","-109.6833","Mexico","MX","MEX","Sonora","minor","12987","1484112459"
"Prescot","Prescot","53.4286","-2.8064","United Kingdom","GB","GBR","Knowsley","","11184","1826329091"
"El Parral","El Parral","16.3662","-93.0067","Mexico","MX","MEX","Chiapas","","11949","1484888163"
"Hillcrest Heights","Hillcrest Heights","38.8373","-76.9641","United States","US","USA","Maryland","","17090","1840005957"
"Manassas Park","Manassas Park","38.7709","-77.4429","United States","US","USA","Virginia","","17081","1840003838"
"Makokou","Makokou","0.5667","12.8667","Gabon","GA","GAB","Ogooué-Ivindo","admin","17070","1266939354"
"Mexicaltzingo","Mexicaltzingo","19.2092","-99.5858","Mexico","MX","MEX","México","minor","10161","1484468778"
"Lodwar","Lodwar","3.1167","35.6000","Kenya","KE","KEN","Turkana","admin","16981","1404993573"
"Montesson","Montesson","48.9086","2.1494","France","FR","FRA","Île-de-France","","15013","1250211420"
"Neubiberg","Neubiberg","48.0833","11.6833","Germany","DE","DEU","Bavaria","","14538","1276417573"
"Shirebrook","Shirebrook","53.2048","-1.2197","United Kingdom","GB","GBR","Derbyshire","","13300","1826437553"
"West Boldon","West Boldon","54.9450","-1.4410","United Kingdom","GB","GBR","South Tyneside","","13271","1826523613"
"Āndipālaiyam","Andipalaiyam","11.0920","77.3150","India","IN","IND","Tamil Nādu","","14982","1356165565"
"San Ignacio","San Ignacio","17.1588","-89.0696","Belize","BZ","BLZ","Cayo","admin","16977","1084924884"
"Anzin","Anzin","50.3714","3.5044","France","FR","FRA","Hauts-de-France","","13329","1250236653"
"Adelphi","Adelphi","39.0017","-76.9649","United States","US","USA","Maryland","","16977","1840005944"
"Fraserburgh","Fraserburgh","57.6930","-2.0050","United Kingdom","GB","GBR","Aberdeenshire","","13180","1826713495"
"Arbon","Arbon","47.5167","9.4333","Switzerland","CH","CHE","Thurgau","","14537","1756486653"
"Ntungamo","Ntungamo","-0.8819","30.2653","Uganda","UG","UGA","Ntungamo","admin","16400","1800042819"
"Cachoeira Alta","Cachoeira Alta","-18.7628","-50.9419","Brazil","BR","BRA","Goiás","","10553","1076336461"
"Beur","Beur","25.5690","85.0944","India","IN","IND","Bihār","","13535","1356099347"
"New Milford","New Milford","40.9337","-74.0196","United States","US","USA","New Jersey","","16912","1840000908"
"Clayton","Clayton","38.6444","-90.3303","United States","US","USA","Missouri","","16905","1840007439"
"Sāhit","Sahit","25.5888","85.7948","India","IN","IND","Bihār","","14714","1356553966"
"Ramonville-Saint-Agne","Ramonville-Saint-Agne","43.5461","1.4756","France","FR","FRA","Occitanie","","14600","1250555797"
"Walsall Wood","Walsall Wood","52.6277","-1.9301","United Kingdom","GB","GBR","Walsall","","13207","1826891290"
"Phrae","Phrae","18.1453","100.1419","Thailand","TH","THA","Phrae","admin","16668","1764209592"
"Keetmanshoop","Keetmanshoop","-26.5786","18.1333","Namibia","NA","NAM","//Karas","admin","16800","1516607376"
"Bodmin","Bodmin","50.4660","-4.7180","United Kingdom","GB","GBR","Cornwall","","14736","1826850129"
"Astara","Astara","38.4561","48.8786","Azerbaijan","AZ","AZE","Astara","admin","16800","1031376971"
"Bommayapālaiyam","Bommayapalaiyam","11.9922","79.8499","India","IN","IND","Tamil Nādu","","8796","1356245678"
"Mollerusa","Mollerusa","41.6317","0.8961","Spain","ES","ESP","Catalonia","","14730","1724107781"
"Hollinwood","Hollinwood","53.5183","-2.1440","United Kingdom","GB","GBR","Oldham","","10920","1826177835"
"Zveçan","Zvecan","42.9000","20.8333","Kosovo","XK","XKS","Zveçan","admin","16650","1901208362"
"Streetly","Streetly","52.5770","-1.8840","United Kingdom","GB","GBR","Walsall","","13934","1826347383"
"Oulad Hamdane","Oulad Hamdane","32.3333","-6.3667","Morocco","MA","MAR","Béni Mellal-Khénifra","","11577","1504839995"
"Spitalfields","Spitalfields","51.5166","-0.0750","United Kingdom","GB","GBR","Tower Hamlets","","10286","1826499077"
"Wollaston","Wollaston","52.4619","-2.1663","United Kingdom","GB","GBR","Dudley","","13092","1826737467"
"Puerto Carreño","Puerto Carreno","6.1833","-67.4667","Colombia","CO","COL","Vichada","admin","16763","1170291142"
"Aylestone","Aylestone","52.6040","-1.1540","United Kingdom","GB","GBR","Leicester","","11151","1826967618"
"Perumbakkam","Perumbakkam","12.9055","80.1993","India","IN","IND","Tamil Nādu","","10666","1356409813"
"L’Ancienne-Lorette","L'Ancienne-Lorette","46.8000","-71.3500","Canada","CA","CAN","Quebec","","16745","1124580674"
"Ogíjares","Ogijares","37.1167","-3.6000","Spain","ES","ESP","Andalusia","","14627","1724843693"
"Pueblo Nuevo","Pueblo Nuevo","17.1576","-92.8973","Mexico","MX","MEX","Chiapas","minor","12805","1484535200"
"Tukums","Tukums","56.9667","23.1500","Latvia","LV","LVA","Tukums","admin","16691","1428304262"
"Bull Run","Bull Run","38.7802","-77.5204","United States","US","USA","Virginia","","16709","1840006093"
"Conisbrough","Conisbrough","53.4790","-1.2270","United Kingdom","GB","GBR","Doncaster","","14333","1826311843"
"Struga","Struga","41.1775","20.6789","Macedonia","MK","MKD","Struga","admin","16559","1807746682"
"Dayālpur Sāpha","Dayalpur Sapha","25.7122","85.3188","India","IN","IND","Bihār","","13021","1356063075"
"Ocean Pointe","Ocean Pointe","21.3145","-158.0289","United States","US","USA","Hawaii","","16676","1840039242"
"Greenville","Greenville","5.0167","-9.0333","Liberia","LR","LBR","Sinoe","admin","16434","1430362799"
"Fort Bonifacio","Fort Bonifacio","14.5311","121.0478","Philippines","PH","PHL","Makati","","11739","1608422255"
"Caerfyrddin","Caerfyrddin","51.8560","-4.3160","United Kingdom","GB","GBR","Carmarthenshire","","13148","1826550370"
"Thorpe Saint Andrew","Thorpe Saint Andrew","52.6354","1.3431","United Kingdom","GB","GBR","Norfolk","","14556","1826296791"
"Palmetto Estates","Palmetto Estates","25.6211","-80.3616","United States","US","USA","Florida","","16612","1840029063"
"Elesbão Veloso","Elesbao Veloso","-6.2019","-42.1400","Brazil","BR","BRA","Piauí","","14512","1076369423"
"Four Corners","Four Corners","44.9290","-122.9732","United States","US","USA","Oregon","","16608","1840034722"
"San Juan Bautista","San Juan Bautista","-26.6333","-57.1667","Paraguay","PY","PRY","Misiones","admin","16593","1600185723"
"Bellmore","Bellmore","40.6569","-73.5285","United States","US","USA","New York","","16603","1840005232"
"Manappakkam","Manappakkam","13.0108","80.1686","India","IN","IND","Tamil Nādu","","11358","1356119464"
"Nalambūr","Nalambur","13.0867","80.1703","India","IN","IND","Tamil Nādu","","11344","1356028475"
"Courcouronnes","Courcouronnes","48.6239","2.4294","France","FR","FRA","Île-de-France","","13490","1250579473"
"Sendafa","Sendafa","9.1500","39.0333","Ethiopia","ET","ETH","Oromīya","","12298","1231004037"
"Qusar","Qusar","41.4264","48.4356","Azerbaijan","AZ","AZE","Qusar","admin","16500","1031059559"
"Santa Cruz Amilpas","Santa Cruz Amilpas","17.0667","-96.6833","Mexico","MX","MEX","Oaxaca","","12814","1484665188"
"San Miguel Xoxtla","San Miguel Xoxtla","19.1833","-98.3000","Mexico","MX","MEX","Puebla","","12341","1484989109"
"Hawick","Hawick","55.4220","-2.7870","United Kingdom","GB","GBR","Scottish Borders","","13740","1826156666"
"Ojus","Ojus","25.9563","-80.1606","United States","US","USA","Florida","","16530","1840028742"
"Mongat","Mongat","41.4667","2.2790","Spain","ES","ESP","Catalonia","","12277","1724914506"
"Narhan","Narhan","25.6941","86.0074","India","IN","IND","Bihār","","14340","1356322684"
"Wolverton","Wolverton","52.0626","-0.8102","United Kingdom","GB","GBR","Milton Keynes","","12492","1826613545"
"Jamira","Jamira","25.5535","84.7063","India","IN","IND","Bihār","","13469","1356641384"
"Linlithgow","Linlithgow","55.9791","-3.6105","United Kingdom","GB","GBR","West Lothian","","13260","1826000089"
"Walnut Park","Walnut Park","33.9682","-118.2220","United States","US","USA","California","","16453","1840019218"
"Burslem","Burslem","53.0426","-2.1879","United Kingdom","GB","GBR","Stoke-on-Trent","","11314","1826767635"
"Bordj Mokhtar","Bordj Mokhtar","21.3289","0.9542","Algeria","DZ","DZA","Bordj Badji Mokhtar","admin","16437","1012945794"
"Broadwater","Broadwater","50.8282","-0.3742","United Kingdom","GB","GBR","West Sussex","","9373","1826765618"
"Vengavasal","Vengavasal","12.8991","80.1690","India","IN","IND","Tamil Nādu","","11737","1356085459"
"Mariakerke","Mariakerke","51.0728","3.6761","Belgium","BE","BEL","Flanders","","14140","1056784133"
"Janāi","Janai","22.7157","88.2426","India","IN","IND","West Bengal","","12837","1356241630"
"Castellanza","Castellanza","45.6167","8.9000","Italy","IT","ITA","Lombardy","","14340","1380085680"
"Persan","Persan","49.1533","2.2711","France","FR","FRA","Île-de-France","","13734","1250727740"
"Hall in Tirol","Hall in Tirol","47.2833","11.5000","Austria","AT","AUT","Tirol","","13897","1040200365"
"Tamarakulam","Tamarakulam","8.1325","77.4848","India","IN","IND","Tamil Nādu","","14188","1356226769"
"Vincent","Vincent","34.0983","-117.9238","United States","US","USA","California","","16399","1840074687"
"Ranong","Ranong","9.9619","98.6389","Thailand","TH","THA","Ranong","admin","16125","1764623795"
"Artesia","Artesia","33.8676","-118.0805","United States","US","USA","California","","16390","1840019224"
"Milford Haven","Milford Haven","51.7142","-5.0427","United Kingdom","GB","GBR","Pembrokeshire","","13725","1826680472"
"Anenecuilco","Anenecuilco","18.7781","-98.9850","Mexico","MX","MEX","Morelos","","11227","1484003918"
"Senglea","Senglea","35.8878","14.5169","Malta","MT","MLT","Isla","admin","2784","1470598185"
"Rakvere","Rakvere","59.3500","26.3500","Estonia","EE","EST","Rakvere","admin","14984","1233049348"
"Moussoro","Moussoro","13.6333","16.4833","Chad","TD","TCD","Barh-El-Gazel","admin","16349","1148918270"
"Mahikeng","Mahikeng","-25.8656","25.6436","South Africa","ZA","ZAF","North West","admin","15117","1710965423"
"Srbobran","Srbobran","45.5333","19.7833","Serbia","RS","SRB","Srbobran","admin","16317","1688431448"
"Aldama","Aldama","22.9194","-98.0736","Mexico","MX","MEX","Tamaulipas","minor","13661","1484835121"
"South Houston","South Houston","29.6611","-95.2285","United States","US","USA","Texas","","16354","1840022199"
"Daharia","Daharia","25.5307","87.5947","India","IN","IND","Bihār","","12678","1356461587"
"Altamirano","Altamirano","16.7361","-92.0389","Mexico","MX","MEX","Chiapas","minor","10618","1484175446"
"North Arlington","North Arlington","40.7874","-74.1273","United States","US","USA","New Jersey","","16339","1840000909"
"Stanford","Stanford","37.4252","-122.1674","United States","US","USA","California","","16333","1840028400"
"Gopālpur","Gopalpur","26.1317","84.3594","India","IN","IND","Bihār","","13738","1356544718"
"Baranzate","Baranzate","45.5167","9.1000","Italy","IT","ITA","Lombardy","","12003","1380987806"
"Mouvaux","Mouvaux","50.7033","3.1406","France","FR","FRA","Hauts-de-France","","13173","1250337691"
"Nakhon Nayok","Nakhon Nayok","14.2069","101.2142","Thailand","TH","THA","Nakhon Nayok","admin","16195","1764068248"
"Chojnów","Chojnow","51.2667","15.9333","Poland","PL","POL","Dolnośląskie","","13904","1616584715"
"Balaxanı","Balaxani","40.4617","49.9200","Azerbaijan","AZ","AZE","Bakı","","11615","1031357692"
"Sedgley","Sedgley","52.5400","-2.1230","United Kingdom","GB","GBR","Dudley","","12087","1826101730"
"Solaro","Solaro","45.6150","9.0839","Italy","IT","ITA","Lombardy","","14163","1380926432"
"Southborough","Southborough","51.1598","0.2652","United Kingdom","GB","GBR","Kent","","11124","1826565398"
"Kotwāpatti Rāmpur","Kotwapatti Rampur","25.7153","84.8063","India","IN","IND","Bihār","","12484","1356235036"
"Massakory","Massakory","13.0000","15.7333","Chad","TD","TCD","Hadjer-Lamis","admin","16237","1148908311"
"Naama","Naama","33.2678","-0.3111","Algeria","DZ","DZA","Naama","admin","16251","1012850542"
"Morangis","Morangis","48.7064","2.3347","France","FR","FRA","Île-de-France","","13453","1250948909"
"Kiratpur Rājārām","Kiratpur Rajaram","25.8560","85.2850","India","IN","IND","Bihār","","11074","1356125591"
"Šilutė","Silute","55.3500","21.4833","Lithuania","LT","LTU","Šilutė","admin","16200","1440568024"
"Kabugao","Kabugao","18.0239","121.1833","Philippines","PH","PHL","Apayao","admin","16215","1608841504"
"Koulamoutou","Koulamoutou","-1.1333","12.4833","Gabon","GA","GAB","Ogooué-Lolo","admin","16222","1266049565"
"La Grange","La Grange","41.8072","-87.8741","United States","US","USA","Illinois","","16220","1840011255"
"Varedo","Varedo","45.6000","9.1667","Italy","IT","ITA","Lombardy","","13446","1380710950"
"Barwat Pasrāin","Barwat Pasrain","26.7925","84.5469","India","IN","IND","Bihār","","12970","1356005091"
"Weehawken","Weehawken","40.7677","-74.0168","United States","US","USA","New Jersey","","16215","1840081754"
"Minehead","Minehead","51.2038","-3.4738","United Kingdom","GB","GBR","Somerset","","11981","1826790025"
"Bačka Topola","Backa Topola","45.8167","19.6333","Serbia","RS","SRB","Bačka Topola","admin","16171","1688533352"
"Auray","Auray","47.6678","-2.9825","France","FR","FRA","Bretagne","","14141","1250657845"
"Monte di Procida","Monte di Procida","40.8000","14.0500","Italy","IT","ITA","Campania","","12743","1380840783"
"Bāhāgalpur","Bahagalpur","24.5934","87.9536","India","IN","IND","West Bengal","","11528","1356004919"
"Floral Park","Floral Park","40.7227","-73.7029","United States","US","USA","New York","","16180","1840005286"
"Muragācha","Muragacha","22.6900","88.4200","India","IN","IND","West Bengal","","13249","1356999062"
"Bacalar","Bacalar","18.6769","-88.3953","Mexico","MX","MEX","Quintana Roo","","12527","1484206678"
"Coseley","Coseley","52.5500","-2.0830","United Kingdom","GB","GBR","Dudley","","12357","1826312383"
"Chard","Chard","50.8728","-2.9587","United Kingdom","GB","GBR","Somerset","","13074","1826438736"
"Buckingham","Buckingham","51.9950","-0.9860","United Kingdom","GB","GBR","Buckinghamshire","","12890","1826027326"
"Tullamore","Tullamore","53.2667","-7.5000","Ireland","IE","IRL","Offaly","admin","14607","1372035774"
"Kerugoya","Kerugoya","-0.5000","37.2833","Kenya","KE","KEN","Kirinyaga","admin","14056","1404597807"
"Kinkala","Kinkala","-4.3614","14.7644","Congo (Brazzaville)","CG","COG","Pool","admin","16132","1178867565"
"Lomma","Lomma","55.6667","13.0833","Sweden","SE","SWE","Skåne","minor","13772","1752810659"
"Bela","Bela","25.8103","85.0157","India","IN","IND","Bihār","","14094","1356055471"
"Sutton on Hull","Sutton on Hull","53.7806","-0.3047","United Kingdom","GB","GBR","Kingston upon Hull, City of","","12649","1826733547"
"Parkway","Parkway","38.4993","-121.4520","United States","US","USA","California","","16091","1840024628"
"South River","South River","40.4455","-74.3783","United States","US","USA","New Jersey","","16087","1840001335"
"Ottaikkālmantapam","Ottaikkalmantapam","10.8827","76.9943","India","IN","IND","Tamil Nādu","","13622","1356159706"
"Arpajon","Arpajon","48.5903","2.2478","France","FR","FRA","Île-de-France","","11355","1250815243"
"Wezembeek-Oppem","Wezembeek-Oppem","50.8333","4.5000","Belgium","BE","BEL","Flanders","","14021","1056614570"
"Al Ḩazm","Al Hazm","16.1642","44.7767","Yemen","YE","YEM","Al Jawf","admin","16044","1887549671"
"Ālampur Gonpura","Alampur Gonpura","25.5404","85.0511","India","IN","IND","Bihār","","12686","1356937815"
"Kraainem","Kraainem","50.8619","4.4572","Belgium","BE","BEL","Flanders","","13690","1056363403"
"Midsomer Norton","Midsomer Norton","51.2842","-2.4817","United Kingdom","GB","GBR","Bath and North East Somerset","","13000","1826227978"
"Simri","Simri","26.1639","85.7617","India","IN","IND","Bihār","","13775","1356103729"
"Belwāra","Belwara","25.7443","86.4755","India","IN","IND","Bihār","","13951","1356378203"
"Milngavie","Milngavie","55.9421","-4.3137","United Kingdom","GB","GBR","East Dunbartonshire","","12795","1826013201"
"Ascensión","Ascension","31.0928","-107.9964","Mexico","MX","MEX","Chihuahua","minor","13456","1484659928"
"Fleury-Mérogis","Fleury-Merogis","48.6294","2.3614","France","FR","FRA","Île-de-France","","13917","1250126617"
"Ángel R. Cabada","Angel R. Cabada","18.5969","-95.4453","Mexico","MX","MEX","Veracruz","minor","11689","1484667573"
"Montévrain","Montevrain","48.8753","2.7456","France","FR","FRA","Île-de-France","","13517","1250069787"
"Căuşeni","Causeni","46.6333","29.4000","Moldova","MD","MDA","Căuşeni","admin","15939","1498826686"
"Corsham","Corsham","51.4340","-2.1850","United Kingdom","GB","GBR","Wiltshire","","13369","1826346821"
"Chhapra","Chhapra","26.4005","85.3721","India","IN","IND","Bihār","","13242","1356942378"
"Fontenay-le-Fleury","Fontenay-le-Fleury","48.8136","2.0486","France","FR","FRA","Île-de-France","","13494","1250728330"
"Alotau","Alotau","-10.3167","150.4333","Papua New Guinea","PG","PNG","Milne Bay","admin","15939","1598284215"
"Southwick","Southwick","50.8360","-0.2390","United Kingdom","GB","GBR","West Sussex","","13195","1826708962"
"Lye","Lye","52.4590","-2.1160","United Kingdom","GB","GBR","Dudley","","12346","1826947385"
"Villa Corzo","Villa Corzo","16.1848","-93.2677","Mexico","MX","MEX","Chiapas","minor","10841","1484270020"
"Barentu","Barentu","15.1167","37.6000","Eritrea","ER","ERI","Gash-Barka","admin","15891","1232695352"
"Ayodhyāpattanam","Ayodhyapattanam","11.6755","78.2409","India","IN","IND","Tamil Nādu","","13122","1356219753"
"Bijelo Polje","Bijelo Polje","43.0400","19.7500","Montenegro","ME","MNE","Bijelo Polje","admin","15883","1499452756"
"Anadyr","Anadyr","64.7333","177.5167","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","admin","15604","1643453469"
"Thatto Heath","Thatto Heath","53.4352","-2.7481","United Kingdom","GB","GBR","St. Helens","","12280","1826530884"
"Parihāra","Parihara","25.5378","86.2728","India","IN","IND","Bihār","","13848","1356541851"
"Maliana","Maliana","-8.9917","125.2197","Timor-Leste","TL","TLS","Bobonaro","admin","15800","1626637884"
"Ericeira","Ericeira","38.9620","-9.4180","Portugal","PT","PRT","Lisboa","","10260","1620634917"
"Richmond","Richmond","-41.3333","173.1833","New Zealand","NZ","NZL","Tasman","admin","15000","1554001427"
"Tenango del Aire","Tenango del Aire","19.1575","-98.8581","Mexico","MX","MEX","México","minor","12470","1484285163"
"Aleg","Aleg","17.0500","-13.9167","Mauritania","MR","MRT","Brakna","admin","15521","1478173139"
"Parihārpur","Pariharpur","26.4026","86.1256","India","IN","IND","Bihār","","13407","1356201145"
"New Cassel","New Cassel","40.7602","-73.5649","United States","US","USA","New York","","15841","1840005239"
"Isla Mujeres","Isla Mujeres","21.2084","-86.7115","Mexico","MX","MEX","Quintana Roo","minor","12491","1484856745"
"Blunsdon Saint Andrew","Blunsdon Saint Andrew","51.6100","-1.7900","United Kingdom","GB","GBR","Swindon","","12414","1826285391"
"Neykkārappatti","Neykkarappatti","11.6225","78.1058","India","IN","IND","Tamil Nādu","","13027","1356219731"
"Sisai","Sisai","26.1899","84.5643","India","IN","IND","Bihār","","13596","1356750651"
"Asperg","Asperg","48.9000","9.1333","Germany","DE","DEU","Baden-Württemberg","","13486","1276656917"
"Altay","Altay","46.3728","96.2572","Mongolia","MN","MNG","Govĭ-Altay","admin","15800","1496574715"
"Bartica","Bartica","6.4000","-58.6167","Guyana","GY","GUY","Cuyuni-Mazaruni","admin","8532","1328806426"
"Whakatane","Whakatane","-37.9600","176.9800","New Zealand","NZ","NZL","Bay of Plenty","admin","15795","1554253729"
"Titel","Titel","45.2047","20.3000","Serbia","RS","SRB","Titel","admin","15738","1688633486"
"Rahui","Rahui","25.2728","85.5555","India","IN","IND","Bihār","","13545","1356085595"
"Glenmont","Glenmont","39.0698","-77.0467","United States","US","USA","Maryland","","15795","1840024490"
"Brownhills","Brownhills","52.6470","-1.9330","United Kingdom","GB","GBR","Walsall","","12676","1826680163"
"Stone Ridge","Stone Ridge","38.9294","-77.5557","United States","US","USA","Virginia","","15776","1840041868"
"Westbury","Westbury","40.7599","-73.5891","United States","US","USA","New York","","15772","1840005332"
"Lwengo","Lwengo","-0.3911","31.4606","Uganda","UG","UGA","Lwengo","admin","15527","1800583650"
"Sukhothai","Sukhothai","17.0000","99.8167","Thailand","TH","THA","Sukhothai","admin","15618","1764175777"
"Patālia","Patalia","25.6600","86.0066","India","IN","IND","Bihār","","13572","1356703536"
"Villa Juárez","Villa Juarez","27.1278","-109.8426","Mexico","MX","MEX","Sonora","minor","13153","1484467834"
"Isla Vista","Isla Vista","34.4128","-119.8614","United States","US","USA","California","","15732","1840017887"
"Mahespur","Mahespur","24.8616","88.0447","India","IN","IND","West Bengal","","12250","1356131619"
"Allestree","Allestree","52.9519","-1.4856","United Kingdom","GB","GBR","Derby","","13622","1826396769"
"Eckington","Eckington","53.3080","-1.3643","United Kingdom","GB","GBR","Derbyshire","","11152","1826393351"
"White Center","White Center","47.5086","-122.3479","United States","US","USA","Washington","","15716","1840037727"
"Épinay-sous-Sénart","Epinay-sous-Senart","48.6931","2.5158","France","FR","FRA","Île-de-France","","12279","1250068767"
"Desri","Desri","25.6691","85.4082","India","IN","IND","Bihār","","12324","1356309525"
"Hoyland Nether","Hoyland Nether","53.4985","-1.4406","United Kingdom","GB","GBR","Barnsley","","11852","1826976360"
"Jaltenango","Jaltenango","15.8725","-92.7250","Mexico","MX","MEX","Chiapas","minor","11875","1484260963"
"Chepstow","Chepstow","51.6420","-2.6750","United Kingdom","GB","GBR","Monmouthshire","","12350","1826986519"
"Maqsūda","Maqsuda","26.2410","86.0585","India","IN","IND","Bihār","","13115","1356976484"
"Giffnock","Giffnock","55.8051","-4.2946","United Kingdom","GB","GBR","East Renfrewshire","","12300","1826874548"
"Foammulah","Foammulah","-0.2932","73.4236","Maldives","MV","MDV","Fuvammulah","admin","13037","1462000002"
"Peshkopi","Peshkopi","41.6833","20.4281","Albania","AL","ALB","Dibër","admin","13251","1008412550"
"Targuist","Targuist","34.9500","-4.3000","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","13390","1504459578"
"La Palma","La Palma","33.8504","-118.0407","United States","US","USA","California","","15650","1840020586"
"Charābidya","Charabidya","22.3271","88.7879","India","IN","IND","West Bengal","","13035","1356210457"
"Wallingford","Wallingford","51.5990","-1.1250","United Kingdom","GB","GBR","Oxfordshire","","11600","1826443803"
"Uthai Thani","Uthai Thani","15.3800","100.0250","Thailand","TH","THA","Uthai Thani","admin","15434","1764844835"
"Seaford","Seaford","40.6678","-73.4922","United States","US","USA","New York","","15636","1840005275"
"Castle Bromwich","Castle Bromwich","52.5050","-1.7856","United Kingdom","GB","GBR","Birmingham","","11217","1826340302"
"Vaires-sur-Marne","Vaires-sur-Marne","48.8742","2.6381","France","FR","FRA","Île-de-France","","13383","1250300713"
"Edineţ","Edinet","48.1667","27.3167","Moldova","MD","MDA","Edineţ","admin","15520","1498114952"
"Billère","Billere","43.3025","-0.3972","France","FR","FRA","Nouvelle-Aquitaine","","12793","1250635282"
"Nuevo Ideal","Nuevo Ideal","24.8875","-105.0728","Mexico","MX","MEX","Durango","minor","12850","1484407989"
"Sarmastpur","Sarmastpur","25.9490","85.5751","India","IN","IND","Bihār","","12043","1356590671"
"Hévié","Hevie","6.4167","2.2500","Benin","BJ","BEN","Atlantique","","13450","1204788471"
"Great Driffield","Great Driffield","54.0050","-0.4380","United Kingdom","GB","GBR","East Riding of Yorkshire","","13080","1826244451"
"Cherryland","Cherryland","37.6792","-122.1038","United States","US","USA","California","","15552","1840028361"
"Chilwell","Chilwell","52.9160","-1.2350","United Kingdom","GB","GBR","Nottinghamshire","","12864","1826053843"
"Marungūr","Marungur","8.1721","77.5037","India","IN","IND","Tamil Nādu","","13335","1356204785"
"Saucillo","Saucillo","28.0333","-105.2833","Mexico","MX","MEX","Chihuahua","minor","11004","1484590798"
"El Carmen","El Carmen","13.7167","-88.9000","El Salvador","SV","SLV","Cuscatlán","","13345","1222575606"
"Seïada","Seiada","35.6700","10.9000","Tunisia","TN","TUN","Monastir","","12708","1788658832"
"Tari","Tari","-5.8489","142.9506","Papua New Guinea","PG","PNG","Hela","admin","15413","1598669271"
"Sedaví","Sedavi","39.4271","-0.3849","Spain","ES","ESP","Valencia","","10349","1724655094"
"Gombe","Gombe","0.1811","32.1142","Uganda","UG","UGA","Butambala","admin","15196","1800068643"
"Ormesby","Ormesby","54.5492","-1.1817","United Kingdom","GB","GBR","Middlesbrough","","10714","1826125130"
"Khānpur","Khanpur","25.8572","85.9330","India","IN","IND","Bihār","","12066","1356667678"
"Princeton Meadows","Princeton Meadows","40.3347","-74.5651","United States","US","USA","New Jersey","","15491","1840033474"
"Ghordaur","Ghordaur","25.7089","86.6120","India","IN","IND","Bihār","","13417","1356528094"
"Nohsa","Nohsa","25.5653","85.0558","India","IN","IND","Bihār","","12551","1356841424"
"Voisins-le-Bretonneux","Voisins-le-Bretonneux","48.7583","2.0508","France","FR","FRA","Île-de-France","","10921","1250010206"
"Santa María Jalapa del Marqués","Santa Maria Jalapa del Marques","16.4401","-95.4434","Mexico","MX","MEX","Oaxaca","minor","13148","1484628971"
"Phra Samut Chedi","Phra Samut Chedi","13.5976","100.5848","Thailand","TH","THA","Samut Prakan","minor","13082","1764769560"
"La Magdalena Chichicaspa","La Magdalena Chichicaspa","19.4181","-99.3228","Mexico","MX","MEX","México","","12193","1484790302"
"Schwyz","Schwyz","47.0167","8.6500","Switzerland","CH","CHE","Schwyz","admin","15181","1756270644"
"Saint Budeaux","Saint Budeaux","50.4033","-4.1856","United Kingdom","GB","GBR","Plymouth","","13369","1826218483"
"Kürdəmir","Kurdamir","40.3383","48.1608","Azerbaijan","AZ","AZE","Kürdəmir","admin","15385","1031160110"
"Blundellsands","Blundellsands","53.4800","-3.0500","United Kingdom","GB","GBR","Sefton","","11514","1826007490"
"Mountain Ash","Mountain Ash","51.6814","-3.3792","United Kingdom","GB","GBR","Rhondda Cynon Taff","","11230","1826926732"
"Chandi","Chandi","22.3503","88.2828","India","IN","IND","West Bengal","","13332","1356146343"
"Rayón","Rayon","19.1481","-99.5800","Mexico","MX","MEX","México","minor","8590","1484649015"
"Saint-Max","Saint-Max","48.7011","6.2067","France","FR","FRA","Grand Est","","10019","1250606222"
"Cuencamé de Ceniceros","Cuencame de Ceniceros","24.8667","-103.7000","Mexico","MX","MEX","Durango","minor","9848","1484919654"
"Kapchorwa","Kapchorwa","1.4000","34.4500","Uganda","UG","UGA","Kapchorwa","admin","12900","1800170407"
"Earlestown","Earlestown","53.4500","-2.6500","United Kingdom","GB","GBR","St. Helens","","10830","1826235843"
"Snaresbrook","Snaresbrook","51.5870","0.0146","United Kingdom","GB","GBR","Redbridge","","11868","1826731920"
"La Tour-de-Peilz","La Tour-de-Peilz","46.4500","6.8667","Switzerland","CH","CHE","Vaud","","11829","1756611497"
"Dzuunmod","Dzuunmod","47.7069","106.9494","Mongolia","MN","MNG","Töv","admin","14660","1496245822"
"Knutsford","Knutsford","53.3025","-2.3708","United Kingdom","GB","GBR","Cheshire East","","13259","1826240471"
"El Fuerte","El Fuerte","26.4214","-108.6200","Mexico","MX","MEX","Sinaloa","minor","12566","1484950387"
"Kamnik","Kamnik","46.2257","14.6120","Slovenia","SI","SVN","Kamnik","admin","13880","1705864078"
"Goh","Goh","24.9845","84.6498","India","IN","IND","Bihār","","13297","1356422757"
"Cadereyta","Cadereyta","20.7000","-99.8167","Mexico","MX","MEX","Querétaro","minor","13347","1484014263"
"Kanungu","Kanungu","-0.8969","29.7756","Uganda","UG","UGA","Kanungu","admin","15056","1800715667"
"Zombo","Zombo","2.5131","30.9081","Uganda","UG","UGA","Zombo","admin","15000","1800293674"
"Santa Venera","Santa Venera","35.8897","14.4778","Malta","MT","MLT","Santa Venera","admin","6932","1470983296"
"Santa María Huazolotitlán","Santa Maria Huazolotitlan","16.3041","-97.9135","Mexico","MX","MEX","Oaxaca","minor","11400","1484964628"
"Santa Ana","Santa Ana","9.9320","-84.1760","Costa Rica","CR","CRI","San José","","12956","1188252548"
"Ainsdale","Ainsdale","53.6021","-3.0405","United Kingdom","GB","GBR","Sefton","","12723","1826404186"
"Jalpura","Jalpura","25.4862","84.7370","India","IN","IND","Bihār","","12621","1356041298"
"Kottāram","Kottaram","8.1188","77.5243","India","IN","IND","Tamil Nādu","","13093","1356204817"
"Asbury Park","Asbury Park","40.2226","-74.0119","United States","US","USA","New Jersey","","15305","1840003684"
"Namanga","Namanga","-2.5500","36.7833","Kenya","KE","KEN","Nyamira","","13193","1404000001"
"Saktipur","Saktipur","23.8640","88.1987","India","IN","IND","West Bengal","","13123","1356407230"
"Radviliškis","Radviliskis","55.8000","23.5500","Lithuania","LT","LTU","Radviliškis","admin","15275","1440712589"
"Norridge","Norridge","41.9637","-87.8231","United States","US","USA","Illinois","","15294","1840011302"
"Harper Woods","Harper Woods","42.4390","-82.9293","United States","US","USA","Michigan","","15287","1840003981"
"Parilla","Parilla","17.9119","-92.9175","Mexico","MX","MEX","Tabasco","","10036","1484710529"
"Karrānah","Karranah","26.2306","50.5108","Bahrain","BH","BHR","Ash Shamālīyah","","12000","1048633318"
"Bou Fekrane","Bou Fekrane","33.7667","-5.4833","Morocco","MA","MAR","Fès-Meknès","","12941","1504146532"
"Marauatpur","Marauatpur","25.6165","85.4685","India","IN","IND","Bihār","","11971","1356673491"
"Zubin Potok","Zubin Potok","42.9167","20.6833","Kosovo","XK","XKS","Zubin Potok","admin","15200","1901608808"
"Santa Ana","Santa Ana","30.5406","-111.1205","Mexico","MX","MEX","Sonora","minor","12372","1484002576"
"Haddon","Haddon","39.9063","-75.0625","United States","US","USA","New Jersey","","15243","1840081686"
"Berkley","Berkley","42.4986","-83.1853","United States","US","USA","Michigan","","15234","1840002437"
"Chāndpura","Chandpura","25.4966","86.2104","India","IN","IND","Bihār","","11905","1356083824"
"Holyhead","Holyhead","53.3090","-4.6330","United Kingdom","GB","GBR","Isle of Anglesey","","11431","1826382629"
"Parkstone","Parkstone","50.7100","-1.9500","United Kingdom","GB","GBR","","","10779","1826119514"
"Phôngsali","Phongsali","21.6833","102.1000","Laos","LA","LAO","Phôngsali","admin","15083","1418524729"
"Calvizzano","Calvizzano","40.9000","14.1833","Italy","IT","ITA","Campania","","12182","1380689845"
"Chinsali","Chinsali","-10.5522","32.0692","Zambia","ZM","ZMB","Muchinga","admin","15198","1894041491"
"Hazel Park","Hazel Park","42.4619","-83.0977","United States","US","USA","Michigan","","15213","1840002444"
"Campbelltown","Campbelltown","-34.0733","150.8261","Australia","AU","AUS","New South Wales","","12566","1036474450"
"Las Veredas","Las Veredas","23.1500","-109.7000","Mexico","MX","MEX","Baja California Sur","","10478","1484096349"
"Sukurhutu","Sukurhutu","23.4433","85.2917","India","IN","IND","Jhārkhand","","11650","1356177695"
"Kenmore","Kenmore","42.9646","-78.8713","United States","US","USA","New York","","15207","1840004386"
"Wordsley","Wordsley","52.4830","-2.1500","United Kingdom","GB","GBR","Dudley","","12582","1826751704"
"Liestal","Liestal","47.4667","7.7333","Switzerland","CH","CHE","Basel-Landschaft","admin","14390","1756371637"
"Trbovlje","Trbovlje","46.1500","15.0500","Slovenia","SI","SVN","Trbovlje","admin","13822","1705370010"
"Escuintla","Escuintla","15.3193","-92.6586","Mexico","MX","MEX","Chiapas","minor","10624","1484508510"
"Pacific Grove","Pacific Grove","36.6188","-121.9259","United States","US","USA","California","","15169","1840020359"
"Chêne-Bougeries","Chene-Bougeries","46.1833","6.1833","Switzerland","CH","CHE","Genève","","12215","1756147685"
"Paro","Paro","27.4333","89.4167","Bhutan","BT","BTN","Paro","admin","15000","1064216277"
"Pānāpur Langa","Panapur Langa","25.7230","85.2878","India","IN","IND","Bihār","","11478","1356404275"
"Sarpavaram","Sarpavaram","17.0102","82.2250","India","IN","IND","Andhra Pradesh","","13073","1356128297"
"Jasauli","Jasauli","26.4996","84.8171","India","IN","IND","Bihār","","12781","1356630186"
"Culcheth","Culcheth","53.4517","-2.5218","United Kingdom","GB","GBR","Warrington","","11454","1826044537"
"Cromer","Cromer","52.9310","1.3020","United Kingdom","GB","GBR","Norfolk","","12457","1826263534"
"North New Hyde Park","North New Hyde Park","40.7460","-73.6876","United States","US","USA","New York","","15125","1840005243"
"Sākhmohan","Sakhmohan","25.6325","85.9859","India","IN","IND","Bihār","","12939","1356048948"
"Ecublens","Ecublens","46.5276","6.5605","Switzerland","CH","CHE","Vaud","","12854","1756001755"
"Libertad","Libertad","-34.6333","-56.6192","Uruguay","UY","URY","San José","","11458","1858652953"
"Lerma","Lerma","19.8000","-90.6000","Mexico","MX","MEX","Campeche","","8971","1484035396"
"Quthing","Quthing","-30.4001","27.7002","Lesotho","LS","LSO","Quthing","admin","15000","1426618526"
"Kidal","Kidal","18.4389","1.4083","Mali","ML","MLI","Kidal","admin","15086","1466444510"
"Gwanda","Gwanda","-20.9389","29.0186","Zimbabwe","ZW","ZWE","Matabeleland South","admin","14450","1716612305"
"West Park","West Park","25.9840","-80.1923","United States","US","USA","Florida","","15074","1840016002"
"San Pedro Jicayán","San Pedro Jicayan","16.4167","-97.9833","Mexico","MX","MEX","Oaxaca","minor","12387","1484151830"
"Masallı","Masalli","39.0342","48.6656","Azerbaijan","AZ","AZE","Masallı","admin","14746","1031126416"
"Masho Khel","Masho Khel","33.9103","71.5025","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","12240","1586821243"
"Fayrōz Kōh","Fayroz Koh","34.5225","65.2517","Afghanistan","AF","AFG","Ghōr","admin","15000","1004988038"
"Nalerigu","Nalerigu","10.5333","-0.3667","Ghana","GH","GHA","North East","admin","14927","1288845503"
"Gaurihar Khāliqnagar","Gaurihar Khaliqnagar","25.9356","85.4671","India","IN","IND","Bihār","","11481","1356507751"
"Leopold","Leopold","-38.1892","144.4644","Australia","AU","AUS","Victoria","","12814","1036263278"
"South Farmingdale","South Farmingdale","40.7175","-73.4473","United States","US","USA","New York","","15009","1840005276"
"Woolton","Woolton","53.3740","-2.8650","United Kingdom","GB","GBR","Liverpool","","12921","1826782144"
"Katarmāla","Katarmala","25.5036","86.2447","India","IN","IND","Bihār","","12992","1356955745"
"Escaldes-Engordany","Escaldes-Engordany","42.5089","1.5408","Andorra","AD","AND","Escaldes-Engordany","admin","14521","1020008674"
"Leāma","Leama","26.2140","85.9980","India","IN","IND","Bihār","","11553","1356631096"
"Baucau","Baucau","-8.4667","126.4500","Timor-Leste","TL","TLS","Baucau","admin","14961","1626835210"
"Halawa","Halawa","21.3753","-157.9185","United States","US","USA","Hawaii","","14990","1840029579"
"Forécariah","Forecariah","9.4300","-13.0980","Guinea","GN","GIN","Kindia","minor","12358","1324863095"
"Killamarsh","Killamarsh","53.3205","-1.3116","United Kingdom","GB","GBR","Derbyshire","","9445","1826000850"
"West University Place","West University Place","29.7157","-95.4321","United States","US","USA","Texas","","14975","1840022205"
"Huétor Vega","Huetor Vega","37.1500","-3.5667","Spain","ES","ESP","Andalusia","","12120","1724497090"
"Canegrate","Canegrate","45.5667","8.9333","Italy","IT","ITA","Lombardy","","12574","1380642269"
"Sisauna","Sisauna","26.1320","87.6231","India","IN","IND","Bihār","","12897","1356777470"
"Saint-André-les-Vergers","Saint-Andre-les-Vergers","48.2797","4.0539","France","FR","FRA","Grand Est","","12784","1250507061"
"Harpur Bochaha","Harpur Bochaha","25.5919","85.7517","India","IN","IND","Bihār","","12768","1356046078"
"Highland Park","Highland Park","40.5006","-74.4283","United States","US","USA","New Jersey","","14947","1840003636"
"Bhabānipur","Bhabanipur","24.7153","87.9227","India","IN","IND","West Bengal","","11465","1356237076"
"Bandlagūda","Bandlaguda","17.3543","78.3853","India","IN","IND","Andhra Pradesh","","12734","1356134351"
"Parsa","Parsa","25.7721","86.1754","India","IN","IND","Bihār","","11804","1356307849"
"Le Grand-Saconnex","Le Grand-Saconnex","46.2333","6.1167","Switzerland","CH","CHE","Genève","","12162","1756885352"
"Cēsis","Cesis","57.3167","25.2667","Latvia","LV","LVA","Cēsis","admin","14815","1428840269"
"Sandridge","Sandridge","51.7808","-0.3038","United Kingdom","GB","GBR","Hertfordshire","","11451","1826504633"
"Fairview","Fairview","40.8182","-74.0022","United States","US","USA","New Jersey","","14918","1840003549"
"Port Antonio","Port Antonio","18.1757","-76.4503","Jamaica","JM","JAM","Portland","admin","14816","1388024275"
"Begampur","Begampur","22.3705","88.4940","India","IN","IND","West Bengal","","12742","1356787471"
"Jesenice","Jesenice","46.4366","14.0602","Slovenia","SI","SVN","Jesenice","admin","13338","1705471403"
"Abergavenny","Abergavenny","51.8240","-3.0167","United Kingdom","GB","GBR","Monmouthshire","","12515","1826362404"
"El Menzel","El Menzel","33.8389","-4.5458","Morocco","MA","MAR","Fès-Meknès","","12641","1504965368"
"Warsop","Warsop","53.2000","-1.1500","United Kingdom","GB","GBR","Nottinghamshire","","11999","1826909482"
"Kotia","Kotia","26.2750","86.0532","India","IN","IND","Bihār","","12311","1356006996"
"Marabella","Marabella","10.3000","-61.4500","Trinidad and Tobago","TT","TTO","San Fernando","","12508","1780714221"
"Gourock","Gourock","55.9538","-4.8173","United Kingdom","GB","GBR","Inverclyde","","10350","1826142319"
"Chiautla de Tapia","Chiautla de Tapia","18.3000","-98.6039","Mexico","MX","MEX","Puebla","","11776","1484573116"
"Olton","Olton","52.4377","-1.8058","United Kingdom","GB","GBR","Solihull","","12167","1826454094"
"San Josecito","San Josecito","9.8885","-84.1055","Costa Rica","CR","CRI","San José","","10506","1188452639"
"Tadjourah","Tadjourah","11.7833","42.8833","Djibouti","DJ","DJI","Tadjourah","admin","14820","1262504958"
"Tierra Colorada","Tierra Colorada","17.1656","-99.5264","Mexico","MX","MEX","Guerrero","minor","10502","1484516715"
"Boumia","Boumia","32.7228","-5.1053","Morocco","MA","MAR","Drâa-Tafilalet","","12444","1504947716"
"Ozurgeti","Ozurgeti","41.9406","41.9906","Georgia","GE","GEO","Guria","admin","14785","1268276279"
"Forres","Forres","57.6080","-3.6200","United Kingdom","GB","GBR","Moray","","10100","1826092519"
"Whitefish Bay","Whitefish Bay","43.1131","-87.9003","United States","US","USA","Wisconsin","","14818","1840003036"
"Eisenstadt","Eisenstadt","47.8500","16.5167","Austria","AT","AUT","Burgenland","admin","14476","1040751740"
"Bandwār","Bandwar","25.5093","86.1595","India","IN","IND","Bihār","","12336","1356605414"
"Hwlffordd","Hwlffordd","51.8000","-4.9700","United Kingdom","GB","GBR","Pembrokeshire","","12042","1826514766"
"Garh Sisai","Garh Sisai","25.6253","85.7887","India","IN","IND","Bihār","","12603","1356003299"
"Oulad Ayyad","Oulad Ayyad","32.3333","-6.3833","Morocco","MA","MAR","Béni Mellal-Khénifra","","9566","1504480318"
"Ahirauliyā","Ahirauliya","26.5091","84.7999","India","IN","IND","Bihār","","12448","1356049208"
"Villa Unión","Villa Union","23.9667","-104.0333","Mexico","MX","MEX","Durango","minor","10753","1484796365"
"Belobaka","Belobaka","-15.6833","46.4167","Madagascar","MG","MDG","Mahajanga","","12000","1450381333"
"Koog aan de Zaan","Koog aan de Zaan","52.4667","4.8000","Netherlands","NL","NLD","Noord-Holland","","11225","1528862520"
"Sandown","Sandown","50.6551","-1.1541","United Kingdom","GB","GBR","Isle of Wight","","11868","1826580279"
"Bottesford","Bottesford","53.5521","-0.6714","United Kingdom","GB","GBR","North Lincolnshire","","11038","1826232962"
"Rorschach","Rorschach","47.4667","9.5000","Switzerland","CH","CHE","Sankt Gallen","minor","9439","1756630924"
"Berriozar","Berriozar","42.8361","-1.6714","Spain","ES","ESP","Navarre","","10765","1724369007"
"Lake Stickney","Lake Stickney","47.8733","-122.2582","United States","US","USA","Washington","","14733","1840037894"
"La Pointe","La Pointe","19.9500","-72.7833","Haiti","HT","HTI","Nord-Ouest","","9930","1332411349"
"Debar","Debar","41.5250","20.5272","Macedonia","MK","MKD","Debar","admin","14561","1807621851"
"Sonoita","Sonoita","31.8614","-112.8544","Mexico","MX","MEX","Sonora","minor","11440","1484032951"
"Bang Phlat","Bang Phlat","13.8247","100.4908","Thailand","TH","THA","Krung Thep Maha Nakhon","minor","12482","1764119231"
"Bargoed","Bargoed","51.6900","-3.2400","United Kingdom","GB","GBR","Caerphilly","","11900","1826865415"
"Lykóvrysi","Lykovrysi","38.0667","23.7833","Greece","GR","GRC","Attikí","","9738","1300868556"
"Salida","Salida","37.7083","-121.0842","United States","US","USA","California","","14690","1840018908"
"Progreso","Progreso","32.5842","-115.5842","Mexico","MX","MEX","Baja California","","12557","1484104363"
"Hambantota","Hambantota","6.1244","81.1225","Sri Lanka","LK","LKA","Southern","","11213","1144358863"
"Parlier","Parlier","36.6087","-119.5434","United States","US","USA","California","","14666","1840020323"
"Djanet","Djanet","24.5550","9.4853","Algeria","DZ","DZA","Djanet","admin","14655","1012827195"
"Biassono","Biassono","45.6333","9.2667","Italy","IT","ITA","Lombardy","","12164","1380356399"
"Oyam","Oyam","2.2350","32.3850","Uganda","UG","UGA","Oyam","admin","14500","1800867016"
"Maxcanú","Maxcanu","20.5833","-89.9833","Mexico","MX","MEX","Yucatán","minor","12621","1484052886"
"Coacoatzintla","Coacoatzintla","19.6500","-96.9333","Mexico","MX","MEX","Veracruz","minor","10482","1484309288"
"Deodha","Deodha","25.7815","86.1346","India","IN","IND","Bihār","","11251","1356653967"
"Muttanampālaiyam","Muttanampalaiyam","11.0845","77.3969","India","IN","IND","Tamil Nādu","","12603","1356166175"
"Māmidipalli","Mamidipalli","17.2516","78.4521","India","IN","IND","Andhra Pradesh","","9236","1356380363"
"Magugpo Poblacion","Magugpo Poblacion","7.3821","125.8017","Philippines","PH","PHL","Davao del Norte","","6726","1608255342"
"Ewa Beach","Ewa Beach","21.3181","-158.0073","United States","US","USA","Hawaii","","14614","1840029470"
"Escazú","Escazu","9.9160","-84.1459","Costa Rica","CR","CRI","San José","","11984","1188889337"
"Hœnheim","Hoenheim","48.6242","7.7547","France","FR","FRA","Grand Est","","11304","1250007037"
"Sahtāh","Sahtah","25.8567","85.2407","India","IN","IND","Bihār","","12012","1356965220"
"Trentham","Trentham","52.9663","-2.1899","United Kingdom","GB","GBR","Staffordshire","","11836","1826068717"
"Naxxar","Naxxar","35.9150","14.4447","Malta","MT","MLT","Naxxar","admin","13443","1470470220"
"Sigulda","Sigulda","57.1500","24.8667","Latvia","LV","LVA","Sigulda","admin","14539","1428703594"
"Malvinas Argentinas","Malvinas Argentinas","-31.3697","-64.0531","Argentina","AR","ARG","Córdoba","","12187","1032010904"
"Busumbala","Busumbala","13.3333","-16.6667","The Gambia","GM","GMB","West Coast","","11189","1270720788"
"Soalandy","Soalandy","-18.9903","47.5000","Madagascar","MG","MDG","Antananarivo","","11719","1450453632"
"Dhanupra","Dhanupra","25.6992","86.4716","India","IN","IND","Bihār","","12500","1356819972"
"Pincourt","Pincourt","45.3833","-73.9833","Canada","CA","CAN","Quebec","","14558","1124637966"
"Cowley","Cowley","51.5280","-0.4810","United Kingdom","GB","GBR","Hillingdon","","8776","1826903011"
"Baruun-Urt","Baruun-Urt","46.6814","113.2803","Mongolia","MN","MNG","Sühbaatar","admin","14297","1496627655"
"Key Biscayne","Key Biscayne","25.6908","-80.1653","United States","US","USA","Florida","","14530","1840018333"
"Ar Rommani","Ar Rommani","33.5333","-6.6167","Morocco","MA","MAR","Rabat-Salé-Kénitra","","12297","1504782261"
"Kemp Mill","Kemp Mill","39.0412","-77.0215","United States","US","USA","Maryland","","14509","1840005842"
"Falls Church","Falls Church","38.8847","-77.1751","United States","US","USA","Virginia","","14494","1840003835"
"Amtala","Amtala","22.2200","88.1700","India","IN","IND","West Bengal","","8765","1356179828"
"Ninga","Ninga","25.4616","86.0757","India","IN","IND","Bihār","","11461","1356250535"
"Wilkinsburg","Wilkinsburg","40.4442","-79.8733","United States","US","USA","Pennsylvania","","14492","1840001278"
"Muna","Muna","20.4800","-89.7200","Mexico","MX","MEX","Yucatán","minor","11469","1484692677"
"Stiring-Wendel","Stiring-Wendel","49.2000","6.9292","France","FR","FRA","Grand Est","","11385","1250001537"
"Carnoustie","Carnoustie","56.5010","-2.7100","United Kingdom","GB","GBR","Angus","","11360","1826799363"
"Lemon Hill","Lemon Hill","38.5172","-121.4573","United States","US","USA","California","","14471","1840075922"
"Tonyrefail","Tonyrefail","51.5840","-3.4306","United Kingdom","GB","GBR","Rhondda Cynon Taff","","12301","1826868989"
"East Rancho Dominguez","East Rancho Dominguez","33.8949","-118.1956","United States","US","USA","California","","14462","1840028368"
"Bariārpur Kāndh","Bariarpur Kandh","25.9391","85.4674","India","IN","IND","Bihār","","10924","1356689993"
"Clarkston","Clarkston","33.8117","-84.2403","United States","US","USA","Georgia","","14455","1840014776"
"Brislington","Brislington","51.4316","-2.5439","United Kingdom","GB","GBR","Bristol, City of","","11511","1826515115"
"Husepur","Husepur","26.4609","84.1699","India","IN","IND","Bihār","","11986","1356065558"
"Ucar","Ucar","40.5183","47.6542","Azerbaijan","AZ","AZE","Ucar","admin","14273","1031822434"
"Bharokhara","Bharokhara","25.8655","85.6834","India","IN","IND","Bihār","","12062","1356790417"
"Bilauri","Bilauri","25.7703","87.5163","India","IN","IND","Bihār","","11916","1356606737"
"Candelaria","Candelaria","18.1835","-91.0463","Mexico","MX","MEX","Campeche","minor","11121","1484925669"
"Chicago Ridge","Chicago Ridge","41.7034","-87.7795","United States","US","USA","Illinois","","14413","1840010146"
"Santiago Tangamandapio","Santiago Tangamandapio","19.9500","-102.4333","Mexico","MX","MEX","Michoacán","minor","10463","1484550468"
"Aphaur","Aphaur","25.8828","84.7595","India","IN","IND","Bihār","","12331","1356037358"
"Thames Ditton","Thames Ditton","51.3890","-0.3310","United Kingdom","GB","GBR","Surrey","","9400","1826658204"
"Cudworth","Cudworth","53.5784","-1.4149","United Kingdom","GB","GBR","Barnsley","","10977","1826159228"
"Kavaratti","Kavaratti","10.5626","72.6369","India","IN","IND","Lakshadweep","admin","11473","1356089869"
"Ardmore","Ardmore","40.0033","-75.2947","United States","US","USA","Pennsylvania","","14391","1840005455"
"Wealdstone","Wealdstone","51.5957","-0.3352","United Kingdom","GB","GBR","Brent","","11394","1826291904"
"Birsfelden","Birsfelden","47.5533","7.6233","Switzerland","CH","CHE","Basel-Landschaft","","10299","1756780364"
"Marsaskala","Marsaskala","35.8625","14.5675","Malta","MT","MLT","Marsaskala","admin","12134","1470808916"
"Newport","Newport","39.0856","-84.4868","United States","US","USA","Kentucky","","14378","1840014261"
"North Fair Oaks","North Fair Oaks","37.4754","-122.2034","United States","US","USA","California","","14360","1840028325"
"Dasraha Bhogrājpur","Dasraha Bhograjpur","25.5691","85.6441","India","IN","IND","Bihār","","12314","1356627031"
"Vellānūr","Vellanur","13.1500","80.1000","India","IN","IND","Tamil Nādu","","9093","1356036166"
"Mathila","Mathila","25.4715","84.1438","India","IN","IND","Bihār","","10546","1356232165"
"Neftçala","Neftcala","39.3586","49.2469","Azerbaijan","AZ","AZE","Neftçala","admin","14241","1031299249"
"Saidpur","Saidpur","33.7421","73.0677","Pakistan","PK","PAK","Islamabad","","12000","1586603702"
"Forest Park","Forest Park","41.8683","-87.8157","United States","US","USA","Illinois","","14297","1840011276"
"Haydock","Haydock","53.4678","-2.6609","United Kingdom","GB","GBR","St. Helens","","11416","1826418141"
"Villars-sur-Glâne","Villars-sur-Glane","46.7833","7.1167","Switzerland","CH","CHE","Fribourg","","12092","1756439374"
"Jamsaut","Jamsaut","25.6100","85.0148","India","IN","IND","Bihār","","11478","1356724915"
"Ville-d’Avray","Ville-d'Avray","48.8261","2.1933","France","FR","FRA","Île-de-France","","11225","1250968732"
"Pākkam","Pakkam","13.1436","80.0280","India","IN","IND","Tamil Nādu","","11509","1356051238"
"Bhainsahi","Bhainsahi","26.4404","84.5443","India","IN","IND","Bihār","","11656","1356844310"
"Gillingham","Gillingham","51.0375","-2.2748","United Kingdom","GB","GBR","Dorset","","11756","1826398821"
"Lambeth","Lambeth","51.4900","-0.1190","United Kingdom","GB","GBR","Lambeth","","9675","1826545364"
"Linslade","Linslade","51.9243","-0.6774","United Kingdom","GB","GBR","Central Bedfordshire","","11185","1826448969"
"Belwa","Belwa","26.1056","87.4916","India","IN","IND","Bihār","","12200","1356224812"
"Zefýri","Zefyri","38.0667","23.7167","Greece","GR","GRC","Attikí","","9454","1300050699"
"Sai Kung Tuk","Sai Kung Tuk","22.3814","114.2733","Hong Kong","HK","HKG","","","11927","1344932135"
"Nor Hachn","Nor Hachn","40.3019","44.5831","Armenia","AM","ARM","Kotayk’","","10198","1051802790"
"Sarafand","Sarafand","33.4517","35.2908","Lebanon","LB","LBN","Liban-Sud","","10965","1422620086"
"Hanzviur","Hanzviur","34.1400","74.5800","India","IN","IND","Jammu and Kashmīr","","9493","1356165074"
"Edgewater","Edgewater","40.8237","-73.9740","United States","US","USA","New Jersey","","14234","1840003543"
"Flower Hill","Flower Hill","39.1676","-77.1824","United States","US","USA","Maryland","","14213","1840148534"
"East Cleveland","East Cleveland","41.5317","-81.5794","United States","US","USA","Ohio","","14212","1840000599"
"Kaabong","Kaabong","3.5200","34.1200","Uganda","UG","UGA","Kaabong","admin","13400","1800299361"
"Street","Street","51.1235","-2.7381","United Kingdom","GB","GBR","Somerset","","11805","1826840312"
"Oakham","Oakham","52.6705","-0.7333","United Kingdom","GB","GBR","Rutland","","10975","1826442570"
"Ban Bang Sai","Ban Bang Sai","13.3847","100.9856","Thailand","TH","THA","Chon Buri","","12136","1764574957"
"Yuscarán","Yuscaran","13.9417","-86.8519","Honduras","HN","HND","El Paraíso","admin","14144","1340315181"
"Raynes Park","Raynes Park","51.4033","-0.2321","United Kingdom","GB","GBR","Merton","","9738","1826000018"
"Velivennu","Velivennu","16.8443","81.6895","India","IN","IND","Andhra Pradesh","","12018","1356592217"
"Negēlē","Negele","5.3167","39.5833","Ethiopia","ET","ETH","Oromīya","","11772","1231215294"
"Hawaiian Gardens","Hawaiian Gardens","33.8304","-118.0728","United States","US","USA","California","","14178","1840020485"
"Bukedea","Bukedea","1.3475","34.0444","Uganda","UG","UGA","Bukedea","admin","13900","1800687979"
"Willowick","Willowick","41.6342","-81.4678","United States","US","USA","Ohio","","14173","1840000549"
"Canatlán","Canatlan","24.5200","-104.7800","Mexico","MX","MEX","Durango","minor","10342","1484221814"
"Gladeview","Gladeview","25.8395","-80.2368","United States","US","USA","Florida","","14161","1840029038"
"Khāngāon","Khangaon","25.5146","84.7528","India","IN","IND","Bihār","","11456","1356219557"
"Kronshagen","Kronshagen","54.3333","10.0833","Germany","DE","DEU","Schleswig-Holstein","","11921","1276781907"
"San Pedro Ixcatlán","San Pedro Ixcatlan","18.1500","-96.5000","Mexico","MX","MEX","Oaxaca","","11653","1484497758"
"Rāmpur Kudarkatti","Rampur Kudarkatti","26.0672","87.4795","India","IN","IND","Bihār","","12080","1356087624"
"Bharra","Bharra","25.4398","86.1624","India","IN","IND","Bihār","","10651","1356580444"
"Bhālpatti","Bhalpatti","26.1853","86.0509","India","IN","IND","Bihār","","10732","1356905665"
"Pierre","Pierre","44.3748","-100.3205","United States","US","USA","South Dakota","admin","14126","1840000313"
"Astley","Astley","53.5008","-2.4454","United Kingdom","GB","GBR","Wigan","","11270","1826000042"
"Federal Heights","Federal Heights","39.8651","-105.0154","United States","US","USA","Colorado","","14111","1840028425"
"Cetinje","Cetinje","42.3800","18.9200","Montenegro","ME","MNE","Cetinje","admin","14093","1499733751"
"Ráth Tó","Rath To","53.5060","-6.4630","Ireland","IE","IRL","Meath","","9533","1372907755"
"Talant","Talant","47.3364","5.0056","France","FR","FRA","Bourgogne-Franche-Comté","","11713","1250438803"
"Olhanpur","Olhanpur","25.8855","84.8186","India","IN","IND","Bihār","","11695","1356064436"
"Mayilādi","Mayiladi","8.1550","77.5056","India","IN","IND","Tamil Nādu","","11881","1356204802"
"Collingswood","Collingswood","39.9160","-75.0758","United States","US","USA","New Jersey","","14087","1840016611"
"Sjenica","Sjenica","43.2667","20.0000","Serbia","RS","SRB","Sjenica","admin","14060","1688303533"
"Karmauli","Karmauli","26.4502","86.1033","India","IN","IND","Bihār","","11634","1356908596"
"Bishopstoke","Bishopstoke","50.9679","-1.3278","United Kingdom","GB","GBR","Hampshire","","9974","1826495708"
"Begampur","Begampur","22.7400","88.2400","India","IN","IND","West Bengal","","10487","1356059388"
"Cayey","Cayey","18.1150","-66.1630","Puerto Rico","PR","PRI","Puerto Rico","","14052","1630035640"
"Akhaltsikhe","Akhaltsikhe","41.6389","42.9861","Georgia","GE","GEO","Samtskhe-Javakheti","admin","14000","1268352935"
"Kippax","Kippax","53.7669","-1.3705","United Kingdom","GB","GBR","Leeds","","9785","1826981453"
"Déville-lès-Rouen","Deville-les-Rouen","49.4697","1.0497","France","FR","FRA","Normandie","","10654","1250228408"
"Bāgnān","Bagnan","22.4700","87.9700","India","IN","IND","West Bengal","","10996","1356776559"
"Bāwāli","Bawali","22.4150","88.1889","India","IN","IND","West Bengal","","10968","1356175916"
"Ryhope","Ryhope","54.8679","-1.3698","United Kingdom","GB","GBR","Sunderland","","10484","1826951829"
"Kalinagar","Kalinagar","22.4383","88.1150","India","IN","IND","West Bengal","","11358","1356809062"
"Pelsall","Pelsall","52.6310","-1.9720","United Kingdom","GB","GBR","Walsall","","11371","1826666565"
"San Gregorio di Catania","San Gregorio di Catania","37.5667","15.1167","Italy","IT","ITA","Sicilia","","11880","1380676054"
"Natshal","Natshal","22.1957","88.0272","India","IN","IND","West Bengal","","11331","1356961923"
"Huntington","Huntington","38.7916","-77.0740","United States","US","USA","Virginia","","13979","1840006030"
"Birchington","Birchington","51.3770","1.3050","United Kingdom","GB","GBR","Kent","","9961","1826115786"
"Roselle Park","Roselle Park","40.6653","-74.2666","United States","US","USA","New Jersey","","13973","1840001093"
"Bhansia","Bhansia","26.0992","87.5316","India","IN","IND","Bihār","","11898","1356903244"
"Belauncha","Belauncha","26.2124","86.3554","India","IN","IND","Bihār","","11222","1356271751"
"University Heights","University Heights","41.4948","-81.5350","United States","US","USA","Ohio","","13950","1840003416"
"Chaital","Chaital","22.5106","88.7996","India","IN","IND","West Bengal","","11927","1356876532"
"Nao Kothi","Nao Kothi","25.5196","86.1954","India","IN","IND","Bihār","","10946","1356232669"
"Etchojoa","Etchojoa","26.8667","-109.6500","Mexico","MX","MEX","Sonora","minor","9831","1484867357"
"Kuressaare","Kuressaare","58.2500","22.4833","Estonia","EE","EST","Saaremaa","admin","13097","1233341219"
"Dugny","Dugny","48.9536","2.4164","France","FR","FRA","Île-de-France","","11073","1250530247"
"Stocksbridge","Stocksbridge","53.4780","-1.5880","United Kingdom","GB","GBR","Sheffield","","9869","1826907758"
"Risca","Risca","51.6080","-3.0910","United Kingdom","GB","GBR","Caerphilly","","11693","1826115137"
"Flixton","Flixton","53.4470","-2.3910","United Kingdom","GB","GBR","Trafford","","10786","1826292876"
"Larbert","Larbert","56.0229","-3.8260","United Kingdom","GB","GBR","Falkirk","","11050","1826937694"
"González","Gonzalez","22.8281","-98.4306","Mexico","MX","MEX","Tamaulipas","minor","11212","1484835501"
"Dishāshah","Dishashah","28.9831","30.8492","Egypt","EG","EGY","Banī Suwayf","","11844","1818375380"
"Villecresnes","Villecresnes","48.7214","2.5342","France","FR","FRA","Île-de-France","","11779","1250263679"
"Chauki","Chauki","25.4573","86.4018","India","IN","IND","Bihār","","11002","1356781078"
"Plav","Plav","42.6000","19.9400","Montenegro","ME","MNE","Plav","admin","13805","1499247875"
"Papraur","Papraur","25.4413","86.0378","India","IN","IND","Bihār","","10797","1356098101"
"Mīt Damsīs","Mit Damsis","30.8267","31.2226","Egypt","EG","EGY","Ad Daqahlīyah","","11528","1818557641"
"Bexley","Bexley","39.9650","-82.9343","United States","US","USA","Ohio","","13820","1840003759"
"Waimalu","Waimalu","21.3913","-157.9345","United States","US","USA","Hawaii","","13818","1840029588"
"Saint Ann’s Bay","Saint Ann's Bay","18.4360","-77.2020","Jamaica","JM","JAM","Saint Ann","admin","13671","1388256334"
"Pietà","Pieta","35.8931","14.4936","Malta","MT","MLT","Pietà","admin","4020","1470444069"
"Tovuz","Tovuz","40.9922","45.6289","Azerbaijan","AZ","AZE","Tovuz","admin","13700","1031405942"
"Westmont","Westmont","39.9082","-75.0551","United States","US","USA","New Jersey","","13784","1840033439"
"Pantelhó","Pantelho","17.0067","-92.4696","Mexico","MX","MEX","Chiapas","minor","8629","1484849992"
"Ermoúpoli","Ermoupoli","37.4333","24.9167","Greece","GR","GRC","Nótio Aigaío","admin","13737","1300072209"
"Akil","Akil","20.2656","-89.3478","Mexico","MX","MEX","Yucatán","","10176","1484515773"
"Sultānpur","Sultanpur","25.6214","85.4637","India","IN","IND","Bihār","","11124","1356647916"
"San Luis de La Loma","San Luis de La Loma","17.2714","-100.8911","Mexico","MX","MEX","Guerrero","","11090","1484748421"
"Ascot","Ascot","51.4084","-0.6707","United Kingdom","GB","GBR","Windsor and Maidenhead","","11603","1826467973"
"Hemiksem","Hemiksem","51.1500","4.3500","Belgium","BE","BEL","Flanders","","11634","1056061898"
"Konand","Konand","25.1729","85.6842","India","IN","IND","Bihār","","11745","1356793391"
"Kibiito","Kibiito","0.4775","30.1925","Uganda","UG","UGA","Bunyangabu","admin","12984","1800035606"
"Borsbeek","Borsbeek","51.2000","4.4833","Belgium","BE","BEL","Flanders","","10949","1056005961"
"Bar","Bar","42.1000","19.1000","Montenegro","ME","MNE","Bar","admin","13719","1499197266"
"Shorewood","Shorewood","43.0913","-87.8864","United States","US","USA","Wisconsin","","13734","1840003031"
"Kumi","Kumi","1.4608","33.9361","Uganda","UG","UGA","Kumi","admin","13000","1800429770"
"Lemington","Lemington","54.9720","-1.7230","United Kingdom","GB","GBR","Newcastle upon Tyne","","10228","1826216570"
"Ndélé","Ndele","8.4092","20.6531","Central African Republic","CF","CAF","Bamingui-Bangoran","admin","13704","1140271951"
"Bithān","Bithan","25.6954","86.2789","India","IN","IND","Bihār","","11704","1356628791"
"Épinay-sur-Orge","Epinay-sur-Orge","48.6739","2.3272","France","FR","FRA","Île-de-France","","11184","1250210383"
"Henley on Thames","Henley on Thames","51.5357","-0.9030","United Kingdom","GB","GBR","Oxfordshire","","11619","1826580742"
"Olympia Heights","Olympia Heights","25.7240","-80.3388","United States","US","USA","Florida","","13695","1840014247"
"Royston","Royston","53.6100","-1.4500","United Kingdom","GB","GBR","Barnsley","","10728","1826977688"
"Ang Thong","Ang Thong","14.5925","100.4572","Thailand","TH","THA","Ang Thong","admin","13277","1764529940"
"Shtërpcë","Shterpce","42.2333","21.0167","Kosovo","XK","XKS","Shtërpcë","admin","13630","1901828239"
"Craponne","Craponne","45.7453","4.7233","France","FR","FRA","Auvergne-Rhône-Alpes","","11248","1250337164"
"Chichihualco","Chichihualco","17.6550","-99.6740","Mexico","MX","MEX","Guerrero","minor","10079","1484534707"
"Aberaman","Aberaman","51.7000","-3.4333","United Kingdom","GB","GBR","Rhondda Cynon Taff","","9964","1826702294"
"Holbeach","Holbeach","52.8037","0.0154","United Kingdom","GB","GBR","Lincolnshire","","10458","1826410577"
"Tavistock","Tavistock","50.5450","-4.1500","United Kingdom","GB","GBR","Devon","","11018","1826700583"
"Liskeard","Liskeard","50.4536","-4.4651","United Kingdom","GB","GBR","Cornwall","","9912","1826851300"
"New Silksworth","New Silksworth","54.8710","-1.3957","United Kingdom","GB","GBR","Sunderland","","10194","1826945369"
"Bradford-on-Avon","Bradford-on-Avon","51.3470","-2.2510","United Kingdom","GB","GBR","Wiltshire","","10405","1826919846"
"Mesrā","Mesra","23.4339","85.4152","India","IN","IND","Jhārkhand","","10068","1356177670"
"Little Falls","Little Falls","40.8762","-74.2181","United States","US","USA","New Jersey","","13628","1840081807"
"Hirnyk","Hirnyk","48.0500","37.3667","Ukraine","UA","UKR","Donetska Oblast","","11378","1804281966"
"Chatteris","Chatteris","52.4560","0.0550","United Kingdom","GB","GBR","Cambridgeshire","","11011","1826618368"
"Wyandanch","Wyandanch","40.7496","-73.3633","United States","US","USA","New York","","13598","1840005115"
"Männedorf","Mannedorf","47.2553","8.6917","Switzerland","CH","CHE","Zürich","","11242","1756961965"
"Tres Ríos","Tres Rios","9.9072","-83.9865","Costa Rica","CR","CRI","Cartago","","9331","1188655041"
"Horsell","Horsell","51.3286","-0.5617","United Kingdom","GB","GBR","Surrey","","9384","1826085922"
"Shāhpur Baghauni","Shahpur Baghauni","25.8831","85.6859","India","IN","IND","Bihār","","11520","1356710376"
"New Carrollton","New Carrollton","38.9656","-76.8775","United States","US","USA","Maryland","","13574","1840005978"
"Rahiār Kunchi","Rahiar Kunchi","25.8627","86.0924","India","IN","IND","Bihār","","11227","1356668204"
"Indiana","Indiana","40.6220","-79.1552","United States","US","USA","Pennsylvania","","13573","1840000997"
"Paura Madan Singh","Paura Madan Singh","25.7917","85.2333","India","IN","IND","Bihār","","10978","1356588174"
"Imsida","Imsida","35.8978","14.4894","Malta","MT","MLT","Imsida","admin","8545","1470395922"
"Nilavārappatti","Nilavarappatti","11.6081","78.1499","India","IN","IND","Tamil Nādu","","10775","1356219726"
"Marton","Marton","54.5372","-1.2047","United Kingdom","GB","GBR","Middlesbrough","","9990","1826705697"
"Chanteloup-les-Vignes","Chanteloup-les-Vignes","48.9783","2.0308","France","FR","FRA","Île-de-France","","10426","1250129620"
"Shepton Mallet","Shepton Mallet","51.1930","-2.5460","United Kingdom","GB","GBR","Somerset","","10810","1826237809"
"Wicklow","Wicklow","52.9779","-6.0330","Ireland","IE","IRL","Wicklow","admin","10584","1372662642"
"Đà Nẵng","Da Nang","16.0748","108.2240","Vietnam","VN","VNM","Đà Nẵng","admin","","1704949870"
"Seonār","Seonar","25.4142","85.8834","India","IN","IND","Bihār","","10788","1356658113"
"Boudenib","Boudenib","31.9497","-3.6078","Morocco","MA","MAR","Drâa-Tafilalet","","11373","1504858254"
"Whitchurch","Whitchurch","52.9690","-2.6820","United Kingdom","GB","GBR","Shropshire","","9781","1826717880"
"Panamarattuppatti","Panamarattuppatti","11.5620","78.1649","India","IN","IND","Tamil Nādu","","10725","1356226426"
"Capodrise","Capodrise","41.0500","14.3000","Italy","IT","ITA","Campania","","10133","1380485604"
"Ponteland","Ponteland","55.0480","-1.7470","United Kingdom","GB","GBR","Northumberland","","10921","1826040320"
"La Grange Park","La Grange Park","41.8308","-87.8723","United States","US","USA","Illinois","","13501","1840011256"
"Samayanallūr","Samayanallur","9.9792","78.0359","India","IN","IND","Tamil Nādu","","11381","1356429861"
"Castlebar","Castlebar","53.8608","-9.2988","Ireland","IE","IRL","Mayo","admin","12068","1372066739"
"Ayapango","Ayapango","19.1264","-98.8028","Mexico","MX","MEX","México","minor","8864","1484009067"
"Buckhurst Hill","Buckhurst Hill","51.6320","0.0360","United Kingdom","GB","GBR","Essex","","11380","1826018401"
"Sinop","Sinop","42.0267","35.1511","Turkey","TR","TUR","Sinop","admin","13354","1792497865"
"Mānsinghpur Bijrauli","Mansinghpur Bijrauli","25.7552","85.5099","India","IN","IND","Bihār","","10987","1356151886"
"Stanwell","Stanwell","51.4570","-0.4715","United Kingdom","GB","GBR","Surrey","","11279","1826245987"
"Ormesson-sur-Marne","Ormesson-sur-Marne","48.7858","2.5383","France","FR","FRA","Île-de-France","","10419","1250640842"
"Newport","Newport","52.7691","-2.3787","United Kingdom","GB","GBR","Telford and Wrekin","","11387","1826096263"
"Eccleston","Eccleston","53.4539","-2.7748","United Kingdom","GB","GBR","St. Helens","","10433","1826176719"
"Dhāmua","Dhamua","22.2875","88.3962","India","IN","IND","West Bengal","","10055","1356600666"
"Beaumont","Beaumont","45.7517","3.0831","France","FR","FRA","Auvergne-Rhône-Alpes","","10793","1250295985"
"Bothell East","Bothell East","47.8064","-122.1844","United States","US","USA","Washington","","13437","1840041892"
"Koila Dewa","Koila Dewa","26.4079","84.1962","India","IN","IND","Bihār","","10222","1356659730"
"Tetela del Volcán","Tetela del Volcan","18.8931","-98.7297","Mexico","MX","MEX","Morelos","minor","10199","1484343325"
"Cupar","Cupar","56.3200","-3.0100","United Kingdom","GB","GBR","Fife","","9020","1826556120"
"Dudhpura","Dudhpura","25.7964","86.1189","India","IN","IND","Bihār","","10059","1356099112"
"Bardīha Turki","Bardiha Turki","25.8059","85.5441","India","IN","IND","Bihār","","11239","1356079852"
"Platón Sánchez","Platon Sanchez","21.2833","-98.3667","Mexico","MX","MEX","Veracruz","minor","10009","1484193492"
"Arniya","Arniya","25.7150","85.5448","India","IN","IND","Bihār","","10913","1356888928"
"Pachāhi","Pachahi","26.1932","86.3597","India","IN","IND","Bihār","","11362","1356119813"
"Kaith","Kaith","25.4776","86.2001","India","IN","IND","Bihār","","9902","1356743248"
"Maromme","Maromme","49.4819","1.0419","France","FR","FRA","Normandie","","10730","1250642232"
"Domžale","Domzale","46.1394","14.5950","Slovenia","SI","SVN","Domžale","admin","13204","1705822721"
"Stranraer","Stranraer","54.9020","-5.0270","United Kingdom","GB","GBR","Dumfries and Galloway","","10320","1826813563"
"Gżira","Gzira","35.9050","14.4944","Malta","MT","MLT","Gżira","admin","8029","1470739045"
"Kumharsan","Kumharsan","25.6421","86.1971","India","IN","IND","Bihār","","11130","1356116299"
"Lathasepura","Lathasepura","25.8131","85.7180","India","IN","IND","Bihār","","11205","1356175529"
"Bāzid Chak Kasturi","Bazid Chak Kasturi","25.6559","85.4412","India","IN","IND","Bihār","","10723","1356391361"
"Somnāha","Somnaha","25.9518","85.7042","India","IN","IND","Bihār","","11147","1356922561"
"Tixkokob","Tixkokob","21.0022","-89.3936","Mexico","MX","MEX","Yucatán","minor","10968","1484306905"
"Longbenton","Longbenton","55.0000","-1.5700","United Kingdom","GB","GBR","Newcastle upon Tyne","","10617","1826725161"
"Corinda","Corinda","-27.4833","153.1000","Australia","AU","AUS","Queensland","","11019","1036501460"
"Acanceh","Acanceh","20.8133","-89.4524","Mexico","MX","MEX","Yucatán","minor","10968","1484170180"
"Sečanj","Secanj","45.3667","20.7725","Serbia","RS","SRB","Sečanj","admin","13267","1688905613"
"Avocado Heights","Avocado Heights","34.0391","-117.9970","United States","US","USA","California","","13322","1840028318"
"Swieqi","Swieqi","35.9208","14.4800","Malta","MT","MLT","Swieqi","admin","10064","1470279940"
"Coatesville","Coatesville","39.9849","-75.8200","United States","US","USA","Pennsylvania","","13306","1840001424"
"Kingston","Kingston","41.2652","-75.8876","United States","US","USA","Pennsylvania","","13304","1840000756"
"Mudichchur","Mudichchur","12.9110","80.0693","India","IN","IND","Tamil Nādu","","8601","1356109242"
"Carpinteria","Carpinteria","34.3962","-119.5118","United States","US","USA","California","","13289","1840019187"
"Mariental","Mariental","-24.6333","17.9667","Namibia","NA","NAM","Hardap","admin","12478","1516582394"
"Drochia","Drochia","48.0333","27.7500","Moldova","MD","MDA","Drochia","admin","13150","1498968635"
"Sahuli","Sahuli","26.1118","84.3485","India","IN","IND","Bihār","","10688","1356771922"
"Żebbuġ","Zebbug","35.8731","14.4419","Malta","MT","MLT","Żebbuġ","admin","11903","1470473600"
"Woodland Park","Woodland Park","40.8904","-74.1946","United States","US","USA","New Jersey","","13265","1840056421"
"Delémont","Delemont","47.3667","7.3500","Switzerland","CH","CHE","Jura","admin","12682","1756748908"
"Adalpur","Adalpur","26.1910","86.0239","India","IN","IND","Bihār","","10494","1356338843"
"Amwa Majhār","Amwa Majhar","26.7362","84.5818","India","IN","IND","Bihār","","10002","1356091183"
"Nieuw Nickerie","Nieuw Nickerie","5.9333","-56.9833","Suriname","SR","SUR","Nickerie","admin","12818","1740480869"
"Maili","Maili","21.4133","-158.1701","United States","US","USA","Hawaii","","13245","1840029522"
"Meliana","Meliana","39.5272","-0.3492","Spain","ES","ESP","Valencia","","10926","1724153449"
"Lydney","Lydney","51.7286","-2.5285","United Kingdom","GB","GBR","Gloucestershire","","10043","1826019580"
"Mannarai","Mannarai","11.1172","77.3726","India","IN","IND","Tamil Nādu","","11214","1356165944"
"Barnoldswick","Barnoldswick","53.9147","-2.1895","United Kingdom","GB","GBR","Lancashire","","10752","1826332641"
"Pajacuarán","Pajacuaran","20.1178","-102.5667","Mexico","MX","MEX","Michoacán","minor","10014","1484044014"
"Montigny-en-Gohelle","Montigny-en-Gohelle","50.4278","2.9297","France","FR","FRA","Hauts-de-France","","10315","1250090128"
"La Trinitaria","La Trinitaria","16.1191","-92.0505","Mexico","MX","MEX","Chiapas","minor","11055","1484842922"
"Druskininkai","Druskininkai","54.0167","23.9667","Lithuania","LT","LTU","Druskininkai","admin","13170","1440489111"
"Phulmālik","Phulmalik","25.4132","86.4255","India","IN","IND","Bihār","","10356","1356885563"
"Jarville-la-Malgrange","Jarville-la-Malgrange","48.6694","6.2061","France","FR","FRA","Grand Est","","9354","1250407896"
"Kibuye","Kibuye","-2.0617","29.3483","Rwanda","RW","RWA","Western Province","admin","12325","1646264461"
"Ridgefield Park","Ridgefield Park","40.8543","-74.0201","United States","US","USA","New Jersey","","13202","1840000898"
"Rangvāsa","Rangvasa","22.6435","75.7886","India","IN","IND","Madhya Pradesh","","8814","1356050198"
"Handsworth","Handsworth","53.3700","-1.4000","United Kingdom","GB","GBR","Sheffield","","9957","1826233814"
"Tubmanburg","Tubmanburg","6.8667","-10.8167","Liberia","LR","LBR","Bomi","admin","13114","1430503114"
"Novi Bečej","Novi Becej","45.6000","20.1167","Serbia","RS","SRB","Novi Bečej","admin","13133","1688588712"
"Amolatar","Amolatar","1.6350","32.8250","Uganda","UG","UGA","Amolatar","admin","12900","1800458737"
"Kargahiā Purab","Kargahia Purab","26.7887","84.5298","India","IN","IND","Bihār","","9922","1356215234"
"Kampel","Kampel","22.6167","76.0500","India","IN","IND","Madhya Pradesh","","8780","1356433450"
"Bocas del Toro","Bocas del Toro","9.3403","-82.2420","Panama","PA","PAN","Bocas del Toro","admin","12996","1591037947"
"Chettipālaiyam","Chettipalaiyam","10.9125","77.0370","India","IN","IND","Tamil Nādu","","10695","1356422917"
"Pongalūr","Pongalur","10.9654","77.3720","India","IN","IND","Tamil Nādu","","11136","1356170026"
"Evington","Evington","52.6210","-1.0720","United Kingdom","GB","GBR","Leicestershire","","11133","1826108996"
"Crosne","Crosne","48.7147","2.4611","France","FR","FRA","Île-de-France","","9369","1250623470"
"Nārsingi","Narsingi","17.3876","78.3570","India","IN","IND","Andhra Pradesh","","9394","1356012877"
"San Ignacio Cohuirimpo","San Ignacio Cohuirimpo","27.0500","-109.4167","Mexico","MX","MEX","Sonora","","10606","1484235510"
"Wilnecote","Wilnecote","52.6081","-1.6677","United Kingdom","GB","GBR","Staffordshire","","9358","1826733076"
"Inverurie","Inverurie","57.2800","-2.3800","United Kingdom","GB","GBR","Aberdeenshire","","10885","1826552358"
"Cuatro Ciénegas de Carranza","Cuatro Cienegas de Carranza","26.9861","-102.0664","Mexico","MX","MEX","Coahuila","minor","10395","1484012996"
"San Juanito","San Juanito","27.9700","-107.6003","Mexico","MX","MEX","Chihuahua","","10535","1484053518"
"Launceston","Launceston","50.6370","-4.3600","United Kingdom","GB","GBR","Cornwall","","10452","1826388225"
"Bhawānīpur","Bhawanipur","26.2385","86.0995","India","IN","IND","Bihār","","9971","1356953689"
"Steinbach am Taunus","Steinbach am Taunus","50.1667","8.5667","Germany","DE","DEU","Hesse","","10682","1276335395"
"Puck","Puck","54.7000","18.4000","Poland","PL","POL","Pomorskie","minor","10868","1616295439"
"Serere","Serere","1.5000","33.5500","Uganda","UG","UGA","Serere","admin","12700","1800171092"
"Nova Gorica","Nova Gorica","45.9558","13.6433","Slovenia","SI","SVN","Nova Gorica","admin","13031","1705939355"
"Żejtun","Zejtun","35.8556","14.5333","Malta","MT","MLT","Żejtun","admin","11508","1470320205"
"Hellesdon","Hellesdon","52.6485","1.2509","United Kingdom","GB","GBR","Norfolk","","10957","1826464470"
"Bishunpur","Bishunpur","25.8029","85.9886","India","IN","IND","Bihār","","9999","1356015457"
"Broadstone","Broadstone","50.7605","-1.9950","United Kingdom","GB","GBR","","","10303","1826492540"
"Rāmnagar Bankat","Ramnagar Bankat","26.7595","84.6122","India","IN","IND","Bihār","","9774","1356143200"
"Marquette-lès-Lille","Marquette-les-Lille","50.6758","3.0661","France","FR","FRA","Hauts-de-France","","10798","1250399592"
"Abasingammedda","Abasingammedda","7.3167","80.6667","Sri Lanka","LK","LKA","Central","","11000","1144237899"
"Älta","Alta","59.2500","18.1833","Sweden","SE","SWE","Stockholm","","9989","1752413057"
"Steinkjer","Steinkjer","64.0148","11.4954","Norway","NO","NOR","Trøndelag","admin","12985","1578642047"
"Na Sceirí","Na Sceiri","53.5828","-6.1083","Ireland","IE","IRL","Fingal","","10043","1372992347"
"Tlahualilo de Zaragoza","Tlahualilo de Zaragoza","26.1083","-103.4431","Mexico","MX","MEX","Durango","minor","9517","1484368402"
"Perūr","Perur","10.9752","76.9129","India","IN","IND","Tamil Nādu","","10513","1356425025"
"Littleport","Littleport","52.4568","0.3046","United Kingdom","GB","GBR","Cambridgeshire","","9168","1826638568"
"Ghanīpur Bejha","Ghanipur Bejha","25.9281","85.5548","India","IN","IND","Bihār","","9422","1356094108"
"Qutubpur","Qutubpur","25.6279","85.3684","India","IN","IND","Bihār","","9346","1356353293"
"Pelham","Pelham","40.9000","-73.8063","United States","US","USA","New York","","12953","1840004938"
"Croissy-sur-Seine","Croissy-sur-Seine","48.8778","2.1422","France","FR","FRA","Île-de-France","","10031","1250762354"
"Alagappapuram","Alagappapuram","8.1468","77.5413","India","IN","IND","Tamil Nādu","","10719","1356206859"
"Nairn","Nairn","57.5860","-3.8690","United Kingdom","GB","GBR","Highland","","9960","1826470405"
"Sugarland Run","Sugarland Run","39.0309","-77.3762","United States","US","USA","Virginia","","12928","1840024510"
"Gaillard","Gaillard","46.1850","6.2075","France","FR","FRA","Auvergne-Rhône-Alpes","","10419","1250388590"
"Psychikó","Psychiko","38.0108","23.7727","Greece","GR","GRC","Attikí","minor","9529","1300684253"
"Procida","Procida","40.7667","14.0333","Italy","IT","ITA","Campania","","10465","1380684124"
"Ialoveni","Ialoveni","46.9500","28.7833","Moldova","MD","MDA","Ialoveni","admin","12515","1498897259"
"Home Gardens","Home Gardens","33.8783","-117.5116","United States","US","USA","California","","12910","1840017987"
"Mokarrampur","Mokarrampur","26.2176","86.0433","India","IN","IND","Bihār","","10342","1356347629"
"Rāmpur Rajwa","Rampur Rajwa","25.7057","86.1781","India","IN","IND","Bihār","","9531","1356443783"
"Dumri","Dumri","25.7350","84.8762","India","IN","IND","Bihār","","9803","1356794728"
"Valga","Valga","57.7833","26.0333","Estonia","EE","EST","Valga","admin","12182","1233406308"
"Naranja","Naranja","25.5164","-80.4222","United States","US","USA","Florida","","12905","1840014246"
"Fords","Fords","40.5359","-74.3126","United States","US","USA","New Jersey","","12897","1840005409"
"Cornelius","Cornelius","45.5188","-123.0510","United States","US","USA","Oregon","","12893","1840018557"
"Obo","Obo","5.4000","26.5000","Central African Republic","CF","CAF","Haut-Mbomou","admin","12887","1140533897"
"Daru","Daru","-9.0833","143.2000","Papua New Guinea","PG","PNG","Western","admin","12879","1598923764"
"Tilehurst","Tilehurst","51.4579","-1.0406","United Kingdom","GB","GBR","Reading","","9185","1826145556"
"Srîfa","Srifa","33.2814","35.3964","Lebanon","LB","LBN","Liban-Sud","","10000","1422782357"
"Lamphun","Lamphun","18.5864","99.0119","Thailand","TH","THA","Lamphun","admin","12595","1764104264"
"Caudebec-lès-Elbeuf","Caudebec-les-Elbeuf","49.2808","1.0211","France","FR","FRA","Normandie","","10156","1250684300"
"Ifield","Ifield","51.1234","-0.2073","United Kingdom","GB","GBR","West Sussex","","8882","1826269195"
"Grover Beach","Grover Beach","35.1204","-120.6197","United States","US","USA","California","","12846","1840020414"
"Multi","Multi","22.2847","88.4053","India","IN","IND","West Bengal","","10215","1356171822"
"Chilmil","Chilmil","25.4563","86.1364","India","IN","IND","Bihār","","9349","1356094370"
"Red Bank","Red Bank","40.3480","-74.0672","United States","US","USA","New Jersey","","12825","1840001358"
"Sītalpur","Sitalpur","25.7764","85.0283","India","IN","IND","Bihār","","10780","1356075661"
"Pierre-Bénite","Pierre-Benite","45.7036","4.8242","France","FR","FRA","Auvergne-Rhône-Alpes","","10461","1250048167"
"Tottington","Tottington","53.6130","-2.3390","United Kingdom","GB","GBR","Bury","","9783","1826671512"
"Tinqueux","Tinqueux","49.2500","3.9908","France","FR","FRA","Grand Est","","10294","1250334937"
"Amesbury","Amesbury","51.1730","-1.7800","United Kingdom","GB","GBR","Wiltshire","","10724","1826157369"
"Cuapiaxtla de Madero","Cuapiaxtla de Madero","18.9167","-97.8167","Mexico","MX","MEX","Puebla","minor","10101","1484036948"
"Valadares","Valadares","41.0937","-8.6392","Portugal","PT","PRT","Porto","","10678","1620413494"
"Rokiškis","Rokiskis","55.9667","25.5833","Lithuania","LT","LTU","Rokiškis","admin","12738","1440918695"
"Mahamda","Mahamda","25.8108","84.7874","India","IN","IND","Bihār","","8970","1356708030"
"San Antonio","San Antonio","9.9781","-84.1879","Costa Rica","CR","CRI","Alajuela","","9942","1188109339"
"Emeryville","Emeryville","37.8382","-122.2932","United States","US","USA","California","","12747","1840020291"
"Shildon","Shildon","54.6300","-1.6500","United Kingdom","GB","GBR","Durham","","9976","1826433267"
"Aţ Ţūr","At Tur","28.2417","33.6222","Egypt","EG","EGY","Janūb Sīnā’","admin","12733","1818306172"
"Mauji","Mauji","25.6585","86.2313","India","IN","IND","Bihār","","9085","1356064336"
"Chai Nat","Chai Nat","15.1872","100.1283","Thailand","TH","THA","Chai Nat","admin","12541","1764848732"
"Chāndpur","Chandpur","22.4368","88.1711","India","IN","IND","West Bengal","","9066","1356602192"
"Nayānagar","Nayanagar","25.7465","86.1027","India","IN","IND","Bihār","","10278","1356660309"
"Phaphot","Phaphot","25.6817","86.0069","India","IN","IND","Bihār","","10544","1356575739"
"Helston","Helston","50.1000","-5.2700","United Kingdom","GB","GBR","Cornwall","","9780","1826301318"
"Solindābād","Solindabad","25.8463","86.5907","India","IN","IND","Bihār","","10131","1356050070"
"Morsand","Morsand","25.9335","85.6980","India","IN","IND","Bihār","","10505","1356981678"
"Sāhāpur","Sahapur","22.5200","88.1700","India","IN","IND","West Bengal","","9022","1356064505"
"Aberbargoed","Aberbargoed","51.6968","-3.2240","United Kingdom","GB","GBR","Caerphilly","","9184","1826650581"
"Kanoni","Kanoni","0.1728","31.9058","Uganda","UG","UGA","Gomba","admin","12439","1800973979"
"Choppington","Choppington","55.1450","-1.6010","United Kingdom","GB","GBR","Northumberland","","9787","1826999025"
"Kotor","Kotor","42.4300","18.7700","Montenegro","ME","MNE","Kotor","admin","12583","1499573612"
"Salisbury","Salisbury","40.7454","-73.5604","United States","US","USA","New York","","12643","1840005255"
"Ciudad Tula","Ciudad Tula","23.0000","-99.7200","Mexico","MX","MEX","Tamaulipas","minor","10043","1484516323"
"Ghoswāri","Ghoswari","25.4687","85.4964","India","IN","IND","Bihār","","10590","1356568613"
"Pipra Dewās","Pipra Dewas","25.4636","86.0236","India","IN","IND","Bihār","","9702","1356942595"
"Zaandijk","Zaandijk","52.4667","4.8000","Netherlands","NL","NLD","Noord-Holland","","8600","1528388373"
"Sihma","Sihma","25.6992","86.2446","India","IN","IND","Bihār","","10548","1356242123"
"San Ġiljan","San Giljan","35.9186","14.4900","Malta","MT","MLT","San Ġiljan","admin","7752","1470707733"
"Hînceşti","Hincesti","46.8167","28.5833","Moldova","MD","MDA","Hînceşti","admin","12491","1498402212"
"Attard","Attard","35.8928","14.4383","Malta","MT","MLT","Attard","admin","10930","1470765022"
"Chapeltown","Chapeltown","53.4620","-1.4660","United Kingdom","GB","GBR","Sheffield","","10043","1826155857"
"Tərtər","Tartar","40.3450","46.9289","Azerbaijan","AZ","AZE","Tərtər","admin","12563","1031622673"
"Dumri","Dumri","25.4750","85.0913","India","IN","IND","Bihār","","9969","1356005039"
"Krapina","Krapina","46.1600","15.8700","Croatia","HR","HRV","Krapinsko-Zagorska Županija","admin","12479","1191389404"
"Nueva Palmira","Nueva Palmira","-33.8833","-58.4167","Uruguay","UY","URY","Colonia","","9857","1858193401"
"Las Tablas","Las Tablas","7.7667","-80.2833","Panama","PA","PAN","Los Santos","admin","11358","1591472114"
"Sîngerei","Singerei","47.6333","28.1500","Moldova","MD","MDA","Sîngerei","admin","12465","1498521430"
"Sohāna","Sohana","30.6833","76.7000","India","IN","IND","Punjab","","9199","1356975267"
"Culfa","Culfa","38.9558","45.6308","Azerbaijan","AZ","AZE","Culfa","admin","12500","1031031472"
"Avranches","Avranches","48.6844","-1.3569","France","FR","FRA","Normandie","minor","10264","1250971017"
"Sānrha","Sanrha","25.7979","84.7504","India","IN","IND","Bihār","","8748","1356316675"
"Gorey","Gorey","52.6770","-6.2920","Ireland","IE","IRL","Wexford","","9822","1372751399"
"Caldicot","Caldicot","51.5910","-2.7492","United Kingdom","GB","GBR","Monmouthshire","","9813","1826366288"
"Qufādah","Qufadah","28.5812","30.7554","Egypt","EG","EGY","Al Minyā","","9948","1818001516"
"Tlalixtac de Cabrera","Tlalixtac de Cabrera","17.0667","-96.6500","Mexico","MX","MEX","Oaxaca","minor","10208","1484878072"
"San Marzano sul Sarno","San Marzano sul Sarno","40.7697","14.5947","Italy","IT","ITA","Campania","","10499","1380943524"
"Teotlaltzingo","Teotlaltzingo","19.2326","-98.5149","Mexico","MX","MEX","Puebla","","10456","1484592594"
"Igny","Igny","48.7422","2.2261","France","FR","FRA","Île-de-France","","9917","1250423219"
"Encamp","Encamp","42.5361","1.5828","Andorra","AD","AND","Encamp","admin","12338","1020417470"
"Fosses","Fosses","49.0981","2.5067","France","FR","FRA","Île-de-France","","9791","1250023015"
"Thorigny-sur-Marne","Thorigny-sur-Marne","48.8788","2.7075","France","FR","FRA","Île-de-France","","10454","1250601682"
"Tramore","Tramore","52.1588","-7.1463","Ireland","IE","IRL","Waterford","","10381","1372317796"
"Masar","Masar","25.5577","84.5767","India","IN","IND","Bihār","","9613","1356553453"
"Simarwāra Durgāpur","Simarwara Durgapur","25.8520","85.4944","India","IN","IND","Bihār","","10288","1356201242"
"Somerville","Somerville","40.5696","-74.6092","United States","US","USA","New Jersey","","12453","1840001056"
"Bulgan","Bulgan","48.8119","103.5336","Mongolia","MN","MNG","Bulgan","admin","12323","1496317433"
"Dunbar","Dunbar","56.0027","-2.5169","United Kingdom","GB","GBR","East Lothian","","9030","1826158363"
"Koini","Koini","26.4226","84.5147","India","IN","IND","Bihār","","9781","1356417149"
"Kaithinia","Kaithinia","26.2317","86.2817","India","IN","IND","Bihār","","9731","1356861595"
"Lālganj","Lalganj","25.7297","87.4799","India","IN","IND","Bihār","","9929","1356756752"
"Meghaul","Meghaul","25.6400","86.0200","India","IN","IND","Bihār","","10078","1356324800"
"San Gregorio Atzompa","San Gregorio Atzompa","19.0224","-98.3445","Mexico","MX","MEX","Puebla","minor","9671","1484057992"
"Ettimadai","Ettimadai","10.8911","76.9087","India","IN","IND","Tamil Nādu","","9956","1356159411"
"Taraclia","Taraclia","45.9000","28.6689","Moldova","MD","MDA","Taraclia","admin","12355","1498129687"
"Zamora","Zamora","-4.0692","-78.9567","Ecuador","EC","ECU","Zamora-Chinchipe","admin","12386","1218873167"
"Warfield","Warfield","51.4420","-0.7370","United Kingdom","GB","GBR","Bracknell Forest","","10088","1826340559"
"Ganvié","Ganvie","6.4667","2.4167","Benin","BJ","BEN","Atlantique","","10280","1204791143"
"Muthābana","Muthabana","24.9203","88.0329","India","IN","IND","West Bengal","","9884","1356015575"
"North Merrick","North Merrick","40.6871","-73.5615","United States","US","USA","New York","","12392","1840005242"
"Anáhuac","Anahuac","28.4800","-106.7442","Mexico","MX","MEX","Chihuahua","","9952","1484106283"
"Võru","Voru","57.8486","26.9928","Estonia","EE","EST","Võru","admin","11533","1233754374"
"Beyləqan","Beylaqan","39.7756","47.6186","Azerbaijan","AZ","AZE","Beyləqan","admin","12263","1031681050"
"Mahisānrh","Mahisanrh","25.7358","85.5818","India","IN","IND","Bihār","","9859","1356055064"
"Mandalgovĭ","Mandalgovi","45.7667","106.2697","Mongolia","MN","MNG","Dundgovĭ","admin","12339","1496666560"
"Patchogue","Patchogue","40.7621","-73.0185","United States","US","USA","New York","","12341","1840005129"
"Cesa","Cesa","40.9667","14.2333","Italy","IT","ITA","Campania","","9039","1380979874"
"Kondaparti","Kondaparti","17.9219","79.5373","India","IN","IND","Andhra Pradesh","","9551","1356766779"
"Glenfield","Glenfield","52.6491","-1.2062","United Kingdom","GB","GBR","Leicestershire","","9643","1826730292"
"Hithadhoo","Hithadhoo","-0.6094","73.0899","Maldives","MV","MDV","Addu","admin","10398","1462406280"
"Māmobihāt","Mamobihat","26.1466","86.1747","India","IN","IND","Bihār","","9969","1356743964"
"Rāmkali","Ramkali","22.3351","88.3562","India","IN","IND","West Bengal","","9399","1356231269"
"Aurāhi","Aurahi","26.0355","86.7467","India","IN","IND","Bihār","","9808","1356259978"
"Broughton Astley","Broughton Astley","52.5278","-1.2275","United Kingdom","GB","GBR","Leicestershire","","8940","1826146185"
"Khunays","Khunays","35.7122","10.8167","Tunisia","TN","TUN","Monastir","","10000","1788472866"
"Mālīnagar","Malinagar","25.9967","85.6863","India","IN","IND","Bihār","","10075","1356508577"
"Amgachia","Amgachia","22.4156","88.3051","India","IN","IND","West Bengal","","10179","1356436848"
"Bararam","Bararam","26.1572","84.3627","India","IN","IND","Bihār","","9703","1356051379"
"Dhangaraha","Dhangaraha","26.0204","84.7023","India","IN","IND","Bihār","","10014","1356219560"
"Kilchberg","Kilchberg","47.3247","8.5492","Switzerland","CH","CHE","Zürich","","8851","1756715308"
"Naruār","Naruar","26.2388","86.2396","India","IN","IND","Bihār","","9544","1356844933"
"Kanchanpur","Kanchanpur","25.6636","85.2703","India","IN","IND","Bihār","","8616","1356097827"
"Amatlán de los Reyes","Amatlan de los Reyes","18.8457","-96.9149","Mexico","MX","MEX","Veracruz","minor","9123","1484683104"
"Xicoténcatl","Xicotencatl","22.9958","-98.9447","Mexico","MX","MEX","Tamaulipas","minor","9593","1484154416"
"Ga-Kgapane","Ga-Kgapane","-23.6490","30.2260","South Africa","ZA","ZAF","Limpopo","","9879","1710661361"
"Hakkila","Hakkila","60.2869","24.9522","Finland","FI","FIN","Uusimaa","","9509","1246076852"
"Chicalim","Chicalim","15.3994","73.8408","India","IN","IND","Goa","","10037","1356852049"
"Alum Rock","Alum Rock","37.3694","-121.8238","United States","US","USA","California","","12218","1840017638"
"Río Grande","Rio Grande","18.3789","-65.8388","Puerto Rico","PR","PRI","Puerto Rico","","12216","1630023701"
"Qalāt","Qalat","32.1061","66.9069","Afghanistan","AF","AFG","Zābul","admin","12191","1004016690"
"Manatí","Manati","18.4283","-66.4823","Puerto Rico","PR","PRI","Puerto Rico","","12207","1630023562"
"Gangāpur Athar","Gangapur Athar","25.8214","85.7123","India","IN","IND","Bihār","","9827","1356935991"
"Eybens","Eybens","45.1486","5.7503","France","FR","FRA","Auvergne-Rhône-Alpes","","9980","1250212542"
"Tekit","Tekit","20.5322","-89.3314","Mexico","MX","MEX","Yucatán","","9834","1484351882"
"Sikandarpur","Sikandarpur","25.3256","86.5051","India","IN","IND","Bihār","","9510","1356254239"
"Bamaiya Harlāl","Bamaiya Harlal","25.6664","85.8802","India","IN","IND","Bihār","","9119","1356020908"
"Assomada","Assomada","15.0960","-23.6670","Cabo Verde","CV","CPV","Santa Catarina","admin","12026","1132845019"
"Ouédo","Ouedo","6.4833","2.4500","Benin","BJ","BEN","Atlantique","","10067","1204184077"
"Aioun","Aioun","16.6667","-9.6167","Mauritania","MR","MRT","Hodh el Gharbi","admin","11867","1478255260"
"Choix","Choix","26.7061","-108.3219","Mexico","MX","MEX","Sinaloa","minor","9306","1484806011"
"Rogerstone","Rogerstone","51.5906","-3.0537","United Kingdom","GB","GBR","Newport","","10158","1826510350"
"Sallaumines","Sallaumines","50.4197","2.8622","France","FR","FRA","Hauts-de-France","","9654","1250623453"
"Newman","Newman","37.3156","-121.0212","United States","US","USA","California","","12154","1840020288"
"Făleşti","Falesti","47.5722","27.7139","Moldova","MD","MDA","Făleşti","admin","12074","1498180648"
"Neuville-lès-Dieppe","Neuville-les-Dieppe","49.9267","1.1014","France","FR","FRA","Normandie","","9616","1250261839"
"Jagatpur","Jagatpur","26.3738","86.0509","India","IN","IND","Bihār","","9750","1356158599"
"Tzucacab","Tzucacab","20.0708","-89.0506","Mexico","MX","MEX","Yucatán","","9967","1484877569"
"Chuy","Chuy","-33.6964","-53.4600","Uruguay","UY","URY","Rocha","","9675","1858852364"
"Ferney-Voltaire","Ferney-Voltaire","46.2558","6.1081","France","FR","FRA","Auvergne-Rhône-Alpes","","10026","1250921305"
"Hasbrouck Heights","Hasbrouck Heights","40.8618","-74.0741","United States","US","USA","New Jersey","","12120","1840003558"
"Caño Martin Peña","Cano Martin Pena","18.4309","-66.0507","Puerto Rico","PR","PRI","Puerto Rico","","12112","1630142912"
"Barwell","Barwell","52.5682","-1.3462","United Kingdom","GB","GBR","Leicestershire","","9022","1826018837"
"Ikkarai Boluvāmpatti","Ikkarai Boluvampatti","10.9669","76.7996","India","IN","IND","Tamil Nādu","","9645","1356159442"
"South Normanton","South Normanton","53.1070","-1.3430","United Kingdom","GB","GBR","Derbyshire","","9445","1826448406"
"Kilsyth","Kilsyth","55.9800","-4.0600","United Kingdom","GB","GBR","North Lanarkshire","","10080","1826765912"
"Floreşti","Floresti","47.8933","28.3014","Moldova","MD","MDA","Floreşti","admin","11998","1498297203"
"Izola","Izola","45.5344","13.6675","Slovenia","SI","SVN","Izola","admin","11682","1705476087"
"Malaudh","Malaudh","30.6333","75.9356","India","IN","IND","Punjab","","9485","1356035420"
"Gamharia","Gamharia","26.0561","86.7243","India","IN","IND","Bihār","","9575","1356259967"
"Cimişlia","Cimislia","46.5167","28.7833","Moldova","MD","MDA","Cimişlia","admin","11997","1498766980"
"Horbury","Horbury","53.6595","-1.5566","United Kingdom","GB","GBR","Wakefield","","10002","1826401665"
"Latifpur","Latifpur","22.3777","91.7639","Bangladesh","BD","BGD","Chattogram","","9901","1050917791"
"River Edge","River Edge","40.9269","-74.0387","United States","US","USA","New Jersey","","12024","1840000900"
"Robertsport","Robertsport","6.7500","-11.3667","Liberia","LR","LBR","Grand Cape Mount","admin","11969","1430885010"
"Khānpur Khairanti","Khanpur Khairanti","26.1456","84.3334","India","IN","IND","Bihār","","9424","1356963358"
"South Miami","South Miami","25.7079","-80.2952","United States","US","USA","Florida","","11997","1840016006"
"Bougival","Bougival","48.8650","2.1394","France","FR","FRA","Île-de-France","","8795","1250107634"
"Otumba","Otumba","19.6989","-98.7572","Mexico","MX","MEX","México","minor","9242","1484001484"
"Llantwit Major","Llantwit Major","51.4062","-3.4750","United Kingdom","GB","GBR","Vale of Glamorgan, The","","9486","1826528530"
"Semri","Semri","25.6246","85.7672","India","IN","IND","Bihār","","9773","1356581496"
"Kaithwār","Kaithwar","26.1678","86.2575","India","IN","IND","Bihār","","9492","1356111306"
"Clayton le Moors","Clayton le Moors","53.7750","-2.3840","United Kingdom","GB","GBR","Lancashire","","8522","1826882980"
"Hathaura","Hathaura","26.1744","84.3294","India","IN","IND","Bihār","","9369","1356725873"
"Nakasongola","Nakasongola","1.3150","32.4650","Uganda","UG","UGA","Nakasongola","admin","11700","1800270160"
"Yeadon","Yeadon","39.9325","-75.2527","United States","US","USA","Pennsylvania","","11945","1840000715"
"Boxley","Boxley","51.3024","0.5429","United Kingdom","GB","GBR","Kent","","9554","1826029798"
"Malaimāchchampatti","Malaimachchampatti","10.9058","77.0050","India","IN","IND","Tamil Nādu","","9472","1356170212"
"Coahuitlán","Coahuitlan","20.2667","-97.7167","Mexico","MX","MEX","Veracruz","","8566","1484959001"
"Rabat","Rabat","35.8817","14.3989","Malta","MT","MLT","Rabat","admin","11497","1470262687"
"Jahāngīrpur Sālkhani","Jahangirpur Salkhani","25.8335","85.4795","India","IN","IND","Bihār","","9319","1356313025"
"Cam","Cam","51.7011","-2.3642","United Kingdom","GB","GBR","Gloucestershire","","8520","1826325556"
"University of California-Santa Barbara","University of California-Santa Barbara","34.4151","-119.8568","United States","US","USA","California","","11912","1840153164"
"Serravalle","Serravalle","43.9694","12.4784","San Marino","SM","SMR","Serravalle","admin","10878","1674000000"
"Telkathu","Telkathu","26.1124","84.3733","India","IN","IND","Bihār","","9320","1356089212"
"Murska Sobota","Murska Sobota","46.6667","16.1667","Slovenia","SI","SVN","Murska Sobota","admin","11107","1705129726"
"Hārua","Harua","24.5249","87.9931","India","IN","IND","West Bengal","","9012","1356085339"
"Odayārpatti","Odayarpatti","11.6663","78.2144","India","IN","IND","Tamil Nādu","","9110","1356219755"
"Widnau","Widnau","47.3997","9.6333","Switzerland","CH","CHE","Sankt Gallen","","9616","1756016282"
"Byureghavan","Byureghavan","40.3147","44.5936","Armenia","AM","ARM","Kotayk’","","9513","1051696950"
"Khundāwandpur","Khundawandpur","25.6689","86.0363","India","IN","IND","Bihār","","9537","1356129700"
"Ukhāi Purbāri Patti","Ukhai Purbari Patti","26.2471","84.4066","India","IN","IND","Bihār","","9372","1356872088"
"Vanimo","Vanimo","-2.6667","141.2833","Papua New Guinea","PG","PNG","West Sepik","admin","11863","1598892363"
"Ban Son Loi","Ban Son Loi","13.9122","100.4200","Thailand","TH","THA","Nonthaburi","","9245","1764617566"
"Biltine","Biltine","14.5275","20.9267","Chad","TD","TCD","Wadi-Fira","admin","11840","1148086843"
"Żurrieq","Zurrieq","35.8292","14.4758","Malta","MT","MLT","Żurrieq","admin","10823","1470331348"
"Bhachhi","Bhachhi","26.3404","86.0585","India","IN","IND","Bihār","","9275","1356564006"
"Thakurainia","Thakurainia","26.2098","86.0191","India","IN","IND","Bihār","","9274","1356465576"
"Higuera de Zaragoza","Higuera de Zaragoza","25.9500","-109.2833","Mexico","MX","MEX","Sinaloa","","8976","1484471294"
"Bures-sur-Yvette","Bures-sur-Yvette","48.6967","2.1614","France","FR","FRA","Île-de-France","","9577","1250501258"
"Guttenberg","Guttenberg","40.7928","-74.0049","United States","US","USA","New Jersey","","11833","1840003597"
"Wallington","Wallington","40.8536","-74.1069","United States","US","USA","New Jersey","","11833","1840000922"
"Mamnūr","Mamnur","17.9092","79.5926","India","IN","IND","Andhra Pradesh","","9047","1356119519"
"Elektrėnai","Elektrenai","54.7861","24.6611","Lithuania","LT","LTU","Elektrénai","admin","11753","1440272776"
"Mirzānagar","Mirzanagar","25.7726","85.3804","India","IN","IND","Bihār","","9077","1356049421"
"Rossmoor","Rossmoor","33.7887","-118.0803","United States","US","USA","California","","11822","1840019320"
"Nawānagar","Nawanagar","25.3861","84.2069","India","IN","IND","Bihār","","9203","1356908819"
"North Wantagh","North Wantagh","40.6983","-73.5086","United States","US","USA","New York","","11807","1840005245"
"Bradwell","Bradwell","52.0500","-0.7870","United Kingdom","GB","GBR","Milton Keynes","","9657","1826794750"
"Bound Brook","Bound Brook","40.5676","-74.5383","United States","US","USA","New Jersey","","11799","1840001053"
"Berane","Berane","42.8400","19.8600","Montenegro","ME","MNE","Berane","admin","11776","1499204492"
"Chalkāri","Chalkari","23.7582","85.9557","India","IN","IND","Jhārkhand","","9189","1356192993"
"Kundiawa","Kundiawa","-6.0167","144.9667","Papua New Guinea","PG","PNG","Chimbu","admin","11553","1598730976"
"Karsaut","Karsaut","26.1298","84.4825","India","IN","IND","Bihār","","9230","1356648905"
"An Cabhán","An Cabhan","53.9910","-7.3601","Ireland","IE","IRL","Cavan","admin","10914","1372007853"
"Nāzira","Nazira","22.2180","88.2757","India","IN","IND","West Bengal","","9552","1356808460"
"Signal Hill","Signal Hill","33.8030","-118.1681","United States","US","USA","California","","11780","1840021868"
"Balzan","Balzan","35.8981","14.4533","Malta","MT","MLT","Balzan","admin","3958","1470875655"
"Škofja Loka","Skofja Loka","46.1672","14.3030","Slovenia","SI","SVN","Škofja Loka","admin","11671","1705743622"
"La Riviera","La Riviera","38.5683","-121.3544","United States","US","USA","California","","11723","1840028382"
"Bol","Bol","13.4600","14.7400","Chad","TD","TCD","Lac","admin","11700","1148232209"
"Bhāsaula Dānāpur","Bhasaula Danapur","25.5509","85.0308","India","IN","IND","Bihār","","8781","1356127844"
"Oak Hills","Oak Hills","45.5405","-122.8413","United States","US","USA","Oregon","","11715","1840034830"
"Epalinges","Epalinges","46.5500","6.6667","Switzerland","CH","CHE","Vaud","","9611","1756549876"
"La Junta","La Junta","28.4778","-107.3317","Mexico","MX","MEX","Chihuahua","","8930","1484913185"
"Le Plessis-Bouchard","Le Plessis-Bouchard","49.0028","2.2367","France","FR","FRA","Île-de-France","","8514","1250970448"
"Sidney","Sidney","48.6506","-123.3986","Canada","CA","CAN","British Columbia","","11672","1124421362"
"Gurmia","Gurmia","25.8190","85.2295","India","IN","IND","Bihār","","9076","1356936952"
"Pontardulais","Pontardulais","51.7100","-4.0400","United Kingdom","GB","GBR","Swansea","","9073","1826755520"
"Sāgarpur","Sagarpur","26.2306","86.0732","India","IN","IND","Bihār","","8512","1356909913"
"Bhadwār","Bhadwar","25.5576","84.7559","India","IN","IND","Bihār","","8906","1356684418"
"Avanāshipālaiyam","Avanashipalaiyam","10.9696","77.4352","India","IN","IND","Tamil Nādu","","9579","1356070013"
"Pahārpur","Paharpur","25.7225","86.6203","India","IN","IND","Bihār","","9521","1356095926"
"Chilón","Chilon","17.1055","-92.2715","Mexico","MX","MEX","Chiapas","","8982","1484862349"
"Mahadipur","Mahadipur","24.8566","88.1248","India","IN","IND","West Bengal","","8638","1356139009"
"Beauchamp","Beauchamp","49.0139","2.1900","France","FR","FRA","Île-de-France","","8687","1250835364"
"Sakaddi","Sakaddi","25.5771","84.7554","India","IN","IND","Bihār","","8851","1356504515"
"Casapulla","Casapulla","41.0667","14.2833","Italy","IT","ITA","Campania","","8597","1380421149"
"Leven","Leven","56.1950","-2.9942","United Kingdom","GB","GBR","Fife","","9040","1826961617"
"Halachó","Halacho","20.4764","-90.0819","Mexico","MX","MEX","Yucatán","minor","9412","1484161200"
"Wilton Manors","Wilton Manors","26.1593","-80.1395","United States","US","USA","Florida","","11525","1840016004"
"Le Portel","Le Portel","50.7067","1.5733","France","FR","FRA","Hauts-de-France","","9157","1250000416"
"Vaucresson","Vaucresson","48.8425","2.1528","France","FR","FRA","Île-de-France","","8683","1250372240"
"Radstock","Radstock","51.2930","-2.4480","United Kingdom","GB","GBR","Bath and North East Somerset","","9419","1826100157"
"Naifaru","Naifaru","5.4444","73.3657","Maldives","MV","MDV","Faadhippolhu","admin","4103","1462186620"
"Clawson","Clawson","42.5367","-83.1504","United States","US","USA","Michigan","","11482","1840002440"
"Sanniquellie","Sanniquellie","7.3622","-8.7061","Liberia","LR","LBR","Nimba","admin","11415","1430949102"
"Iarpur","Iarpur","22.2998","88.2994","India","IN","IND","West Bengal","","9238","1356656331"
"Weißenthurm","Weissenthurm","50.4144","7.4606","Germany","DE","DEU","Rhineland-Palatinate","","9171","1276833572"
"Sibkund","Sibkund","25.3041","86.3990","India","IN","IND","Bihār","","9141","1356041804"
"Mariehamn","Mariehamn","60.1000","19.9333","Finland","FI","FIN","Åland","admin","11461","1246614476"
"Sandiacre","Sandiacre","52.9230","-1.2910","United Kingdom","GB","GBR","Nottinghamshire","","8889","1826005601"
"Gaurdah","Gaurdah","25.7097","86.5570","India","IN","IND","Bihār","","9385","1356656974"
"Qax","Qax","41.4225","46.9242","Azerbaijan","AZ","AZE","Qax","admin","11415","1031852971"
"Pachrukhi","Pachrukhi","26.1593","84.4087","India","IN","IND","Bihār","","8508","1356011237"
"Fatehpur Bāla","Fatehpur Bala","25.8338","85.6928","India","IN","IND","Bihār","","9069","1356643104"
"Hillcrest","Hillcrest","35.3790","-118.9578","United States","US","USA","California","","11443","1840145203"
"Shanhūr","Shanhur","25.8604","32.7779","Egypt","EG","EGY","Qinā","","9314","1818837979"
"Groslay","Groslay","48.9867","2.3444","France","FR","FRA","Île-de-France","","8545","1250782182"
"Katrīdih","Katridih","25.0829","85.6334","India","IN","IND","Bihār","","8688","1356557772"
"Jasauli Patti","Jasauli Patti","26.4912","84.8818","India","IN","IND","Bihār","","8945","1356861586"
"Hazrat Shiura","Hazrat Shiura","25.5976","85.6119","India","IN","IND","Bihār","","9393","1356986686"
"Ḩadībū","Hadibu","12.6500","54.0167","Yemen","YE","YEM","Arkhabīl Suquţrá","admin","11396","1887452377"
"Cherry Creek","Cherry Creek","39.6094","-104.8645","United States","US","USA","Colorado","","11389","1840028588"
"Ban Chang Phuak","Ban Chang Phuak","18.8032","98.9811","Thailand","TH","THA","Chiang Mai","","9191","1764077139"
"Suffern","Suffern","41.1138","-74.1422","United States","US","USA","New York","","11376","1840004987"
"Glastonbury","Glastonbury","51.1485","-2.7140","United Kingdom","GB","GBR","Somerset","","8932","1826365312"
"Piedmont","Piedmont","37.8225","-122.2301","United States","US","USA","California","","11368","1840020297"
"Pérenchies","Perenchies","50.6681","2.9725","France","FR","FRA","Hauts-de-France","","8542","1250110921"
"Rāmpur","Rampur","25.9256","84.7468","India","IN","IND","Bihār","","9261","1356377554"
"Bonhill","Bonhill","55.9830","-4.5670","United Kingdom","GB","GBR","West Dunbartonshire","","9050","1826925473"
"Sant’Agnello","Sant'Agnello","40.6294","14.3996","Italy","IT","ITA","Campania","","9125","1380337324"
"Bucksburn","Bucksburn","57.1770","-2.1750","United Kingdom","GB","GBR","Aberdeen City","","8572","1826325568"
"View Park-Windsor Hills","View Park-Windsor Hills","33.9955","-118.3483","United States","US","USA","California","","11313","1840074686"
"Cullercoats","Cullercoats","55.0330","-1.4330","United Kingdom","GB","GBR","North Tyneside","","9202","1826802473"
"Kahla","Kahla","26.3747","84.5433","India","IN","IND","Bihār","","9233","1356868723"
"Nālikkalpatti","Nalikkalpatti","11.6021","78.1340","India","IN","IND","Tamil Nādu","","8503","1356219724"
"Saraunja","Saraunja","25.7690","86.5273","India","IN","IND","Bihār","","9217","1356894782"
"Tekpanja","Tekpanja","22.2101","88.3088","India","IN","IND","West Bengal","","9107","1356146174"
"Torre Boldone","Torre Boldone","45.7167","9.7000","Italy","IT","ITA","Lombardy","","8755","1380646015"
"Lohna","Lohna","26.2314","86.2095","India","IN","IND","Bihār","","8556","1356147832"
"San Juan Ixcaquixtla","San Juan Ixcaquixtla","18.4500","-97.8167","Mexico","MX","MEX","Puebla","","8752","1484614314"
"Kandiyankovil","Kandiyankovil","11.0147","77.4695","India","IN","IND","Tamil Nādu","","9217","1356163905"
"Akjoujt","Akjoujt","19.7500","-14.3833","Mauritania","MR","MRT","Inchiri","admin","11235","1478247793"
"Aucamville","Aucamville","43.6686","1.4306","France","FR","FRA","Occitanie","","8968","1250377997"
"Évian-les-Bains","Evian-les-Bains","46.4006","6.5900","France","FR","FRA","Auvergne-Rhône-Alpes","","9108","1250622011"
"Longford","Longford","53.7270","-7.7998","Ireland","IE","IRL","Longford","admin","10008","1372905339"
"Madhuban","Madhuban","25.8838","86.8176","India","IN","IND","Bihār","","8796","1356837416"
"Rye","Rye","50.9500","0.7300","United Kingdom","GB","GBR","East Sussex","","9041","1826538056"
"Gadaul","Gadaul","26.1287","86.2375","India","IN","IND","Bihār","","8688","1356798199"
"Magas","Magas","43.1667","44.8000","Russia","RU","RUS","Ingushetiya","admin","10333","1643520048"
"North Lindenhurst","North Lindenhurst","40.7072","-73.3859","United States","US","USA","New York","","11144","1840005058"
"Amiāwār","Amiawar","25.0405","84.3096","India","IN","IND","Bihār","","8667","1356716856"
"Cospicua","Cospicua","35.8822","14.5222","Malta","MT","MLT","Bormla","admin","5395","1470541461"
"Chānp","Chanp","26.1951","84.3900","India","IN","IND","Bihār","","8614","1356287925"
"Tataltepec de Valdés","Tataltepec de Valdes","16.3064","-97.5461","Mexico","MX","MEX","Oaxaca","minor","8869","1484294949"
"Dayr as Sanqūrīyah","Dayr as Sanquriyah","28.4833","30.6500","Egypt","EG","EGY","Al Minyā","","8911","1818109000"
"Summit","Summit","41.7877","-87.8146","United States","US","USA","Illinois","","11098","1840011329"
"Rezina","Rezina","47.7333","28.9500","Moldova","MD","MDA","Rezina","admin","11032","1498876065"
"Loikaw","Loikaw","19.6742","97.2092","Myanmar","MM","MMR","Kayah State","admin","11000","1104542517"
"Ville-la-Grand","Ville-la-Grand","46.2022","6.2469","France","FR","FRA","Auvergne-Rhône-Alpes","","9050","1250808704"
"Hucclecote","Hucclecote","51.8500","-2.1800","United Kingdom","GB","GBR","Gloucestershire","","8826","1826233272"
"Great Neck","Great Neck","40.8029","-73.7333","United States","US","USA","New York","","11034","1840005290"
"Chak Pahār","Chak Pahar","25.7986","85.6218","India","IN","IND","Bihār","","8652","1356330257"
"Hetanpur","Hetanpur","25.5821","85.5495","India","IN","IND","Bihār","","8999","1356270380"
"East Bakersfield","East Bakersfield","35.3832","-118.9743","United States","US","USA","California","","11025","1840150305"
"Bedwas","Bedwas","51.5926","-3.2061","United Kingdom","GB","GBR","Caerphilly","","8512","1826357082"
"Paola","Paola","35.8728","14.5075","Malta","MT","MLT","Paola","admin","7864","1470416994"
"Chak Habib","Chak Habib","25.7238","85.8789","India","IN","IND","Bihār","","8840","1356390829"
"Lansdowne","Lansdowne","39.9408","-75.2760","United States","US","USA","Pennsylvania","","11007","1840000700"
"Northbrook","Northbrook","39.2467","-84.5796","United States","US","USA","Ohio","","11005","1840034123"
"Montmeló","Montmelo","41.5547","2.2500","Spain","ES","ESP","Catalonia","","8798","1724866272"
"Waipio","Waipio","21.4143","-157.9966","United States","US","USA","Hawaii","","10989","1840029589"
"Anenii Noi","Anenii Noi","46.8833","29.2167","Moldova","MD","MDA","Anenii Noi","admin","10872","1498650298"
"Brevik","Brevik","59.3500","18.2000","Sweden","SE","SWE","Stockholm","","8772","1752993223"
"Reinosa","Reinosa","43.0019","-4.1378","Spain","ES","ESP","Cantabria","","8810","1724266157"
"Little Ferry","Little Ferry","40.8464","-74.0388","United States","US","USA","New Jersey","","10973","1840003534"
"Strumica","Strumica","41.4375","22.6431","Macedonia","MK","MKD","Strumica","admin","10868","1807799132"
"Karuzi","Karuzi","-3.1000","30.1667","Burundi","BI","BDI","Karuzi","admin","10705","1108619045"
"Bakhra","Bakhra","26.0333","85.1000","India","IN","IND","Bihār","","8822","1356326370"
"Vinica","Vinica","41.8828","22.5092","Macedonia","MK","MKD","Vinica","admin","10863","1807665568"
"Azīzpur Chānde","Azizpur Chande","25.7932","85.5945","India","IN","IND","Bihār","","8714","1356601753"
"Beni Abbès","Beni Abbes","30.0800","-2.1000","Algeria","DZ","DZA","Béni Abbès","admin","10885","1012633792"
"Brimington","Brimington","53.2580","-1.3905","United Kingdom","GB","GBR","Derbyshire","","8788","1826142035"
"Chakla Waini","Chakla Waini","25.9081","85.6836","India","IN","IND","Bihār","","8821","1356478522"
"Probištip","Probistip","41.9936","22.1767","Macedonia","MK","MKD","Probištip","admin","10826","1807592969"
"Nijgaon Parānpur","Nijgaon Paranpur","25.1559","87.9732","India","IN","IND","West Bengal","","8523","1356110154"
"West Auckland","West Auckland","54.6318","-1.7210","United Kingdom","GB","GBR","Durham","","8509","1826314267"
"Sānwas","Sanwas","25.1795","85.7148","India","IN","IND","Bihār","","8833","1356166837"
"Amuru","Amuru","2.8186","31.8642","Uganda","UG","UGA","Amuru","admin","10800","1800642608"
"Călăraşi","Calarasi","47.2500","28.3000","Moldova","MD","MDA","Călăraşi","admin","10808","1498598747"
"Jõhvi","Johvi","59.3575","27.4269","Estonia","EE","EST","Jõhvi","admin","10541","1233731045"
"Kanyākulam","Kanyakulam","8.2012","77.4015","India","IN","IND","Tamil Nādu","","8591","1356217828"
"Pokhraira","Pokhraira","26.0711","85.2034","India","IN","IND","Bihār","","8694","1356169127"
"Ahumada","Ahumada","30.6186","-106.5122","Mexico","MX","MEX","Chihuahua","minor","8575","1484004336"
"Birżebbuġa","Birzebbuga","35.8256","14.5281","Malta","MT","MLT","Birżebbuġa","admin","9736","1470830646"
"Pattanam","Pattanam","10.9808","77.0542","India","IN","IND","Tamil Nādu","","8549","1356169951"
"Lázaro Cárdenas","Lazaro Cardenas","28.3897","-105.6236","Mexico","MX","MEX","Chihuahua","","8704","1484739082"
"Atherstone","Atherstone","52.5787","-1.5462","United Kingdom","GB","GBR","Warwickshire","","8670","1826567686"
"Tokatippa","Tokatippa","16.2836","81.5024","India","IN","IND","Andhra Pradesh","","8630","1356129281"
"Daşkəsən","Daskasan","40.5244","46.1069","Azerbaijan","AZ","AZE","Daşkəsən","admin","10700","1031479797"
"Salinas","Salinas","-34.7833","-55.8333","Uruguay","UY","URY","Canelones","","8626","1858193593"
"Kralendijk","Kralendijk","12.1444","-68.2656","Bonaire, Sint Eustatius, and Saba","BQ","BES","Bonaire","admin","10620","1528693863"
"Graçanicë","Gracanice","42.6000","21.2000","Kosovo","XK","XKS","Graçanicë","admin","10675","1901753443"
"Westwood Lakes","Westwood Lakes","25.7237","-80.3717","United States","US","USA","Florida","","10706","1840028796"
"Bela Crkva","Bela Crkva","44.8975","21.4169","Serbia","RS","SRB","Bela Crkva","admin","10675","1688101335"
"Roatán","Roatan","16.3230","-86.5370","Honduras","HN","HND","Islas de la Bahía","admin","7514","1340163370"
"Timberlane","Timberlane","29.8781","-90.0303","United States","US","USA","Louisiana","","10697","1840031154"
"Darby","Darby","39.9210","-75.2610","United States","US","USA","Pennsylvania","","10688","1840000695"
"West Haverstraw","West Haverstraw","41.2063","-73.9884","United States","US","USA","New York","","10665","1840004990"
"Ayotoxco de Guerrero","Ayotoxco de Guerrero","20.1000","-97.4000","Mexico","MX","MEX","Puebla","minor","8558","1484789379"
"Citrus","Citrus","34.1160","-117.8891","United States","US","USA","California","","10626","1840017916"
"Del Aire","Del Aire","33.9167","-118.3693","United States","US","USA","California","","10617","1840028367"
"Kirundo","Kirundo","-2.5900","30.0900","Burundi","BI","BDI","Kirundo","admin","10024","1108527579"
"Al Maḩwīt","Al Mahwit","15.4694","43.5453","Yemen","YE","YEM","Al Maḩwīt","admin","10593","1887764813"
"Ada","Ada","45.8000","20.1333","Serbia","RS","SRB","Ada","admin","10547","1688687750"
"Jurbarkas","Jurbarkas","55.0833","22.7667","Lithuania","LT","LTU","Jurbarkas","admin","10571","1440845969"
"Charuānwān","Charuanwan","25.1348","85.6812","India","IN","IND","Bihār","","8570","1356714455"
"Siparia","Siparia","10.1333","-61.5000","Trinidad and Tobago","TT","TTO","Siparia","admin","10254","1780418671"
"Trat","Trat","12.2417","102.5125","Thailand","TH","THA","Trat","admin","10413","1764126822"
"Mellieħa","Mellieha","35.9564","14.3664","Malta","MT","MLT","Mellieħa","admin","10087","1470660015"
"Inwood","Inwood","40.6219","-73.7506","United States","US","USA","New York","","10533","1840005267"
"Haapsalu","Haapsalu","58.9394","23.5408","Estonia","EE","EST","Haapsalu","admin","9675","1233967642"
"Lascano","Lascano","-33.6739","-54.2075","Uruguay","UY","URY","Rocha","","7645","1858593854"
"Potomac Park","Potomac Park","35.3636","-118.9650","United States","US","USA","California","","10517","1840147389"
"Sarnen","Sarnen","46.8961","8.2456","Switzerland","CH","CHE","Obwalden","admin","10368","1756451113"
"Coamo","Coamo","18.0765","-66.3638","Puerto Rico","PR","PRI","Puerto Rico","","10508","1630035648"
"Phangnga","Phangnga","8.4644","98.5317","Thailand","TH","THA","Phangnga","admin","10412","1764855952"
"Santana","Santana","0.2583","6.7417","Sao Tome and Principe","ST","STP","Cantagalo","admin","10290","1678081464"
"Biržai","Birzai","56.2000","24.7500","Lithuania","LT","LTU","Biržai","admin","10444","1440132489"
"Choyr","Choyr","46.3606","108.3650","Mongolia","MN","MNG","Govĭsümber","admin","10434","1496514583"
"Ordubad","Ordubad","38.9081","46.0278","Azerbaijan","AZ","AZE","Ordubad","admin","10372","1031853196"
"Bukomansimbi","Bukomansimbi","-0.1667","31.6186","Uganda","UG","UGA","Bukomansimbi","admin","10000","1800146127"
"Olaine","Olaine","56.7833","23.9333","Latvia","LV","LVA","Olaine","admin","10324","1428220700"
"Watervliet","Watervliet","42.7243","-73.7068","United States","US","USA","New York","","10363","1840002495"
"Butha-Buthe","Butha-Buthe","-28.7833","28.2333","Lesotho","LS","LSO","Butha-Buthe","admin","10000","1426574356"
"Naftalan","Naftalan","40.5067","46.8250","Azerbaijan","AZ","AZE","Naftalan","admin","10100","1031441683"
"Bududa","Bududa","1.0100","34.3317","Uganda","UG","UGA","Bududa","admin","8700","1800970850"
"Woodlyn","Woodlyn","39.8774","-75.3445","United States","US","USA","Pennsylvania","","10332","1840035266"
"Altdorf","Altdorf","46.8667","8.6333","Switzerland","CH","CHE","Uri","admin","9401","1756957308"
"Vilkaviškis","Vilkaviskis","54.6500","23.0333","Lithuania","LT","LTU","Vilkaviškis","admin","10291","1440557692"
"Samux","Samux","40.7700","46.4111","Azerbaijan","AZ","AZE","Samux","admin","10228","1031600567"
"New Hyde Park","New Hyde Park","40.7323","-73.6858","United States","US","USA","New York","","10262","1840005313"
"Richmond Heights","Richmond Heights","25.6347","-80.3721","United States","US","USA","Florida","","10256","1840014249"
"Kwale","Kwale","-4.1744","39.4603","Kenya","KE","KEN","Kwale","admin","10063","1404769042"
"La Massana","La Massana","42.5444","1.5144","Andorra","AD","AND","La Massana","admin","10076","1020543453"
"Birao","Birao","10.2940","22.7820","Central African Republic","CF","CAF","Vakaga","admin","10178","1140392673"
"Muyinga","Muyinga","-2.8500","30.3333","Burundi","BI","BDI","Muyinga","admin","9609","1108466781"
"Neves","Neves","0.3592","6.5517","Sao Tome and Principe","ST","STP","Lembá","admin","10068","1678563060"
"Brookdale","Brookdale","40.8348","-74.1798","United States","US","USA","New Jersey","","10134","1840024254"
"Nisporeni","Nisporeni","47.0833","28.1833","Moldova","MD","MDA","Nisporeni","admin","10063","1498953988"
"Kuldīga","Kuldiga","56.9667","21.9667","Latvia","LV","LVA","Kuldīga","admin","10109","1428739306"
"East Rockaway","East Rockaway","40.6432","-73.6672","United States","US","USA","New York","","10108","1840005343"
"Vrnjačka Banja","Vrnjacka Banja","43.6236","20.8936","Serbia","RS","SRB","Vrnjačka Banja","admin","10065","1688549018"
"Nenagh","Nenagh","52.8632","-8.1995","Ireland","IE","IRL","Tipperary","admin","8968","1372867922"
"Mojkovac","Mojkovac","42.9600","19.5800","Montenegro","ME","MNE","Mojkovac","admin","10066","1499103009"
"West Perrine","West Perrine","25.6061","-80.3639","United States","US","USA","Florida","","10093","1840029018"
"Ħamrun","Hamrun","35.8861","14.4894","Malta","MT","MLT","Ħamrun","admin","9244","1470141385"
"Blacklick Estates","Blacklick Estates","39.9049","-82.8655","United States","US","USA","Ohio","","10066","1840034379"
"Maywood","Maywood","40.9025","-74.0634","United States","US","USA","New Jersey","","10049","1840003536"
"Capitola","Capitola","36.9773","-121.9537","United States","US","USA","California","","10041","1840018959"
"Roslyn","Roslyn","40.1311","-75.1374","United States","US","USA","Pennsylvania","","10009","1840035292"
"Kanjiža","Kanjiza","46.0667","20.0500","Serbia","RS","SRB","Kanjiža","admin","9871","1688731968"
"Brentwood","Brentwood","40.3734","-79.9757","United States","US","USA","Pennsylvania","","9999","1840001212"
"East Whittier","East Whittier","33.9244","-117.9887","United States","US","USA","California","","9995","1840019205"
"Lake Hiawatha","Lake Hiawatha","40.8816","-74.3826","United States","US","USA","New Jersey","","9987","1840033318"
"Nueva Ocotepeque","Nueva Ocotepeque","14.4333","-89.1833","Honduras","HN","HND","Ocotepeque","admin","8780","1340380637"
"Castillos","Castillos","-34.1989","-53.8575","Uruguay","UY","URY","Rocha","","7541","1858885344"
"Bauska","Bauska","56.4083","24.1931","Latvia","LV","LVA","Bauska","admin","9840","1428015452"
"Wood-Ridge","Wood-Ridge","40.8508","-74.0878","United States","US","USA","New Jersey","","9865","1840000925"
"August","August","37.9797","-121.2625","United States","US","USA","California","","9829","1840028357"
"Charter Oak","Charter Oak","34.1025","-117.8564","United States","US","USA","California","","9825","1840017919"
"Stonegate","Stonegate","39.5357","-104.8032","United States","US","USA","Colorado","","9822","1840028570"
"Katakwi","Katakwi","1.9150","33.9550","Uganda","UG","UGA","Katakwi","admin","9100","1800303827"
"Bulisa","Bulisa","2.1217","31.4156","Uganda","UG","UGA","Buliisa","admin","9500","1800842426"
"Keansburg","Keansburg","40.4469","-74.1315","United States","US","USA","New Jersey","","9781","1840003690"
"Jacinto City","Jacinto City","29.7663","-95.2410","United States","US","USA","Texas","","9734","1840019619"
"Lincoln Village","Lincoln Village","39.9532","-83.1314","United States","US","USA","Ohio","","9720","1840034388"
"Pájaros","Pajaros","18.3610","-66.2184","Puerto Rico","PR","PRI","Puerto Rico","","9713","1630035532"
"Makamba","Makamba","-4.1333","29.8000","Burundi","BI","BDI","Makamba","admin","9396","1108520771"
"Basco","Basco","20.4500","121.9667","Philippines","PH","PHL","Batanes","admin","9517","1608620601"
"Raseiniai","Raseiniai","55.3667","23.1167","Lithuania","LT","LTU","Raseiniai","admin","9686","1440481520"
"Saldus","Saldus","56.6667","22.5000","Latvia","LV","LVA","Saldus","admin","9679","1428774997"
"Tshabong","Tshabong","-26.0200","22.4056","Botswana","BW","BWA","Kgalagadi","admin","9679","1072830877"
"Orange Cove","Orange Cove","36.6211","-119.3188","United States","US","USA","California","","9680","1840020322"
"Boulder Hill","Boulder Hill","41.7113","-88.3353","United States","US","USA","Illinois","","9675","1840004821"
"Auki","Auki","-8.7667","160.7000","Solomon Islands","SB","SLB","Malaita","admin","7882","1090993143"
"Tivat","Tivat","42.4300","18.7000","Montenegro","ME","MNE","Tivat","admin","9367","1499952005"
"Postojna","Postojna","45.7759","14.2137","Slovenia","SI","SVN","Postojna","admin","9605","1705090259"
"West Athens","West Athens","33.9235","-118.3033","United States","US","USA","California","","9621","1840028403"
"Thinadhoo","Thinadhoo","0.5302","72.9965","Maldives","MV","MDV","Huvadhuatholhu Dhekunuburi","admin","5230","1462781201"
"Pedra Badejo","Pedra Badejo","15.1370","-23.5340","Cabo Verde","CV","CPV","Santa Cruz","admin","9530","1132841923"
"Bladensburg","Bladensburg","38.9424","-76.9264","United States","US","USA","Maryland","","9591","1840005981"
"Aiea","Aiea","21.3865","-157.9232","United States","US","USA","Hawaii","","9527","1840029469"
"River Road","River Road","44.0833","-123.1320","United States","US","USA","Oregon","","9526","1840034832"
"Bois-des-Filion","Bois-des-Filion","45.6667","-73.7500","Canada","CA","CAN","Quebec","","9485","1124978470"
"Kirkwall","Kirkwall","58.9810","-2.9600","United Kingdom","GB","GBR","Orkney Islands","","7420","1826208698"
"Rožaje","Rozaje","42.8400","20.1600","Montenegro","ME","MNE","Rožaje","admin","9422","1499854307"
"Hani i Elezit","Hani i Elezit","42.1475","21.2992","Kosovo","XK","XKS","Hani i Elezit","admin","9403","1901964789"
"New Square","New Square","41.1410","-74.0294","United States","US","USA","New York","","9433","1840004980"
"Plainedge","Plainedge","40.7241","-73.4770","United States","US","USA","New York","","9428","1840005249"
"Samdrup Jongkhar","Samdrup Jongkhar","26.8000","91.5050","Bhutan","BT","BTN","Samdrup Jongkhar","admin","9325","1064656050"
"Kolonia","Kolonia","6.9639","158.2081","Federated States of Micronesia","FM","FSM","Pohnpei","admin","6074","1583881416"
"Ntchisi","Ntchisi","-13.3667","34.0000","Malawi","MW","MWI","Ntchisi","admin","9357","1454004377"
"Vrhnika","Vrhnika","45.9624","14.2937","Slovenia","SI","SVN","Vrhnika","admin","8888","1705071756"
"Marina del Rey","Marina del Rey","33.9765","-118.4486","United States","US","USA","California","","9355","1840017920"
"Logatec","Logatec","45.9167","14.2297","Slovenia","SI","SVN","Logatec","admin","9091","1705275257"
"South Amboy","South Amboy","40.4852","-74.2831","United States","US","USA","New Jersey","","9320","1840001333"
"Gizo","Gizo","-8.1056","156.8389","Solomon Islands","SB","SLB","Western","admin","6154","1090427067"
"Rîşcani","Riscani","47.9572","27.5539","Moldova","MD","MDA","Rîşcani","admin","9259","1498650340"
"Sant Julià de Lòria","Sant Julia de Loria","42.4650","1.4911","Andorra","AD","AND","Sant Julià de Lòria","admin","9156","1020885733"
"Leisure World","Leisure World","39.1023","-77.0689","United States","US","USA","Maryland","","9291","1840073671"
"Leonia","Leonia","40.8638","-73.9900","United States","US","USA","New Jersey","","9283","1840000904"
"Victoria","Victoria","36.0436","14.2431","Malta","MT","MLT","Rabat (Ghawdex)","admin","6901","1470128499"
"Kasane","Kasane","-17.8167","25.1500","Botswana","BW","BWA","Chobe","admin","9250","1072217316"
"Wangdue Phodrang","Wangdue Phodrang","27.4833","89.9000","Bhutan","BT","BTN","Wangdue Phodrang","admin","8954","1064353953"
"Seven Corners","Seven Corners","38.8658","-77.1445","United States","US","USA","Virginia","","9191","1840006038"
"Žabalj","Zabalj","45.3667","20.0667","Serbia","RS","SRB","Žabalj","admin","9161","1688662344"
"Evinayong","Evinayong","1.4500","10.5667","Equatorial Guinea","GQ","GNQ","Centro Sur","admin","9155","1226336138"
"Coral Hills","Coral Hills","38.8706","-76.9215","United States","US","USA","Maryland","","9169","1840005950"
"Siġġiewi","Siggiewi","35.8542","14.4383","Malta","MT","MLT","Siġġiewi","admin","8721","1470181462"
"Grosuplje","Grosuplje","45.9551","14.6562","Slovenia","SI","SVN","Grosuplje","admin","7703","1705847148"
"Stans","Stans","46.9500","8.3500","Switzerland","CH","CHE","Nidwalden","admin","8393","1756019042"
"Dangriga","Dangriga","16.9667","-88.2167","Belize","BZ","BLZ","Stann Creek","admin","9096","1084718311"
"Amuria","Amuria","2.0300","33.6431","Uganda","UG","UGA","Amuria","admin","8900","1800838006"
"Thyolo","Thyolo","-16.0667","35.1333","Malawi","MW","MWI","Thyolo","admin","7029","1454464861"
"Conshohocken","Conshohocken","40.0772","-75.3034","United States","US","USA","Pennsylvania","","9104","1840003697"
"Ewo","Ewo","-0.8667","14.8167","Congo (Brazzaville)","CG","COG","Cuvette-Ouest","admin","9062","1178339407"
"Lerwick","Lerwick","60.1550","-1.1450","United Kingdom","GB","GBR","Shetland Islands","","6880","1826896340"
"Manatuto","Manatuto","-8.5167","126.0167","Timor-Leste","TL","TLS","Manatuto","admin","9022","1626115337"
"Odžaci","Odzaci","45.5167","19.2667","Serbia","RS","SRB","Odžaci","admin","9021","1688373834"
"Temple Hills","Temple Hills","38.8106","-76.9495","United States","US","USA","Maryland","","9037","1840005967"
"Derby","Derby","39.8400","-104.9172","United States","US","USA","Colorado","","8995","1840028576"
"Westgate","Westgate","26.6994","-80.0989","United States","US","USA","Florida","","8981","1840042909"
"Harwood Heights","Harwood Heights","41.9663","-87.8057","United States","US","USA","Illinois","","8978","1840011252"
"Haledon","Haledon","40.9363","-74.1887","United States","US","USA","New Jersey","","8936","1840003512"
"Rio Claro","Rio Claro","10.3042","-61.1708","Trinidad and Tobago","TT","TTO","Mayaro/Rio Claro","admin","8881","1780883666"
"Sremski Karlovci","Sremski Karlovci","45.2000","19.9333","Serbia","RS","SRB","Sremski Karlovci","admin","8750","1688215764"
"Prienai","Prienai","54.6333","23.9417","Lithuania","LT","LTU","Prienai","admin","8894","1440412268"
"Gleno","Gleno","-8.7239","125.4361","Timor-Leste","TL","TLS","Ermera","admin","8133","1626143485"
"Stony Brook University","Stony Brook University","40.9098","-73.1217","United States","US","USA","New York","","8868","1840042758"
"Collingdale","Collingdale","39.9151","-75.2776","United States","US","USA","Pennsylvania","","8857","1840000693"
"Talsi","Talsi","57.2444","22.5867","Latvia","LV","LVA","Talsi","admin","8816","1428411666"
"Mokhotlong","Mokhotlong","-29.2885","29.0656","Lesotho","LS","LSO","Mokhotlong","admin","8809","1426785819"
"Bogota","Bogota","40.8751","-74.0293","United States","US","USA","New Jersey","","8830","1840000894"
"Corozal","Corozal","18.4000","-88.4000","Belize","BZ","BLZ","Corozal","admin","8800","1084938400"
"Monaghan","Monaghan","54.2478","-6.9708","Ireland","IE","IRL","Monaghan","admin","7678","1372399438"
"Caldwell","Caldwell","40.8389","-74.2776","United States","US","USA","New Jersey","","8800","1840005354"
"Joniškis","Joniskis","56.2333","23.6000","Lithuania","LT","LTU","Joniškis","admin","8757","1440592411"
"North Kensington","North Kensington","39.0393","-77.0723","United States","US","USA","Maryland","","8771","1840031382"
"Dobele","Dobele","56.6167","23.2667","Latvia","LV","LVA","Dobele","admin","8731","1428641580"
"Rakai","Rakai","-0.7100","31.4050","Uganda","UG","UGA","Rakai","admin","8500","1800039440"
"Glodeni","Glodeni","47.7667","27.5167","Moldova","MD","MDA","Glodeni","admin","8676","1498553213"
"Gədəbəy","Gadabay","40.5656","45.8161","Azerbaijan","AZ","AZE","Gədəbəy","admin","8657","1031444480"
"Kočevje","Kocevje","45.6430","14.8594","Slovenia","SI","SVN","Kočevje","admin","8151","1705859832"
"La Cresta","La Cresta","35.3972","-118.9892","United States","US","USA","California","","8726","1840147387"
"Sorø","Soro","55.4333","11.5667","Denmark","DK","DNK","Sjælland","admin","7999","1208841384"
"Pazin","Pazin","45.2403","13.9367","Croatia","HR","HRV","Istarska Županija","admin","8638","1191654566"
"Audubon","Audubon","39.8906","-75.0722","United States","US","USA","New Jersey","","8693","1840003775"
"Cheviot","Cheviot","39.1577","-84.6139","United States","US","USA","Ohio","","8683","1840003813"
"Paide","Paide","58.8833","25.5572","Estonia","EE","EST","Paide","admin","7905","1233393684"
"Hillcrest","Hillcrest","41.1298","-74.0350","United States","US","USA","New York","","8667","1840004967"
"Anykščiai","Anyksciai","55.5333","25.1000","Lithuania","LT","LTU","Anykščiai","admin","8638","1440174702"
"Swissvale","Swissvale","40.4206","-79.8859","United States","US","USA","Pennsylvania","","8645","1840001264"
"Buba","Buba","11.5833","-15.0000","Guinea-Bissau","GW","GNB","Quinara","admin","8556","1624903078"
"Malverne","Malverne","40.6746","-73.6721","United States","US","USA","New York","","8583","1840005305"
"University of California-Davis","University of California-Davis","38.5378","-121.7579","United States","US","USA","California","","8532","1840042938"
"Basarabeasca","Basarabeasca","46.3336","28.9614","Moldova","MD","MDA","Basarabeasca","admin","8471","1498894118"
"Zərdab","Zardab","40.2183","47.7083","Azerbaijan","AZ","AZE","Zərdab","admin","8450","1031852579"
"Rubirizi","Rubirizi","-0.2661","30.1069","Uganda","UG","UGA","Rubirizi","admin","8204","1800947890"
"Broadview Park","Broadview Park","26.0978","-80.2090","United States","US","USA","Florida","","8505","1840028918"
"Varėna","Varena","54.2111","24.5722","Lithuania","LT","LTU","Varėna","admin","8477","1440933251"
"Krško","Krsko","45.9500","15.4833","Slovenia","SI","SVN","Krško","admin","7217","1705286396"
"Phalombe","Phalombe","-15.8033","35.6533","Malawi","MW","MWI","Phalombe","admin","6242","1454510180"
"Funadhoo","Funadhoo","6.1482","73.2900","Maldives","MV","MDV","Miladhunmadulu Uthuruburi","admin","2104","1462657112"
"Kaišiadorys","Kaisiadorys","54.8667","24.4500","Lithuania","LT","LTU","Kaišiadorys","admin","8334","1440257941"
"Brokopondo","Brokopondo","5.0667","-54.9667","Suriname","SR","SUR","Brokopondo","admin","8340","1740558611"
"Tevragh Zeina","Tevragh Zeina","18.0994","-15.9761","Mauritania","MR","MRT","Nouakchott Ouest","admin","","1478902195"
"Balakən","Balakan","41.7258","46.4083","Azerbaijan","AZ","AZE","Balakən","admin","8134","1031370082"
"Slovenska Bistrica","Slovenska Bistrica","46.3903","15.5750","Slovenia","SI","SVN","Slovenska Bistrica","admin","8219","1705359875"
"Naujoji Akmenė","Naujoji Akmene","56.3167","22.9000","Lithuania","LT","LTU","Akmenė","admin","8170","1440899932"
"Koné","Kone","-21.0590","164.8518","New Caledonia","NC","NCL","Province Nord","admin","8144","1540397627"
"Beočin","Beocin","45.2000","19.7333","Serbia","RS","SRB","Beočin","admin","8058","1688152997"
"Alebtong","Alebtong","2.2500","33.3150","Uganda","UG","UGA","Alebtong","admin","7900","1800244501"
"Port Maria","Port Maria","18.3702","-76.8903","Jamaica","JM","JAM","Saint Mary","admin","7906","1388155945"
"Nangan","Nangan","26.1598","119.9432","Taiwan","TW","TWN","Lienchiang","admin","7382","1158327079"
"Sembabule","Sembabule","-0.0800","31.4600","Uganda","UG","UGA","Sembabule","admin","7800","1800497536"
"Mtskheta","Mtskheta","41.8464","44.7194","Georgia","GE","GEO","Mtskheta Mtianeti","admin","7940","1268173120"
"Sokobanja","Sokobanja","43.6500","21.8667","Serbia","RS","SRB","Sokobanja","admin","7982","1688282797"
"Greymouth","Greymouth","-42.4500","171.2075","New Zealand","NZ","NZL","West Coast","admin","7965","1554984303"
"Montpelier","Montpelier","44.2659","-72.5717","United States","US","USA","Vermont","admin","7966","1840002187"
"Litija","Litija","46.0667","14.8167","Slovenia","SI","SVN","Litija","admin","6673","1705902870"
"Yeghegnadzor","Yeghegnadzor","39.7611","45.3333","Armenia","AM","ARM","Vayots’ Dzor","admin","7633","1051234787"
"Falmouth","Falmouth","18.4900","-77.6610","Jamaica","JM","JAM","Trelawny","admin","7779","1388625979"
"Guadalupe","Guadalupe","0.3800","6.6381","Sao Tome and Principe","ST","STP","Lobata","admin","7604","1678998597"
"Bentiu","Bentiu","9.2600","29.8000","South Sudan","SS","SSD","Unity","admin","7781","1728684421"
"Ajdovščina","Ajdovscina","45.8861","13.9097","Slovenia","SI","SVN","Ajdovščina","admin","6843","1705580792"
"Debe","Debe","10.2000","-61.4500","Trinidad and Tobago","TT","TTO","Penal/Debe","admin","7084","1780510715"
"Esperanza","Esperanza","27.5800","-109.9298","Mexico","MX","MEX","Sonora","","3836","1484729183"
"Dowa","Dowa","-13.6667","33.9167","Malawi","MW","MWI","Dowa","admin","7135","1454644071"
"Luba","Luba","3.4500","8.5500","Equatorial Guinea","GQ","GNQ","Bioko Sur","admin","7739","1226278217"
"Aračinovo","Aracinovo","42.0264","21.5617","Macedonia","MK","MKD","Aračinovo","admin","7315","1807619539"
"Yardımlı","Yardimli","38.9206","48.2372","Azerbaijan","AZ","AZE","Yardımlı","admin","7623","1031745676"
"Borgo Maggiore","Borgo Maggiore","43.9450","12.4497","San Marino","SM","SMR","Borgo Maggiore","admin","6871","1674314187"
"Vittoriosa","Vittoriosa","35.8881","14.5225","Malta","MT","MLT","Birgu","admin","2629","1470805169"
"Diekirch","Diekirch","49.8681","6.1567","Luxembourg","LU","LUX","Diekirch","admin","7047","1442382721"
"Sen Monorom","Sen Monorom","12.4500","107.2000","Cambodia","KH","KHM","Mondolkiri","admin","7500","1116978997"
"Ādaži","Adazi","57.0667","24.3333","Latvia","LV","LVA","Ādaži","admin","7482","1428843734"
"Kelmė","Kelme","55.6333","22.9333","Lithuania","LT","LTU","Kelmė","admin","7512","1440476751"
"Leova","Leova","46.4833","28.2500","Moldova","MD","MDA","Leova","admin","7443","1498085636"
"Ludza","Ludza","56.5500","27.7167","Latvia","LV","LVA","Ludza","admin","7473","1428976928"
"Eydhafushi","Eydhafushi","5.1038","73.0704","Maldives","MV","MDV","Maalhosmadulu Dhekunuburi","admin","2658","1462623341"
"Ravne na Koroškem","Ravne na Koroskem","46.5437","14.9642","Slovenia","SI","SVN","Ravne na Koroškem","admin","7363","1705757622"
"Goranboy","Goranboy","40.6103","46.7897","Azerbaijan","AZ","AZE","Goranboy","admin","7400","1031383465"
"Kičevo","Kicevo","41.5142","20.9631","Macedonia","MK","MKD","Kičevo","admin","7280","1807895120"
"Ruyigi","Ruyigi","-3.4833","30.2500","Burundi","BI","BDI","Ruyigi","admin","7139","1108406902"
"Briceni","Briceni","48.3611","27.0839","Moldova","MD","MDA","Briceni","admin","7314","1498970287"
"Lerik","Lerik","38.7753","48.4153","Azerbaijan","AZ","AZE","Lerik","admin","7301","1031887331"
"Ocniţa","Ocnita","48.3853","27.4392","Moldova","MD","MDA","Ocniţa","admin","7254","1498334915"
"Krāslava","Kraslava","55.8833","27.1667","Latvia","LV","LVA","Krāslava","admin","7289","1428739484"
"Slovenj Gradec","Slovenj Gradec","46.5094","15.0792","Slovenia","SI","SVN","Slovenj Gradec","admin","7249","1705845238"
"Mobaye","Mobaye","4.3167","21.1833","Central African Republic","CF","CAF","Basse-Kotto","admin","7176","1140935252"
"Mongomo","Mongomo","1.6287","11.3168","Equatorial Guinea","GQ","GNQ","Wele-Nzas","admin","7251","1226565454"
"Kəlbəcər","Kalbacar","40.1067","46.0383","Azerbaijan","AZ","AZE","Kəlbəcər","admin","7246","1031212922"
"Lapovo","Lapovo","44.1833","21.1000","Serbia","RS","SRB","Lapovo","admin","7143","1688267080"
"Hola","Hola","-1.5000","40.0333","Kenya","KE","KEN","Tana River","admin","6931","1404396632"
"Teleneşti","Telenesti","47.5028","28.3667","Moldova","MD","MDA","Teleneşti","admin","7227","1498811064"
"Radovljica","Radovljica","46.3425","14.1721","Slovenia","SI","SVN","Radovljica","admin","5981","1705488511"
"Aizkraukle","Aizkraukle","56.6042","25.2539","Latvia","LV","LVA","Aizkraukle","admin","7044","1428407271"
"Novobërdë","Novoberde","42.6000","21.4333","Kosovo","XK","XKS","Novobërdë","admin","7121","1901684676"
"Donduşeni","Donduseni","48.2167","27.5833","Moldova","MD","MDA","Donduşeni","admin","7101","1498280582"
"Ştefan Vodă","Stefan Voda","46.5153","29.6631","Moldova","MD","MDA","Ştefan Vodă","admin","7078","1498766864"
"Trindade","Trindade","0.3000","6.6833","Sao Tome and Principe","ST","STP","Mé-Zóchi","admin","6636","1678096936"
"Līvāni","Livani","56.3667","26.1833","Latvia","LV","LVA","Līvāni","admin","7035","1428769984"
"In Guezzam","In Guezzam","19.5686","5.7722","Algeria","DZ","DZA","In Guezzam","admin","7045","1012000031"
"Brežice","Brezice","45.9048","15.5922","Slovenia","SI","SVN","Brežice","admin","6843","1705251132"
"Novi Kneževac","Novi Knezevac","46.0500","20.1000","Serbia","RS","SRB","Novi Kneževac","admin","6960","1688706489"
"Gulbene","Gulbene","57.1667","26.7500","Latvia","LV","LVA","Gulbene","admin","6982","1428888445"
"Kiruhura","Kiruhura","-0.2100","30.8300","Uganda","UG","UGA","Kiruhura","admin","6900","1800198933"
"Obiliq","Obiliq","42.6900","21.0778","Kosovo","XK","XKS","Obiliq","admin","6864","1901102771"
"Oğuz","Oguz","41.0708","47.4583","Azerbaijan","AZ","AZE","Oğuz","admin","6876","1031370337"
"Limbaži","Limbazi","57.5167","24.7167","Latvia","LV","LVA","Limbaži","admin","6888","1428920312"
"Luqa","Luqa","35.8597","14.4892","Malta","MT","MLT","Luqa","admin","5945","1470901810"
"Salavan","Salavan","15.7167","106.4167","Laos","LA","LAO","Salavan","admin","5521","1418448631"
"Criuleni","Criuleni","47.2167","29.1667","Moldova","MD","MDA","Criuleni","admin","6708","1498171854"
"Šalčininkai","Salcininkai","54.3167","25.3833","Lithuania","LT","LTU","Šalčininkai","admin","6841","1440401402"
"Madona","Madona","56.8500","26.2167","Latvia","LV","LVA","Madona","admin","6792","1428548117"
"Kovačica","Kovacica","45.1117","20.6214","Serbia","RS","SRB","Kovačica","admin","6764","1688530993"
"Medvode","Medvode","46.1382","14.4147","Slovenia","SI","SVN","Medvode","admin","5380","1705158152"
"Ros Comáin","Ros Comain","53.6333","-8.1833","Ireland","IE","IRL","Roscommon","admin","5876","1372870384"
"Mengeš","Menges","46.1626","14.5680","Slovenia","SI","SVN","Mengeš","admin","6119","1705274788"
"Kerema","Kerema","-7.9667","145.7667","Papua New Guinea","PG","PNG","Gulf","admin","6551","1598925932"
"Alūksne","Aluksne","57.4167","27.0500","Latvia","LV","LVA","Alūksne","admin","6535","1428873095"
"Mae Hong Son","Mae Hong Son","19.3011","97.9700","Thailand","TH","THA","Mae Hong Son","admin","6526","1764090874"
"Bogatić","Bogatic","44.8333","19.4833","Serbia","RS","SRB","Bogatić","admin","6488","1688635508"
"Pasvalys","Pasvalys","56.0594","24.4036","Lithuania","LT","LTU","Pasvalys","admin","6411","1440957197"
"Siteki","Siteki","-26.4500","31.9500","Swaziland","SZ","SWZ","Lubombo","admin","6381","1748933140"
"Sal Rei","Sal Rei","16.1770","-22.9180","Cabo Verde","CV","CPV","Boa Vista","admin","6357","1132420232"
"Eenhana","Eenhana","-17.4658","16.3369","Namibia","NA","NAM","Ohangwena","admin","5528","1516685856"
"Lorengau","Lorengau","-2.0306","147.2722","Papua New Guinea","PG","PNG","Manus","admin","6313","1598571197"
"Jõgeva","Jogeva","58.7469","26.4000","Estonia","EE","EST","Jõgeva","admin","5073","1233192849"
"Punakha","Punakha","27.5833","89.8583","Bhutan","BT","BTN","Punakha","admin","6262","1064171188"
"Põlva","Polva","58.0536","27.0556","Estonia","EE","EST","Põlva","admin","5324","1233866730"
"Mitoma","Mitoma","-0.6150","30.0450","Uganda","UG","UGA","Mitooma","admin","6000","1800173507"
"Espargos","Espargos","16.7560","-22.9460","Cabo Verde","CV","CPV","Sal","admin","6173","1132760152"
"Kavadarci","Kavadarci","41.4328","22.0117","Macedonia","MK","MKD","Kavadarci","admin","6228","1807183974"
"Jakar","Jakar","27.5500","90.7333","Bhutan","BT","BTN","Bumthang","admin","6243","1064194738"
"Bački Petrovac","Backi Petrovac","45.3606","19.5917","Serbia","RS","SRB","Bački Petrovac","admin","6155","1688400393"
"Schaan","Schaan","47.1667","9.5097","Liechtenstein","LI","LIE","Schaan","admin","5998","1438982330"
"Preiļi","Preili","56.3000","26.7167","Latvia","LV","LVA","Preiļi","admin","6161","1428460035"
"Kupiškis","Kupiskis","55.8333","24.9667","Lithuania","LT","LTU","Kupiškis","admin","6178","1440122297"
"Viqueque","Viqueque","-8.8500","126.3667","Timor-Leste","TL","TLS","Viqueque","admin","6078","1626768340"
"Junik","Junik","42.4761","20.2775","Kosovo","XK","XKS","Junik","admin","6084","1901930204"
"Kudahuvadhoo","Kudahuvadhoo","2.6711","72.8935","Maldives","MV","MDV","Nilandheatholhu Dhekunuburi","admin","2447","1462282241"
"Lucea","Lucea","18.4500","-78.1833","Jamaica","JM","JAM","Hanover","admin","6002","1388423676"
"Rutana","Rutana","-3.9167","30.0000","Burundi","BI","BDI","Rutana","admin","5865","1108441253"
"Puerto Baquerizo Moreno","Puerto Baquerizo Moreno","-0.9025","-89.6092","Ecuador","EC","ECU","Galápagos","admin","6140","1218093882"
"Rapla","Rapla","58.9944","24.8011","Estonia","EE","EST","Rapla","admin","5069","1233013333"
"Glarus","Glarus","47.0333","9.0667","Switzerland","CH","CHE","Glarus","admin","5996","1756095767"
"Cəbrayıl","Cabrayil","39.4000","47.0261","Azerbaijan","AZ","AZE","Cəbrayıl","admin","6070","1031379235"
"Bač","Bac","45.3833","19.2333","Serbia","RS","SRB","Bač","admin","6087","1688168992"
"Appenzell","Appenzell","47.3333","9.4167","Switzerland","CH","CHE","Appenzell Innerrhoden","admin","5750","1756770881"
"Sežana","Sezana","45.7034","13.8706","Slovenia","SI","SVN","Sežana","admin","6037","1705079549"
"Zarasai","Zarasai","55.7333","26.2500","Lithuania","LT","LTU","Zarasai","admin","6051","1440450872"
"Zagorje","Zagorje","46.1342","14.9942","Slovenia","SI","SVN","Zagorje ob Savi","admin","6023","1705942287"
"Muramvya","Muramvya","-3.2500","29.6000","Burundi","BI","BDI","Muramvya","admin","5458","1108007544"
"Rogaška Slatina","Rogaska Slatina","46.2314","15.6381","Slovenia","SI","SVN","Rogaška Slatina","admin","5082","1705772825"
"Prevalje","Prevalje","46.5438","14.9119","Slovenia","SI","SVN","Prevalje","admin","4620","1705187949"
"Echternach","Echternach","49.8117","6.4217","Luxembourg","LU","LUX","Echternach","admin","5705","1442794725"
"Mamushë","Mamushe","42.3167","20.7167","Kosovo","XK","XKS","Mamushë","admin","5507","1901042958"
"Marsa","Marsa","35.8833","14.4947","Malta","MT","MLT","Marsa","admin","4401","1470720892"
"Trakai","Trakai","54.6333","24.9333","Lithuania","LT","LTU","Trakai","admin","5912","1440324561"
"Şoldăneşti","Soldanesti","47.8167","28.8000","Moldova","MD","MDA","Şoldăneşti","admin","5883","1498087659"
"Mwatate","Mwatate","-3.5047","38.3778","Kenya","KE","KEN","Taita/Taveta","admin","5551","1404648092"
"Outapi","Outapi","-17.5167","15.0000","Namibia","NA","NAM","Omusati","admin","4606","1516820698"
"Għaxaq","Ghaxaq","35.8483","14.5172","Malta","MT","MLT","Għaxaq","admin","4722","1470648445"
"Črnomelj","Crnomelj","45.5711","15.1889","Slovenia","SI","SVN","Črnomelj","admin","5496","1705132468"
"Idrija","Idrija","46.0025","14.0275","Slovenia","SI","SVN","Idrija","admin","5886","1705472042"
"Ranillug","Ranillug","42.4920","21.5590","Kosovo","XK","XKS","Ranillug","admin","5800","1901029901"
"Lija","Lija","35.9014","14.4472","Malta","MT","MLT","Lija","admin","3070","1470862409"
"Balvi","Balvi","57.1333","27.2500","Latvia","LV","LVA","Balvi","admin","5840","1428606162"
"Carrick on Shannon","Carrick on Shannon","53.9440","-8.0950","Ireland","IE","IRL","Leitrim","admin","4062","1372960774"
"Hrastnik","Hrastnik","46.1479","15.1528","Slovenia","SI","SVN","Hrastnik","admin","4962","1705818009"
"Molėtai","Moletai","55.2333","25.4167","Lithuania","LT","LTU","Molėtai","admin","5783","1440110307"
"Sisimiut","Sisimiut","66.9389","-53.6722","Greenland","GL","GRL","Qeqqata","admin","5582","1304160154"
"Stratford","Stratford","-39.3333","174.2833","New Zealand","NZ","NZL","Taranaki","admin","5740","1554001293"
"Halba","Halba","34.5506","36.0781","Lebanon","LB","LBN","Aakkâr","admin","4730","1422962727"
"Xocavənd","Xocavand","39.7953","47.1131","Azerbaijan","AZ","AZE","Xocavənd","admin","5700","1031454735"
"Fulin","Fulin","29.3489","102.6767","China","CN","CHN","Sichuan","","1049","1156759821"
"Masunga","Masunga","-20.6667","27.4167","Botswana","BW","BWA","North East","admin","5696","1072609074"
"Gospić","Gospic","44.5460","15.3750","Croatia","HR","HRV","Ličko-Senjska Županija","admin","5695","1191887616"
"Wiltz","Wiltz","49.9667","5.9333","Luxembourg","LU","LUX","Wiltz","admin","5427","1442345477"
"Cacheu","Cacheu","12.2667","-16.1667","Guinea-Bissau","GW","GNB","Cacheu","admin","5674","1624294223"
"Babək","Babak","39.1519","45.4417","Azerbaijan","AZ","AZE","Babək","admin","5600","1031598031"
"Nieuw Amsterdam","Nieuw Amsterdam","5.8833","-55.0833","Suriname","SR","SUR","Commewijne","admin","5650","1740547119"
"Kazlų Rūda","Kazlu Ruda","54.7500","23.5000","Lithuania","LT","LTU","Kazlų Rūdos","admin","5539","1440371651"
"Petnjica","Petnjica","42.9089","19.9644","Montenegro","ME","MNE","Petnjica","admin","5482","1499532482"
"Mali Iđoš","Mali Idos","45.7069","19.6644","Serbia","RS","SRB","Mali Iđoš","admin","5464","1688501809"
"Šakiai","Sakiai","54.9500","23.0500","Lithuania","LT","LTU","Šakiai","admin","5440","1440362808"
"Thaba-Tseka","Thaba-Tseka","-29.5333","28.6000","Lesotho","LS","LSO","Thaba-Tseka","admin","5423","1426611266"
"Kanifing","Kanifing","13.4500","-16.6667","The Gambia","GM","GMB","Kanifing","admin","","1270000000"
"Triesen","Triesen","47.1000","9.5333","Liechtenstein","LI","LIE","Triesen","admin","5230","1438826448"
"Xagħra","Xaghra","36.0503","14.2675","Malta","MT","MLT","Xagħra","admin","4886","1470781874"
"Partesh","Partesh","42.4019","21.4336","Kosovo","XK","XKS","Partesh","admin","5300","1901909986"
"Napak","Napak","2.1156","34.2267","Uganda","UG","UGA","Napak","admin","5278","1800784014"
"Skuodas","Skuodas","56.2667","21.5333","Lithuania","LT","LTU","Skuodas","admin","5346","1440264405"
"Kalangala","Kalangala","-0.3214","32.2919","Uganda","UG","UGA","Kalangala","admin","5200","1800446257"
"Kirakira","Kirakira","-10.4500","161.9167","Solomon Islands","SB","SLB","Makira and Ulawa","admin","3500","1090349580"
"Tarrafal","Tarrafal","16.5660","-24.3570","Cabo Verde","CV","CPV","Tarrafal de São Nicolau","admin","5237","1132502171"
"Ķekava","Kekava","56.8333","24.2333","Latvia","LV","LVA","Ķekava","admin","5160","1428331173"
"Albina","Albina","5.5000","-54.0500","Suriname","SR","SUR","Marowijne","admin","5247","1740921776"
"Pembroke","Pembroke","35.9264","14.4808","Malta","MT","MLT","Pembroke","admin","3645","1470802229"
"Slovenske Konjice","Slovenske Konjice","46.3383","15.4233","Slovenia","SI","SVN","Slovenske Konjice","admin","5157","1705506460"
"Grevenmacher","Grevenmacher","49.6806","6.4417","Luxembourg","LU","LUX","Grevenmacher","admin","4981","1442392954"
"Danilovgrad","Danilovgrad","42.6100","19.0500","Montenegro","ME","MNE","Danilovgrad","admin","5156","1499538261"
"Palé","Pale","-1.4069","5.6322","Equatorial Guinea","GQ","GNQ","Annobón","admin","5008","1226970205"
"Smiltene","Smiltene","57.4333","25.9000","Latvia","LV","LVA","Smiltene","admin","5151","1428035157"
"Liquiçá","Liquica","-8.5935","125.3273","Timor-Leste","TL","TLS","Likisá","admin","5152","1626054379"
"Ilulissat","Ilulissat","69.2167","-51.1000","Greenland","GL","GRL","Avannaata","admin","4737","1304656747"
"Nadur","Nadur","36.0381","14.2950","Malta","MT","MLT","Nadur","admin","4509","1470985739"
"Ignalina","Ignalina","55.3500","26.1667","Lithuania","LT","LTU","Ignalina","admin","5106","1440789779"
"Vrapčište","Vrapciste","41.8337","20.8851","Macedonia","MK","MKD","Vrapčište","admin","4874","1807187187"
"Žalec","Zalec","46.2510","15.1639","Slovenia","SI","SVN","Žalec","admin","5000","1705340993"
"Punta Gorda","Punta Gorda","16.1005","-88.8074","Belize","BZ","BLZ","Toledo","admin","5026","1084536442"
"Samtse","Samtse","27.0333","89.0833","Bhutan","BT","BTN","Samtse","admin","4981","1064763429"
"Gevgelija","Gevgelija","41.1392","22.5025","Macedonia","MK","MKD","Gevgelija","admin","4967","1807600399"
"Bu’aale","Bu'aale","1.0833","42.5833","Somalia","SO","SOM","Jubbada Dhexe","admin","5000","1706765820"
"Aībak","Aibak","36.2653","68.0167","Afghanistan","AF","AFG","Samangān","admin","4938","1004564619"
"Mauren","Mauren","47.2167","9.5333","Liechtenstein","LI","LIE","Mauren","admin","4404","1438767966"
"Iklin","Iklin","35.9042","14.4544","Malta","MT","MLT","Iklin","admin","3130","1470330332"
"Šentjur","Sentjur","46.2176","15.3960","Slovenia","SI","SVN","Šentjur","admin","4940","1705076635"
"Bled","Bled","46.3688","14.1140","Slovenia","SI","SVN","Bled","admin","4929","1705655016"
"Ordino","Ordino","42.5550","1.5331","Andorra","AD","AND","Ordino","admin","4858","1020655238"
"Tuzi","Tuzi","42.3656","19.3314","Montenegro","ME","MNE","Tuzi","admin","4748","1499325970"
"Bolama","Bolama","11.5767","-15.4828","Guinea-Bissau","GW","GNB","Bolama/Bijagós","admin","4819","1624958412"
"Eschen","Eschen","47.2167","9.5167","Liechtenstein","LI","LIE","Eschen","admin","4459","1438692471"
"Ponta do Sol","Ponta do Sol","17.2020","-25.0930","Cabo Verde","CV","CPV","Ribeira Grande","admin","4738","1132137257"
"Irig","Irig","45.1000","19.8500","Serbia","RS","SRB","Irig","admin","4848","1688660485"
"Dispur","Dispur","26.1500","91.7700","India","IN","IND","Assam","admin","","1356960169"
"Balzers","Balzers","47.0667","9.5000","Liechtenstein","LI","LIE","Balzers","admin","4628","1438263000"
"Klaksvík","Klaksvik","62.2375","-6.5390","Faroe Islands","FO","FRO","Klaksvík","admin","4664","1234149995"
"Tolmin","Tolmin","46.1857","13.7352","Slovenia","SI","SVN","Tolmin","admin","3525","1705602247"
"Viligili","Viligili","0.7569","73.4361","Maldives","MV","MDV","Huvadhuatholhu Uthuruburi","admin","2837","1462034411"
"Kuala Belait","Kuala Belait","4.5828","114.1918","Brunei","BN","BRN","Belait","admin","4259","1096999548"
"Bururi","Bururi","-3.9500","29.6167","Burundi","BI","BDI","Bururi","admin","4478","1108963889"
"Čoka","Coka","45.9333","20.1500","Serbia","RS","SRB","Čoka","admin","4707","1688200508"
"Šilalė","Silale","55.4833","22.1833","Lithuania","LT","LTU","Šilalė","admin","4699","1440106904"
"Radoviš","Radovis","41.6381","22.4644","Macedonia","MK","MKD","Radoviš","admin","4678","1807341711"
"Kalkara","Kalkara","35.8892","14.5294","Malta","MT","MLT","Kalkara","admin","3014","1470563392"
"Opovo","Opovo","45.0519","20.4303","Serbia","RS","SRB","Opovo","admin","4546","1688371736"
"Imqabba","Imqabba","35.8442","14.4669","Malta","MT","MLT","Imqabba","admin","3315","1470934982"
"Miklavž na Dravskem Polju","Miklavz na Dravskem Polju","46.5057","15.6993","Slovenia","SI","SVN","Miklavž na Dravskem Polju","admin","3808","1705244140"
"Bueng Kan","Bueng Kan","18.3672","103.6497","Thailand","TH","THA","Bueng Kan","admin","4494","1764944006"
"Sevnica","Sevnica","46.0092","15.3041","Slovenia","SI","SVN","Sevnica","admin","4533","1705218575"
"Remich","Remich","49.5444","6.3667","Luxembourg","LU","LUX","Remich","admin","3825","1442971283"
"Valka","Valka","57.7667","26.0000","Latvia","LV","LVA","Valka","admin","4522","1428763918"
"Pakruojis","Pakruojis","55.9667","23.8667","Lithuania","LT","LTU","Pakruojis","admin","4490","1440570493"
"Fuerte Olimpo","Fuerte Olimpo","-21.0375","-57.8708","Paraguay","PY","PRY","Alto Paraguay","admin","4498","1600444658"
"Švenčionys","Svencionys","55.1333","26.1556","Lithuania","LT","LTU","Švenčionys","admin","4480","1440170338"
"Qıvraq","Qivraq","39.3997","45.1131","Azerbaijan","AZ","AZE","Kəngərli","admin","4444","1031050255"
"Şuşa","Susa","39.7583","46.7483","Azerbaijan","AZ","AZE","Şuşa","admin","4446","1031808614"
"Black River","Black River","18.0257","-77.8509","Jamaica","JM","JAM","Saint Elizabeth","admin","4261","1388022504"
"Trzin","Trzin","46.1353","14.5600","Slovenia","SI","SVN","Trzin","admin","3925","1705707595"
"Dravograd","Dravograd","46.5903","15.0236","Slovenia","SI","SVN","Dravograd","admin","3088","1705130450"
"Floriana","Floriana","35.8933","14.5058","Malta","MT","MLT","Floriana","admin","2205","1470089344"
"Tržič","Trzic","46.3581","14.2930","Slovenia","SI","SVN","Tržič","admin","3670","1705554055"
"João Teves","Joao Teves","15.0680","-23.5890","Cabo Verde","CV","CPV","São Lourenço dos Órgãos","admin","","1132758433"
"Nwoya","Nwoya","2.6350","32.0000","Uganda","UG","UGA","Nwoya","admin","4295","1800205431"
"Ilirska Bistrica","Ilirska Bistrica","45.5679","14.2527","Slovenia","SI","SVN","Ilirska Bistrica","admin","4323","1705031705"
"Ta’ Xbiex","Ta' Xbiex","35.8992","14.4981","Malta","MT","MLT","Ta’ Xbiex","admin","1804","1470129537"
"Gudja","Gudja","35.8483","14.5025","Malta","MT","MLT","Gudja","admin","2997","1470798142"
"Cerknica","Cerknica","45.7964","14.3581","Slovenia","SI","SVN","Cerknica","admin","4018","1705932096"
"Marsaxlokk","Marsaxlokk","35.8417","14.5447","Malta","MT","MLT","Marsaxlokk","admin","3534","1470596833"
"Laško","Lasko","46.1563","15.2386","Slovenia","SI","SVN","Laško","admin","3288","1705962698"
"Ruše","Ruse","46.5386","15.5154","Slovenia","SI","SVN","Ruše","admin","4208","1705417281"
"Zreče","Zrece","46.3750","15.3854","Slovenia","SI","SVN","Zreče","admin","2922","1705764665"
"Šempeter pri Gorici","Sempeter pri Gorici","45.9284","13.6378","Slovenia","SI","SVN","Šempeter-Vrtojba","admin","3694","1705397569"
"Dingli","Dingli","35.8603","14.3814","Malta","MT","MLT","Dingli","admin","3608","1470281352"
"Bensonville","Bensonville","6.4456","-10.6097","Liberia","LR","LBR","Montserrado","admin","4089","1430964557"
"La Palma","La Palma","8.4100","-78.1500","Panama","PA","PAN","Darién","admin","4205","1591177082"
"Calheta de São Miguel","Calheta de Sao Miguel","15.1860","-23.5930","Cabo Verde","CV","CPV","São Miguel","admin","4123","1132750609"
"Kirkop","Kirkop","35.8419","14.4850","Malta","MT","MLT","Kirkop","admin","2191","1470075119"
"Wabag","Wabag","-5.4919","143.7217","Papua New Guinea","PG","PNG","Enga","admin","4072","1598428400"
"Għargħur","Gharghur","35.9241","14.4534","Malta","MT","MLT","Għargħur","admin","2768","1470917815"
"Canillo","Canillo","42.5667","1.6000","Andorra","AD","AND","Canillo","admin","4103","1020594179"
"Žiri","Ziri","46.0469","14.1097","Slovenia","SI","SVN","Žiri","admin","3657","1705440220"
"Gornja Radgona","Gornja Radgona","46.6752","15.9926","Slovenia","SI","SVN","Gornja Radgona","admin","3086","1705643923"
"Butalangu","Butalangu","0.8228","32.2428","Uganda","UG","UGA","Nakaseke","admin","3873","1800001908"
"Domagnano","Domagnano","43.9500","12.4667","San Marino","SM","SMR","Domagnano","admin","3565","1674267133"
"Kaberamaido","Kaberamaido","1.7667","33.1522","Uganda","UG","UGA","Kaberamaido","admin","3400","1800845749"
"Kalvarija","Kalvarija","54.4167","23.2167","Lithuania","LT","LTU","Kalvarija","admin","4025","1440799248"
"Omuthiya","Omuthiya","-18.3606","16.5812","Namibia","NA","NAM","Oshikoto","admin","3794","1516000004"
"Xewkija","Xewkija","36.0331","14.2583","Malta","MT","MLT","Xewkija","admin","3300","1470040289"
"Qobustan","Qobustan","40.5367","48.9328","Azerbaijan","AZ","AZE","Qobustan","admin","3945","1031385198"
"Ağdam","Agdam","39.9833","46.9167","Azerbaijan","AZ","AZE","Ağdam","admin","3770","1031176858"
"Trebnje","Trebnje","45.9104","15.0086","Slovenia","SI","SVN","Trebnje","admin","3927","1705570941"
"Deçan","Decan","42.5333","20.2833","Kosovo","XK","XKS","Deçan","admin","3803","1901189818"
"Kärdla","Kardla","58.9981","22.7467","Estonia","EE","EST","Hiiumaa","admin","3230","1233977488"
"Plandište","Plandiste","45.2269","21.1217","Serbia","RS","SRB","Plandište","admin","3832","1688176320"
"Lazdijai","Lazdijai","54.2333","23.5167","Lithuania","LT","LTU","Lazdijai","admin","3895","1440584882"
"Ribnica","Ribnica","45.7400","14.7308","Slovenia","SI","SVN","Ribnica","admin","3612","1705675272"
"Piran","Piran","45.5283","13.5683","Slovenia","SI","SVN","Piran","admin","3730","1705085466"
"Janjanbureh","Janjanbureh","13.5341","-14.7662","The Gambia","GM","GMB","Central River","admin","3584","1270430701"
"Cankuzo","Cankuzo","-3.2194","30.5528","Burundi","BI","BDI","Cankuzo","admin","3624","1108631099"
"Šoštanj","Sostanj","46.3798","15.0482","Slovenia","SI","SVN","Šoštanj","admin","2933","1705376916"
"Brezovica","Brezovica","46.0207","14.4178","Slovenia","SI","SVN","Brezovica","admin","3221","1705190624"
"Massenya","Massenya","11.4000","16.1667","Chad","TD","TCD","Chari-Baguirmi","admin","3680","1148928302"
"Ljutomer","Ljutomer","46.5168","16.2027","Slovenia","SI","SVN","Ljutomer","admin","3270","1705303708"
"Imġarr","Imgarr","35.9197","14.3664","Malta","MT","MLT","Imġarr","admin","3629","1470038163"
"Berovo","Berovo","41.7078","22.8564","Macedonia","MK","MKD","Berovo","admin","3619","1807873177"
"São João dos Angolares","Sao Joao dos Angolares","0.1333","6.6500","Sao Tome and Principe","ST","STP","Caué","admin","3605","1678826533"
"Mongar","Mongar","27.2750","91.2400","Bhutan","BT","BTN","Mongar","admin","3502","1064208677"
"Ankaran","Ankaran","45.5793","13.7379","Slovenia","SI","SVN","Ankaran","admin","3224","1705521676"
"Kruševo","Krusevo","41.3700","21.2483","Macedonia","MK","MKD","Kruševo","admin","3570","1807741715"
"Imtarfa","Imtarfa","35.8908","14.3969","Malta","MT","MLT","Imtarfa","admin","2572","1470946814"
"Xai","Xai","20.6914","101.9861","Laos","LA","LAO","Oudômxai","admin","","1418805827"
"Colonia","Colonia","9.5167","138.1333","Federated States of Micronesia","FM","FSM","Yap","admin","3126","1583511563"
"Sveti Nikole","Sveti Nikole","41.8650","21.9425","Macedonia","MK","MKD","Sveti Nikole","admin","3468","1807880409"
"Tutin","Tutin","42.9875","20.3256","Serbia","RS","SRB","Tutin","admin","","1688161411"
"Cantemir","Cantemir","46.2667","28.2167","Moldova","MD","MDA","Cantemir","admin","3429","1498973808"
"Xgħajra","Xghajra","35.8864","14.5472","Malta","MT","MLT","Xgħajra","admin","1732","1470535936"
"Šenčur","Sencur","46.2430","14.4181","Slovenia","SI","SVN","Šenčur","admin","3121","1705968659"
"Alibunar","Alibunar","45.0806","20.9656","Serbia","RS","SRB","Alibunar","admin","3431","1688248362"
"Ivančna Gorica","Ivancna Gorica","45.9374","14.8029","Slovenia","SI","SVN","Ivančna Gorica","admin","2205","1705930549"
"Malishevë","Malisheve","42.4828","20.7461","Kosovo","XK","XKS","Malishevë","admin","3395","1901597212"
"Ainaro","Ainaro","-8.9833","125.5000","Timor-Leste","TL","TLS","Ainaro","admin","3085","1626118494"
"Żebbuġ","Zebbug","36.0708","14.2369","Malta","MT","MLT","Żebbuġ (Ghawdex)","admin","2956","1470804129"
"Fish Town","Fish Town","5.1964","-7.8756","Liberia","LR","LBR","River Gee","admin","3328","1430204172"
"Qrendi","Qrendi","35.8342","14.4589","Malta","MT","MLT","Qrendi","admin","2752","1470797535"
"Škofljica","Skofljica","45.9836","14.5761","Slovenia","SI","SVN","Škofljica","admin","2716","1705067758"
"Lenart v Slovenskih Goricah","Lenart v Slovenskih Goricah","46.5742","15.8322","Slovenia","SI","SVN","Lenart","admin","3285","1705719159"
"Ulbroka","Ulbroka","56.9333","24.2833","Latvia","LV","LVA","Ropaži","admin","3102","1428511061"
"Buala","Buala","-8.1333","159.5833","Solomon Islands","SB","SLB","Isabel","admin","2813","1090726690"
"Mežica","Mezica","46.5206","14.8536","Slovenia","SI","SVN","Mežica","admin","3176","1705607524"
"Železniki","Zelezniki","46.2182","14.1592","Slovenia","SI","SVN","Železniki","admin","3069","1705885002"
"Demir Kapija","Demir Kapija","41.4114","22.2422","Macedonia","MK","MKD","Demir Kapija","admin","3275","1807182004"
"Saulkrasti","Saulkrasti","57.2500","24.4167","Latvia","LV","LVA","Saulkrasti","admin","3120","1428934591"
"Golubovci","Golubovci","42.3344","19.2253","Montenegro","ME","MNE","Zeta","admin","3110","1499735056"
"Rietavas","Rietavas","55.7167","21.9333","Lithuania","LT","LTU","Rietavas","admin","3253","1440780088"
"Groningen","Groningen","5.8000","-55.4667","Suriname","SR","SUR","Saramacca","admin","3216","1740072928"
"Metlika","Metlika","45.6517","15.3200","Slovenia","SI","SVN","Metlika","admin","3229","1705008169"
"Qaqortoq","Qaqortoq","60.7222","-46.0403","Greenland","GL","GRL","Kujalleq","admin","3093","1304746917"
"Štore","Store","46.2219","15.3111","Slovenia","SI","SVN","Štore","admin","2257","1705775604"
"Għajnsielem","Ghajnsielem","36.0269","14.2903","Malta","MT","MLT","Għajnsielem","admin","3200","1470050658"
"Veymandoo","Veymandoo","2.1881","73.0947","Maldives","MV","MDV","Kolhumadulu","admin","1118","1462464790"
"Balaka","Balaka","-14.9889","34.9591","Malawi","MW","MWI","Balaka","admin","","1454732693"
"Şahbuz","Sahbuz","39.4072","45.5739","Azerbaijan","AZ","AZE","Şahbuz","admin","3127","1031323095"
"Birštonas","Birstonas","54.6028","24.0206","Lithuania","LT","LTU","Birštonas","admin","3094","1440134144"
"Trashigang","Trashigang","27.3326","91.5528","Bhutan","BT","BTN","Trashigang","admin","3037","1064123227"
"Borovnica","Borovnica","45.9197","14.3572","Slovenia","SI","SVN","Borovnica","admin","2674","1705665714"
"Porto Inglês","Porto Ingles","15.1380","-23.2110","Cabo Verde","CV","CPV","Maio","admin","3056","1132346974"
"Delčevo","Delcevo","41.9661","22.7747","Macedonia","MK","MKD","Delčevo","admin","3033","1807161783"
"Aasiaat","Aasiaat","68.7097","-52.8694","Greenland","GL","GRL","Qeqertalik","admin","3069","1304194714"
"Safi","Safi","35.8333","14.4850","Malta","MT","MLT","Safi","admin","2126","1470417421"
"Rogašovci","Rogasovci","46.8053","16.0344","Slovenia","SI","SVN","Rogašovci","admin","2946","1705970248"
"Rače","Race","46.4529","15.6812","Slovenia","SI","SVN","Rače-Fram","admin","2693","1705643433"
"Lethem","Lethem","3.3833","-59.8000","Guyana","GY","GUY","Upper Takutu-Upper Essequibo","admin","3000","1328749920"
"Mabaruma","Mabaruma","8.2000","-59.7833","Guyana","GY","GUY","Barima-Waini","admin","2972","1328079873"
"Polzela","Polzela","46.2809","15.0740","Slovenia","SI","SVN","Polzela","admin","2351","1705144123"
"Lendava","Lendava","46.5631","16.4519","Slovenia","SI","SVN","Lendava","admin","2954","1705653525"
"Bogdanci","Bogdanci","41.2031","22.5728","Macedonia","MK","MKD","Bogdanci","admin","2951","1807565779"
"Damān","Daman","20.4169","72.8340","India","IN","IND","Dādra and Nagar Haveli and Damān and Diu","admin","","1356008028"
"Vojnik","Vojnik","46.2933","15.3052","Slovenia","SI","SVN","Vojnik","admin","2475","1705662463"
"Thulusdhoo","Thulusdhoo","4.3742","73.6517","Maldives","MV","MDV","Maaleatholhu","admin","","1462000004"
"Žitište","Zitiste","45.4833","20.5500","Serbia","RS","SRB","Žitište","admin","2903","1688363591"
"Fiorentino","Fiorentino","43.9106","12.4556","San Marino","SM","SMR","Fiorentino","admin","2548","1674000001"
"Manadhoo","Manadhoo","5.7628","73.4106","Maldives","MV","MDV","Miladhunmadulu Dhekunuburi","admin","2041","1462220750"
"Sowa Town","Sowa Town","-20.5636","26.2244","Botswana","BW","BWA","Sowa Town","admin","2914","1072487689"
"Bopolu","Bopolu","7.0667","-10.4875","Liberia","LR","LBR","Gbarpolu","admin","2908","1430414456"
"Ta Khmau","Ta Khmau","11.4833","104.9500","Cambodia","KH","KHM","Kandal","admin","","1116571704"
"São Domingos","Sao Domingos","15.0280","-23.5630","Cabo Verde","CV","CPV","São Domingos","admin","2818","1132246964"
"Radlje ob Dravi","Radlje ob Dravi","46.6152","15.2264","Slovenia","SI","SVN","Radlje ob Dravi","admin","2856","1705478193"
"Kerewan","Kerewan","13.5000","-16.0833","The Gambia","GM","GMB","North Bank","admin","2751","1270976260"
"Radenci","Radenci","46.6500","16.0500","Slovenia","SI","SVN","Radenci","admin","2142","1705609332"
"Fontana","Fontana","36.0364","14.2422","Malta","MT","MLT","Fontana","admin","985","1470427419"
"Aranđelovac","Arandelovac","44.3042","20.5561","Serbia","RS","SRB","Aranđelovac","admin","","1688854200"
"Rasdhoo","Rasdhoo","4.2631","72.9919","Maldives","MV","MDV","Ariatholhu Uthuruburi","admin","","1462000003"
"Dhihdhoo","Dhihdhoo","6.8874","73.1140","Maldives","MV","MDV","Thiladhunmathee Uthuruburi","admin","","1462109783"
"Mozirje","Mozirje","46.3381","14.9572","Slovenia","SI","SVN","Mozirje","admin","2052","1705239053"
"Trashi Yangtse","Trashi Yangtse","27.5833","91.4667","Bhutan","BT","BTN","Trashi Yangtse","admin","2735","1064210713"
"Barclayville","Barclayville","4.6797","-8.2339","Liberia","LR","LBR","Grand Kru","admin","2733","1430129873"
"Kolašin","Kolasin","42.8236","19.5225","Montenegro","ME","MNE","Kolašin","admin","2725","1499083772"
"Triesenberg","Triesenberg","47.1181","9.5433","Liechtenstein","LI","LIE","Triesenberg","admin","2643","1438813592"
"Muta","Muta","46.6167","15.1667","Slovenia","SI","SVN","Muta","admin","2291","1705459961"
"Amudat","Amudat","1.9522","34.9444","Uganda","UG","UGA","Amudat","admin","1900","1800886754"
"Trongsa","Trongsa","27.4994","90.5047","Bhutan","BT","BTN","Trongsa","admin","2695","1064630357"
"Negotino","Negotino","41.4839","22.0892","Macedonia","MK","MKD","Negotino","admin","2683","1807848381"
"Cerklje na Gorenjskem","Cerklje na Gorenjskem","46.2488","14.4855","Slovenia","SI","SVN","Cerklje na Gorenjskem","admin","1710","1705820033"
"Sannat","Sannat","36.0244","14.2458","Malta","MT","MLT","Sannat","admin","2117","1470546177"
"Qala","Qala","36.0353","14.3103","Malta","MT","MLT","Qala","admin","2284","1470520722"
"Šmarje","Smarje","46.2281","15.5182","Slovenia","SI","SVN","Šmarje pri Jelšah","admin","1755","1705373506"
"Kllokot","Kllokot","42.3667","21.3833","Kosovo","XK","XKS","Kllokot","admin","2556","1901445768"
"Cestos City","Cestos City","5.4667","-9.5833","Liberia","LR","LBR","River Cess","admin","2578","1430708587"
"Tofol","Tofol","5.3258","163.0086","Federated States of Micronesia","FM","FSM","Kosrae","admin","2591","1583000000"
"Ruggell","Ruggell","47.2450","9.5332","Liechtenstein","LI","LIE","Ruggell","admin","2295","1438787609"
"Acquaviva","Acquaviva","43.9457","12.4185","San Marino","SM","SMR","Acquaviva","admin","2145","1674696011"
"Mahdia","Mahdia","5.2667","-59.1500","Guyana","GY","GUY","Potaro-Siparuni","admin","2563","1328358422"
"Spodnje Hoče","Spodnje Hoce","46.4996","15.6471","Slovenia","SI","SVN","Hoče-Slivnica","admin","2109","1705379997"
"Ivanjica","Ivanjica","43.5811","20.2297","Serbia","RS","SRB","Ivanjica","admin","","1688450536"
"Vevčani","Vevcani","41.2403","20.5931","Macedonia","MK","MKD","Vevčani","admin","2416","1807108428"
"Črna na Koroškem","Crna na Koroskem","46.4697","14.8490","Slovenia","SI","SVN","Črna na Koroškem","admin","2201","1705127725"
"Beltinci","Beltinci","46.6060","16.2328","Slovenia","SI","SVN","Beltinci","admin","2430","1705238814"
"Ig","Ig","45.9692","14.5394","Slovenia","SI","SVN","Ig","admin","2240","1705409266"
"Kabarnet","Kabarnet","0.4940","35.7440","Kenya","KE","KEN","Baringo","admin","","1404620707"
"Afega","Afega","-13.8019","-171.8217","Samoa","WS","WSM","Tuamasaga","admin","1998","1882284139"
"Prebold","Prebold","46.2369","15.0919","Slovenia","SI","SVN","Prebold","admin","1605","1705746627"
"Nida","Nida","55.3033","21.0056","Lithuania","LT","LTU","Neringa","admin","2385","1440271703"
"Pivka","Pivka","45.6833","14.2000","Slovenia","SI","SVN","Pivka","admin","2059","1705709650"
"Vianden","Vianden","49.9336","6.2075","Luxembourg","LU","LUX","Vianden","admin","2161","1442979391"
"Zhemgang","Zhemgang","27.2134","90.6550","Bhutan","BT","BTN","Zhemgang","admin","2332","1064512651"
"Sariwŏn-si","Sariwon-si","38.5242","125.7547","North Korea","KP","PRK","Hwangbuk","admin","","1408002125"
"Laçın","Lacin","39.6408","46.5469","Azerbaijan","AZ","AZE","Laçın","admin","2190","1031245091"
"Kerċem","Kercem","36.0406","14.2297","Malta","MT","MLT","Kerċem","admin","1938","1470847062"
"Lovrenc na Pohorju","Lovrenc na Pohorju","46.5381","15.3844","Slovenia","SI","SVN","Lovrenc na Pohorju","admin","1992","1705376318"
"Capellen","Capellen","49.6444","5.9897","Luxembourg","LU","LUX","Capellen","admin","1986","1442533571"
"Heydərabad","Heydarabad","39.7203","44.8531","Azerbaijan","AZ","AZE","Sədərək","admin","2000","1031540160"
"Onverwacht","Onverwacht","5.5931","-55.1939","Suriname","SR","SUR","Para","admin","2223","1740007571"
"Naklo","Naklo","46.2749","14.3176","Slovenia","SI","SVN","Naklo","admin","1776","1705637872"
"Gradsko","Gradsko","41.5775","21.9428","Macedonia","MK","MKD","Gradsko","admin","2219","1807080471"
"Straža","Straza","45.7864","15.0745","Slovenia","SI","SVN","Straža","admin","1945","1705491164"
"Cidade Velha","Cidade Velha","14.9160","-23.6060","Cabo Verde","CV","CPV","Ribeira Grande de Santiago","admin","2148","1132073400"
"Priboj","Priboj","43.5836","19.5258","Serbia","RS","SRB","Priboj","admin","","1688522811"
"Semič","Semic","45.6546","15.1783","Slovenia","SI","SVN","Semič","admin","2017","1705653513"
"Migori","Migori","-1.0634","34.4731","Kenya","KE","KEN","Migori","admin","","1404478510"
"Totness","Totness","5.8775","-56.3292","Suriname","SR","SUR","Coronie","admin","2150","1740738832"
"Spodnji Duplek","Spodnji Duplek","46.5053","15.7467","Slovenia","SI","SVN","Duplek","admin","1689","1705953692"
"Šentjernej","Sentjernej","45.8389","15.3361","Slovenia","SI","SVN","Šentjernej","admin","1441","1705329272"
"Miren","Miren","45.8962","13.6072","Slovenia","SI","SVN","Miren-Kostanjevica","admin","1506","1705608129"
"Felidhoo","Felidhoo","3.4717","73.5472","Maldives","MV","MDV","Felidheatholhu","admin","506","1462456773"
"Chiradzulu","Chiradzulu","-15.7000","35.1833","Malawi","MW","MWI","Chiradzulu","admin","1580","1454413713"
"Same","Same","-9.0000","125.6500","Timor-Leste","TL","TLS","Manufahi","admin","2100","1626402267"
"Jagodina","Jagodina","43.9750","21.2564","Serbia","RS","SRB","Jagodina","admin","","1688452613"
"Sofifi","Sofifi","0.7244","127.5806","Indonesia","ID","IDN","Maluku Utara","admin","2000","1360431437"
"Vipava","Vipava","45.8476","13.9624","Slovenia","SI","SVN","Vipava","admin","2049","1705864633"
"Ambrolauri","Ambrolauri","42.5194","43.1500","Georgia","GE","GEO","Rach’a-Lechkhumi da Kvemo Svaneti","admin","2047","1268370586"
"Tulagi","Tulagi","-9.1014","160.1467","Solomon Islands","SB","SLB","Central","admin","1251","1090844311"
"Zgornja Kungota","Zgornja Kungota","46.6388","15.6141","Slovenia","SI","SVN","Kungota","admin","1594","1705104301"
"Radeče","Radece","46.0658","15.1728","Slovenia","SI","SVN","Radeče","admin","1993","1705420341"
"Valandovo","Valandovo","41.3169","22.5611","Macedonia","MK","MKD","Valandovo","admin","1992","1807423601"
"Damongo","Damongo","9.0833","-1.8167","Ghana","GH","GHA","Savannah","admin","2014","1288827226"
"Djibloho","Djibloho","1.5889","10.8225","Equatorial Guinea","GQ","GNQ","Djibloho","admin","2000","1226998379"
"Ormož","Ormoz","46.4086","16.1475","Slovenia","SI","SVN","Ormož","admin","1962","1705413437"
"Vuzenica","Vuzenica","46.5992","15.1669","Slovenia","SI","SVN","Vuzenica","admin","1522","1705639122"
"Lifford","Lifford","54.8340","-7.4860","Ireland","IE","IRL","Donegal","admin","1626","1372149317"
"Munxar","Munxar","36.0303","14.2353","Malta","MT","MLT","Munxar","admin","1454","1470116785"
"Kriva Palanka","Kriva Palanka","42.2017","22.3317","Macedonia","MK","MKD","Kriva Palanka","admin","1967","1807058849"
"Bohinjska Bistrica","Bohinjska Bistrica","46.2742","13.9524","Slovenia","SI","SVN","Bohinj","admin","1767","1705094350"
"Divača","Divaca","45.6820","13.9702","Slovenia","SI","SVN","Divača","admin","1734","1705065298"
"Kratovo","Kratovo","42.0783","22.1750","Macedonia","MK","MKD","Kratovo","admin","1925","1807262234"
"Žabljak","Zabljak","43.1550","19.1208","Montenegro","ME","MNE","Žabljak","admin","1937","1499125825"
"Gamprin","Gamprin","47.2167","9.5000","Liechtenstein","LI","LIE","Gamprin","admin","1663","1438568521"
"Šmartno","Smartno","46.0441","14.8466","Slovenia","SI","SVN","Šmartno pri Litiji","admin","1395","1705164835"
"Krivogaštani","Krivogastani","41.3358","21.3331","Macedonia","MK","MKD","Krivogaštani","admin","1870","1807293579"
"Nyamira","Nyamira","-0.5633","34.9358","Kenya","KE","KEN","Nyamira","admin","","1404000104"
"Zrnovci","Zrnovci","41.8542","22.4442","Macedonia","MK","MKD","Zrnovci","admin","1864","1807046032"
"Mislinja","Mislinja","46.4431","15.1931","Slovenia","SI","SVN","Mislinja","admin","1862","1705898157"
"Sopište","Sopiste","41.9500","21.4333","Macedonia","MK","MKD","Sopište","admin","690","1807691189"
"Vodice","Vodice","46.1833","14.4833","Slovenia","SI","SVN","Vodice","admin","1631","1705728443"
"Trim","Trim","53.5550","-6.7917","Ireland","IE","IRL","Meath","admin","","1372676556"
"Rogatec","Rogatec","46.2243","15.7052","Slovenia","SI","SVN","Rogatec","admin","1540","1705579021"
"Għarb","Gharb","36.0611","14.2092","Malta","MT","MLT","Għarb","admin","1539","1470113453"
"Odranci","Odranci","46.5856","16.2753","Slovenia","SI","SVN","Odranci","admin","1632","1705912877"
"Selnica ob Dravi","Selnica ob Dravi","46.5514","15.4947","Slovenia","SI","SVN","Selnica ob Dravi","admin","1348","1705864774"
"Dragomer","Dragomer","46.0189","14.3820","Slovenia","SI","SVN","Log-Dragomer","admin","1464","1705762040"
"Nazarje","Nazarje","46.3202","14.9531","Slovenia","SI","SVN","Nazarje","admin","880","1705598784"
"Vreed-en-Hoop","Vreed-en-Hoop","6.8076","-58.1815","Guyana","GY","GUY","Essequibo Islands-West Demerara","admin","469","1328178268"
"Oplotnica","Oplotnica","46.3860","15.4458","Slovenia","SI","SVN","Oplotnica","admin","1420","1705182651"
"Šabac","Sabac","44.7558","19.6939","Serbia","RS","SRB","Šabac","admin","","1688640421"
"Machinga","Machinga","-14.9667","35.5167","Malawi","MW","MWI","Machinga","admin","1418","1454566785"
"Varakļāni","Varaklani","56.6000","26.7500","Latvia","LV","LVA","Varakļāni","admin","1730","1428435689"
"Mirna","Mirna","45.9476","15.0664","Slovenia","SI","SVN","Mirna","admin","1371","1705033855"
"Pehčevo","Pehcevo","41.7592","22.8906","Macedonia","MK","MKD","Pehčevo","admin","1687","1807666030"
"Ropaži","Ropazi","56.9718","24.6318","Latvia","LV","LVA","Ropaži","admin","1681","1428220999"
"Gusinje","Gusinje","42.5619","19.8339","Montenegro","ME","MNE","Gusinje","admin","1673","1499342041"
"Turnišče","Turnisce","46.6194","16.3223","Slovenia","SI","SVN","Turnišče","admin","1506","1705851676"
"Plasnica","Plasnica","41.4667","21.1167","Macedonia","MK","MKD","Plasnica","admin","1584","1807030257"
"Smederevo","Smederevo","44.6633","20.9289","Serbia","RS","SRB","Smederevo","admin","","1688344019"
"Pārūn","Parun","35.4167","70.9169","Afghanistan","AF","AFG","Nūristān","admin","1647","1004795942"
"Arilje","Arilje","43.7531","20.0956","Serbia","RS","SRB","Arilje","admin","","1688798017"
"Tearce","Tearce","42.0775","21.0519","Macedonia","MK","MKD","Tearce","admin","1475","1807139125"
"Vladičin Han","Vladicin Han","42.7000","22.0667","Serbia","RS","SRB","Vladičin Han","admin","","1688664018"
"Cerkno","Cerkno","46.1283","13.9915","Slovenia","SI","SVN","Cerkno","admin","1425","1705759580"
"Xızı","Xizi","40.9078","49.0733","Azerbaijan","AZ","AZE","Xızı","admin","1600","1031409125"
"Sotik Post","Sotik Post","-0.7813","35.3416","Kenya","KE","KEN","Bomet","admin","","1404087664"
"Bovec","Bovec","46.3378","13.5522","Slovenia","SI","SVN","Bovec","admin","1586","1705609781"
"Nova Varoš","Nova Varos","43.4667","19.8203","Serbia","RS","SRB","Nova Varoš","admin","","1688051556"
"Kanal","Kanal","46.0880","13.6347","Slovenia","SI","SVN","Kanal ob Soči","admin","1142","1705955833"
"Poljčane","Poljcane","46.3135","15.5791","Slovenia","SI","SVN","Poljčane","admin","1118","1705286007"
"Clervaux","Clervaux","50.0500","6.0333","Luxembourg","LU","LUX","Clervaux","admin","1468","1442118762"
"Isangel","Isangel","-19.5300","169.2800","Vanuatu","VU","VUT","Tafea","admin","1200","1548941443"
"Murang’a","Murang'a","-0.7167","37.1500","Kenya","KE","KEN","Murang’a","admin","","1404066738"
"Tabor","Tabor","46.2108","15.0047","Slovenia","SI","SVN","Tabor","admin","1494","1705756002"
"Nova Crnja","Nova Crnja","45.6667","20.6000","Serbia","RS","SRB","Nova Crnja","admin","1491","1688294163"
"Mogila","Mogila","41.1083","21.3786","Macedonia","MK","MKD","Mogila","admin","1480","1807583335"
"Moravče","Moravce","46.1356","14.7439","Slovenia","SI","SVN","Moravče","admin","957","1705291500"
"Horjul","Horjul","46.0230","14.2981","Slovenia","SI","SVN","Horjul","admin","1169","1705243858"
"Kuršumlija","Kursumlija","43.1408","21.2678","Serbia","RS","SRB","Kuršumlija","admin","","1688343996"
"Dornava","Dornava","46.4299","15.9488","Slovenia","SI","SVN","Dornava","admin","1263","1705023699"
"Kranjska Gora","Kranjska Gora","46.4854","13.7871","Slovenia","SI","SVN","Kranjska Gora","admin","1452","1705443136"
"Aleksandrovac","Aleksandrovac","43.4553","21.0514","Serbia","RS","SRB","Aleksandrovac","admin","","1688573505"
"Xékong","Xekong","15.3503","106.7286","Laos","LA","LAO","Xékong","admin","","1418000082"
"Šmartno","Smartno","46.3297","15.0325","Slovenia","SI","SVN","Šmartno ob Paki","admin","636","1705804130"
"Kidričevo","Kidricevo","46.4060","15.7983","Slovenia","SI","SVN","Kidričevo","admin","1257","1705321982"
"Xocalı","Xocali","39.9111","46.7892","Azerbaijan","AZ","AZE","Xocalı","admin","1397","1031237556"
"Leulumoega","Leulumoega","-13.8167","-171.9167","Samoa","WS","WSM","A‘ana","admin","1184","1882782502"
"Nilandhoo","Nilandhoo","3.0567","72.8900","Maldives","MV","MDV","Nilandheatholhu Uthuruburi","admin","","1462000005"
"Taro","Taro","-6.7111","156.3972","Solomon Islands","SB","SLB","Choiseul","admin","","1090931394"
"Schellenberg","Schellenberg","47.2336","9.5481","Liechtenstein","LI","LIE","Schellenberg","admin","1091","1438649917"
"Santo António","Santo Antonio","1.6367","7.4178","Sao Tome and Principe","ST","STP","Príncipe","admin","1300","1678729743"
"Plužine","Pluzine","43.1500","18.8300","Montenegro","ME","MNE","Plužine","admin","1341","1499719205"
"Gornji Milanovac","Gornji Milanovac","44.0212","20.4560","Serbia","RS","SRB","Gornji Milanovac","admin","","1688363978"
"Vitanje","Vitanje","46.3825","15.2956","Slovenia","SI","SVN","Vitanje","admin","859","1705925648"
"Faetano","Faetano","43.9256","12.4983","San Marino","SM","SMR","Faetano","admin","1177","1674994606"
"Gorenja Vas","Gorenja Vas","46.1069","14.1451","Slovenia","SI","SVN","Gorenja Vas-Poljane","admin","1132","1705961189"
"Safotu","Safotu","-13.4528","-172.4036","Samoa","WS","WSM","Gagaifomauga","admin","1270","1882879383"
"Svrljig","Svrljig","43.4167","22.1167","Serbia","RS","SRB","Svrljig","admin","","1688519603"
"Novo Selo","Novo Selo","41.4128","22.8800","Macedonia","MK","MKD","Novo Selo","admin","1246","1807743098"
"Ljubno","Ljubno","46.3333","14.8333","Slovenia","SI","SVN","Ljubno","admin","1094","1705083617"
"Oranjestad","Oranjestad","17.4833","-62.9833","Bonaire, Sint Eustatius, and Saba","BQ","BES","Sint Eustatius","admin","1038","1528346417"
"Novaci","Novaci","41.0419","21.4561","Macedonia","MK","MKD","Novaci","admin","1283","1807936735"
"Rosoman","Rosoman","41.5161","21.9497","Macedonia","MK","MKD","Rosoman","admin","1247","1807663389"
"Lata","Lata","-10.7167","165.8333","Solomon Islands","SB","SLB","Temotu","admin","553","1090442496"
"Komenda","Komenda","46.2073","14.5390","Slovenia","SI","SVN","Komenda","admin","896","1705295612"
"Črenšovci","Crensovci","46.5747","16.2937","Slovenia","SI","SVN","Črenšovci","admin","1151","1705698578"
"Montegiardino","Montegiardino","43.9089","12.4844","San Marino","SM","SMR","Montegiardino","admin","967","1674000002"
"Benedikt","Benedikt","46.6065","15.8842","Slovenia","SI","SVN","Benedikt","admin","1078","1705523597"
"‘Amrān","\`Amran","15.6594","43.9439","Yemen","YE","YEM","‘Amrān","admin","","1887433410"
"Raška","Raska","43.2856","20.6092","Serbia","RS","SRB","Raška","admin","","1688129790"
"Pirot","Pirot","43.1519","22.5850","Serbia","RS","SRB","Pirot","admin","","1688324137"
"Prokuplje","Prokuplje","43.2339","21.5861","Serbia","RS","SRB","Prokuplje","admin","","1688111468"
"Stari Trg","Stari Trg","45.7142","14.4695","Slovenia","SI","SVN","Loška Dolina","admin","838","1705849784"
"Mirna Peč","Mirna Pec","45.8580","15.0876","Slovenia","SI","SVN","Mirna Peč","admin","1015","1705096910"
"Asau","Asau","-13.5194","-172.6372","Samoa","WS","WSM","Vaisigano","admin","1133","1882504626"
"Brus","Brus","43.3836","21.0336","Serbia","RS","SRB","Brus","admin","","1688936225"
"Surdulica","Surdulica","42.6950","22.1672","Serbia","RS","SRB","Surdulica","admin","","1688920744"
"Bosilovo","Bosilovo","41.4406","22.7278","Macedonia","MK","MKD","Bosilovo","admin","1069","1807868218"
"Daga","Daga","27.0667","89.8833","Bhutan","BT","BTN","Dagana","admin","1146","1064785735"
"Gorišnica","Gorisnica","46.4122","16.0131","Slovenia","SI","SVN","Gorišnica","admin","856","1705561619"
"Ub","Ub","44.4500","20.0667","Serbia","RS","SRB","Ub","admin","","1688701954"
"Lučani","Lucani","43.8667","20.1333","Serbia","RS","SRB","Lučani","admin","","1688437278"
"Muli","Muli","2.9217","73.5811","Maldives","MV","MDV","Mulakatholhu","admin","","1462436531"
"Gornji Grad","Gornji Grad","46.2961","14.8052","Slovenia","SI","SVN","Gornji Grad","admin","1016","1705879741"
"Pesnica","Pesnica","46.6099","15.6714","Slovenia","SI","SVN","Pesnica","admin","843","1705389455"
"Kobarid","Kobarid","46.2464","13.5780","Slovenia","SI","SVN","Kobarid","admin","1085","1705651680"
"Andrijevica","Andrijevica","42.7300","19.7900","Montenegro","ME","MNE","Andrijevica","admin","1073","1499379378"
"Središče ob Dravi","Sredisce ob Dravi","46.3933","16.2722","Slovenia","SI","SVN","Središče ob Dravi","admin","984","1705613407"
"Sveta Trojica v Slovenskih Goricah","Sveta Trojica v Slovenskih Goricah","46.5756","15.8769","Slovenia","SI","SVN","Sveta Trojica v Slovenskih Goricah","admin","754","1705659496"
"Veržej","Verzej","46.5818","16.1623","Slovenia","SI","SVN","Veržej","admin","900","1705529436"
"Lufilufi","Lufilufi","-13.8500","-171.5833","Samoa","WS","WSM","Atua","admin","949","1882092632"
"Dobrova","Dobrova","46.0533","14.4144","Slovenia","SI","SVN","Dobrova-Polhov Gradec","admin","921","1705653264"
"Videm pri Ptuju","Videm pri Ptuju","46.3699","15.8935","Slovenia","SI","SVN","Videm","admin","451","1705681768"
"Požega","Pozega","43.8459","20.0368","Serbia","RS","SRB","Požega","admin","","1688211541"
"Neno","Neno","-15.3981","34.6534","Malawi","MW","MWI","Neno","admin","","1454089137"
"Isale","Isale","-3.3444","29.4833","Burundi","BI","BDI","Bujumbura Rural","admin","","1108407022"
"Dobrovnik","Dobrovnik","46.6515","16.3479","Slovenia","SI","SVN","Dobrovnik","admin","948","1705602796"
"Jegunovce","Jegunovce","42.0731","21.1231","Macedonia","MK","MKD","Jegunovce","admin","846","1807040114"
"Siaya","Siaya","0.0667","34.2833","Kenya","KE","KEN","Siaya","admin","","1404162576"
"Velika Polana","Velika Polana","46.5719","16.3472","Slovenia","SI","SVN","Velika Polana","admin","870","1705627212"
"Mahonda","Mahonda","-5.9897","39.2519","Tanzania","TZ","TZA","Zanzibar North","admin","","1834907448"
"Konče","Konce","41.4958","22.3825","Macedonia","MK","MKD","Konče","admin","966","1807382770"
"Dolenjske Toplice","Dolenjske Toplice","45.7545","15.0583","Slovenia","SI","SVN","Dolenjske Toplice","admin","882","1705395845"
"Kostanjevica na Krki","Kostanjevica na Krki","45.8463","15.4249","Slovenia","SI","SVN","Kostanjevica na Krki","admin","689","1705796422"
"Smederevska Palanka","Smederevska Palanka","44.3655","20.9587","Serbia","RS","SRB","Smederevska Palanka","admin","","1688107292"
"Dol","Dol","46.0884","14.6425","Slovenia","SI","SVN","Dol pri Ljubljani","admin","277","1705717325"
"Chikwawa","Chikwawa","-16.0350","34.8010","Malawi","MW","MWI","Chikwawa","admin","","1454343925"
"Preddvor","Preddvor","46.3052","14.4203","Slovenia","SI","SVN","Preddvor","admin","835","1705403351"
"Zgornja Hajdina","Zgornja Hajdina","46.4057","15.8385","Slovenia","SI","SVN","Hajdina","admin","804","1705893552"
"San Lawrenz","San Lawrenz","36.0550","14.2042","Malta","MT","MLT","San Lawrenz","admin","748","1470602161"
"Namutumba","Namutumba","0.8363","33.6858","Uganda","UG","UGA","Namutumba","admin","","1800673440"
"Fonadhoo","Fonadhoo","1.8342","73.5031","Maldives","MV","MDV","Hahdhunmathi","admin","","1462000001"
"Starše","Starse","46.4664","15.7675","Slovenia","SI","SVN","Starše","admin","757","1705557844"
"Sodražica","Sodrazica","45.7616","14.6363","Slovenia","SI","SVN","Sodražica","admin","842","1705295579"
"Babušnica","Babusnica","43.0680","22.4115","Serbia","RS","SRB","Babušnica","admin","","1688685780"
"Vlasotince","Vlasotince","42.9667","22.1333","Serbia","RS","SRB","Vlasotince","admin","","1688116678"
"Ljubovija","Ljubovija","44.1869","19.3728","Serbia","RS","SRB","Ljubovija","admin","","1688274240"
"Lajkovac","Lajkovac","44.3667","20.1667","Serbia","RS","SRB","Lajkovac","admin","","1688900219"
"Rostuša","Rostusa","41.6100","20.6000","Macedonia","MK","MKD","Mavrovo i Rostuše","admin","872","1807907483"
"Majšperk","Majsperk","46.3500","15.7333","Slovenia","SI","SVN","Majšperk","admin","606","1705147774"
"Kosjerić","Kosjeric","44.0000","19.9167","Serbia","RS","SRB","Kosjerić","admin","","1688365764"
"P’yŏngsŏng-si","P'yongsong-si","39.2828","125.8633","North Korea","KP","PRK","P’yŏngnam","admin","","1408002002"
"Mionica","Mionica","44.2500","20.0833","Serbia","RS","SRB","Mionica","admin","","1688862283"
"Velike Lašče","Velike Lasce","45.8363","14.6377","Slovenia","SI","SVN","Velike Lašče","admin","724","1705618557"
"Bela Palanka","Bela Palanka","43.2178","22.3067","Serbia","RS","SRB","Bela Palanka","admin","","1688276422"
"Asaba","Asaba","6.1833","6.7500","Nigeria","NG","NGA","Delta","admin","","1566322425"
"Moravske-Toplice","Moravske-Toplice","46.6927","16.2281","Slovenia","SI","SVN","Moravske Toplice","admin","752","1705126489"
"Videm","Videm","45.8484","14.6944","Slovenia","SI","SVN","Dobrepolje","admin","590","1705074870"
"Dobrna","Dobrna","46.3381","15.2293","Slovenia","SI","SVN","Dobrna","admin","536","1705353787"
"Vailoa","Vailoa","-13.7558","-172.3064","Samoa","WS","WSM","Palauli","admin","784","1882047846"
"Aïn Defla","Ain Defla","36.2583","1.9583","Algeria","DZ","DZA","Aïn Defla","admin","","1012656497"
"Bukwo","Bukwo","1.2928","34.7531","Uganda","UG","UGA","Bukwo","admin","","1800276050"
"Nkurenkuru","Nkurenkuru","-17.6167","18.6000","Namibia","NA","NAM","Kavango West","admin","618","1516271813"
"Obleševo","Oblesevo","41.8833","22.3339","Macedonia","MK","MKD","Češinovo-Obleševo","admin","673","1807758244"
"Kapenguria","Kapenguria","1.2333","35.1167","Kenya","KE","KEN","West Pokot","admin","","1404228137"
"Puconci","Puconci","46.7039","16.1572","Slovenia","SI","SVN","Puconci","admin","633","1705677747"
"Širvintos","Sirvintos","55.0361","24.9694","Lithuania","LT","LTU","Širvintos","admin","744","1440054141"
"Mokronog","Mokronog","45.9426","15.1490","Slovenia","SI","SVN","Mokronog-Trebelno","admin","700","1705336506"
"Rankovce","Rankovce","42.1719","22.1167","Macedonia","MK","MKD","Rankovce","admin","744","1807575395"
"Petrovec","Petrovec","41.9389","21.6150","Macedonia","MK","MKD","Petrovec","admin","580","1807236473"
"Rečica","Recica","46.3247","14.9228","Slovenia","SI","SVN","Rečica ob Savinji","admin","516","1705082644"
"Yenagoa","Yenagoa","4.9267","6.2676","Nigeria","NG","NGA","Bayelsa","admin","","1566468984"
"Umm Şalāl ‘Alī","Umm Salal \`Ali","25.4697","51.3975","Qatar","QA","QAT","Umm Şalāl","admin","","1634646507"
"Lakatoro","Lakatoro","-16.1069","167.4208","Vanuatu","VU","VUT","Malampa","admin","705","1548761183"
"Kozje","Kozje","46.0740","15.5590","Slovenia","SI","SVN","Kozje","admin","634","1705548274"
"Komen","Komen","45.8159","13.7507","Slovenia","SI","SVN","Komen","admin","642","1705031068"
"Križevci","Krizevci","46.5631","16.1399","Slovenia","SI","SVN","Križevci","admin","499","1705138690"
"Hvalba","Hvalba","61.6000","-6.9556","Faroe Islands","FO","FRO","Hvalba","admin","713","1234381324"
"Qubadlı","Qubadli","39.3439","46.5797","Azerbaijan","AZ","AZE","Qubadlı","admin","672","1031526251"
"Aranguez","Aranguez","10.6472","-61.4461","Trinidad and Tobago","TT","TTO","San Juan/Laventille","admin","","1780000001"
"Maracha","Maracha","3.2883","30.9408","Uganda","UG","UGA","Maracha","admin","","1800799569"
"Markovci","Markovci","46.3956","15.9314","Slovenia","SI","SVN","Markovci","admin","495","1705493301"
"Šmarješke Toplice","Smarjeske Toplice","45.8622","15.2214","Slovenia","SI","SVN","Šmarješke Toplice","admin","492","1705361649"
"Požarevac","Pozarevac","44.6200","21.1897","Serbia","RS","SRB","Požarevac","admin","","1688653137"
"Krupanj","Krupanj","44.3667","19.3667","Serbia","RS","SRB","Krupanj","admin","","1688954602"
"Karbinci","Karbinci","41.8167","22.2375","Macedonia","MK","MKD","Karbinci","admin","673","1807568669"
"Vasilevo","Vasilevo","41.4758","22.6417","Macedonia","MK","MKD","Vasilevo","admin","630","1807855424"
"Bangar","Bangar","4.7086","115.0739","Brunei","BN","BRN","Temburong","admin","626","1096321221"
"Knjaževac","Knjazevac","43.5000","22.4333","Serbia","RS","SRB","Knjaževac","admin","","1688849183"
"Picos","Picos","15.0830","-23.6320","Cabo Verde","CV","CPV","São Salvador do Mundo","admin","","1132670996"
"Luče","Luce","46.3565","14.7436","Slovenia","SI","SVN","Luče","admin","402","1705042629"
"Grad","Grad","46.7977","16.0984","Slovenia","SI","SVN","Grad","admin","620","1705033390"
"Zaječar","Zajecar","43.9042","22.2847","Serbia","RS","SRB","Zaječar","admin","","1688564835"
"Podčetrtek","Podcetrtek","46.1573","15.5974","Slovenia","SI","SVN","Podčetrtek","admin","578","1705261000"
"Apače","Apace","46.6967","15.9111","Slovenia","SI","SVN","Apače","admin","543","1705285338"
"Paraćin","Paracin","43.8667","21.4167","Serbia","RS","SRB","Paraćin","admin","","1688523885"
"Lebane","Lebane","42.9167","21.7333","Serbia","RS","SRB","Lebane","admin","","1688169172"
"Lukovica","Lukovica","46.1686","14.6892","Slovenia","SI","SVN","Lukovica","admin","438","1705829201"
"Đà Lạt","Da Lat","11.9359","108.4429","Vietnam","VN","VNM","Lâm Đồng","admin","","1704933464"
"Binyin","Binyin","1.4175","34.5344","Uganda","UG","UGA","Kween","admin","","1800440664"
"Għasri","Ghasri","36.0583","14.2278","Malta","MT","MLT","Għasri","admin","525","1470127050"
"The Bottom","The Bottom","17.6261","-63.2492","Bonaire, Sint Eustatius, and Saba","BQ","BES","Saba","admin","462","1528203421"
"Rača","Raca","44.2333","20.9833","Serbia","RS","SRB","Rača","admin","","1688403125"
"Mila","Mila","36.4481","6.2622","Algeria","DZ","DZA","Mila","admin","","1012656063"
"Tigoa","Tigoa","-11.5583","160.0706","Solomon Islands","SB","SLB","Rennell and Bellona","admin","613","1090423471"
"Cirkulane","Cirkulane","46.3436","15.9941","Slovenia","SI","SVN","Cirkulane","admin","451","1705650900"
"Dobrovo","Dobrovo","45.9989","13.5296","Slovenia","SI","SVN","Brda","admin","334","1705226459"
"Zgornje Gorje","Zgornje Gorje","46.3801","14.0684","Slovenia","SI","SVN","Gorje","admin","532","1705342044"
"Saleaula","Saleaula","-13.4501","-172.3335","Samoa","WS","WSM","Gaga‘emauga","admin","600","1882605280"
"Bulambuli","Bulambuli","1.1600","34.3967","Uganda","UG","UGA","Bulambuli","admin","","1800071156"
"Šentrupert","Sentrupert","45.9769","15.0897","Slovenia","SI","SVN","Šentrupert","admin","310","1705083565"
"Aileu","Aileu","-8.7281","125.5664","Timor-Leste","TL","TLS","Aileu","admin","","1626766880"
"Makedonski Brod","Makedonski Brod","41.5133","21.2153","Macedonia","MK","MKD","Makedonski Brod","admin","567","1807831162"
"Aleksinac","Aleksinac","43.5383","21.7047","Serbia","RS","SRB","Aleksinac","admin","","1688039133"
"Kobilje","Kobilje","46.6837","16.3953","Slovenia","SI","SVN","Kobilje","admin","542","1705965317"
"Vwawa","Vwawa","-9.1081","32.9347","Tanzania","TZ","TZA","Songwe","admin","","1834695780"
"Planken","Planken","47.1833","9.5333","Liechtenstein","LI","LIE","Planken","admin","478","1438419168"
"Staro Nagoričane","Staro Nagoricane","42.2000","21.8300","Macedonia","MK","MKD","Staro Nagoričane","admin","555","1807782793"
"Zgornje Jezersko","Zgornje Jezersko","46.3951","14.4966","Slovenia","SI","SVN","Jezersko","admin","558","1705991236"
"Osečina","Osecina","44.3667","19.6000","Serbia","RS","SRB","Osečina","admin","","1688862457"
"Demir Hisar","Demir Hisar","41.2208","21.2031","Macedonia","MK","MKD","Demir Hisar","admin","543","1807908017"
"Weno","Weno","7.4500","151.8500","Federated States of Micronesia","FM","FSM","Chuuk","admin","","1583964118"
"Jincheng","Jincheng","24.4167","118.3167","Taiwan","TW","TWN","Kinmen","admin","","1158376441"
"Sveti Jurij","Sveti Jurij","46.5683","16.0203","Slovenia","SI","SVN","Sveti Jurij ob Ščavnici","admin","209","1705832384"
"Kuzma","Kuzma","46.8333","16.0833","Slovenia","SI","SVN","Kuzma","admin","429","1705411351"
"Despotovac","Despotovac","44.0833","21.4333","Serbia","RS","SRB","Despotovac","admin","","1688388893"
"Čajetina","Cajetina","43.7500","19.7167","Serbia","RS","SRB","Čajetina","admin","","1688018668"
"Namayingo","Namayingo","0.2398","33.8849","Uganda","UG","UGA","Namayingo","admin","","1800633600"
"Cankova","Cankova","46.7193","16.0179","Slovenia","SI","SVN","Cankova","admin","416","1705097170"
"Lozovo","Lozovo","41.7817","21.9025","Macedonia","MK","MKD","Lozovo","admin","521","1807681577"
"Santa Cruz","Santa Cruz","14.1167","121.2833","Philippines","PH","PHL","Laguna","admin","","1608131481"
"Centar Župa","Centar Zupa","41.4775","20.5578","Macedonia","MK","MKD","Centar Župa","admin","466","1807323094"
"Ljig","Ljig","44.2213","20.2375","Serbia","RS","SRB","Ljig","admin","","1688555007"
"Butebo","Butebo","1.1944","33.9222","Uganda","UG","UGA","Butebo","admin","","1800710436"
"Juršinci","Jursinci","46.4853","15.9710","Slovenia","SI","SVN","Juršinci","admin","381","1705262198"
"Koani","Koani","-6.1333","39.2833","Tanzania","TZ","TZA","Zanzibar Central/South","admin","","1834376215"
"Imdina","Imdina","35.8858","14.4031","Malta","MT","MLT","Imdina","admin","292","1470959100"
"Nova Vas","Nova Vas","45.7726","14.5064","Slovenia","SI","SVN","Bloke","admin","293","1705701553"
"Destrnik","Destrnik","46.4923","15.8801","Slovenia","SI","SVN","Destrnik","admin","186","1705579309"
"Varvarin","Varvarin","43.7167","21.3667","Serbia","RS","SRB","Varvarin","admin","","1688871979"
"Skopun","Skopun","61.9125","-6.8719","Faroe Islands","FO","FRO","Skopun","admin","446","1234929032"
"Gornji Petrovci","Gornji Petrovci","46.8050","16.2172","Slovenia","SI","SVN","Gornji Petrovci","admin","411","1705170234"
"Ribnica","Ribnica","46.5372","15.2733","Slovenia","SI","SVN","Ribnica na Pohorju","admin","404","1705830999"
"Kon Tum","Kon Tum","14.3544","108.0075","Vietnam","VN","VNM","Kon Tum","admin","","1704988146"
"Šavnik","Savnik","42.9500","19.1000","Montenegro","ME","MNE","Šavnik","admin","472","1499140506"
"Podlehnik","Podlehnik","46.3361","15.8786","Slovenia","SI","SVN","Podlehnik","admin","378","1705352359"
"Busesa","Busesa","0.6263","33.6003","Uganda","UG","UGA","Bugweri","admin","","1800175659"
"Dharamshāla","Dharamshala","32.2186","76.3183","India","IN","IND","Himāchal Pradesh","admin","","1356002507"
"Dhuusamarreeb","Dhuusamarreeb","5.5350","46.3861","Somalia","SO","SOM","Galguduud","admin","447","1706027824"
"Buabidi","Buabidi","8.4667","-81.7000","Panama","PA","PAN","Ngäbe-Buglé","admin","420","1591495919"
"Brvenica","Brvenica","41.9672","20.9808","Macedonia","MK","MKD","Brvenica","admin","449","1807009322"
"Belčišta","Belcista","41.3028","20.8303","Macedonia","MK","MKD","Debarca","admin","437","1807953626"
"Makole","Makole","46.3174","15.6669","Slovenia","SI","SVN","Makole","admin","221","1705304446"
"Crna Trava","Crna Trava","42.8101","22.2990","Serbia","RS","SRB","Crna Trava","admin","434","1688208441"
"Trnovska Vas","Trnovska Vas","46.5231","15.8978","Slovenia","SI","SVN","Trnovska Vas","admin","369","1705654373"
"Mali Zvornik","Mali Zvornik","44.3992","19.1214","Serbia","RS","SRB","Mali Zvornik","admin","","1688513998"
"Jurovski Dol","Jurovski Dol","46.6087","15.7796","Slovenia","SI","SVN","Sveti Jurij v Slovenskih Goricah","admin","361","1705288408"
"Hvannasund","Hvannasund","62.2833","-6.5167","Faroe Islands","FO","FRO","Hvannasund","admin","414","1234436400"
"Tišina","Tisina","46.6558","16.0933","Slovenia","SI","SVN","Tišina","admin","408","1705811179"
"Šalovci","Salovci","46.8250","16.2981","Slovenia","SI","SVN","Šalovci","admin","392","1705296581"
"Vitomarci","Vitomarci","46.5248","15.9346","Slovenia","SI","SVN","Sveti Andraž v Slovenskih Goricah","admin","341","1705653481"
"Dogbo","Dogbo","6.8167","1.7833","Benin","BJ","BEN","Couffo","admin","","1204497206"
"Luuka Town","Luuka Town","0.7642","33.3319","Uganda","UG","UGA","Luuka","admin","","1800000051"
"Dolneni","Dolneni","41.4264","21.4536","Macedonia","MK","MKD","Dolneni","admin","375","1807169470"
"Abakaliki","Abakaliki","6.3249","8.1137","Nigeria","NG","NGA","Ebonyi","admin","","1566879878"
"Braslovče","Braslovce","46.2884","15.0386","Slovenia","SI","SVN","Braslovče","admin","378","1705126731"
"Blace","Blace","43.2906","21.2847","Serbia","RS","SRB","Blace","admin","","1688233004"
"Sveta Ana","Sveta Ana","46.6497","15.8453","Slovenia","SI","SVN","Sveta Ana","admin","159","1705062986"
"Doljevac","Doljevac","43.1968","21.8334","Serbia","RS","SRB","Doljevac","admin","","1688354392"
"Cerkvenjak","Cerkvenjak","46.5660","15.9429","Slovenia","SI","SVN","Cerkvenjak","admin","163","1705808302"
"Pombas","Pombas","17.1490","-25.0150","Cabo Verde","CV","CPV","Paul","admin","","1132720315"
"Az̧ Z̧a‘āyin","Az Za\`ayin","25.5669","51.4847","Qatar","QA","QAT","Az̧ Z̧a‘āyin","admin","","1634890537"
"Batočina","Batocina","44.1500","21.0833","Serbia","RS","SRB","Batočina","admin","","1688299986"
"Hrib-Loški Potok","Hrib-Loski Potok","45.7015","14.5901","Slovenia","SI","SVN","Loški Potok","admin","353","1705118688"
"Podvelka","Podvelka","46.5864","15.3286","Slovenia","SI","SVN","Podvelka","admin","299","1705523444"
"Nsiika","Nsiika","-0.2958","30.3433","Uganda","UG","UGA","Buhweju","admin","","1800519756"
"Negotin","Negotin","44.2167","22.5167","Serbia","RS","SRB","Negotin","admin","","1688309283"
"Rubanda","Rubanda","-1.1864","29.8433","Uganda","UG","UGA","Rubanda","admin","","1800066560"
"Ntara","Ntara","0.0047","30.3661","Uganda","UG","UGA","Kitagwenda","admin","","1800001909"
"Škocjan","Skocjan","45.9069","15.2914","Slovenia","SI","SVN","Škocjan","admin","233","1705940381"
"Bistrica ob Sotli","Bistrica ob Sotli","46.0589","15.6642","Slovenia","SI","SVN","Bistrica ob Sotli","admin","235","1705641346"
"Toftir","Toftir","62.0978","-6.7369","Faroe Islands","FO","FRO","Nes","admin","","1234200740"
"Kalungu","Kalungu","-0.1681","31.7600","Uganda","UG","UGA","Kalungu","admin","","1800770335"
"Nelspruit","Nelspruit","-25.4745","30.9703","South Africa","ZA","ZAF","Mpumalanga","admin","","1710114438"
"Bupoto","Bupoto","0.9061","34.3578","Uganda","UG","UGA","Namisindwa","admin","","1800358976"
"Čučer-Sandevo","Cucer-Sandevo","42.0975","21.3877","Macedonia","MK","MKD","Čučer-Sandevo","admin","274","1807969277"
"Veliko Gradište","Veliko Gradiste","44.7500","21.5167","Serbia","RS","SRB","Veliko Gradište","admin","","1688050059"
"Porkeri","Porkeri","61.4814","-6.7458","Faroe Islands","FO","FRO","Porkeri","admin","297","1234757268"
"Santa Luċija","Santa Lucija","36.0431","14.2172","Malta","MT","MLT","Santa Luċija","admin","","1470880833"
"Dimitrovgrad","Dimitrovgrad","43.0167","22.7833","Serbia","RS","SRB","Dimitrovgrad","admin","","1688139023"
"Tomaž pri Ormožu","Tomaz pri Ormozu","46.4833","16.0794","Slovenia","SI","SVN","Sveti Tomaž","admin","254","1705837424"
"Al Jabīn","Al Jabin","14.7040","43.5990","Yemen","YE","YEM","Raymah","admin","","1887910100"
"Velika Plana","Velika Plana","44.3333","21.0833","Serbia","RS","SRB","Velika Plana","admin","","1688536735"
"Petrovac na Mlavi","Petrovac na Mlavi","44.3783","21.4194","Serbia","RS","SRB","Petrovac na Mlavi","admin","","1688674789"
"Svilajnac","Svilajnac","44.2167","21.2000","Serbia","RS","SRB","Svilajnac","admin","","1688007802"
"Boljevac","Boljevac","43.8247","21.9519","Serbia","RS","SRB","Boljevac","admin","","1688076013"
"Zelenikovo","Zelenikovo","41.8867","21.5869","Macedonia","MK","MKD","Zelenikovo","admin","272","1807765797"
"Hodoš","Hodos","46.8285","16.3239","Slovenia","SI","SVN","Hodoš","admin","280","1705217643"
"Kole","Kole","2.4286","32.8011","Uganda","UG","UGA","Kole","admin","","1800826218"
"Narok","Narok","-1.0833","35.8667","Kenya","KE","KEN","Narok","admin","","1404459814"
"Ouled Djellal","Ouled Djellal","34.4167","5.0667","Algeria","DZ","DZA","Ouled Djellal","admin","","1012109892"
"Kladovo","Kladovo","44.6039","22.6072","Serbia","RS","SRB","Kladovo","admin","","1688823592"
"Mparo","Mparo","-1.1844","30.0544","Uganda","UG","UGA","Rukiga","admin","","1800207938"
"Ol Kalou","Ol Kalou","-0.2730","36.3780","Kenya","KE","KEN","Nyandarua","admin","","1404930645"
"Kasanda","Kasanda","0.5467","31.8197","Uganda","UG","UGA","Kasanda","admin","","1800794590"
"Kasaali","Kasaali","-0.6167","31.5500","Uganda","UG","UGA","Kyotera","admin","","1800217765"
"Šentilj","Sentilj","46.6817","15.6481","Slovenia","SI","SVN","Šentilj","admin","","1705840723"
"Bojnik","Bojnik","43.0142","21.7180","Serbia","RS","SRB","Bojnik","admin","","1688559807"
"Kakumiro","Kakumiro","0.7811","31.3231","Uganda","UG","UGA","Kakumiro","admin","","1800894285"
"Želino","Zelino","41.9794","21.0619","Macedonia","MK","MKD","Želino","admin","105","1807040075"
"Bogovinje","Bogovinje","41.9233","20.9133","Macedonia","MK","MKD","Bogovinje","admin","25","1807279534"
"Kinoni","Kinoni","-0.6583","30.4581","Uganda","UG","UGA","Rwampara","admin","","1800009674"
"Sārī","Sari","36.5633","53.0601","Iran","IR","IRN","Māzandarān","admin","","1364501878"
"Koronadal","Koronadal","6.2541","124.9922","Philippines","PH","PHL","South Cotabato","admin","","1608108139"
"Kibingo","Kibingo","-0.5700","30.4150","Uganda","UG","UGA","Sheema","admin","","1800484853"
"Pailin","Pailin","12.8489","102.6093","Cambodia","KH","KHM","Pailin","admin","","1116050327"
"Studeničani","Studenicani","41.9158","21.5306","Macedonia","MK","MKD","Studeničani","admin","59","1807047160"
"Kara","Kara","9.5511","1.1861","Togo","TG","TGO","Kara","admin","","1768768827"
"Solčava","Solcava","46.4201","14.6918","Slovenia","SI","SVN","Solčava","admin","208","1705581561"
"Topola","Topola","44.2525","20.6761","Serbia","RS","SRB","Topola","admin","","1688000008"
"Suai","Suai","-9.3129","125.2565","Timor-Leste","TL","TLS","Kovalima","admin","","1626458227"
"Pante Macassar","Pante Macassar","-9.2000","124.3833","Timor-Leste","TL","TLS","Oé-Cusse Ambeno","admin","","1626175417"
"Razkrižje","Razkrizje","46.5182","16.2882","Slovenia","SI","SVN","Razkrižje","admin","141","1705276725"
"Waitangi","Waitangi","-43.9514","-176.5611","New Zealand","NZ","NZL","Chatham Islands","admin","177","1554847194"
"Kalaki","Kalaki","1.8160","33.3370","Uganda","UG","UGA","Kalaki","admin","","1800972565"
"Chiesanuova","Chiesanuova","43.9061","12.4214","San Marino","SM","SMR","Chiesanuova","admin","","1674546686"
"Saltangará","Saltangara","62.1156","-6.7206","Faroe Islands","FO","FRO","Runavík","admin","","1234473077"
"Mulifanua","Mulifanua","-13.8333","-171.9833","Samoa","WS","WSM","Aiga-i-le-Tai","admin","","1882029963"
"Lospalos","Lospalos","-8.5167","127.0333","Timor-Leste","TL","TLS","Lautém","admin","","1626878399"
"Fort Wellington","Fort Wellington","6.4000","-57.6167","Guyana","GY","GUY","Mahaica-Berbice","admin","118","1328483798"
"Ilinden","Ilinden","41.9945","21.5800","Macedonia","MK","MKD","Ilinden","admin","","1807767601"
"Rabak","Rabak","13.1850","32.7440","Sudan","SD","SDN","White Nile","admin","","1729452233"
"Tsirang","Tsirang","27.0219","90.1229","Bhutan","BT","BTN","Tsirang","admin","","1064617460"
"Tutong","Tutong","4.8067","114.6592","Brunei","BN","BRN","Tutong","admin","","1096181037"
"Merošina","Merosina","43.2833","21.7167","Serbia","RS","SRB","Merošina","admin","","1688733340"
"Viti","Viti","42.3214","21.3583","Kosovo","XK","XKS","Viti","admin","","1901328795"
"Sefwi Wiawso","Sefwi Wiawso","6.2158","-2.4850","Ghana","GH","GHA","Western North","admin","","1288994228"
"Dobje","Dobje","46.1369","15.3945","Slovenia","SI","SVN","Dobje","admin","114","1705204927"
"Hongseong","Hongseong","36.6009","126.6650","South Korea","KR","KOR","Chungnam","admin","","1410822139"
"Mersch","Mersch","49.7500","6.1000","Luxembourg","LU","LUX","Mersch","admin","","1442283324"
"Muan","Muan","34.9897","126.4714","South Korea","KR","KOR","Jeonnam","admin","","1410001061"
"Bangolo","Bangolo","7.0123","-7.4864","Côte d'Ivoire","CI","CIV","Montagnes","admin","","1384048438"
"Saratamata","Saratamata","-15.2875","167.9903","Vanuatu","VU","VUT","Penama","admin","","1548907051"
"Strendur","Strendur","62.1096","-6.7617","Faroe Islands","FO","FRO","Sjóvar","admin","","1234814533"
"Kurumul","Kurumul","-5.8550","144.6311","Papua New Guinea","PG","PNG","Jiwaka","admin","","1598000045"
"Palenga","Palenga","2.5764","32.3544","Uganda","UG","UGA","Omoro","admin","","1800679810"
"Lamwo","Lamwo","3.5300","32.8000","Uganda","UG","UGA","Lamwo","admin","","1800495740"
"Osilnica","Osilnica","45.5289","14.6983","Slovenia","SI","SVN","Osilnica","admin","76","1705839708"
"Žitorađa","Zitorada","43.1833","21.7167","Serbia","RS","SRB","Žitorađa","admin","","1688262057"
"Koceljeva","Koceljeva","44.4708","19.8070","Serbia","RS","SRB","Koceljeva","admin","","1688585119"
"Agago","Agago","2.9847","33.3306","Uganda","UG","UGA","Agago","admin","","1800321499"
"Morant Bay","Morant Bay","17.8819","-76.4074","Jamaica","JM","JAM","Saint Thomas","admin","","1388124588"
"Star Dojran","Star Dojran","41.1865","22.7203","Macedonia","MK","MKD","Dojran","admin","105","1807304153"
"Žužemberk","Zuzemberk","45.8339","14.9292","Slovenia","SI","SVN","Žužemberk","admin","","1705027920"
"Madīnat ash Shamāl","Madinat ash Shamal","26.1167","51.2167","Qatar","QA","QAT","Ash Shamāl","admin","","1634762661"
"Gadžin Han","Gadzin Han","43.2203","22.0258","Serbia","RS","SRB","Gadžin Han","admin","","1688569895"
"Ćićevac","Cicevac","43.7167","21.4500","Serbia","RS","SRB","Ćićevac","admin","","1688834584"
"Zavrč","Zavrc","46.3917","16.0497","Slovenia","SI","SVN","Zavrč","admin","","1705477555"
"Bhararisain","Bhararisain","30.0986","79.2658","India","IN","IND","Uttarākhand","admin","","1356002563"
"Īlām","Ilam","33.6374","46.4227","Iran","IR","IRN","Īlām","admin","","1364655025"
"Lipkovo","Lipkovo","42.1553","21.5875","Macedonia","MK","MKD","Lipkovo","admin","0","1807754347"
"Bosilegrad","Bosilegrad","42.5005","22.4728","Serbia","RS","SRB","Bosilegrad","admin","","1688870763"
"Vladimirci","Vladimirci","44.6167","19.7833","Serbia","RS","SRB","Vladimirci","admin","","1688070989"
"Haa","Haa","27.3825","89.2850","Bhutan","BT","BTN","Haa","admin","","1064717667"
"Ražanj","Razanj","43.6667","21.5500","Serbia","RS","SRB","Ražanj","admin","","1688323517"
"Tvøroyri","Tvoroyri","61.5544","-6.8063","Faroe Islands","FO","FRO","Tvøroyri","admin","","1234871801"
"Kučevo","Kucevo","44.4833","21.6667","Serbia","RS","SRB","Kučevo","admin","","1688495771"
"Boorama","Boorama","9.9361","43.1828","Somalia","SO","SOM","Awdal","admin","","1706767209"
"San Jose","San Jose","10.1800","125.5683","Philippines","PH","PHL","Dinagat Islands","admin","","1608435522"
"Sharan","Sharan","33.1757","68.7304","Afghanistan","AF","AFG","Paktīkā","admin","","1004273142"
"Dambai","Dambai","8.0689","0.1792","Ghana","GH","GHA","Oti","admin","","1288521614"
"Medveđa","Medveda","42.8333","21.5833","Serbia","RS","SRB","Medveđa","admin","","1688014462"
"Goaso","Goaso","6.8000","-2.5167","Ghana","GH","GHA","Ahafo","admin","","1288616024"
"Şuḩār","Suhar","24.3420","56.7299","Oman","OM","OMN","Shamāl al Bāţinah","admin","","1512602960"
"Şərur","Sarur","39.5544","44.9826","Azerbaijan","AZ","AZE","Şərur","admin","","1031386825"
"Redange-sur-Attert","Redange-sur-Attert","49.7652","5.8894","Luxembourg","LU","LUX","Redange","admin","","1442099691"
"Gaoua","Gaoua","10.2992","-3.2508","Burkina Faso","BF","BFA","Sud-Ouest","admin","","1854285570"
"Cocieri","Cocieri","47.3000","29.1167","Moldova","MD","MDA","Dubăsari","admin","","1498580757"
"Fuglafjørður","Fuglafjordhur","62.2448","-6.8150","Faroe Islands","FO","FRO","Fuglafjarður","admin","","1234308038"
"Fámjin","Famjin","61.5264","-6.8769","Faroe Islands","FO","FRO","Fámjin","admin","83","1234221310"
"Rekovac","Rekovac","43.8667","21.1333","Serbia","RS","SRB","Rekovac","admin","","1688955843"
"Knić","Knic","43.9167","20.7167","Serbia","RS","SRB","Knić","admin","","1688545619"
"Samamea","Samamea","-13.9333","-171.5333","Samoa","WS","WSM","Va‘a-o-Fonoti","admin","45","1882468874"
"Nhlangano","Nhlangano","-27.1167","31.2000","Swaziland","SZ","SWZ","Shiselweni","admin","","1748301792"
"Nīlī","Nili","33.7218","66.1302","Afghanistan","AF","AFG","Dāykundī","admin","","1004642532"
"Tabuk","Tabuk","17.4084","121.2785","Philippines","PH","PHL","Kalinga","admin","","1608980358"
"Sarpang","Sarpang","26.8639","90.2674","Bhutan","BT","BTN","Sarpang","admin","","1064362339"
"Nabilatuk","Nabilatuk","2.0525","34.5734","Uganda","UG","UGA","Nabilatuk","admin","","1800577877"
"Nova Sintra","Nova Sintra","14.8710","-24.6960","Cabo Verde","CV","CPV","Brava","admin","","1132634480"
"Sédhiou","Sedhiou","12.7000","-15.5500","Senegal","SN","SEN","Sédhiou","admin","","1686107428"
"Pagėgiai","Pagegiai","55.1333","21.9167","Lithuania","LT","LTU","Pagégiai","admin","57","1440724133"
"Qəbələ","Qabala","40.9825","47.8491","Azerbaijan","AZ","AZE","Qəbələ","admin","","1031787201"
"Pemagatshel","Pemagatshel","27.0333","91.4500","Bhutan","BT","BTN","Pemagatshel","admin","","1064215849"
"Igreja","Igreja","15.0339","-24.3250","Cabo Verde","CV","CPV","Mosteiros","admin","","1132174768"
"Vágur","Vagur","61.4733","-6.8175","Faroe Islands","FO","FRO","Vágur","admin","","1234642477"
"Safotulafai","Safotulafai","-13.6817","-172.1850","Samoa","WS","WSM","Fa‘asaleleaga","admin","","1882524892"
"Qacha’s Nek","Qacha's Nek","-30.1167","28.6833","Lesotho","LS","LSO","Qacha’s Nek","admin","","1426361264"
"Žabari","Zabari","44.3562","21.2143","Serbia","RS","SRB","Žabari","admin","","1688661944"
"Malo Crniće","Malo Crnice","44.5667","21.2833","Serbia","RS","SRB","Malo Crniće","admin","","1688544172"
"Tsimasham","Tsimasham","27.0989","89.5360","Bhutan","BT","BTN","Chhukha","admin","","1064167489"
"Pala","Pala","9.3646","14.9073","Chad","TD","TCD","Mayo-Kebbi-Ouest","admin","","1148687410"
"Dalandzadgad","Dalandzadgad","43.5708","104.4250","Mongolia","MN","MNG","Ömnögovĭ","admin","","1496141284"
"Pader","Pader","3.0500","33.2167","Uganda","UG","UGA","Pader","admin","","1800105199"
"Otuke","Otuke","2.4442","33.5028","Uganda","UG","UGA","Otuke","admin","","1800657796"
"Dəvəçi","Davaci","41.2012","48.9871","Azerbaijan","AZ","AZE","Şabran","admin","","1031053363"
"El Meghaïer","El Meghaier","33.9506","5.9242","Algeria","DZ","DZA","El Meghaier","admin","","1012153214"
"Norðragøta","Nordhragota","62.1990","-6.7432","Faroe Islands","FO","FRO","Eystur","admin","","1234729250"
"Leava","Leava","-14.2933","-178.1583","Wallis and Futuna","WF","WLF","Sigave","admin","","1876731744"
"Buka","Buka","-5.4219","154.6728","Papua New Guinea","PG","PNG","Bougainville","admin","","1598000066"
"Anouvông","Anouvong","18.8989","103.0919","Laos","LA","LAO","Xaisômboun","admin","","1418294155"
"Nakapiripirit","Nakapiripirit","1.9167","34.7833","Uganda","UG","UGA","Nakapiripirit","admin","","1800663685"
"Satupa‘itea","Satupa\`itea","-13.7659","-172.3269","Samoa","WS","WSM","Satupa‘itea","admin","","1882940042"
"Makedonska Kamenica","Makedonska Kamenica","42.0208","22.5876","Macedonia","MK","MKD","Makedonska Kamenica","admin","","1807768534"
"Golubac","Golubac","44.6530","21.6320","Serbia","RS","SRB","Golubac","admin","","1688460377"
"Majdanpek","Majdanpek","44.4167","21.9333","Serbia","RS","SRB","Majdanpek","admin","","1688109331"
"Şūr","Sur","22.5667","59.5289","Oman","OM","OMN","Janūb ash Sharqīyah","admin","","1512350600"
"Ribeira Brava","Ribeira Brava","16.6150","-24.2990","Cabo Verde","CV","CPV","Ribeira Brava","admin","","1132498214"
"Žetale","Zetale","46.2750","15.7939","Slovenia","SI","SVN","Žetale","admin","","1705941695"
"Rustavi","Rustavi","42.2897","43.8543","Georgia","GE","GEO","Kvemo Kartli","admin","","1268297743"
"Sørvágur","Sorvagur","62.0717","-7.3066","Faroe Islands","FO","FRO","Sørvágur","admin","","1234081530"
"Kostel","Kostel","45.5088","14.9100","Slovenia","SI","SVN","Kostel","admin","10","1705541759"
"Resen","Resen","41.0893","21.0109","Macedonia","MK","MKD","Resen","admin","","1807384915"
"Cova Figueira","Cova Figueira","14.8900","-24.2940","Cabo Verde","CV","CPV","Santa Catarina do Fogo","admin","","1132510430"
"Vransko","Vransko","46.1720","14.8217","Slovenia","SI","SVN","Vransko","admin","11","1705616966"
"Eiði","Eidhi","62.2995","-7.0924","Faroe Islands","FO","FRO","Eiði","admin","","1234150922"
"Oyrarbakki","Oyrarbakki","62.2079","-6.9997","Faroe Islands","FO","FRO","Sunda","admin","","1234924029"
"Pili","Pili","13.7177","123.7448","Philippines","PH","PHL","Camarines Sur","admin","","1608825988"
"Vestmanna","Vestmanna","62.1548","-7.1690","Faroe Islands","FO","FRO","Vestmanna","admin","","1234694837"
"Gaigirgordub","Gaigirgordub","9.5586","-78.9484","Panama","PA","PAN","Guna Yala","admin","10","1591425685"
"Kyankwanzi","Kyankwanzi","1.2000","31.8000","Uganda","UG","UGA","Kyankwanzi","admin","","1800427588"
"Kvívík","Kvivik","62.1186","-7.0737","Faroe Islands","FO","FRO","Kvívík","admin","","1234955585"
"Phôn-Hông","Phon-Hong","18.4953","102.4153","Laos","LA","LAO","Viangchan","admin","","1418592644"
"Sumba","Sumba","61.4055","-6.7090","Faroe Islands","FO","FRO","Sumba","admin","","1234926212"
"Sandavágur","Sandavagur","62.0537","-7.1498","Faroe Islands","FO","FRO","Vágar","admin","","1234210554"
"Trgovište","Trgoviste","42.3514","22.0921","Serbia","RS","SRB","Trgovište","admin","","1688150657"
"Sola","Sola","-13.8750","167.5500","Vanuatu","VU","VUT","Torba","admin","","1548600754"
"Hov","Hov","61.5068","-6.7599","Faroe Islands","FO","FRO","Hov","admin","","1234279154"
"Tanjung Selor","Tanjung Selor","2.8500","117.3833","Indonesia","ID","IDN","Kalimantan Utara","admin","","1360029270"
"Viðareiði","Vidhareidhi","62.3600","-6.5313","Faroe Islands","FO","FRO","Viðareiði","admin","","1234512915"
"Žagubica","Zagubica","44.1979","21.7902","Serbia","RS","SRB","Žagubica","admin","","1688849472"
"Sandur","Sandur","61.8344","-6.8171","Faroe Islands","FO","FRO","Sandur","admin","","1234438421"
"El Fula","El Fula","11.7175","28.3400","Sudan","SD","SDN","West Kordofan","admin","","1729946051"
"Lupane","Lupane","-18.9315","27.8070","Zimbabwe","ZW","ZWE","Matabeleland North","admin","","1716206606"
"Loango","Loango","-4.6307","11.8357","Congo (Brazzaville)","CG","COG","Kouilou","admin","","1178836764"
"Laascaanood","Laascaanood","8.4774","47.3597","Somalia","SO","SOM","Sool","admin","","1706106404"
"Ntoroko","Ntoroko","1.0500","30.5350","Uganda","UG","UGA","Ntoroko","admin","","1800207074"
"Húsavík","Husavik","61.8099","-6.6813","Faroe Islands","FO","FRO","Húsavík","admin","","1234224518"
"Georgetown","Georgetown","-7.9286","-14.4119","Saint Helena, Ascension, and Tristan da Cunha","SH","SHN","Ascension","admin","","1654363572"
"Lhuentse","Lhuentse","27.6500","91.1500","Bhutan","BT","BTN","Lhuentse","admin","","1064227529"
"Ceerigaabo","Ceerigaabo","10.6162","47.3679","Somalia","SO","SOM","Sanaag","admin","","1706173875"
"Kunoy","Kunoy","62.2917","-6.6702","Faroe Islands","FO","FRO","Kunoy","admin","","1234932703"
"Skálavík","Skalavik","61.8314","-6.6623","Faroe Islands","FO","FRO","Skálavík","admin","","1234815917"
"Wé","We","-20.9167","167.2653","New Caledonia","NC","NCL","Province Îles","admin","","1540672599"
"Kirkja","Kirkja","62.3263","-6.3238","Faroe Islands","FO","FRO","Fugloy","admin","","1234508245"
"Sieyik","Sieyik","9.3833","-82.6522","Panama","PA","PAN","Naso Tjër Di","admin","","1591986999"
"Edinburgh of the Seven Seas","Edinburgh of the Seven Seas","-37.0675","-12.3105","Saint Helena, Ascension, and Tristan da Cunha","SH","SHN","Tristan da Cunha","admin","","1654661876"
"Skúvoy","Skuvoy","61.7710","-6.8050","Faroe Islands","FO","FRO","Skúvoy","admin","","1234962897"
"Gasa","Gasa","27.9167","89.6833","Bhutan","BT","BTN","Gasa","admin","","1064761583"
"Haymā’","Hayma'","19.9569","56.2758","Oman","OM","OMN","Al Wusţá","admin","","1512927824"
"Idrī","Idri","27.4471","13.0517","Libya","LY","LBY","Wādī ash Shāţi’","admin","","1434443323"
"Unión Chocó","Union Choco","8.0778","-77.5583","Panama","PA","PAN","Emberá-Wounaan","admin","","1591671914"
"Bardaï","Bardai","21.3533","17.0003","Chad","TD","TCD","Tibesti","admin","","1148071351"
"Preševo","Presevo","42.3067","21.6500","Serbia","RS","SRB","Preševo","admin","","1688970219"
"Bujanovac","Bujanovac","42.4667","21.7667","Serbia","RS","SRB","Bujanovac","admin","","1688743477"
"Kitamilo","Kitamilo","0.2367","33.2700","Uganda","UG","UGA","Buvuma","admin","","1800610172"
"Sundsvall","Sundsvall","62.4000","17.3167","Sweden","SE","SWE","Västernorrland","minor","99825","1752024232"
"Xiongzhou","Xiongzhou","38.9786","116.0730","China","CN","CHN","Hebei","","99693","1156059092"
"Udine","Udine","46.0667","13.2333","Italy","IT","ITA","Friuli Venezia Giulia","minor","99518","1380396446"
"Kalisz","Kalisz","51.7575","18.0800","Poland","PL","POL","Wielkopolskie","minor","99761","1616861321"
"Izumisano","Izumisano","34.4000","135.3333","Japan","JP","JPN","Ōsaka","","99328","1392003370"
"Legnica","Legnica","51.2083","16.1603","Poland","PL","POL","Dolnośląskie","minor","99350","1616578356"
"Wakefield","Wakefield","53.6800","-1.4900","United Kingdom","GB","GBR","Wakefield","","99251","1826013112"
"Pouytenga","Pouytenga","12.2500","-0.4333","Burkina Faso","BF","BFA","Centre-Est","","99950","1854151910"
"Xiegang","Xiegang","22.9614","114.1412","China","CN","CHN","Guangdong","","99387","1156584365"
"Kani","Kani","35.4261","137.0613","Japan","JP","JPN","Gifu","","99545","1392198191"
"Kadaiyanallūr","Kadaiyanallur","9.0743","77.3419","India","IN","IND","Tamil Nādu","","99807","1356371792"
"Debre Zeyit","Debre Zeyit","8.7500","38.9833","Ethiopia","ET","ETH","Oromīya","","99928","1231675489"
"Luckeesarai","Luckeesarai","25.1678","86.0944","India","IN","IND","Bihār","","99931","1356958915"
"Dzolokpuita","Dzolokpuita","6.7862","0.4437","Ghana","GH","GHA","Volta","","100000","1288118620"
"Chikusei","Chikusei","36.3071","139.9831","Japan","JP","JPN","Ibaraki","","99758","1392759497"
"Tellicherry","Tellicherry","11.7489","75.5070","India","IN","IND","Kerala","","99386","1356231014"
"Andria","Andria","41.2167","16.3000","Italy","IT","ITA","Puglia","minor","99857","1380463498"
"Ad Dujayl","Ad Dujayl","33.8389","44.2481","Iraq","IQ","IRQ","Şalāḩ ad Dīn","","100000","1368574619"
"Tinsukia","Tinsukia","27.5000","95.3670","India","IN","IND","Assam","","99507","1356391413"
"Gwelej","Gwelej","14.7500","36.7167","Eritrea","ER","ERI","Gash-Barka","","100000","1232869245"
"Babīlē","Babile","9.2167","42.3333","Ethiopia","ET","ETH","Oromīya","","100000","1231924903"
"Ra’s Ghārib","Ra's Gharib","28.3597","33.0775","Egypt","EG","EGY","Al Baḩr al Aḩmar","","100000","1818526915"
"Alaminos","Alaminos","16.1553","119.9808","Philippines","PH","PHL","Pangasinan","","99397","1608828653"
"Pili","Pili","13.5561","123.2756","Philippines","PH","PHL","Camarines Sur","","99196","1608921556"
"Fengning","Fengning","41.2013","116.6433","China","CN","CHN","Hebei","minor","99673","1156391807"
"Urgut Shahri","Urgut Shahri","39.4190","67.2612","Uzbekistan","UZ","UZB","Samarqand","minor","99675","1860562788"
"Dhār","Dhar","22.5992","75.3028","India","IN","IND","Madhya Pradesh","","99493","1356266651"
"Sayaxché","Sayaxche","16.5167","-90.1833","Guatemala","GT","GTM","Petén","minor","99787","1320897653"
"Federal Way","Federal Way","47.3091","-122.3358","United States","US","USA","Washington","","99792","1840019826"
"Hanau","Hanau","50.1328","8.9169","Germany","DE","DEU","Hesse","minor","98502","1276550409"
"Manteca","Manteca","37.7927","-121.2264","United States","US","USA","California","","99764","1840020265"
"Oton","Oton","10.6931","122.4736","Philippines","PH","PHL","Iloilo","","98509","1608471718"
"Hasilpur","Hasilpur","29.6967","72.5542","Pakistan","PK","PAK","Punjab","","99171","1586677874"
"Silvassa","Silvassa","20.2700","73.0200","India","IN","IND","Dādra and Nagar Haveli and Damān and Diu","","98266","1356217846"
"Tsuyama","Tsuyama","35.0692","134.0044","Japan","JP","JPN","Okayama","","99410","1392820005"
"Bislig","Bislig","8.2133","126.3161","Philippines","PH","PHL","Surigao del Sur","","99290","1608693408"
"Lafayette","Lafayette","39.9946","-105.0998","United States","US","USA","Colorado","","99475","1840020180"
"Itārsi","Itarsi","22.6200","77.7500","India","IN","IND","Madhya Pradesh","","99330","1356602700"
"Arezzo","Arezzo","43.4633","11.8783","Italy","IT","ITA","Tuscany","minor","99419","1380418355"
"Ballarat","Ballarat","-37.5608","143.8475","Australia","AU","AUS","Victoria","","98543","1036567186"
"Kārwār","Karwar","14.8136","74.1297","India","IN","IND","Karnātaka","","99050","1356118021"
"Sakata","Sakata","38.9145","139.8364","Japan","JP","JPN","Yamagata","","99235","1392418961"
"Abhar","Abhar","36.1511","49.2244","Iran","IR","IRN","Zanjān","minor","99285","1364688687"
"Tobolsk","Tobolsk","58.1953","68.2581","Russia","RU","RUS","Tyumenskaya Oblast’","","98886","1643201261"
"Khamīs Mushayţ","Khamis Mushayt","18.3000","42.7333","Saudi Arabia","SA","SAU","‘Asīr","","99213","1682497044"
"Cottbus","Cottbus","51.7606","14.3342","Germany","DE","DEU","Brandenburg","minor","98693","1276436655"
"Hesperia","Hesperia","34.3975","-117.3147","United States","US","USA","California","","99287","1840020404"
"La Crosse","La Crosse","43.8241","-91.2268","United States","US","USA","Wisconsin","","99227","1840002577"
"Al Jammālīyah","Al Jammaliyah","31.1792","31.8648","Egypt","EG","EGY","Ad Daqahlīyah","","97682","1818993341"
"Ifakara","Ifakara","-8.1000","36.6833","Tanzania","TZ","TZA","Morogoro","","99000","1834456222"
"Chía","Chia","4.8500","-74.0500","Colombia","CO","COL","Cundinamarca","minor","97896","1170046785"
"Breves","Breves","-1.6819","-50.4800","Brazil","BR","BRA","Pará","","99080","1076061751"
"Weldiya","Weldiya","11.8306","39.6000","Ethiopia","ET","ETH","Āmara","","98911","1231023092"
"Riberalta","Riberalta","-11.0128","-66.0572","Bolivia","BO","BOL","El Beni","","99070","1068951603"
"Sarapul","Sarapul","56.4667","53.8000","Russia","RU","RUS","Udmurtiya","","97910","1643320672"
"Mīāneh","Mianeh","37.4239","47.7197","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","98973","1364529047"
"Senahú","Senahu","15.4164","-89.8203","Guatemala","GT","GTM","Alta Verapaz","minor","98703","1320727854"
"Itoshima","Itoshima","33.5539","130.1978","Japan","JP","JPN","Fukuoka","","98527","1392003499"
"Riverview","Riverview","27.8227","-82.3023","United States","US","USA","Florida","","98928","1840014158"
"Es Senia","Es Senia","35.6478","-0.6239","Algeria","DZ","DZA","Oran","","96928","1012779789"
"Kontagora","Kontagora","10.4030","5.4730","Nigeria","NG","NGA","Niger","minor","98754","1566599231"
"Ixmiquilpan","Ixmiquilpan","20.4861","-99.2589","Mexico","MX","MEX","Hidalgo","minor","98654","1484683374"
"Kingsport","Kingsport","36.5224","-82.5454","United States","US","USA","Tennessee","","98767","1840014425"
"Edinburg","Edinburg","26.3196","-98.1597","United States","US","USA","Texas","","98759","1840021020"
"Khambhāt","Khambhat","22.3000","72.6200","India","IN","IND","Gujarāt","","98700","1356354348"
"Kallūru","Kalluru","15.8162","78.0289","India","IN","IND","Andhra Pradesh","","97483","1356411831"
"Zárate","Zarate","-34.0833","-59.0333","Argentina","AR","ARG","Buenos Aires","minor","98522","1032392383"
"Reyhanlı","Reyhanli","36.2692","36.5672","Turkey","TR","TUR","Hatay","minor","98534","1792253206"
"Bowling Green","Bowling Green","36.9716","-86.4378","United States","US","USA","Kentucky","","98537","1840013265"
"Saku","Saku","36.2488","138.4769","Japan","JP","JPN","Nagano","","98292","1392305904"
"M’lang","M'lang","6.9500","124.8833","Philippines","PH","PHL","Cotabato","","98195","1608858911"
"Koytendag","Koytendag","37.5000","66.0000","Turkmenistan","TM","TKM","Lebap","","98477","1795859391"
"Itaituba","Itaituba","-4.2758","-55.9839","Brazil","BR","BRA","Pará","","98485","1076849673"
"Dongducheon","Dongducheon","37.9167","127.0667","South Korea","KR","KOR","Gyeonggi","","97424","1410519938"
"Worcester","Worcester","-33.6450","19.4436","South Africa","ZA","ZAF","Western Cape","","97098","1710498245"
"Votkinsk","Votkinsk","57.0500","54.0000","Russia","RU","RUS","Udmurtiya","","97550","1643007991"
"Paulínia","Paulinia","-22.7611","-47.1542","Brazil","BR","BRA","São Paulo","","97702","1076327352"
"Iseyin","Iseyin","7.9667","3.6000","Nigeria","NG","NGA","Oyo","minor","98071","1566714777"
"Adjarra","Adjarra","6.5333","2.6667","Benin","BJ","BEN","Ouémé","","97424","1204832299"
"Mariano Roque Alonso","Mariano Roque Alonso","-25.2122","-57.5331","Paraguay","PY","PRY","Central","","97585","1600527067"
"Fanyang","Fanyang","31.0847","118.1942","China","CN","CHN","Anhui","","97300","1156287543"
"Rāmnagar","Ramnagar","29.4000","79.1200","India","IN","IND","Uttarākhand","","97916","1356653209"
"Oodweyne","Oodweyne","9.4000","45.0667","Somalia","SO","SOM","Togdheer","","98131","1706384679"
"Carmel","Carmel","39.9650","-86.1460","United States","US","USA","Indiana","","98137","1840007260"
"Chitose","Chitose","42.8210","141.6509","Japan","JP","JPN","Hokkaidō","","97945","1392003121"
"Kawkareik","Kawkareik","16.5556","98.2400","Myanmar","MM","MMR","Kayin State","","98000","1104220072"
"Longview","Longview","32.5193","-94.7622","United States","US","USA","Texas","","98075","1840020746"
"Attock Khurd","Attock Khurd","33.7667","72.3667","Pakistan","PK","PAK","Punjab","","97374","1586388059"
"Tracy","Tracy","37.7269","-121.4523","United States","US","USA","California","","98022","1840021518"
"Oldham","Oldham","53.5444","-2.1169","United Kingdom","GB","GBR","Oldham","","96555","1826716070"
"Trelew","Trelew","-43.2500","-65.3000","Argentina","AR","ARG","Chubut","","97915","1032440034"
"Lugo","Lugo","43.0167","-7.5500","Spain","ES","ESP","Galicia","minor","97613","1724547526"
"Prescott Valley","Prescott Valley","34.5980","-112.3185","United States","US","USA","Arizona","","97901","1840021772"
"Kambar","Kambar","27.5868","68.0010","Pakistan","PK","PAK","Sindh","","97691","1586424773"
"Hammamet","Hammamet","36.4000","10.6167","Tunisia","TN","TUN","Nabeul","","97579","1788426798"
"Witten","Witten","51.4333","7.3333","Germany","DE","DEU","North Rhine-Westphalia","","96563","1276004960"
"Munakata","Munakata","33.8000","130.5333","Japan","JP","JPN","Fukuoka","","97041","1392003244"
"Paragominas","Paragominas","-2.9958","-47.3528","Brazil","BR","BRA","Pará","","97819","1076557071"
"Serov","Serov","59.6000","60.5667","Russia","RU","RUS","Sverdlovskaya Oblast’","","97762","1643278883"
"Caotun","Caotun","23.9830","120.7326","Taiwan","TW","TWN","Nantou","","96838","1158910060"
"Aşağıçinik","Asagicinik","41.2719","36.3508","Turkey","TR","TUR","Samsun","","97564","1792977425"
"San Cugat del Vallés","San Cugat del Valles","41.4735","2.0852","Spain","ES","ESP","Catalonia","","95725","1724196400"
"Quilenda","Quilenda","-10.6333","14.3333","Angola","AO","AGO","Cuanza-Sul","","97648","1024011632"
"Hadera","Hadera","32.45","34.9167","Israel","IL","ISR","Haifa","","95700","1376761209"
"Tubarão","Tubarao","-28.4667","-49.0069","Brazil","BR","BRA","Santa Catarina","","97235","1076663243"
"Bafra","Bafra","41.5722","35.9147","Turkey","TR","TUR","Samsun","minor","97452","1792560571"
"Brantford","Brantford","43.1667","-80.2500","Canada","CA","CAN","Ontario","","97496","1124737006"
"Beaverton","Beaverton","45.4779","-122.8168","United States","US","USA","Oregon","","97466","1840018556"
"Portsmouth","Portsmouth","43.0580","-70.7826","United States","US","USA","New Hampshire","","97455","1840002932"
"Portsmouth","Portsmouth","36.8468","-76.3540","United States","US","USA","Virginia","","97454","1840003872"
"Ocozocoautla de Espinosa","Ocozocoautla de Espinosa","16.8000","-93.5000","Mexico","MX","MEX","Chiapas","minor","97397","1484720187"
"Valença","Valenca","-13.3700","-39.0728","Brazil","BR","BRA","Bahia","","97305","1076455406"
"Las Rozas de Madrid","Las Rozas de Madrid","40.4917","-3.8733","Spain","ES","ESP","Madrid","","95725","1724629395"
"Yacuiba","Yacuiba","-22.0153","-63.6772","Bolivia","BO","BOL","Tarija","","97296","1068671079"
"Bodināyakkanūr","Bodinayakkanur","10.0000","77.0667","India","IN","IND","Tamil Nādu","","96901","1356254878"
"Lorca","Lorca","37.6798","-1.6944","Spain","ES","ESP","Murcia","","97151","1724565149"
"Ituiutaba","Ituiutaba","-18.9667","-49.4667","Brazil","BR","BRA","Minas Gerais","","97171","1076873111"
"Villupuram","Villupuram","11.9401","79.4861","India","IN","IND","Tamil Nādu","","96253","1356999657"
"Tucuruí","Tucurui","-3.7678","-49.6728","Brazil","BR","BRA","Pará","","97128","1076000076"
"Lysychansk","Lysychansk","48.9169","38.4306","Ukraine","UA","UKR","Luhanska Oblast","","96161","1804673535"
"Jamundí","Jamundi","3.2667","-76.5500","Colombia","CO","COL","Valle del Cauca","minor","96993","1170503987"
"Fishers","Fishers","39.9588","-85.9659","United States","US","USA","Indiana","","97154","1840010496"
"Cesena","Cesena","44.1389","12.2444","Italy","IT","ITA","Emilia-Romagna","","96760","1380256992"
"Itacoatiara","Itacoatiara","-3.1428","-58.4439","Brazil","BR","BRA","Amazonas","","97122","1076759384"
"Vīrappanchathiram","Virappanchathiram","11.3553","77.6678","India","IN","IND","Tamil Nādu","","95967","1356169564"
"Ukhta","Ukhta","63.5667","53.7000","Russia","RU","RUS","Komi","","97087","1643005165"
"Tomohon","Tomohon","1.3244","124.8225","Indonesia","ID","IDN","Sulawesi Utara","minor","96411","1360014714"
"Buin","Buin","-33.7333","-70.7333","Chile","CL","CHL","Región Metropolitana","","96614","1152114001"
"Barra do Piraí","Barra do Pirai","-22.4700","-43.8258","Brazil","BR","BRA","Rio de Janeiro","","96865","1076997923"
"Colón","Colon","13.7167","-89.3667","El Salvador","SV","SLV","La Libertad","","96989","1222369620"
"Paraíso","Paraiso","18.3961","-93.2128","Mexico","MX","MEX","Tabasco","minor","96741","1484393519"
"Tandwa","Tandwa","26.5500","82.6500","India","IN","IND","Uttar Pradesh","","95516","1356683773"
"Zerakpur","Zerakpur","30.6500","76.8200","India","IN","IND","Punjab","","95553","1356277173"
"Quvasoy","Quvasoy","40.3000","71.9667","Uzbekistan","UZ","UZB","Farg‘ona","minor","96900","1860491009"
"Paletwa","Paletwa","21.3000","92.8500","Myanmar","MM","MMR","Chin State","","96899","1104846794"
"Tela","Tela","15.7833","-87.4667","Honduras","HN","HND","Atlántida","","96758","1340789148"
"Greece","Greece","43.2460","-77.6988","United States","US","USA","New York","","96839","1840058136"
"Harihar","Harihar","14.5129","75.8072","India","IN","IND","Karnātaka","","96422","1356008032"
"Balayan","Balayan","13.9333","120.7333","Philippines","PH","PHL","Batangas","","95913","1608903029"
"Iida","Iida","35.5150","137.8214","Japan","JP","JPN","Nagano","","96642","1392226730"
"Caieiras","Caieiras","-23.3644","-46.7408","Brazil","BR","BRA","São Paulo","","95780","1076000967"
"Do Gonbadān","Do Gonbadan","30.3586","50.7981","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","minor","96728","1364102646"
"Cambé","Cambe","-23.2758","-51.2778","Brazil","BR","BRA","Paraná","","96733","1076233235"
"Kāzerūn","Kazerun","29.6194","51.6542","Iran","IR","IRN","Fārs","minor","96683","1364311345"
"Tiddim","Tiddim","23.3758","93.6539","Myanmar","MM","MMR","Chin State","","96624","1104876098"
"Escalante","Escalante","10.8333","123.5000","Philippines","PH","PHL","Negros Occidental","","96159","1608433743"
"Japeri","Japeri","-22.6431","-43.6533","Brazil","BR","BRA","Rio de Janeiro","","95492","1076331274"
"Iguatu","Iguatu","-6.3589","-39.2989","Brazil","BR","BRA","Ceará","","96495","1076734026"
"Wujiaqu","Wujiaqu","44.1670","87.5430","China","CN","CHN","Xinjiang","","96436","1156881466"
"Chākdaha","Chakdaha","23.0800","88.5200","India","IN","IND","West Bengal","","95203","1356089316"
"Arifwala","Arifwala","30.2981","73.0561","Pakistan","PK","PAK","Punjab","","95655","1586262431"
"Nova Lima","Nova Lima","-19.9858","-43.8469","Brazil","BR","BRA","Minas Gerais","","96157","1076876220"
"Vanderbijlpark","Vanderbijlpark","-26.6992","27.8356","South Africa","ZA","ZAF","Gauteng","","95840","1710733212"
"Amalner","Amalner","21.0500","75.0500","India","IN","IND","Mahārāshtra","","95994","1356850132"
"Ōmura","Omura","32.9000","129.9583","Japan","JP","JPN","Nagasaki","","95619","1392185480"
"Longquan","Longquan","40.3703","113.7483","China","CN","CHN","Shanxi","minor","95849","1156389116"
"Kimilili","Kimilili","0.7833","34.7167","Kenya","KE","KEN","Bungoma","","94927","1404489379"
"Leninsk-Kuznetskiy","Leninsk-Kuznetskiy","54.6575","86.1617","Russia","RU","RUS","Kemerovskaya Oblast’","","96139","1643591516"
"Kelo","Kelo","9.3100","15.8100","Chad","TD","TCD","Tandjilé","","96224","1148149571"
"Mezhdurechensk","Mezhdurechensk","53.6864","88.0703","Russia","RU","RUS","Kemerovskaya Oblast’","","96299","1643201199"
"Dovzhansk","Dovzhansk","48.0778","39.6472","Ukraine","UA","UKR","Luhanska Oblast","minor","95153","1804768565"
"Sandy","Sandy","40.5709","-111.8506","United States","US","USA","Utah","","96272","1840037422"
"Tiruchengodu","Tiruchengodu","11.3790","77.8949","India","IN","IND","Tamil Nādu","","95335","1356749372"
"Bendigo","Bendigo","-36.7500","144.2667","Australia","AU","AUS","Victoria","","95587","1036537913"
"San Tan Valley","San Tan Valley","33.1786","-111.5629","United States","US","USA","Arizona","","96127","1840027990"
"Kamisu","Kamisu","35.8899","140.6645","Japan","JP","JPN","Ibaraki","","95471","1392634524"
"Longkeng","Longkeng","24.0341","112.0391","China","CN","CHN","Guangdong","","95881","1156881067"
"Muzaffarabad","Muzaffarabad","34.3583","73.4722","Pakistan","PK","PAK","Azad Kashmir","","96000","1586389158"
"Sivakāsi","Sivakasi","9.4500","77.8000","India","IN","IND","Tamil Nādu","","95261","1356111487"
"Paramagudi","Paramagudi","9.5494","78.5891","India","IN","IND","Tamil Nādu","","95579","1356185369"
"Mons","Mons","50.4500","3.9500","Belgium","BE","BEL","Wallonia","minor","95299","1056836704"
"Itaperuna","Itaperuna","-21.2050","-41.8878","Brazil","BR","BRA","Rio de Janeiro","","95841","1076348886"
"Jinbi","Jinbi","25.7356","101.3239","China","CN","CHN","Yunnan","minor","95639","1156482594"
"Xiluodu","Xiluodu","28.2360","103.6301","China","CN","CHN","Yunnan","minor","95439","1156058164"
"Emmiganūr","Emmiganur","15.7333","77.4833","India","IN","IND","Andhra Pradesh","","95149","1356089591"
"Nagīna","Nagina","29.4430","78.4330","India","IN","IND","Uttar Pradesh","","95246","1356185479"
"Saint-Michel de l’Atalaye","Saint-Michel de l'Atalaye","19.3667","-72.3333","Haiti","HT","HTI","Artibonite","","95216","1332572826"
"Pesaro","Pesaro","43.9167","12.9000","Italy","IT","ITA","Marche","minor","94958","1380435770"
"Boli","Boli","45.7564","130.5759","China","CN","CHN","Heilongjiang","minor","95260","1156933394"
"Vāniyambādi","Vaniyambadi","12.6825","78.6167","India","IN","IND","Tamil Nādu","","95061","1356737709"
"Shirē","Shire","14.1000","38.2833","Ethiopia","ET","ETH","Tigray","","95491","1231526396"
"Tiruttani","Tiruttani","13.1800","79.6300","India","IN","IND","Tamil Nādu","","95014","1356102827"
"Aliağa","Aliaga","38.8008","26.9728","Turkey","TR","TUR","İzmir","minor","95392","1792034311"
"Closepet","Closepet","12.7230","77.2860","India","IN","IND","Karnātaka","","95167","1356069377"
"Yi Xian","Yi Xian","39.3444","115.4954","China","CN","CHN","Hebei","minor","94110","1156048808"
"Esslingen","Esslingen","48.7333","9.3167","Germany","DE","DEU","Baden-Württemberg","minor","93542","1276510955"
"Hương Thủy","Huong Thuy","16.4000","107.6889","Vietnam","VN","VNM","Thừa Thiên-Huế","","95299","1704272342"
"Cáceres","Caceres","39.4833","-6.3667","Spain","ES","ESP","Extremadura","minor","95456","1724532670"
"Shimada","Shimada","34.8363","138.1927","Japan","JP","JPN","Shizuoka","","95198","1392003128"
"Allinagaram","Allinagaram","10.0119","77.4786","India","IN","IND","Tamil Nādu","","94453","1356113956"
"Lecce","Lecce","40.3500","18.1667","Italy","IT","ITA","Puglia","minor","95441","1380057273"
"Hanford","Hanford","36.3274","-119.6549","United States","US","USA","California","","95459","1840020382"
"Guaíba","Guaiba","-30.1139","-51.3250","Brazil","BR","BRA","Rio Grande do Sul","","95204","1076594929"
"Bogo","Bogo","10.7361","14.6108","Cameroon","CM","CMR","Extrême-Nord","","95230","1120371347"
"Erbaa","Erbaa","40.6667","36.5667","Turkey","TR","TUR","Tokat","minor","95361","1792516897"
"Balamban","Balamban","10.4667","123.7833","Philippines","PH","PHL","Cebu","","95136","1608443221"
"Sarov","Sarov","54.9333","43.3167","Russia","RU","RUS","Nizhegorodskaya Oblast’","","95388","1643199056"
"Boca Raton","Boca Raton","26.3752","-80.1080","United States","US","USA","Florida","","95347","1840014207"
"Rubio","Rubio","7.7000","-72.3500","Venezuela","VE","VEN","Táchira","minor","95041","1862078206"
"Daltonganj","Daltonganj","24.0333","84.0667","India","IN","IND","Jhārkhand","","94277","1356991205"
"Mianwali","Mianwali","32.5853","71.5436","Pakistan","PK","PAK","Punjab","minor","95007","1586006907"
"Yalamakūru","Yalamakuru","15.7680","77.4820","India","IN","IND","Andhra Pradesh","","95149","1356154626"
"Rawānduz","Rawanduz","36.6119","44.5247","Iraq","IQ","IRQ","Arbīl","minor","95089","1368066834"
"Middletown","Middletown","39.5033","-84.3659","United States","US","USA","Ohio","","95296","1840003804"
"Livonia","Livonia","42.3972","-83.3733","United States","US","USA","Michigan","","95294","1840003985"
"San Martín Jilotepeque","San Martin Jilotepeque","14.7830","-90.7942","Guatemala","GT","GTM","Chimaltenango","minor","94901","1320611842"
"Birecik","Birecik","37.0250","37.9769","Turkey","TR","TUR","Şanlıurfa","minor","95149","1792775983"
"Jinshan","Jinshan","25.1496","102.0742","China","CN","CHN","Yunnan","minor","95044","1156913383"
"Gerona","Gerona","15.6069","120.5986","Philippines","PH","PHL","Tarlac","","94485","1608730066"
"Nāḩiyat al Karmah","Nahiyat al Karmah","33.3833","43.9000","Iraq","IQ","IRQ","Al Anbār","","95000","1368548065"
"Solikamsk","Solikamsk","59.6433","56.7500","Russia","RU","RUS","Permskiy Kray","","94628","1643137756"
"Siguatepeque","Siguatepeque","14.6000","-87.8333","Honduras","HN","HND","Comayagua","","95121","1340354793"
"Abreu e Lima","Abreu e Lima","-7.9117","-34.9028","Brazil","BR","BRA","Pernambuco","","94429","1076355835"
"Barletta","Barletta","41.3167","16.2833","Italy","IT","ITA","Puglia","minor","94477","1380940717"
"Carson","Carson","33.8374","-118.2559","United States","US","USA","California","","95104","1840019214"
"Valongo","Valongo","41.1833","-8.5000","Portugal","PT","PRT","Porto","minor","93858","1620725806"
"Al Jīzah","Al Jizah","31.7000","35.9500","Jordan","JO","JOR","Al ‘Āşimah","minor","95045","1400797654"
"Toms River","Toms River","39.9895","-74.1654","United States","US","USA","New Jersey","","95002","1840081605"
"Dod Ballāpur","Dod Ballapur","13.2920","77.5430","India","IN","IND","Karnātaka","","94519","1356716579"
"Lagarto","Lagarto","-10.9169","-37.6500","Brazil","BR","BRA","Sergipe","","94861","1076051433"
"Lawang","Lawang","-7.8300","112.7000","Indonesia","ID","IDN","Jawa Timur","","93563","1360944516"
"Lopez","Lopez","13.8840","122.2604","Philippines","PH","PHL","Quezon","","94657","1608026330"
"Bouna","Bouna","9.2667","-3.0000","Côte d'Ivoire","CI","CIV","Zanzan","minor","94883","1384414296"
"Jaworzno","Jaworzno","50.2044","19.2700","Poland","PL","POL","Śląskie","minor","94305","1616134743"
"Behshahr","Behshahr","36.6922","53.5525","Iran","IR","IRN","Māzandarān","minor","94702","1364001181"
"Kanuma","Kanuma","36.5671","139.7451","Japan","JP","JPN","Tochigi","","94591","1392653831"
"Gera","Gera","50.8806","12.0833","Germany","DE","DEU","Thuringia","minor","94152","1276548165"
"Lawrence","Lawrence","38.9597","-95.2642","United States","US","USA","Kansas","","94745","1840001632"
"Grudziądz","Grudziadz","53.4875","18.7550","Poland","PL","POL","Kujawsko-Pomorskie","minor","94368","1616391533"
"Alvand","Alvand","36.1892","50.0642","Iran","IR","IRN","Qazvīn","minor","93836","1364589535"
"Gatchina","Gatchina","59.5684","30.1229","Russia","RU","RUS","Leningradskaya Oblast’","","94447","1643318154"
"Michurinsk","Michurinsk","52.8833","40.4833","Russia","RU","RUS","Tambovskaya Oblast’","","93330","1643419559"
"Daanbantayan","Daanbantayan","11.2500","124.0000","Philippines","PH","PHL","Cebu","","93502","1608517986"
"Slidell","Slidell","30.2887","-89.7828","United States","US","USA","Louisiana","","94498","1840015911"
"Bayan Hot","Bayan Hot","38.8556","105.7001","China","CN","CHN","Inner Mongolia","minor","94445","1156186483"
"Greenburgh","Greenburgh","41.0330","-73.8412","United States","US","USA","New York","","94459","1840087485"
"Shibata","Shibata","37.9479","139.3273","Japan","JP","JPN","Niigata","","94258","1392003079"
"Alessandria","Alessandria","44.9167","8.6167","Italy","IT","ITA","Piedmont","minor","93980","1380334322"
"Santa Cruz Xoxocotlán","Santa Cruz Xoxocotlan","17.0296","-96.7352","Mexico","MX","MEX","Oaxaca","","93188","1484706021"
"Glazov","Glazov","58.1333","52.6667","Russia","RU","RUS","Udmurtiya","","93056","1643380964"
"Blacksburg","Blacksburg","37.2300","-80.4279","United States","US","USA","Virginia","","94398","1840006446"
"Marmaris","Marmaris","36.8500","28.2667","Turkey","TR","TUR","Muğla","minor","94247","1792982553"
"Helmond","Helmond","51.4833","5.6500","Netherlands","NL","NLD","Noord-Brabant","minor","92627","1528158256"
"San Marcos","San Marcos","33.1350","-117.1743","United States","US","USA","California","","94313","1840021991"
"Kwekwe","Kwekwe","-18.9167","29.8167","Zimbabwe","ZW","ZWE","Midlands","","93072","1716327793"
"Tatakan","Tatakan","-6.1116","106.1036","Indonesia","ID","IDN","Banten","","92361","1360853285"
"Cape Breton","Cape Breton","46.1389","-60.1931","Canada","CA","CAN","Nova Scotia","","94285","1124000383"
"Azumino","Azumino","36.3039","137.9058","Japan","JP","JPN","Nagano","","93927","1392101705"
"Manacapuru","Manacapuru","-3.3000","-60.6206","Brazil","BR","BRA","Amazonas","","94175","1076484341"
"Wangjia","Wangjia","30.6218","120.7212","China","CN","CHN","Zhejiang","","93291","1156453694"
"Monkayo","Monkayo","7.8239","126.0536","Philippines","PH","PHL","Davao de Oro","minor","93937","1608856677"
"Lemery","Lemery","13.9167","120.8833","Philippines","PH","PHL","Batangas","","93186","1608128712"
"Kabacan","Kabacan","7.1167","124.8167","Philippines","PH","PHL","Cotabato","","93822","1608578355"
"Yunnanyi","Yunnanyi","25.3916","100.6846","China","CN","CHN","Yunnan","","93671","1156343176"
"Chiantla","Chiantla","15.3567","-91.4568","Guatemala","GT","GTM","Huehuetenango","minor","93780","1320904799"
"Itaúna","Itauna","-20.0750","-44.5758","Brazil","BR","BRA","Minas Gerais","","93847","1076321811"
"Menderes","Menderes","38.2540","27.1340","Turkey","TR","TUR","İzmir","minor","93796","1792033881"
"Sanjō","Sanjo","37.6368","138.9617","Japan","JP","JPN","Niigata","","93671","1392003505"
"Higashi-Matsuyama","Higashi-Matsuyama","36.0422","139.3999","Japan","JP","JPN","Saitama","","92440","1392365792"
"Simeulu","Simeulu","2.6300","96.0900","Indonesia","ID","IDN","Aceh","","93762","1360154104"
"Puerto Padre","Puerto Padre","21.1950","-76.6017","Cuba","CU","CUB","Las Tunas","minor","93705","1192209935"
"Voskresensk","Voskresensk","55.3167","38.7000","Russia","RU","RUS","Moskovskaya Oblast’","","93565","1643351541"
"Vilhena","Vilhena","-12.7406","-60.1458","Brazil","BR","BRA","Rondônia","","93745","1076196581"
"Indanan","Indanan","6.0000","120.9667","Philippines","PH","PHL","Sulu","","93168","1608021703"
"Mikhaylovsk","Mikhaylovsk","45.1333","42.0333","Russia","RU","RUS","Stavropol’skiy Kray","","93658","1643773075"
"Santa Barbara","Santa Barbara","16.0031","120.4008","Philippines","PH","PHL","Pangasinan","","92187","1608539009"
"Goodyear","Goodyear","33.2614","-112.3622","United States","US","USA","Arizona","","93651","1840020564"
"Jaén","Jaen","-5.7083","-78.8083","Peru","PE","PER","Cajamarca","","93631","1604707495"
"Piraquara","Piraquara","-25.4419","-49.0628","Brazil","BR","BRA","Paraná","","93207","1076240504"
"La Spezia","La Spezia","44.1000","9.8167","Italy","IT","ITA","Liguria","minor","93311","1380143500"
"Edmond","Edmond","35.6689","-97.4160","United States","US","USA","Oklahoma","","93522","1840020423"
"Bedford","Bedford","52.1350","-0.4700","United Kingdom","GB","GBR","Bedford","","92407","1826771105"
"Mayagüez","Mayaguez","18.2003","-67.1397","Puerto Rico","PR","PRI","Puerto Rico","","93426","1630035520"
"Linquan","Linquan","37.9513","110.9877","China","CN","CHN","Shanxi","minor","91670","1156332414"
"Iserlohn","Iserlohn","51.3833","7.6667","Germany","DE","DEU","North Rhine-Westphalia","","92666","1276837267"
"Fall River","Fall River","41.7136","-71.1015","United States","US","USA","Massachusetts","","93339","1840000497"
"Akşehir","Aksehir","38.3575","31.4164","Turkey","TR","TUR","Konya","minor","93233","1792359338"
"Contai","Contai","21.7800","87.7500","India","IN","IND","West Bengal","","92226","1356643000"
"Bafang","Bafang","5.1500","10.1833","Cameroon","CM","CMR","Ouest","","93145","1120889812"
"Suffolk","Suffolk","36.6953","-76.6398","United States","US","USA","Virginia","","93268","1840003873"
"Terre Haute","Terre Haute","39.4660","-87.3751","United States","US","USA","Indiana","","93247","1840009671"
"Hilversum","Hilversum","52.2333","5.1667","Netherlands","NL","NLD","Noord-Holland","minor","91235","1528160832"
"Samālūţ","Samalut","28.3097","30.7108","Egypt","EG","EGY","Al Minyā","","91475","1818180512"
"Avignon","Avignon","43.9500","4.8075","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","91729","1250645342"
"Oss","Oss","51.7667","5.5167","Netherlands","NL","NLD","Noord-Brabant","minor","92526","1528488100"
"Phú Thọ","Phu Tho","21.4003","105.2225","Vietnam","VN","VNM","Phú Thọ","minor","91650","1704657729"
"Erciş","Ercis","39.0311","43.3597","Turkey","TR","TUR","Van","minor","92945","1792489856"
"Wukari","Wukari","7.8704","9.7800","Nigeria","NG","NGA","Taraba","minor","92933","1566025302"
"Palimbang","Palimbang","6.2167","124.2000","Philippines","PH","PHL","Sultan Kudarat","","92828","1608177821"
"Akot","Akot","21.1000","77.0600","India","IN","IND","Mahārāshtra","","92637","1356644864"
"Rafaela","Rafaela","-31.2667","-61.4833","Argentina","AR","ARG","Santa Fe","minor","92945","1032181625"
"Velikiye Luki","Velikiye Luki","56.3333","30.5333","Russia","RU","RUS","Pskovskaya Oblast’","","91435","1643622811"
"Kilosa","Kilosa","-6.8300","36.9875","Tanzania","TZ","TZA","Morogoro","","91889","1834242035"
"Itumbiara","Itumbiara","-18.4167","-49.2167","Brazil","BR","BRA","Goiás","","92883","1076693747"
"Hanamaki Onsen","Hanamaki Onsen","39.3886","141.1169","Japan","JP","JPN","Iwate","","92815","1392087821"
"Düren","Duren","50.8000","6.4833","Germany","DE","DEU","North Rhine-Westphalia","minor","91814","1276181957"
"Missoula","Missoula","46.8751","-114.0214","United States","US","USA","Montana","","92863","1840019840"
"Boundiali","Boundiali","9.5167","-6.4833","Côte d'Ivoire","CI","CIV","Savanes","minor","92792","1384246050"
"Azua","Azua","18.4600","-70.7400","Dominican Republic","DO","DOM","Valdesia","minor","92585","1214004418"
"Laiyuan","Laiyuan","39.3515","114.6853","China","CN","CHN","Hebei","","92691","1156459787"
"Itapeva","Itapeva","-23.9819","-48.8758","Brazil","BR","BRA","São Paulo","","92710","1076767110"
"Foumban","Foumban","5.7167","10.9167","Cameroon","CM","CMR","Ouest","","92673","1120088650"
"Tatvan","Tatvan","38.5022","42.2814","Turkey","TR","TUR","Bitlis","minor","92695","1792530559"
"Rāyachoti","Rayachoti","14.0583","78.7517","India","IN","IND","Andhra Pradesh","","91234","1356147853"
"Naju","Naju","35.0333","126.7167","South Korea","KR","KOR","Jeonnam","minor","92582","1410651571"
"Flensburg","Flensburg","54.7819","9.4367","Germany","DE","DEU","Schleswig-Holstein","minor","91113","1276853315"
"Lethbridge","Lethbridge","49.6942","-112.8328","Canada","CA","CAN","Alberta","","92729","1124321200"
"Tübingen","Tubingen","48.5200","9.0556","Germany","DE","DEU","Baden-Württemberg","minor","91877","1276203240"
"Roswell","Roswell","34.0391","-84.3513","United States","US","USA","Georgia","","92688","1840014741"
"Sablayan","Sablayan","12.8428","120.7750","Philippines","PH","PHL","Occidental Mindoro","","92598","1608764789"
"Rongwo","Rongwo","35.5165","102.0166","China","CN","CHN","Qinghai","minor","92601","1156068157"
"San Sebastián de los Reyes","San Sebastian de los Reyes","40.5469","-3.6258","Spain","ES","ESP","Madrid","","91083","1724422840"
"Grahamstown","Grahamstown","-33.2996","26.5200","South Africa","ZA","ZAF","Eastern Cape","","91548","1710671857"
"Yumbo","Yumbo","3.5850","-76.4958","Colombia","CO","COL","Valle del Cauca","minor","92192","1170917722"
"Kitakami","Kitakami","39.2867","141.1132","Japan","JP","JPN","Iwate","","92348","1392905802"
"Bauan","Bauan","13.7917","121.0085","Philippines","PH","PHL","Batangas","","90819","1608547248"
"Gießen","Giessen","50.5833","8.6667","Germany","DE","DEU","Hesse","minor","91255","1276576770"
"Kot Kapūra","Kot Kapura","30.5833","74.9000","India","IN","IND","Punjab","","91979","1356007164"
"Saint-Jean-sur-Richelieu","Saint-Jean-sur-Richelieu","45.3167","-73.2667","Canada","CA","CAN","Quebec","","92394","1124278447"
"Vineland","Vineland","39.4653","-74.9981","United States","US","USA","New Jersey","","92364","1840001551"
"Lavras","Lavras","-21.2450","-45.0000","Brazil","BR","BRA","Minas Gerais","","92200","1076751259"
"Acajutla","Acajutla","13.5900","-89.8336","El Salvador","SV","SLV","Sonsonate","","92000","1222402975"
"Goalundo Ghāt","Goalundo Ghat","23.7333","89.7617","Bangladesh","BD","BGD","Dhaka","","91675","1050260023"
"Tonacatepeque","Tonacatepeque","13.7833","-89.1167","El Salvador","SV","SLV","San Salvador","","90896","1222948232"
"Plantation","Plantation","26.1259","-80.2617","United States","US","USA","Florida","","92212","1840015142"
"Kendu Bay","Kendu Bay","-0.3596","34.6400","Kenya","KE","KEN","Homa Bay","","91248","1404600696"
"Alafaya","Alafaya","28.5280","-81.1868","United States","US","USA","Florida","","92080","1840029102"
"Clarington","Clarington","43.9350","-78.6083","Canada","CA","CAN","Ontario","","92013","1124000882"
"Satsumasendai","Satsumasendai","31.8167","130.3000","Japan","JP","JPN","Kagoshima","","91875","1392003234"
"Ubatuba","Ubatuba","-23.4339","-45.0708","Brazil","BR","BRA","São Paulo","","91824","1076510903"
"São João da Boa Vista","Sao Joao da Boa Vista","-21.9689","-46.7978","Brazil","BR","BRA","São Paulo","","91771","1076459561"
"Çaycuma","Caycuma","41.4267","32.0758","Turkey","TR","TUR","Zonguldak","minor","91569","1792376720"
"Ambohimangakely","Ambohimangakely","-18.9167","47.6000","Madagascar","MG","MDG","Antananarivo","","91056","1450074776"
"Funza","Funza","4.7167","-74.2167","Colombia","CO","COL","Cundinamarca","minor","90671","1170562658"
"Modi‘in Makkabbim Re‘ut","Modi\`in Makkabbim Re\`ut","31.9077","35.0076","Israel","IL","ISR","Central","","90013","1376334230"
"Pickering","Pickering","43.8354","-79.0890","Canada","CA","CAN","Ontario","","91771","1124781814"
"Southport","Southport","53.6475","-3.0053","United Kingdom","GB","GBR","Sefton","","91703","1826271387"
"Ţurayf","Turayf","31.6775","38.6531","Saudi Arabia","SA","SAU","Al Ḩudūd ash Shamālīyah","","91713","1682455207"
"Sinendé","Sinende","10.3447","2.3792","Benin","BJ","BEN","Borgou","","91672","1204182311"
"Mogi Mirim","Mogi Mirim","-22.4319","-46.9578","Brazil","BR","BRA","São Paulo","","91483","1076719683"
"Kirkland","Kirkland","47.6970","-122.2057","United States","US","USA","Washington","","91656","1840019836"
"Weiyuan","Weiyuan","23.5025","100.7075","China","CN","CHN","Yunnan","minor","91536","1156210684"
"Julu","Julu","37.2200","115.0309","China","CN","CHN","Hebei","","90311","1156883631"
"Kāvali","Kavali","14.9130","79.9929","India","IN","IND","Andhra Pradesh","","90099","1356816305"
"Hamilton","Hamilton","40.2046","-74.6765","United States","US","USA","New Jersey","","91557","1840056346"
"Salaman","Salaman","6.6333","124.0667","Philippines","PH","PHL","Sultan Kudarat","","91344","1608705741"
"Talara","Talara","-4.5799","-81.2719","Peru","PE","PER","Piura","","91444","1604790735"
"Yao","Yao","12.8508","17.5608","Chad","TD","TCD","Batha","","91507","1148655909"
"Peñaflor","Penaflor","-33.6167","-70.9167","Chile","CL","CHL","Región Metropolitana","","90201","1152857586"
"Jauharabad","Jauharabad","32.2919","72.2736","Pakistan","PK","PAK","Punjab","","91254","1586975487"
"Pāloncha","Paloncha","17.6018","80.7051","India","IN","IND","Andhra Pradesh","","91196","1356171257"
"Votuporanga","Votuporanga","-20.4228","-49.9728","Brazil","BR","BRA","São Paulo","","91278","1076423091"
"Jalalpur Jattan","Jalalpur Jattan","32.7667","74.2167","Pakistan","PK","PAK","Punjab","","90130","1586822268"
"Caçapava","Cacapava","-23.1008","-45.7069","Brazil","BR","BRA","São Paulo","","91162","1076289420"
"Hoover","Hoover","33.3763","-86.8068","United States","US","USA","Alabama","","91371","1840014795"
"São Félix do Xingu","Sao Felix do Xingu","-6.6333","-51.9833","Brazil","BR","BRA","Pará","","91340","1076909404"
"Avaré","Avare","-23.0989","-48.9258","Brazil","BR","BRA","São Paulo","","91232","1076118860"
"Lachhmangarh Sīkar","Lachhmangarh Sikar","27.8225","75.0253","India","IN","IND","Rājasthān","","90992","1356216971"
"Cáceres","Caceres","-16.0711","-57.6789","Brazil","BR","BRA","Mato Grosso","","91271","1076422654"
"Lawton","Lawton","34.6175","-98.4202","United States","US","USA","Oklahoma","","91247","1840020477"
"Maladzyechna","Maladzyechna","54.3208","26.8572","Belarus","BY","BLR","Minskaya Voblasts’","minor","91063","1112822882"
"Phusro","Phusro","23.7700","85.9900","India","IN","IND","Jhārkhand","","89178","1356193741"
"Agua Prieta","Agua Prieta","31.3258","-109.5489","Mexico","MX","MEX","Sonora","minor","91029","1484381848"
"Auburn","Auburn","32.6087","-85.4903","United States","US","USA","Alabama","","91047","1840013810"
"Chauk","Chauk","20.8833","94.8167","Myanmar","MM","MMR","Magway","","90870","1104898616"
"Imizuchō","Imizucho","36.7306","137.0756","Japan","JP","JPN","Toyama","","90201","1392059150"
"Itajubá","Itajuba","-22.4258","-45.4528","Brazil","BR","BRA","Minas Gerais","","90658","1076423300"
"Pongotan","Pongotan","7.1269","125.8975","Philippines","PH","PHL","Davao de Oro","","90786","1608561205"
"Caimbambo","Caimbambo","-12.9000","14.0833","Angola","AO","AGO","Benguela","","90838","1024891825"
"Norwalk","Norwalk","41.1144","-73.4215","United States","US","USA","Connecticut","","90821","1840004839"
"O'Fallon","O'Fallon","38.7850","-90.7175","United States","US","USA","Missouri","","90802","1840008571"
"Gwadar","Gwadar","25.1264","62.3225","Pakistan","PK","PAK","Balochistan","minor","90762","1586857142"
"Cambambe","Cambambe","-9.7586","14.5122","Angola","AO","AGO","Cuanza-Norte","","90766","1024312565"
"Victorias","Victorias","10.9000","123.0833","Philippines","PH","PHL","Negros Occidental","","90101","1608236440"
"Pinamalayan","Pinamalayan","13.0364","121.4889","Philippines","PH","PHL","Oriental Mindoro","","90383","1608931779"
"Katiola","Katiola","8.1333","-5.1000","Côte d'Ivoire","CI","CIV","Vallée du Bandama","minor","90641","1384854888"
"Chililabombwe","Chililabombwe","-12.3667","27.8278","Zambia","ZM","ZMB","Copperbelt","","90530","1894950283"
"Biga","Biga","40.2281","27.2422","Turkey","TR","TUR","Çanakkale","minor","90576","1792033601"
"Eslāmābād-e Gharb","Eslamabad-e Gharb","34.1094","46.5275","Iran","IR","IRN","Kermānshāh","minor","90559","1364997109"
"Arsuz","Arsuz","36.4128","35.8867","Turkey","TR","TUR","Hatay","minor","90456","1792450466"
"Pisa","Pisa","43.7167","10.4000","Italy","IT","ITA","Tuscany","minor","90118","1380310225"
"Fundación","Fundacion","10.5214","-74.1867","Colombia","CO","COL","Magdalena","minor","90514","1170906059"
"Sahagún","Sahagun","8.9500","-75.4500","Colombia","CO","COL","Córdoba","minor","90487","1170230630"
"São João del Rei","Sao Joao del Rei","-21.1358","-44.2619","Brazil","BR","BRA","Minas Gerais","","90497","1076559413"
"São Sebastião","Sao Sebastiao","-23.8040","-45.4051","Brazil","BR","BRA","São Paulo","","90328","1076889310"
"Ma‘arrat an Nu‘mān","Ma\`arrat an Nu\`man","35.6386","36.6717","Syria","SY","SYR","Idlib","minor","90000","1760482977"
"Nanaimo","Nanaimo","49.1642","-123.9364","Canada","CA","CAN","British Columbia","","90504","1124623893"
"Mancherāl","Mancheral","18.8679","79.4639","India","IN","IND","Andhra Pradesh","","89935","1356990383"
"Chalchuapa","Chalchuapa","13.9833","-89.6833","El Salvador","SV","SLV","Santa Ana","","90000","1222559007"
"Kansk","Kansk","56.2000","95.7167","Russia","RU","RUS","Krasnoyarskiy Kray","","89508","1643874419"
"Kiselëvsk","Kiselevsk","54.0000","86.6500","Russia","RU","RUS","Kemerovskaya Oblast’","","89867","1643153158"
"Zwickau","Zwickau","50.7167","12.5000","Germany","DE","DEU","Saxony","minor","89540","1276683623"
"Pistoia","Pistoia","43.9333","10.9167","Italy","IT","ITA","Tuscany","minor","90195","1380664435"
"Chino","Chino","33.9836","-117.6654","United States","US","USA","California","","90279","1840019114"
"Caucasia","Caucasia","7.9833","-75.2000","Colombia","CO","COL","Antioquia","minor","90213","1170351945"
"Compostela","Compostela","7.6667","126.0833","Philippines","PH","PHL","Davao de Oro","","89884","1608079890"
"Ocaña","Ocana","8.2333","-73.3500","Colombia","CO","COL","Norte de Santander","minor","90037","1170754105"
"Mihara","Mihara","34.4000","133.0833","Japan","JP","JPN","Hiroshima","","89972","1392428941"
"Mijas","Mijas","36.6000","-4.6333","Spain","ES","ESP","Andalusia","","89502","1724011980"
"Sankeshwar","Sankeshwar","16.2700","74.4800","India","IN","IND","Karnātaka","","89627","1356062129"
"Kairāna","Kairana","29.3953","77.2053","India","IN","IND","Uttar Pradesh","","89000","1356768694"
"Richard-Toll","Richard-Toll","16.4667","-15.6833","Senegal","SN","SEN","Saint-Louis","","90000","1686778591"
"Uacu Cungo","Uacu Cungo","-11.3583","15.1194","Angola","AO","AGO","Cuanza-Sul","","90000","1024677127"
"San José Pinula","San Jose Pinula","14.5446","-90.4083","Guatemala","GT","GTM","Guatemala","minor","89639","1320568537"
"Luau","Luau","-10.7044","22.2283","Angola","AO","AGO","Moxico","","90000","1024699465"
"Kadiri","Kadiri","14.1200","78.1700","India","IN","IND","Andhra Pradesh","","89429","1356308559"
"Necochea","Necochea","-38.5500","-58.7333","Argentina","AR","ARG","Buenos Aires","minor","90000","1032624527"
"Barwaaqo","Barwaaqo","3.4833","42.1833","Somalia","SO","SOM","Gedo","","90000","1706735840"
"Möng Tun","Mong Tun","20.3000","98.9333","Myanmar","MM","MMR","Shan State","","90000","1104458096"
"Afmadow","Afmadow","0.5156","42.0733","Somalia","SO","SOM","Jubbada Hoose","","90000","1706654469"
"El Puerto de Santa María","El Puerto de Santa Maria","36.6000","-6.2167","Spain","ES","ESP","Andalusia","","89435","1724087455"
"Aalst","Aalst","50.9383","4.0392","Belgium","BE","BEL","Flanders","minor","88854","1056695813"
"Presidencia Roque Sáenz Peña","Presidencia Roque Saenz Pena","-26.7833","-60.4500","Argentina","AR","ARG","Chaco","minor","89882","1032464495"
"Dover","Dover","43.1887","-70.8845","United States","US","USA","New Hampshire","","89874","1840002808"
"Ciudad de Atlixco","Ciudad de Atlixco","18.9000","-98.4500","Mexico","MX","MEX","Puebla","minor","89314","1484268457"
"Polangui","Polangui","13.2922","123.4856","Philippines","PH","PHL","Albay","","89176","1608444152"
"Waukegan","Waukegan","42.3698","-87.8716","United States","US","USA","Illinois","","89751","1840010113"
"Olavarría","Olavarria","-36.9000","-60.3333","Argentina","AR","ARG","Buenos Aires","minor","89721","1032373353"
"Bogo","Bogo","11.0167","124.0000","Philippines","PH","PHL","Cebu","","88867","1608673076"
"Lucca","Lucca","43.8417","10.5028","Italy","IT","ITA","Tuscany","minor","89243","1380770432"
"Chosica","Chosica","-11.9361","-76.6972","Peru","PE","PER","Lima","","88606","1604040866"
"Buckeye","Buckeye","33.4314","-112.6429","United States","US","USA","Arizona","","89711","1840021939"
"Town 'n' Country","Town 'n' Country","28.0106","-82.5763","United States","US","USA","Florida","","89679","1840029089"
"Leping","Leping","37.6130","113.6995","China","CN","CHN","Shanxi","minor","88559","1156893285"
"Serdar","Serdar","38.9833","56.2833","Turkmenistan","TM","TKM","Balkan","","89582","1795429041"
"Cantaura","Cantaura","9.3005","-64.3564","Venezuela","VE","VEN","Anzoátegui","minor","89552","1862715472"
"Kamensk-Shakhtinskiy","Kamensk-Shakhtinskiy","48.3206","40.2611","Russia","RU","RUS","Rostovskaya Oblast’","minor","88997","1643408575"
"Banga","Banga","6.3000","124.7833","Philippines","PH","PHL","South Cotabato","","89164","1608545440"
"Conda","Conda","-11.1667","14.5000","Angola","AO","AGO","Cuanza-Sul","","89479","1024402215"
"Cheektowaga","Cheektowaga","42.9082","-78.7466","United States","US","USA","New York","","89518","1840057976"
"Surallah","Surallah","6.3667","124.7333","Philippines","PH","PHL","South Cotabato","","89340","1608974893"
"Gitarama","Gitarama","-2.0696","29.7600","Rwanda","RW","RWA","Southern Province","","87613","1646038255"
"Hinigaran","Hinigaran","10.2667","122.8500","Philippines","PH","PHL","Negros Occidental","","88909","1608738002"
"Calabanga","Calabanga","13.7089","123.2161","Philippines","PH","PHL","Camarines Sur","","88906","1608274723"
"Akyazı","Akyazi","40.6833","30.6253","Turkey","TR","TUR","Sakarya","minor","89301","1792667046"
"Bloomington","Bloomington","44.8306","-93.3151","United States","US","USA","Minnesota","","89436","1840006747"
"Prijedor","Prijedor","44.9667","16.7000","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","89397","1070565353"
"Caracase","Caracase","3.7533","42.5442","Somalia","SO","SOM","Gedo","","89345","1706846074"
"Toviklin","Toviklin","6.8333","1.8167","Benin","BJ","BEN","Couffo","","88611","1204701826"
"Léré","Lere","9.7700","14.1500","Chad","TD","TCD","Mayo-Kebbi-Ouest","","89237","1148428566"
"Ségbana","Segbana","10.9278","3.6944","Benin","BJ","BEN","Alibori","","89268","1204782008"
"Ban Laem Chabang","Ban Laem Chabang","13.0833","100.8833","Thailand","TH","THA","Chon Buri","","88271","1764108164"
"Passi","Passi","11.1000","122.6333","Philippines","PH","PHL","Iloilo","","88873","1608905309"
"Shwebo","Shwebo","22.5667","95.7000","Myanmar","MM","MMR","Sagaing","","88914","1104118052"
"Humpata","Humpata","-15.0725","13.3678","Angola","AO","AGO","Huíla","","89144","1024063224"
"Saundatti","Saundatti","15.7833","75.1167","India","IN","IND","Karnātaka","","88955","1356668148"
"Murcia","Murcia","10.6000","123.0333","Philippines","PH","PHL","Negros Occidental","","88868","1608306618"
"Nirmal","Nirmal","19.1000","78.3500","India","IN","IND","Andhra Pradesh","","88433","1356031223"
"Qiantangcun","Qiantangcun","23.6742","116.9150","China","CN","CHN","Guangdong","","88001","1156074442"
"Kūhdasht","Kuhdasht","33.5350","47.6061","Iran","IR","IRN","Lorestān","minor","89091","1364140008"
"Candeias","Candeias","-12.6678","-38.5508","Brazil","BR","BRA","Bahia","","88806","1076438209"
"San Francisco","San Francisco","30.9000","-112.6000","Mexico","MX","MEX","Sonora","","89122","1484657489"
"Santa Catarina Pinula","Santa Catarina Pinula","14.5644","-90.4880","Guatemala","GT","GTM","Guatemala","minor","87362","1320763146"
"Rāsipuram","Rasipuram","11.4700","78.1700","India","IN","IND","Tamil Nādu","","88584","1356036977"
"Calauan","Calauan","14.1500","121.3167","Philippines","PH","PHL","Laguna","","87693","1608352580"
"Aïn M’Lila","Ain M'Lila","36.0367","6.5719","Algeria","DZ","DZA","Oum el Bouaghi","","88441","1012438002"
"Chimbas","Chimbas","-31.5000","-68.5319","Argentina","AR","ARG","San Juan","minor","87258","1032442768"
"Mount Pleasant","Mount Pleasant","32.8537","-79.8203","United States","US","USA","South Carolina","","88900","1840017286"
"Bhakkar","Bhakkar","31.6278","71.0625","Pakistan","PK","PAK","Punjab","minor","88472","1586655125"
"Heerlen","Heerlen","50.8833","5.9833","Netherlands","NL","NLD","Limburg","minor","86936","1528095552"
"Dunkerque","Dunkerque","51.0383","2.3775","France","FR","FRA","Hauts-de-France","minor","86865","1250542305"
"Solana","Solana","17.6522","121.6914","Philippines","PH","PHL","Cagayan","","88445","1608155391"
"Dongchuan","Dongchuan","25.5086","101.2356","China","CN","CHN","Yunnan","minor","88481","1156868763"
"Danlí","Danli","14.0328","-86.5669","Honduras","HN","HND","El Paraíso","","88722","1340108771"
"Madgaon","Madgaon","15.2736","73.9581","India","IN","IND","Goa","","87650","1356337752"
"Halifax","Halifax","53.7250","-1.8630","United Kingdom","GB","GBR","Calderdale","","88134","1826413776"
"Montelíbano","Montelibano","7.9750","-75.4208","Colombia","CO","COL","Córdoba","minor","88674","1170283887"
"Kōka","Koka","34.9667","136.1667","Japan","JP","JPN","Shiga","","88538","1392003145"
"Florence","Florence","34.1780","-79.7898","United States","US","USA","South Carolina","","88709","1840013643"
"San Luis","San Luis","20.1881","-75.8486","Cuba","CU","CUB","Santiago de Cuba","minor","88496","1192042203"
"Jamūī","Jamui","24.9200","86.2200","India","IN","IND","Bihār","","87357","1356076412"
"Baras","Baras","14.5167","121.2667","Philippines","PH","PHL","Rizal","","87637","1608108994"
"Maravatío de Ocampo","Maravatio de Ocampo","19.8933","-100.4428","Mexico","MX","MEX","Michoacán","minor","88535","1484174386"
"Paingkyon","Paingkyon","17.0242","97.9844","Myanmar","MM","MMR","Kayin State","","88604","1104246583"
"Ootacamund","Ootacamund","11.4100","76.7000","India","IN","IND","Tamil Nādu","","88430","1356809681"
"Newton","Newton","42.3316","-71.2085","United States","US","USA","Massachusetts","","88647","1840000433"
"Kimje","Kimje","35.8017","126.8889","South Korea","KR","KOR","Jeonbuk","","88479","1410051393"
"Sakiet ez Zit","Sakiet ez Zit","34.8000","10.7700","Tunisia","TN","TUN","Sfax","","87512","1788542455"
"Nirāla","Nirala","19.5000","78.2000","India","IN","IND","Mahārāshtra","","88433","1356830965"
"Aroroy","Aroroy","12.5125","123.3989","Philippines","PH","PHL","Masbate","","88351","1608024101"
"Grimsby","Grimsby","53.5675","-0.0800","United Kingdom","GB","GBR","North East Lincolnshire","","88243","1826700880"
"Echague","Echague","16.7056","121.6761","Philippines","PH","PHL","Isabela","","88410","1608221355"
"La Grita","La Grita","8.1333","-71.9833","Venezuela","VE","VEN","Táchira","minor","88450","1862682610"
"Bayramaly","Bayramaly","37.6167","62.1500","Turkmenistan","TM","TKM","Mary","","88486","1795017692"
"San Fabian","San Fabian","16.1500","120.4500","Philippines","PH","PHL","La Union","","87428","1608702400"
"Sangrūr","Sangrur","30.2506","75.8442","India","IN","IND","Punjab","","88043","1356578624"
"Jumri Tilaiyā","Jumri Tilaiya","24.4289","85.5355","India","IN","IND","Jhārkhand","","87867","1356900897"
"Livermore","Livermore","37.6868","-121.7607","United States","US","USA","California","","88403","1840020294"
"Zhob","Zhob","31.3417","69.4486","Pakistan","PK","PAK","Balochistan","minor","88356","1586969046"
"Berisso","Berisso","-34.8728","-57.8858","Argentina","AR","ARG","Buenos Aires","minor","87698","1032298172"
"Norton","Norton","-17.8833","30.7000","Zimbabwe","ZW","ZWE","Mashonaland West","","87038","1716209195"
"Sakai","Sakai","36.1669","136.2314","Japan","JP","JPN","Fukui","","87945","1392003292"
"Mettupālaiyam","Mettupalaiyam","11.2341","76.9067","India","IN","IND","Tamil Nādu","","87905","1356679430"
"Pingyuanjie","Pingyuanjie","23.7472","103.7610","China","CN","CHN","Yunnan","","88103","1156222378"
"Maiquetía","Maiquetia","10.5958","-66.9772","Venezuela","VE","VEN","La Guaira","minor","87909","1862822831"
"Janzūr","Janzur","32.8172","13.0111","Libya","LY","LBY","Al Jafārah","","88073","1434528890"
"Ratingen","Ratingen","51.3000","6.8500","Germany","DE","DEU","North Rhine-Westphalia","","87297","1276005433"
"Tacaná","Tacana","15.2415","-92.0684","Guatemala","GT","GTM","San Marcos","minor","87998","1320608933"
"Makilala","Makilala","6.9667","125.0833","Philippines","PH","PHL","Cotabato","","87927","1608709784"
"Ponta Porã","Ponta Pora","-22.5358","-55.7258","Brazil","BR","BRA","Mato Grosso do Sul","","88164","1076647935"
"Changting","Changting","25.8670","116.3167","China","CN","CHN","Fujian","minor","87458","1156460520"
"Farīdkot","Faridkot","30.6700","74.7600","India","IN","IND","Punjab","","87695","1356743545"
"São Pedro da Aldeia","Sao Pedro da Aldeia","-22.8389","-42.1028","Brazil","BR","BRA","Rio de Janeiro","","87875","1076616321"
"Calaca","Calaca","13.9300","120.8131","Philippines","PH","PHL","Batangas","","87361","1608590026"
"Aruppukkottai","Aruppukkottai","9.5139","78.1002","India","IN","IND","Tamil Nādu","","87722","1356144326"
"Leshou","Leshou","38.1902","116.1205","China","CN","CHN","Hebei","","88069","1156491348"
"Sinjār","Sinjar","36.3225","41.8642","Iraq","IQ","IRQ","Nīnawá","minor","88023","1368057870"
"Jilotepec","Jilotepec","19.9519","-99.5328","Mexico","MX","MEX","México","minor","87927","1484789785"
"Rapid City","Rapid City","44.0716","-103.2204","United States","US","USA","South Dakota","","88076","1840002180"
"Słupsk","Slupsk","54.4658","17.0292","Poland","PL","POL","Pomorskie","minor","87660","1616346559"
"Sudbury","Sudbury","46.4900","-81.0100","Canada","CA","CAN","Ontario","","88054","1124539524"
"São Gonçalo do Amarante","Sao Goncalo do Amarante","-5.7928","-35.3289","Brazil","BR","BRA","Rio Grande do Norte","","87668","1076732684"
"Jataí","Jatai","-17.8808","-51.8306","Brazil","BR","BRA","Goiás","","88006","1076908960"
"Decatur","Decatur","39.8557","-88.9342","United States","US","USA","Illinois","","88002","1840007269"
"Sassandra","Sassandra","4.9500","-6.0833","Côte d'Ivoire","CI","CIV","Bas-Sassandra","minor","87945","1384107875"
"Dalton","Dalton","34.7690","-84.9720","United States","US","USA","Georgia","","87944","1840014651"
"Camiling","Camiling","15.6867","120.4131","Philippines","PH","PHL","Tarlac","","87319","1608136759"
"Conroe","Conroe","30.3238","-95.4824","United States","US","USA","Texas","","87930","1840019586"
"Chiclana de la Frontera","Chiclana de la Frontera","36.4167","-6.1500","Spain","ES","ESP","Andalusia","","87493","1724794663"
"Wislane","Wislane","30.2167","-8.3833","Morocco","MA","MAR","Souss-Massa","","87910","1504929490"
"Buzuluk","Buzuluk","52.7833","52.2500","Russia","RU","RUS","Orenburgskaya Oblast’","","86316","1643877351"
"Shuangshuicun","Shuangshuicun","22.4356","112.9964","China","CN","CHN","Guangdong","","87295","1156552883"
"El Ejido","El Ejido","36.7831","-2.8167","Spain","ES","ESP","Andalusia","","87500","1724399333"
"Jagüey Grande","Jaguey Grande","22.5292","-81.1325","Cuba","CU","CUB","Matanzas","minor","87771","1192977963"
"Dipalpur","Dipalpur","30.6708","73.6533","Pakistan","PK","PAK","Punjab","","87176","1586506057"
"Baggao","Baggao","17.9347","121.7733","Philippines","PH","PHL","Cagayan","","87753","1608472700"
"Santa Cruz do Capibaribe","Santa Cruz do Capibaribe","-7.9569","-36.2050","Brazil","BR","BRA","Pernambuco","","87582","1076235057"
"Lünen","Lunen","51.6167","7.5167","Germany","DE","DEU","North Rhine-Westphalia","","86348","1276959723"
"Palangotu Adwār","Palangotu Adwar","32.7888","76.0009","India","IN","IND","Himāchal Pradesh","","87535","1356068456"
"Anakāpalle","Anakapalle","17.6913","83.0039","India","IN","IND","Andhra Pradesh","","86519","1356285975"
"Ceylanpınar","Ceylanpinar","36.8461","40.0489","Turkey","TR","TUR","Şanlıurfa","minor","87684","1792768664"
"Nahualá","Nahuala","14.8429","-91.3170","Guatemala","GT","GTM","Sololá","minor","87319","1320452965"
"Pergamino","Pergamino","-33.8836","-60.5669","Argentina","AR","ARG","Buenos Aires","minor","87652","1032214150"
"Fukuroi","Fukuroi","34.7502","137.9247","Japan","JP","JPN","Shizuoka","","86851","1392865065"
"Binmaley","Binmaley","16.0323","120.2690","Philippines","PH","PHL","Pangasinan","","86881","1608102609"
"Consolación del Sur","Consolacion del Sur","22.5083","-83.5172","Cuba","CU","CUB","Pinar del Río","minor","87500","1192801393"
"Koidu-Bulma","Koidu-Bulma","8.4405","-10.8500","Sierra Leone","SL","SLE","Eastern","","87539","1694104584"
"New Braunfels","New Braunfels","29.6994","-98.1148","United States","US","USA","Texas","","87549","1840021341"
"Cipolletti","Cipolletti","-38.9333","-67.9833","Argentina","AR","ARG","Río Negro","","87492","1032662574"
"Paredes","Paredes","41.2000","-8.3333","Portugal","PT","PRT","Porto","minor","86854","1620650523"
"Brindisi","Brindisi","40.6333","17.9333","Italy","IT","ITA","Puglia","minor","87141","1380201254"
"Ende","Ende","-8.8333","121.6500","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","87269","1360796329"
"Nabua","Nabua","13.4083","123.3750","Philippines","PH","PHL","Camarines Sur","","86490","1608379220"
"Denan","Denan","6.5000","43.5000","Ethiopia","ET","ETH","Sumalē","","87380","1231663674"
"Limpio","Limpio","-25.1683","-57.4942","Paraguay","PY","PRY","Central","","87301","1600926242"
"Tiflet","Tiflet","33.8931","-6.3069","Morocco","MA","MAR","Rabat-Salé-Kénitra","","86709","1504661999"
"Kumārapālaiyam","Kumarapalaiyam","11.4416","77.6907","India","IN","IND","Tamil Nādu","","86945","1356168265"
"Mobara","Mobara","35.4285","140.2881","Japan","JP","JPN","Chiba","","86446","1392225442"
"Bantayan","Bantayan","11.2000","123.7333","Philippines","PH","PHL","Cebu","","86247","1608493740"
"Chongoroi","Chongoroi","-13.5667","13.9500","Angola","AO","AGO","Benguela","","87278","1024105319"
"Bi’r al ‘Abd","Bi'r al \`Abd","31.0181","33.0111","Egypt","EG","EGY","Shamāl Sīnā’","minor","87219","1818433972"
"Houndé","Hounde","11.5000","-3.5167","Burkina Faso","BF","BFA","Hauts-Bassins","minor","87154","1854289245"
"Hānsi","Hansi","29.1000","75.9700","India","IN","IND","Haryāna","","86770","1356181619"
"Jackson","Jackson","42.2431","-84.4038","United States","US","USA","Michigan","","87230","1840003173"
"Itabaiana","Itabaiana","-10.6850","-37.4250","Brazil","BR","BRA","Sergipe","","86967","1076643218"
"Heyunkeng","Heyunkeng","23.9293","112.9185","China","CN","CHN","Guangdong","","86951","1156156749"
"Wulan","Wulan","36.5585","104.6765","China","CN","CHN","Gansu","minor","86990","1156502662"
"São Cristóvão","Sao Cristovao","-11.0150","-37.2058","Brazil","BR","BRA","Sergipe","","86979","1076642686"
"Menglang","Menglang","22.5586","99.9337","China","CN","CHN","Yunnan","minor","86877","1156671398"
"Qaraçuxur","Qaracuxur","40.3967","49.9736","Azerbaijan","AZ","AZE","Bakı","","85900","1031616606"
"Ad Diwem","Ad Diwem","14.0000","32.3167","Sudan","SD","SDN","White Nile","","87068","1729364292"
"Chiguayante","Chiguayante","-36.9167","-73.0167","Chile","CL","CHL","Biobío","","85863","1152693546"
"Muncie","Muncie","40.1989","-85.3950","United States","US","USA","Indiana","","87037","1840008365"
"Villingen-Schwenningen","Villingen-Schwenningen","48.0603","8.4586","Germany","DE","DEU","Baden-Württemberg","minor","86475","1276450523"
"Campana","Campana","-34.1667","-58.9167","Argentina","AR","ARG","Buenos Aires","minor","86860","1032345885"
"Dingcheng","Dingcheng","19.6803","110.3681","China","CN","CHN","Hainan","minor","86367","1156437178"
"Candelaria","Candelaria","3.4000","-76.3833","Colombia","CO","COL","Valle del Cauca","minor","86584","1170406010"
"Kalpitiya","Kalpitiya","8.1667","79.7167","Sri Lanka","LK","LKA","North Western","","86405","1144067438"
"Troy","Troy","42.5817","-83.1457","United States","US","USA","Michigan","","86912","1840002434"
"Shahdol","Shahdol","23.2800","81.3500","India","IN","IND","Madhya Pradesh","","86681","1356191462"
"Tuncheng","Tuncheng","19.3633","110.0978","China","CN","CHN","Hainan","minor","86474","1156049924"
"Gubkin","Gubkin","51.2833","37.5667","Russia","RU","RUS","Belgorodskaya Oblast’","","86780","1643378602"
"Cárdenas","Cardenas","23.0428","-81.2036","Cuba","CU","CUB","Matanzas","minor","86700","1192582521"
"Yaofeng","Yaofeng","35.1395","111.2174","China","CN","CHN","Shanxi","minor","86435","1156638655"
"Kharian","Kharian","32.8110","73.8650","Pakistan","PK","PAK","Punjab","","85765","1586004203"
"Widekum","Widekum","5.8717","9.7706","Cameroon","CM","CMR","North-West","","86822","1120813839"
"Longonjo","Longonjo","-12.9067","15.1864","Angola","AO","AGO","Huambo","","86795","1024081092"
"Ducheng","Ducheng","23.2445","111.5342","China","CN","CHN","Guangdong","minor","85659","1156252532"
"São Roque","Sao Roque","-23.5289","-47.1350","Brazil","BR","BRA","São Paulo","","86515","1076621665"
"Kattagan","Kattagan","40.2000","64.9167","Uzbekistan","UZ","UZB","Navoiy","","86745","1860782136"
"Clarkstown","Clarkstown","41.1319","-73.9660","United States","US","USA","New York","","86783","1840087311"
"Garulia","Garulia","22.8200","88.3700","India","IN","IND","West Bengal","","85336","1356885496"
"Keffi","Keffi","8.8464","7.8733","Nigeria","NG","NGA","Nasarawa","minor","85911","1566364417"
"Gotenba","Gotenba","35.3087","138.9346","Japan","JP","JPN","Shizuoka","","86334","1392876311"
"Novotroitsk","Novotroitsk","51.2039","58.3114","Russia","RU","RUS","Orenburgskaya Oblast’","","86474","1643186193"
"Nīmbāhera","Nimbahera","24.6200","74.6800","India","IN","IND","Rājasthān","","86453","1356139514"
"Pariaman","Pariaman","-0.6261","100.1206","Indonesia","ID","IDN","Sumatera Barat","minor","85485","1360811086"
"Tagaytay","Tagaytay","14.1000","120.9333","Philippines","PH","PHL","Cavite","","85330","1608593949"
"Cabiao","Cabiao","15.2522","120.8575","Philippines","PH","PHL","Nueva Ecija","","85862","1608607264"
"Lugang","Lugang","24.0500","120.4333","Taiwan","TW","TWN","Changhua","","85423","1158025426"
"Santa Rosa Jauregui","Santa Rosa Jauregui","20.7418","-100.4473","Mexico","MX","MEX","Querétaro","","86376","1484681374"
"Homosassa Springs","Homosassa Springs","28.8119","-82.5392","United States","US","USA","Florida","","86578","1840014060"
"San Antonio","San Antonio","-33.5933","-71.6217","Chile","CL","CHL","Valparaíso","minor","86239","1152830501"
"Kameoka","Kameoka","35.0167","135.5667","Japan","JP","JPN","Kyōto","","86112","1392003410"
"Guasavito","Guasavito","25.5655","-108.4718","Mexico","MX","MEX","Sinaloa","","86361","1484662260"
"Treviso","Treviso","45.6722","12.2422","Italy","IT","ITA","Veneto","minor","84954","1380900516"
"Maratturai","Maratturai","11.1000","79.6500","India","IN","IND","Tamil Nādu","","85632","1356242663"
"Port Huron","Port Huron","42.9821","-82.4388","United States","US","USA","Michigan","","86440","1840003953"
"Yabēlo","Yabelo","4.8833","38.0833","Ethiopia","ET","ETH","Oromīya","","86400","1231019846"
"Sārni","Sarni","22.1040","78.1730","India","IN","IND","Madhya Pradesh","","86141","1356600179"
"Shaoshanzhan","Shaoshanzhan","27.9100","112.4800","China","CN","CHN","Hunan","","86036","1156109964"
"Torrente","Torrente","39.4365","-0.4679","Spain","ES","ESP","Valencia","","85142","1724305866"
"Seoni Mālwa","Seoni Malwa","22.4508","77.4681","India","IN","IND","Madhya Pradesh","","86195","1356893622"
"Konstanz","Konstanz","47.6667","9.1833","Germany","DE","DEU","Baden-Württemberg","","84760","1276601373"
"Longchuan","Longchuan","25.1945","101.2759","China","CN","CHN","Yunnan","minor","86060","1156428919"
"Kaizuka","Kaizuka","34.4333","135.3667","Japan","JP","JPN","Ōsaka","","84356","1392003060"
"Hövsan","Hovsan","40.3744","50.0853","Azerbaijan","AZ","AZE","Bakı","","85000","1031396152"
"Khowrāsgān","Khowrasgan","32.6539","51.7553","Iran","IR","IRN","Eşfahān","","86063","1364514942"
"Jaorā","Jaora","23.6300","75.1300","India","IN","IND","Madhya Pradesh","","85946","1356111524"
"Bugulma","Bugulma","54.5364","52.7975","Russia","RU","RUS","Tatarstan","","86085","1643987882"
"Shchëkino","Shchekino","54.0000","37.5167","Russia","RU","RUS","Tul’skaya Oblast’","","86088","1643062876"
"Potiskum","Potiskum","11.7104","11.0800","Nigeria","NG","NGA","Yobe","minor","86002","1566461523"
"Guinobatan","Guinobatan","13.1833","123.6000","Philippines","PH","PHL","Albay","","85786","1608966710"
"Napa","Napa","38.2975","-122.3010","United States","US","USA","California","","86130","1840020241"
"Bāsoda","Basoda","23.8515","77.9365","India","IN","IND","Madhya Pradesh","","85716","1356486235"
"Jastrzębie-Zdrój","Jastrzebie-Zdroj","49.9500","18.5833","Poland","PL","POL","Śląskie","minor","85050","1616365516"
"Dhārāpuram","Dharapuram","10.7300","77.5200","India","IN","IND","Tamil Nādu","","85778","1356467104"
"Iga","Iga","34.7667","136.1333","Japan","JP","JPN","Mie","","85883","1392003101"
"Khemis Miliana","Khemis Miliana","36.2600","2.2000","Algeria","DZ","DZA","Aïn Defla","","84574","1012116292"
"San Pedro Sacatepéquez","San Pedro Sacatepequez","14.9664","-91.7775","Guatemala","GT","GTM","San Marcos","minor","85671","1320463364"
"Longhua","Longhua","41.3170","117.7264","China","CN","CHN","Hebei","minor","85697","1156450415"
"Sharūrah","Sharurah","17.4833","47.1167","Saudi Arabia","SA","SAU","Najrān","","85977","1682490742"
"Kitakōriyamachō","Kitakoriyamacho","34.6500","135.7833","Japan","JP","JPN","Nara","","84007","1392178653"
"Ushiku","Ushiku","35.9794","140.1496","Japan","JP","JPN","Ibaraki","","84505","1392965168"
"North Vancouver","North Vancouver","49.3641","-123.0066","Canada","CA","CAN","British Columbia","","85935.00","1124000146"
"Cacoal","Cacoal","-11.4386","-61.4472","Brazil","BR","BRA","Rondônia","","85893","1076046408"
"Chiquinquirá","Chiquinquira","5.6333","-73.7500","Colombia","CO","COL","Boyacá","minor","85274","1170668478"
"Coari","Coari","-4.0850","-63.1408","Brazil","BR","BRA","Amazonas","","85910","1076899406"
"Springdale","Springdale","36.1901","-94.1574","United States","US","USA","Arkansas","","85887","1840015336"
"Guanambi","Guanambi","-14.2228","-42.7808","Brazil","BR","BRA","Bahia","","85797","1076791780"
"Yeysk","Yeysk","46.7111","38.2733","Russia","RU","RUS","Krasnodarskiy Kray","minor","85760","1643587963"
"Sekimachi","Sekimachi","35.4958","136.9180","Japan","JP","JPN","Gifu","","85636","1392926688"
"Newport Beach","Newport Beach","33.6151","-117.8668","United States","US","USA","California","","85806","1840020581"
"La Dorada","La Dorada","5.4538","-74.6647","Colombia","CO","COL","Caldas","minor","85677","1170734727"
"Dharmapuri","Dharmapuri","12.1270","78.1580","India","IN","IND","Tamil Nādu","","85134","1356724448"
"Woolwich","Woolwich","51.4880","0.0630","United Kingdom","GB","GBR","Greenwich","","84959","1826479020"
"Krishnagiri","Krishnagiri","12.5317","78.2174","India","IN","IND","Tamil Nādu","","85254","1356128688"
"Al Hindīyah","Al Hindiyah","32.5442","44.2197","Iraq","IQ","IRQ","Karbalā’","minor","84100","1368398173"
"Cachoeira do Sul","Cachoeira do Sul","-30.0394","-52.8953","Brazil","BR","BRA","Rio Grande do Sul","","85712","1076336795"
"Pilkhua","Pilkhua","28.7120","77.6545","India","IN","IND","Uttar Pradesh","","83736","1356121422"
"Brossard","Brossard","45.4667","-73.4500","Canada","CA","CAN","Quebec","","85721","1124655166"
"Chita","Chita","35.0000","136.8667","Japan","JP","JPN","Aichi","","83881","1392292278"
"Anderson","Anderson","40.0891","-85.6892","United States","US","USA","Indiana","","85700","1840007242"
"San Ramon","San Ramon","37.7624","-121.9365","United States","US","USA","California","","85688","1840021530"
"General Rodríguez","General Rodriguez","-34.6167","-58.9500","Argentina","AR","ARG","Buenos Aires","minor","85315","1032661171"
"Batarasa","Batarasa","8.6700","117.6200","Philippines","PH","PHL","Palawan","","85439","1608320130"
"Kineshma","Kineshma","57.4333","42.1333","Russia","RU","RUS","Ivanovskaya Oblast’","","83871","1643014487"
"Lake Forest","Lake Forest","33.6605","-117.6714","United States","US","USA","California","","85516","1840020591"
"Junín","Junin","-34.5833","-60.9500","Argentina","AR","ARG","Buenos Aires","minor","85420","1032976047"
"Colonie","Colonie","42.7396","-73.7874","United States","US","USA","New York","","85430","1840004495"
"Mhow","Mhow","22.5500","75.7600","India","IN","IND","Madhya Pradesh","","85023","1356097509"
"Dapitan","Dapitan","8.6549","123.4243","Philippines","PH","PHL","Zamboanga del Norte","","85202","1608014287"
"Warder","Warder","6.9667","45.3500","Ethiopia","ET","ETH","Sumalē","","85400","1231158948"
"Harda Khās","Harda Khas","22.3441","77.0954","India","IN","IND","Madhya Pradesh","","85136","1356762440"
"Mission","Mission","26.2039","-98.3256","United States","US","USA","Texas","","85311","1840021026"
"Caratinga","Caratinga","-19.7900","-42.1389","Brazil","BR","BRA","Minas Gerais","","85239","1076504581"
"Auburn","Auburn","47.3039","-122.2108","United States","US","USA","Washington","","85306","1840018416"
"Brooklyn Park","Brooklyn Park","45.1112","-93.3505","United States","US","USA","Minnesota","","85247","1840004023"
"Takayama","Takayama","36.1460","137.2522","Japan","JP","JPN","Gifu","","85199","1392003285"
"Derry","Derry","54.9917","-7.3417","United Kingdom","GB","GBR","Derry and Strabane","","85016","1826603241"
"Maga","Maga","10.8500","14.9333","Cameroon","CM","CMR","Extrême-Nord","","85100","1120666970"
"Luancheng","Luancheng","37.8846","114.6523","China","CN","CHN","Hebei","","84068","1156272160"
"Bryan","Bryan","30.6650","-96.3807","United States","US","USA","Texas","","85204","1840019569"
"Sumenep","Sumenep","-7.0049","113.8496","Indonesia","ID","IDN","Jawa Timur","minor","84656","1360439354"
"Springfield","Springfield","39.9300","-83.7959","United States","US","USA","Ohio","","85121","1840003765"
"Hattiesburg","Hattiesburg","31.3074","-89.3170","United States","US","USA","Mississippi","","85086","1840013878"
"Walvisbaai","Walvisbaai","-22.9561","14.5081","Namibia","NA","NAM","Erongo","","85000","1516267898"
"Moriyama","Moriyama","35.0586","135.9942","Japan","JP","JPN","Shiga","","83567","1392937175"
"Tshilenge","Tshilenge","-6.2500","23.7667","Congo (Kinshasa)","CD","COD","Kasaï Oriental","","84651","1180504718"
"Bahlā’","Bahla'","22.9680","57.2980","Oman","OM","OMN","Ad Dākhilīyah","","85000","1512321924"
"Korgas","Korgas","44.2125","80.4097","China","CN","CHN","Xinjiang","","85000","1156221385"
"Repentigny","Repentigny","45.7333","-73.4667","Canada","CA","CAN","Quebec","","84965","1124379778"
"Westland","Westland","42.3192","-83.3805","United States","US","USA","Michigan","","84961","1840001847"
"Albany","Albany","31.5776","-84.1762","United States","US","USA","Georgia","","84901","1840013864"
"Marl","Marl","51.6667","7.1167","Germany","DE","DEU","North Rhine-Westphalia","","83941","1276451667"
"Dhorāji","Dhoraji","21.7337","70.4499","India","IN","IND","Gujarāt","","84545","1356595606"
"Sambava","Sambava","-14.2667","50.1667","Madagascar","MG","MDG","Antsiranana","minor","84039","1450004590"
"Ciudad Mante","Ciudad Mante","22.7333","-98.9500","Mexico","MX","MEX","Tamaulipas","minor","84787","1484536173"
"Jacobina","Jacobina","-11.1808","-40.5178","Brazil","BR","BRA","Bahia","","84811","1076049373"
"Science City of Muñoz","Science City of Munoz","15.7153","120.9039","Philippines","PH","PHL","Nueva Ecija","","84308","1608795106"
"Arujá","Aruja","-23.3967","-46.3211","Brazil","BR","BRA","São Paulo","","83939","1076436872"
"Guider","Guider","9.9342","13.9486","Cameroon","CM","CMR","Nord","","84647","1120662630"
"Chirundu","Chirundu","-16.0500","28.8333","Zambia","ZM","ZMB","Southern","","84750","1894856417"
"Senador Canedo","Senador Canedo","-16.7594","-49.0864","Brazil","BR","BRA","Goiás","","84443","1076337498"
"Paracatu","Paracatu","-17.2217","-46.8750","Brazil","BR","BRA","Minas Gerais","","84718","1076347421"
"Torrevieja","Torrevieja","37.9778","-0.6833","Spain","ES","ESP","Valencia","","83547","1724485635"
"Fort Myers","Fort Myers","26.6194","-81.8302","United States","US","USA","Florida","","84694","1840014226"
"Yokotemachi","Yokotemachi","39.3113","140.5533","Japan","JP","JPN","Akita","","84556","1392195240"
"Habiganj","Habiganj","24.3808","91.4144","Bangladesh","BD","BGD","Sylhet","","83114","1050880972"
"Sabanalarga","Sabanalarga","10.6300","-74.9236","Colombia","CO","COL","Atlántico","minor","84410","1170724527"
"Worms","Worms","49.6319","8.3653","Germany","DE","DEU","Rhineland-Palatinate","minor","83850","1276944683"
"Concepción Tutuapa","Concepcion Tutuapa","15.2833","-91.7833","Guatemala","GT","GTM","San Marcos","minor","84138","1320051048"
"Bais","Bais","9.5907","123.1213","Philippines","PH","PHL","Negros Oriental","","84317","1608298426"
"Tire","Tire","38.0833","27.7333","Turkey","TR","TUR","İzmir","minor","84457","1792346729"
"Villa Altagracia","Villa Altagracia","18.6667","-70.1667","Dominican Republic","DO","DOM","Valdesia","","84312","1214414901"
"Dmitrov","Dmitrov","56.3500","37.5333","Russia","RU","RUS","Moskovskaya Oblast’","","84408","1643642162"
"Vélez-Málaga","Velez-Malaga","36.7833","-4.1000","Spain","ES","ESP","Andalusia","","83899","1724206889"
"Channapatna","Channapatna","12.6514","77.2067","India","IN","IND","Karnātaka","","83921","1356894522"
"Bolinao","Bolinao","16.3881","119.8947","Philippines","PH","PHL","Pangasinan","","83979","1608471973"
"Serra Talhada","Serra Talhada","-7.9858","-38.2958","Brazil","BR","BRA","Pernambuco","","84352","1076913729"
"Pará de Minas","Para de Minas","-19.8600","-44.6078","Brazil","BR","BRA","Minas Gerais","","84215","1076626301"
"Chigorodó","Chigorodo","7.6675","-76.6808","Colombia","CO","COL","Antioquia","minor","84183","1170787746"
"Cukai","Cukai","4.2332","103.4479","Malaysia","MY","MYS","Terengganu","","82425","1458033606"
"El Milia","El Milia","36.7500","6.2667","Algeria","DZ","DZA","Jijel","","83931","1012706826"
"Atascocita","Atascocita","29.9777","-95.1953","United States","US","USA","Texas","","84222","1840018251"
"Tiruppattūr","Tiruppattur","12.5000","78.6000","India","IN","IND","Tamil Nādu","","83612","1356818110"
"Lqoliaa","Lqoliaa","30.2908","-9.4661","Morocco","MA","MAR","Souss-Massa","","83235","1504675410"
"Fredrikstad","Fredrikstad","59.2053","10.9500","Norway","NO","NOR","Viken","minor","83892","1578625656"
"Cereté","Cerete","8.8833","-75.8000","Colombia","CO","COL","Córdoba","minor","83917","1170926689"
"Ji’an","Ji'an","23.9500","121.5722","Taiwan","TW","TWN","Hualien","","82840","1158376537"
"Phúc Yên","Phuc Yen","21.2333","105.7000","Vietnam","VN","VNM","Vĩnh Phúc","minor","83352","1704818055"
"Bhawānipatna","Bhawanipatna","19.9100","83.1280","India","IN","IND","Odisha","","83756","1356317899"
"Zheleznogorsk","Zheleznogorsk","56.2500","93.5333","Russia","RU","RUS","Krasnoyarskiy Kray","","83857","1643824002"
"Talavera de la Reina","Talavera de la Reina","39.9583","-4.8328","Spain","ES","ESP","Castille-La Mancha","","83477","1724716966"
"Arona","Arona","28.0996","-16.6809","Spain","ES","ESP","Canary Islands","","82982","1724300501"
"Felipe Carrillo Puerto","Felipe Carrillo Puerto","19.5786","-88.0453","Mexico","MX","MEX","Quintana Roo","minor","83990","1484914299"
"Redwood City","Redwood City","37.5025","-122.2253","United States","US","USA","California","","83905","1840020304"
"Ijuí","Ijui","-28.3878","-53.9150","Brazil","BR","BRA","Rio Grande do Sul","","83764","1076683915"
"Nantingcun","Nantingcun","20.8040","110.0826","China","CN","CHN","Guangdong","","82944","1156475739"
"Sirsilla","Sirsilla","18.3800","78.8300","India","IN","IND","Andhra Pradesh","","83186","1356360144"
"Pontevedra","Pontevedra","42.4333","-8.6333","Spain","ES","ESP","Galicia","minor","83114","1724789643"
"Chilliwack","Chilliwack","49.1577","-121.9509","Canada","CA","CAN","British Columbia","","83788","1124531262"
"Buuhoodle","Buuhoodle","8.2311","46.3267","Somalia","SO","SOM","Togdheer","","83747","1706850351"
"Farmington Hills","Farmington Hills","42.4860","-83.3771","United States","US","USA","Michigan","","83749","1840002442"
"Alton","Alton","38.9037","-90.1520","United States","US","USA","Illinois","","83705","1840007424"
"Lafey","Lafey","3.1508","41.1864","Kenya","KE","KEN","Mandera","","83457","1404326023"
"Tila","Tila","17.3667","-92.5000","Mexico","MX","MEX","Chiapas","minor","83505","1484810186"
"San Antonio","San Antonio","15.3078","120.8533","Philippines","PH","PHL","Nueva Ecija","","83060","1608254250"
"Chingleput","Chingleput","12.6918","79.9766","India","IN","IND","Tamil Nādu","","82608","1356261740"
"Yurga","Yurga","55.7231","84.8861","Russia","RU","RUS","Kemerovskaya Oblast’","","81759","1643047994"
"Melbourne","Melbourne","28.1086","-80.6627","United States","US","USA","Florida","","83500","1840015960"
"Maco","Maco","7.3619","125.8553","Philippines","PH","PHL","Davao de Oro","","83237","1608519654"
"Mian Channun","Mian Channun","30.4397","72.3544","Pakistan","PK","PAK","Punjab","","82586","1586864091"
"Redditch","Redditch","52.3000","-1.9333","United Kingdom","GB","GBR","Worcestershire","","81919","1826205148"
"Taytay","Taytay","10.8167","119.5167","Philippines","PH","PHL","Palawan","","83357","1608175661"
"Serrinha","Serrinha","-11.6639","-39.0078","Brazil","BR","BRA","Bahia","","83275","1076832200"
"Wutiancun","Wutiancun","23.1852","116.4757","China","CN","CHN","Guangdong","","82986","1156219199"
"Santana do Livramento","Santana do Livramento","-30.8908","-55.5328","Brazil","BR","BRA","Rio Grande do Sul","","83324","1076271841"
"Rivadavia","Rivadavia","-31.5303","-68.5947","Argentina","AR","ARG","San Juan","minor","82582","1032402116"
"Siaton","Siaton","9.0667","123.0333","Philippines","PH","PHL","Negros Oriental","","83082","1608000106"
"Tual","Tual","-5.6368","132.7508","Indonesia","ID","IDN","Maluku","","82955","1360221504"
"Mānsa","Mansa","29.9906","75.3996","India","IN","IND","Punjab","","82956","1356518024"
"Boukoumbé","Boukoumbe","10.1833","1.1000","Benin","BJ","BEN","Atacora","","83147","1204827134"
"Bhalwal","Bhalwal","32.2656","72.8994","Pakistan","PK","PAK","Punjab","","82556","1586233064"
"Seropédica","Seropedica","-22.7439","-43.7078","Brazil","BR","BRA","Rio de Janeiro","","82892","1076073491"
"Wuyi","Wuyi","37.7965","115.8920","China","CN","CHN","Hebei","","82526","1156726142"
"La Trinidad","La Trinidad","15.9833","-91.8667","Mexico","MX","MEX","Chiapas","","83111","1484023235"
"Marsala","Marsala","37.7981","12.4342","Italy","IT","ITA","Sicilia","","82802","1380770056"
"Kateríni","Katerini","40.2667","22.5000","Greece","GR","GRC","Kentrikí Makedonía","minor","82971","1300524464"
"Velbert","Velbert","51.3333","7.0500","Germany","DE","DEU","North Rhine-Westphalia","","81984","1276896645"
"Williamsburg","Williamsburg","37.2693","-76.7076","United States","US","USA","Virginia","","83021","1840001720"
"Pozzuoli","Pozzuoli","40.8231","14.1222","Italy","IT","ITA","Campania","","81141","1380746744"
"Al Ḩayy","Al Hayy","32.1667","46.0500","Iraq","IQ","IRQ","Wāsiţ","minor","82900","1368988553"
"Mabinay","Mabinay","9.7333","122.9167","Philippines","PH","PHL","Negros Oriental","","82953","1608313367"
"Chelghoum el Aïd","Chelghoum el Aid","36.1667","6.1667","Algeria","DZ","DZA","Mila","","82560","1012048079"
"Tanjay","Tanjay","9.5167","123.1583","Philippines","PH","PHL","Negros Oriental","","82642","1608955515"
"Hukou","Hukou","24.9000","121.0500","Taiwan","TW","TWN","Hsinchu","","81550","1158167962"
"Xiedian","Xiedian","35.4190","110.8281","China","CN","CHN","Shanxi","minor","81386","1156413530"
"Nakatsu","Nakatsu","33.5992","131.1906","Japan","JP","JPN","Fukuoka","","82714","1392944075"
"Ufeyn","Ufeyn","10.6500","49.7500","Somalia","SO","SOM","Bari","","82850","1706398122"
"Bhaktapur","Bhaktapur","27.6722","85.4278","Nepal","NP","NPL","","","81748","1524449093"
"Barra do Corda","Barra do Corda","-5.5058","-45.2428","Brazil","BR","BRA","Maranhão","","82830","1076745135"
"Shīrvān","Shirvan","37.3967","57.9294","Iran","IR","IRN","Khorāsān-e Shomālī","minor","82790","1364402242"
"Sorriso","Sorriso","-12.5450","-55.7108","Brazil","BR","BRA","Mato Grosso","","82792","1076873169"
"Stockton-on-Tees","Stockton-on-Tees","54.5700","-1.3200","United Kingdom","GB","GBR","Stockton-on-Tees","","82729","1826259802"
"Madera","Madera","36.9630","-120.0778","United States","US","USA","California","","82772","1840020300"
"Kongjiazhuangcun","Kongjiazhuangcun","40.7536","114.7237","China","CN","CHN","Hebei","","81909","1156579045"
"Saunda","Saunda","23.6600","85.3500","India","IN","IND","Jhārkhand","","81915","1356188296"
"Nowy Sącz","Nowy Sacz","49.6239","20.6972","Poland","PL","POL","Małopolskie","minor","81281","1616869871"
"Calatrava","Calatrava","10.6000","123.4833","Philippines","PH","PHL","Negros Occidental","","82540","1608680606"
"Karanganyar","Karanganyar","-7.6033","110.9778","Indonesia","ID","IDN","Jawa Tengah","minor","80812","1360194209"
"Minden","Minden","52.2883","8.9167","Germany","DE","DEU","North Rhine-Westphalia","minor","81857","1276191164"
"Warwick","Warwick","41.7062","-71.4334","United States","US","USA","Rhode Island","","82666","1840003344"
"Cranston","Cranston","41.7658","-71.4858","United States","US","USA","Rhode Island","","82654","1840003287"
"Cruzeiro do Sul","Cruzeiro do Sul","-7.6308","-72.6700","Brazil","BR","BRA","Acre","","82622","1076881427"
"Rivadavia","Rivadavia","-33.1833","-68.4667","Argentina","AR","ARG","Mendoza","minor","82582","1032956544"
"Chulucanas","Chulucanas","-5.0961","-80.1647","Peru","PE","PER","Piura","","82521","1604692561"
"Kātoya","Katoya","23.6500","88.1300","India","IN","IND","West Bengal","","81615","1356487359"
"Ōmihachiman","Omihachiman","35.1283","136.0981","Japan","JP","JPN","Shiga","","81545","1392288149"
"Muroran","Muroran","42.3152","140.9740","Japan","JP","JPN","Hokkaidō","","81580","1392259868"
"Polatsk","Polatsk","55.4833","28.8000","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","82547","1112068992"
"Baytown","Baytown","29.7587","-94.9671","United States","US","USA","Texas","","82543","1840019616"
"El Estor","El Estor","15.5333","-89.3500","Guatemala","GT","GTM","Izabal","minor","82491","1320745698"
"Shikokuchūō","Shikokuchuo","33.9833","133.5500","Japan","JP","JPN","Ehime","","82320","1392003084"
"Carmen","Carmen","7.3606","125.7064","Philippines","PH","PHL","Davao del Norte","","82018","1608601211"
"Largo","Largo","27.9088","-82.7712","United States","US","USA","Florida","","82505","1840015114"
"Patrocínio","Patrocinio","-18.9439","-46.9928","Brazil","BR","BRA","Minas Gerais","","82471","1076001071"
"Campo Mourão","Campo Mourao","-24.0458","-52.3828","Brazil","BR","BRA","Paraná","","82354","1076799533"
"Chaykovskiy","Chaykovskiy","56.7667","54.1500","Russia","RU","RUS","Permskiy Kray","","82382","1643089795"
"Atambua","Atambua","-9.1061","124.8925","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","82196","1360853693"
"Oleksandriia","Oleksandriia","48.6667","33.1167","Ukraine","UA","UKR","Kirovohradska Oblast","minor","82345","1804836321"
"Hengelo","Hengelo","52.2656","6.7931","Netherlands","NL","NLD","Overijssel","minor","81049","1528989102"
"Bulacan","Bulacan","14.7928","120.8789","Philippines","PH","PHL","Bulacan","","81232","1608149284"
"Bekobod","Bekobod","40.2167","69.2167","Uzbekistan","UZ","UZB","Toshkent","","82082","1860881451"
"Puerto Iguazú","Puerto Iguazu","-25.6000","-54.5667","Argentina","AR","ARG","Misiones","","82227","1032320107"
"Maple Ridge","Maple Ridge","49.2167","-122.6000","Canada","CA","CAN","British Columbia","","82256","1124001699"
"La Louvière","La Louviere","50.4667","4.1833","Belgium","BE","BEL","Wallonia","","80986","1056383929"
"Grosseto","Grosseto","42.7667","11.1000","Italy","IT","ITA","Tuscany","minor","82036","1380285116"
"Pilar","Pilar","-34.4588","-58.9142","Argentina","AR","ARG","Buenos Aires","minor","81120","1032712196"
"Siasi","Siasi","5.5462","120.8145","Philippines","PH","PHL","Sulu","","81689","1608781969"
"Harlow","Harlow","51.7790","0.1280","United Kingdom","GB","GBR","Essex","","82059","1826737132"
"Kankakee","Kankakee","41.1020","-87.8643","United States","US","USA","Illinois","","82095","1840008260"
"Peterborough","Peterborough","44.3000","-78.3167","Canada","CA","CAN","Ontario","","82094","1124440356"
"Johns Creek","Johns Creek","34.0333","-84.2027","United States","US","USA","Georgia","","82068","1840014742"
"Ubay","Ubay","10.0560","124.4729","Philippines","PH","PHL","Bohol","","81799","1608803591"
"Hengkou","Hengkou","32.7378","108.7695","China","CN","CHN","Shaanxi","","81086","1156441630"
"Varese","Varese","45.8167","8.8333","Italy","IT","ITA","Lombardy","minor","80544","1380137214"
"Caldas","Caldas","6.0900","-75.6380","Colombia","CO","COL","Antioquia","minor","81381","1170279867"
"Ust’-Ilimsk","Ust'-Ilimsk","58.0000","102.6667","Russia","RU","RUS","Irkutskaya Oblast’","","81976","1643673948"
"Ashoknagar","Ashoknagar","24.5800","77.7300","India","IN","IND","Madhya Pradesh","","81828","1356679678"
"Azov","Azov","47.1000","39.4167","Russia","RU","RUS","Rostovskaya Oblast’","minor","80721","1643548776"
"Aracruz","Aracruz","-19.8200","-40.2728","Brazil","BR","BRA","Espírito Santo","","81832","1076506817"
"Campo Limpo","Campo Limpo","-23.2064","-46.7844","Brazil","BR","BRA","São Paulo","","80847","1076459810"
"Kadi","Kadi","23.3009","72.3322","India","IN","IND","Gujarāt","","81404","1356474902"
"Timóteo","Timoteo","-19.5828","-42.6439","Brazil","BR","BRA","Minas Gerais","","81243","1076541844"
"Watampone","Watampone","-4.5386","120.3297","Indonesia","ID","IDN","Sulawesi Selatan","minor","81629","1360956480"
"Norderstedt","Norderstedt","53.7064","10.0103","Germany","DE","DEU","Schleswig-Holstein","","80420","1276872953"
"Mannārgudi","Mannargudi","10.6653","79.4521","India","IN","IND","Tamil Nādu","","81150","1356628918"
"Xindian","Xindian","25.3172","101.5446","China","CN","CHN","Yunnan","","81667","1156746567"
"Imam Qasim","Imam Qasim","32.3014","44.6892","Iraq","IQ","IRQ","Bābil","","81500","1368437331"
"Bargarh","Bargarh","21.3333","83.6167","India","IN","IND","Odisha","","80625","1356834678"
"Flagstaff","Flagstaff","35.1872","-111.6194","United States","US","USA","Arizona","","81682","1840020335"
"Planaltina","Planaltina","-15.4528","-47.6139","Brazil","BR","BRA","Goiás","","81649","1076186542"
"Paranavaí","Paranavai","-23.0728","-52.4650","Brazil","BR","BRA","Paraná","","81590","1076424384"
"Shuibian","Shuibian","24.1263","112.7668","China","CN","CHN","Guangdong","","81413","1156260365"
"Buhi","Buhi","13.4347","123.5167","Philippines","PH","PHL","Camarines Sur","","81306","1608188485"
"Anapa","Anapa","44.8667","37.3667","Russia","RU","RUS","Krasnodarskiy Kray","minor","81447","1643287068"
"Dessau-Roßlau","Dessau-Rosslau","51.8333","12.2333","Germany","DE","DEU","Saxony-Anhalt","minor","81237","1276983641"
"Kimitsu","Kimitsu","35.3304","139.9027","Japan","JP","JPN","Chiba","","81355","1392801760"
"Matão","Matao","-21.6033","-48.3658","Brazil","BR","BRA","São Paulo","","81439","1076497431"
"Kentaū","Kentau","43.5167","68.5167","Kazakhstan","KZ","KAZ","","","81484","1398997742"
"Franklin","Franklin","35.9200","-86.8516","United States","US","USA","Tennessee","","81531","1840013380"
"Itá","Ita","-25.4833","-57.3500","Paraguay","PY","PRY","Central","","81084","1600969782"
"Matalam","Matalam","7.0833","124.9000","Philippines","PH","PHL","Cotabato","","81355","1608220278"
"Kāmāreddipet","Kamareddipet","18.3205","78.3370","India","IN","IND","Andhra Pradesh","","80315","1356010343"
"Afşin","Afsin","38.2500","36.9167","Turkey","TR","TUR","Kahramanmaraş","minor","81423","1792739931"
"Gobernador Gálvez","Gobernador Galvez","-33.0256","-60.6336","Argentina","AR","ARG","Santa Fe","","80769","1032739359"
"Yanggao","Yanggao","21.3298","109.9177","China","CN","CHN","Guangdong","","80681","1156228848"
"Lambunao","Lambunao","11.0500","122.4833","Philippines","PH","PHL","Iloilo","","81236","1608674437"
"Shiji","Shiji","23.5607","112.9630","China","CN","CHN","Guangdong","","80773","1156272620"
"Senhor do Bonfim","Senhor do Bonfim","-10.4628","-40.1908","Brazil","BR","BRA","Bahia","","81330","1076513309"
"Athi River","Athi River","-1.4500","36.9833","Kenya","KE","KEN","Machakos","","81302","1404232217"
"Novouralsk","Novouralsk","57.2500","60.0833","Russia","RU","RUS","Sverdlovskaya Oblast’","","81202","1643155652"
"Barili","Barili","10.1167","123.5333","Philippines","PH","PHL","Cebu","","80715","1608468753"
"San Jose","San Jose","13.8772","121.1050","Philippines","PH","PHL","Batangas","","79868","1608353630"
"Sefrou","Sefrou","33.8300","-4.8300","Morocco","MA","MAR","Fès-Meknès","","79887","1504820285"
"Kashiwazaki","Kashiwazaki","37.3719","138.5590","Japan","JP","JPN","Niigata","","81027","1392003358"
"Panzos","Panzos","15.3986","-89.6408","Guatemala","GT","GTM","Alta Verapaz","minor","81063","1320362247"
"Comitancillo","Comitancillo","15.0906","-91.7486","Guatemala","GT","GTM","San Marcos","minor","80612","1320980243"
"Arni","Arni","12.6677","79.2853","India","IN","IND","Tamil Nādu","","80275","1356433730"
"Taroudannt","Taroudannt","30.4710","-8.8806","Morocco","MA","MAR","Souss-Massa","","80149","1504415764"
"Kapalong","Kapalong","7.5854","125.7052","Philippines","PH","PHL","Davao del Norte","","81068","1608204911"
"Sint-Niklaas","Sint-Niklaas","51.1667","4.1333","Belgium","BE","BEL","Flanders","minor","80167","1056028982"
"Aquiraz","Aquiraz","-3.9008","-38.3908","Brazil","BR","BRA","Ceará","","80935","1076523331"
"San Cristóbal Verapaz","San Cristobal Verapaz","15.3650","-90.4792","Guatemala","GT","GTM","Alta Verapaz","minor","80613","1320384406"
"Joplin","Joplin","37.0757","-94.5018","United States","US","USA","Missouri","","81025","1840008771"
"Orihuela","Orihuela","38.0856","-0.9469","Spain","ES","ESP","Valencia","","80784","1724864615"
"Tagoloan","Tagoloan","8.5333","124.7500","Philippines","PH","PHL","Misamis Oriental","","80319","1608502082"
"Ahenkro","Ahenkro","7.1164","0.3242","Ghana","GH","GHA","Oti","","80813","1288684117"
"San Francisco","San Francisco","8.5050","125.9771","Philippines","PH","PHL","Agusan del Sur","","80760","1608328732"
"Yonezawa","Yonezawa","37.9222","140.1168","Japan","JP","JPN","Yamagata","","80795","1392003528"
"Randfontein","Randfontein","-26.1797","27.7042","South Africa","ZA","ZAF","Gauteng","","80492","1710137724"
"Bolpur","Bolpur","23.6700","87.7200","India","IN","IND","West Bengal","","80210","1356123985"
"Parma","Parma","41.3843","-81.7286","United States","US","USA","Ohio","","80880","1840003406"
"Malapatan","Malapatan","5.9667","125.2833","Philippines","PH","PHL","Sarangani","","80741","1608544416"
"Layton","Layton","41.0770","-111.9620","United States","US","USA","Utah","","80858","1840020145"
"Los Patios","Los Patios","7.8333","-72.5167","Colombia","CO","COL","Norte de Santander","minor","80235","1170225535"
"Meybod","Meybod","32.2444","54.0186","Iran","IR","IRN","Yazd","minor","80712","1364977923"
"Ipojuca","Ipojuca","-8.4000","-35.0625","Brazil","BR","BRA","Pernambuco","","80637","1076800536"
"Bafut","Bafut","6.0833","10.1000","Cameroon","CM","CMR","North-West","","80305","1120828506"
"Gamagōri","Gamagori","34.8431","137.2196","Japan","JP","JPN","Aichi","","79292","1392024893"
"Akiruno","Akiruno","35.7289","139.2941","Japan","JP","JPN","Tōkyō","","79600","1392270849"
"Anderson","Anderson","34.5211","-82.6478","United States","US","USA","South Carolina","","80657","1840013579"
"San Ramón","San Ramon","10.2182","-84.5992","Costa Rica","CR","CRI","Alajuela","","80566","1188012869"
"Echizen","Echizen","35.9035","136.1687","Japan","JP","JPN","Fukui","","80264","1392003537"
"Neumünster","Neumunster","54.0714","9.9900","Germany","DE","DEU","Schleswig-Holstein","minor","79496","1276757733"
"Sokcho","Sokcho","38.2069","128.5919","South Korea","KR","KOR","Gangwon","minor","79846","1410921315"
"Gumlā","Gumla","23.0444","84.5417","India","IN","IND","Jhārkhand","","80129","1356058585"
"Samā’il","Sama'il","23.3000","57.9833","Oman","OM","OMN","Ad Dākhilīyah","","80538","1512936108"
"Bulanık","Bulanik","39.0950","42.2667","Turkey","TR","TUR","Muş","minor","80540","1792108880"
"Rafḩā","Rafha","29.6386","43.5014","Saudi Arabia","SA","SAU","Al Ḩudūd ash Shamālīyah","","80544","1682612877"
"Peruvancha","Peruvancha","17.3600","80.4200","India","IN","IND","Andhra Pradesh","","80199","1356294882"
"Ādīgala","Adigala","10.4236","42.2333","Ethiopia","ET","ETH","Sumalē","","80440","1231021572"
"Calandala","Calandala","-9.0667","17.9000","Angola","AO","AGO","Lunda-Norte","","80415","1024525047"
"Simdega","Simdega","22.6200","84.5200","India","IN","IND","Jhārkhand","","80129","1356073158"
"Uvinza","Uvinza","-5.1036","30.3911","Tanzania","TZ","TZA","Kigoma","","80350","1834561245"
"Manmād","Manmad","20.2510","74.4390","India","IN","IND","Mahārāshtra","","80058","1356593226"
"Tākestān","Takestan","36.0697","49.6958","Iran","IR","IRN","Qazvīn","minor","80299","1364488107"
"Suramāla","Suramala","13.7500","79.7000","India","IN","IND","Andhra Pradesh","","80056","1356049007"
"Valdemoro","Valdemoro","40.1908","-3.6742","Spain","ES","ESP","Madrid","","79100","1724347455"
"Kottagūdem","Kottagudem","17.5500","80.6300","India","IN","IND","Andhra Pradesh","","79819","1356124457"
"Lívingston","Livingston","15.8300","-88.7500","Guatemala","GT","GTM","Izabal","minor","80249","1320970605"
"Arsikere","Arsikere","13.3139","76.2369","India","IN","IND","Karnātaka","","80000","1356006431"
"Mbalmayo","Mbalmayo","3.5167","11.5000","Cameroon","CM","CMR","Centre","","80206","1120049681"
"Namsan","Namsan","42.2275","129.2541","North Korea","KP","PRK","Hambuk","minor","80146","1408000884"
"Zarzis","Zarzis","33.5000","11.1167","Tunisia","TN","TUN","Médenine","","80000","1788933524"
"Andahuaylas","Andahuaylas","-13.6575","-73.3833","Peru","PE","PER","Apurímac","","80000","1604615351"
"Jamshoro","Jamshoro","25.4244","68.2811","Pakistan","PK","PAK","Sindh","minor","80000","1586889028"
"Tall ‘Afar","Tall \`Afar","36.3742","42.4433","Iraq","IQ","IRQ","Nīnawá","minor","80000","1368564375"
"Meïganga","Meiganga","6.5300","14.3700","Cameroon","CM","CMR","Adamaoua","","80100","1120789508"
"Jaen","Jaen","15.3392","120.9069","Philippines","PH","PHL","Nueva Ecija","","79189","1608058419"
"Balad","Balad","34.0164","44.1453","Iraq","IQ","IRQ","Şalāḩ ad Dīn","minor","80000","1368077707"
"Qo‘ng‘irot Shahri","Qo\`ng\`irot Shahri","43.0758","58.9067","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","80090","1860555381"
"St. Joseph","St. Joseph","39.7598","-94.8210","United States","US","USA","Missouri","","80099","1840009549"
"Poblacion","Poblacion","6.8000","124.6333","Philippines","PH","PHL","Sultan Kudarat","minor","79739","1608318645"
"Masallātah","Masallatah","32.5822","14.0400","Libya","LY","LBY","Al Marqab","","80000","1434116845"
"Vila do Conde","Vila do Conde","41.3528","-8.7444","Portugal","PT","PRT","Porto","minor","79533","1620584469"
"Natori-shi","Natori-shi","38.1715","140.8918","Japan","JP","JPN","Miyagi","","79250","1392168361"
"Manresa","Manresa","41.7264","1.8292","Spain","ES","ESP","Catalonia","","78192","1724989137"
"San Carlos del Zulia","San Carlos del Zulia","9.0000","-71.9333","Venezuela","VE","VEN","Zulia","minor","80000","1862219492"
"Plymouth","Plymouth","45.0225","-93.4617","United States","US","USA","Minnesota","","80034","1840008929"
"Koktokay","Koktokay","47.0004","89.4666","China","CN","CHN","Xinjiang","minor","80000","1156023872"
"Kathri","Kathri","26.4583","68.3192","Pakistan","PK","PAK","Sindh","","80000","1586828294"
"Capenda Camulemba","Capenda Camulemba","-9.5647","18.7633","Angola","AO","AGO","Lunda-Norte","","80000","1024864144"
"Fiumicino","Fiumicino","41.7667","12.2333","Italy","IT","ITA","Lazio","","79630","1380717773"
"Pátzcuaro","Patzcuaro","19.5164","-101.6097","Mexico","MX","MEX","Michoacán","minor","79868","1484039505"
"Turhal","Turhal","40.3900","36.0900","Turkey","TR","TUR","Tokat","minor","79916","1792534249"
"Mabai","Mabai","23.0188","104.3929","China","CN","CHN","Yunnan","minor","79534","1156543494"
"Calarcá","Calarca","4.5333","-75.6500","Colombia","CO","COL","Quindío","minor","79569","1170442353"
"Jinhe","Jinhe","22.7815","103.2280","China","CN","CHN","Yunnan","minor","79658","1156854757"
"Ben Gardane","Ben Gardane","33.1389","11.2167","Tunisia","TN","TUN","Médenine","","79912","1788380441"
"Idah","Idah","7.0833","6.7500","Nigeria","NG","NGA","Kogi","minor","79815","1566566689"
"Robles","Robles","10.3500","123.0667","Philippines","PH","PHL","Negros Occidental","","79492","1608534023"
"Yenakiieve","Yenakiieve","48.2311","38.2053","Ukraine","UA","UKR","Donetska Oblast","","79348","1804228533"
"Baracoa","Baracoa","20.3486","-74.5106","Cuba","CU","CUB","Guantánamo","minor","79797","1192326052"
"Patikul","Patikul","6.0667","121.1000","Philippines","PH","PHL","Sulu","","79564","1608678238"
"Zephyrhills","Zephyrhills","28.2409","-82.1797","United States","US","USA","Florida","","79795","1840015968"
"Langarūd","Langarud","37.1969","50.1536","Iran","IR","IRN","Gīlān","minor","79445","1364215543"
"Yalta","Yalta","44.4994","34.1700","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","79458","1804569428"
"Jiangna","Jiangna","23.6128","104.3433","China","CN","CHN","Yunnan","minor","79448","1156222101"
"Manhuaçu","Manhuacu","-20.2581","-42.0336","Brazil","BR","BRA","Minas Gerais","","79574","1076000444"
"Torres Vedras","Torres Vedras","39.0833","-9.2667","Portugal","PT","PRT","Lisboa","minor","79465","1620003589"
"Tissamaharama","Tissamaharama","6.2833","81.2833","Sri Lanka","LK","LKA","Southern","","79618","1144672530"
"Gyōda","Gyoda","36.1389","139.4556","Japan","JP","JPN","Saitama","","78488","1392815268"
"Florence","Florence","34.8303","-87.6655","United States","US","USA","Alabama","","79590","1840002083"
"Hannō","Hanno","35.8557","139.3277","Japan","JP","JPN","Saitama","","79169","1392681016"
"Alfenas","Alfenas","-21.4289","-45.9469","Brazil","BR","BRA","Minas Gerais","","79481","1076300425"
"Mandiraja Kulon","Mandiraja Kulon","-7.4722","109.5133","Indonesia","ID","IDN","Jawa Tengah","","78090","1360018564"
"Mangatarem","Mangatarem","15.7874","120.2921","Philippines","PH","PHL","Pangasinan","","79323","1608841442"
"Pleasanton","Pleasanton","37.6663","-121.8805","United States","US","USA","California","","79558","1840020298"
"Kadoma","Kadoma","-18.3400","29.9000","Zimbabwe","ZW","ZWE","Mashonaland West","","79174","1716398005"
"Pingyi","Pingyi","35.5104","117.6200","China","CN","CHN","Shandong","","78254","1156542248"
"Krasnyi Luch","Krasnyi Luch","48.1333","38.9333","Ukraine","UA","UKR","Luhanska Oblast","","79533","1804477928"
"Eséka","Eseka","3.6500","10.7667","Cameroon","CM","CMR","Centre","","79500","1120064220"
"Iwamizawa","Iwamizawa","43.1962","141.7759","Japan","JP","JPN","Hokkaidō","","79361","1392277310"
"Bauang","Bauang","16.5333","120.3333","Philippines","PH","PHL","La Union","","78449","1608237333"
"Dobni Para","Dobni Para","22.5200","74.1500","India","IN","IND","Madhya Pradesh","","79185","1356550673"
"Boynton Beach","Boynton Beach","26.5281","-80.0811","United States","US","USA","Florida","","79479","1840014208"
"Afgooye","Afgooye","2.1413","45.1169","Somalia","SO","SOM","Shabeellaha Hoose","minor","79400","1706930457"
"Tīkamgarh","Tikamgarh","24.7472","78.8500","India","IN","IND","Madhya Pradesh","","79106","1356652037"
"Juventino Rosas","Juventino Rosas","20.6500","-101.0000","Mexico","MX","MEX","Guanajuato","minor","79214","1484008596"
"Villa María","Villa Maria","-32.4103","-63.2314","Argentina","AR","ARG","Córdoba","minor","79356","1032987787"
"Sorgun","Sorgun","39.8144","35.1903","Turkey","TR","TUR","Yozgat","minor","79314","1792669012"
"Skarżysko-Kamienna","Skarzysko-Kamienna","51.1167","20.9167","Poland","PL","POL","Świętokrzyskie","minor","78636","1616858398"
"Texarkana","Texarkana","33.4500","-94.0847","United States","US","USA","Texas","","79304","1840021987"
"Eastleigh","Eastleigh","50.9667","-1.3500","United Kingdom","GB","GBR","Hampshire","","78716","1826595228"
"Klin","Klin","56.3333","36.7333","Russia","RU","RUS","Moskovskaya Oblast’","","79168","1643730409"
"Folsom","Folsom","38.6668","-121.1422","United States","US","USA","California","","79201","1840020246"
"Ozërsk","Ozersk","55.7500","60.7167","Russia","RU","RUS","Chelyabinskaya Oblast’","","79069","1643881437"
"Bahārestān","Baharestan","32.4797","51.7753","Iran","IR","IRN","Eşfahān","","79023","1364254559"
"Huaral","Huaral","-11.5000","-77.2000","Peru","PE","PER","Lima","","79011","1604962520"
"Pagbilao","Pagbilao","13.9720","121.6870","Philippines","PH","PHL","Quezon","","78700","1608158304"
"Pato Branco","Pato Branco","-26.2289","-52.6708","Brazil","BR","BRA","Paraná","","79011","1076958036"
"Mooka","Mooka","36.4404","140.0134","Japan","JP","JPN","Tochigi","","78665","1392952105"
"Béziers","Beziers","43.3476","3.2190","France","FR","FRA","Occitanie","minor","78308","1250666824"
"Madīnat as Sādāt","Madinat as Sadat","30.3811","30.5266","Egypt","EG","EGY","Al Buḩayrah","","79000","1818951142"
"Pototan","Pototan","10.9500","122.6333","Philippines","PH","PHL","Iloilo","","78298","1608792754"
"Sahuayo de Morelos","Sahuayo de Morelos","20.0575","-102.7239","Mexico","MX","MEX","Michoacán","minor","78477","1484554120"
"Bamberg","Bamberg","49.8914","10.8869","Germany","DE","DEU","Bavaria","minor","77749","1276824274"
"Mosigkau","Mosigkau","51.8333","12.2333","Germany","DE","DEU","Saxony-Anhalt","","78731","1276680755"
"Francisco Beltrão","Francisco Beltrao","-26.0808","-53.0550","Brazil","BR","BRA","Paraná","","78943","1076112162"
"Jelenia Góra","Jelenia Gora","50.9033","15.7344","Poland","PL","POL","Dolnośląskie","minor","78335","1616105652"
"Telêmaco Borba","Telemaco Borba","-24.3239","-50.6158","Brazil","BR","BRA","Paraná","","78974","1076867165"
"Limay","Limay","14.5619","120.5983","Philippines","PH","PHL","Bataan","","78272","1608113327"
"Pèrèrè","Perere","9.7994","2.9928","Benin","BJ","BEN","Borgou","","78988","1204059359"
"Cuamba","Cuamba","-14.8167","36.5500","Mozambique","MZ","MOZ","Niassa","","79013","1508862081"
"Pharr","Pharr","26.1685","-98.1904","United States","US","USA","Texas","","78997","1840021030"
"Koch Bihār","Koch Bihar","26.3242","89.4510","India","IN","IND","West Bengal","","77935","1356121274"
"Maizuru","Maizuru","35.4667","135.3833","Japan","JP","JPN","Kyōto","","78730","1392353780"
"Kizugawa","Kizugawa","34.7333","135.8167","Japan","JP","JPN","Kyōto","","78035","1392003534"
"Arkonam","Arkonam","13.0778","79.6667","India","IN","IND","Tamil Nādu","","78395","1356031273"
"Hengbei","Hengbei","23.8787","115.7309","China","CN","CHN","Guangdong","","78575","1156338217"
"Macabebe","Macabebe","14.9081","120.7156","Philippines","PH","PHL","Pampanga","","78151","1608096291"
"Homestead","Homestead","25.4665","-80.4472","United States","US","USA","Florida","","78885","1840015159"
"Subulussalam","Subulussalam","2.6422","98.0042","Indonesia","ID","IDN","Aceh","","78801","1360306114"
"Delmenhorst","Delmenhorst","53.0506","8.6317","Germany","DE","DEU","Lower Saxony","minor","77607","1276098664"
"Toboali","Toboali","-2.9997","106.4656","Indonesia","ID","IDN","Kepulauan Bangka Belitung","","78780","1360078937"
"Essaouira","Essaouira","31.5131","-9.7697","Morocco","MA","MAR","Marrakech-Safi","","77966","1504845522"
"Tierralta","Tierralta","8.1728","-76.0594","Colombia","CO","COL","Córdoba","minor","78770","1170909785"
"Valdosta","Valdosta","30.8502","-83.2788","United States","US","USA","Georgia","","78786","1840015894"
"Dondo","Dondo","-19.6167","34.7500","Mozambique","MZ","MOZ","Sofala","","78648","1508741848"
"Alīgūdarz","Aligudarz","33.4006","49.6950","Iran","IR","IRN","Lorestān","minor","78690","1364252948"
"Kortrijk","Kortrijk","50.8333","3.2667","Belgium","BE","BEL","Flanders","minor","77741","1056702614"
"Arlit","Arlit","18.7333","7.3833","Niger","NE","NER","Agadez","minor","78651","1562695011"
"Moju","Moju","-1.8839","-48.7689","Brazil","BR","BRA","Pará","","78629","1076020210"
"Upland","Upland","34.1178","-117.6604","United States","US","USA","California","","78624","1840021730"
"Argao","Argao","9.8833","123.6000","Philippines","PH","PHL","Cebu","minor","78187","1608797040"
"Bamban","Bamban","15.2742","120.5669","Philippines","PH","PHL","Tarlac","","78260","1608680907"
"Bandar Emām","Bandar Emam","30.4356","49.1056","Iran","IR","IRN","Khūzestān","","78353","1364872870"
"Kuvango","Kuvango","-14.4667","16.3000","Angola","AO","AGO","Huíla","","78543","1024458480"
"Manbij","Manbij","36.5275","37.9553","Syria","SY","SYR","Ḩalab","minor","78255","1760355581"
"Pattoki","Pattoki","31.0214","73.8528","Pakistan","PK","PAK","Punjab","","77210","1586235509"
"Vyborg","Vyborg","60.7106","28.7497","Russia","RU","RUS","Leningradskaya Oblast’","","78457","1643778472"
"Dias d’Ávila","Dias d'Avila","-12.6128","-38.2969","Brazil","BR","BRA","Bahia","","78058","1076290338"
"Ban Tha Khlong","Ban Tha Khlong","14.0894","100.6236","Thailand","TH","THA","Pathum Thani","","77243","1764699235"
"Usol’ye-Sibirskoye","Usol'ye-Sibirskoye","52.7500","103.6333","Russia","RU","RUS","Irkutskaya Oblast’","","77407","1643895073"
"Newark","Newark","40.0706","-82.4250","United States","US","USA","Ohio","","78447","1840001419"
"Elizabethtown","Elizabethtown","37.7031","-85.8773","United States","US","USA","Kentucky","","78446","1840013218"
"Hasselt","Hasselt","50.9305","5.3385","Belgium","BE","BEL","Flanders","minor","77651","1056617022"
"San Pedro Pinula","San Pedro Pinula","14.6667","-89.8500","Guatemala","GT","GTM","Jalapa","minor","78244","1320622602"
"St. Augustine","St. Augustine","29.8976","-81.3100","United States","US","USA","Florida","","78386","1840015935"
"Viersen","Viersen","51.2561","6.3972","Germany","DE","DEU","North Rhine-Westphalia","minor","77523","1276629059"
"Rancho Cordova","Rancho Cordova","38.5737","-121.2527","United States","US","USA","California","","78358","1840020248"
"Kambam","Kambam","9.7375","77.2823","India","IN","IND","Tamil Nādu","","77736","1356864573"
"Bustos","Bustos","14.9500","120.9167","Philippines","PH","PHL","Bulacan","","77199","1608938758"
"Qorveh","Qorveh","35.1664","47.8044","Iran","IR","IRN","Kordestān","minor","78276","1364464987"
"Péhonko","Pehonko","10.2283","2.0019","Benin","BJ","BEN","Atacora","","78217","1204295188"
"Kropotkin","Kropotkin","45.4333","40.5667","Russia","RU","RUS","Krasnodarskiy Kray","","78149","1643006243"
"Chino Hills","Chino Hills","33.9508","-117.7254","United States","US","USA","California","","78229","1840019115"
"Pinheiro","Pinheiro","-2.5208","-45.0828","Brazil","BR","BRA","Maranhão","","78162","1076349995"
"Chengbin","Chengbin","19.9991","110.3332","China","CN","CHN","Hainan","","77904","1156484050"
"Chitembo","Chitembo","-13.5167","16.7667","Angola","AO","AGO","Bié","","78156","1024103269"
"Perris","Perris","33.7898","-117.2233","United States","US","USA","California","","78106","1840020557"
"Bor","Bor","56.3500","44.0833","Russia","RU","RUS","Nizhegorodskaya Oblast’","","78063","1643006538"
"Bodhan","Bodhan","18.6700","77.9000","India","IN","IND","Andhra Pradesh","","77573","1356506985"
"Schaumburg","Schaumburg","42.0308","-88.0838","United States","US","USA","Illinois","","78053","1840011319"
"Narra","Narra","9.2833","118.4167","Philippines","PH","PHL","Palawan","","77948","1608000798"
"Kendall","Kendall","25.6697","-80.3556","United States","US","USA","Florida","","78007","1840014244"
"Balqash","Balqash","46.8481","74.9950","Kazakhstan","KZ","KAZ","","","78002","1398134106"
"Villa Victoria","Villa Victoria","19.4333","-100.0000","Mexico","MX","MEX","México","minor","77819","1484528358"
"Fray Bartolomé de Las Casas","Fray Bartolome de Las Casas","15.8456","-89.8658","Guatemala","GT","GTM","Alta Verapaz","minor","77896","1320227536"
"Linkou","Linkou","45.2819","130.2519","China","CN","CHN","Heilongjiang","minor","77754","1156383623"
"Hermosa","Hermosa","14.8333","120.5000","Philippines","PH","PHL","Bataan","","77443","1608543263"
"Basavakalyān","Basavakalyan","17.8744","76.9497","India","IN","IND","Karnātaka","","77596","1356923425"
"Roosendaal","Roosendaal","51.5333","4.4500","Netherlands","NL","NLD","Noord-Brabant","minor","77200","1528671703"
"Palencia","Palencia","42.0167","-4.5333","Spain","ES","ESP","Castille-Leon","minor","77090","1724682403"
"Camarillo","Camarillo","34.2230","-119.0321","United States","US","USA","California","","77795","1840019201"
"Numan","Numan","9.4536","11.8367","Nigeria","NG","NGA","Adamawa","minor","77617","1566815415"
"Puli","Puli","23.9667","120.9667","Taiwan","TW","TWN","Nantou","","77299","1158109634"
"Bebedouro","Bebedouro","-20.9494","-48.4792","Brazil","BR","BRA","São Paulo","","77627","1076045128"
"Khemis el Khechna","Khemis el Khechna","36.6500","3.3306","Algeria","DZ","DZA","Boumerdes","","76474","1012679229"
"Huebampo","Huebampo","26.6667","-109.3500","Mexico","MX","MEX","Sonora","","77682","1484527916"
"Guildford","Guildford","51.2365","-0.5703","United Kingdom","GB","GBR","Surrey","","77057","1826863533"
"Anniston","Anniston","33.6712","-85.8135","United States","US","USA","Alabama","","77690","1840006563"
"Três Rios","Tres Rios","-22.1169","-43.2089","Brazil","BR","BRA","Rio de Janeiro","","77432","1076943829"
"Santa Catalina","Santa Catalina","9.3331","122.8658","Philippines","PH","PHL","Negros Oriental","","77501","1608085821"
"Sumter","Sumter","33.9392","-80.3930","United States","US","USA","South Carolina","","77626","1840015612"
"Honjō","Honjo","36.2436","139.1904","Japan","JP","JPN","Saitama","","76757","1392850139"
"Armant","Armant","25.6167","32.5333","Egypt","EG","EGY","Qinā","","76457","1818701870"
"Unaí","Unai","-16.3639","-46.8944","Brazil","BR","BRA","Minas Gerais","","77565","1076438683"
"Jonesboro","Jonesboro","35.8212","-90.6791","United States","US","USA","Arkansas","","77520","1840014507"
"Bakhmut","Bakhmut","48.5947","38.0008","Ukraine","UA","UKR","Donetska Oblast","minor","75798","1804007616"
"Hammond","Hammond","41.6168","-87.4909","United States","US","USA","Indiana","","77491","1840007092"
"Rheine","Rheine","52.2833","7.4333","Germany","DE","DEU","North Rhine-Westphalia","","76948","1276882978"
"Marburg","Marburg","50.8100","8.7708","Germany","DE","DEU","Hesse","minor","76851","1276012023"
"Tecamachalco","Tecamachalco","18.8667","-97.7167","Mexico","MX","MEX","Puebla","minor","77061","1484013224"
"Santa Inês","Santa Ines","-3.6669","-45.3800","Brazil","BR","BRA","Maranhão","","77282","1076053036"
"Funing","Funing","39.8879","119.2314","China","CN","CHN","Hebei","","76460","1156164001"
"Manaoag","Manaoag","16.0439","120.4856","Philippines","PH","PHL","Pangasinan","","76045","1608599715"
"Daying","Daying","37.3043","115.7196","China","CN","CHN","Hebei","","76800","1156224896"
"Umingan","Umingan","15.9289","120.8419","Philippines","PH","PHL","Pangasinan","","77074","1608046328"
"Carmichael","Carmichael","38.6318","-121.3263","United States","US","USA","California","","77358","1840017579"
"Araripina","Araripina","-7.5500","-40.5667","Brazil","BR","BRA","Pernambuco","","77302","1076945288"
"Aş Şuwayrah","As Suwayrah","32.9403","44.6344","Iraq","IQ","IRQ","Wāsiţ","minor","77200","1368498003"
"Vorkuta","Vorkuta","67.5000","64.0333","Russia","RU","RUS","Komi","","77314","1643002916"
"Harunabad","Harunabad","29.6130","73.1409","Pakistan","PK","PAK","Punjab","","77206","1586810051"
"Fukuchiyama","Fukuchiyama","35.3000","135.1333","Japan","JP","JPN","Kyōto","","77150","1392295524"
"Gelendzhik","Gelendzhik","44.5750","38.0725","Russia","RU","RUS","Krasnodarskiy Kray","","77212","1643756962"
"Arlington Heights","Arlington Heights","42.0955","-87.9826","United States","US","USA","Illinois","","77283","1840011243"
"Tsubame","Tsubame","37.6731","138.8822","Japan","JP","JPN","Niigata","","76590","1392932108"
"Balkh","Balkh","36.7581","66.8981","Afghanistan","AF","AFG","Balkh","minor","77000","1004654094"
"Gandía","Gandia","38.9667","-0.1833","Spain","ES","ESP","Valencia","","75970","1724131747"
"Nipāni","Nipani","16.4800","74.7800","India","IN","IND","Mahārāshtra","","76666","1356358365"
"Morgantown","Morgantown","39.6383","-79.9464","United States","US","USA","West Virginia","","77193","1840005647"
"Siedlce","Siedlce","52.1650","22.2714","Poland","PL","POL","Mazowieckie","minor","76585","1616939180"
"Shūsh","Shush","32.1942","48.2436","Iran","IR","IRN","Khūzestān","minor","77148","1364876336"
"Bongabong","Bongabong","12.7469","121.4881","Philippines","PH","PHL","Oriental Mindoro","","76973","1608784210"
"Talakag","Talakag","8.2319","124.6025","Philippines","PH","PHL","Bukidnon","","77027","1608541231"
"Toyooka","Toyooka","35.5500","134.8167","Japan","JP","JPN","Hyōgo","","77005","1392135908"
"Nikkō","Nikko","36.7198","139.6982","Japan","JP","JPN","Tochigi","","77027","1392782537"
"Dongguan","Dongguan","39.0140","111.0768","China","CN","CHN","Shanxi","minor","75135","1156886549"
"Nagua","Nagua","19.3800","-69.8500","Dominican Republic","DO","DOM","Cibao Nordeste","minor","76933","1214449469"
"Al Musayyib","Al Musayyib","32.7786","44.2900","Iraq","IQ","IRQ","Bābil","minor","76454","1368000434"
"Ryūgasaki","Ryugasaki","35.9116","140.1823","Japan","JP","JPN","Ibaraki","","76055","1392150237"
"Pyapon","Pyapon","16.2860","95.6658","Myanmar","MM","MMR","Ayeyarwady","","76741","1104170402"
"Kahror Pakka","Kahror Pakka","29.6236","71.9167","Pakistan","PK","PAK","Punjab","","76098","1586974931"
"Caserta","Caserta","41.0667","14.3333","Italy","IT","ITA","Campania","minor","75561","1380614087"
"Mafra","Mafra","38.9411","-9.3319","Portugal","PT","PRT","Lisboa","minor","76685","1620095714"
"Montepuez","Montepuez","-13.1167","39.0000","Mozambique","MZ","MOZ","Cabo Delgado","","76139","1508552201"
"Aira","Aira","31.7283","130.6278","Japan","JP","JPN","Kagoshima","","76610","1392003374"
"Fāzilka","Fazilka","30.4030","74.0250","India","IN","IND","Punjab","","76492","1356509839"
"Sarqan","Sarqan","45.4100","79.9186","Kazakhstan","KZ","KAZ","","","76919","1398159801"
"Ait Ali","Ait Ali","30.1765","-9.4886","Morocco","MA","MAR","Souss-Massa","","76646","1504009062"
"Chernogorsk","Chernogorsk","53.8167","91.3000","Russia","RU","RUS","Khakasiya","","75067","1643871191"
"Tuban","Tuban","-6.9000","112.0500","Indonesia","ID","IDN","Jawa Timur","minor","76242","1360482232"
"Itapetinga","Itapetinga","-15.2489","-40.2478","Brazil","BR","BRA","Bahia","","76795","1076005350"
"El Viejo","El Viejo","12.6631","-87.1694","Nicaragua","NI","NIC","Chinandega","minor","76775","1558770292"
"Jablah","Jablah","35.3500","35.9167","Syria","SY","SYR","Al Lādhiqīyah","minor","75505","1760685267"
"Dschang","Dschang","5.4500","10.0667","Cameroon","CM","CMR","Ouest","","76524","1120569504"
"Balashov","Balashov","51.5469","43.1733","Russia","RU","RUS","Saratovskaya Oblast’","","76776","1643009402"
"Shostka","Shostka","51.8657","33.4766","Ukraine","UA","UKR","Sumska Oblast","minor","76788","1804764606"
"Gurupi","Gurupi","-11.7289","-49.0689","Brazil","BR","BRA","Tocantins","","76755","1076649238"
"Dartford","Dartford","51.4400","0.2200","United Kingdom","GB","GBR","Kent","","75000","1826535110"
"Asti","Asti","44.9000","8.2069","Italy","IT","ITA","Piedmont","minor","76211","1380024585"
"Cotuí","Cotui","19.0600","-70.1500","Dominican Republic","DO","DOM","Cibao Sur","minor","76554","1214299029"
"E’erguna","E'erguna","50.2411","120.1720","China","CN","CHN","Inner Mongolia","minor","76667","1156538056"
"So-Awa","So-Awa","6.4667","2.4167","Benin","BJ","BEN","Atlantique","","76315","1204255912"
"Wyoming","Wyoming","42.8908","-85.7066","United States","US","USA","Michigan","","76625","1840000374"
"Fancheng","Fancheng","39.1891","113.2729","China","CN","CHN","Shanxi","minor","76088","1156052290"
"Gravatá","Gravata","-8.2008","-35.5650","Brazil","BR","BRA","Pernambuco","","76458","1076214495"
"Keshod","Keshod","21.3000","70.2500","India","IN","IND","Gujarāt","","76193","1356488794"
"İdil","Idil","37.3410","41.8940","Turkey","TR","TUR","Şırnak","minor","76523","1792618230"
"Palma Soriano","Palma Soriano","20.2139","-75.9919","Cuba","CU","CUB","Santiago de Cuba","minor","76500","1192323011"
"Shājāpur","Shajapur","23.4264","76.2778","India","IN","IND","Madhya Pradesh","","76319","1356454991"
"Stakhanov","Stakhanov","48.5681","38.6586","Ukraine","UA","UKR","Luhanska Oblast","","75745","1804324480"
"Palo","Palo","11.1583","124.9917","Philippines","PH","PHL","Leyte","","76213","1608826823"
"Ciudad Sandino","Ciudad Sandino","12.1667","-86.3553","Nicaragua","NI","NIC","Managua","minor","75083","1558641363"
"Nabari","Nabari","34.6276","136.1084","Japan","JP","JPN","Mie","","75942","1392003150"
"Ibiúna","Ibiuna","-23.6564","-47.2225","Brazil","BR","BRA","São Paulo","","76432","1076173752"
"Venado Tuerto","Venado Tuerto","-33.7500","-61.9667","Argentina","AR","ARG","Santa Fe","","76432","1032284763"
"Sangolquí","Sangolqui","-0.3344","-78.4475","Ecuador","EC","ECU","Pichincha","","75080","1218715616"
"Anzhero-Sudzhensk","Anzhero-Sudzhensk","56.0833","86.0333","Russia","RU","RUS","Kemerovskaya Oblast’","","76217","1643779858"
"Troisdorf","Troisdorf","50.8161","7.1556","Germany","DE","DEU","North Rhine-Westphalia","","75222","1276488227"
"Chintāmani","Chintamani","13.4000","78.0660","India","IN","IND","Karnātaka","","76068","1356761079"
"Infanta","Infanta","14.7425","121.6494","Philippines","PH","PHL","Quezon","","76186","1608594453"
"Pasco","Pasco","46.2506","-119.1305","United States","US","USA","Washington","","76398","1840019888"
"Houmt Souk","Houmt Souk","33.8667","10.8500","Tunisia","TN","TUN","Médenine","","75904","1788550812"
"Santo Ângelo","Santo Angelo","-28.2989","-54.2628","Brazil","BR","BRA","Rio Grande do Sul","","76275","1076113692"
"Kai","Kai","35.6608","138.5158","Japan","JP","JPN","Yamanashi","","75337","1392003210"
"Rānāghāt","Ranaghat","23.1800","88.5800","India","IN","IND","West Bengal","","75365","1356577877"
"Toyomamachi-teraike","Toyomamachi-teraike","38.6918","141.1877","Japan","JP","JPN","Miyagi","","76164","1392470544"
"Nahāvand","Nahavand","34.1886","48.3769","Iran","IR","IRN","Hamadān","minor","76162","1364090972"
"Padre Hurtado","Padre Hurtado","-33.5667","-70.8333","Chile","CL","CHL","Región Metropolitana","","76219","1152529252"
"Daisen","Daisen","39.4531","140.4754","Japan","JP","JPN","Akita","","76164","1392642502"
"Berdychiv","Berdychiv","49.8919","28.6000","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","76229","1804788404"
"Lod","Lod","31.9519","34.8881","Israel","IL","ISR","Central","","75700","1376929543"
"Lins","Lins","-21.6786","-49.7425","Brazil","BR","BRA","São Paulo","","76092","1076798294"
"Surendranagar","Surendranagar","22.7000","71.6833","India","IN","IND","Gujarāt","","75755","1356002804"
"Southfield","Southfield","42.4765","-83.2605","United States","US","USA","Michigan","","76196","1840002431"
"Alcalá de Guadaira","Alcala de Guadaira","37.3333","-5.8500","Spain","ES","ESP","Andalusia","","75917","1724537443"
"Espinal","Espinal","4.2000","-74.8333","Colombia","CO","COL","Tolima","minor","75828","1170084447"
"Santa Rosa","Santa Rosa","15.4239","120.9389","Philippines","PH","PHL","Nueva Ecija","","75649","1608266790"
"Dayong","Dayong","22.4707","113.2854","China","CN","CHN","Guangdong","","74516","1156560925"
"Quixadá","Quixada","-4.9708","-39.0150","Brazil","BR","BRA","Ceará","","76105","1076173437"
"Zhlobin","Zhlobin","52.9000","30.0333","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","76078","1112494264"
"Tocumen","Tocumen","9.0800","-79.3800","Panama","PA","PAN","Panamá","minor","74952","1591862635"
"Pilar","Pilar","12.9244","123.6756","Philippines","PH","PHL","Sorsogon","","75793","1608564166"
"Jocotán","Jocotan","14.8167","-89.3833","Guatemala","GT","GTM","Chiquimula","minor","75578","1320875548"
"San Ramón de la Nueva Orán","San Ramon de la Nueva Oran","-23.1333","-64.3333","Argentina","AR","ARG","Salta","minor","76070","1032020207"
"Wausau","Wausau","44.9620","-89.6459","United States","US","USA","Wisconsin","","76016","1840002163"
"Gbawe","Gbawe","5.5767","-0.3108","Ghana","GH","GHA","Greater Accra","","74403","1288613578"
"Tailai","Tailai","46.3909","123.4161","China","CN","CHN","Heilongjiang","minor","75992","1156294977"
"Rochester Hills","Rochester Hills","42.6645","-83.1563","United States","US","USA","Michigan","","75990","1840002429"
"Villa Elisa","Villa Elisa","-25.5075","-57.5725","Paraguay","PY","PRY","Central","","75933","1600500180"
"Toba Tek Singh","Toba Tek Singh","30.9711","72.4825","Pakistan","PK","PAK","Punjab","minor","75943","1586875835"
"Tindivanam","Tindivanam","12.2267","79.6504","India","IN","IND","Tamil Nādu","","75405","1356132369"
"Loveland","Loveland","40.4166","-105.0623","United States","US","USA","Colorado","","75938","1840020152"
"Piotrków Trybunalski","Piotrkow Trybunalski","51.4000","19.6833","Poland","PL","POL","Łódzkie","minor","74905","1616450789"
"Jabuticabal","Jabuticabal","-21.2550","-48.3219","Brazil","BR","BRA","São Paulo","","75820","1076368201"
"Iju","Iju","6.6107","3.0857","Nigeria","NG","NGA","Ogun","","75000","1566694893"
"Ovalle","Ovalle","-30.6000","-71.2000","Chile","CL","CHL","Coquimbo","minor","75864","1152767334"
"Catacaos","Catacaos","-5.2653","-80.6750","Peru","PE","PER","Piura","","75870","1604229128"
"Rio Largo","Rio Largo","-9.4778","-35.8528","Brazil","BR","BRA","Alagoas","","75645","1076018887"
"Wilhelmshaven","Wilhelmshaven","53.5286","8.1056","Germany","DE","DEU","Lower Saxony","minor","75189","1276324633"
"Kengtung","Kengtung","21.2917","99.6083","Myanmar","MM","MMR","Shan State","","75865","1104395820"
"Xinglong","Xinglong","40.4146","117.4930","China","CN","CHN","Hebei","minor","75381","1156107741"
"Srīvilliputtūr","Srivilliputtur","9.5120","77.6330","India","IN","IND","Tamil Nādu","","75396","1356411981"
"Alexandria","Alexandria","31.2923","-92.4702","United States","US","USA","Louisiana","","75835","1840013873"
"Pinamungahan","Pinamungahan","10.2667","123.5833","Philippines","PH","PHL","Cebu","","75131","1608414270"
"Tiznit","Tiznit","29.7167","-9.7167","Morocco","MA","MAR","Souss-Massa","","74699","1504399838"
"Goiana","Goiana","-7.5608","-35.0028","Brazil","BR","BRA","Pernambuco","","75644","1076692421"
"Shadrinsk","Shadrinsk","56.1333","63.6500","Russia","RU","RUS","Kurganskaya Oblast’","","75348","1643030252"
"Zhongcheng","Zhongcheng","28.6014","103.9430","China","CN","CHN","Yunnan","minor","75425","1156106189"
"Puqiancun","Puqiancun","23.5723","114.6122","China","CN","CHN","Guangdong","","75006","1156538844"
"Bayreuth","Bayreuth","49.9481","11.5783","Germany","DE","DEU","Bavaria","minor","74657","1276275069"
"Sentani","Sentani","-2.5636","140.5146","Indonesia","ID","IDN","Papua","minor","75742","1360670830"
"Arjona","Arjona","10.2586","-75.3461","Colombia","CO","COL","Bolívar","minor","75615","1170602407"
"Tosu","Tosu","33.3833","130.5000","Japan","JP","JPN","Saga","","74673","1392003433"
"Kyōtanabe","Kyotanabe","34.8167","135.7667","Japan","JP","JPN","Kyōto","","73985","1392003459"
"Pittsburg","Pittsburg","38.0182","-121.8965","United States","US","USA","California","","75701","1840020282"
"Lüneburg","Luneburg","53.2525","10.4144","Germany","DE","DEU","Lower Saxony","minor","75599","1276001423"
"Hammond","Hammond","30.5061","-90.4563","United States","US","USA","Louisiana","","75688","1840013908"
"Dubna","Dubna","56.7333","37.1667","Russia","RU","RUS","Moskovskaya Oblast’","","75144","1643266302"
"South Jordan","South Jordan","40.5570","-111.9784","United States","US","USA","Utah","","75617","1840021385"
"Nakatsugawa","Nakatsugawa","35.4876","137.5006","Japan","JP","JPN","Gifu","","75500","1392764709"
"Battle Creek","Battle Creek","42.2985","-85.2296","United States","US","USA","Michigan","","75608","1840003176"
"Bethlehem","Bethlehem","40.6266","-75.3679","United States","US","USA","Pennsylvania","","75599","1840000986"
"Redenção","Redencao","-8.0289","-50.0308","Brazil","BR","BRA","Pará","","75556","1076392685"
"Fajardo","Fajardo","18.3331","-65.6589","Puerto Rico","PR","PRI","Puerto Rico","","75549","1630035668"
"Bonāb","Bonab","37.3428","46.0617","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","75332","1364781552"
"Sapiranga","Sapiranga","-29.6378","-51.0069","Brazil","BR","BRA","Rio Grande do Sul","","74985","1076553484"
"Gangammapeta","Gangammapeta","18.3330","84.0500","India","IN","IND","Andhra Pradesh","","75017","1356582081"
"Tirumangalam","Tirumangalam","9.8216","77.9891","India","IN","IND","Tamil Nādu","","75000","1356214298"
"Mangalagiri","Mangalagiri","16.4300","80.5500","India","IN","IND","Andhra Pradesh","","73613","1356861607"
"Kawartha Lakes","Kawartha Lakes","44.3500","-78.7500","Canada","CA","CAN","Ontario","","75423","1124000852"
"Bombo","Bombo","0.5778","32.5450","Uganda","UG","UGA","Luwero","","75000","1800926068"
"Libon","Libon","13.3000","123.4333","Philippines","PH","PHL","Albay","","75073","1608618102"
"Sasagawa","Sasagawa","37.2865","140.3727","Japan","JP","JPN","Fukushima","","75128","1392742964"
"Itapira","Itapira","-22.4361","-46.8217","Brazil","BR","BRA","São Paulo","","75234","1076706166"
"Carpina","Carpina","-7.8500","-35.2500","Brazil","BR","BRA","Pernambuco","","74858","1076005200"
"Ciudad Real","Ciudad Real","38.9833","-3.9167","Spain","ES","ESP","Castille-La Mancha","minor","75104","1724671059"
"Bugallon","Bugallon","15.9167","120.1833","Philippines","PH","PHL","Pangasinan","","74962","1608802354"
"Ambājogāi","Ambajogai","18.7300","76.3800","India","IN","IND","Mahārāshtra","","74844","1356117450"
"Gūdūr","Gudur","14.1473","79.8477","India","IN","IND","Andhra Pradesh","","74037","1356336545"
"Acacías","Acacias","3.9878","-73.7597","Colombia","CO","COL","Meta","minor","75252","1170420071"
"Apple Valley","Apple Valley","34.5352","-117.2109","United States","US","USA","California","","75311","1840021733"
"Īṭahari̇̄","Itahari","26.6631","87.2744","Nepal","NP","NPL","","","74501","1524696654"
"Pozorrubio","Pozorrubio","16.1167","120.5500","Philippines","PH","PHL","Pangasinan","","74729","1608447063"
"Ardakān","Ardakan","32.3061","54.0081","Iran","IR","IRN","Yazd","minor","75271","1364447507"
"Palencia","Palencia","14.6676","-90.3575","Guatemala","GT","GTM","Guatemala","minor","74839","1320202368"
"Afak","Afak","32.0625","45.2428","Iraq","IQ","IRQ","Al Qādisīyah","minor","75000","1368549091"
"Dabra","Dabra","25.8857","78.3322","India","IN","IND","Madhya Pradesh","","74807","1356520849"
"Tatebayashi","Tatebayashi","36.2448","139.5421","Japan","JP","JPN","Gunma","","73991","1392730385"
"Molina de Segura","Molina de Segura","38.0548","-1.2131","Spain","ES","ESP","Murcia","","74762","1724074824"
"Dorsten","Dorsten","51.6600","6.9642","Germany","DE","DEU","North Rhine-Westphalia","","74736","1276397774"
"Gela","Gela","37.0667","14.2500","Italy","IT","ITA","Sicilia","","74858","1380218145"
"Tura","Tura","25.5200","90.2200","India","IN","IND","Meghālaya","","74858","1356555974"
"Dĩ An","Di An","10.9039","106.7678","Vietnam","VN","VNM","Bình Dương","minor","73859","1704040724"
"Dalaguete","Dalaguete","9.7612","123.5349","Philippines","PH","PHL","Cebu","minor","74596","1608288277"
"Tongye","Tongye","37.9679","114.3782","China","CN","CHN","Hebei","","73428","1156141558"
"Wheeling","Wheeling","40.0752","-80.6951","United States","US","USA","West Virginia","","75065","1840005510"
"Flower Mound","Flower Mound","33.0343","-97.1146","United States","US","USA","Texas","","75050","1840022010"
"Estepona","Estepona","36.4264","-5.1472","Spain","ES","ESP","Andalusia","","74493","1724460361"
"Wisil","Wisil","5.4333","48.1167","Somalia","SO","SOM","Mudug","","75000","1706820984"
"Hoorn","Hoorn","52.6500","5.0667","Netherlands","NL","NLD","Noord-Holland","minor","73619","1528487371"
"Amakusa","Amakusa","32.4667","130.2000","Japan","JP","JPN","Kumamoto","","74861","1392003308"
"São Bento do Sul","Sao Bento do Sul","-26.2500","-49.3789","Brazil","BR","BRA","Santa Catarina","","74801","1076190456"
"Samundri","Samundri","31.0639","72.9611","Pakistan","PK","PAK","Punjab","","73911","1586905223"
"Esperanza","Esperanza","6.7167","124.5167","Philippines","PH","PHL","Sultan Kudarat","","74696","1608191259"
"Harrisonburg","Harrisonburg","38.4362","-78.8735","United States","US","USA","Virginia","","74901","1840003844"
"Balingasag","Balingasag","8.7500","124.7833","Philippines","PH","PHL","Misamis Oriental","","74385","1608537192"
"Toffo","Toffo","6.8500","2.0833","Benin","BJ","BEN","Atlantique","","74717","1204877588"
"Castrop-Rauxel","Castrop-Rauxel","51.5500","7.3167","Germany","DE","DEU","North Rhine-Westphalia","","73425","1276873073"
"Wandiwāsh","Wandiwash","12.5000","79.6200","India","IN","IND","Tamil Nādu","","74320","1356564734"
"Kandori","Kandori","35.6833","140.0333","Japan","JP","JPN","Chiba","","74469","1392989690"
"Péda-Houéyogbé","Peda-Houeyogbe","6.4500","1.9333","Benin","BJ","BEN","Mono","","74492","1204188588"
"Gravesend","Gravesend","51.4415","0.3685","United Kingdom","GB","GBR","Kent","","74000","1826609378"
"Cedar Park","Cedar Park","30.5105","-97.8197","United States","US","USA","Texas","","74741","1840019572"
"Darhan","Darhan","49.6167","106.3500","Mongolia","MN","MNG","Selenge","","74738","1496510995"
"Oshkosh","Oshkosh","44.0227","-88.5619","United States","US","USA","Wisconsin","","74737","1840002246"
"Piraçununga","Piracununga","-21.9961","-47.4258","Brazil","BR","BRA","São Paulo","","74587","1076467647"
"Tanguiéta","Tanguieta","10.6167","1.2667","Benin","BJ","BEN","Atacora","","74675","1204832080"
"Miki","Miki","34.7936","134.9931","Japan","JP","JPN","Hyōgo","","74255","1392000367"
"Grand-Bassam","Grand-Bassam","5.2000","-3.7333","Côte d'Ivoire","CI","CIV","Comoé","minor","73772","1384167408"
"Standerton","Standerton","-26.9500","29.2500","South Africa","ZA","ZAF","Mpumalanga","","74021","1710222465"
"Copacabana","Copacabana","6.3333","-75.5000","Colombia","CO","COL","Antioquia","minor","73574","1170002661"
"San Juan Opico","San Juan Opico","13.8833","-89.3500","El Salvador","SV","SLV","La Libertad","","74280","1222760219"
"Nizhyn","Nizhyn","51.0474","31.8805","Ukraine","UA","UKR","Chernihivska Oblast","minor","73283","1804714087"
"Aprilia","Aprilia","41.5833","12.6500","Italy","IT","ITA","Lazio","","74190","1380289716"
"Winchester","Winchester","39.1735","-78.1746","United States","US","USA","Virginia","","74593","1840001623"
"Bozüyük","Bozuyuk","39.9078","30.0367","Turkey","TR","TUR","Bilecik","minor","74441","1792194878"
"Skellefteå","Skelleftea","64.7500","20.9500","Sweden","SE","SWE","Västerbotten","minor","74402","1752233025"
"Canindé","Caninde","-4.3589","-39.3119","Brazil","BR","BRA","Ceará","","74473","1076519156"
"Dumangas","Dumangas","10.8333","122.7167","Philippines","PH","PHL","Iloilo","","73899","1608791064"
"Novoaltaysk","Novoaltaysk","53.3833","83.9333","Russia","RU","RUS","Altayskiy Kray","","73439","1643896344"
"Saquarema","Saquarema","-22.9200","-42.5100","Brazil","BR","BRA","Rio de Janeiro","","74234","1076235078"
"Ellicott City","Ellicott City","39.2774","-76.8345","United States","US","USA","Maryland","","74432","1840005802"
"Ouaké","Ouake","9.6617","1.3847","Benin","BJ","BEN","Donga","","74289","1204988582"
"Detmold","Detmold","51.9378","8.8833","Germany","DE","DEU","North Rhine-Westphalia","minor","74388","1276802316"
"Palmeira dos Índios","Palmeira dos Indios","-9.4069","-36.6278","Brazil","BR","BRA","Alagoas","","74208","1076723276"
"Pocatello","Pocatello","42.8724","-112.4647","United States","US","USA","Idaho","","74359","1840013376"
"Alicia","Alicia","16.7787","121.6972","Philippines","PH","PHL","Isabela","","73874","1608136235"
"João Monlevade","Joao Monlevade","-19.8100","-43.1739","Brazil","BR","BRA","Minas Gerais","","73610","1076127295"
"Pedro Brand","Pedro Brand","18.5667","-70.0911","Dominican Republic","DO","DOM","Ozama","","74016","1214244231"
"Majalengka","Majalengka","-6.8353","108.2278","Indonesia","ID","IDN","Jawa Barat","minor","73052","1360611152"
"Uspantán","Uspantan","15.3458","-90.8694","Guatemala","GT","GTM","Quiché","minor","74244","1320948711"
"Bongouanou","Bongouanou","6.6500","-4.2000","Côte d'Ivoire","CI","CIV","Lacs","minor","74281","1384380718"
"Rāmhormoz","Ramhormoz","31.2800","49.6036","Iran","IR","IRN","Khūzestān","minor","74285","1364637975"
"Tatsunochō-tominaga","Tatsunocho-tominaga","34.8508","134.5453","Japan","JP","JPN","Hyōgo","","73952","1392582566"
"Tarn Tāran","Tarn Taran","31.4519","74.9278","India","IN","IND","Punjab","","73638","1356155717"
"Khomeyn","Khomeyn","33.6422","50.0789","Iran","IR","IRN","Markazī","minor","74256","1364717218"
"Çınar","Cinar","37.7242","40.4150","Turkey","TR","TUR","Diyarbakır","minor","74207","1792038099"
"Hilton Head Island","Hilton Head Island","32.1896","-80.7499","United States","US","USA","South Carolina","","74248","1840016974"
"Curvelo","Curvelo","-18.7558","-44.4308","Brazil","BR","BRA","Minas Gerais","","74219","1076253887"
"Mineral’nyye Vody","Mineral'nyye Vody","44.2167","43.1333","Russia","RU","RUS","Stavropol’skiy Kray","","74141","1643357328"
"Cherry Hill","Cherry Hill","39.9034","-74.9947","United States","US","USA","New Jersey","","74203","1840081685"
"Landshut","Landshut","48.5397","12.1508","Germany","DE","DEU","Bavaria","minor","73150","1276485277"
"Almelo","Almelo","52.3500","6.6667","Netherlands","NL","NLD","Overijssel","minor","73132","1528084436"
"Mankono","Mankono","8.0500","-6.1833","Côte d'Ivoire","CI","CIV","Woroba","minor","74165","1384518405"
"Hāveri","Haveri","14.7935","75.4045","India","IN","IND","Karnātaka","","73805","1356283006"
"Meshgīn Shahr","Meshgin Shahr","38.3972","47.6739","Iran","IR","IRN","Ardabīl","minor","74109","1364069293"
"Kara-Balta","Kara-Balta","42.8333","73.8500","Kyrgyzstan","KG","KGZ","Chüy","minor","74133","1417201811"
"Vriddhāchalam","Vriddhachalam","11.5000","79.3300","India","IN","IND","Tamil Nādu","","73585","1356313384"
"Al Qā’im","Al Qa'im","34.3688","41.0945","Iraq","IQ","IRQ","Al Anbār","minor","74100","1368231138"
"Ouro Prêto","Ouro Preto","-20.3853","-43.5036","Brazil","BR","BRA","Minas Gerais","","74036","1076240779"
"Yelabuga","Yelabuga","55.7667","52.0833","Russia","RU","RUS","Tatarstan","","74031","1643438030"
"Kallakkurichchi","Kallakkurichchi","11.7380","78.9620","India","IN","IND","Tamil Nādu","","73528","1356128673"
"Majadahonda","Majadahonda","40.4728","-3.8722","Spain","ES","ESP","Madrid","","72179","1724482447"
"Sharm ash Shaykh","Sharm ash Shaykh","27.9122","34.3297","Egypt","EG","EGY","Janūb Sīnā’","","74000","1818039222"
"Brookes Point","Brookes Point","8.7833","117.8333","Philippines","PH","PHL","Palawan","","73994","1608472411"
"Santa Rosa","Santa Rosa","-27.8708","-54.4808","Brazil","BR","BRA","Rio Grande do Sul","","73882","1076071027"
"Konin","Konin","52.2167","18.2667","Poland","PL","POL","Wielkopolskie","minor","73176","1616883533"
"Mansfield","Mansfield","40.7656","-82.5275","United States","US","USA","Ohio","","74021","1840000960"
"Woodbury","Woodbury","44.9057","-92.9230","United States","US","USA","Minnesota","","74014","1840009998"
"Arnsberg","Arnsberg","51.3833","8.0833","Germany","DE","DEU","North Rhine-Westphalia","","73628","1276285824"
"Prince George","Prince George","53.9169","-122.7494","Canada","CA","CAN","British Columbia","","74003","1124733292"
"Kostiantynivka","Kostiantynivka","48.5333","37.7167","Ukraine","UA","UKR","Donetska Oblast","","72888","1804423855"
"Samadiāla","Samadiala","21.3422","71.3063","India","IN","IND","Gujarāt","","73695","1356166137"
"Nova Serrana","Nova Serrana","-19.8667","-44.9833","Brazil","BR","BRA","Minas Gerais","","73699","1076026436"
"Lala","Lala","7.9667","123.7500","Philippines","PH","PHL","Lanao del Norte","","73425","1608312825"
"Lehi","Lehi","40.4136","-111.8725","United States","US","USA","Utah","","73911","1840020168"
"Yurihonjō","Yurihonjo","39.3859","140.0488","Japan","JP","JPN","Akita","","73840","1392820312"
"Inuyama","Inuyama","35.3786","136.9445","Japan","JP","JPN","Gifu","","72926","1392393169"
"Türkoğlu","Turkoglu","37.3914","36.8522","Turkey","TR","TUR","Kahramanmaraş","minor","73770","1792828944"
"Cunhinga","Cunhinga","-12.2333","16.7833","Angola","AO","AGO","Bié","","73826","1024704961"
"Bawku","Bawku","11.0500","-0.2333","Ghana","GH","GHA","Upper East","","73594","1288067377"
"Yegoryevsk","Yegoryevsk","55.3833","39.0500","Russia","RU","RUS","Moskovskaya Oblast’","","73766","1643266841"
"Kasama","Kasama","36.3452","140.3043","Japan","JP","JPN","Ibaraki","","73521","1392308767"
"Bolingbrook","Bolingbrook","41.6901","-88.1021","United States","US","USA","Illinois","","73807","1840011482"
"Chaigoubu","Chaigoubu","40.6687","114.4157","China","CN","CHN","Hebei","minor","73028","1156384517"
"Beyşehir","Beysehir","37.6764","31.7261","Turkey","TR","TUR","Konya","minor","73768","1792946985"
"Gobindgarh","Gobindgarh","30.6709","76.3019","India","IN","IND","Punjab","","73131","1356126630"
"Tupi","Tupi","6.3333","124.9500","Philippines","PH","PHL","South Cotabato","","73459","1608022150"
"Siuna","Siuna","13.7347","-84.7783","Nicaragua","NI","NIC","Costa Caribe Norte","minor","73730","1558482501"
"Brick","Brick","40.0600","-74.1099","United States","US","USA","New Jersey","","73745","1840081606"
"Dale City","Dale City","38.6473","-77.3459","United States","US","USA","Virginia","","73716","1840006094"
"Pattukkottai","Pattukkottai","10.4300","79.3200","India","IN","IND","Tamil Nādu","","73135","1356044693"
"Mérignac","Merignac","44.8386","-0.6436","France","FR","FRA","Nouvelle-Aquitaine","","72197","1250010536"
"Missouri City","Missouri City","29.5630","-95.5365","United States","US","USA","Texas","","73682","1840020950"
"Linares","Linares","-35.8500","-71.6000","Chile","CL","CHL","Maule","minor","73602","1152783281"
"Mysłowice","Myslowice","50.2333","19.1333","Poland","PL","POL","Śląskie","minor","72553","1616757185"
"Jingzhou","Jingzhou","37.6911","116.2672","China","CN","CHN","Hebei","minor","72806","1156058160"
"Irecê","Irece","-11.3039","-41.8558","Brazil","BR","BRA","Bahia","","73380","1076348562"
"Shrewsbury","Shrewsbury","52.7080","-2.7540","United Kingdom","GB","GBR","Shropshire","","71715","1826283137"
"Quillota","Quillota","-32.8667","-71.2500","Chile","CL","CHL","Valparaíso","minor","73261","1152185579"
"Troitsk","Troitsk","54.0833","61.5667","Russia","RU","RUS","Chelyabinskaya Oblast’","","73421","1643374193"
"Bula","Bula","13.4694","123.2775","Philippines","PH","PHL","Camarines Sur","","73143","1608911288"
"Picos","Picos","-7.0769","-41.4669","Brazil","BR","BRA","Piauí","","73414","1076752928"
"El Hamma","El Hamma","33.8864","9.7951","Tunisia","TN","TUN","Gabès","","73512","1788376550"
"Bandar-e Genāveh","Bandar-e Genaveh","29.5839","50.5189","Iran","IR","IRN","Būshehr","minor","73472","1364268734"
"Lanxi","Lanxi","46.2664","126.2760","China","CN","CHN","Heilongjiang","minor","72528","1156269575"
"Paterna","Paterna","39.5028","-0.4406","Spain","ES","ESP","Valencia","","71880","1724974310"
"Bagan Si Api-api","Bagan Si Api-api","2.1667","100.8167","Indonesia","ID","IDN","Riau","minor","73360","1360940689"
"Jose Abad Santos","Jose Abad Santos","5.9167","125.6500","Philippines","PH","PHL","Davao Occidental","","73381","1608681407"
"La Estrella","La Estrella","6.1667","-75.6667","Colombia","CO","COL","Antioquia","minor","71545","1170386533"
"Mackay","Mackay","-21.1411","149.1858","Australia","AU","AUS","Queensland","","72900","1036507374"
"Brakpan","Brakpan","-26.2353","28.3700","South Africa","ZA","ZAF","Gauteng","","73080","1710291121"
"Lake Jackson","Lake Jackson","29.0516","-95.4521","United States","US","USA","Texas","","73478","1840020980"
"Zhuolu","Zhuolu","40.3753","115.2150","China","CN","CHN","Hebei","minor","72538","1156059000"
"Kirovo-Chepetsk","Kirovo-Chepetsk","58.5500","50.0000","Russia","RU","RUS","Kirovskaya Oblast’","","72071","1643630912"
"Vinhedo","Vinhedo","-23.0300","-46.9750","Brazil","BR","BRA","São Paulo","","72550","1076383405"
"Södertälje","Sodertalje","59.1958","17.6281","Sweden","SE","SWE","Stockholm","minor","72704","1752403970"
"Lüdenscheid","Ludenscheid","51.2167","7.6333","Germany","DE","DEU","North Rhine-Westphalia","minor","72611","1276839426"
"Ostrowiec Świętokrzyski","Ostrowiec Swietokrzyski","50.9333","21.4000","Poland","PL","POL","Świętokrzyskie","minor","72001","1616984527"
"Altoona","Altoona","40.5082","-78.4007","United States","US","USA","Pennsylvania","","73374","1840001080"
"Chapadinha","Chapadinha","-3.7419","-43.3600","Brazil","BR","BRA","Maranhão","","73350","1076901443"
"Shibukawa","Shibukawa","36.3894","139.0633","Japan","JP","JPN","Gunma","","73064","1392987761"
"Sault Ste. Marie","Sault Ste. Marie","46.5333","-84.3500","Canada","CA","CAN","Ontario","","73368","1124810690"
"Nepālgañj","Nepalganj","28.0500","81.6167","Nepal","NP","NPL","","","72503","1524207917"
"Belek","Belek","36.8500","31.0500","Turkey","TR","TUR","Antalya","","73260","1792081004"
"Carles","Carles","11.5667","123.1333","Philippines","PH","PHL","Iloilo","","72637","1608210040"
"Reconquista","Reconquista","-29.1443","-59.6438","Argentina","AR","ARG","Santa Fe","minor","73293","1032927391"
"Ostend","Ostend","51.2258","2.9194","Belgium","BE","BEL","Flanders","minor","71557","1056454956"
"San Fernando","San Fernando","10.1667","123.7000","Philippines","PH","PHL","Cebu","","72224","1608073409"
"Farroupilha","Farroupilha","-29.2250","-51.3478","Brazil","BR","BRA","Rio Grande do Sul","","73061","1076221850"
"Wenping","Wenping","27.1930","103.5461","China","CN","CHN","Yunnan","minor","71943","1156139149"
"Sangāreddi","Sangareddi","17.6294","78.0917","India","IN","IND","Andhra Pradesh","","72344","1356177714"
"Tokār","Tokar","18.4253","37.7292","Sudan","SD","SDN","Red Sea","","73202","1729512860"
"Shakargarh","Shakargarh","32.2628","75.1583","Pakistan","PK","PAK","Punjab","","73160","1586086639"
"Tan-Tan","Tan-Tan","28.4333","-11.1000","Morocco","MA","MAR","Guelmim-Oued Noun","","73209","1504164174"
"Sambrial","Sambrial","32.4750","74.3522","Pakistan","PK","PAK","Punjab","","71766","1586539893"
"Chapayevsk","Chapayevsk","52.9833","49.7167","Russia","RU","RUS","Samarskaya Oblast’","","72778","1643027172"
"Santa Rosa de Cabal","Santa Rosa de Cabal","4.8667","-75.6167","Colombia","CO","COL","Risaralda","minor","73028","1170419801"
"Moa","Moa","20.6397","-74.9175","Cuba","CU","CUB","Holguín","minor","73053","1192288426"
"Ōtawara","Otawara","36.8711","140.0156","Japan","JP","JPN","Tochigi","","72923","1392953593"
"Doral","Doral","25.8152","-80.3565","United States","US","USA","Florida","","73126","1840015148"
"Owensboro","Owensboro","37.7575","-87.1172","United States","US","USA","Kentucky","","73115","1840014340"
"Baghlān","Baghlan","36.1328","68.7000","Afghanistan","AF","AFG","Baghlān","minor","73031","1004943705"
"Naqadeh","Naqadeh","36.9547","45.3881","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","72975","1364695268"
"Luján de Cuyo","Lujan de Cuyo","-32.9980","-68.8730","Argentina","AR","ARG","Mendoza","minor","73058","1032844765"
"Esbjerg","Esbjerg","55.4833","8.4500","Denmark","DK","DNK","Syddanmark","minor","72261","1208757169"
"Bacacay","Bacacay","13.2925","123.7917","Philippines","PH","PHL","Albay","","72485","1608847770"
"Morong","Morong","14.5119","121.2389","Philippines","PH","PHL","Rizal","","71151","1608636821"
"Sanwal","Sanwal","27.6061","83.6606","Nepal","NP","NPL","","","72621","1524197976"
"Catanauan","Catanauan","13.5917","122.3250","Philippines","PH","PHL","Quezon","","72752","1608072794"
"Alegrete","Alegrete","-29.7839","-55.7911","Brazil","BR","BRA","Rio Grande do Sul","","73028","1076174745"
"Çatalca","Catalca","41.1417","28.4631","Turkey","TR","TUR","İstanbul","minor","72966","1792373818"
"Vsevolozhsk","Vsevolozhsk","60.0333","30.6667","Russia","RU","RUS","Leningradskaya Oblast’","","72864","1643253701"
"Türkmenbaşy","Turkmenbasy","40.0167","52.9667","Turkmenistan","TM","TKM","Balkan","","73000","1795987646"
"Sotik","Sotik","-0.6800","35.1200","Kenya","KE","KEN","Bomet","","71285","1404807525"
"Palín","Palin","14.4039","-90.6986","Guatemala","GT","GTM","Escuintla","minor","72203","1320669279"
"Padre Las Casas","Padre Las Casas","-38.7667","-72.6000","Chile","CL","CHL","Araucanía","","72892","1152058226"
"San Vicente del Caguán","San Vicente del Caguan","2.1167","-74.7667","Colombia","CO","COL","Caquetá","minor","72949","1170922792"
"Belovo","Belovo","54.4167","86.3000","Russia","RU","RUS","Kemerovskaya Oblast’","","72519","1643699718"
"Xiangjiaba","Xiangjiaba","28.6282","104.4211","China","CN","CHN","Yunnan","minor","72283","1156235765"
"Virudunagar","Virudunagar","9.5680","77.9624","India","IN","IND","Tamil Nādu","","72296","1356157444"
"Saint-Nazaire","Saint-Nazaire","47.2736","-2.2139","France","FR","FRA","Pays de la Loire","minor","71394","1250133864"
"Bakıxanov","Bakixanov","40.4217","49.9644","Azerbaijan","AZ","AZE","Bakı","","71600","1031114052"
"Hương Trà","Huong Tra","16.4675","107.5181","Vietnam","VN","VNM","Thừa Thiên-Huế","minor","72677","1704200603"
"Três Corações","Tres Coracoes","-21.6947","-45.2553","Brazil","BR","BRA","Minas Gerais","","72765","1076131585"
"Tocoa","Tocoa","15.6833","-86.0000","Honduras","HN","HND","Colón","","72847","1340024758"
"Pacatuba","Pacatuba","-3.9839","-38.6200","Brazil","BR","BRA","Ceará","","72299","1076428186"
"Siruguppa","Siruguppa","15.6000","77.0000","India","IN","IND","Karnātaka","","72503","1356620756"
"Amparo","Amparo","-22.7031","-46.7650","Brazil","BR","BRA","São Paulo","","72677","1076656250"
"Crateús","Crateus","-5.1778","-40.6778","Brazil","BR","BRA","Ceará","","72812","1076897989"
"Pavia","Pavia","45.1853","9.1550","Italy","IT","ITA","Lombardy","minor","72773","1380571928"
"Ede","Ede","52.0500","5.6667","Netherlands","NL","NLD","Gelderland","minor","72460","1528361626"
"Aracati","Aracati","-4.5619","-37.7700","Brazil","BR","BRA","Ceará","","72727","1076708817"
"Brandenburg","Brandenburg","52.4167","12.5333","Germany","DE","DEU","Brandenburg","minor","72461","1276372220"
"Broomfield","Broomfield","39.9542","-105.0526","United States","US","USA","Colorado","","72697","1840018781"
"Keshan","Keshan","48.0263","125.8659","China","CN","CHN","Heilongjiang","minor","72403","1156775094"
"Yafran","Yafran","32.0629","12.5267","Libya","LY","LBY","Al Jabal al Gharbī","","72662","1434967497"
"Redlands","Redlands","34.0512","-117.1712","United States","US","USA","California","","72649","1840020412"
"Sipalay","Sipalay","9.7500","122.4000","Philippines","PH","PHL","Negros Occidental","","72448","1608396492"
"Penafiel","Penafiel","41.2060","-8.2840","Portugal","PT","PRT","Porto","minor","72265","1620996272"
"Camalig","Camalig","13.1333","123.6667","Philippines","PH","PHL","Albay","","72042","1608361656"
"Cleveland","Cleveland","35.1817","-84.8707","United States","US","USA","Tennessee","","72589","1840014603"
"Pililla","Pililla","14.4833","121.3000","Philippines","PH","PHL","Rizal","","71535","1608814633"
"La Lima","La Lima","15.4330","-87.9170","Honduras","HN","HND","Cortés","","71910","1340766612"
"Concepción del Uruguay","Concepcion del Uruguay","-32.4833","-58.2333","Argentina","AR","ARG","Entre Ríos","minor","72528","1032999059"
"Belo Jardim","Belo Jardim","-8.3358","-36.4239","Brazil","BR","BRA","Pernambuco","","72432","1076631616"
"Pigcawayan","Pigcawayan","7.2833","124.4333","Philippines","PH","PHL","Cotabato","","72371","1608129711"
"Aschaffenburg","Aschaffenburg","49.9667","9.1500","Germany","DE","DEU","Bavaria","minor","71381","1276069907"
"Dambulla","Dambulla","7.8578","80.6525","Sri Lanka","LK","LKA","Central","","72306","1144340825"
"Zogbodomé","Zogbodome","7.0833","2.1000","Benin","BJ","BEN","Zou","","72338","1204008692"
"Melton","Melton","-37.6833","144.5833","Australia","AU","AUS","Victoria","","72177","1036007778"
"Long Mỹ","Long My","9.6814","105.5708","Vietnam","VN","VNM","Hậu Giang","minor","71963","1704531917"
"Piła","Pila","53.1500","16.7333","Poland","PL","POL","Wielkopolskie","minor","71735","1616414354"
"Ostrów Wielkopolski","Ostrow Wielkopolski","51.6494","17.8164","Poland","PL","POL","Wielkopolskie","minor","70725","1616691422"
"Dothan","Dothan","31.2336","-85.4070","United States","US","USA","Alabama","","72389","1840001518"
"Patzún","Patzun","14.6833","-91.0167","Guatemala","GT","GTM","Chimaltenango","minor","71790","1320400081"
"Cajamar","Cajamar","-23.3558","-46.8769","Brazil","BR","BRA","São Paulo","","71805","1076369653"
"Nilanga","Nilanga","18.1161","76.7525","India","IN","IND","Mahārāshtra","","72000","1356358329"
"Goya","Goya","-29.1333","-59.2500","Argentina","AR","ARG","Corrientes","minor","72304","1032251925"
"Gumaca","Gumaca","13.9210","122.1002","Philippines","PH","PHL","Quezon","","71942","1608989555"
"Warora","Warora","20.2300","79.0008","India","IN","IND","Mahārāshtra","","72126","1356657499"
"Turbaná","Turbana","10.2833","-75.4500","Colombia","CO","COL","Bolívar","minor","72168","1170487782"
"Lajeado","Lajeado","-29.4669","-51.9608","Brazil","BR","BRA","Rio Grande do Sul","","71445","1076361223"
"Ishioka","Ishioka","36.1908","140.2872","Japan","JP","JPN","Ibaraki","","71851","1392188897"
"Casa Nova","Casa Nova","-9.1619","-40.9708","Brazil","BR","BRA","Bahia","","72172","1076947710"
"Redmond","Redmond","47.6763","-122.1169","United States","US","USA","Washington","","72166","1840019835"
"Saymayl","Saymayl","36.8583","42.8501","Iraq","IQ","IRQ","Dahūk","minor","71557","1368902174"
"Shahrixon","Shahrixon","40.7167","72.0500","Uzbekistan","UZ","UZB","Andijon","minor","71400","1860958957"
"Nanfengcun","Nanfengcun","23.7460","111.7966","China","CN","CHN","Guangdong","","71479","1156559294"
"Colón","Colon","22.7225","-80.9067","Cuba","CU","CUB","Matanzas","minor","72000","1192866771"
"Farshūţ","Farshut","26.0549","32.1640","Egypt","EG","EGY","Qinā","","70250","1818845921"
"Tibati","Tibati","6.4667","12.6333","Cameroon","CM","CMR","Adamaoua","","72081","1120299131"
"Cremona","Cremona","45.1333","10.0333","Italy","IT","ITA","Lombardy","minor","72077","1380496758"
"Framingham","Framingham","42.3085","-71.4368","United States","US","USA","Massachusetts","","72089","1840003131"
"Myaydo","Myaydo","19.3667","95.2167","Myanmar","MM","MMR","Magway","","72000","1104898210"
"Santo Tirso","Santo Tirso","41.3333","-8.4667","Portugal","PT","PRT","Porto","minor","71530","1620557735"
"Calauag","Calauag","13.9575","122.2875","Philippines","PH","PHL","Quezon","","71809","1608371509"
"Al Līth","Al Lith","20.1500","40.2667","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","72000","1682243537"
"Belo Tsiribihina","Belo Tsiribihina","-19.7000","44.5500","Madagascar","MG","MDG","Toliara","","72000","1450104370"
"Nadi","Nadi","-17.8000","177.4167","Fiji","FJ","FJI","Ba","","71048","1242357822"
"Merzifon","Merzifon","40.8750","35.4633","Turkey","TR","TUR","Amasya","minor","71916","1792470511"
"Jackson","Jackson","35.6538","-88.8354","United States","US","USA","Tennessee","","71983","1840014528"
"Kawm Umbū","Kawm Umbu","24.4667","32.9500","Egypt","EG","EGY","Aswān","","71596","1818527731"
"Mun’gyŏng","Mun'gyong","36.5939","128.2014","South Korea","KR","KOR","Gyeongbuk","","71863","1410822576"
"Yukuhashi","Yukuhashi","33.7333","130.9833","Japan","JP","JPN","Fukuoka","","70922","1392003279"
"Quixeramobim","Quixeramobim","-5.1989","-39.2928","Brazil","BR","BRA","Ceará","","71887","1076495439"
"Janesville","Janesville","42.6854","-89.0135","United States","US","USA","Wisconsin","","71907","1840002467"
"Valença","Valenca","-22.2458","-43.7000","Brazil","BR","BRA","Rio de Janeiro","","71843","1076574787"
"Mandlā","Mandla","22.6000","80.3800","India","IN","IND","Madhya Pradesh","","71579","1356069599"
"Bouaflé","Bouafle","6.9833","-5.7500","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","minor","71792","1384274339"
"Rāyadrug","Rayadrug","14.6997","76.8524","India","IN","IND","Andhra Pradesh","","71445","1356086451"
"Binalbagan","Binalbagan","10.2000","122.8667","Philippines","PH","PHL","Negros Occidental","","71407","1608949607"
"Talibon","Talibon","10.1167","124.2833","Philippines","PH","PHL","Bohol","","71272","1608420317"
"Parang","Parang","5.9167","120.9167","Philippines","PH","PHL","Sulu","","71495","1608491577"
"Juana Díaz","Juana Diaz","18.0532","-66.5044","Puerto Rico","PR","PRI","Puerto Rico","","71759","1630023583"
"Lubin","Lubin","51.4000","16.2000","Poland","PL","POL","Dolnośląskie","minor","70016","1616748050"
"Dondo","Dondo","-9.6942","14.4228","Angola","AO","AGO","Cuanza-Norte","","71715","1024323066"
"Florida","Florida","21.5294","-78.2228","Cuba","CU","CUB","Camagüey","minor","71676","1192766774"
"Goa","Goa","13.6983","123.4889","Philippines","PH","PHL","Camarines Sur","","71368","1608724845"
"Alexandroúpoli","Alexandroupoli","40.8500","25.8667","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","71601","1300949419"
"Bocholt","Bocholt","51.8333","6.6167","Germany","DE","DEU","North Rhine-Westphalia","","71099","1276001655"
"Carpi","Carpi","44.7833","10.8833","Italy","IT","ITA","Emilia-Romagna","","71148","1380990747"
"Katori","Katori","35.8977","140.4992","Japan","JP","JPN","Chiba","","71391","1392702737"
"Rāyagada","Rayagada","19.1700","83.4200","India","IN","IND","Odisha","","71308","1356978742"
"Tenkāsi","Tenkasi","8.9564","77.3152","India","IN","IND","Tamil Nādu","","70545","1356465590"
"Lisburn","Lisburn","54.5167","-6.0333","United Kingdom","GB","GBR","Lisburn and Castlereagh","","71465","1826667481"
"Quartu Sant’Elena","Quartu Sant'Elena","39.2413","9.1839","Italy","IT","ITA","Sardegna","","70879","1380157297"
"Bunbury","Bunbury","-33.3272","115.6369","Australia","AU","AUS","Western Australia","","71090","1036561011"
"Sarnia","Sarnia","42.9994","-82.3089","Canada","CA","CAN","Ontario","","71594","1124509835"
"Wood Buffalo","Wood Buffalo","57.6042","-111.3284","Canada","CA","CAN","Alberta","","71589.00","1124001123"
"Benidorm","Benidorm","38.5342","-0.1314","Spain","ES","ESP","Valencia","","69738","1724178356"
"Bāpatla","Bapatla","15.9044","80.4675","India","IN","IND","Andhra Pradesh","","70777","1356828422"
"Izmail","Izmail","45.3517","28.8364","Ukraine","UA","UKR","Odeska Oblast","minor","71411","1804652754"
"Mārkāpur","Markapur","15.7300","79.2800","India","IN","IND","Andhra Pradesh","","71092","1356101278"
"Shujaabad","Shujaabad","29.8803","71.2950","Pakistan","PK","PAK","Punjab","","70595","1586679997"
"Orani","Orani","14.8000","120.5333","Philippines","PH","PHL","Bataan","","70342","1608338403"
"San Francisco del Rincón","San Francisco del Rincon","21.0228","-101.8600","Mexico","MX","MEX","Guanajuato","minor","71139","1484674455"
"Ganthier","Ganthier","18.5333","-72.0667","Haiti","HT","HTI","Ouest","","71261","1332549888"
"Kaukhāli","Kaukhali","22.6333","90.0667","Bangladesh","BD","BGD","Barishal","","70515","1050299194"
"Villa Tunari","Villa Tunari","-16.9747","-65.4203","Bolivia","BO","BOL","Cochabamba","","71386","1068987267"
"Mansfield","Mansfield","32.5690","-97.1213","United States","US","USA","Texas","","71375","1840020702"
"Malaut","Malaut","30.1900","74.4990","India","IN","IND","Punjab","","70958","1356603442"
"Izalco","Izalco","13.7333","-89.6667","El Salvador","SV","SLV","Sonsonate","","70959","1222502574"
"Ratangarh","Ratangarh","28.0787","74.6219","India","IN","IND","Rājasthān","","71124","1356344775"
"Konongo","Konongo","6.6167","-1.2167","Ghana","GH","GHA","Ashanti","","71238","1288795145"
"Huwei","Huwei","23.7200","120.4354","Taiwan","TW","TWN","Yunlin","","70300","1158896051"
"Placetas","Placetas","22.3158","-79.6556","Cuba","CU","CUB","Villa Clara","minor","71208","1192546991"
"Verkhnyaya Pyshma","Verkhnyaya Pyshma","56.9761","60.5944","Russia","RU","RUS","Sverdlovskaya Oblast’","","71241","1643491878"
"Waukesha","Waukesha","43.0087","-88.2464","United States","US","USA","Wisconsin","","71307","1840003008"
"Warzat","Warzat","30.9167","-6.9167","Morocco","MA","MAR","Drâa-Tafilalet","","71067","1504381507"
"Cabudare","Cabudare","10.0331","-69.2633","Venezuela","VE","VEN","Lara","minor","70578","1862114226"
"Melong","Melong","5.1211","9.9528","Cameroon","CM","CMR","Littoral","","71189","1120198694"
"Yinying","Yinying","37.9410","113.5602","China","CN","CHN","Shanxi","minor","70614","1156916738"
"Mauban","Mauban","14.1911","121.7308","Philippines","PH","PHL","Quezon","","71081","1608083644"
"Ayvalık","Ayvalik","39.3167","26.6944","Turkey","TR","TUR","Balıkesir","minor","71063","1792417776"
"Gibara","Gibara","21.1072","-76.1367","Cuba","CU","CUB","Holguín","minor","71126","1192391794"
"Érd","Erd","47.3784","18.9220","Hungary","HU","HUN","Pest","minor","70063","1348575496"
"Entebbe","Entebbe","0.0500","32.4600","Uganda","UG","UGA","Wakiso","","69958","1800535915"
"Baiquan","Baiquan","47.6018","126.0819","China","CN","CHN","Heilongjiang","minor","70472","1156009118"
"Fatehābād","Fatehabad","29.5200","75.4500","India","IN","IND","Haryāna","","70777","1356065839"
"Aliaga","Aliaga","15.5036","120.8450","Philippines","PH","PHL","Nueva Ecija","","70363","1608044866"
"An Nu‘mānīyah","An Nu\`maniyah","32.5000","45.3333","Iraq","IQ","IRQ","Wāsiţ","minor","71000","1368555186"
"Minxiong","Minxiong","23.5504","120.4457","Taiwan","TW","TWN","Chiayi","","70316","1158122397"
"Corozal","Corozal","9.3333","-75.2500","Colombia","CO","COL","Sucre","minor","70853","1170958484"
"Wenatchee","Wenatchee","47.4360","-120.3295","United States","US","USA","Washington","","71119","1840021074"
"Bundaberg","Bundaberg","-24.8661","152.3494","Australia","AU","AUS","Queensland","","70540","1036377333"
"Thohoyandou","Thohoyandou","-22.9500","30.4833","South Africa","ZA","ZAF","Limpopo","","69453","1710219075"
"Djemmal","Djemmal","35.6400","10.7600","Tunisia","TN","TUN","Monastir","","70587","1788132714"
"Daytona Beach","Daytona Beach","29.1995","-81.0982","United States","US","USA","Florida","","71041","1840015068"
"Castle Rock","Castle Rock","39.3763","-104.8535","United States","US","USA","Colorado","","71037","1840022455"
"Pan’an","Pan'an","34.7575","105.1135","China","CN","CHN","Gansu","","70072","1156007648"
"Kopargo","Kopargo","9.8375","1.5481","Benin","BJ","BEN","Donga","","70938","1204451155"
"Sankaranayinār Kovil","Sankaranayinar Kovil","9.1600","77.5500","India","IN","IND","Tamil Nādu","","70574","1356966080"
"Moriya","Moriya","35.9514","139.9754","Japan","JP","JPN","Ibaraki","","68992","1392003393"
"Rittō","Ritto","35.0167","136.0000","Japan","JP","JPN","Shiga","","69598","1392932001"
"Hujra Shah Muqim","Hujra Shah Muqim","30.7408","73.8219","Pakistan","PK","PAK","Punjab","","70204","1586402344"
"Tumauini","Tumauini","17.2667","121.8000","Philippines","PH","PHL","Isabela","","70743","1608685501"
"Ejura","Ejura","7.3833","-1.3667","Ghana","GH","GHA","Ashanti","","70807","1288797018"
"San Marcos","San Marcos","29.8734","-97.9356","United States","US","USA","Texas","","70864","1840022188"
"Badvel","Badvel","14.7500","79.0500","India","IN","IND","Andhra Pradesh","","70626","1356145368"
"Caçador","Cacador","-26.7750","-51.0150","Brazil","BR","BRA","Santa Catarina","","70762","1076703793"
"Esperanza","Esperanza","19.5800","-70.9900","Dominican Republic","DO","DOM","Cibao Noroeste","","70588","1214737056"
"Union City","Union City","37.6032","-122.0181","United States","US","USA","California","","70828","1840021539"
"Kabirwala","Kabirwala","30.4053","71.8681","Pakistan","PK","PAK","Punjab","","70123","1586254267"
"Châteauguay","Chateauguay","45.3800","-73.7500","Canada","CA","CAN","Quebec","","70812","1124437897"
"Novomoskovsk","Novomoskovsk","48.6328","35.2239","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","70749","1804952905"
"Rizal","Rizal","15.7100","121.1047","Philippines","PH","PHL","Nueva Ecija","","70196","1608031788"
"Zenica","Zenica","44.2017","17.9039","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","70553","1070047832"
"Loulé","Loule","37.1500","-8.0000","Portugal","PT","PRT","Faro","minor","70622","1620028270"
"Stafford","Stafford","52.8070","-2.1170","United Kingdom","GB","GBR","Staffordshire","","70592","1826088347"
"Vālpārai","Valparai","10.3276","76.9553","India","IN","IND","Tamil Nādu","","70589","1356065507"
"Kladno","Kladno","50.1500","14.1000","Czechia","CZ","CZE","Středočeský Kraj","","68896","1203557079"
"Altamura","Altamura","40.8167","16.5500","Italy","IT","ITA","Puglia","","70514","1380125300"
"Sūratgarh","Suratgarh","29.3177","73.8989","India","IN","IND","Rājasthān","","70536","1356840891"
"Chekhov","Chekhov","55.1500","37.4500","Russia","RU","RUS","Moskovskaya Oblast’","","70548","1643988618"
"Eldersburg","Eldersburg","39.4041","-76.9528","United States","US","USA","Maryland","","70639","1840005698"
"Xangongo","Xangongo","-16.7467","14.9747","Angola","AO","AGO","Cunene","","70568","1024323582"
"Togoch’alē","Togoch'ale","9.6014","43.3361","Ethiopia","ET","ETH","Sumalē","","70450","1231990748"
"Conway","Conway","35.0753","-92.4692","United States","US","USA","Arkansas","","70555","1840014598"
"Būr Fu’ād","Bur Fu'ad","31.2500","32.3167","Egypt","EG","EGY","Būr Sa‘īd","","70417","1818849497"
"São Sebastião do Paraíso","Sao Sebastiao do Paraiso","-20.9169","-46.9908","Brazil","BR","BRA","Minas Gerais","","70450","1076022954"
"Caldas Novas","Caldas Novas","-17.7439","-48.6258","Brazil","BR","BRA","Goiás","","70473","1076906502"
"Dandeli","Dandeli","15.2667","74.6167","India","IN","IND","Karnātaka","","70342","1356782464"
"Bopa","Bopa","6.5833","1.9833","Benin","BJ","BEN","Mono","","70268","1204457759"
"Kovel","Kovel","51.2167","24.7167","Ukraine","UA","UKR","Volynska Oblast","minor","69089","1804093604"
"Santa María La Pila","Santa Maria La Pila","15.6056","-89.8125","Guatemala","GT","GTM","Alta Verapaz","","70321","1320008740"
"Tinambac","Tinambac","13.8183","123.3244","Philippines","PH","PHL","Camarines Sur","","70176","1608308136"
"San Pascual","San Pascual","13.8000","121.0333","Philippines","PH","PHL","Batangas","","69009","1608710457"
"Imerintsiatosika","Imerintsiatosika","-18.9833","47.3167","Madagascar","MG","MDG","Antananarivo","","69953","1450857841"
"Guzhou","Guzhou","25.9452","108.5238","China","CN","CHN","Guizhou","","70098","1156435005"
"Eniwa","Eniwa","42.8826","141.5778","Japan","JP","JPN","Hokkaidō","","70102","1392003482"
"Sesvete","Sesvete","45.8269","16.1111","Croatia","HR","HRV","Zagreb, Grad","","70009","1191537414"
"Rongcheng","Rongcheng","39.0500","115.8657","China","CN","CHN","Hebei","","69143","1156640107"
"Zhanggu","Zhanggu","30.8795","101.8822","China","CN","CHN","Sichuan","minor","70000","1156031323"
"Rocklin","Rocklin","38.8075","-121.2489","United States","US","USA","California","","70317","1840020232"
"Rohri","Rohri","27.6831","68.9000","Pakistan","PK","PAK","Sindh","","69920","1586107565"
"Imola","Imola","44.3531","11.7147","Italy","IT","ITA","Emilia-Romagna","","69936","1380070609"
"Limonade","Limonade","19.6667","-72.1167","Haiti","HT","HTI","Nord","","69256","1332450528"
"Sheboygan","Sheboygan","43.7403","-87.7316","United States","US","USA","Wisconsin","","70234","1840002628"
"Casas Adobes","Casas Adobes","32.3423","-111.0113","United States","US","USA","Arizona","","70224","1840018108"
"St. Charles","St. Charles","38.7954","-90.5157","United States","US","USA","Missouri","","70218","1840009719"
"Rhondda","Rhondda","51.6159","-3.4175","United Kingdom","GB","GBR","Rhondda Cynon Taff","","69506","1826659053"
"Satyamangalam","Satyamangalam","11.5167","77.2500","India","IN","IND","Tamil Nādu","","70000","1356304050"
"Kempten","Kempten","47.7333","10.3167","Germany","DE","DEU","Bavaria","minor","69053","1276008842"
"Villa del Rosario","Villa del Rosario","7.8339","-72.4742","Colombia","CO","COL","Norte de Santander","minor","69833","1170700232"
"Sanlúcar de Barrameda","Sanlucar de Barrameda","36.7667","-6.3500","Spain","ES","ESP","Andalusia","","69727","1724096650"
"Az Zubaydīyah","Az Zubaydiyah","32.7588","45.1773","Iraq","IQ","IRQ","Wāsiţ","","70000","1368931413"
"Phú Quốc","Phu Quoc","10.2289","103.9669","Vietnam","VN","VNM","Kiến Giang","minor","70000","1704992616"
"Raha","Raha","-4.8311","122.7239","Indonesia","ID","IDN","Sulawesi Tenggara","minor","69980","1360221477"
"Al ‘Āmirāt","Al \`Amirat","23.5242","58.4989","Oman","OM","OMN","Masqaţ","","70000","1512675854"
"Oshnavīyeh","Oshnaviyeh","37.0389","45.0992","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","70030","1364910470"
"Nihtaur","Nihtaur","29.3300","78.3800","India","IN","IND","Uttar Pradesh","","68500","1356110156"
"Fanzhuang","Fanzhuang","37.7771","114.9620","China","CN","CHN","Hebei","","69155","1156236189"
"Cahama","Cahama","-16.2833","14.3167","Angola","AO","AGO","Cunene","","70061","1024638606"
"Cianorte","Cianorte","-23.6628","-52.6050","Brazil","BR","BRA","Paraná","","69958","1076944079"
"Nansang","Nansang","20.8889","97.7361","Myanmar","MM","MMR","Shan State","","70000","1104983496"
"Shangchuankou","Shangchuankou","36.3283","102.8015","China","CN","CHN","Qinghai","","69252","1156075077"
"Quilāndi","Quilandi","11.4390","75.6950","India","IN","IND","Kerala","","68982","1356719358"
"Badhan","Badhan","10.7139","48.3347","Somalia","SO","SOM","Sanaag","","70000","1706819798"
"Celle","Celle","52.6256","10.0825","Germany","DE","DEU","Lower Saxony","minor","69602","1276282135"
"Nikki","Nikki","9.9333","3.2083","Benin","BJ","BEN","Borgou","","69970","1204598313"
"Victoria","Victoria","15.5781","120.6819","Philippines","PH","PHL","Tarlac","","69370","1608954565"
"Qingquan","Qingquan","38.7823","101.0826","China","CN","CHN","Gansu","minor","69902","1156215844"
"Garzón","Garzon","2.1819","-75.6578","Colombia","CO","COL","Huila","minor","69823","1170149755"
"Isnā","Isna","25.3000","32.5500","Egypt","EG","EGY","Qinā","","68656","1818782058"
"Minami-Alps","Minami-Alps","35.6083","138.4650","Japan","JP","JPN","Yamanashi","","69642","1392003403"
"Saldanha","Saldanha","-32.9978","17.9456","South Africa","ZA","ZAF","Western Cape","","68284","1710078862"
"Maple Grove","Maple Grove","45.1089","-93.4626","United States","US","USA","Minnesota","","69900","1840008923"
"Cataguases","Cataguases","-21.3889","-42.6969","Brazil","BR","BRA","Minas Gerais","","69757","1076169159"
"Tournai","Tournai","50.6056","3.3881","Belgium","BE","BEL","Wallonia","minor","69554","1056138540"
"Russas","Russas","-4.9400","-37.9758","Brazil","BR","BRA","Ceará","","69833","1076843360"
"Walnut Creek","Walnut Creek","37.9024","-122.0399","United States","US","USA","California","","69876","1840021531"
"Dublin","Dublin","37.7161","-121.8963","United States","US","USA","California","","69818","1840018916"
"North Richland Hills","North Richland Hills","32.8604","-97.2180","United States","US","USA","Texas","","69817","1840020703"
"San Juan de los Lagos","San Juan de los Lagos","21.2489","-102.3304","Mexico","MX","MEX","Jalisco","minor","69725","1484319370"
"Solok","Solok","-0.7883","100.6542","Indonesia","ID","IDN","Sumatera Barat","","68602","1360178406"
"Uwajima","Uwajima","33.2167","132.5667","Japan","JP","JPN","Ehime","","69639","1392682766"
"Nowrangapur","Nowrangapur","19.2300","82.5500","India","IN","IND","Odisha","","69307","1356752279"
"Massa","Massa","44.0333","10.1333","Italy","IT","ITA","Tuscany","minor","69037","1380078151"
"Suwałki","Suwalki","54.0989","22.9286","Poland","PL","POL","Podlaskie","minor","69626","1616523627"
"Gary","Gary","41.5905","-87.3473","United States","US","USA","Indiana","","69739","1840008194"
"Glen Burnie","Glen Burnie","39.1560","-76.6071","United States","US","USA","Maryland","","69739","1840005917"
"Colmar","Colmar","48.0817","7.3556","France","FR","FRA","Grand Est","minor","68703","1250950647"
"Bristol","Bristol","36.5572","-82.2154","United States","US","USA","Tennessee","","69734","1840013301"
"Decatur","Decatur","34.5731","-86.9905","United States","US","USA","Alabama","","69710","1840001403"
"Velsen-Zuid","Velsen-Zuid","52.4667","4.6167","Netherlands","NL","NLD","Noord-Holland","","68617","1528400159"
"Yitiaoshan","Yitiaoshan","37.1889","104.0571","China","CN","CHN","Gansu","minor","68952","1156318453"
"Roxas","Roxas","10.3197","119.3408","Philippines","PH","PHL","Palawan","","69624","1608957509"
"West Bend","West Bend","43.4173","-88.1818","United States","US","USA","Wisconsin","","69672","1840002829"
"San Pedro","San Pedro","-33.6794","-59.6669","Argentina","AR","ARG","Buenos Aires","minor","69616","1032604675"
"Indang","Indang","14.2000","120.8833","Philippines","PH","PHL","Cavite","","68699","1608838014"
"Macaíba","Macaiba","-5.8578","-35.3539","Brazil","BR","BRA","Rio Grande do Norte","","69467","1076176042"
"Saint-Jérôme","Saint-Jerome","45.7833","-74.0000","Canada","CA","CAN","Quebec","","69598","1124268324"
"Don Carlos","Don Carlos","7.6808","124.9953","Philippines","PH","PHL","Bukidnon","","69273","1608683274"
"Kollegāl","Kollegal","12.0628","76.9777","India","IN","IND","Karnātaka","","69441","1356246344"
"Samch’ŏk","Samch'ok","37.4500","129.1650","South Korea","KR","KOR","Gangwon","","69509","1410438648"
"La Paz","La Paz","15.4431","120.7289","Philippines","PH","PHL","Tarlac","","68952","1608056666"
"Gniezno","Gniezno","52.5358","17.5958","Poland","PL","POL","Wielkopolskie","minor","68217","1616984350"
"Fedosiia","Fedosiia","45.0489","35.3792","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","69145","1804677570"
"Bom Jesus da Lapa","Bom Jesus da Lapa","-13.2550","-43.4178","Brazil","BR","BRA","Bahia","","69526","1076439517"
"Cawayan","Cawayan","11.9303","123.7689","Philippines","PH","PHL","Masbate","","69265","1608114662"
"Fernandópolis","Fernandopolis","-20.2839","-50.2458","Brazil","BR","BRA","São Paulo","","69402","1076205721"
"Anan","Anan","33.9167","134.6667","Japan","JP","JPN","Tokushima","","69259","1392003091"
"Poptún","Poptun","16.3222","-89.4222","Guatemala","GT","GTM","Petén","minor","69437","1320738240"
"Sabae","Sabae","35.9565","136.1843","Japan","JP","JPN","Fukui","","68666","1392929907"
"Lodi","Lodi","38.1218","-121.2932","United States","US","USA","California","","69467","1840020263"
"Ongjang","Ongjang","37.9371","125.3571","North Korea","KP","PRK","Hwangnam","","69195","1408848418"
"Lima","Lima","40.7410","-84.1120","United States","US","USA","Ohio","","69440","1840002785"
"Florida","Florida","18.3643","-66.5611","Puerto Rico","PR","PRI","Puerto Rico","","69427","1630023648"
"Mansehra","Mansehra","34.3339","73.2014","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","69085","1586902336"
"Chisec","Chisec","15.8125","-90.3217","Guatemala","GT","GTM","Alta Verapaz","minor","69325","1320378643"
"Aqsū","Aqsu","52.0333","76.9167","Kazakhstan","KZ","KAZ","","minor","69354","1398272761"
"Planeta Rica","Planeta Rica","8.4089","-75.5819","Colombia","CO","COL","Córdoba","minor","69285","1170924519"
"Yanghe","Yanghe","38.2727","106.2496","China","CN","CHN","Ningxia","minor","67901","1156195346"
"Korba","Korba","36.5667","10.8667","Tunisia","TN","TUN","Nabeul","","68966","1788344871"
"Bafia","Bafia","4.7500","11.2167","Cameroon","CM","CMR","Centre","","69270","1120310806"
"Ko Samui","Ko Samui","9.5000","100.0000","Thailand","TH","THA","Surat Thani","minor","68994","1764771934"
"Brumado","Brumado","-14.2039","-41.6650","Brazil","BR","BRA","Bahia","","69255","1076669684"
"Pongnam","Pongnam","37.2200","126.9494","South Korea","KR","KOR","Gyeonggi","","67694","1410174724"
"Liuhe","Liuhe","42.2669","125.7404","China","CN","CHN","Jilin","minor","68938","1156459313"
"Kamsar","Kamsar","10.6500","-14.6167","Guinea","GN","GIN","Boké","","68999","1324075393"
"Fulda","Fulda","50.5508","9.6753","Germany","DE","DEU","Hesse","minor","68586","1276669051"
"Parādīp Garh","Paradip Garh","20.3160","86.6100","India","IN","IND","Odisha","","68585","1356331567"
"Mamungan","Mamungan","8.1167","124.2167","Philippines","PH","PHL","Lanao del Norte","","68465","1608275086"
"Huishi","Huishi","35.6918","105.0531","China","CN","CHN","Gansu","minor","68958","1156373827"
"Ad Darb","Ad Darb","17.7167","42.2500","Saudi Arabia","SA","SAU","Jāzān","","69134","1682370751"
"Necoclí","Necocli","8.4167","-76.7833","Colombia","CO","COL","Antioquia","minor","69090","1170745269"
"Mocuba","Mocuba","-16.8500","36.9833","Mozambique","MZ","MOZ","Zambézia","","69045","1508922450"
"Minusinsk","Minusinsk","53.7000","91.6833","Russia","RU","RUS","Krasnoyarskiy Kray","","68007","1643003309"
"Renk","Renk","11.8300","32.8000","South Sudan","SS","SSD","Upper Nile","","69079","1728415189"
"Aparri","Aparri","18.3575","121.6372","Philippines","PH","PHL","Cagayan","","68839","1608370398"
"Smila","Smila","49.2167","31.8667","Ukraine","UA","UKR","Cherkaska Oblast","minor","68536","1804490050"
"Mococa","Mococa","-21.4678","-47.0047","Brazil","BR","BRA","São Paulo","","68980","1076000865"
"Tanabe","Tanabe","33.7333","135.3833","Japan","JP","JPN","Wakayama","","68986","1392214761"
"Bagumbayan","Bagumbayan","6.5339","124.5633","Philippines","PH","PHL","Maguindanao","","68947","1608205674"
"Oliveira de Azemeis","Oliveira de Azemeis","40.8400","-8.4750","Portugal","PT","PRT","Aveiro","minor","68611","1620421310"
"Buenavista","Buenavista","8.9744","125.4090","Philippines","PH","PHL","Agusan del Norte","","68892","1608153897"
"Purísima de Bustos","Purisima de Bustos","21.0333","-101.8667","Mexico","MX","MEX","Guanajuato","minor","68795","1484104201"
"Lakeville","Lakeville","44.6774","-93.2520","United States","US","USA","Minnesota","","69026","1840007859"
"Mocuba","Mocuba","-16.8496","38.2600","Mozambique","MZ","MOZ","Zambézia","","68984","1508699932"
"Blaine","Blaine","45.1696","-93.2077","United States","US","USA","Minnesota","","68999","1840006719"
"Arcoverde","Arcoverde","-8.4189","-37.0539","Brazil","BR","BRA","Pernambuco","","68793","1076499713"
"San Leonardo","San Leonardo","15.3611","120.9622","Philippines","PH","PHL","Nueva Ecija","","68536","1608550688"
"Wagga Wagga","Wagga Wagga","-35.1189","147.3689","Australia","AU","AUS","New South Wales","","67364","1036273188"
"Stargard Szczeciński","Stargard Szczecinski","53.3333","15.0500","Poland","PL","POL","Zachodniopomorskie","minor","67579","1616480646"
"Tianguá","Tiangua","-3.7319","-40.9919","Brazil","BR","BRA","Ceará","","68892","1076661273"
"Say’ūn","Say'un","15.9430","48.7873","Yemen","YE","YEM","Ḩaḑramawt","minor","68747","1887142998"
"Pālghar","Palghar","19.6900","72.7600","India","IN","IND","Mahārāshtra","","68931","1356868621"
"Dinslaken","Dinslaken","51.5667","6.7333","Germany","DE","DEU","North Rhine-Westphalia","","67525","1276055868"
"Poinciana","Poinciana","28.1217","-81.4821","United States","US","USA","Florida","","68922","1840014132"
"Sousa","Sousa","-6.7608","-38.2308","Brazil","BR","BRA","Paraíba","","68822","1076742573"
"Al Aḩmadī","Al Ahmadi","29.0769","48.0838","Kuwait","KW","KWT","Al Aḩmadī","","68763","1414024487"
"Noblesville","Noblesville","40.0355","-86.0042","United States","US","USA","Indiana","","68885","1840013891"
"Wum","Wum","6.3833","10.0667","Cameroon","CM","CMR","North-West","","68836","1120557987"
"San Pedro Ayampuc","San Pedro Ayampuc","14.7785","-90.4525","Guatemala","GT","GTM","Guatemala","minor","67946","1320976684"
"Sōja","Soja","34.6728","133.7464","Japan","JP","JPN","Okayama","","68551","1392665556"
"Nghĩa Lộ","Nghia Lo","21.5758","104.5192","Vietnam","VN","VNM","Yên Bái","","68206","1704571558"
"Panna","Panna","24.7200","80.2000","India","IN","IND","Madhya Pradesh","","68715","1356085520"
"Aalen","Aalen","48.8333","10.1000","Germany","DE","DEU","Baden-Württemberg","minor","68361","1276757787"
"Lake Elsinore","Lake Elsinore","33.6847","-117.3344","United States","US","USA","California","","68822","1840020560"
"Głogów","Glogow","51.6589","16.0803","Poland","PL","POL","Dolnośląskie","minor","66980","1616244076"
"Tangub","Tangub","8.0667","123.7500","Philippines","PH","PHL","Misamis Occidental","","68389","1608158776"
"Ilkal","Ilkal","15.9592","76.1135","India","IN","IND","Karnātaka","","68534","1356096421"
"Bay","Bay","14.1833","121.2833","Philippines","PH","PHL","Laguna","","67182","1608468352"
"Alamada","Alamada","7.3868","124.5534","Philippines","PH","PHL","Cotabato","","68659","1608117314"
"Concórdia","Concordia","-27.2339","-52.0278","Brazil","BR","BRA","Santa Catarina","","68621","1076922195"
"Cannock","Cannock","52.6910","-2.0270","United Kingdom","GB","GBR","Staffordshire","","67768","1826709192"
"Beypore","Beypore","11.1800","75.8200","India","IN","IND","Kerala","","66883","1356592326"
"Jarabacoa","Jarabacoa","19.1167","-70.6333","Dominican Republic","DO","DOM","Cibao Sur","","68585","1214381692"
"Swedru","Swedru","5.5306","-0.7028","Ghana","GH","GHA","Central","","68216","1288165565"
"Ōdate","Odate","40.2714","140.5642","Japan","JP","JPN","Akita","","68593","1392257761"
"Zapotlanejo","Zapotlanejo","20.6228","-103.0689","Mexico","MX","MEX","Jalisco","minor","68519","1484013669"
"Palo Alto","Palo Alto","37.3905","-122.1468","United States","US","USA","California","","68624","1840020333"
"Doboj","Doboj","44.7333","18.1333","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","68514","1070396173"
"Castillejos","Castillejos","14.9333","120.2000","Philippines","PH","PHL","Zambales","","67889","1608611623"
"Igbanke","Igbanke","6.3869","6.1647","Nigeria","NG","NGA","Edo","","68500","1566776421"
"Lippstadt","Lippstadt","51.6667","8.3500","Germany","DE","DEU","North Rhine-Westphalia","","68007","1276820778"
"Drummondville","Drummondville","45.8833","-72.4833","Canada","CA","CAN","Quebec","","68601","1124624283"
"Sagunto","Sagunto","39.6764","-0.2733","Spain","ES","ESP","Valencia","","68066","1724302941"
"Mbaké","Mbake","14.7917","-15.9083","Senegal","SN","SEN","Diourbel","","68054","1686440030"
"Nābha","Nabha","30.3700","76.1500","India","IN","IND","Punjab","","67972","1356183052"
"Miagao","Miagao","10.6442","122.2352","Philippines","PH","PHL","Iloilo","","68115","1608200248"
"Otukpo","Otukpo","7.1904","8.1300","Nigeria","NG","NGA","Benue","minor","68220","1566465153"
"Tuburan","Tuburan","10.7333","123.8333","Philippines","PH","PHL","Cebu","","68167","1608192465"
"Sipocot","Sipocot","13.7675","122.9783","Philippines","PH","PHL","Camarines Sur","","68169","1608134009"
"Sanza Pombo","Sanza Pombo","-7.3333","16.0000","Angola","AO","AGO","Uíge","","68391","1024770580"
"Tulare","Tulare","36.1995","-119.3400","United States","US","USA","California","","68395","1840021638"
"Cuyapo","Cuyapo","15.7778","120.6608","Philippines","PH","PHL","Nueva Ecija","","68066","1608467809"
"Lala Musa","Lala Musa","32.7003","73.9578","Pakistan","PK","PAK","Punjab","","67283","1586484801"
"Ash Shiḩr","Ash Shihr","14.7608","49.6069","Yemen","YE","YEM","Ḩaḑramawt","minor","68313","1887657562"
"Rogers","Rogers","36.3170","-94.1531","United States","US","USA","Arkansas","","68340","1840014444"
"Ixtlahuacán de los Membrillos","Ixtlahuacan de los Membrillos","20.3500","-103.1833","Mexico","MX","MEX","Jalisco","minor","67969","1484370414"
"Yorba Linda","Yorba Linda","33.8890","-117.7714","United States","US","USA","California","","68319","1840021970"
"Renukūt","Renukut","24.2000","83.0300","India","IN","IND","Uttar Pradesh","","68000","1356918335"
"Tuymazy","Tuymazy","54.6000","53.7000","Russia","RU","RUS","Bashkortostan","","68256","1643065980"
"Chengjiao Chengguanzhen","Chengjiao Chengguanzhen","34.4362","104.0306","China","CN","CHN","Gansu","minor","67826","1156556377"
"Bishnupur","Bishnupur","23.0739","87.3200","India","IN","IND","West Bengal","","67783","1356443025"
"Lodja","Lodja","-3.5242","23.5964","Congo (Kinshasa)","CD","COD","Sankuru","","68244","1180001261"
"Ceará-Mirim","Ceara-Mirim","-5.6339","-35.4258","Brazil","BR","BRA","Rio Grande do Norte","","68141","1076913277"
"Parkersburg","Parkersburg","39.2623","-81.5420","United States","US","USA","West Virginia","","68217","1840005786"
"Zamość","Zamosc","50.7167","23.2528","Poland","PL","POL","Lubelskie","minor","66234","1616490229"
"Conceição do Coité","Conceicao do Coite","-11.5639","-39.2828","Brazil","BR","BRA","Bahia","","68146","1076125877"
"Xinhua","Xinhua","23.6272","105.6200","China","CN","CHN","Yunnan","minor","67976","1156800109"
"Eagan","Eagan","44.8170","-93.1638","United States","US","USA","Minnesota","","68205","1840007852"
"Rājsamand","Rajsamand","25.0700","73.8800","India","IN","IND","Rājasthān","","67798","1356835645"
"Bergen op Zoom","Bergen op Zoom","51.5000","4.3000","Netherlands","NL","NLD","Noord-Brabant","minor","67514","1528854489"
"Gopālganj","Gopalganj","26.4700","84.4300","India","IN","IND","Bihār","","67339","1356098593"
"Weston","Weston","26.1006","-80.4054","United States","US","USA","Florida","","68148","1840016003"
"Santa Barbara","Santa Barbara","10.8231","122.5344","Philippines","PH","PHL","Iloilo","","67630","1608991655"
"Urun-Islāmpur","Urun-Islampur","17.0500","74.2670","India","IN","IND","Mahārāshtra","","67391","1356106833"
"Modāsa","Modasa","23.4700","73.3000","India","IN","IND","Gujarāt","","67648","1356294511"
"La Barca","La Barca","20.2833","-102.5667","Mexico","MX","MEX","Jalisco","minor","67937","1484900173"
"Przemyśl","Przemysl","49.7833","22.7667","Poland","PL","POL","Podkarpackie","minor","67847","1616506094"
"Talipparamba","Talipparamba","12.0368","75.3601","India","IN","IND","Kerala","","67441","1356781430"
"Wanzhuang","Wanzhuang","39.5683","116.5941","China","CN","CHN","Hebei","","67158","1156021193"
"Malkāpur","Malkapur","20.8850","76.2000","India","IN","IND","Mahārāshtra","","67740","1356838464"
"Zanhuang","Zanhuang","37.6590","114.3775","China","CN","CHN","Hebei","","66255","1156247837"
"Dhenkānāl","Dhenkanal","20.6700","85.6000","India","IN","IND","Odisha","","67414","1356304880"
"Kstovo","Kstovo","56.1667","44.1667","Russia","RU","RUS","Nizhegorodskaya Oblast’","","67874","1643713056"
"Viterbo","Viterbo","42.4186","12.1042","Italy","IT","ITA","Lazio","minor","67798","1380408658"
"Longview","Longview","46.1461","-122.9630","United States","US","USA","Washington","","67958","1840019904"
"Tzaneen","Tzaneen","-23.8333","30.1667","South Africa","ZA","ZAF","Limpopo","","67245","1710120942"
"Trapani","Trapani","38.0175","12.5150","Italy","IT","ITA","Sicilia","minor","67923","1380918442"
"Antalaha","Antalaha","-14.8833","50.2500","Madagascar","MG","MDG","Antsiranana","","67888","1450649394"
"Bay City","Bay City","43.5902","-83.8887","United States","US","USA","Michigan","","67917","1840002583"
"Dubuque","Dubuque","42.5002","-90.7070","United States","US","USA","Iowa","","67916","1840000438"
"Tepotzotlán","Tepotzotlan","19.7161","-99.2236","Mexico","MX","MEX","México","minor","67724","1484890297"
"Porterville","Porterville","36.0643","-119.0338","United States","US","USA","California","","67887","1840020368"
"Franklin","Franklin","40.4759","-74.5515","United States","US","USA","New Jersey","","67867","1840143542"
"Etāwa","Etawa","24.1800","78.2000","India","IN","IND","Madhya Pradesh","","67558","1356950925"
"Longshan","Longshan","26.4519","106.9718","China","CN","CHN","Guizhou","","67790","1156115157"
"Xo‘jayli Shahri","Xo\`jayli Shahri","42.4000","59.4500","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","67800","1860783170"
"Mount Vernon","Mount Vernon","48.4203","-122.3115","United States","US","USA","Washington","","67767","1840019774"
"Ullāl","Ullal","12.8000","74.8500","India","IN","IND","Karnātaka","","65861","1356227509"
"Palatine","Palatine","42.1180","-88.0430","United States","US","USA","Illinois","","67754","1840011311"
"Fuefuki","Fuefuki","35.6473","138.6397","Japan","JP","JPN","Yamanashi","","67396","1392003520"
"Embu-Guaçu","Embu-Guacu","-23.8322","-46.8114","Brazil","BR","BRA","São Paulo","","67296","1076814441"
"İslahiye","Islahiye","37.0250","36.6345","Turkey","TR","TUR","Gaziantep","minor","67674","1792369096"
"Rosales","Rosales","15.8944","120.6328","Philippines","PH","PHL","Pangasinan","","66711","1608568753"
"Chicacao","Chicacao","14.5428","-91.3288","Guatemala","GT","GTM","Suchitepéquez","minor","67399","1320018206"
"Southampton","Southampton","40.8997","-72.4931","United States","US","USA","New York","","67704","1840005137"
"El Paso de Robles","El Paso de Robles","35.6394","-120.6560","United States","US","USA","California","","67696","1840074847"
"Sausar","Sausar","21.6500","78.7800","India","IN","IND","Madhya Pradesh","","67459","1356174613"
"Yuquan","Yuquan","40.4202","114.0865","China","CN","CHN","Shanxi","minor","67449","1156773117"
"West Des Moines","West Des Moines","41.5521","-93.7806","United States","US","USA","Iowa","","67640","1840010185"
"Kashima","Kashima","35.9656","140.6448","Japan","JP","JPN","Ibaraki","","67001","1392346235"
"Santa Catarina Otzolotepec","Santa Catarina Otzolotepec","18.5667","-97.2500","Mexico","MX","MEX","Puebla","","67611","1484006128"
"Manaure","Manaure","11.7792","-72.4494","Colombia","CO","COL","La Guajira","minor","67584","1170534927"
"Lushar","Lushar","36.4971","101.5640","China","CN","CHN","Qinghai","minor","67153","1156481548"
"Saint John","Saint John","45.2806","-66.0761","Canada","CA","CAN","New Brunswick","","67575","1124631364"
"Tôlan̈aro","Tolanaro","-25.0325","46.9833","Madagascar","MG","MDG","Toliara","minor","67284","1450217836"
"Hashima","Hashima","35.3199","136.7033","Japan","JP","JPN","Gifu","","66312","1392769362"
"Ayolas","Ayolas","-27.4000","-56.9000","Paraguay","PY","PRY","Misiones","","67487","1600970538"
"Zihuatanejo","Zihuatanejo","17.6444","-101.5511","Mexico","MX","MEX","Guerrero","minor","67408","1484489217"
"Sahaswān","Sahaswan","28.0680","78.7510","India","IN","IND","Uttar Pradesh","","66204","1356981844"
"Sa’ada","Sa'ada","31.6258","-8.1028","Morocco","MA","MAR","Marrakech-Safi","","67086","1504926324"
"Omīdīyeh","Omidiyeh","30.7458","49.7086","Iran","IR","IRN","Khūzestān","minor","67427","1364604697"
"Siddipet","Siddipet","18.1018","78.8520","India","IN","IND","Andhra Pradesh","","66737","1356671720"
"Herford","Herford","52.1333","8.6833","Germany","DE","DEU","North Rhine-Westphalia","minor","66608","1276588147"
"Cuilco","Cuilco","15.4078","-91.9472","Guatemala","GT","GTM","Huehuetenango","minor","67297","1320503377"
"Kissidougou","Kissidougou","9.1905","-10.1200","Guinea","GN","GIN","Faranah","minor","66815","1324750427"
"Koratla","Koratla","18.8215","78.7119","India","IN","IND","Andhra Pradesh","","66504","1356581279"
"Guangping","Guangping","36.4791","114.9410","China","CN","CHN","Hebei","","66384","1156186491"
"Ankazoabokely","Ankazoabokely","-21.5036","45.2094","Madagascar","MG","MDG","Toliara","","67337","1450489692"
"Weiyuan","Weiyuan","36.8413","101.9557","China","CN","CHN","Qinghai","minor","66490","1156249264"
"Chegutu","Chegutu","-18.1400","30.1500","Zimbabwe","ZW","ZWE","Mashonaland West","","65800","1716242569"
"Venustiano Carranza","Venustiano Carranza","16.3000","-92.6000","Mexico","MX","MEX","Chiapas","minor","67292","1484842496"
"Pelileo","Pelileo","-1.3306","-78.5428","Ecuador","EC","ECU","Tungurahua","","66836","1218236950"
"Yachimata","Yachimata","35.6667","140.3167","Japan","JP","JPN","Chiba","","66430","1392651190"
"Caicó","Caico","-6.4578","-37.0978","Brazil","BR","BRA","Rio Grande do Norte","","67259","1076251820"
"Tejen","Tejen","37.3833","60.5000","Turkmenistan","TM","TKM","Ahal","","67294","1795500486"
"Agoo","Agoo","16.3220","120.3647","Philippines","PH","PHL","La Union","","66028","1608343645"
"Itamaraju","Itamaraju","-17.0389","-39.5308","Brazil","BR","BRA","Bahia","","67249","1076241282"
"Rüsselsheim","Russelsheim","49.9950","8.4119","Germany","DE","DEU","Hesse","","66125","1276969472"
"Rio Negro","Rio Negro","-26.1000","-49.7900","Brazil","BR","BRA","Paraná","","65597","1076653242"
"Hobyo","Hobyo","5.3514","48.5256","Somalia","SO","SOM","Mudug","minor","67249","1706623995"
"Cosmópolis","Cosmopolis","-22.6458","-47.1961","Brazil","BR","BRA","São Paulo","","66807","1076281064"
"Supaul","Supaul","26.1260","86.6050","India","IN","IND","Bihār","","65437","1356015995"
"Moita","Moita","38.6500","-8.9833","Portugal","PT","PRT","Setúbal","minor","66029","1620000010"
"San Mateo","San Mateo","16.8833","121.5833","Philippines","PH","PHL","Isabela","","66663","1608259452"
"Sherman","Sherman","33.6273","-96.6221","United States","US","USA","Texas","","67210","1840021953"
"Tabatinga","Tabatinga","-4.2525","-69.9381","Brazil","BR","BRA","Amazonas","","67182","1076877236"
"Dolores Hidalgo Cuna de la Independencia Nacional","Dolores Hidalgo Cuna de la Independencia Nacional","21.1516","-100.9369","Mexico","MX","MEX","Guanajuato","minor","67101","1484115665"
"Ilidža","Ilidza","43.8167","18.3000","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","66730","1070286664"
"Aflao","Aflao","6.1468","1.1799","Ghana","GH","GHA","Volta","","66546","1288563374"
"Janiuay","Janiuay","10.9500","122.5000","Philippines","PH","PHL","Iloilo","","66786","1608317775"
"Kānhangād","Kanhangad","12.3000","75.0900","India","IN","IND","Kerala","","65499","1356114855"
"La Carlota","La Carlota","10.4167","122.9167","Philippines","PH","PHL","Negros Occidental","","66664","1608234277"
"Castro","Castro","-24.7908","-50.0119","Brazil","BR","BRA","Paraná","","67084","1076233349"
"Vidnoye","Vidnoye","55.5500","37.7167","Russia","RU","RUS","Moskovskaya Oblast’","","66158","1643052393"
"Rockville","Rockville","39.0834","-77.1553","United States","US","USA","Maryland","","67095","1840005849"
"Haverhill","Haverhill","42.7838","-71.0871","United States","US","USA","Massachusetts","","67093","1840002450"
"Bongabon","Bongabon","15.6321","121.1448","Philippines","PH","PHL","Nueva Ecija","","66839","1608482440"
"Lupon","Lupon","6.8969","126.0117","Philippines","PH","PHL","Davao Oriental","minor","66979","1608289249"
"Río Grande","Rio Grande","-53.7833","-67.7000","Argentina","AR","ARG","Tierra del Fuego, Antártida e Islas del Atlántico Sur","minor","67038","1032824067"
"Güines","Guines","22.8475","-82.0236","Cuba","CU","CUB","Mayabeque","minor","66892","1192190364"
"Hanover","Hanover","39.8118","-76.9836","United States","US","USA","Pennsylvania","","67037","1840001450"
"Shawnee","Shawnee","39.0158","-94.8076","United States","US","USA","Kansas","","67021","1840001641"
"Fukutsu","Fukutsu","33.7667","130.4833","Japan","JP","JPN","Fukuoka","","65770","1392003286"
"Virginia","Virginia","-28.1064","26.8622","South Africa","ZA","ZAF","Free State","","66208","1710992750"
"Middletown","Middletown","40.3892","-74.0820","United States","US","USA","New Jersey","","67007","1840081835"
"Cedeño","Cedeno","6.7100","-66.1127","Venezuela","VE","VEN","Bolívar","","67000","1862036246"
"Rome","Rome","34.2662","-85.1863","United States","US","USA","Georgia","","66998","1840014681"
"Godalming","Godalming","51.1800","-0.6100","United Kingdom","GB","GBR","Surrey","","66773","1826220347"
"Nueva Guinea","Nueva Guinea","11.6867","-84.4561","Nicaragua","NI","NIC","Costa Caribe Sur","minor","66936","1558868719"
"Coonoor","Coonoor","11.3450","76.7950","India","IN","IND","Tamil Nādu","","66258","1356833070"
"Pessac","Pessac","44.8067","-0.6311","France","FR","FRA","Nouvelle-Aquitaine","","65245","1250292604"
"Old Bridge","Old Bridge","40.4004","-74.3126","United States","US","USA","New Jersey","","66925","1840081688"
"Gohna","Gohna","29.1300","76.9000","India","IN","IND","Haryāna","","65708","1356118267"
"El Seibo","El Seibo","18.7630","-69.0420","Dominican Republic","DO","DOM","Yuma","minor","66867","1214331828"
"Pabianice","Pabianice","51.6500","19.3833","Poland","PL","POL","Łódzkie","minor","64988","1616294006"
"Kerpen","Kerpen","50.8719","6.6961","Germany","DE","DEU","North Rhine-Westphalia","","66294","1276675138"
"Arcot","Arcot","12.9000","79.3333","India","IN","IND","Tamil Nādu","","65937","1356293782"
"Capanema","Capanema","-1.1958","-47.1808","Brazil","BR","BRA","Pará","","66759","1076799309"
"Genk","Genk","50.9662","5.5021","Belgium","BE","BEL","Flanders","","66110","1056555160"
"Janaúba","Janauba","-15.8028","-43.3089","Brazil","BR","BRA","Minas Gerais","","66803","1076293809"
"Bulancak","Bulancak","40.9333","38.2333","Turkey","TR","TUR","Giresun","minor","66736","1792627106"
"Botolan","Botolan","15.2896","120.0245","Philippines","PH","PHL","Zambales","","66739","1608875011"
"Pulivendla","Pulivendla","14.4167","78.2333","India","IN","IND","Andhra Pradesh","","65706","1356099701"
"Çumra","Cumra","37.5750","32.7747","Turkey","TR","TUR","Konya","minor","66794","1792642488"
"Borås","Boras","57.7211","12.9403","Sweden","SE","SWE","Västra Götaland","minor","66273","1752555835"
"Arāmbāgh","Arambagh","22.8800","87.7800","India","IN","IND","West Bengal","","66175","1356169521"
"Hīt","Hit","33.6450","42.8264","Iraq","IQ","IRQ","Al Anbār","minor","66700","1368649537"
"South Hill","South Hill","47.1198","-122.2853","United States","US","USA","Washington","","66713","1840037886"
"Opol","Opol","8.5167","124.5667","Philippines","PH","PHL","Misamis Oriental","","66327","1608552657"
"Karimama","Karimama","12.0667","3.1833","Benin","BJ","BEN","Alibori","","66675","1204991433"
"Ocoyoacac","Ocoyoacac","19.2739","-99.4606","Mexico","MX","MEX","Morelos","minor","66190","1484479100"
"Belogorsk","Belogorsk","50.9167","128.4667","Russia","RU","RUS","Amurskaya Oblast’","","66183","1643671200"
"Qarqan","Qarqan","38.1338","85.5333","China","CN","CHN","Xinjiang","minor","65572","1156279285"
"Pidugurālla","Piduguralla","16.4793","79.8863","India","IN","IND","Andhra Pradesh","","66167","1356026783"
"Gwacheon","Gwacheon","37.4333","127.0000","South Korea","KR","KOR","Gyeonggi","","64817","1410788896"
"DeKalb","DeKalb","41.9314","-88.7481","United States","US","USA","Illinois","","66624","1840007033"
"Petaluma","Petaluma","38.2423","-122.6266","United States","US","USA","California","","66609","1840020244"
"Saiki","Saiki","32.9667","131.9000","Japan","JP","JPN","Ōita","","66497","1392471298"
"Ishim","Ishim","56.1167","69.5000","Russia","RU","RUS","Tyumenskaya Oblast’","","65142","1643798395"
"Sammamish","Sammamish","47.6017","-122.0416","United States","US","USA","Washington","","66532","1840021115"
"San Antonio Suchitepéquez","San Antonio Suchitepequez","14.5333","-91.4167","Guatemala","GT","GTM","Suchitepéquez","minor","65501","1320180712"
"Georgetown","Georgetown","30.6660","-97.6966","United States","US","USA","Texas","","66514","1840020875"
"Valence","Valence","44.9333","4.8917","France","FR","FRA","Auvergne-Rhône-Alpes","minor","64749","1250486068"
"Angat","Angat","14.9281","121.0293","Philippines","PH","PHL","Bulacan","","65617","1608108649"
"Caledon","Caledon","43.8667","-79.8667","Canada","CA","CAN","Ontario","","66502","1124070007"
"Gukovo","Gukovo","48.0500","39.9167","Russia","RU","RUS","Rostovskaya Oblast’","","64869","1643040094"
"Xieqiaocun","Xieqiaocun","30.4973","120.6106","China","CN","CHN","Zhejiang","","65473","1156379681"
"Carbondale","Carbondale","37.7221","-89.2237","United States","US","USA","Illinois","","66482","1840007560"
"Urla","Urla","38.3222","26.7647","Turkey","TR","TUR","İzmir","minor","66360","1792891509"
"Kavála","Kavala","40.9333","24.4000","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","65857","1300398756"
"Rechytsa","Rechytsa","52.3639","30.3947","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","66400","1112587997"
"San Remigio","San Remigio","11.0000","123.9500","Philippines","PH","PHL","Cebu","","65744","1608661314"
"Delray Beach","Delray Beach","26.4550","-80.0905","United States","US","USA","Florida","","66434","1840015123"
"Dundalk","Dundalk","39.2704","-76.4942","United States","US","USA","Maryland","","66426","1840005681"
"Puliyankudi","Puliyankudi","9.1725","77.3956","India","IN","IND","Tamil Nādu","","66000","1356638840"
"Huquan","Huquan","39.7603","114.2834","China","CN","CHN","Shanxi","minor","66271","1156161051"
"Kalush","Kalush","49.0442","24.3597","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","66406","1804375295"
"Kenner","Kenner","30.0109","-90.2549","United States","US","USA","Louisiana","","66411","1840015047"
"Shiojiri","Shiojiri","36.1150","137.9534","Japan","JP","JPN","Nagano","","66181","1392508735"
"Albany","Albany","44.6272","-123.0965","United States","US","USA","Oregon","","66405","1840018605"
"Zeist","Zeist","52.0906","5.2331","Netherlands","NL","NLD","Utrecht","minor","65043","1528328689"
"Slavyansk-na-Kubani","Slavyansk-na-Kubani","45.2500","38.1167","Russia","RU","RUS","Krasnodarskiy Kray","minor","66285","1643757173"
"Ankeny","Ankeny","41.7288","-93.6031","United States","US","USA","Iowa","","66346","1840007066"
"Ilo","Ilo","-17.6459","-71.3453","Peru","PE","PER","Moquegua","","66118","1604886582"
"Itaberaba","Itaberaba","-12.5278","-40.3069","Brazil","BR","BRA","Bahia","","66310","1076160849"
"Oas","Oas","13.2589","123.4953","Philippines","PH","PHL","Albay","","66084","1608286760"
"Malvar","Malvar","14.0417","121.1583","Philippines","PH","PHL","Batangas","","64379","1608841812"
"Castro Valley","Castro Valley","37.7088","-122.0626","United States","US","USA","California","","66324","1840017620"
"Bethesda","Bethesda","38.9866","-77.1188","United States","US","USA","Maryland","","66294","1840005826"
"Saratoga Springs","Saratoga Springs","43.0674","-73.7775","United States","US","USA","New York","","66290","1840000369"
"Kampung Tengah","Kampung Tengah","1.4897","102.0797","Indonesia","ID","IDN","Riau","","66211","1360731304"
"Kazanlak","Kazanlak","42.6167","25.4000","Bulgaria","BG","BGR","Stara Zagora","","66169","1100673552"
"Dangbo","Dangbo","6.5000","2.6833","Benin","BJ","BEN","Ouémé","","66055","1204613725"
"Kungur","Kungur","57.4333","56.9333","Russia","RU","RUS","Permskiy Kray","minor","65284","1643194296"
"Lençóis Paulista","Lencois Paulista","-22.5986","-48.8003","Brazil","BR","BRA","São Paulo","","66131","1076632312"
"Roxas","Roxas","17.1167","121.6167","Philippines","PH","PHL","Isabela","","65839","1608794677"
"Oberá","Obera","-27.4833","-55.1333","Argentina","AR","ARG","Misiones","minor","66112","1032099071"
"Lantapan","Lantapan","8.0000","125.0233","Philippines","PH","PHL","Bukidnon","","65974","1608127977"
"União dos Palmares","Uniao dos Palmares","-9.1628","-36.0319","Brazil","BR","BRA","Alagoas","","66017","1076598708"
"Chibok","Chibok","10.8697","12.8467","Nigeria","NG","NGA","Borno","minor","66105","1566191891"
"Singaparna","Singaparna","-7.3497","108.1106","Indonesia","ID","IDN","Jawa Barat","minor","65464","1360770818"
"Sangamner","Sangamner","19.5700","74.2200","India","IN","IND","Mahārāshtra","","65804","1356669414"
"San Gil","San Gil","6.5592","-73.1350","Colombia","CO","COL","Santander","minor","65687","1170310004"
"Sindelfingen","Sindelfingen","48.7133","9.0028","Germany","DE","DEU","Baden-Württemberg","","64858","1276001469"
"Don Torcuato","Don Torcuato","-34.5000","-58.6167","Argentina","AR","ARG","Buenos Aires","","64867","1032462102"
"Corvallis","Corvallis","44.5698","-123.2779","United States","US","USA","Oregon","","66090","1840018611"
"Seraing","Seraing","50.5833","5.5000","Belgium","BE","BEL","Wallonia","","64270","1056627579"
"El Carmen de Bolívar","El Carmen de Bolivar","9.7167","-75.1333","Colombia","CO","COL","Bolívar","minor","66001","1170394868"
"Stupino","Stupino","54.8833","38.1000","Russia","RU","RUS","Moskovskaya Oblast’","","65990","1643209318"
"Most","Most","50.5031","13.6367","Czechia","CZ","CZE","Ústecký Kraj","","65341","1203813984"
"Asenovgrad","Asenovgrad","42.0167","24.8667","Bulgaria","BG","BGR","Plovdiv","","65962","1100200929"
"Madirovalo","Madirovalo","-16.4333","46.5333","Madagascar","MG","MDG","Mahajanga","","66000","1450602387"
"Venâncio Aires","Venancio Aires","-29.6058","-52.1919","Brazil","BR","BRA","Rio Grande do Sul","","65946","1076887157"
"Ames","Ames","42.0256","-93.6217","United States","US","USA","Iowa","","66027","1840007019"
"Tigbauan","Tigbauan","10.6747","122.3776","Philippines","PH","PHL","Iloilo","","65245","1608641668"
"Glens Falls","Glens Falls","43.3109","-73.6458","United States","US","USA","New York","","66024","1840000345"
"San Francisco El Alto","San Francisco El Alto","14.9500","-91.4500","Guatemala","GT","GTM","Totonicapán","minor","65521","1320250670"
"Xibang","Xibang","30.9412","120.8872","China","CN","CHN","Jiangsu","","65211","1156063023"
"Lahat","Lahat","-3.7970","103.5220","Indonesia","ID","IDN","Sumatera Selatan","minor","65906","1360094721"
"Kiamba","Kiamba","5.9833","124.6167","Philippines","PH","PHL","Sarangani","","65774","1608301851"
"Anamur","Anamur","36.0243","32.8026","Turkey","TR","TUR","Mersin","minor","65920","1792088719"
"Coron","Coron","12.0000","120.2000","Philippines","PH","PHL","Palawan","","65855","1608577211"
"Michigan City","Michigan City","41.7092","-86.8690","United States","US","USA","Indiana","","65946","1840013869"
"Menzel Temime","Menzel Temime","36.7833","10.9833","Tunisia","TN","TUN","Nabeul","","65645","1788000016"
"Weimar","Weimar","50.9833","11.3167","Germany","DE","DEU","Thuringia","minor","65138","1276053525"
"Villasis","Villasis","15.9000","120.5833","Philippines","PH","PHL","Pangasinan","","65047","1608468696"
"Santo Tomé","Santo Tome","-31.6667","-60.7667","Argentina","AR","ARG","Santa Fe","","65684","1032375645"
"Neuwied","Neuwied","50.4286","7.4614","Germany","DE","DEU","Rhineland-Palatinate","minor","65137","1276935443"
"Lutayan","Lutayan","6.6000","124.8500","Philippines","PH","PHL","Maguindanao","","65644","1608798193"
"Gopichettipālaiyam","Gopichettipalaiyam","11.4549","77.4365","India","IN","IND","Tamil Nādu","","65238","1356481097"
"Nieuwegein","Nieuwegein","52.0333","5.0833","Netherlands","NL","NLD","Utrecht","minor","63866","1528701694"
"Xiantangcun","Xiantangcun","23.7940","114.7447","China","CN","CHN","Guangdong","","65491","1156559805"
"Ina","Ina","35.8275","137.9540","Japan","JP","JPN","Nagano","","65670","1392422546"
"Sirsi","Sirsi","28.6400","78.6400","India","IN","IND","Uttar Pradesh","","64345","1356633864"
"Tupã","Tupa","-21.9350","-50.5139","Brazil","BR","BRA","São Paulo","","65651","1076738637"
"Solano","Solano","16.5183","121.1811","Philippines","PH","PHL","Nueva Vizcaya","","65287","1608750917"
"Dhūri","Dhuri","30.3685","75.8679","India","IN","IND","Punjab","","65215","1356294772"
"Jagraon","Jagraon","30.7800","75.4800","India","IN","IND","Punjab","","65240","1356951702"
"Daule","Daule","-1.8667","-79.9833","Ecuador","EC","ECU","Guayas","","65145","1218253183"
"Nagcarlan","Nagcarlan","14.1364","121.4165","Philippines","PH","PHL","Laguna","","64866","1608123291"
"Zarechnyy","Zarechnyy","53.2000","45.1667","Russia","RU","RUS","Penzenskaya Oblast’","","65156","1643027966"
"Bordj Menaïel","Bordj Menaiel","36.7417","3.7231","Algeria","DZ","DZA","Boumerdes","","64820","1012287430"
"Istaravshan","Istaravshan","39.9108","69.0064","Tajikistan","TJ","TJK","Sughd","minor","65600","1762380072"
"Victoria","Victoria","28.8287","-96.9849","United States","US","USA","Texas","","65643","1840023044"
"Sinnar","Sinnar","19.8500","74.0000","India","IN","IND","Mahārāshtra","","65299","1356138889"
"Ladysmith","Ladysmith","-28.5597","29.7806","South Africa","ZA","ZAF","KwaZulu-Natal","","64855","1710998347"
"Villach","Villach","46.6167","13.8333","Austria","AT","AUT","Kärnten","minor","65127","1040202364"
"Bourges","Bourges","47.0844","2.3964","France","FR","FRA","Centre-Val de Loire","minor","64668","1250043242"
"Alīpur Duār","Alipur Duar","26.4890","89.5270","India","IN","IND","West Bengal","","65232","1356472105"
"Torquay","Torquay","50.4700","-3.5300","United Kingdom","GB","GBR","Torbay","","65245","1826982921"
"Purulhá","Purulha","15.2353","-90.2350","Guatemala","GT","GTM","Baja Verapaz","minor","65340","1320914723"
"Gloucester","Gloucester","39.7924","-75.0363","United States","US","USA","New Jersey","","65602","1840081684"
"St. Albert","St. Albert","53.6303","-113.6258","Canada","CA","CAN","Alberta","","65589","1124850754"
"Roeselare","Roeselare","50.9447","3.1233","Belgium","BE","BEL","Flanders","minor","64495","1056334112"
"Ferrol","Ferrol","43.4844","-8.2328","Spain","ES","ESP","Galicia","","64785","1724806861"
"Bāqershahr","Baqershahr","35.5325","51.4025","Iran","IR","IRN","Tehrān","","65388","1364526843"
"Xarardheere","Xarardheere","4.6544","47.8575","Somalia","SO","SOM","Mudug","","65523","1706020308"
"Jocotitlán","Jocotitlan","19.7072","-99.7867","Mexico","MX","MEX","México","minor","65291","1484167307"
"Rāth","Rath","25.5800","79.5700","India","IN","IND","Uttar Pradesh","","65056","1356300745"
"Vaijāpur","Vaijapur","19.9200","74.7300","India","IN","IND","Mahārāshtra","","65296","1356386676"
"Los Amates","Los Amates","15.2667","-89.1000","Guatemala","GT","GTM","Izabal","minor","65475","1320690487"
"Icó","Ico","-6.4008","-38.8619","Brazil","BR","BRA","Ceará","","65456","1076569458"
"Beloretsk","Beloretsk","53.9667","58.4000","Russia","RU","RUS","Bashkortostan","","65477","1643447458"
"Jaisalmer","Jaisalmer","26.9167","70.9167","India","IN","IND","Rājasthān","","65471","1356575402"
"Januária","Januaria","-15.4878","-44.3619","Brazil","BR","BRA","Minas Gerais","","65463","1076924780"
"Weirton","Weirton","40.4060","-80.5671","United States","US","USA","West Virginia","","65447","1840005405"
"Tāndūr","Tandur","17.2300","77.5800","India","IN","IND","Andhra Pradesh","","65115","1356472437"
"Ishimbay","Ishimbay","53.4500","56.0333","Russia","RU","RUS","Bashkortostan","","65422","1643130656"
"Leszno","Leszno","51.8458","16.5806","Poland","PL","POL","Wielkopolskie","minor","63505","1616030015"
"Peruíbe","Peruibe","-24.3100","-47.0000","Brazil","BR","BRA","São Paulo","","65226","1076398365"
"Ārān Bīdgol","Aran Bidgol","34.0575","51.4828","Iran","IR","IRN","Eşfahān","minor","65404","1364946839"
"Korosten","Korosten","50.9500","28.6500","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","63525","1804648682"
"Jamjamāl","Jamjamal","35.5333","44.8333","Iraq","IQ","IRQ","As Sulaymānīyah","minor","65300","1368297719"
"Birnin Konni","Birnin Konni","13.7904","5.2599","Niger","NE","NER","Tahoua","minor","65252","1562868655"
"Novi","Novi","42.4786","-83.4893","United States","US","USA","Michigan","","65369","1840002423"
"Develi","Develi","38.3886","35.4925","Turkey","TR","TUR","Kayseri","minor","65322","1792088923"
"Quezon","Quezon","9.2350","117.9967","Philippines","PH","PHL","Palawan","","65283","1608588871"
"Idappādi","Idappadi","11.5835","77.8341","India","IN","IND","Tamil Nādu","","64435","1356448099"
"Villa Curuguaty","Villa Curuguaty","-24.4800","-55.7100","Paraguay","PY","PRY","Canindeyú","","65310","1600752971"
"Dormagen","Dormagen","51.1000","6.8167","Germany","DE","DEU","North Rhine-Westphalia","","64553","1276688551"
"Plauen","Plauen","50.4833","12.1167","Germany","DE","DEU","Saxony","minor","64660","1276134198"
"Sandefjord","Sandefjord","59.1306","10.2167","Norway","NO","NOR","Vestfold og Telemark","","64943","1578389701"
"Pālitāna","Palitana","21.5200","71.8300","India","IN","IND","Gujarāt","","64497","1356100837"
"Odendaalsrus","Odendaalsrus","-27.8667","26.6833","South Africa","ZA","ZAF","Free State","","63743","1710700901"
"Chunian","Chunian","30.9639","73.9803","Pakistan","PK","PAK","Punjab","","64386","1586655697"
"Ḩalabjah","Halabjah","35.1833","45.9833","Iraq","IQ","IRQ","As Sulaymānīyah","minor","65200","1368595428"
"Viana","Viana","-20.3900","-40.4958","Brazil","BR","BRA","Espírito Santo","","65001","1076304289"
"Rosenheim","Rosenheim","47.8500","12.1333","Germany","DE","DEU","Bavaria","minor","63508","1276301675"
"Marinilla","Marinilla","6.1738","-75.3347","Colombia","CO","COL","Antioquia","minor","64645","1170499821"
"Alpharetta","Alpharetta","34.0704","-84.2738","United States","US","USA","Georgia","","65187","1840013659"
"Formiga","Formiga","-20.4639","-45.4258","Brazil","BR","BRA","Minas Gerais","","65128","1076994550"
"Mecheria","Mecheria","33.5500","-0.2833","Algeria","DZ","DZA","Naama","","65043","1012958256"
"Wesley Chapel","Wesley Chapel","28.2106","-82.3238","United States","US","USA","Florida","","65134","1840014118"
"Esquipulas","Esquipulas","14.6167","-89.2000","Guatemala","GT","GTM","Chiquimula","minor","65000","1320011938"
"Caripito","Caripito","10.1124","-63.1034","Venezuela","VE","VEN","Monagas","minor","64955","1862107520"
"Maasin","Maasin","5.8667","125.0000","Philippines","PH","PHL","Sarangani","","64940","1608825863"
"Pokrovsk","Pokrovsk","48.2828","37.1828","Ukraine","UA","UKR","Donetska Oblast","minor","63437","1804910861"
"Ban Lam Sam Kaeo","Ban Lam Sam Kaeo","13.9728","100.6511","Thailand","TH","THA","Pathum Thani","","63271","1764012428"
"Svyetlahorsk","Svyetlahorsk","52.6333","29.7333","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","64989","1112741799"
"Marovoay","Marovoay","-16.1111","46.6439","Madagascar","MG","MDG","Mahajanga","","65000","1450283819"
"Soloma","Soloma","15.7167","-91.4500","Guatemala","GT","GTM","Huehuetenango","minor","64772","1320272967"
"Bāzār-e Yakāwlang","Bazar-e Yakawlang","34.7333","66.9667","Afghanistan","AF","AFG","Bāmyān","","65000","1004505407"
"Ra’s al Khafjī","Ra's al Khafji","28.4167","48.5000","Saudi Arabia","SA","SAU","Ash Sharqīyah","","65000","1682230104"
"Chiquimulilla","Chiquimulilla","14.0858","-90.3822","Guatemala","GT","GTM","Santa Rosa","minor","64871","1320719368"
"Itō","Ito","34.9657","139.1019","Japan","JP","JPN","Shizuoka","","64473","1392003261"
"Tādepalle","Tadepalle","16.4833","80.6000","India","IN","IND","Andhra Pradesh","","64149","1356511543"
"Bucak","Bucak","37.4592","30.5953","Turkey","TR","TUR","Burdur","minor","64943","1792073114"
"Donskoy","Donskoy","53.9658","38.3247","Russia","RU","RUS","Tul’skaya Oblast’","","63631","1643224911"
"Yanagawa","Yanagawa","33.1597","130.4081","Japan","JP","JPN","Fukuoka","","64120","1392003166"
"Miyoshi","Miyoshi","35.0894","137.0748","Japan","JP","JPN","Aichi","","62963","1392003202"
"Isabela","Isabela","10.2000","122.9833","Philippines","PH","PHL","Negros Occidental","","64516","1608475400"
"Saravia","Saravia","10.8833","122.9667","Philippines","PH","PHL","Negros Occidental","","64290","1608260082"
"Bertioga","Bertioga","-23.8539","-46.1389","Brazil","BR","BRA","São Paulo","","64723","1076752421"
"Hilongos","Hilongos","10.3667","124.7500","Philippines","PH","PHL","Leyte","","64514","1608448862"
"Neubrandenburg","Neubrandenburg","53.5569","13.2611","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","64086","1276954105"
"Gadwāl","Gadwal","16.2300","77.8000","India","IN","IND","Andhra Pradesh","","63177","1356741221"
"Yattir","Yattir","31.4478","35.0900","West Bank","XW","XWB","","","64277","1934947354"
"Sibulan","Sibulan","9.3500","123.2833","Philippines","PH","PHL","Negros Oriental","","64343","1608031686"
"Jatani","Jatani","20.1700","85.7000","India","IN","IND","Odisha","","63697","1356087327"
"Chipindo","Chipindo","-13.8244","15.8000","Angola","AO","AGO","Huíla","","64714","1024089157"
"Seydişehir","Seydisehir","37.4183","31.8506","Turkey","TR","TUR","Konya","minor","64687","1792010255"
"Kodād","Kodad","16.9978","79.9653","India","IN","IND","Andhra Pradesh","","64234","1356852582"
"Santo Domingo Tehuantepec","Santo Domingo Tehuantepec","16.3184","-95.2478","Mexico","MX","MEX","Oaxaca","minor","64639","1484904308"
"Meulaboh","Meulaboh","4.1333","96.1167","Indonesia","ID","IDN","Aceh","minor","64646","1360010778"
"Chokwé","Chokwe","-24.5253","33.0086","Mozambique","MZ","MOZ","Gaza","","63695","1508737607"
"Binga","Binga","2.4000","20.4200","Congo (Kinshasa)","CD","COD","Mongala","","64639","1180236070"
"Waltham","Waltham","42.3889","-71.2423","United States","US","USA","Massachusetts","","64655","1840000428"
"Sodegaura","Sodegaura","35.4300","139.9544","Japan","JP","JPN","Chiba","","63975","1392003058"
"Cruz das Almas","Cruz das Almas","-12.6700","-39.1019","Brazil","BR","BRA","Bahia","","64197","1076285577"
"Rahat","Rahat","31.3925","34.7544","Israel","IL","ISR","Southern","","64462","1376207828"
"San Felipe","San Felipe","-32.7500","-70.7239","Chile","CL","CHL","Valparaíso","minor","64120","1152329463"
"Tamana","Tamana","32.9281","130.5594","Japan","JP","JPN","Kumamoto","","64206","1392407764"
"Apaseo el Alto","Apaseo el Alto","20.4500","-100.6167","Mexico","MX","MEX","Guanajuato","minor","64443","1484638153"
"Laguna Niguel","Laguna Niguel","33.5275","-117.7050","United States","US","USA","California","","64608","1840020589"
"Hiriyūr","Hiriyur","13.9446","76.6172","India","IN","IND","Karnātaka","","64423","1356254728"
"San Clemente","San Clemente","33.4499","-117.6102","United States","US","USA","California","","64600","1840021962"
"Qinggang","Qinggang","46.6900","126.1000","China","CN","CHN","Heilongjiang","minor","64182","1156115921"
"Vittoria","Vittoria","36.9500","14.5333","Italy","IT","ITA","Sicilia","","64212","1380563861"
"Pirané","Pirane","-25.7328","-59.1100","Argentina","AR","ARG","Formosa","minor","64556","1032332556"
"Grevenbroich","Grevenbroich","51.0883","6.5875","Germany","DE","DEU","North Rhine-Westphalia","","63922","1276215861"
"Dhuburi","Dhuburi","26.0200","90.0000","India","IN","IND","Assam","","63338","1356620816"
"Atimonan","Atimonan","14.0036","121.9199","Philippines","PH","PHL","Quezon","","64260","1608726423"
"Nānpāra","Nanpara","27.8700","81.5000","India","IN","IND","Uttar Pradesh","","62782","1356453583"
"Lingshou","Lingshou","38.3063","114.3879","China","CN","CHN","Hebei","","62713","1156202579"
"Santiago Tianguistenco","Santiago Tianguistenco","19.1797","-99.4706","Mexico","MX","MEX","México","minor","64365","1484113134"
"Nawalgarh","Nawalgarh","27.8500","75.2700","India","IN","IND","Rājasthān","","63948","1356085018"
"Estância","Estancia","-11.2678","-37.4378","Brazil","BR","BRA","Sergipe","","64409","1076069381"
"Tenri","Tenri","34.5967","135.8372","Japan","JP","JPN","Nara","","63760","1392003530"
"North Little Rock","North Little Rock","34.7807","-92.2371","United States","US","USA","Arkansas","","64444","1840014632"
"Irún","Irun","43.3378","-1.7888","Spain","ES","ESP","Basque Country","","62933","1724796753"
"Fraijanes","Fraijanes","14.4622","-90.4386","Guatemala","GT","GTM","Guatemala","minor","63721","1320070590"
"Ouricuri","Ouricuri","-7.8828","-40.0819","Brazil","BR","BRA","Pernambuco","","64358","1076194030"
"Pomezia","Pomezia","41.6693","12.5021","Italy","IT","ITA","Lazio","","63641","1380470415"
"Tohāna","Tohana","29.7000","75.9000","India","IN","IND","Haryāna","","63871","1356505021"
"Ciénaga de Oro","Cienaga de Oro","8.8833","-75.6167","Colombia","CO","COL","Córdoba","minor","64226","1170711004"
"Samāstipur","Samastipur","25.8629","85.7811","India","IN","IND","Bihār","","62935","1356801161"
"Salto de Agua","Salto de Agua","17.4167","-92.1833","Mexico","MX","MEX","Chiapas","minor","64251","1484764202"
"Crotone","Crotone","39.0833","17.1167","Italy","IT","ITA","Calabria","minor","63941","1380444441"
"Ghardimaou","Ghardimaou","36.4500","8.4333","Tunisia","TN","TUN","Jendouba","","64170","1788721346"
"Bamei","Bamei","24.2634","105.0809","China","CN","CHN","Yunnan","","64188","1156724753"
"Tuao","Tuao","17.7350","121.4553","Philippines","PH","PHL","Cagayan","","63970","1608369053"
"Sangmélima","Sangmelima","2.9333","11.9833","Cameroon","CM","CMR","Sud","","64227","1120387598"
"Sheikhpura","Sheikhpura","25.1403","85.8508","India","IN","IND","Bihār","","62927","1356900289"
"Nawá","Nawa","32.8889","36.0431","Syria","SY","SYR","Dar‘ā","minor","63676","1760963983"
"At Tall","At Tall","33.6000","36.3000","Syria","SY","SYR","Rīf Dimashq","minor","63554","1760718626"
"Asbest","Asbest","57.0000","61.4667","Russia","RU","RUS","Sverdlovskaya Oblast’","","64091","1643561210"
"Póvoa de Varzim","Povoa de Varzim","41.3800","-8.7608","Portugal","PT","PRT","Porto","minor","63408","1620617429"
"Niksar","Niksar","40.5833","36.9667","Turkey","TR","TUR","Tokat","minor","64119","1792863464"
"Penedo","Penedo","-10.2900","-36.5858","Brazil","BR","BRA","Alagoas","minor","64074","1076906021"
"Fairbanks","Fairbanks","64.8353","-147.6533","United States","US","USA","Alaska","","64160","1840023463"
"Sibalom","Sibalom","10.7883","122.0175","Philippines","PH","PHL","Antique","","63833","1608887661"
"Tiquisate","Tiquisate","14.2833","-91.3667","Guatemala","GT","GTM","Escuintla","minor","63971","1320708461"
"Kangbao","Kangbao","41.8513","114.6091","China","CN","CHN","Hebei","minor","63779","1156122779"
"Māhdāsht","Mahdasht","35.7194","50.7978","Iran","IR","IRN","Tehrān","","62910","1364057697"
"Maidenhead","Maidenhead","51.5217","-0.7177","United Kingdom","GB","GBR","Windsor and Maidenhead","","63580","1826979451"
"Simav","Simav","39.0833","28.9833","Turkey","TR","TUR","Kütahya","minor","64015","1792011375"
"Eden Prairie","Eden Prairie","44.8488","-93.4595","United States","US","USA","Minnesota","","64048","1840007821"
"Armūr","Armur","18.7900","78.2900","India","IN","IND","Andhra Pradesh","","64023","1356043277"
"West Hartford","West Hartford","41.7669","-72.7536","United States","US","USA","Connecticut","","64034","1840035652"
"Quimper","Quimper","47.9967","-4.0964","France","FR","FRA","Bretagne","minor","63283","1250490127"
"Lugano","Lugano","46.0050","8.9525","Switzerland","CH","CHE","Ticino","","63185","1756503816"
"Chik Ballāpur","Chik Ballapur","13.4300","77.7200","India","IN","IND","Karnātaka","","63652","1356871177"
"Pflugerville","Pflugerville","30.4515","-97.6018","United States","US","USA","Texas","","64007","1840020892"
"Asahi","Asahi","35.7167","140.6500","Japan","JP","JPN","Chiba","","63507","1392803608"
"Santa Cruz","Santa Cruz","15.7667","119.9167","Philippines","PH","PHL","Zambales","","63839","1608192524"
"Yangqingcun","Yangqingcun","21.3594","110.1164","China","CN","CHN","Guangdong","","63578","1156544333"
"Tsuruga","Tsuruga","35.6452","136.0555","Japan","JP","JPN","Fukui","","63724","1392056382"
"Ponferrada","Ponferrada","42.5461","-6.5908","Spain","ES","ESP","Castille-Leon","","63747","1724164693"
"Casper","Casper","42.8420","-106.3208","United States","US","USA","Wyoming","","63962","1840038324"
"Jose Pañganiban","Jose Panganiban","14.2922","122.6928","Philippines","PH","PHL","Camarines Norte","","63662","1608483049"
"Boadilla del Monte","Boadilla del Monte","40.4069","-3.8750","Spain","ES","ESP","Madrid","","62627","1724646651"
"Vigevano","Vigevano","45.3167","8.8667","Italy","IT","ITA","Lombardy","","63153","1380675509"
"Burnsville","Burnsville","44.7648","-93.2793","United States","US","USA","Minnesota","","63926","1840006766"
"Athni","Athni","16.7300","75.0700","India","IN","IND","Karnātaka","","63625","1356105261"
"Klintsy","Klintsy","52.7500","32.2333","Russia","RU","RUS","Bryanskaya Oblast’","","62936","1643620360"
"Midoun","Midoun","33.8000","11.0000","Tunisia","TN","TUN","Médenine","","63528","1788034420"
"Sittingbourne","Sittingbourne","51.3400","0.7400","United Kingdom","GB","GBR","Kent","","62500","1826787936"
"Bognor Regis","Bognor Regis","50.7824","-0.6764","United Kingdom","GB","GBR","West Sussex","","63885","1826873593"
"Zvornik","Zvornik","44.3833","19.1000","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","63686","1070289722"
"Bhairāhawā","Bhairahawa","27.5000","83.4500","Nepal","NP","NPL","","","63483","1524444140"
"Sherkot","Sherkot","29.3500","78.5800","India","IN","IND","Uttar Pradesh","","62226","1356441053"
"Nankana Sahib","Nankana Sahib","31.4500","73.7067","Pakistan","PK","PAK","Punjab","","63073","1586529540"
"Dikirnis","Dikirnis","31.0883","31.5969","Egypt","EG","EGY","Ad Daqahlīyah","","62177","1818065059"
"Woking","Woking","51.3050","-0.5390","United Kingdom","GB","GBR","Surrey","","62796","1826205427"
"Zarand","Zarand","30.8128","56.5639","Iran","IR","IRN","Kermān","minor","63744","1364870175"
"Kurihara","Kurihara","38.7301","141.0215","Japan","JP","JPN","Miyagi","","63689","1392698850"
"Grand Forks","Grand Forks","47.9214","-97.0884","United States","US","USA","North Dakota","","63767","1840000101"
"Randers","Randers","56.4570","10.0390","Denmark","DK","DNK","Midtjylland","minor","62802","1208640875"
"Velika Gorica","Velika Gorica","45.7000","16.0667","Croatia","HR","HRV","Zagrebačka Županija","minor","63517","1191113594"
"Escada","Escada","-8.3592","-35.2236","Brazil","BR","BRA","Pernambuco","","63517","1076990073"
"Chincha Alta","Chincha Alta","-13.4500","-76.1333","Peru","PE","PER","Ica","","63671","1604035626"
"Visnagar","Visnagar","23.7000","72.5500","India","IN","IND","Gujarāt","","63073","1356690813"
"Guiglo","Guiglo","6.5500","-7.4833","Côte d'Ivoire","CI","CIV","Montagnes","minor","63643","1384483732"
"Brentwood","Brentwood","37.9356","-121.7190","United States","US","USA","California","","63618","1840018904"
"Tajumulco","Tajumulco","15.0839","-91.9233","Guatemala","GT","GTM","San Marcos","minor","63405","1320562305"
"Graaff-Reinet","Graaff-Reinet","-32.2522","24.5406","South Africa","ZA","ZAF","Eastern Cape","","62896","1710616617"
"Herten","Herten","51.6000","7.1333","Germany","DE","DEU","North Rhine-Westphalia","","61910","1276440470"
"Moncada","Moncada","15.7331","120.5725","Philippines","PH","PHL","Tarlac","","62819","1608310036"
"Wenxicun","Wenxicun","28.1565","120.3824","China","CN","CHN","Zhejiang","","62407","1156382602"
"Aïn Sefra","Ain Sefra","32.7500","-0.5833","Algeria","DZ","DZA","Naama","","63420","1012884513"
"Galgamuwa","Galgamuwa","8.0000","80.2831","Sri Lanka","LK","LKA","North Western","","63273","1144102254"
"Rouiba","Rouiba","36.7333","3.2833","Algeria","DZ","DZA","Alger","","61984","1012334934"
"Bhabhua","Bhabhua","25.0500","83.6200","India","IN","IND","Bihār","","61999","1356880067"
"Budënnovsk","Budennovsk","44.7833","44.1667","Russia","RU","RUS","Stavropol’skiy Kray","","62495","1643010057"
"Badr Ḩunayn","Badr Hunayn","23.7800","38.7906","Saudi Arabia","SA","SAU","Al Madīnah al Munawwarah","","63468","1682157831"
"Khulayş","Khulays","22.1539","39.3183","Saudi Arabia","SA","SAU","Makkah al Mukarramah","","63445","1682152302"
"Asaka","Asaka","40.6333","72.2333","Uzbekistan","UZ","UZB","Andijon","minor","62200","1860622196"
"Greenwich","Greenwich","41.0665","-73.6368","United States","US","USA","Connecticut","","63455","1840034747"
"Rolândia","Rolandia","-23.3100","-51.3689","Brazil","BR","BRA","Paraná","","63316","1076756563"
"Granby","Granby","45.4000","-72.7333","Canada","CA","CAN","Quebec","","63433","1124502071"
"Millcreek","Millcreek","40.6892","-111.8292","United States","US","USA","Utah","","63430","1840018744"
"Carrara","Carrara","44.0792","10.1000","Italy","IT","ITA","Tuscany","","62537","1380429579"
"Shiroi","Shiroi","35.7915","140.0563","Japan","JP","JPN","Chiba","","61673","1392006213"
"Elmira","Elmira","42.0938","-76.8097","United States","US","USA","New York","","63404","1840000472"
"Balboa Heights","Balboa Heights","8.9500","-79.5500","Panama","PA","PAN","Panamá","","62882","1591234164"
"Nago","Nago","26.5917","127.9775","Japan","JP","JPN","Okinawa","","63060","1392102097"
"Mercedes","Mercedes","-34.6500","-59.4333","Argentina","AR","ARG","Buenos Aires","minor","63284","1032994289"
"Santo Antônio do Descoberto","Santo Antonio do Descoberto","-15.9400","-48.2550","Brazil","BR","BRA","Goiás","","63248","1076123384"
"Wundanyi","Wundanyi","-3.3983","38.3603","Kenya","KE","KEN","Taita/Taveta","","62340","1404917325"
"Rānipet","Ranipet","12.9247","79.3333","India","IN","IND","Tamil Nādu","","62360","1356874157"
"Sebring","Sebring","27.4770","-81.4530","United States","US","USA","Florida","","63287","1840015986"
"Chełm","Chelm","51.1322","23.4778","Poland","PL","POL","Lubelskie","minor","61588","1616688578"
"Abū Qurqāş","Abu Qurqas","27.9333","30.8333","Egypt","EG","EGY","Al Minyā","","63264","1818889126"
"Coon Rapids","Coon Rapids","45.1755","-93.3094","United States","US","USA","Minnesota","","63269","1840006714"
"Medicine Hat","Medicine Hat","50.0417","-110.6775","Canada","CA","CAN","Alberta","","63260","1124303972"
"Bannu","Bannu","32.9864","70.6044","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","62191","1586858934"
"Volsk","Volsk","52.0500","47.3833","Russia","RU","RUS","Saratovskaya Oblast’","","63212","1643293120"
"Tartagal","Tartagal","-22.5000","-63.8333","Argentina","AR","ARG","Salta","minor","63196","1032317391"
"Meshkīn Dasht","Meshkin Dasht","35.7469","50.9383","Iran","IR","IRN","Tehrān","","62005","1364951738"
"Kaga","Kaga","36.3028","136.3150","Japan","JP","JPN","Ishikawa","","62997","1392003175"
"Wāshīm","Washim","20.1000","77.1500","India","IN","IND","Mahārāshtra","","62863","1356185959"
"Qurayyāt","Qurayyat","23.3200","58.9080","Oman","OM","OMN","Masqaţ","","63133","1512111751"
"Bossier City","Bossier City","32.5224","-93.6668","United States","US","USA","Louisiana","","63168","1840013790"
"Novaya Balakhna","Novaya Balakhna","56.4899","43.6011","Russia","RU","RUS","Nizhegorodskaya Oblast’","","63083","1643658083"
"Grande Prairie","Grande Prairie","55.1708","-118.7947","Canada","CA","CAN","Alberta","","63166","1124505481"
"Kōshizuka","Koshizuka","32.8860","130.7897","Japan","JP","JPN","Kumamoto","","61997","1392770525"
"Oudtshoorn","Oudtshoorn","-33.5833","22.2000","South Africa","ZA","ZAF","Western Cape","","61507","1710502855"
"Bansalan","Bansalan","6.7833","125.2167","Philippines","PH","PHL","Davao del Sur","","62737","1608747221"
"San Fernando","San Fernando","7.9178","125.3286","Philippines","PH","PHL","Bukidnon","","63045","1608257172"
"Hamilton","Hamilton","39.3939","-84.5653","United States","US","USA","Ohio","","63127","1840003803"
"Chidambaram","Chidambaram","11.4070","79.6912","India","IN","IND","Tamil Nādu","","62153","1356547124"
"Itupeva","Itupeva","-23.1531","-47.0578","Brazil","BR","BRA","São Paulo","","62813","1076387390"
"Łomża","Lomza","53.1764","22.0731","Poland","PL","POL","Podlaskie","minor","62700","1616678952"
"Calinog","Calinog","11.1333","122.5000","Philippines","PH","PHL","Iloilo","","62853","1608343887"
"Santa Ana","Santa Ana","15.0939","120.7681","Philippines","PH","PHL","Pampanga","","61537","1608719510"
"Taylor","Taylor","42.2260","-83.2688","United States","US","USA","Michigan","","63071","1840003965"
"Puerto Asís","Puerto Asis","0.5167","-76.5000","Colombia","CO","COL","Putumayo","minor","63067","1170627964"
"Lower Merion","Lower Merion","40.0282","-75.2807","United States","US","USA","Pennsylvania","","63064","1840142131"
"Çaldıran","Caldiran","39.1419","43.9139","Turkey","TR","TUR","Van","minor","63013","1792259607"
"Batatais","Batatais","-20.8911","-47.5850","Brazil","BR","BRA","São Paulo","","62980","1076581214"
"Kasongo","Kasongo","-4.4500","26.6500","Congo (Kinshasa)","CD","COD","Maniema","","63000","1180000473"
"Sirsi","Sirsi","14.6195","74.8354","India","IN","IND","Karnātaka","","62882","1356142917"
"São Borja","Sao Borja","-28.6608","-56.0039","Brazil","BR","BRA","Rio Grande do Sul","","62990","1076315333"
"Pesqueira","Pesqueira","-8.3617","-36.6947","Brazil","BR","BRA","Pernambuco","","62931","1076637932"
"Lakewood","Lakewood","47.1628","-122.5299","United States","US","USA","Washington","","62972","1840019860"
"Palmela","Palmela","38.5667","-8.9000","Portugal","PT","PRT","Setúbal","minor","62831","1620934052"
"Fujioka","Fujioka","36.2587","139.0746","Japan","JP","JPN","Gunma","","62608","1392000076"
"Yurimaguas","Yurimaguas","-5.9000","-76.0833","Peru","PE","PER","Loreto","","62903","1604248295"
"Lāharpur","Laharpur","27.7200","80.9000","India","IN","IND","Uttar Pradesh","","61999","1356525825"
"Greenwood","Greenwood","39.6019","-86.1073","United States","US","USA","Indiana","","62914","1840007332"
"Tighenif","Tighenif","35.4167","0.3330","Algeria","DZ","DZA","Mascara","","62210","1012981905"
"Nkawkaw","Nkawkaw","6.5500","-0.7667","Ghana","GH","GHA","Eastern","","62667","1288350107"
"Bellevue","Bellevue","41.1485","-95.9390","United States","US","USA","Nebraska","","62888","1840007142"
"Pavlovskiy Posad","Pavlovskiy Posad","55.7667","38.6667","Russia","RU","RUS","Moskovskaya Oblast’","","62718","1643881333"
"Mut","Mut","36.6333","33.4333","Turkey","TR","TUR","Mersin","minor","62853","1792759065"
"Cruz Alta","Cruz Alta","-28.6386","-53.6064","Brazil","BR","BRA","Rio Grande do Sul","","62821","1076542905"
"Rossosh","Rossosh","50.2000","39.5500","Russia","RU","RUS","Voronezhskaya Oblast’","minor","62827","1643115757"
"Widnes","Widnes","53.3630","-2.7280","United Kingdom","GB","GBR","Halton","","61464","1826947686"
"Kapatagan","Kapatagan","7.9000","123.7667","Philippines","PH","PHL","Lanao del Norte","","62571","1608715438"
"Sumbawa Besar","Sumbawa Besar","-8.5000","117.4167","Indonesia","ID","IDN","Nusa Tenggara Barat","minor","62753","1360601862"
"Bodītī","Boditi","6.8667","37.8667","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","61983","1231974220"
"Camaquã","Camaqua","-30.8528","-51.8153","Brazil","BR","BRA","Rio Grande do Sul","","62764","1076449009"
"Margate","Margate","51.3850","1.3838","United Kingdom","GB","GBR","Kent","","61223","1826808237"
"Oriximiná","Oriximina","-1.7658","-55.8658","Brazil","BR","BRA","Pará","","62794","1076567469"
"Żory","Zory","50.0500","18.7000","Poland","PL","POL","Śląskie","minor","61823","1616545481"
"Kolomyia","Kolomyia","48.5167","25.0333","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","61269","1804285171"
"Qoryooley","Qoryooley","1.7833","44.5333","Somalia","SO","SOM","Shabeellaha Hoose","minor","62700","1706713350"
"Revda","Revda","56.8053","59.9283","Russia","RU","RUS","Sverdlovskaya Oblast’","","62687","1643633975"
"Anse à Galets","Anse a Galets","18.8333","-72.8667","Haiti","HT","HTI","Ouest","","62559","1332098898"
"Borisoglebsk","Borisoglebsk","51.3667","42.0833","Russia","RU","RUS","Voronezhskaya Oblast’","","62669","1643002462"
"Kotlas","Kotlas","61.2500","46.6333","Russia","RU","RUS","Arkhangel’skaya Oblast’","","61805","1643953441"
"Deva","Deva","45.8781","22.9144","Romania","RO","ROU","Hunedoara","","61123","1642728479"
"Zelenogorsk","Zelenogorsk","56.1000","94.5833","Russia","RU","RUS","Krasnoyarskiy Kray","","62245","1643010181"
"Bainet","Bainet","18.1833","-72.7500","Haiti","HT","HTI","Sud-Est","","62300","1332091731"
"Camboriú","Camboriu","-27.0250","-48.6539","Brazil","BR","BRA","Santa Catarina","","62361","1076088300"
"Moore","Moore","35.3293","-97.4757","United States","US","USA","Oklahoma","","62633","1840020449"
"Shangtangcun","Shangtangcun","21.5989","111.5907","China","CN","CHN","Guangdong","","62287","1156688741"
"Council Bluffs","Council Bluffs","41.2369","-95.8516","United States","US","USA","Iowa","","62617","1840007117"
"Rowlett","Rowlett","32.9155","-96.5489","United States","US","USA","Texas","","62606","1840020711"
"El Wak","El Wak","2.8028","40.9275","Kenya","KE","KEN","Mandera","","60732","1404102561"
"Mobārakeh","Mobarakeh","32.3464","51.5044","Iran","IR","IRN","Eşfahān","minor","62454","1364110841"
"Leninogorsk","Leninogorsk","54.6000","52.5000","Russia","RU","RUS","Tatarstan","","62531","1643010350"
"Ţūlkarm","Tulkarm","32.3117","35.0272","West Bank","XW","XWB","","","61941","1934334760"
"Berekum","Berekum","7.4500","-2.5833","Ghana","GH","GHA","Bono","","62364","1288257467"
"Spring","Spring","30.0613","-95.3830","United States","US","USA","Texas","","62569","1840019615"
"Wels","Wels","48.1500","14.0167","Austria","AT","AUT","Oberösterreich","minor","61233","1040833210"
"Narwāna","Narwana","29.6167","76.1117","India","IN","IND","Haryāna","","62090","1356363650"
"Kayes","Kayes","-4.1681","13.2939","Congo (Brazzaville)","CG","COG","Bouenza","","62521","1178012015"
"Port Charlotte","Port Charlotte","26.9918","-82.1140","United States","US","USA","Florida","","62531","1840014200"
"Tendō","Tendo","38.3623","140.3779","Japan","JP","JPN","Yamagata","","61981","1392389398"
"Menzel Bourguiba","Menzel Bourguiba","37.1500","9.7833","Tunisia","TN","TUN","Bizerte","","61919","1788007460"
"Villa Carlos Paz","Villa Carlos Paz","-31.4000","-64.5167","Argentina","AR","ARG","Córdoba","","62423","1032854975"
"Bensalem","Bensalem","40.1086","-74.9431","United States","US","USA","Pennsylvania","","62456","1840152153"
"Reston","Reston","38.9497","-77.3461","United States","US","USA","Virginia","","62453","1840006025"
"Bergheim","Bergheim","50.9667","6.6500","Germany","DE","DEU","North Rhine-Westphalia","minor","61807","1276980188"
"Tefé","Tefe","-3.3539","-64.7108","Brazil","BR","BRA","Amazonas","","62444","1076049089"
"Kolding","Kolding","55.4917","9.5000","Denmark","DK","DNK","Syddanmark","minor","61638","1208123784"
"Friedrichshafen","Friedrichshafen","47.6500","9.4833","Germany","DE","DEU","Baden-Württemberg","","61561","1276002705"
"Riosucio","Riosucio","5.4208","-75.7025","Colombia","CO","COL","Caldas","minor","62296","1170642933"
"Siddhapur","Siddhapur","23.9167","72.3833","India","IN","IND","Gujarāt","","61867","1356103735"
"Nanjangūd","Nanjangud","12.1200","76.6800","India","IN","IND","Karnātaka","","61961","1356697205"
"Tarnowskie Góry","Tarnowskie Gory","50.4444","18.8583","Poland","PL","POL","Śląskie","minor","61686","1616868201"
"San Juan Chamelco","San Juan Chamelco","15.4257","-90.3263","Guatemala","GT","GTM","Alta Verapaz","minor","61634","1320919361"
"Boryspil","Boryspil","50.3500","30.9500","Ukraine","UA","UKR","Kyivska Oblast","minor","62281","1804757407"
"Caràzinho","Carazinho","-28.2839","-52.7858","Brazil","BR","BRA","Rio Grande do Sul","","62265","1076906274"
"Caltanissetta","Caltanissetta","37.4903","14.0625","Italy","IT","ITA","Sicilia","minor","62317","1380274368"
"Meihua","Meihua","37.8862","114.8204","China","CN","CHN","Hebei","","60791","1156108916"
"Katueté","Katuete","-24.2481","-54.7575","Paraguay","PY","PRY","Canindeyú","","62319","1600304725"
"Dearborn Heights","Dearborn Heights","42.3164","-83.2769","United States","US","USA","Michigan","","62334","1840003970"
"Tuapse","Tuapse","44.1044","39.0772","Russia","RU","RUS","Krasnodarskiy Kray","minor","62269","1643910130"
"Puerto Peñasco","Puerto Penasco","31.3167","-113.5369","Mexico","MX","MEX","Sonora","minor","62301","1484567937"
"Aurora","Aurora","13.3500","122.5167","Philippines","PH","PHL","Quezon","","62097","1608074830"
"Figueira da Foz","Figueira da Foz","40.1500","-8.8500","Portugal","PT","PRT","Coimbra","minor","62125","1620826473"
"Analavory","Analavory","-18.9667","46.7167","Madagascar","MG","MDG","Antananarivo","","62130","1450643533"
"Chistopol","Chistopol","55.3647","50.6261","Russia","RU","RUS","Tatarstan","","62200","1643992511"
"Camotán","Camotan","14.8167","-89.3667","Guatemala","GT","GTM","Chiquimula","minor","61958","1320004485"
"Itoman","Itoman","26.1236","127.6658","Japan","JP","JPN","Okinawa","","60903","1392029438"
"Port Orange","Port Orange","29.1085","-81.0137","United States","US","USA","Florida","","62197","1840015077"
"Ogōshi","Ogoshi","32.8833","130.7833","Japan","JP","JPN","Kumamoto","","61022","1392448942"
"Penápolis","Penapolis","-21.4200","-50.0778","Brazil","BR","BRA","São Paulo","","62071","1076587749"
"Itapecuru Mirim","Itapecuru Mirim","-3.3928","-44.3589","Brazil","BR","BRA","Maranhão","","62110","1076422017"
"Horsens","Horsens","55.8583","9.8500","Denmark","DK","DNK","Midtjylland","minor","61074","1208409201"
"Encinitas","Encinitas","33.0492","-117.2610","United States","US","USA","California","","62140","1840020619"
"Springfield","Springfield","44.0538","-122.9811","United States","US","USA","Oregon","","62138","1840021263"
"Mitoyo","Mitoyo","34.1825","133.7150","Japan","JP","JPN","Kagawa","","61838","1392003344"
"Ipirá","Ipira","-12.1578","-39.7369","Brazil","BR","BRA","Bahia","","62095","1076297000"
"Great Falls","Great Falls","47.5022","-111.2996","United States","US","USA","Montana","","62112","1840019839"
"Asker","Asker","59.8331","10.4392","Norway","NO","NOR","Viken","minor","61523","1578961890"
"Cambanugoy","Cambanugoy","7.5386","125.7508","Philippines","PH","PHL","Davao del Norte","","61893","1608478243"
"Naro-Fominsk","Naro-Fominsk","55.3833","36.7667","Russia","RU","RUS","Moskovskaya Oblast’","","62002","1643037888"
"Grajaú","Grajau","-5.8189","-46.1389","Brazil","BR","BRA","Maranhão","","62093","1076344134"
"Morada Nova","Morada Nova","-5.1069","-38.3728","Brazil","BR","BRA","Ceará","","62065","1076522163"
"Ostuncalco","Ostuncalco","14.8693","-91.6211","Guatemala","GT","GTM","Quetzaltenango","minor","61517","1320940734"
"Pacajus","Pacajus","-4.1728","-38.4608","Brazil","BR","BRA","Ceará","","61838","1076601122"
"Viareggio","Viareggio","43.8672","10.2506","Italy","IT","ITA","Tuscany","","62079","1380033840"
"Baao","Baao","13.4535","123.3654","Philippines","PH","PHL","Camarines Sur","","61493","1608696551"
"Jhārgrām","Jhargram","22.4500","86.9800","India","IN","IND","West Bengal","","61712","1356046912"
"Rāmanāthapuram","Ramanathapuram","9.3639","78.8395","India","IN","IND","Tamil Nādu","","61440","1356182496"
"Manicaragua","Manicaragua","22.1500","-79.9764","Cuba","CU","CUB","Villa Clara","minor","61990","1192831620"
"Zaraza","Zaraza","9.3394","-65.3167","Venezuela","VE","VEN","Guárico","minor","62027","1862643668"
"Candon","Candon","17.1958","120.4489","Philippines","PH","PHL","Ilocos Sur","","61432","1608761578"
"San Francisco","San Francisco","-31.4356","-62.0714","Argentina","AR","ARG","Córdoba","minor","62001","1032491643"
"Qiryat Gat","Qiryat Gat","31.6061","34.7717","Israel","IL","ISR","Southern","","61817","1376511466"
"Rocky Mount","Rocky Mount","35.9685","-77.8040","United States","US","USA","North Carolina","","61952","1840014490"
"La Democracia","La Democracia","15.6333","-91.8667","Guatemala","GT","GTM","Huehuetenango","minor","61486","1320454944"
"Ar Rastan","Ar Rastan","34.9167","36.7333","Syria","SY","SYR","Ḩimş","minor","61176","1760205423"
"San Antonio del Táchira","San Antonio del Tachira","7.8145","-72.4431","Venezuela","VE","VEN","Táchira","minor","61630","1862415614"
"Diphu","Diphu","25.8300","93.4300","India","IN","IND","Assam","","61797","1356140802"
"Santo Domingo","Santo Domingo","15.5900","120.8786","Philippines","PH","PHL","Nueva Ecija","","61092","1608625033"
"Polevskoy","Polevskoy","56.4500","60.1833","Russia","RU","RUS","Sverdlovskaya Oblast’","","61853","1643717197"
"Plato","Plato","9.7919","-74.7872","Colombia","CO","COL","Magdalena","minor","61856","1170202938"
"Yangiyŭl","Yangiyul","41.1125","69.0467","Uzbekistan","UZ","UZB","Toshkent","","61700","1860453808"
"Sätbayev","Satbayev","47.9000","67.5333","Kazakhstan","KZ","KAZ","","","61883","1398506074"
"Piripiri","Piripiri","-4.2728","-41.7769","Brazil","BR","BRA","Piauí","","61834","1076867493"
"Sarandí","Sarandi","-34.6833","-58.3333","Argentina","AR","ARG","Buenos Aires","","60752","1032993271"
"Derik","Derik","37.3658","40.2697","Turkey","TR","TUR","Mardin","minor","61830","1792566919"
"Mielec","Mielec","50.2833","21.4333","Poland","PL","POL","Podkarpackie","minor","60628","1616825607"
"Schwäbisch Gmünd","Schwabisch Gmund","48.8000","9.8000","Germany","DE","DEU","Baden-Württemberg","","61333","1276897251"
"Hita","Hita","33.3167","130.9333","Japan","JP","JPN","Ōita","","61755","1392999996"
"Aisai","Aisai","35.1528","136.7282","Japan","JP","JPN","Aichi","","60914","1392254584"
"Santo Amaro","Santo Amaro","-12.5469","-38.7119","Brazil","BR","BRA","Bahia","","61702","1076891792"
"Montauban","Montauban","44.0181","1.3558","France","FR","FRA","Occitanie","minor","61372","1250412579"
"São Miguel dos Campos","Sao Miguel dos Campos","-9.7808","-36.0939","Brazil","BR","BRA","Alagoas","","61797","1076922200"
"Kapchagay","Kapchagay","43.8833","77.0833","Kazakhstan","KZ","KAZ","","","61767","1398649394"
"Camabatela","Camabatela","-8.1833","15.3667","Angola","AO","AGO","Cuanza-Norte","","61769","1024701540"
"Lysva","Lysva","58.1003","57.8042","Russia","RU","RUS","Permskiy Kray","","61752","1643566094"
"Ilobasco","Ilobasco","13.8400","-88.8500","El Salvador","SV","SLV","Cabañas","","61510","1222256705"
"Tríkala","Trikala","39.5500","21.7667","Greece","GR","GRC","Thessalía","minor","61653","1300446578"
"Coroatá","Coroata","-4.1300","-44.1239","Brazil","BR","BRA","Maranhão","","61725","1076890180"
"Xinmin","Xinmin","35.1180","108.0986","China","CN","CHN","Shaanxi","","61441","1156021768"
"Fairfield","Fairfield","41.1775","-73.2733","United States","US","USA","Connecticut","","61737","1840034686"
"Sungo","Sungo","-11.2333","14.3167","Angola","AO","AGO","Cuanza-Sul","","61682","1024019988"
"Rubizhne","Rubizhne","49.0336","38.3722","Ukraine","UA","UKR","Luhanska Oblast","","59951","1804246584"
"St. Thomas","St. Thomas","42.7750","-81.1833","Canada","CA","CAN","Ontario","","61707","1124790209"
"Sayyid Şādiq","Sayyid Sadiq","35.3536","45.8667","Iraq","IQ","IRQ","As Sulaymānīyah","minor","61600","1368535515"
"Orion","Orion","14.6206","120.5817","Philippines","PH","PHL","Bataan","","60771","1608943799"
"Sibay","Sibay","52.7000","58.6500","Russia","RU","RUS","Bashkortostan","","61266","1643012532"
"Sawahlunto","Sawahlunto","-0.6828","100.7783","Indonesia","ID","IDN","Sumatera Barat","","61427","1360188622"
"Cabatuan","Cabatuan","10.8833","122.4833","Philippines","PH","PHL","Iloilo","","61110","1608225814"
"Itogon","Itogon","16.3700","120.6700","Philippines","PH","PHL","Benguet","","61498","1608685404"
"Offenburg","Offenburg","48.4667","7.9333","Germany","DE","DEU","Baden-Württemberg","minor","60857","1276214401"
"Wanparti","Wanparti","16.3623","78.0622","India","IN","IND","Andhra Pradesh","","60949","1356934970"
"Hato Mayor","Hato Mayor","18.7667","-69.2500","Dominican Republic","DO","DOM","Higuamo","minor","61576","1214232592"
"Airdrie","Airdrie","51.2917","-114.0144","Canada","CA","CAN","Alberta","","61581","1124990202"
"Santa Catarina Ixtahuacán","Santa Catarina Ixtahuacan","14.8000","-91.3667","Guatemala","GT","GTM","Sololá","minor","61274","1320474320"
"Djibo","Djibo","14.1011","-1.6306","Burkina Faso","BF","BFA","Sahel","minor","61456","1854364299"
"Iztapa","Iztapa","13.9331","-90.7075","Guatemala","GT","GTM","Escuintla","minor","61448","1320160535"
"Cajazeiras","Cajazeiras","-6.8900","-38.5619","Brazil","BR","BRA","Paraíba","","61431","1076367392"
"Uzunköprü","Uzunkopru","41.2669","26.6875","Turkey","TR","TUR","Edirne","minor","61485","1792592620"
"Garbsen","Garbsen","52.4183","9.5981","Germany","DE","DEU","Lower Saxony","","60754","1276318243"
"Commerce City","Commerce City","39.8641","-104.8430","United States","US","USA","Colorado","","61516","1840018785"
"Araranguá","Ararangua","-28.9350","-49.4858","Brazil","BR","BRA","Santa Catarina","minor","61310","1076532332"
"Pontiac","Pontiac","42.6493","-83.2878","United States","US","USA","Michigan","","61498","1840002427"
"Kozluk","Kozluk","38.1944","41.4911","Turkey","TR","TUR","Batman","minor","61437","1792509022"
"Fano","Fano","43.8333","13.0167","Italy","IT","ITA","Marche","","60978","1380639425"
"Udamalpet","Udamalpet","10.5855","77.2513","India","IN","IND","Tamil Nādu","","61133","1356413814"
"Wellington","Wellington","26.6461","-80.2699","United States","US","USA","Florida","","61445","1840018311"
"Slutsk","Slutsk","53.0333","27.5667","Belarus","BY","BLR","Minskaya Voblasts’","minor","61396","1112178336"
"Rio do Sul","Rio do Sul","-27.2139","-49.6428","Brazil","BR","BRA","Santa Catarina","","61198","1076530229"
"Khagaria","Khagaria","25.5022","86.4671","India","IN","IND","Bihār","","59691","1356254291"
"Vacaria","Vacaria","-28.5119","-50.9339","Brazil","BR","BRA","Rio Grande do Sul","","61342","1076001152"
"Hürth","Hurth","50.8775","6.8761","Germany","DE","DEU","North Rhine-Westphalia","","60189","1276025332"
"Tigaon","Tigaon","13.6319","123.4975","Philippines","PH","PHL","Camarines Sur","","60524","1608875000"
"Dumraon","Dumraon","25.5526","84.1515","India","IN","IND","Bihār","","60463","1356621212"
"Nordre Fåle","Nordre Fale","59.7500","10.8667","Norway","NO","NOR","Viken","","61032","1578002015"
"Abuyog","Abuyog","10.7458","125.0122","Philippines","PH","PHL","Leyte","","61216","1608485452"
"Kindamba","Kindamba","-3.7275","14.5211","Congo (Brazzaville)","CG","COG","Pool","","61304","1178489323"
"Barberena","Barberena","14.3167","-90.3667","Guatemala","GT","GTM","Santa Rosa","minor","61079","1320951727"
"Acaraú","Acarau","-2.8858","-40.1200","Brazil","BR","BRA","Ceará","","61210","1076016462"
"Bocaranga","Bocaranga","6.9844","15.6408","Central African Republic","CF","CAF","Ouham-Pendé","","61190","1140638385"
"Wesel","Wesel","51.6586","6.6178","Germany","DE","DEU","North Rhine-Westphalia","minor","60688","1276261527"
"Trollhättan","Trollhattan","58.2828","12.2892","Sweden","SE","SWE","Västra Götaland","minor","59210","1752863731"
"Halton Hills","Halton Hills","43.6300","-79.9500","Canada","CA","CAN","Ontario","","61161","1124000788"
"Hamden","Hamden","41.3961","-72.9215","United States","US","USA","Connecticut","","61160","1840034823"
"San Rafael","San Rafael","37.9905","-122.5221","United States","US","USA","California","","61158","1840021513"
"Tulunan","Tulunan","6.8333","124.8833","Philippines","PH","PHL","Cotabato","","60978","1608290320"
"Adjohon","Adjohon","6.7000","2.4667","Benin","BJ","BEN","Ouémé","","60955","1204412228"
"Nahariyya","Nahariyya","33.0058","35.0989","Israel","IL","ISR","Northern","","60000","1376378013"
"Obando","Obando","14.7083","120.9375","Philippines","PH","PHL","Bulacan","","59978","1608662635"
"Chāmrājnagar","Chamrajnagar","11.9231","76.9395","India","IN","IND","Karnātaka","","60810","1356007832"
"Idiofa","Idiofa","-4.9668","19.5910","Congo (Kinshasa)","CD","COD","Kwilu","","61056","1180986517"
"Gaibandha","Gaibandha","25.3290","89.5415","Bangladesh","BD","BGD","Rangpur","minor","59289","1050299100"
"Skhirate","Skhirate","33.8500","-7.0300","Morocco","MA","MAR","Rabat-Salé-Kénitra","","59775","1504664899"
"Navegantes","Navegantes","-26.8989","-48.6539","Brazil","BR","BRA","Santa Catarina","","60556","1076398974"
"Rosario","Rosario","16.2333","120.4833","Philippines","PH","PHL","La Union","","60278","1608229457"
"Barabai","Barabai","-2.5833","115.3833","Indonesia","ID","IDN","Kalimantan Selatan","minor","60873","1360007824"
"Panruti","Panruti","11.7700","79.5500","India","IN","IND","Tamil Nādu","","60323","1356830695"
"Nekā","Neka","36.6508","53.2992","Iran","IR","IRN","Māzandarān","minor","60991","1364860857"
"Campo Bom","Campo Bom","-29.6789","-51.0528","Brazil","BR","BRA","Rio Grande do Sul","","60074","1076977021"
"Yongyang","Yongyang","27.1017","106.7332","China","CN","CHN","Guizhou","","60557","1156404107"
"Jinoba-an","Jinoba-an","9.6018","122.4668","Philippines","PH","PHL","Negros Occidental","","60865","1608549313"
"Acerra","Acerra","40.9500","14.3667","Italy","IT","ITA","Campania","","59910","1380520117"
"Burhaniye","Burhaniye","39.5000","26.9500","Turkey","TR","TUR","Balıkesir","minor","60799","1792560053"
"Malay","Malay","11.8997","121.9094","Philippines","PH","PHL","Aklan","","60077","1608537061"
"Plymouth","Plymouth","41.8783","-70.6309","United States","US","USA","Massachusetts","","60987","1840053574"
"Woodland","Woodland","38.6712","-121.7500","United States","US","USA","California","","60977","1840021485"
"Marietta","Marietta","33.9533","-84.5422","United States","US","USA","Georgia","","60962","1840015633"
"Castilla","Castilla","12.9553","123.8764","Philippines","PH","PHL","Sorsogon","","60635","1608283593"
"Tamba","Tamba","35.1833","135.0333","Japan","JP","JPN","Hyōgo","","60824","1392003340"
"Oda","Oda","5.9236","-0.9883","Ghana","GH","GHA","Eastern","","60604","1288743724"
"Al Qurayyā","Al Qurayya","35.0025","40.5117","Syria","SY","SYR","Dayr az Zawr","","60780","1760541645"
"Chapel Hill","Chapel Hill","35.9271","-79.0391","United States","US","USA","North Carolina","","60920","1840016152"
"Chalkída","Chalkida","38.4625","23.5950","Greece","GR","GRC","Stereá Elláda","minor","59125","1300585149"
"Velampālaiyam","Velampalaiyam","11.1376","77.3106","India","IN","IND","Tamil Nādu","","60296","1356163714"
"Nambuangongo","Nambuangongo","-8.0167","14.2000","Angola","AO","AGO","Bengo","","60883","1024536521"
"Sennan","Sennan","34.3628","135.2761","Japan","JP","JPN","Ōsaka","","59634","1392003335"
"Villamaría","Villamaria","5.0000","-75.5000","Colombia","CO","COL","Caldas","minor","60729","1170294681"
"Laoang","Laoang","12.5667","125.0167","Philippines","PH","PHL","Northern Samar","","60607","1608435487"
"Cubulco","Cubulco","15.1083","-90.6306","Guatemala","GT","GTM","Baja Verapaz","minor","60706","1320016070"
"Tomaszów Mazowiecki","Tomaszow Mazowiecki","51.5167","20.0167","Poland","PL","POL","Łódzkie","minor","59388","1616394410"
"Shihe","Shihe","39.2708","113.5478","China","CN","CHN","Shanxi","","60724","1156275174"
"Dongcun","Dongcun","38.2800","111.6751","China","CN","CHN","Shanxi","minor","59772","1156680061"
"Matanao","Matanao","6.7500","125.2333","Philippines","PH","PHL","Davao del Sur","","60493","1608710140"
"Meriden","Meriden","41.5369","-72.7946","United States","US","USA","Connecticut","","60790","1840004848"
"Koga","Koga","33.7333","130.4667","Japan","JP","JPN","Fukuoka","","59349","1392503917"
"Calulo","Calulo","-10.0007","14.9010","Angola","AO","AGO","Cuanza-Sul","","60709","1024545873"
"Gubat","Gubat","12.9189","124.1231","Philippines","PH","PHL","Sorsogon","","60294","1608486429"
"Bristol","Bristol","41.6812","-72.9407","United States","US","USA","Connecticut","","60733","1840004772"
"Stralsund","Stralsund","54.3092","13.0819","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","59205","1276640152"
"Igarapé-Miri","Igarape-Miri","-1.9750","-48.9600","Brazil","BR","BRA","Pará","","60675","1076517048"
"Zamora","Zamora","41.5033","-5.7556","Spain","ES","ESP","Castille-Leon","minor","60297","1724611317"
"Ibitinga","Ibitinga","-21.7578","-48.8289","Brazil","BR","BRA","São Paulo","","60600","1076462788"
"Palm Harbor","Palm Harbor","28.0847","-82.7481","United States","US","USA","Florida","","60682","1840014143"
"Druzhkivka","Druzhkivka","48.6203","37.5278","Ukraine","UA","UKR","Donetska Oblast","","59400","1804169216"
"Langenfeld","Langenfeld","51.1167","6.9500","Germany","DE","DEU","North Rhine-Westphalia","","59223","1276016003"
"Autlán de Navarro","Autlan de Navarro","19.7667","-104.3667","Mexico","MX","MEX","Jalisco","minor","60572","1484240559"
"Savona","Savona","44.3080","8.4810","Italy","IT","ITA","Liguria","minor","60632","1380314552"
"Jupiter","Jupiter","26.9200","-80.1128","United States","US","USA","Florida","","60629","1840017255"
"Hendersonville","Hendersonville","36.3063","-86.5997","United States","US","USA","Tennessee","","60628","1840014419"
"Kangān","Kangan","27.8389","52.0619","Iran","IR","IRN","Būshehr","minor","60187","1364748225"
"Piscataway","Piscataway","40.5467","-74.4636","United States","US","USA","New Jersey","","60602","1840081697"
"San Vicente del Raspeig","San Vicente del Raspeig","38.3964","-0.5253","Spain","ES","ESP","Valencia","","59138","1724135642"
"Bełchatów","Belchatow","51.3667","19.3667","Poland","PL","POL","Łódzkie","minor","59025","1616973390"
"Rāzampeta","Razampeta","14.1954","79.1590","India","IN","IND","Andhra Pradesh","","60188","1356160843"
"Atotonilco el Alto","Atotonilco el Alto","20.5333","-102.5000","Mexico","MX","MEX","Jalisco","minor","60480","1484290836"
"Matera","Matera","40.6667","16.6000","Italy","IT","ITA","Basilicata","minor","60403","1380622394"
"Neu-Ulm","Neu-Ulm","48.3833","10.0000","Germany","DE","DEU","Bavaria","minor","59814","1276071813"
"Mirassol","Mirassol","-20.8189","-49.5208","Brazil","BR","BRA","São Paulo","","60303","1076772486"
"Greifswald","Greifswald","54.0833","13.3833","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","59382","1276867765"
"Hashimoto","Hashimoto","34.3167","135.6000","Japan","JP","JPN","Wakayama","","60058","1392655829"
"Kumertau","Kumertau","52.7667","55.7833","Russia","RU","RUS","Bashkortostan","","60164","1643237908"
"Ponnūru","Ponnuru","16.0667","80.5667","India","IN","IND","Andhra Pradesh","","59913","1356219968"
"Paracale","Paracale","14.2797","122.7869","Philippines","PH","PHL","Camarines Norte","","60198","1608056397"
"Yongbei","Yongbei","26.6897","100.7463","China","CN","CHN","Yunnan","minor","60099","1156019929"
"Rzhev","Rzhev","56.2500","34.3167","Russia","RU","RUS","Tverskaya Oblast’","","59422","1643967736"
"Molfetta","Molfetta","41.2000","16.6000","Italy","IT","ITA","Puglia","","59470","1380210810"
"Çivril","Civril","38.3014","29.7386","Turkey","TR","TUR","Denizli","minor","60429","1792021395"
"Clay","Clay","43.1808","-76.1954","United States","US","USA","New York","","60464","1840057989"
"Consuelito","Consuelito","18.5600","-69.3000","Dominican Republic","DO","DOM","Higuamo","","60000","1214750136"
"Belladère","Belladere","18.8667","-71.7667","Haiti","HT","HTI","Centre","","60239","1332473025"
"São Gabriel","Sao Gabriel","-30.3358","-54.3200","Brazil","BR","BRA","Rio Grande do Sul","","60425","1076674208"
"Kakuma","Kakuma","3.7167","34.8667","Kenya","KE","KEN","Turkana","","60000","1404320261"
"Mossel Bay","Mossel Bay","-34.1833","22.1333","South Africa","ZA","ZAF","Western Cape","","59031","1710597106"
"Medellin","Medellin","11.1286","123.9622","Philippines","PH","PHL","Cebu","","59605","1608853186"
"Olbia","Olbia","40.9167","9.5000","Italy","IT","ITA","Sardegna","minor","60261","1380424474"
"Jiguaní","Jiguani","20.3731","-76.4222","Cuba","CU","CUB","Granma","minor","60320","1192161578"
"Des Plaines","Des Plaines","42.0345","-87.9008","United States","US","USA","Illinois","","60413","1840007032"
"Arankhola","Arankhola","24.6610","90.0884","Bangladesh","BD","BGD","Dhaka","","59895","1050000837"
"Parkent","Parkent","41.2944","69.6764","Uzbekistan","UZ","UZB","Toshkent","","60200","1860290912"
"Sayanogorsk","Sayanogorsk","53.0500","91.4500","Russia","RU","RUS","Khakasiya","","60317","1643565856"
"Bor","Bor","37.8833","34.5667","Turkey","TR","TUR","Niğde","minor","60335","1792531194"
"Lakhdaria","Lakhdaria","36.5622","3.5944","Algeria","DZ","DZA","Bouira","","59746","1012284313"
"Maimbung","Maimbung","5.9333","121.0333","Philippines","PH","PHL","Sulu","","59597","1608928235"
"Kathua","Kathua","32.3850","75.5170","India","IN","IND","Jammu and Kashmīr","","59866","1356105038"
"Esmeraldas","Esmeraldas","-19.7628","-44.3139","Brazil","BR","BRA","Minas Gerais","","60271","1076007995"
"Barneveld","Barneveld","52.1333","5.5833","Netherlands","NL","NLD","Gelderland","minor","59992","1528016658"
"Yame","Yame","33.1994","130.5589","Japan","JP","JPN","Fukuoka","","60196","1392003211"
"San Mateo Ixtatán","San Mateo Ixtatan","15.8333","-91.4833","Guatemala","GT","GTM","Huehuetenango","minor","60203","1320506898"
"North Lakhimpur","North Lakhimpur","27.2414","94.1056","India","IN","IND","Assam","","59814","1356628778"
"Afula","Afula","32.6064","35.2881","Israel","IL","ISR","Northern","","60000","1376077681"
"Manhiça","Manhica","-25.4000","32.8000","Mozambique","MZ","MOZ","Maputo","","60031","1508278730"
"Benevento","Benevento","41.1333","14.7833","Italy","IT","ITA","Campania","minor","59789","1380723198"
"Belebey","Belebey","54.1000","54.1333","Russia","RU","RUS","Bashkortostan","","60188","1643040403"
"Bābolsar","Babolsar","36.7025","52.6575","Iran","IR","IRN","Māzandarān","minor","59966","1364000970"
"Labinsk","Labinsk","44.6333","40.7333","Russia","RU","RUS","Krasnodarskiy Kray","minor","60164","1643209545"
"Lianzhuang","Lianzhuang","37.1146","115.7594","China","CN","CHN","Hebei","","59576","1156030181"
"Sanford","Sanford","28.7891","-81.2757","United States","US","USA","Florida","","60215","1840015958"
"San Simon","San Simon","14.9980","120.7800","Philippines","PH","PHL","Pampanga","","59182","1608210651"
"Camocim","Camocim","-2.9019","-40.8408","Brazil","BR","BRA","Ceará","","60158","1076563942"
"Garín","Garin","-34.4167","-58.7500","Argentina","AR","ARG","Buenos Aires","","59335","1032601732"
"Gunupur","Gunupur","19.0800","83.8200","India","IN","IND","Odisha","","60000","1356531908"
"Shimotsuke","Shimotsuke","36.3872","139.8421","Japan","JP","JPN","Tochigi","","59404","1392034271"
"Alfonso","Alfonso","14.1379","120.8552","Philippines","PH","PHL","Cavite","","59306","1608847500"
"Chandralapādu","Chandralapadu","16.7150","80.2089","India","IN","IND","Andhra Pradesh","","59943","1356193821"
"Urus-Martan","Urus-Martan","43.1333","45.5500","Russia","RU","RUS","Chechnya","minor","59954","1643013764"
"Ābyek","Abyek","36.0400","50.5311","Iran","IR","IRN","Qazvīn","minor","60107","1364192974"
"Huntersville","Huntersville","35.4060","-80.8716","United States","US","USA","North Carolina","","60166","1840016349"
"San Mariano","San Mariano","16.9833","122.0167","Philippines","PH","PHL","Isabela","","60124","1608181731"
"Benevides","Benevides","-1.3608","-48.2450","Brazil","BR","BRA","Pará","","59836","1076726167"
"Towada","Towada","40.6127","141.2059","Japan","JP","JPN","Aomori","","60063","1392003343"
"Suifenhe","Suifenhe","44.3998","131.1478","China","CN","CHN","Heilongjiang","minor","60000","1156783152"
"Euless","Euless","32.8508","-97.0800","United States","US","USA","Texas","","60134","1840020693"
"Aranjuez","Aranjuez","40.0333","-3.6028","Spain","ES","ESP","Madrid","","59833","1724798101"
"Sarāvān","Saravan","27.3708","62.3342","Iran","IR","IRN","Sīstān va Balūchestān","","60114","1364931043"
"Vinukonda","Vinukonda","16.0500","79.7500","India","IN","IND","Andhra Pradesh","","59725","1356819642"
"Kananya","Kananya","11.1856","124.5597","Philippines","PH","PHL","Leyte","","59696","1608771995"
"Ash Shaykh Zuwayd","Ash Shaykh Zuwayd","31.2119","34.1106","Egypt","EG","EGY","Shamāl Sīnā’","minor","60000","1818656208"
"Sanyō-Onoda","Sanyo-Onoda","34.0000","131.1833","Japan","JP","JPN","Yamaguchi","","59648","1392003502"
"Rāpar","Rapar","23.5700","70.6300","India","IN","IND","Gujarāt","","60000","1356119017"
"Banane","Banane","0.5000","38.6700","Kenya","KE","KEN","Isiolo","","60000","1404457234"
"Ilog","Ilog","10.0333","122.7667","Philippines","PH","PHL","Negros Occidental","","59855","1608971808"
"Niort","Niort","46.3258","-0.4606","France","FR","FRA","Nouvelle-Aquitaine","minor","59193","1250833218"
"Gohadi","Gohadi","26.4328","78.4420","India","IN","IND","Madhya Pradesh","","59706","1356074811"
"Sigaboy","Sigaboy","6.6536","126.0717","Philippines","PH","PHL","Davao Oriental","","59891","1608254982"
"Horqueta","Horqueta","-23.3442","-57.0436","Paraguay","PY","PRY","Concepción","","60031","1600363376"
"San Marcos","San Marcos","8.6611","-75.1347","Colombia","CO","COL","Sucre","minor","59976","1170680965"
"Bayjī","Bayji","34.9292","43.4931","Iraq","IQ","IRQ","Şalāḩ ad Dīn","minor","60000","1368780727"
"Lagoa Santa","Lagoa Santa","-19.6269","-43.8900","Brazil","BR","BRA","Minas Gerais","","59770","1076169949"
"Kribi","Kribi","2.9350","9.9100","Cameroon","CM","CMR","Sud","","60000","1120360100"
"Pāchora","Pachora","20.6700","75.3500","India","IN","IND","Mahārāshtra","","59609","1356037558"
"Buqda Caqable","Buqda Caqable","4.0600","45.2575","Somalia","SO","SOM","Hiiraan","","60000","1706953037"
"Maués","Maues","-3.3836","-57.7186","Brazil","BR","BRA","Amazonas","","59983","1076490155"
"Shegaon","Shegaon","20.7944","76.6944","India","IN","IND","Mahārāshtra","","59672","1356687763"
"Ragay","Ragay","13.8217","122.7897","Philippines","PH","PHL","Camarines Sur","","59770","1608263758"
"Xinfeng","Xinfeng","24.9167","121.0000","Taiwan","TW","TWN","Hsinchu","","58614","1158988377"
"Bougouni","Bougouni","11.4167","-7.4833","Mali","ML","MLI","Sikasso","minor","59679","1466902797"
"Hardenberg","Hardenberg","52.5667","6.6167","Netherlands","NL","NLD","Overijssel","minor","59689","1528418697"
"Huajiang","Huajiang","25.7491","105.6063","China","CN","CHN","Guizhou","","59657","1156876468"
"Sardhana","Sardhana","29.1450","77.6100","India","IN","IND","Uttar Pradesh","","58490","1356727750"
"Heerhugowaard","Heerhugowaard","52.6667","4.8333","Netherlands","NL","NLD","Noord-Holland","minor","58387","1528560409"
"Shuya","Shuya","56.8500","41.3667","Russia","RU","RUS","Ivanovskaya Oblast’","","58114","1643360424"
"Aguacatán","Aguacatan","15.3429","-91.3120","Guatemala","GT","GTM","Huehuetenango","minor","59638","1320252531"
"Uriangato","Uriangato","20.1333","-101.1667","Mexico","MX","MEX","Guanajuato","minor","59305","1484679933"
"Huehuetoca","Huehuetoca","19.8342","-99.2033","Mexico","MX","MEX","México","minor","59721","1484000089"
"San Francisco","San Francisco","10.6500","124.3500","Philippines","PH","PHL","Cebu","","59236","1608306591"
"Guerra","Guerra","18.5500","-69.7000","Dominican Republic","DO","DOM","Ozama","","59631","1214475992"
"Hamburg","Hamburg","42.7394","-78.8581","United States","US","USA","New York","","59764","1840004385"
"Lesosibirsk","Lesosibirsk","58.2358","92.4828","Russia","RU","RUS","Krasnoyarskiy Kray","","59525","1643351184"
"Zhoujiajing","Zhoujiajing","31.1116","121.0518","China","CN","CHN","Jiangsu","","59300","1156618578"
"Chibuto","Chibuto","-24.6833","33.5333","Mozambique","MZ","MOZ","Gaza","","58012","1508468789"
"Richland","Richland","46.2824","-119.2939","United States","US","USA","Washington","","59718","1840019889"
"Saint-Louis du Sud","Saint-Louis du Sud","18.2667","-73.5500","Haiti","HT","HTI","Sud","","59042","1332867263"
"Palmares","Palmares","-8.6828","-35.5919","Brazil","BR","BRA","Pernambuco","","59526","1076270377"
"Jōsō","Joso","36.0236","139.9938","Japan","JP","JPN","Ibaraki","","59223","1392553484"
"Pasrur","Pasrur","32.2681","74.6675","Pakistan","PK","PAK","Punjab","","58644","1586432904"
"Mouscron","Mouscron","50.7333","3.2167","Belgium","BE","BEL","Wallonia","minor","58234","1056095679"
"Santee","Santee","32.8554","-116.9851","United States","US","USA","California","","59679","1840021992"
"Irosin","Irosin","12.7050","124.0319","Philippines","PH","PHL","Sorsogon","","59267","1608326292"
"Narasapur","Narasapur","16.4361","81.7016","India","IN","IND","Andhra Pradesh","","58770","1356412895"
"Saint-Hyacinthe","Saint-Hyacinthe","45.6167","-72.9500","Canada","CA","CAN","Quebec","","59614","1124010116"
"Ogōri","Ogori","33.4000","130.5500","Japan","JP","JPN","Fukuoka","","58322","1392003447"
"Goianésia","Goianesia","-15.3169","-49.1169","Brazil","BR","BRA","Goiás","","59549","1076636505"
"Alcoy","Alcoy","38.6983","-0.4736","Spain","ES","ESP","Valencia","","59128","1724702362"
"San Luis","San Luis","15.0400","120.7919","Philippines","PH","PHL","Pampanga","","58551","1608207383"
"Unna","Unna","51.5347","7.6889","Germany","DE","DEU","North Rhine-Westphalia","minor","58911","1276028114"
"Agrigento","Agrigento","37.3167","13.5833","Italy","IT","ITA","Sicilia","minor","59329","1380721319"
"Camajuaní","Camajuani","22.4678","-79.7239","Cuba","CU","CUB","Villa Clara","minor","59464","1192894106"
"Montenegro","Montenegro","-29.6889","-51.4608","Brazil","BR","BRA","Rio Grande do Sul","","59415","1076000662"
"Santa Isabel do Pará","Santa Isabel do Para","-1.2989","-48.1608","Brazil","BR","BRA","Pará","","59466","1076463384"
"Roxas","Roxas","12.5833","121.5000","Philippines","PH","PHL","Oriental Mindoro","","58849","1608811315"
"Prokhladnyy","Prokhladnyy","43.7500","44.0333","Russia","RU","RUS","Kabardino-Balkariya","minor","57883","1643315117"
"Dhone","Dhone","15.3960","77.8720","India","IN","IND","Andhra Pradesh","","59272","1356669210"
"Kesennuma","Kesennuma","38.9081","141.5699","Japan","JP","JPN","Miyagi","","59341","1392003464"
"Yara","Yara","20.2767","-76.9469","Cuba","CU","CUB","Granma","minor","59415","1192644994"
"Esfarāyen","Esfarayen","37.0764","57.5100","Iran","IR","IRN","Khorāsān-e Shomālī","minor","59490","1364743408"
"Ouinhri","Ouinhri","7.0000","2.4500","Benin","BJ","BEN","Zou","","59381","1204186479"
"Morón","Moron","22.1108","-78.6278","Cuba","CU","CUB","Ciego de Ávila","minor","59371","1192161102"
"Los Andes","Los Andes","-32.8333","-70.6167","Chile","CL","CHL","Valparaíso","minor","59388","1152833598"
"Lucas do Rio Verde","Lucas do Rio Verde","-13.0500","-55.9108","Brazil","BR","BRA","Mato Grosso","","59436","1076459192"
"Manchester","Manchester","41.7753","-72.5242","United States","US","USA","Connecticut","","59452","1840035273"
"Hyūga","Hyuga","32.4167","131.6167","Japan","JP","JPN","Miyazaki","","59276","1392003479"
"Binnāguri","Binnaguri","26.4600","89.2000","India","IN","IND","West Bengal","","58840","1356498752"
"Hua Hin","Hua Hin","12.5686","99.9578","Thailand","TH","THA","Prachuap Khiri Khan","minor","59369","1764352388"
"Tiruvālūr","Tiruvalur","10.7730","79.6370","India","IN","IND","Tamil Nādu","","58301","1356119061"
"Gannan","Gannan","47.9117","123.4978","China","CN","CHN","Heilongjiang","minor","59239","1156081008"
"Esperanza","Esperanza","8.6760","125.6454","Philippines","PH","PHL","Agusan del Sur","","59353","1608011025"
"Rājgarh","Rajgarh","28.6400","75.3800","India","IN","IND","Rājasthān","","59193","1356123600"
"Aleksandrov","Aleksandrov","56.3936","38.7150","Russia","RU","RUS","Vladimirskaya Oblast’","","59328","1643014960"
"Ibaan","Ibaan","13.8176","121.1330","Philippines","PH","PHL","Batangas","","58507","1608946525"
"Sonabedha","Sonabedha","18.7300","82.8300","India","IN","IND","Odisha","","58647","1356061023"
"Motril","Motril","36.7500","-3.5167","Spain","ES","ESP","Andalusia","","58798","1724209936"
"El Salvador","El Salvador","8.5667","124.5167","Philippines","PH","PHL","Misamis Oriental","","58771","1608813941"
"Tahara","Tahara","34.6688","137.2809","Japan","JP","JPN","Aichi","","59015","1392243074"
"Zahirābād","Zahirabad","17.6814","77.6074","India","IN","IND","Andhra Pradesh","","58857","1356771040"
"Kokomo","Kokomo","40.4640","-86.1277","United States","US","USA","Indiana","","59263","1840013844"
"Mansalay","Mansalay","12.5204","121.4385","Philippines","PH","PHL","Oriental Mindoro","","59114","1608124573"
"Chikuma","Chikuma","36.5339","138.1200","Japan","JP","JPN","Nagano","","58755","1392003086"
"Calatagan","Calatagan","13.8322","120.6322","Philippines","PH","PHL","Batangas","","58719","1608624641"
"Shirakawa","Shirakawa","37.1263","140.2109","Japan","JP","JPN","Fukushima","","59044","1392881381"
"Halesowen","Halesowen","52.4502","-2.0509","United Kingdom","GB","GBR","Dudley","","58135","1826152884"
"Oued Lill","Oued Lill","36.8333","10.0500","Tunisia","TN","TUN","Manouba","","57882","1788810522"
"Chichibu","Chichibu","35.9918","139.0855","Japan","JP","JPN","Saitama","","59101","1392105662"
"Tianningcun","Tianningcun","30.8938","120.8009","China","CN","CHN","Zhejiang","","58430","1156378452"
"Santa María Chiquimula","Santa Maria Chiquimula","15.0292","-91.3294","Guatemala","GT","GTM","Totonicapán","minor","58918","1320879854"
"Zengcun","Zengcun","38.2451","114.7367","China","CN","CHN","Hebei","","58339","1156663552"
"Masantol","Masantol","14.9000","120.7167","Philippines","PH","PHL","Pampanga","","57990","1608226462"
"Kattaqo’rg’on Shahri","Kattaqo'rg'on Shahri","39.8989","66.2561","Uzbekistan","UZ","UZB","Samarqand","minor","58950","1860871064"
"Euskirchen","Euskirchen","50.6597","6.7917","Germany","DE","DEU","North Rhine-Westphalia","minor","58754","1276782162"
"Ranchuelo","Ranchuelo","22.3764","-80.1510","Cuba","CU","CUB","Villa Clara","minor","59062","1192410848"
"Canlaon","Canlaon","10.3833","123.2000","Philippines","PH","PHL","Negros Oriental","","58822","1608924591"
"Pamplona","Pamplona","7.3781","-72.6525","Colombia","CO","COL","Norte de Santander","minor","58975","1170275020"
"Florida","Florida","3.3275","-76.2386","Colombia","CO","COL","Valle del Cauca","minor","58988","1170190923"
"Jaguariúna","Jaguariuna","-22.6800","-46.9900","Brazil","BR","BRA","São Paulo","","58722","1076822145"
"Nūzvīd","Nuzvid","16.7800","80.8500","India","IN","IND","Andhra Pradesh","","58590","1356713948"
"Sandīla","Sandila","27.0800","80.5200","India","IN","IND","Uttar Pradesh","","58346","1356996737"
"Constanza","Constanza","18.9100","-70.7500","Dominican Republic","DO","DOM","Cibao Sur","","59052","1214705132"
"Upleta","Upleta","21.7300","70.2800","India","IN","IND","Gujarāt","","58775","1356920234"
"Dāmghān","Damghan","36.1683","54.3481","Iran","IR","IRN","Semnān","minor","59106","1364099760"
"Wani","Wani","20.0556","78.9533","India","IN","IND","Mahārāshtra","","58840","1356212376"
"Metapán","Metapan","14.3314","-89.4428","El Salvador","SV","SLV","Santa Ana","","59004","1222716890"
"Liantangcun","Liantangcun","22.9517","112.4597","China","CN","CHN","Guangdong","","58429","1156553941"
"Upi","Upi","7.0106","124.1629","Philippines","PH","PHL","Maguindanao","","59004","1608496674"
"Taunton","Taunton","41.9036","-71.0943","United States","US","USA","Massachusetts","","59076","1840003220"
"Faenza","Faenza","44.2856","11.8833","Italy","IT","ITA","Emilia-Romagna","","58797","1380236916"
"Bilhorod-Dnistrovskyi","Bilhorod-Dnistrovskyi","46.1833","30.3500","Ukraine","UA","UKR","Odeska Oblast","minor","57210","1804836080"
"St. Clair Shores","St. Clair Shores","42.4925","-82.8961","United States","US","USA","Michigan","","59046","1840003093"
"Xikeng","Xikeng","24.0505","116.8538","China","CN","CHN","Guangdong","","58890","1156776820"
"Göppingen","Goppingen","48.7025","9.6528","Germany","DE","DEU","Baden-Württemberg","minor","58061","1276730715"
"Towson","Towson","39.3944","-76.6190","United States","US","USA","Maryland","","59014","1840005695"
"Camacupa","Camacupa","-12.0256","17.4686","Angola","AO","AGO","Bié","","59000","1024037086"
"Doetinchem","Doetinchem","51.9667","6.3000","Netherlands","NL","NLD","Gelderland","minor","58270","1528625414"
"Yaguajay","Yaguajay","22.3303","-79.2369","Cuba","CU","CUB","Sancti Spíritus","minor","58938","1192971840"
"Kélibia","Kelibia","36.8500","11.1000","Tunisia","TN","TUN","Nabeul","","58491","1788675416"
"Barra do Garças","Barra do Garcas","-15.8900","-52.2569","Brazil","BR","BRA","Mato Grosso","","58974","1076425403"
"Rass el Djebel","Rass el Djebel","37.2150","10.1200","Tunisia","TN","TUN","Bizerte","","58241","1788857696"
"Gattaran","Gattaran","18.0611","121.6453","Philippines","PH","PHL","Cagayan","","58874","1608571054"
"Pānskura","Panskura","22.4200","87.7000","India","IN","IND","West Bengal","","57932","1356744342"
"Myaungmya","Myaungmya","16.5833","94.9083","Myanmar","MM","MMR","Ayeyarwady","","58698","1104040357"
"Pedro Leopoldo","Pedro Leopoldo","-19.6178","-44.0428","Brazil","BR","BRA","Minas Gerais","","58740","1076144411"
"Mpondwe","Mpondwe","0.0400","29.7250","Uganda","UG","UGA","Kasese","","58600","1800231834"
"Pipariā","Piparia","22.7570","78.3550","India","IN","IND","Madhya Pradesh","","58578","1356825857"
"Lac-Brome","Lac-Brome","45.2167","-72.5167","Canada","CA","CAN","Quebec","","58889","1124000579"
"Timargara","Timargara","34.8278","71.8417","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","58050","1586843763"
"Boufarik","Boufarik","36.5750","2.9097","Algeria","DZ","DZA","Blida","","57162","1012512259"
"Caldwell","Caldwell","43.6453","-116.6594","United States","US","USA","Idaho","","58872","1840018641"
"Wuyang","Wuyang","27.0570","108.3959","China","CN","CHN","Guizhou","","58630","1156642549"
"Una","Una","20.8200","71.0300","India","IN","IND","Gujarāt","","58528","1356734476"
"Jovellanos","Jovellanos","22.8106","-81.1981","Cuba","CU","CUB","Matanzas","minor","58685","1192643668"
"Barotac Nuevo","Barotac Nuevo","10.9000","122.7000","Philippines","PH","PHL","Iloilo","","58176","1608457314"
"Bezerros","Bezerros","-8.2333","-35.7500","Brazil","BR","BRA","Pernambuco","","58668","1076456541"
"Beauvais","Beauvais","49.4303","2.0952","France","FR","FRA","Hauts-de-France","minor","57071","1250473996"
"Mikhaylovka","Mikhaylovka","50.0667","43.2500","Russia","RU","RUS","Volgogradskaya Oblast’","","57829","1643239180"
"Baganga","Baganga","7.5752","126.5585","Philippines","PH","PHL","Davao Oriental","","58714","1608125933"
"Palompon","Palompon","11.0500","124.3833","Philippines","PH","PHL","Leyte","","58313","1608156798"
"Surubim","Surubim","-7.8319","-35.7558","Brazil","BR","BRA","Pernambuco","","58515","1076815713"
"Izberbash","Izberbash","42.5633","47.8636","Russia","RU","RUS","Dagestan","","58690","1643417777"
"Sucun","Sucun","31.0554","118.1057","China","CN","CHN","Anhui","","58300","1156452401"
"Asingan","Asingan","16.0023","120.6695","Philippines","PH","PHL","Pangasinan","","57811","1608557401"
"Lohārdagā","Lohardaga","23.4300","84.6800","India","IN","IND","Jhārkhand","","57411","1356815700"
"Abaji","Abaji","8.4756","6.9433","Nigeria","NG","NGA","Federal Capital Territory","minor","58444","1566650592"
"Cerignola","Cerignola","41.2667","15.9000","Italy","IT","ITA","Puglia","","58540","1380501000"
"Yanguancun","Yanguancun","30.4541","120.5545","China","CN","CHN","Zhejiang","","57609","1156379667"
"Kaş","Kas","36.2000","29.6349","Turkey","TR","TUR","Antalya","minor","58600","1792585060"
"Orland Park","Orland Park","41.6075","-87.8619","United States","US","USA","Illinois","","58622","1840011310"
"Qaskeleng","Qaskeleng","43.2000","76.6200","Kazakhstan","KZ","KAZ","","","58418","1398296961"
"Aldershot","Aldershot","51.2483","-0.7614","United Kingdom","GB","GBR","Hampshire","","57211","1826766548"
"Kitahiroshima","Kitahiroshima","42.9855","141.5631","Japan","JP","JPN","Hokkaidō","","58125","1392003254"
"Aketi","Aketi","2.7333","23.7500","Congo (Kinshasa)","CD","COD","Bas-Uélé","","58601","1180273302"
"Stonecrest","Stonecrest","33.6842","-84.1372","United States","US","USA","Georgia","","58602","1840029466"
"Rāmpur Hat","Rampur Hat","24.1700","87.7800","India","IN","IND","West Bengal","","57833","1356074159"
"Cogan","Cogan","10.5833","123.9667","Philippines","PH","PHL","Cebu","","57897","1608004314"
"Sillod","Sillod","20.3000","75.6500","India","IN","IND","Mahārāshtra","","58230","1356127258"
"Felgueiras","Felgueiras","41.3667","-8.2000","Portugal","PT","PRT","Porto","minor","58065","1620928002"
"Ejmiatsin","Ejmiatsin","40.1728","44.2925","Armenia","AM","ARM","Armavir","","57500","1051214432"
"Tokoname","Tokoname","34.8865","136.8323","Japan","JP","JPN","Aichi","","57507","1392719168"
"Balancán","Balancan","17.8000","-91.3500","Mexico","MX","MEX","Tabasco","minor","58524","1484783192"
"Lambayeque","Lambayeque","-6.7000","-79.9000","Peru","PE","PER","Lambayeque","","58276","1604253597"
"Sunshi","Sunshi","38.7631","116.4876","China","CN","CHN","Hebei","","57980","1156870769"
"Dumanjog","Dumanjog","10.0500","123.4833","Philippines","PH","PHL","Cebu","","57823","1608704492"
"Iskitim","Iskitim","54.6400","83.3061","Russia","RU","RUS","Novosibirskaya Oblast’","","56602","1643924276"
"Guarabira","Guarabira","-6.8550","-35.4900","Brazil","BR","BRA","Paraíba","","58162","1076842460"
"Palm Beach Gardens","Palm Beach Gardens","26.8466","-80.1679","United States","US","USA","Florida","","58482","1840015125"
"San Pedro","San Pedro","-24.2196","-64.8700","Argentina","AR","ARG","Jujuy","minor","58430","1032823743"
"Date","Date","37.8191","140.5630","Japan","JP","JPN","Fukushima","","58232","1392089091"
"Mulbāgal","Mulbagal","13.1635","78.3935","India","IN","IND","Karnātaka","","58123","1356535403"
"Ma’erkang","Ma'erkang","31.9046","102.2167","China","CN","CHN","Sichuan","minor","58437","1156085323"
"Moncalieri","Moncalieri","45.0005","7.6848","Italy","IT","ITA","Piedmont","","57234","1380931785"
"San Fernando","San Fernando","-34.5833","-70.9833","Chile","CL","CHL","Libertador General Bernardo O’Higgins","minor","58367","1152802349"
"Kokawa","Kokawa","34.2664","135.3653","Japan","JP","JPN","Wakayama","","58130","1392193996"
"Sérres","Serres","41.0833","23.5500","Greece","GR","GRC","Kentrikí Makedonía","minor","58287","1300726987"
"Jiantang","Jiantang","27.8205","99.7043","China","CN","CHN","Yunnan","minor","58339","1156175541"
"Royal Oak","Royal Oak","42.5084","-83.1539","United States","US","USA","Michigan","","58368","1840002430"
"Ishikari","Ishikari","43.1667","141.3167","Japan","JP","JPN","Hokkaidō","","58284","1392382929"
"Punta Alta","Punta Alta","-38.8800","-62.0750","Argentina","AR","ARG","Buenos Aires","minor","58315","1032629961"
"Biguaçu","Biguacu","-27.4939","-48.6558","Brazil","BR","BRA","Santa Catarina","","58206","1076869861"
"La Gomera","La Gomera","14.0833","-91.0500","Guatemala","GT","GTM","Escuintla","minor","58272","1320383323"
"Kosai","Kosai","34.7184","137.5316","Japan","JP","JPN","Shizuoka","","57682","1392881893"
"Pebane","Pebane","-17.2667","38.1667","Mozambique","MZ","MOZ","Zambézia","","58342","1508524941"
"Nyagan","Nyagan","62.1333","65.3833","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","58335","1643802074"
"Şatrovka","Satrovka","39.9319","48.9203","Azerbaijan","AZ","AZE","Salyan","","58253","1031501191"
"Stalowa Wola","Stalowa Wola","50.5667","22.0500","Poland","PL","POL","Podkarpackie","minor","57620","1616964956"
"Melchor Ocampo","Melchor Ocampo","19.7083","-99.1444","Mexico","MX","MEX","México","minor","57152","1484243108"
"Samaná","Samana","19.2000","-69.3167","Dominican Republic","DO","DOM","Cibao Nordeste","minor","58156","1214655701"
"Hunsūr","Hunsur","12.3036","76.2927","India","IN","IND","Karnātaka","","57948","1356412744"
"Huaniu","Huaniu","34.5658","105.8215","China","CN","CHN","Gansu","","57360","1156665718"
"Antsalova","Antsalova","-18.6667","44.6167","Madagascar","MG","MDG","Mahajanga","","58280","1450407330"
"Matamey","Matamey","13.4000","8.6000","Niger","NE","NER","Zinder","","58025","1562731904"
"Blue Springs","Blue Springs","39.0124","-94.2721","United States","US","USA","Missouri","","58265","1840007396"
"Fredericton","Fredericton","45.9636","-66.6431","Canada","CA","CAN","New Brunswick","admin","58220","1124061289"
"Choshi","Choshi","35.7346","140.8268","Japan","JP","JPN","Chiba","","57533","1392509915"
"Shoreline","Shoreline","47.7564","-122.3426","United States","US","USA","Washington","","58213","1840021118"
"Tōgane","Togane","35.5599","140.3661","Japan","JP","JPN","Chiba","","57560","1392535901"
"Ávila","Avila","40.6500","-4.6833","Spain","ES","ESP","Castille-Leon","minor","57949","1724392183"
"Gengzhuangqiao","Gengzhuangqiao","37.4453","114.9804","China","CN","CHN","Hebei","","57617","1156111959"
"Tønsberg","Tonsberg","59.2981","10.4236","Norway","NO","NOR","Vestfold og Telemark","minor","57794","1578658467"
"Tanauan","Tanauan","11.1167","125.0167","Philippines","PH","PHL","Leyte","","57455","1608895946"
"Chivilcoy","Chivilcoy","-34.9000","-60.0167","Argentina","AR","ARG","Buenos Aires","minor","58152","1032269915"
"San Luis","San Luis","18.5500","-69.7000","Dominican Republic","DO","DOM","Ozama","","56933","1214702688"
"Pula","Pula","44.8703","13.8456","Croatia","HR","HRV","Istarska Županija","minor","57053","1191143009"
"Bakwa-Kalonji","Bakwa-Kalonji","-4.3500","20.7200","Congo (Kinshasa)","CD","COD","Kasaï","","58092","1180566859"
"Tikhvin","Tikhvin","59.6500","33.5333","Russia","RU","RUS","Leningradskaya Oblast’","","58136","1643955290"
"Midwest City","Midwest City","35.4630","-97.3710","United States","US","USA","Oklahoma","","58146","1840020425"
"Benslimane","Benslimane","33.6167","-7.1167","Morocco","MA","MAR","Casablanca-Settat","","57101","1504790296"
"Lonāvale","Lonavale","18.7481","73.4072","India","IN","IND","Mahārāshtra","","57698","1356087772"
"New Corella","New Corella","7.5866","125.8237","Philippines","PH","PHL","Davao del Norte","","57913","1608906825"
"Pradera","Pradera","3.4167","-76.1667","Colombia","CO","COL","Valle del Cauca","minor","57990","1170774780"
"Abington","Abington","40.1108","-75.1146","United States","US","USA","Pennsylvania","","58131","1840035369"
"Gaspar","Gaspar","-26.9308","-48.9589","Brazil","BR","BRA","Santa Catarina","","57981","1076754588"
"Roermond","Roermond","51.2000","5.9833","Netherlands","NL","NLD","Limburg","minor","57308","1528173066"
"Bowie","Bowie","38.9549","-76.7406","United States","US","USA","Maryland","","58113","1840005970"
"Jackson","Jackson","40.0980","-74.3578","United States","US","USA","New Jersey","","58101","1840081610"
"Mizuho","Mizuho","35.3918","136.6909","Japan","JP","JPN","Gifu","","56106","1392003071"
"Zempoala","Zempoala","19.9167","-98.6667","Mexico","MX","MEX","Hidalgo","minor","57906","1484524155"
"Xiezhou","Xiezhou","34.9124","110.8517","China","CN","CHN","Shanxi","","57773","1156294219"
"Hameln","Hameln","52.1000","9.3667","Germany","DE","DEU","Lower Saxony","minor","57510","1276101153"
"Apex","Apex","35.7237","-78.8743","United States","US","USA","North Carolina","","58066","1840015355"
"Ambahikily","Ambahikily","-21.6000","43.6667","Madagascar","MG","MDG","Toliara","","58027","1450105131"
"Kawthoung","Kawthoung","9.9833","98.5500","Myanmar","MM","MMR","Tanintharyi","","57949","1104713098"
"Meleuz","Meleuz","52.9500","55.9333","Russia","RU","RUS","Bashkortostan","","58004","1643373593"
"Khlong Luang","Khlong Luang","14.0647","100.6458","Thailand","TH","THA","Pathum Thani","minor","56128","1764560763"
"Allanmyo","Allanmyo","19.3783","95.2279","Myanmar","MM","MMR","Magway","","57897","1104582279"
"Sāmalkot","Samalkot","17.0531","82.1695","India","IN","IND","Andhra Pradesh","","56864","1356044530"
"Živinice","Zivinice","44.4500","18.6500","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","57765","1070042260"
"Aleksin","Aleksin","54.5167","37.1000","Russia","RU","RUS","Tul’skaya Oblast’","","57892","1643013998"
"Berëzovskiy","Berezovskiy","56.9000","60.8167","Russia","RU","RUS","Sverdlovskaya Oblast’","","57892","1643702962"
"Ping’an","Ping'an","36.5020","102.1082","China","CN","CHN","Qinghai","","57152","1156959736"
"Kālna","Kalna","23.2200","88.3700","India","IN","IND","West Bengal","","56722","1356051768"
"Naugaon Sādāt","Naugaon Sadat","28.9833","78.4167","India","IN","IND","Uttar Pradesh","","56493","1356577967"
"Qianwu","Qianwu","22.1635","113.2217","China","CN","CHN","Guangdong","","57352","1156854836"
"El Cerrito","El Cerrito","3.6667","-76.1667","Colombia","CO","COL","Valle del Cauca","minor","57749","1170510590"
"Santa Maria","Santa Maria","6.5500","125.4667","Philippines","PH","PHL","Davao del Sur","","57526","1608601046"
"Mogoditshane","Mogoditshane","-24.6333","25.8667","Botswana","BW","BWA","Kweneng","","57637","1072633229"
"Kothāpet","Kothapet","19.3333","79.4833","India","IN","IND","Andhra Pradesh","","57583","1356072921"
"Keonjhargarh","Keonjhargarh","21.6300","85.5800","India","IN","IND","Odisha","","57232","1356695582"
"Ciudad Cuauhtémoc","Ciudad Cuauhtemoc","22.1833","-97.8333","Mexico","MX","MEX","Veracruz","minor","57646","1484666501"
"Będzin","Bedzin","50.3333","19.1167","Poland","PL","POL","Śląskie","minor","56354","1616409616"
"Tikhoretsk","Tikhoretsk","45.8667","40.1333","Russia","RU","RUS","Krasnodarskiy Kray","minor","57771","1643615827"
"Grand-Popo","Grand-Popo","6.2833","1.8333","Benin","BJ","BEN","Mono","","57636","1204984679"
"Unjha","Unjha","23.8000","72.4000","India","IN","IND","Gujarāt","","57108","1356738663"
"Biała Podlaska","Biala Podlaska","52.0333","23.1167","Poland","PL","POL","Lubelskie","minor","57498","1616025020"
"Orito","Orito","0.6661","-76.8708","Colombia","CO","COL","Putumayo","minor","57774","1170380074"
"Jasaan","Jasaan","8.6500","124.7500","Philippines","PH","PHL","Misamis Oriental","","57055","1608206601"
"Qŭnghirot","Qunghirot","43.0704","58.9000","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","57758","1860586828"
"Minokamo","Minokamo","35.4402","137.0157","Japan","JP","JPN","Gifu","","57007","1392923149"
"Sīra","Sira","13.7450","76.9090","India","IN","IND","Karnātaka","","57554","1356563968"
"Tiruvallūr","Tiruvallur","13.1231","79.9120","India","IN","IND","Tamil Nādu","","56074","1356625145"
"Mācherla","Macherla","16.4800","79.4300","India","IN","IND","Andhra Pradesh","","57290","1356348515"
"Meerbusch","Meerbusch","51.2667","6.6667","Germany","DE","DEU","North Rhine-Westphalia","","56855","1276882223"
"Campo Alegre","Campo Alegre","-9.7819","-36.3508","Brazil","BR","BRA","Alagoas","","57548","1076386570"
"Queen Creek","Queen Creek","33.2454","-111.6173","United States","US","USA","Arizona","","57728","1840021944"
"Pavlovo","Pavlovo","55.9667","43.0667","Russia","RU","RUS","Nizhegorodskaya Oblast’","","57630","1643644827"
"Kandukūr","Kandukur","15.2167","79.9042","India","IN","IND","Andhra Pradesh","","57246","1356179414"
"Jerez de García Salinas","Jerez de Garcia Salinas","22.6481","-102.9903","Mexico","MX","MEX","Zacatecas","minor","57610","1484361423"
"Diriamba","Diriamba","11.8564","-86.2403","Nicaragua","NI","NIC","Carazo","minor","57542","1558411169"
"Floriano","Floriano","-6.7669","-43.0228","Brazil","BR","BRA","Piauí","","57690","1076813660"
"Krasnotur’insk","Krasnotur'insk","59.7733","60.1853","Russia","RU","RUS","Sverdlovskaya Oblast’","","57514","1643475253"
"Leander","Leander","30.5728","-97.8618","United States","US","USA","Texas","","57696","1840020877"
"Salsk","Salsk","46.4833","41.5333","Russia","RU","RUS","Rostovskaya Oblast’","minor","57622","1643005627"
"Monte Plata","Monte Plata","18.8100","-69.7900","Dominican Republic","DO","DOM","Higuamo","minor","57553","1214856228"
"Moknine","Moknine","35.6333","10.9000","Tunisia","TN","TUN","Monastir","","57111","1788874932"
"Milagros","Milagros","12.2192","123.5089","Philippines","PH","PHL","Masbate","","57538","1608783254"
"Bartlett","Bartlett","35.2337","-89.8195","United States","US","USA","Tennessee","","57639","1840013472"
"Beni Enzar","Beni Enzar","35.2667","-2.9333","Morocco","MA","MAR","Oriental","","56582","1504616684"
"St. Cloud","St. Cloud","28.2363","-81.2779","United States","US","USA","Florida","","57610","1840015971"
"Wiwilí","Wiwili","13.6197","-85.8183","Nicaragua","NI","NIC","Nueva Segovia","minor","57485","1558766877"
"Binalonan","Binalonan","16.0500","120.6000","Philippines","PH","PHL","Pangasinan","","56382","1608750754"
"Alangalang","Alangalang","11.2061","124.8478","Philippines","PH","PHL","Leyte","","57185","1608580659"
"Santiago Tuxtla","Santiago Tuxtla","18.4654","-95.3000","Mexico","MX","MEX","Veracruz","minor","57466","1484009696"
"Cosquín","Cosquin","-31.2436","-64.4653","Argentina","AR","ARG","Córdoba","minor","57458","1032221091"
"Nova Odessa","Nova Odessa","-22.7797","-47.2962","Brazil","BR","BRA","São Paulo","","56764","1076061503"
"Uson","Uson","12.2253","123.7834","Philippines","PH","PHL","Masbate","","57166","1608634164"
"Ponte Nova","Ponte Nova","-20.4158","-42.9089","Brazil","BR","BRA","Minas Gerais","","57390","1076567178"
"Kettering","Kettering","39.6956","-84.1494","United States","US","USA","Ohio","","57503","1840003783"
"Içara","Icara","-28.7128","-49.3000","Brazil","BR","BRA","Santa Catarina","","57247","1076881795"
"Buluan","Buluan","6.7154","124.7854","Philippines","PH","PHL","Maguindanao","","57406","1608130728"
"Yanam","Yanam","16.7333","82.2167","India","IN","IND","Andhra Pradesh","","55626","1356982123"
"Foumbot","Foumbot","5.5000","10.6333","Cameroon","CM","CMR","Ouest","","57367","1120775025"
"Bail-Hongal","Bail-Hongal","15.8137","74.8589","India","IN","IND","Karnātaka","","57057","1356104757"
"La Plata","La Plata","2.3900","-75.9075","Colombia","CO","COL","Huila","minor","57381","1170531527"
"Hagonoy","Hagonoy","6.6833","125.3000","Philippines","PH","PHL","Davao del Sur","","56919","1608185614"
"Cuvelai","Cuvelai","-15.6667","15.8000","Angola","AO","AGO","Cunene","","57398","1024585667"
"Görlitz","Gorlitz","51.1528","14.9872","Germany","DE","DEU","Saxony","minor","56563","1276000121"
"Uki","Uki","32.6450","130.6864","Japan","JP","JPN","Kumamoto","","57096","1392003352"
"Frankfurt (Oder)","Frankfurt (Oder)","52.3419","14.5517","Germany","DE","DEU","Brandenburg","minor","57015","1276397323"
"Richards Bay","Richards Bay","-28.7694","32.0594","South Africa","ZA","ZAF","KwaZulu-Natal","","57387","1710347129"
"Kędzierzyn-Koźle","Kedzierzyn-Kozle","50.3500","18.2000","Poland","PL","POL","Opolskie","minor","56931","1616306372"
"Sankt Augustin","Sankt Augustin","50.7700","7.1867","Germany","DE","DEU","North Rhine-Westphalia","","55767","1276196312"
"Barwāni","Barwani","22.0300","74.9000","India","IN","IND","Madhya Pradesh","","57066","1356935870"
"Sendhwa","Sendhwa","21.6847","75.0953","India","IN","IND","Madhya Pradesh","","56485","1356657449"
"Eldorado","Eldorado","-26.4000","-54.6333","Argentina","AR","ARG","Misiones","minor","57323","1032056096"
"M’diq","M'diq","35.6858","-5.3253","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","56227","1504774869"
"Stolberg","Stolberg","50.7667","6.2333","Germany","DE","DEU","North Rhine-Westphalia","","56792","1276202704"
"Coruripe","Coruripe","-10.1258","-36.1758","Brazil","BR","BRA","Alagoas","","57294","1076998029"
"Riosucio","Riosucio","7.4406","-77.1189","Colombia","CO","COL","Chocó","minor","57220","1170229854"
"Sattenapalle","Sattenapalle","16.3962","80.1497","India","IN","IND","Andhra Pradesh","","56721","1356153330"
"Bobbili","Bobbili","18.5667","83.3667","India","IN","IND","Andhra Pradesh","","56819","1356820046"
"Ipele","Ipele","7.1333","5.6667","Nigeria","NG","NGA","Ondo","","57000","1566772510"
"Irpin","Irpin","50.5167","30.2500","Ukraine","UA","UKR","Kyivska Oblast","","56806","1804919448"
"Krymsk","Krymsk","44.9233","37.9806","Russia","RU","RUS","Krasnodarskiy Kray","minor","57229","1643118471"
"Parker","Parker","39.5084","-104.7753","United States","US","USA","Colorado","","57311","1840022454"
"Andradina","Andradina","-20.8958","-51.3789","Brazil","BR","BRA","São Paulo","","57250","1076417183"
"Kudamatsu","Kudamatsu","34.0167","131.8667","Japan","JP","JPN","Yamaguchi","","56661","1392936646"
"St. Peters","St. Peters","38.7825","-90.6061","United States","US","USA","Missouri","","57289","1840009721"
"Huaixiangcun","Huaixiangcun","22.4817","111.0542","China","CN","CHN","Guangdong","","56936","1156571325"
"Mora","Mora","10.0088","-84.0216","Costa Rica","CR","CRI","Heredia","","56919","1188001388"
"Nova Esperança","Nova Esperanca","-6.7333","15.9000","Angola","AO","AGO","Uíge","","57248","1024795482"
"Azzaba","Azzaba","36.7333","7.1000","Algeria","DZ","DZA","Skikda","","56922","1012969588"
"Nasīrabād","Nasirabad","26.3000","74.7300","India","IN","IND","Rājasthān","","56980","1356888183"
"Bulungu","Bulungu","-4.5500","18.6000","Congo (Kinshasa)","CD","COD","Kwilu","","57168","1180342743"
"Weymouth","Weymouth","42.1981","-70.9466","United States","US","USA","Massachusetts","","57217","1840132442"
"Foligno","Foligno","42.9500","12.7000","Italy","IT","ITA","Umbria","","56999","1380946507"
"Gūdalur","Gudalur","11.5014","76.4916","India","IN","IND","Tamil Nādu","","56886","1356995125"
"Shicun","Shicun","38.5383","116.1096","China","CN","CHN","Hebei","","56736","1156766079"
"Sarapiquí","Sarapiqui","10.4853","-83.9362","Costa Rica","CR","CRI","Heredia","","57147","1188413717"
"Talagante","Talagante","-33.6667","-70.8667","Chile","CL","CHL","Región Metropolitana","minor","56990","1152696612"
"Vrindāvan","Vrindavan","27.5800","77.7000","India","IN","IND","Uttar Pradesh","","56618","1356168243"
"Paços de Ferreira","Pacos de Ferreira","41.2667","-8.4000","Portugal","PT","PRT","Porto","minor","56340","1620222597"
"Eschweiler","Eschweiler","50.8167","6.2833","Germany","DE","DEU","North Rhine-Westphalia","","56385","1276835632"
"Borūjen","Borujen","31.9678","51.2894","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","minor","57071","1364420894"
"Kan’onjichō","Kan'onjicho","34.1272","133.6614","Japan","JP","JPN","Kagawa","","56639","1392669247"
"Guáimaro","Guaimaro","21.0589","-77.3478","Cuba","CU","CUB","Camagüey","minor","57086","1192870584"
"Rîbniţa","Ribnita","47.7667","29.0000","Moldova","MD","MDA","Stînga Nistrului","","56988","1498197530"
"Lunglei","Lunglei","22.8800","92.7300","India","IN","IND","Mizoram","","57011","1356318741"
"Moramanga","Moramanga","-18.9469","48.2303","Madagascar","MG","MDG","Toamasina","","57084","1450998453"
"Maricopa","Maricopa","33.0408","-112.0011","United States","US","USA","Arizona","","57075","1840020629"
"Raxaul","Raxaul","26.9833","84.8500","India","IN","IND","Bihār","","55537","1356205939"
"Dehdasht","Dehdasht","30.7950","50.5644","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","minor","57036","1364380325"
"Alvarado","Alvarado","18.7811","-95.7572","Mexico","MX","MEX","Veracruz","minor","57035","1484208918"
"Manfredonia","Manfredonia","41.6333","15.9167","Italy","IT","ITA","Puglia","","56906","1380398261"
"General Pico","General Pico","-35.6667","-63.7333","Argentina","AR","ARG","La Pampa","minor","57029","1032081531"
"Langenhagen","Langenhagen","52.4394","9.7400","Germany","DE","DEU","Lower Saxony","","56288","1276031765"
"Semara","Semara","26.7394","-11.6703","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","","57035","1504999984"
"Lian","Lian","14.0333","120.6500","Philippines","PH","PHL","Batangas","","56280","1608855034"
"Bura","Bura","3.1722","42.1411","Somalia","SO","SOM","Gedo","","57000","1706744119"
"Neryungri","Neryungri","56.6833","124.6667","Russia","RU","RUS","Sakha (Yakutiya)","","57009","1643755298"
"Moreno","Moreno","-8.1186","-35.0922","Brazil","BR","BRA","Pernambuco","","56696","1076066897"
"Chini","Chini","23.3916","113.0755","China","CN","CHN","Guangdong","","56609","1156204687"
"Tiwi","Tiwi","13.4585","123.6805","Philippines","PH","PHL","Albay","","56444","1608988529"
"Tama","Tama","34.4886","133.9486","Japan","JP","JPN","Okayama","","56431","1392920586"
"Oosterhout","Oosterhout","51.6431","4.8569","Netherlands","NL","NLD","Noord-Brabant","minor","56206","1528012745"
"Puerto Boyacá","Puerto Boyaca","6.0000","-74.4167","Colombia","CO","COL","Boyacá","minor","56930","1170291040"
"Cuemba","Cuemba","-12.1500","18.0833","Angola","AO","AGO","Bié","","56963","1024686026"
"Ramon","Ramon","16.7833","121.5333","Philippines","PH","PHL","Isabela","","56523","1608504575"
"Gangārāmpur","Gangarampur","25.4000","88.5200","India","IN","IND","West Bengal","","56217","1356994707"
"San Pedro Sacatepéquez","San Pedro Sacatepequez","14.6862","-90.6423","Guatemala","GT","GTM","Guatemala","minor","56545","1320594851"
"Poblacion","Poblacion","10.4667","123.9667","Philippines","PH","PHL","Cebu","","55874","1608466886"
"Den Helder","Den Helder","52.9333","4.7500","Netherlands","NL","NLD","Noord-Holland","minor","56582","1528605675"
"Paombong","Paombong","14.8311","120.7892","Philippines","PH","PHL","Bulacan","","55696","1608381288"
"Bagheria","Bagheria","38.0833","13.5000","Italy","IT","ITA","Sicilia","","55047","1380694374"
"Talegaon Dābhāde","Talegaon Dabhade","18.7200","73.6800","India","IN","IND","Mahārāshtra","","56435","1356444873"
"Verviers","Verviers","50.5833","5.8500","Belgium","BE","BEL","Wallonia","minor","55198","1056464610"
"Kharar","Kharar","30.7400","76.6500","India","IN","IND","Punjab","","55821","1356563444"
"Lagonoy","Lagonoy","13.7353","123.5208","Philippines","PH","PHL","Camarines Sur","","56714","1608249256"
"Acambay","Acambay","19.9539","-99.8431","Mexico","MX","MEX","México","minor","56847","1484667611"
"Alcobaça","Alcobaca","39.5528","-8.9778","Portugal","PT","PRT","Leiria","minor","56693","1620702700"
"Nedumangād","Nedumangad","8.6033","77.0028","India","IN","IND","Kerala","","56138","1356104296"
"Waiblingen","Waiblingen","48.8303","9.3169","Germany","DE","DEU","Baden-Württemberg","minor","55526","1276678986"
"Ixtahuacán","Ixtahuacan","15.4167","-91.7667","Guatemala","GT","GTM","Huehuetenango","minor","56511","1320074127"
"Jacundá","Jacunda","-4.4508","-49.1158","Brazil","BR","BRA","Pará","","56781","1076998765"
"Linares","Linares","38.0833","-3.6333","Spain","ES","ESP","Andalusia","","56525","1724047784"
"Basey","Basey","11.2817","125.0683","Philippines","PH","PHL","Samar","","56685","1608223943"
"Murakami","Murakami","38.2240","139.4800","Japan","JP","JPN","Niigata","","56713","1392955865"
"Lenexa","Lenexa","38.9609","-94.8018","United States","US","USA","Kansas","","56755","1840001639"
"Cuneo","Cuneo","44.3833","7.5500","Italy","IT","ITA","Piedmont","minor","56281","1380488547"
"Colomba","Colomba","14.7167","-91.7333","Guatemala","GT","GTM","Quetzaltenango","minor","56487","1320816498"
"Tilakpur","Tilakpur","28.5278","81.1189","Nepal","NP","NPL","","","56127","1524027768"
"Tivoli","Tivoli","41.9667","12.8000","Italy","IT","ITA","Lazio","","56542","1380558647"
"Athiémé","Athieme","6.5833","1.6667","Benin","BJ","BEN","Mono","","56483","1204354787"
"Highland","Highland","34.1113","-117.1650","United States","US","USA","California","","56733","1840020405"
"Kāndi","Kandi","23.9595","88.0402","India","IN","IND","West Bengal","","55632","1356979141"
"Viseu","Viseu","-1.1969","-46.1400","Brazil","BR","BRA","Pará","","56716","1076429547"
"Anjangaon","Anjangaon","21.1500","77.2800","India","IN","IND","Mahārāshtra","","56380","1356712792"
"Ar Riqqah","Ar Riqqah","29.1489","48.1057","Kuwait","KW","KWT","Al Aḩmadī","","56554","1414927245"
"Pangantocan","Pangantocan","7.8331","124.8294","Philippines","PH","PHL","Bukidnon","","56580","1608437961"
"Taquaritinga","Taquaritinga","-21.4058","-48.5050","Brazil","BR","BRA","São Paulo","","56587","1076141127"
"Mengla","Mengla","21.4866","101.5639","China","CN","CHN","Yunnan","minor","56612","1156134447"
"Salgueiro","Salgueiro","-8.0669","-39.1222","Brazil","BR","BRA","Pernambuco","","56629","1076679973"
"Cui’erzhuang","Cui'erzhuang","38.2897","116.5472","China","CN","CHN","Hebei","","56096","1156112790"
"Placer","Placer","11.8689","123.9167","Philippines","PH","PHL","Masbate","","56340","1608961300"
"Diglipur","Diglipur","13.2667","93.0000","India","IN","IND","Andaman and Nicobar Islands","","56597","1356537734"
"Nōgata","Nogata","33.7417","130.7322","Japan","JP","JPN","Fukuoka","","55705","1392996522"
"Opava","Opava","49.9381","17.9044","Czechia","CZ","CZE","Moravskoslezský Kraj","","55996","1203142483"
"Libungan","Libungan","7.2500","124.5167","Philippines","PH","PHL","Cotabato","","56269","1608132103"
"Khāsh","Khash","28.2211","61.2158","Iran","IR","IRN","Sīstān va Balūchestān","minor","56584","1364052913"
"Ajodhya","Ajodhya","26.8036","82.2006","India","IN","IND","Uttar Pradesh","","55890","1356623948"
"Manicoré","Manicore","-5.8089","-61.3000","Brazil","BR","BRA","Amazonas","","56583","1076657983"
"Tāybād","Taybad","34.7400","60.7756","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","56562","1364220425"
"Trani","Trani","41.2667","16.4167","Italy","IT","ITA","Puglia","minor","56031","1380788265"
"Kengri","Kengri","12.9122","77.4827","India","IN","IND","Karnātaka","","56040","1356059285"
"Hohoe","Hohoe","7.1490","0.4746","Ghana","GH","GHA","Volta","","56202","1288897773"
"Bianyang","Bianyang","25.6194","106.5350","China","CN","CHN","Guizhou","","56451","1156368240"
"Bartolomé Masó","Bartolome Maso","20.1686","-76.9428","Cuba","CU","CUB","Granma","minor","56460","1192545918"
"Tomé-Açu","Tome-Acu","-2.4189","-48.1519","Brazil","BR","BRA","Pará","","56518","1076866918"
"Mount Prospect","Mount Prospect","42.0641","-87.9375","United States","US","USA","Illinois","","56510","1840011300"
"Lake Havasu City","Lake Havasu City","34.5006","-114.3113","United States","US","USA","Arizona","","56510","1840021044"
"Hassa","Hassa","36.7994","36.5178","Turkey","TR","TUR","Hatay","minor","56409","1792468657"
"Magsaysay","Magsaysay","6.7667","125.1833","Philippines","PH","PHL","Davao del Sur","","56263","1608985215"
"Kūt-e ‘Abdollāh","Kut-e \`Abdollah","31.2414","48.6525","Iran","IR","IRN","Khūzestān","","56252","1364936018"
"Registro","Registro","-24.4878","-47.8439","Brazil","BR","BRA","São Paulo","","56393","1076533111"
"Belampalli","Belampalli","19.0756","79.4881","India","IN","IND","Andhra Pradesh","","55841","1356972240"
"Amealco","Amealco","20.1881","-100.1439","Mexico","MX","MEX","Querétaro","minor","56457","1484505085"
"Amarante","Amarante","41.2667","-8.0667","Portugal","PT","PRT","Porto","minor","56264","1620688725"
"Shali","Shali","43.1500","45.9000","Russia","RU","RUS","Chechnya","minor","56226","1643096920"
"Ropar","Ropar","30.9664","76.5331","India","IN","IND","Punjab","","56000","1356959681"
"Periya Semūr","Periya Semur","11.3700","77.6908","India","IN","IND","Tamil Nādu","","55282","1356169558"
"Winneba","Winneba","5.3500","-0.6250","Ghana","GH","GHA","Central","","55331","1288886612"
"Araci","Araci","-11.3328","-38.9669","Brazil","BR","BRA","Bahia","","56370","1076885835"
"Mota","Mota","11.0833","37.8667","Ethiopia","ET","ETH","Āmara","","56156","1231135838"
"Gulariyā","Gulariya","28.2056","81.3472","Nepal","NP","NPL","","","55747","1524608654"
"Monte Alegre","Monte Alegre","-2.0078","-54.0689","Brazil","BR","BRA","Pará","","56391","1076174262"
"Oke-Mesi","Oke-Mesi","7.8167","4.9167","Nigeria","NG","NGA","Ekiti","","56000","1566922020"
"Pithorāgarh","Pithoragarh","29.5800","80.2200","India","IN","IND","Uttarākhand","","56044","1356198469"
"Zgierz","Zgierz","51.8500","19.4167","Poland","PL","POL","Łódzkie","minor","55079","1616382343"
"Pila","Pila","14.2333","121.3667","Philippines","PH","PHL","Laguna","","54613","1608427597"
"Blainville","Blainville","45.6700","-73.8800","Canada","CA","CAN","Quebec","","56363","1124000623"
"Tursunzoda","Tursunzoda","38.5108","68.2303","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","55700","1762277771"
"Takizawa","Takizawa","39.7347","141.0771","Japan","JP","JPN","Iwate","","56050","1392999985"
"Finote Selam","Finote Selam","10.7000","37.2667","Ethiopia","ET","ETH","Āmara","","55567","1231472850"
"Limoeiro do Norte","Limoeiro do Norte","-5.1458","-38.0978","Brazil","BR","BRA","Ceará","","56264","1076525292"
"Xinqing","Xinqing","48.2363","129.5059","China","CN","CHN","Heilongjiang","","55415","1156618293"
"Bihać","Bihac","44.8167","15.8667","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","56261","1070788265"
"Fréjus","Frejus","43.4330","6.7370","France","FR","FRA","Provence-Alpes-Côte d’Azur","","55750","1250083981"
"Sarikei","Sarikei","2.1256","111.5219","Malaysia","MY","MYS","Sarawak","","56228","1458988350"
"San Andrés Villa Seca","San Andres Villa Seca","14.5667","-91.5833","Guatemala","GT","GTM","Retalhuleu","minor","56060","1320905434"
"Diffun","Diffun","16.5936","121.5025","Philippines","PH","PHL","Quirino","","56102","1608094956"
"Irati","Irati","-25.4669","-50.6508","Brazil","BR","BRA","Paraná","","56207","1076276918"
"Urbiztondo","Urbiztondo","15.8227","120.3295","Philippines","PH","PHL","Pangasinan","","55557","1608709126"
"Xánthi","Xanthi","41.1333","24.8833","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","56122","1300051191"
"Zarrīn Shahr","Zarrin Shahr","32.3897","51.3767","Iran","IR","IRN","Eşfahān","minor","55984","1364248651"
"Antratsyt","Antratsyt","48.1167","39.0833","Ukraine","UA","UKR","Luhanska Oblast","","54640","1804312048"
"Khān Shaykhūn","Khan Shaykhun","35.4389","36.6511","Syria","SY","SYR","Idlib","minor","55843","1760049407"
"Longmen","Longmen","35.6119","110.5746","China","CN","CHN","Shaanxi","","55847","1156161688"
"Acul du Nord","Acul du Nord","19.6833","-72.3167","Haiti","HT","HTI","Nord","","55908","1332452359"
"Lozova","Lozova","48.8892","36.3161","Ukraine","UA","UKR","Kharkivska Oblast","minor","55827","1804128755"
"Bisceglie","Bisceglie","41.2431","16.5053","Italy","IT","ITA","Puglia","","55385","1380547143"
"Quezaltepeque","Quezaltepeque","13.8333","-89.2667","El Salvador","SV","SLV","La Libertad","","56181","1222731128"
"General Tinio","General Tinio","15.3500","121.0500","Philippines","PH","PHL","Nueva Ecija","","55925","1608687077"
"Nyenga","Nyenga","0.3800","33.1500","Uganda","UG","UGA","Buikwe","","55600","1800123019"
"Intibucá","Intibuca","14.3167","-88.1667","Honduras","HN","HND","Intibucá","","56017","1340620843"
"Rangewala","Rangewala","30.8222","74.2611","Pakistan","PK","PAK","Punjab","","55057","1586869503"
"Nawucun","Nawucun","22.0039","110.5874","China","CN","CHN","Guangdong","","55658","1156567782"
"Yongqing","Yongqing","34.7522","106.1312","China","CN","CHN","Gansu","minor","55595","1156030120"
"Viramgām","Viramgam","23.1200","72.0300","India","IN","IND","Gujarāt","","55821","1356782098"
"Frýdek-Místek","Frydek-Mistek","49.6856","18.3506","Czechia","CZ","CZE","Moravskoslezský Kraj","","55006","1203111584"
"Union","Union","42.1258","-76.0329","United States","US","USA","New York","","56047","1840058557"
"Ambilobe","Ambilobe","-13.2000","49.0500","Madagascar","MG","MDG","Antsiranana","","56000","1450374226"
"Siliancun","Siliancun","22.7155","111.3163","China","CN","CHN","Guangdong","","55642","1156569305"
"Tinley Park","Tinley Park","41.5670","-87.8049","United States","US","USA","Illinois","","55988","1840011331"
"Bambang","Bambang","16.3872","121.1075","Philippines","PH","PHL","Nueva Vizcaya","","55789","1608731814"
"Tsévié","Tsevie","6.4333","1.2167","Togo","TG","TGO","Maritime","","55775","1768281903"
"Boituva","Boituva","-23.2833","-47.6722","Brazil","BR","BRA","São Paulo","","55725","1076277699"
"Baden-Baden","Baden-Baden","48.7628","8.2408","Germany","DE","DEU","Baden-Württemberg","minor","55527","1276150269"
"Portimão","Portimao","37.1369","-8.5383","Portugal","PT","PRT","Faro","minor","55614","1620859274"
"Lingen","Lingen","52.5231","7.3231","Germany","DE","DEU","Lower Saxony","","55599","1276978218"
"Wangguanzhuang","Wangguanzhuang","37.0183","115.5773","China","CN","CHN","Hebei","","55258","1156599766"
"Volzhsk","Volzhsk","55.8703","48.3561","Russia","RU","RUS","Mariy-El","","54519","1643457067"
"Hoogeveen","Hoogeveen","52.7333","6.4833","Netherlands","NL","NLD","Drenthe","minor","55603","1528912562"
"Changling","Changling","44.2700","123.9900","China","CN","CHN","Jilin","minor","55841","1156262573"
"Hidaka","Hidaka","35.9078","139.3391","Japan","JP","JPN","Saitama","","54728","1392984149"
"Toki","Toki","35.4192","137.1832","Japan","JP","JPN","Gifu","","55388","1392224930"
"Madhupur","Madhupur","24.2600","86.6500","India","IN","IND","Jhārkhand","","55238","1356044128"
"Catu","Catu","-12.3497","-38.3831","Brazil","BR","BRA","Bahia","","55719","1076222188"
"Parsippany-Troy Hills","Parsippany-Troy Hills","40.8601","-74.4237","United States","US","USA","New Jersey","","55848","1840081736"
"Xiva","Xiva","41.3833","60.3667","Uzbekistan","UZ","UZB","Xorazm","minor","55568","1860013300"
"Narbonne","Narbonne","43.1836","3.0042","France","FR","FRA","Occitanie","minor","55516","1250476648"
"Batac","Batac","18.0554","120.5649","Philippines","PH","PHL","Ilocos Norte","","55484","1608314719"
"Hashtgerd","Hashtgerd","35.9619","50.6800","Iran","IR","IRN","Alborz","minor","55640","1364175828"
"Jaru","Jaru","-10.4389","-62.4664","Brazil","BR","BRA","Rondônia","","55806","1076089235"
"Gus’-Khrustal’nyy","Gus'-Khrustal'nyy","55.6167","40.6833","Russia","RU","RUS","Vladimirskaya Oblast’","","54533","1643398222"
"Asadābād","Asadabad","34.7825","48.1186","Iran","IR","IRN","Hamadān","minor","55703","1364818060"
"Tucano","Tucano","-10.9628","-38.7869","Brazil","BR","BRA","Bahia","","55777","1076227914"
"Xihu","Xihu","23.9594","120.4800","Taiwan","TW","TWN","Changhua","","54098","1158658057"
"Atchampeta","Atchampeta","15.5412","78.9503","India","IN","IND","Andhra Pradesh","","55722","1356275253"
"Umm Ruwaba","Umm Ruwaba","12.9046","31.2079","Sudan","SD","SDN","North Kordofan","","55742","1729566926"
"Rengo","Rengo","-34.4167","-70.8667","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","55757","1152408592"
"La Roche-sur-Yon","La Roche-sur-Yon","46.6705","-1.4260","France","FR","FRA","Pays de la Loire","minor","55147","1250977109"
"Ovar","Ovar","40.8667","-8.6333","Portugal","PT","PRT","Aveiro","minor","55398","1620832613"
"Tūyserkān","Tuyserkan","34.5481","48.4469","Iran","IR","IRN","Hamadān","minor","55708","1364054847"
"DeSoto","DeSoto","32.5992","-96.8633","United States","US","USA","Texas","","55761","1840019441"
"Martin","Martin","49.0650","18.9219","Slovakia","SK","SVK","Žilina","minor","54978","1703554366"
"Apatity","Apatity","67.5667","33.4000","Russia","RU","RUS","Murmanskaya Oblast’","","55713","1643183303"
"Azul","Azul","-36.7833","-59.8500","Argentina","AR","ARG","Buenos Aires","minor","55728","1032652005"
"Tauá","Taua","-6.0028","-40.2928","Brazil","BR","BRA","Ceará","","55716","1076613312"
"Bhongīr","Bhongir","17.5100","78.8900","India","IN","IND","Andhra Pradesh","","55000","1356326602"
"Mulanay","Mulanay","13.5222","122.4042","Philippines","PH","PHL","Quezon","","55576","1608804064"
"Zile","Zile","40.3000","35.8833","Turkey","TR","TUR","Tokat","minor","55673","1792365916"
"Asturias","Asturias","10.5679","123.7172","Philippines","PH","PHL","Cebu","","55397","1608719784"
"Narutochō-mitsuishi","Narutocho-mitsuishi","34.1667","134.6167","Japan","JP","JPN","Tokushima","","55264","1392492030"
"San Agustín Acasaguastlán","San Agustin Acasaguastlan","14.9500","-89.9667","Guatemala","GT","GTM","El Progreso","minor","55508","1320288825"
"Sacapulas","Sacapulas","15.2892","-91.0892","Guatemala","GT","GTM","Quiché","minor","55398","1320486900"
"Mercedes","Mercedes","14.1093","123.0109","Philippines","PH","PHL","Camarines Norte","","55334","1608000459"
"Limoeiro","Limoeiro","-7.8750","-35.4500","Brazil","BR","BRA","Pernambuco","","55439","1076675901"
"Monte Mor","Monte Mor","-22.9467","-47.3150","Brazil","BR","BRA","São Paulo","","55409","1076728241"
"Chicopee","Chicopee","42.1764","-72.5719","United States","US","USA","Massachusetts","","55636","1840000462"
"Canterbury","Canterbury","51.2800","1.0800","United Kingdom","GB","GBR","Kent","","54880","1826094061"
"Nāmakkal","Namakkal","11.2167","78.1667","India","IN","IND","Tamil Nādu","","55145","1356002991"
"Corby","Corby","52.4877","-0.7013","United Kingdom","GB","GBR","Northamptonshire","","54927","1826582778"
"Ghātāl","Ghatal","22.6700","87.7200","India","IN","IND","West Bengal","","54591","1356435927"
"El Banco","El Banco","8.9983","-73.9747","Colombia","CO","COL","Magdalena","minor","55530","1170111607"
"Kuala Kapuas","Kuala Kapuas","-3.0013","114.3916","Indonesia","ID","IDN","Kalimantan Tengah","minor","55573","1360858401"
"Manapla","Manapla","10.9580","123.1230","Philippines","PH","PHL","Negros Occidental","","55083","1608826969"
"Buthidaung","Buthidaung","20.8667","92.5333","Myanmar","MM","MMR","Rakhine State","","55545","1104587852"
"Pulheim","Pulheim","51.0000","6.8000","Germany","DE","DEU","North Rhine-Westphalia","","54805","1276728926"
"Belén de Escobar","Belen de Escobar","-34.3333","-58.8167","Argentina","AR","ARG","Buenos Aires","minor","54678","1032287290"
"Madison","Madison","34.7114","-86.7626","United States","US","USA","Alabama","","55551","1840015524"
"Harpanahalli","Harpanahalli","14.7877","75.9886","India","IN","IND","Karnātaka","","55317","1356150852"
"Schweinfurt","Schweinfurt","50.0500","10.2333","Germany","DE","DEU","Bavaria","","54032","1276316912"
"Horizonte","Horizonte","-4.0989","-38.4958","Brazil","BR","BRA","Ceará","","55187","1076238174"
"Khorramdarreh","Khorramdarreh","36.2072","49.1992","Iran","IR","IRN","Zanjān","minor","55368","1364890040"
"Villarrica","Villarrica","-39.2667","-72.2167","Chile","CL","CHL","Araucanía","","55478","1152280288"
"Porirua","Porirua","-41.1333","174.8500","New Zealand","NZ","NZL","Wellington","","55218","1554561880"
"West Haven","West Haven","41.2739","-72.9672","United States","US","USA","Connecticut","","55518","1840004852"
"Jaguaquara","Jaguaquara","-13.5308","-39.9708","Brazil","BR","BRA","Bahia","","55449","1076420862"
"San Remo","San Remo","43.8175","7.7750","Italy","IT","ITA","Liguria","","54529","1380767686"
"Māngrol","Mangrol","21.1200","70.1200","India","IN","IND","Gujarāt","","55094","1356213913"
"Porto Alexandre","PortoAlexandre","-15.8000","11.8500","Angola","AO","AGO","Namibe","","55494","1024489244"
"Tonekābon","Tonekabon","36.8164","50.8739","Iran","IR","IRN","Māzandarān","minor","55434","1364647913"
"Hyères","Hyeres","43.1199","6.1316","France","FR","FRA","Provence-Alpes-Côte d’Azur","","55069","1250566198"
"Yamasá","Yamasa","18.7667","-70.0167","Dominican Republic","DO","DOM","Higuamo","","55348","1214920883"
"Smyrna","Smyrna","33.8633","-84.5168","United States","US","USA","Georgia","","55460","1840015634"
"Rolim de Moura","Rolim de Moura","-11.7254","-61.7778","Brazil","BR","BRA","Rondônia","","55407","1076556836"
"Aurora","Aurora","44.0000","-79.4667","Canada","CA","CAN","Ontario","","55445","1124085034"
"Bitonto","Bitonto","41.1167","16.6833","Italy","IT","ITA","Puglia","","55127","1380312292"
"Ottappālam","Ottappalam","10.7700","76.3800","India","IN","IND","Kerala","","53790","1356181054"
"Wylie","Wylie","33.0362","-96.5161","United States","US","USA","Texas","","55426","1840022018"
"Barbalha","Barbalha","-7.3050","-39.3019","Brazil","BR","BRA","Ceará","","55323","1076490598"
"Taliwang","Taliwang","-8.7336","116.8667","Indonesia","ID","IDN","Nusa Tenggara Barat","minor","55340","1360014762"
"Pallíni","Pallini","38.0000","23.8833","Greece","GR","GRC","Attikí","","54415","1300243974"
"Challakere","Challakere","14.3120","76.6510","India","IN","IND","Karnātaka","","55194","1356147299"
"Vannes","Vannes","47.6559","-2.7603","France","FR","FRA","Bretagne","minor","53719","1250939385"
"Polūr","Polur","12.5000","79.1300","India","IN","IND","Tamil Nādu","","55000","1356267180"
"Puttūr","Puttur","13.4500","79.5500","India","IN","IND","Andhra Pradesh","","54092","1356396930"
"Diamond Bar","Diamond Bar","33.9992","-117.8161","United States","US","USA","California","","55339","1840019244"
"Songlindian","Songlindian","39.4100","115.9235","China","CN","CHN","Hebei","","54467","1156220677"
"San Isidro","San Isidro","15.3097","120.9069","Philippines","PH","PHL","Nueva Ecija","","54372","1608420595"
"Chonthrhu","Chonthrhu","32.4865","75.9941","India","IN","IND","Himāchal Pradesh","","55083","1356595926"
"Hattingen","Hattingen","51.3992","7.1858","Germany","DE","DEU","North Rhine-Westphalia","","54562","1276698647"
"Bad Homburg","Bad Homburg","50.2167","8.6000","Germany","DE","DEU","Hesse","minor","54248","1276019348"
"Pombal","Pombal","39.9161","-8.6281","Portugal","PT","PRT","Leiria","minor","55217","1620430100"
"Christchurch","Christchurch","50.7300","-1.7800","United Kingdom","GB","GBR","Dorset","","54210","1826669534"
"Hervey Bay","Hervey Bay","-25.2900","152.8400","Australia","AU","AUS","Queensland","","54674","1036833938"
"Islāmpur","Islampur","26.2700","88.2000","India","IN","IND","West Bengal","","54340","1356028148"
"Modica","Modica","36.8672","14.7614","Italy","IT","ITA","Sicilia","","54530","1380522093"
"Apple Valley","Apple Valley","44.7457","-93.2004","United States","US","USA","Minnesota","","55237","1840006768"
"Dompu","Dompu","-8.5364","118.4633","Indonesia","ID","IDN","Nusa Tenggara Barat","minor","54987","1360208134"
"Agbangnizoun","Agbangnizoun","7.0667","1.9667","Benin","BJ","BEN","Zou","","55001","1204553139"
"Chino","Chino","35.9955","138.1588","Japan","JP","JPN","Nagano","","55018","1392143893"
"Punganūru","Punganuru","13.3667","78.5833","India","IN","IND","Andhra Pradesh","","54746","1356796945"
"Bávaro","Bavaro","18.7167","-68.4500","Dominican Republic","DO","DOM","Yuma","","55000","1214902144"
"Juruti","Juruti","-2.1519","-56.0919","Brazil","BR","BRA","Pará","","55179","1076008475"
"Eqbālīyeh","Eqbaliyeh","36.2314","49.9258","Iran","IR","IRN","Qazvīn","","55066","1364700294"
"Acıpayam","Acipayam","37.4250","29.3539","Turkey","TR","TUR","Denizli","minor","55141","1792192202"
"Nāndod","Nandod","21.8706","73.5028","India","IN","IND","Gujarāt","","54923","1356800561"
"Konan","Konan","35.0000","136.0833","Japan","JP","JPN","Shiga","","54392","1392003316"
"Santa Cruz","Santa Cruz","10.2358","-85.6408","Costa Rica","CR","CRI","Guanacaste","","55104","1188628183"
"Carigara","Carigara","11.3000","124.6833","Philippines","PH","PHL","Leyte","","54656","1608370270"
"Funato","Funato","34.2500","135.3167","Japan","JP","JPN","Wakayama","","53714","1392061989"
"Zhushan","Zhushan","23.6889","120.7289","Taiwan","TW","TWN","Nantou","","54870","1158343390"
"Ucuma","Ucuma","-12.8500","15.0667","Angola","AO","AGO","Huambo","","55054","1024023900"
"Santa Isabel","Santa Isabel","-23.3158","-46.2208","Brazil","BR","BRA","São Paulo","","54926","1076338328"
"Dikwella South","Dikwella South","5.9667","80.6833","Sri Lanka","LK","LKA","Southern","","54370","1144562731"
"Oxchuc","Oxchuc","16.7833","-92.2833","Mexico","MX","MEX","Chiapas","minor","54932","1484221816"
"Ŏjŏk-tong","Ojok-tong","40.2167","124.5333","North Korea","KP","PRK","P’yŏngbuk","","54767","1408839337"
"Khurai","Khurai","24.0437","78.3301","India","IN","IND","Madhya Pradesh","","54794","1356021522"
"Ensenada","Ensenada","-34.8644","-57.9103","Argentina","AR","ARG","Buenos Aires","minor","54463","1032568012"
"Keighley","Keighley","53.8670","-1.9110","United Kingdom","GB","GBR","Bradford","","53331","1826638204"
"Estancia","Estancia","11.4500","123.1500","Philippines","PH","PHL","Iloilo","","53200","1608481642"
"Bilis Qooqaani","Bilis Qooqaani","0.2822","41.7144","Somalia","SO","SOM","Jubbada Hoose","","55000","1706381746"
"Huaishu","Huaishu","38.0828","115.0591","China","CN","CHN","Hebei","","54246","1156876312"
"Cajicá","Cajica","4.9167","-74.0333","Colombia","CO","COL","Cundinamarca","minor","54111","1170852541"
"Viçosa do Ceará","Vicosa do Ceara","-3.5619","-41.0919","Brazil","BR","BRA","Ceará","","54955","1076236694"
"Pontevedra","Pontevedra","10.3667","122.8833","Philippines","PH","PHL","Negros Occidental","","54502","1608571163"
"Pôrto Ferreira","Porto Ferreira","-21.8539","-47.4789","Brazil","BR","BRA","São Paulo","","54761","1076743280"
"Indaial","Indaial","-26.8978","-49.2319","Brazil","BR","BRA","Santa Catarina","","54854","1076950422"
"Alenquer","Alenquer","-1.9419","-54.7378","Brazil","BR","BRA","Pará","","54960","1076529970"
"Sara","Sara","11.2500","123.0167","Philippines","PH","PHL","Iloilo","","54637","1608000829"
"Pinto","Pinto","40.2500","-3.7000","Spain","ES","ESP","Madrid","","54088","1724713530"
"Barreirinhas","Barreirinhas","-2.7469","-42.8258","Brazil","BR","BRA","Maranhão","","54930","1076060419"
"Liushi","Liushi","38.5445","115.7460","China","CN","CHN","Hebei","","54151","1156768081"
"Báguanos","Baguanos","20.7631","-76.0297","Cuba","CU","CUB","Holguín","minor","54854","1192499938"
"Bradenton","Bradenton","27.4901","-82.5757","United States","US","USA","Florida","","54918","1840014173"
"Minamiuonuma","Minamiuonuma","37.0655","138.8761","Japan","JP","JPN","Niigata","","54820","1392003069"
"Brookhaven","Brookhaven","33.8743","-84.3314","United States","US","USA","Georgia","","54902","1840029224"
"Sakurai","Sakurai","34.5167","135.8500","Japan","JP","JPN","Nara","","54346","1392404351"
"Santa Cruz","Santa Cruz","13.4833","122.0333","Philippines","PH","PHL","Marinduque","","54692","1608716782"
"Luquembo","Luquembo","-10.7333","17.8833","Angola","AO","AGO","Malanje","","54880","1024564888"
"Wayne","Wayne","40.9481","-74.2453","United States","US","USA","New Jersey","","54880","1840081808"
"Kāsaragod","Kasaragod","12.5000","75.0000","India","IN","IND","Kerala","","54172","1356423448"
"Sohna","Sohna","28.2500","77.0700","India","IN","IND","Haryāna","","53962","1356866942"
"Mali","Mali","23.1277","104.7029","China","CN","CHN","Yunnan","minor","54511","1156201370"
"Hacienda Heights","Hacienda Heights","33.9970","-117.9727","United States","US","USA","California","","54841","1840017921"
"Sarpsborg","Sarpsborg","59.2858","11.1119","Norway","NO","NOR","Viken","minor","54678","1578097769"
"Barranqueras","Barranqueras","-27.4833","-58.9333","Argentina","AR","ARG","Chaco","","54698","1032660007"
"Annaka","Annaka","36.3263","138.8871","Japan","JP","JPN","Gunma","","54607","1392614505"
"Kampen","Kampen","52.5500","5.9167","Netherlands","NL","NLD","Overijssel","minor","54474","1528270646"
"Cholet","Cholet","47.0600","-0.8783","France","FR","FRA","Pays de la Loire","minor","54186","1250540016"
"Manhattan","Manhattan","39.1886","-96.6048","United States","US","USA","Kansas","","54794","1840001589"
"Xielu","Xielu","37.0359","115.6922","China","CN","CHN","Hebei","","53692","1156076584"
"Tiruvalla","Tiruvalla","9.3856","76.5756","India","IN","IND","Kerala","","52883","1356161410"
"Taquara","Taquara","-29.6508","-50.7808","Brazil","BR","BRA","Rio Grande do Sul","","54643","1076672393"
"Terneuzen","Terneuzen","51.3333","3.8333","Netherlands","NL","NLD","Zeeland","minor","54589","1528400291"
"Dandarah","Dandarah","26.1681","32.6561","Egypt","EG","EGY","Qinā","","53109","1818692271"
"Chota","Chota","-6.5614","-78.6486","Peru","PE","PER","Cajamarca","","54611","1604928947"
"Tigard","Tigard","45.4237","-122.7845","United States","US","USA","Oregon","","54750","1840021206"
"Monte Santo","Monte Santo","-10.4378","-39.3328","Brazil","BR","BRA","Bahia","","54733","1076713120"
"Saruhanlı","Saruhanli","38.7342","27.5658","Turkey","TR","TUR","Manisa","minor","54660","1792520921"
"Samāna","Samana","30.1500","76.1900","India","IN","IND","Punjab","","54072","1356972840"
"Jinchang","Jinchang","38.4858","112.9643","China","CN","CHN","Shanxi","minor","54533","1156131171"
"Masinloc","Masinloc","15.5333","119.9500","Philippines","PH","PHL","Zambales","","54529","1608869223"
"Teramo","Teramo","42.6589","13.7039","Italy","IT","ITA","Abruzzo","minor","54338","1380348976"
"San Manuel","San Manuel","16.0656","120.6667","Philippines","PH","PHL","Pangasinan","","54271","1608368777"
"Ostrołęka","Ostroleka","53.0667","21.5667","Poland","PL","POL","Mazowieckie","minor","52900","1616360926"
"Bad Salzuflen","Bad Salzuflen","52.0833","8.7467","Germany","DE","DEU","North Rhine-Westphalia","","54127","1276526163"
"Sāgar","Sagar","14.1667","75.0333","India","IN","IND","Karnātaka","","54550","1356424629"
"Antanifotsy","Antanifotsy","-19.6800","47.3300","Madagascar","MG","MDG","Antananarivo","","54448","1450226310"
"Rāhuri","Rahuri","19.3800","74.6500","India","IN","IND","Mahārāshtra","","54325","1356541503"
"Manjeri","Manjeri","11.1197","76.1310","India","IN","IND","Kerala","","53650","1356408570"
"Kızılpınar","Kizilpinar","41.2667","27.9667","Turkey","TR","TUR","Tekirdağ","","54268","1792011924"
"Sidhi","Sidhi","24.4200","81.8800","India","IN","IND","Madhya Pradesh","","54331","1356440000"
"Chenab Nagar","Chenab Nagar","31.7528","72.9222","Pakistan","PK","PAK","Punjab","","53965","1586549514"
"Dayin","Dayin","38.9358","115.7102","China","CN","CHN","Hebei","","53868","1156460830"
"Nihonmatsu","Nihonmatsu","37.5849","140.4312","Japan","JP","JPN","Fukushima","","54447","1392003251"
"Trento","Trento","8.0459","126.0614","Philippines","PH","PHL","Agusan del Sur","","54492","1608000411"
"Cava de’ Tirreni","Cava de' Tirreni","40.7000","14.7000","Italy","IT","ITA","Campania","","53130","1380826204"
"Jalapa","Jalapa","13.9222","-86.1253","Nicaragua","NI","NIC","Nueva Segovia","minor","54491","1558183579"
"Montesilvano","Montesilvano","42.5142","14.1494","Italy","IT","ITA","Abruzzo","","54194","1380908377"
"Tapas","Tapas","11.2622","122.5369","Philippines","PH","PHL","Capiz","","54423","1608778423"
"Mbulungu","Mbulungu","-6.0667","21.9000","Congo (Kinshasa)","CD","COD","Kasaï Central","","54424","1180152255"
"Umm Qurūn","Umm Qurun","25.2500","51.3732","Qatar","QA","QAT","Ar Rayyān","","54339","1634017384"
"Nordhorn","Nordhorn","52.4319","7.0678","Germany","DE","DEU","Lower Saxony","minor","54162","1276078036"
"São José do Rio Pardo","Sao Jose do Rio Pardo","-21.5958","-46.8889","Brazil","BR","BRA","São Paulo","","54388","1076381687"
"Shidong","Shidong","23.6193","112.0701","China","CN","CHN","Guangdong","","54347","1156939710"
"Kotka","Kotka","60.4667","26.9417","Finland","FI","FIN","Kymenlaakso","minor","54319","1246200320"
"Şabbāshahr","Sabbashahr","35.5833","51.1167","Iran","IR","IRN","Tehrān","","53971","1364361684"
"Cornillon","Cornillon","18.6667","-71.9500","Haiti","HT","HTI","Ouest","","54254","1332866584"
"Qingan","Qingan","46.8719","127.5118","China","CN","CHN","Heilongjiang","minor","53206","1156233891"
"Javānrūd","Javanrud","34.8067","46.4886","Iran","IR","IRN","Kermānshāh","minor","54354","1364422541"
"Palmaner","Palmaner","13.2007","78.7468","India","IN","IND","Andhra Pradesh","","54035","1356813856"
"Huixtla","Huixtla","15.1000","-92.5333","Mexico","MX","MEX","Chiapas","minor","54332","1484012568"
"Chaozhou","Chaozhou","22.5500","120.5333","Taiwan","TW","TWN","Pingtung","","53179","1158670506"
"Pārvatipuram","Parvatipuram","18.7800","83.4250","India","IN","IND","Andhra Pradesh","","53844","1356791674"
"Minalabac","Minalabac","13.5700","123.1844","Philippines","PH","PHL","Camarines Sur","","53981","1608455061"
"Tlacotepec","Tlacotepec","18.6882","-97.6489","Mexico","MX","MEX","Puebla","minor","52508","1484013898"
"Popeşti-Leordeni","Popesti-Leordeni","44.3800","26.1700","Romania","RO","ROU","Ilfov","","53431","1642953954"
"Sado","Sado","38.0183","138.3683","Japan","JP","JPN","Niigata","","54304","1392003124"
"Siena","Siena","43.3183","11.3314","Italy","IT","ITA","Tuscany","minor","53901","1380088439"
"El Rama","El Rama","12.1617","-84.2194","Nicaragua","NI","NIC","Costa Caribe Sur","minor","54337","1558512996"
"Piekary Śląskie","Piekary Slaskie","50.3833","18.9500","Poland","PL","POL","Śląskie","minor","53017","1616227996"
"Jalor","Jalor","25.3500","72.6167","India","IN","IND","Rājasthān","","54081","1356488284"
"Kāliyāganj","Kaliyaganj","25.6300","88.3200","India","IN","IND","West Bengal","","53530","1356116477"
"Hanyū","Hanyu","36.1726","139.5485","Japan","JP","JPN","Saitama","","53431","1392690560"
"Cachoeiras de Macacu","Cachoeiras de Macacu","-22.4628","-42.6528","Brazil","BR","BRA","Rio de Janeiro","","54273","1076367130"
"Bandar-e Torkaman","Bandar-e Torkaman","36.9017","54.0708","Iran","IR","IRN","Golestān","minor","53970","1364842482"
"Tarma","Tarma","-11.4186","-75.6908","Peru","PE","PER","Junín","","54078","1604810508"
"Yucaipa","Yucaipa","34.0336","-117.0429","United States","US","USA","California","","54312","1840021732"
"Bristol","Bristol","40.1216","-74.8667","United States","US","USA","Pennsylvania","","54309","1840001318"
"Zarafshon Shahri","Zarafshon Shahri","41.6081","64.2264","Uzbekistan","UZ","UZB","Navoiy","","54300","1860278868"
"Xingcheng","Xingcheng","40.1399","118.3030","China","CN","CHN","Hebei","","52814","1156358528"
"Valle del Guamuez","Valle del Guamuez","0.4253","-76.9053","Colombia","CO","COL","Putumayo","","54237","1170672638"
"Vikārābād","Vikarabad","17.3300","77.9000","India","IN","IND","Andhra Pradesh","","53143","1356904767"
"Ruteng","Ruteng","-8.6127","120.4630","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","53976","1360084520"
"Manglaur","Manglaur","29.8000","77.8700","India","IN","IND","Uttarākhand","","52971","1356014281"
"Hashtpar","Hashtpar","37.8008","48.9036","Iran","IR","IRN","Gīlān","minor","54178","1364496739"
"Ankadinondry-Sakay","Ankadinondry-Sakay","-19.0000","46.4639","Madagascar","MG","MDG","Antananarivo","","54217","1450977196"
"Daudnagar","Daudnagar","25.0300","84.4000","India","IN","IND","Bihār","","52364","1356738424"
"Rotorua","Rotorua","-38.1378","176.2514","New Zealand","NZ","NZL","Bay of Plenty","","54204","1554000379"
"Ixhuatlán de Madero","Ixhuatlan de Madero","20.6833","-98.0167","Mexico","MX","MEX","Veracruz","minor","54132","1484817314"
"Narva","Narva","59.3758","28.1961","Estonia","EE","EST","Narva","minor","53424","1233751159"
"Peabody","Peabody","42.5335","-70.9725","United States","US","USA","Massachusetts","","54200","1840000414"
"Barra","Barra","-11.0889","-43.1419","Brazil","BR","BRA","Bahia","","54188","1076053089"
"Tokmok","Tokmok","42.8333","75.2833","Kyrgyzstan","KG","KGZ","Chüy","","53231","1417875875"
"Mansāla","Mansala","15.9417","77.4281","India","IN","IND","Andhra Pradesh","","53819","1356670969"
"Bir Ali Ben Khalifa","Bir Ali Ben Khalifa","34.7339","10.1000","Tunisia","TN","TUN","Sfax","","54143","1788587849"
"Ban Om Noi","Ban Om Noi","13.7001","100.3241","Thailand","TH","THA","Samut Sakhon","","52457","1764298817"
"Zhigulevsk","Zhigulevsk","53.3997","49.4953","Russia","RU","RUS","Samarskaya Oblast’","","53407","1643351936"
"Umred","Umred","20.8500","79.3300","India","IN","IND","Mahārāshtra","","53971","1356332003"
"Titay","Titay","7.8703","122.5594","Philippines","PH","PHL","Zamboanga Sibugay","","53994","1608727863"
"Southaven","Southaven","34.9514","-89.9786","United States","US","USA","Mississippi","","54141","1840015519"
"Apopka","Apopka","28.7015","-81.5316","United States","US","USA","Florida","","54110","1840014093"
"Tagkawayan","Tagkawayan","13.9667","122.5333","Philippines","PH","PHL","Quezon","","54003","1608280860"
"Chèddra","Cheddra","13.4428","16.0508","Chad","TD","TCD","Barh-El-Gazel","","54072","1148029349"
"Acará","Acara","-1.9608","-48.1969","Brazil","BR","BRA","Pará","","54080","1076141011"
"Badūria","Baduria","22.7400","88.7900","India","IN","IND","West Bengal","","52493","1356034082"
"Juanjuí","Juanjui","-7.1802","-76.7265","Peru","PE","PER","San Martín","","54006","1604229756"
"Avellino","Avellino","40.9167","14.7889","Italy","IT","ITA","Campania","minor","53908","1380716267"
"Sultānganj","Sultanganj","25.2460","86.7380","India","IN","IND","Bihār","","52892","1356568049"
"Dholka","Dholka","22.7200","72.4700","India","IN","IND","Gujarāt","","53792","1356709185"
"Goālpāra","Goalpara","26.1700","90.6200","India","IN","IND","Assam","","53430","1356089059"
"Cuenca","Cuenca","40.0667","-2.1500","Spain","ES","ESP","Castille-La Mancha","minor","53988","1724316040"
"Puerto Libertador","Puerto Libertador","7.9067","-75.6733","Colombia","CO","COL","Córdoba","minor","53990","1170000625"
"Horizon West","Horizon West","28.4417","-81.6146","United States","US","USA","Florida","","54038","1840038926"
"Cabagan","Cabagan","17.4333","121.7667","Philippines","PH","PHL","Isabela","","53897","1608000078"
"Millcreek","Millcreek","42.0859","-80.1194","United States","US","USA","Pennsylvania","","54022","1840143176"
"Ghōriyān","Ghoriyan","34.3400","61.4700","Afghanistan","AF","AFG","Herāt","minor","54000","1004208542"
"Chilibre","Chilibre","9.1500","-79.6200","Panama","PA","PAN","Panamá","minor","53955","1591081204"
"New Plymouth","New Plymouth","-39.0578","174.0742","New Zealand","NZ","NZL","Taranaki","","53988","1554939405"
"Patancheruvu","Patancheruvu","17.5333","78.2645","India","IN","IND","Andhra Pradesh","","53160","1356686498"
"Chystiakove","Chystiakove","48.0219","38.6264","Ukraine","UA","UKR","Donetska Oblast","","53462","1804591771"
"Ashta","Ashta","23.0175","76.7221","India","IN","IND","Madhya Pradesh","","53628","1356002731"
"Tuni","Tuni","17.3500","82.5500","India","IN","IND","Andhra Pradesh","","53425","1356073049"
"Severn","Severn","39.1355","-76.6956","United States","US","USA","Maryland","","53955","1840005925"
"Liski","Liski","50.9667","39.5167","Russia","RU","RUS","Voronezhskaya Oblast’","","53897","1643945171"
"Neustadt","Neustadt","49.3500","8.1500","Germany","DE","DEU","Rhineland-Palatinate","minor","53491","1276053769"
"Markala","Markala","13.6739","-6.0750","Mali","ML","MLI","Ségou","","53738","1466526342"
"Três Pontas","Tres Pontas","-21.3669","-45.5128","Brazil","BR","BRA","Minas Gerais","","53860","1076017914"
"An Nimāş","An Nimas","19.1200","42.1300","Saudi Arabia","SA","SAU","‘Asīr","","53908","1682459035"
"Jaggayyapeta","Jaggayyapeta","16.8920","80.0976","India","IN","IND","Andhra Pradesh","","53530","1356537421"
"Chenārān","Chenaran","36.6456","59.1211","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","53879","1364764236"
"Svobodnyy","Svobodnyy","51.4000","128.1000","Russia","RU","RUS","Amurskaya Oblast’","","53678","1643016250"
"Salo","Salo","60.3861","23.1250","Finland","FI","FIN","Varsinais-Suomi","minor","53890","1246750581"
"Impasugong","Impasugong","8.3028","125.0014","Philippines","PH","PHL","Bukidnon","","53863","1608213204"
"A Yun Pa","A Yun Pa","13.3939","108.4408","Vietnam","VN","VNM","Gia Lai","minor","53720","1704945771"
"Bacaadweyn","Bacaadweyn","7.1917","47.5258","Somalia","SO","SOM","Mudug","","53890","1706478159"
"Santo Domingo","Santo Domingo","22.5833","-80.2383","Cuba","CU","CUB","Villa Clara","minor","53840","1192553243"
"Colton","Colton","34.0538","-117.3255","United States","US","USA","California","","53894","1840019120"
"Bījār","Bijar","35.8729","47.5993","Iran","IR","IRN","Kordestān","minor","53871","1364131066"
"Kentwood","Kentwood","42.8852","-85.5926","United States","US","USA","Michigan","","53884","1840002930"
"Magpet","Magpet","7.1167","125.1167","Philippines","PH","PHL","Cotabato","","53800","1608152372"
"Kitaotao","Kitaotao","7.6406","125.0086","Philippines","PH","PHL","Bukidnon","","53796","1608491957"
"Passau","Passau","48.5744","13.4647","Germany","DE","DEU","Bavaria","minor","53093","1276123699"
"Zongo","Zongo","4.3433","18.5928","Congo (Kinshasa)","CD","COD","Sud-Ubangi","","53743","1180165321"
"São Francisco do Sul","Sao Francisco do Sul","-26.2428","-48.6378","Brazil","BR","BRA","Santa Catarina","","53746","1076452788"
"São Francisco","Sao Francisco","-15.9489","-44.8639","Brazil","BR","BRA","Minas Gerais","","53828","1076643491"
"Hamada","Hamada","34.9000","132.0833","Japan","JP","JPN","Shimane","","53753","1392003345"
"Zaragoza","Zaragoza","15.4492","120.7933","Philippines","PH","PHL","Nueva Ecija","","53090","1608154006"
"Sibonga","Sibonga","10.0333","123.5667","Philippines","PH","PHL","Cebu","","53424","1608015475"
"Pasacao","Pasacao","13.5103","123.0419","Philippines","PH","PHL","Camarines Sur","","53461","1608469033"
"Minnetonka","Minnetonka","44.9322","-93.4598","United States","US","USA","Minnesota","","53809","1840007831"
"Kongoussi","Kongoussi","13.3333","-1.5333","Burkina Faso","BF","BFA","Centre-Nord","minor","53641","1854493633"
"Ajuy","Ajuy","11.1725","123.0196","Philippines","PH","PHL","Iloilo","","53462","1608027727"
"Wheaton","Wheaton","41.8561","-88.1085","United States","US","USA","Illinois","","53762","1840010166"
"Huesca","Huesca","42.1333","-0.4167","Spain","ES","ESP","Aragon","minor","53429","1724384043"
"Cangola","Cangola","-7.9667","15.8667","Angola","AO","AGO","Uíge","","53720","1024204807"
"Port Shepstone","Port Shepstone","-30.7500","30.4500","South Africa","ZA","ZAF","KwaZulu-Natal","","52793","1710443227"
"Devakottai","Devakottai","9.9976","78.6862","India","IN","IND","Tamil Nādu","","53456","1356661235"
"Elda","Elda","38.4789","-0.7967","Spain","ES","ESP","Valencia","","52551","1724626332"
"Marco de Canavezes","Marco de Canavezes","41.1833","-8.1500","Portugal","PT","PRT","Porto","minor","53450","1620534118"
"Dauis","Dauis","9.6250","123.8650","Philippines","PH","PHL","Bohol","minor","52492","1608702299"
"Tilhar","Tilhar","27.9800","79.7300","India","IN","IND","Uttar Pradesh","","52909","1356864780"
"Krasnokamsk","Krasnokamsk","58.0833","55.6833","Russia","RU","RUS","Permskiy Kray","","53631","1643177240"
"Upata","Upata","8.0204","-62.4100","Venezuela","VE","VEN","Bolívar","minor","53685","1862794619"
"Colmenar Viejo","Colmenar Viejo","40.6589","-3.7658","Spain","ES","ESP","Madrid","","53389","1724112232"
"Al Majāridah","Al Majaridah","19.1167","41.9167","Saudi Arabia","SA","SAU","‘Asīr","","53629","1682638402"
"Wetzlar","Wetzlar","50.5667","8.5000","Germany","DE","DEU","Hesse","minor","52969","1276020868"
"Guiuan","Guiuan","11.0300","125.7300","Philippines","PH","PHL","Eastern Samar","","53361","1608781474"
"Velletri","Velletri","41.6667","12.7833","Italy","IT","ITA","Lazio","","53188","1380946852"
"Enerhodar","Enerhodar","47.4989","34.6558","Ukraine","UA","UKR","Zaporizka Oblast","minor","53567","1804819053"
"Frechen","Frechen","50.9167","6.8167","Germany","DE","DEU","North Rhine-Westphalia","","52473","1276255319"
"Shāhpur","Shahpur","16.7000","76.8300","India","IN","IND","Karnātaka","","53366","1356480887"
"Zhangjiazhuang","Zhangjiazhuang","38.1753","114.7395","China","CN","CHN","Hebei","","52604","1156100360"
"Cambundi Catembo","Cambundi Catembo","-10.0756","17.5569","Angola","AO","AGO","Malanje","","53600","1024471573"
"Encarnación de Díaz","Encarnacion de Diaz","21.5167","-102.2333","Mexico","MX","MEX","Jalisco","minor","53555","1484206173"
"West Sacramento","West Sacramento","38.5557","-121.5505","United States","US","USA","California","","53589","1840021483"
"Anzio","Anzio","41.4472","12.6283","Italy","IT","ITA","Lazio","","52381","1380647730"
"Naka","Naka","36.4574","140.4868","Japan","JP","JPN","Ibaraki","","53035","1392003137"
"Acireale","Acireale","37.6167","15.1667","Italy","IT","ITA","Sicilia","","52269","1380128872"
"Dongshan","Dongshan","24.6319","121.7539","Taiwan","TW","TWN","Yilan","","52894","1158216051"
"Lakhminia","Lakhminia","25.4126","86.3253","India","IN","IND","Bihār","","52328","1356101691"
"Morondava","Morondava","-20.2847","44.3175","Madagascar","MG","MDG","Toliara","minor","53510","1450721607"
"Lucban","Lucban","14.1133","121.5569","Philippines","PH","PHL","Quezon","","53091","1608544826"
"Porto Feliz","Porto Feliz","-23.2150","-47.5239","Brazil","BR","BRA","São Paulo","","53402","1076231459"
"Frutal","Frutal","-20.0250","-48.9408","Brazil","BR","BRA","Minas Gerais","","53468","1076112857"
"Casa Grande","Casa Grande","32.9069","-111.7624","United States","US","USA","Arizona","","53488","1840019364"
"Thérmi","Thermi","40.5500","23.0167","Greece","GR","GRC","Kentrikí Makedonía","minor","53201","1300794818"
"Gudermes","Gudermes","43.3500","46.1000","Russia","RU","RUS","Chechnya","minor","52908","1643035629"
"Guernica","Guernica","-34.9167","-58.3833","Argentina","AR","ARG","Buenos Aires","minor","52529","1032422310"
"Pirapora","Pirapora","-17.3478","-44.9436","Brazil","BR","BRA","Minas Gerais","","53368","1076124834"
"San Manuel","San Manuel","15.3333","-87.9167","Honduras","HN","HND","Cortés","","53083","1340778630"
"Rumia","Rumia","54.5667","18.4000","Poland","PL","POL","Pomorskie","","51879","1616655416"
"Yangmei","Yangmei","22.8728","112.7802","China","CN","CHN","Guangdong","","53224","1156273985"
"Dangila","Dangila","11.2667","36.8333","Ethiopia","ET","ETH","Āmara","","53225","1231000015"
"Mutsu","Mutsu","41.2928","141.1836","Japan","JP","JPN","Aomori","","53393","1392177200"
"Barbosa","Barbosa","6.4390","-75.3330","Colombia","CO","COL","Antioquia","minor","53167","1170859178"
"Xincheng","Xincheng","39.9883","112.4673","China","CN","CHN","Shanxi","minor","52903","1156311913"
"Normal","Normal","40.5217","-88.9884","United States","US","USA","Illinois","","53446","1840009401"
"Olímpia","Olimpia","-20.7369","-48.9150","Brazil","BR","BRA","São Paulo","","53360","1076866347"
"Santo Estêvão","Santo Estevao","-12.4300","-39.2508","Brazil","BR","BRA","Bahia","","53269","1076902466"
"Pryluky","Pryluky","50.6000","32.4000","Ukraine","UA","UKR","Chernihivska Oblast","minor","53395","1804037997"
"Puttūr","Puttur","12.7648","75.1842","India","IN","IND","Karnātaka","","53061","1356466163"
"Barobo","Barobo","8.5292","126.1217","Philippines","PH","PHL","Surigao del Sur","minor","53146","1608503227"
"Molave","Molave","8.0933","123.4850","Philippines","PH","PHL","Zamboanga del Sur","","53140","1608000808"
"Vertientes","Vertientes","21.2569","-78.1492","Cuba","CU","CUB","Camagüey","minor","53299","1192237047"
"Yaozhuangcun","Yaozhuangcun","30.9113","120.9573","China","CN","CHN","Zhejiang","","52619","1156378476"
"Capivari","Capivari","-22.9950","-47.5078","Brazil","BR","BRA","São Paulo","","53152","1076000098"
"São Bento do Una","Sao Bento do Una","-8.5228","-36.4439","Brazil","BR","BRA","Pernambuco","","53242","1076263399"
"Buenavista","Buenavista","10.7000","122.6333","Philippines","PH","PHL","Iloilo","","52899","1608364604"
"Howell","Howell","40.1819","-74.1976","United States","US","USA","New Jersey","","53304","1840081644"
"San Jacinto","San Jacinto","33.7970","-116.9916","United States","US","USA","California","","53299","1840021936"
"Aurora","Aurora","7.9484","123.5819","Philippines","PH","PHL","Zamboanga del Sur","","52995","1608800482"
"Akbou","Akbou","36.4667","4.5333","Algeria","DZ","DZA","Bejaïa","","52282","1012950888"
"Tame","Tame","6.4667","-71.7333","Colombia","CO","COL","Arauca","minor","53266","1170459811"
"Canguçu","Cangucu","-31.3950","-52.6758","Brazil","BR","BRA","Rio Grande do Sul","","53259","1076710867"
"Irinjālakuda","Irinjalakuda","10.3514","76.2178","India","IN","IND","Kerala","","51585","1356196702"
"Açu","Acu","-5.5769","-36.9089","Brazil","BR","BRA","Rio Grande do Norte","","53227","1076000524"
"Karlovac","Karlovac","45.4833","15.5500","Croatia","HR","HRV","Karlovačka Županija","","53134","1191000040"
"Vyksa","Vyksa","55.3167","42.1667","Russia","RU","RUS","Nizhegorodskaya Oblast’","","53219","1643006557"
"Woerden","Woerden","52.0833","4.8833","Netherlands","NL","NLD","Utrecht","minor","52694","1528805215"
"Kalamáta","Kalamata","37.0378","22.1111","Greece","GR","GRC","Pelopónnisos","minor","53041","1300638148"
"Asunción Mita","Asuncion Mita","14.3333","-89.7167","Guatemala","GT","GTM","Jutiapa","minor","53141","1320346897"
"San Bartolomé","San Bartolome","27.9254","-15.5726","Spain","ES","ESP","Canary Islands","","53066","1724008128"
"Novato","Novato","38.0920","-122.5575","United States","US","USA","California","","53214","1840020260"
"Mladenovac","Mladenovac","44.4333","20.7000","Serbia","RS","SRB","Beograd","minor","53050","1688838780"
"Leopoldina","Leopoldina","-21.5319","-42.6428","Brazil","BR","BRA","Minas Gerais","","53145","1076352077"
"San Severo","San Severo","41.6951","15.3793","Italy","IT","ITA","Puglia","","53015","1380874809"
"Acopiara","Acopiara","-6.0950","-39.4528","Brazil","BR","BRA","Ceará","","53135","1076661571"
"Sagua la Grande","Sagua la Grande","22.8086","-80.0711","Cuba","CU","CUB","Villa Clara","minor","53077","1192446410"
"Hamtic","Hamtic","10.7000","121.9833","Philippines","PH","PHL","Antique","","52685","1608000341"
"Mandi Dabwāli","Mandi Dabwali","29.9477","74.7322","India","IN","IND","Haryāna","","52873","1356069601"
"Pasaje","Pasaje","-3.3269","-79.8049","Ecuador","EC","ECU","El Oro","","52673","1218997966"
"Pinellas Park","Pinellas Park","27.8589","-82.7078","United States","US","USA","Florida","","53130","1840015112"
"La Ceja","La Ceja","6.0306","-75.4317","Colombia","CO","COL","Antioquia","minor","52723","1170869906"
"Simojovel de Allende","Simojovel de Allende","17.1333","-92.6167","Mexico","MX","MEX","Chiapas","minor","52935","1484425686"
"Padre Garcia","Padre Garcia","13.8833","121.2167","Philippines","PH","PHL","Batangas","","51853","1608742356"
"Roskilde","Roskilde","55.6500","12.0833","Denmark","DK","DNK","Sjælland","minor","51916","1208722269"
"Galveston","Galveston","29.2484","-94.8913","United States","US","USA","Texas","","53092","1840020970"
"Edina","Edina","44.8914","-93.3602","United States","US","USA","Minnesota","","53059","1840007822"
"Ahlen","Ahlen","51.7633","7.8911","Germany","DE","DEU","North Rhine-Westphalia","","52627","1276843072"
"Arsenyev","Arsenyev","44.1667","133.2500","Russia","RU","RUS","Primorskiy Kray","","52471","1643534912"
"Panjakent","Panjakent","39.5000","67.6167","Tajikistan","TJ","TJK","Sughd","minor","52500","1762088946"
"Kleve","Kleve","51.7900","6.1400","Germany","DE","DEU","North Rhine-Westphalia","minor","52470","1276674243"
"San Estanislao","San Estanislao","-24.6500","-56.4333","Paraguay","PY","PRY","San Pedro","","52983","1600701903"
"Venkatagiri","Venkatagiri","13.9667","79.5833","India","IN","IND","Andhra Pradesh","","52688","1356644043"
"Kelaa Kebira","Kelaa Kebira","35.8667","10.5333","Tunisia","TN","TUN","Sousse","","52685","1788598421"
"Neibu","Neibu","22.6500","120.5875","Taiwan","TW","TWN","Pingtung","","52349","1158113958"
"Hyosha","Hyosha","0.6975","29.5197","Congo (Kinshasa)","CD","COD","Nord-Kivu","","52921","1180671131"
"‘Alīābād-e Katūl","\`Aliabad-e Katul","36.9083","54.8689","Iran","IR","IRN","Golestān","minor","52838","1364000152"
"Jiāganj","Jiaganj","24.2300","88.2700","India","IN","IND","West Bengal","","51790","1356818151"
"Sihor","Sihor","21.7000","71.9700","India","IN","IND","Gujarāt","","52603","1356675162"
"Sison","Sison","16.1667","120.5167","Philippines","PH","PHL","Pangasinan","","52320","1608509271"
"Mafra","Mafra","-26.1108","-49.8050","Brazil","BR","BRA","Santa Catarina","","52912","1076971048"
"Lorica","Lorica","9.2419","-75.8160","Colombia","CO","COL","Córdoba","minor","52771","1170094504"
"Lower Paxton","Lower Paxton","40.3183","-76.7983","United States","US","USA","Pennsylvania","","52925","1840149541"
"Chone","Chone","-0.6833","-80.1000","Ecuador","EC","ECU","Manabí","","52810","1218083347"
"Tomiya","Tomiya","38.4000","140.8833","Japan","JP","JPN","Miyagi","","51859","1392996342"
"Imarichō-kō","Imaricho-ko","33.2667","129.8833","Japan","JP","JPN","Saga","","52694","1392532933"
"Bani","Bani","16.1869","119.8592","Philippines","PH","PHL","Pangasinan","","52603","1608695660"
"Jangaon","Jangaon","17.7227","79.1518","India","IN","IND","Andhra Pradesh","","52394","1356403400"
"Usa","Usa","33.5320","131.3495","Japan","JP","JPN","Ōita","","52767","1392003321"
"Kobryn","Kobryn","52.2167","24.3667","Belarus","BY","BLR","Brestskaya Voblasts’","minor","52843","1112806145"
"Korāput","Koraput","18.8120","82.7105","India","IN","IND","Odisha","","52203","1356742628"
"Herriman","Herriman","40.4899","-112.0170","United States","US","USA","Utah","","52860","1840020155"
"Mongaguá","Mongagua","-24.0869","-46.6289","Brazil","BR","BRA","São Paulo","","52492","1076393935"
"Clorinda","Clorinda","-25.2833","-57.7167","Argentina","AR","ARG","Formosa","minor","52837","1032930334"
"Elyria","Elyria","41.3760","-82.1060","United States","US","USA","Ohio","","52853","1840000643"
"San Joaquin","San Joaquin","10.6000","122.0833","Philippines","PH","PHL","Iloilo","","52617","1608754625"
"Wolfenbüttel","Wolfenbuttel","52.1622","10.5369","Germany","DE","DEU","Lower Saxony","minor","52174","1276388010"
"Poprad","Poprad","49.0594","20.2975","Slovakia","SK","SVK","Prešov","minor","52037","1703451549"
"Krasnokamensk","Krasnokamensk","50.1000","118.0333","Russia","RU","RUS","Zabaykal’skiy Kray","","52811","1643383674"
"Tutóia","Tutoia","-2.7619","-42.2739","Brazil","BR","BRA","Maranhão","","52788","1076696049"
"Canoinhas","Canoinhas","-26.1769","-50.3900","Brazil","BR","BRA","Santa Catarina","","52765","1076606234"
"União da Vitória","Uniao da Vitoria","-26.2300","-51.0858","Brazil","BR","BRA","Paraná","","52735","1076355618"
"San José Villa de Allende","San Jose Villa de Allende","19.3747","-100.1475","Mexico","MX","MEX","México","minor","52641","1484492033"
"Malkara","Malkara","40.8933","26.9042","Turkey","TR","TUR","Tekirdağ","minor","52758","1792489159"
"Capitão Poço","Capitao Poco","-1.7450","-47.0650","Brazil","BR","BRA","Pará","","52768","1076870027"
"Fatwa","Fatwa","25.5096","85.3050","India","IN","IND","Bihār","","51047","1356835845"
"Granadilla de Abona","Granadilla de Abona","28.1167","-16.5833","Spain","ES","ESP","Canary Islands","","52447","1724769973"
"El Tumbador","El Tumbador","14.8667","-91.9333","Guatemala","GT","GTM","San Marcos","minor","52144","1320000006"
"Grand Island","Grand Island","40.9218","-98.3586","United States","US","USA","Nebraska","","52755","1840000956"
"Minami-Sōma","Minami-Soma","37.6422","140.9573","Japan","JP","JPN","Fukushima","","52619","1392151264"
"Thongwa","Thongwa","16.7547","96.5193","Myanmar","MM","MMR","Yangon","","52496","1104339441"
"San Miguel Ixtahuacán","San Miguel Ixtahuacan","15.2500","-91.7500","Guatemala","GT","GTM","San Marcos","minor","52452","1320896592"
"Huaiyang","Huaiyang","37.7558","114.5364","China","CN","CHN","Hebei","","50859","1156226404"
"Bargny","Bargny","14.6939","-17.2336","Senegal","SN","SEN","Dakar","","51188","1686924256"
"Ålesund","Alesund","62.4740","6.1582","Norway","NO","NOR","Møre og Romsdal","minor","52163","1578050516"
"Burauen","Burauen","10.9833","124.9000","Philippines","PH","PHL","Leyte","","52511","1608412407"
"Piaseczno","Piaseczno","52.0667","21.0167","Poland","PL","POL","Mazowieckie","minor","51945","1616877485"
"Ibajay","Ibajay","11.8211","122.1617","Philippines","PH","PHL","Aklan","","52364","1608871627"
"Naguilian","Naguilian","16.5333","120.4000","Philippines","PH","PHL","La Union","","52189","1608096678"
"Torrelavega","Torrelavega","43.3531","-4.0458","Spain","ES","ESP","Cantabria","","51237","1724275022"
"Jiyyammavalasa","Jiyyammavalasa","18.7900","83.6010","India","IN","IND","Andhra Pradesh","","52360","1356361410"
"Sironj","Sironj","24.1000","77.7000","India","IN","IND","Madhya Pradesh","","52460","1356323821"
"Granja","Granja","-3.1200","-40.8258","Brazil","BR","BRA","Ceará","","52645","1076052166"
"Kahnūj","Kahnuj","27.9500","57.7000","Iran","IR","IRN","Kermān","minor","52624","1364808068"
"An Nabk","An Nabk","34.0167","36.7167","Syria","SY","SYR","Rīf Dimashq","minor","52502","1760474665"
"Miyakojima","Miyakojima","24.8056","125.2811","Japan","JP","JPN","Okinawa","","52390","1392003221"
"Bato","Bato","13.3578","123.3678","Philippines","PH","PHL","Camarines Sur","","52155","1608558575"
"Horishni Plavni","Horishni Plavni","49.0167","33.6667","Ukraine","UA","UKR","Poltavska Oblast","","52167","1804828787"
"Bad Kreuznach","Bad Kreuznach","49.8500","7.8667","Germany","DE","DEU","Rhineland-Palatinate","minor","51695","1276733459"
"Pacora","Pacora","9.0800","-79.2800","Panama","PA","PAN","Panamá","minor","52494","1591023850"
"Mandamāri","Mandamari","18.9822","79.4811","India","IN","IND","Andhra Pradesh","","52352","1356193430"
"Sertolovo","Sertolovo","60.1500","30.2167","Russia","RU","RUS","Leningradskaya Oblast’","","52535","1643009710"
"President Roxas","President Roxas","7.1544","125.0558","Philippines","PH","PHL","Cotabato","","52512","1608182795"
"Başkale","Baskale","38.0475","44.0150","Turkey","TR","TUR","Van","minor","52544","1792787410"
"Lacey","Lacey","47.0462","-122.7933","United States","US","USA","Washington","","52572","1840019866"
"Claveria","Claveria","8.6100","124.8947","Philippines","PH","PHL","Misamis Oriental","","52478","1608363693"
"Qabqa","Qabqa","36.2667","100.6167","China","CN","CHN","Qinghai","minor","52500","1156130786"
"Bentonville","Bentonville","36.3547","-94.2305","United States","US","USA","Arkansas","","52565","1840013319"
"Caetité","Caetite","-14.0689","-42.4750","Brazil","BR","BRA","Bahia","","52531","1076209772"
"Cihanbeyli","Cihanbeyli","38.6581","32.9281","Turkey","TR","TUR","Konya","minor","52525","1792274655"
"Methuen Town","Methuen Town","42.7340","-71.1889","United States","US","USA","Massachusetts","","52536","1840031195"
"Victoria","Victoria","13.1719","121.2756","Philippines","PH","PHL","Oriental Mindoro","","52175","1608650895"
"Vyazma","Vyazma","55.2107","34.2912","Russia","RU","RUS","Smolenskaya Oblast’","minor","52506","1643507171"
"Alaminos","Alaminos","14.0635","121.2451","Philippines","PH","PHL","Laguna","","51619","1608904750"
"Boa Viagem","Boa Viagem","-5.1278","-39.7319","Brazil","BR","BRA","Ceará","","52498","1076584216"
"Tamboril","Tamboril","19.4800","-70.6000","Dominican Republic","DO","DOM","Cibao Norte","","51695","1214621539"
"Diu","Diu","20.7150","70.9844","India","IN","IND","Dādra and Nagar Haveli and Damān and Diu","","52074","1356923516"
"Bikramganj","Bikramganj","25.2107","84.2551","India","IN","IND","Bihār","","50698","1356504956"
"Phaltan","Phaltan","17.9800","74.4300","India","IN","IND","Mahārāshtra","","52118","1356097873"
"Pordenone","Pordenone","45.9500","12.6500","Italy","IT","ITA","Friuli Venezia Giulia","minor","51127","1380364177"
"Glendora","Glendora","34.1449","-117.8467","United States","US","USA","California","","52458","1840020484"
"Tekkeköy","Tekkekoy","41.2125","36.4569","Turkey","TR","TUR","Samsun","minor","52258","1792843617"
"Marechal Deodoro","Marechal Deodoro","-9.7100","-35.8950","Brazil","BR","BRA","Alagoas","","52260","1076794934"
"Rincón de la Victoria","Rincon de la Victoria","36.7167","-4.2833","Spain","ES","ESP","Andalusia","","50569","1724481445"
"Smyrna","Smyrna","35.9687","-86.5264","United States","US","USA","Tennessee","","52401","1840017758"
"Sapé","Sape","-7.0950","-35.2328","Brazil","BR","BRA","Paraíba","","52218","1076665410"
"Girardota","Girardota","6.3764","-75.4473","Colombia","CO","COL","Antioquia","minor","51662","1170812272"
"Florissant","Florissant","38.7996","-90.3269","United States","US","USA","Missouri","","52382","1840008586"
"Ibbenbüren","Ibbenburen","52.2778","7.7167","Germany","DE","DEU","North Rhine-Westphalia","","51904","1276476330"
"Ambohibary","Ambohibary","-19.6167","47.1500","Madagascar","MG","MDG","Antananarivo","","52000","1450162671"
"Naviraí","Navirai","-23.0650","-54.1908","Brazil","BR","BRA","Mato Grosso do Sul","","52367","1076724628"
"Taxco de Alarcón","Taxco de Alarcon","18.5564","-99.6050","Mexico","MX","MEX","Guerrero","minor","52217","1484987082"
"Leon","Leon","10.7808","122.3894","Philippines","PH","PHL","Iloilo","","51990","1608404496"
"Stratford","Stratford","41.2070","-73.1305","United States","US","USA","Connecticut","","52360","1840035621"
"Delmiro Gouveia","Delmiro Gouveia","-9.3858","-37.9958","Brazil","BR","BRA","Alagoas","","52262","1076057826"
"Roman","Roman","46.9300","26.9300","Romania","RO","ROU","Neamţ","","50713","1642959315"
"Plaisance","Plaisance","19.6000","-72.4667","Haiti","HT","HTI","Nord","","50367","1332601700"
"Villareal","Villareal","39.9378","-0.1014","Spain","ES","ESP","Valencia","","51369","1724206895"
"Civitavecchia","Civitavecchia","42.1000","11.8000","Italy","IT","ITA","Lazio","","51595","1380282443"
"Welland","Welland","42.9833","-79.2333","Canada","CA","CAN","Ontario","","52293","1124745616"
"Khurda","Khurda","20.1833","85.6167","India","IN","IND","Odisha","","51551","1356021518"
"Basankusu","Basankusu","1.2222","19.8028","Congo (Kinshasa)","CD","COD","Équateur","","52216","1180515335"
"Novovolynsk","Novovolynsk","50.7333","24.1667","Ukraine","UA","UKR","Volynska Oblast","","52188","1804052073"
"Cangandala","Cangandala","-9.7833","16.4333","Angola","AO","AGO","Malanje","","52220","1024477783"
"Delano","Delano","35.7662","-119.2635","United States","US","USA","California","","52206","1840019151"
"Thomazeau","Thomazeau","18.6500","-72.1000","Haiti","HT","HTI","Ouest","","52017","1332648470"
"Abdul Hakim","Abdul Hakim","30.5500","72.1328","Pakistan","PK","PAK","Punjab","","51494","1586991157"
"Cruz del Eje","Cruz del Eje","-30.7333","-64.8000","Argentina","AR","ARG","Córdoba","minor","52172","1032976495"
"Portel","Portel","-1.9358","-50.8208","Brazil","BR","BRA","Pará","","52172","1076977846"
"Kannapolis","Kannapolis","35.4764","-80.6408","United States","US","USA","North Carolina","","52173","1840014560"
"Burla","Burla","21.5098","83.8726","India","IN","IND","Odisha","","51749","1356301513"
"Belorechensk","Belorechensk","44.7686","39.8733","Russia","RU","RUS","Krasnodarskiy Kray","minor","52082","1643229263"
"Hoffman Estates","Hoffman Estates","42.0640","-88.1468","United States","US","USA","Illinois","","52153","1840011263"
"Buíque","Buique","-8.6233","-37.1564","Brazil","BR","BRA","Pernambuco","","52105","1076865556"
"Tūndla","Tundla","27.2000","78.2800","India","IN","IND","Uttar Pradesh","","50423","1356798262"
"Chajul","Chajul","15.4872","-91.0347","Guatemala","GT","GTM","Quiché","minor","52019","1320789980"
"Belle-Anse","Belle-Anse","18.2333","-72.0667","Haiti","HT","HTI","Sud-Est","","51707","1332150277"
"Santa Eulalia","Santa Eulalia","15.7333","-91.4586","Guatemala","GT","GTM","Huehuetenango","minor","51910","1320956110"
"Sagua de Tánamo","Sagua de Tanamo","20.5858","-75.2417","Cuba","CU","CUB","Holguín","minor","52013","1192754736"
"Ampanihy","Ampanihy","-24.6833","44.7500","Madagascar","MG","MDG","Toliara","","52053","1450328270"
"Yendi","Yendi","9.4324","-0.0043","Ghana","GH","GHA","Northern","","52008","1288631491"
"Beaumont","Beaumont","33.9076","-116.9766","United States","US","USA","California","","52081","1840019298"
"Gādarwāra","Gadarwara","22.9235","78.7849","India","IN","IND","Madhya Pradesh","","51736","1356199910"
"Ālbū Kamāl","Albu Kamal","34.4536","40.9367","Syria","SY","SYR","Dayr az Zawr","minor","52020","1760836213"
"Wangjiazhai","Wangjiazhai","26.6895","104.8043","China","CN","CHN","Guizhou","","51869","1156886657"
"Izumi","Izumi","32.0833","130.3500","Japan","JP","JPN","Kagoshima","","51893","1392003160"
"Bar Bigha","Bar Bigha","25.2186","85.7332","India","IN","IND","Bihār","","50424","1356047864"
"San Onofre","San Onofre","9.7333","-75.5333","Colombia","CO","COL","Sucre","minor","51991","1170481491"
"Trinidad","Trinidad","21.8042","-79.9831","Cuba","CU","CUB","Sancti Spíritus","minor","51994","1192442414"
"Pola de Siero","Pola de Siero","43.3833","-5.6500","Spain","ES","ESP","Asturias","","51792","1724178701"
"Khattan","Khattan","33.3700","75.5200","India","IN","IND","Jammu and Kashmīr","","51991","1356522570"
"Zangāreddigūdem","Zangareddigudem","17.1229","81.2953","India","IN","IND","Andhra Pradesh","","51507","1356877137"
"Dungu","Dungu","3.6167","28.5667","Congo (Kinshasa)","CD","COD","Haut-Uélé","","52000","1180164137"
"Kālpi","Kalpi","26.1200","79.7300","India","IN","IND","Uttar Pradesh","","51670","1356999222"
"Ad Dabbah","Ad Dabbah","18.0502","30.9495","Sudan","SD","SDN","Northern","","52000","1729074461"
"Mabini","Mabini","13.7167","120.9000","Philippines","PH","PHL","Batangas","","50858","1608253644"
"Timashevsk","Timashevsk","45.6167","38.9333","Russia","RU","RUS","Krasnodarskiy Kray","minor","51925","1643049653"
"Calviá","Calvia","39.5656","2.5056","Spain","ES","ESP","Balearic Islands","","51567","1724569871"
"Eilat","Eilat","29.5569","34.9517","Israel","IL","ISR","Southern","","51935","1376831370"
"Kandangan","Kandangan","-2.7833","115.2667","Indonesia","ID","IDN","Kalimantan Selatan","minor","51847","1360314802"
"Peranāmpattu","Peranampattu","12.9387","78.7028","India","IN","IND","Tamil Nādu","","51271","1356208271"
"Santa Ignacia","Santa Ignacia","15.6167","120.4333","Philippines","PH","PHL","Tarlac","","51626","1608541696"
"Parobé","Parobe","-29.6289","-50.8350","Brazil","BR","BRA","Rio Grande do Sul","","51502","1076852725"
"Metpalli","Metpalli","18.8492","78.6261","India","IN","IND","Andhra Pradesh","","50902","1356583037"
"Ntoum","Ntoum","0.3844","9.7539","Gabon","GA","GAB","Estuaire","","51954","1266045882"
"Racibórz","Raciborz","50.0833","18.2333","Poland","PL","POL","Śląskie","minor","51257","1616829452"
"Ānaiyūr","Anaiyur","9.9615","78.1118","India","IN","IND","Tamil Nādu","","50569","1356235431"
"Caldas da Rainha","Caldas da Rainha","39.4072","-9.1358","Portugal","PT","PRT","Leiria","minor","51729","1620164622"
"Bandō","Bando","36.0484","139.8887","Japan","JP","JPN","Ibaraki","","51511","1392946585"
"Caramoan","Caramoan","13.7707","123.8631","Philippines","PH","PHL","Camarines Sur","","51728","1608379060"
"El Bagre","El Bagre","7.5942","-74.8119","Colombia","CO","COL","Antioquia","minor","51862","1170419984"
"Battipaglia","Battipaglia","40.6167","14.9833","Italy","IT","ITA","Campania","","51005","1380725331"
"Cardona","Cardona","14.4861","121.2289","Philippines","PH","PHL","Rizal","","50143","1608017809"
"Covilhã","Covilha","40.2833","-7.5000","Portugal","PT","PRT","Castelo Branco","minor","51797","1620284248"
"Aguadulce","Aguadulce","8.2400","-80.5400","Panama","PA","PAN","Coclé","minor","51668","1591589017"
"Forbesganj","Forbesganj","26.3000","87.2650","India","IN","IND","Bihār","","50475","1356077404"
"Livingston","Livingston","55.8834","-3.5157","United Kingdom","GB","GBR","West Lothian","","50826","1826286558"
"Cathedral City","Cathedral City","33.8362","-116.4642","United States","US","USA","California","","51836","1840019293"
"Shimencun","Shimencun","30.6263","120.4409","China","CN","CHN","Zhejiang","","51021","1156373452"
"Itupiranga","Itupiranga","-5.1350","-49.3269","Brazil","BR","BRA","Pará","","51806","1076086545"
"Mulongo","Mulongo","-7.8333","27.0000","Congo (Kinshasa)","CD","COD","Haut-Lomami","","51603","1180171787"
"Huazangsi","Huazangsi","36.9836","103.1265","China","CN","CHN","Gansu","minor","51603","1156149225"
"Tsukubamirai","Tsukubamirai","35.9631","140.0370","Japan","JP","JPN","Ibaraki","","51134","1392787574"
"Karviná","Karvina","49.8542","18.5428","Czechia","CZ","CZE","Moravskoslezský Kraj","","50902","1203738904"
"İncirliova","Incirliova","37.8547","27.7236","Turkey","TR","TUR","Aydın","minor","51526","1792559579"
"Böblingen","Boblingen","48.6856","9.0153","Germany","DE","DEU","Baden-Württemberg","minor","50470","1276001231"
"Maubin","Maubin","16.7300","95.6500","Myanmar","MM","MMR","Ayeyarwady","","51542","1104838258"
"Speyer","Speyer","49.3194","8.4311","Germany","DE","DEU","Rhineland-Palatinate","minor","50565","1276684984"
"Isfara","Isfara","40.1167","70.6333","Tajikistan","TJ","TJK","Sughd","minor","51700","1762121684"
"Solnechnogorsk","Solnechnogorsk","56.1833","36.9833","Russia","RU","RUS","Moskovskaya Oblast’","","51612","1643958876"
"Baham","Baham","5.3333","10.4000","Cameroon","CM","CMR","Ouest","","51500","1120072101"
"Caleta Olivia","Caleta Olivia","-46.4333","-67.5333","Argentina","AR","ARG","Santa Cruz","","51733","1032563209"
"Mazara del Vallo","Mazara del Vallo","37.6500","12.5833","Italy","IT","ITA","Sicilia","","51488","1380643775"
"Tulsīpur","Tulsipur","28.1278","82.2956","Nepal","NP","NPL","","","51537","1524921574"
"Jaspur","Jaspur","29.2833","78.8167","India","IN","IND","Uttarākhand","","50523","1356100934"
"Gummersbach","Gummersbach","51.0333","7.5667","Germany","DE","DEU","North Rhine-Westphalia","minor","51126","1276576027"
"El Talar de Pacheco","El Talar de Pacheco","-34.4719","-58.6550","Argentina","AR","ARG","Buenos Aires","","50426","1032391611"
"Bowringpet","Bowringpet","12.9912","78.1780","India","IN","IND","Karnātaka","","51087","1356617296"
"Burien","Burien","47.4762","-122.3394","United States","US","USA","Washington","","51646","1840018420"
"Marigot","Marigot","18.2333","-72.3167","Haiti","HT","HTI","Sud-Est","","50734","1332275185"
"Campo Belo","Campo Belo","-20.8969","-45.2769","Brazil","BR","BRA","Minas Gerais","","51544","1076477943"
"Cerca la Source","Cerca la Source","19.1667","-71.7833","Haiti","HT","HTI","Centre","","51410","1332542335"
"Tachilek","Tachilek","20.4500","99.8833","Myanmar","MM","MMR","Shan State","","51553","1104251503"
"Guapimirim","Guapimirim","-22.5369","-42.9819","Brazil","BR","BRA","Rio de Janeiro","","51483","1076569522"
"Shahr-e Bābak","Shahr-e Babak","30.1164","55.1186","Iran","IR","IRN","Kermān","minor","51620","1364844587"
"Chieti","Chieti","42.3500","14.1667","Italy","IT","ITA","Abruzzo","minor","50770","1380558989"
"Rovigo","Rovigo","45.0809","11.7940","Italy","IT","ITA","Veneto","minor","51149","1380037431"
"São Miguel do Guamá","Sao Miguel do Guama","-1.6269","-47.4828","Brazil","BR","BRA","Pará","","51567","1076315330"
"Guarne","Guarne","6.2800","-75.4427","Colombia","CO","COL","Antioquia","minor","51265","1170977229"
"Qabb Eliâs","Qabb Elias","33.7986","35.8250","Lebanon","LB","LBN","Béqaa","","50000","1422087652"
"Bozeman","Bozeman","45.6833","-111.0558","United States","US","USA","Montana","","51574","1840018509"
"Māndvi","Mandvi","22.8333","69.3556","India","IN","IND","Gujarāt","","51376","1356046879"
"Bantacan","Bantacan","7.5483","126.1381","Philippines","PH","PHL","Davao de Oro","","51466","1608426176"
"North Bay","North Bay","46.3000","-79.4500","Canada","CA","CAN","Ontario","","51553","1124058496"
"Knysna","Knysna","-34.0356","23.0489","South Africa","ZA","ZAF","Western Cape","","51078","1710311704"
"Pau d’Alho","Pau d'Alho","-7.8969","-35.1800","Brazil","BR","BRA","Pernambuco","","51357","1076432442"
"Horsham","Horsham","51.0620","-0.3250","United Kingdom","GB","GBR","West Sussex","","50934","1826018789"
"Zhexiang","Zhexiang","24.2591","98.2826","China","CN","CHN","Yunnan","","51477","1156991627"
"Bhavāni","Bhavani","11.4455","77.6821","India","IN","IND","Tamil Nādu","","51186","1356666227"
"Butare","Butare","-2.6000","29.7500","Rwanda","RW","RWA","Southern Province","","50220","1646348441"
"Ocampo","Ocampo","13.5594","123.3761","Philippines","PH","PHL","Camarines Sur","","51073","1608626195"
"Tucurú","Tucuru","15.3000","-90.0667","Guatemala","GT","GTM","Alta Verapaz","minor","50971","1320241241"
"Scandicci","Scandicci","43.7544","11.1894","Italy","IT","ITA","Tuscany","","50645","1380917394"
"Sidlaghatta","Sidlaghatta","13.3900","77.8600","India","IN","IND","Karnātaka","","51159","1356672381"
"Kizhake Chālakudi","Kizhake Chalakudi","10.3000","76.3300","India","IN","IND","Kerala","","49525","1356982203"
"Ribeira do Pombal","Ribeira do Pombal","-10.8339","-38.5358","Brazil","BR","BRA","Bahia","","51418","1076251379"
"Uzlovaya","Uzlovaya","53.9833","38.1667","Russia","RU","RUS","Tul’skaya Oblast’","","51358","1643849363"
"Cumanayagua","Cumanayagua","22.1522","-80.2011","Cuba","CU","CUB","Cienfuegos","minor","51435","1192631357"
"Ravensburg","Ravensburg","47.7831","9.6114","Germany","DE","DEU","Baden-Württemberg","minor","50928","1276088263"
"Trairi","Trairi","-3.2778","-39.2689","Brazil","BR","BRA","Ceará","","51422","1076680878"
"Utrera","Utrera","37.1830","-5.7670","Spain","ES","ESP","Andalusia","","51402","1724187644"
"Sūsangerd","Susangerd","31.5608","48.1831","Iran","IR","IRN","Khūzestān","minor","51431","1364486333"
"Arao","Arao","32.9833","130.4353","Japan","JP","JPN","Kumamoto","","50570","1392439718"
"Kalawana","Kalawana","6.5315","80.3967","Sri Lanka","LK","LKA","Sabaragamuwa","","51307","1144289812"
"Satte","Satte","36.0781","139.7259","Japan","JP","JPN","Saitama","","49961","1392499681"
"Nentón","Nenton","15.8012","-91.7552","Guatemala","GT","GTM","Huehuetenango","minor","51349","1320745927"
"Cutervo","Cutervo","-6.3778","-78.8167","Peru","PE","PER","Cajamarca","","51272","1604253488"
"Santa Cruz del Sur","Santa Cruz del Sur","20.7194","-77.9911","Cuba","CU","CUB","Camagüey","minor","51335","1192916040"
"Montijo","Montijo","38.6833","-8.9000","Portugal","PT","PRT","Setúbal","minor","51222","1620226078"
"Kyzyl-Kyya","Kyzyl-Kyya","40.2611","72.1306","Kyrgyzstan","KG","KGZ","Batken","","51300","1417902733"
"Severomorsk","Severomorsk","69.0692","33.4167","Russia","RU","RUS","Murmanskaya Oblast’","","51209","1643295687"
"Willich","Willich","51.2631","6.5492","Germany","DE","DEU","North Rhine-Westphalia","","50592","1276849706"
"Tadmur","Tadmur","34.5600","38.2672","Syria","SY","SYR","Ḩimş","minor","51323","1760484707"
"Misterbianco","Misterbianco","37.5183","15.0069","Italy","IT","ITA","Sicilia","","49992","1380615462"
"Hassan Abdal","Hassan Abdal","33.8195","72.6890","Pakistan","PK","PAK","Punjab","","50044","1586993764"
"Marpalli","Marpalli","17.5389","77.7708","India","IN","IND","Andhra Pradesh","","51090","1356470150"
"Humaitá","Humaita","-7.5061","-63.0208","Brazil","BR","BRA","Amazonas","","51302","1076798064"
"Rastatt","Rastatt","48.8500","8.2000","Germany","DE","DEU","Baden-Württemberg","minor","50441","1276684629"
"Rongat","Rongat","12.4667","92.9333","India","IN","IND","Andaman and Nicobar Islands","","51247","1356004057"
"Cheremkhovo","Cheremkhovo","53.1500","103.0833","Russia","RU","RUS","Irkutskaya Oblast’","","50819","1643007385"
"Segovia","Segovia","40.9481","-4.1183","Spain","ES","ESP","Castille-Leon","minor","51258","1724953497"
"Sibsāgar","Sibsagar","26.9800","94.6300","India","IN","IND","Assam","","50781","1356506584"
"San Narciso","San Narciso","13.5677","122.5667","Philippines","PH","PHL","Quezon","","51058","1608673260"
"Cuyotenango","Cuyotenango","14.5421","-91.5723","Guatemala","GT","GTM","Suchitepéquez","minor","50860","1320056390"
"Laksar","Laksar","29.7490","78.0240","India","IN","IND","Uttarākhand","","50450","1356563386"
"Twin Falls","Twin Falls","42.5645","-114.4613","United States","US","USA","Idaho","","51223","1840021301"
"Douyu","Douyu","37.9007","114.5035","China","CN","CHN","Hebei","","49914","1156212678"
"Repalle","Repalle","16.0172","80.8294","India","IN","IND","Andhra Pradesh","","50866","1356083696"
"Chinnamanūr","Chinnamanur","10.0412","77.1709","India","IN","IND","Tamil Nādu","","50635","1356280366"
"Sakubva","Sakubva","-18.9833","32.6500","Zimbabwe","ZW","ZWE","Manicaland","","50000","1716474556"
"Snezhinsk","Snezhinsk","56.0833","60.7333","Russia","RU","RUS","Chelyabinskaya Oblast’","","51048","1643306230"
"Laval","Laval","48.0733","-0.7689","France","FR","FRA","Pays de la Loire","minor","49733","1250720617"
"Kuchaiburi","Kuchaiburi","22.2700","86.1700","India","IN","IND","Odisha","","50798","1356785102"
"Hannan","Hannan","34.3597","135.2394","Japan","JP","JPN","Ōsaka","","49794","1392003274"
"Zabīd","Zabid","14.2000","43.3167","Yemen","YE","YEM","Al Ḩudaydah","minor","50781","1887877995"
"Nanao","Nanao","37.0431","136.9674","Japan","JP","JPN","Ishikawa","","51001","1392003465"
"Fangcun","Fangcun","37.9799","114.5490","China","CN","CHN","Hebei","","49212","1156319319"
"Hilsa","Hilsa","25.3200","85.2800","India","IN","IND","Bihār","","49863","1356503158"
"Vyāra","Vyara","21.1200","73.4000","India","IN","IND","Gujarāt","","50789","1356850056"
"Timbaúba","Timbauba","-7.5050","-35.3178","Brazil","BR","BRA","Pernambuco","","50956","1076430214"
"Enid","Enid","36.4063","-97.8700","United States","US","USA","Oklahoma","","51129","1840020373"
"Sakaidechō","Sakaidecho","34.3167","133.8667","Japan","JP","JPN","Kagawa","","50577","1392517231"
"Padrauna","Padrauna","26.9000","83.9800","India","IN","IND","Uttar Pradesh","","49723","1356332075"
"Dunwoody","Dunwoody","33.9418","-84.3122","United States","US","USA","Georgia","","51103","1840022929"
"Levittown","Levittown","40.1533","-74.8531","United States","US","USA","Pennsylvania","","51100","1840005408"
"Arles","Arles","43.6767","4.6278","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","51031","1250682294"
"Jesús Menéndez","Jesus Menendez","21.1633","-76.4775","Cuba","CU","CUB","Las Tunas","minor","51002","1192323043"
"Palm Desert","Palm Desert","33.7378","-116.3695","United States","US","USA","California","","51081","1840020555"
"Houten","Houten","52.0333","5.1667","Netherlands","NL","NLD","Utrecht","minor","50223","1528005173"
"Esher","Esher","51.3691","-0.3650","United Kingdom","GB","GBR","Surrey","","50904","1826230641"
"Bas Limbé","Bas Limbe","19.8000","-72.3833","Haiti","HT","HTI","Nord","","50456","1332588396"
"Goslar","Goslar","51.9072","10.4300","Germany","DE","DEU","Lower Saxony","minor","50753","1276608610"
"Gombong","Gombong","-7.6000","109.5167","Indonesia","ID","IDN","Jawa Tengah","","50200","1360408971"
"Irondequoit","Irondequoit","43.2096","-77.5705","United States","US","USA","New York","","51054","1840058201"
"Pardwāl","Pardwal","32.3286","76.1153","India","IN","IND","Himāchal Pradesh","","50920","1356125796"
"Campos do Jordão","Campos do Jordao","-22.7394","-45.5914","Brazil","BR","BRA","São Paulo","","50852","1076353308"
"Nazarovo","Nazarovo","56.0000","90.3833","Russia","RU","RUS","Krasnoyarskiy Kray","","50397","1643743440"
"Umán","Uman","20.8500","-89.7667","Mexico","MX","MEX","Yucatán","","50993","1484028409"
"Guisa","Guisa","20.2608","-76.5381","Cuba","CU","CUB","Granma","minor","50923","1192880798"
"Pontal","Pontal","-21.0225","-48.0372","Brazil","BR","BRA","São Paulo","","50852","1076651635"
"Yūki","Yuki","36.3055","139.8766","Japan","JP","JPN","Ibaraki","","50218","1392722230"
"Aringay","Aringay","16.3982","120.3555","Philippines","PH","PHL","La Union","","50380","1608034182"
"Kalamansig","Kalamansig","6.5667","124.0500","Philippines","PH","PHL","Sultan Kudarat","","50900","1608935312"
"East Hartford","East Hartford","41.7634","-72.6152","United States","US","USA","Connecticut","","50971","1840034618"
"Naugachhia","Naugachhia","25.4000","87.1000","India","IN","IND","Bihār","","49069","1356566210"
"Sujānpur","Sujanpur","32.3127","75.6012","India","IN","IND","Punjab","","50000","1356488985"
"Kyōtango","Kyotango","35.6242","135.0611","Japan","JP","JPN","Kyōto","","50857","1392003106"
"Mozhga","Mozhga","56.4500","52.2167","Russia","RU","RUS","Udmurtiya","","49328","1643022471"
"Ye","Ye","15.2467","97.8556","Myanmar","MM","MMR","Mon State","","50798","1104513802"
"Kirishi","Kirishi","59.4500","32.0167","Russia","RU","RUS","Leningradskaya Oblast’","","50885","1643837695"
"Cuyahoga Falls","Cuyahoga Falls","41.1641","-81.5207","United States","US","USA","Ohio","","50906","1840000795"
"Goba","Goba","7.0000","39.9833","Ethiopia","ET","ETH","Oromīya","","49309","1231837780"
"Louveira","Louveira","-23.0864","-46.9506","Brazil","BR","BRA","São Paulo","","49993","1076393293"
"Miyako","Miyako","39.6414","141.9571","Japan","JP","JPN","Iwate","","50855","1392075695"
"Kizlyar","Kizlyar","43.8500","46.7167","Russia","RU","RUS","Dagestan","minor","49247","1643912106"
"Cataingan","Cataingan","12.0028","123.9944","Philippines","PH","PHL","Masbate","","50623","1608181917"
"Goshogawara","Goshogawara","40.8080","140.4401","Japan","JP","JPN","Aomori","","50757","1392003155"
"Peine","Peine","52.3203","10.2336","Germany","DE","DEU","Lower Saxony","minor","50461","1276331439"
"Susono","Susono","35.1739","138.9068","Japan","JP","JPN","Shizuoka","","50510","1392001986"
"Çermik","Cermik","38.1372","39.4511","Turkey","TR","TUR","Diyarbakır","minor","50812","1792975687"
"Moss","Moss","59.4592","10.7008","Norway","NO","NOR","Viken","minor","50290","1578386435"
"Fafe","Fafe","41.4500","-8.1667","Portugal","PT","PRT","Braga","minor","50633","1620607091"
"Nicoya","Nicoya","10.1024","-85.4431","Costa Rica","CR","CRI","Guanacaste","","50825","1188473245"
"Nakagawa","Nakagawa","33.5000","130.4167","Japan","JP","JPN","Fukuoka","","50186","1392003507"
"Banī Walīd","Bani Walid","31.7619","14.0139","Libya","LY","LBY","Mişrātah","","50824","1434398590"
"Marana","Marana","32.4355","-111.1558","United States","US","USA","Arizona","","50834","1840023057"
"Higashiura","Higashiura","34.9771","136.9656","Japan","JP","JPN","Aichi","","49252","1392003377"
"Novovyatsk","Novovyatsk","58.5083","49.6994","Russia","RU","RUS","Kirovskaya Oblast’","","49475","1643083615"
"Mishawaka","Mishawaka","41.6742","-86.1666","United States","US","USA","Indiana","","50823","1840014695"
"Pozi","Pozi","23.4611","120.2420","Taiwan","TW","TWN","Chiayi","","50000","1158227287"
"Tŭrtkŭl","Turtkul","41.5500","61.0000","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","50800","1860664878"
"Longwan","Longwan","38.9564","116.1626","China","CN","CHN","Hebei","","49949","1156506535"
"Beloeil","Beloeil","45.5667","-73.2000","Canada","CA","CAN","Quebec","","50796","1124469084"
"El Jem","El Jem","35.2967","10.7128","Tunisia","TN","TUN","Mahdia","","50611","1788320100"
"Santa Lucía del Camino","Santa Lucia del Camino","17.0667","-96.6833","Mexico","MX","MEX","Oaxaca","","49459","1484226980"
"Mahalapye","Mahalapye","-23.1000","26.8000","Botswana","BW","BWA","Central","","50744","1072304817"
"Puławy","Pulawy","51.4167","21.9667","Poland","PL","POL","Lubelskie","minor","49839","1616098904"
"Mweka","Mweka","-4.8500","21.5667","Congo (Kinshasa)","CD","COD","Kasaï","","50675","1180140663"
"Columbus","Columbus","39.2093","-85.9183","United States","US","USA","Indiana","","50718","1840013714"
"Haskah Mēnah","Haskah Menah","34.1000","70.4667","Afghanistan","AF","AFG","Nangarhār","","50595","1004312725"
"Troy","Troy","42.7354","-73.6751","United States","US","USA","New York","","50717","1840003087"
"Belleville","Belleville","44.1667","-77.3833","Canada","CA","CAN","Ontario","","50716","1124786959"
"Beruniy","Beruniy","41.6833","60.7500","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","50700","1860882371"
"Gloria","Gloria","12.9722","121.4778","Philippines","PH","PHL","Oriental Mindoro","","50496","1608174467"
"Milford city","Milford city","41.2255","-73.0625","United States","US","USA","Connecticut","","50694","1840028605"
"Northwich","Northwich","53.2590","-2.5180","United Kingdom","GB","GBR","Cheshire West and Chester","","50531","1826122027"
"Peddāpuram","Peddapuram","17.0800","82.1300","India","IN","IND","Andhra Pradesh","","49477","1356924364"
"Yasu","Yasu","35.0667","136.0333","Japan","JP","JPN","Shiga","","50049","1392205770"
"Collierville","Collierville","35.0470","-89.6987","United States","US","USA","Tennessee","","50669","1840016403"
"Yerba Buena","Yerba Buena","-26.8167","-65.3167","Argentina","AR","ARG","Tucumán","minor","50057","1032992557"
"Chbar Mon","Chbar Mon","11.4500","104.5000","Cambodia","KH","KHM","Kampong Speu","","50359","1116044649"
"Baranoa","Baranoa","10.8000","-74.9167","Colombia","CO","COL","Atlántico","minor","50261","1170983977"
"Santiago","Santiago","-29.1919","-54.8669","Brazil","BR","BRA","Rio Grande do Sul","","50635","1076821990"
"Shakhtarsk","Shakhtarsk","48.0333","38.4833","Ukraine","UA","UKR","Donetska Oblast","minor","49482","1804275296"
"Ghŭlakandoz","Ghulakandoz","40.1617","69.4678","Tajikistan","TJ","TJK","Sughd","","50200","1762073547"
"La Calera","La Calera","-32.7867","-71.1972","Chile","CL","CHL","Valparaíso","","50554","1152388166"
"Herning","Herning","56.1333","8.9833","Denmark","DK","DNK","Midtjylland","minor","50565","1208998892"
"Metema","Metema","12.9667","36.2000","Ethiopia","ET","ETH","Āmara","","50581","1231471612"
"Nichinan","Nichinan","31.6022","131.3800","Japan","JP","JPN","Miyazaki","","50518","1392942618"
"Sittard","Sittard","51.0000","5.8667","Netherlands","NL","NLD","Limburg","minor","49483","1528793014"
"Silkeborg","Silkeborg","56.1833","9.5517","Denmark","DK","DNK","Midtjylland","minor","49747","1208628878"
"Donsol","Donsol","12.9083","123.5981","Philippines","PH","PHL","Sorsogon","","50281","1608774508"
"Lörrach","Lorrach","47.6167","7.6667","Germany","DE","DEU","Baden-Württemberg","minor","49347","1276104619"
"Tebourba","Tebourba","36.8333","9.8333","Tunisia","TN","TUN","Manouba","","50458","1788846367"
"Ikot Okoro","Ikot Okoro","4.9000","7.7167","Nigeria","NG","NGA","Akwa Ibom","","50000","1566577228"
"Qinhe","Qinhe","36.5047","112.3335","China","CN","CHN","Shanxi","minor","50143","1156264789"
"El Nido","El Nido","11.1956","119.4075","Philippines","PH","PHL","Palawan","","50494","1608909858"
"Clacton-on-Sea","Clacton-on-Sea","51.7918","1.1457","United Kingdom","GB","GBR","Essex","","50548","1826044595"
"Grapevine","Grapevine","32.9343","-97.0742","United States","US","USA","Texas","","50548","1840020697"
"Nettuno","Nettuno","41.4667","12.6667","Italy","IT","ITA","Lazio","","49852","1380995908"
"Amulung","Amulung","17.8387","121.7235","Philippines","PH","PHL","Cagayan","","50336","1608751634"
"Artur Nogueira","Artur Nogueira","-22.5731","-47.1725","Brazil","BR","BRA","São Paulo","","50246","1076283170"
"Kabarore","Kabarore","-1.6211","30.3850","Rwanda","RW","RWA","Eastern Province","","50288","1646082974"
"Starogard Gdański","Starogard Gdanski","53.9667","18.5333","Poland","PL","POL","Pomorskie","minor","48690","1616735469"
"Mirabel","Mirabel","45.6500","-74.0833","Canada","CA","CAN","Quebec","","50513","1124182375"
"Nahuizalco","Nahuizalco","13.7833","-89.7333","El Salvador","SV","SLV","Sonsonate","","49081","1222682318"
"San Ignacio","San Ignacio","-26.8867","-57.0283","Paraguay","PY","PRY","Misiones","","50468","1600346775"
"Weert","Weert","51.2500","5.7000","Netherlands","NL","NLD","Limburg","minor","50011","1528448216"
"Parāsia","Parasia","22.1913","78.7590","India","IN","IND","Madhya Pradesh","","49979","1356420197"
"Wao","Wao","7.6404","124.7257","Philippines","PH","PHL","Lanao del Sur","","50366","1608411072"
"Biaora","Biaora","23.9163","76.9112","India","IN","IND","Madhya Pradesh","","50061","1356147862"
"Chinú","Chinu","9.0833","-75.3333","Colombia","CO","COL","Córdoba","minor","50313","1170780523"
"Amora","Amora","38.6265","-9.1189","Portugal","PT","PRT","Setúbal","","48629","1620947611"
"Āqchah","Aqchah","36.9114","66.1858","Afghanistan","AF","AFG","Jowzjān","minor","50000","1004364776"
"Chatrā","Chatra","24.2045","84.8770","India","IN","IND","Jhārkhand","","49985","1356105725"
"Tājūrā’","Tajura'","32.8818","13.3399","Libya","LY","LBY","Ţarābulus","","48836","1434120461"
"Bhadrāchalam","Bhadrachalam","17.6688","80.8936","India","IN","IND","Andhra Pradesh","","50087","1356456973"
"Emden","Emden","53.3669","7.2061","Germany","DE","DEU","Lower Saxony","minor","49913","1276309938"
"Ardea","Ardea","41.6167","12.5500","Italy","IT","ITA","Lazio","","49663","1380358303"
"Peñablanca","Penablanca","17.6258","121.7853","Philippines","PH","PHL","Cagayan","","50300","1608098995"
"Baicheng","Baicheng","41.7957","81.8715","China","CN","CHN","Xinjiang","minor","49207","1156826835"
"Tammampatti","Tammampatti","11.4381","78.4836","India","IN","IND","Tamil Nādu","","50000","1356881648"
"Wandan","Wandan","22.5897","120.4851","Taiwan","TW","TWN","Pingtung","","49461","1158350878"
"Summerville","Summerville","33.0016","-80.1799","United States","US","USA","South Carolina","","50318","1840016886"
"Casalnuovo di Napoli","Casalnuovo di Napoli","40.9167","14.3500","Italy","IT","ITA","Campania","","49250","1380985501"
"Pananaw","Pananaw","5.9833","121.2500","Philippines","PH","PHL","Sulu","","49849","1608891560"
"Palpalá","Palpala","-24.2500","-65.1167","Argentina","AR","ARG","Jujuy","minor","50183","1032181212"
"Sihorā","Sihora","23.4871","80.1040","India","IN","IND","Madhya Pradesh","","49988","1356579413"
"Huanghuajie","Huanghuajie","27.9950","103.5500","China","CN","CHN","Yunnan","","50032","1156435045"
"Macaúbas","Macaubas","-13.0189","-42.6989","Brazil","BR","BRA","Bahia","","50262","1076955429"
"Rivoli","Rivoli","45.0697","7.5177","Italy","IT","ITA","Piedmont","","48629","1380470611"
"Nanjian","Nanjian","25.0530","100.5231","China","CN","CHN","Yunnan","minor","50083","1156761643"
"Xiazhai","Xiazhai","27.6909","107.1837","China","CN","CHN","Guizhou","","50000","1156893074"
"Kameyama","Kameyama","34.8558","136.4517","Japan","JP","JPN","Mie","","49971","1392003390"
"São Joaquim da Barra","Sao Joaquim da Barra","-20.5808","-47.8550","Brazil","BR","BRA","São Paulo","","50110","1076321926"
"Erftstadt","Erftstadt","50.8167","6.7667","Germany","DE","DEU","North Rhine-Westphalia","","49801","1276479094"
"Downers Grove","Downers Grove","41.7949","-88.0172","United States","US","USA","Illinois","","50209","1840011404"
"Alta Floresta","Alta Floresta","-9.8758","-56.0858","Brazil","BR","BRA","Mato Grosso","","50189","1076069666"
"Cerquilho Velho","Cerquilho Velho","-23.1650","-47.7436","Brazil","BR","BRA","São Paulo","","49802","1076117670"
"Mantova","Mantova","45.1564","10.7911","Italy","IT","ITA","Lombardy","minor","49409","1380636357"
"Murray","Murray","40.6498","-111.8874","United States","US","USA","Utah","","50183","1840020159"
"Vargem Grande Paulista","Vargem Grande Paulista","-23.6028","-47.0258","Brazil","BR","BRA","São Paulo","","48720","1076840914"
"Lindong","Lindong","43.9673","119.3570","China","CN","CHN","Inner Mongolia","minor","50000","1156448425"
"Sojat","Sojat","25.9200","73.6700","India","IN","IND","Rājasthān","","50061","1356521444"
"Bhainsa","Bhainsa","19.1000","77.9667","India","IN","IND","Andhra Pradesh","","49764","1356798697"
"Borovichi","Borovichi","58.4000","33.9167","Russia","RU","RUS","Novgorodskaya Oblast’","","50144","1643059892"
"Santa Lucía","Santa Lucia","21.0281","-75.9964","Cuba","CU","CUB","Holguín","minor","50080","1192060859"
"Aarsâl","Aarsal","34.1794","36.4208","Lebanon","LB","LBN","Baalbek-Hermel","","50000","1422612862"
"Zargar","Zargar","37.8000","68.6500","Tajikistan","TJ","TJK","Khatlon","","49235","1762322356"
"Itararé","Itarare","-24.1125","-49.3317","Brazil","BR","BRA","São Paulo","","50105","1076167358"
"Chhāgalnāiya","Chhagalnaiya","23.0395","91.5146","Bangladesh","BD","BGD","Chattogram","","48243","1050234698"
"Perambalūr","Perambalur","11.2300","78.8800","India","IN","IND","Tamil Nādu","","49648","1356684065"
"Déressia","Deressia","9.7603","16.2697","Chad","TD","TCD","Tandjilé","","50113","1148460531"
"Roslavl","Roslavl","53.9500","32.8833","Russia","RU","RUS","Smolenskaya Oblast’","minor","50112","1643356591"
"Haripur","Haripur","33.9942","72.9333","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","49318","1586000122"
"Gogrial","Gogrial","8.5300","28.1000","South Sudan","SS","SSD","Warrap","","50065","1728342889"
"Shankou","Shankou","24.5032","116.4046","China","CN","CHN","Guangdong","","49896","1156084557"
"Haverford","Haverford","39.9868","-75.3164","United States","US","USA","Pennsylvania","","50111","1840152844"
"Miyoshi","Miyoshi","34.8000","132.8500","Japan","JP","JPN","Hiroshima","","50046","1392714419"
"Pontevedra","Pontevedra","11.4833","122.8333","Philippines","PH","PHL","Capiz","","49725","1608713952"
"IJmuiden","IJmuiden","52.4586","4.6194","Netherlands","NL","NLD","Noord-Holland","minor","48320","1528185831"
"Albi","Albi","43.9289","2.1464","France","FR","FRA","Occitanie","minor","48993","1250988425"
"Sesto Fiorentino","Sesto Fiorentino","43.8333","11.2000","Italy","IT","ITA","Tuscany","","49091","1380739163"
"Catalina Foothills","Catalina Foothills","32.3046","-110.8838","United States","US","USA","Arizona","","50096","1840018110"
"Sardasht","Sardasht","36.1542","45.4789","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","50000","1364996546"
"East Honolulu","East Honolulu","21.2975","-157.7211","United States","US","USA","Hawaii","","50076","1840039181"
"Upper Buchanan","Upper Buchanan","5.9161","-10.0525","Liberia","LR","LBR","Grand Bassa","","48315","1430177860"
"Shawinigan","Shawinigan","46.5667","-72.7500","Canada","CA","CAN","Quebec","","50060","1124441118"
"Tepeapulco","Tepeapulco","19.7856","-98.5517","Mexico","MX","MEX","Hidalgo","minor","49850","1484457950"
"Paine","Paine","-33.8167","-70.7500","Chile","CL","CHL","Región Metropolitana","","50028","1152629158"
"Kahemba","Kahemba","-7.2829","19.0000","Congo (Kinshasa)","CD","COD","Kwango","","50000","1180689417"
"Phalodi","Phalodi","27.1310","72.3640","India","IN","IND","Rājasthān","","49914","1356972193"
"Ceel Dheere","Ceel Dheere","5.3683","46.1911","Somalia","SO","SOM","Galguduud","","50000","1706349687"
"Godinlabe","Godinlabe","5.8794","46.6308","Somalia","SO","SOM","Galguduud","","50000","1706143900"
"Sechura","Sechura","-5.5576","-80.8223","Peru","PE","PER","Piura","","50000","1604728914"
"Porvoo","Porvoo","60.3944","25.6639","Finland","FI","FIN","Uusimaa","minor","49928","1246277728"
"Nohar","Nohar","29.1800","74.7700","India","IN","IND","Rājasthān","","49835","1356119870"
"Al Wajh","Al Wajh","26.2833","36.4167","Saudi Arabia","SA","SAU","Tabūk","","50000","1682508460"
"Abū Ḩamad","Abu Hamad","19.5433","33.3378","Sudan","SD","SDN","River Nile","","50000","1729554381"
"Nenjiang","Nenjiang","49.1745","125.2160","China","CN","CHN","Heilongjiang","minor","50000","1156694308"
"Jiuzhou","Jiuzhou","39.5054","116.5642","China","CN","CHN","Hebei","","49616","1156799658"
"Harderwijk","Harderwijk","52.3500","5.6167","Netherlands","NL","NLD","Gelderland","minor","48726","1528263150"
"Heidenheim","Heidenheim","48.6761","10.1544","Germany","DE","DEU","Baden-Württemberg","minor","49526","1276168895"
"Draper","Draper","40.4957","-111.8605","United States","US","USA","Utah","","49978","1840018747"
"Alcala","Alcala","15.8468","120.5218","Philippines","PH","PHL","Pangasinan","","48908","1608910031"
"Ejeda","Ejeda","-24.3500","44.5167","Madagascar","MG","MDG","Toliara","","49915","1450979491"
"Grasse","Grasse","43.6667","6.9167","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","48870","1250763117"
"Chikugo","Chikugo","33.2092","130.5044","Japan","JP","JPN","Fukuoka","","48794","1392003359"
"Leonberg","Leonberg","48.8014","9.0131","Germany","DE","DEU","Baden-Württemberg","","48931","1276002040"
"Bigadiç","Bigadic","39.3925","28.1311","Turkey","TR","TUR","Balıkesir","minor","49887","1792014763"
"Chomutov","Chomutov","50.4611","13.4167","Czechia","CZ","CZE","Ústecký Kraj","","48349","1203523082"
"Vryburg","Vryburg","-26.9500","24.7472","South Africa","ZA","ZAF","North West","","49588","1710969597"
"Mailiao","Mailiao","23.7500","120.2500","Taiwan","TW","TWN","Yunlin","","49298","1158170590"
"Menggala","Menggala","-4.4750","105.2414","Indonesia","ID","IDN","Lampung","minor","49767","1360247711"
"Buguruslan","Buguruslan","53.6583","52.4358","Russia","RU","RUS","Orenburgskaya Oblast’","","49245","1643675272"
"Kapadvanj","Kapadvanj","23.0200","73.0700","India","IN","IND","Gujarāt","","49308","1356950077"
"Frankenthal","Frankenthal","49.5333","8.3500","Germany","DE","DEU","Rhineland-Palatinate","minor","48773","1276888667"
"Xankəndi","Xankandi","39.8153","46.7519","Azerbaijan","AZ","AZE","Xankəndi","","49848","1031053512"
"Slonim","Slonim","53.0833","25.3167","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","49830","1112619287"
"Concepción","Concepcion","-27.3333","-65.5833","Argentina","AR","ARG","Tucumán","minor","49782","1032935670"
"Gediz","Gediz","39.0417","29.4100","Turkey","TR","TUR","Kütahya","minor","49827","1792980894"
"Santa Rita","Santa Rita","14.9953","120.6153","Philippines","PH","PHL","Pampanga","","48209","1608145435"
"Mājalgaon","Majalgaon","19.1500","76.2333","India","IN","IND","Mahārāshtra","","49453","1356596085"
"Çine","Cine","37.6117","28.0614","Turkey","TR","TUR","Aydın","minor","49760","1792704381"
"Bergkamen","Bergkamen","51.6167","7.6333","Germany","DE","DEU","North Rhine-Westphalia","","48725","1276603807"
"Prattipādu","Prattipadu","16.1878","80.3392","India","IN","IND","Andhra Pradesh","","49390","1356837402"
"Mojo","Mojo","8.6500","39.0833","Ethiopia","ET","ETH","Oromīya","","49521","1231772257"
"‘Akko","\`Akko","32.9278","35.0817","Israel","IL","ISR","Northern","","48900","1376781950"
"Karapınar","Karapinar","37.7147","33.5508","Turkey","TR","TUR","Konya","minor","49766","1792985511"
"Gualán","Gualan","15.1333","-89.3667","Guatemala","GT","GTM","Zacapa","minor","49709","1320124337"
"Sosúa","Sosua","19.7500","-70.5200","Dominican Republic","DO","DOM","Cibao Norte","","49593","1214995511"
"Sulleru","Sulleru","18.5333","83.2167","India","IN","IND","Andhra Pradesh","","49500","1356399027"
"Carmen de Viboral","Carmen de Viboral","6.0833","-75.3333","Colombia","CO","COL","Antioquia","minor","49642","1170768757"
"Sesimbra","Sesimbra","38.4437","-9.0996","Portugal","PT","PRT","Setúbal","minor","49500","1620008532"
"Noshiromachi","Noshiromachi","40.2121","140.0266","Japan","JP","JPN","Akita","","49625","1392645142"
"Rosario","Rosario","8.3814","126.0015","Philippines","PH","PHL","Agusan del Sur","","49610","1608932289"
"Lucan","Lucan","53.3544","-6.4486","Ireland","IE","IRL","South Dublin","","49279","1372345130"
"Adeje","Adeje","28.1167","-16.7167","Spain","ES","ESP","Canary Islands","","49270","1724394126"
"Tomisato","Tomisato","35.7000","140.5667","Japan","JP","JPN","Chiba","","49470","1392179230"
"Suzaka","Suzaka","36.6511","138.3073","Japan","JP","JPN","Nagano","","49393","1392723213"
"Godda","Godda","24.8270","87.2125","India","IN","IND","Jhārkhand","","48850","1356975722"
"Dumarao","Dumarao","11.2631","122.6878","Philippines","PH","PHL","Capiz","","49506","1608348217"
"Ridder","Ridder","50.3500","83.5167","Kazakhstan","KZ","KAZ","","minor","49695","1398556139"
"Batajnica","Batajnica","44.9022","20.2814","Serbia","RS","SRB","Beograd","","48600","1688675083"
"Chesterfield","Chesterfield","38.6589","-90.5803","United States","US","USA","Missouri","","49675","1840007446"
"Zyryanovsk","Zyryanovsk","49.7453","84.2548","Kazakhstan","KZ","KAZ","","minor","49658","1398361284"
"Zongshizhuang","Zongshizhuang","37.8615","115.0575","China","CN","CHN","Hebei","","49014","1156309619"
"Gronau","Gronau","52.2125","7.0417","Germany","DE","DEU","North Rhine-Westphalia","","49031","1276262054"
"Mbouda","Mbouda","5.6333","10.2500","Cameroon","CM","CMR","Ouest","","49313","1120195491"
"Araklı","Arakli","40.9333","40.0500","Turkey","TR","TUR","Trabzon","minor","49496","1792527664"
"Smethwick","Smethwick","52.4931","-1.9686","United Kingdom","GB","GBR","Sandwell","","48180","1826953119"
"Cimitarra","Cimitarra","6.3167","-73.9500","Colombia","CO","COL","Santander","minor","49593","1170205431"
"Guaxupé","Guaxupe","-21.3050","-46.7128","Brazil","BR","BRA","Minas Gerais","","49430","1076985230"
"Liujiaxia","Liujiaxia","35.9423","103.3146","China","CN","CHN","Gansu","minor","48505","1156566759"
"Bedford","Bedford","32.8464","-97.1350","United States","US","USA","Texas","","49576","1840019424"
"Berber","Berber","18.0306","33.9933","Sudan","SD","SDN","River Nile","","49556","1729960469"
"Tōkamachi","Tokamachi","37.1333","138.7500","Japan","JP","JPN","Niigata","","49472","1392044124"
"Bad Oeynhausen","Bad Oeynhausen","52.2000","8.8000","Germany","DE","DEU","North Rhine-Westphalia","","48803","1276503412"
"María la Baja","Maria la Baja","10.0000","-75.3333","Colombia","CO","COL","Bolívar","minor","49462","1170699582"
"Wofotang","Wofotang","38.6190","116.2681","China","CN","CHN","Hebei","","48986","1156126891"
"Odiongan","Odiongan","12.4000","122.0000","Philippines","PH","PHL","Romblon","","49284","1608412988"
"Hikari","Hikari","33.9619","131.9422","Japan","JP","JPN","Yamaguchi","","49013","1392832835"
"St. Louis Park","St. Louis Park","44.9488","-93.3649","United States","US","USA","Minnesota","","49539","1840008935"
"Viana","Viana","-3.2200","-45.0039","Brazil","BR","BRA","Maranhão","","49496","1076423280"
"Waalwijk","Waalwijk","51.6833","5.0667","Netherlands","NL","NLD","Noord-Brabant","minor","48815","1528562202"
"Kiblawan","Kiblawan","6.6167","125.2167","Philippines","PH","PHL","Davao del Sur","","49381","1608861661"
"Jiashizhuang","Jiashizhuang","37.8683","114.9478","China","CN","CHN","Hebei","","48274","1156215171"
"Özgön","Ozgon","40.7667","73.3000","Kyrgyzstan","KG","KGZ","Osh","","49410","1417087485"
"Donglizhuang","Donglizhuang","37.9351","115.0838","China","CN","CHN","Hebei","","48686","1156167743"
"Okhtyrka","Okhtyrka","50.3074","34.9016","Ukraine","UA","UKR","Sumska Oblast","minor","47971","1804074587"
"Muhanga","Muhanga","-2.0845","29.7527","Rwanda","RW","RWA","Southern Province","","49038","1646187395"
"Chioggia","Chioggia","45.2196","12.2789","Italy","IT","ITA","Veneto","","49430","1380033938"
"Azusa","Azusa","34.1386","-117.9124","United States","US","USA","California","","49457","1840019226"
"Asakura","Asakura","33.4167","130.6667","Japan","JP","JPN","Fukuoka","","49254","1392003100"
"Villaguay","Villaguay","-31.8500","-59.0167","Argentina","AR","ARG","Entre Ríos","minor","49445","1032112502"
"Kīlvishāram","Kilvisharam","12.9143","79.2854","India","IN","IND","Tamil Nādu","","48519","1356082976"
"East Brunswick","East Brunswick","40.4281","-74.4179","United States","US","USA","New Jersey","","49448","1840081693"
"Nomimachi","Nomimachi","36.4470","136.5541","Japan","JP","JPN","Ishikawa","","48860","1392180005"
"Vargem Grande","Vargem Grande","-3.5428","-43.9158","Brazil","BR","BRA","Maranhão","","49412","1076198686"
"Pio Duran","Pio Duran","13.0333","123.4500","Philippines","PH","PHL","Albay","","49070","1608712032"
"Dulag","Dulag","10.9525","125.0317","Philippines","PH","PHL","Leyte","","48992","1608955994"
"Nowshahr","Nowshahr","36.6489","51.4961","Iran","IR","IRN","Māzandarān","minor","49403","1364005247"
"Midori","Midori","36.3948","139.2811","Japan","JP","JPN","Gunma","","49190","1392999995"
"Welwyn Garden City","Welwyn Garden City","51.8062","-0.1932","United Kingdom","GB","GBR","Hertfordshire","","48380","1826345498"
"Empoli","Empoli","43.7167","10.9500","Italy","IT","ITA","Tuscany","","48626","1380958169"
"Carmen","Carmen","9.8167","124.2000","Philippines","PH","PHL","Bohol","","49191","1608657913"
"Vinzons","Vinzons","14.1739","122.9081","Philippines","PH","PHL","Camarines Norte","","49042","1608285881"
"Ad Darwa","Ad Darwa","33.4167","-7.5333","Morocco","MA","MAR","Casablanca-Settat","","47719","1504000451"
"Samaniego","Samaniego","1.3500","-77.6000","Colombia","CO","COL","Nariño","minor","49178","1170018906"
"Minalin","Minalin","14.9667","120.6833","Philippines","PH","PHL","Pampanga","","48380","1608713479"
"Euclid","Euclid","41.5903","-81.5188","United States","US","USA","Ohio","","49382","1840000600"
"Cwmbran","Cwmbran","51.6530","-3.0210","United Kingdom","GB","GBR","Torfaen","","48535","1826475602"
"Mulukukú","Mulukuku","13.1764","-84.9564","Nicaragua","NI","NIC","Costa Caribe Norte","minor","49339","1558000015"
"Changyŏn","Changyon","38.2517","125.1021","North Korea","KP","PRK","Hwangnam","","48984","1408492703"
"Yong’an","Yong'an","31.0206","109.4587","China","CN","CHN","Chongqing","minor","49168","1156185323"
"Al Aaroui","Al Aaroui","35.0029","-3.0311","Morocco","MA","MAR","Oriental","","47599","1504779155"
"Beberibe","Beberibe","-4.1800","-38.1308","Brazil","BR","BRA","Ceará","","49311","1076290701"
"Malabang","Malabang","7.5903","124.0703","Philippines","PH","PHL","Lanao del Sur","","49088","1608464101"
"Óbidos","Obidos","-1.9178","-55.5178","Brazil","BR","BRA","Pará","","49333","1076197849"
"Panay","Panay","11.5553","122.7931","Philippines","PH","PHL","Capiz","minor","48890","1608684570"
"McLean","McLean","38.9436","-77.1943","United States","US","USA","Virginia","","49310","1840006018"
"Libertador General San Martín","Libertador General San Martin","-23.8000","-64.7833","Argentina","AR","ARG","Jujuy","minor","49267","1032649273"
"Sông Đốc","Song Doc","9.0333","104.8167","Vietnam","VN","VNM","Cà Mau","","49000","1704045343"
"Santa Rosa","Santa Rosa","-3.4522","-79.9617","Ecuador","EC","ECU","El Oro","","48929","1218626394"
"Rheda-Wiedenbrück","Rheda-Wiedenbruck","51.8417","8.3000","Germany","DE","DEU","North Rhine-Westphalia","","48714","1276045371"
"Coral Gables","Coral Gables","25.7037","-80.2715","United States","US","USA","Florida","","49269","1840015147"
"Monopoli","Monopoli","40.9500","17.3000","Italy","IT","ITA","Puglia","","48964","1380074643"
"Agustín Codazzi","Agustin Codazzi","10.0367","-73.2369","Colombia","CO","COL","Cesar","minor","49233","1170077466"
"Martigues","Martigues","43.4053","5.0475","France","FR","FRA","Provence-Alpes-Côte d’Azur","","48574","1250522318"
"Lecco","Lecco","45.8500","9.4000","Italy","IT","ITA","Lombardy","minor","48177","1380626602"
"Bantvāl","Bantval","12.8905","75.0349","India","IN","IND","Karnātaka","","48615","1356472512"
"Farajok","Farajok","3.8724","32.4792","South Sudan","SS","SSD","Eastern Equatoria","","49000","1728346194"
"San Benedetto del Tronto","San Benedetto del Tronto","42.9500","13.8833","Italy","IT","ITA","Marche","","47351","1380047492"
"Wejherowo","Wejherowo","54.6000","18.2500","Poland","PL","POL","Pomorskie","minor","47357","1616233029"
"Lincoln","Lincoln","38.8774","-121.2937","United States","US","USA","California","","49203","1840020231"
"Nikaweratiya","Nikaweratiya","7.7475","80.1156","Sri Lanka","LK","LKA","North Western","","48770","1144142030"
"Lesnoy","Lesnoy","58.6333","59.7833","Russia","RU","RUS","Sverdlovskaya Oblast’","","49056","1643751945"
"Saray","Saray","41.4411","27.9216","Turkey","TR","TUR","Tekirdağ","minor","49106","1792499882"
"Freising","Freising","48.4028","11.7489","Germany","DE","DEU","Bavaria","minor","48634","1276519005"
"Alsdorf","Alsdorf","50.8833","6.1667","Germany","DE","DEU","North Rhine-Westphalia","","47678","1276261156"
"Verrettes","Verrettes","19.0500","-72.4667","Haiti","HT","HTI","Artibonite","","48724","1332111444"
"Jeffersonville","Jeffersonville","38.3376","-85.7026","United States","US","USA","Indiana","","49178","1840008642"
"La Libertad","La Libertad","15.5000","-91.8333","Guatemala","GT","GTM","Huehuetenango","minor","49035","1320045134"
"Porto Nacional","Porto Nacional","-10.7078","-48.4169","Brazil","BR","BRA","Tocantins","","49146","1076043090"
"Tunuyán","Tunuyan","-33.5667","-69.0167","Argentina","AR","ARG","Mendoza","minor","49132","1032526901"
"Guntakal Junction","Guntakal Junction","15.1200","77.6300","India","IN","IND","Andhra Pradesh","","48658","1356011161"
"Ceres","Ceres","37.5952","-120.9620","United States","US","USA","California","","49132","1840018910"
"La Reja","La Reja","-34.6394","-58.8283","Argentina","AR","ARG","Buenos Aires","","47839","1032013959"
"Washington","Washington","39.7469","-75.0724","United States","US","USA","New Jersey","","49127","1840144649"
"Kifisiá","Kifisia","38.0833","23.8167","Greece","GR","GRC","Attikí","minor","47332","1300808003"
"Dachau","Dachau","48.2603","11.4342","Germany","DE","DEU","Bavaria","minor","47738","1276549079"
"Sungurlu","Sungurlu","40.1610","34.3770","Turkey","TR","TUR","Çorum","minor","49082","1792574243"
"Vich","Vich","41.9304","2.2546","Spain","ES","ESP","Catalonia","","47545","1724823476"
"Libona","Libona","8.3333","124.7433","Philippines","PH","PHL","Bukidnon","","48965","1608325771"
"Jaggisettigūdem","Jaggisettigudem","17.1167","81.3000","India","IN","IND","Andhra Pradesh","","48994","1356368347"
"Starachowice","Starachowice","51.0500","21.0667","Poland","PL","POL","Świętokrzyskie","minor","47638","1616418874"
"Huaquillas","Huaquillas","-3.4814","-80.2432","Ecuador","EC","ECU","El Oro","","47706","1218443042"
"Ascoli Piceno","Ascoli Piceno","42.8500","13.5833","Italy","IT","ITA","Marche","minor","48773","1380216238"
"Biloxi","Biloxi","30.4426","-88.9514","United States","US","USA","Mississippi","","49061","1840013948"
"Jales","Jales","-20.2689","-50.5458","Brazil","BR","BRA","São Paulo","","48922","1076132660"
"Lawrence","Lawrence","39.8674","-85.9904","United States","US","USA","Indiana","","49048","1840008434"
"Dumingag","Dumingag","8.1550","123.3450","Philippines","PH","PHL","Zamboanga del Sur","","48881","1608267722"
"Iranduba","Iranduba","-3.2850","-60.1886","Brazil","BR","BRA","Amazonas","","49011","1076838918"
"Caraballeda","Caraballeda","10.6167","-66.8500","Venezuela","VE","VEN","La Guaira","minor","48622","1862680237"
"Santo Domingo Suchitepéquez","Santo Domingo Suchitepequez","14.4667","-91.4833","Guatemala","GT","GTM","Suchitepéquez","minor","48825","1320285516"
"Bornheim","Bornheim","50.7592","7.0050","Germany","DE","DEU","North Rhine-Westphalia","","48435","1276585020"
"Gooty","Gooty","15.1210","77.6340","India","IN","IND","Andhra Pradesh","","48658","1356133077"
"Al Mayādīn","Al Mayadin","35.0183","40.4533","Syria","SY","SYR","Dayr az Zawr","minor","48922","1760808069"
"Poway","Poway","32.9871","-117.0201","United States","US","USA","California","","49005","1840020624"
"Saint-Herblain","Saint-Herblain","47.2122","-1.6497","France","FR","FRA","Pays de la Loire","","47415","1250679034"
"Cedar Hill","Cedar Hill","32.5810","-96.9591","United States","US","USA","Texas","","48968","1840019433"
"Martina Franca","Martina Franca","40.7000","17.3333","Italy","IT","ITA","Puglia","","48786","1380879312"
"Inabanga","Inabanga","10.0333","124.0667","Philippines","PH","PHL","Bohol","","48534","1608000352"
"Yamaga","Yamaga","33.0169","130.6828","Japan","JP","JPN","Kumamoto","","48753","1392023870"
"Pedreira","Pedreira","-22.7419","-46.9014","Brazil","BR","BRA","São Paulo","","48463","1076000574"
"Erlin","Erlin","23.9229","120.4091","Taiwan","TW","TWN","Changhua","","48379","1158369594"
"Omitama","Omitama","36.2393","140.3526","Japan","JP","JPN","Ibaraki","","48555","1392354218"
"Barotac Viejo","Barotac Viejo","11.0500","122.8500","Philippines","PH","PHL","Iloilo","","48614","1608392273"
"Waingapu","Waingapu","-9.6500","120.2667","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","48828","1360882365"
"Shuishang","Shuishang","23.4319","120.4147","Taiwan","TW","TWN","Chiayi","","48164","1158996361"
"Brandon","Brandon","49.8483","-99.9500","Canada","CA","CAN","Manitoba","","48859","1124239939"
"Orangetown","Orangetown","41.0527","-73.9475","United States","US","USA","New York","","48835","1840087755"
"Sanare","Sanare","9.7822","-69.7931","Venezuela","VE","VEN","Lara","minor","48764","1862592324"
"Prudentópolis","Prudentopolis","-25.2128","-50.9778","Brazil","BR","BRA","Paraná","","48792","1076128798"
"Lal-lo","Lal-lo","18.2000","121.6622","Philippines","PH","PHL","Cagayan","","48733","1608280944"
"Poções","Pocoes","-14.5300","-40.3650","Brazil","BR","BRA","Bahia","","48729","1076994566"
"Maţāy","Matay","28.4167","30.7500","Egypt","EG","EGY","Al Minyā","","46903","1818679215"
"Portage","Portage","42.2000","-85.5906","United States","US","USA","Michigan","","48767","1840003183"
"Rowland Heights","Rowland Heights","33.9716","-117.8912","United States","US","USA","California","","48765","1840019211"
"Niagara Falls","Niagara Falls","43.0921","-79.0147","United States","US","USA","New York","","48763","1840000371"
"Jasdan","Jasdan","22.0300","71.2000","India","IN","IND","Gujarāt","","48483","1356284058"
"Suwa","Suwa","36.0391","138.1140","Japan","JP","JPN","Nagano","","48309","1392189773"
"Ami","Ami","36.0308","140.2148","Japan","JP","JPN","Ibaraki","","48072","1392730678"
"Bacolor","Bacolor","14.9984","120.6526","Philippines","PH","PHL","Pampanga","","48066","1608706572"
"Hørsholm","Horsholm","55.8803","12.5081","Denmark","DK","DNK","Hovedstaden","minor","47680","1208318126"
"Kozlu","Kozlu","41.4333","31.7500","Turkey","TR","TUR","Zonguldak","minor","48381","1792656929"
"Nyaungu","Nyaungu","21.2000","94.9167","Myanmar","MM","MMR","Mandalay","","48528","1104062729"
"Boucan Carré","Boucan Carre","18.9667","-72.2000","Haiti","HT","HTI","Centre","","48700","1332163231"
"Shādegān","Shadegan","30.6497","48.6647","Iran","IR","IRN","Khūzestān","minor","48642","1364528377"
"Garmsār","Garmsar","35.2183","52.3408","Iran","IR","IRN","Semnān","minor","48672","1364421762"
"Settimo Torinese","Settimo Torinese","45.1333","7.7667","Italy","IT","ITA","Piedmont","","47220","1380123933"
"Congonhas","Congonhas","-20.5000","-43.8578","Brazil","BR","BRA","Minas Gerais","","48519","1076164621"
"’Aïn Azel","'Ain Azel","35.8433","5.5219","Algeria","DZ","DZA","Sétif","","48487","1012746080"
"New Washington","New Washington","11.6483","122.4339","Philippines","PH","PHL","Aklan","","47955","1608369678"
"Rimouski","Rimouski","48.4500","-68.5300","Canada","CA","CAN","Quebec","","48664","1124433645"
"Masagua","Masagua","14.2084","-90.8463","Guatemala","GT","GTM","Escuintla","minor","48552","1320486083"
"Cuxhaven","Cuxhaven","53.8611","8.6944","Germany","DE","DEU","Lower Saxony","minor","48356","1276511789"
"Angol","Angol","-37.8000","-72.7167","Chile","CL","CHL","Araucanía","minor","48608","1152404762"
"Begoro","Begoro","6.3833","-0.3833","Ghana","GH","GHA","Eastern","","48516","1288119992"
"Wakema","Wakema","16.6000","95.1833","Myanmar","MM","MMR","Ayeyarwady","","48405","1104266529"
"Leiktho","Leiktho","19.2222","96.5811","Myanmar","MM","MMR","Kayin State","","48606","1104120144"
"Voluntari","Voluntari","44.4925","26.1914","Romania","RO","ROU","Bucureşti","","47366","1642832091"
"Giddarbāha","Giddarbaha","30.2000","74.6700","India","IN","IND","Punjab","","48309","1356706088"
"Jalandhar Cantonment","Jalandhar Cantonment","31.2860","75.6150","India","IN","IND","Punjab","","47845","1356104211"
"Nāngal Township","Nangal Township","31.3850","76.3750","India","IN","IND","Punjab","","48000","1356311138"
"Namtu","Namtu","23.0925","97.4011","Myanmar","MM","MMR","Shan State","","48591","1104043404"
"Siocon","Siocon","7.7067","122.1361","Philippines","PH","PHL","Zamboanga del Norte","","48524","1608916747"
"Dublin","Dublin","40.1112","-83.1454","United States","US","USA","Ohio","","48613","1840008389"
"El Dorado Hills","El Dorado Hills","38.6750","-121.0490","United States","US","USA","California","","48612","1840017565"
"Gumia","Gumia","23.8106","85.8232","India","IN","IND","Jhārkhand","","48141","1356019122"
"Dongshan","Dongshan","22.0635","112.8322","China","CN","CHN","Guangdong","","48229","1156758832"
"Straubing","Straubing","48.8833","12.5667","Germany","DE","DEU","Bavaria","minor","47854","1276278805"
"Barnstable","Barnstable","41.6655","-70.3639","United States","US","USA","Massachusetts","","48556","1840031233"
"Homnābād","Homnabad","17.7707","77.1252","India","IN","IND","Karnātaka","","48202","1356768839"
"Bokāro","Bokaro","23.7871","85.9564","India","IN","IND","Jhārkhand","","48086","1356531281"
"Corato","Corato","41.1500","16.4000","Italy","IT","ITA","Puglia","","48262","1380769899"
"Ōamishirasato","Oamishirasato","35.5167","140.3167","Japan","JP","JPN","Chiba","","47722","1392003188"
"Mangapet","Mangapet","18.2500","80.5200","India","IN","IND","Andhra Pradesh","","48420","1356658499"
"Zutphen","Zutphen","52.1400","6.1950","Netherlands","NL","NLD","Gelderland","minor","47423","1528580352"
"Sanmu","Sanmu","35.6000","140.4167","Japan","JP","JPN","Chiba","","48196","1392513684"
"Helsingør","Helsingor","56.0361","12.6083","Denmark","DK","DNK","Hovedstaden","minor","47257","1208042763"
"Ridderkerk","Ridderkerk","51.8667","4.6000","Netherlands","NL","NLD","Zuid-Holland","minor","46671","1528684351"
"Beveren","Beveren","51.2000","4.2500","Belgium","BE","BEL","Flanders","","48192","1056581431"
"Kurganinsk","Kurganinsk","44.8667","40.6167","Russia","RU","RUS","Krasnodarskiy Kray","minor","48439","1643002399"
"Saint-Priest","Saint-Priest","45.6972","4.9447","France","FR","FRA","Auvergne-Rhône-Alpes","","46927","1250000042"
"Mahayag","Mahayag","8.1297","123.4383","Philippines","PH","PHL","Zamboanga del Sur","","48258","1608887239"
"Gavá","Gava","41.3072","2.0039","Spain","ES","ESP","Catalonia","","46974","1724806532"
"Soest","Soest","51.5711","8.1092","Germany","DE","DEU","North Rhine-Westphalia","minor","47929","1276851034"
"Duyên Hải","Duyen Hai","9.6331","106.4975","Vietnam","VN","VNM","Trà Vinh","minor","48210","1704264895"
"San Pedro Mixtepec","San Pedro Mixtepec","16.0000","-97.1169","Mexico","MX","MEX","Oaxaca","minor","48336","1484546885"
"Vite","Vite","17.2711","74.5378","India","IN","IND","Mahārāshtra","","48289","1356006065"
"West Orange","West Orange","40.7893","-74.2628","United States","US","USA","New Jersey","","48480","1840131596"
"Hoskote","Hoskote","13.0721","77.7892","India","IN","IND","Karnātaka","","47946","1356676321"
"Tuba","Tuba","16.3928","120.5603","Philippines","PH","PHL","Baguio","","48312","1608336813"
"Tibigan","Tibigan","9.9500","123.9667","Philippines","PH","PHL","Bohol","","47886","1608122914"
"Ishigaki","Ishigaki","24.3406","124.1556","Japan","JP","JPN","Okinawa","","48258","1392462580"
"Dornbirn","Dornbirn","47.4167","9.7500","Austria","AT","AUT","Vorarlberg","minor","48067","1040508998"
"Babaeski","Babaeski","41.4325","27.0931","Turkey","TR","TUR","Kırklareli","minor","48391","1792082896"
"Itapagé","Itapage","-3.6869","-39.5858","Brazil","BR","BRA","Ceará","","48350","1076504181"
"Évreux","Evreux","49.0200","1.1500","France","FR","FRA","Normandie","minor","46707","1250149673"
"Kołobrzeg","Kolobrzeg","54.1761","15.5761","Poland","PL","POL","Zachodniopomorskie","minor","46716","1616732501"
"Hojāi","Hojai","26.0028","92.8528","India","IN","IND","Assam","","47147","1356286275"
"Damāvand","Damavand","35.7178","52.0650","Iran","IR","IRN","Tehrān","minor","48380","1364517775"
"Mooresville","Mooresville","35.5847","-80.8266","United States","US","USA","North Carolina","","48431","1840016215"
"Spring Hill","Spring Hill","35.7437","-86.9116","United States","US","USA","Tennessee","","48403","1840015386"
"Aubagne","Aubagne","43.2908","5.5708","France","FR","FRA","Provence-Alpes-Côte d’Azur","","47535","1250602671"
"Sadāseopet","Sadaseopet","17.6203","77.9539","India","IN","IND","Andhra Pradesh","","47960","1356040284"
"Longtoushan Jiezi","Longtoushan Jiezi","27.1157","103.3817","China","CN","CHN","Yunnan","minor","48150","1156256626"
"Kalima","Kalima","-2.5667","26.6167","Congo (Kinshasa)","CD","COD","Maniema","","48337","1180335450"
"Ibiporã","Ibipora","-23.2689","-51.0478","Brazil","BR","BRA","Paraná","","48198","1076663417"
"Chinchiná","Chinchina","4.9825","-75.6036","Colombia","CO","COL","Caldas","minor","47929","1170836479"
"Děčín","Decin","50.7736","14.1961","Czechia","CZ","CZE","Ústecký Kraj","","47951","1203262893"
"Roswell","Roswell","33.3730","-104.5294","United States","US","USA","New Mexico","","48347","1840020549"
"Tarnobrzeg","Tarnobrzeg","50.5833","21.6833","Poland","PL","POL","Podkarpackie","minor","47816","1616631454"
"Durham","Durham","54.7761","-1.5733","United Kingdom","GB","GBR","Durham","","48069","1826031748"
"Xique-Xique","Xique-Xique","-10.8229","-42.7281","Brazil","BR","BRA","Bahia","","48316","1076763009"
"Inhumas","Inhumas","-16.3578","-49.4958","Brazil","BR","BRA","Goiás","","48246","1076130930"
"Lilh","Lilh","32.6900","75.6600","India","IN","IND","Jammu and Kashmīr","","47973","1356863799"
"Campi Bisenzio","Campi Bisenzio","43.8167","11.1333","Italy","IT","ITA","Tuscany","","46696","1380085514"
"Ash Shaykh Badr","Ash Shaykh Badr","34.9833","36.0833","Syria","SY","SYR","Ţarţūs","minor","47982","1760412368"
"Pangkou","Pangkou","38.6457","115.9438","China","CN","CHN","Hebei","","47139","1156758307"
"Kefamenanu","Kefamenanu","-9.4467","124.4781","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","48202","1360755918"
"Zhujiacun","Zhujiacun","26.3164","104.3893","China","CN","CHN","Yunnan","minor","48124","1156062221"
"Rio Grande da Serra","Rio Grande da Serra","-23.7439","-46.3978","Brazil","BR","BRA","São Paulo","","48302","1076473006"
"Beypazarı","Beypazari","40.1703","31.9211","Turkey","TR","TUR","Ankara","minor","48274","1792374799"
"Titao","Titao","13.7667","-2.0736","Burkina Faso","BF","BFA","Nord","minor","48242","1854649320"
"Bhatkal","Bhatkal","13.9853","74.5553","India","IN","IND","Karnātaka","","47748","1356324455"
"Çan","Can","40.0275","27.0475","Turkey","TR","TUR","Çanakkale","minor","48215","1792440839"
"Zawiercie","Zawiercie","50.5000","19.4167","Poland","PL","POL","Śląskie","minor","47689","1616113254"
"Leesburg","Leesburg","39.1057","-77.5544","United States","US","USA","Virginia","","48223","1840005872"
"Brianka","Brianka","48.5133","38.6431","Ukraine","UA","UKR","Luhanska Oblast","","47512","1804124686"
"Turda","Turda","46.5667","23.7833","Romania","RO","ROU","Cluj","","47744","1642714419"
"Joshīmath","Joshimath","30.5550","79.5650","India","IN","IND","Uttarākhand","","48202","1356268711"
"Finike","Finike","36.3000","30.1500","Turkey","TR","TUR","Antalya","minor","48131","1792503494"
"Donetsk","Donetsk","48.3333","39.9333","Russia","RU","RUS","Rostovskaya Oblast’","","47770","1643627132"
"Rancho Santa Margarita","Rancho Santa Margarita","33.6318","-117.5988","United States","US","USA","California","","48190","1840020584"
"Tuckahoe","Tuckahoe","37.5878","-77.5858","United States","US","USA","Virginia","","48187","1840006395"
"Alta Gracia","Alta Gracia","-31.6667","-64.4333","Argentina","AR","ARG","Córdoba","minor","48140","1032411567"
"Balimbing","Balimbing","5.0728","119.8847","Philippines","PH","PHL","Tawi-Tawi","","48055","1608866055"
"Shimotsuchō-kominami","Shimotsucho-kominami","34.1500","135.2167","Japan","JP","JPN","Wakayama","","47697","1392564881"
"Titusville","Titusville","28.5727","-80.8193","United States","US","USA","Florida","","48158","1840015962"
"Paterno","Paterno","37.5667","14.9000","Italy","IT","ITA","Sicilia","","47827","1380563851"
"Glenview","Glenview","42.0825","-87.8214","United States","US","USA","Illinois","","48150","1840011280"
"Yongping","Yongping","37.0103","109.8243","China","CN","CHN","Shaanxi","","47990","1156774453"
"Kashira","Kashira","54.8333","38.1667","Russia","RU","RUS","Moskovskaya Oblast’","","48016","1643003266"
"Shelekhov","Shelekhov","52.2167","104.1167","Russia","RU","RUS","Irkutskaya Oblast’","","48098","1643613779"
"Vila Verde","Vila Verde","41.6500","-8.4333","Portugal","PT","PRT","Braga","minor","47888","1620759791"
"Ena","Ena","35.4492","137.4128","Japan","JP","JPN","Gifu","","47999","1392686294"
"Mengmeng","Mengmeng","23.4718","99.8336","China","CN","CHN","Yunnan","minor","47966","1156100887"
"Perth","Perth","56.3958","-3.4333","United Kingdom","GB","GBR","Perth and Kinross","","47430","1826081674"
"Carepa","Carepa","7.7578","-76.6553","Colombia","CO","COL","Antioquia","minor","47932","1170091679"
"Tobias Barreto","Tobias Barreto","-11.1839","-37.9978","Brazil","BR","BRA","Sergipe","","48040","1076875751"
"Saint-Malo","Saint-Malo","48.6500","-2.0100","France","FR","FRA","Bretagne","minor","46803","1250296332"
"Skierniewice","Skierniewice","51.9667","20.1500","Poland","PL","POL","Łódzkie","minor","47837","1616077823"
"Wauwatosa","Wauwatosa","43.0616","-88.0347","United States","US","USA","Wisconsin","","48072","1840003033"
"Okaya","Okaya","36.0671","138.0493","Japan","JP","JPN","Nagano","","47499","1392003120"
"Stillwater","Stillwater","36.1317","-97.0742","United States","US","USA","Oklahoma","","48055","1840021675"
"Otradnyy","Otradnyy","53.3667","51.3500","Russia","RU","RUS","Samarskaya Oblast’","","47180","1643670563"
"Tonami","Tonami","36.6475","136.9622","Japan","JP","JPN","Toyama","","47638","1392414671"
"Pallipālaiyam","Pallipalaiyam","11.3679","77.7435","India","IN","IND","Tamil Nādu","","47038","1356169579"
"Stade","Stade","53.6008","9.4764","Germany","DE","DEU","Lower Saxony","minor","47579","1276472720"
"Bhamo","Bhamo","24.2667","97.2333","Myanmar","MM","MMR","Kachin State","","47920","1104508192"
"Minot","Minot","48.2375","-101.2780","United States","US","USA","North Dakota","","48005","1840000053"
"Santa Cruz do Rio Pardo","Santa Cruz do Rio Pardo","-22.8989","-49.6328","Brazil","BR","BRA","São Paulo","","47943","1076453060"
"Basoko","Basoko","1.2333","23.6000","Congo (Kinshasa)","CD","COD","Tshopo","","47970","1180509801"
"Higashine","Higashine","38.4313","140.3911","Japan","JP","JPN","Yamagata","","47747","1392717466"
"San Francisco","San Francisco","16.8017","-89.9342","Guatemala","GT","GTM","Petén","minor","47919","1320240607"
"Diamantina","Diamantina","-18.2489","-43.6000","Brazil","BR","BRA","Minas Gerais","","47952","1076865092"
"Ban Plai Bua Phatthana","Ban Plai Bua Phatthana","13.9032","100.3989","Thailand","TH","THA","Pathum Thani","","46274","1764928382"
"Mattanūr","Mattanur","11.9310","75.5720","India","IN","IND","Kerala","","47078","1356016342"
"Santana do Ipanema","Santana do Ipanema","-9.3778","-37.2450","Brazil","BR","BRA","Alagoas","","47820","1076732680"
"Mīt Salsīl","Mit Salsil","31.1667","31.8000","Egypt","EG","EGY","Ad Daqahlīyah","","47217","1818883733"
"Takeochō-takeo","Takeocho-takeo","33.1947","130.0215","Japan","JP","JPN","Saga","","47681","1392009926"
"Soest","Soest","52.1833","5.3000","Netherlands","NL","NLD","Utrecht","minor","46906","1528216001"
"Wilson","Wilson","35.7311","-77.9284","United States","US","USA","North Carolina","","47914","1840015383"
"Charleville-Mézières","Charleville-Mezieres","49.7719","4.7161","France","FR","FRA","Grand Est","minor","46436","1250492188"
"Golpāyegān","Golpayegan","33.4536","50.2883","Iran","IR","IRN","Eşfahān","minor","47849","1364867790"
"Pativilca","Pativilca","-10.6958","-77.7742","Peru","PE","PER","Lima","","47827","1604804321"
"Vigia","Vigia","-0.8578","-48.1419","Brazil","BR","BRA","Pará","","47889","1076728432"
"Rio de Mouro","Rio de Mouro","38.7689","-9.3327","Portugal","PT","PRT","Lisboa","","47311","1620970003"
"Jiquilisco","Jiquilisco","13.3167","-88.5833","El Salvador","SV","SLV","Usulután","","47784","1222869394"
"Pakenham","Pakenham","-38.0712","145.4878","Australia","AU","AUS","Victoria","","46421","1036063031"
"Lousada","Lousada","41.3000","-8.2400","Portugal","PT","PRT","Porto","minor","47387","1620988419"
"Dumka","Dumka","24.4200","87.3400","India","IN","IND","Jhārkhand","","47584","1356153239"
"Talisay","Talisay","14.1000","121.0167","Philippines","PH","PHL","Batangas","","46238","1608635733"
"Águeda","Agueda","40.5744","-8.4481","Portugal","PT","PRT","Aveiro","minor","47729","1620290042"
"Río Cauto","Rio Cauto","20.5636","-76.9172","Cuba","CU","CUB","Granma","minor","47833","1192490461"
"Kuilsrivier","Kuilsrivier","-34.0333","18.7000","South Africa","ZA","ZAF","Western Cape","","46686","1710421112"
"Yecun","Yecun","33.7663","110.1305","China","CN","CHN","Shaanxi","","47575","1156252609"
"Hennef","Hennef","50.7833","7.2833","Germany","DE","DEU","North Rhine-Westphalia","","47400","1276962178"
"Malinao","Malinao","13.4000","123.7000","Philippines","PH","PHL","Albay","","47395","1608177010"
"Media","Media","46.1500","24.3500","Romania","RO","ROU","Sibiu","","47204","1642532287"
"Ono","Ono","34.8497","134.9342","Japan","JP","JPN","Hyōgo","","47316","1392999991"
"Güroymak","Guroymak","38.5769","42.0211","Turkey","TR","TUR","Bitlis","minor","47742","1792781278"
"Luwuk","Luwuk","-0.9396","122.7900","Indonesia","ID","IDN","Sulawesi Tengah","minor","47778","1360727028"
"Newark","Newark","37.5201","-122.0307","United States","US","USA","California","","47815","1840020295"
"Penco","Penco","-36.7333","-72.9833","Chile","CL","CHL","Biobío","","47367","1152993969"
"Sogod","Sogod","10.3833","124.9833","Philippines","PH","PHL","Southern Leyte","","47552","1608917978"
"Herzogenrath","Herzogenrath","50.8667","6.1000","Germany","DE","DEU","North Rhine-Westphalia","","46402","1276873875"
"Vyshniy Volochëk","Vyshniy Volochek","57.5833","34.5667","Russia","RU","RUS","Tverskaya Oblast’","","46908","1643616804"
"Al Madrah Samā’il","Al Madrah Sama'il","23.3103","57.9455","Oman","OM","OMN","Ad Dākhilīyah","","47718","1512000205"
"Ambatondrazaka","Ambatondrazaka","-17.8256","48.4289","Madagascar","MG","MDG","Toamasina","minor","47649","1450068349"
"Vuyyūru","Vuyyuru","16.3667","80.8500","India","IN","IND","Andhra Pradesh","","46490","1356510065"
"Hitachi-ota","Hitachi-ota","36.5383","140.5309","Japan","JP","JPN","Ibaraki","","47586","1392794380"
"Amahai","Amahai","-3.3331","128.9190","Indonesia","ID","IDN","Maluku","minor","47653","1360767250"
"Pilar","Pilar","11.4878","122.9956","Philippines","PH","PHL","Capiz","","47100","1608061178"
"Kikugawa","Kikugawa","34.7500","138.0833","Japan","JP","JPN","Shizuoka","","47194","1392787606"
"Umarkhed","Umarkhed","19.6000","77.7000","India","IN","IND","Mahārāshtra","","47458","1356164903"
"Roseville","Roseville","42.5074","-82.9369","United States","US","USA","Michigan","","47692","1840003092"
"Oberursel","Oberursel","50.2033","8.5769","Germany","DE","DEU","Hesse","","46660","1276288354"
"Snizhne","Snizhne","48.0167","38.7667","Ukraine","UA","UKR","Donetska Oblast","","47259","1804966231"
"Ipiaú","Ipiau","-14.1369","-39.7339","Brazil","BR","BRA","Bahia","","47501","1076023933"
"Ban Bang Mae Nang","Ban Bang Mae Nang","13.8815","100.3759","Thailand","TH","THA","Nonthaburi","","45974","1764607798"
"Rieti","Rieti","42.4044","12.8567","Italy","IT","ITA","Lazio","minor","47436","1380045864"
"‘Āmūdā","\`Amuda","37.1042","40.9300","Syria","SY","SYR","Al Ḩasakah","minor","47580","1760247135"
"San Lorenzo","San Lorenzo","-28.1200","-58.7700","Argentina","AR","ARG","Corrientes","","47626","1032610376"
"Jitaicun","Jitaicun","36.3533","115.3048","China","CN","CHN","Hebei","","46974","1156441197"
"Vikramasingapuram","Vikramasingapuram","8.6700","77.3300","India","IN","IND","Tamil Nādu","","47241","1356054066"
"Chaïdári","Chaidari","38.0167","23.6500","Greece","GR","GRC","Attikí","minor","45642","1300077260"
"Bunawan","Bunawan","8.1781","125.9935","Philippines","PH","PHL","Agusan del Sur","","47512","1608342650"
"Manappārai","Manapparai","10.6075","78.4253","India","IN","IND","Tamil Nādu","","47216","1356338778"
"Catriel","Catriel","-37.8667","-67.8333","Argentina","AR","ARG","Río Negro","","47569","1032975217"
"Brive-la-Gaillarde","Brive-la-Gaillarde","45.1583","1.5321","France","FR","FRA","Nouvelle-Aquitaine","minor","46630","1250549859"
"Donggang","Donggang","22.4667","120.4544","Taiwan","TW","TWN","Pingtung","","45984","1158526053"
"Kutno","Kutno","52.2333","19.3667","Poland","PL","POL","Łódzkie","minor","47557","1616586955"
"Punalūr","Punalur","9.0170","76.9260","India","IN","IND","Kerala","","47226","1356688710"
"La Vega","La Vega","2.0008","-76.7778","Colombia","CO","COL","Cauca","minor","47417","1170000228"
"Chenalhó","Chenalho","16.9333","-92.5667","Mexico","MX","MEX","Chiapas","minor","47371","1484048952"
"Monroe","Monroe","40.3191","-74.4286","United States","US","USA","New Jersey","","47549","1840056354"
"Carcassonne","Carcassonne","43.2100","2.3500","France","FR","FRA","Occitanie","minor","46825","1250306687"
"Livny","Livny","52.4239","37.5997","Russia","RU","RUS","Orlovskaya Oblast’","","47221","1643284653"
"Aznā","Azna","33.4558","49.4556","Iran","IR","IRN","Lorestān","minor","47489","1364854218"
"Cunén","Cunen","15.3379","-91.0270","Guatemala","GT","GTM","Quiché","minor","47250","1320582365"
"Poso","Poso","-1.4000","120.7500","Indonesia","ID","IDN","Sulawesi Tengah","minor","47477","1360727615"
"Capão Bonito","Capao Bonito","-24.0058","-48.3494","Brazil","BR","BRA","São Paulo","","47486","1076334908"
"Isabel","Isabel","10.9300","124.4300","Philippines","PH","PHL","Leyte","","46781","1608929940"
"Landau","Landau","49.2000","8.1167","Germany","DE","DEU","Rhineland-Palatinate","minor","46919","1276000837"
"Nandikotkūr","Nandikotkur","15.8670","78.2670","India","IN","IND","Andhra Pradesh","","46953","1356046899"
"Santa Ana Chiautempan","Santa Ana Chiautempan","19.3167","-98.1833","Mexico","MX","MEX","Tlaxcala","minor","46776","1484567035"
"Mēla Gūdalūr","Mela Gudalur","9.4405","77.1603","India","IN","IND","Tamil Nādu","","46900","1356715167"
"Pilar","Pilar","14.6667","120.5667","Philippines","PH","PHL","Bataan","","46239","1608351430"
"Nanto","Nanto","36.5878","136.9194","Japan","JP","JPN","Toyama","","47390","1392003431"
"Atalaia","Atalaia","-9.5019","-36.0228","Brazil","BR","BRA","Alagoas","","47365","1076630141"
"Qingyuan","Qingyuan","24.5004","108.6667","China","CN","CHN","Guangxi","minor","47062","1156899793"
"Mataquescuintla","Mataquescuintla","14.5336","-90.1838","Guatemala","GT","GTM","Jalapa","minor","47280","1320459318"
"Borgne","Borgne","19.8500","-72.5333","Haiti","HT","HTI","Nord","","46886","1332215529"
"San Andrés de Sotavento","San Andres de Sotavento","9.1500","-75.5000","Colombia","CO","COL","Córdoba","minor","47282","1170392574"
"Sumisip","Sumisip","6.4167","121.9833","Philippines","PH","PHL","Basilan","","47345","1608552751"
"East Lansing","East Lansing","42.7480","-84.4836","United States","US","USA","Michigan","","47427","1840003101"
"Sanski Most","Sanski Most","44.7667","16.6667","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","47359","1070675252"
"Chaparral","Chaparral","3.7500","-75.5833","Colombia","CO","COL","Tolima","minor","47397","1170193964"
"Liulin","Liulin","36.5570","109.4731","China","CN","CHN","Shaanxi","","46991","1156532830"
"Jobabo","Jobabo","20.9078","-77.2831","Cuba","CU","CUB","Las Tunas","minor","47350","1192342239"
"Villa Constitución","Villa Constitucion","-33.2333","-60.3333","Argentina","AR","ARG","Santa Fe","minor","47374","1032793574"
"Gosen","Gosen","37.7444","139.1826","Japan","JP","JPN","Niigata","","47262","1392762928"
"Paracambi","Paracambi","-22.6108","-43.7089","Brazil","BR","BRA","Rio de Janeiro","","47124","1076624588"
"Perinton","Perinton","43.0781","-77.4283","United States","US","USA","New York","","47378","1840087790"
"General Martín Miguel de Güemes","General Martin Miguel de Guemes","-24.6667","-65.0500","Argentina","AR","ARG","Salta","minor","47348","1032716507"
"Mentor","Mentor","41.6893","-81.3362","United States","US","USA","Ohio","","47369","1840000539"
"Tangdukou","Tangdukou","26.9949","111.2708","China","CN","CHN","Hunan","minor","45617","1156473609"
"Bothell","Bothell","47.7735","-122.2044","United States","US","USA","Washington","","47355","1840018419"
"Daxiang","Daxiang","22.3775","112.8008","China","CN","CHN","Guangdong","","46674","1156895295"
"Yehe","Yehe","37.9416","114.5928","China","CN","CHN","Hebei","","46333","1156411968"
"Ouled Moussa","Ouled Moussa","36.6831","3.3681","Algeria","DZ","DZA","Boumerdes","","45770","1012623458"
"Talghar","Talghar","43.3000","77.2333","Kazakhstan","KZ","KAZ","","","47301","1398444969"
"Kingisepp","Kingisepp","59.3667","28.6167","Russia","RU","RUS","Leningradskaya Oblast’","minor","47313","1643712718"
"Yabrūd","Yabrud","33.9667","36.6667","Syria","SY","SYR","Rīf Dimashq","minor","47136","1760885103"
"Aquidauana","Aquidauana","-20.4708","-55.7869","Brazil","BR","BRA","Mato Grosso do Sul","","47323","1076120417"
"Videira","Videira","-27.0078","-51.1519","Brazil","BR","BRA","Santa Catarina","","47188","1076188514"
"Tomioka","Tomioka","36.2599","138.8899","Japan","JP","JPN","Gunma","","46928","1392639316"
"Albuera","Albuera","10.9186","124.6923","Philippines","PH","PHL","Leyte","","47151","1608775659"
"Fellbach","Fellbach","48.8086","9.2758","Germany","DE","DEU","Baden-Württemberg","","45671","1276083210"
"Şowme‘eh Sarā","Sowme\`eh Sara","37.3117","49.3219","Iran","IR","IRN","Gīlān","minor","47083","1364571093"
"Bairāgnia","Bairagnia","26.7406","85.2733","India","IN","IND","Bihār","","45983","1356258895"
"Ayapel","Ayapel","8.3167","-75.1500","Colombia","CO","COL","Córdoba","minor","47247","1170146577"
"Ayungon","Ayungon","9.8584","123.1468","Philippines","PH","PHL","Negros Oriental","","47102","1608744490"
"Haeryong","Haeryong","34.9131","127.5394","South Korea","KR","KOR","Jeonnam","minor","46994","1410002389"
"Kampli","Kampli","15.4063","76.6001","India","IN","IND","Karnātaka","","46701","1356155899"
"Saravena","Saravena","6.9556","-71.8722","Colombia","CO","COL","Arauca","minor","47203","1170587428"
"As Sa‘dīyah","As Sa\`diyah","34.1906","45.1208","Iraq","IQ","IRQ","Diyālá","","47213","1368439343"
"San Luis Obispo","San Luis Obispo","35.2669","-120.6690","United States","US","USA","California","","47257","1840021734"
"Kasumbalesa","Kasumbalesa","-12.2564","27.8028","Congo (Kinshasa)","CD","COD","Haut-Katanga","","47213","1180637799"
"Waspán","Waspan","14.7408","-83.9728","Nicaragua","NI","NIC","Costa Caribe Norte","minor","47231","1558840115"
"Burleson","Burleson","32.5170","-97.3344","United States","US","USA","Texas","","47230","1840019467"
"Selu","Selu","19.4551","76.4372","India","IN","IND","Mahārāshtra","","46915","1356131508"
"Douz","Douz","33.4500","9.0167","Tunisia","TN","TUN","Kébili","","47182","1788276238"
"Zarinsk","Zarinsk","53.7089","84.9672","Russia","RU","RUS","Altayskiy Kray","","46597","1643009932"
"East Providence","East Providence","41.8065","-71.3565","United States","US","USA","Rhode Island","","47171","1840003288"
"Huatan","Huatan","24.0316","120.5579","Taiwan","TW","TWN","Changhua","","45907","1158596327"
"Marhanets","Marhanets","47.6480","34.6167","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","47141","1804303283"
"Padra","Padra","22.2300","73.0800","India","IN","IND","Gujarāt","","46660","1356528617"
"Middletown","Middletown","41.5476","-72.6549","United States","US","USA","Connecticut","","47164","1840004844"
"Schwerte","Schwerte","51.4458","7.5653","Germany","DE","DEU","North Rhine-Westphalia","","46340","1276720376"
"Urrao","Urrao","6.3156","-76.1342","Colombia","CO","COL","Antioquia","minor","47128","1170571210"
"Tres Arroyos","Tres Arroyos","-38.3667","-60.2667","Argentina","AR","ARG","Buenos Aires","minor","47136","1032291724"
"Dülmen","Dulmen","51.8308","7.2783","Germany","DE","DEU","North Rhine-Westphalia","","46877","1276703649"
"Prievidza","Prievidza","48.7711","18.6217","Slovakia","SK","SVK","Nitra","minor","46059","1703497240"
"Egg Harbor","Egg Harbor","39.3787","-74.6102","United States","US","USA","New Jersey","","47124","1840081582"
"Blois","Blois","47.5939","1.3281","France","FR","FRA","Centre-Val de Loire","minor","45898","1250729037"
"Nysa","Nysa","50.4714","17.3339","Poland","PL","POL","Opolskie","minor","46841","1616093801"
"Qiutouzhen","Qiutouzhen","37.9841","114.6909","China","CN","CHN","Hebei","","46291","1156075373"
"Calimaya","Calimaya","19.1608","-99.6172","Mexico","MX","MEX","México","minor","47033","1484137559"
"Hà Tiên","Ha Tien","10.3833","104.4833","Vietnam","VN","VNM","Kiến Giang","minor","47039","1704031961"
"Saranambana","Saranambana","-17.2500","49.1833","Madagascar","MG","MDG","Toamasina","","47000","1450668500"
"Neunkirchen","Neunkirchen","49.3500","7.1667","Germany","DE","DEU","Saarland","minor","46469","1276274217"
"Banisilan","Banisilan","7.5000","124.7000","Philippines","PH","PHL","Cotabato","","46995","1608096617"
"Norala","Norala","6.5500","124.6667","Philippines","PH","PHL","South Cotabato","","46682","1608152835"
"Noboribetsu","Noboribetsu","42.4128","141.1066","Japan","JP","JPN","Hokkaidō","","46838","1392722896"
"Al Quşayr","Al Qusayr","34.5119","36.5764","Syria","SY","SYR","Ḩimş","minor","46772","1760157542"
"Dracena","Dracena","-21.4825","-51.5328","Brazil","BR","BRA","São Paulo","","47043","1076313481"
"Guajará-Mirim","Guajara-Mirim","-10.7828","-65.3394","Brazil","BR","BRA","Rondônia","","47048","1076692558"
"Frosinone","Frosinone","41.6333","13.3500","Italy","IT","ITA","Lazio","minor","46063","1380528356"
"Kabasalan","Kabasalan","7.7968","122.7627","Philippines","PH","PHL","Zamboanga Sibugay","minor","46884","1608527751"
"Fujiyoshida","Fujiyoshida","35.4875","138.8077","Japan","JP","JPN","Yamanashi","","46659","1392003331"
"Puñal","Punal","19.4000","-70.6167","Dominican Republic","DO","DOM","Cibao Norte","","46516","1214351229"
"Garhwa","Garhwa","24.1100","83.8100","India","IN","IND","Jhārkhand","","46059","1356464330"
"Wangtan","Wangtan","39.2847","118.9800","China","CN","CHN","Hebei","","46645","1156210973"
"Sanuki","Sanuki","34.3200","134.1794","Japan","JP","JPN","Kagawa","","46723","1392003130"
"Tuy","Tuy","14.0167","120.7333","Philippines","PH","PHL","Batangas","","46519","1608702969"
"Roldanillo","Roldanillo","4.4167","-76.1500","Colombia","CO","COL","Valle del Cauca","minor","46788","1170267836"
"Filderstadt","Filderstadt","48.6803","9.2183","Germany","DE","DEU","Baden-Württemberg","","45814","1276952389"
"Fastiv","Fastiv","50.0747","29.9181","Ukraine","UA","UKR","Kyivska Oblast","minor","45907","1804739399"
"Nanzhuangzhen","Nanzhuangzhen","23.7210","102.8231","China","CN","CHN","Yunnan","","46696","1156267546"
"Rondon do Pará","Rondon do Para","-4.7758","-48.0669","Brazil","BR","BRA","Tocantins","","46964","1076892918"
"Quva","Quva","40.5247","72.0700","Uzbekistan","UZ","UZB","Farg‘ona","minor","46400","1860496002"
"Yashan","Yashan","22.4776","112.7289","China","CN","CHN","Guangdong","","46700","1156105386"
"Bebandem","Bebandem","-8.4046","115.5395","Indonesia","ID","IDN","Bali","minor","46390","1360542849"
"Valencia","Valencia","8.2592","-76.1469","Colombia","CO","COL","Córdoba","minor","46897","1170188761"
"Agrínio","Agrinio","38.6167","21.4000","Greece","GR","GRC","Dytikí Elláda","minor","46899","1300351677"
"Cornélio Procópio","Cornelio Procopio","-23.1808","-50.6469","Brazil","BR","BRA","Paraná","","46928","1076363529"
"Obra","Obra","24.4200","82.9800","India","IN","IND","Uttar Pradesh","","46574","1356100800"
"Veldhoven","Veldhoven","51.4200","5.4050","Netherlands","NL","NLD","Noord-Brabant","minor","45500","1528462700"
"Sumpango","Sumpango","14.6464","-90.7339","Guatemala","GT","GTM","Sacatepéquez","minor","46082","1320583719"
"Dongzhuosu","Dongzhuosu","38.0658","115.1232","China","CN","CHN","Hebei","","45748","1156100367"
"Melle","Melle","52.2044","8.3389","Germany","DE","DEU","Lower Saxony","","46732","1276489872"
"San Rafael","San Rafael","10.0417","-84.0750","Costa Rica","CR","CRI","Heredia","","45965","1188978329"
"Brea","Brea","33.9254","-117.8655","United States","US","USA","California","","46912","1840019323"
"Ciudad Serdán","Ciudad Serdan","18.9833","-97.4500","Mexico","MX","MEX","Puebla","minor","46778","1484943496"
"Lohja","Lohja","60.2500","24.0667","Finland","FI","FIN","Uusimaa","minor","46785","1246687472"
"Borča","Borca","44.8700","20.4500","Serbia","RS","SRB","Beograd","","46086","1688819875"
"Salina","Salina","38.8136","-97.6143","United States","US","USA","Kansas","","46896","1840001647"
"Potomac","Potomac","39.0141","-77.1943","United States","US","USA","Maryland","","46891","1840005837"
"Lábrea","Labrea","-7.2589","-64.7978","Brazil","BR","BRA","Amazonas","","46882","1076619739"
"Marechal Cândido Rondon","Marechal Candido Rondon","-24.5558","-54.0569","Brazil","BR","BRA","Paraná","","46819","1076891164"
"Hyvinkää","Hyvinkaa","60.6333","24.8500","Finland","FI","FIN","Uusimaa","minor","46739","1246789945"
"Wodzisław Śląski","Wodzislaw Slaski","50.0000","18.4500","Poland","PL","POL","Śląskie","minor","45949","1616578364"
"Lukavac","Lukavac","44.5500","18.5167","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","46731","1070273413"
"Taebaek","Taebaek","37.1667","128.9833","South Korea","KR","KOR","Gangwon","","46715","1410565787"
"Hioki","Hioki","31.6117","130.3731","Japan","JP","JPN","Kagoshima","","46671","1392003440"
"Oranienburg","Oranienburg","52.7544","13.2369","Germany","DE","DEU","Brandenburg","minor","46555","1276677137"
"Shimeo","Shimeo","33.5914","130.4798","Japan","JP","JPN","Fukuoka","","46207","1392938801"
"Fangguan","Fangguan","39.3237","115.9856","China","CN","CHN","Hebei","","46090","1156898704"
"Nilambūr","Nilambur","11.2769","76.2258","India","IN","IND","Kerala","","46366","1356722613"
"La Caleta","La Caleta","18.4639","-69.6806","Dominican Republic","DO","DOM","Ozama","","46698","1214671781"
"Cordon","Cordon","16.6667","121.4500","Philippines","PH","PHL","Isabela","","46477","1608294077"
"Coelho Neto","Coelho Neto","-4.2569","-43.0128","Brazil","BR","BRA","Maranhão","","46750","1076316612"
"Lubny","Lubny","50.0186","32.9869","Ukraine","UA","UKR","Poltavska Oblast","minor","45786","1804740843"
"Tamagawa","Tamagawa","33.6389","130.8061","Japan","JP","JPN","Fukuoka","","45946","1392705807"
"San Francisco de los Romo","San Francisco de los Romo","22.0833","-102.2667","Mexico","MX","MEX","Aguascalientes","minor","46454","1484352122"
"Evesham","Evesham","39.8605","-74.8947","United States","US","USA","New Jersey","","46775","1840081613"
"Berëzovskiy","Berezovskiy","55.6167","86.2500","Russia","RU","RUS","Kemerovskaya Oblast’","","46215","1643773270"
"Montevista","Montevista","7.7053","125.9903","Philippines","PH","PHL","Davao de Oro","","46558","1608301294"
"Vercelli","Vercelli","45.3256","8.4231","Italy","IT","ITA","Piedmont","minor","46181","1380702536"
"Zwijndrecht","Zwijndrecht","51.8167","4.6500","Netherlands","NL","NLD","Zuid-Holland","minor","44775","1528524104"
"Jablonec nad Nisou","Jablonec nad Nisou","50.7244","15.1681","Czechia","CZ","CZE","Liberecký Kraj","","45317","1203127047"
"Aland","Aland","17.5642","76.5685","India","IN","IND","Karnātaka","","46523","1356608267"
"Thoubāl","Thoubal","24.6300","94.0200","India","IN","IND","Manipur","","45947","1356830949"
"Stepnogorsk","Stepnogorsk","52.3497","71.8900","Kazakhstan","KZ","KAZ","","minor","46712","1398766937"
"Hof","Hof","50.3167","11.9167","Germany","DE","DEU","Bavaria","minor","45930","1276561363"
"Cabreúva","Cabreuva","-23.3075","-47.1331","Brazil","BR","BRA","São Paulo","","46528","1076162837"
"Anandpur","Anandpur","21.2141","86.1249","India","IN","IND","Odisha","","46263","1356018392"
"Izunokuni","Izunokuni","35.0333","138.9333","Japan","JP","JPN","Shizuoka","","46212","1392003298"
"Farmington","Farmington","36.7555","-108.1823","United States","US","USA","New Mexico","","46696","1840020349"
"Suluova","Suluova","40.8333","35.6500","Turkey","TR","TUR","Amasya","minor","46608","1792191874"
"Mānvi","Manvi","15.9833","77.0500","India","IN","IND","Karnātaka","","46465","1356314428"
"Jeomchon","Jeomchon","36.6028","127.3042","South Korea","KR","KOR","Chungbuk","","46452","1410262248"
"Faratsiho","Faratsiho","-19.4000","46.9500","Madagascar","MG","MDG","Antananarivo","","46569","1450194641"
"Bocaiúva","Bocaiuva","-17.1078","-43.8150","Brazil","BR","BRA","Minas Gerais","","46654","1076639320"
"Yalvaç","Yalvac","38.2956","31.1800","Turkey","TR","TUR","Isparta","minor","46646","1792064802"
"Muara Teweh","Muara Teweh","-0.9535","114.8981","Indonesia","ID","IDN","Kalimantan Tengah","minor","46652","1360980558"
"Nakai","Nakai","33.5833","133.6333","Japan","JP","JPN","Kōchi","","46567","1392020842"
"Beringen","Beringen","51.0333","5.2167","Belgium","BE","BEL","Flanders","","46065","1056300146"
"Puerto Villarroel","Puerto Villarroel","-16.8667","-64.7831","Bolivia","BO","BOL","Cochabamba","","46627","1068957347"
"Shimenzhai","Shimenzhai","40.0892","119.6019","China","CN","CHN","Hebei","","46356","1156914024"
"Jocotepec","Jocotepec","20.2861","-103.4300","Mexico","MX","MEX","Jalisco","minor","46521","1484245726"
"Sicklerville","Sicklerville","39.7452","-74.9934","United States","US","USA","New Jersey","","46626","1840033409"
"South Brunswick","South Brunswick","40.3840","-74.5256","United States","US","USA","New Jersey","","46623","1840081692"
"Puerto Tejada","Puerto Tejada","3.2500","-76.4167","Colombia","CO","COL","Cauca","minor","46166","1170475909"
"Eusébio","Eusebio","-3.8900","-38.4508","Brazil","BR","BRA","Ceará","","46033","1076187536"
"Todupulai","Todupulai","9.8969","76.7113","India","IN","IND","Kerala","","46226","1356886530"
"Narvacan","Narvacan","17.4192","120.4756","Philippines","PH","PHL","Ilocos Sur","","46234","1608024817"
"Ciudad Tecún Umán","Ciudad Tecun Uman","14.6833","-92.1333","Guatemala","GT","GTM","San Marcos","minor","46383","1320502351"
"Bhālki","Bhalki","18.0435","77.2060","India","IN","IND","Karnātaka","","46322","1356429834"
"Lower Tungawan","Lower Tungawan","7.6047","122.4228","Philippines","PH","PHL","Zamboanga Sibugay","","46497","1608107982"
"Cornwall","Cornwall","45.0275","-74.7400","Canada","CA","CAN","Ontario","","46589","1124938303"
"Cristalina","Cristalina","-16.7689","-47.6139","Brazil","BR","BRA","Goiás","","46580","1076014539"
"Dhupgāri","Dhupgari","26.6000","89.0200","India","IN","IND","West Bengal","","44719","1356217672"
"Itapema","Itapema","-27.0900","-48.6108","Brazil","BR","BRA","Santa Catarina","","45797","1076653025"
"Ocoee","Ocoee","28.5796","-81.5332","United States","US","USA","Florida","","46562","1840015098"
"Hilo","Hilo","19.6883","-155.0863","United States","US","USA","Hawaii","","46559","1840023193"
"Nawāshahr","Nawashahr","31.1167","76.1333","India","IN","IND","Punjab","","46023","1356582611"
"Rodgau","Rodgau","50.0167","8.8833","Germany","DE","DEU","Hesse","","45847","1276173536"
"Gryazi","Gryazi","52.5000","39.9667","Russia","RU","RUS","Lipetskaya Oblast’","","46492","1643963705"
"Ḩadīthah","Hadithah","34.1397","42.3781","Iraq","IQ","IRQ","Al Anbār","minor","46500","1368900463"
"Oro Valley","Oro Valley","32.4208","-110.9767","United States","US","USA","Arizona","","46515","1840023058"
"Sena Madureira","Sena Madureira","-9.0658","-68.6569","Brazil","BR","BRA","Acre","","46511","1076899075"
"La Independencia","La Independencia","16.2000","-91.7833","Mexico","MX","MEX","Chiapas","minor","46409","1484210828"
"Dendermonde","Dendermonde","51.0333","4.1000","Belgium","BE","BEL","Flanders","minor","45673","1056277590"
"Conceição do Araguaia","Conceicao do Araguaia","-8.2578","-49.2650","Brazil","BR","BRA","Pará","","46485","1076224782"
"Río Tercero","Rio Tercero","-32.1833","-64.1000","Argentina","AR","ARG","Córdoba","","46421","1032103221"
"Kurayoshi","Kurayoshi","35.4333","133.8167","Japan","JP","JPN","Tottori","","46269","1392359185"
"Dharmaragar","Dharmaragar","24.3785","92.1783","India","IN","IND","Tripura","","45887","1356861084"
"Montecristi","Montecristi","-1.0500","-80.6667","Ecuador","EC","ECU","Manabí","","46312","1218883853"
"Dingle","Dingle","11.0500","122.6667","Philippines","PH","PHL","Iloilo","","45965","1608576091"
"Minster","Minster","51.4210","0.8090","United Kingdom","GB","GBR","Kent","","45000","1826548197"
"Kasaoka","Kasaoka","34.5039","133.5100","Japan","JP","JPN","Okayama","","46080","1392085500"
"Torzhok","Torzhok","57.0333","34.9667","Russia","RU","RUS","Tverskaya Oblast’","","45641","1643710105"
"Terracina","Terracina","41.2833","13.2500","Italy","IT","ITA","Lazio","","46323","1380975226"
"Fort Pierce","Fort Pierce","27.4255","-80.3431","United States","US","USA","Florida","","46409","1840014178"
"Tallbīsah","Tallbisah","34.8333","36.7333","Syria","SY","SYR","Ḩimş","minor","45853","1760319800"
"Zhongzhai","Zhongzhai","25.7783","107.8665","China","CN","CHN","Guizhou","","46267","1156055541"
"Jangamguda","Jangamguda","17.5092","78.5542","India","IN","IND","Andhra Pradesh","","44562","1356005015"
"Wake Forest","Wake Forest","35.9632","-78.5140","United States","US","USA","North Carolina","","46387","1840016199"
"Chichigalpa","Chichigalpa","12.5722","-87.0267","Nicaragua","NI","NIC","Chinandega","minor","46185","1558921284"
"Camp Perrin","Camp Perrin","18.3167","-73.8667","Haiti","HT","HTI","Sud","","46000","1332737224"
"Kilmarnock","Kilmarnock","55.6111","-4.4957","United Kingdom","GB","GBR","East Ayrshire","","46350","1826595229"
"Gotha","Gotha","50.9489","10.7183","Germany","DE","DEU","Thuringia","minor","45733","1276679639"
"Anshan","Anshan","39.7144","118.9964","China","CN","CHN","Hebei","","45804","1156524522"
"Capannori","Capannori","43.8500","10.5667","Italy","IT","ITA","Tuscany","","46072","1380407023"
"Jammalamadugu","Jammalamadugu","14.8500","78.3800","India","IN","IND","Andhra Pradesh","","46069","1356030028"
"Cavaillon","Cavaillon","18.3000","-73.6500","Haiti","HT","HTI","Sud","","46037","1332637613"
"Lodi","Lodi","45.3167","9.5000","Italy","IT","ITA","Lombardy","minor","45252","1380406529"
"Fuxing","Fuxing","24.0341","120.4235","Taiwan","TW","TWN","Changhua","","45436","1158324066"
"Banī ‘Ubayd","Bani \`Ubayd","31.0234","31.6479","Egypt","EG","EGY","Ad Daqahlīyah","","45157","1818589720"
"Pagalungan","Pagalungan","7.0592","124.6987","Philippines","PH","PHL","Maguindanao","","46277","1608925189"
"San Lorenzo","San Lorenzo","-32.7500","-60.7333","Argentina","AR","ARG","Santa Fe","","46239","1032944740"
"Henrietta","Henrietta","43.0555","-77.6413","United States","US","USA","New York","","46327","1840058179"
"Joal-Fadiout","Joal-Fadiout","14.1667","-16.8333","Senegal","SN","SEN","Thiès","","45903","1686150364"
"Beavercreek","Beavercreek","39.7310","-84.0624","United States","US","USA","Ohio","","46320","1840007312"
"Takashima","Takashima","35.3500","136.0333","Japan","JP","JPN","Shiga","","46246","1392048667"
"Labutta","Labutta","16.1467","94.7611","Myanmar","MM","MMR","Ayeyarwady","","46170","1104103403"
"Santiago Atitlán","Santiago Atitlan","14.6386","-91.2299","Guatemala","GT","GTM","Sololá","minor","45982","1320947677"
"Marino","Marino","41.7667","12.6667","Italy","IT","ITA","Lazio","","44472","1380000027"
"São Gabriel da Cachoeira","Sao Gabriel da Cachoeira","-0.1300","-67.0889","Brazil","BR","BRA","Amazonas","","46303","1076863074"
"Lupao","Lupao","15.8794","120.8994","Philippines","PH","PHL","Nueva Ecija","","45917","1608945408"
"Bünde","Bunde","52.2000","8.6000","Germany","DE","DEU","North Rhine-Westphalia","","45521","1276719683"
"Acaxochitlán","Acaxochitlan","20.1667","-98.2000","Mexico","MX","MEX","Hidalgo","minor","46065","1484005465"
"Laguna","Laguna","-28.4828","-48.7808","Brazil","BR","BRA","Santa Catarina","","46122","1076025747"
"Batroûn","Batroun","34.2500","35.6500","Lebanon","LB","LBN","Liban-Nord","minor","45000","1422217431"
"Kaufbeuren","Kaufbeuren","47.8800","10.6225","Germany","DE","DEU","Bavaria","minor","45118","1276496301"
"Puertollano","Puertollano","38.6833","-4.1167","Spain","ES","ESP","Castille-La Mancha","","46036","1724051219"
"Mairinque","Mairinque","-23.5464","-47.1836","Brazil","BR","BRA","São Paulo","","46015","1076990000"
"Maragogipe","Maragogipe","-12.7778","-38.9189","Brazil","BR","BRA","Bahia","","46106","1076474180"
"Tingo María","Tingo Maria","-9.2953","-75.9975","Peru","PE","PER","Huánuco","","46191","1604736000"
"Megion","Megion","61.0500","76.1000","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","46188","1643111689"
"Kafr al Baţţīkh","Kafr al Battikh","31.3234","31.7612","Egypt","EG","EGY","Dumyāţ","","44716","1818792842"
"Strongsville","Strongsville","41.3128","-81.8313","United States","US","USA","Ohio","","46187","1840003415"
"Xingji","Xingji","38.4682","116.8918","China","CN","CHN","Hebei","","44849","1156703462"
"Amboasary","Amboasary","-25.0500","46.3833","Madagascar","MG","MDG","Toliara","","45989","1450064335"
"Wittenberg","Wittenberg","51.8671","12.6484","Germany","DE","DEU","Saxony-Anhalt","minor","46008","1276533652"
"Campechuela","Campechuela","20.2333","-77.2792","Cuba","CU","CUB","Granma","minor","46092","1192313865"
"Koupéla","Koupela","12.1794","-0.3517","Burkina Faso","BF","BFA","Centre-Est","minor","45900","1854841246"
"Bājil","Bajil","15.0583","43.2850","Yemen","YE","YEM","Al Ḩudaydah","minor","46005","1887795650"
"Saint-Brieuc","Saint-Brieuc","48.5136","-2.7653","France","FR","FRA","Bretagne","minor","44170","1250574796"
"Manono","Manono","-7.2947","27.4545","Congo (Kinshasa)","CD","COD","Tanganyika","","46111","1180968800"
"Rockwall","Rockwall","32.9169","-96.4374","United States","US","USA","Texas","","46150","1840020717"
"Attleboro","Attleboro","41.9311","-71.2950","United States","US","USA","Massachusetts","","46146","1840000496"
"Kikuchi","Kikuchi","32.9833","130.8167","Japan","JP","JPN","Kumamoto","","45971","1392999989"
"Bruchsal","Bruchsal","49.1333","8.6000","Germany","DE","DEU","Baden-Württemberg","","45644","1276034362"
"Hokota","Hokota","36.1587","140.5164","Japan","JP","JPN","Ibaraki","","45910","1392374973"
"Tayug","Tayug","16.0267","120.7478","Philippines","PH","PHL","Pangasinan","","45241","1608042299"
"El Valle del Espíritu Santo","El Valle del Espiritu Santo","10.9833","-63.8833","Venezuela","VE","VEN","Nueva Esparta","minor","45606","1862517439"
"Farrukhnagar","Farrukhnagar","17.0778","78.2011","India","IN","IND","Andhra Pradesh","","45675","1356373389"
"Krosno","Krosno","49.6833","21.7500","Poland","PL","POL","Podkarpackie","minor","45057","1616223462"
"Shima","Shima","34.3333","136.8333","Japan","JP","JPN","Mie","","45834","1392981052"
"Châlons-en-Champagne","Chalons-en-Champagne","48.9575","4.3650","France","FR","FRA","Grand Est","minor","44379","1250720943"
"Albstadt","Albstadt","48.2119","9.0239","Germany","DE","DEU","Baden-Württemberg","","45737","1276969750"
"Chepén","Chepen","-7.2271","-79.4298","Peru","PE","PER","La Libertad","","45897","1604657195"
"Bridgewater","Bridgewater","40.5934","-74.6076","United States","US","USA","New Jersey","","46072","1840081701"
"Nawai","Nawai","26.3824","75.9240","India","IN","IND","Rājasthān","","45787","1356336915"
"Nova Venécia","Nova Venecia","-18.7108","-40.4008","Brazil","BR","BRA","Espírito Santo","","46031","1076770793"
"Lagoa da Prata","Lagoa da Prata","-20.0228","-45.5439","Brazil","BR","BRA","Minas Gerais","","45984","1076652644"
"Livramento de Nossa Senhora","Livramento de Nossa Senhora","-13.6428","-41.8408","Brazil","BR","BRA","Bahia","","46035","1076781145"
"Weinheim","Weinheim","49.5500","8.6667","Germany","DE","DEU","Baden-Württemberg","","45284","1276001742"
"Piro","Piro","25.3300","84.4200","India","IN","IND","Bihār","","45000","1356786496"
"Salon-de-Provence","Salon-de-Provence","43.6406","5.0972","France","FR","FRA","Provence-Alpes-Côte d’Azur","","45400","1250380072"
"Ourém","Ourem","39.6500","-8.5667","Portugal","PT","PRT","Santarém","minor","45932","1620510353"
"Freeport City","Freeport City","26.5286","-78.6967","The Bahamas","BS","BHS","City of Freeport","","45945","1044291614"
"Salto de Pirapora","Salto de Pirapora","-23.6489","-47.5728","Brazil","BR","BRA","São Paulo","","45860","1076664356"
"Mindat","Mindat","21.3667","93.9833","Myanmar","MM","MMR","Chin State","","46008","1104913521"
"Phan Rí Cửa","Phan Ri Cua","11.1739","108.5661","Vietnam","VN","VNM","Bình Thuận","","45805","1704182533"
"Pagsanjan","Pagsanjan","14.2667","121.4500","Philippines","PH","PHL","Laguna","","44327","1608065525"
"Erkrath","Erkrath","51.2239","6.9147","Germany","DE","DEU","North Rhine-Westphalia","","44384","1276917229"
"Maluso","Maluso","6.5500","121.8833","Philippines","PH","PHL","Basilan","","45730","1608259525"
"Karuvambram","Karuvambram","11.0271","76.1062","India","IN","IND","Kerala","","44711","1356447666"
"Paraguaçu Paulista","Paraguacu Paulista","-22.4197","-50.5797","Brazil","BR","BRA","São Paulo","","45945","1076783045"
"Caiguantun","Caiguantun","26.3363","105.9841","China","CN","CHN","Guizhou","","45580","1156519374"
"Lumba-a-Bayabao","Lumba-a-Bayabao","7.8833","124.3833","Philippines","PH","PHL","Lanao del Sur","","45909","1608241306"
"Winter Garden","Winter Garden","28.5421","-81.5967","United States","US","USA","Florida","","45978","1840015965"
"Mladá Boleslav","Mlada Boleslav","50.4125","14.9044","Czechia","CZ","CZE","Středočeský Kraj","","44506","1203616853"
"Pearl City","Pearl City","21.4031","-157.9566","United States","US","USA","Hawaii","","45941","1840023251"
"Haymana","Haymana","39.4311","32.4956","Turkey","TR","TUR","Ankara","minor","45931","1792299517"
"Acatlán de Pérez Figueroa","Acatlan de Perez Figueroa","18.5333","-96.6167","Mexico","MX","MEX","Oaxaca","","45883","1484695178"
"Middletown","Middletown","40.1790","-74.9059","United States","US","USA","Pennsylvania","","45903","1840152880"
"Changchong","Changchong","26.3404","107.1866","China","CN","CHN","Guizhou","","45702","1156385738"
"Mata de São João","Mata de Sao Joao","-12.5300","-38.2989","Brazil","BR","BRA","Bahia","","45813","1076397945"
"Aribinda","Aribinda","14.2292","-0.8633","Burkina Faso","BF","BFA","Sahel","minor","45818","1854268234"
"Chajarí","Chajari","-30.7667","-57.9833","Argentina","AR","ARG","Entre Ríos","","45848","1032147348"
"Tantangan","Tantangan","6.6167","124.7500","Philippines","PH","PHL","South Cotabato","","45744","1608138127"
"Xisa","Xisa","23.4372","104.6714","China","CN","CHN","Yunnan","minor","45518","1156440376"
"Nocera Inferiore","Nocera Inferiore","40.7500","14.6333","Italy","IT","ITA","Campania","","45784","1380757404"
"Kumta","Kumta","14.4264","74.4122","India","IN","IND","Karnātaka","","45538","1356514231"
"Alcira","Alcira","39.1500","-0.4350","Spain","ES","ESP","Valencia","","45451","1724303009"
"Salvador","Salvador","20.2094","-75.2231","Cuba","CU","CUB","Guantánamo","","45773","1192289644"
"Voi","Voi","-3.3906","38.5769","Kenya","KE","KEN","Taita/Taveta","","45483","1404504656"
"Stouffville","Stouffville","43.9667","-79.2500","Canada","CA","CAN","Ontario","","45837","1124207594"
"Kariya","Kariya","34.7517","134.3931","Japan","JP","JPN","Hyōgo","","45473","1392896537"
"Monte Carmelo","Monte Carmelo","-18.7250","-47.4989","Brazil","BR","BRA","Minas Gerais","","45772","1076821086"
"Warud","Warud","21.4167","78.4000","India","IN","IND","Mahārāshtra","","45482","1356146392"
"Cascina","Cascina","43.6833","10.5500","Italy","IT","ITA","Tuscany","","45212","1380319833"
"Haltom City","Haltom City","32.8176","-97.2707","United States","US","USA","Texas","","45777","1840020698"
"Hokuto","Hokuto","41.8241","140.6530","Japan","JP","JPN","Hokkaidō","","45661","1392000277"
"Rājgarh","Rajgarh","24.0300","76.8800","India","IN","IND","Madhya Pradesh","","45726","1356819197"
"Santa María Huatulco","Santa Maria Huatulco","15.8500","-96.3333","Mexico","MX","MEX","Oaxaca","minor","45680","1484007409"
"Olhão","Olhao","37.0260","-7.8410","Portugal","PT","PRT","Faro","minor","45396","1620090779"
"Altamonte Springs","Altamonte Springs","28.6615","-81.3953","United States","US","USA","Florida","","45732","1840014072"
"Beaufort West","Beaufort West","-32.3500","22.5833","South Africa","ZA","ZAF","Western Cape","","44737","1710940488"
"Remedios","Remedios","22.4922","-79.5458","Cuba","CU","CUB","Villa Clara","minor","45621","1192770696"
"Jones","Jones","16.5583","121.7000","Philippines","PH","PHL","Isabela","","45628","1608556354"
"Westfield","Westfield","40.0341","-86.1529","United States","US","USA","Indiana","","45695","1840010500"
"Kitakata","Kitakata","37.6511","139.8748","Japan","JP","JPN","Fukushima","","45590","1392003224"
"Rāmeswaram","Rameswaram","9.2880","79.3130","India","IN","IND","Tamil Nādu","","44856","1356465756"
"Sète","Sete","43.4053","3.6975","France","FR","FRA","Occitanie","","43858","1250599370"
"Erith","Erith","51.4800","0.1778","United Kingdom","GB","GBR","Bexley","","45345","1826991631"
"Drachten","Drachten","53.1000","6.1000","Netherlands","NL","NLD","Fryslân","minor","45186","1528367114"
"Elmhurst","Elmhurst","41.8973","-87.9432","United States","US","USA","Illinois","","45661","1840008142"
"Aliwal North","Aliwal North","-30.7000","26.7000","South Africa","ZA","ZAF","Eastern Cape","","44436","1710445896"
"Talagutong","Talagutong","6.2667","125.6667","Philippines","PH","PHL","Davao Occidental","","45540","1608694317"
"Cuautepec de Hinojosa","Cuautepec de Hinojosa","20.1500","-98.4333","Mexico","MX","MEX","Hidalgo","minor","45527","1484413126"
"Baiji","Baiji","26.0231","106.9267","China","CN","CHN","Guizhou","","45576","1156365747"
"Alcamo","Alcamo","37.9778","12.9639","Italy","IT","ITA","Sicilia","","45273","1380182511"
"Brühl","Bruhl","50.8333","6.9000","Germany","DE","DEU","North Rhine-Westphalia","","44397","1276307796"
"Radā‘","Rada\`","14.4151","44.8371","Yemen","YE","YEM","Al Bayḑā’","minor","45233","1887090855"
"Gaya","Gaya","11.8878","3.4467","Niger","NE","NER","Dosso","minor","45465","1562298335"
"Majurwa","Majurwa","26.1036","86.9193","India","IN","IND","Bihār","","44214","1356897478"
"Seohāra","Seohara","29.2200","78.5800","India","IN","IND","Uttar Pradesh","","43985","1356800742"
"Arantāngi","Arantangi","10.1686","78.9920","India","IN","IND","Tamil Nādu","","45056","1356529940"
"São Sebastião do Passé","Sao Sebastiao do Passe","-12.5128","-38.4950","Brazil","BR","BRA","Bahia","","45482","1076114287"
"San Isidro","San Isidro","9.3403","-83.7338","Costa Rica","CR","CRI","San José","","45327","1188493240"
"Bom Conselho","Bom Conselho","-9.1700","-36.6800","Brazil","BR","BRA","Pernambuco","","45503","1076382698"
"Heusden","Heusden","51.6998","5.1660","Netherlands","NL","NLD","Noord-Brabant","","45005","1528318846"
"Guacharachi","Guacharachi","27.1500","-107.3167","Mexico","MX","MEX","Chihuahua","","45544","1484013438"
"Ortega","Ortega","3.9167","-75.2500","Colombia","CO","COL","Tolima","minor","45524","1170797038"
"Busaar","Busaar","2.6722","41.3225","Somalia","SO","SOM","Gedo","","45533","1706248273"
"Itabirito","Itabirito","-20.2528","-43.8008","Brazil","BR","BRA","Minas Gerais","","45449","1076709831"
"Manacor","Manacor","39.5700","3.2089","Spain","ES","ESP","Balearic Islands","","45352","1724483399"
"Port Macquarie","Port Macquarie","-31.4333","152.9000","Australia","AU","AUS","New South Wales","","44830","1036051032"
"Chimteppa","Chimteppa","38.4667","68.7333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","45221","1762819593"
"Xiluo","Xiluo","23.7827","120.4551","Taiwan","TW","TWN","Yunlin","","44610","1158315546"
"Bountiful","Bountiful","40.8721","-111.8647","United States","US","USA","Utah","","45496","1840018737"
"San Pelayo","San Pelayo","8.9667","-75.8500","Colombia","CO","COL","Córdoba","minor","45396","1170248105"
"Nāyudupet","Nayudupet","13.9000","79.9000","India","IN","IND","Andhra Pradesh","","45055","1356576109"
"Pinamar","Pinamar","-37.1000","-56.8500","Argentina","AR","ARG","Buenos Aires","minor","45000","1032950633"
"Littleton","Littleton","39.5915","-105.0188","United States","US","USA","Colorado","","45465","1840020213"
"Otwock","Otwock","52.1167","21.2667","Poland","PL","POL","Mazowieckie","minor","44524","1616596983"
"Nongzhangjie","Nongzhangjie","24.6160","97.8818","China","CN","CHN","Yunnan","","45270","1156153887"
"Shazhou","Shazhou","40.1376","94.6638","China","CN","CHN","Gansu","minor","44870","1156020029"
"Bāfq","Bafq","31.6033","55.4003","Iran","IR","IRN","Yazd","minor","45453","1364201689"
"Carnot","Carnot","4.9400","15.8700","Central African Republic","CF","CAF","Mambéré-Kadéï","","45421","1140319605"
"Ciudad de Huajuapam de León","Ciudad de Huajuapam de Leon","17.8097","-97.7764","Mexico","MX","MEX","Oaxaca","minor","45321","1484375272"
"Huntsville","Huntsville","30.7009","-95.5567","United States","US","USA","Texas","","45446","1840019565"
"Dehgolān","Dehgolan","35.2781","47.4183","Iran","IR","IRN","Kordestān","minor","45386","1364387900"
"Birāk","Birak","27.5333","14.2834","Libya","LY","LBY","Wādī ash Shāţi’","","45421","1434095842"
"Georgina","Georgina","44.3000","-79.4333","Canada","CA","CAN","Ontario","","45418","1124000048"
"Dębica","Debica","50.0500","21.4167","Poland","PL","POL","Podkarpackie","minor","44047","1616197893"
"Sainthia","Sainthia","23.9451","87.6803","India","IN","IND","West Bengal","","44601","1356561407"
"Dunakeszi","Dunakeszi","47.6303","19.1412","Hungary","HU","HUN","Pest","minor","43990","1348178190"
"Siyāna","Siyana","28.6269","78.0607","India","IN","IND","Uttar Pradesh","","44415","1356843565"
"West Seneca","West Seneca","42.8374","-78.7509","United States","US","USA","New York","","45390","1840058604"
"Kamen’-na-Obi","Kamen'-na-Obi","53.7833","81.3333","Russia","RU","RUS","Altayskiy Kray","","44564","1643009870"
"Garango","Garango","11.8000","-0.5500","Burkina Faso","BF","BFA","Centre-Est","","45150","1854695901"
"Memmingen","Memmingen","47.9878","10.1811","Germany","DE","DEU","Bavaria","minor","44721","1276229376"
"San Jacinto","San Jacinto","16.0725","120.4411","Philippines","PH","PHL","Pangasinan","","44351","1608800600"
"Victoria","Victoria","14.2250","121.3250","Philippines","PH","PHL","Laguna","","43408","1608591152"
"Medemblik","Medemblik","52.6833","5.0333","Netherlands","NL","NLD","Noord-Holland","","45165","1528256385"
"Næstved","Naestved","55.2250","11.7583","Denmark","DK","DNK","Sjælland","minor","44331","1208208658"
"Aguilar","Aguilar","15.8899","120.2379","Philippines","PH","PHL","Pangasinan","","45100","1608688044"
"Charkhi Dādri","Charkhi Dadri","28.5900","76.2700","India","IN","IND","Haryāna","","44892","1356411988"
"Svitlovodsk","Svitlovodsk","49.0503","33.2419","Ukraine","UA","UKR","Kirovohradska Oblast","minor","45312","1804885566"
"Bingmei","Bingmei","25.7408","108.9023","China","CN","CHN","Guizhou","","45094","1156262992"
"Victoriaville","Victoriaville","46.0500","-71.9667","Canada","CA","CAN","Quebec","","45309","1124149787"
"Basud","Basud","14.0667","122.9667","Philippines","PH","PHL","Camarines Norte","","45133","1608662670"
"Parigi","Parigi","-7.6914","108.5372","Indonesia","ID","IDN","Jawa Barat","minor","44857","1360904302"
"Vallenar","Vallenar","-28.5708","-70.7581","Chile","CL","CHL","Atacama","minor","45298","1152000009"
"Ruzayevka","Ruzayevka","54.0667","44.9333","Russia","RU","RUS","Mordoviya","","45248","1643836574"
"Falkensee","Falkensee","52.5583","13.0917","Germany","DE","DEU","Brandenburg","","44280","1276126886"
"Gaoua","Gaoua","10.3167","-3.1667","Burkina Faso","BF","BFA","Sud-Ouest","","45189","1854142068"
"Gračanica","Gracanica","44.6892","18.3022","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","45220","1070355903"
"Biella","Biella","45.5667","8.0667","Italy","IT","ITA","Piedmont","minor","44324","1380214136"
"Mamanguape","Mamanguape","-6.8389","-35.1258","Brazil","BR","BRA","Paraíba","","45136","1076012570"
"Dowlaiswaram","Dowlaiswaram","16.9506","81.7819","India","IN","IND","Andhra Pradesh","","44637","1356803824"
"Morgan Hill","Morgan Hill","37.1325","-121.6419","United States","US","USA","California","","45267","1840020331"
"João Pinheiro","Joao Pinheiro","-17.7442","-46.1739","Brazil","BR","BRA","Minas Gerais","","45260","1076899445"
"Brejo Santo","Brejo Santo","-7.4928","-38.9850","Brazil","BR","BRA","Ceará","","45193","1076816163"
"Tank","Tank","32.1300","70.2300","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","44996","1586200762"
"Lørenskog","Lorenskog","59.8989","10.9642","Norway","NO","NOR","Viken","minor","44693","1578059629"
"Jedeïda","Jedeida","36.8333","9.9167","Tunisia","TN","TUN","Manouba","","45000","1788986183"
"Barnagar","Barnagar","23.0489","75.3780","India","IN","IND","Madhya Pradesh","","44996","1356118388"
"Brejo da Madre de Deus","Brejo da Madre de Deus","-8.1458","-36.3708","Brazil","BR","BRA","Pernambuco","","45180","1076440699"
"Numata","Numata","36.6460","139.0442","Japan","JP","JPN","Gunma","","45132","1392003068"
"Angul","Angul","20.8381","85.0956","India","IN","IND","Odisha","","44390","1356441869"
"Sundargarh","Sundargarh","22.1200","84.0300","India","IN","IND","Odisha","","45036","1356122746"
"San Pedro Perulapán","San Pedro Perulapan","13.7667","-89.0333","El Salvador","SV","SLV","Cuscatlán","","44730","1222471656"
"Seabra","Seabra","-12.4189","-41.7700","Brazil","BR","BRA","Bahia","","45202","1076253764"
"Qarabulaq","Qarabulaq","42.5167","69.8000","Kazakhstan","KZ","KAZ","","","45000","1398582036"
"Sugito","Sugito","36.0258","139.7367","Japan","JP","JPN","Saitama","","43755","1392687302"
"Nimule","Nimule","3.5961","32.0636","South Sudan","SS","SSD","Eastern Equatoria","","45000","1728476966"
"Nanjō","Nanjo","26.1631","127.7706","Japan","JP","JPN","Okinawa","","44318","1392003136"
"Campo Maior","Campo Maior","-4.8278","-42.1689","Brazil","BR","BRA","Piauí","","45177","1076002814"
"Cheramkod","Cheramkod","11.6000","76.3167","India","IN","IND","Tamil Nādu","","44906","1356494273"
"Angadanan","Angadanan","16.7571","121.7479","Philippines","PH","PHL","Isabela","","44977","1608436473"
"Āstāneh-ye Ashrafīyeh","Astaneh-ye Ashrafiyeh","37.2597","49.9444","Iran","IR","IRN","Gīlān","minor","44941","1364719159"
"Wiener Neustadt","Wiener Neustadt","47.8167","16.2500","Austria","AT","AUT","Niederösterreich","minor","44461","1040389518"
"Beiwanglizhen","Beiwanglizhen","37.7604","114.6373","China","CN","CHN","Hebei","","44112","1156215530"
"Keller","Keller","32.9335","-97.2256","United States","US","USA","Texas","","45190","1840019428"
"Shangshan","Shangshan","23.4792","115.6918","China","CN","CHN","Guangdong","","44871","1156123050"
"Tangjiacun","Tangjiacun","20.8425","109.8469","China","CN","CHN","Guangdong","","44684","1156505609"
"Aksay","Aksay","47.2500","39.8667","Russia","RU","RUS","Rostovskaya Oblast’","minor","45078","1643659025"
"Guledagudda","Guledagudda","16.0502","75.7900","India","IN","IND","Karnātaka","","44868","1356735858"
"Radomsko","Radomsko","51.0667","19.4500","Poland","PL","POL","Łódzkie","minor","44441","1616575463"
"Concepcion","Concepcion","11.2000","123.1000","Philippines","PH","PHL","Iloilo","","44633","1608504711"
"Liuquan","Liuquan","39.3658","116.3138","China","CN","CHN","Hebei","","44765","1156756759"
"Melūr","Melur","10.0313","78.3381","India","IN","IND","Tamil Nādu","","44722","1356060351"
"Hassi Messaoud","Hassi Messaoud","31.7000","6.0667","Algeria","DZ","DZA","Ouargla","","45147","1012339655"
"Kyle","Kyle","29.9932","-97.8852","United States","US","USA","Texas","","45147","1840020907"
"Tremembé","Tremembe","-22.9578","-45.5489","Brazil","BR","BRA","São Paulo","","44912","1076329982"
"Yirga ‘Alem","Yirga \`Alem","6.7500","38.4167","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","43815","1231817240"
"Montemorelos","Montemorelos","25.1872","-99.8267","Mexico","MX","MEX","Nuevo León","minor","45108","1484813948"
"Sakura","Sakura","36.6853","139.9664","Japan","JP","JPN","Tochigi","","44777","1392003217"
"Webster","Webster","43.2294","-77.4454","United States","US","USA","New York","","45133","1840004286"
"Buguias","Buguias","16.8033","120.8211","Philippines","PH","PHL","Benguet","","44877","1608025236"
"Châteauroux","Chateauroux","46.8103","1.6911","France","FR","FRA","Centre-Val de Loire","minor","43442","1250603472"
"Little Elm","Little Elm","33.1858","-96.9295","United States","US","USA","Texas","","45124","1840020640"
"Yaopu","Yaopu","26.1708","105.8480","China","CN","CHN","Guizhou","","44703","1156177445"
"Nova Kakhovka","Nova Kakhovka","46.7550","33.3750","Ukraine","UA","UKR","Khersonska Oblast","","45069","1804166107"
"Tateyama","Tateyama","34.9966","139.8700","Japan","JP","JPN","Chiba","","44692","1392021641"
"Tres Valles","Tres Valles","18.2367","-96.1369","Mexico","MX","MEX","Veracruz","minor","44978","1484297361"
"Rabinal","Rabinal","15.0833","-90.4917","Guatemala","GT","GTM","Baja Verapaz","minor","45000","1320817079"
"Iwanuma","Iwanuma","38.1043","140.8702","Japan","JP","JPN","Miyagi","","44340","1392999998"
"Maria Aurora","Maria Aurora","15.7967","121.4737","Philippines","PH","PHL","Aurora","","44958","1608487496"
"Prescott","Prescott","34.5849","-112.4473","United States","US","USA","Arizona","","45063","1840020436"
"Sayreville","Sayreville","40.4656","-74.3237","United States","US","USA","New Jersey","","45062","1840001332"
"Anekal","Anekal","12.7105","77.6967","India","IN","IND","Karnātaka","","43767","1356802211"
"Sidcup","Sidcup","51.4263","0.1024","United Kingdom","GB","GBR","Bexley","","43109","1826363364"
"Urbandale","Urbandale","41.6390","-93.7812","United States","US","USA","Iowa","","45037","1840010184"
"Shaḩḩāt","Shahhat","32.8250","21.8581","Libya","LY","LBY","Al Jabal al Akhḑar","","45000","1434402597"
"Colotenango","Colotenango","15.4054","-91.7156","Guatemala","GT","GTM","Huehuetenango","minor","44823","1320397198"
"Kimry","Kimry","56.8667","37.3500","Russia","RU","RUS","Tverskaya Oblast’","","44743","1643056082"
"Shchūchīnsk","Shchuchinsk","52.9333","70.2000","Kazakhstan","KZ","KAZ","","minor","45004","1398291807"
"Darcheh","Darcheh","32.6153","51.5556","Iran","IR","IRN","Eşfahān","","43183","1364123646"
"Kutum","Kutum","14.2056","24.6500","Sudan","SD","SDN","North Darfur","","45000","1729748758"
"Parma","Parma","65.9230","57.4030","Russia","RU","RUS","Komi","","45000","1643982067"
"Senigallia","Senigallia","43.7131","13.2183","Italy","IT","ITA","Marche","","44616","1380834864"
"San Jose","San Jose","13.6981","123.5186","Philippines","PH","PHL","Camarines Sur","","43973","1608136409"
"Borlänge","Borlange","60.4856","15.4364","Sweden","SE","SWE","Dalarna","minor","44898","1752944924"
"Yatağan","Yatagan","37.3425","28.1393","Turkey","TR","TUR","Muğla","minor","44940","1792053956"
"‘Ayn al ‘Arab","\`Ayn al \`Arab","36.8910","38.3536","Syria","SY","SYR","Ḩalab","minor","44821","1760764004"
"Pederneiras","Pederneiras","-22.3517","-48.7750","Brazil","BR","BRA","São Paulo","","44910","1076724688"
"Rasskazovo","Rasskazovo","52.6667","41.8833","Russia","RU","RUS","Tambovskaya Oblast’","minor","43758","1643014647"
"Manjuyod","Manjuyod","9.6833","123.1500","Philippines","PH","PHL","Negros Oriental","","44799","1608403935"
"Baião","Baiao","-2.7908","-49.6719","Brazil","BR","BRA","Pará","","44956","1076566352"
"Bacnotan","Bacnotan","16.7197","120.3481","Philippines","PH","PHL","La Union","","44388","1608914452"
"Lapa","Lapa","-25.7700","-49.7158","Brazil","BR","BRA","Paraná","","44932","1076904800"
"Ashburn","Ashburn","39.0277","-77.4714","United States","US","USA","Virginia","","44950","1840024495"
"Al ‘Azīzīyah","Al \`Aziziyah","32.9083","45.0667","Iraq","IQ","IRQ","Wāsiţ","minor","44868","1368091168"
"Sierra Vista","Sierra Vista","31.5630","-110.3153","United States","US","USA","Arizona","","44944","1840022103"
"Nadym","Nadym","65.5333","72.5167","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","44940","1643709121"
"Āz̄arshahr","Azarshahr","37.7722","45.9781","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","44887","1364106517"
"Marau","Marau","-28.4489","-52.2000","Brazil","BR","BRA","Rio Grande do Sul","","44858","1076572640"
"Bolívar","Bolivar","1.9708","-76.9694","Colombia","CO","COL","Cauca","minor","44864","1170620269"
"Turnhout","Turnhout","51.3167","4.9500","Belgium","BE","BEL","Flanders","minor","44136","1056378407"
"Santa Bárbara","Santa Barbara","15.3167","-91.6167","Guatemala","GT","GTM","Huehuetenango","minor","44809","1320493010"
"Neustadt am Rübenberge","Neustadt am Rubenberge","52.5000","9.4667","Germany","DE","DEU","Lower Saxony","","44796","1276887904"
"Nandigāma","Nandigama","16.7833","80.3000","India","IN","IND","Andhra Pradesh","","44359","1356012566"
"Dráma","Drama","41.1500","24.1467","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","44823","1300779088"
"Pemangkat","Pemangkat","1.1768","108.9624","Indonesia","ID","IDN","Kalimantan Barat","","44783","1360279205"
"Nasipit","Nasipit","8.9884","125.3408","Philippines","PH","PHL","Agusan del Norte","","44822","1608538280"
"Houbu","Houbu","36.4370","112.9794","China","CN","CHN","Shanxi","","44360","1156137924"
"Volkhov","Volkhov","59.9167","32.3000","Russia","RU","RUS","Leningradskaya Oblast’","","44868","1643547178"
"Barras","Barras","-4.2469","-42.2958","Brazil","BR","BRA","Piauí","","44850","1076766658"
"Cutler Bay","Cutler Bay","25.5765","-80.3356","United States","US","USA","Florida","","44865","1840017276"
"Zhovti Vody","Zhovti Vody","48.3500","33.5167","Ukraine","UA","UKR","Dnipropetrovska Oblast","","44766","1804788479"
"Zevenaar","Zevenaar","51.9167","6.0667","Netherlands","NL","NLD","Gelderland","minor","44096","1528993139"
"Nsawam","Nsawam","5.8000","-0.3500","Ghana","GH","GHA","Eastern","","44522","1288888871"
"Katipunan","Katipunan","8.5134","123.2847","Philippines","PH","PHL","Zamboanga del Norte","","44661","1608419137"
"Mount Pleasant","Mount Pleasant","41.1119","-73.8121","United States","US","USA","New York","","44840","1840058325"
"Jisr ash Shughūr","Jisr ash Shughur","35.8126","36.3176","Syria","SY","SYR","Idlib","minor","44322","1760389710"
"Nagaizumi","Nagaizumi","35.1377","138.8972","Japan","JP","JPN","Shizuoka","","43204","1392999980"
"Kaarst","Kaarst","51.2167","6.6167","Germany","DE","DEU","North Rhine-Westphalia","","43661","1276322915"
"Noordwijk","Noordwijk","52.2333","4.4333","Netherlands","NL","NLD","Zuid-Holland","minor","44062","1528932000"
"Jacaltenango","Jacaltenango","15.6667","-91.7333","Guatemala","GT","GTM","Huehuetenango","minor","44605","1320537447"
"Balcarce","Balcarce","-37.8456","-58.2578","Argentina","AR","ARG","Buenos Aires","minor","44796","1032847478"
"Palm Springs","Palm Springs","33.8017","-116.5382","United States","US","USA","California","","44799","1840020556"
"Uruará","Uruara","-3.7178","-53.7369","Brazil","BR","BRA","Pará","","44789","1076931265"
"Presidente Dutra","Presidente Dutra","-5.2900","-44.4900","Brazil","BR","BRA","Alagoas","","44731","1076660845"
"Manchester","Manchester","39.9652","-74.3738","United States","US","USA","New Jersey","","44780","1840081608"
"Ellenabad","Ellenabad","29.4500","74.6500","India","IN","IND","Haryāna","","44452","1356057911"
"Nidadavole","Nidadavole","16.9200","81.6700","India","IN","IND","Andhra Pradesh","","43809","1356862958"
"Riverton","Riverton","40.5176","-111.9636","United States","US","USA","Utah","","44773","1840020157"
"Dolores","Dolores","12.0378","125.4828","Philippines","PH","PHL","Eastern Samar","","44626","1608089533"
"Piendamó","Piendamo","2.6408","-76.5284","Colombia","CO","COL","Cauca","minor","44535","1170889555"
"Pyu","Pyu","18.4819","96.4369","Myanmar","MM","MMR","Bago","","44642","1104518645"
"Yaoquan","Yaoquan","34.5851","105.7262","China","CN","CHN","Gansu","","44372","1156844219"
"Tori-Bossito","Tori-Bossito","6.5031","2.1450","Benin","BJ","BEN","Atlantique","","44569","1204446512"
"Abū al Maţāmīr","Abu al Matamir","30.9084","30.1485","Egypt","EG","EGY","Al Buḩayrah","","44415","1818329070"
"Lancaster","Lancaster","42.9099","-78.6378","United States","US","USA","New York","","44732","1840004387"
"Taketoyo","Taketoyo","34.8511","136.9147","Japan","JP","JPN","Aichi","","43095","1392393815"
"Lehrte","Lehrte","52.3667","9.9667","Germany","DE","DEU","Lower Saxony","","44369","1276716840"
"Lake Ridge","Lake Ridge","38.6847","-77.3066","United States","US","USA","Virginia","","44697","1840006099"
"Culasi","Culasi","11.4272","122.0560","Philippines","PH","PHL","Antique","","44494","1608277332"
"Mawlaik","Mawlaik","23.2833","94.0833","Myanmar","MM","MMR","Sagaing","","44540","1104686347"
"Deinze","Deinze","50.9833","3.5333","Belgium","BE","BEL","Flanders","","44315","1056715271"
"Świdnik","Swidnik","51.2167","22.7000","Poland","PL","POL","Lubelskie","minor","42797","1616873846"
"Etten-Leur","Etten-Leur","51.5667","4.6333","Netherlands","NL","NLD","Noord-Brabant","minor","43869","1528512039"
"Seferhisar","Seferhisar","38.2000","26.8333","Turkey","TR","TUR","İzmir","minor","44526","1792680946"
"Alaplı","Alapli","41.1833","31.3833","Turkey","TR","TUR","Zonguldak","minor","44286","1792284635"
"San Pascual","San Pascual","13.1286","122.9775","Philippines","PH","PHL","Masbate","","44449","1608784902"
"Arboletes","Arboletes","8.8517","-76.4283","Colombia","CO","COL","Antioquia","minor","44560","1170052299"
"Loon","Loon","9.8000","123.8000","Philippines","PH","PHL","Bohol","","44224","1608295204"
"Garça","Garca","-22.2153","-49.6511","Brazil","BR","BRA","São Paulo","","44532","1076270928"
"Rājaldesar","Rajaldesar","28.0300","74.4700","India","IN","IND","Rājasthān","","44385","1356850348"
"Medak","Medak","18.0460","78.2630","India","IN","IND","Andhra Pradesh","","44255","1356054282"
"Kikuyō","Kikuyo","32.8625","130.8287","Japan","JP","JPN","Kumamoto","","43443","1392003170"
"Vernon","Vernon","50.2670","-119.2720","Canada","CA","CAN","British Columbia","","44600","1124553338"
"Tiberias","Tiberias","32.7944","35.5333","Israel","IL","ISR","Northern","","44200","1376017086"
"Ribeirão","Ribeirao","-8.5139","-35.3778","Brazil","BR","BRA","Pernambuco","","44439","1076626596"
"Dalli Rājhara","Dalli Rajhara","20.5800","81.0800","India","IN","IND","Chhattīsgarh","","44363","1356542563"
"Yuanli","Yuanli","24.4168","120.6830","Taiwan","TW","TWN","Miaoli","","43923","1158991714"
"Jora","Jora","26.3421","77.8092","India","IN","IND","Madhya Pradesh","","44235","1356211132"
"Denia","Denia","38.8444","0.1111","Spain","ES","ESP","Valencia","","43899","1724032915"
"Banaybanay","Banaybanay","6.9664","126.0114","Philippines","PH","PHL","Davao Oriental","minor","44451","1608436387"
"Fairfield","Fairfield","39.3301","-84.5409","United States","US","USA","Ohio","","44550","1840003802"
"Vlissingen","Vlissingen","51.4500","3.5667","Netherlands","NL","NLD","Zeeland","minor","44417","1528524195"
"Labangan","Labangan","7.8667","123.5167","Philippines","PH","PHL","Zamboanga del Sur","","44262","1608208159"
"Bietigheim-Bissingen","Bietigheim-Bissingen","48.9667","9.1333","Germany","DE","DEU","Baden-Württemberg","","43146","1276916733"
"Masuda","Masuda","34.6667","131.8500","Japan","JP","JPN","Shimane","","44474","1392671725"
"Qingfengdian","Qingfengdian","38.6084","115.0568","China","CN","CHN","Hebei","","43703","1156064987"
"Changtoushang","Changtoushang","19.3441","110.6096","China","CN","CHN","Hainan","","44326","1156728716"
"General Pacheco","General Pacheco","-34.4500","-58.6500","Argentina","AR","ARG","Buenos Aires","","43287","1032016569"
"Barwāh","Barwah","22.2539","76.0385","India","IN","IND","Madhya Pradesh","","44297","1356059503"
"West Lafayette","West Lafayette","40.4432","-86.9238","United States","US","USA","Indiana","","44515","1840010430"
"Bulalacao","Bulalacao","12.3333","121.3500","Philippines","PH","PHL","Oriental Mindoro","","44366","1608165022"
"Goose Creek","Goose Creek","32.9927","-80.0055","United States","US","USA","South Carolina","","44502","1840013761"
"Tsiroanomandidy","Tsiroanomandidy","-18.7698","46.0500","Madagascar","MG","MDG","Antananarivo","minor","44461","1450080598"
"Petatlán","Petatlan","17.5383","-101.2739","Mexico","MX","MEX","Guerrero","minor","44485","1484714363"
"Prostějov","Prostejov","49.4722","17.1106","Czechia","CZ","CZE","Olomoucký Kraj","","43381","1203172907"
"Albufeira","Albufeira","37.0897","-8.2458","Portugal","PT","PRT","Faro","minor","44168","1620078404"
"Mount Laurel","Mount Laurel","39.9483","-74.9047","United States","US","USA","New Jersey","","44473","1840081624"
"Porsa","Porsa","26.6700","78.3700","India","IN","IND","Madhya Pradesh","","43695","1356503653"
"San Juan y Martínez","San Juan y Martinez","22.2667","-83.8333","Cuba","CU","CUB","Pinar del Río","minor","44344","1192697907"
"Paraíso do Tocantins","Paraiso do Tocantins","-10.1758","-48.8669","Brazil","BR","BRA","Tocantins","","44417","1076928889"
"Tongoma","Tongoma","8.6500","-11.0667","Sierra Leone","SL","SLE","Eastern","","44376","1694227218"
"Ananipalle","Ananipalle","13.6500","79.4200","India","IN","IND","Andhra Pradesh","","44219","1356390426"
"Wallingford","Wallingford","41.4589","-72.8038","United States","US","USA","Connecticut","","44428","1840045095"
"Sihora","Sihora","23.0000","80.1200","India","IN","IND","Madhya Pradesh","","44048","1356044182"
"Los Banos","Los Banos","37.0630","-120.8406","United States","US","USA","California","","44421","1840020312"
"Svishtov","Svishtov","43.6167","25.3500","Bulgaria","BG","BGR","Veliko Tarnovo","minor","44359","1100115119"
"Fond du Lac","Fond du Lac","43.7718","-88.4397","United States","US","USA","Wisconsin","","44412","1840002603"
"Bassano del Grappa","Bassano del Grappa","45.7667","11.7342","Italy","IT","ITA","Veneto","","43481","1380653190"
"General Mamerto Natividad","General Mamerto Natividad","15.6030","121.0510","Philippines","PH","PHL","Nueva Ecija","","44311","1608562386"
"Çayeli","Cayeli","41.0880","40.7232","Turkey","TR","TUR","Rize","minor","44304","1792569066"
"Tuodian","Tuodian","24.6907","101.6382","China","CN","CHN","Yunnan","minor","44265","1156038006"
"Brentwood","Brentwood","35.9918","-86.7758","United States","US","USA","Tennessee","","44354","1840013381"
"Eqlīd","Eqlid","30.8989","52.6867","Iran","IR","IRN","Fārs","minor","44341","1364072821"
"Maitum","Maitum","6.0333","124.4833","Philippines","PH","PHL","Sarangani","","44185","1608001059"
"Raisen","Raisen","23.3300","77.8000","India","IN","IND","Madhya Pradesh","","44162","1356132089"
"Volksrust","Volksrust","-27.3667","29.8833","South Africa","ZA","ZAF","Mpumalanga","","43378","1710664906"
"Rheden","Rheden","52.0167","6.0500","Netherlands","NL","NLD","Gelderland","","43816","1528068057"
"Manakara","Manakara","-22.1500","48.0000","Madagascar","MG","MDG","Fianarantsoa","minor","44237","1450925890"
"Lombard","Lombard","41.8741","-88.0157","United States","US","USA","Illinois","","44311","1840011402"
"São Benedito","Sao Benedito","-4.0489","-40.8650","Brazil","BR","BRA","Ceará","","44178","1076599736"
"Plainfield","Plainfield","41.6207","-88.2253","United States","US","USA","Illinois","","44296","1840011495"
"Sonqor","Sonqor","34.7836","47.6003","Iran","IR","IRN","Kermānshāh","minor","44256","1364821189"
"Cateel","Cateel","7.7900","126.4531","Philippines","PH","PHL","Davao Oriental","","44207","1608641837"
"Mauriti","Mauriti","-7.3889","-38.7739","Brazil","BR","BRA","Ceará","","44240","1076467166"
"Mandaon","Mandaon","12.2259","123.2842","Philippines","PH","PHL","Masbate","","44122","1608226028"
"Korogwe","Korogwe","-5.1558","38.4503","Tanzania","TZ","TZA","Tanga","","44000","1834605335"
"Char Fasson","Char Fasson","22.1861","90.7597","Bangladesh","BD","BGD","Khulna","","42915","1050997444"
"Dounan","Dounan","23.6833","120.4833","Taiwan","TW","TWN","Yunlin","","43360","1158926049"
"Tinajdad","Tinajdad","31.5000","-5.0167","Morocco","MA","MAR","Drâa-Tafilalet","","43999","1504176217"
"Dikili","Dikili","39.0667","26.8833","Turkey","TR","TUR","İzmir","minor","44172","1792392506"
"Guinayangan","Guinayangan","13.9000","122.4500","Philippines","PH","PHL","Quezon","","44045","1608150826"
"Krasnodon","Krasnodon","48.3000","39.7333","Ukraine","UA","UKR","Luhanska Oblast","","43683","1804346804"
"Xanxerê","Xanxere","-26.8769","-52.4039","Brazil","BR","BRA","Santa Catarina","","44128","1076871308"
"Ogimachi","Ogimachi","33.2500","130.2000","Japan","JP","JPN","Saga","","43787","1392963312"
"Addanki","Addanki","15.8110","79.9738","India","IN","IND","Andhra Pradesh","","43850","1356837121"
"Nilothi","Nilothi","28.8167","76.8667","India","IN","IND","Haryāna","","43371","1356727665"
"Las Matas de Farfán","Las Matas de Farfan","18.8700","-71.5200","Dominican Republic","DO","DOM","El Valle","","44163","1214559824"
"Lompoc","Lompoc","34.6618","-120.4714","United States","US","USA","California","","44232","1840020468"
"Harsīn","Harsin","34.2722","47.5861","Iran","IR","IRN","Kermānshāh","minor","44146","1364489959"
"Panambi","Panambi","-28.2928","-53.5019","Brazil","BR","BRA","Rio Grande do Sul","","44128","1076541369"
"Nijkerk","Nijkerk","52.2333","5.4833","Netherlands","NL","NLD","Gelderland","minor","43600","1528422817"
"Jānakammapeta","Janakammapeta","14.8840","79.9110","India","IN","IND","Andhra Pradesh","","44059","1356032942"
"Labason","Labason","8.0647","122.5200","Philippines","PH","PHL","Zamboanga del Norte","","43934","1608176586"
"Burke","Burke","38.7773","-77.2633","United States","US","USA","Virginia","","44191","1840006014"
"Hagi","Hagi","34.4000","131.4000","Japan","JP","JPN","Yamaguchi","","44118","1392685567"
"Kuybyshev","Kuybyshev","55.4500","78.3000","Russia","RU","RUS","Novosibirskaya Oblast’","","44170","1643017442"
"Jinku","Jinku","23.0398","112.5099","China","CN","CHN","Guangdong","","43623","1156058819"
"Alghero","Alghero","40.5600","8.3150","Italy","IT","ITA","Sardegna","","43964","1380434974"
"Roghun","Roghun","38.6978","69.7522","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","44100","1762926748"
"Saint-Eustache","Saint-Eustache","45.5700","-73.9000","Canada","CA","CAN","Quebec","","44154","1124758162"
"Yutiancun","Yutiancun","25.8747","119.4578","China","CN","CHN","Fujian","","43358","1156469124"
"Otofuke","Otofuke","42.9942","143.1981","Japan","JP","JPN","Hokkaidō","","44049","1392138807"
"Badian","Badian","9.8694","123.3959","Philippines","PH","PHL","Cebu","minor","43735","1608819592"
"San Cristóbal Totonicapán","San Cristobal Totonicapan","14.9197","-91.4400","Guatemala","GT","GTM","Totonicapán","minor","42937","1320524150"
"Yartsevo","Yartsevo","55.0667","32.7000","Russia","RU","RUS","Smolenskaya Oblast’","minor","44097","1643013101"
"Moorhead","Moorhead","46.8673","-96.7461","United States","US","USA","Minnesota","","44129","1840007731"
"Wānkāner","Wankaner","22.6161","70.9531","India","IN","IND","Gujarāt","","43881","1356694528"
"Talatamaty","Talatamaty","-18.8333","47.4500","Madagascar","MG","MDG","Antananarivo","","44082","1450675935"
"Goshikichō-aihara-minamidani","Goshikicho-aihara-minamidani","34.3000","134.7833","Japan","JP","JPN","Hyōgo","","43914","1392800899"
"Barpeta","Barpeta","26.3200","91.0000","India","IN","IND","Assam","","42649","1356659123"
"Kaštel Stari","Kastel Stari","43.5500","16.3500","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","","43349","1191355987"
"Cimerak","Cimerak","-7.7422","108.4244","Indonesia","ID","IDN","Jawa Barat","minor","43500","1360250961"
"San Vicente de Tagua Tagua","San Vicente de Tagua Tagua","-34.4394","-71.0761","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","44046","1152003173"
"Al Midhnab","Al Midhnab","25.8601","44.2223","Saudi Arabia","SA","SAU","Al Qaşīm","","44043","1682375082"
"Rudauli","Rudauli","26.7500","81.7500","India","IN","IND","Uttar Pradesh","","43091","1356247478"
"Pantanal","Pantanal","18.5333","-68.3667","Dominican Republic","DO","DOM","Yuma","","43982","1214144437"
"Maştağa","Mastaga","40.5328","50.0039","Azerbaijan","AZ","AZE","Bakı","","43700","1031769792"
"Maracaju","Maracaju","-21.6139","-55.1678","Brazil","BR","BRA","Mato Grosso do Sul","","44042","1076548335"
"Bakamune","Bakamune","7.7831","80.8183","Sri Lanka","LK","LKA","Northern","","43915","1144645119"
"Xinjun","Xinjun","28.7232","120.0190","China","CN","CHN","Zhejiang","","43793","1156132357"
"Boyabat","Boyabat","41.4653","34.7708","Turkey","TR","TUR","Sinop","minor","44004","1792072042"
"De Bilt","De Bilt","52.1167","5.2000","Netherlands","NL","NLD","Utrecht","","43384","1528141084"
"Menghan","Menghan","21.8526","100.9265","China","CN","CHN","Yunnan","","43965","1156087701"
"Limache","Limache","-33.0167","-71.2667","Chile","CL","CHL","Valparaíso","","43999","1152056672"
"Venray","Venray","51.5333","5.9833","Netherlands","NL","NLD","Limburg","minor","43713","1528891119"
"Abqaiq","Abqaiq","25.9350","49.6661","Saudi Arabia","SA","SAU","Ash Sharqīyah","","44000","1682412526"
"Kamen","Kamen","51.5917","7.6653","Germany","DE","DEU","North Rhine-Westphalia","","42971","1276465116"
"Istres","Istres","43.5151","4.9895","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","43626","1250932353"
"Pinhal","Pinhal","-22.1908","-46.7408","Brazil","BR","BRA","São Paulo","","43897","1076552956"
"Comendador","Comendador","18.8833","-71.7000","Dominican Republic","DO","DOM","El Valle","minor","43894","1214626724"
"Zongolica","Zongolica","18.6642","-97.0011","Mexico","MX","MEX","Veracruz","minor","43871","1484604711"
"Jiaohe","Jiaohe","38.0228","116.2845","China","CN","CHN","Hebei","","43547","1156121848"
"Mucaba","Mucaba","-7.1667","14.9333","Angola","AO","AGO","Uíge","","43974","1024000008"
"Hinatuan","Hinatuan","8.3661","126.3361","Philippines","PH","PHL","Surigao del Sur","minor","43841","1608000148"
"Casale","Casale","18.8014","-72.3836","Haiti","HT","HTI","Ouest","","43943","1332190060"
"Surčin","Surcin","44.8000","20.2833","Serbia","RS","SRB","Beograd","minor","43819","1688106927"
"Jaro","Jaro","11.1894","124.7853","Philippines","PH","PHL","Leyte","","43758","1608163116"
"Pardés H̱anna Karkur","Pardes Hanna Karkur","32.4711","34.9675","Israel","IL","ISR","Haifa","","42100","1376163698"
"Khutubi","Khutubi","44.1874","86.8946","China","CN","CHN","Xinjiang","minor","43127","1156223183"
"San Germán","San German","20.6011","-76.1325","Cuba","CU","CUB","Holguín","minor","43892","1192000020"
"São Gonçalo do Amarante","Sao Goncalo do Amarante","-3.6058","-38.9689","Brazil","BR","BRA","Ceará","","43890","1076511240"
"Mankato","Mankato","44.1715","-93.9772","United States","US","USA","Minnesota","","43938","1840008975"
"Himi","Himi","36.8567","136.9731","Japan","JP","JPN","Toyama","","43747","1392258347"
"Gravina in Puglia","Gravina in Puglia","40.8167","16.4167","Italy","IT","ITA","Puglia","","43816","1380966676"
"Qiryat Moẕqin","Qiryat Mozqin","32.8333","35.0833","Israel","IL","ISR","Haifa","","42000","1376435231"
"Yatomi","Yatomi","35.1167","136.7167","Japan","JP","JPN","Aichi","","43038","1392003504"
"Nandazhang","Nandazhang","38.1031","114.7582","China","CN","CHN","Hebei","","42934","1156256678"
"Loreto","Loreto","8.1856","125.8538","Philippines","PH","PHL","Agusan del Sur","","43880","1608248294"
"Bāniyās","Baniyas","35.1822","35.9403","Syria","SY","SYR","Ţarţūs","minor","43151","1760780974"
"Jaito","Jaito","30.4514","74.8919","India","IN","IND","Punjab","","43433","1356115096"
"Pittsfield","Pittsfield","42.4517","-73.2605","United States","US","USA","Massachusetts","","43890","1840003116"
"Kalilangan","Kalilangan","7.7464","124.7475","Philippines","PH","PHL","Bukidnon","","43711","1608709843"
"Villeta","Villeta","5.0083","-74.4738","Colombia","CO","COL","Cundinamarca","minor","43574","1170441862"
"’Aïn Arnat","'Ain Arnat","36.1833","5.3167","Algeria","DZ","DZA","Sétif","","43551","1012453452"
"Marki","Marki","52.3333","21.1000","Poland","PL","POL","Mazowieckie","","42252","1616076713"
"Tavas","Tavas","37.5728","29.0714","Turkey","TR","TUR","Denizli","minor","43845","1792649925"
"Āzādshahr","Azadshahr","37.0869","55.1739","Iran","IR","IRN","Golestān","minor","43760","1364038403"
"Ciudad Constitución","Ciudad Constitucion","25.0322","-111.6703","Mexico","MX","MEX","Baja California Sur","minor","43805","1484811681"
"Erkelenz","Erkelenz","51.0833","6.3167","Germany","DE","DEU","North Rhine-Westphalia","","43492","1276067473"
"Chikodi","Chikodi","16.4300","74.6000","India","IN","IND","Karnātaka","","43326","1356759185"
"Tanghin-Dassouri","Tanghin-Dassouri","12.2667","-1.7167","Burkina Faso","BF","BFA","Centre","minor","43675","1854307252"
"Şān al Ḩajar al Qiblīyah","San al Hajar al Qibliyah","30.9769","31.8800","Egypt","EG","EGY","Ash Sharqīyah","","43157","1818442798"
"El Asintal","El Asintal","14.6000","-91.7333","Guatemala","GT","GTM","Retalhuleu","minor","43281","1320424397"
"Kāyalpattanam","Kayalpattanam","8.5638","78.1246","India","IN","IND","Tamil Nādu","","43116","1356546663"
"Chefchaouene","Chefchaouene","35.1714","-5.2697","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","42786","1504471687"
"Laurel","Laurel","14.0500","120.9000","Philippines","PH","PHL","Batangas","","43210","1608000356"
"Toboso","Toboso","10.7167","123.5167","Philippines","PH","PHL","Negros Occidental","","43445","1608947836"
"Wismar","Wismar","53.9000","11.4667","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","42785","1276660084"
"Al Kiswah","Al Kiswah","33.3500","36.2333","Syria","SY","SYR","Rīf Dimashq","minor","43456","1760910325"
"Sevilla","Sevilla","4.2689","-75.9361","Colombia","CO","COL","Valle del Cauca","minor","43738","1170453618"
"Mahbūbābād","Mahbubabad","17.5973","80.0021","India","IN","IND","Andhra Pradesh","","42851","1356014527"
"Santa Elena","Santa Elena","14.1797","122.3919","Philippines","PH","PHL","Camarines Norte","","43582","1608770058"
"Madridejos","Madridejos","11.2667","123.7333","Philippines","PH","PHL","Cebu","","42039","1608629186"
"Tamu","Tamu","24.2167","94.3097","Myanmar","MM","MMR","Sagaing","","43737","1104850868"
"Kattivākkam","Kattivakkam","13.2167","80.3167","India","IN","IND","Tamil Nādu","","43018","1356595058"
"Moises Padilla","Moises Padilla","10.2667","123.0833","Philippines","PH","PHL","Negros Occidental","","43462","1608308845"
"Barauli","Barauli","26.3815","84.5872","India","IN","IND","Bihār","","41877","1356793711"
"Chusovoy","Chusovoy","58.3167","57.8167","Russia","RU","RUS","Permskiy Kray","","43737","1643341477"
"Zhangliangcun","Zhangliangcun","37.1256","112.0664","China","CN","CHN","Shanxi","","43151","1156176189"
"Konārak","Konarak","25.3603","60.3994","Iran","IR","IRN","Sīstān va Balūchestān","minor","43732","1364254379"
"Kharik","Kharik","25.3679","87.0026","India","IN","IND","Bihār","","42638","1356788245"
"Macenta","Macenta","8.5504","-9.4800","Guinea","GN","GIN","N’Zérékoré","minor","43102","1324817479"
"Barhiya","Barhiya","25.2833","86.0333","India","IN","IND","Bihār","","43032","1356881084"
"Eṭ Ṭaiyiba","Et Taiyiba","32.2667","35.0103","Israel","IL","ISR","Central","","43100","1376597784"
"Takāb","Takab","36.4008","47.1133","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","43702","1364000025"
"Taniyama-chūō","Taniyama-chuo","31.5211","130.5176","Japan","JP","JPN","Kagoshima","","42683","1392409651"
"Roseller Lim","Roseller Lim","7.6583","122.4639","Philippines","PH","PHL","Zamboanga Sibugay","minor","43575","1608602794"
"Shinshiro","Shinshiro","34.9159","137.4986","Japan","JP","JPN","Aichi","","43620","1392488920"
"Waslala","Waslala","13.3367","-85.3708","Nicaragua","NI","NIC","Costa Caribe Norte","","43676","1558730387"
"Xihuachi","Xihuachi","35.8164","108.0125","China","CN","CHN","Gansu","minor","43338","1156440328"
"Danville","Danville","37.8121","-121.9698","United States","US","USA","California","","43673","1840021532"
"Of","Of","40.9450","40.2644","Turkey","TR","TUR","Trabzon","minor","43499","1792121137"
"San Sebastián Huehuetenango","San Sebastian Huehuetenango","15.3833","-91.5833","Guatemala","GT","GTM","Huehuetenango","minor","43261","1320449471"
"Guambog","Guambog","7.3092","125.8556","Philippines","PH","PHL","Davao de Oro","","43552","1608180955"
"Paoskoto","Paoskoto","13.7833","-15.8167","Senegal","SN","SEN","Kaolack","","43460","1686730236"
"North Brunswick","North Brunswick","40.4505","-74.4798","United States","US","USA","New Jersey","","43652","1840081694"
"Alès","Ales","44.1281","4.0817","France","FR","FRA","Occitanie","minor","41837","1250908381"
"Çeşme","Cesme","38.3236","26.3028","Turkey","TR","TUR","İzmir","minor","43489","1792570179"
"Cové","Cove","7.2333","2.3000","Benin","BJ","BEN","Zou","","43554","1204989651"
"Ponte de Lima","Ponte de Lima","41.7667","-8.5667","Portugal","PT","PRT","Viana do Castelo","minor","43498","1620557012"
"Angoulême","Angouleme","45.6500","0.1600","France","FR","FRA","Nouvelle-Aquitaine","minor","41711","1250150112"
"Zhongbu","Zhongbu","23.4082","120.5343","Taiwan","TW","TWN","Chiayi","","43275","1158600763"
"Birsk","Birsk","55.4167","55.5333","Russia","RU","RUS","Bashkortostan","","43572","1643348438"
"Ambāsamudram","Ambasamudram","8.7037","77.4527","India","IN","IND","Tamil Nādu","","43215","1356103053"
"Dildārnagar","Dildarnagar","25.4309","83.6665","India","IN","IND","Uttar Pradesh","","42121","1356868786"
"Raduzhnyy","Raduzhnyy","62.1333","77.4667","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","43577","1643039023"
"Quinte West","Quinte West","44.1833","-77.5667","Canada","CA","CAN","Ontario","","43577","1124001037"
"Presidente Epitácio","Presidente Epitacio","-21.7633","-52.1156","Brazil","BR","BRA","São Paulo","","43535","1076557618"
"El Cuá","El Cua","13.3678","-85.6725","Nicaragua","NI","NIC","Jinotega","minor","43305","1558463653"
"Lota","Lota","-37.0833","-73.1667","Chile","CL","CHL","Biobío","","43272","1152978276"
"Pontes e Lacerda","Pontes e Lacerda","-15.2258","-59.3350","Brazil","BR","BRA","Mato Grosso","","43538","1076490162"
"Yeola","Yeola","20.0420","74.4890","India","IN","IND","Mahārāshtra","","43205","1356655107"
"Bahía Honda","Bahia Honda","22.9064","-83.1639","Cuba","CU","CUB","Artemisa","minor","43483","1192503832"
"Maïssade","Maissade","19.1667","-72.1333","Haiti","HT","HTI","Centre","","43138","1332767571"
"Santa Cruz","Santa Cruz","32.6833","-16.8000","Portugal","PT","PRT","Madeira","","43005","1620409939"
"Zhangcun","Zhangcun","38.1262","114.9523","China","CN","CHN","Hebei","","42911","1156549957"
"Hokuto","Hokuto","35.7765","138.4236","Japan","JP","JPN","Yamanashi","","43452","1392003315"
"Abucay","Abucay","14.7222","120.5354","Philippines","PH","PHL","Bataan","","42984","1608999598"
"Anse Rouge","Anse Rouge","19.6333","-73.0500","Haiti","HT","HTI","Artibonite","","43395","1332665513"
"The Colony","The Colony","33.0925","-96.8976","United States","US","USA","Texas","","43489","1840022005"
"Odenton","Odenton","39.0661","-76.6938","United States","US","USA","Maryland","","43488","1840005912"
"Wangyuanqiao","Wangyuanqiao","38.3849","106.2664","China","CN","CHN","Ningxia","","42022","1156767941"
"Southington","Southington","41.6050","-72.8801","United States","US","USA","Connecticut","","43474","1840035601"
"Dilbeek","Dilbeek","50.8333","4.2500","Belgium","BE","BEL","Flanders","","42434","1056357068"
"Berubāri","Berubari","26.3603","88.7152","India","IN","IND","West Bengal","","41593","1356337976"
"Pedro Celestino Negrete","Pedro Celestino Negrete","24.7260","-102.9840","Mexico","MX","MEX","Durango","","43447","1484247270"
"Muttayyāpuram","Muttayyapuram","8.7498","78.1311","India","IN","IND","Tamil Nādu","","41993","1356214142"
"Makinohara","Makinohara","34.7333","138.2167","Japan","JP","JPN","Shizuoka","","43061","1392331994"
"Heist-op-den-Berg","Heist-op-den-Berg","51.0833","4.7167","Belgium","BE","BEL","Flanders","","42950","1056533253"
"Zharkent","Zharkent","44.1667","80.0000","Kazakhstan","KZ","KAZ","","","43430","1398507500"
"Siegburg","Siegburg","50.8014","7.2044","Germany","DE","DEU","North Rhine-Westphalia","minor","41660","1276838674"
"South Upi","South Upi","6.8548","124.1443","Philippines","PH","PHL","Maguindanao","","43197","1608025115"
"Bolvadin","Bolvadin","38.7167","31.0500","Turkey","TR","TUR","Afyonkarahisar","minor","43398","1792646513"
"Gurrampod","Gurrampod","16.8667","79.1167","India","IN","IND","Andhra Pradesh","","43280","1356810152"
"Berkeley","Berkeley","39.9156","-74.1923","United States","US","USA","New Jersey","","43412","1840081604"
"Sawākin","Sawakin","19.1025","37.3300","Sudan","SD","SDN","Red Sea","","43337","1729959085"
"Alenquer","Alenquer","39.0500","-9.0167","Portugal","PT","PRT","Lisboa","minor","43267","1620149244"
"Gifhorn","Gifhorn","52.4886","10.5464","Germany","DE","DEU","Lower Saxony","minor","42993","1276793894"
"West Babylon","West Babylon","40.7129","-73.3585","United States","US","USA","New York","","43397","1840005109"
"Shakopee","Shakopee","44.7745","-93.4772","United States","US","USA","Minnesota","","43396","1840008955"
"Linden","Linden","40.6251","-74.2383","United States","US","USA","New Jersey","","43387","1840001094"
"Estância Velha","Estancia Velha","-29.6478","-51.1739","Brazil","BR","BRA","Rio Grande do Sul","","42574","1076521402"
"Douar Bni Malek","Douar Bni Malek","34.7800","-5.6425","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","43282","1504252777"
"Altadena","Altadena","34.1927","-118.1346","United States","US","USA","California","","43384","1840017918"
"Shimabara","Shimabara","32.7878","130.3697","Japan","JP","JPN","Nagasaki","","42858","1392371558"
"Pirthīpur","Pirthipur","25.1853","78.6650","India","IN","IND","Madhya Pradesh","","42883","1356426276"
"Shināş","Shinas","24.7500","56.4667","Oman","OM","OMN","Shamāl al Bāţinah","","43312","1512941410"
"Hueyapan de Ocampo","Hueyapan de Ocampo","18.1500","-95.1500","Mexico","MX","MEX","Veracruz","minor","43309","1484670918"
"Heinsberg","Heinsberg","51.0631","6.0964","Germany","DE","DEU","North Rhine-Westphalia","minor","42888","1276000099"
"Tešanj","Tesanj","44.6142","17.9894","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","43063","1070677914"
"Saoner","Saoner","21.3858","78.9201","India","IN","IND","Mahārāshtra","","43000","1356237945"
"Lokeren","Lokeren","51.1000","3.9833","Belgium","BE","BEL","Flanders","","42703","1056040417"
"Shōranūr","Shoranur","10.7700","76.2800","India","IN","IND","Kerala","","42022","1356075746"
"Esperanza","Esperanza","-31.4167","-60.9333","Argentina","AR","ARG","Santa Fe","minor","43300","1032763447"
"King’s Lynn","King's Lynn","52.7540","0.3980","United Kingdom","GB","GBR","Norfolk","","42800","1826126366"
"Sūrampatti","Surampatti","11.3411","77.6956","India","IN","IND","Tamil Nādu","","42171","1356169572"
"Gallatin","Gallatin","36.3782","-86.4698","United States","US","USA","Tennessee","","43306","1840013295"
"Vahdat","Vahdat","38.5531","69.0206","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","43200","1762043986"
"Akbarpur","Akbarpur","26.5200","82.6200","India","IN","IND","Uttar Pradesh","","42248","1356710855"
"Rantepao","Rantepao","-2.9690","119.9000","Indonesia","ID","IDN","Sulawesi Selatan","minor","43123","1360797389"
"San Lorenzo","San Lorenzo","13.4217","-87.4469","Honduras","HN","HND","Valle","","43042","1340596273"
"San Clemente","San Clemente","-35.5500","-71.4833","Chile","CL","CHL","Maule","","43269","1152025924"
"Maḩallāt","Mahallat","33.9111","50.4531","Iran","IR","IRN","Markazī","minor","43245","1364764045"
"Kemer","Kemer","36.6000","30.5500","Turkey","TR","TUR","Antalya","minor","43226","1792680664"
"Civitanova Marche","Civitanova Marche","43.3000","13.7333","Italy","IT","ITA","Marche","","42353","1380527467"
"Kōta","Kota","34.8645","137.1658","Japan","JP","JPN","Aichi","","42523","1392003243"
"Andes","Andes","5.5833","-75.9167","Colombia","CO","COL","Antioquia","minor","43269","1170727383"
"Anakaputtur","Anakaputtur","12.9823","80.1282","India","IN","IND","Tamil Nādu","","42131","1356301119"
"Paso de los Libres","Paso de los Libres","-29.7167","-57.0833","Argentina","AR","ARG","Corrientes","minor","43251","1032166435"
"Germering","Germering","48.1333","11.3667","Germany","DE","DEU","Bavaria","","41387","1276002965"
"Shuilou","Shuilou","22.3360","112.7929","China","CN","CHN","Guangdong","","42578","1156489508"
"Borken","Borken","51.8333","6.8667","Germany","DE","DEU","North Rhine-Westphalia","minor","42974","1276778327"
"Hố Nai","Ho Nai","10.9725","106.8789","Vietnam","VN","VNM","Đồng Nai","","41922","1704997098"
"Nova Viçosa","Nova Vicosa","-17.8919","-39.3719","Brazil","BR","BRA","Bahia","","43216","1076116919"
"Aguazul","Aguazul","5.1731","-72.5547","Colombia","CO","COL","Casanare","minor","43183","1170359050"
"Quirinópolis","Quirinopolis","-18.4478","-50.4519","Brazil","BR","BRA","Goiás","","43220","1076793896"
"Sevanagala","Sevanagala","6.3867","80.9092","Sri Lanka","LK","LKA","Uva","","42894","1144828840"
"Chicamán","Chicaman","15.3483","-90.7994","Guatemala","GT","GTM","Quiché","minor","43139","1320356049"
"Kalaiyā","Kalaiya","27.0333","85.0000","Nepal","NP","NPL","","","42826","1524196942"
"Wentzville","Wentzville","38.8153","-90.8669","United States","US","USA","Missouri","","43213","1840010717"
"Véroia","Veroia","40.5203","22.2019","Greece","GR","GRC","Kentrikí Makedonía","minor","43158","1300354522"
"Ciechanów","Ciechanow","52.8667","20.6333","Poland","PL","POL","Mazowieckie","minor","42965","1616466171"
"Vergína","Vergina","40.5167","22.2000","Greece","GR","GRC","Kentrikí Makedonía","","43158","1300807660"
"Yepocapa","Yepocapa","14.5000","-90.9500","Guatemala","GT","GTM","Chimaltenango","minor","42996","1320657231"
"Laatzen","Laatzen","52.3167","9.8000","Germany","DE","DEU","Lower Saxony","","41965","1276954154"
"Kobayashi","Kobayashi","31.9944","130.9517","Japan","JP","JPN","Miyazaki","","43114","1392148091"
"Nansan","Nansan","23.7784","98.8253","China","CN","CHN","Yunnan","minor","43097","1156575020"
"Hoddesdon","Hoddesdon","51.7590","-0.0150","United Kingdom","GB","GBR","Hertfordshire","","42253","1826405182"
"Přerov","Prerov","49.4556","17.4511","Czechia","CZ","CZE","Olomoucký Kraj","","42451","1203137734"
"Tiel","Tiel","51.8833","5.4333","Netherlands","NL","NLD","Gelderland","minor","41978","1528386027"
"Villaba","Villaba","11.2167","124.4000","Philippines","PH","PHL","Leyte","","42859","1608389528"
"Ban Pet","Ban Pet","16.4379","102.7645","Thailand","TH","THA","Khon Kaen","","42772","1764339108"
"Cumaribo","Cumaribo","4.4333","-69.8000","Colombia","CO","COL","Vichada","minor","43138","1170966663"
"Singhanakhon","Singhanakhon","7.2389","100.5506","Thailand","TH","THA","Songkhla","minor","42423","1764839884"
"São Lourenço do Sul","Sao Lourenco do Sul","-31.3650","-51.9778","Brazil","BR","BRA","Rio Grande do Sul","","43111","1076758883"
"Laungowāl","Laungowal","30.2167","75.6833","India","IN","IND","Punjab","","42346","1356675083"
"Santo Tomas","Santo Tomas","15.0000","120.7500","Philippines","PH","PHL","Pampanga","","42846","1608099364"
"Miura","Miura","35.1500","139.6167","Japan","JP","JPN","Kanagawa","","41807","1392001356"
"Lubao","Lubao","-5.3850","25.7450","Congo (Kinshasa)","CD","COD","Lomami","","43068","1180891619"
"Jesús María","Jesus Maria","21.9667","-102.3500","Mexico","MX","MEX","Aguascalientes","minor","43012","1484096692"
"Nueva Valencia","Nueva Valencia","10.5259","122.5398","Philippines","PH","PHL","Guimaras","","42771","1608858098"
"Musina","Musina","-22.3381","30.0417","South Africa","ZA","ZAF","Limpopo","","42678","1710956370"
"Buffalo Grove","Buffalo Grove","42.1675","-87.9615","United States","US","USA","Illinois","","43062","1840010143"
"Kārmegh","Karmegh","26.5307","86.4263","India","IN","IND","Bihār","","42203","1356646949"
"Naranjo","Naranjo","10.1053","-84.3885","Costa Rica","CR","CRI","Alajuela","","42713","1188055345"
"Alhaurín de la Torre","Alhaurin de la Torre","36.6667","-4.5500","Spain","ES","ESP","Andalusia","","42531","1724504346"
"Entre Rios","Entre Rios","-11.9419","-38.0839","Brazil","BR","BRA","Bahia","","43006","1076914935"
"Panitan","Panitan","11.4639","122.7694","Philippines","PH","PHL","Capiz","","42565","1608830815"
"Amadeo","Amadeo","14.1728","120.9277","Philippines","PH","PHL","Cavite","","41901","1608636193"
"Periyakulam","Periyakulam","10.1200","77.5500","India","IN","IND","Tamil Nādu","","42976","1356498668"
"La Mata","La Mata","19.1000","-70.1681","Dominican Republic","DO","DOM","Cibao Sur","","42785","1214121582"
"Gualeguay","Gualeguay","-33.1500","-59.3333","Argentina","AR","ARG","Entre Ríos","minor","43009","1032077195"
"Nettetal","Nettetal","51.3167","6.2833","Germany","DE","DEU","North Rhine-Westphalia","","42508","1276049262"
"Jatibonico","Jatibonico","21.9464","-79.1675","Cuba","CU","CUB","Sancti Spíritus","minor","42959","1192610911"
"Albano Laziale","Albano Laziale","41.7333","12.6667","Italy","IT","ITA","Lazio","","41314","1380821881"
"Oakley","Oakley","37.9929","-121.6952","United States","US","USA","California","","42996","1840020279"
"Jājpur","Jajpur","21.2140","86.1070","India","IN","IND","Odisha","","42555","1356107882"
"Miranda","Miranda","3.2500","-76.2500","Colombia","CO","COL","Cauca","minor","42639","1170512860"
"Osinniki","Osinniki","53.6167","87.3333","Russia","RU","RUS","Kemerovskaya Oblast’","","42454","1643107016"
"Cuchi","Cuchi","-14.6500","16.9000","Angola","AO","AGO","Cuando Cubango","","42974","1024135358"
"Bourg-en-Bresse","Bourg-en-Bresse","46.2056","5.2289","France","FR","FRA","Auvergne-Rhône-Alpes","minor","41248","1250397174"
"Itapa-Ekiti","Itapa-Ekiti","7.8131","5.3844","Nigeria","NG","NGA","Ekiti","","42509","1566000321"
"Akdağmadeni","Akdagmadeni","39.6658","35.8836","Turkey","TR","TUR","Yozgat","minor","42919","1792201700"
"Sidi Mohamed Lahmar","Sidi Mohamed Lahmar","34.7167","-6.2667","Morocco","MA","MAR","Rabat-Salé-Kénitra","","42637","1504538713"
"Limbdi","Limbdi","22.5685","71.7949","India","IN","IND","Gujarāt","","42769","1356357949"
"Loufan","Loufan","38.0694","111.7911","China","CN","CHN","Shanxi","minor","42520","1156551929"
"Atenco","Atenco","19.5167","-98.9167","Mexico","MX","MEX","México","minor","42739","1484838122"
"Palmas","Palmas","-26.4839","-51.9908","Brazil","BR","BRA","Paraná","","42888","1076528204"
"Samborondón","Samborondon","-1.9611","-79.7256","Ecuador","EC","ECU","Guayas","","42637","1218370377"
"Zvolen","Zvolen","48.5706","19.1175","Slovakia","SK","SVK","Banská Bystrica","minor","42476","1703102070"
"Eagle Mountain","Eagle Mountain","40.3137","-112.0114","United States","US","USA","Utah","","42905","1840018757"
"Sieradz","Sieradz","51.6000","18.7500","Poland","PL","POL","Łódzkie","minor","42120","1616121526"
"Shenjiatun","Shenjiatun","40.7695","114.8549","China","CN","CHN","Hebei","","42338","1156143117"
"Dreieich","Dreieich","50.0000","8.7000","Germany","DE","DEU","Hesse","","42091","1276481123"
"Huizen","Huizen","52.3000","5.2500","Netherlands","NL","NLD","Noord-Holland","minor","41090","1528154484"
"Lucena","Lucena","37.4000","-4.4833","Spain","ES","ESP","Andalusia","","42712","1724391367"
"Thomassique","Thomassique","19.0833","-71.8333","Haiti","HT","HTI","Centre","","42557","1332374782"
"Nurmijärvi","Nurmijarvi","60.4667","24.8083","Finland","FI","FIN","Uusimaa","minor","42709","1246678771"
"Awaji","Awaji","34.4333","134.9167","Japan","JP","JPN","Hyōgo","","42597","1392003425"
"Orlândia","Orlandia","-20.7203","-47.8867","Brazil","BR","BRA","São Paulo","","42678","1076690245"
"Majhaul","Majhaul","25.5570","86.1280","India","IN","IND","Bihār","","41264","1356299488"
"Kārsiyāng","Karsiyang","26.8800","88.2800","India","IN","IND","West Bengal","","42446","1356532333"
"Vawkavysk","Vawkavysk","53.1667","24.4667","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","42746","1112164201"
"Bukama","Bukama","-9.2000","25.8333","Congo (Kinshasa)","CD","COD","Haut-Lomami","","42718","1180047354"
"San Francisco Menéndez","San Francisco Menendez","13.8425","-90.0167","El Salvador","SV","SLV","Ahuachapán","","42607","1222612864"
"Proper Bansud","Proper Bansud","12.8594","121.4567","Philippines","PH","PHL","Oriental Mindoro","","42671","1608041757"
"Vera Cruz","Vera Cruz","-12.9600","-38.6089","Brazil","BR","BRA","Bahia","","42650","1076136150"
"Catonsville","Catonsville","39.2646","-76.7424","United States","US","USA","Maryland","","42789","1840005679"
"Obita","Obita","32.8252","129.8751","Japan","JP","JPN","Nagasaki","","41341","1392972610"
"Amberg","Amberg","49.4444","11.8483","Germany","DE","DEU","Bavaria","minor","41994","1276551955"
"Hillsborough","Hillsborough","40.4985","-74.6740","United States","US","USA","New Jersey","","42773","1840081699"
"Santa Quitéria","Santa Quiteria","-4.3319","-40.1569","Brazil","BR","BRA","Ceará","","42763","1076565535"
"Eisenach","Eisenach","50.9761","10.3206","Germany","DE","DEU","Thuringia","minor","42370","1276000880"
"Shetou","Shetou","23.9076","120.5992","Taiwan","TW","TWN","Changhua","","41613","1158243856"
"Santo Antônio da Platina","Santo Antonio da Platina","-23.2950","-50.0769","Brazil","BR","BRA","Paraná","","42707","1076118147"
"Aurich","Aurich","53.4714","7.4836","Germany","DE","DEU","Lower Saxony","minor","42544","1276424784"
"Cabo Bojador","Cabo Bojador","26.1333","-14.5000","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","","42651","1504003884"
"Woodbridge","Woodbridge","38.6409","-77.2580","United States","US","USA","Virginia","","42734","1840006106"
"Tucuran","Tucuran","7.8547","123.5783","Philippines","PH","PHL","Zamboanga del Sur","","42429","1608400809"
"Leigh","Leigh","53.4975","-2.5150","United Kingdom","GB","GBR","Wigan","","41275","1826341448"
"Fianga","Fianga","9.9153","15.1375","Chad","TD","TCD","Mayo-Kebbi-Est","","42606","1148228151"
"Mombaça","Mombaca","-5.7428","-39.6278","Brazil","BR","BRA","Piauí","","42690","1076957950"
"Sarıkamış","Sarikamis","40.3381","42.5731","Turkey","TR","TUR","Kars","minor","42683","1792341380"
"Ormond Beach","Ormond Beach","29.2985","-81.1014","United States","US","USA","Florida","","42707","1840015076"
"Currais Novos","Currais Novos","-6.2608","-36.5150","Brazil","BR","BRA","Rio Grande do Norte","","42652","1076200389"
"Sansanné-Mango","Sansanne-Mango","10.3556","0.4756","Togo","TG","TGO","Savanes","","42626","1768815814"
"Hwange","Hwange","-18.3647","26.5000","Zimbabwe","ZW","ZWE","Matabeleland North","","42581","1716416748"
"União","Uniao","-4.5858","-42.8639","Brazil","BR","BRA","Piauí","","42654","1076941290"
"Cacocum","Cacocum","20.7439","-76.3242","Cuba","CU","CUB","Holguín","minor","42623","1192155018"
"Avezzano","Avezzano","42.0411","13.4397","Italy","IT","ITA","Abruzzo","","42276","1380646749"
"Yaguate","Yaguate","18.3300","-70.1800","Dominican Republic","DO","DOM","Valdesia","","42325","1214644367"
"Moline","Moline","41.4821","-90.4920","United States","US","USA","Illinois","","42660","1840008181"
"Mariel","Mariel","22.9936","-82.7539","Cuba","CU","CUB","Artemisa","minor","42500","1192227631"
"Llanera","Llanera","15.6625","121.0197","Philippines","PH","PHL","Nueva Ecija","","42281","1608388009"
"Huber Heights","Huber Heights","39.8595","-84.1130","United States","US","USA","Ohio","","42650","1840003782"
"Kotabumi","Kotabumi","-4.8333","104.9000","Indonesia","ID","IDN","Lampung","minor","42366","1360396080"
"La Orotava","La Orotava","28.3667","-16.5167","Spain","ES","ESP","Canary Islands","","42434","1724852308"
"Edmonds","Edmonds","47.8115","-122.3533","United States","US","USA","Washington","","42622","1840037645"
"Dusheng","Dusheng","38.3804","116.5500","China","CN","CHN","Hebei","","41774","1156566669"
"Nuevo San Carlos","Nuevo San Carlos","14.6000","-91.7000","Guatemala","GT","GTM","Retalhuleu","minor","41956","1320293782"
"Nyunzu","Nyunzu","-5.9500","28.0333","Congo (Kinshasa)","CD","COD","Tanganyika","","42589","1180921995"
"La Tebaida","La Tebaida","4.4500","-75.8000","Colombia","CO","COL","Quindío","minor","42163","1170920731"
"Imperia","Imperia","43.8833","8.0333","Italy","IT","ITA","Liguria","minor","42602","1380075952"
"Charlottetown","Charlottetown","46.2403","-63.1347","Canada","CA","CAN","Prince Edward Island","admin","42602","1124897699"
"Köniz","Koniz","46.9333","7.4000","Switzerland","CH","CHE","Bern","","41784","1756022220"
"Balabac","Balabac","7.9833","117.0500","Philippines","PH","PHL","Palawan","","42527","1608341109"
"Manassas","Manassas","38.7479","-77.4838","United States","US","USA","Virginia","","42596","1840003839"
"Bhatpalli","Bhatpalli","18.4314","79.8605","India","IN","IND","Andhra Pradesh","","42387","1356737115"
"Maroantsetra","Maroantsetra","-15.4333","49.7500","Madagascar","MG","MDG","Toamasina","","42529","1450485257"
"Maulavi Bāzār","Maulavi Bazar","24.4808","91.7644","Bangladesh","BD","BGD","Sylhet","","41358","1050743879"
"Sanarate","Sanarate","14.7950","-90.1922","Guatemala","GT","GTM","El Progreso","minor","42422","1320227243"
"Thān","Than","22.5667","71.1833","India","IN","IND","Gujarāt","","42351","1356711533"
"Machalí","Machali","-34.1825","-70.6514","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","42572","1152901401"
"Kasai","Kasai","34.9333","134.8333","Japan","JP","JPN","Hyōgo","","42290","1392003357"
"Nāndūra Buzurg","Nandura Buzurg","20.8333","76.4597","India","IN","IND","Mahārāshtra","","42200","1356987352"
"Danville","Danville","36.5831","-79.4088","United States","US","USA","Virginia","","42556","1840003878"
"Villa Bisonó","Villa Bisono","19.5600","-70.8700","Dominican Republic","DO","DOM","Cibao Norte","","42092","1214712836"
"Akaiwa","Akaiwa","34.7553","134.0189","Japan","JP","JPN","Okayama","","42341","1392003260"
"El Charco","El Charco","2.4775","-78.1111","Colombia","CO","COL","Nariño","minor","42525","1170354433"
"Liloy","Liloy","8.1228","122.6736","Philippines","PH","PHL","Zamboanga del Norte","","42213","1608049613"
"Tineghir","Tineghir","31.5147","-5.5328","Morocco","MA","MAR","Drâa-Tafilalet","","42044","1504760525"
"San Rafael del Sur","San Rafael del Sur","11.8458","-86.4389","Nicaragua","NI","NIC","Managua","minor","42417","1558570848"
"Araioses","Araioses","-2.8900","-41.9028","Brazil","BR","BRA","Maranhão","","42505","1076312210"
"Shebekino","Shebekino","50.4167","36.9000","Russia","RU","RUS","Belgorodskaya Oblast’","","42465","1643443268"
"Castres","Castres","43.6000","2.2500","France","FR","FRA","Occitanie","minor","42079","1250134275"
"Cortlandt","Cortlandt","41.2552","-73.9018","United States","US","USA","New York","","42495","1840087347"
"Mascouche","Mascouche","45.7500","-73.6000","Canada","CA","CAN","Quebec","","42491","1124001580"
"Cuihua","Cuihua","27.7527","103.8906","China","CN","CHN","Yunnan","minor","42262","1156070831"
"Santa Rita","Santa Rita","11.4522","124.9408","Philippines","PH","PHL","Samar","","42384","1608542075"
"Futtsu","Futtsu","35.3041","139.8570","Japan","JP","JPN","Chiba","","42280","1392321461"
"Carranglan","Carranglan","15.9608","121.0631","Philippines","PH","PHL","Nueva Ecija","","42420","1608667517"
"Enfield","Enfield","41.9839","-72.5548","United States","US","USA","Connecticut","","42480","1840033630"
"Santa Cruz","Santa Cruz","13.0831","120.7192","Philippines","PH","PHL","Occidental Mindoro","","42417","1608892530"
"Alerce","Alerce","-41.4000","-72.9333","Chile","CL","CHL","Los Lagos","","42267","1152663651"
"West Vancouver","West Vancouver","49.3667","-123.1667","Canada","CA","CAN","British Columbia","","42473","1124001824"
"Capão da Canoa","Capao da Canoa","-29.7608","-50.0300","Brazil","BR","BRA","Rio Grande do Sul","","42040","1076749939"
"Pahrump","Pahrump","36.2235","-115.9974","United States","US","USA","Nevada","","42471","1840018821"
"Polanco","Polanco","8.5319","123.3631","Philippines","PH","PHL","Zamboanga del Norte","","42265","1608083261"
"Belleville","Belleville","38.5164","-89.9900","United States","US","USA","Illinois","","42463","1840007486"
"Quezon","Quezon","15.5542","120.8111","Philippines","PH","PHL","Nueva Ecija","","41845","1608944332"
"Shepetivka","Shepetivka","50.1833","27.0667","Ukraine","UA","UKR","Khmelnytska Oblast","minor","41415","1804965832"
"Beverly","Beverly","42.5681","-70.8627","United States","US","USA","Massachusetts","","42448","1840000411"
"Shū","Shu","43.5983","73.7614","Kazakhstan","KZ","KAZ","","","42439","1398834769"
"President Quirino","President Quirino","6.7000","124.7333","Philippines","PH","PHL","Sultan Kudarat","minor","42244","1608129077"
"Tomé","Tome","-36.6167","-72.9500","Chile","CL","CHL","Biobío","","42312","1152166653"
"Midland","Midland","43.6241","-84.2319","United States","US","USA","Michigan","","42425","1840002660"
"Tactic","Tactic","15.3167","-90.3511","Guatemala","GT","GTM","Alta Verapaz","minor","41930","1320105267"
"Yangcunzai","Yangcunzai","23.4338","114.4664","China","CN","CHN","Guangdong","","41958","1156087224"
"Coppell","Coppell","32.9639","-96.9903","United States","US","USA","Texas","","42420","1840019439"
"Salaberry-de-Valleyfield","Salaberry-de-Valleyfield","45.2500","-74.1300","Canada","CA","CAN","Quebec","","42410","1124638758"
"Balykchy","Balykchy","42.4611","76.1803","Kyrgyzstan","KG","KGZ","Ysyk-Köl","","42380","1417967917"
"Uden","Uden","51.6500","5.6167","Netherlands","NL","NLD","Noord-Brabant","minor","41782","1528113509"
"Channelview","Channelview","29.7914","-95.1144","United States","US","USA","Texas","","42394","1840018253"
"Myanaung","Myanaung","18.2833","95.3167","Myanmar","MM","MMR","Ayeyarwady","","42252","1104426829"
"São Lourenço","Sao Lourenco","-22.1158","-45.0539","Brazil","BR","BRA","Minas Gerais","","41657","1076261938"
"Liantang","Liantang","22.9352","111.7237","China","CN","CHN","Guangdong","","42141","1156033321"
"Itápolis","Itapolis","-21.5958","-48.8128","Brazil","BR","BRA","São Paulo","","42343","1076174719"
"Wancheng","Wancheng","37.6273","114.5324","China","CN","CHN","Hebei","","41080","1156955316"
"Claveria","Claveria","12.9035","123.2457","Philippines","PH","PHL","Masbate","minor","42142","1608229626"
"Machang","Machang","26.5634","106.1103","China","CN","CHN","Guizhou","","42185","1156849293"
"Puyallup","Puyallup","47.1793","-122.2902","United States","US","USA","Washington","","42366","1840019859"
"Niquelândia","Niquelandia","-14.4739","-48.4600","Brazil","BR","BRA","Goiás","","42361","1076682103"
"Porangatu","Porangatu","-13.4408","-49.1489","Brazil","BR","BRA","Goiás","","42355","1076806104"
"Nāthdwāra","Nathdwara","24.9300","73.8200","India","IN","IND","Rājasthān","","42016","1356606458"
"Nalhāti","Nalhati","24.3000","87.8200","India","IN","IND","West Bengal","","41534","1356705146"
"Aritao","Aritao","16.2973","121.0338","Philippines","PH","PHL","Nueva Vizcaya","","42197","1608014234"
"Quesada","Quesada","10.3441","-84.4199","Costa Rica","CR","CRI","Alajuela","","42060","1188199888"
"Ban Wat Lak Hok","Ban Wat Lak Hok","13.5633","100.0564","Thailand","TH","THA","Ratchaburi","","41884","1764000965"
"Nakano","Nakano","36.7420","138.3694","Japan","JP","JPN","Nagano","","41972","1392139023"
"Qəzyan","Qazyan","39.2914","46.6689","Azerbaijan","AZ","AZE","Qubadlı","","42288","1031867232"
"Rouyn-Noranda","Rouyn-Noranda","48.2333","-79.0167","Canada","CA","CAN","Quebec","","42334","1124267752"
"Qā’en","Qa'en","33.7256","59.1897","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","42323","1364089791"
"Seevetal","Seevetal","53.3833","10.0333","Germany","DE","DEU","Lower Saxony","","41931","1276709038"
"San Donà di Piave","San Dona di Piave","45.6298","12.5641","Italy","IT","ITA","Veneto","","41794","1380477303"
"Rancho Palos Verdes","Rancho Palos Verdes","33.7554","-118.3634","United States","US","USA","California","","42316","1840020508"
"Homburg","Homburg","49.3167","7.3333","Germany","DE","DEU","Saarland","minor","41811","1276520056"
"Maniwa","Maniwa","35.0758","133.7525","Japan","JP","JPN","Okayama","","42259","1392003241"
"Bilāsipāra","Bilasipara","26.2328","90.2341","India","IN","IND","Assam","","41145","1356018787"
"Beitbridge","Beitbridge","-22.2167","30.0000","Zimbabwe","ZW","ZWE","Matabeleland South","","41767","1716929295"
"Remanso","Remanso","-9.6219","-42.0808","Brazil","BR","BRA","Bahia","","42275","1076051035"
"Coronel Suárez","Coronel Suarez","-37.4667","-61.9333","Argentina","AR","ARG","Buenos Aires","minor","42276","1032444981"
"Pratāpgarh","Pratapgarh","24.0300","74.7800","India","IN","IND","Rājasthān","","42079","1356078917"
"Morarano Chrome","Morarano Chrome","-17.7500","48.1667","Madagascar","MG","MDG","Toamasina","","42147","1450652075"
"Ansbach","Ansbach","49.3000","10.5833","Germany","DE","DEU","Bavaria","minor","41847","1276572551"
"Matnog","Matnog","12.5856","124.0856","Philippines","PH","PHL","Sorsogon","","41989","1608099282"
"Nārāyanpet","Narayanpet","16.7445","77.4960","India","IN","IND","Andhra Pradesh","","41752","1356282239"
"Bacong","Bacong","9.2464","123.2948","Philippines","PH","PHL","Negros Oriental","","41207","1608952649"
"Dacun","Dacun","34.7201","109.0549","China","CN","CHN","Shaanxi","","41763","1156415466"
"Kollo","Kollo","13.0167","1.8833","Niger","NE","NER","Tillabéri","","42192","1562112321"
"Macerata","Macerata","43.3003","13.4533","Italy","IT","ITA","Marche","minor","41776","1380412139"
"Gay","Gay","51.4167","58.4833","Russia","RU","RUS","Orenburgskaya Oblast’","","41619","1643216197"
"Mozdok","Mozdok","43.7333","44.7000","Russia","RU","RUS","North Ossetia","","42155","1643138287"
"Mampong","Mampong","7.0667","-1.4000","Ghana","GH","GHA","Ashanti","","42037","1288185670"
"Sarakhs","Sarakhs","36.5450","61.1578","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","42179","1364504156"
"Alcalde Díaz","Alcalde Diaz","9.1200","-79.5500","Panama","PA","PAN","Panamá","minor","41292","1591462898"
"Sbiba","Sbiba","35.5467","9.0736","Tunisia","TN","TUN","Kasserine","","42091","1788507735"
"Puerto Real","Puerto Real","36.5292","-6.1919","Spain","ES","ESP","Andalusia","","41963","1724134848"
"Nordhausen","Nordhausen","51.5050","10.7911","Germany","DE","DEU","Thuringia","minor","41791","1276946322"
"Safonovo","Safonovo","55.1167","33.2500","Russia","RU","RUS","Smolenskaya Oblast’","minor","42147","1643281098"
"Diyadin","Diyadin","39.5400","43.6772","Turkey","TR","TUR","Ağrı","minor","42123","1792559543"
"Puerto del Rosario","Puerto del Rosario","28.5000","-13.8667","Spain","ES","ESP","Canary Islands","","42024","1724461156"
"Saidpur Dabra","Saidpur Dabra","25.3207","87.1793","India","IN","IND","Bihār","","41734","1356771686"
"Golāghāt","Golaghat","26.5200","93.9700","India","IN","IND","Assam","","41489","1356013906"
"Schwabach","Schwabach","49.3292","11.0208","Germany","DE","DEU","Bavaria","minor","41146","1276017987"
"Minamishimabara","Minamishimabara","32.6667","130.3000","Japan","JP","JPN","Nagasaki","","41903","1392999974"
"Monterotondo","Monterotondo","42.0500","12.6167","Italy","IT","ITA","Lazio","","41144","1380326356"
"Greenock","Greenock","55.9500","-4.7650","United Kingdom","GB","GBR","Inverclyde","","41990","1826864377"
"Sidrolândia","Sidrolandia","-20.9319","-54.9608","Brazil","BR","BRA","Mato Grosso do Sul","","42132","1076992570"
"Montenegro","Montenegro","4.5671","-75.7504","Colombia","CO","COL","Quindío","minor","41857","1170267706"
"Memāri","Memari","23.2000","88.1200","India","IN","IND","West Bengal","","41451","1356560015"
"Amontada","Amontada","-3.3608","-39.8308","Brazil","BR","BRA","Ceará","","42098","1076240159"
"Cáceres","Caceres","7.6667","-75.3333","Colombia","CO","COL","Antioquia","minor","42112","1170859204"
"Puerto Galera","Puerto Galera","13.5000","120.9542","Philippines","PH","PHL","Oriental Mindoro","","41961","1608505295"
"Lingquan","Lingquan","36.9985","110.8417","China","CN","CHN","Shanxi","minor","41996","1156240475"
"Coachella","Coachella","33.6905","-116.1430","United States","US","USA","California","","42123","1840019294"
"Monchegorsk","Monchegorsk","67.9333","32.9167","Russia","RU","RUS","Murmanskaya Oblast’","","42099","1643634027"
"Dronten","Dronten","52.5167","5.7167","Netherlands","NL","NLD","Flevoland","minor","42011","1528712239"
"Soran","Soran","49.7833","72.8500","Kazakhstan","KZ","KAZ","","","42058","1398259748"
"Anda","Anda","16.2896","119.9491","Philippines","PH","PHL","Pangasinan","","41548","1608003034"
"Litian Gezhuang","Litian Gezhuang","39.8151","119.0219","China","CN","CHN","Hebei","","41676","1156516148"
"Coburg","Coburg","50.2667","10.9667","Germany","DE","DEU","Bavaria","minor","41249","1276428862"
"Poti","Poti","42.1500","41.6667","Georgia","GE","GEO","Samegrelo-Zemo Svaneti","minor","41465","1268764439"
"Sarāb","Sarab","37.9414","47.5367","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","42057","1364335741"
"Su-ngai Kolok","Su-ngai Kolok","6.0333","101.9667","Thailand","TH","THA","Narathiwat","minor","41590","1764527716"
"Yagoua","Yagoua","10.3428","15.2406","Cameroon","CM","CMR","Extrême-Nord","","41957","1120388576"
"Linton Hall","Linton Hall","38.7551","-77.5750","United States","US","USA","Virginia","","42089","1840006100"
"Barcellona-Pozzo di Gotto","Barcellona-Pozzo di Gotto","38.1500","15.2167","Italy","IT","ITA","Sicilia","","41389","1380260535"
"Coyuca de Catalán","Coyuca de Catalan","18.3256","-100.6992","Mexico","MX","MEX","Guerrero","minor","42069","1484460732"
"Korenovsk","Korenovsk","45.4686","39.4519","Russia","RU","RUS","Krasnodarskiy Kray","minor","42019","1643474762"
"San José de Bocay","San Jose de Bocay","13.5428","-85.5397","Nicaragua","NI","NIC","Jinotega","minor","42029","1558566609"
"Myedu","Myedu","23.1833","95.4000","Myanmar","MM","MMR","Sagaing","","42000","1104497959"
"Peachtree Corners","Peachtree Corners","33.9670","-84.2320","United States","US","USA","Georgia","","42065","1840029447"
"Woodlawn","Woodlawn","39.3054","-76.7489","United States","US","USA","Maryland","","42053","1840005697"
"Xinleitou","Xinleitou","37.9752","115.2106","China","CN","CHN","Hebei","","40761","1156223072"
"Soliman","Soliman","36.7000","10.4833","Tunisia","TN","TUN","Nabeul","","41579","1788428570"
"Kibawe","Kibawe","7.5678","124.9903","Philippines","PH","PHL","Bukidnon","","41897","1608899134"
"Tequila","Tequila","20.8794","-103.8356","Mexico","MX","MEX","Jalisco","minor","42009","1484763049"
"Moatize","Moatize","-16.1167","33.7333","Mozambique","MZ","MOZ","Tete","","42000","1508074512"
"Merano","Merano","46.6696","11.1594","Italy","IT","ITA","Trentino-Alto Adige","","40485","1380000095"
"Santa Rosa del Sur","Santa Rosa del Sur","7.9667","-74.0500","Colombia","CO","COL","Bolívar","minor","42003","1170579856"
"Vasto","Vasto","42.1116","14.7082","Italy","IT","ITA","Abruzzo","","41433","1380806117"
"Shimotsuma","Shimotsuma","36.1844","139.9675","Japan","JP","JPN","Ibaraki","","41499","1392100062"
"Yinggen","Yinggen","19.0372","109.8283","China","CN","CHN","Hainan","minor","41835","1156066518"
"Fenoarivo Atsinanana","Fenoarivo Atsinanana","-17.3814","49.4092","Madagascar","MG","MDG","Toamasina","minor","41734","1450763584"
"Kolchugino","Kolchugino","56.3167","39.3667","Russia","RU","RUS","Vladimirskaya Oblast’","","41953","1643216490"
"Billerica","Billerica","42.5587","-71.2673","United States","US","USA","Massachusetts","","41995","1840053475"
"Beigang","Beigang","23.5667","120.3000","Taiwan","TW","TWN","Yunlin","","40988","1158593332"
"Rajaori","Rajaori","33.3800","74.3100","India","IN","IND","Jammu and Kashmīr","","41552","1356109150"
"Tādif","Tadif","36.3481","37.5308","Syria","SY","SYR","Ḩalab","minor","41786","1760362095"
"Spassk-Dal’niy","Spassk-Dal'niy","44.6000","132.8167","Russia","RU","RUS","Primorskiy Kray","","41127","1643943494"
"Kirchheim unter Teck","Kirchheim unter Teck","48.6483","9.4511","Germany","DE","DEU","Baden-Württemberg","","40954","1276389368"
"Nürtingen","Nurtingen","48.6333","9.3333","Germany","DE","DEU","Baden-Württemberg","","41093","1276408465"
"Pine Bluff","Pine Bluff","34.2116","-92.0173","United States","US","USA","Arkansas","","41964","1840014699"
"Vargem Grande do Sul","Vargem Grande do Sul","-21.8319","-46.8939","Brazil","BR","BRA","São Paulo","","41807","1076083512"
"Pāmban","Pamban","9.2790","79.2109","India","IN","IND","Tamil Nādu","","40822","1356135474"
"Spanish Fork","Spanish Fork","40.1101","-111.6405","United States","US","USA","Utah","","41953","1840021398"
"Hongfengcun","Hongfengcun","21.9231","111.9329","China","CN","CHN","Guangdong","","40985","1156556630"
"San","San","13.3004","-4.9000","Mali","ML","MLI","Ségou","minor","41386","1466198810"
"Medianeira","Medianeira","-25.2950","-54.0939","Brazil","BR","BRA","Paraná","","41817","1076066608"
"Ishaka","Ishaka","-0.5450","30.1383","Uganda","UG","UGA","Buhweju","","41063","1800035927"
"Tulun","Tulun","54.5667","100.5667","Russia","RU","RUS","Irkutskaya Oblast’","","41640","1643545822"
"Wunstorf","Wunstorf","52.4275","9.4294","Germany","DE","DEU","Lower Saxony","","41594","1276079483"
"Pedra Branca","Pedra Branca","-5.4539","-39.7169","Brazil","BR","BRA","Ceará","","41890","1076190370"
"Bragadiru","Bragadiru","44.3708","25.9750","Romania","RO","ROU","Ilfov","","40080","1642514395"
"Naga","Naga","7.7978","122.6944","Philippines","PH","PHL","Zamboanga Sibugay","","41743","1608430919"
"San Pedro Necta","San Pedro Necta","15.5000","-91.7667","Guatemala","GT","GTM","Huehuetenango","minor","41562","1320888118"
"Kasibu","Kasibu","16.3181","121.2958","Philippines","PH","PHL","Nueva Vizcaya","","41776","1608558514"
"Daram","Daram","11.6341","124.7947","Philippines","PH","PHL","Samar","","41608","1608081438"
"Bayt al Faqīh","Bayt al Faqih","14.5161","43.3244","Yemen","YE","YEM","Al Ḩudaydah","minor","41652","1887465006"
"Jaraguá","Jaragua","-15.7569","-49.3339","Brazil","BR","BRA","Goiás","","41870","1076016135"
"Nanbaishezhen","Nanbaishezhen","37.7526","114.8524","China","CN","CHN","Hebei","","41138","1156242216"
"Colomiers","Colomiers","43.6139","1.3367","France","FR","FRA","Occitanie","","39968","1250882748"
"Sassuolo","Sassuolo","44.5517","10.7856","Italy","IT","ITA","Emilia-Romagna","","40826","1380620922"
"San Miguel","San Miguel","8.9322","126.0403","Philippines","PH","PHL","Surigao del Sur","","41809","1608334875"
"Jardinópolis","Jardinopolis","-21.0178","-47.7639","Brazil","BR","BRA","São Paulo","","41799","1076497660"
"Tramandaí","Tramandai","-29.9850","-50.1339","Brazil","BR","BRA","Rio Grande do Sul","","41585","1076409044"
"Socorro","Socorro","13.0583","121.4117","Philippines","PH","PHL","Oriental Mindoro","","41585","1608390330"
"Wijchen","Wijchen","51.8000","5.7333","Netherlands","NL","NLD","Gelderland","minor","41261","1528300433"
"Ihnāsyā al Madīnah","Ihnasya al Madinah","29.0833","30.9333","Egypt","EG","EGY","Banī Suwayf","","40001","1818108314"
"Tabogon","Tabogon","10.9333","124.0333","Philippines","PH","PHL","Cebu","","41432","1608318557"
"Dhamdāha","Dhamdaha","25.7435","87.1835","India","IN","IND","Bihār","","40916","1356885272"
"Sānand","Sanand","22.9800","72.3800","India","IN","IND","Gujarāt","","41530","1356038288"
"Oqtosh Shahri","Oqtosh Shahri","39.9269","65.9256","Uzbekistan","UZ","UZB","Samarqand","minor","41600","1860821760"
"Satka","Satka","55.0500","59.0333","Russia","RU","RUS","Chelyabinskaya Oblast’","","41798","1643644802"
"Santa Cruz","Santa Cruz","17.0853","120.4553","Philippines","PH","PHL","Ilocos Sur","","41366","1608559758"
"Santa Maria da Vitória","Santa Maria da Vitoria","-13.3978","-44.1978","Brazil","BR","BRA","Bahia","","41795","1076607443"
"Ciudad Vieja","Ciudad Vieja","14.5233","-90.7667","Guatemala","GT","GTM","Sacatepéquez","minor","41010","1320909841"
"Tagudin","Tagudin","16.9361","120.4464","Philippines","PH","PHL","Ilocos Sur","","41538","1608233490"
"Eberswalde","Eberswalde","52.8333","13.8333","Germany","DE","DEU","Brandenburg","minor","41103","1276311453"
"Chojnice","Chojnice","53.7000","17.5500","Poland","PL","POL","Pomorskie","minor","39937","1616746687"
"Amuntai","Amuntai","-2.4177","115.2494","Indonesia","ID","IDN","Kalimantan Selatan","minor","41554","1360306329"
"Wujie","Wujie","24.7000","121.7833","Taiwan","TW","TWN","Yilan","","40750","1158382320"
"Fitchburg","Fitchburg","42.5912","-71.8156","United States","US","USA","Massachusetts","","41796","1840000435"
"Hempfield","Hempfield","40.2847","-79.5841","United States","US","USA","Pennsylvania","","41795","1840151151"
"Nanbei","Nanbei","35.3167","139.1000","Japan","JP","JPN","Kanagawa","","41259","1392514585"
"Kearny","Kearny","40.7528","-74.1202","United States","US","USA","New Jersey","","41790","1840003601"
"Timmins","Timmins","48.4667","-81.3333","Canada","CA","CAN","Ontario","","41788","1124760634"
"Mikołów","Mikolow","50.1667","18.9000","Poland","PL","POL","Śląskie","minor","41266","1616187671"
"Malilipot","Malilipot","13.3167","123.7333","Philippines","PH","PHL","Albay","","40857","1608138791"
"Königswinter","Konigswinter","50.6736","7.1947","Germany","DE","DEU","North Rhine-Westphalia","","41243","1276951419"
"Indi","Indi","17.1773","75.9526","India","IN","IND","Karnātaka","","41556","1356047992"
"Patzicía","Patzicia","14.6336","-90.9268","Guatemala","GT","GTM","Chimaltenango","minor","40848","1320491635"
"Pīleru","Pileru","13.6549","78.9471","India","IN","IND","Andhra Pradesh","","41489","1356034538"
"Buddh Gaya","Buddh Gaya","24.6981","84.9869","India","IN","IND","Bihār","","40731","1356203235"
"Dacun","Dacun","23.9911","120.5394","Taiwan","TW","TWN","Changhua","","40459","1158383737"
"Myski","Myski","53.7000","87.8167","Russia","RU","RUS","Kemerovskaya Oblast’","","41379","1643972343"
"Santa Cruz de Los Taques","Santa Cruz de Los Taques","11.8231","-70.2535","Venezuela","VE","VEN","Falcón","minor","41579","1862415425"
"Rājgīr","Rajgir","25.0300","85.4200","India","IN","IND","Bihār","","41587","1356823690"
"Gonzaga","Gonzaga","18.2614","121.9969","Philippines","PH","PHL","Cagayan","","41680","1608287955"
"Borba","Borba","-4.3878","-59.5939","Brazil","BR","BRA","Amazonas","","41748","1076143096"
"Strezhevoy","Strezhevoy","60.7333","77.5833","Russia","RU","RUS","Tomskaya Oblast’","","41541","1643011459"
"Pueblo Nuevo","Pueblo Nuevo","8.5008","-75.5072","Colombia","CO","COL","Córdoba","minor","41684","1170000348"
"Kwidzyn","Kwidzyn","53.7358","18.9308","Poland","PL","POL","Pomorskie","minor","40008","1616851475"
"Podilsk","Podilsk","47.7419","29.5350","Ukraine","UA","UKR","Odeska Oblast","minor","40155","1804566477"
"Varash","Varash","51.3444","25.8508","Ukraine","UA","UKR","Rivnenska Oblast","minor","41711","1804357890"
"Panchimalco","Panchimalco","13.6167","-89.1833","El Salvador","SV","SLV","San Salvador","","41260","1222019251"
"Yakacık","Yakacik","36.7500","36.2167","Turkey","TR","TUR","Hatay","minor","41409","1792903398"
"Sarandë","Sarande","39.8750","20.0100","Albania","AL","ALB","Vlorë","","41000","1008879697"
"Rāmganj Mandi","Ramganj Mandi","24.6472","75.9444","India","IN","IND","Rājasthān","","41328","1356742923"
"Ust’-Kut","Ust'-Kut","56.7833","105.6333","Russia","RU","RUS","Irkutskaya Oblast’","","41689","1643157794"
"Santo Domingo","Santo Domingo","9.9880","-84.0686","Costa Rica","CR","CRI","Heredia","","40072","1188708621"
"Dohazāri","Dohazari","22.1628","92.0728","Bangladesh","BD","BGD","Chattogram","","40147","1050014013"
"Gatunda","Gatunda","-1.4350","30.0211","Rwanda","RW","RWA","Eastern Province","","41000","1646499404"
"Świnoujście","Swinoujscie","53.9167","14.2500","Poland","PL","POL","Zachodniopomorskie","minor","41479","1616487084"
"Sipe Sipe","Sipe Sipe","-17.4500","-66.3833","Bolivia","BO","BOL","Cochabamba","","41571","1068181864"
"Jayamkondacholapuram","Jayamkondacholapuram","11.2127","79.3637","India","IN","IND","Tamil Nādu","","41273","1356333547"
"Nanmeng","Nanmeng","39.1773","116.3751","China","CN","CHN","Hebei","","41019","1156209822"
"Maplewood","Maplewood","44.9842","-93.0247","United States","US","USA","Minnesota","","41673","1840008937"
"Yangtangxu","Yangtangxu","21.6172","110.0319","China","CN","CHN","Guangdong","","40616","1156069203"
"Patulul","Patulul","14.4167","-91.1667","Guatemala","GT","GTM","Suchitepéquez","minor","41543","1320760305"
"Arraiján","Arraijan","8.9500","-79.6500","Panama","PA","PAN","Panamá Oeste","minor","41041","1591458943"
"Tuchín","Tuchin","9.1858","-75.5553","Colombia","CO","COL","Córdoba","minor","41345","1170331155"
"Herstal","Herstal","50.6667","5.6333","Belgium","BE","BEL","Wallonia","","39958","1056571016"
"Yuzawa","Yuzawa","39.1641","140.4948","Japan","JP","JPN","Akita","","41601","1392884972"
"Lidköping","Lidkoping","58.5000","13.1833","Sweden","SE","SWE","Västra Götaland","minor","40000","1752263259"
"Saint-Germain-en-Laye","Saint-Germain-en-Laye","48.8989","2.0938","France","FR","FRA","Île-de-France","minor","40806","1250764383"
"Cantù","Cantu","45.7333","9.1333","Italy","IT","ITA","Lombardy","","39932","1380683045"
"Copán","Copan","14.8375","-89.1425","Honduras","HN","HND","Copán","","41542","1340766034"
"Fredericia","Fredericia","55.5667","9.7500","Denmark","DK","DNK","Syddanmark","minor","40886","1208515471"
"Peddapalli","Peddapalli","18.6162","79.3832","India","IN","IND","Andhra Pradesh","","41171","1356814846"
"Paranaíba","Paranaiba","-19.6769","-51.1908","Brazil","BR","BRA","Mato Grosso do Sul","","41626","1076355277"
"Mableton","Mableton","33.8133","-84.5655","United States","US","USA","Georgia","","41632","1840013690"
"Sorel-Tracy","Sorel-Tracy","46.0333","-73.1167","Canada","CA","CAN","Quebec","","41629","1124000182"
"Naini Tal","Naini Tal","29.3919","79.4542","India","IN","IND","Uttarākhand","","41377","1356070793"
"Masaki","Masaki","33.7833","132.7167","Japan","JP","JPN","Ehime","","39681","1392691084"
"Pilate","Pilate","19.6667","-72.5500","Haiti","HT","HTI","Nord","","40445","1332272986"
"Karabulak","Karabulak","43.3167","44.9167","Russia","RU","RUS","Ingushetiya","","41469","1643010318"
"Schertz","Schertz","29.5650","-98.2535","United States","US","USA","Texas","","41607","1840022213"
"Ambohitrimanjaka","Ambohitrimanjaka","-18.8667","47.4333","Madagascar","MG","MDG","Antananarivo","","41604","1450986963"
"Ladispoli","Ladispoli","41.9544","12.0742","Italy","IT","ITA","Lazio","","41604","1380799057"
"Yarumal","Yarumal","7.0000","-75.5000","Colombia","CO","COL","Antioquia","minor","41542","1170371045"
"Newnan","Newnan","33.3767","-84.7761","United States","US","USA","Georgia","","41598","1840014833"
"Thionville","Thionville","49.3589","6.1692","France","FR","FRA","Grand Est","minor","40778","1250564736"
"Ayagöz","Ayagoz","47.9667","80.4333","Kazakhstan","KZ","KAZ","","minor","41593","1398040009"
"Kitaibaraki","Kitaibaraki","36.8019","140.7510","Japan","JP","JPN","Ibaraki","","41352","1392092741"
"Amancio","Amancio","20.8197","-77.5844","Cuba","CU","CUB","Las Tunas","minor","41523","1192932562"
"Almenara","Almenara","-16.1005","-40.7101","Brazil","BR","BRA","Minas Gerais","","41552","1076953924"
"Hellevoetsluis","Hellevoetsluis","51.8167","4.1333","Netherlands","NL","NLD","Zuid-Holland","minor","40312","1528739669"
"Gaolincun","Gaolincun","39.0887","115.6617","China","CN","CHN","Hebei","","40729","1156112859"
"Clermont","Clermont","28.5325","-81.7208","United States","US","USA","Florida","","41562","1840015080"
"Mondragon","Mondragon","12.5167","124.7500","Philippines","PH","PHL","Northern Samar","","41415","1608295984"
"North Fort Myers","North Fort Myers","26.7244","-81.8485","United States","US","USA","Florida","","41551","1840014222"
"Schwäbisch Hall","Schwabisch Hall","49.1122","9.7375","Germany","DE","DEU","Baden-Württemberg","minor","41141","1276556451"
"Baichigan","Baichigan","39.5275","115.8564","China","CN","CHN","Hebei","","40748","1156121364"
"Tālcher","Talcher","20.9500","85.2300","India","IN","IND","Odisha","","40841","1356034796"
"Geel","Geel","51.1667","5.0000","Belgium","BE","BEL","Flanders","","41146","1056650070"
"Sinanpaşa","Sinanpasa","38.7500","30.2500","Turkey","TR","TUR","Afyonkarahisar","minor","41468","1792852826"
"Maayon","Maayon","11.3903","122.7828","Philippines","PH","PHL","Capiz","","41226","1608644841"
"Alcala","Alcala","17.9031","121.6556","Philippines","PH","PHL","Cagayan","","41295","1608450739"
"Paliā Kalān","Palia Kalan","28.4500","80.5833","India","IN","IND","Uttar Pradesh","","41126","1356677252"
"Verkhnyaya Salda","Verkhnyaya Salda","58.0456","60.5533","Russia","RU","RUS","Sverdlovskaya Oblast’","","41484","1643609151"
"Marlborough","Marlborough","42.3494","-71.5468","United States","US","USA","Massachusetts","","41505","1840000427"
"Vineyard","Vineyard","38.4740","-121.3240","United States","US","USA","California","","41505","1840018846"
"Erzin","Erzin","36.9533","36.2033","Turkey","TR","TUR","Hatay","minor","41368","1792613172"
"Agar","Agar","23.7118","76.0157","India","IN","IND","Madhya Pradesh","","41193","1356322371"
"Santo Tomas","Santo Tomas","16.2833","120.3833","Philippines","PH","PHL","La Union","","40846","1608081597"
"Komoro","Komoro","36.3275","138.4258","Japan","JP","JPN","Nagano","","41066","1392003412"
"Morrinhos","Morrinhos","-17.7319","-49.1008","Brazil","BR","BRA","Goiás","","41460","1076391164"
"Bury Saint Edmunds","Bury Saint Edmunds","52.2474","0.7183","United Kingdom","GB","GBR","Suffolk","","40664","1826257341"
"Zduńska Wola","Zdunska Wola","51.6000","18.9667","Poland","PL","POL","Łódzkie","minor","39846","1616720651"
"Novo Horizonte","Novo Horizonte","-21.4678","-49.2208","Brazil","BR","BRA","São Paulo","","41414","1076842025"
"Leinfelden-Echterdingen","Leinfelden-Echterdingen","48.6928","9.1428","Germany","DE","DEU","Baden-Württemberg","","40100","1276447188"
"New Tecumseth","New Tecumseth","44.0833","-79.7500","Canada","CA","CAN","Ontario","","41439","1124001571"
"Mersa","Mersa","11.6667","39.6583","Ethiopia","ET","ETH","Āmara","","41253","1231844606"
"Tabarka","Tabarka","36.9544","8.7581","Tunisia","TN","TUN","Jendouba","","41293","1788433154"
"Coram","Coram","40.8812","-73.0059","United States","US","USA","New York","","41431","1840005032"
"Nowa Sól","Nowa Sol","51.8000","15.7167","Poland","PL","POL","Lubuskie","minor","39721","1616040847"
"Villa Ángela","Villa Angela","-27.5833","-60.7167","Argentina","AR","ARG","Chaco","minor","41403","1032039944"
"Lasam","Lasam","18.0667","121.6000","Philippines","PH","PHL","Cagayan","","41225","1608352483"
"Szigetszentmiklós","Szigetszentmiklos","47.3456","19.0483","Hungary","HU","HUN","Pest","minor","40519","1348768623"
"Antequera","Antequera","37.0183","-4.5597","Spain","ES","ESP","Andalusia","","41348","1724357550"
"Sovetsk","Sovetsk","55.0808","21.8864","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","40486","1643001581"
"Patti","Patti","31.2800","74.8600","India","IN","IND","Punjab","","40976","1356157475"
"Sherghāti","Sherghati","24.5700","84.7800","India","IN","IND","Bihār","","40666","1356992433"
"La Libertad","La Libertad","10.0333","123.2167","Philippines","PH","PHL","Negros Oriental","","41089","1608768196"
"Compiègne","Compiegne","49.4149","2.8231","France","FR","FRA","Hauts-de-France","minor","40615","1250876040"
"Hückelhoven","Huckelhoven","51.0608","6.2197","Germany","DE","DEU","North Rhine-Westphalia","","40712","1276391695"
"Tanxia","Tanxia","23.9475","115.5361","China","CN","CHN","Guangdong","","41181","1156351090"
"Tiegan","Tiegan","38.1536","115.3248","China","CN","CHN","Hebei","","40683","1156343188"
"Marlboro","Marlboro","40.3427","-74.2567","United States","US","USA","New Jersey","","41356","1840081649"
"El Tocuyo","El Tocuyo","9.7822","-69.7931","Venezuela","VE","VEN","Lara","minor","41327","1862715800"
"Qiryat Yam","Qiryat Yam","32.8333","35.0667","Israel","IL","ISR","Haifa","","39416","1376460777"
"Vavveru","Vavveru","14.5594","79.8725","India","IN","IND","Andhra Pradesh","","40776","1356094343"
"Guácimo","Guacimo","10.2025","-83.6223","Costa Rica","CR","CRI","Limón","","41266","1188400409"
"Bensheim","Bensheim","49.6667","8.6167","Germany","DE","DEU","Hesse","","40629","1276256416"
"Niquero","Niquero","20.0472","-77.5781","Cuba","CU","CUB","Granma","minor","41252","1192749445"
"Arauquita","Arauquita","7.0303","-71.4356","Colombia","CO","COL","Arauca","minor","41309","1170159021"
"Girau do Ponciano","Girau do Ponciano","-9.8839","-36.8289","Brazil","BR","BRA","Alagoas","","41237","1076704674"
"Chaves","Chaves","41.7406","-7.4714","Portugal","PT","PRT","Vila Real","minor","41243","1620516049"
"Hatfield","Hatfield","51.7636","-0.2258","United Kingdom","GB","GBR","Hertfordshire","","41265","1826844690"
"Ganta","Ganta","7.2375","-8.9814","Liberia","LR","LBR","Nimba","","41106","1430766691"
"Freiberg","Freiberg","50.9119","13.3428","Germany","DE","DEU","Saxony","minor","40461","1276914988"
"São Mateus do Sul","Sao Mateus do Sul","-25.8739","-50.3828","Brazil","BR","BRA","Paraná","","41257","1076883402"
"Kovvūr","Kovvur","17.0170","81.7310","India","IN","IND","Andhra Pradesh","","39667","1356652394"
"Sumoto","Sumoto","34.3500","134.9000","Japan","JP","JPN","Hyōgo","","41056","1392003227"
"Järvenpää","Jarvenpaa","60.4750","25.0917","Finland","FI","FIN","Uusimaa","minor","40106","1246825863"
"Anyuan","Anyuan","34.8839","105.2758","China","CN","CHN","Gansu","","40828","1156792298"
"San Jose de Urquico","San Jose de Urquico","15.4781","120.4639","Philippines","PH","PHL","Tarlac","","41182","1608469501"
"Villanueva","Villanueva","8.5833","124.7833","Philippines","PH","PHL","Misamis Oriental","","40419","1608541985"
"Yingzhou Linchang","Yingzhou Linchang","18.4199","109.8553","China","CN","CHN","Hainan","","40925","1156474301"
"San Juan","San Juan","16.6667","120.3333","Philippines","PH","PHL","La Union","","40507","1608765235"
"Unzen","Unzen","32.8353","130.1875","Japan","JP","JPN","Nagasaki","","41018","1392003351"
"Constitución","Constitucion","-35.3333","-72.4167","Chile","CL","CHL","Maule","","41207","1152065066"
"Temryuk","Temryuk","45.2667","37.3667","Russia","RU","RUS","Krasnodarskiy Kray","minor","41133","1643588256"
"Halfeti","Halfeti","37.2489","37.8658","Turkey","TR","TUR","Şanlıurfa","minor","41142","1792654742"
"Anglet","Anglet","43.4850","-1.5183","France","FR","FRA","Nouvelle-Aquitaine","","39719","1250237596"
"São Manuel","Sao Manuel","-22.7308","-48.5708","Brazil","BR","BRA","São Paulo","","41123","1076604538"
"Amami","Amami","28.3783","129.4944","Japan","JP","JPN","Okinawa","","41049","1392003169"
"Vallehermoso","Vallehermoso","10.3333","123.3167","Philippines","PH","PHL","Negros Oriental","","40779","1608255760"
"Tagbina","Tagbina","8.4578","126.1578","Philippines","PH","PHL","Surigao del Sur","","41051","1608525528"
"Guaduas","Guaduas","5.0694","-74.5981","Colombia","CO","COL","Cundinamarca","minor","41142","1170513211"
"Paraíba do Sul","Paraiba do Sul","-22.1619","-43.2928","Brazil","BR","BRA","Rio de Janeiro","","41084","1076715765"
"Matiguás","Matiguas","12.8372","-85.4619","Nicaragua","NI","NIC","Matagalpa","minor","41127","1558837403"
"Shanhe","Shanhe","35.4844","108.3640","China","CN","CHN","Gansu","minor","40728","1156310376"
"Ardeşen","Ardesen","41.1900","40.9831","Turkey","TR","TUR","Rize","minor","41084","1792160424"
"Penn Hills","Penn Hills","40.4762","-79.8254","United States","US","USA","Pennsylvania","","41132","1840133217"
"Ambositra","Ambositra","-20.5167","47.2500","Madagascar","MG","MDG","Fianarantsoa","minor","41078","1450276194"
"Bartlett","Bartlett","41.9804","-88.2071","United States","US","USA","Illinois","","41126","1840011246"
"Tampakan","Tampakan","6.4500","124.9333","Philippines","PH","PHL","South Cotabato","","41018","1608000995"
"Pilāni","Pilani","28.3700","75.6000","India","IN","IND","Rājasthān","","40590","1356536081"
"Kabuga","Kabuga","-1.9667","30.2167","Rwanda","RW","RWA","Kigali","","40202","1646271073"
"Marcianise","Marcianise","41.0333","14.3000","Italy","IT","ITA","Campania","","39792","1380095709"
"Jeremoabo","Jeremoabo","-10.0669","-38.3500","Brazil","BR","BRA","Bahia","","41100","1076509996"
"Mobo","Mobo","12.3372","123.6581","Philippines","PH","PHL","Masbate","","40823","1608308283"
"Ostfildern","Ostfildern","48.7333","9.2500","Germany","DE","DEU","Baden-Württemberg","","39376","1276855000"
"Lemgo","Lemgo","52.0272","8.9117","Germany","DE","DEU","North Rhine-Westphalia","","40696","1276164957"
"Jiquipilas","Jiquipilas","16.5333","-93.6667","Mexico","MX","MEX","Chiapas","minor","41063","1484691049"
"Mucuri","Mucuri","-18.0858","-39.5508","Brazil","BR","BRA","Bahia","","41068","1076403815"
"Bazar-Korgon","Bazar-Korgon","41.0417","72.7472","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","41011","1417672814"
"Hollister","Hollister","36.8555","-121.3986","United States","US","USA","California","","41077","1840020354"
"Upper Hutt","Upper Hutt","-41.1333","175.0500","New Zealand","NZ","NZL","Wellington","","41000","1554000042"
"Ciudad Darío","Ciudad Dario","12.7322","-86.1233","Nicaragua","NI","NIC","Matagalpa","minor","41014","1558651455"
"Bullhead City","Bullhead City","35.1205","-114.5461","United States","US","USA","Arizona","","41064","1840019748"
"Dois Vizinhos","Dois Vizinhos","-25.7500","-53.0569","Brazil","BR","BRA","Paraná","","41038","1076157751"
"Bolesławiec","Boleslawiec","51.2667","15.5667","Poland","PL","POL","Dolnośląskie","minor","39412","1616593724"
"Lancaster","Lancaster","32.5922","-96.7739","United States","US","USA","Texas","","41057","1840020714"
"Pirmasens","Pirmasens","49.2000","7.6000","Germany","DE","DEU","Rhineland-Palatinate","minor","40403","1276000134"
"Chambas","Chambas","22.1967","-78.9133","Cuba","CU","CUB","Ciego de Ávila","minor","41000","1192803412"
"Sint-Truiden","Sint-Truiden","50.8167","5.1833","Belgium","BE","BEL","Flanders","","40672","1056944500"
"Nabas","Nabas","11.8278","122.0939","Philippines","PH","PHL","Aklan","","40632","1608047597"
"Maarssen","Maarssen","52.1351","5.0413","Netherlands","NL","NLD","Utrecht","minor","39752","1528103509"
"Segovia","Segovia","7.0781","-74.7017","Colombia","CO","COL","Antioquia","minor","41000","1170027654"
"Uozu","Uozu","36.8273","137.4092","Japan","JP","JPN","Toyama","","40828","1392003458"
"Grove City","Grove City","39.8654","-83.0690","United States","US","USA","Ohio","","41025","1840007264"
"Marion","Marion","42.0451","-91.5850","United States","US","USA","Iowa","","41023","1840008099"
"Brookfield","Brookfield","43.0640","-88.1231","United States","US","USA","Wisconsin","","41020","1840003012"
"Bagua Grande","Bagua Grande","-5.7572","-78.4453","Peru","PE","PER","Amazonas","","40947","1604518174"
"Puerto Gaitán","Puerto Gaitan","4.3142","-72.0825","Colombia","CO","COL","Meta","minor","41017","1170781522"
"Pyt’-Yakh","Pyt'-Yakh","60.7500","72.7833","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","41005","1643096120"
"Dowlatābād","Dowlatabad","32.7997","51.6956","Iran","IR","IRN","Eşfahān","minor","40945","1364977743"
"Delaware","Delaware","40.2851","-83.0741","United States","US","USA","Ohio","","41004","1840007237"
"Gap","Gap","44.5594","6.0786","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","40631","1250717816"
"Maddela","Maddela","16.3411","121.6833","Philippines","PH","PHL","Quirino","","40943","1608762330"
"Mambusao","Mambusao","11.4300","122.5953","Philippines","PH","PHL","Capiz","","40690","1608156919"
"Katō","Kato","34.9167","134.9667","Japan","JP","JPN","Hyōgo","","40728","1392003309"
"Novozybkov","Novozybkov","52.5333","31.9333","Russia","RU","RUS","Bryanskaya Oblast’","minor","39725","1643876625"
"Jogbani","Jogbani","26.4024","87.2656","India","IN","IND","Bihār","","39588","1356464438"
"Larantuka","Larantuka","-8.3133","123.0042","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","40828","1360493926"
"Taicheng","Taicheng","38.7206","113.2437","China","CN","CHN","Shanxi","minor","40145","1156018236"
"Barr Eliâs","Barr Elias","33.7750","35.9000","Lebanon","LB","LBN","Béqaa","","40000","1422386644"
"Lobo","Lobo","13.6500","121.2500","Philippines","PH","PHL","Batangas","","40736","1608882529"
"Osório","Osorio","-29.8869","-50.2700","Brazil","BR","BRA","Rio Grande do Sul","","40906","1076367566"
"Woburn","Woburn","42.4869","-71.1543","United States","US","USA","Massachusetts","","40967","1840003124"
"Columbia","Columbia","35.6238","-87.0484","United States","US","USA","Tennessee","","40957","1840014518"
"Germantown","Germantown","35.0829","-89.7824","United States","US","USA","Tennessee","","40955","1840013473"
"Partāpnagar","Partapnagar","25.4145","87.0787","India","IN","IND","Bihār","","39050","1356507539"
"Krishnarājāsāgara","Krishnarajasagara","12.4398","76.3828","India","IN","IND","Karnātaka","","40435","1356899885"
"Inhambupe","Inhambupe","-11.7839","-38.3528","Brazil","BR","BRA","Bahia","","40915","1076169385"
"Chebarkul","Chebarkul","54.9833","60.3667","Russia","RU","RUS","Chelyabinskaya Oblast’","","40378","1643618231"
"Shelton","Shelton","41.3060","-73.1383","United States","US","USA","Connecticut","","40944","1840004840"
"Romny","Romny","50.7428","33.4879","Ukraine","UA","UKR","Sumska Oblast","minor","40316","1804386942"
"Maragondon","Maragondon","14.2667","120.7333","Philippines","PH","PHL","Cavite","","40687","1608780945"
"Covington","Covington","39.0334","-84.5166","United States","US","USA","Kentucky","","40930","1840014263"
"Delījān","Delijan","33.9906","50.6839","Iran","IR","IRN","Markazī","minor","40902","1364920781"
"Balaoan","Balaoan","16.8167","120.4000","Philippines","PH","PHL","La Union","","40339","1608406936"
"Westfield","Westfield","42.1382","-72.7561","United States","US","USA","Massachusetts","","40922","1840000463"
"Ciudad Piar","Ciudad Piar","7.4522","-63.3200","Venezuela","VE","VEN","Bolívar","minor","40918","1862507661"
"Yomitan","Yomitan","26.3961","127.7444","Japan","JP","JPN","Okinawa","","39791","1392003512"
"Tirukkoyilūr","Tirukkoyilur","11.9500","79.2000","India","IN","IND","Tamil Nādu","","40212","1356662137"
"Kitob","Kitob","39.1336","66.8819","Uzbekistan","UZ","UZB","Qashqadaryo","","40800","1860210417"
"Pugachev","Pugachev","52.0167","48.8000","Russia","RU","RUS","Saratovskaya Oblast’","","40895","1643132953"
"Barreiros","Barreiros","-8.8167","-35.2000","Brazil","BR","BRA","Pernambuco","","40732","1076661261"
"Apóstoles","Apostoles","-27.9167","-55.7667","Argentina","AR","ARG","Misiones","minor","40858","1032321516"
"Woodstock","Woodstock","43.1306","-80.7467","Canada","CA","CAN","Ontario","","40902","1124758374"
"Mangaratiba","Mangaratiba","-22.9600","-44.0408","Brazil","BR","BRA","Rio de Janeiro","","40779","1076292553"
"Nowgong","Nowgong","25.0574","79.4381","India","IN","IND","Madhya Pradesh","","40580","1356528828"
"Promissão","Promissao","-21.5369","-49.8583","Brazil","BR","BRA","São Paulo","","40828","1076199684"
"Kadūr","Kadur","13.5529","76.0116","India","IN","IND","Karnātaka","","40674","1356837882"
"Rio Real","Rio Real","-11.4833","-37.9344","Brazil","BR","BRA","Bahia","","40809","1076770840"
"Ciudad Barrios","Ciudad Barrios","13.7667","-88.2667","El Salvador","SV","SLV","San Miguel","","40500","1222390192"
"Wageningen","Wageningen","51.9667","5.6667","Netherlands","NL","NLD","Gelderland","minor","39635","1528070340"
"Chiredzi","Chiredzi","-21.0389","31.6778","Zimbabwe","ZW","ZWE","Masvingo","","40100","1716636761"
"Ahlat","Ahlat","38.7528","42.4944","Turkey","TR","TUR","Bitlis","minor","40806","1792335913"
"Tianzhong","Tianzhong","23.8595","120.5878","Taiwan","TW","TWN","Changhua","","39701","1158841017"
"Cuito Cuanavale","Cuito Cuanavale","-15.1667","19.1667","Angola","AO","AGO","Cuando Cubango","","40829","1024195525"
"Valladolid","Valladolid","10.4667","122.8333","Philippines","PH","PHL","Negros Occidental","","39996","1608471228"
"Abadan","Abadan","38.0517","58.2100","Turkmenistan","TM","TKM","Ahal","","40813","1795394170"
"Caeté","Caete","-19.8800","-43.6700","Brazil","BR","BRA","Minas Gerais","","40750","1076979149"
"São Bento","Sao Bento","-2.6958","-44.8208","Brazil","BR","BRA","Maranhão","","40736","1076246119"
"Zoumi","Zoumi","34.8000","-5.3500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","40661","1504309761"
"Santa Eulalia del Río","Santa Eulalia del Rio","38.9847","1.5336","Spain","ES","ESP","Balearic Islands","","40548","1724568151"
"Samālkha","Samalkha","29.2300","77.0200","India","IN","IND","Haryāna","","40000","1356715099"
"Zhujiezhen","Zhujiezhen","23.7460","104.8990","China","CN","CHN","Yunnan","","40623","1156968894"
"Bañga","Banga","11.6389","122.3331","Philippines","PH","PHL","Aklan","","40318","1608293619"
"Tomar","Tomar","39.6000","-8.4167","Portugal","PT","PRT","Santarém","minor","40677","1620168091"
"Pereslavl’-Zalesskiy","Pereslavl'-Zalesskiy","56.7381","38.8562","Russia","RU","RUS","Yaroslavskaya Oblast’","","39105","1643256841"
"Neumarkt","Neumarkt","49.2833","11.4667","Germany","DE","DEU","Bavaria","minor","40274","1276002746"
"Sabalgarh","Sabalgarh","26.2500","77.4000","India","IN","IND","Madhya Pradesh","","40333","1356895146"
"Gonābād","Gonabad","34.3528","58.6836","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","40773","1364339411"
"Friendswood","Friendswood","29.5110","-95.1981","United States","US","USA","Texas","","40780","1840020969"
"Pālampur","Palampur","32.1097","76.5366","India","IN","IND","Himāchal Pradesh","","40385","1356150389"
"The Acreage","The Acreage","26.7740","-80.2779","United States","US","USA","Florida","","40771","1840026061"
"Jhābua","Jhabua","22.7677","74.5909","India","IN","IND","Madhya Pradesh","","40361","1356050748"
"Lugoj","Lugoj","45.7056","21.9258","Romania","RO","ROU","Timiş","","40361","1642742635"
"Qeshm","Qeshm","26.9581","56.2719","Iran","IR","IRN","Hormozgān","minor","40678","1364691339"
"Pie de Pató","Pie de Pato","5.5333","-77.0000","Colombia","CO","COL","Chocó","minor","40733","1170030072"
"Weißenfels","Weissenfels","51.2000","11.9667","Germany","DE","DEU","Saxony-Anhalt","","40409","1276292106"
"Kīlakkarai","Kilakkarai","9.2314","78.7848","India","IN","IND","Tamil Nādu","","40143","1356066542"
"Boucherville","Boucherville","45.6000","-73.4500","Canada","CA","CAN","Quebec","","40753","1124000296"
"Khowrmūj","Khowrmuj","28.6489","51.3794","Iran","IR","IRN","Būshehr","minor","40722","1364920877"
"Yalutorovsk","Yalutorovsk","56.6500","66.2833","Russia","RU","RUS","Tyumenskaya Oblast’","","39967","1643158086"
"Pachrūkha","Pachrukha","26.7373","84.8861","India","IN","IND","Bihār","","39474","1356336852"
"Sivaganga","Sivaganga","9.8433","78.4809","India","IN","IND","Tamil Nādu","","40403","1356528232"
"Arys","Arys","42.4333","68.8000","Kazakhstan","KZ","KAZ","","","40707","1398483570"
"Siay","Siay","7.7056","122.8641","Philippines","PH","PHL","Zamboanga Sibugay","","40585","1608466245"
"Essex","Essex","39.3021","-76.4449","United States","US","USA","Maryland","","40710","1840005675"
"Komono","Komono","35.0200","136.5075","Japan","JP","JPN","Mie","","40329","1392136117"
"Buchholz in der Nordheide","Buchholz in der Nordheide","53.3167","9.8667","Germany","DE","DEU","Lower Saxony","","40164","1276859084"
"Sciacca","Sciacca","37.5092","13.0889","Italy","IT","ITA","Sicilia","","40488","1380760474"
"Barra de São Francisco","Barra de Sao Francisco","-18.7550","-40.8908","Brazil","BR","BRA","Espírito Santo","","40649","1076442914"
"Nanuque","Nanuque","-17.8389","-40.3539","Brazil","BR","BRA","Minas Gerais","","40665","1076083471"
"Nabarūh","Nabaruh","31.0942","31.3000","Egypt","EG","EGY","Ad Daqahlīyah","","38953","1818811777"
"Channarāyapatna","Channarayapatna","12.9000","76.3900","India","IN","IND","Karnātaka","","40400","1356209573"
"Vellakkovil","Vellakkovil","10.9300","77.7200","India","IN","IND","Tamil Nādu","","40359","1356075546"
"Buxtehude","Buxtehude","53.4769","9.7011","Germany","DE","DEU","Lower Saxony","","40150","1276767530"
"Montélimar","Montelimar","44.5581","4.7508","France","FR","FRA","Auvergne-Rhône-Alpes","","39818","1250664551"
"Khawr Fakkān","Khawr Fakkan","25.3333","56.3500","United Arab Emirates","AE","ARE","Ash Shāriqah","","39515","1784935461"
"La Unión","La Union","4.5331","-76.1006","Colombia","CO","COL","Valle del Cauca","minor","40339","1170145484"
"Bayındır","Bayindir","38.2192","27.6481","Turkey","TR","TUR","İzmir","minor","40584","1792970598"
"Soe","Soe","-9.8667","124.2833","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","40532","1360125783"
"Santo Antônio de Pádua","Santo Antonio de Padua","-21.5389","-42.1800","Brazil","BR","BRA","Rio de Janeiro","","40589","1076879205"
"Pechora","Pechora","65.1667","57.2500","Russia","RU","RUS","Komi","","40653","1643523866"
"Löhne","Lohne","52.2000","8.7000","Germany","DE","DEU","North Rhine-Westphalia","","39977","1276788847"
"Douar Laouamra","Douar Laouamra","31.8000","-8.7167","Morocco","MA","MAR","Marrakech-Safi","","40605","1504542773"
"Manalapan","Manalapan","40.2800","-74.3436","United States","US","USA","New Jersey","","40637","1840081648"
"Leyte","Leyte","11.3667","124.4833","Philippines","PH","PHL","Leyte","","40397","1608131998"
"Panglao","Panglao","9.5833","123.7500","Philippines","PH","PHL","Bohol","","39839","1608819048"
"Rovereto","Rovereto","45.8833","11.0500","Italy","IT","ITA","Trentino-Alto Adige","","39825","1380311979"
"Braine-l’Alleud","Braine-l'Alleud","50.6806","4.3717","Belgium","BE","BEL","Wallonia","","39837","1056287952"
"Lordegān","Lordegan","31.5081","50.8319","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","minor","40528","1364124062"
"Rizal","Rizal","12.4667","120.9667","Philippines","PH","PHL","Occidental Mindoro","","40429","1608028243"
"Agano","Agano","37.8344","139.2260","Japan","JP","JPN","Niigata","","40382","1392003064"
"Huaura","Huaura","-11.1000","-77.6000","Peru","PE","PER","Lima","","40400","1604503366"
"Islāmpur","Islampur","25.1407","85.2059","India","IN","IND","Bihār","","39425","1356357667"
"Xinguara","Xinguara","-7.0950","-49.9458","Brazil","BR","BRA","Pará","","40573","1076290442"
"Iesi","Iesi","43.5236","13.2392","Italy","IT","ITA","Marche","","40210","1380650827"
"Ban Bang Khu Lat","Ban Bang Khu Lat","13.9134","100.3690","Thailand","TH","THA","Nonthaburi","","38758","1764924013"
"Mailapur","Mailapur","13.0210","77.9380","India","IN","IND","Karnātaka","","40050","1356629438"
"Maihar","Maihar","24.2620","80.7610","India","IN","IND","Madhya Pradesh","","40192","1356415420"
"Duncanville","Duncanville","32.6460","-96.9127","United States","US","USA","Texas","","40575","1840019442"
"Taysan","Taysan","13.7833","121.2000","Philippines","PH","PHL","Batangas","","40146","1608589412"
"Murtajāpur","Murtajapur","20.7300","77.5100","India","IN","IND","Mahārāshtra","","40295","1356183552"
"Uray","Uray","60.1333","64.8000","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","40559","1643009149"
"Freital","Freital","51.0167","13.6500","Germany","DE","DEU","Saxony","","39577","1276672229"
"Cabatuan","Cabatuan","16.9589","121.6692","Philippines","PH","PHL","Isabela","","39990","1608638742"
"Dingras","Dingras","18.1036","120.6975","Philippines","PH","PHL","Ilocos Norte","","40127","1608342928"
"Cumbal","Cumbal","0.9078","-77.7908","Colombia","CO","COL","Nariño","minor","40492","1170478415"
"Payabon","Payabon","9.7667","123.1333","Philippines","PH","PHL","Negros Oriental","","40308","1608351258"
"Augusto Correa","Augusto Correa","-1.0219","-46.6450","Brazil","BR","BRA","Pará","","40497","1076227930"
"Halberstadt","Halberstadt","51.8958","11.0467","Germany","DE","DEU","Saxony-Anhalt","minor","40256","1276900818"
"Yian","Yian","47.8804","125.3000","China","CN","CHN","Heilongjiang","minor","39924","1156612993"
"Al Qā‘idah","Al Qa\`idah","13.7569","44.1392","Yemen","YE","YEM","Ibb","","39254","1887959673"
"Maintal","Maintal","50.1500","8.8333","Germany","DE","DEU","Hesse","","39298","1276616960"
"Lilio","Lilio","14.1300","121.4360","Philippines","PH","PHL","Laguna","","39491","1608390374"
"Cedar Falls","Cedar Falls","42.5195","-92.4536","United States","US","USA","Iowa","","40500","1840006946"
"Andradas","Andradas","-22.0678","-46.5689","Brazil","BR","BRA","Minas Gerais","","40407","1076414147"
"Magburaka","Magburaka","8.7169","-11.9433","Sierra Leone","SL","SLE","Northern","","40313","1694067081"
"Milton","Milton","34.1353","-84.3139","United States","US","USA","Georgia","","40487","1840014738"
"Corigliano Calabro","Corigliano Calabro","39.6000","16.5167","Italy","IT","ITA","Calabria","","40478","1380627594"
"Yajalón","Yajalon","17.1833","-92.3167","Mexico","MX","MEX","Chiapas","minor","40285","1484860213"
"Olintepeque","Olintepeque","14.8833","-91.5167","Guatemala","GT","GTM","Quetzaltenango","minor","39383","1320868542"
"Cauquenes","Cauquenes","-35.9667","-72.3500","Chile","CL","CHL","Maule","minor","40441","1152787999"
"Lishaocun","Lishaocun","22.7236","111.4607","China","CN","CHN","Guangdong","","39966","1156554170"
"Hofheim","Hofheim","50.0833","8.4500","Germany","DE","DEU","Hesse","minor","39766","1276364825"
"Camaná","Camana","-16.6167","-72.7167","Peru","PE","PER","Arequipa","","39026","1604379091"
"Aracataca","Aracataca","10.5937","-74.1928","Colombia","CO","COL","Magdalena","minor","40400","1170335661"
"Heemskerk","Heemskerk","52.5167","4.6667","Netherlands","NL","NLD","Noord-Holland","minor","39191","1528784023"
"Aizawa","Aizawa","35.5288","139.3216","Japan","JP","JPN","Kanagawa","","39260","1392673347"
"Sirinhaém","Sirinhaem","-8.5908","-35.1158","Brazil","BR","BRA","Pernambuco","","40296","1076674219"
"Zainsk","Zainsk","55.3000","52.0167","Russia","RU","RUS","Tatarstan","","40366","1643336213"
"Weslaco","Weslaco","26.1599","-97.9877","United States","US","USA","Texas","","40393","1840022258"
"Begamganj","Begamganj","23.5992","78.3406","India","IN","IND","Madhya Pradesh","","40191","1356623727"
"Lake Oswego","Lake Oswego","45.4129","-122.7004","United States","US","USA","Oregon","","40390","1840019955"
"Junnar","Junnar","19.2000","73.8800","India","IN","IND","Mahārāshtra","","40000","1356521815"
"Imbituba","Imbituba","-28.2400","-48.6700","Brazil","BR","BRA","Santa Catarina","","40170","1076391605"
"Ōzu","Ozu","33.5000","132.5500","Japan","JP","JPN","Ehime","","40289","1392217388"
"Uchqŭrghon Shahri","Uchqurghon Shahri","41.1214","72.0936","Uzbekistan","UZ","UZB","Namangan","minor","40000","1860013712"
"Jipijapa","Jipijapa","-1.3486","-80.5786","Ecuador","EC","ECU","Manabí","","40232","1218962184"
"Ilıca","Ilica","39.9458","41.1053","Turkey","TR","TUR","Erzurum","minor","40350","1792419586"
"Malalag","Malalag","6.6000","125.4000","Philippines","PH","PHL","Davao del Sur","","40158","1608009678"
"Túquerres","Tuquerres","1.0833","-77.6167","Colombia","CO","COL","Nariño","minor","40159","1170724789"
"Glenrothes","Glenrothes","56.1980","-3.1780","United Kingdom","GB","GBR","Fife","","38510","1826270044"
"Fonds Verrettes","Fonds Verrettes","18.3833","-71.8500","Haiti","HT","HTI","Ouest","","40224","1332183174"
"Lancaster","Lancaster","39.7248","-82.6049","United States","US","USA","Ohio","","40360","1840003781"
"Eboli","Eboli","40.6169","15.0564","Italy","IT","ITA","Campania","","40058","1380398886"
"Beyneū","Beyneu","45.3247","55.1958","Kazakhstan","KZ","KAZ","","","40344","1398590005"
"Ampana","Ampana","-0.8667","121.5833","Indonesia","ID","IDN","Sulawesi Tengah","minor","40299","1360217456"
"Hailākāndi","Hailakandi","24.6839","92.5610","India","IN","IND","Assam","","39255","1356671360"
"Schorndorf","Schorndorf","48.8000","9.5333","Germany","DE","DEU","Baden-Württemberg","","39642","1276068626"
"Plaridel","Plaridel","8.6214","123.7101","Philippines","PH","PHL","Misamis Occidental","","39840","1608091150"
"Jāle","Jale","26.3800","85.7200","India","IN","IND","Bihār","","38798","1356021966"
"Molina","Molina","-35.1144","-71.2825","Chile","CL","CHL","Maule","","40329","1152236505"
"Balud","Balud","12.0369","123.1935","Philippines","PH","PHL","Masbate","","40155","1608021152"
"Majibacoa","Majibacoa","20.9172","-76.8764","Cuba","CU","CUB","Las Tunas","","40264","1192359661"
"Findlay","Findlay","41.0469","-83.6379","United States","US","USA","Ohio","","40323","1840000886"
"Leramatang","Leramatang","-8.4000","123.5700","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","40200","1360546099"
"Belaya Kalitva","Belaya Kalitva","48.1833","40.7667","Russia","RU","RUS","Rostovskaya Oblast’","minor","40275","1643376629"
"San Fernando de Henares","San Fernando de Henares","40.4256","-3.5353","Spain","ES","ESP","Madrid","","39313","1724056913"
"Tietê","Tiete","-23.1019","-47.7150","Brazil","BR","BRA","São Paulo","","40194","1076154000"
"Gourcy","Gourcy","13.2167","-2.3500","Burkina Faso","BF","BFA","Nord","minor","40144","1854167073"
"Arawa","Arawa","-6.2250","155.5667","Papua New Guinea","PG","PNG","Bougainville","","40266","1598413091"
"New Berlin","New Berlin","42.9726","-88.1291","United States","US","USA","Wisconsin","","40289","1840003025"
"Kunigal","Kunigal","13.0232","77.0252","India","IN","IND","Karnātaka","","40052","1356196460"
"Tosya","Tosya","41.0172","34.0383","Turkey","TR","TUR","Kastamonu","minor","40245","1792502805"
"Águas Belas","Aguas Belas","-9.1167","-37.1167","Brazil","BR","BRA","Pernambuco","","40235","1076330876"
"Oświęcim","Oswiecim","50.0381","19.2214","Poland","PL","POL","Małopolskie","minor","39057","1616528804"
"Považská Bystrica","Povazska Bystrica","49.1139","18.4417","Slovakia","SK","SVK","Nitra","minor","39837","1703729563"
"L’Asile","L'Asile","18.3833","-73.4167","Haiti","HT","HTI","Nippes","","40000","1332348189"
"Santo Niño","Santo Nino","6.4333","124.6833","Philippines","PH","PHL","South Cotabato","","39796","1608688474"
"Itoigawa","Itoigawa","37.0390","137.8627","Japan","JP","JPN","Niigata","","40200","1392314083"
"Sarāqib","Saraqib","35.8636","36.8006","Syria","SY","SYR","Idlib","minor","39901","1760810482"
"Kasumigaura","Kasumigaura","36.1517","140.2371","Japan","JP","JPN","Ibaraki","","39993","1392054909"
"Havza","Havza","40.9667","35.6667","Turkey","TR","TUR","Samsun","minor","40194","1792980537"
"Uttarkāshi","Uttarkashi","30.7300","78.4500","India","IN","IND","Uttarākhand","","40220","1356765143"
"Secunda","Secunda","-26.5161","29.2028","South Africa","ZA","ZAF","Mpumalanga","","40198","1710520670"
"Nawābganj","Nawabganj","28.5400","79.6330","India","IN","IND","Uttar Pradesh","","39241","1356385416"
"Sejenane","Sejenane","37.0564","9.2383","Tunisia","TN","TUN","Bizerte","","40166","1788757280"
"Sindgi","Sindgi","16.9200","76.2340","India","IN","IND","Karnātaka","","40000","1356158626"
"Bāsudebpur","Basudebpur","21.1197","86.7290","India","IN","IND","Odisha","","39607","1356095666"
"Camargo","Camargo","27.6670","-105.1670","Mexico","MX","MEX","Chihuahua","minor","40221","1484013592"
"Tipton","Tipton","52.5259","-2.0751","United Kingdom","GB","GBR","Dudley","","38777","1826072169"
"Santiago Nonualco","Santiago Nonualco","13.5167","-88.9500","El Salvador","SV","SLV","La Paz","","39887","1222183134"
"Hutchinson","Hutchinson","38.0671","-97.9081","United States","US","USA","Kansas","","40202","1840001673"
"Holly Springs","Holly Springs","35.6526","-78.8399","United States","US","USA","North Carolina","","40198","1840016193"
"Mtsensk","Mtsensk","53.2833","36.5667","Russia","RU","RUS","Orlovskaya Oblast’","","38350","1643200546"
"Tutayev","Tutayev","57.8833","39.5500","Russia","RU","RUS","Yaroslavskaya Oblast’","","40154","1643941523"
"French Valley","French Valley","33.5999","-117.1069","United States","US","USA","California","","40188","1840075867"
"Vyshneve","Vyshneve","50.3869","30.3581","Ukraine","UA","UKR","Kyivska Oblast","","39900","1804783746"
"Hangu","Hangu","33.5333","71.0667","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","39766","1586769539"
"Longtang","Longtang","26.1984","107.7946","China","CN","CHN","Guizhou","","40094","1156017325"
"Kālimpong","Kalimpong","27.0600","88.4700","India","IN","IND","West Bengal","","40143","1356392969"
"Raxruhá","Raxruha","15.8666","-90.0424","Guatemala","GT","GTM","Alta Verapaz","minor","40094","1320014679"
"Ninove","Ninove","50.8333","4.0167","Belgium","BE","BEL","Flanders","","39626","1056641524"
"Draguignan","Draguignan","43.5403","6.4667","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","39433","1250434593"
"Medgidia","Medgidia","44.2503","28.2614","Romania","RO","ROU","Constanţa","","39780","1642156284"
"Security-Widefield","Security-Widefield","38.7489","-104.7142","United States","US","USA","Colorado","","40158","1840074635"
"Kambia","Kambia","9.1167","-12.9167","Sierra Leone","SL","SLE","Northern","","40000","1694846063"
"Soavinandriana","Soavinandriana","-19.1667","46.7333","Madagascar","MG","MDG","Antananarivo","","40045","1450397314"
"Oroqen Zizhiqi","Oroqen Zizhiqi","50.5667","123.7167","China","CN","CHN","Inner Mongolia","minor","40128","1156444032"
"Sagae","Sagae","38.3809","140.2760","Japan","JP","JPN","Yamagata","","39854","1392838148"
"Vendrell","Vendrell","41.2201","1.5348","Spain","ES","ESP","Catalonia","","39072","1724555594"
"Raub","Raub","3.7931","101.8569","Malaysia","MY","MYS","Pahang","","40024","1458881822"
"Zacualpa","Zacualpa","15.0272","-90.8778","Guatemala","GT","GTM","Quiché","minor","40003","1320530349"
"Neu Isenburg","Neu Isenburg","50.0500","8.7000","Germany","DE","DEU","Hesse","","38532","1276467901"
"Skövde","Skovde","58.3833","13.8500","Sweden","SE","SWE","Västra Götaland","minor","39580","1752316649"
"Hurst","Hurst","32.8353","-97.1809","United States","US","USA","Texas","","40114","1840019427"
"Amursk","Amursk","50.2167","136.9000","Russia","RU","RUS","Khabarovskiy Kray","","40106","1643243118"
"Baksan","Baksan","43.6825","43.5339","Russia","RU","RUS","Kabardino-Balkariya","","38192","1643894887"
"Otavalo","Otavalo","0.2303","-78.2625","Ecuador","EC","ECU","Imbabura","","39354","1218541444"
"Romeoville","Romeoville","41.6318","-88.0997","United States","US","USA","Illinois","","40099","1840011489"
"Maddaloni","Maddaloni","41.0333","14.3833","Italy","IT","ITA","Campania","","39026","1380749764"
"Crystal Lake","Crystal Lake","42.2333","-88.3351","United States","US","USA","Illinois","","40082","1840006969"
"Altagracia de Orituco","Altagracia de Orituco","9.8504","-66.3800","Venezuela","VE","VEN","Guárico","minor","40052","1862533029"
"Siniloan","Siniloan","14.4167","121.4500","Philippines","PH","PHL","Laguna","","39460","1608472370"
"Unión de Reyes","Union de Reyes","22.8003","-81.5372","Cuba","CU","CUB","Matanzas","minor","40022","1192297639"
"Fondi","Fondi","41.3500","13.4167","Italy","IT","ITA","Lazio","","39779","1380041713"
"Janīn","Janin","32.4611","35.3000","West Bank","XW","XWB","","","39004","1934524373"
"Jayrūd","Jayrud","33.8067","36.7403","Syria","SY","SYR","Rīf Dimashq","minor","39903","1760101368"
"Jalalabad","Jalalabad","35.8800","74.4930","Pakistan","PK","PAK","Gilgit-Baltistan","","40000","1586185836"
"Zhongbai","Zhongbai","26.7723","107.8883","China","CN","CHN","Guizhou","","39893","1156739855"
"Alashankou","Alashankou","45.1733","82.5546","China","CN","CHN","Xinjiang","minor","40000","1156292896"
"Ettlingen","Ettlingen","48.9333","8.4000","Germany","DE","DEU","Baden-Württemberg","","39339","1276126657"
"Vavuniya","Vavuniya","8.7500","80.4833","Sri Lanka","LK","LKA","North Central","","38101","1144186197"
"Tarauacá","Tarauaca","-8.1608","-70.7658","Brazil","BR","BRA","Acre","","40024","1076894557"
"Matsoandakana","Matsoandakana","-14.9667","49.2500","Madagascar","MG","MDG","Mahajanga","","40000","1450774810"
"Revelganj","Revelganj","25.7800","84.6700","India","IN","IND","Bihār","","39039","1356423322"
"Jiaozishan","Jiaozishan","26.3342","105.9324","China","CN","CHN","Guizhou","","39551","1156945094"
"Bangkinang","Bangkinang","0.3500","101.0333","Indonesia","ID","IDN","Riau","minor","39793","1360571456"
"Völklingen","Volklingen","49.2500","6.8333","Germany","DE","DEU","Saarland","","39428","1276052749"
"Pamplona","Pamplona","9.4667","123.1167","Philippines","PH","PHL","Negros Oriental","","39805","1608085129"
"Baco","Baco","13.3584","121.0977","Philippines","PH","PHL","Oriental Mindoro","","39817","1608441009"
"Alimodian","Alimodian","10.8196","122.4322","Philippines","PH","PHL","Iloilo","","39722","1608277073"
"Kyaukme","Kyaukme","22.5392","97.0283","Myanmar","MM","MMR","Shan State","","39930","1104895857"
"Tago","Tago","9.0192","126.2333","Philippines","PH","PHL","Surigao del Sur","","39831","1608986143"
"Pojuca","Pojuca","-12.3659","-38.2433","Brazil","BR","BRA","Bahia","","39972","1076704575"
"Fasano","Fasano","40.8333","17.3667","Italy","IT","ITA","Puglia","","39683","1380301604"
"Idangansālai","Idangansalai","11.6272","77.9890","India","IN","IND","Tamil Nādu","","39118","1356168712"
"Langen","Langen","49.9833","8.6667","Germany","DE","DEU","Hesse","","38651","1276365275"
"Huamachuco","Huamachuco","-7.8120","-78.0487","Peru","PE","PER","La Libertad","","39806","1604864093"
"Halle","Halle","50.7361","4.2372","Belgium","BE","BEL","Flanders","","39096","1056061836"
"Voghera","Voghera","44.9925","9.0092","Italy","IT","ITA","Lombardy","","39354","1380568677"
"Zimapan","Zimapan","20.7333","-99.3833","Mexico","MX","MEX","Hidalgo","","39927","1484785009"
"Ossining","Ossining","41.1559","-73.8564","United States","US","USA","New York","","39973","1840004937"
"Rauma","Rauma","61.1333","21.5000","Finland","FI","FIN","Satakunta","minor","39809","1246262958"
"San Nicolas","San Nicolas","16.0700","120.7653","Philippines","PH","PHL","Pangasinan","","39778","1608738282"
"Acevedo","Acevedo","1.8192","-75.8964","Colombia","CO","COL","Huila","minor","39877","1170946838"
"Villa de San Diego de Ubaté","Villa de San Diego de Ubate","5.3072","-73.8144","Colombia","CO","COL","Cundinamarca","minor","39568","1170489797"
"Xinlizhuang","Xinlizhuang","39.2832","116.1727","China","CN","CHN","Hebei","","39474","1156047320"
"Yecapixtla","Yecapixtla","18.8833","-98.8650","Mexico","MX","MEX","Morelos","minor","39859","1484433908"
"Tivaouane","Tivaouane","14.9500","-16.8167","Senegal","SN","SEN","Thiès","","39755","1686949822"
"Atmakūr","Atmakur","15.8779","78.5884","India","IN","IND","Andhra Pradesh","","39794","1356989044"
"Clinton","Clinton","38.7499","-76.9063","United States","US","USA","Maryland","","39925","1840005949"
"Poblacion","Poblacion","7.5086","125.9206","Philippines","PH","PHL","Davao de Oro","","39631","1608344073"
"Ahaus","Ahaus","52.0667","7.0000","Germany","DE","DEU","North Rhine-Westphalia","","39658","1276411347"
"Weibo","Weibo","38.0290","115.2174","China","CN","CHN","Hebei","","38748","1156810364"
"São José de Mipibu","Sao Jose de Mipibu","-6.0750","-35.2378","Brazil","BR","BRA","Rio Grande do Norte","","39776","1076063132"
"Palauig","Palauig","15.4336","119.9083","Philippines","PH","PHL","Zambales","","39784","1608524035"
"Ayirāpuram","Ayirapuram","9.8781","76.3039","India","IN","IND","Kerala","","39214","1356725281"
"Meadow Woods","Meadow Woods","28.3698","-81.3467","United States","US","USA","Florida","","39904","1840029057"
"Magsaysay","Magsaysay","12.3117","121.1472","Philippines","PH","PHL","Occidental Mindoro","","39767","1608874631"
"Carol Stream","Carol Stream","41.9181","-88.1307","United States","US","USA","Illinois","","39895","1840010169"
"Brighton","Brighton","39.9716","-104.7963","United States","US","USA","Colorado","","39895","1840018784"
"Rio Negrinho","Rio Negrinho","-26.2539","-49.5178","Brazil","BR","BRA","Santa Catarina","","39846","1076110694"
"Agoncillo","Agoncillo","13.9334","120.9285","Philippines","PH","PHL","Batangas","","39101","1608191192"
"Écija","Ecija","37.5333","-5.0833","Spain","ES","ESP","Andalusia","","39838","1724919208"
"Kīsh","Kish","26.5578","54.0194","Iran","IR","IRN","Hormozgān","","39853","1364815176"
"Nueva Santa Rosa","Nueva Santa Rosa","14.3811","-90.2761","Guatemala","GT","GTM","Santa Rosa","minor","39286","1320719046"
"San Nicolas","San Nicolas","18.1750","120.5950","Philippines","PH","PHL","Ilocos Norte","","38895","1608708008"
"Kırkağaç","Kirkagac","39.1039","27.6700","Turkey","TR","TUR","Manisa","minor","39790","1792580975"
"Northampton","Northampton","40.2104","-75.0014","United States","US","USA","Pennsylvania","","39857","1840151117"
"Matías Romero","Matias Romero","16.8798","-95.0391","Mexico","MX","MEX","Oaxaca","","39828","1484296757"
"Betun","Betun","-9.4953","124.9603","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","39650","1360175629"
"Michalovce","Michalovce","48.7553","21.9133","Slovakia","SK","SVK","Košice","minor","39151","1703150232"
"Guaíra","Guaira","-20.3178","-48.3108","Brazil","BR","BRA","São Paulo","","39813","1076361005"
"Soria","Soria","41.7667","-2.4667","Spain","ES","ESP","Castille-Leon","minor","39695","1724106558"
"Wālājāpet","Walajapet","12.9255","79.3652","India","IN","IND","Tamil Nādu","","38907","1356584798"
"Nūrpur","Nurpur","29.1500","78.4000","India","IN","IND","Uttar Pradesh","","38806","1356076131"
"Srebrenik","Srebrenik","44.7000","18.4833","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","39678","1070982884"
"Winslow","Winslow","39.7027","-74.9029","United States","US","USA","New Jersey","","39833","1840081680"
"Würselen","Wurselen","50.8167","6.1333","Germany","DE","DEU","North Rhine-Westphalia","","38712","1276620771"
"Clarin","Clarin","8.2000","123.8500","Philippines","PH","PHL","Misamis Occidental","","39356","1608833654"
"Pamplona","Pamplona","13.5925","123.0833","Philippines","PH","PHL","Camarines Sur","","39333","1608392531"
"Bādepalli","Badepalli","16.7549","78.1443","India","IN","IND","Andhra Pradesh","","39374","1356533455"
"Waxahachie","Waxahachie","32.4035","-96.8446","United States","US","USA","Texas","","39815","1840022083"
"Kurobeshin","Kurobeshin","36.8667","137.4500","Japan","JP","JPN","Toyama","","39718","1392968736"
"Veruela","Veruela","8.0698","125.9554","Philippines","PH","PHL","Agusan del Sur","","39708","1608516164"
"Villamontes","Villamontes","-21.2608","-63.4761","Bolivia","BO","BOL","Tarija","","39800","1068626988"
"Toukountouna","Toukountouna","10.4986","1.3756","Benin","BJ","BEN","Atacora","","39779","1204293214"
"Apsheronsk","Apsheronsk","44.4667","39.7333","Russia","RU","RUS","Krasnodarskiy Kray","minor","39762","1643014988"
"Reynoldsburg","Reynoldsburg","39.9588","-82.7943","United States","US","USA","Ohio","","39803","1840009473"
"Líbano","Libano","4.9167","-75.1667","Colombia","CO","COL","Tolima","minor","39665","1170994564"
"Bosconia","Bosconia","9.9761","-73.8903","Colombia","CO","COL","Cesar","minor","39716","1170142290"
"Dolores","Dolores","16.5142","-89.4158","Guatemala","GT","GTM","Petén","minor","39783","1320503120"
"Mibu","Mibu","36.4422","139.8147","Japan","JP","JPN","Tochigi","","39151","1392551265"
"La Rinconada","La Rinconada","37.4833","-5.9667","Spain","ES","ESP","Andalusia","","39509","1724417340"
"Kalingalan Caluang","Kalingalan Caluang","5.8833","121.2667","Philippines","PH","PHL","Sulu","","39549","1608192303"
"Todos Santos Cuchumatán","Todos Santos Cuchumatan","15.5116","-91.6051","Guatemala","GT","GTM","Huehuetenango","minor","39638","1320616032"
"Buldon","Buldon","7.5167","124.3667","Philippines","PH","PHL","Maguindanao","","39684","1608528294"
"Sogod","Sogod","10.7500","124.0000","Philippines","PH","PHL","Cebu","","39447","1608748948"
"Talacogon","Talacogon","8.4488","125.7869","Philippines","PH","PHL","Agusan del Sur","","39678","1608543218"
"Niederkassel","Niederkassel","50.8167","7.0333","Germany","DE","DEU","North Rhine-Westphalia","","38694","1276316077"
"Steyr","Steyr","48.0333","14.4167","Austria","AT","AUT","Oberösterreich","minor","38331","1040132113"
"Benenitra","Benenitra","-23.4522","45.0781","Madagascar","MG","MDG","Toliara","","39766","1450877393"
"Caraga","Caraga","7.3283","126.5658","Philippines","PH","PHL","Davao Oriental","","39704","1608528892"
"Salcedo","Salcedo","19.3780","-70.4180","Dominican Republic","DO","DOM","Cibao Nordeste","minor","39557","1214901060"
"Pirna","Pirna","50.9622","13.9403","Germany","DE","DEU","Saxony","minor","39027","1276247543"
"Isfisor","Isfisor","40.2433","69.7447","Tajikistan","TJ","TJK","Sughd","","39590","1762426322"
"Solan","Solan","30.9050","77.0970","India","IN","IND","Himāchal Pradesh","","39256","1356105721"
"Shengang","Shengang","24.1648","120.4886","Taiwan","TW","TWN","Changhua","","38019","1158213803"
"Shankarpur Khawās","Shankarpur Khawas","25.2016","87.1496","India","IN","IND","Bihār","","38092","1356813629"
"Mettmann","Mettmann","51.2500","6.9667","Germany","DE","DEU","North Rhine-Westphalia","minor","38829","1276000749"
"Lloret de Mar","Lloret de Mar","41.7000","2.8333","Spain","ES","ESP","Catalonia","","38941","1724676299"
"Plasencia","Plasencia","40.0275","-6.0908","Spain","ES","ESP","Extremadura","","39558","1724951100"
"Asamankese","Asamankese","5.8667","-0.6667","Ghana","GH","GHA","Eastern","","39435","1288419778"
"Streamwood","Streamwood","42.0209","-88.1779","United States","US","USA","Illinois","","39736","1840011328"
"Città di Castello","Citta di Castello","43.4608","12.2439","Italy","IT","ITA","Umbria","","39632","1380983952"
"Tafí Viejo","Tafi Viejo","-26.7333","-65.2667","Argentina","AR","ARG","Tucumán","minor","39601","1032663351"
"Santo Antônio","Santo Antonio","-29.8178","-50.5200","Brazil","BR","BRA","Rio Grande do Sul","","39685","1076978577"
"Yomra","Yomra","40.9667","39.9000","Turkey","TR","TUR","Trabzon","minor","39624","1792187318"
"Arucas","Arucas","28.1184","-15.5232","Spain","ES","ESP","Canary Islands","","38535","1724017455"
"Quincy","Quincy","39.9336","-91.3799","United States","US","USA","Illinois","","39687","1840009466"
"Ţabas","Tabas","33.5958","56.9244","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","39676","1364599694"
"Khāchrod","Khachrod","23.4232","75.2819","India","IN","IND","Madhya Pradesh","","39382","1356269056"
"Schio","Schio","45.7111","11.3556","Italy","IT","ITA","Veneto","","39082","1380627888"
"Alicia","Alicia","7.5060","122.9412","Philippines","PH","PHL","Zamboanga Sibugay","","39456","1608675842"
"Xonqa","Xonqa","41.4742","60.7822","Uzbekistan","UZ","UZB","Xorazm","minor","39400","1860971478"
"Manay","Manay","7.2108","126.5328","Philippines","PH","PHL","Davao Oriental","","39572","1608580743"
"Rāni","Rani","25.5564","85.9021","India","IN","IND","Bihār","","37763","1356670123"
"La Chaux-de-Fonds","La Chaux-de-Fonds","47.1013","6.8250","Switzerland","CH","CHE","Neuchâtel","minor","38965","1756289727"
"Socorro","Socorro","-22.5908","-46.5289","Brazil","BR","BRA","São Paulo","","39565","1076716761"
"Modugno","Modugno","41.0833","16.7833","Italy","IT","ITA","Puglia","","38453","1380946578"
"Novodvinsk","Novodvinsk","64.4167","40.8333","Russia","RU","RUS","Arkhangel’skaya Oblast’","","38434","1643592717"
"Rosário","Rosario","-2.9339","-44.2350","Brazil","BR","BRA","Maranhão","","39576","1076804900"
"Ramallah","Ramallah","31.9000","35.2000","West Bank","XW","XWB","","","38998","1934604374"
"Charaut","Charaut","26.5322","85.7942","India","IN","IND","Bihār","","37775","1356303082"
"Valrico","Valrico","27.9193","-82.2293","United States","US","USA","Florida","","39632","1840028790"
"Joué-lés-Tours","Joue-les-Tours","47.3514","0.6625","France","FR","FRA","Centre-Val de Loire","","38444","1250289965"
"Marratxi","Marratxi","39.6421","2.7527","Spain","ES","ESP","Balearic Islands","","38902","1724429400"
"Arcos","Arcos","-20.2914","-45.5397","Brazil","BR","BRA","Minas Gerais","","39537","1076650376"
"Villa del Carbón","Villa del Carbon","19.7272","-99.5417","Mexico","MX","MEX","México","minor","39587","1484004677"
"Seram","Seram","17.1830","77.2830","India","IN","IND","Karnātaka","","39341","1356028650"
"Takikawa","Takikawa","43.5578","141.9104","Japan","JP","JPN","Hokkaidō","","39266","1392063126"
"Stirling","Stirling","56.1166","-3.9369","United Kingdom","GB","GBR","Stirling","","37610","1826517007"
"Indian Trail","Indian Trail","35.0699","-80.6452","United States","US","USA","North Carolina","","39603","1840016453"
"Stendal","Stendal","52.6000","11.8500","Germany","DE","DEU","Saxony-Anhalt","minor","39439","1276016814"
"Tianchang","Tianchang","37.9981","114.0156","China","CN","CHN","Hebei","","38656","1156032204"
"Sanjiang","Sanjiang","24.7265","112.2884","China","CN","CHN","Guangdong","minor","39398","1156011652"
"San Giuliano Milanese","San Giuliano Milanese","45.4000","9.2833","Italy","IT","ITA","Lombardy","","38314","1380528525"
"Xiushui","Xiushui","24.0356","120.5037","Taiwan","TW","TWN","Changhua","","38248","1158521382"
"Oneşti","Onesti","46.2586","26.7692","Romania","RO","ROU","Bacău","","39172","1642860854"
"Farīmān","Fariman","35.7069","59.8500","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","39515","1364504660"
"Vetapālem","Vetapalem","15.7800","80.3200","India","IN","IND","Andhra Pradesh","","38671","1356538116"
"Trofa","Trofa","41.3374","-8.5596","Portugal","PT","PRT","Porto","minor","38999","1620889916"
"Yangfang","Yangfang","40.5723","115.0301","China","CN","CHN","Hebei","","38922","1156841476"
"San José de Ocoa","San Jose de Ocoa","18.5500","-70.5000","Dominican Republic","DO","DOM","Valdesia","minor","39451","1214866446"
"Gradačac","Gradacac","44.8789","18.4258","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","39340","1070347743"
"Mitsuke","Mitsuke","37.5316","138.9127","Japan","JP","JPN","Niigata","","39024","1392999982"
"Pokrov","Pokrov","47.6533","34.0842","Ukraine","UA","UKR","Dnipropetrovska Oblast","","39497","1804743879"
"Narón","Naron","43.5500","-8.1500","Spain","ES","ESP","Galicia","","38938","1724543353"
"Oliveira","Oliveira","-20.6958","-44.8269","Brazil","BR","BRA","Minas Gerais","","39466","1076723364"
"San Juan del Cesar","San Juan del Cesar","10.7667","-73.0000","Colombia","CO","COL","La Guajira","minor","39472","1170398526"
"Benjamin Constant","Benjamin Constant","-4.3755","-70.0318","Brazil","BR","BRA","Amazonas","","39484","1076933556"
"Damulog","Damulog","7.4853","124.9411","Philippines","PH","PHL","Bukidnon","","39322","1608647933"
"Niutuo","Niutuo","39.2618","116.3421","China","CN","CHN","Hebei","","38947","1156020027"
"Hobbs","Hobbs","32.7281","-103.1600","United States","US","USA","New Mexico","","39476","1840020616"
"Doğanşehir","Dogansehir","38.0958","37.8792","Turkey","TR","TUR","Malatya","minor","39454","1792000270"
"Tlalixcoyan","Tlalixcoyan","18.8031","-96.0611","Mexico","MX","MEX","Veracruz","minor","37857","1484527678"
"Presidente Venceslau","Presidente Venceslau","-21.8761","-51.8439","Brazil","BR","BRA","São Paulo","","39407","1076627824"
"Santa Maria da Boa Vista","Santa Maria da Boa Vista","-8.8089","-39.8250","Brazil","BR","BRA","Pernambuco","","39435","1076034588"
"Çiftlikköy","Ciftlikkoy","40.6603","29.3236","Turkey","TR","TUR","Yalova","minor","39110","1792919002"
"Carini","Carini","38.1333","13.1833","Italy","IT","ITA","Sicilia","","38936","1380895460"
"Sayansk","Sayansk","54.1167","102.1667","Russia","RU","RUS","Irkutskaya Oblast’","","38968","1643517823"
"Abinsk","Abinsk","44.8667","38.1500","Russia","RU","RUS","Krasnodarskiy Kray","minor","39374","1643216828"
"Parral","Parral","-36.1500","-71.8333","Chile","CL","CHL","Maule","","39404","1152767087"
"Rusape","Rusape","-18.5367","32.1247","Zimbabwe","ZW","ZWE","Manicaland","","37906","1716672253"
"Tonbridge","Tonbridge","51.1987","0.2764","United Kingdom","GB","GBR","Kent","","38657","1826806502"
"Tangpingcun","Tangpingcun","22.0292","111.9346","China","CN","CHN","Guangdong","","39302","1156577035"
"Cape Girardeau","Cape Girardeau","37.3109","-89.5597","United States","US","USA","Missouri","","39415","1840007598"
"Eccles","Eccles","53.4824","-2.3390","United Kingdom","GB","GBR","Salford","","38756","1826711263"
"Oviedo","Oviedo","28.6580","-81.1872","United States","US","USA","Florida","","39405","1840015089"
"Tāki","Taki","22.5900","88.9200","India","IN","IND","West Bengal","","38263","1356065941"
"Whanganui","Whanganui","-39.9325","175.0519","New Zealand","NZ","NZL","Manawatu-Wanganui","","39400","1554827998"
"Axochiapan","Axochiapan","18.5000","-98.7500","Mexico","MX","MEX","Morelos","minor","39174","1484424671"
"Sirohi","Sirohi","24.8850","72.8625","India","IN","IND","Rājasthān","","39229","1356102938"
"Sual","Sual","16.0661","120.0958","Philippines","PH","PHL","Pangasinan","","39091","1608276228"
"Teboulba","Teboulba","35.6700","10.9000","Tunisia","TN","TUN","Monastir","","37485","1788404428"
"Pehowa","Pehowa","29.9800","76.5800","India","IN","IND","Haryāna","","38853","1356877923"
"Draa el Mizan","Draa el Mizan","36.5333","3.8333","Algeria","DZ","DZA","Bouira","","38886","1012563094"
"Canela","Canela","-29.3658","-50.8158","Brazil","BR","BRA","Rio Grande do Sul","","39229","1076049094"
"Abrantes","Abrantes","39.4633","-8.1975","Portugal","PT","PRT","Santarém","minor","39325","1620417746"
"Moanda","Moanda","-1.5667","13.2000","Gabon","GA","GAB","Haut-Ogooué","","39298","1266295581"
"Sopot","Sopot","54.4500","18.5667","Poland","PL","POL","Pomorskie","minor","37457","1616375300"
"Apan","Apan","19.7000","-98.4333","Mexico","MX","MEX","Hidalgo","minor","39247","1484086106"
"Puerto Berrío","Puerto Berrio","6.4900","-74.4025","Colombia","CO","COL","Antioquia","minor","39314","1170372171"
"San Dionisio","San Dionisio","11.2711","123.0948","Philippines","PH","PHL","Iloilo","","39048","1608238884"
"Shibata","Shibata","38.0566","140.7658","Japan","JP","JPN","Miyagi","","38638","1392003080"
"Tamba-Sasayama","Tamba-Sasayama","35.0725","135.2219","Japan","JP","JPN","Hyōgo","","39245","1392003223"
"Shijiazhuangnan","Shijiazhuangnan","37.9383","114.4453","China","CN","CHN","Hebei","","38403","1156203505"
"Warren","Warren","41.2390","-80.8174","United States","US","USA","Ohio","","39334","1840000652"
"São Francisco do Conde","Sao Francisco do Conde","-12.6278","-38.6800","Brazil","BR","BRA","Bahia","","39329","1076969118"
"Mapandan","Mapandan","16.0260","120.4540","Philippines","PH","PHL","Pangasinan","","38058","1608000894"
"Rosário do Sul","Rosario do Sul","-30.2578","-54.9139","Brazil","BR","BRA","Rio Grande do Sul","","39314","1076900712"
"Cacuso","Cacuso","-8.2333","15.3500","Angola","AO","AGO","Cuanza-Norte","","39302","1024893780"
"Marcos Paz","Marcos Paz","-34.7667","-58.8333","Argentina","AR","ARG","Buenos Aires","minor","39151","1032271998"
"Køge","Koge","55.4561","12.1797","Denmark","DK","DNK","Sjælland","minor","38304","1208980613"
"Oraiókastro","Oraiokastro","40.7333","22.9167","Greece","GR","GRC","Kentrikí Makedonía","minor","38317","1300041149"
"Cabugao","Cabugao","17.7947","120.4556","Philippines","PH","PHL","Ilocos Sur","","38884","1608084710"
"Tarazá","Taraza","7.5881","-75.4028","Colombia","CO","COL","Antioquia","minor","39257","1170830485"
"Kirkkonummi","Kirkkonummi","60.1167","24.4167","Finland","FI","FIN","Uusimaa","minor","39170","1246425379"
"Betamcherla","Betamcherla","15.4667","78.1667","India","IN","IND","Andhra Pradesh","","38994","1356573340"
"Thakraha","Thakraha","26.7419","84.2562","India","IN","IND","Bihār","","38063","1356337463"
"Sikandra Rao","Sikandra Rao","27.7000","78.4000","India","IN","IND","Uttar Pradesh","","38485","1356029003"
"Plant City","Plant City","28.0140","-82.1201","United States","US","USA","Florida","","39272","1840015115"
"Sandino","Sandino","22.0803","-84.2060","Cuba","CU","CUB","Pinar del Río","","39245","1192743176"
"Higashimatsushima","Higashimatsushima","38.4263","141.2104","Japan","JP","JPN","Miyagi","","38880","1392705600"
"Sidi Bibi","Sidi Bibi","30.2333","-9.5333","Morocco","MA","MAR","Souss-Massa","","39042","1504682772"
"Basista","Basista","15.8524","120.3976","Philippines","PH","PHL","Pangasinan","","37679","1608619317"
"Ilmenau","Ilmenau","50.6839","10.9194","Germany","DE","DEU","Thuringia","","39017","1276541405"
"Ospino","Ospino","9.3000","-69.4500","Venezuela","VE","VEN","Portuguesa","minor","39215","1862899775"
"Amla","Amla","21.9248","78.1279","India","IN","IND","Madhya Pradesh","","39009","1356126635"
"Szczecinek","Szczecinek","53.7167","16.6833","Poland","PL","POL","Zachodniopomorskie","minor","38446","1616218175"
"San Raimundo","San Raimundo","14.7647","-90.5956","Guatemala","GT","GTM","Guatemala","minor","38891","1320612138"
"Sungandiancun","Sungandiancun","36.2221","115.3246","China","CN","CHN","Hebei","","38542","1156441211"
"Yelizovo","Yelizovo","53.1833","158.3833","Russia","RU","RUS","Kamchatskiy Kray","","39216","1643784414"
"Brasschaat","Brasschaat","51.2931","4.4894","Belgium","BE","BEL","Flanders","","38223","1056299309"
"Waregem","Waregem","50.8811","3.4001","Belgium","BE","BEL","Flanders","","38347","1056938686"
"Penumūr","Penumur","13.3667","79.1833","India","IN","IND","Andhra Pradesh","","38912","1356599733"
"Caluya","Caluya","11.9320","121.5480","Philippines","PH","PHL","Antique","","38908","1608445589"
"Salinas","Salinas","-16.1185","-42.1740","Brazil","BR","BRA","Minas Gerais","","39178","1076633358"
"Cayambe","Cayambe","0.0439","-78.1561","Ecuador","EC","ECU","Pichincha","","39028","1218475970"
"Birmitrapur","Birmitrapur","22.4000","84.7667","India","IN","IND","Odisha","","38870","1356055853"
"Urbana","Urbana","40.1107","-88.1973","United States","US","USA","Illinois","","39189","1840010451"
"Manggar","Manggar","-2.8900","108.2700","Indonesia","ID","IDN","Kepulauan Bangka Belitung","minor","39135","1360793335"
"Jacarèzinho","Jacarezinho","-23.1608","-49.9689","Brazil","BR","BRA","Paraná","","39121","1076955757"
"Tuntum","Tuntum","-5.2578","-44.6489","Brazil","BR","BRA","Maranhão","","39183","1076818343"
"Dubbo","Dubbo","-32.2569","148.6011","Australia","AU","AUS","New South Wales","","38943","1036855042"
"Androka","Androka","-25.1167","44.4500","Madagascar","MG","MDG","Toliara","","39168","1450742982"
"Dwārka","Dwarka","22.2403","68.9686","India","IN","IND","Gujarāt","","38873","1356276872"
"José María Morelos","Jose Maria Morelos","19.7500","-88.7000","Mexico","MX","MEX","Quintana Roo","minor","39165","1484872600"
"Xiwanzi","Xiwanzi","40.9717","115.2737","China","CN","CHN","Hebei","minor","38879","1156869748"
"Baikonur","Baikonur","45.6167","63.3167","Kazakhstan","KZ","KAZ","","","39161","1398535530"
"San Fernando","San Fernando","13.5661","123.1422","Philippines","PH","PHL","Camarines Sur","","38626","1608187766"
"Changchunpu","Changchunpu","27.2388","105.1850","China","CN","CHN","Guizhou","","38896","1156848766"
"Colinas","Colinas","-6.0258","-44.2489","Brazil","BR","BRA","Maranhão","","39132","1076657010"
"Sama","Sama","43.3000","-5.6833","Spain","ES","ESP","Asturias","","38683","1724748229"
"São Mateus do Maranhão","Sao Mateus do Maranhao","-4.0400","-44.4700","Brazil","BR","BRA","Maranhão","","39093","1076550429"
"Olive Branch","Olive Branch","34.9610","-89.8469","United States","US","USA","Mississippi","","39136","1840014641"
"Al Mālikīyah","Al Malikiyah","37.1667","42.1333","Syria","SY","SYR","Al Ḩasakah","minor","39000","1760784945"
"Santa Helena","Santa Helena","-2.2308","-45.3000","Brazil","BR","BRA","Maranhão","","39110","1076790462"
"Ílhavo","Ilhavo","40.6000","-8.6667","Portugal","PT","PRT","Aveiro","minor","38598","1620545975"
"Susurluk","Susurluk","39.9139","28.1603","Turkey","TR","TUR","Balıkesir","minor","39058","1792726063"
"Wesseling","Wesseling","50.8167","6.9667","Germany","DE","DEU","North Rhine-Westphalia","","37519","1276392642"
"Monreale","Monreale","38.0817","13.2889","Italy","IT","ITA","Sicilia","","39047","1380377076"
"Ambatomainty","Ambatomainty","-17.6833","48.2167","Madagascar","MG","MDG","Toamasina","","39000","1450199483"
"Kachkanar","Kachkanar","58.7000","59.4833","Russia","RU","RUS","Sverdlovskaya Oblast’","","38996","1643014299"
"Gabaldon","Gabaldon","15.4506","121.3378","Philippines","PH","PHL","Nueva Ecija","","38958","1608599479"
"Souma","Souma","36.5183","2.9053","Algeria","DZ","DZA","Blida","","37461","1012378735"
"Gorinchem","Gorinchem","51.8306","4.9742","Netherlands","NL","NLD","Zuid-Holland","minor","37410","1528005855"
"Severna Park","Severna Park","39.0870","-76.5687","United States","US","USA","Maryland","","39108","1840005926"
"Wheeling","Wheeling","42.1308","-87.9240","United States","US","USA","Illinois","","39104","1840011334"
"Landgraaf","Landgraaf","50.9083","6.0297","Netherlands","NL","NLD","Limburg","minor","37591","1528095847"
"Nuevitas","Nuevitas","21.5403","-77.2644","Cuba","CU","CUB","Camagüey","","38995","1192448182"
"Bougara","Bougara","36.5333","3.0833","Algeria","DZ","DZA","Blida","","38352","1012764987"
"Mehidpur","Mehidpur","23.4888","75.6580","India","IN","IND","Madhya Pradesh","","38780","1356586110"
"Roy","Roy","41.1714","-112.0485","United States","US","USA","Utah","","39076","1840020132"
"Penfield","Penfield","43.1602","-77.4483","United States","US","USA","New York","","39074","1840058391"
"Toumodi","Toumodi","6.5520","-5.0190","Côte d'Ivoire","CI","CIV","Lacs","minor","39005","1384416676"
"Bangar","Bangar","16.9000","120.4167","Philippines","PH","PHL","La Union","","38041","1608636378"
"Issaquah","Issaquah","47.5439","-122.0471","United States","US","USA","Washington","","39057","1840018422"
"Inashiki","Inashiki","35.9565","140.3239","Japan","JP","JPN","Ibaraki","","38853","1392230730"
"Kurchatov","Kurchatov","51.6667","35.6500","Russia","RU","RUS","Kurskaya Oblast’","","38344","1643764083"
"Caivano","Caivano","40.9500","14.3000","Italy","IT","ITA","Campania","","37654","1380551654"
"Pala Oua","Pala Oua","9.3500","14.9667","Chad","TD","TCD","Mayo-Kebbi-Ouest","","38975","1148631807"
"Rtishchevo","Rtishchevo","52.2667","43.7667","Russia","RU","RUS","Saratovskaya Oblast’","","39005","1643692612"
"Los Palacios y Villafranca","Los Palacios y Villafranca","37.1625","-5.9242","Spain","ES","ESP","Andalusia","","38678","1724334949"
"Ancud","Ancud","-41.8667","-73.8333","Chile","CL","CHL","Los Lagos","","38991","1152760614"
"Margosatubig","Margosatubig","7.5783","123.1659","Philippines","PH","PHL","Zamboanga del Sur","","38660","1608570803"
"Valencia","Valencia","9.2833","123.2500","Philippines","PH","PHL","Negros Oriental","","38733","1608009046"
"Yoshinogawa","Yoshinogawa","34.0631","134.3614","Japan","JP","JPN","Tokushima","","38726","1392003198"
"Dibaya-Lubwe","Dibaya-Lubwe","-4.1500","19.8667","Congo (Kinshasa)","CD","COD","Kwilu","","38933","1180208280"
"Itabaianinha","Itabaianinha","-11.2739","-37.7900","Brazil","BR","BRA","Sergipe","","38910","1076633750"
"Samal","Samal","14.7678","120.5431","Philippines","PH","PHL","Bataan","","38302","1608128409"
"Kadiyam","Kadiyam","16.9167","81.8333","India","IN","IND","Andhra Pradesh","","37149","1356803625"
"Vasylkiv","Vasylkiv","50.1775","30.3217","Ukraine","UA","UKR","Kyivska Oblast","","37696","1804342314"
"Buchireddipālem","Buchireddipalem","14.5380","79.8750","India","IN","IND","Andhra Pradesh","","38405","1356799521"
"Mira","Mira","45.4375","12.1329","Italy","IT","ITA","Veneto","","38573","1380620162"
"Umi","Umi","33.5675","130.5111","Japan","JP","JPN","Fukuoka","","37713","1392401859"
"Westerville","Westerville","40.1241","-82.9210","United States","US","USA","Ohio","","38960","1840003763"
"Hitachiomiya","Hitachiomiya","36.5425","140.4109","Japan","JP","JPN","Ibaraki","","38836","1392000308"
"Tshela","Tshela","-4.9667","12.9333","Congo (Kinshasa)","CD","COD","Kongo Central","","38845","1180377922"
"Dubno","Dubno","50.3931","25.7350","Ukraine","UA","UKR","Rivnenska Oblast","minor","37545","1804759407"
"Galeana","Galeana","24.8333","-100.0667","Mexico","MX","MEX","Nuevo León","minor","38930","1484336701"
"Akçakoca","Akcakoca","41.0833","31.1167","Turkey","TR","TUR","Düzce","minor","38846","1792736613"
"Khairtal","Khairtal","27.8346","76.6388","India","IN","IND","Rājasthān","","38298","1356005235"
"Teijlingen","Teijlingen","52.2150","4.5103","Netherlands","NL","NLD","Zuid-Holland","","37791","1528639045"
"Grants Pass","Grants Pass","42.4333","-123.3317","United States","US","USA","Oregon","","38902","1840020087"
"Bitterfeld","Bitterfeld","51.6167","12.3167","Germany","DE","DEU","Saxony-Anhalt","","38475","1276169908"
"Marinha Grande","Marinha Grande","39.7500","-8.9333","Portugal","PT","PRT","Leiria","minor","38681","1620186271"
"Ōmagari","Omagari","39.4531","140.4754","Japan","JP","JPN","Akita","","38794","1392052360"
"Sakuragawa","Sakuragawa","36.3273","140.0906","Japan","JP","JPN","Ibaraki","","38677","1392000708"
"Haverstraw","Haverstraw","41.2055","-74.0384","United States","US","USA","New York","","38872","1840004975"
"Taşköprü","Taskopru","41.5097","34.2142","Turkey","TR","TUR","Kastamonu","minor","38849","1792159059"
"Altos","Altos","-5.0389","-42.4608","Brazil","BR","BRA","Piauí","","38822","1076874326"
"Lluchmayor","Lluchmayor","39.4900","2.8898","Spain","ES","ESP","Balearic Islands","","38722","1724033667"
"Skelmersdale","Skelmersdale","53.5500","-2.7760","United Kingdom","GB","GBR","Lancashire","","38813","1826978666"
"Campina Grande do Sul","Campina Grande do Sul","-25.3058","-49.0550","Brazil","BR","BRA","Paraná","","38769","1076807751"
"Bautzen","Bautzen","51.1814","14.4242","Germany","DE","DEU","Saxony","minor","38261","1276088190"
"Veghel","Veghel","51.6167","5.5500","Netherlands","NL","NLD","Noord-Brabant","minor","38077","1528658070"
"Mission","Mission","49.1337","-122.3112","Canada","CA","CAN","British Columbia","","38833","1124502601"
"Huzūrābād","Huzurabad","18.2000","79.4200","India","IN","IND","Andhra Pradesh","","37665","1356139547"
"Ibusuki","Ibusuki","31.2528","130.6331","Japan","JP","JPN","Kagoshima","","38571","1392003168"
"Pervomaisk","Pervomaisk","48.6297","38.5619","Ukraine","UA","UKR","Luhanska Oblast","","38801","1804078779"
"Rājula","Rajula","21.0500","71.4300","India","IN","IND","Gujarāt","","38489","1356495012"
"Sarikishty","Sarikishty","38.4667","68.7000","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","38474","1762415528"
"Dabola","Dabola","10.7500","-11.1167","Guinea","GN","GIN","Faranah","minor","38617","1324368075"
"Tuusula","Tuusula","60.4333","25.0500","Finland","FI","FIN","Uusimaa","minor","38646","1246218223"
"Fürstenfeldbruck","Furstenfeldbruck","48.1778","11.2556","Germany","DE","DEU","Bavaria","minor","37677","1276347995"
"Yako","Yako","12.9667","-2.2667","Burkina Faso","BF","BFA","Nord","minor","38689","1854815200"
"Lynnwood","Lynnwood","47.8284","-122.3033","United States","US","USA","Washington","","38812","1840019788"
"Calexico","Calexico","32.6849","-115.4944","United States","US","USA","California","","38798","1840019379"
"Cajibío","Cajibio","2.6333","-76.6333","Colombia","CO","COL","Cauca","minor","38703","1170570874"
"Gujō","Gujo","35.7486","136.9643","Japan","JP","JPN","Gifu","","38747","1392159452"
"Aborlan","Aborlan","9.4386","118.5481","Philippines","PH","PHL","Palawan","","38736","1608193671"
"Los Palacios","Los Palacios","22.5822","-83.2489","Cuba","CU","CUB","Pinar del Río","minor","38731","1192924706"
"Olutanga","Olutanga","7.3106","122.8464","Philippines","PH","PHL","Zamboanga Sibugay","","38438","1608000162"
"Simão Dias","Simao Dias","-10.7378","-37.8108","Brazil","BR","BRA","Sergipe","","38702","1076449666"
"Nishiwaki","Nishiwaki","34.9903","134.9722","Japan","JP","JPN","Hyōgo","","38479","1392948535"
"Jamindan","Jamindan","11.4094","122.5103","Philippines","PH","PHL","Capiz","","38670","1608186987"
"Maasin","Maasin","10.8833","122.4333","Philippines","PH","PHL","Iloilo","","38461","1608867615"
"Naushahro Firoz","Naushahro Firoz","26.8422","68.1228","Pakistan","PK","PAK","Sindh","minor","38181","1586454087"
"Real","Real","14.6667","121.6000","Philippines","PH","PHL","Quezon","","38678","1608401187"
"Dzhankoi","Dzhankoi","45.7086","34.3933","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","38714","1804654537"
"Barbacoas","Barbacoas","1.6717","-78.1397","Colombia","CO","COL","Nariño","minor","38708","1170561863"
"Alingsås","Alingsas","57.9300","12.5331","Sweden","SE","SWE","Västra Götaland","minor","38509","1752378437"
"Sibaté","Sibate","4.4908","-74.2594","Colombia","CO","COL","Cundinamarca","minor","38412","1170223176"
"Braintree","Braintree","42.2039","-71.0022","United States","US","USA","Massachusetts","","38712","1840132398"
"Buug","Buug","7.7286","123.0608","Philippines","PH","PHL","Zamboanga Sibugay","","38425","1608366123"
"Mamun","Mamun","32.2824","75.6983","India","IN","IND","Punjab","","37746","1356488565"
"San Andres","San Andres","13.5961","124.0992","Philippines","PH","PHL","Catanduanes","","38480","1608257765"
"Champerico","Champerico","14.2930","-91.9140","Guatemala","GT","GTM","Retalhuleu","minor","38570","1320124316"
"Royal Palm Beach","Royal Palm Beach","26.7038","-80.2241","United States","US","USA","Florida","","38697","1840018309"
"Mechelen-aan-de-Maas","Mechelen-aan-de-Maas","50.9967","5.7025","Belgium","BE","BEL","Flanders","","38193","1056407060"
"Great Yarmouth","Great Yarmouth","52.6060","1.7290","United Kingdom","GB","GBR","Norfolk","","38693","1826653059"
"Nové Zámky","Nove Zamky","47.9856","18.1578","Slovakia","SK","SVK","Nitra","minor","38172","1703734556"
"Tōkai","Tokai","36.4730","140.5661","Japan","JP","JPN","Ibaraki","","37700","1392003127"
"Groton","Groton","41.3598","-72.0293","United States","US","USA","Connecticut","","38686","1840004824"
"Königs Wusterhausen","Konigs Wusterhausen","52.2917","13.6250","Germany","DE","DEU","Brandenburg","","38283","1276871907"
"Metlaoui","Metlaoui","34.3333","8.4000","Tunisia","TN","TUN","Gafsa","","38634","1788302066"
"Land O' Lakes","Land O' Lakes","28.2075","-82.4476","United States","US","USA","Florida","","38674","1840014120"
"Misawa","Misawa","40.6831","141.3691","Japan","JP","JPN","Aomori","","38351","1392268760"
"Vangaindrano","Vangaindrano","-23.3500","47.6000","Madagascar","MG","MDG","Fianarantsoa","","38537","1450641606"
"La Unión","La Union","14.9667","-89.2833","Guatemala","GT","GTM","Zacapa","minor","38473","1320575011"
"San José de Las Matas","San Jose de Las Matas","19.3300","-70.9300","Dominican Republic","DO","DOM","Cibao Norte","","38628","1214663551"
"Ábrego","Abrego","8.0000","-73.2000","Colombia","CO","COL","Norte de Santander","minor","38627","1170272362"
"Mariinsk","Mariinsk","56.2000","87.7833","Russia","RU","RUS","Kemerovskaya Oblast’","","38637","1643015007"
"Bayan","Bayan","36.1148","102.2476","China","CN","CHN","Qinghai","","38315","1156819321"
"Xiaguanying","Xiaguanying","35.9427","104.1717","China","CN","CHN","Gansu","","38342","1156137191"
"Guariba","Guariba","-21.3600","-48.2283","Brazil","BR","BRA","São Paulo","","38499","1076624279"
"Az̧ Z̧āhirīyah","Az Zahiriyah","31.4078","34.9722","West Bank","XW","XWB","","","38002","1934407195"
"Alderetes","Alderetes","-26.8167","-65.1333","Argentina","AR","ARG","Tucumán","","38466","1032427367"
"Bettendorf","Bettendorf","41.5657","-90.4765","United States","US","USA","Iowa","","38631","1840007079"
"Krasnoufimsk","Krasnoufimsk","56.6167","57.7722","Russia","RU","RUS","Sverdlovskaya Oblast’","","38395","1643806300"
"Muaná","Muana","-1.5278","-49.2169","Brazil","BR","BRA","Pará","","38616","1076581165"
"Nindirí","Nindiri","12.0031","-86.1178","Nicaragua","NI","NIC","Masaya","minor","38355","1558216045"
"Darāw","Daraw","24.4000","32.9167","Egypt","EG","EGY","Aswān","","38400","1818705709"
"San Felipe Orizatlán","San Felipe Orizatlan","21.1719","-98.6064","Mexico","MX","MEX","Hidalgo","","38472","1484942420"
"Pacifica","Pacifica","37.6113","-122.4782","United States","US","USA","California","","38595","1840020307"
"Nigel","Nigel","-26.4203","28.4683","South Africa","ZA","ZAF","Gauteng","","38318","1710146906"
"Lanyi","Lanyi","38.7048","111.5601","China","CN","CHN","Shanxi","minor","38431","1156840616"
"Żary","Zary","51.6333","15.1333","Poland","PL","POL","Lubuskie","minor","37502","1616092414"
"Cottage Grove","Cottage Grove","44.8161","-92.9274","United States","US","USA","Minnesota","","38582","1840006735"
"Uryupinsk","Uryupinsk","50.8000","42.0167","Russia","RU","RUS","Volgogradskaya Oblast’","","37620","1643866080"
"Yuzhnoukrainsk","Yuzhnoukrainsk","47.8217","31.1750","Ukraine","UA","UKR","Mykolaivska Oblast","minor","38560","1804000719"
"Monan","Monan","38.2621","115.9504","China","CN","CHN","Hebei","","38158","1156302491"
"Phulbāni","Phulbani","20.4700","84.2300","India","IN","IND","Odisha","","37371","1356638918"
"Boa Esperança","Boa Esperanca","-21.0900","-45.5658","Brazil","BR","BRA","Minas Gerais","","38516","1076371124"
"Falāvarjān","Falavarjan","32.5553","51.5097","Iran","IR","IRN","Eşfahān","minor","37740","1364269609"
"Gomoh","Gomoh","23.8735","86.1516","India","IN","IND","Jhārkhand","","37734","1356304811"
"Dubrājpur","Dubrajpur","23.8000","87.3800","India","IN","IND","West Bengal","","38041","1356740995"
"Patnongon","Patnongon","10.9142","121.9947","Philippines","PH","PHL","Antique","","38329","1608542383"
"Minas","Minas","21.4894","-77.6050","Cuba","CU","CUB","Camagüey","minor","38517","1192839153"
"Kardítsa","Karditsa","39.3667","21.9167","Greece","GR","GRC","Thessalía","minor","38554","1300471729"
"Khalkhāl","Khalkhal","37.6272","48.5300","Iran","IR","IRN","Ardabīl","minor","38521","1364932128"
"Kakching","Kakching","24.6085","94.0029","India","IN","IND","Manipur","","37915","1356794087"
"Caibarién","Caibarien","22.5158","-79.4722","Cuba","CU","CUB","Villa Clara","minor","38479","1192208164"
"Jarosław","Jaroslaw","50.0186","22.6797","Poland","PL","POL","Podkarpackie","minor","37479","1616590558"
"Konakovo","Konakovo","56.7000","36.7500","Russia","RU","RUS","Tverskaya Oblast’","","38486","1643330779"
"Siyang","Siyang","27.2116","108.7463","China","CN","CHN","Guizhou","minor","38261","1156030306"
"Itiúba","Itiuba","-10.6908","-39.8528","Brazil","BR","BRA","Bahia","","38492","1076953529"
"Américo Brasiliense","Americo Brasiliense","-21.7361","-48.1114","Brazil","BR","BRA","São Paulo","","38202","1076027153"
"Backnang","Backnang","48.9464","9.4306","Germany","DE","DEU","Baden-Württemberg","","37558","1276031423"
"Clovis","Clovis","34.4376","-103.1923","United States","US","USA","New Mexico","","38505","1840019195"
"Vestavia Hills","Vestavia Hills","33.4518","-86.7437","United States","US","USA","Alabama","","38504","1840015673"
"Borbon","Borbon","10.8333","124.0000","Philippines","PH","PHL","Cebu","","38187","1608861452"
"Santo Domingo","Santo Domingo","13.2350","123.7769","Philippines","PH","PHL","Albay","","37765","1608392602"
"Ventanas","Ventanas","-1.4500","-79.4700","Ecuador","EC","ECU","Los Ríos","","38168","1218067203"
"Barru","Barru","-4.3587","119.6399","Indonesia","ID","IDN","Sulawesi Selatan","minor","38333","1360568258"
"Villenave-d’Ornon","Villenave-d'Ornon","44.7806","-0.5658","France","FR","FRA","Nouvelle-Aquitaine","","36754","1250296432"
"Várzea Alegre","Varzea Alegre","-6.7889","-39.2958","Brazil","BR","BRA","Ceará","","38434","1076418638"
"Holyoke","Holyoke","42.2125","-72.6411","United States","US","USA","Massachusetts","","38480","1840000465"
"Yanqi","Yanqi","42.0586","86.5683","China","CN","CHN","Xinjiang","minor","37649","1156635550"
"Villagarcía de Arosa","Villagarcia de Arosa","42.5977","-8.7632","Spain","ES","ESP","Galicia","","37677","1724392038"
"Coto Brus","Coto Brus","8.8890","-82.9092","Costa Rica","CR","CRI","Puntarenas","","38453","1188593631"
"Evans","Evans","33.5619","-82.1351","United States","US","USA","Georgia","","38452","1840013095"
"Curuçá","Curuca","-0.7339","-47.8550","Brazil","BR","BRA","Pará","","38391","1076098229"
"Kamp-Lintfort","Kamp-Lintfort","51.5000","6.5333","Germany","DE","DEU","North Rhine-Westphalia","","37847","1276081102"
"Genç","Genc","38.7500","40.5560","Turkey","TR","TUR","Bingöl","minor","38428","1792927831"
"Chacabuco","Chacabuco","-34.6333","-60.4667","Argentina","AR","ARG","Buenos Aires","minor","38418","1032210547"
"Nakhyaungcharīpāra","Nakhyaungcharipara","21.4167","92.1833","Bangladesh","BD","BGD","Chattogram","","38350","1050252175"
"Antsohihy","Antsohihy","-14.8762","47.9834","Madagascar","MG","MDG","Mahajanga","minor","38253","1450240411"
"San Jacinto de Buena Fe","San Jacinto de Buena Fe","-0.8986","-79.4889","Ecuador","EC","ECU","Los Ríos","","38263","1218724130"
"Hanumānnagar","Hanumannagar","26.5394","86.7483","Nepal","NP","NPL","","","37738","1524363655"
"Tayasan","Tayasan","9.9167","123.1500","Philippines","PH","PHL","Negros Oriental","","38159","1608000640"
"Venlo","Venlo","51.3667","6.1667","Netherlands","NL","NLD","Limburg","minor","37663","1528690544"
"Port Talbot","Port Talbot","51.5906","-3.7986","United Kingdom","GB","GBR","Neath Port Talbot","","37276","1826981147"
"Kaka","Kaka","37.3500","59.6000","Turkmenistan","TM","TKM","Ahal","","38381","1795223342"
"Caltagirone","Caltagirone","37.2375","14.5125","Italy","IT","ITA","Sicilia","","38295","1380958599"
"Ibara","Ibara","34.6000","133.4667","Japan","JP","JPN","Okayama","","38236","1392000586"
"Laur","Laur","15.5797","121.1864","Philippines","PH","PHL","Nueva Ecija","","38263","1608394734"
"Usinsk","Usinsk","66.0000","57.5333","Russia","RU","RUS","Komi","","38376","1643395315"
"Sharypovo","Sharypovo","55.5333","89.2000","Russia","RU","RUS","Krasnoyarskiy Kray","","37136","1643451385"
"Atamyrat","Atamyrat","37.8531","65.2369","Turkmenistan","TM","TKM","Lebap","","38350","1795595010"
"Alekseyevka","Alekseyevka","50.6333","38.7000","Russia","RU","RUS","Belgorodskaya Oblast’","","38329","1643033169"
"Bol’shoy Kamen’","Bol'shoy Kamen'","43.1167","132.3500","Russia","RU","RUS","Primorskiy Kray","","38042","1643747678"
"Gūdārah","Gudarah","34.8169","69.0878","Afghanistan","AF","AFG","Kābul","","38000","1004814762"
"Moncada","Moncada","41.4867","2.1879","Spain","ES","ESP","Catalonia","","36794","1724353233"
"Dom Pedrito","Dom Pedrito","-30.9828","-54.6728","Brazil","BR","BRA","Rio Grande do Sul","","38339","1076917608"
"Torre-Pacheco","Torre-Pacheco","37.7333","-0.9500","Spain","ES","ESP","Murcia","","38140","1724458704"
"Navgilem","Navgilem","40.1333","70.6500","Tajikistan","TJ","TJK","Sughd","","38104","1762623109"
"Şarkışla","Sarkisla","39.3500","36.4000","Turkey","TR","TUR","Sivas","minor","38314","1792917755"
"Katsuragi","Katsuragi","34.4833","135.7333","Japan","JP","JPN","Nara","","37225","1392516369"
"Sabang","Sabang","5.8942","95.3192","Indonesia","ID","IDN","Aceh","","38077","1360742506"
"Grimbergen","Grimbergen","50.9333","4.3833","Belgium","BE","BEL","Flanders","","37355","1056864579"
"Krasnyy Sulin","Krasnyy Sulin","47.8833","40.1000","Russia","RU","RUS","Rostovskaya Oblast’","minor","38284","1643848888"
"Jagdīspur","Jagdispur","25.4674","84.4204","India","IN","IND","Bihār","","37072","1356873599"
"Pathanāmthitta","Pathanamthitta","9.2648","76.7870","India","IN","IND","Kerala","","37802","1356059485"
"Lyudinovo","Lyudinovo","53.8667","34.4333","Russia","RU","RUS","Kaluzhskaya Oblast’","","38267","1643598604"
"Apache Junction","Apache Junction","33.3985","-111.5350","United States","US","USA","Arizona","","38311","1840019365"
"Goribidnūr","Goribidnur","13.6111","77.5167","India","IN","IND","Karnātaka","","37947","1356312142"
"Pitangueiras","Pitangueiras","-21.0094","-48.2217","Brazil","BR","BRA","São Paulo","","38211","1076000181"
"Maule","Maule","-35.5333","-71.7000","Chile","CL","CHL","Maule","","38270","1152958152"
"Zavolzhye","Zavolzhye","56.6500","43.4000","Russia","RU","RUS","Nizhegorodskaya Oblast’","","38230","1643954070"
"Shrewsbury","Shrewsbury","42.2842","-71.7154","United States","US","USA","Massachusetts","","38291","1840053699"
"Pen","Pen","18.7500","73.0800","India","IN","IND","Mahārāshtra","","37852","1356056191"
"Tāsgaon","Tasgaon","17.0300","74.6000","India","IN","IND","Mahārāshtra","","37945","1356421633"
"Gampola","Gampola","7.1647","80.5767","Sri Lanka","LK","LKA","Central","","37871","1144422771"
"Dicle","Dicle","38.3750","40.0733","Turkey","TR","TUR","Diyarbakır","minor","38220","1792095932"
"Bahādurganj","Bahadurganj","26.2617","87.8244","India","IN","IND","Bihār","","37115","1356729142"
"Barrancas","Barrancas","11.0000","-72.7500","Colombia","CO","COL","La Guajira","minor","38232","1170612474"
"Bat Khela","Bat Khela","34.6200","71.9700","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","38222","1586033847"
"Cerveteri","Cerveteri","42.0000","12.1000","Italy","IT","ITA","Lazio","","37977","1380612581"
"Taounate","Taounate","34.5358","-4.6400","Morocco","MA","MAR","Fès-Meknès","","37616","1504565730"
"Cheyyār","Cheyyar","12.6620","79.5435","India","IN","IND","Tamil Nādu","","37802","1356916080"
"Orange","Orange","-33.2833","149.1000","Australia","AU","AUS","New South Wales","","38097","1036760396"
"Su’ao","Su'ao","24.6000","121.8500","Taiwan","TW","TWN","Yilan","","37804","1158318130"
"Anosiala","Anosiala","-18.7833","47.4167","Madagascar","MG","MDG","Antananarivo","","36863","1450613112"
"Menomonee Falls","Menomonee Falls","43.1487","-88.1227","United States","US","USA","Wisconsin","","38219","1840003020"
"Phenix City","Phenix City","32.4585","-85.0253","United States","US","USA","Alabama","","38216","1840006012"
"Dengtangcun","Dengtangcun","23.6821","116.5259","China","CN","CHN","Guangdong","","37983","1156077238"
"Papenburg","Papenburg","53.0667","7.4000","Germany","DE","DEU","Lower Saxony","","37885","1276460693"
"Bagabag","Bagabag","16.6044","121.2521","Philippines","PH","PHL","Nueva Vizcaya","","37985","1608331162"
"Savelugu","Savelugu","9.6167","-0.8333","Ghana","GH","GHA","Northern","","38074","1288849459"
"Luna","Luna","16.8500","120.3833","Philippines","PH","PHL","La Union","","37318","1608663387"
"Guayacanes","Guayacanes","18.4533","-69.4500","Dominican Republic","DO","DOM","Higuamo","","37889","1214783973"
"Xinpo","Xinpo","21.6645","110.8911","China","CN","CHN","Guangdong","","36699","1156116471"
"Sitalkuchi","Sitalkuchi","26.1697","89.1914","India","IN","IND","West Bengal","","37052","1356022394"
"San Juan Cancuc","San Juan Cancuc","16.9333","-92.3833","Mexico","MX","MEX","Chiapas","minor","37948","1484128452"
"Post Falls","Post Falls","47.7213","-116.9384","United States","US","USA","Idaho","","38162","1840019811"
"Izobil’nyy","Izobil'nyy","45.3667","41.7167","Russia","RU","RUS","Stavropol’skiy Kray","","38100","1643012152"
"Xinqiao","Xinqiao","39.2694","116.0797","China","CN","CHN","Hebei","","37583","1156277764"
"Goes","Goes","51.5000","3.8833","Netherlands","NL","NLD","Zeeland","minor","37739","1528134132"
"Raalte","Raalte","52.3833","6.2667","Netherlands","NL","NLD","Overijssel","minor","37911","1528789271"
"La Vergne","La Vergne","36.0200","-86.5586","United States","US","USA","Tennessee","","38127","1840014496"
"Oleiros","Oleiros","43.3333","-8.3166","Spain","ES","ESP","Galicia","","37271","1724989777"
"Vaudreuil-Dorion","Vaudreuil-Dorion","45.4000","-74.0333","Canada","CA","CAN","Quebec","","38117","1124618618"
"Sampués","Sampues","9.1833","-75.3833","Colombia","CO","COL","Sucre","minor","37925","1170198440"
"Rūdsar","Rudsar","37.1375","50.2881","Iran","IR","IRN","Gīlān","minor","37998","1364240467"
"Visconde do Rio Branco","Visconde do Rio Branco","-21.0103","-42.8406","Brazil","BR","BRA","Minas Gerais","","37942","1076854756"
"Boryslav","Boryslav","49.2881","23.4267","Ukraine","UA","UKR","Lvivska Oblast","","37093","1804511652"
"Clifton Park","Clifton Park","42.8587","-73.8242","United States","US","USA","New York","","38094","1840057994"
"Parkland","Parkland","47.1417","-122.4376","United States","US","USA","Washington","","38092","1840018453"
"Yamen","Yamen","22.3017","113.0510","China","CN","CHN","Guangdong","","37963","1156210045"
"Uster","Uster","47.3500","8.7167","Switzerland","CH","CHE","Zürich","minor","36791","1756180752"
"Yuzhnouralsk","Yuzhnouralsk","54.4500","61.2500","Russia","RU","RUS","Chelyabinskaya Oblast’","","37728","1643319860"
"Spoleto","Spoleto","42.7565","12.6855","Italy","IT","ITA","Umbria","","37964","1380230959"
"San Andres","San Andres","13.3231","122.6761","Philippines","PH","PHL","Quezon","","37454","1608116909"
"Dālkola","Dalkola","25.8758","87.8401","India","IN","IND","West Bengal","","36930","1356729017"
"Masamba","Masamba","-2.5531","120.3275","Indonesia","ID","IDN","Sulawesi Selatan","minor","38024","1360929880"
"Mount Juliet","Mount Juliet","36.1990","-86.5115","United States","US","USA","Tennessee","","38059","1840014465"
"San Pédro Jocopilas","San Pedro Jocopilas","15.0953","-91.1508","Guatemala","GT","GTM","Quiché","minor","37990","1320372881"
"Güira de Melena","Guira de Melena","22.8019","-82.5047","Cuba","CU","CUB","Artemisa","minor","37838","1192663168"
"Hot Springs","Hot Springs","34.4892","-93.0501","United States","US","USA","Arkansas","","38050","1840014670"
"Les Abricots","Les Abricots","18.6333","-74.3000","Haiti","HT","HTI","Grand’Anse","","37675","1332706374"
"Aristóbulo del Valle","Aristobulo del Valle","-27.1167","-54.9167","Argentina","AR","ARG","Misiones","","38000","1032611762"
"Amambaí","Amambai","-23.1039","-55.2258","Brazil","BR","BRA","Mato Grosso do Sul","","38030","1076013576"
"Qaratog","Qaratog","38.5500","68.2833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","37948","1762906542"
"Formia","Formia","41.2667","13.6167","Italy","IT","ITA","Lazio","","38032","1380356928"
"Coondapoor","Coondapoor","13.6313","74.6902","India","IN","IND","Karnātaka","","37740","1356009987"
"Hajīn","Hajin","34.6894","40.8308","Syria","SY","SYR","Dayr az Zawr","minor","37935","1760926776"
"Winter Springs","Winter Springs","28.6889","-81.2703","United States","US","USA","Florida","","38021","1840015959"
"Mixquiahuala de Juarez","Mixquiahuala de Juarez","20.2311","-99.2131","Mexico","MX","MEX","Hidalgo","","37747","1484223912"
"Andkhōy","Andkhoy","36.9500","65.1167","Afghanistan","AF","AFG","Fāryāb","minor","37887","1004472345"
"Catende","Catende","-8.6669","-35.7169","Brazil","BR","BRA","Pernambuco","","37820","1076237989"
"Monrovia","Monrovia","34.1650","-117.9921","United States","US","USA","California","","37999","1840020498"
"Bom Jardim","Bom Jardim","-7.7958","-35.5869","Brazil","BR","BRA","Pernambuco","","37826","1076905890"
"Luuk","Luuk","5.9676","121.3133","Philippines","PH","PHL","Sulu","","37873","1608357369"
"Haugesund","Haugesund","59.4464","5.2983","Norway","NO","NOR","Rogaland","minor","37444","1578805813"
"Obala","Obala","4.1667","11.5333","Cameroon","CM","CMR","Centre","","37888","1120045790"
"Olot","Olot","42.1822","2.4890","Spain","ES","ESP","Catalonia","","36716","1724694918"
"Mullaittivu","Mullaittivu","9.2833","80.8000","Sri Lanka","LK","LKA","North Central","","37339","1144339427"
"Prattville","Prattville","32.4597","-86.4573","United States","US","USA","Alabama","","37977","1840004086"
"Ohangaron","Ohangaron","40.9061","69.6444","Uzbekistan","UZ","UZB","Toshkent","","37784","1860082477"
"Greven","Greven","52.0917","7.6083","Germany","DE","DEU","North Rhine-Westphalia","","37700","1276669554"
"Argun","Argun","43.2944","45.8839","Russia","RU","RUS","Chechnya","","37373","1643005496"
"Kapiri Mposhi","Kapiri Mposhi","-13.9772","28.6847","Zambia","ZM","ZMB","Central","","37942","1894676779"
"Tunduma","Tunduma","-9.3000","32.7667","Tanzania","TZ","TZA","Mbeya","","36556","1834094894"
"Gaspar Hernández","Gaspar Hernandez","19.6200","-70.2800","Dominican Republic","DO","DOM","Cibao Norte","","37852","1214413891"
"Sanchahe","Sanchahe","36.3780","106.0869","China","CN","CHN","Ningxia","","37675","1156923383"
"Srīnagar","Srinagar","30.2200","78.7800","India","IN","IND","Uttarākhand","","37911","1356107519"
"Carpentersville","Carpentersville","42.1227","-88.2896","United States","US","USA","Illinois","","37940","1840010149"
"Ichchāpuram","Ichchapuram","19.1200","84.7000","India","IN","IND","Andhra Pradesh","","36493","1356662616"
"Amarante do Maranhão","Amarante do Maranhao","-5.5669","-46.7419","Brazil","BR","BRA","Maranhão","","37932","1076306284"
"Santiago","Santiago","25.4333","-100.1333","Mexico","MX","MEX","Nuevo León","minor","37886","1484043921"
"Sighetu Marmaţiei","Sighetu Marmatiei","47.9286","23.8925","Romania","RO","ROU","Maramureş","","37640","1642377306"
"Sītākund","Sitakund","22.6200","91.6600","Bangladesh","BD","BGD","Chattogram","","36650","1050226556"
"Puri","Puri","-7.6833","15.6000","Angola","AO","AGO","Uíge","","37910","1024000079"
"Česká Lípa","Ceska Lipa","50.6886","14.5386","Czechia","CZ","CZE","Liberecký Kraj","","37361","1203109242"
"West Fargo","West Fargo","46.8573","-96.9057","United States","US","USA","North Dakota","","37913","1840001935"
"Shakhtīnsk","Shakhtinsk","49.7100","72.5872","Kazakhstan","KZ","KAZ","","","37899","1398239589"
"Basavana Bāgevādi","Basavana Bagevadi","16.5728","75.9725","India","IN","IND","Karnātaka","","37699","1356236898"
"Akhtubinsk","Akhtubinsk","48.2833","46.1667","Russia","RU","RUS","Astrakhanskaya Oblast’","","37883","1643207712"
"East Point","East Point","33.6696","-84.4701","United States","US","USA","Georgia","","37895","1840013661"
"Amargosa","Amargosa","-13.0300","-39.6050","Brazil","BR","BRA","Bahia","","37807","1076608607"
"Ipueiras","Ipueiras","-4.5419","-40.7189","Brazil","BR","BRA","Ceará","","37862","1076672763"
"Spišská Nová Ves","Spisska Nova Ves","48.9439","20.5675","Slovakia","SK","SVK","Košice","minor","37326","1703217196"
"Kehl","Kehl","48.5667","7.8167","Germany","DE","DEU","Baden-Württemberg","","37378","1276615908"
"Dibulla","Dibulla","11.2667","-73.3000","Colombia","CO","COL","La Guajira","minor","37854","1170252076"
"Tupelo","Tupelo","34.2692","-88.7318","United States","US","USA","Mississippi","","37872","1840015561"
"Rosenberg","Rosenberg","29.5456","-95.8223","United States","US","USA","Texas","","37871","1840020948"
"Peachtree City","Peachtree City","33.3942","-84.5710","United States","US","USA","Georgia","","37867","1840014822"
"Santa Rita do Sapucaí","Santa Rita do Sapucai","-22.2519","-45.7028","Brazil","BR","BRA","Minas Gerais","","37754","1076403381"
"Thāna Bhawan","Thana Bhawan","29.5833","77.4167","India","IN","IND","Uttar Pradesh","","36669","1356100133"
"Ma‘arratmişrīn","Ma\`arratmisrin","36.0000","36.6667","Syria","SY","SYR","Idlib","minor","37490","1760812215"
"Mahanoro","Mahanoro","-19.9000","48.8000","Madagascar","MG","MDG","Toamasina","","37800","1450211146"
"La Quinta","La Quinta","33.6536","-116.2785","United States","US","USA","California","","37846","1840020559"
"Sunbury","Sunbury","-37.5811","144.7139","Australia","AU","AUS","Victoria","","36084","1036519781"
"Patía","Patia","2.1667","-77.0833","Colombia","CO","COL","Cauca","","37781","1170437187"
"Mabuhay","Mabuhay","7.4176","122.8370","Philippines","PH","PHL","Zamboanga Sibugay","","37390","1608353639"
"Lampa","Lampa","-33.2833","-70.9000","Chile","CL","CHL","Región Metropolitana","","37599","1152360918"
"Malavalli","Malavalli","12.3800","77.0800","India","IN","IND","Karnātaka","","37601","1356817840"
"Guaraciaba do Norte","Guaraciaba do Norte","-4.1669","-40.7478","Brazil","BR","BRA","Ceará","","37775","1076780410"
"Emirdağ","Emirdag","39.0167","31.1500","Turkey","TR","TUR","Afyonkarahisar","minor","37817","1792515671"
"Al Qaryatayn","Al Qaryatayn","34.2283","37.2406","Syria","SY","SYR","Ḩimş","minor","37820","1760172806"
"Rāwatbhāta","Rawatbhata","24.9300","75.5800","India","IN","IND","Rājasthān","","37701","1356539481"
"Marantao","Marantao","7.9500","124.2330","Philippines","PH","PHL","Lanao del Sur","","37763","1608129790"
"Jalapa","Jalapa","17.7000","-92.8000","Mexico","MX","MEX","Tabasco","minor","37749","1484336788"
"Dajiecun","Dajiecun","36.2965","115.2071","China","CN","CHN","Hebei","","37290","1156441385"
"San Marcelino","San Marcelino","14.9742","120.1573","Philippines","PH","PHL","Zambales","","37719","1608276470"
"Esperantina","Esperantina","-3.9019","-42.2339","Brazil","BR","BRA","Piauí","","37767","1076676231"
"Malmesbury","Malmesbury","-33.4500","18.7333","South Africa","ZA","ZAF","Western Cape","","35897","1710868778"
"Santiago Sacatepéquez","Santiago Sacatepequez","14.6530","-90.6524","Guatemala","GT","GTM","Sacatepéquez","minor","36982","1320070166"
"Hatta","Hatta","24.1341","79.6012","India","IN","IND","Madhya Pradesh","","37638","1356546540"
"Curitibanos","Curitibanos","-27.2828","-50.5839","Brazil","BR","BRA","Santa Catarina","","37748","1076198267"
"Geraldton","Geraldton","-28.7744","114.6089","Australia","AU","AUS","Western Australia","","37648","1036811875"
"Afuá","Afua","-0.1569","-50.3869","Brazil","BR","BRA","Pará","","37778","1076124321"
"Sārangpur","Sarangpur","23.5700","76.4700","India","IN","IND","Madhya Pradesh","","37435","1356423978"
"Trou du Nord","Trou du Nord","19.6333","-72.0167","Haiti","HT","HTI","Nord-Est","","37405","1332162005"
"Matina","Matina","10.0099","-83.3096","Costa Rica","CR","CRI","Limón","","37721","1188640480"
"Mechanicsville","Mechanicsville","37.6263","-77.3561","United States","US","USA","Virginia","","37767","1840006340"
"Tambulig","Tambulig","8.0700","123.5372","Philippines","PH","PHL","Zamboanga del Sur","","37480","1608474492"
"Daiyue","Daiyue","39.5284","112.8056","China","CN","CHN","Shanxi","minor","35894","1156440493"
"Yolöten","Yoloten","37.3000","62.3500","Turkmenistan","TM","TKM","Mary","","37705","1795448254"
"Uchaly","Uchaly","54.3167","59.3833","Russia","RU","RUS","Bashkortostan","","37710","1643696748"
"Villa González","Villa Gonzalez","19.5333","-70.7833","Dominican Republic","DO","DOM","Cibao Norte","","37349","1214737930"
"Galloway","Galloway","39.4914","-74.4803","United States","US","USA","New Jersey","","37715","1840081583"
"Nalayh","Nalayh","47.7725","107.2536","Mongolia","MN","MNG","Ulaanbaatar","","37659","1496295009"
"Baocheng","Baocheng","18.6431","109.6994","China","CN","CHN","Hainan","minor","37492","1156552771"
"Kalbā","Kalba","25.0742","56.3553","United Arab Emirates","AE","ARE","Ash Shāriqah","","37545","1784956003"
"Granadero Baigorria","Granadero Baigorria","-32.8500","-60.7000","Argentina","AR","ARG","Santa Fe","","36994","1032975920"
"Sun City","Sun City","33.6165","-112.2819","United States","US","USA","Arizona","","37693","1840019311"
"Bamendjou","Bamendjou","5.4000","10.3167","Cameroon","CM","CMR","Ouest","","37547","1120189135"
"Houghton le Spring","Houghton le Spring","54.8410","-1.4680","United Kingdom","GB","GBR","Sunderland","","36746","1826945189"
"Kahrīzak","Kahrizak","35.5175","51.3597","Iran","IR","IRN","Tehrān","","37527","1364597116"
"São Gonçalo dos Campos","Sao Goncalo dos Campos","-12.4328","-38.9669","Brazil","BR","BRA","Bahia","","37554","1076071963"
"Mahē","Mahe","11.7011","75.5367","India","IN","IND","Kerala","","36828","1356686273"
"Magallanes","Magallanes","12.8283","123.8344","Philippines","PH","PHL","Sorsogon","","37411","1608899039"
"Bonito","Bonito","-8.4700","-35.7289","Brazil","BR","BRA","Pernambuco","","37566","1076501019"
"Kalinkavichy","Kalinkavichy","52.1250","29.3333","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","37633","1112264242"
"Oras","Oras","12.1414","125.4392","Philippines","PH","PHL","Eastern Samar","","37451","1608000184"
"San Antonio","San Antonio","14.9486","120.0864","Philippines","PH","PHL","Zambales","","37450","1608496787"
"Porteirinha","Porteirinha","-15.7428","-43.0278","Brazil","BR","BRA","Minas Gerais","","37627","1076606467"
"Chieri","Chieri","45.0139","7.8224","Italy","IT","ITA","Piedmont","","36958","1380145496"
"Lahār","Lahar","26.1940","78.9414","India","IN","IND","Madhya Pradesh","","37293","1356298512"
"Greenfield","Greenfield","42.9619","-88.0052","United States","US","USA","Wisconsin","","37623","1840003039"
"Kanigiri","Kanigiri","15.4000","79.5167","India","IN","IND","Andhra Pradesh","","37420","1356020350"
"Rio Pardo","Rio Pardo","-29.9900","-52.3778","Brazil","BR","BRA","Rio Grande do Sul","","37591","1076675236"
"Esplanada","Esplanada","-11.7958","-37.9450","Brazil","BR","BRA","Bahia","","37578","1076376279"
"Bantay","Bantay","17.5839","120.3908","Philippines","PH","PHL","Ilocos Sur","","37118","1608378458"
"Bayur","Bayur","-0.2610","100.2220","Indonesia","ID","IDN","Sumatera Barat","","37368","1360540790"
"Igarapé-Açu","Igarape-Acu","-1.1269","-47.6178","Brazil","BR","BRA","Pará","","37547","1076684764"
"Salinópolis","Salinopolis","-0.6289","-47.3558","Brazil","BR","BRA","Pará","","37421","1076811443"
"Morsi","Morsi","21.3180","78.0095","India","IN","IND","Mahārāshtra","","37333","1356045043"
"Bouznika","Bouznika","33.7897","-7.1575","Morocco","MA","MAR","Casablanca-Settat","","37238","1504766743"
"Risalpur Cantonment","Risalpur Cantonment","34.0049","71.9989","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","36653","1586844436"
"Jelilyüzi","Jelilyuzi","43.9749","81.5328","China","CN","CHN","Xinjiang","","37238","1156123923"
"São Fidélis","Sao Fidelis","-21.6458","-41.7469","Brazil","BR","BRA","Rio de Janeiro","","37543","1076411461"
"Wangsicun","Wangsicun","37.9975","116.9238","China","CN","CHN","Hebei","","37184","1156481364"
"Al Hāshimīyah","Al Hashimiyah","32.3664","44.6581","Iraq","IQ","IRQ","Bābil","minor","37300","1368037848"
"Paraty","Paraty","-23.2194","-44.7147","Brazil","BR","BRA","Rio de Janeiro","","37533","1076616643"
"Knurów","Knurow","50.2167","18.6833","Poland","PL","POL","Śląskie","","36498","1616347151"
"Progreso","Progreso","21.2800","-89.6700","Mexico","MX","MEX","Yucatán","minor","37369","1484340626"
"Frolovo","Frolovo","49.7667","43.6667","Russia","RU","RUS","Volgogradskaya Oblast’","","36951","1643011047"
"Oleśnica","Olesnica","51.2000","17.3833","Poland","PL","POL","Dolnośląskie","minor","35856","1616255385"
"Alapayevsk","Alapayevsk","57.8500","61.7000","Russia","RU","RUS","Sverdlovskaya Oblast’","","37526","1643648315"
"El Bordo","El Bordo","2.1142","-76.9831","Colombia","CO","COL","Cauca","minor","37504","1170827287"
"Owasso","Owasso","36.2878","-95.8311","United States","US","USA","Oklahoma","","37559","1840020385"
"Bindki","Bindki","26.0300","80.6000","India","IN","IND","Uttar Pradesh","","36926","1356401700"
"Aliança","Alianca","-7.6028","-35.2308","Brazil","BR","BRA","Pernambuco","","37415","1076773543"
"Shiji","Shiji","22.2198","112.8531","China","CN","CHN","Guangdong","","37402","1156124152"
"Tosno","Tosno","59.5500","30.9000","Russia","RU","RUS","Leningradskaya Oblast’","","37509","1643457782"
"Liwonde","Liwonde","-15.0667","35.2333","Malawi","MW","MWI","Balaka","","36421","1454616988"
"Portage","Portage","41.5856","-87.1797","United States","US","USA","Indiana","","37540","1840009264"
"Fermo","Fermo","43.1608","13.7158","Italy","IT","ITA","Marche","minor","37238","1380577210"
"Kishmat Dhanbāri","Kishmat Dhanbari","24.6167","90.0250","Bangladesh","BD","BGD","Dhaka","","36125","1050355548"
"Ratia","Ratia","29.6833","75.5750","India","IN","IND","Haryāna","","37152","1356141622"
"Fengguangcun","Fengguangcun","23.9062","116.6984","China","CN","CHN","Guangdong","","37337","1156453257"
"Chanderi","Chanderi","24.7200","78.1300","India","IN","IND","Madhya Pradesh","","37362","1356825351"
"Dalnegorsk","Dalnegorsk","44.5500","135.5833","Russia","RU","RUS","Primorskiy Kray","","37519","1643147456"
"Maibara","Maibara","35.3167","136.2833","Japan","JP","JPN","Shiga","","37374","1392812033"
"Itarema","Itarema","-2.9200","-39.9150","Brazil","BR","BRA","Ceará","","37471","1076296454"
"Pedro II","Pedro II","-4.4338","-41.4534","Brazil","BR","BRA","Piauí","","37496","1076812228"
"Rock Island","Rock Island","41.4699","-90.5827","United States","US","USA","Illinois","","37519","1840009233"
"Mankayan","Mankayan","16.8567","120.7933","Philippines","PH","PHL","Benguet","","37233","1608432367"
"Rafiganj","Rafiganj","24.8200","84.6500","India","IN","IND","Bihār","","35536","1356030907"
"Goryachiy Klyuch","Goryachiy Klyuch","44.6333","39.1333","Russia","RU","RUS","Krasnodarskiy Kray","","37475","1643630815"
"Malgobek","Malgobek","43.5000","44.7500","Russia","RU","RUS","Ingushetiya","","37442","1643015431"
"Nandaime","Nandaime","11.7569","-86.0528","Nicaragua","NI","NIC","Granada","minor","37413","1558998589"
"Itaqui","Itaqui","-29.1250","-56.5528","Brazil","BR","BRA","Rio Grande do Sul","","37489","1076882439"
"Comapa","Comapa","14.1114","-89.7497","Guatemala","GT","GTM","Jutiapa","minor","37218","1320504188"
"Chrzanów","Chrzanow","50.1333","19.4000","Poland","PL","POL","Małopolskie","minor","36575","1616227522"
"Gajendragarh","Gajendragarh","15.7363","75.9698","India","IN","IND","Karnātaka","","37258","1356054157"
"Santa Rosa de Osos","Santa Rosa de Osos","6.6500","-75.4667","Colombia","CO","COL","Antioquia","minor","37435","1170188464"
"Pilibangan","Pilibangan","29.4888","74.0748","India","IN","IND","Rājasthān","","37288","1356290166"
"Mölndal","Molndal","57.6500","12.0167","Sweden","SE","SWE","Västra Götaland","minor","37233","1752307790"
"Shaxi","Shaxi","22.3067","113.1469","China","CN","CHN","Guangdong","","36957","1156047728"
"Lebanon","Lebanon","36.2040","-86.3481","United States","US","USA","Tennessee","","37471","1840014466"
"Agudos","Agudos","-22.4739","-48.9836","Brazil","BR","BRA","São Paulo","","37401","1076000779"
"Tamamura","Tamamura","36.3044","139.1149","Japan","JP","JPN","Gunma","","36041","1392003541"
"Châtelet","Chatelet","50.4000","4.5167","Belgium","BE","BEL","Wallonia","","36101","1056557187"
"Warendorf","Warendorf","51.9539","7.9933","Germany","DE","DEU","North Rhine-Westphalia","minor","37226","1276005733"
"Kérou","Kerou","10.8250","2.1094","Benin","BJ","BEN","Atacora","","37419","1204765359"
"Holubivske","Holubivske","48.6375","38.6433","Ukraine","UA","UKR","Luhanska Oblast","","36388","1804938302"
"Yugorsk","Yugorsk","61.3167","63.3333","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","37422","1643279109"
"Nakodar","Nakodar","31.1300","75.4700","India","IN","IND","Punjab","","36973","1356952491"
"Tudela","Tudela","42.0653","-1.6067","Spain","ES","ESP","Navarre","","37247","1724338927"
"Pingxiangcheng","Pingxiangcheng","36.9819","114.9131","China","CN","CHN","Hebei","","36907","1156803828"
"Melgar","Melgar","4.2039","-74.6428","Colombia","CO","COL","Tolima","minor","37224","1170387585"
"Dīnhāta","Dinhata","26.1300","89.4700","India","IN","IND","West Bengal","","36124","1356871105"
"Punārakh","Punarakh","25.4930","85.8054","India","IN","IND","Bihār","","36541","1356365373"
"Besni","Besni","37.6942","37.8614","Turkey","TR","TUR","Adıyaman","minor","37323","1792891287"
"Moerdijk","Moerdijk","51.6500","4.6000","Netherlands","NL","NLD","Noord-Brabant","","37185","1528060110"
"Lierre","Lierre","51.1333","4.5667","Belgium","BE","BEL","Flanders","","36646","1056505135"
"Don Benito","Don Benito","38.9545","-5.8617","Spain","ES","ESP","Extremadura","","37310","1724257021"
"Sitangkai","Sitangkai","4.6615","119.3919","Philippines","PH","PHL","Tawi-Tawi","","37319","1608529201"
"Bochil","Bochil","17.0167","-92.9500","Mexico","MX","MEX","Chiapas","minor","37263","1484171114"
"Seika","Seika","34.7667","135.7833","Japan","JP","JPN","Kyōto","","35951","1392999997"
"New Albany","New Albany","38.3090","-85.8234","United States","US","USA","Indiana","","37350","1840008662"
"Maşyāf","Masyaf","35.0653","36.3422","Syria","SY","SYR","Ḩamāh","minor","37109","1760933504"
"Mol","Mol","51.1833","5.1167","Belgium","BE","BEL","Flanders","","37022","1056847907"
"Cheltenham","Cheltenham","40.0781","-75.1382","United States","US","USA","Pennsylvania","","37331","1840143117"
"Daying","Daying","39.0786","116.1076","China","CN","CHN","Hebei","","36660","1156280280"
"Petroşani","Petrosani","45.4122","23.3733","Romania","RO","ROU","Hunedoara","","37160","1642231547"
"Patāmundai","Patamundai","20.5700","86.5700","India","IN","IND","Odisha","","36528","1356395507"
"Kizilyurt","Kizilyurt","43.2167","46.8667","Russia","RU","RUS","Dagestan","minor","37171","1643796605"
"Tibú","Tibu","8.6500","-72.7333","Colombia","CO","COL","Norte de Santander","minor","37286","1170518089"
"Basilisa","Basilisa","10.0654","125.5968","Philippines","PH","PHL","Surigao del Norte","","36911","1608815842"
"Martinez","Martinez","37.9985","-122.1161","United States","US","USA","California","","37308","1840020278"
"Lębork","Lebork","54.5500","17.7500","Poland","PL","POL","Pomorskie","minor","35374","1616424238"
"Riviera Beach","Riviera Beach","26.7813","-80.0741","United States","US","USA","Florida","","37301","1840015126"
"Chorkŭh","Chorkuh","39.9739","70.5822","Tajikistan","TJ","TJK","Sughd","","37065","1762755950"
"Koryazhma","Koryazhma","61.3167","47.1333","Russia","RU","RUS","Arkhangel’skaya Oblast’","","36607","1643316632"
"Hendon","Hendon","51.5837","-0.2252","United Kingdom","GB","GBR","Barnet","","35874","1826052476"
"Coatepec Harinas","Coatepec Harinas","18.9000","-99.7167","Mexico","MX","MEX","México","minor","36269","1484641903"
"Musiri","Musiri","10.9333","78.4500","India","IN","IND","Tamil Nādu","","36882","1356096058"
"Ewing","Ewing","40.2650","-74.8006","United States","US","USA","New Jersey","","37284","1840081658"
"Mieres","Mieres","43.2508","-5.7767","Spain","ES","ESP","Asturias","","37026","1724137111"
"Tunzi","Tunzi","35.5782","107.3699","China","CN","CHN","Gansu","","37179","1156038004"
"Aguelmous","Aguelmous","33.1500","-5.8333","Morocco","MA","MAR","Béni Mellal-Khénifra","","35626","1504241696"
"Holstebro","Holstebro","56.3572","8.6153","Denmark","DK","DNK","Midtjylland","minor","36489","1208971642"
"Kaman","Kaman","39.3575","33.7239","Turkey","TR","TUR","Kırşehir","minor","37223","1792722636"
"Mangai","Mangai","-4.0499","19.5333","Congo (Kinshasa)","CD","COD","Kwilu","","37188","1180224936"
"San Enrique","San Enrique","11.0697","122.6567","Philippines","PH","PHL","Iloilo","","36911","1608715558"
"Andilamena","Andilamena","-17.0167","48.5833","Madagascar","MG","MDG","Toamasina","","37229","1450560153"
"Irbit","Irbit","57.6667","63.0667","Russia","RU","RUS","Sverdlovskaya Oblast’","","36668","1643949941"
"Frankston","Frankston","-38.1580","145.1350","Australia","AU","AUS","Victoria","","36097","1036126828"
"Cisterna di Latina","Cisterna di Latina","41.5908","12.8283","Italy","IT","ITA","Lazio","","36980","1380273577"
"Varberg","Varberg","57.1167","12.2167","Sweden","SE","SWE","Halland","minor","35782","1752538180"
"Hekou","Hekou","22.5174","103.9557","China","CN","CHN","Yunnan","minor","37074","1156669010"
"Adelanto","Adelanto","34.5814","-117.4397","United States","US","USA","California","","37229","1840019117"
"Pleasant Grove","Pleasant Grove","40.3716","-111.7412","United States","US","USA","Utah","","37228","1840020173"
"Moalboal","Moalboal","9.9500","123.4000","Philippines","PH","PHL","Cebu","","36930","1608515460"
"Obburdon","Obburdon","40.4278","69.3047","Tajikistan","TJ","TJK","Sughd","","37104","1762458368"
"Imbatug","Imbatug","8.3128","124.6873","Philippines","PH","PHL","Bukidnon","","37111","1608682824"
"Västervik","Vastervik","57.7500","16.6333","Sweden","SE","SWE","Kalmar","minor","36747","1752051923"
"Leavenworth","Leavenworth","39.3239","-94.9240","United States","US","USA","Kansas","","37216","1840001591"
"Licata","Licata","37.1083","13.9469","Italy","IT","ITA","Sicilia","","37008","1380907780"
"Muskogee","Muskogee","35.7432","-95.3567","United States","US","USA","Oklahoma","","37214","1840020401"
"Suhl","Suhl","50.6106","10.6931","Germany","DE","DEU","Thuringia","minor","36955","1276059886"
"Ciudad Manuel Doblado","Ciudad Manuel Doblado","20.7303","-101.9533","Mexico","MX","MEX","Guanajuato","minor","37145","1484254805"
"Protvino","Protvino","54.8833","37.2167","Russia","RU","RUS","Moskovskaya Oblast’","","35807","1643021953"
"Silves","Silves","37.1869","-8.4389","Portugal","PT","PRT","Faro","minor","37126","1620801770"
"Rezh","Rezh","57.3667","61.3667","Russia","RU","RUS","Sverdlovskaya Oblast’","","37152","1643497489"
"Anzhou","Anzhou","38.8663","115.8124","China","CN","CHN","Hebei","","36619","1156020583"
"Zīra","Zira","30.9700","74.9900","India","IN","IND","Punjab","","36732","1356805271"
"Baishi Airikecun","Baishi Airikecun","40.8035","80.3700","China","CN","CHN","Xinjiang","","36982","1156424093"
"Kannan","Kannan","35.0889","138.9534","Japan","JP","JPN","Shizuoka","","36607","1392155353"
"Kāramadai","Karamadai","11.2428","76.9617","India","IN","IND","Tamil Nādu","","36718","1356284368"
"Yasynuvata","Yasynuvata","48.1167","37.8333","Ukraine","UA","UKR","Donetska Oblast","","35303","1804948935"
"Oregon City","Oregon City","45.3418","-122.5924","United States","US","USA","Oregon","","37160","1840019954"
"Languyan","Languyan","5.2667","120.0833","Philippines","PH","PHL","Tawi-Tawi","","37096","1608286000"
"Manazary","Manazary","-19.0500","46.8667","Madagascar","MG","MDG","Antananarivo","","37000","1450957138"
"Beslan","Beslan","43.1833","44.5500","Russia","RU","RUS","North Ossetia","","37029","1643201517"
"La Ciotat","La Ciotat","43.1769","5.6086","France","FR","FRA","Provence-Alpes-Côte d’Azur","","35993","1250000896"
"Erding","Erding","48.2833","11.9000","Germany","DE","DEU","Bavaria","minor","36469","1276458355"
"Pāthri","Pathri","19.2500","76.4500","India","IN","IND","Mahārāshtra","","36853","1356093977"
"Yongjing","Yongjing","23.9223","120.5356","Taiwan","TW","TWN","Changhua","","35436","1158242366"
"Zuojiawu","Zuojiawu","39.9514","118.1511","China","CN","CHN","Hebei","","36843","1156913356"
"Nanshuicun","Nanshuicun","22.0316","113.2358","China","CN","CHN","Guangdong","","36962","1156561157"
"José de Freitas","Jose de Freitas","-4.7558","-42.5758","Brazil","BR","BRA","Piauí","","37085","1076802708"
"Qulsary","Qulsary","46.9833","54.0167","Kazakhstan","KZ","KAZ","","minor","37103","1398141603"
"Mandoto","Mandoto","-19.5667","46.2833","Madagascar","MG","MDG","Antananarivo","","37000","1450317527"
"Elixku","Elixku","38.6803","77.3106","China","CN","CHN","Xinjiang","","36793","1156223089"
"Beni Khiar","Beni Khiar","36.4667","10.7833","Tunisia","TN","TUN","Nabeul","","36686","1788551393"
"Ariyalūr","Ariyalur","11.1333","79.0833","India","IN","IND","Tamil Nādu","","36725","1356003043"
"Tuensang","Tuensang","26.2716","94.8314","India","IN","IND","Nāgāland","","36774","1356103704"
"Sagnay","Sagnay","13.6039","123.5233","Philippines","PH","PHL","Camarines Sur","","36841","1608123253"
"Bartlesville","Bartlesville","36.7365","-95.9456","United States","US","USA","Oklahoma","","37074","1840018977"
"Kampene","Kampene","-3.5968","26.6658","Congo (Kinshasa)","CD","COD","Maniema","","37034","1180064621"
"Dar Chabanne","Dar Chabanne","36.4700","10.7500","Tunisia","TN","TUN","Nabeul","","35859","1788696431"
"Timbó","Timbo","-26.8228","-49.2719","Brazil","BR","BRA","Santa Catarina","","36774","1076339334"
"Tuttlingen","Tuttlingen","47.9850","8.8233","Germany","DE","DEU","Baden-Württemberg","minor","36672","1276649477"
"Tsubata","Tsubata","36.6692","136.7288","Japan","JP","JPN","Ishikawa","","36728","1392195926"
"Magsaysay","Magsaysay","9.0167","125.1833","Philippines","PH","PHL","Misamis Oriental","","36803","1608823576"
"Coatepeque","Coatepeque","13.9167","-89.5000","El Salvador","SV","SLV","Santa Ana","","36768","1222820033"
"Kyshtym","Kyshtym","55.7000","60.5500","Russia","RU","RUS","Chelyabinskaya Oblast’","","36997","1643212206"
"Beckum","Beckum","51.7550","8.0403","Germany","DE","DEU","North Rhine-Westphalia","","36737","1276000534"
"Lesozavodsk","Lesozavodsk","45.4667","133.4000","Russia","RU","RUS","Primorskiy Kray","","37034","1643722124"
"Robertsganj","Robertsganj","24.7000","83.0700","India","IN","IND","Uttar Pradesh","","36689","1356141088"
"Canaman","Canaman","13.6481","123.1711","Philippines","PH","PHL","Camarines Sur","","36205","1608395412"
"Autazes","Autazes","-3.5864","-59.1197","Brazil","BR","BRA","Amazonas","","37033","1076415677"
"Manucan","Manucan","8.5161","123.0917","Philippines","PH","PHL","Zamboanga del Norte","","36887","1608513294"
"Partizansk","Partizansk","43.1167","133.1167","Russia","RU","RUS","Primorskiy Kray","","36992","1643537193"
"Azuqueca de Henares","Azuqueca de Henares","40.5647","-3.2681","Spain","ES","ESP","Castille-La Mancha","","35236","1724909657"
"San Luis","San Luis","13.8333","120.9333","Philippines","PH","PHL","Batangas","","36172","1608597506"
"Monatélé","Monatele","4.2667","11.2000","Cameroon","CM","CMR","Centre","","36933","1120594177"
"Villeta","Villeta","-25.5100","-57.5600","Paraguay","PY","PRY","Central","","36985","1600551877"
"Colón","Colon","8.0378","-72.2519","Venezuela","VE","VEN","Táchira","minor","36925","1862928589"
"Dabutou","Dabutou","36.0708","112.8744","China","CN","CHN","Shanxi","","36556","1156210368"
"Brighton","Brighton","43.1175","-77.5835","United States","US","USA","New York","","36986","1840057402"
"Woodley","Woodley","51.4530","-0.9020","United Kingdom","GB","GBR","Wokingham","","35470","1826133609"
"Santa Ana","Santa Ana","7.6453","-72.2758","Venezuela","VE","VEN","Táchira","minor","36925","1862776224"
"Lakshmeshwar","Lakshmeshwar","15.1300","75.4700","India","IN","IND","Karnātaka","","36754","1356038836"
"Saratoga Springs","Saratoga Springs","40.3450","-111.9154","United States","US","USA","Utah","","36977","1840021397"
"Netishyn","Netishyn","50.3300","26.6400","Ukraine","UA","UKR","Khmelnytska Oblast","","36921","1804202140"
"Yovon","Yovon","38.3167","69.0500","Tajikistan","TJ","TJK","Khatlon","minor","36700","1762920268"
"Şuhut","Suhut","38.5311","30.5458","Turkey","TR","TUR","Afyonkarahisar","minor","36947","1792959774"
"San Miguel Chicaj","San Miguel Chicaj","15.1000","-90.4000","Guatemala","GT","GTM","Baja Verapaz","minor","36845","1320830871"
"Caimito","Caimito","22.9575","-82.5964","Cuba","CU","CUB","Artemisa","minor","36813","1192437074"
"Xindian","Xindian","37.1341","114.8833","China","CN","CHN","Hebei","","36195","1156991946"
"Aourir","Aourir","30.4833","-9.6333","Morocco","MA","MAR","Souss-Massa","","36948","1504616385"
"Uruaçu","Uruacu","-14.5250","-49.1408","Brazil","BR","BRA","Goiás","","36929","1076588964"
"Fuchūchō","Fuchucho","34.5650","133.2419","Japan","JP","JPN","Hiroshima","","36754","1392822358"
"Santaluz","Santaluz","-11.2558","-39.3750","Brazil","BR","BRA","Bahia","","36915","1076830828"
"Aurora","Aurora","16.9918","121.6357","Philippines","PH","PHL","Isabela","","36621","1608526044"
"Sōma","Soma","37.7967","140.9196","Japan","JP","JPN","Fukushima","","36752","1392003527"
"Sokol","Sokol","59.4667","40.1167","Russia","RU","RUS","Vologodskaya Oblast’","","36924","1643586879"
"Dagua","Dagua","3.6667","-76.7000","Colombia","CO","COL","Valle del Cauca","minor","36891","1170326563"
"Mizunami","Mizunami","35.3618","137.2545","Japan","JP","JPN","Gifu","","36721","1392283682"
"Numancia","Numancia","11.7042","122.3289","Philippines","PH","PHL","Aklan","","35693","1608072562"
"Chimboy Shahri","Chimboy Shahri","42.9295","59.7820","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","36917","1860167547"
"Komatsushimachō","Komatsushimacho","34.0003","134.5844","Japan","JP","JPN","Tokushima","","36123","1392858240"
"Pihānī","Pihani","27.6199","80.2034","India","IN","IND","Uttar Pradesh","","36014","1356285844"
"Beiya","Beiya","36.4640","104.4513","China","CN","CHN","Gansu","","36241","1156672706"
"Accrington","Accrington","53.7534","-2.3638","United Kingdom","GB","GBR","Lancashire","","35456","1826709919"
"Enrile","Enrile","17.5622","121.7011","Philippines","PH","PHL","Cagayan","","36705","1608990960"
"Agogo","Agogo","6.8000","-1.0833","Ghana","GH","GHA","Ashanti","","36797","1288687736"
"Sayula","Sayula","19.8836","-103.5972","Mexico","MX","MEX","Jalisco","minor","36778","1484178547"
"Sankaridrug","Sankaridrug","11.4800","77.8700","India","IN","IND","Tamil Nādu","","36239","1356903753"
"Pimenta Bueno","Pimenta Bueno","-11.6725","-61.1936","Brazil","BR","BRA","Rondônia","","36881","1076267246"
"Yejituo","Yejituo","39.8634","118.6645","China","CN","CHN","Hebei","","36218","1156489268"
"Mangalia","Mangalia","43.8172","28.5828","Romania","RO","ROU","Constanţa","","36364","1642499663"
"Guilderland","Guilderland","42.7080","-73.9631","United States","US","USA","New York","","36862","1840058149"
"Cuenca","Cuenca","13.9167","121.0500","Philippines","PH","PHL","Batangas","","36235","1608210905"
"Ikongo","Ikongo","-21.8833","47.4333","Madagascar","MG","MDG","Fianarantsoa","","36684","1450895695"
"Torres Novas","Torres Novas","39.4667","-8.5333","Portugal","PT","PRT","Santarém","minor","36717","1620693421"
"Qalādizay","Qaladizay","36.1833","45.1278","Iraq","IQ","IRQ","As Sulaymānīyah","minor","36792","1368327633"
"Kanada","Kanada","33.7760","130.9805","Japan","JP","JPN","Fukuoka","","36075","1392483747"
"Rossano","Rossano","39.5667","16.6333","Italy","IT","ITA","Calabria","","36598","1380048894"
"Pennsauken","Pennsauken","39.9649","-75.0563","United States","US","USA","New Jersey","","36838","1840081687"
"Porsgrunn","Porsgrunn","59.1156","9.7100","Norway","NO","NOR","Vestfold og Telemark","minor","36624","1578150916"
"Taxisco","Taxisco","14.0716","-90.4645","Guatemala","GT","GTM","Santa Rosa","minor","36750","1320216194"
"Zhaoqiao","Zhaoqiao","37.9398","115.9499","China","CN","CHN","Hebei","","36123","1156170146"
"Trumbull","Trumbull","41.2602","-73.2083","United States","US","USA","Connecticut","","36830","1840035628"
"Lajedo","Lajedo","-8.6639","-36.3200","Brazil","BR","BRA","Pernambuco","","36628","1076183844"
"Mek’ī","Mek'i","8.1500","38.8167","Ethiopia","ET","ETH","Oromīya","","36597","1231135266"
"Yasugichō","Yasugicho","35.4283","133.2536","Japan","JP","JPN","Shimane","","36713","1392750514"
"Jaral del Progreso","Jaral del Progreso","20.3667","-101.0667","Mexico","MX","MEX","Guanajuato","minor","36584","1484182390"
"Wangtuan","Wangtuan","36.8624","105.9915","China","CN","CHN","Ningxia","","36729","1156162386"
"Santa Pola","Santa Pola","38.1897","-0.5556","Spain","ES","ESP","Valencia","","36174","1724077015"
"La Macarena","La Macarena","2.1828","-73.7847","Colombia","CO","COL","Meta","minor","36781","1170049072"
"Köneürgench","Koneurgench","42.3333","59.1500","Turkmenistan","TM","TKM","Daşoguz","","36754","1795588005"
"Dargaz","Dargaz","37.4444","59.1081","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","36762","1364647948"
"Mbulu","Mbulu","-3.8500","35.5500","Tanzania","TZ","TZA","Manyara","","36408","1834497748"
"Chippenham","Chippenham","51.4590","-2.1160","United Kingdom","GB","GBR","Wiltshire","","36548","1826463257"
"Kambove","Kambove","-10.8764","26.5969","Congo (Kinshasa)","CD","COD","Haut-Katanga","","36702","1180986023"
"Danihe","Danihe","39.8489","119.4210","China","CN","CHN","Hebei","","36313","1156564842"
"Tucker","Tucker","33.8436","-84.2024","United States","US","USA","Georgia","","36764","1840013701"
"Esik","Esik","43.3500","77.4667","Kazakhstan","KZ","KAZ","","","36715","1398166418"
"Las Navas","Las Navas","12.3400","125.0320","Philippines","PH","PHL","Northern Samar","","36621","1608082772"
"Pūrna","Purna","19.1800","77.0500","India","IN","IND","Mahārāshtra","","36433","1356140147"
"Araçuaí","Aracuai","-16.8500","-42.0700","Brazil","BR","BRA","Minas Gerais","","36712","1076501078"
"Namlea","Namlea","-3.2500","127.0833","Indonesia","ID","IDN","Maluku","minor","36680","1360926873"
"Brant","Brant","43.1167","-80.3667","Canada","CA","CAN","Ontario","","36707","1124000682"
"Huzūrnagar","Huzurnagar","16.9000","79.8833","India","IN","IND","Andhra Pradesh","","35850","1356954462"
"Cloppenburg","Cloppenburg","52.8500","8.0500","Germany","DE","DEU","Lower Saxony","minor","36183","1276769960"
"Esquel","Esquel","-42.9000","-71.3167","Argentina","AR","ARG","Buenos Aires, Ciudad Autónoma de","minor","36687","1032489067"
"Cantanhede","Cantanhede","40.3462","-8.5941","Portugal","PT","PRT","Coimbra","minor","36595","1620294454"
"Cambrils","Cambrils","41.0670","1.0564","Spain","ES","ESP","Catalonia","","35675","1724843807"
"Castricum","Castricum","52.5500","4.6667","Netherlands","NL","NLD","Noord-Holland","minor","36086","1528242440"
"Franklin","Franklin","42.8854","-88.0104","United States","US","USA","Wisconsin","","36685","1840003043"
"Oakton","Oakton","38.8887","-77.3016","United States","US","USA","Virginia","","36675","1840006023"
"Zhetisay","Zhetisay","40.7753","68.3272","Kazakhstan","KZ","KAZ","","","36494","1398994827"
"Kulp","Kulp","38.5017","41.0117","Turkey","TR","TUR","Diyarbakır","minor","36640","1792693843"
"Pinerolo","Pinerolo","44.8873","7.3319","Italy","IT","ITA","Piedmont","","35947","1380505638"
"Maur","Maur","30.0833","75.2500","India","IN","IND","Punjab","","36356","1356076094"
"Belalcázar","Belalcazar","2.6547","-75.9928","Colombia","CO","COL","Cauca","minor","36628","1170676223"
"Neiba","Neiba","18.4900","-71.4200","Dominican Republic","DO","DOM","Enriquillo","minor","36511","1214562502"
"Coesfeld","Coesfeld","51.9500","7.1667","Germany","DE","DEU","North Rhine-Westphalia","minor","36382","1276053897"
"Kinel","Kinel","53.2222","50.6333","Russia","RU","RUS","Samarskaya Oblast’","","35675","1643467753"
"Bulicun","Bulicun","24.3657","116.2726","China","CN","CHN","Guangdong","","36526","1156493287"
"Guápiles","Guapiles","10.2070","-83.8581","Costa Rica","CR","CRI","Limón","","36469","1188786301"
"Kodaikānal","Kodaikanal","10.2300","77.4800","India","IN","IND","Tamil Nādu","","36501","1356350123"
"Shirdi","Shirdi","19.7700","74.4800","India","IN","IND","Mahārāshtra","","36004","1356109433"
"Natick","Natick","42.2847","-71.3497","United States","US","USA","Massachusetts","","36627","1840053640"
"Uddevalla","Uddevalla","58.3500","11.9167","Sweden","SE","SWE","Västra Götaland","minor","34781","1752935117"
"Santa Cruz Verapaz","Santa Cruz Verapaz","15.3736","-90.4306","Guatemala","GT","GTM","Alta Verapaz","minor","35878","1320163756"
"Haomen","Haomen","37.3760","101.6173","China","CN","CHN","Qinghai","minor","36515","1156823792"
"Lewiston","Lewiston","44.0915","-70.1681","United States","US","USA","Maine","","36615","1840000320"
"Bindé","Binde","11.7500","-1.1000","Burkina Faso","BF","BFA","Centre-Sud","","36518","1854349204"
"Lakeshore","Lakeshore","42.2399","-82.6511","Canada","CA","CAN","Ontario","","36611.00","1124001501"
"Montluçon","Montlucon","46.3408","2.6033","France","FR","FRA","Auvergne-Rhône-Alpes","minor","34938","1250269997"
"Songo","Songo","-15.6142","32.7709","Mozambique","MZ","MOZ","Tete","","36523","1508318557"
"Sholinghur","Sholinghur","13.1200","79.4200","India","IN","IND","Tamil Nādu","","34854","1356077846"
"Dinas","Dinas","7.6161","123.3378","Philippines","PH","PHL","Zamboanga del Sur","","36291","1608805302"
"Tocancipá","Tocancipa","4.9657","-73.9136","Colombia","CO","COL","Cundinamarca","minor","36439","1170000496"
"South Valley","South Valley","35.0093","-106.6819","United States","US","USA","New Mexico","","36583","1840033724"
"Nuoro","Nuoro","40.3167","9.3333","Italy","IT","ITA","Sardegna","minor","36579","1380476722"
"Limburg","Limburg","50.3833","8.0667","Germany","DE","DEU","Hesse","minor","35783","1276188257"
"Ekpé","Ekpe","6.4000","2.5333","Benin","BJ","BEN","Ouémé","","34917","1204585283"
"‘Afrīn","\`Afrin","36.5083","36.8692","Syria","SY","SYR","Ḩalab","minor","36562","1760410858"
"Dashtobod","Dashtobod","40.1269","68.4944","Uzbekistan","UZ","UZB","Jizzax","","36500","1860941088"
"Beloit","Beloit","42.5230","-89.0184","United States","US","USA","Wisconsin","","36567","1840002471"
"Upper Arlington","Upper Arlington","40.0272","-83.0704","United States","US","USA","Ohio","","36566","1840003762"
"Innisfil","Innisfil","44.3000","-79.5833","Canada","CA","CAN","Ontario","","36566","1124001408"
"Chandanais","Chandanais","22.2000","92.0083","Bangladesh","BD","BGD","Chattogram","","35248","1050506316"
"Barra Bonita","Barra Bonita","-22.4947","-48.5581","Brazil","BR","BRA","São Paulo","","36321","1076780548"
"Mingjian","Mingjian","23.8510","120.6775","Taiwan","TW","TWN","Nantou","","36127","1158386968"
"Francavilla Fontana","Francavilla Fontana","40.5333","17.5833","Italy","IT","ITA","Puglia","","36358","1380107756"
"Voerde","Voerde","51.6000","6.6833","Germany","DE","DEU","North Rhine-Westphalia","","35889","1276242749"
"Şile","Sile","41.1764","29.6128","Turkey","TR","TUR","İstanbul","minor","36516","1792880825"
"Gasan","Gasan","13.3167","121.8500","Philippines","PH","PHL","Marinduque","","36197","1608036488"
"Taastrup","Taastrup","55.6500","12.3000","Denmark","DK","DNK","Hovedstaden","minor","35238","1208068405"
"Masasi","Masasi","-10.7296","38.7999","Tanzania","TZ","TZA","Mtwara","","36032","1834414126"
"Nargund","Nargund","15.7200","75.3800","India","IN","IND","Karnātaka","","36291","1356088988"
"Águilas","Aguilas","37.4042","-1.5819","Spain","ES","ESP","Murcia","","36403","1724197717"
"São Paulo de Olivença","Sao Paulo de Olivenca","-3.4500","-68.9500","Brazil","BR","BRA","Amazonas","","36536","1076704300"
"Kharagpur","Kharagpur","25.1245","86.5558","India","IN","IND","Bihār","","35739","1356238766"
"Villajoyosa","Villajoyosa","38.5053","-0.2328","Spain","ES","ESP","Valencia","","34828","1724104919"
"DeLand","DeLand","29.0225","-81.2875","United States","US","USA","Florida","","36528","1840015071"
"Mamfe","Mamfe","5.7667","9.2833","Cameroon","CM","CMR","South-West","","36500","1120298819"
"Kaniama","Kaniama","-7.5696","24.1700","Congo (Kinshasa)","CD","COD","Haut-Lomami","","36481","1180770775"
"Arroyomolinos","Arroyomolinos","40.2667","-3.9000","Spain","ES","ESP","Madrid","","34833","1724999273"
"Aparecida","Aparecida","-22.8472","-45.2300","Brazil","BR","BRA","São Paulo","","36217","1076806368"
"Simbahan","Simbahan","6.3000","120.5833","Philippines","PH","PHL","Sulu","","36374","1608320501"
"Wildomar","Wildomar","33.6173","-117.2582","United States","US","USA","California","","36511","1840022902"
"Emsdetten","Emsdetten","52.1728","7.5344","Germany","DE","DEU","North Rhine-Westphalia","","36012","1276439566"
"Cassino","Cassino","41.5000","13.8333","Italy","IT","ITA","Lazio","","36497","1380403961"
"Nueve de Julio","Nueve de Julio","-35.4500","-60.8833","Argentina","AR","ARG","Buenos Aires","minor","36494","1032287957"
"Santa Helena de Goiás","Santa Helena de Goias","-17.8139","-50.5969","Brazil","BR","BRA","Goiás","","36469","1076150197"
"San Juan Cotzal","San Juan Cotzal","15.4353","-91.0356","Guatemala","GT","GTM","Quiché","minor","36298","1320691334"
"San Miguel Acatán","San Miguel Acatan","15.7000","-91.6167","Guatemala","GT","GTM","Huehuetenango","minor","36245","1320204061"
"Ma‘alot Tarshīḥā","Ma\`alot Tarshiha","33.0167","35.2708","Israel","IL","ISR","Northern","","36000","1376992708"
"Selçuk","Selcuk","37.9500","27.3667","Turkey","TR","TUR","İzmir","minor","36360","1792359915"
"Camamu","Camamu","-13.9450","-39.1039","Brazil","BR","BRA","Bahia","","36435","1076215451"
"Mühlhausen","Muhlhausen","51.2167","10.4500","Germany","DE","DEU","Thuringia","minor","36200","1276181580"
"Sonsón","Sonson","5.7097","-75.3108","Colombia","CO","COL","Antioquia","minor","36445","1170000118"
"Sanok","Sanok","49.5500","22.2167","Poland","PL","POL","Podkarpackie","minor","35546","1616025467"
"Pakribarawān","Pakribarawan","24.9540","85.7376","India","IN","IND","Bihār","","35049","1356513036"
"Zaventem","Zaventem","50.8833","4.4667","Belgium","BE","BEL","Flanders","","35184","1056786797"
"Huehuetán","Huehuetan","15.0000","-92.4333","Mexico","MX","MEX","Chiapas","minor","36333","1484054085"
"Aketao","Aketao","39.1468","75.9411","China","CN","CHN","Xinjiang","minor","36270","1156561563"
"Jalārpet","Jalarpet","12.5703","78.5732","India","IN","IND","Tamil Nādu","","35860","1356919193"
"Estahbān","Estahban","29.1267","54.0422","Iran","IR","IRN","Fārs","minor","36410","1364912385"
"Careiro","Careiro","-3.7678","-60.3689","Brazil","BR","BRA","Amazonas","","36435","1076439783"
"Dama","Dama","30.5009","120.3413","China","CN","CHN","Zhejiang","","35357","1156372343"
"Taguasco","Taguasco","22.0050","-79.2650","Cuba","CU","CUB","Sancti Spíritus","minor","36365","1192783112"
"Cabanglasan","Cabanglasan","8.0772","125.2992","Philippines","PH","PHL","Bukidnon","","36286","1608230715"
"Uto","Uto","32.6828","130.6669","Japan","JP","JPN","Kumamoto","","35947","1392003208"
"Saint-Raphaël","Saint-Raphael","43.4252","6.7684","France","FR","FRA","Provence-Alpes-Côte d’Azur","","36027","1250046595"
"Yorktown","Yorktown","41.2727","-73.8092","United States","US","USA","New York","","36424","1840088070"
"Kotma","Kotma","23.2038","81.9790","India","IN","IND","Madhya Pradesh","","36263","1356862503"
"Udhampur","Udhampur","32.9160","75.1419","India","IN","IND","Jammu and Kashmīr","","35507","1356335272"
"Sankt Ingbert","Sankt Ingbert","49.3000","7.1167","Germany","DE","DEU","Saarland","","35714","1276174089"
"Isla de Maipo","Isla de Maipo","-33.7500","-70.9000","Chile","CL","CHL","Región Metropolitana","","36219","1152656260"
"Petit-Trou de Nippes","Petit-Trou de Nippes","18.5333","-73.5167","Haiti","HT","HTI","Nippes","","36143","1332845900"
"Banaz","Banaz","38.7333","29.7500","Turkey","TR","TUR","Uşak","minor","36373","1792411962"
"Morohongō","Morohongo","35.9415","139.3160","Japan","JP","JPN","Saitama","","35370","1392302813"
"Nyaungdon","Nyaungdon","17.0461","95.6374","Myanmar","MM","MMR","Ayeyarwady","","36172","1104318813"
"Kerava","Kerava","60.4000","25.1000","Finland","FI","FIN","Uusimaa","minor","35293","1246221274"
"Dagami","Dagami","11.0611","124.9031","Philippines","PH","PHL","Leyte","","36178","1608027608"
"La Vallée de Jacmel","La Vallee de Jacmel","18.2667","-72.6667","Haiti","HT","HTI","Sud-Est","","36188","1332561469"
"Morecambe","Morecambe","54.0730","-2.8700","United Kingdom","GB","GBR","Lancashire","","34768","1826651603"
"Farafenni","Farafenni","13.5667","-15.6000","The Gambia","GM","GMB","North Bank","","36000","1270512503"
"Rapu-Rapu","Rapu-Rapu","13.1833","124.1333","Philippines","PH","PHL","Albay","","36151","1608870516"
"Princeton","Princeton","25.5396","-80.3971","United States","US","USA","Florida","","36381","1840014248"
"Rende","Rende","39.3333","16.1833","Italy","IT","ITA","Calabria","","35727","1380899541"
"Jasło","Jaslo","49.7478","21.4714","Poland","PL","POL","Podkarpackie","minor","35445","1616015306"
"Narsampet","Narsampet","17.9285","79.8925","India","IN","IND","Andhra Pradesh","","35571","1356029838"
"San Pablo","San Pablo","7.4825","-73.9250","Colombia","CO","COL","Bolívar","minor","36341","1170480324"
"Yahyalı","Yahyali","38.1000","35.3608","Turkey","TR","TUR","Kayseri","minor","36331","1792969584"
"Kurtalan","Kurtalan","37.9261","41.7003","Turkey","TR","TUR","Siirt","minor","36273","1792384710"
"Capalonga","Capalonga","14.3314","122.4928","Philippines","PH","PHL","Camarines Norte","","36223","1608015273"
"Oeiras","Oeiras","-7.0250","-42.1308","Brazil","BR","BRA","Piauí","","36329","1076220246"
"São Miguel d’Oeste","Sao Miguel d'Oeste","-26.7250","-53.5178","Brazil","BR","BRA","Santa Catarina","","36306","1076717099"
"Santiago Juxtlahuaca","Santiago Juxtlahuaca","17.3333","-98.0167","Mexico","MX","MEX","Oaxaca","","36275","1484380988"
"Bronkhorst","Bronkhorst","52.0833","6.1833","Netherlands","NL","NLD","Gelderland","","36212","1528829480"
"Czechowice-Dziedzice","Czechowice-Dziedzice","49.9131","19.0064","Poland","PL","POL","Śląskie","","35261","1616154009"
"Vempalle","Vempalle","14.3667","78.4667","India","IN","IND","Andhra Pradesh","","36031","1356134721"
"Andover","Andover","42.6466","-71.1651","United States","US","USA","Massachusetts","","36323","1840053666"
"Wai","Wai","17.9500","73.8900","India","IN","IND","Mahārāshtra","","36030","1356883366"
"Mahna","Mahna","30.2280","74.9937","India","IN","IND","Punjab","","35678","1356169491"
"San Bernardo del Viento","San Bernardo del Viento","9.3500","-75.9500","Colombia","CO","COL","Córdoba","minor","36200","1170684147"
"Rifu","Rifu","38.3303","140.9759","Japan","JP","JPN","Miyagi","","35521","1392894446"
"Claremont","Claremont","34.1259","-117.7153","United States","US","USA","California","","36312","1840019216"
"Medchal","Medchal","17.6297","78.4814","India","IN","IND","Andhra Pradesh","","35611","1356009043"
"Siayan","Siayan","8.2517","123.1144","Philippines","PH","PHL","Zamboanga del Norte","","36236","1608937514"
"Garhākota","Garhakota","23.7791","79.1432","India","IN","IND","Madhya Pradesh","","36119","1356671679"
"Central Islip","Central Islip","40.7836","-73.1945","United States","US","USA","New York","","36292","1840005028"
"Georgetown","Georgetown","38.2247","-84.5487","United States","US","USA","Kentucky","","36281","1840013197"
"Pandan","Pandan","11.7206","122.0961","Philippines","PH","PHL","Antique","","35965","1608538108"
"Chelmsford","Chelmsford","42.6000","-71.3631","United States","US","USA","Massachusetts","","36271","1840070185"
"Pingshang","Pingshang","23.3974","115.8842","China","CN","CHN","Guangdong","","36140","1156332960"
"Oak Creek","Oak Creek","42.8803","-87.9009","United States","US","USA","Wisconsin","","36266","1840003047"
"Anloga","Anloga","5.7919","0.9003","Ghana","GH","GHA","Volta","","35933","1288427445"
"Commack","Commack","40.8435","-73.2833","United States","US","USA","New York","","36252","1840005030"
"Andújar","Andujar","38.0392","-4.0506","Spain","ES","ESP","Andalusia","","36212","1724144147"
"Bad Vilbel","Bad Vilbel","50.1781","8.7361","Germany","DE","DEU","Hesse","","34905","1276387851"
"San Carlos Sija","San Carlos Sija","14.9833","-91.5500","Guatemala","GT","GTM","Quetzaltenango","minor","36001","1320621922"
"Barira","Barira","7.4706","124.3561","Philippines","PH","PHL","Maguindanao","","36143","1608237236"
"Smarhon’","Smarhon'","54.4836","26.4000","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","36202","1112716074"
"Onteniente","Onteniente","38.8222","-0.6072","Spain","ES","ESP","Valencia","","35946","1724051475"
"Mālpura","Malpura","26.2800","75.3800","India","IN","IND","Rājasthān","","36028","1356775815"
"Zhmerynka","Zhmerynka","49.0500","28.1000","Ukraine","UA","UKR","Vinnytska Oblast","minor","34353","1804771070"
"Palapye","Palapye","-22.5500","27.1333","Botswana","BW","BWA","Central","","36211","1072264514"
"Bombardopolis","Bombardopolis","19.7000","-73.3333","Haiti","HT","HTI","Nord-Ouest","","36028","1332445868"
"Wokha","Wokha","26.1000","94.2700","India","IN","IND","Nāgāland","","35004","1356847119"
"Kalāleh","Kalaleh","37.3808","55.4917","Iran","IR","IRN","Golestān","minor","36176","1364463106"
"Moorpark","Moorpark","34.2861","-118.8768","United States","US","USA","California","","36201","1840020472"
"Ban Mueang Na Tai","Ban Mueang Na Tai","19.5932","98.9618","Thailand","TH","THA","Chiang Mai","","36156","1764800951"
"Ingelheim","Ingelheim","49.9747","8.0564","Germany","DE","DEU","Rhineland-Palatinate","minor","35486","1276699130"
"Tlokweng","Tlokweng","-24.6667","25.9667","Botswana","BW","BWA","South East","","35982","1072229706"
"Aţ Ţurrah","At Turrah","32.6368","35.9900","Jordan","JO","JOR","Irbid","","34948","1400078308"
"Merrillville","Merrillville","41.4728","-87.3196","United States","US","USA","Indiana","","36196","1840009261"
"Hellendoorn","Hellendoorn","52.3667","6.4667","Netherlands","NL","NLD","Overijssel","","35932","1528944468"
"Estero","Estero","26.4276","-81.7951","United States","US","USA","Florida","","36187","1840013129"
"Cumberland","Cumberland","41.9703","-71.4198","United States","US","USA","Rhode Island","","36186","1840066127"
"Lunsar","Lunsar","8.6833","-12.5333","Sierra Leone","SL","SLE","Northern","","36108","1694711563"
"Augusta","Augusta","37.2500","15.2167","Italy","IT","ITA","Sicilia","","35854","1380515752"
"Tomelloso","Tomelloso","39.1578","-3.0208","Spain","ES","ESP","Castille-La Mancha","","36024","1724367154"
"Zacatepec","Zacatepec","18.6833","-99.1833","Mexico","MX","MEX","Morelos","minor","36159","1484549065"
"Ampasina-Maningory","Ampasina-Maningory","-17.2167","49.4167","Madagascar","MG","MDG","Toamasina","","36000","1450030003"
"Gutalac","Gutalac","7.9833","122.4000","Philippines","PH","PHL","Zamboanga del Norte","","36090","1608515089"
"Bautista","Bautista","15.8103","120.4756","Philippines","PH","PHL","Pangasinan","","35398","1608749594"
"Al Jabāyish","Al Jabayish","30.9549","46.9751","Iraq","IQ","IRQ","Dhī Qār","minor","36100","1368506768"
"Tambolaka","Tambolaka","-9.4333","119.2333","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","35790","1360753806"
"Paiçandu","Paicandu","-23.4578","-52.0489","Brazil","BR","BRA","Paraná","","35936","1076005300"
"Meyzieu","Meyzieu","45.7667","5.0036","France","FR","FRA","Auvergne-Rhône-Alpes","","34640","1250340917"
"Claveria","Claveria","9.5742","125.7328","Philippines","PH","PHL","Surigao del Norte","","36033","1608512763"
"Avdiivka","Avdiivka","48.1333","37.7500","Ukraine","UA","UKR","Donetska Oblast","","34938","1804444433"
"Tarikere","Tarikere","13.7104","75.8122","India","IN","IND","Karnātaka","","35942","1356964443"
"Somma Vesuviana","Somma Vesuviana","40.8725","14.4369","Italy","IT","ITA","Campania","","34981","1380770260"
"Virú","Viru","-8.4143","-78.7524","Peru","PE","PER","La Libertad","","36029","1604901046"
"Partūr","Partur","19.5911","76.2161","India","IN","IND","Mahārāshtra","","35883","1356565221"
"Roseville","Roseville","45.0155","-93.1545","United States","US","USA","Minnesota","","36119","1840008939"
"Vohipeno","Vohipeno","-17.1667","49.3000","Madagascar","MG","MDG","Toamasina","","36000","1450859907"
"Canicattì","Canicatti","37.3667","13.8500","Italy","IT","ITA","Sicilia","","35722","1380041258"
"Dunedin","Dunedin","28.0329","-82.7862","United States","US","USA","Florida","","36110","1840014144"
"Dietzenbach","Dietzenbach","50.0167","8.7833","Germany","DE","DEU","Hesse","","34517","1276741284"
"San Antonio","San Antonio","13.9000","121.3000","Philippines","PH","PHL","Quezon","minor","35891","1608858854"
"Cento","Cento","44.7333","11.2833","Italy","IT","ITA","Emilia-Romagna","","35547","1380277894"
"Sibuco","Sibuco","7.2928","122.0669","Philippines","PH","PHL","Zamboanga del Norte","","36049","1608708028"
"Mokokchūng","Mokokchung","26.3200","94.5000","India","IN","IND","Nāgāland","","35913","1356880084"
"Rāmsar","Ramsar","36.9031","50.6583","Iran","IR","IRN","Māzandarān","minor","35997","1364587162"
"Farmers Branch","Farmers Branch","32.9272","-96.8804","United States","US","USA","Texas","","36091","1840020706"
"Saparua","Saparua","-3.5749","128.6470","Indonesia","ID","IDN","Maluku","minor","36022","1360081288"
"Matanog","Matanog","7.4667","124.2500","Philippines","PH","PHL","Maguindanao","","36034","1608202820"
"Calumet City","Calumet City","41.6134","-87.5505","United States","US","USA","Illinois","","36065","1840007023"
"Adrano","Adrano","37.6667","14.8333","Italy","IT","ITA","Sicilia","","35633","1380203916"
"Sarıgöl","Sarigol","38.2403","28.6989","Turkey","TR","TUR","Manisa","minor","35957","1792940712"
"Itaitinga","Itaitinga","-3.9689","-38.5278","Brazil","BR","BRA","Ceará","","35817","1076730632"
"Marion","Marion","40.5973","-83.1227","United States","US","USA","Ohio","","36053","1840008332"
"Cativá","Cativa","9.3600","-79.8400","Panama","PA","PAN","Colón","minor","34558","1591118642"
"Conegliano","Conegliano","45.8868","12.2974","Italy","IT","ITA","Veneto","","35082","1380962405"
"Jagna","Jagna","9.6500","124.3667","Philippines","PH","PHL","Bohol","","35832","1608424671"
"Kalakkādu","Kalakkadu","8.5138","77.5494","India","IN","IND","Tamil Nādu","","35758","1356157318"
"Dumbéa","Dumbea","-22.1500","166.4500","New Caledonia","NC","NCL","Province Sud","","35873","1540079351"
"Zvishavane","Zvishavane","-20.3333","30.0333","Zimbabwe","ZW","ZWE","Masvingo","","35229","1716895095"
"Segrate","Segrate","45.4900","9.2953","Italy","IT","ITA","Lombardy","","35492","1380736133"
"Shiggaon","Shiggaon","14.9910","75.2230","India","IN","IND","Karnātaka","","35678","1356564009"
"Velasco Ibarra","Velasco Ibarra","-1.0439","-79.6383","Ecuador","EC","ECU","Guayas","","35686","1218000005"
"Guayaramerín","Guayaramerin","-10.8000","-65.3833","Bolivia","BO","BOL","El Beni","","36008","1068744206"
"Pylaía","Pylaia","40.6000","22.9833","Greece","GR","GRC","Kentrikí Makedonía","","34625","1300241123"
"Casiguran","Casiguran","12.8731","124.0081","Philippines","PH","PHL","Sorsogon","","35602","1608909406"
"Porta Westfalica","Porta Westfalica","52.2167","8.9333","Germany","DE","DEU","North Rhine-Westphalia","","35671","1276426167"
"Itapicuru","Itapicuru","-11.3169","-38.2328","Brazil","BR","BRA","Bahia","","35987","1076288432"
"Fraiburgo","Fraiburgo","-27.0258","-50.9208","Brazil","BR","BRA","Santa Catarina","","35942","1076662614"
"San Andrés Itzapa","San Andres Itzapa","14.6167","-90.8500","Guatemala","GT","GTM","Chimaltenango","minor","35579","1320220802"
"Salinas","Salinas","-2.2167","-80.9501","Ecuador","EC","ECU","Guayas","","34719","1218838548"
"Miyajima","Miyajima","33.1500","130.4667","Japan","JP","JPN","Kumamoto","","35665","1392131326"
"Addison","Addison","41.9313","-88.0085","United States","US","USA","Illinois","","35999","1840010171"
"La Libertad","La Libertad","13.4833","-89.3167","El Salvador","SV","SLV","La Libertad","","35997","1222663809"
"Chortoq","Chortoq","41.0689","71.8153","Uzbekistan","UZ","UZB","Namangan","minor","35615","1860436436"
"Sinsheim","Sinsheim","49.2500","8.8833","Germany","DE","DEU","Baden-Württemberg","","35707","1276820270"
"Chipinge","Chipinge","-20.2000","32.6200","Zimbabwe","ZW","ZWE","Manicaland","","34959","1716863573"
"Hole Narsipur","Hole Narsipur","12.7863","76.2433","India","IN","IND","Karnātaka","","35671","1356844043"
"Zhentang","Zhentang","21.8662","110.6996","China","CN","CHN","Guangdong","","35616","1156587531"
"Boyarka","Boyarka","70.7670","97.5000","Russia","RU","RUS","Krasnoyarskiy Kray","","35968","1643010185"
"Praya","Praya","-8.7223","116.2923","Indonesia","ID","IDN","Nusa Tenggara Barat","minor","35183","1360664646"
"Geiro","Geiro","-6.1428","36.8694","Tanzania","TZ","TZA","Morogoro","","35638","1834543579"
"San Lorenzo de Guayubín","San Lorenzo de Guayubin","19.6200","-71.3300","Dominican Republic","DO","DOM","Cibao Noroeste","","35923","1214005862"
"Farafangana","Farafangana","-22.8167","47.8167","Madagascar","MG","MDG","Fianarantsoa","minor","34676","1450306255"
"Kamenka","Kamenka","53.1833","44.0500","Russia","RU","RUS","Penzenskaya Oblast’","","35929","1643795205"
"Winsen","Winsen","53.3667","10.2167","Germany","DE","DEU","Lower Saxony","minor","35630","1276491512"
"Belluno","Belluno","46.1403","12.2167","Italy","IT","ITA","Veneto","minor","35710","1380892097"
"Winchester","Winchester","51.0632","-1.3080","United Kingdom","GB","GBR","Hampshire","","35200","1826967267"
"Ambohibary","Ambohibary","-18.9000","48.2333","Madagascar","MG","MDG","Toamasina","","35923","1450987733"
"Kakhovka","Kakhovka","46.8000","33.4667","Ukraine","UA","UKR","Khersonska Oblast","minor","35900","1804525373"
"Chernyakhovsk","Chernyakhovsk","54.6347","21.8119","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","35888","1643016647"
"Prince Albert","Prince Albert","53.2000","-105.7500","Canada","CA","CAN","Saskatchewan","","35926","1124158154"
"Chītāpur","Chitapur","17.1201","77.0869","India","IN","IND","Karnātaka","","35647","1356846578"
"Setouchi","Setouchi","34.6667","134.1000","Japan","JP","JPN","Okayama","","35624","1392003436"
"Toretsk","Toretsk","48.3917","37.8733","Ukraine","UA","UKR","Donetska Oblast","","34378","1804980684"
"Vyazniki","Vyazniki","56.2500","42.1667","Russia","RU","RUS","Vladimirskaya Oblast’","","35865","1643016775"
"Leyland","Leyland","53.6920","-2.6970","United Kingdom","GB","GBR","Lancashire","","35578","1826726004"
"Kumo","Kumo","10.0431","11.2183","Nigeria","NG","NGA","Gombe","minor","35712","1566501095"
"Six-Fours-les-Plages","Six-Fours-les-Plages","43.1009","5.8200","France","FR","FRA","Provence-Alpes-Côte d’Azur","","34592","1250162436"
"Dayr Ḩāfir","Dayr Hafir","36.1569","37.7078","Syria","SY","SYR","Ḩalab","minor","35409","1760046071"
"Sambir","Sambir","49.5167","23.2000","Ukraine","UA","UKR","Lvivska Oblast","minor","34444","1804333942"
"Mengdong","Mengdong","23.1499","99.2462","China","CN","CHN","Yunnan","minor","35741","1156435258"
"Tallkalakh","Tallkalakh","34.6683","36.2597","Syria","SY","SYR","Ḩimş","minor","35445","1760254463"
"Miranda de Ebro","Miranda de Ebro","42.6833","-2.9333","Spain","ES","ESP","Castille-Leon","","35528","1724933402"
"Ẕefat","Zefat","32.9658","35.4983","Israel","IL","ISR","Northern","","35700","1376611460"
"Sion","Sion","46.2333","7.3667","Switzerland","CH","CHE","Valais","","34708","1756457392"
"Prestea","Prestea","5.4333","-2.1500","Ghana","GH","GHA","Western","","35760","1288044330"
"Savanūr","Savanur","14.9731","75.3328","India","IN","IND","Karnātaka","","35563","1356852026"
"Karak","Karak","33.1167","71.0833","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","35844","1586491871"
"Santa Cruz","Santa Cruz","-6.2289","-36.0228","Brazil","BR","BRA","Rio Grande do Norte","","35797","1076706078"
"Richmond","Richmond","39.8318","-84.8905","United States","US","USA","Indiana","","35854","1840014176"
"Medina","Medina","8.9167","125.0167","Philippines","PH","PHL","Misamis Oriental","","35612","1608096754"
"Estreito","Estreito","-6.5608","-47.4508","Brazil","BR","BRA","Maranhão","","35835","1076867067"
"Atami","Atami","35.0960","139.0716","Japan","JP","JPN","Shizuoka","","35276","1392990970"
"Kita Chauhāttar","Kita Chauhattar","25.6770","84.8720","India","IN","IND","Bihār","","34342","1356562270"
"Carrollwood","Carrollwood","28.0577","-82.5148","United States","US","USA","Florida","","35846","1840029026"
"Guacarí","Guacari","3.7667","-76.3333","Colombia","CO","COL","Valle del Cauca","minor","35662","1170383272"
"Kundian","Kundian","32.4522","71.4718","Pakistan","PK","PAK","Punjab","","35406","1586180022"
"Umarga","Umarga","17.8400","76.6217","India","IN","IND","Mahārāshtra","","35477","1356601429"
"Pak Chong","Pak Chong","14.6796","101.3976","Thailand","TH","THA","Nakhon Ratchasima","minor","35736","1764898547"
"Várzea da Palma","Varzea da Palma","-17.5978","-44.7308","Brazil","BR","BRA","Minas Gerais","","35809","1076481562"
"Olney","Olney","39.1465","-77.0715","United States","US","USA","Maryland","","35815","1840005836"
"Guimbal","Guimbal","10.6667","122.3167","Philippines","PH","PHL","Iloilo","","35022","1608964265"
"Usuki","Usuki","33.1333","131.8000","Japan","JP","JPN","Ōita","","35681","1392521875"
"Leganes","Leganes","10.7833","122.5833","Philippines","PH","PHL","Iloilo","","34725","1608931505"
"Berg en Dal","Berg en Dal","51.8167","5.9167","Netherlands","NL","NLD","Gelderland","","35010","1528151765"
"Sūrandai","Surandai","8.9773","77.4206","India","IN","IND","Tamil Nādu","","35272","1356097080"
"Vavatenina","Vavatenina","-17.4667","49.2000","Madagascar","MG","MDG","Toamasina","","35750","1450949458"
"Isumi","Isumi","35.2539","140.3852","Japan","JP","JPN","Chiba","","35570","1392003275"
"Uravakonda","Uravakonda","14.9500","77.2700","India","IN","IND","Andhra Pradesh","","35565","1356897305"
"Teruel","Teruel","40.3456","-1.1065","Spain","ES","ESP","Aragon","minor","35691","1724398880"
"Al Ḩişn","Al Hisn","32.4583","35.8583","Jordan","JO","JOR","Irbid","minor","35085","1400378169"
"Svetlograd","Svetlograd","45.3500","42.8500","Russia","RU","RUS","Stavropol’skiy Kray","","35745","1643409997"
"Santiago de Tolú","Santiago de Tolu","9.5333","-75.5833","Colombia","CO","COL","Sucre","minor","35670","1170666994"
"Santa Ana","Santa Ana","18.4589","122.1419","Philippines","PH","PHL","Cagayan","","35688","1608694374"
"Guane","Guane","22.2006","-84.0839","Cuba","CU","CUB","Pinar del Río","","35718","1192395151"
"Matanzas","Matanzas","18.2428","-70.4192","Dominican Republic","DO","DOM","Valdesia","","35414","1214252982"
"Burriana","Burriana","39.8894","-0.0925","Spain","ES","ESP","Valencia","","35019","1724445446"
"Baraidih","Baraidih","25.9798","86.1434","India","IN","IND","Bihār","","34236","1356122015"
"Sochaczew","Sochaczew","52.2333","20.2333","Poland","PL","POL","Mazowieckie","minor","34439","1616172675"
"Ouro Preto d’Oeste","Ouro Preto d'Oeste","-10.7481","-62.2158","Brazil","BR","BRA","Rondônia","","35737","1076571637"
"Pambujan","Pambujan","12.5667","124.9333","Philippines","PH","PHL","Northern Samar","","35532","1608440228"
"Camiri","Camiri","-20.1000","-63.5333","Bolivia","BO","BOL","Santa Cruz","","35712","1068637731"
"Zaidpur","Zaidpur","26.8300","81.3300","India","IN","IND","Uttar Pradesh","","34443","1356767674"
"Orangevale","Orangevale","38.6881","-121.2209","United States","US","USA","California","","35729","1840018842"
"Macomia","Macomia","-12.2377","40.1190","Mozambique","MZ","MOZ","Cabo Delgado","","35682","1508378335"
"Imzouren","Imzouren","35.1500","-3.8500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","33852","1504878608"
"Balasan","Balasan","11.4728","123.0878","Philippines","PH","PHL","Iloilo","","35064","1608796347"
"Evergem","Evergem","51.0167","3.7000","Belgium","BE","BEL","Flanders","","35239","1056954111"
"Třebíč","Trebic","49.2150","15.8817","Czechia","CZ","CZE","Vysočina","","35107","1203437113"
"Shīyāli","Shiyali","11.2390","79.7360","India","IN","IND","Tamil Nādu","","34927","1356278926"
"Santa Fe","Santa Fe","11.1500","123.8000","Philippines","PH","PHL","Cebu","","34471","1608538046"
"Boyarka","Boyarka","50.3292","30.2886","Ukraine","UA","UKR","Kyivska Oblast","","35411","1804000125"
"Cieszyn","Cieszyn","49.7500","18.6333","Poland","PL","POL","Śląskie","minor","34513","1616089679"
"Lixingcun","Lixingcun","23.0852","116.3666","China","CN","CHN","Guangdong","","34254","1156050305"
"Mendez-Nuñez","Mendez-Nunez","14.1286","120.9058","Philippines","PH","PHL","Cavite","","34879","1608479897"
"Slagelse","Slagelse","55.4049","11.3531","Denmark","DK","DNK","Sjælland","minor","34636","1208687791"
"Pola","Pola","13.1439","121.4400","Philippines","PH","PHL","Oriental Mindoro","","35455","1608906063"
"Gürpınar","Gurpinar","38.3269","43.4133","Turkey","TR","TUR","Van","minor","35663","1792954694"
"Bedēsa","Bedesa","6.8830","37.9329","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","35294","1231807796"
"Câmara de Lobos","Camara de Lobos","32.6953","-16.9781","Portugal","PT","PRT","Madeira","","35666","1620560549"
"Kilimli","Kilimli","41.4833","31.8333","Turkey","TR","TUR","Zonguldak","minor","35323","1792892281"
"Garibaldi","Garibaldi","-29.2558","-51.5339","Brazil","BR","BRA","Rio Grande do Sul","","35440","1076481816"
"Karoi","Karoi","-16.8100","29.7000","Zimbabwe","ZW","ZWE","Mashonaland West","","34200","1716627056"
"Dundee","Dundee","-28.1725","30.2314","South Africa","ZA","ZAF","KwaZulu-Natal","","34924","1710402130"
"Haguenau","Haguenau","48.8200","7.7900","France","FR","FRA","Grand Est","minor","35448","1250576545"
"Ypané","Ypane","-25.4500","-57.5300","Paraguay","PY","PRY","Central","","34943","1600647389"
"Lökbatan","Lokbatan","40.3272","49.7300","Azerbaijan","AZ","AZE","Bakı","","35523","1031329823"
"Randallstown","Randallstown","39.3723","-76.8024","United States","US","USA","Maryland","","35641","1840005688"
"Al ‘Aqīq","Al \`Aqiq","20.2685","41.6493","Saudi Arabia","SA","SAU","Al Bāḩah","","35629","1682354121"
"Usilampatti","Usilampatti","9.9700","77.8000","India","IN","IND","Tamil Nādu","","35219","1356683619"
"Bariri","Bariri","-22.0744","-48.7403","Brazil","BR","BRA","São Paulo","","35558","1076167446"
"Guskhara","Guskhara","23.4928","87.7347","India","IN","IND","West Bengal","","35388","1356041960"
"Cha-am","Cha-am","12.7992","99.9683","Thailand","TH","THA","Phetchaburi","minor","35581","1764087780"
"Riverhead","Riverhead","40.9408","-72.7095","United States","US","USA","New York","","35630","1840058445"
"Schoten","Schoten","51.2500","4.5000","Belgium","BE","BEL","Flanders","","34462","1056187001"
"Palmares","Palmares","10.0466","-84.4373","Costa Rica","CR","CRI","Alajuela","","34716","1188588183"
"Gahanna","Gahanna","40.0251","-82.8637","United States","US","USA","Ohio","","35626","1840008390"
"Meridian","Meridian","32.3846","-88.6896","United States","US","USA","Mississippi","","35625","1840014891"
"Alatyr","Alatyr","54.8500","46.5833","Russia","RU","RUS","Chuvashiya","","34785","1643942115"
"Nova Cruz","Nova Cruz","-6.4778","-35.4339","Brazil","BR","BRA","Rio Grande do Norte","","35490","1076169350"
"Saint-Chamond","Saint-Chamond","45.4775","4.5153","France","FR","FRA","Auvergne-Rhône-Alpes","","34979","1250831814"
"Mörfelden-Walldorf","Morfelden-Walldorf","50.0000","8.5833","Germany","DE","DEU","Hesse","","34828","1276425407"
"Gladstone","Gladstone","-23.8427","151.2555","Australia","AU","AUS","Queensland","","34703","1036070755"
"Minami-Bōsō","Minami-Boso","35.0432","139.8400","Japan","JP","JPN","Chiba","","35454","1392515382"
"Meppen","Meppen","52.6936","7.2928","Germany","DE","DEU","Lower Saxony","minor","35415","1276180771"
"Coevorden","Coevorden","52.6667","6.7500","Netherlands","NL","NLD","Drenthe","minor","35483","1528647157"
"Dimitrovgrad","Dimitrovgrad","42.0500","25.6000","Bulgaria","BG","BGR","Haskovo","","35507","1100570821"
"Buhriz","Buhriz","33.7000","44.6667","Iraq","IQ","IRQ","Diyālá","","35400","1368638747"
"Juban","Juban","12.8478","123.9894","Philippines","PH","PHL","Sorsogon","","35297","1608767825"
"Karumattampatti","Karumattampatti","11.1093","77.1820","India","IN","IND","Tamil Nādu","","34949","1356717480"
"Gigante","Gigante","2.3867","-75.5461","Colombia","CO","COL","Huila","minor","35498","1170264129"
"Hamidiye","Hamidiye","40.0997","26.3967","Turkey","TR","TUR","Çanakkale","","35390","1792326639"
"Tilingzhai","Tilingzhai","40.2353","118.5282","China","CN","CHN","Hebei","","35197","1156620851"
"Yangambi","Yangambi","0.7675","24.4414","Congo (Kinshasa)","CD","COD","Tshopo","","35531","1180077706"
"José Bonifácio","Jose Bonifacio","-21.0528","-49.6878","Brazil","BR","BRA","São Paulo","","35538","1076809600"
"Leer","Leer","53.2308","7.4528","Germany","DE","DEU","Lower Saxony","minor","35078","1276392695"
"Yerköy","Yerkoy","39.6381","34.4672","Turkey","TR","TUR","Yozgat","minor","35561","1792072941"
"Simiganj","Simiganj","38.6525","69.0064","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","35473","1762393981"
"Douzhuang","Douzhuang","39.4323","116.0583","China","CN","CHN","Hebei","","34960","1156945827"
"Toritama","Toritama","-8.0067","-36.0567","Brazil","BR","BRA","Pernambuco","","35554","1076597056"
"La Porte","La Porte","29.6689","-95.0484","United States","US","USA","Texas","","35566","1840020932"
"Ōfunato","Ofunato","39.0680","141.7252","Japan","JP","JPN","Iwate","","35452","1392009967"
"Şaḩneh","Sahneh","34.4814","47.6908","Iran","IR","IRN","Kermānshāh","minor","35508","1364060244"
"La Troncal","La Troncal","-2.4000","-79.3300","Ecuador","EC","ECU","Cañar","","35259","1218086252"
"Zorgo","Zorgo","12.2500","-0.6167","Burkina Faso","BF","BFA","Plateau-Central","minor","35406","1854077108"
"Morong","Morong","14.6800","120.2683","Philippines","PH","PHL","Bataan","","35394","1608129969"
"Newton","Newton","8.3333","-13.0667","Sierra Leone","SL","SLE","Western Area","","35300","1694000067"
"Guamá Abajo","Guama Abajo","19.9758","-76.4100","Cuba","CU","CUB","Santiago de Cuba","","35516","1192469384"
"Quimbaya","Quimbaya","4.6333","-75.7500","Colombia","CO","COL","Quindío","minor","35276","1170735319"
"Angra do Heroísmo","Angra do Heroismo","38.6558","-27.2153","Portugal","PT","PRT","Azores","","35402","1620152939"
"Ikot Abasi","Ikot Abasi","4.5704","7.5600","Nigeria","NG","NGA","Akwa Ibom","minor","34911","1566812706"
"Nanzhiqiu","Nanzhiqiu","37.7492","115.2357","China","CN","CHN","Hebei","","35165","1156899967"
"Sungaiselam","Sungaiselam","-2.3839","105.9800","Indonesia","ID","IDN","Kepulauan Bangka Belitung","","35495","1360317432"
"Inver Grove Heights","Inver Grove Heights","44.8247","-93.0596","United States","US","USA","Minnesota","","35539","1840007858"
"Mahabo","Mahabo","-20.3833","44.6667","Madagascar","MG","MDG","Toliara","","35532","1450310874"
"Savonlinna","Savonlinna","61.8681","28.8861","Finland","FI","FIN","Etelä-Savo","minor","35523","1246948647"
"Freehold","Freehold","40.2233","-74.2986","United States","US","USA","New Jersey","","35531","1840003687"
"Akouda","Akouda","35.8714","10.5697","Tunisia","TN","TUN","Sousse","","34494","1788415117"
"Hilliard","Hilliard","40.0353","-83.1578","United States","US","USA","Ohio","","35530","1840007265"
"Cutral-Có","Cutral-Co","-38.9333","-69.2333","Argentina","AR","ARG","Neuquén","","35465","1032070013"
"Sun Prairie","Sun Prairie","43.1825","-89.2362","United States","US","USA","Wisconsin","","35528","1840002902"
"Lanciano","Lanciano","42.2333","14.3833","Italy","IT","ITA","Abruzzo","","35002","1380596606"
"Yayladağı","Yayladagi","35.9025","36.0606","Turkey","TR","TUR","Hatay","minor","35460","1792355233"
"Yeonil","Yeonil","36.0000","129.3500","South Korea","KR","KOR","Gyeongbuk","","34560","1410520135"
"Kraśnik","Krasnik","50.9167","22.2167","Poland","PL","POL","Lubelskie","minor","34230","1616082443"
"També","Tambe","-7.4100","-35.1128","Brazil","BR","BRA","Pernambuco","","35398","1076207007"
"Bathurst","Bathurst","-33.4200","149.5778","Australia","AU","AUS","New South Wales","","34303","1036446465"
"Vichuga","Vichuga","57.2000","41.9167","Russia","RU","RUS","Ivanovskaya Oblast’","","34394","1643107407"
"Lage","Lage","51.9889","8.7944","Germany","DE","DEU","North Rhine-Westphalia","","35047","1276243855"
"Shenjiabang","Shenjiabang","30.5783","120.8193","China","CN","CHN","Zhejiang","","34963","1156480578"
"Charqueadas","Charqueadas","-29.9550","-51.6250","Brazil","BR","BRA","Rio Grande do Sul","","35320","1076933382"
"Saint-Benoît","Saint-Benoit","-21.0339","55.7128","Reunion","RE","REU","","","35310","1638823449"
"Kahoku","Kahoku","36.7198","136.7067","Japan","JP","JPN","Ishikawa","","34936","1392426434"
"Cegléd","Cegled","47.1743","19.8020","Hungary","HU","HUN","Pest","minor","35334","1348930347"
"Cartagena del Chairá","Cartagena del Chaira","1.3500","-74.8333","Colombia","CO","COL","Caquetá","minor","35473","1170248005"
"Rio Brilhante","Rio Brilhante","-21.8019","-54.5458","Brazil","BR","BRA","Mato Grosso do Sul","","35465","1076414627"
"Além Paraíba","Alem Paraiba","-21.8878","-42.7039","Brazil","BR","BRA","Minas Gerais","","35401","1076955526"
"Bom Jesus do Itabapoana","Bom Jesus do Itabapoana","-21.1339","-41.6800","Brazil","BR","BRA","Rio de Janeiro","","35411","1076034658"
"Kamata","Kamata","33.5632","130.7118","Japan","JP","JPN","Fukuoka","","35208","1392357723"
"Aïne Draham","Aine Draham","36.7833","8.7000","Tunisia","TN","TUN","Jendouba","","35400","1788027300"
"Radebeul","Radebeul","51.1000","13.6500","Germany","DE","DEU","Saxony","","34151","1276277969"
"Tamuín","Tamuin","22.0000","-98.7833","Mexico","MX","MEX","San Luis Potosí","minor","35446","1484269723"
"Auxerre","Auxerre","47.7986","3.5672","France","FR","FRA","Bourgogne-Franche-Comté","minor","34764","1250482884"
"Copperas Cove","Copperas Cove","31.1192","-97.9140","United States","US","USA","Texas","","35452","1840019538"
"Tahuna","Tahuna","3.6119","125.5008","Indonesia","ID","IDN","Sulawesi Utara","minor","35307","1360805845"
"Torrington","Torrington","41.8349","-73.1282","United States","US","USA","Connecticut","","35447","1840004770"
"Armenia","Armenia","13.7500","-89.5000","El Salvador","SV","SLV","Sonsonate","","34912","1222071319"
"Pilar","Pilar","-9.5972","-35.9567","Brazil","BR","BRA","Alagoas","","35295","1076033888"
"Riachão do Jacuípe","Riachao do Jacuipe","-11.8100","-39.3819","Brazil","BR","BRA","Bahia","","35403","1076945082"
"Pilão Arcado","Pilao Arcado","-10.0018","-42.4830","Brazil","BR","BRA","Bahia","","35428","1076876998"
"Unnan","Unnan","35.2833","132.9000","Japan","JP","JPN","Shimane","","35366","1392003098"
"Buxin","Buxin","23.9523","120.5349","Taiwan","TW","TWN","Changhua","","33816","1158336542"
"Pentecoste","Pentecoste","-3.7928","-39.2700","Brazil","BR","BRA","Ceará","","35400","1076355140"
"Media Luna","Media Luna","20.1444","-77.4361","Cuba","CU","CUB","Granma","minor","35330","1192264221"
"Casimiro de Abreu","Casimiro de Abreu","-22.4808","-42.2039","Brazil","BR","BRA","Rio de Janeiro","","35347","1076781474"
"Ürgüp","Urgup","38.6314","34.9131","Turkey","TR","TUR","Nevşehir","minor","35352","1792233908"
"El Mirage","El Mirage","33.5905","-112.3271","United States","US","USA","Arizona","","35410","1840020562"
"Millerovo","Millerovo","48.9167","40.3833","Russia","RU","RUS","Rostovskaya Oblast’","minor","35384","1643830496"
"Ouro Branco","Ouro Branco","-20.5208","-43.6919","Brazil","BR","BRA","Minas Gerais","","35268","1076485763"
"Datteln","Datteln","51.6539","7.3417","Germany","DE","DEU","North Rhine-Westphalia","","34876","1276259793"
"La Palma","La Palma","22.7472","-83.5525","Cuba","CU","CUB","Pinar del Río","minor","35346","1192218029"
"Osimo","Osimo","43.4833","13.4833","Italy","IT","ITA","Marche","","35071","1380993170"
"Cieza","Cieza","38.2392","-1.4189","Spain","ES","ESP","Murcia","","35301","1724168894"
"Itaberaí","Itaberai","-16.0200","-49.8100","Brazil","BR","BRA","Goiás","","35371","1076044452"
"Coventry","Coventry","41.6934","-71.6611","United States","US","USA","Rhode Island","","35386","1840066132"
"Wildwood","Wildwood","38.5800","-90.6698","United States","US","USA","Missouri","","35384","1840010746"
"San Juan","San Juan","26.1903","-98.1520","United States","US","USA","Texas","","35384","1840022256"
"Sulop","Sulop","6.5986","125.3436","Philippines","PH","PHL","Davao del Sur","","35151","1608421469"
"Akçadağ","Akcadag","38.3450","37.9686","Turkey","TR","TUR","Malatya","minor","35359","1792886616"
"Galapagar","Galapagar","40.5764","-4.0019","Spain","ES","ESP","Madrid","","34834","1724218090"
"Winsford","Winsford","53.1940","-2.5200","United Kingdom","GB","GBR","Cheshire West and Chester","","35000","1826615523"
"Yarumal","Yarumal","7.0306","-75.5905","Colombia","CO","COL","Antioquia","","35315","1170196021"
"Oakville","Oakville","38.4479","-90.3199","United States","US","USA","Missouri","","35357","1840006121"
"Qadsayyā","Qadsayya","33.5333","36.2167","Syria","SY","SYR","Rīf Dimashq","minor","33571","1760188872"
"Allacapan","Allacapan","18.2270","121.5556","Philippines","PH","PHL","Cagayan","","35234","1608618208"
"Saarlouis","Saarlouis","49.3167","6.7500","Germany","DE","DEU","Saarland","minor","34552","1276663502"
"Tangancícuaro de Arista","Tangancicuaro de Arista","19.8889","-102.2050","Mexico","MX","MEX","Michoacán","","35256","1484528763"
"Langford Station","Langford Station","48.4506","-123.5058","Canada","CA","CAN","British Columbia","","35342","1124095065"
"Ōzu","Ozu","32.8790","130.8683","Japan","JP","JPN","Kumamoto","","34975","1392068009"
"Bradford West Gwillimbury","Bradford West Gwillimbury","44.1333","-79.6333","Canada","CA","CAN","Ontario","","35325","1124001093"
"San Juan Capistrano","San Juan Capistrano","33.5008","-117.6544","United States","US","USA","California","","35319","1840021963"
"Jieshang","Jieshang","25.6989","107.6481","China","CN","CHN","Guizhou","","35238","1156777366"
"Yellandu","Yellandu","17.6000","80.3300","India","IN","IND","Andhra Pradesh","","35056","1356029729"
"Ieper","Ieper","50.8508","2.8850","Belgium","BE","BEL","Flanders","minor","35039","1056744519"
"Gandara","Gandara","12.0130","124.8118","Philippines","PH","PHL","Samar","","35242","1608802493"
"Guaramirim","Guaramirim","-26.4728","-49.0028","Brazil","BR","BRA","Santa Catarina","","35172","1076561116"
"Trinidad","Trinidad","10.0795","124.3432","Philippines","PH","PHL","Bohol","","35119","1608919708"
"Yecla","Yecla","38.6167","-1.1167","Spain","ES","ESP","Murcia","","35234","1724394283"
"Nyamata","Nyamata","-2.2050","30.1450","Rwanda","RW","RWA","Eastern Province","","34922","1646130280"
"San Luis","San Luis","32.4911","-114.7089","United States","US","USA","Arizona","","35289","1840021996"
"Yarīm","Yarim","14.2972","44.3803","Yemen","YE","YEM","Ibb","minor","34805","1887851991"
"Bou Salem","Bou Salem","36.6167","8.9667","Tunisia","TN","TUN","Jendouba","","35192","1788364620"
"Luga","Luga","58.7333","29.8167","Russia","RU","RUS","Leningradskaya Oblast’","","35262","1643463120"
"Giddalūr","Giddalur","15.3784","78.9265","India","IN","IND","Andhra Pradesh","","35150","1356420019"
"Tall Salḩab","Tall Salhab","35.2609","36.3822","Syria","SY","SYR","Ḩamāh","minor","35000","1760127036"
"Baba I","Baba I","6.0622","10.4903","Cameroon","CM","CMR","North-West","","35000","1120222398"
"Brunswick","Brunswick","41.2465","-81.8198","United States","US","USA","Ohio","","35272","1840003485"
"Crema","Crema","45.3667","9.6833","Italy","IT","ITA","Lombardy","","34264","1380590695"
"Mananara Avaratra","Mananara Avaratra","-16.1667","49.7667","Madagascar","MG","MDG","Toamasina","","35148","1450802138"
"Cansanção","Cansancao","-10.6708","-39.4978","Brazil","BR","BRA","Bahia","","35235","1076743191"
"Mahasolo","Mahasolo","-19.1167","46.3667","Madagascar","MG","MDG","Antananarivo","","35152","1450644716"
"Voznesensk","Voznesensk","47.5725","31.3119","Ukraine","UA","UKR","Mykolaivska Oblast","minor","35243","1804117559"
"Fonseca","Fonseca","10.8333","-72.8333","Colombia","CO","COL","La Guajira","minor","35205","1170493430"
"Salisbury","Salisbury","35.6658","-80.4905","United States","US","USA","North Carolina","","35258","1840015384"
"Lingig","Lingig","8.0381","126.4127","Philippines","PH","PHL","Surigao del Sur","","35142","1608738480"
"Massapê","Massape","-3.5228","-40.3428","Brazil","BR","BRA","Ceará","","35191","1076000173"
"Ville Bonheur","Ville Bonheur","18.8167","-72.2000","Haiti","HT","HTI","Centre","","34885","1332904368"
"Gorizia","Gorizia","45.9333","13.6167","Italy","IT","ITA","Friuli Venezia Giulia","minor","34411","1380874405"
"Tecuci","Tecuci","45.8467","27.4278","Romania","RO","ROU","Galaţi","","34871","1642402101"
"Abra de Ilog","Abra de Ilog","13.4448","120.7260","Philippines","PH","PHL","Occidental Mindoro","","35176","1608871272"
"Muddebihāl","Muddebihal","16.3300","76.1300","India","IN","IND","Karnātaka","","35000","1356094712"
"Safidon","Safidon","29.4200","76.6700","India","IN","IND","Haryāna","","34728","1356834731"
"San Luis","San Luis","8.4964","125.7364","Philippines","PH","PHL","Agusan del Sur","","35196","1608855724"
"Wermelskirchen","Wermelskirchen","51.1500","7.2167","Germany","DE","DEU","North Rhine-Westphalia","","34765","1276444512"
"Mirnyy","Mirnyy","62.5500","113.9667","Russia","RU","RUS","Sakha (Yakutiya)","","35223","1643007406"
"Tooele","Tooele","40.5393","-112.3082","United States","US","USA","Utah","","35223","1840021356"
"Izkī","Izki","22.9339","57.7750","Oman","OM","OMN","Ad Dākhilīyah","","35173","1512293504"
"Curaçá","Curaca","-8.9919","-39.9078","Brazil","BR","BRA","Bahia","","35208","1076762736"
"Vedāranniyam","Vedaranniyam","10.3774","79.8495","India","IN","IND","Tamil Nādu","","34266","1356350689"
"Kōṯah-ye ‘As̲h̲rō","Kotah-ye \`Ashro","34.4492","68.7939","Afghanistan","AF","AFG","Wardak","","35008","1004450357"
"Venaria Reale","Venaria Reale","45.1167","7.6333","Italy","IT","ITA","Piedmont","","33564","1380174847"
"Yefremov","Yefremov","53.1500","38.1167","Russia","RU","RUS","Tul’skaya Oblast’","","35168","1643358029"
"Rāwatsār","Rawatsar","29.2800","74.3800","India","IN","IND","Rājasthān","","35102","1356147857"
"Umarkot","Umarkot","25.3614","69.7361","Pakistan","PK","PAK","Sindh","minor","35059","1586494172"
"Vadigenhalli","Vadigenhalli","13.2900","77.8000","India","IN","IND","Karnātaka","","34866","1356133781"
"Munai","Munai","7.9758","124.0636","Philippines","PH","PHL","Lanao del Norte","","35020","1608067274"
"Crailsheim","Crailsheim","49.1347","10.0706","Germany","DE","DEU","Baden-Württemberg","","34862","1276001596"
"Rūdarpur","Rudarpur","26.4293","83.6104","India","IN","IND","Uttar Pradesh","","34014","1356844988"
"Nevers","Nevers","46.9933","3.1572","France","FR","FRA","Bourgogne-Franche-Comté","minor","33279","1250147982"
"Afogados da Ingazeira","Afogados da Ingazeira","-7.7508","-37.6342","Brazil","BR","BRA","Pernambuco","","35088","1076271045"
"Bodocó","Bodoco","-7.7778","-39.9408","Brazil","BR","BRA","Pernambuco","","35158","1076358937"
"Třinec","Trinec","49.6778","18.6728","Czechia","CZ","CZE","Moravskoslezský Kraj","","34778","1203430167"
"Ahrensburg","Ahrensburg","53.6747","10.2411","Germany","DE","DEU","Schleswig-Holstein","","34201","1276744963"
"Owings Mills","Owings Mills","39.4115","-76.7913","United States","US","USA","Maryland","","35170","1840005684"
"Igarapé","Igarape","-20.0700","-44.3019","Brazil","BR","BRA","Minas Gerais","","34851","1076387530"
"Nova Olinda do Norte","Nova Olinda do Norte","-3.8878","-59.0939","Brazil","BR","BRA","Amazonas","","35156","1076698345"
"Mâcon","Macon","46.3063","4.8313","France","FR","FRA","Bourgogne-Franche-Comté","minor","33908","1250992809"
"Tamra","Tamra","32.8536","35.1978","Israel","IL","ISR","Northern","","34000","1376012882"
"Lingsugūr","Lingsugur","16.1700","76.5200","India","IN","IND","Karnātaka","","34932","1356924817"
"Wedel","Wedel","53.5833","9.7000","Germany","DE","DEU","Schleswig-Holstein","","34151","1276016066"
"Tienen","Tienen","50.8000","4.9333","Belgium","BE","BEL","Flanders","","34675","1056670352"
"Matou","Matou","39.5503","116.1063","China","CN","CHN","Hebei","","34281","1156076125"
"Greer","Greer","34.9330","-82.2313","United States","US","USA","South Carolina","","35151","1840013502"
"Jiaoxi","Jiaoxi","24.8167","121.7667","Taiwan","TW","TWN","Yilan","","34807","1158494243"
"Paxtaobod","Paxtaobod","40.9283","72.4992","Uzbekistan","UZ","UZB","Andijon","minor","34400","1860865597"
"Ngororero","Ngororero","-1.8650","29.6250","Rwanda","RW","RWA","Western Province","","34559","1646903610"
"Ikalamavony","Ikalamavony","-21.1500","46.5833","Madagascar","MG","MDG","Fianarantsoa","","35114","1450926732"
"San Vicente de Chucurí","San Vicente de Chucuri","6.8833","-73.4167","Colombia","CO","COL","Santander","minor","35121","1170349085"
"Hīrākud","Hirakud","21.5250","83.8727","India","IN","IND","Odisha","","34840","1356029751"
"Campbell River","Campbell River","50.0244","-125.2475","Canada","CA","CAN","British Columbia","","35138","1124851971"
"Lake Stevens","Lake Stevens","48.0024","-122.0953","United States","US","USA","Washington","","35135","1840019794"
"Adamantina","Adamantina","-21.6847","-51.0733","Brazil","BR","BRA","São Paulo","","35048","1076252044"
"Wavre","Wavre","50.7167","4.6000","Belgium","BE","BEL","Wallonia","minor","34305","1056538762"
"Al Ḩamdānīyah","Al Hamdaniyah","36.2697","43.3775","Iraq","IQ","IRQ","Nīnawá","minor","35000","1368523059"
"Azazga","Azazga","36.7453","4.3711","Algeria","DZ","DZA","Tizi Ouzou","","34683","1012087552"
"Tamura","Tamura","37.4333","140.5667","Japan","JP","JPN","Fukushima","","35042","1392003531"
"San Luis de Sincé","San Luis de Since","9.2500","-75.1500","Colombia","CO","COL","Sucre","minor","35022","1170323001"
"Northbrook","Northbrook","42.1292","-87.8352","United States","US","USA","Illinois","","35108","1840011304"
"Iyo","Iyo","33.7500","132.7000","Japan","JP","JPN","Ehime","","34927","1392849626"
"Bagamoyo","Bagamoyo","-6.4444","38.9028","Tanzania","TZ","TZA","Coast","","35000","1834546518"
"Ringsaker","Ringsaker","61.0242","10.8019","Norway","NO","NOR","Innlandet","","35073","1578611812"
"Puerto López","Puerto Lopez","4.0833","-72.9667","Colombia","CO","COL","Meta","minor","35097","1170290556"
"Kempen","Kempen","51.3658","6.4194","Germany","DE","DEU","North Rhine-Westphalia","","34597","1276967592"
"Payao","Payao","7.5857","122.8022","Philippines","PH","PHL","Zamboanga Sibugay","","34952","1608479429"
"Piskent","Piskent","40.8992","69.3425","Uzbekistan","UZ","UZB","Toshkent","","34900","1860730854"
"Fereydūn Kenār","Fereydun Kenar","36.6864","52.5225","Iran","IR","IRN","Māzandarān","minor","34452","1364024073"
"Casilda","Casilda","-33.0500","-61.1667","Argentina","AR","ARG","Santa Fe","minor","35058","1032000005"
"Formigine","Formigine","44.6072","10.9333","Italy","IT","ITA","Emilia-Romagna","","34347","1380168196"
"Talanga","Talanga","14.4047","-87.0817","Honduras","HN","HND","Francisco Morazán","","34997","1340622146"
"Āllagadda","Allagadda","15.1322","78.5138","India","IN","IND","Andhra Pradesh","","34815","1356073530"
"Seelze","Seelze","52.3961","9.5981","Germany","DE","DEU","Lower Saxony","","34442","1276781689"
"Firavahana","Firavahana","-18.6333","46.8667","Madagascar","MG","MDG","Antananarivo","","35000","1450613090"
"Glastonbury","Glastonbury","41.6921","-72.5472","United States","US","USA","Connecticut","","35061","1840034707"
"Mlimba","Mlimba","-8.7786","35.8111","Tanzania","TZ","TZA","Morogoro","","34970","1834564958"
"Fotadrevo","Fotadrevo","-24.0500","45.0167","Madagascar","MG","MDG","Toliara","","35000","1450481485"
"Caucagua","Caucagua","10.2822","-66.3772","Venezuela","VE","VEN","Miranda","minor","35000","1862601608"
"Kulittalai","Kulittalai","10.9357","78.4241","India","IN","IND","Tamil Nādu","","34532","1356566393"
"Beidou","Beidou","23.8747","120.5248","Taiwan","TW","TWN","Changhua","","33289","1158581171"
"Mānāmadurai","Manamadurai","9.6956","78.4564","India","IN","IND","Tamil Nādu","","34653","1356699794"
"Kalaa Srira","Kalaa Srira","35.8236","10.5583","Tunisia","TN","TUN","Sousse","","34548","1788232663"
"Blagoveshchensk","Blagoveshchensk","55.0350","55.9781","Russia","RU","RUS","Bashkortostan","","35008","1643322992"
"Aloguinsan","Aloguinsan","10.2229","123.5491","Philippines","PH","PHL","Cebu","","34466","1608628202"
"Tarkwa","Tarkwa","5.3000","-1.9833","Ghana","GH","GHA","Western","","34941","1288746725"
"Campoalegre","Campoalegre","2.6867","-75.3256","Colombia","CO","COL","Huila","minor","34923","1170655224"
"Ḑulay‘ Rashīd","Dulay\` Rashid","25.5067","42.8614","Saudi Arabia","SA","SAU","Al Qaşīm","","35000","1682000063"
"Shaqrā’","Shaqra'","25.2483","45.2528","Saudi Arabia","SA","SAU","Ar Riyāḑ","","35000","1682664913"
"Hatibanda","Hatibanda","24.2105","90.1492","Bangladesh","BD","BGD","Dhaka","","34445","1050959978"
"Dabaga","Dabaga","17.2667","8.1000","Niger","NE","NER","Agadez","","35000","1562964626"
"Menen","Menen","50.7956","3.1217","Belgium","BE","BEL","Flanders","","33982","1056841514"
"Korkino","Korkino","54.9000","61.4167","Russia","RU","RUS","Chelyabinskaya Oblast’","","34672","1643383415"
"Meppel","Meppel","52.7000","6.1833","Netherlands","NL","NLD","Drenthe","minor","34386","1528720406"
"Ngaoundal","Ngaoundal","6.5000","13.2667","Cameroon","CM","CMR","Adamaoua","","34971","1120312039"
"Balingen","Balingen","48.2731","8.8506","Germany","DE","DEU","Baden-Württemberg","minor","34604","1276586382"
"Camoapa","Camoapa","12.3842","-85.5119","Nicaragua","NI","NIC","Boaco","minor","34962","1558644621"
"Dhāmnod","Dhamnod","22.2093","75.4706","India","IN","IND","Madhya Pradesh","","34680","1356172191"
"Bethlehem","Bethlehem","42.5856","-73.8219","United States","US","USA","New York","","34981","1840087216"
"Dueñas","Duenas","11.0667","122.6167","Philippines","PH","PHL","Iloilo","","34597","1608780214"
"Baja","Baja","46.1833","18.9536","Hungary","HU","HUN","Bács-Kiskun","minor","34788","1348727226"
"Armação dos Búzios","Armacao dos Buzios","-22.7469","-41.8819","Brazil","BR","BRA","Rio de Janeiro","","34477","1076574611"
"Milaor","Milaor","13.5956","123.1781","Philippines","PH","PHL","Camarines Sur","","33963","1608100026"
"Laoac East","Laoac East","16.0333","120.5500","Philippines","PH","PHL","Pangasinan","","34128","1608510641"
"Seiyo","Seiyo","33.3628","132.5108","Japan","JP","JPN","Ehime","","34898","1392003249"
"Eşme","Esme","38.4000","28.9667","Turkey","TR","TUR","Uşak","minor","34932","1792758797"
"Steinfurt","Steinfurt","52.1475","7.3442","Germany","DE","DEU","North Rhine-Westphalia","minor","34645","1276842137"
"Khodābandeh","Khodabandeh","36.1194","48.5917","Iran","IR","IRN","Zanjān","","34921","1364020796"
"Arsin","Arsin","40.9500","39.9333","Turkey","TR","TUR","Trabzon","minor","34831","1792115072"
"Odorheiu Secuiesc","Odorheiu Secuiesc","46.3139","25.3017","Romania","RO","ROU","Harghita","","34257","1642134385"
"Anse-à-Veau","Anse-a-Veau","18.5167","-73.3500","Haiti","HT","HTI","Nippes","","34613","1332029379"
"Santa Cruz","Santa Cruz","-34.6372","-71.3631","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","34914","1152821821"
"Misungwi","Misungwi","-2.8500","33.0833","Tanzania","TZ","TZA","Mwanza","","34612","1834354110"
"Gundlupēt","Gundlupet","11.8000","76.6800","India","IN","IND","Karnātaka","","34768","1356073035"
"Aguaí","Aguai","-22.0603","-46.9736","Brazil","BR","BRA","São Paulo","","34863","1076299565"
"San Javier","San Javier","37.8037","-0.8343","Spain","ES","ESP","Murcia","","34468","1724177387"
"Balarāmpur","Balarampur","26.2432","89.5863","India","IN","IND","West Bengal","","34113","1356027247"
"Aznakayevo","Aznakayevo","54.8500","53.1000","Russia","RU","RUS","Tatarstan","","34896","1643955098"
"Victoria","Victoria","-38.2333","-72.3333","Chile","CL","CHL","Araucanía","","34890","1152078114"
"Barugo","Barugo","11.3167","124.7333","Philippines","PH","PHL","Leyte","","34497","1608857790"
"Deggendorf","Deggendorf","48.8333","12.9667","Germany","DE","DEU","Bavaria","minor","34454","1276512590"
"Barra do Choça","Barra do Choca","-14.8808","-40.5789","Brazil","BR","BRA","Bahia","","34853","1076410831"
"Goch","Goch","51.6839","6.1619","Germany","DE","DEU","North Rhine-Westphalia","","34593","1276890926"
"Năvodari","Navodari","44.3211","28.6133","Romania","RO","ROU","Constanţa","","34398","1642234655"
"Feijó","Feijo","-8.1639","-70.3539","Brazil","BR","BRA","Acre","","34884","1076695082"
"North Ridgeville","North Ridgeville","41.3852","-82.0195","United States","US","USA","Ohio","","34883","1840000645"
"Sint-Pieters-Leeuw","Sint-Pieters-Leeuw","50.7833","4.2500","Belgium","BE","BEL","Flanders","","34038","1056770935"
"Angamāli","Angamali","10.2000","76.4000","India","IN","IND","Kerala","","33424","1356561428"
"Vemalwāda","Vemalwada","18.4667","78.8833","India","IN","IND","Andhra Pradesh","","33706","1356119137"
"Paithan","Paithan","19.4800","75.3800","India","IN","IND","Mahārāshtra","","34556","1356410780"
"Tôrres","Torres","-29.3350","-49.7269","Brazil","BR","BRA","Rio Grande do Sul","","34656","1076421011"
"Kalarūch","Kalaruch","34.5731","74.3210","India","IN","IND","Jammu and Kashmīr","","34280","1356649877"
"Madhubani","Madhubani","26.5147","84.7645","India","IN","IND","Bihār","","33425","1356007727"
"Santa Cruz das Palmeiras","Santa Cruz das Palmeiras","-21.8269","-47.2489","Brazil","BR","BRA","São Paulo","","34737","1076200565"
"Diaowo","Diaowo","39.4812","116.0761","China","CN","CHN","Hebei","","34304","1156187183"
"Bugasong","Bugasong","11.0447","122.0647","Philippines","PH","PHL","Antique","","34676","1608618288"
"Guying","Guying","38.0887","114.5560","China","CN","CHN","Hebei","","33884","1156014074"
"Mosonmagyaróvár","Mosonmagyarovar","47.8737","17.2687","Hungary","HU","HUN","Győr-Moson-Sopron","minor","34439","1348666755"
"Capelinha","Capelinha","-17.6908","-42.5158","Brazil","BR","BRA","Minas Gerais","","34803","1076950734"
"Mogpog","Mogpog","13.4833","121.8667","Philippines","PH","PHL","Marinduque","","34516","1608266747"
"Del Rio","Del Rio","29.3708","-100.8801","United States","US","USA","Texas","","34831","1840019607"
"Dabouziya","Dabouziya","33.3064","-8.3548","Morocco","MA","MAR","Casablanca-Settat","","34607","1504001026"
"Santa Maria","Santa Maria","14.4750","121.4250","Philippines","PH","PHL","Laguna","","34511","1608364592"
"Narsīpatnam","Narsipatnam","17.6650","82.6140","India","IN","IND","Andhra Pradesh","","33757","1356577178"
"Iława","Ilawa","53.5964","19.5656","Poland","PL","POL","Warmińsko-Mazurskie","minor","33322","1616033026"
"Aksay","Aksay","51.1678","52.9950","Kazakhstan","KZ","KAZ","","","34799","1398621920"
"Houlong","Houlong","24.6167","120.7833","Taiwan","TW","TWN","Miaoli","","34355","1158090050"
"Castelo","Castelo","-20.6039","-41.1850","Brazil","BR","BRA","Espírito Santo","","34747","1076313406"
"Goshen","Goshen","41.5743","-85.8309","United States","US","USA","Indiana","","34792","1840013733"
"Abulug","Abulug","18.4441","121.4576","Philippines","PH","PHL","Cagayan","","34579","1608894919"
"Yangquan","Yangquan","37.0749","111.5541","China","CN","CHN","Shanxi","","34570","1156060978"
"Jalajala","Jalajala","14.3557","121.3233","Philippines","PH","PHL","Rizal","","34017","1608617458"
"Apodi","Apodi","-5.6639","-37.7989","Brazil","BR","BRA","Rio Grande do Norte","","34763","1076092856"
"Espiye","Espiye","40.9500","38.7333","Turkey","TR","TUR","Giresun","minor","34592","1792099876"
"Bonifacio","Bonifacio","8.0527","123.6136","Philippines","PH","PHL","Misamis Occidental","","34558","1608594855"
"Ballesteros","Ballesteros","18.4108","121.5169","Philippines","PH","PHL","Cagayan","","34488","1608497933"
"San Dimas","San Dimas","34.1082","-117.8090","United States","US","USA","California","","34775","1840021860"
"Dupnitsa","Dupnitsa","42.2650","23.1185","Bulgaria","BG","BGR","Kyustendil","","34653","1100440807"
"Sōsa","Sosa","35.7075","140.5642","Japan","JP","JPN","Chiba","","34427","1392454467"
"Yhú","Yhu","-25.0600","-55.9300","Paraguay","PY","PRY","Caaguazú","","34737","1600450977"
"Shingū","Shingu","33.7153","130.4465","Japan","JP","JPN","Fukuoka","","33017","1392392982"
"Springville","Springville","40.1638","-111.6205","United States","US","USA","Utah","","34750","1840021399"
"Awa","Awa","34.1014","134.2964","Japan","JP","JPN","Tokushima","","34569","1392003305"
"Benton","Benton","34.5776","-92.5713","United States","US","USA","Arkansas","","34746","1840013572"
"Phra Phutthabat","Phra Phutthabat","14.7212","100.8047","Thailand","TH","THA","Saraburi","minor","34322","1764815202"
"Zhitiqara","Zhitiqara","52.1908","61.2011","Kazakhstan","KZ","KAZ","","minor","34736","1398276170"
"Hinesville","Hinesville","31.8248","-81.6137","United States","US","USA","Georgia","","34733","1840014931"
"Banda","Banda","24.0449","78.9609","India","IN","IND","Madhya Pradesh","","34561","1356803031"
"Biharamulo","Biharamulo","-2.6333","31.3167","Tanzania","TZ","TZA","Kagera","","34038","1834491817"
"Sarpol-e Z̄ahāb","Sarpol-e Zahab","34.4611","45.8628","Iran","IR","IRN","Kermānshāh","minor","34632","1364372222"
"Dzierżoniów","Dzierzoniow","50.7281","16.6511","Poland","PL","POL","Dolnośląskie","minor","33137","1616826931"
"Zelenokumsk","Zelenokumsk","44.4167","43.9167","Russia","RU","RUS","Stavropol’skiy Kray","","34690","1643031364"
"Buenos Aires","Buenos Aires","2.9167","-76.6667","Colombia","CO","COL","Cauca","minor","34631","1170770289"
"Abashiri","Abashiri","44.0167","144.2667","Japan","JP","JPN","Hokkaidō","","34640","1392204790"
"Santa Maria","Santa Maria","15.9808","120.7003","Philippines","PH","PHL","Pangasinan","","34220","1608045097"
"Shangzhen","Shangzhen","33.7116","110.2575","China","CN","CHN","Shaanxi","","34481","1156871369"
"São Luís do Quitunde","Sao Luis do Quitunde","-9.3178","-35.5608","Brazil","BR","BRA","Alagoas","","34692","1076776614"
"La Blanca","La Blanca","14.5791","-92.1414","Guatemala","GT","GTM","San Marcos","minor","34307","1320627277"
"Merseburg","Merseburg","51.3544","11.9928","Germany","DE","DEU","Saxony-Anhalt","minor","34080","1276735436"
"Zweibrücken","Zweibrucken","49.2500","7.3667","Germany","DE","DEU","Rhineland-Palatinate","minor","34209","1276900149"
"Kasba","Kasba","25.8564","87.5384","India","IN","IND","Bihār","","33691","1356199609"
"Socorro","Socorro","31.6383","-106.2601","United States","US","USA","Texas","","34687","1840022136"
"IJsselstein","IJsselstein","52.0167","5.0333","Netherlands","NL","NLD","Utrecht","minor","34160","1528645762"
"Madakalavāripalli","Madakalavaripalli","14.7475","79.0843","India","IN","IND","Andhra Pradesh","","34449","1356847382"
"Ofunato","Ofunato","39.0819","141.7086","Japan","JP","JPN","Iwate","","34568","1392003522"
"Inca","Inca","39.7167","2.9167","Spain","ES","ESP","Balearic Islands","","34093","1724489288"
"Spanaway","Spanaway","47.0979","-122.4233","United States","US","USA","Washington","","34670","1840018451"
"Richmond","Richmond","37.7307","-84.2925","United States","US","USA","Kentucky","","34662","1840014341"
"Qaşr al Qarabūllī","Qasr al Qarabulli","32.7500","13.7167","Libya","LY","LBY","Ţarābulus","","34584","1434342806"
"Randolph","Randolph","42.1778","-71.0539","United States","US","USA","Massachusetts","","34661","1840004675"
"Barsinghausen","Barsinghausen","52.3000","9.4811","Germany","DE","DEU","Lower Saxony","","34327","1276183527"
"Tábor","Tabor","49.4144","14.6578","Czechia","CZ","CZE","Jihočeský Kraj","","34119","1203712399"
"Bugojno","Bugojno","44.0572","17.4508","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","34559","1070311249"
"Heunghae","Heunghae","36.1167","129.3500","South Korea","KR","KOR","Gyeongbuk","minor","34328","1410289558"
"Betafo","Betafo","-19.8400","46.8550","Madagascar","MG","MDG","Antananarivo","","34336","1450624332"
"Pleasant Hill","Pleasant Hill","37.9539","-122.0759","United States","US","USA","California","","34649","1840020283"
"Falls","Falls","40.1686","-74.7915","United States","US","USA","Pennsylvania","","34644","1840152152"
"Ankola","Ankola","14.6605","74.3047","India","IN","IND","Karnātaka","","34502","1356300206"
"Guarambaré","Guarambare","-25.4900","-57.4600","Paraguay","PY","PRY","Central","","33208","1600825126"
"Cachoeira","Cachoeira","-12.6178","-38.9558","Brazil","BR","BRA","Bahia","","34535","1076370546"
"Bayog","Bayog","7.8474","123.0423","Philippines","PH","PHL","Zamboanga del Sur","","34519","1608058578"
"Esposende","Esposende","41.5333","-8.7833","Portugal","PT","PRT","Braga","minor","34254","1620944667"
"Playas","Playas","-2.6300","-80.3900","Ecuador","EC","ECU","Guayas","","34409","1218216648"
"Zhangzhengqiao","Zhangzhengqiao","38.4042","106.3567","China","CN","CHN","Ningxia","","34256","1156241639"
"Parnarama","Parnarama","-5.6819","-43.0928","Brazil","BR","BRA","Maranhão","","34586","1076586563"
"Ocuilan de Arteaga","Ocuilan de Arteaga","19.0000","-99.4000","Mexico","MX","MEX","México","minor","34485","1484570544"
"Sabaneta","Sabaneta","19.4833","-71.3500","Dominican Republic","DO","DOM","Cibao Noroeste","minor","34540","1214181927"
"Cañete","Canete","-37.7994","-73.3953","Chile","CL","CHL","Biobío","","34537","1152180527"
"Tiruvūr","Tiruvur","17.1000","80.6000","India","IN","IND","Andhra Pradesh","","34173","1356119105"
"Hemer","Hemer","51.3833","7.7667","Germany","DE","DEU","North Rhine-Westphalia","","34080","1276327126"
"University Place","University Place","47.2147","-122.5461","United States","US","USA","Washington","","34565","1840021130"
"Stow","Stow","41.1765","-81.4344","United States","US","USA","Ohio","","34556","1840000803"
"Skhira","Skhira","34.3006","10.0708","Tunisia","TN","TUN","Sfax","","34511","1788034697"
"Kópavogur","Kopavogur","64.1108","-21.9094","Iceland","IS","ISL","Kópavogur","","34140","1352411842"
"Cameron Highlands","Cameron Highlands","4.5000","101.5000","Malaysia","MY","MYS","Pahang","","34510","1458039687"
"Songcaozhen","Songcaozhen","37.7562","114.5834","China","CN","CHN","Hebei","","33601","1156982964"
"Centenario","Centenario","-38.8000","-68.1333","Argentina","AR","ARG","Neuquén","","34421","1032495336"
"Humenné","Humenne","48.9358","21.9067","Slovakia","SK","SVK","Prešov","minor","33441","1703492155"
"Cícero Dantas","Cicero Dantas","-10.6000","-38.3828","Brazil","BR","BRA","Bahia","","34478","1076276017"
"Quilevo","Quilevo","-7.6167","15.0500","Angola","AO","AGO","Uíge","","34297","1024896848"
"New Panamao","New Panamao","5.9667","121.2000","Philippines","PH","PHL","Sulu","","34249","1608080145"
"Bāft","Baft","29.2331","56.6022","Iran","IR","IRN","Kermān","minor","34517","1364013464"
"Mnasra","Mnasra","34.7667","-5.5167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","34429","1504815098"
"Áno Liósia","Ano Liosia","38.0833","23.7000","Greece","GR","GRC","Attikí","minor","33565","1300536018"
"Kaneohe","Kaneohe","21.4062","-157.7904","United States","US","USA","Hawaii","","34509","1840029498"
"Gotō","Goto","32.7000","128.8333","Japan","JP","JPN","Nagasaki","","34425","1392003394"
"Banes","Banes","20.9697","-75.7117","Cuba","CU","CUB","Holguín","minor","34452","1192981466"
"Oława","Olawa","50.9333","17.3000","Poland","PL","POL","Dolnośląskie","minor","33268","1616142881"
"Gerāsh","Gerash","27.6672","54.1411","Iran","IR","IRN","Fārs","minor","34469","1364856957"
"Iturama","Iturama","-19.7278","-50.1958","Brazil","BR","BRA","Minas Gerais","","34456","1076049375"
"Xincheng","Xincheng","38.2679","114.6832","China","CN","CHN","Hebei","","33248","1156176098"
"Inabe","Inabe","35.1156","136.5614","Japan","JP","JPN","Mie","","34323","1392110591"
"Degāna","Degana","26.8951","74.3222","India","IN","IND","Rājasthān","","34315","1356669884"
"Curuzú Cuatiá","Curuzu Cuatia","-29.7833","-58.0833","Argentina","AR","ARG","Corrientes","minor","34470","1032386934"
"Hisuā","Hisua","24.8336","85.4173","India","IN","IND","Bihār","","33270","1356104630"
"Vassouras","Vassouras","-22.4039","-43.6628","Brazil","BR","BRA","Rio de Janeiro","","34410","1076049981"
"Nola","Nola","40.9333","14.5333","Italy","IT","ITA","Campania","","34467","1380727895"
"Fruit Cove","Fruit Cove","30.0972","-81.6175","United States","US","USA","Florida","","34469","1840013970"
"Monroe","Monroe","35.0063","-80.5596","United States","US","USA","North Carolina","","34464","1840014613"
"Youwangjie","Youwangjie","24.8695","99.1067","China","CN","CHN","Yunnan","","33978","1156155003"
"Minas de Matahambre","Minas de Matahambre","22.5822","-83.9492","Cuba","CU","CUB","Pinar del Río","minor","34419","1192768054"
"Simunul","Simunul","4.8980","119.8213","Philippines","PH","PHL","Tawi-Tawi","","34245","1608851616"
"Sirīpur","Siripur","25.9970","86.9599","India","IN","IND","Bihār","","33417","1356094473"
"Shinjō","Shinjo","38.7667","140.3000","Japan","JP","JPN","Yamagata","","34284","1392003420"
"Risod","Risod","19.9700","76.7800","India","IN","IND","Mahārāshtra","","34136","1356033966"
"Râmnicu Sărat","Ramnicu Sarat","45.3800","27.0600","Romania","RO","ROU","Buzău","","33843","1642484794"
"Exmouth","Exmouth","50.6200","-3.4130","United Kingdom","GB","GBR","Devon","","34432","1826008044"
"Pingtang","Pingtang","22.7542","111.7527","China","CN","CHN","Guangdong","","34103","1156555380"
"Jaguaribe","Jaguaribe","-5.8908","-38.6219","Brazil","BR","BRA","Ceará","","34409","1076039425"
"Belhi","Belhi","26.5769","86.1723","India","IN","IND","Bihār","","32635","1356604967"
"Shamsābād","Shamsabad","17.2603","78.3969","India","IN","IND","Andhra Pradesh","","32583","1356251040"
"Zamānia","Zamania","25.4194","83.5569","India","IN","IND","Uttar Pradesh","","33243","1356580984"
"Douglasville","Douglasville","33.7384","-84.7054","United States","US","USA","Georgia","","34410","1840014804"
"Casale Monferrato","Casale Monferrato","45.1342","8.4583","Italy","IT","ITA","Piedmont","","34010","1380727799"
"Almora","Almora","29.5971","79.6591","India","IN","IND","Uttarākhand","","34122","1356045284"
"Fairborn","Fairborn","39.8010","-84.0094","United States","US","USA","Ohio","","34398","1840008440"
"Biwong","Biwong","3.1333","11.1500","Cameroon","CM","CMR","Sud","","34374","1120534976"
"Korschenbroich","Korschenbroich","51.1833","6.5167","Germany","DE","DEU","North Rhine-Westphalia","","33786","1276206387"
"Talayan","Talayan","6.9844","124.3564","Philippines","PH","PHL","Maguindanao","","34156","1608918556"
"San Manuel","San Manuel","17.0167","121.6333","Philippines","PH","PHL","Isabela","","34085","1608882654"
"Tandubas","Tandubas","5.1340","120.3461","Philippines","PH","PHL","Tawi-Tawi","","34316","1608495674"
"Lommel","Lommel","51.2333","5.3167","Belgium","BE","BEL","Flanders","","34044","1056200489"
"Kallidaikurichi","Kallidaikurichi","8.6859","77.4659","India","IN","IND","Tamil Nādu","","33984","1356664693"
"Santa Ana Nextlalpan","Santa Ana Nextlalpan","19.7167","-99.0667","Mexico","MX","MEX","México","minor","34374","1484696225"
"Butte","Butte","45.9020","-112.6571","United States","US","USA","Montana","","34368","1840032850"
"Vibo Valentia","Vibo Valentia","38.6753","16.0959","Italy","IT","ITA","Calabria","minor","33642","1380359147"
"Sibutu","Sibutu","4.8500","119.4667","Philippines","PH","PHL","Tawi-Tawi","","34243","1608534545"
"Dakota Ridge","Dakota Ridge","39.6192","-105.1344","United States","US","USA","Colorado","","34361","1840038683"
"Machachi","Machachi","-0.5100","-78.5700","Ecuador","EC","ECU","Pichincha","","34283","1218716306"
"Tōon","Toon","33.7833","132.8667","Japan","JP","JPN","Ehime","","34195","1392003399"
"Toda Bhīm","Toda Bhim","26.9167","76.8167","India","IN","IND","Rājasthān","","33876","1356092473"
"Oildale","Oildale","35.4293","-119.0306","United States","US","USA","California","","34350","1840019137"
"Kohtla-Järve","Kohtla-Jarve","59.3978","27.2572","Estonia","EE","EST","Kohtla-Järve","minor","33498","1233016348"
"Qingyang","Qingyang","36.1985","113.4313","China","CN","CHN","Shanxi","minor","34098","1156149522"
"Cariari","Cariari","10.4349","-83.6851","Costa Rica","CR","CRI","Limón","","34176","1188001355"
"Mason","Mason","39.3571","-84.3023","United States","US","USA","Ohio","","34337","1840008467"
"Missão Velha","Missao Velha","-7.2500","-39.1428","Brazil","BR","BRA","Ceará","","34274","1076844582"
"Oswego","Oswego","41.6834","-88.3372","United States","US","USA","Illinois","","34324","1840011508"
"Borne","Borne","25.5431","86.6999","India","IN","IND","Bihār","","33199","1356892552"
"São Sebastião","Sao Sebastiao","-9.9339","-36.5539","Brazil","BR","BRA","Alagoas","","34211","1076306142"
"Pinukpuk","Pinukpuk","17.5731","121.3653","Philippines","PH","PHL","Kalinga","","34275","1608337793"
"Gadsden","Gadsden","34.0086","-86.0157","United States","US","USA","Alabama","","34317","1840006332"
"Plainfield","Plainfield","39.6954","-86.3716","United States","US","USA","Indiana","","34311","1840010579"
"Penalva","Penalva","-3.2939","-45.1739","Brazil","BR","BRA","Maranhão","","34267","1076744863"
"Guindulman","Guindulman","9.7620","124.4880","Philippines","PH","PHL","Bohol","","34104","1608000310"
"Úbeda","Ubeda","38.0117","-3.3717","Spain","ES","ESP","Andalusia","","34208","1724367504"
"Gulkevichi","Gulkevichi","45.3594","40.7072","Russia","RU","RUS","Krasnodarskiy Kray","minor","34215","1643733778"
"Businga","Businga","3.3397","20.8700","Congo (Kinshasa)","CD","COD","Nord-Ubangi","","34247","1180719970"
"Bogorodsk","Bogorodsk","56.1167","43.5000","Russia","RU","RUS","Nizhegorodskaya Oblast’","","34240","1643424718"
"Znojmo","Znojmo","48.8556","16.0489","Czechia","CZ","CZE","Jihomoravský Kraj","","33775","1203362507"
"Pangururan","Pangururan","2.6075","98.6959","Indonesia","ID","IDN","Sumatera Utara","minor","34209","1360440190"
"Lautaro","Lautaro","-38.5167","-72.4500","Chile","CL","CHL","Araucanía","","34268","1152700530"
"San Quintin","San Quintin","15.9844","120.8150","Philippines","PH","PHL","Pangasinan","","33980","1608250761"
"San José Poaquil","San Jose Poaquil","14.8167","-90.9000","Guatemala","GT","GTM","Chimaltenango","minor","33932","1320659437"
"Cambrai","Cambrai","50.1767","3.2356","France","FR","FRA","Hauts-de-France","minor","32501","1250084532"
"São Desidério","Sao Desiderio","-12.3628","-44.9728","Brazil","BR","BRA","Bahia","","34266","1076278126"
"Puqiancun","Puqiancun","20.0263","110.5780","China","CN","CHN","Hainan","","34027","1156572519"
"Manitowoc","Manitowoc","44.0991","-87.6812","United States","US","USA","Wisconsin","","34267","1840002213"
"Lufkin","Lufkin","31.3217","-94.7277","United States","US","USA","Texas","","34264","1840020840"
"Çınarcık","Cinarcik","40.6422","29.1203","Turkey","TR","TUR","Yalova","minor","34076","1792016755"
"Caldono","Caldono","2.8000","-76.5333","Colombia","CO","COL","Cauca","minor","34132","1170589443"
"General Nakar","General Nakar","14.7631","121.6350","Philippines","PH","PHL","Quezon","","34225","1608579527"
"Tepeji del Río de Ocampo","Tepeji del Rio de Ocampo","19.9039","-99.3414","Mexico","MX","MEX","Hidalgo","","34151","1484352351"
"Komárno","Komarno","47.7633","18.1283","Slovakia","SK","SVK","Nitra","","33927","1703927453"
"Cedar City","Cedar City","37.6834","-113.0956","United States","US","USA","Utah","","34246","1840018895"
"Datang","Datang","26.3909","108.0764","China","CN","CHN","Guizhou","","34169","1156055547"
"Shāhīn Dezh","Shahin Dezh","36.6792","46.5669","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","34204","1364208028"
"Vitrolles","Vitrolles","43.4600","5.2486","France","FR","FRA","Provence-Alpes-Côte d’Azur","","33333","1250199358"
"Nāz̧erābād","Nazerabad","31.5775","54.4369","Iran","IR","IRN","Yazd","","34237","1364306000"
"Deer Park","Deer Park","29.6898","-95.1151","United States","US","USA","Texas","","34240","1840019621"
"Gyapekurom","Gyapekurom","7.5833","-2.7833","Ghana","GH","GHA","Bono","","34107","1288171372"
"Lexington","Lexington","42.4456","-71.2307","United States","US","USA","Massachusetts","","34235","1840053638"
"Quinchía","Quinchia","5.3379","-75.7296","Colombia","CO","COL","Risaralda","minor","34005","1170201227"
"Rafaḩ","Rafah","31.2808","34.2414","Egypt","EG","EGY","Shamāl Sīnā’","minor","34055","1818109072"
"Kokrajhar","Kokrajhar","26.4000","90.2700","India","IN","IND","Assam","","34136","1356034356"
"McMinnville","McMinnville","45.2110","-123.1918","United States","US","USA","Oregon","","34224","1840019956"
"Dao","Dao","11.3800","122.6300","Philippines","PH","PHL","Capiz","","33842","1608336072"
"Palapag","Palapag","12.5470","125.1160","Philippines","PH","PHL","Northern Samar","","34034","1608117306"
"Goianira","Goianira","-16.4958","-49.4258","Brazil","BR","BRA","Goiás","","34060","1076457587"
"Tobelo","Tobelo","1.7319","128.0078","Indonesia","ID","IDN","Maluku Utara","minor","34150","1360613851"
"Cravinhos","Cravinhos","-21.3403","-47.7294","Brazil","BR","BRA","São Paulo","","34110","1076465069"
"Tabango","Tabango","11.3067","124.3714","Philippines","PH","PHL","Leyte","","33868","1608467512"
"Şereflikoçhisar","Sereflikochisar","38.9444","33.5419","Turkey","TR","TUR","Ankara","minor","34202","1792505999"
"Tynaarlo","Tynaarlo","53.0833","6.5833","Netherlands","NL","NLD","Drenthe","","33978","1528510592"
"Initao","Initao","8.5000","124.3167","Philippines","PH","PHL","Misamis Oriental","","33902","1608853525"
"Peyziwat","Peyziwat","39.4905","76.7389","China","CN","CHN","Xinjiang","minor","33971","1156141748"
"Cantilan","Cantilan","9.3356","125.9769","Philippines","PH","PHL","Surigao del Sur","","34060","1608479522"
"Derry","Derry","42.8888","-71.2804","United States","US","USA","New Hampshire","","34197","1840054793"
"Nowy Targ","Nowy Targ","49.4667","20.0167","Poland","PL","POL","Małopolskie","minor","33545","1616880514"
"Viernheim","Viernheim","49.5417","8.5786","Germany","DE","DEU","Hesse","","34189","1276924320"
"Marignane","Marignane","43.4160","5.2145","France","FR","FRA","Provence-Alpes-Côte d’Azur","","32793","1250764669"
"Termoli","Termoli","42.0000","14.9833","Italy","IT","ITA","Molise","","33583","1380386947"
"Geldern","Geldern","51.5197","6.3325","Germany","DE","DEU","North Rhine-Westphalia","","33836","1276273584"
"Byādgi","Byadgi","14.6733","75.4868","India","IN","IND","Karnātaka","","33875","1356354161"
"Grodzisk Mazowiecki","Grodzisk Mazowiecki","52.1167","20.6333","Poland","PL","POL","Mazowieckie","minor","33708","1616204400"
"Biberach","Biberach","48.1000","9.7833","Germany","DE","DEU","Baden-Württemberg","minor","33708","1276241010"
"Shisō","Shiso","35.0000","134.5500","Japan","JP","JPN","Hyōgo","","34116","1392003057"
"San Martín Sacatepéquez","San Martin Sacatepequez","14.8246","-91.6425","Guatemala","GT","GTM","Quetzaltenango","minor","33826","1320569817"
"Cishan","Cishan","36.5780","114.1020","China","CN","CHN","Hebei","","33474","1156278646"
"Río Verde Arriba","Rio Verde Arriba","19.3200","-70.5700","Dominican Republic","DO","DOM","Cibao Sur","","33809","1214786012"
"Pulgaon","Pulgaon","20.7260","78.3170","India","IN","IND","Mahārāshtra","","33925","1356747554"
"Irvine","Irvine","55.6201","-4.6614","United Kingdom","GB","GBR","North Ayrshire","","33698","1826965925"
"Valuyki","Valuyki","50.2167","38.1000","Russia","RU","RUS","Belgorodskaya Oblast’","","34118","1643337970"
"Woodridge","Woodridge","41.7370","-88.0409","United States","US","USA","Illinois","","34161","1840011411"
"Bassin Bleu","Bassin Bleu","19.7833","-72.8000","Haiti","HT","HTI","Nord-Ouest","","33926","1332244969"
"Romans-sur-Isère","Romans-sur-Isere","45.0464","5.0517","France","FR","FRA","Auvergne-Rhône-Alpes","","33160","1250668637"
"Paşcani","Pascani","47.2494","26.7272","Romania","RO","ROU","Iaşi","","33745","1642197726"
"Binche","Binche","50.4000","4.1667","Belgium","BE","BEL","Wallonia","","33598","1056520553"
"Fungurume","Fungurume","-10.6167","26.3000","Congo (Kinshasa)","CD","COD","Lualaba","","34104","1180430603"
"Uonuma","Uonuma","37.2301","138.9615","Japan","JP","JPN","Niigata","","34112","1392915660"
"Dimona","Dimona","31.0667","35.0333","Israel","IL","ISR","Southern","","34135","1376956540"
"San Agustín","San Agustin","1.9000","-76.2833","Colombia","CO","COL","Huila","minor","34120","1170663571"
"Mascalucia","Mascalucia","37.5667","15.0500","Italy","IT","ITA","Sicilia","","32167","1380571044"
"Annigeri","Annigeri","15.4251","75.4335","India","IN","IND","Karnātaka","","33937","1356141254"
"Boğazlıyan","Bogazliyan","39.1942","35.2472","Turkey","TR","TUR","Yozgat","minor","34121","1792773324"
"Câmpina","Campina","45.1300","25.7400","Romania","RO","ROU","Prahova","","32935","1642518120"
"San Remigio","San Remigio","10.8331","122.0875","Philippines","PH","PHL","Antique","","34045","1608377520"
"Santa Ana","Santa Ana","11.0680","-63.9221","Venezuela","VE","VEN","Nueva Esparta","minor","33435","1862533518"
"Chand Chaur","Chand Chaur","25.7276","85.7865","India","IN","IND","Bihār","","32754","1356967717"
"Villena","Villena","38.6350","-0.8658","Spain","ES","ESP","Valencia","","34025","1724638080"
"Pontneddfechan","Pontneddfechan","51.7554","-3.5878","United Kingdom","GB","GBR","Powys","","34000","1826157261"
"Prairieville","Prairieville","30.3151","-90.9571","United States","US","USA","Louisiana","","34118","1840026029"
"New City","New City","41.1542","-73.9912","United States","US","USA","New York","","34118","1840004964"
"Tobias Fornier","Tobias Fornier","10.5178","121.9450","Philippines","PH","PHL","Antique","","33816","1608748452"
"Piombino","Piombino","42.9348","10.5221","Italy","IT","ITA","Tuscany","","33855","1380556086"
"Zhuqi","Zhuqi","23.5065","120.6021","Taiwan","TW","TWN","Chiayi","","33906","1158917918"
"Lochem","Lochem","52.1615","6.4152","Netherlands","NL","NLD","Gelderland","minor","33948","1528747792"
"Victoria Falls","Victoria Falls","-17.9333","25.8333","Zimbabwe","ZW","ZWE","Matabeleland North","","33060","1716149447"
"Mahasoabe","Mahasoabe","-21.5833","47.2167","Madagascar","MG","MDG","Fianarantsoa","","34000","1450903895"
"Atiquizaya","Atiquizaya","13.9667","-89.7500","El Salvador","SV","SLV","Ahuachapán","","33587","1222716346"
"Matozinhos","Matozinhos","-19.5578","-44.0808","Brazil","BR","BRA","Minas Gerais","","33955","1076626899"
"Stuhr","Stuhr","53.0167","8.7500","Germany","DE","DEU","Lower Saxony","","33678","1276158158"
"Castelfranco Veneto","Castelfranco Veneto","45.6667","11.9333","Italy","IT","ITA","Veneto","","33435","1380391523"
"Lianga","Lianga","8.6330","126.0932","Philippines","PH","PHL","Surigao del Sur","minor","33869","1608000757"
"San Antonio de los Baños","San Antonio de los Banos","22.8889","-82.4989","Cuba","CU","CUB","Artemisa","minor","33811","1192374010"
"Réo","Reo","12.3167","-2.4667","Burkina Faso","BF","BFA","Centre-Ouest","minor","33893","1854684997"
"Zhaitangcun","Zhaitangcun","24.5133","112.3451","China","CN","CHN","Guangdong","","33876","1156138159"
"Cookeville","Cookeville","36.1482","-85.5109","United States","US","USA","Tennessee","","34068","1840014470"
"Lupi Viejo","Lupi Viejo","13.7908","122.9092","Philippines","PH","PHL","Camarines Sur","","33897","1608378429"
"Spruce Grove","Spruce Grove","53.5450","-113.9008","Canada","CA","CAN","Alberta","","34066","1124943146"
"San Juan Nepomuceno","San Juan Nepomuceno","9.9500","-75.0833","Colombia","CO","COL","Bolívar","minor","34012","1170645816"
"Yinchengpu","Yinchengpu","39.8189","118.1880","China","CN","CHN","Hebei","","32471","1156786204"
"Shikārpūr","Shikarpur","28.2814","78.0108","India","IN","IND","Uttar Pradesh","","33130","1356948529"
"Dartmouth","Dartmouth","41.6138","-70.9973","United States","US","USA","Massachusetts","","34054","1840070232"
"Catmon","Catmon","10.6667","123.9500","Philippines","PH","PHL","Cebu","","33745","1608086129"
"Majagual","Majagual","8.5000","-74.6667","Colombia","CO","COL","Sucre","minor","34010","1170046110"
"Westlake","Westlake","41.4524","-81.9294","United States","US","USA","Ohio","","34049","1840003420"
"Ankazomiriotra","Ankazomiriotra","-19.6500","46.5000","Madagascar","MG","MDG","Antananarivo","","34000","1450000270"
"Sneek","Sneek","53.0325","5.6600","Netherlands","NL","NLD","Fryslân","minor","33855","1528519107"
"Tortosa","Tortosa","40.8125","0.5211","Spain","ES","ESP","Catalonia","","33890","1724228664"
"Dīvāndarreh","Divandarreh","35.9139","47.0239","Iran","IR","IRN","Kordestān","minor","34007","1364126275"
"Eirunepé","Eirunepe","-6.6597","-69.8744","Brazil","BR","BRA","Amazonas","","34025","1076884388"
"Brumadinho","Brumadinho","-20.1428","-44.2000","Brazil","BR","BRA","Minas Gerais","","33973","1076272564"
"Maromandia","Maromandia","-14.2167","48.0833","Madagascar","MG","MDG","Mahajanga","","34000","1450666158"
"Sibagat","Sibagat","8.8219","125.6938","Philippines","PH","PHL","Agusan del Sur","","33957","1608693755"
"Mizuho","Mizuho","35.7720","139.3540","Japan","JP","JPN","Saitama","","32108","1392003072"
"Merritt Island","Merritt Island","28.3139","-80.6708","United States","US","USA","Florida","","34013","1840014076"
"Shamsābād","Shamsabad","27.0200","78.1300","India","IN","IND","Uttar Pradesh","","33144","1356841862"
"Bacarra","Bacarra","18.2519","120.6107","Philippines","PH","PHL","Ilocos Norte","","33496","1608322238"
"Baltiysk","Baltiysk","54.6500","19.9167","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","33317","1643620016"
"Vadavalli","Vadavalli","11.0258","76.9038","India","IN","IND","Tamil Nādu","","32708","1356781996"
"Oulad Zemam","Oulad Zemam","32.3500","-6.6333","Morocco","MA","MAR","Béni Mellal-Khénifra","","33652","1504396811"
"Naguilian","Naguilian","17.0167","121.8500","Philippines","PH","PHL","Isabela","","33788","1608878667"
"Kondapalle","Kondapalle","16.6167","80.5333","India","IN","IND","Andhra Pradesh","","33373","1356354397"
"Elmina","Elmina","5.0833","-1.3500","Ghana","GH","GHA","Central","","33576","1288051661"
"Lewiston","Lewiston","46.3934","-116.9933","United States","US","USA","Idaho","","33964","1840019897"
"Pôrto de Moz","Porto de Moz","-1.7478","-52.2378","Brazil","BR","BRA","Pará","","33956","1076963542"
"Furmanov","Furmanov","57.2500","41.1333","Russia","RU","RUS","Ivanovskaya Oblast’","","33905","1643283283"
"Descalvado","Descalvado","-21.9039","-47.6189","Brazil","BR","BRA","São Paulo","","33910","1076021755"
"Ojiya","Ojiya","37.3144","138.7951","Japan","JP","JPN","Niigata","","33729","1392003354"
"Turiaçu","Turiacu","-1.6628","-45.3719","Brazil","BR","BRA","Maranhão","","33933","1076381653"
"Sındırgı","Sindirgi","39.2400","28.1750","Turkey","TR","TUR","Balıkesir","minor","33924","1792952422"
"Almendralejo","Almendralejo","38.6833","-6.4167","Spain","ES","ESP","Extremadura","","33741","1724000018"
"Datu Paglas","Datu Paglas","6.7669","124.8500","Philippines","PH","PHL","Maguindanao","","33682","1608409121"
"Kadingilan","Kadingilan","7.6003","124.9097","Philippines","PH","PHL","Bukidnon","","33735","1608332793"
"Nizhneudinsk","Nizhneudinsk","54.9333","99.0000","Russia","RU","RUS","Irkutskaya Oblast’","","33918","1643007260"
"Midlothian","Midlothian","32.4669","-96.9890","United States","US","USA","Texas","","33914","1840020757"
"Lebowakgomo","Lebowakgomo","-24.2000","29.5000","South Africa","ZA","ZAF","Limpopo","","33308","1710999076"
"Xinyuan","Xinyuan","22.5149","120.4470","Taiwan","TW","TWN","Pingtung","","33038","1158820437"
"Batan","Batan","11.5853","122.4981","Philippines","PH","PHL","Aklan","","33484","1608398108"
"Ben Ahmed","Ben Ahmed","33.0655","-7.2464","Morocco","MA","MAR","Casablanca-Settat","","33105","1504202051"
"Dhekiajuli","Dhekiajuli","26.7037","92.4781","India","IN","IND","Assam","","33460","1356842057"
"Capoocan","Capoocan","11.2944","124.6419","Philippines","PH","PHL","Leyte","","33721","1608022763"
"Moose Jaw","Moose Jaw","50.3933","-105.5519","Canada","CA","CAN","Saskatchewan","","33890","1124806868"
"Ken Caryl","Ken Caryl","39.5770","-105.1144","United States","US","USA","Colorado","","33889","1840028580"
"Landskrona","Landskrona","55.8706","12.8311","Sweden","SE","SWE","Skåne","minor","33372","1752529229"
"Debagrām","Debagram","23.6833","88.2833","India","IN","IND","West Bengal","","32695","1356831548"
"Bozdoğan","Bozdogan","37.6728","28.3103","Turkey","TR","TUR","Aydın","minor","33843","1792268434"
"Bāmaur","Bamaur","26.3390","78.1010","India","IN","IND","Madhya Pradesh","","33299","1356975130"
"Custódia","Custodia","-8.0875","-37.6431","Brazil","BR","BRA","Pernambuco","","33855","1076621453"
"Tiruchendūr","Tiruchendur","8.4946","78.1219","India","IN","IND","Tamil Nādu","","33171","1356944816"
"Uelzen","Uelzen","52.9647","10.5658","Germany","DE","DEU","Lower Saxony","minor","33629","1276042012"
"Camiri","Camiri","-20.0386","-63.5183","Bolivia","BO","BOL","Santa Cruz","","33838","1068205873"
"Cooper City","Cooper City","26.0463","-80.2862","United States","US","USA","Florida","","33867","1840015133"
"Ban Pak Phun","Ban Pak Phun","8.5391","99.9702","Thailand","TH","THA","Nakhon Si Thammarat","","33407","1764808518"
"El Ksar","El Ksar","34.3900","8.8000","Tunisia","TN","TUN","Gafsa","","33729","1788855302"
"Tekes","Tekes","43.2181","81.8372","China","CN","CHN","Xinjiang","minor","33740","1156184217"
"Woodstock","Woodstock","34.1026","-84.5091","United States","US","USA","Georgia","","33857","1840015574"
"La Trinidad","La Trinidad","12.9678","-86.2339","Nicaragua","NI","NIC","Managua","","33840","1558209065"
"Côtes de Fer","Cotes de Fer","18.1833","-73.0000","Haiti","HT","HTI","Sud-Est","","33577","1332390901"
"Golungo Alto","Golungo Alto","-9.1333","14.7667","Angola","AO","AGO","Cuanza-Norte","","33834","1024406694"
"Bell Ville","Bell Ville","-32.6333","-62.6833","Argentina","AR","ARG","Córdoba","minor","33835","1032028928"
"Bhawānīpur Rājdhām","Bhawanipur Rajdham","25.6501","87.1396","India","IN","IND","Bihār","","32627","1356738539"
"Didouche Mourad","Didouche Mourad","36.4484","6.6339","Algeria","DZ","DZA","Constantine","","33266","1012151355"
"Martinez","Martinez","33.5209","-82.0985","United States","US","USA","Georgia","","33829","1840013747"
"South Riding","South Riding","38.9120","-77.5132","United States","US","USA","Virginia","","33828","1840024507"
"Geraardsbergen","Geraardsbergen","50.7667","3.8667","Belgium","BE","BEL","Flanders","","33403","1056572611"
"Valparaiso","Valparaiso","41.4783","-87.0506","United States","US","USA","Indiana","","33820","1840014674"
"Batobato","Batobato","6.8361","126.0889","Philippines","PH","PHL","Davao Oriental","","33664","1608375365"
"Churi","Churi","23.6549","85.0128","India","IN","IND","Jhārkhand","","33293","1356195229"
"Villafranca di Verona","Villafranca di Verona","45.3500","10.8500","Italy","IT","ITA","Veneto","","33232","1380407942"
"Reghin","Reghin","46.7758","24.7083","Romania","RO","ROU","Mureş","","33281","1642173105"
"Xiadian","Xiadian","39.9435","116.9120","China","CN","CHN","Hebei","","32686","1156209154"
"Léo","Leo","11.1000","-2.1000","Burkina Faso","BF","BFA","Centre-Ouest","minor","33717","1854225723"
"Mazarrón","Mazarron","37.5983","-1.3139","Spain","ES","ESP","Murcia","","33700","1724139198"
"Sertânia","Sertania","-8.0706","-37.2658","Brazil","BR","BRA","Pernambuco","","33787","1076986072"
"Olkusz","Olkusz","50.2813","19.5650","Poland","PL","POL","Małopolskie","minor","33462","1616458567"
"Dej","Dej","47.0872","23.8053","Romania","RO","ROU","Cluj","","33497","1642916857"
"Poço Redondo","Poco Redondo","-9.8058","-37.6850","Brazil","BR","BRA","Sergipe","","33757","1076471819"
"Bad Nauheim","Bad Nauheim","50.3667","8.7500","Germany","DE","DEU","Hesse","","32777","1276664999"
"Masiu","Masiu","7.8167","124.3167","Philippines","PH","PHL","Lanao del Sur","","33580","1608760371"
"‘Ajab Shīr","\`Ajab Shir","37.4831","45.8900","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","33606","1364433724"
"Parkland","Parkland","26.3219","-80.2533","United States","US","USA","Florida","","33772","1840015140"
"Villa Riva","Villa Riva","19.1667","-69.9000","Dominican Republic","DO","DOM","Cibao Nordeste","","33663","1214066811"
"Mella","Mella","20.3694","-75.9111","Cuba","CU","CUB","Santiago de Cuba","","33667","1192880173"
"Walla Walla","Walla Walla","46.0671","-118.3368","United States","US","USA","Washington","","33765","1840021174"
"Jishi","Jishi","35.8511","102.4788","China","CN","CHN","Qinghai","minor","33022","1156339632"
"Penticton","Penticton","49.4911","-119.5886","Canada","CA","CAN","British Columbia","","33761","1124613898"
"Jaguarari","Jaguarari","-10.2600","-40.1958","Brazil","BR","BRA","Bahia","","33746","1076470682"
"Leawood","Leawood","38.9075","-94.6258","United States","US","USA","Kansas","","33748","1840001638"
"Fatehpur Sīkri","Fatehpur Sikri","27.0911","77.6611","India","IN","IND","Uttar Pradesh","","32905","1356099769"
"Belen","Belen","36.4889","36.1944","Turkey","TR","TUR","Hatay","minor","33540","1792296962"
"Encrucijada","Encrucijada","22.6169","-79.8661","Cuba","CU","CUB","Villa Clara","minor","33641","1192495582"
"Chilecito","Chilecito","-29.1667","-67.5000","Argentina","AR","ARG","La Rioja","minor","33724","1032078195"
"Midsalip","Midsalip","8.0328","123.3147","Philippines","PH","PHL","Zamboanga del Sur","","33711","1608000929"
"Graham","Graham","47.0322","-122.2827","United States","US","USA","Washington","","33727","1840017365"
"Rahachow","Rahachow","53.1000","30.0500","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","33702","1112299593"
"Sapa Sapa","Sapa Sapa","5.0899","120.2729","Philippines","PH","PHL","Tawi-Tawi","","33580","1608223776"
"Rubengera","Rubengera","-2.0519","29.4144","Rwanda","RW","RWA","Western Province","","33019","1646153886"
"Sukhoy Log","Sukhoy Log","56.9167","62.0500","Russia","RU","RUS","Sverdlovskaya Oblast’","","33689","1643890809"
"Warwick","Warwick","52.2800","-1.5900","United Kingdom","GB","GBR","Warwickshire","","32719","1826383937"
"Comé","Come","6.4000","1.8833","Benin","BJ","BEN","Mono","","33507","1204211586"
"Puerto Rico","Puerto Rico","1.9142","-75.1450","Colombia","CO","COL","Caquetá","minor","33701","1170209030"
"Vyatskiye Polyany","Vyatskiye Polyany","56.2239","51.0633","Russia","RU","RUS","Kirovskaya Oblast’","","32562","1643642329"
"Ronda","Ronda","36.7372","-5.1647","Spain","ES","ESP","Andalusia","","33624","1724795472"
"Carmel","Carmel","41.3899","-73.7239","United States","US","USA","New York","","33710","1840057833"
"Tucumã","Tucuma","-6.7519","-51.1539","Brazil","BR","BRA","Pará","","33690","1076697803"
"Banate","Banate","11.0500","122.7833","Philippines","PH","PHL","Iloilo","","33376","1608443731"
"Getafe","Getafe","10.1500","124.1500","Philippines","PH","PHL","Bohol","","33422","1608770120"
"Jamikunta","Jamikunta","18.2864","79.4761","India","IN","IND","Andhra Pradesh","","32645","1356570935"
"Patrātu","Patratu","23.6700","85.2800","India","IN","IND","Jhārkhand","","32899","1356581953"
"San Antero","San Antero","9.3833","-75.7500","Colombia","CO","COL","Córdoba","minor","33662","1170032632"
"Schwedt (Oder)","Schwedt (Oder)","53.0500","14.2667","Germany","DE","DEU","Brandenburg","","33525","1276552729"
"Vechta","Vechta","52.7306","8.2886","Germany","DE","DEU","Lower Saxony","minor","33309","1276147280"
"Rexburg","Rexburg","43.8226","-111.7919","United States","US","USA","Idaho","","33684","1840020037"
"Ōno","Ono","35.9797","136.4875","Japan","JP","JPN","Fukui","","33640","1392027459"
"Macuro","Macuro","10.6500","-61.9333","Venezuela","VE","VEN","Sucre","","33621","1862222020"
"Menlo Park","Menlo Park","37.4685","-122.1673","United States","US","USA","California","","33677","1840020305"
"Sicuani","Sicuani","-14.2720","-71.2290","Peru","PE","PER","Cusco","","33575","1604929096"
"Lianmuqin Kancun","Lianmuqin Kancun","42.8833","89.9333","China","CN","CHN","Xinjiang","","33635","1156426270"
"Korsakov","Korsakov","46.6333","142.7667","Russia","RU","RUS","Sakhalinskaya Oblast’","","33645","1643017767"
"Ibaté","Ibate","-21.9550","-47.9969","Brazil","BR","BRA","São Paulo","","33535","1076395966"
"Barra do Bugres","Barra do Bugres","-15.0728","-57.1808","Brazil","BR","BRA","Mato Grosso","","33644","1076717726"
"São Domingos do Maranhão","Sao Domingos do Maranhao","-5.5758","-44.3850","Brazil","BR","BRA","Maranhão","","33607","1076192320"
"Corinto","Corinto","3.1739","-76.2594","Colombia","CO","COL","Cauca","minor","33489","1170948905"
"Mildura","Mildura","-34.1889","142.1583","Australia","AU","AUS","Victoria","","32738","1036624348"
"Zhanjia","Zhanjia","34.7564","109.3846","China","CN","CHN","Shaanxi","","32667","1156040005"
"Asse","Asse","50.9000","4.2000","Belgium","BE","BEL","Flanders","","32958","1056775940"
"Sakaiminato","Sakaiminato","35.5333","133.2333","Japan","JP","JPN","Shimane","","32503","1392354466"
"Chascomús","Chascomus","-35.5750","-58.0089","Argentina","AR","ARG","Buenos Aires","minor","33607","1032352363"
"Darsi","Darsi","15.7667","79.6833","India","IN","IND","Andhra Pradesh","","33418","1356113162"
"Manatí","Manati","21.3144","-76.9378","Cuba","CU","CUB","Las Tunas","minor","33573","1192167066"
"Aleshtar","Aleshtar","33.8633","48.2619","Iran","IR","IRN","Lorestān","minor","33558","1364420866"
"Batouri","Batouri","4.4333","14.3667","Cameroon","CM","CMR","Est","","33594","1120380999"
"Rheinfelden (Baden)","Rheinfelden (Baden)","47.5611","7.7917","Germany","DE","DEU","Baden-Württemberg","","33074","1276547516"
"Fomento","Fomento","22.1053","-79.7203","Cuba","CU","CUB","Sancti Spíritus","minor","33528","1192397321"
"São Bento","Sao Bento","-6.4858","-37.4508","Brazil","BR","BRA","Paraíba","","33464","1076842336"
"Ayvacık","Ayvacik","39.6011","26.4047","Turkey","TR","TUR","Çanakkale","minor","33568","1792135489"
"Shangcaiyuan","Shangcaiyuan","24.6817","102.6918","China","CN","CHN","Yunnan","","33287","1156307869"
"Cottonwood Heights","Cottonwood Heights","40.6137","-111.8144","United States","US","USA","Utah","","33597","1840018746"
"Jhanjhārpur","Jhanjharpur","26.2647","86.2799","India","IN","IND","Bihār","","31827","1356323325"
"Makhdumpur","Makhdumpur","25.0720","84.9730","India","IN","IND","Bihār","","31994","1356751511"
"Manlius","Manlius","43.0490","-75.9792","United States","US","USA","New York","","33591","1840004323"
"Iriba","Iriba","15.1167","22.2500","Chad","TD","TCD","Wadi-Fira","","33572","1148106836"
"Ōsawa","Osawa","33.2000","130.3833","Japan","JP","JPN","Fukuoka","","32609","1392646983"
"An’gang","An'gang","35.9900","129.2270","South Korea","KR","KOR","Gyeongbuk","","33333","1410507369"
"Castro","Castro","-42.4667","-73.8000","Chile","CL","CHL","Los Lagos","minor","33417","1152445107"
"Columbio","Columbio","6.7000","124.9333","Philippines","PH","PHL","Sultan Kudarat","","33527","1608232119"
"Iperó","Ipero","-23.3503","-47.6886","Brazil","BR","BRA","São Paulo","","33367","1076747973"
"Shaliuhe","Shaliuhe","39.8728","117.9390","China","CN","CHN","Hebei","","32778","1156835618"
"Caçapava do Sul","Cacapava do Sul","-30.5119","-53.4908","Brazil","BR","BRA","Rio Grande do Sul","","33548","1076550820"
"Kearney","Kearney","40.7011","-99.0833","United States","US","USA","Nebraska","","33558","1840000957"
"Mercedes","Mercedes","-29.2000","-58.0833","Argentina","AR","ARG","Corrientes","minor","33551","1032972413"
"Kavundappādi","Kavundappadi","11.4248","77.5579","India","IN","IND","Tamil Nādu","","33227","1356772268"
"Port Moody","Port Moody","49.2831","-122.8317","Canada","CA","CAN","British Columbia","","33551","1124252668"
"Pikesville","Pikesville","39.3893","-76.7020","United States","US","USA","Maryland","","33549","1840005687"
"Orodara","Orodara","10.9800","-4.9100","Burkina Faso","BF","BFA","Hauts-Bassins","minor","33422","1854111637"
"Niangoloko","Niangoloko","10.2833","-4.9167","Burkina Faso","BF","BFA","Cascades","","33500","1854857887"
"Taishi","Taishi","34.8333","134.5724","Japan","JP","JPN","Hyōgo","","33321","1392003237"
"Slobodskoy","Slobodskoy","58.7208","50.1850","Russia","RU","RUS","Kirovskaya Oblast’","","32793","1643784759"
"Rodas","Rodas","22.3428","-80.5553","Cuba","CU","CUB","Cienfuegos","minor","33477","1192506240"
"Pôrto União","Porto Uniao","-26.2378","-51.0778","Brazil","BR","BRA","Santa Catarina","","33493","1076057988"
"San Vicente","San Vicente","10.5281","119.2542","Philippines","PH","PHL","Palawan","","33507","1608866365"
"Ar Raḩmānīyah","Ar Rahmaniyah","31.1062","30.6337","Egypt","EG","EGY","Al Buḩayrah","","32093","1818669069"
"Sahuarita","Sahuarita","31.9323","-110.9654","United States","US","USA","Arizona","","33524","1840022102"
"Crown Point","Crown Point","41.4143","-87.3457","United States","US","USA","Indiana","","33518","1840007091"
"Calape","Calape","9.8833","123.8833","Philippines","PH","PHL","Bohol","","33079","1608336876"
"Ārumuganeri","Arumuganeri","8.5717","78.0922","India","IN","IND","Tamil Nādu","","32809","1356744238"
"Nānjikkottai","Nanjikkottai","10.7453","79.1289","India","IN","IND","Tamil Nādu","","32689","1356174083"
"Timberwood Park","Timberwood Park","29.6995","-98.4838","United States","US","USA","Texas","","33506","1840019651"
"Kos","Kos","36.8500","27.2333","Greece","GR","GRC","Nótio Aigaío","minor","33387","1300683758"
"Pruszcz Gdański","Pruszcz Gdanski","54.2667","18.6333","Poland","PL","POL","Pomorskie","minor","31582","1616008731"
"Bôca do Acre","Boca do Acre","-8.7519","-67.3978","Brazil","BR","BRA","Amazonas","","33498","1076558816"
"City Bell","City Bell","-34.8500","-58.0500","Argentina","AR","ARG","Buenos Aires","","32646","1032568432"
"Madikeri","Madikeri","12.4209","75.7397","India","IN","IND","Karnātaka","","33381","1356609242"
"Boxtel","Boxtel","51.5833","5.3333","Netherlands","NL","NLD","Noord-Brabant","minor","32973","1528314024"
"Hlukhiv","Hlukhiv","51.6765","33.9078","Ukraine","UA","UKR","Sumska Oblast","","33478","1804181345"
"Date","Date","42.4719","140.8647","Japan","JP","JPN","Hokkaidō","","33404","1392999978"
"Al Mindak","Al Mindak","20.1833","41.2333","Saudi Arabia","SA","SAU","Al Bāḩah","","33319","1682913363"
"Shalingzicun","Shalingzicun","40.6807","114.9205","China","CN","CHN","Hebei","","32960","1156575136"
"Chimbarongo","Chimbarongo","-34.7089","-71.0431","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","33446","1152212161"
"Vác","Vac","47.7752","19.1310","Hungary","HU","HUN","Pest","minor","32927","1348810997"
"Xinbu","Xinbu","24.8478","121.0956","Taiwan","TW","TWN","Hsinchu","","33002","1158924907"
"Nautan Dube","Nautan Dube","26.7118","84.5030","India","IN","IND","Bihār","","31650","1356660130"
"Castro-Urdiales","Castro-Urdiales","43.3844","-3.2150","Spain","ES","ESP","Cantabria","","33109","1724390704"
"Warminster","Warminster","40.2043","-75.0915","United States","US","USA","Pennsylvania","","33442","1840035370"
"Nokia","Nokia","61.4767","23.5053","Finland","FI","FIN","Pirkanmaa","minor","33322","1246560498"
"Gölbaşı","Golbasi","37.7839","37.6444","Turkey","TR","TUR","Adıyaman","minor","33373","1792595244"
"Shōbara","Shobara","34.8544","133.0192","Japan","JP","JPN","Hiroshima","","33409","1392341787"
"Aranda de Duero","Aranda de Duero","41.6833","-3.6833","Spain","ES","ESP","Castille-Leon","","33172","1724218741"
"Udaipur","Udaipur","23.5389","91.4917","India","IN","IND","Tripura","","32758","1356603574"
"Baturité","Baturite","-4.3289","-38.8850","Brazil","BR","BRA","Ceará","","33321","1076144455"
"Arteijo","Arteijo","43.3044","-8.5114","Spain","ES","ESP","Galicia","","33076","1724272253"
"San Sebastián de Mariquita","San Sebastian de Mariquita","5.2500","-74.9167","Colombia","CO","COL","Tolima","minor","33340","1170963203"
"Abbiategrasso","Abbiategrasso","45.4000","8.9167","Italy","IT","ITA","Lombardy","","32737","1380022331"
"Loughton","Loughton","51.6494","0.0735","United Kingdom","GB","GBR","Essex","","33353","1826436728"
"Epe","Epe","52.3500","5.9833","Netherlands","NL","NLD","Gelderland","minor","33198","1528488090"
"Radnor","Radnor","40.0287","-75.3675","United States","US","USA","Pennsylvania","","33408","1840152846"
"Kolín","Kolin","50.0281","15.2006","Czechia","CZ","CZE","Středočeský Kraj","","32490","1203413479"
"La Virginia","La Virginia","4.9167","-75.8333","Colombia","CO","COL","Risaralda","minor","32330","1170879762"
"Hayama","Hayama","35.2725","139.5861","Japan","JP","JPN","Kanagawa","","31546","1392003406"
"Vintar","Vintar","18.2306","120.6489","Philippines","PH","PHL","Ilocos Norte","","33339","1608954444"
"Perşembe","Persembe","41.0656","37.7714","Turkey","TR","TUR","Ordu","minor","33253","1792543078"
"Umaria","Umaria","23.5247","80.8372","India","IN","IND","Madhya Pradesh","","33114","1356133627"
"Dupax Del Norte","Dupax Del Norte","16.3075","121.1019","Philippines","PH","PHL","Nueva Vizcaya","","33295","1608433556"
"Brejo","Brejo","-3.6839","-42.7500","Brazil","BR","BRA","Maranhão","","33359","1076010144"
"Southport","Southport","-27.9667","153.4000","Australia","AU","AUS","Queensland","","31908","1036417513"
"Conceição de Jacuípe","Conceicao de Jacuipe","-12.3269","-38.7650","Brazil","BR","BRA","Bahia","","33354","1076309231"
"Pandami","Pandami","5.5333","120.7500","Philippines","PH","PHL","Sulu","","33177","1608493332"
"Tayshet","Tayshet","55.9500","98.0167","Russia","RU","RUS","Irkutskaya Oblast’","","33364","1643635211"
"Tavda","Tavda","58.0500","65.2667","Russia","RU","RUS","Sverdlovskaya Oblast’","","33356","1643627997"
"Milot","Milot","19.6047","-72.2186","Haiti","HT","HTI","Nord","","31992","1332342808"
"Huangzhuang","Huangzhuang","39.9905","117.1037","China","CN","CHN","Hebei","","32752","1156352690"
"Bethel Park","Bethel Park","40.3238","-80.0364","United States","US","USA","Pennsylvania","","33356","1840001206"
"Amparafaravola","Amparafaravola","-17.5833","48.2167","Madagascar","MG","MDG","Toamasina","","33299","1450596201"
"Pantelimon","Pantelimon","44.4500","26.2000","Romania","RO","ROU","Ilfov","","32873","1642982115"
"Gīmbī","Gimbi","9.1667","35.8333","Ethiopia","ET","ETH","Oromīya","","31809","1231550284"
"Salina","Salina","43.1023","-76.1768","United States","US","USA","New York","","33343","1840087878"
"Glossop","Glossop","53.4430","-1.9490","United Kingdom","GB","GBR","Derbyshire","","33340","1826787990"
"Police","Police","53.5333","14.5667","Poland","PL","POL","Zachodniopomorskie","minor","33152","1616953743"
"Sherpur","Sherpur","25.6539","84.9793","India","IN","IND","Bihār","","31833","1356337645"
"Kusapín","Kusapin","9.1800","-81.8900","Panama","PA","PAN","Ngäbe-Buglé","minor","33121","1591073084"
"Kareli","Kareli","22.9153","79.0638","India","IN","IND","Madhya Pradesh","","33056","1356823504"
"Kārkala","Karkala","13.2000","74.9800","India","IN","IND","Karnātaka","","33153","1356095325"
"Hāngal","Hangal","14.7646","75.1246","India","IN","IND","Karnātaka","","33011","1356154406"
"Tynda","Tynda","55.1667","124.7167","Russia","RU","RUS","Amurskaya Oblast’","","33061","1643364583"
"Kaarina","Kaarina","60.4000","22.3667","Finland","FI","FIN","Varsinais-Suomi","minor","33099","1246381646"
"Mohnyin","Mohnyin","24.7833","96.3667","Myanmar","MM","MMR","Kachin State","","33290","1104701882"
"São Luís Gonzaga","Sao Luis Gonzaga","-28.4078","-54.9608","Brazil","BR","BRA","Rio Grande do Sul","","33293","1076320357"
"Los Gatos","Los Gatos","37.2304","-121.9562","United States","US","USA","California","","33309","1840021575"
"Obukhiv","Obukhiv","50.1109","30.6269","Ukraine","UA","UKR","Kyivska Oblast","minor","33204","1804079919"
"Brooklyn Center","Brooklyn Center","45.0681","-93.3162","United States","US","USA","Minnesota","","33298","1840004022"
"Watari","Watari","38.0442","140.8676","Japan","JP","JPN","Miyagi","","32846","1392003535"
"Canavieiras","Canavieiras","-15.6750","-38.9469","Brazil","BR","BRA","Bahia","","33268","1076258532"
"Anserma","Anserma","5.2381","-75.7840","Colombia","CO","COL","Caldas","minor","33146","1170000614"
"Oldenzaal","Oldenzaal","52.3167","6.9333","Netherlands","NL","NLD","Overijssel","minor","31840","1528961744"
"Almeirim","Almeirim","-1.5228","-52.5819","Brazil","BR","BRA","Pará","","33282","1076655786"
"Alamnagar","Alamnagar","25.5610","86.9014","India","IN","IND","Bihār","","31818","1356100470"
"Muconda","Muconda","-10.6000","21.3167","Angola","AO","AGO","Lunda-Sul","","33264","1024646532"
"Wisbech","Wisbech","52.6640","0.1600","United Kingdom","GB","GBR","Cambridgeshire","","31573","1826161684"
"Rencun","Rencun","22.6585","112.3674","China","CN","CHN","Guangdong","","32942","1156561656"
"Temascalapa","Temascalapa","19.8000","-98.9000","Mexico","MX","MEX","Hidalgo","minor","33063","1484792221"
"Palmira","Palmira","22.2444","-80.3944","Cuba","CU","CUB","Cienfuegos","minor","33153","1192626448"
"Waikabubak","Waikabubak","-9.6358","119.4131","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","33064","1360399705"
"Pivijay","Pivijay","10.4667","-74.6167","Colombia","CO","COL","Magdalena","minor","33225","1170978231"
"Shatura","Shatura","55.5667","39.5500","Russia","RU","RUS","Moskovskaya Oblast’","","33222","1643976065"
"Puerto Cumarebo","Puerto Cumarebo","11.4861","-69.3503","Venezuela","VE","VEN","Falcón","minor","33166","1862046151"
"Camacan","Camacan","-15.4189","-39.4958","Brazil","BR","BRA","Bahia","","33197","1076604615"
"Zgorzelec","Zgorzelec","51.1500","15.0083","Poland","PL","POL","Dolnośląskie","minor","31359","1616207310"
"San Roque","San Roque","36.2097","-5.3844","Spain","ES","ESP","Andalusia","","33018","1724332438"
"Djenné","Djenne","13.9000","-4.5500","Mali","ML","MLI","Mopti","minor","32944","1466017388"
"Bragado","Bragado","-35.1167","-60.5000","Argentina","AR","ARG","Buenos Aires","minor","33222","1032509795"
"Coxim","Coxim","-18.5069","-54.7600","Brazil","BR","BRA","Mato Grosso do Sul","","33231","1076196665"
"Epsom","Epsom","51.3360","-0.2670","United Kingdom","GB","GBR","Surrey","","31489","1826078283"
"Petersburg","Petersburg","37.2043","-77.3913","United States","US","USA","Virginia","","33229","1840003861"
"Ross","Ross","40.5256","-80.0243","United States","US","USA","Pennsylvania","","33229","1840149299"
"Dipaculao","Dipaculao","15.9833","121.6333","Philippines","PH","PHL","Aurora","","33131","1608371572"
"Andırın","Andirin","37.5764","36.3506","Turkey","TR","TUR","Kahramanmaraş","minor","33193","1792314196"
"Castelfranco Emilia","Castelfranco Emilia","44.5967","11.0528","Italy","IT","ITA","Emilia-Romagna","","32894","1380892576"
"Goldsboro","Goldsboro","35.3778","-77.9717","United States","US","USA","North Carolina","","33214","1840013432"
"Fucheng","Fucheng","35.3678","103.7074","China","CN","CHN","Gansu","minor","32463","1156178996"
"Dumalinao","Dumalinao","7.8167","123.3667","Philippines","PH","PHL","Zamboanga del Sur","","32928","1608596790"
"Minamikyūshū","Minamikyushu","31.3778","130.4419","Japan","JP","JPN","Kagoshima","","33115","1392003265"
"Khvāf","Khvaf","34.5764","60.1408","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","33189","1364404057"
"De Aar","De Aar","-30.6500","24.0167","South Africa","ZA","ZAF","Northern Cape","","32318","1710551900"
"San Lazzaro di Savena","San Lazzaro di Savena","44.4716","11.4049","Italy","IT","ITA","Emilia-Romagna","","32473","1380383689"
"Příbram","Pribram","49.6883","14.0092","Czechia","CZ","CZE","Středočeský Kraj","","32248","1203262749"
"Aravan","Aravan","40.5150","72.4992","Kyrgyzstan","KG","KGZ","Osh","minor","32917","1417417400"
"Correntina","Correntina","-13.3428","-44.6369","Brazil","BR","BRA","Bahia","","33183","1076400445"
"Lucera","Lucera","41.5000","15.3333","Italy","IT","ITA","Puglia","","33085","1380392352"
"Lawrence","Lawrence","40.2954","-74.7205","United States","US","USA","New Jersey","","33178","1840056345"
"Forchheim","Forchheim","49.7197","11.0581","Germany","DE","DEU","Bavaria","minor","32433","1276030665"
"Asakuchi","Asakuchi","34.5247","133.5875","Japan","JP","JPN","Okayama","","32669","1392999990"
"Redmond","Redmond","44.2612","-121.1810","United States","US","USA","Oregon","","33160","1840020003"
"Kakira","Kakira","0.5036","33.2825","Uganda","UG","UGA","Jinja","","32819","1800697790"
"Água Preta","Agua Preta","-8.7000","-35.3500","Brazil","BR","BRA","Pernambuco","","33095","1076495726"
"Shimotoba","Shimotoba","34.8838","135.6630","Japan","JP","JPN","Kyōto","","31292","1392825221"
"Upper Merion","Upper Merion","40.0902","-75.3791","United States","US","USA","Pennsylvania","","33153","1840152386"
"Lower Makefield","Lower Makefield","40.2309","-74.8550","United States","US","USA","Pennsylvania","","33141","1840151114"
"Vohitromby","Vohitromby","-23.3000","47.5333","Madagascar","MG","MDG","Fianarantsoa","","33000","1450355473"
"Kamaishi","Kamaishi","39.2758","141.8857","Japan","JP","JPN","Iwate","","33061","1392589485"
"Fuquay-Varina","Fuquay-Varina","35.5953","-78.7778","United States","US","USA","North Carolina","","33135","1840016197"
"Laojiezi","Laojiezi","26.8600","103.1306","China","CN","CHN","Yunnan","minor","32998","1156352436"
"Palmeira das Missões","Palmeira das Missoes","-27.8989","-53.3139","Brazil","BR","BRA","Rio Grande do Sul","","33131","1076243689"
"Lampertheim","Lampertheim","49.6000","8.4667","Germany","DE","DEU","Hesse","","32682","1276406891"
"Alabaster","Alabaster","33.2198","-86.8225","United States","US","USA","Alabama","","33133","1840013756"
"Kovūr","Kovur","14.4833","79.9833","India","IN","IND","Andhra Pradesh","","32082","1356586750"
"Lumbang","Lumbang","14.2970","121.4590","Philippines","PH","PHL","Laguna","","32330","1608829120"
"Rio das Pedras","Rio das Pedras","-22.8428","-47.6058","Brazil","BR","BRA","São Paulo","","32982","1076199521"
"Bungoōno","Bungoono","32.9833","131.5833","Japan","JP","JPN","Ōita","","33066","1392003478"
"Massafra","Massafra","40.5833","17.1167","Italy","IT","ITA","Puglia","","32861","1380268099"
"Sānchor","Sanchor","24.7517","71.7762","India","IN","IND","Rājasthān","","32875","1356008293"
"Knokke-Heist","Knokke-Heist","51.3414","3.2869","Belgium","BE","BEL","Flanders","","33097","1056022450"
"Umarkot","Umarkot","19.6653","82.2063","India","IN","IND","Chhattīsgarh","","32813","1356562244"
"Mashiki","Mashiki","32.8006","130.8170","Japan","JP","JPN","Kumamoto","","32587","1392833070"
"Ambodimanga II","Ambodimanga II","-17.2667","49.1667","Madagascar","MG","MDG","Toamasina","","33000","1450874988"
"San Luis","San Luis","22.2828","-83.7681","Cuba","CU","CUB","Pinar del Río","minor","33039","1192769650"
"Wakabadai","Wakabadai","45.4156","141.6731","Japan","JP","JPN","Hokkaidō","","33036","1392002486"
"Puebloviejo","Puebloviejo","10.9972","-74.2875","Colombia","CO","COL","Magdalena","minor","33030","1170245441"
"Mushie","Mushie","-3.0167","16.9167","Congo (Kinshasa)","CD","COD","Mai-Ndombe","","33062","1180240809"
"Kadınhanı","Kadinhani","38.2397","32.2114","Turkey","TR","TUR","Konya","minor","33036","1792310502"
"Purāini","Puraini","25.1426","86.9797","India","IN","IND","Bihār","","31967","1356712534"
"Forbe Oroya","Forbe Oroya","-11.5220","-75.9100","Peru","PE","PER","Junín","","33000","1604666304"
"Didy","Didy","-18.1167","48.5333","Madagascar","MG","MDG","Toamasina","","33039","1450048536"
"Franklin","Franklin","42.0862","-71.4113","United States","US","USA","Massachusetts","","33057","1840031178"
"Rusera","Rusera","25.7600","86.0300","India","IN","IND","Bihār","","31155","1356419529"
"Gillette","Gillette","44.2752","-105.4982","United States","US","USA","Wyoming","","33047","1840019978"
"Sarıkaya","Sarikaya","39.4936","35.3769","Turkey","TR","TUR","Yozgat","minor","33010","1792208662"
"Biritiba-Mirim","Biritiba-Mirim","-23.5728","-46.0389","Brazil","BR","BRA","São Paulo","","32936","1076510731"
"Yamanashi","Yamanashi","35.6934","138.6869","Japan","JP","JPN","Yamanashi","","32925","1392003165"
"Maragogi","Maragogi","-9.0122","-35.2225","Brazil","BR","BRA","Alagoas","","32940","1076984582"
"Kennesaw","Kennesaw","34.0260","-84.6177","United States","US","USA","Georgia","","33036","1840014756"
"Dolores","Dolores","14.0157","121.4011","Philippines","PH","PHL","Quezon","","32514","1608414696"
"Las Mercedes","Las Mercedes","8.5104","-66.5030","Venezuela","VE","VEN","Guárico","minor","33025","1862558629"
"Liptovský Mikuláš","Liptovsky Mikulas","49.0842","19.6022","Slovakia","SK","SVK","Žilina","minor","32593","1703155005"
"Itzehoe","Itzehoe","53.9250","9.5164","Germany","DE","DEU","Schleswig-Holstein","minor","31879","1276277285"
"Carapeguá","Carapegua","-25.7690","-57.2470","Paraguay","PY","PRY","Paraguarí","","32939","1600844405"
"Osvaldo Cruz","Osvaldo Cruz","-21.7967","-50.8786","Brazil","BR","BRA","São Paulo","","33000","1076438430"
"Bardejov","Bardejov","49.2933","21.2761","Slovakia","SK","SVK","Prešov","minor","32587","1703663141"
"Gumdag","Gumdag","39.2061","54.5906","Turkmenistan","TM","TKM","Balkan","","33000","1795425588"
"Gyzylgaya","Gyzylgaya","40.6219","55.4656","Turkmenistan","TM","TKM","Balkan","","33000","1795017897"
"Zumbo","Zumbo","-15.6167","30.4500","Mozambique","MZ","MOZ","Tete","","33000","1508473445"
"Hinda","Hinda","-4.6133","12.0408","Congo (Brazzaville)","CG","COG","Kouilou","","32995","1178008443"
"Colombia","Colombia","20.9906","-77.4158","Cuba","CU","CUB","Las Tunas","minor","32942","1192555025"
"Fallbrook","Fallbrook","33.3693","-117.2259","United States","US","USA","California","","32999","1840018019"
"Jülich","Julich","50.9222","6.3583","Germany","DE","DEU","North Rhine-Westphalia","","32635","1276891190"
"Maḩallat Damanah","Mahallat Damanah","31.0754","31.4959","Egypt","EG","EGY","Ad Daqahlīyah","","31293","1818420317"
"Pueblo West","Pueblo West","38.3465","-104.7246","United States","US","USA","Colorado","","32991","1840018860"
"Leamington","Leamington","42.0667","-82.5833","Canada","CA","CAN","Ontario","","32991","1124258797"
"Ayorou","Ayorou","14.7350","0.9200","Niger","NE","NER","Tillabéri","","32958","1562787330"
"Teykovo","Teykovo","56.8547","40.5411","Russia","RU","RUS","Ivanovskaya Oblast’","","31801","1643732572"
"Charthāwal","Charthawal","29.5500","77.5833","India","IN","IND","Uttar Pradesh","","31493","1356743179"
"Esperança","Esperanca","-7.0333","-35.8500","Brazil","BR","BRA","Paraíba","","32785","1076765835"
"Chernushka","Chernushka","56.5167","56.1000","Russia","RU","RUS","Permskiy Kray","","32952","1643534428"
"Sayram","Sayram","42.3000","69.7667","Kazakhstan","KZ","KAZ","","","32757","1398493242"
"Pullman","Pullman","46.7336","-117.1680","United States","US","USA","Washington","","32968","1840019864"
"Massango","Massango","-11.3167","14.9333","Angola","AO","AGO","Cuanza-Sul","","32918","1024770645"
"La Uruca","La Uruca","9.9575","-84.1327","Costa Rica","CR","CRI","San José","","31728","1188598269"
"Örnsköldsvik","Ornskoldsvik","63.2908","18.7156","Sweden","SE","SWE","Västernorrland","minor","32953","1752250773"
"Bembe","Bembe","-7.1000","14.4500","Angola","AO","AGO","Uíge","","32955","1024112632"
"Dara","Dara","15.3500","-15.6000","Senegal","SN","SEN","Louga","","32941","1686515842"
"Dellys","Dellys","36.9133","3.9141","Algeria","DZ","DZA","Boumerdes","","32954","1012717910"
"Bernburg","Bernburg","51.8000","11.7333","Germany","DE","DEU","Saxony-Anhalt","minor","32674","1276331294"
"Badoc","Badoc","17.9267","120.4754","Philippines","PH","PHL","Ilocos Norte","","32530","1608039575"
"Baraguá","Baragua","21.6819","-78.6244","Cuba","CU","CUB","Ciego de Ávila","","32909","1192061113"
"Dehlorān","Dehloran","32.6942","47.2678","Iran","IR","IRN","Īlām","minor","32941","1364480030"
"Buloqboshi","Buloqboshi","40.6222","72.5028","Uzbekistan","UZ","UZB","Andijon","minor","32200","1860281423"
"Gandu","Gandu","-13.7439","-39.4869","Brazil","BR","BRA","Bahia","","32809","1076167274"
"Ketti","Ketti","11.4000","76.7000","India","IN","IND","Tamil Nādu","","32384","1356137841"
"Aalsmeer","Aalsmeer","52.2667","4.7500","Netherlands","NL","NLD","Noord-Holland","minor","31991","1528899853"
"Sō","So","31.6536","131.0194","Japan","JP","JPN","Kagoshima","","32849","1392003153"
"Ōiso","Oiso","35.3069","139.3113","Japan","JP","JPN","Kanagawa","","31121","1392342585"
"Ţafas","Tafas","32.7356","36.0669","Syria","SY","SYR","Dar‘ā","","32236","1760012771"
"Wernigerode","Wernigerode","51.8350","10.7853","Germany","DE","DEU","Saxony-Anhalt","","32733","1276322228"
"Ciudad del Plata","Ciudad del Plata","-34.7667","-56.3806","Uruguay","UY","URY","San José","","31145","1858720449"
"Trëkhgornyy","Trekhgornyy","54.8000","58.4500","Russia","RU","RUS","Chelyabinskaya Oblast’","","32715","1643017912"
"La Mesa","La Mesa","4.6303","-74.4625","Colombia","CO","COL","Cundinamarca","minor","32694","1170000629"
"Tawsalun","Tawsalun","21.4060","94.1360","Myanmar","MM","MMR","Magway","","32865","1104589040"
"St. Charles","St. Charles","41.9193","-88.3109","United States","US","USA","Illinois","","32908","1840009190"
"Erraguntla","Erraguntla","14.6333","78.5333","India","IN","IND","Andhra Pradesh","","32574","1356180347"
"Araban","Araban","37.4247","37.6928","Turkey","TR","TUR","Gaziantep","minor","32846","1792472790"
"Curralinho","Curralinho","-1.8139","-49.7950","Brazil","BR","BRA","Pará","","32881","1076735826"
"Kifrī","Kifri","34.6833","44.9667","Iraq","IQ","IRQ","Diyālá","minor","32870","1368292327"
"Unchagao","Unchagao","16.6988","74.2749","India","IN","IND","Mahārāshtra","","31238","1356045868"
"Oisterwijk","Oisterwijk","51.5833","5.2000","Netherlands","NL","NLD","Noord-Brabant","minor","32373","1528135071"
"Arbaoua","Arbaoua","34.9000","-5.9167","Morocco","MA","MAR","Rabat-Salé-Kénitra","","32690","1504079088"
"Manises","Manises","39.4833","-0.4500","Spain","ES","ESP","Valencia","","31287","1724921476"
"Şāfītā","Safita","34.8167","36.1167","Syria","SY","SYR","Ţarţūs","minor","32213","1760592493"
"San Felipe","San Felipe","21.4833","-101.2167","Mexico","MX","MEX","Guanajuato","minor","32831","1484279509"
"Puerto Escondido","Puerto Escondido","8.9500","-76.2500","Colombia","CO","COL","Córdoba","minor","32745","1170484515"
"Kuji","Kuji","40.1905","141.7757","Japan","JP","JPN","Iwate","","32813","1392882660"
"Spring Valley","Spring Valley","32.7317","-116.9766","United States","US","USA","California","","32860","1840019343"
"Satānā","Satana","20.5797","74.2160","India","IN","IND","Mahārāshtra","","32551","1356628559"
"Achim","Achim","53.0653","9.0342","Germany","DE","DEU","Lower Saxony","","32379","1276242263"
"Quţūr","Qutur","30.9735","30.9563","Egypt","EG","EGY","Al Gharbīyah","","31366","1818962685"
"Campos Novos","Campos Novos","-27.4019","-51.2250","Brazil","BR","BRA","Santa Catarina","","32824","1076882483"
"Matalom","Matalom","10.2833","124.8000","Philippines","PH","PHL","Leyte","","32586","1608519626"
"Baraawe","Baraawe","1.1133","44.0303","Somalia","SO","SOM","Shabeellaha Hoose","","32800","1706566007"
"American Fork","American Fork","40.3783","-111.7953","United States","US","USA","Utah","","32822","1840018756"
"São João da Barra","Sao Joao da Barra","-21.6400","-41.0508","Brazil","BR","BRA","Rio de Janeiro","","32747","1076887451"
"Presidente Figueiredo","Presidente Figueiredo","-2.0172","-60.0250","Brazil","BR","BRA","Amazonas","","32812","1076857891"
"Buco Zau","Buco Zau","-4.7950","12.5467","Angola","AO","AGO","Cabinda","","32792","1024523085"
"Monteiro","Monteiro","-7.8889","-37.1200","Brazil","BR","BRA","Paraíba","","32774","1076669282"
"São Miguel Arcanjo","Sao Miguel Arcanjo","-23.8778","-47.9969","Brazil","BR","BRA","São Paulo","","32769","1076595585"
"Ylöjärvi","Ylojarvi","61.5500","23.5833","Finland","FI","FIN","Pirkanmaa","minor","32738","1246651024"
"Payshamba Shahri","Payshamba Shahri","40.0078","66.2372","Uzbekistan","UZ","UZB","Samarqand","minor","32568","1860071345"
"Épinal","Epinal","48.1744","6.4512","France","FR","FRA","Grand Est","minor","32256","1250137888"
"Yufu","Yufu","33.1833","131.4333","Japan","JP","JPN","Ōita","","32698","1392003544"
"Turaiyūr","Turaiyur","11.1686","78.6211","India","IN","IND","Tamil Nādu","","32439","1356208904"
"Bocas de Satinga","Bocas de Satinga","2.3469","-78.3256","Colombia","CO","COL","Nariño","minor","32759","1170318285"
"Mānwat","Manwat","19.3000","76.5000","India","IN","IND","Mahārāshtra","","32488","1356217655"
"Kaizu","Kaizu","35.2206","136.6365","Japan","JP","JPN","Gifu","","32493","1392269681"
"Ansongo","Ansongo","15.6650","0.5028","Mali","ML","MLI","Gao","minor","32709","1466948813"
"Ingenio","Ingenio","27.9214","-15.4324","Spain","ES","ESP","Canary Islands","","31932","1724555097"
"Chancay","Chancay","-11.5653","-77.2714","Peru","PE","PER","Lima","","32312","1604882367"
"Elk Grove Village","Elk Grove Village","42.0064","-87.9921","United States","US","USA","Illinois","","32763","1840011271"
"Tārānagar","Taranagar","28.6689","75.0406","India","IN","IND","Rājasthān","","32640","1356352395"
"Mandaguari","Mandaguari","-23.5478","-51.6708","Brazil","BR","BRA","Paraná","","32658","1076000964"
"Rushden","Rushden","52.2880","-0.6010","United Kingdom","GB","GBR","Northamptonshire","","31690","1826167520"
"Motosu","Motosu","35.4830","136.6786","Japan","JP","JPN","Gifu","","32664","1392598380"
"Pombal","Pombal","-6.7700","-37.8019","Brazil","BR","BRA","Paraíba","","32712","1076681392"
"Altenburg","Altenburg","50.9850","12.4333","Germany","DE","DEU","Thuringia","minor","32074","1276224301"
"Bilzen","Bilzen","50.8667","5.5167","Belgium","BE","BEL","Flanders","","32318","1056431359"
"Silvia","Silvia","2.6167","-76.3833","Colombia","CO","COL","Cauca","minor","32692","1170405315"
"Baiyan","Baiyan","26.3584","106.2347","China","CN","CHN","Guizhou","","32473","1156177660"
"Nurlat","Nurlat","54.4333","50.8000","Russia","RU","RUS","Tatarstan","","32703","1643326238"
"Clarence","Clarence","43.0196","-78.6375","United States","US","USA","New York","","32727","1840057985"
"East Lake","East Lake","28.1206","-82.6868","United States","US","USA","Florida","","32714","1840028980"
"Istra","Istra","55.9167","36.8667","Russia","RU","RUS","Moskovskaya Oblast’","","32609","1643610937"
"Diplahan","Diplahan","7.6911","122.9853","Philippines","PH","PHL","Zamboanga Sibugay","","32585","1608903309"
"Redan","Redan","33.7394","-84.1644","United States","US","USA","Georgia","","32712","1840013706"
"Deurne","Deurne","51.4667","5.8000","Netherlands","NL","NLD","Noord-Brabant","minor","32437","1528815355"
"Camaiore","Camaiore","43.9333","10.3000","Italy","IT","ITA","Tuscany","","32328","1380212450"
"Espigão D’Oeste","Espigao D'Oeste","-11.5247","-61.0128","Brazil","BR","BRA","Rondônia","","32695","1076120982"
"Mayantoc","Mayantoc","15.6203","120.3775","Philippines","PH","PHL","Tarlac","","32597","1608123368"
"Concón","Concon","-32.9167","-71.5167","Chile","CL","CHL","Valparaíso","","32273","1152523682"
"Favara","Favara","37.3186","13.6631","Italy","IT","ITA","Sicilia","","32299","1380627505"
"Oğuzeli","Oguzeli","36.9650","37.5092","Turkey","TR","TUR","Gaziantep","minor","32653","1792753596"
"Dobryanka","Dobryanka","58.4667","56.4167","Russia","RU","RUS","Permskiy Kray","","32676","1643202553"
"Shiroishi","Shiroishi","38.0025","140.6197","Japan","JP","JPN","Miyagi","","32570","1392860584"
"Goleta","Goleta","34.4361","-119.8594","United States","US","USA","California","","32677","1840001783"
"El Difícil","El Dificil","9.8500","-74.2386","Colombia","CO","COL","Magdalena","minor","32637","1170207587"
"Akivīdu","Akividu","16.5823","81.3811","India","IN","IND","Andhra Pradesh","","32021","1356405611"
"Yorii","Yorii","36.1183","139.1930","Japan","JP","JPN","Saitama","","32172","1392564179"
"Plaisir","Plaisir","48.8183","1.9472","France","FR","FRA","Île-de-France","","31013","1250415771"
"Ptolemaḯda","Ptolemaida","40.5167","21.6833","Greece","GR","GRC","Dytikí Makedonía","minor","32127","1300255989"
"Santa Cruz del Norte","Santa Cruz del Norte","23.1556","-81.9267","Cuba","CU","CUB","Mayabeque","minor","32576","1192541265"
"Redcliff","Redcliff","-19.0333","29.7833","Zimbabwe","ZW","ZWE","Midlands","","32346","1716966753"
"Sadābād","Sadabad","27.4500","78.0500","India","IN","IND","Uttar Pradesh","","31737","1356288209"
"Mateur","Mateur","37.0400","9.6650","Tunisia","TN","TUN","Bizerte","","32546","1788734106"
"Cururupu","Cururupu","-1.8278","-44.8678","Brazil","BR","BRA","Maranhão","","32626","1076284280"
"East Kelowna","East Kelowna","49.8625","-119.5833","Canada","CA","CAN","British Columbia","","32655","1124070905"
"Avanos","Avanos","38.7150","34.8467","Turkey","TR","TUR","Nevşehir","minor","32618","1792692545"
"Paratinga","Paratinga","-12.6908","-43.1839","Brazil","BR","BRA","Bahia","","32636","1076218209"
"Naumburg","Naumburg","51.1500","11.8167","Germany","DE","DEU","Saxony-Anhalt","minor","32402","1276335227"
"Balindong","Balindong","7.9167","124.2000","Philippines","PH","PHL","Lanao del Sur","","32573","1608101746"
"São José do Belmonte","Sao Jose do Belmonte","-7.8608","-38.7600","Brazil","BR","BRA","Pernambuco","","32617","1076639958"
"Ostrogozhsk","Ostrogozhsk","50.8667","39.0667","Russia","RU","RUS","Voronezhskaya Oblast’","","32601","1643004403"
"Des Moines","Des Moines","47.3914","-122.3156","United States","US","USA","Washington","","32631","1840018426"
"Inzá","Inza","2.5500","-76.0667","Colombia","CO","COL","Cauca","minor","32582","1170048803"
"Jaguariaíva","Jaguariaiva","-24.2508","-49.7058","Brazil","BR","BRA","Paraná","","32606","1076322206"
"Nagdha Simla","Nagdha Simla","24.6143","89.9166","Bangladesh","BD","BGD","Dhaka","","31436","1050208700"
"Şarköy","Sarkoy","40.6039","27.1064","Turkey","TR","TUR","Tekirdağ","minor","32565","1792319237"
"Sanha","Sanha","25.4020","86.3592","India","IN","IND","Bihār","","31394","1356447763"
"Minamisatsuma","Minamisatsuma","31.4169","130.3228","Japan","JP","JPN","Kagoshima","","32503","1392999973"
"Kävlinge","Kavlinge","55.7939","13.1133","Sweden","SE","SWE","Skåne","minor","32341","1752983535"
"Peñaranda","Penaranda","15.3531","121.0017","Philippines","PH","PHL","Nueva Ecija","","32269","1608867657"
"Plettenberg Bay","Plettenberg Bay","-34.0500","23.3667","South Africa","ZA","ZAF","Western Cape","","31804","1710852785"
"Shimanto","Shimanto","33.0000","132.9333","Japan","JP","JPN","Kōchi","","32551","1392003113"
"Cranberry","Cranberry","40.7104","-80.1059","United States","US","USA","Pennsylvania","","32594","1840147947"
"Lugo","Lugo","44.4167","11.9167","Italy","IT","ITA","Emilia-Romagna","","32317","1380226468"
"Vught","Vught","51.6500","5.3000","Netherlands","NL","NLD","Noord-Brabant","minor","31669","1528328553"
"Wobulenzi","Wobulenzi","0.7200","32.5300","Uganda","UG","UGA","Luwero","","31000","1800113774"
"Utraula","Utraula","27.3200","82.4200","India","IN","IND","Uttar Pradesh","","32145","1356007969"
"Fürstenwalde","Furstenwalde","52.3667","14.0667","Germany","DE","DEU","Brandenburg","","32120","1276767308"
"Mioveni","Mioveni","44.9569","24.9406","Romania","RO","ROU","Argeş","","31998","1642961342"
"Kulebaki","Kulebaki","55.4167","42.5333","Russia","RU","RUS","Nizhegorodskaya Oblast’","","32518","1643223914"
"Nāspur","Naspur","18.8300","79.4500","India","IN","IND","Andhra Pradesh","","31244","1356637430"
"Czeladź","Czeladz","50.3333","19.0833","Poland","PL","POL","Śląskie","","30681","1616073267"
"Réthymno","Rethymno","35.3689","24.4739","Greece","GR","GRC","Kríti","minor","32468","1300036161"
"Samdhin","Samdhin","27.1370","79.7030","India","IN","IND","Uttar Pradesh","","31479","1356573776"
"Sherwood","Sherwood","34.8507","-92.2029","United States","US","USA","Arkansas","","32544","1840015511"
"Kalyandrug","Kalyandrug","14.5500","77.1000","India","IN","IND","Andhra Pradesh","","32328","1356922416"
"Nossa Senhora da Glória","Nossa Senhora da Gloria","-10.2178","-37.4200","Brazil","BR","BRA","Sergipe","","32497","1076396481"
"Zarqān","Zarqan","29.7742","52.7236","Iran","IR","IRN","Fārs","","32261","1364934865"
"Alubijid","Alubijid","8.5714","124.4751","Philippines","PH","PHL","Misamis Oriental","","32163","1608269125"
"Hampden","Hampden","40.2602","-76.9809","United States","US","USA","Pennsylvania","","32530","1840143338"
"Slantsy","Slantsy","59.1167","28.0667","Russia","RU","RUS","Leningradskaya Oblast’","","32508","1643315242"
"Yankou","Yankou","27.5950","105.4174","China","CN","CHN","Guizhou","","32351","1156171220"
"Falmouth","Falmouth","41.5913","-70.5912","United States","US","USA","Massachusetts","","32526","1840053501"
"Kolachel","Kolachel","8.1767","77.2591","India","IN","IND","Tamil Nādu","","31398","1356117775"
"Goiatuba","Goiatuba","-18.0128","-49.3569","Brazil","BR","BRA","Goiás","","32492","1076079439"
"Espinho","Espinho","41.0100","-8.6400","Portugal","PT","PRT","Aveiro","minor","31027","1620002917"
"Levice","Levice","48.2164","18.6081","Slovakia","SK","SVK","Nitra","minor","31974","1703699284"
"Milazzo","Milazzo","38.2170","15.2370","Italy","IT","ITA","Sicilia","","31231","1380644927"
"Chiché","Chiche","15.0106","-91.0650","Guatemala","GT","GTM","Quiché","minor","32271","1320628712"
"Amés","Ames","42.9000","-8.6333","Spain","ES","ESP","Galicia","","32095","1724737109"
"Geesthacht","Geesthacht","53.4375","10.3675","Germany","DE","DEU","Schleswig-Holstein","","31539","1276117326"
"Barreirinha","Barreirinha","-2.8025","-57.0686","Brazil","BR","BRA","Amazonas","","32483","1076984519"
"Nouna","Nouna","12.7333","-3.8667","Burkina Faso","BF","BFA","Boucle du Mouhoun","minor","32428","1854190171"
"Jimalalud","Jimalalud","9.9797","123.1999","Philippines","PH","PHL","Negros Oriental","","32256","1608267364"
"Beixinzhuang","Beixinzhuang","38.7914","116.0917","China","CN","CHN","Hebei","","31876","1156348914"
"Shiyeli","Shiyeli","44.1789","66.7328","Kazakhstan","KZ","KAZ","","","32480","1398206798"
"La Calera","La Calera","-31.3439","-64.3353","Argentina","AR","ARG","Córdoba","","32227","1032927400"
"Delbrück","Delbruck","51.7667","8.5667","Germany","DE","DEU","North Rhine-Westphalia","","32266","1276424035"
"Andover","Andover","45.2571","-93.3265","United States","US","USA","Minnesota","","32471","1840006716"
"São José da Tapera","Sao Jose da Tapera","-9.5578","-37.3808","Brazil","BR","BRA","Alagoas","","32405","1076222428"
"Kāngayam","Kangayam","11.0054","77.5620","India","IN","IND","Tamil Nādu","","32147","1356560852"
"San Pedro de Ribas","San Pedro de Ribas","41.2592","1.7730","Spain","ES","ESP","Catalonia","","31688","1724896777"
"Staryy Beyneu","Staryy Beyneu","45.1834","55.1000","Kazakhstan","KZ","KAZ","","","32452","1398655445"
"Landecy","Landecy","46.1834","6.1166","Switzerland","CH","CHE","Genève","","31942","1756407034"
"Plottier","Plottier","-38.9500","-68.2333","Argentina","AR","ARG","Neuquén","","32390","1032684946"
"Mallig","Mallig","17.2086","121.6106","Philippines","PH","PHL","Isabela","","32208","1608733659"
"Rokhaty","Rokhaty","38.6000","68.9333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","32152","1762898038"
"Virei","Virei","-15.7167","12.9500","Angola","AO","AGO","Namibe","","32445","1024726045"
"Totana","Totana","37.7711","-1.5003","Spain","ES","ESP","Murcia","","32329","1724153814"
"Kaysville","Kaysville","41.0290","-111.9456","United States","US","USA","Utah","","32438","1840018738"
"Maputsoe","Maputsoe","-28.8950","27.9070","Lesotho","LS","LSO","Leribe","","32117","1426999579"
"La Concepción","La Concepcion","11.9369","-86.1889","Nicaragua","NI","NIC","Masaya","minor","31950","1558215738"
"Draa Ben Khedda","Draa Ben Khedda","36.7349","3.9556","Algeria","DZ","DZA","Boumerdes","","31382","1012011041"
"Bijaynagar","Bijaynagar","25.9300","74.6386","India","IN","IND","Rājasthān","","32124","1356404338"
"Zangang","Zangang","39.0524","116.2017","China","CN","CHN","Hebei","","31671","1156273914"
"Tawaramoto","Tawaramoto","34.5566","135.7950","Japan","JP","JPN","Nara","","30950","1392169878"
"Madīnat al Ḩabbānīyah","Madinat al Habbaniyah","33.3667","43.5667","Iraq","IQ","IRQ","Al Anbār","","32300","1368678108"
"Igbaras","Igbaras","10.7167","122.2667","Philippines","PH","PHL","Iloilo","","32197","1608731890"
"Zentsujichó","Zentsujicho","34.2167","133.7833","Japan","JP","JPN","Kagawa","","31620","1392897802"
"Gramado","Gramado","-29.3789","-50.8739","Brazil","BR","BRA","Rio Grande do Sul","","32273","1076167286"
"Waddinxveen","Waddinxveen","52.0500","4.6500","Netherlands","NL","NLD","Zuid-Holland","minor","31342","1528011369"
"Cachoeira Paulista","Cachoeira Paulista","-22.6650","-45.0094","Brazil","BR","BRA","São Paulo","","32294","1076563996"
"Herrenberg","Herrenberg","48.5967","8.8708","Germany","DE","DEU","Baden-Württemberg","","31918","1276549799"
"Salamína","Salamina","37.9667","23.5000","Greece","GR","GRC","Attikí","minor","31776","1300019459"
"Georgsmarienhütte","Georgsmarienhutte","52.2000","8.0667","Germany","DE","DEU","Lower Saxony","","31827","1276001308"
"Statesboro","Statesboro","32.4375","-81.7751","United States","US","USA","Georgia","","32400","1840015801"
"Zaojiao","Zaojiao","34.4727","105.7062","China","CN","CHN","Gansu","","32260","1156127816"
"Pekin","Pekin","40.5678","-89.6251","United States","US","USA","Illinois","","32398","1840009402"
"Balimbing","Balimbing","7.9000","123.8500","Philippines","PH","PHL","Lanao del Norte","","32115","1608968849"
"Dumfries","Dumfries","55.0700","-3.6030","United Kingdom","GB","GBR","Dumfries and Galloway","","32379","1826801547"
"San Pedro de Urabá","San Pedro de Uraba","8.2833","-76.3833","Colombia","CO","COL","Antioquia","minor","32328","1170185600"
"Bedworth","Bedworth","52.4750","-1.4770","United Kingdom","GB","GBR","Warwickshire","","31332","1826435844"
"Point Pedro","Point Pedro","9.8167","80.2333","Sri Lanka","LK","LKA","North Central","","31351","1144518773"
"Foça","Foca","38.6667","26.7667","Turkey","TR","TUR","İzmir","minor","32264","1792410342"
"Tubao","Tubao","16.3500","120.4167","Philippines","PH","PHL","La Union","","31763","1608119731"
"Āreka","Areka","7.0710","37.7076","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","31408","1231646056"
"Dhahran","Dhahran","26.2667","50.1500","Saudi Arabia","SA","SAU","Ash Sharqīyah","","32067","1682374155"
"Carangola","Carangola","-20.7328","-42.0289","Brazil","BR","BRA","Minas Gerais","","32296","1076047833"
"Guararapes","Guararapes","-21.2608","-50.6428","Brazil","BR","BRA","São Paulo","","32346","1076646816"
"Kōnan","Konan","33.5667","133.7000","Japan","JP","JPN","Kōchi","","32123","1392003317"
"Kukshi","Kukshi","22.2068","74.7579","India","IN","IND","Madhya Pradesh","","32093","1356834246"
"Kristianstad","Kristianstad","56.0337","14.1333","Sweden","SE","SWE","Skåne","minor","32188","1752606470"
"Ferreñafe","Ferrenafe","-6.7183","-79.7703","Peru","PE","PER","Lambayeque","","31777","1604452796"
"North Olmsted","North Olmsted","41.4149","-81.9190","United States","US","USA","Ohio","","32371","1840003400"
"Kiranomena","Kiranomena","-18.2833","46.0500","Madagascar","MG","MDG","Antananarivo","","32354","1450873803"
"Kalisizo","Kalisizo","-0.5350","31.6225","Uganda","UG","UGA","Rakai","","31439","1800028081"
"Menglie","Menglie","22.5833","101.8660","China","CN","CHN","Yunnan","minor","32306","1156955601"
"Nagarote","Nagarote","12.2650","-86.5639","Nicaragua","NI","NIC","León","minor","32303","1558005234"
"Dracut","Dracut","42.6832","-71.3010","United States","US","USA","Massachusetts","","32356","1840053477"
"Rādhanpur","Radhanpur","23.8300","71.6000","India","IN","IND","Gujarāt","","32076","1356941349"
"Narsinghgarh","Narsinghgarh","23.7000","77.1000","India","IN","IND","Madhya Pradesh","","32329","1356964288"
"Nacogdoches","Nacogdoches","31.6134","-94.6528","United States","US","USA","Texas","","32347","1840020820"
"Buguey","Buguey","18.2882","121.8331","Philippines","PH","PHL","Cagayan","minor","32148","1608139858"
"Canton","Canton","34.2467","-84.4897","United States","US","USA","Georgia","","32342","1840014703"
"São Raimundo Nonato","Sao Raimundo Nonato","-9.0150","-42.6989","Brazil","BR","BRA","Piauí","","32327","1076608833"
"Wheat Ridge","Wheat Ridge","39.7728","-105.1028","United States","US","USA","Colorado","","32340","1840021431"
"Châtellerault","Chatellerault","46.8178","0.5461","France","FR","FRA","Nouvelle-Aquitaine","minor","31733","1250127354"
"Berasia","Berasia","23.6146","77.4645","India","IN","IND","Madhya Pradesh","","32078","1356531444"
"Nieuw-Vennep","Nieuw-Vennep","52.2644","4.6347","Netherlands","NL","NLD","Noord-Holland","","31415","1528802124"
"Sébaco","Sebaco","12.8553","-86.0983","Nicaragua","NI","NIC","Matagalpa","minor","32221","1558662201"
"Oudenaarde","Oudenaarde","50.8500","3.6000","Belgium","BE","BEL","Flanders","minor","31866","1056780523"
"Catubig","Catubig","12.4000","125.0500","Philippines","PH","PHL","Northern Samar","","32174","1608682352"
"Curanilahue","Curanilahue","-37.4764","-73.3444","Chile","CL","CHL","Biobío","","32288","1152226096"
"Harker Heights","Harker Heights","31.0572","-97.6446","United States","US","USA","Texas","","32320","1840020851"
"Namerikawa","Namerikawa","36.7644","137.3412","Japan","JP","JPN","Toyama","","32317","1392003318"
"Rochester","Rochester","43.2990","-70.9787","United States","US","USA","New Hampshire","","32317","1840002809"
"Miguel Alves","Miguel Alves","-4.1658","-42.8950","Brazil","BR","BRA","Piauí","","32289","1076086416"
"Rome","Rome","43.2260","-75.4909","United States","US","USA","New York","","32309","1840000349"
"Mangūr","Mangur","17.9373","80.8185","India","IN","IND","Andhra Pradesh","","32091","1356904043"
"Bambadinca","Bambadinca","12.0333","-14.8667","Guinea-Bissau","GW","GNB","Bafatá","","32255","1624729840"
"Gödöllő","Godollo","47.6000","19.3667","Hungary","HU","HUN","Pest","minor","31779","1348039473"
"Ribeira Grande","Ribeira Grande","37.8167","-25.5167","Portugal","PT","PRT","Azores","","32112","1620024564"
"Ilhabela","Ilhabela","-23.7778","-45.3578","Brazil","BR","BRA","São Paulo","","32197","1076323267"
"Salug","Salug","8.1075","122.7575","Philippines","PH","PHL","Zamboanga del Norte","","32134","1608808940"
"Kottaikuppam","Kottaikuppam","11.9613","79.8392","India","IN","IND","Tamil Nādu","","31779","1356245670"
"Messaména","Messamena","3.7333","12.8333","Cameroon","CM","CMR","Est","","32282","1120403736"
"San Carlos Alzatate","San Carlos Alzatate","14.5000","-90.0667","Guatemala","GT","GTM","Jalapa","minor","31938","1320987618"
"Shimizuchō","Shimizucho","35.0990","138.9029","Japan","JP","JPN","Shizuoka","","31652","1392909115"
"Bramsche","Bramsche","52.4000","7.9833","Germany","DE","DEU","Lower Saxony","","32103","1276405075"
"Jaguaruana","Jaguaruana","-4.8339","-37.7808","Brazil","BR","BRA","Ceará","","32236","1076906413"
"Nagato","Nagato","34.3722","131.1833","Japan","JP","JPN","Yamaguchi","","32183","1392904724"
"Capulhuac","Capulhuac","19.2000","-99.4667","Mexico","MX","MEX","México","minor","30838","1484829703"
"João Câmara","Joao Camara","-5.5378","-35.8200","Brazil","BR","BRA","Rio Grande do Norte","","32227","1076573306"
"Kangasala","Kangasala","61.4639","24.0650","Finland","FI","FIN","Pirkanmaa","minor","32229","1246606231"
"Tamparan","Tamparan","7.8790","124.3330","Philippines","PH","PHL","Lanao del Sur","","32074","1608808635"
"Lower Macungie","Lower Macungie","40.5303","-75.5700","United States","US","USA","Pennsylvania","","32261","1840149645"
"Tummapāla","Tummapala","17.7166","82.9965","India","IN","IND","Andhra Pradesh","","31035","1356569796"
"Oer-Erkenschwick","Oer-Erkenschwick","51.6422","7.2508","Germany","DE","DEU","North Rhine-Westphalia","","31442","1276269090"
"Caramoran","Caramoran","13.9833","124.1333","Philippines","PH","PHL","Catanduanes","","32114","1608187782"
"Cheb","Cheb","50.0794","12.3706","Czechia","CZ","CZE","Karlovarský Kraj","","31920","1203676556"
"Antsohimbondrona","Antsohimbondrona","-13.0833","48.8500","Madagascar","MG","MDG","Antsiranana","","32080","1450655799"
"Bauko","Bauko","16.9917","120.8644","Philippines","PH","PHL","Mountain","","32021","1608534033"
"Rosmalen","Rosmalen","51.7167","5.3667","Netherlands","NL","NLD","Noord-Brabant","","31219","1528018450"
"Windsor","Windsor","51.4791","-0.6095","United Kingdom","GB","GBR","Windsor and Maidenhead","","32184","1826792931"
"Wevelgem","Wevelgem","50.8081","3.1839","Belgium","BE","BEL","Flanders","","31412","1056233593"
"Primorsko-Akhtarsk","Primorsko-Akhtarsk","46.0500","38.1667","Russia","RU","RUS","Krasnodarskiy Kray","minor","32180","1643397467"
"Xangda","Xangda","32.2056","96.4751","China","CN","CHN","Qinghai","minor","32175","1156944769"
"Poconé","Pocone","-16.2569","-56.6228","Brazil","BR","BRA","Mato Grosso","","32205","1076449216"
"Kingman","Kingman","35.2170","-114.0105","United States","US","USA","Arizona","","32204","1840021043"
"Pedana","Pedana","16.2667","81.1667","India","IN","IND","Andhra Pradesh","","30721","1356417725"
"Grottaglie","Grottaglie","40.5333","17.4333","Italy","IT","ITA","Puglia","","31890","1380386888"
"Bizen","Bizen","34.7453","134.1889","Japan","JP","JPN","Okayama","","32077","1392003450"
"Września","Wrzesnia","52.3333","17.5833","Poland","PL","POL","Wielkopolskie","minor","31986","1616719550"
"Massillon","Massillon","40.7838","-81.5254","United States","US","USA","Ohio","","32199","1840000965"
"Jāsim","Jasim","32.9667","36.0667","Syria","SY","SYR","Dar‘ā","minor","31683","1760676905"
"Aguilares","Aguilares","-27.4333","-65.6167","Argentina","AR","ARG","Tucumán","minor","32097","1032907476"
"Bandeirantes","Bandeirantes","-23.1100","-50.3678","Brazil","BR","BRA","Paraná","","32184","1076892563"
"Novovoronezh","Novovoronezh","51.3167","39.2167","Russia","RU","RUS","Voronezhskaya Oblast’","","31503","1643532936"
"Dongxianpo","Dongxianpo","39.5610","115.9865","China","CN","CHN","Hebei","","31410","1156637664"
"Itamarandiba","Itamarandiba","-17.8569","-42.8589","Brazil","BR","BRA","Minas Gerais","","32175","1076820842"
"Buyan","Buyan","23.9961","120.4618","Taiwan","TW","TWN","Changhua","","31380","1158864822"
"Saint-Médard-en-Jalles","Saint-Medard-en-Jalles","44.8964","-0.7164","France","FR","FRA","Nouvelle-Aquitaine","","31808","1250115670"
"Cruces","Cruces","22.3419","-80.2761","Cuba","CU","CUB","Cienfuegos","minor","32139","1192346254"
"Waiyuanshan","Waiyuanshan","24.7443","121.6684","Taiwan","TW","TWN","Yilan","","31893","1158844891"
"Ōdachō-ōda","Odacho-oda","35.1833","132.5000","Japan","JP","JPN","Shimane","","32102","1392184583"
"Shima","Shima","39.5544","141.1555","Japan","JP","JPN","Iwate","","32036","1392952545"
"Douar Oulad Hssine","Douar Oulad Hssine","33.0680","-8.5107","Morocco","MA","MAR","Casablanca-Settat","","32130","1504473203"
"Wallsend","Wallsend","54.9910","-1.5340","United Kingdom","GB","GBR","North Tyneside","","30400","1826171317"
"Malangas","Malangas","7.6317","123.0322","Philippines","PH","PHL","Zamboanga Sibugay","","32022","1608502020"
"Pervari","Pervari","37.9331","42.5469","Turkey","TR","TUR","Siirt","minor","32128","1792426838"
"Termas de Río Hondo","Termas de Rio Hondo","-27.4833","-64.8667","Argentina","AR","ARG","Santiago del Estero","minor","32116","1032812250"
"Palmeira","Palmeira","-25.4289","-50.0058","Brazil","BR","BRA","Paraná","","32123","1076227836"
"Quintero","Quintero","-32.7833","-71.5333","Chile","CL","CHL","Valparaíso","","31923","1152270732"
"Yaguarón","Yaguaron","-25.5621","-57.2840","Paraguay","PY","PRY","Paraguarí","","32000","1600386044"
"Colíder","Colider","-10.8128","-55.4550","Brazil","BR","BRA","Mato Grosso","","32120","1076780919"
"Nālchiti","Nalchiti","22.6350","90.2700","Bangladesh","BD","BGD","Barishal","","30803","1050419528"
"Yaese","Yaese","26.1582","127.7186","Japan","JP","JPN","Okinawa","","30974","1392003385"
"Radolfzell am Bodensee","Radolfzell am Bodensee","47.7333","8.9667","Germany","DE","DEU","Baden-Württemberg","","31582","1276934854"
"Jiming","Jiming","40.1884","118.1392","China","CN","CHN","Hebei","","31658","1156365308"
"Mutki","Mutki","38.4092","41.9219","Turkey","TR","TUR","Bitlis","minor","32090","1792505661"
"Guaratuba","Guaratuba","-25.8828","-48.5750","Brazil","BR","BRA","Paraná","","32095","1076880478"
"Serdobsk","Serdobsk","52.4667","44.2000","Russia","RU","RUS","Penzenskaya Oblast’","","32087","1643300629"
"Yuzhne","Yuzhne","46.6300","31.1000","Ukraine","UA","UKR","Odeska Oblast","","32100","1804000461"
"Masatepe","Masatepe","11.9147","-86.1442","Nicaragua","NI","NIC","Masaya","minor","31583","1558293076"
"Donggou","Donggou","35.5621","112.7046","China","CN","CHN","Shanxi","","31732","1156953157"
"Quitilipi","Quitilipi","-26.8667","-60.2167","Argentina","AR","ARG","Chaco","minor","32083","1032874316"
"Zapala","Zapala","-38.9000","-70.0667","Argentina","AR","ARG","Neuquén","minor","32097","1032365659"
"Dar Ould Zidouh","Dar Ould Zidouh","32.3167","-6.9000","Morocco","MA","MAR","Béni Mellal-Khénifra","","31170","1504900512"
"Shentang","Shentang","22.2915","113.3695","China","CN","CHN","Guangdong","","31559","1156977088"
"Tongxiao","Tongxiao","24.4833","120.6833","Taiwan","TW","TWN","Miaoli","","31799","1158427043"
"Savage","Savage","44.7545","-93.3632","United States","US","USA","Minnesota","","32092","1840008957"
"Sami","Sami","21.2933","93.0965","Myanmar","MM","MMR","Chin State","","32082","1104034136"
"Alba","Alba","44.7000","8.0333","Italy","IT","ITA","Piedmont","","31498","1380267586"
"Tirebolu","Tirebolu","41.0056","38.8167","Turkey","TR","TUR","Giresun","minor","32008","1792367671"
"Renkum","Renkum","51.9833","5.8500","Netherlands","NL","NLD","Gelderland","","31417","1528187286"
"Partinico","Partinico","38.0500","13.1167","Italy","IT","ITA","Sicilia","","31786","1380288818"
"Tongkou","Tongkou","38.7952","115.8646","China","CN","CHN","Hebei","","31576","1156204912"
"San Lucas Tolimán","San Lucas Toliman","14.6333","-91.1333","Guatemala","GT","GTM","Sololá","minor","31805","1320193805"
"Remedios","Remedios","7.0275","-74.6939","Colombia","CO","COL","Antioquia","minor","32057","1170639594"
"Uglich","Uglich","57.5333","38.3333","Russia","RU","RUS","Yaroslavskaya Oblast’","","32057","1643786573"
"Norak","Norak","38.3833","69.3167","Tajikistan","TJ","TJK","Khatlon","minor","31400","1762774503"
"Claveria","Claveria","18.6061","121.0822","Philippines","PH","PHL","Cagayan","","31900","1608555618"
"Stadskanaal","Stadskanaal","52.9833","6.9500","Netherlands","NL","NLD","Groningen","minor","31789","1528657709"
"Cabaiguán","Cabaiguan","22.0839","-79.4953","Cuba","CU","CUB","Sancti Spíritus","minor","32000","1192068623"
"Vohilava","Vohilava","-21.0667","48.0000","Madagascar","MG","MDG","Fianarantsoa","","32000","1450947026"
"Gurupá","Gurupa","-1.4050","-51.6400","Brazil","BR","BRA","Pará","","32049","1076469550"
"Oristano","Oristano","39.9058","8.5916","Italy","IT","ITA","Sardegna","minor","31671","1380531119"
"Bekoratsaka","Bekoratsaka","-16.1000","47.5000","Madagascar","MG","MDG","Mahajanga","","32000","1450752674"
"Polillo","Polillo","14.7167","121.9500","Philippines","PH","PHL","Quezon","","31908","1608558714"
"Lumding","Lumding","25.7500","93.1700","India","IN","IND","Assam","","31347","1356061348"
"Bahharet Oulad Ayyad","Bahharet Oulad Ayyad","34.7702","-6.3047","Morocco","MA","MAR","Rabat-Salé-Kénitra","","31860","1504139256"
"Chūō","Chuo","35.5996","138.6339","Japan","JP","JPN","Yamanashi","","31053","1392999994"
"Courcelles","Courcelles","50.4578","4.3756","Belgium","BE","BEL","Wallonia","","31376","1056057326"
"Itampolo","Itampolo","-24.6833","43.9500","Madagascar","MG","MDG","Toliara","","32000","1450567522"
"Kochugaon","Kochugaon","26.5518","90.0762","India","IN","IND","Assam","","31719","1356850689"
"Pandua","Pandua","23.0800","88.2800","India","IN","IND","West Bengal","","30700","1356460941"
"Moche","Moche","-8.1706","-79.0094","Peru","PE","PER","La Libertad","","30671","1604819600"
"Asilah","Asilah","35.4667","-6.0333","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","31147","1504676037"
"Andriba","Andriba","-17.5833","46.9333","Madagascar","MG","MDG","Mahajanga","","32000","1450511055"
"Mataas Na Kahoy","Mataas Na Kahoy","13.9667","121.0833","Philippines","PH","PHL","Batangas","","30621","1608270985"
"Nilka","Nilka","43.7826","82.5089","China","CN","CHN","Xinjiang","minor","31907","1156907624"
"Sigma","Sigma","11.4214","122.6662","Philippines","PH","PHL","Capiz","","31688","1608472773"
"Sergio Osmeña Sr","Sergio Osmena Sr","8.3003","123.5083","Philippines","PH","PHL","Zamboanga del Sur","","31942","1608000320"
"Deptford","Deptford","39.8157","-75.1181","United States","US","USA","New Jersey","","31995","1840081679"
"Desert Hot Springs","Desert Hot Springs","33.9550","-116.5429","United States","US","USA","California","","31988","1840019306"
"Nanyuki","Nanyuki","0.0167","37.0667","Kenya","KE","KEN","Laikipia","","31577","1404481993"
"Sakhnīn","Sakhnin","32.8667","35.3","Israel","IL","ISR","Northern","","31100","1376646130"
"Mohyliv-Podilskyi","Mohyliv-Podilskyi","48.4500","27.7833","Ukraine","UA","UKR","Vinnytska Oblast","minor","30589","1804578556"
"Centereach","Centereach","40.8681","-73.0821","United States","US","USA","New York","","31979","1840005026"
"Pichucalco","Pichucalco","17.5167","-93.2000","Mexico","MX","MEX","Chiapas","minor","31919","1484558261"
"Paipa","Paipa","5.8333","-73.1500","Colombia","CO","COL","Boyacá","minor","31868","1170592144"
"Windsor","Windsor","40.4690","-104.9198","United States","US","USA","Colorado","","31972","1840022401"
"Namegata","Namegata","35.9905","140.4890","Japan","JP","JPN","Ibaraki","","31828","1392906263"
"Santa Rita","Santa Rita","10.5367","-71.5108","Venezuela","VE","VEN","Zulia","minor","31810","1862676200"
"Warwick","Warwick","41.2597","-74.3614","United States","US","USA","New York","","31965","1840004881"
"Shahedian","Shahedian","37.6546","114.7392","China","CN","CHN","Hebei","","31370","1156635202"
"O'Fallon","O'Fallon","38.5974","-89.9148","United States","US","USA","Illinois","","31963","1840008632"
"Marialva","Marialva","-23.4850","-51.7919","Brazil","BR","BRA","Paraná","","31959","1076544129"
"Calubian","Calubian","11.4467","124.4278","Philippines","PH","PHL","Leyte","","31646","1608361988"
"Cañuelas","Canuelas","-35.0333","-58.7333","Argentina","AR","ARG","Buenos Aires","minor","31901","1032954479"
"Komagane","Komagane","35.7288","137.9339","Japan","JP","JPN","Nagano","","31763","1392438911"
"Santa Catarina Mita","Santa Catarina Mita","14.4500","-89.7500","Guatemala","GT","GTM","Jutiapa","minor","31709","1320928551"
"Fangyuan","Fangyuan","23.9250","120.3158","Taiwan","TW","TWN","Changhua","","31572","1158966656"
"Majidpur","Majidpur","23.5796","90.7689","Bangladesh","BD","BGD","Chattogram","","30257","1050816935"
"I‘zāz","I\`zaz","36.5866","37.0463","Syria","SY","SYR","Ḩalab","minor","31534","1760669569"
"Yenice","Yenice","39.9308","27.2581","Turkey","TR","TUR","Çanakkale","minor","31907","1792433763"
"Lālgola","Lalgola","24.4200","88.2500","India","IN","IND","West Bengal","","31698","1356049004"
"Sitges","Sitges","41.2339","1.8042","Spain","ES","ESP","Catalonia","","31222","1724328412"
"São Gabriel","Sao Gabriel","-19.0169","-40.5358","Brazil","BR","BRA","Espírito Santo","","31859","1076212257"
"Jyväskylän Maalaiskunta","Jyvaskylan Maalaiskunta","62.2889","25.7417","Finland","FI","FIN","Keski-Suomi","","31870","1246861694"
"Tuzantán","Tuzantan","15.1333","-92.4000","Mexico","MX","MEX","Chiapas","minor","31755","1484633156"
"Marrero","Marrero","29.8871","-90.1126","United States","US","USA","Louisiana","","31928","1840013993"
"West Odessa","West Odessa","31.8389","-102.5003","United States","US","USA","Texas","","31927","1840037100"
"Dreux","Dreux","48.7372","1.3664","France","FR","FRA","Centre-Val de Loire","minor","30664","1250111227"
"Bayaguana","Bayaguana","18.7500","-69.6333","Dominican Republic","DO","DOM","Higuamo","","31889","1214513210"
"Bangor","Bangor","44.8323","-68.7906","United States","US","USA","Maine","","31920","1840000238"
"Galapa","Galapa","10.9167","-74.8333","Colombia","CO","COL","Atlántico","minor","31596","1170611900"
"Piuí","Piui","-20.4650","-45.9578","Brazil","BR","BRA","Minas Gerais","","31883","1076338261"
"Bagrāmī","Bagrami","34.4911","69.2756","Afghanistan","AF","AFG","Kābul","minor","31680","1004712473"
"Guachavés","Guachaves","1.2219","-77.6772","Colombia","CO","COL","Nariño","minor","31856","1170755614"
"Baradères","Baraderes","18.4825","-73.6386","Haiti","HT","HTI","Nippes","","31689","1332944244"
"Temascaltepec de González","Temascaltepec de Gonzalez","19.0433","-100.0414","Mexico","MX","MEX","México","minor","30336","1484114407"
"Merida","Merida","10.9098","124.5376","Philippines","PH","PHL","Leyte","","31574","1608181701"
"Hoyerswerda","Hoyerswerda","51.4333","14.2500","Germany","DE","DEU","Saxony","","31575","1276992982"
"Tuljāpur","Tuljapur","18.0000","76.0800","India","IN","IND","Mahārāshtra","","31714","1356838341"
"Turicato","Turicato","19.0500","-101.4167","Mexico","MX","MEX","Michoacán","minor","31877","1484580353"
"Cacolo","Cacolo","-10.1333","19.2667","Angola","AO","AGO","Lunda-Sul","","31895","1024592975"
"Gongguan","Gongguan","24.5053","120.8447","Taiwan","TW","TWN","Miaoli","","31453","1158933960"
"Montebelluna","Montebelluna","45.7753","12.0389","Italy","IT","ITA","Veneto","","31258","1380459138"
"Igrejinha","Igrejinha","-29.5739","-50.7900","Brazil","BR","BRA","Rio Grande do Sul","","31660","1076637934"
"Sasaguri","Sasaguri","33.6239","130.5262","Japan","JP","JPN","Fukuoka","","31092","1392528017"
"El Oro de Hidalgo","El Oro de Hidalgo","19.8008","-100.1314","Mexico","MX","MEX","Morelos","minor","31847","1484677445"
"Ruy Barbosa","Ruy Barbosa","-12.2839","-40.4939","Brazil","BR","BRA","Bahia","","31867","1076207484"
"Katagami","Katagami","39.8832","139.9886","Japan","JP","JPN","Akita","","31558","1392973238"
"Louvain-la-Neuve","Louvain-la-Neuve","50.6667","4.5000","Belgium","BE","BEL","Wallonia","","31551","1056082103"
"Domingos Martins","Domingos Martins","-20.3628","-40.6589","Brazil","BR","BRA","Espírito Santo","","31847","1076223781"
"São José do Egito","Sao Jose do Egito","-7.4733","-37.2744","Brazil","BR","BRA","Pernambuco","","31829","1076085111"
"Níjar","Nijar","36.9667","-2.2000","Spain","ES","ESP","Andalusia","","31816","1724044503"
"Touba","Touba","8.2833","-7.6833","Côte d'Ivoire","CI","CIV","Woroba","minor","31844","1384485647"
"Gevelsberg","Gevelsberg","51.3167","7.3333","Germany","DE","DEU","North Rhine-Westphalia","","30695","1276634761"
"Ubajara","Ubajara","-3.8544","-40.9211","Brazil","BR","BRA","Ceará","","31787","1076215653"
"Val-d’Or","Val-d'Or","48.1000","-77.7833","Canada","CA","CAN","Quebec","","31862","1124239138"
"Amatenango de la Frontera","Amatenango de la Frontera","15.5333","-92.0833","Mexico","MX","MEX","Chiapas","minor","31735","1484343885"
"São Gotardo","Sao Gotardo","-19.3108","-46.0489","Brazil","BR","BRA","Minas Gerais","","31819","1076795698"
"Ivisan","Ivisan","11.5217","122.6908","Philippines","PH","PHL","Capiz","","31278","1608052349"
"Buzovna","Buzovna","40.5161","50.1164","Azerbaijan","AZ","AZE","Bakı","","31500","1031797928"
"Masi-Manimba","Masi-Manimba","-4.7790","17.9077","Congo (Kinshasa)","CD","COD","Kwilu","","31802","1180044488"
"Ortaköy","Ortakoy","38.7372","34.0386","Turkey","TR","TUR","Aksaray","minor","31818","1792138980"
"Boghni","Boghni","36.5437","3.9523","Algeria","DZ","DZA","Tizi Ouzou","","31263","1012740129"
"Pantabangan","Pantabangan","15.8086","121.1433","Philippines","PH","PHL","Nueva Ecija","","31763","1608834097"
"Newburgh","Newburgh","41.5531","-74.0599","United States","US","USA","New York","","31841","1840000586"
"’Aïn Abid","'Ain Abid","36.2325","6.9440","Algeria","DZ","DZA","Constantine","","31743","1012017837"
"Farrokh Shahr","Farrokh Shahr","32.2714","50.9775","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","31739","1364423135"
"Avola","Avola","36.9167","15.1333","Italy","IT","ITA","Sicilia","","31408","1380501928"
"Gallipoli","Gallipoli","40.4139","26.6703","Turkey","TR","TUR","Çanakkale","minor","31782","1792594888"
"Ivaiporã","Ivaipora","-24.2478","-51.6850","Brazil","BR","BRA","Paraná","","31816","1076889382"
"San Sebastián","San Sebastian","14.5667","-91.6500","Guatemala","GT","GTM","Retalhuleu","minor","30724","1320277612"
"Owen Sound","Owen Sound","44.5667","-80.9333","Canada","CA","CAN","Ontario","","31820","1124623613"
"Al ‘Aydābī","Al \`Aydabi","17.2370","42.9390","Saudi Arabia","SA","SAU","Jāzān","","31735","1682495088"
"Velikiy Ustyug","Velikiy Ustyug","60.7667","46.3000","Russia","RU","RUS","Vologodskaya Oblast’","","31806","1643173653"
"Needham","Needham","42.2814","-71.2411","United States","US","USA","Massachusetts","","31808","1840053553"
"Shikārpur","Shikarpur","14.2700","75.3500","India","IN","IND","Karnātaka","","31508","1356373493"
"Ganderkesee","Ganderkesee","53.0358","8.5483","Germany","DE","DEU","Lower Saxony","","31578","1276035845"
"Weil am Rhein","Weil am Rhein","47.5947","7.6108","Germany","DE","DEU","Baden-Württemberg","","30251","1276439955"
"Clearfield","Clearfield","41.1030","-112.0238","United States","US","USA","Utah","","31802","1840018735"
"Al ’Attawia","Al 'Attawia","31.8347","-7.3125","Morocco","MA","MAR","Marrakech-Safi","","30315","1504496327"
"Gubbio","Gubbio","43.3500","12.5667","Italy","IT","ITA","Umbria","","31736","1380648510"
"Kavār","Kavar","29.2050","52.6900","Iran","IR","IRN","Fārs","minor","31711","1364873386"
"Mława","Mlawa","53.1167","20.3667","Poland","PL","POL","Mazowieckie","minor","30919","1616236136"
"San Giuliano Terme","San Giuliano Terme","43.7625","10.4414","Italy","IT","ITA","Tuscany","","31447","1380980385"
"Chum Phae","Chum Phae","16.5431","102.1104","Thailand","TH","THA","Khon Kaen","minor","31597","1764511790"
"Rahīmpur","Rahimpur","25.4894","86.4774","India","IN","IND","Bihār","","30089","1356175788"
"Bocana de Paiwas","Bocana de Paiwas","12.7878","-85.1228","Nicaragua","NI","NIC","Costa Caribe Sur","minor","31762","1558901000"
"Valkenswaard","Valkenswaard","51.3500","5.4667","Netherlands","NL","NLD","Noord-Brabant","minor","31221","1528944698"
"Springfield","Springfield","38.7810","-77.1839","United States","US","USA","Virginia","","31769","1840006039"
"General José de San Martín","General Jose de San Martin","-26.5375","-59.3417","Argentina","AR","ARG","Chaco","minor","31758","1032349411"
"Rāhatgarh","Rahatgarh","23.7800","78.3700","India","IN","IND","Madhya Pradesh","","31537","1356272218"
"Willingboro","Willingboro","40.0280","-74.8882","United States","US","USA","New Jersey","","31763","1840081630"
"Monção","Moncao","-3.4919","-45.2508","Brazil","BR","BRA","Maranhão","","31738","1076424979"
"Yıldızeli","Yildizeli","39.8642","36.5983","Turkey","TR","TUR","Sivas","minor","31748","1792870647"
"Jalpatagua","Jalpatagua","14.1364","-90.0052","Guatemala","GT","GTM","Jutiapa","minor","31601","1320215735"
"Uithoorn","Uithoorn","52.2333","4.8333","Netherlands","NL","NLD","Noord-Holland","minor","30206","1528483112"
"Kasimov","Kasimov","54.9333","41.4000","Russia","RU","RUS","Ryazanskaya Oblast’","","30243","1643283044"
"Jamālpur","Jamalpur","25.4112","86.6593","India","IN","IND","Bihār","","30008","1356180868"
"Fengrenxu","Fengrenxu","24.1757","115.3271","China","CN","CHN","Guangdong","","30324","1156616040"
"Sri Mādhopur","Sri Madhopur","27.4667","75.6000","India","IN","IND","Rājasthān","","31366","1356278703"
"Fair Oaks","Fair Oaks","38.6504","-121.2496","United States","US","USA","California","","31750","1840017580"
"Kuroishi","Kuroishi","40.6426","140.6113","Japan","JP","JPN","Aomori","","31604","1392880554"
"Paracuru","Paracuru","-3.4100","-39.0308","Brazil","BR","BRA","Ceará","","31636","1076591278"
"Sitionuevo","Sitionuevo","10.7833","-74.7167","Colombia","CO","COL","Magdalena","minor","31706","1170226891"
"Holladay","Holladay","40.6600","-111.8226","United States","US","USA","Utah","","31738","1840020156"
"Haan","Haan","51.1667","7.0000","Germany","DE","DEU","North Rhine-Westphalia","","30484","1276168837"
"Aguada de Pasajeros","Aguada de Pasajeros","22.3847","-80.8461","Cuba","CU","CUB","Cienfuegos","minor","31687","1192172785"
"Kotovsk","Kotovsk","52.5833","41.5167","Russia","RU","RUS","Tambovskaya Oblast’","","29999","1643608746"
"Jesús María","Jesus Maria","-30.9833","-64.1000","Argentina","AR","ARG","Córdoba","minor","31602","1032875104"
"Dongmaying","Dongmaying","39.1221","115.9814","China","CN","CHN","Hebei","","30841","1156844412"
"Mirnyy","Mirnyy","62.7667","40.3333","Russia","RU","RUS","Arkhangel’skaya Oblast’","","31704","1643687005"
"Siquirres","Siquirres","10.0901","-83.5016","Costa Rica","CR","CRI","Limón","","31637","1188355031"
"El Palmar","El Palmar","14.6500","-91.5833","Guatemala","GT","GTM","Quetzaltenango","minor","31706","1320401854"
"Balyqshy","Balyqshy","47.0667","51.8667","Kazakhstan","KZ","KAZ","","","31624","1398354265"
"Maebara","Maebara","35.1140","140.0989","Japan","JP","JPN","Chiba","","31554","1392895244"
"Sarno","Sarno","40.8167","14.6167","Italy","IT","ITA","Campania","","31625","1380289095"
"Syracuse","Syracuse","41.0859","-112.0698","United States","US","USA","Utah","","31715","1840021352"
"Dania Beach","Dania Beach","26.0593","-80.1637","United States","US","USA","Florida","","31703","1840015135"
"Jerez","Jerez","14.1000","-89.7500","Guatemala","GT","GTM","Jutiapa","minor","31601","1320129254"
"Gadarpur","Gadarpur","29.0437","79.2465","India","IN","IND","Uttarākhand","","30753","1356122882"
"Florence","Florence","38.9899","-84.6471","United States","US","USA","Kentucky","","31699","1840013161"
"Žepče","Zepce","44.4333","18.0333","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","31582","1070466847"
"Navalcarnero","Navalcarnero","40.2847","-4.0136","Spain","ES","ESP","Madrid","","31379","1724865858"
"Pontefract","Pontefract","53.6910","-1.3120","United Kingdom","GB","GBR","Wakefield","","30881","1826127611"
"Irituia","Irituia","-1.7689","-47.4389","Brazil","BR","BRA","Pará","","31664","1076738567"
"Weyhe","Weyhe","52.9936","8.8733","Germany","DE","DEU","Lower Saxony","","31162","1276749536"
"Magsingal","Magsingal","17.6850","120.4244","Philippines","PH","PHL","Ilocos Sur","","31308","1608782120"
"Lemery","Lemery","11.2333","122.9333","Philippines","PH","PHL","Iloilo","","31414","1608458862"
"Nueva Imperial","Nueva Imperial","-38.7433","-72.9500","Chile","CL","CHL","Araucanía","","31632","1152277064"
"Tekkali","Tekkali","18.6057","84.2355","India","IN","IND","Andhra Pradesh","","30740","1356120719"
"President Roxas","President Roxas","11.4297","122.9247","Philippines","PH","PHL","Capiz","","31269","1608614914"
"Chmistâr","Chmistar","33.9667","36.0167","Lebanon","LB","LBN","Baalbek-Hermel","","30000","1422851411"
"Tecozautla","Tecozautla","20.5333","-99.6333","Mexico","MX","MEX","Hidalgo","","31609","1484766704"
"Exu","Exu","-7.5133","-39.7239","Brazil","BR","BRA","Pernambuco","","31636","1076979284"
"Ennery","Ennery","19.4833","-72.4833","Haiti","HT","HTI","Artibonite","","31285","1332338598"
"North Huntingdon","North Huntingdon","40.3294","-79.7336","United States","US","USA","Pennsylvania","","31654","1840144809"
"Castelvetrano","Castelvetrano","37.6833","12.7931","Italy","IT","ITA","Sicilia","","31503","1380441707"
"Cueto","Cueto","20.6481","-75.9317","Cuba","CU","CUB","Holguín","","31552","1192622876"
"Tanmen","Tanmen","19.2429","110.6120","China","CN","CHN","Hainan","","31322","1156673934"
"Morro Agudo","Morro Agudo","-20.7314","-48.0578","Brazil","BR","BRA","São Paulo","","31620","1076136085"
"Deori Khās","Deori Khas","23.3902","79.0163","India","IN","IND","Madhya Pradesh","","31423","1356404675"
"Mussoorie","Mussoorie","30.4500","78.0800","India","IN","IND","Uttarākhand","","30118","1356713058"
"Chorbog","Chorbog","38.6667","68.7667","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","31585","1762123039"
"Ayabe","Ayabe","35.3000","135.2667","Japan","JP","JPN","Kyōto","","31537","1392801118"
"Ouro Fino","Ouro Fino","-22.2828","-46.3689","Brazil","BR","BRA","Minas Gerais","","31568","1076007504"
"Titlāgarh","Titlagarh","20.3000","83.1500","India","IN","IND","Odisha","","31258","1356364042"
"Mahemdāvād","Mahemdavad","22.8300","72.7700","India","IN","IND","Gujarāt","","30769","1356006601"
"Kreuztal","Kreuztal","50.9667","7.9667","Germany","DE","DEU","North Rhine-Westphalia","","31187","1276001601"
"Frederico Westphalen","Frederico Westphalen","-27.3589","-53.3939","Brazil","BR","BRA","Rio Grande do Sul","","31498","1076744210"
"Inkhil","Inkhil","33.0000","36.1167","Syria","SY","SYR","Dar‘ā","","31258","1760570627"
"Bābura","Babura","25.6838","84.7825","India","IN","IND","Bihār","","30695","1356052811"
"Waterloo","Waterloo","50.7167","4.3833","Belgium","BE","BEL","Wallonia","","30174","1056353647"
"San Andrés Xecul","San Andres Xecul","14.9000","-91.4833","Guatemala","GT","GTM","Totonicapán","minor","29849","1320913861"
"Mae Sot","Mae Sot","16.7131","98.5747","Thailand","TH","THA","Tak","minor","31530","1764198529"
"Carballo","Carballo","43.2167","-8.6833","Spain","ES","ESP","Galicia","","31432","1724180814"
"Yawatahama-shi","Yawatahama-shi","33.4667","132.4167","Japan","JP","JPN","Ehime","minor","31363","1392674556"
"Mundelein","Mundelein","42.2693","-88.0102","United States","US","USA","Illinois","","31596","1840011175"
"Tredyffrin","Tredyffrin","40.0663","-75.4540","United States","US","USA","Pennsylvania","","31596","1840148599"
"Nardò","Nardo","40.1797","18.0333","Italy","IT","ITA","Puglia","","31431","1380000001"
"Kosonsoy","Kosonsoy","41.2500","71.5500","Uzbekistan","UZ","UZB","Namangan","minor","31217","1860234389"
"Naugatuck","Naugatuck","41.4890","-73.0518","United States","US","USA","Connecticut","","31586","1840004849"
"Pasadena","Pasadena","39.1552","-76.5537","United States","US","USA","Maryland","","31583","1840005914"
"Whitstable","Whitstable","51.3610","1.0260","United Kingdom","GB","GBR","Kent","","30195","1826750836"
"South Kingstown","South Kingstown","41.4458","-71.5440","United States","US","USA","Rhode Island","","31576","1840106244"
"Ózd","Ozd","48.2192","20.2869","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","31234","1348082095"
"Cicero","Cicero","43.1662","-76.0662","United States","US","USA","New York","","31571","1840057983"
"Husainābād","Husainabad","24.5285","84.0000","India","IN","IND","Jhārkhand","","30942","1356138744"
"Santa Cruz","Santa Cruz","10.1819","-67.5025","Venezuela","VE","VEN","Aragua","minor","29773","1862293225"
"Los Altos","Los Altos","37.3684","-122.0965","United States","US","USA","California","","31562","1840020328"
"Zima","Zima","53.9167","102.0500","Russia","RU","RUS","Irkutskaya Oblast’","","30988","1643510813"
"Dimataling","Dimataling","7.5297","123.3661","Philippines","PH","PHL","Zamboanga del Sur","","31340","1608315903"
"Sosnogorsk","Sosnogorsk","63.6000","53.9000","Russia","RU","RUS","Komi","","31556","1643361228"
"Wanghong Yidui","Wanghong Yidui","38.1993","106.2284","China","CN","CHN","Ningxia","","30943","1156534005"
"Huangxicun","Huangxicun","24.4684","115.7751","China","CN","CHN","Guangdong","","31087","1156069463"
"Qo‘rg‘ontepa","Qo\`rg\`ontepa","40.7336","72.7583","Uzbekistan","UZ","UZB","Andijon","minor","30800","1860268246"
"Changuinola","Changuinola","9.4300","-82.5200","Panama","PA","PAN","Bocas del Toro","minor","31223","1591849136"
"Dasol","Dasol","15.9896","119.8805","Philippines","PH","PHL","Pangasinan","","31355","1608297635"
"Ibaraki","Ibaraki","36.2869","140.4245","Japan","JP","JPN","Ibaraki","","31278","1392003422"
"Łowicz","Lowicz","52.1000","19.9333","Poland","PL","POL","Łódzkie","minor","30383","1616979860"
"Puerto Wilches","Puerto Wilches","7.3500","-73.9000","Colombia","CO","COL","Santander","minor","31509","1170389077"
"Verbania","Verbania","45.9228","8.5519","Italy","IT","ITA","Piedmont","minor","30709","1380922553"
"Okagaki","Okagaki","33.8536","130.6111","Japan","JP","JPN","Fukuoka","","30891","1392003395"
"Carlsbad","Carlsbad","32.4010","-104.2388","United States","US","USA","New Mexico","","31525","1840019444"
"Masqaţ","Masqat","23.5889","58.4083","Oman","OM","OMN","Masqaţ","","31409","1512001294"
"Chinnālapatti","Chinnalapatti","10.2875","77.9120","India","IN","IND","Tamil Nādu","","30823","1356243886"
"Bünyan","Bunyan","38.8486","35.8592","Turkey","TR","TUR","Kayseri","minor","31497","1792359826"
"Aiken","Aiken","33.5303","-81.7271","United States","US","USA","South Carolina","","31516","1840013722"
"Marks","Marks","51.7167","46.7500","Russia","RU","RUS","Saratovskaya Oblast’","","31488","1643304163"
"Rheinberg","Rheinberg","51.5467","6.6006","Germany","DE","DEU","North Rhine-Westphalia","","31097","1276286236"
"Kostopil","Kostopil","50.8833","26.4431","Ukraine","UA","UKR","Rivnenska Oblast","","31463","1804484929"
"Duluth","Duluth","34.0053","-84.1492","United States","US","USA","Georgia","","31506","1840013675"
"Laramie","Laramie","41.3099","-105.6085","United States","US","USA","Wyoming","","31501","1840020096"
"Bagac","Bagac","14.5951","120.3918","Philippines","PH","PHL","Bataan","","31365","1608665049"
"Westville","Westville","-29.8310","30.9250","South Africa","ZA","ZAF","KwaZulu-Natal","","30508","1710233007"
"Jam","Jam","27.8236","52.3303","Iran","IR","IRN","Būshehr","minor","31436","1364586053"
"Santa Fé do Sul","Santa Fe do Sul","-20.2108","-50.9258","Brazil","BR","BRA","São Paulo","","31348","1076177037"
"Tongeren","Tongeren","50.7794","5.4631","Belgium","BE","BEL","Flanders","minor","31142","1056711491"
"Neira","Neira","5.1664","-75.5189","Colombia","CO","COL","Caldas","minor","31399","1170186315"
"Halden","Halden","59.1264","11.4828","Norway","NO","NOR","Viken","minor","31444","1578441478"
"Cibolo","Cibolo","29.5634","-98.2115","United States","US","USA","Texas","","31489","1840019639"
"Savé","Save","8.0333","2.4833","Benin","BJ","BEN","Collines","","31444","1204120625"
"Qārā","Qara","29.8833","40.2167","Saudi Arabia","SA","SAU","Al Jawf","","31480","1682446657"
"Hitoyoshi","Hitoyoshi","32.2167","130.7500","Japan","JP","JPN","Kumamoto","","31332","1392003176"
"Riga","Riga","26.6553","85.4420","India","IN","IND","Bihār","","29974","1356117708"
"Mograne","Mograne","34.4167","-6.4333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","31292","1504174936"
"Netivot","Netivot","31.4167","34.5833","Israel","IL","ISR","Southern","","31314","1376200656"
"Tiquipaya","Tiquipaya","-17.3333","-66.2167","Bolivia","BO","BOL","Cochabamba","","31264","1068863881"
"Stratford","Stratford","43.3708","-80.9819","Canada","CA","CAN","Ontario","","31465","1124676255"
"Yingyangcun","Yingyangcun","22.0974","106.7567","China","CN","CHN","Guangxi","","31109","1156849392"
"Kaminokawa","Kaminokawa","36.4393","139.9099","Japan","JP","JPN","Tochigi","","30886","1392906156"
"Monjas","Monjas","14.5010","-89.8723","Guatemala","GT","GTM","Jalapa","minor","31330","1320080716"
"Çay","Cay","38.5926","31.0274","Turkey","TR","TUR","Afyonkarahisar","minor","31412","1792955156"
"Araçoiaba da Serra","Aracoiaba da Serra","-23.5053","-47.6142","Brazil","BR","BRA","São Paulo","","31321","1076732508"
"Barrinha","Barrinha","-21.1936","-48.1639","Brazil","BR","BRA","São Paulo","","31230","1076492454"
"Periyanāyakkanpālaiyam","Periyanayakkanpalaiyam","11.1544","76.9499","India","IN","IND","Tamil Nādu","","30154","1356123218"
"Trebinje","Trebinje","42.7089","18.3217","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","31433","1070826374"
"Făgăraş","Fagaras","45.8447","24.9742","Romania","RO","ROU","Braşov","","30714","1642873074"
"Posse","Posse","-14.0928","-46.3689","Brazil","BR","BRA","Goiás","","31419","1076046646"
"Pulupandan","Pulupandan","10.5167","122.8000","Philippines","PH","PHL","Negros Occidental","","30117","1608181104"
"La Verne","La Verne","34.1207","-117.7703","United States","US","USA","California","","31423","1840020514"
"Chantal","Chantal","18.2000","-73.8833","Haiti","HT","HTI","Sud","","31030","1332205204"
"Wenxian Chengguanzhen","Wenxian Chengguanzhen","32.9421","104.6870","China","CN","CHN","Gansu","minor","31339","1156390767"
"Mārgrām","Margram","24.1516","87.8442","India","IN","IND","West Bengal","","30055","1356114722"
"Devanhalli","Devanhalli","13.2300","77.7000","India","IN","IND","Karnātaka","","30895","1356286455"
"Lloydminster","Lloydminster","53.2783","-110.0050","Canada","CA","CAN","Saskatchewan","","31410","1124051728"
"Laguna Hills","Laguna Hills","33.5918","-117.6991","United States","US","USA","California","","31409","1840020588"
"Río Bravo","Rio Bravo","14.4011","-91.3195","Guatemala","GT","GTM","Suchitepéquez","minor","31303","1320453852"
"Guamo","Guamo","4.0833","-74.9167","Colombia","CO","COL","Tolima","minor","31350","1170113847"
"Shepparton","Shepparton","-36.3833","145.4000","Australia","AU","AUS","Victoria","","31197","1036030548"
"Bolobo","Bolobo","-2.1667","16.2333","Congo (Kinshasa)","CD","COD","Mai-Ndombe","","31366","1180723089"
"Atwater","Atwater","37.3529","-120.5959","United States","US","USA","California","","31401","1840018934"
"Buey Arriba","Buey Arriba","20.1736","-76.7494","Cuba","CU","CUB","Granma","minor","31327","1192773291"
"Newark","Newark","39.6776","-75.7576","United States","US","USA","Delaware","","31393","1840005578"
"Al Ḩusaynīyah","Al Husayniyah","30.8617","31.9181","Egypt","EG","EGY","Ash Sharqīyah","","30825","1818070991"
"Radcliffe","Radcliffe","53.5615","-2.3268","United Kingdom","GB","GBR","Bury","","29950","1826622636"
"Mamaroneck","Mamaroneck","40.9443","-73.7488","United States","US","USA","New York","","31381","1840004935"
"Pointe-Claire","Pointe-Claire","45.4500","-73.8167","Canada","CA","CAN","Quebec","","31380","1124470650"
"Podili","Podili","15.6040","79.6080","India","IN","IND","Andhra Pradesh","","31145","1356266538"
"Kudymkar","Kudymkar","59.0167","54.6667","Russia","RU","RUS","Permskiy Kray","minor","31370","1643283582"
"Yahşihan","Yahsihan","39.8503","33.4536","Turkey","TR","TUR","Kırıkkale","minor","31308","1792504360"
"Burgdorf","Burgdorf","52.4500","10.0083","Germany","DE","DEU","Lower Saxony","","31083","1276623419"
"Vinkovci","Vinkovci","45.2911","18.8011","Croatia","HR","HRV","Vukovarsko-Srijemska Županija","minor","31057","1191241036"
"Eyvān","Eyvan","33.8272","46.3097","Iran","IR","IRN","Īlām","minor","31299","1364070633"
"Shrīgonda","Shrigonda","18.6160","74.6980","India","IN","IND","Mahārāshtra","","31134","1356662339"
"Buenavista","Buenavista","13.7394","122.4675","Philippines","PH","PHL","Quezon","","31160","1608200372"
"Ngudu","Ngudu","-2.9667","33.3333","Tanzania","TZ","TZA","Mwanza","","31123","1834250706"
"Cândido Mota","Candido Mota","-22.7464","-50.3869","Brazil","BR","BRA","São Paulo","","31346","1076335584"
"Cuauhtémoc","Cuauhtemoc","19.3281","-103.6028","Mexico","MX","MEX","Colima","minor","31267","1484009228"
"Araguatins","Araguatins","-5.6508","-48.1239","Brazil","BR","BRA","Tocantins","","31329","1076919490"
"Ostuni","Ostuni","40.7333","17.5833","Italy","IT","ITA","Puglia","","31197","1380813167"
"Caracal","Caracal","44.1125","24.3472","Romania","RO","ROU","Olt","","30954","1642726745"
"Kandalaksha","Kandalaksha","67.1569","32.4117","Russia","RU","RUS","Murmanskaya Oblast’","","31329","1643555433"
"Manduria","Manduria","40.4000","17.6333","Italy","IT","ITA","Puglia","","31159","1380088320"
"Yaita","Yaita","36.8067","139.9241","Japan","JP","JPN","Tochigi","","31145","1392031620"
"Parambu","Parambu","-6.2108","-40.6939","Brazil","BR","BRA","Ceará","","31309","1076966523"
"Duanshan","Duanshan","25.7943","106.6983","China","CN","CHN","Guizhou","","31203","1156406564"
"Vienne","Vienne","45.5242","4.8781","France","FR","FRA","Auvergne-Rhône-Alpes","minor","29993","1250052124"
"Iporá","Ipora","-16.4419","-51.1178","Brazil","BR","BRA","Goiás","","31274","1076392298"
"Siraway","Siraway","7.5853","122.1406","Philippines","PH","PHL","Zamboanga del Norte","","31163","1608364909"
"Yby Yaú","Yby Yau","-22.9631","-56.5358","Paraguay","PY","PRY","Concepción","","31290","1600498095"
"Mānsa","Mansa","23.4300","72.6700","India","IN","IND","Gujarāt","","30347","1356609725"
"Bittou","Bittou","11.2575","-0.3039","Burkina Faso","BF","BFA","Centre-Est","","31210","1854307960"
"Palaiya Āyakkudi","Palaiya Ayakkudi","10.4560","77.5595","India","IN","IND","Tamil Nādu","","30848","1356125524"
"Rosignano Marittimo","Rosignano Marittimo","43.4000","10.4667","Italy","IT","ITA","Tuscany","","31039","1380869971"
"Guanhães","Guanhaes","-18.7750","-42.9328","Brazil","BR","BRA","Minas Gerais","","31262","1076235295"
"Karera","Karera","25.4581","78.1358","India","IN","IND","Madhya Pradesh","","31026","1356071073"
"Maryville","Maryville","35.7468","-83.9789","United States","US","USA","Tennessee","","31281","1840015380"
"Culleredo","Culleredo","43.2883","-8.3894","Spain","ES","ESP","Galicia","","30790","1724416062"
"Gata","Gata","7.8500","124.3500","Philippines","PH","PHL","Lanao del Sur","","31141","1608491707"
"Pindaré-Mirim","Pindare-Mirim","-3.6078","-45.3428","Brazil","BR","BRA","Maranhão","","31152","1076752524"
"Taozhuangcun","Taozhuangcun","30.9694","120.8095","China","CN","CHN","Zhejiang","","30595","1156378488"
"Vitória do Mearim","Vitoria do Mearim","-3.4619","-44.8708","Brazil","BR","BRA","Maranhão","","31217","1076499667"
"Perico","Perico","22.7753","-81.0153","Cuba","CU","CUB","Matanzas","minor","31147","1192945175"
"Norwood","Norwood","42.1861","-71.1948","United States","US","USA","Massachusetts","","31256","1840053676"
"Coria del Río","Coria del Rio","37.2833","-6.0500","Spain","ES","ESP","Andalusia","","30774","1724372076"
"San Antonio Ilotenango","San Antonio Ilotenango","15.0544","-91.2286","Guatemala","GT","GTM","Quiché","minor","30864","1320641141"
"Maināguri","Mainaguri","26.5640","88.8200","India","IN","IND","West Bengal","","30490","1356149706"
"Burgess Hill","Burgess Hill","50.9535","-0.1262","United Kingdom","GB","GBR","West Sussex","","30635","1826867983"
"Bielsk Podlaski","Bielsk Podlaski","52.7667","23.2000","Poland","PL","POL","Podlaskie","minor","30322","1616037323"
"Shawnee","Shawnee","35.3531","-96.9647","United States","US","USA","Oklahoma","","31237","1840021785"
"Cambita Garabitos","Cambita Garabitos","18.4500","-70.2000","Dominican Republic","DO","DOM","Valdesia","","31057","1214509517"
"Brasília de Minas","Brasilia de Minas","-16.2078","-44.4289","Brazil","BR","BRA","Minas Gerais","","31213","1076970617"
"Paracelis","Paracelis","17.1811","121.4036","Philippines","PH","PHL","Mountain","","31168","1608673530"
"Khānah Sūr","Khanah Sur","36.4731","41.6161","Iraq","IQ","IRQ","Nīnawá","","31161","1368503455"
"San Juan Ixcoy","San Juan Ixcoy","15.6000","-91.4500","Guatemala","GT","GTM","Huehuetenango","minor","31081","1320110393"
"Lobos","Lobos","-35.1833","-59.0833","Argentina","AR","ARG","Buenos Aires","minor","31190","1032314483"
"Yingshouyingzi","Yingshouyingzi","40.5451","117.6560","China","CN","CHN","Hebei","minor","29664","1156329428"
"Lake Magdalene","Lake Magdalene","28.0875","-82.4791","United States","US","USA","Florida","","31211","1840029049"
"Ambatomiady","Ambatomiady","-19.6833","47.4167","Madagascar","MG","MDG","Antananarivo","","31140","1450424927"
"Marigliano","Marigliano","40.9333","14.4500","Italy","IT","ITA","Campania","","29879","1380237063"
"Maubeuge","Maubeuge","50.2775","3.9734","France","FR","FRA","Hauts-de-France","","29632","1250099699"
"Alamogordo","Alamogordo","32.8837","-105.9625","United States","US","USA","New Mexico","","31201","1840019403"
"Malakanagiri","Malakanagiri","18.3500","81.9000","India","IN","IND","Odisha","","31007","1356440061"
"Sakaraha","Sakaraha","-22.9167","44.5497","Madagascar","MG","MDG","Toliara","","31183","1450732335"
"Danghara","Danghara","38.0983","69.3467","Tajikistan","TJ","TJK","Khatlon","minor","31100","1762192372"
"Eshtehārd","Eshtehard","35.7239","50.3697","Iran","IR","IRN","Alborz","minor","29993","1364749382"
"Lagos","Lagos","37.1028","-8.6728","Portugal","PT","PRT","Faro","minor","31049","1620635530"
"Saratoga","Saratoga","37.2684","-122.0263","United States","US","USA","California","","31194","1840021572"
"San Narciso","San Narciso","15.0167","120.0833","Philippines","PH","PHL","Zambales","","30759","1608230911"
"Lushnjë","Lushnje","40.9333","19.7000","Albania","AL","ALB","Fier","","31105","1008790888"
"Bogoroditsk","Bogoroditsk","53.7667","38.1333","Russia","RU","RUS","Tul’skaya Oblast’","","31139","1643215151"
"Varto","Varto","39.1731","41.4558","Turkey","TR","TUR","Muş","minor","31169","1792985807"
"Flores da Cunha","Flores da Cunha","-29.0289","-51.1819","Brazil","BR","BRA","Rio Grande do Sul","","31063","1076774323"
"Werl","Werl","51.5500","7.9200","Germany","DE","DEU","North Rhine-Westphalia","","30772","1276291604"
"Orillia","Orillia","44.6000","-79.4167","Canada","CA","CAN","Ontario","","31166","1124049830"
"Minaçu","Minacu","-13.5328","-48.2200","Brazil","BR","BRA","Goiás","","31154","1076477797"
"Łuków","Lukow","51.9272","22.3833","Poland","PL","POL","Lubelskie","minor","30381","1616162700"
"Chitral","Chitral","35.8511","71.7889","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","31100","1586861716"
"Sibanicú","Sibanicu","21.2389","-77.5211","Cuba","CU","CUB","Camagüey","minor","31117","1192253284"
"Sabana Grande de Boyá","Sabana Grande de Boya","18.9500","-69.8000","Dominican Republic","DO","DOM","Higuamo","","31096","1214983415"
"Tewksbury","Tewksbury","42.6120","-71.2278","United States","US","USA","Massachusetts","","31153","1840053490"
"Tokmak","Tokmak","47.2514","35.7058","Ukraine","UA","UKR","Zaporizka Oblast","minor","31016","1804494597"
"North Royalton","North Royalton","41.3138","-81.7450","United States","US","USA","Ohio","","31150","1840003402"
"Puthupalli","Puthupalli","9.5594","76.5722","India","IN","IND","Kerala","","29774","1356016714"
"Sovetskiy","Sovetskiy","61.3614","63.5842","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","31138","1643802838"
"Kalgoorlie","Kalgoorlie","-30.7489","121.4658","Australia","AU","AUS","Western Australia","","30841","1036087904"
"Ski","Ski","59.7419","10.8939","Norway","NO","NOR","Viken","minor","30843","1578776384"
"Espinosa","Espinosa","-14.9081","-42.8103","Brazil","BR","BRA","Minas Gerais","","31113","1076000498"
"Touros","Touros","-5.1989","-35.4608","Brazil","BR","BRA","Rio Grande do Norte","","31089","1076875720"
"Hadagalli","Hadagalli","15.0200","75.9500","India","IN","IND","Karnātaka","","30906","1356369905"
"Nantan","Nantan","35.1000","135.4667","Japan","JP","JPN","Kyōto","","31075","1392003194"
"Lakeside","Lakeside","30.1356","-81.7674","United States","US","USA","Florida","","31125","1840029051"
"Benguema","Benguema","8.3333","-13.0667","Sierra Leone","SL","SLE","Western Area","","30268","1694621543"
"Lavras da Mangabeira","Lavras da Mangabeira","-6.7528","-38.9719","Brazil","BR","BRA","Ceará","","31090","1076000532"
"Kūttānallūr","Kuttanallur","10.7069","79.5235","India","IN","IND","Tamil Nādu","","30353","1356125994"
"Tairan Camp","Tairan Camp","6.6500","121.8667","Philippines","PH","PHL","Basilan","","31040","1608342208"
"Sakhipur","Sakhipur","24.3167","90.1750","Bangladesh","BD","BGD","Dhaka","","30028","1050079670"
"Portão","Portao","-29.7019","-51.2419","Brazil","BR","BRA","Rio Grande do Sul","","30920","1076512435"
"Bembèrèkè","Bembereke","10.2250","2.6681","Benin","BJ","BEN","Borgou","","31101","1204771815"
"Vohitrandriana","Vohitrandriana","-20.7500","48.2667","Madagascar","MG","MDG","Fianarantsoa","","31000","1450279828"
"Neuruppin","Neuruppin","52.9331","12.8000","Germany","DE","DEU","Brandenburg","minor","31002","1276820903"
"Livingston","Livingston","40.7855","-74.3291","United States","US","USA","New Jersey","","31103","1840081749"
"Egra","Egra","21.9000","87.5300","India","IN","IND","West Bengal","","30148","1356806752"
"Itabela","Itabela","-16.5750","-39.5528","Brazil","BR","BRA","Bahia","","31055","1076263072"
"Illescas","Illescas","40.1167","-3.8333","Spain","ES","ESP","Castille-La Mancha","","30553","1724426043"
"Muritiba","Muritiba","-12.6258","-38.9900","Brazil","BR","BRA","Bahia","","30743","1076695612"
"Oak Ridge","Oak Ridge","35.9639","-84.2938","United States","US","USA","Tennessee","","31087","1840014473"
"Tāramangalam","Taramangalam","11.7000","77.9800","India","IN","IND","Tamil Nādu","","30222","1356170643"
"Best","Best","51.5167","5.4000","Netherlands","NL","NLD","Noord-Brabant","minor","30216","1528108081"
"Mānāvadar","Manavadar","21.5000","70.1300","India","IN","IND","Gujarāt","","30850","1356643955"
"Tijucas","Tijucas","-27.2408","-48.6339","Brazil","BR","BRA","Santa Catarina","","30960","1076000713"
"Schönebeck","Schonebeck","52.0167","11.7500","Germany","DE","DEU","Saxony-Anhalt","","30720","1276217785"
"Guanta","Guanta","10.2383","-64.5917","Venezuela","VE","VEN","Anzoátegui","minor","30891","1862441134"
"Nicholasville","Nicholasville","37.8906","-84.5668","United States","US","USA","Kentucky","","31066","1840014330"
"Marar","Marar","25.5392","86.5074","India","IN","IND","Bihār","","29368","1356773473"
"Əmircan","Amircan","40.4264","49.9836","Azerbaijan","AZ","AZE","Bakı","","29800","1031561618"
"Leusden","Leusden","52.1333","5.4333","Netherlands","NL","NLD","Utrecht","minor","30544","1528565031"
"Chagne","Chagne","10.9500","36.5000","Ethiopia","ET","ETH","Āmara","","30938","1231473183"
"New Bern","New Bern","35.0955","-77.0723","United States","US","USA","North Carolina","","31058","1840014591"
"San Miguel","San Miguel","10.7833","122.4667","Philippines","PH","PHL","Iloilo","","30115","1608896431"
"LaGrange","LaGrange","33.0274","-85.0384","United States","US","USA","Georgia","","31053","1840029147"
"Itapemirim","Itapemirim","-21.0108","-40.8339","Brazil","BR","BRA","Espírito Santo","","30988","1076103193"
"Bochnia","Bochnia","49.9833","20.4333","Poland","PL","POL","Małopolskie","minor","30075","1616833825"
"Andranovory","Andranovory","-23.1333","44.1333","Madagascar","MG","MDG","Toliara","","31000","1450787147"
"Canguaretama","Canguaretama","-6.3800","-35.1289","Brazil","BR","BRA","Rio Grande do Norte","","30916","1076516835"
"Matões","Matoes","-5.5189","-43.1989","Brazil","BR","BRA","Maranhão","","31015","1076560860"
"Ambanja","Ambanja","-13.6786","48.4522","Madagascar","MG","MDG","Antsiranana","","30621","1450987919"
"Omaezaki","Omaezaki","34.6379","138.1281","Japan","JP","JPN","Shizuoka","","30562","1392319177"
"Chengam","Chengam","12.3112","78.7925","India","IN","IND","Tamil Nādu","","30654","1356084791"
"Săcele","Sacele","45.6200","25.7097","Romania","RO","ROU","Braşov","","30920","1642349987"
"Parsuram","Parsuram","23.2135","91.4447","Bangladesh","BD","BGD","Chattogram","","29691","1050185603"
"Kamisato","Kamisato","36.2516","139.1447","Japan","JP","JPN","Saitama","","29987","1392003248"
"Arcos de la Frontera","Arcos de la Frontera","36.7500","-5.8000","Spain","ES","ESP","Andalusia","","30953","1724851482"
"Inami","Inami","34.7488","134.9135","Japan","JP","JPN","Hyōgo","","30148","1392003476"
"Dyurtyuli","Dyurtyuli","55.4833","54.8667","Russia","RU","RUS","Bashkortostan","","30969","1643865529"
"Nova Russas","Nova Russas","-4.7000","-40.5667","Brazil","BR","BRA","Ceará","","30965","1076574955"
"Xingang","Xingang","23.5600","120.3500","Taiwan","TW","TWN","Chiayi","","30543","1158285063"
"Mocímboa da Praia","Mocimboa da Praia","-11.3500","40.3333","Mozambique","MZ","MOZ","Cabo Delgado","","30950","1508896362"
"Nazaré da Mata","Nazare da Mata","-7.7419","-35.2278","Brazil","BR","BRA","Pernambuco","","30796","1076588542"
"Balatan","Balatan","13.3167","123.2333","Philippines","PH","PHL","Camarines Sur","","30669","1608458938"
"Ballwin","Ballwin","38.5950","-90.5500","United States","US","USA","Missouri","","30998","1840007447"
"Santa Vitória do Palmar","Santa Vitoria do Palmar","-33.5189","-53.3678","Brazil","BR","BRA","Rio Grande do Sul","","30990","1076114405"
"La Paz","La Paz","8.2801","125.8092","Philippines","PH","PHL","Agusan del Sur","","30969","1608150705"
"Dolo Odo","Dolo Odo","4.1667","42.0667","Ethiopia","ET","ETH","Sumalē","","30970","1231046394"
"Cleburne","Cleburne","32.3568","-97.4151","United States","US","USA","Texas","","30984","1840019464"
"Nartkala","Nartkala","43.5500","43.8500","Russia","RU","RUS","Kabardino-Balkariya","","30832","1643632227"
"Warrnambool","Warrnambool","-38.3833","142.4833","Australia","AU","AUS","Victoria","","29661","1036291219"
"João Alfredo","Joao Alfredo","-7.8558","-35.5878","Brazil","BR","BRA","Pernambuco","","30743","1076231454"
"San Isidro","San Isidro","11.4167","124.3500","Philippines","PH","PHL","Leyte","","30722","1608758636"
"Niles","Niles","42.0278","-87.8099","United States","US","USA","Illinois","","30971","1840011301"
"Bel Air North","Bel Air North","39.5543","-76.3732","United States","US","USA","Maryland","","30969","1840073591"
"Rostov","Rostov","57.1833","39.4167","Russia","RU","RUS","Yaroslavskaya Oblast’","","30943","1643848937"
"Einbeck","Einbeck","51.8167","9.8667","Germany","DE","DEU","Lower Saxony","","30826","1276253461"
"Salou","Salou","41.0796","1.1316","Spain","ES","ESP","Catalonia","","29028","1724922401"
"Westfield","Westfield","40.6516","-74.3432","United States","US","USA","New Jersey","","30950","1840001096"
"Tanguá","Tangua","-22.7300","-42.7139","Brazil","BR","BRA","Rio de Janeiro","","30732","1076102758"
"Taiobeiras","Taiobeiras","-15.8078","-42.2328","Brazil","BR","BRA","Minas Gerais","","30917","1076685590"
"Mossendjo","Mossendjo","-2.9500","12.7333","Congo (Brazzaville)","CG","COG","Niari","","30936","1178679314"
"Orcutt","Orcutt","34.8691","-120.4222","United States","US","USA","California","","30940","1840019183"
"Żywiec","Zywiec","49.6892","19.2058","Poland","PL","POL","Śląskie","minor","30334","1616870150"
"Kanzakimachi-kanzaki","Kanzakimachi-kanzaki","33.3167","130.3667","Japan","JP","JPN","Saga","","30686","1392684338"
"Cornelius","Cornelius","35.4724","-80.8813","United States","US","USA","North Carolina","","30925","1840016352"
"Tunduru","Tunduru","-11.0667","37.3500","Tanzania","TZ","TZA","Ruvuma","","30761","1834030694"
"Ganassi","Ganassi","7.8269","124.1034","Philippines","PH","PHL","Lanao del Sur","","30802","1608113357"
"Fabriano","Fabriano","43.3333","12.9167","Italy","IT","ITA","Marche","","30809","1380375723"
"Lohmar","Lohmar","50.8167","7.2167","Germany","DE","DEU","North Rhine-Westphalia","","30452","1276535520"
"Pichanal","Pichanal","-23.3167","-64.2167","Argentina","AR","ARG","Salta","","30903","1032967573"
"Bela Cruz","Bela Cruz","-3.0508","-40.1678","Brazil","BR","BRA","Ceará","","30878","1076116893"
"Bannūr","Bannur","12.3329","76.8620","India","IN","IND","Karnātaka","","30675","1356936306"
"Alma","Alma","48.5500","-71.6500","Canada","CA","CAN","Quebec","","30904","1124138438"
"Aglipay","Aglipay","16.4889","121.5874","Philippines","PH","PHL","Quirino","","30714","1608815754"
"Gamu","Gamu","17.0500","121.8333","Philippines","PH","PHL","Isabela","","30655","1608641716"
"Cabrobó","Cabrobo","-8.5119","-39.3069","Brazil","BR","BRA","Pernambuco","","30873","1076270461"
"Devarshola","Devarshola","11.5437","76.4404","India","IN","IND","Tamil Nādu","","30576","1356933679"
"Tsuruno","Tsuruno","40.8087","140.3801","Japan","JP","JPN","Aomori","","30760","1392798246"
"Colinas do Tocantins","Colinas do Tocantins","-8.0589","-48.4750","Brazil","BR","BRA","Tocantins","","30838","1076519835"
"Rāghopur","Raghopur","26.1785","86.0892","India","IN","IND","Bihār","","29149","1356027685"
"Manāwar","Manawar","22.2300","75.0800","India","IN","IND","Madhya Pradesh","","30393","1356638595"
"Uran","Uran","18.8900","72.9500","India","IN","IND","Mahārāshtra","","30439","1356923031"
"Leh","Leh","34.1642","77.5847","India","IN","IND","Ladākh","","30870","1356074174"
"SeaTac","SeaTac","47.4444","-122.2986","United States","US","USA","Washington","","30867","1840021116"
"Beverley","Beverley","53.8450","-0.4270","United Kingdom","GB","GBR","East Riding of Yorkshire","","30831","1826700096"
"Vicência","Vicencia","-7.6569","-35.3269","Brazil","BR","BRA","Pernambuco","","30732","1076853689"
"Voorhees","Voorhees","39.8450","-74.9549","United States","US","USA","New Jersey","","30864","1840081683"
"San Gregorio de Nigua","San Gregorio de Nigua","18.3833","-70.0833","Dominican Republic","DO","DOM","","","30268","1214954643"
"Garner","Garner","35.6936","-78.6181","United States","US","USA","North Carolina","","30859","1840016198"
"Tepetlaoxtoc","Tepetlaoxtoc","19.5731","-98.8203","Mexico","MX","MEX","México","minor","30680","1484140193"
"Písek","Pisek","49.3089","14.1475","Czechia","CZ","CZE","Jihočeský Kraj","","30379","1203813820"
"Unterschleißheim","Unterschleissheim","48.2833","11.5667","Germany","DE","DEU","Bavaria","","28907","1276071648"
"Sarayköy","Saraykoy","37.9265","28.9267","Turkey","TR","TUR","Denizli","minor","30768","1792181527"
"Ennepetal","Ennepetal","51.2833","7.3333","Germany","DE","DEU","North Rhine-Westphalia","","30306","1276394678"
"Río Blanco","Rio Blanco","12.9325","-85.2244","Nicaragua","NI","NIC","Matagalpa","minor","30785","1558593583"
"Capela","Capela","-10.5028","-37.0528","Brazil","BR","BRA","Sergipe","","30761","1076554382"
"Myszków","Myszkow","50.5833","19.3167","Poland","PL","POL","Śląskie","minor","30415","1616173209"
"Gurnee","Gurnee","42.3708","-87.9392","United States","US","USA","Illinois","","30826","1840011152"
"Anilao","Anilao","10.9785","122.7531","Philippines","PH","PHL","Iloilo","","30520","1608171466"
"West Warwick","West Warwick","41.6986","-71.5157","United States","US","USA","Rhode Island","","30823","1840106223"
"Laranjeiras do Sul","Laranjeiras do Sul","-25.4078","-52.4158","Brazil","BR","BRA","Paraná","","30777","1076918166"
"Qahderījān","Qahderijan","32.5767","51.4550","Iran","IR","IRN","Eşfahān","","30002","1364015114"
"Jonuta","Jonuta","18.0333","-92.1333","Mexico","MX","MEX","Tabasco","minor","30798","1484712409"
"Minas Novas","Minas Novas","-17.2189","-42.5900","Brazil","BR","BRA","Minas Gerais","","30794","1076593448"
"Opelika","Opelika","32.6612","-85.3769","United States","US","USA","Alabama","","30810","1840005210"
"Novoyavorovskoye","Novoyavorovskoye","49.9311","23.5731","Ukraine","UA","UKR","Lvivska Oblast","","30723","1804412448"
"Alegre","Alegre","-20.7639","-41.5328","Brazil","BR","BRA","Espírito Santo","","30768","1076514864"
"Rasrā","Rasra","25.8500","83.8500","India","IN","IND","Uttar Pradesh","","29263","1356103379"
"Keles","Keles","41.4033","69.2067","Uzbekistan","UZ","UZB","Toshkent","","30600","1860903282"
"Estrêla","Estrela","-29.5019","-51.9658","Brazil","BR","BRA","Rio Grande do Sul","","30619","1076456706"
"Hajdúböszörmény","Hajduboszormeny","47.6667","21.5167","Hungary","HU","HUN","Hajdú-Bihar","minor","30704","1348357188"
"Hopkinsville","Hopkinsville","36.8381","-87.4775","United States","US","USA","Kentucky","","30771","1840014399"
"Jarinu","Jarinu","-23.1014","-46.7283","Brazil","BR","BRA","São Paulo","","30617","1076915802"
"Tekeli","Tekeli","44.8300","78.8239","Kazakhstan","KZ","KAZ","","","30707","1398001451"
"Guaíra","Guaira","-24.0800","-54.2558","Brazil","BR","BRA","Paraná","","30704","1076687981"
"Colgong","Colgong","25.2633","87.2326","India","IN","IND","Bihār","","29104","1356541852"
"San Ramón","San Ramon","12.9236","-85.8389","Nicaragua","NI","NIC","Matagalpa","minor","30682","1558617224"
"Southlake","Southlake","32.9545","-97.1503","United States","US","USA","Texas","","30754","1840022048"
"Duanzhuang","Duanzhuang","36.5796","111.7577","China","CN","CHN","Shanxi","","29498","1156974275"
"Synelnykove","Synelnykove","48.3178","35.5119","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","30724","1804535574"
"Novo Cruzeiro","Novo Cruzeiro","-17.4678","-41.8750","Brazil","BR","BRA","Minas Gerais","","30725","1076396418"
"Xinzhaidian","Xinzhaidian","37.8136","114.7144","China","CN","CHN","Hebei","","30025","1156013645"
"Treviglio","Treviglio","45.5214","9.5928","Italy","IT","ITA","Lombardy","","29815","1380238695"
"Catolé do Rocha","Catole do Rocha","-6.3439","-37.7469","Brazil","BR","BRA","Paraíba","","30684","1076149254"
"Amagá","Amaga","6.0500","-75.7000","Colombia","CO","COL","Antioquia","minor","30376","1170092259"
"Orangeville","Orangeville","43.9167","-80.1167","Canada","CA","CAN","Ontario","","30734","1124566061"
"Nanwucun","Nanwucun","37.3885","115.5529","China","CN","CHN","Hebei","","30427","1156274817"
"Toribío","Toribio","2.9581","-76.2714","Colombia","CO","COL","Cauca","minor","30654","1170262354"
"Emmen","Emmen","47.0833","8.3000","Switzerland","CH","CHE","Luzern","","29292","1756487266"
"Olopa","Olopa","14.6833","-89.3500","Guatemala","GT","GTM","Chiquimula","minor","30534","1320980263"
"Princeton","Princeton","40.3562","-74.6693","United States","US","USA","New Jersey","","30717","1840001381"
"Bowling Green","Bowling Green","41.3776","-83.6495","United States","US","USA","Ohio","","30715","1840000621"
"Fort Erie","Fort Erie","42.9167","-79.0167","Canada","CA","CAN","Ontario","","30710","1124516852"
"Sāgwāra","Sagwara","23.6681","74.0244","India","IN","IND","Rājasthān","","30000","1356016551"
"Madruga","Madruga","22.9164","-81.8572","Cuba","CU","CUB","Mayabeque","minor","30640","1192001508"
"Pápa","Papa","47.3237","17.4680","Hungary","HU","HUN","Veszprém","minor","30382","1348169908"
"Anse-à-Foleur","Anse-a-Foleur","19.9000","-72.6167","Haiti","HT","HTI","Nord-Ouest","","30217","1332252871"
"Juquitiba","Juquitiba","-23.9319","-47.0686","Brazil","BR","BRA","São Paulo","","30642","1076231410"
"Ciudad Melchor de Mencos","Ciudad Melchor de Mencos","17.0667","-89.1500","Guatemala","GT","GTM","Petén","minor","30682","1320635102"
"Andernach","Andernach","50.4397","7.4017","Germany","DE","DEU","Rhineland-Palatinate","","30126","1276142194"
"Rio Branco do Sul","Rio Branco do Sul","-25.1900","-49.3139","Brazil","BR","BRA","Paraná","","30650","1076759925"
"Milford Mill","Milford Mill","39.3444","-76.7668","United States","US","USA","Maryland","","30685","1840031493"
"Fārsān","Farsan","32.2553","50.5711","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","minor","30504","1364887348"
"Tirwa","Tirwa","26.9633","79.7922","India","IN","IND","Uttar Pradesh","","30000","1356133451"
"Gobernador Virasora","Gobernador Virasora","-28.0500","-56.0333","Argentina","AR","ARG","Corrientes","","30666","1032278994"
"Yanai","Yanai","33.9667","132.1167","Japan","JP","JPN","Yamaguchi","","30461","1392003062"
"Yunoshima","Yunoshima","35.8059","137.2441","Japan","JP","JPN","Gifu","","30641","1392006188"
"To‘raqo‘rg‘on","To\`raqo\`rg\`on","41.0000","71.5158","Uzbekistan","UZ","UZB","Namangan","","30300","1860185154"
"Taunusstein","Taunusstein","50.1333","8.1500","Germany","DE","DEU","Hesse","","30221","1276571090"
"Phú Mỹ","Phu My","10.5906","107.0481","Vietnam","VN","VNM","Bà Rịa-Vũng Tàu","","29738","1704062444"
"North Andover","North Andover","42.6713","-71.0865","United States","US","USA","Massachusetts","","30671","1840053536"
"Bielawa","Bielawa","50.7000","16.6167","Poland","PL","POL","Dolnośląskie","","29872","1616721687"
"Banamba","Banamba","13.5500","-7.4500","Mali","ML","MLI","Koulikoro","minor","30591","1466760822"
"Aungban","Aungban","20.6667","96.6333","Myanmar","MM","MMR","Shan State","","30515","1104836373"
"Thung Song","Thung Song","8.1669","99.6745","Thailand","TH","THA","Nakhon Si Thammarat","minor","30500","1764422992"
"Torrijos","Torrijos","13.3167","122.0833","Philippines","PH","PHL","Marinduque","","30476","1608448413"
"Osterholz-Scharmbeck","Osterholz-Scharmbeck","53.2167","8.8000","Germany","DE","DEU","Lower Saxony","minor","30438","1276987274"
"Villa Donato Guerra","Villa Donato Guerra","19.3083","-100.1422","Mexico","MX","MEX","México","minor","29621","1484701900"
"Canyon Lake","Canyon Lake","29.8761","-98.2611","United States","US","USA","Texas","","30641","1840018260"
"Holbæk","Holbaek","55.7156","11.7225","Denmark","DK","DNK","Sjælland","minor","29608","1208012089"
"Kuttuparamba","Kuttuparamba","11.8300","75.5800","India","IN","IND","Kerala","","28907","1356042076"
"Décines-Charpieu","Decines-Charpieu","45.7694","4.9594","France","FR","FRA","Auvergne-Rhône-Alpes","","28930","1250944644"
"Suzak","Suzak","40.8981","72.9047","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","30534","1417055245"
"Victoria","Victoria","-32.6167","-60.1667","Argentina","AR","ARG","Entre Ríos","minor","30623","1032557014"
"Anjad","Anjad","22.0417","75.0552","India","IN","IND","Madhya Pradesh","","30204","1356029275"
"Thuận Tiến","Thuan Tien","10.0894","105.8281","Vietnam","VN","VNM","Vĩnh Long","","29806","1704059515"
"San Andrés del Rabanedo","San Andres del Rabanedo","42.6167","-5.6000","Spain","ES","ESP","Castille-Leon","","30160","1724350553"
"North Chicago","North Chicago","42.3172","-87.8595","United States","US","USA","Illinois","","30619","1840008086"
"Wuyuan","Wuyuan","41.0896","108.2722","China","CN","CHN","Inner Mongolia","","30057","1156146594"
"Mont-de-Marsan","Mont-de-Marsan","43.8900","-0.5000","France","FR","FRA","Nouvelle-Aquitaine","minor","29807","1250242566"
"Rapallo","Rapallo","44.3500","9.2333","Italy","IT","ITA","Liguria","","29728","1380678273"
"Chimākurti","Chimakurti","15.5819","79.8680","India","IN","IND","Andhra Pradesh","","30279","1356627585"
"Middle River","Middle River","39.3436","-76.4253","United States","US","USA","Maryland","","30606","1840005682"
"Founougo","Founougo","11.4808","2.5322","Benin","BJ","BEN","Alibori","","30527","1204005508"
"Sokuluk","Sokuluk","42.8600","74.3000","Kyrgyzstan","KG","KGZ","Chüy","minor","30540","1417050744"
"Drexel Heights","Drexel Heights","32.1453","-111.0480","United States","US","USA","Arizona","","30599","1840027928"
"Motomiya","Motomiya","37.5132","140.3938","Japan","JP","JPN","Fukushima","","30253","1392717520"
"Uzynaghash","Uzynaghash","43.2297","76.3100","Kazakhstan","KZ","KAZ","","","30589","1398430039"
"Alcázar de San Juan","Alcazar de San Juan","39.4056","-3.2056","Spain","ES","ESP","Castille-La Mancha","","30548","1724889534"
"Sardrūd","Sardrud","38.0286","46.1475","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","29739","1364669564"
"North Tonawanda","North Tonawanda","43.0457","-78.8659","United States","US","USA","New York","","30592","1840000372"
"Puerto de la Cruz","Puerto de la Cruz","28.4167","-16.5500","Spain","ES","ESP","Canary Islands","","30349","1724298488"
"Pokhrām","Pokhram","25.9358","86.1974","India","IN","IND","Bihār","","28861","1356518614"
"Rehli","Rehli","23.6300","79.0800","India","IN","IND","Madhya Pradesh","","30329","1356969347"
"Kirov","Kirov","54.0833","34.3167","Russia","RU","RUS","Kaluzhskaya Oblast’","","30520","1643587873"
"Draveil","Draveil","48.6852","2.4080","France","FR","FRA","Île-de-France","","28741","1250259151"
"Blagodarnyy","Blagodarnyy","45.1000","43.4500","Russia","RU","RUS","Stavropol’skiy Kray","","30530","1643447136"
"Metu","Metu","8.3000","35.5833","Ethiopia","ET","ETH","Oromīya","","28782","1231361169"
"Gorna Oryahovitsa","Gorna Oryahovitsa","43.1333","25.7000","Bulgaria","BG","BGR","Veliko Tarnovo","","30413","1100589506"
"Shāhpura","Shahpura","25.6300","74.9300","India","IN","IND","Rājasthān","","30320","1356989633"
"Kailahun","Kailahun","8.2772","-10.5739","Sierra Leone","SL","SLE","Eastern","","30411","1694287163"
"G’ijduvon Shahri","G'ijduvon Shahri","40.1000","64.6667","Uzbekistan","UZ","UZB","Buxoro","minor","30486","1860839337"
"Rio Preto da Eva","Rio Preto da Eva","-2.6989","-59.7000","Brazil","BR","BRA","Amazonas","","30530","1076575566"
"Asprópyrgos","Aspropyrgos","38.0667","23.5833","Greece","GR","GRC","Attikí","minor","30251","1300057241"
"Nanyō","Nanyo","38.0551","140.1476","Japan","JP","JPN","Yamagata","","30343","1392003388"
"Hellín","Hellin","38.5167","-1.6833","Spain","ES","ESP","Castille-La Mancha","","30492","1724570430"
"Calafell","Calafell","41.2004","1.5693","Spain","ES","ESP","Catalonia","","29102","1724383580"
"Ferry Pass","Ferry Pass","30.5205","-87.1899","United States","US","USA","Florida","","30526","1840013909"
"Ob","Ob","54.9917","82.7125","Russia","RU","RUS","Novosibirskaya Oblast’","","29194","1643161360"
"Gaggenau","Gaggenau","48.8039","8.3194","Germany","DE","DEU","Baden-Württemberg","","30059","1276552610"
"Ar Ruḩaybah","Ar Ruhaybah","33.7436","36.7012","Syria","SY","SYR","Rīf Dimashq","minor","30450","1760100307"
"Askøy","Askoy","60.4667","5.1500","Norway","NO","NOR","Vestland","","29816","1578869945"
"Quillabamba","Quillabamba","-12.8681","-72.6931","Peru","PE","PER","Cusco","","30422","1604109792"
"Bad Hersfeld","Bad Hersfeld","50.8683","9.7075","Germany","DE","DEU","Hesse","minor","30101","1276923242"
"Xinpo","Xinpo","19.7738","110.3658","China","CN","CHN","Hainan","","30318","1156959579"
"Jima Abajo","Jima Abajo","19.1300","-70.3800","Dominican Republic","DO","DOM","Cibao Sur","","30261","1214366277"
"Lalla Mimouna","Lalla Mimouna","34.8500","-6.0669","Morocco","MA","MAR","Rabat-Salé-Kénitra","","29479","1504801532"
"El Fanar","El Fanar","33.8667","35.5667","Lebanon","LB","LBN","Mont-Liban","","30000","1422818939"
"Mukumbura","Mukumbura","-16.2000","31.6833","Zimbabwe","ZW","ZWE","Mashonaland Central","","30470","1716579075"
"Newington","Newington","41.6870","-72.7308","United States","US","USA","Connecticut","","30493","1840035505"
"Pantao-Ragat","Pantao-Ragat","8.0500","124.1500","Philippines","PH","PHL","Lanao del Norte","","30247","1608760882"
"Feteşti","Fetesti","44.4150","27.8236","Romania","RO","ROU","Ialomiţa","","30217","1642433547"
"Afonso Cláudio","Afonso Claudio","-20.0739","-41.1239","Brazil","BR","BRA","Espírito Santo","","30455","1076232395"
"Ono","Ono","35.9797","136.4875","Japan","JP","JPN","Fukui","","30449","1392999992"
"Motema","Motema","8.6167","-11.1667","Sierra Leone","SL","SLE","Eastern","","30452","1694972469"
"Walsrode","Walsrode","52.8667","9.5833","Germany","DE","DEU","Lower Saxony","","30370","1276472764"
"Tchindjendje","Tchindjendje","-12.8167","14.9333","Angola","AO","AGO","Huambo","","30443","1024902504"
"Santa Maria","Santa Maria","17.3719","120.4814","Philippines","PH","PHL","Ilocos Sur","","30006","1608975400"
"Beşiri","Besiri","37.9210","41.2920","Turkey","TR","TUR","Batman","minor","30445","1792978558"
"Slavgorod","Slavgorod","52.9833","78.6667","Russia","RU","RUS","Altayskiy Kray","","29916","1643239330"
"Maguing","Maguing","7.9000","124.4000","Philippines","PH","PHL","Lanao del Sur","","30436","1608901118"
"Kórinthos","Korinthos","37.9386","22.9272","Greece","GR","GRC","Pelopónnisos","minor","30176","1300348183"
"Tskhinvali","Tskhinvali","42.2257","43.9701","Georgia","GE","GEO","Shida Kartli","","30432","1268142653"
"Itaporanga d’Ajuda","Itaporanga d'Ajuda","-10.9978","-37.3108","Brazil","BR","BRA","Sergipe","","30419","1076537619"
"Bandar-e Lengeh","Bandar-e Lengeh","26.5581","54.8806","Iran","IR","IRN","Hormozgān","minor","30435","1364154507"
"Ash Shinān","Ash Shinan","27.1782","42.4430","Saudi Arabia","SA","SAU","Ḩā’il","","30441","1682426795"
"Aarschot","Aarschot","50.9842","4.8333","Belgium","BE","BEL","Flanders","","29965","1056740173"
"Ypacaraí","Ypacarai","-25.3833","-57.2667","Paraguay","PY","PRY","Central","","30150","1600844536"
"Kalpatta","Kalpatta","11.6088","76.0814","India","IN","IND","Kerala","","29602","1356102360"
"Ust’-Dzheguta","Ust'-Dzheguta","44.0872","41.9733","Russia","RU","RUS","Karachayevo-Cherkesiya","","30369","1643267187"
"Friedberg","Friedberg","48.3500","10.9833","Germany","DE","DEU","Bavaria","","30056","1276408320"
"Sali","Sali","14.4383","-17.0125","Senegal","SN","SEN","Thiès","","30000","1686109073"
"Bālā Kōh","Bala Koh","36.5753","66.8203","Afghanistan","AF","AFG","Balkh","","30419","1004171817"
"Horki","Horki","54.2667","30.9833","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","30390","1112565326"
"Balungao","Balungao","15.9000","120.7000","Philippines","PH","PHL","Pangasinan","","30004","1608858364"
"Hirakawachō","Hirakawacho","40.5841","140.5665","Japan","JP","JPN","Aomori","","30326","1392839223"
"Mahugaon","Mahugaon","22.5748","75.7445","India","IN","IND","Madhya Pradesh","","30012","1356672951"
"Togitsu","Togitsu","32.8289","129.8486","Japan","JP","JPN","Nagasaki","","29025","1392037686"
"Candijay","Candijay","9.8180","124.4960","Philippines","PH","PHL","Bohol","","30119","1608954791"
"Iguape","Iguape","-24.7081","-47.5553","Brazil","BR","BRA","São Paulo","","30390","1076144584"
"Dalin","Dalin","23.5989","120.4700","Taiwan","TW","TWN","Chiayi","","29937","1158347421"
"Huanta","Huanta","-12.9397","-74.2475","Peru","PE","PER","Ayacucho","","30234","1604179024"
"Télimélé","Telimele","10.9050","-13.0430","Guinea","GN","GIN","Kindia","minor","30311","1324315862"
"Granger","Granger","41.7374","-86.1348","United States","US","USA","Indiana","","30394","1840030079"
"Adilcevaz","Adilcevaz","38.8058","42.7469","Turkey","TR","TUR","Bitlis","minor","30376","1792411490"
"Morristown","Morristown","36.2043","-83.3001","United States","US","USA","Tennessee","","30387","1840014467"
"Weatherford","Weatherford","32.7536","-97.7723","United States","US","USA","Texas","","30385","1840022041"
"Tsuru","Tsuru","35.5515","138.9054","Japan","JP","JPN","Yamanashi","","30193","1392003063"
"Pop","Pop","40.8736","71.1089","Uzbekistan","UZ","UZB","Namangan","","30000","1860055055"
"Koratgi","Koratgi","15.6081","76.6597","India","IN","IND","Karnātaka","","29991","1356316435"
"Dumalag","Dumalag","11.3039","122.6214","Philippines","PH","PHL","Capiz","","30098","1608493161"
"Carlos Barbosa","Carlos Barbosa","-29.2978","-51.5039","Brazil","BR","BRA","Rio Grande do Sul","","30241","1076670550"
"Kafr Nubl","Kafr Nubl","35.6139","36.5611","Syria","SY","SYR","Idlib","minor","30000","1760535722"
"Myōkō","Myoko","37.0252","138.2535","Japan","JP","JPN","Niigata","","30300","1392003161"
"Kālappatti","Kalappatti","11.0794","77.0371","India","IN","IND","Tamil Nādu","","29084","1356160168"
"Madhepur","Madhepur","26.1775","86.3783","India","IN","IND","Bihār","","28894","1356584341"
"Abū Şuwayr","Abu Suwayr","30.5633","32.1155","Egypt","EG","EGY","Ash Sharqīyah","","30320","1818806418"
"Villa Comaltitlán","Villa Comaltitlan","15.1667","-92.6167","Mexico","MX","MEX","Chiapas","minor","30297","1484763836"
"Pittsford","Pittsford","43.0733","-77.5268","United States","US","USA","New York","","30364","1840004283"
"Rotterdam","Rotterdam","42.8133","-74.0129","United States","US","USA","New York","","30363","1840058454"
"Al Madad","Al Madad","13.7167","44.7333","Yemen","YE","YEM","Aḑ Ḑāli‘","","29853","1887543762"
"Candelaria","Candelaria","15.6333","119.9333","Philippines","PH","PHL","Zambales","","30263","1608679847"
"Graneros","Graneros","-34.0647","-70.7264","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","30320","1152651446"
"Bretten","Bretten","49.0364","8.7061","Germany","DE","DEU","Baden-Württemberg","","29927","1276638008"
"Balete","Balete","11.5553","122.3792","Philippines","PH","PHL","Aklan","","30090","1608488735"
"Shetang","Shetang","34.5568","105.9722","China","CN","CHN","Gansu","","29811","1156068799"
"Daheba","Daheba","28.0259","106.4134","China","CN","CHN","Guizhou","","30197","1156120096"
"Iguig","Iguig","17.7517","121.7364","Philippines","PH","PHL","Cagayan","","30060","1608362919"
"Northport","Northport","33.2586","-87.5993","United States","US","USA","Alabama","","30334","1840014819"
"Galdácano","Galdacano","43.2306","-2.8458","Spain","ES","ESP","Basque Country","","29404","1724891889"
"Chimichagua","Chimichagua","9.2500","-73.8167","Colombia","CO","COL","Cesar","minor","30303","1170174620"
"Salay","Salay","8.8667","124.8000","Philippines","PH","PHL","Misamis Oriental","","29998","1608139757"
"Garaimāri","Garaimari","24.0217","88.6263","India","IN","IND","Bihār","","29260","1356122000"
"Anse à Pitre","Anse a Pitre","18.0500","-71.7500","Haiti","HT","HTI","Sud-Est","","30146","1332458305"
"Souk et Tnine Jorf el Mellah","Souk et Tnine Jorf el Mellah","34.4833","-5.5169","Morocco","MA","MAR","Rabat-Salé-Kénitra","","28681","1504332951"
"Kondopoga","Kondopoga","62.2000","34.2833","Russia","RU","RUS","Kareliya","","30299","1643955723"
"Talwāra","Talwara","31.9376","75.8866","India","IN","IND","Punjab","","29797","1356415625"
"Jāmtāra","Jamtara","23.9500","86.8000","India","IN","IND","Jhārkhand","","29776","1356076405"
"Lawrenceville","Lawrenceville","33.9523","-83.9932","United States","US","USA","Georgia","","30299","1840014745"
"Mannārgudi","Mannargudi","11.2761","79.5520","India","IN","IND","Tamil Nādu","","29602","1356233950"
"Galesburg","Galesburg","40.9506","-90.3763","United States","US","USA","Illinois","","30298","1840008277"
"Pānakkudi","Panakkudi","8.3492","77.5672","India","IN","IND","Tamil Nādu","","29895","1356098939"
"Xunjiansi","Xunjiansi","23.9620","103.1925","China","CN","CHN","Yunnan","","30159","1156252865"
"Māgadi","Magadi","12.9700","77.2300","India","IN","IND","Karnātaka","","30000","1356879362"
"Las Nieves","Las Nieves","8.7351","125.6010","Philippines","PH","PHL","Agusan del Norte","","30240","1608365860"
"Wallkill","Wallkill","41.4854","-74.3939","United States","US","USA","New York","","30291","1840024229"
"Kibiti","Kibiti","-7.7296","38.9500","Tanzania","TZ","TZA","Coast","","30163","1834522080"
"Gukeng","Gukeng","23.6500","120.5667","Taiwan","TW","TWN","Yunlin","","30105","1158248475"
"Cihuatlán","Cihuatlan","19.2500","-104.5667","Mexico","MX","MEX","Jalisco","minor","30241","1484019808"
"Dauin","Dauin","9.2000","123.2667","Philippines","PH","PHL","Negros Oriental","","30018","1608545503"
"Kuah","Kuah","6.3167","99.8500","Malaysia","MY","MYS","Kedah","","30000","1458485956"
"Valdepeñas","Valdepenas","38.7667","-3.4000","Spain","ES","ESP","Castille-La Mancha","","30218","1724776859"
"Gūdalūr","Gudalur","11.1455","76.9366","India","IN","IND","Tamil Nādu","","28995","1356104055"
"Kombissiri","Kombissiri","12.0667","-1.3333","Burkina Faso","BF","BFA","Centre-Sud","minor","30137","1854637161"
"Vinces","Vinces","-1.5500","-79.7333","Ecuador","EC","ECU","Los Ríos","","30241","1218211679"
"Dayr Abū Sa‘īd","Dayr Abu Sa\`id","32.5025","35.6922","Jordan","JO","JOR","Irbid","minor","29590","1400352428"
"Jinka","Jinka","5.7833","36.5667","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","30249","1231671120"
"Navāpur","Navapur","21.1700","73.7800","India","IN","IND","Mahārāshtra","","29979","1356890324"
"Calintaan","Calintaan","12.5756","120.9428","Philippines","PH","PHL","Occidental Mindoro","","30190","1608559951"
"Temse","Temse","51.1167","4.2000","Belgium","BE","BEL","Flanders","","29528","1056178805"
"Friedberg","Friedberg","50.3333","8.7500","Germany","DE","DEU","Hesse","minor","29674","1276800921"
"Quezaltepeque","Quezaltepeque","14.6333","-89.4500","Guatemala","GT","GTM","Chiquimula","minor","30136","1320647000"
"Salāya","Salaya","22.3200","69.6000","India","IN","IND","Gujarāt","","30000","1356118174"
"Augustów","Augustow","53.8436","22.9794","Poland","PL","POL","Podlaskie","minor","30153","1616778386"
"Lewe","Lewe","19.6333","96.1167","Myanmar","MM","MMR","Nay Pyi Taw","","30208","1104486750"
"Zionsville","Zionsville","39.9897","-86.3182","United States","US","USA","Indiana","","30258","1840010515"
"Matsubushi","Matsubushi","35.9258","139.8152","Japan","JP","JPN","Saitama","","28494","1392883192"
"Gorodets","Gorodets","56.6500","43.4833","Russia","RU","RUS","Nizhegorodskaya Oblast’","","30188","1643700852"
"Socorro","Socorro","6.5333","-73.2000","Colombia","CO","COL","Santander","minor","29997","1170793746"
"Budaörs","Budaors","47.4607","18.9580","Hungary","HU","HUN","Pest","","29018","1348705618"
"Highland Park","Highland Park","42.1823","-87.8104","United States","US","USA","Illinois","","30245","1840006973"
"Trutnov","Trutnov","50.5606","15.9131","Czechia","CZ","CZE","Královéhradecký Kraj","","29958","1203787150"
"Padada","Padada","6.6333","125.3500","Philippines","PH","PHL","Davao del Sur","","29878","1608991234"
"Sue","Sue","33.5872","130.5072","Japan","JP","JPN","Fukuoka","","28489","1392341153"
"Petite Rivière de Nippes","Petite Riviere de Nippes","18.4833","-73.2500","Haiti","HT","HTI","Nippes","","29815","1332459442"
"Pinhão","Pinhao","-25.6958","-51.6600","Brazil","BR","BRA","Paraná","","30208","1076271089"
"Bergen","Bergen","52.6667","4.7167","Netherlands","NL","NLD","Noord-Holland","","29974","1528106849"
"Piagapo","Piagapo","8.0000","124.2000","Philippines","PH","PHL","Lanao del Sur","","30132","1608525630"
"Mozhaysk","Mozhaysk","55.5167","36.0333","Russia","RU","RUS","Moskovskaya Oblast’","","30190","1643344419"
"Shangpa","Shangpa","26.9052","98.8679","China","CN","CHN","Yunnan","minor","30129","1156190291"
"‘Aynkāwah","\`Aynkawah","36.2292","43.9936","Iraq","IQ","IRQ","Arbīl","minor","30000","1368335955"
"Qarataū","Qaratau","43.1667","70.4500","Kazakhstan","KZ","KAZ","","","30204","1398932329"
"Candelária","Candelaria","-29.6689","-52.7889","Brazil","BR","BRA","Rio Grande do Sul","","30171","1076542930"
"Yoshida","Yoshida","34.7709","138.2520","Japan","JP","JPN","Shizuoka","","28811","1392003313"
"Marogong","Marogong","7.6667","124.1500","Philippines","PH","PHL","Lanao del Sur","","30118","1608554059"
"Zaragoza","Zaragoza","14.6498","-90.8902","Guatemala","GT","GTM","Chimaltenango","minor","29668","1320225440"
"Rendsburg","Rendsburg","54.3000","9.6667","Germany","DE","DEU","Schleswig-Holstein","minor","28977","1276159889"
"Neuburg","Neuburg","48.7333","11.1833","Germany","DE","DEU","Bavaria","minor","29830","1276560116"
"Tameslouht","Tameslouht","31.5000","-8.1000","Morocco","MA","MAR","Marrakech-Safi","","28978","1504936367"
"Kottūru","Kotturu","14.8262","76.2259","India","IN","IND","Karnātaka","","29965","1356041615"
"Northfleet","Northfleet","51.4400","0.3400","United Kingdom","GB","GBR","Kent","","29474","1826633103"
"Hafnarfjörður","Hafnarfjordhur","64.0200","-21.9700","Iceland","IS","ISL","Hafnarfjörður","","29974","1352845977"
"Dahana","Dahana","38.0583","69.8667","Tajikistan","TJ","TJK","Khatlon","","29776","1762626270"
"Agde","Agde","43.3108","3.4758","France","FR","FRA","Occitanie","","29600","1250539086"
"LaSalle","LaSalle","42.2167","-83.0667","Canada","CA","CAN","Ontario","","30180","1124000988"
"Austintown","Austintown","41.0932","-80.7405","United States","US","USA","Ohio","","30179","1840005185"
"Makato","Makato","11.7120","122.2922","Philippines","PH","PHL","Aklan","","29717","1608336520"
"Greenville","Greenville","33.3850","-91.0514","United States","US","USA","Mississippi","","30175","1840013759"
"Plympton","Plympton","50.3860","-4.0510","United Kingdom","GB","GBR","Plymouth","","29899","1826807244"
"Birūr","Birur","13.5972","75.9717","India","IN","IND","Karnātaka","","29966","1356646935"
"Tizi Gheniff","Tizi Gheniff","36.5891","3.7678","Algeria","DZ","DZA","Boumerdes","","29409","1012794312"
"Crevillente","Crevillente","38.2486","-0.8089","Spain","ES","ESP","Valencia","","29881","1724275111"
"Ikoto","Ikoto","4.0783","33.1089","South Sudan","SS","SSD","Eastern Equatoria","","30000","1728694229"
"El Kseur","El Kseur","36.6844","4.8522","Algeria","DZ","DZA","Bejaïa","","29842","1012893043"
"Salem","Salem","42.7902","-71.2202","United States","US","USA","New Hampshire","","30158","1840055510"
"Les Palmes","Les Palmes","18.3333","-72.8500","Haiti","HT","HTI","Sud-Est","","30000","1332456322"
"Guotang","Guotang","23.8414","115.9157","China","CN","CHN","Guangdong","","29315","1156512586"
"Taşova","Tasova","40.7500","36.3167","Turkey","TR","TUR","Amasya","minor","30123","1792881496"
"Paraipaba","Paraipaba","-3.4389","-39.1478","Brazil","BR","BRA","Ceará","","30041","1076816341"
"Bra","Bra","44.7000","7.8500","Italy","IT","ITA","Piedmont","","29645","1380694360"
"Planadas","Planadas","3.1964","-75.6444","Colombia","CO","COL","Tolima","minor","30117","1170444880"
"Milford","Milford","42.1565","-71.5188","United States","US","USA","Massachusetts","","30134","1840053594"
"Mathba","Mathba","22.3000","89.9500","Bangladesh","BD","BGD","Barishal","","28851","1050564750"
"Liberty","Liberty","39.2394","-94.4191","United States","US","USA","Missouri","","30129","1840008502"
"Miramar","Miramar","-38.2667","-57.8333","Argentina","AR","ARG","Buenos Aires","minor","30100","1032791086"
"Tabio","Tabio","4.9158","-74.0983","Colombia","CO","COL","Cundinamarca","minor","29731","1170060372"
"Brodnica","Brodnica","53.2597","19.3956","Poland","PL","POL","Kujawsko-Pomorskie","minor","28880","1616831249"
"Xiaba","Xiaba","27.8825","108.1017","China","CN","CHN","Guizhou","","29848","1156427860"
"Mucari","Mucari","-9.4667","16.9167","Angola","AO","AGO","Malanje","","30112","1024127704"
"Mīnūdasht","Minudasht","37.2289","55.3747","Iran","IR","IRN","Golestān","minor","30085","1364374243"
"Agno","Agno","16.1161","119.8027","Philippines","PH","PHL","Pangasinan","","29947","1608574609"
"Sottaiyampālaiyam","Sottaiyampalaiyam","11.4053","77.6794","India","IN","IND","Tamil Nādu","","28585","1356168854"
"Conguaco","Conguaco","14.0470","-90.0336","Guatemala","GT","GTM","Jutiapa","minor","29887","1320354333"
"Du Yar","Du Yar","15.2617","97.7819","Myanmar","MM","MMR","Mon State","","30000","1104089904"
"L’Arbaa Naït Irathen","L'Arbaa Nait Irathen","36.6367","4.2067","Algeria","DZ","DZA","Tizi Ouzou","","29376","1012117061"
"Motala","Motala","58.5333","15.0333","Sweden","SE","SWE","Östergötland","minor","29823","1752105097"
"Krishnarājpet","Krishnarajpet","12.6662","76.4877","India","IN","IND","Karnātaka","","29805","1356263747"
"Sainte-Julie","Sainte-Julie","45.5833","-73.3333","Canada","CA","CAN","Quebec","","30104","1124000521"
"Shibancun","Shibancun","22.1539","110.7082","China","CN","CHN","Guangdong","","29812","1156653418"
"Barbosa","Barbosa","5.9330","-73.6210","Colombia","CO","COL","Santander","minor","29581","1170376059"
"Placer","Placer","9.6570","125.6016","Philippines","PH","PHL","Surigao del Norte","","29616","1608949184"
"Hakmana","Hakmana","6.0796","80.6577","Sri Lanka","LK","LKA","Southern","","28941","1144021831"
"Landhaura","Landhaura","29.8200","77.9300","India","IN","IND","Uttarākhand","","28786","1356731751"
"Bontoc","Bontoc","10.3500","124.9667","Philippines","PH","PHL","Southern Leyte","","29799","1608980422"
"Shubrākhīt","Shubrakhit","31.0275","30.7128","Egypt","EG","EGY","Al Buḩayrah","","28505","1818459935"
"Ragan Sur","Ragan Sur","17.3167","121.7833","Philippines","PH","PHL","Isabela","","29928","1608589574"
"Yong’ancun","Yong'ancun","23.1788","112.6904","China","CN","CHN","Guangdong","","29732","1156571191"
"Nattam","Nattam","10.2249","78.2310","India","IN","IND","Tamil Nādu","","29759","1356227042"
"Pervomaiskyi","Pervomaiskyi","49.3869","36.2142","Ukraine","UA","UKR","Kharkivska Oblast","","30048","1804863173"
"Bugarama","Bugarama","-2.6972","29.0083","Rwanda","RW","RWA","Western Province","","28810","1646110055"
"San Roque","San Roque","12.5330","124.8670","Philippines","PH","PHL","Northern Samar","","29882","1608667043"
"Condega","Condega","13.3617","-86.3972","Nicaragua","NI","NIC","Estelí","minor","30000","1558543389"
"Mundi","Mundi","22.0700","76.5000","India","IN","IND","Madhya Pradesh","","30000","1356854133"
"Selargius","Selargius","39.2537","9.1606","Italy","IT","ITA","Sardegna","","28986","1380323668"
"São Luís de Montes Belos","Sao Luis de Montes Belos","-16.5250","-50.3719","Brazil","BR","BRA","Goiás","","30034","1076530689"
"Soledade","Soledade","-28.8178","-52.5100","Brazil","BR","BRA","Rio Grande do Sul","","30044","1076105389"
"Heesch","Heesch","51.7333","5.5333","Netherlands","NL","NLD","Noord-Brabant","minor","29728","1528844626"
"Sheohar","Sheohar","26.5200","85.3000","India","IN","IND","Bihār","","28116","1356092731"
"Uychi","Uychi","41.0294","71.8483","Uzbekistan","UZ","UZB","Namangan","","29683","1860657866"
"Ambalavao","Ambalavao","-21.8333","46.9333","Madagascar","MG","MDG","Fianarantsoa","","30000","1450812103"
"Nelson","Nelson","53.8346","-2.2180","United Kingdom","GB","GBR","Lancashire","","29135","1826818117"
"Tacuba","Tacuba","13.9000","-89.9333","El Salvador","SV","SLV","Ahuachapán","","29858","1222693059"
"Meschede","Meschede","51.3500","8.2833","Germany","DE","DEU","North Rhine-Westphalia","minor","29921","1276139753"
"Waltrop","Waltrop","51.6167","7.3833","Germany","DE","DEU","North Rhine-Westphalia","","29429","1276333081"
"Irará","Irara","-12.0500","-38.7669","Brazil","BR","BRA","Bahia","","29950","1076945010"
"Villa Regina","Villa Regina","-39.1000","-67.0667","Argentina","AR","ARG","Río Negro","","30028","1032583314"
"Culemborg","Culemborg","51.9500","5.2333","Netherlands","NL","NLD","Gelderland","minor","29121","1528861210"
"Saint-Étienne-du-Rouvray","Saint-Etienne-du-Rouvray","49.3786","1.1050","France","FR","FRA","Normandie","","28500","1250043757"
"Hengchun","Hengchun","22.0000","120.7500","Taiwan","TW","TWN","Pingtung","","29835","1158823887"
"Gongyefu","Gongyefu","41.8378","118.5011","China","CN","CHN","Inner Mongolia","","30000","1156901662"
"Chikuzen","Chikuzen","33.4570","130.5952","Japan","JP","JPN","Fukuoka","","29608","1392999988"
"Malème Hodar","Maleme Hodar","14.0883","-15.2944","Senegal","SN","SEN","Kaffrine","","30000","1686577434"
"Werne an der Lippe","Werne an der Lippe","51.6667","7.6167","Germany","DE","DEU","North Rhine-Westphalia","","29662","1276066567"
"Saalfeld","Saalfeld","50.6500","11.3667","Germany","DE","DEU","Thuringia","minor","29457","1276907261"
"Campo Alegre de Lourdes","Campo Alegre de Lourdes","-9.5158","-43.0108","Brazil","BR","BRA","Bahia","","29938","1076811887"
"Canosa di Puglia","Canosa di Puglia","41.2167","16.0667","Italy","IT","ITA","Puglia","","29847","1380837242"
"Jalawlā’","Jalawla'","34.2719","45.1681","Iraq","IQ","IRQ","Diyālá","","30000","1368851231"
"Nan’ao","Nan'ao","38.5162","114.5648","China","CN","CHN","Hebei","","29508","1156037095"
"Ambinanitelo","Ambinanitelo","-15.3500","49.5833","Madagascar","MG","MDG","Toamasina","","30000","1450149490"
"Ibrāhīmpatnam","Ibrahimpatnam","16.6056","80.3786","India","IN","IND","Andhra Pradesh","","29432","1356450270"
"Degeh Bur","Degeh Bur","8.2167","43.5667","Ethiopia","ET","ETH","Sumalē","","30027","1231855349"
"Devarkonda","Devarkonda","16.6919","78.9207","India","IN","IND","Andhra Pradesh","","29731","1356060197"
"Sāho","Saho","25.9718","86.1537","India","IN","IND","Bihār","","28306","1356535689"
"Andranomanelatra","Andranomanelatra","-19.7833","47.1000","Madagascar","MG","MDG","Antananarivo","","30000","1450464375"
"Casma","Casma","-9.4742","-78.3106","Peru","PE","PER","Ancash","","30000","1604136455"
"Ārda","Arda","32.3087","76.5772","India","IN","IND","Himāchal Pradesh","","30006","1356118283"
"Sanford","Sanford","35.4875","-79.1770","United States","US","USA","North Carolina","","30024","1840015421"
"Santa Rita","Santa Rita","14.8667","-89.1000","Honduras","HN","HND","Copán","","29927","1340516072"
"Buenavista","Buenavista","10.0833","124.1167","Philippines","PH","PHL","Bohol","","29711","1608981835"
"Merzig","Merzig","49.4500","6.6167","Germany","DE","DEU","Saarland","minor","29745","1276524998"
"Bourgoin-Jallieu","Bourgoin-Jallieu","45.5861","5.2736","France","FR","FRA","Auvergne-Rhône-Alpes","","28834","1250584408"
"Monterey","Monterey","36.5919","-121.8808","United States","US","USA","California","","30014","1840020358"
"Pitanga","Pitanga","-24.7569","-51.7608","Brazil","BR","BRA","Paraná","","29994","1076485533"
"Uar Esgudud","Uar Esgudud","1.2667","41.3833","Somalia","SO","SOM","Gedo","","30000","1706563157"
"Baxdo","Baxdo","5.7889","47.2267","Somalia","SO","SOM","Galguduud","","30000","1706255273"
"Sabanilla","Sabanilla","17.3167","-92.6167","Mexico","MX","MEX","Chiapas","minor","29889","1484304523"
"Carpentras","Carpentras","44.0558","5.0489","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","29236","1250619476"
"Bourèm Guindou","Bourem Guindou","16.9004","-0.3500","Mali","ML","MLI","Gao","","30000","1466124401"
"Tsitondroina","Tsitondroina","-21.3000","45.9833","Madagascar","MG","MDG","Fianarantsoa","","30000","1450441433"
"Asipovichy","Asipovichy","53.2933","28.4756","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","29981","1112583247"
"Evaz","Evaz","27.7600","54.0072","Iran","IR","IRN","Fārs","","29987","1364440193"
"Vernon","Vernon","41.8364","-72.4606","United States","US","USA","Connecticut","","30002","1840035637"
"East Lake-Orient Park","East Lake-Orient Park","27.9970","-82.3653","United States","US","USA","Florida","","30002","1840073826"
"Pitou","Pitou","23.8775","120.4706","Taiwan","TW","TWN","Changhua","","29314","1158054057"
"Kelkheim (Taunus)","Kelkheim (Taunus)","50.1378","8.4497","Germany","DE","DEU","Hesse","","29055","1276386467"
"San Lucas Sacatepéquez","San Lucas Sacatepequez","14.6095","-90.6568","Guatemala","GT","GTM","Sacatepéquez","minor","28819","1320964594"
"Leduc","Leduc","53.2594","-113.5492","Canada","CA","CAN","Alberta","","29993","1124170853"
"Balabagan","Balabagan","7.5333","124.1167","Philippines","PH","PHL","Lanao del Sur","","29863","1608273813"
"Dala","Dala","-11.0342","20.2036","Angola","AO","AGO","Lunda-Sul","","29991","1024514204"
"Sint-Michielsgestel","Sint-Michielsgestel","51.6433","5.3586","Netherlands","NL","NLD","Noord-Brabant","minor","29498","1528884798"
"Southgate","Southgate","42.2047","-83.2057","United States","US","USA","Michigan","","29990","1840003964"
"Murlīganj","Murliganj","25.9000","86.9800","India","IN","IND","Bihār","","28691","1356124802"
"Hengshuicun","Hengshuicun","22.3674","111.4105","China","CN","CHN","Guangdong","","29777","1156573295"
"Arlon","Arlon","49.6833","5.8167","Belgium","BE","BEL","Wallonia","minor","29733","1056746428"
"Eastern Goleta Valley","Eastern Goleta Valley","34.4448","-119.7879","United States","US","USA","California","","29983","1840153163"
"Pattikonda","Pattikonda","15.4000","77.5167","India","IN","IND","Andhra Pradesh","","29342","1356859765"
"Santana do Acaraú","Santana do Acarau","-3.4608","-40.2119","Brazil","BR","BRA","Ceará","","29946","1076356639"
"Izegem","Izegem","50.9172","3.2150","Belgium","BE","BEL","Flanders","","28845","1056662336"
"Esmeralda","Esmeralda","21.8561","-78.1114","Cuba","CU","CUB","Camagüey","minor","29953","1192770557"
"Tönisvorst","Tonisvorst","51.3208","6.4931","Germany","DE","DEU","North Rhine-Westphalia","","29306","1276126819"
"Carinhanha","Carinhanha","-14.3050","-43.7650","Brazil","BR","BRA","Bahia","","29955","1076985743"
"Vyshhorod","Vyshhorod","50.5833","30.4853","Ukraine","UA","UKR","Kyivska Oblast","minor","29913","1804392856"
"Giannitsá","Giannitsa","40.7833","22.4000","Greece","GR","GRC","Kentrikí Makedonía","minor","29789","1300157319"
"Allūru","Alluru","15.1317","78.5131","India","IN","IND","Andhra Pradesh","","29789","1356074737"
"Pignon","Pignon","19.3333","-72.1167","Haiti","HT","HTI","Nord","","29327","1332052953"
"Paripiranga","Paripiranga","-10.6878","-37.8619","Brazil","BR","BRA","Bahia","","29878","1076083044"
"Oosterend","Oosterend","53.0036","6.0664","Netherlands","NL","NLD","Fryslân","","29812","1528797070"
"Puente-Genil","Puente-Genil","37.3833","-4.7667","Spain","ES","ESP","Andalusia","","29767","1724654674"
"Fresno","Fresno","5.1556","-75.0403","Colombia","CO","COL","Tolima","minor","29794","1170216002"
"Schwelm","Schwelm","51.2667","7.2667","Germany","DE","DEU","North Rhine-Westphalia","minor","28542","1276363762"
"San Jacinto","San Jacinto","12.5683","123.7336","Philippines","PH","PHL","Masbate","","29686","1608975961"
"Santiago de Baney","Santiago de Baney","3.7000","8.9167","Equatorial Guinea","GQ","GNQ","Bioko Norte","","29366","1226687832"
"Zamboanguita","Zamboanguita","9.1000","123.1990","Philippines","PH","PHL","Negros Oriental","","29569","1608790324"
"Benito Soliven","Benito Soliven","16.9833","121.9500","Philippines","PH","PHL","Isabela","","29752","1608904633"
"Dzyarzhynsk","Dzyarzhynsk","53.6833","27.1333","Belarus","BY","BLR","Minskaya Voblasts’","","29863","1112600874"
"General Alvear","General Alvear","-34.9667","-67.7000","Argentina","AR","ARG","Mendoza","minor","29909","1032632724"
"Casa Branca","Casa Branca","-21.7739","-47.0858","Brazil","BR","BRA","São Paulo","","29877","1076748418"
"San Rafael","San Rafael","20.1889","-96.8658","Mexico","MX","MEX","Veracruz","minor","29631","1484480100"
"Ancón","Ancon","8.9700","-79.5500","Panama","PA","PAN","Panamá","minor","29761","1591827249"
"Meißen","Meissen","51.1667","13.4833","Germany","DE","DEU","Saxony","minor","28964","1276054483"
"Mandalī","Mandali","33.7333","45.5500","Iraq","IQ","IRQ","Diyālá","","29882","1368763045"
"Inagawa","Inagawa","34.8951","135.3761","Japan","JP","JPN","Hyōgo","","29571","1392003473"
"Zhangguzhuang","Zhangguzhuang","38.0584","115.2891","China","CN","CHN","Hebei","","29079","1156677576"
"Chamblee","Chamblee","33.8842","-84.3007","United States","US","USA","Georgia","","29894","1840014775"
"Raytown","Raytown","38.9944","-94.4641","United States","US","USA","Missouri","","29893","1840009681"
"Villa Dolores","Villa Dolores","-31.9333","-65.2000","Argentina","AR","ARG","Córdoba","","29854","1032787286"
"Leonding","Leonding","48.2792","14.2528","Austria","AT","AUT","Oberösterreich","","28698","1040991026"
"Pārdi","Pardi","20.5200","72.9500","India","IN","IND","Gujarāt","","28495","1356060001"
"Xalatlaco","Xalatlaco","19.1811","-99.4164","Mexico","MX","MEX","México","minor","29572","1484277337"
"Aral","Aral","46.7833","61.6667","Kazakhstan","KZ","KAZ","","","29887","1398109811"
"Tequisquiapan","Tequisquiapan","20.5206","-99.8958","Mexico","MX","MEX","Querétaro","minor","29799","1484303338"
"Villa Gesell","Villa Gesell","-37.2556","-56.9681","Argentina","AR","ARG","Buenos Aires","minor","29593","1032960969"
"Mocajuba","Mocajuba","-2.5839","-49.5069","Brazil","BR","BRA","Pará","","29846","1076949250"
"Nepānagar","Nepanagar","21.4558","76.4494","India","IN","IND","Madhya Pradesh","","29682","1356116829"
"Carney","Carney","39.4050","-76.5235","United States","US","USA","Maryland","","29873","1840005678"
"San Luis","San Luis","15.7167","121.5167","Philippines","PH","PHL","Aurora","","29824","1608491560"
"Algonquin","Algonquin","42.1629","-88.3159","United States","US","USA","Illinois","","29869","1840010110"
"Changzhi","Changzhi","22.6833","120.5333","Taiwan","TW","TWN","Pingtung","","29138","1158049266"
"Kınık","Kinik","39.0939","27.3767","Turkey","TR","TUR","İzmir","minor","29803","1792776515"
"Savanette","Savanette","18.6833","-72.0500","Haiti","HT","HTI","Ouest","","29717","1332721450"
"Vaihingen an der Enz","Vaihingen an der Enz","48.9328","8.9564","Germany","DE","DEU","Baden-Württemberg","","29467","1276306973"
"N’Gaous","N'Gaous","35.5550","5.6106","Algeria","DZ","DZA","Batna","","29504","1012978553"
"Capim","Capim","-1.6750","-47.7750","Brazil","BR","BRA","Pará","","29846","1076189438"
"Lākheri","Lakheri","25.6700","76.1700","India","IN","IND","Rājasthān","","29572","1356081599"
"Heywood","Heywood","53.5900","-2.2190","United Kingdom","GB","GBR","Rochdale","","28205","1826591970"
"Comiso","Comiso","36.9500","14.6000","Italy","IT","ITA","Sicilia","","29845","1380243758"
"Olocuilta","Olocuilta","13.5667","-89.1167","El Salvador","SV","SLV","La Paz","","29529","1222567022"
"Kabalo","Kabalo","-6.0500","26.9167","Congo (Kinshasa)","CD","COD","Tanganyika","","29833","1180905835"
"Hosdurga","Hosdurga","13.7963","76.2841","India","IN","IND","Karnātaka","","29684","1356933529"
"Landsberg","Landsberg","48.0478","10.8989","Germany","DE","DEU","Bavaria","minor","29344","1276208782"
"Purificación","Purificacion","3.8667","-74.9333","Colombia","CO","COL","Tolima","minor","29777","1170787107"
"Dedovsk","Dedovsk","55.8500","37.1167","Russia","RU","RUS","Moskovskaya Oblast’","","29738","1643962207"
"Rota","Rota","36.6167","-6.3500","Spain","ES","ESP","Andalusia","","29491","1724152240"
"Mbanga","Mbanga","4.5092","9.5681","Cameroon","CM","CMR","Littoral","","29732","1120507574"
"Nauhata","Nauhata","25.9971","86.4887","India","IN","IND","Bihār","","28687","1356847951"
"Rietberg","Rietberg","51.8000","8.4333","Germany","DE","DEU","North Rhine-Westphalia","","29564","1276651074"
"Mahón","Mahon","39.8894","4.2642","Spain","ES","ESP","Balearic Islands","","29578","1724771449"
"Antsampandrano","Antsampandrano","-19.9167","47.5667","Madagascar","MG","MDG","Antananarivo","","29788","1450559854"
"Pasuquin","Pasuquin","18.3342","120.6183","Philippines","PH","PHL","Ilocos Norte","","29678","1608946484"
"Nanga Eboko","Nanga Eboko","4.6708","12.3731","Cameroon","CM","CMR","Centre","","29814","1120540405"
"Bella Vista","Bella Vista","36.4667","-94.2707","United States","US","USA","Arkansas","","29818","1840015287"
"Vadakku Valliyūr","Vadakku Valliyur","8.3833","77.6500","India","IN","IND","Tamil Nādu","","29417","1356766618"
"Swarzędz","Swarzedz","52.4083","17.0714","Poland","PL","POL","Wielkopolskie","","29295","1616602592"
"Pilón","Pilon","19.9053","-77.3208","Cuba","CU","CUB","Granma","minor","29751","1192113201"
"Gloucester","Gloucester","42.6260","-70.6897","United States","US","USA","Massachusetts","","29814","1840000412"
"Hattersheim","Hattersheim","50.0722","8.4864","Germany","DE","DEU","Hesse","","28040","1276209175"
"Crofton","Crofton","39.0144","-76.6800","United States","US","USA","Maryland","","29812","1840005909"
"Jāmai","Jamai","22.1964","78.5919","India","IN","IND","Madhya Pradesh","","29620","1356386792"
"Petrich","Petrich","41.3953","23.2069","Bulgaria","BG","BGR","Blagoevgrad","","29727","1100407427"
"Rhennouch","Rhennouch","33.9300","10.0700","Tunisia","TN","TUN","Gabès","","28051","1788492556"
"Misilmeri","Misilmeri","38.0333","13.4500","Italy","IT","ITA","Sicilia","","29376","1380249040"
"Igarapava","Igarapava","-20.0383","-47.7469","Brazil","BR","BRA","São Paulo","","29727","1076928991"
"Parabiago","Parabiago","45.5583","8.9477","Italy","IT","ITA","Lombardy","","27842","1380735720"
"Flandes","Flandes","4.2833","-74.8000","Colombia","CO","COL","Tolima","minor","29478","1170495426"
"Manjo","Manjo","4.8500","9.8167","Cameroon","CM","CMR","Littoral","","29683","1120935317"
"Artëmovskiy","Artemovskiy","57.3564","61.8711","Russia","RU","RUS","Sverdlovskaya Oblast’","","29754","1643010530"
"Alushta","Alushta","44.6672","34.3978","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","29668","1804773543"
"Rancharia","Rancharia","-22.2289","-50.8928","Brazil","BR","BRA","São Paulo","","29778","1076007180"
"Brecht","Brecht","51.3500","4.6333","Belgium","BE","BEL","Flanders","","29454","1056740797"
"Zaltbommel","Zaltbommel","51.8000","5.2500","Netherlands","NL","NLD","Gelderland","minor","29447","1528934368"
"Santiago do Cacém","Santiago do Cacem","38.0167","-8.7000","Portugal","PT","PRT","Setúbal","minor","29749","1620952831"
"Spinea","Spinea","45.4931","12.1606","Italy","IT","ITA","Veneto","","27909","1380346235"
"Ban Bang Khu Wat","Ban Bang Khu Wat","13.9576","100.4903","Thailand","TH","THA","Pathum Thani","","28349","1764948515"
"Atascadero","Atascadero","35.4827","-120.6858","United States","US","USA","California","","29771","1840019126"
"Alquízar","Alquizar","22.8067","-82.5828","Cuba","CU","CUB","Artemisa","minor","29616","1192960992"
"Calimete","Calimete","22.5339","-80.9097","Cuba","CU","CUB","Matanzas","minor","29736","1192157251"
"Bonou","Bonou","6.9000","2.4500","Benin","BJ","BEN","Ouémé","","29656","1204851057"
"Shuangtian","Shuangtian","22.8640","114.5443","China","CN","CHN","Guangdong","","29276","1156939315"
"Carmo do Paranaíba","Carmo do Paranaiba","-19.0008","-46.3158","Brazil","BR","BRA","Minas Gerais","","29735","1076039872"
"Mangdongshan","Mangdongshan","24.1169","98.3085","China","CN","CHN","Yunnan","","29587","1156290976"
"Labasa","Labasa","-16.4311","179.3703","Fiji","FJ","FJI","Macuata","","27949","1242740801"
"Redondela","Redondela","42.2833","-8.6167","Spain","ES","ESP","Galicia","","29192","1724657198"
"Central","Central","30.5593","-91.0369","United States","US","USA","Louisiana","","29746","1840023139"
"Chiknāyakanhalli","Chiknayakanhalli","13.4161","76.6206","India","IN","IND","Karnātaka","","29553","1356242591"
"Mashan","Mashan","37.0017","111.8933","China","CN","CHN","Shanxi","","29578","1156070641"
"Bayramiç","Bayramic","39.8128","26.6100","Turkey","TR","TUR","Çanakkale","minor","29716","1792600918"
"Atbasar","Atbasar","51.8000","68.3333","Kazakhstan","KZ","KAZ","","minor","29733","1398900162"
"Xinmin","Xinmin","25.4831","104.8416","China","CN","CHN","Guizhou","","29614","1156225975"
"Vattalkundu","Vattalkundu","10.1630","77.7580","India","IN","IND","Tamil Nādu","","29054","1356020072"
"Piraju","Piraju","-23.1936","-49.3839","Brazil","BR","BRA","São Paulo","","29664","1076217227"
"Kitaakita","Kitaakita","40.2260","140.3708","Japan","JP","JPN","Akita","","29696","1392003362"
"Vallentuna","Vallentuna","59.5333","18.0833","Sweden","SE","SWE","Stockholm","minor","29519","1752629377"
"Asha","Asha","55.0000","57.2667","Russia","RU","RUS","Chelyabinskaya Oblast’","","29692","1643056456"
"Bosanska Krupa","Bosanska Krupa","44.8833","16.1500","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","29659","1070449050"
"Ozumba","Ozumba","19.0392","-98.7936","Mexico","MX","MEX","México","minor","29114","1484458348"
"Manticao","Manticao","8.4042","124.2867","Philippines","PH","PHL","Misamis Oriental","","29469","1608455267"
"Paraparaumu","Paraparaumu","-40.9167","175.0167","New Zealand","NZ","NZL","Wellington","","28701","1554166459"
"Licab","Licab","15.5400","120.7619","Philippines","PH","PHL","Nueva Ecija","","29269","1608362580"
"Brunssum","Brunssum","50.9500","5.9667","Netherlands","NL","NLD","Limburg","minor","28103","1528862260"
"South Ubian","South Ubian","5.1833","120.4833","Philippines","PH","PHL","Tawi-Tawi","","29583","1608837264"
"Toksun","Toksun","42.7918","88.6536","China","CN","CHN","Xinjiang","minor","29657","1156067475"
"Sumilao","Sumilao","8.3281","124.9783","Philippines","PH","PHL","Bukidnon","","29531","1608478133"
"Matinhos","Matinhos","-25.8178","-48.5428","Brazil","BR","BRA","Paraná","","29428","1076832060"
"San Agustín Chahal","San Agustin Chahal","15.7500","-89.5667","Guatemala","GT","GTM","Alta Verapaz","","29590","1320935505"
"North Cowichan","North Cowichan","48.8236","-123.7192","Canada","CA","CAN","British Columbia","","29676","1124000052"
"Gotse Delchev","Gotse Delchev","41.5667","23.7333","Bulgaria","BG","BGR","Blagoevgrad","","29585","1100837214"
"Nowy Dwór Mazowiecki","Nowy Dwor Mazowiecki","52.4333","20.7167","Poland","PL","POL","Mazowieckie","minor","28651","1616753471"
"New Smyrna Beach","New Smyrna Beach","29.0249","-80.9652","United States","US","USA","Florida","","29674","1840015073"
"Kumalarang","Kumalarang","7.7478","123.1442","Philippines","PH","PHL","Zamboanga del Sur","","29479","1608494719"
"Winter Park","Winter Park","28.5989","-81.3442","United States","US","USA","Florida","","29672","1840015966"
"Arniquet","Arniquet","18.1500","-73.8833","Haiti","HT","HTI","Sud","","29180","1332082768"
"Orchard Park","Orchard Park","42.7516","-78.7455","United States","US","USA","New York","","29668","1840004389"
"Eagle","Eagle","43.7223","-116.3866","United States","US","USA","Idaho","","29665","1840018642"
"Tournefeuille","Tournefeuille","43.5853","1.3442","France","FR","FRA","Occitanie","","28117","1250628309"
"Banning","Banning","33.9460","-116.8992","United States","US","USA","California","","29664","1840019297"
"Bāglung","Baglung","28.2667","83.6000","Nepal","NP","NPL","","","29360","1524266307"
"Albany","Albany","-35.0228","117.8814","Australia","AU","AUS","Western Australia","","29373","1036047365"
"Dembī Dolo","Dembi Dolo","8.5333","34.8000","Ethiopia","ET","ETH","Oromīya","","29448","1231620291"
"Güstrow","Gustrow","53.7939","12.1764","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","29241","1276582967"
"Ātmakūr","Atmakur","14.6167","79.6167","India","IN","IND","Andhra Pradesh","","29419","1356146231"
"Kottakota","Kottakota","13.6573","78.2625","India","IN","IND","Andhra Pradesh","","29385","1356420637"
"Lavezares","Lavezares","12.5333","124.3333","Philippines","PH","PHL","Northern Samar","","29390","1608921102"
"Juchitepec","Juchitepec","19.0997","-98.8792","Mexico","MX","MEX","México","minor","29436","1484007934"
"Uttamapālaiyam","Uttamapalaiyam","9.8000","77.3333","India","IN","IND","Tamil Nādu","","29050","1356877367"
"Yangshuling","Yangshuling","40.9942","118.8791","China","CN","CHN","Hebei","","29463","1156401993"
"Antsahalava","Antsahalava","-19.5833","47.4667","Madagascar","MG","MDG","Antananarivo","","29556","1450903969"
"Oelde","Oelde","51.8333","8.1500","Germany","DE","DEU","North Rhine-Westphalia","","29326","1276826099"
"Mūdbidri","Mudbidri","12.9101","75.0030","India","IN","IND","Karnātaka","","29431","1356755135"
"Riesa","Riesa","51.3081","13.2939","Germany","DE","DEU","Saxony","","29113","1276381687"
"Lādwa","Ladwa","29.9958","77.0400","India","IN","IND","Haryāna","","28887","1356043870"
"East Windsor","East Windsor","40.2606","-74.5295","United States","US","USA","New Jersey","","29603","1840081654"
"Poás","Poas","10.1060","-84.2475","Costa Rica","CR","CRI","Alajuela","","29199","1188859935"
"Sevenoaks","Sevenoaks","51.2781","0.1874","United Kingdom","GB","GBR","Kent","","29506","1826815474"
"Königsbrunn","Konigsbrunn","48.2689","10.8908","Germany","DE","DEU","Bavaria","","28076","1276917723"
"Sonāmukhi","Sonamukhi","23.3000","87.4200","India","IN","IND","West Bengal","","29085","1356334677"
"Khunti","Khunti","23.0140","85.2724","India","IN","IND","Jhārkhand","","29271","1356123961"
"Garfield Heights","Garfield Heights","41.4199","-81.6038","United States","US","USA","Ohio","","29585","1840000602"
"Winnenden","Winnenden","48.8764","9.3978","Germany","DE","DEU","Baden-Württemberg","","28569","1276828910"
"Bad Zwischenahn","Bad Zwischenahn","53.1836","8.0097","Germany","DE","DEU","Lower Saxony","","29351","1276503429"
"Mahendragarh","Mahendragarh","28.2800","76.1500","India","IN","IND","Haryāna","","29128","1356201517"
"Santo Domingo","Santo Domingo","17.6414","120.4108","Philippines","PH","PHL","Ilocos Sur","","29041","1608638724"
"Figuil","Figuil","9.7667","13.9667","Cameroon","CM","CMR","Nord","","29399","1120977247"
"Udaipur","Udaipur","27.7289","75.4716","India","IN","IND","Rājasthān","","29236","1356373349"
"Garchitorena","Garchitorena","13.8833","123.7000","Philippines","PH","PHL","Camarines Sur","","29436","1608974221"
"Johnston","Johnston","41.8274","-71.5202","United States","US","USA","Rhode Island","","29550","1840106232"
"Chichaoua","Chichaoua","31.5333","-8.7667","Morocco","MA","MAR","Marrakech-Safi","","27869","1504125569"
"Budaka","Budaka","1.0167","33.9450","Uganda","UG","UGA","Tororo","","29100","1800690743"
"Nakrekal","Nakrekal","17.1667","79.4333","India","IN","IND","Andhra Pradesh","","29126","1356926722"
"Ja‘ār","Ja\`ar","13.2231","45.3056","Yemen","YE","YEM","Abyan","minor","29495","1887726452"
"Danville","Danville","40.1426","-87.6111","United States","US","USA","Illinois","","29534","1840007235"
"Takanezawa","Takanezawa","36.6310","139.9865","Japan","JP","JPN","Tochigi","","29123","1392003299"
"Burton","Burton","42.9974","-83.6175","United States","US","USA","Michigan","","29532","1840002945"
"McCandless","McCandless","40.5836","-80.0283","United States","US","USA","Pennsylvania","","29526","1840148208"
"Riihimäki","Riihimaki","60.7333","24.7667","Finland","FI","FIN","Kanta-Häme","minor","29269","1246263977"
"Nazaré","Nazare","-13.0350","-39.0139","Brazil","BR","BRA","Bahia","","29406","1076623776"
"Pueblo Nuevo Viñas","Pueblo Nuevo Vinas","14.2333","-90.4667","Guatemala","GT","GTM","Santa Rosa","minor","29418","1320114174"
"Hobart","Hobart","41.5140","-87.2729","United States","US","USA","Indiana","","29516","1840007093"
"Buenos Aires","Buenos Aires","9.1985","-83.2816","Costa Rica","CR","CRI","Puntarenas","","29454","1188382290"
"Fleming Island","Fleming Island","30.0988","-81.7124","United States","US","USA","Florida","","29505","1840038864"
"Fountain","Fountain","38.6886","-104.6829","United States","US","USA","Colorado","","29495","1840020236"
"Texarkana","Texarkana","33.4360","-93.9959","United States","US","USA","Arkansas","","29492","1840015708"
"Schwandorf","Schwandorf","49.3283","12.1100","Germany","DE","DEU","Bavaria","minor","29254","1276268287"
"Munsan","Munsan","37.8529","126.7848","South Korea","KR","KOR","Gyeonggi","","28880","1410851571"
"Oslob","Oslob","9.5500","123.4000","Philippines","PH","PHL","Cebu","","29264","1608302642"
"Monfalcone","Monfalcone","45.8000","13.5333","Italy","IT","ITA","Friuli Venezia Giulia","","28107","1380849688"
"Fritissa","Fritissa","33.6167","-3.5500","Morocco","MA","MAR","Fès-Meknès","","29460","1504842954"
"Nieuwkoop","Nieuwkoop","52.1833","4.7667","Netherlands","NL","NLD","Zuid-Holland","","29151","1528015076"
"Orlová","Orlova","49.8452","18.4302","Czechia","CZ","CZE","Moravskoslezský Kraj","","28330","1203799110"
"Tōmi","Tomi","36.3594","138.3304","Japan","JP","JPN","Nagano","","29213","1392003077"
"Desenzano del Garda","Desenzano del Garda","45.4689","10.5350","Italy","IT","ITA","Lombardy","","28982","1380888752"
"Maddagiri","Maddagiri","13.6600","77.2100","India","IN","IND","Karnātaka","","29215","1356747611"
"Hisor","Hisor","38.5264","68.5381","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","29100","1762136882"
"Xiaobazi","Xiaobazi","27.3401","105.5020","China","CN","CHN","Guizhou","","29108","1156850268"
"Brāhmana Periya Agrahāram","Brahmana Periya Agraharam","11.3690","77.7063","India","IN","IND","Tamil Nādu","","28324","1356169563"
"Timizart","Timizart","36.8000","4.2667","Algeria","DZ","DZA","Tizi Ouzou","","28996","1012767619"
"East Fishkill","East Fishkill","41.5567","-73.7824","United States","US","USA","New York","","29455","1840058059"
"Rösrath","Rosrath","50.9000","7.1833","Germany","DE","DEU","North Rhine-Westphalia","","28712","1276492557"
"Likino-Dulevo","Likino-Dulevo","55.7167","38.9500","Russia","RU","RUS","Moskovskaya Oblast’","","29376","1643014243"
"Reddish","Reddish","53.4383","-2.1613","United Kingdom","GB","GBR","Stockport","","28052","1826969307"
"Ans","Ans","50.6625","5.5200","Belgium","BE","BEL","Wallonia","","28238","1056084793"
"Poggibonsi","Poggibonsi","43.4667","11.1500","Italy","IT","ITA","Tuscany","","29031","1380606727"
"Bulung’ur Shahri","Bulung'ur Shahri","39.7600","67.2700","Uzbekistan","UZ","UZB","Samarqand","minor","29200","1860452420"
"Phuntsholing","Phuntsholing","26.8500","89.3833","Bhutan","BT","BTN","Chhukha","","27658","1064177612"
"Kavajë","Kavaje","41.1842","19.5619","Albania","AL","ALB","Tiranë","","29354","1008475617"
"Vinaroz","Vinaroz","40.4686","0.4736","Spain","ES","ESP","Valencia","","29120","1724995416"
"Windsor","Windsor","41.8710","-72.6736","United States","US","USA","Connecticut","","29424","1840033738"
"Fridley","Fridley","45.0841","-93.2595","United States","US","USA","Minnesota","","29422","1840007798"
"Gyula","Gyula","46.6500","21.2833","Hungary","HU","HUN","Békés","minor","29308","1348624339"
"Kiskunfélegyháza","Kiskunfelegyhaza","46.7052","19.8500","Hungary","HU","HUN","Bács-Kiskun","minor","29306","1348638728"
"Matthews","Matthews","35.1195","-80.7101","United States","US","USA","North Carolina","","29417","1840016350"
"Kamalāpuram","Kamalapuram","15.3044","76.4765","India","IN","IND","Karnātaka","","28815","1356476518"
"Capim Grosso","Capim Grosso","-11.3808","-40.0128","Brazil","BR","BRA","Bahia","","29346","1076073401"
"Lakewood Ranch","Lakewood Ranch","27.4185","-82.3948","United States","US","USA","Florida","","29408","1840137436"
"Lāmerd","Lamerd","27.3423","53.1768","Iran","IR","IRN","Fārs","minor","29380","1364138178"
"Parsa","Parsa","26.0021","84.5979","India","IN","IND","Bihār","","27644","1356730583"
"Ath","Ath","50.6167","3.7667","Belgium","BE","BEL","Wallonia","minor","29164","1056512490"
"Sexmoan","Sexmoan","14.9333","120.6167","Philippines","PH","PHL","Pampanga","","29076","1608694965"
"Kostomuksha","Kostomuksha","64.5833","30.6000","Russia","RU","RUS","Kareliya","","29381","1643774276"
"Jacksonville","Jacksonville","34.8807","-92.1304","United States","US","USA","Arkansas","","29385","1840014633"
"Ra’s al ‘Ayn","Ra's al \`Ayn","36.8503","40.0706","Syria","SY","SYR","Al Ḩasakah","minor","29347","1760030804"
"Schererville","Schererville","41.4861","-87.4442","United States","US","USA","Indiana","","29381","1840010222"
"Sopó","Sopo","4.9167","-73.9333","Colombia","CO","COL","Cundinamarca","minor","29120","1170179207"
"Northampton","Northampton","42.3266","-72.6745","United States","US","USA","Massachusetts","","29379","1840000451"
"Şūrān","Suran","35.2897","36.7433","Syria","SY","SYR","Ḩamāh","minor","29100","1760512352"
"Benhao","Benhao","18.6122","109.9587","China","CN","CHN","Hainan","","29295","1156834292"
"Shibushi","Shibushi","31.5000","131.0500","Japan","JP","JPN","Kagoshima","","29267","1392003073"
"Erie","Erie","40.0404","-105.0399","United States","US","USA","Colorado","","29367","1840021362"
"Wellesley","Wellesley","42.3043","-71.2855","United States","US","USA","Massachusetts","","29365","1840053560"
"Fitchburg","Fitchburg","42.9859","-89.4255","United States","US","USA","Wisconsin","","29361","1840002905"
"Pālpā","Palpa","27.8667","83.5500","Nepal","NP","NPL","","","29095","1524400922"
"Agui","Agui","34.9329","136.9153","Japan","JP","JPN","Aichi","","28176","1392003310"
"Quivicán","Quivican","22.8247","-82.3558","Cuba","CU","CUB","Mayabeque","minor","29253","1192492613"
"Prainha","Prainha","-1.8000","-53.4800","Brazil","BR","BRA","Pará","","29349","1076355822"
"Matouying","Matouying","39.2922","118.8143","China","CN","CHN","Hebei","","29021","1156172640"
"Hazleton","Hazleton","40.9505","-75.9725","United States","US","USA","Pennsylvania","","29339","1840000753"
"Novaya Usman’","Novaya Usman'","51.6439","39.4103","Russia","RU","RUS","Voronezhskaya Oblast’","","29270","1643539163"
"Kailāras","Kailaras","26.3050","77.6160","India","IN","IND","Madhya Pradesh","","28955","1356003552"
"Paragould","Paragould","36.0555","-90.5149","United States","US","USA","Arkansas","","29327","1840014474"
"Dapa","Dapa","9.7578","126.0528","Philippines","PH","PHL","Surigao del Norte","","29006","1608000744"
"Boxmeer","Boxmeer","51.6500","5.9500","Netherlands","NL","NLD","Noord-Brabant","minor","29065","1528624004"
"Hinunangan","Hinunangan","10.4000","125.2000","Philippines","PH","PHL","Southern Leyte","","29149","1608201687"
"Gragnano","Gragnano","40.6957","14.5154","Italy","IT","ITA","Campania","","28897","1380505289"
"Rāikot","Raikot","30.6500","75.6000","India","IN","IND","Punjab","","28734","1356216914"
"Divnogorsk","Divnogorsk","55.9594","92.3619","Russia","RU","RUS","Krasnoyarskiy Kray","","29246","1643520779"
"Mondragone","Mondragone","41.1000","13.8833","Italy","IT","ITA","Campania","","28797","1380019528"
"Glenville","Glenville","42.8869","-73.9925","United States","US","USA","New York","","29313","1840058129"
"Carmona","Carmona","37.4667","-5.6333","Spain","ES","ESP","Andalusia","","29279","1724616347"
"Orange","Orange","44.1383","4.8097","France","FR","FRA","Provence-Alpes-Côte d’Azur","","28922","1250367364"
"Capitán Bermúdez","Capitan Bermudez","-32.8167","-60.7167","Argentina","AR","ARG","Santa Fe","","29218","1032901343"
"Northeim","Northeim","51.7067","10.0011","Germany","DE","DEU","Lower Saxony","minor","29107","1276938853"
"Namaacha","Namaacha","-25.9667","32.0333","Mozambique","MZ","MOZ","Maputo","","29272","1508141495"
"Khmilnyk","Khmilnyk","49.5569","27.9572","Ukraine","UA","UKR","Vinnytska Oblast","minor","27941","1804424011"
"Plaine du Nord","Plaine du Nord","19.6833","-72.2667","Haiti","HT","HTI","Nord","","28544","1332818460"
"Duzhuang","Duzhuang","40.0121","119.5262","China","CN","CHN","Hebei","","28831","1156202283"
"Poona-Piagapo","Poona-Piagapo","8.0833","124.0833","Philippines","PH","PHL","Lanao del Norte","","29183","1608243882"
"Brétigny-sur-Orge","Bretigny-sur-Orge","48.6114","2.3059","France","FR","FRA","Île-de-France","","27412","1250662537"
"Floresta","Floresta","-8.6008","-38.5678","Brazil","BR","BRA","Pernambuco","","29285","1076279449"
"Seguin","Seguin","29.5891","-97.9676","United States","US","USA","Texas","","29293","1840022214"
"Blankenfelde","Blankenfelde","52.3500","13.4000","Germany","DE","DEU","Brandenburg","","28761","1276580790"
"Anadia","Anadia","40.4333","-8.4333","Portugal","PT","PRT","Aveiro","minor","29150","1620362655"
"Bühl","Buhl","48.6953","8.1350","Germany","DE","DEU","Baden-Württemberg","","28889","1276639756"
"Moñitos","Monitos","9.2503","-76.1325","Colombia","CO","COL","Córdoba","minor","29117","1170797471"
"Bethany","Bethany","45.5614","-122.8370","United States","US","USA","Oregon","","29277","1840034671"
"Carmagnola","Carmagnola","44.8500","7.7167","Italy","IT","ITA","Piedmont","","28969","1380814745"
"Truskavets","Truskavets","49.2806","23.5050","Ukraine","UA","UKR","Lvivska Oblast","","28867","1804322399"
"San Marcos","San Marcos","11.9081","-86.2025","Nicaragua","NI","NIC","Carazo","minor","29019","1558544498"
"Jimenez","Jimenez","8.3333","123.8333","Philippines","PH","PHL","Misamis Occidental","","28909","1608870263"
"Kaminoyama","Kaminoyama","38.1496","140.2679","Japan","JP","JPN","Yamagata","","29141","1392003302"
"Lugus","Lugus","5.7000","120.8167","Philippines","PH","PHL","Sulu","","29043","1608911835"
"Cañada de Gómez","Canada de Gomez","-32.8167","-61.4000","Argentina","AR","ARG","Santa Fe","minor","29205","1032264958"
"Shiqiao","Shiqiao","34.1418","105.1214","China","CN","CHN","Gansu","","29050","1156600992"
"Fundão","Fundao","40.1333","-7.5000","Portugal","PT","PRT","Castelo Branco","minor","29213","1620544097"
"Viacha","Viacha","-16.6533","-68.3017","Bolivia","BO","BOL","La Paz","","29108","1068833754"
"Shotley Bridge","Shotley Bridge","54.8700","-1.8600","United Kingdom","GB","GBR","Durham","","27394","1826950423"
"Ikaruga","Ikaruga","34.6088","135.7306","Japan","JP","JPN","Nara","","27330","1392003493"
"Fandriana","Fandriana","-20.2333","47.3833","Madagascar","MG","MDG","Fianarantsoa","","29232","1450916986"
"Tabuleiro do Norte","Tabuleiro do Norte","-5.2481","-38.1300","Brazil","BR","BRA","Ceará","","29204","1076771180"
"Amarpur","Amarpur","25.0397","86.9025","India","IN","IND","Bihār","","27673","1356373131"
"Prado","Prado","-17.3408","-39.2208","Brazil","BR","BRA","Bahia","","29218","1076433537"
"Deodrug","Deodrug","16.4167","76.9341","India","IN","IND","Karnātaka","","29032","1356079708"
"Kirkwood","Kirkwood","38.5788","-90.4203","United States","US","USA","Missouri","","29233","1840008598"
"Sherobod","Sherobod","37.6667","67.0000","Uzbekistan","UZ","UZB","Surxondaryo","","29100","1860350024"
"Winterswijk","Winterswijk","51.9667","6.7167","Netherlands","NL","NLD","Gelderland","minor","29022","1528225916"
"Garupá","Garupa","-27.4833","-55.8333","Argentina","AR","ARG","Misiones","","28814","1032468152"
"Bethlehem","Bethlehem","31.7031","35.1956","West Bank","XW","XWB","","","28591","1934388150"
"Aipe","Aipe","3.2167","-75.2333","Colombia","CO","COL","Huila","minor","29177","1170334427"
"Patjirwa","Patjirwa","26.8084","84.3847","India","IN","IND","Bihār","","27535","1356153786"
"West Windsor","West Windsor","40.2897","-74.6266","United States","US","USA","New Jersey","","29211","1840081655"
"Cottica","Cottica","3.8542","-54.2281","Suriname","SR","SUR","Sipaliwini","","29210","1740371814"
"Ciying","Ciying","25.3595","103.9143","China","CN","CHN","Yunnan","","29060","1156074510"
"Santa Quitéria do Maranhão","Santa Quiteria do Maranhao","-3.5158","-42.5469","Brazil","BR","BRA","Maranhão","","29191","1076783910"
"Falkenberg","Falkenberg","56.9053","12.4911","Sweden","SE","SWE","Halland","minor","27813","1752455040"
"Nalbāri","Nalbari","26.4450","91.4400","India","IN","IND","Assam","","27389","1356164124"
"Shaker Heights","Shaker Heights","41.4744","-81.5496","United States","US","USA","Ohio","","29197","1840003412"
"Reinbek","Reinbek","53.5089","10.2483","Germany","DE","DEU","Schleswig-Holstein","","28277","1276939131"
"Pontedera","Pontedera","43.6625","10.6328","Italy","IT","ITA","Tuscany","","29168","1380040470"
"Aleysk","Aleysk","52.5000","82.7833","Russia","RU","RUS","Altayskiy Kray","","28528","1643212700"
"Ituberá","Itubera","-13.7319","-39.1489","Brazil","BR","BRA","Bahia","","29108","1076882481"
"Goriar","Goriar","25.5513","87.2046","India","IN","IND","Bihār","","27948","1356026819"
"Oktyabr’sk","Oktyabr'sk","49.4556","57.4161","Kazakhstan","KZ","KAZ","","","29169","1398394723"
"Kakiri","Kakiri","0.4200","32.3900","Uganda","UG","UGA","Wakiso","","28100","1800154731"
"La Concordia","La Concordia","-0.0067","-79.3958","Ecuador","EC","ECU","Esmeraldas","","29003","1218975936"
"Sant’Anastasia","Sant'Anastasia","40.8667","14.4000","Italy","IT","ITA","Campania","","27690","1380460463"
"Perry Hall","Perry Hall","39.4068","-76.4780","United States","US","USA","Maryland","","29165","1840005686"
"Evesham","Evesham","52.0920","-1.9470","United Kingdom","GB","GBR","Worcestershire","","27684","1826763416"
"Primero de Enero","Primero de Enero","21.9453","-78.4189","Cuba","CU","CUB","Ciego de Ávila","minor","29117","1192846441"
"Gonglang","Gonglang","24.8391","100.3122","China","CN","CHN","Yunnan","","29004","1156229602"
"San Cristóbal","San Cristobal","22.7169","-83.0511","Cuba","CU","CUB","Artemisa","minor","29119","1192301736"
"Braço do Norte","Braco do Norte","-28.2750","-49.1658","Brazil","BR","BRA","Santa Catarina","","29018","1076735238"
"Reda","Reda","54.6167","18.3500","Poland","PL","POL","Pomorskie","","28190","1616665081"
"Glória do Goitá","Gloria do Goita","-7.9992","-35.2911","Brazil","BR","BRA","Pernambuco","","29019","1076540889"
"Kodumur","Kodumur","15.6833","77.7833","India","IN","IND","Andhra Pradesh","","28828","1356171101"
"Cyangugu","Cyangugu","-2.4833","28.8967","Rwanda","RW","RWA","Western Province","","27416","1646073091"
"Bogdanovich","Bogdanovich","56.7803","62.0494","Russia","RU","RUS","Sverdlovskaya Oblast’","","29108","1643075894"
"Catandica","Catandica","-18.0564","33.1747","Mozambique","MZ","MOZ","Manica","","29052","1508992783"
"Madīnat Zāyid","Madinat Zayid","23.6522","53.6536","United Arab Emirates","AE","ARE","Abū Z̧aby","","29095","1784810704"
"Springe","Springe","52.2167","9.5500","Germany","DE","DEU","Lower Saxony","","28951","1276257894"
"Laplace","Laplace","30.0731","-90.4758","United States","US","USA","Louisiana","","29129","1840013968"
"Magna","Magna","40.7634","-112.1599","United States","US","USA","Utah","","29128","1840017499"
"Kōshū","Koshu","35.7043","138.7294","Japan","JP","JPN","Yamanashi","","29017","1392003139"
"Ban Phai","Ban Phai","16.0730","102.7362","Thailand","TH","THA","Khon Kaen","minor","28913","1764026556"
"Gates","Gates","43.1514","-77.7130","United States","US","USA","New York","","29125","1840058119"
"Old Harbour","Old Harbour","17.9333","-77.1167","Jamaica","JM","JAM","Saint Catherine","","28665","1388632984"
"Laranjal Paulista","Laranjal Paulista","-23.0118","-47.8657","Brazil","BR","BRA","São Paulo","","29047","1076357656"
"Pawāyan","Pawayan","28.0667","80.1000","India","IN","IND","Uttar Pradesh","","28613","1356763358"
"Chambly","Chambly","45.4311","-73.2873","Canada","CA","CAN","Quebec","","29120.00","1124345124"
"Baixo Guandu","Baixo Guandu","-19.5189","-41.0158","Brazil","BR","BRA","Espírito Santo","","29081","1076851088"
"Tabuelan","Tabuelan","10.8500","123.9000","Philippines","PH","PHL","Cebu","","28907","1608122773"
"Rio Pardo de Minas","Rio Pardo de Minas","-15.6100","-42.5400","Brazil","BR","BRA","Minas Gerais","","29099","1076405479"
"Narsimlāpet","Narsimlapet","17.5100","79.8044","India","IN","IND","Andhra Pradesh","","28819","1356043611"
"Vestal","Vestal","42.0492","-76.0260","United States","US","USA","New York","","29103","1840058562"
"Ixtapa","Ixtapa","16.8167","-92.9167","Mexico","MX","MEX","Chiapas","minor","28999","1484243579"
"Nāhan","Nahan","30.5500","77.3000","India","IN","IND","Himāchal Pradesh","","28899","1356417528"
"Nirasaki","Nirasaki","35.7089","138.4461","Japan","JP","JPN","Yamanashi","","28896","1392409307"
"Chili","Chili","43.0845","-77.7541","United States","US","USA","New York","","29097","1840087304"
"Ponot","Ponot","8.4428","123.0219","Philippines","PH","PHL","Zamboanga del Norte","","28881","1608607022"
"Bella Vista","Bella Vista","-28.4667","-59.0500","Argentina","AR","ARG","Corrientes","minor","29071","1032181684"
"Benavente","Benavente","38.9667","-8.8000","Portugal","PT","PRT","Santarém","minor","29019","1620484545"
"Mirandópolis","Mirandopolis","-21.1336","-51.1017","Brazil","BR","BRA","São Paulo","","29043","1076193094"
"Dalfsen","Dalfsen","52.5000","6.2500","Netherlands","NL","NLD","Overijssel","minor","28901","1528339087"
"Babatngon","Babatngon","11.4207","124.8434","Philippines","PH","PHL","Leyte","","28823","1608546355"
"Itaperuçu","Itaperucu","-25.2200","-49.3478","Brazil","BR","BRA","Paraná","","29070","1076522601"
"Bagre","Bagre","-1.9000","-50.1639","Brazil","BR","BRA","Pará","","29065","1076537991"
"Kūdligi","Kudligi","14.9050","76.3853","India","IN","IND","Karnātaka","","28840","1356159023"
"Somotillo","Somotillo","13.0447","-86.9044","Nicaragua","NI","NIC","Chinandega","minor","29030","1558936840"
"Candelaria","Candelaria","28.3547","-16.3710","Spain","ES","ESP","Canary Islands","","28485","1724124128"
"La Dorada","La Dorada","0.3436","-76.9108","Colombia","CO","COL","Putumayo","minor","28982","1170206428"
"San Manuel","San Manuel","15.7975","120.6072","Philippines","PH","PHL","Tarlac","","28387","1608808386"
"Alatri","Alatri","41.7250","13.3417","Italy","IT","ITA","Lazio","","28763","1380009791"
"Dongwang","Dongwang","38.3242","114.8869","China","CN","CHN","Hebei","","28317","1156905283"
"Gagarin","Gagarin","55.5500","35.0167","Russia","RU","RUS","Smolenskaya Oblast’","minor","29041","1643316239"
"Russellville","Russellville","35.2762","-93.1383","United States","US","USA","Arkansas","","29057","1840014541"
"Ampatuan","Ampatuan","6.8348","124.4581","Philippines","PH","PHL","Maguindanao","","28941","1608744041"
"Tocache Nuevo","Tocache Nuevo","-8.1889","-76.5139","Peru","PE","PER","San Martín","","29029","1604060187"
"San Javier","San Javier","-35.5924","-71.7353","Chile","CL","CHL","Maule","","29017","1152644283"
"Roseaux","Roseaux","18.6000","-74.0167","Haiti","HT","HTI","Grand’Anse","","28811","1332874884"
"Cervia","Cervia","44.2586","12.3558","Italy","IT","ITA","Emilia-Romagna","","28700","1380322591"
"Ofaqim","Ofaqim","31.3167","34.6167","Israel","IL","ISR","Southern","","29000","1376992458"
"Śrem","Srem","52.0886","17.0147","Poland","PL","POL","Wielkopolskie","minor","28835","1616734639"
"Dhabauli","Dhabauli","25.8346","86.8057","India","IN","IND","Bihār","","27952","1356067326"
"Limoeiro de Anadia","Limoeiro de Anadia","-9.7414","-36.5033","Brazil","BR","BRA","Alagoas","","28953","1076445956"
"Emmendingen","Emmendingen","48.1214","7.8492","Germany","DE","DEU","Baden-Württemberg","minor","28207","1276708263"
"Stoughton","Stoughton","42.1192","-71.1019","United States","US","USA","Massachusetts","","29028","1840053558"
"Tanglou","Tanglou","22.1888","110.8710","China","CN","CHN","Guangdong","","28718","1156311977"
"Langedijk","Langedijk","52.6936","4.7944","Netherlands","NL","NLD","Noord-Holland","","27992","1528789674"
"Thabazimbi","Thabazimbi","-24.6000","27.4000","South Africa","ZA","ZAF","Limpopo","","28847","1710123508"
"Cajati","Cajati","-24.7361","-48.1228","Brazil","BR","BRA","São Paulo","","28962","1076157800"
"Hendījān","Hendijan","30.2364","49.7119","Iran","IR","IRN","Khūzestān","minor","29015","1364511493"
"Tondela","Tondela","40.5167","-8.0833","Portugal","PT","PRT","Viseu","minor","28946","1620042884"
"Lake in the Hills","Lake in the Hills","42.1913","-88.3477","United States","US","USA","Illinois","","29024","1840011129"
"Sortöbe","Sortobe","42.8600","75.2300","Kazakhstan","KZ","KAZ","","","29000","1398173306"
"Tudiyalūr","Tudiyalur","11.0816","76.9446","India","IN","IND","Tamil Nādu","","27725","1356728214"
"La Calera","La Calera","4.7531","-73.9200","Colombia","CO","COL","Cundinamarca","minor","28908","1170042987"
"Höxter","Hoxter","51.7667","9.3667","Germany","DE","DEU","North Rhine-Westphalia","minor","28824","1276004976"
"Germī","Germi","39.0297","48.0803","Iran","IR","IRN","Ardabīl","minor","28967","1364702813"
"Baleno","Baleno","12.4739","123.4982","Philippines","PH","PHL","Masbate","","28855","1608892797"
"Nivelles","Nivelles","50.5833","4.3167","Belgium","BE","BEL","Wallonia","minor","28521","1056187375"
"Macau","Macau","-5.1150","-36.6339","Brazil","BR","BRA","Rio Grande do Norte","","28954","1076020090"
"Rosa Zarate","Rosa Zarate","0.3300","-79.4800","Ecuador","EC","ECU","Esmeraldas","","28928","1218721040"
"Dīnānagar","Dinanagar","32.1500","75.4667","India","IN","IND","Punjab","","28382","1356448598"
"Mabini","Mabini","9.8650","124.5230","Philippines","PH","PHL","Bohol","","28701","1608176606"
"Xizhou","Xizhou","24.1156","120.5350","Taiwan","TW","TWN","Changhua","","28597","1158560698"
"Toqsu","Toqsu","41.5417","82.6040","China","CN","CHN","Xinjiang","minor","27454","1156163332"
"Vihti","Vihti","60.4051","24.3959","Finland","FI","FIN","Uusimaa","","28919","1246687261"
"Hanawa","Hanawa","40.2158","140.7884","Japan","JP","JPN","Akita","","28929","1392488669"
"Jauja","Jauja","-11.7750","-75.5000","Peru","PE","PER","Junín","","27560","1604948232"
"Krotoszyn","Krotoszyn","51.6833","17.4333","Poland","PL","POL","Wielkopolskie","minor","28804","1616105817"
"Caicedonia","Caicedonia","4.3333","-75.8333","Colombia","CO","COL","Valle del Cauca","minor","28825","1170729476"
"Tetela de Ocampo","Tetela de Ocampo","19.8167","-97.8000","Mexico","MX","MEX","Puebla","minor","28139","1484794888"
"Las Rosas","Las Rosas","16.3667","-92.3500","Mexico","MX","MEX","Chiapas","minor","28829","1484866090"
"Barabinsk","Barabinsk","55.3500","78.3500","Russia","RU","RUS","Novosibirskaya Oblast’","","28941","1643008352"
"Nava","Nava","28.4214","-100.7675","Mexico","MX","MEX","Coahuila","minor","26963","1484120104"
"Acatenango","Acatenango","14.5544","-90.9439","Guatemala","GT","GTM","Chimaltenango","minor","28780","1320527035"
"Pucón","Pucon","-39.2767","-71.9744","Chile","CL","CHL","Araucanía","","28923","1152377330"
"Hirado","Hirado","33.3667","129.5500","Japan","JP","JPN","Nagasaki","minor","28822","1392812578"
"Sébikhotane","Sebikhotane","14.7469","-17.1367","Senegal","SN","SEN","Dakar","","27402","1686663994"
"Pilar","Pilar","9.8333","124.3333","Philippines","PH","PHL","Bohol","","28693","1608391596"
"Chahe","Chahe","27.1746","105.3716","China","CN","CHN","Guizhou","","28711","1156073525"
"Lansing","Lansing","41.5648","-87.5462","United States","US","USA","Illinois","","28916","1840011257"
"Queensbury","Queensbury","43.3568","-73.6765","United States","US","USA","New York","","28914","1840058430"
"Heshancun","Heshancun","30.6344","120.3637","China","CN","CHN","Zhejiang","","28191","1156372416"
"Petrovsk","Petrovsk","52.3167","45.3833","Russia","RU","RUS","Saratovskaya Oblast’","","28893","1643935134"
"Itatiaia","Itatiaia","-22.4914","-44.5592","Brazil","BR","BRA","Rio de Janeiro","","28783","1076077667"
"Whitehall","Whitehall","40.6571","-75.5046","United States","US","USA","Pennsylvania","","28909","1840147555"
"Balakliia","Balakliia","49.4564","36.8389","Ukraine","UA","UKR","Kharkivska Oblast","","28868","1804852516"
"Srungavarapukota","Srungavarapukota","18.1167","83.1667","India","IN","IND","Andhra Pradesh","","28304","1356572581"
"Kroměříž","Kromeriz","49.2989","17.3931","Czechia","CZ","CZE","Zlínský Kraj","","28360","1203995171"
"Cifuentes","Cifuentes","22.6208","-80.0661","Cuba","CU","CUB","Villa Clara","minor","28850","1192637464"
"Quakers Hill","Quakers Hill","-33.7344","150.8789","Australia","AU","AUS","New South Wales","","27080","1036046868"
"Zhonghechang","Zhonghechang","27.8886","107.2531","China","CN","CHN","Guizhou","","28745","1156256442"
"Henstedt-Ulzburg","Henstedt-Ulzburg","53.7833","10.0000","Germany","DE","DEU","Schleswig-Holstein","","28182","1276986461"
"Borzya","Borzya","50.3833","116.5167","Russia","RU","RUS","Zabaykal’skiy Kray","","28888","1643309577"
"Kuzhittura","Kuzhittura","8.3165","77.2061","India","IN","IND","Tamil Nādu","","27063","1356042082"
"Tudela","Tudela","8.2472","123.8424","Philippines","PH","PHL","Misamis Occidental","","28599","1608684783"
"Rehoboth","Rehoboth","-23.3167","17.0833","Namibia","NA","NAM","Hardap","","28843","1516928100"
"Novyi Rozdil","Novyi Rozdil","49.4703","24.1300","Ukraine","UA","UKR","Lvivska Oblast","","28745","1804473027"
"Okotoks","Okotoks","50.7250","-113.9750","Canada","CA","CAN","Alberta","","28881","1124521470"
"Bāgha Purāna","Bagha Purana","30.6881","75.0984","India","IN","IND","Punjab","","28506","1356036328"
"Takahashi","Takahashi","34.7914","133.6164","Japan","JP","JPN","Okayama","","28825","1392003457"
"Shangluhu","Shangluhu","23.2538","115.5815","China","CN","CHN","Guangdong","","28581","1156850281"
"Asago","Asago","35.3333","134.8500","Japan","JP","JPN","Hyōgo","","28802","1392003122"
"Mateare","Mateare","12.2356","-86.4289","Nicaragua","NI","NIC","Managua","minor","28775","1558569665"
"Carapó","Carapo","-22.6339","-54.8219","Brazil","BR","BRA","Mato Grosso do Sul","","28867","1076883647"
"Tibiao","Tibiao","11.2892","122.0342","Philippines","PH","PHL","Antique","","28703","1608615251"
"Stanford le Hope","Stanford le Hope","51.5140","0.4244","United Kingdom","GB","GBR","Thurrock","","28765","1826848098"
"Summerlin South","Summerlin South","36.1242","-115.3324","United States","US","USA","Nevada","","28861","1840074790"
"Laoaoba","Laoaoba","26.8377","105.5292","China","CN","CHN","Guizhou","","28595","1156161916"
"Goio-Erê","Goio-Ere","-24.1850","-53.0278","Brazil","BR","BRA","Paraná","","28808","1076469014"
"Ogawa","Ogawa","36.0567","139.2618","Japan","JP","JPN","Saitama","","28386","1392897605"
"Bentley","Bentley","53.5510","-1.1440","United Kingdom","GB","GBR","Doncaster","","27145","1826141452"
"Herentals","Herentals","51.1833","4.8333","Belgium","BE","BEL","Flanders","","28272","1056125768"
"Shāhpura","Shahpura","27.3897","75.9596","India","IN","IND","Rājasthān","","28170","1356667085"
"Sønderborg","Sonderborg","54.9138","9.7922","Denmark","DK","DNK","Syddanmark","minor","27826","1208870577"
"Balzar","Balzar","-1.3600","-79.9000","Ecuador","EC","ECU","Guayas","","28794","1218257587"
"Nongstoin","Nongstoin","25.5200","91.2700","India","IN","IND","Meghālaya","","28742","1356690668"
"Oyabe","Oyabe","36.6755","136.8687","Japan","JP","JPN","Toyama","","28634","1392185523"
"New Iberia","New Iberia","30.0049","-91.8202","United States","US","USA","Louisiana","","28844","1840015053"
"Harelbeke","Harelbeke","50.8567","3.3131","Belgium","BE","BEL","Flanders","","27886","1056312961"
"Losal","Losal","27.4000","74.9200","India","IN","IND","Rājasthān","","28504","1356031120"
"Lepe","Lepe","37.2542","-7.2033","Spain","ES","ESP","Andalusia","","28617","1724165295"
"Zhongtai","Zhongtai","35.0675","107.6130","China","CN","CHN","Gansu","","28578","1156898882"
"Pijnacker","Pijnacker","52.0167","4.4333","Netherlands","NL","NLD","Zuid-Holland","minor","27130","1528994302"
"Santa Rita de Cássia","Santa Rita de Cassia","-11.0089","-44.5189","Brazil","BR","BRA","Bahia","","28822","1076250504"
"Prosper","Prosper","33.2394","-96.8087","United States","US","USA","Texas","","28825","1840022019"
"Heerenveen","Heerenveen","52.9500","5.9333","Netherlands","NL","NLD","Fryslân","minor","28497","1528356043"
"Morrisville","Morrisville","35.8368","-78.8348","United States","US","USA","North Carolina","","28824","1840016195"
"Andoharanomaitso","Andoharanomaitso","-21.4667","46.9500","Madagascar","MG","MDG","Fianarantsoa","","28708","1450341443"
"Kalwākurti","Kalwakurti","16.6500","78.4800","India","IN","IND","Andhra Pradesh","","28060","1356100988"
"Jamestown","Jamestown","42.0976","-79.2367","United States","US","USA","New York","","28818","1840000446"
"Mount Olive","Mount Olive","40.8662","-74.7426","United States","US","USA","New Jersey","","28815","1840081727"
"West Springfield","West Springfield","42.1253","-72.6503","United States","US","USA","Massachusetts","","28814","1840031158"
"Oakleaf Plantation","Oakleaf Plantation","30.1689","-81.8337","United States","US","USA","Florida","","28808","1840039045"
"Ambatolampy","Ambatolampy","-19.3861","47.4292","Madagascar","MG","MDG","Antananarivo","","28500","1450037305"
"Jeffersontown","Jeffersontown","38.2049","-85.5701","United States","US","USA","Kentucky","","28800","1840014311"
"Bānswāda","Banswada","18.3833","77.8833","India","IN","IND","Andhra Pradesh","","28384","1356367642"
"Bussy-Saint-Georges","Bussy-Saint-Georges","48.8422","2.6983","France","FR","FRA","Île-de-France","","26806","1250024212"
"Pires do Rio","Pires do Rio","-17.3008","-48.2800","Brazil","BR","BRA","Goiás","","28762","1076357135"
"Ibaiti","Ibaiti","-23.8489","-50.1878","Brazil","BR","BRA","Paraná","","28751","1076065226"
"Zhailuo","Zhailuo","26.8794","105.3081","China","CN","CHN","Guizhou","","28463","1156122398"
"Águas Santas","Aguas Santas","41.2090","-8.5890","Portugal","PT","PRT","Porto","","27470","1620762299"
"San Salvador","San Salvador","20.2833","-99.0153","Mexico","MX","MEX","Hidalgo","minor","28637","1484680072"
"Yangiyer","Yangiyer","40.2667","68.8167","Uzbekistan","UZ","UZB","Sirdaryo","","28593","1860492176"
"Leichlingen","Leichlingen","51.1167","7.0167","Germany","DE","DEU","North Rhine-Westphalia","","28031","1276227302"
"Amaliáda","Amaliada","37.8000","21.3500","Greece","GR","GRC","Dytikí Elláda","minor","28520","1300074645"
"Esparza","Esparza","9.9959","-84.6467","Costa Rica","CR","CRI","Puntarenas","","28644","1188812694"
"Fichē","Fiche","9.8000","38.7333","Ethiopia","ET","ETH","Oromīya","","27493","1231384026"
"Cecina","Cecina","43.3167","10.5167","Italy","IT","ITA","Tuscany","","28112","1380999506"
"Oulad Yaïch","Oulad Yaich","32.4167","-6.3333","Morocco","MA","MAR","Béni Mellal-Khénifra","","27476","1504233317"
"Taza","Taza","35.0639","-5.2025","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","28713","1504253957"
"Malmal","Malmal","26.4833","86.0500","India","IN","IND","Bihār","","27921","1356381290"
"Cheshire","Cheshire","41.5113","-72.9036","United States","US","USA","Connecticut","","28766","1840044994"
"Rajpur","Rajpur","22.3053","74.3525","India","IN","IND","Madhya Pradesh","","28498","1356223466"
"Santiago","Santiago","9.2654","125.5602","Philippines","PH","PHL","Agusan del Norte","","28657","1608671914"
"Pitoa","Pitoa","9.3833","13.5333","Cameroon","CM","CMR","Nord","","28636","1120762308"
"Zeitz","Zeitz","51.0478","12.1383","Germany","DE","DEU","Saxony-Anhalt","","27955","1276390204"
"Hatonuevo","Hatonuevo","11.0694","-72.7669","Colombia","CO","COL","La Guajira","minor","28671","1170166285"
"Propriá","Propria","-10.2111","-36.8403","Brazil","BR","BRA","Sergipe","","28451","1076924587"
"Casselberry","Casselberry","28.6624","-81.3217","United States","US","USA","Florida","","28750","1840015088"
"Naranjito","Naranjito","-2.1667","-79.4653","Ecuador","EC","ECU","Guayas","","28546","1218386613"
"Nueva Concepción","Nueva Concepcion","14.1333","-89.3000","El Salvador","SV","SLV","Chalatenango","","28625","1222433381"
"Alagoa Grande","Alagoa Grande","-7.0822","-35.6000","Brazil","BR","BRA","Paraíba","","28646","1076229979"
"Hājipur","Hajipur","31.9771","75.7544","India","IN","IND","Punjab","","28226","1356008805"
"Idar-Oberstein","Idar-Oberstein","49.7114","7.3131","Germany","DE","DEU","Rhineland-Palatinate","","28423","1276902522"
"Ḩarīr","Harir","36.5517","44.3516","Iraq","IQ","IRQ","Arbīl","","28518","1368699269"
"Vittorio Veneto","Vittorio Veneto","45.9833","12.3000","Italy","IT","ITA","Veneto","","28389","1380283185"
"Mahārājpur","Maharajpur","25.0194","79.7319","India","IN","IND","Madhya Pradesh","","28366","1356646290"
"Novi Ligure","Novi Ligure","44.7592","8.7856","Italy","IT","ITA","Piedmont","","28210","1380529141"
"Toucheng","Toucheng","24.8500","121.8167","Taiwan","TW","TWN","Yilan","","28438","1158073803"
"Madison Heights","Madison Heights","42.5073","-83.1034","United States","US","USA","Michigan","","28719","1840002449"
"Karakoçan","Karakocan","38.9500","40.0333","Turkey","TR","TUR","Elazığ","minor","28702","1792070024"
"Extrema","Extrema","-22.8550","-46.3178","Brazil","BR","BRA","Minas Gerais","","28599","1076000569"
"Agawam","Agawam","42.0657","-72.6526","United States","US","USA","Massachusetts","","28715","1840031157"
"Passira","Passira","-7.9950","-35.5808","Brazil","BR","BRA","Pernambuco","","28628","1076482392"
"Palotina","Palotina","-24.2839","-53.8400","Brazil","BR","BRA","Paraná","","28683","1076516410"
"Qincun","Qincun","37.8360","116.6708","China","CN","CHN","Hebei","","28380","1156420015"
"Griesheim","Griesheim","49.8639","8.5639","Germany","DE","DEU","Hesse","","27435","1276867372"
"Jujutla","Jujutla","13.7833","-89.8500","El Salvador","SV","SLV","Ahuachapán","","28599","1222833658"
"Basay","Basay","9.4167","122.6333","Philippines","PH","PHL","Negros Oriental","","28531","1608826752"
"Matihāni","Matihani","25.3595","86.1826","India","IN","IND","Bihār","","27466","1356735230"
"Maracanã","Maracana","-0.7650","-47.4500","Brazil","BR","BRA","Pará","","28668","1076318334"
"Gyöngyös","Gyongyos","47.7833","19.9333","Hungary","HU","HUN","Heves","minor","28179","1348619810"
"Ferdows","Ferdows","34.0186","58.1722","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","28695","1364726731"
"Saint Bernard","Saint Bernard","10.2833","125.1333","Philippines","PH","PHL","Southern Leyte","","28414","1608368658"
"Harūr","Harur","12.0510","78.4827","India","IN","IND","Tamil Nādu","","28410","1356466320"
"Douar Oulad Aj-jabri","Douar Oulad Aj-jabri","32.2567","-6.7839","Morocco","MA","MAR","Béni Mellal-Khénifra","","28438","1504025075"
"Doña Remedios Trinidad","Dona Remedios Trinidad","15.0000","121.0833","Philippines","PH","PHL","Bulacan","","28656","1608246190"
"Witney","Witney","51.7800","-1.4900","United Kingdom","GB","GBR","Oxfordshire","","27522","1826136644"
"Saito","Saito","32.1167","131.4000","Japan","JP","JPN","Miyazaki","","28618","1392000777"
"Taungup","Taungup","18.8500","94.2333","Myanmar","MM","MMR","Rakhine State","","28652","1104997473"
"Umargām","Umargam","20.1700","72.7600","India","IN","IND","Gujarāt","","27857","1356131657"
"Pāvugada","Pavugada","14.1000","77.2800","India","IN","IND","Karnātaka","","28486","1356037404"
"Quijingue","Quijingue","-10.7528","-39.2100","Brazil","BR","BRA","Bahia","","28655","1076131651"
"Mehlville","Mehlville","38.5018","-90.3149","United States","US","USA","Missouri","","28672","1840006120"
"Olching","Olching","48.2000","11.3167","Germany","DE","DEU","Bavaria","","27741","1276458023"
"Byaroza","Byaroza","52.5500","24.9667","Belarus","BY","BLR","Brestskaya Voblasts’","minor","28619","1112705431"
"Aioi","Aioi","34.8000","134.4667","Japan","JP","JPN","Hyōgo","","28350","1392132001"
"Geislingen an der Steige","Geislingen an der Steige","48.6244","9.8306","Germany","DE","DEU","Baden-Württemberg","","28290","1276183243"
"Bihpur","Bihpur","25.3889","86.9337","India","IN","IND","Bihār","","27322","1356547801"
"San Carlos","San Carlos","8.8006","-75.7022","Colombia","CO","COL","Córdoba","minor","28597","1170505254"
"Walnut","Walnut","34.0334","-117.8593","United States","US","USA","California","","28641","1840021875"
"Gevaş","Gevas","38.2978","43.1056","Turkey","TR","TUR","Van","minor","28620","1792623763"
"Teltow","Teltow","52.4022","13.2706","Germany","DE","DEU","Brandenburg","","27371","1276388501"
"Pazarcık","Pazarcik","37.4860","37.2970","Turkey","TR","TUR","Kahramanmaraş","minor","28582","1792266473"
"Fenoarivobe","Fenoarivobe","-18.4497","46.5617","Madagascar","MG","MDG","Antananarivo","","28616","1450638055"
"Valencia","Valencia","9.6097","124.2080","Philippines","PH","PHL","Bohol","","28392","1608239988"
"İhsaniye","Ihsaniye","39.0333","30.4167","Turkey","TR","TUR","Afyonkarahisar","minor","28603","1792841421"
"Baunatal","Baunatal","51.2589","9.4183","Germany","DE","DEU","Hesse","","27906","1276339266"
"Harrison","Harrison","41.0236","-73.7193","United States","US","USA","New York","","28635","1840004931"
"Mampikony","Mampikony","-16.0917","47.6472","Madagascar","MG","MDG","Mahajanga","","28593","1450443116"
"Kartaly","Kartaly","53.0500","60.6500","Russia","RU","RUS","Chelyabinskaya Oblast’","","28607","1643181186"
"Isnos","Isnos","1.9333","-76.2333","Colombia","CO","COL","Huila","minor","28530","1170081298"
"Magdalena","Magdalena","14.2000","121.4333","Philippines","PH","PHL","Laguna","","27816","1608837240"
"Baishaling","Baishaling","24.0950","113.7591","China","CN","CHN","Guangdong","","28419","1156761418"
"Pokhvistnevo","Pokhvistnevo","53.6500","52.1333","Russia","RU","RUS","Samarskaya Oblast’","","28177","1643843387"
"Monroeville","Monroeville","40.4262","-79.7605","United States","US","USA","Pennsylvania","","28607","1840003631"
"Oeiras do Pará","Oeiras do Para","-2.0028","-49.8539","Brazil","BR","BRA","Pará","","28595","1076592894"
"Dubăsari","Dubasari","47.2667","29.1667","Moldova","MD","MDA","Stînga Nistrului","","28500","1498583082"
"Cabricán","Cabrican","15.0768","-91.6500","Guatemala","GT","GTM","Quetzaltenango","minor","28124","1320549099"
"Santo Niño","Santo Nino","17.8861","121.5691","Philippines","PH","PHL","Cagayan","","28537","1608449787"
"Rūdehen","Rudehen","35.7378","51.9117","Iran","IR","IRN","Tehrān","","28533","1364001385"
"Naranjal","Naranjal","-2.6728","-79.6150","Ecuador","EC","ECU","Guayas","","28487","1218900978"
"Tarāna","Tarana","23.3300","76.0300","India","IN","IND","Madhya Pradesh","","28317","1356027638"
"Lushoto","Lushoto","-4.7928","38.2911","Tanzania","TZ","TZA","Tanga","","28190","1834431227"
"El Copey","El Copey","10.1500","-73.9667","Colombia","CO","COL","Cesar","minor","28550","1170203188"
"Kpandu","Kpandu","7.0000","0.3000","Ghana","GH","GHA","Volta","","28334","1288918364"
"McDonough","McDonough","33.4397","-84.1505","United States","US","USA","Georgia","","28574","1840015706"
"Lacey","Lacey","39.8564","-74.2624","United States","US","USA","New Jersey","","28573","1840081603"
"Izu","Izu","34.9765","138.9468","Japan","JP","JPN","Shizuoka","","28493","1392453323"
"Stockbridge","Stockbridge","33.5254","-84.2295","United States","US","USA","Georgia","","28567","1840015707"
"Villaviciosa de Odón","Villaviciosa de Odon","40.3583","-3.9033","Spain","ES","ESP","Madrid","","28152","1724552632"
"Baradero","Baradero","-33.8000","-59.5167","Argentina","AR","ARG","Buenos Aires","minor","28537","1032310302"
"Arivonimamo","Arivonimamo","-19.0333","47.1667","Madagascar","MG","MDG","Antananarivo","","28324","1450000014"
"Lillehammer","Lillehammer","61.1167","10.4667","Norway","NO","NOR","Innlandet","minor","28493","1578724478"
"Tha Yang","Tha Yang","12.9658","99.8924","Thailand","TH","THA","Phetchaburi","minor","28437","1764170056"
"Azángaro","Azangaro","-14.9099","-70.1974","Peru","PE","PER","Puno","","28526","1604000053"
"Giarre","Giarre","37.7333","15.1833","Italy","IT","ITA","Sicilia","","27546","1380431128"
"Ilave","Ilave","-16.0836","-69.6383","Peru","PE","PER","Puno","","28483","1604815838"
"Wegberg","Wegberg","51.1417","6.2792","Germany","DE","DEU","North Rhine-Westphalia","","28213","1276843809"
"Kobyłka","Kobylka","52.3333","21.2000","Poland","PL","POL","Mazowieckie","","27185","1616520686"
"Mechernich","Mechernich","50.6000","6.6500","Germany","DE","DEU","North Rhine-Westphalia","","28327","1276281325"
"Sept-Îles","Sept-Iles","50.2167","-66.3833","Canada","CA","CAN","Quebec","","28534","1124406431"
"Mūvattupula","Muvattupula","9.9798","76.5803","India","IN","IND","Kerala","","27595","1356561612"
"Lučenec","Lucenec","48.3286","19.6692","Slovakia","SK","SVK","Banská Bystrica","minor","27991","1703222138"
"Triggiano","Triggiano","41.0667","16.9167","Italy","IT","ITA","Puglia","","27180","1380774515"
"Bamba","Bamba","17.0333","-1.4000","Mali","ML","MLI","Gao","","28524","1466722054"
"Gingee","Gingee","12.2528","79.4173","India","IN","IND","Tamil Nādu","","28051","1356622896"
"Barão de Cocais","Barao de Cocais","-19.9458","-43.4869","Brazil","BR","BRA","Minas Gerais","","28442","1076326274"
"Seaford","Seaford","50.7700","0.1000","United Kingdom","GB","GBR","East Sussex","","26955","1826426946"
"Saugus","Saugus","42.4681","-71.0145","United States","US","USA","Massachusetts","","28521","1840053670"
"Oulad Hammou","Oulad Hammou","33.2499","-8.3346","Morocco","MA","MAR","Casablanca-Settat","","28449","1504007795"
"Arari","Arari","-3.4539","-44.7800","Brazil","BR","BRA","Maranhão","","28488","1076577900"
"Venezuela","Venezuela","21.7511","-78.7792","Cuba","CU","CUB","Ciego de Ávila","minor","28470","1192477567"
"Carauari","Carauari","-4.8828","-66.8958","Brazil","BR","BRA","Amazonas","","28508","1076358416"
"Glen Ellyn","Glen Ellyn","41.8666","-88.0629","United States","US","USA","Illinois","","28506","1840011405"
"Villa Allende","Villa Allende","-31.3000","-64.3000","Argentina","AR","ARG","Córdoba","","28374","1032113931"
"Perumbalam","Perumbalam","10.8000","76.1400","India","IN","IND","Kerala","","26550","1356740621"
"Assisi","Assisi","43.0758","12.6175","Italy","IT","ITA","Umbria","","28352","1380853123"
"Longaví","Longavi","-35.9667","-71.6833","Chile","CL","CHL","Maule","","28499","1152122275"
"Biłgoraj","Bilgoraj","50.5500","22.7333","Poland","PL","POL","Lubelskie","minor","27290","1616554572"
"Aberdeen","Aberdeen","45.4649","-98.4686","United States","US","USA","South Dakota","","28500","1840002032"
"Tsushima","Tsushima","34.2000","129.2833","Japan","JP","JPN","Nagasaki","","28456","1392003427"
"Imbituva","Imbituva","-25.2300","-50.6044","Brazil","BR","BRA","Paraná","","28455","1076855265"
"Stafford","Stafford","39.7049","-74.2643","United States","US","USA","New Jersey","","28492","1840081600"
"Higashikagawa","Higashikagawa","34.2500","134.3667","Japan","JP","JPN","Kagawa","","28305","1392003245"
"Seseña","Sesena","40.1036","-3.6981","Spain","ES","ESP","Castille-La Mancha","","28102","1724634027"
"Líšeň","Lisen","49.2075","16.6861","Czechia","CZ","CZE","Jihomoravský Kraj","","26781","1203926697"
"Coyaima","Coyaima","3.8333","-75.0833","Colombia","CO","COL","Tolima","minor","28443","1170700858"
"Bonito Oriental","Bonito Oriental","15.7333","-85.7333","Honduras","HN","HND","Colón","","28427","1340343332"
"Mpika","Mpika","-11.8300","31.4600","Zambia","ZM","ZMB","Muchinga","","28445","1894224372"
"Rāisinghnagar","Raisinghnagar","29.5342","73.4477","India","IN","IND","Rājasthān","","28330","1356007573"
"Gorlice","Gorlice","49.6547","21.1597","Poland","PL","POL","Małopolskie","minor","27357","1616148150"
"Kolambugan","Kolambugan","8.1144","123.8971","Philippines","PH","PHL","Lanao del Norte","","28265","1608474341"
"Chortkiv","Chortkiv","49.0167","25.8000","Ukraine","UA","UKR","Ternopilska Oblast","minor","28393","1804015197"
"Datu Piang","Datu Piang","7.0178","124.4974","Philippines","PH","PHL","Maguindanao","","28380","1608264842"
"Karanjiā","Karanjia","21.7626","85.9732","India","IN","IND","Odisha","","28302","1356693640"
"Conceição da Barra","Conceicao da Barra","-18.5928","-39.7319","Brazil","BR","BRA","Espírito Santo","","28449","1076271999"
"Circasia","Circasia","4.6167","-75.6333","Colombia","CO","COL","Quindío","minor","28162","1170367133"
"Bom Jesus","Bom Jesus","-4.4200","-46.7650","Brazil","BR","BRA","Maranhão","","28459","1076538984"
"Kotagiri","Kotagiri","11.4167","76.8667","India","IN","IND","Tamil Nādu","","28207","1356645188"
"Serra Negra","Serra Negra","-22.6119","-46.7008","Brazil","BR","BRA","São Paulo","","28321","1076394760"
"Carbonia","Carbonia","39.1672","8.5222","Italy","IT","ITA","Sardegna","minor","28265","1380899990"
"Mogliano Veneto","Mogliano Veneto","45.5619","12.2364","Italy","IT","ITA","Veneto","","27852","1380309612"
"Guararema","Guararema","-23.4150","-46.0350","Brazil","BR","BRA","São Paulo","","28344","1076938810"
"Rāmanayyapeta","Ramanayyapeta","17.3203","82.1014","India","IN","IND","Andhra Pradesh","","28369","1356601275"
"Almus","Almus","40.3748","36.9031","Turkey","TR","TUR","Tokat","minor","28413","1792278732"
"Yemanzhelinsk","Yemanzhelinsk","54.7667","61.3333","Russia","RU","RUS","Chelyabinskaya Oblast’","","28216","1643916431"
"Luís Correia","Luis Correia","-2.8789","-41.6669","Brazil","BR","BRA","Piauí","","28406","1076761860"
"Taiwa","Taiwa","38.4373","140.8864","Japan","JP","JPN","Miyagi","","28307","1392003533"
"Allen Park","Allen Park","42.2595","-83.2107","United States","US","USA","Michigan","","28431","1840003967"
"Raseborg","Raseborg","59.9750","23.4333","Finland","FI","FIN","Uusimaa","minor","28405","1246414831"
"AshShajarah","AshShajarah","32.6417","35.9417","Jordan","JO","JOR","Irbid","","27902","1400450578"
"Alotenango","Alotenango","14.4878","-90.8058","Guatemala","GT","GTM","Sacatepéquez","minor","28129","1320366805"
"Huanghuajing","Huanghuajing","24.1989","112.9104","China","CN","CHN","Guangdong","","28282","1156276532"
"Manaquiri","Manaquiri","-3.4281","-60.4594","Brazil","BR","BRA","Amazonas","","28413","1076794192"
"Brownsburg","Brownsburg","39.8337","-86.3830","United States","US","USA","Indiana","","28420","1840009536"
"Ciro Redondo","Ciro Redondo","22.0189","-78.7031","Cuba","CU","CUB","Ciego de Ávila","minor","28370","1192204579"
"Maner","Maner","25.6500","84.8800","India","IN","IND","Bihār","","26912","1356935859"
"Jeffrey’s Bay","Jeffrey's Bay","-34.0333","24.9167","South Africa","ZA","ZAF","Eastern Cape","","27107","1710277177"
"Pongoz","Pongoz","40.7500","70.2500","Tajikistan","TJ","TJK","Sughd","","28352","1762429014"
"Warin Chamrap","Warin Chamrap","15.2008","104.8612","Thailand","TH","THA","Ubon Ratchathani","minor","28154","1764105770"
"Pershotravensk","Pershotravensk","48.3464","36.4044","Ukraine","UA","UKR","Dnipropetrovska Oblast","","28363","1804847334"
"Ar Ruţbah","Ar Rutbah","33.0381","40.2844","Iraq","IQ","IRQ","Al Anbār","minor","28400","1368174661"
"San Giovanni in Persiceto","San Giovanni in Persiceto","44.6408","11.1850","Italy","IT","ITA","Emilia-Romagna","","28153","1380356919"
"Altınova","Altinova","40.6975","29.5114","Turkey","TR","TUR","Yalova","minor","28232","1792919647"
"Gaotan","Gaotan","32.3227","108.3812","China","CN","CHN","Shaanxi","","28149","1156998570"
"Pādiyanallūr","Padiyanallur","13.2004","80.1761","India","IN","IND","Tamil Nādu","","27638","1356720796"
"Reina Mercedes Viejo","Reina Mercedes Viejo","16.9872","121.8272","Philippines","PH","PHL","Isabela","","27900","1608400065"
"Tangutūru","Tanguturu","15.3400","80.0390","India","IN","IND","Andhra Pradesh","","27652","1356174472"
"Libacao","Libacao","11.4833","122.3000","Philippines","PH","PHL","Aklan","","28272","1608816911"
"Ban Chang","Ban Chang","12.7208","101.0669","Thailand","TH","THA","Rayong","minor","27668","1764733644"
"Milton","Milton","42.2412","-71.0844","United States","US","USA","Massachusetts","","28382","1840053675"
"Sighişoara","Sighisoara","46.2169","24.7911","Romania","RO","ROU","Mureş","","28102","1642947204"
"Toli","Toli","45.9313","83.6039","China","CN","CHN","Xinjiang","minor","28378","1156588017"
"Bermejo","Bermejo","-22.7322","-64.3425","Bolivia","BO","BOL","Tarija","","28285","1068060614"
"Bacoli","Bacoli","40.8000","14.0833","Italy","IT","ITA","Campania","","26412","1380169390"
"Orchards","Orchards","45.6890","-122.5306","United States","US","USA","Washington","","28371","1840018519"
"Sāngola","Sangola","17.4378","75.1939","India","IN","IND","Mahārāshtra","","28116","1356137884"
"Eagle Pass","Eagle Pass","28.7125","-100.4840","United States","US","USA","Texas","","28367","1840019690"
"Lagdo","Lagdo","9.0500","13.7333","Cameroon","CM","CMR","Nord","","28239","1120762160"
"Bārughutu","Barughutu","23.8038","85.5555","India","IN","IND","Jhārkhand","","27841","1356182224"
"Benicarló","Benicarlo","40.4167","0.4167","Spain","ES","ESP","Valencia","","27780","1724776568"
"Pedras de Fogo","Pedras de Fogo","-7.4019","-35.1158","Brazil","BR","BRA","Paraíba","","28286","1076970130"
"Olindina","Olindina","-11.3669","-38.3328","Brazil","BR","BRA","Bahia","","28304","1076626137"
"Talusan","Talusan","7.4263","122.8084","Philippines","PH","PHL","Zamboanga Sibugay","","27873","1608149746"
"Pilar do Sul","Pilar do Sul","-23.8128","-47.7158","Brazil","BR","BRA","São Paulo","","28309","1076254637"
"Khust","Khust","48.1814","23.2978","Ukraine","UA","UKR","Zakarpatska Oblast","minor","28206","1804836391"
"Guamal","Guamal","9.1472","-74.2300","Colombia","CO","COL","Magdalena","minor","28277","1170423757"
"Bridgewater","Bridgewater","41.9728","-70.9749","United States","US","USA","Massachusetts","","28337","1840003199"
"Baesweiler","Baesweiler","50.9000","6.1833","Germany","DE","DEU","North Rhine-Westphalia","","27351","1276109783"
"Samayac","Samayac","14.5821","-91.4610","Guatemala","GT","GTM","Suchitepéquez","minor","26665","1320175624"
"Shakīso","Shakiso","5.7500","38.9167","Ethiopia","ET","ETH","Oromīya","","28260","1231345308"
"Mollendo","Mollendo","-17.0231","-72.0147","Peru","PE","PER","Arequipa","","28305","1604134214"
"Djugu","Djugu","1.9184","30.5019","Congo (Kinshasa)","CD","COD","Ituri","","28061","1180240354"
"Três Marias","Tres Marias","-18.2058","-45.2419","Brazil","BR","BRA","Minas Gerais","","28318","1076622551"
"Forest Hills","Forest Hills","42.9577","-85.4895","United States","US","USA","Michigan","","28327","1840004304"
"Leimen","Leimen","49.3481","8.6911","Germany","DE","DEU","Baden-Württemberg","","27016","1276081089"
"Bakhor","Bakhor","38.5500","68.9833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","28276","1762767243"
"Spring","Spring","40.3038","-76.0262","United States","US","USA","Pennsylvania","","28316","1840142060"
"Gusev","Gusev","54.5833","22.2000","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","28257","1643116529"
"Oltinko‘l","Oltinko\`l","43.0758","58.9067","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","28300","1860833822"
"Grimma","Grimma","51.2386","12.7253","Germany","DE","DEU","Saxony","","28181","1276049939"
"Illkirch-Graffenstaden","Illkirch-Graffenstaden","48.5300","7.7200","France","FR","FRA","Grand Est","","27087","1250689150"
"Wetter (Ruhr)","Wetter (Ruhr)","51.3881","7.3950","Germany","DE","DEU","North Rhine-Westphalia","","27441","1276406634"
"Esztergom","Esztergom","47.7856","18.7403","Hungary","HU","HUN","Komárom-Esztergom","minor","28026","1348527410"
"Arerāj","Areraj","26.5527","84.6726","India","IN","IND","Bihār","","26869","1356766179"
"Bellaa","Bellaa","30.0314","-9.5542","Morocco","MA","MAR","Souss-Massa","","27699","1504167169"
"Piešťany","Piest'any","48.5842","17.8336","Slovakia","SK","SVK","Trnava","minor","27681","1703636029"
"Kevelaer","Kevelaer","51.5833","6.2500","Germany","DE","DEU","North Rhine-Westphalia","","28021","1276753170"
"Belpasso","Belpasso","37.5833","14.9833","Italy","IT","ITA","Sicilia","","28126","1380875925"
"Majayjay","Majayjay","14.1463","121.4729","Philippines","PH","PHL","Laguna","","27893","1608576645"
"Vakfıkebir","Vakfikebir","41.0475","39.2798","Turkey","TR","TUR","Trabzon","minor","28209","1792101092"
"Svendborg","Svendborg","55.0594","10.6083","Denmark","DK","DNK","Syddanmark","minor","27300","1208924964"
"Mainit","Mainit","9.5369","125.5231","Philippines","PH","PHL","Surigao del Norte","","28019","1608163552"
"Ajka","Ajka","47.1006","17.5522","Hungary","HU","HUN","Veszprém","minor","27995","1348671384"
"Cabangan","Cabangan","15.1333","120.1500","Philippines","PH","PHL","Zambales","","28118","1608614054"
"Alga","Alga","49.9028","57.3333","Kazakhstan","KZ","KAZ","","minor","28267","1398920371"
"Safājā","Safaja","26.7333","33.9333","Egypt","EG","EGY","Al Baḩr al Aḩmar","","28261","1818038126"
"Åkersberga","Akersberga","59.4833","18.3000","Sweden","SE","SWE","Stockholm","minor","28033","1752878685"
"Hiji","Hiji","33.3694","131.5324","Japan","JP","JPN","Ōita","","27887","1392251373"
"Obama","Obama","35.4957","135.7466","Japan","JP","JPN","Fukui","","28142","1392050824"
"Zacoalco de Torres","Zacoalco de Torres","20.2333","-103.5833","Mexico","MX","MEX","Jalisco","minor","28205","1484373793"
"Independence","Independence","38.9510","-84.5492","United States","US","USA","Kentucky","","28256","1840014267"
"Cuartero","Cuartero","11.3428","122.6714","Philippines","PH","PHL","Capiz","","27993","1608559971"
"Bad Neuenahr-Ahrweiler","Bad Neuenahr-Ahrweiler","50.5447","7.1133","Germany","DE","DEU","Rhineland-Palatinate","minor","28251","1276768738"
"Dar Bel Hamri","Dar Bel Hamri","34.1889","-5.9697","Morocco","MA","MAR","Rabat-Salé-Kénitra","","28156","1504221505"
"Santa Cruz Cabrália","Santa Cruz Cabralia","-16.2778","-39.0250","Brazil","BR","BRA","Bahia","","28226","1076118882"
"Bardaskan","Bardaskan","35.2608","57.9697","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","28233","1364509063"
"Enterprise","Enterprise","31.3275","-85.8463","United States","US","USA","Alabama","","28242","1840001548"
"Nainijor","Nainijor","25.6811","84.3456","India","IN","IND","Bihār","","26929","1356679305"
"Nunspeet","Nunspeet","52.3833","5.7833","Netherlands","NL","NLD","Gelderland","minor","28021","1528766305"
"Čapljina","Capljina","43.1118","17.7055","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","28122","1070896558"
"Branford","Branford","41.2843","-72.7979","United States","US","USA","Connecticut","","28230","1840044985"
"Caucete","Caucete","-31.6500","-68.2833","Argentina","AR","ARG","San Juan","minor","28222","1032782355"
"Tuku","Tuku","23.6911","120.3620","Taiwan","TW","TWN","Yunlin","","27662","1158715962"
"Çiftlik","Ciftlik","38.1667","34.4833","Turkey","TR","TUR","Niğde","minor","28168","1792535198"
"Navahrudak","Navahrudak","53.5833","25.8167","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","","28199","1112500125"
"San Miniato","San Miniato","43.6833","10.8500","Italy","IT","ITA","Tuscany","","27950","1380217161"
"Kłodzko","Klodzko","50.4333","16.6500","Poland","PL","POL","Dolnośląskie","minor","27193","1616824014"
"Almazora","Almazora","39.9403","-0.0625","Spain","ES","ESP","Valencia","","27383","1724564412"
"Rapperswil-Jona","Rapperswil-Jona","47.2167","8.8167","Switzerland","CH","CHE","Sankt Gallen","minor","26995","1756219797"
"Qujingpu","Qujingpu","38.0814","106.0489","China","CN","CHN","Ningxia","","28086","1156642316"
"Honchō","Honcho","41.8957","140.6944","Japan","JP","JPN","Hokkaidō","","28073","1392772650"
"Āron","Aron","24.3811","77.4174","India","IN","IND","Madhya Pradesh","","27954","1356401971"
"Henderson","Henderson","37.8397","-87.5798","United States","US","USA","Kentucky","","28201","1840014337"
"Guma","Guma","37.6168","78.2809","China","CN","CHN","Xinjiang","minor","28163","1156023187"
"Kushtagi","Kushtagi","15.7562","76.1911","India","IN","IND","Karnātaka","","27961","1356579284"
"Orvault","Orvault","47.2717","-1.6225","France","FR","FRA","Pays de la Loire","","27209","1250301385"
"Centre Wellington","Centre Wellington","43.7000","-80.3667","Canada","CA","CAN","Ontario","","28191","1124000849"
"Bo‘ka","Bo\`ka","40.8136","69.2019","Uzbekistan","UZ","UZB","Toshkent","","28000","1860263265"
"Babar","Babar","35.1692","7.1013","Algeria","DZ","DZA","Khenchela","","28182","1012018384"
"Glen Cove","Glen Cove","40.8709","-73.6287","United States","US","USA","New York","","28187","1840000987"
"Shuiding","Shuiding","44.0500","80.8667","China","CN","CHN","Xinjiang","","27702","1156440544"
"Dajabón","Dajabon","19.5667","-71.7100","Dominican Republic","DO","DOM","Cibao Noroeste","minor","28071","1214367800"
"Arqalyq","Arqalyq","50.2481","66.9278","Kazakhstan","KZ","KAZ","","minor","28169","1398980293"
"Geilenkirchen","Geilenkirchen","50.9653","6.1194","Germany","DE","DEU","North Rhine-Westphalia","","27836","1276421191"
"Aloran","Aloran","8.4146","123.8228","Philippines","PH","PHL","Misamis Occidental","","27934","1608445813"
"Oppegård","Oppegard","59.7925","10.7903","Norway","NO","NOR","Viken","","27394","1578023739"
"Khrestivka","Khrestivka","48.1464","38.3606","Ukraine","UA","UKR","Donetska Oblast","","28149","1804952819"
"Mima","Mima","34.0500","134.1667","Japan","JP","JPN","Tokushima","","28087","1392003474"
"Szentendre","Szentendre","47.7044","19.0686","Hungary","HU","HUN","Pest","minor","27534","1348281649"
"Ipubi","Ipubi","-7.6519","-40.1489","Brazil","BR","BRA","Pernambuco","","28120","1076834456"
"San Felipe Jalapa de Díaz","San Felipe Jalapa de Diaz","18.0716","-96.5352","Mexico","MX","MEX","Oaxaca","","27979","1484014091"
"La Paz Centro","La Paz Centro","12.3397","-86.6758","Nicaragua","NI","NIC","León","minor","28118","1558538095"
"Sandrandahy","Sandrandahy","-20.3500","47.3000","Madagascar","MG","MDG","Fianarantsoa","","28000","1450900156"
"Tsinjoarivo","Tsinjoarivo","-19.6333","47.6833","Madagascar","MG","MDG","Antananarivo","","28108","1450592265"
"Llallagua","Llallagua","-18.4167","-66.5833","Bolivia","BO","BOL","Potosí","","28069","1068159509"
"Vadnagar","Vadnagar","23.7850","72.6400","India","IN","IND","Gujarāt","","27790","1356242825"
"Ambohijanahary","Ambohijanahary","-17.4000","48.3667","Madagascar","MG","MDG","Toamasina","","28000","1450399017"
"Bayang","Bayang","7.7930","124.1920","Philippines","PH","PHL","Lanao del Sur","","28023","1608918894"
"Maryland Heights","Maryland Heights","38.7189","-90.4749","United States","US","USA","Missouri","","28141","1840008591"
"Talisay","Talisay","14.1356","122.9244","Philippines","PH","PHL","Camarines Norte","","27244","1608025167"
"Greenville","Greenville","33.1116","-96.1099","United States","US","USA","Texas","","28128","1840020649"
"Pô","Po","11.1667","-1.1500","Burkina Faso","BF","BFA","Centre-Sud","minor","28079","1854979912"
"Moulay Bousselham","Moulay Bousselham","34.8786","-6.2933","Morocco","MA","MAR","Rabat-Salé-Kénitra","","26608","1504188471"
"Langdu","Langdu","23.3129","102.2283","China","CN","CHN","Yunnan","","27859","1156238029"
"Shaler","Shaler","40.5229","-79.9632","United States","US","USA","Pennsylvania","","28117","1840150314"
"Imito","Imito","-20.4167","47.3167","Madagascar","MG","MDG","Fianarantsoa","","28000","1450451117"
"Yangyuhe","Yangyuhe","33.8614","109.8740","China","CN","CHN","Shaanxi","","27971","1156768896"
"Guaimaca","Guaimaca","14.5333","-86.8167","Honduras","HN","HND","Francisco Morazán","","28076","1340628932"
"Blerick","Blerick","51.3667","6.1500","Netherlands","NL","NLD","Limburg","","27330","1528516428"
"Peniche","Peniche","39.3500","-9.3667","Portugal","PT","PRT","Leiria","minor","27753","1620744996"
"Nakhal","Nakhal","23.3833","57.8167","Oman","OM","OMN","Janūb al Bāţinah","","28088","1512392286"
"Tainai","Tainai","38.0597","139.4103","Japan","JP","JPN","Niigata","","28004","1392439704"
"Sahavato","Sahavato","-20.6000","48.3333","Madagascar","MG","MDG","Fianarantsoa","","28000","1450860730"
"Nallıhan","Nallihan","40.1836","31.3506","Turkey","TR","TUR","Ankara","minor","28091","1792689244"
"Koewarasan","Koewarasan","5.7697","-55.2922","Suriname","SR","SUR","Wanica","","27713","1740587100"
"Hazar","Hazar","39.4450","53.1150","Turkmenistan","TM","TKM","Balkan","","28095","1795996343"
"Khulm","Khulm","36.6833","67.6833","Afghanistan","AF","AFG","Balkh","minor","28078","1004486677"
"Tara","Tara","56.8833","74.3667","Russia","RU","RUS","Omskaya Oblast’","","28099","1643015750"
"Xochistlahuaca","Xochistlahuaca","16.7914","-98.2419","Mexico","MX","MEX","Guerrero","minor","28089","1484003499"
"Bixby","Bixby","35.9454","-95.8776","United States","US","USA","Oklahoma","","28091","1840019058"
"Mādhura","Madhura","26.3388","87.1027","India","IN","IND","Bihār","","27023","1356925430"
"Neptune","Neptune","40.2105","-74.0539","United States","US","USA","New Jersey","","28083","1840081642"
"Kushva","Kushva","58.2833","59.7333","Russia","RU","RUS","Sverdlovskaya Oblast’","","28060","1643697197"
"Citong","Citong","23.7729","120.5277","Taiwan","TW","TWN","Yunlin","","27538","1158010048"
"Lathrop","Lathrop","37.8090","-121.3131","United States","US","USA","California","","28078","1840020266"
"New Milford","New Milford","41.6042","-73.4213","United States","US","USA","Connecticut","","28074","1840035508"
"Aşağı Göycəli","Asagi Goycali","41.1158","45.4853","Azerbaijan","AZ","AZE","Ağstafa","","28000","1031509183"
"Villazón","Villazon","-22.0911","-65.5961","Bolivia","BO","BOL","Potosí","","28045","1068163517"
"Vícar","Vicar","36.8317","-2.6431","Spain","ES","ESP","Andalusia","","27631","1724429496"
"Ahmadābād","Ahmadabad","25.3019","87.7938","India","IN","IND","Bihār","","26513","1356352188"
"Shirley","Shirley","40.7936","-72.8748","United States","US","USA","New York","","28059","1840005097"
"Lincoln","Lincoln","-34.8500","-61.5167","Argentina","AR","ARG","Buenos Aires","minor","28051","1032332688"
"Tehuipango","Tehuipango","18.5167","-97.0500","Mexico","MX","MEX","Veracruz","minor","26322","1484975485"
"East Haven","East Haven","41.2988","-72.8575","United States","US","USA","Connecticut","","28054","1840034619"
"Stratford-upon-Avon","Stratford-upon-Avon","52.1928","-1.7064","United Kingdom","GB","GBR","Warwickshire","","27445","1826756514"
"Yahaba","Yahaba","39.6060","141.1429","Japan","JP","JPN","Iwate","","27638","1392003189"
"Bujaru","Bujaru","-1.5150","-48.0450","Brazil","BR","BRA","Pará","","28016","1076411520"
"Sorochinsk","Sorochinsk","52.4167","53.1000","Russia","RU","RUS","Orenburgskaya Oblast’","","27547","1643278533"
"Sueca","Sueca","39.2026","-0.3112","Spain","ES","ESP","Valencia","","27738","1724472560"
"Kakuda","Kakuda","37.9770","140.7821","Japan","JP","JPN","Miyagi","","27848","1392278934"
"Dame-Marie","Dame-Marie","18.5667","-74.4167","Haiti","HT","HTI","Grand’Anse","","27127","1332046251"
"Khātegaon","Khategaon","22.5957","76.9133","India","IN","IND","Madhya Pradesh","","27745","1356796220"
"San Pedro del Pinatar","San Pedro del Pinatar","37.8167","-0.7500","Spain","ES","ESP","Murcia","","26827","1724144996"
"San Andrés Sajcabajá","San Andres Sajcabaja","15.1756","-90.9417","Guatemala","GT","GTM","Quiché","minor","27962","1320184261"
"La Oliva","La Oliva","28.6167","-13.9333","Spain","ES","ESP","Canary Islands","","27945","1724741564"
"Muchun","Muchun","39.8833","69.1833","Tajikistan","TJ","TJK","Sughd","","27835","1762037289"
"Garden City","Garden City","37.9753","-100.8527","United States","US","USA","Kansas","","28022","1840001669"
"Gameleira","Gameleira","-8.5844","-35.3867","Brazil","BR","BRA","Pernambuco","","27912","1076789377"
"Oakdale","Oakdale","44.9876","-92.9641","United States","US","USA","Minnesota","","28019","1840007813"
"Anajás","Anajas","-0.9869","-49.9400","Brazil","BR","BRA","Pará","","28012","1076668693"
"Varandarapilli","Varandarapilli","10.4167","76.3333","India","IN","IND","Kerala","","27343","1356143594"
"Timbiras","Timbiras","-4.2550","-43.9408","Brazil","BR","BRA","Maranhão","","27997","1076776751"
"Uchturpan","Uchturpan","41.2136","79.2319","China","CN","CHN","Xinjiang","minor","27836","1156052031"
"Kasangulu","Kasangulu","-4.5911","15.1703","Congo (Kinshasa)","CD","COD","Kongo Central","","27961","1180582533"
"Punceres","Punceres","9.9661","-63.1391","Venezuela","VE","VEN","Monagas","","27954","1862883655"
"Charkhāri","Charkhari","25.4000","79.7500","India","IN","IND","Uttar Pradesh","","27760","1356368808"
"South Laurel","South Laurel","39.0603","-76.8456","United States","US","USA","Maryland","","27998","1840031498"
"Dois Irmãos","Dois Irmaos","-29.5800","-51.0850","Brazil","BR","BRA","Rio Grande do Sul","","27572","1076193510"
"Quiroga","Quiroga","19.6638","-101.5240","Mexico","MX","MEX","Michoacán","minor","27862","1484553970"
"Careiro da Várzea","Careiro da Varzea","-3.2208","-59.8258","Brazil","BR","BRA","Amazonas","","27981","1076363917"
"Pachor","Pachor","23.7098","76.7339","India","IN","IND","Madhya Pradesh","","27639","1356105636"
"Tsinjoarivo","Tsinjoarivo","-18.9333","46.3167","Madagascar","MG","MDG","Antananarivo","","27934","1450298456"
"Ridgecrest","Ridgecrest","35.6308","-117.6622","United States","US","USA","California","","27989","1840020417"
"Kriens","Kriens","47.0333","8.2833","Switzerland","CH","CHE","Luzern","","26997","1756901807"
"Hikawa","Hikawa","35.3929","132.8432","Japan","JP","JPN","Shimane","","27641","1392003296"
"Ampasimanolotra","Ampasimanolotra","-18.8194","49.0736","Madagascar","MG","MDG","Toamasina","","27865","1450133972"
"Kupiansk","Kupiansk","49.7064","37.6167","Ukraine","UA","UKR","Kharkivska Oblast","minor","27169","1804300255"
"Chíos","Chios","38.3725","26.1375","Greece","GR","GRC","Vóreio Aigaío","minor","26850","1300169896"
"Pedernales","Pedernales","18.0333","-71.7500","Dominican Republic","DO","DOM","Enriquillo","minor","27955","1214415453"
"San Isidro","San Isidro","12.3880","124.3310","Philippines","PH","PHL","Northern Samar","","27867","1608612789"
"Huitiupán","Huitiupan","17.2333","-92.7000","Mexico","MX","MEX","Chiapas","minor","27893","1484743259"
"Niuchangqiao","Niuchangqiao","26.6247","106.0077","China","CN","CHN","Guizhou","","27651","1156561910"
"San Antonio del Monte","San Antonio del Monte","13.7167","-89.7333","El Salvador","SV","SLV","Sonsonate","","26902","1222683305"
"Bouka","Bouka","10.2167","3.1333","Benin","BJ","BEN","Borgou","","27906","1204320470"
"Kholmsk","Kholmsk","47.0500","142.0500","Russia","RU","RUS","Sakhalinskaya Oblast’","","27954","1643364292"
"Wilmslow","Wilmslow","53.3250","-2.2390","United Kingdom","GB","GBR","Cheshire East","","26213","1826883084"
"Dingalan","Dingalan","15.3833","121.4000","Philippines","PH","PHL","Aurora","","27878","1608912340"
"Imatra","Imatra","61.1833","28.7667","Finland","FI","FIN","Etelä-Karjala","minor","27835","1246114111"
"Ternivka","Ternivka","48.5231","36.0826","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","27929","1804507506"
"Danvers","Danvers","42.5740","-70.9494","United States","US","USA","Massachusetts","","27966","1840053528"
"Ambohitompoina","Ambohitompoina","-19.7500","47.5667","Madagascar","MG","MDG","Antananarivo","","27899","1450129746"
"Nochistlán de Mejía","Nochistlan de Mejia","21.3642","-102.8464","Mexico","MX","MEX","Zacatecas","minor","27932","1484761642"
"San Martín de los Andes","San Martin de los Andes","-40.1667","-71.3500","Argentina","AR","ARG","Neuquén","minor","27956","1032807074"
"Algemesí","Algemesi","39.1897","-0.4378","Spain","ES","ESP","Valencia","","27305","1724231513"
"Mocímboa","Mocimboa","-11.3196","40.3500","Mozambique","MZ","MOZ","Cabo Delgado","","27909","1508815299"
"Shalqar","Shalqar","47.8333","59.6000","Kazakhstan","KZ","KAZ","","","27957","1398440872"
"Kashima","Kashima","33.1036","130.0989","Japan","JP","JPN","Saga","","27710","1392003378"
"Dahmani","Dahmani","35.9500","8.8333","Tunisia","TN","TUN","Kef","","27907","1788333939"
"Bitam","Bitam","2.0833","11.4833","Gabon","GA","GAB","Woleu-Ntem","","27923","1266921836"
"Mateus Leme","Mateus Leme","-19.9858","-44.4278","Brazil","BR","BRA","Minas Gerais","","27856","1076446394"
"Millville","Millville","39.3903","-75.0561","United States","US","USA","New Jersey","","27946","1840001550"
"Jaguarão","Jaguarao","-32.5658","-53.3758","Brazil","BR","BRA","Rio Grande do Sul","","27931","1076878261"
"Sundern","Sundern","51.3167","8.0000","Germany","DE","DEU","North Rhine-Westphalia","","27802","1276362137"
"Sabaneta","Sabaneta","8.7522","-69.9325","Venezuela","VE","VEN","Barinas","minor","27850","1862775956"
"Thetford","Thetford","52.4100","0.7400","United Kingdom","GB","GBR","Norfolk","","27027","1826591780"
"Qibray","Qibray","41.3897","69.4650","Uzbekistan","UZ","UZB","Toshkent","","27750","1860400736"
"El Sauce","El Sauce","12.8861","-86.5389","Nicaragua","NI","NIC","León","minor","27900","1558542535"
"Tenares","Tenares","19.3700","-70.3500","Dominican Republic","DO","DOM","Cibao Nordeste","","27765","1214033722"
"Wappinger","Wappinger","41.5899","-73.8918","United States","US","USA","New York","","27939","1840088006"
"Kahului","Kahului","20.8715","-156.4603","United States","US","USA","Hawaii","","27938","1840023232"
"Larreynaga","Larreynaga","12.5939","-86.6797","Nicaragua","NI","NIC","León","","27898","1558602365"
"Tlalpujahua de Rayón","Tlalpujahua de Rayon","19.8052","-100.1741","Mexico","MX","MEX","Michoacán","minor","27788","1484026388"
"Santa Bárbara","Santa Barbara","14.4333","-91.2333","Guatemala","GT","GTM","Suchitepéquez","minor","27708","1320803022"
"Valente","Valente","-11.4119","-39.4619","Brazil","BR","BRA","Bahia","","27906","1076278646"
"Santa Bárbara","Santa Barbara","-19.9589","-43.4150","Brazil","BR","BRA","Minas Gerais","","27876","1076509484"
"Rambouillet","Rambouillet","48.6444","1.8308","France","FR","FRA","Île-de-France","minor","27141","1250752872"
"Shilan","Shilan","21.9053","110.7151","China","CN","CHN","Guangdong","","27625","1156102859"
"Kent","Kent","41.1490","-81.3610","United States","US","USA","Ohio","","27912","1840008256"
"Guaçuí","Guacui","-20.7758","-41.6789","Brazil","BR","BRA","Espírito Santo","","27851","1076047001"
"Kongsberg","Kongsberg","59.6694","9.6517","Norway","NO","NOR","Viken","minor","27879","1578035378"
"Ludwigsfelde","Ludwigsfelde","52.2997","13.2667","Germany","DE","DEU","Brandenburg","","27658","1276940684"
"Williamsport","Williamsport","41.2399","-77.0370","United States","US","USA","Pennsylvania","","27908","1840000637"
"Sens","Sens","48.1975","3.2877","France","FR","FRA","Bourgogne-Franche-Comté","minor","26688","1250989326"
"Alitagtag","Alitagtag","13.8650","121.0063","Philippines","PH","PHL","Batangas","","26819","1608000702"
"Dodge City","Dodge City","37.7611","-100.0182","United States","US","USA","Kansas","","27899","1840001684"
"Arraial do Cabo","Arraial do Cabo","-22.9658","-42.0278","Brazil","BR","BRA","Rio de Janeiro","","27715","1076235224"
"Cártama","Cartama","36.7114","-4.6306","Spain","ES","ESP","Andalusia","","27712","1724807752"
"Wilmette","Wilmette","42.0771","-87.7282","United States","US","USA","Illinois","","27895","1840011316"
"Piraí","Pirai","-22.6289","-43.8978","Brazil","BR","BRA","Rio de Janeiro","","27838","1076693567"
"Andenne","Andenne","50.4833","5.1000","Belgium","BE","BEL","Wallonia","","27573","1056666375"
"Molíns de Rey","Molins de Rey","41.4139","2.0158","Spain","ES","ESP","Catalonia","","26242","1724528554"
"Thātha","Thatha","25.4988","86.5908","India","IN","IND","Bihār","","26766","1356260743"
"Pomerode","Pomerode","-26.7408","-49.1769","Brazil","BR","BRA","Santa Catarina","","27759","1076620376"
"Bignona","Bignona","12.8167","-16.2333","Senegal","SN","SEN","Ziguinchor","","27826","1686478890"
"Mahaplag","Mahaplag","10.6072","124.9636","Philippines","PH","PHL","Leyte","","27865","1608946105"
"Hādīshahr","Hadishahr","38.8367","45.6683","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","27842","1364264141"
"Topki","Topki","55.2833","85.6167","Russia","RU","RUS","Kemerovskaya Oblast’","","27860","1643475451"
"Villa Luvianos","Villa Luvianos","18.9200","-100.2983","Mexico","MX","MEX","México","minor","27860","1484500757"
"Chalhuanca","Chalhuanca","-14.2950","-73.2444","Peru","PE","PER","Apurímac","","27857","1604068252"
"Salinas Victoria","Salinas Victoria","25.9667","-100.3000","Mexico","MX","MEX","Nuevo León","minor","27848","1484014927"
"Atlautla","Atlautla","19.0000","-98.7167","Mexico","MX","MEX","México","minor","27663","1484312852"
"Mboro","Mboro","15.1500","-16.9000","Senegal","SN","SEN","Thiès","","27693","1686694714"
"Traipu","Traipu","-9.9708","-37.0028","Brazil","BR","BRA","Alagoas","","27826","1076310468"
"Porto Sant’Elpidio","Porto Sant'Elpidio","43.2667","13.7500","Italy","IT","ITA","Marche","","26408","1380059589"
"Apaxco de Ocampo","Apaxco de Ocampo","19.9800","-99.1700","Mexico","MX","MEX","Hidalgo","minor","27521","1484505240"
"Lufeng","Lufeng","24.5738","103.1130","China","CN","CHN","Yunnan","","27731","1156933878"
"Zolotonosha","Zolotonosha","49.6833","32.0333","Ukraine","UA","UKR","Cherkaska Oblast","minor","27847","1804210857"
"Ðakovo","Dakovo","45.3100","18.4100","Croatia","HR","HRV","Osječko-Baranjska Županija","minor","27745","1191845338"
"Sandūr","Sandur","15.1000","76.5500","India","IN","IND","Karnātaka","","27614","1356076024"
"Ōuda-yamaguchi","Ouda-yamaguchi","34.5167","135.9667","Japan","JP","JPN","Nara","","27747","1392926898"
"Soron","Soron","27.8800","78.7500","India","IN","IND","Uttar Pradesh","","26722","1356965322"
"Soignies","Soignies","50.5667","4.0667","Belgium","BE","BEL","Wallonia","minor","27603","1056957745"
"Pau dos Ferros","Pau dos Ferros","-6.1108","-38.2089","Brazil","BR","BRA","Rio Grande do Norte","","27745","1076579928"
"Fūman","Fuman","37.2239","49.3125","Iran","IR","IRN","Gīlān","minor","27763","1364180869"
"Veendam","Veendam","53.1000","6.8833","Netherlands","NL","NLD","Groningen","minor","27491","1528706438"
"Chicago Heights","Chicago Heights","41.5100","-87.6345","United States","US","USA","Illinois","","27840","1840007024"
"Bernards","Bernards","40.6761","-74.5677","United States","US","USA","New Jersey","","27836","1840081704"
"Burnie","Burnie","-41.0636","145.8753","Australia","AU","AUS","Tasmania","","27174","1036394657"
"Clinton","Clinton","32.3540","-90.3403","United States","US","USA","Mississippi","","27830","1840014893"
"Maloyaroslavets","Maloyaroslavets","55.0167","36.4667","Russia","RU","RUS","Kaluzhskaya Oblast’","","27791","1643014206"
"Arnstadt","Arnstadt","50.8342","10.9464","Germany","DE","DEU","Thuringia","minor","27330","1276728389"
"Lapuyan","Lapuyan","7.6325","123.1914","Philippines","PH","PHL","Zamboanga del Sur","","27737","1608412170"
"Tualatin","Tualatin","45.3772","-122.7748","United States","US","USA","Oregon","","27821","1840021207"
"Iguaí","Iguai","-14.7558","-40.0889","Brazil","BR","BRA","Bahia","","27787","1076176449"
"Pāyakarāopeta","Payakaraopeta","17.4161","82.6144","India","IN","IND","Andhra Pradesh","","27001","1356611577"
"Viñales","Vinales","22.6153","-83.7158","Cuba","CU","CUB","Pinar del Río","minor","27771","1192851636"
"Andapa","Andapa","-14.6500","49.6500","Madagascar","MG","MDG","Antsiranana","","27618","1450290201"
"Uchinada","Uchinada","36.6536","136.6450","Japan","JP","JPN","Ishikawa","","26503","1392003199"
"Zarraga","Zarraga","10.8167","122.6000","Philippines","PH","PHL","Iloilo","","27305","1608101435"
"Pitogo","Pitogo","7.4536","123.3133","Philippines","PH","PHL","Zamboanga del Sur","","27516","1608000920"
"Lagoa Vermelha","Lagoa Vermelha","-28.2089","-51.5258","Brazil","BR","BRA","Rio Grande do Sul","","27778","1076588134"
"Penukonda","Penukonda","14.0847","77.5967","India","IN","IND","Andhra Pradesh","","27382","1356305205"
"Zakopane","Zakopane","49.3000","19.9500","Poland","PL","POL","Małopolskie","minor","27490","1616298051"
"Hayang","Hayang","35.9167","128.8167","South Korea","KR","KOR","Gyeongbuk","","27236","1410183086"
"Mukher","Mukher","18.7008","77.3628","India","IN","IND","Mahārāshtra","","27650","1356126979"
"Havran","Havran","39.5583","27.0983","Turkey","TR","TUR","Balıkesir","minor","27741","1792883078"
"Gabasumdo","Gabasumdo","35.2554","100.5693","China","CN","CHN","Qinghai","minor","27742","1156270760"
"Axim","Axim","4.8667","-2.2333","Ghana","GH","GHA","Western","","27719","1288413384"
"Mananjary","Mananjary","-21.2311","48.3419","Madagascar","MG","MDG","Fianarantsoa","","27686","1450681595"
"Limoeiro do Ajuru","Limoeiro do Ajuru","-1.8950","-49.3808","Brazil","BR","BRA","Pará","","27760","1076973089"
"Statesville","Statesville","35.7842","-80.8713","United States","US","USA","North Carolina","","27777","1840015358"
"Gioia del Colle","Gioia del Colle","40.8000","16.9333","Italy","IT","ITA","Puglia","","27644","1380731346"
"Borşa","Borsa","47.6553","24.6631","Romania","RO","ROU","Maramureş","","27711","1642172332"
"Long Thành","Long Thanh","10.8667","106.9167","Vietnam","VN","VNM","Đồng Nai","minor","27084","1704003595"
"Jarrow","Jarrow","54.9814","-1.4900","United Kingdom","GB","GBR","South Tyneside","","27526","1826615056"
"Kitsuki","Kitsuki","33.4167","131.6167","Japan","JP","JPN","Ōita","","27668","1392339287"
"Mirano","Mirano","45.5000","12.1000","Italy","IT","ITA","Veneto","","27169","1380832945"
"Di Linh","Di Linh","11.5778","108.0751","Vietnam","VN","VNM","Lâm Đồng","minor","27645","1704189593"
"Marion","Marion","40.5497","-85.6604","United States","US","USA","Indiana","","27757","1840013868"
"Itako","Itako","35.9471","140.5554","Japan","JP","JPN","Ibaraki","","27373","1392908453"
"Pirapòzinho","Pirapozinho","-22.2753","-51.5000","Brazil","BR","BRA","São Paulo","","27754","1076099500"
"Lainate","Lainate","45.5667","9.0333","Italy","IT","ITA","Lombardy","","25763","1380262791"
"Nova Prata","Nova Prata","-28.7839","-51.6100","Brazil","BR","BRA","Rio Grande do Sul","","27648","1076463045"
"K’olīto","K'olito","7.3122","38.0892","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","27359","1231294595"
"Leon Postigo","Leon Postigo","8.1514","122.9244","Philippines","PH","PHL","Zamboanga del Norte","","27639","1608711286"
"Lebrija","Lebrija","36.9194","-6.0781","Spain","ES","ESP","Andalusia","","27665","1724598585"
"Boca da Mata","Boca da Mata","-9.6408","-36.2200","Brazil","BR","BRA","Alagoas","","27590","1076171767"
"Mazenod","Mazenod","-29.4642","27.4969","Lesotho","LS","LSO","Maseru","","27553","1426996323"
"Batgram","Batgram","34.6833","73.0167","Pakistan","PK","PAK","Khyber Pakhtunkhwa","minor","27083","1586989930"
"Bandipura","Bandipura","34.4225","74.6375","India","IN","IND","Jammu and Kashmīr","","27482","1356796191"
"Ágios Nikólaos","Agios Nikolaos","35.1833","25.7167","Greece","GR","GRC","Kríti","minor","27074","1300945111"
"Timaru","Timaru","-44.3931","171.2508","New Zealand","NZ","NZL","Canterbury","","27501","1554243304"
"Zongdi","Zongdi","25.5909","106.3163","China","CN","CHN","Guizhou","","27651","1156725646"
"Sūlūru","Suluru","13.7000","80.0180","India","IN","IND","Andhra Pradesh","","27504","1356969200"
"Ayyagarpet","Ayyagarpet","17.2197","80.8339","India","IN","IND","Andhra Pradesh","","27393","1356265892"
"Troyan","Troyan","42.8833","24.7167","Bulgaria","BG","BGR","Lovech","","27687","1100528745"
"Longtan","Longtan","40.7830","115.5754","China","CN","CHN","Hebei","","27639","1156698437"
"Zarechnyy","Zarechnyy","56.8167","61.3333","Russia","RU","RUS","Sverdlovskaya Oblast’","","27617","1643142776"
"Supía","Supia","5.4667","-75.6500","Colombia","CO","COL","Caldas","minor","27489","1170656633"
"Argelia","Argelia","2.2431","-77.2786","Colombia","CO","COL","Cauca","minor","27670","1170028016"
"Narat","Narat","43.3198","84.0147","China","CN","CHN","Xinjiang","","27572","1156203198"
"Kapellen","Kapellen","51.3167","4.4333","Belgium","BE","BEL","Flanders","","26981","1056965326"
"Ukiha","Ukiha","33.3500","130.7500","Japan","JP","JPN","Fukuoka","","27474","1392003451"
"Curtea de Argeş","Curtea de Arges","45.1392","24.6792","Romania","RO","ROU","Argeş","","27359","1642859797"
"Margate","Margate","-30.8500","30.3667","South Africa","ZA","ZAF","KwaZulu-Natal","","26785","1710711260"
"Ocean","Ocean","40.2520","-74.0392","United States","US","USA","New Jersey","","27697","1840056382"
"Mankāchar","Mankachar","25.5300","89.8700","India","IN","IND","Meghālaya","","26162","1356498655"
"North Kingstown","North Kingstown","41.5687","-71.4629","United States","US","USA","Rhode Island","","27696","1840066135"
"San Juan Guichicovi","San Juan Guichicovi","16.9667","-95.0833","Mexico","MX","MEX","Oaxaca","","27646","1484056048"
"Miramas","Miramas","43.5822","5.0019","France","FR","FRA","Provence-Alpes-Côte d’Azur","","26668","1250908076"
"Washington","Washington","37.1303","-113.4878","United States","US","USA","Utah","","27689","1840021561"
"Lohne","Lohne","52.6667","8.2386","Germany","DE","DEU","Lower Saxony","","27387","1276514079"
"Choba","Choba","4.8906","6.9033","Nigeria","NG","NGA","Rivers","","27253","1566302729"
"Hulst","Hulst","51.2833","4.0500","Netherlands","NL","NLD","Zeeland","minor","27575","1528357490"
"Padre Bernardo","Padre Bernardo","-15.1600","-48.2839","Brazil","BR","BRA","Goiás","","27671","1076653841"
"Vīrakeralam","Virakeralam","11.0077","76.9126","India","IN","IND","Tamil Nādu","","26392","1356024238"
"Lier","Lier","59.8675","10.2142","Norway","NO","NOR","Viken","minor","27584","1578874426"
"Ibotirama","Ibotirama","-12.1850","-43.2208","Brazil","BR","BRA","Bahia","","27655","1076512015"
"Santa Perpetua de Moguda","Santa Perpetua de Moguda","41.5375","2.1819","Spain","ES","ESP","Catalonia","","26033","1724312278"
"Gojō","Gojo","34.3486","135.6964","Japan","JP","JPN","Nara","","27578","1392081561"
"Villareal","Villareal","11.5667","124.9333","Philippines","PH","PHL","Samar","","27394","1608546195"
"Wangtang","Wangtang","19.9327","110.8750","China","CN","CHN","Hainan","","27569","1156582961"
"Immokalee","Immokalee","26.4253","-81.4251","United States","US","USA","Florida","","27669","1840014230"
"Mont-Dore","Mont-Dore","-22.2157","166.4665","New Caledonia","NC","NCL","Province Sud","","27620","1540939524"
"Ambodibonara","Ambodibonara","-20.3388","48.5177","Madagascar","MG","MDG","Toamasina","","27597","1450738968"
"Xima","Xima","37.9763","114.6259","China","CN","CHN","Hebei","","26842","1156186259"
"Malargüe","Malargue","-35.4750","-69.5833","Argentina","AR","ARG","Mendoza","minor","27660","1032824937"
"Erice","Erice","38.0369","12.5864","Italy","IT","ITA","Sicilia","","27655","1380955760"
"Shāhganj","Shahganj","26.0560","82.6820","India","IN","IND","Uttar Pradesh","","26556","1356607208"
"Itacaré","Itacare","-14.2778","-38.9969","Brazil","BR","BRA","Bahia","","27619","1076667564"
"Manihāri","Manihari","25.3500","87.6300","India","IN","IND","Bihār","","26629","1356002043"
"Oktyabrsk","Oktyabrsk","53.1667","48.6667","Russia","RU","RUS","Samarskaya Oblast’","","26438","1643865779"
"Kobuleti","Kobuleti","41.8111","41.7753","Georgia","GE","GEO","Ajaria","minor","27546","1268789492"
"Morón de la Frontera","Moron de la Frontera","37.1222","-5.4517","Spain","ES","ESP","Andalusia","","27582","1724945093"
"Maple Valley","Maple Valley","47.3659","-122.0368","United States","US","USA","Washington","","27643","1840019828"
"Bien Unido","Bien Unido","10.1333","124.3833","Philippines","PH","PHL","Bohol","","26666","1608389168"
"Campos Gerais","Campos Gerais","-21.2350","-45.7589","Brazil","BR","BRA","Minas Gerais","","27600","1076697616"
"Wiesloch","Wiesloch","49.2942","8.6983","Germany","DE","DEU","Baden-Württemberg","","26758","1276473184"
"Parimpūdi","Parimpudi","17.1171","81.4342","India","IN","IND","Andhra Pradesh","","27122","1356403774"
"East Hampton","East Hampton","41.0117","-72.1277","United States","US","USA","New York","","27626","1840005147"
"Orosháza","Oroshaza","46.5667","20.6667","Hungary","HU","HUN","Békés","minor","27492","1348070535"
"Karlskoga","Karlskoga","59.3333","14.5167","Sweden","SE","SWE","Örebro","minor","27562","1752529628"
"Sola","Sola","58.8561","5.5750","Norway","NO","NOR","Rogaland","minor","27568","1578642286"
"Duayaw-Nkwanta","Duayaw-Nkwanta","7.1667","-2.1000","Ghana","GH","GHA","Ahafo","","27476","1288805863"
"Baytūnyā","Baytunya","31.8889","35.1675","West Bank","XW","XWB","","","26604","1934209318"
"Shencottah","Shencottah","8.9733","77.2464","India","IN","IND","Tamil Nādu","","26823","1356064369"
"Jaynagar","Jaynagar","26.5833","86.1500","India","IN","IND","Bihār","","25828","1356588241"
"Sādri","Sadri","25.1800","73.4300","India","IN","IND","Rājasthān","","27394","1356487933"
"Cabrero","Cabrero","-37.0333","-72.4000","Chile","CL","CHL","Biobío","","27595","1152020950"
"Corralillo","Corralillo","22.9856","-80.5831","Cuba","CU","CUB","Villa Clara","minor","27571","1192979534"
"Malebennūr","Malebennur","14.3537","75.7400","India","IN","IND","Karnātaka","","27023","1356684839"
"Mu’tah","Mu'tah","31.1000","35.7000","Jordan","JO","JOR","Al Karak","","27426","1400088878"
"Bluffton","Bluffton","32.2135","-80.9316","United States","US","USA","South Carolina","","27596","1840015799"
"Santuario","Santuario","6.1375","-75.2639","Colombia","CO","COL","Antioquia","minor","27120","1170679272"
"Conner","Conner","17.8086","121.2889","Philippines","PH","PHL","Apayao","","27552","1608855963"
"Waddān","Waddan","29.1611","16.1436","Libya","LY","LBY","Al Jufrah","","27590","1434593649"
"New London","New London","41.3502","-72.1022","United States","US","USA","Connecticut","","27585","1840004827"
"Nizao","Nizao","18.2441","-70.1984","Dominican Republic","DO","DOM","Valdesia","","27028","1214110366"
"Bhānder","Bhander","25.7358","78.7456","India","IN","IND","Madhya Pradesh","","27287","1356939371"
"Tongyangdao","Tongyangdao","41.7676","109.9711","China","CN","CHN","Inner Mongolia","minor","27476","1156031592"
"Tortona","Tortona","44.8942","8.8656","Italy","IT","ITA","Piedmont","","27299","1380709962"
"Marolambo","Marolambo","-20.0500","48.1167","Madagascar","MG","MDG","Toamasina","","27509","1450683142"
"Mount Pleasant","Mount Pleasant","42.7129","-87.8876","United States","US","USA","Wisconsin","","27572","1840038102"
"Haiyang","Haiyang","39.9534","119.5431","China","CN","CHN","Hebei","","27105","1156375114"
"Marshalltown","Marshalltown","42.0343","-92.9068","United States","US","USA","Iowa","","27570","1840008112"
"Gurlan","Gurlan","41.8500","60.4000","Uzbekistan","UZ","UZB","Xorazm","minor","27300","1860136766"
"Piracuruca","Piracuruca","-3.9278","-41.7089","Brazil","BR","BRA","Piauí","","27553","1076504082"
"Lapão","Lapao","-11.3828","-41.8319","Brazil","BR","BRA","Bahia","","27521","1076995374"
"Roşiori de Vede","Rosiori de Vede","44.1114","24.9942","Romania","RO","ROU","Teleorman","","27416","1642825706"
"Mulchén","Mulchen","-37.7167","-72.2333","Chile","CL","CHL","Biobío","","27557","1152030121"
"Niimi","Niimi","34.9739","133.4731","Japan","JP","JPN","Okayama","","27526","1392904313"
"Malekān","Malekan","37.1508","46.0975","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","27431","1364475423"
"West Islip","West Islip","40.7041","-73.2954","United States","US","USA","New York","","27557","1840005111"
"Santa María de Jesús","Santa Maria de Jesus","14.4933","-90.7094","Guatemala","GT","GTM","Sacatepéquez","minor","26769","1320368552"
"Ciudad Bolívar","Ciudad Bolivar","5.8500","-76.0333","Colombia","CO","COL","Antioquia","minor","27458","1170990931"
"Coromandel","Coromandel","-18.4728","-47.2000","Brazil","BR","BRA","Minas Gerais","","27547","1076143235"
"Miguel Calmon","Miguel Calmon","-11.4289","-40.5950","Brazil","BR","BRA","Bahia","","27536","1076065953"
"Neckarsulm","Neckarsulm","49.1917","9.2246","Germany","DE","DEU","Baden-Württemberg","","26492","1276644905"
"Drimmelen","Drimmelen","51.6833","4.8000","Netherlands","NL","NLD","Noord-Brabant","","27325","1528513571"
"Temescal Valley","Temescal Valley","33.7581","-117.4677","United States","US","USA","California","","27546","1840043023"
"Rasiāri","Rasiari","26.0464","86.3471","India","IN","IND","Bihār","","26279","1356103361"
"Teno","Teno","-34.8667","-71.1833","Chile","CL","CHL","Maule","","27532","1152981256"
"Overath","Overath","50.9500","7.3000","Germany","DE","DEU","North Rhine-Westphalia","","27148","1276053339"
"New Windsor","New Windsor","41.4742","-74.1089","United States","US","USA","New York","","27541","1840058348"
"İpsala","Ipsala","40.9217","26.3819","Turkey","TR","TUR","Edirne","minor","27498","1792000511"
"Ashford","Ashford","51.4340","-0.4640","United Kingdom","GB","GBR","Surrey","","27382","1826203695"
"Santa Rita do Passa Quatro","Santa Rita do Passa Quatro","-21.7100","-47.4778","Brazil","BR","BRA","São Paulo","","27502","1076791348"
"Cangas","Cangas","42.2642","-8.7819","Spain","ES","ESP","Galicia","","26832","1724669943"
"Panchānandapur","Panchanandapur","24.9339","87.9761","India","IN","IND","West Bengal","","26358","1356286330"
"Abarkūh","Abarkuh","31.1267","53.2592","Iran","IR","IRN","Yazd","minor","27524","1364624357"
"Macalelon","Macalelon","13.7500","122.1333","Philippines","PH","PHL","Quezon","","27312","1608722865"
"Montbéliard","Montbeliard","47.5100","6.8000","France","FR","FRA","Bourgogne-Franche-Comté","minor","25809","1250390358"
"Chillán Viejo","Chillan Viejo","-36.6229","-72.1317","Chile","CL","CHL","Ñuble","","27359","1152645198"
"Bidur","Bidur","27.8961","85.1456","Nepal","NP","NPL","","","26750","1524355084"
"Schloß Holte-Stukenbrock","Schloss Holte-Stukenbrock","51.8833","8.6167","Germany","DE","DEU","North Rhine-Westphalia","","27120","1276497165"
"Ramon Magsaysay","Ramon Magsaysay","8.0053","123.4869","Philippines","PH","PHL","Zamboanga del Sur","","27280","1608096621"
"Verkhniy Ufaley","Verkhniy Ufaley","56.0667","60.2333","Russia","RU","RUS","Chelyabinskaya Oblast’","","27498","1643894211"
"Strausberg","Strausberg","52.5833","13.8833","Germany","DE","DEU","Brandenburg","","27119","1276019110"
"Manbengtang","Manbengtang","22.0896","100.8931","China","CN","CHN","Yunnan","","27448","1156707030"
"Terlizzi","Terlizzi","41.1333","16.5500","Italy","IT","ITA","Puglia","","27125","1380884039"
"Ilchester","Ilchester","39.2187","-76.7683","United States","US","USA","Maryland","","27513","1840024482"
"Reoti","Reoti","25.8500","84.3833","India","IN","IND","Uttar Pradesh","","26359","1356904621"
"Sürmene","Surmene","40.9142","40.1125","Turkey","TR","TUR","Trabzon","minor","27428","1792859106"
"Marapanim","Marapanim","-0.7139","-47.6939","Brazil","BR","BRA","Pará","","27471","1076797641"
"Wasco","Wasco","35.5938","-119.3671","United States","US","USA","California","","27505","1840021738"
"Villa El Carmen","Villa El Carmen","11.9794","-86.5103","Nicaragua","NI","NIC","Managua","minor","27449","1558217117"
"Aleksandrovskoye","Aleksandrovskoye","44.7167","43.0000","Russia","RU","RUS","Stavropol’skiy Kray","","27471","1643340732"
"Lagoa Sêca","Lagoa Seca","-7.1708","-35.8539","Brazil","BR","BRA","Paraíba","","27247","1076187656"
"Lunel","Lunel","43.6769","4.1353","France","FR","FRA","Occitanie","","26385","1250801871"
"Xovos","Xovos","40.2197","68.8400","Uzbekistan","UZ","UZB","Sirdaryo","","27300","1860506724"
"Tracuateua","Tracuateua","-1.0719","-46.8969","Brazil","BR","BRA","Pará","","27455","1076920936"
"Novo Oriente","Novo Oriente","-5.5339","-40.7739","Brazil","BR","BRA","Ceará","","27453","1076000676"
"Palanas","Palanas","12.1464","123.9219","Philippines","PH","PHL","Masbate","","27322","1608982092"
"Bo’ao","Bo'ao","19.1606","110.5808","China","CN","CHN","Hainan","","27155","1156420285"
"San Felipe","San Felipe","14.6206","-91.5961","Guatemala","GT","GTM","Retalhuleu","minor","26640","1320120726"
"Ronse","Ronse","50.7500","3.6000","Belgium","BE","BEL","Flanders","","26702","1056897548"
"Kedu","Kedu","25.7103","106.8460","China","CN","CHN","Guizhou","","27363","1156431139"
"Longdian","Longdian","37.9033","115.8744","China","CN","CHN","Hebei","","27084","1156254267"
"Rockledge","Rockledge","28.3203","-80.7360","United States","US","USA","Florida","","27466","1840015095"
"Navrongo","Navrongo","10.8847","-1.0903","Ghana","GH","GHA","Upper East","","27306","1288024756"
"East Grinstead","East Grinstead","51.1300","-0.0000","United Kingdom","GB","GBR","West Sussex","","26383","1826278903"
"Chaska","Chaska","44.8164","-93.6091","United States","US","USA","Minnesota","","27461","1840006760"
"Madison","Madison","32.4738","-90.1300","United States","US","USA","Mississippi","","27459","1840015782"
"Silao","Silao","25.0836","85.4280","India","IN","IND","Bihār","","26633","1356525355"
"Zirndorf","Zirndorf","49.4500","10.9500","Germany","DE","DEU","Bavaria","","26534","1276906291"
"Ticuantepe","Ticuantepe","12.0231","-86.2064","Nicaragua","NI","NIC","Managua","minor","27008","1558748108"
"Sipacapa","Sipacapa","15.2128","-91.6342","Guatemala","GT","GTM","San Marcos","minor","27270","1320386242"
"Digboi","Digboi","27.3932","95.6184","India","IN","IND","Assam","","27129","1356278084"
"Rheinbach","Rheinbach","50.6333","6.9500","Germany","DE","DEU","North Rhine-Westphalia","","27063","1276000657"
"Oak Forest","Oak Forest","41.6054","-87.7527","United States","US","USA","Illinois","","27445","1840008132"
"Rāver","Raver","21.2431","76.0333","India","IN","IND","Mahārāshtra","","27039","1356564115"
"Citrus Park","Citrus Park","28.0730","-82.5628","United States","US","USA","Florida","","27440","1840014152"
"Burntwood","Burntwood","52.6831","-1.9200","United Kingdom","GB","GBR","Staffordshire","","26049","1826702051"
"Barcelos","Barcelos","-0.9750","-62.9239","Brazil","BR","BRA","Amazonas","","27433","1076105080"
"Vimercate","Vimercate","45.6167","9.3667","Italy","IT","ITA","Lombardy","","26170","1380150179"
"Chettipālaiyam","Chettipalaiyam","11.1667","77.3350","India","IN","IND","Tamil Nādu","","26642","1356163826"
"Sliedrecht","Sliedrecht","51.8167","4.7667","Netherlands","NL","NLD","Zuid-Holland","minor","25597","1528494904"
"Paouignan","Paouignan","7.6937","2.2673","Benin","BJ","BEN","Collines","","27351","1204769858"
"San Isidro","San Isidro","16.8667","121.7667","Philippines","PH","PHL","Isabela","","27044","1608367745"
"Panauti","Panauti","27.5844","85.5147","Nepal","NP","NPL","","","27358","1524203755"
"Zhongshan","Zhongshan","34.9430","105.8771","China","CN","CHN","Gansu","","27147","1156256431"
"Klosterneuburg","Klosterneuburg","48.3042","16.3167","Austria","AT","AUT","Niederösterreich","minor","27058","1040735965"
"Cuerámaro","Cueramaro","20.6258","-101.6739","Mexico","MX","MEX","Guanajuato","minor","27308","1484743958"
"Tall Rif‘at","Tall Rif\`at","36.4733","37.0972","Syria","SY","SYR","Ḩalab","minor","27086","1760489101"
"Haliyāl","Haliyal","15.3294","74.7633","India","IN","IND","Karnātaka","","27261","1356028927"
"Nuevo Arraiján","Nuevo Arraijan","8.9200","-79.7200","Panama","PA","PAN","Panamá Oeste","minor","26298","1591824667"
"Ramsey","Ramsey","45.2617","-93.4494","United States","US","USA","Minnesota","","27409","1840008904"
"Itaí","Itai","-23.4178","-49.0906","Brazil","BR","BRA","São Paulo","","27382","1076144804"
"Gonesse","Gonesse","48.9875","2.4494","France","FR","FRA","Île-de-France","","26106","1250682314"
"Badiangan","Badiangan","10.9860","122.5369","Philippines","PH","PHL","Iloilo","","27056","1608786837"
"Bakhchysarai","Bakhchysarai","44.7528","33.8608","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","27351","1804779344"
"Tsaravary","Tsaravary","-21.2500","48.3000","Madagascar","MG","MDG","Fianarantsoa","","27000","1450274736"
"Aalten","Aalten","51.9333","6.5833","Netherlands","NL","NLD","Gelderland","minor","27120","1528326020"
"Frankfort","Frankfort","-27.2833","28.5167","South Africa","ZA","ZAF","Free State","","26144","1710386625"
"Behara","Behara","-24.9500","46.3833","Madagascar","MG","MDG","Toliara","","27285","1450740958"
"Porto Calvo","Porto Calvo","-9.0450","-35.3978","Brazil","BR","BRA","Alagoas","","27288","1076563393"
"Takahagi","Takahagi","36.7192","140.7167","Japan","JP","JPN","Ibaraki","","27251","1392592116"
"Staraya Russa","Staraya Russa","58.0000","31.3333","Russia","RU","RUS","Novgorodskaya Oblast’","","27377","1643015974"
"Aytos","Aytos","42.7000","27.2500","Bulgaria","BG","BGR","Burgas","","27326","1100109481"
"Boralday","Boralday","43.3603","76.8578","Kazakhstan","KZ","KAZ","","","27188","1398737190"
"Huaquechula","Huaquechula","18.7667","-98.5500","Mexico","MX","MEX","Puebla","minor","26114","1484461466"
"Aschersleben","Aschersleben","51.7500","11.4667","Germany","DE","DEU","Saxony-Anhalt","","27220","1276084397"
"Newtown","Newtown","41.3988","-73.2927","United States","US","USA","Connecticut","","27388","1840004838"
"Iúna","Iuna","-20.3458","-41.5358","Brazil","BR","BRA","Espírito Santo","","27328","1076149444"
"Tāzah Khūrmātū","Tazah Khurmatu","35.3028","44.3286","Iraq","IQ","IRQ","Karkūk","","27359","1368922262"
"Mason City","Mason City","43.1487","-93.1997","United States","US","USA","Iowa","","27385","1840000380"
"Garmisch-Partenkirchen","Garmisch-Partenkirchen","47.5000","11.0833","Germany","DE","DEU","Bavaria","minor","27249","1276000934"
"Wassenaar","Wassenaar","52.1500","4.4000","Netherlands","NL","NLD","Zuid-Holland","minor","26949","1528746121"
"Sangyuan","Sangyuan","38.6211","115.5991","China","CN","CHN","Hebei","","26683","1156114240"
"Haqqulobod","Haqqulobod","40.9061","72.1194","Uzbekistan","UZ","UZB","Namangan","minor","27000","1860543628"
"Chintalapalle","Chintalapalle","15.0400","76.2000","India","IN","IND","Karnātaka","","27136","1356016287"
"Balch Springs","Balch Springs","32.7194","-96.6151","United States","US","USA","Texas","","27369","1840019435"
"Williston","Williston","48.1814","-103.6364","United States","US","USA","North Dakota","","27368","1840001871"
"Rāmpur","Rampur","26.2126","87.2491","India","IN","IND","Bihār","","25972","1356644862"
"Bathnāha","Bathnaha","26.6433","85.5747","India","IN","IND","Bihār","","25458","1356561919"
"Santana do Paraíso","Santana do Paraiso","-19.3639","-42.5689","Brazil","BR","BRA","Minas Gerais","","27265","1076433913"
"Ourilândia do Norte","Ourilandia do Norte","-6.7550","-51.0839","Brazil","BR","BRA","Pará","","27359","1076846265"
"Converse","Converse","29.5091","-98.3084","United States","US","USA","Texas","","27361","1840019655"
"Saint-Constant","Saint-Constant","45.3700","-73.5700","Canada","CA","CAN","Quebec","","27359","1124000054"
"Hennigsdorf","Hennigsdorf","52.6378","13.2036","Germany","DE","DEU","Brandenburg","","26515","1276509399"
"Clarksburg","Clarksburg","39.2246","-77.2659","United States","US","USA","Maryland","","27354","1840005830"
"Vādippatti","Vadippatti","10.0843","77.9634","India","IN","IND","Tamil Nādu","","26830","1356136892"
"Ban Phonla Krang","Ban Phonla Krang","14.9192","102.1095","Thailand","TH","THA","Nakhon Ratchasima","","26804","1764032168"
"Ubatã","Ubata","-14.2139","-39.5228","Brazil","BR","BRA","Bahia","","27263","1076839494"
"Sihecun","Sihecun","22.4729","111.5734","China","CN","CHN","Guangdong","","27191","1156556384"
"Awara","Awara","36.2113","136.2290","Japan","JP","JPN","Fukui","","27107","1392157496"
"Magarao","Magarao","13.6619","123.1903","Philippines","PH","PHL","Camarines Sur","","26742","1608965435"
"Granite City","Granite City","38.7296","-90.1268","United States","US","USA","Illinois","","27336","1840008565"
"Short Pump","Short Pump","37.6549","-77.6201","United States","US","USA","Virginia","","27332","1840006394"
"Savur","Savur","37.5375","40.8892","Turkey","TR","TUR","Mardin","minor","27304","1792346583"
"Heiligenhaus","Heiligenhaus","51.3167","6.9667","Germany","DE","DEU","North Rhine-Westphalia","","26367","1276630818"
"Ermelo","Ermelo","52.3000","5.6167","Netherlands","NL","NLD","Gelderland","minor","27016","1528737145"
"Castro Alves","Castro Alves","-12.7658","-39.4278","Brazil","BR","BRA","Bahia","","27286","1076609570"
"San Jose","San Jose","10.0083","125.5889","Philippines","PH","PHL","Dinagat Islands","","26375","1608706852"
"Gradignan","Gradignan","44.7725","-0.6156","France","FR","FRA","Nouvelle-Aquitaine","","25694","1250206083"
"Atitalaquia","Atitalaquia","20.0583","-99.2208","Mexico","MX","MEX","Hidalgo","","26904","1484827281"
"Ichikikushikino","Ichikikushikino","31.7167","130.2667","Japan","JP","JPN","Kagoshima","","27080","1392003195"
"Miranda","Miranda","-20.2408","-56.3778","Brazil","BR","BRA","Mato Grosso do Sul","","27316","1076310888"
"Enna","Enna","37.5633","14.2761","Italy","IT","ITA","Sicilia","minor","27243","1380519522"
"Swatara","Swatara","40.2463","-76.8031","United States","US","USA","Pennsylvania","","27318","1840149538"
"Huntley","Huntley","42.1599","-88.4330","United States","US","USA","Illinois","","27314","1840011134"
"Grimsby","Grimsby","43.2000","-79.5500","Canada","CA","CAN","Ontario","","27314","1124989517"
"Ixchiguán","Ixchiguan","15.1642","-91.9333","Guatemala","GT","GTM","San Marcos","minor","27176","1320779608"
"Teutônia","Teutonia","-29.4478","-51.8058","Brazil","BR","BRA","Rio Grande do Sul","","27272","1076130983"
"Buldan","Buldan","38.0450","28.8306","Turkey","TR","TUR","Denizli","minor","27241","1792112571"
"Tepehuacán de Guerrero","Tepehuacan de Guerrero","21.0131","-98.8442","Mexico","MX","MEX","Hidalgo","","27240","1484592101"
"Albignasego","Albignasego","45.3500","11.8667","Italy","IT","ITA","Veneto","","26071","1380732466"
"Akitakata","Akitakata","34.6631","132.7064","Japan","JP","JPN","Hiroshima","","27249","1392003327"
"Bergerac","Bergerac","44.8500","0.4800","France","FR","FRA","Nouvelle-Aquitaine","minor","26823","1250057635"
"Bhuban","Bhuban","20.8820","85.8333","India","IN","IND","Odisha","","26708","1356508067"
"Duptiair","Duptiair","23.9910","89.7982","Bangladesh","BD","BGD","Dhaka","","26336","1050168412"
"El Alia","El Alia","37.1667","10.0333","Tunisia","TN","TUN","Bizerte","","27075","1788662915"
"Santa Eugenia","Santa Eugenia","42.5667","-8.9833","Spain","ES","ESP","Galicia","","26897","1724379476"
"Madattukkulam","Madattukkulam","10.5587","77.3660","India","IN","IND","Tamil Nādu","","26864","1356371338"
"Sandanski","Sandanski","41.5681","23.2823","Bulgaria","BG","BGR","Blagoevgrad","","27245","1100580276"
"Bourzanga","Bourzanga","13.6781","-1.5461","Burkina Faso","BF","BFA","Centre-Nord","","27213","1854447025"
"Conchal","Conchal","-22.3300","-47.1728","Brazil","BR","BRA","São Paulo","","27132","1076034589"
"Jajce","Jajce","44.3417","17.2694","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","27258","1070282607"
"Rappang","Rappang","-3.8447","119.8205","Indonesia","ID","IDN","Sulawesi Selatan","minor","27116","1360002024"
"San Giovanni Rotondo","San Giovanni Rotondo","41.7000","15.7333","Italy","IT","ITA","Puglia","","27172","1380362159"
"Santana","Santana","-12.9828","-44.0508","Brazil","BR","BRA","Bahia","","27260","1076550800"
"Afzalpur","Afzalpur","17.2000","76.3500","India","IN","IND","Karnātaka","","27088","1356045507"
"Garden City","Garden City","42.3244","-83.3412","United States","US","USA","Michigan","","27268","1840003974"
"Sibinal","Sibinal","15.1333","-92.0500","Guatemala","GT","GTM","San Marcos","minor","27171","1320990586"
"Liugoucun","Liugoucun","40.9455","118.2708","China","CN","CHN","Hebei","","27084","1156579024"
"Green","Green","40.9483","-81.4757","United States","US","USA","Ohio","","27267","1840008254"
"Guanxi","Guanxi","24.8000","121.1833","Taiwan","TW","TWN","Hsinchu","","27051","1158461658"
"Baden","Baden","48.0075","16.2344","Austria","AT","AUT","Niederösterreich","minor","26286","1040765350"
"San Juan de Urabá","San Juan de Uraba","8.7667","-76.5333","Colombia","CO","COL","Antioquia","minor","27149","1170070547"
"Brzozów","Brzozow","49.7000","22.0167","Poland","PL","POL","Podkarpackie","minor","26615","1616772088"
"Dongen","Dongen","51.6333","4.9333","Netherlands","NL","NLD","Noord-Brabant","minor","26368","1528299202"
"Āmangal","Amangal","16.8499","78.5327","India","IN","IND","Andhra Pradesh","","27016","1356070257"
"Märsta","Marsta","59.6167","17.8500","Sweden","SE","SWE","Stockholm","minor","27034","1752183604"
"Qingshan","Qingshan","27.3500","105.0200","China","CN","CHN","Yunnan","","26509","1156581185"
"Sebeş","Sebes","45.9600","23.5700","Romania","RO","ROU","Alba","","27019","1642475227"
"Estarreja","Estarreja","40.7500","-8.5667","Portugal","PT","PRT","Aveiro","minor","26997","1620391989"
"Taraka","Taraka","7.8994","124.3481","Philippines","PH","PHL","Lanao del Sur","","27184","1608550964"
"Hénin-Beaumont","Henin-Beaumont","50.4217","2.9508","France","FR","FRA","Hauts-de-France","","25992","1250614137"
"Pereyaslav-Khmel’nyts’kyy","Pereyaslav-Khmel'nyts'kyy","50.0650","31.4450","Ukraine","UA","UKR","Kyivska Oblast","","27088","1804106305"
"Fremont","Fremont","41.4395","-96.4873","United States","US","USA","Nebraska","","27245","1840003346"
"Coffs Harbour","Coffs Harbour","-30.3022","153.1189","Australia","AU","AUS","New South Wales","","27089","1036320442"
"Rangia","Rangia","26.4700","91.6300","India","IN","IND","Assam","","26389","1356060286"
"Prior Lake","Prior Lake","44.7246","-93.4419","United States","US","USA","Minnesota","","27242","1840008956"
"Gubkinskiy","Gubkinskiy","64.4333","76.5000","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","27238","1643018972"
"Caboolture","Caboolture","-27.0667","152.9670","Australia","AU","AUS","Queensland","","26433","1036273434"
"McHenry","McHenry","42.3387","-88.2932","United States","US","USA","Illinois","","27237","1840008085"
"Margherita","Margherita","27.2800","95.6800","India","IN","IND","Arunāchal Pradesh","","26914","1356412298"
"Saguiaran","Saguiaran","8.0333","124.2667","Philippines","PH","PHL","Lanao del Sur","","26712","1608961026"
"Wągrowiec","Wagrowiec","52.8000","17.2000","Poland","PL","POL","Wielkopolskie","minor","25773","1616366641"
"Nelliyalam","Nelliyalam","11.5255","76.3589","India","IN","IND","Tamil Nādu","","26921","1356792077"
"Mel Bhuvanagiri","Mel Bhuvanagiri","11.4380","79.6328","India","IN","IND","Tamil Nādu","","26240","1356238516"
"Miki","Miki","34.2682","134.1344","Japan","JP","JPN","Kagawa","","26859","1392000455"
"Galatina","Galatina","40.1667","18.1667","Italy","IT","ITA","Puglia","","26887","1380914504"
"Wangen im Allgäu","Wangen im Allgau","47.6858","9.8342","Germany","DE","DEU","Baden-Württemberg","","26956","1276638340"
"Hohen Neuendorf","Hohen Neuendorf","52.6667","13.2833","Germany","DE","DEU","Brandenburg","","26658","1276577032"
"Sadalgi","Sadalgi","16.4200","74.5800","India","IN","IND","Karnātaka","","26666","1356698875"
"Bāgepalli","Bagepalli","13.7800","77.7900","India","IN","IND","Karnātaka","","27011","1356080876"
"Liqizhuang","Liqizhuang","39.9703","117.0013","China","CN","CHN","Hebei","","26667","1156069739"
"San Agustín Tlaxiaca","San Agustin Tlaxiaca","20.1144","-98.8867","Mexico","MX","MEX","Hidalgo","","27118","1484185265"
"Neenah","Neenah","44.1669","-88.4767","United States","US","USA","Wisconsin","","27194","1840002244"
"Talāja","Talaja","21.3500","72.0500","India","IN","IND","Gujarāt","","26667","1356770262"
"Benicia","Benicia","38.0725","-122.1526","United States","US","USA","California","","27191","1840018858"
"Tauramena","Tauramena","5.0167","-72.7500","Colombia","CO","COL","Casanare","minor","27178","1170457009"
"Jirwa","Jirwa","26.0064","86.8604","India","IN","IND","Bihār","","26162","1356059694"
"Vilyeyka","Vilyeyka","54.4980","26.9260","Belarus","BY","BLR","Minskaya Voblasts’","minor","27167","1112405572"
"Mundo Novo","Mundo Novo","-11.8589","-40.4719","Brazil","BR","BRA","Bahia","","27165","1076708728"
"Fidenza","Fidenza","44.8667","10.0667","Italy","IT","ITA","Emilia-Romagna","","26898","1380915205"
"Willebroek","Willebroek","51.0667","4.3667","Belgium","BE","BEL","Flanders","","26223","1056186071"
"Quezon","Quezon","17.3119","121.6050","Philippines","PH","PHL","Isabela","","27037","1608313013"
"Porto da Folha","Porto da Folha","-9.9169","-37.2778","Brazil","BR","BRA","Sergipe","","27146","1076255219"
"Twentynine Palms","Twentynine Palms","34.1478","-116.0659","United States","US","USA","California","","27176","1840021729"
"West Linn","West Linn","45.3670","-122.6399","United States","US","USA","Oregon","","27173","1840021218"
"Nguti","Nguti","5.3167","9.4167","Cameroon","CM","CMR","South-West","","27151","1120701627"
"Mayskiy","Mayskiy","43.6500","44.0667","Russia","RU","RUS","Kabardino-Balkariya","minor","27074","1643405638"
"Westport","Westport","41.1428","-73.3475","United States","US","USA","Connecticut","","27168","1840035669"
"Alakamisy Itenina","Alakamisy Itenina","-21.6333","47.1500","Madagascar","MG","MDG","Fianarantsoa","","27000","1450665249"
"Wethersfield","Wethersfield","41.7013","-72.6703","United States","US","USA","Connecticut","","27162","1840035670"
"Werkendam","Werkendam","51.8000","4.9000","Netherlands","NL","NLD","Noord-Brabant","minor","26940","1528525610"
"Binə","Bina","40.4539","50.0764","Azerbaijan","AZ","AZE","Bakı","","26395","1031124487"
"Turrialba","Turrialba","9.8897","-83.6807","Costa Rica","CR","CRI","Cartago","","26680","1188199997"
"Szentes","Szentes","46.6510","20.2580","Hungary","HU","HUN","Csongrád-Csanád","minor","27080","1348319560"
"Sikonge","Sikonge","-5.6333","32.7667","Tanzania","TZ","TZA","Tabora","","27113","1834313836"
"Mantena","Mantena","-18.7819","-40.9800","Brazil","BR","BRA","Minas Gerais","","27111","1076942263"
"Tianwei","Tianwei","23.9023","120.5214","Taiwan","TW","TWN","Changhua","","26063","1158880346"
"Zomin Shaharchasi","Zomin Shaharchasi","39.9631","68.3939","Uzbekistan","UZ","UZB","Jizzax","minor","27077","1860079464"
"Bankāpur","Bankapur","14.9230","75.2622","India","IN","IND","Karnātaka","","26827","1356921421"
"La Garde","La Garde","43.1256","6.0108","France","FR","FRA","Provence-Alpes-Côte d’Azur","","25505","1250097779"
"Ksebia","Ksebia","34.2933","-6.1594","Morocco","MA","MAR","Rabat-Salé-Kénitra","","27059","1504059620"
"Soanierana Ivongo","Soanierana Ivongo","-16.9167","49.5833","Madagascar","MG","MDG","Toamasina","","26990","1450631531"
"Joaçaba","Joacaba","-27.1778","-51.5050","Brazil","BR","BRA","Santa Catarina","","27020","1076899882"
"Assèmini","Assemini","39.2833","9.0000","Italy","IT","ITA","Sardegna","","26901","1380936771"
"Miandrarivo","Miandrarivo","-19.4333","46.7500","Madagascar","MG","MDG","Antananarivo","","27000","1450672454"
"Suār","Suar","29.0270","79.0570","India","IN","IND","Uttar Pradesh","","26142","1356047123"
"Plum","Plum","40.5024","-79.7495","United States","US","USA","Pennsylvania","","27123","1840003632"
"Kāko","Kako","25.2259","85.0642","India","IN","IND","Bihār","","25605","1356818795"
"Bijbiāra","Bijbiara","33.7938","75.1070","India","IN","IND","Jammu and Kashmīr","","26128","1356024716"
"Ambinanisakana","Ambinanisakana","-16.9500","49.5833","Madagascar","MG","MDG","Toamasina","","26990","1450453303"
"Nayoro","Nayoro","44.3559","142.4632","Japan","JP","JPN","Hokkaidō","","27062","1392003247"
"Antsiatsiaka","Antsiatsiaka","-17.0000","49.2000","Madagascar","MG","MDG","Toamasina","","27000","1450414497"
"Labrador","Labrador","16.0339","120.1392","Philippines","PH","PHL","Pangasinan","","26811","1608960162"
"Scicli","Scicli","36.7914","14.7025","Italy","IT","ITA","Sicilia","","27051","1380993047"
"Tlaxcoapan","Tlaxcoapan","20.0953","-99.2200","Mexico","MX","MEX","Hidalgo","","26758","1484883241"
"Sol’-Iletsk","Sol'-Iletsk","51.1667","55.0000","Russia","RU","RUS","Orenburgskaya Oblast’","","27085","1643001542"
"Vohipaho","Vohipaho","-23.5500","47.5000","Madagascar","MG","MDG","Fianarantsoa","","27000","1450398664"
"San Juan","San Juan","17.7431","120.4583","Philippines","PH","PHL","Ilocos Sur","","26674","1608362566"
"Daphne","Daphne","30.6263","-87.8816","United States","US","USA","Alabama","","27088","1840006178"
"Libenge","Libenge","3.6500","18.6333","Congo (Kinshasa)","CD","COD","Sud-Ubangi","","27053","1180325896"
"Tarragona","Tarragona","7.0491","126.4471","Philippines","PH","PHL","Davao Oriental","","26996","1608231910"
"Pāmidi","Pamidi","14.9500","77.5833","India","IN","IND","Andhra Pradesh","","26886","1356151467"
"Geldermalsen","Geldermalsen","51.8833","5.2833","Netherlands","NL","NLD","Gelderland","minor","26818","1528816915"
"Bamessi","Bamessi","6.0333","10.5792","Cameroon","CM","CMR","North-West","","26802","1120836103"
"Falconara Marittima","Falconara Marittima","43.6333","13.4000","Italy","IT","ITA","Marche","","26063","1380000141"
"Unterhaching","Unterhaching","48.0658","11.6100","Germany","DE","DEU","Bavaria","","25379","1276779846"
"Amatepec","Amatepec","18.6500","-100.1500","Mexico","MX","MEX","México","minor","27026","1484058749"
"Almuñécar","Almunecar","36.7339","-3.6911","Spain","ES","ESP","Andalusia","","26748","1724549849"
"San Sebastián de Yalí","San Sebastian de Yali","13.3061","-86.1867","Nicaragua","NI","NIC","Jinotega","minor","26979","1558797659"
"Laranjeiras","Laranjeiras","-10.8061","-37.1717","Brazil","BR","BRA","Sergipe","","26902","1076042749"
"Dasūya","Dasuya","31.8168","75.6531","India","IN","IND","Punjab","","26550","1356361177"
"Ružomberok","Ruzomberok","49.0786","19.3083","Slovakia","SK","SVK","Žilina","minor","26854","1703510980"
"Hamminkeln","Hamminkeln","51.7319","6.5908","Germany","DE","DEU","North Rhine-Westphalia","","26900","1276465671"
"Jiangdi","Jiangdi","27.0120","103.6042","China","CN","CHN","Yunnan","","26863","1156823029"
"Mesagne","Mesagne","40.5667","17.8000","Italy","IT","ITA","Puglia","","26836","1380258856"
"Paracuellos de Jarama","Paracuellos de Jarama","40.5500","-3.5167","Spain","ES","ESP","Madrid","","26450","1724371566"
"Springettsbury","Springettsbury","39.9907","-76.6736","United States","US","USA","Pennsylvania","","27049","1840147114"
"Mandi","Mandi","31.7200","76.9200","India","IN","IND","Himāchal Pradesh","","26422","1356326622"
"Pueblo Bello","Pueblo Bello","10.4167","-73.5833","Colombia","CO","COL","Cesar","minor","27007","1170551166"
"Wakefield","Wakefield","42.5035","-71.0656","United States","US","USA","Massachusetts","","27041","1840053641"
"Srīnivāspur","Srinivaspur","13.3378","78.2122","India","IN","IND","Karnātaka","","26793","1356515219"
"Chengannūr","Chengannur","9.3200","76.6215","India","IN","IND","Kerala","","25391","1356007942"
"Blagnac","Blagnac","43.6364","1.3906","France","FR","FRA","Occitanie","","25525","1250134186"
"Quarrata","Quarrata","43.8475","10.9833","Italy","IT","ITA","Tuscany","","26460","1380744286"
"Ar Rudayyif","Ar Rudayyif","34.3833","8.1500","Tunisia","TN","TUN","Gafsa","","26976","1788485664"
"Buriti","Buriti","-3.9419","-42.9250","Brazil","BR","BRA","Maranhão","","27013","1076275793"
"Chiriguaná","Chiriguana","9.3667","-73.6000","Colombia","CO","COL","Cesar","minor","27006","1170624254"
"Zuitou","Zuitou","34.0622","107.3127","China","CN","CHN","Shaanxi","minor","26948","1156397431"
"Santa Margarita","Santa Margarita","12.0378","124.6578","Philippines","PH","PHL","Samar","","26816","1608562056"
"Tarui","Tarui","35.3663","136.5379","Japan","JP","JPN","Gifu","","26558","1392301201"
"Parit Buntar","Parit Buntar","5.1167","100.5000","Malaysia","MY","MYS","Perak","","26328","1458515879"
"Pacasmayo","Pacasmayo","-7.4003","-79.5700","Peru","PE","PER","La Libertad","","26125","1604459740"
"Pearl","Pearl","32.2730","-90.0918","United States","US","USA","Mississippi","","27017","1840014888"
"Marcos Juárez","Marcos Juarez","-32.7000","-62.1000","Argentina","AR","ARG","Córdoba","minor","27004","1032677394"
"Andranomavo","Andranomavo","-16.5667","45.5833","Madagascar","MG","MDG","Mahajanga","","27000","1450968548"
"Kantābānji","Kantabanji","20.4671","82.9204","India","IN","IND","Odisha","","26525","1356006594"
"Aragua de Barcelona","Aragua de Barcelona","9.4575","-64.8261","Venezuela","VE","VEN","Anzoátegui","minor","27000","1862505778"
"Rājgarh","Rajgarh","27.2360","76.6220","India","IN","IND","Rājasthān","","26631","1356787651"
"Mandabe","Mandabe","-21.0500","44.9333","Madagascar","MG","MDG","Toliara","","27000","1450718280"
"Albania","Albania","11.1597","-72.5856","Colombia","CO","COL","La Guajira","minor","26940","1170788671"
"Idigny","Idigny","7.4833","2.7000","Benin","BJ","BEN","Plateau","","26882","1204693959"
"Wellington","Wellington","11.3655","76.7844","India","IN","IND","Tamil Nādu","","26686","1356417394"
"Gilarchāt","Gilarchat","22.0703","88.4455","India","IN","IND","West Bengal","","25897","1356659506"
"Saumur","Saumur","47.2600","-0.0769","France","FR","FRA","Pays de la Loire","minor","26599","1250940567"
"Ourika Wawrmas","Ourika Wawrmas","30.7167","-6.5333","Morocco","MA","MAR","Drâa-Tafilalet","","26990","1504021700"
"Lochearn","Lochearn","39.3461","-76.7307","United States","US","USA","Maryland","","26995","1840005694"
"Celendín","Celendin","-6.8667","-78.1500","Peru","PE","PER","Cajamarca","","26925","1604315050"
"Aripuanã","Aripuana","-10.1767","-59.4439","Brazil","BR","BRA","Mato Grosso","","26983","1076120202"
"Alvin","Alvin","29.3872","-95.2938","United States","US","USA","Texas","","26982","1840019671"
"Paianía","Paiania","37.9500","23.8500","Greece","GR","GRC","Attikí","minor","26668","1300888494"
"Pantar","Pantar","8.0667","124.2667","Philippines","PH","PHL","Lanao del Norte","","26599","1608597402"
"Qā’emīyeh","Qa'emiyeh","29.8519","51.5869","Iran","IR","IRN","Fārs","","26918","1364710224"
"Manjacaze","Manjacaze","-24.7117","33.8828","Mozambique","MZ","MOZ","Gaza","","26641","1508000199"
"Hutto","Hutto","30.5396","-97.5440","United States","US","USA","Texas","","26971","1840019573"
"Noicattaro","Noicattaro","41.0333","16.9833","Italy","IT","ITA","Puglia","","26325","1380444548"
"Ibimirim","Ibimirim","-8.5408","-37.6903","Brazil","BR","BRA","Pernambuco","","26954","1076028075"
"Chuimatan","Chuimatan","35.7166","102.8771","China","CN","CHN","Gansu","minor","26044","1156604134"
"San Luis Jilotepeque","San Luis Jilotepeque","14.6500","-89.7333","Guatemala","GT","GTM","Jalapa","minor","26874","1320097405"
"Uyuni","Uyuni","-20.4667","-66.8167","Bolivia","BO","BOL","Potosí","","26958","1068695031"
"Karpinsk","Karpinsk","59.7667","59.9833","Russia","RU","RUS","Sverdlovskaya Oblast’","","26957","1643966398"
"Puerto Colombia","Puerto Colombia","11.0167","-74.8833","Colombia","CO","COL","Atlántico","minor","26595","1170869718"
"Lagindingan","Lagindingan","8.5833","124.4500","Philippines","PH","PHL","Misamis Oriental","","26363","1608139544"
"Holbrook","Holbrook","40.7944","-73.0700","United States","US","USA","New York","","26958","1840005078"
"Santa María Ixhuatán","Santa Maria Ixhuatan","14.1833","-90.2667","Guatemala","GT","GTM","Santa Rosa","minor","26721","1320000004"
"Skopin","Skopin","53.8167","39.5500","Russia","RU","RUS","Ryazanskaya Oblast’","","26112","1643315805"
"Lauf","Lauf","49.5103","11.2772","Germany","DE","DEU","Bavaria","minor","26515","1276202337"
"Hancun","Hancun","39.4062","116.6126","China","CN","CHN","Hebei","","26543","1156324762"
"Şafāshahr","Safashahr","30.6131","53.1953","Iran","IR","IRN","Fārs","minor","26933","1364976274"
"Kamyshlov","Kamyshlov","56.8333","62.7167","Russia","RU","RUS","Sverdlovskaya Oblast’","","26444","1643094029"
"Sapian","Sapian","11.4939","122.6047","Philippines","PH","PHL","Capiz","","26697","1608481925"
"Niscemi","Niscemi","37.1500","14.3833","Italy","IT","ITA","Sicilia","","26946","1380700929"
"Acapetahua","Acapetahua","15.2333","-92.7667","Mexico","MX","MEX","Chiapas","minor","26899","1484664781"
"Turek","Turek","52.0167","18.5000","Poland","PL","POL","Wielkopolskie","minor","25376","1616094895"
"Putignano","Putignano","40.8500","17.1167","Italy","IT","ITA","Puglia","","26679","1380734552"
"Al Quţayfah","Al Qutayfah","33.7389","36.6000","Syria","SY","SYR","Rīf Dimashq","minor","26671","1760671268"
"Lauri","Lauri","25.1396","80.0011","India","IN","IND","Madhya Pradesh","","26621","1356046064"
"New Lenox","New Lenox","41.5095","-87.9703","United States","US","USA","Illinois","","26942","1840011493"
"Thomasville","Thomasville","35.8813","-80.0807","United States","US","USA","North Carolina","","26941","1840015361"
"Znamensk","Znamensk","48.5833","45.7500","Russia","RU","RUS","Astrakhanskaya Oblast’","","26927","1643760173"
"Khānābād","Khanabad","36.6831","69.1636","Afghanistan","AF","AFG","Kunduz","minor","26803","1004931539"
"Crestview","Crestview","30.7477","-86.5785","United States","US","USA","Florida","","26934","1840015007"
"Yōrō","Yoro","35.3084","136.5614","Japan","JP","JPN","Gifu","","26565","1392003218"
"Miracema","Miracema","-21.4119","-42.1969","Brazil","BR","BRA","Rio de Janeiro","","26843","1076359191"
"Pemberton","Pemberton","39.9562","-74.6000","United States","US","USA","New Jersey","","26926","1840001480"
"Buritizeiro","Buritizeiro","-17.3508","-44.9619","Brazil","BR","BRA","Minas Gerais","","26922","1076067806"
"Nādbai","Nadbai","27.2300","77.2000","India","IN","IND","Rājasthān","","26411","1356960987"
"Sun City Center","Sun City Center","27.7150","-82.3569","United States","US","USA","Florida","","26920","1840001841"
"Jaltenco","Jaltenco","19.7511","-99.0931","Mexico","MX","MEX","México","minor","26359","1484504076"
"Pınarbaşı","Pinarbasi","38.7220","36.3910","Turkey","TR","TUR","Kayseri","minor","26911","1792740700"
"Ehingen an der Donau","Ehingen an der Donau","48.2833","9.7236","Germany","DE","DEU","Baden-Württemberg","","26766","1276432627"
"Ambodiangezoka","Ambodiangezoka","-14.6000","49.5000","Madagascar","MG","MDG","Antsiranana","","26673","1450741103"
"Sabaa Aiyoun","Sabaa Aiyoun","33.9000","-5.3667","Morocco","MA","MAR","Fès-Meknès","","26277","1504462212"
"Karasuk","Karasuk","53.7167","78.0500","Russia","RU","RUS","Novosibirskaya Oblast’","","26902","1643416504"
"Auburn","Auburn","42.9338","-76.5685","United States","US","USA","New York","","26913","1840000367"
"Iglesias","Iglesias","39.3167","8.5333","Italy","IT","ITA","Sardegna","minor","26784","1380230224"
"Butzbach","Butzbach","50.4367","8.6622","Germany","DE","DEU","Hesse","","26660","1276386116"
"Sarayönü","Sarayonu","38.2661","32.4064","Turkey","TR","TUR","Konya","minor","26875","1792347742"
"Flémalle-Haute","Flemalle-Haute","50.6011","5.4628","Belgium","BE","BEL","Wallonia","","26174","1056508801"
"Boisbriand","Boisbriand","45.6200","-73.8300","Canada","CA","CAN","Quebec","","26884","1124001940"
"Miyanaga","Miyanaga","33.7167","130.6667","Japan","JP","JPN","Fukuoka","","26690","1392770083"
"Majagua","Majagua","21.9244","-78.9906","Cuba","CU","CUB","Ciego de Ávila","minor","26830","1192814385"
"Jinju","Jinju","22.7073","111.8223","China","CN","CHN","Guangdong","","26393","1156152673"
"Cândido Sales","Candido Sales","-15.5050","-41.2389","Brazil","BR","BRA","Bahia","","26855","1076675409"
"Tamorot","Tamorot","34.9333","-4.7833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","26748","1504592587"
"Karumāndi Chellipālaiyam","Karumandi Chellipalaiyam","11.3019","77.5860","India","IN","IND","Tamil Nādu","","26495","1356168999"
"Shoreview","Shoreview","45.0842","-93.1358","United States","US","USA","Minnesota","","26871","1840008938"
"Pérama","Perama","37.9667","23.5667","Greece","GR","GRC","Attikí","minor","25389","1300559034"
"Bozyazı","Bozyazi","36.1000","32.9667","Turkey","TR","TUR","Mersin","minor","26840","1792667292"
"Moon","Moon","40.5081","-80.2074","United States","US","USA","Pennsylvania","","26863","1840035106"
"Heppenheim","Heppenheim","49.6431","8.6389","Germany","DE","DEU","Hesse","minor","26357","1276763901"
"Ipixuna","Ipixuna","-7.0508","-71.6950","Brazil","BR","BRA","Amazonas","","26860","1076079764"
"Shangxiao","Shangxiao","35.4969","107.4914","China","CN","CHN","Gansu","","26686","1156308697"
"Sofiyivs’ka Borshchahivka","Sofiyivs'ka Borshchahivka","50.4114","30.3692","Ukraine","UA","UKR","Kyivska Oblast","","26571","1804314606"
"Lamego","Lamego","41.1008","-7.8100","Portugal","PT","PRT","Viseu","minor","26691","1620221228"
"Santa Rosa de Viterbo","Santa Rosa de Viterbo","-21.4728","-47.3628","Brazil","BR","BRA","São Paulo","","26753","1076895582"
"Lauaan","Lauaan","11.1429","122.0417","Philippines","PH","PHL","Antique","","26580","1608082047"
"Pérez","Perez","-33.0000","-60.7667","Argentina","AR","ARG","Santa Fe","","26448","1032176211"
"Bafoulabé","Bafoulabe","13.8064","-10.8322","Mali","ML","MLI","Kayes","minor","26823","1466740370"
"Zottegem","Zottegem","50.8667","3.8000","Belgium","BE","BEL","Flanders","","26373","1056768332"
"Solânea","Solanea","-6.7778","-35.6969","Brazil","BR","BRA","Paraíba","","26734","1076127830"
"Bugho","Bugho","10.8000","124.9333","Philippines","PH","PHL","Leyte","","26658","1608894978"
"Tamayo","Tamayo","18.4000","-71.2000","Dominican Republic","DO","DOM","Enriquillo","","26772","1214403049"
"Mauganj","Mauganj","24.6800","81.8800","India","IN","IND","Madhya Pradesh","","26420","1356423548"
"San Pablo","San Pablo","7.6578","123.4597","Philippines","PH","PHL","Zamboanga del Sur","","26648","1608873105"
"Ulubey","Ulubey","40.8761","37.7406","Turkey","TR","TUR","Ordu","minor","26737","1792933054"
"Gladstone","Gladstone","39.2134","-94.5592","United States","US","USA","Missouri","","26825","1840008496"
"Chivasso","Chivasso","45.1910","7.8872","Italy","IT","ITA","Piedmont","","26730","1380480974"
"Nepomuceno","Nepomuceno","-21.2358","-45.2358","Brazil","BR","BRA","Minas Gerais","","26769","1076022891"
"Qarah Ẕīā’ od Dīn","Qarah Zia' od Din","38.8914","45.0256","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","26767","1364834159"
"Dyatkovo","Dyatkovo","53.6000","34.3333","Russia","RU","RUS","Bryanskaya Oblast’","","26775","1643789328"
"Fortul","Fortul","6.7922","-71.7756","Colombia","CO","COL","Arauca","minor","26798","1170737836"
"Cavaillon","Cavaillon","43.8375","5.0381","France","FR","FRA","Provence-Alpes-Côte d’Azur","","26236","1250337002"
"Deer Park","Deer Park","40.7623","-73.3218","United States","US","USA","New York","","26803","1840005034"
"Dardoq","Dardoq","40.8156","72.8311","Uzbekistan","UZ","UZB","Andijon","","26055","1860913698"
"Obertshausen","Obertshausen","50.0667","8.8333","Germany","DE","DEU","Hesse","","24968","1276455006"
"Bishunpur Sundar","Bishunpur Sundar","26.0579","86.9833","India","IN","IND","Bihār","","25773","1356224656"
"Sombrio","Sombrio","-29.1039","-49.6289","Brazil","BR","BRA","Santa Catarina","","26613","1076557999"
"Alhaurín el Grande","Alhaurin el Grande","36.6331","-4.6831","Spain","ES","ESP","Andalusia","","26436","1724876495"
"Sachse","Sachse","32.9726","-96.5793","United States","US","USA","Texas","","26797","1840020712"
"Los Córdobas","Los Cordobas","8.9000","-76.3667","Colombia","CO","COL","Córdoba","minor","26705","1170780435"
"Jarocin","Jarocin","51.9667","17.5000","Poland","PL","POL","Wielkopolskie","minor","26565","1616201243"
"Novoaleksandrovsk","Novoaleksandrovsk","45.5000","41.2333","Russia","RU","RUS","Stavropol’skiy Kray","","26761","1643490548"
"Ōtake","Otake","34.2333","132.2167","Japan","JP","JPN","Hiroshima","","26458","1392671781"
"Guruzāla","Guruzala","16.5800","79.5700","India","IN","IND","Andhra Pradesh","","26190","1356361552"
"Macrohon","Macrohon","10.0797","124.9431","Philippines","PH","PHL","Southern Leyte","","26580","1608879566"
"Tōin","Toin","35.0741","136.5836","Japan","JP","JPN","Mie","","25658","1392003067"
"Malitbog","Malitbog","8.5361","124.8808","Philippines","PH","PHL","Bukidnon","","26741","1608929640"
"Apam","Apam","5.2789","-0.7397","Ghana","GH","GHA","Central","","26466","1288261355"
"Eidsvoll","Eidsvoll","60.3475","11.2508","Norway","NO","NOR","Viken","minor","26716","1578516896"
"Codajás","Codajas","-3.8369","-62.0569","Brazil","BR","BRA","Amazonas","","26777","1076405566"
"Key West","Key West","24.5642","-81.7775","United States","US","USA","Florida","","26776","1840015161"
"Santeramo in Colle","Santeramo in Colle","40.8000","16.7667","Italy","IT","ITA","Puglia","","26592","1380194006"
"Francavilla al Mare","Francavilla al Mare","42.4167","14.3000","Italy","IT","ITA","Abruzzo","","25663","1380658126"
"Semiluki","Semiluki","51.6833","39.0333","Russia","RU","RUS","Voronezhskaya Oblast’","","26732","1643299650"
"Carrollton","Carrollton","33.5818","-85.0838","United States","US","USA","Georgia","","26773","1840014801"
"Morales","Morales","2.7603","-76.6339","Colombia","CO","COL","Cauca","minor","26707","1170455752"
"Mariano Comense","Mariano Comense","45.7000","9.1833","Italy","IT","ITA","Lombardy","","24956","1380489297"
"Chuanliaocun","Chuanliaocun","28.2611","120.2106","China","CN","CHN","Zhejiang","","26602","1156374827"
"Narasannapeta","Narasannapeta","18.4151","84.0447","India","IN","IND","Andhra Pradesh","","26280","1356526261"
"Eureka","Eureka","40.7943","-124.1564","United States","US","USA","California","","26763","1840009558"
"Merrimack","Merrimack","42.8547","-71.5188","United States","US","USA","New Hampshire","","26762","1840080351"
"Montreux","Montreux","46.4333","6.9167","Switzerland","CH","CHE","Vaud","","25984","1756041272"
"Piracaia","Piracaia","-23.0539","-46.3581","Brazil","BR","BRA","São Paulo","","26688","1076072528"
"Ḩukūmatī Baghrān","Hukumati Baghran","33.0669","65.0917","Afghanistan","AF","AFG","Helmand","","26724","1004391533"
"Vadakkanandal","Vadakkanandal","11.7739","78.8666","India","IN","IND","Tamil Nādu","","26196","1356244789"
"Wooster","Wooster","40.8172","-81.9336","United States","US","USA","Ohio","","26753","1840000961"
"Poytug‘","Poytug\`","40.9000","72.2500","Uzbekistan","UZ","UZB","Andijon","minor","26000","1860067682"
"Jardim","Jardim","-7.5819","-39.2978","Brazil","BR","BRA","Ceará","","26688","1076202458"
"Polysayevo","Polysayevo","54.6014","86.2486","Russia","RU","RUS","Kemerovskaya Oblast’","","26212","1643007659"
"Muchamiel","Muchamiel","38.4136","-0.4456","Spain","ES","ESP","Valencia","","26192","1724272993"
"Wanlaweyn","Wanlaweyn","2.6167","44.9000","Somalia","SO","SOM","Shabeellaha Hoose","","26700","1706708274"
"Pfaffenhofen","Pfaffenhofen","48.5333","11.5167","Germany","DE","DEU","Bavaria","minor","26453","1276259181"
"Tekkalakote","Tekkalakote","15.5348","76.8785","India","IN","IND","Karnātaka","","26224","1356141123"
"Hukeri","Hukeri","16.2300","74.6000","India","IN","IND","Karnātaka","","26290","1356892943"
"Shingū","Shingu","33.7167","136.0000","Japan","JP","JPN","Mie","","26628","1392301953"
"Weiterstadt","Weiterstadt","49.9000","8.6000","Germany","DE","DEU","Hesse","","25975","1276959186"
"Asheboro","Asheboro","35.7158","-79.8127","United States","US","USA","North Carolina","","26729","1840013400"
"Fortuna Foothills","Fortuna Foothills","32.6616","-114.3974","United States","US","USA","Arizona","","26727","1840027989"
"Shelek","Shelek","43.5972","78.2511","Kazakhstan","KZ","KAZ","","","26688","1398010386"
"Manāsa","Manasa","24.4800","75.1500","India","IN","IND","Madhya Pradesh","","26551","1356979630"
"Santo Antônio do Tauá","Santo Antonio do Taua","-1.1519","-48.1289","Brazil","BR","BRA","Pará","","26674","1076012870"
"Vernon Hills","Vernon Hills","42.2340","-87.9608","United States","US","USA","Illinois","","26723","1840011181"
"South Windsor","South Windsor","41.8353","-72.5733","United States","US","USA","Connecticut","","26719","1840035602"
"Iesolo","Iesolo","45.5331","12.6448","Italy","IT","ITA","Veneto","","26438","1380134395"
"Taloda","Taloda","21.5607","74.2200","India","IN","IND","Mahārāshtra","","26363","1356014572"
"Maevatanana","Maevatanana","-16.9500","46.8333","Madagascar","MG","MDG","Mahajanga","minor","25928","1450402111"
"Kirzhach","Kirzhach","56.1500","38.8667","Russia","RU","RUS","Vladimirskaya Oblast’","","26676","1643096892"
"Brasiléia","Brasileia","-11.0100","-68.7478","Brazil","BR","BRA","Acre","","26702","1076430930"
"Ratau","Ratau","-29.3828","27.7892","Lesotho","LS","LSO","Maseru","","26582","1426355442"
"Beuningen","Beuningen","51.8667","5.7667","Netherlands","NL","NLD","Gelderland","minor","26157","1528306688"
"Tubize","Tubize","50.6930","4.2047","Belgium","BE","BEL","Wallonia","","25914","1056180721"
"Kiruna","Kiruna","67.8489","20.3028","Sweden","SE","SWE","Norrbotten","minor","26703","1752001526"
"Yuanyangzhen","Yuanyangzhen","34.7847","104.7762","China","CN","CHN","Gansu","","26352","1156163479"
"Kiskunhalas","Kiskunhalas","46.4319","19.4883","Hungary","HU","HUN","Bács-Kiskun","minor","26584","1348184980"
"Dondon","Dondon","19.5333","-72.2333","Haiti","HT","HTI","Nord","","25846","1332640840"
"Kudachi","Kudachi","16.4800","74.7800","India","IN","IND","Karnātaka","","26192","1356860757"
"Krasnoarmeysk","Krasnoarmeysk","56.1000","38.1333","Russia","RU","RUS","Moskovskaya Oblast’","","26519","1643779487"
"Betong","Betong","5.7731","101.0725","Thailand","TH","THA","Yala","minor","26640","1764484131"
"Nova Esperança","Nova Esperanca","-23.1839","-52.2050","Brazil","BR","BRA","Paraná","","26615","1076389482"
"Dum Duma","Dum Duma","27.5688","95.5566","India","IN","IND","Assam","","26143","1356035545"
"Lanquín","Lanquin","15.5758","-89.9811","Guatemala","GT","GTM","Alta Verapaz","minor","26551","1320936016"
"Masanwa","Masanwa","-3.1833","33.7833","Tanzania","TZ","TZA","Simiyu","","26597","1834929930"
"Huşi","Husi","46.6742","28.0597","Romania","RO","ROU","Vaslui","","26266","1642292684"
"Wieliczka","Wieliczka","49.9894","20.0661","Poland","PL","POL","Małopolskie","minor","26599","1616249410"
"Mizque","Mizque","-17.9333","-65.3167","Bolivia","BO","BOL","Cochabamba","","26659","1068748757"
"Az Zabadānī","Az Zabadani","33.7250","36.0972","Syria","SY","SYR","Rīf Dimashq","minor","26285","1760989247"
"Segezha","Segezha","63.7333","34.3167","Russia","RU","RUS","Kareliya","","26666","1643971245"
"Maski","Maski","15.9581","76.6569","India","IN","IND","Karnātaka","","26440","1356780515"
"San Giovanni Lupatoto","San Giovanni Lupatoto","45.3833","11.0333","Italy","IT","ITA","Veneto","","25337","1380942958"
"Plainview","Plainview","40.7832","-73.4732","United States","US","USA","New York","","26665","1840005250"
"Pandag","Pandag","6.7411","124.7827","Philippines","PH","PHL","Maguindanao","","26356","1608191003"
"Siófok","Siofok","46.9239","18.0901","Hungary","HU","HUN","Somogy","minor","26451","1348589938"
"Planalto","Planalto","-14.6700","-40.4708","Brazil","BR","BRA","Bahia","","26632","1076745918"
"Kirovsk","Kirovsk","48.6333","38.6500","Ukraine","UA","UKR","Luhanska Oblast","","26654","1804790912"
"Jászberény","Jaszbereny","47.5000","19.9167","Hungary","HU","HUN","Jász-Nagykun-Szolnok","minor","26540","1348347095"
"Sibi","Sibi","12.3786","-8.3333","Mali","ML","MLI","Koulikoro","","26633","1466065498"
"Las Flores","Las Flores","-36.0139","-59.1000","Argentina","AR","ARG","Buenos Aires","minor","26651","1032178626"
"Caetés","Caetes","-8.7728","-36.6228","Brazil","BR","BRA","Pernambuco","","26577","1076875758"
"Melgaço","Melgaco","-1.8039","-50.7119","Brazil","BR","BRA","Pará","","26652","1076524502"
"Frías","Frias","-28.6333","-65.1333","Argentina","AR","ARG","Santiago del Estero","minor","26649","1032077058"
"Paramus","Paramus","40.9455","-74.0712","United States","US","USA","New Jersey","","26650","1840000916"
"Poro","Poro","10.6290","124.4070","Philippines","PH","PHL","Cebu","","26232","1608111818"
"Cuajinicuilapa","Cuajinicuilapa","16.4717","-98.4153","Mexico","MX","MEX","Guerrero","minor","26627","1484450188"
"Maçka","Macka","40.8167","39.6167","Turkey","TR","TUR","Trabzon","minor","26626","1792354178"
"Deogarh","Deogarh","21.5383","84.7334","India","IN","IND","Odisha","","26526","1356230761"
"Rich","Rich","32.2583","-4.5056","Morocco","MA","MAR","Drâa-Tafilalet","","25992","1504005566"
"Bétera","Betera","39.5922","-0.4625","Spain","ES","ESP","Valencia","","26289","1724948157"
"Lemoore","Lemoore","36.2949","-119.7983","United States","US","USA","California","","26631","1840020383"
"Veranópolis","Veranopolis","-28.9358","-51.5489","Brazil","BR","BRA","Rio Grande do Sul","","26533","1076303764"
"Jumilla","Jumilla","38.4792","-1.3250","Spain","ES","ESP","Murcia","","26596","1724151562"
"Horn Lake","Horn Lake","34.9512","-90.0501","United States","US","USA","Mississippi","","26623","1840014643"
"Giussano","Giussano","45.7000","9.2167","Italy","IT","ITA","Lombardy","","25945","1380789613"
"Aş Şanamayn","As Sanamayn","33.0711","36.1842","Syria","SY","SYR","Dar‘ā","minor","26268","1760865788"
"Mirassol d’Oeste","Mirassol d'Oeste","-15.6750","-58.0958","Brazil","BR","BRA","Mato Grosso","","26596","1076202383"
"Upper Dublin","Upper Dublin","40.1502","-75.1813","United States","US","USA","Pennsylvania","","26619","1840152392"
"Okuta","Okuta","9.2199","3.2005","Nigeria","NG","NGA","Kwara","","26589","1566074252"
"Mühlacker","Muhlacker","48.9500","8.8394","Germany","DE","DEU","Baden-Württemberg","","26137","1276456440"
"Dalnerechensk","Dalnerechensk","45.9333","133.7333","Russia","RU","RUS","Primorskiy Kray","","26378","1643306858"
"San Benito Abad","San Benito Abad","8.9333","-75.0333","Colombia","CO","COL","Sucre","minor","26595","1170661666"
"Chintalapūdi","Chintalapudi","17.0667","80.9833","India","IN","IND","Andhra Pradesh","","25952","1356061024"
"Pura","Pura","15.6248","120.6480","Philippines","PH","PHL","Tarlac","","25781","1608629577"
"Mangaldai","Mangaldai","26.4300","92.0300","India","IN","IND","Assam","","25989","1356621216"
"Casiguran","Casiguran","16.2833","122.1167","Philippines","PH","PHL","Aurora","","26564","1608404856"
"Superior","Superior","46.6941","-92.0823","United States","US","USA","Wisconsin","","26601","1840038034"
"Cambuí","Cambui","-22.6119","-46.0578","Brazil","BR","BRA","Minas Gerais","","26488","1076056119"
"Wyszków","Wyszkow","52.5928","21.4584","Poland","PL","POL","Mazowieckie","minor","26349","1616567857"
"San Alberto","San Alberto","7.7525","-73.3892","Colombia","CO","COL","Cesar","minor","26551","1170270470"
"Kirovsk","Kirovsk","67.6167","33.6500","Russia","RU","RUS","Murmanskaya Oblast’","","26581","1643433043"
"Khā̃dbāri̇̄","Khadbari","27.3667","87.2167","Nepal","NP","NPL","","","26301","1524504106"
"Oldbury","Oldbury","52.5050","-2.0159","United Kingdom","GB","GBR","Sandwell","","25488","1826772887"
"Werota","Werota","11.9167","37.7000","Ethiopia","ET","ETH","Āmara","","26317","1231170165"
"Temple Terrace","Temple Terrace","28.0436","-82.3773","United States","US","USA","Florida","","26585","1840015983"
"Lichtenburg","Lichtenburg","-26.1500","26.1667","South Africa","ZA","ZAF","North West","","26338","1710444843"
"Samokov","Samokov","42.3371","23.5554","Bulgaria","BG","BGR","Sofia","","26548","1100464208"
"Beohāri","Beohari","24.0242","81.3783","India","IN","IND","Madhya Pradesh","","26413","1356847193"
"Nilakkottai","Nilakkottai","10.1700","77.8700","India","IN","IND","Tamil Nādu","","25891","1356243370"
"Schleswig","Schleswig","54.5181","9.5703","Germany","DE","DEU","Schleswig-Holstein","minor","25510","1276884755"
"Farmington","Farmington","41.7288","-72.8407","United States","US","USA","Connecticut","","26559","1840034695"
"Dois Córregos","Dois Corregos","-22.3661","-48.3803","Brazil","BR","BRA","São Paulo","","26517","1076640457"
"Severouralsk","Severouralsk","60.1500","59.9667","Russia","RU","RUS","Sverdlovskaya Oblast’","","26543","1643560617"
"San Antonio del Sur","San Antonio del Sur","20.0569","-74.8078","Cuba","CU","CUB","Guantánamo","minor","26509","1192849948"
"Fălticeni","Falticeni","47.4597","26.3000","Romania","RO","ROU","Suceava","","25723","1642953328"
"Windsor","Windsor","38.5422","-122.8089","United States","US","USA","California","","26548","1840022484"
"Bubong","Bubong","8.0167","124.4833","Philippines","PH","PHL","Lanao del Sur","","26514","1608714839"
"Groß-Gerau","Gross-Gerau","49.9192","8.4850","Germany","DE","DEU","Hesse","minor","26068","1276722779"
"Mabini","Mabini","16.0697","119.9400","Philippines","PH","PHL","Pangasinan","","26454","1608238060"
"Quilalí","Quilali","13.5683","-86.0267","Nicaragua","NI","NIC","Nueva Segovia","minor","26461","1558890150"
"Ruskin","Ruskin","27.7065","-82.4209","United States","US","USA","Florida","","26536","1840014159"
"Cesenatico","Cesenatico","44.2000","12.3944","Italy","IT","ITA","Emilia-Romagna","","25959","1380296104"
"La Unión","La Union","-40.2833","-73.0833","Chile","CL","CHL","Los Ríos","minor","26517","1152649834"
"Valdagno","Valdagno","45.6500","11.3000","Italy","IT","ITA","Veneto","","26016","1380223912"
"Redenção","Redencao","-4.2258","-38.7308","Brazil","BR","BRA","Ceará","","26415","1076923300"
"Ganjing","Ganjing","35.3338","110.0955","China","CN","CHN","Shaanxi","","26224","1156233174"
"Madalum","Madalum","7.8530","124.1190","Philippines","PH","PHL","Lanao del Sur","","26478","1608644937"
"Campos Sales","Campos Sales","-7.0739","-40.3758","Brazil","BR","BRA","Ceará","","26506","1076347129"
"Budhlāda","Budhlada","29.9300","75.5700","India","IN","IND","Punjab","","26172","1356751792"
"Safīpur","Safipur","26.7300","80.3500","India","IN","IND","Uttar Pradesh","","25688","1356233932"
"Zhaicun","Zhaicun","22.6174","112.6275","China","CN","CHN","Guangdong","","26254","1156600491"
"Del Gallego","Del Gallego","13.9233","122.5961","Philippines","PH","PHL","Camarines Sur","","26403","1608303439"
"Nacimiento","Nacimiento","-37.5000","-72.6667","Chile","CL","CHL","Biobío","","26523","1152525394"
"Rīngas","Ringas","27.3700","75.5700","India","IN","IND","Rājasthān","","26139","1356539629"
"Kayapa","Kayapa","16.3583","120.8861","Philippines","PH","PHL","Nueva Vizcaya","","26469","1608843014"
"Migdal Ha‘Emeq","Migdal Ha\`Emeq","32.6714","35.2406","Israel","IL","ISR","Northern","","25600","1376279434"
"Charata","Charata","-27.2167","-61.2000","Argentina","AR","ARG","Chaco","minor","26497","1032241417"
"Mount Gambier","Mount Gambier","-37.8294","140.7828","Australia","AU","AUS","South Australia","","25591","1036932780"
"Manoli","Manoli","15.7800","75.1200","India","IN","IND","Karnātaka","","26264","1356767918"
"Mannachchanellūr","Mannachchanellur","10.9078","78.7022","India","IN","IND","Tamil Nādu","","25931","1356064239"
"Bouknadel","Bouknadel","34.1333","-6.7333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","25255","1504368806"
"São João Nepomuceno","Sao Joao Nepomuceno","-21.5400","-43.0108","Brazil","BR","BRA","Minas Gerais","","26447","1076117412"
"Haines City","Haines City","28.1102","-81.6157","United States","US","USA","Florida","","26510","1840014136"
"Xincun","Xincun","21.6960","111.4400","China","CN","CHN","Guangdong","","26486","1156551591"
"Santa Josefa","Santa Josefa","7.9842","126.0285","Philippines","PH","PHL","Agusan del Sur","","26432","1608144664"
"Terdāl","Terdal","16.5000","75.0500","India","IN","IND","Karnātaka","","26088","1356680781"
"Vadakku Viravanallur","Vadakku Viravanallur","8.6979","77.5192","India","IN","IND","Tamil Nādu","","26116","1356820577"
"Horsham","Horsham","40.1993","-75.1665","United States","US","USA","Pennsylvania","","26503","1840035022"
"São Bernardo","Sao Bernardo","-3.3608","-42.4178","Brazil","BR","BRA","Maranhão","","26476","1076666817"
"East Chicago","East Chicago","41.6469","-87.4545","United States","US","USA","Indiana","","26502","1840008193"
"Água Azul do Norte","Agua Azul do Norte","-6.7908","-50.4669","Brazil","BR","BRA","Pará","","26497","1076912396"
"Makubetsu","Makubetsu","42.9085","143.3561","Japan","JP","JPN","Hokkaidō","","26444","1392003307"
"El Monte","El Monte","-33.6833","-71.0167","Chile","CL","CHL","Región Metropolitana","","26459","1152684387"
"Tabira","Tabira","-7.5908","-37.5394","Brazil","BR","BRA","Pernambuco","","26427","1076875239"
"Al ‘Awwāmīyah","Al \`Awwamiyah","26.5833","49.9833","Saudi Arabia","SA","SAU","Ash Sharqīyah","","25500","1682093940"
"Ubaidullāhganj","Ubaidullahganj","22.9983","77.5862","India","IN","IND","Madhya Pradesh","","26318","1356028433"
"Nordenham","Nordenham","53.5000","8.4667","Germany","DE","DEU","Lower Saxony","","26193","1276525140"
"Santa Lucia","Santa Lucia","17.1222","120.4494","Philippines","PH","PHL","Ilocos Sur","","25966","1608914119"
"Licey al Medio","Licey al Medio","19.4300","-70.6200","Dominican Republic","DO","DOM","Cibao Norte","","25539","1214865144"
"Aurillac","Aurillac","44.9261","2.4406","France","FR","FRA","Auvergne-Rhône-Alpes","minor","25593","1250648062"
"Airmadidi","Airmadidi","1.4333","124.9833","Indonesia","ID","IDN","Sulawesi Utara","minor","26178","1360792086"
"Aït Faska","Ait Faska","31.5058","-7.7161","Morocco","MA","MAR","Marrakech-Safi","","26210","1504886930"
"Hjørring","Hjorring","57.4636","9.9814","Denmark","DK","DNK","Nordjylland","minor","25764","1208371956"
"Lilancheng","Lilancheng","39.2011","116.7169","China","CN","CHN","Hebei","","25322","1156159837"
"Renigunta","Renigunta","13.6500","79.5200","India","IN","IND","Andhra Pradesh","","26031","1356090944"
"Ksour Essaf","Ksour Essaf","35.4300","10.9800","Tunisia","TN","TUN","Mahdia","","26219","1788935774"
"Siquinalá","Siquinala","14.3082","-90.9659","Guatemala","GT","GTM","Escuintla","minor","26317","1320866947"
"Tālīkota","Talikota","16.4800","76.3200","India","IN","IND","Karnātaka","","26217","1356943515"
"Balboa","Balboa","2.0436","-77.2197","Colombia","CO","COL","Cauca","minor","26384","1170412260"
"Ōra","Ora","36.2524","139.4623","Japan","JP","JPN","Gunma","","25623","1392001653"
"Kirovsk","Kirovsk","59.8667","30.9833","Russia","RU","RUS","Leningradskaya Oblast’","","26387","1643936101"
"Aru","Aru","2.8617","30.8333","Congo (Kinshasa)","CD","COD","Ituri","","26290","1180698715"
"Selm","Selm","51.6833","7.4833","Germany","DE","DEU","North Rhine-Westphalia","","26011","1276238006"
"Pati do Alferes","Pati do Alferes","-22.4289","-43.4189","Brazil","BR","BRA","Rio de Janeiro","","26359","1076839400"
"Bingen am Rhein","Bingen am Rhein","49.9667","7.9000","Germany","DE","DEU","Rhineland-Palatinate","","25757","1276879617"
"Wall","Wall","40.1674","-74.0974","United States","US","USA","New Jersey","","26439","1840081643"
"Gūdūru","Guduru","15.7748","77.8074","India","IN","IND","Andhra Pradesh","","26142","1356895039"
"San Juan Atitán","San Juan Atitan","15.4333","-91.6333","Guatemala","GT","GTM","Huehuetenango","minor","26031","1320659095"
"Arida","Arida","34.0833","135.1333","Japan","JP","JPN","Wakayama","","25737","1392003442"
"Caririaçu","Caririacu","-7.0419","-39.2839","Brazil","BR","BRA","Ceará","","26393","1076000543"
"Randolph","Randolph","40.8434","-74.5819","United States","US","USA","New Jersey","","26433","1840081731"
"Brawley","Brawley","32.9783","-115.5287","United States","US","USA","California","","26431","1840019378"
"Mansfield","Mansfield","41.7892","-72.2287","United States","US","USA","Connecticut","","26431","1840045040"
"Thatcham","Thatcham","51.4050","-1.2640","United Kingdom","GB","GBR","West Berkshire","","25267","1826803857"
"South Portland","South Portland","43.6310","-70.2895","United States","US","USA","Maine","","26428","1840000328"
"Liushuquan","Liushuquan","39.3512","118.1039","China","CN","CHN","Hebei","","26178","1156255440"
"Arrecifes","Arrecifes","-34.0667","-60.1167","Argentina","AR","ARG","Buenos Aires","minor","26400","1032802963"
"Kami","Kami","33.6000","133.6833","Japan","JP","JPN","Kōchi","","26376","1392003485"
"Canarana","Canarana","-11.6850","-41.7689","Brazil","BR","BRA","Bahia","","26382","1076773196"
"Sanger","Sanger","36.6990","-119.5575","United States","US","USA","California","","26424","1840021566"
"Ondokuzmayıs","Ondokuzmayis","41.4944","36.0789","Turkey","TR","TUR","Samsun","minor","26337","1792287985"
"Vignola","Vignola","44.4808","11.0022","Italy","IT","ITA","Emilia-Romagna","","25313","1380248860"
"Sangerhausen","Sangerhausen","51.4667","11.3000","Germany","DE","DEU","Saxony-Anhalt","minor","26297","1276588418"
"Topoľčany","Topol'cany","48.5542","18.1769","Slovakia","SK","SVK","Nitra","minor","25492","1703972339"
"Port Alfred","Port Alfred","-33.5917","26.8875","South Africa","ZA","ZAF","Eastern Cape","","25859","1710920478"
"Walpole","Walpole","42.1464","-71.2555","United States","US","USA","Massachusetts","","26413","1840053559"
"Sapouy","Sapouy","11.5544","-1.7736","Burkina Faso","BF","BFA","Centre-Ouest","minor","26345","1854971695"
"Cabot","Cabot","34.9766","-92.0274","United States","US","USA","Arkansas","","26411","1840013530"
"Navabad","Navabad","38.5667","68.5167","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","26321","1762368316"
"Manito","Manito","13.1235","123.8693","Philippines","PH","PHL","Albay","","26162","1608821494"
"Whitehaven","Whitehaven","54.5480","-3.5855","United Kingdom","GB","GBR","Cumbria","","25032","1826916205"
"Lamut","Lamut","16.6517","121.2178","Philippines","PH","PHL","Ifugao","","26235","1608437546"
"Panchari Bazar","Panchari Bazar","23.2900","91.9000","Bangladesh","BD","BGD","Chattogram","","26319","1050934898"
"Burgos","Burgos","17.0667","121.7000","Philippines","PH","PHL","Isabela","","26040","1608000109"
"Sinait","Sinait","17.8667","120.4583","Philippines","PH","PHL","Ilocos Sur","","25998","1608219534"
"La Teste-de-Buch","La Teste-de-Buch","44.6200","-1.1457","France","FR","FRA","Nouvelle-Aquitaine","","26248","1250666362"
"Cotoca","Cotoca","-17.7536","-62.9967","Bolivia","BO","BOL","Santa Cruz","","26305","1068998348"
"Pensilvania","Pensilvania","5.5000","-75.0833","Colombia","CO","COL","Caldas","minor","26342","1170749751"
"São João Batista","Sao Joao Batista","-27.2758","-48.8489","Brazil","BR","BRA","Santa Catarina","","26260","1076000370"
"Ilha Solteira","Ilha Solteira","-20.4272","-51.3436","Brazil","BR","BRA","São Paulo","","26344","1076272168"
"İncesu","Incesu","38.6222","35.1847","Turkey","TR","TUR","Kayseri","minor","26353","1792170018"
"Kernersville","Kernersville","36.1065","-80.0842","United States","US","USA","North Carolina","","26376","1840016123"
"Conversano","Conversano","40.9667","17.1167","Italy","IT","ITA","Puglia","","26171","1380054683"
"Frauenfeld","Frauenfeld","47.5500","8.9000","Switzerland","CH","CHE","Thurgau","","25442","1756399413"
"Bourdoud","Bourdoud","34.5922","-4.5492","Morocco","MA","MAR","Fès-Meknès","","26236","1504015338"
"Poldokhtar","Poldokhtar","33.1536","47.7136","Iran","IR","IRN","Lorestān","minor","26352","1364688613"
"Owatonna","Owatonna","44.0912","-93.2312","United States","US","USA","Minnesota","","26366","1840008983"
"Buenavista","Buenavista","13.2500","121.9500","Philippines","PH","PHL","Marinduque","","26043","1608976324"
"Pukekohe East","Pukekohe East","-37.2000","174.9000","New Zealand","NZ","NZL","Auckland","","26300","1554599644"
"Termini Imerese","Termini Imerese","37.9833","13.7000","Italy","IT","ITA","Sicilia","","26029","1380818198"
"San Pablo","San Pablo","17.4478","121.7950","Philippines","PH","PHL","Isabela","","26320","1608290602"
"Kalfou","Kalfou","10.2833","14.9333","Cameroon","CM","CMR","Extrême-Nord","","26223","1120297396"
"Susquehanna","Susquehanna","40.3111","-76.8699","United States","US","USA","Pennsylvania","","26354","1840149537"
"Sun City West","Sun City West","33.6695","-112.3573","United States","US","USA","Arizona","","26354","1840019312"
"Arzignano","Arzignano","45.5167","11.3333","Italy","IT","ITA","Veneto","","25605","1380567531"
"Rāman","Raman","29.9504","74.9785","India","IN","IND","Punjab","","25807","1356018297"
"Bad Honnef am Rhein","Bad Honnef am Rhein","50.6450","7.2269","Germany","DE","DEU","North Rhine-Westphalia","","25816","1276512885"
"Chitarpur","Chitarpur","23.5794","85.6548","India","IN","IND","Jhārkhand","","25048","1356110582"
"Tiruppattūr","Tiruppattur","10.1300","78.6200","India","IN","IND","Tamil Nādu","","25980","1356338351"
"Shuanghe","Shuanghe","33.0320","109.6099","China","CN","CHN","Shaanxi","","26171","1156604501"
"Šumperk","Sumperk","49.9653","16.9707","Czechia","CZ","CZE","Olomoucký Kraj","","25452","1203170809"
"Kurchaloy","Kurchaloy","43.2044","46.0911","Russia","RU","RUS","Chechnya","","25672","1643001462"
"Maheshwar","Maheshwar","22.1769","75.5856","India","IN","IND","Madhya Pradesh","","25936","1356130674"
"Beramanja","Beramanja","-13.3333","48.8667","Madagascar","MG","MDG","Antsiranana","","26273","1450322357"
"Bangzha","Bangzha","24.8345","104.6721","China","CN","CHN","Guizhou","","26027","1156734177"
"Sora","Sora","41.7167","13.6167","Italy","IT","ITA","Lazio","","25972","1380902074"
"Kluczbork","Kluczbork","50.9833","18.2167","Poland","PL","POL","Opolskie","minor","26164","1616259508"
"Buddayyakota","Buddayyakota","13.6593","78.2632","India","IN","IND","Andhra Pradesh","","26191","1356445403"
"Dengjiazhuang","Dengjiazhuang","37.7051","115.7883","China","CN","CHN","Hebei","","26081","1156534159"
"Mahitsy","Mahitsy","-18.7500","47.3500","Madagascar","MG","MDG","Antananarivo","","26056","1450344593"
"Lindau","Lindau","47.5458","9.6833","Germany","DE","DEU","Bavaria","","25547","1276918139"
"Chinna Salem","Chinna Salem","11.6342","78.8741","India","IN","IND","Tamil Nādu","","25761","1356043149"
"Bushey","Bushey","51.6429","-0.3604","United Kingdom","GB","GBR","Hertfordshire","","25328","1826690246"
"Dugda","Dugda","23.7452","86.1717","India","IN","IND","Jhārkhand","","24904","1356626278"
"Teustepe","Teustepe","12.4206","-85.7997","Nicaragua","NI","NIC","Boaco","minor","26265","1558471999"
"Farnham","Farnham","51.2150","-0.7990","United Kingdom","GB","GBR","Surrey","","25604","1826338893"
"Bozkır","Bozkir","37.1886","32.2456","Turkey","TR","TUR","Konya","minor","26287","1792736010"
"Geretsried","Geretsried","47.8667","11.4667","Germany","DE","DEU","Bavaria","","25275","1276737058"
"Fleetwood","Fleetwood","53.9220","-3.0120","United Kingdom","GB","GBR","Lancashire","","25939","1826937482"
"Saikaichō-kobagō","Saikaicho-kobago","32.9333","129.6500","Japan","JP","JPN","Nagasaki","","26188","1392114454"
"Sanchez-Mira","Sanchez-Mira","18.5611","121.2344","Philippines","PH","PHL","Cagayan","","26164","1608566052"
"Friedrichsdorf","Friedrichsdorf","50.2556","8.6397","Germany","DE","DEU","Hesse","","25450","1276577829"
"Kottapeta","Kottapeta","16.7167","81.9000","India","IN","IND","Andhra Pradesh","","24539","1356033971"
"Boureït","Boureit","34.9833","-4.9167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","25872","1504130214"
"Perevalsk","Perevalsk","48.4333","38.8167","Ukraine","UA","UKR","Luhanska Oblast","minor","26132","1804106401"
"Buesaco","Buesaco","1.3833","-77.1667","Colombia","CO","COL","Nariño","minor","26229","1170721996"
"Polasara","Polasara","19.7815","84.5836","India","IN","IND","Odisha","","25829","1356234116"
"Montemor-o-Velho","Montemor-o-Velho","40.1667","-8.6833","Portugal","PT","PRT","Coimbra","minor","26171","1620287392"
"Itaíba","Itaiba","-8.9478","-37.4228","Brazil","BR","BRA","Pernambuco","","26256","1076158935"
"Yapacani","Yapacani","-17.4028","-63.8850","Bolivia","BO","BOL","Santa Cruz","","26270","1068002876"
"Ambatomborona","Ambatomborona","-19.4000","47.1167","Madagascar","MG","MDG","Antananarivo","","26000","1450670028"
"Union City","Union City","33.5942","-84.5629","United States","US","USA","Georgia","","26273","1840015610"
"Semīrom","Semirom","31.4142","51.5694","Iran","IR","IRN","Eşfahān","minor","26260","1364089259"
"Rancho Grande","Rancho Grande","13.2353","-85.5589","Nicaragua","NI","NIC","Matagalpa","minor","26223","1558000014"
"Gulām","Gulam","25.7965","86.7416","India","IN","IND","Bihār","","25149","1356025076"
"Batavia","Batavia","41.8479","-88.3109","United States","US","USA","Illinois","","26261","1840007035"
"Elefsína","Elefsina","38.0333","23.5333","Greece","GR","GRC","Attikí","minor","24910","1300591853"
"Vertou","Vertou","47.1689","-1.4697","France","FR","FRA","Pays de la Loire","","25541","1250277528"
"Lanaken","Lanaken","50.8925","5.6497","Belgium","BE","BEL","Flanders","","25818","1056538435"
"Étampes","Etampes","48.4343","2.1615","France","FR","FRA","Île-de-France","minor","25629","1250906889"
"Xishan","Xishan","23.0636","115.5467","China","CN","CHN","Guangdong","","25881","1156094430"
"Paducah","Paducah","37.0711","-88.6440","United States","US","USA","Kentucky","","26248","1840014388"
"Iradan","Iradan","40.2600","72.1000","Kyrgyzstan","KG","KGZ","Batken","","26200","1417514470"
"Infanta","Infanta","15.8208","119.9083","Philippines","PH","PHL","Pangasinan","","26242","1608909845"
"Paz de Ariporo","Paz de Ariporo","5.8833","-71.9000","Colombia","CO","COL","Casanare","minor","26237","1170610185"
"Dongfeng","Dongfeng","22.2479","112.3794","China","CN","CHN","Guangdong","","26129","1156247235"
"Cañas","Canas","10.4457","-85.0992","Costa Rica","CR","CRI","Guanacaste","","26201","1188753368"
"La Máquina","La Maquina","14.3021","-91.5655","Guatemala","GT","GTM","Suchitepéquez","minor","26062","1320355896"
"Mint Hill","Mint Hill","35.1783","-80.6528","United States","US","USA","North Carolina","","26236","1840016351"
"Gressier","Gressier","18.5500","-72.5167","Haiti","HT","HTI","Ouest","","25947","1332282683"
"Świecie","Swiecie","53.4167","18.4333","Poland","PL","POL","Kujawsko-Pomorskie","minor","26026","1616982457"
"Malyn","Malyn","50.7689","29.2700","Ukraine","UA","UKR","Zhytomyrska Oblast","","26204","1804889971"
"Burlington","Burlington","42.5022","-71.2027","United States","US","USA","Massachusetts","","26234","1840053476"
"Trindade","Trindade","-7.7619","-40.2678","Brazil","BR","BRA","Pernambuco","","26116","1076703158"
"Köthen","Kothen","51.7500","11.9167","Germany","DE","DEU","Saxony-Anhalt","minor","25911","1276148346"
"Attimarappatti","Attimarappatti","8.7297","78.1169","India","IN","IND","Tamil Nādu","","24772","1356214140"
"Sierra Bullones","Sierra Bullones","9.8167","124.2833","Philippines","PH","PHL","Bohol","","26095","1608138395"
"Bom Jesus dos Perdões","Bom Jesus dos Perdoes","-23.1350","-46.4653","Brazil","BR","BRA","São Paulo","","25985","1076453674"
"Barki Ballia","Barki Ballia","25.4223","86.2758","India","IN","IND","Bihār","","25020","1356311518"
"Potrerillos","Potrerillos","15.2167","-87.9500","Honduras","HN","HND","Cortés","","25960","1340725749"
"Conde","Conde","-11.8139","-37.6108","Brazil","BR","BRA","Bahia","","26194","1076912261"
"Haoping","Haoping","32.5992","108.6148","China","CN","CHN","Shaanxi","","25903","1156912101"
"Vsetín","Vsetin","49.3387","17.9962","Czechia","CZ","CZE","Zlínský Kraj","","25782","1203549413"
"El Progreso","El Progreso","14.3500","-89.8500","Guatemala","GT","GTM","Jutiapa","minor","25839","1320689547"
"Phulera","Phulera","26.8700","75.2300","India","IN","IND","Rājasthān","","26091","1356254629"
"New Milton","New Milton","50.7600","-1.6500","United Kingdom","GB","GBR","Hampshire","","25717","1826998764"
"Khashuri","Khashuri","41.9975","43.5986","Georgia","GE","GEO","Shida Kartli","minor","26135","1268805813"
"Labytnangi","Labytnangi","66.6572","66.4183","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","26211","1643017504"
"Tavira","Tavira","37.1167","-7.6500","Portugal","PT","PRT","Faro","minor","26167","1620756467"
"Baykan","Baykan","38.1639","41.7847","Turkey","TR","TUR","Siirt","minor","26160","1792353918"
"Raghunāthpur","Raghunathpur","23.5500","86.6700","India","IN","IND","West Bengal","","25561","1356379674"
"Homewood","Homewood","33.4619","-86.8090","United States","US","USA","Alabama","","26206","1840014794"
"Gembloux","Gembloux","50.5667","4.6833","Belgium","BE","BEL","Wallonia","","25933","1056071343"
"Humberto de Campos","Humberto de Campos","-2.5978","-43.4608","Brazil","BR","BRA","Maranhão","","26189","1076571149"
"Yuanchang","Yuanchang","23.6420","120.3229","Taiwan","TW","TWN","Yunlin","","25840","1158471732"
"Casillas","Casillas","14.4171","-90.2442","Guatemala","GT","GTM","Santa Rosa","minor","26059","1320372175"
"Big Spring","Big Spring","32.2389","-101.4799","United States","US","USA","Texas","","26199","1840019471"
"Winona","Winona","44.0505","-91.6684","United States","US","USA","Minnesota","","26199","1840010034"
"Conception Bay South","Conception Bay South","47.5167","-52.9833","Canada","CA","CAN","Newfoundland and Labrador","","26199","1124000563"
"Nagar","Nagar","27.4247","77.0995","India","IN","IND","Rājasthān","","25572","1356030258"
"Ambatofotsy","Ambatofotsy","-21.7500","47.4833","Madagascar","MG","MDG","Fianarantsoa","","26000","1450519366"
"Castel Volturno","Castel Volturno","41.0500","13.9167","Italy","IT","ITA","Campania","","25847","1380523837"
"Kulmbach","Kulmbach","50.1000","11.4333","Germany","DE","DEU","Bavaria","minor","25915","1276728110"
"Lice","Lice","38.4500","40.6500","Turkey","TR","TUR","Diyarbakır","minor","26163","1792106480"
"Iaçu","Iacu","-12.7669","-40.2119","Brazil","BR","BRA","Bahia","","26178","1076999933"
"Tholen","Tholen","51.5333","4.2000","Netherlands","NL","NLD","Zeeland","minor","26085","1528449757"
"Puerto Varas","Puerto Varas","-41.3167","-72.9833","Chile","CL","CHL","Los Lagos","","26172","1152560692"
"Marimba","Marimba","-8.3667","17.0167","Angola","AO","AGO","Malanje","","26180","1024422457"
"El Quetzal","El Quetzal","14.7667","-91.8167","Guatemala","GT","GTM","San Marcos","minor","25888","1320885818"
"Rosario de la Frontera","Rosario de la Frontera","-25.8000","-64.9667","Argentina","AR","ARG","Salta","minor","26174","1032725904"
"Norco","Norco","33.9252","-117.5498","United States","US","USA","California","","26176","1840020554"
"Aracoiaba","Aracoiaba","-4.3708","-38.8139","Brazil","BR","BRA","Ceará","","26134","1076829052"
"Wetteren","Wetteren","51.0000","3.8833","Belgium","BE","BEL","Flanders","","25477","1056973131"
"Bessemer","Bessemer","33.3712","-86.9728","United States","US","USA","Alabama","","26171","1840013732"
"Achern","Achern","48.6333","8.0667","Germany","DE","DEU","Baden-Württemberg","","25776","1276045216"
"Socorro","Socorro","9.6181","125.9661","Philippines","PH","PHL","Surigao del Norte","","25942","1608166420"
"Taquari","Taquari","-29.8000","-51.8597","Brazil","BR","BRA","Rio Grande do Sul","","26092","1076188300"
"Bobon","Bobon","12.5167","124.5667","Philippines","PH","PHL","Northern Samar","","25964","1608017004"
"Emmeloord","Emmeloord","52.7097","5.7508","Netherlands","NL","NLD","Flevoland","minor","26055","1528979701"
"Attili","Attili","16.7000","81.6000","India","IN","IND","Andhra Pradesh","","25004","1356502281"
"Novopavlovsk","Novopavlovsk","43.9500","43.6333","Russia","RU","RUS","Stavropol’skiy Kray","","26106","1643845073"
"Mahates","Mahates","10.2333","-75.1833","Colombia","CO","COL","Bolívar","minor","26075","1170291893"
"Sasovo","Sasovo","54.3500","41.9167","Russia","RU","RUS","Ryazanskaya Oblast’","","25177","1643401119"
"Uherské Hradiště","Uherske Hradiste","49.0697","17.4597","Czechia","CZ","CZE","Zlínský Kraj","","25001","1203165664"
"Itapuranga","Itapuranga","-15.5619","-49.9489","Brazil","BR","BRA","Goiás","","26125","1076547032"
"Oum Hadjer","Oum Hadjer","13.2833","19.6833","Chad","TD","TCD","Batha","","26136","1148641107"
"Nij Khari","Nij Khari","22.0929","88.4288","India","IN","IND","West Bengal","","24496","1356997742"
"Madira","Madira","16.9167","80.3667","India","IN","IND","Andhra Pradesh","","25671","1356389157"
"Dancagan","Dancagan","7.6119","125.0028","Philippines","PH","PHL","Bukidnon","","26076","1608456608"
"Grevená","Grevena","40.0850","21.4275","Greece","GR","GRC","Dytikí Makedonía","minor","25905","1300453596"
"Toktogul","Toktogul","41.8722","72.9389","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","26115","1417791878"
"Santiago Papasquiaro","Santiago Papasquiaro","25.0439","-105.4192","Mexico","MX","MEX","Durango","minor","26121","1484176278"
"Mungaoli","Mungaoli","24.4084","78.0959","India","IN","IND","Madhya Pradesh","","25787","1356116361"
"Oltiariq","Oltiariq","40.3917","71.4767","Uzbekistan","UZ","UZB","Farg‘ona","minor","25543","1860439845"
"Lakshmīcharīpāra","Lakshmicharipara","22.7900","91.9000","Bangladesh","BD","BGD","Chattogram","","25994","1050022288"
"Mizdah","Mizdah","31.4450","12.9831","Libya","LY","LBY","Al Jabal al Gharbī","","26107","1434622990"
"Helmstedt","Helmstedt","52.2281","11.0106","Germany","DE","DEU","Lower Saxony","minor","25728","1276360393"
"Saint-Bruno-de-Montarville","Saint-Bruno-de-Montarville","45.5333","-73.3500","Canada","CA","CAN","Quebec","","26107","1124286783"
"Requínoa","Requinoa","-34.2786","-70.8117","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","26089","1152583421"
"Queimadas","Queimadas","-10.9778","-39.6239","Brazil","BR","BRA","Bahia","","26083","1076400945"
"Zacualtipán","Zacualtipan","20.6500","-98.6500","Mexico","MX","MEX","Hidalgo","","25987","1484806928"
"Mahambo","Mahambo","-17.4833","49.4667","Madagascar","MG","MDG","Toamasina","","26000","1450449223"
"Seondha","Seondha","26.1542","78.7812","India","IN","IND","Madhya Pradesh","","25773","1356928095"
"Troy","Troy","40.0437","-84.2186","United States","US","USA","Ohio","","26093","1840010508"
"East Hempfield","East Hempfield","40.0825","-76.3831","United States","US","USA","Pennsylvania","","26092","1840148811"
"San Sebastián Coatán","San Sebastian Coatan","15.7333","-91.5667","Guatemala","GT","GTM","Huehuetenango","minor","25935","1320533592"
"South Salt Lake","South Salt Lake","40.7057","-111.8986","United States","US","USA","Utah","","26086","1840021386"
"Mantua","Mantua","22.2908","-84.2875","Cuba","CU","CUB","Pinar del Río","","26065","1192303406"
"Sankt Wendel","Sankt Wendel","49.4667","7.1667","Germany","DE","DEU","Saarland","minor","25862","1276025539"
"Shāhedshahr","Shahedshahr","35.5714","51.0839","Iran","IR","IRN","Tehrān","","25544","1364463020"
"Odemira","Odemira","37.5833","-8.6333","Portugal","PT","PRT","Beja","minor","26066","1620717332"
"Ligang","Ligang","22.8011","120.5070","Taiwan","TW","TWN","Pingtung","","25701","1158548477"
"Lockport","Lockport","41.5906","-88.0293","United States","US","USA","Illinois","","26070","1840009256"
"Lübbecke","Lubbecke","52.3081","8.6231","Germany","DE","DEU","North Rhine-Westphalia","","25674","1276934438"
"Roseto degli Abruzzi","Roseto degli Abruzzi","42.6833","14.0167","Italy","IT","ITA","Abruzzo","","25588","1380181304"
"Bāsopatti","Basopatti","26.5780","86.0052","India","IN","IND","Bihār","","24808","1356088494"
"Tinipuka","Tinipuka","-4.5485","136.8895","Indonesia","ID","IDN","Papua","","26021","1360525797"
"Vierzon","Vierzon","47.2225","2.0694","France","FR","FRA","Centre-Val de Loire","minor","25725","1250535432"
"Zavodoukovsk","Zavodoukovsk","56.4833","66.5333","Russia","RU","RUS","Tyumenskaya Oblast’","","26048","1643833832"
"Puerto Píritu","Puerto Piritu","10.0667","-65.0500","Venezuela","VE","VEN","Anzoátegui","minor","25981","1862843143"
"Binidayan","Binidayan","7.8000","124.1667","Philippines","PH","PHL","Lanao del Sur","","25965","1608188559"
"Traun","Traun","48.2217","14.2397","Austria","AT","AUT","Oberösterreich","","24477","1040684469"
"Cocal","Cocal","-3.4708","-41.5550","Brazil","BR","BRA","Piauí","","26036","1076588558"
"Stein","Stein","50.9667","5.7667","Netherlands","NL","NLD","Limburg","minor","24961","1528261703"
"Sahaspur","Sahaspur","29.1210","78.6210","India","IN","IND","Uttar Pradesh","","24463","1356358964"
"Vijāpur","Vijapur","23.5700","72.7500","India","IN","IND","Gujarāt","","25558","1356813215"
"Hercules","Hercules","38.0064","-122.2564","United States","US","USA","California","","26050","1840020276"
"Tsarazaza","Tsarazaza","-20.1333","47.2333","Madagascar","MG","MDG","Fianarantsoa","","26000","1450665251"
"Coração de Jesus","Coracao de Jesus","-16.6850","-44.3650","Brazil","BR","BRA","Minas Gerais","","26033","1076466441"
"Duiven","Duiven","51.9500","6.0333","Netherlands","NL","NLD","Gelderland","minor","25332","1528557240"
"Villanueva de la Serena","Villanueva de la Serena","38.9667","-5.8000","Spain","ES","ESP","Extremadura","","25873","1724363816"
"Kadamalaikkundu","Kadamalaikkundu","9.8110","77.5039","India","IN","IND","Tamil Nādu","","25794","1356209842"
"Sirumugai","Sirumugai","11.3214","77.0052","India","IN","IND","Tamil Nādu","","25585","1356143655"
"Gaoya","Gaoya","36.4609","104.9936","China","CN","CHN","Gansu","","25993","1156452678"
"Gajwel","Gajwel","17.8517","78.6828","India","IN","IND","Andhra Pradesh","","24961","1356985510"
"Tabina","Tabina","7.4655","123.4086","Philippines","PH","PHL","Zamboanga del Sur","","25734","1608883183"
"Austin","Austin","43.6721","-92.9784","United States","US","USA","Minnesota","","26030","1840006830"
"Montichiari","Montichiari","45.4161","10.3917","Italy","IT","ITA","Lombardy","","25714","1380668110"
"Malaimbandy","Malaimbandy","-20.3333","45.6000","Madagascar","MG","MDG","Toliara","","26000","1450415415"
"Diapaga","Diapaga","12.0667","1.7833","Burkina Faso","BF","BFA","Est","minor","26013","1854687079"
"Dano","Dano","11.1436","-3.0628","Burkina Faso","BF","BFA","Sud-Ouest","minor","25922","1854971871"
"Ōguchi","Oguchi","35.3325","136.9078","Japan","JP","JPN","Aichi","","24234","1392003283"
"Medina","Medina","41.1358","-81.8695","United States","US","USA","Ohio","","26011","1840003486"
"Rāghopur","Raghopur","25.5323","85.3809","India","IN","IND","Bihār","","24838","1356915365"
"Ponta de Pedras","Ponta de Pedras","-1.3900","-48.8708","Brazil","BR","BRA","Pará","","25999","1076902460"
"Santo Antônio do Monte","Santo Antonio do Monte","-20.0869","-45.2939","Brazil","BR","BRA","Minas Gerais","","25975","1076769736"
"Oupeye","Oupeye","50.7083","5.6431","Belgium","BE","BEL","Wallonia","","25297","1056414303"
"Correggio","Correggio","44.7703","10.7822","Italy","IT","ITA","Emilia-Romagna","","25664","1380849664"
"Istmina","Istmina","5.1667","-76.6833","Colombia","CO","COL","Chocó","minor","25981","1170211056"
"Ridgewood","Ridgewood","40.9821","-74.1127","United States","US","USA","New Jersey","","25991","1840000899"
"Maozhou","Maozhou","38.8600","116.1244","China","CN","CHN","Hebei","","25509","1156590130"
"Kimovsk","Kimovsk","53.9667","38.5333","Russia","RU","RUS","Tul’skaya Oblast’","","25951","1643483377"
"Sirmatpur","Sirmatpur","25.3235","87.3836","India","IN","IND","Bihār","","24830","1356181970"
"Tekman","Tekman","39.6450","41.5083","Turkey","TR","TUR","Erzurum","minor","25969","1792042705"
"Kīranūr","Kiranur","11.6920","79.2899","India","IN","IND","Tamil Nādu","","25420","1356113043"
"Traralgon","Traralgon","-38.1958","146.5403","Australia","AU","AUS","Victoria","","24933","1036938861"
"Marmara Ereğlisi","Marmara Ereglisi","40.9697","27.9553","Turkey","TR","TUR","Tekirdağ","minor","25873","1792522233"
"Koko","Koko","11.4232","4.5170","Nigeria","NG","NGA","Kebbi","","25792","1566646210"
"Tubay","Tubay","9.1650","125.5226","Philippines","PH","PHL","Agusan del Norte","","25785","1608372831"
"Demre","Demre","36.2472","29.9828","Turkey","TR","TUR","Antalya","minor","25893","1792936795"
"Nāgod","Nagod","24.5692","80.5881","India","IN","IND","Madhya Pradesh","","25688","1356118219"
"Maintirano","Maintirano","-18.0667","44.0167","Madagascar","MG","MDG","Mahajanga","minor","25788","1450625023"
"Natividad","Natividad","16.0422","120.7953","Philippines","PH","PHL","Pangasinan","","25771","1608325844"
"El Jícaro","El Jicaro","13.7225","-86.1347","Nicaragua","NI","NIC","Nueva Segovia","minor","25901","1558370447"
"Nagai","Nagai","38.1075","140.0405","Japan","JP","JPN","Yamagata","","25836","1392003142"
"Santa Ana","Santa Ana","9.3190","-74.5700","Colombia","CO","COL","Magdalena","minor","25938","1170325175"
"Dolores","Dolores","-36.3167","-57.6667","Argentina","AR","ARG","Buenos Aires","minor","25940","1032650497"
"Torrelodones","Torrelodones","40.5756","-3.9292","Spain","ES","ESP","Madrid","","24775","1724434250"
"San Vicente de Cañete","San Vicente de Canete","-13.0833","-76.4000","Peru","PE","PER","Lima","","25829","1604901496"
"Novelda","Novelda","38.3850","-0.7680","Spain","ES","ESP","Valencia","","25611","1724123582"
"Tosa","Tosa","33.5000","133.4333","Japan","JP","JPN","Kōchi","","25665","1392003205"
"Talisayan","Talisayan","8.9917","124.8833","Philippines","PH","PHL","Misamis Oriental","","25761","1608510465"
"Santa Maria","Santa Maria","17.4667","121.7500","Philippines","PH","PHL","Isabela","","25758","1608195670"
"Yinajia","Yinajia","26.8239","105.6950","China","CN","CHN","Guizhou","","25745","1156264480"
"Mimoso do Sul","Mimoso do Sul","-21.0639","-41.3658","Brazil","BR","BRA","Espírito Santo","","25902","1076302164"
"Grandview","Grandview","38.8802","-94.5227","United States","US","USA","Missouri","","25931","1840008532"
"Ōmachi","Omachi","36.5030","137.8511","Japan","JP","JPN","Nagano","","25883","1392979595"
"Malaṅgawā","Malangawa","26.8667","85.5667","Nepal","NP","NPL","","","25102","1524985987"
"Columbine","Columbine","39.5879","-105.0694","United States","US","USA","Colorado","","25924","1840135257"
"De Witt","De Witt","43.0501","-76.0710","United States","US","USA","New York","","25920","1840087377"
"Wetzikon","Wetzikon","47.3167","8.8000","Switzerland","CH","CHE","Zürich","","24452","1756078126"
"Āgaro","Agaro","7.8500","36.5833","Ethiopia","ET","ETH","Oromīya","","25458","1231182793"
"Lourinhã","Lourinha","39.2333","-9.3000","Portugal","PT","PRT","Lisboa","minor","25735","1620671850"
"Tarangnan","Tarangnan","11.9000","124.7500","Philippines","PH","PHL","Samar","","25713","1608797331"
"Alegria","Alegria","9.7243","123.3402","Philippines","PH","PHL","Cebu","","25620","1608812207"
"San Martín","San Martin","3.6944","-73.6936","Colombia","CO","COL","Meta","minor","25902","1170677493"
"Beersel","Beersel","50.7631","4.3086","Belgium","BE","BEL","Flanders","","25069","1056689003"
"Alaçam","Alacam","41.6100","35.5950","Turkey","TR","TUR","Samsun","minor","25854","1792375686"
"Singarāyakonda","Singarayakonda","15.2500","80.0300","India","IN","IND","Andhra Pradesh","","24324","1356627403"
"Ciempozuelos","Ciempozuelos","40.1592","-3.6183","Spain","ES","ESP","Madrid","","25383","1724341699"
"Santa María Colotepec","Santa Maria Colotepec","15.8833","-96.9167","Mexico","MX","MEX","Oaxaca","minor","24076","1484824159"
"Teniente Primero Manuel Irala Fernández","Teniente Primero Manuel Irala Fernandez","-22.8140","-59.5860","Paraguay","PY","PRY","Presidente Hayes","","25890","1600002648"
"Mola di Bari","Mola di Bari","41.0667","17.0833","Italy","IT","ITA","Puglia","","25393","1380831006"
"São Joaquim de Bicas","Sao Joaquim de Bicas","-20.0489","-44.2739","Brazil","BR","BRA","Minas Gerais","","25537","1076293816"
"Haisyn","Haisyn","48.8094","29.3906","Ukraine","UA","UKR","Vinnytska Oblast","minor","25833","1804988265"
"Wilsonville","Wilsonville","45.3109","-122.7702","United States","US","USA","Oregon","","25887","1840021219"
"Erandio","Erandio","43.3047","-2.9731","Spain","ES","ESP","Basque Country","","24489","1724931391"
"West Chicago","West Chicago","41.8960","-88.2253","United States","US","USA","Illinois","","25881","1840010165"
"Felixstowe","Felixstowe","51.9639","1.3515","United Kingdom","GB","GBR","Suffolk","","24441","1826193047"
"Mar de Ajó","Mar de Ajo","-36.7203","-56.6878","Argentina","AR","ARG","Buenos Aires","","25520","1032066406"
"Woodburn","Woodburn","45.1472","-122.8603","United States","US","USA","Oregon","","25877","1840021230"
"Vitez","Vitez","44.1585","17.7885","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","25836","1070182317"
"Miyaki","Miyaki","33.3249","130.4546","Japan","JP","JPN","Saga","","25386","1392003521"
"Altavas","Altavas","11.5383","122.4869","Philippines","PH","PHL","Aklan","","25639","1608142591"
"Busselton","Busselton","-33.6478","115.3458","Australia","AU","AUS","Western Australia","","25329","1036911902"
"Korkut","Korkut","38.7386","41.7828","Turkey","TR","TUR","Muş","minor","25817","1792285237"
"Pszczyna","Pszczyna","49.9833","18.9500","Poland","PL","POL","Śląskie","minor","25565","1616839287"
"Kunisakimachi-tsurugawa","Kunisakimachi-tsurugawa","33.5653","131.7311","Japan","JP","JPN","Ōita","","25790","1392098659"
"Andasibe","Andasibe","-17.3333","48.9000","Madagascar","MG","MDG","Toamasina","","25831","1450830509"
"Upper Moreland","Upper Moreland","40.1572","-75.1021","United States","US","USA","Pennsylvania","","25863","1840152387"
"Oxford","Oxford","34.3627","-89.5336","United States","US","USA","Mississippi","","25859","1840014688"
"Nova Soure","Nova Soure","-11.2328","-38.4828","Brazil","BR","BRA","Bahia","","25854","1076178035"
"Santa Lucia La Reforma","Santa Lucia La Reforma","15.1333","-91.2333","Guatemala","GT","GTM","Totonicapán","minor","25668","1320776973"
"Roth","Roth","49.2461","11.0911","Germany","DE","DEU","Bavaria","","25593","1276856142"
"Cochrane","Cochrane","51.1890","-114.4670","Canada","CA","CAN","Alberta","","25853","1124952542"
"Verl","Verl","51.8831","8.5167","Germany","DE","DEU","North Rhine-Westphalia","","25498","1276182306"
"Inkster","Inkster","42.2935","-83.3148","United States","US","USA","Michigan","","25849","1840003983"
"Pāveh","Paveh","35.0433","46.3564","Iran","IR","IRN","Kermānshāh","minor","25771","1364382725"
"Oulad Barhil","Oulad Barhil","30.6408","-8.4750","Morocco","MA","MAR","Souss-Massa","","24288","1504160582"
"Baliqchi","Baliqchi","40.9000","71.8528","Uzbekistan","UZ","UZB","Andijon","minor","25100","1860683125"
"Saintes","Saintes","45.7464","-0.6333","France","FR","FRA","Nouvelle-Aquitaine","minor","25287","1250181820"
"Yany Kapu","Yany Kapu","45.9675","33.8003","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","25769","1804718701"
"Fort Washington","Fort Washington","38.7339","-77.0069","United States","US","USA","Maryland","","25840","1840005953"
"Rāmdiri","Ramdiri","25.3118","86.1023","India","IN","IND","Bihār","","24595","1356818398"
"Scandiano","Scandiano","44.5925","10.6878","Italy","IT","ITA","Emilia-Romagna","","25758","1380786805"
"Harsewinkel","Harsewinkel","51.9667","8.2331","Germany","DE","DEU","North Rhine-Westphalia","","25575","1276882516"
"Woodstock","Woodstock","42.3096","-88.4353","United States","US","USA","Illinois","","25829","1840010107"
"Colleyville","Colleyville","32.8913","-97.1486","United States","US","USA","Texas","","25827","1840019430"
"Bagno a Ripoli","Bagno a Ripoli","43.7500","11.3167","Italy","IT","ITA","Tuscany","","25483","1380429442"
"Triunfo","Triunfo","-29.9428","-51.7178","Brazil","BR","BRA","Rio Grande do Sul","","25793","1076750257"
"Nechí","Nechi","8.1000","-74.7833","Colombia","CO","COL","Antioquia","minor","25790","1170181619"
"Huejotzingo","Huejotzingo","19.1594","-98.4073","Mexico","MX","MEX","Puebla","minor","25684","1484474577"
"Kireyevsk","Kireyevsk","53.9333","37.9333","Russia","RU","RUS","Tul’skaya Oblast’","","25741","1643694623"
"Hasköy","Haskoy","38.6822","41.6894","Turkey","TR","TUR","Muş","minor","25749","1792439039"
"Upper Macungie","Upper Macungie","40.5694","-75.6244","United States","US","USA","Pennsylvania","","25813","1840146587"
"Cláudio","Claudio","-20.4428","-44.7658","Brazil","BR","BRA","Minas Gerais","","25771","1076820271"
"Louis Trichardt","Louis Trichardt","-23.0500","29.9000","South Africa","ZA","ZAF","Limpopo","","25360","1710514527"
"Prata","Prata","-19.3069","-48.9239","Brazil","BR","BRA","Minas Gerais","","25802","1076234734"
"Baheri","Baheri","25.9426","86.0471","India","IN","IND","Bihār","","24284","1356549569"
"Batabanó","Batabano","22.7167","-82.2833","Cuba","CU","CUB","Mayabeque","minor","25664","1192995527"
"Golborne","Golborne","53.4758","-2.5943","United Kingdom","GB","GBR","Wigan","","24169","1826407882"
"Merkānam","Merkanam","12.1942","79.9435","India","IN","IND","Tamil Nādu","","25263","1356108640"
"Ureshinomachi-shimojuku","Ureshinomachi-shimojuku","33.1333","130.0667","Japan","JP","JPN","Saga","","25593","1392832096"
"Pereira Barreto","Pereira Barreto","-20.6383","-51.1092","Brazil","BR","BRA","São Paulo","","25767","1076105088"
"Marshfield","Marshfield","42.1140","-70.7151","United States","US","USA","Massachusetts","","25793","1840053681"
"Batalha","Batalha","-4.0239","-42.0778","Brazil","BR","BRA","Piauí","","25774","1076539157"
"Montgomery","Montgomery","40.2411","-75.2318","United States","US","USA","Pennsylvania","","25790","1840142129"
"Mogtédo","Mogtedo","12.2833","-0.8333","Burkina Faso","BF","BFA","Plateau-Central","","25699","1854602237"
"Nanpingcun","Nanpingcun","39.7530","114.0923","China","CN","CHN","Shanxi","","25710","1156273464"
"Adra","Adra","36.7478","-3.0161","Spain","ES","ESP","Andalusia","","25501","1724071055"
"Diébougou","Diebougou","10.9667","-3.2500","Burkina Faso","BF","BFA","Sud-Ouest","minor","25688","1854858995"
"São Miguel do Iguaçu","Sao Miguel do Iguacu","-25.3478","-54.2378","Brazil","BR","BRA","Paraná","","25769","1076475965"
"Lopez Jaena","Lopez Jaena","8.5500","123.7667","Philippines","PH","PHL","Misamis Occidental","","25507","1608136774"
"Firmat","Firmat","-33.4500","-61.4833","Argentina","AR","ARG","Santa Fe","","25757","1032869120"
"Harbiye","Harbiye","36.1167","36.1333","Turkey","TR","TUR","Hatay","minor","25118","1792958920"
"Camas","Camas","45.6005","-122.4304","United States","US","USA","Washington","","25774","1840018524"
"Kouhu","Kouhu","23.5763","120.1725","Taiwan","TW","TWN","Yunlin","","25454","1158470096"
"West Melbourne","West Melbourne","28.0694","-80.6736","United States","US","USA","Florida","","25768","1840015963"
"Forest Grove","Forest Grove","45.5243","-123.1097","United States","US","USA","Oregon","","25767","1840019930"
"Ban Piang Luang","Ban Piang Luang","19.6493","98.6352","Thailand","TH","THA","Chiang Mai","","25725","1764002589"
"Allen","Allen","12.5013","124.2820","Philippines","PH","PHL","Northern Samar","","25228","1608146334"
"Vaterstetten","Vaterstetten","48.1050","11.7706","Germany","DE","DEU","Bavaria","","25023","1276137898"
"Holt","Holt","42.6416","-84.5308","United States","US","USA","Michigan","","25755","1840004501"
"Stevens Point","Stevens Point","44.5241","-89.5507","United States","US","USA","Wisconsin","","25752","1840002320"
"Lanling","Lanling","23.0033","114.5671","China","CN","CHN","Guangdong","","25043","1156167543"
"Jaruco","Jaruco","23.0428","-82.0094","Cuba","CU","CUB","Mayabeque","minor","25658","1192724464"
"Bandar-e Deylam","Bandar-e Deylam","30.0542","50.1639","Iran","IR","IRN","Būshehr","minor","25730","1364497361"
"Oulad Said","Oulad Said","32.6320","-8.8456","Morocco","MA","MAR","Casablanca-Settat","","25650","1504360657"
"Ayuquitan","Ayuquitan","9.4644","123.2237","Philippines","PH","PHL","Negros Oriental","","25513","1608812083"
"Tipo-Tipo","Tipo-Tipo","6.5333","122.1667","Philippines","PH","PHL","Basilan","","25531","1608266318"
"Pfungstadt","Pfungstadt","49.8056","8.6044","Germany","DE","DEU","Hesse","","25151","1276568312"
"Los Arabos","Los Arabos","22.7400","-80.7161","Cuba","CU","CUB","Matanzas","minor","25702","1192836274"
"Overijse","Overijse","50.7833","4.5333","Belgium","BE","BEL","Flanders","","25169","1056558887"
"Xaxim","Xaxim","-26.9619","-52.5350","Brazil","BR","BRA","Santa Catarina","","25713","1076578728"
"Olesa de Montserrat","Olesa de Montserrat","41.5450","1.8944","Spain","ES","ESP","Catalonia","","24272","1724299057"
"La Esperanza","La Esperanza","14.8719","-91.5619","Guatemala","GT","GTM","Quetzaltenango","minor","24948","1320463184"
"Nogi","Nogi","36.2332","139.7407","Japan","JP","JPN","Tochigi","","24903","1392003268"
"Ishii","Ishii","34.0742","134.4406","Japan","JP","JPN","Tokushima","","24856","1392141128"
"Rinteln","Rinteln","52.1906","9.0814","Germany","DE","DEU","Lower Saxony","","25484","1276137941"
"Carahue","Carahue","-38.7089","-73.1647","Chile","CL","CHL","Araucanía","","25696","1152919462"
"Panelas","Panelas","-8.6639","-36.0058","Brazil","BR","BRA","Pernambuco","","25645","1076525779"
"Manyoni","Manyoni","-5.7500","34.8333","Tanzania","TZ","TZA","Singida","","25505","1834963293"
"Pinillos","Pinillos","8.9167","-74.4667","Colombia","CO","COL","Bolívar","minor","24923","1170148335"
"Salor","Salor","41.3833","69.3583","Uzbekistan","UZ","UZB","Toshkent","","25521","1860292312"
"Lamzoudia","Lamzoudia","31.5833","-8.4833","Morocco","MA","MAR","Marrakech-Safi","","25674","1504553058"
"Thetford Mines","Thetford Mines","46.1000","-71.3000","Canada","CA","CAN","Quebec","","25709","1124032181"
"Londonderry","Londonderry","42.8796","-71.3873","United States","US","USA","New Hampshire","","25708","1840055138"
"North Tustin","North Tustin","33.7636","-117.7947","United States","US","USA","California","","25708","1840019319"
"Mimata","Mimata","31.7307","131.1250","Japan","JP","JPN","Miyazaki","","25474","1392003371"
"Zaprešić","Zapresic","45.8572","15.8050","Croatia","HR","HRV","Zagrebačka Županija","minor","25223","1191023090"
"Uttaramerūr","Uttaramerur","12.6160","79.7530","India","IN","IND","Tamil Nādu","","25194","1356014919"
"Ditzingen","Ditzingen","48.8264","9.0667","Germany","DE","DEU","Baden-Württemberg","","24883","1276272726"
"Villanueva","Villanueva","12.9647","-86.8150","Nicaragua","NI","NIC","Chinandega","minor","25660","1558614186"
"Igaci","Igaci","-9.5369","-36.6339","Brazil","BR","BRA","Alagoas","","25613","1076358024"
"Lennestadt","Lennestadt","51.1236","8.0681","Germany","DE","DEU","North Rhine-Westphalia","","25503","1276165009"
"Haiwei","Haiwei","19.4275","108.8129","China","CN","CHN","Hainan","","25568","1156252846"
"Libourne","Libourne","44.9200","-0.2400","France","FR","FRA","Nouvelle-Aquitaine","minor","24511","1250403589"
"Balabanovo","Balabanovo","55.1833","36.6667","Russia","RU","RUS","Kaluzhskaya Oblast’","","25608","1643544515"
"Otradnoye","Otradnoye","59.7833","30.8167","Russia","RU","RUS","Leningradskaya Oblast’","","25623","1643443234"
"Busuanga","Busuanga","12.1335","119.9363","Philippines","PH","PHL","Palawan","","25617","1608290614"
"Vnukovo","Vnukovo","55.5997","37.2800","Russia","RU","RUS","Moskovskaya Oblast’","","25406","1643528688"
"Sangrāmpur","Sangrampur","26.4752","84.6898","India","IN","IND","Bihār","","24064","1356149238"
"Irugūr","Irugur","11.0178","77.0629","India","IN","IND","Tamil Nādu","","24581","1356858696"
"Budai","Budai","23.3600","120.1700","Taiwan","TW","TWN","Chiayi","","25267","1158993335"
"Yolombó","Yolombo","6.5978","-75.0122","Colombia","CO","COL","Antioquia","minor","25647","1170796377"
"Kamonkoli","Kamonkoli","1.0750","34.0956","Uganda","UG","UGA","Budaka","","25000","1800717956"
"Witham","Witham","51.7978","0.6373","United Kingdom","GB","GBR","Essex","","25542","1826336424"
"Cayo Mambí","Cayo Mambi","20.6647","-75.2814","Cuba","CU","CUB","Holguín","minor","25621","1192030394"
"Palma","Palma","-10.7758","40.4667","Mozambique","MZ","MOZ","Cabo Delgado","","25627","1508033717"
"Nesher","Nesher","32.7711","35.0394","Israel","IL","ISR","Haifa","","23700","1376735055"
"Fujikawaguchiko","Fujikawaguchiko","35.4973","138.7549","Japan","JP","JPN","Yamanashi","","25495","1392003259"
"San Pedro Masahuat","San Pedro Masahuat","13.5500","-89.0333","El Salvador","SV","SLV","La Paz","","25446","1222970423"
"Ḩaql","Haql","29.2833","34.9333","Saudi Arabia","SA","SAU","Al Jawf","","25649","1682704031"
"Unisan","Unisan","13.8413","121.9752","Philippines","PH","PHL","Quezon","","25448","1608135212"
"Boquim","Boquim","-11.1469","-37.6208","Brazil","BR","BRA","Sergipe","","25533","1076424081"
"Chanhassen","Chanhassen","44.8546","-93.5621","United States","US","USA","Minnesota","","25646","1840006759"
"Veenoord","Veenoord","52.9875","6.2914","Netherlands","NL","NLD","Fryslân","","25531","1528771829"
"La Valette-du-Var","La Valette-du-Var","43.1383","5.9831","France","FR","FRA","Provence-Alpes-Côte d’Azur","","24087","1250438884"
"Bejucal","Bejucal","22.9328","-82.3869","Cuba","CU","CUB","Mayabeque","minor","25425","1192816073"
"Tegalbuleud","Tegalbuleud","-7.3578","106.6220","Indonesia","ID","IDN","Jawa Barat","","25000","1360719911"
"Jardim","Jardim","-21.4800","-56.1378","Brazil","BR","BRA","Mato Grosso do Sul","","25617","1076077588"
"Sarrat","Sarrat","18.1578","120.6447","Philippines","PH","PHL","Ilocos Norte","","25186","1608165020"
"Rottweil","Rottweil","48.1681","8.6247","Germany","DE","DEU","Baden-Württemberg","minor","25274","1276117708"
"Pauri","Pauri","30.1500","78.7800","India","IN","IND","Uttarākhand","","25440","1356678121"
"Nabīnagar","Nabinagar","24.6200","84.1200","India","IN","IND","Bihār","","25146","1356155865"
"Mata Grande","Mata Grande","-9.1178","-37.7339","Brazil","BR","BRA","Alagoas","","25589","1076215597"
"Fengruncun","Fengruncun","34.8537","109.8283","China","CN","CHN","Shaanxi","","25236","1156760227"
"Andhra Thārhi","Andhra Tharhi","26.3771","86.3341","India","IN","IND","Bihār","","24497","1356372709"
"Nāmrup","Namrup","27.1939","95.3193","India","IN","IND","Assam","","25107","1356777660"
"Ḑubā","Duba","27.3493","35.6962","Saudi Arabia","SA","SAU","Tabūk","","25601","1682927913"
"Wiehl","Wiehl","50.9500","7.5333","Germany","DE","DEU","North Rhine-Westphalia","","25135","1276813101"
"Erlun","Erlun","23.7910","120.3954","Taiwan","TW","TWN","Yunlin","","25183","1158955964"
"Oliva","Oliva","38.9194","-0.1211","Spain","ES","ESP","Valencia","","25179","1724426329"
"Courtenay","Courtenay","49.6878","-124.9944","Canada","CA","CAN","British Columbia","","25599","1124324905"
"Podatūrpeta","Podaturpeta","13.2817","79.4854","India","IN","IND","Tamil Nādu","","24703","1356639054"
"Şarkîkaraağaç","Sarkikaraagac","38.0803","31.3658","Turkey","TR","TUR","Isparta","minor","25578","1792228308"
"Ấp Khánh Hòa","Ap Khanh Hoa","10.6333","105.2167","Vietnam","VN","VNM","An Giang","","24486","1704618066"
"Horb am Neckar","Horb am Neckar","48.4453","8.6911","Germany","DE","DEU","Baden-Württemberg","","25379","1276353248"
"Vynohradiv","Vynohradiv","48.1397","23.0331","Ukraine","UA","UKR","Zakarpatska Oblast","minor","25403","1804673048"
"Lower Providence","Lower Providence","40.1485","-75.4266","United States","US","USA","Pennsylvania","","25589","1840142132"
"Mərdəkan","Mardakan","40.4922","50.1389","Azerbaijan","AZ","AZE","Bakı","","25232","1031262332"
"Kazincbarcika","Kazincbarcika","48.2531","20.6456","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","24905","1348628122"
"Ash Shūnah ash Shamālīyah","Ash Shunah ash Shamaliyah","32.6100","35.6081","Jordan","JO","JOR","Irbid","minor","25000","1400135383"
"Wałcz","Walcz","53.2667","16.4667","Poland","PL","POL","Zachodniopomorskie","minor","24949","1616129049"
"Pangil","Pangil","14.4000","121.4667","Philippines","PH","PHL","Laguna","","25026","1608594203"
"Independência","Independencia","-5.3958","-40.3089","Brazil","BR","BRA","Ceará","","25573","1076432999"
"Vāsudevanallūr","Vasudevanallur","9.2417","77.4118","India","IN","IND","Tamil Nādu","","25161","1356139217"
"Kocaali","Kocaali","41.0547","30.8503","Turkey","TR","TUR","Sakarya","minor","25497","1792708434"
"Legnago","Legnago","45.1929","11.3115","Italy","IT","ITA","Veneto","","25259","1380904282"
"Plettenberg","Plettenberg","51.2167","7.8833","Germany","DE","DEU","North Rhine-Westphalia","","25318","1276414700"
"San Nicolas","San Nicolas","13.9283","120.9510","Philippines","PH","PHL","Batangas","","23908","1608000052"
"Jalpan","Jalpan","21.2167","-99.4725","Mexico","MX","MEX","Querétaro","minor","25550","1484214957"
"Delitzsch","Delitzsch","51.5264","12.3425","Germany","DE","DEU","Saxony","","25276","1276928962"
"Tougué","Tougue","11.4400","-11.6700","Guinea","GN","GIN","Labé","minor","25531","1324920258"
"Penha","Penha","-26.7689","-48.6458","Brazil","BR","BRA","Santa Catarina","","25141","1076551669"
"Camalaniugan","Camalaniugan","18.2756","121.6756","Philippines","PH","PHL","Cagayan","","25236","1608820417"
"Ilaiyānkudi","Ilaiyankudi","9.6271","78.6247","India","IN","IND","Tamil Nādu","","25233","1356621144"
"Abay","Abay","49.6311","72.8539","Kazakhstan","KZ","KAZ","","","25550","1398353856"
"Panāgar","Panagar","23.3000","79.9800","India","IN","IND","Madhya Pradesh","","25143","1356428315"
"Araira","Araira","10.4525","-66.4850","Venezuela","VE","VEN","Miranda","","25000","1862607333"
"San Miguel","San Miguel","10.0000","124.3167","Philippines","PH","PHL","Bohol","","25356","1608318281"
"Yanyan","Yanyan","24.4166","116.3271","China","CN","CHN","Guangdong","","25355","1156483148"
"Lātehār","Latehar","23.7442","84.4998","India","IN","IND","Jhārkhand","","25188","1356490044"
"Oud-Beijerland","Oud-Beijerland","51.8167","4.4000","Netherlands","NL","NLD","Zuid-Holland","minor","24301","1528064011"
"Rudolstadt","Rudolstadt","50.7169","11.3275","Germany","DE","DEU","Thuringia","","25115","1276466405"
"Mimasaka","Mimasaka","35.0086","134.1486","Japan","JP","JPN","Okayama","","25497","1392001207"
"Staßfurt","Stassfurt","51.8667","11.5667","Germany","DE","DEU","Saxony-Anhalt","","25385","1276438491"
"Idstein","Idstein","50.2206","8.2742","Germany","DE","DEU","Hesse","","25234","1276320507"
"Solano","Solano","0.6983","-75.2539","Colombia","CO","COL","Caquetá","minor","25546","1170616766"
"Zhongcun","Zhongcun","35.3615","107.9791","China","CN","CHN","Gansu","","25372","1156544277"
"Thiene","Thiene","45.7072","11.4786","Italy","IT","ITA","Veneto","","24309","1380849890"
"Baarn","Baarn","52.2167","5.2833","Netherlands","NL","NLD","Utrecht","minor","24792","1528984547"
"Timurni","Timurni","22.3712","77.2274","India","IN","IND","Madhya Pradesh","","25321","1356057503"
"Wādi","Wadi","17.0700","76.9800","India","IN","IND","Karnātaka","","25258","1356659332"
"Jaisinghpur","Jaisinghpur","26.6318","84.7855","India","IN","IND","Bihār","","24101","1356757347"
"Shrīrangapattana","Shrirangapattana","12.4140","76.7040","India","IN","IND","Karnātaka","","25061","1356689943"
"Yanaul","Yanaul","56.2833","54.9333","Russia","RU","RUS","Bashkortostan","","25511","1643998426"
"Boo","Boo","59.3167","18.2500","Sweden","SE","SWE","Stockholm","","24052","1752145853"
"Mahwah","Mahwah","41.0816","-74.1856","United States","US","USA","New Jersey","","25530","1840081806"
"Rajākheri","Rajakheri","23.8593","78.7852","India","IN","IND","Madhya Pradesh","","25091","1356394598"
"Maaseik","Maaseik","51.1019","5.7856","Belgium","BE","BEL","Flanders","minor","25201","1056459643"
"Tirorā","Tirora","21.4072","79.9326","India","IN","IND","Mahārāshtra","","25181","1356865532"
"Brilon","Brilon","51.3956","8.5678","Germany","DE","DEU","North Rhine-Westphalia","","25417","1276723318"
"Ladera Ranch","Ladera Ranch","33.5492","-117.6417","United States","US","USA","California","","25526","1840028333"
"São José do Norte","Sao Jose do Norte","-32.0150","-52.0419","Brazil","BR","BRA","Rio Grande do Sul","","25503","1076411252"
"Saidpur","Saidpur","25.5500","83.1800","India","IN","IND","Uttar Pradesh","","24338","1356124209"
"Borçka","Borcka","41.3636","41.6792","Turkey","TR","TUR","Artvin","minor","25500","1792001054"
"Demba","Demba","-5.5000","22.2667","Congo (Kinshasa)","CD","COD","Kasaï Central","","25384","1180021198"
"Rosemount","Rosemount","44.7466","-93.0661","United States","US","USA","Minnesota","","25513","1840008951"
"Iraquara","Iraquara","-12.2489","-41.6189","Brazil","BR","BRA","Bahia","","25478","1076405932"
"Avanigadda","Avanigadda","16.0197","80.9200","India","IN","IND","Andhra Pradesh","","23737","1356495520"
"Tumbao","Tumbao","7.1167","124.3833","Philippines","PH","PHL","Maguindanao","","25439","1608047878"
"Ain Aicha","Ain Aicha","34.4833","-4.7000","Morocco","MA","MAR","Fès-Meknès","","25417","1504818592"
"Mercer Island","Mercer Island","47.5661","-122.2320","United States","US","USA","Washington","","25506","1840019830"
"Talugtug","Talugtug","15.7789","120.8111","Philippines","PH","PHL","Nueva Ecija","","25236","1608248844"
"Tanabi","Tanabi","-20.6258","-49.6489","Brazil","BR","BRA","São Paulo","","25467","1076368132"
"Salaga","Salaga","8.5500","-0.5167","Ghana","GH","GHA","Savannah","","25472","1288387076"
"Bungku","Bungku","-2.5333","121.9667","Indonesia","ID","IDN","Sulawesi Tengah","minor","25477","1360661164"
"Somoniyon","Somoniyon","38.4422","68.7769","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","25200","1762902089"
"Jevargi","Jevargi","17.0139","76.7732","India","IN","IND","Karnātaka","","25316","1356918542"
"Palm River-Clair Mel","Palm River-Clair Mel","27.9239","-82.3791","United States","US","USA","Florida","","25492","1840073873"
"Skawina","Skawina","49.9833","19.8333","Poland","PL","POL","Małopolskie","","24310","1616660724"
"Korostyshiv","Korostyshiv","50.3186","29.0592","Ukraine","UA","UKR","Zhytomyrska Oblast","","25445","1804003590"
"Clayton","Clayton","35.6590","-78.4498","United States","US","USA","North Carolina","","25486","1840016290"
"Palm City","Palm City","27.1735","-80.2862","United States","US","USA","Florida","","25486","1840014192"
"Kirkby in Ashfield","Kirkby in Ashfield","53.0990","-1.2450","United Kingdom","GB","GBR","Nottinghamshire","","25265","1826272364"
"Zheleznovodsk","Zheleznovodsk","44.1394","43.0169","Russia","RU","RUS","Stavropol’skiy Kray","","24919","1643181159"
"Harbel","Harbel","6.2833","-10.3500","Liberia","LR","LBR","Margibi","","25309","1430289576"
"Dalmine","Dalmine","45.6500","9.6000","Italy","IT","ITA","Lombardy","","23495","1380355124"
"Reading","Reading","42.5351","-71.1056","United States","US","USA","Massachusetts","","25483","1840053484"
"Hermanus","Hermanus","-34.4167","19.2500","South Africa","ZA","ZAF","Western Cape","","25153","1710409701"
"Senador Pompeu","Senador Pompeu","-5.5878","-39.3719","Brazil","BR","BRA","Ceará","","25456","1076883393"
"Limonar","Limonar","22.9561","-81.4086","Cuba","CU","CUB","Matanzas","minor","25421","1192446751"
"Almonte","Almonte","37.2667","-6.5167","Spain","ES","ESP","Andalusia","","25448","1724955448"
"Caloto","Caloto","3.0333","-76.4167","Colombia","CO","COL","Cauca","minor","25416","1170219131"
"Xiaoli","Xiaoli","38.9999","115.8047","China","CN","CHN","Hebei","","25015","1156388217"
"Jilikŭl","Jilikul","37.4928","68.5286","Tajikistan","TJ","TJK","Khatlon","minor","25382","1762222147"
"Exeter","Exeter","40.3139","-75.8340","United States","US","USA","Pennsylvania","","25470","1840144085"
"Sandoná","Sandona","1.2833","-77.4667","Colombia","CO","COL","Nariño","minor","25220","1170586561"
"Elk River","Elk River","45.3314","-93.5670","United States","US","USA","Minnesota","","25467","1840007794"
"Vistahermosa","Vistahermosa","3.1239","-73.7514","Colombia","CO","COL","Meta","minor","25461","1170088623"
"Hazelwood","Hazelwood","38.7931","-90.3899","United States","US","USA","Missouri","","25466","1840007453"
"Tamboril","Tamboril","-4.8319","-40.3208","Brazil","BR","BRA","Ceará","","25451","1076937036"
"Mudgal","Mudgal","16.0119","76.4420","India","IN","IND","Karnātaka","","25233","1356762864"
"Sabanagrande","Sabanagrande","10.8000","-74.7500","Colombia","CO","COL","Atlántico","minor","24880","1170578455"
"Ban Khamen","Ban Khamen","13.8823","100.2879","Thailand","TH","THA","Nonthaburi","","24745","1764794976"
"San José","San Jose","1.6967","-78.2453","Colombia","CO","COL","Nariño","minor","25433","1170557399"
"Ulongué","Ulongue","-14.7167","34.3667","Mozambique","MZ","MOZ","Tete","","25309","1508479155"
"Samtredia","Samtredia","42.1625","42.3417","Georgia","GE","GEO","Imereti","minor","25318","1268722261"
"Leandro N. Alem","Leandro N. Alem","-27.6000","-55.3333","Argentina","AR","ARG","Misiones","minor","25404","1032046721"
"Baar","Baar","47.2000","8.5167","Switzerland","CH","CHE","Zug","","24464","1756858308"
"Markkleeberg","Markkleeberg","51.2778","12.3833","Germany","DE","DEU","Saxony","","24664","1276805845"
"Hamme","Hamme","51.0833","4.1333","Belgium","BE","BEL","Flanders","","24829","1056862264"
"Wumayingcun","Wumayingcun","38.0094","116.8032","China","CN","CHN","Hebei","","25220","1156529040"
"Inta","Inta","66.0833","60.1333","Russia","RU","RUS","Komi","","25444","1643587482"
"Halfmoon","Halfmoon","42.8640","-73.7275","United States","US","USA","New York","","25442","1840058153"
"Ruvo di Puglia","Ruvo di Puglia","41.1167","16.4833","Italy","IT","ITA","Puglia","","25328","1380946552"
"La Unión","La Union","1.6019","-77.1317","Colombia","CO","COL","Nariño","minor","25270","1170740769"
"Yamagata","Yamagata","35.5061","136.7811","Japan","JP","JPN","Gifu","","25325","1392860202"
"Warrington","Warrington","40.2489","-75.1580","United States","US","USA","Pennsylvania","","25438","1840035400"
"Suşehri","Susehri","40.1658","38.0942","Turkey","TR","TUR","Sivas","minor","25404","1792648554"
"Ozëry","Ozery","54.8500","38.5667","Russia","RU","RUS","Moskovskaya Oblast’","","25359","1643008708"
"Rānia","Rania","29.5300","74.8300","India","IN","IND","Haryāna","","25123","1356052724"
"Vangviang","Vangviang","18.9333","102.4500","Laos","LA","LAO","Viangchan","","25000","1418562192"
"Farkhor","Farkhor","37.5000","69.4000","Tajikistan","TJ","TJK","Khatlon","minor","25300","1762773904"
"Şaydnāyā","Saydnaya","33.6967","36.3739","Syria","SY","SYR","Rīf Dimashq","minor","25194","1760302528"
"Öhringen","Ohringen","49.2000","9.5000","Germany","DE","DEU","Baden-Württemberg","","25049","1276641265"
"Chiromo","Chiromo","-16.5500","35.1333","Malawi","MW","MWI","Nsanje","","25235","1454972056"
"Corrente","Corrente","-10.4428","-45.1619","Brazil","BR","BRA","Piauí","","25407","1076941506"
"Kingsville","Kingsville","27.5094","-97.8610","United States","US","USA","Texas","","25415","1840021013"
"Nagtipunan","Nagtipunan","16.2167","121.6000","Philippines","PH","PHL","Quirino","","25399","1608561096"
"Heiloo","Heiloo","52.6000","4.7167","Netherlands","NL","NLD","Noord-Holland","minor","24144","1528757776"
"Albergaria-a-Velha","Albergaria-a-Velha","40.6936","-8.4806","Portugal","PT","PRT","Aveiro","minor","25252","1620890833"
"Xenia","Xenia","39.6829","-83.9413","United States","US","USA","Ohio","","25411","1840003790"
"Acatlán","Acatlan","20.4242","-103.6014","Mexico","MX","MEX","Jalisco","minor","25250","1484516807"
"Cuijk","Cuijk","51.7296","5.8810","Netherlands","NL","NLD","Noord-Brabant","minor","24931","1528466383"
"Tapa","Tapa","30.2979","75.3694","India","IN","IND","Punjab","","24970","1356153642"
"Meckenheim","Meckenheim","50.6333","7.0167","Germany","DE","DEU","North Rhine-Westphalia","","24693","1276114705"
"Bom Jardim","Bom Jardim","-22.1519","-42.4189","Brazil","BR","BRA","Rio de Janeiro","","25333","1076904801"
"Paete","Paete","14.3667","121.4833","Philippines","PH","PHL","Laguna","","24945","1608131728"
"Keystone","Keystone","28.1312","-82.5999","United States","US","USA","Florida","","25397","1840028914"
"Areia Branca","Areia Branca","-4.9558","-37.1369","Brazil","BR","BRA","Rio Grande do Norte","","25315","1076509200"
"Galt","Galt","38.2698","-121.3004","United States","US","USA","California","","25385","1840020247"
"Lafayette","Lafayette","37.8919","-122.1189","United States","US","USA","California","","25384","1840020284"
"Ottumwa","Ottumwa","41.0195","-92.4186","United States","US","USA","Iowa","","25379","1840009339"
"Dhing","Dhing","26.4679","92.4734","India","IN","IND","Assam","","23554","1356100928"
"Formosa do Rio Preto","Formosa do Rio Preto","-11.0478","-45.1928","Brazil","BR","BRA","Bahia","","25372","1076368552"
"Kasrāwad","Kasrawad","22.1274","75.6110","India","IN","IND","Madhya Pradesh","","25146","1356487430"
"Mengdan","Mengdan","24.2752","98.4672","China","CN","CHN","Yunnan","","25299","1156438926"
"Rockaway","Rockaway","40.9602","-74.4988","United States","US","USA","New Jersey","","25370","1840000951"
"Cota","Cota","4.8167","-74.1000","Colombia","CO","COL","Cundinamarca","minor","24916","1170708586"
"Derby","Derby","37.5571","-97.2551","United States","US","USA","Kansas","","25365","1840007569"
"Kariba","Kariba","-16.5167","28.8000","Zimbabwe","ZW","ZWE","Mashonaland West","","24210","1716563536"
"Mombin Crochu","Mombin Crochu","19.3667","-71.9833","Haiti","HT","HTI","Nord-Est","","25113","1332692300"
"Silver Springs Shores","Silver Springs Shores","29.1126","-82.0149","United States","US","USA","Florida","","25363","1840028949"
"Monreal","Monreal","12.6440","123.6648","Philippines","PH","PHL","Masbate","","25164","1608903492"
"Banga","Banga","31.1887","75.9949","India","IN","IND","Punjab","","24827","1356028584"
"Staunton","Staunton","38.1593","-79.0611","United States","US","USA","Virginia","","25358","1840001670"
"Magog","Magog","45.2667","-72.1500","Canada","CA","CAN","Quebec","","25358","1124404849"
"Ula","Ula","37.1036","28.4147","Turkey","TR","TUR","Muğla","minor","25294","1792863593"
"Florence","Florence","33.0590","-111.4209","United States","US","USA","Arizona","","25356","1840021999"
"Kola","Kola","22.4300","87.8700","India","IN","IND","West Bengal","","25191","1356776203"
"Pitangui","Pitangui","-19.6828","-44.8900","Brazil","BR","BRA","Minas Gerais","","25311","1076766029"
"Pallappatti","Pallappatti","10.7198","77.8915","India","IN","IND","Tamil Nādu","","25131","1356278919"
"Mednogorsk","Mednogorsk","51.4222","57.5953","Russia","RU","RUS","Orenburgskaya Oblast’","","25272","1643650403"
"Workington","Workington","54.6365","-3.5549","United Kingdom","GB","GBR","Cumbria","","25207","1826107196"
"Chocontá","Choconta","5.1467","-73.6825","Colombia","CO","COL","Cundinamarca","minor","25257","1170690374"
"Moscow","Moscow","46.7308","-116.9986","United States","US","USA","Idaho","","25336","1840019868"
"Madūru","Maduru","14.7000","78.7800","India","IN","IND","Andhra Pradesh","","24843","1356897345"
"Westerlo","Westerlo","51.0833","4.9167","Belgium","BE","BEL","Flanders","","24884","1056672480"
"Salem","Salem","37.2864","-80.0555","United States","US","USA","Virginia","","25335","1840001719"
"Salamanca","Salamanca","-31.7667","-70.9667","Chile","CL","CHL","Coquimbo","","25326","1152804087"
"Camillus","Camillus","43.0539","-76.3056","United States","US","USA","New York","","25333","1840004331"
"Kovancılar","Kovancilar","38.7217","39.8672","Turkey","TR","TUR","Elazığ","minor","25295","1792035505"
"Moron","Moron","18.5600","-74.2579","Haiti","HT","HTI","Grand’Anse","","24713","1332216244"
"Onda","Onda","39.9625","-0.2639","Spain","ES","ESP","Valencia","","25099","1724421220"
"Paoay","Paoay","18.0625","120.5203","Philippines","PH","PHL","Ilocos Norte","","25001","1608717163"
"Pinal de Amoles","Pinal de Amoles","21.1342","-99.4586","Mexico","MX","MEX","Querétaro","minor","25325","1484630305"
"Requena","Requena","-5.0569","-73.8515","Peru","PE","PER","Loreto","","25313","1604712979"
"Fairland","Fairland","39.0803","-76.9527","United States","US","USA","Maryland","","25324","1840001593"
"Newport","Newport","41.4801","-71.3203","United States","US","USA","Rhode Island","","25322","1840003355"
"Khalāri","Khalari","23.6506","85.0074","India","IN","IND","Jhārkhand","","24793","1356046558"
"Hudson","Hudson","42.7639","-71.4072","United States","US","USA","New Hampshire","","25314","1840055034"
"Burē","Bure","10.7000","37.0667","Ethiopia","ET","ETH","Āmara","","25084","1231106866"
"Kochubeyevskoye","Kochubeyevskoye","44.6706","41.8380","Russia","RU","RUS","Stavropol’skiy Kray","","25279","1643495941"
"Anajatuba","Anajatuba","-3.2639","-44.6200","Brazil","BR","BRA","Maranhão","","25291","1076287191"
"Bad Oldesloe","Bad Oldesloe","53.8117","10.3742","Germany","DE","DEU","Schleswig-Holstein","minor","24841","1276683854"
"Lengir","Lengir","42.1819","69.8878","Kazakhstan","KZ","KAZ","","","25298","1398031838"
"Barberton","Barberton","41.0095","-81.6037","United States","US","USA","Ohio","","25309","1840000792"
"Dedham","Dedham","42.2467","-71.1777","United States","US","USA","Massachusetts","","25308","1840053547"
"Merelbeke","Merelbeke","51.0000","3.7500","Belgium","BE","BEL","Flanders","","24634","1056415717"
"Khed Brahma","Khed Brahma","24.0299","73.0463","India","IN","IND","Gujarāt","","25001","1356775137"
"Siswa","Siswa","26.7027","84.9133","India","IN","IND","Bihār","","24039","1356948136"
"Dhāriwāl","Dhariwal","31.9561","75.3239","India","IN","IND","Punjab","","24697","1356409245"
"Norden","Norden","53.5967","7.2056","Germany","DE","DEU","Lower Saxony","","25060","1276766299"
"Poblacion","Poblacion","10.1614","125.1303","Philippines","PH","PHL","Southern Leyte","","24800","1608122231"
"Salzkotten","Salzkotten","51.6708","8.6047","Germany","DE","DEU","North Rhine-Westphalia","","25062","1276941056"
"Laon","Laon","49.5639","3.6244","France","FR","FRA","Hauts-de-France","minor","24710","1250552164"
"Caledonia","Caledonia","42.7986","-87.8762","United States","US","USA","Wisconsin","","25288","1840010091"
"Kalayaan","Kalayaan","14.3280","121.4800","Philippines","PH","PHL","Laguna","","24755","1608777170"
"Petershagen","Petershagen","52.3833","8.9667","Germany","DE","DEU","North Rhine-Westphalia","","25168","1276300392"
"Mejorada del Campo","Mejorada del Campo","40.3967","-3.3858","Spain","ES","ESP","Madrid","","23897","1724293748"
"Pooler","Pooler","32.1043","-81.2569","United States","US","USA","Georgia","","25284","1840014914"
"Flores Costa Cuca","Flores Costa Cuca","14.6500","-91.8167","Guatemala","GT","GTM","Quetzaltenango","minor","24888","1320176965"
"Guéné","Guene","11.7306","3.2242","Benin","BJ","BEN","Atacora","","25206","1204326206"
"Elói Mendes","Eloi Mendes","-21.6100","-45.5650","Brazil","BR","BRA","Minas Gerais","","25220","1076184209"
"Drodro","Drodro","1.7667","30.5333","Congo (Kinshasa)","CD","COD","Ituri","","25000","1180800758"
"Cajuru","Cajuru","-21.2753","-47.3042","Brazil","BR","BRA","São Paulo","","25230","1076846644"
"Sprockhövel","Sprockhovel","51.3667","7.2500","Germany","DE","DEU","North Rhine-Westphalia","","24747","1276006803"
"El Ghiate","El Ghiate","32.0331","-9.1625","Morocco","MA","MAR","Marrakech-Safi","","25162","1504222954"
"Xiaolongtan","Xiaolongtan","23.8099","103.2030","China","CN","CHN","Yunnan","","24986","1156737938"
"Nijverdal","Nijverdal","52.3667","6.4667","Netherlands","NL","NLD","Overijssel","minor","25000","1528550649"
"San Felipe","San Felipe","15.0619","120.0700","Philippines","PH","PHL","Zambales","","25033","1608994010"
"Safsaf","Safsaf","34.5581","-6.0078","Morocco","MA","MAR","Rabat-Salé-Kénitra","","25075","1504872939"
"Barra dos Coqueiros","Barra dos Coqueiros","-10.9089","-37.0389","Brazil","BR","BRA","Sergipe","","24976","1076773193"
"Catembe","Catembe","-26.0050","32.5597","Mozambique","MZ","MOZ","Maputo","","25000","1508571099"
"Cave Spring","Cave Spring","37.2254","-80.0072","United States","US","USA","Virginia","","25248","1840006440"
"Hastings","Hastings","40.5961","-98.3900","United States","US","USA","Nebraska","","25247","1840001130"
"Tiruvattār","Tiruvattar","8.3307","77.2673","India","IN","IND","Tamil Nādu","","24119","1356205250"
"Kościerzyna","Koscierzyna","54.1167","17.9833","Poland","PL","POL","Pomorskie","minor","23744","1616544683"
"Pezinok","Pezinok","48.2919","17.2661","Slovakia","SK","SVK","Bratislava","minor","24900","1703456673"
"Muret","Muret","43.4611","1.3267","France","FR","FRA","Occitanie","minor","24813","1250231707"
"Aridagawa","Aridagawa","34.0575","135.2162","Japan","JP","JPN","Wakayama","","25169","1392003510"
"Sastamala","Sastamala","61.3417","22.9083","Finland","FI","FIN","Pirkanmaa","minor","25220","1246526086"
"Apiaí","Apiai","-24.5097","-48.8428","Brazil","BR","BRA","São Paulo","","25211","1076258648"
"Edwardsville","Edwardsville","38.7922","-89.9874","United States","US","USA","Illinois","","25236","1840008564"
"Nagarpāra","Nagarpara","25.4155","86.8832","India","IN","IND","Bihār","","24354","1356562948"
"Zittau","Zittau","50.8961","14.8072","Germany","DE","DEU","Saxony","","24858","1276061456"
"Jaleshwar","Jaleshwar","26.6500","85.8000","Nepal","NP","NPL","","","23533","1524945108"
"Solin","Solin","43.5317","16.4947","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","23926","1191452143"
"Pagudpud","Pagudpud","18.5614","120.7878","Philippines","PH","PHL","Ilocos Norte","","25098","1608369823"
"Genzano di Roma","Genzano di Roma","41.7022","12.6925","Italy","IT","ITA","Lazio","","23892","1380051338"
"Quirino","Quirino","17.1356","121.7350","Philippines","PH","PHL","Isabela","","25023","1608140160"
"De Pere","De Pere","44.4308","-88.0785","United States","US","USA","Wisconsin","","25216","1840002348"
"Seal Beach","Seal Beach","33.7542","-118.0714","United States","US","USA","California","","25216","1840021965"
"Shaqlāwah","Shaqlawah","36.4056","44.3208","Iraq","IQ","IRQ","Arbīl","minor","25000","1368884610"
"Morozovsk","Morozovsk","48.3667","41.8333","Russia","RU","RUS","Rostovskaya Oblast’","minor","25198","1643038040"
"Cambre","Cambre","43.2830","-8.3330","Spain","ES","ESP","Galicia","","24616","1724885486"
"Athens","Athens","34.7843","-86.9503","United States","US","USA","Alabama","","25213","1840000935"
"Tōno","Tono","39.3279","141.5334","Japan","JP","JPN","Iwate","","25181","1392078638"
"Ghoti Budrukh","Ghoti Budrukh","19.7167","73.6333","India","IN","IND","Mahārāshtra","","24838","1356727216"
"Jenks","Jenks","35.9981","-95.9736","United States","US","USA","Oklahoma","","25210","1840019060"
"Sahāwar","Sahawar","27.8000","78.8500","India","IN","IND","Uttar Pradesh","","24457","1356246628"
"Steenbergen","Steenbergen","51.5833","4.2500","Netherlands","NL","NLD","Noord-Brabant","minor","25054","1528925300"
"Göle","Gole","40.7928","42.6086","Turkey","TR","TUR","Ardahan","minor","25187","1792802514"
"Portogruaro","Portogruaro","45.7833","12.8333","Italy","IT","ITA","Veneto","","24959","1380545268"
"Sezze","Sezze","41.5000","13.0667","Italy","IT","ITA","Lazio","","24954","1380311446"
"Piranhas","Piranhas","-9.6239","-37.7569","Brazil","BR","BRA","Alagoas","","25183","1076219170"
"Bayonet Point","Bayonet Point","28.3254","-82.6834","United States","US","USA","Florida","","25200","1840014117"
"Otuzco","Otuzco","-7.9000","-78.5833","Peru","PE","PER","La Libertad","","25134","1604161129"
"Reserva","Reserva","-24.6500","-50.8508","Brazil","BR","BRA","Paraná","","25172","1076052177"
"Great Sankey","Great Sankey","53.3918","-2.6383","United Kingdom","GB","GBR","Warrington","","24211","1826102019"
"Aş Şaqlāwīyah","As Saqlawiyah","33.3964","43.6833","Iraq","IQ","IRQ","Al Anbār","","25000","1368898863"
"Santa María Tonameca","Santa Maria Tonameca","15.7458","-96.5472","Mexico","MX","MEX","Oaxaca","minor","25130","1484998049"
"Mayāng Imphāl","Mayang Imphal","24.6000","93.9000","India","IN","IND","Manipur","","24239","1356895176"
"Umga","Umga","24.6396","84.5577","India","IN","IND","Bihār","","24261","1356143590"
"Raahe","Raahe","64.6833","24.4667","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","25165","1246712185"
"Ban Pa Sak","Ban Pa Sak","16.8477","100.2563","Thailand","TH","THA","Phitsanulok","","24902","1764248083"
"Belvidere","Belvidere","42.2543","-88.8649","United States","US","USA","Illinois","","25179","1840006971"
"Okemos","Okemos","42.7057","-84.4135","United States","US","USA","Michigan","","25179","1840004502"
"Sikandarpur","Sikandarpur","26.0333","84.0500","India","IN","IND","Uttar Pradesh","","23986","1356747004"
"Minowa","Minowa","35.9150","137.9819","Japan","JP","JPN","Nagano","","24887","1392003517"
"San Francisco Zapotitlán","San Francisco Zapotitlan","14.5833","-91.5167","Guatemala","GT","GTM","Suchitepéquez","minor","24763","1320950165"
"Valls","Valls","41.2883","1.2508","Spain","ES","ESP","Catalonia","","24727","1724511688"
"Nedre Eiker","Nedre Eiker","59.7647","10.0333","Norway","NO","NOR","Viken","","24963","1578585975"
"Watertown","Watertown","43.9734","-75.9094","United States","US","USA","New York","","25172","1840002200"
"Purwā","Purwa","26.4700","80.7800","India","IN","IND","Uttar Pradesh","","24467","1356463206"
"Reedley","Reedley","36.5988","-119.4471","United States","US","USA","California","","25168","1840020320"
"Gyál","Gyal","47.3861","19.2192","Hungary","HU","HUN","Pest","minor","24193","1348670361"
"Icatu","Icatu","-2.7758","-44.0658","Brazil","BR","BRA","Maranhão","","25145","1076831989"
"Saiha","Saiha","22.4800","92.9700","India","IN","IND","Mizoram","","25110","1356131507"
"Bardsīr","Bardsir","29.9275","56.5722","Iran","IR","IRN","Kermān","minor","25152","1364465140"
"Aklvidu","Aklvidu","16.6000","81.3833","India","IN","IND","Andhra Pradesh","","24506","1356049380"
"Aimorés","Aimores","-19.4958","-41.0639","Brazil","BR","BRA","Minas Gerais","","25141","1076798379"
"Atkarsk","Atkarsk","51.8667","45.0000","Russia","RU","RUS","Saratovskaya Oblast’","","25140","1643598170"
"Rangāpāra","Rangapara","26.8377","92.6688","India","IN","IND","Assam","","24847","1356822563"
"Boussé","Bousse","12.6667","-1.8975","Burkina Faso","BF","BFA","Plateau-Central","minor","25022","1854235785"
"Pālakodu","Palakodu","12.3037","78.0730","India","IN","IND","Tamil Nādu","","24640","1356661099"
"Sovetskaya Gavan’","Sovetskaya Gavan'","48.9667","140.2833","Russia","RU","RUS","Khabarovskiy Kray","","25147","1643037289"
"Walker","Walker","42.9853","-85.7446","United States","US","USA","Michigan","","25149","1840002925"
"Novi Travnik","Novi Travnik","44.1748","17.6634","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","25107","1070493406"
"Villa Tapia","Villa Tapia","19.3000","-70.4200","Dominican Republic","DO","DOM","Cibao Nordeste","","24871","1214090755"
"Ninohe","Ninohe","40.2713","141.3048","Japan","JP","JPN","Iwate","","25083","1392003135"
"Ereymentaū","Ereymentau","51.6167","73.1000","Kazakhstan","KZ","KAZ","","minor","25141","1398103059"
"Sohāgpur","Sohagpur","22.7000","78.2000","India","IN","IND","Madhya Pradesh","","25040","1356364830"
"Tarpon Springs","Tarpon Springs","28.1493","-82.7623","United States","US","USA","Florida","","25138","1840015980"
"Sho‘rchi","Sho\`rchi","38.0111","67.7875","Uzbekistan","UZ","UZB","Surxondaryo","","25000","1860901035"
"Saltpond","Saltpond","5.2000","-1.0667","Ghana","GH","GHA","Central","","24689","1288282866"
"Wolfsberg","Wolfsberg","46.8419","14.8408","Austria","AT","AUT","Kärnten","minor","25035","1040360550"
"Āshkhāneh","Ashkhaneh","37.5614","56.9211","Iran","IR","IRN","Khorāsān-e Shomālī","minor","25104","1364197462"
"Barstow","Barstow","34.8661","-117.0471","United States","US","USA","California","","25123","1840019118"
"Tulsīpur","Tulsipur","27.5500","82.4200","India","IN","IND","Uttar Pradesh","","24488","1356203063"
"Calpe","Calpe","38.6450","0.0442","Spain","ES","ESP","Valencia","","24096","1724877295"
"Saky","Saky","45.1336","33.5772","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","24282","1804485367"
"Le Ray","Le Ray","44.0771","-75.7975","United States","US","USA","New York","","25118","1840087600"
"Franklin","Franklin","39.4948","-86.0544","United States","US","USA","Indiana","","25112","1840008460"
"Sahjanwa","Sahjanwa","26.7500","83.2167","India","IN","IND","Uttar Pradesh","","25091","1356149492"
"São João da Ponte","Sao Joao da Ponte","-15.9289","-44.0078","Brazil","BR","BRA","Minas Gerais","","25098","1076581475"
"Newberg","Newberg","45.3075","-122.9601","United States","US","USA","Oregon","","25108","1840019957"
"Bystrc","Bystrc","49.2247","16.5239","Czechia","CZ","CZE","Jihomoravský Kraj","","24218","1203641060"
"Dhāmnagar","Dhamnagar","20.9141","86.4468","India","IN","IND","Odisha","","24486","1356990724"
"Wil","Wil","47.4667","9.0500","Switzerland","CH","CHE","Sankt Gallen","minor","23955","1756263917"
"Sand","Sand","59.1343","10.2244","Norway","NO","NOR","Vestfold og Telemark","","24898","1578001129"
"Tirumuruganpūndi","Tirumuruganpundi","11.1649","77.3084","India","IN","IND","Tamil Nādu","","24495","1356163710"
"Dalupo","Dalupo","19.3908","110.4662","China","CN","CHN","Hainan","","24923","1156159705"
"Ādīs Zemen","Adis Zemen","12.1167","37.7833","Ethiopia","ET","ETH","Āmara","","24849","1231619970"
"Santo Tomás","Santo Tomas","-14.4510","-72.0820","Peru","PE","PER","Cusco","","25087","1604893836"
"Uauá","Uaua","-9.8419","-39.4819","Brazil","BR","BRA","Bahia","","25087","1076204495"
"Dbarwa","Dbarwa","15.1000","38.8333","Eritrea","ER","ERI","Debub","","25000","1232218993"
"Montalbán","Montalban","10.2522","-68.3019","Venezuela","VE","VEN","Carabobo","minor","24908","1862131171"
"Kabanga","Kabanga","-2.9022","30.4986","Tanzania","TZ","TZA","Kigoma","","24979","1834808900"
"Matriz de Camarajibe","Matriz de Camarajibe","-9.1519","-35.5328","Brazil","BR","BRA","Alagoas","","25012","1076079879"
"Pallapatti","Pallapatti","9.4664","77.8219","India","IN","IND","Tamil Nādu","","24319","1356214211"
"Preah Vihear","Preah Vihear","13.8167","104.9667","Cambodia","KH","KHM","Preah Vihear","","24360","1116000285"
"Vicuña","Vicuna","-30.0333","-70.7167","Chile","CL","CHL","Coquimbo","","25085","1152771899"
"Krems an der Donau","Krems an der Donau","48.4167","15.6167","Austria","AT","AUT","Niederösterreich","minor","24610","1040577668"
"Kamo","Kamo","37.6663","139.0402","Japan","JP","JPN","Niigata","","25086","1392480203"
"Whitehorse","Whitehorse","60.7029","-135.0691","Canada","CA","CAN","Yukon","admin","25085.00","1124348186"
"Nittedal","Nittedal","60.0731","10.8722","Norway","NO","NOR","Viken","minor","24947","1578894489"
"Pitsea","Pitsea","51.5690","0.5040","United Kingdom","GB","GBR","Essex","","25000","1826099748"
"Antsirabe Avaratra","Antsirabe Avaratra","-13.9667","49.9667","Madagascar","MG","MDG","Antsiranana","","25000","1450349546"
"Tateyama","Tateyama","36.6636","137.3137","Japan","JP","JPN","Toyama","","24996","1392003449"
"Dimasalang","Dimasalang","12.1933","123.8583","Philippines","PH","PHL","Masbate","","24909","1608843461"
"Fidirana","Fidirana","-19.5000","46.5667","Madagascar","MG","MDG","Antananarivo","","25000","1450335762"
"Mundargi","Mundargi","15.2070","75.8840","India","IN","IND","Karnātaka","","24919","1356700482"
"Jitotol","Jitotol","17.0833","-92.8833","Mexico","MX","MEX","Chiapas","minor","24966","1484831989"
"Ma‘bar","Ma\`bar","14.7940","44.2935","Yemen","YE","YEM","Dhamār","minor","24707","1887814519"
"Waldkraiburg","Waldkraiburg","48.2167","12.4000","Germany","DE","DEU","Bavaria","","23957","1276784836"
"Sandusky","Sandusky","41.4468","-82.7024","United States","US","USA","Ohio","","25066","1840003433"
"Ternate","Ternate","14.2833","120.7167","Philippines","PH","PHL","Cavite","","24653","1608912964"
"Narasingapuram","Narasingapuram","11.6038","78.5778","India","IN","IND","Tamil Nādu","","24670","1356244145"
"Arnold","Arnold","39.0437","-76.4974","United States","US","USA","Maryland","","25056","1840005907"
"Wujiaying","Wujiaying","33.1871","107.0480","China","CN","CHN","Shaanxi","","24861","1156245757"
"Nainpur","Nainpur","22.4300","80.1200","India","IN","IND","Madhya Pradesh","","24914","1356078158"
"Ridgefield","Ridgefield","41.3065","-73.5023","United States","US","USA","Connecticut","","25053","1840035559"
"Piritiba","Piritiba","-11.7300","-40.5550","Brazil","BR","BRA","Bahia","","25027","1076244219"
"Iki","Iki","33.7497","129.6914","Japan","JP","JPN","Nagasaki","","24873","1392003437"
"General Luna","General Luna","13.6881","122.1708","Philippines","PH","PHL","Quezon","","24804","1608376950"
"Espelkamp","Espelkamp","52.3772","8.6328","Germany","DE","DEU","North Rhine-Westphalia","","24754","1276996942"
"Białogard","Bialogard","54.0000","15.9833","Poland","PL","POL","Zachodniopomorskie","minor","24146","1616159820"
"Quisqueya","Quisqueya","18.5542","-69.4056","Dominican Republic","DO","DOM","Higuamo","","24885","1214538768"
"Rădăuţi","Radauti","47.8425","25.9192","Romania","RO","ROU","Suceava","","24292","1642696175"
"Yuzhang","Yuzhang","25.3561","105.1020","China","CN","CHN","Guizhou","","24934","1156023423"
"Slavutych","Slavutych","51.5206","30.7569","Ukraine","UA","UKR","Kyivska Oblast","","25030","1804529063"
"Bloomingdale","Bloomingdale","27.8784","-82.2624","United States","US","USA","Florida","","25042","1840014150"
"Turnu Măgurele","Turnu Magurele","43.7517","24.8708","Romania","RO","ROU","Teleorman","","24772","1642918101"
"Norton Shores","Norton Shores","43.1621","-86.2519","United States","US","USA","Michigan","","25040","1840002873"
"Rāmshīr","Ramshir","30.8947","49.4092","Iran","IR","IRN","Khūzestān","minor","25009","1364250700"
"Zhuangwei","Zhuangwei","24.7702","121.8023","Taiwan","TW","TWN","Yilan","","24402","1158013111"
"Żagań","Zagan","51.6167","15.3167","Poland","PL","POL","Lubuskie","minor","24424","1616067846"
"Dainyor","Dainyor","35.9194","74.3889","Pakistan","PK","PAK","Gilgit-Baltistan","","25000","1586862411"
"Sultepec","Sultepec","18.8667","-99.9500","Mexico","MX","MEX","México","minor","24986","1484381977"
"Uvarovo","Uvarovo","51.9833","42.2667","Russia","RU","RUS","Tambovskaya Oblast’","","23987","1643354757"
"Ampahana","Ampahana","-14.7500","50.2167","Madagascar","MG","MDG","Antsiranana","","25000","1450937375"
"Tirumala","Tirumala","13.6833","79.3500","India","IN","IND","Andhra Pradesh","","23778","1356345138"
"Trussville","Trussville","33.6405","-86.5815","United States","US","USA","Alabama","","25027","1840015672"
"Calliaqua","Calliaqua","13.1308","-61.1928","Saint Vincent and the Grenadines","VC","VCT","Saint George","","24205","1670040822"
"Vila do Conde","Vila do Conde","-7.2600","-34.9078","Brazil","BR","BRA","Paraíba","","25010","1076678740"
"Baroy","Baroy","8.0333","123.7833","Philippines","PH","PHL","Lanao del Norte","","24683","1608036111"
"Malanguan","Malanguan","40.1873","117.6956","China","CN","CHN","Hebei","","24569","1156614706"
"Lüdinghausen","Ludinghausen","51.7667","7.4333","Germany","DE","DEU","North Rhine-Westphalia","","24847","1276526583"
"Santiago de Chuco","Santiago de Chuco","-8.1502","-78.1783","Peru","PE","PER","La Libertad","","25000","1604882492"
"Ehden","Ehden","34.2919","35.9546","Lebanon","LB","LBN","Liban-Nord","","25000","1422592801"
"Mahatalaky","Mahatalaky","-24.7833","47.0833","Madagascar","MG","MDG","Toliara","","25000","1450981094"
"Balete","Balete","14.0167","121.1000","Philippines","PH","PHL","Batangas","","24055","1608299609"
"San Enrique","San Enrique","10.4167","122.8500","Philippines","PH","PHL","Negros Occidental","","24177","1608409119"
"Kobo","Kobo","12.1500","39.6333","Ethiopia","ET","ETH","Āmara","","24867","1231442396"
"West Milford","West Milford","41.1060","-74.3914","United States","US","USA","New Jersey","","25014","1840081809"
"Ankazomborona","Ankazomborona","-16.1167","46.7500","Madagascar","MG","MDG","Mahajanga","","25000","1450164210"
"Zwevegem","Zwevegem","50.8000","3.3333","Belgium","BE","BEL","Flanders","","24619","1056326453"
"Villarrobledo","Villarrobledo","39.2667","-2.6000","Spain","ES","ESP","Castille-La Mancha","","24978","1724704168"
"Woolwich","Woolwich","43.5667","-80.4833","Canada","CA","CAN","Ontario","","25006","1124000096"
"Caballococha","Caballococha","-3.9058","-70.5164","Peru","PE","PER","Loreto","","25000","1604340559"
"Avon Lake","Avon Lake","41.4944","-82.0159","United States","US","USA","Ohio","","25005","1840000642"
"Edgewood","Edgewood","39.4190","-76.2964","United States","US","USA","Maryland","","25004","1840005661"
"Morton Grove","Morton Grove","42.0423","-87.7890","United States","US","USA","Illinois","","25004","1840011299"
"Chandili","Chandili","19.2467","83.4058","India","IN","IND","Odisha","","24664","1356355786"
"Solsona","Solsona","18.0961","120.7725","Philippines","PH","PHL","Ilocos Norte","","24851","1608585456"
"Übach-Palenberg","Ubach-Palenberg","50.9197","6.1194","Germany","DE","DEU","North Rhine-Westphalia","","24081","1276712019"
"Valparaíso","Valparaiso","-21.2278","-50.8678","Brazil","BR","BRA","São Paulo","","24993","1076354357"
"Bay Point","Bay Point","38.0329","-121.9615","United States","US","USA","California","","24999","1840028316"
"Warstein","Warstein","51.4500","8.3500","Germany","DE","DEU","North Rhine-Westphalia","","24842","1276854914"
"Magenta","Magenta","45.4603","8.8766","Italy","IT","ITA","Lombardy","","23906","1380833063"
"Wyandotte","Wyandotte","42.2113","-83.1558","United States","US","USA","Michigan","","24992","1840001849"
"Muskego","Muskego","42.8860","-88.1291","United States","US","USA","Wisconsin","","24991","1840003023"
"Santa Gertrudes","Santa Gertrudes","-22.4569","-47.5300","Brazil","BR","BRA","São Paulo","","24737","1076580877"
"Mogalturru","Mogalturru","16.4167","81.6000","India","IN","IND","Andhra Pradesh","","24189","1356138893"
"Ronnenberg","Ronnenberg","52.3194","9.6556","Germany","DE","DEU","Lower Saxony","","24347","1276210070"
"Voorst","Voorst","52.2333","6.0833","Netherlands","NL","NLD","Gelderland","","24790","1528065793"
"Caransebeş","Caransebes","45.4214","22.2219","Romania","RO","ROU","Caraş-Severin","","24689","1642355893"
"Mātābhānga","Matabhanga","26.3420","89.2155","India","IN","IND","West Bengal","","23890","1356999276"
"Madaoua","Madaoua","14.0762","5.9586","Niger","NE","NER","Tahoua","minor","24804","1562237869"
"Piat","Piat","17.7919","121.4769","Philippines","PH","PHL","Cagayan","","24805","1608280728"
"Tsaratanana","Tsaratanana","-16.7972","47.6528","Madagascar","MG","MDG","Mahajanga","","24971","1450412492"
"Romulus","Romulus","42.2237","-83.3660","United States","US","USA","Michigan","","24977","1840003963"
"Olpe","Olpe","51.0167","7.8333","Germany","DE","DEU","North Rhine-Westphalia","minor","24688","1276501027"
"Shivganj","Shivganj","25.1500","73.0700","India","IN","IND","Rājasthān","","24785","1356629626"
"Echemmaia Est","Echemmaia Est","32.0786","-8.6325","Morocco","MA","MAR","Marrakech-Safi","","24303","1504437117"
"Balarāmpur","Balarampur","23.0972","86.2231","India","IN","IND","West Bengal","","24431","1356300676"
"Norfolk","Norfolk","42.0324","-97.4217","United States","US","USA","Nebraska","","24964","1840008136"
"Ajnāla","Ajnala","31.8400","74.7600","India","IN","IND","Punjab","","24537","1356432062"
"Easton","Easton","42.0362","-71.1103","United States","US","USA","Massachusetts","","24962","1840053660"
"Mulakumūd","Mulakumud","8.2681","77.2860","India","IN","IND","Tamil Nādu","","23835","1356207389"
"Ponneri","Ponneri","13.3200","80.2000","India","IN","IND","Tamil Nādu","","24205","1356521272"
"Sandomierz","Sandomierz","50.6833","21.7500","Poland","PL","POL","Świętokrzyskie","minor","24187","1616893663"
"Taquaritinga do Norte","Taquaritinga do Norte","-7.9001","-36.0502","Brazil","BR","BRA","Pernambuco","","24903","1076267864"
"Rishton","Rishton","40.3567","71.2847","Uzbekistan","UZ","UZB","Farg‘ona","minor","23706","1860062985"
"Meiningen","Meiningen","50.5500","10.4167","Germany","DE","DEU","Thuringia","minor","24538","1276941916"
"Schmallenberg","Schmallenberg","51.1490","8.2845","Germany","DE","DEU","North Rhine-Westphalia","","24869","1276002699"
"Dodola","Dodola","6.9833","39.1833","Ethiopia","ET","ETH","Oromīya","","24767","1231390840"
"Patnāgarh","Patnagarh","20.7083","83.1326","India","IN","IND","Odisha","","24676","1356712742"
"São João dos Patos","Sao Joao dos Patos","-6.4950","-43.7019","Brazil","BR","BRA","Maranhão","","24928","1076415753"
"Jabonga","Jabonga","9.3431","125.5156","Philippines","PH","PHL","Agusan del Norte","","24855","1608225880"
"Tugaya","Tugaya","7.8840","124.1720","Philippines","PH","PHL","Lanao del Sur","","24778","1608559480"
"Shitan","Shitan","22.4546","112.5832","China","CN","CHN","Guangdong","","24667","1156422347"
"Trebišov","Trebisov","48.6278","21.7172","Slovakia","SK","SVK","Košice","minor","24587","1703917090"
"Santo Tomas","Santo Tomas","17.4000","121.7667","Philippines","PH","PHL","Isabela","","24528","1608577163"
"Newton Abbot","Newton Abbot","50.5290","-3.6100","United Kingdom","GB","GBR","Devon","","23580","1826126497"
"Pāta Kalidindi","Pata Kalidindi","16.5014","81.2908","India","IN","IND","Andhra Pradesh","","24600","1356028407"
"Pamplona","Pamplona","18.4661","121.3425","Philippines","PH","PHL","Cagayan","","24781","1608804149"
"Northdale","Northdale","28.1058","-82.5263","United States","US","USA","Florida","","24924","1840029107"
"Qabāţīyah","Qabatiyah","32.4097","35.2808","West Bank","XW","XWB","","","24439","1934173704"
"Omurtag","Omurtag","43.1000","26.4170","Bulgaria","BG","BGR","Targovishte","","24876","1100244508"
"Marysville","Marysville","40.2279","-83.3595","United States","US","USA","Ohio","","24922","1840008355"
"Harstad","Harstad","68.8011","16.5441","Norway","NO","NOR","Troms og Finnmark","minor","24804","1578844884"
"Francisco Sá","Francisco Sa","-16.4102","-43.4950","Brazil","BR","BRA","Minas Gerais","","24912","1076078241"
"Nemuro","Nemuro","43.3301","145.5829","Japan","JP","JPN","Hokkaidō","","24858","1392003428"
"Marín","Marin","42.3933","-8.7000","Spain","ES","ESP","Galicia","","24248","1724759253"
"Calahorra","Calahorra","42.3000","-1.9667","Spain","ES","ESP","La Rioja","","24654","1724553946"
"El Milagro","El Milagro","-8.0284","-79.0672","Peru","PE","PER","La Libertad","","24625","1604842741"
"Broadstairs","Broadstairs","51.3589","1.4394","United Kingdom","GB","GBR","Kent","","24903","1826167465"
"Ouaoula","Ouaoula","31.8667","-6.7500","Morocco","MA","MAR","Béni Mellal-Khénifra","","24790","1504340462"
"Kungsbacka","Kungsbacka","57.4833","12.0667","Sweden","SE","SWE","Halland","minor","23000","1752375831"
"Sahasoa","Sahasoa","-21.9833","45.9500","Madagascar","MG","MDG","Fianarantsoa","","24877","1450687379"
"Litoměřice","Litomerice","50.5342","14.1328","Czechia","CZ","CZE","Ústecký Kraj","","23623","1203149283"
"Sharya","Sharya","58.3667","45.5000","Russia","RU","RUS","Kostromskaya Oblast’","","23976","1643446883"
"Rawtenstall","Rawtenstall","53.6990","-2.2910","United Kingdom","GB","GBR","Lancashire","","23000","1826204769"
"Üshtöbe","Ushtobe","45.2422","77.9822","Kazakhstan","KZ","KAZ","","","24895","1398940566"
"Ottaviano","Ottaviano","40.8500","14.4775","Italy","IT","ITA","Campania","","23710","1380556398"
"Douar Olad. Salem","Douar Olad. Salem","32.8739","-8.8588","Morocco","MA","MAR","Casablanca-Settat","","24775","1504813232"
"Yarmouth","Yarmouth","41.6756","-70.2242","United States","US","USA","Massachusetts","","24890","1840053508"
"Sinj","Sinj","43.7000","16.6333","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","24826","1191337250"
"Verukulambu","Verukulambu","8.2953","77.2939","India","IN","IND","Tamil Nādu","","23763","1356207396"
"Liangwu","Liangwu","23.6012","111.8238","China","CN","CHN","Guangdong","","24838","1156119931"
"Brandon","Brandon","32.2778","-89.9896","United States","US","USA","Mississippi","","24887","1840013825"
"Pavlovsk","Pavlovsk","50.4578","40.1081","Russia","RU","RUS","Voronezhskaya Oblast’","minor","24858","1643562859"
"Aso","Aso","32.9483","131.1239","Japan","JP","JPN","Kumamoto","","24820","1392003066"
"Shamgarh","Shamgarh","24.1800","75.6300","India","IN","IND","Madhya Pradesh","","24637","1356586819"
"Anivorano Avaratra","Anivorano Avaratra","-12.7333","49.2333","Madagascar","MG","MDG","Antsiranana","","24838","1450739750"
"Stutterheim","Stutterheim","-32.5667","27.4167","South Africa","ZA","ZAF","Eastern Cape","","24673","1710990071"
"Vinnamāla","Vinnamala","13.9074","79.9086","India","IN","IND","Andhra Pradesh","","24446","1356425707"
"Venice","Venice","27.1184","-82.4137","United States","US","USA","Florida","","24876","1840015989"
"Araquari","Araquari","-26.3700","-48.7219","Brazil","BR","BRA","Santa Catarina","","24810","1076471476"
"Leoben","Leoben","47.3817","15.0972","Austria","AT","AUT","Steiermark","minor","24645","1040591429"
"Tirutturaippūndi","Tirutturaippundi","10.5300","79.6500","India","IN","IND","Tamil Nādu","","24404","1356134060"
"Campo Magro","Campo Magro","-25.3689","-49.4508","Brazil","BR","BRA","Paraná","","24843","1076716501"
"Zhuchangba","Zhuchangba","26.6615","106.5485","China","CN","CHN","Guizhou","","24281","1156067473"
"Bad Soden am Taunus","Bad Soden am Taunus","50.1333","8.5000","Germany","DE","DEU","Hesse","","23036","1276847418"
"Montevarchi","Montevarchi","43.5286","11.5700","Italy","IT","ITA","Tuscany","","24440","1380447263"
"Dakṣiṇkāli̇̄","Daksinkali","27.6089","85.2672","Nepal","NP","NPL","","","24297","1524170564"
"Dongxiaozhai","Dongxiaozhai","40.1149","118.1375","China","CN","CHN","Hebei","","24495","1156890376"
"Lucena","Lucena","10.8833","122.6000","Philippines","PH","PHL","Iloilo","","24314","1608674517"
"Břeclav","Breclav","48.7589","16.8819","Czechia","CZ","CZE","Jihomoravský Kraj","","24554","1203778686"
"Nāyanakulam","Nayanakulam","9.9611","78.1371","India","IN","IND","Tamil Nādu","","23499","1356236129"
"Suchitoto","Suchitoto","13.9333","-89.0333","El Salvador","SV","SLV","Cuscatlán","","24786","1222451223"
"Pindwāra","Pindwara","24.7945","73.0550","India","IN","IND","Rājasthān","","24487","1356740431"
"Tamilisan","Tamilisan","7.9761","122.6639","Philippines","PH","PHL","Zamboanga del Norte","","24680","1608811551"
"Chāpar","Chapar","26.2839","90.2264","India","IN","IND","Assam","","24496","1356054288"
"Santa Rosa","Santa Rosa","10.4456","-75.3686","Colombia","CO","COL","Bolívar","minor","24694","1170627978"
"Tumwater","Tumwater","46.9891","-122.9175","United States","US","USA","Washington","","24855","1840021145"
"Anastácio","Anastacio","-20.4839","-55.8069","Brazil","BR","BRA","Mato Grosso do Sul","","24852","1076847200"
"Vēttakkāranpudūr","Vettakkaranpudur","10.5637","76.9166","India","IN","IND","Tamil Nādu","","24235","1356521161"
"Dickinson","Dickinson","46.8918","-102.7925","United States","US","USA","North Dakota","","24851","1840000192"
"Springfield","Springfield","39.9281","-75.3362","United States","US","USA","Pennsylvania","","24851","1840147258"
"Gemerek","Gemerek","39.1819","36.0681","Turkey","TR","TUR","Sivas","minor","24828","1792500011"
"Būndu","Bundu","23.1609","85.5901","India","IN","IND","Jhārkhand","","24445","1356307063"
"Sebastian","Sebastian","27.7822","-80.4819","United States","US","USA","Florida","","24843","1840015984"
"Kulachi","Kulachi","31.9286","70.4592","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","24738","1586015711"
"Gorantla","Gorantla","13.9892","77.7703","India","IN","IND","Andhra Pradesh","","24586","1356060409"
"Zhenbeibu","Zhenbeibu","38.6275","106.0669","China","CN","CHN","Ningxia","","24706","1156014183"
"Sarandi","Sarandi","-27.9439","-52.9228","Brazil","BR","BRA","Rio Grande do Sul","","24763","1076885857"
"Zarautz","Zarautz","43.2833","-2.1667","Spain","ES","ESP","Basque Country","","23271","1724056021"
"Pão de Açúcar","Pao de Acucar","-9.7478","-37.4369","Brazil","BR","BRA","Alagoas","","24792","1076453534"
"Ardmore","Ardmore","34.1949","-97.1256","United States","US","USA","Oklahoma","","24829","1840019258"
"Pweto","Pweto","-8.4667","28.9000","Congo (Kinshasa)","CD","COD","Haut-Katanga","","24767","1180628499"
"Somerset","Somerset","40.5083","-74.5010","United States","US","USA","New Jersey","","24827","1840005375"
"Fort Dodge","Fort Dodge","42.5098","-94.1751","United States","US","USA","Iowa","","24826","1840000439"
"São Joaquim","Sao Joaquim","-28.2939","-49.9319","Brazil","BR","BRA","Santa Catarina","","24812","1076398960"
"Delfzijl","Delfzijl","53.3333","6.9167","Netherlands","NL","NLD","Groningen","minor","24716","1528897599"
"São Mamede de Infesta","Sao Mamede de Infesta","41.2000","-8.6000","Portugal","PT","PRT","Porto","","23122","1620906554"
"Junqueiro","Junqueiro","-9.9250","-36.4758","Brazil","BR","BRA","Alagoas","","24722","1076985686"
"Navalgund","Navalgund","15.5700","75.3700","India","IN","IND","Karnātaka","","24613","1356120941"
"Isernhagen-Süd","Isernhagen-Sud","52.4342","9.8572","Germany","DE","DEU","Lower Saxony","","24409","1276610067"
"Mequon","Mequon","43.2352","-87.9839","United States","US","USA","Wisconsin","","24814","1840037989"
"Wright","Wright","30.4445","-86.6419","United States","US","USA","Florida","","24814","1840013921"
"San Benito","San Benito","26.1298","-97.6440","United States","US","USA","Texas","","24812","1840022260"
"Pepa","Pepa","-7.7106","29.7760","Congo (Kinshasa)","CD","COD","Tanganyika","","24767","1180195737"
"Tekāri","Tekari","24.9425","84.8427","India","IN","IND","Bihār","","23259","1356622953"
"Zanesville","Zanesville","39.9565","-82.0132","United States","US","USA","Ohio","","24809","1840001496"
"Patian","Patian","5.8444","121.1439","Philippines","PH","PHL","Sulu","","24736","1608801724"
"Bhirāha","Bhiraha","25.7978","86.0736","India","IN","IND","Bihār","","22904","1356163268"
"Scotch Plains","Scotch Plains","40.6332","-74.3739","United States","US","USA","New Jersey","","24798","1840081747"
"Wierden","Wierden","52.3500","6.6000","Netherlands","NL","NLD","Overijssel","minor","24538","1528021372"
"Hulbuk","Hulbuk","37.8050","69.6464","Tajikistan","TJ","TJK","Khatlon","minor","24500","1762158333"
"Oga","Oga","39.8868","139.8476","Japan","JP","JPN","Akita","","24690","1392185256"
"Raisio","Raisio","60.4858","22.1692","Finland","FI","FIN","Varsinais-Suomi","minor","24290","1246206941"
"Apollo Beach","Apollo Beach","27.7618","-82.4003","United States","US","USA","Florida","","24790","1840014149"
"Armidale","Armidale","-30.5000","151.6500","Australia","AU","AUS","New South Wales","","23352","1036975829"
"Velūr","Velur","11.1114","78.0048","India","IN","IND","Tamil Nādu","","24305","1356119135"
"Tuburan","Tuburan","6.6000","122.2000","Philippines","PH","PHL","Basilan","","24742","1608240278"
"Fort Hood","Fort Hood","31.1357","-97.7834","United States","US","USA","Texas","","24781","1840073932"
"Derry","Derry","40.2709","-76.6561","United States","US","USA","Pennsylvania","","24780","1840152635"
"Pāsighāt","Pasighat","28.0700","95.3300","India","IN","IND","Arunāchal Pradesh","","24656","1356208104"
"Kakonko","Kakonko","-3.2796","30.9600","Tanzania","TZ","TZA","Kigoma","","24688","1834778373"
"Villanueva","Villanueva","4.6087","-72.9288","Colombia","CO","COL","Casanare","minor","24740","1170495463"
"G’allaorol Shahri","G'allaorol Shahri","40.0214","67.5975","Uzbekistan","UZ","UZB","Jizzax","minor","24700","1860986830"
"Burdeos","Burdeos","14.8436","121.9697","Philippines","PH","PHL","Quezon","","24644","1608885174"
"Ziketan","Ziketan","35.5885","99.9866","China","CN","CHN","Qinghai","minor","24758","1156998574"
"Moses Lake","Moses Lake","47.1279","-119.2759","United States","US","USA","Washington","","24764","1840019817"
"Český Těšín","Cesky Tesin","49.7461","18.6261","Czechia","CZ","CZE","Moravskoslezský Kraj","","24069","1203518530"
"Buy","Buy","58.4833","41.5333","Russia","RU","RUS","Kostromskaya Oblast’","","23809","1643121343"
"Hodonín","Hodonin","48.8489","17.1325","Czechia","CZ","CZE","Jihomoravský Kraj","","24385","1203626632"
"Şamaxı","Samaxi","40.6303","48.6414","Azerbaijan","AZ","AZE","Şamaxı","","24681","1031286384"
"Seia","Seia","40.4220","-7.7024","Portugal","PT","PRT","Guarda","minor","24702","1620376674"
"Luzilândia","Luzilandia","-3.4578","-42.3700","Brazil","BR","BRA","Piauí","","24721","1076062219"
"Tres Isletas","Tres Isletas","-26.3500","-60.4167","Argentina","AR","ARG","Chaco","minor","24747","1032320525"
"Belsand","Belsand","26.4436","85.4008","India","IN","IND","Bihār","","23548","1356075716"
"Astorga","Astorga","-23.2328","-51.6658","Brazil","BR","BRA","Paraná","","24698","1076827455"
"Aparecida do Taboado","Aparecida do Taboado","-20.0869","-51.0939","Brazil","BR","BRA","Mato Grosso do Sul","","24745","1076127062"
"Algeciras","Algeciras","2.5333","-75.3167","Colombia","CO","COL","Huila","minor","24708","1170438880"
"Tamahú","Tamahu","15.3069","-90.2342","Guatemala","GT","GTM","Alta Verapaz","minor","24444","1320032339"
"Chinobod","Chinobod","40.8767","71.9725","Uzbekistan","UZ","UZB","Andijon","","24000","1860184702"
"Jalolquduq","Jalolquduq","40.7194","72.6428","Uzbekistan","UZ","UZB","Andijon","","24000","1860303427"
"Oirase","Oirase","40.5992","141.3977","Japan","JP","JPN","Aomori","","24407","1392003070"
"Marco","Marco","-3.1239","-40.1469","Brazil","BR","BRA","Ceará","","24703","1076214721"
"Bintuni","Bintuni","-2.1167","133.5333","Indonesia","ID","IDN","Papua Barat","minor","24742","1360482675"
"Extremoz","Extremoz","-5.7058","-35.3069","Brazil","BR","BRA","Rio Grande do Norte","","24569","1076180911"
"Kungälv","Kungalv","57.8667","11.9667","Sweden","SE","SWE","Västra Götaland","minor","22768","1752531521"
"Ipameri","Ipameri","-17.7219","-48.1600","Brazil","BR","BRA","Goiás","","24735","1076781326"
"Dix Hills","Dix Hills","40.8033","-73.3375","United States","US","USA","New York","","24739","1840005035"
"Petrinja","Petrinja","45.4406","16.2783","Croatia","HR","HRV","Sisačko-Moslavačka Županija","minor","24671","1191586902"
"Miguel Pereira","Miguel Pereira","-22.4539","-43.4689","Brazil","BR","BRA","Rio de Janeiro","","24642","1076793723"
"Giulianova","Giulianova","42.7500","13.9500","Italy","IT","ITA","Abruzzo","","23875","1380913817"
"Čadca","Cadca","49.4358","18.7922","Slovakia","SK","SVK","Žilina","minor","24315","1703091898"
"Firuzoba","Firuzoba","40.3000","70.4000","Tajikistan","TJ","TJK","Sughd","","23813","1762567325"
"Monte Cristi","Monte Cristi","19.8667","-71.6500","Dominican Republic","DO","DOM","Cibao Noroeste","minor","24674","1214970157"
"Gose","Gose","34.4667","135.7333","Japan","JP","JPN","Nara","","24317","1392003367"
"Jacaré","Jacare","-18.9058","-40.0758","Brazil","BR","BRA","Espírito Santo","","24678","1076355276"
"Chebba","Chebba","35.2372","11.1150","Tunisia","TN","TUN","Mahdia","","24515","1788108362"
"Canindé de São Francisco","Caninde de Sao Francisco","-9.6419","-37.7878","Brazil","BR","BRA","Sergipe","","24686","1076351057"
"São Geraldo do Araguaia","Sao Geraldo do Araguaia","-6.4008","-48.5550","Brazil","BR","BRA","Pará","","24705","1076057827"
"Loma Linda","Loma Linda","34.0450","-117.2498","United States","US","USA","California","","24712","1840020406"
"Calamar","Calamar","10.2500","-74.9158","Colombia","CO","COL","Bolívar","minor","24573","1170252193"
"Sucre","Sucre","-1.2600","-80.4400","Ecuador","EC","ECU","Manabí","","24631","1218621750"
"Igaraçu do Tietê","Igaracu do Tiete","-22.5092","-48.5578","Brazil","BR","BRA","São Paulo","","24451","1076166440"
"Channagiri","Channagiri","14.0240","75.9258","India","IN","IND","Karnātaka","","24435","1356077835"
"Juayúa","Juayua","13.8333","-89.7333","El Salvador","SV","SLV","Sonsonate","","24465","1222293042"
"Medford","Medford","40.8220","-72.9859","United States","US","USA","New York","","24702","1840005043"
"Tanglin","Tanglin","37.4377","115.8463","China","CN","CHN","Hebei","","24041","1156863553"
"Albenga","Albenga","44.0500","8.2167","Italy","IT","ITA","Liguria","","24042","1380307501"
"Arad","Arad","31.25","35.2167","Israel","IL","ISR","Southern","","24436","1376674296"
"Vizela","Vizela","41.3667","-8.2667","Portugal","PT","PRT","Porto","","23736","1620166206"
"Khārupatia","Kharupatia","26.5184","92.1472","India","IN","IND","Assam","","23473","1356360922"
"Takhli","Takhli","15.2667","100.3500","Thailand","TH","THA","Nakhon Sawan","minor","24563","1764978785"
"Srvanampatti","Srvanampatti","11.0764","77.0045","India","IN","IND","Tamil Nādu","","23412","1356160163"
"Mummidivaram","Mummidivaram","16.6500","82.1167","India","IN","IND","Andhra Pradesh","","23732","1356969235"
"Shangtianba","Shangtianba","28.0390","103.8584","China","CN","CHN","Yunnan","minor","24606","1156154168"
"Dorohoi","Dorohoi","47.9597","26.3997","Romania","RO","ROU","Botoşani","","24309","1642539172"
"Oleshky","Oleshky","46.6333","32.5833","Ukraine","UA","UKR","Khersonska Oblast","minor","24639","1804351272"
"Sagbayan","Sagbayan","9.9167","124.1000","Philippines","PH","PHL","Bohol","","24335","1608415406"
"Ashton in Makerfield","Ashton in Makerfield","53.4870","-2.6410","United Kingdom","GB","GBR","Wigan","","24000","1826919005"
"Carrascal","Carrascal","9.3683","125.9494","Philippines","PH","PHL","Surigao del Sur","","24586","1608000823"
"Itabaiana","Itabaiana","-7.3289","-35.3328","Brazil","BR","BRA","Paraíba","","24565","1076694926"
"Port St. John","Port St. John","28.4757","-80.8104","United States","US","USA","Florida","","24677","1840014079"
"Rickmansworth","Rickmansworth","51.6383","-0.4659","United Kingdom","GB","GBR","Hertfordshire","","23973","1826881978"
"Las Cabras","Las Cabras","-34.2917","-71.3097","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","24640","1152883984"
"Macia","Macia","-25.0333","33.1000","Mozambique","MZ","MOZ","Gaza","","24153","1508558129"
"West Memphis","West Memphis","35.1530","-90.1996","United States","US","USA","Arkansas","","24666","1840015448"
"Álvares Machado","Alvares Machado","-22.0789","-51.4719","Brazil","BR","BRA","São Paulo","","24651","1076095632"
"Homer Glen","Homer Glen","41.6043","-87.9497","United States","US","USA","Illinois","","24664","1840011483"
"Khajurāho","Khajuraho","24.8500","79.9333","India","IN","IND","Madhya Pradesh","","24481","1356993147"
"Konstancin-Jeziorna","Konstancin-Jeziorna","52.0833","21.1167","Poland","PL","POL","Mazowieckie","","23694","1616018861"
"Zion","Zion","42.4603","-87.8511","United States","US","USA","Illinois","","24660","1840010114"
"Hannoversch Münden","Hannoversch Munden","51.4167","9.6500","Germany","DE","DEU","Lower Saxony","","24467","1276002967"
"Rochefort","Rochefort","45.9421","-0.9588","France","FR","FRA","Nouvelle-Aquitaine","minor","23584","1250215806"
"Shama","Shama","5.0167","-1.6333","Ghana","GH","GHA","Western","","23699","1288704035"
"Socastee","Socastee","33.6871","-79.0086","United States","US","USA","South Carolina","","24657","1840013647"
"Sūleswaranpatti","Suleswaranpatti","10.6388","77.0084","India","IN","IND","Tamil Nādu","","24038","1356170691"
"Sanharó","Sanharo","-8.3608","-36.5658","Brazil","BR","BRA","Pernambuco","","24556","1076875890"
"Clinton","Clinton","41.8435","-90.2412","United States","US","USA","Iowa","","24651","1840000507"
"Malinao","Malinao","11.6431","122.3072","Philippines","PH","PHL","Aklan","","24517","1608219095"
"Larvik","Larvik","59.0811","10.0164","Norway","NO","NOR","Vestfold og Telemark","","24647","1578938550"
"Buôn Trấp","Buon Trap","12.4833","108.0167","Vietnam","VN","VNM","Đắk Lắk","minor","23863","1704599387"
"Putten","Putten","52.2667","5.6000","Netherlands","NL","NLD","Gelderland","minor","24365","1528973473"
"Igreja Nova","Igreja Nova","-10.1253","-36.6619","Brazil","BR","BRA","Alagoas","","24586","1076657948"
"Carolina Forest","Carolina Forest","33.7651","-78.9130","United States","US","USA","South Carolina","","24642","1840147796"
"Al Qubbah","Al Qubbah","32.7667","22.2500","Libya","LY","LBY","Darnah","","24631","1434167769"
"Ashwarāopeta","Ashwaraopeta","17.2500","81.1333","India","IN","IND","Andhra Pradesh","","24405","1356588318"
"Perrysburg","Perrysburg","41.5377","-83.6413","United States","US","USA","Ohio","","24633","1840000623"
"Nossa Senhora das Dores","Nossa Senhora das Dores","-10.4919","-37.1928","Brazil","BR","BRA","Sergipe","","24580","1076135049"
"Westmont","Westmont","41.7948","-87.9742","United States","US","USA","Illinois","","24632","1840011414"
"Collinsville","Collinsville","38.6770","-90.0063","United States","US","USA","Illinois","","24627","1840007423"
"Ain Dfali","Ain Dfali","34.5990","-5.5488","Morocco","MA","MAR","Rabat-Salé-Kénitra","","24521","1504618863"
"Borre","Borre","59.3799","10.4539","Norway","NO","NOR","Vestfold og Telemark","","24272","1578001449"
"Ālangulam","Alangulam","8.8640","77.4994","India","IN","IND","Tamil Nādu","","23658","1356105551"
"Garcia Hernandez","Garcia Hernandez","9.6144","124.2946","Philippines","PH","PHL","Bohol","","24430","1608000451"
"Freha","Freha","36.7620","4.3163","Algeria","DZ","DZA","Tizi Ouzou","","24228","1012372550"
"Cartaxo","Cartaxo","39.1500","-8.7833","Portugal","PT","PRT","Santarém","minor","24462","1620745841"
"Yanbu","Yanbu","22.7544","120.5724","Taiwan","TW","TWN","Pingtung","","24237","1158818026"
"Attendorn","Attendorn","51.1167","7.9000","Germany","DE","DEU","North Rhine-Westphalia","","24367","1276325506"
"San Borja","San Borja","-14.8583","-66.7475","Bolivia","BO","BOL","El Beni","","24610","1068118050"
"Murādpur","Muradpur","25.8000","86.0055","India","IN","IND","Bihār","","22714","1356112432"
"Gelemso","Gelemso","8.8167","40.5167","Ethiopia","ET","ETH","Oromīya","","24200","1231438273"
"Panglong","Panglong","24.1003","98.3139","Myanmar","MM","MMR","Shan State","","24565","1104894804"
"Païta","Paita","-22.1337","166.3505","New Caledonia","NC","NCL","Province Sud","","24563","1540983323"
"Greenbelt","Greenbelt","38.9953","-76.8885","United States","US","USA","Maryland","","24602","1840005974"
"Bacolod","Bacolod","8.1892","124.0238","Philippines","PH","PHL","Lanao del Norte","","24367","1608296127"
"Les Irois","Les Irois","18.4000","-74.4500","Haiti","HT","HTI","Grand’Anse","","24374","1332919321"
"Sugar Hill","Sugar Hill","34.1080","-84.0558","United States","US","USA","Georgia","","24599","1840015615"
"Pātakākāni","Patakakani","16.3422","80.4911","India","IN","IND","Andhra Pradesh","","23201","1356288383"
"Karataş","Karatas","36.5625","35.3803","Turkey","TR","TUR","Adana","minor","24559","1792800632"
"Nasukarasuyama","Nasukarasuyama","36.6569","140.1514","Japan","JP","JPN","Tochigi","","24456","1392480551"
"Mau","Mau","26.2658","78.6711","India","IN","IND","Madhya Pradesh","","24244","1356185503"
"Mina","Mina","10.9333","122.5833","Philippines","PH","PHL","Iloilo","","24042","1608196122"
"Chanaur","Chanaur","25.8510","86.6739","India","IN","IND","Bihār","","23478","1356786394"
"Kauswagan","Kauswagan","8.1917","124.0847","Philippines","PH","PHL","Lanao del Norte","","24193","1608069927"
"Aurora","Aurora","-6.9428","-38.9678","Brazil","BR","BRA","Ceará","","24566","1076635506"
"Urbano Santos","Urbano Santos","-3.2078","-43.4039","Brazil","BR","BRA","Maranhão","","24573","1076959663"
"Sulmona","Sulmona","42.0480","13.9262","Italy","IT","ITA","Abruzzo","","24173","1380132981"
"Amatán","Amatan","17.3833","-92.8500","Mexico","MX","MEX","Chiapas","minor","24512","1484881501"
"Yādiki","Yadiki","15.0500","77.8800","India","IN","IND","Andhra Pradesh","","24018","1356425691"
"Corsicana","Corsicana","32.0824","-96.4665","United States","US","USA","Texas","","24589","1840019505"
"Buzen","Buzen","33.6167","131.1333","Japan","JP","JPN","Fukuoka","","24369","1392003411"
"Alicia","Alicia","9.8957","124.4415","Philippines","PH","PHL","Bohol","","24374","1608855246"
"Grombalia","Grombalia","36.6000","10.5000","Tunisia","TN","TUN","Nabeul","","24336","1788960227"
"Zernograd","Zernograd","46.8500","40.3167","Russia","RU","RUS","Rostovskaya Oblast’","minor","24561","1643009335"
"Valdivia","Valdivia","7.1636","-75.4392","Colombia","CO","COL","Antioquia","minor","24538","1170000617"
"Tuzluca","Tuzluca","40.0494","43.6608","Turkey","TR","TUR","Iğdır","minor","24560","1792848113"
"Canhotinho","Canhotinho","-8.8819","-36.1908","Brazil","BR","BRA","Pernambuco","","24521","1076691166"
"Mekla","Mekla","36.6876","4.2681","Algeria","DZ","DZA","Tizi Ouzou","","24237","1012384530"
"Starkville","Starkville","33.4608","-88.8297","United States","US","USA","Mississippi","","24578","1840015713"
"La Huerta","La Huerta","19.4833","-104.6500","Mexico","MX","MEX","Jalisco","minor","24563","1484366583"
"Pirajuí","Pirajui","-21.9989","-49.4569","Brazil","BR","BRA","São Paulo","","24546","1076016134"
"Herzogenaurach","Herzogenaurach","49.5700","10.8819","Germany","DE","DEU","Bavaria","","24068","1276809658"
"Bela Vista de Goiás","Bela Vista de Goias","-16.9728","-48.9528","Brazil","BR","BRA","Goiás","","24554","1076686680"
"Borne","Borne","52.3000","6.7500","Netherlands","NL","NLD","Overijssel","minor","23668","1528920797"
"Ravānsar","Ravansar","34.7153","46.6533","Iran","IR","IRN","Kermānshāh","minor","24527","1364582269"
"El Retorno","El Retorno","2.3306","-72.6278","Colombia","CO","COL","Guaviare","minor","24562","1170783770"
"Bhadās","Bhadas","25.5184","86.4434","India","IN","IND","Bihār","","22866","1356302743"
"Cedro","Cedro","-6.6069","-39.0619","Brazil","BR","BRA","Ceará","","24527","1076688549"
"Fossano","Fossano","44.5500","7.7333","Italy","IT","ITA","Piedmont","","24372","1380823812"
"Bainbridge Island","Bainbridge Island","47.6439","-122.5434","United States","US","USA","Washington","","24557","1840018409"
"Syke","Syke","52.9131","8.8269","Germany","DE","DEU","Lower Saxony","","24365","1276951328"
"Condado","Condado","-7.5858","-35.1058","Brazil","BR","BRA","Pernambuco","","24282","1076861646"
"Itaporanga","Itaporanga","-7.3039","-38.1500","Brazil","BR","BRA","Paraíba","","24499","1076874618"
"Sakai","Sakai","36.1085","139.7949","Japan","JP","JPN","Ibaraki","","24035","1392827163"
"Rugeley","Rugeley","52.7599","-1.9388","United Kingdom","GB","GBR","Staffordshire","","24386","1826009668"
"Kaniv","Kaniv","49.7447","31.4558","Ukraine","UA","UKR","Cherkaska Oblast","","24532","1804910839"
"Selma","Selma","36.5715","-119.6143","United States","US","USA","California","","24545","1840021567"
"Avon","Avon","41.4485","-82.0187","United States","US","USA","Ohio","","24542","1840000641"
"Encruzilhada do Sul","Encruzilhada do Sul","-30.5439","-52.5219","Brazil","BR","BRA","Rio Grande do Sul","","24534","1076248019"
"Khairā Tolā","Khaira Tola","26.8958","84.1403","India","IN","IND","Bihār","","23011","1356404750"
"Watsa","Watsa","3.0372","29.5355","Congo (Kinshasa)","CD","COD","Haut-Uélé","","24516","1180367451"
"Kosigi","Kosigi","15.8542","77.2430","India","IN","IND","Andhra Pradesh","","24165","1356175415"
"Senboku","Senboku","39.7017","140.7315","Japan","JP","JPN","Akita","","24515","1392802025"
"Waldshut-Tiengen","Waldshut-Tiengen","47.6231","8.2144","Germany","DE","DEU","Baden-Württemberg","minor","24226","1276011078"
"Sālār","Salar","23.7748","88.1026","India","IN","IND","West Bengal","","22894","1356546647"
"Westford","Westford","42.5864","-71.4401","United States","US","USA","Massachusetts","","24535","1840053493"
"San Agustín Loxicha","San Agustin Loxicha","16.0167","-96.6167","Mexico","MX","MEX","Oaxaca","","24467","1484509506"
"Ōgawara","Ogawara","38.0494","140.7308","Japan","JP","JPN","Miyagi","","23586","1392003441"
"Dubnica nad Váhom","Dubnica nad Vahom","48.9606","18.1739","Slovakia","SK","SVK","Nitra","","24068","1703468923"
"Roissy-en-Brie","Roissy-en-Brie","48.7906","2.6519","France","FR","FRA","Île-de-France","","22851","1250233189"
"White Bear Lake","White Bear Lake","45.0656","-93.0150","United States","US","USA","Minnesota","","24520","1840010005"
"Goirle","Goirle","51.5167","5.0667","Netherlands","NL","NLD","Noord-Brabant","minor","23952","1528485242"
"Zungeru","Zungeru","9.8128","6.1558","Nigeria","NG","NGA","Niger","","24447","1566658964"
"Clarence-Rockland","Clarence-Rockland","45.4833","-75.2000","Canada","CA","CAN","Ontario","","24512","1124000639"
"Mahdīshahr","Mahdishahr","35.7108","53.3539","Iran","IR","IRN","Semnān","minor","24485","1364801554"
"Bereket","Bereket","39.2431","55.5169","Turkmenistan","TM","TKM","Balkan","","24500","1795808351"
"Sevan","Sevan","40.5550","44.9536","Armenia","AM","ARM","Geghark’unik’","","23200","1051586680"
"Athens","Athens","39.3270","-82.0987","United States","US","USA","Ohio","","24507","1840007355"
"Haaksbergen","Haaksbergen","52.1500","6.7500","Netherlands","NL","NLD","Overijssel","minor","24277","1528342884"
"Copertino","Copertino","40.2667","18.0500","Italy","IT","ITA","Puglia","","24094","1380260824"
"Limbach-Oberfrohna","Limbach-Oberfrohna","50.8667","12.7500","Germany","DE","DEU","Saxony","","24029","1276772007"
"Pýrgos","Pyrgos","37.6667","21.4333","Greece","GR","GRC","Dytikí Elláda","minor","24359","1300188949"
"Sanaur","Sanaur","30.3018","76.4579","India","IN","IND","Punjab","","23674","1356895790"
"Coín","Coin","36.6667","-4.7500","Spain","ES","ESP","Andalusia","","24309","1724570414"
"Kumar Khad","Kumar Khad","26.5918","86.2640","India","IN","IND","Bihār","","23806","1356386794"
"Ponca City","Ponca City","36.7235","-97.0677","United States","US","USA","Oklahoma","","24499","1840020345"
"Spremberg","Spremberg","51.5717","14.3794","Germany","DE","DEU","Brandenburg","","24373","1276939967"
"Gollapūdi","Gollapudi","16.5412","80.5784","India","IN","IND","Andhra Pradesh","","23555","1356285137"
"Neftekumsk","Neftekumsk","44.7506","44.9797","Russia","RU","RUS","Stavropol’skiy Kray","","24472","1643496065"
"Vilankulo","Vilankulo","-22.0000","35.3167","Mozambique","MZ","MOZ","Inhambane","","24433","1508379289"
"Xinxing","Xinxing","47.1601","123.8000","China","CN","CHN","Heilongjiang","minor","24317","1156085832"
"El Congo","El Congo","13.9000","-89.4833","El Salvador","SV","SLV","Santa Ana","","24219","1222555785"
"Windham","Windham","41.7102","-72.1671","United States","US","USA","Connecticut","","24480","1840035671"
"Bīrpur","Birpur","26.5082","87.0119","India","IN","IND","Bihār","","23736","1356129226"
"Lagonglong","Lagonglong","8.8000","124.7833","Philippines","PH","PHL","Misamis Oriental","","24190","1608442449"
"Gaz","Gaz","32.8022","51.6211","Iran","IR","IRN","Eşfahān","","24433","1364058076"
"Liushui","Liushui","32.5959","108.7479","China","CN","CHN","Shaanxi","","24149","1156827365"
"Pacho","Pacho","5.1306","-74.1583","Colombia","CO","COL","Cundinamarca","minor","24413","1170161563"
"Riverside","Riverside","39.7835","-84.1219","United States","US","USA","Ohio","","24472","1840012490"
"Loha","Loha","18.9623","77.1309","India","IN","IND","Mahārāshtra","","24125","1356853431"
"Simsbury","Simsbury","41.8729","-72.8256","United States","US","USA","Connecticut","","24467","1840045081"
"Shāhpur","Shahpur","21.2374","76.2256","India","IN","IND","Madhya Pradesh","","23964","1356036035"
"Lukaya","Lukaya","-0.1508","31.8744","Uganda","UG","UGA","Kalungu","","24250","1800343653"
"Fish Hawk","Fish Hawk","27.8511","-82.2164","United States","US","USA","Florida","","24463","1840028982"
"Tocopilla","Tocopilla","-22.0964","-70.2000","Chile","CL","CHL","Antofagasta","minor","24460","1152050254"
"Maksi","Maksi","23.2600","76.1457","India","IN","IND","Madhya Pradesh","","24189","1356073929"
"Senftenberg","Senftenberg","51.5167","14.0167","Germany","DE","DEU","Brandenburg","minor","24275","1276241839"
"Vernon","Vernon","49.0900","1.4900","France","FR","FRA","Normandie","","23777","1250890405"
"Caibiran","Caibiran","11.5667","124.5833","Philippines","PH","PHL","Biliran","","24167","1608798672"
"Ajim","Ajim","33.7167","10.7500","Tunisia","TN","TUN","Médenine","","24294","1788666202"
"Tupanatinga","Tupanatinga","-8.7528","-37.3400","Brazil","BR","BRA","Pernambuco","","24425","1076649310"
"Ramain","Ramain","7.9667","124.3500","Philippines","PH","PHL","Lanao del Sur","","24406","1608918458"
"Pennādam","Pennadam","11.4039","79.2416","India","IN","IND","Tamil Nādu","","23979","1356183893"
"North Potomac","North Potomac","39.0955","-77.2373","United States","US","USA","Maryland","","24449","1840005835"
"Paris","Paris","33.6688","-95.5460","United States","US","USA","Texas","","24447","1840020594"
"Stroitel","Stroitel","50.7833","36.4833","Russia","RU","RUS","Belgorodskaya Oblast’","minor","24392","1643765660"
"Soamanandrariny","Soamanandrariny","-19.6500","47.2833","Madagascar","MG","MDG","Antananarivo","","24003","1450764328"
"Hujiachi","Hujiachi","37.8917","115.7110","China","CN","CHN","Hebei","","24105","1156497973"
"Annūr","Annur","11.2325","77.1069","India","IN","IND","Tamil Nādu","","23828","1356154050"
"Helleland","Helleland","58.8922","5.7336","Norway","NO","NOR","Rogaland","","24173","1578920333"
"Yokadouma","Yokadouma","3.5167","15.0500","Cameroon","CM","CMR","Est","","24430","1120416546"
"Porto de Mós","Porto de Mos","39.6017","-8.8175","Portugal","PT","PRT","Leiria","minor","24342","1620575221"
"Bad Mergentheim","Bad Mergentheim","49.5000","9.7667","Germany","DE","DEU","Baden-Württemberg","","24247","1276714498"
"Almansa","Almansa","38.8682","-1.0979","Spain","ES","ESP","Castille-La Mancha","","24388","1724819701"
"Shamsābād","Shamsabad","27.5364","79.4394","India","IN","IND","Uttar Pradesh","","23584","1356620177"
"Bijāwar","Bijawar","24.6235","79.4899","India","IN","IND","Madhya Pradesh","","24306","1356698004"
"El Bosque","El Bosque","17.0333","-92.7500","Mexico","MX","MEX","Chiapas","minor","24273","1484278804"
"Martos","Martos","37.7167","-3.9667","Spain","ES","ESP","Andalusia","","24329","1724000251"
"Kotelnich","Kotelnich","58.3078","48.3181","Russia","RU","RUS","Kirovskaya Oblast’","","23682","1643742016"
"Orbassano","Orbassano","45.0073","7.5369","Italy","IT","ITA","Piedmont","","23365","1380058518"
"Bormujos","Bormujos","37.3667","-6.0667","Spain","ES","ESP","Andalusia","","22536","1724359655"
"Lisse","Lisse","52.2500","4.5500","Netherlands","NL","NLD","Zuid-Holland","minor","22982","1528160050"
"Selydove","Selydove","48.1500","37.3000","Ukraine","UA","UKR","Donetska Oblast","","23793","1804092732"
"Qiaomaichuan","Qiaomaichuan","39.7866","113.8239","China","CN","CHN","Shanxi","","24233","1156487658"
"Ambohimasina","Ambohimasina","-19.6500","46.7333","Madagascar","MG","MDG","Antananarivo","","24352","1450467942"
"Santa Ana","Santa Ana","16.8069","-89.8272","Guatemala","GT","GTM","Petén","minor","24380","1320569617"
"Jocotenango","Jocotenango","14.5868","-90.7444","Guatemala","GT","GTM","Sacatepéquez","minor","23492","1320436479"
"El Factor","El Factor","19.3200","-69.8800","Dominican Republic","DO","DOM","Cibao Nordeste","","24240","1214790750"
"Koili Simra","Koili Simra","25.4714","87.2175","India","IN","IND","Bihār","","23480","1356758777"
"Wijk bij Duurstede","Wijk bij Duurstede","51.9833","5.3333","Netherlands","NL","NLD","Utrecht","minor","23925","1528933907"
"Craíbas","Craibas","-9.6178","-36.7678","Brazil","BR","BRA","Alagoas","","24309","1076030109"
"Newport","Newport","50.7010","-1.2883","United Kingdom","GB","GBR","Isle of Wight","","23957","1826718687"
"Vila Rica","Vila Rica","-10.0119","-51.1158","Brazil","BR","BRA","Mato Grosso","","24392","1076540734"
"Sendamangalam","Sendamangalam","11.2825","78.2339","India","IN","IND","Tamil Nādu","","23913","1356633926"
"Rathenow","Rathenow","52.6000","12.3333","Germany","DE","DEU","Brandenburg","minor","24179","1276833446"
"Husum","Husum","54.4667","9.0500","Germany","DE","DEU","Schleswig-Holstein","minor","23478","1276973778"
"Dole","Dole","47.0931","5.4906","France","FR","FRA","Bourgogne-Franche-Comté","minor","23770","1250000209"
"Vulcan","Vulcan","45.3811","23.2914","Romania","RO","ROU","Hunedoara","","24160","1642844493"
"Nocera Superiore","Nocera Superiore","40.7417","14.6722","Italy","IT","ITA","Campania","","24369","1380320885"
"Ţayyibat al Imām","Tayyibat al Imam","35.2661","36.7114","Syria","SY","SYR","Ḩamāh","","24105","1760649932"
"Dinuba","Dinuba","36.5453","-119.3987","United States","US","USA","California","","24383","1840019044"
"Lanester","Lanester","47.7647","-3.3422","France","FR","FRA","Bretagne","","23124","1250476491"
"Joaquim Gomes","Joaquim Gomes","-9.1328","-35.7478","Brazil","BR","BRA","Alagoas","","24280","1076186837"
"Fishkill","Fishkill","41.5129","-73.9271","United States","US","USA","New York","","24381","1840004748"
"Środa Wielkopolska","Sroda Wielkopolska","52.2333","17.2833","Poland","PL","POL","Wielkopolskie","minor","24225","1616107812"
"Dighwa","Dighwa","26.2437","84.7532","India","IN","IND","Bihār","","23353","1356125108"
"Horquetas","Horquetas","10.3195","-83.9647","Costa Rica","CR","CRI","Heredia","","24331","1188000133"
"Marple","Marple","53.3970","-2.0610","United Kingdom","GB","GBR","Stockport","","23687","1826000495"
"Amarapura","Amarapura","21.8530","96.0954","Myanmar","MM","MMR","Mandalay","","23200","1104567036"
"Gollalagunta","Gollalagunta","17.1672","82.2847","India","IN","IND","Andhra Pradesh","","23882","1356024704"
"Dalān","Dalan","25.6030","87.5592","India","IN","IND","Bihār","","22689","1356213744"
"Puerto Guzmán","Puerto Guzman","0.9636","-76.4086","Colombia","CO","COL","Putumayo","minor","24355","1170853414"
"Asino","Asino","57.0000","86.1333","Russia","RU","RUS","Tomskaya Oblast’","","24354","1643361400"
"Tadjmout","Tadjmout","33.8667","2.5167","Algeria","DZ","DZA","Laghouat","","24320","1012533662"
"Latauna","Latauna","26.0912","86.8809","India","IN","IND","Bihār","","22968","1356753749"
"Volendam","Volendam","52.4994","5.0675","Netherlands","NL","NLD","Noord-Holland","minor","22715","1528968861"
"Tirukkalikkunram","Tirukkalikkunram","12.6067","80.0625","India","IN","IND","Tamil Nādu","","23677","1356106403"
"Buturlinovka","Buturlinovka","50.8333","40.5833","Russia","RU","RUS","Voronezhskaya Oblast’","","24319","1643165289"
"Dunaharaszti","Dunaharaszti","47.3539","19.0948","Hungary","HU","HUN","Pest","","23536","1348702931"
"Kiên Lương","Kien Luong","10.2856","104.6444","Vietnam","VN","VNM","Kiến Giang","minor","24287","1704518749"
"La Montañita","La Montanita","1.4792","-75.4361","Colombia","CO","COL","Caquetá","minor","24323","1170812971"
"Mulgund","Mulgund","15.2807","75.5213","India","IN","IND","Karnātaka","","23889","1356406320"
"Cedros","Cedros","14.6000","-87.1167","Honduras","HN","HND","Francisco Morazán","","24305","1340514859"
"Sarykemer","Sarykemer","43.0106","71.5172","Kazakhstan","KZ","KAZ","","","24314","1398707771"
"Curacaví","Curacavi","-33.4000","-71.1500","Chile","CL","CHL","Región Metropolitana","","24298","1152178936"
"Kuju","Kuju","23.7254","85.5102","India","IN","IND","Jhārkhand","","23812","1356020297"
"Ridgeland","Ridgeland","32.4236","-90.1481","United States","US","USA","Mississippi","","24330","1840014873"
"Capão do Leão","Capao do Leao","-31.7628","-52.4839","Brazil","BR","BRA","Rio Grande do Sul","","24298","1076641402"
"Nerópolis","Neropolis","-16.4058","-49.2189","Brazil","BR","BRA","Goiás","","24210","1076444185"
"Palmetto Bay","Palmetto Bay","25.6219","-80.3221","United States","US","USA","Florida","","24327","1840018335"
"Nueva Paz","Nueva Paz","22.7633","-81.7581","Cuba","CU","CUB","Mayabeque","minor","24277","1192235992"
"Denison","Denison","33.7672","-96.5808","United States","US","USA","Texas","","24324","1840019317"
"Donmatías","Donmatias","6.4833","-75.4333","Colombia","CO","COL","Antioquia","minor","24201","1170287770"
"Douar ’Ayn Dfali","Douar 'Ayn Dfali","33.9500","-4.4500","Morocco","MA","MAR","Fès-Meknès","","24241","1504477105"
"Sīlappādi","Silappadi","10.3940","78.0078","India","IN","IND","Tamil Nādu","","23678","1356224423"
"La Paz","La Paz","-30.7500","-59.6500","Argentina","AR","ARG","Entre Ríos","minor","24307","1032955221"
"Novo Aripuanã","Novo Aripuana","-5.1214","-60.3806","Brazil","BR","BRA","Amazonas","","24315","1076527890"
"Stjørdal","Stjordal","63.4750","11.1708","Norway","NO","NOR","Trøndelag","minor","24287","1578420419"
"Faribault","Faribault","44.2996","-93.2789","United States","US","USA","Minnesota","","24310","1840007889"
"Katrineholm","Katrineholm","59.0000","16.2000","Sweden","SE","SWE","Södermanland","minor","24271","1752643787"
"Saint-Laurent-du-Maroni","Saint-Laurent-du-Maroni","5.4976","-54.0325","French Guiana","GF","GUF","","minor","24287","1254543638"
"Cogua","Cogua","5.0667","-73.9833","Colombia","CO","COL","Cundinamarca","minor","24092","1170991139"
"Marsella","Marsella","4.9167","-75.7500","Colombia","CO","COL","Risaralda","minor","24131","1170787775"
"Rolling Meadows","Rolling Meadows","42.0747","-88.0252","United States","US","USA","Illinois","","24300","1840009185"
"Cabrera","Cabrera","19.6300","-69.9000","Dominican Republic","DO","DOM","Cibao Nordeste","","24218","1214117944"
"Dazhuang","Dazhuang","38.6951","115.6067","China","CN","CHN","Hebei","","23559","1156072600"
"Alfter","Alfter","50.7356","7.0092","Germany","DE","DEU","North Rhine-Westphalia","","23622","1276256549"
"Manage","Manage","50.5000","4.2333","Belgium","BE","BEL","Wallonia","","23122","1056832632"
"Boulsa","Boulsa","12.6667","-0.5833","Burkina Faso","BF","BFA","Centre-Nord","minor","24200","1854535753"
"Mahaiza","Mahaiza","-19.9000","46.8167","Madagascar","MG","MDG","Antananarivo","","24244","1450126479"
"San Fernando","San Fernando","12.3167","122.6000","Philippines","PH","PHL","Romblon","","24171","1608719636"
"Quipapá","Quipapa","-8.8278","-36.0119","Brazil","BR","BRA","Pernambuco","","24186","1076936377"
"Rimavská Sobota","Rimavska Sobota","48.3811","20.0144","Slovakia","SK","SVK","Banská Bystrica","minor","24010","1703980891"
"San Agustin","San Agustin","12.5689","122.1314","Philippines","PH","PHL","Romblon","","24115","1608747283"
"Sattahip","Sattahip","12.6636","100.9036","Thailand","TH","THA","Chon Buri","minor","23418","1764518101"
"Mairena del Alcor","Mairena del Alcor","37.3667","-5.7333","Spain","ES","ESP","Andalusia","","23938","1724398748"
"Centerville","Centerville","39.6339","-84.1449","United States","US","USA","Ohio","","24281","1840007308"
"Hajdúszoboszló","Hajduszoboszlo","47.4333","21.3833","Hungary","HU","HUN","Hajdú-Bihar","minor","24175","1348837635"
"Gotvand","Gotvand","32.2514","48.8161","Iran","IR","IRN","Khūzestān","","24216","1364528412"
"Gazojak","Gazojak","41.1833","61.4000","Turkmenistan","TM","TKM","Lebap","","24251","1795672645"
"Qiloane","Qiloane","-29.3625","27.6319","Lesotho","LS","LSO","Maseru","","24093","1426291915"
"Pallikondai","Pallikondai","12.9167","78.9333","India","IN","IND","Tamil Nādu","","23067","1356612703"
"Formby","Formby","53.5586","-3.0666","United Kingdom","GB","GBR","Sefton","","22419","1826350182"
"San Juan Nepomuceno","San Juan Nepomuceno","-26.1167","-55.9300","Paraguay","PY","PRY","Caazapá","","24243","1600809234"
"Oak Harbor","Oak Harbor","48.2964","-122.6333","United States","US","USA","Washington","","24267","1840019779"
"Medford","Medford","39.8639","-74.8231","United States","US","USA","New Jersey","","24265","1840081614"
"Oiapoque","Oiapoque","3.8428","-51.8350","Brazil","BR","BRA","Amapá","","24263","1076048629"
"Lebedyn","Lebedyn","50.5831","34.4823","Ukraine","UA","UKR","Sumska Oblast","","24238","1804576509"
"Pout","Pout","14.7739","-17.0603","Senegal","SN","SEN","Thiès","","23728","1686073120"
"Kalispell","Kalispell","48.2153","-114.3274","United States","US","USA","Montana","","24260","1840018350"
"Skoura","Skoura","31.0606","-6.5556","Morocco","MA","MAR","Drâa-Tafilalet","","24055","1504320897"
"Feriana","Feriana","34.9500","8.5667","Tunisia","TN","TUN","Kasserine","","24198","1788954884"
"Noto","Noto","36.8833","15.0833","Italy","IT","ITA","Sicilia","","24028","1380605437"
"Haren","Haren","52.7667","7.2167","Germany","DE","DEU","Lower Saxony","","24137","1276278460"
"Ambalamanasy II","Ambalamanasy II","-14.5167","49.5833","Madagascar","MG","MDG","Antsiranana","","24211","1450605901"
"South Plainfield","South Plainfield","40.5748","-74.4152","United States","US","USA","New Jersey","","24251","1840001334"
"Benbrook","Benbrook","32.6788","-97.4637","United States","US","USA","Texas","","24248","1840019425"
"Chandrakona","Chandrakona","22.7300","87.5200","India","IN","IND","West Bengal","","23629","1356507688"
"Salqīn","Salqin","36.1333","36.4500","Syria","SY","SYR","Idlib","minor","23700","1760573067"
"Gauripur","Gauripur","26.0800","89.9700","India","IN","IND","Assam","","23477","1356178626"
"Kyonpyaw","Kyonpyaw","17.3000","95.2000","Myanmar","MM","MMR","Ayeyarwady","","23966","1104964864"
"Diest","Diest","50.9847","5.0514","Belgium","BE","BEL","Flanders","","23824","1056065349"
"North Laurel","North Laurel","39.1285","-76.8476","United States","US","USA","Maryland","","24233","1840031495"
"Ibirapitanga","Ibirapitanga","-14.1639","-39.3739","Brazil","BR","BRA","Bahia","","24180","1076745407"
"Pietrasanta","Pietrasanta","43.9452","10.2183","Italy","IT","ITA","Tuscany","","23662","1380628379"
"Tehri","Tehri","30.3800","78.4800","India","IN","IND","Uttarākhand","","24014","1356223714"
"Bela Vista","Bela Vista","-22.1089","-56.5208","Brazil","BR","BRA","Mato Grosso do Sul","","24223","1076965093"
"Ivrea","Ivrea","45.4674","7.8748","Italy","IT","ITA","Piedmont","","23442","1380635779"
"Emporia","Emporia","38.4028","-96.1932","United States","US","USA","Kansas","","24220","1840001652"
"Middleborough","Middleborough","41.8803","-70.8745","United States","US","USA","Massachusetts","","24219","1840070288"
"North Haven","North Haven","41.3818","-72.8573","United States","US","USA","Connecticut","","24217","1840035515"
"Setti Fatma","Setti Fatma","31.2256","-7.6758","Morocco","MA","MAR","Marrakech-Safi","","24129","1504468462"
"Varel","Varel","53.3969","8.1361","Germany","DE","DEU","Lower Saxony","","24001","1276834869"
"Baraúna","Barauna","-5.0800","-37.6169","Brazil","BR","BRA","Rio Grande do Norte","","24182","1076848828"
"Krychaw","Krychaw","53.7194","31.7139","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","24170","1112203208"
"Lālgudi","Lalgudi","10.8700","78.8300","India","IN","IND","Tamil Nādu","","23740","1356119022"
"Uchquduq Shahri","Uchquduq Shahri","42.1567","63.5556","Uzbekistan","UZ","UZB","Navoiy","minor","24200","1860459881"
"Kailāshahar","Kailashahar","24.3300","92.0200","India","IN","IND","Tripura","","23418","1356074575"
"Ndora","Ndora","-2.6033","29.8336","Rwanda","RW","RWA","Southern Province","","23813","1646457755"
"Auburn Hills","Auburn Hills","42.6735","-83.2448","United States","US","USA","Michigan","","24202","1840002436"
"Tupaciguara","Tupaciguara","-18.5928","-48.7050","Brazil","BR","BRA","Minas Gerais","","24188","1076623546"
"Pingtang","Pingtang","26.0677","107.4035","China","CN","CHN","Guizhou","","24156","1156261379"
"Velingrad","Velingrad","42.0167","24.0000","Bulgaria","BG","BGR","Pazardzhik","minor","24154","1100145292"
"Kaltenkirchen","Kaltenkirchen","53.8397","9.9603","Germany","DE","DEU","Schleswig-Holstein","","23191","1276286164"
"Sarea Khās","Sarea Khas","26.6350","84.5483","India","IN","IND","Bihār","","22428","1356480085"
"Isa","Isa","32.0572","130.6131","Japan","JP","JPN","Kagoshima","","24131","1392003324"
"Saint-Louis","Saint-Louis","47.5900","7.5700","France","FR","FRA","Grand Est","","22835","1250664055"
"Huilongping","Huilongping","28.1887","106.2086","China","CN","CHN","Guizhou","","23976","1156854943"
"Westchase","Westchase","28.0597","-82.6110","United States","US","USA","Florida","","24185","1840029017"
"Angatuba","Angatuba","-23.4903","-48.4128","Brazil","BR","BRA","São Paulo","","24161","1076790515"
"Konnūr","Konnur","16.2014","74.7489","India","IN","IND","Karnātaka","","23733","1356140426"
"Quezon","Quezon","16.4894","121.2639","Philippines","PH","PHL","Nueva Vizcaya","","24055","1608056116"
"Guaiuba","Guaiuba","-4.0400","-38.6369","Brazil","BR","BRA","Ceará","","24091","1076913370"
"Bulusan","Bulusan","12.7522","124.1356","Philippines","PH","PHL","Sorsogon","","23932","1608161228"
"Magallanes","Magallanes","14.1833","120.7500","Philippines","PH","PHL","Cavite","","23851","1608040710"
"Baiheqiao","Baiheqiao","22.9764","103.7982","China","CN","CHN","Yunnan","","24113","1156362779"
"Lihe","Lihe","34.0090","105.3416","China","CN","CHN","Gansu","","23852","1156262615"
"Taupo","Taupo","-38.6875","176.0694","New Zealand","NZ","NZL","Waikato","","23622","1554654784"
"Grootfontein","Grootfontein","-19.5658","18.1036","Namibia","NA","NAM","Otjozondjupa","","24099","1516286878"
"Bedburg","Bedburg","51.0000","6.5625","Germany","DE","DEU","North Rhine-Westphalia","","23867","1276409548"
"Anjozorobe","Anjozorobe","-18.4033","47.8500","Madagascar","MG","MDG","Antananarivo","","24117","1450597043"
"Kamiamakusa","Kamiamakusa","32.5000","130.4167","Japan","JP","JPN","Kumamoto","","23973","1392003074"
"Pombos","Pombos","-8.1492","-35.4011","Brazil","BR","BRA","Pernambuco","","24046","1076143659"
"Burlington","Burlington","40.8071","-91.1247","United States","US","USA","Iowa","","24160","1840007164"
"Suonan","Suonan","35.6634","103.3923","China","CN","CHN","Gansu","minor","23499","1156421160"
"Quedlinburg","Quedlinburg","51.7917","11.1472","Germany","DE","DEU","Saxony-Anhalt","","23989","1276672547"
"Baharly","Baharly","38.4303","57.4369","Turkmenistan","TM","TKM","Ahal","","24139","1795475430"
"Rioblanco","Rioblanco","3.5000","-75.8333","Colombia","CO","COL","Tolima","minor","24139","1170893363"
"Fort Saskatchewan","Fort Saskatchewan","53.7128","-113.2133","Canada","CA","CAN","Alberta","","24149","1124769097"
"Bình Hòa","Binh Hoa","10.9353","106.8614","Vietnam","VN","VNM","Bình Dương","","23398","1704275105"
"Beni Amrane","Beni Amrane","36.6686","3.5922","Algeria","DZ","DZA","Boumerdes","","23621","1012420167"
"Los Barrios","Los Barrios","36.1833","-5.4833","Spain","ES","ESP","Andalusia","","24069","1724279475"
"Liria","Liria","39.6258","-0.5961","Spain","ES","ESP","Valencia","","24036","1724728759"
"São José da Laje","Sao Jose da Laje","-9.0100","-36.0578","Brazil","BR","BRA","Alagoas","","24048","1076054704"
"Calw","Calw","48.7167","8.7333","Germany","DE","DEU","Baden-Württemberg","minor","23742","1276416126"
"Jequitinhonha","Jequitinhonha","-16.4339","-41.0028","Brazil","BR","BRA","Minas Gerais","","24131","1076638879"
"Ambatotsipihina","Ambatotsipihina","-19.6333","47.7500","Madagascar","MG","MDG","Antananarivo","","24003","1450556369"
"Pelham","Pelham","33.3114","-86.7573","United States","US","USA","Alabama","","24134","1840014826"
"Ibicaraí","Ibicarai","-14.8650","-39.5878","Brazil","BR","BRA","Bahia","","24029","1076929128"
"Castiglione delle Stiviere","Castiglione delle Stiviere","45.4000","10.5000","Italy","IT","ITA","Lombardy","","23570","1380170299"
"Koilkuntla","Koilkuntla","15.2333","78.3167","India","IN","IND","Andhra Pradesh","","23859","1356024299"
"Lopary","Lopary","-23.1833","47.6667","Madagascar","MG","MDG","Fianarantsoa","","24000","1450854735"
"Tsiatosika","Tsiatosika","-21.2000","48.2333","Madagascar","MG","MDG","Fianarantsoa","","24000","1450500490"
"Canton","Canton","42.1750","-71.1264","United States","US","USA","Massachusetts","","24122","1840053673"
"Tegina","Tegina","10.0706","6.1906","Nigeria","NG","NGA","Niger","","24037","1566672639"
"Vänersborg","Vanersborg","58.3806","12.3250","Sweden","SE","SWE","Västra Götaland","minor","23882","1752467593"
"Elkridge","Elkridge","39.1941","-76.7428","United States","US","USA","Maryland","","24115","1840005801"
"Misato","Misato","38.5444","141.0567","Japan","JP","JPN","Miyagi","","23796","1392003276"
"Ban Phru","Ban Phru","6.9480","100.4794","Thailand","TH","THA","Songkhla","","23518","1764608441"
"Bieruń Stary","Bierun Stary","50.0897","19.0928","Poland","PL","POL","Śląskie","","23640","1616995218"
"Amstetten","Amstetten","48.1167","14.8667","Austria","AT","AUT","Niederösterreich","minor","23656","1040466200"
"Deyr","Deyr","27.8417","51.9394","Iran","IR","IRN","Būshehr","minor","24083","1364059934"
"Inhapim","Inhapim","-19.5489","-42.1200","Brazil","BR","BRA","Minas Gerais","","24079","1076403115"
"Sonneberg","Sonneberg","50.3500","11.1667","Germany","DE","DEU","Thuringia","minor","23830","1276304773"
"Yangi Marg‘ilon","Yangi Marg\`ilon","40.4311","71.7183","Uzbekistan","UZ","UZB","Farg‘ona","","23531","1860305931"
"Zaragoza","Zaragoza","7.4940","-74.8710","Colombia","CO","COL","Antioquia","minor","24067","1170606958"
"Cavinti","Cavinti","14.2450","121.5070","Philippines","PH","PHL","Laguna","","23980","1608823208"
"As Sulayyil","As Sulayyil","20.4597","45.5744","Saudi Arabia","SA","SAU","Ar Riyāḑ","","24097","1682960661"
"Mapoteng","Mapoteng","-29.1097","27.9739","Lesotho","LS","LSO","Berea","","23926","1426371012"
"Laguna Salada","Laguna Salada","19.6500","-71.0833","Dominican Republic","DO","DOM","Cibao Noroeste","","23962","1214529360"
"Itapissuma","Itapissuma","-7.7764","-34.8919","Brazil","BR","BRA","Pernambuco","","23769","1076841556"
"Rita","Rita","10.4337","-83.7530","Costa Rica","CR","CRI","Limón","","24041","1188000230"
"Badou","Badou","7.5833","0.6000","Togo","TG","TGO","Plateaux","","24000","1768728398"
"Freeport","Freeport","42.2891","-89.6346","United States","US","USA","Illinois","","24087","1840008083"
"Monsefú","Monsefu","-6.8778","-79.8719","Peru","PE","PER","Lambayeque","","23561","1604406976"
"Montecchio Maggiore","Montecchio Maggiore","45.5037","11.4120","Italy","IT","ITA","Veneto","","23316","1380200882"
"Semënov","Semenov","56.8000","44.5000","Russia","RU","RUS","Nizhegorodskaya Oblast’","","24067","1643525669"
"Hungund","Hungund","16.0621","76.0586","India","IN","IND","Karnātaka","","23808","1356140443"
"Rio Tinto","Rio Tinto","-6.8028","-35.0808","Brazil","BR","BRA","Paraíba","","24023","1076667318"
"Kerrville","Kerrville","30.0398","-99.1320","United States","US","USA","Texas","","24071","1840020908"
"Solon","Solon","41.3865","-81.4400","United States","US","USA","Ohio","","24070","1840003413"
"Marple","Marple","39.9654","-75.3657","United States","US","USA","Pennsylvania","","24070","1840100266"
"Fort Mill","Fort Mill","35.0061","-80.9389","United States","US","USA","South Carolina","","24069","1840016479"
"Ventimiglia","Ventimiglia","43.7903","7.6083","Italy","IT","ITA","Liguria","","24065","1380716377"
"Gueznaia","Gueznaia","35.7200","-5.8940","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","23601","1504255211"
"São Miguel do Guaporé","Sao Miguel do Guapore","-11.6936","-62.7114","Brazil","BR","BRA","Rondônia","","24059","1076548430"
"Pullappalli","Pullappalli","9.7133","76.6873","India","IN","IND","Kerala","","22640","1356204795"
"Hingham","Hingham","42.2176","-70.8854","United States","US","USA","Massachusetts","","24061","1840053569"
"Zawyat an Nwaçer","Zawyat an Nwacer","33.3611","-7.6114","Morocco","MA","MAR","Casablanca-Settat","","23802","1504451401"
"Pitogo","Pitogo","10.1210","124.5530","Philippines","PH","PHL","Bohol","","23625","1608889123"
"Ibi","Ibi","38.6272","-0.5753","Spain","ES","ESP","Valencia","","23677","1724086250"
"Yugawara","Yugawara","35.1479","139.1083","Japan","JP","JPN","Kanagawa","","23480","1392003107"
"Guapí","Guapi","2.5703","-77.8856","Colombia","CO","COL","Cauca","minor","24037","1170811574"
"Puerto San José","Puerto San Jose","13.9333","-90.8167","Guatemala","GT","GTM","Escuintla","","23887","1320016478"
"Wekiwa Springs","Wekiwa Springs","28.6984","-81.4251","United States","US","USA","Florida","","24049","1840029016"
"Zolochiv","Zolochiv","49.8075","24.9031","Ukraine","UA","UKR","Lvivska Oblast","minor","23986","1804914979"
"Miarinarivo","Miarinarivo","-17.6500","49.1000","Madagascar","MG","MDG","Toamasina","","24000","1450268766"
"Elgin","Elgin","57.6500","-3.3150","United Kingdom","GB","GBR","Moray","","23128","1826386055"
"Tayga","Tayga","56.0667","85.6167","Russia","RU","RUS","Kemerovskaya Oblast’","","23565","1643905675"
"Kourou","Kourou","5.1600","-52.6499","French Guiana","GF","GUF","","minor","24029","1254317561"
"Litvínov","Litvinov","50.6008","13.6112","Czechia","CZ","CZE","Ústecký Kraj","","23489","1203716170"
"Horley","Horley","51.1740","-0.1720","United Kingdom","GB","GBR","Surrey","","22076","1826232822"
"Ocara","Ocara","-4.4908","-38.5969","Brazil","BR","BRA","Ceará","","24007","1076000009"
"Ponda","Ponda","15.4034","74.0152","India","IN","IND","Goa","","23381","1356252428"
"Piracanjuba","Piracanjuba","-17.3028","-49.0178","Brazil","BR","BRA","Goiás","","24026","1076271266"
"Hoh Ereg","Hoh Ereg","41.0955","111.4408","China","CN","CHN","Inner Mongolia","minor","23776","1156060937"
"Antonibe","Antonibe","-15.1167","47.4000","Madagascar","MG","MDG","Mahajanga","","24000","1450316334"
"Kelīshād va Sūdarjān","Kelishad va Sudarjan","32.5542","51.5250","Iran","IR","IRN","Eşfahān","","23203","1364528827"
"Tangalan","Tangalan","11.7775","122.2603","Philippines","PH","PHL","Aklan","","23704","1608572229"
"Si Racha","Si Racha","13.1740","100.9300","Thailand","TH","THA","Chon Buri","minor","23400","1764003327"
"Waltham Abbey","Waltham Abbey","51.6846","0.0004","United Kingdom","GB","GBR","Essex","","22859","1826980531"
"Catigbian","Catigbian","9.8333","124.0333","Philippines","PH","PHL","Bohol","","23805","1608374807"
"Bcharré","Bcharre","34.2511","36.0111","Lebanon","LB","LBN","Liban-Nord","minor","24000","1422518205"
"North Augusta","North Augusta","33.5214","-81.9547","United States","US","USA","South Carolina","","24013","1840014788"
"Sheghnān","Sheghnan","37.6167","71.4500","Afghanistan","AF","AFG","Badakhshān","","24000","1004187710"
"Antanimieva","Antanimieva","-22.2333","43.7667","Madagascar","MG","MDG","Toliara","","24000","1450718789"
"Limbuhan","Limbuhan","11.8836","124.0497","Philippines","PH","PHL","Masbate","","23744","1608489510"
"Döbeln","Dobeln","51.1194","13.1128","Germany","DE","DEU","Saxony","","23720","1276060973"
"Oldebroek","Oldebroek","52.4500","5.9000","Netherlands","NL","NLD","Gelderland","minor","23760","1528582577"
"Pakil","Pakil","14.3833","121.4833","Philippines","PH","PHL","Laguna","","23495","1608247786"
"Tranquebar","Tranquebar","11.0292","79.8494","India","IN","IND","Tamil Nādu","","23191","1356342738"
"Allendale","Allendale","42.9845","-85.9499","United States","US","USA","Michigan","","23999","1840004345"
"Ambohimahamasina","Ambohimahamasina","-21.9333","47.1833","Madagascar","MG","MDG","Fianarantsoa","","23949","1450478240"
"Sehnde","Sehnde","52.3161","9.9642","Germany","DE","DEU","Lower Saxony","","23769","1276201782"
"Santa Apolonia","Santa Apolonia","14.7833","-90.9667","Guatemala","GT","GTM","Chimaltenango","minor","23751","1320348479"
"Bloemendaal","Bloemendaal","52.4000","4.6000","Netherlands","NL","NLD","Noord-Holland","","23478","1528509651"
"Baud","Baud","20.8333","84.3167","India","IN","IND","Odisha","","23793","1356005442"
"Quellón","Quellon","-43.0992","-73.5961","Chile","CL","CHL","Los Lagos","","23992","1152502358"
"Sulya","Sulya","12.5610","75.3874","India","IN","IND","Karnātaka","","23796","1356024884"
"East Gwillimbury","East Gwillimbury","44.1333","-79.4167","Canada","CA","CAN","Ontario","","23991","1124001370"
"Farmington","Farmington","40.9845","-111.9065","United States","US","USA","Utah","","23990","1840020142"
"Macaparana","Macaparana","-7.5550","-35.4528","Brazil","BR","BRA","Pernambuco","","23925","1076657327"
"Bagulā","Bagula","23.3350","88.6440","India","IN","IND","West Bengal","","22649","1356186106"
"Lisle","Lisle","41.7918","-88.0888","United States","US","USA","Illinois","","23982","1840011401"
"Lalganj","Lalganj","26.1277","80.7855","India","IN","IND","Uttar Pradesh","","23124","1356366149"
"Fairfax","Fairfax","38.8531","-77.2997","United States","US","USA","Virginia","","23980","1840001650"
"Perungudi","Perungudi","8.2792","77.6031","India","IN","IND","Tamil Nādu","","23579","1356205314"
"Tecoluca","Tecoluca","13.5333","-88.7833","El Salvador","SV","SLV","San Vicente","","23893","1222261273"
"Stepanavan","Stepanavan","41.0096","44.3841","Armenia","AM","ARM","Lorri","","23782","1051221961"
"Três Coroas","Tres Coroas","-29.5169","-50.7778","Brazil","BR","BRA","Rio Grande do Sul","","23848","1076520525"
"Freudenstadt","Freudenstadt","48.4633","8.4111","Germany","DE","DEU","Baden-Württemberg","minor","23704","1276092007"
"Kafr Sa‘d","Kafr Sa\`d","31.3594","31.6865","Egypt","EG","EGY","Dumyāţ","","22500","1818266119"
"El Zulia","El Zulia","7.9333","-72.6000","Colombia","CO","COL","Norte de Santander","minor","23921","1170321590"
"Kingston","Kingston","41.9295","-73.9968","United States","US","USA","New York","","23972","1840000493"
"Embarcación","Embarcacion","-23.2167","-64.1000","Argentina","AR","ARG","Salta","","23964","1032320491"
"Paxtakor Shahri","Paxtakor Shahri","40.3153","67.9544","Uzbekistan","UZ","UZB","Jizzax","minor","23900","1860664894"
"Plainsboro","Plainsboro","40.3377","-74.5879","United States","US","USA","New Jersey","","23966","1840081691"
"Aldeias Altas","Aldeias Altas","-4.6278","-43.4708","Brazil","BR","BRA","Maranhão","","23952","1076098052"
"Hollola","Hollola","60.9886","25.5128","Finland","FI","FIN","Päijät-Häme","minor","23915","1246266596"
"La Maná","La Mana","-0.9300","-79.2200","Ecuador","EC","ECU","Cotopaxi","","23775","1218200586"
"Wajimazakimachi","Wajimazakimachi","37.3906","136.8992","Japan","JP","JPN","Ishikawa","","23906","1392449990"
"Hopa","Hopa","41.3903","41.4194","Turkey","TR","TUR","Artvin","minor","23846","1792630144"
"Kalach-na-Donu","Kalach-na-Donu","48.7000","43.5167","Russia","RU","RUS","Volgogradskaya Oblast’","","23947","1643990894"
"Anchieta","Anchieta","-20.8056","-40.6444","Brazil","BR","BRA","Espírito Santo","","23902","1076302227"
"Biancavilla","Biancavilla","37.6500","14.8667","Italy","IT","ITA","Sicilia","","23948","1380858870"
"Rodniki","Rodniki","57.1167","41.7333","Russia","RU","RUS","Ivanovskaya Oblast’","","23924","1643416659"
"Columbus","Columbus","41.4366","-97.3565","United States","US","USA","Nebraska","","23954","1840003345"
"São Gonçalo do Sapucaí","Sao Goncalo do Sapucai","-21.8919","-45.5950","Brazil","BR","BRA","Minas Gerais","","23906","1076119475"
"Laje","Laje","-13.1819","-39.4250","Brazil","BR","BRA","Bahia","","23904","1076078373"
"Cambará","Cambara","-23.0458","-50.0739","Brazil","BR","BRA","Paraná","","23886","1076033204"
"Loon op Zand","Loon op Zand","51.6667","5.0333","Netherlands","NL","NLD","Noord-Brabant","","23504","1528312573"
"El Tejar","El Tejar","14.6500","-90.8000","Guatemala","GT","GTM","Chimaltenango","minor","23780","1320344068"
"Três Passos","Tres Passos","-27.4558","-53.9319","Brazil","BR","BRA","Rio Grande do Sul","","23852","1076641723"
"Jüchen","Juchen","51.1011","6.5017","Germany","DE","DEU","North Rhine-Westphalia","","23611","1276318248"
"Maldegem","Maldegem","51.2000","3.4333","Belgium","BE","BEL","Flanders","","23689","1056438653"
"Nanxingguo","Nanxingguo","37.6306","114.4857","China","CN","CHN","Hebei","","23623","1156910412"
"Gungu","Gungu","-5.7333","19.3167","Congo (Kinshasa)","CD","COD","Kwilu","","23893","1180524206"
"Carás","Caras","-9.0472","-77.7433","Peru","PE","PER","Ancash","","23841","1604139629"
"Akwatia","Akwatia","6.0500","-0.8000","Ghana","GH","GHA","Eastern","","23766","1288226088"
"Três de Maio","Tres de Maio","-27.7728","-54.2400","Brazil","BR","BRA","Rio Grande do Sul","","23876","1076340947"
"Bogotol","Bogotol","56.2000","89.5167","Russia","RU","RUS","Krasnoyarskiy Kray","","23622","1643208513"
"Burgos","Burgos","16.0465","119.8568","Philippines","PH","PHL","Pangasinan","","23749","1608000110"
"Bhawānīgarh","Bhawanigarh","30.2700","76.0400","India","IN","IND","Punjab","","23485","1356822025"
"Garmdarreh","Garmdarreh","35.7642","51.0692","Iran","IR","IRN","Alborz","","22726","1364748189"
"Capoterra","Capoterra","39.1744","8.9711","Italy","IT","ITA","Sardegna","","23583","1380097598"
"Quesada","Quesada","14.2723","-90.0373","Guatemala","GT","GTM","Jutiapa","minor","23822","1320537682"
"Lubliniec","Lubliniec","50.6833","18.6833","Poland","PL","POL","Śląskie","minor","23660","1616573478"
"Rajpur","Rajpur","21.9402","75.1361","India","IN","IND","Madhya Pradesh","","23645","1356678902"
"Kalawit","Kalawit","7.9051","122.5279","Philippines","PH","PHL","Zamboanga del Norte","","23812","1608843662"
"Fresno","Fresno","29.5357","-95.4696","United States","US","USA","Texas","","23921","1840018266"
"Dongsheng","Dongsheng","36.9996","105.0029","China","CN","CHN","Gansu","","23809","1156107612"
"Andoany","Andoany","-13.4000","48.2666","Madagascar","MG","MDG","Antsiranana","","22680","1450689047"
"Ringsted","Ringsted","55.4425","11.7900","Denmark","DK","DNK","Sjælland","minor","23086","1208027536"
"Ikeda","Ikeda","35.4423","136.5730","Japan","JP","JPN","Gifu","","23310","1392003225"
"Bazhajiemicun","Bazhajiemicun","38.8967","77.6529","China","CN","CHN","Xinjiang","","23308","1156091216"
"Hadžići","Hadzici","43.8217","18.2017","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","23891","1070884568"
"Honda","Honda","5.2042","-74.7417","Colombia","CO","COL","Tolima","minor","23830","1170848069"
"Monte Aprazível","Monte Aprazivel","-20.7728","-49.7139","Brazil","BR","BRA","São Paulo","","23857","1076150563"
"Sacramento","Sacramento","-19.8650","-47.4400","Brazil","BR","BRA","Minas Gerais","","23896","1076384537"
"Jawor","Jawor","51.0500","16.2000","Poland","PL","POL","Dolnośląskie","minor","22758","1616434559"
"Acton","Acton","42.4843","-71.4378","United States","US","USA","Massachusetts","","23899","1840053471"
"Majdel Aanjar","Majdel Aanjar","33.7075","35.9039","Lebanon","LB","LBN","Béqaa","","23000","1422228495"
"Khenichet-sur Ouerrha","Khenichet-sur Ouerrha","34.4333","-5.6667","Morocco","MA","MAR","Rabat-Salé-Kénitra","","23707","1504817862"
"Barnegat","Barnegat","39.7668","-74.2776","United States","US","USA","New Jersey","","23894","1840081601"
"Chinoz","Chinoz","40.9375","68.7578","Uzbekistan","UZ","UZB","Toshkent","","23700","1860346487"
"San Miguel Ocotenco","San Miguel Ocotenco","18.9894","-97.4470","Mexico","MX","MEX","Puebla","","23824","1484616570"
"Sokolov","Sokolov","50.1814","12.6402","Czechia","CZ","CZE","Karlovarský Kraj","","22924","1203662180"
"Mirandela","Mirandela","41.4853","-7.1817","Portugal","PT","PRT","Bragança","minor","23850","1620845288"
"Alice Springs","Alice Springs","-23.7000","133.8667","Australia","AU","AUS","Northern Territory","","23726","1036830397"
"Jurh","Jurh","44.6961","120.5123","China","CN","CHN","Inner Mongolia","","23842","1156183699"
"Starnberg","Starnberg","47.9972","11.3406","Germany","DE","DEU","Bavaria","minor","23498","1276454637"
"Champlin","Champlin","45.1702","-93.3903","United States","US","USA","Minnesota","","23877","1840006746"
"Oostkamp","Oostkamp","51.1544","3.2353","Belgium","BE","BEL","Flanders","","23580","1056412742"
"Papillion","Papillion","41.1511","-96.0665","United States","US","USA","Nebraska","","23875","1840009328"
"Douar Ouled Ayad","Douar Ouled Ayad","32.4167","-7.1000","Morocco","MA","MAR","Casablanca-Settat","","23818","1504223555"
"Candoni","Candoni","9.8167","122.6000","Philippines","PH","PHL","Negros Occidental","","23751","1608991604"
"Tecolutla","Tecolutla","20.4797","-97.0100","Mexico","MX","MEX","Veracruz","minor","23865","1484719991"
"Gaojiayingcun","Gaojiayingcun","40.8814","114.9662","China","CN","CHN","Hebei","","23784","1156556102"
"Palu","Palu","38.7039","39.9511","Turkey","TR","TUR","Elazığ","minor","23834","1792036043"
"Zemst","Zemst","50.9722","4.4222","Belgium","BE","BEL","Flanders","","23325","1056199870"
"Nyūzen","Nyuzen","36.9335","137.5021","Japan","JP","JPN","Toyama","","23537","1392959152"
"Ibiapina","Ibiapina","-3.9228","-40.8889","Brazil","BR","BRA","Ceará","","23808","1076549899"
"Makhmūr","Makhmur","35.7756","43.5794","Iraq","IQ","IRQ","Arbīl","minor","23828","1368589887"
"Saint-Dizier","Saint-Dizier","48.6383","4.9497","France","FR","FRA","Grand Est","minor","23382","1250642464"
"Columbus","Columbus","33.5088","-88.4096","United States","US","USA","Mississippi","","23858","1840014807"
"Pedra Azul","Pedra Azul","-16.0050","-41.2969","Brazil","BR","BRA","Minas Gerais","","23839","1076967714"
"Zeya","Zeya","53.7333","127.2500","Russia","RU","RUS","Amurskaya Oblast’","","23270","1643518761"
"Upper Providence","Upper Providence","40.1654","-75.4888","United States","US","USA","Pennsylvania","","23851","1840145447"
"Popovo","Popovo","43.3500","26.2333","Bulgaria","BG","BGR","Targovishte","","23819","1100589134"
"Abbeville","Abbeville","50.1058","1.8358","France","FR","FRA","Hauts-de-France","minor","22980","1250376759"
"Kasongo-Lunda","Kasongo-Lunda","-6.4783","16.8166","Congo (Kinshasa)","CD","COD","Kwango","","23820","1180706751"
"Lustenau","Lustenau","47.4271","9.6711","Austria","AT","AUT","Vorarlberg","","22821","1040745157"
"Hillegom","Hillegom","52.2833","4.5833","Netherlands","NL","NLD","Zuid-Holland","minor","22197","1528098877"
"Berehove","Berehove","48.2025","22.6375","Ukraine","UA","UKR","Zakarpatska Oblast","minor","23732","1804040318"
"Quartier Militaire","Quartier Militaire","-20.2500","57.5500","Mauritius","MU","MUS","Moka","","22745","1480030855"
"Alexânia","Alexania","-16.0819","-48.5069","Brazil","BR","BRA","Goiás","","23814","1076561964"
"King of Prussia","King of Prussia","40.0963","-75.3821","United States","US","USA","Pennsylvania","","23841","1840005464"
"Horgen","Horgen","47.2667","8.6000","Switzerland","CH","CHE","Zürich","","23090","1756066280"
"Vélingara","Velingara","13.1500","-14.1167","Senegal","SN","SEN","Kolda","","23775","1686799000"
"Kenilworth","Kenilworth","52.3410","-1.5660","United Kingdom","GB","GBR","Warwickshire","","22538","1826857178"
"Jucás","Jucas","-6.5250","-39.5278","Brazil","BR","BRA","Ceará","","23807","1076754978"
"Belton","Belton","38.8192","-94.5335","United States","US","USA","Missouri","","23832","1840007463"
"Hirpardangal","Hirpardangal","23.8200","86.9000","India","IN","IND","Jhārkhand","","22599","1356189450"
"Takehara","Takehara","34.3333","132.9167","Japan","JP","JPN","Hiroshima","","23631","1392241539"
"El Maknassi","El Maknassi","34.6042","9.6056","Tunisia","TN","TUN","Sidi Bouzid","","23789","1788035263"
"Padre Burgos","Padre Burgos","13.9226","121.8116","Philippines","PH","PHL","Quezon","minor","23488","1608396818"
"Grande-Synthe","Grande-Synthe","51.0139","2.3028","France","FR","FRA","Hauts-de-France","","22777","1250318038"
"Lutz","Lutz","28.1396","-82.4467","United States","US","USA","Florida","","23827","1840014156"
"Chrudim","Chrudim","49.9511","15.7956","Czechia","CZ","CZE","Pardubický Kraj","","23140","1203309920"
"Baliguian","Baliguian","7.8088","122.1452","Philippines","PH","PHL","Zamboanga del Norte","","23771","1608476966"
"Cranford","Cranford","40.6564","-74.3036","United States","US","USA","New Jersey","","23823","1840081744"
"Mirandola","Mirandola","44.8873","11.0660","Italy","IT","ITA","Emilia-Romagna","","23650","1380556207"
"Webster Groves","Webster Groves","38.5866","-90.3544","United States","US","USA","Missouri","","23817","1840010744"
"Ramos","Ramos","15.6667","120.6417","Philippines","PH","PHL","Tarlac","","22879","1608310757"
"Yesagyo","Yesagyo","21.6333","95.2414","Myanmar","MM","MMR","Magway","","23603","1104030863"
"San José","San Jose","1.4744","-77.0836","Colombia","CO","COL","Nariño","minor","23279","1170922261"
"Makouda","Makouda","36.7909","4.0669","Algeria","DZ","DZA","Boumerdes","","23388","1012786926"
"Manuel B. Gonnet","Manuel B. Gonnet","-34.8500","-58.0167","Argentina","AR","ARG","Buenos Aires","","22963","1032782726"
"Nísia Floresta","Nisia Floresta","-6.0908","-35.2089","Brazil","BR","BRA","Rio Grande do Norte","","23784","1076637133"
"Cockeysville","Cockeysville","39.4804","-76.6294","United States","US","USA","Maryland","","23803","1840005680"
"Wandlitz","Wandlitz","52.7500","13.4497","Germany","DE","DEU","Brandenburg","","23657","1276059066"
"Brody","Brody","50.0781","25.1542","Ukraine","UA","UKR","Lvivska Oblast","","23752","1804538798"
"Ostrów Mazowiecka","Ostrow Mazowiecka","52.8000","21.9000","Poland","PL","POL","Mazowieckie","minor","22800","1616194953"
"Ituango","Ituango","7.1667","-75.7500","Colombia","CO","COL","Antioquia","minor","23784","1170490299"
"Auburn","Auburn","44.0851","-70.2492","United States","US","USA","Maine","","23790","1840000319"
"Wadsworth","Wadsworth","41.0279","-81.7323","United States","US","USA","Ohio","","23789","1840010294"
"Lincoln","Lincoln","43.1300","-79.4300","Canada","CA","CAN","Ontario","","23787","1124001767"
"Elburg","Elburg","52.4500","5.8500","Netherlands","NL","NLD","Gelderland","minor","23429","1528998186"
"Mandan","Mandan","46.8290","-100.8870","United States","US","USA","North Dakota","","23784","1840000199"
"Honāvar","Honavar","14.2809","74.4450","India","IN","IND","Karnātaka","","23527","1356841107"
"Oulad Hassoune","Oulad Hassoune","31.6503","-7.8361","Morocco","MA","MAR","Marrakech-Safi","","23475","1504841903"
"Tata","Tata","47.6526","18.3238","Hungary","HU","HUN","Komárom-Esztergom","minor","23479","1348967161"
"Dunajská Streda","Dunajska Streda","47.9944","17.6194","Slovakia","SK","SVK","Trnava","minor","23044","1703872671"
"Qifţ","Qift","25.9956","32.8158","Egypt","EG","EGY","Qinā","","22063","1818432841"
"Nový Jičín","Novy Jicin","49.5944","18.0103","Czechia","CZ","CZE","Moravskoslezský Kraj","","23151","1203475212"
"Mosbach","Mosbach","49.3522","9.1467","Germany","DE","DEU","Baden-Württemberg","minor","23398","1276659317"
"Belmonte","Belmonte","-15.8628","-38.8828","Brazil","BR","BRA","Bahia","","23759","1076000416"
"Kuvandyk","Kuvandyk","51.4667","57.3667","Russia","RU","RUS","Orenburgskaya Oblast’","","23763","1643197108"
"Hachimantai","Hachimantai","39.9561","141.0711","Japan","JP","JPN","Iwate","","23742","1392427072"
"Korbach","Korbach","51.2833","8.8667","Germany","DE","DEU","Hesse","minor","23581","1276822678"
"Mansfield","Mansfield","42.0163","-71.2187","United States","US","USA","Massachusetts","","23768","1840070235"
"Burlington","Burlington","40.0641","-74.8394","United States","US","USA","New Jersey","","23768","1840001477"
"Cuyo","Cuyo","10.8500","121.0167","Philippines","PH","PHL","Palawan","","23489","1608896568"
"Kuna","Kuna","43.4880","-116.3933","United States","US","USA","Idaho","","23765","1840020047"
"Fria","Fria","10.3804","-13.5500","Guinea","GN","GIN","Boké","minor","23729","1324583144"
"Ceccano","Ceccano","41.5667","13.3333","Italy","IT","ITA","Lazio","","23380","1380744061"
"Conil de la Frontera","Conil de la Frontera","36.2667","-6.0833","Spain","ES","ESP","Andalusia","","23497","1724051704"
"Maracás","Maracas","-13.4408","-40.4308","Brazil","BR","BRA","Bahia","","23751","1076077347"
"Samrāla","Samrala","30.8360","76.1932","India","IN","IND","Punjab","","23218","1356562174"
"Tecali","Tecali","18.9000","-97.9833","Mexico","MX","MEX","Puebla","minor","23625","1484035847"
"Luninyets","Luninyets","52.2500","26.8000","Belarus","BY","BLR","Brestskaya Voblasts’","minor","23734","1112500648"
"Muscatine","Muscatine","41.4195","-91.0680","United States","US","USA","Iowa","","23759","1840008202"
"Funes","Funes","-32.9167","-60.8167","Argentina","AR","ARG","Santa Fe","","23520","1032992183"
"Dalyoni Bolo","Dalyoni Bolo","39.7422","68.9944","Tajikistan","TJ","TJK","Sughd","","23670","1762004498"
"Frontignan","Frontignan","43.4483","3.7561","France","FR","FRA","Occitanie","","23028","1250857388"
"Willoughby","Willoughby","41.6459","-81.4084","United States","US","USA","Ohio","","23753","1840000547"
"Bariārpur","Bariarpur","25.2885","86.5758","India","IN","IND","Bihār","","22853","1356237187"
"Santa Lucía Utatlán","Santa Lucia Utatlan","14.7667","-91.2667","Guatemala","GT","GTM","Sololá","minor","23255","1320410768"
"South Elgin","South Elgin","41.9906","-88.3135","United States","US","USA","Illinois","","23749","1840013564"
"Tadotsu","Tadotsu","34.2729","133.7586","Japan","JP","JPN","Kagawa","","22813","1392003312"
"Herdecke","Herdecke","51.4000","7.4333","Germany","DE","DEU","North Rhine-Westphalia","","22733","1276078079"
"Ayancık","Ayancik","41.9500","34.5833","Turkey","TR","TUR","Sinop","minor","23720","1792845166"
"Raul Soares","Raul Soares","-20.1019","-42.4528","Brazil","BR","BRA","Minas Gerais","","23711","1076014594"
"Icod de los Vinos","Icod de los Vinos","28.3500","-16.7000","Spain","ES","ESP","Canary Islands","","23496","1724332990"
"Tatarsk","Tatarsk","55.2500","75.9833","Russia","RU","RUS","Novosibirskaya Oblast’","","23729","1643001589"
"Eisenhüttenstadt","Eisenhuttenstadt","52.1450","14.6728","Germany","DE","DEU","Brandenburg","","23373","1276902561"
"Gelnhausen","Gelnhausen","50.2000","9.1667","Germany","DE","DEU","Hesse","","23220","1276351264"
"Kedia","Kedia","23.7945","85.5872","India","IN","IND","Jhārkhand","","23213","1356181237"
"Vilaseca de Solcina","Vilaseca de Solcina","41.1110","1.1450","Spain","ES","ESP","Catalonia","","22681","1724103527"
"Salzwedel","Salzwedel","52.8500","11.1500","Germany","DE","DEU","Saxony-Anhalt","minor","23655","1276222011"
"Ayt Mohamed","Ayt Mohamed","32.5667","-6.9833","Morocco","MA","MAR","Béni Mellal-Khénifra","","23696","1504703331"
"Metekora","Metekora","22.1881","88.5973","India","IN","IND","West Bengal","","22225","1356023990"
"Lebu","Lebu","-37.6000","-73.6667","Chile","CL","CHL","Biobío","minor","23722","1152064207"
"Soledad Atzompa","Soledad Atzompa","18.7550","-97.1522","Mexico","MX","MEX","Veracruz","minor","23130","1484642759"
"Yellāpur","Yellapur","14.9637","74.7093","India","IN","IND","Karnātaka","","23658","1356002309"
"Toda Rai Singh","Toda Rai Singh","26.0167","75.4833","India","IN","IND","Rājasthān","","23559","1356092474"
"Beni Khalled","Beni Khalled","36.6500","10.6000","Tunisia","TN","TUN","Nabeul","","23356","1788038432"
"Khromtaū","Khromtau","50.2503","58.4347","Kazakhstan","KZ","KAZ","","","23715","1398766984"
"Munster","Munster","41.5468","-87.5040","United States","US","USA","Indiana","","23717","1840009262"
"Alfreton","Alfreton","53.0970","-1.3800","United Kingdom","GB","GBR","Derbyshire","","22302","1826000016"
"San Juan Cotzocón","San Juan Cotzocon","17.1667","-95.7833","Mexico","MX","MEX","Oaxaca","","23686","1484942065"
"Badnāwar","Badnawar","23.0218","75.2327","India","IN","IND","Madhya Pradesh","","23424","1356417675"
"San Lorenzo","San Lorenzo","12.3783","-85.6661","Nicaragua","NI","NIC","Boaco","minor","23666","1558007382"
"Bauta","Bauta","22.9919","-82.5492","Cuba","CU","CUB","Artemisa","minor","23557","1192335041"
"Kreuzlingen","Kreuzlingen","47.6333","9.1667","Switzerland","CH","CHE","Thurgau","minor","21801","1756463563"
"Yukon","Yukon","35.5201","-97.7639","United States","US","USA","Oklahoma","","23697","1840021740"
"Highland","Highland","41.5483","-87.4588","United States","US","USA","Indiana","","23695","1840010226"
"Sarai Ranjan","Sarai Ranjan","25.7671","85.7245","India","IN","IND","Bihār","","21747","1356097254"
"Miantso","Miantso","-18.7167","47.1500","Madagascar","MG","MDG","Antananarivo","","23691","1450952879"
"Vadakarai Kīl Pidāgai","Vadakarai Kil Pidagai","9.0401","77.2741","India","IN","IND","Tamil Nādu","","22900","1356212260"
"Santo Antônio do Içá","Santo Antonio do Ica","-3.1019","-67.9400","Brazil","BR","BRA","Amazonas","","23688","1076789719"
"Mandāwa","Mandawa","28.0500","75.1500","India","IN","IND","Rājasthān","","23335","1356190708"
"Bonoufla","Bonoufla","7.1333","-6.4833","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","","23467","1384583177"
"Ravulapalem","Ravulapalem","16.7530","81.8320","India","IN","IND","Andhra Pradesh","","23142","1356121536"
"Qādirganj","Qadirganj","24.9138","85.5926","India","IN","IND","Bihār","","21692","1356603581"
"Płońsk","Plonsk","52.6333","20.3833","Poland","PL","POL","Mazowieckie","minor","21800","1616662594"
"Altea","Altea","38.5986","-0.0519","Spain","ES","ESP","Valencia","","23010","1724509426"
"Corinto","Corinto","-18.3808","-44.4558","Brazil","BR","BRA","Minas Gerais","","23668","1076610293"
"Saginaw","Saginaw","32.8657","-97.3654","United States","US","USA","Texas","","23676","1840022046"
"Sredneuralsk","Sredneuralsk","56.9833","60.4667","Russia","RU","RUS","Sverdlovskaya Oblast’","","23353","1643770147"
"Gitega","Gitega","-3.4260","29.8436","Burundi","BI","BDI","Mwaro","","23167","1108962433"
"Quảng Trị","Quang Tri","16.7469","107.1940","Vietnam","VN","VNM","Quảng Trị","","23356","1704089491"
"Saint-Ghislain","Saint-Ghislain","50.4500","3.8167","Belgium","BE","BEL","Wallonia","","23335","1056129350"
"Pokhuria","Pokhuria","25.1307","88.0375","India","IN","IND","Jhārkhand","","22550","1356874180"
"Koziatyn","Koziatyn","49.7167","28.8333","Ukraine","UA","UKR","Vinnytska Oblast","minor","23610","1804731678"
"Bulle","Bulle","46.6167","7.0500","Switzerland","CH","CHE","Fribourg","","22709","1756347311"
"Poggiomarino","Poggiomarino","40.8000","14.5500","Italy","IT","ITA","Campania","","21993","1380287017"
"Carletonville","Carletonville","-26.3581","27.3981","South Africa","ZA","ZAF","Gauteng","","23000","1710257170"
"Qianmotou","Qianmotou","37.7952","115.4905","China","CN","CHN","Hebei","","23282","1156152569"
"Thiotte","Thiotte","18.2500","-71.8500","Haiti","HT","HTI","Sud-Est","","23041","1332831508"
"Zheleznogorsk-Ilimskiy","Zheleznogorsk-Ilimskiy","56.5833","104.1333","Russia","RU","RUS","Irkutskaya Oblast’","","23643","1643429689"
"Dillenburg","Dillenburg","50.7333","8.2833","Germany","DE","DEU","Hesse","","23365","1276583007"
"Krnov","Krnov","50.0906","17.6986","Czechia","CZ","CZE","Moravskoslezský Kraj","","23130","1203745289"
"Leutkirch im Allgäu","Leutkirch im Allgau","47.8256","10.0222","Germany","DE","DEU","Baden-Württemberg","","23509","1276589635"
"Fountain Hills","Fountain Hills","33.6073","-111.7398","United States","US","USA","Arizona","","23639","1840021945"
"Lentini","Lentini","37.2833","15.0000","Italy","IT","ITA","Sicilia","","23526","1380237919"
"Fucecchio","Fucecchio","43.7333","10.8000","Italy","IT","ITA","Tuscany","","23275","1380960076"
"Lupeni","Lupeni","45.3603","23.2383","Romania","RO","ROU","Hunedoara","","23390","1642534540"
"Lermontov","Lermontov","44.1167","42.9833","Russia","RU","RUS","Stavropol’skiy Kray","","22891","1643313776"
"Nova Milanese","Nova Milanese","45.5833","9.2000","Italy","IT","ITA","Lombardy","","23334","1380767371"
"Benemérito","Benemerito","16.3333","-90.5833","Mexico","MX","MEX","Chiapas","minor","23603","1484889494"
"Achhnera","Achhnera","27.1800","77.7700","India","IN","IND","Uttar Pradesh","","22781","1356781878"
"Caba","Caba","16.4316","120.3446","Philippines","PH","PHL","La Union","","23119","1608755935"
"Burhar","Burhar","23.2149","81.5320","India","IN","IND","Madhya Pradesh","","23395","1356555241"
"Kyeintali","Kyeintali","18.0061","94.4900","Myanmar","MM","MMR","Rakhine State","","23581","1104621866"
"Princesa Isabel","Princesa Isabel","-7.7369","-37.9928","Brazil","BR","BRA","Paraíba","","23549","1076459483"
"Rāmpura","Rampura","24.4670","75.4400","India","IN","IND","Madhya Pradesh","","23439","1356066695"
"Lubartów","Lubartow","51.4667","22.6000","Poland","PL","POL","Lubelskie","minor","22138","1616620144"
"Partizánske","Partizanske","48.6258","18.3728","Slovakia","SK","SVK","Nitra","minor","22653","1703749885"
"Cupira","Cupira","-8.6169","-35.9500","Brazil","BR","BRA","Pernambuco","","23390","1076298613"
"Havlíčkŭv Brod","Havlickuv Brod","49.6078","15.5808","Czechia","CZ","CZE","Vysočina","","23255","1203272361"
"Kulgam","Kulgam","33.6400","75.0200","India","IN","IND","Jammu and Kashmīr","","23584","1356729904"
"Káto Polemídia","Kato Polemidia","34.6931","32.9992","Cyprus","CY","CYP","Lemesós","","22369","1196458883"
"Sobradinho","Sobradinho","-9.4550","-40.8228","Brazil","BR","BRA","Bahia","","23583","1076713074"
"Aşkale","Askale","39.9211","40.6947","Turkey","TR","TUR","Erzurum","minor","23589","1792986151"
"Ipatovo","Ipatovo","45.7167","42.9000","Russia","RU","RUS","Stavropol’skiy Kray","","23579","1643097291"
"Urucurituba","Urucurituba","-3.1308","-58.1550","Brazil","BR","BRA","Amazonas","","23585","1076213551"
"Bronnitsy","Bronnitsy","55.4261","38.2650","Russia","RU","RUS","Moskovskaya Oblast’","","22567","1643641489"
"Severobaykalsk","Severobaykalsk","55.6500","109.3167","Russia","RU","RUS","Buryatiya","","23365","1643130007"
"Xiaping","Xiaping","24.6168","112.5945","China","CN","CHN","Guangdong","","23536","1156076147"
"Mallasamudram","Mallasamudram","11.4933","78.0312","India","IN","IND","Tamil Nādu","","22678","1356123568"
"Parsāgarhi","Parsagarhi","26.0935","87.0672","India","IN","IND","Bihār","","22057","1356182729"
"Caculé","Cacule","-14.5028","-42.2219","Brazil","BR","BRA","Bahia","","23545","1076086247"
"Westerstede","Westerstede","53.2500","7.9167","Germany","DE","DEU","Lower Saxony","minor","23452","1276738053"
"Unecha","Unecha","52.8461","32.6767","Russia","RU","RUS","Bryanskaya Oblast’","","23550","1643789718"
"Dumaran","Dumaran","10.5333","119.7667","Philippines","PH","PHL","Palawan","","23528","1608027520"
"Zărneşti","Zarnesti","45.5667","25.3333","Romania","RO","ROU","Braşov","","23476","1642971107"
"Canalete","Canalete","8.7900","-76.2411","Colombia","CO","COL","Córdoba","minor","23520","1170068172"
"Neusäß","Neusass","48.4000","10.8333","Germany","DE","DEU","Bavaria","","22676","1276057547"
"San Felíu de Guixols","San Feliu de Guixols","41.7806","3.0306","Spain","ES","ESP","Catalonia","","22210","1724736863"
"Alcalá","Alcala","4.6667","-75.7500","Colombia","CO","COL","Valle del Cauca","minor","23195","1170054347"
"Ādampur","Adampur","31.4322","75.7148","India","IN","IND","Punjab","","22053","1356184220"
"Southold","Southold","41.0432","-72.4185","United States","US","USA","New York","","23571","1840058507"
"San Ignacio de Velasco","San Ignacio de Velasco","-16.3667","-60.9500","Bolivia","BO","BOL","Santa Cruz","","23569","1068082737"
"Gamay","Gamay","12.3833","125.3000","Philippines","PH","PHL","Northern Samar","","23367","1608289207"
"Maibog","Maibog","10.1500","125.0000","Philippines","PH","PHL","Southern Leyte","","23256","1608524831"
"São Sepé","Sao Sepe","-30.1608","-53.5650","Brazil","BR","BRA","Rio Grande do Sul","","23555","1076826234"
"Wallenhorst","Wallenhorst","52.3500","8.0167","Germany","DE","DEU","Lower Saxony","","23081","1276580914"
"El Paso","El Paso","9.6622","-73.7519","Colombia","CO","COL","Cesar","minor","23534","1170404892"
"Gerede","Gerede","40.8006","32.1986","Turkey","TR","TUR","Bolu","minor","23547","1792755001"
"Bad Salzungen","Bad Salzungen","50.8117","10.2333","Germany","DE","DEU","Thuringia","minor","22976","1276249222"
"Chantilly","Chantilly","38.8868","-77.4453","United States","US","USA","Virginia","","23564","1840006016"
"West Springfield","West Springfield","38.7771","-77.2268","United States","US","USA","Virginia","","23562","1840006041"
"Montgomery","Montgomery","40.4260","-74.6791","United States","US","USA","New Jersey","","23557","1840081698"
"Pesochin","Pesochin","49.9539","36.1092","Ukraine","UA","UKR","Kharkivska Oblast","","23414","1804680478"
"Kenmore","Kenmore","47.7516","-122.2489","United States","US","USA","Washington","","23556","1840018423"
"Nagla","Nagla","29.0100","79.5100","India","IN","IND","Uttarākhand","","22258","1356327557"
"Floridia","Floridia","37.0833","15.1500","Italy","IT","ITA","Sicilia","","22694","1380210338"
"Roseburg","Roseburg","43.2231","-123.3520","United States","US","USA","Oregon","","23551","1840020034"
"Dongshi","Dongshi","23.4748","120.1769","Taiwan","TW","TWN","Chiayi","","23327","1158540364"
"Sonāri","Sonari","27.0246","95.0163","India","IN","IND","Assam","","23102","1356530240"
"Ruwa","Ruwa","-17.8897","31.2447","Zimbabwe","ZW","ZWE","Mashonaland East","","22038","1716489136"
"Tugatog","Tugatog","14.6629","120.9690","Philippines","PH","PHL","Malabon","","23296","1608956003"
"Brodósqui","Brodosqui","-20.9908","-47.6589","Brazil","BR","BRA","São Paulo","","23460","1076955012"
"North Platte","North Platte","41.1263","-100.7641","United States","US","USA","Nebraska","","23543","1840008251"
"Bni Frassen","Bni Frassen","34.3833","-4.3833","Morocco","MA","MAR","Fès-Meknès","","23429","1504574079"
"Kurugodu","Kurugodu","15.3460","76.8360","India","IN","IND","Karnātaka","","22940","1356352581"
"Ron","Ron","15.6700","75.7300","India","IN","IND","Karnātaka","","23311","1356685730"
"Elektrogorsk","Elektrogorsk","55.8833","38.8000","Russia","RU","RUS","Moskovskaya Oblast’","","22950","1643526255"
"Karahrūd","Karahrud","34.0600","49.6439","Iran","IR","IRN","Markazī","","23399","1364671095"
"Ahualulco de Mercado","Ahualulco de Mercado","20.6992","-103.9556","Mexico","MX","MEX","Jalisco","minor","23362","1484137273"
"Cataño","Catano","18.4375","-66.1440","Puerto Rico","PR","PRI","Puerto Rico","","23536","1630023554"
"Frederikshavn","Frederikshavn","57.4410","10.5340","Denmark","DK","DNK","Nordjylland","minor","23501","1208910213"
"Santo Tomás","Santo Tomas","10.7667","-74.9167","Colombia","CO","COL","Atlántico","minor","23188","1170972056"
"Miyoshi","Miyoshi","34.0260","133.8072","Japan","JP","JPN","Tokushima","","23500","1392003203"
"Maigo","Maigo","8.1500","123.9667","Philippines","PH","PHL","Lanao del Norte","","23337","1608670705"
"Mégara","Megara","37.9964","23.3444","Greece","GR","GRC","Attikí","minor","23456","1300371579"
"Ribas do Rio Pardo","Ribas do Rio Pardo","-20.4428","-53.7589","Brazil","BR","BRA","Mato Grosso do Sul","","23526","1076404625"
"Vega Baja","Vega Baja","18.4406","-66.3997","Puerto Rico","PR","PRI","Puerto Rico","","23527","1630023572"
"Pace","Pace","30.6188","-87.1667","United States","US","USA","Florida","","23527","1840013919"
"Nagykőrös","Nagykoros","47.0332","19.7840","Hungary","HU","HUN","Pest","minor","23424","1348967171"
"Bondo","Bondo","3.8167","23.6833","Congo (Kinshasa)","CD","COD","Bas-Uélé","","23517","1180786691"
"Eschborn","Eschborn","50.1436","8.5700","Germany","DE","DEU","Hesse","","21734","1276712049"
"Senden","Senden","48.3167","10.0667","Germany","DE","DEU","Bavaria","","22625","1276555386"
"Davlekanovo","Davlekanovo","54.2167","55.0333","Russia","RU","RUS","Bashkortostan","","23499","1643452201"
"Nijlen","Nijlen","51.1667","4.6500","Belgium","BE","BEL","Flanders","","22936","1056215628"
"Gersthofen","Gersthofen","48.4167","10.8667","Germany","DE","DEU","Bavaria","","22847","1276965949"
"Patterson","Patterson","37.4758","-121.1536","United States","US","USA","California","","23517","1840020290"
"Tirat Karmel","Tirat Karmel","32.7667","34.9667","Israel","IL","ISR","Haifa","","22200","1376894717"
"Jieshang","Jieshang","26.2663","104.6671","China","CN","CHN","Guizhou","","23311","1156442745"
"Muang Sing","Muang Sing","21.2000","101.1500","Laos","LA","LAO","Louangnamtha","minor","23500","1418291138"
"Sederot","Sederot","31.5228","34.5953","Israel","IL","ISR","Southern","","23090","1376365217"
"Maple Heights","Maple Heights","41.4094","-81.5625","United States","US","USA","Ohio","","23511","1840000611"
"Villanueva de la Cañada","Villanueva de la Canada","40.4500","-3.9833","Spain","ES","ESP","Madrid","","22845","1724000272"
"Neihuzhai","Neihuzhai","22.9745","115.8343","China","CN","CHN","Guangdong","","23126","1156546650"
"Barbasa","Barbasa","11.1961","122.0389","Philippines","PH","PHL","Antique","","23359","1608243064"
"Pissila","Pissila","13.1667","-0.8167","Burkina Faso","BF","BFA","Centre-Nord","","23420","1854949911"
"Rock Springs","Rock Springs","41.5947","-109.2209","United States","US","USA","Wyoming","","23505","1840020101"
"Sonepur","Sonepur","20.8333","83.9167","India","IN","IND","Odisha","","23152","1356986560"
"Teguise","Teguise","29.0611","-13.5597","Spain","ES","ESP","Canary Islands","","23411","1724648198"
"Assaré","Assare","-6.8739","-39.8750","Brazil","BR","BRA","Ceará","","23478","1076082622"
"Nasu","Nasu","37.0198","140.1210","Japan","JP","JPN","Tochigi","","23435","1392003453"
"Martí","Marti","22.9522","-80.9167","Cuba","CU","CUB","Matanzas","minor","23475","1192518503"
"Medeiros Neto","Medeiros Neto","-17.3739","-40.2208","Brazil","BR","BRA","Bahia","","23478","1076423540"
"Muttupet","Muttupet","10.3950","79.4935","India","IN","IND","Tamil Nādu","","22945","1356647743"
"Jacksonville Beach","Jacksonville Beach","30.2782","-81.4045","United States","US","USA","Florida","","23492","1840015032"
"Haar","Haar","48.1000","11.7333","Germany","DE","DEU","Bavaria","","21797","1276621025"
"Monte Caseros","Monte Caseros","-30.2500","-57.6500","Argentina","AR","ARG","Corrientes","minor","23470","1032051417"
"Gālīkesh","Galikesh","37.2719","55.4325","Iran","IR","IRN","Golestān","minor","23394","1364497413"
"Ban Thoet Thai","Ban Thoet Thai","20.3084","99.6445","Thailand","TH","THA","Chiang Rai","","23392","1764807861"
"Almeirim","Almeirim","39.2000","-8.6167","Portugal","PT","PRT","Santarém","minor","23376","1620738863"
"Lensk","Lensk","60.7333","114.9167","Russia","RU","RUS","Sakha (Yakutiya)","","23479","1643009226"
"’s-Gravenzande","'s-Gravenzande","52.0000","4.1667","Netherlands","NL","NLD","Zuid-Holland","","22190","1528221207"
"Sakleshpur","Sakleshpur","12.8930","75.7250","India","IN","IND","Karnātaka","","23352","1356540508"
"Ḑank","Dank","23.5500","56.2500","Oman","OM","OMN","Az̧ Z̧āhirah","","23466","1512330290"
"Ambohimiadana","Ambohimiadana","-19.2333","47.7833","Madagascar","MG","MDG","Antananarivo","","23320","1450165876"
"Belo Oriente","Belo Oriente","-19.2200","-42.4839","Brazil","BR","BRA","Minas Gerais","","23397","1076586666"
"Sluis","Sluis","51.3083","3.3861","Netherlands","NL","NLD","Zeeland","","23386","1528332351"
"Shuilin","Shuilin","23.5662","120.2340","Taiwan","TW","TWN","Yunlin","","23134","1158354409"
"Calamba","Calamba","8.5583","123.6417","Philippines","PH","PHL","Misamis Occidental","","23227","1608994208"
"Frascati","Frascati","41.8167","12.6833","Italy","IT","ITA","Lazio","","22450","1380415659"
"Lunbei","Lunbei","23.7789","120.3331","Taiwan","TW","TWN","Yunlin","","23051","1158459635"
"Pajapita","Pajapita","14.7243","-92.0334","Guatemala","GT","GTM","San Marcos","minor","23171","1320459427"
"Santa Helena","Santa Helena","-24.8600","-54.3328","Brazil","BR","BRA","Paraná","","23413","1076079430"
"Happy Valley","Happy Valley","45.4358","-122.5081","United States","US","USA","Oregon","","23442","1840019950"
"Piraí do Sul","Pirai do Sul","-24.5258","-49.9489","Brazil","BR","BRA","Paraná","","23424","1076395519"
"Waukee","Waukee","41.5985","-93.8869","United States","US","USA","Iowa","","23441","1840010181"
"Brotas","Brotas","-22.2839","-48.1269","Brazil","BR","BRA","São Paulo","","23419","1076744263"
"Rucphen","Rucphen","51.5333","4.5833","Netherlands","NL","NLD","Noord-Brabant","minor","23080","1528156482"
"Ortona","Ortona","42.3500","14.4000","Italy","IT","ITA","Abruzzo","","23111","1380661495"
"Nakūr","Nakur","29.9189","77.3044","India","IN","IND","Uttar Pradesh","","22712","1356965243"
"Kumano","Kumano","34.3358","132.5847","Japan","JP","JPN","Hiroshima","","22760","1392003231"
"Brookings","Brookings","44.3022","-96.7859","United States","US","USA","South Dakota","","23432","1840002506"
"Raritan","Raritan","40.5070","-74.8662","United States","US","USA","New Jersey","","23426","1840143543"
"Doctor Mora","Doctor Mora","21.1425","-100.3192","Mexico","MX","MEX","Guanajuato","minor","23324","1484329164"
"Épernay","Epernay","49.0403","3.9600","France","FR","FRA","Grand Est","minor","22433","1250669330"
"Riehen","Riehen","47.5833","7.6333","Switzerland","CH","CHE","Basel-Stadt","","21448","1756991792"
"Gaoshu","Gaoshu","22.8202","120.5979","Taiwan","TW","TWN","Pingtung","","23162","1158803250"
"Johnston","Johnston","41.6910","-93.7234","United States","US","USA","Iowa","","23418","1840008170"
"Laguna de Duero","Laguna de Duero","41.5833","-4.7167","Spain","ES","ESP","Castille-Leon","","22642","1724571345"
"Veinticinco de Mayo","Veinticinco de Mayo","-35.4167","-60.1667","Argentina","AR","ARG","Buenos Aires","minor","23408","1032801782"
"Moskva","Moskva","37.6567","69.6292","Tajikistan","TJ","TJK","Khatlon","minor","23300","1762027698"
"Calabasas","Calabasas","34.1375","-118.6689","United States","US","USA","California","","23410","1840019234"
"Torre del Mar","Torre del Mar","36.7500","-4.1000","Spain","ES","ESP","Andalusia","","22819","1724657999"
"Ban Bang Rin","Ban Bang Rin","9.9531","98.6342","Thailand","TH","THA","Ranong","","23144","1764108342"
"Teorama","Teorama","8.4353","-73.2864","Colombia","CO","COL","Norte de Santander","minor","23381","1170297865"
"Çağlayancerit","Caglayancerit","37.7506","37.2931","Turkey","TR","TUR","Kahramanmaraş","minor","23346","1792761709"
"Scherpenheuvel","Scherpenheuvel","51.0103","4.9728","Belgium","BE","BEL","Flanders","","22952","1056346547"
"General Luna","General Luna","9.7836","126.1567","Philippines","PH","PHL","Surigao del Norte","","22853","1608814874"
"Cordeirópolis","Cordeiropolis","-22.4819","-47.4569","Brazil","BR","BRA","São Paulo","","23234","1076452499"
"Cuilapan de Guerrero","Cuilapan de Guerrero","16.9972","-96.7817","Mexico","MX","MEX","Oaxaca","minor","21597","1484534441"
"San Lorenzo de Esmeraldas","San Lorenzo de Esmeraldas","1.2658","-78.8414","Ecuador","EC","ECU","Esmeraldas","","23265","1218156660"
"Carandaí","Carandai","-20.9539","-43.8058","Brazil","BR","BRA","Minas Gerais","","23346","1076288590"
"Akkuş","Akkus","40.7931","37.0164","Turkey","TR","TUR","Ordu","minor","23369","1792320002"
"Calbiga","Calbiga","11.6333","125.0167","Philippines","PH","PHL","Samar","","23310","1608755840"
"Vernāg","Vernag","33.5377","75.2503","India","IN","IND","Jammu and Kashmīr","","22752","1356589875"
"Achkhoy-Martan","Achkhoy-Martan","43.1939","45.2833","Russia","RU","RUS","Chechnya","minor","23282","1643219840"
"Ortigueira","Ortigueira","-24.2078","-50.9489","Brazil","BR","BRA","Paraná","","23380","1076897299"
"Farragut","Farragut","35.8731","-84.1821","United States","US","USA","Tennessee","","23388","1840016171"
"Chākia","Chakia","26.4160","85.0467","India","IN","IND","Bihār","","21948","1356151353"
"San José Ojetenam","San Jose Ojetenam","15.2167","-91.9667","Guatemala","GT","GTM","San Marcos","minor","22768","1320556269"
"Tobatí","Tobati","-25.2600","-57.0814","Paraguay","PY","PRY","Cordillera","","23295","1600471923"
"Khirkiyān","Khirkiyan","22.1673","76.8614","India","IN","IND","Madhya Pradesh","","23082","1356175336"
"Salcajá","Salcaja","14.8833","-91.4500","Guatemala","GT","GTM","Quetzaltenango","minor","21577","1320564253"
"Cajidiocan","Cajidiocan","12.3667","122.6833","Philippines","PH","PHL","Romblon","","23259","1608803885"
"Beshariq","Beshariq","40.4358","70.6103","Uzbekistan","UZ","UZB","Farg‘ona","minor","22800","1860775601"
"Avellaneda","Avellaneda","-29.1175","-59.6583","Argentina","AR","ARG","Santa Fe","","23341","1032387319"
"Qaţanā","Qatana","33.4333","36.0833","Syria","SY","SYR","Rīf Dimashq","minor","22831","1760346496"
"Nyakrom","Nyakrom","5.6167","-0.7833","Ghana","GH","GHA","Central","","22911","1288290852"
"Cullera","Cullera","39.1639","-0.2517","Spain","ES","ESP","Andalusia","","22937","1724054051"
"Karben","Karben","50.2322","8.7681","Germany","DE","DEU","Hesse","","22843","1276723734"
"Piteå","Pitea","65.3333","21.5000","Sweden","SE","SWE","Norrbotten","minor","23350","1752869095"
"Goumori","Goumori","11.1833","2.2833","Benin","BJ","BEN","Alibori","","23286","1204178150"
"Caniço","Canico","32.6412","-16.8504","Portugal","PT","PRT","Madeira","","23361","1620433583"
"Vengattūr","Vengattur","13.0999","79.9321","India","IN","IND","Tamil Nādu","","22540","1356050244"
"La Prairie","La Prairie","45.4200","-73.5000","Canada","CA","CAN","Quebec","","23357","1124956496"
"Melchor Romero","Melchor Romero","-34.9492","-58.0472","Argentina","AR","ARG","Buenos Aires","","22511","1032196678"
"Palmeiras de Goiás","Palmeiras de Goias","-16.8050","-49.9258","Brazil","BR","BRA","Goiás","","23338","1076618499"
"Cide","Cide","41.8922","33.0044","Turkey","TR","TUR","Kastamonu","minor","23329","1792045032"
"Westerly","Westerly","41.3635","-71.7899","United States","US","USA","Rhode Island","","23352","1840026106"
"Taylors","Taylors","34.9157","-82.3124","United States","US","USA","South Carolina","","23348","1840013498"
"San Pablo Jocopilas","San Pablo Jocopilas","14.5833","-91.4500","Guatemala","GT","GTM","Suchitepéquez","minor","22490","1320534769"
"Olivet","Olivet","47.8639","1.9006","France","FR","FRA","Centre-Val de Loire","","22386","1250759195"
"Nakagusuku","Nakagusuku","26.2678","127.7914","Japan","JP","JPN","Okinawa","","21924","1392003297"
"Píritu","Piritu","9.9178","-65.0646","Venezuela","VE","VEN","Anzoátegui","minor","23248","1862835516"
"Miantsoarivo","Miantsoarivo","-19.2000","47.4000","Madagascar","MG","MDG","Antananarivo","","23000","1450237098"
"Monteros","Monteros","-27.1667","-65.5000","Argentina","AR","ARG","Tucumán","minor","23274","1032530967"
"Waverly","Waverly","42.7401","-84.6354","United States","US","USA","Michigan","","23328","1840006924"
"Bermo","Bermo","23.7878","85.9353","India","IN","IND","Jhārkhand","","22396","1356191728"
"Collipulli","Collipulli","-37.9500","-72.4333","Chile","CL","CHL","Araucanía","","23321","1152908869"
"Telica","Telica","12.5228","-86.8597","Nicaragua","NI","NIC","León","minor","23266","1558515596"
"Moncada","Moncada","39.5456","-0.3956","Spain","ES","ESP","Valencia","","21913","1724450178"
"Tiruverumbūr","Tiruverumbur","10.7937","78.7690","India","IN","IND","Tamil Nādu","","22207","1356207661"
"Wilmington","Wilmington","42.5609","-71.1654","United States","US","USA","Massachusetts","","23311","1840053495"
"Simaria","Simaria","25.5671","87.4929","India","IN","IND","Bihār","","22194","1356788289"
"Santo Tomé","Santo Tome","-28.5500","-56.0500","Argentina","AR","ARG","Corrientes","minor","23299","1032959342"
"Taquarituba","Taquarituba","-23.5328","-49.2439","Brazil","BR","BRA","São Paulo","","23256","1076078451"
"Douar Imoukkane","Douar Imoukkane","35.0298","-4.8125","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","23132","1504341644"
"Kirkintilloch","Kirkintilloch","55.9380","-4.1550","United Kingdom","GB","GBR","East Dunbartonshire","","21380","1826571447"
"Fangliao","Fangliao","22.3656","120.5936","Taiwan","TW","TWN","Pingtung","","22911","1158243044"
"Combs-la-Ville","Combs-la-Ville","48.6700","2.5600","France","FR","FRA","Île-de-France","","21811","1250661921"
"Baile Átha Luain","Baile Atha Luain","53.4236","-7.9425","Ireland","IE","IRL","Westmeath","","21349","1372885521"
"Linda","Linda","39.1241","-121.5421","United States","US","USA","California","","23304","1840018800"
"Moirāng","Moirang","24.3490","93.6910","India","IN","IND","Manipur","","22674","1356046634"
"Yoshioka","Yoshioka","36.4474","139.0097","Japan","JP","JPN","Gunma","","22234","1392003196"
"Warrenton","Warrenton","-28.1114","24.8497","South Africa","ZA","ZAF","Northern Cape","","22588","1710333461"
"Griffin","Griffin","33.2418","-84.2748","United States","US","USA","Georgia","","23300","1840013776"
"Jiucangzhou","Jiucangzhou","38.2060","117.0239","China","CN","CHN","Hebei","","23056","1156150430"
"Kaler","Kaler","25.1180","84.4955","India","IN","IND","Bihār","","22763","1356008941"
"Netphen","Netphen","50.9147","8.1000","Germany","DE","DEU","North Rhine-Westphalia","","23130","1276020652"
"Vilnohirsk","Vilnohirsk","48.4781","34.0281","Ukraine","UA","UKR","Dnipropetrovska Oblast","","23235","1804164194"
"Minamata","Minamata","32.2167","130.4000","Japan","JP","JPN","Kumamoto","","23155","1392003179"
"Zaruma","Zaruma","-3.6833","-79.6000","Ecuador","EC","ECU","El Oro","","22222","1218248860"
"New Brighton","New Brighton","45.0658","-93.2060","United States","US","USA","Minnesota","","23293","1840007841"
"Añatuya","Anatuya","-28.4667","-62.8333","Argentina","AR","ARG","Santiago del Estero","minor","23286","1032511298"
"Oliveira do Bairro","Oliveira do Bairro","40.5167","-8.5000","Portugal","PT","PRT","Aveiro","minor","23028","1620488479"
"Tubungan","Tubungan","10.7833","122.3000","Philippines","PH","PHL","Iloilo","","23021","1608093467"
"Iguaba Grande","Iguaba Grande","-22.8389","-42.2289","Brazil","BR","BRA","Rio de Janeiro","","22851","1076198023"
"La Cruz de Río Grande","La Cruz de Rio Grande","13.1122","-84.1869","Nicaragua","NI","NIC","Costa Caribe Sur","minor","23284","1558703104"
"Qiryat Mal’akhi","Qiryat Mal'akhi","31.7333","34.7333","Israel","IL","ISR","Southern","","23100","1376662881"
"Teodoro Sampaio","Teodoro Sampaio","-22.5328","-52.1678","Brazil","BR","BRA","São Paulo","","23273","1076720221"
"Alcobaça","Alcobaca","-17.5189","-39.1958","Brazil","BR","BRA","Bahia","","23282","1076035694"
"Mine","Mine","34.1631","131.2083","Japan","JP","JPN","Yamaguchi","","23236","1392003146"
"Kulattuppālaiyam","Kulattuppalaiyam","10.7613","77.5843","India","IN","IND","Tamil Nādu","","23018","1356227710"
"Sheybān","Sheyban","31.4086","48.7942","Iran","IR","IRN","Khūzestān","","23211","1364776996"
"Shāhbāzpur","Shahbazpur","24.0531","91.1719","Bangladesh","BD","BGD","Chattogram","","21429","1050688770"
"Shiling","Shiling","27.3576","105.1535","China","CN","CHN","Guizhou","","23023","1156800255"
"East Retford","East Retford","53.3228","-0.9431","United Kingdom","GB","GBR","Nottinghamshire","","22013","1826529962"
"Velugodu","Velugodu","15.7817","78.6892","India","IN","IND","Andhra Pradesh","","23048","1356119130"
"Qiryat Shemona","Qiryat Shemona","33.2075","35.5697","Israel","IL","ISR","Northern","","23076","1376248603"
"Tsaratanana","Tsaratanana","-21.1833","47.6333","Madagascar","MG","MDG","Fianarantsoa","","23252","1450726969"
"Riachão das Neves","Riachao das Neves","-11.7458","-44.9100","Brazil","BR","BRA","Bahia","","23264","1076225070"
"Puchheim","Puchheim","48.1500","11.3500","Germany","DE","DEU","Bavaria","","21531","1276452600"
"Perāvūrani","Peravurani","10.3000","79.1800","India","IN","IND","Tamil Nādu","","22084","1356611422"
"Ulukışla","Ulukisla","37.5467","34.4844","Turkey","TR","TUR","Niğde","minor","23252","1792654631"
"Gobō","Gobo","33.8833","135.1500","Japan","JP","JPN","Wakayama","","22744","1392651383"
"Culion","Culion","11.8944","120.0186","Philippines","PH","PHL","Palawan","","23213","1608889308"
"Karlsfeld","Karlsfeld","48.2167","11.4667","Germany","DE","DEU","Bavaria","","21853","1276796218"
"Blangmangat","Blangmangat","5.1881","97.1403","Indonesia","ID","IDN","Aceh","","22850","1360562688"
"Palmar de Varela","Palmar de Varela","10.7500","-74.7500","Colombia","CO","COL","Atlántico","minor","23012","1170543827"
"Tambaú","Tambau","-21.7050","-47.2739","Brazil","BR","BRA","São Paulo","","23214","1076693852"
"Selim","Selim","40.4633","42.7858","Turkey","TR","TUR","Kars","minor","23231","1792640205"
"Ozuluama de Mascareñas","Ozuluama de Mascarenas","21.6667","-97.8500","Mexico","MX","MEX","Veracruz","minor","23244","1484230951"
"Farmington","Farmington","44.6572","-93.1687","United States","US","USA","Minnesota","","23253","1840007853"
"Asafābād","Asafabad","19.3650","79.2740","India","IN","IND","Andhra Pradesh","","23059","1356547910"
"Senador Guiomard","Senador Guiomard","-10.1497","-67.7374","Brazil","BR","BRA","Acre","","23236","1076352899"
"Kapolei","Kapolei","21.3399","-158.0677","United States","US","USA","Hawaii","","23248","1840025211"
"Carmen","Carmen","8.9999","125.2648","Philippines","PH","PHL","Agusan del Norte","","23172","1608585358"
"Nakashunbetsu","Nakashunbetsu","43.5552","144.9714","Japan","JP","JPN","Hokkaidō","","23211","1392557467"
"Devonport","Devonport","-41.1800","146.3503","Australia","AU","AUS","Tasmania","","23046","1036033175"
"Pilar de la Horadada","Pilar de la Horadada","37.8667","-0.7833","Spain","ES","ESP","Valencia","","22949","1724816403"
"Kūn Puhāl","Kun Puhal","33.0058","76.5334","India","IN","IND","Himāchal Pradesh","","23229","1356702868"
"Tototlán","Tototlan","20.5333","-102.7833","Mexico","MX","MEX","Jalisco","minor","23171","1484885208"
"Tighedouine","Tighedouine","31.4236","-7.5208","Morocco","MA","MAR","Marrakech-Safi","","22971","1504874700"
"Lagoa","Lagoa","37.1333","-8.4500","Portugal","PT","PRT","Faro","minor","22975","1620217287"
"Puenteareas","Puenteareas","42.1667","-8.5000","Spain","ES","ESP","Galicia","","23049","1724337811"
"Mentana","Mentana","42.0167","12.6500","Italy","IT","ITA","Lazio","","23126","1380358509"
"Tadmaït","Tadmait","36.7427","3.9019","Algeria","DZ","DZA","Boumerdes","","22838","1012245949"
"Tecumseh","Tecumseh","42.2431","-82.9256","Canada","CA","CAN","Ontario","","23229","1124720869"
"Ibiá","Ibia","-19.4778","-46.5389","Brazil","BR","BRA","Minas Gerais","","23218","1076749050"
"Loves Park","Loves Park","42.3364","-88.9975","United States","US","USA","Illinois","","23223","1840009130"
"Nevyansk","Nevyansk","57.4833","60.2000","Russia","RU","RUS","Sverdlovskaya Oblast’","","23200","1643592737"
"Itaporã","Itapora","-22.0789","-54.7889","Brazil","BR","BRA","Mato Grosso do Sul","","23220","1076866825"
"‘Ālī Shahr","\`Ali Shahr","28.9306","51.0689","Iran","IR","IRN","Būshehr","","23178","1364067141"
"Tūkrah","Tukrah","32.5322","20.5722","Libya","LY","LBY","Banghāzī","","23164","1434350017"
"Bassar","Bassar","9.2500","0.7833","Togo","TG","TGO","Kara","","23181","1768050143"
"Linstead","Linstead","18.1368","-77.0317","Jamaica","JM","JAM","Saint Catherine","","22757","1388611853"
"Touboro","Touboro","7.7708","15.3567","Cameroon","CM","CMR","Nord","","23196","1120000456"
"Warburg","Warburg","51.5000","9.1697","Germany","DE","DEU","North Rhine-Westphalia","","23079","1276612246"
"Beek en Donk","Beek en Donk","51.5347","5.6303","Netherlands","NL","NLD","Noord-Brabant","minor","22805","1528543857"
"Van Buren","Van Buren","35.4483","-94.3528","United States","US","USA","Arkansas","","23213","1840015397"
"Kolpashevo","Kolpashevo","58.3000","82.9000","Russia","RU","RUS","Tomskaya Oblast’","","23209","1643773170"
"Haywards Heath","Haywards Heath","51.0048","-0.0979","United Kingdom","GB","GBR","West Sussex","","22800","1826565038"
"Coração de Maria","Coracao de Maria","-12.2328","-38.7500","Brazil","BR","BRA","Bahia","","23146","1076552698"
"Guaraí","Guarai","-8.8339","-48.5100","Brazil","BR","BRA","Tocantins","","23200","1076025725"
"Vranov nad Topľou","Vranov nad Topl'ou","48.8808","21.6733","Slovakia","SK","SVK","Prešov","minor","22589","1703513512"
"Yásica Arriba","Yasica Arriba","19.6333","-70.6000","Dominican Republic","DO","DOM","Cibao Norte","","23114","1214961311"
"Colón","Colon","-33.8833","-61.1000","Argentina","AR","ARG","Buenos Aires","minor","23171","1032795867"
"Karhula","Karhula","60.5155","26.9354","Finland","FI","FIN","Kymenlaakso","","22686","1246292106"
"Molodohvardiisk","Molodohvardiisk","48.3444","39.6583","Ukraine","UA","UKR","Luhanska Oblast","","22937","1804689495"
"Areia","Areia","-6.9628","-35.6919","Brazil","BR","BRA","Paraíba","","23110","1076995315"
"Clinton","Clinton","41.1395","-112.0656","United States","US","USA","Utah","","23192","1840018736"
"Simpsonville","Simpsonville","34.7287","-82.2569","United States","US","USA","South Carolina","","23190","1840015477"
"Gormi","Gormi","26.6003","78.5119","India","IN","IND","Madhya Pradesh","","22779","1356378955"
"Hulikal","Hulikal","11.3193","76.7732","India","IN","IND","Tamil Nādu","","22511","1356040942"
"Abreus","Abreus","22.2806","-80.5678","Cuba","CU","CUB","Cienfuegos","","23144","1192567991"
"Montgomery","Montgomery","41.5399","-74.2073","United States","US","USA","New York","","23184","1840004875"
"Himora","Himora","14.2861","36.6097","Ethiopia","ET","ETH","Tigray","","21653","1231449720"
"Esparraguera","Esparraguera","41.5381","1.8692","Spain","ES","ESP","Catalonia","","22365","1724908880"
"Colón","Colon","-32.2167","-58.1333","Argentina","AR","ARG","Entre Ríos","minor","23150","1032656120"
"Fokino","Fokino","42.9667","132.4000","Russia","RU","RUS","Primorskiy Kray","","23077","1643752363"
"Ankireddikuntapālem","Ankireddikuntapalem","16.2884","80.3916","India","IN","IND","Andhra Pradesh","","22256","1356617917"
"Christiansburg","Christiansburg","37.1406","-80.4036","United States","US","USA","Virginia","","23174","1840006447"
"Kodarmā","Kodarma","24.4675","85.5940","India","IN","IND","Jhārkhand","","22764","1356260269"
"Tnine Lgharbia","Tnine Lgharbia","32.5719","-8.8568","Morocco","MA","MAR","Casablanca-Settat","","23074","1504401185"
"Allison Park","Allison Park","40.5730","-79.9603","United States","US","USA","Pennsylvania","","23160","1840026468"
"Koło","Kolo","52.2000","18.6333","Poland","PL","POL","Wielkopolskie","minor","21686","1616148045"
"Trotwood","Trotwood","39.7926","-84.3165","United States","US","USA","Ohio","","23157","1840010582"
"Bedford","Bedford","42.9406","-71.5302","United States","US","USA","New Hampshire","","23157","1840054623"
"Tapaktuan","Tapaktuan","3.2500","97.1667","Indonesia","ID","IDN","Aceh","","23100","1360203588"
"Puerto Concordia","Puerto Concordia","2.6833","-72.7667","Colombia","CO","COL","Meta","minor","23131","1170046908"
"Ambohitrarivo","Ambohitrarivo","-17.4667","48.3500","Madagascar","MG","MDG","Toamasina","","23000","1450446247"
"Wareham","Wareham","41.7662","-70.6999","United States","US","USA","Massachusetts","","23149","1840053578"
"Roxbury","Roxbury","40.8822","-74.6523","United States","US","USA","New Jersey","","23149","1840081733"
"Sandviken","Sandviken","60.6167","16.7833","Sweden","SE","SWE","Gävleborg","minor","22965","1752810020"
"Crystal","Crystal","45.0377","-93.3599","United States","US","USA","Minnesota","","23143","1840006745"
"Bafilo","Bafilo","9.3500","1.2667","Togo","TG","TGO","Kara","","23085","1768588000"
"Zunheboto","Zunheboto","25.9667","94.5167","India","IN","IND","Nāgāland","","22809","1356223921"
"Sidi Ifni","Sidi Ifni","29.3833","-10.1667","Morocco","MA","MAR","Guelmim-Oued Noun","","21618","1504270532"
"Kilkís","Kilkis","40.9833","22.8667","Greece","GR","GRC","Kentrikí Makedonía","minor","22914","1300726797"
"Mountain House","Mountain House","37.7673","-121.5449","United States","US","USA","California","","23132","1840026768"
"Balen","Balen","51.1667","5.1500","Belgium","BE","BEL","Flanders","","22813","1056866817"
"Fleurus","Fleurus","50.4833","4.5333","Belgium","BE","BEL","Wallonia","","22738","1056660744"
"Laguna Beach","Laguna Beach","33.5455","-117.7613","United States","US","USA","California","","23121","1840020587"
"Okahandja","Okahandja","-21.9833","16.9167","Namibia","NA","NAM","Otjozondjupa","","22639","1516123978"
"Gif-sur-Yvette","Gif-sur-Yvette","48.7018","2.1339","France","FR","FRA","Île-de-France","","21308","1250169413"
"Losser","Losser","52.2667","7.0000","Netherlands","NL","NLD","Overijssel","minor","22888","1528246399"
"Paravai","Paravai","9.9690","78.0613","India","IN","IND","Tamil Nādu","","21447","1356231818"
"Mékhé","Mekhe","15.1167","-16.6333","Senegal","SN","SEN","Thiès","","22944","1686246700"
"Ballenger Creek","Ballenger Creek","39.3807","-77.4206","United States","US","USA","Maryland","","23114","1840031490"
"Baclayon","Baclayon","9.6227","123.9135","Philippines","PH","PHL","Bohol","minor","22461","1608417534"
"Komló","Komlo","46.1912","18.2613","Hungary","HU","HUN","Baranya","minor","22635","1348836400"
"Weilheim","Weilheim","47.8333","11.1500","Germany","DE","DEU","Bavaria","minor","22703","1276001381"
"Tashtagol","Tashtagol","52.7667","87.8667","Russia","RU","RUS","Kemerovskaya Oblast’","","23107","1643787144"
"Pitogo","Pitogo","13.7850","122.0881","Philippines","PH","PHL","Quezon","","22798","1608648467"
"Tupiza","Tupiza","-21.4375","-65.7158","Bolivia","BO","BOL","Potosí","","23100","1068328989"
"Senda","Senda","24.4976","88.0684","India","IN","IND","West Bengal","","21668","1356727951"
"Prieto Diaz","Prieto Diaz","13.0408","124.1931","Philippines","PH","PHL","Sorsogon","","22644","1608000004"
"Cholavandān","Cholavandan","10.0167","77.9667","India","IN","IND","Tamil Nādu","","22578","1356342416"
"Yunak","Yunak","38.8172","31.7356","Turkey","TR","TUR","Konya","minor","23093","1792108399"
"Mangidy","Mangidy","-21.2500","46.8000","Madagascar","MG","MDG","Fianarantsoa","","23033","1450650738"
"Nanyangcun","Nanyangcun","34.7015","108.3295","China","CN","CHN","Shaanxi","","22654","1156160890"
"Sīwah","Siwah","29.2000","25.5167","Egypt","EG","EGY","Maţrūḩ","","23080","1818755237"
"Niskayuna","Niskayuna","42.8030","-73.8729","United States","US","USA","New York","","23094","1840058350"
"Nazareth","Nazareth","8.5562","77.9710","India","IN","IND","Tamil Nādu","","22387","1356111397"
"Ambohitoaka","Ambohitoaka","-16.0167","47.6500","Madagascar","MG","MDG","Mahajanga","","23000","1450398424"
"Câmpia Turzii","Campia Turzii","46.5486","23.8800","Romania","RO","ROU","Cluj","","22223","1642507537"
"Eastmont","Eastmont","47.8968","-122.1818","United States","US","USA","Washington","","23088","1840139116"
"Gusinoozërsk","Gusinoozersk","51.2833","106.5167","Russia","RU","RUS","Buryatiya","","23082","1643386183"
"Garden City","Garden City","40.7266","-73.6447","United States","US","USA","New York","","23087","1840005289"
"Gainsborough","Gainsborough","53.4016","-0.7732","United Kingdom","GB","GBR","Lincolnshire","","22841","1826888621"
"Mahela","Mahela","-20.9667","48.4500","Madagascar","MG","MDG","Fianarantsoa","","23000","1450069784"
"Azové","Azove","6.9500","1.7000","Benin","BJ","BEN","Couffo","","22853","1204299072"
"Yuli","Yuli","41.3351","86.2614","China","CN","CHN","Xinjiang","minor","22474","1156717864"
"Stoneham","Stoneham","42.4741","-71.0972","United States","US","USA","Massachusetts","","23077","1840053487"
"Bhatpurī","Bhatpuri","29.1700","79.1600","India","IN","IND","Uttarākhand","","22255","1356123363"
"Santa Maria do Pará","Santa Maria do Para","-1.3519","-47.5758","Brazil","BR","BRA","Pará","","23026","1076556798"
"Nikaho","Nikaho","39.2030","139.9077","Japan","JP","JPN","Akita","","22981","1392175644"
"Kapfenberg","Kapfenberg","47.4394","15.2894","Austria","AT","AUT","Steiermark","","22798","1040864298"
"Wulongpu","Wulongpu","37.9356","110.3566","China","CN","CHN","Shaanxi","","22910","1156161458"
"Amoucha","Amoucha","36.3880","5.4108","Algeria","DZ","DZA","Sétif","","22767","1012766595"
"Searcy","Searcy","35.2418","-91.7351","United States","US","USA","Arkansas","","23074","1840015429"
"Oyonnax","Oyonnax","46.2561","5.6556","France","FR","FRA","Auvergne-Rhône-Alpes","","22456","1250935509"
"Meiwa","Meiwa","34.5500","136.6167","Japan","JP","JPN","Mie","","22523","1392003513"
"Überlingen","Uberlingen","47.7667","9.1650","Germany","DE","DEU","Baden-Württemberg","","22684","1276778071"
"Mercato San Severino","Mercato San Severino","40.7833","14.7667","Italy","IT","ITA","Campania","","22335","1380511383"
"Vigonza","Vigonza","45.4500","11.9833","Italy","IT","ITA","Veneto","","22958","1380217105"
"Strakonice","Strakonice","49.2615","13.9024","Czechia","CZ","CZE","Jihočeský Kraj","","22428","1203755418"
"Lexington","Lexington","33.9890","-81.2202","United States","US","USA","South Carolina","","23068","1840016724"
"Wichian Buri","Wichian Buri","15.6565","101.1072","Thailand","TH","THA","Phetchabun","minor","22995","1764906431"
"Achí","Achi","8.5667","-74.5500","Colombia","CO","COL","Bolívar","minor","23051","1170684229"
"Minbu","Minbu","20.1800","94.8700","Myanmar","MM","MMR","Magway","","22962","1104239805"
"Farahalana","Farahalana","-14.4333","50.1667","Madagascar","MG","MDG","Antsiranana","","23000","1450865823"
"Gardner","Gardner","38.8122","-94.9275","United States","US","USA","Kansas","","23065","1840001636"
"Keta","Keta","5.9167","0.9833","Ghana","GH","GHA","Volta","","22739","1288925538"
"Seva","Seva","5.9845","0.9414","Ghana","GH","GHA","Volta","","22739","1288737377"
"Dindori","Dindori","22.9414","81.0797","India","IN","IND","Madhya Pradesh","","22997","1356626889"
"Conceição das Alagoas","Conceicao das Alagoas","-19.9150","-48.3878","Brazil","BR","BRA","Minas Gerais","","23043","1076552026"
"Gyêgu","Gyegu","33.0166","96.7333","China","CN","CHN","Qinghai","minor","23000","1156604060"
"Union Hill-Novelty Hill","Union Hill-Novelty Hill","47.6788","-122.0284","United States","US","USA","Washington","","23060","1840074663"
"Lysander","Lysander","43.1799","-76.3745","United States","US","USA","New York","","23060","1840058273"
"Rastede","Rastede","53.2500","8.2000","Germany","DE","DEU","Lower Saxony","","22874","1276294384"
"Andalatanosy","Andalatanosy","-24.6667","45.6000","Madagascar","MG","MDG","Toliara","","23000","1450638418"
"Shurugwi","Shurugwi","-19.6667","30.0000","Zimbabwe","ZW","ZWE","Midlands","","22900","1716870364"
"Niamtougou","Niamtougou","9.7667","1.1000","Togo","TG","TGO","Kara","","22986","1768703422"
"Morris","Morris","40.7959","-74.4945","United States","US","USA","New Jersey","","23049","1840081723"
"Huixtán","Huixtan","16.6833","-92.4000","Mexico","MX","MEX","Chiapas","minor","22975","1484973594"
"Cheriāl","Cherial","17.9264","78.9719","India","IN","IND","Andhra Pradesh","","22810","1356579296"
"Yeadon","Yeadon","53.8690","-1.6880","United Kingdom","GB","GBR","Leeds","","22233","1826214875"
"Analamisampy","Analamisampy","-22.4667","43.6500","Madagascar","MG","MDG","Toliara","","23000","1450834889"
"Curumaní","Curumani","9.2000","-73.5500","Colombia","CO","COL","Cesar","minor","23018","1170847203"
"Huaibaijie","Huaibaijie","35.7056","109.5855","China","CN","CHN","Shaanxi","","22935","1156051925"
"Guanajay","Guanajay","22.9306","-82.6881","Cuba","CU","CUB","Artemisa","minor","22839","1192568960"
"Orobó","Orobo","-7.7450","-35.6019","Brazil","BR","BRA","Pernambuco","","22878","1076292886"
"Tiruttangal","Tiruttangal","9.4833","77.8333","India","IN","IND","Tamil Nādu","","22265","1356125534"
"Junction City","Junction City","39.0277","-96.8508","United States","US","USA","Kansas","","23033","1840001620"
"Barbate de Franco","Barbate de Franco","36.1833","-5.9167","Spain","ES","ESP","Andalusia","","22872","1724165909"
"Makīnsk","Makinsk","52.6292","70.4169","Kazakhstan","KZ","KAZ","","minor","23020","1398733675"
"Tembagapura","Tembagapura","-4.1428","137.0906","Indonesia","ID","IDN","Papua","minor","23022","1360289992"
"Ondangwa","Ondangwa","-17.9167","15.9500","Namibia","NA","NAM","Oshana","","22822","1516484668"
"Grajewo","Grajewo","53.6500","22.4500","Poland","PL","POL","Podlaskie","minor","21900","1616787967"
"Watertown","Watertown","43.1893","-88.7285","United States","US","USA","Wisconsin","","23030","1840003000"
"Pawni","Pawni","20.7800","79.6300","India","IN","IND","Mahārāshtra","","22821","1356506430"
"Ōno","Ono","35.4706","136.6276","Japan","JP","JPN","Gifu","","22375","1392999993"
"Marovato","Marovato","-15.9000","48.0500","Madagascar","MG","MDG","Mahajanga","","23000","1450492624"
"Aiud","Aiud","46.3122","23.7292","Romania","RO","ROU","Alba","","22876","1642981181"
"Greenfield","Greenfield","39.7937","-85.7738","United States","US","USA","Indiana","","23024","1840014673"
"Hopewell","Hopewell","37.2915","-77.2985","United States","US","USA","Virginia","","23020","1840003859"
"Ankaramy","Ankaramy","-13.9833","48.2000","Madagascar","MG","MDG","Mahajanga","","23000","1450209200"
"Vale de Cambra","Vale de Cambra","40.8500","-8.4000","Portugal","PT","PRT","Aveiro","minor","22864","1620033828"
"Alâattin","Alaattin","37.4667","29.3167","Turkey","TR","TUR","Denizli","","22985","1792362414"
"Büdingen","Budingen","50.2908","9.1125","Germany","DE","DEU","Hesse","","22831","1276951058"
"Pirenópolis","Pirenopolis","-15.8519","-48.9589","Brazil","BR","BRA","Goiás","","23006","1076563560"
"Araçariguama","Aracariguama","-23.4386","-47.0614","Brazil","BR","BRA","São Paulo","","22860","1076653796"
"Oakdale","Oakdale","37.7618","-120.8468","United States","US","USA","California","","23016","1840020289"
"Viga","Viga","13.8667","124.3000","Philippines","PH","PHL","Catanduanes","","22869","1608216822"
"Famaillá","Famailla","-27.0500","-65.4000","Argentina","AR","ARG","Tucumán","minor","22924","1032521528"
"Ankilizato","Ankilizato","-20.4000","45.0500","Madagascar","MG","MDG","Toliara","","23000","1450796220"
"Krasnouralsk","Krasnouralsk","58.3500","60.0500","Russia","RU","RUS","Sverdlovskaya Oblast’","","22996","1643663207"
"Zedelgem","Zedelgem","51.1431","3.1361","Belgium","BE","BEL","Flanders","","22635","1056608404"
"Xinsi","Xinsi","34.6503","104.6302","China","CN","CHN","Gansu","","22829","1156092051"
"Krasnoarmeysk","Krasnoarmeysk","51.0231","45.7031","Russia","RU","RUS","Saratovskaya Oblast’","","22994","1643504367"
"Yanshanbu","Yanshanbu","26.3326","107.1071","China","CN","CHN","Guizhou","","22811","1156227642"
"Soure","Soure","-0.7169","-48.5228","Brazil","BR","BRA","Pará","","23001","1076147572"
"Fenyuan","Fenyuan","24.0154","120.6274","Taiwan","TW","TWN","Changhua","","22417","1158373673"
"Shelbyville","Shelbyville","35.4987","-86.4517","United States","US","USA","Tennessee","","23005","1840015412"
"Beixingzhuang","Beixingzhuang","35.7033","111.1029","China","CN","CHN","Shanxi","","22682","1156979818"
"Hudson","Hudson","41.2399","-81.4408","United States","US","USA","Ohio","","23001","1840007138"
"Middletown","Middletown","39.4450","-75.7183","United States","US","USA","Delaware","","23001","1840005581"
"Roselle","Roselle","41.9809","-88.0861","United States","US","USA","Illinois","","23000","1840011407"
"Barakī Barak","Baraki Barak","33.9692","68.9447","Afghanistan","AF","AFG","Lōgar","minor","22622","1004707647"
"Nagold","Nagold","48.5519","8.7256","Germany","DE","DEU","Baden-Württemberg","","22635","1276622142"
"Abu","Abu","24.5925","72.7083","India","IN","IND","Rājasthān","","22943","1356598126"
"Vagos","Vagos","40.5500","-8.6833","Portugal","PT","PRT","Aveiro","minor","22851","1620705657"
"Kerūr","Kerur","16.0138","75.5463","India","IN","IND","Karnātaka","","22726","1356020053"
"Guadalupe","Guadalupe","2.0250","-75.7572","Colombia","CO","COL","Huila","minor","22867","1170375870"
"Danwān","Danwan","25.5292","84.4598","India","IN","IND","Bihār","","21823","1356873543"
"Al Fuḩayş","Al Fuhays","32.0167","35.7667","Jordan","JO","JOR","Al Balqā’","minor","21908","1400712553"
"Ivatsevichy","Ivatsevichy","52.7167","25.3333","Belarus","BY","BLR","Brestskaya Voblasts’","minor","22958","1112493375"
"Forbach","Forbach","49.1900","6.9000","France","FR","FRA","Grand Est","minor","21652","1250265526"
"Brushy Creek","Brushy Creek","30.5128","-97.7386","United States","US","USA","Texas","","22972","1840135494"
"Luruaco","Luruaco","10.6083","-75.1417","Colombia","CO","COL","Atlántico","minor","22878","1170268775"
"Sarāri","Sarari","26.2523","84.5484","India","IN","IND","Bihār","","21168","1356577867"
"Keene","Keene","42.9494","-72.2998","United States","US","USA","New Hampshire","","22969","1840003050"
"Las Breñas","Las Brenas","-27.0667","-61.0667","Argentina","AR","ARG","Chaco","minor","22953","1032967158"
"Wädenswil","Wadenswil","47.2333","8.6667","Switzerland","CH","CHE","Zürich","","21716","1756533832"
"Dabeiba","Dabeiba","7.0000","-76.2500","Colombia","CO","COL","Antioquia","minor","22954","1170194523"
"Kulkent","Kulkent","40.1500","70.7000","Tajikistan","TJ","TJK","Sughd","","22731","1762615413"
"Zeewolde","Zeewolde","52.3333","5.5500","Netherlands","NL","NLD","Flevoland","minor","22879","1528306050"
"Laupheim","Laupheim","48.2289","9.8797","Germany","DE","DEU","Baden-Württemberg","","22595","1276003982"
"Kanchika","Kanchika","16.4125","80.2323","India","IN","IND","Andhra Pradesh","","22756","1356407030"
"Manghit","Manghit","42.1167","60.0667","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","22949","1860400027"
"Dokuchaievsk","Dokuchaievsk","47.7506","37.6716","Ukraine","UA","UKR","Donetska Oblast","","22907","1804462209"
"Pointe à Raquettes","Pointe a Raquettes","18.7833","-73.0667","Haiti","HT","HTI","Ouest","","22886","1332151637"
"Palma di Montechiaro","Palma di Montechiaro","37.1936","13.7658","Italy","IT","ITA","Sicilia","","22663","1380075513"
"Mount Pearl Park","Mount Pearl Park","47.5189","-52.8058","Canada","CA","CAN","Newfoundland and Labrador","","22957","1124869949"
"La Democracia","La Democracia","14.2333","-90.9500","Guatemala","GT","GTM","Escuintla","minor","22884","1320578325"
"Itaocara","Itaocara","-21.6789","-42.0819","Brazil","BR","BRA","Rio de Janeiro","","22899","1076973020"
"Edgewater","Edgewater","28.9594","-80.9406","United States","US","USA","Florida","","22952","1840014043"
"Znamianka","Znamianka","48.7136","32.6733","Ukraine","UA","UKR","Kirovohradska Oblast","minor","22936","1804158323"
"Świebodzice","Swiebodzice","50.8667","16.3333","Poland","PL","POL","Dolnośląskie","","22219","1616959677"
"Edewecht","Edewecht","53.1258","7.9825","Germany","DE","DEU","Lower Saxony","","22748","1276458945"
"Rivera","Rivera","2.7853","-75.2589","Colombia","CO","COL","Huila","minor","22877","1170362771"
"Wertheim","Wertheim","49.7500","9.5167","Germany","DE","DEU","Baden-Württemberg","","22780","1276150823"
"Malacatancito","Malacatancito","15.3167","-91.6167","Guatemala","GT","GTM","Huehuetenango","minor","22859","1320075688"
"Bad Kissingen","Bad Kissingen","50.2000","10.0667","Germany","DE","DEU","Bavaria","minor","22619","1276216676"
"West Goshen","West Goshen","39.9756","-75.5927","United States","US","USA","Pennsylvania","","22940","1840133853"
"Oteiza","Oteiza","8.7437","126.2214","Philippines","PH","PHL","Surigao del Sur","","22855","1608974134"
"Maying","Maying","36.0448","102.8343","China","CN","CHN","Qinghai","","22415","1156786456"
"Ayagawa","Ayagawa","34.2496","133.9230","Japan","JP","JPN","Kagawa","","22730","1392003219"
"Nasrullāhganj","Nasrullahganj","22.6837","77.2707","India","IN","IND","Madhya Pradesh","","22756","1356071687"
"Candelaria de La Frontera","Candelaria de La Frontera","14.1167","-89.6500","El Salvador","SV","SLV","Santa Ana","","22686","1222926680"
"Saint Austell","Saint Austell","50.3400","-4.7900","United Kingdom","GB","GBR","Cornwall","","21054","1826177227"
"Pillaiyārkuppam","Pillaiyarkuppam","11.8119","79.7922","India","IN","IND","Tamil Nādu","","21219","1356244833"
"Goulburn","Goulburn","-34.7547","149.6186","Australia","AU","AUS","New South Wales","","22419","1036347537"
"Espera Feliz","Espera Feliz","-20.6500","-41.9069","Brazil","BR","BRA","Minas Gerais","","22856","1076630023"
"Selvazzano Dentro","Selvazzano Dentro","45.3833","11.7833","Italy","IT","ITA","Veneto","","22903","1380351916"
"Vreden","Vreden","52.0333","6.8333","Germany","DE","DEU","North Rhine-Westphalia","","22758","1276296218"
"Manosque","Manosque","43.8342","5.7839","France","FR","FRA","Provence-Alpes-Côte d’Azur","","22528","1250372631"
"Nixa","Nixa","37.0453","-93.2959","United States","US","USA","Missouri","","22925","1840008789"
"Tervuren","Tervuren","50.8167","4.5000","Belgium","BE","BEL","Flanders","","22248","1056221910"
"Mihama","Mihama","34.7789","136.9082","Japan","JP","JPN","Aichi","","22440","1392003460"
"Lumbatan","Lumbatan","7.7850","124.2560","Philippines","PH","PHL","Lanao del Sur","","22780","1608418624"
"Gōtsuchō","Gotsucho","35.0167","132.2167","Japan","JP","JPN","Shimane","","22838","1392030464"
"Ayos","Ayos","3.9069","12.5272","Cameroon","CM","CMR","Centre","","22899","1120902688"
"Isilkul","Isilkul","54.9500","71.2500","Russia","RU","RUS","Omskaya Oblast’","","22903","1643502986"
"São Sebastião da Boa Vista","Sao Sebastiao da Boa Vista","-1.7178","-49.5408","Brazil","BR","BRA","Pará","","22904","1076028346"
"Mutuípe","Mutuipe","-13.2289","-39.5050","Brazil","BR","BRA","Bahia","","22833","1076000638"
"Jogipet","Jogipet","17.8356","78.0681","India","IN","IND","Andhra Pradesh","","22512","1356188487"
"Eckernförde","Eckernforde","54.4742","9.8378","Germany","DE","DEU","Schleswig-Holstein","","21902","1276177935"
"Thebes","Thebes","38.3208","23.3178","Greece","GR","GRC","Stereá Elláda","minor","22883","1300138276"
"El Hermel","El Hermel","34.3914","36.3958","Lebanon","LB","LBN","Baalbek-Hermel","minor","22250","1422119929"
"Kitzingen","Kitzingen","49.7333","10.1667","Germany","DE","DEU","Bavaria","minor","22429","1276000007"
"Buriti Bravo","Buriti Bravo","-5.8369","-43.8339","Brazil","BR","BRA","Maranhão","","22899","1076262362"
"Camp Springs","Camp Springs","38.8052","-76.9199","United States","US","USA","Maryland","","22903","1840005947"
"Haderslev","Haderslev","55.2428","9.5250","Denmark","DK","DNK","Syddanmark","minor","22011","1208309317"
"Patacamaya","Patacamaya","-17.2333","-67.9167","Bolivia","BO","BOL","La Paz","","22858","1068827645"
"Trezzano sul Naviglio","Trezzano sul Naviglio","45.4333","9.0667","Italy","IT","ITA","Lombardy","","20956","1380981105"
"Conceição da Feira","Conceicao da Feira","-12.5058","-38.9989","Brazil","BR","BRA","Bahia","","22762","1076395046"
"Garoua Boulaï","Garoua Boulai","5.8833","14.5500","Cameroon","CM","CMR","Est","","22882","1120290274"
"Prāntij","Prantij","23.4384","72.8572","India","IN","IND","Gujarāt","","22306","1356442912"
"Arcos de Valdevez","Arcos de Valdevez","41.8472","-8.4186","Portugal","PT","PRT","Viana do Castelo","minor","22847","1620183489"
"Kalat","Kalat","29.0258","66.5900","Pakistan","PK","PAK","Balochistan","minor","22879","1586475161"
"Robertson","Robertson","-33.8000","19.8833","South Africa","ZA","ZAF","Western Cape","","21929","1710095226"
"Guryevsk","Guryevsk","54.2833","85.9333","Russia","RU","RUS","Kemerovskaya Oblast’","","22872","1643007187"
"Lengerich","Lengerich","52.1750","7.8667","Germany","DE","DEU","North Rhine-Westphalia","","22641","1276617854"
"Allen","Allen","-38.9667","-67.8333","Argentina","AR","ARG","Río Negro","","22859","1032144191"
"Nazca","Nazca","-14.8289","-74.9436","Peru","PE","PER","Ica","","22859","1604291738"
"Patharia","Patharia","23.8992","79.1939","India","IN","IND","Madhya Pradesh","","22684","1356543628"
"Santa Teresita","Santa Teresita","13.8664","120.9814","Philippines","PH","PHL","Batangas","","21559","1608023323"
"Candler-McAfee","Candler-McAfee","33.7267","-84.2723","United States","US","USA","Georgia","","22880","1840073846"
"Moncagua","Moncagua","13.5333","-88.2500","El Salvador","SV","SLV","San Miguel","","22659","1222775983"
"Catamayo","Catamayo","-3.9833","-79.3500","Ecuador","EC","ECU","Loja","","22697","1218400594"
"Winchester","Winchester","42.4518","-71.1463","United States","US","USA","Massachusetts","","22875","1840053496"
"Machesney Park","Machesney Park","42.3666","-89.0266","United States","US","USA","Illinois","","22870","1840011123"
"Glauchau","Glauchau","50.8233","12.5444","Germany","DE","DEU","Saxony","","22440","1276610130"
"San Cataldo","San Cataldo","37.4833","13.9833","Italy","IT","ITA","Sicilia","","22557","1380693837"
"Banovići","Banovici","44.4000","18.5333","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","22773","1070705661"
"Belton","Belton","31.0525","-97.4790","United States","US","USA","Texas","","22866","1840019556"
"Marshall","Marshall","32.5370","-94.3515","United States","US","USA","Texas","","22862","1840020739"
"Aināpur","Ainapur","16.4800","74.7800","India","IN","IND","Karnātaka","","22367","1356401696"
"Naciria","Naciria","36.7500","3.8333","Algeria","DZ","DZA","Boumerdes","","22431","1012065880"
"Manki","Manki","14.1789","74.4818","India","IN","IND","Karnātaka","","22599","1356053114"
"Alnif","Alnif","31.1167","-5.1667","Morocco","MA","MAR","Drâa-Tafilalet","","22724","1504225472"
"Alangāyam","Alangayam","12.6223","78.7521","India","IN","IND","Tamil Nādu","","22243","1356186253"
"Aybastı","Aybasti","40.6867","37.3992","Turkey","TR","TUR","Ordu","minor","22783","1792402028"
"Amtali","Amtali","22.1370","90.2280","Bangladesh","BD","BGD","Barishal","","21808","1050553814"
"Karachayevsk","Karachayevsk","43.7731","41.9169","Russia","RU","RUS","Karachayevo-Cherkesiya","","21067","1643606721"
"Ecoporanga","Ecoporanga","-18.3728","-40.8308","Brazil","BR","BRA","Espírito Santo","","22835","1076291520"
"Saint-Jean-de-Braye","Saint-Jean-de-Braye","47.9128","1.9719","France","FR","FRA","Centre-Val de Loire","","21288","1250168374"
"Kihei","Kihei","20.7653","-156.4454","United States","US","USA","Hawaii","","22840","1840029507"
"Radcliff","Radcliff","37.8204","-85.9365","United States","US","USA","Kentucky","","22839","1840014329"
"Butig","Butig","7.7239","124.3011","Philippines","PH","PHL","Lanao del Sur","","22768","1608715004"
"Shaomi","Shaomi","26.4727","104.8126","China","CN","CHN","Guizhou","","22688","1156070391"
"Nogoyá","Nogoya","-32.4000","-59.8000","Argentina","AR","ARG","Entre Ríos","minor","22824","1032062118"
"Goldasht","Goldasht","32.6267","51.4392","Iran","IR","IRN","Eşfahān","","22693","1364824865"
"Jarqo‘rg‘on","Jarqo\`rg\`on","37.5083","67.4167","Uzbekistan","UZ","UZB","Surxondaryo","","22700","1860148120"
"Cartagena","Cartagena","-33.5425","-71.5958","Chile","CL","CHL","Valparaíso","","22738","1152675574"
"Āmodei","Amodei","26.8975","84.8182","India","IN","IND","Bihār","","21105","1356438929"
"Singur","Singur","22.8100","88.2300","India","IN","IND","West Bengal","","21382","1356135248"
"Działdowo","Dzialdowo","53.2333","20.1833","Poland","PL","POL","Warmińsko-Mazurskie","minor","21279","1616181805"
"Røyken","Royken","59.7336","10.4289","Norway","NO","NOR","Viken","minor","22635","1578092486"
"Chincholi","Chincholi","17.4651","77.4187","India","IN","IND","Karnātaka","","22630","1356831659"
"Itambacuri","Itambacuri","-18.0364","-41.6830","Brazil","BR","BRA","Minas Gerais","","22809","1076323547"
"Phillaur","Phillaur","31.0300","75.7800","India","IN","IND","Punjab","","22228","1356134568"
"Ivato","Ivato","-20.6167","47.2000","Madagascar","MG","MDG","Fianarantsoa","","22745","1450605437"
"Tomarza","Tomarza","38.4472","35.7992","Turkey","TR","TUR","Kayseri","minor","22808","1792500972"
"Katangi","Katangi","23.4412","79.7962","India","IN","IND","Madhya Pradesh","","22521","1356365601"
"Chaves","Chaves","-0.1600","-49.9878","Brazil","BR","BRA","Pará","","22821","1076482622"
"Onondaga","Onondaga","42.9686","-76.2169","United States","US","USA","New York","","22822","1840087751"
"Khosrowshahr","Khosrowshahr","37.9497","46.0508","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","21972","1364295244"
"Nāḩiyat al Kifl","Nahiyat al Kifl","32.2242","44.3767","Iraq","IQ","IRQ","Bābil","","22800","1368308216"
"Whyalla","Whyalla","-33.0333","137.5667","Australia","AU","AUS","South Australia","","21501","1036961366"
"Couëron","Coueron","47.2156","-1.7228","France","FR","FRA","Pays de la Loire","","22309","1250732111"
"Cinco Saltos","Cinco Saltos","-38.8167","-68.0667","Argentina","AR","ARG","Río Negro","","22790","1032470108"
"Curchorem","Curchorem","15.2500","74.1000","India","IN","IND","Goa","","22730","1356224201"
"Prairie Village","Prairie Village","38.9874","-94.6362","United States","US","USA","Kansas","","22812","1840001644"
"Kāchhāri","Kachhari","22.0732","88.4276","India","IN","IND","West Bengal","","21900","1356308388"
"Kadiapattanam","Kadiapattanam","8.1312","77.3067","India","IN","IND","Tamil Nādu","","21684","1356216354"
"Santa Fe de Antioquia","Santa Fe de Antioquia","6.5500","-75.8167","Colombia","CO","COL","Antioquia","minor","22764","1170034183"
"Itaparica","Itaparica","-12.8878","-38.6789","Brazil","BR","BRA","Bahia","","22615","1076639827"
"Wettingen","Wettingen","47.4659","8.3267","Switzerland","CH","CHE","Aargau","","20843","1756544966"
"Kutina","Kutina","45.4667","16.7833","Croatia","HR","HRV","Sisačko-Moslavačka Županija","minor","22760","1191337296"
"Penzance","Penzance","50.1190","-5.5370","United Kingdom","GB","GBR","Cornwall","","21168","1826600240"
"Lazi","Lazi","9.1280","123.6340","Philippines","PH","PHL","Siquijor","","22488","1608438262"
"Howli","Howli","26.4224","90.9800","India","IN","IND","Assam","","22083","1356834463"
"Acatlán","Acatlan","20.1444","-98.4383","Mexico","MX","MEX","Hidalgo","minor","22268","1484652472"
"Béguédo","Beguedo","11.7833","-0.7333","Burkina Faso","BF","BFA","Centre-Est","","21894","1854929240"
"Diriomo","Diriomo","11.8753","-86.0519","Nicaragua","NI","NIC","Granada","minor","22352","1558287966"
"Magallanes","Magallanes","9.0225","125.5179","Philippines","PH","PHL","Agusan del Norte","","22293","1608148038"
"Leland","Leland","34.2042","-78.0279","United States","US","USA","North Carolina","","22794","1840016683"
"Cachoeira do Arari","Cachoeira do Arari","-1.0108","-48.9628","Brazil","BR","BRA","Pará","","22786","1076292118"
"Agrestina","Agrestina","-8.4500","-35.9500","Brazil","BR","BRA","Pernambuco","","22679","1076512287"
"Kigumba","Kigumba","1.8150","32.0100","Uganda","UG","UGA","Kiryandongo","","22000","1800399963"
"Oxford","Oxford","39.5061","-84.7446","United States","US","USA","Ohio","","22783","1840009572"
"Naqādah","Naqadah","25.9000","32.7167","Egypt","EG","EGY","Qinā","","21530","1818662110"
"Velsk","Velsk","61.0667","42.1167","Russia","RU","RUS","Arkhangel’skaya Oblast’","","22776","1643373817"
"Oliveira dos Brejinhos","Oliveira dos Brejinhos","-12.3169","-42.8958","Brazil","BR","BRA","Bahia","","22774","1076923541"
"Cottage Lake","Cottage Lake","47.7466","-122.0755","United States","US","USA","Washington","","22779","1840037848"
"Rixensart","Rixensart","50.7167","4.5333","Belgium","BE","BEL","Wallonia","","22401","1056643005"
"Sarzana","Sarzana","44.1136","9.9600","Italy","IT","ITA","Liguria","","22133","1380361026"
"Baravāt","Baravat","29.0664","58.4047","Iran","IR","IRN","Kermān","","22761","1364538524"
"Lagoa Grande","Lagoa Grande","-8.9969","-40.2719","Brazil","BR","BRA","Pernambuco","","22760","1076319673"
"Svetlyy","Svetlyy","54.6667","20.1333","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","21928","1643742025"
"Tiruvankod","Tiruvankod","8.2452","77.3010","India","IN","IND","Tamil Nādu","","21644","1356090947"
"Forney","Forney","32.7440","-96.4529","United States","US","USA","Texas","","22770","1840020734"
"Asaita","Asaita","11.5667","41.4333","Ethiopia","ET","ETH","Āfar","","22718","1231171609"
"Valašské Meziříčí","Valasske Mezirici","49.4718","17.9712","Czechia","CZ","CZE","Zlínský Kraj","","22149","1203786401"
"Montilla","Montilla","37.5833","-4.6333","Spain","ES","ESP","Andalusia","","22633","1724865599"
"Mrągowo","Mragowo","53.8667","21.3000","Poland","PL","POL","Warmińsko-Mazurskie","minor","21325","1616274741"
"Alhama de Murcia","Alhama de Murcia","37.8514","-1.4264","Spain","ES","ESP","Murcia","","22691","1724947627"
"Łęczna","Leczna","51.3000","22.8833","Poland","PL","POL","Lubelskie","minor","21802","1616600546"
"Beşikdüzü","Besikduzu","41.0494","39.2353","Turkey","TR","TUR","Trabzon","minor","22630","1792243437"
"Massarosa","Massarosa","43.8667","10.3333","Italy","IT","ITA","Tuscany","","22430","1380676955"
"Petrila","Petrila","45.4500","23.4200","Romania","RO","ROU","Hunedoara","","22692","1642937566"
"Villa Park","Villa Park","41.8865","-87.9779","United States","US","USA","Illinois","","22755","1840011412"
"San Miguel de Papasquiaro","San Miguel de Papasquiaro","24.8304","-105.3400","Mexico","MX","MEX","Durango","","22750","1484521716"
"Moguer","Moguer","37.2747","-6.8386","Spain","ES","ESP","Andalusia","","22643","1724821530"
"Iracemápolis","Iracemapolis","-22.5808","-47.5189","Brazil","BR","BRA","São Paulo","","22557","1076095020"
"Kalachinsk","Kalachinsk","55.0500","74.5833","Russia","RU","RUS","Omskaya Oblast’","","22737","1643894191"
"Anthem","Anthem","33.8560","-112.1168","United States","US","USA","Arizona","","22750","1840022919"
"Bambuí","Bambui","-20.0058","-45.9769","Brazil","BR","BRA","Minas Gerais","","22734","1076477568"
"Parabcan","Parabcan","13.7100","123.7450","Philippines","PH","PHL","Camarines Sur","","22591","1608553429"
"Ladyzhyn","Ladyzhyn","48.6897","29.2417","Ukraine","UA","UKR","Vinnytska Oblast","","22682","1804829324"
"Byala Slatina","Byala Slatina","43.4667","23.9333","Bulgaria","BG","BGR","Vratsa","","22716","1100994011"
"Sarangani","Sarangani","5.4120","125.4220","Philippines","PH","PHL","Davao del Sur","","22515","1608711725"
"Arroyito","Arroyito","-31.4167","-63.0500","Argentina","AR","ARG","Córdoba","","22726","1032970192"
"Dom Pedro","Dom Pedro","-5.0328","-44.4358","Brazil","BR","BRA","Maranhão","","22681","1076482404"
"Buritis","Buritis","-15.6178","-46.4228","Brazil","BR","BRA","Minas Gerais","","22737","1076783642"
"Perundurai","Perundurai","11.2777","77.5837","India","IN","IND","Tamil Nādu","","22364","1356607726"
"Pinheiral","Pinheiral","-22.5128","-44.0008","Brazil","BR","BRA","Rio de Janeiro","","22719","1076878276"
"Buenavista","Buenavista","8.2233","-75.4817","Colombia","CO","COL","Córdoba","minor","22711","1170230620"
"Donaueschingen","Donaueschingen","47.9531","8.5033","Germany","DE","DEU","Baden-Württemberg","","22526","1276107502"
"Timmāpur","Timmapur","19.0484","78.6414","India","IN","IND","Andhra Pradesh","","22608","1356159166"
"Rosita","Rosita","13.9239","-84.3989","Nicaragua","NI","NIC","Costa Caribe Norte","minor","22723","1558197649"
"Amalfi","Amalfi","6.9167","-75.0833","Colombia","CO","COL","Antioquia","minor","22714","1170542286"
"Rāvar","Ravar","31.2656","56.8056","Iran","IR","IRN","Kermān","minor","22729","1364603087"
"Alta Floresta D’Oeste","Alta Floresta D'Oeste","-11.9283","-61.9950","Brazil","BR","BRA","Rondônia","","22728","1076909732"
"Staraya Kupavna","Staraya Kupavna","55.8000","38.1667","Russia","RU","RUS","Moskovskaya Oblast’","","22476","1643193878"
"Árta","Arta","39.1650","20.9875","Greece","GR","GRC","Ípeiros","minor","21895","1300006848"
"Peters","Peters","40.2739","-80.0803","United States","US","USA","Pennsylvania","","22728","1840147982"
"Aljaraque","Aljaraque","37.2667","-7.0167","Spain","ES","ESP","Andalusia","","22078","1724459161"
"Manzanares","Manzanares","5.2519","-75.1569","Colombia","CO","COL","Caldas","minor","22623","1170983089"
"Metzingen","Metzingen","48.5367","9.2858","Germany","DE","DEU","Baden-Württemberg","","22084","1276043043"
"Raymore","Raymore","38.8029","-94.4583","United States","US","USA","Missouri","","22722","1840009787"
"Seynod","Seynod","45.8889","6.0961","France","FR","FRA","Auvergne-Rhône-Alpes","","21595","1250597397"
"Frameries","Frameries","50.4088","3.8906","Belgium","BE","BEL","Wallonia","","21878","1056190283"
"Sāmbhar","Sambhar","26.9103","75.1858","India","IN","IND","Rājasthān","","22327","1356819322"
"Ambriz","Ambriz","-7.8500","13.1167","Angola","AO","AGO","Bengo","","22712","1024609563"
"Lefkáda","Lefkada","38.7178","20.6439","Greece","GR","GRC","Ionía Nísia","minor","22652","1300961833"
"Šaľa","Sal'a","48.1503","17.8775","Slovakia","SK","SVK","Nitra","minor","22219","1703397102"
"Apolda","Apolda","51.0167","11.5167","Germany","DE","DEU","Thuringia","minor","22232","1276857102"
"East Peoria","East Peoria","40.6736","-89.5419","United States","US","USA","Illinois","","22707","1840008327"
"Supaul","Supaul","25.9469","86.2497","India","IN","IND","Bihār","","20747","1356341294"
"Friesoythe","Friesoythe","53.0206","7.8586","Germany","DE","DEU","Lower Saxony","","22612","1276189033"
"Mondovì","Mondovi","44.3833","7.8167","Italy","IT","ITA","Piedmont","","22444","1380965042"
"Alnāvar","Alnavar","15.4273","74.7411","India","IN","IND","Karnātaka","","21502","1356106746"
"Mandelieu-la-Napoule","Mandelieu-la-Napoule","43.5464","6.9381","France","FR","FRA","Provence-Alpes-Côte d’Azur","","21998","1250852551"
"Pushkar","Pushkar","26.4903","74.5542","India","IN","IND","Rājasthān","","21626","1356618005"
"Naawan","Naawan","8.4333","124.3000","Philippines","PH","PHL","Misamis Oriental","","22444","1608771890"
"Zhengtun","Zhengtun","25.1494","105.0802","China","CN","CHN","Guizhou","","22400","1156232482"
"Magdalena","Magdalena","20.9092","-103.9803","Mexico","MX","MEX","Jalisco","minor","22643","1484476169"
"Uenohara","Uenohara","35.6302","139.1113","Japan","JP","JPN","Kanagawa","","22557","1392602835"
"Khaw Zar Chaung Wa","Khaw Zar Chaung Wa","15.0375","97.8293","Myanmar","MM","MMR","Mon State","","22663","1104706858"
"Oytal","Oytal","42.9153","73.2549","Kazakhstan","KZ","KAZ","","","22673","1398816918"
"Pickerington","Pickerington","39.8890","-82.7678","United States","US","USA","Ohio","","22682","1840009528"
"Lucala","Lucala","-9.6333","14.0667","Angola","AO","AGO","Bengo","","22674","1024404685"
"Sondrio","Sondrio","46.1697","9.8700","Italy","IT","ITA","Lombardy","minor","21642","1380709664"
"Mānjhi","Manjhi","25.8384","84.5788","India","IN","IND","Bihār","","21007","1356427473"
"Llaillay","Llaillay","-32.8403","-70.9483","Chile","CL","CHL","Valparaíso","","22659","1152335361"
"Souk Tlet El Gharb","Souk Tlet El Gharb","34.6211","-6.1336","Morocco","MA","MAR","Rabat-Salé-Kénitra","","22554","1504595315"
"Pachino","Pachino","36.7167","15.1000","Italy","IT","ITA","Sicilia","","22237","1380098905"
"Forquilhinha","Forquilhinha","-28.7469","-49.4719","Brazil","BR","BRA","Santa Catarina","","22548","1076176111"
"Lochristi","Lochristi","51.1000","3.8333","Belgium","BE","BEL","Flanders","","22300","1056543917"
"Le Creusot","Le Creusot","46.8014","4.4411","France","FR","FRA","Bourgogne-Franche-Comté","","21491","1250722828"
"Duncan","Duncan","34.5408","-97.9215","United States","US","USA","Oklahoma","","22664","1840019253"
"Sant’Arcangelo di Romagna","Sant'Arcangelo di Romagna","44.0633","12.4466","Italy","IT","ITA","Emilia-Romagna","","22171","1380993276"
"Huangzhai","Huangzhai","38.0605","112.6701","China","CN","CHN","Shanxi","minor","22233","1156097233"
"Talwandi Sābo","Talwandi Sabo","29.9838","75.0820","India","IN","IND","Punjab","","22115","1356589470"
"Quemado de Güines","Quemado de Guines","22.7900","-80.2561","Cuba","CU","CUB","Villa Clara","minor","22590","1192892274"
"Ouani","Ouani","-12.1350","44.4275","Comoros","KM","COM","Anjouan","","22501","1174574251"
"Lajas","Lajas","22.4164","-80.2906","Cuba","CU","CUB","Cienfuegos","minor","22602","1192894974"
"Darton","Darton","53.5850","-1.5325","United Kingdom","GB","GBR","Barnsley","","21345","1826106593"
"Taulahā","Taulaha","26.9566","84.0731","India","IN","IND","Bihār","","22321","1356770484"
"Annecy-le-Vieux","Annecy-le-Vieux","45.9192","6.1419","France","FR","FRA","Auvergne-Rhône-Alpes","","21390","1250494600"
"Pudsey","Pudsey","53.7970","-1.6630","United Kingdom","GB","GBR","Leeds","","22408","1826326804"
"Easley","Easley","34.8188","-82.5827","United States","US","USA","South Carolina","","22643","1840013526"
"Tocantinópolis","Tocantinopolis","-6.3289","-47.4158","Brazil","BR","BRA","Tocantins","","22619","1076917053"
"Singampunari","Singampunari","10.1815","78.4231","India","IN","IND","Tamil Nādu","","22252","1356208126"
"Goleniów","Goleniow","53.5636","14.8281","Poland","PL","POL","Zachodniopomorskie","minor","22553","1616391921"
"Saint-Genis-Laval","Saint-Genis-Laval","45.6960","4.7930","France","FR","FRA","Auvergne-Rhône-Alpes","","21019","1250513560"
"Qādiān","Qadian","31.8192","75.3764","India","IN","IND","Punjab","","21899","1356976129"
"Morombe","Morombe","-21.7500","43.3667","Madagascar","MG","MDG","Toliara","","22625","1450018559"
"Bom Jesus","Bom Jesus","-9.0739","-44.3589","Brazil","BR","BRA","Piauí","","22629","1076937557"
"Lempäälä","Lempaala","61.3167","23.7500","Finland","FI","FIN","Pirkanmaa","minor","22536","1246708138"
"Artémida","Artemida","37.9667","24.0000","Greece","GR","GRC","Attikí","","21488","1300514768"
"Sek’ot’a","Sek'ot'a","12.6253","39.0350","Ethiopia","ET","ETH","Āmara","","22346","1231550759"
"Yahualica de González Gallo","Yahualica de Gonzalez Gallo","21.1781","-102.8860","Mexico","MX","MEX","Jalisco","minor","22586","1484271707"
"Eislingen","Eislingen","48.6933","9.7067","Germany","DE","DEU","Baden-Württemberg","","21329","1276267974"
"Medjez el Bab","Medjez el Bab","36.6436","9.6042","Tunisia","TN","TUN","Béja","","22532","1788828392"
"Cartersville","Cartersville","34.1644","-84.8009","United States","US","USA","Georgia","","22626","1840014701"
"Los Llanos","Los Llanos","18.6167","-69.5000","Dominican Republic","DO","DOM","Higuamo","","22573","1214827487"
"Bukkarāyasamudram","Bukkarayasamudram","14.6944","77.6381","India","IN","IND","Andhra Pradesh","","22000","1356973726"
"Sun Valley","Sun Valley","39.6104","-119.7770","United States","US","USA","Nevada","","22622","1840018711"
"Omagh","Omagh","54.5900","-7.2900","United Kingdom","GB","GBR","Fermanagh and Omagh","","21056","1826686995"
"Umbaúba","Umbauba","-11.3828","-37.6578","Brazil","BR","BRA","Sergipe","","22434","1076584847"
"Pāpanāsam","Papanasam","10.9273","79.2708","India","IN","IND","Tamil Nādu","","21653","1356776070"
"Junnārdev","Junnardev","22.2000","78.5800","India","IN","IND","Madhya Pradesh","","22426","1356419381"
"Bhadaur","Bhadaur","30.4764","75.3306","India","IN","IND","Punjab","","22186","1356265101"
"Corcoran","Corcoran","36.0841","-119.5613","United States","US","USA","California","","22616","1840019056"
"Stadthagen","Stadthagen","52.3247","9.2069","Germany","DE","DEU","Lower Saxony","minor","22247","1276512771"
"Gonghe","Gonghe","35.3308","108.0885","China","CN","CHN","Gansu","","22375","1156000394"
"Quaraí","Quarai","-30.3878","-56.4508","Brazil","BR","BRA","Rio Grande do Sul","","22607","1076203994"
"Burg","Burg","52.2725","11.8550","Germany","DE","DEU","Saxony-Anhalt","minor","22478","1276295040"
"Flörsheim","Florsheim","50.0167","8.4333","Germany","DE","DEU","Hesse","","21671","1276463362"
"Furukawa","Furukawa","36.2381","137.1862","Japan","JP","JPN","Gifu","","22585","1392467496"
"Bellview","Bellview","30.4620","-87.3120","United States","US","USA","Florida","","22613","1840013912"
"Bilthoven","Bilthoven","52.1283","5.1986","Netherlands","NL","NLD","Utrecht","minor","21984","1528062799"
"Makó","Mako","46.2170","20.4830","Hungary","HU","HUN","Csongrád-Csanád","minor","22514","1348716786"
"Ganapavaram","Ganapavaram","16.1233","80.1721","India","IN","IND","Andhra Pradesh","","21457","1356029973"
"Şirvan","Sirvan","38.0633","42.0275","Turkey","TR","TUR","Siirt","minor","22580","1792671782"
"Isperih","Isperih","43.7167","26.8333","Bulgaria","BG","BGR","Razgrad","","22556","1100443393"
"Gurmatkāl","Gurmatkal","16.8677","77.3909","India","IN","IND","Karnātaka","","22338","1356040973"
"Biswanath Chariali","Biswanath Chariali","26.7278","93.1517","India","IN","IND","Assam","","22209","1356710319"
"Yokoshibahikari","Yokoshibahikari","35.6658","140.5048","Japan","JP","JPN","Chiba","","22271","1392003398"
"Puente Nacional","Puente Nacional","19.3333","-96.4833","Mexico","MX","MEX","Veracruz","minor","22454","1484974086"
"San Antonio La Paz","San Antonio La Paz","14.7500","-90.2833","Guatemala","GT","GTM","El Progreso","minor","22493","1320686525"
"Chinameca","Chinameca","13.5000","-88.3500","El Salvador","SV","SLV","San Miguel","","22311","1222091549"
"Huautla","Huautla","21.0308","-98.2850","Mexico","MX","MEX","Hidalgo","","22521","1484208664"
"Paraopeba","Paraopeba","-19.2739","-44.4039","Brazil","BR","BRA","Minas Gerais","","22563","1076363838"
"Sardinata","Sardinata","8.0833","-72.8000","Colombia","CO","COL","Norte de Santander","minor","22583","1170154538"
"Summit","Summit","40.7154","-74.3647","United States","US","USA","New Jersey","","22598","1840000999"
"Goianinha","Goianinha","-6.2669","-35.2100","Brazil","BR","BRA","Rio Grande do Norte","","22481","1076687499"
"Pāmarru","Pamarru","16.3270","80.9610","India","IN","IND","Andhra Pradesh","","21395","1356135473"
"Sivagiri","Sivagiri","11.1200","77.8000","India","IN","IND","Tamil Nādu","","21456","1356934751"
"Chāgallu","Chagallu","16.9833","81.6667","India","IN","IND","Andhra Pradesh","","21703","1356269485"
"Dawmat al Jandal","Dawmat al Jandal","29.8153","39.8664","Saudi Arabia","SA","SAU","Al Jawf","","22583","1682116359"
"Gölhisar","Golhisar","37.1408","29.5075","Turkey","TR","TUR","Burdur","minor","22519","1792026126"
"Oshwe","Oshwe","-3.4188","19.4334","Congo (Kinshasa)","CD","COD","Mai-Ndombe","","22576","1180864578"
"Porto Torres","Porto Torres","40.8369","8.4014","Italy","IT","ITA","Sardegna","","22367","1380199561"
"Sandbach","Sandbach","53.1460","-2.3670","United Kingdom","GB","GBR","Cheshire East","","21916","1826911682"
"Piddig","Piddig","18.1647","120.7172","Philippines","PH","PHL","Ilocos Norte","","22475","1608284501"
"Watertown","Watertown","44.9094","-97.1532","United States","US","USA","South Dakota","","22577","1840002156"
"Batad","Batad","11.4167","123.1167","Philippines","PH","PHL","Iloilo","","22157","1608532395"
"Bélabo","Belabo","4.9333","13.3000","Cameroon","CM","CMR","Est","","22553","1120106572"
"Paese","Paese","45.6667","12.1500","Italy","IT","ITA","Veneto","","21992","1380092506"
"Ariano Irpino","Ariano Irpino","41.1528","15.0889","Italy","IT","ITA","Campania","","22448","1380664255"
"Martellago","Martellago","45.5467","12.1575","Italy","IT","ITA","Veneto","","21502","1380825761"
"Kadiria","Kadiria","36.5333","3.6833","Algeria","DZ","DZA","Bouira","","22327","1012278992"
"Biggleswade","Biggleswade","52.0855","-0.2557","United Kingdom","GB","GBR","Central Bedfordshire","","21700","1826800454"
"Fougères","Fougeres","48.3525","-1.1986","France","FR","FRA","Bretagne","minor","20595","1250890838"
"Río Ceballos","Rio Ceballos","-31.1667","-64.3167","Argentina","AR","ARG","Córdoba","","22432","1032130097"
"Wenceslau Guimarães","Wenceslau Guimaraes","-13.6869","-39.4789","Brazil","BR","BRA","Bahia","","22530","1076558308"
"Caravelas","Caravelas","-17.7319","-39.2658","Brazil","BR","BRA","Bahia","","22548","1076667484"
"Braine-le-Comte","Braine-le-Comte","50.6000","4.1333","Belgium","BE","BEL","Wallonia","","22290","1056000009"
"Buftea","Buftea","44.5700","25.9500","Romania","RO","ROU","Ilfov","","22178","1642134624"
"Ginosa","Ginosa","40.5000","16.7500","Italy","IT","ITA","Puglia","","22430","1380281287"
"Cruz","Cruz","-2.9178","-40.1719","Brazil","BR","BRA","Ceará","","22479","1076550734"
"Barra Velha","Barra Velha","-26.6319","-48.6850","Brazil","BR","BRA","Santa Catarina","","22386","1076273407"
"Doutou","Doutou","6.5500","1.8833","Benin","BJ","BEN","Mono","","22099","1204517894"
"Las Torres de Cotillas","Las Torres de Cotillas","38.0264","-1.2436","Spain","ES","ESP","Andalusia","","21980","1724150041"
"Agropoli","Agropoli","40.3500","14.9833","Italy","IT","ITA","Campania","","21874","1380689774"
"Pogradec","Pogradec","40.9000","20.6500","Albania","AL","ALB","Korçë","","20848","1008366566"
"Heide","Heide","54.1961","9.0933","Germany","DE","DEU","Schleswig-Holstein","minor","21852","1276473220"
"Santo Antônio de Posse","Santo Antonio de Posse","-22.6058","-46.9189","Brazil","BR","BRA","São Paulo","","22389","1076962973"
"Eustis","Eustis","28.8563","-81.6771","United States","US","USA","Florida","","22532","1840014054"
"Jaipur Chuhar","Jaipur Chuhar","25.4187","86.8352","India","IN","IND","Bihār","","21651","1356592786"
"Sevlievo","Sevlievo","43.0256","25.1133","Bulgaria","BG","BGR","Gabrovo","","22503","1100820915"
"Quickborn","Quickborn","53.7333","9.8972","Germany","DE","DEU","Schleswig-Holstein","","22015","1276737039"
"Mutatá","Mutata","7.2442","-76.4358","Colombia","CO","COL","Antioquia","minor","22505","1170918759"
"Kundgol","Kundgol","15.2561","75.2474","India","IN","IND","Karnātaka","","22228","1356628344"
"Árgos","Argos","37.6167","22.7167","Greece","GR","GRC","Pelopónnisos","minor","22085","1300389064"
"Radevormwald","Radevormwald","51.2000","7.3500","Germany","DE","DEU","North Rhine-Westphalia","","22107","1276941483"
"Jebba","Jebba","9.1539","4.8119","Nigeria","NG","NGA","Kwara","","22411","1566859918"
"Nadvirna","Nadvirna","48.6333","24.5833","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","22447","1804082259"
"Gaoguzhuang","Gaoguzhuang","37.8364","115.5016","China","CN","CHN","Hebei","","22208","1156682072"
"Chester","Chester","37.3531","-77.4342","United States","US","USA","Virginia","","22512","1840006410"
"Santa Fe","Santa Fe","11.1856","124.9161","Philippines","PH","PHL","Leyte","","22102","1608498927"
"Khadyzhensk","Khadyzhensk","44.4256","39.5319","Russia","RU","RUS","Krasnodarskiy Kray","","22468","1643592689"
"Saktī","Sakti","22.0300","82.9700","India","IN","IND","Chhattīsgarh","","21955","1356825451"
"Ambohimanga","Ambohimanga","-18.7667","47.5667","Madagascar","MG","MDG","Antananarivo","","20872","1450695670"
"Auch","Auch","43.6465","0.5855","France","FR","FRA","Occitanie","minor","22200","1250746513"
"Vilcún","Vilcun","-38.6500","-72.2333","Chile","CL","CHL","Araucanía","","22499","1152236518"
"Geertruidenberg","Geertruidenberg","51.7000","4.8586","Netherlands","NL","NLD","Noord-Brabant","","21770","1528001604"
"Kannānendal","Kannanendal","9.9649","78.1416","India","IN","IND","Tamil Nādu","","21137","1356236133"
"Sucre","Sucre","8.8139","-74.7253","Colombia","CO","COL","Sucre","minor","22480","1170782443"
"Maribojoc","Maribojoc","9.7500","123.8500","Philippines","PH","PHL","Bohol","minor","22178","1608369009"
"Karnobat","Karnobat","42.6333","26.9833","Bulgaria","BG","BGR","Burgas","","22464","1100484520"
"Upper Allen","Upper Allen","40.1800","-76.9808","United States","US","USA","Pennsylvania","","22491","1840151634"
"Malaba","Malaba","0.6444","34.2606","Uganda","UG","UGA","Tororo","","20800","1800629392"
"Zhedao","Zhedao","24.8098","98.2961","China","CN","CHN","Yunnan","minor","22290","1156435456"
"Taixi","Taixi","23.7000","120.2000","Taiwan","TW","TWN","Yunlin","","22077","1158631451"
"Kālchīni","Kalchini","26.6979","89.4568","India","IN","IND","West Bengal","","22072","1356460672"
"Ấp Phú Mỹ","Ap Phu My","9.7500","106.0000","Vietnam","VN","VNM","Sóc Trăng","","21931","1704694419"
"Vendram","Vendram","16.5360","81.6230","India","IN","IND","Andhra Pradesh","","21718","1356457139"
"Zambrów","Zambrow","52.9833","22.2500","Poland","PL","POL","Podlaskie","minor","21351","1616716876"
"Rupauli","Rupauli","25.8695","87.0600","India","IN","IND","Bihār","","21186","1356970583"
"Gorgonzola","Gorgonzola","45.5333","9.4000","Italy","IT","ITA","Lombardy","","20529","1380436916"
"Padmanābhapuram","Padmanabhapuram","8.2446","77.3367","India","IN","IND","Tamil Nādu","","21342","1356030422"
"South Ockendon","South Ockendon","51.5207","0.2956","United Kingdom","GB","GBR","Thurrock","","22303","1826422421"
"Srīperumbūdūr","Sriperumbudur","12.9680","79.9473","India","IN","IND","Tamil Nādu","","21325","1356588291"
"Chatra Gobraura","Chatra Gobraura","26.4973","86.2179","India","IN","IND","Bihār","","21769","1356380636"
"Dasnāpur","Dasnapur","19.6536","78.5114","India","IN","IND","Andhra Pradesh","","22216","1356628600"
"Halluin","Halluin","50.7836","3.1256","France","FR","FRA","Hauts-de-France","","20808","1250891444"
"Ibatiba","Ibatiba","-20.2364","-41.5092","Brazil","BR","BRA","Espírito Santo","","22366","1076060126"
"Koksijde","Koksijde","51.1000","2.6500","Belgium","BE","BEL","Flanders","","21957","1056544281"
"Betsizaraina","Betsizaraina","-19.9500","48.7500","Madagascar","MG","MDG","Toamasina","","22409","1450919460"
"Soyāgaon","Soyagaon","20.5514","74.5072","India","IN","IND","Mahārāshtra","","21822","1356671280"
"El Colegio","El Colegio","4.5808","-74.4425","Colombia","CO","COL","Cundinamarca","minor","22247","1170000628"
"Yuli","Yuli","23.3875","121.3764","Taiwan","TW","TWN","Hualien","","22356","1158695945"
"Zirāpur","Zirapur","24.0222","76.3771","India","IN","IND","Madhya Pradesh","","22205","1356098977"
"Boquira","Boquira","-12.8228","-42.7308","Brazil","BR","BRA","Bahia","","22429","1076113400"
"Namayumba","Namayumba","0.5281","32.2506","Uganda","UG","UGA","Wakiso","","22100","1800060213"
"Tepexi de Rodríguez","Tepexi de Rodriguez","18.5797","-97.9267","Mexico","MX","MEX","Puebla","","20598","1484944836"
"Gardelegen","Gardelegen","52.5264","11.3925","Germany","DE","DEU","Saxony-Anhalt","","22402","1276557570"
"Bloomingdale","Bloomingdale","41.9497","-88.0895","United States","US","USA","Illinois","","22436","1840011399"
"Ōtsuki","Otsuki","35.6106","138.9400","Japan","JP","JPN","Yamanashi","","22356","1392398648"
"Kopřivnice","Koprivnice","49.5995","18.1448","Czechia","CZ","CZE","Moravskoslezský Kraj","","21657","1203847096"
"Aleksandrów Łódzki","Aleksandrow Lodzki","51.8194","19.3039","Poland","PL","POL","Łódzkie","","22148","1616531721"
"Otopeni","Otopeni","44.5500","26.0700","Romania","RO","ROU","Ilfov","","21750","1642216985"
"Arouca","Arouca","40.9338","-8.2449","Portugal","PT","PRT","Aveiro","minor","22359","1620642425"
"Schwetzingen","Schwetzingen","49.3833","8.5667","Germany","DE","DEU","Baden-Württemberg","","21435","1276516691"
"Juatuba","Juatuba","-19.9519","-44.3428","Brazil","BR","BRA","Minas Gerais","","22202","1076016491"
"Palmas de Monte Alto","Palmas de Monte Alto","-14.2669","-43.1619","Brazil","BR","BRA","Bahia","","22416","1076381491"
"Arbi’a Tighadwiyn","Arbi'a Tighadwiyn","31.4236","-7.5208","Morocco","MA","MAR","Marrakech-Safi","","22353","1504625035"
"Sarapāka","Sarapaka","17.6922","80.8614","India","IN","IND","Andhra Pradesh","","22149","1356331264"
"Şā al Ḩajar","Sa al Hajar","30.9647","30.7683","Egypt","EG","EGY","Al Gharbīyah","","20568","1818595696"
"Tiruppuvanam","Tiruppuvanam","9.8550","78.2744","India","IN","IND","Tamil Nādu","","21350","1356482598"
"Târnăveni","Tarnaveni","46.3297","24.2700","Romania","RO","ROU","Mureş","","22075","1642275183"
"Lincoln","Lincoln","41.9171","-71.4505","United States","US","USA","Rhode Island","","22415","1840106233"
"Vakon","Vakon","6.5167","2.5667","Benin","BJ","BEN","Ouémé","","20541","1204060141"
"Barra da Estiva","Barra da Estiva","-13.6258","-41.3269","Brazil","BR","BRA","Bahia","","22394","1076633859"
"Zurbāţīyah","Zurbatiyah","33.1500","46.0500","Iraq","IQ","IRQ","Wāsiţ","","22400","1368601094"
"Gubbi","Gubbi","13.3122","76.9410","India","IN","IND","Karnātaka","","22182","1356784156"
"Klatovy","Klatovy","49.3956","13.2951","Czechia","CZ","CZE","Plzeňský Kraj","","22140","1203400126"
"Santa Isabel do Rio Negro","Santa Isabel do Rio Negro","-0.4139","-65.0189","Brazil","BR","BRA","Amazonas","","22404","1076252354"
"Hockenheim","Hockenheim","49.3181","8.5472","Germany","DE","DEU","Baden-Württemberg","","21779","1276940967"
"Phulpur","Phulpur","25.5500","82.1000","India","IN","IND","Uttar Pradesh","","21066","1356547940"
"Daxin","Daxin","26.6210","107.2398","China","CN","CHN","Guizhou","","22311","1156737791"
"Vernon","Vernon","41.1973","-74.4857","United States","US","USA","New Jersey","","22399","1840081777"
"Zvenigorod","Zvenigorod","55.7333","36.8500","Russia","RU","RUS","Moskovskaya Oblast’","","21931","1643823915"
"Dudelange","Dudelange","49.4833","6.0833","Luxembourg","LU","LUX","Esch-sur-Alzette","minor","21513","1442627138"
"Casal di Principe","Casal di Principe","41.0167","14.1333","Italy","IT","ITA","Campania","","21482","1380491026"
"Borgomanero","Borgomanero","45.7000","8.4667","Italy","IT","ITA","Piedmont","","21719","1380548990"
"Mendeleyevsk","Mendeleyevsk","55.9000","52.3333","Russia","RU","RUS","Tatarstan","","22336","1643860674"
"Cornaredo","Cornaredo","45.5000","9.0333","Italy","IT","ITA","Lombardy","","20534","1380437222"
"Goiás","Goias","-15.9339","-50.1400","Brazil","BR","BRA","Goiás","","22381","1076649363"
"Sevilimedu","Sevilimedu","12.8083","79.6864","India","IN","IND","Tamil Nādu","","21285","1356926613"
"Zhucaoying","Zhucaoying","40.1759","119.5909","China","CN","CHN","Hebei","","22276","1156215779"
"Chaumont","Chaumont","48.1117","5.1389","France","FR","FRA","Grand Est","minor","21990","1250139461"
"Guérou","Guerou","16.8167","-11.8333","Mauritania","MR","MRT","Assaba","","22323","1478121598"
"Chankou","Chankou","35.7754","104.5345","China","CN","CHN","Gansu","","22273","1156838063"
"La Cruz","La Cruz","-32.8281","-71.1761","Chile","CL","CHL","Valparaíso","","22098","1152948474"
"Atuntaqui","Atuntaqui","0.3317","-78.2189","Ecuador","EC","ECU","Imbabura","","21286","1218840955"
"Masis","Masis","40.0633","44.4408","Armenia","AM","ARM","Ararat","","21376","1051588635"
"Binnish","Binnish","35.9500","36.7000","Syria","SY","SYR","Idlib","minor","21848","1760335847"
"Takahata","Takahata","38.0027","140.1891","Japan","JP","JPN","Yamagata","","22242","1392054677"
"Guaratinga","Guaratinga","-16.5839","-39.7828","Brazil","BR","BRA","Bahia","","22355","1076729766"
"Yahualica","Yahualica","20.9531","-98.3800","Mexico","MX","MEX","Hidalgo","","22228","1484980764"
"Greenwood","Greenwood","34.1947","-82.1542","United States","US","USA","South Carolina","","22363","1840013626"
"Senekane","Senekane","-29.2525","27.7433","Lesotho","LS","LSO","Berea","","22262","1426773784"
"Chapa de Mota","Chapa de Mota","19.8144","-99.5269","Mexico","MX","MEX","México","minor","21746","1484616953"
"Caraí","Carai","-17.1889","-41.6950","Brazil","BR","BRA","Minas Gerais","","22343","1076396807"
"Pākāla","Pakala","13.4667","79.1167","India","IN","IND","Andhra Pradesh","","21565","1356159620"
"San Jose","San Jose","9.4167","123.2333","Philippines","PH","PHL","Negros Oriental","","21956","1608586571"
"Gīlān-e Gharb","Gilan-e Gharb","34.1422","45.9203","Iran","IR","IRN","Kermānshāh","minor","22331","1364195329"
"Cisnădie","Cisnadie","45.7128","24.1508","Romania","RO","ROU","Sibiu","","22277","1642003986"
"Hoogezand","Hoogezand","53.1572","6.7533","Netherlands","NL","NLD","Groningen","minor","21830","1528523949"
"Ulus","Ulus","41.5861","32.6406","Turkey","TR","TUR","Bartın","minor","22333","1792700033"
"Vohimasina","Vohimasina","-21.7333","48.1333","Madagascar","MG","MDG","Fianarantsoa","","22000","1450287658"
"Rajauli","Rajauli","24.6449","85.5003","India","IN","IND","Bihār","","21846","1356490411"
"Ḩārim","Harim","36.2000","36.5167","Syria","SY","SYR","Idlib","minor","21934","1760216782"
"Anamalais","Anamalais","10.5830","76.9344","India","IN","IND","Tamil Nādu","","21736","1356352228"
"Morinda","Morinda","30.7900","76.5000","India","IN","IND","Punjab","","21788","1356124805"
"Waldkirch","Waldkirch","48.1000","7.9667","Germany","DE","DEU","Baden-Württemberg","","21899","1276926019"
"Anini-y","Anini-y","10.4325","121.9253","Philippines","PH","PHL","Antique","","22018","1608618256"
"Qorako‘l Shahri","Qorako\`l Shahri","39.5028","63.8556","Uzbekistan","UZ","UZB","Buxoro","minor","22300","1860899143"
"Colorado","Colorado","-22.8378","-51.9728","Brazil","BR","BRA","Paraná","","22345","1076860546"
"Fantino","Fantino","19.1200","-70.3000","Dominican Republic","DO","DOM","Cibao Sur","","22117","1214690546"
"Natagaima","Natagaima","3.5833","-75.0833","Colombia","CO","COL","Tolima","minor","22320","1170715642"
"Ingabu","Ingabu","17.8167","95.2667","Myanmar","MM","MMR","Ayeyarwady","","22225","1104461128"
"Saurh","Saurh","25.3789","86.8223","India","IN","IND","Bihār","","21134","1356053801"
"Mārutūru","Maruturu","15.9862","80.1051","India","IN","IND","Andhra Pradesh","","21434","1356098911"
"Fernley","Fernley","39.5627","-119.1906","United States","US","USA","Nevada","","22343","1840020214"
"Guabo","Guabo","-3.2388","-79.8292","Ecuador","EC","ECU","El Oro","","22172","1218736994"
"Montville","Montville","40.9135","-74.3594","United States","US","USA","New Jersey","","22340","1840081737"
"Bronkhorstspruit","Bronkhorstspruit","-25.8050","28.7464","South Africa","ZA","ZAF","Gauteng","","20769","1710969025"
"Irauçuba","Iraucuba","-3.7458","-39.7828","Brazil","BR","BRA","Ceará","","22324","1076060392"
"Palestrina","Palestrina","41.8333","12.9000","Italy","IT","ITA","Lazio","","21872","1380751637"
"Sulejówek","Sulejowek","52.2442","21.2800","Poland","PL","POL","Mazowieckie","","21248","1616515972"
"Murayama","Murayama","38.4833","140.3833","Japan","JP","JPN","Yamagata","","22222","1392537300"
"Ampasimanjeva","Ampasimanjeva","-21.7333","48.0333","Madagascar","MG","MDG","Fianarantsoa","","22000","1450896480"
"Lyman","Lyman","48.9853","37.8111","Ukraine","UA","UKR","Donetska Oblast","","22315","1804424570"
"El Doncello","El Doncello","1.6833","-75.2833","Colombia","CO","COL","Caquetá","minor","22306","1170237364"
"Biddeford","Biddeford","43.4673","-70.4511","United States","US","USA","Maine","","22331","1840000342"
"El Tortuguero","El Tortuguero","12.8183","-84.2006","Nicaragua","NI","NIC","Costa Caribe Sur","minor","22324","1558390224"
"São João do Paraíso","Sao Joao do Paraiso","-15.3139","-42.0139","Brazil","BR","BRA","Minas Gerais","","22319","1076830423"
"Salcedo","Salcedo","11.1500","125.6667","Philippines","PH","PHL","Eastern Samar","","22136","1608306893"
"Acworth","Acworth","34.0566","-84.6716","United States","US","USA","Georgia","","22330","1840013688"
"Hallim","Hallim","33.3729","126.2827","South Korea","KR","KOR","Jeju","","22087","1410693280"
"Priego de Córdoba","Priego de Cordoba","37.4333","-4.1833","Spain","ES","ESP","Andalusia","","22251","1724212027"
"Husnābād","Husnabad","17.0667","77.6500","India","IN","IND","Andhra Pradesh","","22082","1356546891"
"Bahçe","Bahce","37.2000","36.5833","Turkey","TR","TUR","Osmaniye","minor","22242","1792557707"
"Guazacapán","Guazacapan","14.0751","-90.4161","Guatemala","GT","GTM","Santa Rosa","minor","22197","1320179300"
"Rioja","Rioja","-6.0500","-77.1417","Peru","PE","PER","San Martín","","22197","1604043795"
"Alakamisy-Ambohimaha","Alakamisy-Ambohimaha","-21.3167","47.2167","Madagascar","MG","MDG","Fianarantsoa","","22000","1450325718"
"Pingtan","Pingtan","23.2524","111.4120","China","CN","CHN","Guangxi","","22120","1156658366"
"Nikolskoye","Nikolskoye","59.7000","30.7833","Russia","RU","RUS","Leningradskaya Oblast’","","22286","1643810900"
"Feira Grande","Feira Grande","-9.9000","-36.6778","Brazil","BR","BRA","Alagoas","","22178","1076009834"
"Ban Pa Tueng","Ban Pa Tueng","20.1339","99.8325","Thailand","TH","THA","Chiang Rai","","22139","1764197530"
"Gardanne","Gardanne","43.4553","5.4760","France","FR","FRA","Provence-Alpes-Côte d’Azur","","21521","1250144210"
"Ituporanga","Ituporanga","-27.4139","-49.6008","Brazil","BR","BRA","Santa Catarina","","22250","1076063095"
"Kuppam","Kuppam","12.7450","78.3440","India","IN","IND","Andhra Pradesh","","21963","1356676774"
"Lyndhurst","Lyndhurst","40.7965","-74.1099","United States","US","USA","New Jersey","","22315","1840081755"
"Ouardenine","Ouardenine","35.7200","10.6700","Tunisia","TN","TUN","Monastir","","21814","1788833416"
"Djinet","Djinet","36.8770","3.7231","Algeria","DZ","DZA","Boumerdes","","21966","1012989318"
"Digor","Digor","40.3756","43.4125","Turkey","TR","TUR","Kars","minor","22282","1792355056"
"Laja","Laja","-37.2667","-72.7000","Chile","CL","CHL","Biobío","","22288","1152499154"
"Bristol","Bristol","41.6827","-71.2694","United States","US","USA","Rhode Island","","22305","1840106238"
"Zoersel","Zoersel","51.2667","4.7000","Belgium","BE","BEL","Flanders","","21739","1056874458"
"Banbishancun","Banbishancun","40.3700","117.9317","China","CN","CHN","Hebei","","22029","1156558840"
"Herent","Herent","50.9081","4.6706","Belgium","BE","BEL","Flanders","","21632","1056180575"
"Gujan-Mestras","Gujan-Mestras","44.6364","-1.0667","France","FR","FRA","Nouvelle-Aquitaine","","21887","1250633398"
"West Deptford","West Deptford","39.8431","-75.1852","United States","US","USA","New Jersey","","22292","1840081678"
"Tupanciretã","Tupancireta","-29.0808","-53.8358","Brazil","BR","BRA","Rio Grande do Sul","","22281","1076364387"
"Basi","Basi","30.5872","76.8428","India","IN","IND","Punjab","","20910","1356209118"
"São Miguel","Sao Miguel","-6.2119","-38.4969","Brazil","BR","BRA","Rio Grande do Norte","","22157","1076653158"
"Santo Antônio","Santo Antonio","-6.3108","-35.4789","Brazil","BR","BRA","Rio Grande do Norte","","22216","1076309827"
"Taveta","Taveta","-3.3956","37.6761","Kenya","KE","KEN","Taita/Taveta","","22018","1404624215"
"São Miguel do Araguaia","Sao Miguel do Araguaia","-13.2750","-50.1628","Brazil","BR","BRA","Goiás","","22283","1076063801"
"Colfontaine","Colfontaine","50.4057","3.8514","Belgium","BE","BEL","Wallonia","","20762","1056047515"
"Ruston","Ruston","32.5328","-92.6363","United States","US","USA","Louisiana","","22286","1840014878"
"Koduvilārpatti","Koduvilarpatti","9.9695","77.4915","India","IN","IND","Tamil Nādu","","21242","1356244107"
"Corail","Corail","18.5667","-73.8833","Haiti","HT","HTI","Grand’Anse","","22021","1332320384"
"Ilsede","Ilsede","52.2667","10.1833","Germany","DE","DEU","Lower Saxony","","21975","1276410827"
"Mandirituba","Mandirituba","-25.7789","-49.3258","Brazil","BR","BRA","Paraná","","22220","1076772401"
"Unchahra","Unchahra","24.3825","80.7809","India","IN","IND","Madhya Pradesh","","21996","1356718269"
"Bad Harzburg","Bad Harzburg","51.8811","10.5622","Germany","DE","DEU","Lower Saxony","","21945","1276192309"
"Ḩamīdīyeh","Hamidiyeh","31.4850","48.2686","Iran","IR","IRN","Khūzestān","","22057","1364148663"
"Woensdrecht","Woensdrecht","51.4167","4.3333","Netherlands","NL","NLD","Noord-Brabant","","22028","1528569809"
"Aswāpuram","Aswapuram","17.8348","80.8236","India","IN","IND","Andhra Pradesh","","22044","1356060648"
"San Felipe","San Felipe","31.0275","-114.8353","Mexico","MX","MEX","Baja California","","20750","1484794463"
"Hillsborough","Hillsborough","40.5069","-74.6523","United States","US","USA","New Jersey","","22273","1840153047"
"Reigate","Reigate","51.2370","-0.2060","United Kingdom","GB","GBR","Surrey","","22123","1826422343"
"Bressanone","Bressanone","46.7165","11.6579","Italy","IT","ITA","Trentino-Alto Adige","","22011","1380666737"
"Ugong","Ugong","14.5842","121.0729","Philippines","PH","PHL","Pasig","","22266","1608448625"
"Albertville","Albertville","34.2633","-86.2108","United States","US","USA","Alabama","","22268","1840013599"
"Comacchio","Comacchio","44.7000","12.1833","Italy","IT","ITA","Emilia-Romagna","","22188","1380546897"
"Les Pennes-Mirabeau","Les Pennes-Mirabeau","43.4106","5.3103","France","FR","FRA","Provence-Alpes-Côte d’Azur","","21623","1250976076"
"Lihuzhuang","Lihuzhuang","39.6520","117.8649","China","CN","CHN","Hebei","","21844","1156775869"
"Mūlki","Mulki","13.1000","74.8000","India","IN","IND","Karnātaka","","21633","1356361914"
"Koāth","Koath","25.3264","84.2598","India","IN","IND","Bihār","","20875","1356861671"
"Thuân An","Thuan An","16.5489","107.6436","Vietnam","VN","VNM","Thừa Thiên-Huế","","20972","1704000352"
"Itigi","Itigi","-5.7000","34.4833","Tanzania","TZ","TZA","Singida","","21777","1834524806"
"Repelón","Repelon","10.5500","-75.1333","Colombia","CO","COL","Atlántico","minor","22196","1170326698"
"Conselheiro Pena","Conselheiro Pena","-19.1719","-41.4719","Brazil","BR","BRA","Minas Gerais","","22242","1076535135"
"Jāwad","Jawad","24.5992","74.8626","India","IN","IND","","","22026","1356338791"
"Naregal","Naregal","15.5732","75.8080","India","IN","IND","Karnātaka","","22024","1356850764"
"Martha Lake","Martha Lake","47.8479","-122.2327","United States","US","USA","Washington","","22251","1840037875"
"Salvaterra de Magos","Salvaterra de Magos","39.0167","-8.7833","Portugal","PT","PRT","Santarém","minor","22159","1620916588"
"Eppingen","Eppingen","49.1333","8.9167","Germany","DE","DEU","Baden-Württemberg","","22001","1276844300"
"Caojiachuan","Caojiachuan","34.9016","111.5210","China","CN","CHN","Shanxi","","22100","1156192443"
"Golden Valley","Golden Valley","44.9901","-93.3591","United States","US","USA","Minnesota","","22247","1840007824"
"Marina","Marina","36.6810","-121.7892","United States","US","USA","California","","22246","1840020357"
"Afourar","Afourar","32.2167","-6.5000","Morocco","MA","MAR","Béni Mellal-Khénifra","","21382","1504710376"
"Rio Formoso","Rio Formoso","-8.6639","-35.1589","Brazil","BR","BRA","Pernambuco","","22151","1076850628"
"Normanton","Normanton","53.6970","-1.4160","United Kingdom","GB","GBR","Wakefield","","20872","1826795957"
"Takelsa","Takelsa","36.7833","10.6333","Tunisia","TN","TUN","Nabeul","","22151","1788957675"
"Andranovorivato","Andranovorivato","-21.6333","46.9833","Madagascar","MG","MDG","Fianarantsoa","","22000","1450284938"
"Corinto","Corinto","12.4819","-87.1742","Nicaragua","NI","NIC","Chinandega","minor","22000","1558830378"
"Sahil","Sahil","40.2200","49.5714","Azerbaijan","AZ","AZE","Bakı","","22116","1031499506"
"Yavuzeli","Yavuzeli","37.3192","37.5686","Turkey","TR","TUR","Gaziantep","minor","22192","1792996811"
"Ladário","Ladario","-19.0050","-57.6019","Brazil","BR","BRA","Mato Grosso do Sul","","22228","1076116467"
"Shabestar","Shabestar","38.1800","45.7039","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","22181","1364001665"
"Bonney Lake","Bonney Lake","47.1791","-122.1700","United States","US","USA","Washington","","22229","1840018454"
"Coralville","Coralville","41.6990","-91.5967","United States","US","USA","Iowa","","22226","1840007076"
"Hazebrouck","Hazebrouck","50.7250","2.5392","France","FR","FRA","Hauts-de-France","","21408","1250140504"
"Nova Zagora","Nova Zagora","42.4911","26.0144","Bulgaria","BG","BGR","Sliven","","22175","1100577244"
"Corinth","Corinth","33.1434","-97.0682","United States","US","USA","Texas","","22216","1840019389"
"Moineşti","Moinesti","46.4333","26.4833","Romania","RO","ROU","Bacău","","21787","1642141405"
"Itamaracá","Itamaraca","-7.7478","-34.8258","Brazil","BR","BRA","Pernambuco","","21884","1076577014"
"Oxford","Oxford","33.5967","-85.8687","United States","US","USA","Alabama","","22210","1840014780"
"Gümüşhacıköy","Gumushacikoy","40.8667","35.2167","Turkey","TR","TUR","Amasya","minor","22179","1792935743"
"Castrovillari","Castrovillari","39.8167","16.2000","Italy","IT","ITA","Calabria","","22037","1380393848"
"Bato","Bato","13.6000","124.3000","Philippines","PH","PHL","Catanduanes","","21748","1608668224"
"Flores","Flores","-7.8658","-37.9750","Brazil","BR","BRA","Pernambuco","","22169","1076955448"
"Imbert","Imbert","19.7500","-70.8300","Dominican Republic","DO","DOM","Cibao Norte","","22058","1214841886"
"Mandapam","Mandapam","9.2822","79.1436","India","IN","IND","Tamil Nādu","","21572","1356241519"
"Mountain Brook","Mountain Brook","33.4871","-86.7400","United States","US","USA","Alabama","","22184","1840014792"
"Villeneuve-sur-Lot","Villeneuve-sur-Lot","44.4081","0.7050","France","FR","FRA","Nouvelle-Aquitaine","minor","21915","1250450976"
"Nangavaram","Nangavaram","10.8692","78.5392","India","IN","IND","Tamil Nādu","","21658","1356221132"
"Chesham","Chesham","51.7120","-0.6120","United Kingdom","GB","GBR","Buckinghamshire","","21483","1826549288"
"Luofa","Luofa","39.4343","116.8386","China","CN","CHN","Hebei","","21369","1156560570"
"Laventille","Laventille","10.6500","-61.4833","Trinidad and Tobago","TT","TTO","San Juan/Laventille","","21454","1780201146"
"San Agustin","San Agustin","16.5167","121.7500","Philippines","PH","PHL","Isabela","","22096","1608476600"
"Madakasīra","Madakasira","13.9369","77.2694","India","IN","IND","Andhra Pradesh","","21464","1356646201"
"Bavly","Bavly","54.3833","53.2833","Russia","RU","RUS","Tatarstan","","22145","1643213721"
"Bad Rappenau","Bad Rappenau","49.2333","9.1000","Germany","DE","DEU","Baden-Württemberg","","21876","1276726877"
"Jugiāl","Jugial","32.3684","75.6782","India","IN","IND","Punjab","","22001","1356147551"
"Zundert","Zundert","51.4667","4.6667","Netherlands","NL","NLD","Noord-Brabant","minor","21988","1528815556"
"Qarazhal","Qarazhal","48.0253","70.7999","Kazakhstan","KZ","KAZ","","","22170","1398149188"
"Clarksville","Clarksville","38.3220","-85.7673","United States","US","USA","Indiana","","22169","1840009665"
"Belonia","Belonia","23.2500","91.4500","India","IN","IND","Tripura","","21176","1356011027"
"Tabursuq","Tabursuq","36.4572","9.2483","Tunisia","TN","TUN","Béja","","22115","1788107658"
"Bellavista","Bellavista","-7.0668","-76.5847","Peru","PE","PER","San Martín","","22116","1604125292"
"Sem","Sem","59.2792","10.3278","Norway","NO","NOR","Vestfold og Telemark","","21948","1578156342"
"Oberwingert","Oberwingert","47.5170","8.7690","Switzerland","CH","CHE","Zürich","","21748","1756012377"
"Qapqal","Qapqal","43.8340","81.1581","China","CN","CHN","Xinjiang","minor","22111","1156008098"
"São Jerônimo","Sao Jeronimo","-29.9589","-51.7219","Brazil","BR","BRA","Rio Grande do Sul","","22134","1076728230"
"Jiuru","Jiuru","22.7333","120.4833","Taiwan","TW","TWN","Pingtung","","21642","1158362617"
"Selouane","Selouane","35.0667","-2.9333","Morocco","MA","MAR","Oriental","","21570","1504039594"
"Silvania","Silvania","4.4033","-74.3881","Colombia","CO","COL","Cundinamarca","minor","22020","1170709496"
"Huejúcar","Huejucar","22.3167","-103.2333","Mexico","MX","MEX","Jalisco","minor","22083","1484409823"
"Lom","Lom","43.8256","23.2375","Bulgaria","BG","BGR","Montana","minor","22075","1100440040"
"Ambalaroka","Ambalaroka","-22.1333","47.7333","Madagascar","MG","MDG","Fianarantsoa","","22000","1450936637"
"Florida Ridge","Florida Ridge","27.5805","-80.3848","United States","US","USA","Florida","","22151","1840029035"
"Machico","Machico","32.7000","-16.7667","Portugal","PT","PRT","Madeira","","21828","1620563649"
"Waynesboro","Waynesboro","38.0674","-78.9014","United States","US","USA","Virginia","","22149","1840003848"
"Madamba","Madamba","7.8833","124.0667","Philippines","PH","PHL","Lanao del Sur","","22050","1608005333"
"Hardi","Hardi","26.0785","86.6809","India","IN","IND","Bihār","","20611","1356259959"
"Chambersburg","Chambersburg","39.9315","-77.6556","United States","US","USA","Pennsylvania","","22141","1840001410"
"Patpāra","Patpara","26.4328","86.1916","India","IN","IND","Bihār","","21024","1356549985"
"Sandhurst","Sandhurst","51.3490","-0.8000","United Kingdom","GB","GBR","Hampshire","","20803","1826474175"
"Karlovo","Karlovo","42.6436","24.8072","Bulgaria","BG","BGR","Plovdiv","","22095","1100780442"
"Belas","Belas","38.7754","-9.2716","Portugal","PT","PRT","Lisboa","","21172","1620052831"
"Kommunar","Kommunar","59.6333","30.4000","Russia","RU","RUS","Leningradskaya Oblast’","","22055","1643122289"
"Beni Douala","Beni Douala","36.6167","4.0667","Algeria","DZ","DZA","Tizi Ouzou","","21551","1012742443"
"Neu Wulmstorf","Neu Wulmstorf","53.4628","9.7956","Germany","DE","DEU","Lower Saxony","","21748","1276059362"
"Bemanonga","Bemanonga","-20.2833","44.3500","Madagascar","MG","MDG","Toliara","","22000","1450905761"
"Honmachi","Honmachi","36.0609","136.5006","Japan","JP","JPN","Fukui","","22047","1392794270"
"Palmer","Palmer","40.7007","-75.2628","United States","US","USA","Pennsylvania","","22133","1840149341"
"Millau","Millau","44.0986","3.0783","France","FR","FRA","Occitanie","minor","22002","1250699654"
"Ampanotokana","Ampanotokana","-18.7167","47.3000","Madagascar","MG","MDG","Antananarivo","","21969","1450337048"
"Nirmāli","Nirmali","26.3140","86.5854","India","IN","IND","Bihār","","21306","1356128831"
"São Sebastião do Caí","Sao Sebastiao do Cai","-29.5869","-51.3758","Brazil","BR","BRA","Rio Grande do Sul","","21932","1076903891"
"Świedbodzin","Swiedbodzin","52.2500","15.5333","Poland","PL","POL","Lubuskie","minor","21988","1616504526"
"Maravilha","Maravilha","-26.7700","-53.2167","Brazil","BR","BRA","Santa Catarina","","22101","1076119350"
"Iarintsena","Iarintsena","-21.8167","46.8667","Madagascar","MG","MDG","Fianarantsoa","","22000","1450516080"
"Pailitas","Pailitas","8.9667","-73.6333","Colombia","CO","COL","Cesar","minor","22083","1170355661"
"Arroyito","Arroyito","-23.1733","-56.7747","Paraguay","PY","PRY","Concepción","","22100","1600329256"
"Villa de Leyva","Villa de Leyva","5.6333","-73.5333","Colombia","CO","COL","Boyacá","minor","21953","1170607364"
"Salémata","Salemata","12.6333","-12.8167","Senegal","SN","SEN","Kédougou","","22111","1686886935"
"Una","Una","-15.2928","-39.0750","Brazil","BR","BRA","Bahia","","22105","1076665693"
"Vecsés","Vecses","47.4057","19.2648","Hungary","HU","HUN","Pest","minor","21529","1348714695"
"Crowborough","Crowborough","51.0600","0.1600","United Kingdom","GB","GBR","East Sussex","","20607","1826550710"
"Shiroishi","Shiroishi","33.1811","130.1434","Japan","JP","JPN","Saga","","21902","1392003123"
"Silver Firs","Silver Firs","47.8635","-122.1497","United States","US","USA","Washington","","22122","1840037898"
"Mailavaram","Mailavaram","16.7833","80.6333","India","IN","IND","Andhra Pradesh","","21763","1356149702"
"Cortona","Cortona","43.2756","11.9881","Italy","IT","ITA","Tuscany","","22057","1380618031"
"Cherry Hill","Cherry Hill","38.5696","-77.2895","United States","US","USA","Virginia","","22118","1840026712"
"El Pinar","El Pinar","-34.7972","-55.9097","Uruguay","UY","URY","Canelones","","21091","1858554922"
"Guilford","Guilford","41.3345","-72.7004","United States","US","USA","Connecticut","","22113","1840045023"
"Watertown","Watertown","41.6160","-73.1177","United States","US","USA","Connecticut","","22110","1840035651"
"Bloomington","Bloomington","34.0601","-117.4013","United States","US","USA","California","","22107","1840017799"
"Palafrugell","Palafrugell","41.9174","3.1631","Spain","ES","ESP","Catalonia","","21049","1724222383"
"Cunha","Cunha","-23.0744","-44.9597","Brazil","BR","BRA","São Paulo","","22086","1076000535"
"Muelle de los Bueyes","Muelle de los Bueyes","12.0664","-84.5428","Nicaragua","NI","NIC","Costa Caribe Sur","minor","22082","1558837878"
"Yaojiafen","Yaojiafen","40.7158","114.8733","China","CN","CHN","Hebei","","21726","1156288547"
"Palmital","Palmital","-22.7889","-50.2175","Brazil","BR","BRA","São Paulo","","22094","1076414022"
"Manlleu","Manlleu","42.0000","2.2836","Spain","ES","ESP","Catalonia","","20883","1724798027"
"Paramirim","Paramirim","-13.4428","-42.2389","Brazil","BR","BRA","Bahia","","22077","1076772548"
"Lower","Lower","38.9819","-74.9087","United States","US","USA","New Jersey","","22095","1840081575"
"Merefa","Merefa","49.8197","36.0686","Ukraine","UA","UKR","Kharkivska Oblast","","21794","1804653075"
"Kontich","Kontich","51.1333","4.4500","Belgium","BE","BEL","Flanders","","21203","1056241765"
"Māngrol","Mangrol","25.3300","76.5200","India","IN","IND","Rājasthān","","21836","1356021018"
"Timbiquí","Timbiqui","2.7667","-77.6333","Colombia","CO","COL","Cauca","minor","22077","1170187722"
"Bataguaçu","Bataguacu","-21.7139","-52.4219","Brazil","BR","BRA","Mato Grosso do Sul","","22084","1076342312"
"Darien","Darien","41.7448","-87.9822","United States","US","USA","Illinois","","22087","1840007045"
"Versmold","Versmold","52.0436","8.1500","Germany","DE","DEU","North Rhine-Westphalia","","21829","1276482608"
"Tacaratu","Tacaratu","-9.1058","-38.1500","Brazil","BR","BRA","Pernambuco","","22068","1076482640"
"Ambodimotso Atsimo","Ambodimotso Atsimo","-15.3833","48.6000","Madagascar","MG","MDG","Mahajanga","","22000","1450354042"
"Ust’-Katav","Ust'-Katav","54.9333","58.1833","Russia","RU","RUS","Chelyabinskaya Oblast’","","22042","1643326615"
"Cuncolim","Cuncolim","15.1773","73.9939","India","IN","IND","Goa","","20935","1356002399"
"Horizon City","Horizon City","31.6799","-106.1903","United States","US","USA","Texas","","22075","1840020805"
"Elsdorf","Elsdorf","50.9333","6.5667","Germany","DE","DEU","North Rhine-Westphalia","","21745","1276581538"
"San Martín","San Martin","-31.4397","-68.5206","Argentina","AR","ARG","San Juan","minor","22046","1032363608"
"Jafaro","Jafaro","-24.9167","45.5167","Madagascar","MG","MDG","Toliara","","22000","1450577279"
"Tiquisio","Tiquisio","8.5667","-74.2667","Colombia","CO","COL","Bolívar","minor","22041","1170823883"
"Semikarakorsk","Semikarakorsk","47.5167","40.8000","Russia","RU","RUS","Rostovskaya Oblast’","minor","22030","1643808895"
"Hastings","Hastings","44.7318","-92.8538","United States","US","USA","Minnesota","","22063","1840006770"
"Taminango","Taminango","1.5667","-77.2833","Colombia","CO","COL","Nariño","minor","21985","1170223532"
"Tola","Tola","11.4392","-85.9389","Nicaragua","NI","NIC","Rivas","minor","22012","1558491249"
"Shendurjana","Shendurjana","21.5217","78.2908","India","IN","IND","Mahārāshtra","","21748","1356166995"
"Mazagão","Mazagao","-0.1150","-51.2889","Brazil","BR","BRA","Amapá","","22053","1076090210"
"Kueneng","Kueneng","-29.0161","27.9981","Lesotho","LS","LSO","Berea","","21887","1426216569"
"Sonthofen","Sonthofen","47.5158","10.2811","Germany","DE","DEU","Bavaria","minor","21589","1276823857"
"Póvoa de Lanhoso","Povoa de Lanhoso","41.5667","-8.2667","Portugal","PT","PRT","Braga","minor","21886","1620452875"
"Chillicothe","Chillicothe","39.3393","-82.9939","United States","US","USA","Ohio","","22047","1840007369"
"Tufānganj","Tufanganj","26.3200","89.6700","India","IN","IND","West Bengal","","20998","1356500654"
"Ankiabe-Salohy","Ankiabe-Salohy","-15.6000","48.7167","Madagascar","MG","MDG","Mahajanga","","22000","1450727399"
"Phulaut","Phulaut","25.5114","86.9375","India","IN","IND","Bihār","","20399","1356310414"
"Seligenstadt","Seligenstadt","50.0333","8.9667","Germany","DE","DEU","Hesse","","21345","1276356108"
"Hlohovec","Hlohovec","48.4333","17.8033","Slovakia","SK","SVK","Trnava","minor","21715","1703788561"
"Kozmodemyansk","Kozmodemyansk","56.3367","46.5711","Russia","RU","RUS","Mariy-El","","20505","1643473458"
"Poço Verde","Poco Verde","-10.7078","-38.1828","Brazil","BR","BRA","Sergipe","","21983","1076944490"
"Imi-n-Tanout","Imi-n-Tanout","31.1600","-8.8500","Morocco","MA","MAR","Marrakech-Safi","","20837","1504593970"
"Chalchihuitán","Chalchihuitan","17.0333","-92.6167","Mexico","MX","MEX","Chiapas","","21915","1484099582"
"Amaraji","Amaraji","-8.3758","-35.4522","Brazil","BR","BRA","Pernambuco","","21939","1076105357"
"San Bonifacio","San Bonifacio","45.4000","11.2833","Italy","IT","ITA","Veneto","","21397","1380614746"
"Khānāpur","Khanapur","15.6394","74.5083","India","IN","IND","Karnātaka","","21868","1356772159"
"Vilyuchinsk","Vilyuchinsk","52.9306","158.4028","Russia","RU","RUS","Kamchatskiy Kray","","21973","1643675061"
"Kotli","Kotli","33.5156","73.9019","Pakistan","PK","PAK","Azad Kashmir","minor","21462","1586677063"
"Carutapera","Carutapera","-1.1950","-46.0200","Brazil","BR","BRA","Maranhão","","22006","1076275757"
"Bānapur","Banapur","19.7789","85.1703","India","IN","IND","Odisha","","21745","1356666995"
"Rancho San Diego","Rancho San Diego","32.7624","-116.9197","United States","US","USA","California","","22019","1840019347"
"Antsakabary","Antsakabary","-15.0500","48.9333","Madagascar","MG","MDG","Mahajanga","","22000","1450047713"
"Omutninsk","Omutninsk","58.6667","52.1833","Russia","RU","RUS","Kirovskaya Oblast’","","22009","1643987727"
"DeBary","DeBary","28.8815","-81.3240","United States","US","USA","Florida","","22015","1840015070"
"Ensley","Ensley","30.5259","-87.2733","United States","US","USA","Florida","","22015","1840013104"
"Picasent","Picasent","39.3611","-0.4600","Spain","ES","ESP","Valencia","","21761","1724170506"
"Tewkesbury","Tewkesbury","51.9900","-2.1600","United Kingdom","GB","GBR","Gloucestershire","","20360","1826890799"
"Barhampur","Barhampur","26.3042","86.4729","India","IN","IND","Bihār","","20764","1356142503"
"Tân Phong","Tan Phong","19.7322","105.7817","Vietnam","VN","VNM","Thanh Hóa","","20603","1704432456"
"Manakambahiny","Manakambahiny","-17.8000","48.3333","Madagascar","MG","MDG","Toamasina","","22000","1450199709"
"Andilanatoby","Andilanatoby","-17.9333","48.2333","Madagascar","MG","MDG","Toamasina","","22000","1450217709"
"Machagai","Machagai","-26.9167","-60.0333","Argentina","AR","ARG","Chaco","minor","21997","1032606439"
"Gigaquit","Gigaquit","9.5947","125.6975","Philippines","PH","PHL","Surigao del Norte","","21849","1608408271"
"Bungotakada","Bungotakada","33.5500","131.4500","Japan","JP","JPN","Ōita","","21901","1392003392"
"Bañolas","Banolas","42.1194","2.7664","Spain","ES","ESP","Catalonia","","20187","1724632297"
"Thenia","Thenia","36.7278","3.5539","Algeria","DZ","DZA","Boumerdes","","21439","1012908635"
"Khowai","Khowai","24.0650","91.6050","India","IN","IND","","","21387","1356063610"
"San Luis Talpa","San Luis Talpa","13.4667","-89.0833","El Salvador","SV","SLV","La Paz","","21675","1222408635"
"Kotovo","Kotovo","50.3000","44.8167","Russia","RU","RUS","Volgogradskaya Oblast’","","21990","1643692011"
"Richmond","Richmond","51.4560","-0.3010","United Kingdom","GB","GBR","Richmond upon Thames","","21469","1826875300"
"Conway","Conway","33.8401","-79.0431","United States","US","USA","South Carolina","","22001","1840014716"
"Pascagoula","Pascagoula","30.3666","-88.5506","United States","US","USA","Mississippi","","22001","1840015018"
"‘Adrā","\`Adra","33.6000","36.5150","Syria","SY","SYR","Rīf Dimashq","","20559","1760640037"
"Mangalvedha","Mangalvedha","17.5167","75.4667","India","IN","IND","Mahārāshtra","","21824","1356014228"
"Axapusco","Axapusco","19.7194","-98.7972","Mexico","MX","MEX","México","minor","21915","1484580584"
"Pitrufquén","Pitrufquen","-38.9833","-72.6500","Chile","CL","CHL","Araucanía","","21981","1152964744"
"Birkerød","Birkerod","55.8333","12.4333","Denmark","DK","DNK","Hovedstaden","","20877","1208853307"
"La Porte","La Porte","41.6069","-86.7142","United States","US","USA","Indiana","","21993","1840030063"
"Vereshchagino","Vereshchagino","58.1000","54.6667","Russia","RU","RUS","Permskiy Kray","","21969","1643676991"
"New Castle","New Castle","40.9956","-80.3458","United States","US","USA","Pennsylvania","","21992","1840000938"
"San Rafael del Yuma","San Rafael del Yuma","18.4333","-68.6667","Dominican Republic","DO","DOM","Yuma","","21967","1214650912"
"Xanten","Xanten","51.6622","6.4539","Germany","DE","DEU","North Rhine-Westphalia","","21690","1276917094"
"Brambleton","Brambleton","38.9803","-77.5323","United States","US","USA","Virginia","","21986","1840024497"
"Coreaú","Coreau","-3.5328","-40.6569","Brazil","BR","BRA","Ceará","","21954","1076455548"
"Kemi","Kemi","65.7361","24.5636","Finland","FI","FIN","Lappi","minor","21758","1246411843"
"Remígio","Remigio","-6.9028","-35.8339","Brazil","BR","BRA","Paraíba","","21859","1076104927"
"Chalmette","Chalmette","29.9438","-89.9660","United States","US","USA","Louisiana","","21981","1840014001"
"Isernia","Isernia","41.6028","14.2397","Italy","IT","ITA","Molise","","21666","1380050497"
"Guía de Isora","Guia de Isora","28.2110","-16.7784","Spain","ES","ESP","Canary Islands","","21827","1724572709"
"Guipos","Guipos","7.7350","123.3231","Philippines","PH","PHL","Zamboanga del Sur","","21738","1608106395"
"Winter Gardens","Winter Gardens","32.8376","-116.9268","United States","US","USA","California","","21978","1840019351"
"Mühldorf","Muhldorf","48.2456","12.5228","Germany","DE","DEU","Bavaria","minor","21254","1276623949"
"Yoqne‘am ‘Illit","Yoqne\`am \`Illit","32.6594","35.11","Israel","IL","ISR","Northern","","21383","1376262404"
"Nøtterøy","Notteroy","59.2011","10.4078","Norway","NO","NOR","Vestfold og Telemark","","21621","1578114950"
"Polkowice","Polkowice","51.5000","16.0667","Poland","PL","POL","Dolnośląskie","minor","21786","1616214914"
"Anūppur","Anuppur","23.1034","81.6908","India","IN","IND","Madhya Pradesh","","21651","1356181149"
"Iisalmi","Iisalmi","63.5667","27.1833","Finland","FI","FIN","Pohjois-Savo","minor","21945","1246230947"
"Opoczno","Opoczno","51.3772","20.2869","Poland","PL","POL","Łódzkie","minor","21780","1616841485"
"East Ridge","East Ridge","34.9973","-85.2285","United States","US","USA","Tennessee","","21967","1840013452"
"Amposta","Amposta","40.7106","0.5808","Spain","ES","ESP","Catalonia","","21807","1724566001"
"Looc","Looc","12.2605","121.9926","Philippines","PH","PHL","Romblon","","21799","1608317666"
"Pedernales","Pedernales","0.0700","-80.0550","Ecuador","EC","ECU","Manabí","","21910","1218670991"
"El Ksiba","El Ksiba","32.5681","-6.0308","Morocco","MA","MAR","Béni Mellal-Khénifra","","20001","1504390061"
"Montclair","Montclair","38.6111","-77.3400","United States","US","USA","Virginia","","21961","1840006095"
"Caaporã","Caapora","-7.5158","-34.9078","Brazil","BR","BRA","Paraíba","","21955","1076003332"
"West Pensacola","West Pensacola","30.4263","-87.2679","United States","US","USA","Florida","","21960","1840013915"
"Trinidad","Trinidad","-33.5333","-56.8833","Uruguay","UY","URY","Flores","","21429","1858903609"
"Gummudipūndi","Gummudipundi","13.3995","80.1187","India","IN","IND","Tamil Nādu","","21378","1356179238"
"Port Hueneme","Port Hueneme","34.1618","-119.2036","United States","US","USA","California","","21954","1840020475"
"Isla-Cristina","Isla-Cristina","37.1992","-7.3214","Spain","ES","ESP","Andalusia","","21523","1724215419"
"MacArthur","MacArthur","10.8356","124.9964","Philippines","PH","PHL","Leyte","","21578","1608224197"
"Membakut","Membakut","5.4667","115.7833","Malaysia","MY","MYS","Sabah","","21900","1458057986"
"Goodlands","Goodlands","-20.0350","57.6431","Mauritius","MU","MUS","Rivière du Rempart","","20712","1480043599"
"Halle","Halle","52.0667","8.3667","Germany","DE","DEU","North Rhine-Westphalia","","21640","1276770255"
"Tornio","Tornio","65.8500","24.1500","Finland","FI","FIN","Lappi","minor","21928","1246209112"
"Rātu","Ratu","23.4204","85.2146","India","IN","IND","Jhārkhand","","21196","1356352472"
"Darreh Shahr","Darreh Shahr","33.1397","47.3761","Iran","IR","IRN","Īlām","minor","21900","1364154593"
"Bhānpura","Bhanpura","24.5108","75.7483","India","IN","IND","Madhya Pradesh","","21772","1356689479"
"Amarpātan","Amarpatan","24.3137","80.9770","India","IN","IND","Madhya Pradesh","","21609","1356524463"
"Tongzhou","Tongzhou","25.7716","106.9730","China","CN","CHN","Guizhou","","21848","1156096766"
"Mehnatobod","Mehnatobod","37.7167","69.5500","Tajikistan","TJ","TJK","Khatlon","","21816","1762901629"
"Cabucgayan","Cabucgayan","11.4719","124.5750","Philippines","PH","PHL","Biliran","","21542","1608804448"
"Osterode","Osterode","51.7286","10.2522","Germany","DE","DEU","Lower Saxony","minor","21731","1276593815"
"Matinha","Matinha","-3.1008","-45.0339","Brazil","BR","BRA","Maranhão","","21885","1076345021"
"Colangute","Colangute","15.5450","73.7631","India","IN","IND","","","20833","1356794856"
"Amherstburg","Amherstburg","42.1000","-83.0833","Canada","CA","CAN","Ontario","","21936","1124696938"
"Hallein","Hallein","47.6667","13.0833","Austria","AT","AUT","Salzburg","minor","21150","1040821146"
"Parsippany","Parsippany","40.8645","-74.4135","United States","US","USA","New Jersey","","21931","1840033373"
"Katy","Katy","29.7905","-95.8353","United States","US","USA","Texas","","21926","1840019648"
"Samba Cango","Samba Cango","-9.1000","15.5500","Angola","AO","AGO","Malanje","","21918","1024281729"
"Caxambu","Caxambu","-21.9769","-44.9328","Brazil","BR","BRA","Minas Gerais","","21705","1076000185"
"Parramos","Parramos","14.6078","-90.8031","Guatemala","GT","GTM","Chimaltenango","minor","20632","1320705427"
"Los Palmitos","Los Palmitos","9.3811","-75.2714","Colombia","CO","COL","Sucre","minor","21831","1170000253"
"Lālru","Lalru","30.4867","76.8006","India","IN","IND","Punjab","","20887","1356090094"
"Lohutí","Lohuti","40.2500","70.1333","Tajikistan","TJ","TJK","Sughd","","21652","1762012091"
"Sermādevi","Sermadevi","8.6873","77.5662","India","IN","IND","Tamil Nādu","","21523","1356149578"
"Józefów","Jozefow","52.1333","21.2333","Poland","PL","POL","Mazowieckie","","21035","1616296972"
"Freire","Freire","-38.9558","-72.6256","Chile","CL","CHL","Araucanía","","21890","1152013724"
"Caranavi","Caranavi","-15.8333","-67.5667","Bolivia","BO","BOL","La Paz","","21883","1068233708"
"Almoradí","Almoradi","38.1097","-0.7894","Spain","ES","ESP","Valencia","","21404","1724624918"
"Ayvacık","Ayvacik","41.0036","36.6319","Turkey","TR","TUR","Samsun","minor","21847","1792294480"
"Dax","Dax","43.7100","-1.0500","France","FR","FRA","Nouvelle-Aquitaine","minor","20843","1250059757"
"Gallup","Gallup","35.5182","-108.7431","United States","US","USA","New Mexico","","21901","1840020395"
"Potters Bar","Potters Bar","51.6980","-0.1830","United Kingdom","GB","GBR","Hertfordshire","","21639","1826408007"
"Had Oulad Issa","Had Oulad Issa","32.9519","-8.6982","Morocco","MA","MAR","Casablanca-Settat","","21779","1504891592"
"Azambuja","Azambuja","39.0667","-8.8667","Portugal","PT","PRT","Lisboa","minor","21814","1620393026"
"Casablanca","Casablanca","-33.3167","-71.4167","Chile","CL","CHL","Región Metropolitana","","21874","1152471983"
"Sardulgarh","Sardulgarh","29.6970","75.2388","India","IN","IND","Punjab","","21535","1356456046"
"Colônia Leopoldina","Colonia Leopoldina","-8.9089","-35.7250","Brazil","BR","BRA","Alagoas","","21818","1076490767"
"Shinhidaka","Shinhidaka","42.3413","142.3686","Japan","JP","JPN","Hokkaidō","","21873","1392000645"
"Uruçuca","Urucuca","-14.5928","-39.2839","Brazil","BR","BRA","Bahia","","21849","1076099811"
"Orzesze","Orzesze","50.1500","18.7500","Poland","PL","POL","Śląskie","","21631","1616872368"
"Colle di Val d’Elsa","Colle di Val d'Elsa","43.4000","11.1333","Italy","IT","ITA","Tuscany","","21651","1380884722"
"Ginebra","Ginebra","3.7500","-76.1667","Colombia","CO","COL","Valle del Cauca","minor","21815","1170109956"
"South Euclid","South Euclid","41.5240","-81.5245","United States","US","USA","Ohio","","21882","1840003414"
"New Hartford","New Hartford","43.0586","-75.2821","United States","US","USA","New York","","21882","1840004206"
"Mount Pleasant","Mount Pleasant","43.5966","-84.7759","United States","US","USA","Michigan","","21880","1840002663"
"San Fernando","San Fernando","12.4858","123.7628","Philippines","PH","PHL","Masbate","","21600","1608341558"
"Ayamonte","Ayamonte","37.2000","-7.4000","Spain","ES","ESP","Andalusia","","21725","1724119454"
"Gros Islet","Gros Islet","14.0810","-60.9530","Saint Lucia","LC","LCA","Gros-Islet","","21660","1662587230"
"Nāranammālpuram","Naranammalpuram","8.7599","77.7399","India","IN","IND","Tamil Nādu","","21539","1356201643"
"Maghalleen","Maghalleen","-30.0942","27.4189","Lesotho","LS","LSO","Mohale’s Hoek","","21869","1426440371"
"Halewood","Halewood","53.3599","-2.8400","United Kingdom","GB","GBR","Knowsley","","20116","1826807360"
"Tādikombu","Tadikombu","10.4390","77.9546","India","IN","IND","Tamil Nādu","","21226","1356786637"
"Fukuyoshi","Fukuyoshi","33.6832","130.7800","Japan","JP","JPN","Fukuoka","","21360","1392624866"
"Alcântara","Alcantara","-2.4089","-44.4150","Brazil","BR","BRA","Maranhão","","21851","1076650057"
"Sainte-Suzanne","Sainte-Suzanne","19.5833","-72.0833","Haiti","HT","HTI","Nord-Est","","21617","1332623138"
"Farakka","Farakka","24.8200","87.9000","India","IN","IND","West Bengal","","20126","1356510711"
"Yabu","Yabu","35.4000","134.7667","Japan","JP","JPN","Hyōgo","","21813","1392817826"
"Alliance","Alliance","40.9107","-81.1189","United States","US","USA","Ohio","","21864","1840000962"
"Nogales","Nogales","-32.7350","-71.2025","Chile","CL","CHL","Valparaíso","","21856","1152042866"
"Mashiko","Mashiko","36.4673","140.0934","Japan","JP","JPN","Tochigi","","21621","1392280086"
"Codlea","Codlea","45.6969","25.4439","Romania","RO","ROU","Braşov","","21708","1642573857"
"Scarborough","Scarborough","43.5911","-70.3682","United States","US","USA","Maine","","21857","1840052990"
"Olho d’Água das Flores","Olho d'Agua das Flores","-9.5358","-37.2939","Brazil","BR","BRA","Alagoas","","21738","1076043896"
"Smithfield","Smithfield","41.9014","-71.5308","United States","US","USA","Rhode Island","","21855","1840106200"
"Tigbao","Tigbao","7.8205","123.2277","Philippines","PH","PHL","Zamboanga del Sur","","21675","1608240626"
"Santa Teresa","Santa Teresa","-19.9358","-40.6000","Brazil","BR","BRA","Espírito Santo","","21823","1076719847"
"Brockville","Brockville","44.5833","-75.6833","Canada","CA","CAN","Ontario","","21854","1124286131"
"Ramona","Ramona","33.0474","-116.8766","United States","US","USA","California","","21853","1840019346"
"Sapulpa","Sapulpa","36.0091","-96.1003","United States","US","USA","Oklahoma","","21853","1840021691"
"Mbandjok","Mbandjok","4.4500","11.9000","Cameroon","CM","CMR","Centre","","21841","1120826552"
"Ocotlán de Morelos","Ocotlan de Morelos","16.7914","-96.6750","Mexico","MX","MEX","Oaxaca","minor","21676","1484002311"
"Essex","Essex","44.5196","-73.0656","United States","US","USA","Vermont","","21851","1840115363"
"Tidili Masfiywat","Tidili Masfiywat","31.4667","-7.6167","Morocco","MA","MAR","Marrakech-Safi","","21706","1504563620"
"Ozoir-la-Ferrière","Ozoir-la-Ferriere","48.7780","2.6800","France","FR","FRA","Île-de-France","","20532","1250806458"
"Cagwait","Cagwait","8.9181","126.3017","Philippines","PH","PHL","Surigao del Sur","","21747","1608922741"
"Piazza Armerina","Piazza Armerina","37.3833","14.3667","Italy","IT","ITA","Sicilia","","21775","1380975145"
"Nowa Ruda","Nowa Ruda","50.5833","16.5000","Poland","PL","POL","Dolnośląskie","","21264","1616805229"
"Siilinjärvi","Siilinjarvi","63.0750","27.6600","Finland","FI","FIN","Pohjois-Savo","minor","21794","1246336548"
"Pueblo Juárez","Pueblo Juarez","17.7000","-93.2000","Mexico","MX","MEX","Chiapas","","21807","1484543161"
"East Patchogue","East Patchogue","40.7704","-72.9817","United States","US","USA","New York","","21830","1840005040"
"Forquilha","Forquilha","-3.7978","-40.2608","Brazil","BR","BRA","Ceará","","21786","1076612158"
"Itajuípe","Itajuipe","-14.6778","-39.3750","Brazil","BR","BRA","Bahia","","21754","1076492773"
"Ma’mūnīyeh","Ma'muniyeh","35.2992","50.5117","Iran","IR","IRN","Markazī","","21814","1364067553"
"Kumla","Kumla","59.1167","15.1333","Sweden","SE","SWE","Örebro","minor","21640","1752201206"
"Fairhope","Fairhope","30.5209","-87.8813","United States","US","USA","Alabama","","21825","1840002039"
"San Martín de las Pirámides","San Martin de las Piramides","19.7333","-98.8167","Mexico","MX","MEX","México","minor","21511","1484000182"
"Chełmno","Chelmno","53.3492","18.4231","Poland","PL","POL","Kujawsko-Pomorskie","minor","20428","1616879030"
"Nellimarla","Nellimarla","18.1667","83.4333","India","IN","IND","Andhra Pradesh","","20498","1356634988"
"Bornem","Bornem","51.1000","4.2333","Belgium","BE","BEL","Flanders","","21353","1056491633"
"Sanford","Sanford","43.4244","-70.7573","United States","US","USA","Maine","","21812","1840002672"
"Pendurti","Pendurti","17.8278","83.2022","India","IN","IND","Andhra Pradesh","","21447","1356081644"
"Puerto Triunfo","Puerto Triunfo","5.8667","-74.6500","Colombia","CO","COL","Antioquia","minor","21745","1170980494"
"São Francisco de Paula","Sao Francisco de Paula","-29.4478","-50.5839","Brazil","BR","BRA","Rio Grande do Sul","","21801","1076280018"
"São Lourenço d’Oeste","Sao Lourenco d'Oeste","-26.3589","-52.8508","Brazil","BR","BRA","Santa Catarina","","21792","1076967132"
"Kosgi","Kosgi","16.9839","77.7193","India","IN","IND","Andhra Pradesh","","21215","1356669733"
"San Bernardo","San Bernardo","1.5108","-77.0467","Colombia","CO","COL","Nariño","minor","21492","1170882959"
"Amizmiz","Amizmiz","31.2167","-8.2500","Morocco","MA","MAR","Marrakech-Safi","","21445","1504971543"
"Ambatomanoina","Ambatomanoina","-18.3167","47.6167","Madagascar","MG","MDG","Antananarivo","","21779","1450369214"
"Allauch","Allauch","43.3369","5.4828","France","FR","FRA","Provence-Alpes-Côte d’Azur","","21372","1250339228"
"Collingwood","Collingwood","44.5000","-80.2167","Canada","CA","CAN","Ontario","","21793","1124219884"
"G‘azalkent","G\`azalkent","41.5625","69.7750","Uzbekistan","UZ","UZB","Toshkent","","21600","1860474160"
"Gülşehir","Gulsehir","38.7464","34.6206","Turkey","TR","TUR","Nevşehir","minor","21771","1792089346"
"Monsummano","Monsummano","43.8667","10.8167","Italy","IT","ITA","Tuscany","","21141","1380798376"
"Puruk Cahu","Puruk Cahu","-0.6384","114.5677","Indonesia","ID","IDN","Kalimantan Tengah","minor","21781","1360025620"
"Del City","Del City","35.4483","-97.4408","United States","US","USA","Oklahoma","","21778","1840019154"
"Pergine Valsugana","Pergine Valsugana","46.0667","11.2333","Italy","IT","ITA","Trentino-Alto Adige","","21384","1380117079"
"El Callao","El Callao","7.3463","-61.8271","Venezuela","VE","VEN","Bolívar","minor","21769","1862703627"
"Etajima","Etajima","34.2167","132.4500","Japan","JP","JPN","Hiroshima","","21557","1392003172"
"Sori","Sori","10.7281","2.7825","Benin","BJ","BEN","Alibori","","21735","1204061306"
"Unity","Unity","40.2811","-79.4236","United States","US","USA","Pennsylvania","","21765","1840144811"
"Corciano","Corciano","43.1333","12.2833","Italy","IT","ITA","Umbria","","21427","1380269037"
"Zara","Zara","39.8950","37.7531","Turkey","TR","TUR","Sivas","minor","21753","1792319821"
"Palo del Colle","Palo del Colle","41.0500","16.7000","Italy","IT","ITA","Puglia","","21488","1380083467"
"Can-Avid","Can-Avid","12.0000","125.4500","Philippines","PH","PHL","Eastern Samar","","21682","1608000580"
"Mezdra","Mezdra","43.1500","23.7000","Bulgaria","BG","BGR","Vratsa","","21726","1100715931"
"Srīvaikuntam","Srivaikuntam","8.6318","77.9130","India","IN","IND","Tamil Nādu","","21411","1356398114"
"Bilohorodka","Bilohorodka","50.4000","30.2167","Ukraine","UA","UKR","Kyivska Oblast","","21367","1804583708"
"Chaudfontaine","Chaudfontaine","50.5833","5.6333","Belgium","BE","BEL","Wallonia","","20935","1056159261"
"Qal’acha","Qal'acha","40.1333","69.6833","Tajikistan","TJ","TJK","Sughd","","21585","1762339363"
"Miguelópolis","Miguelopolis","-20.1794","-48.0319","Brazil","BR","BRA","São Paulo","","21728","1076327986"
"Zonhoven","Zonhoven","50.9853","5.3647","Belgium","BE","BEL","Flanders","","21214","1056182511"
"Shanywathit","Shanywathit","17.4244","97.8878","Myanmar","MM","MMR","Kayin State","","21735","1104224930"
"Kurikka","Kurikka","62.6167","22.4000","Finland","FI","FIN","Etelä-Pohjanmaa","minor","21734","1246688374"
"Vicksburg","Vicksburg","32.3173","-90.8868","United States","US","USA","Mississippi","","21748","1840015802"
"Aichach","Aichach","48.4500","11.1333","Germany","DE","DEU","Bavaria","minor","21515","1276090074"
"São Marcos","Sao Marcos","-28.9708","-51.0678","Brazil","BR","BRA","Rio Grande do Sul","","21658","1076872375"
"Chernogolovka","Chernogolovka","56.0000","38.3667","Russia","RU","RUS","Moskovskaya Oblast’","","21342","1643604279"
"Tinja","Tinja","37.1667","9.7667","Tunisia","TN","TUN","Bizerte","","21167","1788355927"
"Moularès","Moulares","34.4833","8.2667","Tunisia","TN","TUN","Gafsa","","21716","1788693734"
"Huy","Huy","50.5167","5.2333","Belgium","BE","BEL","Wallonia","minor","21293","1056597064"
"Gopavaram","Gopavaram","14.7841","78.5729","India","IN","IND","Andhra Pradesh","","20267","1356037338"
"Sihu","Sihu","23.6411","120.2010","Taiwan","TW","TWN","Yunlin","","21458","1158387036"
"American Canyon","American Canyon","38.1796","-122.2583","United States","US","USA","California","","21735","1840018834"
"Germersheim","Germersheim","49.2167","8.3667","Germany","DE","DEU","Rhineland-Palatinate","minor","20779","1276963829"
"Gökçebey","Gokcebey","41.3081","32.1417","Turkey","TR","TUR","Zonguldak","minor","21655","1792723189"
"Göd","God","47.6906","19.1344","Hungary","HU","HUN","Pest","","20799","1348600580"
"Kaikalūr","Kaikalur","16.5667","81.2000","India","IN","IND","Andhra Pradesh","","21292","1356743819"
"Birmingham","Birmingham","42.5446","-83.2166","United States","US","USA","Michigan","","21728","1840002438"
"Kibeho","Kibeho","-2.6333","29.5500","Rwanda","RW","RWA","Southern Province","","21456","1646397419"
"Evans","Evans","40.3660","-104.7390","United States","US","USA","Colorado","","21727","1840020147"
"Stadtallendorf","Stadtallendorf","50.8333","9.0167","Germany","DE","DEU","Hesse","","21456","1276032217"
"Cujubim","Cujubim","-9.3628","-62.5853","Brazil","BR","BRA","Rondônia","","21720","1076802495"
"Kavak","Kavak","41.0736","36.0403","Turkey","TR","TUR","Samsun","minor","21692","1792170662"
"Duarte","Duarte","34.1610","-117.9504","United States","US","USA","California","","21723","1840019246"
"Kobilo","Kobilo","15.9251","-13.5242","Senegal","SN","SEN","Matam","","21656","1686275678"
"Xinzhai","Xinzhai","26.6959","106.9964","China","CN","CHN","Guizhou","","21607","1156424497"
"Brent","Brent","30.4727","-87.2495","United States","US","USA","Florida","","21717","1840013913"
"Kami","Kami","38.5718","140.8548","Japan","JP","JPN","Miyagi","","21666","1392003486"
"Novopokrovka","Novopokrovka","42.8708","74.7508","Kyrgyzstan","KG","KGZ","Chüy","","21619","1417819015"
"Noāmundi","Noamundi","22.1609","85.5042","India","IN","IND","Jhārkhand","","21423","1356171764"
"Klamath Falls","Klamath Falls","42.2195","-121.7754","United States","US","USA","Oregon","","21710","1840020054"
"Shirhatti","Shirhatti","15.2313","75.5772","India","IN","IND","Karnātaka","","21394","1356225676"
"Alcalá la Real","Alcala la Real","37.4500","-3.9167","Spain","ES","ESP","Andalusia","","21623","1724682908"
"Eyl","Eyl","7.9667","49.8500","Somalia","SO","SOM","Nugaal","","21700","1706929381"
"Nāmagiripettai","Namagiripettai","11.4700","78.2700","India","IN","IND","Tamil Nādu","","21250","1356621032"
"Nuenen","Nuenen","51.4733","5.5467","Netherlands","NL","NLD","Noord-Brabant","minor","20580","1528177816"
"Lisieux","Lisieux","49.1500","0.2300","France","FR","FRA","Normandie","minor","20171","1250048111"
"Manor","Manor","39.9848","-76.4216","United States","US","USA","Pennsylvania","","21703","1840147751"
"Nerja","Nerja","36.7469","-3.8790","Spain","ES","ESP","Andalusia","","21450","1724190692"
"Zerbst","Zerbst","51.9681","12.0844","Germany","DE","DEU","Saxony-Anhalt","","21657","1276007718"
"Montecatini Terme","Montecatini Terme","43.8828","10.7711","Italy","IT","ITA","Tuscany","","20540","1380958897"
"Vicente Noble","Vicente Noble","18.3833","-71.1833","Dominican Republic","DO","DOM","Enriquillo","","21605","1214778645"
"Tlaltenango de Sánchez Román","Tlaltenango de Sanchez Roman","21.7815","-103.3059","Mexico","MX","MEX","Zacatecas","minor","21636","1484361545"
"Bayou Cane","Bayou Cane","29.6243","-90.7510","United States","US","USA","Louisiana","","21699","1840014031"
"Łaziska Górne","Laziska Gorne","50.1500","18.8500","Poland","PL","POL","Śląskie","","21592","1616701110"
"Sedalia","Sedalia","38.7059","-93.2340","United States","US","USA","Missouri","","21696","1840009724"
"Marvast","Marvast","30.4775","54.2128","Iran","IR","IRN","Yazd","","21690","1364747499"
"Ashland","Ashland","38.4592","-82.6448","United States","US","USA","Kentucky","","21695","1840013195"
"Mānikganj","Manikganj","23.8500","90.0167","Bangladesh","BD","BGD","Dhaka","","20000","1050452657"
"San Jacinto","San Jacinto","9.8311","-75.1219","Colombia","CO","COL","Bolívar","minor","21644","1170354916"
"São Felipe","Sao Felipe","-12.8469","-39.0889","Brazil","BR","BRA","Bahia","","21582","1076363237"
"Cassilândia","Cassilandia","-19.1128","-51.7339","Brazil","BR","BRA","Mato Grosso do Sul","","21685","1076404532"
"Nova Brasilândia d’Oeste","Nova Brasilandia d'Oeste","-11.7197","-62.3158","Brazil","BR","BRA","Rondônia","","21670","1076575922"
"Follonica","Follonica","42.9189","10.7614","Italy","IT","ITA","Tuscany","","21308","1380824134"
"Wülfrath","Wulfrath","51.2833","7.0333","Germany","DE","DEU","North Rhine-Westphalia","","21035","1276377418"
"Āndippatti","Andippatti","9.9980","77.6210","India","IN","IND","Tamil Nādu","","21435","1356542749"
"New Hope","New Hope","45.0375","-93.3869","United States","US","USA","Minnesota","","21683","1840007829"
"Muhlenberg","Muhlenberg","40.3955","-75.9250","United States","US","USA","Pennsylvania","","21682","1840100491"
"Büren","Buren","51.5500","8.5667","Germany","DE","DEU","North Rhine-Westphalia","","21556","1276905250"
"Makulubita","Makulubita","0.5122","32.3664","Uganda","UG","UGA","Luwero","","21300","1800015106"
"Entroncamento","Entroncamento","39.4653","-8.4681","Portugal","PT","PRT","Santarém","minor","20206","1620318542"
"Kangal","Kangal","39.2367","37.3886","Turkey","TR","TUR","Sivas","minor","21669","1792418082"
"Yenice","Yenice","41.2000","32.3292","Turkey","TR","TUR","Karabük","minor","21625","1792456391"
"Granite Bay","Granite Bay","38.7601","-121.1714","United States","US","USA","California","","21673","1840017559"
"Waghäusel","Waghausel","49.2500","8.5167","Germany","DE","DEU","Baden-Württemberg","","21178","1276002094"
"Kant","Kant","42.8833","74.8500","Kyrgyzstan","KG","KGZ","Chüy","minor","21589","1417254104"
"Nefta","Nefta","33.8833","7.8833","Tunisia","TN","TUN","Tozeur","","21654","1788494742"
"Gīnīr","Ginir","7.1333","40.7000","Ethiopia","ET","ETH","Oromīya","","21585","1231167016"
"Rancheria Payau","Rancheria Payau","7.8509","123.1542","Philippines","PH","PHL","Zamboanga del Sur","","21559","1608410057"
"Bhojpur Kadīm","Bhojpur Kadim","25.5841","84.1265","India","IN","IND","Bihār","","20474","1356907861"
"Yucca Valley","Yucca Valley","34.1234","-116.4216","United States","US","USA","California","","21663","1840022726"
"Senaki","Senaki","42.2689","42.0678","Georgia","GE","GEO","Samegrelo-Zemo Svaneti","minor","21596","1268594062"
"Palestina de los Altos","Palestina de los Altos","14.9333","-91.7000","Guatemala","GT","GTM","Quetzaltenango","minor","20719","1320342960"
"Crisópolis","Crisopolis","-11.5108","-38.1500","Brazil","BR","BRA","Bahia","","21617","1076853607"
"Rāmnagar Farsāhi","Ramnagar Farsahi","25.8904","87.1284","India","IN","IND","Bihār","","20374","1356975268"
"Denain","Denain","50.3286","3.3950","France","FR","FRA","Hauts-de-France","","19933","1250726384"
"Paraíso","Paraiso","9.8432","-83.8463","Costa Rica","CR","CRI","Cartago","","20601","1188699728"
"Mililani Mauka","Mililani Mauka","21.4756","-157.9948","United States","US","USA","Hawaii","","21657","1840039224"
"Shāmgarh","Shamgarh","25.8935","86.8759","India","IN","IND","Bihār","","20273","1356015242"
"Pandan","Pandan","14.0500","124.1667","Philippines","PH","PHL","Catanduanes","","21473","1608192117"
"San Nicolás","San Nicolas","22.7819","-81.9069","Cuba","CU","CUB","Mayabeque","minor","21563","1192849250"
"Frederickson","Frederickson","47.0916","-122.3603","United States","US","USA","Washington","","21651","1840037551"
"Challans","Challans","46.8467","-1.8781","France","FR","FRA","Pays de la Loire","","21322","1250531482"
"Hazel Dell","Hazel Dell","45.6797","-122.6553","United States","US","USA","Washington","","21650","1840037662"
"Lindlar","Lindlar","51.0167","7.3833","Germany","DE","DEU","North Rhine-Westphalia","","21396","1276176624"
"Murree","Murree","33.9042","73.3903","Pakistan","PK","PAK","Punjab","","20869","1586573416"
"Nueva Granada","Nueva Granada","9.8031","-74.3903","Colombia","CO","COL","Magdalena","minor","21612","1170955196"
"Millburn","Millburn","40.7394","-74.3240","United States","US","USA","New Jersey","","21637","1840081751"
"Tamgrout","Tamgrout","30.2667","-5.6667","Morocco","MA","MAR","Drâa-Tafilalet","","21603","1504571275"
"Middleton","Middleton","43.1064","-89.5059","United States","US","USA","Wisconsin","","21634","1840002919"
"Lakato","Lakato","-19.1833","48.4333","Madagascar","MG","MDG","Toamasina","","21609","1450863135"
"La Resolana","La Resolana","19.6031","-104.4362","Mexico","MX","MEX","Jalisco","minor","21584","1484556709"
"San Justo","San Justo","-30.7833","-60.5833","Argentina","AR","ARG","Santa Fe","minor","21624","1032734419"
"Geseke","Geseke","51.6500","8.5167","Germany","DE","DEU","North Rhine-Westphalia","","21411","1276815446"
"Zhoucun","Zhoucun","37.4509","115.4829","China","CN","CHN","Hebei","","21401","1156879512"
"Hoogstraten","Hoogstraten","51.4000","4.7667","Belgium","BE","BEL","Flanders","","21424","1056021828"
"Leerdam","Leerdam","51.9000","5.1000","Netherlands","NL","NLD","Zuid-Holland","minor","21030","1528119382"
"Tolosa","Tolosa","11.0633","125.0353","Philippines","PH","PHL","Leyte","","20708","1608095230"
"Bānki","Banki","20.3791","85.5295","India","IN","IND","Odisha","","21105","1356526925"
"Bear","Bear","39.6189","-75.6808","United States","US","USA","Delaware","","21625","1840005568"
"Almasi","Almasi","38.6833","68.6000","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","21261","1762773329"
"Bad Krozingen","Bad Krozingen","47.9167","7.7000","Germany","DE","DEU","Baden-Württemberg","","21029","1276964772"
"Chilca","Chilca","-12.5208","-76.7369","Peru","PE","PER","Lima","","21573","1604361895"
"Scugog","Scugog","44.0900","-78.9360","Canada","CA","CAN","Ontario","","21617","1124000741"
"Sondershausen","Sondershausen","51.3667","10.8667","Germany","DE","DEU","Thuringia","minor","21513","1276468586"
"Günzburg","Gunzburg","48.4527","10.2713","Germany","DE","DEU","Bavaria","minor","21233","1276986738"
"Inopacan","Inopacan","10.5000","124.7500","Philippines","PH","PHL","Leyte","","21389","1608108768"
"Galeras","Galeras","9.1667","-75.0500","Colombia","CO","COL","Sucre","minor","21548","1170148348"
"Zele","Zele","51.0667","4.0333","Belgium","BE","BEL","Flanders","","20976","1056909340"
"Kara-Suu","Kara-Suu","40.7000","72.8833","Kyrgyzstan","KG","KGZ","Osh","minor","20862","1417321617"
"Sāmba","Samba","32.5624","75.1199","India","IN","IND","Jammu and Kashmīr","","21006","1356029248"
"Tehata","Tehata","23.7274","88.5331","India","IN","IND","West Bengal","","21093","1356819415"
"Belén de Umbría","Belen de Umbria","5.2000","-75.8667","Colombia","CO","COL","Risaralda","minor","21450","1170015614"
"Wātrāp","Watrap","9.6353","77.6389","India","IN","IND","Tamil Nādu","","21129","1356997468"
"Rosario de Lerma","Rosario de Lerma","-24.9833","-65.5833","Argentina","AR","ARG","Salta","minor","21592","1032672697"
"Anagni","Anagni","41.7422","13.1583","Italy","IT","ITA","Lazio","","21404","1380482139"
"Nang Rong","Nang Rong","14.6283","102.7933","Thailand","TH","THA","Buri Ram","minor","21493","1764430858"
"Tong’anyi","Tong'anyi","35.3041","104.6802","China","CN","CHN","Gansu","","21448","1156749754"
"Farum","Farum","55.8083","12.3581","Denmark","DK","DNK","Hovedstaden","minor","20312","1208077125"
"Argenta","Argenta","44.6153","11.8333","Italy","IT","ITA","Emilia-Romagna","","21521","1380471746"
"Acatic","Acatic","20.7803","-102.9100","Mexico","MX","MEX","Jalisco","minor","21530","1484373854"
"Brunswick","Brunswick","43.9007","-69.9761","United States","US","USA","Maine","","21589","1840052591"
"Dolton","Dolton","41.6284","-87.5979","United States","US","USA","Illinois","","21588","1840011269"
"Naryn","Naryn","38.2000","68.9000","Tajikistan","TJ","TJK","Khatlon","","21314","1762541760"
"Eeklo","Eeklo","51.1858","3.5639","Belgium","BE","BEL","Flanders","minor","20890","1056979321"
"Āthagarh","Athagarh","20.5600","85.6300","India","IN","IND","Odisha","","20919","1356453513"
"Ambohitromby","Ambohitromby","-18.9667","46.3167","Madagascar","MG","MDG","Antananarivo","","21466","1450386250"
"Santa Rosa de Lima","Santa Rosa de Lima","14.3881","-90.2956","Guatemala","GT","GTM","Santa Rosa","minor","21415","1320410444"
"Pancas","Pancas","-19.2250","-40.8508","Brazil","BR","BRA","Espírito Santo","","21548","1076691599"
"Grottaferrata","Grottaferrata","41.7883","12.6717","Italy","IT","ITA","Lazio","","20460","1380288930"
"Pudupattanam","Pudupattanam","12.5037","80.1507","India","IN","IND","Tamil Nādu","","20897","1356118601"
"Shelby","Shelby","35.2904","-81.5451","United States","US","USA","North Carolina","","21564","1840015426"
"Clarin","Clarin","9.9667","124.0167","Philippines","PH","PHL","Bohol","","21158","1608201092"
"Paiganapalli","Paiganapalli","12.4760","78.1748","India","IN","IND","Tamil Nādu","","21069","1356817310"
"Qia’erbagecun","Qia'erbagecun","37.9766","77.3417","China","CN","CHN","Xinjiang","","21189","1156143854"
"Buritirama","Buritirama","-10.7078","-43.6308","Brazil","BR","BRA","Bahia","","21549","1076008986"
"Jämsä","Jamsa","61.8667","25.1833","Finland","FI","FIN","Keski-Suomi","minor","21542","1246902464"
"Chinchali","Chinchali","16.5647","74.8160","India","IN","IND","Karnātaka","","21052","1356555917"
"Kingsville","Kingsville","42.1000","-82.7167","Canada","CA","CAN","Ontario","","21552","1124616034"
"Savigliano","Savigliano","44.6500","7.6333","Italy","IT","ITA","Piedmont","","21526","1380258510"
"Nambiyūr","Nambiyur","11.3581","77.3211","India","IN","IND","Tamil Nādu","","20927","1356781564"
"Linamon","Linamon","8.1833","124.1667","Philippines","PH","PHL","Lanao del Norte","","21269","1608680252"
"Áno Sýros","Ano Syros","37.4500","24.9000","Greece","GR","GRC","Nótio Aigaío","minor","21507","1300291821"
"Porto Franco","Porto Franco","-6.3378","-47.3989","Brazil","BR","BRA","Maranhão","","21530","1076451922"
"Tukwila","Tukwila","47.4750","-122.2728","United States","US","USA","Washington","","21545","1840021120"
"Mundgod","Mundgod","14.9714","75.0366","India","IN","IND","Karnātaka","","21348","1356108329"
"Denderleeuw","Denderleeuw","50.8833","4.0833","Belgium","BE","BEL","Flanders","","20086","1056940491"
"Barro","Barro","-7.1769","-38.7819","Brazil","BR","BRA","Ceará","","21514","1076705196"
"Romano di Lombardia","Romano di Lombardia","45.5167","9.7500","Italy","IT","ITA","Lombardy","","20486","1380769763"
"Bishunpur","Bishunpur","26.0868","86.6541","India","IN","IND","Bihār","","19760","1356256599"
"Chatiā","Chatia","26.5396","84.5660","India","IN","IND","Bihār","","19924","1356989809"
"Cajueiro","Cajueiro","-9.3967","-36.1536","Brazil","BR","BRA","Alagoas","","21526","1076808412"
"Hajnówka","Hajnowka","52.7333","23.5833","Poland","PL","POL","Podlaskie","minor","20615","1616405290"
"Mabitac","Mabitac","14.4333","121.4167","Philippines","PH","PHL","Laguna","","21275","1608651432"
"Baie-Comeau","Baie-Comeau","49.2167","-68.1500","Canada","CA","CAN","Quebec","","21536","1124859576"
"Alejandro Korn","Alejandro Korn","-34.9667","-58.3667","Argentina","AR","ARG","Buenos Aires","","21407","1032420273"
"Gherla","Gherla","47.0200","23.9000","Romania","RO","ROU","Cluj","","20982","1642597159"
"Bogandé","Bogande","12.9667","-0.1500","Burkina Faso","BF","BFA","Est","minor","21443","1854811035"
"Santa Bárbara","Santa Barbara","5.8747","-75.5661","Colombia","CO","COL","Antioquia","minor","21413","1170129538"
"Rāybāg","Raybag","16.4800","74.7800","India","IN","IND","Karnātaka","","21026","1356002612"
"Deán Funes","Dean Funes","-30.4333","-64.3500","Argentina","AR","ARG","Córdoba","minor","21518","1032778928"
"Villagarzón","Villagarzon","1.0294","-76.6164","Colombia","CO","COL","Putumayo","minor","21507","1170786448"
"South Holland","South Holland","41.5977","-87.6022","United States","US","USA","Illinois","","21524","1840011324"
"Mpwapwa","Mpwapwa","-6.3500","36.4833","Tanzania","TZ","TZA","Dodoma","","21337","1834235223"
"Wieluń","Wielun","51.2206","18.5700","Poland","PL","POL","Łódzkie","minor","21270","1616628604"
"Blankenberge","Blankenberge","51.3000","3.1167","Belgium","BE","BEL","Flanders","","20349","1056155354"
"Sarreguemines","Sarreguemines","49.1100","7.0700","France","FR","FRA","Grand Est","minor","20820","1250256927"
"Rocky River","Rocky River","41.4702","-81.8525","United States","US","USA","Ohio","","21515","1840003410"
"Caombo","Caombo","-8.7000","16.5167","Angola","AO","AGO","Malanje","","21511","1024957584"
"Parasi","Parasi","24.1908","82.7817","India","IN","IND","Uttar Pradesh","","21203","1356773288"
"Takaba","Takaba","3.3963","40.2251","Kenya","KE","KEN","Mandera","","21474","1404710881"
"Parkāl","Parkal","18.2000","79.7167","India","IN","IND","Andhra Pradesh","","20257","1356956935"
"Miorimivalana","Miorimivalana","-17.2167","49.2000","Madagascar","MG","MDG","Toamasina","","21000","1450765179"
"Terrier Rouge","Terrier Rouge","19.6333","-71.9500","Haiti","HT","HTI","Nord-Est","","21328","1332922844"
"La Mornaghia","La Mornaghia","36.7667","10.0167","Tunisia","TN","TUN","Manouba","","19924","1788281746"
"Wang Nam Yen","Wang Nam Yen","13.5004","102.1806","Thailand","TH","THA","Sa Kaeo","minor","21301","1764123627"
"Livadeiá","Livadeia","38.4333","22.8667","Greece","GR","GRC","Stereá Elláda","minor","21379","1300871430"
"Paramonga","Paramonga","-10.6744","-77.8186","Peru","PE","PER","Lima","","21453","1604981726"
"Panagyurishte","Panagyurishte","42.5000","24.1833","Bulgaria","BG","BGR","Pazardzhik","minor","21430","1100223841"
"Sidi Rahal","Sidi Rahal","33.4720","-7.9570","Morocco","MA","MAR","Casablanca-Settat","","20628","1504637195"
"Llorente","Llorente","11.4125","125.5458","Philippines","PH","PHL","Eastern Samar","","21459","1608195797"
"Suzzara","Suzzara","44.9927","10.7494","Italy","IT","ITA","Lombardy","","21154","1380507493"
"Manhumirim","Manhumirim","-20.3578","-41.9578","Brazil","BR","BRA","Minas Gerais","","21382","1076127364"
"Atchoupa","Atchoupa","6.5333","2.6333","Benin","BJ","BEN","Ouémé","","19565","1204356835"
"Aspe","Aspe","38.3456","-0.7689","Spain","ES","ESP","Valencia","","21191","1724126700"
"Volnovakha","Volnovakha","47.5994","37.4999","Ukraine","UA","UKR","Donetska Oblast","minor","21441","1804124268"
"Nubl","Nubl","36.3756","36.9942","Syria","SY","SYR","Ḩalab","minor","21039","1760030884"
"Juli","Juli","-16.2125","-69.4603","Peru","PE","PER","Puno","","21462","1604693505"
"Sulphur","Sulphur","30.2286","-93.3566","United States","US","USA","Louisiana","","21490","1840015927"
"Almaguer","Almaguer","1.9167","-76.8667","Colombia","CO","COL","Cauca","minor","21374","1170307475"
"Xintian","Xintian","23.1427","103.5489","China","CN","CHN","Yunnan","","21159","1156074904"
"Hojambaz","Hojambaz","38.1167","65.0000","Turkmenistan","TM","TKM","Lebap","","21465","1795181508"
"Darien","Darien","41.0798","-73.4824","United States","US","USA","Connecticut","","21485","1840034409"
"El Paujíl","El Paujil","1.5644","-75.3319","Colombia","CO","COL","Caquetá","minor","21462","1170891728"
"Ryazhsk","Ryazhsk","53.7000","40.0500","Russia","RU","RUS","Ryazanskaya Oblast’","","21457","1643410159"
"Rosolini","Rosolini","36.8167","14.9500","Italy","IT","ITA","Sicilia","","21206","1380289458"
"La Unión","La Union","37.6192","-0.8756","Spain","ES","ESP","Murcia","","20656","1724665681"
"Anori","Anori","-3.7728","-61.6442","Brazil","BR","BRA","Amazonas","","21477","1076651452"
"Gökdepe","Gokdepe","38.1578","57.9664","Turkmenistan","TM","TKM","Ahal","","21465","1795372627"
"Puerto Natales","Puerto Natales","-51.7333","-72.5167","Chile","CL","CHL","Magallanes y de la Antártica Chilena","minor","21477","1152225106"
"Geneva","Geneva","41.8833","-88.3242","United States","US","USA","Illinois","","21472","1840008135"
"Enköping","Enkoping","59.6356","17.0764","Sweden","SE","SWE","Uppsala","minor","21121","1752230483"
"Loncoche","Loncoche","-39.3667","-72.6333","Chile","CL","CHL","Araucanía","","21458","1152864736"
"Rose Hill","Rose Hill","38.7872","-77.1085","United States","US","USA","Virginia","","21466","1840133323"
"Khotkovo","Khotkovo","56.2500","38.0000","Russia","RU","RUS","Moskovskaya Oblast’","","21349","1643642751"
"Aishō","Aisho","35.1688","136.2123","Japan","JP","JPN","Shiga","","20913","1392003056"
"Chāgalamarri","Chagalamarri","14.9667","78.5833","India","IN","IND","Andhra Pradesh","","21289","1356030918"
"Tubbergen","Tubbergen","52.4000","6.7833","Netherlands","NL","NLD","Overijssel","minor","21315","1528263465"
"Schramberg","Schramberg","48.2269","8.3842","Germany","DE","DEU","Baden-Württemberg","","21189","1276492911"
"El Bâzoûrîyé","El Bazouriye","33.2539","35.2717","Lebanon","LB","LBN","Liban-Sud","","20000","1422000794"
"Prudnik","Prudnik","50.3228","17.5767","Poland","PL","POL","Opolskie","minor","21237","1616117977"
"Givors","Givors","45.5906","4.7689","France","FR","FRA","Auvergne-Rhône-Alpes","","20285","1250743415"
"Elverum","Elverum","60.8819","11.5623","Norway","NO","NOR","Innlandet","minor","21435","1578144824"
"Palatka","Palatka","60.1000","150.9000","Russia","RU","RUS","Magadanskaya Oblast’","","21452","1643837733"
"Hafshejān","Hafshejan","32.2269","50.7931","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","21352","1364919018"
"Bīrpur","Birpur","25.5209","85.4614","India","IN","IND","Bihār","","20281","1356162092"
"Chodavaram","Chodavaram","17.8333","82.9500","India","IN","IND","Andhra Pradesh","","20251","1356853746"
"Mandza","Mandza","-11.4167","43.3000","Comoros","KM","COM","Grande Comore","","21000","1174892176"
"Tarko-Sale","Tarko-Sale","64.9167","77.8000","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","21448","1643113714"
"Jindřichŭv Hradec","Jindrichuv Hradec","49.1442","15.0031","Czechia","CZ","CZE","Jihočeský Kraj","","21169","1203413811"
"Sint-Katelijne-Waver","Sint-Katelijne-Waver","51.0667","4.5333","Belgium","BE","BEL","Flanders","","20870","1056641832"
"Galūgāh","Galugah","36.7272","53.8089","Iran","IR","IRN","Māzandarān","minor","21352","1364928073"
"Metahāra","Metahara","8.9000","39.9167","Ethiopia","ET","ETH","Oromīya","","21348","1231766002"
"Naraura","Naraura","28.1967","78.3814","India","IN","IND","Uttar Pradesh","","20376","1356087509"
"Kutná Hora","Kutna Hora","49.9483","15.2683","Czechia","CZ","CZE","Středočeský Kraj","","20828","1203692740"
"Sanhe","Sanhe","36.5643","105.6401","China","CN","CHN","Ningxia","minor","20844","1156227748"
"Traunreut","Traunreut","47.9667","12.5833","Germany","DE","DEU","Bavaria","","20977","1276958674"
"Secaucus","Secaucus","40.7810","-74.0659","United States","US","USA","New Jersey","","21439","1840001033"
"Holiday","Holiday","28.1864","-82.7429","United States","US","USA","Florida","","21435","1840014114"
"Fatehgarh Chūriān","Fatehgarh Churian","31.8643","74.9567","India","IN","IND","Punjab","","21007","1356030242"
"Cumaral","Cumaral","4.2694","-73.4864","Colombia","CO","COL","Meta","minor","21397","1170080308"
"Orleães","Orleaes","-28.3589","-49.2908","Brazil","BR","BRA","Santa Catarina","","21393","1076528540"
"Cugir","Cugir","45.8436","23.3636","Romania","RO","ROU","Alba","","21376","1642368746"
"Güimar","Guimar","28.3150","-16.4100","Spain","ES","ESP","Canary Islands","","21224","1724294895"
"Navalpattu","Navalpattu","10.7557","78.6064","India","IN","IND","Tamil Nādu","","20320","1356213472"
"Tizi-n-Bechar","Tizi-n-Bechar","36.4311","5.3600","Algeria","DZ","DZA","Bejaïa","","21086","1012925118"
"Matsuura","Matsuura","33.3333","129.7167","Japan","JP","JPN","Nagasaki","","21261","1392003110"
"Soltau","Soltau","52.9833","9.8333","Germany","DE","DEU","Lower Saxony","","21317","1276678434"
"Orós","Oros","-6.2439","-38.9139","Brazil","BR","BRA","Ceará","","21384","1076430062"
"Mī’ēso","Mi'eso","9.2333","40.7500","Ethiopia","ET","ETH","Oromīya","","21348","1231730193"
"Urk","Urk","52.6667","5.6000","Netherlands","NL","NLD","Flevoland","minor","21227","1528778897"
"Somers","Somers","41.3056","-73.7252","United States","US","USA","New York","","21419","1840058503"
"Ambinanindrano","Ambinanindrano","-20.6833","47.5667","Madagascar","MG","MDG","Fianarantsoa","","21364","1450392046"
"Cournon-d’Auvergne","Cournon-d'Auvergne","45.7422","3.1972","France","FR","FRA","Auvergne-Rhône-Alpes","","20322","1250524743"
"Ban Wat Sala Daeng","Ban Wat Sala Daeng","13.8097","100.3589","Thailand","TH","THA","Krung Thep Maha Nakhon","","20057","1764831787"
"Lehāra","Lehara","26.1515","86.1034","India","IN","IND","Bihār","","19697","1356928729"
"Karaiyāmpudūr","Karaiyampudur","11.0077","77.2942","India","IN","IND","Tamil Nādu","","20354","1356166236"
"North Salt Lake","North Salt Lake","40.8439","-111.9187","United States","US","USA","Utah","","21411","1840020144"
"Mukilteo","Mukilteo","47.9096","-122.3035","United States","US","USA","Washington","","21409","1840019793"
"Liberty Triangle","Liberty Triangle","29.0760","-82.2191","United States","US","USA","Florida","","21409","1840152944"
"Sanxing","Sanxing","24.6739","121.6631","Taiwan","TW","TWN","Yilan","","21258","1158866259"
"Pio XII","Pio XII","-3.8939","-45.1700","Brazil","BR","BRA","Maranhão","","21379","1076588816"
"Aígio","Aigio","38.2500","22.0833","Greece","GR","GRC","Dytikí Elláda","minor","20422","1300238679"
"Groß-Umstadt","Gross-Umstadt","49.8667","8.9333","Germany","DE","DEU","Hesse","","21162","1276232744"
"Kitcharao","Kitcharao","9.4581","125.5756","Philippines","PH","PHL","Agusan del Norte","","21278","1608800839"
"Teteven","Teteven","42.9167","24.2667","Bulgaria","BG","BGR","Lovech","","21374","1100032419"
"São João","Sao Joao","-8.8758","-36.3669","Brazil","BR","BRA","Pernambuco","","21312","1076391297"
"Bloomfield","Bloomfield","41.8426","-72.7406","United States","US","USA","Connecticut","","21399","1840004778"
"San Isidro","San Isidro","10.0299","-84.0431","Costa Rica","CR","CRI","Heredia","","20633","1188832612"
"Itapecerica","Itapecerica","-20.4728","-45.1258","Brazil","BR","BRA","Minas Gerais","","21377","1076261582"
"Colleferro","Colleferro","41.7333","13.0167","Italy","IT","ITA","Lazio","","21394","1380286659"
"Lorton","Lorton","38.6983","-77.2164","United States","US","USA","Virginia","","21394","1840006035"
"Los Vilos","Los Vilos","-31.9167","-71.5167","Chile","CL","CHL","Coquimbo","","21382","1152593219"
"Carthage","Carthage","36.8528","10.3233","Tunisia","TN","TUN","Tunis","","21276","1788412127"
"Sestu","Sestu","39.2994","9.0918","Italy","IT","ITA","Sardegna","","20958","1380888807"
"Recanati","Recanati","43.4000","13.5500","Italy","IT","ITA","Marche","","21186","1380935619"
"Narkher","Narkher","21.4383","78.5683","India","IN","IND","Mahārāshtra","","21227","1356862755"
"Paradise","Paradise","47.5333","-52.8667","Canada","CA","CAN","Newfoundland and Labrador","","21389","1124001159"
"Sessa Aurunca","Sessa Aurunca","41.2333","13.9333","Italy","IT","ITA","Campania","","21252","1380035714"
"Lakeside","Lakeside","32.8560","-116.9040","United States","US","USA","California","","21383","1840018025"
"St. Andrews","St. Andrews","34.0510","-81.1057","United States","US","USA","South Carolina","","21383","1840135211"
"Umrāpur","Umrapur","24.5863","87.9294","India","IN","IND","West Bengal","","19855","1356439102"
"Nāgamangala","Nagamangala","12.8194","76.7546","India","IN","IND","Karnātaka","","21188","1356823510"
"Pompéia","Pompeia","-22.1089","-50.1719","Brazil","BR","BRA","São Paulo","","21375","1076663020"
"Ronda","Ronda","10.0003","123.4095","Philippines","PH","PHL","Cebu","","21005","1608631219"
"Silva Jardim","Silva Jardim","-22.6508","-42.3919","Brazil","BR","BRA","Rio de Janeiro","","21349","1076211429"
"Park Forest","Park Forest","41.4817","-87.6867","United States","US","USA","Illinois","","21368","1840011313"
"Beaune","Beaune","47.0250","4.8397","France","FR","FRA","Bourgogne-Franche-Comté","minor","20711","1250996619"
"Bad Schwartau","Bad Schwartau","53.9194","10.6975","Germany","DE","DEU","Schleswig-Holstein","","20264","1276687859"
"Concepción Huista","Concepcion Huista","15.6167","-91.6833","Guatemala","GT","GTM","Huehuetenango","minor","21208","1320586594"
"Skive","Skive","56.5667","9.0333","Denmark","DK","DNK","Midtjylland","minor","20683","1208791354"
"Gryfino","Gryfino","53.2531","14.4875","Poland","PL","POL","Zachodniopomorskie","minor","21221","1616595491"
"Concepción Chiquirichapa","Concepcion Chiquirichapa","14.8500","-91.6167","Guatemala","GT","GTM","Quetzaltenango","minor","20920","1320512845"
"South Lake Tahoe","South Lake Tahoe","38.9393","-119.9828","United States","US","USA","California","","21355","1840021471"
"Bananeiras","Bananeiras","-6.7500","-35.6328","Brazil","BR","BRA","Paraíba","","21269","1076063648"
"Varadero","Varadero","23.1394","-81.2861","Cuba","CU","CUB","Matanzas","","21170","1192203270"
"Ashland","Ashland","42.1905","-122.6992","United States","US","USA","Oregon","","21348","1840018674"
"Coswig","Coswig","51.1333","13.5833","Germany","DE","DEU","Saxony","","20554","1276791212"
"Shisui","Shisui","35.7249","140.2695","Japan","JP","JPN","Chiba","","20278","1392687208"
"Schenefeld","Schenefeld","53.6028","9.8233","Germany","DE","DEU","Schleswig-Holstein","","19402","1276791655"
"Kaltan","Kaltan","53.5167","87.2833","Russia","RU","RUS","Kemerovskaya Oblast’","","20841","1643027336"
"Lubań","Luban","51.1181","15.2889","Poland","PL","POL","Dolnośląskie","minor","20096","1616557848"
"Mobetsu","Mobetsu","44.3564","143.3544","Japan","JP","JPN","Hokkaidō","","21317","1392557863"
"Garforth","Garforth","53.7920","-1.3880","United Kingdom","GB","GBR","Leeds","","19811","1826216468"
"Aralık","Aralik","39.8728","44.5192","Turkey","TR","TUR","Adıyaman","minor","21311","1792704359"
"Grand Island","Grand Island","43.0198","-78.9619","United States","US","USA","New York","","21340","1840087480"
"Lyskovo","Lyskovo","56.0289","45.0361","Russia","RU","RUS","Nizhegorodskaya Oblast’","","21321","1643597130"
"Mitry-Mory","Mitry-Mory","48.9839","2.6164","France","FR","FRA","Île-de-France","","20648","1250583978"
"Youganning","Youganning","34.7356","101.5978","China","CN","CHN","Qinghai","minor","21323","1156189202"
"Dickinson","Dickinson","29.4548","-95.0589","United States","US","USA","Texas","","21336","1840019667"
"Belovodskoe","Belovodskoe","42.8300","74.1000","Kyrgyzstan","KG","KGZ","Chüy","minor","21275","1417996342"
"Asni","Asni","31.2500","-7.9833","Morocco","MA","MAR","Marrakech-Safi","","21244","1504093117"
"Luna","Luna","18.3311","121.3725","Philippines","PH","PHL","Apayao","","21297","1608625482"
"Barcelona","Barcelona","12.8694","124.1419","Philippines","PH","PHL","Sorsogon","","20987","1608408567"
"Katākos","Katakos","25.3339","87.6715","India","IN","IND","Bihār","","20304","1356127564"
"Asarganj","Asarganj","25.1500","86.6800","India","IN","IND","Bihār","","20000","1356622092"
"Daboh","Daboh","26.0024","78.8766","India","IN","IND","Madhya Pradesh","","20984","1356044526"
"Akonolinga","Akonolinga","3.7667","12.2500","Cameroon","CM","CMR","Centre","","21299","1120103788"
"Oshoba","Oshoba","40.7347","70.4417","Tajikistan","TJ","TJK","Sughd","","21260","1762699211"
"Matanga","Matanga","-23.5167","47.5500","Madagascar","MG","MDG","Fianarantsoa","","21000","1450994250"
"Algete","Algete","40.5978","-3.5003","Spain","ES","ESP","Madrid","","20767","1724204700"
"Ādēt","Adet","11.2667","37.4833","Ethiopia","ET","ETH","Āmara","","21117","1231929103"
"Krishnapur","Krishnapur","24.4123","88.2582","India","IN","IND","West Bengal","","19462","1356078973"
"Villanueva","Villanueva","10.4456","-75.2761","Colombia","CO","COL","Bolívar","minor","21135","1170676204"
"Puzol","Puzol","39.6167","-0.3011","Spain","ES","ESP","Valencia","","20191","1724895655"
"Lugait","Lugait","8.3411","124.2592","Philippines","PH","PHL","Misamis Oriental","","20559","1608172910"
"Injibara","Injibara","10.9500","36.9333","Ethiopia","ET","ETH","Āmara","","21065","1231853157"
"Araripe","Araripe","-7.2128","-40.0458","Brazil","BR","BRA","Ceará","","21289","1076163103"
"Voiron","Voiron","45.3642","5.5906","France","FR","FRA","Auvergne-Rhône-Alpes","","20372","1250058509"
"Jiangjiadong","Jiangjiadong","24.4985","112.8397","China","CN","CHN","Guangdong","","21204","1156218941"
"Silverdale","Silverdale","47.6663","-122.6828","United States","US","USA","Washington","","21301","1840018406"
"Carei","Carei","47.6839","22.4669","Romania","RO","ROU","Satu Mare","","21112","1642934242"
"Tanhaçu","Tanhacu","-14.0208","-41.2478","Brazil","BR","BRA","Bahia","","21282","1076735805"
"Traunstein","Traunstein","47.8667","12.6333","Germany","DE","DEU","Bavaria","minor","20868","1276793310"
"Bruchköbel","Bruchkobel","50.1833","8.9167","Germany","DE","DEU","Hesse","","20602","1276199010"
"Sādpur","Sadpur","25.4758","86.3786","India","IN","IND","Bihār","","19600","1356083174"
"Kattanam","Kattanam","9.1833","76.5500","India","IN","IND","Kerala","","19764","1356034553"
"Pushchino","Pushchino","54.8333","37.6167","Russia","RU","RUS","Moskovskaya Oblast’","","21281","1643679889"
"Musselburgh","Musselburgh","55.9420","-3.0540","United Kingdom","GB","GBR","East Lothian","","20840","1826974542"
"Bülach","Bulach","47.5167","8.5333","Switzerland","CH","CHE","Zürich","","20046","1756393445"
"Nerekhta","Nerekhta","57.4500","40.5833","Russia","RU","RUS","Kostromskaya Oblast’","","21262","1643170330"
"Dupax del Sur","Dupax del Sur","16.2842","121.0917","Philippines","PH","PHL","Nueva Vizcaya","","21224","1608036917"
"Santa María Chilchotla","Santa Maria Chilchotla","18.2333","-96.8167","Mexico","MX","MEX","Oaxaca","minor","20328","1484011437"
"Manuel Tames","Manuel Tames","20.1803","-75.0514","Cuba","CU","CUB","Guantánamo","","21237","1192473479"
"Monte Sião","Monte Siao","-22.4328","-46.5728","Brazil","BR","BRA","Minas Gerais","","21203","1076832513"
"Vittal","Vittal","12.7660","75.1220","India","IN","IND","Karnātaka","","20658","1356920016"
"Baldwin","Baldwin","40.3690","-79.9669","United States","US","USA","Pennsylvania","","21274","1840001201"
"Alfena","Alfena","41.2381","-8.5253","Portugal","PT","PRT","Porto","","20000","1620416709"
"Conceição de Macabu","Conceicao de Macabu","-22.0850","-41.8678","Brazil","BR","BRA","Rio de Janeiro","","21211","1076769860"
"Bonito","Bonito","-21.1333","-56.4833","Brazil","BR","BRA","Mato Grosso do Sul","","21267","1076969524"
"Huseni","Huseni","26.3768","84.7952","India","IN","IND","Bihār","","19546","1356133187"
"Monte Santo de Minas","Monte Santo de Minas","-21.1900","-46.9800","Brazil","BR","BRA","Minas Gerais","","21234","1076791401"
"Palavūr","Palavur","8.2055","77.5755","India","IN","IND","Tamil Nādu","","20870","1356207947"
"Cranendonck","Cranendonck","51.2853","5.5881","Netherlands","NL","NLD","Noord-Brabant","","21001","1528448818"
"Rio Maior","Rio Maior","39.3333","-8.9333","Portugal","PT","PRT","Santarém","minor","21192","1620036651"
"Nizāmpatam","Nizampatam","15.9000","80.6667","India","IN","IND","Andhra Pradesh","","20982","1356034288"
"Hindang","Hindang","10.4339","124.7278","Philippines","PH","PHL","Leyte","","20849","1608422710"
"Selden","Selden","40.8714","-73.0466","United States","US","USA","New York","","21265","1840005093"
"Moorestown","Moorestown","39.9784","-74.9413","United States","US","USA","New Jersey","","21265","1840081626"
"Aguadas","Aguadas","5.6167","-75.4667","Colombia","CO","COL","Caldas","minor","21237","1170536116"
"Lucélia","Lucelia","-21.7203","-51.0189","Brazil","BR","BRA","São Paulo","","21196","1076675967"
"Timmarāsanāyakkanūr","Timmarasanayakkanur","10.0015","77.6769","India","IN","IND","Tamil Nādu","","21014","1356228777"
"Fierenana","Fierenana","-18.5500","45.8500","Madagascar","MG","MDG","Antananarivo","","21224","1450253158"
"San Antonio Sacatepéquez","San Antonio Sacatepequez","14.9667","-91.7333","Guatemala","GT","GTM","San Marcos","minor","20994","1320733193"
"Adigaratti","Adigaratti","11.3357","76.6971","India","IN","IND","Tamil Nādu","","21123","1356123782"
"Erval d’Oeste","Erval d'Oeste","-27.1939","-51.4950","Brazil","BR","BRA","Santa Catarina","","21239","1076008886"
"Ferentino","Ferentino","41.6833","13.2500","Italy","IT","ITA","Lazio","","20992","1380938907"
"Brezno","Brezno","48.8039","19.6436","Slovakia","SK","SVK","Banská Bystrica","minor","21082","1703148175"
"Itororó","Itororo","-15.1169","-40.0700","Brazil","BR","BRA","Bahia","","21178","1076665799"
"Montecristo","Montecristo","8.2994","-74.4756","Colombia","CO","COL","Bolívar","minor","21229","1170653032"
"San Isidro de Lules","San Isidro de Lules","-26.9333","-65.3500","Argentina","AR","ARG","Tucumán","minor","21088","1032638777"
"Carrboro","Carrboro","35.9259","-79.0878","United States","US","USA","North Carolina","","21237","1840015334"
"Chokkampatti","Chokkampatti","9.1259","77.3634","India","IN","IND","Tamil Nādu","","20383","1356214229"
"Jagatpur","Jagatpur","23.6048","90.7628","Bangladesh","BD","BGD","Chattogram","","19830","1050544125"
"San Juan de Río Coco","San Juan de Rio Coco","13.5444","-86.1644","Nicaragua","NI","NIC","Madriz","minor","21114","1558017262"
"Peritoró","Peritoro","-4.3828","-44.3328","Brazil","BR","BRA","Maranhão","","21201","1076656373"
"Vili","Vili","12.2833","-2.2167","Burkina Faso","BF","BFA","Centre-Ouest","","20914","1854996122"
"Gannavaram","Gannavaram","16.5333","80.8000","India","IN","IND","Andhra Pradesh","","20728","1356037826"
"Shāzand","Shazand","33.9275","49.4117","Iran","IR","IRN","Markazī","minor","21181","1364700789"
"Sadao","Sadao","6.6386","100.4240","Thailand","TH","THA","Songkhla","minor","21086","1764417199"
"Hirekerūr","Hirekerur","14.4551","75.3952","India","IN","IND","Karnātaka","","20951","1356863080"
"Los Llanos de Aridane","Los Llanos de Aridane","28.6500","-17.9000","Spain","ES","ESP","Canary Islands","","20648","1724727479"
"Bobonong","Bobonong","-21.9704","28.4233","Botswana","BW","BWA","Central","","21216","1072058132"
"San Víctor Abajo","San Victor Abajo","19.4500","-70.5300","Dominican Republic","DO","DOM","Cibao Norte","","21009","1214414391"
"Baixa Grande","Baixa Grande","-11.9600","-40.1678","Brazil","BR","BRA","Bahia","","21197","1076238457"
"Westborough","Westborough","42.2681","-71.6140","United States","US","USA","Massachusetts","","21213","1840053611"
"Komatipoort","Komatipoort","-25.4333","31.9500","South Africa","ZA","ZAF","Mpumalanga","","20508","1710137353"
"Saponé","Sapone","12.0528","-1.6036","Burkina Faso","BF","BFA","Centre-Sud","minor","21148","1854915322"
"San Salvo","San Salvo","42.0455","14.7315","Italy","IT","ITA","Abruzzo","","20184","1380137726"
"Loanda","Loanda","-22.9228","-53.1369","Brazil","BR","BRA","Paraná","","21201","1076974194"
"Monroe","Monroe","41.3043","-74.1941","United States","US","USA","New York","","21206","1840004874"
"Gauting","Gauting","48.0678","11.3739","Germany","DE","DEU","Bavaria","","20829","1276677644"
"Maraú","Marau","-14.1028","-39.0150","Brazil","BR","BRA","Bahia","","21175","1076616022"
"Agan","Agan","35.9167","103.8471","China","CN","CHN","Gansu","","20985","1156933148"
"Hancha","Hancha","37.8572","109.4972","China","CN","CHN","Shaanxi","","21149","1156562804"
"Cauto Cristo","Cauto Cristo","20.5619","-76.4694","Cuba","CU","CUB","Granma","","21159","1192943802"
"Anping","Anping","39.7034","116.8954","China","CN","CHN","Hebei","","20450","1156795683"
"Varkaus","Varkaus","62.3167","27.8917","Finland","FI","FIN","Pohjois-Savo","minor","21155","1246449573"
"Seria","Seria","4.6141","114.3302","Brunei","BN","BRN","Belait","","21082","1096351415"
"Pleasant Prairie","Pleasant Prairie","42.5266","-87.8895","United States","US","USA","Wisconsin","","21193","1840003163"
"Ghatkesar","Ghatkesar","17.4494","78.6853","India","IN","IND","Andhra Pradesh","","19763","1356365517"
"Seymour","Seymour","38.9476","-85.8911","United States","US","USA","Indiana","","21192","1840009708"
"Yachiyo","Yachiyo","36.1816","139.8911","Japan","JP","JPN","Ibaraki","","20838","1392999979"
"Bastos","Bastos","-21.9167","-50.7333","Brazil","BR","BRA","São Paulo","","21067","1076067823"
"Bezhetsk","Bezhetsk","57.7667","36.7000","Russia","RU","RUS","Tverskaya Oblast’","","21179","1643708632"
"Stange","Stange","60.6494","11.3664","Norway","NO","NOR","Innlandet","minor","21156","1578696237"
"Antanambao","Antanambao","-19.6667","47.3333","Madagascar","MG","MDG","Antananarivo","","21000","1450771777"
"Gardner","Gardner","42.5845","-71.9868","United States","US","USA","Massachusetts","","21183","1840000436"
"Diamantino","Diamantino","-14.4089","-56.4458","Brazil","BR","BRA","Mato Grosso","","21180","1076901088"
"East Moline","East Moline","41.5199","-90.3879","United States","US","USA","Illinois","","21181","1840008180"
"Ambalavato","Ambalavato","-22.9667","47.4667","Madagascar","MG","MDG","Fianarantsoa","","21000","1450281811"
"Lino Lakes","Lino Lakes","45.1679","-93.0830","United States","US","USA","Minnesota","","21180","1840008902"
"Wipperfürth","Wipperfurth","51.1167","7.4000","Germany","DE","DEU","North Rhine-Westphalia","","21003","1276735297"
"Tsarasaotra","Tsarasaotra","-20.4333","47.2333","Madagascar","MG","MDG","Fianarantsoa","","21000","1450387787"
"Tittagudi","Tittagudi","11.4167","79.1167","India","IN","IND","Tamil Nādu","","20734","1356200149"
"Palāshi","Palashi","23.7898","88.2583","India","IN","IND","West Bengal","","19984","1356301670"
"Uxbridge","Uxbridge","44.1167","-79.1333","Canada","CA","CAN","Ontario","","21176","1124829638"
"Xinnongcun","Xinnongcun","42.2357","122.9455","China","CN","CHN","Liaoning","","21060","1156235883"
"Oulad Embarek","Oulad Embarek","32.2833","-6.4667","Morocco","MA","MAR","Béni Mellal-Khénifra","","19774","1504452976"
"Florencia","Florencia","22.1475","-78.9669","Cuba","CU","CUB","Ciego de Ávila","minor","21150","1192281767"
"Suaza","Suaza","1.9833","-75.8167","Colombia","CO","COL","Huila","minor","21119","1170853182"
"Bologoye","Bologoye","57.8833","34.0500","Russia","RU","RUS","Tverskaya Oblast’","minor","21158","1643381656"
"Al Brouj","Al Brouj","32.5050","-7.1927","Morocco","MA","MAR","Casablanca-Settat","","19235","1504448147"
"Nahorkatiya","Nahorkatiya","27.2891","95.3418","India","IN","IND","Assam","","20490","1356217812"
"Aguinaldo","Aguinaldo","16.9789","121.3272","Philippines","PH","PHL","Santiago","","21128","1608419129"
"Sahamadio","Sahamadio","-22.5167","47.5667","Madagascar","MG","MDG","Fianarantsoa","","21000","1450499923"
"Praia da Vitória","Praia da Vitoria","38.7333","-27.0667","Portugal","PT","PRT","Azores","","21035","1620566017"
"Goris","Goris","39.5078","46.3386","Armenia","AM","ARM","Syunik’","","20591","1051724931"
"Luna","Luna","16.9667","121.7333","Philippines","PH","PHL","Isabela","","20697","1608621353"
"Luisiana","Luisiana","14.1850","121.5109","Philippines","PH","PHL","Laguna","","20859","1608821290"
"Taperoá","Taperoa","-13.5378","-39.0989","Brazil","BR","BRA","Bahia","","21091","1076531500"
"Quirima","Quirima","-10.9000","18.0833","Angola","AO","AGO","Malanje","","21134","1024880976"
"Welench’ītī","Welench'iti","8.6667","39.4333","Ethiopia","ET","ETH","Oromīya","","20984","1231349696"
"Bambalang","Bambalang","5.8867","10.5331","Cameroon","CM","CMR","North-West","","20863","1120261303"
"Carlos Casares","Carlos Casares","-35.6167","-61.3667","Argentina","AR","ARG","Buenos Aires","minor","21125","1032627170"
"El Calafate","El Calafate","-50.3333","-72.2833","Argentina","AR","ARG","Santa Cruz","minor","21132","1032210475"
"Villapinzón","Villapinzon","5.2167","-73.6000","Colombia","CO","COL","Cundinamarca","minor","21049","1170598594"
"Schifferstadt","Schifferstadt","49.3833","8.3667","Germany","DE","DEU","Rhineland-Palatinate","","20403","1276776055"
"Brummen","Brummen","52.0833","6.1500","Netherlands","NL","NLD","Gelderland","minor","20884","1528439273"
"Fröndenberg","Frondenberg","51.4719","7.7658","Germany","DE","DEU","North Rhine-Westphalia","","20766","1276071190"
"Karaağaç","Karaagac","36.5667","36.1333","Turkey","TR","TUR","Hatay","","20459","1792377763"
"Chāndpur","Chandpur","25.5059","87.2488","India","IN","IND","Bihār","","20207","1356934631"
"Skegness","Skegness","53.1436","0.3428","United Kingdom","GB","GBR","Lincolnshire","","21128","1826662811"
"Mariāni","Mariani","26.6700","94.3300","India","IN","IND","Assam","","20801","1356109974"
"Fredonia","Fredonia","5.9167","-75.6667","Colombia","CO","COL","Antioquia","minor","20997","1170731658"
"Hino","Hino","35.0180","136.2460","Japan","JP","JPN","Shiga","","20945","1392003293"
"Damua","Damua","22.1929","78.4670","India","IN","IND","Madhya Pradesh","","20931","1356033304"
"Camanducaia","Camanducaia","-22.7550","-46.1450","Brazil","BR","BRA","Minas Gerais","","21080","1076164831"
"Vikhorevka","Vikhorevka","56.1167","101.1667","Russia","RU","RUS","Irkutskaya Oblast’","","21118","1643023865"
"Andolofotsy","Andolofotsy","-18.7500","46.6333","Madagascar","MG","MDG","Antananarivo","","21000","1450054125"
"Fót","Fot","47.6181","19.1903","Hungary","HU","HUN","Pest","","20568","1348547694"
"Hyde Park","Hyde Park","41.8011","-73.9060","United States","US","USA","New York","","21115","1840058196"
"Jangy-Nookat","Jangy-Nookat","40.2500","72.5500","Kyrgyzstan","KG","KGZ","Osh","","21049","1417565816"
"Ampanety","Ampanety","-18.9750","47.4944","Madagascar","MG","MDG","Antananarivo","","19135","1450696660"
"Bohumín","Bohumin","49.9042","18.3575","Czechia","CZ","CZE","Moravskoslezský Kraj","","20450","1203275330"
"Werdau","Werdau","50.7333","12.3833","Germany","DE","DEU","Saxony","","20793","1276717054"
"Milwaukie","Milwaukie","45.4445","-122.6219","United States","US","USA","Oregon","","21108","1840019952"
"Jatāra","Jatara","25.0096","79.0487","India","IN","IND","Madhya Pradesh","","20590","1356197815"
"Furano","Furano","43.3420","142.3832","Japan","JP","JPN","Hokkaidō","","21071","1392470642"
"Trecate","Trecate","45.4333","8.7333","Italy","IT","ITA","Piedmont","","20566","1380882788"
"Aurād","Aurad","18.2540","77.4176","India","IN","IND","Karnātaka","","20861","1356600800"
"Imi n’Oulaoun","Imi n'Oulaoun","31.3094","-6.4992","Morocco","MA","MAR","Drâa-Tafilalet","","21061","1504402350"
"Akora","Akora","33.9925","72.1450","Pakistan","PK","PAK","Khyber Pakhtunkhwa","","20157","1586486842"
"Payson","Payson","40.0355","-111.7390","United States","US","USA","Utah","","21093","1840020172"
"Bahía de Caráquez","Bahia de Caraquez","-0.6000","-80.4167","Ecuador","EC","ECU","Manabí","","20921","1218764402"
"Louisville","Louisville","39.9710","-105.1441","United States","US","USA","Colorado","","21091","1840020179"
"Cherakhera","Cherakhera","25.6869","86.4187","India","IN","IND","Bihār","","19736","1356573010"
"Khampat","Khampat","23.7823","94.1442","Myanmar","MM","MMR","Sagaing","","21000","1104599399"
"Sidi Taibi","Sidi Taibi","34.1917","-6.6845","Morocco","MA","MAR","Rabat-Salé-Kénitra","","19979","1504376928"
"La Colonia Tovar","La Colonia Tovar","10.4056","-67.2894","Venezuela","VE","VEN","Aragua","minor","21000","1862004978"
"Essa","Essa","44.2500","-79.7833","Canada","CA","CAN","Ontario","","21083","1124001569"
"Sirnia","Sirnia","25.4903","87.5892","India","IN","IND","Bihār","","19240","1356641138"
"Santo Anastácio","Santo Anastacio","-21.9769","-51.6519","Brazil","BR","BRA","São Paulo","","21044","1076929183"
"Kochas","Kochas","25.2340","83.9215","India","IN","IND","Bihār","","19803","1356055255"
"Sotouboua","Sotouboua","8.5667","0.9833","Togo","TG","TGO","Centrale","","21054","1768355631"
"Elektrougli","Elektrougli","55.7167","38.2000","Russia","RU","RUS","Moskovskaya Oblast’","","20823","1643003501"
"Vyškov","Vyskov","49.2775","16.9989","Czechia","CZ","CZE","Jihomoravský Kraj","","20676","1203012353"
"Toguchin","Toguchin","55.2333","84.4167","Russia","RU","RUS","Novosibirskaya Oblast’","","21064","1643095919"
"Zhangjiazhuang","Zhangjiazhuang","39.7804","118.2004","China","CN","CHN","Hebei","","20227","1156060807"
"Pindobaçu","Pindobacu","-10.7428","-40.3628","Brazil","BR","BRA","Bahia","","21062","1076228908"
"Pájara","Pajara","28.3500","-14.1000","Spain","ES","ESP","Canary Islands","","21014","1724377413"
"Fiadanana","Fiadanana","-20.4333","48.3833","Madagascar","MG","MDG","Fianarantsoa","","21000","1450442831"
"Alcudia","Alcudia","39.8525","3.1192","Spain","ES","ESP","Balearic Islands","","20717","1724681701"
"Saka","Saka","34.6167","-3.4167","Morocco","MA","MAR","Oriental","","21048","1504711196"
"Ţorqabeh","Torqabeh","36.3103","59.3736","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","20998","1364331013"
"Steinhagen","Steinhagen","52.0050","8.4147","Germany","DE","DEU","North Rhine-Westphalia","","20698","1276279674"
"El Retén","El Reten","10.6167","-74.2667","Colombia","CO","COL","Magdalena","minor","20981","1170874464"
"Sysert","Sysert","56.5000","60.8167","Russia","RU","RUS","Sverdlovskaya Oblast’","","21029","1643162244"
"Pathrajolhania","Pathrajolhania","26.1369","86.6614","India","IN","IND","Bihār","","19546","1356256032"
"Ambohipandrano","Ambohipandrano","-19.1500","47.3667","Madagascar","MG","MDG","Antananarivo","","21000","1450475583"
"Ambohimanambola","Ambohimanambola","-19.8000","46.6167","Madagascar","MG","MDG","Antananarivo","","21000","1450223551"
"Esperalvillo","Esperalvillo","18.8200","-70.0300","Dominican Republic","DO","DOM","Higuamo","","20900","1214132473"
"Ludlow","Ludlow","42.1921","-72.4580","United States","US","USA","Massachusetts","","21050","1840053449"
"Pālkonda","Palkonda","18.6000","83.7500","India","IN","IND","Andhra Pradesh","","20760","1356333362"
"Chợ Phước Hải","Cho Phuoc Hai","10.4283","107.2953","Vietnam","VN","VNM","Bà Rịa-Vũng Tàu","","20923","1704395205"
"Lindenwold","Lindenwold","39.8173","-74.9898","United States","US","USA","New Jersey","","21048","1840000730"
"Sidi Abdelkader","Sidi Abdelkader","33.0494","-8.6886","Morocco","MA","MAR","Casablanca-Settat","","20854","1504001212"
"Ālampālaiyam","Alampalaiyam","11.3635","77.7677","India","IN","IND","Tamil Nādu","","20136","1356166815"
"Candiac","Candiac","45.3800","-73.5200","Canada","CA","CAN","Quebec","","21047","1124457982"
"Andāl","Andal","23.6000","87.2000","India","IN","IND","West Bengal","","19924","1356681205"
"Sokal","Sokal","50.4833","24.2833","Ukraine","UA","UKR","Lvivska Oblast","","20986","1804008451"
"Sitampiky","Sitampiky","-16.6667","46.1000","Madagascar","MG","MDG","Mahajanga","","21000","1450688807"
"Bni Rzine","Bni Rzine","35.0108","-4.7262","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","20904","1504313733"
"Karianga","Karianga","-22.4167","47.3667","Madagascar","MG","MDG","Fianarantsoa","","21000","1450185068"
"Gallipoli","Gallipoli","40.0556","17.9917","Italy","IT","ITA","Puglia","","20545","1380324772"
"Ankililoaka","Ankililoaka","-22.7667","43.6167","Madagascar","MG","MDG","Toliara","","21000","1450496497"
"Ambondro","Ambondro","-25.2500","45.8333","Madagascar","MG","MDG","Toliara","","20796","1450087173"
"Tsiamalao","Tsiamalao","-15.0500","48.4500","Madagascar","MG","MDG","Mahajanga","","21000","1450394904"
"Abano Terme","Abano Terme","45.3603","11.7900","Italy","IT","ITA","Veneto","","20101","1380340471"
"Oro-Medonte","Oro-Medonte","44.5667","-79.5833","Canada","CA","CAN","Ontario","","21036","1124001350"
"Chelak","Chelak","39.9203","66.8611","Uzbekistan","UZ","UZB","Samarqand","","20800","1860308280"
"Žďár nad Sázavou","Zd'ar nad Sazavou","49.5627","15.9393","Czechia","CZ","CZE","Vysočina","","20485","1203204559"
"Banting","Banting","5.7167","120.9000","Philippines","PH","PHL","Sulu","","20799","1608676732"
"Clemmons","Clemmons","36.0319","-80.3861","United States","US","USA","North Carolina","","21032","1840017728"
"Liubotyn","Liubotyn","49.9489","35.9306","Ukraine","UA","UKR","Kharkivska Oblast","","20887","1804620993"
"Soanindrariny","Soanindrariny","-19.9000","47.2333","Madagascar","MG","MDG","Antananarivo","","21000","1450013819"
"Reichenbach/Vogtland","Reichenbach/Vogtland","50.6167","12.3000","Germany","DE","DEU","Saxony","","20436","1276903490"
"Luckenwalde","Luckenwalde","52.0833","13.1667","Germany","DE","DEU","Brandenburg","minor","20586","1276376363"
"Bugalagrande","Bugalagrande","4.2167","-76.1667","Colombia","CO","COL","Valle del Cauca","minor","20970","1170646923"
"Piedra Blanca","Piedra Blanca","18.8438","-70.3157","Dominican Republic","DO","DOM","Cibao Sur","","20934","1214843839"
"Pontal do Paraná","Pontal do Parana","-25.5768","-48.3581","Brazil","BR","BRA","Paraná","","20920","1076438304"
"Klaukkala","Klaukkala","60.3820","24.7492","Finland","FI","FIN","Uusimaa","","21019","1246435222"
"Ampasimatera","Ampasimatera","-15.9333","47.7500","Madagascar","MG","MDG","Mahajanga","","21000","1450446865"
"Morafeno","Morafeno","-15.4833","48.2000","Madagascar","MG","MDG","Mahajanga","","21000","1450032930"
"Zaliohouan","Zaliohouan","6.7833","-6.2333","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","","20867","1384464976"
"San José de Jáchal","San Jose de Jachal","-30.2333","-68.7500","Argentina","AR","ARG","San Juan","minor","21018","1032660627"
"Osmaneli","Osmaneli","40.3500","30.0167","Turkey","TR","TUR","Bilecik","minor","20976","1792257463"
"Barki Saria","Barki Saria","24.1759","85.8894","India","IN","IND","Jhārkhand","","20375","1356112723"
"Az Zuwaytīnah","Az Zuwaytinah","30.9522","20.1203","Libya","LY","LBY","Banghāzī","","21015","1434562634"
"Dudinka","Dudinka","69.4064","86.1764","Russia","RU","RUS","Krasnoyarskiy Kray","","21015","1643923236"
"Pulicat","Pulicat","13.4161","80.3168","India","IN","IND","Tamil Nādu","","20260","1356317294"
"Tongobory","Tongobory","-23.5167","44.3333","Madagascar","MG","MDG","Toliara","","21000","1450220493"
"Palma del Río","Palma del Rio","37.7000","-5.2833","Spain","ES","ESP","Andalusia","","20910","1724000025"
"El Coco","El Coco","8.8834","-79.8332","Panama","PA","PAN","Panamá Oeste","minor","19603","1591500996"
"Nurota","Nurota","40.5650","65.6850","Uzbekistan","UZ","UZB","Navoiy","","21001","1860266981"
"Hualqui","Hualqui","-36.9600","-72.9300","Chile","CL","CHL","Biobío","","20968","1152533945"
"Alto-Cuilo","Alto-Cuilo","-8.2371","19.5238","Angola","AO","AGO","Lunda-Sul","","21004","1024029875"
"Lambari","Lambari","-21.9758","-45.3500","Brazil","BR","BRA","Minas Gerais","","20907","1076960133"
"Castel San Pietro Terme","Castel San Pietro Terme","44.4000","11.5833","Italy","IT","ITA","Emilia-Romagna","","20862","1380181797"
"Roshal","Roshal","55.6667","39.8833","Russia","RU","RUS","Moskovskaya Oblast’","","20418","1643370665"
"Teghra English","Teghra English","25.4072","87.7410","India","IN","IND","Bihār","","19452","1356117731"
"Cacongo","Cacongo","-5.2333","12.1333","Angola","AO","AGO","Cabinda","","21000","1024356360"
"Anah","Anah","34.3722","41.9875","Iraq","IQ","IRQ","Al Anbār","minor","21000","1368909811"
"Xincheng","Xincheng","24.0514","121.6083","Taiwan","TW","TWN","Hualien","","20308","1158118718"
"Varennes","Varennes","45.6833","-73.4333","Canada","CA","CAN","Quebec","","20994","1124232101"
"Koskāpur","Koskapur","26.0216","87.2279","India","IN","IND","Bihār","","19930","1356731362"
"Merrick","Merrick","40.6515","-73.5535","United States","US","USA","New York","","20990","1840005238"
"Rivalta di Torino","Rivalta di Torino","45.0340","7.5184","Italy","IT","ITA","Piedmont","","20185","1380788921"
"Kyaukpyu","Kyaukpyu","19.4264","93.5458","Myanmar","MM","MMR","Rakhine State","","20866","1104321745"
"Arnaud","Arnaud","18.4500","-73.3833","Haiti","HT","HTI","Nippes","","20718","1332859176"
"Torhout","Torhout","51.0661","3.1014","Belgium","BE","BEL","Flanders","","20530","1056225493"
"Madre de Deus","Madre de Deus","-12.7408","-38.6208","Brazil","BR","BRA","Bahia","","20348","1076769060"
"Achampet","Achampet","16.6299","80.1213","India","IN","IND","Andhra Pradesh","","20721","1356144571"
"Enger","Enger","52.1333","8.5667","Germany","DE","DEU","North Rhine-Westphalia","","20483","1276241559"
"San Guillermo","San Guillermo","16.7244","121.8100","Philippines","PH","PHL","Isabela","","20915","1608485443"
"Bholsar","Bholsar","25.2133","87.2147","India","IN","IND","Bihār","","19327","1356304058"
"Mössingen","Mossingen","48.4064","9.0575","Germany","DE","DEU","Baden-Württemberg","","20567","1276236369"
"Ripley","Ripley","53.0500","-1.4070","United Kingdom","GB","GBR","Derbyshire","","20807","1826520155"
"Nördlingen","Nordlingen","48.8511","10.4883","Germany","DE","DEU","Bavaria","","20674","1276160287"
"Banganapalle","Banganapalle","15.3167","78.2331","India","IN","IND","Andhra Pradesh","","20749","1356257354"
"Nidderau","Nidderau","50.2500","8.9000","Germany","DE","DEU","Hesse","","20539","1276730067"
"Bussolengo","Bussolengo","45.4667","10.8500","Italy","IT","ITA","Veneto","","20142","1380760419"
"Mae Sai","Mae Sai","20.4333","99.8833","Thailand","TH","THA","Chiang Rai","minor","20699","1764624336"
"Rangra","Rangra","25.3803","87.1866","India","IN","IND","Bihār","","19203","1356370005"
"Debark’","Debark'","13.1333","37.9000","Ethiopia","ET","ETH","Āmara","","20839","1231894156"
"Pedra","Pedra","-8.5006","-36.9456","Brazil","BR","BRA","Pernambuco","","20944","1076436337"
"Guará","Guara","-20.4283","-47.8242","Brazil","BR","BRA","São Paulo","","20911","1076888362"
"Ås","As","59.6603","10.7836","Norway","NO","NOR","Viken","minor","20780","1578160935"
"Nizhniy Lomov","Nizhniy Lomov","53.5167","43.6667","Russia","RU","RUS","Penzenskaya Oblast’","","20943","1643956972"
"Kāmalāpuram","Kamalapuram","14.5983","78.6694","India","IN","IND","Andhra Pradesh","","20623","1356435309"
"Purranque","Purranque","-40.9167","-73.1667","Chile","CL","CHL","Los Lagos","","20949","1152468662"
"Punturin","Punturin","14.7381","120.9978","Philippines","PH","PHL","Valenzuela","","20930","1608903782"
"Paldorak","Paldorak","40.4833","69.3000","Tajikistan","TJ","TJK","Sughd","","20837","1762537276"
"Quela","Quela","-9.3833","17.0500","Angola","AO","AGO","Malanje","","20949","1024948229"
"Oderzo","Oderzo","45.7808","12.4928","Italy","IT","ITA","Veneto","","20466","1380552197"
"Sonkach","Sonkach","22.9717","76.3467","India","IN","IND","Madhya Pradesh","","20529","1356679131"
"Xo‘jaobod","Xo\`jaobod","40.6653","72.5667","Uzbekistan","UZ","UZB","Andijon","minor","20200","1860459703"
"Kuknūr","Kuknur","15.4900","75.9914","India","IN","IND","Karnātaka","","20747","1356472470"
"Bandar-e Gaz","Bandar-e Gaz","36.7742","53.9481","Iran","IR","IRN","Golestān","minor","20742","1364226344"
"Veresegyház","Veresegyhaz","47.6505","19.2830","Hungary","HU","HUN","Pest","","20236","1348362703"
"Oliveira do Hospital","Oliveira do Hospital","40.3589","-7.8625","Portugal","PT","PRT","Coimbra","minor","20855","1620792410"
"Moniquirá","Moniquira","5.9167","-73.5000","Colombia","CO","COL","Boyacá","minor","20848","1170206857"
"Harvey","Harvey","29.8876","-90.0666","United States","US","USA","Louisiana","","20942","1840013985"
"Bhainsdehi","Bhainsdehi","21.6449","77.6302","India","IN","IND","Madhya Pradesh","","20811","1356758250"
"Narwar","Narwar","25.6439","77.9129","India","IN","IND","Madhya Pradesh","","20742","1356644280"
"Dillingen","Dillingen","49.3500","6.7333","Germany","DE","DEU","Saarland","","20048","1276001038"
"Madukkūr","Madukkur","10.4800","79.4000","India","IN","IND","Tamil Nādu","","20362","1356068755"
"Upper St. Clair","Upper St. Clair","40.3336","-80.0842","United States","US","USA","Pennsylvania","","20938","1840005397"
"Baroda","Baroda","25.5000","76.6500","India","IN","IND","Madhya Pradesh","","20688","1356959440"
"Jericho","Jericho","31.8711","35.4442","West Bank","XW","XWB","","","20300","1934197330"
"Ararat","Ararat","39.8303","44.7025","Armenia","AM","ARM","Ararat","","20800","1051804328"
"Beldaur","Beldaur","25.5947","86.7909","India","IN","IND","Bihār","","19737","1356061516"
"’Aïn el Hammam","'Ain el Hammam","36.5714","4.3097","Algeria","DZ","DZA","Tizi Ouzou","","20401","1012595495"
"Sánchez","Sanchez","19.2282","-69.6128","Dominican Republic","DO","DOM","Cibao Nordeste","","20865","1214869405"
"Zestaponi","Zestaponi","42.1083","43.0417","Georgia","GE","GEO","Imereti","minor","20814","1268512465"
"Herborn","Herborn","50.6825","8.3061","Germany","DE","DEU","Hesse","","20603","1276002994"
"Vico Equense","Vico Equense","40.6667","14.4333","Italy","IT","ITA","Campania","","20919","1380413196"
"São Vicente Ferrer","Sao Vicente Ferrer","-2.8939","-44.8800","Brazil","BR","BRA","Maranhão","","20863","1076834299"
"Kurovskoye","Kurovskoye","55.5833","38.9167","Russia","RU","RUS","Moskovskaya Oblast’","","20844","1643003596"
"Cercado Abajo","Cercado Abajo","18.7300","-71.5200","Dominican Republic","DO","DOM","El Valle","","20843","1214618384"
"Osa","Osa","57.3000","55.4667","Russia","RU","RUS","Permskiy Kray","","20899","1643282459"
"Rio Rico","Rio Rico","31.4957","-110.9885","United States","US","USA","Arizona","","20914","1840027004"
"Kamamaung","Kamamaung","17.3469","97.6528","Myanmar","MM","MMR","Kayin State","","20895","1104892359"
"Ubaitaba","Ubaitaba","-14.3128","-39.3228","Brazil","BR","BRA","Bahia","","20813","1076532739"
"Guácima","Guacima","9.9613","-84.2588","Costa Rica","CR","CRI","Alajuela","","20183","1188224134"
"Wuustwezel","Wuustwezel","51.3833","4.6000","Belgium","BE","BEL","Flanders","","20671","1056525110"
"Gerlingen","Gerlingen","48.8000","9.0653","Germany","DE","DEU","Baden-Württemberg","","19745","1276144443"
"Morauna","Morauna","26.1706","86.4231","India","IN","IND","Bihār","","19121","1356515710"
"Chennimalai","Chennimalai","11.1638","77.6039","India","IN","IND","Tamil Nādu","","20522","1356565627"
"South Whitehall","South Whitehall","40.6154","-75.5503","United States","US","USA","Pennsylvania","","20898","1840148586"
"Wildeshausen","Wildeshausen","52.9000","8.4333","Germany","DE","DEU","Lower Saxony","","20667","1276599018"
"Vinany","Vinany","-19.6167","46.4833","Madagascar","MG","MDG","Antananarivo","","20844","1450526902"
"Samacá","Samaca","5.5000","-73.4833","Colombia","CO","COL","Boyacá","minor","20767","1170064917"
"Kremenets","Kremenets","50.1081","25.7275","Ukraine","UA","UKR","Ternopilska Oblast","minor","20837","1804050857"
"Pallazzolo sull’Oglio","Pallazzolo sull'Oglio","45.6000","9.8833","Italy","IT","ITA","Lombardy","","20026","1380736878"
"Yangping","Yangping","27.7607","100.6614","China","CN","CHN","Yunnan","","20852","1156821978"
"Ovejas","Ovejas","9.5000","-75.1667","Colombia","CO","COL","Sucre","minor","20846","1170231895"
"Pico Truncado","Pico Truncado","-46.7950","-67.9550","Argentina","AR","ARG","Santa Cruz","","20889","1032509324"
"Schortens","Schortens","53.5333","7.9500","Germany","DE","DEU","Lower Saxony","","20590","1276002315"
"Sotomayor","Sotomayor","1.4933","-77.5214","Colombia","CO","COL","Nariño","minor","20865","1170028050"
"Bālugān","Balugan","19.7333","85.2167","India","IN","IND","Odisha","","20886","1356605710"
"East Pennsboro","East Pennsboro","40.2886","-76.9394","United States","US","USA","Pennsylvania","","20886","1840143336"
"Buinsk","Buinsk","54.9667","48.2833","Russia","RU","RUS","Tatarstan","","20854","1643218865"
"Gamboma","Gamboma","-1.8764","15.8644","Congo (Brazzaville)","CG","COG","Plateaux","","20877","1178991023"
"Wade Hampton","Wade Hampton","34.8821","-82.3336","United States","US","USA","South Carolina","","20879","1840035800"
"Coquimatlán","Coquimatlan","19.2038","-103.8086","Mexico","MX","MEX","Colima","minor","20837","1484193381"
"Lockport","Lockport","43.1698","-78.6956","United States","US","USA","New York","","20876","1840000370"
"Rājgarh","Rajgarh","22.6800","74.9500","India","IN","IND","Madhya Pradesh","","20614","1356869217"
"Vadamadurai","Vadamadurai","10.4408","78.0978","India","IN","IND","Tamil Nādu","","20524","1356094891"
"Sultanpur","Sultanpur","31.2235","75.2041","India","IN","IND","Punjab","","20553","1356067050"
"Ozark","Ozark","37.0365","-93.2158","United States","US","USA","Missouri","","20874","1840009926"
"Hays","Hays","38.8821","-99.3221","United States","US","USA","Kansas","","20870","1840001628"
"Honnāli","Honnali","14.2399","75.6474","India","IN","IND","Karnātaka","","20549","1356028341"
"Pertuis","Pertuis","43.6950","5.5036","France","FR","FRA","Provence-Alpes-Côte d’Azur","","20557","1250296528"
"Strathroy-Caradoc","Strathroy-Caradoc","42.9575","-81.6167","Canada","CA","CAN","Ontario","","20867","1124000831"
"Horten","Horten","59.4208","10.4328","Norway","NO","NOR","Vestfold og Telemark","","20504","1578000515"
"Bourg-lès-Valence","Bourg-les-Valence","44.9475","4.8953","France","FR","FRA","Auvergne-Rhône-Alpes","","19889","1250580691"
"Makakilo","Makakilo","21.3591","-158.0813","United States","US","USA","Hawaii","","20862","1840137080"
"Kolāras","Kolaras","25.2193","77.6117","India","IN","IND","Madhya Pradesh","","20700","1356103434"
"Derinkuyu","Derinkuyu","38.3736","34.7347","Turkey","TR","TUR","Nevşehir","minor","20786","1792177319"
"Miches","Miches","18.9800","-69.0500","Dominican Republic","DO","DOM","Yuma","","20813","1214377408"
"Māvalli","Mavalli","14.1000","74.4833","India","IN","IND","Karnātaka","","20339","1356123179"
"Olenegorsk","Olenegorsk","68.1500","33.2833","Russia","RU","RUS","Murmanskaya Oblast’","","20847","1643381812"
"Valkeakoski","Valkeakoski","61.2667","24.0333","Finland","FI","FIN","Pirkanmaa","minor","20800","1246028934"
"Kiseljak","Kiseljak","43.9431","18.0775","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","20722","1070130357"
"Skanderborg","Skanderborg","56.0417","9.9250","Denmark","DK","DNK","Midtjylland","minor","19963","1208582614"
"Hexiang","Hexiang","19.5285","109.6354","China","CN","CHN","Hainan","","20729","1156312038"
"Sêrro","Serro","-18.6050","-43.3789","Brazil","BR","BRA","Minas Gerais","","20835","1076331667"
"Mahināwān","Mahinawan","26.0208","86.1672","India","IN","IND","Bihār","","19508","1356496348"
"Boussu","Boussu","50.4331","3.7961","Belgium","BE","BEL","Wallonia","","19856","1056992402"
"Avon","Avon","39.7601","-86.3916","United States","US","USA","Indiana","","20848","1840009535"
"Carcagente","Carcagente","39.1222","-0.4489","Spain","ES","ESP","Valencia","","20498","1724931242"
"Letlhakane","Letlhakane","-21.4167","25.5833","Botswana","BW","BWA","Central","","20841","1072953964"
"Blieskastel","Blieskastel","49.2333","7.2500","Germany","DE","DEU","Saarland","","20656","1276041906"
"Ellamanda","Ellamanda","16.1847","80.0546","India","IN","IND","Andhra Pradesh","","19817","1356131239"
"Matheu","Matheu","-34.3667","-58.8333","Argentina","AR","ARG","Buenos Aires","","19964","1032459344"
"Junqueirópolis","Junqueiropolis","-21.5147","-51.4336","Brazil","BR","BRA","São Paulo","","20831","1076673647"
"Carquefou","Carquefou","47.2969","-1.4928","France","FR","FRA","Pays de la Loire","","20365","1250000265"
"Haßloch","Hassloch","49.3500","8.2500","Germany","DE","DEU","Rhineland-Palatinate","","20326","1276280419"
"Capinzal","Capinzal","-27.3439","-51.6119","Brazil","BR","BRA","Santa Catarina","","20769","1076789641"
"Cugnaux","Cugnaux","43.5378","1.3436","France","FR","FRA","Occitanie","","19344","1250327764"
"Humanes de Madrid","Humanes de Madrid","40.2539","-3.8278","Spain","ES","ESP","Madrid","","19838","1724764038"
"Grayslake","Grayslake","42.3405","-88.0338","United States","US","USA","Illinois","","20827","1840011150"
"Jicomé","Jicome","19.6500","-70.9500","Dominican Republic","DO","DOM","Cibao Noroeste","","20777","1214269436"
"Karmana","Karmana","40.1422","65.3625","Uzbekistan","UZ","UZB","Navoiy","","20816","1860118561"
"Wachtberg","Wachtberg","50.6242","7.1322","Germany","DE","DEU","North Rhine-Westphalia","","20414","1276189631"
"Samboan","Samboan","9.5288","123.3064","Philippines","PH","PHL","Cebu","","20373","1608765850"
"Hanmayingcun","Hanmayingcun","41.2636","117.8596","China","CN","CHN","Hebei","","20713","1156451257"
"Willmar","Willmar","45.1216","-95.0569","United States","US","USA","Minnesota","","20823","1840009995"
"Aomar","Aomar","36.5000","3.7833","Algeria","DZ","DZA","Bouira","","20532","1012376964"
"Fālākāta","Falakata","26.5300","89.2000","India","IN","IND","West Bengal","","19716","1356059125"
"Ceres","Ceres","-15.3078","-49.5978","Brazil","BR","BRA","Goiás","","20722","1076087042"
"Archena","Archena","38.1150","-1.2992","Spain","ES","ESP","Murcia","","19622","1724744981"
"Husainpur","Husainpur","31.3387","75.1969","India","IN","IND","Punjab","","20244","1356735077"
"Feltre","Feltre","46.0186","11.9100","Italy","IT","ITA","Veneto","","20608","1380882091"
"Pacatu","Pacatu","-11.9578","-38.9750","Brazil","BR","BRA","Bahia","","20754","1076255229"
"Tamandaré","Tamandare","-8.7600","-35.1050","Brazil","BR","BRA","Pernambuco","","20715","1076348637"
"Dharmkot","Dharmkot","30.9456","75.2324","India","IN","IND","Punjab","","20326","1356137820"
"Ubaíra","Ubaira","-13.2678","-39.6628","Brazil","BR","BRA","Bahia","","20782","1076435268"
"Blaj","Blaj","46.1753","23.9144","Romania","RO","ROU","Alba","","20630","1642308950"
"Murrysville","Murrysville","40.4456","-79.6554","United States","US","USA","Pennsylvania","","20810","1840001148"
"Arnold","Arnold","38.4297","-90.3733","United States","US","USA","Missouri","","20808","1840007506"
"Giovinazzo","Giovinazzo","41.1833","16.6667","Italy","IT","ITA","Puglia","","20348","1380694202"
"Pita","Pita","11.0800","-12.4010","Guinea","GN","GIN","Mamou","minor","20052","1324788694"
"Fort Walton Beach","Fort Walton Beach","30.4255","-86.6222","United States","US","USA","Florida","","20805","1840013923"
"Kōtekāra","Kotekara","12.7929","74.8722","India","IN","IND","Karnātaka","","18907","1356152754"
"Lebedinovka","Lebedinovka","42.8800","74.6800","Kyrgyzstan","KG","KGZ","Chüy","minor","20709","1417855889"
"Suroth","Suroth","26.8098","77.1470","India","IN","IND","Rājasthān","","20000","1356012920"
"Zülpich","Zulpich","50.7000","6.6500","Germany","DE","DEU","North Rhine-Westphalia","","20597","1276675318"
"Bladel","Bladel","51.3667","5.2167","Netherlands","NL","NLD","Noord-Brabant","minor","20529","1528208371"
"Kortenberg","Kortenberg","50.8833","4.5333","Belgium","BE","BEL","Flanders","","20214","1056214918"
"Tielt","Tielt","50.9989","3.3258","Belgium","BE","BEL","Flanders","minor","20500","1056782112"
"Buckingham","Buckingham","40.3188","-75.0580","United States","US","USA","Pennsylvania","","20795","1840034882"
"Kihihi","Kihihi","-0.7489","29.7003","Uganda","UG","UGA","Kanungu","","20349","1800948354"
"Ino","Ino","33.5487","133.4278","Japan","JP","JPN","Kōchi","","20750","1392013071"
"Sivaslı","Sivasli","38.4833","29.6833","Turkey","TR","TUR","Uşak","minor","20753","1792806037"
"Polonne","Polonne","50.1167","27.5167","Ukraine","UA","UKR","Khmelnytska Oblast","","20785","1804361206"
"Kawayan","Kawayan","11.7000","124.3667","Philippines","PH","PHL","Biliran","","20455","1608294088"
"Vinjamūr","Vinjamur","14.8330","79.5830","India","IN","IND","Andhra Pradesh","","20639","1356585127"
"Monte Alegre","Monte Alegre","-6.0678","-35.3319","Brazil","BR","BRA","Rio Grande do Norte","","20685","1076839231"
"Nadikūde","Nadikude","16.5941","79.7167","India","IN","IND","Andhra Pradesh","","20466","1356330016"
"Dumbarton","Dumbarton","55.9500","-4.5667","United Kingdom","GB","GBR","West Dunbartonshire","","20560","1826077098"
"Pontassieve","Pontassieve","43.7750","11.4375","Italy","IT","ITA","Tuscany","","20607","1380388107"
"South Milwaukee","South Milwaukee","42.9120","-87.8627","United States","US","USA","Wisconsin","","20785","1840003032"
"Scobinţi","Scobinti","47.3833","26.9333","Romania","RO","ROU","Iaşi","","20700","1642479776"
"Lastra a Signa","Lastra a Signa","43.7667","11.1000","Italy","IT","ITA","Tuscany","","20308","1380712822"
"Germantown","Germantown","43.2343","-88.1217","United States","US","USA","Wisconsin","","20780","1840002830"
"Sint-Andries","Sint-Andries","51.2000","3.1833","Belgium","BE","BEL","Flanders","","19818","1056152263"
"Forst (Lausitz)","Forst (Lausitz)","51.7333","14.6333","Germany","DE","DEU","Brandenburg","minor","20618","1276307453"
"Dugulubgey","Dugulubgey","43.6500","43.5500","Russia","RU","RUS","Kabardino-Balkariya","","20718","1643487395"
"Medina","Medina","-16.2228","-41.4769","Brazil","BR","BRA","Minas Gerais","","20759","1076322819"
"Mattigiri","Mattigiri","12.6980","77.8083","India","IN","IND","Tamil Nādu","","20139","1356260234"
"Lebanon","Lebanon","39.4254","-84.2133","United States","US","USA","Ohio","","20770","1840001546"
"Ghafurov","Ghafurov","40.2200","69.7300","Tajikistan","TJ","TJK","Sughd","minor","20600","1762485684"
"Sidhapa","Sidhapa","26.5355","86.3355","India","IN","IND","Bihār","","20075","1356112939"
"Tala Yfassene","Tala Yfassene","36.4583","5.0889","Algeria","DZ","DZA","Sétif","","20337","1012098651"
"Bruges","Bruges","44.8828","-0.6125","France","FR","FRA","Nouvelle-Aquitaine","","19403","1250426923"
"Arroyo Seco","Arroyo Seco","-33.1667","-60.5000","Argentina","AR","ARG","Santa Fe","","20620","1032882258"
"Gyömrő","Gyomro","47.4250","19.3977","Hungary","HU","HUN","Pest","","20010","1348769818"
"Parma Heights","Parma Heights","41.3865","-81.7637","United States","US","USA","Ohio","","20764","1840003407"
"Illapel","Illapel","-31.6333","-71.1667","Chile","CL","CHL","Coquimbo","minor","20751","1152446656"
"Somanya","Somanya","6.1039","-0.0150","Ghana","GH","GHA","Eastern","","20596","1288817361"
"Mougins","Mougins","43.6000","6.9947","France","FR","FRA","Provence-Alpes-Côte d’Azur","","19982","1250320161"
"Banaue","Banaue","16.9119","121.0614","Philippines","PH","PHL","Ifugao","","20652","1608712825"
"Luacano","Luacano","-11.2167","21.6500","Angola","AO","AGO","Moxico","","20755","1024333482"
"Nova Granada","Nova Granada","-20.5339","-49.3139","Brazil","BR","BRA","São Paulo","","20717","1076239207"
"Sivrihisar","Sivrihisar","39.4500","31.5378","Turkey","TR","TUR","Eskişehir","minor","20746","1792393048"
"Chachahuantla","Chachahuantla","20.2756","-98.1503","Mexico","MX","MEX","Hidalgo","","20619","1484829941"
"Harvey","Harvey","41.6076","-87.6520","United States","US","USA","Illinois","","20751","1840007029"
"Morrinhos","Morrinhos","-3.2289","-40.1250","Brazil","BR","BRA","Ceará","","20700","1076354699"
"Dolyna","Dolyna","48.9703","24.0108","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","20691","1804692275"
"Na Klang","Na Klang","17.3043","102.1964","Thailand","TH","THA","Nong Bua Lamphu","minor","20609","1764000026"
"Sultonobod","Sultonobod","40.7644","72.9764","Uzbekistan","UZ","UZB","Andijon","","20000","1860591797"
"Blansko","Blansko","49.3631","16.6431","Czechia","CZ","CZE","Jihomoravský Kraj","","20306","1203195651"
"Fonte Boa","Fonte Boa","-2.5139","-66.0917","Brazil","BR","BRA","Amazonas","","20742","1076851330"
"Bom Jesus","Bom Jesus","-18.2150","-49.7419","Brazil","BR","BRA","Goiás","","20727","1076683582"
"Elvas","Elvas","38.8667","-7.1500","Portugal","PT","PRT","Portalegre","minor","20706","1620193707"
"Pukhrāyān","Pukhrayan","26.2300","79.8500","India","IN","IND","Uttar Pradesh","","19908","1356085361"
"Senica","Senica","48.6806","17.3667","Slovakia","SK","SVK","Trnava","minor","20342","1703713928"
"Melena del Sur","Melena del Sur","22.7881","-82.1512","Cuba","CU","CUB","Mayabeque","minor","20646","1192175478"
"Zhangatas","Zhangatas","43.5705","69.7335","Kazakhstan","KZ","KAZ","","","20731","1398375093"
"Moḩammadābād","Mohammadabad","28.6689","59.0733","Iran","IR","IRN","Kermān","minor","20720","1364372718"
"Hernani","Hernani","43.2667","-1.9667","Spain","ES","ESP","Basque Country","","20375","1724313794"
"Iriona","Iriona","15.9694","-85.0933","Honduras","HN","HND","Colón","","20730","1340981910"
"Green Valley","Green Valley","31.8393","-111.0009","United States","US","USA","Arizona","","20729","1840018111"
"Cartaya","Cartaya","37.2833","-7.1500","Spain","ES","ESP","Andalusia","","20717","1724750362"
"Mäntsälä","Mantsala","60.6331","25.3167","Finland","FI","FIN","Uusimaa","minor","20685","1246340286"
"Pueblo Nuevo","Pueblo Nuevo","13.3797","-86.4808","Nicaragua","NI","NIC","Estelí","minor","20620","1558373095"
"Campestre","Campestre","-21.7108","-46.2458","Brazil","BR","BRA","Minas Gerais","","20686","1076284455"
"Quaregnon","Quaregnon","50.4333","3.8667","Belgium","BE","BEL","Wallonia","","19006","1056660273"
"Sondiha","Sondiha","24.8950","87.0175","India","IN","IND","Bihār","","19455","1356791276"
"Xionglin","Xionglin","24.7742","121.0964","Taiwan","TW","TWN","Hsinchu","","20226","1158057150"
"Foya Tangia","Foya Tangia","8.2713","-10.2283","Liberia","LR","LBR","Lofa","","20569","1430908591"
"Sugaon","Sugaon","26.7317","84.7629","India","IN","IND","Bihār","","18883","1356081704"
"Monte Azul","Monte Azul","-15.1553","-42.8589","Brazil","BR","BRA","Minas Gerais","","20696","1076346009"
"Okha","Okha","53.5889","142.9492","Russia","RU","RUS","Sakhalinskaya Oblast’","","20715","1643780649"
"Stadtlohn","Stadtlohn","51.9925","6.9150","Germany","DE","DEU","North Rhine-Westphalia","","20458","1276897939"
"Senapparetti","Senapparetti","10.9625","78.1132","India","IN","IND","Tamil Nādu","","19909","1356227752"
"Kātpādi","Katpadi","12.9695","79.1455","India","IN","IND","Tamil Nādu","","19683","1356697724"
"Kekem","Kekem","5.1667","10.0333","Cameroon","CM","CMR","Ouest","","20579","1120134625"
"Yakkabog‘","Yakkabog\`","38.9800","66.6800","Uzbekistan","UZ","UZB","Qashqadaryo","","20600","1860608586"
"Meinerzhagen","Meinerzhagen","51.1167","7.6333","Germany","DE","DEU","North Rhine-Westphalia","","20535","1276363054"
"Lagoa do Itaenga","Lagoa do Itaenga","-7.9358","-35.2900","Brazil","BR","BRA","Pernambuco","","20659","1076975552"
"Sapang Dalaga","Sapang Dalaga","8.5500","123.5667","Philippines","PH","PHL","Misamis Occidental","","20490","1608409297"
"Ménaka","Menaka","15.9167","2.4000","Mali","ML","MLI","Gao","minor","20702","1466373205"
"Lincolnia","Lincolnia","38.8158","-77.1543","United States","US","USA","Virginia","","20704","1840006034"
"North Ogden","North Ogden","41.3123","-111.9584","United States","US","USA","Utah","","20703","1840020134"
"Pozantı","Pozanti","37.4278","34.8711","Turkey","TR","TUR","Adana","minor","20683","1792284053"
"Sabanitas","Sabanitas","9.3400","-79.8000","Panama","PA","PAN","Colón","minor","19052","1591407052"
"Nootdorp","Nootdorp","52.0333","4.4000","Netherlands","NL","NLD","Zuid-Holland","","19000","1528544415"
"Mead Valley","Mead Valley","33.8333","-117.2852","United States","US","USA","California","","20698","1840028331"
"Caoayan","Caoayan","17.5469","120.3831","Philippines","PH","PHL","Ilocos Sur","","19574","1608000679"
"Sidi Chiker","Sidi Chiker","31.7453","-8.7069","Morocco","MA","MAR","Marrakech-Safi","","20658","1504682316"
"San Julián","San Julian","-16.9064","-62.6169","Bolivia","BO","BOL","Santa Cruz","","20687","1068794197"
"Hörstel","Horstel","52.2972","7.5861","Germany","DE","DEU","North Rhine-Westphalia","","20506","1276546954"
"Pastores","Pastores","14.5967","-90.7547","Guatemala","GT","GTM","Sacatepéquez","minor","19661","1320454567"
"Shimogamo","Shimogamo","34.6795","138.9453","Japan","JP","JPN","Shizuoka","","20499","1392351063"
"Cittadella","Cittadella","45.6500","11.7833","Italy","IT","ITA","Veneto","","20145","1380660724"
"Little Egg Harbor","Little Egg Harbor","39.5969","-74.3454","United States","US","USA","New Jersey","","20693","1840081598"
"Miracema do Tocantins","Miracema do Tocantins","-9.5669","-48.3919","Brazil","BR","BRA","Tocantins","","20684","1076346229"
"Cirencester","Cirencester","51.7190","-1.9680","United Kingdom","GB","GBR","Gloucestershire","","19076","1826367738"
"Gaeta","Gaeta","41.2167","13.5667","Italy","IT","ITA","Lazio","","20545","1380812819"
"Pendembu","Pendembu","8.1000","-10.7000","Sierra Leone","SL","SLE","Eastern","","20502","1694288658"
"Botevgrad","Botevgrad","42.9073","23.7937","Bulgaria","BG","BGR","Sofia","","20624","1100788799"
"Coolbaugh","Coolbaugh","41.1837","-75.4386","United States","US","USA","Pennsylvania","","20686","1840145213"
"Hailsham","Hailsham","50.8647","0.2577","United Kingdom","GB","GBR","East Sussex","","20476","1826151855"
"Senden","Senden","51.8572","7.4828","Germany","DE","DEU","North Rhine-Westphalia","","20495","1276271183"
"Rocky Hill","Rocky Hill","41.6572","-72.6632","United States","US","USA","Connecticut","","20682","1840035560"
"Profesor Salvador Mazza","Profesor Salvador Mazza","-22.0500","-63.7000","Argentina","AR","ARG","Salta","","20670","1032887089"
"Arhavi","Arhavi","41.3333","41.3000","Turkey","TR","TUR","Artvin","minor","20565","1792000779"
"Helena","Helena","33.2837","-86.8791","United States","US","USA","Alabama","","20680","1840014827"
"Sabaudia","Sabaudia","41.2998","13.0248","Italy","IT","ITA","Lazio","","20536","1380097890"
"São João de Pirabas","Sao Joao de Pirabas","-0.7689","-47.1739","Brazil","BR","BRA","Pará","","20647","1076536958"
"Duderstadt","Duderstadt","51.5125","10.2597","Germany","DE","DEU","Lower Saxony","","20466","1276392919"
"Wasaga Beach","Wasaga Beach","44.5206","-80.0167","Canada","CA","CAN","Ontario","","20675","1124001919"
"Ware","Ware","51.8109","-0.0314","United Kingdom","GB","GBR","Hertfordshire","","18799","1826891949"
"Adrian","Adrian","41.8994","-84.0447","United States","US","USA","Michigan","","20674","1840003226"
"Rhenen","Rhenen","51.9667","5.5667","Netherlands","NL","NLD","Utrecht","minor","20203","1528573204"
"Lannion","Lannion","48.7325","-3.4553","France","FR","FRA","Bretagne","minor","20210","1250528188"
"Nové Mesto nad Váhom","Nove Mesto nad Vahom","48.7547","17.8308","Slovakia","SK","SVK","Nitra","minor","20066","1703781613"
"Alagoa Nova","Alagoa Nova","-7.0473","-35.7540","Brazil","BR","BRA","Paraíba","","20500","1076318882"
"Pittsburg","Pittsburg","37.4129","-94.6985","United States","US","USA","Kansas","","20667","1840001697"
"Neufahrn bei Freising","Neufahrn bei Freising","48.3159","11.6632","Germany","DE","DEU","Bavaria","","20223","1276307498"
"Mill Creek","Mill Creek","47.8631","-122.2037","United States","US","USA","Washington","","20665","1840019790"
"Chapulhuacán","Chapulhuacan","21.1547","-98.9039","Mexico","MX","MEX","Hidalgo","","20577","1484747290"
"Bagou","Bagou","10.8147","2.7164","Benin","BJ","BEN","Alibori","","20631","1204993446"
"Encantado","Encantado","-29.2358","-51.8700","Brazil","BR","BRA","Rio Grande do Sul","","20510","1076221232"
"Batī","Bati","11.1833","40.0167","Ethiopia","ET","ETH","Āmara","","20536","1231243570"
"Murphy","Murphy","33.0186","-96.6105","United States","US","USA","Texas","","20655","1840020659"
"Harpālpur","Harpalpur","25.2877","79.3328","India","IN","IND","Madhya Pradesh","","20341","1356366238"
"Gandujie","Gandujie","35.8908","102.3272","China","CN","CHN","Qinghai","","20536","1156419174"
"Dingolfing","Dingolfing","48.6333","12.5000","Germany","DE","DEU","Bavaria","minor","20191","1276764455"
"Ban Tha Pha","Ban Tha Pha","13.8437","99.8681","Thailand","TH","THA","Ratchaburi","","20211","1764900404"
"Bulnes","Bulnes","-36.7333","-72.3000","Chile","CL","CHL","Ñuble","","20595","1152653950"
"Marānchi","Maranchi","25.3544","85.9919","India","IN","IND","Bihār","","18767","1356126281"
"Baião","Baiao","41.1667","-8.0333","Portugal","PT","PRT","Porto","minor","20522","1620277576"
"Miandrivazo","Miandrivazo","-19.5239","45.4573","Madagascar","MG","MDG","Toliara","","20631","1450332104"
"Jefferson","Jefferson","41.0003","-74.5531","United States","US","USA","New Jersey","","20638","1840081740"
"South St. Paul","South St. Paul","44.8877","-93.0411","United States","US","USA","Minnesota","","20637","1840008948"
"Senec","Senec","48.2189","17.3997","Slovakia","SK","SVK","Bratislava","minor","20116","1703144516"
"Sārangpur","Sarangpur","25.7389","85.6951","India","IN","IND","Bihār","","18993","1356998982"
"Kapay","Kapay","8.0833","124.4000","Philippines","PH","PHL","Lanao del Sur","","20581","1608601607"
"Lubalo","Lubalo","-9.1500","19.2833","Angola","AO","AGO","Lunda-Norte","","20631","1024568664"
"Bilgi","Bilgi","16.3472","75.6181","India","IN","IND","Karnātaka","","20399","1356393849"
"Karlapālem","Karlapalem","15.9333","80.5500","India","IN","IND","Andhra Pradesh","","19874","1356463901"
"Mường Lay","Muong Lay","22.0678","103.1506","Vietnam","VN","VNM","Điện Biên","","20450","1704853058"
"Suwanee","Suwanee","34.0508","-84.0686","United States","US","USA","Georgia","","20629","1840015616"
"Beroun","Beroun","49.9642","14.0739","Czechia","CZ","CZE","Středočeský Kraj","","19988","1203585066"
"Bobrov","Bobrov","51.1000","40.0333","Russia","RU","RUS","Voronezhskaya Oblast’","","20605","1643014225"
"Coronado","Coronado","32.6649","-117.1604","United States","US","USA","California","","20627","1840019354"
"Pohādi","Pohadi","26.0809","86.2831","India","IN","IND","Bihār","","19311","1356647423"
"Mazinde","Mazinde","-4.8073","38.2118","Tanzania","TZ","TZA","Tanga","","20534","1834830165"
"Newmarket","Newmarket","52.2459","0.4105","United Kingdom","GB","GBR","Suffolk","","20384","1826537250"
"Floreşti","Floresti","46.7475","23.4908","Romania","RO","ROU","Cluj","","20256","1642926208"
"Itanhém","Itanhem","-17.1658","-40.3300","Brazil","BR","BRA","Bahia","","20611","1076490669"
"Nalua","Nalua","22.1051","88.4619","India","IN","IND","West Bengal","","19071","1356015232"
"Āmta","Amta","22.5834","88.0104","India","IN","IND","West Bengal","","19510","1356710048"
"Korntal-Münchingen","Korntal-Munchingen","48.8306","9.1214","Germany","DE","DEU","Baden-Württemberg","","19679","1276087969"
"Sultanhisar","Sultanhisar","37.8897","28.1575","Turkey","TR","TUR","Aydın","minor","20533","1792625507"
"Hiddenhausen","Hiddenhausen","52.1667","8.6167","Germany","DE","DEU","North Rhine-Westphalia","","19790","1276748088"
"Gil","Gil","30.8469","75.8636","India","IN","IND","Punjab","","19881","1356139239"
"Acquaviva delle Fonti","Acquaviva delle Fonti","40.9000","16.8500","Italy","IT","ITA","Puglia","","20600","1380485261"
"Mar’’ina Horka","Mar''ina Horka","53.5167","28.1417","Belarus","BY","BLR","Minskaya Voblasts’","","20592","1112958819"
"Pak Phanang","Pak Phanang","8.3538","100.2023","Thailand","TH","THA","Nakhon Si Thammarat","minor","20425","1764714642"
"Mbala","Mbala","-8.8333","31.3833","Zambia","ZM","ZMB","Northern","","20570","1894671312"
"Libertyville","Libertyville","42.2870","-87.9670","United States","US","USA","Illinois","","20616","1840011161"
"Nishigō","Nishigo","37.1417","140.1554","Japan","JP","JPN","Fukushima","","20508","1392003125"
"Spennymoor","Spennymoor","54.7000","-1.5900","United Kingdom","GB","GBR","Durham","","19816","1826426455"
"Albertville","Albertville","45.6758","6.3925","France","FR","FRA","Auvergne-Rhône-Alpes","minor","19502","1250427117"
"La Cañada Flintridge","La Canada Flintridge","34.2097","-118.2002","United States","US","USA","California","","20613","1840020510"
"Kawambwa","Kawambwa","-9.7914","29.0789","Zambia","ZM","ZMB","Luapula","","20589","1894964184"
"Mealhada","Mealhada","40.3833","-8.4500","Portugal","PT","PRT","Aveiro","minor","20428","1620589603"
"Bhasāwar","Bhasawar","27.0361","77.0528","India","IN","IND","Rājasthān","","19946","1356595489"
"Coronel Vivida","Coronel Vivida","-25.9800","-52.5678","Brazil","BR","BRA","Paraná","","20580","1076806010"
"New Glasgow","New Glasgow","45.5926","-62.6455","Canada","CA","CAN","Nova Scotia","","20609","1124760188"
"Covington","Covington","47.3667","-122.1045","United States","US","USA","Washington","","20608","1840018425"
"Cordeiro","Cordeiro","-22.0289","-42.3608","Brazil","BR","BRA","Rio de Janeiro","","20430","1076177058"
"Gorāya","Goraya","31.1241","75.7724","India","IN","IND","Punjab","","20008","1356794200"
"San Bernardino","San Bernardino","-25.3106","-57.2961","Paraguay","PY","PRY","Cordillera","","20491","1600530109"
"Salvaterra","Salvaterra","-0.7528","-48.5169","Brazil","BR","BRA","Pará","","20183","1076162949"
"Loja","Loja","37.1667","-4.1500","Spain","ES","ESP","Andalusia","","20555","1724466102"
"Makhtal","Makhtal","16.5012","77.5156","India","IN","IND","Andhra Pradesh","","20339","1356006714"
"Douar Lamrabih","Douar Lamrabih","34.8167","-5.8167","Morocco","MA","MAR","Rabat-Salé-Kénitra","","20461","1504888923"
"Sidi Jaber","Sidi Jaber","32.3833","-6.4167","Morocco","MA","MAR","Béni Mellal-Khénifra","","20432","1504230672"
"Tullahoma","Tullahoma","35.3721","-86.2172","United States","US","USA","Tennessee","","20599","1840015410"
"Magsaysay","Magsaysay","8.0333","123.9167","Philippines","PH","PHL","Lanao del Norte","","20463","1608966155"
"Station des Essais M.V.A.","Station des Essais M.V.A.","34.9352","-2.4306","Morocco","MA","MAR","Oriental","","20513","1504298109"
"Sunchales","Sunchales","-30.9333","-61.5667","Argentina","AR","ARG","Santa Fe","","20537","1032630690"
"Ely","Ely","52.3981","0.2622","United Kingdom","GB","GBR","Cambridgeshire","","20256","1826009346"
"Aldan","Aldan","58.6167","125.4167","Russia","RU","RUS","Sakha (Yakutiya)","","20595","1643237106"
"Hashtrūd","Hashtrud","37.4714","47.0583","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","20572","1364790184"
"Ryūyō","Ryuyo","34.6783","137.8167","Japan","JP","JPN","Shizuoka","","19756","1392003096"
"Springfield","Springfield","40.0986","-75.2016","United States","US","USA","Pennsylvania","","20590","1840152388"
"Gjøvik","Gjovik","60.7957","10.6915","Norway","NO","NOR","Innlandet","minor","20589","1578892271"
"Casarano","Casarano","40.0167","18.1667","Italy","IT","ITA","Puglia","","20070","1380392143"
"Nikolsk","Nikolsk","53.7167","46.0667","Russia","RU","RUS","Penzenskaya Oblast’","","20572","1643760647"
"Veroli","Veroli","41.6833","13.4167","Italy","IT","ITA","Lazio","","20414","1380485951"
"Harmanli","Harmanli","41.9333","25.9000","Bulgaria","BG","BGR","Haskovo","","20549","1100003361"
"Donggou","Donggou","19.6593","110.8460","China","CN","CHN","Hainan","","20393","1156098481"
"Samthar","Samthar","25.8400","78.9000","India","IN","IND","Uttar Pradesh","","20227","1356902851"
"Bibai","Bibai","43.3329","141.8538","Japan","JP","JPN","Hokkaidō","","20507","1392376631"
"Gioia Tauro","Gioia Tauro","38.4333","15.9000","Italy","IT","ITA","Calabria","","20076","1380096431"
"Sivagiri","Sivagiri","9.3300","77.4300","India","IN","IND","Tamil Nādu","","20160","1356423925"
"Susaki","Susaki","33.3925","133.2931","Japan","JP","JPN","Kōchi","","20424","1392572991"
"Rosamond","Rosamond","34.8658","-118.2155","United States","US","USA","California","","20574","1840019140"
"New Canaan","New Canaan","41.1592","-73.4992","United States","US","USA","Connecticut","","20574","1840035495"
"Baden","Baden","47.4667","8.3000","Switzerland","CH","CHE","Aargau","","19122","1756517451"
"Mundra","Mundra","22.8500","69.7300","India","IN","IND","Gujarāt","","20338","1356047064"
"Qagan Us","Qagan Us","36.3972","98.0122","China","CN","CHN","Qinghai","minor","20570","1156989781"
"Arsk","Arsk","56.1000","49.8833","Russia","RU","RUS","Tatarstan","","20540","1643889493"
"Aïn Cheggag","Ain Cheggag","33.8833","-5.0333","Morocco","MA","MAR","Fès-Meknès","","20456","1504540865"
"Hem","Hem","50.6553","3.1878","France","FR","FRA","Hauts-de-France","","18636","1250489811"
"Timaná","Timana","1.9833","-75.9500","Colombia","CO","COL","Huila","minor","20463","1170667913"
"San Mauro Torinese","San Mauro Torinese","45.1039","7.7536","Italy","IT","ITA","Piedmont","","19048","1380895328"
"Schwanewede","Schwanewede","53.2333","8.6000","Germany","DE","DEU","Lower Saxony","","20408","1276874322"
"Marquette","Marquette","46.5440","-87.4082","United States","US","USA","Michigan","","20561","1840003923"
"Alatsinainy-Bakaro","Alatsinainy-Bakaro","-19.3167","47.6833","Madagascar","MG","MDG","Antananarivo","","20370","1450565382"
"Sidney","Sidney","40.2891","-84.1667","United States","US","USA","Ohio","","20559","1840009429"
"Kurshab","Kurshab","40.6917","73.1889","Kyrgyzstan","KG","KGZ","Osh","","20471","1417161123"
"Sanrh Majhgawan","Sanrh Majhgawan","24.6845","85.3650","India","IN","IND","Bihār","","19984","1356016827"
"Rāmchandrapur","Ramchandrapur","23.9208","88.3621","India","IN","IND","West Bengal","","19452","1356234983"
"Namegawa","Namegawa","36.0660","139.3610","Japan","JP","JPN","Saitama","","19885","1392003515"
"Miracatu","Miracatu","-24.2808","-47.4600","Brazil","BR","BRA","São Paulo","","20533","1076480292"
"Altônia","Altonia","-23.8739","-53.9019","Brazil","BR","BRA","Paraná","","20516","1076528546"
"Colonia General Felipe Ángeles","Colonia General Felipe Angeles","23.9167","-104.6000","Mexico","MX","MEX","Durango","","20511","1484647102"
"Wilmot","Wilmot","43.4000","-80.6500","Canada","CA","CAN","Ontario","","20545","1124001797"
"Acri","Acri","39.5000","16.3833","Italy","IT","ITA","Calabria","","20442","1380975284"
"Bethany","Bethany","35.5071","-97.6418","United States","US","USA","Oklahoma","","20542","1840019153"
"Loutété","Loutete","-4.2961","13.8508","Congo (Brazzaville)","CG","COG","Bouenza","","20519","1178075371"
"Laboulaye","Laboulaye","-34.1167","-63.4000","Argentina","AR","ARG","Córdoba","minor","20534","1032996866"
"Rawicz","Rawicz","51.6092","16.8575","Poland","PL","POL","Wielkopolskie","minor","20305","1616333894"
"Caracaraí","Caracarai","1.8158","-61.1278","Brazil","BR","BRA","Roraima","","20537","1076314585"
"Teonthar","Teonthar","24.9821","81.6419","India","IN","IND","Madhya Pradesh","","20135","1356798396"
"Battle Ground","Battle Ground","45.7766","-122.5413","United States","US","USA","Washington","","20536","1840018523"
"Carmen de Patagones","Carmen de Patagones","-40.7833","-62.9667","Argentina","AR","ARG","Buenos Aires","minor","20533","1032352587"
"Bouguenais","Bouguenais","47.1775","-1.6214","France","FR","FRA","Pays de la Loire","","19903","1250502155"
"Kothia","Kothia","25.7612","84.8811","India","IN","IND","Bihār","","18538","1356385426"
"Tyrnyauz","Tyrnyauz","43.4000","42.9167","Russia","RU","RUS","Kabardino-Balkariya","minor","20513","1643073002"
"Pleszew","Pleszew","51.8833","17.7833","Poland","PL","POL","Wielkopolskie","minor","20365","1616452688"
"Eruh","Eruh","37.7497","42.1797","Turkey","TR","TUR","Siirt","minor","20513","1792341278"
"Wittmund","Wittmund","53.5750","7.7808","Germany","DE","DEU","Lower Saxony","minor","20433","1276497812"
"Bayt Ūmmar","Bayt Ummar","31.6214","35.1022","West Bank","XW","XWB","","","19892","1934976309"
"Ban Patong","Ban Patong","7.8931","98.2983","Thailand","TH","THA","Phuket","","19346","1764195169"
"Khvānsār","Khvansar","33.2206","50.3150","Iran","IR","IRN","Eşfahān","minor","20490","1364250256"
"Nova Xavantina","Nova Xavantina","-14.6728","-52.3528","Brazil","BR","BRA","Mato Grosso","","20519","1076345710"
"Rifādpur","Rifadpur","25.2635","87.4157","India","IN","IND","Bihār","","19359","1356112249"
"Sakae","Sakae","35.8408","140.2439","Japan","JP","JPN","Chiba","","19902","1392003462"
"Letterkenny","Letterkenny","54.9566","-7.7203","Ireland","IE","IRL","Donegal","","19274","1372452484"
"Chodzież","Chodziez","52.9833","16.9000","Poland","PL","POL","Wielkopolskie","minor","19100","1616441880"
"Sacile","Sacile","45.9667","12.5000","Italy","IT","ITA","Friuli Venezia Giulia","","19905","1380761998"
"Baskil","Baskil","38.5697","38.8231","Turkey","TR","TUR","Elazığ","minor","20505","1792261115"
"Cinfães","Cinfaes","41.1000","-8.1333","Portugal","PT","PRT","Viseu","minor","20427","1620729048"
"Seydi","Seydi","39.4167","62.9000","Turkmenistan","TM","TKM","Lebap","","20491","1795186775"
"Tondi","Tondi","9.7417","79.0177","India","IN","IND","Tamil Nādu","","20201","1356191008"
"Fara","Fara","11.5333","-2.7667","Burkina Faso","BF","BFA","Boucle du Mouhoun","","20434","1854783851"
"Pleasantville","Pleasantville","39.3900","-74.5169","United States","US","USA","New Jersey","","20505","1840001535"
"Stephenville","Stephenville","32.2147","-98.2205","United States","US","USA","Texas","","20504","1840022097"
"Yorkville","Yorkville","41.6563","-88.4507","United States","US","USA","Illinois","","20503","1840010216"
"Chandragiri","Chandragiri","13.5833","79.3167","India","IN","IND","Andhra Pradesh","","20299","1356092797"
"Gostyń","Gostyn","51.8792","17.0125","Poland","PL","POL","Wielkopolskie","minor","20296","1616275576"
"Sartalillo","Sartalillo","9.9759","-84.0232","Costa Rica","CR","CRI","Cartago","","20349","1188001617"
"São Joaquim do Monte","Sao Joaquim do Monte","-8.4319","-35.8039","Brazil","BR","BRA","Pernambuco","","20488","1076649573"
"Becerril","Becerril","9.7000","-73.2833","Colombia","CO","COL","Cesar","minor","20477","1170989688"
"Dorchester","Dorchester","50.7154","-2.4367","United Kingdom","GB","GBR","Dorset","","20135","1826909037"
"Bad Waldsee","Bad Waldsee","47.9211","9.7519","Germany","DE","DEU","Baden-Württemberg","","20308","1276818086"
"L’Isle-sur-la-Sorgue","L'Isle-sur-la-Sorgue","43.9194","5.0514","France","FR","FRA","Provence-Alpes-Côte d’Azur","","20042","1250006499"
"Karlshamn","Karlshamn","56.1667","14.8500","Sweden","SE","SWE","Blekinge","","19075","1752379401"
"Tobe","Tobe","33.7492","132.7922","Japan","JP","JPN","Ehime","","20290","1392744694"
"Garhshankar","Garhshankar","31.2154","76.1415","India","IN","IND","Punjab","","19995","1356237015"
"Takanabe","Takanabe","32.1280","131.5033","Japan","JP","JPN","Miyazaki","","20030","1392293829"
"San Lorenzo","San Lorenzo","1.5039","-77.2178","Colombia","CO","COL","Nariño","minor","20403","1170168710"
"Taşlıçay","Taslicay","39.6333","43.3778","Turkey","TR","TUR","Ağrı","minor","20450","1792445598"
"Mārahra","Marahra","27.7500","78.5667","India","IN","IND","Uttar Pradesh","","19542","1356519387"
"Puerto Quito","Puerto Quito","0.1272","-79.2531","Ecuador","EC","ECU","Pichincha","","20445","1218847413"
"Muzambinho","Muzambinho","-21.3758","-46.5258","Brazil","BR","BRA","Minas Gerais","","20430","1076230022"
"Uetze","Uetze","52.4661","10.2039","Germany","DE","DEU","Lower Saxony","","20333","1276620577"
"Beforona","Beforona","-18.9667","48.5833","Madagascar","MG","MDG","Toamasina","","20380","1450730932"
"Montalto Uffugo","Montalto Uffugo","39.4000","16.1500","Italy","IT","ITA","Calabria","","20213","1380541652"
"West St. Paul","West St. Paul","44.9018","-93.0858","United States","US","USA","Minnesota","","20476","1840010012"
"Pargi","Pargi","17.1756","77.8781","India","IN","IND","Andhra Pradesh","","20164","1356287180"
"Snellville","Snellville","33.8562","-84.0038","United States","US","USA","Georgia","","20475","1840015614"
"Cantanhede","Cantanhede","-3.6328","-44.3769","Brazil","BR","BRA","Maranhão","","20448","1076238888"
"San Martín","San Martin","8.0472","-73.5153","Colombia","CO","COL","Cesar","minor","20452","1170564282"
"Bhitarwār","Bhitarwar","25.7922","78.1108","India","IN","IND","Madhya Pradesh","","20148","1356863827"
"Frøyland","Froyland","58.8308","5.7506","Norway","NO","NOR","Rogaland","","20353","1578422189"
"Bhojpur Jadīd","Bhojpur Jadid","25.5880","84.1621","India","IN","IND","Bihār","","19281","1356285087"
"Nguékhokh","Nguekhokh","14.5128","-17.0500","Senegal","SN","SEN","Thiès","","20053","1686782757"
"Sompeta","Sompeta","18.9300","84.6000","India","IN","IND","Andhra Pradesh","","18778","1356426585"
"Chepo","Chepo","9.1700","-79.1000","Panama","PA","PAN","Panamá","minor","20420","1591679968"
"Kashkar-Kyshtak","Kashkar-Kyshtak","40.6200","72.8300","Kyrgyzstan","KG","KGZ","Osh","","20334","1417542891"
"Canarana I","Canarana I","-13.5500","-52.1658","Brazil","BR","BRA","Mato Grosso","","20461","1076002028"
"Piove di Sacco","Piove di Sacco","45.2977","12.0368","Italy","IT","ITA","Veneto","","19902","1380727166"
"Santa Rita","Santa Rita","15.2000","-87.8833","Honduras","HN","HND","Yoro","","20301","1340894551"
"Annaberg-Buchholz","Annaberg-Buchholz","50.5800","13.0022","Germany","DE","DEU","Saxony","minor","19769","1276866777"
"Tenjo","Tenjo","4.9167","-74.1667","Colombia","CO","COL","Cundinamarca","minor","20269","1170657903"
"Belūr","Belur","13.1642","75.8647","India","IN","IND","Karnātaka","","20225","1356747417"
"Villefontaine","Villefontaine","45.6133","5.1486","France","FR","FRA","Auvergne-Rhône-Alpes","","18835","1250325596"
"Mirador","Mirador","-6.3708","-44.3628","Brazil","BR","BRA","Maranhão","","20452","1076000972"
"Kampong Thum","Kampong Thum","12.7120","104.8890","Cambodia","KH","KHM","Kampong Thom","","19951","1116000326"
"Ypsilanti","Ypsilanti","42.2440","-83.6208","United States","US","USA","Michigan","","20448","1840000459"
"Pennāgaram","Pennagaram","12.1343","77.8952","India","IN","IND","Tamil Nādu","","20203","1356884367"
"Ibirubá","Ibiruba","-28.6278","-53.0900","Brazil","BR","BRA","Rio Grande do Sul","","20413","1076502864"
"Carmo do Rio Claro","Carmo do Rio Claro","-20.9731","-46.1189","Brazil","BR","BRA","Minas Gerais","","20426","1076819558"
"Kudra","Kudra","25.0463","83.7926","India","IN","IND","Bihār","","19265","1356855561"
"Vianen","Vianen","51.9833","5.1000","Netherlands","NL","NLD","Utrecht","minor","19967","1528383911"
"Kanniyākumāri","Kanniyakumari","8.0911","77.5417","India","IN","IND","Tamil Nādu","","19678","1356026727"
"Ostrov","Ostrov","57.3500","28.3500","Russia","RU","RUS","Pskovskaya Oblast’","","20427","1643602961"
"Piñan","Pinan","8.4822","123.4500","Philippines","PH","PHL","Zamboanga del Norte","","20221","1608273058"
"Baihar","Baihar","22.1013","80.5497","India","IN","IND","Madhya Pradesh","","20314","1356452972"
"Akanavāritota","Akanavaritota","16.5984","81.8116","India","IN","IND","Andhra Pradesh","","19507","1356311836"
"Yabayo","Yabayo","5.9500","-6.6000","Côte d'Ivoire","CI","CIV","Bas-Sassandra","","20086","1384922069"
"Kubinka","Kubinka","55.5667","36.7000","Russia","RU","RUS","Moskovskaya Oblast’","","20157","1643021922"
"Butiá","Butia","-30.1200","-51.9619","Brazil","BR","BRA","Rio Grande do Sul","","20406","1076641089"
"Dereli","Dereli","40.7333","38.4333","Turkey","TR","TUR","Giresun","minor","20405","1792552024"
"Çilimli","Cilimli","40.9000","31.0500","Turkey","TR","TUR","Düzce","minor","20266","1792945570"
"Kuala Pembuang","Kuala Pembuang","-3.3871","112.5434","Indonesia","ID","IDN","Kalimantan Tengah","minor","20413","1360736097"
"Five Corners","Five Corners","45.6883","-122.5738","United States","US","USA","Washington","","20428","1840037869"
"Essex","Essex","42.0833","-82.9000","Canada","CA","CAN","Ontario","","20427","1124628052"
"Chã Grande","Cha Grande","-8.2378","-35.4619","Brazil","BR","BRA","Pernambuco","","20137","1076188673"
"Ghogardīha","Ghogardiha","26.2799","86.4700","India","IN","IND","Bihār","","19174","1356994830"
"Middle","Middle","39.0852","-74.8337","United States","US","USA","New Jersey","","20423","1840081576"
"Cruzeiro do Oeste","Cruzeiro do Oeste","-23.7850","-53.0728","Brazil","BR","BRA","Paraná","","20416","1076303901"
"Tolosa","Tolosa","43.1333","-2.0833","Spain","ES","ESP","Basque Country","","19886","1724081737"
"Durbat","Durbat","38.5333","68.6333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","20052","1762805459"
"Kulpahār","Kulpahar","25.3190","79.6410","India","IN","IND","Uttar Pradesh","","20096","1356135312"
"Baza","Baza","37.4833","-2.7667","Spain","ES","ESP","Andalusia","","20376","1724271422"
"Khilchipur","Khilchipur","24.0394","76.5780","India","IN","IND","Madhya Pradesh","","20218","1356856413"
"Lower Southampton","Lower Southampton","40.1541","-74.9903","United States","US","USA","Pennsylvania","","20410","1840151113"
"Oroville","Oroville","39.4999","-121.5634","United States","US","USA","California","","20409","1840020182"
"La Corredoría","La Corredoria","43.3853","-5.8208","Spain","ES","ESP","Asturias","","19191","1724680069"
"Medina del Campo","Medina del Campo","41.3000","-4.9167","Spain","ES","ESP","Castille-Leon","","20271","1724808879"
"Reggane","Reggane","26.7000","0.1667","Algeria","DZ","DZA","Adrar","","20402","1012709576"
"João Lisboa","Joao Lisboa","-5.4478","-47.4008","Brazil","BR","BRA","Maranhão","","20381","1076460589"
"Bryant","Bryant","34.6152","-92.4914","United States","US","USA","Arkansas","","20399","1840013573"
"Marblehead","Marblehead","42.4992","-70.8639","United States","US","USA","Massachusetts","","20397","1840053532"
"Concordia","Concordia","6.0490","-75.9090","Colombia","CO","COL","Antioquia","minor","20271","1170368496"
"Terenos","Terenos","-20.4419","-54.8600","Brazil","BR","BRA","Mato Grosso do Sul","","20387","1076729588"
"Parelhas","Parelhas","-6.6878","-36.6578","Brazil","BR","BRA","Rio Grande do Norte","","20354","1076603835"
"Elko","Elko","40.8381","-115.7678","United States","US","USA","Nevada","","20391","1840020120"
"Tilothu","Tilothu","24.8051","84.0833","India","IN","IND","Bihār","","18406","1356139149"
"Mahājerān-e Kamar","Mahajeran-e Kamar","34.0183","49.4028","Iran","IR","IRN","Markazī","","20346","1364396330"
"Torrox","Torrox","36.7500","-3.9500","Spain","ES","ESP","Andalusia","","19997","1724600506"
"Złotów","Zlotow","53.3603","17.0408","Poland","PL","POL","Wielkopolskie","minor","18768","1616339419"
"Birnagar","Birnagar","25.9785","87.1110","India","IN","IND","Bihār","","18758","1356070365"
"Kissa","Kissa","7.0333","1.7833","Benin","BJ","BEN","Couffo","","20016","1204966404"
"Ocós","Ocos","14.5138","-92.1909","Guatemala","GT","GTM","San Marcos","minor","20215","1320442186"
"Bouansa","Bouansa","-4.2186","13.7617","Congo (Brazzaville)","CG","COG","Bouenza","","20361","1178437316"
"Old Jamestown","Old Jamestown","38.8394","-90.2847","United States","US","USA","Missouri","","20382","1840039632"
"Hanahan","Hanahan","32.9302","-80.0027","United States","US","USA","South Carolina","","20381","1840014256"
"Huanuni","Huanuni","-18.2900","-66.8383","Bolivia","BO","BOL","Oruro","","20336","1068902608"
"Jandaia do Sul","Jandaia do Sul","-23.6028","-51.6428","Brazil","BR","BRA","Paraná","","20269","1076619857"
"Melzo","Melzo","45.5000","9.4167","Italy","IT","ITA","Lombardy","","18493","1380925708"
"Mandrosohasina","Mandrosohasina","-19.5833","47.1667","Madagascar","MG","MDG","Antananarivo","","20000","1450360215"
"Vallam","Vallam","10.7199","79.0598","India","IN","IND","Tamil Nādu","","19557","1356401542"
"Parsāhi Sirsia","Parsahi Sirsia","26.4676","86.4984","India","IN","IND","Bihār","","19517","1356796108"
"Northfield","Northfield","44.4550","-93.1698","United States","US","USA","Minnesota","","20374","1840007891"
"Bequimão","Bequimao","-2.4489","-44.7828","Brazil","BR","BRA","Maranhão","","20344","1076416411"
"Danao","Danao","10.0167","124.2667","Philippines","PH","PHL","Bohol","","20245","1608630019"
"Sandwich","Sandwich","41.7137","-70.4851","United States","US","USA","Massachusetts","","20369","1840053505"
"Ėsanboy","Esanboy","38.0833","68.3833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","20072","1762743298"
"Mayluu-Suu","Mayluu-Suu","41.2457","72.4480","Kyrgyzstan","KG","KGZ","Jalal-Abad","","20200","1417039122"
"Teculután","Teculutan","14.9877","-89.7170","Guatemala","GT","GTM","Zacapa","minor","20272","1320018661"
"Kloten","Kloten","47.4500","8.5833","Switzerland","CH","CHE","Zürich","","19362","1756844710"
"Sonhauli","Sonhauli","25.5108","86.4707","India","IN","IND","Bihār","","18671","1356835776"
"Forest Lake","Forest Lake","45.2536","-92.9582","United States","US","USA","Minnesota","","20366","1840007808"
"Villa Verde","Villa Verde","16.6067","121.1828","Philippines","PH","PHL","Nueva Vizcaya","","20118","1608397059"
"Bourne","Bourne","41.7233","-70.5816","United States","US","USA","Massachusetts","","20364","1840053642"
"Khowrzūq","Khowrzuq","32.7781","51.6461","Iran","IR","IRN","Eşfahān","","20301","1364753903"
"Wilnsdorf","Wilnsdorf","50.8167","8.1000","Germany","DE","DEU","North Rhine-Westphalia","","20088","1276419924"
"Agoura Hills","Agoura Hills","34.1510","-118.7609","United States","US","USA","California","","20362","1840019221"
"Eastwood","Eastwood","53.0180","-1.3040","United Kingdom","GB","GBR","Nottinghamshire","","18612","1826227657"
"Le Puy-en-Velay","Le Puy-en-Velay","45.0433","3.8850","France","FR","FRA","Auvergne-Rhône-Alpes","minor","19215","1250044604"
"Nhamundá","Nhamunda","-2.1858","-56.7128","Brazil","BR","BRA","Amazonas","","20358","1076418792"
"Mělník","Melnik","50.3506","14.4742","Czechia","CZ","CZE","Středočeský Kraj","","19579","1203735988"
"Abaré","Abare","-8.7208","-39.1150","Brazil","BR","BRA","Bahia","","20347","1076490247"
"São José de Piranhas","Sao Jose de Piranhas","-7.1208","-38.5019","Brazil","BR","BRA","Paraíba","","20329","1076773961"
"Tsotsin-Yurt","Tsotsin-Yurt","43.2419","46.0000","Russia","RU","RUS","Chechnya","","20013","1643948204"
"Rosas","Rosas","42.2633","3.1750","Spain","ES","ESP","Catalonia","","19921","1724996343"
"Oberkirch","Oberkirch","48.5333","8.0833","Germany","DE","DEU","Baden-Württemberg","","20066","1276739886"
"Cuité","Cuite","-6.4850","-36.1569","Brazil","BR","BRA","Paraíba","","20325","1076189452"
"Piqua","Piqua","40.1506","-84.2441","United States","US","USA","Ohio","","20352","1840009467"
"Aguas Zarcas","Aguas Zarcas","10.4223","-84.3554","Costa Rica","CR","CRI","Alajuela","","20239","1188992281"
"Depālpur","Depalpur","22.8509","75.5422","India","IN","IND","Madhya Pradesh","","20077","1356031267"
"Monroe","Monroe","41.9155","-83.3849","United States","US","USA","Michigan","","20344","1840003987"
"Lohur","Lohur","38.3833","68.7000","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","20047","1762289718"
"Valença do Piauí","Valenca do Piaui","-6.4078","-41.7458","Brazil","BR","BRA","Piauí","","20326","1076056568"
"Encruzilhada","Encruzilhada","-15.5308","-40.9089","Brazil","BR","BRA","Bahia","","20331","1076905505"
"Sátiro Dias","Satiro Dias","-11.6000","-38.6000","Brazil","BR","BRA","Bahia","","20320","1076994641"
"Greiz","Greiz","50.6547","12.1997","Germany","DE","DEU","Thuringia","minor","20103","1276236041"
"Mirfield","Mirfield","53.6807","-1.6888","United Kingdom","GB","GBR","Kirklees","","19563","1826835871"
"Dan Gorayo","Dan Gorayo","8.7278","49.3417","Somalia","SO","SOM","Bari","","20331","1706372631"
"Cardedeu","Cardedeu","41.6406","2.3594","Spain","ES","ESP","Catalonia","","18785","1724134455"
"São José do Rio Preto","Sao Jose do Rio Preto","-22.1508","-42.9239","Brazil","BR","BRA","Rio de Janeiro","","20251","1076054685"
"Donauwörth","Donauworth","48.7000","10.8000","Germany","DE","DEU","Bavaria","minor","20080","1276878391"
"Lonār","Lonar","19.9800","76.5200","India","IN","IND","Mahārāshtra","","20082","1356253434"
"Luperón","Luperon","19.9000","-70.9500","Dominican Republic","DO","DOM","Cibao Norte","","20259","1214308542"
"Cabra","Cabra","37.4667","-4.4333","Spain","ES","ESP","Andalusia","","20245","1724617911"
"Abhayāpuri","Abhayapuri","26.3225","90.6853","India","IN","IND","Assam","","19368","1356069797"
"Nambūru","Namburu","16.3600","80.5200","India","IN","IND","Andhra Pradesh","","19676","1356137044"
"Rumoi","Rumoi","43.9410","141.6370","Japan","JP","JPN","Hokkaidō","","20260","1392820591"
"Hatvan","Hatvan","47.6681","19.6697","Hungary","HU","HUN","Heves","minor","20079","1348933364"
"Farāshband","Farashband","28.8714","52.0917","Iran","IR","IRN","Fārs","minor","20320","1364248167"
"Atescatempa","Atescatempa","14.1750","-89.7417","Guatemala","GT","GTM","Jutiapa","minor","20033","1320044665"
"Orvieto","Orvieto","42.7183","12.1103","Italy","IT","ITA","Umbria","","20253","1380264756"
"Santa Rosa","Santa Rosa","-26.8667","-56.8500","Paraguay","PY","PRY","Misiones","","20306","1600899720"
"Hirokawa","Hirokawa","33.2415","130.5515","Japan","JP","JPN","Fukuoka","","19801","1392003284"
"Selendi","Selendi","38.7444","28.8678","Turkey","TR","TUR","Manisa","minor","20291","1792936092"
"Itaiópolis","Itaiopolis","-26.3358","-49.9058","Brazil","BR","BRA","Santa Catarina","","20301","1076429298"
"Marneuli","Marneuli","41.4969","44.8108","Georgia","GE","GEO","Kvemo Kartli","minor","20211","1268246632"
"Jdour","Jdour","32.1269","-8.7633","Morocco","MA","MAR","Marrakech-Safi","","20239","1504892254"
"Siripur","Siripur","26.7336","84.7217","India","IN","IND","Bihār","","18975","1356067871"
"Azacualpa","Azacualpa","15.3667","-88.5500","Honduras","HN","HND","Santa Bárbara","","20210","1340612681"
"Painesville","Painesville","41.7240","-81.2536","United States","US","USA","Ohio","","20311","1840000542"
"Comăneşti","Comanesti","46.4297","26.4500","Romania","RO","ROU","Bacău","","19996","1642511009"
"Kyakhta","Kyakhta","50.3500","106.4500","Russia","RU","RUS","Buryatiya","","20297","1643237220"
"Urussanga","Urussanga","-28.5178","-49.3208","Brazil","BR","BRA","Santa Catarina","","20223","1076830411"
"Jirkov","Jirkov","50.4999","13.4478","Czechia","CZ","CZE","Ústecký Kraj","","19200","1203484786"
"Bamumkumbit","Bamumkumbit","5.8167","10.4167","Cameroon","CM","CMR","Ouest","","20000","1120980462"
"Leinefelde","Leinefelde","51.3833","10.3333","Germany","DE","DEU","Thuringia","","20124","1276779963"
"Crest Hill","Crest Hill","41.5723","-88.1124","United States","US","USA","Illinois","","20305","1840007088"
"Rajaudha","Rajaudha","26.7046","78.4223","India","IN","IND","Madhya Pradesh","","19534","1356732434"
"Nāri Bhadaun","Nari Bhadaun","26.0065","86.2509","India","IN","IND","Bihār","","18345","1356002213"
"Concarneau","Concarneau","47.8753","-3.9189","France","FR","FRA","Bretagne","","19816","1250014863"
"Villa Hidalgo","Villa Hidalgo","21.6762","-102.5880","Mexico","MX","MEX","Jalisco","minor","20257","1484702656"
"Lichtenfels","Lichtenfels","50.1333","11.0333","Germany","DE","DEU","Bavaria","minor","20133","1276573026"
"Rosedale","Rosedale","39.3266","-76.5084","United States","US","USA","Maryland","","20296","1840005690"
"Paraguaçu","Paraguacu","-21.5331","-45.7664","Brazil","BR","BRA","Minas Gerais","","20245","1076438228"
"Sergach","Sergach","55.5333","45.4667","Russia","RU","RUS","Nizhegorodskaya Oblast’","","20269","1643460838"
"Porto Real do Colégio","Porto Real do Colegio","-10.1858","-36.8400","Brazil","BR","BRA","Alagoas","","20205","1076149221"
"Bethel","Bethel","41.3747","-73.3928","United States","US","USA","Connecticut","","20287","1840034129"
"Simití","Simiti","7.9667","-73.9500","Colombia","CO","COL","Bolívar","minor","20271","1170040846"
"Neustrelitz","Neustrelitz","53.3647","13.0636","Germany","DE","DEU","Mecklenburg-Western Pomerania","","20140","1276001058"
"Bīrpur","Birpur","25.5181","86.0826","India","IN","IND","Bihār","","18989","1356592237"
"Glassboro","Glassboro","39.7014","-75.1113","United States","US","USA","New Jersey","","20284","1840003789"
"Smørumnedre","Smorumnedre","55.7333","12.3000","Denmark","DK","DNK","Hovedstaden","","19780","1208922799"
"Rovira","Rovira","4.2500","-75.3333","Colombia","CO","COL","Tolima","minor","20255","1170046008"
"Sherwood","Sherwood","45.3594","-122.8427","United States","US","USA","Oregon","","20281","1840021205"
"East Northport","East Northport","40.8791","-73.3233","United States","US","USA","New York","","20281","1840005039"
"Ogden","Ogden","43.1646","-77.8220","United States","US","USA","New York","","20281","1840087746"
"Limerick","Limerick","40.2323","-75.5344","United States","US","USA","Pennsylvania","","20280","1840035062"
"Hakui","Hakui","36.8936","136.7790","Japan","JP","JPN","Ishikawa","","20031","1392246998"
"Água Branca","Agua Branca","-9.2608","-37.9361","Brazil","BR","BRA","Alagoas","","20230","1076362239"
"Huanímaro","Huanimaro","20.3675","-101.4969","Mexico","MX","MEX","Guanajuato","minor","20117","1484325445"
"Minturno","Minturno","41.2667","13.7500","Italy","IT","ITA","Lazio","","19804","1380600468"
"Diguapo","Diguapo","25.6988","104.9614","China","CN","CHN","Guizhou","","20049","1156521848"
"Sbeitla","Sbeitla","35.2297","9.1294","Tunisia","TN","TUN","Kasserine","","20253","1788964283"
"Quissamã","Quissama","-22.1069","-41.4719","Brazil","BR","BRA","Rio de Janeiro","","20242","1076255718"
"Tepetlixpa","Tepetlixpa","19.0006","-98.8178","Mexico","MX","MEX","México","minor","19843","1484018861"
"Voyenno-Antonovka","Voyenno-Antonovka","42.8722","74.4500","Kyrgyzstan","KG","KGZ","Chüy","","20182","1417044107"
"Náchod","Nachod","50.4167","16.1630","Czechia","CZ","CZE","Královéhradecký Kraj","","19688","1203181897"
"Belém de São Francisco","Belem de Sao Francisco","-8.7578","-38.9639","Brazil","BR","BRA","Pernambuco","","20253","1076900718"
"Telwa","Telwa","25.8796","86.3837","India","IN","IND","Bihār","","18643","1356257286"
"Simri","Simri","25.6405","84.1195","India","IN","IND","Bihār","","19072","1356048353"
"Shīrūru","Shiruru","13.9080","74.6058","India","IN","IND","Karnātaka","","19972","1356227953"
"Lalín","Lalin","42.6500","-8.1167","Spain","ES","ESP","Galicia","","20201","1724795282"
"Agarpur","Agarpur","25.1957","87.0423","India","IN","IND","Bihār","","18367","1356918290"
"Pujehun","Pujehun","7.3506","-11.7181","Sierra Leone","SL","SLE","Southern","","20121","1694026620"
"Stebnyk","Stebnyk","49.3000","23.5667","Ukraine","UA","UKR","Lvivska Oblast","","20200","1804781699"
"Del Carmen","Del Carmen","9.8690","125.9700","Philippines","PH","PHL","Surigao del Norte","","20127","1608397694"
"Anicuns","Anicuns","-16.4608","-49.9619","Brazil","BR","BRA","Goiás","","20239","1076386139"
"Requena","Requena","39.4861","-1.1017","Spain","ES","ESP","Valencia","","20235","1724453990"
"Pedro Carbo","Pedro Carbo","-1.8333","-80.2333","Ecuador","EC","ECU","Guayas","","20220","1218092556"
"Torgau","Torgau","51.5603","13.0056","Germany","DE","DEU","Saxony","minor","20065","1276804920"
"Haren","Haren","53.1667","6.6000","Netherlands","NL","NLD","Groningen","minor","19860","1528092333"
"Bella Vista","Bella Vista","15.6167","-92.2333","Mexico","MX","MEX","Chiapas","minor","20157","1484881396"
"Edam","Edam","52.5167","5.0500","Netherlands","NL","NLD","Noord-Holland","","18828","1528614478"
"Brandýs nad Labem-Stará Boleslav","Brandys nad Labem-Stara Boleslav","50.1894","14.6672","Czechia","CZ","CZE","Středočeský Kraj","","19420","1203785584"
"Tsararafa","Tsararafa","-22.7333","47.8167","Madagascar","MG","MDG","Fianarantsoa","","20000","1450899440"
"Cahors","Cahors","44.4475","1.4406","France","FR","FRA","Occitanie","minor","19937","1250410554"
"Rāmjībanpur","Ramjibanpur","22.8300","87.6200","India","IN","IND","West Bengal","","19611","1356578747"
"Zossen","Zossen","52.2167","13.4497","Germany","DE","DEU","Brandenburg","","20130","1276905478"
"Acqui Terme","Acqui Terme","44.6761","8.4686","Italy","IT","ITA","Piedmont","","19651","1380674535"
"Goole","Goole","53.6992","-0.8692","United Kingdom","GB","GBR","East Riding of Yorkshire","","19518","1826412985"
"Viadana","Viadana","44.9267","10.5200","Italy","IT","ITA","Lombardy","","20044","1380676530"
"Parnamirim","Parnamirim","-8.0908","-39.5778","Brazil","BR","BRA","Pernambuco","","20224","1076703866"
"Puerto Viejo","Puerto Viejo","10.5737","-83.9548","Costa Rica","CR","CRI","Heredia","","20184","1188508084"
"Cruz Grande","Cruz Grande","16.7333","-99.1333","Mexico","MX","MEX","Guerrero","minor","20175","1484074616"
"Boromo","Boromo","11.7500","-2.9333","Burkina Faso","BF","BFA","Boucle du Mouhoun","minor","20193","1854589040"
"Perry","Perry","32.4720","-83.7283","United States","US","USA","Georgia","","20227","1840014885"
"Dračevo","Dracevo","41.9367","21.5217","Macedonia","MK","MKD","Skopje","","19246","1807215833"
"Abrīsham","Abrisham","32.5550","51.5731","Iran","IR","IRN","Eşfahān","","19406","1364088280"
"Holzminden","Holzminden","51.8297","9.4483","Germany","DE","DEU","Lower Saxony","minor","19998","1276491992"
"Erragondapālem","Erragondapalem","16.0397","79.3043","India","IN","IND","Andhra Pradesh","","20155","1356579088"
"Yeniseysk","Yeniseysk","58.4667","92.1333","Russia","RU","RUS","Krasnoyarskiy Kray","","19920","1643588190"
"Bideford","Bideford","51.0160","-4.2090","United Kingdom","GB","GBR","Devon","","18260","1826207226"
"Schopfheim","Schopfheim","47.6500","7.8167","Germany","DE","DEU","Baden-Württemberg","","19922","1276002233"
"Río Grande","Rio Grande","19.7300","-70.8000","Dominican Republic","DO","DOM","","","20154","1214092035"
"Kākdwīp","Kakdwip","21.8791","88.1913","India","IN","IND","West Bengal","","19368","1356562790"
"Pāmūru","Pamuru","15.0944","79.4138","India","IN","IND","Andhra Pradesh","","20000","1356991450"
"Sukhāsan","Sukhasan","25.4944","87.5263","India","IN","IND","Bihār","","18982","1356714992"
"Málaga","Malaga","6.7833","-72.6667","Colombia","CO","COL","Santander","minor","19884","1170443368"
"Bichena","Bichena","10.4500","38.2000","Ethiopia","ET","ETH","Āmara","","19916","1231640166"
"Kleppe","Kleppe","58.7772","5.5967","Norway","NO","NOR","Rogaland","minor","20163","1578765733"
"Celorico de Basto","Celorico de Basto","41.3833","-8.0000","Portugal","PT","PRT","Braga","minor","20098","1620638780"
"Vīrarājendrapet","Virarajendrapet","12.1964","75.8051","India","IN","IND","Karnātaka","","20081","1356031405"
"Kęty","Kety","49.9000","19.2167","Poland","PL","POL","Małopolskie","","19428","1616742375"
"Bhīkhi","Bhikhi","30.0700","75.5300","India","IN","IND","Punjab","","19905","1356512688"
"Otegen Batyr","Otegen Batyr","43.3333","77.0500","Kazakhstan","KZ","KAZ","","","20163","1398960718"
"Saco","Saco","43.5390","-70.4624","United States","US","USA","Maine","","20203","1840000343"
"Telgte","Telgte","51.9819","7.7856","Germany","DE","DEU","North Rhine-Westphalia","","19982","1276981111"
"Qorasuv","Qorasuv","40.7222","72.8872","Uzbekistan","UZ","UZB","Andijon","","19454","1860791531"
"Giengen an der Brenz","Giengen an der Brenz","48.6217","10.2450","Germany","DE","DEU","Baden-Württemberg","","19753","1276782990"
"Ampefy","Ampefy","-19.0419","46.7328","Madagascar","MG","MDG","Antananarivo","","19949","1450915627"
"Bekatra Maromiandra","Bekatra Maromiandra","-22.1000","47.7167","Madagascar","MG","MDG","Fianarantsoa","","20000","1450240946"
"Hazlet","Hazlet","40.4265","-74.1712","United States","US","USA","New Jersey","","20193","1840081651"
"Kannod","Kannod","22.6676","76.7429","India","IN","IND","Madhya Pradesh","","20044","1356443452"
"Taquarana","Taquarana","-9.6450","-36.4969","Brazil","BR","BRA","Alagoas","","20072","1076029137"
"Pikalëvo","Pikalevo","59.5333","34.1333","Russia","RU","RUS","Leningradskaya Oblast’","","20183","1643572951"
"El Viso del Alcor","El Viso del Alcor","37.3833","-5.7167","Spain","ES","ESP","Andalusia","","19251","1724881330"
"Marion Oaks","Marion Oaks","29.0011","-82.1953","United States","US","USA","Florida","","20190","1840047949"
"Imouzzer Kandar","Imouzzer Kandar","33.7300","-5.0100","Morocco","MA","MAR","Fès-Meknès","","19125","1504932203"
"Bara Malehra","Bara Malehra","24.5681","79.3069","India","IN","IND","Madhya Pradesh","","19858","1356842746"
"Whitpain","Whitpain","40.1578","-75.2769","United States","US","USA","Pennsylvania","","20189","1840145445"
"Ivoti","Ivoti","-29.5908","-51.1608","Brazil","BR","BRA","Rio Grande do Sul","","19874","1076815528"
"Chipiona","Chipiona","36.7333","-6.4333","Spain","ES","ESP","Andalusia","","19592","1724000060"
"Irlam","Irlam","53.4450","-2.4220","United Kingdom","GB","GBR","Salford","","19933","1826561547"
"Kotelnikovo","Kotelnikovo","47.6333","43.1500","Russia","RU","RUS","Volgogradskaya Oblast’","","20172","1643615512"
"Bothell West","Bothell West","47.8056","-122.2401","United States","US","USA","Washington","","20180","1840041906"
"Plainview","Plainview","34.1911","-101.7235","United States","US","USA","Texas","","20180","1840020538"
"Snina","Snina","48.9881","22.1567","Slovakia","SK","SVK","Prešov","minor","19855","1703799818"
"São Luís Gonzaga do Maranhão","Sao Luis Gonzaga do Maranhao","-4.3800","-44.6700","Brazil","BR","BRA","Maranhão","","20153","1076739707"
"Ayaviri","Ayaviri","-14.8818","-70.5901","Peru","PE","PER","Puno","","20152","1604922172"
"Frontino","Frontino","6.7833","-76.1333","Colombia","CO","COL","Antioquia","minor","20156","1170729668"
"Toprakkale","Toprakkale","37.0669","36.1464","Turkey","TR","TUR","Osmaniye","minor","20127","1792333084"
"Antsampandrano","Antsampandrano","-19.5333","47.5833","Madagascar","MG","MDG","Antananarivo","","20000","1450604562"
"Foley","Foley","30.3983","-87.6650","United States","US","USA","Alabama","","20170","1840002084"
"Kuala Lipis","Kuala Lipis","4.1800","102.0500","Malaysia","MY","MYS","Pahang","","20000","1458124212"
"Heusenstamm","Heusenstamm","50.0500","8.8000","Germany","DE","DEU","Hesse","","19160","1276666927"
"Baragoi","Baragoi","1.7833","36.7833","Kenya","KE","KEN","Samburu","","20000","1404601511"
"Manaratsandry","Manaratsandry","-16.1833","46.5000","Madagascar","MG","MDG","Mahajanga","","20000","1450445247"
"Thimiri","Thimiri","12.8283","79.3079","India","IN","IND","Tamil Nādu","","19737","1356942559"
"Perdões","Perdoes","-21.0908","-45.0908","Brazil","BR","BRA","Minas Gerais","","20087","1076957512"
"Satsuma","Satsuma","31.9057","130.4556","Japan","JP","JPN","Kagoshima","","20093","1392003341"
"Huércal-Overa","Huercal-Overa","37.3833","-1.9333","Spain","ES","ESP","Andalusia","","20093","1724061354"
"Fort St. John","Fort St. John","56.2465","-120.8476","Canada","CA","CAN","British Columbia","","20155","1124517495"
"Weesp","Weesp","52.3000","5.0500","Netherlands","NL","NLD","Noord-Holland","minor","19334","1528983358"
"Mithi","Mithi","24.7333","69.8000","Pakistan","PK","PAK","Sindh","","20079","1586400830"
"Sesheke","Sesheke","-17.4667","24.3000","Zambia","ZM","ZMB","Western","","20149","1894753767"
"Uruçuí","Urucui","-7.2333","-44.5500","Brazil","BR","BRA","Piauí","","20149","1076915022"
"Saint-Dié-des-Vosges","Saint-Die-des-Vosges","48.2842","6.9492","France","FR","FRA","Grand Est","minor","19724","1250197318"
"Naaldwijk","Naaldwijk","51.9931","4.2050","Netherlands","NL","NLD","Zuid-Holland","minor","18858","1528653084"
"Kfar Kiddé","Kfar Kidde","34.2028","35.6544","Lebanon","LB","LBN","Mont-Liban","","20000","1422121365"
"Haka","Haka","22.9833","94.0167","Myanmar","MM","MMR","Sagaing","","20000","1104921389"
"Guding","Guding","26.4876","107.4460","China","CN","CHN","Guizhou","","20051","1156175801"
"Paraibano","Paraibano","-6.4328","-43.9839","Brazil","BR","BRA","Maranhão","","20103","1076424207"
"Lancing","Lancing","50.8320","-0.3190","United Kingdom","GB","GBR","West Sussex","","18810","1826909253"
"Jāmbai","Jambai","11.4678","77.6434","India","IN","IND","Tamil Nādu","","19814","1356167838"
"Yuancun","Yuancun","27.4540","106.6649","China","CN","CHN","Guizhou","","19913","1156227005"
"Jiangjiehe","Jiangjiehe","27.2618","107.3886","China","CN","CHN","Guizhou","","20015","1156459084"
"La Chapelle-sur-Erdre","La Chapelle-sur-Erdre","47.2989","-1.5528","France","FR","FRA","Pays de la Loire","","19551","1250918236"
"Jasidih","Jasidih","24.5138","86.6458","India","IN","IND","Jhārkhand","","18660","1356038055"
"Pattamadai","Pattamadai","8.6726","77.5845","India","IN","IND","Tamil Nādu","","19743","1356205164"
"Plaisance-du-Touch","Plaisance-du-Touch","43.5656","1.2964","France","FR","FRA","Occitanie","","19402","1250061109"
"Araruna","Araruna","-6.5578","-35.7419","Brazil","BR","BRA","Paraíba","","20051","1076203959"
"Villabate","Villabate","38.0833","13.4500","Italy","IT","ITA","Sicilia","","20051","1380925596"
"Mahaditra","Mahaditra","-21.7500","47.1000","Madagascar","MG","MDG","Fianarantsoa","","20068","1450574593"
"Sangīn","Sangin","32.0733","64.8339","Afghanistan","AF","AFG","Helmand","minor","20000","1004067824"
"Dagohoy","Dagohoy","9.9167","124.2833","Philippines","PH","PHL","Bohol","","19874","1608161316"
"Schilde","Schilde","51.2333","4.5667","Belgium","BE","BEL","Flanders","","19585","1056448493"
"Kürten","Kurten","51.0500","7.2667","Germany","DE","DEU","North Rhine-Westphalia","","19832","1276494913"
"Fukagawa","Fukagawa","43.7233","142.0536","Japan","JP","JPN","Hokkaidō","","20086","1392710666"
"Udalguri","Udalguri","26.7537","92.1021","India","IN","IND","Assam","","19664","1356415781"
"Clifton","Clifton","39.0764","-108.4605","United States","US","USA","Colorado","","20122","1840017556"
"Bhulwāl","Bhulwal","32.8094","74.7683","India","IN","IND","Jammu and Kashmīr","","18873","1356300423"
"Dicholi","Dicholi","15.5932","73.9457","India","IN","IND","Goa","","19685","1356953406"
"Naranjos","Naranjos","21.3472","-97.6833","Mexico","MX","MEX","Veracruz","minor","20073","1484227797"
"Mairi","Mairi","-11.7108","-40.1489","Brazil","BR","BRA","Bahia","","20097","1076746756"
"’Ali Ben Sliman","'Ali Ben Sliman","31.9100","-7.2100","Morocco","MA","MAR","Marrakech-Safi","","19977","1504127885"
"San Martín de la Vega","San Martin de la Vega","40.2094","-3.5722","Spain","ES","ESP","Madrid","","19927","1724328645"
"Palm Valley","Palm Valley","30.2011","-81.3948","United States","US","USA","Florida","","20115","1840013971"
"Moaña","Moana","42.2833","-8.7500","Spain","ES","ESP","Galicia","","19496","1724505635"
"Mayfield Heights","Mayfield Heights","41.5174","-81.4534","United States","US","USA","Ohio","","20113","1840000613"
"Uetersen","Uetersen","53.6872","9.6692","Germany","DE","DEU","Schleswig-Holstein","","18496","1276507212"
"Nkoteng","Nkoteng","4.5167","12.0333","Cameroon","CM","CMR","Centre","","20100","1120935035"
"Aquidabã","Aquidaba","-10.2833","-37.0333","Brazil","BR","BRA","Sergipe","","20056","1076336875"
"Nesoddtangen","Nesoddtangen","59.8086","10.6556","Norway","NO","NOR","Viken","minor","19939","1578569253"
"Berlin","Berlin","41.6114","-72.7758","United States","US","USA","Connecticut","","20107","1840034026"
"Anagé","Anage","-14.6119","-41.1358","Brazil","BR","BRA","Bahia","","20096","1076575935"
"Şiran","Siran","40.1894","39.1267","Turkey","TR","TUR","Gümüşhane","minor","20084","1792645839"
"Tolú Viejo","Tolu Viejo","9.4500","-75.4500","Colombia","CO","COL","Sucre","minor","20033","1170864829"
"Shāhpur","Shahpur","25.6029","84.4041","India","IN","IND","Bihār","","19099","1356256618"
"Artik","Artik","40.6172","43.9758","Armenia","AM","ARM","Shirak","","19543","1051264283"
"Itarantim","Itarantim","-15.6600","-40.0658","Brazil","BR","BRA","Bahia","","20091","1076145914"
"Mölln","Molln","53.6269","10.6847","Germany","DE","DEU","Schleswig-Holstein","","19329","1276643502"
"Ambatosoratra","Ambatosoratra","-17.6167","48.5167","Madagascar","MG","MDG","Toamasina","","20000","1450886984"
"Guapiaçu","Guapiacu","-20.7950","-49.2200","Brazil","BR","BRA","São Paulo","","20037","1076397497"
"Montrose","Montrose","38.4689","-107.8590","United States","US","USA","Colorado","","20098","1840020251"
"Peshtera","Peshtera","42.0333","24.3000","Bulgaria","BG","BGR","Pazardzhik","minor","19877","1100868605"
"Adel","Adel","53.8489","-1.5849","United Kingdom","GB","GBR","Leeds","","19430","1826917092"
"San Marcos","San Marcos","14.4000","-88.9500","Honduras","HN","HND","Ocotepeque","","19978","1340516781"
"El Mansouria","El Mansouria","33.7500","-7.3000","Morocco","MA","MAR","Casablanca-Settat","","19853","1504714536"
"Faversham","Faversham","51.3177","0.8928","United Kingdom","GB","GBR","Kent","","19316","1826000093"
"Issaba","Issaba","7.0833","2.6167","Benin","BJ","BEN","Plateau","","19732","1204318038"
"Vasiana","Vasiana","-19.6333","46.2333","Madagascar","MG","MDG","Antananarivo","","19992","1450035563"
"Mandiavato","Mandiavato","-19.0833","47.0167","Madagascar","MG","MDG","Antananarivo","","20000","1450557492"
"Guaymango","Guaymango","13.7500","-89.8333","El Salvador","SV","SLV","Ahuachapán","","19775","1222351635"
"Slobozia","Slobozia","46.7333","29.7000","Moldova","MD","MDA","Stînga Nistrului","","20000","1498228098"
"Callosa de Segura","Callosa de Segura","38.1225","-0.8797","Spain","ES","ESP","Valencia","","19315","1724000256"
"Kwinana","Kwinana","-32.2394","115.7702","Australia","AU","AUS","Western Australia","","20086","1036753478"
"Montgomery","Montgomery","41.7237","-88.3633","United States","US","USA","Illinois","","20084","1840011350"
"Zhaodianzi","Zhaodianzi","39.9373","118.6401","China","CN","CHN","Hebei","","19766","1156421374"
"Makurazaki","Makurazaki","31.2728","130.2969","Japan","JP","JPN","Kagoshima","","19817","1392003174"
"Castillo de Teayo","Castillo de Teayo","20.7500","-97.6333","Mexico","MX","MEX","Veracruz","minor","20037","1484628176"
"Cassano d’Adda","Cassano d'Adda","45.5333","9.5167","Italy","IT","ITA","Lombardy","","19057","1380087487"
"Ochtrup","Ochtrup","52.2056","7.1903","Germany","DE","DEU","North Rhine-Westphalia","","19893","1276195349"
"Padre Las Casas","Padre Las Casas","18.7333","-70.9333","Dominican Republic","DO","DOM","Valdesia","","20041","1214352050"
"Glinde","Glinde","53.5406","10.2111","Germany","DE","DEU","Schleswig-Holstein","","18443","1276316044"
"Kébémer","Kebemer","15.3667","-16.4333","Senegal","SN","SEN","Louga","","20000","1686793316"
"Mohiuddinnagar","Mohiuddinnagar","25.5737","85.6694","India","IN","IND","Bihār","","18175","1356085626"
"Tsarabaria","Tsarabaria","-13.7500","49.9500","Madagascar","MG","MDG","Antsiranana","","20000","1450130867"
"Shirahama","Shirahama","33.6781","135.3481","Japan","JP","JPN","Wakayama","","19976","1392728798"
"Westbrook","Westbrook","43.6954","-70.3539","United States","US","USA","Maine","","20074","1840000329"
"Bachi-Yurt","Bachi-Yurt","43.2242","46.1942","Russia","RU","RUS","Chechnya","","19727","1643918793"
"Zhosaly","Zhosaly","45.4893","64.0915","Kazakhstan","KZ","KAZ","","","20065","1398510696"
"Zverevo","Zverevo","48.0200","40.1200","Russia","RU","RUS","Rostovskaya Oblast’","","19456","1643130170"
"Silla","Silla","39.3618","-0.4103","Spain","ES","ESP","Valencia","","19298","1724349109"
"Nārāyankher","Narayankher","18.0333","77.7833","India","IN","IND","Andhra Pradesh","","19620","1356845403"
"Zwijndrecht","Zwijndrecht","51.2167","4.3333","Belgium","BE","BEL","Flanders","","19002","1056749597"
"Shenandoah","Shenandoah","30.4019","-91.0020","United States","US","USA","Louisiana","","20068","1840031152"
"Liancourt","Liancourt","19.1356","-72.5333","Haiti","HT","HTI","Artibonite","","18574","1332300804"
"L’Assomption","L'Assomption","45.8333","-73.4167","Canada","CA","CAN","Quebec","","20065","1124500862"
"Nejo","Nejo","9.5000","35.5000","Ethiopia","ET","ETH","Oromīya","","19887","1231617295"
"Ban Tha Kham","Ban Tha Kham","9.1058","99.2325","Thailand","TH","THA","Surat Thani","","19939","1764400647"
"Shakhunya","Shakhunya","57.6667","46.6167","Russia","RU","RUS","Nizhegorodskaya Oblast’","","20049","1643622934"
"Alagir","Alagir","43.0333","44.2333","Russia","RU","RUS","North Ossetia","minor","20043","1643555747"
"Kanekallu","Kanekallu","14.8864","77.0317","India","IN","IND","Andhra Pradesh","","19731","1356028771"
"Krasnohrad","Krasnohrad","49.3719","35.4569","Ukraine","UA","UKR","Kharkivska Oblast","minor","20013","1804470896"
"Shōnai","Shonai","38.8499","139.9047","Japan","JP","JPN","Yamagata","","19977","1392999981"
"Soavina Antanety","Soavina Antanety","-19.8000","46.7333","Madagascar","MG","MDG","Antananarivo","","20000","1450021851"
"Carmo do Cajuru","Carmo do Cajuru","-20.1839","-44.7708","Brazil","BR","BRA","Minas Gerais","","20012","1076104600"
"Malabuyoc","Malabuyoc","9.6500","123.3333","Philippines","PH","PHL","Cebu","","19770","1608741221"
"Tarhzirt","Tarhzirt","32.4333","-6.1833","Morocco","MA","MAR","Béni Mellal-Khénifra","","19936","1504643080"
"Altagracia","Altagracia","11.5672","-85.5783","Nicaragua","NI","NIC","Rivas","minor","19955","1558219497"
"Tiruvādi","Tiruvadi","10.8805","79.1049","India","IN","IND","Tamil Nādu","","19154","1356934920"
"Sāmalāpuram","Samalapuram","11.0724","77.1980","India","IN","IND","Tamil Nādu","","19410","1356159517"
"Benicasim","Benicasim","40.0553","0.0642","Spain","ES","ESP","Valencia","","19507","1724830248"
"Yumbel","Yumbel","-37.1333","-72.5333","Chile","CL","CHL","Biobío","","20034","1152526139"
"Puerto Leguízamo","Puerto Leguizamo","-0.1939","-74.7806","Colombia","CO","COL","Putumayo","minor","20045","1170882938"
"Atlapexco","Atlapexco","21.0042","-98.5056","Mexico","MX","MEX","Hidalgo","","19812","1484002302"
"Valderrama","Valderrama","11.0036","122.1294","Philippines","PH","PHL","Antique","","19971","1608087263"
"Caridade","Caridade","-4.2319","-39.1928","Brazil","BR","BRA","Ceará","","20020","1076485540"
"South Burlington","South Burlington","44.4622","-73.2202","United States","US","USA","Vermont","","20042","1840002311"
"Golden","Golden","39.7406","-105.2118","United States","US","USA","Colorado","","20041","1840020197"
"Frankfort","Frankfort","41.4892","-87.8363","United States","US","USA","Illinois","","20040","1840011486"
"Nīlgiri","Nilgiri","21.6466","86.9199","India","IN","IND","Odisha","","19479","1356113149"
"Carira","Carira","-10.3581","-37.7008","Brazil","BR","BRA","Sergipe","","20007","1076945908"
"Hässleholm","Hassleholm","56.1667","13.7667","Sweden","SE","SWE","Skåne","minor","18500","1752526882"
"Hudson","Hudson","42.3887","-71.5465","United States","US","USA","Massachusetts","","20032","1840053480"
"La Esmeralda","La Esmeralda","3.1739","-65.5467","Venezuela","VE","VEN","Amazonas","minor","20030","1862255876"
"Casamassima","Casamassima","40.9500","16.9167","Italy","IT","ITA","Puglia","","19777","1380135275"
"Rantabe","Rantabe","-15.7000","49.6500","Madagascar","MG","MDG","Toamasina","","20000","1450394708"
"Ambalakirajy","Ambalakirajy","-15.8333","48.5000","Madagascar","MG","MDG","Mahajanga","","20000","1450763595"
"Canto do Buriti","Canto do Buriti","-8.1100","-42.9439","Brazil","BR","BRA","Piauí","","20020","1076214871"
"Analapatsy","Analapatsy","-24.2500","47.2000","Madagascar","MG","MDG","Toliara","","20000","1450179269"
"Midalam","Midalam","8.2167","77.2667","India","IN","IND","Tamil Nādu","","18788","1356216347"
"Ban Tha Ton","Ban Tha Ton","20.0608","99.3622","Thailand","TH","THA","Chiang Mai","","19902","1764205588"
"Mesquite","Mesquite","36.8032","-114.1330","United States","US","USA","Nevada","","20019","1840020362"
"Ampasimena","Ampasimena","-24.3667","47.1667","Madagascar","MG","MDG","Toliara","","20000","1450235896"
"Trostyanets’","Trostyanets'","50.4811","34.9646","Ukraine","UA","UKR","Sumska Oblast","","19985","1804845915"
"Haya","Haya","18.3461","36.3969","Sudan","SD","SDN","Red Sea","","20000","1729440799"
"Bulolo","Bulolo","-7.2000","146.6467","Papua New Guinea","PG","PNG","Morobe","","20000","1598883682"
"Andirá","Andira","-23.0508","-50.2289","Brazil","BR","BRA","Paraná","","19926","1076197418"
"Giszowiec","Giszowiec","50.2333","19.0667","Poland","PL","POL","Śląskie","","18475","1616451758"
"El Arenal","El Arenal","20.7754","-103.6935","Mexico","MX","MEX","Jalisco","minor","19900","1484970184"
"Jubbah","Jubbah","28.0200","40.9400","Saudi Arabia","SA","SAU","Ḩā’il","","20000","1682817897"
"Kāmākhyānagar","Kamakhyanagar","20.9338","85.5449","India","IN","IND","Odisha","","19803","1356115343"
"Whitehall","Whitehall","39.9682","-82.8833","United States","US","USA","Ohio","","20004","1840003764"
"Zemio","Zemio","5.0333","25.1333","Central African Republic","CF","CAF","Haut-Mbomou","","20000","1140178462"
"Calatayud","Calatayud","41.3500","-1.6333","Spain","ES","ESP","Aragon","","19870","1724454911"
"Nykøbing Falster","Nykobing Falster","54.7654","11.8755","Denmark","DK","DNK","Sjælland","minor","19976","1208336770"
"East Hemet","East Hemet","33.7301","-116.9410","United States","US","USA","California","","19998","1840028370"
"Cartavio","Cartavio","-7.8833","-79.2167","Peru","PE","PER","La Libertad","","19842","1604754726"
"Emet","Emet","39.3333","29.2667","Turkey","TR","TUR","Kütahya","minor","19986","1792855978"
"Törökszentmiklós","Torokszentmiklos","47.1833","20.4167","Hungary","HU","HUN","Jász-Nagykun-Szolnok","minor","19890","1348803732"
"Gebre Guracha","Gebre Guracha","9.8000","38.4000","Ethiopia","ET","ETH","Oromīya","","19830","1231514161"
"Rohār","Rohar","25.9917","86.2366","India","IN","IND","Bihār","","18526","1356047202"
"Tettnang","Tettnang","47.6708","9.5875","Germany","DE","DEU","Baden-Württemberg","","19710","1276775320"
"Ennigerloh","Ennigerloh","51.8367","8.0256","Germany","DE","DEU","North Rhine-Westphalia","","19829","1276421778"
"Ceglie Messapico","Ceglie Messapico","40.6500","17.5000","Italy","IT","ITA","Puglia","","19833","1380040929"
"Had Zraqtane","Had Zraqtane","31.4500","-7.4000","Morocco","MA","MAR","Marrakech-Safi","","19926","1504613352"
"Imperial","Imperial","32.8390","-115.5720","United States","US","USA","California","","19982","1840019381"
"Usman","Usman","52.0500","39.7333","Russia","RU","RUS","Lipetskaya Oblast’","","19956","1643486876"
"North Liberty","North Liberty","41.7438","-91.6112","United States","US","USA","Iowa","","19976","1840008179"
"Jhalidā","Jhalida","23.3654","85.9758","India","IN","IND","West Bengal","","19544","1356425223"
"Mulundo","Mulundo","7.9333","124.3833","Philippines","PH","PHL","Lanao del Sur","","19932","1608789953"
"Leek","Leek","53.1667","6.3833","Netherlands","NL","NLD","Groningen","minor","19669","1528583392"
"World Golf Village","World Golf Village","29.9654","-81.4896","United States","US","USA","Florida","","19975","1840039162"
"Sīāhkal","Siahkal","37.1528","49.8708","Iran","IR","IRN","Gīlān","minor","19924","1364607008"
"Mangualde","Mangualde","40.6042","-7.7611","Portugal","PT","PRT","Viseu","minor","19880","1620643689"
"Sleaford","Sleaford","52.9960","-0.4130","United Kingdom","GB","GBR","Lincolnshire","","19807","1826743491"
"Plattsburgh","Plattsburgh","44.6951","-73.4563","United States","US","USA","New York","","19969","1840000296"
"Wenden","Wenden","50.9667","7.8667","Germany","DE","DEU","North Rhine-Westphalia","","19701","1276958292"
"Uruburetama","Uruburetama","-3.6250","-39.5078","Brazil","BR","BRA","Ceará","","19765","1076255613"
"Hauppauge","Hauppauge","40.8211","-73.2109","United States","US","USA","New York","","19967","1840005077"
"Aït Tamlil","Ait Tamlil","31.4800","-6.9400","Morocco","MA","MAR","Béni Mellal-Khénifra","","19930","1504250838"
"Tomaszów Lubelski","Tomaszow Lubelski","50.4500","23.4167","Poland","PL","POL","Lubelskie","minor","18567","1616783498"
"Xiaqiaotou","Xiaqiaotou","27.2167","100.1500","China","CN","CHN","Yunnan","minor","19922","1156667293"
"Coruche","Coruche","38.9569","-8.5269","Portugal","PT","PRT","Santarém","minor","19944","1620431702"
"Quatro Barras","Quatro Barras","-25.3658","-49.0769","Brazil","BR","BRA","Paraná","","19851","1076060644"
"Olten","Olten","47.3500","7.9000","Switzerland","CH","CHE","Solothurn","","18362","1756558985"
"Bressuire","Bressuire","46.8400","-0.4886","France","FR","FRA","Nouvelle-Aquitaine","minor","19850","1250147584"
"Rovato","Rovato","45.5670","9.9986","Italy","IT","ITA","Lombardy","","19223","1380136759"
"El Paraíso","El Paraiso","15.0167","-88.9833","Honduras","HN","HND","Copán","","19882","1340000059"
"Morlanwelz-Mariemont","Morlanwelz-Mariemont","50.4500","4.2333","Belgium","BE","BEL","Wallonia","","19019","1056514399"
"Neviges","Neviges","51.3128","7.0869","Germany","DE","DEU","North Rhine-Westphalia","","18937","1276164931"
"Liuliang","Liuliang","35.2695","105.9860","China","CN","CHN","Gansu","","19582","1156214198"
"Salsomaggiore Terme","Salsomaggiore Terme","44.8148","9.9796","Italy","IT","ITA","Emilia-Romagna","","19710","1380396773"
"Rolla","Rolla","37.9459","-91.7607","United States","US","USA","Missouri","","19949","1840009849"
"São João Batista","Sao Joao Batista","-2.9550","-44.8069","Brazil","BR","BRA","Maranhão","","19920","1076543208"
"Doany","Doany","-14.3667","49.5167","Madagascar","MG","MDG","Antsiranana","","19928","1450623389"
"San Miguel","San Miguel","7.6500","123.2678","Philippines","PH","PHL","Zamboanga del Sur","","19838","1608922363"
"Allanridge","Allanridge","-27.7544","26.6367","South Africa","ZA","ZAF","Free State","","19337","1710862550"
"Poperinge","Poperinge","50.8547","2.7256","Belgium","BE","BEL","Flanders","","19782","1056648990"
"Blankenburg","Blankenburg","51.7953","10.9622","Germany","DE","DEU","Saxony-Anhalt","","19817","1276635033"
"Pecan Grove","Pecan Grove","29.6235","-95.7330","United States","US","USA","Texas","","19944","1840019645"
"Mathurāpur","Mathurapur","25.5112","86.4557","India","IN","IND","Bihār","","18247","1356304907"
"Quepos","Quepos","9.4571","-84.1387","Costa Rica","CR","CRI","Puntarenas","","19858","1188443023"
"Ennis","Ennis","32.3254","-96.6347","United States","US","USA","Texas","","19942","1840020754"
"Äänekoski","Aanekoski","62.6000","25.7250","Finland","FI","FIN","Keski-Suomi","minor","19919","1246710490"
"Gázi","Gazi","35.3250","25.0667","Greece","GR","GRC","Kríti","minor","19221","1300208558"
"Volketswil","Volketswil","47.3906","8.6953","Switzerland","CH","CHE","Zürich","","18615","1756554999"
"Forest Park","Forest Park","39.2861","-84.5258","United States","US","USA","Ohio","","19940","1840003818"
"Komárom","Komarom","47.7403","18.1244","Hungary","HU","HUN","Komárom-Esztergom","minor","19656","1348857421"
"Mapai","Mapai","-22.7306","32.0583","Mozambique","MZ","MOZ","Gaza","","19932","1508081096"
"Oregon","Oregon","41.6524","-83.4321","United States","US","USA","Ohio","","19935","1840000570"
"Sand Springs","Sand Springs","36.1353","-96.1283","United States","US","USA","Oklahoma","","19935","1840021671"
"Poshkent","Poshkent","39.8833","69.0333","Tajikistan","TJ","TJK","Sughd","","19746","1762717942"
"Pradópolis","Pradopolis","-21.3594","-48.0656","Brazil","BR","BRA","São Paulo","","19814","1076557864"
"Ba","Ba","-17.5333","177.6833","Fiji","FJ","FJI","Ba","","18526","1242193764"
"Ozorków","Ozorkow","51.9667","19.2833","Poland","PL","POL","Łódzkie","","18721","1616513470"
"Utebo","Utebo","41.7141","-0.9944","Spain","ES","ESP","Aragon","","18881","1724976095"
"Waldbröl","Waldbrol","50.8789","7.6150","Germany","DE","DEU","North Rhine-Westphalia","","19618","1276170251"
"Comapa","Comapa","19.1667","-96.8666","Mexico","MX","MEX","Veracruz","minor","19859","1484768761"
"Hasanparti","Hasanparti","18.0681","79.5236","India","IN","IND","Andhra Pradesh","","19450","1356821355"
"Amorebieta","Amorebieta","43.2192","-2.7342","Spain","ES","ESP","Basque Country","","19587","1724617538"
"Ban Na San","Ban Na San","8.7997","99.3635","Thailand","TH","THA","Surat Thani","minor","19801","1764021256"
"Hanumana","Hanumana","24.7789","82.0960","India","IN","IND","Madhya Pradesh","","19629","1356255385"
"Schmalkalden","Schmalkalden","50.7167","10.4500","Germany","DE","DEU","Thuringia","","19732","1276313755"
"Portland","Portland","27.8942","-97.3278","United States","US","USA","Texas","","19914","1840021006"
"Eschwege","Eschwege","51.1881","10.0528","Germany","DE","DEU","Hesse","minor","19606","1276348756"
"Kittūr","Kittur","15.6000","74.9000","India","IN","IND","Karnātaka","","19524","1356070622"
"Major Isidoro","Major Isidoro","-9.5319","-36.9850","Brazil","BR","BRA","Alagoas","","19864","1076827691"
"Lebbeke","Lebbeke","51.0000","4.1167","Belgium","BE","BEL","Flanders","","19195","1056292516"
"Yosano","Yosano","35.5653","135.1529","Japan","JP","JPN","Kyōto","","19726","1392003204"
"Vīrapāndi","Virapandi","9.9633","77.4472","India","IN","IND","Tamil Nādu","","18868","1356956354"
"Os","Os","60.2269","5.4758","Norway","NO","NOR","Vestland","minor","19742","1578224963"
"Signa","Signa","43.7833","11.1000","Italy","IT","ITA","Tuscany","","18901","1380504356"
"Brandsen","Brandsen","-35.1667","-58.2167","Argentina","AR","ARG","Buenos Aires","minor","19877","1032251860"
"Nottuln","Nottuln","51.9278","7.3542","Germany","DE","DEU","North Rhine-Westphalia","","19672","1276258837"
"Spoltore","Spoltore","42.4550","14.1399","Italy","IT","ITA","Abruzzo","","19378","1380636388"
"Nizhnyaya Tura","Nizhnyaya Tura","58.6208","59.8478","Russia","RU","RUS","Sverdlovskaya Oblast’","","19883","1643124513"
"Tevāram","Tevaram","9.8967","77.2809","India","IN","IND","Tamil Nādu","","19320","1356378133"
"Dęblin","Deblin","51.5667","21.8614","Poland","PL","POL","Lubelskie","","19513","1616913108"
"Mokena","Mokena","41.5327","-87.8781","United States","US","USA","Illinois","","19897","1840011491"
"Shafter","Shafter","35.4794","-119.2013","United States","US","USA","California","","19897","1840021735"
"Idhnā","Idhna","31.5586","34.9761","West Bank","XW","XWB","","","19012","1934524225"
"Hamina","Hamina","60.5667","27.2000","Finland","FI","FIN","Kymenlaakso","minor","19877","1246729258"
"Prunedale","Prunedale","36.8064","-121.6555","United States","US","USA","California","","19893","1840019020"
"Castilho","Castilho","-20.8722","-51.4875","Brazil","BR","BRA","São Paulo","","19873","1076553673"
"Santiago","Santiago","17.2947","120.4453","Philippines","PH","PHL","Ilocos Sur","","19471","1608306642"
"San Miguel","San Miguel","11.2942","124.8300","Philippines","PH","PHL","Leyte","","19753","1608453434"
"Saint-Lazare","Saint-Lazare","45.4000","-74.1333","Canada","CA","CAN","Quebec","","19889","1124000613"
"Kūysinjaq","Kuysinjaq","36.0833","44.6333","Iraq","IQ","IRQ","Arbīl","minor","19878","1368937180"
"Port Angeles","Port Angeles","48.1141","-123.4565","United States","US","USA","Washington","","19888","1840019782"
"Kinston","Kinston","35.2747","-77.5939","United States","US","USA","North Carolina","","19888","1840014585"
"Chestermere","Chestermere","51.0500","-113.8225","Canada","CA","CAN","Alberta","","19887","1124000371"
"Līkak","Likak","30.8950","50.0931","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","minor","19857","1364865107"
"Campamento","Campamento","14.5500","-86.6500","Honduras","HN","HND","Olancho","","19832","1340615985"
"Santo Amaro da Imperatriz","Santo Amaro da Imperatriz","-27.6878","-48.7789","Brazil","BR","BRA","Santa Catarina","","19823","1076813549"
"Jaggampeta","Jaggampeta","17.1833","82.0500","India","IN","IND","Andhra Pradesh","","18879","1356578072"
"Monroe","Monroe","47.8595","-121.9851","United States","US","USA","Washington","","19879","1840019791"
"Miamisburg","Miamisburg","39.6322","-84.2724","United States","US","USA","Ohio","","19878","1840008436"
"El Castillo de La Concepción","El Castillo de La Concepcion","11.0444","-84.4719","Nicaragua","NI","NIC","Río San Juan","","19864","1558240788"
"Sarstedt","Sarstedt","52.2394","9.8606","Germany","DE","DEU","Lower Saxony","","19423","1276490791"
"Cognac","Cognac","45.6958","-0.3292","France","FR","FRA","Nouvelle-Aquitaine","minor","18670","1250000336"
"Saint-Lô","Saint-Lo","49.1144","-1.0917","France","FR","FRA","Normandie","minor","19050","1250710011"
"Nīlambūr","Nilambur","11.0590","77.0838","India","IN","IND","Tamil Nādu","","19096","1356159587"
"Salangaippālaiyam","Salangaippalaiyam","11.4260","77.5707","India","IN","IND","Tamil Nādu","","19406","1356168592"
"Cajolá","Cajola","14.9252","-91.6160","Guatemala","GT","GTM","Quetzaltenango","minor","19333","1320101495"
"Sinacaban","Sinacaban","8.2854","123.8436","Philippines","PH","PHL","Misamis Occidental","","19671","1608859514"
"Carlisle","Carlisle","40.2000","-77.2034","United States","US","USA","Pennsylvania","","19869","1840001386"
"Pichor","Pichor","25.1756","78.1886","India","IN","IND","Madhya Pradesh","","19656","1356581375"
"Gubakha","Gubakha","58.8706","57.5933","Russia","RU","RUS","Permskiy Kray","","19831","1643737905"
"Southbury","Southbury","41.4745","-73.2329","United States","US","USA","Connecticut","","19866","1840035599"
"Atarfe","Atarfe","37.2229","-3.6899","Spain","ES","ESP","Andalusia","","19452","1724936353"
"Castellana Grotte","Castellana Grotte","40.8833","17.1667","Italy","IT","ITA","Puglia","","19582","1380352998"
"Ariccia","Ariccia","41.7167","12.6667","Italy","IT","ITA","Lazio","","18851","1380585218"
"Kuusankoski","Kuusankoski","60.9083","26.6236","Finland","FI","FIN","Kymenlaakso","","19831","1246302102"
"Mihona","Mihona","26.2837","78.9805","India","IN","IND","Madhya Pradesh","","19542","1356271843"
"Volokolamsk","Volokolamsk","56.0333","35.9500","Russia","RU","RUS","Moskovskaya Oblast’","","19824","1643346099"
"Cantagalo","Cantagalo","-21.9808","-42.3678","Brazil","BR","BRA","Rio de Janeiro","","19830","1076919346"
"Forest Park","Forest Park","33.6209","-84.3590","United States","US","USA","Georgia","","19856","1840013750"
"Arcore","Arcore","45.6333","9.3167","Italy","IT","ITA","Lombardy","","17916","1380749002"
"Puerto Armuelles","Puerto Armuelles","8.2833","-82.8667","Panama","PA","PAN","Chiriquí","minor","19763","1591562054"
"Nosy Varika","Nosy Varika","-20.5833","48.5333","Madagascar","MG","MDG","Fianarantsoa","","19787","1450317495"
"Quartier Morin","Quartier Morin","19.7000","-72.1500","Haiti","HT","HTI","Nord","","19241","1332258724"
"Mustang","Mustang","35.3917","-97.7246","United States","US","USA","Oklahoma","","19847","1840020421"
"Piratini","Piratini","-31.4478","-53.1039","Brazil","BR","BRA","Rio Grande do Sul","","19841","1076525146"
"Bhawānīpur","Bhawanipur","26.4361","84.7356","India","IN","IND","Bihār","","18384","1356931432"
"Buşrá ash Shām","Busra ash Sham","32.5167","36.4833","Syria","SY","SYR","Dar‘ā","minor","19683","1760158105"
"Barroso","Barroso","-21.1869","-43.9758","Brazil","BR","BRA","Minas Gerais","","19599","1076577038"
"Ystrad Mynach","Ystrad Mynach","51.6419","-3.2405","United Kingdom","GB","GBR","Caerphilly","","19204","1826498228"
"Echizen","Echizen","35.9742","136.1298","Japan","JP","JPN","Fukui","","19708","1392003538"
"Ranbīrsinghpura","Ranbirsinghpura","32.6079","74.7293","India","IN","IND","Jammu and Kashmīr","","18907","1356368404"
"Alhandra","Alhandra","-7.3504","-34.9284","Brazil","BR","BRA","Paraíba","","19727","1076719161"
"Sil-li","Sil-li","39.4880","125.4640","North Korea","KP","PRK","P’yŏngnam","","19463","1408623890"
"Arlington","Arlington","48.1701","-122.1442","United States","US","USA","Washington","","19832","1840018378"
"Pescia","Pescia","43.9017","10.6898","Italy","IT","ITA","Tuscany","","19584","1380269318"
"Coaraci","Coaraci","-14.6408","-39.5508","Brazil","BR","BRA","Bahia","","19770","1076386969"
"Nogales","Nogales","31.3624","-110.9336","United States","US","USA","Arizona","","19829","1840020823"
"Massy","Massy","41.0608","72.6328","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","19774","1417666567"
"Howard","Howard","44.5703","-88.0928","United States","US","USA","Wisconsin","","19828","1840002345"
"Kitatajima","Kitatajima","35.9821","139.4816","Japan","JP","JPN","Saitama","","19362","1392425295"
"Liuguang","Liuguang","26.9970","106.4376","China","CN","CHN","Guizhou","","19540","1156115106"
"Broadview Heights","Broadview Heights","41.3196","-81.6779","United States","US","USA","Ohio","","19820","1840000591"
"Masterton","Masterton","-40.9700","175.6500","New Zealand","NZ","NZL","Wellington","","19818","1554641049"
"Maple Shade","Maple Shade","39.9520","-74.9950","United States","US","USA","New Jersey","","19818","1840081625"
"Huntsville","Huntsville","45.3333","-79.2167","Canada","CA","CAN","Ontario","","19816","1124961145"
"Agryz","Agryz","56.5219","52.9975","Russia","RU","RUS","Tatarstan","","19794","1643220768"
"Burghausen","Burghausen","48.1667","12.8333","Germany","DE","DEU","Bavaria","","18862","1276305755"
"Bieruń","Bierun","50.1333","19.1000","Poland","PL","POL","Śląskie","minor","19334","1616014994"
"Salmon Creek","Salmon Creek","45.7099","-122.6632","United States","US","USA","Washington","","19809","1840018520"
"Sulzbach-Rosenberg","Sulzbach-Rosenberg","49.5000","11.7500","Germany","DE","DEU","Bavaria","","19414","1276541212"
"Guantiankan","Guantiankan","28.2966","106.6083","China","CN","CHN","Guizhou","","19665","1156099886"
"Lake Zurich","Lake Zurich","42.1956","-88.0870","United States","US","USA","Illinois","","19807","1840011160"
"Corner Brook","Corner Brook","48.9287","-57.9260","Canada","CA","CAN","Newfoundland and Labrador","","19806.00","1124244792"
"St. John","St. John","41.4429","-87.4697","United States","US","USA","Indiana","","19805","1840010221"
"Casa de Oro-Mount Helix","Casa de Oro-Mount Helix","32.7640","-116.9688","United States","US","USA","California","","19804","1840073904"
"Çekerek","Cekerek","40.0731","35.4947","Turkey","TR","TUR","Yozgat","minor","19786","1792189001"
"Ad Dir‘īyah","Ad Dir\`iyah","24.7333","46.5756","Saudi Arabia","SA","SAU","Ar Riyāḑ","","19143","1682421969"
"Zaouïa Aït Ishak","Zaouia Ait Ishak","32.7600","-5.7233","Morocco","MA","MAR","Béni Mellal-Khénifra","","19133","1504322304"
"Dillingen","Dillingen","48.5667","10.4667","Germany","DE","DEU","Bavaria","minor","19538","1276506212"
"Paulistana","Paulistana","-8.1439","-41.1500","Brazil","BR","BRA","Piauí","","19785","1076742398"
"Tingloy","Tingloy","13.6500","120.8667","Philippines","PH","PHL","Batangas","minor","19215","1608774107"
"Mandaguaçu","Mandaguacu","-23.3469","-52.0950","Brazil","BR","BRA","Paraná","","19781","1076000965"
"Mosopa","Mosopa","-24.7792","25.4183","Botswana","BW","BWA","Southern","","19780","1072143479"
"Grumo Nevano","Grumo Nevano","40.9333","14.2667","Italy","IT","ITA","Campania","","17939","1380731522"
"Sélestat","Selestat","48.2594","7.4542","France","FR","FRA","Grand Est","minor","19360","1250444960"
"Desavilakku","Desavilakku","11.6476","77.9670","India","IN","IND","Tamil Nādu","","18924","1356168701"
"Nyandoma","Nyandoma","61.6667","40.2167","Russia","RU","RUS","Arkhangel’skaya Oblast’","","19783","1643011175"
"Karcag","Karcag","47.3111","20.9161","Hungary","HU","HUN","Jász-Nagykun-Szolnok","minor","19732","1348514059"
"Alpinópolis","Alpinopolis","-20.8639","-46.3878","Brazil","BR","BRA","Minas Gerais","","19741","1076981643"
"Westminster","Westminster","39.5796","-77.0067","United States","US","USA","Maryland","","19783","1840005701"
"Maḩmūdābād Nemūneh","Mahmudabad Nemuneh","36.2886","49.9022","Iran","IR","IRN","Qazvīn","","19669","1364599190"
"Simonésia","Simonesia","-20.1239","-42.0008","Brazil","BR","BRA","Minas Gerais","","19736","1076773579"
"Pichidegua","Pichidegua","-34.3586","-71.2831","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","19714","1152025661"
"Jnane Bouih","Jnane Bouih","32.0308","-8.7894","Morocco","MA","MAR","Marrakech-Safi","","19706","1504889186"
"Mickleover","Mickleover","52.9010","-1.5520","United Kingdom","GB","GBR","Derby","","18000","1826627574"
"Karuizawa","Karuizawa","36.3484","138.5970","Japan","JP","JPN","Gunma","","19648","1392706783"
"Āsasa","Asasa","7.1000","39.1944","Ethiopia","ET","ETH","Oromīya","","19506","1231640244"
"Katangi","Katangi","21.7737","79.8051","India","IN","IND","Madhya Pradesh","","19487","1356150118"
"Arbaoun","Arbaoun","36.4667","5.6500","Algeria","DZ","DZA","Sétif","","19383","1012971347"
"Enkhuizen","Enkhuizen","52.7000","5.3000","Netherlands","NL","NLD","Noord-Holland","minor","18637","1528919863"
"Calvillo","Calvillo","21.8500","-102.7167","Mexico","MX","MEX","Aguascalientes","minor","19742","1484468847"
"Menzel Abderhaman","Menzel Abderhaman","37.2300","9.8600","Tunisia","TN","TUN","Bizerte","","19078","1788469824"
"Carate Brianza","Carate Brianza","45.7667","9.1833","Italy","IT","ITA","Lombardy","","17952","1380973816"
"Itatinga","Itatinga","-23.1017","-48.6158","Brazil","BR","BRA","São Paulo","","19738","1076066003"
"Brie-Comte-Robert","Brie-Comte-Robert","48.6925","2.6111","France","FR","FRA","Île-de-France","","18812","1250865551"
"Lower Allen","Lower Allen","40.2082","-76.9287","United States","US","USA","Pennsylvania","","19755","1840035428"
"Lakhna","Lakhna","25.4532","85.1431","India","IN","IND","Bihār","","18667","1356187398"
"Moosburg","Moosburg","48.4667","11.9333","Germany","DE","DEU","Bavaria","","19309","1276561909"
"Narsāpur","Narsapur","17.7374","78.2805","India","IN","IND","Andhra Pradesh","","19450","1356276951"
"Marsberg","Marsberg","51.4500","8.8333","Germany","DE","DEU","North Rhine-Westphalia","","19640","1276309670"
"Trzebinia","Trzebinia","50.1667","19.4833","Poland","PL","POL","Małopolskie","","19134","1616296505"
"Punjai Puliyampatti","Punjai Puliyampatti","11.3516","77.1667","India","IN","IND","Tamil Nādu","","19578","1356455477"
"Casalgrande","Casalgrande","44.5833","10.7333","Italy","IT","ITA","Emilia-Romagna","","19234","1380213899"
"Culpeper","Culpeper","38.4704","-78.0001","United States","US","USA","Virginia","","19740","1840006169"
"Tradate","Tradate","45.7000","8.9167","Italy","IT","ITA","Lombardy","","18861","1380890571"
"Regente Feijó","Regente Feijo","-22.2208","-51.3028","Brazil","BR","BRA","São Paulo","","19733","1076508194"
"Wanluan","Wanluan","22.5727","120.5678","Taiwan","TW","TWN","Pingtung","","19418","1158436916"
"Borna","Borna","51.1167","12.5000","Germany","DE","DEU","Saxony","","19425","1276020510"
"Várpalota","Varpalota","47.1989","18.1376","Hungary","HU","HUN","Veszprém","minor","19484","1348052599"
"Río Segundo","Rio Segundo","-31.6501","-63.9116","Argentina","AR","ARG","Córdoba","","19713","1032477999"
"Wittlich","Wittlich","49.9869","6.8897","Germany","DE","DEU","Rhineland-Palatinate","minor","19345","1276069973"
"Vazante","Vazante","-17.9869","-46.9078","Brazil","BR","BRA","Minas Gerais","","19723","1076073984"
"Oschersleben","Oschersleben","52.0167","11.2500","Germany","DE","DEU","Saxony-Anhalt","","19630","1276686337"
"Hongshui","Hongshui","38.5077","100.8814","China","CN","CHN","Gansu","minor","19718","1156311485"
"Taketa","Taketa","32.9667","131.4000","Japan","JP","JPN","Ōita","","19688","1392003396"
"Andaingo Gara","Andaingo Gara","-18.2000","48.2833","Madagascar","MG","MDG","Toamasina","","19656","1450562250"
"Ayyāmpettai","Ayyampettai","10.8971","79.1915","India","IN","IND","Tamil Nādu","","18759","1356246240"
"Luénoufla","Luenoufla","7.0667","-6.2500","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","","19643","1384556975"
"Rybnoye","Rybnoye","54.7333","39.5167","Russia","RU","RUS","Ryazanskaya Oblast’","","19700","1643910725"
"Suárez","Suarez","2.9589","-76.6953","Colombia","CO","COL","Cauca","minor","19690","1170553127"
"Tash-Kömür","Tash-Komur","41.3500","72.2167","Kyrgyzstan","KG","KGZ","Jalal-Abad","","19310","1417242124"
"Polonuevo","Polonuevo","10.7833","-74.8667","Colombia","CO","COL","Atlántico","minor","19454","1170997382"
"Colotlán","Colotlan","22.1000","-103.2833","Mexico","MX","MEX","Jalisco","minor","19689","1484670065"
"Homewood","Homewood","41.5591","-87.6610","United States","US","USA","Illinois","","19716","1840011264"
"Meise","Meise","50.9333","4.3333","Belgium","BE","BEL","Flanders","","19164","1056974860"
"Eupen","Eupen","50.6333","6.0333","Belgium","BE","BEL","Wallonia","","19526","1056584006"
"Talwandi Bhai","Talwandi Bhai","30.8558","74.9298","India","IN","IND","Punjab","","19236","1356157945"
"Babanūsah","Babanusah","11.3333","27.8000","Sudan","SD","SDN","West Kordofan","","19700","1729601692"
"Stroud","Stroud","41.0001","-75.2173","United States","US","USA","Pennsylvania","","19711","1840144158"
"Kufstein","Kufstein","47.5833","12.1667","Austria","AT","AUT","Tirol","minor","19223","1040156374"
"Shelbyville","Shelbyville","39.5352","-85.7793","United States","US","USA","Indiana","","19710","1840009561"
"Comodoro","Comodoro","-13.6593","-59.7941","Brazil","BR","BRA","Mato Grosso","","19702","1076826121"
"Chopinzinho","Chopinzinho","-25.8558","-52.5228","Brazil","BR","BRA","Paraná","","19679","1076299334"
"Puerto El Triunfo","Puerto El Triunfo","13.2833","-88.5500","El Salvador","SV","SLV","Usulután","","19600","1222465303"
"Doujing","Doujing","26.4789","105.1253","China","CN","CHN","Guizhou","","19563","1156192512"
"Lackawanna","Lackawanna","42.8182","-78.8325","United States","US","USA","New York","","19697","1840000387"
"Lieto","Lieto","60.5000","22.4497","Finland","FI","FIN","Varsinais-Suomi","minor","19596","1246293271"
"Ban Bueng","Ban Bueng","13.3142","101.1114","Thailand","TH","THA","Chon Buri","minor","19346","1764134032"
"Ciriè","Cirie","45.2353","7.6003","Italy","IT","ITA","Piedmont","","18639","1380920795"
"Santa Teresita","Santa Teresita","18.2519","121.9100","Philippines","PH","PHL","Cagayan","","19573","1608448920"
"Rhar el Melah","Rhar el Melah","37.1667","10.1833","Tunisia","TN","TUN","Bizerte","","19477","1788960922"
"Urumita","Urumita","10.5667","-73.0167","Colombia","CO","COL","La Guajira","minor","19629","1170312888"
"Anguillara Sabazia","Anguillara Sabazia","42.0833","12.2667","Italy","IT","ITA","Lazio","","19426","1380136899"
"Nakło nad Notecią","Naklo nad Notecia","53.1403","17.5928","Poland","PL","POL","Kujawsko-Pomorskie","minor","19507","1616313608"
"Englewood","Englewood","26.9717","-82.3524","United States","US","USA","Florida","","19683","1840013127"
"Bad Tölz","Bad Tolz","47.7603","11.5567","Germany","DE","DEU","Bavaria","minor","19061","1276892151"
"Dharmsāla","Dharmsala","32.2153","76.3186","India","IN","IND","Himāchal Pradesh","","19034","1356618034"
"Universal City","Universal City","29.5521","-98.3074","United States","US","USA","Texas","","19679","1840022225"
"Žiar nad Hronom","Ziar nad Hronom","48.5842","18.8608","Slovakia","SK","SVK","Banská Bystrica","minor","19188","1703196559"
"Sarsāwa","Sarsawa","30.0160","77.4000","India","IN","IND","Uttar Pradesh","","18956","1356067521"
"Oulad Salmane","Oulad Salmane","34.3356","-6.4604","Morocco","MA","MAR","Rabat-Salé-Kénitra","","19488","1504113014"
"Jakobstad","Jakobstad","63.6667","22.7000","Finland","FI","FIN","Pohjanmaa","minor","19436","1246775062"
"Pôrto Grande","Porto Grande","0.7128","-51.4128","Brazil","BR","BRA","Amapá","","19669","1076117009"
"Yuanhucun","Yuanhucun","44.1977","86.8928","China","CN","CHN","Xinjiang","","19650","1156995696"
"Someren","Someren","51.3833","5.7167","Netherlands","NL","NLD","Noord-Brabant","minor","19428","1528912207"
"Gilching","Gilching","48.1103","11.3011","Germany","DE","DEU","Bavaria","","19065","1276366284"
"Cabusao","Cabusao","13.7275","123.1142","Philippines","PH","PHL","Camarines Sur","","19257","1608781251"
"Mayen","Mayen","50.3333","7.2167","Germany","DE","DEU","Rhineland-Palatinate","","19335","1276174509"
"Riverview","Riverview","46.0613","-64.8052","Canada","CA","CAN","New Brunswick","","19667","1124000112"
"Konstantynów Łódzki","Konstantynow Lodzki","51.7500","19.3333","Poland","PL","POL","Łódzkie","","18960","1616340948"
"Durağan","Duragan","41.4167","35.0500","Turkey","TR","TUR","Sinop","minor","19650","1792057871"
"Arbutus","Arbutus","39.2428","-76.6922","United States","US","USA","Maryland","","19662","1840005676"
"Holden","Holden","42.3561","-71.8608","United States","US","USA","Massachusetts","","19659","1840053588"
"Tīrthahalli","Tirthahalli","13.6884","75.2455","India","IN","IND","Karnātaka","","19546","1356259197"
"Lajinha","Lajinha","-20.1508","-41.6228","Brazil","BR","BRA","Minas Gerais","","19609","1076832869"
"Majhua","Majhua","26.0140","87.3018","India","IN","IND","Bihār","","18590","1356153023"
"Barreira","Barreira","-4.2869","-38.6428","Brazil","BR","BRA","Ceará","","19573","1076309909"
"Tirmitine","Tirmitine","36.6618","3.9848","Algeria","DZ","DZA","Tizi Ouzou","","19027","1012932001"
"Yahotyn","Yahotyn","50.2569","31.7817","Ukraine","UA","UKR","Kyivska Oblast","","19613","1804447594"
"Capoeiras","Capoeiras","-8.7350","-36.6269","Brazil","BR","BRA","Pernambuco","","19593","1076140888"
"Ait Bousarane","Ait Bousarane","31.7917","-7.0926","Morocco","MA","MAR","Béni Mellal-Khénifra","","19501","1504791291"
"Andrychów","Andrychow","49.8550","19.3414","Poland","PL","POL","Małopolskie","","19203","1616553516"
"Hushihacun","Hushihacun","40.8899","116.9870","China","CN","CHN","Hebei","","19495","1156575613"
"Kopa","Kopa","25.8513","84.6308","India","IN","IND","Bihār","","18080","1356133392"
"Weil der Stadt","Weil der Stadt","48.7508","8.8706","Germany","DE","DEU","Baden-Württemberg","","19205","1276534554"
"Waxhaw","Waxhaw","34.9363","-80.7439","United States","US","USA","North Carolina","","19645","1840016458"
"Prichard","Prichard","30.7735","-88.1301","United States","US","USA","Alabama","","19645","1840014990"
"Lloydminster","Lloydminster","53.2807","-110.0350","Canada","CA","CAN","Alberta","","19645.00","1124000858"
"Pugo","Pugo","16.2833","120.4833","Philippines","PH","PHL","La Union","","19337","1608886606"
"Alamo","Alamo","26.1810","-98.1177","United States","US","USA","Texas","","19644","1840019732"
"Gunri","Gunri","25.6408","84.6580","India","IN","IND","Bihār","","18726","1356349640"
"Venadillo","Venadillo","4.7500","-74.9167","Colombia","CO","COL","Tolima","minor","19586","1170931543"
"Rizal","Rizal","17.5000","121.6000","Philippines","PH","PHL","Kalinga","","19554","1608640536"
"West Mifflin","West Mifflin","40.3581","-79.9073","United States","US","USA","Pennsylvania","","19638","1840001273"
"Keszthely","Keszthely","46.7696","17.2481","Hungary","HU","HUN","Zala","minor","19387","1348430852"
"Indargarh","Indargarh","25.9109","78.5619","India","IN","IND","Madhya Pradesh","","19268","1356570714"
"Jagalūr","Jagalur","14.5196","76.3391","India","IN","IND","Karnātaka","","19462","1356605315"
"Eersel","Eersel","51.3500","5.3167","Netherlands","NL","NLD","Noord-Brabant","minor","19528","1528475270"
"Kasba Maker","Kasba Maker","25.9591","85.0316","India","IN","IND","Bihār","","18267","1356500077"
"Manjathala","Manjathala","11.3624","76.7789","India","IN","IND","Tamil Nādu","","19373","1356364137"
"Malacky","Malacky","48.4358","17.0178","Slovakia","SK","SVK","Bratislava","minor","18935","1703320479"
"Kalghatgi","Kalghatgi","15.1832","74.9710","India","IN","IND","Karnātaka","","19377","1356966430"
"Rāwah","Rawah","34.4686","41.9167","Iraq","IQ","IRQ","Al Anbār","minor","19629","1368442139"
"Ranst","Ranst","51.2000","4.5500","Belgium","BE","BEL","Flanders","","19187","1056004847"
"Alzey","Alzey","49.7458","8.1153","Germany","DE","DEU","Rhineland-Palatinate","minor","19082","1276680173"
"Middelkerke","Middelkerke","51.1847","2.8192","Belgium","BE","BEL","Flanders","","19371","1056231349"
"Monte Alegre de Minas","Monte Alegre de Minas","-18.8708","-48.8808","Brazil","BR","BRA","Minas Gerais","","19619","1076152420"
"Cherepanovo","Cherepanovo","54.2333","83.3667","Russia","RU","RUS","Novosibirskaya Oblast’","","19610","1643646272"
"Okhmalynka","Okhmalynka","47.5333","29.4500","Ukraine","UA","UKR","Odeska Oblast","","19606","1804223828"
"Nanuet","Nanuet","41.0957","-74.0155","United States","US","USA","New York","","19624","1840004963"
"Utinga","Utinga","-12.0819","-41.0939","Brazil","BR","BRA","Bahia","","19593","1076977689"
"Sint-Gillis-Waas","Sint-Gillis-Waas","51.2167","4.1167","Belgium","BE","BEL","Flanders","","19273","1056149557"
"Sparta","Sparta","41.0486","-74.6264","United States","US","USA","New Jersey","","19621","1840081784"
"Joliette","Joliette","46.0167","-73.4500","Canada","CA","CAN","Quebec","","19621","1124841554"
"Mogiyon","Mogiyon","39.2500","67.6500","Tajikistan","TJ","TJK","Sughd","","19553","1762342219"
"Staveley","Staveley","53.2694","-1.3484","United Kingdom","GB","GBR","Derbyshire","","18247","1826136696"
"Tublay","Tublay","16.4764","120.6333","Philippines","PH","PHL","Benguet","","19429","1608250449"
"Ban Thap Kwang","Ban Thap Kwang","14.6103","101.0753","Thailand","TH","THA","Saraburi","","19425","1764264191"
"Midway","Midway","30.4169","-87.0229","United States","US","USA","Florida","","19617","1840143881"
"Atasū","Atasu","48.6903","71.6499","Kazakhstan","KZ","KAZ","","","19616","1398926180"
"Bückeburg","Buckeburg","52.2608","9.0492","Germany","DE","DEU","Lower Saxony","","19336","1276508515"
"Lodhwe","Lodhwe","24.6404","85.2913","India","IN","IND","Bihār","","18520","1356743921"
"Tolentino","Tolentino","43.2086","13.2841","Italy","IT","ITA","Marche","","19409","1380100386"
"Hechingen","Hechingen","48.3517","8.9633","Germany","DE","DEU","Baden-Württemberg","","19324","1276884131"
"Diinsoor","Diinsoor","2.4106","42.9767","Somalia","SO","SOM","Bay","","19600","1706924480"
"Ansermanuevo","Ansermanuevo","4.8000","-76.0000","Colombia","CO","COL","Valle del Cauca","minor","19557","1170498489"
"Rožňava","Roznava","48.6583","20.5308","Slovakia","SK","SVK","Košice","minor","19190","1703307007"
"Khamaria","Khamaria","23.2129","80.0313","India","IN","IND","Madhya Pradesh","","19191","1356078635"
"Dāchepalle","Dachepalle","16.6000","79.7333","India","IN","IND","Andhra Pradesh","","19042","1356157736"
"Crespo","Crespo","-32.0333","-60.3167","Argentina","AR","ARG","Entre Ríos","","19536","1032701663"
"Adria","Adria","45.0500","12.0500","Italy","IT","ITA","Veneto","","19436","1380910667"
"Palera","Palera","25.0201","79.2282","India","IN","IND","Madhya Pradesh","","19314","1356324036"
"Riom","Riom","45.8936","3.1125","France","FR","FRA","Auvergne-Rhône-Alpes","minor","19011","1250311050"
"Kolbermoor","Kolbermoor","47.8500","12.0667","Germany","DE","DEU","Bavaria","","18662","1276583234"
"Diepenbeek","Diepenbeek","50.9072","5.4175","Belgium","BE","BEL","Flanders","","19137","1056573577"
"Nocatee","Nocatee","30.0927","-81.4095","United States","US","USA","Florida","","19601","1840028805"
"Xapuri","Xapuri","-10.6519","-68.5039","Brazil","BR","BRA","Acre","","19596","1076525025"
"Yershov","Yershov","51.3500","48.2667","Russia","RU","RUS","Saratovskaya Oblast’","","19590","1643218689"
"Engelskirchen","Engelskirchen","50.9833","7.4167","Germany","DE","DEU","North Rhine-Westphalia","","19293","1276315673"
"Heinola","Heinola","61.2000","26.0333","Finland","FI","FIN","Päijät-Häme","minor","19575","1246846423"
"Capela do Alto","Capela do Alto","-23.4706","-47.7347","Brazil","BR","BRA","São Paulo","","19482","1076545027"
"Meerssen","Meerssen","50.8858","5.7519","Netherlands","NL","NLD","Limburg","minor","18923","1528383946"
"Bad Pyrmont","Bad Pyrmont","51.9867","9.2636","Germany","DE","DEU","Lower Saxony","","19285","1276800450"
"Bad Aibling","Bad Aibling","47.8667","12.0167","Germany","DE","DEU","Bavaria","","19164","1276844408"
"El Arahal","El Arahal","37.2667","-5.5500","Spain","ES","ESP","Andalusia","","19497","1724678120"
"Hemmingen","Hemmingen","52.3236","9.7256","Germany","DE","DEU","Lower Saxony","","18998","1276579217"
"Caraúbas","Caraubas","-5.7928","-37.5569","Brazil","BR","BRA","Rio Grande do Norte","","19576","1076497520"
"Pidhorodne","Pidhorodne","48.5737","35.0966","Ukraine","UA","UKR","Dnipropetrovska Oblast","","19524","1804891177"
"Candelaria","Candelaria","22.7439","-82.9581","Cuba","CU","CUB","Artemisa","minor","19523","1192962484"
"Acıgöl","Acigol","38.5500","34.5167","Turkey","TR","TUR","Nevşehir","minor","19561","1792587521"
"Jasien","Jasien","54.3399","18.5600","Poland","PL","POL","Pomorskie","","18014","1616135118"
"Sokołów Podlaski","Sokolow Podlaski","52.4000","22.2500","Poland","PL","POL","Mazowieckie","minor","18526","1616559747"
"Jājarm","Jajarm","36.9500","56.3800","Iran","IR","IRN","Khorāsān-e Shomālī","minor","19580","1364569834"
"Pastrana","Pastrana","11.1333","124.8833","Philippines","PH","PHL","Leyte","","19359","1608924926"
"Rhede","Rhede","51.8333","6.7006","Germany","DE","DEU","North Rhine-Westphalia","","19336","1276000350"
"Aysha","Aysha","10.7500","42.5667","Ethiopia","ET","ETH","Sumalē","","19570","1231232970"
"Garot","Garot","24.3234","75.6520","India","IN","IND","Madhya Pradesh","","19231","1356870618"
"Ituzaingó","Ituzaingo","-27.6000","-56.6667","Argentina","AR","ARG","Corrientes","minor","19575","1032176829"
"Buruanga","Buruanga","11.8438","121.8888","Philippines","PH","PHL","Aklan","","19357","1608311797"
"Meadowbrook","Meadowbrook","37.4301","-77.4740","United States","US","USA","Virginia","","19574","1840024786"
"Stowmarket","Stowmarket","52.1900","1.0000","United Kingdom","GB","GBR","Suffolk","","19280","1826085574"
"Hoxut","Hoxut","42.2552","86.8607","China","CN","CHN","Xinjiang","minor","19520","1156176924"
"Puerto Morelos","Puerto Morelos","20.8536","-86.8753","Mexico","MX","MEX","Quintana Roo","","19205","1484801229"
"Altamont","Altamont","42.1980","-121.7248","United States","US","USA","Oregon","","19569","1840017440"
"Yellowknife","Yellowknife","62.4709","-114.4053","Canada","CA","CAN","Northwest Territories","admin","19569.00","1124208917"
"Ibicoara","Ibicoara","-13.4108","-41.2850","Brazil","BR","BRA","Bahia","","19548","1076421535"
"Arvin","Arvin","35.1944","-118.8306","United States","US","USA","California","","19568","1840019147"
"Essen","Essen","51.4667","4.4667","Belgium","BE","BEL","Flanders","","19162","1056694919"
"Monkseaton","Monkseaton","55.0430","-1.4590","United Kingdom","GB","GBR","North Tyneside","","19044","1826239698"
"Otsego","Otsego","45.2660","-93.6201","United States","US","USA","Minnesota","","19565","1840008899"
"Fukusaki","Fukusaki","34.9503","134.7603","Japan","JP","JPN","Hyōgo","","19143","1392003495"
"Xihuangcun","Xihuangcun","37.1411","114.2293","China","CN","CHN","Hebei","","19403","1156541440"
"São João do Piauí","Sao Joao do Piaui","-8.3578","-42.2469","Brazil","BR","BRA","Piauí","","19548","1076131046"
"Haiger","Haiger","50.7422","8.2039","Germany","DE","DEU","Hesse","","19378","1276810840"
"Töle Bī","Tole Bi","43.6768","73.7620","Kazakhstan","KZ","KAZ","","","19551","1398035809"
"São Gabriel","Sao Gabriel","-11.2289","-41.9119","Brazil","BR","BRA","Bahia","","19542","1076679834"
"Waterford","Waterford","41.3692","-72.1483","United States","US","USA","Connecticut","","19558","1840035649"
"Siruma","Siruma","14.0219","123.2589","Philippines","PH","PHL","Camarines Sur","","19419","1608719782"
"Hunters Creek","Hunters Creek","28.3610","-81.4358","United States","US","USA","Florida","","19554","1840028986"
"Bagaces","Bagaces","10.5157","-85.2478","Costa Rica","CR","CRI","Guanacaste","","19536","1188033610"
"Rolante","Rolante","-29.6508","-50.5758","Brazil","BR","BRA","Rio Grande do Sul","","19485","1076821052"
"Valle Vista","Valle Vista","33.7436","-116.8871","United States","US","USA","California","","19550","1840019292"
"Thamaga","Thamaga","-24.7167","25.5333","Botswana","BW","BWA","Kweneng","","19547","1072258632"
"Terek","Terek","43.4833","44.1333","Russia","RU","RUS","Kabardino-Balkariya","","19494","1643162208"
"Aradíppou","Aradippou","34.9478","33.5881","Cyprus","CY","CYP","Lárnaka","","19199","1196144891"
"Beloyarskiy","Beloyarskiy","63.7167","66.6667","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","19542","1643836632"
"Ashland","Ashland","40.8668","-82.3156","United States","US","USA","Ohio","","19544","1840002751"
"Ibigawa","Ibigawa","35.4873","136.5686","Japan","JP","JPN","Gifu","","19519","1392003088"
"Grafton","Grafton","42.2085","-71.6838","United States","US","USA","Massachusetts","","19540","1840053690"
"West Whiteland","West Whiteland","40.0227","-75.6239","United States","US","USA","Pennsylvania","","19539","1840146650"
"Bābai","Babai","22.7026","77.9349","India","IN","IND","Madhya Pradesh","","19260","1356891203"
"Sjöbo","Sjobo","55.6333","13.7167","Sweden","SE","SWE","Skåne","minor","19497","1752520046"
"Bruz","Bruz","48.0247","-1.7458","France","FR","FRA","Bretagne","","18905","1250149956"
"Madisonville","Madisonville","37.3409","-87.5034","United States","US","USA","Kentucky","","19533","1840015244"
"Cervantes","Cervantes","16.9917","120.7333","Philippines","PH","PHL","Ilocos Sur","","19449","1608265222"
"Seesen","Seesen","51.8931","10.1783","Germany","DE","DEU","Lower Saxony","","19340","1276914120"
"Kumārapuram","Kumarapuram","8.2922","77.3215","India","IN","IND","Tamil Nādu","","18398","1356201550"
"Valavanūr","Valavanur","11.9205","79.5785","India","IN","IND","Tamil Nādu","","18589","1356608642"
"Radeberg","Radeberg","51.1167","13.9167","Germany","DE","DEU","Saxony","","18891","1276815602"
"Müllheim","Mullheim","47.8000","7.6333","Germany","DE","DEU","Baden-Württemberg","","19188","1276925185"
"Stevenson Ranch","Stevenson Ranch","34.3894","-118.5883","United States","US","USA","California","","19518","1840076281"
"Somandepalle","Somandepalle","14.0078","77.6086","India","IN","IND","Andhra Pradesh","","18895","1356300184"
"Rio Verde de Mato Grosso","Rio Verde de Mato Grosso","-18.9178","-54.8439","Brazil","BR","BRA","Mato Grosso do Sul","","19515","1076408568"
"Capinota","Capinota","-17.7150","-66.2636","Bolivia","BO","BOL","Cochabamba","","19477","1068429998"
"Yusufeli","Yusufeli","40.8106","41.5269","Turkey","TR","TUR","Artvin","minor","19510","1792772140"
"Sirvār","Sirvar","16.1739","77.0225","India","IN","IND","Karnātaka","","19295","1356081620"
"Bad Berleburg","Bad Berleburg","51.0497","8.4000","Germany","DE","DEU","North Rhine-Westphalia","","19446","1276128056"
"Squamish","Squamish","49.7017","-123.1589","Canada","CA","CAN","British Columbia","","19512","1124005958"
"Kempston","Kempston","52.1140","-0.4970","United Kingdom","GB","GBR","Bedford","","19440","1826007274"
"Pułtusk","Pultusk","52.7000","21.0833","Poland","PL","POL","Mazowieckie","minor","19327","1616411619"
"Warrensburg","Warrensburg","38.7627","-93.7260","United States","US","USA","Missouri","","19509","1840010721"
"Jesús María","Jesus Maria","20.6068","-102.2230","Mexico","MX","MEX","Jalisco","minor","19469","1484588871"
"Teminabuan","Teminabuan","-1.4333","132.0167","Indonesia","ID","IDN","Papua Barat","minor","19491","1360620056"
"Traiskirchen","Traiskirchen","48.0189","16.2922","Austria","AT","AUT","Niederösterreich","","18858","1040581289"
"Erlanger","Erlanger","39.0109","-84.5864","United States","US","USA","Kentucky","","19506","1840013168"
"Espiritu","Espiritu","17.9806","120.6544","Philippines","PH","PHL","Ilocos Norte","","19297","1608000475"
"Marihatag","Marihatag","8.8008","126.2983","Philippines","PH","PHL","Surigao del Sur","","19441","1608424422"
"Chinna Ganjām","Chinna Ganjam","15.6930","80.2405","India","IN","IND","Andhra Pradesh","","19060","1356286805"
"Regensdorf","Regensdorf","47.4333","8.4667","Switzerland","CH","CHE","Zürich","","18253","1756820070"
"Whitemarsh","Whitemarsh","40.1040","-75.2483","United States","US","USA","Pennsylvania","","19501","1840102027"
"Hurricane","Hurricane","37.1487","-113.3517","United States","US","USA","Utah","","19501","1840018936"
"Pūranpur","Puranpur","28.5200","80.1500","India","IN","IND","Uttar Pradesh","","19042","1356012806"
"Heishanzuicun","Heishanzuicun","41.0354","116.9304","China","CN","CHN","Hebei","","19370","1156450538"
"Los Álamos","Los Alamos","-37.6272","-73.4619","Chile","CL","CHL","Biobío","","19494","1152738427"
"Alcoy","Alcoy","9.7082","123.5060","Philippines","PH","PHL","Cebu","","19186","1608968544"
"Bužim","Buzim","45.0500","16.0333","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","19340","1070103520"
"Orinda","Orinda","37.8808","-122.1790","United States","US","USA","California","","19497","1840020280"
"Holtsville","Holtsville","40.8124","-73.0447","United States","US","USA","New York","","19497","1840005079"
"Sorada","Sorada","19.7608","84.4300","India","IN","IND","Odisha","","19335","1356002782"
"La Nucía","La Nucia","38.6172","-0.1231","Spain","ES","ESP","Valencia","","18624","1724405068"
"Ardon","Ardon","43.1833","44.3167","Russia","RU","RUS","North Ossetia","","19412","1643992333"
"Hoveyzeh","Hoveyzeh","31.4619","48.0739","Iran","IR","IRN","Khūzestān","minor","19481","1364073949"
"Magitang","Magitang","35.9386","102.0297","China","CN","CHN","Qinghai","minor","19226","1156042270"
"Echt","Echt","51.1000","5.8833","Netherlands","NL","NLD","Limburg","minor","19237","1528492944"
"Gogogogo","Gogogogo","-24.2667","44.8000","Madagascar","MG","MDG","Toliara","","19454","1450629172"
"Palamós","Palamos","41.8458","3.1289","Spain","ES","ESP","Catalonia","","18196","1724685553"
"Gōdo","Godo","35.4174","136.6085","Japan","JP","JPN","Gifu","","18505","1392043863"
"Jiabong","Jiabong","11.7625","124.9519","Philippines","PH","PHL","Samar","","19205","1608853752"
"Yandrapalle","Yandrapalle","15.9062","79.3244","India","IN","IND","Andhra Pradesh","","19398","1356545849"
"Kantharalak","Kantharalak","14.6536","104.6278","Thailand","TH","THA","Si Sa Ket","minor","19392","1764009547"
"Heishuikeng","Heishuikeng","37.7905","110.5711","China","CN","CHN","Shaanxi","","19357","1156825716"
"Yumurtalık","Yumurtalik","36.7678","35.7922","Turkey","TR","TUR","Adana","minor","18587","1792054257"
"Ramsbottom","Ramsbottom","53.6484","-2.3169","United Kingdom","GB","GBR","Bury","","17872","1826959328"
"Tadian","Tadian","16.9961","120.8208","Philippines","PH","PHL","Mountain","","19341","1608338407"
"East Dereham","East Dereham","52.6810","0.9400","United Kingdom","GB","GBR","Norfolk","","18609","1826674740"
"Łańcut","Lancut","50.0667","22.2333","Poland","PL","POL","Podkarpackie","minor","17768","1616335382"
"Kāveripatnam","Kaveripatnam","12.4219","78.2188","India","IN","IND","Tamil Nādu","","18978","1356992842"
"Douar Ait Sidi Daoud","Douar Ait Sidi Daoud","31.6345","-7.6440","Morocco","MA","MAR","Marrakech-Safi","","19286","1504024368"
"Vila Real de Santo António","Vila Real de Santo Antonio","37.1939","-7.4158","Portugal","PT","PRT","Faro","minor","19156","1620662816"
"Kushiro","Kushiro","42.9961","144.4661","Japan","JP","JPN","Hokkaidō","","19391","1392003152"
"Binka","Binka","21.0263","83.8120","India","IN","IND","Odisha","","19191","1356541292"
"Vellmar","Vellmar","51.3500","9.4667","Germany","DE","DEU","Hesse","","18166","1276293369"
"Pozzallo","Pozzallo","36.7303","14.8467","Italy","IT","ITA","Sicilia","","19466","1380784313"
"Lodeynoye Pole","Lodeynoye Pole","60.7167","33.5500","Russia","RU","RUS","Leningradskaya Oblast’","","19458","1643012084"
"Sipoo","Sipoo","60.3764","25.2722","Finland","FI","FIN","Uusimaa","minor","19399","1246836049"
"Sorgues","Sorgues","44.0083","4.8725","France","FR","FRA","Provence-Alpes-Côte d’Azur","","18893","1250751764"
"Time","Time","58.7228","5.7653","Norway","NO","NOR","Rogaland","","19353","1578972107"
"Schwalmtal","Schwalmtal","51.2225","6.2625","Germany","DE","DEU","North Rhine-Westphalia","","19062","1276833199"
"Dhānsāria","Dhansaria","24.6400","88.0200","India","IN","IND","Jhārkhand","","17800","1356939198"
"Wervik","Wervik","50.7797","3.0400","Belgium","BE","BEL","Flanders","","19020","1056938335"
"Royan","Royan","45.6231","-1.0431","France","FR","FRA","Nouvelle-Aquitaine","","18499","1250074417"
"Ban Fang Tuen","Ban Fang Tuen","19.5497","99.7566","Thailand","TH","THA","Chiang Rai","","19326","1764355524"
"Lake Forest","Lake Forest","42.2380","-87.8596","United States","US","USA","Illinois","","19450","1840008087"
"Onga","Onga","33.8479","130.6683","Japan","JP","JPN","Fukuoka","","18609","1392003497"
"Fécamp","Fecamp","49.7575","0.3792","France","FR","FRA","Normandie","","18251","1250655012"
"Rivière-du-Loup","Riviere-du-Loup","47.8333","-69.5333","Canada","CA","CAN","Quebec","","19447","1124662123"
"Pariquera-Açu","Pariquera-Acu","-24.7150","-47.8811","Brazil","BR","BRA","São Paulo","","19391","1076959622"
"Perūru","Peruru","14.3500","77.3333","India","IN","IND","Andhra Pradesh","","19323","1356550055"
"Quixeré","Quixere","-5.0739","-37.9889","Brazil","BR","BRA","Ceará","","19412","1076686573"
"Albolote","Albolote","37.2306","-3.6569","Spain","ES","ESP","Andalusia","","19199","1724140876"
"Tirunāgeswaram","Tirunageswaram","10.9646","79.4293","India","IN","IND","Tamil Nādu","","18212","1356173215"
"Salgado","Salgado","-11.0319","-37.4750","Brazil","BR","BRA","Sergipe","","19365","1076036946"
"Chiari","Chiari","45.5197","9.8833","Italy","IT","ITA","Lombardy","","18944","1380779872"
"Dashtigulho","Dashtigulho","37.7000","69.5000","Tajikistan","TJ","TJK","Khatlon","","19331","1762339993"
"Starodub","Starodub","52.5833","32.7667","Russia","RU","RUS","Bryanskaya Oblast’","minor","18615","1643347111"
"Sirālkoppa","Siralkoppa","14.3807","75.2501","India","IN","IND","Karnātaka","","19141","1356798171"
"Cobourg","Cobourg","43.9667","-78.1667","Canada","CA","CAN","Ontario","","19440","1124831257"
"Druten","Druten","51.8833","5.6000","Netherlands","NL","NLD","Gelderland","minor","18991","1528263321"
"La Paz","La Paz","10.8911","124.9572","Philippines","PH","PHL","Leyte","","19174","1608196714"
"Paraisópolis","Paraisopolis","-22.5539","-45.7800","Brazil","BR","BRA","Minas Gerais","","19379","1076153372"
"East St. Louis","East St. Louis","38.6155","-90.1304","United States","US","USA","Illinois","","19437","1840008629"
"Dalanzadgad","Dalanzadgad","43.5700","104.4258","Mongolia","MN","MNG","Ömnögovĭ","minor","19396","1496544358"
"San Antonio Huista","San Antonio Huista","15.6500","-91.7667","Guatemala","GT","GTM","Huehuetenango","minor","19311","1320351687"
"Tangbian","Tangbian","25.6539","106.7793","China","CN","CHN","Guizhou","","19335","1156223007"
"Holalkere","Holalkere","14.0429","76.1850","India","IN","IND","Karnātaka","","19237","1356272144"
"Mino","Mino","35.5448","136.9076","Japan","JP","JPN","Gifu","","19266","1392884993"
"Srikhanda","Srikhanda","23.5984","88.0871","India","IN","IND","West Bengal","","18394","1356033928"
"Barhampur","Barhampur","25.5976","84.3011","India","IN","IND","Bihār","","18119","1356715994"
"Nārapala","Narapala","14.7206","77.8106","India","IN","IND","Andhra Pradesh","","18982","1356130701"
"Lynn Haven","Lynn Haven","30.2337","-85.6370","United States","US","USA","Florida","","19425","1840015922"
"Koropí","Koropi","37.9000","23.8733","Greece","GR","GRC","Attikí","minor","19164","1300178029"
"Vence","Vence","43.7225","7.1119","France","FR","FRA","Provence-Alpes-Côte d’Azur","","18940","1250241115"
"Riachão do Dantas","Riachao do Dantas","-11.0689","-37.7250","Brazil","BR","BRA","Sergipe","","19386","1076967515"
"Ituaçu","Ituacu","-13.8128","-41.2969","Brazil","BR","BRA","Bahia","","19406","1076736927"
"Kapangan","Kapangan","16.5764","120.6053","Philippines","PH","PHL","Benguet","","19297","1608871034"
"Scorzè","Scorze","45.5719","12.1089","Italy","IT","ITA","Veneto","","18848","1380933834"
"Purushottampur","Purushottampur","19.5202","84.8851","India","IN","IND","Odisha","","18808","1356559069"
"Mingjiujie","Mingjiujie","23.4558","103.6522","China","CN","CHN","Yunnan","","19330","1156341002"
"Angleton","Angleton","29.1721","-95.4293","United States","US","USA","Texas","","19408","1840019672"
"Frutillar Alto","Frutillar Alto","-41.1228","-73.0581","Chile","CL","CHL","Los Lagos","","19400","1152452605"
"Izra","Izra","32.8454","36.2499","Syria","SY","SYR","Dar‘ā","minor","19158","1760987853"
"Krasnystaw","Krasnystaw","50.9833","23.1833","Poland","PL","POL","Lubelskie","minor","18630","1616374210"
"Rayevskiy","Rayevskiy","54.0658","54.9467","Russia","RU","RUS","Bashkortostan","","19384","1643613500"
"Cabildo","Cabildo","-32.4275","-71.0664","Chile","CL","CHL","Valparaíso","","19388","1152995057"
"Kamiichi","Kamiichi","36.6984","137.3624","Japan","JP","JPN","Toyama","","19318","1392722248"
"Wassenberg","Wassenberg","51.1000","6.1500","Germany","DE","DEU","North Rhine-Westphalia","","18952","1276956199"
"Tajerouine","Tajerouine","35.8833","8.5500","Tunisia","TN","TUN","Kef","","19362","1788699943"
"Cocos","Cocos","-14.1839","-44.5339","Brazil","BR","BRA","Bahia","","19396","1076142940"
"Motru","Motru","44.8036","22.9708","Romania","RO","ROU","Gorj","","19079","1642442019"
"Makronia","Makronia","23.8476","78.7990","India","IN","IND","Madhya Pradesh","","18955","1356001591"
"Stabroek","Stabroek","51.3333","4.3667","Belgium","BE","BEL","Flanders","","18529","1056032515"
"Montemurlo","Montemurlo","43.9333","11.0333","Italy","IT","ITA","Tuscany","","18779","1380036366"
"Halstenbek","Halstenbek","53.6334","9.8372","Germany","DE","DEU","Schleswig-Holstein","","17961","1276502053"
"Lake Shore","Lake Shore","39.1030","-76.4876","United States","US","USA","Maryland","","19388","1840005920"
"Bādkulla","Badkulla","23.2800","88.5300","India","IN","IND","West Bengal","","18051","1356407381"
"Juquiá","Juquia","-24.3208","-47.6347","Brazil","BR","BRA","São Paulo","","19359","1076000755"
"Balta","Balta","47.9361","29.6225","Ukraine","UA","UKR","Odeska Oblast","minor","19353","1804082783"
"Ambatomirahavavy","Ambatomirahavavy","-18.9333","47.3833","Madagascar","MG","MDG","Antananarivo","","19163","1450466321"
"San Vito dei Normanni","San Vito dei Normanni","40.6556","17.7036","Italy","IT","ITA","Puglia","","19095","1380148507"
"Pauini","Pauini","-7.7139","-66.9758","Brazil","BR","BRA","Amazonas","","19378","1076909875"
"Chilkūru","Chilkuru","16.9611","79.9125","India","IN","IND","Andhra Pradesh","","18952","1356142066"
"Monte Azul Paulista","Monte Azul Paulista","-20.9072","-48.6414","Brazil","BR","BRA","São Paulo","","19304","1076549080"
"Sukhsena","Sukhsena","25.7952","87.1089","India","IN","IND","Bihār","","17978","1356467553"
"Paranapanema","Paranapanema","-23.3886","-48.7228","Brazil","BR","BRA","São Paulo","","19357","1076413704"
"Iiyama","Iiyama","36.8516","138.3655","Japan","JP","JPN","Nagano","","19279","1392890981"
"Brigham City","Brigham City","41.5035","-112.0453","United States","US","USA","Utah","","19373","1840018702"
"Mukaiengaru","Mukaiengaru","44.0621","143.5276","Japan","JP","JPN","Hokkaidō","","19358","1392295028"
"Pfullingen","Pfullingen","48.4656","9.2261","Germany","DE","DEU","Baden-Württemberg","","18749","1276393885"
"Chautāpal","Chautapal","17.2508","78.8972","India","IN","IND","Andhra Pradesh","","19092","1356795326"
"Buerarema","Buerarema","-14.9589","-39.3000","Brazil","BR","BRA","Bahia","","19283","1076122498"
"Haldensleben","Haldensleben","52.2833","11.4167","Germany","DE","DEU","Saxony-Anhalt","minor","19247","1276371853"
"Marchena","Marchena","37.3333","-5.4167","Spain","ES","ESP","Andalusia","","19317","1724561354"
"Bagahi","Bagahi","26.7632","84.4670","India","IN","IND","Bihār","","17798","1356156215"
"Krasnogorskiy","Krasnogorskiy","41.1542","69.6697","Uzbekistan","UZ","UZB","Toshkent","","19176","1860769739"
"Hardinxveld-Giessendam","Hardinxveld-Giessendam","51.8167","4.8333","Netherlands","NL","NLD","Zuid-Holland","minor","18413","1528515465"
"Burscheid","Burscheid","51.1000","7.1167","Germany","DE","DEU","North Rhine-Westphalia","","18681","1276397307"
"Raita","Raita","32.5495","76.2390","India","IN","IND","Himāchal Pradesh","","19158","1356027472"
"Khonj","Khonj","27.8914","53.4344","Iran","IR","IRN","Fārs","minor","19347","1364328098"
"Bracciano","Bracciano","42.1000","12.1833","Italy","IT","ITA","Lazio","","19219","1380527355"
"Bergneustadt","Bergneustadt","51.0333","7.6500","Germany","DE","DEU","North Rhine-Westphalia","","18865","1276283829"
"Tibagi","Tibagi","-24.5089","-50.4139","Brazil","BR","BRA","Paraná","","19344","1076856975"
"Narni","Narni","42.5167","12.5167","Italy","IT","ITA","Umbria","","19252","1380161361"
"Almel","Almel","16.9200","76.2400","India","IN","IND","Karnātaka","","19120","1356472316"
"Sebt Gzoula","Sebt Gzoula","32.1167","-9.0833","Morocco","MA","MAR","Marrakech-Safi","","18543","1504003595"
"Lexington","Lexington","35.8018","-80.2682","United States","US","USA","North Carolina","","19347","1840015360"
"Syosset","Syosset","40.8157","-73.5020","United States","US","USA","New York","","19347","1840005278"
"Venceslau Brás","Venceslau Bras","-23.8739","-49.8028","Brazil","BR","BRA","Paraná","","19298","1076893180"
"Hidrolândia","Hidrolandia","-4.4078","-40.4378","Brazil","BR","BRA","Ceará","","19325","1076197318"
"Altoona","Altoona","41.6483","-93.4783","United States","US","USA","Iowa","","19345","1840007065"
"Diadi","Diadi","16.6600","121.3686","Philippines","PH","PHL","Nueva Vizcaya","","19236","1608324482"
"Guru Har Sahāi","Guru Har Sahai","30.7086","74.4041","India","IN","IND","Punjab","","18943","1356143986"
"Texcaltitlán","Texcaltitlan","18.9297","-99.9372","Mexico","MX","MEX","México","minor","19206","1484685265"
"Liberal","Liberal","37.0466","-100.9295","United States","US","USA","Kansas","","19340","1840001704"
"Athār","Athar","26.0392","86.0590","India","IN","IND","Bihār","","17999","1356215590"
"Aci Sant’Antonio","Aci Sant'Antonio","37.6000","15.1167","Italy","IT","ITA","Sicilia","","18076","1380974532"
"Zerong","Zerong","24.9692","104.9558","China","CN","CHN","Guizhou","","19186","1156075127"
"Chaita","Chaita","25.7671","85.8792","India","IN","IND","Bihār","","17962","1356574932"
"Haslett","Haslett","42.7525","-84.4020","United States","US","USA","Michigan","","19333","1840004500"
"Kānp","Kanp","25.8337","86.7402","India","IN","IND","Bihār","","18237","1356110601"
"Ābomsa","Abomsa","8.5833","39.8500","Ethiopia","ET","ETH","Oromīya","","19208","1231331780"
"Taka","Taka","35.0503","134.9234","Japan","JP","JPN","Hyōgo","","19227","1392003290"
"Campina Verde","Campina Verde","-19.5358","-49.4858","Brazil","BR","BRA","Minas Gerais","","19324","1076800884"
"Bouanri","Bouanri","10.2000","2.8667","Benin","BJ","BEN","Borgou","","19281","1204515161"
"Orange","Orange","30.1226","-93.7607","United States","US","USA","Texas","","19329","1840020910"
"Korsholm","Korsholm","63.1125","21.6778","Finland","FI","FIN","Pohjanmaa","minor","19302","1246225569"
"Horseheads","Horseheads","42.1625","-76.7941","United States","US","USA","New York","","19326","1840004688"
"Khetia","Khetia","21.6712","74.5853","India","IN","IND","Mahārāshtra","","18827","1356346104"
"Ban Mae Hia Nai","Ban Mae Hia Nai","18.7433","98.9640","Thailand","TH","THA","Chiang Mai","","18564","1764930475"
"Monção","Moncao","42.0733","-8.4800","Portugal","PT","PRT","Viana do Castelo","minor","19230","1620375502"
"Sidi Redouane","Sidi Redouane","34.6833","-5.4500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","19169","1504580531"
"Tapejara","Tapejara","-28.0678","-52.0139","Brazil","BR","BRA","Rio Grande do Sul","","19250","1076224015"
"Kirovgrad","Kirovgrad","57.4350","60.0561","Russia","RU","RUS","Sverdlovskaya Oblast’","","19277","1643253740"
"Soure","Soure","40.0500","-8.6333","Portugal","PT","PRT","Coimbra","minor","19245","1620536643"
"Koppal","Koppal","15.3500","76.1500","India","IN","IND","Karnātaka","","18988","1356544817"
"Lebedyan","Lebedyan","53.0167","39.1500","Russia","RU","RUS","Lipetskaya Oblast’","","19288","1643241295"
"Kralupy nad Vltavou","Kralupy nad Vltavou","50.2411","14.3116","Czechia","CZ","CZE","Středočeský Kraj","","18485","1203317115"
"Mâncio Lima","Mancio Lima","-7.6139","-72.8958","Brazil","BR","BRA","Acre","","19311","1076586312"
"Horizontina","Horizontina","-27.6258","-54.3078","Brazil","BR","BRA","Rio Grande do Sul","","19232","1076684828"
"Cutlerville","Cutlerville","42.8403","-85.6734","United States","US","USA","Michigan","","19313","1840004308"
"Agliana","Agliana","43.9000","11.0000","Italy","IT","ITA","Tuscany","","17789","1380301784"
"Schkeuditz","Schkeuditz","51.4000","12.2167","Germany","DE","DEU","Saxony","","19075","1276228794"
"Kronberg","Kronberg","50.1833","8.5000","Germany","DE","DEU","Hesse","","18322","1276558702"
"Santa Marinella","Santa Marinella","42.0333","11.8500","Italy","IT","ITA","Lazio","","18921","1380956052"
"Dumri","Dumri","25.5356","85.5996","India","IN","IND","Bihār","","18126","1356150568"
"Qandala","Qandala","11.4711","49.8739","Somalia","SO","SOM","Bari","","19300","1706605271"
"Atok","Atok","16.6272","120.7672","Philippines","PH","PHL","Benguet","","19218","1608057883"
"Ban Mae Kha Tai","Ban Mae Kha Tai","18.7433","98.9640","Thailand","TH","THA","Chiang Mai","","18546","1764637838"
"Henichesk","Henichesk","46.1667","34.8000","Ukraine","UA","UKR","Khersonska Oblast","minor","19253","1804703441"
"Dolynska","Dolynska","48.1111","32.7648","Ukraine","UA","UKR","Kirovohradska Oblast","minor","19287","1804823842"
"Marechal Taumaturgo","Marechal Taumaturgo","-8.9408","-72.7919","Brazil","BR","BRA","Acre","","19299","1076754206"
"Bakal","Bakal","54.9333","58.8167","Russia","RU","RUS","Chelyabinskaya Oblast’","","19264","1643146722"
"Groesbeek","Groesbeek","51.7833","5.9333","Netherlands","NL","NLD","Gelderland","minor","18870","1528617785"
"Söğüt","Sogut","40.0186","30.1814","Turkey","TR","TUR","Bilecik","minor","19244","1792676934"
"Lebach","Lebach","49.4100","6.9100","Germany","DE","DEU","Saarland","","19006","1276242576"
"Mohana","Mohana","25.9027","77.7715","India","IN","IND","Madhya Pradesh","","18577","1356310580"
"Shāhgarh","Shahgarh","24.3137","79.1181","India","IN","IND","Madhya Pradesh","","19121","1356825789"
"Vohimasy","Vohimasy","-22.1000","47.7833","Madagascar","MG","MDG","Fianarantsoa","","19000","1450885065"
"Siroda","Siroda","15.3292","74.0271","India","IN","IND","Goa","","18627","1356128905"
"Corbetta","Corbetta","45.4667","8.9167","Italy","IT","ITA","Lombardy","","18302","1380105269"
"Pahārpur","Paharpur","25.5681","85.3763","India","IN","IND","Bihār","","18109","1356129715"
"Tsumeb","Tsumeb","-19.2500","17.7000","Namibia","NA","NAM","Oshikoto","","19275","1516619412"
"Ampataka","Ampataka","-23.3500","47.4667","Madagascar","MG","MDG","Fianarantsoa","","19000","1450220197"
"Zachary","Zachary","30.6643","-91.1634","United States","US","USA","Louisiana","","19278","1840015908"
"Çal","Cal","38.0836","29.3989","Turkey","TR","TUR","Denizli","minor","19259","1792007424"
"Ban Mon Pin","Ban Mon Pin","19.9168","99.1583","Thailand","TH","THA","Chiang Mai","","19123","1764739974"
"Püttlingen","Puttlingen","49.2833","6.8833","Germany","DE","DEU","Saarland","","18510","1276328612"
"Amboanana","Amboanana","-19.1500","47.1667","Madagascar","MG","MDG","Antananarivo","","19217","1450418380"
"Águas Formosas","Aguas Formosas","-17.0819","-40.9358","Brazil","BR","BRA","Minas Gerais","","19247","1076043870"
"Norton","Norton","41.9640","-71.1842","United States","US","USA","Massachusetts","","19270","1840070386"
"La Crau","La Crau","43.1497","6.0742","France","FR","FRA","Provence-Alpes-Côte d’Azur","","18774","1250618622"
"Shovot","Shovot","41.6500","60.3000","Uzbekistan","UZ","UZB","Xorazm","minor","19000","1860224573"
"Lgov","Lgov","51.6667","35.2667","Russia","RU","RUS","Kurskaya Oblast’","","18774","1643991928"
"Sömmerda","Sommerda","51.1617","11.1169","Germany","DE","DEU","Thuringia","minor","19034","1276806984"
"Quitandinha","Quitandinha","-25.8719","-49.4978","Brazil","BR","BRA","Paraná","","19221","1076520293"
"Gātāda","Gatada","18.2844","83.5406","India","IN","IND","Andhra Pradesh","","18893","1356521862"
"Kamatgi","Kamatgi","16.1190","75.8482","India","IN","IND","Mahārāshtra","","18981","1356123894"
"Rāmpur Tilak","Rampur Tilak","25.8513","87.0930","India","IN","IND","Bihār","","17977","1356622862"
"Gülağaç","Gulagac","38.3956","34.3456","Turkey","TR","TUR","Aksaray","minor","19158","1792759356"
"Huércal de Almería","Huercal de Almeria","36.8833","-2.4333","Spain","ES","ESP","Andalusia","","18384","1724495259"
"Cranbrook","Cranbrook","49.5097","-115.7667","Canada","CA","CAN","British Columbia","","19259","1124937794"
"Žatec","Zatec","50.3273","13.5459","Czechia","CZ","CZE","Ústecký Kraj","","18823","1203368176"
"Nakhl-e Taqī","Nakhl-e Taqi","27.5014","52.5858","Iran","IR","IRN","Būshehr","","18837","1364288724"
"Lede","Lede","50.9667","3.9833","Belgium","BE","BEL","Flanders","","18628","1056629611"
"Aroeiras","Aroeiras","-7.5450","-35.7078","Brazil","BR","BRA","Paraíba","","19204","1076842868"
"Kabūdarāhang","Kabudarahang","35.2083","48.7239","Iran","IR","IRN","Hamadān","minor","19216","1364143684"
"Hochheim am Main","Hochheim am Main","50.0167","8.3500","Germany","DE","DEU","Hesse","","18310","1276794905"
"Rāmpur","Rampur","26.4424","89.8038","India","IN","IND","West Bengal","","18274","1356114247"
"Ismailpur","Ismailpur","25.3113","87.1270","India","IN","IND","Bihār","","18909","1356461115"
"Noci","Noci","40.8000","17.1333","Italy","IT","ITA","Puglia","","19115","1380163445"
"Stillwater","Stillwater","45.0573","-92.8313","United States","US","USA","Minnesota","","19243","1840008914"
"Kottakota","Kottakota","17.7513","82.7004","India","IN","IND","Andhra Pradesh","","18862","1356478634"
"Jalakandāpuram","Jalakandapuram","11.6986","77.8776","India","IN","IND","Tamil Nādu","","18581","1356972928"
"Kīlmangalam","Kilmangalam","10.0933","77.6108","India","IN","IND","Tamil Nādu","","18633","1356047226"
"Torredembarra","Torredembarra","41.1457","1.3957","Spain","ES","ESP","Catalonia","","17256","1724533049"
"Abdulino","Abdulino","53.6667","53.6333","Russia","RU","RUS","Orenburgskaya Oblast’","","19222","1643685252"
"Ferguson","Ferguson","40.7432","-77.9403","United States","US","USA","Pennsylvania","","19236","1840149387"
"Bandar-e Kong","Bandar-e Kong","26.5992","54.9361","Iran","IR","IRN","Hormozgān","","19213","1364767646"
"Karia Ba Mohamed","Karia Ba Mohamed","34.3667","-5.2000","Morocco","MA","MAR","Fès-Meknès","","18762","1504442421"
"Júlio de Castilhos","Julio de Castilhos","-29.2269","-53.6819","Brazil","BR","BRA","Rio Grande do Sul","","19224","1076339588"
"Dolný Kubín","Dolny Kubin","49.2106","19.2975","Slovakia","SK","SVK","Žilina","minor","18905","1703836159"
"Jamiltepec","Jamiltepec","16.2783","-97.8200","Mexico","MX","MEX","Oaxaca","minor","19201","1484035155"
"Rizal","Rizal","17.8478","121.3450","Philippines","PH","PHL","Cagayan","","19077","1608052586"
"Nova Olímpia","Nova Olimpia","-14.7969","-57.2878","Brazil","BR","BRA","Mato Grosso","","19218","1076561598"
"Punta Gorda","Punta Gorda","26.8941","-82.0513","United States","US","USA","Florida","","19230","1840015122"
"Meylan","Meylan","45.2086","5.7794","France","FR","FRA","Auvergne-Rhône-Alpes","","17786","1250096459"
"Pokaran","Pokaran","26.9194","71.9208","India","IN","IND","Rājasthān","","19186","1356738519"
"Deerfield","Deerfield","42.1654","-87.8516","United States","US","USA","Illinois","","19228","1840011167"
"Badger","Badger","64.8006","-147.3877","United States","US","USA","Alaska","","19226","1840023690"
"Seminole","Seminole","27.8435","-82.7840","United States","US","USA","Florida","","19224","1840015978"
"Riacho das Almas","Riacho das Almas","-8.1339","-35.6892","Brazil","BR","BRA","Pernambuco","","19162","1076029220"
"Twinsburg","Twinsburg","41.3220","-81.4451","United States","US","USA","Ohio","","19220","1840000805"
"Uzhur","Uzhur","55.3175","89.8225","Russia","RU","RUS","Krasnoyarskiy Kray","","19212","1643237169"
"Bhagabānpur","Bhagabanpur","24.7765","88.0217","India","IN","IND","West Bengal","","17397","1356672735"
"Oberasbach","Oberasbach","49.4219","10.9583","Germany","DE","DEU","Bavaria","","17749","1276883001"
"Hindoria","Hindoria","23.9035","79.5686","India","IN","IND","Madhya Pradesh","","19046","1356873486"
"La Unión","La Union","5.9736","-75.3614","Colombia","CO","COL","Antioquia","minor","19119","1170723102"
"Gomīshān","Gomishan","37.0717","54.0767","Iran","IR","IRN","Golestān","minor","19191","1364412693"
"Ellensburg","Ellensburg","46.9999","-120.5475","United States","US","USA","Washington","","19215","1840019841"
"La Eliana","La Eliana","39.5661","-0.5281","Spain","ES","ESP","Valencia","","18544","1724040123"
"Lumphat","Lumphat","13.5070","106.9810","Cambodia","KH","KHM","Ratanakiri","minor","19205","1116149070"
"Ainan","Ainan","32.9667","132.5833","Japan","JP","JPN","Ehime","","19131","1392003348"
"Wallingford Center","Wallingford Center","41.4499","-72.8189","United States","US","USA","Connecticut","","19211","1840073312"
"Mure","Mure","34.3376","134.1397","Japan","JP","JPN","Kagawa","","18108","1392003506"
"Mount Eliza","Mount Eliza","-38.1890","145.0920","Australia","AU","AUS","Victoria","","17888","1036041769"
"Forio","Forio","40.7333","13.8500","Italy","IT","ITA","Campania","","17840","1380644792"
"Louviers","Louviers","49.2153","1.1656","France","FR","FRA","Normandie","","18518","1250408063"
"Navraftor","Navraftor","37.7333","68.7667","Tajikistan","TJ","TJK","Khatlon","","18281","1762606160"
"Griffith","Griffith","-34.2900","146.0400","Australia","AU","AUS","New South Wales","","18196","1036563313"
"Rurrenabaque","Rurrenabaque","-14.4422","-67.5283","Bolivia","BO","BOL","El Beni","","19195","1068557134"
"Antas","Antas","-10.4000","-38.3328","Brazil","BR","BRA","Bahia","","19183","1076203131"
"Boiro","Boiro","42.6500","-8.9000","Spain","ES","ESP","Galicia","","18976","1724627314"
"Naantali","Naantali","60.4667","22.0333","Finland","FI","FIN","Varsinais-Suomi","minor","19167","1246881232"
"Huehuetla","Huehuetla","20.1075","-97.6233","Mexico","MX","MEX","Puebla","minor","18803","1484001118"
"Marwa","Marwa","25.3994","86.9257","India","IN","IND","Bihār","","17859","1356169083"
"Carambeí","Carambei","-24.9178","-50.0969","Brazil","BR","BRA","Paraná","","19163","1076138552"
"Valenza","Valenza","45.0140","8.6458","Italy","IT","ITA","Piedmont","","18804","1380755074"
"Bredene","Bredene","51.2336","2.9756","Belgium","BE","BEL","Flanders","","17828","1056554205"
"Sukumo","Sukumo","32.9333","132.7167","Japan","JP","JPN","Kōchi","","19124","1392912259"
"Kalmthout","Kalmthout","51.3833","4.4667","Belgium","BE","BEL","Flanders","","18872","1056545149"
"Vera","Vera","-29.4667","-60.2167","Argentina","AR","ARG","Santa Fe","minor","19185","1032305174"
"Naples","Naples","26.1504","-81.7936","United States","US","USA","Florida","","19187","1840015131"
"Lumberton","Lumberton","34.6312","-79.0186","United States","US","USA","North Carolina","","19186","1840015531"
"Maracaçumé","Maracacume","-2.0428","-45.9589","Brazil","BR","BRA","Maranhão","","19155","1076686311"
"Palmi","Palmi","38.3667","15.8500","Italy","IT","ITA","Calabria","","18606","1380460701"
"Karuppur","Karuppur","11.7170","78.0924","India","IN","IND","Tamil Nādu","","18321","1356227186"
"Dadeldhurā","Dadeldhura","29.3000","80.6000","Nepal","NP","NPL","","","19014","1524639464"
"Aberdeen","Aberdeen","40.4165","-74.2249","United States","US","USA","New Jersey","","19184","1840081652"
"Cuquío","Cuquio","20.9275","-103.0239","Mexico","MX","MEX","Jalisco","minor","17980","1484426156"
"San Teodoro","San Teodoro","13.4358","121.0192","Philippines","PH","PHL","Oriental Mindoro","","19121","1608549628"
"Vargem Alta","Vargem Alta","-20.6708","-41.0069","Brazil","BR","BRA","Espírito Santo","","19130","1076304593"
"Tazishan","Tazishan","41.0937","119.0408","China","CN","CHN","Hebei","","19081","1156652867"
"Tora","Tora","21.3262","83.6708","India","IN","IND","Odisha","","18093","1356141491"
"Vilavūr","Vilavur","8.2669","77.3052","India","IN","IND","Tamil Nādu","","18047","1356207386"
"Claremore","Claremore","36.3146","-95.6095","United States","US","USA","Oklahoma","","19173","1840019049"
"Ấp Khánh Hưng","Ap Khanh Hung","10.2000","105.8500","Vietnam","VN","VNM","Cần Thơ","","18874","1704912903"
"Tak Bai","Tak Bai","6.2592","102.0531","Thailand","TH","THA","Narathiwat","minor","18943","1764842940"
"Toshloq","Toshloq","40.4808","71.7608","Uzbekistan","UZ","UZB","Farg‘ona","minor","18600","1860363369"
"Pastavy","Pastavy","55.1167","26.8333","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","19153","1112856591"
"Bad Driburg","Bad Driburg","51.7333","9.0167","Germany","DE","DEU","North Rhine-Westphalia","","19002","1276571920"
"La Ligua","La Ligua","-32.4500","-71.2167","Chile","CL","CHL","Valparaíso","minor","19127","1152826056"
"Ait Ben Daoudi","Ait Ben Daoudi","31.6345","-7.6440","Morocco","MA","MAR","Marrakech-Safi","","18976","1504718613"
"Diamante","Diamante","-32.0667","-60.6500","Argentina","AR","ARG","Entre Ríos","minor","19142","1032164257"
"Castelfidardo","Castelfidardo","43.4642","13.5461","Italy","IT","ITA","Marche","","18601","1380264751"
"Five Forks","Five Forks","34.8069","-82.2271","United States","US","USA","South Carolina","","19158","1840013491"
"El Rosal","El Rosal","4.8519","-74.2628","Colombia","CO","COL","Cundinamarca","minor","18958","1170518827"
"Lumbayanague","Lumbayanague","7.7833","124.2833","Philippines","PH","PHL","Lanao del Sur","","19091","1608423809"
"Aporá","Apora","-11.6600","-38.0808","Brazil","BR","BRA","Bahia","","19146","1076481861"
"Maitland","Maitland","28.6295","-81.3718","United States","US","USA","Florida","","19154","1840015964"
"Arita","Arita","33.2106","129.8490","Japan","JP","JPN","Saga","","18867","1392003093"
"Ifanirea","Ifanirea","-22.1833","47.4667","Madagascar","MG","MDG","Fianarantsoa","","19000","1450435831"
"Tlalnelhuayocan","Tlalnelhuayocan","19.5667","-96.9667","Mexico","MX","MEX","Veracruz","minor","18715","1484053667"
"Raisāri","Raisari","26.1319","87.0238","India","IN","IND","Bihār","","18117","1356586220"
"Nanthankulam","Nanthankulam","8.3331","77.8442","India","IN","IND","Tamil Nādu","","18751","1356222827"
"Akune","Akune","32.0167","130.2167","Japan","JP","JPN","Kagoshima","","19009","1392003418"
"Kurumbapālaiyam","Kurumbapalaiyam","11.1053","76.9217","India","IN","IND","Tamil Nādu","","17864","1356160161"
"Aizumisato","Aizumisato","37.4649","139.8342","Japan","JP","JPN","Fukushima","","19078","1392003414"
"West Manchester","West Manchester","39.9456","-76.7952","United States","US","USA","Pennsylvania","","19147","1840144165"
"Cerqueira César","Cerqueira Cesar","-23.0356","-49.1661","Brazil","BR","BRA","São Paulo","","19109","1076534225"
"Santa Ana Jilotzingo","Santa Ana Jilotzingo","19.5333","-99.4000","Mexico","MX","MEX","México","minor","19013","1484669477"
"Kasterlee","Kasterlee","51.2500","4.9500","Belgium","BE","BEL","Flanders","","18882","1056843375"
"Shichigahama","Shichigahama","38.3045","141.0591","Japan","JP","JPN","Miyagi","","17793","1392003288"
"Hermiston","Hermiston","45.8326","-119.2854","United States","US","USA","Oregon","","19141","1840019915"
"Lakshmaneswaram","Lakshmaneswaram","16.4082","81.6867","India","IN","IND","Andhra Pradesh","","18023","1356884524"
"Varzelândia","Varzelandia","-15.7008","-44.0278","Brazil","BR","BRA","Minas Gerais","","19116","1076633098"
"Shemonaīkha","Shemonaikha","50.6269","81.9109","Kazakhstan","KZ","KAZ","","minor","19127","1398507085"
"Nikolayevsk-na-Amure","Nikolayevsk-na-Amure","53.1333","140.7333","Russia","RU","RUS","Khabarovskiy Kray","","19135","1643026765"
"Londerzeel","Londerzeel","51.0000","4.3000","Belgium","BE","BEL","Flanders","","18620","1056504817"
"Nayāgarh","Nayagarh","20.1288","85.0963","India","IN","IND","Odisha","","18894","1356118588"
"Moissy-Cramayel","Moissy-Cramayel","48.6261","2.5922","France","FR","FRA","Île-de-France","","17882","1250228933"
"Ashkezar","Ashkezar","32.0006","54.2075","Iran","IR","IRN","Yazd","minor","19123","1364544757"
"Valle","Valle","-2.9500","-78.9833","Ecuador","EC","ECU","Azuay","","18692","1218202285"
"Moreni","Moreni","44.9803","25.6444","Romania","RO","ROU","Dâmboviţa","","18687","1642694377"
"Banni","Banni","25.4692","86.6068","India","IN","IND","Bihār","","17387","1356257311"
"Malanday","Malanday","14.7194","120.9547","Philippines","PH","PHL","Valenzuela","","19060","1608047856"
"Fond des Blancs","Fond des Blancs","18.2833","-73.1333","Haiti","HT","HTI","Sud","","19010","1332189993"
"White Oak","White Oak","39.2106","-84.6060","United States","US","USA","Ohio","","19123","1840034164"
"Águas de Lindóia","Aguas de Lindoia","-22.4758","-46.6328","Brazil","BR","BRA","São Paulo","","18808","1076621560"
"Eiheiji","Eiheiji","36.0922","136.2987","Japan","JP","JPN","Fukui","","18921","1392003240"
"Ranomena","Ranomena","-23.4167","47.2667","Madagascar","MG","MDG","Fianarantsoa","","19000","1450229424"
"Lunéville","Luneville","48.5894","6.5017","France","FR","FRA","Grand Est","minor","18027","1250172555"
"Achocalla","Achocalla","-16.5833","-68.1667","Bolivia","BO","BOL","La Paz","","18442","1068220398"
"Amares","Amares","41.6167","-8.3333","Portugal","PT","PRT","Braga","minor","18889","1620212427"
"Dianópolis","Dianopolis","-11.6258","-46.8203","Brazil","BR","BRA","Tocantins","","19112","1076139135"
"Beaconsfield","Beaconsfield","45.4333","-73.8667","Canada","CA","CAN","Quebec","","19115","1124755118"
"Ambodinonoka","Ambodinonoka","-20.9833","47.8833","Madagascar","MG","MDG","Fianarantsoa","","19000","1450637374"
"Nanjanād","Nanjanad","11.3669","76.6415","India","IN","IND","Tamil Nādu","","18889","1356948865"
"Abdurahmoni Jomí","Abdurahmoni Jomi","37.9458","68.8117","Tajikistan","TJ","TJK","Khatlon","minor","18800","1762589864"
"Bình Minh","Binh Minh","10.0961","105.7894","Vietnam","VN","VNM","Vĩnh Long","minor","18105","1704182594"
"Viradouro","Viradouro","-20.8728","-48.2969","Brazil","BR","BRA","São Paulo","","19017","1076583585"
"Barhampur","Barhampur","26.3023","85.7476","India","IN","IND","Bihār","","17563","1356855054"
"Ixtlahuacán del Río","Ixtlahuacan del Rio","20.8667","-103.2500","Mexico","MX","MEX","Jalisco","minor","19070","1484528814"
"Ban Na Yang","Ban Na Yang","12.8339","99.9346","Thailand","TH","THA","Phetchaburi","","18961","1764003401"
"Buriti dos Lopes","Buriti dos Lopes","-3.1750","-41.8669","Brazil","BR","BRA","Piauí","","19074","1076651757"
"Sint-Genesius-Rode","Sint-Genesius-Rode","50.7450","4.3464","Belgium","BE","BEL","Flanders","","18296","1056326710"
"Palma Campania","Palma Campania","40.8667","14.5500","Italy","IT","ITA","Campania","","18218","1380996186"
"Paillaco","Paillaco","-40.0667","-72.8833","Chile","CL","CHL","Los Ríos","","19088","1152131985"
"Sendamaram","Sendamaram","9.0648","77.4369","India","IN","IND","Tamil Nādu","","18729","1356027575"
"Silvânia","Silvania","-16.6589","-48.6078","Brazil","BR","BRA","Goiás","","19089","1076536713"
"Inajá","Inaja","-8.9030","-37.8270","Brazil","BR","BRA","Alagoas","","19081","1076678213"
"Sartell","Sartell","45.6188","-94.2206","United States","US","USA","Minnesota","","19096","1840008886"
"Ballincollig","Ballincollig","51.8879","-8.5893","Ireland","IE","IRL","Cork","","18621","1372262951"
"Mumbwa","Mumbwa","-14.9853","27.0619","Zambia","ZM","ZMB","Central","","19086","1894568812"
"Jadcherla","Jadcherla","16.7667","78.1500","India","IN","IND","Andhra Pradesh","","17958","1356187321"
"Bezaha","Bezaha","-23.5000","44.5000","Madagascar","MG","MDG","Toliara","","19000","1450987255"
"Shirin","Shirin","40.2269","69.1344","Uzbekistan","UZ","UZB","Sirdaryo","","18900","1860820392"
"Xinhua","Xinhua","37.8286","102.5953","China","CN","CHN","Gansu","","18798","1156146089"
"San Gabriel","San Gabriel","16.6667","120.4000","Philippines","PH","PHL","La Union","","18943","1608723804"
"Malimono","Malimono","9.6183","125.4019","Philippines","PH","PHL","Surigao del Norte","","18852","1608228503"
"Preakness","Preakness","40.9382","-74.2242","United States","US","USA","New Jersey","","19083","1840144111"
"Shimomura","Shimomura","36.0696","138.0802","Japan","JP","JPN","Nagano","","18801","1392835805"
"Saugerties","Saugerties","42.0891","-73.9969","United States","US","USA","New York","","19082","1840004732"
"Mahazoarivo","Mahazoarivo","-22.3167","47.5833","Madagascar","MG","MDG","Fianarantsoa","","19000","1450077325"
"Telpaneca","Telpaneca","13.5319","-86.2872","Nicaragua","NI","NIC","Madriz","minor","19025","1558890724"
"Valenzano","Valenzano","41.0500","16.8833","Italy","IT","ITA","Puglia","","17952","1380189067"
"Gostynin","Gostynin","52.4167","19.4667","Poland","PL","POL","Mazowieckie","minor","18527","1616908435"
"Rawmarsh","Rawmarsh","53.4636","-1.3439","United Kingdom","GB","GBR","Rotherham","","18498","1826485638"
"Préveza","Preveza","38.9500","20.7333","Greece","GR","GRC","Ípeiros","minor","19042","1300933723"
"Maumelle","Maumelle","34.8522","-92.4000","United States","US","USA","Arkansas","","19070","1840015510"
"Hazorasp","Hazorasp","41.3167","61.0667","Uzbekistan","UZ","UZB","Xorazm","minor","18800","1860504639"
"Târgu Neamţ","Targu Neamt","47.2025","26.3586","Romania","RO","ROU","Neamţ","","18695","1642788528"
"Lakhnādon","Lakhnadon","22.6005","79.6009","India","IN","IND","Madhya Pradesh","","18934","1356947035"
"Cabaceiras do Paraguaçu","Cabaceiras do Paraguacu","-12.5358","-39.1908","Brazil","BR","BRA","Bahia","","18978","1076654912"
"Zuidhorn","Zuidhorn","53.2500","6.4000","Netherlands","NL","NLD","Groningen","minor","18917","1528073440"
"Umburanas","Umburanas","-10.7328","-41.3258","Brazil","BR","BRA","Bahia","","19055","1076883289"
"Pinole","Pinole","37.9931","-122.2833","United States","US","USA","California","","19065","1840020281"
"Silver Spring","Silver Spring","40.2503","-77.0567","United States","US","USA","Pennsylvania","","19064","1840151631"
"Anjahabe","Anjahabe","-16.3667","46.6167","Madagascar","MG","MDG","Mahajanga","","19000","1450925843"
"Akureyri","Akureyri","65.6833","-18.1000","Iceland","IS","ISL","Akureyri","","18925","1352098424"
"Miarinavaratra","Miarinavaratra","-20.2167","47.5167","Madagascar","MG","MDG","Fianarantsoa","","19000","1450849602"
"Springwater","Springwater","44.4333","-79.7333","Canada","CA","CAN","Ontario","","19059","1124001298"
"Cofradía","Cofradia","15.4168","-88.1603","Honduras","HN","HND","Cortés","","18100","1340899741"
"Duero","Duero","9.7167","124.4000","Philippines","PH","PHL","Bohol","","18861","1608996005"
"Tinton Falls","Tinton Falls","40.2708","-74.0949","United States","US","USA","New Jersey","","19053","1840001368"
"Guernica y Luno","Guernica y Luno","43.3167","-2.6667","Spain","ES","ESP","Basque Country","","17093","1724279131"
"Kosatarosh","Kosatarosh","39.4000","67.7500","Tajikistan","TJ","TJK","Sughd","","18986","1762504857"
"Haaltert","Haaltert","50.9000","4.0000","Belgium","BE","BEL","Flanders","","18443","1056802735"
"Ambalanirana","Ambalanirana","-18.7500","46.5000","Madagascar","MG","MDG","Antananarivo","","19000","1450592549"
"Tanambe","Tanambe","-17.3667","48.4500","Madagascar","MG","MDG","Toamasina","","19000","1450750659"
"Nova Petrópolis","Nova Petropolis","-29.3758","-51.1119","Brazil","BR","BRA","Rio Grande do Sul","","19045","1076513774"
"Kalugumalai","Kalugumalai","9.1494","77.7057","India","IN","IND","Tamil Nādu","","18596","1356095684"
"Pruzhany","Pruzhany","52.5567","24.4644","Belarus","BY","BLR","Brestskaya Voblasts’","minor","19032","1112269031"
"Matteson","Matteson","41.5095","-87.7468","United States","US","USA","Illinois","","19047","1840011293"
"Caowotan","Caowotan","37.2739","104.1046","China","CN","CHN","Gansu","","19015","1156149402"
"Santander","Santander","9.4500","123.3333","Philippines","PH","PHL","Cebu","","18527","1608606634"
"Proletarsk","Proletarsk","46.7000","41.7333","Russia","RU","RUS","Rostovskaya Oblast’","minor","19032","1643862086"
"Manantenina","Manantenina","-24.2833","47.3167","Madagascar","MG","MDG","Toliara","","19000","1450085416"
"Belamoty","Belamoty","-23.5500","44.8000","Madagascar","MG","MDG","Toliara","","19000","1450066289"
"Schneverdingen","Schneverdingen","53.1167","9.8000","Germany","DE","DEU","Lower Saxony","","18964","1276752778"
"Bajo Boquete","Bajo Boquete","8.7800","-82.4400","Panama","PA","PAN","Chiriquí","minor","19000","1591141139"
"El Bolsón","El Bolson","-41.9667","-71.5333","Argentina","AR","ARG","Río Negro","","19009","1032652182"
"Bronte","Bronte","37.7833","14.8333","Italy","IT","ITA","Sicilia","","18963","1380225642"
"Dixon","Dixon","38.4469","-121.8250","United States","US","USA","California","","19038","1840018859"
"Arcata","Arcata","40.8615","-124.0758","United States","US","USA","California","","19038","1840009422"
"Alsip","Alsip","41.6701","-87.7368","United States","US","USA","Illinois","","19036","1840010147"
"Dankov","Dankov","53.2500","39.1333","Russia","RU","RUS","Lipetskaya Oblast’","","19017","1643711821"
"Martigny","Martigny","46.1000","7.0667","Switzerland","CH","CHE","Valais","","18301","1756450627"
"Bukungu","Bukungu","1.4361","32.8686","Uganda","UG","UGA","Buyende","","19033","1800027499"
"Pitseng","Pitseng","-29.0097","28.2575","Lesotho","LS","LSO","Leribe","","18948","1426276649"
"Mill Hill","Mill Hill","51.6200","-0.2300","United Kingdom","GB","GBR","Barnet","","18451","1826875983"
"Onega","Onega","63.9167","38.0833","Russia","RU","RUS","Arkhangel’skaya Oblast’","","19030","1643716527"
"Ambatomena","Ambatomena","-19.8500","47.2500","Madagascar","MG","MDG","Antananarivo","","19000","1450833007"
"Cumberland","Cumberland","39.6515","-78.7584","United States","US","USA","Maryland","","19028","1840005621"
"Renningen","Renningen","48.7661","8.9347","Germany","DE","DEU","Baden-Württemberg","","18435","1276122703"
"Ghedi","Ghedi","45.4020","10.2803","Italy","IT","ITA","Lombardy","","18719","1380181093"
"Oirschot","Oirschot","51.5000","5.3000","Netherlands","NL","NLD","Noord-Brabant","minor","18842","1528394543"
"San Rafael La Independencia","San Rafael La Independencia","15.7167","-91.5333","Guatemala","GT","GTM","Huehuetenango","minor","18733","1320263088"
"Peduasi","Peduasi","5.8500","-0.1833","Ghana","GH","GHA","Eastern","","18701","1288311590"
"Aburi","Aburi","5.8500","-0.1833","Ghana","GH","GHA","Eastern","","18701","1288664364"
"Devadānappatti","Devadanappatti","10.1467","77.6439","India","IN","IND","Tamil Nādu","","18415","1356815991"
"Ambatosia","Ambatosia","-14.6667","48.6500","Madagascar","MG","MDG","Mahajanga","","19000","1450014138"
"Eitorf","Eitorf","50.7697","7.4517","Germany","DE","DEU","North Rhine-Westphalia","","18751","1276150855"
"Khokha","Khokha","25.9741","87.4062","India","IN","IND","Bihār","","17908","1356887547"
"Caconde","Caconde","-21.5289","-46.6439","Brazil","BR","BRA","São Paulo","","18976","1076573900"
"Midlothian","Midlothian","37.4856","-77.6522","United States","US","USA","Virginia","","19016","1840037396"
"Scituate","Scituate","42.1992","-70.7590","United States","US","USA","Massachusetts","","19016","1840053577"
"Shimanovsk","Shimanovsk","52.0000","127.7000","Russia","RU","RUS","Amurskaya Oblast’","","18643","1643749021"
"Cortes","Cortes","9.7167","123.8833","Philippines","PH","PHL","Bohol","","18344","1608052044"
"Dŭstí","Dusti","37.3486","68.6733","Tajikistan","TJ","TJK","Khatlon","minor","18800","1762895050"
"Befandriana Atsimo","Befandriana Atsimo","-22.1000","43.8833","Madagascar","MG","MDG","Toliara","","19000","1450758154"
"Beshkent Shahri","Beshkent Shahri","38.8167","65.6500","Uzbekistan","UZ","UZB","Qashqadaryo","minor","18900","1860873640"
"Uwchlan","Uwchlan","40.0522","-75.6679","United States","US","USA","Pennsylvania","","19013","1840145647"
"Haukipudas","Haukipudas","65.1833","25.3500","Finland","FI","FIN","Pohjois-Pohjanmaa","","18994","1246294051"
"Heerde","Heerde","52.3833","6.0500","Netherlands","NL","NLD","Gelderland","minor","18776","1528444731"
"Laukaa","Laukaa","62.4167","25.9500","Finland","FI","FIN","Keski-Suomi","minor","18978","1246056174"
"Tiruvadamarudūr","Tiruvadamarudur","10.9065","79.4482","India","IN","IND","Tamil Nādu","","18169","1356628264"
"Mitsinjo","Mitsinjo","-16.0000","45.8667","Madagascar","MG","MDG","Mahajanga","","19000","1450222035"
"San Sebastián Salitrillo","San Sebastian Salitrillo","13.9500","-89.6333","El Salvador","SV","SLV","Santa Ana","","18566","1222442997"
"Beni Zouli","Beni Zouli","30.4839","-5.8619","Morocco","MA","MAR","Drâa-Tafilalet","","18941","1504549752"
"Stord","Stord","59.8081","5.4664","Norway","NO","NOR","Vestland","minor","18919","1578682657"
"Bánovce nad Bebravou","Banovce nad Bebravou","48.7186","18.2581","Slovakia","SK","SVK","Nitra","minor","18350","1703803804"
"Odžak","Odzak","45.0106","18.3264","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","18821","1070191800"
"Warwick","Warwick","40.1558","-76.2799","United States","US","USA","Pennsylvania","","19000","1840142491"
"Kamienna Góra","Kamienna Gora","50.7833","16.0333","Poland","PL","POL","Dolnośląskie","minor","17998","1616000725"
"Heanor","Heanor","53.0140","-1.3540","United Kingdom","GB","GBR","Derbyshire","","17251","1826825779"
"Las Terrenas","Las Terrenas","19.3200","-69.5300","Dominican Republic","DO","DOM","Cibao Nordeste","","18829","1214125963"
"Wodonga","Wodonga","-36.1214","146.8881","Australia","AU","AUS","Victoria","","18948","1036788540"
"Aci Castello","Aci Castello","37.5556","15.1458","Italy","IT","ITA","Sicilia","","18577","1380669232"
"Winchester","Winchester","38.0018","-84.1908","United States","US","USA","Kentucky","","18993","1840015216"
"Sylvania","Sylvania","41.7100","-83.7092","United States","US","USA","Ohio","","18991","1840009255"
"Ribera","Ribera","37.4994","13.2650","Italy","IT","ITA","Sicilia","","18832","1380605395"
"Rizal","Rizal","14.1083","121.3917","Philippines","PH","PHL","Laguna","","18332","1608889949"
"Zola Predosa","Zola Predosa","44.4883","11.2181","Italy","IT","ITA","Emilia-Romagna","","18939","1380463262"
"Vitré","Vitre","48.1233","-1.2094","France","FR","FRA","Bretagne","","18487","1250153250"
"Motomachi","Motomachi","43.8242","144.1074","Japan","JP","JPN","Hokkaidō","","18943","1392132388"
"Sebaste","Sebaste","11.5901","122.0945","Philippines","PH","PHL","Antique","","18816","1608993841"
"Chichiriviche","Chichiriviche","10.9343","-68.2771","Venezuela","VE","VEN","Falcón","minor","18960","1862197989"
"Chorozinho","Chorozinho","-4.3000","-38.4969","Brazil","BR","BRA","Ceará","","18915","1076780139"
"Raghunāthpur","Raghunathpur","25.8440","86.9697","India","IN","IND","Bihār","","17596","1356067962"
"Dorval","Dorval","45.4500","-73.7500","Canada","CA","CAN","Quebec","","18980","1124933556"
"Prenzlau","Prenzlau","53.3167","13.8667","Germany","DE","DEU","Brandenburg","minor","18849","1276456147"
"Qitai","Qitai","41.5494","113.5339","China","CN","CHN","Inner Mongolia","minor","18831","1156174797"
"Jiaoxiyakou","Jiaoxiyakou","26.1274","102.4502","China","CN","CHN","Yunnan","minor","18894","1156159282"
"Avion","Avion","50.4097","2.8328","France","FR","FRA","Hauts-de-France","","17625","1250888907"
"Rāzole","Razole","16.4761","81.8391","India","IN","IND","Andhra Pradesh","","17888","1356469151"
"Korsimoro","Korsimoro","12.8250","-1.0672","Burkina Faso","BF","BFA","Centre-Nord","minor","18875","1854567141"
"Altamira","Altamira","19.6667","-70.8333","Dominican Republic","DO","DOM","Cibao Norte","","18868","1214514689"
"Nal Khera","Nal Khera","23.8357","76.2364","India","IN","IND","Madhya Pradesh","","18741","1356201417"
"Ajacuba","Ajacuba","20.0833","-99.1167","Mexico","MX","MEX","Hidalgo","","18872","1484132188"
"Qo’shko’pir","Qo'shko'pir","41.5333","60.3500","Uzbekistan","UZ","UZB","Xorazm","minor","18700","1860996001"
"Rawa Mazowiecka","Rawa Mazowiecka","51.7658","20.2533","Poland","PL","POL","Łódzkie","minor","17765","1616083640"
"Marktoberdorf","Marktoberdorf","47.7667","10.6167","Germany","DE","DEU","Bavaria","minor","18769","1276720955"
"Guachucal","Guachucal","0.9667","-77.7333","Colombia","CO","COL","Nariño","minor","18845","1170078700"
"Appley Bridge","Appley Bridge","53.5790","-2.7210","United Kingdom","GB","GBR","Lancashire","","18500","1826409679"
"Montceau-les-Mines","Montceau-les-Mines","46.6669","4.3689","France","FR","FRA","Bourgogne-Franche-Comté","","17897","1250799530"
"Ban Bo Haeo","Ban Bo Haeo","18.3048","99.4657","Thailand","TH","THA","Lampang","","18777","1764536818"
"Madalag","Madalag","11.5269","122.3064","Philippines","PH","PHL","Aklan","","18890","1608730047"
"Bourbonnais","Bourbonnais","41.1830","-87.8784","United States","US","USA","Illinois","","18959","1840011692"
"Kalynivka","Kalynivka","49.4472","28.5231","Ukraine","UA","UKR","Vinnytska Oblast","minor","18906","1804606611"
"San Juan Ermita","San Juan Ermita","14.7667","-89.4333","Guatemala","GT","GTM","Chiquimula","minor","18751","1320736877"
"Durango","Durango","37.2659","-107.8781","United States","US","USA","Colorado","","18953","1840018929"
"Jucuapa","Jucuapa","13.5167","-88.3833","El Salvador","SV","SLV","Usulután","","18442","1222623868"
"Itapororoca","Itapororoca","-6.8300","-35.2469","Brazil","BR","BRA","Paraíba","","18823","1076439855"
"Santo Antônio do Sudoeste","Santo Antonio do Sudoeste","-26.0700","-53.7228","Brazil","BR","BRA","Paraná","","18893","1076623893"
"Āb Pakhsh","Ab Pakhsh","29.3606","51.0683","Iran","IR","IRN","Būshehr","","18913","1364462555"
"Villaquilambre","Villaquilambre","42.6167","-5.6000","Spain","ES","ESP","Castille-Leon","","18597","1724727940"
"Central Point","Central Point","42.3764","-122.9111","United States","US","USA","Oregon","","18948","1840018672"
"Choghādak","Choghadak","28.9864","51.0375","Iran","IR","IRN","Būshehr","","18702","1364637540"
"Ansonia","Ansonia","41.3443","-73.0689","United States","US","USA","Connecticut","","18945","1840004846"
"Castel Maggiore","Castel Maggiore","44.5667","11.3667","Italy","IT","ITA","Emilia-Romagna","","18349","1380605846"
"Be’er Ya‘aqov","Be'er Ya\`aqov","31.9436","34.8392","Israel","IL","ISR","Central","","18401","1376941719"
"Rubiataba","Rubiataba","-15.1639","-49.8028","Brazil","BR","BRA","Goiás","","18915","1076760210"
"Pindoretama","Pindoretama","-4.0278","-38.3058","Brazil","BR","BRA","Ceará","","18683","1076655483"
"Baena","Baena","37.6167","-4.3167","Spain","ES","ESP","Andalusia","","18885","1724279990"
"Bergeijk","Bergeijk","51.3167","5.3500","Netherlands","NL","NLD","Noord-Brabant","minor","18754","1528769857"
"Gautier","Gautier","30.4106","-88.6568","United States","US","USA","Mississippi","","18932","1840013937"
"Fihaonana","Fihaonana","-18.6000","47.2000","Madagascar","MG","MDG","Antananarivo","","18846","1450022062"
"Kakraul","Kakraul","26.3620","86.0139","India","IN","IND","Bihār","","16991","1356799355"
"Elumalai","Elumalai","9.8650","77.6992","India","IN","IND","Tamil Nādu","","18651","1356327493"
"Monroe","Monroe","41.3379","-73.2250","United States","US","USA","Connecticut","","18927","1840035488"
"Náousa","Naousa","40.6333","22.0667","Greece","GR","GRC","Kentrikí Makedonía","minor","18882","1300524747"
"Affton","Affton","38.5499","-90.3264","United States","US","USA","Missouri","","18925","1840006124"
"Tadó","Tado","5.2667","-76.5667","Colombia","CO","COL","Chocó","minor","18906","1170279230"
"Narangba","Narangba","-27.2015","152.9655","Australia","AU","AUS","Queensland","","18573","1036559054"
"Nauen","Nauen","52.6000","12.8831","Germany","DE","DEU","Brandenburg","","18854","1276864648"
"Cachoeirinha","Cachoeirinha","-8.4858","-36.2328","Brazil","BR","BRA","Pernambuco","","18819","1076462006"
"Contamana","Contamana","-7.3333","-75.0167","Peru","PE","PER","Loreto","minor","18921","1604465551"
"Baturbāri","Baturbari","26.2281","87.4714","India","IN","IND","Bihār","","17241","1356606160"
"Guareí","Guarei","-23.3728","-48.1842","Brazil","BR","BRA","São Paulo","","18887","1076364248"
"Ponmana","Ponmana","8.3867","77.2006","India","IN","IND","Tamil Nādu","","17793","1356201540"
"Itatira","Itatira","-4.5289","-39.6219","Brazil","BR","BRA","Ceará","","18894","1076647761"
"Ipaba","Ipaba","-19.4139","-42.4189","Brazil","BR","BRA","Minas Gerais","","18769","1076725503"
"Polohy","Polohy","47.4796","36.2611","Ukraine","UA","UKR","Zaporizka Oblast","minor","18886","1804554214"
"Pirkkala","Pirkkala","61.4667","23.6500","Finland","FI","FIN","Pirkanmaa","minor","18913","1246586300"
"Highland","Highland","40.4276","-111.7957","United States","US","USA","Utah","","18913","1840020167"
"Antonina","Antonina","-25.4289","-48.7119","Brazil","BR","BRA","Paraná","","18891","1076700351"
"Sudbury","Sudbury","42.3847","-71.4234","United States","US","USA","Massachusetts","","18912","1840053489"
"San Sebastián de Buenavista","San Sebastian de Buenavista","9.2403","-74.3556","Colombia","CO","COL","Magdalena","minor","18865","1170564424"
"Chapada dos Guimarães","Chapada dos Guimaraes","-15.4608","-55.7500","Brazil","BR","BRA","Mato Grosso","","18906","1076928999"
"Fakfak","Fakfak","-2.9167","132.3000","Indonesia","ID","IDN","Papua Barat","minor","18900","1360400295"
"Fox Crossing","Fox Crossing","44.2228","-88.4763","United States","US","USA","Wisconsin","","18907","1840038105"
"Massaguet","Massaguet","12.4742","15.4428","Chad","TD","TCD","Hadjer-Lamis","","18872","1148369489"
"Paks","Paks","46.6220","18.8559","Hungary","HU","HUN","Tolna","minor","18788","1348932688"
"Franklin Farm","Franklin Farm","38.9133","-77.3969","United States","US","USA","Virginia","","18906","1840041752"
"Mo i Rana","Mo i Rana","66.3128","14.1428","Norway","NO","NOR","Nordland","minor","18899","1578067925"
"Arroio do Meio","Arroio do Meio","-29.4008","-51.9450","Brazil","BR","BRA","Rio Grande do Sul","","18783","1076785789"
"Pālaiyampatti","Palaiyampatti","9.5392","78.0984","India","IN","IND","Tamil Nādu","","18502","1356204531"
"Tonantins","Tonantins","-2.8728","-67.8019","Brazil","BR","BRA","Amazonas","","18897","1076820908"
"Kabala","Kabala","9.5833","-11.5500","Sierra Leone","SL","SLE","Northern","","18770","1694931423"
"Kāriyāpatti","Kariyapatti","9.6741","78.1007","India","IN","IND","Tamil Nādu","","18454","1356464584"
"Togba","Togba","7.1000","1.6667","Benin","BJ","BEN","Couffo","","18674","1204084609"
"Bad Reichenhall","Bad Reichenhall","47.7247","12.8769","Germany","DE","DEU","Bavaria","minor","18456","1276059039"
"Augusta","Augusta","44.3341","-69.7319","United States","US","USA","Maine","admin","18895","1840000302"
"Hoppegarten","Hoppegarten","52.5167","13.6667","Germany","DE","DEU","Brandenburg","","18322","1276233204"
"Hala","Hala","26.8272","103.9938","China","CN","CHN","Guizhou","","18797","1156208195"
"Nelidovo","Nelidovo","56.2167","32.8000","Russia","RU","RUS","Tverskaya Oblast’","","18883","1643775202"
"Conceição","Conceicao","-7.5619","-38.5089","Brazil","BR","BRA","Paraíba","","18860","1076029380"
"Louny","Louny","50.3571","13.7968","Czechia","CZ","CZE","Ústecký Kraj","","18156","1203988380"
"Cocoa","Cocoa","28.3820","-80.7675","United States","US","USA","Florida","","18891","1840015092"
"Anzoátegui","Anzoategui","4.6339","-75.0972","Colombia","CO","COL","Tolima","minor","18849","1170133861"
"Sestri Levante","Sestri Levante","44.2733","9.3932","Italy","IT","ITA","Liguria","","18339","1380139186"
"Padre Paraíso","Padre Paraiso","-17.0719","-41.5239","Brazil","BR","BRA","Minas Gerais","","18849","1076628988"
"Vélez","Velez","6.0103","-73.6763","Colombia","CO","COL","Santander","minor","18808","1170298824"
"Tādigadapa","Tadigadapa","16.4713","80.6970","India","IN","IND","Andhra Pradesh","","17090","1356158447"
"Alfeld","Alfeld","51.9886","9.8269","Germany","DE","DEU","Lower Saxony","","18626","1276733070"
"Bartow","Bartow","27.8868","-81.8214","United States","US","USA","Florida","","18880","1840014128"
"Kalabahi","Kalabahi","-8.2167","124.5167","Indonesia","ID","IDN","Nusa Tenggara Timur","minor","18804","1360839467"
"Muttenz","Muttenz","47.5167","7.6500","Switzerland","CH","CHE","Basel-Landschaft","","17809","1756008285"
"Kovylkino","Kovylkino","54.0403","43.9214","Russia","RU","RUS","Mordoviya","","18857","1643328958"
"San Julián","San Julian","13.6976","-89.5596","El Salvador","SV","SLV","Sonsonate","","18648","1222097757"
"Boissy-Saint-Léger","Boissy-Saint-Leger","48.7503","2.5097","France","FR","FRA","Île-de-France","","16977","1250749700"
"Villanueva","Villanueva","10.6000","-72.9833","Colombia","CO","COL","La Guajira","minor","18804","1170525155"
"Rosedale","Rosedale","35.3886","-119.2058","United States","US","USA","California","","18875","1840019141"
"Fushë-Krujë","Fushe-Kruje","41.4833","19.7167","Albania","AL","ALB","Durrës","","18477","1008652935"
"Tessenderlo","Tessenderlo","51.0697","5.0897","Belgium","BE","BEL","Flanders","","18514","1056426796"
"Sirvel","Sirvel","15.3170","78.5330","India","IN","IND","Andhra Pradesh","","18478","1356385349"
"Araç","Arac","41.2422","33.3283","Turkey","TR","TUR","Kastamonu","minor","18863","1792161933"
"Ebbw Vale","Ebbw Vale","51.7800","-3.2100","United Kingdom","GB","GBR","Blaenau Gwent","","18558","1826821223"
"Piatykhatky","Piatykhatky","48.4126","33.7034","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","18845","1804098931"
"Mellacheruvu","Mellacheruvu","16.8173","79.9331","India","IN","IND","Andhra Pradesh","","18550","1356016977"
"Százhalombatta","Szazhalombatta","47.3004","18.9136","Hungary","HU","HUN","Pest","","18241","1348329361"
"Taft","Taft","11.9058","125.4181","Philippines","PH","PHL","Eastern Samar","","18786","1608376132"
"Nunuñgan","Nunungan","7.8167","123.9667","Philippines","PH","PHL","Lanao del Norte","","18827","1608001234"
"Tocaima","Tocaima","4.5000","-74.6667","Colombia","CO","COL","Cundinamarca","minor","18790","1170682674"
"Nefas Mewch’a","Nefas Mewch'a","11.7333","38.4667","Ethiopia","ET","ETH","Āmara","","18691","1231652571"
"Brownwood","Brownwood","31.7127","-98.9767","United States","US","USA","Texas","","18865","1840019519"
"Futog","Futog","45.2333","19.7167","Serbia","RS","SRB","","","18641","1688141881"
"Oulmes","Oulmes","33.4450","-6.0011","Morocco","MA","MAR","Rabat-Salé-Kénitra","","18786","1504050150"
"Tādikonda","Tadikonda","16.4167","80.4542","India","IN","IND","Andhra Pradesh","","18505","1356715367"
"Maimón","Maimon","18.7700","-70.3300","Dominican Republic","DO","DOM","Cibao Sur","","18655","1214149201"
"Molakālumuru","Molakalumuru","14.7178","76.7468","India","IN","IND","Karnātaka","","18655","1356340126"
"Hopkins","Hopkins","44.9259","-93.4056","United States","US","USA","Minnesota","","18861","1840006749"
"Kara-Köl","Kara-Kol","41.6311","72.6686","Kyrgyzstan","KG","KGZ","Jalal-Abad","","18843","1417090900"
"Kalach","Kalach","50.4333","41.0000","Russia","RU","RUS","Voronezhskaya Oblast’","","18834","1643931782"
"Umirim","Umirim","-3.6769","-39.3500","Brazil","BR","BRA","Ceará","","18802","1076894065"
"Monor","Monor","47.3475","19.4489","Hungary","HU","HUN","Pest","minor","18476","1348049694"
"Milton","Milton","43.0406","-73.8998","United States","US","USA","New York","","18856","1840058308"
"Le Pontet","Le Pontet","43.9642","4.8600","France","FR","FRA","Provence-Alpes-Côte d’Azur","","17273","1250760034"
"Alzenau in Unterfranken","Alzenau in Unterfranken","50.0667","9.0667","Germany","DE","DEU","Bavaria","","18542","1276942017"
"Anandpur","Anandpur","31.2393","76.5025","India","IN","IND","Punjab","","18333","1356058243"
"Berkovitsa","Berkovitsa","43.2333","23.1167","Bulgaria","BG","BGR","Montana","minor","18822","1100553391"
"Taufkirchen","Taufkirchen","48.0500","11.6167","Germany","DE","DEU","Bavaria","","18035","1276946888"
"Bradfordville","Bradfordville","30.5735","-84.2055","United States","US","USA","Florida","","18850","1840028817"
"Crimmitschau","Crimmitschau","50.8181","12.3875","Germany","DE","DEU","Saxony","","18545","1276188517"
"Ngathainggyaung","Ngathainggyaung","17.4000","95.0833","Myanmar","MM","MMR","Ayeyarwady","","18743","1104435662"
"Târgu Secuiesc","Targu Secuiesc","45.9969","26.1406","Romania","RO","ROU","Covasna","","18491","1642834541"
"Villa Nueva","Villa Nueva","-32.4331","-63.2475","Argentina","AR","ARG","Córdoba","","18818","1032374249"
"Heguri","Heguri","34.6292","135.7006","Japan","JP","JPN","Nara","","18088","1392003472"
"Thomasville","Thomasville","30.8394","-83.9782","United States","US","USA","Georgia","","18844","1840015893"
"Antotohazo","Antotohazo","-18.4667","47.0833","Madagascar","MG","MDG","Antananarivo","","18774","1450165853"
"Yeddumailāram","Yeddumailaram","17.5011","78.1242","India","IN","IND","Andhra Pradesh","","17941","1356460296"
"Balilihan","Balilihan","9.7500","123.9667","Philippines","PH","PHL","Bohol","","18694","1608731665"
"Osny","Osny","49.0592","2.0625","France","FR","FRA","Île-de-France","","17446","1250209813"
"Khorabar","Khorabar","26.7403","83.8889","India","IN","IND","Uttar Pradesh","","17982","1356389767"
"Vestby","Vestby","59.5750","10.7319","Norway","NO","NOR","Viken","","18699","1578197278"
"Dzüünharaa","Dzuunharaa","48.8500","106.4583","Mongolia","MN","MNG","Selenge","","18830","1496200032"
"Koriāpatti","Koriapatti","26.1149","86.9905","India","IN","IND","Bihār","","17538","1356690638"
"Paglat","Paglat","6.7811","124.7849","Philippines","PH","PHL","Maguindanao","","18727","1608170498"
"Gossau","Gossau","47.4167","9.2500","Switzerland","CH","CHE","Sankt Gallen","","18171","1756392610"
"Pôrto Acre","Porto Acre","-9.5878","-67.5328","Brazil","BR","BRA","Acre","","18824","1076171692"
"Springboro","Springboro","39.5615","-84.2348","United States","US","USA","Ohio","","18831","1840009573"
"Mennzel Bou Zelfa","Mennzel Bou Zelfa","36.6817","10.5847","Tunisia","TN","TUN","Nabeul","","18551","1788372559"
"Chowchilla","Chowchilla","37.1095","-120.2349","United States","US","USA","California","","18826","1840018919"
"Ferguson","Ferguson","38.7490","-90.2950","United States","US","USA","Missouri","","18826","1840008584"
"Altus","Altus","34.6565","-99.3061","United States","US","USA","Oklahoma","","18825","1840019202"
"Lukovit","Lukovit","43.2000","24.1667","Bulgaria","BG","BGR","Lovech","","18788","1100460209"
"Māḩiş","Mahis","31.9833","35.7667","Jordan","JO","JOR","Al Balqā’","","17754","1400449886"
"Starobilsk","Starobilsk","49.2829","38.8974","Ukraine","UA","UKR","Luhanska Oblast","minor","18796","1804913112"
"Rājmahal","Rajmahal","25.0500","87.8400","India","IN","IND","West Bengal","","17974","1356144679"
"Avon","Avon","41.7907","-72.8538","United States","US","USA","Connecticut","","18821","1840034005"
"Or ‘Aqiva","Or \`Aqiva","32.5","34.9167","Israel","IL","ISR","Haifa","","17759","1376081727"
"Ascención de Guarayos","Ascencion de Guarayos","-15.8922","-63.1881","Bolivia","BO","BOL","Santa Cruz","","18816","1068006500"
"Carlos Spegazzini","Carlos Spegazzini","-34.8833","-58.5667","Argentina","AR","ARG","Buenos Aires","","18820","1032807626"
"Kegalle","Kegalle","7.2531","80.3453","Sri Lanka","LK","LKA","Sabaragamuwa","","17962","1144224835"
"Soalkuchi","Soalkuchi","26.1700","91.5700","India","IN","IND","Assam","","18655","1356794202"
"Santa Fe Springs","Santa Fe Springs","33.9329","-118.0625","United States","US","USA","California","","18817","1840021865"
"Marsciano","Marsciano","42.9167","12.3333","Italy","IT","ITA","Umbria","","18701","1380905185"
"Sortavala","Sortavala","61.7056","30.6958","Russia","RU","RUS","Kareliya","minor","18801","1643799487"
"Batalha","Batalha","-9.6778","-37.1247","Brazil","BR","BRA","Alagoas","","18757","1076863522"
"Mukkūdal","Mukkudal","8.7431","77.5225","India","IN","IND","Tamil Nādu","","18420","1356204226"
"Szamotuły","Szamotuly","52.6000","16.5833","Poland","PL","POL","Wielkopolskie","minor","18639","1616500868"
"Montrouis","Montrouis","18.9506","-72.7044","Haiti","HT","HTI","Ouest","","18419","1332947082"
"Lessines","Lessines","50.7167","3.8333","Belgium","BE","BEL","Wallonia","","18552","1056283764"
"Siderno Marina","Siderno Marina","38.2667","16.3000","Italy","IT","ITA","Calabria","","18231","1380554937"
"Middelburg","Middelburg","-31.4939","25.0172","South Africa","ZA","ZAF","Eastern Cape","","18164","1710010705"
"Bensenville","Bensenville","41.9579","-87.9442","United States","US","USA","Illinois","","18801","1840011398"
"Thorold","Thorold","43.1167","-79.2000","Canada","CA","CAN","Ontario","","18801","1124718251"
"Palmeiras","Palmeiras","-2.6450","-44.8950","Brazil","BR","BRA","Maranhão","","18764","1076244098"
"East Lyme","East Lyme","41.3668","-72.2353","United States","US","USA","Connecticut","","18798","1840045011"
"Ban Mai","Ban Mai","14.9629","102.0314","Thailand","TH","THA","Nakhon Ratchasima","","18255","1764316736"
"Owego","Owego","42.0881","-76.1905","United States","US","USA","New York","","18796","1840004668"
"Novyy Oskol","Novyy Oskol","50.7583","37.8736","Russia","RU","RUS","Belgorodskaya Oblast’","","18763","1643972982"
"Mānullahpatti","Manullahpatti","26.0619","87.1751","India","IN","IND","Bihār","","17632","1356760132"
"Bremervörde","Bremervorde","53.4833","9.1333","Germany","DE","DEU","Lower Saxony","","18666","1276000575"
"Kiliia","Kiliia","45.4500","29.2667","Ukraine","UA","UKR","Odeska Oblast","minor","18745","1804423656"
"Bni Bouayach","Bni Bouayach","35.0986","-3.8397","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","18271","1504579713"
"Catarina","Catarina","-6.1308","-39.8778","Brazil","BR","BRA","Ceará","","18745","1076764846"
"Weißenburg","Weissenburg","49.0306","10.9719","Germany","DE","DEU","Bavaria","minor","18593","1276099657"
"Villa Rica","Villa Rica","2.5167","-76.8500","Colombia","CO","COL","Cauca","","18761","1170377938"
"Doñihue","Donihue","-34.2261","-70.9650","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","18764","1152472682"
"San Lorenzo de El Escorial","San Lorenzo de El Escorial","40.5936","-4.1428","Spain","ES","ESP","Madrid","","18454","1724204190"
"Engenheiro Coelho","Engenheiro Coelho","-22.4883","-47.2150","Brazil","BR","BRA","São Paulo","","18611","1076001478"
"Bīdestān","Bidestan","36.2311","50.1236","Iran","IR","IRN","Qazvīn","","18060","1364097069"
"Guaranésia","Guaranesia","-21.2989","-46.8028","Brazil","BR","BRA","Minas Gerais","","18714","1076203093"
"Colonia","Colonia","40.5926","-74.3148","United States","US","USA","New Jersey","","18776","1840005411"
"Ronkonkoma","Ronkonkoma","40.8037","-73.1246","United States","US","USA","New York","","18775","1840005071"
"Kappiyara","Kappiyara","8.2466","77.2617","India","IN","IND","Tamil Nādu","","17645","1356216346"
"Yupiltepeque","Yupiltepeque","14.1941","-89.7913","Guatemala","GT","GTM","Jutiapa","minor","18262","1320104275"
"Beltsville","Beltsville","39.0394","-76.9211","United States","US","USA","Maryland","","18769","1840005945"
"Ključ","Kljuc","44.5333","16.7667","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","18714","1070860636"
"Kinzau-Vuete","Kinzau-Vuete","-5.4967","13.2893","Congo (Kinshasa)","CD","COD","Kongo Central","","18676","1180000185"
"Shuangluan","Shuangluan","40.9608","117.7939","China","CN","CHN","Hebei","minor","18250","1156573747"
"Oxon Hill","Oxon Hill","38.7884","-76.9727","United States","US","USA","Maryland","","18763","1840026688"
"Emba","Emba","48.8267","58.1442","Kazakhstan","KZ","KAZ","","","18760","1398915734"
"Stekene","Stekene","51.2000","4.0333","Belgium","BE","BEL","Flanders","","18352","1056000195"
"Goyty","Goyty","43.1642","45.6228","Russia","RU","RUS","Chechnya","","18534","1643961202"
"Ibititá","Ibitita","-11.5469","-41.9778","Brazil","BR","BRA","Bahia","","18727","1076591656"
"Concord","Concord","38.5117","-90.3574","United States","US","USA","Missouri","","18760","1840006126"
"Onalaska","Onalaska","43.8883","-91.2075","United States","US","USA","Wisconsin","","18760","1840002579"
"Retiro","Retiro","-36.0333","-71.7667","Chile","CL","CHL","Maule","","18754","1152001329"
"Konz","Konz","49.7000","6.5833","Germany","DE","DEU","Rhineland-Palatinate","","18348","1276863750"
"Bad Dürkheim","Bad Durkheim","49.4594","8.1681","Germany","DE","DEU","Rhineland-Palatinate","minor","18576","1276602357"
"Reota","Reota","25.8194","86.3061","India","IN","IND","Bihār","","17437","1356255453"
"Farias Brito","Farias Brito","-6.9308","-39.5658","Brazil","BR","BRA","Ceará","","18720","1076558582"
"Ajaigarh","Ajaigarh","24.8988","80.2592","India","IN","IND","Madhya Pradesh","","18476","1356098210"
"Blooming Grove","Blooming Grove","41.3948","-74.1840","United States","US","USA","New York","","18752","1840057241"
"Buckhall","Buckhall","38.7239","-77.4476","United States","US","USA","Virginia","","18751","1840026707"
"Rufino","Rufino","-34.2667","-62.7000","Argentina","AR","ARG","Santa Fe","","18727","1032127696"
"Longjia","Longjia","19.1487","110.3209","China","CN","CHN","Hainan","","18385","1156017075"
"Camrose","Camrose","53.0167","-112.8333","Canada","CA","CAN","Alberta","","18742","1124351657"
"Ranquitte","Ranquitte","19.4167","-72.0833","Haiti","HT","HTI","Nord","","18197","1332218380"
"Los Lagos","Los Lagos","-39.8500","-72.8333","Chile","CL","CHL","Los Ríos","","18733","1152340454"
"Gāndarbal","Gandarbal","34.2262","74.7748","India","IN","IND","Jammu and Kashmīr","","18111","1356684962"
"Domodossola","Domodossola","46.1161","8.2911","Italy","IT","ITA","Piedmont","","18237","1380041863"
"Salamina","Salamina","5.4083","-75.4900","Colombia","CO","COL","Caldas","minor","18704","1170954127"
"Tottiyam","Tottiyam","10.9880","78.3363","India","IN","IND","Tamil Nādu","","18061","1356454221"
"Cambridge","Cambridge","-37.8833","175.4667","New Zealand","NZ","NZL","Waikato","","18651","1554000848"
"Jbabra","Jbabra","34.4314","-4.9642","Morocco","MA","MAR","Fès-Meknès","","18592","1504581289"
"Schönefeld","Schonefeld","52.3886","13.5048","Germany","DE","DEU","Brandenburg","","18499","1276663675"
"Kumbhrāj","Kumbhraj","24.3734","77.0484","India","IN","IND","Madhya Pradesh","","18552","1356048844"
"Berea","Berea","41.3696","-81.8641","United States","US","USA","Ohio","","18724","1840000588"
"Ammāpettai","Ammapettai","10.7948","79.3199","India","IN","IND","Tamil Nādu","","18349","1356150020"
"Miyatoko","Miyatoko","33.6992","130.9202","Japan","JP","JPN","Fukuoka","","18599","1392427893"
"Round Lake","Round Lake","42.3435","-88.1059","United States","US","USA","Illinois","","18720","1840011170"
"Ottawa","Ottawa","41.3555","-88.8261","United States","US","USA","Illinois","","18719","1840009272"
"Cafarnaum","Cafarnaum","-11.6939","-41.4678","Brazil","BR","BRA","Bahia","","18695","1076083831"
"Édessa","Edessa","40.8000","22.0500","Greece","GR","GRC","Kentrikí Makedonía","minor","18229","1300917524"
"Murambi","Murambi","-1.8133","30.0456","Rwanda","RW","RWA","Northern Province","","17892","1646072975"
"Wisconsin Rapids","Wisconsin Rapids","44.3927","-89.8265","United States","US","USA","Wisconsin","","18711","1840002333"
"Jora Khurd","Jora Khurd","26.4893","77.9758","India","IN","IND","Madhya Pradesh","","18126","1356704994"
"Abong Mbang","Abong Mbang","3.9833","13.1667","Cameroon","CM","CMR","Est","","18700","1120726386"
"Sanpaicun","Sanpaicun","24.6642","112.2935","China","CN","CHN","Guangdong","","18381","1156024999"
"Destelbergen","Destelbergen","51.0500","3.8000","Belgium","BE","BEL","Flanders","","18026","1056444494"
"Tsivory","Tsivory","-24.0683","46.0756","Madagascar","MG","MDG","Toliara","","18694","1450216088"
"Morales","Morales","8.3133","-73.8719","Colombia","CO","COL","Bolívar","minor","18678","1170533443"
"Inhapi","Inhapi","-9.2189","-37.7539","Brazil","BR","BRA","Alagoas","","18652","1076122999"
"Çerkeş","Cerkes","40.8139","32.8908","Turkey","TR","TUR","Çankırı","minor","18694","1792814201"
"Leteri","Leteri","24.0598","77.4086","India","IN","IND","Madhya Pradesh","","18555","1356313596"
"Laives","Laives","46.4228","11.3348","Italy","IT","ITA","Trentino-Alto Adige","","17954","1380561318"
"Picuí","Picui","-6.5550","-36.3489","Brazil","BR","BRA","Paraíba","","18670","1076876343"
"Rutigliano","Rutigliano","40.9333","16.9000","Italy","IT","ITA","Puglia","","18591","1380706112"
"Asakapalle","Asakapalle","17.7364","83.3000","India","IN","IND","Andhra Pradesh","","18247","1356431305"
"Caiapônia","Caiaponia","-16.9569","-51.8100","Brazil","BR","BRA","Goiás","","16757","1076081886"
"Sidi Allal Tazi","Sidi Allal Tazi","34.5197","-6.3236","Morocco","MA","MAR","Rabat-Salé-Kénitra","","18055","1504240446"
"Surovikino","Surovikino","48.6000","42.8500","Russia","RU","RUS","Volgogradskaya Oblast’","","18685","1643870086"
"Toflea","Toflea","46.0637","27.3341","Romania","RO","ROU","Galaţi","","18521","1642422327"
"Qaryat al Qī‘ān","Qaryat al Qi\`an","32.0167","11.5000","Libya","LY","LBY","Nālūt","","18693","1434845164"
"Andéranboukan","Anderanboukan","15.4283","3.0183","Mali","ML","MLI","Gao","","18688","1466636444"
"Doura","Doura","12.0167","-9.0167","Guinea","GN","GIN","Kankan","","18675","1324130854"
"Mumford","Mumford","5.2625","-0.7578","Ghana","GH","GHA","Central","","18368","1288614778"
"Ibipeba","Ibipeba","-11.6408","-42.0108","Brazil","BR","BRA","Bahia","","18674","1076930608"
"Keskin","Keskin","39.6731","33.6136","Turkey","TR","TUR","Kırıkkale","minor","18139","1792416544"
"Pitimbu","Pitimbu","-7.4708","-34.8089","Brazil","BR","BRA","Paraíba","","18685","1076000940"
"Santiago de María","Santiago de Maria","13.4833","-88.4667","El Salvador","SV","SLV","Usulután","","18201","1222971304"
"Sendārappatti","Sendarappatti","11.4373","78.5215","India","IN","IND","Tamil Nādu","","18342","1356253655"
"Carlos Chagas","Carlos Chagas","-17.7028","-40.7658","Brazil","BR","BRA","Minas Gerais","","18674","1076618010"
"Tummalapenta","Tummalapenta","14.8997","80.0687","India","IN","IND","Andhra Pradesh","","18164","1356859571"
"Nīār","Niar","38.2369","48.3272","Iran","IR","IRN","Ardabīl","","18405","1364163489"
"Liuma","Liuma","25.6682","105.8732","China","CN","CHN","Guizhou","","18576","1156279905"
"Jhagarua","Jhagarua","26.0059","86.3496","India","IN","IND","Bihār","","17406","1356269615"
"Vovchansk","Vovchansk","50.2881","36.9461","Ukraine","UA","UKR","Kharkivska Oblast","minor","18644","1804820380"
"Capua","Capua","41.1056","14.2139","Italy","IT","ITA","Campania","","18293","1380557016"
"Marshfield","Marshfield","44.6627","-90.1728","United States","US","USA","Wisconsin","","18668","1840002336"
"Jaguaripe","Jaguaripe","-13.1128","-38.8958","Brazil","BR","BRA","Bahia","","18647","1076014557"
"Bagnols-sur-Cèze","Bagnols-sur-Ceze","44.1625","4.6200","France","FR","FRA","Occitanie","","18091","1250852287"
"Vlotho","Vlotho","52.1667","8.8497","Germany","DE","DEU","North Rhine-Westphalia","","18429","1276438597"
"Terzigno","Terzigno","40.8000","14.5000","Italy","IT","ITA","Campania","","18584","1380036650"
"Boa Vista do Tupim","Boa Vista do Tupim","-12.6600","-40.6089","Brazil","BR","BRA","Bahia","","18658","1076115228"
"Raghunāthpur","Raghunathpur","26.1145","87.1590","India","IN","IND","Bihār","","17600","1356779063"
"Orăştie","Orastie","45.8500","23.2000","Romania","RO","ROU","Hunedoara","","18227","1642031164"
"Kavalerovo","Kavalerovo","44.2702","135.0498","Russia","RU","RUS","Primorskiy Kray","","18657","1643093138"
"Siddāpur","Siddapur","14.3470","74.8940","India","IN","IND","Karnātaka","","18546","1356082098"
"Filomeno Mata","Filomeno Mata","20.2000","-97.7000","Mexico","MX","MEX","Veracruz","minor","18367","1484846261"
"Sheridan","Sheridan","44.7961","-106.9642","United States","US","USA","Wyoming","","18660","1840021243"
"Thorne","Thorne","53.6083","-0.9583","United Kingdom","GB","GBR","Doncaster","","17295","1826750264"
"Baliangao","Baliangao","8.6667","123.6000","Philippines","PH","PHL","Misamis Occidental","","18433","1608124408"
"San Rafael Pie de la Cuesta","San Rafael Pie de la Cuesta","14.9333","-91.9167","Guatemala","GT","GTM","San Marcos","minor","18352","1320018274"
"Itapiúna","Itapiuna","-4.5639","-38.9219","Brazil","BR","BRA","Ceará","","18626","1076370750"
"Jiadong","Jiadong","22.4305","120.5471","Taiwan","TW","TWN","Pingtung","","18073","1158867196"
"Bilar","Bilar","9.7000","124.1000","Philippines","PH","PHL","Bohol","","18512","1608720598"
"Pohādi","Pohadi","26.0387","86.1612","India","IN","IND","Bihār","","17313","1356029073"
"Freilassing","Freilassing","47.8333","12.9667","Germany","DE","DEU","Bavaria","","17472","1276282147"
"Acharipallam","Acharipallam","8.1700","77.3698","India","IN","IND","Tamil Nādu","","16836","1356217808"
"Myślenice","Myslenice","49.8347","19.9389","Poland","PL","POL","Małopolskie","minor","18051","1616135502"
"Malnate","Malnate","45.8000","8.8833","Italy","IT","ITA","Lombardy","","16784","1380202782"
"Creve Coeur","Creve Coeur","38.6620","-90.4430","United States","US","USA","Missouri","","18648","1840007443"
"Ughara","Ughara","26.0563","86.0013","India","IN","IND","Bihār","","16964","1356996432"
"Johnstown","Johnstown","40.3260","-78.9194","United States","US","USA","Pennsylvania","","18647","1840001116"
"Bönen","Bonen","51.5986","7.7592","Germany","DE","DEU","North Rhine-Westphalia","","18169","1276501741"
"Ashland","Ashland","42.2573","-71.4687","United States","US","USA","Massachusetts","","18646","1840053632"
"South Frontenac","South Frontenac","44.5081","-76.4939","Canada","CA","CAN","Ontario","","18646","1124000063"
"Guanzhai","Guanzhai","26.2697","105.3089","China","CN","CHN","Guizhou","","18514","1156028952"
"Antombana","Antombana","-15.0000","50.1667","Madagascar","MG","MDG","Antsiranana","","18592","1450620743"
"Bonanza","Bonanza","14.0275","-84.6211","Nicaragua","NI","NIC","Costa Caribe Norte","minor","18633","1558074379"
"Sāhna","Sahna","30.4293","75.3813","India","IN","IND","Punjab","","18212","1356298063"
"Lora del Río","Lora del Rio","37.6500","-5.5167","Spain","ES","ESP","Andalusia","","18578","1724052917"
"Limay","Limay","48.9933","1.7358","France","FR","FRA","Île-de-France","","17147","1250570665"
"Tamri","Tamri","30.6950","-9.8250","Morocco","MA","MAR","Souss-Massa","","18577","1504501049"
"Kulu","Kulu","31.9600","77.1000","India","IN","IND","Himāchal Pradesh","","18536","1356863384"
"Unhel","Unhel","23.3379","75.5593","India","IN","IND","Madhya Pradesh","","18414","1356006108"
"Cajari","Cajari","-3.3208","-45.0108","Brazil","BR","BRA","Maranhão","","18338","1076208069"
"Fayetteville","Fayetteville","33.4501","-84.4710","United States","US","USA","Georgia","","18633","1840013755"
"Sivandipuram","Sivandipuram","8.7811","77.3428","India","IN","IND","Tamil Nādu","","18241","1356214725"
"Solrød Strand","Solrod Strand","55.5167","12.2167","Denmark","DK","DNK","Sjælland","minor","17337","1208504827"
"Bīmgal","Bimgal","18.7000","78.4667","India","IN","IND","Andhra Pradesh","","18320","1356278004"
"Jawkatiā","Jawkatia","26.7308","84.6263","India","IN","IND","Bihār","","17292","1356251351"
"Malacacheta","Malacacheta","-17.8419","-42.0769","Brazil","BR","BRA","Minas Gerais","","18602","1076342419"
"Porto Real","Porto Real","-22.4200","-44.2900","Brazil","BR","BRA","Rio de Janeiro","","18266","1076140508"
"Bistāria","Bistaria","26.1262","87.2300","India","IN","IND","Bihār","","17559","1356752035"
"Skara","Skara","58.3833","13.4333","Sweden","SE","SWE","Västra Götaland","minor","18580","1752171029"
"Bombon","Bombon","13.6867","123.1994","Philippines","PH","PHL","Camarines Sur","","17995","1608029555"
"Manganj","Manganj","26.1388","86.9944","India","IN","IND","Bihār","","17585","1356198414"
"Vanino","Vanino","49.0873","140.2425","Russia","RU","RUS","Khabarovskiy Kray","","18618","1643490098"
"Cimarron Hills","Cimarron Hills","38.8597","-104.6995","United States","US","USA","Colorado","","18619","1840028574"
"Carnaíba","Carnaiba","-7.8050","-37.7939","Brazil","BR","BRA","Pernambuco","","18574","1076801823"
"Shaogang","Shaogang","38.1584","106.0661","China","CN","CHN","Ningxia","","18548","1156652088"
"Knightdale","Knightdale","35.7911","-78.4966","United States","US","USA","North Carolina","","18614","1840016194"
"Tata","Tata","29.7428","-7.9725","Morocco","MA","MAR","Souss-Massa","","18611","1504497241"
"Beerse","Beerse","51.3167","4.8667","Belgium","BE","BEL","Flanders","","18128","1056431058"
"Cluses","Cluses","46.0603","6.5786","France","FR","FRA","Auvergne-Rhône-Alpes","","16996","1250500811"
"Unquillo","Unquillo","-31.2333","-64.3167","Argentina","AR","ARG","Córdoba","","18483","1032865903"
"Tatsuno","Tatsuno","35.9824","137.9876","Japan","JP","JPN","Nagano","","18503","1392044975"
"Orestiáda","Orestiada","41.5000","26.5333","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","18426","1300874812"
"Olho d’Água das Cunhãs","Olho d'Agua das Cunhas","-4.1389","-45.1200","Brazil","BR","BRA","Maranhão","","18601","1076622179"
"Tarifa","Tarifa","36.0140","-5.6060","Spain","ES","ESP","Andalusia","","18564","1724329319"
"Amīnpur","Aminpur","17.5241","78.3242","India","IN","IND","Andhra Pradesh","","17790","1356831114"
"Busovača","Busovaca","44.1000","17.8833","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","18488","1070855809"
"Montville","Montville","41.4636","-72.1570","United States","US","USA","Connecticut","","18607","1840045046"
"Saylac","Saylac","11.3539","43.4731","Somalia","SO","SOM","Awdal","","18600","1706903180"
"Pottanūr","Pottanur","11.1098","77.9888","India","IN","IND","Tamil Nādu","","18120","1356703006"
"Gidi","Gidi","23.6909","85.3641","India","IN","IND","Jhārkhand","","18025","1356187456"
"Sam Phran","Sam Phran","13.7270","100.2153","Thailand","TH","THA","Nakhon Pathom","minor","17611","1764639144"
"Misato","Misato","39.4616","140.5821","Japan","JP","JPN","Akita","","18493","1392003277"
"Taió","Taio","-27.1158","-49.9978","Brazil","BR","BRA","Santa Catarina","","18576","1076995333"
"Bhawānīpur","Bhawanipur","25.3944","87.1264","India","IN","IND","Bihār","","16843","1356310234"
"Lahnstein","Lahnstein","50.3011","7.6056","Germany","DE","DEU","Rhineland-Palatinate","","18111","1276335770"
"Yoshimi","Yoshimi","36.0399","139.4538","Japan","JP","JPN","Saitama","","18133","1392003109"
"Kuttālam","Kuttalam","11.0758","79.5608","India","IN","IND","Tamil Nādu","","17746","1356489790"
"Kunitomi","Kunitomi","31.9906","131.3235","Japan","JP","JPN","Miyazaki","","18460","1392003235"
"Chubek","Chubek","37.6167","69.7000","Tajikistan","TJ","TJK","Khatlon","","18490","1762738611"
"Balş","Bals","44.3500","24.0994","Romania","RO","ROU","Olt","","18164","1642139572"
"Timoktene","Timoktene","27.0217","1.0150","Algeria","DZ","DZA","Adrar","","18598","1012254407"
"Payyannūr","Payyannur","12.1500","75.1500","India","IN","IND","Kerala","","17961","1356149780"
"Phelan","Phelan","34.4398","-117.5248","United States","US","USA","California","","18599","1840025798"
"Paluan","Paluan","13.4167","120.4667","Philippines","PH","PHL","Occidental Mindoro","","18566","1608778604"
"Firminy","Firminy","45.3881","4.2872","France","FR","FRA","Auvergne-Rhône-Alpes","","16981","1250952856"
"Brand","Brand","50.7489","6.1650","Germany","DE","DEU","North Rhine-Westphalia","","17284","1276474228"
"Denizciler","Denizciler","36.6500","36.2167","Turkey","TR","TUR","Hatay","","17925","1792282618"
"Budrio","Budrio","44.5500","11.5333","Italy","IT","ITA","Emilia-Romagna","","18440","1380367732"
"Brook Park","Brook Park","41.4036","-81.8219","United States","US","USA","Ohio","","18592","1840000594"
"Vissannapeta","Vissannapeta","16.9500","80.7833","India","IN","IND","Andhra Pradesh","","17852","1356082522"
"San Carlos Park","San Carlos Park","26.4765","-81.8193","United States","US","USA","Florida","","18590","1840014218"
"Veternik","Veternik","45.2533","19.7608","Serbia","RS","SRB","","","17454","1688644381"
"Gerd Farāmarz Shāhedīyeh","Gerd Faramarz Shahediyeh","31.9408","54.2736","Iran","IR","IRN","Yazd","","18309","1364448828"
"Abrandābād-e Shāhedīyeh","Abrandabad-e Shahediyeh","31.9414","54.2828","Iran","IR","IRN","Yazd","","18309","1364301778"
"Cariús","Carius","-6.5369","-39.4969","Brazil","BR","BRA","Ceará","","18567","1076668766"
"Conceição do Almeida","Conceicao do Almeida","-12.8089","-39.1639","Brazil","BR","BRA","Bahia","","18525","1076485799"
"Zhangshicun","Zhangshicun","24.5477","113.5240","China","CN","CHN","Guangdong","","18436","1156563282"
"Tinajeros","Tinajeros","14.6733","120.9690","Philippines","PH","PHL","Malabon","","18387","1608675793"
"Presidente Olegário","Presidente Olegario","-18.4178","-46.4178","Brazil","BR","BRA","Minas Gerais","","18577","1076631969"
"Dapi","Dapi","23.6494","120.4254","Taiwan","TW","TWN","Yunlin","","18178","1158141217"
"Mūkondapalli","Mukondapalli","12.7514","77.8017","India","IN","IND","Tamil Nādu","","17948","1356027640"
"Presidente Médici","Presidente Medici","-11.1758","-61.9008","Brazil","BR","BRA","Rondônia","","18571","1076970693"
"Kasongan","Kasongan","-1.8959","113.3878","Indonesia","ID","IDN","Kalimantan Tengah","","18572","1360902352"
"Colina","Colina","-20.7136","-48.5411","Brazil","BR","BRA","São Paulo","","18535","1076416101"
"Neópolis","Neopolis","-10.3200","-36.5794","Brazil","BR","BRA","Sergipe","minor","18506","1076383247"
"Moloacán","Moloacan","17.9833","-94.3500","Mexico","MX","MEX","Veracruz","minor","17504","1484009613"
"Newton","Newton","38.0368","-97.3449","United States","US","USA","Kansas","","18576","1840003846"
"Kristiansund","Kristiansund","63.1104","7.7279","Norway","NO","NOR","Møre og Romsdal","minor","18273","1578717143"
"Bel Air","Bel Air","-20.2582","57.7545","Mauritius","MU","MUS","Flacq","","17605","1480143096"
"Oskarshamn","Oskarshamn","57.2650","16.4500","Sweden","SE","SWE","Kalmar","minor","17258","1752437769"
"Pitt Meadows","Pitt Meadows","49.2333","-122.6833","Canada","CA","CAN","British Columbia","","18573","1124786902"
"Zagarolo","Zagarolo","41.8333","12.8333","Italy","IT","ITA","Lazio","","17933","1380250264"
"Nigrán","Nigran","42.1419","-8.8056","Spain","ES","ESP","Galicia","","18054","1724832311"
"Capanema","Capanema","-25.6719","-53.8089","Brazil","BR","BRA","Paraná","","18526","1076374663"
"Priozërsk","Priozersk","61.0500","30.1333","Russia","RU","RUS","Leningradskaya Oblast’","","18552","1643755142"
"San Celoni","San Celoni","41.6895","2.4897","Spain","ES","ESP","Catalonia","","18285","1724968176"
"Pizarro","Pizarro","4.9500","-77.3667","Colombia","CO","COL","Chocó","minor","18561","1170809490"
"Santa Cruz Zenzontepec","Santa Cruz Zenzontepec","16.5333","-97.5000","Mexico","MX","MEX","Oaxaca","","18512","1484403131"
"Kottá Kalidindi","Kotta Kalidindi","16.5032","81.2877","India","IN","IND","Andhra Pradesh","","18059","1356539189"
"Melville","Melville","40.7824","-73.4088","United States","US","USA","New York","","18561","1840005044"
"Springfield","Springfield","36.4949","-86.8711","United States","US","USA","Tennessee","","18561","1840015273"
"San Diego","San Diego","10.3375","-73.1825","Colombia","CO","COL","Cesar","minor","18531","1170032847"
"Wheatfield","Wheatfield","43.0975","-78.8831","United States","US","USA","New York","","18558","1840088041"
"Turuvanūr","Turuvanur","14.2200","76.4000","India","IN","IND","Karnātaka","","18227","1356997813"
"Ranzan","Ranzan","36.0565","139.3205","Japan","JP","JPN","Saitama","","17954","1392003117"
"Feilding","Feilding","-40.2167","175.5667","New Zealand","NZ","NZL","Manawatu-Wanganui","","17800","1554000257"
"Chouafaa","Chouafaa","34.7667","-6.0500","Morocco","MA","MAR","Rabat-Salé-Kénitra","","18436","1504441282"
"Tinoc","Tinoc","16.6750","120.9367","Philippines","PH","PHL","Ifugao","","18475","1608797221"
"Tyāgadurgam","Tyagadurgam","11.7411","79.0770","India","IN","IND","Tamil Nādu","","17995","1356898345"
"Poulton le Fylde","Poulton le Fylde","53.8470","-2.9950","United Kingdom","GB","GBR","Lancashire","","18115","1826051466"
"Guadix","Guadix","37.3000","-3.1333","Spain","ES","ESP","Andalusia","","18493","1724022918"
"Berkhampstead","Berkhampstead","51.7600","-0.5600","United Kingdom","GB","GBR","Hertfordshire","","18100","1826863985"
"Asunción Nochixtlán","Asuncion Nochixtlan","17.4592","-97.2261","Mexico","MX","MEX","Oaxaca","minor","18525","1484302009"
"Shaoyu","Shaoyu","34.0629","105.3672","China","CN","CHN","Gansu","","18327","1156019971"
"Oskū","Osku","37.9108","46.1264","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","18459","1364247640"
"Borgo San Lorenzo","Borgo San Lorenzo","43.9500","11.3833","Italy","IT","ITA","Tuscany","","18419","1380111491"
"Heusweiler","Heusweiler","49.3375","6.9301","Germany","DE","DEU","Saarland","","18094","1276589030"
"Langen","Langen","53.6116","8.6032","Germany","DE","DEU","Lower Saxony","","18395","1276787724"
"Międzyrzecz","Miedzyrzecz","52.4483","15.5883","Poland","PL","POL","Lubuskie","minor","18459","1616321580"
"Vera","Vera","37.2500","-1.8667","Spain","ES","ESP","Andalusia","","18224","1724244978"
"Sarotar","Sarotar","26.4291","84.7813","India","IN","IND","Bihār","","17079","1356063673"
"Guabiruba","Guabiruba","-27.0858","-48.9808","Brazil","BR","BRA","Santa Catarina","","18430","1076001337"
"Toyono","Toyono","34.9189","135.4941","Japan","JP","JPN","Ōsaka","","18011","1392001130"
"Cordenons","Cordenons","45.9833","12.7000","Italy","IT","ITA","Friuli Venezia Giulia","","18210","1380431820"
"Palestine","Palestine","31.7544","-95.6471","United States","US","USA","Texas","","18532","1840020798"
"Fátima","Fatima","-10.6000","-38.2169","Brazil","BR","BRA","Bahia","","18481","1076705268"
"Bramhall","Bramhall","53.3570","-2.1640","United Kingdom","GB","GBR","Stockport","","17436","1826643172"
"San Blas Atempa","San Blas Atempa","16.3167","-95.2167","Mexico","MX","MEX","Oaxaca","minor","18406","1484548529"
"Tha Bo","Tha Bo","17.8494","102.5858","Thailand","TH","THA","Nong Khai","minor","18320","1764956322"
"Campo de la Cruz","Campo de la Cruz","10.3778","-74.8814","Colombia","CO","COL","Atlántico","minor","18354","1170737801"
"Kherāmeh","Kherameh","29.5000","53.3167","Iran","IR","IRN","Fārs","minor","18477","1364200979"
"Walcourt","Walcourt","50.2500","4.4167","Belgium","BE","BEL","Wallonia","","18376","1056075775"
"Littau","Littau","47.0494","8.2639","Switzerland","CH","CHE","Luzern","","17224","1756821740"
"Bitkine","Bitkine","11.9817","18.2125","Chad","TD","TCD","Guéra","","18495","1148547709"
"Siqueira Campos","Siqueira Campos","-23.6889","-49.8339","Brazil","BR","BRA","Paraná","","18454","1076393725"
"Ponedera","Ponedera","10.6500","-74.7500","Colombia","CO","COL","Atlántico","minor","18430","1170527788"
"Rhaude","Rhaude","53.1667","7.5500","Germany","DE","DEU","Lower Saxony","","18341","1276244739"
"Plácido de Castro","Placido de Castro","-10.2758","-67.1500","Brazil","BR","BRA","Acre","","18510","1076151388"
"Foum el Anser","Foum el Anser","32.3718","-6.2613","Morocco","MA","MAR","Béni Mellal-Khénifra","","18412","1504049816"
"Mūlanūr","Mulanur","10.7943","77.7115","India","IN","IND","Tamil Nādu","","18251","1356581560"
"Kivsharivka","Kivsharivka","49.6303","37.6908","Ukraine","UA","UKR","Kharkivska Oblast","","18496","1804870027"
"Kitanakagusuku","Kitanakagusuku","26.3011","127.7931","Japan","JP","JPN","Okinawa","","17038","1392003471"
"Cândido Mendes","Candido Mendes","-1.4469","-45.7169","Brazil","BR","BRA","Maranhão","","18505","1076905087"
"Dniprorudne","Dniprorudne","47.3907","35.0027","Ukraine","UA","UKR","Zaporizka Oblast","","18468","1804975764"
"Kostrzyn nad Odrą","Kostrzyn nad Odra","52.5883","14.6667","Poland","PL","POL","Lubuskie","","18125","1616354192"
"Hatfield","Hatfield","40.2758","-75.2895","United States","US","USA","Pennsylvania","","18510","1840003701"
"Beloozërskiy","Beloozerskiy","55.4589","38.4411","Russia","RU","RUS","Moskovskaya Oblast’","","18297","1643491121"
"Somma Lombardo","Somma Lombardo","45.6833","8.7000","Italy","IT","ITA","Lombardy","","17919","1380175887"
"Savignano sul Rubicone","Savignano sul Rubicone","44.0881","12.3933","Italy","IT","ITA","Emilia-Romagna","","17744","1380057142"
"North Myrtle Beach","North Myrtle Beach","33.8232","-78.7082","United States","US","USA","South Carolina","","18502","1840014718"
"Nederland","Nederland","29.9707","-94.0015","United States","US","USA","Texas","","18502","1840020917"
"Martinsburg","Martinsburg","39.4582","-77.9776","United States","US","USA","West Virginia","","18502","1840005742"
"Hopkinton","Hopkinton","42.2255","-71.5378","United States","US","USA","Massachusetts","","18501","1840053637"
"Mayorga","Mayorga","10.9000","125.0000","Philippines","PH","PHL","Leyte","","18071","1608478434"
"San Vicente Pacaya","San Vicente Pacaya","14.4161","-90.6392","Guatemala","GT","GTM","Escuintla","minor","18417","1320296319"
"Apastepeque","Apastepeque","13.6667","-88.7667","El Salvador","SV","SLV","San Vicente","","18342","1222195494"
"Nara","Nara","15.1800","-7.2800","Mali","ML","MLI","Koulikoro","minor","18459","1466925477"
"Trenton","Trenton","42.1394","-83.1929","United States","US","USA","Michigan","","18489","1840003966"
"Parole","Parole","38.9863","-76.5518","United States","US","USA","Maryland","","18488","1840005913"
"Qaşr-e Shīrīn","Qasr-e Shirin","34.5156","45.5792","Iran","IR","IRN","Kermānshāh","minor","18473","1364110689"
"Wilton","Wilton","41.2070","-73.4401","United States","US","USA","Connecticut","","18486","1840045106"
"Piatã","Piata","-13.1519","-41.7728","Brazil","BR","BRA","Bahia","","18473","1076965832"
"Mirangaba","Mirangaba","-10.9539","-40.5758","Brazil","BR","BRA","Bahia","","18474","1076520621"
"Los Alcázares","Los Alcazares","37.7436","-0.8497","Spain","ES","ESP","Murcia","","17603","1724437898"
"Deoria","Deoria","26.1791","85.0234","India","IN","IND","Bihār","","16671","1356888269"
"Arroio Grande","Arroio Grande","-32.2378","-53.0869","Brazil","BR","BRA","Rio Grande do Sul","","18470","1076512343"
"Itapaci","Itapaci","-14.9508","-49.5489","Brazil","BR","BRA","Goiás","","18458","1076617885"
"Udaipura","Udaipura","23.0743","78.5111","India","IN","IND","Madhya Pradesh","","18193","1356838839"
"Fairwood","Fairwood","47.4467","-122.1430","United States","US","USA","Washington","","18477","1840037517"
"Sharon","Sharon","42.1085","-71.1830","United States","US","USA","Massachusetts","","18477","1840053557"
"Middelharnis","Middelharnis","51.7500","4.1700","Netherlands","NL","NLD","Zuid-Holland","minor","18050","1528464383"
"Cesário Lange","Cesario Lange","-23.2267","-47.9531","Brazil","BR","BRA","São Paulo","","18375","1076669066"
"Nerviano","Nerviano","45.5500","8.9833","Italy","IT","ITA","Lombardy","","17176","1380840352"
"Taft","Taft","31.7492","54.2103","Iran","IR","IRN","Yazd","minor","18464","1364828948"
"Lakeway","Lakeway","30.3544","-97.9864","United States","US","USA","Texas","","18471","1840020894"
"Logansport","Logansport","40.7472","-86.3520","United States","US","USA","Indiana","","18471","1840013861"
"Lamrasla","Lamrasla","32.0247","-8.9153","Morocco","MA","MAR","Marrakech-Safi","","18335","1504000483"
"Bakhmach","Bakhmach","51.1810","32.8163","Ukraine","UA","UKR","Chernihivska Oblast","","18441","1804417903"
"Otrokovice","Otrokovice","49.2099","17.5308","Czechia","CZ","CZE","Zlínský Kraj","","17592","1203087231"
"Elizabeth City","Elizabeth City","36.2942","-76.2360","United States","US","USA","North Carolina","","18466","1840013315"
"Albino","Albino","45.7606","9.7969","Italy","IT","ITA","Lombardy","","17903","1380409115"
"Felanitx","Felanitx","39.4692","3.1481","Spain","ES","ESP","Balearic Islands","","18357","1724767579"
"Astrea","Astrea","9.5000","-73.9833","Colombia","CO","COL","Cesar","minor","18434","1170944258"
"Villorba","Villorba","45.7333","12.2333","Italy","IT","ITA","Veneto","","17879","1380357143"
"Békés","Bekes","46.7667","21.1333","Hungary","HU","HUN","Békés","minor","18322","1348964497"
"Bad Segeberg","Bad Segeberg","53.9167","10.3167","Germany","DE","DEU","Schleswig-Holstein","minor","17529","1276181220"
"Levin","Levin","-40.6219","175.2867","New Zealand","NZ","NZL","Manawatu-Wanganui","","17670","1554983635"
"Yur’yev-Pol’skiy","Yur'yev-Pol'skiy","56.5000","39.6833","Russia","RU","RUS","Vladimirskaya Oblast’","","18433","1643130630"
"Zielonka","Zielonka","52.3008","21.1586","Poland","PL","POL","Mazowieckie","","18219","1616038948"
"Gheorgheni","Gheorgheni","46.7200","25.5900","Romania","RO","ROU","Harghita","","18377","1642450160"
"Bettioua","Bettioua","35.8000","-0.2667","Algeria","DZ","DZA","Oran","","18215","1012337546"
"Korsun-Shevchenkivskyi","Korsun-Shevchenkivskyi","49.4261","31.2806","Ukraine","UA","UKR","Cherkaska Oblast","","18401","1804512301"
"Duffel","Duffel","51.1000","4.5167","Belgium","BE","BEL","Flanders","","17664","1056716373"
"San José Tenango","San Jose Tenango","18.1500","-96.7333","Mexico","MX","MEX","Oaxaca","minor","18316","1484844323"
"São José da Coroa Grande","Sao Jose da Coroa Grande","-8.8978","-35.1478","Brazil","BR","BRA","Pernambuco","","18180","1076387901"
"Montornés del Vallés","Montornes del Valles","41.5444","2.2670","Spain","ES","ESP","Catalonia","","16804","1724726528"
"Nipomo","Nipomo","35.0319","-120.4985","United States","US","USA","California","","18440","1840019122"
"Kreminna","Kreminna","49.0500","38.2167","Ukraine","UA","UKR","Luhanska Oblast","","18417","1804552469"
"Amvrosiivka","Amvrosiivka","47.7958","38.4801","Ukraine","UA","UKR","Donetska Oblast","","18413","1804821911"
"Fulwood","Fulwood","53.3650","-1.5440","United Kingdom","GB","GBR","Sheffield","","18233","1826986011"
"Phoenixville","Phoenixville","40.1359","-75.5201","United States","US","USA","Pennsylvania","","18437","1840001433"
"Svatove","Svatove","49.4150","38.1550","Ukraine","UA","UKR","Luhanska Oblast","minor","18417","1804850971"
"Icapuí","Icapui","-4.7128","-37.3550","Brazil","BR","BRA","Ceará","","18392","1076044924"
"Campos Belos","Campos Belos","-13.0369","-46.7719","Brazil","BR","BRA","Goiás","","18410","1076340789"
"Franklin Park","Franklin Park","41.9361","-87.8794","United States","US","USA","Illinois","","18435","1840011278"
"Badantola","Badantola","25.2402","87.9617","India","IN","IND","West Bengal","","17190","1356751575"
"Reriutaba","Reriutaba","-4.1419","-40.5819","Brazil","BR","BRA","Ceará","","18385","1076183973"
"Albert Lea","Albert Lea","43.6548","-93.3643","United States","US","USA","Minnesota","","18433","1840006824"
"Almondbury","Almondbury","53.6344","-1.7489","United Kingdom","GB","GBR","Kirklees","","18346","1826676976"
"Foxborough","Foxborough","42.0627","-71.2461","United States","US","USA","Massachusetts","","18431","1840053549"
"Schwechat","Schwechat","48.1411","16.4786","Austria","AT","AUT","Niederösterreich","","18026","1040189871"
"Selma","Selma","32.4166","-87.0336","United States","US","USA","Alabama","","18429","1840008329"
"Fontaine-l’Évêque","Fontaine-l'Eveque","50.4167","4.3167","Belgium","BE","BEL","Wallonia","","17801","1056623225"
"Sengés","Senges","-24.1128","-49.4639","Brazil","BR","BRA","Paraná","","18414","1076152833"
"Buenavista","Buenavista","8.6833","-75.2500","Colombia","CO","COL","Córdoba","","18344","1170418186"
"Hampton","Hampton","40.5844","-79.9534","United States","US","USA","Pennsylvania","","18425","1840147156"
"Heidenau","Heidenau","50.9833","13.8667","Germany","DE","DEU","Saxony","","16898","1276588672"
"Durleşti","Durlesti","47.0333","28.9500","Moldova","MD","MDA","Chişinău","","17210","1498757203"
"Concord","Concord","42.4620","-71.3639","United States","US","USA","Massachusetts","","18424","1840053635"
"Cagdianao","Cagdianao","9.9167","125.6667","Philippines","PH","PHL","Dinagat Islands","","18350","1608094230"
"Arroyo Grande","Arroyo Grande","35.1241","-120.5845","United States","US","USA","California","","18422","1840019125"
"Bodegraven","Bodegraven","52.0822","4.7504","Netherlands","NL","NLD","Zuid-Holland","minor","17954","1528875196"
"Budhni","Budhni","22.7825","77.6820","India","IN","IND","Madhya Pradesh","","18204","1356020676"
"Harrisburg","Harrisburg","35.3125","-80.6485","United States","US","USA","North Carolina","","18415","1840016364"
"Belo Campo","Belo Campo","-15.0378","-41.2600","Brazil","BR","BRA","Bahia","","18383","1076167389"
"Wörth am Rhein","Worth am Rhein","49.0517","8.2603","Germany","DE","DEU","Rhineland-Palatinate","","18274","1276803392"
"Harinākunda","Harinakunda","23.6500","89.0333","Bangladesh","BD","BGD","Khulna","","17533","1050173632"
"Caldas de Montbuy","Caldas de Montbuy","41.6328","2.1675","Spain","ES","ESP","Catalonia","","17932","1724624522"
"Schwalmstadt","Schwalmstadt","50.9333","9.2167","Germany","DE","DEU","Hesse","","18195","1276496894"
"Tallmadge","Tallmadge","41.1023","-81.4216","United States","US","USA","Ohio","","18409","1840000804"
"Omalūr","Omalur","11.7300","78.0700","India","IN","IND","Tamil Nādu","","17544","1356613575"
"Santa Maria di Sala","Santa Maria di Sala","45.5089","12.0363","Italy","IT","ITA","Veneto","","17774","1380364806"
"Schwarzenbek","Schwarzenbek","53.5042","10.4792","Germany","DE","DEU","Schleswig-Holstein","","16940","1276765796"
"Pinecrest","Pinecrest","25.6650","-80.3042","United States","US","USA","Florida","","18405","1840018336"
"L’Isle-d’Abeau","L'Isle-d'Abeau","45.6194","5.2331","France","FR","FRA","Auvergne-Rhône-Alpes","","16584","1250774753"
"Taku","Taku","33.2833","130.1167","Japan","JP","JPN","Saga","","18215","1392003498"
"Visé","Vise","50.7333","5.7000","Belgium","BE","BEL","Wallonia","","17767","1056336313"
"Mineros","Mineros","-17.1178","-63.2331","Bolivia","BO","BOL","Santa Cruz","","18340","1068867021"
"Kadaň","Kadan","50.3761","13.2714","Czechia","CZ","CZE","Ústecký Kraj","","18133","1203388944"
"Formoso do Araguaia","Formoso do Araguaia","-11.7969","-49.5289","Brazil","BR","BRA","Tocantins","","18399","1076817559"
"Serra Dourada","Serra Dourada","-12.7608","-43.9500","Brazil","BR","BRA","Bahia","","18389","1076669259"
"Cinco Ranch","Cinco Ranch","29.7395","-95.7607","United States","US","USA","Texas","","18399","1840037039"
"Monthey","Monthey","46.2500","6.9500","Switzerland","CH","CHE","Valais","","17777","1756628597"
"Vechelde","Vechelde","52.2608","10.3719","Germany","DE","DEU","Lower Saxony","","18158","1276280794"
"Ommen","Ommen","52.5167","6.4167","Netherlands","NL","NLD","Overijssel","minor","18295","1528222836"
"Gálvez","Galvez","-32.0333","-61.2167","Argentina","AR","ARG","Santa Fe","","18374","1032423534"
"Burlington","Burlington","39.0223","-84.7217","United States","US","USA","Kentucky","","18394","1840013160"
"Carvin","Carvin","50.4931","2.9581","France","FR","FRA","Hauts-de-France","","17557","1250728783"
"Nytva","Nytva","57.9500","55.3333","Russia","RU","RUS","Permskiy Kray","minor","18366","1643014234"
"Iati","Iati","-9.0458","-36.8458","Brazil","BR","BRA","Pernambuco","","18360","1076586652"
"Cassano al Ionio","Cassano al Ionio","39.7833","16.3167","Italy","IT","ITA","Calabria","","18270","1380542354"
"Garwolin","Garwolin","51.9000","21.6333","Poland","PL","POL","Mazowieckie","minor","17602","1616433503"
"Vettavalam","Vettavalam","12.1077","79.2452","India","IN","IND","Tamil Nādu","","17690","1356960513"
"Ekazhevo","Ekazhevo","43.2081","44.8186","Russia","RU","RUS","Ingushetiya","","17734","1643710055"
"Yoichi","Yoichi","43.1953","140.7835","Japan","JP","JPN","Hokkaidō","","18252","1392190850"
"Hessisch Oldendorf","Hessisch Oldendorf","52.1667","9.2500","Germany","DE","DEU","Lower Saxony","","18228","1276530384"
"Palos Hills","Palos Hills","41.6986","-87.8266","United States","US","USA","Illinois","","18379","1840009182"
"Borgaon","Borgaon","16.4200","74.5800","India","IN","IND","Mahārāshtra","","17845","1356104480"
"Jussara","Jussara","-15.8650","-50.8678","Brazil","BR","BRA","Goiás","","18371","1076624380"
"Shirāli","Shirali","14.0297","74.5280","India","IN","IND","Karnātaka","","17850","1356090688"
"Yutz","Yutz","49.3589","6.1886","France","FR","FRA","Grand Est","","17143","1250819646"
"Gothini","Gothini","26.1626","84.0379","India","IN","IND","Bihār","","17338","1356085735"
"Do’stlik Shahri","Do'stlik Shahri","40.5247","68.0358","Uzbekistan","UZ","UZB","Jizzax","minor","18300","1860352571"
"Portsmouth","Portsmouth","38.7538","-82.9445","United States","US","USA","Ohio","","18369","1840001646"
"Fairmont","Fairmont","39.4768","-80.1491","United States","US","USA","West Virginia","","18369","1840005732"
"Aït Bouchta","Ait Bouchta","35.1056","-3.8503","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","18271","1504367001"
"Assa","Assa","28.6086","-9.4269","Morocco","MA","MAR","Guelmim-Oued Noun","","18367","1504311835"
"Novopavlovka","Novopavlovka","42.8700","74.4800","Kyrgyzstan","KG","KGZ","Chüy","","18332","1417769741"
"Piúma","Piuma","-20.8350","-40.7289","Brazil","BR","BRA","Espírito Santo","","18123","1076847843"
"Memuro-minami","Memuro-minami","42.9119","143.0508","Japan","JP","JPN","Hokkaidō","","18329","1392837636"
"Talata-Volonondry","Talata-Volonondry","-18.7500","47.6500","Madagascar","MG","MDG","Antananarivo","","18314","1450123818"
"Saladas","Saladas","-28.2500","-58.6167","Argentina","AR","ARG","Corrientes","minor","18349","1032869998"
"Galaat el Andeless","Galaat el Andeless","37.0625","10.1183","Tunisia","TN","TUN","L’Ariana","","18225","1788275217"
"Durant","Durant","33.9949","-96.3923","United States","US","USA","Oklahoma","","18358","1840019280"
"Großenhain","Grossenhain","51.2833","13.5500","Germany","DE","DEU","Saxony","","18218","1276062611"
"Sørum","Sorum","59.9871","11.2402","Norway","NO","NOR","Viken","","18263","1578429477"
"East Goshen","East Goshen","39.9934","-75.5478","United States","US","USA","Pennsylvania","","18356","1840150925"
"Cariré","Carire","-3.9508","-40.4728","Brazil","BR","BRA","Piauí","","18348","1076644740"
"Stonington","Stonington","41.3738","-71.9034","United States","US","USA","Connecticut","","18354","1840004829"
"Mirante do Paranapanema","Mirante do Paranapanema","-22.2919","-51.9064","Brazil","BR","BRA","São Paulo","","18338","1076182507"
"Bet She’an","Bet She'an","32.5","35.5","Israel","IL","ISR","Northern","","18200","1376100451"
"Alauli","Alauli","25.6440","86.4065","India","IN","IND","Bihār","","16998","1356202491"
"Qishe","Qishe","24.9232","104.7218","China","CN","CHN","Guizhou","","18154","1156178726"
"Końskie","Konskie","51.2000","20.4167","Poland","PL","POL","Świętokrzyskie","minor","18201","1616604827"
"Hāvi Bhauār","Havi Bhauar","26.1091","86.1059","India","IN","IND","Bihār","","16676","1356100986"
"Shika","Shika","37.0062","136.7780","Japan","JP","JPN","Ishikawa","","18275","1392003481"
"Calera de Tango","Calera de Tango","-33.6302","-70.7616","Chile","CL","CHL","Región Metropolitana","","18235","1152670034"
"Jalpa de Méndez","Jalpa de Mendez","18.1764","-93.0631","Mexico","MX","MEX","Tabasco","minor","16679","1484768624"
"Castelo do Piauí","Castelo do Piaui","-5.3219","-41.5528","Brazil","BR","BRA","Piauí","","18336","1076388877"
"Araçoiaba","Aracoiaba","-7.7900","-35.0908","Brazil","BR","BRA","Pernambuco","","18156","1076400121"
"Windham","Windham","43.7981","-70.4056","United States","US","USA","Maine","","18343","1840066537"
"Serrita","Serrita","-7.9328","-39.2958","Brazil","BR","BRA","Pernambuco","","18331","1076395069"
"Santa Lucía Milpas Altas","Santa Lucia Milpas Altas","14.5757","-90.6780","Guatemala","GT","GTM","Sacatepéquez","minor","17422","1320588328"
"Piossasco","Piossasco","44.9906","7.4637","Italy","IT","ITA","Piedmont","","18322","1380000132"
"Flórina","Florina","40.7833","21.4000","Greece","GR","GRC","Dytikí Makedonía","minor","17686","1300644565"
"Dobrush","Dobrush","52.4167","31.3167","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","18311","1112814087"
"Waalre","Waalre","51.4000","5.4667","Netherlands","NL","NLD","Noord-Brabant","minor","17544","1528079244"
"Illertissen","Illertissen","48.2167","10.0833","Germany","DE","DEU","Bavaria","","17843","1276593057"
"Gitagum","Gitagum","8.5956","124.4054","Philippines","PH","PHL","Misamis Oriental","","17920","1608366014"
"Aragarças","Aragarcas","-15.8978","-52.2508","Brazil","BR","BRA","Goiás","","18305","1076176600"
"Monforte de Lemos","Monforte de Lemos","42.5164","-7.5161","Spain","ES","ESP","Galicia","","18242","1724233223"
"Baependi","Baependi","-21.9589","-44.8900","Brazil","BR","BRA","Minas Gerais","","18307","1076882694"
"Wantagh","Wantagh","40.6686","-73.5104","United States","US","USA","New York","","18331","1840005281"
"Shaying","Shaying","25.9740","105.4233","China","CN","CHN","Guizhou","","18203","1156920468"
"Pembroke","Pembroke","42.0655","-70.8014","United States","US","USA","Massachusetts","","18329","1840053573"
"Siloe","Siloe","-29.9836","27.2792","Lesotho","LS","LSO","Mohale’s Hoek","","18261","1426116869"
"Braniewo","Braniewo","54.3833","19.8333","Poland","PL","POL","Warmińsko-Mazurskie","minor","16992","1616740656"
"Garching bei München","Garching bei Munchen","48.2500","11.6500","Germany","DE","DEU","Bavaria","","17711","1276544473"
"Dehti","Dehti","26.2115","87.5513","India","IN","IND","Bihār","","17261","1356230002"
"Clive","Clive","41.6147","-93.7980","United States","US","USA","Iowa","","18323","1840007063"
"Santa Fe","Santa Fe","16.1592","120.9378","Philippines","PH","PHL","Nueva Vizcaya","","18276","1608768804"
"Niles","Niles","41.1879","-80.7531","United States","US","USA","Ohio","","18319","1840008241"
"Vertentes","Vertentes","-7.9028","-35.9878","Brazil","BR","BRA","Pernambuco","","18222","1076000487"
"Meishan","Meishan","23.5607","120.6296","Taiwan","TW","TWN","Chiayi","","18164","1158732853"
"Oyama","Oyama","35.3601","138.9873","Japan","JP","JPN","Kanagawa","","18181","1392334632"
"Tillmans Corner","Tillmans Corner","30.5819","-88.2128","United States","US","USA","Alabama","","18313","1840013896"
"Republic","Republic","37.1452","-93.4446","United States","US","USA","Missouri","","18313","1840009906"
"Sikhio","Sikhio","14.9000","101.7167","Thailand","TH","THA","Nakhon Ratchasima","minor","18209","1764681503"
"Concepción de Ataco","Concepcion de Ataco","13.8667","-89.8500","El Salvador","SV","SLV","Ahuachapán","","18101","1222271937"
"Naarden","Naarden","52.2953","5.1622","Netherlands","NL","NLD","Noord-Holland","minor","17555","1528281177"
"Bekalta","Bekalta","35.6167","11.0000","Tunisia","TN","TUN","Monastir","","17850","1788338192"
"Manchester","Manchester","38.5830","-90.5064","United States","US","USA","Missouri","","18308","1840009739"
"Nehbandān","Nehbandan","31.5419","60.0364","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","18304","1364475715"
"Port Colborne","Port Colborne","42.8833","-79.2500","Canada","CA","CAN","Ontario","","18306","1124274319"
"Kosvik","Kosvik","58.1500","8.0667","Norway","NO","NOR","Agder","","17981","1578433332"
"Kikube","Kikube","1.3328","31.2075","Uganda","UG","UGA","Kikuube","","18100","1800164805"
"Pocinhos","Pocinhos","-7.0769","-36.0608","Brazil","BR","BRA","Paraíba","","18272","1076955414"
"Pisz","Pisz","53.6333","21.8000","Poland","PL","POL","Warmińsko-Mazurskie","minor","18245","1616757867"
"San Cristóbal Cucho","San Cristobal Cucho","14.9000","-91.7833","Guatemala","GT","GTM","San Marcos","minor","17987","1320539548"
"Garopaba","Garopaba","-28.0228","-48.6128","Brazil","BR","BRA","Santa Catarina","","18138","1076202178"
"Ribat Al Khayr","Ribat Al Khayr","33.8200","-4.4100","Morocco","MA","MAR","Fès-Meknès","","16739","1504387842"
"Slyudyanka","Slyudyanka","51.6333","103.7500","Russia","RU","RUS","Irkutskaya Oblast’","","18287","1643956172"
"Koratagere","Koratagere","13.5220","77.2373","India","IN","IND","Karnātaka","","18024","1356799783"
"Sikandra","Sikandra","24.9564","86.0344","India","IN","IND","Bihār","","16981","1356416593"
"Svilengrad","Svilengrad","41.7667","26.2000","Bulgaria","BG","BGR","Haskovo","","18263","1100757621"
"Eski-Nookat","Eski-Nookat","40.2658","72.6181","Kyrgyzstan","KG","KGZ","Osh","minor","18228","1417348189"
"Şenkaya","Senkaya","40.5619","42.3464","Turkey","TR","TUR","Erzurum","minor","18281","1792624662"
"Naula","Naula","25.5535","86.0636","India","IN","IND","Bihār","","16995","1356470171"
"Tavares","Tavares","28.7920","-81.7352","United States","US","USA","Florida","","18290","1840015953"
"Savaştepe","Savastepe","39.3839","27.6547","Turkey","TR","TUR","Balıkesir","minor","18243","1792897169"
"Vennandūr","Vennandur","11.5150","78.0908","India","IN","IND","Tamil Nādu","","17796","1356228863"
"Paraibuna","Paraibuna","-23.3861","-45.6622","Brazil","BR","BRA","São Paulo","","18263","1076100986"
"Antanambao","Antanambao","-15.1667","50.3667","Madagascar","MG","MDG","Antsiranana","","18232","1450199403"
"Condeúba","Condeuba","-14.8950","-41.9689","Brazil","BR","BRA","Bahia","","18269","1076228671"
"Newburyport","Newburyport","42.8124","-70.8878","United States","US","USA","Massachusetts","","18282","1840000413"
"Knik-Fairview","Knik-Fairview","61.5082","-149.6252","United States","US","USA","Alaska","","18282","1840075080"
"Sipacate","Sipacate","13.9333","-91.1500","Guatemala","GT","GTM","Escuintla","","18212","1320395246"
"Panórama","Panorama","40.5833","23.0333","Greece","GR","GRC","Kentrikí Makedonía","minor","17444","1300530276"
"Xochiatipan de Castillo","Xochiatipan de Castillo","20.8333","-98.2850","Mexico","MX","MEX","Hidalgo","","18157","1484688256"
"Nyborg","Nyborg","55.3122","10.7897","Denmark","DK","DNK","Syddanmark","minor","17525","1208199099"
"Dergaon","Dergaon","26.7000","93.9667","India","IN","IND","Assam","","17748","1356645681"
"Ocean Springs","Ocean Springs","30.4082","-88.7861","United States","US","USA","Mississippi","","18275","1840015017"
"Goffstown","Goffstown","43.0190","-71.5680","United States","US","USA","New Hampshire","","18274","1840054900"
"Bouarouss","Bouarouss","34.3667","-4.8167","Morocco","MA","MAR","Fès-Meknès","","18147","1504643537"
"Isfana","Isfana","39.8389","69.5275","Kyrgyzstan","KG","KGZ","Batken","minor","18244","1417781894"
"Somerset","Somerset","41.7404","-71.1612","United States","US","USA","Massachusetts","","18271","1840053662"
"Werdohl","Werdohl","51.2667","7.7667","Germany","DE","DEU","North Rhine-Westphalia","","17737","1276252108"
"Auerbach","Auerbach","50.5094","12.4000","Germany","DE","DEU","Saxony","","17947","1276679224"
"San Kamphaeng","San Kamphaeng","18.7500","99.1167","Thailand","TH","THA","Chiang Mai","minor","17857","1764805486"
"Vāyalpād","Vayalpad","13.6500","78.6333","India","IN","IND","Andhra Pradesh","","17535","1356770003"
"Almeria","Almeria","11.6206","124.3794","Philippines","PH","PHL","Biliran","","17954","1608711562"
"Khutāha","Khutaha","25.2645","86.0598","India","IN","IND","Bihār","","17558","1356645470"
"Seagoville","Seagoville","32.6530","-96.5456","United States","US","USA","Texas","","18263","1840022054"
"Kishi","Kishi","35.3364","139.1232","Japan","JP","JPN","Kanagawa","","18204","1392295769"
"Kottapalle","Kottapalle","17.2918","81.8939","India","IN","IND","Andhra Pradesh","","18161","1356439882"
"Washington","Washington","40.7877","-74.7918","United States","US","USA","New Jersey","","18258","1840144404"
"Marcos","Marcos","18.0444","120.6786","Philippines","PH","PHL","Ilocos Norte","","18010","1608612564"
"Gangwuzhen","Gangwuzhen","25.9644","105.3376","China","CN","CHN","Guizhou","","18130","1156079601"
"Veldurti","Veldurti","15.5667","77.9167","India","IN","IND","Andhra Pradesh","","17890","1356075545"
"Ikniwn","Ikniwn","31.1736","-5.6733","Morocco","MA","MAR","Drâa-Tafilalet","","18235","1504281850"
"Wadowice","Wadowice","49.8833","19.5000","Poland","PL","POL","Małopolskie","minor","17905","1616191332"
"San Antonio de Ibarra","San Antonio de Ibarra","0.3627","-78.1307","Ecuador","EC","ECU","Imbabura","","17500","1218584009"
"Ambāla","Ambala","17.6500","79.0500","India","IN","IND","Andhra Pradesh","","18054","1356604322"
"Ōi","Oi","35.3266","139.1565","Japan","JP","JPN","Kanagawa","","17062","1392003380"
"Kudavāsal","Kudavasal","10.8582","79.4823","India","IN","IND","Tamil Nādu","","17497","1356012268"
"Quispamsis","Quispamsis","45.4322","-65.9462","Canada","CA","CAN","New Brunswick","","18245","1124000379"
"Bad Säckingen","Bad Sackingen","47.5500","7.9500","Germany","DE","DEU","Baden-Württemberg","","17552","1276877057"
"Kiyama","Kiyama","33.4270","130.5232","Japan","JP","JPN","Fukuoka","","17455","1392003435"
"Greene","Greene","39.9543","-77.5668","United States","US","USA","Pennsylvania","","18242","1840147454"
"Gibsonton","Gibsonton","27.8260","-82.3761","United States","US","USA","Florida","","18242","1840014148"
"Sunbury","Sunbury","51.4230","-0.4240","United Kingdom","GB","GBR","Surrey","","18041","1826417394"
"Andalucía","Andalucia","4.1667","-76.1667","Colombia","CO","COL","Valle del Cauca","minor","18132","1170644836"
"San Pedro","San Pedro","4.0000","-76.2292","Colombia","CO","COL","Valle del Cauca","minor","18128","1170576529"
"Púchov","Puchov","49.1200","18.3306","Slovakia","SK","SVK","Nitra","minor","17810","1703321833"
"Sierpc","Sierpc","52.8833","19.6667","Poland","PL","POL","Mazowieckie","minor","17308","1616875285"
"Čitluk","Citluk","43.2000","17.7000","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","18140","1070415308"
"Ploemeur","Ploemeur","47.7358","-3.4311","France","FR","FRA","Bretagne","","17790","1250987239"
"Ţāqah","Taqah","17.0372","54.4036","Oman","OM","OMN","Z̧ufār","","18218","1512705781"
"Băicoi","Baicoi","45.0453","25.8658","Romania","RO","ROU","Prahova","","17981","1642646801"
"Maripād","Maripad","17.4031","79.8567","India","IN","IND","Andhra Pradesh","","17685","1356873992"
"Tordera","Tordera","41.7008","2.7200","Spain","ES","ESP","Catalonia","","18022","1724310728"
"Manga","Manga","-14.7558","-43.9319","Brazil","BR","BRA","Minas Gerais","","18226","1076922718"
"Pinabacdao","Pinabacdao","11.6167","124.9833","Philippines","PH","PHL","Samar","","18136","1608226074"
"Chapelle","Chapelle","19.4167","-72.5500","Haiti","HT","HTI","Artibonite","","18092","1332599769"
"San Jose","San Jose","12.5310","124.4870","Philippines","PH","PHL","Northern Samar","","17641","1608912746"
"Rikuzen-Takata","Rikuzen-Takata","39.0280","141.6254","Japan","JP","JPN","Iwate","","18153","1392211075"
"Pieksämäki","Pieksamaki","62.3000","27.1333","Finland","FI","FIN","Etelä-Savo","minor","18220","1246171745"
"Loay","Loay","9.6000","124.0167","Philippines","PH","PHL","Bohol","","17855","1608763593"
"McAlester","McAlester","34.9257","-95.7734","United States","US","USA","Oklahoma","","18225","1840020457"
"Łask","Lask","51.5903","19.1333","Poland","PL","POL","Łódzkie","minor","18029","1616006477"
"North Hykeham","North Hykeham","53.1833","-0.5875","United Kingdom","GB","GBR","Lincolnshire","","16844","1826546566"
"Trujillo","Trujillo","4.2500","-76.3333","Colombia","CO","COL","Valle del Cauca","minor","18142","1170602348"
"Padugupādu","Padugupadu","14.4885","79.9923","India","IN","IND","Andhra Pradesh","","17175","1356497267"
"Drăgăşani","Dragasani","44.6611","24.2606","Romania","RO","ROU","Vâlcea","","17871","1642790753"
"Dombóvár","Dombovar","46.3820","18.1422","Hungary","HU","HUN","Tolna","minor","17995","1348286136"
"Alpignano","Alpignano","45.1000","7.5167","Italy","IT","ITA","Piedmont","","16811","1380567274"
"Amsterdam","Amsterdam","42.9420","-74.1906","United States","US","USA","New York","","18218","1840000391"
"Wemmel","Wemmel","50.9167","4.3000","Belgium","BE","BEL","Flanders","","16347","1056649043"
"Gikongoro","Gikongoro","-2.4697","29.5814","Rwanda","RW","RWA","Southern Province","","16695","1646632562"
"Kingsland","Kingsland","30.8194","-81.7216","United States","US","USA","Georgia","","18217","1840014991"
"Nagnur","Nagnur","16.1400","74.8100","India","IN","IND","Karnātaka","","17765","1356355171"
"Son en Breugel","Son en Breugel","51.5167","5.5000","Netherlands","NL","NLD","Noord-Brabant","minor","17552","1528367676"
"Sarvestān","Sarvestan","29.2736","53.2203","Iran","IR","IRN","Fārs","minor","18187","1364001043"
"Baaqlîne","Baaqline","33.6797","35.5583","Lebanon","LB","LBN","Mont-Liban","","17000","1422382914"
"Lake Ronkonkoma","Lake Ronkonkoma","40.8297","-73.1128","United States","US","USA","New York","","18214","1840005086"
"São Francisco de Assis","Sao Francisco de Assis","-29.5500","-55.1308","Brazil","BR","BRA","Rio Grande do Sul","","18205","1076070438"
"Īlām","Ilam","26.9080","87.9260","Nepal","NP","NPL","","","17491","1524034598"
"Ipanema","Ipanema","-19.8008","-41.7128","Brazil","BR","BRA","Minas Gerais","","18170","1076328318"
"Amīngarh","Amingarh","16.0572","75.9489","India","IN","IND","Karnātaka","","17942","1356819437"
"Kiangara","Kiangara","-17.9667","47.0333","Madagascar","MG","MDG","Antananarivo","","18197","1450262091"
"Mŭynoq","Muynoq","43.7667","59.0333","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","18196","1860204414"
"Glencoe","Glencoe","-28.1833","30.1500","South Africa","ZA","ZAF","KwaZulu-Natal","","17548","1710982965"
"Vordingborg","Vordingborg","55.0000","11.9000","Denmark","DK","DNK","Sjælland","minor","17868","1208237699"
"Ingá","Inga","-7.2678","-35.6128","Brazil","BR","BRA","Paraíba","","18144","1076947371"
"Belākoba","Belakoba","26.5744","88.6052","India","IN","IND","West Bengal","","17215","1356014870"
"Lenoir","Lenoir","35.9094","-81.5249","United States","US","USA","North Carolina","","18205","1840014493"
"Concord","Concord","39.8741","-75.5135","United States","US","USA","Pennsylvania","","18202","1840152841"
"St. Marys","St. Marys","30.7567","-81.5722","United States","US","USA","Georgia","","18200","1840015888"
"Mont-Saint-Hilaire","Mont-Saint-Hilaire","45.5622","-73.1917","Canada","CA","CAN","Quebec","","18200","1124333461"
"Kukraun","Kukraun","25.6858","87.1638","India","IN","IND","Bihār","","17276","1356450389"
"Klaeng","Klaeng","12.7778","101.6483","Thailand","TH","THA","Rayong","minor","17764","1764855012"
"Tremedal","Tremedal","-14.9758","-41.4108","Brazil","BR","BRA","Bahia","","18187","1076525489"
"Cudahy","Cudahy","42.9467","-87.8641","United States","US","USA","Wisconsin","","18197","1840003045"
"‘Anbarābād","\`Anbarabad","28.4758","57.8481","Iran","IR","IRN","Kermān","minor","18185","1364601735"
"Pānchgrām","Panchgram","24.1996","88.0077","India","IN","IND","West Bengal","","17137","1356279119"
"Antsampanimahazo","Antsampanimahazo","-19.6400","47.1580","Madagascar","MG","MDG","Antananarivo","","18000","1450189710"
"Maroaloka","Maroaloka","-25.1667","46.2000","Madagascar","MG","MDG","Toliara","","18000","1450615223"
"Lavāsān","Lavasan","35.8231","51.6242","Iran","IR","IRN","Tehrān","","18146","1364266184"
"Quibaxi","Quibaxi","-8.5000","14.5833","Angola","AO","AGO","Bengo","","18176","1024593185"
"Ismaning","Ismaning","48.2264","11.6725","Germany","DE","DEU","Bavaria","","17750","1276633464"
"Avtury","Avtury","43.1667","46.0000","Russia","RU","RUS","Chechnya","","17970","1643015857"
"Trinity","Trinity","28.1809","-82.6582","United States","US","USA","Florida","","18191","1840029012"
"Steiner Ranch","Steiner Ranch","30.3654","-97.8960","United States","US","USA","Texas","","18189","1840142451"
"Santo Antônio do Leverger","Santo Antonio do Leverger","-15.8658","-56.0769","Brazil","BR","BRA","Mato Grosso","","18186","1076851288"
"Rāmpur","Rampur","21.0735","84.3409","India","IN","IND","Odisha","","18114","1356858350"
"Santa Cruz Naranjo","Santa Cruz Naranjo","14.3833","-90.3667","Guatemala","GT","GTM","Santa Rosa","minor","17875","1320978897"
"Ghouazi","Ghouazi","34.4667","-5.3000","Morocco","MA","MAR","Fès-Meknès","","18081","1504717727"
"Sint-Oedenrode","Sint-Oedenrode","51.5636","5.4608","Netherlands","NL","NLD","Noord-Brabant","minor","17893","1528117476"
"Parchim","Parchim","53.4167","11.8333","Germany","DE","DEU","Mecklenburg-Western Pomerania","minor","18037","1276781173"
"Pratteln","Pratteln","47.5167","7.7000","Switzerland","CH","CHE","Basel-Landschaft","","16621","1756235773"
"Beruri","Beruri","-3.9022","-61.3714","Brazil","BR","BRA","Amazonas","","18171","1076241200"
"Vīrapāndi","Virapandi","11.1723","76.9692","India","IN","IND","Tamil Nādu","","16888","1356122570"
"Três Barras","Tres Barras","-26.1058","-50.3219","Brazil","BR","BRA","Santa Catarina","","18129","1076248669"
"Almolonga","Almolonga","14.8122","-91.4944","Guatemala","GT","GTM","Quetzaltenango","minor","17305","1320537455"
"New Castle","New Castle","41.1841","-73.7721","United States","US","USA","New York","","18170","1840087710"
"Central","Central","-11.1542","-42.0814","Brazil","BR","BRA","Bahia","","18140","1076870137"
"Hranice","Hranice","49.5525","17.7683","Czechia","CZ","CZE","Olomoucký Kraj","","17818","1203110445"
"Alto Araguaia","Alto Araguaia","-17.3150","-53.2150","Brazil","BR","BRA","Mato Grosso","","18164","1076823641"
"Sangenjo","Sangenjo","42.4017","-8.8067","Spain","ES","ESP","Galicia","","17760","1724000040"
"Bafanji","Bafanji","5.8361","10.4000","Cameroon","CM","CMR","North-West","","17890","1120039159"
"Châteauneuf-les-Martigues","Chateauneuf-les-Martigues","43.3831","5.1642","France","FR","FRA","Provence-Alpes-Côte d’Azur","","17606","1250884154"
"Birstall","Birstall","53.7320","-1.6600","United Kingdom","GB","GBR","Kirklees","","16298","1826968702"
"Menasha","Menasha","44.2125","-88.4271","United States","US","USA","Wisconsin","","18157","1840002243"
"Juanacatlán","Juanacatlan","20.5000","-103.1667","Mexico","MX","MEX","Jalisco","minor","17955","1484068541"
"Manīn","Manin","33.6422","36.2978","Syria","SY","SYR","Rīf Dimashq","","17521","1760640796"
"Minamishibetsuchō","Minamishibetsucho","44.1786","142.4001","Japan","JP","JPN","Hokkaidō","","18138","1392487129"
"Bathurst","Bathurst","47.6200","-65.6500","Canada","CA","CAN","New Brunswick","","18154","1124816720"
"Rāyappanpatti","Rayappanpatti","9.7997","77.2372","India","IN","IND","Tamil Nādu","","17575","1356252406"
"Shirva","Shirva","13.2465","74.8444","India","IN","IND","Karnātaka","","17530","1356203404"
"Cuautitlán","Cuautitlan","19.4333","-104.3000","Mexico","MX","MEX","Jalisco","minor","18138","1484216677"
"Tapauá","Tapaua","-5.6278","-63.1828","Brazil","BR","BRA","Amazonas","","18152","1076687241"
"Libjo","Libjo","10.1960","125.5328","Philippines","PH","PHL","Dinagat Islands","","18051","1608332155"
"Lebanon","Lebanon","44.5317","-122.9071","United States","US","USA","Oregon","","18150","1840019991"
"Willimantic","Willimantic","41.7171","-72.2114","United States","US","USA","Connecticut","","18150","1840003284"
"St. Michael","St. Michael","45.2014","-93.6920","United States","US","USA","Minnesota","","18148","1840008901"
"Calenzano","Calenzano","43.8667","11.1667","Italy","IT","ITA","Tuscany","","17914","1380409219"
"Santa Vitória","Santa Vitoria","-18.8389","-50.1208","Brazil","BR","BRA","Minas Gerais","","18138","1076319430"
"Sycamore","Sycamore","41.9957","-88.6821","United States","US","USA","Illinois","","18144","1840009187"
"North Druid Hills","North Druid Hills","33.8185","-84.3254","United States","US","USA","Georgia","","18143","1840029449"
"Alakamisy","Alakamisy","-20.2000","47.3167","Madagascar","MG","MDG","Fianarantsoa","","18000","1450227437"
"Saint-Augustin-de-Desmaures","Saint-Augustin-de-Desmaures","46.7333","-71.4667","Canada","CA","CAN","Quebec","","18141","1124000358"
"Upper Grand Lagoon","Upper Grand Lagoon","30.1690","-85.7407","United States","US","USA","Florida","","18140","1840029090"
"Mahesh Khunt","Mahesh Khunt","25.4500","86.6333","India","IN","IND","Bihār","","16394","1356257310"
"Bexbach","Bexbach","49.3494","7.2594","Germany","DE","DEU","Saarland","","17577","1276508289"
"Shedbal","Shedbal","16.6892","74.7543","India","IN","IND","Mahārāshtra","","17839","1356155863"
"Solonópole","Solonopole","-5.7328","-39.0078","Brazil","BR","BRA","Ceará","","18127","1076012866"
"Goshaingaon","Goshaingaon","26.4395","89.9631","India","IN","IND","Assam","","17830","1356705745"
"São Miguel do Tapuio","Sao Miguel do Tapuio","-5.5039","-41.3228","Brazil","BR","BRA","Piauí","","18134","1076871034"
"Patar","Patar","40.3333","70.4500","Tajikistan","TJ","TJK","Sughd","","17870","1762922189"
"Huitán","Huitan","15.0486","-91.6400","Guatemala","GT","GTM","Quetzaltenango","minor","17209","1320474688"
"Atalaia do Norte","Atalaia do Norte","-4.3719","-70.1919","Brazil","BR","BRA","Amazonas","","18133","1076017686"
"Ankofa","Ankofa","-15.4000","49.6500","Madagascar","MG","MDG","Toamasina","","18000","1450855797"
"Charleston","Charleston","39.4842","-88.1781","United States","US","USA","Illinois","","18130","1840007324"
"Buadiposo-Buntong","Buadiposo-Buntong","7.9667","124.3833","Philippines","PH","PHL","Lanao del Sur","","18046","1608795603"
"Marcon","Marcon","45.5543","12.2994","Italy","IT","ITA","Veneto","","17447","1380183530"
"Maranello","Maranello","44.5333","10.8667","Italy","IT","ITA","Emilia-Romagna","","17590","1380374163"
"Coronda","Coronda","-31.9667","-60.9167","Argentina","AR","ARG","Santa Fe","minor","18115","1032005345"
"Bucay","Bucay","17.5388","120.7167","Philippines","PH","PHL","Abra","","17953","1608666532"
"Manari","Manari","-8.9639","-37.6278","Brazil","BR","BRA","Pernambuco","","18083","1076507706"
"Colonial Heights","Colonial Heights","37.2650","-77.3969","United States","US","USA","Virginia","","18127","1840003860"
"Feyẕābād","Feyzabad","35.0144","58.7800","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","18120","1364181999"
"San Vicente","San Vicente","6.2819","-75.3322","Colombia","CO","COL","Antioquia","minor","18051","1170160969"
"Blythe","Blythe","33.6219","-114.6195","United States","US","USA","California","","18125","1840019299"
"Sona","Sona","45.4333","10.8333","Italy","IT","ITA","Veneto","","17694","1380331377"
"Steubenville","Steubenville","40.3653","-80.6519","United States","US","USA","Ohio","","18122","1840001341"
"Whitestown","Whitestown","43.1350","-75.3404","United States","US","USA","New York","","18122","1840088046"
"Mendes","Mendes","-22.5269","-43.7328","Brazil","BR","BRA","Rio de Janeiro","","17935","1076845533"
"Vohilengo","Vohilengo","-17.3000","49.4167","Madagascar","MG","MDG","Toamasina","","18000","1450602843"
"Ambongo","Ambongo","-23.4500","47.2667","Madagascar","MG","MDG","Fianarantsoa","","18000","1450621707"
"Ambiula","Ambiula","-7.4333","14.6500","Angola","AO","AGO","Uíge","","18118","1024070190"
"Madeley","Madeley","52.6370","-2.4480","United Kingdom","GB","GBR","Telford and Wrekin","","17935","1826979637"
"Kelsterbach","Kelsterbach","50.0617","8.5311","Germany","DE","DEU","Hesse","","17013","1276996005"
"Mengjiacun","Mengjiacun","40.0186","119.7878","China","CN","CHN","Liaoning","","17822","1156415162"
"Kuchinda","Kuchinda","21.7436","84.3485","India","IN","IND","Odisha","","17933","1356736303"
"Matigou","Matigou","37.6043","109.9024","China","CN","CHN","Shaanxi","","18024","1156281787"
"Gorham","Gorham","43.7034","-70.4581","United States","US","USA","Maine","","18114","1840052834"
"Jacksonville","Jacksonville","39.7292","-90.2317","United States","US","USA","Illinois","","18114","1840008438"
"Quanzhang","Quanzhang","35.6613","111.1049","China","CN","CHN","Shanxi","","17578","1156186999"
"Dīzīcheh","Dizicheh","32.3836","51.5147","Iran","IR","IRN","Eşfahān","","17966","1364315138"
"Bobingen","Bobingen","48.2667","10.8167","Germany","DE","DEU","Bavaria","","17757","1276277241"
"San Pedro","San Pedro","8.7333","-74.7167","Colombia","CO","COL","Sucre","","18029","1170847061"
"Pescantina","Pescantina","45.4833","10.8667","Italy","IT","ITA","Veneto","","17236","1380632366"
"Manampatrana","Manampatrana","-21.6500","47.5833","Madagascar","MG","MDG","Fianarantsoa","","18000","1450823569"
"Southeast","Southeast","41.4032","-73.5985","United States","US","USA","New York","","18106","1840087927"
"Analaiva","Analaiva","-20.3333","44.5000","Madagascar","MG","MDG","Toliara","","18000","1450809706"
"Madalena","Madalena","-4.8569","-39.5769","Brazil","BR","BRA","Ceará","","18088","1076658814"
"Tsararivotra","Tsararivotra","-19.1714","47.5500","Madagascar","MG","MDG","Antananarivo","","17024","1450649450"
"Natchitoches","Natchitoches","31.7315","-93.0975","United States","US","USA","Louisiana","","18105","1840014925"
"Port-de-Bouc","Port-de-Bouc","43.4050","4.9886","France","FR","FRA","Provence-Alpes-Côte d’Azur","","16651","1250000488"
"Inkollu","Inkollu","15.8300","80.2000","India","IN","IND","Andhra Pradesh","","17581","1356980783"
"Herzele","Herzele","50.8833","3.8833","Belgium","BE","BEL","Flanders","","17723","1056826596"
"General Juan Madariaga","General Juan Madariaga","-37.0167","-57.1333","Argentina","AR","ARG","Buenos Aires","minor","18089","1032481870"
"Gunjur","Gunjur","13.1833","-16.7667","The Gambia","GM","GMB","West Coast","","17520","1270361274"
"Alatsinainy Ialamarina","Alatsinainy Ialamarina","-21.5333","47.3000","Madagascar","MG","MDG","Fianarantsoa","","18000","1450949627"
"Oak Bay","Oak Bay","48.4264","-123.3228","Canada","CA","CAN","British Columbia","","18094","1124761730"
"Muḩradah","Muhradah","35.2500","36.5833","Syria","SY","SYR","Ḩamāh","minor","17578","1760500956"
"Tall ‘Aran","Tall \`Aran","36.1231","37.3369","Syria","SY","SYR","Ḩalab","","17767","1760332493"
"Bhucho Mandi","Bhucho Mandi","30.2129","75.0969","India","IN","IND","Punjab","","17484","1356753284"
"Kharhiāl","Kharhial","20.2885","82.7606","India","IN","IND","Odisha","","17699","1356885732"
"Ashtabula","Ashtabula","41.8805","-80.7984","United States","US","USA","Ohio","","18091","1840007046"
"Putte","Putte","51.0500","4.6333","Belgium","BE","BEL","Flanders","","17584","1056061719"
"Ricaurte","Ricaurte","1.2108","-77.9933","Colombia","CO","COL","Nariño","minor","18067","1170607902"
"Behenjy","Behenjy","-19.2000","47.4833","Madagascar","MG","MDG","Antananarivo","","18000","1450616391"
"Piaçabuçu","Piacabucu","-10.4086","-36.4339","Brazil","BR","BRA","Alagoas","minor","18011","1076666231"
"Santa Fe","Santa Fe","12.1500","122.0000","Philippines","PH","PHL","Romblon","","17802","1608199689"
"Aliyābād","Aliyabad","12.6317","79.2164","India","IN","IND","Tamil Nādu","","17544","1356083368"
"Lattes","Lattes","43.5678","3.9021","France","FR","FRA","Occitanie","","17453","1250642832"
"Valguarnera Caropepe","Valguarnera Caropepe","37.5000","14.3833","Italy","IT","ITA","Sicilia","","18040","1380135070"
"Pastos Bons","Pastos Bons","-6.6019","-44.0769","Brazil","BR","BRA","Maranhão","","18067","1076108777"
"Canby","Canby","45.2652","-122.6867","United States","US","USA","Oregon","","18078","1840018569"
"Plymouth","Plymouth","40.1115","-75.2976","United States","US","USA","Pennsylvania","","18073","1840142125"
"Manthani","Manthani","18.6500","79.6667","India","IN","IND","Andhra Pradesh","","17927","1356453110"
"Stilfontein","Stilfontein","-26.8428","26.7744","South Africa","ZA","ZAF","North West","","17942","1710755167"
"Turmalina","Turmalina","-17.2858","-42.7300","Brazil","BR","BRA","Minas Gerais","","18055","1076799551"
"Villanueva del Pardillo","Villanueva del Pardillo","40.4833","-3.9667","Spain","ES","ESP","Madrid","","17382","1724000236"
"Abangaritos","Abangaritos","10.2492","-85.0268","Costa Rica","CR","CRI","Puntarenas","","18039","1188357414"
"Anda","Anda","9.7440","124.5760","Philippines","PH","PHL","Bohol","minor","17778","1608563628"
"Cortes","Cortes","9.2753","126.1911","Philippines","PH","PHL","Surigao del Sur","","17924","1608215343"
"La Marque","La Marque","29.3683","-94.9941","United States","US","USA","Texas","","18065","1840020972"
"Biro","Biro","9.9000","2.9333","Benin","BJ","BEN","Borgou","","18003","1204043636"
"Scarsdale","Scarsdale","40.9902","-73.7773","United States","US","USA","New York","","18063","1840004943"
"Freudenberg","Freudenberg","50.8997","7.8667","Germany","DE","DEU","North Rhine-Westphalia","","17739","1276203816"
"Tsianisiha","Tsianisiha","-22.9000","43.5833","Madagascar","MG","MDG","Toliara","","18000","1450654837"
"Pūlla","Pulla","16.8060","81.3241","India","IN","IND","Andhra Pradesh","","17670","1356959196"
"San Rafael","San Rafael","11.1728","122.8267","Philippines","PH","PHL","Iloilo","","17795","1608423819"
"Dahibhāt Mādhopur","Dahibhat Madhopur","26.2763","86.0847","India","IN","IND","Bihār","","16461","1356301597"
"Tall Qaşab","Tall Qasab","36.2583","41.9431","Iraq","IQ","IRQ","Nīnawá","","18000","1368840597"
"Maryland City","Maryland City","39.1016","-76.8051","United States","US","USA","Maryland","","18059","1840005922"
"Jaicós","Jaicos","-7.3589","-41.1378","Brazil","BR","BRA","Piauí","","18035","1076153512"
"Hijuelas","Hijuelas","-32.7986","-71.1439","Chile","CL","CHL","Valparaíso","","17988","1152290187"
"Boone","Boone","36.2111","-81.6669","United States","US","USA","North Carolina","","18055","1840015310"
"Mabéhiri","Mabehiri","5.6833","-6.4167","Côte d'Ivoire","CI","CIV","Bas-Sassandra","","17909","1384102956"
"Cruz Machado","Cruz Machado","-26.0169","-51.3469","Brazil","BR","BRA","Paraná","","18040","1076000409"
"Curti","Curti","15.4167","74.0167","India","IN","IND","Goa","","17396","1356029081"
"Sorrento","Sorrento","40.6278","14.3736","Italy","IT","ITA","Campania","","16405","1380150181"
"South Hadley","South Hadley","42.2567","-72.5793","United States","US","USA","Massachusetts","","18051","1840053467"
"Fuente-Álamo de Murcia","Fuente-Alamo de Murcia","37.7394","-1.1881","Spain","ES","ESP","Murcia","","17589","1724036059"
"Belobaka","Belobaka","-18.9833","45.6833","Madagascar","MG","MDG","Antananarivo","","18000","1450102483"
"Amboahangibe","Amboahangibe","-14.1333","49.8167","Madagascar","MG","MDG","Antsiranana","","18000","1450703164"
"Silvāni","Silvani","23.3026","78.4408","India","IN","IND","Madhya Pradesh","","17892","1356940965"
"White Settlement","White Settlement","32.7554","-97.4605","United States","US","USA","Texas","","18040","1840022051"
"Munguía","Munguia","43.3547","-2.8472","Spain","ES","ESP","Basque Country","","17701","1724541767"
"Vilāttikulam","Vilattikulam","9.1312","78.1687","India","IN","IND","Tamil Nādu","","17875","1356651559"
"Sinzig","Sinzig","50.5453","7.2519","Germany","DE","DEU","Rhineland-Palatinate","","17614","1276200986"
"Sevierville","Sevierville","35.8872","-83.5678","United States","US","USA","Tennessee","","18038","1840015359"
"Zuhres","Zuhres","48.0181","38.2563","Ukraine","UA","UKR","Donetska Oblast","","18024","1804960043"
"Antanambe","Antanambe","-16.4333","49.8500","Madagascar","MG","MDG","Toamasina","","18000","1450702701"
"Oroszlány","Oroszlany","47.4833","18.3167","Hungary","HU","HUN","Komárom-Esztergom","minor","17802","1348109196"
"Batié","Batie","9.8833","-2.9167","Burkina Faso","BF","BFA","Sud-Ouest","minor","17997","1854437674"
"Ibrāhīmpatan","Ibrahimpatan","17.1017","78.6294","India","IN","IND","Andhra Pradesh","","17345","1356313323"
"Qal‘ah-ye Zāl","Qal\`ah-ye Zal","37.0150","68.4672","Afghanistan","AF","AFG","Kunduz","","18000","1004481163"
"Itaberá","Itabera","-23.8619","-49.1372","Brazil","BR","BRA","São Paulo","","18015","1076280626"
"Maroteza","Maroteza","-22.3000","47.2000","Madagascar","MG","MDG","Fianarantsoa","","18000","1450569140"
"Shirosato","Shirosato","36.4792","140.3762","Japan","JP","JPN","Ibaraki","","17916","1392999984"
"Ambovombe Afovoany","Ambovombe Afovoany","-20.7667","47.0833","Madagascar","MG","MDG","Fianarantsoa","","18000","1450912618"
"Kolonodale","Kolonodale","-1.9833","121.3333","Indonesia","ID","IDN","Sulawesi Tengah","minor","17997","1360484541"
"Matsakabanja","Matsakabanja","-15.9167","45.8333","Madagascar","MG","MDG","Mahajanga","","18000","1450113608"
"Texistepeque","Texistepeque","14.1333","-89.5000","El Salvador","SV","SLV","Santa Ana","","17923","1222835765"
"Polignano a Mare","Polignano a Mare","41.0000","17.2167","Italy","IT","ITA","Puglia","","18022","1380726054"
"Tranoroa","Tranoroa","-24.7000","45.0667","Madagascar","MG","MDG","Toliara","","18000","1450659939"
"Pomáz","Pomaz","47.6431","19.0196","Hungary","HU","HUN","Pest","","17660","1348490155"
"Sileby","Sileby","52.7310","-1.1060","United Kingdom","GB","GBR","Leicestershire","","17835","1826945985"
"Mosbrough","Mosbrough","53.3250","-1.3620","United Kingdom","GB","GBR","Sheffield","","17097","1826344897"
"Yoboki","Yoboki","11.5167","42.1000","Djibouti","DJ","DJI","Dikhil","","18000","1262096912"
"Castaic","Castaic","34.4818","-118.6316","United States","US","USA","California","","18017","1840022826"
"Maridi","Maridi","4.9100","29.4500","South Sudan","SS","SSD","Western Equatoria","","18000","1728685965"
"Busolwe","Busolwe","0.8492","33.9269","Uganda","UG","UGA","Butaleja","","16800","1800677931"
"Beantake","Beantake","-23.8000","44.3000","Madagascar","MG","MDG","Toliara","","18000","1450564654"
"Neftegorsk","Neftegorsk","52.8000","51.1667","Russia","RU","RUS","Samarskaya Oblast’","","17990","1643711805"
"Tárrega","Tarrega","41.6464","1.1394","Spain","ES","ESP","Catalonia","","17810","1724893355"
"Namayingo","Namayingo","0.3450","33.8800","Uganda","UG","UGA","Namayingo","","17300","1800364038"
"Mahazoma","Mahazoma","-17.1667","46.5667","Madagascar","MG","MDG","Mahajanga","","18000","1450338910"
"Ginsheim-Gustavsburg","Ginsheim-Gustavsburg","49.9833","8.3333","Germany","DE","DEU","Hesse","","16807","1276577011"
"General Deheza","General Deheza","-32.7564","-63.7889","Argentina","AR","ARG","Córdoba","","18000","1032325674"
"Bemahatazana-Belobaka","Bemahatazana-Belobaka","-19.3500","46.0000","Madagascar","MG","MDG","Antananarivo","","18000","1450930842"
"Pimentel","Pimentel","19.1833","-70.1000","Dominican Republic","DO","DOM","Cibao Nordeste","","17864","1214665602"
"Doruma","Doruma","4.7333","27.7000","Congo (Kinshasa)","CD","COD","Haut-Uélé","","18000","1180085699"
"Ashby de la Zouch","Ashby de la Zouch","52.7460","-1.4760","United Kingdom","GB","GBR","Leicestershire","","16491","1826304398"
"Thānga","Thanga","24.5500","93.7600","India","IN","IND","Manipur","","17272","1356919276"
"Rypin","Rypin","53.0667","19.4500","Poland","PL","POL","Kujawsko-Pomorskie","minor","16558","1616716547"
"Baronissi","Baronissi","40.7462","14.7705","Italy","IT","ITA","Campania","","17051","1380376060"
"Alice","Alice","27.7556","-98.0653","United States","US","USA","Texas","","18001","1840019714"
"Brielle","Brielle","51.9000","4.1667","Netherlands","NL","NLD","Zuid-Holland","minor","17439","1528923467"
"Flora","Flora","18.2147","121.4186","Philippines","PH","PHL","Apayao","","17944","1608587235"
"Oborniki","Oborniki","52.6500","16.8167","Poland","PL","POL","Wielkopolskie","minor","17895","1616127510"
"Ervália","Ervalia","-20.8400","-42.6569","Brazil","BR","BRA","Minas Gerais","","17946","1076582850"
"Arco","Arco","45.9167","10.8833","Italy","IT","ITA","Trentino-Alto Adige","","17716","1380761996"
"Ibirataia","Ibirataia","-14.0669","-39.6408","Brazil","BR","BRA","Bahia","","17947","1076392092"
"Alton","Alton","26.2884","-98.3098","United States","US","USA","Texas","","17993","1840019733"
"Outat Oulad Al Haj","Outat Oulad Al Haj","33.3333","-3.6833","Morocco","MA","MAR","Fès-Meknès","","16388","1504806480"
"Messias","Messias","-9.3828","-35.8419","Brazil","BR","BRA","Alagoas","","17988","1076673417"
"Centralia","Centralia","46.7223","-122.9696","United States","US","USA","Washington","","17988","1840018471"
"Hương Canh","Huong Canh","21.2833","105.6500","Vietnam","VN","VNM","Vĩnh Phúc","minor","16341","1704639991"
"Remagen","Remagen","50.5786","7.2306","Germany","DE","DEU","Rhineland-Palatinate","","17456","1276287418"
"Odaipatti","Odaipatti","9.8323","77.4433","India","IN","IND","Tamil Nādu","","17401","1356243274"
"Towamencin","Towamencin","40.2417","-75.3387","United States","US","USA","Pennsylvania","","17979","1840152389"
"Sabana de Torres","Sabana de Torres","7.4000","-73.5000","Colombia","CO","COL","Santander","minor","17965","1170400571"
"Crowley","Crowley","32.5781","-97.3585","United States","US","USA","Texas","","17977","1840019431"
"Puurs","Puurs","51.0761","4.2803","Belgium","BE","BEL","Flanders","","17452","1056438332"
"Kharabali","Kharabali","47.4050","47.2556","Russia","RU","RUS","Astrakhanskaya Oblast’","","17968","1643134744"
"Oconomowoc","Oconomowoc","43.0996","-88.4948","United States","US","USA","Wisconsin","","17969","1840003027"
"Kongsvinger","Kongsvinger","60.1905","11.9977","Norway","NO","NOR","Innlandet","minor","17949","1578097529"
"Antenor Navarro","Antenor Navarro","-6.7289","-38.4489","Brazil","BR","BRA","Paraíba","","17929","1076302575"
"Marabut","Marabut","11.1167","125.2167","Philippines","PH","PHL","Samar","","17842","1608504628"
"Pontarlier","Pontarlier","46.9061","6.3547","France","FR","FRA","Bourgogne-Franche-Comté","minor","17542","1250265545"
"Redland","Redland","39.1339","-77.1464","United States","US","USA","Maryland","","17965","1840005838"
"Melfi","Melfi","41.0000","15.6500","Italy","IT","ITA","Basilicata","","17878","1380950870"
"Jovellar","Jovellar","13.0667","123.6000","Philippines","PH","PHL","Albay","","17795","1608200196"
"Laurel","Laurel","37.6375","-77.5062","United States","US","USA","Virginia","","17962","1840006393"
"Farmington","Farmington","37.7822","-90.4282","United States","US","USA","Missouri","","17959","1840008686"
"Pelaya","Pelaya","8.6833","-73.6667","Colombia","CO","COL","Cesar","minor","17910","1170015000"
"Policoro","Policoro","40.2000","16.6667","Italy","IT","ITA","Basilicata","","17694","1380519892"
"Susner","Susner","23.9467","76.0882","India","IN","IND","Madhya Pradesh","","17727","1356506764"
"Papanduva","Papanduva","-26.3700","-50.1439","Brazil","BR","BRA","Santa Catarina","","17928","1076237146"
"Kakkalapalle","Kakkalapalle","14.6415","77.5668","India","IN","IND","Andhra Pradesh","","16654","1356669495"
"Kreuzau","Kreuzau","50.7470","6.4907","Germany","DE","DEU","North Rhine-Westphalia","","17532","1276795378"
"Frankenberg","Frankenberg","51.0589","8.7967","Germany","DE","DEU","Hesse","","17808","1276051534"
"Coltauco","Coltauco","-34.3000","-71.1000","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","17918","1152000004"
"Zapotlán del Rey","Zapotlan del Rey","20.4674","-102.9248","Mexico","MX","MEX","Jalisco","minor","17893","1484897349"
"Gaoniang","Gaoniang","26.8394","109.1770","China","CN","CHN","Guizhou","","17878","1156096505"
"Harwich","Harwich","51.9340","1.2660","United Kingdom","GB","GBR","Essex","","17684","1826285169"
"Shorewood","Shorewood","41.5169","-88.2146","United States","US","USA","Illinois","","17947","1840011496"
"Lagoa da Canoa","Lagoa da Canoa","-9.8300","-36.7378","Brazil","BR","BRA","Alagoas","","17771","1076877725"
"Unión de San Antonio","Union de San Antonio","21.1280","-102.0060","Mexico","MX","MEX","Jalisco","minor","17915","1484001030"
"Lobogo","Lobogo","6.6333","1.9000","Benin","BJ","BEN","Mono","","17622","1204784192"
"Juazeirinho","Juazeirinho","-7.0678","-36.5778","Brazil","BR","BRA","Paraíba","","17902","1076068443"
"Rājbalhāi","Rajbalhai","22.7600","88.0400","India","IN","IND","West Bengal","","16479","1356531992"
"Pyskowice","Pyskowice","50.3833","18.6167","Poland","PL","POL","Śląskie","","17394","1616775078"
"Manambaro","Manambaro","-25.0333","46.8167","Madagascar","MG","MDG","Toliara","","17901","1450768903"
"McKeesport","McKeesport","40.3418","-79.8440","United States","US","USA","Pennsylvania","","17935","1840001243"
"Ban Thum","Ban Thum","16.4523","102.7225","Thailand","TH","THA","Khon Kaen","","17564","1764043980"
"Cohoes","Cohoes","42.7732","-73.7077","United States","US","USA","New York","","17931","1840000418"
"Saint Andrews","Saint Andrews","56.3404","-2.7955","United Kingdom","GB","GBR","Fife","","17580","1826606142"
"Tres de Mayo","Tres de Mayo","-26.4794","-56.0961","Paraguay","PY","PRY","Caazapá","","17928","1600000015"
"Golitsyno","Golitsyno","55.6147","36.9872","Russia","RU","RUS","Moskovskaya Oblast’","","17651","1643646490"
"Weilerswist","Weilerswist","50.7525","6.8378","Germany","DE","DEU","North Rhine-Westphalia","","17619","1276196385"
"Bhai Rupa","Bhai Rupa","30.4311","75.2206","India","IN","IND","Punjab","","17548","1356533219"
"Buchen in Odenwald","Buchen in Odenwald","49.5217","9.3233","Germany","DE","DEU","Baden-Württemberg","","17796","1276974738"
"Guapiara","Guapiara","-24.1850","-48.5328","Brazil","BR","BRA","São Paulo","","17879","1076094869"
"Kristinehamn","Kristinehamn","59.3000","14.1167","Sweden","SE","SWE","Värmland","minor","17839","1752708772"
"Yaguachi Nuevo","Yaguachi Nuevo","-2.1200","-79.6900","Ecuador","EC","ECU","Guayas","","17806","1218680506"
"Āyikudi","Ayikudi","9.0032","77.3449","India","IN","IND","Tamil Nādu","","17067","1356213628"
"Marmande","Marmande","44.5000","0.1653","France","FR","FRA","Nouvelle-Aquitaine","minor","17534","1250008059"
"Monselice","Monselice","45.2333","11.7500","Italy","IT","ITA","Veneto","","17572","1380805860"
"Cary","Cary","42.2129","-88.2494","United States","US","USA","Illinois","","17919","1840010109"
"Broken Hill","Broken Hill","-31.9500","141.4667","Australia","AU","AUS","New South Wales","","17814","1036216956"
"Bay City","Bay City","28.9838","-95.9601","United States","US","USA","Texas","","17918","1840019682"
"Nguigmi","Nguigmi","14.2532","13.1108","Niger","NE","NER","Diffa","minor","17897","1562214324"
"Kirsanov","Kirsanov","52.6500","42.7333","Russia","RU","RUS","Tambovskaya Oblast’","","16409","1643643425"
"Madanpur","Madanpur","26.2103","87.5141","India","IN","IND","Bihār","","16851","1356082917"
"Biliran","Biliran","11.5800","124.4800","Philippines","PH","PHL","Biliran","","17662","1608175854"
"South Fayette","South Fayette","40.3556","-80.1617","United States","US","USA","Pennsylvania","","17913","1840150313"
"Boqueirão","Boqueirao","-7.4819","-36.1350","Brazil","BR","BRA","Paraíba","","17870","1076458629"
"Anadia","Anadia","-9.6844","-36.3042","Brazil","BR","BRA","Alagoas","","17818","1076360866"
"Puquio","Puquio","-14.6939","-74.1241","Peru","PE","PER","Ayacucho","","17890","1604629997"
"Benavente","Benavente","42.0031","-5.6742","Spain","ES","ESP","Castille-Leon","","17523","1724254951"
"Berlín","Berlin","13.5000","-88.5333","El Salvador","SV","SLV","Usulután","","17787","1222050508"
"Herve","Herve","50.6333","5.8000","Belgium","BE","BEL","Wallonia","","17598","1056779487"
"Międzyrzec Podlaski","Miedzyrzec Podlaski","51.9833","22.7833","Poland","PL","POL","Lubelskie","","17117","1616448418"
"Pālaiyam","Palaiyam","10.7256","78.1354","India","IN","IND","Tamil Nādu","","17556","1356219223"
"Galich","Galich","58.3833","42.3500","Russia","RU","RUS","Kostromskaya Oblast’","","16911","1643147614"
"Serafina Corêa","Serafina Corea","-28.7119","-51.9350","Brazil","BR","BRA","Rio Grande do Sul","","17795","1076410679"
"Eslöv","Eslov","55.8392","13.3039","Sweden","SE","SWE","Skåne","minor","17748","1752202546"
"Yuanquan","Yuanquan","40.5004","95.8000","China","CN","CHN","Gansu","minor","17886","1156339161"
"Khirhar","Khirhar","26.5168","85.9618","India","IN","IND","Bihār","","16642","1356956123"
"Ambohitralanana","Ambohitralanana","-15.2333","50.4500","Madagascar","MG","MDG","Antsiranana","","17850","1450680382"
"Sakardih","Sakardih","25.2280","85.3546","India","IN","IND","Bihār","","16447","1356519554"
"Porto San Giorgio","Porto San Giorgio","43.1833","13.8000","Italy","IT","ITA","Marche","","16068","1380690749"
"Groveland","Groveland","28.6098","-81.8246","United States","US","USA","Florida","","17896","1840014056"
"Catarman","Catarman","9.1333","124.6833","Philippines","PH","PHL","Camiguin","","17569","1608981725"
"San Bartolo Tutotepec","San Bartolo Tutotepec","20.4000","-98.2000","Mexico","MX","MEX","Hidalgo","minor","17837","1484290508"
"Wangaratta","Wangaratta","-36.3583","146.3125","Australia","AU","AUS","Victoria","","17377","1036395326"
"Settiyārpatti","Settiyarpatti","9.3935","77.4935","India","IN","IND","Tamil Nādu","","17150","1356209039"
"Cullman","Cullman","34.1775","-86.8407","United States","US","USA","Alabama","","17892","1840001354"
"North Aurora","North Aurora","41.8086","-88.3414","United States","US","USA","Illinois","","17892","1840011351"
"Lillerød","Lillerod","55.8681","12.3417","Denmark","DK","DNK","Hovedstaden","","16793","1208644794"
"Gazantarak","Gazantarak","39.9667","69.1333","Tajikistan","TJ","TJK","Sughd","","17801","1762795587"
"Ocean Acres","Ocean Acres","39.7430","-74.2805","United States","US","USA","New Jersey","","17886","1840033483"
"Norrtälje","Norrtalje","59.7667","18.7000","Sweden","SE","SWE","Stockholm","minor","17275","1752949126"
"Arai","Arai","34.6833","137.5667","Japan","JP","JPN","Shizuoka","","16975","1392537304"
"San Rafael del Norte","San Rafael del Norte","13.2128","-86.1108","Nicaragua","NI","NIC","Jinotega","minor","17789","1558286153"
"North Canton","North Canton","40.8742","-81.3971","United States","US","USA","Ohio","","17884","1840008290"
"Panama City Beach","Panama City Beach","30.2370","-85.8776","United States","US","USA","Florida","","17882","1840015035"
"Tiffin","Tiffin","41.1165","-83.1805","United States","US","USA","Ohio","","17882","1840000821"
"Banhatti","Banhatti","16.3853","75.1494","India","IN","IND","Karnātaka","","17460","1356647094"
"Holzwickede","Holzwickede","51.5000","7.6167","Germany","DE","DEU","North Rhine-Westphalia","","17118","1276204941"
"Al Qbab","Al Qbab","32.7333","-5.5167","Morocco","MA","MAR","Béni Mellal-Khénifra","","16157","1504689479"
"Jacupiranga","Jacupiranga","-24.6925","-48.0022","Brazil","BR","BRA","São Paulo","","17851","1076127392"
"Jaguaretama","Jaguaretama","-5.6128","-38.7669","Brazil","BR","BRA","Ceará","","17863","1076022624"
"Birhana","Birhana","25.4489","85.7054","India","IN","IND","Bihār","","16098","1356023477"
"Alton","Alton","51.1498","-0.9769","United Kingdom","GB","GBR","Hampshire","","17816","1826279601"
"Ban Mae Ngon Khilek","Ban Mae Ngon Khilek","19.8021","99.1614","Thailand","TH","THA","Chiang Mai","","17715","1764180148"
"Buguda","Buguda","19.8081","84.7908","India","IN","IND","Odisha","","17499","1356831429"
"Arinos","Arinos","-15.9169","-46.1058","Brazil","BR","BRA","Minas Gerais","","17862","1076034909"
"Montanha","Montanha","-18.1269","-40.3628","Brazil","BR","BRA","Espírito Santo","","17849","1076200143"
"Cenoví","Cenovi","19.2500","-70.3700","Dominican Republic","DO","DOM","Cibao Nordeste","","17857","1214125293"
"Douar Oulad Mbarek","Douar Oulad Mbarek","34.2833","-4.3167","Morocco","MA","MAR","Fès-Meknès","","17747","1504643299"
"Taree","Taree","-31.9000","152.4500","Australia","AU","AUS","New South Wales","","16197","1036855717"
"Frederikssund","Frederikssund","55.8333","12.0666","Denmark","DK","DNK","Hovedstaden","minor","16850","1208511565"
"Yanggezhuang","Yanggezhuang","39.3831","118.7119","China","CN","CHN","Hebei","","16959","1156200427"
"Kāgvād","Kagvad","16.4800","74.7800","India","IN","IND","Mahārāshtra","","17554","1356059342"
"Carlentini","Carlentini","37.2833","15.0167","Italy","IT","ITA","Sicilia","","17741","1380090560"
"Amba Icharua","Amba Icharua","25.6140","86.4258","India","IN","IND","Bihār","","16498","1356276487"
"Kukmor","Kukmor","56.1855","50.8944","Russia","RU","RUS","Tatarstan","","17815","1643556564"
"Barão do Grajaú","Barao do Grajau","-6.7558","-43.0239","Brazil","BR","BRA","Maranhão","","17841","1076023877"
"Nazaré Paulista","Nazare Paulista","-23.1808","-46.3950","Brazil","BR","BRA","São Paulo","","17794","1076428478"
"Rio Claro","Rio Claro","-22.7228","-44.1358","Brazil","BR","BRA","Rio de Janeiro","","17826","1076913313"
"Salgar","Salgar","5.9617","-75.9753","Colombia","CO","COL","Antioquia","minor","17804","1170000086"
"Bon Air","Bon Air","37.5187","-77.5713","United States","US","USA","Virginia","","17846","1840006409"
"Manzanares","Manzanares","38.9964","-3.3731","Spain","ES","ESP","Castille-La Mancha","","17845","1724101525"
"Mitake","Mitake","35.4344","137.1307","Japan","JP","JPN","Gifu","","17536","1392599630"
"Schwalbach","Schwalbach","49.2833","6.8167","Germany","DE","DEU","Saarland","","17218","1276949643"
"Colón","Colon","9.9096","-84.2620","Costa Rica","CR","CRI","San José","","17411","1188235947"
"Ash Shaykhān","Ash Shaykhan","36.6917","43.3500","Iraq","IQ","IRQ","Nīnawá","minor","17766","1368512457"
"Cesson-Sévigné","Cesson-Sevigne","48.1208","-1.6036","France","FR","FRA","Bretagne","","17312","1250083001"
"Orta Nova","Orta Nova","41.3308","15.7114","Italy","IT","ITA","Puglia","","17675","1380032682"
"Wadgassen","Wadgassen","49.2667","6.7833","Germany","DE","DEU","Saarland","","17190","1276539797"
"Isola Capo Rizzuto","Isola Capo Rizzuto","38.9589","17.0956","Italy","IT","ITA","Calabria","","17832","1380206384"
"Shangjing","Shangjing","24.6076","114.9939","China","CN","CHN","Guangdong","","17534","1156630379"
"Zhongguyue","Zhongguyue","38.2833","113.8670","China","CN","CHN","Hebei","","17610","1156200266"
"Gretna","Gretna","29.9101","-90.0515","United States","US","USA","Louisiana","","17836","1840013996"
"Miaojiaping","Miaojiaping","37.5777","110.0658","China","CN","CHN","Shaanxi","","17689","1156026056"
"Longjia","Longjia","36.0608","113.4128","China","CN","CHN","Shanxi","","17622","1156512875"
"Sobinka","Sobinka","56.0000","40.0333","Russia","RU","RUS","Vladimirskaya Oblast’","","17799","1643819695"
"Ngara","Ngara","-2.5122","30.6558","Tanzania","TZ","TZA","Kagera","","16890","1834067520"
"Seyah Cheshmeh","Seyah Cheshmeh","39.0631","44.3836","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","17804","1364268650"
"Salonta","Salonta","46.8000","21.6500","Romania","RO","ROU","Bihor","","17735","1642519468"
"Doylestown","Doylestown","40.2962","-75.1393","United States","US","USA","Pennsylvania","","17825","1840001320"
"Las Heras","Las Heras","-46.5500","-68.9500","Argentina","AR","ARG","Santa Cruz","","17821","1032657206"
"Pinili","Pinili","17.9540","120.5270","Philippines","PH","PHL","Ilocos Norte","","17626","1608153949"
"Bovisio Masciago","Bovisio Masciago","45.6167","9.1500","Italy","IT","ITA","Lombardy","","16929","1380192033"
"Pātan","Patan","23.2864","79.6896","India","IN","IND","Madhya Pradesh","","17441","1356772419"
"Porciúncula","Porciuncula","-20.9628","-42.0408","Brazil","BR","BRA","Rio de Janeiro","","17760","1076077917"
"Bechem","Bechem","7.0833","-2.0167","Ghana","GH","GHA","Ahafo","","17677","1288926942"
"São Benedito do Rio Preto","Sao Benedito do Rio Preto","-3.3339","-43.5278","Brazil","BR","BRA","Maranhão","","17799","1076042619"
"Bāţūfah","Batufah","37.1744","43.0132","Iraq","IQ","IRQ","Dahūk","minor","17186","1368000329"
"Agua Blanca","Agua Blanca","14.4833","-89.6494","Guatemala","GT","GTM","Jutiapa","minor","17765","1320861023"
"Sapeaçu","Sapeacu","-12.7278","-39.1819","Brazil","BR","BRA","Bahia","","17664","1076513396"
"Köping","Koping","59.5167","15.9833","Sweden","SE","SWE","Västmanland","minor","17743","1752366756"
"Godfrey","Godfrey","38.9577","-90.2156","United States","US","USA","Illinois","","17812","1840012796"
"Daimiel","Daimiel","39.0833","-3.6167","Spain","ES","ESP","Castille-La Mancha","","17771","1724840629"
"Ban Phe","Ban Phe","12.6287","101.4399","Thailand","TH","THA","Rayong","","17116","1764896078"
"Hlybokaye","Hlybokaye","55.1333","27.6833","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","17790","1112510591"
"Pirapora do Bom Jesus","Pirapora do Bom Jesus","-23.3972","-47.0028","Brazil","BR","BRA","São Paulo","","17646","1076000099"
"Sirari","Sirari","-1.2244","34.4383","Tanzania","TZ","TZA","Mara","","17564","1834994899"
"Não-Me-Toque","Nao-Me-Toque","-28.4589","-52.8208","Brazil","BR","BRA","Rio Grande do Sul","","17758","1076179285"
"Oerlinghausen","Oerlinghausen","51.9667","8.6667","Germany","DE","DEU","North Rhine-Westphalia","","17286","1276933659"
"Latsia","Latsia","35.1000","33.3667","Cyprus","CY","CYP","Lefkosía","","16774","1196538391"
"Portsmouth","Portsmouth","41.5922","-71.2745","United States","US","USA","Rhode Island","","17802","1840066124"
"Dirba","Dirba","30.0700","75.9800","India","IN","IND","Punjab","","17264","1356277764"
"Santa Elena","Santa Elena","-30.9500","-59.8000","Argentina","AR","ARG","Entre Ríos","","17791","1032467373"
"Trossingen","Trossingen","48.0756","8.6361","Germany","DE","DEU","Baden-Württemberg","","17095","1276343997"
"El Dorado","El Dorado","33.2184","-92.6640","United States","US","USA","Arkansas","","17800","1840013773"
"Grenchen","Grenchen","47.1906","7.3964","Switzerland","CH","CHE","Solothurn","","17140","1756258549"
"Esperanza","Esperanza","11.7369","124.0417","Philippines","PH","PHL","Masbate","","17534","1608888956"
"Morros","Morros","-2.8639","-44.0389","Brazil","BR","BRA","Maranhão","","17783","1076928114"
"Cameron Park","Cameron Park","38.6738","-120.9872","United States","US","USA","California","","17790","1840028322"
"Orsay","Orsay","48.6981","2.1875","France","FR","FRA","Île-de-France","","15844","1250000776"
"Portchester","Portchester","50.8420","-1.1200","United Kingdom","GB","GBR","Hampshire","","17789","1826303470"
"Beni Fouda","Beni Fouda","36.2861","5.6071","Algeria","DZ","DZA","Sétif","","17667","1012660842"
"Poyo","Poyo","42.4333","-8.6667","Spain","ES","ESP","Galicia","","17276","1724049171"
"Gautampura","Gautampura","22.9866","75.5192","India","IN","IND","Madhya Pradesh","","17457","1356053148"
"Reni","Reni","45.4575","28.2711","Ukraine","UA","UKR","Odeska Oblast","minor","17736","1804505327"
"Kiangan","Kiangan","16.7775","121.0878","Philippines","PH","PHL","Ifugao","","17691","1608326470"
"Guttal","Guttal","14.8333","75.6333","India","IN","IND","Karnātaka","","17421","1356063552"
"Minquan","Minquan","37.4420","103.3811","China","CN","CHN","Gansu","","17663","1156696426"
"Swellendam","Swellendam","-34.0231","20.4400","South Africa","ZA","ZAF","Western Cape","","17537","1710416218"
"Rockland","Rockland","42.1295","-70.9100","United States","US","USA","Massachusetts","","17774","1840053576"
"Bishunpura","Bishunpura","26.5436","84.4364","India","IN","IND","Bihār","","16958","1356127085"
"Calverton","Calverton","39.0578","-76.9488","United States","US","USA","Maryland","","17772","1840031445"
"Cairu","Cairu","-13.4869","-39.0439","Brazil","BR","BRA","Bahia","","17730","1076381355"
"Winder","Winder","33.9917","-83.7218","United States","US","USA","Georgia","","17768","1840015623"
"Kahoku","Kahoku","38.4263","140.3143","Japan","JP","JPN","Yamagata","","17435","1392003454"
"Sanary-sur-Mer","Sanary-sur-Mer","43.1192","5.8022","France","FR","FRA","Provence-Alpes-Côte d’Azur","","16889","1250421120"
"Troy","Troy","31.8021","-85.9664","United States","US","USA","Alabama","","17765","1840005558"
"Balaguer","Balaguer","41.7904","0.8056","Spain","ES","ESP","Catalonia","","17460","1724316561"
"Monzón","Monzon","41.9100","0.1900","Spain","ES","ESP","Aragon","","17650","1724852393"
"Zaslawye","Zaslawye","54.0083","27.2847","Belarus","BY","BLR","Minskaya Voblasts’","","17701","1112333553"
"Turuvekere","Turuvekere","13.1637","76.6664","India","IN","IND","Karnātaka","","17530","1356316443"
"Nova Pazova","Nova Pazova","44.9500","20.2167","Serbia","RS","SRB","","","17105","1688348841"
"Laç","Lac","41.6353","19.7131","Albania","AL","ALB","Lezhë","","17086","1008762785"
"Sāram","Saram","23.7625","85.8300","India","IN","IND","Jhārkhand","","17298","1356192414"
"Zalţan","Zaltan","32.9500","11.8658","Libya","LY","LBY","An Nuqāţ al Khams","","17700","1434839248"
"Tolūprpatti","Toluprpatti","11.0244","78.3134","India","IN","IND","Tamil Nādu","","17087","1356232670"
"Banora Point","Banora Point","-28.2225","153.5386","Australia","AU","AUS","New South Wales","","16167","1036608972"
"Balighattam","Balighattam","17.6510","82.6059","India","IN","IND","Andhra Pradesh","","16695","1356864369"
"Złotoryja","Zlotoryja","51.1333","15.9167","Poland","PL","POL","Dolnośląskie","minor","16479","1616742834"
"Glen Parva","Glen Parva","52.5867","-1.1617","United Kingdom","GB","GBR","Leicestershire","","17189","1826454038"
"Dingjiagouxiang","Dingjiagouxiang","35.5307","105.0207","China","CN","CHN","Gansu","","17639","1156074860"
"Duijiang","Duijiang","27.0782","105.5256","China","CN","CHN","Guizhou","","17533","1156741560"
"Stegen","Stegen","47.4760","8.7700","Switzerland","CH","CHE","Zürich","","17630","1756008872"
"Canapi","Canapi","-9.1269","-37.6050","Brazil","BR","BRA","Alagoas","","17719","1076262890"
"Anoka","Anoka","45.2099","-93.3893","United States","US","USA","Minnesota","","17749","1840006717"
"Qal’at Mgouna","Qal'at Mgouna","31.2414","-6.1283","Morocco","MA","MAR","Drâa-Tafilalet","","16956","1504989758"
"Laatatra","Laatatra","32.6315","-8.4148","Morocco","MA","MAR","Casablanca-Settat","","17593","1504800245"
"Nīkshahr","Nikshahr","26.2258","60.2142","Iran","IR","IRN","Sīstān va Balūchestān","minor","17732","1364780885"
"Eastlake","Eastlake","41.6581","-81.4322","United States","US","USA","Ohio","","17743","1840000533"
"Ruhango","Ruhango","-2.2325","29.7803","Rwanda","RW","RWA","Southern Province","","17051","1646180341"
"Madison","Madison","41.3398","-72.6278","United States","US","USA","Connecticut","","17742","1840045038"
"Linbian","Linbian","22.4333","120.5167","Taiwan","TW","TWN","Pingtung","","16698","1158632383"
"Talitay","Talitay","7.0353","124.3758","Philippines","PH","PHL","Maguindanao","","17463","1608386370"
"Cipó","Cipo","-11.1000","-38.5169","Brazil","BR","BRA","Bahia","","17602","1076696194"
"Amacuzac","Amacuzac","18.6000","-99.3700","Mexico","MX","MEX","Morelos","minor","17598","1484072402"
"Sierre","Sierre","46.3000","7.5333","Switzerland","CH","CHE","Valais","","16860","1756959511"
"Schrobenhausen","Schrobenhausen","48.5333","11.2667","Germany","DE","DEU","Bavaria","","17505","1276307699"
"Kodumudi","Kodumudi","11.0769","77.8876","India","IN","IND","Tamil Nādu","","16716","1356494184"
"Ban Pong","Ban Pong","13.8174","99.8830","Thailand","TH","THA","Ratchaburi","minor","17298","1764963515"
"Itacarambi","Itacarambi","-15.1019","-44.0919","Brazil","BR","BRA","Minas Gerais","","17720","1076622492"
"Bahon","Bahon","19.4694","-72.1147","Haiti","HT","HTI","Nord","","17417","1332943637"
"Lousã","Lousa","40.1125","-8.2469","Portugal","PT","PRT","Coimbra","minor","17604","1620586922"
"Tábara Arriba","Tabara Arriba","18.5694","-70.8792","Dominican Republic","DO","DOM","Valdesia","","17647","1214703827"
"New River","New River","33.8835","-112.0858","United States","US","USA","Arizona","","17731","1840019307"
"Estancia Pozo Colorado","Estancia Pozo Colorado","-23.4136","-58.9144","Paraguay","PY","PRY","Presidente Hayes","","17727","1600995173"
"Ban Mae Ka Hua Thung","Ban Mae Ka Hua Thung","19.0942","99.9136","Thailand","TH","THA","Phayao","","17629","1764621599"
"Cerrillos","Cerrillos","-24.9000","-65.4833","Argentina","AR","ARG","Salta","minor","17634","1032917216"
"Pirayú","Pirayu","-25.4800","-57.2300","Paraguay","PY","PRY","Paraguarí","","17601","1600633221"
"Schaesberg","Schaesberg","50.9000","6.0167","Netherlands","NL","NLD","Limburg","","15900","1528425439"
"Tanque Novo","Tanque Novo","-13.5458","-42.4908","Brazil","BR","BRA","Bahia","","17702","1076829439"
"Yerrapālem","Yerrapalem","16.1500","80.0300","India","IN","IND","Andhra Pradesh","","17336","1356787733"
"Mount Washington","Mount Washington","38.0430","-85.5551","United States","US","USA","Kentucky","","17723","1840014322"
"Thị Trấn Ngải Giao","Thi Tran Ngai Giao","10.6406","107.2478","Vietnam","VN","VNM","Bà Rịa-Vũng Tàu","minor","16522","1704244459"
"Tizi Rached","Tizi Rached","36.6718","4.1918","Algeria","DZ","DZA","Tizi Ouzou","","17161","1012966884"
"Ambolomadinika","Ambolomadinika","-21.9500","47.4333","Madagascar","MG","MDG","Fianarantsoa","","17616","1450992662"
"Spanish Lake","Spanish Lake","38.7884","-90.2077","United States","US","USA","Missouri","","17721","1840006123"
"Loboc","Loboc","9.6333","124.0333","Philippines","PH","PHL","Bohol","","17418","1608516341"
"Sibutao","Sibutao","8.6131","123.4797","Philippines","PH","PHL","Zamboanga del Norte","","17453","1608536412"
"Sānwer","Sanwer","22.9742","75.8271","India","IN","IND","Madhya Pradesh","","17365","1356423371"
"Milanówek","Milanowek","52.1333","20.6833","Poland","PL","POL","Mazowieckie","","16490","1616814692"
"Fraserpet","Fraserpet","12.4587","75.9605","India","IN","IND","Karnātaka","","17405","1356131799"
"Jucurutu","Jucurutu","-6.0339","-37.0200","Brazil","BR","BRA","Rio Grande do Norte","","17692","1076172332"
"Rijen","Rijen","51.5833","4.9500","Netherlands","NL","NLD","Noord-Brabant","minor","16800","1528875142"
"Calceta","Calceta","-0.8400","-80.1700","Ecuador","EC","ECU","Manabí","","17682","1218330963"
"Delran","Delran","40.0170","-74.9495","United States","US","USA","New Jersey","","17708","1840081628"
"Verdun","Verdun","49.1597","5.3828","France","FR","FRA","Grand Est","minor","17161","1250378592"
"Salmon Arm","Salmon Arm","50.7022","-119.2722","Canada","CA","CAN","British Columbia","","17706","1124478865"
"Alcochete","Alcochete","38.7500","-8.9667","Portugal","PT","PRT","Setúbal","minor","17569","1620781537"
"Fanandrana","Fanandrana","-18.1944","49.3792","Madagascar","MG","MDG","Toamasina","","17644","1450388677"
"Palanan","Palanan","17.0589","122.4300","Philippines","PH","PHL","Isabela","","17684","1608029043"
"Asarcık","Asarcik","41.0314","36.2683","Turkey","TR","TUR","Samsun","minor","17628","1792169528"
"Ashton","Ashton","-44.0333","171.7667","New Zealand","NZ","NZL","Canterbury","","17700","1554784264"
"Matipó","Matipo","-20.2839","-42.3408","Brazil","BR","BRA","Minas Gerais","","17639","1076369965"
"Nedugula","Nedugula","11.4764","76.8904","India","IN","IND","Tamil Nādu","","17442","1356702562"
"Rio Maria","Rio Maria","-7.3108","-50.0478","Brazil","BR","BRA","Pará","","17697","1076042056"
"Tlahuelilpan","Tlahuelilpan","20.1297","-99.2286","Mexico","MX","MEX","Hidalgo","","17153","1484009996"
"Dharampuri","Dharampuri","22.1495","75.3444","India","IN","IND","Madhya Pradesh","","17472","1356493779"
"Sokółka","Sokolka","53.4000","23.5000","Poland","PL","POL","Podlaskie","minor","17616","1616965170"
"Benito Juárez","Benito Juarez","20.8833","-98.2000","Mexico","MX","MEX","Veracruz","minor","17618","1484526354"
"Kempele","Kempele","64.9125","25.5083","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","17535","1246739287"
"Fléron","Fleron","50.6167","5.6833","Belgium","BE","BEL","Wallonia","","16495","1056005769"
"Kitahiroshima","Kitahiroshima","34.6746","132.5384","Japan","JP","JPN","Hiroshima","","17669","1392003255"
"East Lampeter","East Lampeter","40.0375","-76.2162","United States","US","USA","Pennsylvania","","17696","1840148810"
"Pionki","Pionki","51.4833","21.4500","Poland","PL","POL","Mazowieckie","","16779","1616722660"
"Dourbali","Dourbali","11.8050","15.8647","Chad","TD","TCD","Chari-Baguirmi","","17682","1148000062"
"Varjota","Varjota","-4.1939","-40.4769","Brazil","BR","BRA","Ceará","","17593","1076392050"
"Preganziol","Preganziol","45.6000","12.2333","Italy","IT","ITA","Veneto","","16957","1380449850"
"Al ‘Ashārah","Al \`Asharah","34.9203","40.5594","Syria","SY","SYR","Dayr az Zawr","minor","17537","1760227736"
"Matagob","Matagob","11.1469","124.4733","Philippines","PH","PHL","Leyte","","17522","1608448233"
"Libertad","Libertad","11.7690","121.9189","Philippines","PH","PHL","Antique","","17507","1608653541"
"Baxt","Baxt","40.7139","68.6933","Uzbekistan","UZ","UZB","Sirdaryo","","17500","1860344342"
"Tufanbeyli","Tufanbeyli","38.2646","36.2224","Turkey","TR","TUR","Adana","minor","17667","1792881095"
"Cerca Carvajal","Cerca Carvajal","19.2667","-71.9500","Haiti","HT","HTI","Centre","","17571","1332505200"
"Latifpur","Latifpur","24.1181","90.1478","Bangladesh","BD","BGD","Dhaka","","16857","1050431969"
"Zhoujia","Zhoujia","35.2976","108.0347","China","CN","CHN","Gansu","","17484","1156060692"
"Villiers","Villiers","-27.0333","28.6000","South Africa","ZA","ZAF","Free State","","17315","1710177732"
"Killingly","Killingly","41.8311","-71.8499","United States","US","USA","Connecticut","","17681","1840045031"
"La Garriga","La Garriga","41.6804","2.2833","Spain","ES","ESP","Catalonia","","16788","1724918427"
"Louth","Louth","53.3669","-0.0061","United Kingdom","GB","GBR","Lincolnshire","","17382","1826872959"
"Pio IX","Pio IX","-6.8378","-40.5789","Brazil","BR","BRA","Piauí","","17671","1076863731"
"Port Alberni","Port Alberni","49.2339","-124.8050","Canada","CA","CAN","British Columbia","","17678","1124952808"
"Verneuil-sur-Seine","Verneuil-sur-Seine","48.9797","1.9739","France","FR","FRA","Île-de-France","","15982","1250119946"
"Chhapra Bahās","Chhapra Bahas","26.7266","84.7912","India","IN","IND","Bihār","","15842","1356641308"
"Guidan Roumdji","Guidan Roumdji","13.6575","6.6958","Niger","NE","NER","Maradi","minor","17525","1562532711"
"Boljoon","Boljoon","9.6333","123.4333","Philippines","PH","PHL","Cebu","","17525","1608991858"
"Arsanjān","Arsanjan","29.9125","53.3086","Iran","IR","IRN","Fārs","minor","17642","1364977336"
"Schijndel","Schijndel","51.6167","5.4333","Netherlands","NL","NLD","Noord-Brabant","minor","17104","1528263982"
"Santos Reyes Nopala","Santos Reyes Nopala","16.1000","-97.1500","Mexico","MX","MEX","Oaxaca","","17583","1484003311"
"Attappampatti","Attappampatti","11.4820","78.0248","India","IN","IND","Tamil Nādu","","16761","1356237822"
"Novo Airão","Novo Airao","-2.6208","-60.9439","Brazil","BR","BRA","Amazonas","","17671","1076092136"
"El Carmen de Chucurí","El Carmen de Chucuri","6.6981","-73.5111","Colombia","CO","COL","Santander","minor","17638","1170751882"
"Restrepo","Restrepo","4.2500","-73.5667","Colombia","CO","COL","Meta","minor","17610","1170439018"
"Totoró","Totoro","2.5117","-76.4003","Colombia","CO","COL","Cauca","minor","17611","1170684203"
"Ribeirão Branco","Ribeirao Branco","-24.2208","-48.7658","Brazil","BR","BRA","São Paulo","","17646","1076454991"
"Seoni Chhapāra","Seoni Chhapara","22.3939","79.5424","India","IN","IND","Madhya Pradesh","","17429","1356837624"
"San Alejo","San Alejo","13.4333","-87.9667","El Salvador","SV","SLV","La Unión","","17598","1222190285"
"Bendorf","Bendorf","50.4297","7.5703","Germany","DE","DEU","Rhineland-Palatinate","","16962","1276205648"
"Pianoro","Pianoro","44.3833","11.3333","Italy","IT","ITA","Emilia-Romagna","","17503","1380089762"
"Aït Majdane","Ait Majdane","31.8514","-6.9658","Morocco","MA","MAR","Béni Mellal-Khénifra","","17572","1504617199"
"Webster","Webster","42.0521","-71.8485","United States","US","USA","Massachusetts","","17664","1840053610"
"Nerang","Nerang","-27.9956","153.3220","Australia","AU","AUS","Queensland","","16864","1036082899"
"Baquerizo Moreno","Baquerizo Moreno","-1.9167","-79.5167","Ecuador","EC","ECU","Guayas","","17512","1218304584"
"Dighaun","Dighaun","25.5549","86.8097","India","IN","IND","Bihār","","16467","1356130466"
"Yayas de Viajama","Yayas de Viajama","18.6000","-70.9200","Dominican Republic","DO","DOM","Valdesia","","17620","1214963330"
"Dugo Selo","Dugo Selo","45.8058","16.2331","Croatia","HR","HRV","Zagrebačka Županija","minor","17466","1191082189"
"Pernamitta","Pernamitta","15.5333","80.0000","India","IN","IND","Andhra Pradesh","","17041","1356574055"
"Strzelce Opolskie","Strzelce Opolskie","50.5000","18.2833","Poland","PL","POL","Opolskie","minor","17512","1616448367"
"Polyarnyy","Polyarnyy","69.2000","33.4667","Russia","RU","RUS","Murmanskaya Oblast’","","17605","1643432811"
"Abejorral","Abejorral","5.7894","-75.4281","Colombia","CO","COL","Antioquia","minor","17599","1170508965"
"San Rafael Cedros","San Rafael Cedros","13.7333","-88.8833","El Salvador","SV","SLV","Cuscatlán","","17069","1222366819"
"Agourai","Agourai","33.6333","-5.5833","Morocco","MA","MAR","Fès-Meknès","","16651","1504734608"
"San Jorge","San Jorge","11.9833","124.8167","Philippines","PH","PHL","Samar","","17579","1608420594"
"Arauco","Arauco","-37.2500","-73.3167","Chile","CL","CHL","Biobío","","17597","1152468620"
"Dabas","Dabas","47.1890","19.3126","Hungary","HU","HUN","Pest","minor","17546","1348671815"
"Estelle","Estelle","29.8447","-90.1021","United States","US","USA","Louisiana","","17650","1840013111"
"Rāni Shakarpura","Rani Shakarpura","25.5534","86.2992","India","IN","IND","Bihār","","16007","1356333671"
"Lam Luk Ka","Lam Luk Ka","13.9297","100.7375","Thailand","TH","THA","Pathum Thani","minor","15881","1764490226"
"Kalanaur","Kalanaur","32.0200","75.1500","India","IN","IND","Punjab","","17068","1356773306"
"Manilva","Manilva","36.3833","-5.2500","Spain","ES","ESP","Andalusia","","17157","1724772448"
"Tricase","Tricase","39.9333","18.3667","Italy","IT","ITA","Puglia","","17525","1380669582"
"Bailén","Bailen","38.0833","-3.7667","Spain","ES","ESP","Andalusia","","17498","1724854020"
"Inza","Inza","53.8500","46.3500","Russia","RU","RUS","Ul’yanovskaya Oblast’","","17630","1643770166"
"San Bernardino","San Bernardino","14.5333","-91.4500","Guatemala","GT","GTM","Suchitepéquez","minor","17110","1320929953"
"Totutla","Totutla","19.2167","-96.9667","Mexico","MX","MEX","Veracruz","minor","17428","1484089486"
"Southbridge","Southbridge","42.0604","-72.0338","United States","US","USA","Massachusetts","","17643","1840132611"
"Mawkanin","Mawkanin","15.5106","97.8533","Myanmar","MM","MMR","Mon State","","17552","1104866506"
"Sìnnai","Sinnai","39.3026","9.2031","Italy","IT","ITA","Sardegna","","17562","1380071240"
"Aïn Zaouïa","Ain Zaouia","36.5483","3.8942","Algeria","DZ","DZA","Tizi Ouzou","","17320","1012695288"
"Same","Same","-4.0667","37.7833","Tanzania","TZ","TZA","Kilimanjaro","","17455","1834888088"
"Chedaopo","Chedaopo","36.4008","106.7351","China","CN","CHN","Gansu","","17608","1156042297"
"Tougan","Tougan","13.0667","-3.0667","Burkina Faso","BF","BFA","Boucle du Mouhoun","minor","17590","1854407539"
"Noniyā","Noniya","26.6763","84.6163","India","IN","IND","Bihār","","16298","1356020439"
"Saint-Cyr-sur-Loire","Saint-Cyr-sur-Loire","47.4028","0.6781","France","FR","FRA","Centre-Val de Loire","","16419","1250581615"
"Rocca di Papa","Rocca di Papa","41.7667","12.7167","Italy","IT","ITA","Lazio","","17201","1380121501"
"Jomboy Shahri","Jomboy Shahri","39.6989","67.0933","Uzbekistan","UZ","UZB","Samarqand","minor","17400","1860586927"
"Vallières","Vallieres","19.4333","-71.9167","Haiti","HT","HTI","Nord-Est","","17470","1332923713"
"As Suqaylibīyah","As Suqaylibiyah","35.3697","36.3800","Syria","SY","SYR","Ḩamāh","minor","17313","1760164203"
"Para","Para","5.5167","-7.3333","Côte d'Ivoire","CI","CIV","Bas-Sassandra","","17594","1384364677"
"Barrhead","Barrhead","55.8010","-4.3890","United Kingdom","GB","GBR","East Renfrewshire","","17610","1826311398"
"Magalhães de Almeida","Magalhaes de Almeida","-3.3958","-42.2039","Brazil","BR","BRA","Maranhão","","17587","1076952345"
"Balma","Balma","43.6103","1.4986","France","FR","FRA","Occitanie","","16625","1250736136"
"Bethpage","Bethpage","40.7495","-73.4856","United States","US","USA","New York","","17627","1840005233"
"Bad Münder am Deister","Bad Munder am Deister","52.1992","9.4653","Germany","DE","DEU","Lower Saxony","","17465","1276351668"
"La Primavera","La Primavera","5.4906","-70.4092","Colombia","CO","COL","Vichada","minor","17626","1170159193"
"Awlouz","Awlouz","30.7000","-8.1500","Morocco","MA","MAR","Souss-Massa","","17409","1504521135"
"Wayne","Wayne","42.2774","-83.3877","United States","US","USA","Michigan","","17624","1840001846"
"Hinode","Hinode","35.7421","139.2574","Japan","JP","JPN","Tōkyō","","17016","1392999983"
"New Philadelphia","New Philadelphia","40.4860","-81.4401","United States","US","USA","Ohio","","17622","1840001281"
"Colbún","Colbun","-35.7000","-71.4167","Chile","CL","CHL","Maule","","17619","1152103340"
"Şavşat","Savsat","41.2433","42.3639","Turkey","TR","TUR","Artvin","minor","17606","1792545473"
"Belém","Belem","-6.7469","-35.5189","Brazil","BR","BRA","Paraíba","","17594","1076976694"
"Gokavaram","Gokavaram","17.2667","81.8500","India","IN","IND","Andhra Pradesh","","16389","1356790353"
"Filadélfia","Filadelfia","-10.7408","-40.1328","Brazil","BR","BRA","Bahia","","17583","1076975791"
"Poção de Pedras","Pocao de Pedras","-4.7500","-44.9333","Brazil","BR","BRA","Maranhão","","17595","1076851693"
"Gudipallipādu","Gudipallipadu","14.4588","80.0482","India","IN","IND","Andhra Pradesh","","17076","1356150052"
"Brignoles","Brignoles","43.4058","6.0617","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","17364","1250233071"
"Yondó","Yondo","7.0000","-73.9167","Colombia","CO","COL","Antioquia","minor","17597","1170716454"
"Kamudi","Kamudi","9.4090","78.3679","India","IN","IND","Tamil Nādu","","17340","1356056335"
"Humacao","Humacao","18.1520","-65.8204","Puerto Rico","PR","PRI","Puerto Rico","","17607","1630035683"
"Kembhāvi","Kembhavi","16.6500","76.5333","India","IN","IND","Karnātaka","","17346","1356653155"
"Pisticci","Pisticci","40.3833","16.5500","Italy","IT","ITA","Basilicata","","17602","1380400778"
"Zaysan","Zaysan","47.4667","84.8667","Kazakhstan","KZ","KAZ","","minor","17600","1398700526"
"Bakouma","Bakouma","5.6986","22.7833","Central African Republic","CF","CAF","Mbomou","","17599","1140312993"
"Kirksville","Kirksville","40.1986","-92.5752","United States","US","USA","Missouri","","17600","1840008372"
"Cubellas","Cubellas","41.2100","1.6736","Spain","ES","ESP","Catalonia","","16386","1724725097"
"Marche-en-Famenne","Marche-en-Famenne","50.2167","5.3333","Belgium","BE","BEL","Wallonia","minor","17455","1056302403"
"Pataskala","Pataskala","40.0110","-82.7155","United States","US","USA","Ohio","","17598","1840001420"
"Afrânio","Afranio","-8.5000","-41.0000","Brazil","BR","BRA","Pernambuco","","17586","1076000646"
"Pont-à-Celles","Pont-a-Celles","50.5000","4.3500","Belgium","BE","BEL","Wallonia","","17287","1056160831"
"San Giovanni Valdarno","San Giovanni Valdarno","43.5644","11.5328","Italy","IT","ITA","Tuscany","","16812","1380269807"
"Mansingha","Mansingha","26.7807","84.8367","India","IN","IND","Bihār","","15759","1356678788"
"Wendlingen am Neckar","Wendlingen am Neckar","48.6747","9.3817","Germany","DE","DEU","Baden-Württemberg","","16268","1276408904"
"Ivanovka","Ivanovka","42.8864","75.0822","Kyrgyzstan","KG","KGZ","Chüy","minor","17513","1417486254"
"Vimodrone","Vimodrone","45.5139","9.2844","Italy","IT","ITA","Lombardy","","17016","1380371315"
"Lubang","Lubang","13.8586","120.1228","Philippines","PH","PHL","Occidental Mindoro","","17437","1608630434"
"Phirangipuram","Phirangipuram","16.3000","80.2667","India","IN","IND","Andhra Pradesh","","16365","1356134569"
"St. Matthews","St. Matthews","38.2497","-85.6384","United States","US","USA","Kentucky","","17588","1840015194"
"Tenango de Doria","Tenango de Doria","20.3356","-98.2267","Mexico","MX","MEX","Hidalgo","minor","17503","1484595420"
"Sulaco","Sulaco","14.9167","-87.2667","Honduras","HN","HND","Yoro","","17509","1340801499"
"Toba","Toba","34.4813","136.8434","Japan","JP","JPN","Mie","","17421","1392068005"
"Compostela","Compostela","21.2389","-104.9000","Mexico","MX","MEX","Nayarit","minor","17573","1484966833"
"Rubano","Rubano","45.4333","11.7833","Italy","IT","ITA","Veneto","","16448","1380183022"
"Bad Langensalza","Bad Langensalza","51.1081","10.6467","Germany","DE","DEU","Thuringia","","17441","1276854476"
"Freienbach","Freienbach","47.2000","8.7500","Switzerland","CH","CHE","Schwyz","","16391","1756905696"
"Sedeh Lanjān","Sedeh Lanjan","32.3781","51.3181","Iran","IR","IRN","Eşfahān","","17335","1364171855"
"Sagrada Familia","Sagrada Familia","-35.0000","-71.3833","Chile","CL","CHL","Maule","","17569","1152799480"
"Cortland","Cortland","42.6004","-76.1784","United States","US","USA","New York","","17577","1840000419"
"Ban Song","Ban Song","8.6603","99.3768","Thailand","TH","THA","Surat Thani","","17373","1764610829"
"Nova Era","Nova Era","-19.7606","-43.0289","Brazil","BR","BRA","Minas Gerais","","17528","1076826150"
"Kasimkota","Kasimkota","17.6736","82.9634","India","IN","IND","Andhra Pradesh","","15753","1356731748"
"Mudukulattūr","Mudukulattur","9.3440","78.5138","India","IN","IND","Tamil Nādu","","17326","1356304316"
"Kawa","Kawa","17.0897","96.4663","Myanmar","MM","MMR","Bago","","17396","1104865586"
"Sahel","Sahel","34.9667","-4.5500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","17496","1504781617"
"Neerpelt","Neerpelt","51.2333","5.4333","Belgium","BE","BEL","Flanders","","17174","1056445796"
"Passagem Franca","Passagem Franca","-6.1800","-43.7839","Brazil","BR","BRA","Maranhão","","17562","1076066063"
"Tabernes de Valldigna","Tabernes de Valldigna","39.0722","-0.2658","Spain","ES","ESP","Valencia","","17222","1724331053"
"Regeneração","Regeneracao","-6.2378","-42.6878","Brazil","BR","BRA","Piauí","","17556","1076132232"
"Havelock","Havelock","34.9078","-76.8987","United States","US","USA","North Carolina","","17570","1840014592"
"Jenison","Jenison","42.9063","-85.8269","United States","US","USA","Michigan","","17570","1840004344"
"Ebino","Ebino","32.0333","130.8167","Japan","JP","JPN","Miyazaki","","17508","1392003466"
"Perupālem","Perupalem","16.3705","81.5813","India","IN","IND","Andhra Pradesh","","16948","1356440920"
"Thale","Thale","51.7500","11.0500","Germany","DE","DEU","Saxony-Anhalt","","17442","1276578169"
"Raunheim","Raunheim","50.0167","8.4500","Germany","DE","DEU","Hesse","","16284","1276238709"
"Marktredwitz","Marktredwitz","50.0000","12.0667","Germany","DE","DEU","Bavaria","","17217","1276442185"
"Shin-Kamigotō","Shin-Kamigoto","32.9844","129.0733","Japan","JP","JPN","Nagasaki","","17479","1392003402"
"Coalinga","Coalinga","36.1420","-120.3242","United States","US","USA","California","","17560","1840018945"
"Derhachi","Derhachi","50.1114","36.1219","Ukraine","UA","UKR","Kharkivska Oblast","","17433","1804803854"
"Rojales","Rojales","38.0886","-0.7236","Spain","ES","ESP","Valencia","","16943","1724468567"
"Sapucaia","Sapucaia","-21.9950","-42.9139","Brazil","BR","BRA","Rio de Janeiro","","17525","1076318561"
"Fanipal","Fanipal","53.7500","27.3333","Belarus","BY","BLR","Minskaya Voblasts’","","17507","1112544829"
"Paragaticherla","Paragaticherla","16.3324","80.3528","India","IN","IND","Andhra Pradesh","","16563","1356648968"
"Rio Bananal","Rio Bananal","-19.2650","-40.3328","Brazil","BR","BRA","Espírito Santo","","17530","1076670065"
"Miharu","Miharu","37.4406","140.4932","Japan","JP","JPN","Fukushima","","17319","1392003415"
"Tasquillo","Tasquillo","20.6167","-99.2500","Mexico","MX","MEX","Hidalgo","","17451","1484659098"
"Zandvoort","Zandvoort","52.3667","4.5333","Netherlands","NL","NLD","Noord-Holland","minor","17168","1528150245"
"Ketama","Ketama","34.9158","-4.5686","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","17351","1504947642"
"Chorhat","Chorhat","24.4274","81.6695","India","IN","IND","Madhya Pradesh","","17297","1356101081"
"Morarano-Gara","Morarano-Gara","-18.7100","48.2700","Madagascar","MG","MDG","Toamasina","","17545","1450740976"
"Tagana-an","Tagana-an","9.6964","125.5825","Philippines","PH","PHL","Surigao del Norte","","17323","1608157822"
"Colchester","Colchester","44.5545","-73.2168","United States","US","USA","Vermont","","17547","1840070411"
"Bílina","Bilina","50.5481","13.7761","Czechia","CZ","CZE","Ústecký Kraj","","17103","1203669701"
"Laguna Woods","Laguna Woods","33.6099","-117.7299","United States","US","USA","California","","17546","1840020590"
"Höganäs","Hoganas","56.2000","12.5667","Sweden","SE","SWE","Skåne","minor","16001","1752137413"
"San Juan Nonualco","San Juan Nonualco","13.5072","-88.8997","El Salvador","SV","SLV","La Paz","","17256","1222379264"
"Castelfiorentino","Castelfiorentino","43.6000","10.9667","Italy","IT","ITA","Tuscany","","17283","1380361720"
"Dighirpār","Dighirpar","22.3034","88.6678","India","IN","IND","West Bengal","","15881","1356585729"
"Yeşilhisar","Yesilhisar","38.3500","35.0867","Turkey","TR","TUR","Kayseri","minor","17525","1792717556"
"Guanagazapa","Guanagazapa","14.2333","-90.6500","Guatemala","GT","GTM","Escuintla","minor","17458","1320760116"
"Cherān","Cheran","25.3610","85.5421","India","IN","IND","Bihār","","16241","1356896619"
"Miramichi","Miramichi","47.0196","-65.5072","Canada","CA","CAN","New Brunswick","","17537.00","1124714190"
"Wittenberge","Wittenberge","53.0000","11.7500","Germany","DE","DEU","Brandenburg","","17206","1276040852"
"Băileşti","Bailesti","44.0308","23.3525","Romania","RO","ROU","Dolj","","17437","1642673128"
"Lemont","Lemont","41.6695","-87.9838","United States","US","USA","Illinois","","17535","1840011258"
"Chadchan","Chadchan","17.1700","75.9600","India","IN","IND","Karnātaka","","17313","1356381266"
"Puerto Rico","Puerto Rico","-26.8000","-55.0167","Argentina","AR","ARG","Misiones","minor","17491","1032211947"
"Ban Tha Mai I","Ban Tha Mai I","18.7461","98.9819","Thailand","TH","THA","Chiang Mai","","16859","1764002973"
"Mārtahalli","Martahalli","11.9919","77.4972","India","IN","IND","Karnātaka","","17400","1356168320"
"Damme","Damme","52.5208","8.1986","Germany","DE","DEU","Lower Saxony","","17366","1276165336"
"Adelfia","Adelfia","41.0000","16.8667","Italy","IT","ITA","Puglia","","16963","1380602837"
"Kombai","Kombai","9.8475","77.2960","India","IN","IND","Tamil Nādu","","16951","1356097455"
"Plast","Plast","54.3833","60.8167","Russia","RU","RUS","Chelyabinskaya Oblast’","","17508","1643651635"
"Scott","Scott","40.3875","-80.0791","United States","US","USA","Pennsylvania","","17527","1840141807"
"Thornton","Thornton","53.7898","-1.8504","United Kingdom","GB","GBR","Bradford","","17276","1826639956"
"Karukh","Karukh","34.4922","62.5922","Afghanistan","AF","AFG","Herāt","minor","17484","1004546127"
"Kishundāspur","Kishundaspur","25.3334","87.3318","India","IN","IND","Bihār","","15874","1356241529"
"Windlesham","Windlesham","51.3600","-0.6600","United Kingdom","GB","GBR","Surrey","","16775","1826961751"
"Dharmāpuri","Dharmapuri","18.9475","79.0940","India","IN","IND","Andhra Pradesh","","17243","1356922969"
"Dar Chaifat","Dar Chaifat","32.5500","-7.5000","Morocco","MA","MAR","Casablanca-Settat","","17454","1504290821"
"Goodlettsville","Goodlettsville","36.3330","-86.7026","United States","US","USA","Tennessee","","17522","1840013345"
"Pudu","Pudu","12.8667","74.9673","India","IN","IND","Karnātaka","","16406","1356920085"
"Cafelândia","Cafelandia","-21.8025","-49.6100","Brazil","BR","BRA","São Paulo","","17499","1076575289"
"Agaram","Agaram","10.4433","77.9485","India","IN","IND","Tamil Nādu","","16874","1356245534"
"Challapalle","Challapalle","16.1175","80.9314","India","IN","IND","Andhra Pradesh","","17067","1356541236"
"Stowbtsy","Stowbtsy","53.4833","26.7333","Belarus","BY","BLR","Minskaya Voblasts’","","17496","1112415577"
"Kushk","Kushk","33.2956","61.9522","Afghanistan","AF","AFG","Herāt","","17479","1004679765"
"Plainville","Plainville","41.6741","-72.8575","United States","US","USA","Connecticut","","17514","1840035535"
"Hatfield","Hatfield","53.5800","-1.0000","United Kingdom","GB","GBR","Doncaster","","17236","1826723545"
"Puerto Guzmán","Puerto Guzman","0.9667","-76.5833","Colombia","CO","COL","Putumayo","","17495","1170540013"
"Conceição do Mato Dentro","Conceicao do Mato Dentro","-19.0369","-43.4250","Brazil","BR","BRA","Minas Gerais","","17503","1076112656"
"Niagara-on-the-Lake","Niagara-on-the-Lake","43.2553","-79.0717","Canada","CA","CAN","Ontario","","17511","1124366228"
"Soumagne","Soumagne","50.6167","5.7500","Belgium","BE","BEL","Wallonia","","16888","1056664546"
"Radzionków Nowy","Radzionkow Nowy","50.3833","18.8833","Poland","PL","POL","Śląskie","","16253","1616721944"
"Lalībela","Lalibela","12.0317","39.0411","Ethiopia","ET","ETH","Āmara","","17367","1231020267"
"Krasnokumskoye","Krasnokumskoye","44.1778","43.5000","Russia","RU","RUS","Stavropol’skiy Kray","","17451","1643492685"
"Berga","Berga","42.1000","1.8456","Spain","ES","ESP","Catalonia","","16762","1724869982"
"Ziracuaretiro","Ziracuaretiro","19.4189","-101.9088","Mexico","MX","MEX","Michoacán","","17394","1484827641"
"Luz","Luz","-19.8008","-45.6858","Brazil","BR","BRA","Minas Gerais","","17486","1076520575"
"Kolokondé","Kolokonde","9.9000","1.7667","Benin","BJ","BEN","Donga","","17419","1204155469"
"Ambatolahy","Ambatolahy","-20.0000","45.5333","Madagascar","MG","MDG","Toliara","","17482","1450548076"
"Cheraro","Cheraro","14.3958","37.7731","Ethiopia","ET","ETH","Tigray","","17045","1231449470"
"Woodcrest","Woodcrest","33.8789","-117.3687","United States","US","USA","California","","17492","1840019296"
"Forssa","Forssa","60.8167","23.6250","Finland","FI","FIN","Kanta-Häme","minor","17422","1246156595"
"Gunjāpalle","Gunjapalle","14.3845","77.9495","India","IN","IND","Andhra Pradesh","","17376","1356600224"
"Kottapeta","Kottapeta","15.7913","80.3770","India","IN","IND","Andhra Pradesh","","16931","1356040690"
"Karachev","Karachev","53.1167","34.9667","Russia","RU","RUS","Bryanskaya Oblast’","","17466","1643019084"
"Santa Magdalena","Santa Magdalena","12.6489","124.1083","Philippines","PH","PHL","Sorsogon","","17096","1608724436"
"Samaná","Samana","5.5833","-74.9167","Colombia","CO","COL","Caldas","minor","17466","1170332815"
"Sake","Sake","-1.5741","29.0413","Congo (Kinshasa)","CD","COD","Nord-Kivu","","17151","1180647480"
"Carmo","Carmo","-21.9339","-42.6089","Brazil","BR","BRA","Rio de Janeiro","","17434","1076161057"
"Bahutāl","Bahutal","24.5600","87.8900","India","IN","IND","West Bengal","","15963","1356754233"
"Puerto Caimito","Puerto Caimito","8.8700","-79.7200","Panama","PA","PAN","Panamá Oeste","minor","16951","1591589350"
"Kadogawa","Kadogawa","32.4712","131.6461","Japan","JP","JPN","Miyazaki","","17341","1392290615"
"Sant’Elpidio a Mare","Sant'Elpidio a Mare","43.2295","13.6861","Italy","IT","ITA","Marche","","17144","1380442467"
"Tirukkāttuppalli","Tirukkattuppalli","10.8481","78.9526","India","IN","IND","Tamil Nādu","","16588","1356846103"
"Suvorov","Suvorov","54.1500","36.5500","Russia","RU","RUS","Tul’skaya Oblast’","","17450","1643843521"
"Lara","Lara","-38.0167","144.4167","Australia","AU","AUS","Victoria","","16355","1036754750"
"Concorezzo","Concorezzo","45.5897","9.3359","Italy","IT","ITA","Lombardy","","15644","1380380632"
"Pavullo nel Frignano","Pavullo nel Frignano","44.3327","10.8331","Italy","IT","ITA","Emilia-Romagna","","17361","1380076570"
"Aurāhi","Aurahi","26.1895","87.3372","India","IN","IND","Bihār","","16088","1356152123"
"Nāranattenvanpatti","Naranattenvanpatti","9.7233","77.3099","India","IN","IND","Tamil Nādu","","16901","1356244201"
"Sulzbach","Sulzbach","49.2833","7.0667","Germany","DE","DEU","Saarland","","16468","1276579983"
"São Raimundo das Mangabeiras","Sao Raimundo das Mangabeiras","-7.0219","-45.4808","Brazil","BR","BRA","Maranhão","","17474","1076826320"
"Burgdorf","Burgdorf","47.0500","7.6167","Switzerland","CH","CHE","Bern","","16420","1756999920"
"Penugonda","Penugonda","16.6569","81.7461","India","IN","IND","Andhra Pradesh","","16038","1356098929"
"San Isidro","San Isidro","12.9283","-86.1922","Nicaragua","NI","NIC","Matagalpa","minor","17412","1558059313"
"Osuna","Osuna","37.2333","-5.1000","Spain","ES","ESP","Andalusia","","17442","1724498340"
"Correntes","Correntes","-9.1289","-36.3300","Brazil","BR","BRA","Pernambuco","","17419","1076224678"
"Guaranda","Guaranda","8.4672","-74.5367","Colombia","CO","COL","Sucre","minor","17422","1170473707"
"Epitacio Huerta","Epitacio Huerta","20.1348","-100.2934","Mexico","MX","MEX","Michoacán","","16622","1484920890"
"Camilo Ponce Enríquez","Camilo Ponce Enriquez","-3.0500","-79.7333","Ecuador","EC","ECU","Azuay","","17404","1218840796"
"Skadovsk","Skadovsk","46.1167","32.9167","Ukraine","UA","UKR","Khersonska Oblast","minor","17344","1804206912"
"Lake Butler","Lake Butler","28.4862","-81.5460","United States","US","USA","Florida","","17465","1840143771"
"New Castle","New Castle","39.9191","-85.3697","United States","US","USA","Indiana","","17465","1840008396"
"Sigmaringen","Sigmaringen","48.0867","9.2164","Germany","DE","DEU","Baden-Württemberg","minor","17278","1276579491"
"Malhada","Malhada","-14.3358","-43.7739","Brazil","BR","BRA","Bahia","","17455","1076450063"
"Mỹ Lương","My Luong","20.8667","105.6667","Vietnam","VN","VNM","Hà Nội","","15540","1704740855"
"Saint-Lin--Laurentides","Saint-Lin--Laurentides","45.8500","-73.7667","Canada","CA","CAN","Quebec","","17463","1124906585"
"Mori","Mori","34.8356","137.9271","Japan","JP","JPN","Shizuoka","","17333","1392757253"
"Calafat","Calafat","43.9858","22.9575","Romania","RO","ROU","Dolj","","17336","1642467713"
"Nidda","Nidda","50.4128","9.0092","Germany","DE","DEU","Hesse","","17314","1276604046"
"Chambellan","Chambellan","18.5667","-74.3167","Haiti","HT","HTI","Grand’Anse","","16883","1332814208"
"Alcantara","Alcantara","12.2584","122.0543","Philippines","PH","PHL","Romblon","","17171","1608033218"
"Bijni","Bijni","26.4959","90.7030","India","IN","IND","Assam","","16589","1356148310"
"Anosiarivo","Anosiarivo","-19.9167","47.0333","Madagascar","MG","MDG","Antananarivo","","17423","1450015265"
"Centerton","Centerton","36.3566","-94.2971","United States","US","USA","Arkansas","","17455","1840014441"
"Zarumilla","Zarumilla","-3.5014","-80.2722","Peru","PE","PER","Tumbes","","16971","1604793858"
"Mareth","Mareth","33.6333","10.3000","Tunisia","TN","TUN","Gabès","","17385","1788204287"
"Bolhrad","Bolhrad","45.6855","28.6134","Ukraine","UA","UKR","Odeska Oblast","minor","17400","1804273550"
"Montemor-o-Novo","Montemor-o-Novo","38.6500","-8.2167","Portugal","PT","PRT","Évora","minor","17437","1620010573"
"Galaz","Galaz","34.5500","-4.8000","Morocco","MA","MAR","Fès-Meknès","","17333","1504676326"
"Mahālandi","Mahalandi","24.0738","88.1214","India","IN","IND","West Bengal","","16354","1356022399"
"Ottakkadai","Ottakkadai","9.9580","78.1886","India","IN","IND","Tamil Nādu","","16084","1356233663"
"Mwingi","Mwingi","-0.9333","38.0500","Kenya","KE","KEN","Kitui","","15970","1404312666"
"Saguday","Saguday","16.5394","121.5636","Philippines","PH","PHL","Quirino","","17137","1608148393"
"Calayan","Calayan","19.2619","121.4753","Philippines","PH","PHL","Cagayan","","17410","1608516921"
"Conchas","Conchas","-23.0134","-48.0078","Brazil","BR","BRA","São Paulo","","17406","1076967858"
"Mujikharf","Mujikharf","38.8500","69.8833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","17366","1762947574"
"Puerto Aysén","Puerto Aysen","-45.4000","-72.6833","Chile","CL","CHL","Aysén","minor","17441","1152158570"
"Kafr Zaytā","Kafr Zayta","35.3736","36.6017","Syria","SY","SYR","Ḩamāh","minor","17052","1760801679"
"Cássia","Cassia","-20.5828","-46.9219","Brazil","BR","BRA","Minas Gerais","","17412","1076905078"
"Mehrān","Mehran","33.1222","46.1647","Iran","IR","IRN","Īlām","minor","17435","1364360817"
"Anacortes","Anacortes","48.4878","-122.6292","United States","US","USA","Washington","","17437","1840018366"
"Srīkūrmam","Srikurmam","18.1600","84.1000","India","IN","IND","Andhra Pradesh","","16973","1356473941"
"Nadugadda","Nadugadda","16.3539","79.4380","India","IN","IND","Andhra Pradesh","","17238","1356186749"
"Veinticinco de Mayo","Veinticinco de Mayo","-37.7712","-67.7174","Argentina","AR","ARG","La Pampa","minor","17430","1032912712"
"Brookfield","Brookfield","41.4674","-73.3923","United States","US","USA","Connecticut","","17428","1840034171"
"Arachchalūr","Arachchalur","11.1627","77.7007","India","IN","IND","Tamil Nādu","","16292","1356165095"
"Cadoneghe","Cadoneghe","45.4500","11.9333","Italy","IT","ITA","Veneto","","16176","1380080342"
"Gokarn","Gokarn","14.5500","74.3167","India","IN","IND","Karnātaka","","17100","1356619240"
"Tapiramutá","Tapiramuta","-11.8469","-40.7908","Brazil","BR","BRA","Bahia","","17398","1076220458"
"Dijiasuoxiang","Dijiasuoxiang","35.6883","105.2586","China","CN","CHN","Gansu","","17320","1156379414"
"Gachancipá","Gachancipa","4.9908","-73.8731","Colombia","CO","COL","Cundinamarca","minor","17026","1170853649"
"Hope Mills","Hope Mills","34.9710","-78.9597","United States","US","USA","North Carolina","","17421","1840016438"
"Gescher","Gescher","51.9569","7.0056","Germany","DE","DEU","North Rhine-Westphalia","","17205","1276129657"
"Bedford","Bedford","41.2250","-73.6673","United States","US","USA","New York","","17417","1840057226"
"Raychikhinsk","Raychikhinsk","49.7833","129.4167","Russia","RU","RUS","Amurskaya Oblast’","","17372","1643638607"
"Hidrolândia","Hidrolandia","-16.9619","-49.2289","Brazil","BR","BRA","Goiás","","17398","1076638231"
"Suesca","Suesca","5.1000","-73.8000","Colombia","CO","COL","Cundinamarca","minor","17318","1170580731"
"Mannō","Manno","34.1923","133.8414","Japan","JP","JPN","Kagawa","","17324","1392003200"
"Norcross","Norcross","33.9379","-84.2065","United States","US","USA","Georgia","","17413","1840014744"
"Bad Münstereifel","Bad Munstereifel","50.5531","6.7661","Germany","DE","DEU","North Rhine-Westphalia","","17299","1276172917"
"Saint-Égrève","Saint-Egreve","45.2317","5.6831","France","FR","FRA","Auvergne-Rhône-Alpes","","15944","1250398609"
"Gennep","Gennep","51.7000","5.9667","Netherlands","NL","NLD","Limburg","minor","17071","1528960180"
"La Algaba","La Algaba","37.4500","-6.0000","Spain","ES","ESP","Andalusia","","16491","1724135955"
"Pirapemas","Pirapemas","-3.7269","-44.2228","Brazil","BR","BRA","Maranhão","","17381","1076407329"
"Arani","Arani","13.3346","80.0848","India","IN","IND","Tamil Nādu","","16650","1356083141"
"Çat","Cat","39.6111","40.9789","Turkey","TR","TUR","Erzurum","minor","17389","1792728659"
"Nümbrecht","Numbrecht","50.9053","7.5422","Germany","DE","DEU","North Rhine-Westphalia","","17165","1276389854"
"Fate","Fate","32.9430","-96.3858","United States","US","USA","Texas","","17403","1840020715"
"Nizhnyaya Salda","Nizhnyaya Salda","58.0667","60.7167","Russia","RU","RUS","Sverdlovskaya Oblast’","","17373","1643295393"
"Khunti Dhanaili","Khunti Dhanaili","25.9376","87.3790","India","IN","IND","Bihār","","16304","1356144139"
"Bakeshiyingcun","Bakeshiyingcun","40.7237","117.1930","China","CN","CHN","Hebei","","17216","1156576365"
"Ammon","Ammon","43.4745","-111.9568","United States","US","USA","Idaho","","17401","1840018643"
"Ibirama","Ibirama","-27.0569","-49.5178","Brazil","BR","BRA","Santa Catarina","","17330","1076103738"
"Baie de Henne","Baie de Henne","19.6667","-73.2000","Haiti","HT","HTI","Nord-Ouest","","17277","1332831627"
"Soavina","Soavina","-18.9500","47.4833","Madagascar","MG","MDG","Antananarivo","","15658","1450506005"
"Beaumont","Beaumont","53.3572","-113.4147","Canada","CA","CAN","Alberta","","17396","1124001210"
"Péruwelz","Peruwelz","50.5167","3.5833","Belgium","BE","BEL","Wallonia","","17113","1056806547"
"Rattaphum","Rattaphum","7.1412","100.2905","Thailand","TH","THA","Songkhla","minor","17257","1764151351"
"Alcantara","Alcantara","9.9715","123.4047","Philippines","PH","PHL","Cebu","minor","16910","1608085067"
"Emmiganūru","Emmiganuru","15.1500","76.9200","India","IN","IND","Karnātaka","","16790","1356701425"
"Yeni Suraxanı","Yeni Suraxani","40.4311","50.0361","Azerbaijan","AZ","AZE","Bakı","","16127","1031627896"
"Morganton","Morganton","35.7408","-81.7002","United States","US","USA","North Carolina","","17389","1840014504"
"Staphorst","Staphorst","52.6500","6.2167","Netherlands","NL","NLD","Overijssel","minor","17261","1528939311"
"Arpaçay","Arpacay","40.8483","43.3317","Turkey","TR","TUR","Kars","minor","17373","1792082484"
"Kouka","Kouka","11.9000","-4.3333","Burkina Faso","BF","BFA","Boucle du Mouhoun","","17286","1854523229"
"Betulia","Betulia","6.1122","-75.9839","Colombia","CO","COL","Antioquia","minor","17317","1170946542"
"Palhālan","Palhalan","34.1823","74.5506","India","IN","IND","Jammu and Kashmīr","","15540","1356145709"
"Brenham","Brenham","30.1584","-96.3966","United States","US","USA","Texas","","17385","1840019604"
"Künzell","Kunzell","50.5500","9.7167","Germany","DE","DEU","Hesse","","16829","1276700947"
"Dazhangzi","Dazhangzi","40.6239","118.1081","China","CN","CHN","Hebei","","16896","1156601767"
"Saint-Pierre-des-Corps","Saint-Pierre-des-Corps","47.3908","0.7281","France","FR","FRA","Centre-Val de Loire","","15967","1250354597"
"Oak Grove","Oak Grove","45.4156","-122.6349","United States","US","USA","Oregon","","17382","1840018568"
"Eutin","Eutin","54.1378","10.6181","Germany","DE","DEU","Schleswig-Holstein","minor","16971","1276288365"
"Santo Antônio do Amparo","Santo Antonio do Amparo","-20.9458","-44.9189","Brazil","BR","BRA","Minas Gerais","","17345","1076950155"
"Stockelsdorf","Stockelsdorf","53.8833","10.6500","Germany","DE","DEU","Schleswig-Holstein","","17079","1276893181"
"Fairview","Fairview","40.1735","-76.8655","United States","US","USA","Pennsylvania","","17378","1840150913"
"Berezhany","Berezhany","49.4464","24.9436","Ukraine","UA","UKR","Ternopilska Oblast","minor","17316","1804101117"
"Heilbad Heiligenstadt","Heilbad Heiligenstadt","51.3789","10.1386","Germany","DE","DEU","Thuringia","minor","17105","1276037701"
"Kamenz","Kamenz","51.2667","14.1000","Germany","DE","DEU","Saxony","","17056","1276558710"
"Figline Valdarno","Figline Valdarno","43.6167","11.4667","Italy","IT","ITA","Tuscany","","17136","1380910062"
"Milla’ab","Milla'ab","31.4737","-4.7424","Morocco","MA","MAR","Drâa-Tafilalet","","17360","1504537795"
"Laurel","Laurel","31.6956","-89.1449","United States","US","USA","Mississippi","","17375","1840014945"
"Bad Wildungen","Bad Wildungen","51.1167","9.1167","Germany","DE","DEU","Hesse","","17231","1276828659"
"Barbastro","Barbastro","42.0361","0.1264","Spain","ES","ESP","Aragon","","17214","1724545797"
"Riva del Garda","Riva del Garda","45.8833","10.8500","Italy","IT","ITA","Trentino-Alto Adige","","17370","1380246993"
"Saint-Julien-en-Genevois","Saint-Julien-en-Genevois","46.1442","6.0842","France","FR","FRA","Auvergne-Rhône-Alpes","minor","15873","1250672754"
"Stockerau","Stockerau","48.3858","16.2108","Austria","AT","AUT","Niederösterreich","","16916","1040718812"
"Cosautlán","Cosautlan","19.3333","-96.9833","Mexico","MX","MEX","Veracruz","minor","16353","1484430709"
"Horn-Bad Meinberg","Horn-Bad Meinberg","51.8833","8.9667","Germany","DE","DEU","North Rhine-Westphalia","","17178","1276648708"
"Ayacucho","Ayacucho","-37.1333","-58.4667","Argentina","AR","ARG","Buenos Aires","minor","17364","1032171567"
"G‘uzor","G\`uzor","38.6208","66.2481","Uzbekistan","UZ","UZB","Qashqadaryo","","17253","1860850796"
"Wohlen","Wohlen","47.3506","8.2778","Switzerland","CH","CHE","Aargau","","16078","1756643512"
"Ampère","Ampere","-25.9150","-53.4728","Brazil","BR","BRA","Paraná","","17308","1076399065"
"Domont","Domont","49.0275","2.3267","France","FR","FRA","Île-de-France","","15504","1250749171"
"Sohtha","Sohtha","25.6449","87.3068","India","IN","IND","Bihār","","16291","1356380399"
"Pital","Pital","10.6024","-84.2474","Costa Rica","CR","CRI","Alajuela","","17318","1188091027"
"Stockach","Stockach","47.8514","9.0114","Germany","DE","DEU","Baden-Württemberg","","17118","1276190104"
"East Finchley","East Finchley","51.5902","-0.1753","United Kingdom","GB","GBR","Barnet","","15989","1826180591"
"Sudipen","Sudipen","16.9000","120.4667","Philippines","PH","PHL","La Union","","17187","1608633480"
"South Ogden","South Ogden","41.1722","-111.9576","United States","US","USA","Utah","","17363","1840021342"
"Saint-Maximin-la-Sainte-Baume","Saint-Maximin-la-Sainte-Baume","43.4533","5.8619","France","FR","FRA","Provence-Alpes-Côte d’Azur","","17095","1250254633"
"Penn","Penn","39.7994","-76.9642","United States","US","USA","Pennsylvania","","17361","1840147116"
"Freiberg am Neckar","Freiberg am Neckar","48.9333","9.2000","Germany","DE","DEU","Baden-Württemberg","","16153","1276056473"
"Krasnoslobodsk","Krasnoslobodsk","48.7000","44.5667","Russia","RU","RUS","Volgogradskaya Oblast’","","17327","1643897251"
"Diepholz","Diepholz","52.6072","8.3711","Germany","DE","DEU","Lower Saxony","minor","17192","1276476080"
"Guryevsk","Guryevsk","54.7833","20.6167","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","17295","1643078744"
"Tonj","Tonj","7.2800","28.6800","South Sudan","SS","SSD","Warrap","","17340","1728183836"
"São Domingos do Prata","Sao Domingos do Prata","-19.8650","-42.9678","Brazil","BR","BRA","Minas Gerais","","17327","1076343054"
"Saviano","Saviano","40.9167","14.5167","Italy","IT","ITA","Campania","","16182","1380874488"
"Souto Soares","Souto Soares","-12.0889","-41.6378","Brazil","BR","BRA","Bahia","","17332","1076113405"
"Gussago","Gussago","45.6000","10.1500","Italy","IT","ITA","Lombardy","","16681","1380922728"
"Shumikha","Shumikha","55.2333","63.3000","Russia","RU","RUS","Kurganskaya Oblast’","","17336","1643819566"
"Muniz Freire","Muniz Freire","-20.4639","-41.4128","Brazil","BR","BRA","Espírito Santo","","17319","1076991967"
"Volgorechensk","Volgorechensk","57.4439","41.1592","Russia","RU","RUS","Kostromskaya Oblast’","","16539","1643892465"
"Jaguaruna","Jaguaruna","-28.6150","-49.0258","Brazil","BR","BRA","Santa Catarina","","17290","1076644928"
"Cajamarca","Cajamarca","4.4167","-75.5000","Colombia","CO","COL","Tolima","minor","17309","1170462346"
"Hrubieszów","Hrubieszow","50.8167","23.8833","Poland","PL","POL","Lubelskie","minor","16832","1616901935"
"Pécel","Pecel","47.4893","19.3354","Hungary","HU","HUN","Pest","","16952","1348260007"
"Camocim de São Félix","Camocim de Sao Felix","-8.3589","-35.7619","Brazil","BR","BRA","Pernambuco","","17104","1076257336"
"Nederweert","Nederweert","51.2833","5.7500","Netherlands","NL","NLD","Limburg","minor","17171","1528349130"
"Tíogollo","Tiogollo","10.3333","-74.6667","Colombia","CO","COL","Magdalena","","17308","1170688181"
"Betroka","Betroka","-23.2683","46.1044","Madagascar","MG","MDG","Toliara","","17327","1450282495"
"Tekanpur","Tekanpur","25.9940","78.2832","India","IN","IND","Madhya Pradesh","","16926","1356120722"
"Finnentrop","Finnentrop","51.1731","7.9725","Germany","DE","DEU","North Rhine-Westphalia","","17173","1276799672"
"Amboanjo","Amboanjo","-22.0000","47.8500","Madagascar","MG","MDG","Fianarantsoa","","17000","1450091390"
"Wilton","Wilton","43.1502","-73.7276","United States","US","USA","New York","","17333","1840058618"
"Banabuiú","Banabuiu","-5.3100","-38.9208","Brazil","BR","BRA","Ceará","","17315","1076818292"
"San Casciano in Val di Pesa","San Casciano in Val di Pesa","43.6569","11.1858","Italy","IT","ITA","Tuscany","","17171","1380526420"
"Bareggio","Bareggio","45.4667","8.9833","Italy","IT","ITA","Lombardy","","17304","1380239097"
"Przasnysz","Przasnysz","53.0167","20.8833","Poland","PL","POL","Mazowieckie","minor","16662","1616909392"
"Uryzhar","Uryzhar","47.0900","81.6228","Kazakhstan","KZ","KAZ","","minor","17320","1398461653"
"Pisaflores","Pisaflores","21.1933","-99.0050","Mexico","MX","MEX","Hidalgo","","17214","1484698691"
"Gonegandla","Gonegandla","15.7170","77.6000","India","IN","IND","Andhra Pradesh","","16691","1356039774"
"Yizhu","Yizhu","23.3565","120.2235","Taiwan","TW","TWN","Chiayi","","17104","1158909827"
"Urandi","Urandi","-14.7708","-42.6550","Brazil","BR","BRA","Bahia","","17301","1076830751"
"Handlová","Handlova","48.7272","18.7619","Slovakia","SK","SVK","Nitra","","17119","1703292608"
"Holmdel","Holmdel","40.3768","-74.1725","United States","US","USA","New Jersey","","17317","1840081650"
"Hayange","Hayange","49.3297","6.0619","France","FR","FRA","Grand Est","","16005","1250073762"
"Heysham","Heysham","54.0460","-2.8940","United Kingdom","GB","GBR","Lancashire","","17016","1826178724"
"Sundarpur","Sundarpur","26.3037","84.8581","India","IN","IND","Bihār","","15591","1356729218"
"Mount Holly","Mount Holly","35.3136","-81.0073","United States","US","USA","North Carolina","","17315","1840014590"
"Noordwijkerhout","Noordwijkerhout","52.2667","4.5000","Netherlands","NL","NLD","Zuid-Holland","minor","16605","1528406512"
"Sawankhalok","Sawankhalok","17.3099","99.8263","Thailand","TH","THA","Sukhothai","minor","17148","1764710205"
"Svalyava","Svalyava","48.5472","22.9861","Ukraine","UA","UKR","Zakarpatska Oblast","","17234","1804834428"
"Peso da Régua","Peso da Regua","41.1653","-7.7764","Portugal","PT","PRT","Vila Real","minor","17131","1620264911"
"Attūr","Attur","8.3224","77.2558","India","IN","IND","Tamil Nādu","","16184","1356205253"
"Pindorama","Pindorama","-21.1858","-48.9069","Brazil","BR","BRA","São Paulo","","17216","1076588629"
"Ambarès-et-Lagrave","Ambares-et-Lagrave","44.9247","-0.4867","France","FR","FRA","Nouvelle-Aquitaine","","16636","1250081826"
"Königstein im Taunus","Konigstein im Taunus","50.1833","8.4667","Germany","DE","DEU","Hesse","","16648","1276481618"
"Kaatsheuvel","Kaatsheuvel","51.6667","5.0667","Netherlands","NL","NLD","Noord-Brabant","minor","16600","1528956627"
"Achaljāmu","Achaljamu","24.0243","85.8306","India","IN","IND","Jhārkhand","","16826","1356255420"
"Braunau am Inn","Braunau am Inn","48.2583","13.0333","Austria","AT","AUT","Oberösterreich","minor","17095","1040852989"
"Cruz do Espírito Santo","Cruz do Espirito Santo","-7.1400","-35.0858","Brazil","BR","BRA","Paraíba","","17212","1076291105"
"Linnei","Linnei","23.7591","120.6184","Taiwan","TW","TWN","Yunlin","","16856","1158497555"
"Aubange","Aubange","49.5667","5.8050","Belgium","BE","BEL","Wallonia","","16927","1056280555"
"Lyepyel","Lyepyel","54.8750","28.6944","Belarus","BY","BLR","Vitsyebskaya Voblasts’","","17280","1112177201"
"Rustampur","Rustampur","25.5700","85.3061","India","IN","IND","Bihār","","16123","1356881266"
"Mohanpur","Mohanpur","25.5620","85.5917","India","IN","IND","Bihār","","15966","1356241175"
"Tomas Oppus","Tomas Oppus","10.2500","124.9833","Philippines","PH","PHL","Southern Leyte","","16990","1608136756"
"Gokarna","Gokarna","24.0306","88.1181","India","IN","IND","West Bengal","","16198","1356378146"
"Altena","Altena","51.3000","7.6667","Germany","DE","DEU","North Rhine-Westphalia","","16922","1276575290"
"Mont-Organisé","Mont-Organise","19.4000","-71.7833","Haiti","HT","HTI","Nord-Est","","17189","1332250944"
"Boerne","Boerne","29.7847","-98.7292","United States","US","USA","Texas","","17290","1840019623"
"Nunna","Nunna","16.5788","80.6841","India","IN","IND","Andhra Pradesh","","16354","1356312429"
"Kangaba","Kangaba","11.9333","-8.4167","Mali","ML","MLI","Koulikoro","minor","17232","1466770326"
"Amesbury","Amesbury","42.8530","-70.9446","United States","US","USA","Massachusetts","","17286","1840132383"
"Toledo","Toledo","7.3131","-72.4875","Colombia","CO","COL","Norte de Santander","minor","17283","1170149745"
"Rentachintala","Rentachintala","16.5524","79.5529","India","IN","IND","Andhra Pradesh","","16523","1356544137"
"Poing","Poing","48.1667","11.8167","Germany","DE","DEU","Bavaria","","16042","1276650408"
"Streetsboro","Streetsboro","41.2396","-81.3456","United States","US","USA","Ohio","","17282","1840000808"
"Carovigno","Carovigno","40.7000","17.6667","Italy","IT","ITA","Puglia","","17120","1380489913"
"Raspur Patasia","Raspur Patasia","25.5616","85.5245","India","IN","IND","Bihār","","15374","1356551207"
"Sītāmau","Sitamau","24.0147","75.3532","India","IN","IND","Madhya Pradesh","","17013","1356996654"
"Tecolotlán","Tecolotlan","20.2024","-104.0504","Mexico","MX","MEX","Jalisco","minor","17257","1484469850"
"Milledgeville","Milledgeville","33.0874","-83.2414","United States","US","USA","Georgia","","17278","1840014857"
"Peçanha","Pecanha","-18.5489","-42.5569","Brazil","BR","BRA","Minas Gerais","","17260","1076594698"
"Bermeo","Bermeo","43.4200","-2.7264","Spain","ES","ESP","Basque Country","","16784","1724977150"
"Podporozhye","Podporozhye","60.9000","34.2000","Russia","RU","RUS","Leningradskaya Oblast’","","17270","1643153235"
"Shahritus","Shahritus","37.2667","68.1333","Tajikistan","TJ","TJK","Khatlon","minor","17200","1762631675"
"Salesópolis","Salesopolis","-23.5319","-45.8458","Brazil","BR","BRA","São Paulo","","17252","1076843165"
"Kinel’-Cherkassy","Kinel'-Cherkassy","53.4683","51.4775","Russia","RU","RUS","Samarskaya Oblast’","","17252","1643695991"
"Shongzhy","Shongzhy","43.5417","79.4703","Kazakhstan","KZ","KAZ","","","17263","1398268525"
"Stuart","Stuart","27.1959","-80.2444","United States","US","USA","Florida","","17269","1840015990"
"Peka","Peka","-28.9667","27.7667","Lesotho","LS","LSO","Leribe","","17161","1426883047"
"Bom Sucesso","Bom Sucesso","-21.0328","-44.7578","Brazil","BR","BRA","Minas Gerais","","17243","1076350360"
"Grójec","Grojec","51.8656","20.8675","Poland","PL","POL","Mazowieckie","minor","17037","1616458276"
"Semra","Semra","26.6523","85.0872","India","IN","IND","Bihār","","15338","1356200490"
"Chandia","Chandia","23.6565","80.7091","India","IN","IND","Madhya Pradesh","","17088","1356153825"
"Guoxing","Guoxing","24.0550","120.8700","Taiwan","TW","TWN","Nantou","","17168","1158237846"
"West Columbia","West Columbia","33.9932","-81.0936","United States","US","USA","South Carolina","","17265","1840015605"
"Baymak","Baymak","52.5833","58.3167","Russia","RU","RUS","Bashkortostan","","17254","1643017239"
"Erba","Erba","45.8167","9.2167","Italy","IT","ITA","Lombardy","","16346","1380026548"
"Middlesex Centre","Middlesex Centre","43.0500","-81.4500","Canada","CA","CAN","Ontario","","17262","1124001841"
"Bakarpur Ogairah","Bakarpur Ogairah","25.3294","87.5138","India","IN","IND","Bihār","","16106","1356113318"
"Beckley","Beckley","37.7877","-81.1841","United States","US","USA","West Virginia","","17261","1840006350"
"Lérida","Lerida","4.9000","-74.9167","Colombia","CO","COL","Tolima","minor","17197","1170865929"
"Kodikulam","Kodikulam","9.9932","79.2007","India","IN","IND","Tamil Nādu","","16803","1356085608"
"Eggenstein-Leopoldshafen","Eggenstein-Leopoldshafen","49.0778","8.3925","Germany","DE","DEU","Baden-Württemberg","","16625","1276489568"
"Fulshear","Fulshear","29.6930","-95.8804","United States","US","USA","Texas","","17259","1840020946"
"Piranga","Piranga","-20.6850","-43.3000","Brazil","BR","BRA","Minas Gerais","","17232","1076731478"
"Mula","Mula","38.0419","-1.4906","Spain","ES","ESP","Murcia","","17231","1724000713"
"Xintangcun","Xintangcun","23.9423","116.7865","China","CN","CHN","Guangdong","","17100","1156190257"
"Talsint","Talsint","32.5398","-3.4408","Morocco","MA","MAR","Oriental","","16166","1504691780"
"Calnali","Calnali","20.9000","-98.5833","Mexico","MX","MEX","Hidalgo","","17163","1484014196"
"Ambohipihaonana","Ambohipihaonana","-19.4333","47.4167","Madagascar","MG","MDG","Antananarivo","","17000","1450648421"
"Tamallalt","Tamallalt","31.8289","-7.5261","Morocco","MA","MAR","Marrakech-Safi","","16539","1504009378"
"Cacimba de Dentro","Cacimba de Dentro","-6.6419","-35.7900","Brazil","BR","BRA","Paraíba","","17149","1076408478"
"Kāmavarapukota","Kamavarapukota","17.0033","81.1953","India","IN","IND","Andhra Pradesh","","16790","1356057733"
"Piçarras","Picarras","-26.7500","-48.6667","Brazil","BR","BRA","Santa Catarina","","17078","1076750040"
"Villa Isabela","Villa Isabela","19.8200","-71.0500","Dominican Republic","DO","DOM","Cibao Norte","","17169","1214019421"
"Saint Ives","Saint Ives","52.3344","-0.0761","United Kingdom","GB","GBR","Cambridgeshire","","16815","1826186113"
"Pinehurst","Pinehurst","35.1922","-79.4684","United States","US","USA","North Carolina","","17250","1840017852"
"Ilaka Atsinanana","Ilaka Atsinanana","-19.5531","48.8408","Madagascar","MG","MDG","Toamasina","","17120","1450972250"
"Bawgalegyi","Bawgalegyi","18.9144","96.7856","Myanmar","MM","MMR","Kayin State","","17237","1104881493"
"El Rosario","El Rosario","13.5000","-89.0333","El Salvador","SV","SLV","La Paz","","16784","1222543838"
"Minakami","Minakami","36.6786","138.9991","Japan","JP","JPN","Gunma","","17223","1392003162"
"Minamichita","Minamichita","34.7151","136.9298","Japan","JP","JPN","Aichi","","16805","1392003355"
"El Segundo","El Segundo","33.9170","-118.4018","United States","US","USA","California","","17243","1840020481"
"Fort Thomas","Fort Thomas","39.0801","-84.4518","United States","US","USA","Kentucky","","17241","1840013163"
"Murray","Murray","36.6146","-88.3207","United States","US","USA","Kentucky","","17241","1840014410"
"São João dos Poleiros","Sao Joao dos Poleiros","-5.1139","-43.8100","Brazil","BR","BRA","Maranhão","","17238","1076115863"
"Mohács","Mohacs","45.9959","18.6798","Hungary","HU","HUN","Baranya","minor","17089","1348907014"
"Cham","Cham","49.2167","12.6500","Germany","DE","DEU","Bavaria","minor","17029","1276459468"
"Cáqueza","Caqueza","4.4053","-73.9478","Colombia","CO","COL","Cundinamarca","minor","17048","1170175410"
"Pollensa","Pollensa","39.8772","3.0164","Spain","ES","ESP","Balearic Islands","","17126","1724052318"
"Ribeirópolis","Ribeiropolis","-10.5389","-37.4361","Brazil","BR","BRA","Sergipe","","17173","1076522611"
"Clemson","Clemson","34.6837","-82.8124","United States","US","USA","South Carolina","","17238","1840014626"
"Çukurca","Cukurca","37.2470","43.6130","Turkey","TR","TUR","Hakkâri","minor","17216","1792638324"
"Glenvar Heights","Glenvar Heights","25.7090","-80.3155","United States","US","USA","Florida","","17237","1840014238"
"Camano","Camano","48.1865","-122.4708","United States","US","USA","Washington","","17235","1840037492"
"Inverness","Inverness","46.2000","-61.1000","Canada","CA","CAN","Nova Scotia","","17235","1124840965"
"Massé","Masse","7.1578","2.5436","Benin","BJ","BEN","Plateau","","16902","1204168312"
"Hīrna","Hirna","9.2167","41.1000","Ethiopia","ET","ETH","Oromīya","","16726","1231773847"
"Penzberg","Penzberg","47.7500","11.3833","Germany","DE","DEU","Bavaria","","16586","1276589283"
"Bārnia","Barnia","23.7311","88.4329","India","IN","IND","West Bengal","","16350","1356722689"
"Sada","Sada","43.3500","-8.2500","Spain","ES","ESP","Galicia","","16627","1724976865"
"Terre Neuve","Terre Neuve","19.6000","-72.7833","Haiti","HT","HTI","Artibonite","","17045","1332504723"
"Ustka","Ustka","54.5833","16.8500","Poland","PL","POL","Pomorskie","","15812","1616638026"
"Cestas","Cestas","44.7444","-0.6822","France","FR","FRA","Nouvelle-Aquitaine","","17053","1250313637"
"Chuarrancho","Chuarrancho","14.8167","-90.5167","Guatemala","GT","GTM","Guatemala","minor","17057","1320688968"
"Elbeuf","Elbeuf","49.2858","1.0083","France","FR","FRA","Normandie","","16224","1250753787"
"Guadarrama","Guadarrama","40.6728","-4.0889","Spain","ES","ESP","Madrid","","16921","1724067759"
"Nāyakanhatti","Nayakanhatti","14.4644","76.5451","India","IN","IND","Karnātaka","","17016","1356856848"
"Dour","Dour","50.3979","3.7807","Belgium","BE","BEL","Wallonia","","16716","1056826139"
"Huruta","Huruta","8.1500","39.3500","Ethiopia","ET","ETH","Oromīya","","16922","1231839518"
"Xianxi","Xianxi","24.1334","120.4589","Taiwan","TW","TWN","Changhua","","16310","1158894536"
"Aabenraa","Aabenraa","55.0444","9.4181","Denmark","DK","DNK","Syddanmark","minor","16401","1208000017"
"Tsuiki","Tsuiki","33.6561","131.0562","Japan","JP","JPN","Fukuoka","","17067","1392121731"
"Pozoblanco","Pozoblanco","38.3833","-4.8500","Spain","ES","ESP","Andalusia","","17156","1724804150"
"Santa Maria das Barreiras","Santa Maria das Barreiras","-8.8550","-49.7219","Brazil","BR","BRA","Pará","","17206","1076324057"
"Kozienice","Kozienice","51.5833","21.5667","Poland","PL","POL","Mazowieckie","minor","17075","1616307779"
"Marienberg","Marienberg","50.6333","13.1500","Germany","DE","DEU","Saxony","","17097","1276662047"
"Sāyarpuram","Sayarpuram","8.6822","78.0265","India","IN","IND","Tamil Nādu","","16857","1356468611"
"Math Lohiyār","Math Lohiyar","26.6247","84.7072","India","IN","IND","Bihār","","16119","1356692789"
"Condeixa-a-Nova","Condeixa-a-Nova","40.1167","-8.5000","Portugal","PT","PRT","Coimbra","minor","17078","1620361727"
"Barrington","Barrington","41.7443","-71.3145","United States","US","USA","Rhode Island","","17201","1840106239"
"Fiorano Modenese","Fiorano Modenese","44.5333","10.8167","Italy","IT","ITA","Emilia-Romagna","","17099","1380451196"
"Shoufeng","Shoufeng","23.8667","121.5167","Taiwan","TW","TWN","Hualien","","17119","1158349952"
"Araçagi","Aracagi","-6.8528","-35.3808","Brazil","BR","BRA","Paraíba","","17122","1076975974"
"Lebanon","Lebanon","40.0324","-86.4551","United States","US","USA","Indiana","","17196","1840008387"
"Rubeho","Rubeho","-6.2578","36.8653","Tanzania","TZ","TZA","Morogoro","","17020","1834075405"
"Santana do Cariri","Santana do Cariri","-7.1878","-39.7369","Brazil","BR","BRA","Ceará","","17170","1076040650"
"Balmazújváros","Balmazujvaros","47.6167","21.3500","Hungary","HU","HUN","Hajdú-Bihar","minor","17109","1348090687"
"Mebane","Mebane","36.0852","-79.2736","United States","US","USA","North Carolina","","17192","1840015328"
"Itirapina","Itirapina","-22.2528","-47.8228","Brazil","BR","BRA","São Paulo","","17160","1076964066"
"Karratha","Karratha","-20.7364","116.8464","Australia","AU","AUS","Western Australia","","17013","1036951388"
"Saran","Saran","47.9514","1.8803","France","FR","FRA","Centre-Val de Loire","","16357","1250533222"
"Stony Plain","Stony Plain","53.5264","-114.0069","Canada","CA","CAN","Alberta","","17189","1124982081"
"Jičín","Jicin","50.4367","15.3517","Czechia","CZ","CZE","Královéhradecký Kraj","","16551","1203562586"
"Petawawa","Petawawa","45.9000","-77.2833","Canada","CA","CAN","Ontario","","17187","1124206291"
"Rāmāyampet","Ramayampet","18.1166","78.4298","India","IN","IND","Andhra Pradesh","","16899","1356359427"
"Campagna","Campagna","40.6667","15.1000","Italy","IT","ITA","Campania","","17169","1380576696"
"Brzesko","Brzesko","49.9667","20.6167","Poland","PL","POL","Małopolskie","minor","16819","1616496298"
"Canudos","Canudos","-9.9639","-39.1639","Brazil","BR","BRA","Bahia","","17177","1076987172"
"Sedan","Sedan","49.7019","4.9403","France","FR","FRA","Grand Est","minor","16193","1250183265"
"Lagoa Formosa","Lagoa Formosa","-18.7789","-46.4078","Brazil","BR","BRA","Minas Gerais","","17161","1076465186"
"Rio Linda","Rio Linda","38.6875","-121.4417","United States","US","USA","California","","17181","1840018843"
"Azaourissè","Azaourisse","6.6944","2.5075","Benin","BJ","BEN","Ouémé","","16883","1204305272"
"Adustina","Adustina","-10.5328","-38.1000","Brazil","BR","BRA","Bahia","","17153","1076245187"
"Saluzzo","Saluzzo","44.6453","7.4906","Italy","IT","ITA","Piedmont","","16958","1380304445"
"Rio Pomba","Rio Pomba","-21.2750","-43.1789","Brazil","BR","BRA","Minas Gerais","","17110","1076797985"
"Borda da Mata","Borda da Mata","-22.2739","-46.1650","Brazil","BR","BRA","Minas Gerais","","17118","1076422233"
"Konstantinovsk","Konstantinovsk","47.5667","41.0833","Russia","RU","RUS","Rostovskaya Oblast’","minor","17160","1643188587"
"Babenhausen","Babenhausen","49.9667","8.9500","Germany","DE","DEU","Hesse","","16920","1276006345"
"Sidi Daoud","Sidi Daoud","36.8500","3.8500","Algeria","DZ","DZA","Boumerdes","","16900","1012618002"
"Bloemhof","Bloemhof","-27.6500","25.5900","South Africa","ZA","ZAF","North West","","17122","1710321800"
"Knaresborough","Knaresborough","54.0084","-1.4670","United Kingdom","GB","GBR","North Yorkshire","","15441","1826000526"
"Stafford","Stafford","29.6271","-95.5654","United States","US","USA","Texas","","17170","1840022216"
"Traiguén","Traiguen","-38.2500","-72.6833","Chile","CL","CHL","Araucanía","","17164","1152501347"
"Elias Fausto","Elias Fausto","-23.0428","-47.3739","Brazil","BR","BRA","São Paulo","","17085","1076412113"
"Ganeshpur","Ganeshpur","25.7678","87.3587","India","IN","IND","Bihār","","16175","1356429813"
"Burbaliq","Burbaliq","40.3247","71.3692","Uzbekistan","UZ","UZB","Farg‘ona","","16600","1860496270"
"Fairview Park","Fairview Park","41.4419","-81.8530","United States","US","USA","Ohio","","17167","1840000601"
"Brixham","Brixham","50.3940","-3.5160","United Kingdom","GB","GBR","Devon","","16825","1826371818"
"Hannibal","Hannibal","39.7098","-91.3939","United States","US","USA","Missouri","","17165","1840007306"
"El Reno","El Reno","35.5429","-97.9660","United States","US","USA","Oklahoma","","17164","1840020420"
"Danville","Danville","37.6418","-84.7777","United States","US","USA","Kentucky","","17164","1840014354"
"Urucará","Urucara","-2.5358","-57.7600","Brazil","BR","BRA","Amazonas","","17163","1076852276"
"Penamalūru","Penamaluru","16.4681","80.7194","India","IN","IND","Andhra Pradesh","","15371","1356199565"
"Dilasag","Dilasag","16.4000","122.2167","Philippines","PH","PHL","Aurora","","17102","1608348381"
"Gubin","Gubin","51.9500","14.7167","Poland","PL","POL","Lubuskie","","17150","1616164527"
"Cañon City","Canon City","38.4430","-105.2203","United States","US","USA","Colorado","","17157","1840018855"
"Kruibeke","Kruibeke","51.1667","4.3000","Belgium","BE","BEL","Flanders","","16657","1056526528"
"Defiance","Defiance","41.2813","-84.3657","United States","US","USA","Ohio","","17155","1840007133"
"West Lampeter","West Lampeter","39.9947","-76.2560","United States","US","USA","Pennsylvania","","17155","1840152738"
"Benešov","Benesov","49.7828","14.6886","Czechia","CZ","CZE","Středočeský Kraj","","16804","1203042729"
"Kusa","Kusa","55.3500","59.4500","Russia","RU","RUS","Chelyabinskaya Oblast’","","17136","1643204008"
"São Vicente Férrer","Sao Vicente Ferrer","-7.5908","-35.4908","Brazil","BR","BRA","Pernambuco","","17000","1076047875"
"Anosibe-Ifanja","Anosibe-Ifanja","-18.8667","46.7500","Madagascar","MG","MDG","Antananarivo","","17000","1450904435"
"Ejea de los Caballeros","Ejea de los Caballeros","42.1292","-1.1372","Spain","ES","ESP","Aragon","","17124","1724015222"
"Douar Lamjaara","Douar Lamjaara","34.6147","-5.2887","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16899","1504061051"
"Sa‘ādat Shahr","Sa\`adat Shahr","30.0775","53.1331","Iran","IR","IRN","Fārs","minor","17131","1364794226"
"Manlin","Manlin","23.6630","101.8853","China","CN","CHN","Yunnan","","16921","1156662471"
"Capistrano","Capistrano","-4.4700","-38.9008","Brazil","BR","BRA","Ceará","","17062","1076524750"
"Jaboticatubas","Jaboticatubas","-19.5139","-43.7450","Brazil","BR","BRA","Minas Gerais","","17134","1076147511"
"Belterra","Belterra","-2.6358","-54.9369","Brazil","BR","BRA","Pará","","17145","1076386463"
"Codru","Codru","46.9753","28.8194","Moldova","MD","MDA","Chişinău","","15934","1498544482"
"Broxburn","Broxburn","55.9340","-3.4710","United Kingdom","GB","GBR","West Lothian","","15440","1826364673"
"Ignacio de la Llave","Ignacio de la Llave","18.6618","-95.9721","Mexico","MX","MEX","Veracruz","minor","17105","1484700629"
"Amarante","Amarante","-6.2408","-42.8550","Brazil","BR","BRA","Piauí","","17135","1076771383"
"Hinsdale","Hinsdale","41.8007","-87.9273","United States","US","USA","Illinois","","17148","1840011403"
"Lerum","Lerum","57.7667","12.3000","Sweden","SE","SWE","Västra Götaland","minor","16855","1752006435"
"Ginatilan","Ginatilan","9.6000","123.3500","Philippines","PH","PHL","Cebu","","16906","1608666277"
"Sidi Yakoub","Sidi Yakoub","31.6667","-7.0667","Morocco","MA","MAR","Béni Mellal-Khénifra","","17054","1504968095"
"Gainesville","Gainesville","33.6390","-97.1487","United States","US","USA","Texas","","17146","1840020573"
"Piñas","Pinas","-3.6806","-79.6806","Ecuador","EC","ECU","El Oro","","16981","1218213967"
"Shāhkot","Shahkot","31.0800","75.3400","India","IN","IND","Punjab","","16641","1356058575"
"Livinjipuram","Livinjipuram","8.1535","77.5719","India","IN","IND","Tamil Nādu","","16736","1356207951"
"Xixinzhuangzhen","Xixinzhuangzhen","37.0165","111.4908","China","CN","CHN","Shanxi","","16839","1156550241"
"San Juan del Sur","San Juan del Sur","11.2533","-85.8708","Nicaragua","NI","NIC","Rivas","minor","17104","1558967577"
"Castellaneta","Castellaneta","40.6333","16.9333","Italy","IT","ITA","Puglia","","17069","1380977317"
"Xinlong","Xinlong","18.9534","108.6800","China","CN","CHN","Hainan","","17047","1156258858"
"Basārh","Basarh","25.9808","85.1285","India","IN","IND","Bihār","","15330","1356089143"
"Zvenyhorodka","Zvenyhorodka","49.0833","30.9667","Ukraine","UA","UKR","Cherkaska Oblast","minor","17078","1804045250"
"Pontalina","Pontalina","-17.5258","-49.4489","Brazil","BR","BRA","Goiás","","17121","1076353153"
"Gurh","Gurh","24.5026","81.5004","India","IN","IND","Madhya Pradesh","","16434","1356075581"
"Avenel","Avenel","40.5842","-74.2716","United States","US","USA","New Jersey","","17132","1840005410"
"Bhit Bhagwānpur","Bhit Bhagwanpur","26.1160","86.3550","India","IN","IND","Bihār","","15660","1356282994"
"Rancho Mirage","Rancho Mirage","33.7635","-116.4271","United States","US","USA","California","","17129","1840020558"
"Landerneau","Landerneau","48.4508","-4.2494","France","FR","FRA","Bretagne","","15918","1250610309"
"Kronach","Kronach","50.2411","11.3281","Germany","DE","DEU","Bavaria","minor","16874","1276212761"
"Long’e","Long'e","25.8061","109.2134","China","CN","CHN","Guizhou","","16928","1156190405"
"Nandigaon","Nandigaon","17.1190","78.2564","India","IN","IND","Andhra Pradesh","","16752","1356973584"
"Geddes","Geddes","43.0762","-76.2252","United States","US","USA","New York","","17123","1840087460"
"Rotselaar","Rotselaar","50.9511","4.7108","Belgium","BE","BEL","Flanders","","16678","1056082095"
"Wādī Ḩalfā’","Wadi Halfa'","21.8000","31.3500","Sudan","SD","SDN","Northern","","17121","1729435342"
"Lakhipur","Lakhipur","26.2897","90.2281","India","IN","IND","Assam","","16562","1356389047"
"Svitavy","Svitavy","49.7560","16.4683","Czechia","CZ","CZE","Pardubický Kraj","","16601","1203802204"
"San Giovanni in Fiore","San Giovanni in Fiore","39.2642","16.7003","Italy","IT","ITA","Calabria","","17059","1380843542"
"Bargteheide","Bargteheide","53.7167","10.2667","Germany","DE","DEU","Schleswig-Holstein","","16109","1276611028"
"Tolongoina","Tolongoina","-21.5500","47.5167","Madagascar","MG","MDG","Fianarantsoa","","17000","1450803657"
"Kibungan","Kibungan","16.6939","120.6539","Philippines","PH","PHL","Benguet","","17051","1608673701"
"Navalmoral de la Mata","Navalmoral de la Mata","39.8983","-5.5403","Spain","ES","ESP","Extremadura","","17010","1724575983"
"Preetz","Preetz","54.2367","10.2822","Germany","DE","DEU","Schleswig-Holstein","","16005","1276274661"
"Mandritsara","Mandritsara","-15.8333","48.8167","Madagascar","MG","MDG","Mahajanga","","17000","1450981137"
"Villeneuve-Loubet","Villeneuve-Loubet","43.6581","7.1214","France","FR","FRA","Provence-Alpes-Côte d’Azur","","16285","1250412551"
"Eppelborn","Eppelborn","49.4022","6.9706","Germany","DE","DEU","Saarland","","16767","1276013431"
"Podalakūr","Podalakur","14.3667","79.7333","India","IN","IND","Andhra Pradesh","","16662","1356905606"
"Espartinas","Espartinas","37.3833","-6.1167","Spain","ES","ESP","Andalusia","","16401","1724191526"
"Yangi Mirishkor","Yangi Mirishkor","38.8514","65.2778","Uzbekistan","UZ","UZB","Qashqadaryo","minor","17000","1860618656"
"Anosivelo","Anosivelo","-22.7333","47.7667","Madagascar","MG","MDG","Fianarantsoa","","17000","1450013354"
"Tagounite","Tagounite","29.9833","-5.5667","Morocco","MA","MAR","Drâa-Tafilalet","","17103","1504500189"
"Ferndale","Ferndale","39.1869","-76.6331","United States","US","USA","Maryland","","17113","1840005906"
"Nidgundi","Nidgundi","16.7000","75.5300","India","IN","IND","Karnātaka","","16901","1356334960"
"Taxtako‘pir","Taxtako\`pir","43.0225","60.2886","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","17100","1860617096"
"Tifton","Tifton","31.4624","-83.5203","United States","US","USA","Georgia","","17112","1840015863"
"Néa Mákri","Nea Makri","38.0833","23.9833","Greece","GR","GRC","Attikí","","16670","1300420365"
"Pelham","Pelham","43.0333","-79.3333","Canada","CA","CAN","Ontario","","17110","1124000042"
"Aurora","Aurora","41.3118","-81.3450","United States","US","USA","Ohio","","17108","1840000806"
"Famy","Famy","14.4333","121.4500","Philippines","PH","PHL","Laguna","","16791","1608223932"
"Kežmarok","Kezmarok","49.1336","20.4264","Slovakia","SK","SVK","Prešov","minor","16481","1703214180"
"Abelardo Luz","Abelardo Luz","-26.5650","-52.3278","Brazil","BR","BRA","Santa Catarina","","17100","1076575845"
"Oswego","Oswego","43.4516","-76.5005","United States","US","USA","New York","","17105","1840000348"
"Resplendor","Resplendor","-19.3258","-41.2550","Brazil","BR","BRA","Minas Gerais","","17089","1076246144"
"Nova Canaã","Nova Canaa","-14.7939","-40.1419","Brazil","BR","BRA","Bahia","","17082","1076285608"
"Knemis Dades","Knemis Dades","31.3090","-6.0280","Morocco","MA","MAR","Drâa-Tafilalet","","17045","1504000136"
"Analanampotsy","Analanampotsy","-17.1667","49.4833","Madagascar","MG","MDG","Toamasina","","16987","1450341522"
"Thandla","Thandla","23.0096","74.5775","India","IN","IND","Madhya Pradesh","","16744","1356869853"
"El Socorro","El Socorro","8.9936","-65.7456","Venezuela","VE","VEN","Guárico","minor","17097","1862016030"
"Xinying","Xinying","35.7060","104.1824","China","CN","CHN","Gansu","","17018","1156248513"
"Łęczyca","Leczyca","52.0500","19.2000","Poland","PL","POL","Łódzkie","minor","15593","1616649790"
"Bargūr","Bargur","12.5429","78.3574","India","IN","IND","Tamil Nādu","","16608","1356140947"
"Yabuki","Yabuki","37.2013","140.3386","Japan","JP","JPN","Fukushima","","16823","1392633195"
"Conyers","Conyers","33.6646","-83.9966","United States","US","USA","Georgia","","17101","1840014806"
"Ilakatra","Ilakatra","-22.3500","47.6667","Madagascar","MG","MDG","Fianarantsoa","","17000","1450772547"
"Tullinge","Tullinge","59.2000","17.9000","Sweden","SE","SWE","Stockholm","","16000","1752531824"
"Mahires","Mahires","34.5333","10.5000","Tunisia","TN","TUN","Sfax","","15878","1788002942"
"San Esteban","San Esteban","-32.7992","-70.5803","Chile","CL","CHL","Valparaíso","","17090","1152059779"
"Croatá","Croata","-4.4000","-40.9108","Brazil","BR","BRA","Ceará","","17069","1076626638"
"Safety Harbor","Safety Harbor","28.0080","-82.6964","United States","US","USA","Florida","","17093","1840015975"
"Chopadandi","Chopadandi","18.5833","79.1667","India","IN","IND","Andhra Pradesh","","16459","1356589291"
"Carmópolis de Minas","Carmopolis de Minas","-20.5408","-44.6350","Brazil","BR","BRA","Minas Gerais","","17048","1076274670"
"Rāmachandrapuran","Ramachandrapuran","17.3000","78.1700","India","IN","IND","Andhra Pradesh","","15381","1356042653"
"Stone","Stone","52.9000","-2.1500","United Kingdom","GB","GBR","Staffordshire","","16385","1826761372"
"Macatuba","Macatuba","-22.5022","-48.7114","Brazil","BR","BRA","São Paulo","","17013","1076530289"
"Sallanches","Sallanches","45.9364","6.6319","France","FR","FRA","Auvergne-Rhône-Alpes","","16831","1250639013"
"Ghonchí","Ghonchi","39.9589","69.1369","Tajikistan","TJ","TJK","Sughd","minor","17000","1762461184"
"Fundão","Fundao","-19.9328","-40.4069","Brazil","BR","BRA","Espírito Santo","","17025","1076953629"
"Terrell","Terrell","32.7340","-96.2931","United States","US","USA","Texas","","17083","1840022065"
"Martinsicuro","Martinsicuro","42.8833","13.9167","Italy","IT","ITA","Abruzzo","","15992","1380108637"
"Iluppur","Iluppur","10.5137","78.6216","India","IN","IND","Tamil Nādu","","16719","1356459968"
"Gainesville","Gainesville","38.7931","-77.6347","United States","US","USA","Virginia","","17081","1840006098"
"Piera","Piera","41.5222","1.7494","Spain","ES","ESP","Catalonia","","16787","1724184364"
"Sadabe","Sadabe","-18.6333","47.7000","Madagascar","MG","MDG","Antananarivo","","17000","1450000063"
"Shklow","Shklow","54.2236","30.2864","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","17064","1112362928"
"Hindarx","Hindarx","40.0700","47.2047","Azerbaijan","AZ","AZE","Ağcabədi","","16998","1031830681"
"Gavrilov-Yam","Gavrilov-Yam","57.3167","39.8500","Russia","RU","RUS","Yaroslavskaya Oblast’","","17057","1643185412"
"Ambondromisotra","Ambondromisotra","-20.3333","46.9167","Madagascar","MG","MDG","Fianarantsoa","","17000","1450889572"
"Miqiao","Miqiao","35.4991","108.2949","China","CN","CHN","Gansu","","16886","1156059565"
"Groves","Groves","29.9457","-93.9164","United States","US","USA","Texas","","17078","1840020916"
"Oulad Amrane","Oulad Amrane","32.2833","-9.2333","Morocco","MA","MAR","Marrakech-Safi","","16979","1504791859"
"Xiulin","Xiulin","24.2167","121.5333","Taiwan","TW","TWN","Hualien","","17068","1158906306"
"Menzelinsk","Menzelinsk","55.7333","53.1167","Russia","RU","RUS","Tatarstan","","17055","1643750653"
"Torre Maggiore","Torre Maggiore","41.6833","15.2833","Italy","IT","ITA","Puglia","","17069","1380458474"
"Mauguio","Mauguio","43.6164","4.0075","France","FR","FRA","Occitanie","","16735","1250674369"
"Los Lunas","Los Lunas","34.8115","-106.7803","United States","US","USA","New Mexico","","17072","1840022814"
"Dhulkot","Dhulkot","21.6095","75.5522","India","IN","IND","Madhya Pradesh","","16896","1356129072"
"Mullānwāla","Mullanwala","31.0619","74.8228","India","IN","IND","Punjab","","16628","1356314398"
"Ban Cho Ho","Ban Cho Ho","15.0311","102.1381","Thailand","TH","THA","Nakhon Ratchasima","","16527","1764003189"
"Druzhba","Druzhba","41.2222","61.3067","Uzbekistan","UZ","UZB","Xorazm","","16800","1860072225"
"Água Fria","Agua Fria","-11.8669","-38.7669","Brazil","BR","BRA","Bahia","","17043","1076969631"
"Schwarzenberg","Schwarzenberg","50.5453","12.7792","Germany","DE","DEU","Saxony","","16723","1276831560"
"Denville","Denville","40.8890","-74.4893","United States","US","USA","New Jersey","","17068","1840081734"
"Holzkirchen","Holzkirchen","47.8833","11.7000","Germany","DE","DEU","Bavaria","","16722","1276287582"
"Stanley","Stanley","53.7145","-1.4760","United Kingdom","GB","GBR","Wakefield","","15314","1826805412"
"Annonay","Annonay","45.2400","4.6708","France","FR","FRA","Auvergne-Rhône-Alpes","","16297","1250947806"
"Tortum","Tortum","40.2981","41.6325","Turkey","TR","TUR","Erzurum","minor","17054","1792060853"
"Santo Tomás de Jánico","Santo Tomas de Janico","19.4000","-70.8000","Dominican Republic","DO","DOM","Cibao Norte","","16993","1214364807"
"Grottammare","Grottammare","42.9897","13.8658","Italy","IT","ITA","Marche","","16166","1380551204"
"Gunzenhausen","Gunzenhausen","49.1147","10.7542","Germany","DE","DEU","Bavaria","","16860","1276273217"
"Turinsk","Turinsk","58.0333","63.7000","Russia","RU","RUS","Sverdlovskaya Oblast’","","17060","1643094071"
"Milanoa","Milanoa","-13.5833","49.7833","Madagascar","MG","MDG","Antsiranana","","17000","1450982964"
"Ambohitrolomahitsy","Ambohitrolomahitsy","-18.7000","47.6833","Madagascar","MG","MDG","Antananarivo","","17000","1450324872"
"Pokrov","Pokrov","55.9117","39.1847","Russia","RU","RUS","Vladimirskaya Oblast’","","17025","1643390317"
"Capela","Capela","-9.4075","-36.0736","Brazil","BR","BRA","Alagoas","","16979","1076765224"
"Hosakote","Hosakote","14.2817","77.1742","India","IN","IND","Karnātaka","","16868","1356110096"
"Tsarahonenana","Tsarahonenana","-15.4833","48.4833","Madagascar","MG","MDG","Mahajanga","","17000","1450021972"
"Sahamadio","Sahamadio","-20.3000","47.4000","Madagascar","MG","MDG","Fianarantsoa","","17000","1450646070"
"Selwyn","Selwyn","44.4167","-78.3333","Canada","CA","CAN","Ontario","","17060","1124000937"
"Bruckmühl","Bruckmuhl","47.8833","11.9167","Germany","DE","DEU","Bavaria","","16724","1276950984"
"Arıcak","Aricak","38.5644","40.1339","Turkey","TR","TUR","Elazığ","minor","17028","1792641593"
"Seltso","Seltso","53.3678","34.0978","Russia","RU","RUS","Bryanskaya Oblast’","","16554","1643772327"
"Khutauna","Khutauna","26.4969","86.3959","India","IN","IND","Bihār","","16197","1356005732"
"Sendurai","Sendurai","10.3934","78.2517","India","IN","IND","Tamil Nādu","","16728","1356395330"
"Zafra","Zafra","38.4167","-6.4167","Spain","ES","ESP","Extremadura","","16786","1724035487"
"Asten","Asten","51.4000","5.7500","Netherlands","NL","NLD","Noord-Brabant","minor","16817","1528132809"
"Vedelago","Vedelago","45.6833","12.0167","Italy","IT","ITA","Veneto","","16781","1380349363"
"Ettaiyāpuram","Ettaiyapuram","9.1474","77.9928","India","IN","IND","Tamil Nādu","","16888","1356783397"
"Sughrāin","Sughrain","25.7460","86.3250","India","IN","IND","Bihār","","16001","1356055718"
"Filiaşi","Filiasi","44.4000","23.5200","Romania","RO","ROU","Dolj","","16900","1642083363"
"Befotaka","Befotaka","-14.5333","48.0167","Madagascar","MG","MDG","Mahajanga","","17000","1450777604"
"Norwalk","Norwalk","41.2443","-82.6088","United States","US","USA","Ohio","","17048","1840000817"
"Pelitli","Pelitli","40.9833","39.8000","Turkey","TR","TUR","Trabzon","","15503","1792308015"
"Sint-Kruis","Sint-Kruis","51.2117","3.2500","Belgium","BE","BEL","Flanders","","15892","1056830939"
"Khed","Khed","17.7189","73.3969","India","IN","IND","Mahārāshtra","","16892","1356073622"
"San Miguelito","San Miguelito","11.4025","-84.9003","Nicaragua","NI","NIC","Río San Juan","minor","17031","1558918781"
"Dzitbalché","Dzitbalche","20.3167","-90.0500","Mexico","MX","MEX","Campeche","","17000","1484603562"
"Ambohitsimanova","Ambohitsimanova","-19.9500","47.1167","Madagascar","MG","MDG","Antananarivo","","17000","1450894938"
"Antsahavaribe","Antsahavaribe","-13.9833","49.6167","Madagascar","MG","MDG","Antsiranana","","17000","1450824727"
"Marofoty","Marofoty","-22.9333","43.5667","Madagascar","MG","MDG","Toliara","","17000","1450797893"
"Manombo Atsimo","Manombo Atsimo","-22.9500","43.4667","Madagascar","MG","MDG","Toliara","","17000","1450265584"
"Ambolidibe Atsinanana","Ambolidibe Atsinanana","-15.1000","48.8000","Madagascar","MG","MDG","Mahajanga","","17000","1450065794"
"Bykhaw","Bykhaw","53.5167","30.2500","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","17031","1112154662"
"Ashwaubenon","Ashwaubenon","44.4796","-88.0889","United States","US","USA","Wisconsin","","17042","1840002342"
"Antanimora Atsinanana","Antanimora Atsinanana","-24.8167","45.6667","Madagascar","MG","MDG","Toliara","","17000","1450178860"
"Lazarivo","Lazarivo","-23.9000","44.9500","Madagascar","MG","MDG","Toliara","","17000","1450663627"
"Etrotroka","Etrotroka","-22.8833","47.6000","Madagascar","MG","MDG","Fianarantsoa","","17000","1450566502"
"Seeheim-Jugenheim","Seeheim-Jugenheim","49.7667","8.6500","Germany","DE","DEU","Hesse","","16451","1276449114"
"Ambila","Ambila","-21.9833","47.9500","Madagascar","MG","MDG","Fianarantsoa","","17000","1450155551"
"Dongjiangshui","Dongjiangshui","33.3792","104.9516","China","CN","CHN","Gansu","","16816","1156537152"
"Dacaozhuang","Dacaozhuang","37.5546","114.9316","China","CN","CHN","Hebei","","16709","1156389817"
"Retiro","Retiro","6.0572","-75.5025","Colombia","CO","COL","Antioquia","minor","16974","1170702192"
"Valencia","Valencia","-0.9525","-79.3531","Ecuador","EC","ECU","Los Ríos","","16983","1218923469"
"Ankarongana","Ankarongana","-15.4167","48.9167","Madagascar","MG","MDG","Mahajanga","","17000","1450971281"
"Cernavodă","Cernavoda","44.3381","28.0336","Romania","RO","ROU","Ialomiţa","","17022","1642042708"
"Santa María","Santa Maria","-26.6833","-66.0333","Argentina","AR","ARG","Catamarca","minor","17030","1032055755"
"Andranofasika","Andranofasika","-16.3833","46.9000","Madagascar","MG","MDG","Mahajanga","","16971","1450575484"
"Costa Marques","Costa Marques","-12.4450","-64.2272","Brazil","BR","BRA","Rondônia","","17031","1076376222"
"Haslemere","Haslemere","51.0900","-0.7120","United Kingdom","GB","GBR","Surrey","","16826","1826519948"
"Liuchuan","Liuchuan","26.6549","108.5876","China","CN","CHN","Guizhou","","16950","1156013815"
"Marosangy","Marosangy","-21.0000","48.3000","Madagascar","MG","MDG","Fianarantsoa","","17000","1450773413"
"Guben","Guben","51.9533","14.7167","Germany","DE","DEU","Brandenburg","","16656","1276800396"
"Namysłów","Namyslow","51.0728","17.7069","Poland","PL","POL","Opolskie","minor","16942","1616674517"
"Lakhaura","Lakhaura","26.7522","84.9647","India","IN","IND","Bihār","","15164","1356784795"
"West Hempfield","West Hempfield","40.0564","-76.4632","United States","US","USA","Pennsylvania","","17028","1840152740"
"Montagu","Montagu","-33.7833","20.1167","South Africa","ZA","ZAF","Western Cape","","15176","1710489752"
"Batuco","Batuco","-33.2308","-70.8089","Chile","CL","CHL","Región Metropolitana","","16784","1152375054"
"Bytów","Bytow","54.1333","17.5000","Poland","PL","POL","Pomorskie","minor","16888","1616588946"
"Myrtle Grove","Myrtle Grove","30.4158","-87.3027","United States","US","USA","Florida","","17023","1840013917"
"Beahitse","Beahitse","-24.1667","44.4333","Madagascar","MG","MDG","Toliara","","17000","1450908001"
"Aḑ Ḑulū‘īyah","Ad Dulu\`iyah","34.0500","44.2000","Iraq","IQ","IRQ","Şalāḩ ad Dīn","","17000","1368869426"
"Scordia","Scordia","37.3000","14.8500","Italy","IT","ITA","Sicilia","","16919","1380710947"
"Swansea","Swansea","41.7571","-71.2120","United States","US","USA","Massachusetts","","17020","1840053663"
"Kompalle","Kompalle","17.4993","78.4583","India","IN","IND","Andhra Pradesh","","15575","1356654785"
"Rāmnagar","Ramnagar","26.0773","86.8387","India","IN","IND","Bihār","","16152","1356412859"
"Isoanala","Isoanala","-23.8333","45.7167","Madagascar","MG","MDG","Toliara","","17000","1450685823"
"Samarate","Samarate","45.6167","8.7833","Italy","IT","ITA","Lombardy","","16018","1380984900"
"Huntington","Huntington","40.8810","-85.5063","United States","US","USA","Indiana","","17018","1840007169"
"Rosário Oeste","Rosario Oeste","-14.8358","-56.4278","Brazil","BR","BRA","Mato Grosso","","17016","1076536709"
"Garagoa","Garagoa","5.0833","-73.3667","Colombia","CO","COL","Boyacá","minor","16944","1170105749"
"Middelfart","Middelfart","55.4986","9.7444","Denmark","DK","DNK","Syddanmark","minor","16277","1208896567"
"Lentate sul Seveso","Lentate sul Seveso","45.6784","9.1219","Italy","IT","ITA","Lombardy","","15878","1380948255"
"Marahōm","Marahom","33.8303","75.0832","India","IN","IND","Jammu and Kashmīr","","16015","1356971041"
"Liuguoju","Liuguoju","38.2571","110.3597","China","CN","CHN","Shaanxi","","16929","1156925791"
"Usgao","Usgao","15.4333","74.0667","India","IN","IND","Goa","","16354","1356114042"
"Kesath","Kesath","25.4208","84.2414","India","IN","IND","Bihār","","16390","1356645932"
"San Juan Lalana","San Juan Lalana","17.4667","-95.8833","Mexico","MX","MEX","Oaxaca","","16972","1484819599"
"Peñamiller","Penamiller","21.0519","-99.8150","Mexico","MX","MEX","Querétaro","minor","17007","1484158047"
"Rātan","Ratan","25.4349","86.6432","India","IN","IND","Bihār","","15259","1356256699"
"Aiuaba","Aiuaba","-6.5739","-40.1239","Brazil","BR","BRA","Ceará","","16997","1076600534"
"Springfield","Springfield","40.6994","-74.3254","United States","US","USA","New Jersey","","17004","1840056368"
"Lamas","Lamas","-6.4167","-76.5333","Peru","PE","PER","San Martín","","16871","1604035883"
"Jericoacoara","Jericoacoara","-2.7939","-40.5128","Brazil","BR","BRA","Ceará","","17002","1076587991"
"Ashmyany","Ashmyany","54.4250","25.9375","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","16979","1112383165"
"Kelheim","Kelheim","48.9167","11.8667","Germany","DE","DEU","Bavaria","minor","16779","1276123640"
"Guisborough","Guisborough","54.5350","-1.0563","United Kingdom","GB","GBR","Redcar and Cleveland","","16979","1826412979"
"Piriyāpatna","Piriyapatna","12.3365","76.0792","India","IN","IND","Karnātaka","","16685","1356018863"
"Chettināyakkanpatti","Chettinayakkanpatti","10.3940","77.9754","India","IN","IND","Tamil Nādu","","16354","1356249275"
"Bristol","Bristol","36.6181","-82.1604","United States","US","USA","Virginia","","16996","1840003879"
"Malta","Malta","42.9853","-73.7879","United States","US","USA","New York","","16996","1840058280"
"Steffisburg","Steffisburg","46.7667","7.6333","Switzerland","CH","CHE","Bern","","15816","1756440418"
"Osowa","Osowa","54.4272","18.4708","Poland","PL","POL","Pomorskie","","15843","1616417029"
"Guadalupe Victoria","Guadalupe Victoria","19.2833","-97.3333","Mexico","MX","MEX","Puebla","","16923","1484527511"
"Grangemouth","Grangemouth","56.0120","-3.7170","United Kingdom","GB","GBR","Falkirk","","16650","1826973593"
"Seara","Seara","-27.1489","-52.3108","Brazil","BR","BRA","Santa Catarina","","16936","1076737843"
"Bhainsoda","Bhainsoda","24.4427","75.8403","India","IN","IND","Madhya Pradesh","","16818","1356914068"
"Vári","Vari","37.8333","23.8000","Greece","GR","GRC","Attikí","","15855","1300867950"
"Areia Branca","Areia Branca","-10.7578","-37.3150","Brazil","BR","BRA","Sergipe","","16857","1076722272"
"Escoublac","Escoublac","47.2858","-2.3922","France","FR","FRA","Pays de la Loire","","16255","1250651907"
"Asthānwān","Asthanwan","25.2215","85.6262","India","IN","IND","Bihār","","15486","1356091403"
"Sonāpur","Sonapur","25.3561","86.2082","India","IN","IND","Bihār","","15746","1356303493"
"Cessnock","Cessnock","-32.8342","151.3555","Australia","AU","AUS","New South Wales","","15177","1036822046"
"Belle Glade","Belle Glade","26.6916","-80.6656","United States","US","USA","Florida","","16983","1840014206"
"Middletown","Middletown","41.5175","-71.2769","United States","US","USA","Rhode Island","","16983","1840066121"
"Mattoon","Mattoon","39.4774","-88.3623","United States","US","USA","Illinois","","16982","1840008449"
"Aklanpa","Aklanpa","8.1684","2.2320","Benin","BJ","BEN","Collines","","16895","1204854535"
"Giaveno","Giaveno","45.0420","7.3520","Italy","IT","ITA","Piedmont","","16747","1380065100"
"Azeffoun","Azeffoun","36.8961","4.4204","Algeria","DZ","DZA","Tizi Ouzou","","16847","1012290725"
"Belaur","Belaur","25.4477","84.6473","India","IN","IND","Bihār","","15789","1356591775"
"Jilotepec","Jilotepec","19.6113","-96.9224","Mexico","MX","MEX","Veracruz","minor","16682","1484009524"
"Reinheim","Reinheim","49.8269","8.8308","Germany","DE","DEU","Hesse","","16385","1276510132"
"Mugumu","Mugumu","-1.8333","34.6667","Tanzania","TZ","TZA","Mara","","16851","1834097845"
"Abington","Abington","42.1180","-70.9590","United States","US","USA","Massachusetts","","16974","1840053564"
"Tourlaville","Tourlaville","49.6408","-1.5789","France","FR","FRA","Normandie","","16040","1250228136"
"Erumād","Erumad","11.5681","76.2608","India","IN","IND","Tamil Nādu","","16678","1356597128"
"Loyalist","Loyalist","44.2500","-76.7500","Canada","CA","CAN","Ontario","","16971","1124001145"
"Santa Teresa","Santa Teresa","11.8039","-86.1639","Nicaragua","NI","NIC","Carazo","","16891","1558632681"
"Heumen","Heumen","51.7833","5.8500","Netherlands","NL","NLD","Gelderland","","16569","1528375615"
"Khirpai","Khirpai","22.7110","87.6174","India","IN","IND","West Bengal","","16384","1356147739"
"Flowing Wells","Flowing Wells","32.2937","-111.0110","United States","US","USA","Arizona","","16965","1840027988"
"Ambohimanambola","Ambohimanambola","-18.9500","47.6000","Madagascar","MG","MDG","Antananarivo","","16418","1450250431"
"Northallerton","Northallerton","54.3378","-1.4285","United Kingdom","GB","GBR","North Yorkshire","","15741","1826697671"
"Domchānch","Domchanch","24.4748","85.6921","India","IN","IND","Jhārkhand","","16552","1356974592"
"Ripon","Ripon","54.1380","-1.5240","United Kingdom","GB","GBR","North Yorkshire","","16274","1826726048"
"Kalundborg","Kalundborg","55.6814","11.0850","Denmark","DK","DNK","Sjælland","minor","16490","1208716084"
"Belo","Belo","25.8747","86.8855","India","IN","IND","Bihār","","15576","1356552062"
"Marion","Marion","37.7345","-88.9420","United States","US","USA","Illinois","","16960","1840008712"
"Tobré","Tobre","10.2000","2.1333","Benin","BJ","BEN","Atacora","","16912","1204523231"
"Acoyapa","Acoyapa","11.9708","-85.1719","Nicaragua","NI","NIC","Chontales","minor","16946","1558571997"
"Terra Santa","Terra Santa","-2.1039","-56.4869","Brazil","BR","BRA","Pará","","16949","1076655629"
"La Rinconada","La Rinconada","-14.6325","-69.4458","Peru","PE","PER","Puno","","16907","1604001824"
"Mátészalka","Mateszalka","47.9500","22.3167","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","16576","1348758097"
"Sarıoğlan","Sarioglan","39.0833","35.9833","Turkey","TR","TUR","Kayseri","minor","16938","1792533162"
"La Sierpe","La Sierpe","21.7606","-79.2433","Cuba","CU","CUB","Sancti Spíritus","minor","16937","1192088236"
"Riemst","Riemst","50.8089","5.6019","Belgium","BE","BEL","Flanders","","16665","1056578404"
"San Jacinto Amilpas","San Jacinto Amilpas","17.1006","-96.7625","Mexico","MX","MEX","Oaxaca","","15720","1484813830"
"Cêrro Azul","Cerro Azul","-24.8239","-49.2608","Brazil","BR","BRA","Paraná","","16938","1076319045"
"Pran Buri","Pran Buri","12.3939","99.9159","Thailand","TH","THA","Prachuap Khiri Khan","minor","16851","1764353337"
"Bek’ojī","Bek'oji","7.5833","39.3333","Ethiopia","ET","ETH","Oromīya","","16730","1231921614"
"Krapkowice","Krapkowice","50.4667","17.9667","Poland","PL","POL","Opolskie","minor","16721","1616664766"
"Live Oak","Live Oak","36.9860","-121.9804","United States","US","USA","California","","16948","1840144061"
"Fetromby","Fetromby","-18.5833","48.9167","Madagascar","MG","MDG","Toamasina","","16894","1450802139"
"Kollūru","Kolluru","16.1833","80.8000","India","IN","IND","Andhra Pradesh","","16079","1356157661"
"Vadugappatti","Vadugappatti","10.1036","77.5731","India","IN","IND","Tamil Nādu","","16337","1356228731"
"Damascus","Damascus","39.2701","-77.1957","United States","US","USA","Maryland","","16945","1840005832"
"Vatutine","Vatutine","49.0167","31.0667","Ukraine","UA","UKR","Cherkaska Oblast","","16888","1804033648"
"Telua","Telua","26.6396","84.5108","India","IN","IND","Bihār","","15143","1356133939"
"América Dourada","America Dourada","-11.4550","-41.4358","Brazil","BR","BRA","Bahia","","16923","1076121763"
"San José de Chiquitos","San Jose de Chiquitos","-17.8500","-60.7500","Bolivia","BO","BOL","Santa Cruz","","16942","1068082794"
"Siloam Springs","Siloam Springs","36.1844","-94.5315","United States","US","USA","Arkansas","","16944","1840015288"
"Fındıklı","Findikli","41.1333","41.0167","Turkey","TR","TUR","Rize","minor","16902","1792497318"
"Ban Thung Tam Sao","Ban Thung Tam Sao","6.9581","100.3207","Thailand","TH","THA","Songkhla","","16344","1764139945"
"Mohanūr","Mohanur","11.0594","78.1397","India","IN","IND","Tamil Nādu","","16457","1356214256"
"Bad Wörishofen","Bad Worishofen","48.0058","10.5969","Germany","DE","DEU","Bavaria","","16648","1276068961"
"Dēra","Dera","8.3333","39.3167","Ethiopia","ET","ETH","Oromīya","","16731","1231378383"
"Tubaran","Tubaran","7.7167","124.1667","Philippines","PH","PHL","Lanao del Sur","","16896","1608579261"
"Iziaslav","Iziaslav","50.1167","26.8000","Ukraine","UA","UKR","Khmelnytska Oblast","","16901","1804060657"
"Bellingham","Bellingham","42.0777","-71.4741","United States","US","USA","Massachusetts","","16934","1840053544"
"Shelbyville","Shelbyville","38.2067","-85.2292","United States","US","USA","Kentucky","","16932","1840015205"
"Neratovice","Neratovice","50.2593","14.5177","Czechia","CZ","CZE","Středočeský Kraj","","16138","1203620692"
"Westervoort","Westervoort","51.9667","5.9667","Netherlands","NL","NLD","Gelderland","minor","15014","1528918651"
"Kaukauna","Kaukauna","44.2773","-88.2641","United States","US","USA","Wisconsin","","16929","1840002403"
"Dormentes","Dormentes","-8.4469","-40.7708","Brazil","BR","BRA","Pernambuco","","16917","1076159651"
"Hennebont","Hennebont","47.8042","-3.2789","France","FR","FRA","Bretagne","","16062","1250878648"
"Severínia","Severinia","-20.8089","-48.8028","Brazil","BR","BRA","São Paulo","","16806","1076968616"
"Turhāpatti","Turhapatti","26.8511","84.4522","India","IN","IND","Bihār","","15455","1356428490"
"Agrate Brianza","Agrate Brianza","45.5833","9.3500","Italy","IT","ITA","Lombardy","","15540","1380660199"
"Bogatynia","Bogatynia","50.9069","14.9569","Poland","PL","POL","Dolnośląskie","","16647","1616146165"
"Ratba","Ratba","34.7833","-4.9333","Morocco","MA","MAR","Fès-Meknès","","16825","1504991654"
"Kodala","Kodala","19.6243","84.9408","India","IN","IND","Odisha","","16295","1356028415"
"Río Bueno","Rio Bueno","-40.3167","-72.9667","Chile","CL","CHL","Los Ríos","","16909","1152938619"
"Beldānga","Beldanga","24.8106","87.8864","India","IN","IND","Jhārkhand","","15265","1356119081"
"Dehāqān","Dehaqan","31.9400","51.6478","Iran","IR","IRN","Eşfahān","minor","16899","1364614039"
"Sāhpur","Sahpur","25.7035","85.1483","India","IN","IND","Bihār","","15273","1356140305"
"Shahbā","Shahba","32.8542","36.6292","Syria","SY","SYR","As Suwaydā’","minor","16745","1760158993"
"Calhoun","Calhoun","34.4910","-84.9386","United States","US","USA","Georgia","","16920","1840013596"
"Takhatgarh","Takhatgarh","25.3300","73.0000","India","IN","IND","Rājasthān","","16729","1356513228"
"West Haven","West Haven","41.2083","-112.0541","United States","US","USA","Utah","","16918","1840021344"
"Cinnaminson","Cinnaminson","40.0008","-74.9930","United States","US","USA","New Jersey","","16915","1840081627"
"Tsundupalle","Tsundupalle","13.9809","78.9152","India","IN","IND","Andhra Pradesh","","16779","1356039781"
"São Caetano de Odivelas","Sao Caetano de Odivelas","-0.7500","-48.0200","Brazil","BR","BRA","Pará","","16891","1076720948"
"Easton","Easton","38.7760","-76.0701","United States","US","USA","Maryland","","16912","1840006089"
"Dilijan","Dilijan","40.7408","44.8631","Armenia","AM","ARM","Tavush","","16600","1051278149"
"Japaratuba","Japaratuba","-10.5928","-36.9400","Brazil","BR","BRA","Sergipe","","16864","1076479545"
"Upper Gwynedd","Upper Gwynedd","40.2144","-75.2891","United States","US","USA","Pennsylvania","","16910","1840152390"
"Farmingville","Farmingville","40.8390","-73.0404","United States","US","USA","New York","","16909","1840005009"
"Zhukovka","Zhukovka","53.5333","33.7333","Russia","RU","RUS","Bryanskaya Oblast’","","16878","1643737188"
"Maşīf Sarsink","Masif Sarsink","37.0333","43.3333","Iraq","IQ","IRQ","Dahūk","","16766","1368000421"
"Morteros","Morteros","-30.7000","-62.0000","Argentina","AR","ARG","Córdoba","","16890","1032224912"
"Bürstadt","Burstadt","49.6333","8.4500","Germany","DE","DEU","Hesse","","16430","1276454378"
"Kumage","Kumage","34.0495","131.9690","Japan","JP","JPN","Yamaguchi","","16666","1392003369"
"Ōyodo","Oyodo","34.3906","135.7897","Japan","JP","JPN","Nara","","16469","1392003294"
"Katsuura","Katsuura","35.1500","140.3167","Japan","JP","JPN","Chiba","","16723","1392044206"
"Tichi","Tichi","36.6675","5.1601","Algeria","DZ","DZA","Bejaïa","","16546","1012798792"
"Trzcianka","Trzcianka","53.0500","16.4667","Poland","PL","POL","Wielkopolskie","","16831","1616300801"
"São Pedro do Sul","Sao Pedro do Sul","40.7500","-8.0667","Portugal","PT","PRT","Viseu","minor","16851","1620666925"
"Alfajayucan","Alfajayucan","20.4000","-99.3500","Mexico","MX","MEX","Hidalgo","","16859","1484991912"
"Ichhāwar","Ichhawar","23.2278","77.2666","India","IN","IND","Madhya Pradesh","","16749","1356125192"
"Vincennes","Vincennes","38.6759","-87.5102","United States","US","USA","Indiana","","16892","1840010730"
"Hajdúnánás","Hajdunanas","47.8500","21.4333","Hungary","HU","HUN","Hajdú-Bihar","minor","16828","1348235609"
"Mitrapur","Mitrapur","24.4371","87.9666","India","IN","IND","West Bengal","","15452","1356115899"
"Tongluo","Tongluo","24.4833","120.7833","Taiwan","TW","TWN","Miaoli","","16676","1158194331"
"Montbrison","Montbrison","45.6075","4.0653","France","FR","FRA","Auvergne-Rhône-Alpes","minor","15915","1250924928"
"Alto Santo","Alto Santo","-5.5208","-38.2719","Brazil","BR","BRA","Ceará","","16876","1076376148"
"Bonito","Bonito","-11.9658","-41.2669","Brazil","BR","BRA","Bahia","","16884","1076286351"
"Hünfeld","Hunfeld","50.6667","9.7667","Germany","DE","DEU","Hesse","","16748","1276813919"
"’Aïn Abessa","'Ain Abessa","36.3000","5.2950","Algeria","DZ","DZA","Sétif","","16770","1012074116"
"Roxana","Roxana","10.3586","-83.6738","Costa Rica","CR","CRI","Limón","","16790","1188274720"
"Mount Vernon","Mount Vernon","40.3854","-82.4737","United States","US","USA","Ohio","","16885","1840001343"
"Posoltega","Posoltega","12.5447","-86.9792","Nicaragua","NI","NIC","Chinandega","minor","16771","1558561951"
"Janakkala","Janakkala","60.9167","24.6500","Finland","FI","FIN","Kanta-Häme","","16853","1246767263"
"Bridgeview","Bridgeview","41.7403","-87.8067","United States","US","USA","Illinois","","16883","1840010140"
"Marbach am Neckar","Marbach am Neckar","48.9333","9.2500","Germany","DE","DEU","Baden-Württemberg","","16008","1276176133"
"La Quiaca","La Quiaca","-22.1042","-65.5972","Argentina","AR","ARG","Jujuy","minor","16874","1032628832"
"Tāwargeri","Tawargeri","15.7667","76.5333","India","IN","IND","Karnātaka","","16647","1356671680"
"Leinì","Leini","45.1846","7.7133","Italy","IT","ITA","Piedmont","","16375","1380991388"
"Stará Ľubovňa","Stara L'ubovna","49.3094","20.6789","Slovakia","SK","SVK","Prešov","minor","16348","1703928081"
"Sobrado de Paiva","Sobrado de Paiva","41.0333","-8.2667","Portugal","PT","PRT","Aveiro","","16733","1620213325"
"Hohenems","Hohenems","47.3667","9.6667","Austria","AT","AUT","Vorarlberg","","16317","1040845678"
"Medicina","Medicina","44.4833","11.6333","Italy","IT","ITA","Emilia-Romagna","","16768","1380077398"
"Khaniādhāna","Khaniadhana","25.0298","78.1271","India","IN","IND","Madhya Pradesh","","16605","1356038073"
"Bina","Bina","26.0792","86.6326","India","IN","IND","Bihār","","15079","1356257236"
"Meiti","Meiti","24.3517","94.3422","India","IN","IND","Manipur","","16847","1356279278"
"Kajur","Kajur","24.8768","85.2920","India","IN","IND","Bihār","","16104","1356236484"
"Boysun","Boysun","38.2000","67.2000","Uzbekistan","UZ","UZB","Surxondaryo","","16732","1860052863"
"Boden","Boden","65.8256","21.6906","Sweden","SE","SWE","Norrbotten","minor","16847","1752956055"
"Quepem","Quepem","15.2200","74.0700","India","IN","IND","Goa","","16596","1356045767"
"Midland","Midland","44.7500","-79.8833","Canada","CA","CAN","Ontario","","16864","1124104490"
"Villa Rica","Villa Rica","33.7294","-84.9177","United States","US","USA","Georgia","","16863","1840015679"
"Upper Saucon","Upper Saucon","40.5364","-75.4084","United States","US","USA","Pennsylvania","","16863","1840146376"
"Este","Este","45.2333","11.6667","Italy","IT","ITA","Veneto","","16364","1380163207"
"Jāsk","Jask","25.6439","57.7744","Iran","IR","IRN","Hormozgān","minor","16860","1364208450"
"Pendleton","Pendleton","45.6757","-118.8201","United States","US","USA","Oregon","","16861","1840019917"
"Sin-le-Noble","Sin-le-Noble","50.3631","3.1131","France","FR","FRA","Hauts-de-France","","15522","1250652964"
"Belaya Glina","Belaya Glina","46.0833","40.8583","Russia","RU","RUS","Krasnodarskiy Kray","minor","16838","1643712602"
"El Escorial","El Escorial","40.5817","-4.1258","Spain","ES","ESP","Madrid","","16618","1724408477"
"Meghauna","Meghauna","25.6904","86.3809","India","IN","IND","Bihār","","15506","1356322048"
"Colwood","Colwood","48.4236","-123.4958","Canada","CA","CAN","British Columbia","","16859","1124000395"
"Sinūnī","Sinuni","36.4575","41.7061","Iraq","IQ","IRQ","Nīnawá","","16798","1368606100"
"Leopoldshöhe","Leopoldshohe","52.0167","8.7000","Germany","DE","DEU","North Rhine-Westphalia","","16413","1276147484"
"Bella Vista","Bella Vista","-22.1167","-56.5167","Paraguay","PY","PRY","Amambay","","16852","1600430147"
"Wixom","Wixom","42.5243","-83.5346","United States","US","USA","Michigan","","16853","1840000407"
"Diksmuide","Diksmuide","51.0333","2.8667","Belgium","BE","BEL","Flanders","minor","16739","1056661894"
"Chinampa de Gorostiza","Chinampa de Gorostiza","21.3667","-97.7333","Mexico","MX","MEX","Veracruz","minor","16741","1484006654"
"Truckee","Truckee","39.3455","-120.1848","United States","US","USA","California","","16850","1840022462"
"Periyakoduveri","Periyakoduveri","11.4811","77.2989","India","IN","IND","Tamil Nādu","","16374","1356163346"
"Tejuçuoca","Tejucuoca","-3.9889","-39.5808","Brazil","BR","BRA","Ceará","","16827","1076859044"
"Ouaouzgane","Ouaouzgane","35.0167","-4.5167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16772","1504425512"
"Bandora","Bandora","15.4082","73.9813","India","IN","IND","Goa","","16192","1356770456"
"Locarno","Locarno","46.1667","8.8000","Switzerland","CH","CHE","Ticino","","16012","1756696211"
"Vallegrande","Vallegrande","-18.4833","-64.1000","Bolivia","BO","BOL","Santa Cruz","","16837","1068162149"
"Sāhar","Sahar","26.5408","85.8590","India","IN","IND","Bihār","","15711","1356475122"
"Aberdeen","Aberdeen","46.9757","-123.8094","United States","US","USA","Washington","","16842","1840018446"
"Lille","Lille","51.2333","4.8333","Belgium","BE","BEL","Flanders","","16564","1056651207"
"Libiąż","Libiaz","50.1000","19.3167","Poland","PL","POL","Małopolskie","","16385","1616724482"
"Saalfelden am Steinernen Meer","Saalfelden am Steinernen Meer","47.4269","12.8483","Austria","AT","AUT","Salzburg","","16700","1040716716"
"Monesiglio","Monesiglio","44.4667","8.1167","Italy","IT","ITA","Piedmont","","16631","1380000103"
"Beinan","Beinan","22.7833","121.1167","Taiwan","TW","TWN","Taitung","","16798","1158487727"
"Grecia","Grecia","10.0693","-84.3136","Costa Rica","CR","CRI","Alajuela","","14859","1188985866"
"Westchester","Westchester","41.8492","-87.8906","United States","US","USA","Illinois","","16837","1840011332"
"Strzegom","Strzegom","50.9611","16.3444","Poland","PL","POL","Dolnośląskie","","16650","1616613189"
"Panajachel","Panajachel","14.7361","-91.1558","Guatemala","GT","GTM","Sololá","minor","16101","1320143050"
"Campo do Brito","Campo do Brito","-10.7328","-37.4928","Brazil","BR","BRA","Sergipe","","16749","1076269886"
"Iglino","Iglino","54.8385","56.4232","Russia","RU","RUS","Bashkortostan","","16811","1643038194"
"Sudak","Sudak","44.8514","34.9725","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","16784","1804728325"
"Fuying","Fuying","40.8754","117.6978","China","CN","CHN","Hebei","","16717","1156085305"
"Bagnacavallo","Bagnacavallo","44.4167","11.9833","Italy","IT","ITA","Emilia-Romagna","","16780","1380211099"
"Nässjö","Nassjo","57.6500","14.6833","Sweden","SE","SWE","Jönköping","minor","16678","1752891199"
"Sidi Lamine","Sidi Lamine","32.9000","-6.0500","Morocco","MA","MAR","Béni Mellal-Khénifra","","16808","1504670404"
"Pindaí","Pindai","-14.4928","-42.6869","Brazil","BR","BRA","Bahia","","16805","1076864570"
"Câmpulung Moldovenesc","Campulung Moldovenesc","47.5308","25.5514","Romania","RO","ROU","Suceava","","16722","1642825057"
"Auburn","Auburn","42.1972","-71.8453","United States","US","USA","Massachusetts","","16826","1840053684"
"Jiblah","Jiblah","13.9167","44.1500","Yemen","YE","YEM","Ibb","minor","15431","1887050093"
"Cayetano Germosén","Cayetano Germosen","19.3300","-70.4800","Dominican Republic","DO","DOM","Cibao Norte","","16439","1214204194"
"Salūmbar","Salumbar","24.0800","74.0200","India","IN","IND","Rājasthān","","16425","1356580244"
"Itariri","Itariri","-24.2888","-47.1332","Brazil","BR","BRA","São Paulo","","16759","1076020953"
"Bethlehem","Bethlehem","-28.2240","28.3110","South Africa","ZA","ZAF","Free State","","16236","1710750307"
"Madison","Madison","40.7586","-74.4169","United States","US","USA","New Jersey","","16819","1840003574"
"Tromsdalen","Tromsdalen","69.6442","18.9992","Norway","NO","NOR","Troms og Finnmark","","16787","1578517782"
"Saidpur","Saidpur","25.5436","86.5640","India","IN","IND","Bihār","","15008","1356110200"
"Pampa","Pampa","35.5479","-100.9651","United States","US","USA","Texas","","16817","1840022939"
"Altıntaş","Altintas","39.0615","30.1099","Turkey","TR","TUR","Kütahya","minor","16803","1792043886"
"Ocho Rios","Ocho Rios","18.4167","-77.1167","Jamaica","JM","JAM","Saint Ann","","16671","1388472214"
"Wurzen","Wurzen","51.3667","12.7167","Germany","DE","DEU","Saxony","","16575","1276002805"
"Pedra Preta","Pedra Preta","-16.6228","-54.4739","Brazil","BR","BRA","Mato Grosso","","16811","1076000176"
"Central Saanich","Central Saanich","48.5142","-123.3839","Canada","CA","CAN","British Columbia","","16814","1124000519"
"La Paz","La Paz","17.6739","120.6847","Philippines","PH","PHL","Abra","","16493","1608998670"
"Ban Nong Han","Ban Nong Han","18.9000","99.0000","Thailand","TH","THA","Chiang Mai","","16463","1764321776"
"Buford","Buford","34.1192","-83.9903","United States","US","USA","Georgia","","16811","1840013678"
"Yağlıdere","Yaglidere","40.9000","38.4167","Turkey","TR","TUR","Giresun","minor","16758","1792741679"
"Říčany","Ricany","49.9917","14.6543","Czechia","CZ","CZE","Středočeský Kraj","","16182","1203639839"
"Daireaux","Daireaux","-36.6000","-61.7500","Argentina","AR","ARG","Buenos Aires","minor","16804","1032067440"
"Patilār","Patilar","27.0361","84.2106","India","IN","IND","Bihār","","15491","1356081104"
"Port Washington","Port Washington","40.8268","-73.6765","United States","US","USA","New York","","16807","1840005252"
"Buntok","Buntok","-1.7190","114.8448","Indonesia","ID","IDN","Kalimantan Tengah","minor","16774","1360010323"
"Upper Chichester","Upper Chichester","39.8414","-75.4421","United States","US","USA","Pennsylvania","","16806","1840142584"
"Ostrov","Ostrov","50.3060","12.9392","Czechia","CZ","CZE","Karlovarský Kraj","","16491","1203022506"
"Rājāpur","Rajapur","26.2861","83.1306","India","IN","IND","Uttar Pradesh","","15632","1356737116"
"Sumé","Sume","-7.6719","-36.8800","Brazil","BR","BRA","Paraíba","","16784","1076446813"
"Ada","Ada","34.7662","-96.6681","United States","US","USA","Oklahoma","","16804","1840019191"
"San Roque","San Roque","6.4853","-75.0197","Colombia","CO","COL","Antioquia","minor","16789","1170674966"
"Ekma","Ekma","25.9670","84.5383","India","IN","IND","Bihār","","15048","1356937137"
"Simaria","Simaria","24.7950","81.1520","India","IN","IND","Madhya Pradesh","","16269","1356816759"
"Kushima","Kushima","31.4681","131.2467","Japan","JP","JPN","Miyazaki","","16744","1392989065"
"Lonigo","Lonigo","45.3833","11.3833","Italy","IT","ITA","Veneto","","16466","1380114554"
"Kauhava","Kauhava","63.1000","23.0667","Finland","FI","FIN","Etelä-Pohjanmaa","minor","16784","1246180633"
"Vendôme","Vendome","47.7928","1.0656","France","FR","FRA","Centre-Val de Loire","minor","16133","1250674773"
"Mikumi","Mikumi","-7.4072","36.9722","Tanzania","TZ","TZA","Morogoro","","16780","1834405632"
"Klippansbruk","Klippansbruk","56.1167","13.1500","Sweden","SE","SWE","Skåne","","16715","1752885132"
"Graham","Graham","36.0589","-79.3894","United States","US","USA","North Carolina","","16793","1840013361"
"Anna","Anna","33.3472","-96.5508","United States","US","USA","Texas","","16792","1840019397"
"Carnaubal","Carnaubal","-4.1669","-40.9428","Brazil","BR","BRA","Ceará","","16746","1076105679"
"Adjahomé","Adjahome","7.0618","1.8368","Benin","BJ","BEN","Couffo","","16421","1204465355"
"East Greenbush","East Greenbush","42.6122","-73.6969","United States","US","USA","New York","","16788","1840058060"
"Großostheim","Grossostheim","49.9167","9.0833","Germany","DE","DEU","Bavaria","","16419","1276880614"
"Laconia","Laconia","43.5725","-71.4775","United States","US","USA","New Hampshire","","16786","1840002683"
"La Vista","La Vista","41.1816","-96.0664","United States","US","USA","Nebraska","","16786","1840008263"
"Storrs","Storrs","41.8083","-72.2514","United States","US","USA","Connecticut","","16784","1840003276"
"El Piñón","El Pinon","10.4039","-74.8231","Colombia","CO","COL","Magdalena","minor","16752","1170478027"
"Jurāwanpur Karāri","Jurawanpur Karari","25.5273","85.4182","India","IN","IND","Bihār","","15612","1356203688"
"Micoud","Micoud","13.8190","-60.9000","Saint Lucia","LC","LCA","Micoud","","16620","1662284739"
"Terra Roxa d’Oeste","Terra Roxa d'Oeste","-24.1569","-54.0969","Brazil","BR","BRA","Paraná","","16759","1076869704"
"Cabeceiras de Basto","Cabeceiras de Basto","41.5333","-8.0167","Portugal","PT","PRT","Braga","minor","16710","1620118213"
"Aue","Aue","50.5853","12.7008","Germany","DE","DEU","Saxony","","16012","1276459690"
"Liangyi","Liangyi","35.2698","106.0930","China","CN","CHN","Gansu","","16529","1156209978"
"Ban Wang Nok Aen","Ban Wang Nok Aen","16.8333","100.4833","Thailand","TH","THA","Phitsanulok","","16689","1764425126"
"Islāmpur","Islampur","24.1513","88.4671","India","IN","IND","West Bengal","","15019","1356728986"
"Kawagoe","Kawagoe","35.0167","136.6667","Japan","JP","JPN","Mie","","15048","1392003468"
"Madrid","Madrid","9.2619","125.9647","Philippines","PH","PHL","Surigao del Sur","","16653","1608854529"
"Vodil","Vodil","40.1789","71.7297","Uzbekistan","UZ","UZB","Farg‘ona","minor","16200","1860396622"
"Tirmalgiri","Tirmalgiri","17.4746","79.4361","India","IN","IND","Andhra Pradesh","","16457","1356041789"
"Kaiwen","Kaiwen","27.1548","99.8371","China","CN","CHN","Yunnan","minor","16738","1156000909"
"Kudatini","Kudatini","15.1500","76.9200","India","IN","IND","Karnātaka","","16166","1356240727"
"Ceadîr-Lunga","Ceadir-Lunga","46.0500","28.8333","Moldova","MD","MDA","Găgăuzia","","16605","1498057941"
"Katosi","Katosi","0.1528","32.8014","Uganda","UG","UGA","Mukono","","16442","1800190825"
"Sūrappalli","Surappalli","11.7187","77.8778","India","IN","IND","Tamil Nādu","","16102","1356168794"
"Shintomi","Shintomi","32.0689","131.4880","Japan","JP","JPN","Miyazaki","","16495","1392003250"
"Aston","Aston","39.8719","-75.4349","United States","US","USA","Pennsylvania","","16762","1840150553"
"Sainte-Catherine","Sainte-Catherine","45.4000","-73.5800","Canada","CA","CAN","Quebec","","16762","1124941451"
"Bhagwāngola","Bhagwangola","24.3485","88.3243","India","IN","IND","West Bengal","","15587","1356116155"
"Potirendaba","Potirendaba","-21.0428","-49.3769","Brazil","BR","BRA","São Paulo","","16709","1076838060"
"Chorfa","Chorfa","36.3617","4.3307","Algeria","DZ","DZA","Bouira","","16173","1012035343"
"Illzach","Illzach","47.7822","7.3481","France","FR","FRA","Grand Est","","14784","1250260364"
"Pozos","Pozos","9.9536","-84.1926","Costa Rica","CR","CRI","San José","","15585","1188000058"
"Port Hope","Port Hope","43.9500","-78.3000","Canada","CA","CAN","Ontario","","16753","1124105292"
"Ayyampālaiyam","Ayyampalaiyam","10.2253","77.7485","India","IN","IND","Tamil Nādu","","16055","1356840994"
"Cerea","Cerea","45.2000","11.2167","Italy","IT","ITA","Veneto","","16517","1380206980"
"Dhusar Tikāpatti","Dhusar Tikapatti","25.5214","87.2212","India","IN","IND","Bihār","","15829","1356095493"
"Moraga","Moraga","37.8439","-122.1225","United States","US","USA","California","","16750","1840022538"
"Caravaggio","Caravaggio","45.4978","9.6431","Italy","IT","ITA","Lombardy","","16261","1380893805"
"Machelen","Machelen","50.9103","4.4367","Belgium","BE","BEL","Flanders","","15417","1056477677"
"Ervādi","Ervadi","9.2500","78.8510","India","IN","IND","Tamil Nādu","","16482","1356205536"
"Bad Lippspringe","Bad Lippspringe","51.7833","8.8167","Germany","DE","DEU","North Rhine-Westphalia","","16424","1276944431"
"Country Club Hills","Country Club Hills","41.5636","-87.7251","United States","US","USA","Illinois","","16746","1840007025"
"Wyckoff","Wyckoff","40.9989","-74.1676","United States","US","USA","New Jersey","","16746","1840081803"
"Douar Tabouda","Douar Tabouda","34.7167","-5.1333","Morocco","MA","MAR","Fès-Meknès","","16618","1504107473"
"Yerbas Buenas","Yerbas Buenas","-35.7500","-71.5667","Chile","CL","CHL","Maule","","16738","1152448482"
"Turgutreis","Turgutreis","37.0167","27.2667","Turkey","TR","TUR","Muğla","minor","16490","1792333566"
"Fort Carson","Fort Carson","38.7095","-104.7720","United States","US","USA","Colorado","","16743","1840028557"
"Donna","Donna","26.1468","-98.0559","United States","US","USA","Texas","","16743","1840019734"
"Ponte de Sôr","Ponte de Sor","39.2500","-8.0167","Portugal","PT","PRT","Portalegre","minor","16722","1620028457"
"Seacombe","Seacombe","53.4090","-3.0290","United Kingdom","GB","GBR","Wirral","","15387","1826216139"
"La Cruz","La Cruz","1.6047","-76.9742","Colombia","CO","COL","Nariño","minor","16674","1170176423"
"Novomichurinsk","Novomichurinsk","54.0500","39.7167","Russia","RU","RUS","Ryazanskaya Oblast’","","16710","1643380709"
"Merchtem","Merchtem","50.9667","4.2333","Belgium","BE","BEL","Flanders","","16294","1056962343"
"Saint-Basile-le-Grand","Saint-Basile-le-Grand","45.5333","-73.2833","Canada","CA","CAN","Quebec","","16736","1124000968"
"Mariestad","Mariestad","58.7000","13.8167","Sweden","SE","SWE","Västra Götaland","minor","16611","1752879298"
"Povorino","Povorino","51.2000","42.2500","Russia","RU","RUS","Voronezhskaya Oblast’","","16702","1643533014"
"San Juan","San Juan","9.1590","123.4940","Philippines","PH","PHL","Siquijor","","16363","1608471746"
"Murehwa","Murehwa","-17.6500","31.7833","Zimbabwe","ZW","ZWE","Mashonaland East","","16666","1716334846"
"Al Mazār ash Shamālī","Al Mazar ash Shamali","32.4725","35.7928","Jordan","JO","JOR","Irbid","minor","16000","1400714744"
"Seymour","Seymour","41.3810","-73.0873","United States","US","USA","Connecticut","","16731","1840035570"
"Rygge","Rygge","59.3747","10.7147","Norway","NO","NOR","Viken","minor","16145","1578903321"
"Hernando","Hernando","34.8500","-89.9921","United States","US","USA","Mississippi","","16730","1840014642"
"Anamorós","Anamoros","13.7333","-87.8667","El Salvador","SV","SLV","La Unión","","16594","1222875440"
"Francheville","Francheville","45.7364","4.7636","France","FR","FRA","Auvergne-Rhône-Alpes","","14906","1250555439"
"Ukiah","Ukiah","39.1464","-123.2105","United States","US","USA","California","","16728","1840021420"
"Sidi Ettiji","Sidi Ettiji","32.1717","-8.8686","Morocco","MA","MAR","Marrakech-Safi","","16649","1504984837"
"Khirbat Ghazālah","Khirbat Ghazalah","32.7333","36.2000","Syria","SY","SYR","Dar‘ā","minor","16240","1760127200"
"Monsenhor Tabosa","Monsenhor Tabosa","-4.7889","-40.0628","Brazil","BR","BRA","Ceará","","16705","1076361501"
"Chestnuthill","Chestnuthill","40.9568","-75.4183","United States","US","USA","Pennsylvania","","16723","1840145216"
"Sunagawa","Sunagawa","43.4948","141.9035","Japan","JP","JPN","Hokkaidō","","16513","1392198315"
"Madhuban","Madhuban","26.4386","85.1357","India","IN","IND","Bihār","","14843","1356095720"
"Menomonie","Menomonie","44.8893","-91.9084","United States","US","USA","Wisconsin","","16721","1840037988"
"Uherský Brod","Uhersky Brod","49.0251","17.6472","Czechia","CZ","CZE","Zlínský Kraj","","16409","1203839661"
"Zirara","Zirara","32.3500","-8.5333","Morocco","MA","MAR","Casablanca-Settat","","16606","1504681558"
"Colle Salvetti","Colle Salvetti","43.6000","10.4833","Italy","IT","ITA","Tuscany","","16693","1380386926"
"Porto Empedocle","Porto Empedocle","37.2944","13.5272","Italy","IT","ITA","Sicilia","","16701","1380764645"
"Gālivedu","Galivedu","14.0333","78.5000","India","IN","IND","Andhra Pradesh","","16344","1356039134"
"Capurso","Capurso","41.0500","16.9167","Italy","IT","ITA","Puglia","","15682","1380279313"
"Mensora","Mensora","34.8356","-4.9655","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16559","1504316721"
"Ubrique","Ubrique","36.6833","-5.4500","Spain","ES","ESP","Andalusia","","16482","1724430804"
"Kade","Kade","6.0833","-0.8333","Ghana","GH","GHA","Eastern","","16542","1288010670"
"Centerville","Centerville","40.9284","-111.8848","United States","US","USA","Utah","","16711","1840018734"
"Ansfelden","Ansfelden","48.2083","14.2889","Austria","AT","AUT","Oberösterreich","","16194","1040496902"
"Ibicuí","Ibicui","-14.8419","-39.9869","Brazil","BR","BRA","Bahia","","16696","1076085235"
"Wentang","Wentang","23.9918","112.2868","China","CN","CHN","Guangdong","","16542","1156801367"
"Ibitiara","Ibitiara","-12.6519","-42.2178","Brazil","BR","BRA","Bahia","","16699","1076577189"
"Pipra Latīf","Pipra Latif","25.3579","86.7174","India","IN","IND","Bihār","","15498","1356257799"
"Pochëp","Pochep","52.9333","33.4500","Russia","RU","RUS","Bryanskaya Oblast’","","16687","1643674732"
"Tantéga","Tantega","10.8500","1.0333","Benin","BJ","BEN","Atacora","","16625","1204310643"
"Tiahounkossi","Tiahounkossi","10.8167","1.0667","Benin","BJ","BEN","Atacora","","16625","1204600986"
"Illingen","Illingen","49.3764","7.0525","Germany","DE","DEU","Saarland","","16262","1276359675"
"Rumilly","Rumilly","45.8667","5.9444","France","FR","FRA","Auvergne-Rhône-Alpes","","15768","1250434844"
"Zumarraga","Zumarraga","11.6390","124.8410","Philippines","PH","PHL","Samar","","16279","1608985918"
"Manamelkudi","Manamelkudi","10.0419","79.2300","India","IN","IND","Tamil Nādu","","16243","1356625120"
"Powder Springs","Powder Springs","33.8659","-84.6840","United States","US","USA","Georgia","","16698","1840014755"
"Rāikal","Raikal","18.9000","78.8000","India","IN","IND","Andhra Pradesh","","16404","1356268881"
"Kāmayakkavundanpatti","Kamayakkavundanpatti","9.7386","77.3200","India","IN","IND","Tamil Nādu","","16118","1356243384"
"Yoshinogari","Yoshinogari","33.3212","130.3989","Japan","JP","JPN","Saga","","16324","1392003363"
"Zequ","Zequ","35.0376","101.4606","China","CN","CHN","Qinghai","minor","16666","1156698715"
"Hueytown","Hueytown","33.4239","-87.0220","United States","US","USA","Alabama","","16694","1840014796"
"Mariyammanahalli","Mariyammanahalli","15.1600","76.3600","India","IN","IND","Karnātaka","","16097","1356575497"
"Bālasamudram","Balasamudram","10.4190","77.4992","India","IN","IND","Tamil Nādu","","16242","1356172140"
"Jamālpur","Jamalpur","25.9549","86.3600","India","IN","IND","Bihār","","15426","1356258329"
"Rožnov pod Radhoštěm","Roznov pod Radhostem","49.4585","18.1430","Czechia","CZ","CZE","Zlínský Kraj","","16280","1203287923"
"Pôrto Murtinho","Porto Murtinho","-21.6989","-57.8828","Brazil","BR","BRA","Mato Grosso do Sul","","16686","1076052096"
"Ujre","Ujre","12.9961","75.3248","India","IN","IND","Karnātaka","","16465","1356113904"
"Segbwema","Segbwema","8.0000","-10.9500","Sierra Leone","SL","SLE","Eastern","","16532","1694704842"
"Gasparillo","Gasparillo","10.3167","-61.4167","Trinidad and Tobago","TT","TTO","Couva/Tabaquite/Talparo","","16426","1780142944"
"Marui","Marui","24.8639","85.6338","India","IN","IND","Bihār","","15242","1356620444"
"Beek","Beek","50.9333","5.8000","Netherlands","NL","NLD","Limburg","minor","15929","1528590819"
"Princeton","Princeton","33.1778","-96.5044","United States","US","USA","Texas","","16683","1840020663"
"Banbridge","Banbridge","54.3430","-6.2600","United Kingdom","GB","GBR","Armagh City, Banbridge and Craigavon","","14744","1826331367"
"Puerto Pilón","Puerto Pilon","9.3600","-79.7900","Panama","PA","PAN","Colón","minor","16517","1591571377"
"Rājāsūr","Rajasur","17.8600","76.9500","India","IN","IND","Karnātaka","","16349","1356367448"
"Todi","Todi","42.7789","12.4142","Italy","IT","ITA","Umbria","","16606","1380391781"
"Palagonia","Palagonia","37.3333","14.7500","Italy","IT","ITA","Sicilia","","16654","1380106335"
"Ban Na Sai","Ban Na Sai","17.7334","98.9513","Thailand","TH","THA","Lamphun","","16645","1764024755"
"Federación","Federacion","-30.9833","-57.9167","Argentina","AR","ARG","Entre Ríos","minor","16658","1032860100"
"Reggello","Reggello","43.6833","11.5333","Italy","IT","ITA","Tuscany","","16543","1380547309"
"Shiraoi","Shiraoi","42.5512","141.3559","Japan","JP","JPN","Hokkaidō","","16638","1392327130"
"Jinji","Jinji","22.1650","112.4848","China","CN","CHN","Guangdong","","16493","1156813063"
"Chorbogh","Chorbogh","39.8667","69.0833","Tajikistan","TJ","TJK","Sughd","","16488","1762188162"
"Millbrook","Millbrook","32.5027","-86.3737","United States","US","USA","Alabama","","16675","1840014877"
"Zeulenroda","Zeulenroda","50.6486","11.9806","Germany","DE","DEU","Thuringia","","16487","1276933669"
"Grimari","Grimari","5.7167","20.0500","Central African Republic","CF","CAF","Ouaka","","16486","1140969115"
"Mennecy","Mennecy","48.5653","2.4361","France","FR","FRA","Île-de-France","","15292","1250666840"
"Horad Smalyavichy","Horad Smalyavichy","54.1000","28.0667","Belarus","BY","BLR","Minskaya Voblasts’","minor","16594","1112264806"
"East Highland Park","East Highland Park","37.5770","-77.3865","United States","US","USA","Virginia","","16669","1840006388"
"Chittayankottai","Chittayankottai","10.2686","77.8327","India","IN","IND","Tamil Nādu","","15970","1356245549"
"Noale","Noale","45.5501","12.0709","Italy","IT","ITA","Veneto","","16017","1380537608"
"Aladağ","Aladag","37.5465","35.3987","Turkey","TR","TUR","Adana","minor","16653","1792029396"
"Lake St. Louis","Lake St. Louis","38.7846","-90.7886","United States","US","USA","Missouri","","16665","1840008572"
"Harborcreek","Harborcreek","42.1498","-79.9501","United States","US","USA","Pennsylvania","","16665","1840099632"
"Washougal","Washougal","45.5825","-122.3451","United States","US","USA","Washington","","16664","1840021190"
"Dallas","Dallas","44.9221","-123.3130","United States","US","USA","Oregon","","16663","1840018590"
"Codogno","Codogno","45.1600","9.7050","Italy","IT","ITA","Lombardy","","15901","1380367305"
"Miyazu","Miyazu","35.5333","135.2000","Japan","JP","JPN","Kyōto","","16564","1392216318"
"Añisoc","Anisoc","1.8500","10.7667","Equatorial Guinea","GQ","GNQ","Wele-Nzas","","16626","1226309663"
"Īlkhchī","Ilkhchi","37.9378","45.9803","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","16574","1364345674"
"Brunico","Brunico","46.7963","11.9355","Italy","IT","ITA","Trentino-Alto Adige","","16580","1380194729"
"Ādra","Adra","23.5000","86.6700","India","IN","IND","West Bengal","","14956","1356918249"
"Mangueirinha","Mangueirinha","-25.9408","-52.1758","Brazil","BR","BRA","Paraná","","16642","1076072805"
"Sainte-Luce-sur-Loire","Sainte-Luce-sur-Loire","47.2494","-1.4867","France","FR","FRA","Pays de la Loire","","15319","1250001049"
"Barkot","Barkot","30.8200","78.2000","India","IN","IND","Uttarākhand","","16568","1356083312"
"Al Laţāminah","Al Lataminah","35.3208","36.6225","Syria","SY","SYR","Ḩamāh","","16267","1760263860"
"Long Beach","Long Beach","30.3608","-89.1651","United States","US","USA","Mississippi","","16654","1840015914"
"Kājha","Kajha","25.7747","87.3545","India","IN","IND","Bihār","","15659","1356662670"
"Odumase","Odumase","7.3667","-2.3167","Ghana","GH","GHA","Bono","","16542","1288366074"
"Khargāpur","Khargapur","24.8230","79.1440","India","IN","IND","Madhya Pradesh","","16385","1356091431"
"Northenden","Northenden","53.4075","-2.2583","United Kingdom","GB","GBR","Manchester","","15200","1826100514"
"Denby Dale","Denby Dale","53.5720","-1.6550","United Kingdom","GB","GBR","Kirklees","","16365","1826175295"
"Tirhassaline","Tirhassaline","32.7833","-5.6500","Morocco","MA","MAR","Béni Mellal-Khénifra","","15204","1504798306"
"Falköping","Falkoping","58.1750","13.5531","Sweden","SE","SWE","Västra Götaland","minor","16350","1752889623"
"Ad Dīs","Ad Dis","14.9100","49.9920","Yemen","YE","YEM","Ḩaḑramawt","","16614","1887279052"
"Mifune","Mifune","32.7146","130.8019","Japan","JP","JPN","Kumamoto","","16468","1392426855"
"Santa María Petapa","Santa Maria Petapa","16.8167","-95.1167","Mexico","MX","MEX","Oaxaca","","16518","1484501021"
"Bolivia","Bolivia","22.0750","-78.3503","Cuba","CU","CUB","Ciego de Ávila","minor","16612","1192406744"
"Tapes","Tapes","-30.6728","-51.3958","Brazil","BR","BRA","Rio Grande do Sul","","16629","1076622644"
"Kovdor","Kovdor","67.5594","30.4667","Russia","RU","RUS","Murmanskaya Oblast’","","16623","1643366927"
"Nakanoto","Nakanoto","36.9889","136.9015","Japan","JP","JPN","Ishikawa","","16442","1392003102"
"Bacuri","Bacuri","-1.7028","-45.1339","Brazil","BR","BRA","Maranhão","","16604","1076407593"
"Hannut","Hannut","50.6667","5.0833","Belgium","BE","BEL","Wallonia","minor","16435","1056196454"
"Hatti","Hatti","16.1984","76.6604","India","IN","IND","Karnātaka","","16394","1356672211"
"Digne-les-Bains","Digne-les-Bains","44.0925","6.2356","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","16482","1250413412"
"Zhutian","Zhutian","22.5890","120.5303","Taiwan","TW","TWN","Pingtung","","16068","1158857379"
"Guaymate","Guaymate","18.5800","-68.9800","Dominican Republic","DO","DOM","Yuma","","16558","1214081912"
"Ippy","Ippy","6.2500","21.2000","Central African Republic","CF","CAF","Ouaka","","16571","1140476425"
"Água Branca","Agua Branca","-5.8900","-42.6378","Brazil","BR","BRA","Piauí","","16451","1076724006"
"Zapotlán de Juárez","Zapotlan de Juarez","19.9667","-98.8500","Mexico","MX","MEX","Hidalgo","minor","16493","1484756389"
"Gryfice","Gryfice","53.9147","15.1986","Poland","PL","POL","Zachodniopomorskie","minor","16524","1616794492"
"Vesoul","Vesoul","47.6222","6.1553","France","FR","FRA","Bourgogne-Franche-Comté","minor","14973","1250247496"
"Rājnagar","Rajnagar","24.8893","79.9118","India","IN","IND","Madhya Pradesh","","16432","1356074010"
"Ramón Santana","Ramon Santana","18.5500","-69.1833","Dominican Republic","DO","DOM","Higuamo","","16548","1214143135"
"Lumbreras","Lumbreras","37.5633","-1.8072","Spain","ES","ESP","Murcia","","16564","1724380246"
"Umbertide","Umbertide","43.3000","12.3333","Italy","IT","ITA","Umbria","","16530","1380190822"
"Zhytkavichy","Zhytkavichy","52.2333","27.8667","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","16600","1112156374"
"Peer","Peer","51.1328","5.4536","Belgium","BE","BEL","Flanders","","16421","1056050741"
"Batán","Batan","10.1041","-83.3529","Costa Rica","CR","CRI","Limón","","16532","1188331157"
"Xinyuan","Xinyuan","37.2953","99.0341","China","CN","CHN","Qinghai","minor","16585","1156272954"
"Chełmża","Chelmza","53.1847","18.6042","Poland","PL","POL","Kujawsko-Pomorskie","","14808","1616701343"
"Ina","Ina","9.9833","2.7167","Benin","BJ","BEN","Borgou","","16558","1204781465"
"Swift Current","Swift Current","50.2881","-107.7939","Canada","CA","CAN","Saskatchewan","","16604","1124460875"
"Pupiales","Pupiales","0.8667","-77.6500","Colombia","CO","COL","Nariño","minor","16431","1170678513"
"Humble","Humble","29.9921","-95.2655","United States","US","USA","Texas","","16603","1840020926"
"Słubice","Slubice","52.3500","14.5667","Poland","PL","POL","Lubuskie","minor","16495","1616690993"
"Vemuladīvi","Vemuladivi","16.3408","81.6958","India","IN","IND","Andhra Pradesh","","15484","1356562018"
"Valdez","Valdez","1.2500","-79.0000","Ecuador","EC","ECU","Esmeraldas","","16513","1218064465"
"Fairview Heights","Fairview Heights","38.5974","-90.0053","United States","US","USA","Illinois","","16601","1840008630"
"Macetown","Macetown","-44.8650","168.8190","New Zealand","NZ","NZL","Otago","","16600","1554582165"
"San Estanislao","San Estanislao","10.4000","-75.1500","Colombia","CO","COL","Bolívar","minor","16518","1170812235"
"Benipati","Benipati","26.4442","85.9083","India","IN","IND","Bihār","","14806","1356245268"
"Red Wing","Red Wing","44.5817","-92.6036","United States","US","USA","Minnesota","","16596","1840008959"
"Johnstown","Johnstown","40.3499","-104.9482","United States","US","USA","Colorado","","16596","1840021369"
"Beaver Dam","Beaver Dam","43.4688","-88.8309","United States","US","USA","Wisconsin","","16594","1840002738"
"Punta Umbría","Punta Umbria","37.1667","-6.9500","Spain","ES","ESP","Andalusia","","16167","1724226508"
"Guardamar del Segura","Guardamar del Segura","38.0897","-0.6550","Spain","ES","ESP","Valencia","","16138","1724960242"
"Morton","Morton","40.6135","-89.4669","United States","US","USA","Illinois","","16591","1840011991"
"Obra","Obra","24.8910","84.3742","India","IN","IND","Bihār","","15966","1356681679"
"Saraikela","Saraikela","22.6996","85.9313","India","IN","IND","Jhārkhand","","16196","1356002009"
"Gangāpur","Gangapur","25.5136","86.8957","India","IN","IND","Bihār","","15129","1356374719"
"Shamsa","Shamsa","25.6230","85.9310","India","IN","IND","Bihār","","15015","1356028488"
"Kirchlengern","Kirchlengern","52.2000","8.6331","Germany","DE","DEU","North Rhine-Westphalia","","16111","1276250400"
"Buritama","Buritama","-21.0667","-50.1475","Brazil","BR","BRA","São Paulo","","16583","1076594548"
"Champāpur","Champapur","26.8881","84.8340","India","IN","IND","Bihār","","14881","1356899056"
"Corabia","Corabia","43.7736","24.5033","Romania","RO","ROU","Olt","","16441","1642203673"
"Kurtamysh","Kurtamysh","54.9167","64.4333","Russia","RU","RUS","Kurganskaya Oblast’","","16579","1643527477"
"Ahmetli","Ahmetli","38.5289","27.9447","Turkey","TR","TUR","Manisa","minor","16530","1792093004"
"Wyke","Wyke","53.7333","-1.7667","United Kingdom","GB","GBR","Bradford","","14950","1826505143"
"Kota","Kota","14.0333","80.0500","India","IN","IND","Andhra Pradesh","","16237","1356180779"
"Keflavík","Keflavik","64.0167","-22.5667","Iceland","IS","ISL","Reykjanesbær","","16463","1352901945"
"Pirque","Pirque","-33.6333","-70.5500","Chile","CL","CHL","Región Metropolitana","","16565","1152296541"
"Edmundston","Edmundston","47.3765","-68.3253","Canada","CA","CAN","New Brunswick","","16580","1124274233"
"Cristinápolis","Cristinapolis","-11.4758","-37.7550","Brazil","BR","BRA","Sergipe","","16519","1076000776"
"Bluffdale","Bluffdale","40.4744","-111.9381","United States","US","USA","Utah","","16576","1840018745"
"Bree","Bree","51.1333","5.5833","Belgium","BE","BEL","Flanders","","16324","1056304554"
"Chinde","Chinde","-18.5833","36.4667","Mozambique","MZ","MOZ","Zambézia","","16500","1508587868"
"Weybridge","Weybridge","51.3620","-0.4530","United Kingdom","GB","GBR","Surrey","","15449","1826438211"
"Brackenheim","Brackenheim","49.0833","9.0667","Germany","DE","DEU","Baden-Württemberg","","16219","1276363194"
"Centre de Flacq","Centre de Flacq","-20.2002","57.7177","Mauritius","MU","MUS","Flacq","","15791","1480176466"
"Fiano Romano","Fiano Romano","42.1667","12.6000","Italy","IT","ITA","Lazio","","16177","1380521014"
"Hønefoss","Honefoss","60.1667","10.3000","Norway","NO","NOR","Viken","minor","16547","1578754019"
"Birsinghpur","Birsinghpur","24.7981","80.9739","India","IN","IND","Madhya Pradesh","","16286","1356089275"
"Nobsa","Nobsa","5.7667","-72.9500","Colombia","CO","COL","Boyacá","minor","16271","1170848106"
"Çarşıbaşı","Carsibasi","41.0833","39.3833","Turkey","TR","TUR","Trabzon","minor","16335","1792834286"
"Besana in Brianza","Besana in Brianza","45.7000","9.2833","Italy","IT","ITA","Lombardy","","15573","1380091291"
"Franklin","Franklin","39.5954","-75.0190","United States","US","USA","New Jersey","","16561","1840150769"
"Quarteira","Quarteira","37.0690","-8.1030","Portugal","PT","PRT","Faro","","16131","1620157848"
"Ḩammām al ‘Alīl","Hammam al \`Alil","36.1581","43.2594","Iraq","IQ","IRQ","Nīnawá","","15985","1368957629"
"Loxstedt","Loxstedt","53.4699","8.6479","Germany","DE","DEU","Lower Saxony","","16441","1276084019"
"Berezan","Berezan","50.3197","31.4700","Ukraine","UA","UKR","Kyivska Oblast","","16506","1804009720"
"Horodok","Horodok","49.1667","26.5667","Ukraine","UA","UKR","Khmelnytska Oblast","","16516","1804176513"
"Nanbu","Nanbu","40.4206","141.3303","Japan","JP","JPN","Aomori","","16449","1392002869"
"Yaypan","Yaypan","40.3758","70.8156","Uzbekistan","UZ","UZB","Farg‘ona","minor","15984","1860703672"
"Palanisettipatti","Palanisettipatti","9.9998","77.4641","India","IN","IND","Tamil Nādu","","15510","1356242703"
"Ségou","Segou","6.6167","2.2167","Benin","BJ","BEN","Atlantique","","16124","1204316381"
"Rāhon","Rahon","31.0527","76.1191","India","IN","IND","Punjab","","16016","1356318645"
"Uchkeken","Uchkeken","43.9333","42.5167","Russia","RU","RUS","Karachayevo-Cherkesiya","","16512","1643030043"
"Hampton Bays","Hampton Bays","40.8695","-72.5225","United States","US","USA","New York","","16546","1840005076"
"Langenthal","Langenthal","47.2167","7.7833","Switzerland","CH","CHE","Bern","","15639","1756087578"
"Ban Lam Narai","Ban Lam Narai","15.2000","101.1333","Thailand","TH","THA","Lop Buri","","16470","1764729465"
"Bayat","Bayat","40.6460","34.2610","Turkey","TR","TUR","Çorum","minor","16525","1792851737"
"Celina","Celina","33.3154","-96.7941","United States","US","USA","Texas","","16542","1840019395"
"Az Zaydīyah","Az Zaydiyah","15.3292","43.0094","Yemen","YE","YEM","Al Ḩudaydah","minor","16246","1887436812"
"Pedregulho","Pedregulho","-20.2569","-47.4767","Brazil","BR","BRA","São Paulo","","16517","1076549433"
"Charqueada","Charqueada","-22.5097","-47.7781","Brazil","BR","BRA","São Paulo","","16440","1076382769"
"Ciney","Ciney","50.3000","5.1000","Belgium","BE","BEL","Wallonia","","16439","1056079204"
"Mapiri","Mapiri","-15.2500","-68.1667","Bolivia","BO","BOL","La Paz","","16520","1068025834"
"Lugu","Lugu","23.7500","120.7330","Taiwan","TW","TWN","Nantou","","16413","1158744613"
"Chennūr","Chennur","14.5667","78.8000","India","IN","IND","Andhra Pradesh","","16126","1356096953"
"Garðabær","Gardhabaer","64.0833","-22.0000","Iceland","IS","ISL","Garðabær","","16299","1352650773"
"Differdange","Differdange","49.5222","5.8917","Luxembourg","LU","LUX","Esch-sur-Alzette","minor","15455","1442264411"
"Srīmushnam","Srimushnam","11.4012","79.4038","India","IN","IND","Tamil Nādu","","15829","1356640376"
"Hövelhof","Hovelhof","51.8167","8.6500","Germany","DE","DEU","North Rhine-Westphalia","","16294","1276911477"
"Châteaurenard","Chateaurenard","43.8825","4.8550","France","FR","FRA","Provence-Alpes-Côte d’Azur","","16063","1250364972"
"Saint-Amand-les-Eaux","Saint-Amand-les-Eaux","50.4481","3.4269","France","FR","FRA","Hauts-de-France","","16053","1250163931"
"Ivanava","Ivanava","52.1333","25.5500","Belarus","BY","BLR","Brestskaya Voblasts’","minor","16498","1112507447"
"Petersberg","Petersberg","50.5667","9.7167","Germany","DE","DEU","Hesse","","16069","1276763295"
"Qarqīn","Qarqin","37.4128","66.0472","Afghanistan","AF","AFG","Jowzjān","minor","16500","1004340289"
"Mastchoh","Mastchoh","40.3667","69.3333","Tajikistan","TJ","TJK","Sughd","","16401","1762426354"
"Bobangui","Bobangui","4.0500","18.1167","Central African Republic","CF","CAF","Lobaye","","16486","1140666891"
"Santomera","Santomera","38.0617","-1.0492","Spain","ES","ESP","Murcia","","16154","1724892618"
"Russell","Russell","45.2569","-75.3583","Canada","CA","CAN","Ontario","","16520","1124982538"
"Antanambao Mahatsara","Antanambao Mahatsara","-19.3167","48.7833","Madagascar","MG","MDG","Toamasina","","16482","1450169284"
"Sultānābād","Sultanabad","18.5333","79.3333","India","IN","IND","Andhra Pradesh","","16116","1356975454"
"Maruim","Maruim","-10.7378","-37.0819","Brazil","BR","BRA","Sergipe","","16343","1076470942"
"Sānātikri","Sanatikri","22.0198","88.5026","India","IN","IND","West Bengal","","15536","1356384809"
"Al Ghāţ","Al Ghat","26.0267","44.9608","Saudi Arabia","SA","SAU","Ar Riyāḑ","","16500","1682563733"
"Karema","Karema","-6.8205","30.4389","Tanzania","TZ","TZA","Katavi","","16385","1834711465"
"Kisvárda","Kisvarda","48.2167","22.0833","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","16084","1348590811"
"Makhu","Makhu","31.1033","74.9963","India","IN","IND","Punjab","","16071","1356055682"
"Langrucun","Langrucun","36.9014","79.6261","China","CN","CHN","Xinjiang","","16505","1156566556"
"Sabotsy","Sabotsy","-19.2333","47.6000","Madagascar","MG","MDG","Antananarivo","","16471","1450925010"
"Bady Bassitt","Bady Bassitt","-20.9178","-49.4450","Brazil","BR","BRA","São Paulo","","16359","1076001081"
"Longchang","Longchang","27.6627","105.7881","China","CN","CHN","Guizhou","","16240","1156109029"
"San Miguel","San Miguel","11.4920","119.8710","Philippines","PH","PHL","Palawan","","16424","1608547539"
"Dison","Dison","50.6167","5.8500","Belgium","BE","BEL","Wallonia","","15405","1056512338"
"Zhongzai","Zhongzai","26.6877","105.6548","China","CN","CHN","Guizhou","","16379","1156200025"
"Saunshi","Saunshi","15.2167","75.3000","India","IN","IND","Karnātaka","","16208","1356696879"
"Nyahanga","Nyahanga","-2.3829","33.5500","Tanzania","TZ","TZA","Simiyu","","16092","1834609339"
"Regenstauf","Regenstauf","49.1236","12.1283","Germany","DE","DEU","Bavaria","","16343","1276162289"
"Tōhoku","Tohoku","40.7279","141.2579","Japan","JP","JPN","Aomori","","16449","1392003186"
"Tadikalapūdi","Tadikalapudi","16.8991","81.1764","India","IN","IND","Andhra Pradesh","","16235","1356616862"
"Tafresh","Tafresh","34.6919","50.0131","Iran","IR","IRN","Markazī","minor","16493","1364000242"
"Az Zuwāydah","Az Zuwaydah","31.4284","34.3659","Gaza Strip","XG","XGZ","","","15483","1916190557"
"Belén","Belen","11.5028","-85.8889","Nicaragua","NI","NIC","Rivas","minor","16428","1558470755"
"Changji","Changji","26.9471","108.7524","China","CN","CHN","Guizhou","","16308","1156061807"
"Toro","Toro","4.6117","-76.0814","Colombia","CO","COL","Valle del Cauca","minor","16394","1170854707"
"Dinmānpur","Dinmanpur","25.9112","85.9288","India","IN","IND","Bihār","","14588","1356756110"
"Sunland Park","Sunland Park","31.8201","-106.5958","United States","US","USA","New Mexico","","16488","1840033664"
"Loimaa","Loimaa","60.8500","23.0583","Finland","FI","FIN","Varsinais-Suomi","minor","16467","1246923432"
"Barbacoas","Barbacoas","9.4833","-66.9761","Venezuela","VE","VEN","Aragua","minor","16469","1862577336"
"Kirchhain","Kirchhain","50.8167","8.9167","Germany","DE","DEU","Hesse","","16298","1276056692"
"Harenkarspel","Harenkarspel","52.7344","4.7706","Netherlands","NL","NLD","Noord-Holland","","16179","1528667512"
"Clevelândia","Clevelandia","-26.3958","-52.4708","Brazil","BR","BRA","Paraná","","16450","1076001262"
"Cloverly","Cloverly","39.1064","-76.9995","United States","US","USA","Maryland","","16473","1840031267"
"Yunshan","Yunshan","34.7610","105.7915","China","CN","CHN","Gansu","","16220","1156469234"
"Ambohimalaza","Ambohimalaza","-18.9167","47.6500","Madagascar","MG","MDG","Antananarivo","","15988","1450435158"
"Jadia","Jadia","26.0937","86.9852","India","IN","IND","Bihār","","15083","1356747886"
"Rakovník","Rakovnik","50.1038","13.7335","Czechia","CZ","CZE","Středočeský Kraj","","15652","1203062806"
"Douglas","Douglas","31.3602","-109.5394","United States","US","USA","Arizona","","16470","1840019496"
"Kozelsk","Kozelsk","54.0353","35.7767","Russia","RU","RUS","Kaluzhskaya Oblast’","","16443","1643293500"
"Loma Plata","Loma Plata","-22.3833","-59.8333","Paraguay","PY","PRY","Boquerón","","16460","1600342774"
"Clearlake","Clearlake","38.9589","-122.6330","United States","US","USA","California","","16468","1840028139"
"Bovolone","Bovolone","45.2500","11.1333","Italy","IT","ITA","Veneto","","16078","1380019323"
"Vienna","Vienna","38.8996","-77.2597","United States","US","USA","Virginia","","16465","1840003829"
"Jakkampālaiyam","Jakkampalaiyam","10.5264","77.2884","India","IN","IND","Tamil Nādu","","16127","1356170346"
"Slaný","Slany","50.2305","14.0870","Czechia","CZ","CZE","Středočeský Kraj","","16010","1203367412"
"Bilohirsk","Bilohirsk","45.0544","34.6022","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","16428","1804571792"
"Itaeté","Itaete","-12.9858","-40.9728","Brazil","BR","BRA","Bahia","","16446","1076167864"
"Tasīl","Tasil","32.8353","35.9714","Syria","SY","SYR","Dar‘ā","minor","15985","1760040877"
"Lake Mary","Lake Mary","28.7592","-81.3360","United States","US","USA","Florida","","16459","1840015090"
"Anorí","Anori","7.0736","-75.1458","Colombia","CO","COL","Antioquia","minor","16447","1170571922"
"Pocking","Pocking","48.4000","13.3167","Germany","DE","DEU","Bavaria","","16223","1276193940"
"Chittūr","Chittur","11.6470","77.8239","India","IN","IND","Tamil Nādu","","15550","1356168530"
"Las Cabezas de San Juan","Las Cabezas de San Juan","36.9817","-5.9406","Spain","ES","ESP","Andalusia","","16386","1724899014"
"McKinleyville","McKinleyville","40.9488","-124.0857","United States","US","USA","California","","16457","1840017485"
"Arteche","Arteche","12.2694","125.3712","Philippines","PH","PHL","Eastern Samar","","16360","1608901984"
"Odaiyakulam","Odaiyakulam","10.5679","76.9105","India","IN","IND","Tamil Nādu","","15838","1356162297"
"Ban Samo Khae","Ban Samo Khae","16.8408","100.3415","Thailand","TH","THA","Phitsanulok","","16056","1764002915"
"Hayes","Hayes","51.3780","0.0192","United Kingdom","GB","GBR","Bromley","","15906","1826550930"
"Taohongpozhen","Taohongpozhen","36.9854","111.4737","China","CN","CHN","Shanxi","","16378","1156984384"
"Dilārpur","Dilarpur","25.3968","87.5969","India","IN","IND","Bihār","","15429","1356946822"
"Harlingen","Harlingen","53.1736","5.4272","Netherlands","NL","NLD","Fryslân","minor","15822","1528687785"
"Turkaguda","Turkaguda","17.2728","78.5708","India","IN","IND","Andhra Pradesh","","15689","1356802261"
"Rolleston","Rolleston","-43.5833","172.3833","New Zealand","NZ","NZL","Canterbury","","16350","1554176166"
"Olecko","Olecko","54.0333","22.5000","Poland","PL","POL","Warmińsko-Mazurskie","minor","16364","1616175752"
"Straelen","Straelen","51.4500","6.2667","Germany","DE","DEU","North Rhine-Westphalia","","16232","1276805801"
"North Grenville","North Grenville","44.9667","-75.6500","Canada","CA","CAN","Ontario","","16451","1124000746"
"Ispica","Ispica","36.7833","14.9000","Italy","IT","ITA","Sicilia","","16307","1380690340"
"Al Ḩībah","Al Hibah","28.7736","30.9228","Egypt","EG","EGY","Al Baḩr al Aḩmar","","16209","1818176638"
"Shuili","Shuili","23.7989","120.8553","Taiwan","TW","TWN","Nantou","","16297","1158589365"
"Panukulan","Panukulan","14.9333","121.8167","Philippines","PH","PHL","Quezon","","16376","1608744035"
"Pātapatnam","Patapatnam","18.7500","84.0833","India","IN","IND","Andhra Pradesh","","15954","1356118629"
"Angara-Débou","Angara-Debou","11.3289","3.0406","Benin","BJ","BEN","Atacora","","16369","1204754411"
"Verrières-le-Buisson","Verrieres-le-Buisson","48.7475","2.2628","France","FR","FRA","Île-de-France","","14941","1250622233"
"Islām Qal‘ah","Islam Qal\`ah","34.6667","61.0667","Afghanistan","AF","AFG","Herāt","","16408","1004574354"
"Bruntál","Bruntal","49.9883","17.4647","Czechia","CZ","CZE","Moravskoslezský Kraj","","15908","1203666435"
"Sava","Sava","40.4003","17.5667","Italy","IT","ITA","Puglia","","16076","1380330034"
"Āgiripalle","Agiripalle","16.6833","80.6500","India","IN","IND","Andhra Pradesh","","16150","1356687645"
"Sandhausen","Sandhausen","49.3439","8.6581","Germany","DE","DEU","Baden-Württemberg","","15378","1276169005"
"Rajni","Rajni","25.8132","86.9772","India","IN","IND","Bihār","","15050","1356061146"
"Les Herbiers","Les Herbiers","46.8711","-1.0136","France","FR","FRA","Pays de la Loire","","16250","1250629086"
"Alegria","Alegria","9.4667","125.5767","Philippines","PH","PHL","Surigao del Norte","","16184","1608913712"
"Agudo","Agudo","-29.6450","-53.2400","Brazil","BR","BRA","Rio Grande do Sul","","16401","1076713609"
"Domoni","Domoni","-12.2586","44.5303","Comoros","KM","COM","Anjouan","","16276","1174938041"
"Spárti","Sparti","37.0739","22.4294","Greece","GR","GRC","Pelopónnisos","minor","16239","1300989189"
"Boshrūyeh","Boshruyeh","33.8683","57.4289","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","16426","1364726619"
"Curacautín","Curacautin","-38.4333","-71.8833","Chile","CL","CHL","Araucanía","","16418","1152761176"
"Verkhivtseve","Verkhivtseve","48.4812","34.2488","Ukraine","UA","UKR","Dnipropetrovska Oblast","","16364","1804209032"
"South Venice","South Venice","27.0444","-82.4155","United States","US","USA","Florida","","16426","1840014180"
"Bosobolo","Bosobolo","4.1833","19.9000","Congo (Kinshasa)","CD","COD","Nord-Ubangi","","16397","1180318544"
"Uckfield","Uckfield","50.9700","0.1000","United Kingdom","GB","GBR","East Sussex","","14493","1826320254"
"Center Point","Center Point","33.6447","-86.6852","United States","US","USA","Alabama","","16422","1840014789"
"Dyer","Dyer","41.4977","-87.5090","United States","US","USA","Indiana","","16422","1840010224"
"Portachuelo","Portachuelo","-17.3572","-63.3906","Bolivia","BO","BOL","Santa Cruz","","16408","1068477207"
"Neykkārappatti","Neykkarappatti","10.4489","77.4627","India","IN","IND","Tamil Nādu","","15970","1356172097"
"Anantapalle","Anantapalle","16.9767","81.4442","India","IN","IND","Andhra Pradesh","","16092","1356893543"
"La Falda","La Falda","-31.0833","-64.5000","Argentina","AR","ARG","Córdoba","","16335","1032413311"
"Sebt Aït Saghiouchen","Sebt Ait Saghiouchen","34.0122","-4.5536","Morocco","MA","MAR","Fès-Meknès","","16362","1504600750"
"Chai Prakan","Chai Prakan","19.7322","99.1403","Thailand","TH","THA","Chiang Mai","minor","16090","1764347576"
"Erlensee","Erlensee","50.1333","8.9333","Germany","DE","DEU","Hesse","","15579","1276495268"
"Jambaló","Jambalo","2.8500","-76.3167","Colombia","CO","COL","Cauca","minor","16353","1170689229"
"Bangor","Bangor","53.2280","-4.1280","United Kingdom","GB","GBR","Isle of Anglesey","","16358","1826969137"
"Heber","Heber","40.5070","-111.3986","United States","US","USA","Utah","","16416","1840074861"
"Bissegem","Bissegem","50.8167","3.2167","Belgium","BE","BEL","Flanders","","15533","1056902569"
"Chinácota","Chinacota","7.6167","-72.6000","Colombia","CO","COL","Norte de Santander","minor","16348","1170848933"
"Schmelz","Schmelz","49.4167","6.8333","Germany","DE","DEU","Saarland","","16136","1276565540"
"Banigbé","Banigbe","6.9000","2.6500","Benin","BJ","BEN","Plateau","","16080","1204880247"
"Éghezée","Eghezee","50.5833","4.9167","Belgium","BE","BEL","Wallonia","","16247","1056285363"
"Addison","Addison","32.9590","-96.8355","United States","US","USA","Texas","","16405","1840022057"
"Herisau","Herisau","47.3833","9.2667","Switzerland","CH","CHE","Appenzell Ausserrhoden","","15780","1756581563"
"Telfs","Telfs","47.3069","11.0722","Austria","AT","AUT","Tirol","","16046","1040393855"
"Kannampālaiyam","Kannampalaiyam","10.9954","77.0990","India","IN","IND","Tamil Nādu","","15760","1356169958"
"Copparo","Copparo","44.9000","11.8333","Italy","IT","ITA","Emilia-Romagna","","16294","1380210267"
"Cotorra","Cotorra","9.0500","-75.8000","Colombia","CO","COL","Córdoba","minor","16215","1170561401"
"Markranstädt","Markranstadt","51.3017","12.2211","Germany","DE","DEU","Saxony","","16121","1276832529"
"Wittstock","Wittstock","53.1636","12.4856","Germany","DE","DEU","Brandenburg","","16363","1276000618"
"Baipingshan","Baipingshan","26.1960","106.5500","China","CN","CHN","Guizhou","","16330","1156170884"
"Wolf Trap","Wolf Trap","38.9395","-77.2842","United States","US","USA","Virginia","","16394","1840006042"
"Beko","Beko","24.0415","85.9536","India","IN","IND","Jhārkhand","","15700","1356258941"
"Acarape","Acarape","-4.2242","-38.7083","Brazil","BR","BRA","Ceará","","16288","1076431033"
"Penicuik","Penicuik","55.8260","-3.2200","United Kingdom","GB","GBR","Midlothian","","15926","1826366579"
"Ustroń","Ustron","49.7194","18.8119","Poland","PL","POL","Śląskie","","16118","1616758185"
"Thompson","Thompson","41.6474","-74.6745","United States","US","USA","New York","","16391","1840058541"
"Do‘stobod","Do\`stobod","40.8564","68.9281","Uzbekistan","UZ","UZB","Toshkent","","16200","1860923500"
"Damdama","Damdama","24.6300","88.0200","India","IN","IND","Jhārkhand","","15688","1356137038"
"Przeworsk","Przeworsk","50.0667","22.5000","Poland","PL","POL","Podkarpackie","minor","15713","1616040608"
"Carcarañá","Carcarana","-32.8500","-61.1500","Argentina","AR","ARG","Santa Fe","","16277","1032358026"
"Ham Lake","Ham Lake","45.2545","-93.2039","United States","US","USA","Minnesota","","16389","1840006720"
"Pearl River","Pearl River","41.0615","-74.0047","United States","US","USA","New York","","16387","1840004966"
"Bālkonda","Balkonda","18.8667","78.3500","India","IN","IND","Andhra Pradesh","","15644","1356903520"
"East Longmeadow","East Longmeadow","42.0597","-72.4990","United States","US","USA","Massachusetts","","16386","1840053444"
"Hebli","Hebli","15.4858","75.1283","India","IN","IND","Karnātaka","","15187","1356109745"
"Bassum","Bassum","52.8494","8.7267","Germany","DE","DEU","Lower Saxony","","16289","1276562113"
"Realeza","Realeza","-25.7669","-53.5269","Brazil","BR","BRA","Paraná","","16338","1076096900"
"Huliyār","Huliyar","13.5833","76.5500","India","IN","IND","Karnātaka","","16192","1356127585"
"Divriği","Divrigi","39.3667","38.1167","Turkey","TR","TUR","Sivas","minor","16377","1792352411"
"Codroipo","Codroipo","45.9625","12.9792","Italy","IT","ITA","Friuli Venezia Giulia","","16165","1380582710"
"Nawada","Nawada","25.5431","85.9240","India","IN","IND","Bihār","","14476","1356099416"
"Porumāmilla","Porumamilla","15.0167","78.9833","India","IN","IND","Andhra Pradesh","","16201","1356429126"
"Sopelana","Sopelana","43.3814","-2.9792","Spain","ES","ESP","Basque Country","","14635","1724497959"
"Hude","Hude","53.1111","8.4625","Germany","DE","DEU","Lower Saxony","","16248","1276379256"
"Chiva","Chiva","39.4714","-0.7197","Spain","ES","ESP","Valencia","","16285","1724224613"
"Montivilliers","Montivilliers","49.5461","0.1881","France","FR","FRA","Normandie","","15564","1250957103"
"Sahatavy","Sahatavy","-17.4489","48.9953","Madagascar","MG","MDG","Toamasina","","16334","1450831507"
"Eilenburg","Eilenburg","51.4608","12.6358","Germany","DE","DEU","Saxony","","16031","1276166351"
"Fillmore","Fillmore","34.3989","-118.9174","United States","US","USA","California","","16373","1840020471"
"Middletown","Middletown","39.9094","-75.4311","United States","US","USA","Pennsylvania","","16372","1840152839"
"San Dionisio","San Dionisio","12.7625","-85.8503","Nicaragua","NI","NIC","Matagalpa","minor","16273","1558895835"
"Momil","Momil","9.2333","-75.6833","Colombia","CO","COL","Córdoba","minor","16264","1170726403"
"North Decatur","North Decatur","33.8074","-84.2889","United States","US","USA","Georgia","","16369","1840013704"
"Warragul","Warragul","-38.1500","145.9333","Australia","AU","AUS","Victoria","","15757","1036504084"
"Douar Azla","Douar Azla","35.5564","-5.2453","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16128","1504601982"
"Karambakkudi","Karambakkudi","10.4587","79.1410","India","IN","IND","Tamil Nādu","","15913","1356141439"
"Villa San José","Villa San Jose","-32.2000","-58.2167","Argentina","AR","ARG","Entre Ríos","","16336","1032325673"
"Brakel","Brakel","51.7167","9.1833","Germany","DE","DEU","North Rhine-Westphalia","","16270","1276092395"
"Solapuram","Solapuram","9.3757","77.5598","India","IN","IND","Tamil Nādu","","15620","1356204350"
"Sultonobod","Sultonobod","38.4500","68.9500","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","16066","1762709548"
"Novoukrainka","Novoukrainka","48.3156","31.5269","Ukraine","UA","UKR","Kirovohradska Oblast","minor","16338","1804711566"
"Bang Phae","Bang Phae","13.6983","99.9068","Thailand","TH","THA","Ratchaburi","minor","16130","1764337429"
"Isrāin Kalān","Israin Kalan","25.9844","87.0372","India","IN","IND","Bihār","","15334","1356141383"
"Sananduva","Sananduva","-27.9500","-51.8069","Brazil","BR","BRA","Rio Grande do Sul","","16328","1076687791"
"Kierspe","Kierspe","51.1333","7.5667","Germany","DE","DEU","North Rhine-Westphalia","","16137","1276256169"
"Kallūr","Kallur","17.2000","80.5500","India","IN","IND","Andhra Pradesh","","15807","1356501492"
"Néa Alikarnassós","Nea Alikarnassos","35.3167","25.1833","Greece","GR","GRC","Kríti","","14635","1300433837"
"Otacílio Costa","Otacilio Costa","-27.4828","-50.1219","Brazil","BR","BRA","Santa Catarina","","16337","1076112115"
"Pinhalzinho","Pinhalzinho","-26.8478","-52.9919","Brazil","BR","BRA","Santa Catarina","","16332","1076938611"
"Bingawan","Bingawan","11.2333","122.5667","Philippines","PH","PHL","Iloilo","","16164","1608856667"
"Ōarai","Oarai","36.3133","140.5749","Japan","JP","JPN","Ibaraki","","15696","1392502635"
"Grosse Pointe Woods","Grosse Pointe Woods","42.4366","-82.8987","United States","US","USA","Michigan","","16353","1840003979"
"Troutdale","Troutdale","45.5372","-122.3955","United States","US","USA","Oregon","","16353","1840021209"
"Guelendeng","Guelendeng","10.9183","15.5486","Chad","TD","TCD","Mayo-Kebbi-Est","","16320","1148777607"
"El Peñol","El Penol","6.2186","-75.2433","Colombia","CO","COL","Nariño","","16223","1170074292"
"Dieburg","Dieburg","49.9000","8.8500","Germany","DE","DEU","Hesse","","15679","1276337501"
"Narlıca","Narlica","36.2333","36.2167","Turkey","TR","TUR","Hatay","","15692","1792831887"
"Wardenburg","Wardenburg","53.0617","8.1967","Germany","DE","DEU","Lower Saxony","","16213","1276888712"
"New Port Richey","New Port Richey","28.2468","-82.7170","United States","US","USA","Florida","","16348","1840015102"
"Restrepo","Restrepo","3.8250","-76.5250","Colombia","CO","COL","Valle del Cauca","minor","16227","1170000422"
"Orimattila","Orimattila","60.8042","25.7333","Finland","FI","FIN","Päijät-Häme","minor","16326","1246385500"
"Puerto Pimentel","Puerto Pimentel","-6.8367","-79.9342","Peru","PE","PER","Lambayeque","","15552","1604924541"
"Sunnyside","Sunnyside","46.3157","-120.0058","United States","US","USA","Washington","","16343","1840021150"
"Yorkton","Yorkton","51.2139","-102.4628","Canada","CA","CAN","Saskatchewan","","16343","1124108820"
"Corbélia","Corbelia","-24.7989","-53.3069","Brazil","BR","BRA","Paraná","","16312","1076405992"
"Ellington","Ellington","41.9152","-72.4485","United States","US","USA","Connecticut","","16339","1840004744"
"Aranyaprathet","Aranyaprathet","13.6928","102.5017","Thailand","TH","THA","Sa Kaeo","minor","16211","1764167237"
"Oyten","Oyten","53.0611","9.0178","Germany","DE","DEU","Lower Saxony","","16085","1276791592"
"Ourém","Ourem","-1.5478","-47.1189","Brazil","BR","BRA","Pará","","16311","1076169606"
"Michelstadt","Michelstadt","49.6786","9.0039","Germany","DE","DEU","Hesse","","16151","1276024719"
"Palagiano","Palagiano","40.5833","17.0500","Italy","IT","ITA","Puglia","","16105","1380205580"
"Shahriston","Shahriston","39.7667","68.8167","Tajikistan","TJ","TJK","Sughd","minor","16295","1762671207"
"Peddaboddepalle","Peddaboddepalle","17.6606","82.6296","India","IN","IND","Andhra Pradesh","","15273","1356392870"
"Chamgardān","Chamgardan","32.3936","51.3408","Iran","IR","IRN","Eşfahān","","16086","1364112093"
"Halver","Halver","51.1833","7.4667","Germany","DE","DEU","North Rhine-Westphalia","","16120","1276039349"
"Tarquinia","Tarquinia","42.2492","11.7561","Italy","IT","ITA","Lazio","","16269","1380000153"
"Queensbury","Queensbury","53.7683","-1.8453","United Kingdom","GB","GBR","Calderdale","","16273","1826557476"
"Musāpur","Musapur","25.6417","87.4154","India","IN","IND","Bihār","","15210","1356952433"
"Ostashkov","Ostashkov","57.1500","33.1000","Russia","RU","RUS","Tverskaya Oblast’","","16318","1643804559"
"Villalbilla","Villalbilla","40.4339","-3.2989","Spain","ES","ESP","Madrid","","15866","1724385460"
"Schiffweiler","Schiffweiler","49.3667","7.1167","Germany","DE","DEU","Saarland","","15595","1276169477"
"Fort Hunt","Fort Hunt","38.7361","-77.0589","United States","US","USA","Virginia","","16322","1840006010"
"Ponsacco","Ponsacco","43.6167","10.6333","Italy","IT","ITA","Tuscany","","15539","1380451204"
"Morro da Fumaça","Morro da Fumaca","-28.6508","-49.2100","Brazil","BR","BRA","Santa Catarina","","16126","1076895972"
"Leopoldsburg","Leopoldsburg","51.1169","5.2558","Belgium","BE","BEL","Flanders","","15625","1056277308"
"Belāri","Belari","25.9655","86.9161","India","IN","IND","Bihār","","15293","1356139089"
"Faxinal","Faxinal","-24.0008","-51.3200","Brazil","BR","BRA","Paraná","","16314","1076625413"
"Wiefelstede","Wiefelstede","53.2581","8.1172","Germany","DE","DEU","Lower Saxony","","16167","1276114167"
"Poplar Bluff","Poplar Bluff","36.7632","-90.4135","United States","US","USA","Missouri","","16318","1840009935"
"Albemarle","Albemarle","35.3594","-80.1915","United States","US","USA","North Carolina","","16318","1840013444"
"Yotoco","Yotoco","3.8667","-76.3833","Colombia","CO","COL","Valle del Cauca","minor","16263","1170305801"
"Acahay","Acahay","-25.9100","-57.1100","Paraguay","PY","PRY","Paraguarí","","16264","1600352357"
"Lyss","Lyss","47.0667","7.3000","Switzerland","CH","CHE","Bern","","15282","1756010328"
"Vallirana","Vallirana","41.3878","1.9321","Spain","ES","ESP","Catalonia","","15658","1724988283"
"Lemay","Lemay","38.5325","-90.2845","United States","US","USA","Missouri","","16312","1840006119"
"Walldorf","Walldorf","49.3000","8.6500","Germany","DE","DEU","Baden-Württemberg","","15534","1276002664"
"Opa-locka","Opa-locka","25.8997","-80.2551","United States","US","USA","Florida","","16310","1840015156"
"Guérande","Guerande","47.3281","-2.4292","France","FR","FRA","Pays de la Loire","","16112","1250224274"
"Stahnsdorf","Stahnsdorf","52.3922","13.2167","Germany","DE","DEU","Brandenburg","","15983","1276819646"
"Timezgana","Timezgana","34.5833","-4.7333","Morocco","MA","MAR","Fès-Meknès","","16172","1504420273"
"Leso","Leso","11.6697","122.3269","Philippines","PH","PHL","Aklan","","15639","1608823601"
"Novoanninskiy","Novoanninskiy","50.5333","42.6833","Russia","RU","RUS","Volgogradskaya Oblast’","","16294","1643803707"
"Garou","Garou","11.8053","3.4739","Benin","BJ","BEN","Atacora","","16235","1204420447"
"Susanville","Susanville","40.4206","-120.6132","United States","US","USA","California","","16305","1840021348"
"Sabana de La Mar","Sabana de La Mar","19.0700","-69.3900","Dominican Republic","DO","DOM","Higuamo","","16272","1214759253"
"Adjud","Adjud","46.1000","27.1797","Romania","RO","ROU","Vrancea","","16045","1642892790"
"Ambalabe","Ambalabe","-15.1667","50.4167","Madagascar","MG","MDG","Antsiranana","","16250","1450947423"
"Ponders End","Ponders End","51.6460","-0.0460","United Kingdom","GB","GBR","Enfield","","15664","1826336314"
"Kodikkulam","Kodikkulam","9.6493","77.5860","India","IN","IND","Tamil Nādu","","15824","1356216825"
"Kumiyama","Kumiyama","34.8814","135.7328","Japan","JP","JPN","Kyōto","","15200","1392003183"
"Bikou","Bikou","32.7522","105.2424","China","CN","CHN","Gansu","","16247","1156883336"
"Carlet","Carlet","39.2264","-0.5211","Spain","ES","ESP","Valencia","","15945","1724149530"
"Ospitaletto","Ospitaletto","45.5553","10.0733","Italy","IT","ITA","Lombardy","","14711","1380485790"
"’Ayn Bni Mathar","'Ayn Bni Mathar","34.0889","-2.0247","Morocco","MA","MAR","Oriental","","16289","1504845272"
"Northbridge","Northbridge","42.1300","-71.6547","United States","US","USA","Massachusetts","","16291","1840053598"
"Montalvânia","Montalvania","-14.4228","-44.3658","Brazil","BR","BRA","Minas Gerais","","14747","1076690370"
"Borodino","Borodino","55.9056","94.8997","Russia","RU","RUS","Krasnoyarskiy Kray","","15838","1643427490"
"Sayville","Sayville","40.7478","-73.0840","United States","US","USA","New York","","16290","1840005092"
"Tiverton","Tiverton","41.6090","-71.1741","United States","US","USA","Rhode Island","","16287","1840066125"
"Chaona","Chaona","35.0895","107.2190","China","CN","CHN","Gansu","","16111","1156078071"
"Kabo","Kabo","7.6994","18.6292","Central African Republic","CF","CAF","Ouham","","16279","1140386370"
"Frogn","Frogn","59.6989","10.6553","Norway","NO","NOR","Viken","","16084","1578001728"
"Kotoura","Kotoura","35.5000","133.7000","Japan","JP","JPN","Tottori","","16167","1392003461"
"Antsahanoro","Antsahanoro","-14.8333","50.1333","Madagascar","MG","MDG","Antsiranana","","16253","1450717425"
"Crawfordsville","Crawfordsville","40.0428","-86.8976","United States","US","USA","Indiana","","16279","1840007261"
"Nanzuo","Nanzuo","37.8369","114.3591","China","CN","CHN","Hebei","","15704","1156717619"
"Civita Castellana","Civita Castellana","42.2961","12.4100","Italy","IT","ITA","Lazio","","16262","1380553391"
"Leixlip","Leixlip","53.3643","-6.4881","Ireland","IE","IRL","Kildare","","15504","1372553411"
"Niepołomice","Niepolomice","50.0339","20.2172","Poland","PL","POL","Małopolskie","","15697","1616183842"
"Adwick le Street","Adwick le Street","53.5677","-1.1931","United Kingdom","GB","GBR","Doncaster","","15911","1826413996"
"General Villegas","General Villegas","-35.0333","-63.0167","Argentina","AR","ARG","Buenos Aires","minor","16270","1032883098"
"Mercedes","Mercedes","26.1533","-97.9128","United States","US","USA","Texas","","16274","1840021025"
"Ransiki","Ransiki","-1.5000","134.1667","Indonesia","ID","IDN","Papua Barat","minor","16245","1360755420"
"Château-Thierry","Chateau-Thierry","49.0464","3.4031","France","FR","FRA","Hauts-de-France","minor","15351","1250189547"
"Concepción","Concepcion","-11.9185","-75.3129","Peru","PE","PER","Junín","","15428","1604755051"
"Sadhoa","Sadhoa","25.4049","87.1980","India","IN","IND","Bihār","","14501","1356262606"
"Petrovsk-Zabaykal’skiy","Petrovsk-Zabaykal'skiy","51.2667","108.8333","Russia","RU","RUS","Zabaykal’skiy Kray","","16213","1643676561"
"San Antonio Oeste","San Antonio Oeste","-40.7333","-64.9500","Argentina","AR","ARG","Río Negro","minor","16265","1032154473"
"Ban Kao","Ban Kao","13.8667","99.1500","Thailand","TH","THA","Kanchanaburi","","16147","1764249203"
"Xiaozhengzhuang","Xiaozhengzhuang","39.6250","117.9031","China","CN","CHN","Hebei","","15845","1156126253"
"Santa Cruz Muluá","Santa Cruz Mulua","14.5833","-91.6167","Guatemala","GT","GTM","Retalhuleu","minor","16138","1320315123"
"Brahmadesam","Brahmadesam","11.5449","77.5803","India","IN","IND","Tamil Nādu","","15937","1356163048"
"Pallappatti","Pallappatti","10.3564","77.9516","India","IN","IND","Tamil Nādu","","15620","1356249292"
"Erwitte","Erwitte","51.6167","8.3500","Germany","DE","DEU","North Rhine-Westphalia","","16045","1276225862"
"Olivehurst","Olivehurst","39.0795","-121.5566","United States","US","USA","California","","16262","1840018801"
"Welby","Welby","39.8403","-104.9655","United States","US","USA","Colorado","","16260","1840028449"
"Hilltown","Hilltown","40.3415","-75.2534","United States","US","USA","Pennsylvania","","16260","1840147878"
"Mezőkövesd","Mezokovesd","47.8167","20.5833","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","16098","1348265393"
"Llanquihue","Llanquihue","-41.2581","-73.0086","Chile","CL","CHL","Los Lagos","","16249","1152498352"
"Dewangarh","Dewangarh","24.8637","85.8584","India","IN","IND","Bihār","","15610","1356231194"
"Shāhpur","Shahpur","23.8937","79.0507","India","IN","IND","Madhya Pradesh","","16077","1356440946"
"Porto Belo","Porto Belo","-27.1578","-48.5528","Brazil","BR","BRA","Santa Catarina","","16083","1076716285"
"Landen","Landen","50.7547","5.0814","Belgium","BE","BEL","Flanders","","15961","1056582232"
"Vero Beach","Vero Beach","27.6463","-80.3930","United States","US","USA","Florida","","16254","1840015985"
"Sered’","Sered'","48.2886","17.7314","Slovakia","SK","SVK","Trnava","","15737","1703574353"
"Hathīaundha","Hathiaundha","25.7223","86.9972","India","IN","IND","Bihār","","15168","1356151591"
"Horodok","Horodok","49.7822","23.6436","Ukraine","UA","UKR","Lvivska Oblast","","16158","1804513657"
"Benjamín Aceval","Benjamin Aceval","-24.9703","-57.5667","Paraguay","PY","PRY","Presidente Hayes","","16248","1600309049"
"Bruck an der Mur","Bruck an der Mur","47.4106","15.2686","Austria","AT","AUT","Steiermark","minor","15837","1040863751"
"Gangoli","Gangoli","13.6500","74.6667","India","IN","IND","Karnātaka","","15958","1356041450"
"Merimandroso","Merimandroso","-18.7500","47.5167","Madagascar","MG","MDG","Antananarivo","","14545","1450984411"
"Carhué","Carhue","-37.1796","-62.7601","Argentina","AR","ARG","Buenos Aires","minor","16245","1032595269"
"Upala","Upala","10.8645","-84.9942","Costa Rica","CR","CRI","Alajuela","","16139","1188635884"
"Sapatgrām","Sapatgram","26.3373","90.1236","India","IN","IND","Assam","","16006","1356558749"
"Nonantola","Nonantola","44.6777","11.0430","Italy","IT","ITA","Emilia-Romagna","","15957","1380440392"
"Westport","Westport","41.5886","-71.0837","United States","US","USA","Massachusetts","","16245","1840070239"
"Igaporã","Igapora","-13.7728","-42.7139","Brazil","BR","BRA","Bahia","","16225","1076949627"
"Andemaka","Andemaka","-22.3000","47.7500","Madagascar","MG","MDG","Fianarantsoa","","16000","1450024135"
"Zegzel","Zegzel","34.8407","-2.3543","Morocco","MA","MAR","Oriental","","16137","1504844688"
"Vemulūru","Vemuluru","16.9337","81.8057","India","IN","IND","Andhra Pradesh","","14613","1356414293"
"Udayagiri","Udayagiri","14.8667","79.3167","India","IN","IND","Andhra Pradesh","","15870","1356113273"
"Perchtoldsdorf","Perchtoldsdorf","48.1167","16.2667","Austria","AT","AUT","Niederösterreich","","15047","1040055191"
"Bois-Guillaume","Bois-Guillaume","49.4700","1.1194","France","FR","FRA","Normandie","","14592","1250792031"
"Hermitage","Hermitage","41.2305","-80.4413","United States","US","USA","Pennsylvania","","16240","1840000732"
"Ampahimanga","Ampahimanga","-19.0833","47.2000","Madagascar","MG","MDG","Antananarivo","","16184","1450235819"
"Yaojia","Yaojia","28.4547","109.1831","China","CN","CHN","Chongqing","","16072","1156978908"
"Merate","Merate","45.7000","9.4333","Italy","IT","ITA","Lombardy","","14891","1380531302"
"Pianezza","Pianezza","45.1058","7.5434","Italy","IT","ITA","Piedmont","","15309","1380471240"
"Wülflingen","Wulflingen","47.5100","8.6830","Switzerland","CH","CHE","Zürich","","15096","1756013743"
"Lagoa do Carro","Lagoa do Carro","-7.8450","-35.3200","Brazil","BR","BRA","Pernambuco","","16007","1076947302"
"Certaldo","Certaldo","43.5478","11.0411","Italy","IT","ITA","Tuscany","","16023","1380404782"
"Großenkneten","Grossenkneten","52.9500","8.2667","Germany","DE","DEU","Lower Saxony","","16144","1276069119"
"Moreau","Moreau","43.2469","-73.6659","United States","US","USA","New York","","16235","1840087689"
"Dyersburg","Dyersburg","36.0465","-89.3777","United States","US","USA","Tennessee","","16235","1840013372"
"Ishtixon Shahri","Ishtixon Shahri","39.9664","66.4861","Uzbekistan","UZ","UZB","Samarqand","minor","16000","1860693720"
"Araputanga","Araputanga","-15.4708","-58.3528","Brazil","BR","BRA","Mato Grosso","","16223","1076938208"
"Wolcott","Wolcott","41.6007","-72.9734","United States","US","USA","Connecticut","","16232","1840035673"
"Chautham","Chautham","25.5439","86.6567","India","IN","IND","Bihār","","15107","1356148093"
"Jasper","Jasper","38.3933","-86.9402","United States","US","USA","Indiana","","16231","1840008646"
"Chilgazí","Chilgazi","40.1500","70.7333","Tajikistan","TJ","TJK","Sughd","","15997","1762908199"
"Payson","Payson","34.2433","-111.3195","United States","US","USA","Arizona","","16229","1840022857"
"Buwama","Buwama","0.0633","32.1064","Uganda","UG","UGA","Mpigi","","16000","1800499815"
"Clausthal-Zellerfeld","Clausthal-Zellerfeld","51.8050","10.3356","Germany","DE","DEU","Lower Saxony","","15888","1276565687"
"Mistrató","Mistrato","5.3000","-75.8833","Colombia","CO","COL","Risaralda","minor","16203","1170662238"
"Bīleh Savār","Bileh Savar","39.3778","48.3497","Iran","IR","IRN","Ardabīl","minor","16188","1364239600"
"Çaykara","Caykara","40.7475","40.2419","Turkey","TR","TUR","Trabzon","minor","16213","1792430327"
"Hibbing","Hibbing","47.3980","-92.9486","United States","US","USA","Minnesota","","16224","1840006594"
"San Pedro de Lloc","San Pedro de Lloc","-7.4167","-79.5000","Peru","PE","PER","La Libertad","","16198","1604981283"
"Jhandāpur","Jhandapur","25.3995","86.9479","India","IN","IND","Bihār","","14886","1356240717"
"Wendelstein","Wendelstein","49.3536","11.1483","Germany","DE","DEU","Bavaria","","15909","1276957789"
"Galliate","Galliate","45.4833","8.7000","Italy","IT","ITA","Piedmont","","15685","1380412651"
"Breaza","Breaza","45.1872","25.6622","Romania","RO","ROU","Prahova","","15928","1642547235"
"Kolakalūru","Kolakaluru","16.3036","80.6172","India","IN","IND","Andhra Pradesh","","15607","1356745224"
"Fenoarivo","Fenoarivo","-23.1167","47.5667","Madagascar","MG","MDG","Fianarantsoa","","16000","1450612194"
"San Antonio Palopó","San Antonio Palopo","14.7000","-91.1167","Guatemala","GT","GTM","Sololá","minor","15752","1320477213"
"Fairburn","Fairburn","33.5496","-84.5914","United States","US","USA","Georgia","","16215","1840013662"
"Ikast","Ikast","56.1333","9.1500","Denmark","DK","DNK","Midtjylland","minor","15979","1208187034"
"Santa Anita","Santa Anita","30.4500","-111.0500","Mexico","MX","MEX","Sonora","","16203","1484530456"
"San Gabriel","San Gabriel","0.5983","-77.8353","Ecuador","EC","ECU","Carchi","","16066","1218199820"
"Tahlequah","Tahlequah","35.9112","-94.9770","United States","US","USA","Oklahoma","","16213","1840021700"
"Griffith","Griffith","41.5277","-87.4239","United States","US","USA","Indiana","","16213","1840010225"
"Alabat","Alabat","14.1023","122.0136","Philippines","PH","PHL","Quezon","","15936","1608115829"
"Köprüköy","Koprukoy","39.9756","41.8711","Turkey","TR","TUR","Erzurum","minor","16178","1792880701"
"Elandakuttai","Elandakuttai","11.3992","77.7782","India","IN","IND","Tamil Nādu","","15300","1356166281"
"San Cesareo","San Cesareo","41.8167","12.8000","Italy","IT","ITA","Lazio","","15552","1380153894"
"Fenoarivo","Fenoarivo","-20.8667","46.8833","Madagascar","MG","MDG","Fianarantsoa","","16000","1450147550"
"Bouchabel","Bouchabel","34.3833","-5.0167","Morocco","MA","MAR","Fès-Meknès","","16084","1504000782"
"Radford","Radford","37.1229","-80.5587","United States","US","USA","Virginia","","16205","1840003864"
"Conwy","Conwy","53.2800","-3.8300","United Kingdom","GB","GBR","Conwy","","14723","1826011797"
"Zulte","Zulte","50.9167","3.4333","Belgium","BE","BEL","Flanders","","15720","1056878448"
"Ābīy Ādī","Abiy Adi","13.6231","39.0017","Ethiopia","ET","ETH","Tigray","","16115","1231200318"
"Ponto Novo","Ponto Novo","-10.8619","-40.1339","Brazil","BR","BRA","Bahia","","16168","1076602226"
"Kujwa","Kujwa","33.5025","126.7944","South Korea","KR","KOR","Jeju","","16116","1410125260"
"Caidat Sidi Boubker El Haj","Caidat Sidi Boubker El Haj","34.9148","-6.0735","Morocco","MA","MAR","Rabat-Salé-Kénitra","","15990","1504131696"
"Taucha","Taucha","51.3800","12.4936","Germany","DE","DEU","Saxony","","15733","1276068319"
"Ouadhia","Ouadhia","36.5500","4.0833","Algeria","DZ","DZA","Tizi Ouzou","","15771","1012312650"
"Tangainony","Tangainony","-22.7000","47.7500","Madagascar","MG","MDG","Fianarantsoa","","16000","1450508448"
"Altinópolis","Altinopolis","-21.0231","-47.3728","Brazil","BR","BRA","São Paulo","","16179","1076728807"
"Subachoque","Subachoque","4.9281","-74.1736","Colombia","CO","COL","Cundinamarca","minor","16117","1170356384"
"Les Sables-d’Olonne","Les Sables-d'Olonne","46.4972","-1.7833","France","FR","FRA","Pays de la Loire","minor","14545","1250216385"
"Masmouda","Masmouda","34.7862","-5.7078","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16106","1504193768"
"Alpedrete","Alpedrete","40.6583","-4.0322","Spain","ES","ESP","Madrid","","15006","1724547365"
"Trélazé","Trelaze","47.4461","-0.4664","France","FR","FRA","Pays de la Loire","","14961","1250896370"
"Burrillville","Burrillville","41.9706","-71.6984","United States","US","USA","Rhode Island","","16186","1840106236"
"Sardoba","Sardoba","40.5422","68.4125","Uzbekistan","UZ","UZB","Sirdaryo","","16000","1860363244"
"Totteridge","Totteridge","51.6354","-0.2000","United Kingdom","GB","GBR","Barnet","","15159","1826812709"
"Barra de Santo Antônio","Barra de Santo Antonio","-9.4000","-35.5000","Brazil","BR","BRA","Alagoas","","16068","1076057177"
"Gavinivāripālem","Gavinivaripalem","15.8378","80.4315","India","IN","IND","Andhra Pradesh","","14502","1356149556"
"Babayurt","Babayurt","43.6003","46.7789","Russia","RU","RUS","Dagestan","","16165","1643640362"
"Puduppatti","Puduppatti","11.5500","78.2833","India","IN","IND","Tamil Nādu","","15727","1356253569"
"Bar","Bar","49.0781","27.6831","Ukraine","UA","UKR","Vinnytska Oblast","minor","16136","1804657070"
"Huarmey","Huarmey","-10.0686","-78.1603","Peru","PE","PER","Ancash","","16172","1604582499"
"Lewes","Lewes","50.8747","0.0117","United Kingdom","GB","GBR","East Sussex","","15988","1826774397"
"Bumpe","Bumpe","7.8919","-11.9025","Sierra Leone","SL","SLE","Southern","","16123","1694169724"
"Sulechów","Sulechow","52.0833","15.6167","Poland","PL","POL","Lubuskie","","16063","1616086796"
"Nowogard","Nowogard","53.6667","15.1167","Poland","PL","POL","Zachodniopomorskie","","16102","1616864460"
"Pelhřimov","Pelhrimov","49.4314","15.2234","Czechia","CZ","CZE","Vysočina","","16012","1203256095"
"Yurihama","Yurihama","35.4899","133.8646","Japan","JP","JPN","Tottori","","15972","1392003407"
"Santa Ana","Santa Ana","13.9333","-87.2667","Honduras","HN","HND","Francisco Morazán","","16009","1340709645"
"Aberdeen","Aberdeen","39.5151","-76.1733","United States","US","USA","Maryland","","16176","1840005666"
"As Sukhnah","As Sukhnah","34.8868","38.8721","Syria","SY","SYR","Ḩimş","minor","16173","1760585431"
"Khaira","Khaira","26.3433","87.1476","India","IN","IND","Bihār","","15108","1356244858"
"Parma","Parma","43.2651","-77.7968","United States","US","USA","New York","","16174","1840087782"
"Lake Wales","Lake Wales","27.9195","-81.5961","United States","US","USA","Florida","","16173","1840015107"
"Sāyalkudi","Sayalkudi","9.1692","78.4470","India","IN","IND","Tamil Nādu","","15908","1356108648"
"Finsterwalde","Finsterwalde","51.6282","13.7102","Germany","DE","DEU","Brandenburg","","15968","1276493643"
"Pursa","Pursa","26.3019","86.4778","India","IN","IND","Bihār","","14919","1356602949"
"Rylsk","Rylsk","51.5667","34.6667","Russia","RU","RUS","Kurskaya Oblast’","","16147","1643436531"
"Brüggen","Bruggen","51.2417","6.1822","Germany","DE","DEU","North Rhine-Westphalia","","15907","1276029002"
"São Pedro do Sul","Sao Pedro do Sul","-29.6208","-54.1789","Brazil","BR","BRA","Rio Grande do Sul","","16148","1076597961"
"Skhour Rehamna","Skhour Rehamna","32.4833","-7.9167","Morocco","MA","MAR","Marrakech-Safi","","15479","1504150525"
"Amarwāra","Amarwara","22.2978","79.1694","India","IN","IND","Madhya Pradesh","","15966","1356152620"
"Tankal","Tankal","8.0000","124.0000","Philippines","PH","PHL","Lanao del Norte","","16075","1608156636"
"Vontimitta","Vontimitta","14.3833","79.0333","India","IN","IND","Andhra Pradesh","","16067","1356090391"
"Füssen","Fussen","47.5667","10.7000","Germany","DE","DEU","Bavaria","","15800","1276059513"
"Hampton","Hampton","42.9391","-70.8370","United States","US","USA","New Hampshire","","16163","1840054955"
"Owase","Owase","34.0708","136.1910","Japan","JP","JPN","Mie","","16079","1392003455"
"Getúlio Vargas","Getulio Vargas","-27.8900","-52.2278","Brazil","BR","BRA","Rio Grande do Sul","","16154","1076576171"
"Seven Pagodas","Seven Pagodas","12.6208","80.1933","India","IN","IND","Tamil Nādu","","15172","1356071916"
"Tarqui","Tarqui","2.1106","-75.8231","Colombia","CO","COL","Huila","minor","16108","1170724338"
"Taylor","Taylor","30.5729","-97.4268","United States","US","USA","Texas","","16154","1840022165"
"Potenza Picena","Potenza Picena","43.3667","13.6167","Italy","IT","ITA","Marche","","15827","1380714734"
"Ukrainka","Ukrainka","50.1500","30.7519","Ukraine","UA","UKR","Kyivska Oblast","","16056","1804574309"
"Bni Tajjit","Bni Tajjit","32.2833","-3.4833","Morocco","MA","MAR","Oriental","","16149","1504670713"
"Ropczyce","Ropczyce","50.0500","21.6167","Poland","PL","POL","Podkarpackie","minor","15815","1616760049"
"Caldera","Caldera","-27.0667","-70.8333","Chile","CL","CHL","Atacama","","16150","1152916235"
"Paidiipalli","Paidiipalli","18.0172","79.6159","India","IN","IND","Andhra Pradesh","","14407","1356135654"
"Parrita","Parrita","9.5471","-84.3464","Costa Rica","CR","CRI","Puntarenas","","16115","1188507189"
"Sukth","Sukth","41.3833","19.5333","Albania","AL","ALB","Durrës","","15966","1008647190"
"Hasanpur","Hasanpur","26.1147","87.2319","India","IN","IND","Bihār","","15083","1356626949"
"Toul","Toul","48.6750","5.8917","France","FR","FRA","Grand Est","minor","15633","1250766927"
"Americus","Americus","32.0736","-84.2248","United States","US","USA","Georgia","","16144","1840013841"
"Aruvikkara","Aruvikkara","8.4219","77.0968","India","IN","IND","Tamil Nādu","","15017","1356205251"
"Baléyara","Baleyara","13.7840","2.9500","Niger","NE","NER","Tillabéri","","16063","1562876747"
"West Norriton","West Norriton","40.1308","-75.3794","United States","US","USA","Pennsylvania","","16141","1840133862"
"Aki","Aki","33.5000","133.9000","Japan","JP","JPN","Kōchi","","16090","1392875978"
"Mezőtúr","Mezotur","47.0000","20.6333","Hungary","HU","HUN","Jász-Nagykun-Szolnok","minor","16086","1348445088"
"Warrington","Warrington","30.3835","-87.2946","United States","US","USA","Florida","","16140","1840013914"
"Vardannāpet","Vardannapet","17.7735","79.5731","India","IN","IND","Andhra Pradesh","","15787","1356141021"
"Bni Quolla","Bni Quolla","34.7380","-5.5441","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","16049","1504770766"
"Bad Essen","Bad Essen","52.3214","8.3400","Germany","DE","DEU","Lower Saxony","","15982","1276585776"
"Los Muermos","Los Muermos","-41.4000","-73.4833","Chile","CL","CHL","Los Lagos","","16132","1152894778"
"Sassenheim","Sassenheim","52.2258","4.5225","Netherlands","NL","NLD","Zuid-Holland","minor","14886","1528941330"
"Ambarakaraka","Ambarakaraka","-13.5000","48.8667","Madagascar","MG","MDG","Antsiranana","","16114","1450875169"
"Ndioum","Ndioum","16.5167","-14.6500","Senegal","SN","SEN","Saint-Louis","","16103","1686158908"
"Frankfort","Frankfort","40.2810","-86.5212","United States","US","USA","Indiana","","16134","1840008358"
"Colonial Park","Colonial Park","40.2987","-76.8069","United States","US","USA","Pennsylvania","","16132","1840005398"
"Coulommiers","Coulommiers","48.8156","3.0836","France","FR","FRA","Île-de-France","","14779","1250097723"
"Spanish Springs","Spanish Springs","39.6568","-119.6694","United States","US","USA","Nevada","","16131","1840033826"
"Fullerton","Fullerton","40.6309","-75.4834","United States","US","USA","Pennsylvania","","16131","1840034977"
"Mölnlycke","Molnlycke","57.6667","12.1167","Sweden","SE","SWE","Västra Götaland","minor","15608","1752294225"
"Qazyan","Qazyan","40.4425","47.6767","Azerbaijan","AZ","AZE","Ucar","","16018","1031879880"
"Antadinga","Antadinga","-22.1500","47.4167","Madagascar","MG","MDG","Fianarantsoa","","15977","1450101676"
"Cunit","Cunit","41.1976","1.6345","Spain","ES","ESP","Catalonia","","14622","1724738474"
"Utnūr","Utnur","19.3667","78.7667","India","IN","IND","Andhra Pradesh","","16005","1356434516"
"Hongsi","Hongsi","35.5113","105.5136","China","CN","CHN","Gansu","","15938","1156462347"
"Santa Marta de Tormes","Santa Marta de Tormes","40.9494","-5.6325","Spain","ES","ESP","Castille-Leon","","14661","1724596061"
"Saint-Avertin","Saint-Avertin","47.3667","0.7267","France","FR","FRA","Centre-Val de Loire","","14995","1250209573"
"Ebersbach an der Fils","Ebersbach an der Fils","48.7147","9.5236","Germany","DE","DEU","Baden-Württemberg","","15535","1276554005"
"Calera","Calera","33.1254","-86.7449","United States","US","USA","Alabama","","16126","1840013757"
"Chinggil","Chinggil","46.6660","90.3794","China","CN","CHN","Xinjiang","minor","16098","1156912323"
"Kālikāpur","Kalikapur","26.4956","86.0890","India","IN","IND","Bihār","","15277","1356403754"
"Weener","Weener","53.1692","7.3564","Germany","DE","DEU","Lower Saxony","","15927","1276425095"
"Andapafito","Andapafito","-16.9167","49.3833","Madagascar","MG","MDG","Toamasina","","16000","1450969130"
"Undi","Undi","16.6000","81.4667","India","IN","IND","Andhra Pradesh","","15322","1356723564"
"Samsikāpuram","Samsikapuram","9.4156","77.5917","India","IN","IND","Tamil Nādu","","15379","1356205369"
"Buffalo","Buffalo","45.1794","-93.8644","United States","US","USA","Minnesota","","16121","1840006703"
"Rasulpur Dhuria","Rasulpur Dhuria","25.5693","87.0375","India","IN","IND","Bihār","","14658","1356806028"
"Quiindy","Quiindy","-25.9730","-57.2370","Paraguay","PY","PRY","Paraguarí","","16074","1600822993"
"Easthampton","Easthampton","42.2651","-72.6720","United States","US","USA","Massachusetts","","16120","1840031156"
"Csongrád","Csongrad","46.7113","20.1403","Hungary","HU","HUN","Csongrád-Csanád","minor","16027","1348885069"
"Vieux Fort","Vieux Fort","13.7280","-60.9540","Saint Lucia","LC","LCA","Vieux-Fort","","15750","1662173284"
"Altopascio","Altopascio","43.8167","10.6833","Italy","IT","ITA","Tuscany","","15572","1380450023"
"Kodinsk","Kodinsk","58.6833","99.1833","Russia","RU","RUS","Krasnoyarskiy Kray","","16116","1643232820"
"Chāilāha","Chailaha","26.6738","84.8801","India","IN","IND","Bihār","","14857","1356011638"
"Valmontone","Valmontone","41.7833","12.9167","Italy","IT","ITA","Lazio","","16073","1380441456"
"Mahavelona","Mahavelona","-18.5167","47.2500","Madagascar","MG","MDG","Antananarivo","","16028","1450216247"
"Tracadie","Tracadie","47.5124","-64.9101","Canada","CA","CAN","New Brunswick","","16114.00","1124362021"
"Ternat","Ternat","50.8667","4.1833","Belgium","BE","BEL","Flanders","","15481","1056099349"
"Alsfeld","Alsfeld","50.7511","9.2711","Germany","DE","DEU","Hesse","","15989","1276584676"
"Dalachi","Dalachi","36.6392","105.0156","China","CN","CHN","Gansu","","16075","1156098982"
"Minamiminowa","Minamiminowa","35.8729","137.9751","Japan","JP","JPN","Nagano","","15727","1392003230"
"Kanan","Kanan","34.4917","135.6296","Japan","JP","JPN","Ōsaka","","15497","1392003300"
"Şemdinli","Semdinli","37.3080","44.5710","Turkey","TR","TUR","Hakkâri","minor","16079","1792975891"
"Quezon","Quezon","14.0500","122.1333","Philippines","PH","PHL","Quezon","","15886","1608656453"
"Bad Bentheim","Bad Bentheim","52.3031","7.1597","Germany","DE","DEU","Lower Saxony","","15948","1276319622"
"Fartura","Fartura","-23.3883","-49.5100","Brazil","BR","BRA","São Paulo","","16070","1076486558"
"Prinzapolka","Prinzapolka","13.5008","-84.2244","Nicaragua","NI","NIC","Costa Caribe Norte","minor","16105","1558643619"
"Chenôve","Chenove","47.2911","5.0072","France","FR","FRA","Bourgogne-Franche-Comté","","14216","1250661547"
"Jitwārpur Kumhra","Jitwarpur Kumhra","25.7852","85.7044","India","IN","IND","Bihār","","14160","1356139201"
"Mananasy-Tsitakondaza","Mananasy-Tsitakondaza","-19.1500","46.6500","Madagascar","MG","MDG","Antananarivo","","16000","1450104138"
"Degtyarsk","Degtyarsk","56.7000","60.1167","Russia","RU","RUS","Sverdlovskaya Oblast’","","16001","1643565822"
"Aquitania","Aquitania","5.5833","-72.8333","Colombia","CO","COL","Boyacá","minor","16087","1170697722"
"Readington","Readington","40.5822","-74.7796","United States","US","USA","New Jersey","","16104","1840081708"
"Matelândia","Matelandia","-25.2408","-53.9958","Brazil","BR","BRA","Paraná","","16078","1076088305"
"Hüyük","Huyuk","37.9519","31.5958","Turkey","TR","TUR","Konya","minor","16073","1792361232"
"Hariharpāra","Hariharpara","24.0468","88.4244","India","IN","IND","West Bengal","","14827","1356090743"
"Cipanas","Cipanas","-6.7330","107.0410","Indonesia","ID","IDN","Jawa Barat","","15435","1360915257"
"Sérarou","Serarou","9.5833","2.6500","Benin","BJ","BEN","Borgou","","16060","1204060961"
"Venturosa","Venturosa","-8.5747","-36.8742","Brazil","BR","BRA","Pernambuco","","16052","1076535367"
"Myrza-Ake","Myrza-Ake","40.7500","73.4100","Kyrgyzstan","KG","KGZ","Osh","","16011","1417504242"
"Barharwā","Barharwa","24.8571","87.7790","India","IN","IND","Jhārkhand","","14863","1356166271"
"Khat Azakane","Khat Azakane","32.2226","-9.1343","Morocco","MA","MAR","Marrakech-Safi","","16014","1504377421"
"Qarabulaq","Qarabulaq","44.9089","78.4922","Kazakhstan","KZ","KAZ","","","16037","1398637758"
"Fiadanana","Fiadanana","-20.8667","47.1833","Madagascar","MG","MDG","Fianarantsoa","","16000","1450506769"
"Beaucaire","Beaucaire","43.8072","4.6433","France","FR","FRA","Occitanie","","15906","1250121839"
"Alcañiz","Alcaniz","41.0511","-0.1336","Spain","ES","ESP","Aragon","","16054","1724184358"
"Nagasu","Nagasu","32.9297","130.4528","Japan","JP","JPN","Kumamoto","","15299","1392244225"
"Thundersley","Thundersley","51.5700","0.5900","United Kingdom","GB","GBR","Essex","","15600","1826836917"
"Clarksburg","Clarksburg","39.2862","-80.3232","United States","US","USA","West Virginia","","16085","1840005767"
"Glória","Gloria","-9.3389","-38.2569","Brazil","BR","BRA","Bahia","","16072","1076000836"
"Shāhpura","Shahpura","23.1366","79.6640","India","IN","IND","Madhya Pradesh","","15784","1356043628"
"Léré","Lere","15.7117","-4.9117","Mali","ML","MLI","Tombouctou","","16072","1466628152"
"Lübbenau/Spreewald","Lubbenau/Spreewald","51.8667","13.9667","Germany","DE","DEU","Brandenburg","","15969","1276392829"
"Paola","Paola","39.3667","16.0333","Italy","IT","ITA","Calabria","","15716","1380181514"
"Despujols","Despujols","12.5183","122.0108","Philippines","PH","PHL","Romblon","","15940","1608108433"
"Ambohibe","Ambohibe","-17.4500","49.1167","Madagascar","MG","MDG","Toamasina","","16000","1450755165"
"Federal","Federal","-30.9500","-58.8000","Argentina","AR","ARG","Entre Ríos","minor","16075","1032401212"
"Leanja","Leanja","-15.5500","47.9333","Madagascar","MG","MDG","Mahajanga","","16000","1450058429"
"Androrangavola","Androrangavola","-20.5167","48.2333","Madagascar","MG","MDG","Fianarantsoa","","16000","1450693503"
"Heggadadevankote","Heggadadevankote","12.0881","76.3296","India","IN","IND","Karnātaka","","15899","1356368626"
"Vijayapuri North","Vijayapuri North","16.6028","79.3075","India","IN","IND","Andhra Pradesh","","15887","1356296261"
"Antarvedi","Antarvedi","16.3333","81.7333","India","IN","IND","Andhra Pradesh","","15605","1356744905"
"Darién","Darien","3.9167","-76.6667","Colombia","CO","COL","Valle del Cauca","minor","16054","1170479782"
"Naivasha","Naivasha","-0.7167","36.4333","Kenya","KE","KEN","Nakuru","","14563","1404904927"
"Ambalatany","Ambalatany","-22.5333","47.6000","Madagascar","MG","MDG","Fianarantsoa","","16000","1450788430"
"Issoire","Issoire","45.5442","3.2489","France","FR","FRA","Auvergne-Rhône-Alpes","minor","15296","1250940085"
"Alarobia","Alarobia","-18.9667","47.7333","Madagascar","MG","MDG","Antananarivo","","16000","1450929281"
"Schlüchtern","Schluchtern","50.3500","9.5167","Germany","DE","DEU","Hesse","","15927","1276713332"
"Dublin","Dublin","32.5360","-82.9280","United States","US","USA","Georgia","","16067","1840013813"
"Mount Pleasant","Mount Pleasant","33.1586","-94.9727","United States","US","USA","Texas","","16067","1840020666"
"Betanty","Betanty","-25.5667","45.5167","Madagascar","MG","MDG","Toliara","","16000","1450311726"
"Westwood","Westwood","42.2202","-71.2106","United States","US","USA","Massachusetts","","16066","1840053561"
"Palmitos","Palmitos","-27.0678","-53.1608","Brazil","BR","BRA","Santa Catarina","","16020","1076113773"
"Zunil","Zunil","14.7836","-91.4844","Guatemala","GT","GTM","Quetzaltenango","minor","15893","1320355897"
"Sāligrāma","Saligrama","12.5602","76.2611","India","IN","IND","Karnātaka","","15549","1356676972"
"Boudjima","Boudjima","36.8140","4.1588","Algeria","DZ","DZA","Tizi Ouzou","","15628","1012379618"
"Carterton","Carterton","51.7600","-1.5900","United Kingdom","GB","GBR","Oxfordshire","","15680","1826750065"
"Stäfa","Stafa","47.2400","8.7300","Switzerland","CH","CHE","Zürich","","14389","1756312480"
"Dinan","Dinan","48.4556","-2.0503","France","FR","FRA","Bretagne","minor","14407","1250726966"
"Neustadt in Holstein","Neustadt in Holstein","54.1072","10.8158","Germany","DE","DEU","Schleswig-Holstein","","15288","1276780766"
"Vohiposa","Vohiposa","-20.9833","47.1500","Madagascar","MG","MDG","Fianarantsoa","","16000","1450467200"
"Tachiarai","Tachiarai","33.3724","130.6224","Japan","JP","JPN","Fukuoka","","15387","1392006046"
"Red Hill","Red Hill","33.7777","-79.0111","United States","US","USA","South Carolina","","16060","1840013649"
"L’Oulja","L'Oulja","34.2894","-4.9481","Morocco","MA","MAR","Fès-Meknès","","15956","1504438935"
"Doğanhisar","Doganhisar","38.1447","31.6781","Turkey","TR","TUR","Konya","minor","16029","1792413713"
"River Falls","River Falls","44.8609","-92.6247","United States","US","USA","Wisconsin","","16058","1840002289"
"Tsiningia","Tsiningia","-15.4833","47.4500","Madagascar","MG","MDG","Mahajanga","","16000","1450406103"
"Vohilengo","Vohilengo","-22.5333","47.6833","Madagascar","MG","MDG","Fianarantsoa","","16000","1450242299"
"Ranohira","Ranohira","-22.4333","45.3500","Madagascar","MG","MDG","Toliara","","16041","1450777804"
"Doria Sonāpur","Doria Sonapur","26.1830","87.3873","India","IN","IND","Bihār","","14374","1356739647"
"Sikeston","Sikeston","36.8854","-89.5877","United States","US","USA","Missouri","","16054","1840009921"
"Bay Village","Bay Village","41.4851","-81.9317","United States","US","USA","Ohio","","16051","1840003394"
"Azzano Decimo","Azzano Decimo","45.8833","12.7167","Italy","IT","ITA","Friuli Venezia Giulia","","15744","1380971345"
"Sansepolcro","Sansepolcro","43.5756","12.1439","Italy","IT","ITA","Tuscany","","15876","1380207996"
"Dūbacherla","Dubacherla","16.9116","81.3569","India","IN","IND","Andhra Pradesh","","15725","1356077652"
"Taromske","Taromske","48.4619","34.7829","Ukraine","UA","UKR","Dnipropetrovska Oblast","","15838","1804000443"
"Inanantonana","Inanantonana","-19.6500","46.6167","Madagascar","MG","MDG","Antananarivo","","16000","1450953986"
"Bambous","Bambous","-20.2600","57.4148","Mauritius","MU","MUS","Black River","","15345","1480968056"
"Hetton le Hole","Hetton le Hole","54.8210","-1.4490","United Kingdom","GB","GBR","Sunderland","","14402","1826816233"
"Maigh Nuad","Maigh Nuad","53.3816","-6.5910","Ireland","IE","IRL","Kildare","","14585","1372438535"
"Bevonotra","Bevonotra","-14.0500","49.6167","Madagascar","MG","MDG","Antsiranana","","16000","1450295554"
"Sveti Ivan Zelina","Sveti Ivan Zelina","45.9596","16.2431","Croatia","HR","HRV","Zagrebačka Županija","minor","15959","1191628730"
"Grandville","Grandville","42.9004","-85.7564","United States","US","USA","Michigan","","16043","1840002929"
"Majdal Shams","Majdal Shams","33.2692","35.7706","Israel","IL","ISR","Northern","","15973","1376000007"
"Antindra","Antindra","-14.1333","49.8167","Madagascar","MG","MDG","Antsiranana","","16000","1450679608"
"Nova Ipixuna","Nova Ipixuna","-4.9208","-49.0769","Brazil","BR","BRA","Tocantins","","16032","1076910776"
"Anjangoveratra","Anjangoveratra","-14.1333","50.0833","Madagascar","MG","MDG","Antsiranana","","16000","1450405493"
"Milford","Milford","42.8178","-71.6736","United States","US","USA","New Hampshire","","16041","1840055182"
"Türkeli","Turkeli","41.9486","34.3367","Turkey","TR","TUR","Sinop","minor","16004","1792240414"
"Mohanpur Gaughāta","Mohanpur Gaughata","25.3345","87.3404","India","IN","IND","Bihār","","14388","1356309943"
"South Middleton","South Middleton","40.1324","-77.1642","United States","US","USA","Pennsylvania","","16039","1840152898"
"Corbera de Llobregat","Corbera de Llobregat","41.4169","1.9314","Spain","ES","ESP","Catalonia","","15210","1724982789"
"Kumārīpur","Kumaripur","25.4425","87.6217","India","IN","IND","Bihār","","14815","1356335845"
"Todmorden","Todmorden","53.7130","-2.0960","United Kingdom","GB","GBR","Calderdale","","15481","1826615743"
"Dianga","Dianga","34.0587","103.2074","China","CN","CHN","Gansu","minor","15993","1156555088"
"Chornobaivka","Chornobaivka","46.7006","32.5478","Ukraine","UA","UKR","Khersonska Oblast","","15984","1804826781"
"Dhemāji","Dhemaji","27.4833","94.5833","India","IN","IND","Assam","","15659","1356876792"
"Tizi-n-Tleta","Tizi-n-Tleta","36.5457","4.0571","Algeria","DZ","DZA","Tizi Ouzou","","15479","1012925920"
"Analila","Analila","-14.4500","49.0500","Madagascar","MG","MDG","Mahajanga","","16000","1450364545"
"Bitburg","Bitburg","49.9667","6.5333","Germany","DE","DEU","Rhineland-Palatinate","minor","15700","1276110282"
"Forks","Forks","40.7358","-75.2211","United States","US","USA","Pennsylvania","","16029","1840149342"
"Az Zintān","Az Zintan","31.9306","12.2483","Libya","LY","LBY","Al Jabal al Gharbī","","16024","1434009562"
"Pornic","Pornic","47.1156","-2.1033","France","FR","FRA","Pays de la Loire","","15859","1250278368"
"Antsenavolo","Antsenavolo","-21.4000","48.0500","Madagascar","MG","MDG","Fianarantsoa","","16000","1450984218"
"Buçimas","Bucimas","40.8917","20.6806","Albania","AL","ALB","Korçë","","15687","1008557449"
"Kasli","Kasli","55.9000","60.7667","Russia","RU","RUS","Chelyabinskaya Oblast’","","16013","1643483987"
"Tarascon","Tarascon","43.8050","4.6594","France","FR","FRA","Provence-Alpes-Côte d’Azur","","15811","1250228954"
"Manompana","Manompana","-16.6833","49.7333","Madagascar","MG","MDG","Toamasina","","16000","1450170559"
"Mangabe","Mangabe","-16.7167","46.8167","Madagascar","MG","MDG","Mahajanga","","16000","1450285321"
"San Tomas","San Tomas","15.8792","120.5883","Philippines","PH","PHL","Pangasinan","","14878","1608865943"
"Vīraghattam","Viraghattam","18.6833","83.6000","India","IN","IND","Andhra Pradesh","","14315","1356596508"
"Prospect Heights","Prospect Heights","42.1039","-87.9267","United States","US","USA","Illinois","","16021","1840009184"
"Ankazondandy","Ankazondandy","-18.6833","47.8167","Madagascar","MG","MDG","Antananarivo","","16000","1450557561"
"Bilpura","Bilpura","23.2216","79.9917","India","IN","IND","Madhya Pradesh","","15601","1356052061"
"Shchigry","Shchigry","51.8667","36.9333","Russia","RU","RUS","Kurskaya Oblast’","","15292","1643401048"
"Manturovo","Manturovo","58.3333","44.7667","Russia","RU","RUS","Kostromskaya Oblast’","","15203","1643951602"
"Villers-lès-Nancy","Villers-les-Nancy","48.6731","6.1547","France","FR","FRA","Grand Est","","14558","1250058932"
"Râşnov","Rasnov","45.5933","25.4603","Romania","RO","ROU","Braşov","","15920","1642579613"
"Neston","Neston","53.2890","-3.0740","United Kingdom","GB","GBR","Cheshire West and Chester","","15221","1826478798"
"Bekitro","Bekitro","-24.5500","45.3000","Madagascar","MG","MDG","Toliara","","16000","1450444164"
"Fīshvar","Fishvar","27.7975","53.6850","Iran","IR","IRN","Fārs","","16000","1364755324"
"Yorito","Yorito","15.0600","-87.2900","Honduras","HN","HND","Yoro","","15774","1340550354"
"Mandrosonoro","Mandrosonoro","-20.5833","46.0000","Madagascar","MG","MDG","Fianarantsoa","","16000","1450073615"
"Piancó","Pianco","-7.1978","-37.9289","Brazil","BR","BRA","Paraíba","","15985","1076544994"
"Bracebridge","Bracebridge","45.0333","-79.3000","Canada","CA","CAN","Ontario","","16010","1124793645"
"Świdwin","Swidwin","53.7833","15.7667","Poland","PL","POL","Zachodniopomorskie","minor","15338","1616092118"
"Kings Park","Kings Park","40.8881","-73.2475","United States","US","USA","New York","","16008","1840005085"
"Washington","Washington","40.7050","-89.4340","United States","US","USA","Illinois","","16008","1840010377"
"Kastsyukovichy","Kastsyukovichy","53.3333","32.0500","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","15993","1112780059"
"San Miguel Dueñas","San Miguel Duenas","14.5167","-90.8000","Guatemala","GT","GTM","Sacatepéquez","minor","15562","1320250757"
"Ban Dung","Ban Dung","17.6986","103.2611","Thailand","TH","THA","Udon Thani","minor","15913","1764905558"
"Duxbury","Duxbury","42.0465","-70.7139","United States","US","USA","Massachusetts","","16004","1840053566"
"Summerfield","Summerfield","38.9042","-76.8678","United States","US","USA","Maryland","","16002","1840039531"
"Kamyzyak","Kamyzyak","46.1167","48.0833","Russia","RU","RUS","Astrakhanskaya Oblast’","","15984","1643101212"
"Alloa","Alloa","56.1160","-3.7930","United Kingdom","GB","GBR","Clackmannanshire","","14420","1826126712"
"Kafia Kingi","Kafia Kingi","9.2731","24.4174","South Sudan","SS","SSD","Western Bahr el Ghazal","","16000","1728934320"
"Vlagtwedde","Vlagtwedde","52.9500","7.1500","Netherlands","NL","NLD","Groningen","","15908","1528022418"
"Caetano","Caetano","-14.3378","-40.9100","Brazil","BR","BRA","Bahia","","15982","1076642327"
"Pamiers","Pamiers","43.1164","1.6108","France","FR","FRA","Occitanie","minor","15659","1250154239"
"Darpa","Darpa","26.8400","84.9774","India","IN","IND","Bihār","","14562","1356075097"
"Tanakpur","Tanakpur","29.0740","80.1090","India","IN","IND","Uttarākhand","","15810","1356824248"
"Gatesville","Gatesville","31.4445","-97.7317","United States","US","USA","Texas","","15997","1840020825"
"Chiconquiaco","Chiconquiaco","19.7500","-96.8167","Mexico","MX","MEX","Veracruz","minor","14394","1484230887"
"Yaransk","Yaransk","57.3167","47.9000","Russia","RU","RUS","Kirovskaya Oblast’","","15985","1643107810"
"Üsharal","Usharal","46.1697","80.9394","Kazakhstan","KZ","KAZ","","","15991","1398996101"
"Poço Fundo","Poco Fundo","-21.7808","-45.9650","Brazil","BR","BRA","Minas Gerais","","15959","1076793223"
"Barysh","Barysh","53.6500","47.1000","Russia","RU","RUS","Ul’yanovskaya Oblast’","","15974","1643221892"
"Chervonopartyzansk","Chervonopartyzansk","48.0833","39.7833","Ukraine","UA","UKR","Luhanska Oblast","","15218","1804816840"
"Santa Catalina","Santa Catalina","17.5917","120.3644","Philippines","PH","PHL","Ilocos Sur","","14493","1608658662"
"West Richland","West Richland","46.3115","-119.3999","United States","US","USA","Washington","","15988","1840021168"
"Ernagūdem","Ernagudem","17.0020","81.5063","India","IN","IND","Andhra Pradesh","","15572","1356062617"
"Bastogne","Bastogne","50.0042","5.7200","Belgium","BE","BEL","Wallonia","minor","15894","1056747497"
"Chivolo","Chivolo","10.0261","-74.6211","Colombia","CO","COL","Magdalena","minor","15960","1170052767"
"Androy","Androy","-21.3333","47.2667","Madagascar","MG","MDG","Fianarantsoa","","15938","1450421324"
"Ganjām","Ganjam","19.3870","85.0508","India","IN","IND","","","14938","1356354762"
"Haddada","Haddada","34.2236","-6.5111","Morocco","MA","MAR","Rabat-Salé-Kénitra","","15898","1504933242"
"Sabana Grande de Palenque","Sabana Grande de Palenque","18.2667","-70.1500","Dominican Republic","DO","DOM","Valdesia","","15466","1214912665"
"Pochampalli","Pochampalli","17.3473","78.8195","India","IN","IND","Andhra Pradesh","","15704","1356196811"
"Gargždai","Gargzdai","55.7128","21.4033","Lithuania","LT","LTU","Klaipėda","","15932","1440368270"
"Ghomrassen","Ghomrassen","33.0592","10.3400","Tunisia","TN","TUN","Tataouine","","15957","1788309516"
"Murraysville","Murraysville","34.2919","-77.8429","United States","US","USA","North Carolina","","15976","1840013631"
"Avalpūndurai","Avalpundurai","11.2310","77.7182","India","IN","IND","Tamil Nādu","","14840","1356164216"
"Middleburg Heights","Middleburg Heights","41.3696","-81.8150","United States","US","USA","Ohio","","15975","1840000614"
"Yelur","Yelur","15.7817","74.5178","India","IN","IND","Karnātaka","","14984","1356581985"
"Puliyūr","Puliyur","10.9499","78.1453","India","IN","IND","Tamil Nādu","","15168","1356118165"
"Kyabé","Kyabe","9.4514","18.9447","Chad","TD","TCD","Moyen-Chari","","15960","1148010568"
"Calbuco","Calbuco","-41.7667","-73.1333","Chile","CL","CHL","Los Lagos","","15903","1152875669"
"Chembagarāmanpudūr","Chembagaramanpudur","8.2461","77.4907","India","IN","IND","Tamil Nādu","","15602","1356228551"
"Hot Springs Village","Hot Springs Village","34.6566","-92.9643","United States","US","USA","Arkansas","","15970","1840013587"
"Coquimbito","Coquimbito","-32.9667","-68.7500","Argentina","AR","ARG","Mendoza","","15678","1032818057"
"Muscle Shoals","Muscle Shoals","34.7432","-87.6343","United States","US","USA","Alabama","","15969","1840014657"
"Dehaq","Dehaq","33.1047","50.9589","Iran","IR","IRN","Eşfahān","","15828","1364662944"
"Kadaiyam","Kadaiyam","8.8320","77.3570","India","IN","IND","Tamil Nādu","","15576","1356657382"
"Blanquefort","Blanquefort","44.9106","-0.6375","France","FR","FRA","Nouvelle-Aquitaine","","15510","1250384882"
"Pawai","Pawai","24.2664","80.1620","India","IN","IND","Madhya Pradesh","","15845","1356453090"
"Middle Smithfield","Middle Smithfield","41.0918","-75.1031","United States","US","USA","Pennsylvania","","15966","1840150869"
"Dunaivtsi","Dunaivtsi","48.8873","26.8476","Ukraine","UA","UKR","Khmelnytska Oblast","","15914","1804592967"
"Bhagta","Bhagta","30.4882","75.1013","India","IN","IND","Punjab","","15587","1356054484"
"Ripon","Ripon","37.7417","-121.1310","United States","US","USA","California","","15961","1840020264"
"Batalha","Batalha","39.6500","-8.8000","Portugal","PT","PRT","Leiria","minor","15805","1620446432"
"Ərkivan","Arkivan","39.0183","48.6500","Azerbaijan","AZ","AZE","Masallı","","15654","1031692548"
"Sahambala","Sahambala","-17.9417","49.1083","Madagascar","MG","MDG","Toamasina","","15850","1450273239"
"Kumaralingam","Kumaralingam","10.4894","77.3499","India","IN","IND","Tamil Nādu","","15535","1356001816"
"Xixucun","Xixucun","36.6940","113.7967","China","CN","CHN","Hebei","","15683","1156446167"
"Vatomandry","Vatomandry","-19.3308","48.9781","Madagascar","MG","MDG","Toamasina","","15836","1450604534"
"Fremont","Fremont","41.3535","-83.1147","United States","US","USA","Ohio","","15954","1840000653"
"Augustinópolis","Augustinopolis","-5.4658","-47.8878","Brazil","BR","BRA","Tocantins","","15950","1076224438"
"Rescaldina","Rescaldina","45.6167","8.9500","Italy","IT","ITA","Lombardy","","14185","1380000133"
"Talitsa","Talitsa","57.0167","63.7167","Russia","RU","RUS","Sverdlovskaya Oblast’","","15941","1643272531"
"Aveiro","Aveiro","-3.6058","-55.3319","Brazil","BR","BRA","Pará","","15950","1076478075"
"Esquipulas","Esquipulas","12.6639","-85.7881","Nicaragua","NI","NIC","Matagalpa","minor","15877","1558602400"
"San Julián","San Julian","61.5240","105.3188","Mexico","MX","MEX","Jalisco","minor","15890","1484367855"
"Firestone","Firestone","40.1565","-104.9494","United States","US","USA","Colorado","","15949","1840021363"
"Bocşa","Bocsa","45.3747","21.7106","Romania","RO","ROU","Caraş-Severin","","15842","1642424364"
"Tarancón","Tarancon","40.0167","-3.0000","Spain","ES","ESP","Castille-La Mancha","","15799","1724475180"
"Brofodoumé","Brofodoume","5.5167","-3.9333","Côte d'Ivoire","CI","CIV","Abidjan","","15842","1384691434"
"Plavsk","Plavsk","53.7167","37.2833","Russia","RU","RUS","Tul’skaya Oblast’","","15920","1643003936"
"Contenda","Contenda","-25.6758","-49.5350","Brazil","BR","BRA","Paraná","","15891","1076154987"
"Galván","Galvan","18.5039","-71.3400","Dominican Republic","DO","DOM","Enriquillo","","15869","1214696051"
"Chhāpia","Chhapia","26.0831","84.8447","India","IN","IND","Bihār","","14710","1356064201"
"Jitwārpur Nizāmat","Jitwarpur Nizamat","25.8489","85.8006","India","IN","IND","Bihār","","14571","1356394181"
"Castanet-Tolosan","Castanet-Tolosan","43.5156","1.4981","France","FR","FRA","Occitanie","","14213","1250448014"
"The Dalles","The Dalles","45.6053","-121.1818","United States","US","USA","Oregon","","15942","1840021212"
"Saraland","Saraland","30.8479","-88.1004","United States","US","USA","Alabama","","15942","1840015886"
"Lidzbark Warmiński","Lidzbark Warminski","54.1167","20.5833","Poland","PL","POL","Warmińsko-Mazurskie","minor","14878","1616280672"
"Chitila","Chitila","44.5083","25.9819","Romania","RO","ROU","Ilfov","","14762","1642960997"
"Bikin","Bikin","46.8167","134.2667","Russia","RU","RUS","Khabarovskiy Kray","","15928","1643608189"
"Maardu","Maardu","59.4781","25.0161","Estonia","EE","EST","Maardu","minor","15284","1233565021"
"Jastrebarsko","Jastrebarsko","45.6719","15.6511","Croatia","HR","HRV","Zagrebačka Županija","minor","15866","1191489216"
"La Grange","La Grange","41.6787","-73.8029","United States","US","USA","New York","","15935","1840087585"
"Aramil","Aramil","56.7000","60.8333","Russia","RU","RUS","Sverdlovskaya Oblast’","","15236","1643755465"
"Luzhou","Luzhou","23.3687","114.5196","China","CN","CHN","Guangdong","","15890","1156708150"
"Rutland","Rutland","43.6092","-72.9783","United States","US","USA","Vermont","","15934","1840002632"
"Marghita","Marghita","47.3500","22.3333","Romania","RO","ROU","Bihor","","15770","1642848546"
"Stallings","Stallings","35.1088","-80.6598","United States","US","USA","North Carolina","","15932","1840017878"
"Amāri","Amari","25.7179","86.0839","India","IN","IND","Bihār","","14034","1356096170"
"North Fayette","North Fayette","40.4204","-80.2245","United States","US","USA","Pennsylvania","","15930","1840148209"
"Kolnād","Kolnad","12.9000","75.0300","India","IN","IND","Karnātaka","","15313","1356761377"
"Volpiano","Volpiano","45.2000","7.7833","Italy","IT","ITA","Piedmont","","15453","1380955438"
"Marco Island","Marco Island","25.9330","-81.6993","United States","US","USA","Florida","","15929","1840036141"
"San Martino Buon Albergo","San Martino Buon Albergo","45.4167","11.1000","Italy","IT","ITA","Veneto","","15482","1380954715"
"Aïn Mediouna","Ain Mediouna","34.5000","-4.5500","Morocco","MA","MAR","Fès-Meknès","","15770","1504603767"
"Comarapa","Comarapa","-17.9158","-64.5300","Bolivia","BO","BOL","Santa Cruz","","15919","1068701913"
"Tabatinga","Tabatinga","-21.7169","-48.6878","Brazil","BR","BRA","São Paulo","","15881","1076705867"
"Bellevue","Bellevue","44.4592","-87.9550","United States","US","USA","Wisconsin","","15922","1840000308"
"New Haven","New Haven","41.0676","-85.0174","United States","US","USA","Indiana","","15922","1840008262"
"Coos Bay","Coos Bay","43.3789","-124.2330","United States","US","USA","Oregon","","15921","1840018653"
"Muhammadābād","Muhammadabad","16.8731","77.8909","India","IN","IND","Andhra Pradesh","","15578","1356713109"
"Maryborough","Maryborough","-25.5375","152.7019","Australia","AU","AUS","Queensland","","15406","1036205623"
"Bredasdorp","Bredasdorp","-34.5333","20.0417","South Africa","ZA","ZAF","Western Cape","","14936","1710282172"
"Gardendale","Gardendale","33.6677","-86.8069","United States","US","USA","Alabama","","15920","1840002820"
"Dassari","Dassari","10.8158","1.1406","Benin","BJ","BEN","Atacora","","15839","1204472631"
"Poxoréo","Poxoreo","-15.8369","-54.3889","Brazil","BR","BRA","Mato Grosso","","15916","1076239905"
"Mottola","Mottola","40.6333","17.0333","Italy","IT","ITA","Puglia","","15842","1380141881"
"Sertã","Serta","39.8000","-8.1000","Portugal","PT","PRT","Castelo Branco","minor","15880","1620976292"
"Tocantins","Tocantins","-21.1750","-43.0178","Brazil","BR","BRA","Minas Gerais","","15823","1076558658"
"Fairhaven","Fairhaven","41.6394","-70.8732","United States","US","USA","Massachusetts","","15911","1840053521"
"Cardonal","Cardonal","20.6167","-99.1167","Mexico","MX","MEX","Hidalgo","","15876","1484936605"
"Roh","Roh","24.8905","85.6750","India","IN","IND","Bihār","","14467","1356630941"
"São Paulo do Potengi","Sao Paulo do Potengi","-5.8950","-35.7628","Brazil","BR","BRA","Rio Grande do Norte","","15843","1076910967"
"Overland","Overland","38.6966","-90.3689","United States","US","USA","Missouri","","15908","1840009744"
"Curtorim","Curtorim","15.2800","74.0300","India","IN","IND","Goa","","14766","1356468637"
"Königslutter am Elm","Konigslutter am Elm","52.2500","10.8167","Germany","DE","DEU","Lower Saxony","","15786","1276192101"
"Beernem","Beernem","51.1428","3.3408","Belgium","BE","BEL","Flanders","","15687","1056159118"
"San Bartolomé Jocotenango","San Bartolome Jocotenango","15.1928","-91.0775","Guatemala","GT","GTM","Quiché","minor","15777","1320987645"
"Warsaw","Warsaw","41.2448","-85.8465","United States","US","USA","Indiana","","15905","1840010277"
"Joaquim Nabuco","Joaquim Nabuco","-8.6239","-35.5328","Brazil","BR","BRA","Pernambuco","","15773","1076344424"
"Ezzhiliga","Ezzhiliga","33.3000","-6.5333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","15430","1504882785"
"Puraini","Puraini","25.5968","86.9892","India","IN","IND","Bihār","","14381","1356050161"
"Richmond Hill","Richmond Hill","31.9012","-81.3125","United States","US","USA","Georgia","","15900","1840014913"
"Lewiston","Lewiston","43.1793","-78.9710","United States","US","USA","New York","","15899","1840004272"
"Tamazouzt","Tamazouzt","31.3833","-8.3833","Morocco","MA","MAR","Marrakech-Safi","","15846","1504120032"
"Fort Drum","Fort Drum","44.0450","-75.7845","United States","US","USA","New York","","15895","1840073579"
"Dvůr Králové nad Labem","Dvur Kralove nad Labem","50.4331","15.8122","Czechia","CZ","CZE","Královéhradecký Kraj","","15471","1203492959"
"Bituruna","Bituruna","-26.1608","-51.5528","Brazil","BR","BRA","Paraná","","15880","1076922643"
"Sidi Allal el Bahraoui","Sidi Allal el Bahraoui","33.9830","-6.4170","Morocco","MA","MAR","Rabat-Salé-Kénitra","","15866","1504503519"
"Berea","Berea","34.8802","-82.4650","United States","US","USA","South Carolina","","15892","1840013495"
"Greater Napanee","Greater Napanee","44.2500","-76.9500","Canada","CA","CAN","Ontario","","15892","1124001319"
"Breisach am Rhein","Breisach am Rhein","48.0333","7.5833","Germany","DE","DEU","Baden-Württemberg","","15606","1276880866"
"Skvyra","Skvyra","49.7333","29.6833","Ukraine","UA","UKR","Kyivska Oblast","minor","15889","1804833632"
"Julita","Julita","10.9731","124.9622","Philippines","PH","PHL","Leyte","","15598","1608180902"
"Al Mu‘abbadah","Al Mu\`abbadah","37.0164","41.9544","Syria","SY","SYR","Al Ḩasakah","","15759","1760650060"
"Yuvileine","Yuvileine","48.5531","39.1742","Ukraine","UA","UKR","Luhanska Oblast","","15830","1804471616"
"Ivdel","Ivdel","60.6833","60.4167","Russia","RU","RUS","Sverdlovskaya Oblast’","","15888","1643246085"
"Nuth","Nuth","50.9167","5.8833","Netherlands","NL","NLD","Limburg","minor","15423","1528559023"
"Aldine","Aldine","29.9123","-95.3784","United States","US","USA","Texas","","15887","1840018250"
"Pichhor","Pichhor","25.9602","78.3893","India","IN","IND","Madhya Pradesh","","15477","1356491423"
"Opelousas","Opelousas","30.5252","-92.0816","United States","US","USA","Louisiana","","15884","1840015014"
"Voss","Voss","60.7025","6.4231","Norway","NO","NOR","Vestland","minor","15875","1578803539"
"Budakeszi","Budakeszi","47.5123","18.9281","Hungary","HU","HUN","Pest","minor","15466","1348195369"
"Katav-Ivanovsk","Katav-Ivanovsk","54.7500","58.2167","Russia","RU","RUS","Chelyabinskaya Oblast’","","15872","1643848436"
"Hedongcun","Hedongcun","37.7546","102.7906","China","CN","CHN","Gansu","","15667","1156671158"
"Indiaroba","Indiaroba","-11.5189","-37.5119","Brazil","BR","BRA","Sergipe","","15831","1076691419"
"Giruá","Girua","-28.0278","-54.3500","Brazil","BR","BRA","Rio Grande do Sul","","15863","1076892905"
"Sakhā","Sakha","31.0881","30.9456","Egypt","EG","EGY","Kafr ash Shaykh","","14733","1818238723"
"Miguelturra","Miguelturra","38.9667","-3.9500","Spain","ES","ESP","Castille-La Mancha","","15744","1724947836"
"Casalpusterlengo","Casalpusterlengo","45.1778","9.6500","Italy","IT","ITA","Lombardy","","15280","1380675259"
"Ponnamarāvati","Ponnamaravati","10.2803","78.5360","India","IN","IND","Tamil Nādu","","15544","1356460227"
"Udayendram","Udayendram","12.6962","78.6227","India","IN","IND","Tamil Nādu","","15268","1356139197"
"Galatone","Galatone","40.1500","18.0667","Italy","IT","ITA","Puglia","","15544","1380792497"
"Rumst","Rumst","51.0833","4.4167","Belgium","BE","BEL","Flanders","","15114","1056887516"
"Coto de Caza","Coto de Caza","33.5959","-117.5860","United States","US","USA","California","","15873","1840028363"
"Chhājli","Chhajli","30.0348","75.8280","India","IN","IND","Punjab","","15335","1356247208"
"Waterville","Waterville","44.5441","-69.6624","United States","US","USA","Maine","","15872","1840000305"
"Fort Mohave","Fort Mohave","35.0004","-114.5748","United States","US","USA","Arizona","","15872","1840042961"
"Tillsonburg","Tillsonburg","42.8667","-80.7333","Canada","CA","CAN","Ontario","","15872","1124817746"
"Ince-in-Makerfield","Ince-in-Makerfield","53.5402","-2.5990","United Kingdom","GB","GBR","Wigan","","15664","1826328021"
"Kabayan","Kabayan","16.6233","120.8381","Philippines","PH","PHL","Benguet","","15806","1608199009"
"Machang","Machang","33.1912","107.3732","China","CN","CHN","Shaanxi","","15552","1156253448"
"Wadern","Wadern","49.5167","6.8667","Germany","DE","DEU","Saarland","","15727","1276516463"
"Mandialaza","Mandialaza","-18.6167","48.0333","Madagascar","MG","MDG","Toamasina","","15835","1450052263"
"Saint-Avold","Saint-Avold","49.1042","6.7081","France","FR","FRA","Grand Est","","15433","1250159254"
"Bariariyā","Bariariya","26.5105","84.7165","India","IN","IND","Bihār","","14287","1356092761"
"João Neiva","Joao Neiva","-19.7578","-40.3858","Brazil","BR","BRA","Espírito Santo","","15809","1076583289"
"St. Simons","St. Simons","31.1775","-81.3857","United States","US","USA","Georgia","","15865","1840013874"
"Ait Yazza","Ait Yazza","30.5063","-8.7931","Morocco","MA","MAR","Souss-Massa","","14259","1504675685"
"Suffield","Suffield","41.9945","-72.6789","United States","US","USA","Connecticut","","15863","1840033678"
"Longmeadow","Longmeadow","42.0475","-72.5718","United States","US","USA","Massachusetts","","15863","1840053448"
"Mountain Home","Mountain Home","43.1324","-115.6970","United States","US","USA","Idaho","","15863","1840020017"
"Vatlūru","Vatluru","16.7009","81.0525","India","IN","IND","Andhra Pradesh","","15561","1356799189"
"Tsukumiura","Tsukumiura","33.0667","131.8667","Japan","JP","JPN","Ōita","","15664","1392037074"
"Hilvarenbeek","Hilvarenbeek","51.4833","5.1333","Netherlands","NL","NLD","Noord-Brabant","minor","15698","1528436938"
"Kavarna","Kavarna","43.4333","28.3333","Bulgaria","BG","BGR","Dobrich","minor","15834","1100727601"
"Seshambe","Seshambe","38.5333","68.2500","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","15197","1762108075"
"Adrasmon","Adrasmon","40.6486","69.9856","Tajikistan","TJ","TJK","Sughd","","15800","1762711403"
"Jussara","Jussara","-11.0469","-41.9689","Brazil","BR","BRA","Bahia","","15841","1076757344"
"Ixhuatlán del Sureste","Ixhuatlan del Sureste","18.0170","-94.3800","Mexico","MX","MEX","Veracruz","minor","15800","1484815291"
"Hollins","Hollins","37.3434","-79.9534","United States","US","USA","Virginia","","15857","1840006441"
"Overpelt","Overpelt","51.2167","5.4167","Belgium","BE","BEL","Flanders","","15478","1056903151"
"Fos-sur-Mer","Fos-sur-Mer","43.4544","4.9436","France","FR","FRA","Provence-Alpes-Côte d’Azur","","15687","1250364549"
"Dixon","Dixon","41.8439","-89.4794","United States","US","USA","Illinois","","15855","1840007051"
"Dickson","Dickson","36.0637","-87.3665","United States","US","USA","Tennessee","","15855","1840014468"
"Rakovski","Rakovski","42.2884","24.9655","Bulgaria","BG","BGR","Plovdiv","","15694","1100163147"
"Baeza","Baeza","37.9833","-3.4667","Spain","ES","ESP","Andalusia","","15773","1724128455"
"Barrocas","Barrocas","-11.5289","-39.0778","Brazil","BR","BRA","Bahia","","15770","1076187162"
"Ash Shaddādah","Ash Shaddadah","36.0561","40.7303","Syria","SY","SYR","Al Ḩasakah","minor","15806","1760828558"
"Sulat","Sulat","11.8167","125.4500","Philippines","PH","PHL","Eastern Samar","","15758","1608107294"
"Amawom","Amawom","5.4667","7.5667","Nigeria","NG","NGA","Abia","","15000","1566956703"
"Bāisāri","Baisari","22.7900","90.1313","Bangladesh","BD","BGD","Barishal","","15000","1050065179"
"Warren","Warren","40.6323","-74.5146","United States","US","USA","New Jersey","","15850","1840081703"
"Chenlu","Chenlu","35.0287","109.1548","China","CN","CHN","Shaanxi","","15642","1156334816"
"Gonohe","Gonohe","40.5312","141.3080","Japan","JP","JPN","Aomori","","15761","1392003366"
"Derzhavīnsk","Derzhavinsk","51.1000","66.3167","Kazakhstan","KZ","KAZ","","minor","15848","1398070336"
"Kumano","Kumano","33.8886","136.1002","Japan","JP","JPN","Mie","","15806","1392123261"
"Ibateguara","Ibateguara","-8.9728","-35.9389","Brazil","BR","BRA","Alagoas","","15840","1076378528"
"Exeter","Exeter","42.9901","-70.9646","United States","US","USA","New Hampshire","","15847","1840054838"
"Patton","Patton","40.8258","-77.9237","United States","US","USA","Pennsylvania","","15847","1840144238"
"Künzelsau","Kunzelsau","49.2833","9.6833","Germany","DE","DEU","Baden-Württemberg","minor","15637","1276563190"
"Itaquitinga","Itaquitinga","-7.6678","-35.1019","Brazil","BR","BRA","Pernambuco","","15692","1076330419"
"Ammūr","Ammur","12.9750","79.3628","India","IN","IND","Tamil Nādu","","14910","1356248445"
"Surbo","Surbo","40.4000","18.1333","Italy","IT","ITA","Puglia","","15115","1380657814"
"Jataúba","Jatauba","-7.9900","-36.4958","Brazil","BR","BRA","Pernambuco","","15819","1076599725"
"Carugate","Carugate","45.5500","9.3333","Italy","IT","ITA","Lombardy","","15482","1380456369"
"Phulwār","Phulwar","26.8148","84.8430","India","IN","IND","Bihār","","14110","1356125360"
"Nioro du Rip","Nioro du Rip","13.7500","-15.8000","Senegal","SN","SEN","Kaolack","","15643","1686812399"
"Ankli","Ankli","16.4200","74.5800","India","IN","IND","Karnātaka","","15302","1356026296"
"Skalica","Skalica","48.8422","17.2267","Slovakia","SK","SVK","Trnava","minor","15573","1703467870"
"Kalininsk","Kalininsk","51.5000","44.4667","Russia","RU","RUS","Saratovskaya Oblast’","","15821","1643016449"
"Mount Dora","Mount Dora","28.8142","-81.6344","United States","US","USA","Florida","","15830","1840015082"
"Balīgaon","Baligaon","25.4196","84.4951","India","IN","IND","Bihār","","14582","1356489968"
"Steinbach","Steinbach","49.5258","-96.6839","Canada","CA","CAN","Manitoba","","15829","1124152692"
"Lagunia Surajkanth","Lagunia Surajkanth","25.8304","85.7887","India","IN","IND","Bihār","","14457","1356706931"
"Biritinga","Biritinga","-11.6169","-38.8000","Brazil","BR","BRA","Bahia","","15799","1076152180"
"Castillo","Castillo","19.2200","-70.0300","Dominican Republic","DO","DOM","Cibao Nordeste","","15709","1214863705"
"Muttunāyakkanpatti","Muttunayakkanpatti","11.7125","78.0330","India","IN","IND","Tamil Nādu","","14962","1356227156"
"Patos","Patos","40.6833","19.6167","Albania","AL","ALB","Fier","","15397","1008184635"
"Bad Neustadt","Bad Neustadt","50.3219","10.2161","Germany","DE","DEU","Bavaria","minor","15411","1276106311"
"Sulphur Springs","Sulphur Springs","33.1421","-95.6122","United States","US","USA","Texas","","15825","1840022031"
"Lithia Springs","Lithia Springs","33.7811","-84.6487","United States","US","USA","Georgia","","15825","1840013740"
"Matuga","Matuga","0.4603","32.5286","Uganda","UG","UGA","Wakiso","","15000","1800696552"
"Mamadysh","Mamadysh","55.7131","51.4106","Russia","RU","RUS","Tatarstan","","15806","1643004821"
"Nymburk","Nymburk","50.1861","15.0417","Czechia","CZ","CZE","Středočeský Kraj","","15106","1203273272"
"Jamestown","Jamestown","46.9063","-98.6937","United States","US","USA","North Dakota","","15823","1840000153"
"Kurāwar","Kurawar","23.5118","77.0246","India","IN","IND","Madhya Pradesh","","15487","1356791434"
"Józefosław","Jozefoslaw","52.1005","21.0463","Poland","PL","POL","Mazowieckie","","14806","1616077121"
"Vakhsh","Vakhsh","37.7108","68.8311","Tajikistan","TJ","TJK","Khatlon","minor","14900","1762695931"
"Taurianova","Taurianova","38.3500","16.0167","Italy","IT","ITA","Calabria","","15501","1380999931"
"Santa Rosa","Santa Rosa","-33.2500","-68.1500","Argentina","AR","ARG","Mendoza","minor","15818","1032261452"
"Haftkel","Haftkel","31.4469","49.5294","Iran","IR","IRN","Khūzestān","minor","15802","1364566639"
"Chilamattūru","Chilamatturu","13.8394","77.7039","India","IN","IND","Andhra Pradesh","","15449","1356493610"
"Kerman","Kerman","36.7248","-120.0625","United States","US","USA","California","","15817","1840020324"
"Lagoa Real","Lagoa Real","-14.0350","-42.1419","Brazil","BR","BRA","Bahia","","15801","1076846837"
"Torelló","Torello","42.0492","2.2629","Spain","ES","ESP","Catalonia","","14726","1724191754"
"Babhangāwān","Babhangawan","25.3674","86.9501","India","IN","IND","Bihār","","14479","1356005473"
"Ovruch","Ovruch","51.3244","28.8081","Ukraine","UA","UKR","Zhytomyrska Oblast","","15795","1804670823"
"Verkhnodniprovsk","Verkhnodniprovsk","48.6561","34.3283","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","15749","1804265302"
"Diang","Diang","4.5833","13.3500","Cameroon","CM","CMR","Est","","15795","1120665597"
"Saint-Omer","Saint-Omer","50.7483","2.2608","France","FR","FRA","Hauts-de-France","minor","14903","1250769262"
"Ras el Oued","Ras el Oued","34.1500","-4.0000","Morocco","MA","MAR","Fès-Meknès","","15778","1504000629"
"Harduli","Harduli","22.9278","79.9030","India","IN","IND","Madhya Pradesh","","14490","1356310603"
"Juma Shahri","Juma Shahri","39.7161","66.6642","Uzbekistan","UZ","UZB","Samarqand","minor","15571","1860861355"
"Putyvl","Putyvl","51.3347","33.8686","Ukraine","UA","UKR","Sumska Oblast","","15778","1804280760"
"Lower Salford","Lower Salford","40.2639","-75.3929","United States","US","USA","Pennsylvania","","15801","1840142128"
"Yasnyy","Yasnyy","51.0500","59.9667","Russia","RU","RUS","Orenburgskaya Oblast’","","15573","1643810405"
"San Miguel","San Miguel","13.6411","124.3008","Philippines","PH","PHL","Catanduanes","","15680","1608306903"
"Goubellat","Goubellat","36.5333","9.6667","Tunisia","TN","TUN","Béja","","15762","1788528654"
"Santa Cruz de la Palma","Santa Cruz de la Palma","28.6825","-17.7650","Spain","ES","ESP","Canary Islands","","15446","1724730721"
"Maddikera","Maddikera","15.2500","77.4170","India","IN","IND","Andhra Pradesh","","15328","1356488197"
"Macedo de Cavaleiros","Macedo de Cavaleiros","41.5389","-6.9608","Portugal","PT","PRT","Bragança","minor","15776","1620590215"
"Melonguane","Melonguane","4.0045","126.6827","Indonesia","ID","IDN","Sulawesi Utara","minor","15670","1360210043"
"Pereiro","Pereiro","-6.0450","-38.4608","Brazil","BR","BRA","Ceará","","15757","1076102603"
"Castenaso","Castenaso","44.5097","11.4706","Italy","IT","ITA","Emilia-Romagna","","15363","1380350174"
"Ulladulla","Ulladulla","-35.3486","150.4678","Australia","AU","AUS","New South Wales","","15278","1036687220"
"Plochingen","Plochingen","48.7117","9.4164","Germany","DE","DEU","Baden-Württemberg","","14433","1276921571"
"Omegna","Omegna","45.8781","8.4069","Italy","IT","ITA","Piedmont","","15285","1380579967"
"Fontainebleau","Fontainebleau","48.4089","2.7017","France","FR","FRA","Île-de-France","minor","15696","1250101096"
"Itajibá","Itajiba","-14.2839","-39.8428","Brazil","BR","BRA","Bahia","","15767","1076600606"
"Hautmont","Hautmont","50.2481","3.9244","France","FR","FRA","Hauts-de-France","","14601","1250109081"
"Alūr","Alur","15.3944","77.2250","India","IN","IND","Andhra Pradesh","","15606","1356013492"
"Holly Springs","Holly Springs","34.1681","-84.4847","United States","US","USA","Georgia","","15784","1840014704"
"Santa Úrsula","Santa Ursula","28.4253","-16.4917","Spain","ES","ESP","Canary Islands","","15114","1724982663"
"Talladega","Talladega","33.4333","-86.0986","United States","US","USA","Alabama","","15782","1840008244"
"Uusikaupunki","Uusikaupunki","60.8000","21.4167","Finland","FI","FIN","Varsinais-Suomi","minor","15752","1246110587"
"Pijiño del Carmen","Pijino del Carmen","9.3333","-74.4500","Colombia","CO","COL","Magdalena","minor","15759","1170562025"
"Victor","Victor","42.9894","-77.4277","United States","US","USA","New York","","15779","1840004414"
"Chinnavādampatti","Chinnavadampatti","11.0615","76.9838","India","IN","IND","Tamil Nādu","","14494","1356163989"
"Bad Bramstedt","Bad Bramstedt","53.9186","9.8844","Germany","DE","DEU","Schleswig-Holstein","","15149","1276793883"
"Kyazanga","Kyazanga","-0.3864","31.3186","Uganda","UG","UGA","Lwengo","","15531","1800635316"
"Niederkrüchten","Niederkruchten","51.1989","6.2194","Germany","DE","DEU","North Rhine-Westphalia","","15550","1276522692"
"Acajutiba","Acajutiba","-11.6619","-38.0169","Brazil","BR","BRA","Bahia","","15717","1076094256"
"Guasca","Guasca","4.8658","-73.8772","Colombia","CO","COL","Cundinamarca","minor","15727","1170022740"
"Korsør","Korsor","55.3336","11.1397","Denmark","DK","DNK","Sjælland","","14702","1208142065"
"Abadiânia","Abadiania","-16.2039","-48.7069","Brazil","BR","BRA","Goiás","","15757","1076524654"
"Chokkanāthapuram","Chokkanathapuram","9.9921","77.3566","India","IN","IND","Tamil Nādu","","15403","1356241685"
"Santa Cecília","Santa Cecilia","-26.9608","-50.4269","Brazil","BR","BRA","Santa Catarina","","15757","1076643785"
"Cassina de’ Pecchi","Cassina de' Pecchi","45.5167","9.3667","Italy","IT","ITA","Lombardy","","13849","1380160221"
"Kalocsa","Kalocsa","46.5335","18.9858","Hungary","HU","HUN","Bács-Kiskun","minor","15490","1348222203"
"Highland Village","Highland Village","33.0897","-97.0615","United States","US","USA","Texas","","15769","1840020638"
"Molinella","Molinella","44.6167","11.6667","Italy","IT","ITA","Emilia-Romagna","","15642","1380126666"
"Crixás","Crixas","-14.5489","-49.9689","Brazil","BR","BRA","Goiás","","15760","1076662218"
"Saboeiro","Saboeiro","-6.5419","-39.9069","Brazil","BR","BRA","Ceará","","15752","1076976949"
"Silvi Paese","Silvi Paese","42.5500","14.1167","Italy","IT","ITA","Abruzzo","","15708","1380754608"
"Weißwasser/Oberlausitz","Weisswasser/Oberlausitz","51.5000","14.6333","Germany","DE","DEU","Saxony","","15521","1276986089"
"Restinga Sêca","Restinga Seca","-29.8128","-53.3750","Brazil","BR","BRA","Rio Grande do Sul","","15744","1076824655"
"Funyan Bīra","Funyan Bira","9.3500","42.4000","Ethiopia","ET","ETH","Oromīya","","15478","1231640828"
"Ershui","Ershui","23.8167","120.6167","Taiwan","TW","TWN","Changhua","","15240","1158555502"
"Miyota","Miyota","36.3223","138.5068","Japan","JP","JPN","Nagano","","15494","1392003209"
"Wenwu","Wenwu","24.7413","116.1942","China","CN","CHN","Guangdong","","15662","1156045163"
"Matsushige","Matsushige","34.1339","134.5803","Japan","JP","JPN","Tokushima","","14723","1392003501"
"Barajor","Barajor","24.7992","86.3731","India","IN","IND","Bihār","","15003","1356256861"
"Barod","Barod","23.7889","75.8116","India","IN","IND","Madhya Pradesh","","15528","1356772699"
"Zaṟah Sharan","Zarah Sharan","33.1300","68.7800","Afghanistan","AF","AFG","Paktīkā","","15651","1004895469"
"Jiaojiazhuang","Jiaojiazhuang","38.2636","101.8328","China","CN","CHN","Gansu","","15635","1156488903"
"Simmerath","Simmerath","50.6000","6.2997","Germany","DE","DEU","North Rhine-Westphalia","","15614","1276931162"
"Udala","Udala","21.5781","86.5668","India","IN","IND","Odisha","","15459","1356120498"
"Grenzach-Wyhlen","Grenzach-Wyhlen","47.5517","7.6592","Germany","DE","DEU","Baden-Württemberg","","14897","1276030397"
"Drensteinfurt","Drensteinfurt","51.7944","7.7392","Germany","DE","DEU","North Rhine-Westphalia","","15607","1276206694"
"Cuarte de Huerva","Cuarte de Huerva","41.5833","-0.9167","Spain","ES","ESP","Aragon","","14169","1724381446"
"General Pinedo","General Pinedo","-27.3167","-61.2833","Argentina","AR","ARG","Chaco","minor","15741","1032426830"
"Bunnik","Bunnik","52.0500","5.2333","Netherlands","NL","NLD","Utrecht","","15341","1528719790"
"Ascheberg","Ascheberg","51.7889","7.6200","Germany","DE","DEU","North Rhine-Westphalia","","15602","1276423917"
"Kasumi","Kasumi","35.6333","134.6333","Japan","JP","JPN","Hyōgo","","15704","1392645167"
"Mvurwi","Mvurwi","-17.0167","30.8500","Zimbabwe","ZW","ZWE","Mashonaland Central","","15645","1716745701"
"Guinguinéo","Guinguineo","14.2667","-15.9500","Senegal","SN","SEN","Fatick","","15627","1686164172"
"Iguatemi","Iguatemi","-23.6800","-54.5608","Brazil","BR","BRA","Mato Grosso do Sul","","15738","1076107714"
"San Giorgio Ionico","San Giorgio Ionico","40.4500","17.3667","Italy","IT","ITA","Puglia","","15101","1380292242"
"Vellālāpuram","Vellalapuram","11.6525","77.9380","India","IN","IND","Tamil Nādu","","14863","1356168688"
"Morretes","Morretes","-25.4769","-48.8339","Brazil","BR","BRA","Paraná","","15718","1076545302"
"Glenn Heights","Glenn Heights","32.5506","-96.8548","United States","US","USA","Texas","","15741","1840020708"
"Si Satchanalai","Si Satchanalai","17.4155","99.8152","Thailand","TH","THA","Sukhothai","minor","15691","1764174789"
"Pakaryā Harsidhi","Pakarya Harsidhi","26.6526","84.7198","India","IN","IND","Bihār","","14516","1356477129"
"Talaināyar Agrahāram","Talainayar Agraharam","10.5614","79.7723","India","IN","IND","Tamil Nādu","","15358","1356055273"
"Aartselaar","Aartselaar","51.1333","4.3833","Belgium","BE","BEL","Flanders","","14427","1056119316"
"Arumbāvūr","Arumbavur","11.3810","78.7296","India","IN","IND","Tamil Nādu","","15437","1356133950"
"Mount Clemens","Mount Clemens","42.5977","-82.8821","United States","US","USA","Michigan","","15735","1840003089"
"Mallāpuram","Mallapuram","11.9823","78.2480","India","IN","IND","Tamil Nādu","","15145","1356979622"
"Hanover","Hanover","49.4433","-96.8492","Canada","CA","CAN","Manitoba","","15733","1124001704"
"Kela Khera","Kela Khera","29.0900","79.1900","India","IN","IND","Uttarākhand","","14911","1356055136"
"Tēkkampatti","Tekkampatti","11.2559","76.8872","India","IN","IND","Tamil Nādu","","15284","1356121903"
"Spittal an der Drau","Spittal an der Drau","46.7917","13.4958","Austria","AT","AUT","Kärnten","minor","15413","1040131621"
"Coelemu","Coelemu","-36.4878","-72.7028","Chile","CL","CHL","Ñuble","","15711","1152096905"
"Finale Emilia","Finale Emilia","44.8333","11.2833","Italy","IT","ITA","Emilia-Romagna","","15581","1380983848"
"Sebt Bni Smith","Sebt Bni Smith","35.1373","-4.7107","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","15577","1504877940"
"Ulverstone","Ulverstone","-41.1667","146.1667","Australia","AU","AUS","Tasmania","","14109","1036014544"
"Oswestry","Oswestry","52.8598","-3.0538","United Kingdom","GB","GBR","Shropshire","","15613","1826246108"
"Elkton","Elkton","39.6066","-75.8209","United States","US","USA","Maryland","","15724","1840005635"
"Riposto","Riposto","37.7333","15.2000","Italy","IT","ITA","Sicilia","","14620","1380724785"
"Terrace","Terrace","54.5164","-128.5997","Canada","CA","CAN","British Columbia","","15723","1124878479"
"Yasnogorsk","Yasnogorsk","54.5000","37.6667","Russia","RU","RUS","Tul’skaya Oblast’","","15706","1643871801"
"Tīrān","Tiran","32.7025","51.1536","Iran","IR","IRN","Eşfahān","minor","15673","1364233124"
"Rattihalli","Rattihalli","14.4167","75.5000","India","IN","IND","Karnātaka","","15446","1356898380"
"Chapelle-lez-Herlaimont","Chapelle-lez-Herlaimont","50.4667","4.2833","Belgium","BE","BEL","Wallonia","","14900","1056800453"
"Yazu","Yazu","35.4092","134.2505","Japan","JP","JPN","Tottori","","15642","1392003496"
"Maskanah","Maskanah","35.9632","38.0356","Syria","SY","SYR","Ḩalab","minor","15477","1760994714"
"Kukkundūr","Kukkundur","13.2404","74.9541","India","IN","IND","Karnātaka","","15536","1356746140"
"Mwinilunga","Mwinilunga","-11.7172","24.4289","Zambia","ZM","ZMB","North-Western","","15704","1894210586"
"Zhuolan","Zhuolan","24.3222","120.8447","Taiwan","TW","TWN","Miaoli","","15505","1158259553"
"Langenau","Langenau","48.4967","10.1200","Germany","DE","DEU","Baden-Württemberg","","15501","1276345648"
"Hewitt","Hewitt","31.4520","-97.1960","United States","US","USA","Texas","","15707","1840020809"
"Castelnuovo Rangone","Castelnuovo Rangone","44.5500","10.9333","Italy","IT","ITA","Emilia-Romagna","","15035","1380301860"
"Crépy-en-Valois","Crepy-en-Valois","49.2344","2.8875","France","FR","FRA","Hauts-de-France","","14796","1250074872"
"Auburndale","Auburndale","28.0963","-81.8012","United States","US","USA","Florida","","15704","1840014127"
"Discovery Bay","Discovery Bay","37.9063","-121.5990","United States","US","USA","California","","15704","1840018897"
"Renai","Renai","24.0462","121.1304","Taiwan","TW","TWN","Nantou","","15691","1158608821"
"Buggānipalle","Bugganipalle","15.4741","78.1750","India","IN","IND","Andhra Pradesh","","15416","1356687033"
"Vallūr","Vallur","13.2560","80.2818","India","IN","IND","Tamil Nādu","","14947","1356924542"
"Ifanadiana","Ifanadiana","-21.3000","47.6333","Madagascar","MG","MDG","Fianarantsoa","","15681","1450233083"
"Ostroh","Ostroh","50.3333","26.5167","Ukraine","UA","UKR","Rivnenska Oblast","","15642","1804135367"
"Kanding","Kanding","22.5194","120.5032","Taiwan","TW","TWN","Pingtung","","15205","1158898818"
"Zhirnovsk","Zhirnovsk","50.9833","44.7833","Russia","RU","RUS","Volgogradskaya Oblast’","","15680","1643137846"
"Toui","Toui","8.6833","2.6000","Benin","BJ","BEN","Collines","","15634","1204315946"
"Dejen","Dejen","10.1667","38.1333","Ethiopia","ET","ETH","Āmara","","15483","1231136097"
"Los Osos","Los Osos","35.3065","-120.8242","United States","US","USA","California","","15692","1840017805"
"Poté","Pote","-17.8069","-41.7858","Brazil","BR","BRA","Minas Gerais","","15667","1076639035"
"Einsiedeln","Einsiedeln","47.1167","8.7333","Switzerland","CH","CHE","Schwyz","minor","15550","1756410538"
"Kuusamo","Kuusamo","65.9667","29.1833","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","15688","1246292850"
"Yakumo","Yakumo","42.2560","140.2652","Japan","JP","JPN","Hokkaidō","","15674","1392003438"
"‘Alavīcheh","\`Alavicheh","33.0528","51.0825","Iran","IR","IRN","Eşfahān","","15550","1364605877"
"Newton","Newton","41.6963","-93.0403","United States","US","USA","Iowa","","15688","1840008171"
"Ashikita","Ashikita","32.2991","130.4932","Japan","JP","JPN","Kumamoto","","15621","1392003333"
"Kinross","Kinross","-26.4167","29.0833","South Africa","ZA","ZAF","Mpumalanga","","15246","1710559931"
"Vipparla","Vipparla","16.3023","79.9373","India","IN","IND","Andhra Pradesh","","15418","1356198393"
"Loma de Cabrera","Loma de Cabrera","19.4220","-71.6150","Dominican Republic","DO","DOM","Cibao Noroeste","","15624","1214366417"
"Kysucké Nové Mesto","Kysucke Nove Mesto","49.2983","18.7867","Slovakia","SK","SVK","Žilina","minor","15132","1703666551"
"Mayoyao","Mayoyao","16.9736","121.2214","Philippines","PH","PHL","Ifugao","","15621","1608130519"
"Şaḩnāyā","Sahnaya","33.4242","36.2244","Syria","SY","SYR","Rīf Dimashq","minor","13993","1760192285"
"Marivorahona","Marivorahona","-13.0833","49.0833","Madagascar","MG","MDG","Antsiranana","","15654","1450497444"
"Lady Lake","Lady Lake","28.9241","-81.9299","United States","US","USA","Florida","","15683","1840017229"
"Kot Bhāi","Kot Bhai","30.2678","74.6990","India","IN","IND","Punjab","","15360","1356036190"
"Lagoa dos Gatos","Lagoa dos Gatos","-8.6578","-35.9000","Brazil","BR","BRA","Pernambuco","","15615","1076146195"
"Tondangi","Tondangi","17.2500","82.4667","India","IN","IND","Andhra Pradesh","","15189","1356408013"
"Macomb","Macomb","40.4709","-90.6807","United States","US","USA","Illinois","","15681","1840009411"
"Brühl","Bruhl","49.4000","8.5353","Germany","DE","DEU","Baden-Württemberg","","14288","1276886758"
"Navelim","Navelim","15.2428","73.9331","India","IN","IND","Goa","","14538","1356163461"
"Varnsdorf","Varnsdorf","50.9116","14.6184","Czechia","CZ","CZE","Ústecký Kraj","","15117","1203159316"
"Barela","Barela","23.0968","80.0508","India","IN","IND","Madhya Pradesh","","14361","1356660345"
"Bengonbeyene","Bengonbeyene","1.6931","11.0342","Equatorial Guinea","GQ","GNQ","Wele-Nzas","","15644","1226996248"
"Matlock","Matlock","53.1400","-1.5500","United Kingdom","GB","GBR","Derbyshire","","15519","1826168850"
"Mitchell","Mitchell","43.7294","-98.0337","United States","US","USA","South Dakota","","15677","1840002642"
"Templin","Templin","53.1167","13.5000","Germany","DE","DEU","Brandenburg","","15636","1276089341"
"Aç-çahrij","Ac-cahrij","31.8000","-7.1400","Morocco","MA","MAR","Marrakech-Safi","","15385","1504655221"
"Dammāj","Dammaj","16.8939","43.8022","Yemen","YE","YEM","Şa‘dah","","15626","1887597627"
"Novouzensk","Novouzensk","50.4667","48.1333","Russia","RU","RUS","Saratovskaya Oblast’","","15665","1643676305"
"Opera","Opera","45.3833","9.2167","Italy","IT","ITA","Lombardy","","13858","1380000121"
"Pontivy","Pontivy","48.0686","-2.9628","France","FR","FRA","Bretagne","minor","15064","1250640308"
"Mecitözü","Mecitozu","40.5200","35.2953","Turkey","TR","TUR","Çorum","minor","15655","1792194107"
"Wailuku","Wailuku","20.8834","-156.5059","United States","US","USA","Hawaii","","15670","1840023239"
"Badarpur","Badarpur","24.8685","92.5961","India","IN","IND","Assam","","14912","1356793949"
"North Whitehall","North Whitehall","40.6797","-75.5788","United States","US","USA","Pennsylvania","","15669","1840149202"
"Sertanópolis","Sertanopolis","-23.0589","-51.0358","Brazil","BR","BRA","Paraná","","15638","1076421900"
"Rakhiv","Rakhiv","48.0500","24.2167","Ukraine","UA","UKR","Zakarpatska Oblast","minor","15621","1804590257"
"Kasagi","Kasagi","34.2965","135.5037","Japan","JP","JPN","Wakayama","","15565","1392507322"
"Manjīl","Manjil","36.7433","49.4022","Iran","IR","IRN","Gīlān","","15630","1364624018"
"Dacheng","Dacheng","23.8483","120.3089","Taiwan","TW","TWN","Changhua","","15425","1158751898"
"Valeggio sul Mincio","Valeggio sul Mincio","45.3500","10.7333","Italy","IT","ITA","Veneto","","15424","1380970991"
"Casalmaggiore","Casalmaggiore","44.9858","10.4147","Italy","IT","ITA","Lombardy","","15425","1380588723"
"Szarvas","Szarvas","46.8667","20.5500","Hungary","HU","HUN","Békés","minor","15565","1348371438"
"Mortara","Mortara","45.2500","8.7500","Italy","IT","ITA","Lombardy","","15362","1380404772"
"Waremme","Waremme","50.6975","5.2556","Belgium","BE","BEL","Wallonia","minor","15168","1056180426"
"Porcia","Porcia","45.9667","12.6167","Italy","IT","ITA","Friuli Venezia Giulia","","15142","1380622956"
"Chickasha","Chickasha","35.0409","-97.9474","United States","US","USA","Oklahoma","","15655","1840019169"
"Tōbetsu","Tobetsu","43.2237","141.5170","Japan","JP","JPN","Hokkaidō","","15618","1392723776"
"Kambadūru","Kambaduru","14.3575","77.2186","India","IN","IND","Andhra Pradesh","","15441","1356132175"
"Udiyāvara","Udiyavara","13.3097","74.7366","India","IN","IND","Karnātaka","","15029","1356112857"
"Metković","Metkovic","43.0500","17.6500","Croatia","HR","HRV","Dubrovačko-Neretvanska Županija","minor","15349","1191697358"
"Manūjān","Manujan","27.4064","57.5014","Iran","IR","IRN","Kermān","minor","15634","1364733657"
"Alpine","Alpine","32.8439","-116.7585","United States","US","USA","California","","15648","1840018014"
"Tupi Paulista","Tupi Paulista","-21.3808","-51.5708","Brazil","BR","BRA","São Paulo","","15583","1076679633"
"Newberry","Newberry","40.1286","-76.7919","United States","US","USA","Pennsylvania","","15646","1840151961"
"Itki Thākurgaon","Itki Thakurgaon","23.3456","85.1114","India","IN","IND","Jhārkhand","","15108","1356191932"
"Gaillac","Gaillac","43.9006","1.8983","France","FR","FRA","Occitanie","","15345","1250825610"
"Huasca de Ocampo","Huasca de Ocampo","20.2028","-98.5758","Mexico","MX","MEX","Hidalgo","minor","15201","1484063344"
"Lindås","Lindas","60.6247","5.3283","Norway","NO","NOR","Vestland","","15607","1578074399"
"Rio do Antônio","Rio do Antonio","-14.4108","-42.0758","Brazil","BR","BRA","Bahia","","15628","1076833793"
"Kinna","Kinna","57.5167","12.6833","Sweden","SE","SWE","Västra Götaland","minor","15452","1752953604"
"Bhoj","Bhoj","16.5333","74.4500","India","IN","IND","Karnātaka","","15110","1356958224"
"Dolo","Dolo","45.4269","12.0753","Italy","IT","ITA","Veneto","","15022","1380619643"
"Kolwāra","Kolwara","25.3538","86.7700","India","IN","IND","Bihār","","14430","1356151383"
"Tone","Tone","35.8578","140.1392","Japan","JP","JPN","Ibaraki","","15035","1392003334"
"Passa Quatro","Passa Quatro","-22.3900","-44.9669","Brazil","BR","BRA","Minas Gerais","","15582","1076116679"
"Shively","Shively","38.1970","-85.8136","United States","US","USA","Kentucky","","15638","1840015196"
"Bolotnoye","Bolotnoye","55.6833","84.4167","Russia","RU","RUS","Novosibirskaya Oblast’","","15629","1643828761"
"Serpa","Serpa","37.9447","-7.5989","Portugal","PT","PRT","Beja","minor","15623","1620464160"
"Kallakkudi","Kallakkudi","10.9767","78.9525","India","IN","IND","Tamil Nādu","","15168","1356203495"
"Mindelheim","Mindelheim","48.0333","10.4667","Germany","DE","DEU","Bavaria","minor","15364","1276322308"
"Kohīr","Kohir","17.6000","77.7167","India","IN","IND","Andhra Pradesh","","15075","1356364942"
"Česká Třebová","Ceska Trebova","49.9019","16.4472","Czechia","CZ","CZE","Pardubický Kraj","","15267","1203262802"
"Rossville","Rossville","39.3572","-76.4767","United States","US","USA","Maryland","","15633","1840031398"
"Seymour","Seymour","35.8783","-83.7669","United States","US","USA","Tennessee","","15633","1840013386"
"Cunco","Cunco","-38.9167","-72.0333","Chile","CL","CHL","Araucanía","","15628","1152535744"
"Kara-Kulja","Kara-Kulja","40.6333","73.5925","Kyrgyzstan","KG","KGZ","Osh","minor","15616","1417892733"
"Fort Leonard Wood","Fort Leonard Wood","37.7562","-92.1274","United States","US","USA","Missouri","","15629","1840073756"
"Tuineje","Tuineje","28.3167","-14.0500","Spain","ES","ESP","Canary Islands","","15572","1724388694"
"Olmué","Olmue","-32.9953","-71.1856","Chile","CL","CHL","Valparaíso","","15611","1152762974"
"Baia-Sprie","Baia-Sprie","47.6592","23.7042","Romania","RO","ROU","Maramureş","","15476","1642427641"
"Weston","Weston","53.4130","-2.7980","United Kingdom","GB","GBR","Halton","","14263","1826832575"
"Bŭston","Buston","40.5217","69.3308","Tajikistan","TJ","TJK","Sughd","minor","15500","1762646765"
"Ban Na Kham","Ban Na Kham","14.0681","101.8125","Thailand","TH","THA","Prachin Buri","","15465","1764834379"
"Rahden","Rahden","52.4167","8.6167","Germany","DE","DEU","North Rhine-Westphalia","","15505","1276217000"
"Siswa","Siswa","26.6214","84.6739","India","IN","IND","Bihār","","14535","1356000074"
"Anao-aon","Anao-aon","9.7778","125.4231","Philippines","PH","PHL","Surigao del Norte","","15347","1608949695"
"Shizukuishi","Shizukuishi","39.6963","140.9758","Japan","JP","JPN","Iwate","","15589","1392537608"
"Mangqu","Mangqu","35.5707","100.7798","China","CN","CHN","Qinghai","minor","15609","1156828773"
"Montijo","Montijo","38.9100","-6.6175","Spain","ES","ESP","Extremadura","","15483","1724772763"
"Jędrzejów","Jedrzejow","50.6333","20.3000","Poland","PL","POL","Świętokrzyskie","minor","15484","1616363750"
"Espumoso","Espumoso","-28.7250","-52.8500","Brazil","BR","BRA","Rio Grande do Sul","","15591","1076869927"
"Corridonia","Corridonia","43.2500","13.5167","Italy","IT","ITA","Marche","","15362","1380496994"
"Weston","Weston","44.8906","-89.5487","United States","US","USA","Wisconsin","","15609","1840038093"
"Boppard","Boppard","50.2314","7.5908","Germany","DE","DEU","Rhineland-Palatinate","","15403","1276667317"
"Akdepe","Akdepe","42.0500","59.4000","Turkmenistan","TM","TKM","Daşoguz","","15580","1795536165"
"Pyāpali","Pyapali","15.2669","77.7611","India","IN","IND","Andhra Pradesh","","15200","1356242157"
"Grovetown","Grovetown","33.4503","-82.2073","United States","US","USA","Georgia","","15608","1840013748"
"Bohodukhiv","Bohodukhiv","50.1608","35.5164","Ukraine","UA","UKR","Kharkivska Oblast","minor","15576","1804919140"
"Tiruvāsaladi","Tiruvasaladi","11.4013","79.7746","India","IN","IND","Tamil Nādu","","14634","1356235662"
"Ágios Athanásios","Agios Athanasios","34.7087","33.0504","Cyprus","CY","CYP","Lemesós","","14578","1196729508"
"Mangawān","Mangawan","24.6675","81.5464","India","IN","IND","Madhya Pradesh","","15259","1356348209"
"Northborough","Northborough","42.3231","-71.6462","United States","US","USA","Massachusetts","","15605","1840053597"
"Qaryat Sulūq","Qaryat Suluq","31.6686","20.2503","Libya","LY","LBY","Banghāzī","","15543","1434000178"
"Saint-Rambert","Saint-Rambert","45.4994","4.2400","France","FR","FRA","Auvergne-Rhône-Alpes","","15229","1250296469"
"At-Bashy","At-Bashy","41.1700","75.8000","Kyrgyzstan","KG","KGZ","Naryn","minor","15601","1417188010"
"Pihra","Pihra","24.6424","85.8043","India","IN","IND","Jhārkhand","","15315","1356308510"
"Fameck","Fameck","49.2992","6.1097","France","FR","FRA","Grand Est","","14442","1250380278"
"Khamir","Khamir","15.9889","43.9589","Yemen","YE","YEM","‘Amrān","minor","15333","1887000562"
"Antrim","Antrim","39.7862","-77.7221","United States","US","USA","Pennsylvania","","15601","1840148478"
"Ipuã","Ipua","-20.4381","-48.0122","Brazil","BR","BRA","São Paulo","","15567","1076410935"
"Mandal","Mandal","58.0267","7.4533","Norway","NO","NOR","Agder","minor","15529","1578061147"
"Kāuriya","Kauriya","26.1517","84.6948","India","IN","IND","Bihār","","13842","1356024494"
"Yığılca","Yigilca","40.9667","31.4500","Turkey","TR","TUR","Düzce","minor","15573","1792664621"
"Borborema","Borborema","-21.6200","-49.0739","Brazil","BR","BRA","São Paulo","","15569","1076625654"
"Najasa","Najasa","21.0836","-77.7472","Cuba","CU","CUB","Camagüey","minor","15579","1192325619"
"Soliera","Soliera","44.7381","10.9245","Italy","IT","ITA","Emilia-Romagna","","15296","1380581557"
"Pānr","Panr","25.6884","85.7980","India","IN","IND","Bihār","","14201","1356127443"
"Cumru","Cumru","40.2811","-75.9544","United States","US","USA","Pennsylvania","","15595","1840145303"
"Hille","Hille","52.3331","8.7500","Germany","DE","DEU","North Rhine-Westphalia","","15445","1276307584"
"Ban Pang Mu","Ban Pang Mu","19.3336","97.9656","Thailand","TH","THA","Mae Hong Son","","15577","1764504430"
"Blaydon","Blaydon","54.9630","-1.7190","United Kingdom","GB","GBR","Gateshead","","15155","1826275133"
"Bonheiden","Bonheiden","51.0333","4.5333","Belgium","BE","BEL","Flanders","","15078","1056704706"
"Southchase","Southchase","28.3793","-81.3903","United States","US","USA","Florida","","15592","1840029005"
"Castellarano","Castellarano","44.5667","10.7833","Italy","IT","ITA","Emilia-Romagna","","15326","1380006384"
"Bonito","Bonito","-1.3628","-47.3069","Brazil","BR","BRA","Pará","","15563","1076970076"
"Pewaukee","Pewaukee","43.0701","-88.2412","United States","US","USA","Wisconsin","","15589","1840033459"
"Maullín","Maullin","-41.6167","-73.6000","Chile","CL","CHL","Los Lagos","","15580","1152172124"
"Windham","Windham","42.8076","-71.2995","United States","US","USA","New Hampshire","","15587","1840055881"
"Dastgerd","Dastgerd","32.8019","51.6636","Iran","IR","IRN","Eşfahān","","15524","1364996387"
"São João Evangelista","Sao Joao Evangelista","-18.5478","-42.7628","Brazil","BR","BRA","Minas Gerais","","15553","1076468674"
"Arvika","Arvika","59.6542","12.5914","Sweden","SE","SWE","Värmland","minor","14244","1752743749"
"Tredegar","Tredegar","51.7776","-3.2407","United Kingdom","GB","GBR","Blaenau Gwent","","15103","1826084160"
"Caudry","Caudry","50.1250","3.4117","France","FR","FRA","Hauts-de-France","","14487","1250525651"
"Ribnitz-Damgarten","Ribnitz-Damgarten","54.2500","12.4667","Germany","DE","DEU","Mecklenburg-Western Pomerania","","15451","1276064199"
"Pequannock","Pequannock","40.9627","-74.3041","United States","US","USA","New Jersey","","15577","1840081738"
"Pahou","Pahou","6.3833","2.2167","Benin","BJ","BEN","Atlantique","","14436","1204813265"
"Mastic","Mastic","40.8096","-72.8479","United States","US","USA","New York","","15576","1840005090"
"Svetogorsk","Svetogorsk","61.1167","28.8500","Russia","RU","RUS","Leningradskaya Oblast’","","15546","1643956298"
"Haldībāri","Haldibari","26.3300","88.7700","India","IN","IND","West Bengal","","14404","1356516679"
"Meco","Meco","40.5539","-3.3261","Spain","ES","ESP","Madrid","","15143","1724484406"
"Banqiao","Banqiao","35.8912","107.9655","China","CN","CHN","Gansu","","15486","1156805029"
"Radzyń Podlaski","Radzyn Podlaski","51.7828","22.6242","Poland","PL","POL","Lubelskie","minor","14806","1616425780"
"Corinto","Corinto","13.8167","-87.9667","El Salvador","SV","SLV","Morazán","","15410","1222976576"
"Urziceni","Urziceni","44.7181","26.6453","Romania","RO","ROU","Ialomiţa","","15308","1642027184"
"El Chal","El Chal","16.6333","-89.6500","Guatemala","GT","GTM","Petén","minor","15555","1320084650"
"Ambaguio","Ambaguio","16.5316","121.0282","Philippines","PH","PHL","Nueva Vizcaya","","15472","1608849107"
"Chiaravalle","Chiaravalle","43.6000","13.3333","Italy","IT","ITA","Marche","","14733","1380566953"
"Butiama","Butiama","-1.7667","33.9667","Tanzania","TZ","TZA","Mara","","15383","1834201635"
"Wanda","Wanda","-25.9667","-54.5833","Argentina","AR","ARG","Misiones","","15529","1032480150"
"Davorlim","Davorlim","15.2722","73.9924","India","IN","IND","Goa","","14426","1356958388"
"Wawizaght","Wawizaght","32.1586","-6.3550","Morocco","MA","MAR","Béni Mellal-Khénifra","","14570","1504619132"
"Kusāha","Kusaha","26.2077","86.9587","India","IN","IND","Bihār","","14530","1356500868"
"Shimanto","Shimanto","33.2117","133.1369","Japan","JP","JPN","Kōchi","","15540","1392003114"
"Garrel","Garrel","52.9581","8.0253","Germany","DE","DEU","Lower Saxony","","15428","1276561308"
"San Policarpo","San Policarpo","12.1791","125.5072","Philippines","PH","PHL","Eastern Samar","","15365","1608259196"
"Solec Kujawski","Solec Kujawski","53.0833","18.2333","Poland","PL","POL","Kujawsko-Pomorskie","","15458","1616600171"
"Monkey Bay","Monkey Bay","-14.0833","34.9167","Malawi","MW","MWI","Mangochi","","14955","1454428949"
"Seven Oaks","Seven Oaks","34.0475","-81.1435","United States","US","USA","South Carolina","","15561","1840035850"
"Ipecaetá","Ipecaeta","-12.3000","-39.3078","Brazil","BR","BRA","Bahia","","15521","1076177801"
"Monroe","Monroe","39.4461","-84.3667","United States","US","USA","Ohio","","15560","1840008465"
"Sayō","Sayo","35.0042","134.3558","Japan","JP","JPN","Hyōgo","","15508","1392577247"
"Baiceng","Baiceng","25.3885","105.7848","China","CN","CHN","Guizhou","","15464","1156170367"
"Castiglione del Lago","Castiglione del Lago","43.1386","12.0478","Italy","IT","ITA","Umbria","","15479","1380310295"
"Live Oak","Live Oak","29.5545","-98.3404","United States","US","USA","Texas","","15554","1840020960"
"Wittenheim","Wittenheim","47.8075","7.3369","France","FR","FRA","Grand Est","","14776","1250689595"
"Amānganj","Amanganj","24.4266","80.0358","India","IN","IND","Madhya Pradesh","","15323","1356973374"
"Kawasaki","Kawasaki","33.6000","130.8149","Japan","JP","JPN","Fukuoka","","15134","1392730705"
"Schriesheim","Schriesheim","49.4736","8.6592","Germany","DE","DEU","Baden-Württemberg","","15081","1276514747"
"Colchester","Colchester","41.5621","-72.3475","United States","US","USA","Connecticut","","15552","1840034365"
"Küsnacht","Kusnacht","47.3167","8.5833","Switzerland","CH","CHE","Zürich","","14387","1756976671"
"Mujuí dos Campos","Mujui dos Campos","-2.6847","-54.6403","Brazil","BR","BRA","Pará","","15548","1076330861"
"Vadugapatti","Vadugapatti","11.1518","77.7375","India","IN","IND","Tamil Nādu","","14415","1356165126"
"Khao Yoi","Khao Yoi","13.2403","99.8254","Thailand","TH","THA","Phetchaburi","minor","15416","1764047925"
"Siemiatycze","Siemiatycze","52.4272","22.8625","Poland","PL","POL","Podlaskie","minor","15169","1616804060"
"North Reading","North Reading","42.5816","-71.0876","United States","US","USA","Massachusetts","","15549","1840053482"
"Azalea Park","Azalea Park","28.5473","-81.2956","United States","US","USA","Florida","","15549","1840014082"
"Luduş","Ludus","46.4778","24.0961","Romania","RO","ROU","Mureş","","15328","1642649798"
"Anār","Anar","30.8733","55.2706","Iran","IR","IRN","Kermān","minor","15532","1364013146"
"Sovetsk","Sovetsk","57.5833","48.9833","Russia","RU","RUS","Kirovskaya Oblast’","","15538","1643054411"
"Greenwood Village","Greenwood Village","39.6153","-104.9131","United States","US","USA","Colorado","","15548","1840020212"
"Alvarães","Alvaraes","-3.2000","-64.8333","Brazil","BR","BRA","Amazonas","","15545","1076076112"
"Ayaş","Ayas","40.0172","32.3483","Turkey","TR","TUR","Ankara","minor","15540","1792609303"
"Muttukūru","Muttukuru","14.2667","80.1000","India","IN","IND","Andhra Pradesh","","14333","1356004216"
"Mering","Mering","48.2625","10.9844","Germany","DE","DEU","Bavaria","","14986","1276382837"
"Indianola","Indianola","41.3629","-93.5652","United States","US","USA","Iowa","","15544","1840000648"
"La Roda","La Roda","39.2070","-2.1604","Spain","ES","ESP","Castille-La Mancha","","15497","1724000113"
"Romit","Romit","38.7167","69.3167","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","15440","1762333880"
"Brooklyn Park","Brooklyn Park","39.2170","-76.6174","United States","US","USA","Maryland","","15541","1840031312"
"Hugo","Hugo","45.1671","-92.9588","United States","US","USA","Minnesota","","15541","1840006739"
"Bobleshwar","Bobleshwar","16.8300","75.7300","India","IN","IND","Karnātaka","","15216","1356172549"
"Doda","Doda","30.3844","74.6394","India","IN","IND","Punjab","","15218","1356983953"
"Ventaquemada","Ventaquemada","5.4167","-73.5000","Colombia","CO","COL","Boyacá","minor","15442","1170473736"
"Daigo","Daigo","36.7681","140.3552","Japan","JP","JPN","Ibaraki","","15491","1392539732"
"Magdiwang","Magdiwang","12.4833","122.5167","Philippines","PH","PHL","Romblon","","15385","1608595511"
"Amboavory","Amboavory","-17.3000","48.5000","Madagascar","MG","MDG","Toamasina","","15465","1450001958"
"Bhānumukkala","Bhanumukkala","15.3119","78.2277","India","IN","IND","Andhra Pradesh","","15309","1356399410"
"Bokoro","Bokoro","12.3667","17.0500","Chad","TD","TCD","Hadjer-Lamis","","15517","1148118704"
"Saren","Saren","25.1149","84.9744","India","IN","IND","Bihār","","14238","1356170104"
"Zelenogradsk","Zelenogradsk","54.9667","20.4833","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","15493","1643896449"
"Rellingen","Rellingen","53.6500","9.8167","Germany","DE","DEU","Schleswig-Holstein","","14437","1276483816"
"Niceville","Niceville","30.5290","-86.4748","United States","US","USA","Florida","","15532","1840015009"
"Fiorenzuola d’Arda","Fiorenzuola d'Arda","44.9333","9.9000","Italy","IT","ITA","Emilia-Romagna","","15276","1380545358"
"Viškovo","Viskovo","45.3778","14.3861","Croatia","HR","HRV","Primorsko-Goranska Županija","minor","14445","1191502082"
"Tremelo","Tremelo","50.9911","4.7044","Belgium","BE","BEL","Flanders","","14842","1056959552"
"Pudtol","Pudtol","18.2356","121.3733","Philippines","PH","PHL","Apayao","","15491","1608544633"
"Grenaa","Grenaa","56.4161","10.8923","Denmark","DK","DNK","Midtjylland","minor","14856","1208961865"
"Sutihār","Sutihar","25.8482","84.9712","India","IN","IND","Bihār","","13977","1356548358"
"Gehrden","Gehrden","52.3117","9.6003","Germany","DE","DEU","Lower Saxony","","15177","1276000662"
"Ban Duea","Ban Duea","16.1253","101.8967","Thailand","TH","THA","Chaiyaphum","","15463","1764693756"
"El Palmar","El Palmar","8.0244","-61.8819","Venezuela","VE","VEN","Bolívar","minor","15521","1862731321"
"Isorana","Isorana","-21.3167","46.9333","Madagascar","MG","MDG","Fianarantsoa","","15472","1450788463"
"Jelcz-Laskowice","Jelcz-Laskowice","51.0333","17.3333","Poland","PL","POL","Dolnośląskie","","15380","1616808157"
"Hartford","Hartford","43.3223","-88.3784","United States","US","USA","Wisconsin","","15526","1840002831"
"Feucht","Feucht","49.3758","11.2131","Germany","DE","DEU","Bavaria","","14088","1276937991"
"Abaza","Abaza","52.6481","90.0739","Russia","RU","RUS","Khakasiya","","15335","1643885049"
"Wilmington Island","Wilmington Island","32.0033","-80.9752","United States","US","USA","Georgia","","15521","1840029461"
"Bouc-Bel-Air","Bouc-Bel-Air","43.4544","5.4144","France","FR","FRA","Provence-Alpes-Côte d’Azur","","14838","1250567506"
"Sinha","Sinha","25.6902","84.6291","India","IN","IND","Bihār","","14603","1356887123"
"Genappe","Genappe","50.6000","4.4500","Belgium","BE","BEL","Wallonia","","15353","1056101881"
"Barra de Santa Rosa","Barra de Santa Rosa","-6.7200","-36.0608","Brazil","BR","BRA","Paraíba","","15497","1076169130"
"Pannimadai","Pannimadai","11.0823","76.9164","India","IN","IND","Tamil Nādu","","14232","1356312978"
"Ban Nong Kathao","Ban Nong Kathao","16.9833","100.7833","Thailand","TH","THA","Phitsanulok","","15470","1764811554"
"Teodoro Schmidt","Teodoro Schmidt","-38.9949","-73.0894","Chile","CL","CHL","Araucanía","","15504","1152001741"
"Bailleul","Bailleul","50.7375","2.7339","France","FR","FRA","Hauts-de-France","","15163","1250119605"
"Bilaua","Bilaua","26.0501","78.2752","India","IN","IND","Madhya Pradesh","","15102","1356056913"
"Cumbum","Cumbum","15.5767","79.1055","India","IN","IND","Andhra Pradesh","","15169","1356391649"
"Limanowa","Limanowa","49.7006","20.4267","Poland","PL","POL","Małopolskie","minor","14738","1616015371"
"Parapatti","Parapatti","12.1083","78.0198","India","IN","IND","Tamil Nādu","","14884","1356086834"
"Strängnäs","Strangnas","59.3667","17.0333","Sweden","SE","SWE","Södermanland","minor","15363","1752111370"
"Cerro Maggiore","Cerro Maggiore","45.6000","8.9500","Italy","IT","ITA","Lombardy","","15211","1380334696"
"Senlis","Senlis","49.2072","2.5867","France","FR","FRA","Hauts-de-France","minor","14891","1250110984"
"Bunkeflostrand","Bunkeflostrand","55.5500","12.9167","Sweden","SE","SWE","Skåne","","15212","1752685972"
"Barwon Heads","Barwon Heads","-38.2500","144.5167","Australia","AU","AUS","Victoria","","14165","1036797778"
"Vicovu de Sus","Vicovu de Sus","47.9258","25.6800","Romania","RO","ROU","Suceava","","15143","1642275762"
"Ifrane","Ifrane","33.5333","-5.1167","Morocco","MA","MAR","Fès-Meknès","","14659","1504656119"
"Bankheri","Bankheri","22.7696","78.5341","India","IN","IND","Madhya Pradesh","","15314","1356075878"
"Panorama","Panorama","-21.3564","-51.8600","Brazil","BR","BRA","São Paulo","","15458","1076525343"
"Linguère","Linguere","15.3944","-15.1167","Senegal","SN","SEN","Louga","","15482","1686531921"
"Nālwār","Nalwar","16.9333","76.9833","India","IN","IND","Karnātaka","","15223","1356610966"
"Horsham","Horsham","-36.7167","142.2000","Australia","AU","AUS","Victoria","","14543","1036654340"
"Passo de Camarajibe","Passo de Camarajibe","-9.2378","-35.4928","Brazil","BR","BRA","Alagoas","","15419","1076530381"
"Neustadt bei Coburg","Neustadt bei Coburg","50.3289","11.1211","Germany","DE","DEU","Bavaria","","15257","1276116282"
"Santa Croce sull’ Arno","Santa Croce sull' Arno","43.7202","10.7727","Italy","IT","ITA","Tuscany","","14627","1380678049"
"Candelaria","Candelaria","18.4042","-66.2175","Puerto Rico","PR","PRI","Puerto Rico","","15498","1630023689"
"Batavia","Batavia","42.9987","-78.1802","United States","US","USA","New York","","15497","1840000385"
"Forfar","Forfar","56.6442","-2.8884","United Kingdom","GB","GBR","Angus","","14230","1826527188"
"Galanta","Galanta","48.1889","17.7264","Slovakia","SK","SVK","Trnava","minor","15052","1703120770"
"Capotille","Capotille","19.4500","-71.7000","Haiti","HT","HTI","Nord-Est","","15086","1332991129"
"Shchuchyn","Shchuchyn","53.6167","24.7333","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","15475","1112140509"
"Rio Grande City","Rio Grande City","26.3808","-98.8215","United States","US","USA","Texas","","15494","1840021016"
"Piquet Carneiro","Piquet Carneiro","-5.8039","-39.4178","Brazil","BR","BRA","Ceará","","15467","1076313017"
"Madagh","Madagh","35.0133","-2.3397","Morocco","MA","MAR","Oriental","","14486","1504000835"
"Salinas da Margarida","Salinas da Margarida","-12.8708","-38.7639","Brazil","BR","BRA","Bahia","","15385","1076707335"
"Ober-Ramstadt","Ober-Ramstadt","49.8333","8.7500","Germany","DE","DEU","Hesse","","15130","1276189736"
"Kalongo","Kalongo","3.0400","33.3711","Uganda","UG","UGA","Agago","","15000","1800772178"
"Mainburg","Mainburg","48.6500","11.7833","Germany","DE","DEU","Bavaria","","15241","1276991073"
"Hofgeismar","Hofgeismar","51.4833","9.4000","Germany","DE","DEU","Hesse","","15309","1276882512"
"Ban Bang Muang","Ban Bang Muang","13.8273","100.3859","Thailand","TH","THA","Nonthaburi","","13786","1764508090"
"Ratekau","Ratekau","53.9500","10.7333","Germany","DE","DEU","Schleswig-Holstein","","15231","1276252655"
"Ljungby","Ljungby","56.8333","13.9333","Sweden","SE","SWE","Kronoberg","minor","15205","1752960918"
"Altunhisar","Altunhisar","37.9981","34.3711","Turkey","TR","TUR","Niğde","minor","15463","1792539911"
"Tamaki","Tamaki","34.4902","136.6308","Japan","JP","JPN","Mie","","15113","1392003483"
"Sembedu","Sembedu","13.1298","79.5634","India","IN","IND","Tamil Nādu","","14922","1356153290"
"Manohisoa","Manohisoa","-19.7833","46.7667","Madagascar","MG","MDG","Antananarivo","","15422","1450417871"
"Campanha","Campanha","-21.8389","-45.3997","Brazil","BR","BRA","Minas Gerais","","15433","1076571008"
"Flers","Flers","48.7483","-0.5694","France","FR","FRA","Normandie","","14779","1250849360"
"Relangi","Relangi","16.7050","81.6470","India","IN","IND","Andhra Pradesh","","14559","1356133893"
"Ilovaisk","Ilovaisk","47.9250","38.1950","Ukraine","UA","UKR","Donetska Oblast","","15447","1804207514"
"Riegelsberg","Riegelsberg","49.2942","6.9375","Germany","DE","DEU","Saarland","","14501","1276278201"
"La Solana","La Solana","38.9414","-3.2394","Spain","ES","ESP","Castille-La Mancha","","15357","1724007812"
"Yeşilova","Yesilova","37.5069","29.7533","Turkey","TR","TUR","Burdur","minor","15457","1792622787"
"Salitre","Salitre","-7.2839","-40.4606","Brazil","BR","BRA","Ceará","","15453","1076715103"
"Churumuco de Morelos","Churumuco de Morelos","18.6167","-101.6333","Mexico","MX","MEX","Michoacán","minor","15455","1484004930"
"Nancagua","Nancagua","-34.6667","-71.2167","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","15409","1152511353"
"Suknadānga","Suknadanga","24.4600","88.0500","India","IN","IND","West Bengal","","14027","1356740992"
"Taperoá","Taperoa","-7.2064","-36.8236","Brazil","BR","BRA","Paraíba","","15441","1076037125"
"Berea","Berea","37.5904","-84.2898","United States","US","USA","Kentucky","","15465","1840013225"
"Ezine","Ezine","39.7900","26.3325","Turkey","TR","TUR","Çanakkale","minor","15408","1792825630"
"Itapiranga","Itapiranga","-27.1689","-53.7119","Brazil","BR","BRA","Santa Catarina","","15409","1076880830"
"Outreau","Outreau","50.7039","1.5939","France","FR","FRA","Hauts-de-France","","13575","1250076890"
"Jamao al Norte","Jamao al Norte","19.6500","-70.6167","Dominican Republic","DO","DOM","Cibao Norte","","15390","1214314716"
"Sher Chakla","Sher Chakla","25.3848","86.6952","India","IN","IND","Bihār","","13713","1356257783"
"Mian Sahib","Mian Sahib","28.1559","68.6397","Pakistan","PK","PAK","Sindh","","15000","1586510355"
"Erjie","Erjie","24.6997","102.4872","China","CN","CHN","Yunnan","","15350","1156472520"
"Pudur","Pudur","9.0001","77.2076","India","IN","IND","Tamil Nādu","","14665","1356243296"
"Bludenz","Bludenz","47.1533","9.8219","Austria","AT","AUT","Vorarlberg","minor","14956","1040404083"
"Satyāmangala","Satyamangala","13.0193","76.1243","India","IN","IND","Karnātaka","","15032","1356349288"
"Al Hammam","Al Hammam","33.1868","-5.6225","Morocco","MA","MAR","Béni Mellal-Khénifra","","15438","1504972770"
"Lahoysk","Lahoysk","54.2000","27.8500","Belarus","BY","BLR","Minskaya Voblasts’","minor","15442","1112489566"
"Büttelborn","Buttelborn","49.9022","8.5137","Germany","DE","DEU","Hesse","","14955","1276655872"
"Anazzou","Anazzou","30.6333","-8.0500","Morocco","MA","MAR","Souss-Massa","","15429","1504693352"
"Vieiro","Vieiro","43.6481","-7.5900","Spain","ES","ESP","Galicia","","15312","1724508956"
"Konārka","Konarka","19.8878","86.0948","India","IN","IND","Odisha","","15015","1356415171"
"Ala-Buka","Ala-Buka","41.4083","71.4806","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","15426","1417329927"
"Fortuna","Fortuna","10.4483","-84.6421","Costa Rica","CR","CRI","Alajuela","","15383","1188145328"
"Coremas","Coremas","-7.0139","-37.9458","Brazil","BR","BRA","Paraíba","","15409","1076489874"
"Featherstone","Featherstone","53.7000","-1.3700","United Kingdom","GB","GBR","Wakefield","","15244","1826477772"
"Broxbourne","Broxbourne","51.7495","-0.0216","United Kingdom","GB","GBR","Essex","","15303","1826900136"
"Scottsboro","Scottsboro","34.6438","-86.0491","United States","US","USA","Alabama","","15446","1840005605"
"Chubbuck","Chubbuck","42.9263","-112.4623","United States","US","USA","Idaho","","15445","1840018667"
"North Strabane","North Strabane","40.2279","-80.1488","United States","US","USA","Pennsylvania","","15445","1840147984"
"Clark","Clark","40.6203","-74.3134","United States","US","USA","New Jersey","","15445","1840081746"
"Méridjonou","Meridjonou","6.4619","2.6774","Benin","BJ","BEN","Ouémé","","13959","1204586133"
"Victoria","Victoria","12.4500","124.3167","Philippines","PH","PHL","Northern Samar","","15361","1608413047"
"Morlaix","Morlaix","48.5775","-3.8278","France","FR","FRA","Bretagne","minor","14845","1250299673"
"Tiruvalanjuli","Tiruvalanjuli","10.9449","79.3286","India","IN","IND","Tamil Nādu","","14460","1356035619"
"Kérouané","Kerouane","9.2704","-9.0200","Guinea","GN","GIN","Kankan","minor","15406","1324580549"
"Odenthal","Odenthal","51.0333","7.1167","Germany","DE","DEU","North Rhine-Westphalia","","15063","1276022742"
"Moul El Bergui","Moul El Bergui","32.5113","-8.9849","Morocco","MA","MAR","Marrakech-Safi","","15342","1504152809"
"Grain Valley","Grain Valley","39.0171","-94.2084","United States","US","USA","Missouri","","15435","1840008531"
"Agidel","Agidel","55.9000","53.9333","Russia","RU","RUS","Bashkortostan","","14601","1643864231"
"Głuchołazy","Glucholazy","50.3131","17.3742","Poland","PL","POL","Opolskie","","15293","1616458381"
"Sabinópolis","Sabinopolis","-18.6658","-43.0839","Brazil","BR","BRA","Minas Gerais","","15416","1076277315"
"Jangīd","Jangid","18.4475","79.8414","India","IN","IND","Andhra Pradesh","","15190","1356065255"
"Wālājābād","Walajabad","12.4994","80.1472","India","IN","IND","Tamil Nādu","","14325","1356636684"
"Aravakkurichchi","Aravakkurichchi","10.7760","77.9076","India","IN","IND","Tamil Nādu","","15205","1356216819"
"Glenn Dale","Glenn Dale","38.9833","-76.8040","United States","US","USA","Maryland","","15426","1840005955"
"Tūprān","Tupran","17.8447","78.4800","India","IN","IND","Andhra Pradesh","","14401","1356032656"
"Klimavichy","Klimavichy","53.6167","31.9500","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","15408","1112437772"
"Schroeder","Schroeder","-26.4128","-49.0728","Brazil","BR","BRA","Santa Catarina","","15316","1076396669"
"Kanegasaki","Kanegasaki","39.1957","141.1163","Japan","JP","JPN","Iwate","","15337","1392073512"
"Rubiera","Rubiera","44.6500","10.7833","Italy","IT","ITA","Emilia-Romagna","","14833","1380779258"
"Narahia","Narahia","26.3681","86.5434","India","IN","IND","Bihār","","14450","1356992369"
"Jacaraci","Jacaraci","-14.8500","-42.4328","Brazil","BR","BRA","Bahia","","15409","1076287718"
"Gomboussougou","Gomboussougou","11.4333","-0.7667","Burkina Faso","BF","BFA","Centre-Sud","","15336","1854877911"
"Putaparti","Putaparti","14.1652","77.8117","India","IN","IND","Andhra Pradesh","","15088","1356082274"
"Landázuri","Landazuri","6.2181","-73.8114","Colombia","CO","COL","Santander","minor","15395","1170306835"
"Razan","Razan","35.3867","49.0339","Iran","IR","IRN","Hamadān","minor","15371","1364076890"
"Andrakata","Andrakata","-14.6167","49.7167","Madagascar","MG","MDG","Antsiranana","","15401","1450466560"
"Tulle","Tulle","45.2658","1.7722","France","FR","FRA","Nouvelle-Aquitaine","minor","14812","1250644176"
"Amatitán","Amatitan","20.8333","-103.7167","Mexico","MX","MEX","Jalisco","minor","15344","1484634445"
"Ban Khek Noi","Ban Khek Noi","16.8118","100.9671","Thailand","TH","THA","Phetchabun","","15382","1764751835"
"Daisen","Daisen","35.5000","133.5000","Japan","JP","JPN","Tottori","","15336","1392889589"
"Atyrá","Atyra","-25.2786","-57.1706","Paraguay","PY","PRY","Cordillera","","15278","1600262669"
"Villa del Rosario","Villa del Rosario","-31.5833","-63.5333","Argentina","AR","ARG","Córdoba","minor","15394","1032540940"
"Neutraubling","Neutraubling","48.9936","12.1953","Germany","DE","DEU","Bavaria","","13981","1276485988"
"Evans","Evans","42.6528","-79.0063","United States","US","USA","New York","","15414","1840087420"
"Nova Resende","Nova Resende","-21.1258","-46.4200","Brazil","BR","BRA","Minas Gerais","","15374","1076349415"
"Grande Saline","Grande Saline","19.2500","-72.7833","Haiti","HT","HTI","Artibonite","","14940","1332897755"
"Barro Alto","Barro Alto","-11.7608","-41.9119","Brazil","BR","BRA","Bahia","","15377","1076372996"
"Dattapulia","Dattapulia","23.2404","88.7115","India","IN","IND","West Bengal","","13562","1356802865"
"Mendrisio","Mendrisio","45.8667","8.9833","Switzerland","CH","CHE","Ticino","","14942","1756337416"
"Lynden","Lynden","48.9502","-122.4545","United States","US","USA","Washington","","15410","1840019752"
"Durham","Durham","43.1174","-70.9194","United States","US","USA","New Hampshire","","15410","1840054805"
"Azpeitia","Azpeitia","43.1819","-2.2653","Spain","ES","ESP","Basque Country","","15191","1724450790"
"Serinyol","Serinyol","36.3667","36.2000","Turkey","TR","TUR","Hatay","minor","14751","1792914235"
"Ambongamarina","Ambongamarina","-18.3250","47.9542","Madagascar","MG","MDG","Antananarivo","","15382","1450442766"
"Vila Bela da Santíssima Trindade","Vila Bela da Santissima Trindade","-15.0078","-59.9508","Brazil","BR","BRA","Mato Grosso","","15406","1076493823"
"Chak Husaini","Chak Husaini","25.5169","86.5566","India","IN","IND","Bihār","","13685","1356015975"
"Bāra","Bara","24.3146","87.9643","India","IN","IND","West Bengal","","14450","1356955037"
"Calolziocorte","Calolziocorte","45.8000","9.4333","Italy","IT","ITA","Lombardy","","13877","1380962766"
"Edingen-Neckarhausen","Edingen-Neckarhausen","49.4469","8.6094","Germany","DE","DEU","Baden-Württemberg","","14238","1276673287"
"Basāon","Basaon","26.2082","84.6174","India","IN","IND","Bihār","","13409","1356083614"
"Libagon","Libagon","10.3000","125.0500","Philippines","PH","PHL","Southern Leyte","","15244","1608224039"
"Mulakaledu","Mulakaledu","14.3648","76.9267","India","IN","IND","Andhra Pradesh","","15234","1356795956"
"Carthage","Carthage","37.1503","-94.3225","United States","US","USA","Missouri","","15395","1840007629"
"Markgröningen","Markgroningen","48.9047","9.0808","Germany","DE","DEU","Baden-Württemberg","","14865","1276236194"
"Mantua","Mantua","39.7618","-75.1686","United States","US","USA","New Jersey","","15393","1840081677"
"Springdale","Springdale","39.8769","-74.9724","United States","US","USA","New Jersey","","15393","1840133731"
"Rochedale","Rochedale","-27.6000","153.1330","Australia","AU","AUS","Queensland","","15317","1036018437"
"Warni","Warni","18.5436","77.9072","India","IN","IND","Andhra Pradesh","","15123","1356627298"
"Ashtead","Ashtead","51.3100","-0.2990","United Kingdom","GB","GBR","Surrey","","14169","1826304464"
"Thoen","Thoen","17.6100","99.2234","Thailand","TH","THA","Lampang","minor","15360","1764582305"
"Tendrara","Tendrara","33.0500","-2.0000","Morocco","MA","MAR","Oriental","","15390","1504340310"
"Olonne-sur-Mer","Olonne-sur-Mer","46.5361","-1.7728","France","FR","FRA","Pays de la Loire","","15061","1250986690"
"Guipavas","Guipavas","48.4336","-4.4008","France","FR","FRA","Bretagne","","15050","1250618199"
"Māndleshwar","Mandleshwar","22.1760","75.6599","India","IN","IND","Madhya Pradesh","","14983","1356901779"
"Vişeu de Sus","Viseu de Sus","47.7092","24.4239","Romania","RO","ROU","Maramureş","","15349","1642452297"
"Veys","Veys","31.4825","48.8747","Iran","IR","IRN","Khūzestān","","15312","1364127242"
"Groß-Zimmern","Gross-Zimmern","49.8726","8.8343","Germany","DE","DEU","Hesse","","14691","1276589803"
"Lokomby","Lokomby","-22.1833","47.7500","Madagascar","MG","MDG","Fianarantsoa","","15000","1450028790"
"Serra Preta","Serra Preta","-12.1600","-39.3319","Brazil","BR","BRA","Bahia","","15351","1076184457"
"Castro Daire","Castro Daire","40.9000","-7.9333","Portugal","PT","PRT","Viseu","minor","15339","1620316265"
"Mirzāpur","Mirzapur","26.1616","87.2349","India","IN","IND","Bihār","","13985","1356332156"
"Chaltyr","Chaltyr","47.2848","39.4823","Russia","RU","RUS","Rostovskaya Oblast’","minor","15334","1643982881"
"Tiruppāchūr","Tiruppachur","13.1384","79.8764","India","IN","IND","Tamil Nādu","","14560","1356568594"
"Oulad Bou Rahmoun","Oulad Bou Rahmoun","32.2954","-6.6668","Morocco","MA","MAR","Béni Mellal-Khénifra","","15113","1504264037"
"Tubod","Tubod","9.5547","125.5697","Philippines","PH","PHL","Surigao del Norte","","15043","1608095348"
"Uvalde","Uvalde","29.2152","-99.7782","United States","US","USA","Texas","","15374","1840022231"
"Barakī","Baraki","33.9333","68.9167","Afghanistan","AF","AFG","Lōgar","","15000","1004392037"
"Pedara","Pedara","37.6167","15.0667","Italy","IT","ITA","Sicilia","","14613","1380359884"
"Baulia","Baulia","25.3990","87.6002","India","IN","IND","Bihār","","14345","1356661601"
"Swallownest","Swallownest","53.3623","-1.3251","United Kingdom","GB","GBR","Rotherham","","15022","1826951859"
"Great Baddow","Great Baddow","51.7190","0.5070","United Kingdom","GB","GBR","Essex","","14650","1826685396"
"Lakkundi","Lakkundi","15.3897","75.7183","India","IN","IND","Karnātaka","","14922","1356271975"
"Saint-Genis-Pouilly","Saint-Genis-Pouilly","46.2433","6.0214","France","FR","FRA","Auvergne-Rhône-Alpes","","13943","1250112043"
"Saloá","Saloa","-8.9758","-36.6878","Brazil","BR","BRA","Pernambuco","","15309","1076257586"
"Hohenstein-Ernstthal","Hohenstein-Ernstthal","50.8000","12.7167","Germany","DE","DEU","Saxony","","14607","1276671628"
"Three Lakes","Three Lakes","25.6415","-80.4000","United States","US","USA","Florida","","15367","1840029010"
"Putaendo","Putaendo","-32.6278","-70.7167","Chile","CL","CHL","Valparaíso","","15361","1152325898"
"Münster","Munster","49.9167","8.8667","Germany","DE","DEU","Hesse","","14672","1276203607"
"Mackworth","Mackworth","52.9277","-1.5373","United Kingdom","GB","GBR","Derby","","14180","1826657031"
"Motiong","Motiong","11.7833","125.0000","Philippines","PH","PHL","Samar","","15276","1608299750"
"Wakuya","Wakuya","38.5397","141.1282","Japan","JP","JPN","Miyagi","","15177","1392967427"
"Antarvedipālem","Antarvedipalem","16.3319","81.7320","India","IN","IND","Andhra Pradesh","","14162","1356118051"
"Greeneville","Greeneville","36.1680","-82.8197","United States","US","USA","Tennessee","","15361","1840016099"
"Krasnovishersk","Krasnovishersk","60.4167","57.1000","Russia","RU","RUS","Permskiy Kray","","15359","1643010731"
"Gräfelfing","Grafelfing","48.1189","11.4289","Germany","DE","DEU","Bavaria","","13939","1276126852"
"Avrillé","Avrille","47.5069","-0.5889","France","FR","FRA","Pays de la Loire","","14443","1250508041"
"San Luis del Palmar","San Luis del Palmar","-27.5167","-58.5667","Argentina","AR","ARG","Corrientes","minor","15347","1032360742"
"Brake","Brake","53.3333","8.4833","Germany","DE","DEU","Lower Saxony","minor","14965","1276055035"
"Maydolong","Maydolong","11.5000","125.5000","Philippines","PH","PHL","Eastern Samar","","15314","1608825891"
"Bar-le-Duc","Bar-le-Duc","48.7717","5.1672","France","FR","FRA","Grand Est","minor","14733","1250231244"
"Hückeswagen","Huckeswagen","51.1450","7.3417","Germany","DE","DEU","North Rhine-Westphalia","","15060","1276742746"
"Liangwancun","Liangwancun","28.4466","104.2563","China","CN","CHN","Yunnan","","15239","1156673586"
"Horw","Horw","47.0167","8.3167","Switzerland","CH","CHE","Luzern","","14243","1756927189"
"Chilcuautla","Chilcuautla","20.3333","-99.2333","Mexico","MX","MEX","Hidalgo","minor","15284","1484513510"
"Chīpurupalle","Chipurupalle","18.3114","83.5683","India","IN","IND","Andhra Pradesh","","14847","1356643864"
"Poynton","Poynton","53.3500","-2.1167","United Kingdom","GB","GBR","Cheshire East","","14260","1826399707"
"Eldorado","Eldorado","-24.5200","-48.1081","Brazil","BR","BRA","São Paulo","","15339","1076129715"
"Guática","Guatica","5.3167","-75.8000","Colombia","CO","COL","Risaralda","minor","15306","1170331290"
"Alta","Alta","69.9689","23.2716","Norway","NO","NOR","Troms og Finnmark","minor","15342","1578308187"
"Şüvəlan","Suvalan","40.4889","50.2069","Azerbaijan","AZ","AZE","Bakı","","14992","1031090215"
"Raposos","Raposos","-19.9669","-43.8039","Brazil","BR","BRA","Minas Gerais","","15342","1076085875"
"Bennington","Bennington","42.8854","-73.2133","United States","US","USA","Vermont","","15345","1840070381"
"Kāttupputtūr","Kattupputtur","10.9833","78.2333","India","IN","IND","Tamil Nādu","","14675","1356099704"
"Sanyi","Sanyi","24.4167","120.7667","Taiwan","TW","TWN","Miaoli","","15124","1158612872"
"Port Glasgow","Port Glasgow","55.9340","-4.6906","United Kingdom","GB","GBR","Inverclyde","","14620","1826330462"
"Glasgow","Glasgow","39.6015","-75.7473","United States","US","USA","Delaware","","15342","1840005571"
"Springfield","Springfield","49.9292","-96.6939","Canada","CA","CAN","Manitoba","","15342","1124000696"
"Nazaré","Nazare","39.6000","-9.0667","Portugal","PT","PRT","Leiria","minor","15158","1620000054"
"Malo","Malo","45.6582","11.4047","Italy","IT","ITA","Veneto","","14855","1380662716"
"Seekonk","Seekonk","41.8379","-71.3174","United States","US","USA","Massachusetts","","15339","1840053523"
"Amāha","Amaha","26.1284","86.7998","India","IN","IND","Bihār","","13951","1356129136"
"Noto","Noto","37.3103","137.1477","Japan","JP","JPN","Ishikawa","","15280","1392003490"
"Mastic Beach","Mastic Beach","40.7664","-72.8369","United States","US","USA","New York","","15333","1840005089"
"Devendranagar","Devendranagar","24.6169","80.3789","India","IN","IND","Madhya Pradesh","","15065","1356740875"
"Yankton","Yankton","42.8901","-97.3927","United States","US","USA","South Dakota","","15332","1840003058"
"Junín","Junin","-11.1500","-75.9833","Peru","PE","PER","Junín","","15320","1604997443"
"Douar El Arbaa Bou Quorra","Douar El Arbaa Bou Quorra","34.7490","-5.1982","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","15165","1504563622"
"Loviisa","Loviisa","60.4583","26.2333","Finland","FI","FIN","Uusimaa","minor","15311","1246944790"
"Fraga","Fraga","41.5200","0.3500","Spain","ES","ESP","Aragon","","15294","1724042587"
"Castellammare del Golfo","Castellammare del Golfo","38.0264","12.8806","Italy","IT","ITA","Sicilia","","15209","1380531580"
"Hindalgi","Hindalgi","16.1364","74.6547","India","IN","IND","Karnātaka","","14337","1356494231"
"Villa Paranacito","Villa Paranacito","-33.7000","-58.6833","Argentina","AR","ARG","Entre Ríos","minor","15321","1032597939"
"Kishanpur Ratwāra","Kishanpur Ratwara","25.4822","86.8511","India","IN","IND","Bihār","","13862","1356060129"
"Zevio","Zevio","45.3728","11.1303","Italy","IT","ITA","Veneto","","15048","1380762128"
"Shāl","Shal","35.8983","49.7722","Iran","IR","IRN","Qazvīn","","15290","1364939972"
"Penacova","Penacova","40.2706","-8.2833","Portugal","PT","PRT","Coimbra","minor","15251","1620105584"
"Belokurikha","Belokurikha","51.9833","84.9833","Russia","RU","RUS","Altayskiy Kray","","15160","1643613232"
"Kurate","Kurate","33.7919","130.6742","Japan","JP","JPN","Fukuoka","","14901","1392001825"
"Kawaminami","Kawaminami","32.1920","131.5259","Japan","JP","JPN","Miyazaki","","15151","1392313930"
"Driouch","Driouch","34.9833","-3.3833","Morocco","MA","MAR","Oriental","","14741","1504125393"
"Eden","Eden","36.5027","-79.7412","United States","US","USA","North Carolina","","15317","1840013309"
"Guastalla","Guastalla","44.9167","10.6667","Italy","IT","ITA","Emilia-Romagna","","15032","1380315818"
"Hayden","Hayden","47.7680","-116.8040","United States","US","USA","Idaho","","15316","1840019808"
"Niquinohomo","Niquinohomo","11.9047","-86.0956","Nicaragua","NI","NIC","Masaya","minor","14847","1558281722"
"Port Orchard","Port Orchard","47.5163","-122.6610","United States","US","USA","Washington","","15315","1840019821"
"Capinópolis","Capinopolis","-18.6819","-49.5700","Brazil","BR","BRA","Minas Gerais","","15290","1076136434"
"Tazert","Tazert","31.6597","-7.4092","Morocco","MA","MAR","Marrakech-Safi","","15243","1504394144"
"Poronaysk","Poronaysk","49.2167","143.1167","Russia","RU","RUS","Sakhalinskaya Oblast’","","15311","1643094568"
"Ledyard","Ledyard","41.4400","-72.0167","United States","US","USA","Connecticut","","15313","1840045034"
"Kankipādu","Kankipadu","16.4500","80.7833","India","IN","IND","Andhra Pradesh","","14616","1356836923"
"Ibipitanga","Ibipitanga","-12.8819","-42.4858","Brazil","BR","BRA","Bahia","","15296","1076883970"
"Sannicandro Garganico","Sannicandro Garganico","41.8333","15.5667","Italy","IT","ITA","Puglia","","15224","1380967856"
"Wentorf bei Hamburg","Wentorf bei Hamburg","53.4931","10.2533","Germany","DE","DEU","Schleswig-Holstein","","13366","1276516107"
"Santa Luzia","Santa Luzia","-6.8719","-36.9189","Brazil","BR","BRA","Paraíba","","15278","1076884988"
"San Vito al Tagliamento","San Vito al Tagliamento","45.9000","12.8667","Italy","IT","ITA","Friuli Venezia Giulia","","15063","1380256352"
"Aïn Jemaa","Ain Jemaa","34.0333","-5.8000","Morocco","MA","MAR","Fès-Meknès","","15265","1504158964"
"Hallstahammar","Hallstahammar","59.6167","16.2500","Sweden","SE","SWE","Västmanland","minor","15200","1752239523"
"Hartselle","Hartselle","34.4391","-86.9396","United States","US","USA","Alabama","","15308","1840013593"
"Tagoloan","Tagoloan","8.1333","124.2667","Philippines","PH","PHL","Lanao del Norte","","15091","1608053612"
"Tenafly","Tenafly","40.9176","-73.9532","United States","US","USA","New Jersey","","15307","1840000918"
"Pineto","Pineto","42.6167","14.0667","Italy","IT","ITA","Abruzzo","","14915","1380708207"
"Blomberg","Blomberg","51.9333","9.0833","Germany","DE","DEU","North Rhine-Westphalia","","15154","1276943915"
"Kurichchi","Kurichchi","11.5701","77.6969","India","IN","IND","Tamil Nādu","","14980","1356004481"
"Sippola","Sippola","60.7392","27.0003","Finland","FI","FIN","Kymenlaakso","","15278","1246444349"
"Dengshangcun","Dengshangcun","41.3442","118.1676","China","CN","CHN","Hebei","","15245","1156570044"
"Privolzhsk","Privolzhsk","57.3825","41.2861","Russia","RU","RUS","Ivanovskaya Oblast’","","15264","1643261146"
"Sárvár","Sarvar","47.2500","16.9333","Hungary","HU","HUN","Vas","minor","15072","1348516359"
"Tapolca","Tapolca","46.8828","17.4081","Hungary","HU","HUN","Veszprém","minor","15072","1348079279"
"Kamitonda","Kamitonda","33.6963","135.4288","Japan","JP","JPN","Wakayama","","15040","1392003148"
"Selb","Selb","50.1667","12.1333","Germany","DE","DEU","Bavaria","","15128","1276030522"
"Langelsheim","Langelsheim","51.9381","10.3350","Germany","DE","DEU","Lower Saxony","","14990","1276567894"
"Santa Maria a Vico","Santa Maria a Vico","41.0333","14.4833","Italy","IT","ITA","Campania","","14004","1380703992"
"Roanoke Rapids","Roanoke Rapids","36.4452","-77.6490","United States","US","USA","North Carolina","","15294","1840014437"
"Chislehurst","Chislehurst","51.4120","0.0750","United Kingdom","GB","GBR","Bromley","","14831","1826578404"
"Ribeiro do Amparo","Ribeiro do Amparo","-11.0469","-38.4328","Brazil","BR","BRA","Bahia","","15269","1076783278"
"Bennane","Bennane","35.6833","10.8333","Tunisia","TN","TUN","Monastir","","14218","1788571318"
"Pāli","Pali","26.0576","86.3097","India","IN","IND","Bihār","","13984","1356354969"
"Xihuangni","Xihuangni","38.3575","113.8520","China","CN","CHN","Hebei","","15119","1156189817"
"Mexborough","Mexborough","53.4992","-1.2830","United Kingdom","GB","GBR","Doncaster","","15244","1826750525"
"Witzenhausen","Witzenhausen","51.3422","9.8578","Germany","DE","DEU","Hesse","","15167","1276417378"
"Chellaston","Chellaston","52.8671","-1.4384","United Kingdom","GB","GBR","Derbyshire","","15198","1826021847"
"Bétérou","Beterou","9.2000","2.2667","Benin","BJ","BEN","Borgou","","15236","1204549567"
"Mani","Mani","13.2600","-0.2094","Burkina Faso","BF","BFA","Est","","15066","1854799448"
"Grodzisk Wielkopolski","Grodzisk Wielkopolski","52.2333","16.3667","Poland","PL","POL","Wielkopolskie","minor","15131","1616036580"
"Yinhua","Yinhua","33.4530","110.2601","China","CN","CHN","Shaanxi","","14999","1156133477"
"Uiraúna","Uirauna","-6.5178","-38.4119","Brazil","BR","BRA","Paraíba","","15228","1076023422"
"Vubatalai","Vubatalai","11.3597","76.7649","India","IN","IND","Tamil Nādu","","14640","1356521470"
"Pestovo","Pestovo","58.6000","35.8167","Russia","RU","RUS","Novgorodskaya Oblast’","","15269","1643460089"
"Grefrath","Grefrath","51.3363","6.3408","Germany","DE","DEU","North Rhine-Westphalia","","14802","1276931895"
"Robbinsville","Robbinsville","40.2220","-74.5910","United States","US","USA","New Jersey","","15277","1840081653"
"Ban Ton Thong Chai","Ban Ton Thong Chai","18.3375","99.5334","Thailand","TH","THA","Lampang","","15093","1764171016"
"Città Sant’Angelo","Citta Sant'Angelo","42.5167","14.0500","Italy","IT","ITA","Abruzzo","","15034","1380482747"
"Oria","Oria","40.5000","17.6333","Italy","IT","ITA","Puglia","","15094","1380772733"
"Támesis","Tamesis","5.6667","-75.7167","Colombia","CO","COL","Antioquia","minor","15218","1170438434"
"Johnson City","Johnson City","42.1230","-75.9624","United States","US","USA","New York","","15275","1840004658"
"Souk Et-Tleta des Oulad Hamdane","Souk Et-Tleta des Oulad Hamdane","33.1047","-8.2495","Morocco","MA","MAR","Casablanca-Settat","","15205","1504163979"
"Jackson","Jackson","37.3792","-89.6522","United States","US","USA","Missouri","","15272","1840008735"
"Pullambādi","Pullambadi","10.9667","78.9167","India","IN","IND","Tamil Nādu","","14803","1356106831"
"Çatalpınar","Catalpinar","40.8790","37.4532","Turkey","TR","TUR","Ordu","minor","14954","1792029598"
"Majiagoucha","Majiagoucha","37.5033","109.6303","China","CN","CHN","Shaanxi","","15128","1156112478"
"Gagarin Shahri","Gagarin Shahri","40.6619","68.1722","Uzbekistan","UZ","UZB","Jizzax","minor","15200","1860088960"
"Winkfield","Winkfield","51.4318","-0.7096","United Kingdom","GB","GBR","Bracknell Forest","","14998","1826199391"
"Laxou","Laxou","48.6856","6.1522","France","FR","FRA","Grand Est","","14366","1250742857"
"Valangimān","Valangiman","10.8897","79.3940","India","IN","IND","Tamil Nādu","","14890","1356317285"
"Władysławowo","Wladyslawowo","54.8000","18.4000","Poland","PL","POL","Pomorskie","","15015","1616875034"
"Laterza","Laterza","40.6333","16.8000","Italy","IT","ITA","Puglia","","15171","1380697612"
"Fray Luis A. Beltrán","Fray Luis A. Beltran","-32.7833","-60.7333","Argentina","AR","ARG","Santa Fe","","15176","1032928868"
"Traverse City","Traverse City","44.7546","-85.6038","United States","US","USA","Michigan","","15263","1840003946"
"Katkol","Katkol","15.9500","75.3000","India","IN","IND","Karnātaka","","15038","1356774848"
"Robinson","Robinson","40.4578","-80.1334","United States","US","USA","Pennsylvania","","15261","1840149298"
"Västerhaninge","Vasterhaninge","59.1167","18.0667","Sweden","SE","SWE","Stockholm","minor","15134","1752693403"
"Spitak","Spitak","40.8372","44.2675","Armenia","AM","ARM","Lorri","","15000","1051088654"
"La Apartada","La Apartada","8.1006","-75.3825","Colombia","CO","COL","Córdoba","minor","15204","1170000626"
"Pâ","Pa","11.5500","-3.2600","Burkina Faso","BF","BFA","Boucle du Mouhoun","","15170","1854939518"
"Sabbah","Sabbah","33.8036","-7.0372","Morocco","MA","MAR","Rabat-Salé-Kénitra","","15029","1504883472"
"São Lourenço da Serra","Sao Lourenco da Serra","-23.8528","-46.9428","Brazil","BR","BRA","São Paulo","","15177","1076572943"
"Paralímni","Paralimni","35.0333","33.9833","Cyprus","CY","CYP","Ammóchostos","","14963","1196452575"
"Humayingcun","Humayingcun","41.1145","116.8985","China","CN","CHN","Hebei","","15207","1156450682"
"Boksitogorsk","Boksitogorsk","59.4833","33.8333","Russia","RU","RUS","Leningradskaya Oblast’","","15249","1643927094"
"Barāri","Barari","25.5068","87.3761","India","IN","IND","Bihār","","14025","1356712266"
"Ilha de Moçambique","Ilha de Mocambique","-15.0367","40.7328","Mozambique","MZ","MOZ","Nampula","","14000","1508063712"
"Salyan","Salyan","28.3500","82.1833","Nepal","NP","NPL","","","15000","1524828921"
"Kujūkuri","Kujukuri","35.5333","140.4333","Japan","JP","JPN","Chiba","","14652","1392003306"
"Northview","Northview","43.0427","-85.6016","United States","US","USA","Michigan","","15251","1840004305"
"Afzala","Afzala","25.9319","86.2293","India","IN","IND","Bihār","","13475","1356634784"
"Peddapalle","Peddapalle","14.4046","78.9456","India","IN","IND","Andhra Pradesh","","15065","1356337770"
"Kingersheim","Kingersheim","47.7914","7.3381","France","FR","FRA","Grand Est","","13267","1250069091"
"Ritterhude","Ritterhude","53.1861","8.7580","Germany","DE","DEU","Lower Saxony","","14800","1276391599"
"Dehmoí","Dehmoi","40.2167","69.5167","Tajikistan","TJ","TJK","Sughd","","14802","1762633756"
"Upper Southampton","Upper Southampton","40.1723","-75.0363","United States","US","USA","Pennsylvania","","15249","1840150058"
"White","White","40.6210","-79.1513","United States","US","USA","Pennsylvania","","15249","1840151488"
"Beckingen","Beckingen","49.3928","6.7008","Germany","DE","DEU","Saarland","","14958","1276301126"
"Brejões","Brejoes","-13.1039","-39.7958","Brazil","BR","BRA","Bahia","","15214","1076965135"
"Campodarsego","Campodarsego","45.5000","11.9167","Italy","IT","ITA","Veneto","","14675","1380456540"
"Dentsville","Dentsville","34.0754","-80.9547","United States","US","USA","South Carolina","","15245","1840013089"
"Charām","Charam","30.7461","50.7461","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","minor","15218","1364997839"
"São Félix","Sao Felix","-12.6050","-38.9719","Brazil","BR","BRA","Bahia","","15091","1076181846"
"Terra Rica","Terra Rica","-22.7089","-52.6169","Brazil","BR","BRA","Paraná","","15221","1076538470"
"Méru","Meru","49.2358","2.1350","France","FR","FRA","Hauts-de-France","","14609","1250924267"
"Laurinburg","Laurinburg","34.7602","-79.4773","United States","US","USA","North Carolina","","15242","1840014631"
"Thibodaux","Thibodaux","29.7949","-90.8146","United States","US","USA","Louisiana","","15242","1840015946"
"Uchiko","Uchiko","33.5330","132.6583","Japan","JP","JPN","Ehime","","15191","1392976487"
"Berlare","Berlare","51.0250","4.0025","Belgium","BE","BEL","Flanders","","14849","1056435659"
"Ahram","Ahram","28.8833","51.2750","Iran","IR","IRN","Būshehr","minor","15198","1364866540"
"Majholi","Majholi","23.5011","79.9240","India","IN","IND","Madhya Pradesh","","14939","1356086946"
"Rizal","Rizal","8.5272","123.5517","Philippines","PH","PHL","Zamboanga del Norte","","15052","1608940142"
"Franklin Park","Franklin Park","40.5903","-80.0999","United States","US","USA","Pennsylvania","","15239","1840001198"
"Qantīr","Qantir","30.8000","31.8333","Egypt","EG","EGY","Ash Sharqīyah","","13660","1818827672"
"Revūr","Revur","16.8216","79.9926","India","IN","IND","Andhra Pradesh","","15041","1356950387"
"Bradley","Bradley","41.1641","-87.8452","United States","US","USA","Illinois","","15236","1840011693"
"Murray Bridge","Murray Bridge","-35.1170","139.2670","Australia","AU","AUS","South Australia","","15043","1036345943"
"Tarumã","Taruma","-22.7469","-50.5769","Brazil","BR","BRA","São Paulo","","15183","1076499806"
"Attibele","Attibele","12.7781","77.7726","India","IN","IND","Karnātaka","","13937","1356055941"
"Vammala","Vammala","61.3417","22.9083","Finland","FI","FIN","Pirkanmaa","","15213","1246493883"
"Teolândia","Teolandia","-13.6019","-39.4908","Brazil","BR","BRA","Bahia","","15178","1076955332"
"Río San Juan","Rio San Juan","19.6400","-70.0800","Dominican Republic","DO","DOM","Cibao Nordeste","","15168","1214629665"
"Belchertown","Belchertown","42.2788","-72.4004","United States","US","USA","Massachusetts","","15228","1840053455"
"Andrésy","Andresy","48.9808","2.0583","France","FR","FRA","Île-de-France","","13302","1250507183"
"Naduvattam","Naduvattam","11.4808","76.5437","India","IN","IND","Tamil Nādu","","15008","1356153745"
"Florencia","Florencia","10.3665","-84.4814","Costa Rica","CR","CRI","Alajuela","","15149","1188578028"
"Santa Adélia","Santa Adelia","-21.2428","-48.8039","Brazil","BR","BRA","São Paulo","","15178","1076471467"
"Clinton","Clinton","42.4119","-71.6888","United States","US","USA","Massachusetts","","15221","1840053585"
"Nakanojōmachi","Nakanojomachi","36.5898","138.8410","Japan","JP","JPN","Gunma","","15186","1392957874"
"Bedfordview","Bedfordview","-26.1794","28.1361","South Africa","ZA","ZAF","Gauteng","","13959","1710039739"
"Ambahive","Ambahive","-22.2000","47.7167","Madagascar","MG","MDG","Fianarantsoa","","15000","1450201009"
"Masty","Masty","53.4170","24.5500","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","15197","1112000626"
"Hārohalli","Harohalli","12.6807","77.4743","India","IN","IND","Karnātaka","","14966","1356851750"
"Tartarugalzinho","Tartarugalzinho","1.5058","-50.9119","Brazil","BR","BRA","Amapá","","15212","1076510504"
"Gloversville","Gloversville","43.0491","-74.3465","United States","US","USA","New York","","15211","1840000375"
"Domaniç","Domanic","39.8033","29.6178","Turkey","TR","TUR","Kütahya","minor","15181","1792195942"
"Talata Ampano","Talata Ampano","-21.5500","47.0333","Madagascar","MG","MDG","Fianarantsoa","","15000","1450352718"
"Samobor","Samobor","45.8000","15.7167","Croatia","HR","HRV","Zagrebačka Županija","minor","15147","1191807327"
"Itajobi","Itajobi","-21.3178","-49.0539","Brazil","BR","BRA","São Paulo","","15177","1076185056"
"Ben Taieb","Ben Taieb","35.0837","-3.4696","Morocco","MA","MAR","Oriental","","14257","1504783487"
"Kaguchi","Kaguchi","11.4519","76.8061","India","IN","IND","Tamil Nādu","","14988","1356141085"
"Mahaly","Mahaly","-24.1667","46.3500","Madagascar","MG","MDG","Toliara","","15197","1450092739"
"Gayéri","Gayeri","12.6500","0.5000","Burkina Faso","BF","BFA","Est","minor","15170","1854170724"
"Kaintragarh","Kaintragarh","20.7211","84.5351","India","IN","IND","Odisha","","15016","1356082620"
"Choszczno","Choszczno","53.1667","15.4167","Poland","PL","POL","Zachodniopomorskie","minor","15109","1616054552"
"Beech Grove","Beech Grove","39.7157","-86.0871","United States","US","USA","Indiana","","15202","1840007307"
"Nitte","Nitte","13.1823","74.9397","India","IN","IND","Karnātaka","","15022","1356038440"
"Romilly-sur-Seine","Romilly-sur-Seine","48.5158","3.7267","France","FR","FRA","Grand Est","","14623","1250726047"
"Bellavista","Bellavista","-34.9333","-71.3167","Chile","CL","CHL","Maule","","15187","1152249017"
"Sisian","Sisian","39.5208","46.0322","Armenia","AM","ARM","Syunik’","","14894","1051297901"
"Huchuan","Huchuan","34.9249","106.1409","China","CN","CHN","Gansu","","14993","1156405295"
"Alvinópolis","Alvinopolis","-20.1069","-43.0489","Brazil","BR","BRA","Minas Gerais","","15169","1076309584"
"Aiyetoro Gbede","Aiyetoro Gbede","7.9833","6.0000","Nigeria","NG","NGA","Kogi","","15000","1566150800"
"Arnedo","Arnedo","42.2167","-2.1000","Spain","ES","ESP","La Rioja","","15017","1724772767"
"Antsoso","Antsoso","-19.8167","46.8667","Madagascar","MG","MDG","Antananarivo","","15000","1450373078"
"Lakkampatti","Lakkampatti","11.4461","77.4118","India","IN","IND","Tamil Nādu","","14570","1356163777"
"Boguszów-Gorce","Boguszow-Gorce","50.7667","16.2000","Poland","PL","POL","Dolnośląskie","","14648","1616569765"
"Kesamudram","Kesamudram","17.6875","79.8944","India","IN","IND","Andhra Pradesh","","14846","1356260742"
"Depew","Depew","42.9118","-78.7043","United States","US","USA","New York","","15188","1840004397"
"Chāprā","Chapra","23.5371","88.5527","India","IN","IND","West Bengal","","14123","1356891953"
"Hola Prystan","Hola Prystan","46.5167","32.5167","Ukraine","UA","UKR","Khersonska Oblast","minor","15163","1804132171"
"Sāmbre","Sambre","15.8800","74.5600","India","IN","IND","Karnātaka","","14196","1356592011"
"Kurumbalūr","Kurumbalur","11.2360","78.8005","India","IN","IND","Tamil Nādu","","14686","1356242181"
"Richterswil","Richterswil","47.2167","8.7000","Switzerland","CH","CHE","Zürich","","13407","1756472939"
"Niedernhausen","Niedernhausen","50.1617","8.3176","Germany","DE","DEU","Hesse","","14766","1276265252"
"Moura","Moura","38.1333","-7.4500","Portugal","PT","PRT","Beja","minor","15167","1620196948"
"Tazzarine","Tazzarine","30.7722","-5.5458","Morocco","MA","MAR","Drâa-Tafilalet","","15169","1504777093"
"Dembecha","Dembecha","10.5500","37.4833","Ethiopia","ET","ETH","Āmara","","15008","1231138306"
"Kutchan","Kutchan","42.9017","140.7589","Japan","JP","JPN","Hokkaidō","","15123","1392245778"
"Ianantsony","Ianantsony","-23.5500","43.7667","Madagascar","MG","MDG","Toliara","","15000","1450210749"
"San Pedro Tapanatepec","San Pedro Tapanatepec","16.3667","-94.2000","Mexico","MX","MEX","Oaxaca","","15152","1484844084"
"Mahuwa Singhrai","Mahuwa Singhrai","25.8168","85.3853","India","IN","IND","Bihār","","13622","1356216886"
"Mata Roma","Mata Roma","-3.6250","-43.1108","Brazil","BR","BRA","Maranhão","","15150","1076418048"
"Lānjī","Lanji","21.5018","80.5406","India","IN","IND","Madhya Pradesh","","14923","1356732050"
"Spring Creek","Spring Creek","40.7450","-115.5983","United States","US","USA","Nevada","","15176","1840018704"
"Midar","Midar","34.9500","-3.5333","Morocco","MA","MAR","Oriental","","15021","1504267844"
"Landsberg","Landsberg","51.5333","12.1667","Germany","DE","DEU","Saxony-Anhalt","","15054","1276487466"
"Zozocolco de Hidalgo","Zozocolco de Hidalgo","20.1333","-97.5833","Mexico","MX","MEX","Veracruz","minor","15031","1484949093"
"Meilen","Meilen","47.2667","8.6333","Switzerland","CH","CHE","Zürich","","13999","1756411281"
"Andondabe","Andondabe","-17.7667","49.3847","Madagascar","MG","MDG","Toamasina","","15058","1450987708"
"Ban Mae Sun Luang","Ban Mae Sun Luang","19.8305","99.1706","Thailand","TH","THA","Chiang Mai","","15019","1764535667"
"Kimyogarlar","Kimyogarlar","39.6672","66.8461","Uzbekistan","UZ","UZB","Samarqand","","14937","1860415259"
"Sakabansi","Sakabansi","10.0442","3.3821","Benin","BJ","BEN","Borgou","","15111","1204658968"
"Eloy","Eloy","32.7470","-111.5992","United States","US","USA","Arizona","","15171","1840020628"
"Ambodiriana","Ambodiriana","-19.5833","47.5333","Madagascar","MG","MDG","Antananarivo","","15000","1450970500"
"Gondomar","Gondomar","42.1111","-8.7611","Spain","ES","ESP","Galicia","","14970","1724301277"
"Zacualpan","Zacualpan","18.7197","-99.7800","Mexico","MX","MEX","México","minor","15121","1484694794"
"Pont-à-Mousson","Pont-a-Mousson","48.9044","6.0542","France","FR","FRA","Grand Est","","14497","1250450574"
"Silvino Lobos","Silvino Lobos","12.3281","124.8464","Philippines","PH","PHL","Northern Samar","","15100","1608688340"
"Youngsville","Youngsville","30.0963","-91.9968","United States","US","USA","Louisiana","","15167","1840015931"
"Paramati","Paramati","11.1544","78.0213","India","IN","IND","Tamil Nādu","","14683","1356176307"
"Joghtāy","Joghtay","36.6350","57.0717","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","15134","1364200718"
"Australind","Australind","-33.2800","115.7260","Australia","AU","AUS","Western Australia","","14539","1036182351"
"Sommacampagna","Sommacampagna","45.4000","10.8500","Italy","IT","ITA","Veneto","","14802","1380030808"
"Gaspé","Gaspe","48.8333","-64.4833","Canada","CA","CAN","Quebec","","15163","1124212754"
"Vennesla","Vennesla","58.3106","7.8569","Norway","NO","NOR","Agder","minor","15123","1578283395"
"Busko-Zdrój","Busko-Zdroj","50.4667","20.7167","Poland","PL","POL","Świętokrzyskie","minor","15014","1616795752"
"Andover","Andover","37.6873","-97.1352","United States","US","USA","Kansas","","15162","1840007546"
"Mellila","Mellila","33.3833","-7.1333","Morocco","MA","MAR","Casablanca-Settat","","15081","1504008725"
"Bayserke","Bayserke","43.4797","77.0336","Kazakhstan","KZ","KAZ","","","15124","1398644775"
"Hudiksvall","Hudiksvall","61.7333","17.1167","Sweden","SE","SWE","Gävleborg","minor","15015","1752219385"
"Cossato","Cossato","45.5667","8.1667","Italy","IT","ITA","Piedmont","","14633","1380536408"
"Gardabani","Gardabani","41.4500","45.1000","Georgia","GE","GEO","Kvemo Kartli","minor","15100","1268317674"
"Andoain","Andoain","43.2167","-2.0167","Spain","ES","ESP","Basque Country","","14631","1724830496"
"Patnanungan","Patnanungan","14.7833","122.1833","Philippines","PH","PHL","Quezon","","15052","1608203189"
"Miraí","Mirai","-21.1950","-42.6139","Brazil","BR","BRA","Minas Gerais","","15111","1076394477"
"Ambohimierambe-Andranofito","Ambohimierambe-Andranofito","-19.7833","47.4333","Madagascar","MG","MDG","Antananarivo","","15127","1450911993"
"Elk Plain","Elk Plain","47.0425","-122.3663","United States","US","USA","Washington","","15157","1840037546"
"El Arenal","El Arenal","20.2167","-98.9167","Mexico","MX","MEX","Hidalgo","","15037","1484425146"
"Southern Pines","Southern Pines","35.1927","-79.4039","United States","US","USA","North Carolina","","15156","1840017850"
"Bella Vista","Bella Vista","-27.0333","-65.3000","Argentina","AR","ARG","Tucumán","minor","15126","1032337057"
"Vandalia","Vandalia","39.8791","-84.1930","United States","US","USA","Ohio","","15155","1840003786"
"Bangui","Bangui","18.5378","120.7653","Philippines","PH","PHL","Ilocos Norte","","15019","1608972484"
"Oued Jdida","Oued Jdida","33.9333","-5.3667","Morocco","MA","MAR","Fès-Meknès","","14935","1504052620"
"Chirpan","Chirpan","42.1998","25.3244","Bulgaria","BG","BGR","Stara Zagora","","15112","1100767507"
"Şefaatlı","Sefaatli","39.5017","34.7522","Turkey","TR","TUR","Yozgat","minor","15135","1792923271"
"Kaleyānpur","Kaleyanpur","26.4297","84.9327","India","IN","IND","Bihār","","13704","1356786428"
"Rosarno","Rosarno","38.4850","15.9797","Italy","IT","ITA","Calabria","","14776","1380355749"
"Piney Green","Piney Green","34.7498","-77.3208","United States","US","USA","North Carolina","","15149","1840013558"
"Cirò Marina","Ciro Marina","39.3694","17.1278","Italy","IT","ITA","Calabria","","14794","1380503781"
"Ifatsy","Ifatsy","-22.4000","47.7500","Madagascar","MG","MDG","Fianarantsoa","","15000","1450298054"
"Phillipsburg","Phillipsburg","40.6894","-75.1821","United States","US","USA","New Jersey","","15146","1840000944"
"Amlash","Amlash","37.0975","50.1864","Iran","IR","IRN","Gīlān","minor","15047","1364041120"
"Alangānallūr","Alanganallur","10.0470","78.0903","India","IN","IND","Tamil Nādu","","14622","1356192802"
"Nopala de Villagran","Nopala de Villagran","20.2528","-99.6433","Mexico","MX","MEX","Hidalgo","","15099","1484987808"
"Anororo","Anororo","-17.5167","48.4333","Madagascar","MG","MDG","Toamasina","","14992","1450000237"
"Dorfen","Dorfen","48.2667","12.1500","Germany","DE","DEU","Bavaria","","14992","1276442321"
"Ipauçu","Ipaucu","-23.0569","-49.6267","Brazil","BR","BRA","São Paulo","","15069","1076000796"
"Bela Vista do Paraíso","Bela Vista do Paraiso","-22.9969","-51.1908","Brazil","BR","BRA","Paraná","","15079","1076738885"
"Devarapalle","Devarapalle","17.0347","81.5616","India","IN","IND","Andhra Pradesh","","14796","1356067935"
"Staffanstorp","Staffanstorp","55.6333","13.2000","Sweden","SE","SWE","Skåne","minor","14808","1752021671"
"Gualdo Tadino","Gualdo Tadino","43.2333","12.7833","Italy","IT","ITA","Umbria","","15018","1380252231"
"Tsiatajavona-Ankaratra","Tsiatajavona-Ankaratra","-19.3833","47.3500","Madagascar","MG","MDG","Antananarivo","","15000","1450357089"
"Halwāra","Halwara","30.7167","75.6500","India","IN","IND","Punjab","","14551","1356371341"
"Kaonke","Kaonke","30.7659","75.4172","India","IN","IND","Punjab","","14646","1356051266"
"Bhimphedi","Bhimphedi","27.5510","85.1300","Nepal","NP","NPL","","","15000","1524596923"
"Tonawanda","Tonawanda","43.0105","-78.8805","United States","US","USA","New York","","15132","1840004381"
"Sindhnūr","Sindhnur","15.7700","76.7556","India","IN","IND","Karnātaka","","14906","1356158630"
"Trepuzzi","Trepuzzi","40.4000","18.0667","Italy","IT","ITA","Puglia","","14512","1380618729"
"Camp Pendleton South","Camp Pendleton South","33.2329","-117.3930","United States","US","USA","California","","15131","1840073902"
"Canutama","Canutama","-6.5339","-64.3828","Brazil","BR","BRA","Amazonas","","15130","1076453269"
"Porteiras","Porteiras","-7.5350","-39.1178","Brazil","BR","BRA","Ceará","","15061","1076933484"
"Rāmechhāp","Ramechhap","27.3260","86.0870","Nepal","NP","NPL","","","15000","1524628973"
"Santa","Santa","17.4860","120.4344","Philippines","PH","PHL","Ilocos Sur","","14992","1608245743"
"Tavagnacco","Tavagnacco","46.1333","13.2167","Italy","IT","ITA","Friuli Venezia Giulia","","14981","1380864690"
"Kochkor-Ata","Kochkor-Ata","41.0319","72.4833","Kyrgyzstan","KG","KGZ","Jalal-Abad","","14814","1417211654"
"Ratzeburg","Ratzeburg","53.7000","10.7500","Germany","DE","DEU","Schleswig-Holstein","minor","14652","1276258916"
"Saint-Jacques-de-la-Lande","Saint-Jacques-de-la-Lande","48.0903","-1.6956","France","FR","FRA","Bretagne","","13948","1250001594"
"Burshtyn","Burshtyn","49.2600","24.6350","Ukraine","UA","UKR","Ivano-Frankivska Oblast","","15039","1804941166"
"Tatarikan","Tatarikan","7.7333","124.1167","Philippines","PH","PHL","Lanao del Sur","","15057","1608749088"
"Nadisāl","Nadisal","13.1326","74.7777","India","IN","IND","Karnātaka","","14502","1356233043"
"Râs Baalbek","Ras Baalbek","34.2597","36.4236","Lebanon","LB","LBN","Baalbek-Hermel","","15000","1422859198"
"Barţalah","Bartalah","36.3522","43.3797","Iraq","IQ","IRQ","Nīnawá","","15000","1368230046"
"Clarksdale","Clarksdale","34.1933","-90.5693","United States","US","USA","Mississippi","","15124","1840014696"
"Andranovelona","Andranovelona","-19.6500","47.6333","Madagascar","MG","MDG","Antananarivo","","15000","1450879443"
"La Carolina","La Carolina","38.2667","-3.6167","Spain","ES","ESP","Andalusia","","15048","1724252481"
"Natividade do Carangola","Natividade do Carangola","-21.0419","-41.9728","Brazil","BR","BRA","Rio de Janeiro","","15082","1076436673"
"Ambesisika","Ambesisika","-16.5167","46.8667","Madagascar","MG","MDG","Mahajanga","","15000","1450028481"
"Fortuna","Fortuna","-5.7328","-44.1578","Brazil","BR","BRA","Maranhão","","15098","1076197650"
"Anpachi","Anpachi","35.3353","136.6654","Japan","JP","JPN","Gifu","","14330","1392003423"
"Ban Ho Mae Salong","Ban Ho Mae Salong","20.1631","99.6225","Thailand","TH","THA","Chiang Rai","","15028","1764427138"
"Nannestad","Nannestad","60.2456","10.9528","Norway","NO","NOR","Viken","minor","15074","1578500041"
"Nisko","Nisko","50.5200","22.1394","Poland","PL","POL","Podkarpackie","minor","14874","1616837686"
"Santa Brígida","Santa Brigida","-9.7358","-38.1258","Brazil","BR","BRA","Bahia","","15100","1076986156"
"Guayos","Guayos","22.0497","-79.4622","Cuba","CU","CUB","Sancti Spíritus","","15000","1192879612"
"Sahave","Sahave","-21.0667","47.1167","Madagascar","MG","MDG","Fianarantsoa","","15000","1450964359"
"Antequera","Antequera","9.7812","123.8975","Philippines","PH","PHL","Bohol","","14990","1608570243"
"Whitman","Whitman","42.0800","-70.9399","United States","US","USA","Massachusetts","","15116","1840053580"
"Greenlawn","Greenlawn","40.8632","-73.3646","United States","US","USA","New York","","15115","1840005074"
"Çifteler","Cifteler","39.3831","31.0392","Turkey","TR","TUR","Eskişehir","minor","15098","1792521829"
"Bemidji","Bemidji","47.4828","-94.8797","United States","US","USA","Minnesota","","15114","1840006600"
"Kulunda","Kulunda","52.5667","78.9472","Russia","RU","RUS","Altayskiy Kray","","15102","1643649266"
"Tayum","Tayum","17.6165","120.6553","Philippines","PH","PHL","Abra","","14869","1608000454"
"Mutukula","Mutukula","-1.0000","31.4167","Uganda","UG","UGA","Rakai","","15000","1800910667"
"Lohfelden","Lohfelden","51.2716","9.5487","Germany","DE","DEU","Hesse","","14252","1276890913"
"Burbach","Burbach","50.7444","8.0861","Germany","DE","DEU","North Rhine-Westphalia","","14924","1276749212"
"Yellāreddi","Yellareddi","18.1859","78.0212","India","IN","IND","Andhra Pradesh","","14923","1356002311"
"Pākala","Pakala","15.2694","80.0720","India","IN","IND","Andhra Pradesh","","14467","1356674156"
"Opwijk","Opwijk","50.9667","4.1833","Belgium","BE","BEL","Flanders","","14378","1056667924"
"Hanamsāgar","Hanamsagar","15.8722","76.0431","India","IN","IND","Karnātaka","","14874","1356799012"
"Kyaukmyaung","Kyaukmyaung","22.5833","95.9500","Myanmar","MM","MMR","Sagaing","","15000","1104023096"
"Juruá","Jurua","-3.4808","-66.0689","Brazil","BR","BRA","Amazonas","","15106","1076219164"
"Vohilava","Vohilava","-20.7000","48.3500","Madagascar","MG","MDG","Fianarantsoa","","15000","1450900027"
"Castañuelas","Castanuelas","19.7000","-71.5000","Dominican Republic","DO","DOM","Cibao Noroeste","","14921","1214540220"
"Alarobia Bemaha","Alarobia Bemaha","-20.2000","46.8833","Madagascar","MG","MDG","Antananarivo","","15000","1450979840"
"Teixeira","Teixeira","-7.2228","-37.2539","Brazil","BR","BRA","Paraíba","","14974","1076864397"
"Khallikot","Khallikot","19.6091","85.0861","India","IN","IND","Odisha","","14464","1356087366"
"Sultandağı","Sultandagi","38.5333","31.2333","Turkey","TR","TUR","Afyonkarahisar","minor","15076","1792743781"
"La Flèche","La Fleche","47.6997","-0.0761","France","FR","FRA","Pays de la Loire","minor","14902","1250450362"
"Punata","Punata","-17.5500","-65.8333","Bolivia","BO","BOL","Cochabamba","","14742","1068639665"
"Joubb Jannîne","Joubb Jannine","33.6333","35.7833","Lebanon","LB","LBN","Béqaa","minor","14728","1422617067"
"Tulchyn","Tulchyn","48.6744","28.8497","Ukraine","UA","UKR","Vinnytska Oblast","minor","15051","1804861116"
"Pilisvörösvár","Pilisvorosvar","47.6211","18.9108","Hungary","HU","HUN","Pest","minor","14500","1348911831"
"Kök-Janggak","Kok-Janggak","41.0333","73.2000","Kyrgyzstan","KG","KGZ","Jalal-Abad","","15000","1417546126"
"Jiquiriçá","Jiquirica","-13.2569","-39.5719","Brazil","BR","BRA","Bahia","","15033","1076375229"
"Kenora","Kenora","49.7667","-94.4833","Canada","CA","CAN","Ontario","","15096","1124844807"
"Kodāngipatti","Kodangipatti","9.9920","77.4332","India","IN","IND","Tamil Nādu","","14688","1356242702"
"Rosà","Rosa","45.7167","11.7667","Italy","IT","ITA","Veneto","","14499","1380877179"
"Amirlī","Amirli","34.7250","44.5875","Iraq","IQ","IRQ","Şalāḩ ad Dīn","","15000","1368331180"
"Skipton","Skipton","53.9625","-2.0163","United Kingdom","GB","GBR","North Yorkshire","","14623","1826200772"
"Julianadorp","Julianadorp","52.8833","4.7333","Netherlands","NL","NLD","Noord-Holland","","13925","1528722946"
"Chebrolu","Chebrolu","16.8206","81.4048","India","IN","IND","Andhra Pradesh","","14216","1356037410"
"Tāti","Tati","23.3772","85.4379","India","IN","IND","Jhārkhand","","13874","1356176680"
"Hilchenbach","Hilchenbach","50.9983","8.1094","Germany","DE","DEU","North Rhine-Westphalia","","14906","1276321870"
"Biatorbágy","Biatorbagy","47.4712","18.8252","Hungary","HU","HUN","Pest","","14749","1348104935"
"Özdere","Ozdere","38.0175","27.1283","Turkey","TR","TUR","İzmir","","15000","1792471771"
"Longwood","Longwood","28.7014","-81.3487","United States","US","USA","Florida","","15085","1840015957"
"Triunfo","Triunfo","-7.8378","-38.1019","Brazil","BR","BRA","Pernambuco","","15006","1076950985"
"Henderson","Henderson","36.3256","-78.4151","United States","US","USA","North Carolina","","15084","1840014433"
"Vil’nyans’k","Vil'nyans'k","47.9445","35.4331","Ukraine","UA","UKR","Zaporizka Oblast","","15044","1804823848"
"Mahinog","Mahinog","9.1500","124.7833","Philippines","PH","PHL","Camiguin","","14634","1608465702"
"Nawāda","Nawada","26.0881","86.1530","India","IN","IND","Bihār","","13743","1356291013"
"Ahigbé Koffikro","Ahigbe Koffikro","5.4000","-3.3833","Côte d'Ivoire","CI","CIV","Comoé","","14928","1384105456"
"Ambatoharanana","Ambatoharanana","-17.3000","49.1167","Madagascar","MG","MDG","Toamasina","","15000","1450149595"
"Kyzyl-Suu","Kyzyl-Suu","42.3392","78.0067","Kyrgyzstan","KG","KGZ","Ysyk-Köl","minor","15075","1417060130"
"Horta","Horta","38.5333","-28.6333","Portugal","PT","PRT","Azores","","14994","1620589121"
"Thouars","Thouars","46.9750","-0.2153","France","FR","FRA","Nouvelle-Aquitaine","","13932","1250596037"
"Matungao","Matungao","8.1333","124.1667","Philippines","PH","PHL","Lanao del Norte","","14756","1608497733"
"Roquebrune-sur-Argens","Roquebrune-sur-Argens","43.4433","6.6378","France","FR","FRA","Provence-Alpes-Côte d’Azur","","14937","1250822704"
"Şalkhad","Salkhad","32.4917","36.7111","Syria","SY","SYR","As Suwaydā’","minor","15000","1760200029"
"Ramainandro","Ramainandro","-19.3000","47.0000","Madagascar","MG","MDG","Antananarivo","","15000","1450704256"
"Leninsk","Leninsk","48.7000","45.2167","Russia","RU","RUS","Volgogradskaya Oblast’","","15064","1643120939"
"Alexándreia","Alexandreia","40.6333","22.4500","Greece","GR","GRC","Kentrikí Makedonía","minor","14821","1300981589"
"Lolotique","Lolotique","13.5500","-88.3500","El Salvador","SV","SLV","San Miguel","","14916","1222136023"
"Ambinanindrano","Ambinanindrano","-20.6500","47.8000","Madagascar","MG","MDG","Fianarantsoa","","15000","1450049097"
"Bāladharmāram","Baladharmaram","17.6703","80.1628","India","IN","IND","Andhra Pradesh","","14721","1356744948"
"Petrovaradin","Petrovaradin","45.2500","19.8667","Serbia","RS","SRB","Novi Sad","minor","14810","1688320028"
"Bijai","Bijai","25.4711","87.0874","India","IN","IND","Bihār","","13349","1356213954"
"Zollikon","Zollikon","47.3422","8.5783","Switzerland","CH","CHE","Zürich","","13367","1756438023"
"Aizubange","Aizubange","37.5615","139.8216","Japan","JP","JPN","Fukushima","","14906","1392003207"
"Ylivieska","Ylivieska","64.0750","24.5333","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","15039","1246367983"
"Veliki Preslav","Veliki Preslav","43.1667","26.8170","Bulgaria","BG","BGR","Shumen","","14960","1100285364"
"San Juan","San Juan","10.2667","125.1833","Philippines","PH","PHL","Southern Leyte","","14912","1608987406"
"Chépica","Chepica","-34.7333","-71.2833","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","15037","1152611232"
"Stein bei Nürnberg","Stein bei Nurnberg","49.4167","11.0167","Germany","DE","DEU","Bavaria","","14329","1276407895"
"Milenaka","Milenaka","-22.8333","43.5833","Madagascar","MG","MDG","Toliara","","15000","1450835609"
"Calanogas","Calanogas","7.7500","124.1000","Philippines","PH","PHL","Lanao del Sur","","14985","1608508694"
"Manambondro","Manambondro","-23.8000","47.5500","Madagascar","MG","MDG","Fianarantsoa","","15000","1450557454"
"Lagangilang","Lagangilang","17.6103","120.7344","Philippines","PH","PHL","Abra","","14914","1608928439"
"Sasso Marconi","Sasso Marconi","44.4000","11.2500","Italy","IT","ITA","Emilia-Romagna","","14903","1380881391"
"Taguatinga","Taguatinga","-12.4061","-46.4339","Brazil","BR","BRA","Tocantins","","15051","1076324478"
"Barahari","Barahari","25.7652","87.0937","India","IN","IND","Bihār","","13655","1356644677"
"Uhingen","Uhingen","48.7058","9.5919","Germany","DE","DEU","Baden-Württemberg","","14439","1276901108"
"Manandona","Manandona","-20.0500","47.0667","Madagascar","MG","MDG","Antananarivo","","15000","1450309894"
"Ālampur","Alampur","15.8793","78.1334","India","IN","IND","Andhra Pradesh","","14877","1356839498"
"Perunkolattūr","Perunkolattur","12.0430","78.9174","India","IN","IND","Tamil Nādu","","14749","1356689266"
"Mykolaiv","Mykolaiv","49.5247","23.9789","Ukraine","UA","UKR","Lvivska Oblast","minor","14915","1804613053"
"Marofototra","Marofototra","-20.8667","48.1667","Madagascar","MG","MDG","Fianarantsoa","","15000","1450760830"
"Obando","Obando","4.5833","-75.9167","Colombia","CO","COL","Valle del Cauca","minor","14980","1170669388"
"Bihpuriāgaon","Bihpuriagaon","26.8031","93.5544","India","IN","IND","Assam","","14345","1356100821"
"Kesariyā","Kesariya","26.3650","84.8730","India","IN","IND","Bihār","","13469","1356027855"
"Antanifotsy","Antanifotsy","-16.8667","49.6333","Madagascar","MG","MDG","Toamasina","","15000","1450612710"
"Buggenhout","Buggenhout","51.0000","4.2000","Belgium","BE","BEL","Flanders","","14475","1056466298"
"Puerto Salgar","Puerto Salgar","5.5000","-74.5833","Colombia","CO","COL","Cundinamarca","minor","15019","1170945460"
"Pontinia","Pontinia","41.4083","13.0443","Italy","IT","ITA","Lazio","","14915","1380666503"
"Sorso","Sorso","40.7983","8.5772","Italy","IT","ITA","Sardegna","","14826","1380293910"
"Worthington","Worthington","40.0950","-83.0209","United States","US","USA","Ohio","","15047","1840010522"
"Koungheul","Koungheul","13.9833","-14.8000","Senegal","SN","SEN","Kaffrine","","15000","1686554670"
"Kopoky","Kopoky","-25.2000","45.2000","Madagascar","MG","MDG","Toliara","","15000","1450764380"
"Carmignano","Carmignano","43.8167","11.0167","Italy","IT","ITA","Tuscany","","14663","1380264482"
"Haga","Haga","36.5483","140.0582","Japan","JP","JPN","Tochigi","","14833","1392003489"
"Ankisabe","Ankisabe","-19.2833","46.4833","Madagascar","MG","MDG","Antananarivo","","15000","1450772097"
"Hereford","Hereford","34.8225","-102.4001","United States","US","USA","Texas","","15043","1840020462"
"Swampscott","Swampscott","42.4757","-70.9068","United States","US","USA","Massachusetts","","15043","1840053540"
"Esbiaat","Esbiaat","32.2044","-8.5608","Morocco","MA","MAR","Marrakech-Safi","","14986","1504764211"
"Itaú de Minas","Itau de Minas","-20.7389","-46.7519","Brazil","BR","BRA","Minas Gerais","","14945","1076747219"
"Bevoay","Bevoay","-24.4833","47.0167","Madagascar","MG","MDG","Toliara","","15000","1450951688"
"Imanombo","Imanombo","-24.4333","45.8167","Madagascar","MG","MDG","Toliara","","15000","1450493019"
"Rukhāe","Rukhae","25.3269","85.3725","India","IN","IND","Bihār","","13539","1356989496"
"Front Royal","Front Royal","38.9260","-78.1838","United States","US","USA","Virginia","","15039","1840006045"
"Breukelen","Breukelen","52.1717","5.0017","Netherlands","NL","NLD","Utrecht","","14736","1528284816"
"Lummen","Lummen","50.9833","5.2000","Belgium","BE","BEL","Flanders","","14762","1056359094"
"Villaviciosa","Villaviciosa","43.4833","-5.4333","Spain","ES","ESP","Asturias","","14984","1724121275"
"Mutyālapalle","Mutyalapalle","16.4019","81.5703","India","IN","IND","Andhra Pradesh","","14406","1356287898"
"Ambatomanjaka","Ambatomanjaka","-18.8833","46.8667","Madagascar","MG","MDG","Antananarivo","","15000","1450557644"
"Swansea","Swansea","38.5507","-89.9858","United States","US","USA","Illinois","","15037","1840012880"
"Bajala","Bajala","12.8537","74.8853","India","IN","IND","Karnātaka","","13141","1356317866"
"Bacuag","Bacuag","9.6081","125.6405","Philippines","PH","PHL","Surigao del Norte","","14881","1608421649"
"Dayton","Dayton","39.2592","-119.5653","United States","US","USA","Nevada","","15036","1840018796"
"Qumqo‘rg‘on","Qumqo\`rg\`on","37.8278","67.5972","Uzbekistan","UZ","UZB","Surxondaryo","","14900","1860294369"
"San Mateo del Mar","San Mateo del Mar","16.2000","-94.9833","Mexico","MX","MEX","Oaxaca","","14835","1484954959"
"San Vito","San Vito","8.8400","-82.9784","Costa Rica","CR","CRI","Puntarenas","","14834","1188392681"
"Tiorpāra","Tiorpara","22.2351","88.5540","India","IN","IND","West Bengal","","13350","1356566924"
"Taywarah","Taywarah","33.5100","64.4200","Afghanistan","AF","AFG","Ghōr","minor","15000","1004304837"
"Canteleu","Canteleu","49.4511","1.0361","France","FR","FRA","Normandie","","14244","1250633404"
"Ankilimivory","Ankilimivory","-24.4667","44.9667","Madagascar","MG","MDG","Toliara","","15000","1450659823"
"Beroy Atsimo","Beroy Atsimo","-23.9833","44.6667","Madagascar","MG","MDG","Toliara","","15000","1450415670"
"Markt Schwaben","Markt Schwaben","48.1917","11.8681","Germany","DE","DEU","Bavaria","","13761","1276009835"
"Grão Mogol","Grao Mogol","-16.5589","-42.8900","Brazil","BR","BRA","Minas Gerais","","15024","1076387230"
"Tetāri","Tetari","25.3757","87.0691","India","IN","IND","Bihār","","13126","1356504789"
"Ravels","Ravels","51.3667","5.0000","Belgium","BE","BEL","Flanders","","14871","1056915524"
"Saint-Brevin-les-Pins","Saint-Brevin-les-Pins","47.2464","-2.1669","France","FR","FRA","Pays de la Loire","","14287","1250221865"
"Novyi Buh","Novyi Buh","47.6833","32.5000","Ukraine","UA","UKR","Mykolaivska Oblast","minor","15003","1804139918"
"Quixelô","Quixelo","-6.2539","-39.2019","Brazil","BR","BRA","Ceará","","15000","1076619844"
"Bakel","Bakel","14.9042","-12.4583","Senegal","SN","SEN","Tambacounda","","15000","1686470120"
"Shatrāna","Shatrana","29.9102","76.1202","India","IN","IND","Punjab","","14452","1356070208"
"Analamary","Analamary","-24.2333","47.2167","Madagascar","MG","MDG","Toliara","","15000","1450722054"
"Jisr ez Zarqā","Jisr ez Zarqa","32.5379","34.9122","Israel","IL","ISR","Haifa","","13962","1376963985"
"Clarines","Clarines","9.9433","-65.1661","Venezuela","VE","VEN","Anzoátegui","minor","15000","1862292462"
"Malkhaid","Malkhaid","17.1950","77.1608","India","IN","IND","Karnātaka","","14757","1356531822"
"Anahidrano","Anahidrano","-15.0833","47.9333","Madagascar","MG","MDG","Mahajanga","","15000","1450053738"
"Cherrapunji","Cherrapunji","25.2792","91.7242","India","IN","IND","Meghālaya","","14816","1356603770"
"Penrith","Penrith","-33.7511","150.6942","Australia","AU","AUS","New South Wales","","13295","1036796452"
"Dargeçit","Dargecit","37.5440","41.7200","Turkey","TR","TUR","Mardin","minor","14976","1792947093"
"Neu-Anspach","Neu-Anspach","50.2931","8.5089","Germany","DE","DEU","Hesse","","14618","1276155891"
"Anjoma-Ramartina","Anjoma-Ramartina","-19.6333","45.9667","Madagascar","MG","MDG","Antananarivo","","15000","1450497768"
"Bachhauta","Bachhauta","25.5242","86.4606","India","IN","IND","Bihār","","13321","1356866644"
"Sarmīn","Sarmin","35.9033","36.7258","Syria","SY","SYR","Idlib","minor","14530","1760931266"
"Antanimenabaka","Antanimenabaka","-16.9333","48.4833","Madagascar","MG","MDG","Toamasina","","15000","1450330628"
"Tarlapalli","Tarlapalli","18.8345","79.4907","India","IN","IND","Andhra Pradesh","","14454","1356167301"
"Lapseki","Lapseki","40.3439","26.6836","Turkey","TR","TUR","Çanakkale","minor","14984","1792992667"
"Soalala","Soalala","-16.1000","45.3167","Madagascar","MG","MDG","Mahajanga","","15000","1450631176"
"Turnov","Turnov","50.5873","15.1569","Czechia","CZ","CZE","Liberecký Kraj","","14391","1203116739"
"Douar Souk L‘qolla","Douar Souk L\`qolla","35.0718","-5.5702","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","14965","1504031338"
"Massalubrense","Massalubrense","40.6167","14.3500","Italy","IT","ITA","Campania","","14294","1380941923"
"Qiaoyang","Qiaoyang","35.0716","104.1846","China","CN","CHN","Gansu","","14851","1156800797"
"Quan’ancun","Quan'ancun","25.1345","114.2646","China","CN","CHN","Guangdong","","14898","1156577171"
"Kezi","Kezi","-20.9167","28.4667","Zimbabwe","ZW","ZWE","Matabeleland South","","15000","1716107111"
"Befasy","Befasy","-20.5667","44.3667","Madagascar","MG","MDG","Toliara","","15000","1450031681"
"Riverdale","Riverdale","33.5639","-84.4103","United States","US","USA","Georgia","","15013","1840014815"
"Lālejīn","Lalejin","34.9747","48.4758","Iran","IR","IRN","Hamadān","","14916","1364688538"
"Dalkeith","Dalkeith","55.8958","-3.0583","United Kingdom","GB","GBR","Midlothian","","13400","1826152382"
"Lieusaint","Lieusaint","48.6322","2.5486","France","FR","FRA","Île-de-France","","13853","1250618392"
"Behisatse","Behisatse","-21.8000","45.1167","Madagascar","MG","MDG","Toliara","","15000","1450538114"
"Akora","Akora","26.4721","78.7989","India","IN","IND","Madhya Pradesh","","14566","1356169043"
"Aghbal","Aghbal","34.9394","-2.1272","Morocco","MA","MAR","Oriental","","14908","1504000927"
"Chaguaramas","Chaguaramas","9.3333","-66.2667","Venezuela","VE","VEN","Guárico","minor","15000","1862406127"
"Babhanganwa","Babhanganwa","26.1398","86.8464","India","IN","IND","Bihār","","13618","1356831790"
"Wān Long","Wan Long","22.1667","99.1833","Myanmar","MM","MMR","Shan State","","15000","1104904549"
"Mitane","Mitane","40.1016","140.0050","Japan","JP","JPN","Akita","","14944","1392003191"
"Bikkavolu","Bikkavolu","16.9624","82.0490","India","IN","IND","Andhra Pradesh","","14278","1356624036"
"Lipno","Lipno","52.8500","19.1667","Poland","PL","POL","Kujawsko-Pomorskie","minor","13752","1616442467"
"Villa Ojo de Agua","Villa Ojo de Agua","-29.5167","-63.7000","Argentina","AR","ARG","Santiago del Estero","minor","15000","1032372035"
"Iharan̈a","Iharana","-13.3500","50.0000","Madagascar","MG","MDG","Antsiranana","","15000","1450849551"
"Madanpur","Madanpur","23.0200","88.4800","India","IN","IND","West Bengal","","13675","1356082919"
"Shlisselburg","Shlisselburg","59.9536","31.0383","Russia","RU","RUS","Leningradskaya Oblast’","","14763","1643852770"
"Dimiao","Dimiao","9.6167","124.1667","Philippines","PH","PHL","Bohol","","14889","1608602385"
"Nakagawa","Nakagawa","36.7363","140.1726","Japan","JP","JPN","Tochigi","","14921","1392003508"
"Doukouya","Doukouya","6.4333","-5.5500","Côte d'Ivoire","CI","CIV","Gôh-Djiboua","","14881","1384210901"
"Usingen","Usingen","50.3344","8.5375","Germany","DE","DEU","Hesse","","14733","1276509598"
"Verdal","Verdal","63.7930","11.4800","Norway","NO","NOR","Trøndelag","minor","14986","1578893544"
"Mashpee","Mashpee","41.6178","-70.4908","United States","US","USA","Massachusetts","","14996","1840053502"
"Tabant","Tabant","31.6581","-6.4200","Morocco","MA","MAR","Béni Mellal-Khénifra","","14963","1504160916"
"Otley","Otley","53.9050","-1.6870","United Kingdom","GB","GBR","Leeds","","13668","1826254599"
"Auriflama","Auriflama","-20.6858","-50.5550","Brazil","BR","BRA","São Paulo","","14961","1076210906"
"’Tlat Bni Oukil","'Tlat Bni Oukil","32.5770","-6.8400","Morocco","MA","MAR","Béni Mellal-Khénifra","","14960","1504789319"
"Bni Darkoul","Bni Darkoul","35.0563","-5.0688","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","14779","1504747977"
"Padavēdu","Padavedu","12.6730","79.1047","India","IN","IND","Tamil Nādu","","14241","1356024180"
"Omiš","Omis","43.4333","16.6833","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","14936","1191146852"
"Candói","Candoi","-25.6628","-52.1258","Brazil","BR","BRA","Paraná","","14983","1076520622"
"Plainfield","Plainfield","41.6992","-71.8987","United States","US","USA","Connecticut","","14991","1840045063"
"Linganaboyinacherla","Linganaboyinacherla","16.3961","81.6598","India","IN","IND","Andhra Pradesh","","13869","1356497118"
"Yuchi","Yuchi","23.9000","120.9333","Taiwan","TW","TWN","Nantou","","14863","1158195284"
"Dahé","Dahe","6.5167","1.9500","Benin","BJ","BEN","Mono","","14622","1204007633"
"Iporã","Ipora","-24.0028","-53.7039","Brazil","BR","BRA","Paraná","","14981","1076130156"
"Srīnagar","Srinagar","26.7830","84.3018","India","IN","IND","Bihār","","14454","1356950611"
"Drahichyn","Drahichyn","52.1833","25.1500","Belarus","BY","BLR","Brestskaya Voblasts’","","14963","1112244200"
"Greensburg","Greensburg","40.3113","-79.5445","United States","US","USA","Pennsylvania","","14982","1840001137"
"Coribe","Coribe","-13.8289","-44.4539","Brazil","BR","BRA","Bahia","","14976","1076084010"
"Jinta","Jinta","37.8573","102.5775","China","CN","CHN","Gansu","","14830","1156146091"
"Krasnohorivka","Krasnohorivka","48.0116","37.5041","Ukraine","UA","UKR","Donetska Oblast","","14917","1804827162"
"Woippy","Woippy","49.1511","6.1514","France","FR","FRA","Grand Est","","14014","1250024439"
"Saint-Fargeau","Saint-Fargeau","48.5328","2.5447","France","FR","FRA","Île-de-France","","14121","1250000833"
"Coconuco","Coconuco","2.2500","-76.4167","Colombia","CO","COL","Cauca","minor","14952","1170332695"
"Langenselbold","Langenselbold","50.1833","9.0333","Germany","DE","DEU","Hesse","","14421","1276686360"
"Sidmant al Jabal","Sidmant al Jabal","29.0856","30.9344","Egypt","EG","EGY","Al Fayyūm","","13119","1818324066"
"Sardinal","Sardinal","10.5343","-85.6955","Costa Rica","CR","CRI","Guanacaste","","14912","1188475127"
"Gourrama","Gourrama","32.3333","-4.0833","Morocco","MA","MAR","Drâa-Tafilalet","","14927","1504780592"
"Tiszaújváros","Tiszaujvaros","47.9333","21.0833","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","14649","1348143264"
"Łapy","Lapy","52.9833","22.8833","Poland","PL","POL","Podlaskie","","14790","1616816583"
"Serhetabat","Serhetabat","35.2833","62.3500","Turkmenistan","TM","TKM","Mary","","14940","1795236805"
"Botelhos","Botelhos","-21.6424","-46.3937","Brazil","BR","BRA","Minas Gerais","","14920","1076129493"
"Cumayeri","Cumayeri","40.8736","30.9508","Turkey","TR","TUR","Düzce","minor","14895","1792461502"
"Kothanūru","Kothanuru","16.0022","81.0417","India","IN","IND","Andhra Pradesh","","14747","1356734110"
"Terkuvengānallūr","Terkuvenganallur","9.4052","77.5408","India","IN","IND","Tamil Nādu","","14220","1356204361"
"Vohipeno","Vohipeno","-22.3500","47.8333","Madagascar","MG","MDG","Fianarantsoa","","14751","1450599708"
"Cold Lake","Cold Lake","54.4642","-110.1825","Canada","CA","CAN","Alberta","","14961","1124089461"
"Efkarpía","Efkarpia","40.6833","22.9500","Greece","GR","GRC","Kentrikí Makedonía","","13905","1300091524"
"Assaí","Assai","-23.3728","-50.8408","Brazil","BR","BRA","Paraná","","14954","1076370532"
"Tall Abyaḑ","Tall Abyad","36.6975","38.9567","Syria","SY","SYR","Ar Raqqah","minor","14825","1760694879"
"Kārai","Karai","12.9377","79.3163","India","IN","IND","Tamil Nādu","","14026","1356356577"
"Anzegem","Anzegem","50.8336","3.4783","Belgium","BE","BEL","Flanders","","14609","1056104188"
"Raynham","Raynham","41.9312","-71.0437","United States","US","USA","Massachusetts","","14957","1840053522"
"Detva","Detva","48.5514","19.4183","Slovakia","SK","SVK","Banská Bystrica","minor","14751","1703135182"
"Ālamūru","Alamuru","16.7833","81.9000","India","IN","IND","Andhra Pradesh","","13844","1356170039"
"Joaíma","Joaima","-16.6539","-41.0308","Brazil","BR","BRA","Minas Gerais","","14941","1076626637"
"Tendūkheda","Tendukheda","23.3962","79.5395","India","IN","IND","Madhya Pradesh","","14822","1356099152"
"Kānjikkovil","Kanjikkovil","11.3689","77.5962","India","IN","IND","Tamil Nādu","","14572","1356169226"
"Shepshed","Shepshed","52.7711","-1.2951","United Kingdom","GB","GBR","Leicestershire","","14875","1826737427"
"Williamstown","Williamstown","39.6874","-74.9786","United States","US","USA","New Jersey","","14947","1840016785"
"Fritzlar","Fritzlar","51.1333","9.2833","Germany","DE","DEU","Hesse","","14780","1276290750"
"Tanque Verde","Tanque Verde","32.2687","-110.7437","United States","US","USA","Arizona","","14946","1840019489"
"Sullivan","Sullivan","43.0923","-75.8794","United States","US","USA","New York","","14944","1840058535"
"Pallasovka","Pallasovka","50.0500","46.8833","Russia","RU","RUS","Volgogradskaya Oblast’","","14940","1643361305"
"Heswall","Heswall","53.3280","-3.0990","United Kingdom","GB","GBR","Wirral","","13401","1826689320"
"Saint-Jean-de-Luz","Saint-Jean-de-Luz","43.3903","-1.6597","France","FR","FRA","Nouvelle-Aquitaine","","14198","1250210112"
"Telmar","Telmar","25.4237","85.4358","India","IN","IND","Bihār","","13387","1356088055"
"Nioro","Nioro","15.1833","-9.5500","Mali","ML","MLI","Kayes","minor","14421","1466532076"
"Nobres","Nobres","-14.7200","-56.3278","Brazil","BR","BRA","Mato Grosso","","14938","1076148553"
"Vinanivao","Vinanivao","-15.8833","50.2667","Madagascar","MG","MDG","Antsiranana","","14906","1450469991"
"Człuchów","Czluchow","53.6667","17.3667","Poland","PL","POL","Pomorskie","minor","13869","1616601877"
"Sumidouro","Sumidouro","-22.0500","-42.6750","Brazil","BR","BRA","Rio de Janeiro","","14900","1076176327"
"Presidente Getúlio","Presidente Getulio","-27.0508","-49.6228","Brazil","BR","BRA","Santa Catarina","","14887","1076724781"
"Stolac","Stolac","43.0825","17.9558","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","14889","1070102395"
"Sera","Sera","34.5868","133.0566","Japan","JP","JPN","Hiroshima","","14878","1392003350"
"Echuca","Echuca","-36.1333","144.7500","Australia","AU","AUS","Victoria","","14043","1036292989"
"Rixheim","Rixheim","47.7486","7.4044","France","FR","FRA","Grand Est","","14204","1250445761"
"Magione","Magione","43.1500","12.2000","Italy","IT","ITA","Umbria","","14815","1380265251"
"Nastola","Nastola","60.9500","25.9333","Finland","FI","FIN","Päijät-Häme","minor","14890","1246731228"
"Dhanauri","Dhanauri","29.7833","76.1833","India","IN","IND","Haryāna","","14467","1356453084"
"Sahakevo","Sahakevo","-20.2833","47.8333","Madagascar","MG","MDG","Toamasina","","14885","1450256846"
"Radzymin","Radzymin","52.4167","21.1833","Poland","PL","POL","Mazowieckie","","14314","1616443722"
"Levoča","Levoca","49.0253","20.5881","Slovakia","SK","SVK","Prešov","minor","14803","1703381498"
"Misaki","Misaki","34.3169","135.1421","Japan","JP","JPN","Ōsaka","","14629","1392003214"
"Cabañas","Cabanas","14.9333","-89.8000","Guatemala","GT","GTM","Zacapa","minor","14817","1320540151"
"Mineral Wells","Mineral Wells","32.8169","-98.0776","United States","US","USA","Texas","","14925","1840020689"
"Potsdam","Potsdam","44.6774","-75.0396","United States","US","USA","New York","","14925","1840004061"
"Montataire","Montataire","49.2556","2.4383","France","FR","FRA","Hauts-de-France","","13645","1250858576"
"Nerchinsk","Nerchinsk","51.9944","116.5556","Russia","RU","RUS","Zabaykal’skiy Kray","","14919","1643896987"
"Tarauna","Tarauna","26.2371","87.5123","India","IN","IND","Bihār","","13860","1356425788"
"Cabral","Cabral","18.2500","-71.2167","Dominican Republic","DO","DOM","","","14823","1214056250"
"Glasgow","Glasgow","37.0048","-85.9263","United States","US","USA","Kentucky","","14922","1840013268"
"Shuichecun","Shuichecun","24.0900","116.0174","China","CN","CHN","Guangdong","","14792","1156655066"
"Vinci","Vinci","43.7833","10.9167","Italy","IT","ITA","Tuscany","","14650","1380412084"
"Sterling","Sterling","41.7996","-89.6956","United States","US","USA","Illinois","","14919","1840009200"
"Impruneta","Impruneta","43.6833","11.2500","Italy","IT","ITA","Tuscany","","14618","1380948118"
"Edenburg","Edenburg","-29.7347","25.9369","South Africa","ZA","ZAF","Free State","","14566","1710160465"
"Farnborough","Farnborough","51.3591","0.0741","United Kingdom","GB","GBR","Bromley","","14632","1826247244"
"Woudrichem","Woudrichem","51.8167","5.0000","Netherlands","NL","NLD","Noord-Brabant","minor","14634","1528290983"
"Busto Garolfo","Busto Garolfo","45.5478","8.8867","Italy","IT","ITA","Lombardy","","13851","1380073256"
"Buhuşi","Buhusi","46.7150","26.7042","Romania","RO","ROU","Bacău","","14562","1642879325"
"Mineral del Monte","Mineral del Monte","20.1333","-98.6667","Mexico","MX","MEX","Hidalgo","minor","14640","1484360018"
"California City","California City","35.1578","-117.8721","United States","US","USA","California","","14914","1840019149"
"Hima","Hima","0.2906","30.1775","Uganda","UG","UGA","Kasese","","14700","1800860546"
"Mukāsi Pidāriyūr","Mukasi Pidariyur","11.2069","77.5922","India","IN","IND","Tamil Nādu","","14535","1356164685"
"Meerane","Meerane","50.8519","12.4636","Germany","DE","DEU","Saxony","","14208","1276923420"
"Lons","Lons","43.3150","-0.4108","France","FR","FRA","Nouvelle-Aquitaine","","13720","1250818066"
"Bad Wurzach","Bad Wurzach","47.9094","9.8994","Germany","DE","DEU","Baden-Württemberg","","14827","1276243830"
"Amersham","Amersham","51.6770","-0.6030","United Kingdom","GB","GBR","Buckinghamshire","","14384","1826557635"
"Uppunda","Uppunda","13.8333","74.6167","India","IN","IND","Karnātaka","","14616","1356058339"
"Lumino","Lumino","0.3250","33.9958","Uganda","UG","UGA","Busia","","14624","1800145886"
"Areado","Areado","-21.3589","-46.1458","Brazil","BR","BRA","Minas Gerais","","14851","1076925556"
"Sanquelim","Sanquelim","15.5027","73.7669","India","IN","IND","Goa","","14776","1356841313"
"Malsch","Malsch","48.8808","8.3342","Germany","DE","DEU","Baden-Württemberg","","14616","1276981496"
"Kondrovo","Kondrovo","54.8000","35.9333","Russia","RU","RUS","Kaluzhskaya Oblast’","","14857","1643237880"
"Kharki","Kharki","23.9165","85.8596","India","IN","IND","Jhārkhand","","14423","1356199312"
"Kizel","Kizel","59.0500","57.6667","Russia","RU","RUS","Permskiy Kray","","14883","1643267536"
"San Julian","San Julian","11.7536","125.4558","Philippines","PH","PHL","Eastern Samar","","14800","1608494835"
"Mascote","Mascote","-15.5628","-39.3028","Brazil","BR","BRA","Bahia","","14877","1076045822"
"Jever","Jever","53.5744","7.9008","Germany","DE","DEU","Lower Saxony","minor","14550","1276105413"
"Poděbrady","Podebrady","50.1425","15.1189","Czechia","CZ","CZE","Středočeský Kraj","","14469","1203251007"
"Holalagondi","Holalagondi","15.4873","77.0464","India","IN","IND","Andhra Pradesh","","14593","1356510446"
"Mions","Mions","45.6631","4.9531","France","FR","FRA","Auvergne-Rhône-Alpes","","13707","1250164695"
"Mori","Mori","42.1050","140.5764","Japan","JP","JPN","Hokkaidō","","14851","1392003424"
"Holliston","Holliston","42.1977","-71.4450","United States","US","USA","Massachusetts","","14891","1840053479"
"Mondolfo","Mondolfo","43.7500","13.1000","Italy","IT","ITA","Marche","","14265","1380664340"
"Vrbovec","Vrbovec","45.8833","16.4333","Croatia","HR","HRV","Zagrebačka Županija","minor","14797","1191484288"
"Bom Jesus do Galho","Bom Jesus do Galho","-19.8289","-42.3158","Brazil","BR","BRA","Minas Gerais","","14862","1076678788"
"Arona","Arona","45.7569","8.5600","Italy","IT","ITA","Piedmont","","13966","1380422638"
"Dumariā","Dumaria","26.7652","84.3964","India","IN","IND","Bihār","","13317","1356718086"
"Penrith","Penrith","54.6648","-2.7548","United Kingdom","GB","GBR","Cumbria","","14756","1826839302"
"Enniskillen","Enniskillen","54.3447","-7.6389","United Kingdom","GB","GBR","Fermanagh and Omagh","","13823","1826724326"
"Bhalil","Bhalil","33.8500","-4.8667","Morocco","MA","MAR","Fès-Meknès","","12997","1504827842"
"Nyzhnia Krynka","Nyzhnia Krynka","48.1144","38.1606","Ukraine","UA","UKR","Donetska Oblast","","13927","1804441450"
"Metuchen","Metuchen","40.5424","-74.3628","United States","US","USA","New Jersey","","14883","1840003633"
"Collecchio","Collecchio","44.7500","10.2167","Italy","IT","ITA","Emilia-Romagna","","14634","1380939433"
"Lebanon","Lebanon","37.6717","-92.6603","United States","US","USA","Missouri","","14880","1840008711"
"Dharphari","Dharphari","26.1501","84.9564","India","IN","IND","Bihār","","13234","1356009147"
"Ban San Phak Wan Luang","Ban San Phak Wan Luang","18.7049","98.9661","Thailand","TH","THA","Chiang Mai","","14557","1764106797"
"Wąbrzeźno","Wabrzezno","53.2833","18.9500","Poland","PL","POL","Kujawsko-Pomorskie","minor","13317","1616122226"
"Hendersonville","Hendersonville","35.3247","-82.4575","United States","US","USA","North Carolina","","14878","1840014561"
"Nagongera","Nagongera","0.7700","34.0261","Uganda","UG","UGA","Tororo","","14300","1800803785"
"Ayní","Ayni","39.3975","68.5406","Tajikistan","TJ","TJK","Sughd","minor","14862","1762304788"
"Stony Point","Stony Point","41.2593","-74.0112","United States","US","USA","New York","","14876","1840058529"
"Narasingapuram","Narasingapuram","12.9728","79.2840","India","IN","IND","Tamil Nādu","","13943","1356947489"
"Vuhledar","Vuhledar","47.7794","37.2475","Ukraine","UA","UKR","Donetska Oblast","","14822","1804709738"
"Gafanha da Nazaré","Gafanha da Nazare","40.6350","-8.7140","Portugal","PT","PRT","Aveiro","","14021","1620364496"
"Balassagyarmat","Balassagyarmat","48.0786","19.2942","Hungary","HU","HUN","Nógrád","minor","14262","1348612236"
"Château-d’Olonne","Chateau-d'Olonne","46.5042","-1.7372","France","FR","FRA","Pays de la Loire","","14411","1250339100"
"Batuan","Batuan","12.4222","123.7817","Philippines","PH","PHL","Masbate","","14610","1608145736"
"Manavālakurichi","Manavalakurichi","8.1478","77.3055","India","IN","IND","Tamil Nādu","","13743","1356078697"
"Madānpur","Madanpur","25.8670","86.7875","India","IN","IND","Bihār","","13775","1356089111"
"Boulder City","Boulder City","35.8407","-114.9257","United States","US","USA","Nevada","","14868","1840019028"
"Fujisaki","Fujisaki","40.6561","140.5028","Japan","JP","JPN","Aomori","","14478","1392919322"
"Balhāpur","Balhapur","25.3511","86.2122","India","IN","IND","Bihār","","13626","1356966762"
"Oftringen","Oftringen","47.3167","7.9167","Switzerland","CH","CHE","Aargau","","13791","1756569554"
"Great Bend","Great Bend","38.3593","-98.8015","United States","US","USA","Kansas","","14864","1840001656"
"Audincourt","Audincourt","47.4828","6.8397","France","FR","FRA","Bourgogne-Franche-Comté","","13341","1250114423"
"Kannamanāyakkanūr","Kannamanayakkanur","10.5527","77.2690","India","IN","IND","Tamil Nādu","","14526","1356171156"
"Thuin","Thuin","50.3333","4.2833","Belgium","BE","BEL","Wallonia","minor","14671","1056107614"
"Nzalat Laadam","Nzalat Laadam","32.1000","-7.9500","Morocco","MA","MAR","Marrakech-Safi","","14838","1504318940"
"Inhuma","Inhuma","-6.6678","-41.7078","Brazil","BR","BRA","Piauí","","14845","1076479788"
"Branchburg","Branchburg","40.5629","-74.7140","United States","US","USA","New Jersey","","14859","1840081705"
"Ternitz","Ternitz","47.7167","16.0333","Austria","AT","AUT","Niederösterreich","","14632","1040196639"
"Chegdomyn","Chegdomyn","51.1178","133.0241","Russia","RU","RUS","Khabarovskiy Kray","","14855","1643328181"
"Cambuci","Cambuci","-21.5750","-41.9108","Brazil","BR","BRA","Rio de Janeiro","","14827","1076525044"
"Pattensen","Pattensen","52.2667","9.7667","Germany","DE","DEU","Lower Saxony","","14636","1276603378"
"Ekwāri","Ekwari","25.2833","84.5833","India","IN","IND","Bihār","","13075","1356073073"
"Bom Lugar","Bom Lugar","-4.2200","-45.0389","Brazil","BR","BRA","Maranhão","","14818","1076364137"
"Pouso Redondo","Pouso Redondo","-27.2578","-49.9339","Brazil","BR","BRA","Santa Catarina","","14810","1076522780"
"Ferrier","Ferrier","19.6167","-71.7833","Haiti","HT","HTI","Nord-Est","","14642","1332033044"
"Dionísio Cerqueira","Dionisio Cerqueira","-26.2550","-53.6400","Brazil","BR","BRA","Santa Catarina","","14811","1076396640"
"Rotenburg an der Fulda","Rotenburg an der Fulda","50.9961","9.7278","Germany","DE","DEU","Hesse","","14676","1276092129"
"Lucao","Lucao","23.4133","120.3092","Taiwan","TW","TWN","Chiayi","","14579","1158141608"
"Gaundrā","Gaundra","26.3683","85.0036","India","IN","IND","Bihār","","13403","1356075658"
"Cehegín","Cehegin","38.0925","-1.7989","Spain","ES","ESP","Murcia","","14798","1724606574"
"Navashino","Navashino","55.5500","42.2000","Russia","RU","RUS","Nizhegorodskaya Oblast’","","14830","1643012933"
"Gabane","Gabane","-24.6667","25.7833","Botswana","BW","BWA","Kweneng","","14842","1072883950"
"Cuevas del Almanzora","Cuevas del Almanzora","37.3000","-1.8667","Spain","ES","ESP","Andalusia","","14790","1724152622"
"Santo Domingo Xenacoj","Santo Domingo Xenacoj","14.6822","-90.7066","Guatemala","GT","GTM","Sacatepéquez","minor","14455","1320621620"
"Monterrey","Monterrey","4.8783","-72.8972","Colombia","CO","COL","Casanare","minor","14828","1170450558"
"Amasra","Amasra","41.7494","32.3864","Turkey","TR","TUR","Bartın","minor","14776","1792275434"
"Grândola","Grandola","38.1700","-8.5600","Portugal","PT","PRT","Setúbal","minor","14826","1620454238"
"Belison","Belison","10.8381","121.9606","Philippines","PH","PHL","Antique","","14129","1608259543"
"Sprimont","Sprimont","50.5000","5.6667","Belgium","BE","BEL","Wallonia","","14645","1056261345"
"Grimes","Grimes","41.6779","-93.7947","United States","US","USA","Iowa","","14842","1840007068"
"Mercaderes","Mercaderes","1.8000","-77.1667","Colombia","CO","COL","Cauca","minor","14824","1170092647"
"Spenge","Spenge","52.1333","8.4833","Germany","DE","DEU","North Rhine-Westphalia","","14487","1276387127"
"Kontiolahti","Kontiolahti","62.7667","29.8500","Finland","FI","FIN","Pohjois-Karjala","minor","14827","1246150764"
"Romsey","Romsey","50.9890","-1.4966","United Kingdom","GB","GBR","Hampshire","","14768","1826793384"
"Betsukai","Betsukai","43.3938","145.1175","Japan","JP","JPN","Hokkaidō","","14828","1392367898"
"Huasuo","Huasuo","35.4043","107.0869","China","CN","CHN","Gansu","","14610","1156020651"
"Brwinów","Brwinow","52.1417","20.7167","Poland","PL","POL","Mazowieckie","","14448","1616620268"
"Fiadanana","Fiadanana","-18.2167","46.8833","Madagascar","MG","MDG","Antananarivo","","14784","1450707459"
"Darihat","Darihat","24.9702","84.2319","India","IN","IND","Bihār","","13730","1356640693"
"Ighrem n’Ougdal","Ighrem n'Ougdal","31.2333","-7.4333","Morocco","MA","MAR","Drâa-Tafilalet","","14804","1504078159"
"Bairia","Bairia","25.5563","85.1835","India","IN","IND","Bihār","","12897","1356779350"
"Brasil Novo","Brasil Novo","-3.2619","-52.6678","Brazil","BR","BRA","Pará","","14834","1076611672"
"Broadlands","Broadlands","39.0168","-77.5167","United States","US","USA","Virginia","","14835","1840024498"
"Bissendorf","Bissendorf","52.2333","8.1667","Germany","DE","DEU","Lower Saxony","","14680","1276073314"
"Anomabu","Anomabu","5.1667","-1.1167","Ghana","GH","GHA","Central","","14389","1288745249"
"Rājpur","Rajpur","25.0768","84.2055","India","IN","IND","Bihār","","13334","1356396028"
"Patrocínio Paulista","Patrocinio Paulista","-20.6394","-47.2817","Brazil","BR","BRA","São Paulo","","14807","1076390439"
"Wombourn","Wombourn","52.5302","-2.1857","United Kingdom","GB","GBR","Staffordshire","","14157","1826116234"
"Fortim","Fortim","-4.4500","-37.7833","Brazil","BR","BRA","Ceará","","14817","1076247527"
"Bishops Cleeve","Bishops Cleeve","51.9470","-2.0610","United Kingdom","GB","GBR","Gloucestershire","","14068","1826876835"
"Warwick","Warwick","40.2503","-75.0818","United States","US","USA","Pennsylvania","","14830","1840150057"
"Slochteren","Slochteren","53.2167","6.8000","Netherlands","NL","NLD","Groningen","minor","14737","1528066163"
"Summerside","Summerside","46.4000","-63.7833","Canada","CA","CAN","Prince Edward Island","","14829","1124487102"
"Katyr-Yurt","Katyr-Yurt","43.1700","45.3711","Russia","RU","RUS","Chechnya","","14720","1643031996"
"Nevel","Nevel","56.0333","29.9167","Russia","RU","RUS","Pskovskaya Oblast’","","14819","1643498267"
"Markacho","Markacho","24.3263","85.8346","India","IN","IND","Jhārkhand","","14467","1356939966"
"Comox","Comox","49.6733","-124.9022","Canada","CA","CAN","British Columbia","","14828","1124788300"
"Odlābāri","Odlabari","26.8364","88.6294","India","IN","IND","West Bengal","","14194","1356894248"
"Chikhli Kalān","Chikhli Kalan","22.2152","78.7430","India","IN","IND","Madhya Pradesh","","14322","1356092059"
"Purísima de la Concepción","Purisima de la Concepcion","9.2333","-75.7333","Colombia","CO","COL","Córdoba","minor","14705","1170780807"
"Karabanovo","Karabanovo","56.3167","38.7000","Russia","RU","RUS","Vladimirskaya Oblast’","","14786","1643955513"
"Plérin","Plerin","48.5344","-2.7708","France","FR","FRA","Bretagne","","14309","1250209994"
"Montelupo Fiorentino","Montelupo Fiorentino","43.7333","11.0167","Italy","IT","ITA","Tuscany","","14247","1380095746"
"Sinmpérékou","Sinmperekou","11.2333","2.4167","Benin","BJ","BEN","Alibori","","14748","1204770736"
"Las Guáranas","Las Guaranas","19.2000","-70.2200","Dominican Republic","DO","DOM","Cibao Nordeste","","14661","1214326778"
"Kottavalasa","Kottavalasa","17.9000","83.2000","India","IN","IND","Andhra Pradesh","","14321","1356779786"
"Amay","Amay","50.5478","5.3192","Belgium","BE","BEL","Wallonia","","14305","1056317273"
"Highland Springs","Highland Springs","37.5516","-77.3285","United States","US","USA","Virginia","","14823","1840006391"
"Surajpura","Surajpura","25.2576","86.2283","India","IN","IND","Bihār","","14502","1356446616"
"Maglie","Maglie","40.1167","18.1333","Italy","IT","ITA","Puglia","","14196","1380267095"
"Sierra Vista Southeast","Sierra Vista Southeast","31.4525","-110.2160","United States","US","USA","Arizona","","14822","1840074401"
"Ampohibe","Ampohibe","-15.0333","50.3000","Madagascar","MG","MDG","Antsiranana","","14433","1450141886"
"Ankadinandriana","Ankadinandriana","-19.0667","47.6667","Madagascar","MG","MDG","Antananarivo","","14684","1450861528"
"Nova Timboteua","Nova Timboteua","-1.2058","-47.3858","Brazil","BR","BRA","Pará","","14791","1076421795"
"Kalasa","Kalasa","13.2340","75.3553","India","IN","IND","Karnātaka","","14710","1356141567"
"Montabaur","Montabaur","50.4375","7.8258","Germany","DE","DEU","Rhineland-Palatinate","minor","14391","1276982904"
"Cândido de Abreu","Candido de Abreu","-24.5669","-51.3328","Brazil","BR","BRA","Paraná","","14809","1076875901"
"Baiersbronn","Baiersbronn","48.5058","8.3711","Germany","DE","DEU","Baden-Württemberg","","14741","1276633374"
"Cassano delle Murge","Cassano delle Murge","40.8833","16.7667","Italy","IT","ITA","Puglia","","14765","1380045660"
"Murgod","Murgod","15.7800","75.1200","India","IN","IND","Karnātaka","","14563","1356881678"
"Sylvan Lake","Sylvan Lake","52.3083","-114.0964","Canada","CA","CAN","Alberta","","14816","1124397940"
"Gundlapelle","Gundlapelle","16.4102","79.8813","India","IN","IND","Andhra Pradesh","","14546","1356048740"
"Schwabmünchen","Schwabmunchen","48.1789","10.7550","Germany","DE","DEU","Bavaria","","14553","1276593802"
"Sozopol","Sozopol","42.4167","27.7000","Bulgaria","BG","BGR","Burgas","minor","14789","1100393207"
"Cocorná","Cocorna","6.0569","-75.1853","Colombia","CO","COL","Antioquia","minor","14743","1170897315"
"Ostwald","Ostwald","48.5511","7.7136","France","FR","FRA","Grand Est","","12985","1250942766"
"Alginet","Alginet","39.2625","-0.4683","Spain","ES","ESP","Valencia","","14219","1724793089"
"Hemsworth","Hemsworth","53.6100","-1.3500","United Kingdom","GB","GBR","Wakefield","","13533","1826714452"
"Ivanić-Grad","Ivanic-Grad","45.7081","16.3947","Croatia","HR","HRV","Zagrebačka Županija","minor","14723","1191909415"
"Sanjiang Nongchang","Sanjiang Nongchang","19.8798","110.6011","China","CN","CHN","Hainan","","14147","1156367879"
"Gryazovets","Gryazovets","58.8833","40.2500","Russia","RU","RUS","Vologodskaya Oblast’","","14800","1643659896"
"Sumbal","Sumbal","34.2307","74.6472","India","IN","IND","Jammu and Kashmīr","","14064","1356059271"
"Morpeth","Morpeth","55.1675","-1.6908","United Kingdom","GB","GBR","Northumberland","","14017","1826000022"
"Hirono","Hirono","40.4085","141.7187","Japan","JP","JPN","Iwate","","14757","1392003416"
"Alindao","Alindao","5.0333","21.2167","Central African Republic","CF","CAF","Basse-Kotto","","14401","1140620640"
"Bayeux","Bayeux","49.2786","-0.7039","France","FR","FRA","Normandie","minor","13017","1250138472"
"Andorinha","Andorinha","-10.3450","-39.8328","Brazil","BR","BRA","Bahia","","14791","1076869918"
"Muthallath al Azraq","Muthallath al Azraq","31.8342","36.8150","Jordan","JO","JOR","Az Zarqā’","minor","14800","1400807685"
"Tabuse","Tabuse","33.9547","132.0414","Japan","JP","JPN","Yamaguchi","","14515","1392416444"
"Tornesch","Tornesch","53.7000","9.7167","Germany","DE","DEU","Schleswig-Holstein","","14118","1276106363"
"Buritirama","Buritirama","-5.5928","-47.0178","Brazil","BR","BRA","Maranhão","","14784","1076000008"
"Presidente Dutra","Presidente Dutra","-11.2958","-41.9869","Brazil","BR","BRA","Bahia","","14712","1076548546"
"Ramsey","Ramsey","41.0595","-74.1454","United States","US","USA","New Jersey","","14801","1840000896"
"Tepetzintla","Tepetzintla","21.1452","-97.8558","Mexico","MX","MEX","Veracruz","minor","14736","1484385901"
"Oldsmar","Oldsmar","28.0506","-82.6698","United States","US","USA","Florida","","14800","1840015111"
"Obanazawa","Obanazawa","38.6000","140.4000","Japan","JP","JPN","Yamagata","","14760","1392411470"
"Herselt","Herselt","51.0500","4.8833","Belgium","BE","BEL","Flanders","","14521","1056803578"
"Castel San Giorgio","Castel San Giorgio","40.7833","14.7000","Italy","IT","ITA","Campania","","13784","1380000068"
"Bradley Gardens","Bradley Gardens","40.5711","-74.6678","United States","US","USA","New Jersey","","14798","1840033232"
"Kelamangalam","Kelamangalam","12.6031","77.8519","India","IN","IND","Tamil Nādu","","14588","1356944454"
"Roncade","Roncade","45.6246","12.3766","Italy","IT","ITA","Veneto","","14561","1380417225"
"Lampa","Lampa","-15.3636","-70.3656","Peru","PE","PER","Puno","","14780","1604742918"
"Saint Joseph","Saint Joseph","10.6556","-61.4167","Trinidad and Tobago","TT","TTO","Tunapuna/Piarco","","14376","1780256893"
"Schiffdorf","Schiffdorf","53.5355","8.6622","Germany","DE","DEU","Lower Saxony","","14665","1276001395"
"Burbage","Burbage","52.5277","-1.3483","United Kingdom","GB","GBR","Leicestershire","","14568","1826305898"
"Garuva","Garuva","-26.0269","-48.8550","Brazil","BR","BRA","Santa Catarina","","14761","1076424163"
"Nāgireddipalli","Nagireddipalli","14.2701","79.1013","India","IN","IND","Andhra Pradesh","","14534","1356595191"
"Villa San Giovanni","Villa San Giovanni","38.2167","15.6333","Italy","IT","ITA","Calabria","","13667","1380269595"
"Gantt","Gantt","34.7837","-82.4027","United States","US","USA","South Carolina","","14790","1840013492"
"Shimubi","Shimubi","19.1645","110.3043","China","CN","CHN","Hainan","","14702","1156654799"
"Washington","Washington","39.7494","-77.5579","United States","US","USA","Pennsylvania","","14788","1840146370"
"Mānjri","Manjri","16.4200","74.5800","India","IN","IND","Karnātaka","","14254","1356081803"
"Briniamaro","Briniamaro","10.7411","2.0731","Benin","BJ","BEN","Atacora","","14751","1204210282"
"Glen Allen","Glen Allen","37.6660","-77.4838","United States","US","USA","Virginia","","14783","1840006390"
"Morwell","Morwell","-38.2333","146.4000","Australia","AU","AUS","Victoria","","13771","1036750619"
"Aït Yaïch","Ait Yaich","36.5811","4.3303","Algeria","DZ","DZA","Tizi Ouzou","","14439","1012677951"
"Piano di Sorrento","Piano di Sorrento","40.6333","14.4111","Italy","IT","ITA","Campania","","13008","1380275318"
"Krasnogvardeyskoye","Krasnogvardeyskoye","45.8500","41.5167","Russia","RU","RUS","Stavropol’skiy Kray","","14761","1643102176"
"Ambérieu-en-Bugey","Amberieu-en-Bugey","45.9581","5.3578","France","FR","FRA","Auvergne-Rhône-Alpes","","14204","1250767338"
"Ferndale","Ferndale","48.8526","-122.5894","United States","US","USA","Washington","","14777","1840019751"
"Sirmaur","Sirmaur","24.8365","81.3645","India","IN","IND","Madhya Pradesh","","14435","1356596778"
"Berettyóújfalu","Berettyoujfalu","47.2167","21.5500","Hungary","HU","HUN","Hajdú-Bihar","minor","14690","1348328817"
"Onklou","Onklou","9.5000","1.9833","Benin","BJ","BEN","Donga","","14693","1204898832"
"Brunswick","Brunswick","31.1449","-81.4746","United States","US","USA","Georgia","","14774","1840013875"
"Hooksett","Hooksett","43.0709","-71.4365","United States","US","USA","New Hampshire","","14772","1840055022"
"Kārkūdalpatti","Karkudalpatti","11.5002","78.3521","India","IN","IND","Tamil Nādu","","14316","1356253579"
"Harhorin","Harhorin","47.1972","102.8239","Mongolia","MN","MNG","Övörhangay","minor","14765","1496223301"
"Kushimoto","Kushimoto","33.4667","135.7833","Japan","JP","JPN","Wakayama","","14661","1392703742"
"Riofrío","Riofrio","4.1561","-76.2878","Colombia","CO","COL","Valle del Cauca","minor","14716","1170785545"
"Cabarete","Cabarete","19.7511","-70.4083","Dominican Republic","DO","DOM","Cibao Norte","","14600","1214941065"
"Béré","Bere","9.3156","16.1597","Chad","TD","TCD","Tandjilé","","14666","1148449680"
"Nishinoomote","Nishinoomote","30.7322","130.9967","Japan","JP","JPN","Kagoshima","","14694","1392981409"
"Tiltil","Tiltil","-33.0817","-70.9278","Chile","CL","CHL","Región Metropolitana","","14755","1152063956"
"Alexander City","Alexander City","32.9229","-85.9358","United States","US","USA","Alabama","","14765","1840013788"
"Muri","Muri","46.9333","7.4833","Switzerland","CH","CHE","Bern","","13054","1756349869"
"Rokycany","Rokycany","49.7428","13.5946","Czechia","CZ","CZE","Plzeňský Kraj","","14313","1203614655"
"Much","Much","50.9167","7.4000","Germany","DE","DEU","North Rhine-Westphalia","","14577","1276464394"
"Essau","Essau","13.4833","-16.5333","The Gambia","GM","GMB","North Bank","","14560","1270359208"
"Sanderstead","Sanderstead","51.3358","-0.0818","United Kingdom","GB","GBR","Croydon","","12777","1826382700"
"Polāia Kalān","Polaia Kalan","23.2119","76.5529","India","IN","IND","Madhya Pradesh","","14322","1356741312"
"Tsivilsk","Tsivilsk","55.8667","47.4833","Russia","RU","RUS","Chuvashiya","","14718","1643574991"
"Ekenäs","Ekenas","59.9750","23.4333","Finland","FI","FIN","Uusimaa","","14754","1246589382"
"Muy Muy","Muy Muy","12.7625","-85.6289","Nicaragua","NI","NIC","Matagalpa","minor","14721","1558387669"
"Bo’ness","Bo'ness","56.0168","-3.6089","United Kingdom","GB","GBR","Falkirk","","14760","1826331223"
"Neqāb","Neqab","36.7081","57.4214","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","14723","1364003209"
"Narragansett","Narragansett","41.4291","-71.4668","United States","US","USA","Rhode Island","","14759","1840106247"
"Maqat","Maqat","47.6500","53.3167","Kazakhstan","KZ","KAZ","","minor","14756","1398887034"
"Danilov","Danilov","58.1833","40.1667","Russia","RU","RUS","Yaroslavskaya Oblast’","","14746","1643198619"
"San Ramón","San Ramon","-11.1247","-75.3569","Peru","PE","PER","Junín","","14708","1604957373"
"Pilar","Pilar","-31.6833","-63.9000","Argentina","AR","ARG","Córdoba","","14735","1032492017"
"Sukhinichi","Sukhinichi","54.1000","35.3500","Russia","RU","RUS","Kaluzhskaya Oblast’","","14736","1643650021"
"Vargem da Roça","Vargem da Roca","-11.6069","-40.1369","Brazil","BR","BRA","Bahia","","14729","1076859944"
"Roncq","Roncq","50.7536","3.1203","France","FR","FRA","Hauts-de-France","","13482","1250773025"
"Monteroni di Lecce","Monteroni di Lecce","40.3333","18.1000","Italy","IT","ITA","Puglia","","13923","1380872538"
"Piraziz","Piraziz","40.9333","38.1333","Turkey","TR","TUR","Giresun","","14659","1792709498"
"Badhoevedorp","Badhoevedorp","52.3333","4.7833","Netherlands","NL","NLD","Noord-Holland","","13290","1528100185"
"Çiçekdağı","Cicekdagi","39.6069","34.4086","Turkey","TR","TUR","Kırşehir","minor","14735","1792539032"
"Törökbálint","Torokbalint","47.4367","18.9122","Hungary","HU","HUN","Pest","","14264","1348719921"
"Teignmouth","Teignmouth","50.5515","-3.4886","United Kingdom","GB","GBR","Devon","","14749","1826052083"
"Trinitapoli","Trinitapoli","41.3500","16.1000","Italy","IT","ITA","Puglia","","14649","1380094002"
"Tumbippādi","Tumbippadi","11.8080","78.0783","India","IN","IND","Tamil Nādu","","13883","1356246787"
"Wildwood","Wildwood","28.7752","-82.0075","United States","US","USA","Florida","","14747","1840015956"
"Nampicuan","Nampicuan","15.7342","120.6356","Philippines","PH","PHL","Nueva Ecija","","14471","1608623397"
"Oulad Driss","Oulad Driss","31.9536","-8.2053","Morocco","MA","MAR","Marrakech-Safi","","14716","1504026756"
"Penuganchiprolu","Penuganchiprolu","16.9167","80.2500","India","IN","IND","Andhra Pradesh","","14374","1356065391"
"Larena","Larena","9.2490","123.5910","Philippines","PH","PHL","Siquijor","","14454","1608113335"
"Orbetello","Orbetello","42.4394","11.2125","Italy","IT","ITA","Tuscany","","14744","1380129001"
"Lope de Vega","Lope de Vega","12.2983","124.6238","Philippines","PH","PHL","Northern Samar","","14690","1608016163"
"Hanover","Hanover","42.1224","-70.8566","United States","US","USA","Massachusetts","","14742","1840053568"
"Maravilla Tenejapa","Maravilla Tenejapa","16.2167","-91.2500","Mexico","MX","MEX","Chiapas","minor","14714","1484598932"
"Soledade","Soledade","-7.0569","-36.3628","Brazil","BR","BRA","Paraíba","","14714","1076149519"
"Ząbkowice Śląskie","Zabkowice Slaskie","50.5833","16.8167","Poland","PL","POL","Dolnośląskie","minor","14728","1616718625"
"Pandhāna","Pandhana","21.6982","76.2249","India","IN","IND","Madhya Pradesh","","14522","1356002397"
"Wilsdruff","Wilsdruff","51.0522","13.5383","Germany","DE","DEU","Saxony","","14559","1276738335"
"Xincun","Xincun","27.6739","103.8739","China","CN","CHN","Yunnan","minor","14614","1156709141"
"Río Negro","Rio Negro","-40.7833","-73.2000","Chile","CL","CHL","Los Lagos","","14732","1152537804"
"Hedehusene","Hedehusene","55.6547","12.1953","Denmark","DK","DNK","Hovedstaden","","13663","1208482999"
"Água Clara","Agua Clara","-20.4500","-52.8667","Brazil","BR","BRA","Mato Grosso do Sul","","14734","1076035194"
"Androrangavola","Androrangavola","-21.5000","47.7167","Madagascar","MG","MDG","Fianarantsoa","","14709","1450623784"
"Greendale","Greendale","42.9371","-88.0018","United States","US","USA","Wisconsin","","14735","1840003038"
"Pānsemāl","Pansemal","21.6598","74.6994","India","IN","IND","Madhya Pradesh","","14182","1356110842"
"Măgurele","Magurele","44.3494","26.0297","Romania","RO","ROU","Ilfov","","14414","1642420938"
"Angical","Angical","-12.0069","-44.6939","Brazil","BR","BRA","Bahia","","14724","1076374273"
"Uch-Korgon","Uch-Korgon","40.2300","72.0600","Kyrgyzstan","KG","KGZ","Batken","minor","14708","1417468277"
"Karpi","Karpi","25.1612","84.7299","India","IN","IND","Bihār","","13607","1356770910"
"Barhauna","Barhauna","25.6043","85.7288","India","IN","IND","Bihār","","12827","1356324310"
"Uzun","Uzun","38.3667","68.0056","Uzbekistan","UZ","UZB","Surxondaryo","","14600","1860948605"
"Arzgir","Arzgir","45.3694","44.2281","Russia","RU","RUS","Stavropol’skiy Kray","","14722","1643130198"
"Wymondham","Wymondham","52.5700","1.1160","United Kingdom","GB","GBR","Norfolk","","14405","1826379714"
"Deming","Deming","32.2631","-107.7525","United States","US","USA","New Mexico","","14730","1840019458"
"Fort Payne","Fort Payne","34.4559","-85.6965","United States","US","USA","Alabama","","14730","1840004822"
"Suwāsra","Suwasra","24.0698","75.6523","India","IN","IND","Madhya Pradesh","","14505","1356720999"
"Negreşti-Oaş","Negresti-Oas","47.8694","23.4242","Romania","RO","ROU","Satu Mare","","14616","1642109083"
"Ashiya","Ashiya","33.8938","130.6639","Japan","JP","JPN","Fukuoka","","13559","1392633502"
"Haacht","Haacht","50.9767","4.6372","Belgium","BE","BEL","Flanders","","14470","1056601741"
"Chandauli","Chandauli","25.8972","85.7161","India","IN","IND","Bihār","","13007","1356346615"
"Askim","Askim","59.5861","11.1700","Norway","NO","NOR","Viken","minor","14488","1578155881"
"Solim","Solim","15.6200","73.7670","India","IN","IND","Goa","","13619","1356515505"
"Cowes","Cowes","50.7595","-1.3002","United Kingdom","GB","GBR","Isle of Wight","","14724","1826109660"
"Newtown","Newtown","39.9920","-75.4106","United States","US","USA","Pennsylvania","","14724","1840152845"
"Villa Alegre","Villa Alegre","-35.6667","-71.7500","Chile","CL","CHL","Maule","","14695","1152729852"
"Sikat","Sikat","25.5186","87.4423","India","IN","IND","Bihār","","13491","1356993910"
"Chifubu","Chifubu","-12.9333","28.6167","Zambia","ZM","ZMB","Copperbelt","","13307","1894511606"
"Eraurā","Eraura","24.6130","84.4555","India","IN","IND","Bihār","","14101","1356012077"
"Bhānukumāri","Bhanukumari","26.3395","89.7697","India","IN","IND","West Bengal","","14340","1356371010"
"Kottacheruvu","Kottacheruvu","14.1886","77.7658","India","IN","IND","Andhra Pradesh","","14564","1356316170"
"Wschowa","Wschowa","51.8000","16.3167","Poland","PL","POL","Lubuskie","minor","14607","1616415919"
"Lingāl","Lingal","16.2833","78.5167","India","IN","IND","Andhra Pradesh","","14497","1356015110"
"Ochakiv","Ochakiv","46.6186","31.5392","Ukraine","UA","UKR","Mykolaivska Oblast","minor","14705","1804376560"
"Dohta","Dohta","26.2864","88.2564","India","IN","IND","Bihār","","13323","1356946301"
"Zemamra","Zemamra","32.6215","-8.7023","Morocco","MA","MAR","Casablanca-Settat","","13279","1504256384"
"Barhi","Barhi","23.9033","80.8152","India","IN","IND","Madhya Pradesh","","14418","1356453912"
"Travagliato","Travagliato","45.5240","10.0797","Italy","IT","ITA","Lombardy","","13930","1380526991"
"Vail","Vail","32.0217","-110.6937","United States","US","USA","Arizona","","14715","1840019487"
"Villasanta","Villasanta","45.6053","9.3033","Italy","IT","ITA","Lombardy","","13972","1380959254"
"Kerepestarcsa","Kerepestarcsa","47.5478","19.2634","Hungary","HU","HUN","Pest","","13490","1348751669"
"Ālangudi","Alangudi","10.3604","78.9779","India","IN","IND","Tamil Nādu","","14204","1356214603"
"Massaranduba","Massaranduba","-26.6108","-49.0078","Brazil","BR","BRA","Santa Catarina","","14674","1076435799"
"Gulf Shores","Gulf Shores","30.2764","-87.7017","United States","US","USA","Alabama","","14712","1840013886"
"Ottweiler","Ottweiler","49.3667","7.1667","Germany","DE","DEU","Saarland","","14395","1276806597"
"Ladson","Ladson","33.0093","-80.1078","United States","US","USA","South Carolina","","14711","1840014254"
"White Oak","White Oak","39.0451","-76.9885","United States","US","USA","Maryland","","14711","1840005847"
"Suō-Ōshima","Suo-Oshima","33.9276","132.1952","Japan","JP","JPN","Yamaguchi","","14605","1392001396"
"Troon","Troon","55.5400","-4.6600","United Kingdom","GB","GBR","South Ayrshire","","14710","1826943206"
"Chelsea","Chelsea","33.3262","-86.6300","United States","US","USA","Alabama","","14710","1840014823"
"Owosso","Owosso","42.9955","-84.1760","United States","US","USA","Michigan","","14708","1840003068"
"Ngoulemakong","Ngoulemakong","3.0833","11.4333","Cameroon","CM","CMR","Sud","","14675","1120215656"
"Bombinhas","Bombinhas","-27.1378","-48.5169","Brazil","BR","BRA","Santa Catarina","","14293","1076894216"
"Guilford","Guilford","39.8811","-77.6012","United States","US","USA","Pennsylvania","","14706","1840073686"
"Carmen de Areco","Carmen de Areco","-34.3858","-59.8294","Argentina","AR","ARG","Buenos Aires","minor","14692","1032088260"
"Lubbeek","Lubbeek","50.8817","4.8414","Belgium","BE","BEL","Flanders","","14393","1056043837"
"Antioch","Antioch","42.4742","-88.0721","United States","US","USA","Illinois","","14705","1840010115"
"Mittweida","Mittweida","50.9856","12.9811","Germany","DE","DEU","Saxony","","14351","1276378266"
"Rehlingen-Siersburg","Rehlingen-Siersburg","49.3686","6.6786","Germany","DE","DEU","Saarland","","14469","1276372658"
"Fraser","Fraser","42.5388","-82.9496","United States","US","USA","Michigan","","14703","1840002421"
"Candiba","Candiba","-14.4108","-42.8669","Brazil","BR","BRA","Bahia","","14667","1076540136"
"Shintō","Shinto","36.4384","138.9671","Japan","JP","JPN","Gunma","","14193","1392001283"
"Ardestān","Ardestan","33.3761","52.3694","Iran","IR","IRN","Eşfahān","minor","14698","1364684540"
"Mosina","Mosina","52.2467","16.8450","Poland","PL","POL","Wielkopolskie","","14511","1616028362"
"Versoix","Versoix","46.2833","6.1667","Switzerland","CH","CHE","Genève","","13422","1756526224"
"Berck-sur-Mer","Berck-sur-Mer","50.4083","1.5928","France","FR","FRA","Hauts-de-France","","13791","1250250433"
"Ranomafana","Ranomafana","-18.9583","48.8417","Madagascar","MG","MDG","Toamasina","","14672","1450951573"
"Barka Parbatta","Barka Parbatta","25.3396","87.0576","India","IN","IND","Bihār","","13365","1356452934"
"Hulyaypole","Hulyaypole","47.6644","36.2632","Ukraine","UA","UKR","Zaporizka Oblast","","14678","1804759589"
"Pataili","Pataili","25.7872","85.8316","India","IN","IND","Bihār","","13324","1356933920"
"Lauda-Königshofen","Lauda-Konigshofen","49.5686","9.7039","Germany","DE","DEU","Baden-Württemberg","","14542","1276018769"
"Hammonton","Hammonton","39.6572","-74.7678","United States","US","USA","New Jersey","","14695","1840003800"
"Zhutang","Zhutang","23.8528","120.4114","Taiwan","TW","TWN","Changhua","","14353","1158953082"
"Claxton Bay","Claxton Bay","10.3405","-61.4555","Trinidad and Tobago","TT","TTO","Couva/Tabaquite/Talparo","","14436","1780203587"
"Paniem","Paniem","15.5167","78.3500","India","IN","IND","Andhra Pradesh","","14562","1356154152"
"Korangal","Korangal","17.1070","77.6270","India","IN","IND","Andhra Pradesh","","14294","1356182344"
"Great Falls","Great Falls","39.0110","-77.3013","United States","US","USA","Virginia","","14690","1840006028"
"Mmopone","Mmopone","-24.5669","25.8733","Botswana","BW","BWA","Kweneng","","14655","1072944302"
"Isny im Allgäu","Isny im Allgau","47.6919","10.0394","Germany","DE","DEU","Baden-Württemberg","","14518","1276817029"
"Zontecomatlán de López y Fuentes","Zontecomatlan de Lopez y Fuentes","20.7667","-98.3333","Mexico","MX","MEX","Veracruz","minor","14620","1484226245"
"Port Lincoln","Port Lincoln","-34.7322","135.8586","Australia","AU","AUS","South Australia","","14120","1036558150"
"Baie du Tombeau","Baie du Tombeau","-20.1138","57.5066","Mauritius","MU","MUS","Pamplemousses","","14687","1480279659"
"Sisa","Sisa","-6.6142","-76.6936","Peru","PE","PER","San Martín","","14639","1604313737"
"Mahatsinjo","Mahatsinjo","-17.7333","47.0333","Madagascar","MG","MDG","Mahajanga","","14675","1450180817"
"Esquipulas Palo Gordo","Esquipulas Palo Gordo","14.9333","-91.8167","Guatemala","GT","GTM","San Marcos","minor","14018","1320136046"
"Gland","Gland","46.4200","6.2700","Switzerland","CH","CHE","Vaud","","13106","1756505867"
"Ożarów Mazowiecki","Ozarow Mazowiecki","52.2167","20.8000","Poland","PL","POL","Mazowieckie","minor","14375","1616007056"
"Ban Du","Ban Du","19.9691","99.8589","Thailand","TH","THA","Chiang Rai","","14542","1764009256"
"Quinapundan","Quinapundan","11.1578","125.5208","Philippines","PH","PHL","Eastern Samar","","14507","1608910750"
"Mangamila","Mangamila","-18.5667","47.8583","Madagascar","MG","MDG","Antananarivo","","14640","1450330746"
"Cafelândia","Cafelandia","-24.6178","-53.3200","Brazil","BR","BRA","Paraná","","14662","1076309402"
"Waunakee","Waunakee","43.1829","-89.4447","United States","US","USA","Wisconsin","","14679","1840002904"
"Mascali","Mascali","37.7500","15.2000","Italy","IT","ITA","Sicilia","","14301","1380299124"
"Bollullos par del Condado","Bollullos par del Condado","37.3358","-6.5364","Spain","ES","ESP","Andalusia","","14392","1724227724"
"Condoto","Condoto","5.1000","-76.6500","Colombia","CO","COL","Chocó","minor","14660","1170624933"
"Udaipur Bithwār","Udaipur Bithwar","26.2872","86.1578","India","IN","IND","Bihār","","13077","1356840356"
"Chikitigarh","Chikitigarh","19.2023","84.6145","India","IN","IND","Odisha","","14258","1356475209"
"Monroe","Monroe","33.7990","-83.7161","United States","US","USA","Georgia","","14675","1840014786"
"Fruitville","Fruitville","27.3328","-82.4616","United States","US","USA","Florida","","14673","1840014179"
"Ilampillai","Ilampillai","11.6066","78.0068","India","IN","IND","Tamil Nādu","","14011","1356041811"
"Canyon","Canyon","34.9911","-101.9190","United States","US","USA","Texas","","14669","1840019180"
"Bischofsheim","Bischofsheim","49.9890","8.3550","Germany","DE","DEU","Hesse","","13230","1276670052"
"El Paisnal","El Paisnal","13.9667","-89.2167","El Salvador","SV","SLV","San Salvador","","14551","1222184186"
"Latiano","Latiano","40.5333","17.7167","Italy","IT","ITA","Puglia","","14403","1380589775"
"Dennis","Dennis","41.7064","-70.1644","United States","US","USA","Massachusetts","","14664","1840053500"
"Sepīdān","Sepidan","30.2625","51.9833","Iran","IR","IRN","Fārs","minor","14633","1364046715"
"Bartabwa","Bartabwa","0.8300","35.8000","Kenya","KE","KEN","Baringo","","14611","1404047345"
"Gouré","Goure","13.9874","10.2700","Niger","NE","NER","Zinder","minor","14639","1562482102"
"Bahçesaray","Bahcesaray","38.1286","42.8075","Turkey","TR","TUR","Van","minor","14622","1792864088"
"Boston","Boston","7.8697","126.3761","Philippines","PH","PHL","Davao Oriental","","14618","1608800917"
"Kulharia","Kulharia","25.3382","86.7612","India","IN","IND","Bihār","","13449","1356216013"
"Bicas","Bicas","-21.7250","-43.0589","Brazil","BR","BRA","Minas Gerais","","14554","1076204929"
"Guajiquiro","Guajiquiro","14.1000","-87.8333","Honduras","HN","HND","La Paz","","14616","1340570749"
"Chandhaus","Chandhaus","25.2977","84.8595","India","IN","IND","Bihār","","13077","1356304256"
"Cicciano","Cicciano","40.9667","14.5333","Italy","IT","ITA","Campania","","12897","1380669369"
"Lopik","Lopik","51.9667","4.9500","Netherlands","NL","NLD","Utrecht","minor","14473","1528959358"
"Barja","Barja","33.6497","35.4433","Lebanon","LB","LBN","Mont-Liban","","12888","1422958830"
"Bijaipur","Bijaipur","26.0556","77.3697","India","IN","IND","Madhya Pradesh","","14555","1356820272"
"Harsefeld","Harsefeld","53.4500","9.5000","Germany","DE","DEU","Lower Saxony","","14378","1276886588"
"Maria","Maria","9.1960","123.6550","Philippines","PH","PHL","Siquijor","","14385","1608861500"
"Forster","Forster","-32.1806","152.5117","Australia","AU","AUS","New South Wales","","13740","1036685795"
"Palmview","Palmview","26.2318","-98.3823","United States","US","USA","Texas","","14654","1840021028"
"Astravyets","Astravyets","54.6136","25.9553","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","minor","14641","1112665123"
"Brad","Brad","46.1294","22.7900","Romania","RO","ROU","Hunedoara","","14495","1642122253"
"Lakhsetipet","Lakhsetipet","18.8873","79.2069","India","IN","IND","Andhra Pradesh","","14332","1356094959"
"Pineville","Pineville","31.3414","-92.4096","United States","US","USA","Louisiana","","14653","1840014963"
"Dornakal","Dornakal","17.4447","80.1492","India","IN","IND","Andhra Pradesh","","14425","1356805121"
"Ichikawamisato","Ichikawamisato","35.5652","138.5024","Japan","JP","JPN","Yamanashi","","14458","1392003491"
"Neustadt an der Donau","Neustadt an der Donau","48.8000","11.7667","Germany","DE","DEU","Bavaria","","14495","1276348770"
"Münsingen","Munsingen","48.4128","9.4953","Germany","DE","DEU","Baden-Württemberg","","14524","1276411655"
"Arugollu","Arugollu","16.8253","81.5775","India","IN","IND","Andhra Pradesh","","13767","1356482140"
"Kuurne","Kuurne","50.8519","3.2861","Belgium","BE","BEL","Flanders","","13318","1056459162"
"Słupca","Slupca","52.3000","17.8667","Poland","PL","POL","Wielkopolskie","minor","13353","1616372877"
"Kātrāvulapalle","Katravulapalle","17.1277","82.0831","India","IN","IND","Andhra Pradesh","","14164","1356074807"
"Ban Kaeng","Ban Kaeng","16.4119","102.0402","Thailand","TH","THA","Chaiyaphum","","14529","1764204808"
"Sailāna","Sailana","23.4622","74.9232","India","IN","IND","Madhya Pradesh","","14372","1356046522"
"Cómbita","Combita","5.7500","-73.2500","Colombia","CO","COL","Boyacá","minor","14632","1170000033"
"Davidson","Davidson","35.4840","-80.8247","United States","US","USA","North Carolina","","14644","1840016353"
"San Sebastián","San Sebastian","13.7333","-88.8333","El Salvador","SV","SLV","San Vicente","","14411","1222133372"
"Sukhāsan","Sukhasan","25.9644","86.7732","India","IN","IND","Bihār","","13477","1356099157"
"Jurema","Jurema","-8.7178","-36.1358","Brazil","BR","BRA","Pernambuco","","14541","1076726309"
"Kalývia Thorikoú","Kalyvia Thorikou","37.8333","23.9167","Greece","GR","GRC","Attikí","minor","14424","1300423873"
"Aleksandrovka","Aleksandrovka","42.8528","74.2194","Kyrgyzstan","KG","KGZ","Chüy","","14577","1417705377"
"Anapoima","Anapoima","4.5503","-74.5361","Colombia","CO","COL","Cundinamarca","minor","14519","1170450555"
"Ayt ’Attou ou L’Arbi","Ayt 'Attou ou L'Arbi","31.5456","-8.2967","Morocco","MA","MAR","Marrakech-Safi","","14544","1504796196"
"Radomyshl","Radomyshl","50.4947","29.2333","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","14607","1804242306"
"Rahika","Rahika","26.3803","86.0204","India","IN","IND","Bihār","","12844","1356130805"
"Salīmpur","Salimpur","25.2480","86.2347","India","IN","IND","Bihār","","14314","1356008658"
"Plewiska","Plewiska","52.3664","16.8089","Poland","PL","POL","Wielkopolskie","","14160","1616010188"
"Alzano Lombardo","Alzano Lombardo","45.7317","9.7286","Italy","IT","ITA","Lombardy","","13637","1380900977"
"Eberbach","Eberbach","49.4667","8.9833","Germany","DE","DEU","Baden-Württemberg","","14460","1276456342"
"Markdorf","Markdorf","47.7208","9.3917","Germany","DE","DEU","Baden-Württemberg","","14284","1276086137"
"Melmangalam","Melmangalam","10.1000","77.5833","India","IN","IND","Tamil Nādu","","14025","1356228725"
"Tavares","Tavares","-7.6358","-37.8778","Brazil","BR","BRA","Paraíba","","14568","1076181148"
"Lapua","Lapua","62.9700","23.0083","Finland","FI","FIN","Etelä-Pohjanmaa","minor","14609","1246396654"
"Salem Lakes","Salem Lakes","42.5366","-88.1306","United States","US","USA","Wisconsin","","14630","1840038106"
"Copalchí","Copalchi","9.8473","-84.0834","Costa Rica","CR","CRI","Cartago","","13676","1188649164"
"Klášterec nad Ohří","Klasterec nad Ohri","50.3846","13.1714","Czechia","CZ","CZE","Ústecký Kraj","","14365","1203315809"
"Tonya","Tonya","40.8856","39.2922","Turkey","TR","TUR","Trabzon","minor","14592","1792136537"
"Kiri","Kiri","-1.4955","18.9277","Congo (Kinshasa)","CD","COD","Mai-Ndombe","","14612","1180133436"
"Barjora","Barjora","23.4333","87.2833","India","IN","IND","West Bengal","","14012","1356294396"
"Mohania","Mohania","25.1692","83.6192","India","IN","IND","Bihār","","13601","1356472952"
"Náfplio","Nafplio","37.5667","22.8000","Greece","GR","GRC","Pelopónnisos","minor","14203","1300744950"
"Rønne","Ronne","55.0986","14.7014","Denmark","DK","DNK","Hovedstaden","minor","13807","1208734482"
"Sainte-Maxime","Sainte-Maxime","43.3089","6.6378","France","FR","FRA","Provence-Alpes-Côte d’Azur","","14448","1250686983"
"Havre de Grace","Havre de Grace","39.5480","-76.1146","United States","US","USA","Maryland","","14625","1840005667"
"Vedasandūr","Vedasandur","10.5310","77.9502","India","IN","IND","Tamil Nādu","","14273","1356044131"
"Godella","Godella","39.5200","-0.4114","Spain","ES","ESP","Valencia","","13050","1724346773"
"Urbino","Urbino","43.7252","12.6372","Italy","IT","ITA","Marche","","14558","1380375007"
"Moultrie","Moultrie","31.1591","-83.7708","United States","US","USA","Georgia","","14621","1840014977"
"Sokone","Sokone","13.8833","-16.3667","Senegal","SN","SEN","Fatick","","14500","1686985910"
"Noya","Noya","42.7833","-8.8833","Spain","ES","ESP","Galicia","","14240","1724537566"
"Baoshan","Baoshan","24.7428","120.9991","Taiwan","TW","TWN","Hsinchu","","14398","1158298805"
"Hershey","Hershey","40.2806","-76.6458","United States","US","USA","Pennsylvania","","14619","1840005400"
"Cruzília","Cruzilia","-21.8389","-44.8078","Brazil","BR","BRA","Minas Gerais","","14591","1076132930"
"Jaitpur","Jaitpur","25.9132","84.5730","India","IN","IND","Bihār","","12946","1356034927"
"Priverno","Priverno","41.4667","13.1833","Italy","IT","ITA","Lazio","","14365","1380371078"
"Candeias","Candeias","-20.7669","-45.2758","Brazil","BR","BRA","Minas Gerais","","14595","1076831135"
"Bo","Bo","20.6736","105.5311","Vietnam","VN","VNM","Hòa Bình","minor","14401","1704407394"
"Langwedel","Langwedel","52.9999","9.1732","Germany","DE","DEU","Lower Saxony","","14425","1276601336"
"Mount Vernon","Mount Vernon","38.3140","-88.9174","United States","US","USA","Illinois","","14614","1840008654"
"Zhabinka","Zhabinka","52.2006","24.0233","Belarus","BY","BLR","Brestskaya Voblasts’","minor","14577","1112259690"
"Gua","Gua","22.2136","85.3877","India","IN","IND","","","14323","1356599469"
"Olsberg","Olsberg","51.3500","8.4833","Germany","DE","DEU","North Rhine-Westphalia","","14489","1276829513"
"San Francisco","San Francisco","15.6667","-87.0333","Honduras","HN","HND","Atlántida","","14559","1340403835"
"Majia","Majia","35.4599","103.2076","China","CN","CHN","Gansu","","14391","1156704044"
"Dargahān","Dargahan","26.9636","56.0622","Iran","IR","IRN","Hormozgān","","14525","1364735795"
"Villa Yapacaní","Villa Yapacani","-17.4028","-63.8850","Bolivia","BO","BOL","Santa Cruz","","14589","1068932083"
"Satwās","Satwas","22.5363","76.6845","India","IN","IND","Madhya Pradesh","","14428","1356509023"
"Cadaval","Cadaval","39.2333","-9.1000","Portugal","PT","PRT","Lisboa","minor","14525","1620009427"
"Washington","Washington","38.5515","-91.0154","United States","US","USA","Missouri","","14607","1840010770"
"Oschatz","Oschatz","51.3003","13.1072","Germany","DE","DEU","Saxony","","14349","1276313153"
"Pualas","Pualas","7.8167","124.0667","Philippines","PH","PHL","Lanao del Sur","","14526","1608420799"
"Grünstadt","Grunstadt","49.5692","8.1681","Germany","DE","DEU","Rhineland-Palatinate","","13840","1276261667"
"Ayomi","Ayomi","6.7833","1.7167","Benin","BJ","BEN","Couffo","","14191","1204017290"
"Río Branco","Rio Branco","-32.5972","-53.3833","Uruguay","UY","URY","Cerro Largo","","14604","1858289927"
"Ungutūru","Unguturu","16.8230","81.4238","India","IN","IND","Andhra Pradesh","","14280","1356443968"
"Tapejara","Tapejara","-23.7328","-52.8728","Brazil","BR","BRA","Paraná","","14598","1076885443"
"Boninal","Boninal","-12.7019","-41.8278","Brazil","BR","BRA","Bahia","","14585","1076205755"
"Morris","Morris","41.3749","-88.4304","United States","US","USA","Illinois","","14601","1840008243"
"Wilbraham","Wilbraham","42.1270","-72.4308","United States","US","USA","Massachusetts","","14600","1840053629"
"Yanhewan","Yanhewan","36.7523","109.3677","China","CN","CHN","Shaanxi","","14499","1156889552"
"Sumbha","Sumbha","25.5789","86.3608","India","IN","IND","Bihār","","13246","1356013417"
"Darłowo","Darlowo","54.4208","16.4097","Poland","PL","POL","Zachodniopomorskie","","13943","1616699844"
"Marofinaritra","Marofinaritra","-15.0333","50.1167","Madagascar","MG","MDG","Antsiranana","","14546","1450912826"
"Ōki","Oki","33.2104","130.4398","Japan","JP","JPN","Fukuoka","","13847","1392003386"
"Anderson Creek","Anderson Creek","35.2657","-78.9580","United States","US","USA","North Carolina","","14597","1840089354"
"Rendon","Rendon","32.5789","-97.2349","United States","US","USA","Texas","","14597","1840019421"
"Kottaiyūr","Kottaiyur","10.1096","78.7956","India","IN","IND","Tamil Nādu","","14035","1356073395"
"Boa Nova","Boa Nova","-14.3628","-40.2078","Brazil","BR","BRA","Bahia","","14577","1076117568"
"Weigelstown","Weigelstown","39.9852","-76.8306","United States","US","USA","Pennsylvania","","14593","1840005509"
"Qazyqurt","Qazyqurt","41.7598","69.3880","Kazakhstan","KZ","KAZ","","","14563","1398181231"
"Ústí nad Orlicí","Usti nad Orlici","49.9739","16.3937","Czechia","CZ","CZE","Pardubický Kraj","","14209","1203823971"
"Apuiarés","Apuiares","-3.9489","-39.4319","Brazil","BR","BRA","Ceará","","14565","1076006852"
"Ludvika","Ludvika","60.1333","15.1833","Sweden","SE","SWE","Dalarna","minor","14498","1752311892"
"Boula’wane","Boula'wane","32.8607","-8.0530","Morocco","MA","MAR","Casablanca-Settat","","14485","1504484491"
"Ganguvārpatti","Ganguvarpatti","10.1694","77.6973","India","IN","IND","Tamil Nādu","","13981","1356226910"
"Qobustan","Qobustan","40.0842","49.4158","Azerbaijan","AZ","AZE","Bakı","","14470","1031778275"
"Chelsfield","Chelsfield","51.3582","0.1278","United Kingdom","GB","GBR","Bromley","","14507","1826005730"
"Hasami","Hasami","33.1379","129.8955","Japan","JP","JPN","Nagasaki","","14332","1392003252"
"Santa Branca","Santa Branca","-23.3969","-45.8839","Brazil","BR","BRA","São Paulo","","14534","1076129261"
"Mūrak","Murak","35.3753","36.6872","Syria","SY","SYR","Ḩamāh","","14307","1760107905"
"Bakun","Bakun","16.7925","120.6614","Philippines","PH","PHL","Benguet","","14535","1608668315"
"Tostado","Tostado","-29.2333","-61.7667","Argentina","AR","ARG","Santa Fe","minor","14582","1032032722"
"Domkonda","Domkonda","18.2536","78.4378","India","IN","IND","Andhra Pradesh","","13648","1356677299"
"Kārempūdi","Karempudi","16.4333","79.7167","India","IN","IND","Andhra Pradesh","","14385","1356058281"
"Breza","Breza","44.0167","18.2611","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","14564","1070229008"
"Killarney","Killarney","52.0588","-9.5072","Ireland","IE","IRL","Kerry","","14504","1372916792"
"Raghunāthpur","Raghunathpur","25.5615","84.3050","India","IN","IND","Bihār","","13271","1356365003"
"Kambainellūr","Kambainellur","12.2071","78.3187","India","IN","IND","Tamil Nādu","","14299","1356561953"
"Chippewa Falls","Chippewa Falls","44.9358","-91.3902","United States","US","USA","Wisconsin","","14581","1840002108"
"Mocha","Mocha","13.3203","43.2500","Yemen","YE","YEM","Ta‘izz","minor","14562","1887487308"
"Paina","Paina","25.5406","86.9939","India","IN","IND","Bihār","","13060","1356441040"
"Hatten","Hatten","53.0083","8.3167","Germany","DE","DEU","Lower Saxony","","14440","1276627272"
"Chaugāin","Chaugain","25.4801","84.2328","India","IN","IND","Bihār","","13959","1356616107"
"Eggertsville","Eggertsville","42.9665","-78.8065","United States","US","USA","New York","","14577","1840024061"
"Paraíso","Paraiso","18.0000","-71.1667","Dominican Republic","DO","DOM","Enriquillo","","14472","1214707712"
"Douarnenez","Douarnenez","48.0922","-4.3303","France","FR","FRA","Bretagne","","14015","1250598362"
"Miho","Miho","36.0045","140.3019","Japan","JP","JPN","Ibaraki","","14355","1392003233"
"Tlayacapan","Tlayacapan","18.9556","-98.9811","Mexico","MX","MEX","Morelos","minor","14467","1484386110"
"Perdūr","Perdur","13.3844","74.9029","India","IN","IND","Karnātaka","","13945","1356604629"
"Stanytsia Luhanska","Stanytsia Luhanska","48.6449","39.4958","Ukraine","UA","UKR","Luhanska Oblast","","14543","1804822467"
"Boa Esperança do Sul","Boa Esperanca do Sul","-21.9925","-48.3908","Brazil","BR","BRA","São Paulo","","14546","1076269444"
"Leno","Leno","45.3703","10.2167","Italy","IT","ITA","Lombardy","","14322","1380781038"
"East Milton","East Milton","30.6175","-86.9636","United States","US","USA","Florida","","14566","1840025112"
"West Freehold","West Freehold","40.2324","-74.2943","United States","US","USA","New Jersey","","14565","1840005454"
"Dumas","Dumas","35.8613","-101.9642","United States","US","USA","Texas","","14565","1840019086"
"Ouled Sidi Brahim","Ouled Sidi Brahim","36.3667","3.7167","Algeria","DZ","DZA","Bouira","","14499","1012821401"
"Mount Barker","Mount Barker","-35.0667","138.8500","Australia","AU","AUS","South Australia","","13842","1036530325"
"Bezou","Bezou","32.0833","-7.0500","Morocco","MA","MAR","Béni Mellal-Khénifra","","14072","1504408285"
"Ollioules","Ollioules","43.1394","5.8469","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13866","1250044981"
"Hanover","Hanover","40.8197","-74.4287","United States","US","USA","New Jersey","","14563","1840081717"
"Bagulin","Bagulin","16.6079","120.4378","Philippines","PH","PHL","La Union","","14428","1608422405"
"Iakora","Iakora","-23.1000","46.6500","Madagascar","MG","MDG","Fianarantsoa","","14547","1450296994"
"Tijucas do Sul","Tijucas do Sul","-25.9278","-49.1989","Brazil","BR","BRA","Paraná","","14537","1076858124"
"Pucioasa","Pucioasa","45.0742","25.4342","Romania","RO","ROU","Dâmboviţa","","14254","1642731261"
"Altınekin","Altinekin","38.3078","32.8686","Turkey","TR","TUR","Konya","minor","14548","1792784602"
"Goonellabah","Goonellabah","-28.8167","153.3167","Australia","AU","AUS","New South Wales","","12900","1036897159"
"Red Bluff","Red Bluff","40.1735","-122.2413","United States","US","USA","California","","14557","1840020176"
"Ghaura","Ghaura","24.5059","79.0781","India","IN","IND","Madhya Pradesh","","14273","1356126141"
"Tepecoyo","Tepecoyo","13.7003","-89.4678","El Salvador","SV","SLV","La Libertad","","14322","1222039134"
"Sur Singh","Sur Singh","31.3992","74.7160","India","IN","IND","Punjab","","14134","1356117402"
"Haaren","Haaren","51.6000","5.2333","Netherlands","NL","NLD","Noord-Brabant","minor","14195","1528557214"
"Sison","Sison","9.6592","125.5272","Philippines","PH","PHL","Surigao del Norte","","14290","1608527049"
"Tālbahat","Talbahat","25.0420","78.4330","India","IN","IND","Uttar Pradesh","","14176","1356719523"
"Benaulim","Benaulim","15.2700","73.9346","India","IN","IND","Goa","","13408","1356789752"
"Tolland","Tolland","41.8786","-72.3648","United States","US","USA","Connecticut","","14546","1840004762"
"Tsimlyansk","Tsimlyansk","47.6167","42.1000","Russia","RU","RUS","Rostovskaya Oblast’","minor","14528","1643104941"
"Keāl","Keal","25.1218","84.6324","India","IN","IND","Bihār","","14010","1356988570"
"Amarāvati","Amaravati","16.5730","80.3580","India","IN","IND","Andhra Pradesh","","13400","1356116050"
"Löbau","Lobau","51.0944","14.6667","Germany","DE","DEU","Saxony","","14363","1276786301"
"Corinth","Corinth","34.9474","-88.5143","United States","US","USA","Mississippi","","14545","1840014638"
"Ust’-Ordynskiy","Ust'-Ordynskiy","52.8056","104.7622","Russia","RU","RUS","Irkutskaya Oblast’","","14538","1643007224"
"Poço das Trincheiras","Poco das Trincheiras","-9.3128","-37.2858","Brazil","BR","BRA","Alagoas","","14493","1076000314"
"Çobanlar","Cobanlar","38.7014","30.7828","Turkey","TR","TUR","Afyonkarahisar","minor","14503","1792683899"
"Belpukur","Belpukur","21.9851","88.2125","India","IN","IND","West Bengal","","13011","1356759284"
"Kearsley","Kearsley","53.5300","-2.3700","United Kingdom","GB","GBR","Bolton","","14212","1826981144"
"Asagiri","Asagiri","32.2403","130.8979","Japan","JP","JPN","Kumamoto","","14448","1392003542"
"Barra do Mendes","Barra do Mendes","-11.8100","-42.0589","Brazil","BR","BRA","Bahia","","14526","1076707399"
"Kurikuppi","Kurikuppi","15.0700","76.5500","India","IN","IND","Karnātaka","","14278","1356140124"
"Água Boa","Agua Boa","-17.9958","-42.3889","Brazil","BR","BRA","Minas Gerais","","14524","1076580092"
"Minignan","Minignan","10.0000","-7.8333","Côte d'Ivoire","CI","CIV","Denguélé","minor","14521","1384218318"
"General MacArthur","General MacArthur","11.2486","125.5400","Philippines","PH","PHL","Eastern Samar","","14411","1608572895"
"Tiruppanandāl","Tiruppanandal","11.0919","79.4538","India","IN","IND","Tamil Nādu","","13696","1356037156"
"Paulino Neves","Paulino Neves","-2.7189","-42.5328","Brazil","BR","BRA","Maranhão","","14519","1076830329"
"Plumtree","Plumtree","-20.4781","27.7972","Zimbabwe","ZW","ZWE","Matabeleland South","","14459","1716916272"
"Ankaramena","Ankaramena","-25.0167","46.7500","Madagascar","MG","MDG","Toliara","","14456","1450651511"
"Guiratinga","Guiratinga","-16.3489","-53.7578","Brazil","BR","BRA","Mato Grosso","","14525","1076329698"
"Jericho","Jericho","40.7875","-73.5416","United States","US","USA","New York","","14526","1840005268"
"Mbuyapey","Mbuyapey","-26.2000","-56.7500","Paraguay","PY","PRY","Paraguarí","","14512","1600084808"
"San Martín de Loba","San Martin de Loba","8.8333","-73.9167","Colombia","CO","COL","Bolívar","minor","14504","1170000618"
"Farap","Farap","39.1667","63.6000","Turkmenistan","TM","TKM","Lebap","","14503","1795918715"
"Bad Camberg","Bad Camberg","50.3000","8.2667","Germany","DE","DEU","Hesse","","14263","1276097713"
"Robbinsdale","Robbinsdale","45.0261","-93.3332","United States","US","USA","Minnesota","","14522","1840008931"
"Bayt Jālā","Bayt Jala","31.7150","35.1872","West Bank","XW","XWB","","","13484","1934810558"
"Abensberg","Abensberg","48.8000","11.8500","Germany","DE","DEU","Bavaria","","14284","1276501504"
"Greenwood","Greenwood","33.5126","-90.1993","United States","US","USA","Mississippi","","14521","1840013739"
"Morroa","Morroa","9.3333","-75.3167","Colombia","CO","COL","Sucre","minor","14429","1170532353"
"Iflissen","Iflissen","36.8636","4.2203","Algeria","DZ","DZA","Tizi Ouzou","","14311","1012326451"
"Sopetrán","Sopetran","6.5017","-75.7433","Colombia","CO","COL","Antioquia","minor","14453","1170000615"
"Bāzidpur","Bazidpur","26.1536","86.1511","India","IN","IND","Bihār","","12801","1356452229"
"Vatra Dornei","Vatra Dornei","47.3461","25.3594","Romania","RO","ROU","Suceava","","14429","1642626415"
"Pinhalzinho","Pinhalzinho","-22.7800","-46.5700","Brazil","BR","BRA","São Paulo","","14423","1076934968"
"Cefalù","Cefalu","38.0333","14.0167","Italy","IT","ITA","Sicilia","","14298","1380556329"
"Barroquinha","Barroquinha","-3.0189","-41.1369","Brazil","BR","BRA","Ceará","","14476","1076119723"
"Middlesex","Middlesex","40.5744","-74.5011","United States","US","USA","New Jersey","","14513","1840001337"
"Undrājavaram","Undrajavaram","16.7866","81.6997","India","IN","IND","Andhra Pradesh","","13293","1356797513"
"Wörgl","Worgl","47.4831","12.0664","Austria","AT","AUT","Tirol","","13811","1040228921"
"Scottsbluff","Scottsbluff","41.8684","-103.6616","United States","US","USA","Nebraska","","14511","1840009197"
"Murra","Murra","13.7592","-86.0172","Nicaragua","NI","NIC","Nueva Segovia","minor","14487","1558838779"
"Oleggio","Oleggio","45.6000","8.6333","Italy","IT","ITA","Piedmont","","14135","1380034539"
"Eching","Eching","48.3000","11.6167","Germany","DE","DEU","Bavaria","","14130","1276481683"
"Alausí","Alausi","-2.1900","-78.8500","Ecuador","EC","ECU","Chimborazo","","14294","1218578121"
"Uibaí","Uibai","-11.3369","-42.1328","Brazil","BR","BRA","Bahia","","14483","1076214416"
"Alterosa","Alterosa","-21.2190","-46.1869","Brazil","BR","BRA","Minas Gerais","","14497","1076001106"
"Lotte","Lotte","52.2764","7.9167","Germany","DE","DEU","North Rhine-Westphalia","","14135","1276170713"
"Malvik","Malvik","63.3728","10.7508","Norway","NO","NOR","Trøndelag","","14425","1578696010"
"Orikhiv","Orikhiv","47.5677","35.7849","Ukraine","UA","UKR","Zaporizka Oblast","","14479","1804862588"
"La Carlota","La Carlota","37.6667","-4.9333","Spain","ES","ESP","Andalusia","","14324","1724972174"
"Araceli","Araceli","10.5529","119.9904","Philippines","PH","PHL","Palawan","","14434","1608953216"
"Ceres","Ceres","-29.8667","-61.9500","Argentina","AR","ARG","Santa Fe","","14499","1032855291"
"Hutchinson","Hutchinson","44.8855","-94.3768","United States","US","USA","Minnesota","","14504","1840006756"
"Painan","Painan","-1.3511","100.5756","Indonesia","ID","IDN","Sumatera Barat","minor","14425","1360654433"
"Abony","Abony","47.1894","20.0097","Hungary","HU","HUN","Pest","","14392","1348748527"
"Retirolândia","Retirolandia","-11.4950","-39.4258","Brazil","BR","BRA","Bahia","","14495","1076905275"
"Squinzano","Squinzano","40.4333","18.0500","Italy","IT","ITA","Puglia","","14031","1380933305"
"Al Mu‘aḑḑamīyah","Al Mu\`addamiyah","33.7421","36.6435","Syria","SY","SYR","Rīf Dimashq","","14228","1760021523"
"Bela Simri","Bela Simri","25.5295","86.3079","India","IN","IND","Bihār","","12805","1356624262"
"Annāram","Annaram","16.7840","79.3020","India","IN","IND","Andhra Pradesh","","13994","1356732259"
"San Carlos","San Carlos","6.1897","-74.9969","Colombia","CO","COL","Antioquia","minor","14480","1170540736"
"Tostedt","Tostedt","53.2833","9.7167","Germany","DE","DEU","Lower Saxony","","14206","1276412627"
"Short Hills","Short Hills","40.7389","-74.3278","United States","US","USA","New Jersey","","14500","1840033408"
"Hudson","Hudson","44.9639","-92.7312","United States","US","USA","Wisconsin","","14500","1840002135"
"West Lincoln","West Lincoln","43.0667","-79.5667","Canada","CA","CAN","Ontario","","14500","1124001460"
"Belvedere Park","Belvedere Park","33.7489","-84.2599","United States","US","USA","Georgia","","14497","1840013699"
"Khagra","Khagra","25.3511","87.0624","India","IN","IND","Bihār","","12595","1356119136"
"Shamalgan","Shamalgan","43.3775","76.6200","Kazakhstan","KZ","KAZ","","","14294","1398379931"
"Liedekerke","Liedekerke","50.8667","4.0833","Belgium","BE","BEL","Flanders","","13188","1056222350"
"Bongaree","Bongaree","-27.0813","153.1636","Australia","AU","AUS","Queensland","","13649","1036269345"
"Ban Don Kaeo","Ban Don Kaeo","18.8567","98.9689","Thailand","TH","THA","Chiang Mai","","14286","1764651646"
"Artondale","Artondale","47.3021","-122.6406","United States","US","USA","Washington","","14495","1840037617"
"Pé de Serra","Pe de Serra","-11.8339","-39.6128","Brazil","BR","BRA","Bahia","","14471","1076313589"
"Krasnyy Kut","Krasnyy Kut","50.9500","46.9667","Russia","RU","RUS","Saratovskaya Oblast’","","14483","1643654009"
"Manoel Vitorino","Manoel Vitorino","-14.1450","-40.2428","Brazil","BR","BRA","Bahia","","14488","1076783361"
"Denzlingen","Denzlingen","48.0683","7.8825","Germany","DE","DEU","Baden-Württemberg","","13686","1276127515"
"Serinhisar","Serinhisar","37.5807","29.2698","Turkey","TR","TUR","Denizli","minor","14430","1792476134"
"Schwaz","Schwaz","47.3500","11.7000","Austria","AT","AUT","Tirol","minor","13810","1040919527"
"Immenstadt im Allgäu","Immenstadt im Allgau","47.5667","10.2167","Germany","DE","DEU","Bavaria","","14315","1276627298"
"Wangjiaxian","Wangjiaxian","36.5443","104.1496","China","CN","CHN","Gansu","","14400","1156046791"
"Maisach","Maisach","48.2167","11.2667","Germany","DE","DEU","Bavaria","","14223","1276438792"
"Schneeberg","Schneeberg","50.5942","12.6456","Germany","DE","DEU","Saxony","","13894","1276764942"
"Bokod","Bokod","16.4914","120.8300","Philippines","PH","PHL","Benguet","","14435","1608394399"
"Villa Vásquez","Villa Vasquez","19.7400","-71.4500","Dominican Republic","DO","DOM","Cibao Noroeste","","14424","1214796601"
"Fiesole","Fiesole","43.8072","11.2919","Italy","IT","ITA","Tuscany","","14150","1380368981"
"Locorotondo","Locorotondo","40.7558","17.3264","Italy","IT","ITA","Puglia","","14190","1380099343"
"Hickory Hills","Hickory Hills","41.7248","-87.8280","United States","US","USA","Illinois","","14483","1840007030"
"Bensville","Bensville","38.6176","-77.0077","United States","US","USA","Maryland","","14483","1840073595"
"Torrijos","Torrijos","39.9833","-4.2814","Spain","ES","ESP","Castille-La Mancha","","13678","1724246544"
"Santa María","Santa Maria","-32.7469","-70.6597","Chile","CL","CHL","Valparaíso","","14452","1152248168"
"Lockhart","Lockhart","29.8785","-97.6831","United States","US","USA","Texas","","14480","1840020936"
"Doddipatla","Doddipatla","16.5167","81.8436","India","IN","IND","Andhra Pradesh","","13258","1356959809"
"Belazao","Belazao","-19.8833","46.9667","Madagascar","MG","MDG","Antananarivo","","14332","1450189336"
"Verona","Verona","40.8323","-74.2431","United States","US","USA","New Jersey","","14479","1840131594"
"Sidi Azzouz","Sidi Azzouz","31.7667","-7.6667","Morocco","MA","MAR","Marrakech-Safi","","14356","1504893148"
"Cliftonville","Cliftonville","51.3881","1.4046","United Kingdom","GB","GBR","Kent","","12900","1826914470"
"Rovinj","Rovinj","45.0833","13.6333","Croatia","HR","HRV","Istarska Županija","minor","14294","1191599469"
"Barranco de Loba","Barranco de Loba","8.9500","-74.1167","Colombia","CO","COL","Bolívar","minor","14435","1170730052"
"Vellodu","Vellodu","10.3048","77.9552","India","IN","IND","Tamil Nādu","","13832","1356243135"
"Ourtzagh","Ourtzagh","34.5500","-4.9333","Morocco","MA","MAR","Fès-Meknès","","14381","1504945412"
"Pomorie","Pomorie","42.5683","27.6167","Bulgaria","BG","BGR","Burgas","minor","14409","1100639674"
"Vembārpatti","Vembarpatti","10.2500","78.1167","India","IN","IND","Tamil Nādu","","13829","1356228075"
"Mizil","Mizil","45.0000","26.4406","Romania","RO","ROU","Prahova","","14312","1642044399"
"Gernsbach","Gernsbach","48.7633","8.3342","Germany","DE","DEU","Baden-Württemberg","","14296","1276744650"
"Colesville","Colesville","39.0730","-77.0010","United States","US","USA","Maryland","","14468","1840005831"
"Zemrane","Zemrane","31.5339","-8.2672","Morocco","MA","MAR","Marrakech-Safi","","14338","1504983059"
"Argentan","Argentan","48.7444","-0.0203","France","FR","FRA","Normandie","minor","13730","1250846292"
"Daulatnagar","Daulatnagar","25.3216","87.8443","India","IN","IND","West Bengal","","13235","1356122184"
"Vadasseri","Vadasseri","8.1937","77.4343","India","IN","IND","Tamil Nādu","","12751","1356221888"
"Tagalft","Tagalft","32.2389","-6.1238","Morocco","MA","MAR","Béni Mellal-Khénifra","","14423","1504338163"
"Reidsville","Reidsville","36.3376","-79.6725","United States","US","USA","North Carolina","","14463","1840014436"
"Ulchin","Ulchin","37.0013","129.3449","South Korea","KR","KOR","Gyeongbuk","","14426","1410704555"
"Matane","Matane","48.8500","-67.5333","Canada","CA","CAN","Quebec","","14462","1124528318"
"Lāla","Lala","24.5542","92.6159","India","IN","IND","Assam","","13556","1356685742"
"Ponnampatti","Ponnampatti","10.3668","78.3955","India","IN","IND","Tamil Nādu","","14071","1356215759"
"Riedisheim","Riedisheim","47.7483","7.3669","France","FR","FRA","Grand Est","","12660","1250886439"
"Ömerli","Omerli","37.4025","40.9561","Turkey","TR","TUR","Mardin","minor","14233","1792513736"
"Graça","Graca","-4.0458","-40.7528","Brazil","BR","BRA","Ceará","","14407","1076068643"
"Holzgerlingen","Holzgerlingen","48.6392","9.0108","Germany","DE","DEU","Baden-Württemberg","","13453","1276703274"
"Mantaly","Mantaly","-13.1667","48.9667","Madagascar","MG","MDG","Antsiranana","","14420","1450370610"
"Bålsta","Balsta","59.5833","17.5000","Sweden","SE","SWE","Uppsala","minor","13138","1752539011"
"Geldagana","Geldagana","43.2162","46.0381","Russia","RU","RUS","Chechnya","","14111","1643104513"
"Pueblo Rico","Pueblo Rico","5.2500","-76.1667","Colombia","CO","COL","Risaralda","minor","14429","1170315840"
"Dahāria","Daharia","26.1807","87.0043","India","IN","IND","Bihār","","13419","1356511757"
"Luino","Luino","46.0000","8.7500","Italy","IT","ITA","Lombardy","","14454","1380854109"
"Peddavadlapūdi","Peddavadlapudi","16.4098","80.6114","India","IN","IND","Andhra Pradesh","","13076","1356150720"
"Lindenhurst","Lindenhurst","42.4175","-88.0257","United States","US","USA","Illinois","","14453","1840011163"
"El Rodeo","El Rodeo","14.3906","-90.8314","Guatemala","GT","GTM","Escuintla","","14125","1320523864"
"Alagoinha","Alagoinha","-6.9500","-35.5450","Brazil","BR","BRA","Paraíba","","14284","1076535810"
"Wingene","Wingene","51.0500","3.2667","Belgium","BE","BEL","Flanders","","14243","1056221494"
"Brooks","Brooks","50.5642","-111.8989","Canada","CA","CAN","Alberta","","14451","1124093123"
"Middlewich","Middlewich","53.1920","-2.4430","United Kingdom","GB","GBR","Cheshire East","","13595","1826609580"
"Vempalle","Vempalle","13.5382","78.4604","India","IN","IND","Andhra Pradesh","","13691","1356040722"
"Sai Buri","Sai Buri","6.7012","101.6181","Thailand","TH","THA","Pattani","minor","14137","1764406670"
"Yedapalli","Yedapalli","18.6789","77.9505","India","IN","IND","Andhra Pradesh","","13469","1356857489"
"Brazópolis","Brazopolis","-22.4739","-45.6078","Brazil","BR","BRA","Minas Gerais","","14410","1076311905"
"Taggia","Taggia","43.8439","7.8509","Italy","IT","ITA","Liguria","","14003","1380039801"
"Amantea","Amantea","39.1333","16.0667","Italy","IT","ITA","Calabria","","13975","1380959246"
"Nattappettai","Nattappettai","12.8187","79.7493","India","IN","IND","Tamil Nādu","","13346","1356365336"
"Kihoku","Kihoku","34.2114","136.3372","Japan","JP","JPN","Mie","","14392","1392003092"
"Mannūr","Mannur","17.2994","76.0984","India","IN","IND","Karnātaka","","14263","1356143550"
"Tianguistengo","Tianguistengo","20.7278","-98.6289","Mexico","MX","MEX","Hidalgo","","14340","1484089008"
"Hlučín","Hlucin","49.8967","18.1931","Czechia","CZ","CZE","Moravskoslezský Kraj","","13805","1203528969"
"Caselle Torinese","Caselle Torinese","45.1775","7.6464","Italy","IT","ITA","Piedmont","","13958","1380453030"
"Yamada","Yamada","39.4677","141.9489","Japan","JP","JPN","Iwate","","14388","1392896319"
"Sassenberg","Sassenberg","51.9897","8.0408","Germany","DE","DEU","North Rhine-Westphalia","","14260","1276379002"
"Lagoa","Lagoa","37.7500","-25.5833","Portugal","PT","PRT","Azores","","14442","1620677502"
"Belmont","Belmont","35.2212","-81.0401","United States","US","USA","North Carolina","","14442","1840013469"
"Auria","Auria","24.8563","87.0112","India","IN","IND","Bihār","","13177","1356826386"
"Temsamane","Temsamane","35.1167","-3.6333","Morocco","MA","MAR","Oriental","","13920","1504304960"
"Muqui","Muqui","-20.9519","-41.3458","Brazil","BR","BRA","Espírito Santo","","14396","1076475999"
"Washington Court House","Washington Court House","39.5381","-83.4281","United States","US","USA","Ohio","","14440","1840003801"
"Cojedes","Cojedes","9.6167","-68.9167","Venezuela","VE","VEN","Cojedes","minor","14044","1862336998"
"Tokoroa","Tokoroa","-38.2167","175.8667","New Zealand","NZ","NZL","Waikato","","13572","1554951529"
"Tounfit","Tounfit","32.4667","-5.2333","Morocco","MA","MAR","Drâa-Tafilalet","","13297","1504650500"
"Ihaddadene","Ihaddadene","31.2000","-9.6500","Morocco","MA","MAR","Marrakech-Safi","","14345","1504932139"
"Adams","Adams","40.7092","-80.0118","United States","US","USA","Pennsylvania","","14438","1840149061"
"Bāri","Bari","25.8769","86.1918","India","IN","IND","Bihār","","12712","1356730923"
"San Antonio","San Antonio","3.9167","-75.5000","Colombia","CO","COL","Tolima","minor","14400","1170845571"
"Polyarnyye Zori","Polyarnyye Zori","67.3667","32.5000","Russia","RU","RUS","Murmanskaya Oblast’","","14421","1643472022"
"Salempur","Salempur","26.4588","84.6517","India","IN","IND","Bihār","","12818","1356639560"
"Natchez","Natchez","31.5437","-91.3867","United States","US","USA","Mississippi","","14435","1840014951"
"Ascensión","Ascension","-15.6996","-63.0800","Bolivia","BO","BOL","Santa Cruz","","14429","1068811738"
"Sariosiyo","Sariosiyo","38.4133","67.9572","Uzbekistan","UZ","UZB","Surxondaryo","","14300","1860882708"
"Tururu","Tururu","-3.5808","-39.4369","Brazil","BR","BRA","Ceará","","14408","1076994637"
"Pine","Pine","40.6437","-80.0315","United States","US","USA","Pennsylvania","","14432","1840147147"
"Palmer Ranch","Palmer Ranch","27.2286","-82.4672","United States","US","USA","Florida","","14430","1840142108"
"Lohiyār Ujain","Lohiyar Ujain","26.6284","84.7452","India","IN","IND","Bihār","","12992","1356078372"
"Zaouiat Moulay Bouchta El Khammar","Zaouiat Moulay Bouchta El Khammar","34.4833","-5.1167","Morocco","MA","MAR","Fès-Meknès","","14347","1504234234"
"Lilburn","Lilburn","33.8897","-84.1364","United States","US","USA","Georgia","","14424","1840015613"
"Anantarāzupeta","Anantarazupeta","14.0014","79.3319","India","IN","IND","Andhra Pradesh","","14287","1356328441"
"Marostica","Marostica","45.7456","11.6553","Italy","IT","ITA","Veneto","","14039","1380791088"
"Igrapiúna","Igrapiuna","-13.8258","-39.1419","Brazil","BR","BRA","Bahia","","14395","1076750964"
"Sanpozhen","Sanpozhen","39.6683","115.4426","China","CN","CHN","Hebei","","14365","1156196003"
"Kinogitan","Kinogitan","8.9855","124.7937","Philippines","PH","PHL","Misamis Oriental","","14091","1608778669"
"New Paltz","New Paltz","41.7577","-74.0883","United States","US","USA","New York","","14421","1840004731"
"Sainte-Anne-des-Plaines","Sainte-Anne-des-Plaines","45.7617","-73.8204","Canada","CA","CAN","Quebec","","14421.00","1124304532"
"Kukdeshwar","Kukdeshwar","24.4828","75.2675","India","IN","IND","Madhya Pradesh","","14246","1356020293"
"Santa Maria do Suaçuí","Santa Maria do Suacui","-18.1900","-42.4139","Brazil","BR","BRA","Minas Gerais","","14395","1076386732"
"Balangiga","Balangiga","11.1097","125.3875","Philippines","PH","PHL","Eastern Samar","","14341","1608562317"
"Itinga","Itinga","-16.6128","-41.7650","Brazil","BR","BRA","Minas Gerais","","14407","1076187768"
"Sunset","Sunset","25.7060","-80.3530","United States","US","USA","Florida","","14415","1840029084"
"San Andrés Timilpan","San Andres Timilpan","19.8667","-99.7333","Mexico","MX","MEX","México","minor","14335","1484996853"
"Tezu","Tezu","27.9167","96.1667","India","IN","IND","Arunāchal Pradesh","","14376","1356415744"
"Beasain","Beasain","43.0458","-2.1894","Spain","ES","ESP","Basque Country","","13949","1724630898"
"Sīdī Barānī","Sidi Barani","31.6108","25.9256","Egypt","EG","EGY","Maţrūḩ","","14393","1818288433"
"Besārh","Besarh","25.9537","87.0491","India","IN","IND","Bihār","","13384","1356151788"
"Fisciano","Fisciano","40.7667","14.8000","Italy","IT","ITA","Campania","","13967","1380391140"
"Grafing bei München","Grafing bei Munchen","48.0500","11.9667","Germany","DE","DEU","Bavaria","","13936","1276799795"
"Alsager","Alsager","53.0961","-2.3048","United Kingdom","GB","GBR","Cheshire East","","13389","1826797417"
"Sitalpur","Sitalpur","26.4050","85.0070","India","IN","IND","Bihār","","12971","1356050429"
"Cotegipe","Cotegipe","-12.0278","-44.2578","Brazil","BR","BRA","Bahia","","14403","1076905904"
"Peumo","Peumo","-34.3961","-71.1694","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","14313","1152229023"
"Wulong","Wulong","23.3202","112.1901","China","CN","CHN","Guangdong","","14207","1156600637"
"Niederzier","Niederzier","50.8831","6.4667","Germany","DE","DEU","North Rhine-Westphalia","","14180","1276339063"
"Vemulapūdi","Vemulapudi","17.6087","82.6138","India","IN","IND","Andhra Pradesh","","13345","1356480875"
"Taglio","Taglio","45.0167","12.2167","Italy","IT","ITA","Veneto","","14298","1380512277"
"Modakkurichchi","Modakkurichchi","11.2329","77.7794","India","IN","IND","Tamil Nādu","","13268","1356167384"
"Chudovo","Chudovo","59.1167","31.6667","Russia","RU","RUS","Novgorodskaya Oblast’","","14394","1643696989"
"Angermünde","Angermunde","53.0333","14.0000","Germany","DE","DEU","Brandenburg","","14360","1276588063"
"Groveton","Groveton","38.7605","-77.0980","United States","US","USA","Virginia","","14402","1840006029"
"Pazar","Pazar","40.2620","36.3130","Turkey","TR","TUR","Tokat","minor","14335","1792303628"
"Ayni","Ayni","38.6667","68.8167","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","14052","1762288360"
"Ban Nong Kula","Ban Nong Kula","16.6500","99.9500","Thailand","TH","THA","Phitsanulok","","14273","1764676544"
"Det Udom","Det Udom","14.9060","105.0784","Thailand","TH","THA","Ubon Ratchathani","minor","14264","1764920064"
"Kodigenahalli","Kodigenahalli","13.8608","77.4792","India","IN","IND","Andhra Pradesh","","13759","1356090415"
"Üzümlü","Uzumlu","39.7100","39.7017","Turkey","TR","TUR","Erzincan","minor","14390","1792668241"
"Saldaña","Saldana","3.9347","-75.0203","Colombia","CO","COL","Tolima","minor","14329","1170588181"
"Ozark","Ozark","31.4508","-85.6473","United States","US","USA","Alabama","","14396","1840005283"
"Mānikpur","Manikpur","25.1339","86.6777","India","IN","IND","Bihār","","13174","1356061598"
"Hopatcong","Hopatcong","40.9541","-74.6593","United States","US","USA","New Jersey","","14395","1840003482"
"San Cristóbal","San Cristobal","-30.3167","-61.2333","Argentina","AR","ARG","Santa Fe","minor","14389","1032634720"
"Moorestown-Lenola","Moorestown-Lenola","39.9659","-74.9643","United States","US","USA","New Jersey","","14394","1840035113"
"Tibri","Tibri","31.9854","75.4316","India","IN","IND","Punjab","","13794","1356010850"
"East Bridgewater","East Bridgewater","42.0352","-70.9424","United States","US","USA","Massachusetts","","14393","1840053567"
"Kannivādi","Kannivadi","10.3794","77.8303","India","IN","IND","Tamil Nādu","","13750","1356251289"
"Hettstedt","Hettstedt","51.6333","11.5000","Germany","DE","DEU","Saxony-Anhalt","","14023","1276039853"
"Koundara","Koundara","12.4800","-13.2960","Guinea","GN","GIN","Boké","minor","13990","1324267190"
"Dom Feliciano","Dom Feliciano","-30.7039","-52.1078","Brazil","BR","BRA","Rio Grande do Sul","","14380","1076532414"
"Sankt Leon-Rot","Sankt Leon-Rot","49.2653","8.5986","Germany","DE","DEU","Baden-Württemberg","","13849","1276660837"
"Yanshuiguan","Yanshuiguan","36.8252","110.2345","China","CN","CHN","Shaanxi","","14331","1156437091"
"Royal Kunia","Royal Kunia","21.4053","-158.0318","United States","US","USA","Hawaii","","14390","1840137108"
"Lockhart","Lockhart","28.6270","-81.4354","United States","US","USA","Florida","","14390","1840014095"
"Tafrant","Tafrant","34.6250","-5.1242","Morocco","MA","MAR","Fès-Meknès","","14246","1504722056"
"Langerwehe","Langerwehe","50.8167","6.3497","Germany","DE","DEU","North Rhine-Westphalia","","14050","1276770497"
"Valday","Valday","57.9667","33.2500","Russia","RU","RUS","Novgorodskaya Oblast’","","14379","1643697222"
"Pak Thong Chai","Pak Thong Chai","14.7167","102.0167","Thailand","TH","THA","Nakhon Ratchasima","minor","14272","1764718877"
"Tamār","Tamar","23.0488","85.6490","India","IN","IND","Jhārkhand","","14081","1356660465"
"Vöhringen","Vohringen","48.2833","10.0833","Germany","DE","DEU","Bavaria","","13800","1276392768"
"Nether Providence","Nether Providence","39.8971","-75.3697","United States","US","USA","Pennsylvania","","14384","1840152840"
"Gangaikondān","Gangaikondan","11.5389","79.4765","India","IN","IND","Tamil Nādu","","13837","1356159185"
"Doctor Juan Eulogio Estigarribia","Doctor Juan Eulogio Estigarribia","-25.3700","-55.7000","Paraguay","PY","PRY","Caaguazú","","14316","1600438790"
"Feldkirchen","Feldkirchen","46.7236","14.0919","Austria","AT","AUT","Kärnten","minor","14198","1040318480"
"Kartuzy","Kartuzy","54.3333","18.2000","Poland","PL","POL","Pomorskie","minor","14204","1616246268"
"Emerald","Emerald","-23.5208","148.1619","Australia","AU","AUS","Queensland","","14356","1036124660"
"Zolote","Zolote","48.6833","38.5167","Ukraine","UA","UKR","Luhanska Oblast","","14376","1804231300"
"Abhwar","Abhwar","26.1740","86.6324","India","IN","IND","Bihār","","13390","1356253810"
"Nacozari Viejo","Nacozari Viejo","30.4833","-109.4667","Mexico","MX","MEX","Sonora","","14369","1484108383"
"O‘nhayot","O\`nhayot","41.0103","71.7614","Uzbekistan","UZ","UZB","Namangan","","14000","1860318016"
"Lake Arbor","Lake Arbor","38.9105","-76.8304","United States","US","USA","Maryland","","14376","1840031485"
"Black Forest","Black Forest","39.0608","-104.6752","United States","US","USA","Colorado","","14375","1840017562"
"Itakura","Itakura","36.2259","139.6019","Japan","JP","JPN","Gunma","","14039","1392003326"
"Furukawamen","Furukawamen","33.2384","129.6504","Japan","JP","JPN","Nagasaki","","13942","1392778986"
"Matiçan","Matican","42.6449","21.1918","Kosovo","XK","XKS","Prishtinë","","13876","1901916938"
"Jacaraú","Jacarau","-6.6119","-35.2928","Brazil","BR","BRA","Paraíba","","14316","1076944790"
"Rheinfelden","Rheinfelden","47.5500","7.8000","Switzerland","CH","CHE","Aargau","minor","13528","1756486705"
"Rozenburg","Rozenburg","51.9058","4.2486","Netherlands","NL","NLD","Zuid-Holland","","12455","1528996034"
"Ciénega de Flores","Cienega de Flores","25.9500","-100.1833","Mexico","MX","MEX","Nuevo León","minor","14268","1484782533"
"Abadla","Abadla","31.0167","-2.7333","Algeria","DZ","DZA","Béchar","","14364","1012262770"
"Quakenbrück","Quakenbruck","52.6772","7.9575","Germany","DE","DEU","Lower Saxony","","13612","1276336549"
"Jasper","Jasper","33.8508","-87.2710","United States","US","USA","Alabama","","14370","1840003778"
"Whitewater","Whitewater","42.8372","-88.7341","United States","US","USA","Wisconsin","","14369","1840002476"
"Værløse","Vaerlose","55.7819","12.3731","Denmark","DK","DNK","Hovedstaden","","13127","1208862489"
"Neuri","Neuri","26.0076","86.1841","India","IN","IND","Bihār","","13019","1356795978"
"Nawāda Gobindganj","Nawada Gobindganj","26.4868","84.6561","India","IN","IND","Bihār","","12749","1356758681"
"Barkuhi","Barkuhi","22.1901","78.7047","India","IN","IND","Madhya Pradesh","","14220","1356670874"
"Lakinsk","Lakinsk","56.0333","39.9667","Russia","RU","RUS","Vladimirskaya Oblast’","","14330","1643547858"
"Artena","Artena","41.7333","12.9167","Italy","IT","ITA","Lazio","","14107","1380902355"
"West Nipissing / Nipissing Ouest","West Nipissing / Nipissing Ouest","46.3667","-79.9167","Canada","CA","CAN","Ontario","","14364","1124000026"
"Sidmouth","Sidmouth","50.6800","-3.2390","United Kingdom","GB","GBR","Devon","","12569","1826699367"
"Assenede","Assenede","51.2333","3.7500","Belgium","BE","BEL","Flanders","","14200","1056796526"
"Manoharpur","Manoharpur","22.3746","85.1923","India","IN","IND","Jhārkhand","","14265","1356895889"
"Uslar","Uslar","51.6597","9.6358","Germany","DE","DEU","Lower Saxony","","14236","1276673378"
"Montfoort","Montfoort","52.0500","4.9500","Netherlands","NL","NLD","Utrecht","minor","13996","1528818658"
"Corat","Corat","40.5725","49.7064","Azerbaijan","AZ","AZE","Sumqayıt","","12900","1031974785"
"Mathurāpur","Mathurapur","24.9073","85.9836","India","IN","IND","Bihār","","13214","1356236454"
"Cervignano del Friuli","Cervignano del Friuli","45.8231","13.3350","Italy","IT","ITA","Friuli Venezia Giulia","","13881","1380444773"
"North Mankato","North Mankato","44.1810","-94.0391","United States","US","USA","Minnesota","","14356","1840007896"
"Tsukawaki","Tsukawaki","33.2832","131.1515","Japan","JP","JPN","Ōita","","14306","1392760554"
"Mapanas","Mapanas","12.4750","125.2540","Philippines","PH","PHL","Northern Samar","","14234","1608249381"
"Duvva","Duvva","16.7792","81.6242","India","IN","IND","Andhra Pradesh","","13596","1356394587"
"Yakhroma","Yakhroma","56.2833","37.4667","Russia","RU","RUS","Moskovskaya Oblast’","","14275","1643220847"
"Romitan Shahri","Romitan Shahri","39.9333","64.3833","Uzbekistan","UZ","UZB","Buxoro","minor","14300","1860913419"
"Bog Walk","Bog Walk","18.1020","-77.0054","Jamaica","JM","JAM","Saint Catherine","","13889","1388794020"
"Buda","Buda","30.0758","-97.8487","United States","US","USA","Texas","","14348","1840019605"
"Marhamat","Marhamat","40.5000","72.3333","Uzbekistan","UZ","UZB","Andijon","minor","13600","1860259161"
"Bhīmadolu","Bhimadolu","16.8144","81.2617","India","IN","IND","Andhra Pradesh","","13669","1356817825"
"Caln","Caln","40.0014","-75.7619","United States","US","USA","Pennsylvania","","14346","1840034888"
"Estremoz","Estremoz","38.8500","-7.5833","Portugal","PT","PRT","Évora","minor","14318","1620467967"
"Sadovoye","Sadovoye","46.1269","30.3850","Ukraine","UA","UKR","Odeska Oblast","","14321","1804829390"
"Ban Mueang Nga","Ban Mueang Nga","18.5997","99.0181","Thailand","TH","THA","Lamphun","","14067","1764686544"
"Tergnier","Tergnier","49.6558","3.2872","France","FR","FRA","Hauts-de-France","","13588","1250044921"
"Ishikawa","Ishikawa","37.1571","140.4468","Japan","JP","JPN","Fukushima","","14222","1392003187"
"Doranahalli","Doranahalli","16.7324","76.9162","India","IN","IND","Karnātaka","","14097","1356140754"
"Nyasvizh","Nyasvizh","53.2167","26.6667","Belarus","BY","BLR","Minskaya Voblasts’","minor","14300","1112288294"
"Kot Īsa Khān","Kot Isa Khan","31.1351","75.0231","India","IN","IND","Punjab","","13858","1356125716"
"Eski Īkan","Eski Ikan","43.1833","68.5333","Kazakhstan","KZ","KAZ","","","14294","1398338734"
"Dharampur Bānde","Dharampur Bande","25.6609","85.5742","India","IN","IND","Bihār","","12741","1356024870"
"Lengede","Lengede","52.2049","10.3078","Germany","DE","DEU","Lower Saxony","","13937","1276406361"
"Medvezhyegorsk","Medvezhyegorsk","62.9000","34.4667","Russia","RU","RUS","Kareliya","","14340","1643392697"
"Sumbas","Sumbas","37.4431","36.0322","Turkey","TR","TUR","Osmaniye","minor","14308","1792838910"
"Ambohimanga Atsimo","Ambohimanga Atsimo","-20.8667","47.6000","Madagascar","MG","MDG","Fianarantsoa","","14293","1450661685"
"Tenerife","Tenerife","9.8989","-74.8589","Colombia","CO","COL","Magdalena","minor","14320","1170763439"
"El Barrio de la Soledad","El Barrio de la Soledad","16.8000","-95.1167","Mexico","MX","MEX","Oaxaca","","14277","1484814019"
"Beekman","Beekman","41.6042","-73.6944","United States","US","USA","New York","","14338","1840057227"
"San Rafael Petzal","San Rafael Petzal","15.4167","-91.6667","Guatemala","GT","GTM","Huehuetenango","minor","13786","1320389398"
"Coronel Bogado","Coronel Bogado","-27.1700","-56.2500","Paraguay","PY","PRY","Itapúa","","14297","1600512741"
"Oud-Turnhout","Oud-Turnhout","51.3167","4.9833","Belgium","BE","BEL","Flanders","","13977","1056409500"
"Phulwaria","Phulwaria","25.9395","85.0035","India","IN","IND","Bihār","","12969","1356334092"
"Boha","Boha","29.8348","75.5237","India","IN","IND","Punjab","","13977","1356826630"
"Bua Yai","Bua Yai","15.5858","102.4337","Thailand","TH","THA","Nakhon Ratchasima","minor","14168","1764992879"
"Zapolyarnyy","Zapolyarnyy","69.4167","30.8000","Russia","RU","RUS","Murmanskaya Oblast’","","14330","1643432092"
"Juan L. Lacaze","Juan L. Lacaze","-34.4311","-57.4483","Uruguay","UY","URY","Colonia","","12816","1858599610"
"Ūttukkottai","Uttukkottai","13.3354","79.8980","India","IN","IND","Andhra Pradesh","","14026","1356351109"
"Bideipur","Bideipur","21.0147","86.8195","India","IN","IND","Odisha","","13796","1356984397"
"Midlothian","Midlothian","41.6254","-87.7243","United States","US","USA","Illinois","","14330","1840011298"
"Algarrobo","Algarrobo","10.1000","-74.2667","Colombia","CO","COL","Magdalena","","14294","1170512460"
"Raydah","Raydah","15.8233","44.0386","Yemen","YE","YEM","‘Amrān","minor","13971","1887086285"
"Mirandiba","Mirandiba","-8.1189","-38.7278","Brazil","BR","BRA","Pernambuco","","14308","1076690736"
"Khiria","Khiria","25.6047","87.4578","India","IN","IND","Bihār","","13209","1356527725"
"Rājnagar","Rajnagar","23.9500","87.3200","India","IN","IND","West Bengal","","13965","1356624053"
"Simri","Simri","25.7523","86.6074","India","IN","IND","Bihār","","12619","1356816397"
"Sibundoy","Sibundoy","1.2033","-76.9192","Colombia","CO","COL","Putumayo","minor","14104","1170369776"
"Ortahisar","Ortahisar","38.6208","34.8647","Turkey","TR","TUR","Nevşehir","","14262","1792262495"
"Tāmaraikkulam","Tamaraikkulam","10.1085","77.5553","India","IN","IND","Tamil Nādu","","13716","1356228734"
"Gonzalez","Gonzalez","30.5822","-87.2906","United States","US","USA","Florida","","14324","1840013910"
"Gesuba","Gesuba","6.7242","37.5567","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","13927","1231778594"
"Vikravāndi","Vikravandi","12.0369","79.5460","India","IN","IND","Tamil Nādu","","13391","1356152686"
"Novohrodivka","Novohrodivka","48.2000","37.3394","Ukraine","UA","UKR","Donetska Oblast","","14300","1804940594"
"Viera West","Viera West","28.2467","-80.7387","United States","US","USA","Florida","","14322","1840039133"
"Daean","Daean","35.4038","129.2788","South Korea","KR","KOR","Ulsan","","14037","1410996164"
"Byāhatti","Byahatti","15.4468","75.2054","India","IN","IND","Karnātaka","","14097","1356065476"
"Rezzato","Rezzato","45.5150","10.3175","Italy","IT","ITA","Lombardy","","13576","1380707152"
"Thame","Thame","51.7500","-0.9700","United Kingdom","GB","GBR","Oxfordshire","","13273","1826274943"
"Takerbouzt","Takerbouzt","36.4180","4.3428","Algeria","DZ","DZA","Bouira","","14000","1012858445"
"Ocotepec","Ocotepec","17.2167","-93.1833","Mexico","MX","MEX","Chiapas","minor","14088","1484734371"
"Cristópolis","Cristopolis","-12.2339","-44.4208","Brazil","BR","BRA","Bahia","","14302","1076513995"
"Santiago Ixtayutla","Santiago Ixtayutla","16.5667","-97.6500","Mexico","MX","MEX","Oaxaca","","13041","1484640868"
"Wang’anzhen","Wang'anzhen","39.3989","114.9367","China","CN","CHN","Hebei","","14163","1156932229"
"Santol","Santol","16.7667","120.4500","Philippines","PH","PHL","La Union","","14166","1608145061"
"Lebanon","Lebanon","43.6353","-72.2531","United States","US","USA","New Hampshire","","14316","1840002192"
"Vellarivalli","Vellarivalli","11.6003","77.7927","India","IN","IND","Tamil Nādu","","13408","1356169049"
"Dippoldiswalde","Dippoldiswalde","50.8933","13.6667","Germany","DE","DEU","Saxony","","14179","1276205316"
"Korneuburg","Korneuburg","48.3453","16.3331","Austria","AT","AUT","Niederösterreich","minor","12986","1040595988"
"Rānāpur","Ranapur","22.6470","74.5212","India","IN","IND","Madhya Pradesh","","14007","1356515642"
"Mankal","Mankal","17.2014","78.4678","India","IN","IND","Andhra Pradesh","","13974","1356750787"
"Svetlogorsk","Svetlogorsk","54.9500","20.1500","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","13663","1643734918"
"Makarska","Makarska","43.3000","17.0333","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","13834","1191012753"
"Bārah","Barah","25.3885","85.4912","India","IN","IND","Bihār","","13017","1356539403"
"Saddle Brook","Saddle Brook","40.9033","-74.0955","United States","US","USA","New Jersey","","14313","1840081802"
"Tarumirim","Tarumirim","-19.2808","-42.0069","Brazil","BR","BRA","Minas Gerais","","14293","1076777364"
"Marechal Floriano","Marechal Floriano","-20.4128","-40.6828","Brazil","BR","BRA","Espírito Santo","","14262","1076108782"
"Frei Miguelinho","Frei Miguelinho","-7.9408","-35.9106","Brazil","BR","BRA","Pernambuco","","14293","1076139307"
"Sines","Sines","37.9547","-8.8644","Portugal","PT","PRT","Setúbal","minor","14238","1620898309"
"Orizona","Orizona","-17.0308","-48.2958","Brazil","BR","BRA","Goiás","","14300","1076705732"
"North Auburn","North Auburn","38.9306","-121.0820","United States","US","USA","California","","14307","1840028390"
"Santo Antônio dos Lopes","Santo Antonio dos Lopes","-4.8689","-44.3600","Brazil","BR","BRA","Maranhão","","14288","1076178562"
"Nova Olinda","Nova Olinda","-7.0919","-39.6808","Brazil","BR","BRA","Ceará","","14256","1076999604"
"Mongeri","Mongeri","8.3167","-11.7333","Sierra Leone","SL","SLE","Southern","","14273","1694288062"
"Bousso","Bousso","10.4825","16.7161","Chad","TD","TCD","Chari-Baguirmi","","14286","1148720066"
"Wennigsen","Wennigsen","52.2742","9.5708","Germany","DE","DEU","Lower Saxony","","14043","1276983413"
"Kouroussa","Kouroussa","10.6530","-9.8920","Guinea","GN","GIN","Kankan","minor","14223","1324187341"
"Gudūr","Gudur","17.7956","79.9747","India","IN","IND","Andhra Pradesh","","14110","1356074702"
"Kashin","Kashin","57.3500","37.6167","Russia","RU","RUS","Tverskaya Oblast’","","14287","1643719450"
"Bondeno","Bondeno","44.8833","11.4167","Italy","IT","ITA","Emilia-Romagna","","14217","1380410103"
"Castelsarrasin","Castelsarrasin","44.0400","1.1069","France","FR","FRA","Occitanie","minor","14114","1250268017"
"Frankenberg","Frankenberg","50.9108","13.0378","Germany","DE","DEU","Saxony","","14088","1276762731"
"Sālotgi","Salotgi","17.1700","75.9600","India","IN","IND","Karnātaka","","14076","1356787208"
"Taki","Taki","34.4961","136.5462","Japan","JP","JPN","Mie","","14160","1392003303"
"Dayr al Barshā","Dayr al Barsha","27.7500","30.9000","Egypt","EG","EGY","Al Minyā","","14297","1818073234"
"Rosemère","Rosemere","45.6369","-73.8000","Canada","CA","CAN","Quebec","","14294","1124741055"
"Bellair-Meadowbrook Terrace","Bellair-Meadowbrook Terrace","30.1796","-81.7375","United States","US","USA","Florida","","14289","1840073845"
"Bedford","Bedford","42.4969","-71.2783","United States","US","USA","Massachusetts","","14287","1840053633"
"Vinto","Vinto","-17.3833","-66.3000","Bolivia","BO","BOL","Cochabamba","","14180","1068969654"
"Hasanpur","Hasanpur","25.6330","85.5728","India","IN","IND","Bihār","","12302","1356804211"
"Tranås","Tranas","58.0333","14.9667","Sweden","SE","SWE","Jönköping","minor","14197","1752722213"
"Avalēpalli","Avalepalli","12.7714","77.8594","India","IN","IND","Tamil Nādu","","13651","1356669863"
"Lubsko","Lubsko","51.8000","14.9667","Poland","PL","POL","Lubuskie","","14182","1616174794"
"Eastham","Eastham","53.3130","-2.9620","United Kingdom","GB","GBR","Liverpool","","13882","1826374388"
"Benito Juárez","Benito Juarez","-37.6667","-59.7833","Argentina","AR","ARG","Buenos Aires","minor","14279","1032382110"
"Nirakpurpāli","Nirakpurpali","25.3244","84.8114","India","IN","IND","Bihār","","12701","1356802168"
"Kawaii","Kawaii","38.0045","140.0458","Japan","JP","JPN","Yamagata","","14196","1392919858"
"Almoloya de Alquisiras","Almoloya de Alquisiras","18.8500","-99.8500","Mexico","MX","MEX","México","minor","14196","1484855096"
"Kelafo","Kelafo","5.5889","44.2056","Ethiopia","ET","ETH","Sumalē","","14242","1231044866"
"Bishenpur","Bishenpur","24.6282","93.7610","India","IN","IND","Manipur","","13548","1356854088"
"Goshen","Goshen","41.3817","-74.3498","United States","US","USA","New York","","14279","1840004868"
"Thonotosassa","Thonotosassa","28.0464","-82.2910","United States","US","USA","Florida","","14278","1840014154"
"Staszów","Staszow","50.5642","21.1669","Poland","PL","POL","Świętokrzyskie","minor","14158","1616211187"
"Bökönbaev","Bokonbaev","42.1100","76.9900","Kyrgyzstan","KG","KGZ","Ysyk-Köl","minor","14267","1417321497"
"Itanhandu","Itanhandu","-22.2958","-44.9350","Brazil","BR","BRA","Minas Gerais","","14175","1076312428"
"Entre Rios de Minas","Entre Rios de Minas","-20.6708","-44.0658","Brazil","BR","BRA","Minas Gerais","","14242","1076189083"
"Kīāshahr","Kiashahr","37.4194","49.9489","Iran","IR","IRN","Gīlān","","14022","1364362691"
"Hamworthy","Hamworthy","50.7207","-2.0109","United Kingdom","GB","GBR","","","13141","1826152071"
"Ya‘bad","Ya\`bad","32.4467","35.1703","West Bank","XW","XWB","","","13640","1934942551"
"Jodoigne","Jodoigne","50.7167","4.8667","Belgium","BE","BEL","Wallonia","","14079","1056984728"
"Rasauli","Rasauli","26.1270","84.8378","India","IN","IND","Bihār","","13038","1356589887"
"Samāi","Samai","24.9788","85.5861","India","IN","IND","Bihār","","12697","1356620327"
"El Porvenir de Velasco Suárez","El Porvenir de Velasco Suarez","15.4333","-92.2667","Mexico","MX","MEX","Chiapas","minor","14121","1484841642"
"Lamont","Lamont","35.2659","-118.9159","United States","US","USA","California","","14269","1840017825"
"Cêrro Largo","Cerro Largo","-28.1489","-54.7378","Brazil","BR","BRA","Rio Grande do Sul","","14189","1076133279"
"Pilas","Pilas","37.3017","-6.2986","Spain","ES","ESP","Andalusia","","13964","1724984763"
"Hard","Hard","47.4892","9.6900","Austria","AT","AUT","Vorarlberg","","13495","1040681347"
"Leirvik","Leirvik","59.7798","5.5005","Norway","NO","NOR","Vestland","","14126","1578908792"
"Ewarton","Ewarton","18.1833","-77.0833","Jamaica","JM","JAM","Saint Catherine","","13807","1388307474"
"Santa Cruz de Bezana","Santa Cruz de Bezana","43.4442","-3.9031","Spain","ES","ESP","Cantabria","","13482","1724616596"
"Juan de Acosta","Juan de Acosta","10.8333","-75.0333","Colombia","CO","COL","Atlántico","minor","14184","1170261449"
"Satuba","Satuba","-9.5628","-35.8239","Brazil","BR","BRA","Alagoas","","13936","1076759300"
"Douar Hammadi","Douar Hammadi","31.6000","-8.4667","Morocco","MA","MAR","Marrakech-Safi","","14227","1504604687"
"Vīraganūr","Viraganur","11.4761","78.7355","India","IN","IND","Tamil Nādu","","13922","1356945650"
"Saraykent","Saraykent","39.6936","35.5111","Turkey","TR","TUR","Yozgat","minor","14198","1792706620"
"Jiānganj","Jianganj","25.8661","87.2612","India","IN","IND","Bihār","","12976","1356725284"
"Germasógeia","Germasogeia","34.7181","33.0856","Cyprus","CY","CYP","Lemesós","","13421","1196232379"
"Beloeil","Beloeil","50.5333","3.7167","Belgium","BE","BEL","Wallonia","","14032","1056697952"
"Arklow","Arklow","52.7941","-6.1649","Ireland","IE","IRL","Wicklow","","13163","1372998803"
"Shichinohe","Shichinohe","40.7447","141.1579","Japan","JP","JPN","Aomori","","14217","1392716252"
"Nowy Tomyśl","Nowy Tomysl","52.3167","16.1333","Poland","PL","POL","Wielkopolskie","minor","14116","1616447936"
"Bickley","Bickley","51.4003","0.0466","United Kingdom","GB","GBR","Bromley","","13904","1826046416"
"Kurwa Mathiā","Kurwa Mathia","26.8572","84.5058","India","IN","IND","Bihār","","12793","1356034976"
"Thisted","Thisted","56.9569","8.6944","Denmark","DK","DNK","Nordjylland","minor","13461","1208324421"
"Hokuei","Hokuei","35.4833","133.7667","Japan","JP","JPN","Tottori","","14010","1392003104"
"Cambados","Cambados","42.5000","-8.8000","Spain","ES","ESP","Galicia","","13673","1724551317"
"Annāmalainagar","Annamalainagar","11.4000","79.7333","India","IN","IND","Tamil Nādu","","13281","1356979937"
"Ahrensfelde","Ahrensfelde","52.5762","13.5753","Germany","DE","DEU","Brandenburg","","14011","1276671758"
"Orkney","Orkney","-26.9789","26.6692","South Africa","ZA","ZAF","North West","","13435","1710136226"
"Cromwell","Cromwell","41.6122","-72.6638","United States","US","USA","Connecticut","","14252","1840034391"
"Mistassini","Mistassini","48.8229","-72.2154","Canada","CA","CAN","Quebec","","14250.00","1124980171"
"Sihaul","Sihaul","25.9438","86.5371","India","IN","IND","Bihār","","13308","1356719445"
"Mittegroßefehn","Mittegrossefehn","53.3833","7.5833","Germany","DE","DEU","Lower Saxony","","14138","1276224109"
"Valença","Valenca","42.0167","-8.6333","Portugal","PT","PRT","Viana do Castelo","minor","14127","1620333876"
"Bou Izakarn","Bou Izakarn","29.1667","-9.7333","Morocco","MA","MAR","Guelmim-Oued Noun","","14228","1504787076"
"Ichtegem","Ichtegem","51.1000","3.0000","Belgium","BE","BEL","Flanders","","13939","1056000402"
"Uitgeest","Uitgeest","52.5333","4.7167","Netherlands","NL","NLD","Noord-Holland","minor","13632","1528048993"
"Chatham","Chatham","39.6733","-89.6934","United States","US","USA","Illinois","","14245","1840010560"
"Amriswil","Amriswil","47.5500","9.3000","Switzerland","CH","CHE","Thurgau","","13534","1756967253"
"Ochër","Ocher","57.8833","54.7333","Russia","RU","RUS","Permskiy Kray","","14226","1643072449"
"Daparkha","Daparkha","26.1293","86.9004","India","IN","IND","Bihār","","12854","1356642626"
"Champasak","Champasak","14.8833","105.8667","Laos","LA","LAO","Champasak","","13400","1418902447"
"Mill Valley","Mill Valley","37.9086","-122.5421","United States","US","USA","California","","14240","1840020259"
"Milnrow","Milnrow","53.6101","-2.1111","United Kingdom","GB","GBR","Rochdale","","13061","1826133583"
"Cecil","Cecil","40.3147","-80.1942","United States","US","USA","Pennsylvania","","14239","1840034893"
"Eppstein","Eppstein","50.1333","8.4000","Germany","DE","DEU","Hesse","","13673","1276417820"
"Burglengenfeld","Burglengenfeld","49.2000","12.0333","Germany","DE","DEU","Bavaria","","14086","1276523136"
"Van Buren","Van Buren","43.1211","-76.3591","United States","US","USA","New York","","14237","1840058558"
"Zherdevka","Zherdevka","51.8500","41.4500","Russia","RU","RUS","Tambovskaya Oblast’","","14214","1643603288"
"Hayashima","Hayashima","34.6006","133.8283","Japan","JP","JPN","Okayama","","12584","1392761843"
"Friedland","Friedland","51.4167","9.9333","Germany","DE","DEU","Lower Saxony","","14050","1276585633"
"Monticello","Monticello","45.2991","-93.7996","United States","US","USA","Minnesota","","14235","1840007795"
"Zayda","Zayda","32.8167","-4.9500","Morocco","MA","MAR","Drâa-Tafilalet","","13181","1504467977"
"Mercerville","Mercerville","40.2360","-74.6917","United States","US","USA","New Jersey","","14234","1840033344"
"Krishnarāyapuram","Krishnarayapuram","10.9563","78.2782","India","IN","IND","Tamil Nādu","","13903","1356224691"
"Aḑ Ḑab‘ah","Ad Dab\`ah","31.0338","28.4333","Egypt","EG","EGY","Maţrūḩ","","14212","1818525598"
"Boa Esperança","Boa Esperanca","-18.5400","-40.2958","Brazil","BR","BRA","Espírito Santo","","14199","1076131880"
"Brasilândia","Brasilandia","-17.0100","-46.0089","Brazil","BR","BRA","Minas Gerais","","14226","1076593373"
"Venëv","Venev","54.3500","38.2667","Russia","RU","RUS","Tul’skaya Oblast’","","14211","1643092677"
"Varde","Varde","55.6200","8.4806","Denmark","DK","DNK","Syddanmark","minor","14039","1208411481"
"Mahrail","Mahrail","26.2952","86.2763","India","IN","IND","Bihār","","12529","1356728004"
"Murrhardt","Murrhardt","48.9800","9.5814","Germany","DE","DEU","Baden-Württemberg","","14033","1276873434"
"Sakaki","Sakaki","36.4618","138.1801","Japan","JP","JPN","Nagano","","13967","1392071838"
"Püspökladány","Puspokladany","47.3167","21.1167","Hungary","HU","HUN","Hajdú-Bihar","minor","14154","1348038932"
"Dumri","Dumri","26.9873","84.0752","India","IN","IND","Bihār","","13894","1356697341"
"Belambo","Belambo","-19.4000","47.5167","Madagascar","MG","MDG","Antananarivo","","14000","1450624771"
"Lauchhammer","Lauchhammer","51.5000","13.8000","Germany","DE","DEU","Brandenburg","","14070","1276576746"
"Haidarnagar","Haidarnagar","24.4883","83.9443","India","IN","IND","Jhārkhand","","13833","1356739331"
"Fort Bliss","Fort Bliss","31.8396","-106.3747","United States","US","USA","Texas","","14226","1840073931"
"Campos Altos","Campos Altos","-19.6958","-46.1708","Brazil","BR","BRA","Minas Gerais","","14206","1076184165"
"Bariyārpur","Bariyarpur","26.6347","84.9245","India","IN","IND","Bihār","","12757","1356125284"
"Orange","Orange","41.2827","-73.0272","United States","US","USA","Connecticut","","14225","1840035528"
"Nilo Peçanha","Nilo Pecanha","-13.5989","-39.1069","Brazil","BR","BRA","Bahia","","14188","1076019753"
"Sanlúcar la Mayor","Sanlucar la Mayor","37.3831","-6.2000","Spain","ES","ESP","Andalusia","","14120","1724032342"
"Kaoma","Kaoma","-14.8000","24.8000","Zambia","ZM","ZMB","Western","","14212","1894846267"
"Mirinzal","Mirinzal","-2.0650","-44.7839","Brazil","BR","BRA","Maranhão","","14218","1076215987"
"Iskapalli","Iskapalli","14.7363","80.0981","India","IN","IND","Andhra Pradesh","","13852","1356031605"
"Capitão Enéas","Capitao Eneas","-16.3239","-43.7108","Brazil","BR","BRA","Minas Gerais","","14206","1076526989"
"Minamiaizu","Minamiaizu","37.2004","139.7732","Japan","JP","JPN","Fukushima","","14204","1392003525"
"Storozhynets","Storozhynets","48.1597","25.7150","Ukraine","UA","UKR","Chernivetska Oblast","minor","14138","1804218197"
"Bad Ischl","Bad Ischl","47.7115","13.6239","Austria","AT","AUT","Oberösterreich","","14133","1040620508"
"Aqköl","Aqkol","52.0000","70.9333","Kazakhstan","KZ","KAZ","","minor","14217","1398465376"
"Yulee","Yulee","30.6350","-81.5678","United States","US","USA","Florida","","14217","1840013928"
"Kubādupuram","Kubadupuram","16.4680","79.9539","India","IN","IND","Andhra Pradesh","","13904","1356122957"
"Little Lever","Little Lever","53.5630","-2.3690","United Kingdom","GB","GBR","Bolton","","12803","1826282057"
"Paithān Kawai","Paithan Kawai","26.1755","86.0685","India","IN","IND","Bihār","","12497","1356359381"
"Singalāndāpuram","Singalandapuram","11.4166","78.2224","India","IN","IND","Tamil Nādu","","13757","1356232146"
"Malepur","Malepur","24.9740","86.2555","India","IN","IND","Bihār","","13728","1356005982"
"Bainbridge","Bainbridge","30.9052","-84.5732","United States","US","USA","Georgia","","14210","1840013899"
"Adakplamé","Adakplame","7.4500","2.5500","Benin","BJ","BEN","Plateau","","14089","1204512448"
"Nasiyanūr","Nasiyanur","11.3381","77.6408","India","IN","IND","Tamil Nādu","","13074","1356169374"
"West Bradford","West Bradford","39.9633","-75.7160","United States","US","USA","Pennsylvania","","14207","1840145641"
"Aldenhoven","Aldenhoven","50.8958","6.2831","Germany","DE","DEU","North Rhine-Westphalia","","13893","1276558871"
"Rebouças","Reboucas","-25.6208","-50.6928","Brazil","BR","BRA","Paraná","","14176","1076989419"
"Uppidamangalam","Uppidamangalam","10.9034","78.1575","India","IN","IND","Tamil Nādu","","13399","1356224965"
"Beronono","Beronono","-18.2000","47.5167","Madagascar","MG","MDG","Antananarivo","","14148","1450727787"
"Brookside","Brookside","39.6665","-75.7152","United States","US","USA","Delaware","","14202","1840005569"
"Medapādu","Medapadu","17.0044","82.1001","India","IN","IND","Andhra Pradesh","","13097","1356630785"
"Papagaios","Papagaios","-19.4489","-44.7478","Brazil","BR","BRA","Minas Gerais","","14175","1076663724"
"Piquete","Piquete","-22.6136","-45.1761","Brazil","BR","BRA","São Paulo","","14123","1076872108"
"Karma","Karma","23.6840","85.5706","India","IN","IND","Jhārkhand","","13681","1356176895"
"Baianópolis","Baianopolis","-12.3058","-44.5350","Brazil","BR","BRA","Bahia","","14195","1076531349"
"Summerstrand","Summerstrand","-33.9914","25.6569","South Africa","ZA","ZAF","Eastern Cape","","12614","1710926578"
"Padaivedu","Padaivedu","11.4401","77.8089","India","IN","IND","Tamil Nādu","","13287","1356166710"
"Valbonne","Valbonne","43.6414","7.0089","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13486","1250002578"
"Marchtrenk","Marchtrenk","48.1917","14.1106","Austria","AT","AUT","Oberösterreich","","13603","1040791234"
"Ksibet el Mediouni","Ksibet el Mediouni","35.6900","10.8500","Tunisia","TN","TUN","Monastir","","13122","1788726660"
"Neuenkirchen","Neuenkirchen","52.2411","7.3689","Germany","DE","DEU","North Rhine-Westphalia","","13905","1276854717"
"Simões","Simoes","-7.5989","-40.8178","Brazil","BR","BRA","Piauí","","14180","1076805001"
"Belm","Belm","52.3000","8.1333","Germany","DE","DEU","Lower Saxony","","13896","1276040946"
"Bhadarwāh","Bhadarwah","32.9800","75.7200","India","IN","IND","Jammu and Kashmīr","","13881","1356209475"
"Hunasagi","Hunasagi","16.4575","76.5239","India","IN","IND","Karnātaka","","13932","1356920046"
"Bovenden","Bovenden","51.5897","9.9222","Germany","DE","DEU","Lower Saxony","","13972","1276711259"
"Titiribí","Titiribi","6.0667","-75.8000","Colombia","CO","COL","Antioquia","minor","14092","1170013003"
"Seirō","Seiro","37.9745","139.2742","Japan","JP","JPN","Niigata","","13823","1392003492"
"Beilen","Beilen","52.8567","6.5111","Netherlands","NL","NLD","Drenthe","minor","14089","1528196835"
"Mŭ’minobod","Mu'minobod","38.1083","70.0292","Tajikistan","TJ","TJK","Khatlon","minor","14100","1762625291"
"Głowno","Glowno","51.9642","19.7117","Poland","PL","POL","Łódzkie","","13506","1616156342"
"Pedro Velho","Pedro Velho","-6.4389","-35.2208","Brazil","BR","BRA","Rio Grande do Norte","","14114","1076997513"
"Rio Piracicaba","Rio Piracicaba","-19.9289","-43.1739","Brazil","BR","BRA","Minas Gerais","","14149","1076877084"
"Puerto Deseado","Puerto Deseado","-47.7500","-65.9167","Argentina","AR","ARG","Santa Cruz","minor","14183","1032537209"
"Wezep","Wezep","52.4625","5.9983","Netherlands","NL","NLD","Gelderland","","13715","1528612724"
"Vellakkinar","Vellakkinar","11.0736","76.9566","India","IN","IND","Tamil Nādu","","12899","1356335324"
"Kadena","Kadena","26.3616","127.7553","Japan","JP","JPN","Okinawa","","13301","1392320952"
"Lauriyā Nandangarh","Lauriya Nandangarh","26.9833","84.4000","India","IN","IND","Bihār","","12207","1356535234"
"Knottingley","Knottingley","53.7050","-1.2490","United Kingdom","GB","GBR","Wakefield","","13710","1826742978"
"Mucambo","Mucambo","-3.9089","-40.7469","Brazil","BR","BRA","Ceará","","14102","1076000948"
"Veglie","Veglie","40.3333","17.9667","Italy","IT","ITA","Puglia","","13947","1380574760"
"Galatge","Galatge","16.4200","74.5800","India","IN","IND","Karnātaka","","13638","1356046792"
"Sidi Abdelkarim","Sidi Abdelkarim","33.1869","-7.2333","Morocco","MA","MAR","Casablanca-Settat","","14037","1504411270"
"Grand Falls","Grand Falls","48.9578","-55.6633","Canada","CA","CAN","Newfoundland and Labrador","","14171.00","1124068277"
"Rose Belle","Rose Belle","-20.4025","57.6061","Mauritius","MU","MUS","Grand Port","","12799","1480996186"
"Olgiate Olona","Olgiate Olona","45.6333","8.8833","Italy","IT","ITA","Lombardy","","12444","1380625196"
"Mayrtup","Mayrtup","43.2036","46.1322","Russia","RU","RUS","Chechnya","","13824","1643710855"
"Polukallu","Polukallu","15.8284","77.7431","India","IN","IND","Andhra Pradesh","","13885","1356075184"
"Kerben","Kerben","41.5000","71.7500","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","14141","1417321910"
"Ostrzeszów","Ostrzeszow","51.4167","17.9333","Poland","PL","POL","Wielkopolskie","minor","14036","1616530680"
"Jefferson Valley-Yorktown","Jefferson Valley-Yorktown","41.3180","-73.8008","United States","US","USA","New York","","14168","1840073699"
"Lakoucun","Lakoucun","28.3412","119.9523","China","CN","CHN","Zhejiang","","14016","1156377287"
"Nioaque","Nioaque","-21.1350","-55.8300","Brazil","BR","BRA","Mato Grosso do Sul","","14167","1076087049"
"Turki","Turki","26.0381","85.3577","India","IN","IND","Bihār","","12191","1356210606"
"Eggenfelden","Eggenfelden","48.4039","12.7642","Germany","DE","DEU","Bavaria","","13853","1276543412"
"Ipiranga","Ipiranga","-25.0239","-50.5839","Brazil","BR","BRA","Paraná","","14150","1076445636"
"Jīran","Jiran","24.3087","74.8909","India","IN","IND","","","13890","1356405063"
"San Ferdinando di Puglia","San Ferdinando di Puglia","41.3000","16.0667","Italy","IT","ITA","Puglia","","13828","1380027939"
"Kakunodatemachi","Kakunodatemachi","39.5963","140.5620","Japan","JP","JPN","Akita","","14138","1392000310"
"Náfpaktos","Nafpaktos","38.3939","21.8306","Greece","GR","GRC","Dytikí Elláda","minor","13415","1300883211"
"Lakeland North","Lakeland North","47.3374","-122.2812","United States","US","USA","Washington","","14161","1840074254"
"Arenoso","Arenoso","19.1800","-69.8500","Dominican Republic","DO","DOM","Cibao Nordeste","","14062","1214932555"
"Novoulyanovsk","Novoulyanovsk","54.1667","48.3833","Russia","RU","RUS","Ul’yanovskaya Oblast’","","13990","1643073136"
"Pélézi","Pelezi","7.2833","-6.8333","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","","14100","1384934552"
"Kisanuki","Kisanuki","31.3444","130.9452","Japan","JP","JPN","Kagoshima","","14114","1392204633"
"San Vicente","San Vicente","17.5947","120.3753","Philippines","PH","PHL","Ilocos Sur","","13118","1608946096"
"Uxbridge","Uxbridge","42.0593","-71.6380","United States","US","USA","Massachusetts","","14159","1840053609"
"Arlington","Arlington","35.2594","-89.6680","United States","US","USA","Tennessee","","14158","1840015458"
"Narvik","Narvik","68.4363","17.3983","Norway","NO","NOR","Nordland","minor","14148","1578744025"
"Fara in Sabina","Fara in Sabina","42.2167","12.7333","Italy","IT","ITA","Lazio","","13904","1380110436"
"Kępno","Kepno","51.2833","17.9833","Poland","PL","POL","Wielkopolskie","minor","13951","1616511558"
"Santiago Jocotepec","Santiago Jocotepec","17.5833","-95.8833","Mexico","MX","MEX","Oaxaca","","14135","1484170405"
"Zimnicea","Zimnicea","43.6522","25.3681","Romania","RO","ROU","Teleorman","","14058","1642900319"
"Thong Pha Phum","Thong Pha Phum","14.7382","98.6320","Thailand","TH","THA","Kanchanaburi","minor","14141","1764883114"
"Rāyapalle","Rayapalle","16.2836","81.5024","India","IN","IND","Andhra Pradesh","","13597","1356641882"
"Waianae","Waianae","21.4568","-158.1758","United States","US","USA","Hawaii","","14152","1840029552"
"Lübben (Spreewald)","Lubben (Spreewald)","51.9500","13.9000","Germany","DE","DEU","Brandenburg","minor","14036","1276964065"
"Sonbarsa","Sonbarsa","25.7069","86.7379","India","IN","IND","Bihār","","12731","1356020659"
"Skippack","Skippack","40.2165","-75.4190","United States","US","USA","Pennsylvania","","14151","1840005466"
"Clearview","Clearview","44.3981","-80.0742","Canada","CA","CAN","Ontario","","14151","1124000053"
"Sungai Guntung","Sungai Guntung","0.2956","103.6114","Indonesia","ID","IDN","Riau","","14088","1360986029"
"Aginiparru","Aginiparru","16.6817","80.7839","India","IN","IND","Andhra Pradesh","","13283","1356998058"
"Nelas","Nelas","40.5167","-7.8500","Portugal","PT","PRT","Viseu","minor","14037","1620011198"
"Leverano","Leverano","40.2833","18.0833","Italy","IT","ITA","Puglia","","14145","1380656627"
"Eynesil","Eynesil","41.0500","39.1333","Turkey","TR","TUR","Giresun","minor","13955","1792625951"
"Nesebar","Nesebar","42.6500","27.7333","Bulgaria","BG","BGR","Burgas","minor","14146","1100835570"
"Cheadle","Cheadle","52.9849","-1.9865","United Kingdom","GB","GBR","Staffordshire","","12165","1826424119"
"Rambha","Rambha","19.4433","84.9881","India","IN","IND","Odisha","","14145","1356101896"
"Boutilimit","Boutilimit","17.5504","-14.7000","Mauritania","MR","MRT","Trarza","","14142","1478235368"
"Vetralla","Vetralla","42.3106","12.0792","Italy","IT","ITA","Lazio","","14020","1380933431"
"San José Acatempa","San Jose Acatempa","14.2667","-90.1333","Guatemala","GT","GTM","Jutiapa","minor","13938","1320370299"
"Bhakua","Bhakua","26.5167","86.1974","India","IN","IND","Bihār","","13449","1356119252"
"Gangavalli","Gangavalli","11.4381","78.4836","India","IN","IND","Tamil Nādu","","13803","1356921311"
"Sobrāon","Sobraon","31.1833","74.8500","India","IN","IND","Punjab","","13720","1356542965"
"Chōsei","Chosei","35.4167","140.3500","Japan","JP","JPN","Chiba","","13656","1392003143"
"Netāpur Tānda","Netapur Tanda","16.8321","79.4356","India","IN","IND","Andhra Pradesh","","13897","1356170706"
"Jabbeke","Jabbeke","51.1833","3.1000","Belgium","BE","BEL","Flanders","","13880","1056724213"
"Ovidiu","Ovidiu","44.2700","28.5600","Romania","RO","ROU","Constanţa","","13968","1642244371"
"Debre Werk’","Debre Werk'","10.6667","38.1667","Ethiopia","ET","ETH","Āmara","","13908","1231572143"
"Torre del Campo","Torre del Campo","37.7667","-3.8833","Spain","ES","ESP","Andalusia","","14059","1724707407"
"Vedappatti","Vedappatti","10.9988","76.8960","India","IN","IND","Tamil Nādu","","12846","1356159350"
"Naţanz","Natanz","33.5133","51.9164","Iran","IR","IRN","Eşfahān","minor","14122","1364002460"
"Spaichingen","Spaichingen","48.0758","8.7378","Germany","DE","DEU","Baden-Württemberg","","13407","1276488091"
"Mitontic","Mitontic","16.8667","-92.5667","Mexico","MX","MEX","Chiapas","minor","13755","1484032209"
"Söğütlü","Sogutlu","40.9000","30.4833","Turkey","TR","TUR","Sakarya","minor","13973","1792276598"
"Felixlândia","Felixlandia","-18.7578","-44.8989","Brazil","BR","BRA","Minas Gerais","","14121","1076846673"
"Fokino","Fokino","53.4500","34.4167","Russia","RU","RUS","Bryanskaya Oblast’","","12938","1643057736"
"Fujikawa","Fujikawa","35.5612","138.4613","Japan","JP","JPN","Yamanashi","","14004","1392003228"
"Dundigal","Dundigal","17.5781","78.4288","India","IN","IND","Andhra Pradesh","","13465","1356684448"
"California","California","38.2969","-76.4949","United States","US","USA","Maryland","","14129","1840006228"
"Ricaurte","Ricaurte","-2.8667","-78.9333","Ecuador","EC","ECU","Azuay","","14006","1218488658"
"Niška Banja","Niska Banja","43.2933","22.0061","Serbia","RS","SRB","Niš","minor","14098","1688095113"
"Leuna","Leuna","51.3167","12.0167","Germany","DE","DEU","Saxony-Anhalt","","13969","1276318458"
"Frederick","Frederick","40.1088","-104.9701","United States","US","USA","Colorado","","14127","1840021364"
"Sābang","Sabang","22.1830","87.5990","India","IN","IND","West Bengal","","13224","1356188574"
"Sāgar","Sagar","16.6249","76.8015","India","IN","IND","Karnātaka","","13879","1356170970"
"Somerton","Somerton","32.6007","-114.6994","United States","US","USA","Arizona","","14126","1840021997"
"Rāmpurwā","Rampurwa","26.7544","84.7114","India","IN","IND","Bihār","","12788","1356556355"
"Maria da Fé","Maria da Fe","-22.3078","-45.3750","Brazil","BR","BRA","Minas Gerais","","14056","1076211048"
"Gurinhém","Gurinhem","-7.1239","-35.4239","Brazil","BR","BRA","Paraíba","","14117","1076881947"
"Sher","Sher","26.3422","84.6210","India","IN","IND","Bihār","","12375","1356839857"
"Tamm","Tamm","48.9167","9.1167","Germany","DE","DEU","Baden-Württemberg","","12685","1276203630"
"Bebra","Bebra","50.9711","9.7903","Germany","DE","DEU","Hesse","","13972","1276742603"
"Sülüktü","Suluktu","39.9400","69.5600","Kyrgyzstan","KG","KGZ","Batken","","13378","1417013156"
"Sahalanona","Sahalanona","-22.0500","47.6167","Madagascar","MG","MDG","Fianarantsoa","","14000","1450696602"
"Lukoyanov","Lukoyanov","55.0333","44.4833","Russia","RU","RUS","Nizhegorodskaya Oblast’","","14104","1643123561"
"Balua","Balua","26.3272","87.6231","India","IN","IND","Bihār","","13057","1356806047"
"Tempio Pausania","Tempio Pausania","40.9015","9.1044","Italy","IT","ITA","Sardegna","minor","14052","1380832522"
"Hòa Thượng","Hoa Thuong","21.6472","105.8278","Vietnam","VN","VNM","Thái Nguyên","","13871","1704496519"
"Astolfo Dutra","Astolfo Dutra","-21.3150","-42.8619","Brazil","BR","BRA","Minas Gerais","","14030","1076185639"
"Kaluvāya","Kaluvaya","14.5117","79.4101","India","IN","IND","Andhra Pradesh","","13981","1356719331"
"Lich","Lich","50.5217","8.8208","Germany","DE","DEU","Hesse","","13938","1276029431"
"Saghar Sultānpur","Saghar Sultanpur","26.1583","84.7570","India","IN","IND","Bihār","","12893","1356938462"
"Antanambaobe","Antanambaobe","-16.2500","49.6667","Madagascar","MG","MDG","Toamasina","","14000","1450819233"
"Rio Azul","Rio Azul","-25.7328","-50.7958","Brazil","BR","BRA","Paraná","","14093","1076547411"
"Luling","Luling","29.9008","-90.3523","United States","US","USA","Louisiana","","14115","1840013980"
"Mahatsinjony","Mahatsinjony","-21.4167","47.2000","Madagascar","MG","MDG","Fianarantsoa","","14000","1450141568"
"Tibasosa","Tibasosa","5.8333","-72.9667","Colombia","CO","COL","Boyacá","minor","14063","1170340482"
"East Wenatchee","East Wenatchee","47.4174","-120.2822","United States","US","USA","Washington","","14113","1840019795"
"Elizabethton","Elizabethton","36.3367","-82.2370","United States","US","USA","Tennessee","","14112","1840013314"
"Tola Khadda","Tola Khadda","26.7396","84.5014","India","IN","IND","Bihār","","12522","1356139722"
"Kalkar","Kalkar","51.7389","6.2925","Germany","DE","DEU","North Rhine-Westphalia","","13953","1276383703"
"Alvorada D’Oeste","Alvorada D'Oeste","-11.3417","-62.2861","Brazil","BR","BRA","Rondônia","","14106","1076530041"
"Italva","Italva","-21.4208","-41.6908","Brazil","BR","BRA","Rio de Janeiro","","14063","1076552060"
"Santiago de Anaya","Santiago de Anaya","20.3844","-98.9647","Mexico","MX","MEX","Hidalgo","","14066","1484062040"
"Stroud","Stroud","51.7440","-2.2150","United Kingdom","GB","GBR","Gloucestershire","","13468","1826302127"
"Golub-Dobrzyń","Golub-Dobrzyn","53.1000","19.0500","Poland","PL","POL","Kujawsko-Pomorskie","minor","12546","1616198300"
"El Guetar","El Guetar","34.3372","8.9528","Tunisia","TN","TUN","Gafsa","","14088","1788801519"
"Fatehpur","Fatehpur","26.2813","87.1084","India","IN","IND","Bihār","","13041","1356115572"
"Farmington","Farmington","42.9895","-77.3087","United States","US","USA","New York","","14108","1840058090"
"Rāmpur Shāmchand","Rampur Shamchand","25.5664","85.3289","India","IN","IND","Bihār","","12937","1356906624"
"Mjölby","Mjolby","58.3333","15.1167","Sweden","SE","SWE","Östergötland","minor","13959","1752185099"
"Lomas de Sargentillo","Lomas de Sargentillo","-1.8833","-80.0833","Ecuador","EC","ECU","Guayas","","13775","1218238536"
"Xexéu","Xexeu","-8.8019","-35.6269","Brazil","BR","BRA","Pernambuco","","14093","1076882581"
"Damalcheruvu","Damalcheruvu","13.4833","79.0500","India","IN","IND","Andhra Pradesh","","13722","1356187711"
"Fiadanana","Fiadanana","-20.3333","47.4333","Madagascar","MG","MDG","Fianarantsoa","","14000","1450293178"
"Zazafotsy","Zazafotsy","-22.2000","46.3500","Madagascar","MG","MDG","Fianarantsoa","","14062","1450729734"
"Bamble","Bamble","59.0197","9.5608","Norway","NO","NOR","Vestfold og Telemark","","14056","1578030718"
"Santa Catalina","Santa Catalina","10.6039","-75.2878","Colombia","CO","COL","Bolívar","minor","14039","1170893444"
"Sint-Gillis-bij-Dendermonde","Sint-Gillis-bij-Dendermonde","51.0189","4.1058","Belgium","BE","BEL","Flanders","","12920","1056239926"
"Pelham","Pelham","42.7335","-71.3240","United States","US","USA","New Hampshire","","14099","1840055379"
"Zhipingxiang","Zhipingxiang","35.2949","105.6157","China","CN","CHN","Gansu","","13929","1156880056"
"Aracatu","Aracatu","-14.4278","-41.4619","Brazil","BR","BRA","Bahia","","14089","1076243226"
"Chaparral","Chaparral","32.0442","-106.4060","United States","US","USA","New Mexico","","14098","1840018050"
"Gudikallu","Gudikallu","15.7441","77.4760","India","IN","IND","Andhra Pradesh","","13481","1356038669"
"Villacidro","Villacidro","39.4578","8.7424","Italy","IT","ITA","Sardegna","minor","14019","1380833253"
"Galmi","Galmi","13.9660","5.6750","Niger","NE","NER","Tahoua","","13888","1562080549"
"Ambohimitombo","Ambohimitombo","-20.7167","47.4333","Madagascar","MG","MDG","Fianarantsoa","","14000","1450710090"
"Kivertsi","Kivertsi","50.8331","25.4614","Ukraine","UA","UKR","Volynska Oblast","","14050","1804112302"
"Shitāb Diāra","Shitab Diara","25.7563","84.6133","India","IN","IND","Bihār","","13108","1356138394"
"Gouveia","Gouveia","40.5000","-7.6000","Portugal","PT","PRT","Guarda","minor","14046","1620922007"
"Sarezzo","Sarezzo","45.6500","10.2000","Italy","IT","ITA","Lombardy","","13337","1380756460"
"Mahavelona","Mahavelona","-19.1667","46.5167","Madagascar","MG","MDG","Antananarivo","","14000","1450370479"
"Dryden","Dryden","42.4786","-76.3564","United States","US","USA","New York","","14089","1840004547"
"Mbini","Mbini","1.5833","9.6167","Equatorial Guinea","GQ","GNQ","Litoral","","14034","1226662822"
"St. Clair","St. Clair","42.7833","-82.3500","Canada","CA","CAN","Ontario","","14086","1124000228"
"McFarland","McFarland","35.6781","-119.2413","United States","US","USA","California","","14085","1840020419"
"Adh Dhakhīrah","Adh Dhakhirah","25.7347","51.5475","Qatar","QA","QAT","Al Khawr wa adh Dhakhīrah","","13511","1634525121"
"Petrovske","Petrovske","48.2833","38.8833","Ukraine","UA","UKR","Luhanska Oblast","","13500","1804450037"
"Gondalga","Gondalga","17.5097","80.1594","India","IN","IND","Andhra Pradesh","","13576","1356653197"
"Talwat","Talwat","31.2883","-7.2372","Morocco","MA","MAR","Drâa-Tafilalet","","14060","1504140319"
"Akropong","Akropong","5.9742","-0.0881","Ghana","GH","GHA","Eastern","","13785","1288823605"
"Meckenbeuren","Meckenbeuren","47.7000","9.5625","Germany","DE","DEU","Baden-Württemberg","","13651","1276323112"
"Mignouré","Mignoure","7.4833","-6.7833","Côte d'Ivoire","CI","CIV","Sassandra-Marahoué","","14025","1384735479"
"Livingston","Livingston","37.3875","-120.7248","United States","US","USA","California","","14078","1840020311"
"Bang Racham","Bang Racham","14.9000","100.3167","Thailand","TH","THA","Sing Buri","minor","13937","1764338966"
"Latham","Latham","42.7427","-73.7497","United States","US","USA","New York","","14076","1840033905"
"Moudjbara","Moudjbara","34.5037","3.4704","Algeria","DZ","DZA","Djelfa","","14052","1012667528"
"Kovilūr","Kovilur","12.5537","78.9168","India","IN","IND","Tamil Nādu","","13706","1356053857"
"Frunze","Frunze","40.1267","71.7236","Kyrgyzstan","KG","KGZ","Batken","","14049","1417652388"
"Enghien","Enghien","50.7000","4.0333","Belgium","BE","BEL","Wallonia","minor","13734","1056884890"
"Casatenovo","Casatenovo","45.6983","9.3117","Italy","IT","ITA","Lombardy","","13042","1380592679"
"Andalgalá","Andalgala","-27.6000","-66.3167","Argentina","AR","ARG","Catamarca","minor","14068","1032960533"
"Allouez","Allouez","44.4721","-88.0259","United States","US","USA","Wisconsin","","14072","1840002346"
"Xikou","Xikou","23.5947","120.3948","Taiwan","TW","TWN","Chiayi","","13658","1158767756"
"Ambatomarina","Ambatomarina","-20.5833","47.0167","Madagascar","MG","MDG","Fianarantsoa","","14000","1450490728"
"Marokarima","Marokarima","-21.2167","48.1333","Madagascar","MG","MDG","Fianarantsoa","","14000","1450279342"
"Oberhaching","Oberhaching","48.0167","11.5833","Germany","DE","DEU","Bavaria","","13657","1276103002"
"Montepulciano","Montepulciano","43.1000","11.7833","Italy","IT","ITA","Tuscany","","13984","1380295341"
"Amborondra","Amborondra","-21.9167","47.7667","Madagascar","MG","MDG","Fianarantsoa","","14000","1450089809"
"Schmölln","Schmolln","50.8950","12.3564","Germany","DE","DEU","Thuringia","","13741","1276498002"
"Wauconda","Wauconda","42.2749","-88.1359","United States","US","USA","Illinois","","14068","1840011184"
"Río de Oro","Rio de Oro","8.2917","-73.3872","Colombia","CO","COL","Cesar","minor","14041","1170000449"
"Vanono","Vanono","-16.0333","49.4500","Madagascar","MG","MDG","Toamasina","","14000","1450232049"
"Covington","Covington","33.6049","-83.8463","United States","US","USA","Georgia","","14065","1840014808"
"Wanding","Wanding","24.0833","98.0667","China","CN","CHN","Yunnan","","13906","1156830041"
"Gullapuram","Gullapuram","10.0657","77.6446","India","IN","IND","Tamil Nādu","","13456","1356226040"
"El Carmen de Atrato","El Carmen de Atrato","5.8983","-76.1431","Colombia","CO","COL","Chocó","minor","14049","1170000623"
"Tsarahasina","Tsarahasina","-15.7667","47.5833","Madagascar","MG","MDG","Mahajanga","","14000","1450231941"
"Gekhi","Gekhi","43.1636","45.4725","Russia","RU","RUS","Chechnya","","13836","1643605487"
"Mór","Mor","47.3717","18.2086","Hungary","HU","HUN","Fejér","minor","13936","1348505276"
"Tall Banāt","Tall Banat","36.2550","42.0164","Iraq","IQ","IRQ","Nīnawá","","14000","1368054893"
"Bēylul","Beylul","13.2644","42.3342","Eritrea","ER","ERI","Debubawi K’eyyĭḥ Baḥri","","14055","1232983964"
"Huron","Huron","44.3623","-98.2094","United States","US","USA","South Dakota","","14060","1840002373"
"Hantsavichy","Hantsavichy","52.7500","26.4333","Belarus","BY","BLR","Brestskaya Voblasts’","minor","14043","1112778811"
"Múggia","Muggia","45.6000","13.7667","Italy","IT","ITA","Friuli Venezia Giulia","","13111","1380254049"
"Srīnagar","Srinagar","25.9823","86.6662","India","IN","IND","Bihār","","12167","1356882320"
"Córdoba","Cordoba","0.8550","-77.5211","Colombia","CO","COL","Nariño","minor","14006","1170192500"
"Māndalgarh","Mandalgarh","25.2000","75.1000","India","IN","IND","Rājasthān","","13844","1356060627"
"Antohobe","Antohobe","-19.7667","46.0167","Madagascar","MG","MDG","Antananarivo","","14016","1450847933"
"Chumpak","Chumpak","41.8585","84.1370","China","CN","CHN","Xinjiang","","13983","1156186659"
"Brainerd","Brainerd","46.3553","-94.1983","United States","US","USA","Minnesota","","14053","1840006644"
"New Ulm","New Ulm","44.3120","-94.4685","United States","US","USA","Minnesota","","14052","1840007894"
"Fāmenīn","Famenin","35.1142","48.9717","Iran","IR","IRN","Hamadān","minor","14019","1364228108"
"Kamituga","Kamituga","-3.0600","28.1800","Congo (Kinshasa)","CD","COD","Sud-Kivu","","13995","1180593231"
"Shāndīz","Shandiz","36.3953","59.2964","Iran","IR","IRN","Khorāsān-e Raẕavī","","13987","1364668748"
"Shofirkon Shahri","Shofirkon Shahri","40.1167","64.5000","Uzbekistan","UZ","UZB","Buxoro","minor","14000","1860579364"
"La Entrada","La Entrada","15.0500","-88.7333","Honduras","HN","HND","Copán","","13949","1340911070"
"Itaguaçu","Itaguacu","-19.8019","-40.8558","Brazil","BR","BRA","Espírito Santo","","14023","1076753541"
"Kumirimora","Kumirimora","22.6939","88.2192","India","IN","IND","West Bengal","","12208","1356708218"
"Ambodihara","Ambodihara","-14.7500","48.9833","Madagascar","MG","MDG","Mahajanga","","14000","1450973236"
"Wadlakonda","Wadlakonda","17.7736","79.5750","India","IN","IND","Andhra Pradesh","","13715","1356041600"
"Buchloe","Buchloe","48.0375","10.7250","Germany","DE","DEU","Bavaria","","13668","1276184679"
"Chesterton","Chesterton","41.5997","-87.0550","United States","US","USA","Indiana","","14045","1840009267"
"Lariano","Lariano","41.7333","12.8333","Italy","IT","ITA","Lazio","","13448","1380374934"
"Campina da Lagoa","Campina da Lagoa","-24.5919","-52.7989","Brazil","BR","BRA","Paraná","","14043","1076525992"
"Genthin","Genthin","52.4000","12.1667","Germany","DE","DEU","Saxony-Anhalt","","13985","1276284209"
"Talata-Vohimena","Talata-Vohimena","-20.8500","46.9667","Madagascar","MG","MDG","Fianarantsoa","","14000","1450903316"
"Villalba","Villalba","43.3000","-7.6833","Spain","ES","ESP","Galicia","","14006","1724414757"
"Bealanana","Bealanana","-14.5500","48.7333","Madagascar","MG","MDG","Antsiranana","","14000","1450073773"
"Aßlar","Asslar","50.5833","8.4667","Germany","DE","DEU","Hesse","","13725","1276224828"
"Killai","Killai","11.4493","79.7668","India","IN","IND","Tamil Nādu","","13066","1356235645"
"Havelock North","Havelock North","-39.6667","176.8833","New Zealand","NZ","NZL","Hawke’s Bay","","13950","1554193593"
"Erbach","Erbach","49.6569","8.9931","Germany","DE","DEU","Hesse","minor","13818","1276643067"
"Taiyong","Taiyong","26.4726","108.5105","China","CN","CHN","Guizhou","","13987","1156078399"
"Itamonte","Itamonte","-22.2839","-44.8700","Brazil","BR","BRA","Minas Gerais","","14003","1076596384"
"Solhan","Solhan","38.9681","41.0536","Turkey","TR","TUR","Bingöl","minor","14005","1792668752"
"Wood Dale","Wood Dale","41.9668","-87.9808","United States","US","USA","Illinois","","14034","1840010167"
"Biscarrosse","Biscarrosse","44.3931","-1.1639","France","FR","FRA","Nouvelle-Aquitaine","","13947","1250732906"
"Devipattinam","Devipattinam","9.4770","78.8961","India","IN","IND","Tamil Nādu","","13419","1356744974"
"Skoczów","Skoczow","49.8006","18.7883","Poland","PL","POL","Śląskie","","13814","1616973602"
"College Park","College Park","33.6363","-84.4640","United States","US","USA","Georgia","","14032","1840014737"
"Ambohimiera","Ambohimiera","-21.0500","47.5000","Madagascar","MG","MDG","Fianarantsoa","","13983","1450697311"
"Donji Vakuf","Donji Vakuf","44.1500","17.4000","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","13985","1070953048"
"Alexandria","Alexandria","45.8776","-95.3767","United States","US","USA","Minnesota","","14030","1840006680"
"Iraci","Iraci","-8.8078","-35.9519","Brazil","BR","BRA","Pernambuco","","13941","1076000903"
"Jacksonville","Jacksonville","31.9642","-95.2617","United States","US","USA","Texas","","14029","1840019515"
"Beraketa","Beraketa","-24.1833","45.6833","Madagascar","MG","MDG","Toliara","","14000","1450431295"
"Ambahita","Ambahita","-24.0000","45.2667","Madagascar","MG","MDG","Toliara","","14000","1450954940"
"Kalandy","Kalandy","-15.7500","48.7333","Madagascar","MG","MDG","Mahajanga","","14000","1450827177"
"Vieste","Vieste","41.8833","16.1667","Italy","IT","ITA","Puglia","","13943","1380156505"
"Bevato","Bevato","-18.6833","46.2500","Madagascar","MG","MDG","Antananarivo","","14000","1450827914"
"Mahajamba","Mahajamba","-15.7000","47.1000","Madagascar","MG","MDG","Mahajanga","","14000","1450063476"
"Sharonville","Sharonville","39.2825","-84.4071","United States","US","USA","Ohio","","14022","1840001613"
"Saint-Gilles","Saint-Gilles","43.6778","4.4311","France","FR","FRA","Occitanie","","13931","1250752437"
"Jānapādu","Janapadu","16.4617","79.8699","India","IN","IND","Andhra Pradesh","","13533","1356780398"
"Thala","Thala","35.5667","8.6667","Tunisia","TN","TUN","Kasserine","","13968","1788886917"
"Tierra Amarilla","Tierra Amarilla","-27.4822","-70.2658","Chile","CL","CHL","Atacama","","14019","1152576362"
"Batuan","Batuan","9.8000","124.1333","Philippines","PH","PHL","Bohol","","13845","1608180055"
"Boechout","Boechout","51.1500","4.4833","Belgium","BE","BEL","Flanders","","13372","1056225265"
"Ankiliabo","Ankiliabo","-21.7000","43.9000","Madagascar","MG","MDG","Toliara","","14000","1450311896"
"Mirdaul","Mirdaul","26.2363","87.0971","India","IN","IND","Bihār","","12951","1356003414"
"Taphan Hin","Taphan Hin","16.2108","100.4188","Thailand","TH","THA","Phichit","minor","13868","1764640959"
"Ban Khlong","Ban Khlong","16.8353","100.2320","Thailand","TH","THA","Phitsanulok","","13560","1764509673"
"Montesarchio","Montesarchio","41.0667","14.6333","Italy","IT","ITA","Campania","","13508","1380707425"
"Sale","Sale","-38.1000","147.0667","Australia","AU","AUS","Victoria","","13673","1036960753"
"San Bartolo","San Bartolo","15.0844","-91.4558","Guatemala","GT","GTM","Totonicapán","minor","12459","1320306258"
"Teploklyuchenka","Teploklyuchenka","42.5000","78.5250","Kyrgyzstan","KG","KGZ","Ysyk-Köl","minor","14009","1417699860"
"Landau an der Isar","Landau an der Isar","48.6667","12.6667","Germany","DE","DEU","Bavaria","","13852","1276797945"
"Taishi","Taishi","34.5187","135.6476","Japan","JP","JPN","Ōsaka","","13092","1392283998"
"Marotsiraka","Marotsiraka","-24.2833","45.9833","Madagascar","MG","MDG","Toliara","","14000","1450433936"
"Zeven","Zeven","53.3000","9.2833","Germany","DE","DEU","Lower Saxony","","13828","1276616913"
"Oostakker","Oostakker","51.1000","3.7667","Belgium","BE","BEL","Flanders","","12793","1056882866"
"Erbach","Erbach","48.3281","9.8878","Germany","DE","DEU","Baden-Württemberg","","13797","1276634303"
"San Rafael Las Flores","San Rafael Las Flores","14.4814","-90.1733","Guatemala","GT","GTM","Santa Rosa","minor","13850","1320867057"
"Vatolatsaka","Vatolatsaka","-23.3000","44.3000","Madagascar","MG","MDG","Toliara","","14000","1450731231"
"Statte","Statte","40.5667","17.2000","Italy","IT","ITA","Puglia","","13808","1380083106"
"Forest City","Forest City","28.6619","-81.4444","United States","US","USA","Florida","","14011","1840028825"
"San Pedro Pochutla","San Pedro Pochutla","15.7476","-96.4666","Mexico","MX","MEX","Oaxaca","minor","13685","1484815091"
"Woudenberg","Woudenberg","52.0833","5.4167","Netherlands","NL","NLD","Utrecht","minor","13639","1528766382"
"Poço Branco","Poco Branco","-5.6228","-35.6628","Brazil","BR","BRA","Rio Grande do Norte","","13949","1076085292"
"Miarinarivo","Miarinarivo","-16.6167","48.2500","Madagascar","MG","MDG","Toamasina","","14000","1450574333"
"Dunmore","Dunmore","41.4152","-75.6072","United States","US","USA","Pennsylvania","","14009","1840003381"
"Bekipay","Bekipay","-16.2500","46.1333","Madagascar","MG","MDG","Mahajanga","","14000","1450117746"
"Bhikkiwind Uttār","Bhikkiwind Uttar","31.3400","74.7000","India","IN","IND","Punjab","","13586","1356788831"
"Chityāl","Chityal","17.2333","79.1333","India","IN","IND","Andhra Pradesh","","13752","1356091931"
"Nachikatsuura","Nachikatsuura","33.6261","135.9411","Japan","JP","JPN","Wakayama","","13930","1392003087"
"Beharona","Beharona","-21.5167","44.3000","Madagascar","MG","MDG","Toliara","","14000","1450572601"
"Hilpoltstein","Hilpoltstein","49.1833","11.1833","Germany","DE","DEU","Bavaria","","13852","1276036036"
"Guapó","Guapo","-16.8308","-49.5319","Brazil","BR","BRA","Goiás","","13976","1076785734"
"Marudūr","Marudur","10.9160","78.4509","India","IN","IND","Tamil Nādu","","13482","1356222787"
"Sonupur","Sonupur","25.8000","86.0253","India","IN","IND","Bihār","","12105","1356566802"
"Rafaï","Rafai","4.9731","23.9319","Central African Republic","CF","CAF","Mbomou","","14000","1140732903"
"Kurtkoti","Kurtkoti","15.3681","75.5239","India","IN","IND","Karnātaka","","13552","1356640663"
"Dhāna","Dhana","23.7470","78.8623","India","IN","IND","Madhya Pradesh","","13561","1356586928"
"Misano Adriatico","Misano Adriatico","43.9667","12.7000","Italy","IT","ITA","Emilia-Romagna","","13400","1380200354"
"Huangyadong","Huangyadong","36.8039","113.4446","China","CN","CHN","Shanxi","","13857","1156154833"
"Weißenhorn","Weissenhorn","48.3000","10.1667","Germany","DE","DEU","Bavaria","","13743","1276798632"
"Dubovka","Dubovka","49.0500","44.8333","Russia","RU","RUS","Volgogradskaya Oblast’","","13988","1643833054"
"Gorbea","Gorbea","-39.1000","-72.6833","Chile","CL","CHL","Araucanía","","13990","1152299665"
"Çaybaşı","Caybasi","41.0333","37.1000","Turkey","TR","TUR","Ordu","minor","13922","1792204529"
"Midleton","Midleton","51.9160","-8.1750","Ireland","IE","IRL","Cork","","12496","1372938716"
"Khe Sanh","Khe Sanh","16.6193","106.7305","Vietnam","VN","VNM","Quảng Trị","minor","13927","1704133278"
"Bairia","Bairia","26.7373","84.4371","India","IN","IND","Bihār","","12425","1356404618"
"Tako","Tako","35.7356","140.4677","Japan","JP","JPN","Chiba","","13804","1392986792"
"Titara","Titara","26.2311","84.2444","India","IN","IND","Bihār","","12421","1356107458"
"Kuraymah","Kuraymah","18.5500","31.8500","Sudan","SD","SDN","Northern","","13981","1729460308"
"Canmore","Canmore","51.0890","-115.3590","Canada","CA","CAN","Alberta","","13992","1124688642"
"Kadoli","Kadoli","15.8800","74.5600","India","IN","IND","Karnātaka","","13000","1356013157"
"Lauterbach","Lauterbach","50.6378","9.3944","Germany","DE","DEU","Hesse","minor","13852","1276435243"
"Kathevaram","Kathevaram","16.2608","80.6368","India","IN","IND","Andhra Pradesh","","13209","1356570574"
"Nehrəm","Nehram","39.1122","45.4611","Azerbaijan","AZ","AZE","Babək","","13900","1031344559"
"Mahuākherāganj","Mahuakheraganj","29.1300","78.9200","India","IN","IND","Uttar Pradesh","","12584","1356504467"
"Boyovut","Boyovut","40.2822","69.0183","Uzbekistan","UZ","UZB","Sirdaryo","","13800","1860318919"
"Dueville","Dueville","45.6333","11.5500","Italy","IT","ITA","Veneto","","13804","1380919954"
"Altınoluk","Altinoluk","39.5823","26.7394","Turkey","TR","TUR","Balıkesir","minor","13800","1792463047"
"Philippsburg","Philippsburg","49.2333","8.4500","Germany","DE","DEU","Baden-Württemberg","","13713","1276006660"
"Beesel","Beesel","51.2833","6.0333","Netherlands","NL","NLD","Limburg","","13519","1528749686"
"Lyndhurst","Lyndhurst","41.5172","-81.4922","United States","US","USA","Ohio","","13980","1840000610"
"Aleksandrów Kujawski","Aleksandrow Kujawski","52.8767","18.6936","Poland","PL","POL","Kujawsko-Pomorskie","minor","12359","1616651073"
"Dhutauli","Dhutauli","25.5274","86.6250","India","IN","IND","Bihār","","12855","1356933760"
"Chodov","Chodov","50.2414","12.7439","Czechia","CZ","CZE","Karlovarský Kraj","","13089","1203758078"
"Alfredo Chaves","Alfredo Chaves","-20.6350","-40.7500","Brazil","BR","BRA","Espírito Santo","","13955","1076666348"
"Bolívar","Bolivar","4.3386","-76.1847","Colombia","CO","COL","Valle del Cauca","minor","13954","1170438450"
"Alcanena","Alcanena","39.4667","-8.6667","Portugal","PT","PRT","Santarém","minor","13868","1620799086"
"Wanzleben","Wanzleben","52.0667","11.4333","Germany","DE","DEU","Saxony-Anhalt","","13903","1276538835"
"Bandar-e ‘Asalūyeh","Bandar-e \`Asaluyeh","27.4744","52.6114","Iran","IR","IRN","Būshehr","minor","13557","1364542336"
"Baduriātola","Baduriatola","24.0928","84.0895","India","IN","IND","Jhārkhand","","12990","1356511216"
"Sterling","Sterling","40.6205","-103.1925","United States","US","USA","Colorado","","13976","1840021377"
"East Norriton","East Norriton","40.1506","-75.3364","United States","US","USA","Pennsylvania","","13976","1840133023"
"Berre-l’Étang","Berre-l'Etang","43.4756","5.1681","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13660","1250267731"
"Polavaram","Polavaram","17.2500","81.6333","India","IN","IND","Andhra Pradesh","","13861","1356054535"
"East Greenwich","East Greenwich","41.6362","-71.5058","United States","US","USA","Rhode Island","","13970","1840066133"
"Pacaembu","Pacaembu","-21.5622","-51.2606","Brazil","BR","BRA","São Paulo","","13961","1076580275"
"Fergus Falls","Fergus Falls","46.2854","-96.0758","United States","US","USA","Minnesota","","13969","1840007747"
"Vilanova del Camí","Vilanova del Cami","41.5733","1.6381","Spain","ES","ESP","Catalonia","","12736","1724654906"
"Noyon","Noyon","49.5811","2.9989","France","FR","FRA","Hauts-de-France","","13235","1250032325"
"Salaverry","Salaverry","-8.2214","-78.9764","Peru","PE","PER","La Libertad","","13892","1604741233"
"Kiskőrös","Kiskoros","46.6204","19.2838","Hungary","HU","HUN","Bács-Kiskun","minor","13833","1348641106"
"Topliţa","Toplita","46.9236","25.3458","Romania","RO","ROU","Harghita","","13929","1642037502"
"Primeira Cruz","Primeira Cruz","-2.5100","-43.4378","Brazil","BR","BRA","Maranhão","","13954","1076394129"
"Hobe Sound","Hobe Sound","27.0729","-80.1425","United States","US","USA","Florida","","13964","1840014188"
"San Agustín de Guadalix","San Agustin de Guadalix","40.6781","-3.6150","Spain","ES","ESP","Madrid","","13608","1724232823"
"Petrolândia","Petrolandia","-9.1828","-38.2689","Brazil","BR","BRA","Pernambuco","","13963","1076000765"
"Spiesen-Elversberg","Spiesen-Elversberg","49.3167","7.1331","Germany","DE","DEU","Saarland","","12850","1276068112"
"Anapurus","Anapurus","-3.6719","-43.1158","Brazil","BR","BRA","Maranhão","","13939","1076087687"
"Jhaua","Jhaua","25.6250","84.5161","India","IN","IND","Bihār","","12955","1356368167"
"Athens","Athens","35.4573","-84.6045","United States","US","USA","Tennessee","","13961","1840013429"
"Palkūr","Palkur","15.4144","78.2507","India","IN","IND","Andhra Pradesh","","13733","1356704289"
"Vedi","Vedi","39.9106","44.7278","Armenia","AM","ARM","Ararat","","13600","1051497874"
"Sesquilé","Sesquile","5.0453","-73.7972","Colombia","CO","COL","Cundinamarca","minor","13936","1170884868"
"Antonivka","Antonivka","46.6791","32.7228","Ukraine","UA","UKR","Khersonska Oblast","","12812","1804356221"
"Sugauna","Sugauna","26.4077","86.2174","India","IN","IND","Bihār","","12842","1356098448"
"Willow Grove","Willow Grove","40.1469","-75.1174","United States","US","USA","Pennsylvania","","13958","1840005471"
"Nynäshamn","Nynashamn","58.9000","17.9500","Sweden","SE","SWE","Stockholm","minor","13510","1752209871"
"Novellara","Novellara","44.8500","10.7333","Italy","IT","ITA","Emilia-Romagna","","13721","1380114632"
"Red Oak","Red Oak","32.5212","-96.7866","United States","US","USA","Texas","","13956","1840020761"
"Tapilula","Tapilula","17.2500","-93.0000","Mexico","MX","MEX","Chiapas","minor","13592","1484306142"
"Polotitlán de la Ilustración","Polotitlan de la Ilustracion","20.2231","-99.8147","Mexico","MX","MEX","México","minor","13851","1484418357"
"Panapākkam","Panapakkam","12.9210","79.5663","India","IN","IND","Tamil Nādu","","13399","1356621794"
"Fagnano Olona","Fagnano Olona","45.6667","8.8667","Italy","IT","ITA","Lombardy","","12510","1380853819"
"Tucacas","Tucacas","10.7978","-68.3175","Venezuela","VE","VEN","Falcón","minor","13901","1862663213"
"Ping’anbao","Ping'anbao","40.4901","117.5973","China","CN","CHN","Hebei","","13870","1156996995"
"Binkolo","Binkolo","8.9500","-11.9833","Sierra Leone","SL","SLE","Northern","","13867","1694588159"
"Badarwās","Badarwas","24.9752","77.5649","India","IN","IND","Madhya Pradesh","","13739","1356131576"
"Bahādurpur","Bahadurpur","25.4522","85.6303","India","IN","IND","Bihār","","12739","1356197437"
"Velānganni","Velanganni","10.6814","79.8508","India","IN","IND","Tamil Nādu","","13399","1356548924"
"Curiúva","Curiuva","-24.0328","-50.4578","Brazil","BR","BRA","Paraná","","13923","1076113763"
"Gömeç","Gomec","39.3911","26.8400","Turkey","TR","TUR","Balıkesir","minor","13894","1792446908"
"St. James","St. James","40.8761","-73.1521","United States","US","USA","New York","","13945","1840005103"
"Wakasa","Wakasa","35.5489","135.9082","Japan","JP","JPN","Fukui","","13867","1392003432"
"Champādānga","Champadanga","22.8275","87.9844","India","IN","IND","West Bengal","","12518","1356101477"
"Bheja","Bheja","26.1046","86.3950","India","IN","IND","Bihār","","12471","1356853271"
"Chợ Mới","Cho Moi","10.5500","105.4000","Vietnam","VN","VNM","An Giang","minor","12898","1704969130"
"Olean","Olean","42.0819","-78.4321","United States","US","USA","New York","","13941","1840000452"
"Fujimi","Fujimi","35.9146","138.2407","Japan","JP","JPN","Nagano","","13844","1392003258"
"Maracaí","Maracai","-22.6106","-50.6672","Brazil","BR","BRA","São Paulo","","13913","1076001252"
"Hidalgo","Hidalgo","26.1090","-98.2464","United States","US","USA","Texas","","13939","1840021023"
"Moralzarzal","Moralzarzal","40.6750","-3.9694","Spain","ES","ESP","Madrid","","13905","1724782308"
"Pirallahı","Pirallahi","40.4708","50.3217","Azerbaijan","AZ","AZE","Bakı","","13583","1031058715"
"Zhovkva","Zhovkva","50.0667","23.9667","Ukraine","UA","UKR","Lvivska Oblast","","13852","1804176070"
"Aurora","Aurora","42.7382","-78.6373","United States","US","USA","New York","","13936","1840150816"
"Marmeleiro","Marmeleiro","-26.1489","-53.0258","Brazil","BR","BRA","Paraná","","13900","1076581635"
"Lorsch","Lorsch","49.6539","8.5675","Germany","DE","DEU","Hesse","","13930","1276723392"
"Šamorín","Samorin","48.0267","17.3117","Slovakia","SK","SVK","Trnava","","13628","1703883370"
"Brejo do Cruz","Brejo do Cruz","-6.3489","-37.4978","Brazil","BR","BRA","Paraíba","","13900","1076202028"
"Oulad Chikh","Oulad Chikh","32.8544","-7.5386","Morocco","MA","MAR","Casablanca-Settat","","13866","1504262708"
"Toyloq Qishlog’i","Toyloq Qishlog'i","39.6014","67.0908","Uzbekistan","UZ","UZB","Samarqand","minor","13700","1860817167"
"Moberly","Moberly","39.4179","-92.4364","United States","US","USA","Missouri","","13933","1840008463"
"Circleville","Circleville","39.6063","-82.9334","United States","US","USA","Ohio","","13933","1840007317"
"Kalādgi","Kaladgi","16.2040","75.5000","India","IN","IND","Karnātaka","","13676","1356448614"
"Gien","Gien","47.6981","2.6250","France","FR","FRA","Centre-Val de Loire","","13732","1250883884"
"Urupês","Urupes","-21.2019","-49.2900","Brazil","BR","BRA","São Paulo","","13888","1076048342"
"Newhaven","Newhaven","50.8000","0.0600","United Kingdom","GB","GBR","East Sussex","","12232","1826390746"
"Manor","Manor","30.3562","-97.5227","United States","US","USA","Texas","","13928","1840020890"
"Bihārīganj","Bihariganj","25.7341","86.9884","India","IN","IND","Bihār","","12843","1356877215"
"Puxinanã","Puxinana","-7.1608","-35.9608","Brazil","BR","BRA","Paraíba","","13741","1076989636"
"Paikpar","Paikpar","26.0966","87.1349","India","IN","IND","Bihār","","12299","1356504894"
"Kamtaul","Kamtaul","26.3280","85.8238","India","IN","IND","Bihār","","12386","1356328118"
"Kamigōri","Kamigori","34.8736","134.3561","Japan","JP","JPN","Hyōgo","","13835","1392000469"
"Barghāt","Barghat","22.0306","79.7328","India","IN","IND","Madhya Pradesh","","13742","1356547277"
"Diego de Almagro","Diego de Almagro","-26.3911","-70.0458","Chile","CL","CHL","Atacama","","13925","1152585849"
"Kettering","Kettering","38.8888","-76.7889","United States","US","USA","Maryland","","13924","1840005958"
"Llanera","Llanera","43.4667","-5.9333","Spain","ES","ESP","Asturias","","13792","1724716854"
"Pitlam","Pitlam","18.2227","77.8238","India","IN","IND","Andhra Pradesh","","13631","1356247517"
"Shepherdsville","Shepherdsville","37.9813","-85.7007","United States","US","USA","Kentucky","","13918","1840015214"
"San Prisco","San Prisco","41.0833","14.2833","Italy","IT","ITA","Campania","","12333","1380378777"
"McPherson","McPherson","38.3714","-97.6605","United States","US","USA","Kansas","","13916","1840001659"
"Bayt Sāḩūr","Bayt Sahur","31.7000","35.2167","West Bank","XW","XWB","","","13281","1934945594"
"Maumee","Maumee","41.5696","-83.6636","United States","US","USA","Ohio","","13915","1840000569"
"Karaund","Karaund","25.9741","86.2343","India","IN","IND","Bihār","","11957","1356190947"
"Krumbach","Krumbach","48.2500","10.3667","Germany","DE","DEU","Bavaria","","13610","1276275014"
"Littleborough","Littleborough","53.6440","-2.0980","United Kingdom","GB","GBR","Rochdale","","13807","1826853866"
"New Franklin","New Franklin","40.9525","-81.5838","United States","US","USA","Ohio","","13913","1840008255"
"Arbaa Laaounate","Arbaa Laaounate","32.7446","-8.1942","Morocco","MA","MAR","Casablanca-Settat","","13793","1504711140"
"Bergen","Bergen","54.4167","13.4333","Germany","DE","DEU","Mecklenburg-Western Pomerania","","13647","1276083334"
"Melsungen","Melsungen","51.1333","9.5500","Germany","DE","DEU","Hesse","","13695","1276369572"
"Sun Lakes","Sun Lakes","33.2172","-111.8695","United States","US","USA","Arizona","","13912","1840019313"
"Engenheiro Beltrão","Engenheiro Beltrao","-23.7969","-52.2689","Brazil","BR","BRA","Paraná","","13906","1076388802"
"Strada","Strada","43.5833","11.3167","Italy","IT","ITA","Tuscany","","13829","1380687962"
"Nāgāwaram","Nagawaram","17.4875","78.6035","India","IN","IND","Andhra Pradesh","","12006","1356076830"
"Frei Paulo","Frei Paulo","-10.5489","-37.5339","Brazil","BR","BRA","Sergipe","","13874","1076780736"
"Tomiño","Tomino","41.9833","-8.7167","Spain","ES","ESP","Galicia","","13779","1724945871"
"Kodriva","Kodriva","22.5342","75.7725","India","IN","IND","Madhya Pradesh","","13507","1356585361"
"Qiblaí","Qiblai","38.6167","68.8333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","13860","1762560534"
"Khmis Sidi al ’Aydi","Khmis Sidi al 'Aydi","33.1228","-7.6219","Morocco","MA","MAR","Casablanca-Settat","","13839","1504059482"
"Pīrnagar","Pirnagar","25.5982","86.7178","India","IN","IND","Bihār","","12712","1356851216"
"Hājan","Hajan","34.2989","74.6168","India","IN","IND","Jammu and Kashmīr","","13163","1356406781"
"Chaoyangdicun","Chaoyangdicun","42.0221","118.2178","China","CN","CHN","Hebei","","13773","1156452468"
"Hīdaj","Hidaj","36.2547","49.1311","Iran","IR","IRN","Zanjān","","13840","1364864435"
"Plouzané","Plouzane","48.3800","-4.6006","France","FR","FRA","Bretagne","","13496","1250955231"
"Jālihalli","Jalihalli","16.3643","76.7813","India","IN","IND","Karnātaka","","13702","1356088739"
"San Antonio de las Vueltas","San Antonio de las Vueltas","22.5167","-79.7021","Cuba","CU","CUB","Villa Clara","","13805","1192073576"
"Avanhandava","Avanhandava","-21.4608","-49.9497","Brazil","BR","BRA","São Paulo","","13859","1076708682"
"Turki","Turki","26.0294","86.0666","India","IN","IND","Bihār","","12532","1356651804"
"Panjgirāin Kalān","Panjgirain Kalan","30.6096","74.9143","India","IN","IND","Punjab","","13464","1356812094"
"Waycross","Waycross","31.2108","-82.3579","United States","US","USA","Georgia","","13898","1840015871"
"Bodagudipādu","Bodagudipadu","14.7669","79.9797","India","IN","IND","Andhra Pradesh","","13733","1356670589"
"Ivanec","Ivanec","46.2264","16.1253","Croatia","HR","HRV","Varaždinska Županija","minor","13758","1191135840"
"Ipanguaçu","Ipanguacu","-5.4978","-36.8550","Brazil","BR","BRA","Rio Grande do Norte","","13856","1076285175"
"Douar Lehgagcha","Douar Lehgagcha","32.5500","-8.7167","Morocco","MA","MAR","Casablanca-Settat","","13748","1504017254"
"Lanuvio","Lanuvio","41.6833","12.7000","Italy","IT","ITA","Lazio","","13580","1380932306"
"Tummalapenta","Tummalapenta","15.0278","78.0234","India","IN","IND","Andhra Pradesh","","13708","1356234962"
"Cavarzere","Cavarzere","45.1370","12.0817","Italy","IT","ITA","Veneto","","13791","1380003044"
"Higashimiyoshi","Higashimiyoshi","34.0368","133.9369","Japan","JP","JPN","Tokushima","","13776","1392003475"
"Zhashkiv","Zhashkiv","49.2500","30.1000","Ukraine","UA","UKR","Cherkaska Oblast","","13853","1804691013"
"North Battleford","North Battleford","52.7575","-108.2861","Canada","CA","CAN","Saskatchewan","","13888","1124789635"
"Guapé","Guape","-20.7619","-45.9178","Brazil","BR","BRA","Minas Gerais","","13872","1076767151"
"Corupá","Corupa","-26.4250","-49.2428","Brazil","BR","BRA","Santa Catarina","","13852","1076196507"
"Jutaí","Jutai","-2.7469","-66.7669","Brazil","BR","BRA","Amazonas","","13886","1076835139"
"Kauhajoki","Kauhajoki","62.4319","22.1794","Finland","FI","FIN","Etelä-Pohjanmaa","minor","13875","1246101648"
"Gura Humorului","Gura Humorului","47.5539","25.8892","Romania","RO","ROU","Suceava","","13667","1642117970"
"Wanze","Wanze","50.5353","5.2133","Belgium","BE","BEL","Wallonia","","13576","1056151767"
"Dongshicuo","Dongshicuo","23.7021","120.2556","Taiwan","TW","TWN","Yunlin","","13601","1158664791"
"Hatoyama","Hatoyama","35.9815","139.3341","Japan","JP","JPN","Saitama","","13365","1392003229"
"Paruchūru","Paruchuru","15.9667","80.2667","India","IN","IND","Andhra Pradesh","","13375","1356304321"
"Wommelgem","Wommelgem","51.2000","4.5167","Belgium","BE","BEL","Flanders","","12893","1056798866"
"Luozi","Luozi","-4.9480","14.1330","Congo (Kinshasa)","CD","COD","Kongo Central","","13855","1180949635"
"Pembroke","Pembroke","45.8167","-77.1000","Canada","CA","CAN","Ontario","","13882","1124877940"
"Jinmingsi","Jinmingsi","38.0512","110.2869","China","CN","CHN","Shaanxi","","13780","1156738342"
"Ettenheim","Ettenheim","48.2556","7.8119","Germany","DE","DEU","Baden-Württemberg","","13603","1276445594"
"Avenal","Avenal","36.0311","-120.1162","United States","US","USA","California","","13881","1840019054"
"Miami Springs","Miami Springs","25.8195","-80.2894","United States","US","USA","Florida","","13880","1840015152"
"Clemencia","Clemencia","10.5833","-75.3333","Colombia","CO","COL","Bolívar","minor","13821","1170519066"
"Powell","Powell","40.1689","-83.0829","United States","US","USA","Ohio","","13879","1840009430"
"Guamal","Guamal","3.8800","-73.7656","Colombia","CO","COL","Meta","minor","13857","1170121080"
"Arroio dos Ratos","Arroio dos Ratos","-30.0769","-51.7289","Brazil","BR","BRA","Rio Grande do Sul","","13606","1076770923"
"Santo Augusto","Santo Augusto","-27.8508","-53.7769","Brazil","BR","BRA","Rio Grande do Sul","","13848","1076594342"
"Casinhas","Casinhas","-7.7411","-35.7211","Brazil","BR","BRA","Pernambuco","","13766","1076512375"
"Găeşti","Gaesti","44.7194","25.3197","Romania","RO","ROU","Dâmboviţa","","13317","1642744335"
"Lower Moreland","Lower Moreland","40.1346","-75.0542","United States","US","USA","Pennsylvania","","13868","1840142130"
"Zhongliao","Zhongliao","23.9039","120.7819","Taiwan","TW","TWN","Nantou","","13774","1158905637"
"Haßfurt","Hassfurt","50.0167","10.5000","Germany","DE","DEU","Bavaria","minor","13609","1276364933"
"Toulal","Toulal","32.3036","-3.9861","Morocco","MA","MAR","Drâa-Tafilalet","","13852","1504925224"
"Zábřeh","Zabreh","49.8826","16.8723","Czechia","CZ","CZE","Olomoucký Kraj","","13479","1203721653"
"Mangalāpuram","Mangalapuram","11.5667","78.3788","India","IN","IND","Tamil Nādu","","13337","1356224150"
"Cabo Verde","Cabo Verde","-21.4719","-46.3958","Brazil","BR","BRA","Minas Gerais","","13823","1076433719"
"Sant’Angelo Lodigiano","Sant'Angelo Lodigiano","45.2389","9.4097","Italy","IT","ITA","Lombardy","","13202","1380951177"
"Oosterzele","Oosterzele","50.9500","3.8000","Belgium","BE","BEL","Flanders","","13546","1056179626"
"Canals","Canals","38.9611","-0.5850","Spain","ES","ESP","Valencia","","13257","1724671799"
"Wayland","Wayland","42.3586","-71.3594","United States","US","USA","Massachusetts","","13859","1840053492"
"Helensburgh","Helensburgh","56.0166","-4.7333","United Kingdom","GB","GBR","Argyll and Bute","","13640","1826378273"
"Hammam el Rhezez","Hammam el Rhezez","36.8900","11.1200","Tunisia","TN","TUN","Nabeul","","13634","1788114133"
"Powell","Powell","36.0358","-84.0296","United States","US","USA","Tennessee","","13855","1840036085"
"Ban Tha Luang Lang","Ban Tha Luang Lang","12.6376","102.0884","Thailand","TH","THA","Chanthaburi","","13785","1764285638"
"Alamo","Alamo","37.8548","-122.0136","United States","US","USA","California","","13852","1840017609"
"Arcozelo","Arcozelo","41.0555","-8.6395","Portugal","PT","PRT","Porto","","12393","1620153472"
"Dhubaria","Dhubaria","24.0044","89.8484","Bangladesh","BD","BGD","Dhaka","","12760","1050353483"
"Couvin","Couvin","50.0500","4.4833","Belgium","BE","BEL","Wallonia","","13782","1056064147"
"Margraten","Margraten","50.8167","5.8167","Netherlands","NL","NLD","Limburg","","13370","1528721027"
"Grünberg","Grunberg","50.6000","8.9500","Germany","DE","DEU","Hesse","","13694","1276207612"
"West Lealman","West Lealman","27.8192","-82.7384","United States","US","USA","Florida","","13847","1840039134"
"Konāje","Konaje","12.8162","74.9021","India","IN","IND","Karnātaka","","11951","1356077319"
"San Pietro Vernotico","San Pietro Vernotico","40.4833","18.0500","Italy","IT","ITA","Puglia","","13556","1380961722"
"Ajjampur","Ajjampur","13.7279","76.0068","India","IN","IND","Karnātaka","","13654","1356451205"
"Uruana","Uruana","-15.4978","-49.6878","Brazil","BR","BRA","Goiás","","13818","1076128306"
"Plumstead","Plumstead","40.3878","-75.1164","United States","US","USA","Pennsylvania","","13843","1840152882"
"Horodyshche","Horodyshche","49.2925","31.4581","Ukraine","UA","UKR","Cherkaska Oblast","minor","13799","1804360634"
"Sirugamani","Sirugamani","10.8975","78.5142","India","IN","IND","Tamil Nādu","","13192","1356225001"
"San Juan","San Juan","10.6500","-61.4500","Trinidad and Tobago","TT","TTO","San Juan/Laventille","","13797","1780554728"
"Tiruppālai","Tiruppalai","9.9825","78.1430","India","IN","IND","Tamil Nādu","","12477","1356231836"
"Pilich","Pilich","25.2379","85.3483","India","IN","IND","Bihār","","12550","1356105629"
"Apostolove","Apostolove","47.6595","33.7170","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","13792","1804423281"
"Forlimpopoli","Forlimpopoli","44.1833","12.1333","Italy","IT","ITA","Emilia-Romagna","","13294","1380469474"
"Peri-Mirim","Peri-Mirim","-2.5778","-44.8539","Brazil","BR","BRA","Maranhão","","13803","1076167021"
"Wells Branch","Wells Branch","30.4433","-97.6790","United States","US","USA","Texas","","13837","1840019588"
"Dammennu","Dammennu","17.0308","81.6861","India","IN","IND","Andhra Pradesh","","13000","1356047567"
"Joaquim Pires","Joaquim Pires","-3.5078","-42.1978","Brazil","BR","BRA","Piauí","","13817","1076601550"
"Machados","Machados","-7.6858","-35.5150","Brazil","BR","BRA","Pernambuco","","13596","1076004279"
"Latisana","Latisana","45.7833","13.1167","Italy","IT","ITA","Friuli Venezia Giulia","","13478","1380553278"
"Ampasinambo","Ampasinambo","-20.5167","48.0000","Madagascar","MG","MDG","Fianarantsoa","","13774","1450644953"
"Sariq","Sariq","37.6722","67.2375","Uzbekistan","UZ","UZB","Surxondaryo","","13700","1860642236"
"Yamanobe","Yamanobe","38.2891","140.2622","Japan","JP","JPN","Yamagata","","13610","1392439756"
"Granville","Granville","48.8381","-1.5869","France","FR","FRA","Normandie","","12567","1250956668"
"Rājupālem","Rajupalem","15.1378","79.9927","India","IN","IND","Andhra Pradesh","","13106","1356902831"
"Aberystwyth","Aberystwyth","52.4140","-4.0810","United Kingdom","GB","GBR","Ceredigion","","13040","1826420477"
"Achuapa","Achuapa","13.0536","-86.5900","Nicaragua","NI","NIC","León","minor","13797","1558765003"
"Sauk Rapids","Sauk Rapids","45.5981","-94.1538","United States","US","USA","Minnesota","","13830","1840008873"
"Fátima","Fatima","39.6255","-8.6659","Portugal","PT","PRT","Santarém","","13212","1620677968"
"Ak-Dovurak","Ak-Dovurak","51.1833","90.6000","Russia","RU","RUS","Tyva","","13580","1643785448"
"Alagoinha","Alagoinha","-8.4658","-36.7758","Brazil","BR","BRA","Pernambuco","","13759","1076976847"
"Dowbarān","Dowbaran","28.4061","54.1881","Iran","IR","IRN","Fārs","","13809","1364470614"
"Millbury","Millbury","42.1925","-71.7741","United States","US","USA","Massachusetts","","13827","1840053595"
"Jupi","Jupi","-8.7119","-36.4150","Brazil","BR","BRA","Pernambuco","","13705","1076087263"
"Lyaskovets","Lyaskovets","43.1000","25.7167","Bulgaria","BG","BGR","Veliko Tarnovo","","13735","1100739412"
"Selston","Selston","53.0700","-1.3000","United Kingdom","GB","GBR","Nottinghamshire","","12596","1826087105"
"Cherry Hill Mall","Cherry Hill Mall","39.9384","-75.0117","United States","US","USA","New Jersey","","13826","1840033469"
"Balha","Balha","25.5468","86.5683","India","IN","IND","Bihār","","12015","1356343603"
"Höchstadt an der Aisch","Hochstadt an der Aisch","49.7000","10.8000","Germany","DE","DEU","Bavaria","","13632","1276346402"
"Rutherford","Rutherford","-32.7150","151.5317","Australia","AU","AUS","New South Wales","","11884","1036471917"
"Bundāla","Bundala","31.5333","74.9833","India","IN","IND","Punjab","","13192","1356723444"
"Chāhatpur","Chahatpur","26.2331","87.5816","India","IN","IND","Bihār","","12760","1356118851"
"Jindayris","Jindayris","36.3947","36.6889","Syria","SY","SYR","Ḩalab","minor","13661","1760063267"
"Ghorādal","Ghoradal","22.0519","88.3594","India","IN","IND","West Bengal","","12170","1356149424"
"Michendorf","Michendorf","52.3129","13.0292","Germany","DE","DEU","Brandenburg","","13618","1276652646"
"Arzachena","Arzachena","41.0833","9.3833","Italy","IT","ITA","Sardegna","","13756","1380105084"
"Crispiano","Crispiano","40.6000","17.2333","Italy","IT","ITA","Puglia","","13693","1380058805"
"Uppāda","Uppada","17.0883","82.3333","India","IN","IND","Andhra Pradesh","","12964","1356472489"
"Leżajsk","Lezajsk","50.2667","22.4333","Poland","PL","POL","Podkarpackie","minor","13164","1616610279"
"Gouka","Gouka","8.1333","1.9667","Benin","BJ","BEN","Collines","","13765","1204271160"
"Kings Park West","Kings Park West","38.8151","-77.2960","United States","US","USA","Virginia","","13812","1840041769"
"Loganville","Loganville","33.8353","-83.8958","United States","US","USA","Georgia","","13810","1840015656"
"Jardín","Jardin","5.5986","-75.8194","Colombia","CO","COL","Antioquia","minor","13748","1170860427"
"St. Helens","St. Helens","45.8572","-122.8164","United States","US","USA","Oregon","","13808","1840021185"
"Eichstätt","Eichstatt","48.8919","11.1839","Germany","DE","DEU","Bavaria","minor","13525","1276205721"
"Atomé-Avégamé","Atome-Avegame","7.2333","1.6500","Benin","BJ","BEN","Couffo","","13582","1204148297"
"Zarghūn Shahr","Zarghun Shahr","32.8500","68.4167","Afghanistan","AF","AFG","Paktīkā","","13737","1004965319"
"Beachwood","Beachwood","41.4759","-81.5030","United States","US","USA","Ohio","","13806","1840003395"
"Ampthill","Ampthill","52.0263","-0.4906","United Kingdom","GB","GBR","Central Bedfordshire","","13307","1826072891"
"Sóller","Soller","39.7667","2.7000","Spain","ES","ESP","Balearic Islands","","13491","1724439575"
"Teays Valley","Teays Valley","38.4482","-81.9246","United States","US","USA","West Virginia","","13805","1840038312"
"Heliópolis","Heliopolis","-10.6828","-38.2858","Brazil","BR","BRA","Bahia","","13762","1076906632"
"Melenki","Melenki","55.3333","41.6500","Russia","RU","RUS","Vladimirskaya Oblast’","","13789","1643886318"
"Kadattūr","Kadattur","12.0861","78.2926","India","IN","IND","Tamil Nādu","","13442","1356132916"
"Kolagallu","Kolagallu","15.1500","76.9200","India","IN","IND","Karnātaka","","13201","1356599563"
"Neckargemünd","Neckargemund","49.3939","8.7975","Germany","DE","DEU","Baden-Württemberg","","13290","1276103353"
"Sarāyān","Sarayan","33.8603","58.5217","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","13795","1364790905"
"Verona","Verona","42.9892","-89.5383","United States","US","USA","Wisconsin","","13798","1840002903"
"Puduppatti","Puduppatti","9.7639","77.2678","India","IN","IND","Tamil Nādu","","13219","1356250290"
"Isāgarh","Isagarh","24.8391","77.8827","India","IN","IND","Madhya Pradesh","","13658","1356735093"
"Carmópolis","Carmopolis","-10.6478","-36.9889","Brazil","BR","BRA","Sergipe","","13503","1076033854"
"Forest Hill","Forest Hill","32.6619","-97.2662","United States","US","USA","Texas","","13797","1840020695"
"Marienheide","Marienheide","51.0833","7.5333","Germany","DE","DEU","North Rhine-Westphalia","","13552","1276546142"
"Nallajerla","Nallajerla","16.9500","81.4000","India","IN","IND","Andhra Pradesh","","13457","1356576230"
"South Sioux City","South Sioux City","42.4627","-96.4125","United States","US","USA","Nebraska","","13796","1840009129"
"Nantucket","Nantucket","41.2831","-70.0692","United States","US","USA","Massachusetts","","13795","1840053617"
"Curuá","Curua","-1.8878","-55.1169","Brazil","BR","BRA","Pará","","13783","1076290240"
"Carmo de Minas","Carmo de Minas","-22.1219","-45.1289","Brazil","BR","BRA","Minas Gerais","","13750","1076417253"
"Bellefontaine","Bellefontaine","40.3627","-83.7630","United States","US","USA","Ohio","","13792","1840007233"
"Amrābād","Amrabad","16.3833","78.8333","India","IN","IND","Andhra Pradesh","","13753","1356022195"
"Pakra","Pakra","25.3711","87.0807","India","IN","IND","Bihār","","11889","1356044870"
"Sansare","Sansare","14.7478","-90.1158","Guatemala","GT","GTM","El Progreso","minor","13674","1320883953"
"Chinna Mushidivāda","Chinna Mushidivada","17.8057","83.2033","India","IN","IND","Andhra Pradesh","","13425","1356329396"
"Verín","Verin","41.9408","-7.4358","Spain","ES","ESP","Galicia","","13644","1724261912"
"Shōdoshima","Shodoshima","34.4798","134.3089","Japan","JP","JPN","Kagawa","","13646","1392003526"
"Kyzyl-Kyshtak","Kyzyl-Kyshtak","40.5444","72.7739","Kyrgyzstan","KG","KGZ","Osh","","11971","1417327031"
"Bhulath Gharbi","Bhulath Gharbi","31.5344","75.4953","India","IN","IND","Punjab","","13320","1356574372"
"Dārat ‘Izzah","Darat \`Izzah","36.2828","36.8519","Syria","SY","SYR","Ḩalab","minor","13525","1760338572"
"Rostamābād","Rostamabad","36.8983","49.4906","Iran","IR","IRN","Gīlān","","13746","1364326640"
"Kupino","Kupino","54.3667","77.3000","Russia","RU","RUS","Novosibirskaya Oblast’","","13777","1643059736"
"Pierrelatte","Pierrelatte","44.3775","4.6961","France","FR","FRA","Auvergne-Rhône-Alpes","","13510","1250398109"
"Worthington","Worthington","43.6281","-95.5990","United States","US","USA","Minnesota","","13782","1840010036"
"Pondūru","Ponduru","18.3508","83.7567","India","IN","IND","Andhra Pradesh","","12640","1356187054"
"Kaua Kol","Kaua Kol","24.8447","85.8867","India","IN","IND","Bihār","","13135","1356077753"
"Ketsch","Ketsch","49.3658","8.5336","Germany","DE","DEU","Baden-Württemberg","","12995","1276661701"
"Atner","Atner","21.6238","77.9152","India","IN","IND","Madhya Pradesh","","13601","1356056396"
"Chiran","Chiran","31.3783","130.4416","Japan","JP","JPN","Kagoshima","","13667","1392000261"
"Torul","Torul","40.5572","39.2919","Turkey","TR","TUR","Gümüşhane","minor","13771","1792476725"
"Beeville","Beeville","28.4053","-97.7490","United States","US","USA","Texas","","13780","1840019698"
"Mont-Laurier","Mont-Laurier","46.5500","-75.5000","Canada","CA","CAN","Quebec","","13779","1124355399"
"Münchenstein","Munchenstein","47.5167","7.6167","Switzerland","CH","CHE","Basel-Landschaft","","12096","1756012839"
"Castel San Giovanni","Castel San Giovanni","45.0500","9.4333","Italy","IT","ITA","Emilia-Romagna","","13756","1380014722"
"Richland","Richland","40.4490","-75.3362","United States","US","USA","Pennsylvania","","13778","1840152881"
"‘Alem T’ēna","\`Alem T'ena","8.3000","38.9500","Ethiopia","ET","ETH","Oromīya","","13529","1231008148"
"Gołdap","Goldap","54.3161","22.3094","Poland","PL","POL","Warmińsko-Mazurskie","minor","13716","1616193832"
"Uzwil","Uzwil","47.4369","9.1333","Switzerland","CH","CHE","Sankt Gallen","","12885","1756713626"
"Tarumizu","Tarumizu","31.5228","130.7594","Japan","JP","JPN","Kagoshima","","13688","1392003141"
"Bad Soden-Salmünster","Bad Soden-Salmunster","50.2667","9.3667","Germany","DE","DEU","Hesse","","13540","1276069295"
"Ajas","Ajas","34.3316","74.6801","India","IN","IND","Jammu and Kashmīr","","13028","1356098164"
"Mata","Mata","14.0436","21.1611","Chad","TD","TCD","Ouaddaï","","13739","1148342501"
"Chandla","Chandla","25.0715","80.1929","India","IN","IND","Madhya Pradesh","","13485","1356042916"
"Dyykan-Kyshtak","Dyykan-Kyshtak","40.5100","72.7800","Kyrgyzstan","KG","KGZ","Osh","","13406","1417644014"
"Sattar","Sattar","25.9550","86.5828","India","IN","IND","Bihār","","12827","1356003039"
"Ephrata","Ephrata","40.1811","-76.1812","United States","US","USA","Pennsylvania","","13767","1840003717"
"Arsali","Arsali","24.3754","83.5903","India","IN","IND","Jhārkhand","","13214","1356616827"
"Mansidão","Mansidao","-10.7158","-44.0339","Brazil","BR","BRA","Bahia","","13761","1076320335"
"Gülchö","Gulcho","40.3167","73.4333","Kyrgyzstan","KG","KGZ","Osh","minor","13755","1417801725"
"Gosaingaon","Gosaingaon","25.3724","87.1049","India","IN","IND","Bihār","","12492","1356422615"
"Coldwater","Coldwater","41.9465","-84.9989","United States","US","USA","Michigan","","13764","1840003250"
"Villas","Villas","26.5504","-81.8678","United States","US","USA","Florida","","13764","1840029092"
"Khomām","Khomam","37.3892","49.6583","Iran","IR","IRN","Gīlān","","12901","1364567758"
"Biedenkopf","Biedenkopf","50.9128","8.5322","Germany","DE","DEU","Hesse","","13614","1276705045"
"Włodawa","Wlodawa","51.5500","23.5500","Poland","PL","POL","Lubelskie","minor","13066","1616013060"
"Ba Chúc","Ba Chuc","10.5000","104.9000","Vietnam","VN","VNM","An Giang","","13122","1704637528"
"Sampaloc","Sampaloc","14.1625","121.6378","Philippines","PH","PHL","Quezon","","13629","1608820568"
"Chinique","Chinique","15.0411","-91.0269","Guatemala","GT","GTM","Quiché","minor","13547","1320877724"
"Nurkot","Nurkot","32.2017","75.1186","Pakistan","PK","PAK","Punjab","","12876","1586853360"
"Msata","Msata","-6.3362","38.3850","Tanzania","TZ","TZA","Coast","","13740","1834058417"
"Bollène","Bollene","44.2803","4.7489","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13509","1250710929"
"Châlette-sur-Loing","Chalette-sur-Loing","48.0117","2.7358","France","FR","FRA","Centre-Val de Loire","","12790","1250465626"
"Strathmore","Strathmore","51.0378","-113.4003","Canada","CA","CAN","Alberta","","13756","1124000881"
"Palmeirais","Palmeirais","-5.9778","-43.0628","Brazil","BR","BRA","Piauí","","13745","1076925403"
"Bandarbeyla","Bandarbeyla","9.4833","50.8167","Somalia","SO","SOM","Bari","","13753","1706279652"
"Buttar","Buttar","31.0038","75.3973","India","IN","IND","Punjab","","13269","1356502615"
"Filandia","Filandia","4.6667","-75.6333","Colombia","CO","COL","Quindío","minor","13628","1170471225"
"Trzebnica","Trzebnica","51.3050","17.0614","Poland","PL","POL","Dolnośląskie","minor","13631","1616145534"
"San Juan La Laguna","San Juan La Laguna","14.7000","-91.2833","Guatemala","GT","GTM","Sololá","minor","13400","1320529701"
"Destin","Destin","30.3950","-86.4701","United States","US","USA","Florida","","13751","1840015008"
"Alleroy","Alleroy","43.2500","46.1333","Russia","RU","RUS","Chechnya","","13405","1643528710"
"Santa Coloma de Farnés","Santa Coloma de Farnes","41.8624","2.6654","Spain","ES","ESP","Catalonia","","13557","1724089787"
"Bhachhi Asli","Bhachhi Asli","26.0147","86.0444","India","IN","IND","Bihār","","12228","1356825854"
"Schoonhoven","Schoonhoven","51.9500","4.8500","Netherlands","NL","NLD","Zuid-Holland","minor","11922","1528834731"
"Brandermill","Brandermill","37.4340","-77.6522","United States","US","USA","Virginia","","13745","1840024784"
"Dharmaram","Dharmaram","18.3038","79.4892","India","IN","IND","Andhra Pradesh","","13239","1356091555"
"Bully-les-Mines","Bully-les-Mines","50.4419","2.7244","France","FR","FRA","Hauts-de-France","","12156","1250064948"
"Futrono","Futrono","-40.1333","-72.4000","Chile","CL","CHL","Los Ríos","","13736","1152738108"
"Nybro","Nybro","56.7333","15.9000","Sweden","SE","SWE","Kalmar","minor","13583","1752956963"
"Kādachchinallūr","Kadachchinallur","11.3686","77.8033","India","IN","IND","Tamil Nādu","","12830","1356166809"
"Río Caribe","Rio Caribe","10.7008","-63.1086","Venezuela","VE","VEN","Sucre","minor","13667","1862329516"
"Caledon","Caledon","-34.2300","19.4283","South Africa","ZA","ZAF","Western Cape","","13020","1710038887"
"Kotgīr","Kotgir","18.5722","77.8243","India","IN","IND","Andhra Pradesh","","13413","1356072917"
"Xireg","Xireg","36.9257","98.4837","China","CN","CHN","Qinghai","minor","13723","1156977804"
"Ipswich","Ipswich","42.6857","-70.8399","United States","US","USA","Massachusetts","","13740","1840053668"
"Kafr ‘Awān","Kafr \`Awan","32.4167","35.6833","Jordan","JO","JOR","Irbid","","13056","1400261137"
"East Whiteland","East Whiteland","40.0474","-75.5547","United States","US","USA","Pennsylvania","","13739","1840150930"
"Sátoraljaújhely","Satoraljaujhely","48.4000","21.6667","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","13554","1348874769"
"Hünxe","Hunxe","51.6417","6.7672","Germany","DE","DEU","North Rhine-Westphalia","","13611","1276346440"
"Auburn","Auburn","38.8950","-121.0777","United States","US","USA","California","","13738","1840010231"
"Warrenville","Warrenville","41.8209","-88.1857","United States","US","USA","Illinois","","13738","1840010164"
"Bondāda","Bondada","16.5295","81.4549","India","IN","IND","Andhra Pradesh","","13205","1356484226"
"Carlópolis","Carlopolis","-23.4250","-49.7208","Brazil","BR","BRA","Paraná","","13706","1076068355"
"Fallsburg","Fallsburg","41.7391","-74.6038","United States","US","USA","New York","","13736","1840058089"
"Saksohāra","Saksohara","25.3635","85.7013","India","IN","IND","Bihār","","11961","1356102869"
"Tejutla","Tejutla","14.1667","-89.1000","El Salvador","SV","SLV","Chalatenango","","13608","1222169556"
"Genas","Genas","45.7314","5.0022","France","FR","FRA","Auvergne-Rhône-Alpes","","13181","1250432890"
"Solonytsivka","Solonytsivka","49.9942","36.0264","Ukraine","UA","UKR","Kharkivska Oblast","","13626","1804926194"
"São José do Cedro","Sao Jose do Cedro","-26.4550","-53.4939","Brazil","BR","BRA","Santa Catarina","","13684","1076863446"
"Camponogara","Camponogara","45.3833","12.0667","Italy","IT","ITA","Veneto","","13117","1380151926"
"Sabbavaram","Sabbavaram","17.7900","83.1230","India","IN","IND","Andhra Pradesh","","12200","1356248920"
"Bangāwān","Bangawan","26.0427","87.4202","India","IN","IND","Bihār","","12051","1356041297"
"Neunkirchen","Neunkirchen","50.7861","8.0056","Germany","DE","DEU","North Rhine-Westphalia","","13406","1276694320"
"Mangha","Mangha","23.3700","98.9892","China","CN","CHN","Yunnan","","13689","1156812685"
"Andaraí","Andarai","-12.8069","-41.3308","Brazil","BR","BRA","Bahia","","13723","1076838333"
"Motupe","Motupe","-6.1519","-79.7142","Peru","PE","PER","Lambayeque","","13681","1604380129"
"Kawatana","Kawatana","33.0728","129.8613","Japan","JP","JPN","Nagasaki","","13370","1392003444"
"Marshall","Marshall","44.4488","-95.7897","United States","US","USA","Minnesota","","13728","1840007879"
"Namīn","Namin","38.4256","48.4839","Iran","IR","IRN","Ardabīl","minor","13659","1364085966"
"Hazel Crest","Hazel Crest","41.5732","-87.6899","United States","US","USA","Illinois","","13727","1840011260"
"Kollipara","Kollipara","16.2877","80.7519","India","IN","IND","Andhra Pradesh","","12982","1356510810"
"Cobh","Cobh","51.8510","-8.2967","Ireland","IE","IRL","Cork","","12347","1372119751"
"Bedford","Bedford","38.8602","-86.4895","United States","US","USA","Indiana","","13726","1840007426"
"Cedar Lake","Cedar Lake","41.3696","-87.4389","United States","US","USA","Indiana","","13725","1840009259"
"Altos del Rosario","Altos del Rosario","8.8000","-74.1667","Colombia","CO","COL","Bolívar","minor","13669","1170079040"
"Kuleshovka","Kuleshovka","47.0833","39.5667","Russia","RU","RUS","Rostovskaya Oblast’","","13692","1643894648"
"Busogo","Busogo","-1.5572","29.5442","Rwanda","RW","RWA","Northern Province","","12460","1646386740"
"São João do Triunfo","Sao Joao do Triunfo","-25.6828","-50.2969","Brazil","BR","BRA","Paraná","","13704","1076836472"
"Hinabangan","Hinabangan","11.7000","125.0667","Philippines","PH","PHL","Samar","","13693","1608780476"
"Schermbeck","Schermbeck","51.6950","6.8756","Germany","DE","DEU","North Rhine-Westphalia","","13599","1276572969"
"Massaranduba","Massaranduba","-7.2000","-35.7889","Brazil","BR","BRA","Paraíba","","13654","1076880277"
"Blegny","Blegny","50.6667","5.7333","Belgium","BE","BEL","Wallonia","","13212","1056021214"
"Nules","Nules","39.8525","-0.1506","Spain","ES","ESP","Valencia","","13449","1724279489"
"Snoqualmie","Snoqualmie","47.5293","-121.8412","United States","US","USA","Washington","","13718","1840021119"
"Yongcong","Yongcong","26.0451","109.1327","China","CN","CHN","Guizhou","","13670","1156121343"
"Manta","Manta","10.3564","1.1056","Benin","BJ","BEN","Atacora","","13633","1204718749"
"Mahalpur","Mahalpur","31.3618","76.0377","India","IN","IND","Punjab","","13223","1356794919"
"El Crucero","El Crucero","11.9894","-86.3103","Nicaragua","NI","NIC","Managua","minor","13656","1558750409"
"Tamsaout","Tamsaout","29.5333","-8.8500","Morocco","MA","MAR","Souss-Massa","","13711","1504846802"
"Mansāpur","Mansapur","26.5262","86.4101","India","IN","IND","Bihār","","12859","1356029587"
"Konaklı","Konakli","36.5833","31.8833","Turkey","TR","TUR","Antalya","","13563","1792175009"
"Arcadia","Arcadia","43.0870","-77.0858","United States","US","USA","New York","","13715","1840087174"
"Saugeen Shores","Saugeen Shores","44.4333","-81.3667","Canada","CA","CAN","Ontario","","13715","1124000488"
"Beacon","Beacon","41.5036","-73.9655","United States","US","USA","New York","","13713","1840000499"
"Palmito","Palmito","9.3333","-75.5500","Colombia","CO","COL","Sucre","minor","13682","1170554434"
"Chanal","Chanal","16.6000","-92.2167","Mexico","MX","MEX","Chiapas","minor","13678","1484260225"
"Minneola","Minneola","28.6067","-81.7322","United States","US","USA","Florida","","13709","1840015081"
"Las Rosas","Las Rosas","-32.4833","-61.5833","Argentina","AR","ARG","Santa Fe","minor","13689","1032811294"
"Quartucciu","Quartucciu","39.2529","9.1762","Italy","IT","ITA","Sardegna","","13234","1380238620"
"Meruoca","Meruoca","-3.5419","-40.4550","Brazil","BR","BRA","Ceará","","13693","1076314792"
"Mount Vernon","Mount Vernon","38.7140","-77.1043","United States","US","USA","Virginia","","13707","1840006020"
"Kachchippalli","Kachchippalli","11.5950","77.9188","India","IN","IND","Tamil Nādu","","12798","1356169189"
"Dallas","Dallas","33.9152","-84.8416","United States","US","USA","Georgia","","13704","1840014757"
"Raamsdonksveer","Raamsdonksveer","51.6833","4.8667","Netherlands","NL","NLD","Noord-Brabant","minor","12470","1528845706"
"Quatá","Quata","-22.2475","-50.6983","Brazil","BR","BRA","São Paulo","","13702","1076183598"
"Ban Nong Hoi","Ban Nong Hoi","18.7500","99.0167","Thailand","TH","THA","Chiang Mai","","13144","1764993744"
"Pallappālaiyam","Pallappalaiyam","10.9951","77.0845","India","IN","IND","Tamil Nādu","","13065","1356169956"
"Bairo","Bairo","26.0144","86.5100","India","IN","IND","Bihār","","11910","1356258965"
"Lone Tree","Lone Tree","39.5309","-104.8709","United States","US","USA","Colorado","","13701","1840020222"
"Canton","Canton","40.5632","-90.0409","United States","US","USA","Illinois","","13701","1840007212"
"Sirigeri","Sirigeri","15.6300","76.9000","India","IN","IND","Karnātaka","","13361","1356573407"
"Ad Duraykīsh","Ad Duraykish","34.8969","36.1346","Syria","SY","SYR","Ţarţūs","minor","13244","1760515031"
"Friesenheim","Friesenheim","48.3731","7.8833","Germany","DE","DEU","Baden-Württemberg","","13412","1276429859"
"McMinnville","McMinnville","35.6864","-85.7812","United States","US","USA","Tennessee","","13699","1840015385"
"Kākan","Kakan","26.1509","87.5674","India","IN","IND","Bihār","","12636","1356098838"
"Winsum","Winsum","53.3312","6.5157","Netherlands","NL","NLD","Groningen","minor","13560","1528389180"
"Camapuã","Camapua","-19.5308","-54.0439","Brazil","BR","BRA","Mato Grosso do Sul","","13693","1076456553"
"South Park","South Park","40.2988","-79.9944","United States","US","USA","Pennsylvania","","13695","1840150312"
"Grammichele","Grammichele","37.2147","14.6364","Italy","IT","ITA","Sicilia","","13267","1380407016"
"Warrensville Heights","Warrensville Heights","41.4363","-81.5222","United States","US","USA","Ohio","","13694","1840003419"
"Channahon","Channahon","41.4210","-88.2599","United States","US","USA","Illinois","","13694","1840010214"
"Birstall","Birstall","52.6736","-1.1200","United Kingdom","GB","GBR","Leicester","","12216","1826141026"
"Lakeland","Lakeland","35.2585","-89.7308","United States","US","USA","Tennessee","","13693","1840014594"
"Uarini","Uarini","-2.9900","-65.1078","Brazil","BR","BRA","Amazonas","","13690","1076915660"
"Jimbolia","Jimbolia","45.7931","20.7375","Romania","RO","ROU","Timiş","","13597","1642376521"
"Fontenay-le-Comte","Fontenay-le-Comte","46.4669","-0.8064","France","FR","FRA","Pays de la Loire","minor","13302","1250703956"
"Duvergé","Duverge","18.3800","-71.3633","Dominican Republic","DO","DOM","Enriquillo","","13668","1214710572"
"Cidelândia","Cidelandia","-5.1739","-47.7819","Brazil","BR","BRA","Tocantins","","13681","1076287483"
"Lanuza","Lanuza","9.2322","126.0592","Philippines","PH","PHL","Surigao del Sur","","13642","1608065312"
"San Jorge","San Jorge","14.9253","-89.5897","Guatemala","GT","GTM","Zacapa","minor","13525","1320839859"
"Pātiram","Patiram","25.3167","88.7500","India","IN","IND","West Bengal","","12462","1356075890"
"Löningen","Loningen","52.7167","7.7667","Germany","DE","DEU","Lower Saxony","","13592","1276522371"
"Franklin Park","Franklin Park","40.4439","-74.5431","United States","US","USA","New Jersey","","13686","1840039889"
"Chāmpāhāti","Champahati","22.4000","88.5000","India","IN","IND","West Bengal","","12111","1356545706"
"Ihtiman","Ihtiman","42.4374","23.8164","Bulgaria","BG","BGR","Sofia","","13658","1100416856"
"Basantpur","Basantpur","26.1331","87.4656","India","IN","IND","Bihār","","12002","1356716312"
"Lappersdorf","Lappersdorf","49.0525","12.0903","Germany","DE","DEU","Bavaria","","13298","1276955440"
"Dinant","Dinant","50.2667","4.9167","Belgium","BE","BEL","Wallonia","minor","13544","1056337712"
"Thompson","Thompson","55.7433","-97.8553","Canada","CA","CAN","Manitoba","","13678","1124110693"
"Gopālnagar","Gopalnagar","22.8289","88.2139","India","IN","IND","West Bengal","","12232","1356095553"
"Beverstedt","Beverstedt","53.4340","8.8183","Germany","DE","DEU","Lower Saxony","","13608","1276723766"
"Karnāwad","Karnawad","22.7361","76.2439","India","IN","IND","Madhya Pradesh","","13545","1356382568"
"An Thành B","An Thanh B","10.1958","106.3314","Vietnam","VN","VNM","Bến Tre","","12968","1704953278"
"Palestina","Palestina","5.0833","-75.6667","Colombia","CO","COL","Caldas","minor","13560","1170065725"
"Vázquez","Vazquez","21.1399","-76.7294","Cuba","CU","CUB","Las Tunas","","13600","1192082487"
"Dragør","Dragor","55.5833","12.6667","Denmark","DK","DNK","Hovedstaden","minor","12401","1208259300"
"Bunhe","Bunhe","48.2206","38.2739","Ukraine","UA","UKR","Donetska Oblast","","13633","1804980165"
"Castelfranco di Sotto","Castelfranco di Sotto","43.7000","10.7500","Italy","IT","ITA","Tuscany","","13396","1380555737"
"Sirhāli Kalān","Sirhali Kalan","31.2783","74.9414","India","IN","IND","Punjab","","13012","1356211889"
"Melissa","Melissa","33.2891","-96.5573","United States","US","USA","Texas","","13671","1840020658"
"Vigodarzere","Vigodarzere","45.4500","11.8833","Italy","IT","ITA","Veneto","","13017","1380176970"
"Nikolayevsk","Nikolayevsk","50.0333","45.4500","Russia","RU","RUS","Volgogradskaya Oblast’","","13660","1643965946"
"Sivalārkulam","Sivalarkulam","8.8700","77.5267","India","IN","IND","Tamil Nādu","","12705","1356202498"
"Royal Wootton Bassett","Royal Wootton Bassett","51.5330","-1.9000","United Kingdom","GB","GBR","Wiltshire","","13570","1826578502"
"Saint-Orens-de-Gameville","Saint-Orens-de-Gameville","43.5514","1.5342","France","FR","FRA","Occitanie","","12696","1250181845"
"Caldeirão Grande","Caldeirao Grande","-11.0200","-40.3028","Brazil","BR","BRA","Bahia","","13641","1076740446"
"Echelon","Echelon","39.8482","-74.9957","United States","US","USA","New Jersey","","13668","1840033470"
"Ettāpur","Ettapur","11.6625","78.4764","India","IN","IND","Tamil Nādu","","13276","1356005422"
"Alto Paraná","Alto Parana","-23.1289","-52.3189","Brazil","BR","BRA","Paraná","","13663","1076148940"
"São Pedro do Piauí","Sao Pedro do Piaui","-5.9289","-42.7189","Brazil","BR","BRA","Piauí","","13639","1076845021"
"Saint-Paul-lès-Dax","Saint-Paul-les-Dax","43.7256","-1.0528","France","FR","FRA","Nouvelle-Aquitaine","","13436","1250809513"
"Quierschied","Quierschied","49.3167","7.0500","Germany","DE","DEU","Saarland","","13030","1276003316"
"Itapagipe","Itapagipe","-19.9089","-49.3808","Brazil","BR","BRA","Minas Gerais","","13656","1076647867"
"Bad Laasphe","Bad Laasphe","50.9303","8.4167","Germany","DE","DEU","North Rhine-Westphalia","","13565","1276330247"
"Salzano","Salzano","45.5333","12.1167","Italy","IT","ITA","Veneto","","12911","1380489219"
"Eloxochitlán","Eloxochitlan","18.5088","-96.9227","Mexico","MX","MEX","Puebla","","12520","1484796183"
"Llanes","Llanes","43.4214","-4.7564","Spain","ES","ESP","Asturias","","13611","1724106388"
"Garag","Garag","15.5750","74.9297","India","IN","IND","Karnātaka","","12463","1356496793"
"Doumé","Doume","8.0167","1.6333","Benin","BJ","BEN","Collines","","13592","1204780226"
"Beyne-Heusay","Beyne-Heusay","50.6167","5.6500","Belgium","BE","BEL","Wallonia","","12019","1056881685"
"Monte Alegre de Sergipe","Monte Alegre de Sergipe","-10.0269","-37.5619","Brazil","BR","BRA","Sergipe","","13627","1076297228"
"Velilla de San Antonio","Velilla de San Antonio","40.3667","-3.4833","Spain","ES","ESP","Madrid","","12770","1724060021"
"Ratnāpuram","Ratnapuram","18.6283","79.5517","India","IN","IND","Andhra Pradesh","","13293","1356503205"
"Shawangunk","Shawangunk","41.6335","-74.2654","United States","US","USA","New York","","13659","1840087908"
"São Félix da Marinha","Sao Felix da Marinha","41.0340","-8.6230","Portugal","PT","PRT","Porto","","12706","1620414430"
"Parjuār","Parjuar","26.4836","86.0047","India","IN","IND","Bihār","","12811","1356113169"
"Šternberk","Sternberk","49.7305","17.2989","Czechia","CZ","CZE","Olomoucký Kraj","","13388","1203827138"
"Lavaltrie","Lavaltrie","45.8833","-73.2833","Canada","CA","CAN","Quebec","","13657","1124818327"
"Mutterstadt","Mutterstadt","49.4417","8.3561","Germany","DE","DEU","Rhineland-Palatinate","","13036","1276681342"
"Amboasary-Gara","Amboasary-Gara","-18.4333","48.2667","Madagascar","MG","MDG","Toamasina","","13601","1450032744"
"Callaway","Callaway","30.1349","-85.5568","United States","US","USA","Florida","","13656","1840013958"
"Mantenópolis","Mantenopolis","-18.8628","-41.1228","Brazil","BR","BRA","Espírito Santo","","13612","1076166982"
"Alto Longá","Alto Longa","-5.2508","-42.2100","Brazil","BR","BRA","Piauí","","13646","1076773885"
"Ihorombe","Ihorombe","-23.0000","47.5333","Madagascar","MG","MDG","Fianarantsoa","","13000","1450066210"
"Caldas","Caldas","-21.9239","-46.3858","Brazil","BR","BRA","Minas Gerais","","13633","1076658037"
"Spring Garden","Spring Garden","39.9454","-76.7212","United States","US","USA","Pennsylvania","","13652","1840147124"
"Bridport","Bridport","50.7336","-2.7584","United Kingdom","GB","GBR","Dorset","","13569","1826126753"
"Okinoshima","Okinoshima","36.2091","133.3218","Japan","JP","JPN","Shimane","","13595","1392003154"
"Gran","Gran","60.4411","10.4956","Norway","NO","NOR","Innlandet","","13633","1578913505"
"Ixcatepec","Ixcatepec","21.2333","-98.0000","Mexico","MX","MEX","Veracruz","","13591","1484476969"
"Nicosia","Nicosia","37.7500","14.4000","Italy","IT","ITA","Sicilia","","13588","1380619404"
"Pfullendorf","Pfullendorf","47.9242","9.2567","Germany","DE","DEU","Baden-Württemberg","","13501","1276345734"
"Głubczyce","Glubczyce","50.2011","17.8247","Poland","PL","POL","Opolskie","minor","13572","1616541369"
"Rasūlpur","Rasulpur","25.9938","85.8935","India","IN","IND","Bihār","","11825","1356027790"
"Kankandighi","Kankandighi","21.9744","88.4659","India","IN","IND","West Bengal","","12900","1356591513"
"Cajabamba","Cajabamba","-7.6237","-78.0460","Peru","PE","PER","Cajamarca","","13486","1604780784"
"Salisbury","Salisbury","40.5768","-75.4535","United States","US","USA","Pennsylvania","","13645","1840148585"
"Chāwalhāti","Chawalhati","26.4614","88.2660","India","IN","IND","West Bengal","","12988","1356096734"
"San Martino di Lupari","San Martino di Lupari","45.6557","11.8594","Italy","IT","ITA","Veneto","","13104","1380028466"
"Santa Luzia","Santa Luzia","-15.4289","-39.3339","Brazil","BR","BRA","Bahia","","13626","1076656988"
"North Branford","North Branford","41.3645","-72.7768","United States","US","USA","Connecticut","","13643","1840035511"
"Talne","Talne","48.8863","30.7027","Ukraine","UA","UKR","Cherkaska Oblast","","13558","1804616798"
"Hösbach","Hosbach","50.0000","9.2000","Germany","DE","DEU","Bavaria","","13212","1276827182"
"Barāon","Baraon","25.4551","84.2661","India","IN","IND","Bihār","","13021","1356156731"
"Sowerby Bridge","Sowerby Bridge","53.7100","-1.9100","United Kingdom","GB","GBR","Calderdale","","11703","1826733950"
"New Fairfield","New Fairfield","41.4880","-73.4883","United States","US","USA","Connecticut","","13640","1840035500"
"San Martín Zapotitlán","San Martin Zapotitlan","14.6000","-91.6000","Guatemala","GT","GTM","Retalhuleu","minor","13094","1320924510"
"Castilla La Nueva","Castilla La Nueva","3.8333","-73.6833","Colombia","CO","COL","Meta","minor","13611","1170337623"
"Shiloh","Shiloh","38.5534","-89.9161","United States","US","USA","Illinois","","13638","1840012877"
"Bergen","Bergen","52.8103","9.9611","Germany","DE","DEU","Lower Saxony","","13556","1276820204"
"Tilāri","Tilari","25.0040","85.2520","India","IN","IND","Bihār","","12649","1356140676"
"Cayce","Cayce","33.9459","-81.0429","United States","US","USA","South Carolina","","13637","1840014734"
"Virgem da Lapa","Virgem da Lapa","-16.8039","-42.3428","Brazil","BR","BRA","Minas Gerais","","13619","1076690680"
"Mulakalūru","Mulakaluru","16.2776","80.0672","India","IN","IND","Andhra Pradesh","","12949","1356896759"
"Beach Park","Beach Park","42.4260","-87.8583","United States","US","USA","Illinois","","13634","1840011149"
"Crevalcore","Crevalcore","44.7167","11.1500","Italy","IT","ITA","Emilia-Romagna","","13501","1380853789"
"Hathwān","Hathwan","25.6672","86.3999","India","IN","IND","Bihār","","12279","1356054419"
"Dakit","Dakit","10.0600","125.1606","Philippines","PH","PHL","Southern Leyte","","13436","1608424493"
"Takkolam","Takkolam","13.0164","79.7325","India","IN","IND","Tamil Nādu","","13078","1356344925"
"Mangalapur","Mangalapur","13.6245","79.5803","India","IN","IND","Andhra Pradesh","","13353","1356401073"
"Manivilundān","Manivilundan","11.6004","78.7169","India","IN","IND","Tamil Nādu","","13240","1356244165"
"Trubchevsk","Trubchevsk","52.5667","33.7667","Russia","RU","RUS","Bryanskaya Oblast’","","13613","1643481460"
"Torredonjimeno","Torredonjimeno","37.7667","-3.9500","Spain","ES","ESP","Andalusia","","13545","1724172384"
"Sunjiayan","Sunjiayan","27.8776","108.3073","China","CN","CHN","Guizhou","","13408","1156077286"
"Kirchheim bei München","Kirchheim bei Munchen","48.1766","11.7556","Germany","DE","DEU","Bavaria","","12806","1276864694"
"Conway","Conway","28.4968","-81.3316","United States","US","USA","Florida","","13630","1840013121"
"Haldipur","Haldipur","14.3333","74.4333","India","IN","IND","Karnātaka","","13374","1356970915"
"Cañasgordas","Canasgordas","6.7497","-76.0258","Colombia","CO","COL","Antioquia","minor","13595","1170135054"
"Kyzyl-Adyr","Kyzyl-Adyr","42.6200","71.5900","Kyrgyzstan","KG","KGZ","Talas","minor","13612","1417891810"
"Peabiru","Peabiru","-23.9128","-52.3428","Brazil","BR","BRA","Paraná","","13624","1076249784"
"Rio de Contas","Rio de Contas","-13.5789","-41.8108","Brazil","BR","BRA","Bahia","","13616","1076754744"
"Maheswa","Maheswa","25.9718","86.8550","India","IN","IND","Bihār","","12757","1356277915"
"Cossimbāzār","Cossimbazar","24.1200","88.2800","India","IN","IND","West Bengal","","11724","1356094914"
"Żnin","Znin","52.8500","17.7000","Poland","PL","POL","Kujawsko-Pomorskie","minor","13523","1616616613"
"Karivalamvandanallūr","Karivalamvandanallur","9.2715","77.5416","India","IN","IND","Tamil Nādu","","13258","1356203361"
"Montefiascone","Montefiascone","42.5333","12.0333","Italy","IT","ITA","Lazio","","13498","1380379185"
"Nemocón","Nemocon","5.0500","-73.8833","Colombia","CO","COL","Cundinamarca","minor","13488","1170453041"
"Paranhos","Paranhos","-23.8928","-55.4308","Brazil","BR","BRA","Mato Grosso do Sul","","13615","1076900363"
"Zelzate","Zelzate","51.2000","3.8167","Belgium","BE","BEL","Flanders","","12699","1056552955"
"Marion","Marion","35.2035","-90.2059","United States","US","USA","Arkansas","","13624","1840015445"
"Masangshy","Masangshy","42.9289","75.3019","Kazakhstan","KZ","KAZ","","","13606","1398737126"
"Chīmalapādu","Chimalapadu","16.8902","80.6505","India","IN","IND","Andhra Pradesh","","13379","1356424340"
"Udayagiri","Udayagiri","20.1242","84.3687","India","IN","IND","Odisha","","13469","1356027854"
"Kastoriá","Kastoria","40.5167","21.2667","Greece","GR","GRC","Dytikí Makedonía","minor","13387","1300839562"
"Saint-Martin-de-Crau","Saint-Martin-de-Crau","43.6397","4.8125","France","FR","FRA","Provence-Alpes-Côte d’Azur","","13558","1250306761"
"Hersham","Hersham","51.3681","-0.4008","United Kingdom","GB","GBR","Surrey","","12414","1826749539"
"Fenton","Fenton","52.9977","-2.1578","United Kingdom","GB","GBR","Stoke-on-Trent","","12070","1826789719"
"Melilli","Melilli","37.1833","15.1167","Italy","IT","ITA","Sicilia","","13519","1380628157"
"Kingston","Kingston","41.9862","-70.7482","United States","US","USA","Massachusetts","","13618","1840053680"
"Manching","Manching","48.7186","11.4972","Germany","DE","DEU","Bavaria","","12829","1276452710"
"Kaimana","Kaimana","-3.6444","133.6953","Indonesia","ID","IDN","Papua Barat","minor","13613","1360777936"
"Afir","Afir","36.7676","3.7029","Algeria","DZ","DZA","Boumerdes","","13223","1012675026"
"Terra Boa","Terra Boa","-12.3919","-38.6250","Brazil","BR","BRA","Bahia","","13547","1076578412"
"Gopālasamudram","Gopalasamudram","8.6747","77.6379","India","IN","IND","Tamil Nādu","","13223","1356203301"
"Khānāpur","Khanapur","19.0333","78.6667","India","IN","IND","Andhra Pradesh","","13485","1356522803"
"Speedway","Speedway","39.7937","-86.2481","United States","US","USA","Indiana","","13613","1840010574"
"Bad Dürrheim","Bad Durrheim","48.0167","8.5333","Germany","DE","DEU","Baden-Württemberg","","13397","1276778010"
"Korahia","Korahia","26.5325","86.1552","India","IN","IND","Bihār","","11828","1356635280"
"Zhukovo","Zhukovo","55.0333","36.7500","Russia","RU","RUS","Kaluzhskaya Oblast’","","13576","1643886560"
"Plougastel-Daoulas","Plougastel-Daoulas","48.3725","-4.3706","France","FR","FRA","Bretagne","","13330","1250426921"
"Urucuia","Urucuia","-16.1328","-45.7419","Brazil","BR","BRA","Minas Gerais","","13604","1076674277"
"San Javier","San Javier","-30.5833","-59.9500","Argentina","AR","ARG","Santa Fe","minor","13604","1032258062"
"Grez-Doiceau","Grez-Doiceau","50.7333","4.7000","Belgium","BE","BEL","Wallonia","","13368","1056606953"
"San Gennaro Vesuviano","San Gennaro Vesuviano","40.8667","14.5333","Italy","IT","ITA","Campania","","11910","1380767923"
"Qingxicun","Qingxicun","24.5300","116.5904","China","CN","CHN","Guangdong","","13603","1156363702"
"Combarbalá","Combarbala","-31.1833","-71.0000","Chile","CL","CHL","Coquimbo","","13605","1152896722"
"Baras","Baras","13.6667","124.3667","Philippines","PH","PHL","Catanduanes","","13484","1608759660"
"Brecksville","Brecksville","41.3079","-81.6192","United States","US","USA","Ohio","","13607","1840000590"
"Wolsztyn","Wolsztyn","52.1167","16.1167","Poland","PL","POL","Wielkopolskie","minor","13477","1616069343"
"Betanzos","Betanzos","43.2792","-8.2106","Spain","ES","ESP","Galicia","","13066","1724011741"
"Ceuti","Ceuti","38.0789","-1.2722","Spain","ES","ESP","Murcia","","12391","1724039482"
"Ugo","Ugo","39.1993","140.4129","Japan","JP","JPN","Akita","","13547","1392003467"
"Nidzica","Nidzica","53.3667","20.4333","Poland","PL","POL","Warmińsko-Mazurskie","minor","13547","1616216829"
"Majārhāt","Majarhat","25.9654","86.8126","India","IN","IND","Bihār","","12738","1356269146"
"Montespertoli","Montespertoli","43.6500","11.0833","Italy","IT","ITA","Tuscany","","13497","1380899851"
"Haaren","Haaren","50.7956","6.1269","Germany","DE","DEU","North Rhine-Westphalia","","12216","1276965294"
"San Jacinto","San Jacinto","14.6667","-89.5000","Guatemala","GT","GTM","Chiquimula","","13381","1320094957"
"Usmānpur","Usmanpur","25.3487","86.9669","India","IN","IND","Bihār","","12500","1356413511"
"Jānkinagar","Jankinagar","25.8955","87.0668","India","IN","IND","Bihār","","12319","1356136854"
"Ráquira","Raquira","5.5333","-73.6333","Colombia","CO","COL","Boyacá","minor","13588","1170663878"
"Aghbala","Aghbala","32.4833","-5.6333","Morocco","MA","MAR","Béni Mellal-Khénifra","","12781","1504000722"
"Mahārājpur","Maharajpur","25.1040","87.8898","India","IN","IND","Jhārkhand","","12755","1356435854"
"Santaquin","Santaquin","39.9708","-111.7941","United States","US","USA","Utah","","13602","1840021396"
"Ban Mae Kaluang","Ban Mae Kaluang","19.0778","99.9270","Thailand","TH","THA","Phayao","","13502","1764438481"
"Bisee","Bisee","14.0243","-60.9744","Saint Lucia","LC","LCA","Castries","","12980","1662015000"
"Hajdúsámson","Hajdusamson","47.6000","21.7667","Hungary","HU","HUN","Hajdú-Bihar","","13407","1348454380"
"Lakewood Park","Lakewood Park","27.5390","-80.3865","United States","US","USA","Florida","","13600","1840014177"
"Rommerskirchen","Rommerskirchen","51.0347","6.6914","Germany","DE","DEU","North Rhine-Westphalia","","13377","1276832668"
"Schüttorf","Schuttorf","52.3167","7.2167","Germany","DE","DEU","Lower Saxony","","12934","1276950675"
"Hulkoti","Hulkoti","15.4333","75.5333","India","IN","IND","Karnātaka","","13149","1356580585"
"Valdemorillo","Valdemorillo","40.5017","-4.0667","Spain","ES","ESP","Madrid","","13453","1724632141"
"Saarwellingen","Saarwellingen","49.3542","6.8050","Germany","DE","DEU","Saarland","","13279","1276467371"
"Auta","Auta","25.3882","85.9558","India","IN","IND","Bihār","","11716","1356703956"
"South Charleston","South Charleston","38.3426","-81.7155","United States","US","USA","West Virginia","","13595","1840006200"
"Drøbak","Drobak","59.6667","10.6333","Norway","NO","NOR","Viken","","13393","1578855474"
"Maribondo","Maribondo","-9.5769","-36.3050","Brazil","BR","BRA","Alagoas","","13514","1076733416"
"Walton-on-the-Naze","Walton-on-the-Naze","51.8480","1.2680","United Kingdom","GB","GBR","Essex","","12054","1826757430"
"Kharv-e Soflá","Kharv-e Sofla","36.1686","59.0508","Iran","IR","IRN","Khorāsān-e Raẕavī","","13535","1364830111"
"Sūrān","Suran","27.2856","61.9964","Iran","IR","IRN","Sīstān va Balūchestān","minor","13580","1364461163"
"Quatis","Quatis","-22.4069","-44.2578","Brazil","BR","BRA","Rio de Janeiro","","13543","1076266883"
"Sidi Yahia","Sidi Yahia","30.4969","-8.8211","Morocco","MA","MAR","Souss-Massa","","11955","1504503193"
"Endicott","Endicott","42.0980","-76.0639","United States","US","USA","New York","","13588","1840004664"
"Itapetim","Itapetim","-7.3778","-37.1900","Brazil","BR","BRA","Pernambuco","","13553","1076005851"
"Kaharlyk","Kaharlyk","49.8522","30.8092","Ukraine","UA","UKR","Kyivska Oblast","minor","13544","1804596715"
"Comines","Comines","50.7611","3.0078","France","FR","FRA","Hauts-de-France","","12788","1250749868"
"Ban Bung Kha","Ban Bung Kha","16.1675","104.6408","Thailand","TH","THA","Yasothon","","13507","1764502861"
"Shangping","Shangping","25.0897","113.0395","China","CN","CHN","Guangdong","","13529","1156065548"
"Marly","Marly","50.3489","3.5442","France","FR","FRA","Hauts-de-France","","12083","1250056842"
"San Rafael Oriente","San Rafael Oriente","13.3833","-88.3500","El Salvador","SV","SLV","San Miguel","","13290","1222301206"
"Kozy","Kozy","49.8667","19.1500","Poland","PL","POL","Śląskie","","13098","1616180693"
"High River","High River","50.5808","-113.8744","Canada","CA","CAN","Alberta","","13584","1124607825"
"San Marco in Lamis","San Marco in Lamis","41.7117","15.6350","Italy","IT","ITA","Puglia","","13583","1380173757"
"Serra Branca","Serra Branca","-7.4828","-36.6650","Brazil","BR","BRA","Paraíba","","13564","1076589844"
"Bataredh","Bataredh","26.4250","84.6061","India","IN","IND","Bihār","","11833","1356465927"
"Arara","Arara","-6.8278","-35.7578","Brazil","BR","BRA","Paraíba","","13542","1076753005"
"York","York","43.1860","-70.6660","United States","US","USA","Maine","","13581","1840066551"
"Manerbio","Manerbio","45.3667","10.1333","Italy","IT","ITA","Lombardy","","13109","1380871035"
"Spiez","Spiez","46.6833","7.6667","Switzerland","CH","CHE","Bern","","12810","1756813024"
"West End","West End","26.6867","-78.9750","The Bahamas","BS","BHS","West Grand Bahama","","13577","1044539481"
"Sabana Iglesia","Sabana Iglesia","19.3300","-70.7500","Dominican Republic","DO","DOM","Cibao Norte","","13348","1214409279"
"Sigli","Sigli","15.0631","75.4667","India","IN","IND","Karnātaka","","13346","1356206388"
"Novi Iskar","Novi Iskar","42.8000","23.3500","Bulgaria","BG","BGR","Sofia-Grad","","13574","1100373780"
"Ouargaye","Ouargaye","11.5000","0.0500","Burkina Faso","BF","BFA","Centre-Est","minor","13431","1854385112"
"Eichenau","Eichenau","48.1667","11.3167","Germany","DE","DEU","Bavaria","","11894","1276298284"
"Presidente Bernardes","Presidente Bernardes","-22.0061","-51.5531","Brazil","BR","BRA","São Paulo","","13568","1076773912"
"Palangarai","Palangarai","11.1978","77.3058","India","IN","IND","Tamil Nādu","","12963","1356165837"
"Carluke","Carluke","55.7340","-3.8340","United Kingdom","GB","GBR","South Lanarkshire","","13320","1826058388"
"New Providence","New Providence","40.6996","-74.4035","United States","US","USA","New Jersey","","13570","1840001095"
"Kewatgāwān","Kewatgawan","25.8012","86.3229","India","IN","IND","Bihār","","12518","1356255463"
"Feldbach","Feldbach","46.9550","15.8883","Austria","AT","AUT","Steiermark","minor","13369","1040342228"
"Mario Campos","Mario Campos","-20.0558","-44.1878","Brazil","BR","BRA","Minas Gerais","","13192","1076413887"
"Pānchgani","Panchgani","17.9200","73.8200","India","IN","IND","Mahārāshtra","","13280","1356363420"
"Schinnen","Schinnen","50.9500","5.8833","Netherlands","NL","NLD","Limburg","minor","12911","1528127651"
"Lourdes","Lourdes","43.0950","-0.0453","France","FR","FRA","Occitanie","","13210","1250430295"
"Mqam at Tolba","Mqam at Tolba","33.9375","-6.2544","Morocco","MA","MAR","Rabat-Salé-Kénitra","","13503","1504020296"
"Nārāyangarh","Narayangarh","24.2708","75.0501","India","IN","IND","Madhya Pradesh","","13306","1356079928"
"Arruda dos Vinhos","Arruda dos Vinhos","38.9833","-9.0667","Portugal","PT","PRT","Lisboa","minor","13391","1620150819"
"Buşrá al Ḩarīr","Busra al Harir","32.8425","36.3400","Syria","SY","SYR","Dar‘ā","","13315","1760065014"
"Barhī","Barhi","24.3045","85.4195","India","IN","IND","Jhārkhand","","13111","1356431545"
"Amilly","Amilly","47.9731","2.7703","France","FR","FRA","Centre-Val de Loire","","13233","1250170473"
"Reeuwijk","Reeuwijk","52.0500","4.7300","Netherlands","NL","NLD","Zuid-Holland","","13296","1528903557"
"Primavera","Primavera","-8.3378","-35.3550","Brazil","BR","BRA","Pernambuco","","13439","1076000353"
"Oak Park","Oak Park","34.1850","-118.7669","United States","US","USA","California","","13561","1840019198"
"Kolappalūr","Kolappalur","11.5100","77.4500","India","IN","IND","Tamil Nādu","","12939","1356164008"
"Lake Wylie","Lake Wylie","35.0997","-81.0678","United States","US","USA","South Carolina","","13560","1840013518"
"Bilga","Bilga","31.0500","75.6500","India","IN","IND","Punjab","","12963","1356917560"
"Marietta","Marietta","39.4241","-81.4465","United States","US","USA","Ohio","","13559","1840001545"
"Blytheville","Blytheville","35.9321","-89.9051","United States","US","USA","Arkansas","","13559","1840013390"
"Creutzwald","Creutzwald","49.2053","6.6958","France","FR","FRA","Grand Est","","13070","1250656042"
"Clinton","Clinton","40.6315","-74.8553","United States","US","USA","New Jersey","","13558","1840003604"
"Trebur","Trebur","49.9242","8.4092","Germany","DE","DEU","Hesse","","13294","1276689795"
"Nawāda","Nawada","25.1021","86.6902","India","IN","IND","Bihār","","12294","1356969393"
"Connersville","Connersville","39.6582","-85.1410","United States","US","USA","Indiana","","13556","1840007318"
"Châteaudun","Chateaudun","48.0708","1.3378","France","FR","FRA","Centre-Val de Loire","minor","13096","1250130477"
"Thara","Thara","26.7027","78.2788","India","IN","IND","Madhya Pradesh","","13008","1356561205"
"Vernouillet","Vernouillet","48.7208","1.3606","France","FR","FRA","Centre-Val de Loire","","12522","1250906399"
"Grass Valley","Grass Valley","39.2238","-121.0522","United States","US","USA","California","","13550","1840020225"
"Western Springs","Western Springs","41.8023","-87.9006","United States","US","USA","Illinois","","13550","1840011333"
"Malone","Malone","44.7956","-74.2859","United States","US","USA","New York","","13547","1840004067"
"Jardim de Piranhas","Jardim de Piranhas","-6.3789","-37.3519","Brazil","BR","BRA","Rio Grande do Norte","","13506","1076324896"
"Chalma","Chalma","21.2167","-98.4000","Mexico","MX","MEX","Veracruz","minor","13479","1484039198"
"Ilamatlán","Ilamatlan","20.7833","-98.4500","Mexico","MX","MEX","Veracruz","minor","13474","1484806313"
"Bhankarpur","Bhankarpur","30.6500","76.8200","India","IN","IND","Punjab","","12165","1356665921"
"Bāsmenj","Basmenj","37.9964","46.4717","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","12692","1364033031"
"Flint","Flint","53.2482","-3.1358","United Kingdom","GB","GBR","Flintshire","","12953","1826504781"
"Calderara di Reno","Calderara di Reno","44.5667","11.2667","Italy","IT","ITA","Emilia-Romagna","","13224","1380049846"
"Chañaral","Chanaral","-26.3444","-70.6219","Chile","CL","CHL","Atacama","minor","13543","1152993625"
"Alexandria","Alexandria","-6.4128","-38.0158","Brazil","BR","BRA","Rio Grande do Norte","","13507","1076254681"
"San Rafael","San Rafael","14.7333","-88.4167","Honduras","HN","HND","Lempira","","13410","1340725432"
"L’Union","L'Union","43.6564","1.4844","France","FR","FRA","Occitanie","","11798","1250011667"
"Knittelfeld","Knittelfeld","47.2150","14.8294","Austria","AT","AUT","Steiermark","","12626","1040186382"
"Mechtras","Mechtras","36.5448","4.0049","Algeria","DZ","DZA","Tizi Ouzou","","12683","1012201497"
"Sant Sadurní d’Anoia","Sant Sadurni d'Anoia","41.4261","1.7850","Spain","ES","ESP","Catalonia","","12863","1724266131"
"Patate","Patate","-1.3167","-78.5167","Ecuador","EC","ECU","Tungurahua","","13497","1218528663"
"Le Relecq-Kerhuon","Le Relecq-Kerhuon","48.4086","-4.3969","France","FR","FRA","Bretagne","","11717","1250504198"
"Neustadt","Neustadt","49.5967","10.6089","Germany","DE","DEU","Bavaria","minor","13319","1276596771"
"Charxin","Charxin","39.6967","66.7686","Uzbekistan","UZ","UZB","Samarqand","","13300","1860828377"
"Port Neches","Port Neches","29.9765","-93.9459","United States","US","USA","Texas","","13534","1840020920"
"Samālsar","Samalsar","30.6364","74.9983","India","IN","IND","Punjab","","13161","1356305754"
"Moissac","Moissac","44.1047","1.0853","France","FR","FRA","Occitanie","","13378","1250905510"
"Wiesmoor","Wiesmoor","53.4000","7.7333","Germany","DE","DEU","Lower Saxony","","13372","1276001031"
"Chimá","Chima","9.1500","-75.6333","Colombia","CO","COL","Córdoba","minor","13492","1170217235"
"Glen Carbon","Glen Carbon","38.7580","-89.9822","United States","US","USA","Illinois","","13532","1840012795"
"Tezze sul Brenta","Tezze sul Brenta","45.6862","11.7042","Italy","IT","ITA","Veneto","","12817","1380238354"
"Arendonk","Arendonk","51.3167","5.0833","Belgium","BE","BEL","Flanders","","13290","1056153119"
"Besigheim","Besigheim","48.9983","9.1417","Germany","DE","DEU","Baden-Württemberg","","12772","1276386454"
"Patti","Patti","38.1389","14.9648","Italy","IT","ITA","Sicilia","","13266","1380086020"
"Gex","Gex","46.3333","6.0578","France","FR","FRA","Auvergne-Rhône-Alpes","minor","13121","1250045400"
"Küssnacht","Kussnacht","47.0667","8.4333","Switzerland","CH","CHE","Schwyz","minor","13084","1756750260"
"Lake Forest Park","Lake Forest Park","47.7574","-122.2864","United States","US","USA","Washington","","13529","1840019837"
"Aubergenville","Aubergenville","48.9583","1.8550","France","FR","FRA","Île-de-France","","12152","1250246824"
"Gricignano d’Aversa","Gricignano d'Aversa","41.0000","14.1833","Italy","IT","ITA","Campania","","12296","1380461828"
"General Belgrano","General Belgrano","-35.7667","-58.5000","Argentina","AR","ARG","Buenos Aires","minor","13516","1032451126"
"Lavagna","Lavagna","44.3167","9.3333","Italy","IT","ITA","Liguria","","12617","1380105250"
"Kofelē","Kofele","7.0000","38.7500","Ethiopia","ET","ETH","Oromīya","","13127","1231392237"
"Libertad","Libertad","8.5583","124.3500","Philippines","PH","PHL","Misamis Oriental","","12948","1608897342"
"Águas Vermelhas","Aguas Vermelhas","-15.7469","-41.4600","Brazil","BR","BRA","Minas Gerais","","13513","1076605464"
"Sault Ste. Marie","Sault Ste. Marie","46.4817","-84.3723","United States","US","USA","Michigan","","13523","1840003938"
"San Juan de Betulia","San Juan de Betulia","9.2756","-75.2456","Colombia","CO","COL","Sucre","minor","13437","1170286513"
"Dok Kham Tai","Dok Kham Tai","19.1620","99.9926","Thailand","TH","THA","Phayao","minor","13450","1764998774"
"Çayıralan","Cayiralan","39.3050","35.6444","Turkey","TR","TUR","Yozgat","minor","13512","1792865323"
"Manatí","Manati","10.4450","-74.9597","Colombia","CO","COL","Atlántico","minor","13456","1170827497"
"Matias Barbosa","Matias Barbosa","-21.8689","-43.3189","Brazil","BR","BRA","Minas Gerais","","13435","1076881695"
"Lislique","Lislique","13.8000","-87.8833","El Salvador","SV","SLV","La Unión","","13385","1222181387"
"Roudnice nad Labem","Roudnice nad Labem","50.4254","14.2618","Czechia","CZ","CZE","Ústecký Kraj","","12770","1203268404"
"Birdāban","Birdaban","26.4012","84.9934","India","IN","IND","Bihār","","12083","1356428155"
"Mayenne","Mayenne","48.3031","-0.6136","France","FR","FRA","Pays de la Loire","minor","12874","1250001626"
"Mosgiel","Mosgiel","-45.8750","170.3486","New Zealand","NZ","NZL","Otago","","13400","1554341062"
"Pôrto Esperidião","Porto Esperidiao","-15.8528","-58.4600","Brazil","BR","BRA","Mato Grosso","","11535","1076867575"
"Loreto","Loreto","43.4389","13.6086","Italy","IT","ITA","Marche","","12802","1380440033"
"Kissane Ltouqi","Kissane Ltouqi","34.6000","-5.0833","Morocco","MA","MAR","Fès-Meknès","","13390","1504247609"
"Casale sul Sile","Casale sul Sile","45.6000","12.3333","Italy","IT","ITA","Veneto","","13032","1380855247"
"Keisen","Keisen","33.5788","130.6781","Japan","JP","JPN","Fukuoka","","12876","1392003158"
"Neunkirchen","Neunkirchen","47.7269","16.0817","Austria","AT","AUT","Niederösterreich","minor","12879","1040203057"
"Pentapādu Kasba","Pentapadu Kasba","16.7939","81.5403","India","IN","IND","Andhra Pradesh","","12889","1356836151"
"Royse City","Royse City","32.9762","-96.3175","United States","US","USA","Texas","","13512","1840020718"
"Pervomaysk","Pervomaysk","54.8667","43.8000","Russia","RU","RUS","Nizhegorodskaya Oblast’","","13496","1643783903"
"Lavello","Lavello","41.0500","15.8000","Italy","IT","ITA","Basilicata","","13411","1380330095"
"Luchenza","Luchenza","-16.0167","35.3000","Malawi","MW","MWI","Thyolo","","12600","1454389807"
"Goudomp","Goudomp","12.5778","-15.8722","Senegal","SN","SEN","Sédhiou","","13394","1686688910"
"Vitry-le-François","Vitry-le-Francois","48.7247","4.5844","France","FR","FRA","Grand Est","minor","11743","1250909820"
"Trogir","Trogir","43.5169","16.2514","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","13260","1191315860"
"Pāiker","Paiker","24.4388","87.9073","India","IN","IND","West Bengal","","12250","1356281686"
"Yecuatla","Yecuatla","19.8521","-96.7792","Mexico","MX","MEX","Veracruz","minor","12108","1484655912"
"Bacup","Bacup","53.7040","-2.1990","United Kingdom","GB","GBR","Lancashire","","13323","1826120221"
"Sondho Dullāh","Sondho Dullah","25.9016","85.3569","India","IN","IND","Bihār","","11951","1356118415"
"Ubstadt-Weiher","Ubstadt-Weiher","49.1656","8.6250","Germany","DE","DEU","Baden-Württemberg","","13143","1276999466"
"Quattro Castella","Quattro Castella","44.6333","10.4667","Italy","IT","ITA","Emilia-Romagna","","13217","1380509604"
"Andasibe","Andasibe","-18.9333","48.4167","Madagascar","MG","MDG","Toamasina","","13493","1450648431"
"Brunn am Gebirge","Brunn am Gebirge","48.1069","16.2842","Austria","AT","AUT","Niederösterreich","","11864","1040836072"
"Bāgli","Bagli","22.6412","76.3488","India","IN","IND","Madhya Pradesh","","13368","1356499857"
"Henderson","Henderson","32.1576","-94.7960","United States","US","USA","Texas","","13498","1840020767"
"Zehdenick","Zehdenick","52.9833","13.3333","Germany","DE","DEU","Brandenburg","","13437","1276607810"
"Tuchola","Tuchola","53.6000","17.8500","Poland","PL","POL","Kujawsko-Pomorskie","minor","13408","1616160656"
"Māyamānkurichchi","Mayamankurichchi","8.8855","77.5041","India","IN","IND","Tamil Nādu","","12528","1356202495"
"Tummanatti","Tummanatti","11.4284","76.7585","India","IN","IND","Tamil Nādu","","13275","1356750225"
"Fereydūnshahr","Fereydunshahr","32.9411","50.1211","Iran","IR","IRN","Eşfahān","minor","13475","1364461971"
"Govindgarh","Govindgarh","24.3785","81.2964","India","IN","IND","Madhya Pradesh","","12800","1356190496"
"Hathauri","Hathauri","25.9593","85.9747","India","IN","IND","Bihār","","11973","1356107222"
"Formello","Formello","42.0833","12.4000","Italy","IT","ITA","Lazio","","13070","1380473948"
"Kādiyāmpatti","Kadiyampatti","11.8720","78.0961","India","IN","IND","Tamil Nādu","","12625","1356641867"
"Standish","Standish","53.5860","-2.6640","United Kingdom","GB","GBR","Wigan","","13278","1826196984"
"Seabrook","Seabrook","29.5751","-95.0235","United States","US","USA","Texas","","13487","1840022197"
"San Juan de Limay","San Juan de Limay","13.1739","-86.6128","Nicaragua","NI","NIC","Estelí","minor","13455","1558155206"
"Unión de Tula","Union de Tula","19.9570","-104.2680","Mexico","MX","MEX","Jalisco","minor","13446","1484444951"
"Bischwiller","Bischwiller","48.7664","7.8569","France","FR","FRA","Grand Est","","12746","1250001641"
"Pathra","Pathra","24.8804","87.0447","India","IN","IND","Bihār","","12219","1356037226"
"Ataco","Ataco","3.6000","-75.3833","Colombia","CO","COL","Tolima","minor","13470","1170693038"
"Rionero in Vulture","Rionero in Vulture","40.9167","15.6667","Italy","IT","ITA","Basilicata","","13236","1380324946"
"Várzea Nova","Varzea Nova","-11.2589","-40.9419","Brazil","BR","BRA","Bahia","","13470","1076669086"
"Wasserburg am Inn","Wasserburg am Inn","48.0617","12.2333","Germany","DE","DEU","Bavaria","","12800","1276900487"
"Al Ḩā’ir","Al Ha'ir","25.7900","45.3800","Saudi Arabia","SA","SAU","Ar Riyāḑ","","13473","1682572501"
"Frederiksværk","Frederiksvaerk","55.9667","12.0167","Denmark","DK","DNK","Hovedstaden","minor","12718","1208825906"
"Jucuarán","Jucuaran","13.2544","-88.2489","El Salvador","SV","SLV","Usulután","","13424","1222034395"
"San Juan","San Juan","14.4167","-88.4333","Honduras","HN","HND","Intibucá","","13405","1340811694"
"Dambal","Dambal","15.2960","75.7737","India","IN","IND","Karnātaka","","13325","1356008490"
"Aguilar","Aguilar","37.5167","-4.6500","Spain","ES","ESP","Andalusia","","13398","1724391215"
"Rio Casca","Rio Casca","-20.2258","-42.6508","Brazil","BR","BRA","Minas Gerais","","13473","1076162657"
"Kalārdasht","Kalardasht","36.5167","51.1667","Iran","IR","IRN","Māzandarān","","13401","1364243164"
"Severn","Severn","44.7500","-79.5167","Canada","CA","CAN","Ontario","","13477","1124489890"
"Martinsville","Martinsville","36.6826","-79.8636","United States","US","USA","Virginia","","13476","1840001727"
"Mangarwāra","Mangarwara","26.0119","87.0854","India","IN","IND","Bihār","","12129","1356597404"
"Paripueira","Paripueira","-9.4650","-35.5519","Brazil","BR","BRA","Alagoas","","13332","1076989218"
"Les Ponts-de-Cé","Les Ponts-de-Ce","47.4244","-0.5253","France","FR","FRA","Pays de la Loire","","12819","1250187988"
"Jobat","Jobat","22.4160","74.5683","India","IN","IND","Madhya Pradesh","","13188","1356471365"
"Tenente Portela","Tenente Portela","-27.3708","-53.7578","Brazil","BR","BRA","Rio Grande do Sul","","13434","1076242271"
"Wickede","Wickede","51.4964","7.8658","Germany","DE","DEU","North Rhine-Westphalia","","12959","1276828839"
"Hissāramuruvani","Hissaramuruvani","15.7710","77.4194","India","IN","IND","Andhra Pradesh","","13189","1356635083"
"Dinklage","Dinklage","52.6667","8.1333","Germany","DE","DEU","Lower Saxony","","13290","1276444417"
"Kaspi","Kaspi","41.9250","44.4222","Georgia","GE","GEO","Shida Kartli","minor","13423","1268997310"
"Abre Campo","Abre Campo","-20.3008","-42.4778","Brazil","BR","BRA","Minas Gerais","","13444","1076902568"
"General Viamonte","General Viamonte","-35.0000","-61.0333","Argentina","AR","ARG","Buenos Aires","minor","13462","1032695621"
"Jaca","Jaca","42.5500","-0.5500","Spain","ES","ESP","Aragon","","13437","1724277088"
"Zehak","Zehak","30.8939","61.6803","Iran","IR","IRN","Sīstān va Balūchestān","minor","13357","1364240166"
"Costessey","Costessey","52.6602","1.2161","United Kingdom","GB","GBR","Norfolk","","12463","1826359411"
"Albertirsa","Albertirsa","47.2400","19.6067","Hungary","HU","HUN","Pest","","13286","1348408587"
"Lakkavaram","Lakkavaram","17.0628","81.2919","India","IN","IND","Andhra Pradesh","","12945","1356073499"
"Wake","Wake","34.8029","134.1575","Japan","JP","JPN","Okayama","","13373","1392389367"
"Tirmaigiri","Tirmaigiri","16.7230","79.3374","India","IN","IND","Andhra Pradesh","","12899","1356092592"
"Wehr","Wehr","47.6297","7.9044","Germany","DE","DEU","Baden-Württemberg","","13098","1276432205"
"Bteghrîne","Bteghrine","33.9300","35.7450","Lebanon","LB","LBN","Mont-Liban","","12000","1422572821"
"Kalaiyamputtūr","Kalaiyamputtur","10.4542","77.4831","India","IN","IND","Tamil Nādu","","13013","1356172091"
"Hockessin","Hockessin","39.7837","-75.6815","United States","US","USA","Delaware","","13464","1840005573"
"Pendências","Pendencias","-5.2600","-36.7219","Brazil","BR","BRA","Rio Grande do Norte","","13432","1076860228"
"San Antonio Aguas Calientes","San Antonio Aguas Calientes","14.5333","-90.7667","Guatemala","GT","GTM","Sacatepéquez","minor","12716","1320340484"
"Sirsia Hanumānganj","Sirsia Hanumanganj","26.1381","87.1133","India","IN","IND","Bihār","","11835","1356034616"
"Bad Salzdetfurth","Bad Salzdetfurth","52.0653","10.0092","Germany","DE","DEU","Lower Saxony","","13266","1276420532"
"Sathmalpur","Sathmalpur","25.8749","85.8567","India","IN","IND","Bihār","","11559","1356459117"
"Bichkunda","Bichkunda","18.4000","77.7167","India","IN","IND","Andhra Pradesh","","13213","1356419572"
"Ŭrtaowul","Urtaowul","41.1908","69.1494","Uzbekistan","UZ","UZB","Toshkent","","13271","1860578331"
"Harnes","Harnes","50.4450","2.9058","France","FR","FRA","Hauts-de-France","","12324","1250573539"
"Muisne","Muisne","0.6108","-80.0186","Ecuador","EC","ECU","Esmeraldas","","13393","1218271569"
"Barßel","Barssel","53.1703","7.7467","Germany","DE","DEU","Lower Saxony","","13301","1276212188"
"Irondale","Irondale","33.5439","-86.6606","United States","US","USA","Alabama","","13457","1840014797"
"Pont-Sainte-Maxence","Pont-Sainte-Maxence","49.3011","2.6036","France","FR","FRA","Hauts-de-France","","12602","1250653053"
"Fatehābād","Fatehabad","26.0652","84.9722","India","IN","IND","Bihār","","11645","1356317360"
"Testour","Testour","36.5500","9.4500","Tunisia","TN","TUN","Béja","","13397","1788691139"
"Brig-Glis","Brig-Glis","46.3167","7.9667","Switzerland","CH","CHE","Valais","minor","13109","1756778801"
"Tenambākkam","Tenambakkam","12.8102","79.7341","India","IN","IND","Tamil Nādu","","12351","1356220952"
"Butler","Butler","40.8616","-79.8962","United States","US","USA","Pennsylvania","","13452","1840000857"
"Nakskov","Nakskov","54.8333","11.1500","Denmark","DK","DNK","Sjælland","","12758","1208341545"
"San Jacinto del Cauca","San Jacinto del Cauca","8.2500","-74.7167","Colombia","CO","COL","Bolívar","minor","13426","1170204575"
"Bhagwānpur Desua","Bhagwanpur Desua","25.8124","85.8382","India","IN","IND","Bihār","","12079","1356852037"
"Chiampo","Chiampo","45.5500","11.2833","Italy","IT","ITA","Veneto","","12879","1380934204"
"Khoyniki","Khoyniki","51.8892","29.9644","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","13439","1112972491"
"Itano","Itano","34.1443","134.4626","Japan","JP","JPN","Tokushima","","13087","1392003282"
"Ruza","Ruza","55.7000","36.2000","Russia","RU","RUS","Moskovskaya Oblast’","","13403","1643967155"
"Lumberton","Lumberton","30.2562","-94.2070","United States","US","USA","Texas","","13447","1840020896"
"Elavanasūr","Elavanasur","11.7154","79.1796","India","IN","IND","Tamil Nādu","","12889","1356028126"
"Amondara","Amondara","39.5178","67.8131","Tajikistan","TJ","TJK","Sughd","","13380","1762761916"
"Araruna","Araruna","-23.9319","-52.4958","Brazil","BR","BRA","Paraná","","13419","1076431580"
"San Clemente","San Clemente","15.7119","120.3603","Philippines","PH","PHL","Tarlac","","13181","1608579832"
"Glenpool","Glenpool","35.9488","-96.0052","United States","US","USA","Oklahoma","","13446","1840020384"
"Eatontown","Eatontown","40.2913","-74.0558","United States","US","USA","New Jersey","","13446","1840003679"
"Sangar","Sangar","37.1811","49.6939","Iran","IR","IRN","Gīlān","","12583","1364837477"
"Jimboomba","Jimboomba","-27.8300","153.0313","Australia","AU","AUS","Queensland","","13201","1036214142"
"Arboga","Arboga","59.3939","15.8386","Sweden","SE","SWE","Västmanland","minor","13404","1752494456"
"Gulni","Gulni","24.9309","85.8030","India","IN","IND","Bihār","","12026","1356239988"
"Englewood","Englewood","39.8643","-84.3070","United States","US","USA","Ohio","","13441","1840008435"
"Oboyan","Oboyan","51.2000","36.2833","Russia","RU","RUS","Kurskaya Oblast’","minor","13413","1643628324"
"Dores do Indaiá","Dores do Indaia","-19.4628","-45.6019","Brazil","BR","BRA","Minas Gerais","","13427","1076206387"
"Wernau","Wernau","48.6886","9.4222","Germany","DE","DEU","Baden-Württemberg","","12324","1276040712"
"Akhty","Akhty","41.4647","47.7400","Russia","RU","RUS","Dagestan","","13405","1643631394"
"Jupiter Farms","Jupiter Farms","26.9222","-80.2189","United States","US","USA","Florida","","13437","1840042432"
"Inawashiro","Inawashiro","37.5578","140.1048","Japan","JP","JPN","Fukushima","","13403","1392003280"
"Capaci","Capaci","38.1667","13.2333","Italy","IT","ITA","Sicilia","","11549","1380855471"
"Keerbergen","Keerbergen","51.0031","4.6311","Belgium","BE","BEL","Flanders","","12743","1056257778"
"River Ridge","River Ridge","29.9593","-90.2202","United States","US","USA","Louisiana","","13435","1840013995"
"Plover","Plover","44.4615","-89.5382","United States","US","USA","Wisconsin","","13435","1840002328"
"Kamalāpuram","Kamalapuram","11.7680","78.0599","India","IN","IND","Tamil Nādu","","12570","1356248016"
"Nangavalli","Nangavalli","11.7619","77.8909","India","IN","IND","Tamil Nādu","","12772","1356501976"
"Tucapel","Tucapel","-37.2833","-71.9500","Chile","CL","CHL","Biobío","","13427","1152537993"
"Pegnitz","Pegnitz","49.7564","11.5450","Germany","DE","DEU","Bavaria","","13298","1276197483"
"Marshall","Marshall","39.1147","-93.2010","United States","US","USA","Missouri","","13431","1840008509"
"Golyshmanovo","Golyshmanovo","56.3819","68.3715","Russia","RU","RUS","Tyumenskaya Oblast’","","13424","1643817304"
"Tauberbischofsheim","Tauberbischofsheim","49.6225","9.6628","Germany","DE","DEU","Baden-Württemberg","minor","13238","1276697756"
"Calcinaia","Calcinaia","43.6833","10.6167","Italy","IT","ITA","Tuscany","","12582","1380579954"
"Bardstown","Bardstown","37.8175","-85.4550","United States","US","USA","Kentucky","","13425","1840013219"
"Itapuí","Itapui","-22.2333","-48.7192","Brazil","BR","BRA","São Paulo","","13328","1076494773"
"Barsbüttel","Barsbuttel","53.5667","10.1667","Germany","DE","DEU","Schleswig-Holstein","","12899","1276921067"
"El Tabo","El Tabo","-33.4586","-71.6619","Chile","CL","CHL","Valparaíso","","13286","1152719676"
"Strand","Strand","59.0633","6.0278","Norway","NO","NOR","Rogaland","","13268","1578528180"
"Befandriana","Befandriana","-15.2667","48.5333","Madagascar","MG","MDG","Mahajanga","","13000","1450151702"
"King City","King City","36.2164","-121.1330","United States","US","USA","California","","13419","1840020360"
"Newington","Newington","38.7358","-77.1993","United States","US","USA","Virginia","","13419","1840006021"
"Bollnäs","Bollnas","61.3481","16.3947","Sweden","SE","SWE","Gävleborg","minor","13398","1752287863"
"Barlinek","Barlinek","53.0000","15.2000","Poland","PL","POL","Zachodniopomorskie","","13339","1616433075"
"Sendenhorst","Sendenhorst","51.8439","7.8278","Germany","DE","DEU","North Rhine-Westphalia","","13279","1276094140"
"Aconibe","Aconibe","1.3000","10.9333","Equatorial Guinea","GQ","GNQ","Wele-Nzas","","13382","1226173790"
"Nemours","Nemours","48.2686","2.6936","France","FR","FRA","Île-de-France","","13165","1250798290"
"Khombole","Khombole","14.7667","-16.6833","Senegal","SN","SEN","Thiès","","12880","1686305401"
"Koskāpur","Koskapur","26.2669","87.1465","India","IN","IND","Bihār","","12348","1356961860"
"Saka","Saka","34.3412","132.5136","Japan","JP","JPN","Hiroshima","","12611","1392541121"
"Nishon Tumani","Nishon Tumani","38.6558","65.6994","Uzbekistan","UZ","UZB","Qashqadaryo","","13300","1860315743"
"Wath upon Dearne","Wath upon Dearne","53.5022","-1.3402","United Kingdom","GB","GBR","Rotherham","","11816","1826701184"
"Lewisville","Lewisville","36.1030","-80.4166","United States","US","USA","North Carolina","","13413","1840016124"
"Pottsville","Pottsville","40.6798","-76.2091","United States","US","USA","Pennsylvania","","13413","1840002775"
"Seyitgazi","Seyitgazi","39.4456","30.6939","Turkey","TR","TUR","Eskişehir","minor","13405","1792468477"
"Mozarlândia","Mozarlandia","-14.7450","-50.5708","Brazil","BR","BRA","Goiás","","13404","1076012080"
"Santa Bárbara","Santa Barbara","-37.6706","-72.0214","Chile","CL","CHL","Biobío","","13405","1152827647"
"Singhbāri","Singhbari","25.3946","88.0131","India","IN","IND","West Bengal","","12003","1356043177"
"Fåberg","Faberg","61.1684","10.4048","Norway","NO","NOR","Innlandet","","13381","1578605281"
"Pomfret","Pomfret","42.4029","-79.3534","United States","US","USA","New York","","13409","1840087818"
"Serrolândia","Serrolandia","-11.4158","-40.3019","Brazil","BR","BRA","Bahia","","13373","1076239269"
"Eraniel","Eraniel","8.2059","77.3173","India","IN","IND","Tamil Nādu","","12282","1356662397"
"Mahtha","Mahtha","26.5988","86.3158","India","IN","IND","Bihār","","12715","1356428225"
"Espita","Espita","21.0128","-88.3047","Mexico","MX","MEX","Yucatán","minor","11551","1484433962"
"Qigexingcun","Qigexingcun","42.0200","86.3031","China","CN","CHN","Xinjiang","","13352","1156430883"
"Shanyincun","Shanyincun","37.9151","114.4126","China","CN","CHN","Hebei","","12957","1156553999"
"Plattling","Plattling","48.7667","12.8667","Germany","DE","DEU","Bavaria","","13043","1276078281"
"Pritzwalk","Pritzwalk","53.1500","12.1833","Germany","DE","DEU","Brandenburg","","13336","1276349904"
"Rocky Point","Rocky Point","40.9357","-72.9364","United States","US","USA","New York","","13406","1840005070"
"Rosas","Rosas","2.2667","-76.7500","Colombia","CO","COL","Cauca","minor","13302","1170048993"
"Lakhipur","Lakhipur","24.8000","93.0200","India","IN","IND","Assam","","12938","1356065191"
"Atlantic Beach","Atlantic Beach","30.3375","-81.4127","United States","US","USA","Florida","","13404","1840013956"
"Morąg","Morag","53.9167","19.9333","Poland","PL","POL","Warmińsko-Mazurskie","","13317","1616114206"
"Kalikiri","Kalikiri","13.6333","78.8000","India","IN","IND","Andhra Pradesh","","13065","1356592994"
"Palos Verdes Estates","Palos Verdes Estates","33.7872","-118.3973","United States","US","USA","California","","13403","1840020503"
"Carapebus","Carapebus","-22.1869","-41.6608","Brazil","BR","BRA","Rio de Janeiro","","13359","1076355795"
"Buchs","Buchs","47.1667","9.4667","Switzerland","CH","CHE","Sankt Gallen","","12612","1756006204"
"Luís Antônio","Luis Antonio","-21.5550","-47.7044","Brazil","BR","BRA","São Paulo","","13378","1076517541"
"Chüy","Chuy","42.8100","75.2800","Kyrgyzstan","KG","KGZ","Chüy","minor","12688","1417150707"
"Oxford","Oxford","42.1286","-71.8665","United States","US","USA","Massachusetts","","13399","1840053601"
"Pandireddigūdem","Pandireddigudem","16.8000","81.3167","India","IN","IND","Andhra Pradesh","","13043","1356551071"
"Gmunden","Gmunden","47.9181","13.7994","Austria","AT","AUT","Oberösterreich","minor","13191","1040670341"
"Dossenheim","Dossenheim","49.4492","8.6722","Germany","DE","DEU","Baden-Württemberg","","12515","1276116472"
"Cariamanga","Cariamanga","-4.3200","-79.5600","Ecuador","EC","ECU","Loja","","13311","1218774934"
"Matsushima","Matsushima","38.3802","141.0673","Japan","JP","JPN","Miyagi","","13151","1392903558"
"Tahannawt","Tahannawt","31.3514","-7.9508","Morocco","MA","MAR","Marrakech-Safi","","12102","1504266057"
"Veyrier","Veyrier","46.1667","6.1833","Switzerland","CH","CHE","Genève","","11609","1756988678"
"Paso de los Toros","Paso de los Toros","-32.8112","-56.5086","Uruguay","UY","URY","Durazno","","12985","1858768851"
"Zelenodolsk","Zelenodolsk","47.5631","33.6524","Ukraine","UA","UKR","Dnipropetrovska Oblast","","13349","1804394068"
"Kallanai","Kallanai","10.0374","78.0829","India","IN","IND","Tamil Nādu","","12245","1356217780"
"Crissiumal","Crissiumal","-27.5000","-54.1008","Brazil","BR","BRA","Rio Grande do Sul","","13357","1076648862"
"Nolensville","Nolensville","35.9572","-86.6719","United States","US","USA","Tennessee","","13393","1840016219"
"Dommasandra","Dommasandra","12.8781","77.7543","India","IN","IND","Karnātaka","","12097","1356933363"
"Carpenedolo","Carpenedolo","45.3654","10.4323","Italy","IT","ITA","Lombardy","","12957","1380820310"
"Pushing","Pushing","38.1833","69.4333","Tajikistan","TJ","TJK","Khatlon","","13293","1762385172"
"Eiras","Eiras","40.2421","-8.4240","Portugal","PT","PRT","Coimbra","","12053","1620679868"
"Grimstad","Grimstad","58.3405","8.5934","Norway","NO","NOR","Agder","minor","13304","1578703293"
"Yengema","Yengema","8.6167","-11.1667","Sierra Leone","SL","SLE","Eastern","","13358","1694224678"
"Varazze","Varazze","44.3600","8.5766","Italy","IT","ITA","Liguria","","13113","1380033309"
"Santa Marcela","Santa Marcela","18.2872","121.4375","Philippines","PH","PHL","Apayao","","13317","1608825041"
"Shibām","Shibam","15.9269","48.6267","Yemen","YE","YEM","Ḩaḑramawt","","13316","1887765734"
"Velen","Velen","51.8939","6.9897","Germany","DE","DEU","North Rhine-Westphalia","","13198","1276638227"
"Soyaniquilpan","Soyaniquilpan","19.9892","-99.4361","Mexico","MX","MEX","México","minor","13290","1484895616"
"Santa Filomena","Santa Filomena","-8.1628","-40.6158","Brazil","BR","BRA","Pernambuco","","13371","1076635908"
"Pāpampeta","Papampeta","14.6855","77.5780","India","IN","IND","Andhra Pradesh","","12084","1356610077"
"Calle Larga","Calle Larga","-32.8831","-70.6486","Chile","CL","CHL","Valparaíso","","13366","1152509615"
"Port Victoria","Port Victoria","0.1000","33.9833","Kenya","KE","KEN","Busia","","12194","1404757827"
"Hopewell","Hopewell","40.5906","-80.2731","United States","US","USA","Pennsylvania","","13381","1840146082"
"Carros","Carros","43.7725","7.1933","France","FR","FRA","Provence-Alpes-Côte d’Azur","","12550","1250649127"
"Affoltern am Albis","Affoltern am Albis","47.2833","8.4500","Switzerland","CH","CHE","Zürich","","12226","1756025824"
"Rüti","Ruti","47.2667","8.8500","Switzerland","CH","CHE","Zürich","","12170","1756152565"
"Mogeiro","Mogeiro","-7.2989","-35.4789","Brazil","BR","BRA","Paraíba","","13317","1076034911"
"Joaquín V. González","Joaquin V. Gonzalez","-25.0833","-64.1833","Argentina","AR","ARG","Salta","minor","13376","1032690861"
"Tachov","Tachov","49.7954","12.6337","Czechia","CZ","CZE","Plzeňský Kraj","","13072","1203227002"
"San José de Maipo","San Jose de Maipo","-33.6833","-70.3333","Chile","CL","CHL","Región Metropolitana","","13376","1152128380"
"Chalfont Saint Peter","Chalfont Saint Peter","51.6070","-0.5560","United Kingdom","GB","GBR","Buckinghamshire","","12766","1826655909"
"El Almendro","El Almendro","11.6781","-84.7014","Nicaragua","NI","NIC","Río San Juan","minor","13363","1558104640"
"Ribeira Brava","Ribeira Brava","32.6833","-17.0500","Portugal","PT","PRT","Madeira","","13375","1620853683"
"Sainte-Sophie","Sainte-Sophie","45.8200","-73.9000","Canada","CA","CAN","Quebec","","13375","1124001574"
"Guéret","Gueret","46.1706","1.8683","France","FR","FRA","Nouvelle-Aquitaine","minor","12889","1250176932"
"Itagi","Itagi","-14.1628","-40.0058","Brazil","BR","BRA","Bahia","","13359","1076065946"
"Diósd","Diosd","47.4042","18.9458","Hungary","HU","HUN","Pest","","11393","1348180472"
"Roßdorf","Rossdorf","49.8583","8.7556","Germany","DE","DEU","Hesse","","12755","1276485958"
"Peddakūrapādu","Peddakurapadu","16.4833","80.2667","India","IN","IND","Andhra Pradesh","","13014","1356574972"
"Palombara Sabina","Palombara Sabina","42.0667","12.7667","Italy","IT","ITA","Lazio","","13200","1380033023"
"Cavallino","Cavallino","40.3167","18.2000","Italy","IT","ITA","Puglia","","12808","1380885640"
"Elfers","Elfers","28.2140","-82.7230","United States","US","USA","Florida","","13373","1840013122"
"Forrest City","Forrest City","35.0135","-90.7931","United States","US","USA","Arkansas","","13372","1840013522"
"Barentin","Barentin","49.5444","0.9536","France","FR","FRA","Normandie","","12398","1250116522"
"Bag‘dod","Bag\`dod","40.4606","71.2214","Uzbekistan","UZ","UZB","Farg‘ona","","12800","1860308372"
"Yozyovon","Yozyovon","40.6619","71.7431","Uzbekistan","UZ","UZB","Farg‘ona","minor","12800","1860299143"
"Rödental","Rodental","50.2833","11.0667","Germany","DE","DEU","Bavaria","","13107","1276302053"
"Autun","Autun","46.9511","4.2986","France","FR","FRA","Bourgogne-Franche-Comté","minor","13157","1250474820"
"Tōnoshō","Tonosho","35.8372","140.6687","Japan","JP","JPN","Chiba","","13085","1392003419"
"La Queue-en-Brie","La Queue-en-Brie","48.7894","2.5767","France","FR","FRA","Île-de-France","","12051","1250433597"
"Farādonbeh","Faradonbeh","32.0078","51.2156","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","13317","1364199475"
"North Walsham","North Walsham","52.8214","1.3861","United Kingdom","GB","GBR","Norfolk","","12634","1826981933"
"Katagon","Katagon","6.6333","2.5833","Benin","BJ","BEN","Ouémé","","12173","1204968472"
"Kilgore","Kilgore","32.3980","-94.8602","United States","US","USA","Texas","","13364","1840020747"
"Yakage","Yakage","34.6275","133.5872","Japan","JP","JPN","Okayama","","13218","1392939800"
"Narhat","Narhat","24.7774","85.4237","India","IN","IND","Bihār","","12088","1356762189"
"Qal‘at al Maḑīq","Qal\`at al Madiq","35.4100","36.3900","Syria","SY","SYR","Ḩamāh","minor","12925","1760079003"
"Fatehpur","Fatehpur","25.2463","87.0380","India","IN","IND","Bihār","","13017","1356278039"
"Dębno","Debno","52.7333","14.7000","Poland","PL","POL","Zachodniopomorskie","","13291","1616728902"
"Ingeniero Maschwitz","Ingeniero Maschwitz","-34.3667","-58.7500","Argentina","AR","ARG","Buenos Aires","","12482","1032533871"
"Lincolnwood","Lincolnwood","42.0054","-87.7330","United States","US","USA","Illinois","","13358","1840011259"
"Novi Marof","Novi Marof","46.1667","16.3333","Croatia","HR","HRV","Varaždinska Županija","minor","13246","1191054242"
"Ottersberg","Ottersberg","53.1000","9.1500","Germany","DE","DEU","Lower Saxony","","13224","1276986240"
"Ambodilazana","Ambodilazana","-18.1194","49.1208","Madagascar","MG","MDG","Toamasina","","13304","1450502468"
"Kūdangulam","Kudangulam","8.1903","77.7000","India","IN","IND","Tamil Nādu","","12957","1356249202"
"Lüderitz","Luderitz","-26.6458","15.1539","Namibia","NA","NAM","//Karas","","12537","1516141896"
"Dahu","Dahu","24.3978","120.8486","Taiwan","TW","TWN","Miaoli","","13210","1158685093"
"Sudbury","Sudbury","52.0417","0.7282","United Kingdom","GB","GBR","Suffolk","","13063","1826131737"
"Nova Veneza","Nova Veneza","-28.6369","-49.4978","Brazil","BR","BRA","Santa Catarina","","13309","1076000591"
"Puerto Morazán","Puerto Morazan","12.7669","-87.1317","Nicaragua","NI","NIC","Chinandega","","13328","1558742913"
"Kamikawa","Kamikawa","36.2139","139.1017","Japan","JP","JPN","Saitama","","13077","1392003364"
"Flitwick","Flitwick","52.0038","-0.4966","United Kingdom","GB","GBR","Central Bedfordshire","","12700","1826751494"
"Bugugoucun","Bugugoucun","41.6904","117.4483","China","CN","CHN","Hebei","","13266","1156450424"
"Shīn","Shin","34.7833","36.4333","Syria","SY","SYR","Ḩimş","minor","13020","1760607600"
"Aubenas","Aubenas","44.6197","4.3903","France","FR","FRA","Auvergne-Rhône-Alpes","","12479","1250555001"
"Český Krumlov","Cesky Krumlov","48.8111","14.3153","Czechia","CZ","CZE","Jihočeský Kraj","","12788","1203973467"
"Oudenbosch","Oudenbosch","51.5892","4.5239","Netherlands","NL","NLD","Noord-Brabant","minor","12780","1528311277"
"Nordestina","Nordestina","-10.8228","-39.4278","Brazil","BR","BRA","Bahia","","13321","1076755188"
"Coolidge","Coolidge","32.9363","-111.5254","United States","US","USA","Arizona","","13347","1840019367"
"Tanagura","Tanagura","37.0299","140.3796","Japan","JP","JPN","Fukushima","","13264","1392922842"
"Shetpe","Shetpe","44.1667","52.1167","Kazakhstan","KZ","KAZ","","","13346","1398066409"
"Castiglion Fiorentino","Castiglion Fiorentino","43.3439","11.9189","Italy","IT","ITA","Tuscany","","13228","1380916249"
"Tehachapi","Tehachapi","35.1276","-118.4744","United States","US","USA","California","","13346","1840021737"
"Itiruçu","Itirucu","-13.5319","-40.1500","Brazil","BR","BRA","Bahia","","13307","1076026512"
"Cowdenbeath","Cowdenbeath","56.1100","-3.3500","United Kingdom","GB","GBR","Fife","","11640","1826932190"
"Odder","Odder","55.9725","10.1497","Denmark","DK","DNK","Midtjylland","minor","12454","1208566393"
"Kasumkent","Kasumkent","41.6667","48.1333","Russia","RU","RUS","Dagestan","","13232","1643247115"
"Kūhbil","Kuhbil","36.5133","41.9542","Iraq","IQ","IRQ","Nīnawá","","13281","1368670995"
"Tarhūnah","Tarhunah","32.4339","13.6344","Libya","LY","LBY","Ţarābulus","","13264","1434109178"
"Ficarazzi","Ficarazzi","38.0833","13.4667","Italy","IT","ITA","Sicilia","","13080","1380754605"
"Moorreesburg","Moorreesburg","-33.1500","18.6667","South Africa","ZA","ZAF","Western Cape","","12877","1710940552"
"Porto Recanati","Porto Recanati","43.4322","13.6647","Italy","IT","ITA","Marche","","12609","1380228995"
"Tirumakūdal Narsipur","Tirumakudal Narsipur","12.2121","76.9018","India","IN","IND","Karnātaka","","13103","1356885614"
"Hüllhorst","Hullhorst","52.2833","8.6667","Germany","DE","DEU","North Rhine-Westphalia","","13047","1276646127"
"Baş Göynük","Bas Goynuk","41.3228","47.1133","Azerbaijan","AZ","AZE","Şəki","","13260","1031767565"
"Demerval Lobão","Demerval Lobao","-5.3578","-42.6758","Brazil","BR","BRA","Piauí","","13278","1076667213"
"Shāhpura","Shahpura","23.1828","80.6997","India","IN","IND","Madhya Pradesh","","12471","1356043629"
"Guillena","Guillena","37.5333","-6.0500","Spain","ES","ESP","Andalusia","","13279","1724767687"
"Bombarral","Bombarral","39.2681","-9.1553","Portugal","PT","PRT","Leiria","minor","13193","1620112554"
"Trebaseleghe","Trebaseleghe","45.5833","12.0500","Italy","IT","ITA","Veneto","","12916","1380826282"
"Ban Chomphu Nuea","Ban Chomphu Nuea","16.6833","100.6667","Thailand","TH","THA","Phitsanulok","","13266","1764044071"
"Arma","Arma","25.1966","86.2468","India","IN","IND","Bihār","","12372","1356863436"
"Abrera","Abrera","41.5165","1.9024","Spain","ES","ESP","Catalonia","","12697","1724786014"
"Picaña","Picana","39.4361","-0.4350","Spain","ES","ESP","Valencia","","11692","1724147430"
"Chiroqchi","Chiroqchi","39.0336","66.5739","Uzbekistan","UZ","UZB","Qashqadaryo","","13220","1860377999"
"Al Muzayrīb","Al Muzayrib","32.7109","36.0266","Syria","SY","SYR","Dar‘ā","minor","12640","1760935578"
"Jitaúna","Jitauna","-14.0189","-39.8889","Brazil","BR","BRA","Bahia","","13300","1076105440"
"Engenheiro Paulo de Frontin","Engenheiro Paulo de Frontin","-22.5500","-43.6778","Brazil","BR","BRA","Rio de Janeiro","","13237","1076890953"
"Puerto San Martín","Puerto San Martin","-32.7167","-60.7333","Argentina","AR","ARG","Santa Fe","","13243","1032928261"
"Amity","Amity","40.2905","-75.7477","United States","US","USA","Pennsylvania","","13330","1840151686"
"Miarinarivo","Miarinarivo","-18.9608","46.9000","Madagascar","MG","MDG","Antananarivo","minor","13109","1450403783"
"Harwich","Harwich","41.6957","-70.0684","United States","US","USA","Massachusetts","","13329","1840070211"
"Cremlingen","Cremlingen","52.2489","10.6542","Germany","DE","DEU","Lower Saxony","","13111","1276495554"
"Aš","As","50.2239","12.1950","Czechia","CZ","CZE","Karlovarský Kraj","","13105","1203503000"
"Sítio do Mato","Sitio do Mato","-13.0850","-43.4650","Brazil","BR","BRA","Bahia","","13320","1076645517"
"Weinsberg","Weinsberg","49.1518","9.2857","Germany","DE","DEU","Baden-Württemberg","","12753","1276634003"
"Vilāngurichchi","Vilangurichchi","11.0709","77.0178","India","IN","IND","Tamil Nādu","","12043","1356160164"
"Kallūr","Kallur","16.1405","77.2081","India","IN","IND","Karnātaka","","13107","1356064038"
"Sint-Michiels","Sint-Michiels","51.1833","3.2167","Belgium","BE","BEL","Flanders","","12045","1056174386"
"Mānākondūr","Manakondur","18.3981","79.2250","India","IN","IND","Andhra Pradesh","","12687","1356353668"
"Wolfhagen","Wolfhagen","51.3167","9.1667","Germany","DE","DEU","Hesse","","13205","1276757955"
"Fort Campbell North","Fort Campbell North","36.6631","-87.4764","United States","US","USA","Kentucky","","13323","1840073832"
"Pfarrkirchen","Pfarrkirchen","48.4167","12.9167","Germany","DE","DEU","Bavaria","minor","13073","1276885935"
"Berezne","Berezne","51.0000","26.7500","Ukraine","UA","UKR","Rivnenska Oblast","","13285","1804802755"
"Washington","Washington","40.1741","-80.2465","United States","US","USA","Pennsylvania","","13322","1840003668"
"Reggada","Reggada","29.5716","-9.6972","Morocco","MA","MAR","Souss-Massa","","13284","1504921230"
"Saint-Charles-Borromée","Saint-Charles-Borromee","46.0500","-73.4667","Canada","CA","CAN","Quebec","","13321","1124000877"
"Capo d’Orlando","Capo d'Orlando","38.1500","14.7333","Italy","IT","ITA","Sicilia","","13320","1380000117"
"Rabot","Rabot","38.6167","68.1833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","13053","1762294560"
"Hashikami","Hashikami","40.4525","141.6211","Japan","JP","JPN","Aomori","","13176","1392003105"
"Fene","Fene","43.4667","-8.1667","Spain","ES","ESP","Galicia","","12832","1724145974"
"Nosivka","Nosivka","50.9300","31.5800","Ukraine","UA","UKR","Chernihivska Oblast","","13310","1804559608"
"Bhagwatpur","Bhagwatpur","25.7484","85.7016","India","IN","IND","Bihār","","11369","1356225654"
"Motobu","Motobu","26.6575","127.8978","Japan","JP","JPN","Okinawa","","13073","1392003540"
"Glendale","Glendale","43.1287","-87.9277","United States","US","USA","Wisconsin","","13313","1840003037"
"Nesconset","Nesconset","40.8467","-73.1522","United States","US","USA","New York","","13313","1840005051"
"Charlton","Charlton","42.1351","-71.9680","United States","US","USA","Massachusetts","","13312","1840053584"
"Stolin","Stolin","51.8833","26.8500","Belarus","BY","BLR","Brestskaya Voblasts’","","13290","1112925605"
"Kalavai","Kalavai","12.7691","79.4196","India","IN","IND","Tamil Nādu","","12887","1356950297"
"Zella-Mehlis","Zella-Mehlis","50.6597","10.6669","Germany","DE","DEU","Thuringia","","12863","1276496702"
"Zandhoven","Zandhoven","51.2167","4.6500","Belgium","BE","BEL","Flanders","","12985","1056347611"
"Sweden","Sweden","43.1791","-77.9406","United States","US","USA","New York","","13307","1840087957"
"Ban Chorakhe Samphan","Ban Chorakhe Samphan","14.3258","99.8623","Thailand","TH","THA","Suphan Buri","","13157","1764660410"
"Brønderslev","Bronderslev","57.2694","9.9472","Denmark","DK","DNK","Nordjylland","minor","12549","1208825509"
"Ergolding","Ergolding","48.5833","12.1667","Germany","DE","DEU","Bavaria","","12957","1276980704"
"Bocholt","Bocholt","51.1722","5.5794","Belgium","BE","BEL","Flanders","","13085","1056755409"
"Mercedes Umaña","Mercedes Umana","13.5667","-88.5000","El Salvador","SV","SLV","Usulután","","13092","1222697666"
"Turbihāl","Turbihal","15.7614","76.5964","India","IN","IND","Karnātaka","","13034","1356364510"
"Palmetto","Palmetto","27.5251","-82.5749","United States","US","USA","Florida","","13304","1840015116"
"Portage La Prairie","Portage La Prairie","49.9728","-98.2919","Canada","CA","CAN","Manitoba","","13304","1124282782"
"Cocotitlán","Cocotitlan","19.2167","-98.8500","Mexico","MX","MEX","México","minor","12142","1484387908"
"Röthenbach an der Pegnitz","Rothenbach an der Pegnitz","49.4847","11.2475","Germany","DE","DEU","Bavaria","","12431","1276836579"
"Qal‘eh Ra’īsī","Qal\`eh Ra'isi","31.1900","50.4419","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","","13269","1364540672"
"El Tablón","El Tablon","1.4269","-77.0969","Colombia","CO","COL","Nariño","minor","13255","1170509980"
"Sahri","Sahri","25.4721","85.7277","India","IN","IND","Bihār","","11522","1356756964"
"Usumatlán","Usumatlan","14.9489","-89.7767","Guatemala","GT","GTM","Zacapa","minor","13245","1320983656"
"Kolattūr","Kolattur","13.3295","79.4797","India","IN","IND","Andhra Pradesh","","12401","1356220875"
"Beyla","Beyla","8.6833","-8.6333","Guinea","GN","GIN","N’Zérékoré","minor","13204","1324564039"
"Fruita","Fruita","39.1548","-108.7305","United States","US","USA","Colorado","","13296","1840020229"
"La Victoria","La Victoria","4.5214","-76.0364","Colombia","CO","COL","Valle del Cauca","minor","13247","1170932962"
"Maní","Mani","4.8167","-72.2833","Colombia","CO","COL","Casanare","minor","13291","1170495978"
"Borodyanka","Borodyanka","50.6474","29.9258","Ukraine","UA","UKR","Kyivska Oblast","","13230","1804040478"
"Franconia","Franconia","40.3055","-75.3590","United States","US","USA","Pennsylvania","","13293","1840102936"
"Herbrechtingen","Herbrechtingen","48.6253","10.1739","Germany","DE","DEU","Baden-Württemberg","","13070","1276327261"
"Lake Station","Lake Station","41.5729","-87.2599","United States","US","USA","Indiana","","13292","1840008195"
"Terebovlya","Terebovlya","49.3000","25.7000","Ukraine","UA","UKR","Ternopilska Oblast","","13279","1804560427"
"Kiiminki","Kiiminki","65.1333","25.7750","Finland","FI","FIN","Pohjois-Pohjanmaa","","13252","1246609687"
"Chợ Lách","Cho Lach","10.2647","106.1300","Vietnam","VN","VNM","Bến Tre","minor","11836","1704967577"
"Seravezza","Seravezza","44.0000","10.2333","Italy","IT","ITA","Tuscany","","12962","1380685109"
"Caçu","Cacu","-18.5569","-51.1308","Brazil","BR","BRA","Goiás","","13283","1076792796"
"Olfen","Olfen","51.7167","7.3833","Germany","DE","DEU","North Rhine-Westphalia","","13040","1276474415"
"Los Chiles","Los Chiles","10.9639","-84.5990","Costa Rica","CR","CRI","Alajuela","","13262","1188974834"
"Rābor","Rabor","29.2911","56.9131","Iran","IR","IRN","Kermān","minor","13263","1364476007"
"Dhanaura","Dhanaura","25.1905","87.2595","India","IN","IND","Bihār","","11637","1356815392"
"Kamianka","Kamianka","49.0333","32.1000","Ukraine","UA","UKR","Cherkaska Oblast","","13203","1804999372"
"Arakere","Arakere","12.4133","76.8168","India","IN","IND","Karnātaka","","12814","1356591054"
"Slobozhanske","Slobozhanske","48.5342","35.0749","Ukraine","UA","UKR","Dnipropetrovska Oblast","","13221","1804133428"
"Sargūr","Sargur","11.9997","76.3961","India","IN","IND","Karnātaka","","13108","1356430206"
"Baruāri","Baruari","26.0305","86.5965","India","IN","IND","Bihār","","11495","1356001278"
"Montegranaro","Montegranaro","43.2333","13.6333","Italy","IT","ITA","Marche","","12876","1380180900"
"Chenggong","Chenggong","23.1167","121.3500","Taiwan","TW","TWN","Taitung","","13193","1158560266"
"Gärtringen","Gartringen","48.6408","8.9006","Germany","DE","DEU","Baden-Württemberg","","12657","1276667329"
"Lawang Bato","Lawang Bato","14.7300","120.9961","Philippines","PH","PHL","Valenzuela","","13237","1608444502"
"Superior","Superior","39.9340","-105.1588","United States","US","USA","Colorado","","13283","1840022425"
"Clinton","Clinton","41.2980","-72.5300","United States","US","USA","Connecticut","","13283","1840034364"
"Fyzabad","Fyzabad","10.1833","-61.5500","Trinidad and Tobago","TT","TTO","Siparia","","13099","1780514222"
"Chulumani","Chulumani","-16.4102","-67.5255","Bolivia","BO","BOL","La Paz","","13204","1068146431"
"Ammavārikuppam","Ammavarikuppam","13.1784","79.4169","India","IN","IND","Tamil Nādu","","12386","1356257144"
"Calcinato","Calcinato","45.4581","10.4146","Italy","IT","ITA","Lombardy","","12894","1380753410"
"Auburn","Auburn","41.3666","-85.0559","United States","US","USA","Indiana","","13281","1840007100"
"Svirsk","Svirsk","53.0833","103.3333","Russia","RU","RUS","Irkutskaya Oblast’","","12945","1643442205"
"Harrow on the Hill","Harrow on the Hill","51.5655","-0.3327","United Kingdom","GB","GBR","Harrow","","12270","1826937930"
"Panjīpāra","Panjipara","26.1369","88.0132","India","IN","IND","West Bengal","","12376","1356550147"
"Mohgaon","Mohgaon","21.6394","78.7364","India","IN","IND","Madhya Pradesh","","13057","1356501396"
"Haselünne","Haselunne","52.6667","7.4667","Germany","DE","DEU","Lower Saxony","","13196","1276706458"
"Cairo Montenotte","Cairo Montenotte","44.4000","8.2667","Italy","IT","ITA","Liguria","","13145","1380236204"
"Ambazoa","Ambazoa","-25.3139","45.8972","Madagascar","MG","MDG","Toliara","","13000","1450252226"
"Gaura","Gaura","24.9643","86.3739","India","IN","IND","Bihār","","12789","1356202365"
"Ocaña","Ocana","39.9569","-3.4967","Spain","ES","ESP","Castille-La Mancha","","13185","1724751955"
"Sebt Labrikiyne","Sebt Labrikiyne","32.2944","-8.0722","Morocco","MA","MAR","Marrakech-Safi","","13225","1504100244"
"Pontecorvo","Pontecorvo","41.4626","13.6676","Italy","IT","ITA","Lazio","","13125","1380359206"
"Campo de Criptana","Campo de Criptana","39.4000","-3.1167","Spain","ES","ESP","Castille-La Mancha","","13229","1724221240"
"Sarria","Sarria","42.7833","-7.4167","Spain","ES","ESP","Galicia","","13257","1724423830"
"Finneytown","Finneytown","39.2159","-84.5144","United States","US","USA","Ohio","","13271","1840034109"
"Pácora","Pacora","5.5258","-75.4589","Colombia","CO","COL","Caldas","minor","13214","1170478424"
"Ambohidronono","Ambohidronono","-18.7500","48.0333","Madagascar","MG","MDG","Toamasina","","13206","1450192899"
"Los Alamos","Los Alamos","35.8927","-106.2862","United States","US","USA","New Mexico","","13270","1840017779"
"Ulvila","Ulvila","61.4333","21.9000","Finland","FI","FIN","Satakunta","minor","13237","1246357042"
"Shin'onsen","Shin'onsen","35.6235","134.4489","Japan","JP","JPN","Hyōgo","","13213","1392003167"
"Esneux","Esneux","50.5333","5.5667","Belgium","BE","BEL","Wallonia","","12889","1056729004"
"Mahao","Mahao","26.8675","108.3267","China","CN","CHN","Guizhou","","13151","1156500145"
"Palangavāngudi","Palangavangudi","10.7244","78.8108","India","IN","IND","Tamil Nādu","","12157","1356229996"
"Nawā Nagar Nizāmat","Nawa Nagar Nizamat","26.2895","84.9351","India","IN","IND","Bihār","","11621","1356907104"
"Monteux","Monteux","44.0356","4.9972","France","FR","FRA","Provence-Alpes-Côte d’Azur","","12935","1250058114"
"Ajijic","Ajijic","20.3000","-103.2833","Mexico","MX","MEX","Jalisco","","11439","1484699000"
"Skoghall","Skoghall","59.3333","13.4333","Sweden","SE","SWE","Värmland","minor","13265","1752185362"
"Gvardeysk","Gvardeysk","54.6667","21.0833","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","13227","1643013384"
"Barkāgaon","Barkagaon","23.8651","85.2167","India","IN","IND","Jhārkhand","","12900","1356112405"
"Brignais","Brignais","45.6739","4.7542","France","FR","FRA","Auvergne-Rhône-Alpes","","12097","1250873150"
"Hardiyā","Hardiya","26.9443","84.8542","India","IN","IND","Bihār","","11729","1356623387"
"Kalappālangulam","Kalappalangulam","9.1889","77.5604","India","IN","IND","Tamil Nādu","","12896","1356204247"
"San Biagio di Callalta","San Biagio di Callalta","45.6867","12.3815","Italy","IT","ITA","Veneto","","12996","1380128806"
"Parvomay","Parvomay","42.1000","25.2167","Bulgaria","BG","BGR","Plovdiv","","13263","1100284082"
"Smithfield","Smithfield","41.8349","-111.8265","United States","US","USA","Utah","","13263","1840021321"
"Serra Azul","Serra Azul","-21.3108","-47.5658","Brazil","BR","BRA","São Paulo","","13216","1076292642"
"Manamodu","Manamodu","11.1965","79.5953","India","IN","IND","Tamil Nādu","","12243","1356246237"
"El Kansera","El Kansera","34.0419","-5.9272","Morocco","MA","MAR","Rabat-Salé-Kénitra","","13209","1504142817"
"Qazmalar","Qazmalar","40.9814","47.8458","Azerbaijan","AZ","AZE","Qəbələ","","13190","1031242200"
"Lahaina","Lahaina","20.8848","-156.6618","United States","US","USA","Hawaii","","13261","1840023234"
"Bergen","Bergen","51.6000","6.0500","Netherlands","NL","NLD","Limburg","minor","13140","1528008506"
"North Tidworth","North Tidworth","51.2370","-1.6640","United Kingdom","GB","GBR","Wiltshire","","12089","1826577257"
"Sillamäe","Sillamae","59.3931","27.7742","Estonia","EE","EST","Sillamäe","minor","12230","1233128320"
"Östringen","Ostringen","49.2194","8.7108","Germany","DE","DEU","Baden-Württemberg","","13015","1276810660"
"Torquay","Torquay","-38.3333","144.3167","Australia","AU","AUS","Victoria","","13258","1036765457"
"Alto Alegre dos Parecis","Alto Alegre dos Parecis","-12.1278","-61.8508","Brazil","BR","BRA","Rondônia","","13255","1076019248"
"Damaishan","Damaishan","24.5038","112.2797","China","CN","CHN","Guangdong","","13230","1156858739"
"Bridgetown","Bridgetown","39.1552","-84.6359","United States","US","USA","Ohio","","13257","1840034100"
"Īnderbor","Inderbor","48.5500","51.7833","Kazakhstan","KZ","KAZ","","minor","13254","1398316274"
"Vohitrindry","Vohitrindry","-22.3833","47.8500","Madagascar","MG","MDG","Fianarantsoa","","13000","1450431689"
"Empedrado","Empedrado","-27.9333","-58.7833","Argentina","AR","ARG","Corrientes","minor","13245","1032921774"
"Rāghopur","Raghopur","25.3180","87.0080","India","IN","IND","Bihār","","12149","1356085734"
"Giporlos","Giporlos","11.1208","125.4494","Philippines","PH","PHL","Eastern Samar","","13117","1608760153"
"Douar Sidi Laaroussi","Douar Sidi Laaroussi","31.8450","-9.1586","Morocco","MA","MAR","Marrakech-Safi","","13202","1504954714"
"Kachavaram","Kachavaram","16.5700","79.6750","India","IN","IND","Andhra Pradesh","","12928","1356086007"
"Apricena","Apricena","41.7667","15.4333","Italy","IT","ITA","Puglia","","13174","1380762322"
"Beverungen","Beverungen","51.6628","9.3725","Germany","DE","DEU","North Rhine-Westphalia","","13115","1276597510"
"Sovetskoe","Sovetskoe","41.0600","72.7300","Kyrgyzstan","KG","KGZ","Jalal-Abad","","13178","1417936909"
"Bridgnorth","Bridgnorth","52.5350","-2.4195","United Kingdom","GB","GBR","Shropshire","","12079","1826579574"
"Woodinville","Woodinville","47.7570","-122.1477","United States","US","USA","Washington","","13247","1840021121"
"Las Vegas","Las Vegas","35.6011","-105.2206","United States","US","USA","New Mexico","","13247","1840020397"
"Argentona","Argentona","41.5558","2.4025","Spain","ES","ESP","Catalonia","","12745","1724175024"
"Clayton","Clayton","39.8689","-84.3292","United States","US","USA","Ohio","","13246","1840010584"
"Bedburg","Bedburg","51.7667","6.1833","Germany","DE","DEU","North Rhine-Westphalia","","13033","1276539122"
"Great Bookham","Great Bookham","51.2780","-0.3730","United Kingdom","GB","GBR","Surrey","","11375","1826910231"
"Castelnuovo di Verona","Castelnuovo di Verona","45.4333","10.7667","Italy","IT","ITA","Veneto","","13137","1380923037"
"Balatonfüred","Balatonfured","46.9500","17.8833","Hungary","HU","HUN","Veszprém","minor","12966","1348517997"
"Radomir","Radomir","42.5500","22.9500","Bulgaria","BG","BGR","Pernik","","13209","1100594345"
"Morbegno","Morbegno","46.1333","9.5667","Italy","IT","ITA","Lombardy","","12407","1380051452"
"San Jerónimo","San Jeronimo","6.4417","-75.7278","Colombia","CO","COL","Antioquia","minor","13158","1170182912"
"Chigwell","Chigwell","51.6225","0.0723","United Kingdom","GB","GBR","Essex","","12449","1826768266"
"Sabinov","Sabinov","49.1061","21.0856","Slovakia","SK","SVK","Prešov","minor","12700","1703204865"
"Rāpūr","Rapur","14.2015","79.5351","India","IN","IND","Andhra Pradesh","","13154","1356388256"
"Gudgeri","Gudgeri","15.1225","75.3653","India","IN","IND","Karnātaka","","12947","1356313738"
"Mehsāri","Mehsari","25.7554","85.8477","India","IN","IND","Bihār","","11869","1356095450"
"Baghauni","Baghauni","25.9338","86.0594","India","IN","IND","Bihār","","11722","1356433257"
"Plaza Huincul","Plaza Huincul","-38.9338","-69.1987","Argentina","AR","ARG","Neuquén","","13172","1032678166"
"Ceyu","Ceyu","37.7939","114.0905","China","CN","CHN","Hebei","","13161","1156215830"
"Yamato","Yamato","32.6858","130.9859","Japan","JP","JPN","Kumamoto","","13210","1392003078"
"Andrews","Andrews","32.3207","-102.5520","United States","US","USA","Texas","","13234","1840019474"
"Joure","Joure","52.9667","5.7833","Netherlands","NL","NLD","Fryslân","minor","13090","1528021505"
"Rudrūr","Rudrur","18.6700","77.9000","India","IN","IND","Andhra Pradesh","","12906","1356053906"
"Lakshmīnārāyanapuram","Lakshminarayanapuram","11.7914","79.5555","India","IN","IND","Tamil Nādu","","12467","1356202940"
"Blue Ash","Blue Ash","39.2480","-84.3827","United States","US","USA","Ohio","","13229","1840003812"
"Guābāri","Guabari","26.1511","88.1623","India","IN","IND","Bihār","","11835","1356968267"
"Brenes","Brenes","37.5500","-5.8667","Spain","ES","ESP","Andalusia","","12652","1724993123"
"Bolintin Vale","Bolintin Vale","44.4472","25.7572","Romania","RO","ROU","Giurgiu","","12929","1642230164"
"McCalla","McCalla","33.3023","-87.0302","United States","US","USA","Alabama","","13221","1840041400"
"Monfort Heights","Monfort Heights","39.1823","-84.6075","United States","US","USA","Ohio","","13221","1840034325"
"Cicuco","Cicuco","9.2667","-74.6500","Colombia","CO","COL","Bolívar","minor","13120","1170569018"
"Ocna Mureş","Ocna Mures","46.3900","23.8600","Romania","RO","ROU","Alba","","13036","1642234121"
"North Greenbush","North Greenbush","42.6706","-73.6633","United States","US","USA","New York","","13218","1840087735"
"Raffadali","Raffadali","37.4047","13.5339","Italy","IT","ITA","Sicilia","","12650","1380472862"
"Vila Pouca de Aguiar","Vila Pouca de Aguiar","41.5006","-7.6422","Portugal","PT","PRT","Vila Real","minor","13187","1620331126"
"Lincoln","Lincoln","40.1508","-89.3720","United States","US","USA","Illinois","","13217","1840009447"
"Chakwai","Chakwai","25.0543","85.6620","India","IN","IND","Bihār","","11451","1356150821"
"Sankt Georgen im Schwarzwald","Sankt Georgen im Schwarzwald","48.1247","8.3308","Germany","DE","DEU","Baden-Württemberg","","12998","1276384519"
"Weilburg","Weilburg","50.4833","8.2500","Germany","DE","DEU","Hesse","","12990","1276833970"
"Vasylivka","Vasylivka","47.4435","35.2802","Ukraine","UA","UKR","Zaporizka Oblast","minor","13166","1804609243"
"Asjen","Asjen","34.8500","-5.6167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","13139","1504066835"
"Messadine","Messadine","35.7619","10.5953","Tunisia","TN","TUN","Sousse","","12916","1788994280"
"La Grande","La Grande","45.3242","-118.0865","United States","US","USA","Oregon","","13212","1840019924"
"Agutaya","Agutaya","11.1520","120.9396","Philippines","PH","PHL","Palawan","","12867","1608681129"
"Gangelt","Gangelt","50.9831","6.0000","Germany","DE","DEU","North Rhine-Westphalia","","12946","1276133418"
"Auriol","Auriol","43.3694","5.6314","France","FR","FRA","Provence-Alpes-Côte d’Azur","","12922","1250066730"
"Marojala","Marojala","-14.4833","50.0333","Madagascar","MG","MDG","Antsiranana","","13000","1450598155"
"Çamardı","Camardi","37.8330","34.9854","Turkey","TR","TUR","Niğde","minor","13200","1792093929"
"Sarrebourg","Sarrebourg","48.7347","7.0539","France","FR","FRA","Grand Est","minor","12449","1250870151"
"Huguan Nongchang","Huguan Nongchang","21.2015","110.2459","China","CN","CHN","Guangdong","","13074","1156566709"
"Dedemsvaart","Dedemsvaart","52.6000","6.4667","Netherlands","NL","NLD","Overijssel","","12840","1528168277"
"Wolvega","Wolvega","52.8761","6.0014","Netherlands","NL","NLD","Fryslân","minor","13090","1528105224"
"Fouriesburg","Fouriesburg","-28.6227","28.2109","South Africa","ZA","ZAF","Free State","","12974","1710689900"
"Kanabūr","Kanabur","13.3523","75.4517","India","IN","IND","Karnātaka","","13107","1356968867"
"Orocó","Oroco","-8.6200","-39.6019","Brazil","BR","BRA","Pernambuco","","13180","1076142710"
"Xaafuun","Xaafuun","10.4167","51.2667","Somalia","SO","SOM","Bari","","13200","1706739156"
"Sharon","Sharon","41.2340","-80.4998","United States","US","USA","Pennsylvania","","13203","1840003441"
"Jāmi","Jami","18.0458","83.2664","India","IN","IND","Andhra Pradesh","","12732","1356039997"
"Ansião","Ansiao","39.9167","-8.4333","Portugal","PT","PRT","Leiria","minor","13128","1620604793"
"Oncativo","Oncativo","-31.9167","-63.6667","Argentina","AR","ARG","Córdoba","","13180","1032751442"
"Miranda do Corvo","Miranda do Corvo","40.1000","-8.3333","Portugal","PT","PRT","Coimbra","minor","13098","1620469916"
"Baghambarpur","Baghambarpur","26.8075","84.4059","India","IN","IND","Bihār","","11614","1356494563"
"Barrafranca","Barrafranca","37.3667","14.2000","Italy","IT","ITA","Sicilia","","12960","1380555012"
"Matinilla","Matinilla","9.9246","-84.0713","Costa Rica","CR","CRI","San José","","12936","1188724391"
"Maglód","Maglod","47.4439","19.3526","Hungary","HU","HUN","Pest","","12635","1348383412"
"Simijaca","Simijaca","5.5019","-73.8519","Colombia","CO","COL","Cundinamarca","minor","13077","1170095690"
"Mandrāmo","Mandramo","24.1822","85.8734","India","IN","IND","Jhārkhand","","12554","1356256561"
"Uttoxeter","Uttoxeter","52.8980","-1.8600","United Kingdom","GB","GBR","Staffordshire","","13089","1826375262"
"Belëv","Belev","53.8167","36.1500","Russia","RU","RUS","Tul’skaya Oblast’","","13180","1643708912"
"Carnot-Moon","Carnot-Moon","40.5187","-80.2178","United States","US","USA","Pennsylvania","","13196","1840073606"
"Yzeure","Yzeure","46.5658","3.3544","France","FR","FRA","Auvergne-Rhône-Alpes","","12899","1250577424"
"Spilamberto","Spilamberto","44.5333","11.0167","Italy","IT","ITA","Emilia-Romagna","","12767","1380630753"
"Alaverdi","Alaverdi","41.1333","44.6500","Armenia","AM","ARM","Lorri","","13000","1051787137"
"Hanımçiftliği","Hanimciftligi","38.3833","38.3333","Turkey","TR","TUR","Malatya","","12670","1792147557"
"Tinglayan","Tinglayan","17.2650","121.1489","Philippines","PH","PHL","Kalinga","","13148","1608957422"
"Nako","Nako","-29.6481","27.7336","Lesotho","LS","LSO","Maseru","","13146","1426816465"
"Quzanlı","Quzanli","40.1600","47.1722","Azerbaijan","AZ","AZE","Ağdam","","13002","1031479140"
"Kotturu","Kotturu","17.2958","82.4752","India","IN","IND","Andhra Pradesh","","12661","1356490582"
"Bou Arada","Bou Arada","36.3500","9.6167","Tunisia","TN","TUN","Siliana","","13170","1788598968"
"Steamboat Springs","Steamboat Springs","40.4777","-106.8243","United States","US","USA","Colorado","","13193","1840021374"
"La Vega","La Vega","4.9992","-74.3411","Colombia","CO","COL","Cundinamarca","minor","13085","1170671820"
"Mazatlán Villa de Flores","Mazatlan Villa de Flores","18.0167","-96.9167","Mexico","MX","MEX","Oaxaca","minor","13097","1484893255"
"Manuel Ribas","Manuel Ribas","-24.5158","-51.6678","Brazil","BR","BRA","Paraná","","13169","1076619354"
"Pūdūru","Puduru","16.1520","77.8273","India","IN","IND","Andhra Pradesh","","12660","1356104456"
"Krasnozavodsk","Krasnozavodsk","56.4333","38.2333","Russia","RU","RUS","Moskovskaya Oblast’","","13076","1643009707"
"Thames Centre","Thames Centre","43.0300","-81.0800","Canada","CA","CAN","Ontario","","13191","1124000993"
"Bagadó","Bagado","5.4167","-76.4167","Colombia","CO","COL","Chocó","minor","13174","1170462492"
"Duvvūru","Duvvuru","14.8333","78.6500","India","IN","IND","Andhra Pradesh","","12727","1356883583"
"Lingamparti","Lingamparti","17.2827","82.1231","India","IN","IND","Andhra Pradesh","","12967","1356156344"
"Presidente Jânio Quadros","Presidente Janio Quadros","-14.6889","-41.6778","Brazil","BR","BRA","Bahia","","13178","1076806633"
"Altos","Altos","-25.2333","-57.2500","Paraguay","PY","PRY","Cordillera","","13114","1600982607"
"Mounds View","Mounds View","45.1071","-93.2076","United States","US","USA","Minnesota","","13187","1840007840"
"Toura","Toura","11.2436","2.3831","Benin","BJ","BEN","Alibori","","13110","1204764388"
"Bolsover","Bolsover","53.2304","-1.2875","United Kingdom","GB","GBR","Derbyshire","","11673","1826732756"
"Singuilucan","Singuilucan","19.9675","-98.5178","Mexico","MX","MEX","Hidalgo","minor","13143","1484024966"
"Rogers","Rogers","45.1865","-93.5783","United States","US","USA","Minnesota","","13182","1840008932"
"Somain","Somain","50.3575","3.2803","France","FR","FRA","Hauts-de-France","","12213","1250094134"
"Yelmalla","Yelmalla","18.8241","78.1609","India","IN","IND","Andhra Pradesh","","12800","1356986294"
"Baños","Banos","-1.3964","-78.4247","Ecuador","EC","ECU","Tungurahua","","12995","1218100027"
"Sevūr","Sevur","12.6865","79.2687","India","IN","IND","Tamil Nādu","","12273","1356060240"
"Abarán","Abaran","38.2031","-1.4003","Spain","ES","ESP","Murcia","","13066","1724553721"
"Seclin","Seclin","50.5483","3.0294","France","FR","FRA","Hauts-de-France","","12463","1250824349"
"Silago","Silago","10.5291","125.1618","Philippines","PH","PHL","Southern Leyte","","13116","1608487781"
"Uyar","Uyar","55.8267","94.3153","Russia","RU","RUS","Krasnoyarskiy Kray","","13167","1643343542"
"Gavere","Gavere","50.9333","3.6667","Belgium","BE","BEL","Flanders","","12769","1056307465"
"Phalaborwa","Phalaborwa","-23.9333","31.1167","South Africa","ZA","ZAF","Limpopo","","13108","1710768568"
"Pacatuba","Pacatuba","-10.4528","-36.6508","Brazil","BR","BRA","Sergipe","","13137","1076001109"
"Kirano","Kirano","-21.8333","47.0500","Madagascar","MG","MDG","Fianarantsoa","","13000","1450729863"
"Venkatagirikota","Venkatagirikota","13.0000","78.5000","India","IN","IND","Andhra Pradesh","","12803","1356937273"
"Villa Sandino","Villa Sandino","12.0500","-84.9928","Nicaragua","NI","NIC","Chontales","minor","13152","1558762382"
"Bachrā","Bachra","23.6886","85.0703","India","IN","IND","Jhārkhand","","12855","1356195250"
"Oftersheim","Oftersheim","49.3706","8.5847","Germany","DE","DEU","Baden-Württemberg","","12213","1276500222"
"Mīnākshipuram","Minakshipuram","9.8815","77.2747","India","IN","IND","Tamil Nādu","","12592","1356250289"
"Qal‘eh Ganj","Qal\`eh Ganj","27.5236","57.8811","Iran","IR","IRN","Kermān","minor","13169","1364072799"
"Agdangan","Agdangan","13.8758","121.9122","Philippines","PH","PHL","Quezon","minor","12764","1608783470"
"Arcachon","Arcachon","44.6586","-1.1689","France","FR","FRA","Nouvelle-Aquitaine","minor","11630","1250000809"
"Tracunhaém","Tracunhaem","-7.8050","-35.2400","Brazil","BR","BRA","Pernambuco","","13055","1076593886"
"Terrasini Favarotta","Terrasini Favarotta","38.1500","13.0833","Italy","IT","ITA","Sicilia","","12535","1380588592"
"São João do Araguaia","Sao Joao do Araguaia","-5.3578","-48.7908","Brazil","BR","BRA","Pará","","13155","1076471292"
"Stezzano","Stezzano","45.6508","9.6528","Italy","IT","ITA","Lombardy","","13112","1380771456"
"Santa María de Ipire","Santa Maria de Ipire","8.6638","-65.3563","Venezuela","VE","VEN","Guárico","minor","13161","1862961193"
"Vetlanda","Vetlanda","57.4333","15.0667","Sweden","SE","SWE","Jönköping","minor","13050","1752000768"
"Velykodolynske","Velykodolynske","46.3425","30.5653","Ukraine","UA","UKR","Odeska Oblast","","13036","1804355288"
"Mississippi Mills","Mississippi Mills","45.2167","-76.2000","Canada","CA","CAN","Ontario","","13163","1124001617"
"Ambatondrakalavao","Ambatondrakalavao","-19.4333","47.5000","Madagascar","MG","MDG","Antananarivo","","13000","1450037495"
"Güçlükonak","Guclukonak","37.4711","41.9139","Turkey","TR","TUR","Şırnak","minor","13091","1792624390"
"Kumarkhāli","Kumarkhali","22.3598","88.7990","India","IN","IND","West Bengal","","12021","1356025767"
"Mavorano","Mavorano","-21.7833","47.9333","Madagascar","MG","MDG","Toliara","","13000","1450909135"
"Iona","Iona","26.5160","-81.9601","United States","US","USA","Florida","","13160","1840028702"
"Teror","Teror","28.0590","-15.5476","Spain","ES","ESP","Canary Islands","","12667","1724300080"
"Yvetot","Yvetot","49.6169","0.7531","France","FR","FRA","Normandie","","11627","1250505707"
"Begowāl","Begowal","31.6125","75.5194","India","IN","IND","Punjab","","12693","1356869076"
"Schleiden","Schleiden","50.5331","6.4667","Germany","DE","DEU","North Rhine-Westphalia","","13053","1276198974"
"Raymond Terrace","Raymond Terrace","-32.7615","151.7441","Australia","AU","AUS","New South Wales","","12820","1036570825"
"Spreitenbach","Spreitenbach","47.4167","8.3667","Switzerland","CH","CHE","Aargau","","11788","1756282407"
"Monteprandone","Monteprandone","42.9167","13.8333","Italy","IT","ITA","Marche","","12678","1380155308"
"Domahani","Domahani","23.7569","87.0256","India","IN","IND","West Bengal","","12480","1356465911"
"Sinincay","Sinincay","-2.8333","-79.0000","Ecuador","EC","ECU","Azuay","","12650","1218165430"
"Stoughton","Stoughton","42.9237","-89.2225","United States","US","USA","Wisconsin","","13158","1840002901"
"Zabaykalsk","Zabaykalsk","49.6514","117.3269","Russia","RU","RUS","Zabaykal’skiy Kray","","13154","1643974760"
"Kotla","Kotla","32.7522","74.7470","India","IN","IND","Jammu and Kashmīr","","11909","1356389510"
"Mazıdağı","Mazidagi","37.4792","40.4864","Turkey","TR","TUR","Mardin","minor","13117","1792121043"
"Azle","Azle","32.8955","-97.5379","United States","US","USA","Texas","","13157","1840019423"
"Powell River","Powell River","49.8353","-124.5247","Canada","CA","CAN","British Columbia","","13157","1124154376"
"Sete Barras","Sete Barras","-24.3878","-47.9258","Brazil","BR","BRA","São Paulo","","13144","1076515429"
"Adi Keyh","Adi Keyh","14.8333","39.3667","Eritrea","ER","ERI","Ma’ĭkel","","13061","1232384326"
"Prudhoe","Prudhoe","54.9610","-1.8490","United Kingdom","GB","GBR","Northumberland","","11675","1826202112"
"Bonyhád","Bonyhad","46.3006","18.5309","Hungary","HU","HUN","Tolna","minor","12982","1348576105"
"Triel-sur-Seine","Triel-sur-Seine","48.9808","2.0061","France","FR","FRA","Île-de-France","","12250","1250691237"
"Kannudaiyāmpatti","Kannudaiyampatti","10.6377","78.4733","India","IN","IND","Tamil Nādu","","12778","1356202592"
"Balya","Balya","39.7500","27.5833","Turkey","TR","TUR","Balıkesir","minor","13141","1792528199"
"Gouandé","Gouande","10.7828","0.9186","Benin","BJ","BEN","Atacora","","13071","1204660302"
"Alovera","Alovera","40.5967","-3.2481","Spain","ES","ESP","Castille-La Mancha","","13142","1724090496"
"Waltershausen","Waltershausen","50.8975","10.5558","Germany","DE","DEU","Thuringia","","12973","1276892683"
"South Glengarry","South Glengarry","45.2000","-74.5833","Canada","CA","CAN","Ontario","","13150","1124001506"
"Petushki","Petushki","55.9333","39.4667","Russia","RU","RUS","Vladimirskaya Oblast’","","13112","1643011606"
"Adygeysk","Adygeysk","44.8832","39.1902","Russia","RU","RUS","Adygeya","minor","12745","1643365200"
"Hadamar","Hadamar","50.4500","8.0500","Germany","DE","DEU","Hesse","","12836","1276022783"
"Culaba","Culaba","11.6578","124.5425","Philippines","PH","PHL","Leyte","","12972","1608715779"
"Mettet","Mettet","50.3192","4.6569","Belgium","BE","BEL","Wallonia","","13037","1056136798"
"Shīnīlē","Shinile","9.6667","41.8333","Ethiopia","ET","ETH","Sumalē","","13132","1231940232"
"Bargaon","Bargaon","25.3455","84.6607","India","IN","IND","Bihār","","11354","1356697109"
"Claiborne","Claiborne","32.5379","-92.1981","United States","US","USA","Louisiana","","13147","1840013811"
"Berkeley Heights","Berkeley Heights","40.6764","-74.4244","United States","US","USA","New Jersey","","13147","1840081748"
"Barsaun","Barsaun","25.6316","84.4372","India","IN","IND","Bihār","","12140","1356297533"
"Castellbisbal","Castellbisbal","41.4767","1.9822","Spain","ES","ESP","Catalonia","","12735","1724327677"
"Ganapavaram","Ganapavaram","15.9232","80.5856","India","IN","IND","Andhra Pradesh","","12859","1356151920"
"Betatao","Betatao","-18.2000","47.8833","Madagascar","MG","MDG","Antananarivo","","13112","1450435049"
"Ganapatipālaiyam","Ganapatipalaiyam","11.0309","77.3367","India","IN","IND","Tamil Nādu","","12084","1356165595"
"Mūlaikkaraippatti","Mulaikkaraippatti","8.5454","77.7679","India","IN","IND","Tamil Nādu","","12859","1356419663"
"Kapelle","Kapelle","51.4833","3.9500","Netherlands","NL","NLD","Zeeland","minor","12882","1528835251"
"Ringnod","Ringnod","22.6113","74.9338","India","IN","IND","Madhya Pradesh","","12880","1356965606"
"Ambalakindresy","Ambalakindresy","-21.1667","47.3333","Madagascar","MG","MDG","Fianarantsoa","","13000","1450729938"
"Sali","Sali","26.9833","-0.0333","Algeria","DZ","DZA","Adrar","","13138","1012996789"
"Chabāl Kalān","Chabal Kalan","31.4818","74.7927","India","IN","IND","Punjab","","12477","1356907554"
"Santa Maria a Monte","Santa Maria a Monte","43.7000","10.6833","Italy","IT","ITA","Tuscany","","13061","1380753148"
"Upperu","Upperu","16.6500","77.5167","India","IN","IND","Andhra Pradesh","","12562","1356924393"
"Qulan","Qulan","42.9100","72.7150","Kazakhstan","KZ","KAZ","","","13128","1398525079"
"Tapiratiba","Tapiratiba","-21.4678","-46.7489","Brazil","BR","BRA","São Paulo","","13076","1076955040"
"Strunino","Strunino","56.3667","38.5833","Russia","RU","RUS","Vladimirskaya Oblast’","","13094","1643212321"
"Olivar Bajo","Olivar Bajo","-34.2330","-70.8830","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","13033","1152427692"
"Tsiately","Tsiately","-23.3167","47.6167","Madagascar","MG","MDG","Fianarantsoa","","13000","1450928724"
"Mahaboboka","Mahaboboka","-22.9000","44.3333","Madagascar","MG","MDG","Toliara","","13115","1450446461"
"Belemoka","Belemoka","-21.7833","47.6167","Madagascar","MG","MDG","Fianarantsoa","","13000","1450739147"
"Springbok","Springbok","-29.6667","17.8833","South Africa","ZA","ZAF","Northern Cape","","12790","1710894218"
"Bad Doberan","Bad Doberan","54.1069","11.9053","Germany","DE","DEU","Mecklenburg-Western Pomerania","","12752","1276361039"
"San Antonio del Tequendama","San Antonio del Tequendama","4.6328","-74.3522","Colombia","CO","COL","Cundinamarca","minor","13084","1170251762"
"North Perth","North Perth","43.7300","-80.9500","Canada","CA","CAN","Ontario","","13130","1124000749"
"Kaspiyskiy","Kaspiyskiy","45.3908","47.3658","Russia","RU","RUS","Kalmykiya","","13125","1643395852"
"Tezontepec","Tezontepec","19.8833","-98.8167","Mexico","MX","MEX","Hidalgo","minor","13032","1484120923"
"General Enrique Mosconi","General Enrique Mosconi","-22.6000","-63.8167","Argentina","AR","ARG","Salta","","13118","1032708180"
"Jennings","Jennings","38.7231","-90.2644","United States","US","USA","Missouri","","13129","1840008596"
"Gurgunta","Gurgunta","16.2875","76.6319","India","IN","IND","Karnātaka","","12898","1356120396"
"Bamessing","Bamessing","5.9847","10.3603","Cameroon","CM","CMR","North-West","","12726","1120344122"
"Piratininga","Piratininga","-22.4128","-49.1350","Brazil","BR","BRA","São Paulo","","13093","1076861726"
"Pharkia","Pharkia","25.9384","87.4034","India","IN","IND","Bihār","","12062","1356487358"
"Loveland","Loveland","39.2677","-84.2733","United States","US","USA","Ohio","","13125","1840003809"
"Periya Pattanam","Periya Pattanam","9.2726","78.9023","India","IN","IND","Mizoram","","12510","1356033061"
"Honggu","Honggu","36.2930","102.9575","China","CN","CHN","Gansu","","12700","1156002197"
"Mahatsinjo","Mahatsinjo","-22.8000","47.4500","Madagascar","MG","MDG","Fianarantsoa","","13000","1450644475"
"Neuhausen auf den Fildern","Neuhausen auf den Fildern","48.6844","9.2744","Germany","DE","DEU","Baden-Württemberg","","12150","1276361118"
"Rosbach vor der Höhe","Rosbach vor der Hohe","50.2986","8.7006","Germany","DE","DEU","Hesse","","12838","1276636774"
"Noeux-les-Mines","Noeux-les-Mines","50.4797","2.6647","France","FR","FRA","Hauts-de-France","","11813","1250980395"
"Sulingen","Sulingen","52.6667","8.8000","Germany","DE","DEU","Lower Saxony","","13005","1276180018"
"Sirpur","Sirpur","19.4833","79.6000","India","IN","IND","Andhra Pradesh","","13005","1356164247"
"Befeta","Befeta","-21.2333","47.0500","Madagascar","MG","MDG","Fianarantsoa","","13000","1450865101"
"Ambodiampana","Ambodiampana","-14.5333","49.8667","Madagascar","MG","MDG","Antsiranana","","13000","1450410168"
"Anjiajia","Anjiajia","-16.4833","46.9667","Madagascar","MG","MDG","Mahajanga","","13000","1450871087"
"Bihtā","Bihta","25.5731","84.8358","India","IN","IND","Bihār","","11918","1356234338"
"Wantage","Wantage","51.5890","-1.4270","United Kingdom","GB","GBR","Oxfordshire","","13106","1826123790"
"Nartan","Nartan","43.5167","43.7000","Russia","RU","RUS","Kabardino-Balkariya","","12813","1643903135"
"Mekra","Mekra","25.4705","85.8406","India","IN","IND","Bihār","","12250","1356317314"
"Dărmăneşti","Darmanesti","46.3700","26.4797","Romania","RO","ROU","Bacău","","13069","1642492566"
"Sagay","Sagay","9.1167","124.7167","Philippines","PH","PHL","Camiguin","","12826","1608754014"
"Tarnos","Tarnos","43.5406","-1.4614","France","FR","FRA","Nouvelle-Aquitaine","","12634","1250122765"
"Mercier","Mercier","45.3200","-73.7500","Canada","CA","CAN","Quebec","","13115","1124186621"
"Milhã","Milha","-5.6750","-39.1939","Brazil","BR","BRA","Ceará","","13086","1076214085"
"Kaufungen","Kaufungen","51.2811","9.6186","Germany","DE","DEU","Hesse","","12629","1276899210"
"South Stormont","South Stormont","45.0833","-74.9667","Canada","CA","CAN","Ontario","","13110","1124001793"
"Governador Celso Ramos","Governador Celso Ramos","-27.3150","-48.5589","Brazil","BR","BRA","Santa Catarina","","12999","1076925889"
"Jensen Beach","Jensen Beach","27.2437","-80.2423","United States","US","USA","Florida","","13108","1840014190"
"Lokāpur","Lokapur","16.1656","75.3660","India","IN","IND","Karnātaka","","12790","1356341352"
"Andranomamy","Andranomamy","-16.6000","47.1333","Madagascar","MG","MDG","Mahajanga","","13000","1450780614"
"Leonforte","Leonforte","37.6417","14.3958","Italy","IT","ITA","Sicilia","","13106","1380550337"
"Medway","Medway","42.1535","-71.4291","United States","US","USA","Massachusetts","","13105","1840053674"
"Ksar Sghir","Ksar Sghir","35.8419","-5.5586","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12997","1504919377"
"Olen","Olen","51.1500","4.8500","Belgium","BE","BEL","Flanders","","12560","1056909759"
"West Monroe","West Monroe","32.5120","-92.1513","United States","US","USA","Louisiana","","13103","1840015795"
"Saraiya","Saraiya","25.6467","84.6312","India","IN","IND","Bihār","","12186","1356115412"
"Kouti","Kouti","6.5542","2.6616","Benin","BJ","BEN","Plateau","","12751","1204870206"
"Scheeßel","Scheessel","53.1706","9.4831","Germany","DE","DEU","Lower Saxony","","13014","1276647577"
"Sakoabe","Sakoabe","-22.2000","47.8333","Madagascar","MG","MDG","Fianarantsoa","","13000","1450793349"
"Moody","Moody","33.5986","-86.4963","United States","US","USA","Alabama","","13100","1840014770"
"Ban Bang Lamung","Ban Bang Lamung","13.0470","100.9289","Thailand","TH","THA","Chon Buri","","11324","1764268034"
"Çavdır","Cavdir","37.1550","29.6939","Turkey","TR","TUR","Burdur","minor","13077","1792076843"
"Kathāniān","Kathanian","31.6373","74.7572","India","IN","IND","Punjab","","12476","1356006871"
"Ratanpur","Ratanpur","24.8996","86.2888","India","IN","IND","Bihār","","11831","1356259061"
"North Palm Beach","North Palm Beach","26.8217","-80.0576","United States","US","USA","Florida","","13096","1840018307"
"Hersbruck","Hersbruck","49.5081","11.4328","Germany","DE","DEU","Bavaria","","12548","1276272170"
"Chos Malal","Chos Malal","-37.3833","-70.2667","Argentina","AR","ARG","Neuquén","minor","13092","1032817076"
"Gumani","Gumani","9.4500","-0.7667","Ghana","GH","GHA","Northern","","12506","1288021248"
"Vannivedu","Vannivedu","12.9173","79.3530","India","IN","IND","Tamil Nādu","","12161","1356934925"
"Song Phi Nong","Song Phi Nong","14.2306","100.0389","Thailand","TH","THA","Suphan Buri","minor","12926","1764684967"
"Ambohitsilaozana","Ambohitsilaozana","-17.6833","48.4500","Madagascar","MG","MDG","Toamasina","","13000","1450224032"
"Monte Belo","Monte Belo","-21.3258","-46.3678","Brazil","BR","BRA","Minas Gerais","","13061","1076552957"
"Pudūr","Pudur","11.2960","77.7478","India","IN","IND","Tamil Nādu","","11956","1356169586"
"Bagaura","Bagaura","26.0388","84.4624","India","IN","IND","Bihār","","11658","1356073341"
"Kūshk","Kushk","32.6428","51.5000","Iran","IR","IRN","Eşfahān","","11264","1364515692"
"Al Jumaylīyah","Al Jumayliyah","25.6208","51.0819","Qatar","QA","QAT","Ash Shīḩānīyah","","13085","1634232373"
"Soddy-Daisy","Soddy-Daisy","35.2571","-85.1739","United States","US","USA","Tennessee","","13090","1840035990"
"Atmākūr","Atmakur","16.3364","77.8056","India","IN","IND","Andhra Pradesh","","12297","1356165403"
"Semri","Semri","25.3404","84.1970","India","IN","IND","Bihār","","11703","1356368035"
"Ambondrona","Ambondrona","-21.2667","46.9833","Madagascar","MG","MDG","Fianarantsoa","","13000","1450002861"
"Tonoshō","Tonosho","34.4869","134.1884","Japan","JP","JPN","Kagawa","","12915","1392016671"
"Akseki","Akseki","37.0519","31.7842","Turkey","TR","TUR","Antalya","minor","13084","1792149428"
"Glencullen","Glencullen","53.2230","-6.2160","Ireland","IE","IRL","Dún Laoghaire-Rathdown","","13006","1372794759"
"Fannūj","Fannuj","26.5758","59.6397","Iran","IR","IRN","Sīstān va Balūchestān","","13070","1364947990"
"Lawaan","Lawaan","11.1408","125.3011","Philippines","PH","PHL","Eastern Samar","","13003","1608580234"
"Dover","Dover","40.5304","-81.4806","United States","US","USA","Ohio","","13083","1840007217"
"Antônio Prado","Antonio Prado","-28.8578","-51.2828","Brazil","BR","BRA","Rio Grande do Sul","","13045","1076953199"
"Macará","Macara","-4.3833","-79.9500","Ecuador","EC","ECU","Loja","","13035","1218426387"
"Noceto","Noceto","44.8167","10.1833","Italy","IT","ITA","Emilia-Romagna","","12919","1380187452"
"Bagnolo Mella","Bagnolo Mella","45.4333","10.1833","Italy","IT","ITA","Lombardy","","12677","1380227920"
"Burgos","Burgos","17.3331","120.4944","Philippines","PH","PHL","Ilocos Sur","","12793","1608752427"
"Harrison","Harrison","36.2438","-93.1198","United States","US","USA","Arkansas","","13081","1840013327"
"Newton","Newton","35.6630","-81.2335","United States","US","USA","North Carolina","","13081","1840014523"
"Maryport","Maryport","54.7128","-3.4926","United Kingdom","GB","GBR","Cumbria","","11262","1826196892"
"Saint-Colomban","Saint-Colomban","45.7300","-74.1300","Canada","CA","CAN","Quebec","","13080","1124001676"
"Grand Terrace","Grand Terrace","34.0312","-117.3132","United States","US","USA","California","","13079","1840020403"
"Ampanefena","Ampanefena","-13.8667","49.9667","Madagascar","MG","MDG","Antsiranana","","13000","1450684282"
"Honiton","Honiton","50.8000","-3.1900","United Kingdom","GB","GBR","Devon","","11822","1826581914"
"Colesberg","Colesberg","-30.7167","25.1000","South Africa","ZA","ZAF","Northern Cape","","12423","1710885210"
"Treuchtlingen","Treuchtlingen","48.9553","10.9094","Germany","DE","DEU","Bavaria","","12951","1276141545"
"Pingtiancun","Pingtiancun","25.2225","114.6252","China","CN","CHN","Guangdong","","12955","1156840263"
"Jacksonville","Jacksonville","33.8088","-85.7545","United States","US","USA","Alabama","","13076","1840007433"
"Cusset","Cusset","46.1344","3.4564","France","FR","FRA","Auvergne-Rhône-Alpes","","12685","1250595334"
"Nova Londrina","Nova Londrina","-22.7658","-52.9850","Brazil","BR","BRA","Paraná","","13067","1076898480"
"Morarano","Morarano","-18.9000","47.3167","Madagascar","MG","MDG","Antananarivo","","13000","1450596372"
"Ambodisakoana","Ambodisakoana","-15.4167","47.9333","Madagascar","MG","MDG","Mahajanga","","13000","1450641793"
"Cedar Grove","Cedar Grove","40.8565","-74.2288","United States","US","USA","New Jersey","","13073","1840081752"
"Bedford","Bedford","41.3919","-81.5360","United States","US","USA","Ohio","","13073","1840003396"
"Claye-Souilly","Claye-Souilly","48.9450","2.6867","France","FR","FRA","Île-de-France","","12264","1250084946"
"Dorandā","Doranda","24.4710","85.9449","India","IN","IND","Jhārkhand","","12195","1356256415"
"Ben","Ben","32.5444","50.7461","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","12971","1364428326"
"Morafeno","Morafeno","-21.1000","48.2000","Madagascar","MG","MDG","Fianarantsoa","","13000","1450422826"
"Graulhet","Graulhet","43.7608","1.9886","France","FR","FRA","Occitanie","","12844","1250475546"
"Mandritsara","Mandritsara","-19.5500","47.0500","Madagascar","MG","MDG","Antananarivo","","13000","1450508025"
"Lowshān","Lowshan","36.6206","49.5106","Iran","IR","IRN","Gīlān","","13032","1364318823"
"Vöcklabruck","Vocklabruck","48.0086","13.6558","Austria","AT","AUT","Oberösterreich","minor","12299","1040721177"
"Herzberg am Harz","Herzberg am Harz","51.6575","10.3411","Germany","DE","DEU","Lower Saxony","","12889","1276001736"
"Crayford","Crayford","51.4491","0.1812","United Kingdom","GB","GBR","Kent","","11226","1826027984"
"Chernigovka","Chernigovka","44.3406","132.5689","Russia","RU","RUS","Primorskiy Kray","","13046","1643176778"
"Arohi","Arohi","23.4270","88.6056","India","IN","IND","Himāchal Pradesh","","12794","1356395367"
"Frecheirinha","Frecheirinha","-3.7600","-40.8158","Brazil","BR","BRA","Ceará","","12991","1076241447"
"Gamboula","Gamboula","4.1333","15.1500","Central African Republic","CF","CAF","Mambéré-Kadéï","","13048","1140530300"
"Chinnakkāmpālaiyam","Chinnakkampalaiyam","10.7064","77.4575","India","IN","IND","Tamil Nādu","","12796","1356171080"
"Peißenberg","Peissenberg","47.7950","11.0603","Germany","DE","DEU","Bavaria","","12674","1276277124"
"San Lucas","San Lucas","13.4144","-86.6114","Nicaragua","NI","NIC","Madriz","minor","12975","1558795437"
"Ortaklar","Ortaklar","37.8833","27.5000","Turkey","TR","TUR","Aydın","minor","12960","1792405965"
"Mirabela","Mirabela","-16.2628","-44.1639","Brazil","BR","BRA","Minas Gerais","","13042","1076000722"
"Kharar","Kharar","22.7000","87.6800","India","IN","IND","West Bengal","","12118","1356793502"
"Annapolis Neck","Annapolis Neck","38.9409","-76.4997","United States","US","USA","Maryland","","13059","1840039445"
"Stjørdalshalsen","Stjordalshalsen","63.4712","10.9189","Norway","NO","NOR","Trøndelag","","13032","1578486368"
"Battulapalle","Battulapalle","14.5167","77.7833","India","IN","IND","Andhra Pradesh","","12697","1356852308"
"Albatera","Albatera","38.1786","-0.8681","Spain","ES","ESP","Valencia","","12864","1724280918"
"Hosir","Hosir","23.7735","85.8132","India","IN","IND","Jhārkhand","","12596","1356192410"
"Tetiiv","Tetiiv","49.3708","29.6900","Ukraine","UA","UKR","Kyivska Oblast","minor","13015","1804600319"
"Vieira do Minho","Vieira do Minho","41.6333","-8.1333","Portugal","PT","PRT","Braga","minor","12997","1620164873"
"Lacombe","Lacombe","52.4683","-113.7369","Canada","CA","CAN","Alberta","","13057","1124057569"
"Amersfoort","Amersfoort","-27.0078","29.8711","South Africa","ZA","ZAF","Mpumalanga","","12335","1710004486"
"Ātmakūru","Atmakuru","14.6452","77.3554","India","IN","IND","Andhra Pradesh","","12926","1356647980"
"Manalalondo","Manalalondo","-19.2500","47.1167","Madagascar","MG","MDG","Antananarivo","","12995","1450712810"
"Marovandrika","Marovandrika","-22.5167","47.6667","Madagascar","MG","MDG","Fianarantsoa","","13000","1450169623"
"Evato","Evato","-22.6000","47.6833","Madagascar","MG","MDG","Fianarantsoa","","13000","1450349321"
"Bānsbāri","Bansbari","26.1934","87.4498","India","IN","IND","Bihār","","11374","1356119213"
"Kaji","Kaji","26.0249","102.7873","China","CN","CHN","Yunnan","minor","13005","1156095715"
"Kyotera","Kyotera","-0.6317","31.5433","Uganda","UG","UGA","Rakai","","12789","1800498277"
"Alcácer do Sal","Alcacer do Sal","38.3725","-8.5136","Portugal","PT","PRT","Setúbal","minor","13046","1620351188"
"Campiglia Marittima","Campiglia Marittima","43.0667","10.6167","Italy","IT","ITA","Tuscany","","13018","1380320661"
"Tenmalai","Tenmalai","9.3129","77.4959","India","IN","IND","Tamil Nādu","","12636","1356209027"
"Manambolo","Manambolo","-15.2833","49.7000","Madagascar","MG","MDG","Toamasina","","13000","1450568646"
"Juan de Herrera","Juan de Herrera","18.8667","-71.2333","Dominican Republic","DO","DOM","El Valle","","12963","1214992695"
"Sebt Bni Garfett","Sebt Bni Garfett","35.2500","-5.8333","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12994","1504641834"
"West Carrollton","West Carrollton","39.6701","-84.2542","United States","US","USA","Ohio","","13051","1840059955"
"Abū Dīs","Abu Dis","31.7625","35.2658","West Bank","XW","XWB","","","12604","1934018131"
"Čelákovice","Celakovice","50.1606","14.7500","Czechia","CZ","CZE","Středočeský Kraj","","12293","1203396532"
"Wächtersbach","Wachtersbach","50.2547","9.2919","Germany","DE","DEU","Hesse","","12797","1276414935"
"Rochester","Rochester","42.6866","-83.1198","United States","US","USA","Michigan","","13048","1840002428"
"Siderópolis","Sideropolis","-28.5978","-49.4239","Brazil","BR","BRA","Santa Catarina","","12998","1076815331"
"Halásztelek","Halasztelek","47.3608","18.9878","Hungary","HU","HUN","Pest","","11694","1348284345"
"Villa Nueva","Villa Nueva","-32.9000","-68.7667","Argentina","AR","ARG","Mendoza","minor","11104","1032473395"
"Amborompotsy","Amborompotsy","-24.6667","44.9500","Madagascar","MG","MDG","Toliara","","13000","1450600717"
"Marovato","Marovato","-25.5333","45.2667","Madagascar","MG","MDG","Toliara","","13000","1450393411"
"Santo Stino di Livenza","Santo Stino di Livenza","45.7333","12.6833","Italy","IT","ITA","Veneto","","12855","1380323884"
"Notodden","Notodden","59.6294","9.1914","Norway","NO","NOR","Vestfold og Telemark","minor","13029","1578892380"
"Boortmeerbeek","Boortmeerbeek","50.9833","4.5667","Belgium","BE","BEL","Flanders","","12379","1056329239"
"Vikāsnagar","Vikasnagar","30.4680","77.7740","India","IN","IND","Uttarākhand","","12485","1356377577"
"Rehti","Rehti","22.7378","77.4340","India","IN","IND","Madhya Pradesh","","12859","1356617853"
"Parigi","Parigi","13.8929","77.4598","India","IN","IND","Andhra Pradesh","","12614","1356618142"
"Bryn","Bryn","53.4990","-2.6570","United Kingdom","GB","GBR","Wigan","","11662","1826011571"
"Bemanevika","Bemanevika","-14.1333","50.1000","Madagascar","MG","MDG","Antsiranana","","13000","1450530961"
"Pharāha","Pharaha","24.8522","85.5471","India","IN","IND","Bihār","","11052","1356014460"
"Antsirabe Afovoany","Antsirabe Afovoany","-15.9500","48.9667","Madagascar","MG","MDG","Mahajanga","","13000","1450923807"
"Fehmarnsund","Fehmarnsund","54.4454","11.1702","Germany","DE","DEU","Schleswig-Holstein","","12971","1276306503"
"El Marsa","El Marsa","36.8111","3.2556","Algeria","DZ","DZA","Alger","","12100","1012716943"
"Kotra","Kotra","22.7062","88.5411","India","IN","IND","West Bengal","","11615","1356847696"
"Ankilimalinika","Ankilimalinika","-22.9500","43.5667","Madagascar","MG","MDG","Toliara","","13000","1450201063"
"Sandrakatsy","Sandrakatsy","-16.3333","49.6167","Madagascar","MG","MDG","Toamasina","","13000","1450889811"
"Manambidala","Manambidala","-22.7500","47.2667","Madagascar","MG","MDG","Fianarantsoa","","13000","1450982031"
"Shahr-e Herāt","Shahr-e Herat","30.0547","54.3717","Iran","IR","IRN","Yazd","minor","13032","1364330823"
"Isaka-Ivondro","Isaka-Ivondro","-24.8000","46.8500","Madagascar","MG","MDG","Toliara","","13000","1450725316"
"Andreba","Andreba","-14.5833","48.1833","Madagascar","MG","MDG","Mahajanga","","13000","1450633860"
"Ambararata","Ambararata","-15.0167","48.6333","Madagascar","MG","MDG","Mahajanga","","13000","1450169435"
"Gárdony","Gardony","47.1973","18.6091","Hungary","HU","HUN","Fejér","minor","12832","1348505408"
"La Chapelle-Saint-Luc","La Chapelle-Saint-Luc","48.3119","4.0444","France","FR","FRA","Grand Est","","11913","1250799427"
"Tabocas do Brejo Velho","Tabocas do Brejo Velho","-12.7058","-44.0069","Brazil","BR","BRA","Bahia","","13025","1076911587"
"Pallejá","Palleja","41.4242","1.9978","Spain","ES","ESP","Catalonia","","11636","1724638711"
"Mudhol","Mudhol","18.9667","77.9167","India","IN","IND","Andhra Pradesh","","12777","1356741326"
"Solofra","Solofra","40.8333","14.8500","Italy","IT","ITA","Campania","","12470","1380330990"
"Sahavalanina-Antenina","Sahavalanina-Antenina","-16.5667","49.2500","Madagascar","MG","MDG","Toamasina","","13000","1450370161"
"Ankilivalo","Ankilivalo","-20.2833","44.6333","Madagascar","MG","MDG","Toliara","","13000","1450597906"
"Bolongongo","Bolongongo","-8.4667","15.2500","Angola","AO","AGO","Cuanza-Norte","","13019","1024319966"
"Linnich","Linnich","50.9789","6.2678","Germany","DE","DEU","North Rhine-Westphalia","","12835","1276427998"
"Portland","Portland","36.5921","-86.5239","United States","US","USA","Tennessee","","13031","1840014418"
"Mamborê","Mambore","-24.3189","-52.5300","Brazil","BR","BRA","Paraná","","13014","1076214001"
"Schodack","Schodack","42.5297","-73.6858","United States","US","USA","New York","","13030","1840087891"
"Mangindrano","Mangindrano","-14.2833","48.9667","Madagascar","MG","MDG","Mahajanga","","13000","1450075192"
"Lansdowne","Lansdowne","39.0844","-77.4839","United States","US","USA","Virginia","","13029","1840041773"
"Mahāgama","Mahagama","25.0342","87.3187","India","IN","IND","Jhārkhand","","11412","1356275174"
"Ambohimahavelona","Ambohimahavelona","-23.4333","43.8833","Madagascar","MG","MDG","Toliara","","13000","1450064416"
"Tāran","Taran","26.1570","87.5430","India","IN","IND","Bihār","","11965","1356054851"
"Mineiros do Tietê","Mineiros do Tiete","-22.4089","-48.4508","Brazil","BR","BRA","São Paulo","","12966","1076206689"
"Amboaboa","Amboaboa","-15.9333","48.7167","Madagascar","MG","MDG","Mahajanga","","13000","1450226220"
"Cambridge","Cambridge","38.5515","-76.0787","United States","US","USA","Maryland","","13026","1840006165"
"Ambahoabe","Ambahoabe","-16.7667","49.5000","Madagascar","MG","MDG","Toamasina","","13000","1450430279"
"Ban Tat","Ban Tat","17.2791","102.8022","Thailand","TH","THA","Udon Thani","","12760","1764551392"
"Puttānattam","Puttanattam","10.4670","78.3687","India","IN","IND","Tamil Nādu","","12637","1356016712"
"Oulad Rahmoun","Oulad Rahmoun","32.3278","-6.5006","Morocco","MA","MAR","Béni Mellal-Khénifra","","12720","1504810043"
"Anolaima","Anolaima","4.7617","-74.4640","Colombia","CO","COL","Cundinamarca","minor","12911","1170457629"
"Solana Beach","Solana Beach","32.9943","-117.2574","United States","US","USA","California","","13025","1840021993"
"Andohajango","Andohajango","-15.9000","48.5000","Madagascar","MG","MDG","Mahajanga","","13000","1450979666"
"North Adams","North Adams","42.6844","-73.1166","United States","US","USA","Massachusetts","","13024","1840000423"
"Shiwan","Shiwan","37.4786","109.4230","China","CN","CHN","Shaanxi","","12942","1156709549"
"Ampitasimo","Ampitasimo","-17.7833","48.3833","Madagascar","MG","MDG","Toamasina","","13000","1450380832"
"Ilafy","Ilafy","-17.8833","48.4167","Madagascar","MG","MDG","Toamasina","","13000","1450451732"
"Morungaba","Morungaba","-22.8800","-46.7917","Brazil","BR","BRA","São Paulo","","12934","1076597172"
"Kirikera","Kirikera","13.7690","77.4841","India","IN","IND","Andhra Pradesh","","11907","1356812667"
"Brieselang","Brieselang","52.5833","13.0000","Germany","DE","DEU","Brandenburg","","12735","1276596392"
"Maktar","Maktar","35.8606","9.2058","Tunisia","TN","TUN","Siliana","","12942","1788602708"
"Tranovaho","Tranovaho","-25.3000","44.9667","Madagascar","MG","MDG","Toliara","","13000","1450131232"
"Ampanavoana","Ampanavoana","-15.6667","50.2000","Madagascar","MG","MDG","Antsiranana","","13009","1450838781"
"Ambatolampy","Ambatolampy","-18.9000","46.1167","Madagascar","MG","MDG","Antananarivo","","13000","1450574788"
"Parker","Parker","34.8513","-82.4512","United States","US","USA","South Carolina","","13019","1840035848"
"Hanumantanpatti","Hanumantanpatti","9.7858","77.3170","India","IN","IND","Tamil Nādu","","12439","1356252401"
"Bonneville","Bonneville","46.0789","6.4008","France","FR","FRA","Auvergne-Rhône-Alpes","minor","12557","1250437955"
"Antongomena-Bevary","Antongomena-Bevary","-15.9500","45.9333","Madagascar","MG","MDG","Mahajanga","","13000","1450046754"
"Trezzo sull’Adda","Trezzo sull'Adda","45.6089","9.5200","Italy","IT","ITA","Lombardy","","12090","1380406444"
"Plan-de-Cuques","Plan-de-Cuques","43.3469","5.4631","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11649","1250022523"
"Zhdanivka","Zhdanivka","48.1500","38.2500","Ukraine","UA","UKR","Donetska Oblast","","11913","1804790480"
"Lauritsala","Lauritsala","61.0708","28.2597","Finland","FI","FIN","Etelä-Karjala","","12965","1246391744"
"Sunne","Sunne","59.8333","13.1333","Sweden","SE","SWE","Värmland","minor","13000","1752127912"
"Bni Khloug","Bni Khloug","32.6500","-7.3833","Morocco","MA","MAR","Casablanca-Settat","","12930","1504624308"
"Wanderley","Wanderley","-12.1200","-43.8878","Brazil","BR","BRA","Bahia","","13008","1076046103"
"Haslev","Haslev","55.3333","11.9667","Denmark","DK","DNK","Sjælland","minor","12119","1208160409"
"Ambakireny","Ambakireny","-17.6667","47.8500","Madagascar","MG","MDG","Mahajanga","","13000","1450303775"
"Galashiels","Galashiels","55.6194","-2.8033","United Kingdom","GB","GBR","Scottish Borders","","13000","1826185661"
"Bejofo","Bejofo","-17.8333","48.2333","Madagascar","MG","MDG","Toamasina","","13000","1450197169"
"Chapantongo","Chapantongo","20.2833","-99.4000","Mexico","MX","MEX","Hidalgo","","12967","1484970395"
"Kalingiyam","Kalingiyam","11.4324","77.4178","India","IN","IND","Tamil Nādu","","12389","1356164000"
"Gomparou","Gomparou","11.3000","2.4489","Benin","BJ","BEN","Alibori","","12934","1204353834"
"Kanel","Kanel","15.4833","-13.1667","Senegal","SN","SEN","Matam","","12975","1686649291"
"Sirūr","Sirur","16.0965","75.7858","India","IN","IND","Karnātaka","","12623","1356261996"
"Santa Luzia do Itanhy","Santa Luzia do Itanhy","-11.3508","-37.4478","Brazil","BR","BRA","Sergipe","","12969","1076556984"
"Dorado","Dorado","18.4657","-66.2726","Puerto Rico","PR","PRI","Puerto Rico","","13008","1630023736"
"Divnoye","Divnoye","45.9072","43.3558","Russia","RU","RUS","Stavropol’skiy Kray","","12998","1643003956"
"Ḩarmah","Harmah","25.9261","45.3336","Saudi Arabia","SA","SAU","Ar Riyāḑ","","13000","1682617431"
"St. Ann","St. Ann","38.7266","-90.3872","United States","US","USA","Missouri","","13007","1840009771"
"Upanema","Upanema","-5.6419","-37.2578","Brazil","BR","BRA","Rio Grande do Norte","","12992","1076592037"
"Kesabpur","Kesabpur","22.9700","88.2600","India","IN","IND","West Bengal","","12073","1356046002"
"Khajuri","Khajuri","25.9144","86.6772","India","IN","IND","Bihār","","11854","1356544502"
"Maroviro","Maroviro","-21.2333","44.7333","Madagascar","MG","MDG","Toliara","","13000","1450225913"
"Chero","Chero","25.2463","85.8169","India","IN","IND","Bihār","","11836","1356177404"
"Bou Adel","Bou Adel","34.5428","-4.5075","Morocco","MA","MAR","Fès-Meknès","","12684","1504793576"
"Loyish Shaharchasi","Loyish Shaharchasi","39.8839","66.7481","Uzbekistan","UZ","UZB","Samarqand","minor","12769","1860150334"
"Guisser","Guisser","32.7667","-7.5000","Morocco","MA","MAR","Casablanca-Settat","","12289","1504348253"
"Amboise","Amboise","47.4114","0.9825","France","FR","FRA","Centre-Val de Loire","","12693","1250134855"
"Sooke","Sooke","48.3761","-123.7378","Canada","CA","CAN","British Columbia","","13001","1124034713"
"Green Valley","Green Valley","39.3414","-77.2400","United States","US","USA","Maryland","","12999","1840133409"
"Meadville","Meadville","41.6476","-80.1468","United States","US","USA","Pennsylvania","","12999","1840000558"
"Chaiyo","Chaiyo","14.6666","100.4702","Thailand","TH","THA","Ang Thong","minor","12710","1764400832"
"Miami","Miami","36.8878","-94.8711","United States","US","USA","Oklahoma","","12997","1840020346"
"Antri","Antri","26.0581","78.2100","India","IN","IND","Madhya Pradesh","","12586","1356016828"
"Rozhyshche","Rozhyshche","50.9131","25.2700","Ukraine","UA","UKR","Volynska Oblast","","12953","1804390315"
"Dagmāra","Dagmara","26.3953","86.7601","India","IN","IND","Bihār","","12268","1356068809"
"Oatfield","Oatfield","45.4125","-122.5940","United States","US","USA","Oregon","","12993","1840034837"
"Joppatowne","Joppatowne","39.4181","-76.3516","United States","US","USA","Maryland","","12992","1840005665"
"Antigua","Antigua","28.4160","-14.0118","Spain","ES","ESP","Canary Islands","","12940","1724978313"
"Bruay-sur-l’Escaut","Bruay-sur-l'Escaut","50.3983","3.5394","France","FR","FRA","Hauts-de-France","","11311","1250001269"
"Teutschenthal","Teutschenthal","51.4500","11.8000","Germany","DE","DEU","Saxony-Anhalt","","12850","1276601483"
"Maizières-lès-Metz","Maizieres-les-Metz","49.2122","6.1611","France","FR","FRA","Grand Est","","11668","1250969949"
"Brzeg Dolny","Brzeg Dolny","51.2708","16.7208","Poland","PL","POL","Dolnośląskie","","12816","1616585341"
"Mercogliano","Mercogliano","40.9231","14.7428","Italy","IT","ITA","Campania","","12369","1380603196"
"Perez","Perez","14.1833","121.9333","Philippines","PH","PHL","Quezon","minor","12767","1608929976"
"Mariánské Lázně","Marianske Lazne","49.9647","12.7011","Czechia","CZ","CZE","Karlovarský Kraj","","12752","1203682983"
"Montgomeryville","Montgomeryville","40.2502","-75.2405","United States","US","USA","Pennsylvania","","12988","1840005457"
"Ifs","Ifs","49.1383","-0.3531","France","FR","FRA","Normandie","","11696","1250020337"
"Nagar","Nagar","24.0914","87.9888","India","IN","IND","West Bengal","","11882","1356675788"
"San Juan Tecuaco","San Juan Tecuaco","14.0836","-90.2664","Guatemala","GT","GTM","Santa Rosa","minor","12825","1320640188"
"Motkūr","Motkur","17.4500","79.2667","India","IN","IND","Andhra Pradesh","","12616","1356353860"
"Franeker","Franeker","53.2000","5.5333","Netherlands","NL","NLD","Fryslân","minor","12781","1528890881"
"Piketberg","Piketberg","-32.9000","18.7667","South Africa","ZA","ZAF","Western Cape","","12075","1710229097"
"Borgosesia","Borgosesia","45.7167","8.2667","Italy","IT","ITA","Piedmont","","12676","1380192410"
"Conceição do Rio Verde","Conceicao do Rio Verde","-21.8808","-45.0850","Brazil","BR","BRA","Minas Gerais","","12949","1076344752"
"South Daytona","South Daytona","29.1657","-81.0055","United States","US","USA","Florida","","12984","1840015950"
"Itayanagi","Itayanagi","40.6959","140.4572","Japan","JP","JPN","Aomori","","12681","1392658620"
"Gornyak","Gornyak","50.9833","81.4667","Russia","RU","RUS","Altayskiy Kray","","12972","1643814454"
"Dāmarcherla","Damarcherla","16.7269","79.6369","India","IN","IND","Andhra Pradesh","","12710","1356000300"
"Chinna Gollapālem","Chinna Gollapalem","16.3701","81.5322","India","IN","IND","Andhra Pradesh","","12738","1356089174"
"Montecorvino Rovella","Montecorvino Rovella","40.7000","14.9833","Italy","IT","ITA","Campania","","12682","1380643380"
"Schongau","Schongau","47.8167","10.9000","Germany","DE","DEU","Bavaria","","12401","1276690057"
"Licínio de Almeida","Licinio de Almeida","-14.6819","-42.5078","Brazil","BR","BRA","Bahia","","12966","1076371811"
"Nyāmti","Nyamti","14.1400","75.5600","India","IN","IND","Karnātaka","","12661","1356399864"
"Pianiga","Pianiga","45.4583","12.0080","Italy","IT","ITA","Veneto","","12363","1380942076"
"Kent","Kent","41.4735","-73.7319","United States","US","USA","New York","","12978","1840058632"
"Dawson Creek","Dawson Creek","55.7606","-120.2356","Canada","CA","CAN","British Columbia","","12978","1124081402"
"Palmital","Palmital","-24.8928","-52.2028","Brazil","BR","BRA","Paraná","","12960","1076062841"
"Karāhal","Karahal","25.4909","77.0543","India","IN","IND","Madhya Pradesh","","12903","1356107107"
"Yavoriv","Yavoriv","49.9469","23.3931","Ukraine","UA","UKR","Lvivska Oblast","minor","12888","1804586607"
"Anderlues","Anderlues","50.4080","4.2696","Belgium","BE","BEL","Wallonia","","12254","1056010234"
"Saposoa","Saposoa","-6.9364","-76.7718","Peru","PE","PER","San Martín","","12951","1604843122"
"Tashi","Tashi","34.4977","106.5281","China","CN","CHN","Shaanxi","","12904","1156072954"
"Trenton","Trenton","39.4792","-84.4620","United States","US","USA","Ohio","","12973","1840003805"
"Grave","Grave","51.7667","5.7333","Netherlands","NL","NLD","Noord-Brabant","minor","12486","1528377507"
"Kashasha","Kashasha","-1.7578","31.6158","Tanzania","TZ","TZA","Kagera","","12508","1834270082"
"Larkspur","Larkspur","37.9393","-122.5312","United States","US","USA","California","","12972","1840020261"
"Timberlake","Timberlake","37.3167","-79.2481","United States","US","USA","Virginia","","12972","1840006437"
"Ulubey","Ulubey","38.4167","29.2833","Turkey","TR","TUR","Uşak","minor","12955","1792931185"
"Arluno","Arluno","45.5000","8.9333","Italy","IT","ITA","Lombardy","","12000","1380328223"
"Bonita","Bonita","32.6651","-117.0296","United States","US","USA","California","","12970","1840018015"
"Kafr Buhum","Kafr Buhum","35.0611","36.6972","Syria","SY","SYR","Ḩamāh","","12194","1760856800"
"Gibraleón","Gibraleon","37.3753","-6.9694","Spain","ES","ESP","Andalusia","","12930","1724585426"
"Saúde","Saude","-10.9408","-40.4178","Brazil","BR","BRA","Bahia","","12943","1076626668"
"Peraiyūr","Peraiyur","9.7341","77.7900","India","IN","IND","Tamil Nādu","","12690","1356124163"
"Monte San Giovanni Campano","Monte San Giovanni Campano","41.6333","13.5167","Italy","IT","ITA","Lazio","","12706","1380135673"
"Syston","Syston","52.7000","-1.0800","United Kingdom","GB","GBR","Leicestershire","","12804","1826679514"
"Santiago","Santiago","9.8291","-84.3044","Costa Rica","CR","CRI","San José","","12600","1188768291"
"Sante Bennūr","Sante Bennur","14.1697","76.0033","India","IN","IND","Karnātaka","","12695","1356024084"
"Lipari","Lipari","38.4667","14.9500","Italy","IT","ITA","Sicilia","","12819","1380222047"
"Sun Village","Sun Village","34.5596","-117.9558","United States","US","USA","California","","12961","1840076298"
"Santo Tomás La Unión","Santo Tomas La Union","14.6333","-91.3833","Guatemala","GT","GTM","Suchitepéquez","minor","12800","1320346394"
"Broussard","Broussard","30.1396","-91.9540","United States","US","USA","Louisiana","","12960","1840013964"
"Moka","Moka","15.2380","77.0599","India","IN","IND","Karnātaka","","12359","1356765626"
"Overlea","Overlea","39.3642","-76.5175","United States","US","USA","Maryland","","12959","1840005683"
"Cantagalo","Cantagalo","-25.3739","-52.1258","Brazil","BR","BRA","Paraná","","12952","1076426121"
"Nandyālampeta","Nandyalampeta","14.7218","78.8083","India","IN","IND","Andhra Pradesh","","12768","1356121850"
"Yuza","Yuza","39.0148","139.9088","Japan","JP","JPN","Yamagata","","12895","1392728665"
"Hajdúhadház","Hajduhadhaz","47.6833","21.6667","Hungary","HU","HUN","Hajdú-Bihar","minor","12811","1348644629"
"Herk-de-Stad","Herk-de-Stad","50.9406","5.1672","Belgium","BE","BEL","Flanders","","12661","1056088942"
"Provins","Provins","48.5589","3.2994","France","FR","FRA","Île-de-France","minor","12132","1250217938"
"Üllő","Ullo","47.3854","19.3439","Hungary","HU","HUN","Pest","","12692","1348501834"
"Ribeirão do Pinhal","Ribeirao do Pinhal","-23.4167","-50.3500","Brazil","BR","BRA","Paraná","","12948","1076116676"
"Claremont","Claremont","43.3790","-72.3368","United States","US","USA","New Hampshire","","12955","1840002805"
"Mogocha","Mogocha","53.7333","119.7667","Russia","RU","RUS","Zabaykal’skiy Kray","","12953","1643177886"
"Kisara","Kisara","17.5233","78.6677","India","IN","IND","Andhra Pradesh","","11048","1356998500"
"Beelitz","Beelitz","52.2333","12.9667","Germany","DE","DEU","Brandenburg","","12880","1276861998"
"Mándra","Mandra","38.0667","23.5000","Greece","GR","GRC","Attikí","minor","12888","1300840026"
"Kuyganyor","Kuyganyor","40.8611","72.3111","Uzbekistan","UZ","UZB","Andijon","minor","12200","1860676676"
"Andijon","Andijon","40.6444","72.3639","Uzbekistan","UZ","UZB","Andijon","","12200","1860676293"
"Lucena","Lucena","-6.9000","-34.8689","Brazil","BR","BRA","Paraíba","","12804","1076932668"
"Rushall","Rushall","52.6110","-1.9570","United Kingdom","GB","GBR","Walsall","","11871","1826029820"
"Rokupr","Rokupr","9.0167","-12.9500","Sierra Leone","SL","SLE","Northern","","12744","1694701972"
"Misaki","Misaki","34.9979","133.9582","Japan","JP","JPN","Okayama","","12889","1392003215"
"Santo Niño","Santo Nino","11.9263","124.4492","Philippines","PH","PHL","Samar","","12519","1608284547"
"Japoatã","Japoata","-10.3469","-36.8008","Brazil","BR","BRA","Sergipe","","12938","1076539060"
"Jagdīshpur","Jagdishpur","26.1526","86.1146","India","IN","IND","Bihār","","11225","1356751845"
"Croxley Green","Croxley Green","51.6470","-0.4450","United Kingdom","GB","GBR","Hertfordshire","","12562","1826810678"
"Mablethorpe","Mablethorpe","53.3409","0.2610","United Kingdom","GB","GBR","Lincolnshire","","12531","1826000615"
"Sothgaon","Sothgaon","26.6046","85.9473","India","IN","IND","Bihār","","11678","1356360652"
"Balbalan","Balbalan","17.4436","121.2008","Philippines","PH","PHL","Kalinga","","12914","1608060387"
"Arês","Ares","-6.1939","-35.1600","Brazil","BR","BRA","Rio Grande do Norte","","12924","1076599761"
"Ibaretama","Ibaretama","-4.8039","-38.7528","Brazil","BR","BRA","Ceará","","12922","1076260521"
"Ribeirão Bonito","Ribeirao Bonito","-22.0669","-48.1758","Brazil","BR","BRA","São Paulo","","12909","1076447966"
"Velten","Velten","52.6833","13.1833","Germany","DE","DEU","Brandenburg","","12405","1276342323"
"Mar de Espanha","Mar de Espanha","-21.8669","-43.0100","Brazil","BR","BRA","Minas Gerais","","12901","1076321934"
"San Pablo","San Pablo","1.6725","-77.0139","Colombia","CO","COL","Nariño","minor","12929","1170794698"
"De Haan","De Haan","51.2731","3.0336","Belgium","BE","BEL","Flanders","","12635","1056930293"
"Hāthidāh Buzurg","Hathidah Buzurg","25.3716","85.9857","India","IN","IND","Bihār","","11057","1356437155"
"Kingsbury","Kingsbury","43.3440","-73.5396","United States","US","USA","New York","","12932","1840058216"
"Ridge","Ridge","40.9068","-72.8816","United States","US","USA","New York","","12931","1840005068"
"Vadnais Heights","Vadnais Heights","45.0570","-93.0748","United States","US","USA","Minnesota","","12931","1840010004"
"Castel Goffredo","Castel Goffredo","45.2981","10.4750","Italy","IT","ITA","Lombardy","","12633","1380666608"
"El Qâa","El Qaa","34.3436","36.4756","Lebanon","LB","LBN","Baalbek-Hermel","","12000","1422667021"
"Takamori","Takamori","35.5138","137.8739","Japan","JP","JPN","Nagano","","12650","1392003163"
"Gerpinnes","Gerpinnes","50.3369","4.5283","Belgium","BE","BEL","Wallonia","","12660","1056491300"
"Rivarolo Canavese","Rivarolo Canavese","45.3312","7.7171","Italy","IT","ITA","Piedmont","","12539","1380310565"
"Kakching Khunou","Kakching Khunou","24.4047","93.8998","India","IN","IND","","","12299","1356271744"
"Santa Margarita","Santa Margarita","39.7033","3.1036","Spain","ES","ESP","Balearic Islands","","12776","1724447564"
"Vaddāpalli","Vaddapalli","18.5324","77.8950","India","IN","IND","Andhra Pradesh","","12281","1356963102"
"Pell City","Pell City","33.5610","-86.2669","United States","US","USA","Alabama","","12923","1840014771"
"Zhongling","Zhongling","28.9391","107.7073","China","CN","CHN","Guizhou","","12845","1156685899"
"Lake Country","Lake Country","50.0833","-119.4142","Canada","CA","CAN","British Columbia","","12922","1124001544"
"Chamical","Chamical","-30.3667","-66.3167","Argentina","AR","ARG","La Rioja","minor","12919","1032026963"
"Veľký Krtíš","Vel'ky Krtis","48.2150","19.3381","Slovakia","SK","SVK","Banská Bystrica","minor","12115","1703541479"
"Ban Laem","Ban Laem","13.2168","99.9767","Thailand","TH","THA","Phetchaburi","minor","12541","1764878982"
"Douar Oulad Youssef","Douar Oulad Youssef","32.4807","-6.3769","Morocco","MA","MAR","Béni Mellal-Khénifra","","12804","1504001351"
"Kaviti","Kaviti","19.0167","84.6833","India","IN","IND","Andhra Pradesh","","11984","1356984645"
"Asahi","Asahi","35.0343","136.6644","Japan","JP","JPN","Mie","","11070","1392003328"
"Peligros","Peligros","37.2333","-3.6333","Spain","ES","ESP","Andalusia","","11624","1724473390"
"Sim","Sim","54.9833","57.7000","Russia","RU","RUS","Chelyabinskaya Oblast’","","12893","1643571460"
"Kaeng Khoi","Kaeng Khoi","14.5864","100.9967","Thailand","TH","THA","Saraburi","minor","12752","1764167813"
"Big Bear City","Big Bear City","34.2536","-116.7903","United States","US","USA","California","","12913","1840017797"
"Hungen","Hungen","50.4667","8.9000","Germany","DE","DEU","Hesse","","12765","1276067778"
"Mādugula","Madugula","17.9167","82.8000","India","IN","IND","Andhra Pradesh","","12704","1356549390"
"San Mateo","San Mateo","9.7400","-64.5531","Venezuela","VE","VEN","Anzoátegui","minor","12905","1862621121"
"Brzeziny","Brzeziny","51.8000","19.7500","Poland","PL","POL","Łódzkie","minor","12501","1616839084"
"Magdalena Milpas Altas","Magdalena Milpas Altas","14.5453","-90.6753","Guatemala","GT","GTM","Sacatepéquez","minor","12766","1320138967"
"Frutillar","Frutillar","-41.1258","-73.0605","Chile","CL","CHL","Los Lagos","","12876","1152151773"
"Avrig","Avrig","45.7081","24.3747","Romania","RO","ROU","Sibiu","","12815","1642698711"
"Palacagüina","Palacaguina","13.4550","-86.4050","Nicaragua","NI","NIC","Madriz","minor","12825","1558692932"
"Saint-André-de-Cubzac","Saint-Andre-de-Cubzac","44.9947","-0.4458","France","FR","FRA","Nouvelle-Aquitaine","","12372","1250001544"
"Niefern-Öschelbronn","Niefern-Oschelbronn","48.9164","8.7842","Germany","DE","DEU","Baden-Württemberg","","12345","1276349542"
"Uruoca","Uruoca","-3.3139","-40.5569","Brazil","BR","BRA","Ceará","","12883","1076593478"
"Ambalarondra","Ambalarondra","-18.4667","48.9833","Madagascar","MG","MDG","Toamasina","","12884","1450882593"
"Milovice","Milovice","50.2260","14.8887","Czechia","CZ","CZE","Středočeský Kraj","","12460","1203709386"
"Kot Shamir","Kot Shamir","30.1191","75.0058","India","IN","IND","Punjab","","12294","1356729057"
"Trent Hills","Trent Hills","44.3142","-77.8514","Canada","CA","CAN","Ontario","","12900","1124001755"
"Tomar do Geru","Tomar do Geru","-11.3728","-37.8408","Brazil","BR","BRA","Sergipe","","12855","1076263182"
"Malaryta","Malaryta","51.7833","24.0833","Belarus","BY","BLR","Brestskaya Voblasts’","minor","12883","1112089191"
"Beaufort","Beaufort","32.4597","-80.7235","United States","US","USA","South Carolina","","12899","1840013818"
"Möckern","Mockern","52.1406","11.9525","Germany","DE","DEU","Saxony-Anhalt","","12874","1276562892"
"Tizgane","Tizgane","35.4136","-5.0696","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12773","1504806808"
"Mesolóngi","Mesolongi","38.3692","21.4278","Greece","GR","GRC","Dytikí Elláda","minor","12785","1300632495"
"Purén","Puren","-38.0319","-73.0728","Chile","CL","CHL","Araucanía","","12868","1152043737"
"Āppukkudal","Appukkudal","11.4684","77.5766","India","IN","IND","Tamil Nādu","","12569","1356167373"
"Kilindoni","Kilindoni","-7.9167","39.6500","Tanzania","TZ","TZA","Coast","","12409","1834982666"
"Esmoriz","Esmoriz","40.9550","-8.6270","Portugal","PT","PRT","Aveiro","","11448","1620854749"
"Vyazemskiy","Vyazemskiy","47.5250","134.7572","Russia","RU","RUS","Khabarovskiy Kray","","12889","1643608053"
"Pilar","Pilar","10.8070","124.5650","Philippines","PH","PHL","Cebu","","12506","1608112350"
"Marikal","Marikal","16.6020","77.7294","India","IN","IND","Andhra Pradesh","","12550","1356109973"
"Kasba Tanora","Kasba Tanora","32.4751","-6.1498","Morocco","MA","MAR","Béni Mellal-Khénifra","","12783","1504367360"
"Woodhaven","Woodhaven","42.1320","-83.2374","United States","US","USA","Michigan","","12889","1840001848"
"Holiday City-Berkeley","Holiday City-Berkeley","39.9639","-74.2787","United States","US","USA","New Jersey","","12889","1840073689"
"Sainte-Marie","Sainte-Marie","46.4500","-71.0333","Canada","CA","CAN","Quebec","","12889","1124650507"
"Barud","Barud","21.7497","75.5270","India","IN","IND","Madhya Pradesh","","12749","1356142011"
"Belagal","Belagal","15.8183","77.6702","India","IN","IND","Andhra Pradesh","","12603","1356016761"
"Sonāpur","Sonapur","26.3716","87.2162","India","IN","IND","Bihār","","11494","1356446663"
"Valencia West","Valencia West","32.1355","-111.1123","United States","US","USA","Arizona","","12887","1840074666"
"Villeneuve-lès-Avignon","Villeneuve-les-Avignon","43.9664","4.7958","France","FR","FRA","Occitanie","","12216","1250823512"
"Kuala Kurun","Kuala Kurun","-1.1016","113.8664","Indonesia","ID","IDN","Kalimantan Tengah","","12870","1360003464"
"Le Chambon-Feugerolles","Le Chambon-Feugerolles","45.3961","4.3250","France","FR","FRA","Auvergne-Rhône-Alpes","","12200","1250932728"
"Florange","Florange","49.3214","6.1183","France","FR","FRA","Grand Est","","11972","1250388117"
"Muthuswāmipuram","Muthuswamipuram","9.3900","77.4887","India","IN","IND","Tamil Nādu","","12137","1356209038"
"Brugg","Brugg","47.4864","8.2067","Switzerland","CH","CHE","Aargau","","11129","1756121125"
"Gajhara","Gajhara","26.5237","86.3715","India","IN","IND","Bihār","","12153","1356575861"
"Chodavaram","Chodavaram","17.4380","81.7781","India","IN","IND","Andhra Pradesh","","12764","1356023415"
"Bassersdorf","Bassersdorf","47.4431","8.6283","Switzerland","CH","CHE","Zürich","","11593","1756124438"
"Ālwārkurichchi","Alwarkurichchi","8.7838","77.3952","India","IN","IND","Tamil Nādu","","12485","1356605258"
"Siur","Siur","24.8166","85.6728","India","IN","IND","Bihār","","11634","1356925006"
"Laarne","Laarne","51.0167","3.8500","Belgium","BE","BEL","Flanders","","12487","1056466372"
"Varzaneh","Varzaneh","32.4197","52.6483","Iran","IR","IRN","Eşfahān","","12714","1364732883"
"New Hanover","New Hanover","40.3145","-75.5566","United States","US","USA","Pennsylvania","","12876","1840103521"
"Kārvetnagar","Karvetnagar","13.4167","79.4500","India","IN","IND","Andhra Pradesh","","12031","1356243834"
"Strombeek-Bever","Strombeek-Bever","50.9100","4.3500","Belgium","BE","BEL","Brussels-Capital Region","","11500","1056731023"
"Hemsbach","Hemsbach","49.5903","8.6564","Germany","DE","DEU","Baden-Württemberg","","11968","1276078759"
"Surkhakhi","Surkhakhi","43.1878","44.9036","Russia","RU","RUS","Ingushetiya","","12734","1643874723"
"Garkha","Garkha","25.8293","84.8617","India","IN","IND","Bihār","","10879","1356169350"
"Lynnfield","Lynnfield","42.5356","-71.0382","United States","US","USA","Massachusetts","","12874","1840053531"
"Novhorod-Siverskyi","Novhorod-Siverskyi","51.9972","33.2667","Ukraine","UA","UKR","Chernihivska Oblast","minor","12862","1804704468"
"Saint-Jean","Saint-Jean","43.6653","1.5050","France","FR","FRA","Occitanie","","11017","1250935813"
"Arrigorriaga","Arrigorriaga","43.2078","-2.8861","Spain","ES","ESP","Basque Country","","12140","1724012334"
"Pebberu","Pebberu","16.2167","77.9833","India","IN","IND","Andhra Pradesh","","12289","1356076940"
"Mānegaon","Manegaon","23.2062","80.0123","India","IN","IND","Madhya Pradesh","","12100","1356575505"
"L’Isle-Adam","L'Isle-Adam","49.1111","2.2228","France","FR","FRA","Île-de-France","","12063","1250488808"
"Choró","Choro","-4.8428","-39.1408","Brazil","BR","BRA","Ceará","","12853","1076374357"
"Dharhara","Dharhara","25.2543","86.4030","India","IN","IND","Bihār","","12363","1356980037"
"Raun","Raun","25.6462","86.3869","India","IN","IND","Bihār","","11515","1356582103"
"Churchdown","Churchdown","51.8800","-2.1700","United Kingdom","GB","GBR","Gloucestershire","","10990","1826299477"
"Richton Park","Richton Park","41.4816","-87.7387","United States","US","USA","Illinois","","12867","1840011283"
"Arhribs","Arhribs","36.8022","4.3227","Algeria","DZ","DZA","Tizi Ouzou","","12474","1012696122"
"Lubaczów","Lubaczow","50.1500","23.1167","Poland","PL","POL","Podkarpackie","minor","12415","1616650009"
"Bhandārso","Bhandarso","26.1780","86.1415","India","IN","IND","Bihār","","11151","1356119955"
"Grumo Appula","Grumo Appula","41.0167","16.7000","Italy","IT","ITA","Puglia","","12710","1380738889"
"Berja","Berja","36.8453","-2.9469","Spain","ES","ESP","Andalusia","","12807","1724229322"
"Chiatura","Chiatura","42.2903","43.2819","Georgia","GE","GEO","Imereti","minor","12803","1268109210"
"Almirante","Almirante","9.3000","-82.4000","Panama","PA","PAN","Bocas del Toro","minor","12731","1591293829"
"Limburgerhof","Limburgerhof","49.4222","8.3919","Germany","DE","DEU","Rhineland-Palatinate","","11578","1276445051"
"Cornwall","Cornwall","41.4195","-74.0540","United States","US","USA","New York","","12864","1840087346"
"Oxapampa","Oxapampa","-10.5740","-75.4050","Peru","PE","PER","Pasco","","12826","1604643521"
"Lauenburg","Lauenburg","53.3833","10.5667","Germany","DE","DEU","Schleswig-Holstein","","11644","1276704232"
"Voerendaal","Voerendaal","50.8833","5.9333","Netherlands","NL","NLD","Limburg","minor","12466","1528448333"
"Partiāla","Partiala","16.4537","80.3039","India","IN","IND","Andhra Pradesh","","12485","1356064375"
"Mora","Mora","61.0167","14.5333","Sweden","SE","SWE","Dalarna","minor","12854","1752665392"
"Rasaunk","Rasaunk","25.5537","86.5117","India","IN","IND","Bihār","","11165","1356970952"
"New Kingman-Butler","New Kingman-Butler","35.2645","-114.0091","United States","US","USA","Arizona","","12858","1840037118"
"East Hampton","East Hampton","41.5696","-72.5074","United States","US","USA","Connecticut","","12858","1840034616"
"Magsaysay","Magsaysay","10.8667","121.0500","Philippines","PH","PHL","Palawan","","12603","1608360033"
"Umri","Umri","26.5106","78.9367","India","IN","IND","Madhya Pradesh","","11947","1356172360"
"Costa de Caparica","Costa de Caparica","38.6446","-9.2356","Portugal","PT","PRT","Setúbal","","11707","1620140762"
"Dorking","Dorking","51.2325","-0.3306","United Kingdom","GB","GBR","Surrey","","11158","1826338951"
"Bohmte","Bohmte","52.3667","8.3167","Germany","DE","DEU","Lower Saxony","","12741","1276943763"
"Newcastle","Newcastle","47.5304","-122.1633","United States","US","USA","Washington","","12855","1840019831"
"El Dorado","El Dorado","37.8210","-96.8613","United States","US","USA","Kansas","","12855","1840001676"
"Dorog","Dorog","47.7194","18.7292","Hungary","HU","HUN","Komárom-Esztergom","","11883","1348677837"
"Pittalavānipālem","Pittalavanipalem","15.9806","80.6347","India","IN","IND","Andhra Pradesh","","12036","1356781905"
"Bretzfeld","Bretzfeld","49.1833","9.4333","Germany","DE","DEU","Baden-Württemberg","","12651","1276507113"
"Levelland","Levelland","33.5806","-102.3635","United States","US","USA","Texas","","12854","1840020606"
"Chino Valley","Chino Valley","34.7594","-112.4120","United States","US","USA","Arizona","","12854","1840022753"
"Guelph/Eramosa","Guelph/Eramosa","43.6300","-80.2200","Canada","CA","CAN","Ontario","","12854","1124001707"
"Puerto Jiménez","Puerto Jimenez","8.5338","-83.4610","Costa Rica","CR","CRI","Puntarenas","","12836","1188020392"
"Andernos-les-Bains","Andernos-les-Bains","44.7425","-1.0903","France","FR","FRA","Nouvelle-Aquitaine","","12242","1250155920"
"Betma","Betma","22.6800","75.6200","India","IN","IND","Madhya Pradesh","","12529","1356357879"
"Zaragoza","Zaragoza","28.4869","-100.9175","Mexico","MX","MEX","Coahuila","minor","11150","1484517254"
"Weddington","Weddington","35.0228","-80.7383","United States","US","USA","North Carolina","","12851","1840016459"
"Umag","Umag","45.4333","13.5167","Croatia","HR","HRV","Istarska Županija","minor","12767","1191619826"
"Pastpār","Pastpar","25.8275","86.8327","India","IN","IND","Bihār","","11757","1356823857"
"Altenstadt","Altenstadt","50.2856","8.9450","Germany","DE","DEU","Hesse","","12437","1276344597"
"Pola de Laviana","Pola de Laviana","43.2358","-5.5563","Spain","ES","ESP","Asturias","","12754","1724362497"
"Bloomsburg","Bloomsburg","41.0027","-76.4561","United States","US","USA","Pennsylvania","","12850","1840000811"
"Cuisnahuat","Cuisnahuat","13.6333","-89.6000","El Salvador","SV","SLV","Sonsonate","","12676","1222033035"
"Nong Khae","Nong Khae","14.3352","100.8727","Thailand","TH","THA","Saraburi","minor","12336","1764836619"
"Donwari","Donwari","11.1197","2.8558","Benin","BJ","BEN","Alibori","","12773","1204710462"
"Malar","Malar","26.2237","86.6909","India","IN","IND","Bihār","","11860","1356254961"
"Sala Consilina","Sala Consilina","40.4000","15.6000","Italy","IT","ITA","Campania","","12636","1380877260"
"Locri","Locri","38.2160","16.2288","Italy","IT","ITA","Calabria","","12367","1380626724"
"Samashki","Samashki","43.2906","45.3014","Russia","RU","RUS","Chechnya","","12738","1643255362"
"Flexeiras","Flexeiras","-9.2728","-35.7150","Brazil","BR","BRA","Alagoas","","12807","1076291080"
"Naduhatti","Naduhatti","11.4043","76.8160","India","IN","IND","Tamil Nādu","","12628","1356141769"
"Santa Rosa de Calamuchita","Santa Rosa de Calamuchita","-32.0667","-64.5333","Argentina","AR","ARG","Córdoba","","12830","1032342654"
"Florida City","Florida City","25.4418","-80.4685","United States","US","USA","Florida","","12844","1840014250"
"Webb City","Webb City","37.1412","-94.4676","United States","US","USA","Missouri","","12843","1840010878"
"Méricourt","Mericourt","50.4022","2.8658","France","FR","FRA","Hauts-de-France","","11346","1250515600"
"Kulattūr","Kulattur","10.7052","78.5507","India","IN","IND","Tamil Nādu","","12276","1356961776"
"Urbana","Urbana","39.3274","-77.3423","United States","US","USA","Maryland","","12841","1840024455"
"Bershad","Bershad","48.3728","29.5325","Ukraine","UA","UKR","Vinnytska Oblast","minor","12795","1804698312"
"Syasstroy","Syasstroy","60.1500","32.5833","Russia","RU","RUS","Leningradskaya Oblast’","","12824","1643899356"
"Cheval","Cheval","28.1459","-82.5184","United States","US","USA","Florida","","12839","1840028977"
"M’dhilla","M'dhilla","34.2500","8.7500","Tunisia","TN","TUN","Gafsa","","12814","1788513102"
"Sahasmal","Sahasmal","26.2461","87.4983","India","IN","IND","Bihār","","11775","1356054732"
"Raismes","Raismes","50.3892","3.4858","France","FR","FRA","Hauts-de-France","","12468","1250527948"
"Chettikulam","Chettikulam","8.0943","77.5614","India","IN","IND","Tamil Nādu","","12438","1356381744"
"Fuldatal","Fuldatal","51.3484","9.5256","Germany","DE","DEU","Hesse","","12467","1276357406"
"Gohpur","Gohpur","26.8818","93.6156","India","IN","IND","Assam","","12433","1356335172"
"Ivaí","Ivai","-25.0108","-50.8589","Brazil","BR","BRA","Paraná","","12815","1076679254"
"Northlake","Northlake","41.9142","-87.9054","United States","US","USA","Illinois","","12836","1840008131"
"Jaroměř","Jaromer","50.3503","15.9183","Czechia","CZ","CZE","Královéhradecký Kraj","","12324","1203124128"
"Rafína","Rafina","38.0167","24.0000","Greece","GR","GRC","Attikí","minor","12168","1300557701"
"Salobreña","Salobrena","36.7467","-3.5869","Spain","ES","ESP","Andalusia","","12477","1724046465"
"Baghra","Baghra","25.5597","85.5841","India","IN","IND","Bihār","","11365","1356948344"
"Sebiston","Sebiston","38.2500","69.2833","Tajikistan","TJ","TJK","Khatlon","","12736","1762892686"
"Braselton","Braselton","34.1087","-83.8127","United States","US","USA","Georgia","","12833","1840015596"
"Holly Hill","Holly Hill","29.2442","-81.0465","United States","US","USA","Florida","","12833","1840015078"
"Chitauria","Chitauria","25.4869","87.5511","India","IN","IND","Bihār","","11447","1356086519"
"Canet-en-Roussillon","Canet-en-Roussillon","42.7056","3.0072","France","FR","FRA","Occitanie","","12284","1250710356"
"Attanūr","Attanur","11.5006","78.1356","India","IN","IND","Tamil Nādu","","12377","1356227228"
"Francofonte","Francofonte","37.2333","14.8833","Italy","IT","ITA","Sicilia","","12661","1380615654"
"Manjanādi","Manjanadi","12.8700","74.8400","India","IN","IND","Karnātaka","","10933","1356744782"
"Ấp Phú Hải","Ap Phu Hai","11.1667","108.5667","Vietnam","VN","VNM","Bình Thuận","","12624","1704618914"
"Canillá","Canilla","15.1671","-90.8481","Guatemala","GT","GTM","Quiché","minor","12724","1320925878"
"Bamhnī","Bamhni","22.4822","80.3716","India","IN","IND","Madhya Pradesh","","12687","1356499672"
"Truro","Truro","45.3647","-63.2800","Canada","CA","CAN","Nova Scotia","","12826","1124952899"
"Gyomaendrőd","Gyomaendrod","46.9361","20.8236","Hungary","HU","HUN","Békés","minor","12784","1348198279"
"Bozkurt","Bozkurt","37.8167","29.6167","Turkey","TR","TUR","Denizli","minor","12788","1792962015"
"Steinheim","Steinheim","51.8658","9.0944","Germany","DE","DEU","North Rhine-Westphalia","","12657","1276434002"
"Amos","Amos","48.5667","-78.1167","Canada","CA","CAN","Quebec","","12823","1124939649"
"Kafr Rūmā","Kafr Ruma","35.6342","36.6322","Syria","SY","SYR","Idlib","","12276","1760413960"
"Újfehértó","Ujfeherto","47.7989","21.6833","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","","12736","1348703777"
"General Las Heras","General Las Heras","-34.9333","-58.9500","Argentina","AR","ARG","Buenos Aires","minor","12799","1032789819"
"Playa Grande","Playa Grande","15.9833","-90.7667","Guatemala","GT","GTM","Quiché","minor","12710","1320692827"
"Barahkurwa","Barahkurwa","26.1460","86.9237","India","IN","IND","Bihār","","11433","1356119826"
"Karedu","Karedu","15.1833","80.0667","India","IN","IND","Andhra Pradesh","","12573","1356898210"
"Bānu Chhapra","Banu Chhapra","26.8098","84.5201","India","IN","IND","Bihār","","10821","1356436701"
"White House","White House","36.4648","-86.6666","United States","US","USA","Tennessee","","12820","1840015274"
"Thikriwāla","Thikriwala","30.4328","75.5261","India","IN","IND","Punjab","","12306","1356154407"
"Souakene","Souakene","35.1167","-5.9500","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12632","1504734379"
"Miraíma","Miraima","-3.5689","-39.9700","Brazil","BR","BRA","Ceará","","12800","1076684320"
"Kriftel","Kriftel","50.0828","8.4667","Germany","DE","DEU","Hesse","","11188","1276384053"
"Mamarappatti","Mamarappatti","11.4845","78.0374","India","IN","IND","Tamil Nādu","","11907","1356237818"
"Atri","Atri","42.5833","13.9833","Italy","IT","ITA","Abruzzo","","12680","1380722588"
"Dubak","Dubak","18.1914","78.6783","India","IN","IND","Andhra Pradesh","","12349","1356243033"
"Perehinske","Perehinske","48.8103","24.1819","Ukraine","UA","UKR","Ivano-Frankivska Oblast","","12762","1804617617"
"Nova Ponte","Nova Ponte","-19.1983","-47.7272","Brazil","BR","BRA","Minas Gerais","","12812","1076000777"
"Amha","Amha","26.0518","86.6906","India","IN","IND","Bihār","","11024","1356259953"
"Wawarsing","Wawarsing","41.7526","-74.4172","United States","US","USA","New York","","12814","1840058585"
"Gorokhovets","Gorokhovets","56.2000","42.7000","Russia","RU","RUS","Vladimirskaya Oblast’","","12799","1643628827"
"Jilava","Jilava","44.3328","26.0781","Romania","RO","ROU","Ilfov","","12223","1642206727"
"Bamber Bridge","Bamber Bridge","53.7281","-2.6606","United Kingdom","GB","GBR","Lancashire","","12126","1826794193"
"Kortemark","Kortemark","51.0286","3.0444","Belgium","BE","BEL","Flanders","","12584","1056621981"
"Buharkent","Buharkent","37.9617","28.7422","Turkey","TR","TUR","Aydın","minor","12688","1792505587"
"Ban Sop Tia","Ban Sop Tia","18.3895","98.6780","Thailand","TH","THA","Chiang Mai","","12739","1764003065"
"Pecica","Pecica","46.1700","21.0700","Romania","RO","ROU","Arad","","12762","1642561590"
"Krupka","Krupka","50.6846","13.8583","Czechia","CZ","CZE","Ústecký Kraj","","12547","1203244914"
"Si Mustapha","Si Mustapha","36.7247","3.6153","Algeria","DZ","DZA","Boumerdes","","12087","1012130143"
"Rānti","Ranti","26.3519","86.0932","India","IN","IND","Bihār","","11212","1356080533"
"Rosario del Tala","Rosario del Tala","-32.3000","-59.1333","Argentina","AR","ARG","Entre Ríos","minor","12801","1032485062"
"Teroual","Teroual","34.6667","-5.2667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12621","1504418024"
"Fonsorbes","Fonsorbes","43.5361","1.2311","France","FR","FRA","Occitanie","","12170","1250172848"
"Taldom","Taldom","56.7333","37.5333","Russia","RU","RUS","Moskovskaya Oblast’","","12777","1643222038"
"The Nation / La Nation","The Nation / La Nation","45.3500","-75.0333","Canada","CA","CAN","Ontario","","12808","1124001243"
"Sonosari","Sonosari","-7.8014","110.3644","Indonesia","ID","IDN","Yogyakarta","","11807","1360754867"
"Chandwā","Chandwa","23.6756","84.7401","India","IN","IND","Jhārkhand","","12580","1356313386"
"Raia","Raia","15.4969","73.8089","India","IN","IND","Goa","","11664","1356368579"
"Hvardiiske","Hvardiiske","45.1142","34.0142","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","12711","1804124377"
"Guymon","Guymon","36.6903","-101.4774","United States","US","USA","Oklahoma","","12804","1840020350"
"Coringa","Coringa","16.8000","82.2333","India","IN","IND","Andhra Pradesh","","12495","1356152808"
"Landeh","Landeh","30.9817","50.4233","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","","12772","1364576368"
"Pachchaimalaiyankottai","Pachchaimalaiyankottai","10.2736","77.8794","India","IN","IND","Tamil Nādu","","12129","1356245563"
"Karagwe","Karagwe","0.6769","30.1967","Uganda","UG","UGA","Kabarole","","12045","1800882770"
"Pichilemu","Pichilemu","-34.3919","-72.0139","Chile","CL","CHL","Libertador General Bernardo O’Higgins","minor","12776","1152498637"
"Harrison","Harrison","39.2584","-84.7868","United States","US","USA","Ohio","","12801","1840007381"
"Ban Muang Ngam","Ban Muang Ngam","7.3536","100.4898","Thailand","TH","THA","Songkhla","","12084","1764397075"
"Rompicherla","Rompicherla","16.2098","79.9090","India","IN","IND","Andhra Pradesh","","12475","1356682436"
"Oulad Ouchchih","Oulad Ouchchih","35.0939","-5.9454","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12608","1504064976"
"San Vicente","San Vicente","14.1061","122.8733","Philippines","PH","PHL","Camarines Norte","","12579","1608940211"
"Suzu","Suzu","37.4363","137.2605","Japan","JP","JPN","Ishikawa","","12746","1392003156"
"Medleri","Medleri","14.6667","75.7333","India","IN","IND","Karnātaka","","12442","1356007792"
"Mikashevichy","Mikashevichy","52.2203","27.4736","Belarus","BY","BLR","Brestskaya Voblasts’","","12771","1112846282"
"Zuvvaladinne","Zuvvaladinne","14.8080","80.0705","India","IN","IND","Andhra Pradesh","","12492","1356851672"
"Vohimarina","Vohimarina","-21.6400","46.8989","Madagascar","MG","MDG","Fianarantsoa","","12559","1450330004"
"San Anselmo","San Anselmo","37.9821","-122.5699","United States","US","USA","California","","12795","1840022520"
"Hedensted","Hedensted","55.7725","9.7017","Denmark","DK","DNK","Midtjylland","minor","12220","1208990844"
"Sankt Veit an der Glan","Sankt Veit an der Glan","46.7667","14.3603","Austria","AT","AUT","Kärnten","minor","12547","1040281881"
"Vinings","Vinings","33.8608","-84.4686","United States","US","USA","Georgia","","12793","1840013687"
"Oissel","Oissel","49.3419","1.0914","France","FR","FRA","Normandie","","12241","1250450712"
"Porecatu","Porecatu","-22.7558","-51.3789","Brazil","BR","BRA","Paraná","","12748","1076148890"
"Burj al ‘Arab","Burj al \`Arab","30.8489","29.6117","Egypt","EG","EGY","Al Iskandarīyah","","12625","1818114873"
"Terrabona","Terrabona","12.7311","-85.9650","Nicaragua","NI","NIC","Matagalpa","minor","12740","1558844965"
"Fontanafredda","Fontanafredda","45.9667","12.5667","Italy","IT","ITA","Friuli Venezia Giulia","","12521","1380424478"
"Sławno","Slawno","54.3583","16.6750","Poland","PL","POL","Zachodniopomorskie","minor","12028","1616283206"
"Ashibetsu","Ashibetsu","43.5182","142.1896","Japan","JP","JPN","Hokkaidō","","12775","1392076834"
"Sarahandrano","Sarahandrano","-14.7833","50.0167","Madagascar","MG","MDG","Antsiranana","","12758","1450771441"
"Novi Pazar","Novi Pazar","43.3500","27.2000","Bulgaria","BG","BGR","Shumen","","12742","1100036020"
"Utehia","Utehia","26.4800","89.7300","India","IN","IND","Uttar Pradesh","","12022","1356895004"
"Ikhlāspur","Ikhlaspur","25.0589","83.6083","India","IN","IND","Bihār","","11301","1356049165"
"Bad Urach","Bad Urach","48.4931","9.3986","Germany","DE","DEU","Baden-Württemberg","","12561","1276416917"
"San Miguel Panán","San Miguel Panan","14.5333","-91.3667","Guatemala","GT","GTM","Suchitepéquez","minor","12475","1320095749"
"Gaimersheim","Gaimersheim","48.8167","11.3667","Germany","DE","DEU","Bavaria","","12251","1276719449"
"Vidauban","Vidauban","43.4272","6.4319","France","FR","FRA","Provence-Alpes-Côte d’Azur","","12616","1250930632"
"Antanandava","Antanandava","-19.0833","47.9500","Madagascar","MG","MDG","Toamasina","","12755","1450889393"
"Kishunpur","Kishunpur","25.3272","87.7173","India","IN","IND","Bihār","","11237","1356155445"
"Puliyampatti","Puliyampatti","11.6653","77.8041","India","IN","IND","Tamil Nādu","","11878","1356168414"
"Le Haillan","Le Haillan","44.8717","-0.6769","France","FR","FRA","Nouvelle-Aquitaine","","11539","1250893807"
"Greenville","Greenville","40.1043","-84.6209","United States","US","USA","Ohio","","12785","1840008366"
"Fostoria","Fostoria","41.1600","-83.4121","United States","US","USA","Ohio","","12785","1840000820"
"Kalyazin","Kalyazin","57.2333","37.8833","Russia","RU","RUS","Tverskaya Oblast’","","12770","1643298637"
"Laamarna","Laamarna","31.8944","-8.9842","Morocco","MA","MAR","Marrakech-Safi","","12717","1504140780"
"Santa Ana de Yacuma","Santa Ana de Yacuma","-13.7444","-65.4269","Bolivia","BO","BOL","El Beni","","12783","1068925682"
"Sangtŭda","Sangtuda","38.0333","69.0833","Tajikistan","TJ","TJK","Khatlon","","12686","1762543236"
"Simeria","Simeria","45.8500","23.0100","Romania","RO","ROU","Hunedoara","","12556","1642409253"
"São Sebastião do Uatumã","Sao Sebastiao do Uatuma","-2.5719","-57.8708","Brazil","BR","BRA","Amazonas","","12781","1076957010"
"Nacajuca","Nacajuca","18.1692","-93.0197","Mexico","MX","MEX","Tabasco","minor","10956","1484698021"
"Santa Fe","Santa Fe","29.3889","-95.1003","United States","US","USA","Texas","","12782","1840022233"
"San Francisco de Mostazal","San Francisco de Mostazal","-33.9799","-70.7122","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","12719","1152382411"
"Stupava","Stupava","48.2747","17.0317","Slovakia","SK","SVK","Bratislava","","12595","1703216738"
"Kanra","Kanra","36.2430","138.9218","Japan","JP","JPN","Gunma","","12567","1392003131"
"Tacaimbó","Tacaimbo","-8.3158","-36.2928","Brazil","BR","BRA","Pernambuco","","12725","1076203896"
"Malmédy","Malmedy","50.4167","6.0167","Belgium","BE","BEL","Wallonia","","12654","1056228315"
"Diari","Diari","9.8452","-0.8655","Ghana","GH","GHA","Northern","","12666","1288997321"
"Valverde del Camino","Valverde del Camino","37.5667","-6.7500","Spain","ES","ESP","Andalusia","","12721","1724355645"
"Orangeburg","Orangeburg","33.4928","-80.8671","United States","US","USA","South Carolina","","12779","1840014812"
"Rognac","Rognac","43.4878","5.2322","France","FR","FRA","Provence-Alpes-Côte d’Azur","","12086","1250737826"
"Wadersloh","Wadersloh","51.7386","8.2514","Germany","DE","DEU","North Rhine-Westphalia","","12669","1276176692"
"Novo Lino","Novo Lino","-8.9150","-35.6469","Brazil","BR","BRA","Alagoas","","12764","1076744203"
"Kłobuck","Klobuck","50.9167","18.9333","Poland","PL","POL","Śląskie","minor","12513","1616782641"
"Puduvayal","Puduvayal","10.1034","78.8462","India","IN","IND","Tamil Nādu","","12216","1356336025"
"Medfield","Medfield","42.1848","-71.3050","United States","US","USA","Massachusetts","","12775","1840053552"
"Enamadala","Enamadala","16.2281","80.3306","India","IN","IND","Andhra Pradesh","","11897","1356945614"
"Suamico","Suamico","44.6354","-88.0683","United States","US","USA","Wisconsin","","12774","1840000309"
"Fort Lewis","Fort Lewis","47.0955","-122.5674","United States","US","USA","Washington","","12773","1840073933"
"Waconia","Waconia","44.8422","-93.7896","United States","US","USA","Minnesota","","12773","1840010008"
"Oakland","Oakland","41.0313","-74.2408","United States","US","USA","New Jersey","","12773","1840000912"
"Santiago Amoltepec","Santiago Amoltepec","16.6167","-97.5000","Mexico","MX","MEX","Oaxaca","","12683","1484936827"
"Samahuta","Samahuta","25.8542","84.6614","India","IN","IND","Bihār","","11266","1356002044"
"Majanji","Majanji","0.2408","33.9914","Uganda","UG","UGA","Busia","","11274","1800028555"
"St. Marys","St. Marys","41.4574","-78.5342","United States","US","USA","Pennsylvania","","12771","1840000618"
"Iferhounene","Iferhounene","36.5338","4.3701","Algeria","DZ","DZA","Tizi Ouzou","","12460","1012745524"
"Laukaria","Laukaria","26.7184","84.4242","India","IN","IND","Bihār","","11202","1356037772"
"Lymm","Lymm","53.3834","-2.4750","United Kingdom","GB","GBR","Cheshire East","","12700","1826822160"
"Knowsley","Knowsley","53.4498","-2.8501","United Kingdom","GB","GBR","Knowsley","","11343","1826568900"
"Sāvalgi","Savalgi","16.6710","75.3515","India","IN","IND","Karnātaka","","12506","1356087192"
"Rantoul","Rantoul","40.3031","-88.1549","United States","US","USA","Illinois","","12766","1840012227"
"Los Hidalgos","Los Hidalgos","19.7300","-71.0300","Dominican Republic","DO","DOM","","","12639","1214268175"
"Oneonta","Oneonta","42.4551","-75.0666","United States","US","USA","New York","","12764","1840000405"
"Brock Hall","Brock Hall","38.8604","-76.7459","United States","US","USA","Maryland","","12764","1840024541"
"Zag","Zag","28.0167","-9.3333","Morocco","MA","MAR","Guelmim-Oued Noun","","12763","1504746689"
"Tepetzintla","Tepetzintla","19.9667","-97.8333","Mexico","MX","MEX","Puebla","minor","11622","1484778629"
"Hirschaid","Hirschaid","49.8167","10.9833","Germany","DE","DEU","Bavaria","","12458","1276140159"
"Joanópolis","Joanopolis","-22.9303","-46.2756","Brazil","BR","BRA","São Paulo","","12725","1076442097"
"Dalby","Dalby","-27.1813","151.2661","Australia","AU","AUS","Queensland","","12719","1036053707"
"Sant’Agata di Militello","Sant'Agata di Militello","38.0680","14.6333","Italy","IT","ITA","Sicilia","","12393","1380653240"
"Erumaippatti","Erumaippatti","11.1467","78.2900","India","IN","IND","Tamil Nādu","","12277","1356443438"
"Ingersoll","Ingersoll","43.0392","-80.8836","Canada","CA","CAN","Ontario","","12757","1124716784"
"Kumari","Kumari","25.6564","87.7112","India","IN","IND","Bihār","","11425","1356109646"
"Kyōtamba","Kyotamba","35.1699","135.4194","Japan","JP","JPN","Kyōto","","12714","1392003487"
"Āvadattūr","Avadattur","11.7014","77.8638","India","IN","IND","Tamil Nādu","","11848","1356168388"
"Eufaula","Eufaula","31.9102","-85.1505","United States","US","USA","Alabama","","12753","1840001685"
"Parasurāmpūr","Parasurampur","14.3200","76.6400","India","IN","IND","Karnātaka","","12551","1356986475"
"Lumberton","Lumberton","39.9569","-74.8036","United States","US","USA","New Jersey","","12752","1840081622"
"Rio Vermelho","Rio Vermelho","-18.2939","-43.0089","Brazil","BR","BRA","Minas Gerais","","12739","1076636906"
"Barka Gaon","Barka Gaon","26.1440","84.6598","India","IN","IND","Bihār","","11184","1356508796"
"Dināra","Dinara","25.2500","84.0667","India","IN","IND","Bihār","","12035","1356483718"
"Olintla","Olintla","20.1000","-97.6833","Mexico","MX","MEX","Puebla","","11517","1484975870"
"Muswellbrook","Muswellbrook","-32.2654","150.8885","Australia","AU","AUS","New South Wales","","12075","1036253543"
"Senduriā","Senduria","26.7469","84.6754","India","IN","IND","Bihār","","11411","1356107333"
"Orange City","Orange City","28.9348","-81.2881","United States","US","USA","Florida","","12748","1840015075"
"San Salvador","San Salvador","-31.6167","-58.5000","Argentina","AR","ARG","Entre Ríos","minor","12733","1032064102"
"Saint David’s","Saint David's","12.0444","-61.6806","Grenada","GD","GRD","Saint David","","12486","1308183777"
"Isāpur","Isapur","25.4122","86.9893","India","IN","IND","Bihār","","11642","1356083007"
"Pont-du-Château","Pont-du-Chateau","45.7983","3.2483","France","FR","FRA","Auvergne-Rhône-Alpes","","12182","1250866779"
"Oxford","Oxford","41.4313","-73.1350","United States","US","USA","Connecticut","","12744","1840035533"
"Brunsbüttel","Brunsbuttel","53.8964","9.1386","Germany","DE","DEU","Schleswig-Holstein","","12554","1276581579"
"Lamballe","Lamballe","48.4686","-2.5178","France","FR","FRA","Bretagne","","12579","1250481777"
"Pīr Maker","Pir Maker","25.9622","85.0257","India","IN","IND","Bihār","","11377","1356019955"
"Warwick","Warwick","-28.2152","152.0352","Australia","AU","AUS","Queensland","","12222","1036412726"
"Shirataka","Shirataka","38.1831","140.0986","Japan","JP","JPN","Yamagata","","12663","1392003409"
"Cheney","Cheney","47.4901","-117.5790","United States","US","USA","Washington","","12743","1840018398"
"Bad Abbach","Bad Abbach","48.9333","12.0500","Germany","DE","DEU","Bavaria","","12515","1276008199"
"Steinheim am der Murr","Steinheim am der Murr","48.9667","9.2833","Germany","DE","DEU","Baden-Württemberg","","12220","1276793728"
"Alpen","Alpen","51.5750","6.5125","Germany","DE","DEU","North Rhine-Westphalia","","12528","1276474767"
"Yemva","Yemva","62.6000","50.8667","Russia","RU","RUS","Komi","","12736","1643717483"
"Palos de la Frontera","Palos de la Frontera","37.2283","-6.8944","Spain","ES","ESP","Andalusia","","12483","1724003384"
"Baker","Baker","30.5832","-91.1582","United States","US","USA","Louisiana","","12736","1840013939"
"D'Iberville","D'Iberville","30.4709","-88.9011","United States","US","USA","Mississippi","","12736","1840015023"
"Snezhnogorsk","Snezhnogorsk","69.2000","33.2333","Russia","RU","RUS","Murmanskaya Oblast’","","12642","1643272014"
"Beniel","Beniel","38.0464","-1.0014","Spain","ES","ESP","Murcia","","11578","1724289918"
"Villa Aldama","Villa Aldama","19.6506","-97.2208","Mexico","MX","MEX","Veracruz","minor","12492","1484465521"
"Qiaotouba","Qiaotouba","33.8116","104.8493","China","CN","CHN","Gansu","","12649","1156138572"
"Port Royal","Port Royal","32.3557","-80.7029","United States","US","USA","South Carolina","","12734","1840016975"
"Hattem","Hattem","52.4667","6.0667","Netherlands","NL","NLD","Gelderland","minor","12228","1528928512"
"Kuçovë","Kucove","40.8039","19.9144","Albania","AL","ALB","Berat","","12654","1008520632"
"Sinalunga","Sinalunga","43.2167","11.7333","Italy","IT","ITA","Tuscany","","12573","1380874703"
"Río Segundo","Rio Segundo","10.0013","-84.1937","Costa Rica","CR","CRI","Alajuela","","10794","1188990744"
"Ban Bang Non","Ban Bang Non","9.9923","98.6507","Thailand","TH","THA","Ranong","","12466","1764844910"
"Chakai","Chakai","26.0884","87.6154","India","IN","IND","Bihār","","11700","1356309912"
"Altavilla Vicentina","Altavilla Vicentina","45.5167","11.4500","Italy","IT","ITA","Veneto","","12013","1380330182"
"Sason","Sason","38.3803","41.3953","Turkey","TR","TUR","Batman","minor","12696","1792334757"
"Pidigan","Pidigan","17.5703","120.5893","Philippines","PH","PHL","Abra","","12475","1608879662"
"Dabat","Dabat","12.9842","37.7650","Ethiopia","ET","ETH","Āmara","","12574","1231841967"
"Librazhd-Qendër","Librazhd-Qender","41.1969","20.3356","Albania","AL","ALB","Elbasan","","12691","1008639331"
"Jefferson","Jefferson","34.1373","-83.6021","United States","US","USA","Georgia","","12727","1840014723"
"Panzgām","Panzgam","34.4840","74.0753","India","IN","IND","Jammu and Kashmīr","","12141","1356638545"
"Paullo","Paullo","45.4167","9.4000","Italy","IT","ITA","Lombardy","","11429","1380956387"
"Gundugolanu","Gundugolanu","16.7833","81.2333","India","IN","IND","Andhra Pradesh","","11895","1356974157"
"Betton","Betton","48.1825","-1.6439","France","FR","FRA","Bretagne","","12265","1250996360"
"Saint-Germain-lès-Arpajon","Saint-Germain-les-Arpajon","48.5953","2.2556","France","FR","FRA","Île-de-France","","10983","1250483663"
"Saint-Estève","Saint-Esteve","42.7133","2.8419","France","FR","FRA","Occitanie","","11719","1250864378"
"Brus Laguna","Brus Laguna","15.7500","-84.4833","Honduras","HN","HND","Gracias a Dios","","12720","1340003428"
"Mountain Home","Mountain Home","36.3351","-92.3840","United States","US","USA","Arkansas","","12722","1840014452"
"Vrchlabí","Vrchlabi","50.6270","15.6095","Czechia","CZ","CZE","Královéhradecký Kraj","","12289","1203953730"
"Dongyuancun","Dongyuancun","28.3190","120.2321","China","CN","CHN","Zhejiang","","12572","1156375908"
"Nemili","Nemili","12.9783","79.6172","India","IN","IND","Tamil Nādu","","12166","1356646512"
"Anao","Anao","15.7304","120.6264","Philippines","PH","PHL","","","12208","1608758954"
"Sagopshi","Sagopshi","43.4847","44.5881","Russia","RU","RUS","Ingushetiya","","12614","1643924057"
"Athens","Athens","32.2041","-95.8321","United States","US","USA","Texas","","12719","1840019500"
"Ələt","Alat","39.9411","49.4061","Azerbaijan","AZ","AZE","Bakı","","12600","1031947328"
"Blaricum","Blaricum","52.2667","5.2500","Netherlands","NL","NLD","Noord-Holland","minor","11952","1528676942"
"Itāmāti","Itamati","20.1333","85.1500","India","IN","IND","Odisha","","11835","1356035819"
"Made","Made","51.6764","4.7925","Netherlands","NL","NLD","Noord-Brabant","minor","12470","1528216720"
"Ounagha","Ounagha","31.5333","-9.5500","Morocco","MA","MAR","Marrakech-Safi","","12461","1504589161"
"Spondon","Spondon","52.9200","-1.4010","United Kingdom","GB","GBR","Derby","","12377","1826000037"
"Kāttukkottai","Kattukkottai","11.6026","78.6698","India","IN","IND","Tamil Nādu","","12324","1356243353"
"Basbiti","Basbiti","26.0939","86.5289","India","IN","IND","Bihār","","10923","1356257719"
"Ladenburg","Ladenburg","49.4667","8.6167","Germany","DE","DEU","Baden-Württemberg","","12076","1276610621"
"Morazán","Morazan","14.9322","-90.1433","Guatemala","GT","GTM","El Progreso","minor","12672","1320386903"
"Vejer de la Frontera","Vejer de la Frontera","36.2500","-5.9667","Spain","ES","ESP","Andalusia","","12656","1724776060"
"Ahermoumou","Ahermoumou","33.8184","-4.4106","Morocco","MA","MAR","Fès-Meknès","","12654","1504332158"
"Kallupatti","Kallupatti","9.7167","77.8667","India","IN","IND","Tamil Nādu","","12429","1356893230"
"Zofingen","Zofingen","47.2833","7.9333","Switzerland","CH","CHE","Aargau","","11655","1756295445"
"Chennampatti","Chennampatti","11.7011","77.6785","India","IN","IND","Tamil Nādu","","12380","1356164750"
"Pandalkudi","Pandalkudi","9.3947","78.1051","India","IN","IND","Tamil Nādu","","12306","1356715413"
"Derventa","Derventa","44.9800","17.9100","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","12680","1070137362"
"Gundelfingen","Gundelfingen","48.0425","7.8657","Germany","DE","DEU","Baden-Württemberg","","11872","1276046048"
"Khasanya","Khasanya","43.4333","43.5750","Russia","RU","RUS","Kabardino-Balkariya","","10829","1643788659"
"Grand Baie","Grand Baie","-20.0184","57.5802","Mauritius","MU","MUS","Rivière du Rempart","","11910","1480922858"
"Lalmatie","Lalmatie","-20.0184","57.5802","Mauritius","MU","MUS","Flacq","","11910","1480729311"
"Sulz am Neckar","Sulz am Neckar","48.3628","8.6317","Germany","DE","DEU","Baden-Württemberg","","12560","1276239131"
"Graben-Neudorf","Graben-Neudorf","49.1592","8.4894","Germany","DE","DEU","Baden-Württemberg","","12277","1276955602"
"Hobro","Hobro","56.6333","9.8000","Denmark","DK","DNK","Nordjylland","minor","12071","1208783800"
"Chok Chai","Chok Chai","14.7333","102.1667","Thailand","TH","THA","Nakhon Ratchasima","minor","12568","1764008665"
"Vardenis","Vardenis","40.1806","45.7200","Armenia","AM","ARM","Geghark’unik’","","12363","1051776950"
"Bayona","Bayona","42.1178","-8.8506","Spain","ES","ESP","Galicia","","12349","1724188693"
"Grünwald","Grunwald","48.0483","11.5317","Germany","DE","DEU","Bavaria","","11287","1276645609"
"Kānkon","Kankon","15.0109","74.0363","India","IN","IND","Goa","","12434","1356134641"
"Makuyu","Makuyu","-0.9000","37.1833","Kenya","KE","KEN","Murang’a","","12541","1404000094"
"Challapata","Challapata","-18.9000","-66.7667","Bolivia","BO","BOL","Oruro","","12684","1068352309"
"Fernandina Beach","Fernandina Beach","30.6571","-81.4511","United States","US","USA","Florida","","12697","1840013929"
"Lakeland South","Lakeland South","47.2786","-122.2826","United States","US","USA","Washington","","12696","1840074270"
"Beaconsfield","Beaconsfield","51.6009","-0.6347","United Kingdom","GB","GBR","Buckinghamshire","","12081","1826964711"
"Winterberg","Winterberg","51.2000","8.5167","Germany","DE","DEU","North Rhine-Westphalia","","12611","1276682144"
"Olgiate Comasco","Olgiate Comasco","45.7833","8.9667","Italy","IT","ITA","Lombardy","","11633","1380841304"
"San Miguel de Salcedo","San Miguel de Salcedo","-1.0500","-78.5833","Ecuador","EC","ECU","Cotopaxi","","12488","1218435695"
"Leibnitz","Leibnitz","46.7831","15.5450","Austria","AT","AUT","Steiermark","minor","12176","1040580132"
"Olagadam","Olagadam","11.5656","77.6428","India","IN","IND","Tamil Nādu","","12368","1356164744"
"Horndean","Horndean","50.9136","-0.9961","United Kingdom","GB","GBR","Hampshire","","12639","1826591617"
"Roding","Roding","49.2000","12.5167","Germany","DE","DEU","Bavaria","","12587","1276027598"
"Fulton","Fulton","38.8551","-91.9510","United States","US","USA","Missouri","","12691","1840008560"
"Pieve di Soligo","Pieve di Soligo","45.8833","12.1667","Italy","IT","ITA","Veneto","","12057","1380766234"
"Ozatlán","Ozatlan","13.3833","-88.5000","El Salvador","SV","SLV","Usulután","","12443","1222834795"
"Feuchtwangen","Feuchtwangen","49.1667","10.3167","Germany","DE","DEU","Bavaria","","12599","1276691065"
"Aït Youssef Ou Ali","Ait Youssef Ou Ali","31.9833","-5.7833","Morocco","MA","MAR","Drâa-Tafilalet","","12673","1504087117"
"Lakeside","Lakeside","37.6132","-77.4768","United States","US","USA","Virginia","","12690","1840006392"
"Los Santos","Los Santos","6.9167","-73.0833","Colombia","CO","COL","Santander","minor","12648","1170390233"
"Dardenne Prairie","Dardenne Prairie","38.7565","-90.7319","United States","US","USA","Missouri","","12689","1840007428"
"Wickliffe","Wickliffe","41.6072","-81.4690","United States","US","USA","Ohio","","12688","1840000546"
"Arroio do Tigre","Arroio do Tigre","-29.3328","-53.0928","Brazil","BR","BRA","Rio Grande do Sul","","12648","1076835084"
"Ban Tom Klang","Ban Tom Klang","19.1961","99.8378","Thailand","TH","THA","Phayao","","12586","1764492879"
"Moncks Corner","Moncks Corner","33.1730","-80.0107","United States","US","USA","South Carolina","","12685","1840016863"
"Ataléia","Ataleia","-18.0439","-41.1100","Brazil","BR","BRA","Minas Gerais","","12678","1076641631"
"Chettipulam","Chettipulam","10.4743","79.7546","India","IN","IND","Tamil Nādu","","12306","1356200769"
"Zawiat Moulay Brahim","Zawiat Moulay Brahim","31.2858","-7.9656","Morocco","MA","MAR","Marrakech-Safi","","11813","1504818335"
"Florence","Florence","40.0977","-74.7886","United States","US","USA","New Jersey","","12684","1840081638"
"Lienz","Lienz","46.8297","12.7697","Austria","AT","AUT","Tirol","minor","11935","1040923905"
"Alampur","Alampur","26.0251","78.7970","India","IN","IND","Madhya Pradesh","","12342","1356726047"
"Chāmarru","Chamarru","16.6500","80.1333","India","IN","IND","Andhra Pradesh","","12232","1356227278"
"Lexington Park","Lexington Park","38.2528","-76.4424","United States","US","USA","Maryland","","12681","1840006231"
"Mountain Park","Mountain Park","33.8458","-84.1314","United States","US","USA","Georgia","","12680","1840143063"
"Blaubeuren","Blaubeuren","48.4119","9.7850","Germany","DE","DEU","Baden-Württemberg","","12521","1276939647"
"Orzinuovi","Orzinuovi","45.4000","9.9333","Italy","IT","ITA","Lombardy","","12419","1380910228"
"Kamianka-Dniprovska","Kamianka-Dniprovska","47.4922","34.4106","Ukraine","UA","UKR","Zaporizka Oblast","minor","12638","1804716389"
"Darfield","Darfield","53.5380","-1.3812","United Kingdom","GB","GBR","Barnsley","","10685","1826920550"
"Grenada","Grenada","33.7816","-89.8130","United States","US","USA","Mississippi","","12675","1840013717"
"Pionerskiy","Pionerskiy","54.9517","20.2333","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","11312","1643251057"
"Kasaishi","Kasaishi","37.2528","140.3434","Japan","JP","JPN","Fukushima","","12282","1392964767"
"Bni Drar","Bni Drar","34.8578","-1.9928","Morocco","MA","MAR","Oriental","","10934","1504593239"
"Yenipazar","Yenipazar","37.8269","28.1975","Turkey","TR","TUR","Aydın","minor","12608","1792212054"
"Lakri","Lakri","26.2323","84.6933","India","IN","IND","Bihār","","11647","1356122530"
"Sarjāpur","Sarjapur","12.8600","77.7860","India","IN","IND","Karnātaka","","11378","1356750832"
"Concepción Las Minas","Concepcion Las Minas","14.5167","-89.4500","Guatemala","GT","GTM","Chiquimula","minor","12615","1320071349"
"Ronchi dei Legionari","Ronchi dei Legionari","45.8333","13.5000","Italy","IT","ITA","Friuli Venezia Giulia","","11971","1380970887"
"Shilka","Shilka","51.8500","116.0333","Russia","RU","RUS","Zabaykal’skiy Kray","","12663","1643938937"
"Capul","Capul","12.4230","124.1820","Philippines","PH","PHL","Northern Samar","","12323","1608632874"
"Kantang","Kantang","7.4067","99.5150","Thailand","TH","THA","Trang","minor","12533","1764372106"
"Cidreira","Cidreira","-30.1608","-50.2339","Brazil","BR","BRA","Rio Grande do Sul","","12668","1076392597"
"Chaval","Chaval","-3.0339","-41.2439","Brazil","BR","BRA","Ceará","","12615","1076712591"
"Greetland","Greetland","53.6869","-1.8714","United Kingdom","GB","GBR","Calderdale","","11389","1826686743"
"Buenos Aires","Buenos Aires","-7.7258","-35.3269","Brazil","BR","BRA","Pernambuco","","12537","1076935774"
"Paradise Valley","Paradise Valley","33.5434","-111.9595","United States","US","USA","Arizona","","12666","1840022913"
"Karrāpur","Karrapur","23.9489","78.8658","India","IN","IND","Madhya Pradesh","","12227","1356374401"
"Sahsaul","Sahsaul","25.7024","86.7758","India","IN","IND","Bihār","","11245","1356955844"
"Heddesheim","Heddesheim","49.5053","8.6033","Germany","DE","DEU","Baden-Württemberg","","11859","1276190557"
"Eski Arab","Eski Arab","40.3686","71.4231","Uzbekistan","UZ","UZB","Farg‘ona","","12600","1860690514"
"Amherst","Amherst","41.4022","-82.2303","United States","US","USA","Ohio","","12665","1840000640"
"Tarwāra","Tarwara","26.2007","84.4874","India","IN","IND","Bihār","","10896","1356286916"
"Drongen","Drongen","51.0500","3.6667","Belgium","BE","BEL","Flanders","","12218","1056322707"
"Mèze","Meze","43.4267","3.6053","France","FR","FRA","Occitanie","","12307","1250412150"
"Shirguppi","Shirguppi","16.6187","74.7091","India","IN","IND","Mahārāshtra","","12129","1356097723"
"Nāttarampalli","Nattarampalli","12.5920","78.5116","India","IN","IND","Tamil Nādu","","12056","1356088086"
"Devāpur","Devapur","19.0443","79.3499","India","IN","IND","Andhra Pradesh","","12549","1356346019"
"Capitán Bado","Capitan Bado","-23.2600","-55.5400","Paraguay","PY","PRY","Amambay","","11319","1600353180"
"Patarrá","Patarra","9.8637","-84.0248","Costa Rica","CR","CRI","San José","","11921","1188875771"
"Penal","Penal","10.1667","-61.4667","Trinidad and Tobago","TT","TTO","Penal/Debe","","12281","1780976981"
"Winkler","Winkler","49.1817","-97.9397","Canada","CA","CAN","Manitoba","","12660","1124205424"
"Balpyq Bī","Balpyq Bi","44.8947","78.2083","Kazakhstan","KZ","KAZ","","","12654","1398089749"
"Loiyo","Loiyo","23.7928","85.6320","India","IN","IND","Jhārkhand","","12198","1356181233"
"Trophy Club","Trophy Club","33.0040","-97.1899","United States","US","USA","Texas","","12659","1840022993"
"New Germany","New Germany","-29.8000","30.8833","South Africa","ZA","ZAF","KwaZulu-Natal","","12592","1710344867"
"Verde Village","Verde Village","34.7119","-111.9941","United States","US","USA","Arizona","","12658","1840017843"
"Winnetka","Winnetka","42.1064","-87.7421","United States","US","USA","Illinois","","12658","1840011317"
"Antilla","Antilla","20.8411","-75.7206","Cuba","CU","CUB","Holguín","minor","12542","1192885476"
"Avigliana","Avigliana","45.0779","7.3984","Italy","IT","ITA","Piedmont","","12599","1380109385"
"Gurramkonda","Gurramkonda","13.7833","78.5833","India","IN","IND","Andhra Pradesh","","12477","1356284759"
"Florham Park","Florham Park","40.7773","-74.3953","United States","US","USA","New Jersey","","12655","1840003582"
"Wetaskiwin","Wetaskiwin","52.9694","-113.3769","Canada","CA","CAN","Alberta","","12655","1124492484"
"Mende","Mende","44.5183","3.5006","France","FR","FRA","Occitanie","minor","12318","1250206138"
"Shchastia","Shchastia","48.7381","39.2311","Ukraine","UA","UKR","Luhanska Oblast","","12629","1804786776"
"Villafranca de los Barros","Villafranca de los Barros","38.5667","-6.3333","Spain","ES","ESP","Extremadura","","12534","1724272394"
"Cambuquira","Cambuquira","-21.8583","-45.2911","Brazil","BR","BRA","Minas Gerais","","12602","1076171499"
"El Adjiba","El Adjiba","36.3333","4.1500","Algeria","DZ","DZA","Bouira","","12486","1012383350"
"Dunkirk","Dunkirk","42.4801","-79.3324","United States","US","USA","New York","","12651","1840000445"
"Emiliano Zapata","Emiliano Zapata","19.6500","-98.5500","Mexico","MX","MEX","Hidalgo","minor","12309","1484053015"
"Chauny","Chauny","49.6156","3.2192","France","FR","FRA","Hauts-de-France","","11773","1250000360"
"Fatehpur Shāhbāz","Fatehpur Shahbaz","25.5698","85.3437","India","IN","IND","Bihār","","11480","1356045506"
"Galaosiyo Shahri","Galaosiyo Shahri","39.8500","64.4500","Uzbekistan","UZ","UZB","Buxoro","minor","12600","1860860569"
"Nāysar","Naysar","35.3267","47.0686","Iran","IR","IRN","Kordestān","","12480","1364980803"
"Erkner","Erkner","52.4167","13.7500","Germany","DE","DEU","Brandenburg","","11935","1276566171"
"Māndu","Mandu","23.7946","85.4687","India","IN","IND","Jhārkhand","","12128","1356054229"
"Viotá","Viota","4.4381","-74.5239","Colombia","CO","COL","Cundinamarca","minor","12589","1170693383"
"Anzola dell’Emilia","Anzola dell'Emilia","44.5472","11.1956","Italy","IT","ITA","Emilia-Romagna","","12310","1380103166"
"Baykalsk","Baykalsk","51.5167","104.1333","Russia","RU","RUS","Irkutskaya Oblast’","","12629","1643186912"
"Koyulhisar","Koyulhisar","40.3053","37.8306","Turkey","TR","TUR","Sivas","minor","12637","1792512695"
"Pennsville","Pennsville","39.6266","-75.5089","United States","US","USA","New Jersey","","12642","1840081664"
"Chudamani","Chudamani","21.1379","86.7794","India","IN","IND","Odisha","","12023","1356059558"
"Newington Forest","Newington Forest","38.7370","-77.2339","United States","US","USA","Virginia","","12641","1840041848"
"Rielasingen-Worblingen","Rielasingen-Worblingen","47.7347","8.8401","Germany","DE","DEU","Baden-Württemberg","","11995","1276133236"
"Saltcoats","Saltcoats","55.6352","-4.7896","United Kingdom","GB","GBR","North Ayrshire","","12640","1826618463"
"Panj","Panj","37.2353","69.0972","Tajikistan","TJ","TJK","Khatlon","minor","12500","1762607780"
"Ubud","Ubud","-8.5069","115.2624","Indonesia","ID","IDN","Bali","","10873","1360332409"
"Bishunpur","Bishunpur","26.1561","87.2711","India","IN","IND","Bihār","","11353","1356588689"
"Artesia","Artesia","32.8497","-104.4268","United States","US","USA","New Mexico","","12637","1840019443"
"Tagoloan","Tagoloan","8.1092","124.4392","Philippines","PH","PHL","Lanao del Sur","","12602","1608551661"
"Twistringen","Twistringen","52.8000","8.6500","Germany","DE","DEU","Lower Saxony","","12527","1276091874"
"Yenkuvārigūdem","Yenkuvarigudem","16.9989","81.5061","India","IN","IND","Andhra Pradesh","","12277","1356153760"
"Zaladanki","Zaladanki","14.8843","79.9125","India","IN","IND","Andhra Pradesh","","12471","1356884236"
"Santa Bárbara de Pinto","Santa Barbara de Pinto","9.4333","-74.7000","Colombia","CO","COL","Magdalena","minor","12610","1170483777"
"Kawamata","Kawamata","37.6650","140.5983","Japan","JP","JPN","Fukushima","","12537","1392002612"
"Miranorte","Miranorte","-9.5289","-48.5900","Brazil","BR","BRA","Tocantins","","12623","1076578531"
"Kalajoki","Kalajoki","64.2667","23.9500","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","12621","1246746115"
"Xiaping","Xiaping","33.4047","106.0525","China","CN","CHN","Shaanxi","","12496","1156533586"
"Pannaikkādu","Pannaikkadu","10.2761","77.6272","India","IN","IND","Tamil Nādu","","12504","1356205007"
"Pajo","Pajo","-8.5167","120.3333","Indonesia","ID","IDN","Nusa Tenggara Timur","","12545","1360777434"
"Virālippatti","Viralippatti","10.1081","77.7257","India","IN","IND","Tamil Nādu","","11960","1356227809"
"Beaumont","Beaumont","18.4833","-73.9667","Haiti","HT","HTI","Grand’Anse","","12486","1332335707"
"Kaujalgi","Kaujalgi","16.1400","74.8100","India","IN","IND","Karnātaka","","12179","1356573615"
"Rochefort","Rochefort","50.1667","5.2167","Belgium","BE","BEL","Wallonia","","12554","1056032806"
"Junín de los Andes","Junin de los Andes","-39.9167","-71.0667","Argentina","AR","ARG","Neuquén","minor","12621","1032993092"
"Le Pont-de-Claix","Le Pont-de-Claix","45.1231","5.6981","France","FR","FRA","Auvergne-Rhône-Alpes","","10716","1250173049"
"Zonnebeke","Zonnebeke","50.8667","2.9833","Belgium","BE","BEL","Flanders","","12445","1056453121"
"Goldenrod","Goldenrod","28.6114","-81.2916","United States","US","USA","Florida","","12629","1840014080"
"Godohou","Godohou","7.0333","1.7833","Benin","BJ","BEN","Couffo","","12259","1204689512"
"Ulster","Ulster","41.9699","-74.0041","United States","US","USA","New York","","12628","1840087981"
"Monte Compatri","Monte Compatri","41.8081","12.7372","Italy","IT","ITA","Lazio","","12134","1380232172"
"Jaciara","Jaciara","-15.9650","-54.9678","Brazil","BR","BRA","Mato Grosso","","12620","1076001392"
"Adré","Adre","13.4667","22.2000","Chad","TD","TCD","Ouaddaï","","12571","1148372250"
"Lana","Lana","46.6160","11.1449","Italy","IT","ITA","Trentino-Alto Adige","","12286","1380125406"
"Mahavanona","Mahavanona","-12.4500","49.3667","Madagascar","MG","MDG","Antsiranana","","12075","1450051630"
"Hullatti","Hullatti","11.4771","76.6960","India","IN","IND","Tamil Nādu","","12408","1356030543"
"Justice","Justice","41.7495","-87.8345","United States","US","USA","Illinois","","12625","1840011253"
"Wadhraf","Wadhraf","33.9833","9.9667","Tunisia","TN","TUN","Gabès","","12557","1788853513"
"Adalaj","Adalaj","23.1700","72.5800","India","IN","IND","Gujarāt","","11957","1356655981"
"Cardoso Moreira","Cardoso Moreira","-21.4878","-41.6158","Brazil","BR","BRA","Rio de Janeiro","","12600","1076342720"
"Cunda diá Baze","Cunda dia Baze","-8.9167","17.0667","Angola","AO","AGO","Malanje","","12620","1024847614"
"Martinho Campos","Martinho Campos","-19.3319","-45.2369","Brazil","BR","BRA","Minas Gerais","","12611","1076819510"
"Sarıveliler","Sariveliler","36.6964","32.6203","Turkey","TR","TUR","Karaman","minor","12601","1792137897"
"Edemissen","Edemissen","52.3667","10.2667","Germany","DE","DEU","Lower Saxony","","12502","1276000727"
"Çukurçayır","Cukurcayir","40.9667","39.7333","Turkey","TR","TUR","Trabzon","","11077","1792087302"
"Itiquira","Itiquira","-17.2089","-54.1500","Brazil","BR","BRA","Mato Grosso","","12620","1076169143"
"Durmersheim","Durmersheim","48.9383","8.2769","Germany","DE","DEU","Baden-Württemberg","","12160","1276764193"
"Manpaur","Manpaur","26.5081","85.9478","India","IN","IND","Bihār","","11361","1356527351"
"Oshikango","Oshikango","-17.4000","15.8833","Namibia","NA","NAM","Ohangwena","","12423","1516819374"
"Kākalūr","Kakalur","13.1394","79.9287","India","IN","IND","Tamil Nādu","","11803","1356069257"
"San Marino","San Marino","34.1224","-118.1132","United States","US","USA","California","","12620","1840021863"
"Cinisi","Cinisi","38.1667","13.1000","Italy","IT","ITA","Sicilia","","12250","1380020077"
"Smyrna","Smyrna","39.2935","-75.6083","United States","US","USA","Delaware","","12619","1840005820"
"Mamakating","Mamakating","41.5860","-74.4951","United States","US","USA","New York","","12619","1840087643"
"San Mauro Pascoli","San Mauro Pascoli","44.1000","12.4167","Italy","IT","ITA","Emilia-Romagna","","11929","1380282850"
"Paramanandal","Paramanandal","12.3585","78.7664","India","IN","IND","Tamil Nādu","","12031","1356416905"
"Tuzdybastaū","Tuzdybastau","43.3189","77.0594","Kazakhstan","KZ","KAZ","","","12577","1398000921"
"Paramankurichi","Paramankurichi","8.4785","78.0465","India","IN","IND","Tamil Nādu","","11913","1356232090"
"Baikunthpur","Baikunthpur","24.7277","81.4098","India","IN","IND","Madhya Pradesh","","12276","1356049328"
"Wieringerwerf","Wieringerwerf","52.8506","5.0228","Netherlands","NL","NLD","Noord-Holland","","12576","1528023742"
"Brandfort","Brandfort","-28.7000","26.4667","South Africa","ZA","ZAF","Free State","","12091","1710953731"
"Kayattār","Kayattar","8.9469","77.7714","India","IN","IND","Tamil Nādu","","12162","1356975939"
"Rong Kwang","Rong Kwang","18.3392","100.3172","Thailand","TH","THA","Phrae","minor","12553","1764929562"
"Duartina","Duartina","-22.4144","-49.4039","Brazil","BR","BRA","São Paulo","","12567","1076361175"
"Tundhul","Tundhul","23.3247","85.2447","India","IN","IND","Jhārkhand","","11985","1356192540"
"Cananéia","Cananeia","-25.0150","-47.9269","Brazil","BR","BRA","São Paulo","","12604","1076252867"
"Camposampiero","Camposampiero","45.5667","11.9333","Italy","IT","ITA","Veneto","","12043","1380620139"
"Yuzha","Yuzha","56.5833","42.0333","Russia","RU","RUS","Ivanovskaya Oblast’","","12595","1643003357"
"Isla Ratón","Isla Raton","5.1311","-67.8106","Venezuela","VE","VEN","Amazonas","minor","12612","1862486831"
"San Rafael","San Rafael","6.2975","-75.0314","Colombia","CO","COL","Antioquia","minor","12578","1170732033"
"Borger","Borger","35.6598","-101.4012","United States","US","USA","Texas","","12612","1840019083"
"Wietmarschen","Wietmarschen","52.5331","7.1331","Germany","DE","DEU","Lower Saxony","","12507","1276669327"
"Brunswick","Brunswick","42.7558","-73.5903","United States","US","USA","New York","","12611","1840087244"
"Taguaí","Taguai","-23.4519","-49.4089","Brazil","BR","BRA","São Paulo","","12586","1076580589"
"Festus","Festus","38.2194","-90.4097","United States","US","USA","Missouri","","12609","1840008652"
"Phulparās","Phulparas","26.3549","86.4908","India","IN","IND","Bihār","","11358","1356118381"
"Spresiano","Spresiano","45.7833","12.2500","Italy","IT","ITA","Veneto","","12136","1380609623"
"Central Elgin","Central Elgin","42.7667","-81.1000","Canada","CA","CAN","Ontario","","12607","1124000475"
"San Pietro in Casale","San Pietro in Casale","44.7000","11.4000","Italy","IT","ITA","Emilia-Romagna","","12418","1380864368"
"Gavardo","Gavardo","45.5875","10.4389","Italy","IT","ITA","Lombardy","","12197","1380750448"
"Matsukawa","Matsukawa","35.5972","137.9097","Japan","JP","JPN","Nagano","","12435","1392003267"
"Steenokkerzeel","Steenokkerzeel","50.9189","4.5083","Belgium","BE","BEL","Flanders","","12090","1056649195"
"Quimperlé","Quimperle","47.8728","-3.5497","France","FR","FRA","Bretagne","","12220","1250503112"
"Hinundayan","Hinundayan","10.3500","125.2500","Philippines","PH","PHL","Southern Leyte","","12398","1608679493"
"Tiszavasvári","Tiszavasvari","47.9511","21.3689","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","12508","1348419818"
"Provadia","Provadia","43.1833","27.4333","Bulgaria","BG","BGR","Varna","","12556","1100595849"
"Cevicos","Cevicos","19.0000","-69.9800","Dominican Republic","DO","DOM","Cibao Sur","","12589","1214401810"
"Vălenii de Munte","Valenii de Munte","45.1856","26.0397","Romania","RO","ROU","Prahova","","12044","1642303389"
"Goražde","Gorazde","43.6667","18.9778","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","12512","1070619970"
"Birni","Birni","9.9892","1.5269","Benin","BJ","BEN","Atacora","","12559","1204657577"
"Anklam","Anklam","53.8500","13.6833","Germany","DE","DEU","Mecklenburg-Western Pomerania","","12385","1276192154"
"Ambātturai","Ambatturai","10.2603","77.9303","India","IN","IND","Tamil Nādu","","11902","1356286270"
"Shamsābād","Shamsabad","23.8149","77.4957","India","IN","IND","Madhya Pradesh","","12372","1356165566"
"Chaukhata","Chaukhata","25.0247","81.7375","India","IN","IND","Uttar Pradesh","","12018","1356216053"
"Elland","Elland","53.6830","-1.8400","United Kingdom","GB","GBR","Calderdale","","11676","1826432819"
"Bryne","Bryne","58.7354","5.6477","Norway","NO","NOR","Rogaland","minor","12465","1578311049"
"Casciana Terme","Casciana Terme","43.5281","10.6192","Italy","IT","ITA","Tuscany","","12444","1380083261"
"Mehnatobod","Mehnatobod","40.2000","68.9500","Tajikistan","TJ","TJK","Sughd","","12400","1762955778"
"Ierápetra","Ierapetra","35.0000","25.7333","Greece","GR","GRC","Kríti","minor","12355","1300968251"
"East Islip","East Islip","40.7257","-73.1869","United States","US","USA","New York","","12596","1840005036"
"Paal","Paal","51.0392","5.1728","Belgium","BE","BEL","Flanders","","12083","1056886583"
"Balneário do Rincão","Balneario do Rincao","-28.8344","-49.2361","Brazil","BR","BRA","Santa Catarina","","12403","1076217271"
"Cumpăna","Cumpana","44.1128","28.5558","Romania","RO","ROU","Constanţa","","12333","1642472472"
"Khujner","Khujner","23.7860","76.6177","India","IN","IND","Madhya Pradesh","","12250","1356604233"
"North Valley","North Valley","35.1736","-106.6231","United States","US","USA","New Mexico","","12594","1840033721"
"Qārah","Qarah","34.1542","36.7442","Syria","SY","SYR","Rīf Dimashq","","12508","1760019863"
"Eral","Eral","8.6258","78.0228","India","IN","IND","Tamil Nādu","","12248","1356473945"
"Neuenburg am Rhein","Neuenburg am Rhein","47.8147","7.5619","Germany","DE","DEU","Baden-Württemberg","","12314","1276286003"
"Linkenheim-Hochstetten","Linkenheim-Hochstetten","49.1261","8.4100","Germany","DE","DEU","Baden-Württemberg","","12081","1276585901"
"Maba","Maba","0.7000","128.3000","Indonesia","ID","IDN","Maluku Utara","minor","12560","1360800707"
"Ottawa","Ottawa","38.5996","-95.2636","United States","US","USA","Kansas","","12592","1840003840"
"Norwalk","Norwalk","41.4895","-93.6913","United States","US","USA","Iowa","","12592","1840008213"
"Bānk","Bank","24.9539","84.1928","India","IN","IND","Bihār","","11484","1356148827"
"Byram","Byram","32.1890","-90.2861","United States","US","USA","Mississippi","","12591","1840023049"
"Burām","Buram","25.9725","86.3170","India","IN","IND","Bihār","","11322","1356260732"
"Ongwediva","Ongwediva","-17.7833","15.7667","Namibia","NA","NAM","Oshana","","10964","1516962966"
"Anna Regina","Anna Regina","7.2633","-58.4806","Guyana","GY","GUY","Pomeroon-Supenaam","","12448","1328787362"
"Barra do Ribeiro","Barra do Ribeiro","-30.2908","-51.3008","Brazil","BR","BRA","Rio Grande do Sul","","12572","1076080277"
"Santa Ana Maya","Santa Ana Maya","20.0000","-101.0167","Mexico","MX","MEX","Michoacán","minor","12466","1484004080"
"Buchach","Buchach","49.0647","25.3872","Ukraine","UA","UKR","Ternopilska Oblast","","12511","1804566704"
"Valu lui Traian","Valu lui Traian","44.1650","28.4550","Romania","RO","ROU","Constanţa","","12376","1642008112"
"Nanakuli","Nanakuli","21.3892","-158.1445","United States","US","USA","Hawaii","","12587","1840029531"
"Tiadiaye","Tiadiaye","14.4167","-16.7000","Senegal","SN","SEN","Thiès","","12168","1686233081"
"Mecatlán","Mecatlan","20.2135","-97.6574","Mexico","MX","MEX","Veracruz","minor","12332","1484471160"
"Sarare","Sarare","9.7839","-69.1611","Venezuela","VE","VEN","Lara","minor","12535","1862461339"
"Pyrzyce","Pyrzyce","53.1333","14.8833","Poland","PL","POL","Zachodniopomorskie","minor","12484","1616856540"
"Iconha","Iconha","-20.7928","-40.8108","Brazil","BR","BRA","Espírito Santo","","12523","1076857916"
"Mattenhof","Mattenhof","47.4870","8.7470","Switzerland","CH","CHE","Zürich","","12098","1756012852"
"General Acha","General Acha","-37.3833","-64.6000","Argentina","AR","ARG","La Pampa","minor","12583","1032754680"
"Khānda","Khanda","28.9167","76.8833","India","IN","IND","Haryāna","","12000","1356524975"
"Berwick-Upon-Tweed","Berwick-Upon-Tweed","55.7692","-2.0025","United Kingdom","GB","GBR","Northumberland","","12043","1826037734"
"Menfi","Menfi","37.6078","12.9686","Italy","IT","ITA","Sicilia","","12471","1380428251"
"Beydağ","Beydag","38.0833","28.2333","Turkey","TR","TUR","Kayseri","minor","12507","1792722415"
"Payariq Shahri","Payariq Shahri","39.9892","66.8458","Uzbekistan","UZ","UZB","Samarqand","minor","12345","1860124573"
"Branson","Branson","36.6509","-93.2636","United States","US","USA","Missouri","","12579","1840007659"
"Bhaur","Bhaur","26.2751","86.1552","India","IN","IND","Bihār","","10672","1356702286"
"Harri","Harri","26.3238","86.2975","India","IN","IND","Bihār","","10879","1356412471"
"Drouin","Drouin","-38.1333","145.8500","Australia","AU","AUS","Victoria","","12349","1036564546"
"Geneva","Geneva","42.8645","-76.9826","United States","US","USA","New York","","12577","1840000393"
"Sântana","Santana","46.3500","21.5000","Romania","RO","ROU","Arad","","12460","1642162746"
"San Andrés de Llevaneras","San Andres de Llevaneras","41.5733","2.4828","Spain","ES","ESP","Catalonia","","11593","1724176719"
"Russi","Russi","44.3764","12.0334","Italy","IT","ITA","Emilia-Romagna","","12309","1380637948"
"Prairie Ridge","Prairie Ridge","47.1438","-122.1408","United States","US","USA","Washington","","12575","1840037882"
"Bishnāh","Bishnah","32.6106","74.8556","India","IN","IND","Jammu and Kashmīr","","12063","1356036048"
"Ponnai","Ponnai","13.1276","79.2575","India","IN","IND","Tamil Nādu","","12394","1356059965"
"Currimao","Currimao","18.0203","120.4864","Philippines","PH","PHL","Ilocos Norte","","12215","1608940282"
"Kataysk","Kataysk","56.3000","62.6000","Russia","RU","RUS","Kurganskaya Oblast’","","12565","1643869391"
"Tezoatlán de Segura y Luna","Tezoatlan de Segura y Luna","17.6500","-97.8167","Mexico","MX","MEX","Oaxaca","minor","11034","1484633645"
"Bandipur","Bandipur","27.9381","84.4069","Nepal","NP","NPL","","","12450","1524589061"
"Vohindava","Vohindava","-22.4167","47.8667","Madagascar","MG","MDG","Fianarantsoa","","12000","1450616288"
"Kongnolli","Kongnolli","16.4200","74.5800","India","IN","IND","Mahārāshtra","","12035","1356271145"
"Lake Tapps","Lake Tapps","47.2307","-122.1695","United States","US","USA","Washington","","12570","1840041971"
"Elkhotovo","Elkhotovo","43.3458","44.2028","Russia","RU","RUS","North Ossetia","","12501","1643282472"
"Gethaura","Gethaura","25.6879","87.7092","India","IN","IND","Bihār","","11237","1356427282"
"Sheerness","Sheerness","51.4410","0.7600","United Kingdom","GB","GBR","Kent","","11938","1826263896"
"Quirihue","Quirihue","-36.2833","-72.5333","Chile","CL","CHL","Ñuble","","12562","1152146130"
"Neerijnen","Neerijnen","51.8333","5.2833","Netherlands","NL","NLD","Gelderland","minor","12397","1528553514"
"Kelso","Kelso","46.1236","-122.8909","United States","US","USA","Washington","","12566","1840018503"
"Mira","Mira","40.4333","-8.7333","Portugal","PT","PRT","Coimbra","minor","12465","1620156397"
"Kurhani","Kurhani","25.9803","85.3374","India","IN","IND","Bihār","","10589","1356384326"
"Borgo San Dalmazzo","Borgo San Dalmazzo","44.3333","7.4833","Italy","IT","ITA","Piedmont","","12492","1380833264"
"Bandalli","Bandalli","12.1640","77.3504","India","IN","IND","Karnātaka","","12431","1356147283"
"Revúca","Revuca","48.6831","20.1133","Slovakia","SK","SVK","Banská Bystrica","minor","12249","1703490352"
"Lindenberg im Allgäu","Lindenberg im Allgau","47.6000","9.9000","Germany","DE","DEU","Bavaria","","11585","1276413375"
"Cloquet","Cloquet","46.7221","-92.4923","United States","US","USA","Minnesota","","12563","1840006646"
"Tilbury","Tilbury","51.4606","0.3582","United Kingdom","GB","GBR","Thurrock","","12450","1826146136"
"Brown Deer","Brown Deer","43.1743","-87.9750","United States","US","USA","Wisconsin","","12562","1840003041"
"Reshuijie","Reshuijie","24.4580","114.8282","China","CN","CHN","Guangdong","","12461","1156095161"
"Leutenbach","Leutenbach","48.8883","9.3914","Germany","DE","DEU","Baden-Württemberg","","11762","1276324506"
"Vianópolis","Vianopolis","-16.7419","-48.5158","Brazil","BR","BRA","Goiás","","12548","1076970203"
"Ban Krang","Ban Krang","16.8775","100.2059","Thailand","TH","THA","Phitsanulok","","12103","1764045308"
"Aywaille","Aywaille","50.4733","5.6739","Belgium","BE","BEL","Wallonia","","12405","1056994881"
"Gaffney","Gaffney","35.0743","-81.6552","United States","US","USA","South Carolina","","12560","1840013515"
"Calanasan","Calanasan","18.2550","121.0436","Philippines","PH","PHL","Apayao","","12550","1608799917"
"Sabugal","Sabugal","40.3500","-7.0833","Portugal","PT","PRT","Guarda","minor","12544","1620978952"
"Windsor Locks","Windsor Locks","41.9267","-72.6544","United States","US","USA","Connecticut","","12559","1840033751"
"Awānkh","Awankh","32.1392","75.4611","India","IN","IND","Punjab","","11959","1356237155"
"Bolbec","Bolbec","49.5722","0.4725","France","FR","FRA","Normandie","","11610","1250933047"
"Benifayó","Benifayo","39.2847","-0.4281","Spain","ES","ESP","Valencia","","11967","1724017102"
"Namchi","Namchi","27.1700","88.3500","India","IN","IND","Sikkim","","12190","1356210398"
"Armadale","Armadale","55.8978","-3.7047","United Kingdom","GB","GBR","West Lothian","","12550","1826278494"
"Diamou","Diamou","14.0939","-11.2669","Mali","ML","MLI","Kayes","","12550","1466700073"
"Petrov Val","Petrov Val","50.1500","45.2167","Russia","RU","RUS","Volgogradskaya Oblast’","","12544","1643860504"
"Koriukivka","Koriukivka","51.7753","32.2497","Ukraine","UA","UKR","Chernihivska Oblast","minor","12539","1804903304"
"Correia Pinto","Correia Pinto","-27.5850","-50.3608","Brazil","BR","BRA","Santa Catarina","","12553","1076381418"
"Bou Arkoub","Bou Arkoub","36.5400","10.5500","Tunisia","TN","TUN","Nabeul","","12312","1788987515"
"Hisarcık","Hisarcik","39.2497","29.2314","Turkey","TR","TUR","Kütahya","minor","12531","1792346193"
"Kamin-Kashyrskyi","Kamin-Kashyrskyi","51.6242","24.9606","Ukraine","UA","UKR","Volynska Oblast","minor","12519","1804718360"
"Preußisch Oldendorf","Preussisch Oldendorf","52.2833","8.5000","Germany","DE","DEU","North Rhine-Westphalia","","12375","1276631008"
"Kishanganj","Kishanganj","25.6844","86.9509","India","IN","IND","Bihār","","11470","1356240760"
"Frohburg","Frohburg","51.0561","12.5550","Germany","DE","DEU","Saxony","","12470","1276859230"
"Gandorhun","Gandorhun","7.5564","-11.6925","Sierra Leone","SL","SLE","Southern","","12288","1694102369"
"Vysoké Mýto","Vysoke Myto","49.9533","16.1617","Czechia","CZ","CZE","Pardubický Kraj","","12267","1203435938"
"Uppukkottai","Uppukkottai","9.9587","77.4105","India","IN","IND","Tamil Nādu","","12173","1356241697"
"Alkhan-Kala","Alkhan-Kala","43.2586","45.5392","Russia","RU","RUS","Chechnya","","12340","1643152177"
"Simrāhi","Simrahi","26.3135","86.8454","India","IN","IND","Bihār","","11388","1356589770"
"Chichli","Chichli","22.8336","78.8261","India","IN","IND","Madhya Pradesh","","12210","1356108572"
"Lindsay","Lindsay","36.2082","-119.0897","United States","US","USA","California","","12551","1840020367"
"Lachute","Lachute","45.6500","-74.3333","Canada","CA","CAN","Quebec","","12551","1124217062"
"Biddupur","Biddupur","25.6464","85.3203","India","IN","IND","Bihār","","11369","1356015536"
"Srīpur","Sripur","25.5861","86.0803","India","IN","IND","Bihār","","10995","1356131631"
"Koluszki","Koluszki","51.7500","19.8000","Poland","PL","POL","Łódzkie","","12396","1616140044"
"Xavantes","Xavantes","-23.0389","-49.7094","Brazil","BR","BRA","São Paulo","","12484","1076632906"
"Bhaisālotan","Bhaisalotan","27.4500","83.9167","India","IN","IND","","","12175","1356650309"
"Sangrām","Sangram","26.3141","86.3515","India","IN","IND","Bihār","","10899","1356053653"
"Mettuppālaiyam","Mettuppalaiyam","11.4503","77.5682","India","IN","IND","Tamil Nādu","","12031","1356167377"
"Huodoushancun","Huodoushancun","40.7797","117.2040","China","CN","CHN","Hebei","","12410","1156563163"
"Oberwil","Oberwil","47.5135","7.5546","Switzerland","CH","CHE","Basel-Landschaft","","11136","1756150153"
"Ouédo-Aguéko","Ouedo-Agueko","6.4963","2.4268","Benin","BJ","BEN","Atlantique","","10610","1204089947"
"Pillānallūr","Pillanallur","11.4322","78.1334","India","IN","IND","Tamil Nādu","","12092","1356230178"
"Ammāpettai","Ammapettai","11.6197","77.7426","India","IN","IND","Tamil Nādu","","12221","1356656152"
"Woolwich","Woolwich","39.7400","-75.3170","United States","US","USA","New Jersey","","12546","1840081674"
"La Carlota","La Carlota","-33.4333","-63.3000","Argentina","AR","ARG","Córdoba","minor","12537","1032992119"
"Monte Quemado","Monte Quemado","-25.8036","-62.8303","Argentina","AR","ARG","Santiago del Estero","minor","12543","1032834102"
"Melavāyi","Melavayi","13.9397","77.2256","India","IN","IND","Andhra Pradesh","","12290","1356348734"
"Enumclaw","Enumclaw","47.2018","-121.9897","United States","US","USA","Washington","","12544","1840019825"
"Nanzhai","Nanzhai","26.6299","108.7645","China","CN","CHN","Guizhou","","12495","1156088302"
"Lila","Lila","9.6000","124.1000","Philippines","PH","PHL","Bohol","","12240","1608056848"
"Laguna Paiva","Laguna Paiva","-31.3039","-60.6589","Argentina","AR","ARG","Santa Fe","","12334","1032996959"
"Pfäffikon","Pfaffikon","47.3667","8.7833","Switzerland","CH","CHE","Zürich","minor","11931","1756406468"
"Bad Windsheim","Bad Windsheim","49.5000","10.4167","Germany","DE","DEU","Bavaria","","12382","1276120612"
"Nihāl Singhwāla","Nihal Singhwala","30.5919","75.2804","India","IN","IND","Punjab","","12080","1356204919"
"Ichora","Ichora","19.4333","78.4667","India","IN","IND","Andhra Pradesh","","12358","1356489123"
"Ebersberg","Ebersberg","48.0833","11.9667","Germany","DE","DEU","Bavaria","minor","12239","1276452047"
"Gulshan","Gulshan","37.5833","69.4333","Tajikistan","TJ","TJK","Khatlon","","12418","1762579835"
"Uludere","Uludere","37.4460","42.8520","Turkey","TR","TUR","Şırnak","minor","12491","1792227573"
"Bickenhill","Bickenhill","52.4390","-1.7250","United Kingdom","GB","GBR","Solihull","","12456","1826206537"
"North Smithfield","North Smithfield","41.9727","-71.5514","United States","US","USA","Rhode Island","","12537","1840106199"
"Tendūkheda","Tendukheda","23.1708","78.8724","India","IN","IND","Madhya Pradesh","","12381","1356102722"
"Gobardhanpur Kanāp","Gobardhanpur Kanap","25.0682","84.5241","India","IN","IND","Bihār","","10641","1356100404"
"Jaidte Lbatma","Jaidte Lbatma","31.6806","-7.7281","Morocco","MA","MAR","Marrakech-Safi","","12330","1504017587"
"Ugento","Ugento","39.9333","18.1667","Italy","IT","ITA","Puglia","","12412","1380157196"
"Charmahīn","Charmahin","32.3378","51.1961","Iran","IR","IRN","Eşfahān","","12292","1364383489"
"Harrai","Harrai","22.6143","79.2221","India","IN","IND","Madhya Pradesh","","12447","1356796609"
"Tamalpais-Homestead Valley","Tamalpais-Homestead Valley","37.8793","-122.5382","United States","US","USA","California","","12535","1840074828"
"Mendota","Mendota","36.7555","-120.3776","United States","US","USA","California","","12534","1840020321"
"Melvindale","Melvindale","42.2802","-83.1782","United States","US","USA","Michigan","","12534","1840003986"
"Béjar","Bejar","40.3833","-5.7667","Spain","ES","ESP","Castille-Leon","","12269","1724324799"
"Ottappārai","Ottapparai","11.1936","77.5964","India","IN","IND","Tamil Nādu","","12154","1356165713"
"Motta Sant’Anastasia","Motta Sant'Anastasia","37.5000","14.9667","Italy","IT","ITA","Sicilia","","12189","1380816706"
"Minnāl","Minnal","13.0744","79.5590","India","IN","IND","Tamil Nādu","","11976","1356804836"
"Fort Atkinson","Fort Atkinson","42.9253","-88.8442","United States","US","USA","Wisconsin","","12529","1840003004"
"Niel","Niel","51.1167","4.3333","Belgium","BE","BEL","Flanders","","10546","1056834573"
"Shoeburyness","Shoeburyness","51.5316","0.7978","United Kingdom","GB","GBR","Southend-on-Sea","","11159","1826043837"
"La Motte-Servolex","La Motte-Servolex","45.5967","5.8775","France","FR","FRA","Auvergne-Rhône-Alpes","","12120","1250622610"
"Manne Ekeli","Manne Ekeli","17.7200","77.3600","India","IN","IND","Karnātaka","","12039","1356494614"
"Wilmington","Wilmington","39.4362","-83.8141","United States","US","USA","Ohio","","12523","1840010630"
"Kameshkovo","Kameshkovo","56.3500","41.0000","Russia","RU","RUS","Vladimirskaya Oblast’","","12496","1643012226"
"Merāl","Meral","24.1876","83.7076","India","IN","IND","Jhārkhand","","11861","1356067094"
"Samādh Bhai","Samadh Bhai","30.5985","75.1506","India","IN","IND","Punjab","","12149","1356590880"
"Viswanāthaperi","Viswanathaperi","9.3359","77.4500","India","IN","IND","Tamil Nādu","","12079","1356208064"
"Valmadrera","Valmadrera","45.8463","9.3582","Italy","IT","ITA","Lombardy","","11601","1380021270"
"Nossa Senhora do Livramento","Nossa Senhora do Livramento","-15.7750","-56.3458","Brazil","BR","BRA","Mato Grosso","","12518","1076933932"
"Sawādah","Sawadah","28.0775","30.7953","Egypt","EG","EGY","Al Minyā","","10571","1818472639"
"Estepa","Estepa","37.2917","-4.8792","Spain","ES","ESP","Andalusia","","12459","1724708314"
"San Severino Marche","San Severino Marche","43.2289","13.1771","Italy","IT","ITA","Marche","","12456","1380732356"
"Dayālpur","Dayalpur","26.0511","84.5968","India","IN","IND","Bihār","","11073","1356016891"
"Harsinghpur","Harsinghpur","26.0504","86.2180","India","IN","IND","Bihār","","11179","1356955157"
"West Grey","West Grey","44.1833","-80.8167","Canada","CA","CAN","Ontario","","12518","1124000272"
"Dom Basílio","Dom Basilio","-13.7600","-41.7708","Brazil","BR","BRA","Bahia","","12499","1076510733"
"Xinyingheyan","Xinyingheyan","35.3369","103.2681","China","CN","CHN","Gansu","","12461","1156822952"
"Boumalne","Boumalne","31.3738","-5.9956","Morocco","MA","MAR","Drâa-Tafilalet","","12328","1504840361"
"Parksville","Parksville","49.3150","-124.3120","Canada","CA","CAN","British Columbia","","12514","1124698963"
"Murukondapādu","Murukondapadu","15.9174","80.4131","India","IN","IND","Andhra Pradesh","","11749","1356038570"
"Codegua","Codegua","-34.0347","-70.6686","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","12495","1152476133"
"Taviano","Taviano","39.9833","18.0833","Italy","IT","ITA","Puglia","","11972","1380879595"
"Arumanallūr","Arumanallur","8.3167","77.4167","India","IN","IND","Tamil Nādu","","12144","1356206365"
"Sylacauga","Sylacauga","33.1780","-86.2605","United States","US","USA","Alabama","","12512","1840015700"
"Dorridge","Dorridge","52.3720","-1.7554","United Kingdom","GB","GBR","Solihull","","11140","1826008544"
"Álamos","Alamos","27.0275","-108.9400","Mexico","MX","MEX","Sonora","minor","10961","1484052656"
"Boone","Boone","42.0531","-93.8770","United States","US","USA","Iowa","","12509","1840007012"
"Cascades","Cascades","39.0464","-77.3874","United States","US","USA","Virginia","","12509","1840041712"
"Kamalnagar","Kamalnagar","18.2310","77.1791","India","IN","IND","Karnātaka","","12265","1356727189"
"Sátão","Satao","40.7333","-7.7333","Portugal","PT","PRT","Viseu","minor","12444","1620157053"
"Baūyrzhan Momyshuly","Bauyrzhan Momyshuly","42.6189","70.7597","Kazakhstan","KZ","KAZ","","","12491","1398405690"
"Nandipeta","Nandipeta","18.9622","78.1772","India","IN","IND","Andhra Pradesh","","11525","1356396371"
"Lodhīkheda","Lodhikheda","21.5824","78.8591","India","IN","IND","Madhya Pradesh","","12282","1356200763"
"Viterbo","Viterbo","5.0667","-75.8833","Colombia","CO","COL","Caldas","minor","12432","1170417091"
"Vilsbiburg","Vilsbiburg","48.4500","12.3500","Germany","DE","DEU","Bavaria","","12325","1276843205"
"Tīkar","Tikar","24.4194","81.3776","India","IN","IND","Madhya Pradesh","","11812","1356112388"
"Dokkum","Dokkum","53.3269","5.9981","Netherlands","NL","NLD","Fryslân","minor","12365","1528667221"
"Rainhill","Rainhill","53.4157","-2.7625","United Kingdom","GB","GBR","St. Helens","","10853","1826589564"
"Koilakh","Koilakh","26.3358","86.2010","India","IN","IND","Bihār","","10904","1356743184"
"Nobeji","Nobeji","40.8644","141.1287","Japan","JP","JPN","Aomori","","12352","1392690088"
"Chachagüí","Chachagui","1.3605","-77.2835","Colombia","CO","COL","Nariño","minor","12419","1170508673"
"Munnūru","Munnuru","12.8283","74.8803","India","IN","IND","Karnātaka","","10607","1356076863"
"Datoda","Datoda","22.5713","75.8823","India","IN","IND","Madhya Pradesh","","12101","1356222549"
"Dhaula","Dhaula","30.2856","75.4602","India","IN","IND","Punjab","","12071","1356585688"
"Lake Norman of Catawba","Lake Norman of Catawba","35.5995","-80.9840","United States","US","USA","North Carolina","","12499","1840073862"
"Valentim Gentil","Valentim Gentil","-20.4219","-50.0878","Brazil","BR","BRA","São Paulo","","12416","1076430785"
"Bajpe","Bajpe","12.9803","74.8832","India","IN","IND","Karnātaka","","10603","1356073378"
"Massena","Massena","44.9609","-74.8339","United States","US","USA","New York","","12498","1840004058"
"Kingri","Kingri","25.6326","86.6911","India","IN","IND","Bihār","","10747","1356670551"
"Brzeszcze","Brzeszcze","50.0000","19.1500","Poland","PL","POL","Małopolskie","","11921","1616019723"
"Deerlijk","Deerlijk","50.8533","3.3533","Belgium","BE","BEL","Flanders","","11796","1056327002"
"Lake Los Angeles","Lake Los Angeles","34.6097","-117.8339","United States","US","USA","California","","12497","1840017922"
"Mortugaba","Mortugaba","-15.0228","-42.3678","Brazil","BR","BRA","Bahia","","12477","1076150645"
"Shichuanxiang","Shichuanxiang","34.5866","104.3211","China","CN","CHN","Gansu","","12436","1156078610"
"Kuhsān","Kuhsan","34.6500","61.1667","Afghanistan","AF","AFG","Herāt","minor","12463","1004303723"
"Matāla","Matala","17.8244","76.8591","India","IN","IND","Karnātaka","","12162","1356052569"
"La Argentina","La Argentina","2.1961","-75.9800","Colombia","CO","COL","Huila","minor","12475","1170000037"
"Hasroûn","Hasroun","34.2419","35.9794","Lebanon","LB","LBN","Liban-Nord","","12000","1422559333"
"El Tambo","El Tambo","1.4131","-77.3981","Colombia","CO","COL","Nariño","minor","12457","1170998419"
"Jüterbog","Juterbog","51.9933","13.0728","Germany","DE","DEU","Brandenburg","","12423","1276769458"
"Buttāyagūdem","Buttayagudem","17.2089","81.3014","India","IN","IND","Andhra Pradesh","","12394","1356470319"
"Chambray-lès-Tours","Chambray-les-Tours","47.3375","0.7139","France","FR","FRA","Centre-Val de Loire","","11880","1250870881"
"Cornedo Vicentino","Cornedo Vicentino","45.6167","11.3500","Italy","IT","ITA","Veneto","","11982","1380484003"
"Los Bellosos","Los Bellosos","19.8333","-71.0167","Dominican Republic","DO","DOM","Cibao Norte","","12414","1214104845"
"Oulad Aïssa","Oulad Aissa","30.5580","-8.6140","Morocco","MA","MAR","Souss-Massa","","12331","1504718030"
"Iarinarivo","Iarinarivo","-18.9167","47.4000","Madagascar","MG","MDG","Antananarivo","","12000","1450317086"
"Cowansville","Cowansville","45.2000","-72.7500","Canada","CA","CAN","Quebec","","12489","1124357421"
"Feliz","Feliz","-29.4508","-51.3058","Brazil","BR","BRA","Rio Grande do Sul","","12359","1076429476"
"Anjuna","Anjuna","15.5833","73.7333","India","IN","IND","Goa","","11383","1356248951"
"Möglingen","Moglingen","48.8883","9.1292","Germany","DE","DEU","Baden-Württemberg","","11361","1276353037"
"Bad Sassendorf","Bad Sassendorf","51.5831","8.1667","Germany","DE","DEU","North Rhine-Westphalia","","12294","1276292004"
"Kīrippatti","Kirippatti","11.5357","78.4897","India","IN","IND","Tamil Nādu","","12096","1356227893"
"Finspång","Finspang","58.7000","15.8000","Sweden","SE","SWE","Östergötland","minor","12440","1752523151"
"Nina Rodrigues","Nina Rodrigues","-3.4658","-43.9050","Brazil","BR","BRA","Maranhão","","12464","1076652930"
"Kewanee","Kewanee","41.2399","-89.9264","United States","US","USA","Illinois","","12485","1840008206"
"Pothia","Pothia","25.5413","87.2637","India","IN","IND","Bihār","","11411","1356317852"
"Gamarra","Gamarra","8.3333","-73.6667","Colombia","CO","COL","Cesar","minor","12444","1170567440"
"Obernai","Obernai","48.4622","7.4819","France","FR","FRA","Grand Est","","12015","1250860703"
"Shuangxianxiang","Shuangxianxiang","35.3300","105.6950","China","CN","CHN","Gansu","","12322","1156522235"
"Xiushuicun","Xiushuicun","25.1728","113.0007","China","CN","CHN","Guangdong","","12137","1156573112"
"Lulhaul","Lulhaul","25.8787","86.1386","India","IN","IND","Bihār","","10755","1356587870"
"Fortuna","Fortuna","40.5862","-124.1419","United States","US","USA","California","","12480","1840009553"
"Doddanahalli","Doddanahalli","12.3892","76.9672","India","IN","IND","Karnātaka","","12243","1356227049"
"Xinchangcun","Xinchangcun","26.4249","107.5323","China","CN","CHN","Guizhou","","12394","1156680242"
"Plan-les-Ouates","Plan-les-Ouates","46.1667","6.1167","Switzerland","CH","CHE","Genève","","10656","1756223041"
"Hessisch Lichtenau","Hessisch Lichtenau","51.2000","9.7167","Germany","DE","DEU","Hesse","","12360","1276002742"
"Duggirāla","Duggirala","16.3281","80.6243","India","IN","IND","Andhra Pradesh","","11098","1356373497"
"Epping","Epping","51.7004","0.1087","United Kingdom","GB","GBR","Essex","","11047","1826234838"
"Araçás","Aracas","-12.2200","-38.2028","Brazil","BR","BRA","Bahia","","12450","1076854989"
"Tsuruta","Tsuruta","40.7588","140.4284","Japan","JP","JPN","Aomori","","12211","1392736759"
"Georgetown","Georgetown","31.9849","-81.2260","United States","US","USA","Georgia","","12474","1840134450"
"Ulverston","Ulverston","54.1930","-3.0900","United Kingdom","GB","GBR","Cumbria","","11678","1826513559"
"Eyvānekey","Eyvanekey","35.3433","52.0675","Iran","IR","IRN","Semnān","","12462","1364816027"
"Kesarimangalam","Kesarimangalam","11.5423","77.7247","India","IN","IND","Tamil Nādu","","12144","1356164729"
"Yangiariq","Yangiariq","41.3628","60.6075","Uzbekistan","UZ","UZB","Xorazm","minor","12200","1860360954"
"An Phú","An Phu","10.8500","105.0833","Vietnam","VN","VNM","An Giang","minor","11108","1704682983"
"Cortês","Cortes","-8.4700","-35.5436","Brazil","BR","BRA","Pernambuco","","12452","1076047462"
"Monóvar","Monovar","38.4369","-0.8381","Spain","ES","ESP","Valencia","","12387","1724870019"
"Putussibau","Putussibau","0.8575","112.9250","Indonesia","ID","IDN","Kalimantan Barat","minor","12459","1360803364"
"Diamniadio","Diamniadio","14.7219","-17.1789","Senegal","SN","SEN","Dakar","","12326","1686338473"
"Kolanpāk","Kolanpak","17.6942","79.0348","India","IN","IND","Andhra Pradesh","","12204","1356542725"
"Pecos","Pecos","31.3971","-103.5201","United States","US","USA","Texas","","12467","1840020806"
"Sędziszów Małopolski","Sedziszow Malopolski","50.0667","21.7000","Poland","PL","POL","Podkarpackie","","12313","1616036495"
"Ban Bang Yai","Ban Bang Yai","13.8369","100.3591","Thailand","TH","THA","Nonthaburi","","10767","1764949681"
"Norīa","Noria","32.5210","74.7984","India","IN","IND","Jammu and Kashmīr","","11955","1356669149"
"Złocieniec","Zlocieniec","53.5269","16.0122","Poland","PL","POL","Zachodniopomorskie","","12373","1616208535"
"Emstek","Emstek","52.8167","8.1500","Germany","DE","DEU","Lower Saxony","","12351","1276929642"
"Riverview","Riverview","42.1723","-83.1946","United States","US","USA","Michigan","","12465","1840003961"
"Gladenbach","Gladenbach","50.7681","8.5828","Germany","DE","DEU","Hesse","","12294","1276762551"
"Acalá del Río","Acala del Rio","37.5167","-5.9667","Spain","ES","ESP","Andalusia","","12315","1724367182"
"Troy","Troy","38.9708","-90.9714","United States","US","USA","Missouri","","12463","1840010689"
"Tharīke","Tharike","30.8669","75.7811","India","IN","IND","Punjab","","11726","1356090999"
"Candolim","Candolim","15.5200","73.7500","India","IN","IND","","","11357","1356622237"
"Balchik","Balchik","43.4269","28.1617","Bulgaria","BG","BGR","Dobrich","minor","12429","1100579546"
"Refahiye","Refahiye","39.9011","38.7686","Turkey","TR","TUR","Erzincan","minor","12456","1792768598"
"Datiāna","Datiana","25.4834","84.8628","India","IN","IND","Bihār","","11258","1356047509"
"Novalukoml","Novalukoml","54.6569","29.1500","Belarus","BY","BLR","Vitsyebskaya Voblasts’","","12439","1112086719"
"Commerce","Commerce","33.9963","-118.1519","United States","US","USA","California","","12459","1840019239"
"Vallejuelo","Vallejuelo","18.6500","-71.3300","Dominican Republic","DO","DOM","El Valle","","12403","1214441701"
"Sangān","Sangan","34.3986","60.2581","Iran","IR","IRN","Khorāsān-e Raẕavī","","12443","1364471861"
"Vaddepalli","Vaddepalli","15.9363","77.8413","India","IN","IND","Andhra Pradesh","","12239","1356809394"
"Huai Yot","Huai Yot","7.7894","99.6347","Thailand","TH","THA","Trang","minor","12337","1764009966"
"Kinrooi","Kinrooi","51.1450","5.7403","Belgium","BE","BEL","Flanders","","12233","1056406187"
"Sablé-sur-Sarthe","Sable-sur-Sarthe","47.8400","-0.3342","France","FR","FRA","Pays de la Loire","","12127","1250336325"
"Angalakurichchi","Angalakurichchi","10.5282","76.9962","India","IN","IND","Tamil Nādu","","11837","1356162956"
"Lakeland Village","Lakeland Village","33.6480","-117.3706","United States","US","USA","California","","12455","1840017989"
"Khānjahānpur","Khanjahanpur","25.6055","86.0927","India","IN","IND","Bihār","","10899","1356018473"
"Al Awjām","Al Awjam","26.5583","49.9503","Saudi Arabia","SA","SAU","Ash Sharqīyah","","11460","1682207771"
"Hizan","Hizan","38.2256","42.4269","Turkey","TR","TUR","Bitlis","minor","12409","1792308787"
"Bernalda","Bernalda","40.4167","16.6833","Italy","IT","ITA","Basilicata","","12453","1380725968"
"Leominster","Leominster","52.2282","-2.7385","United Kingdom","GB","GBR","Herefordshire","","11959","1826948829"
"Siki","Siki","10.1833","2.3833","Benin","BJ","BEN","Borgou","","12398","1204404747"
"Guamaré","Guamare","-5.0950","-36.3250","Brazil","BR","BRA","Rio Grande do Norte","","12404","1076393739"
"Gaildorf","Gaildorf","49.0000","9.7667","Germany","DE","DEU","Baden-Württemberg","","12268","1276657591"
"Sever do Vouga","Sever do Vouga","40.7333","-8.3667","Portugal","PT","PRT","Aveiro","minor","12356","1620380173"
"Marysville","Marysville","39.1518","-121.5835","United States","US","USA","California","","12451","1840020219"
"Xinbocun","Xinbocun","42.3037","117.7259","China","CN","CHN","Hebei","","12396","1156030405"
"Mugdampalli","Mugdampalli","17.6167","77.4974","India","IN","IND","Andhra Pradesh","","11992","1356222425"
"Reẕvānshahr","Rezvanshahr","37.5511","49.1394","Iran","IR","IRN","Gīlān","minor","12355","1364695165"
"Dehqonobod","Dehqonobod","37.6333","69.4500","Tajikistan","TJ","TJK","Khatlon","","12327","1762454979"
"Murnau am Staffelsee","Murnau am Staffelsee","47.6833","11.2000","Germany","DE","DEU","Bavaria","","12128","1276454269"
"Kika","Kika","9.3000","2.2667","Benin","BJ","BEN","Borgou","","12398","1204827560"
"Teningen","Teningen","48.1269","7.8103","Germany","DE","DEU","Baden-Württemberg","","12145","1276711820"
"Profondeville","Profondeville","50.3769","4.8700","Belgium","BE","BEL","Wallonia","","12204","1056732493"
"Romanshorn","Romanshorn","47.5635","9.3564","Switzerland","CH","CHE","Thurgau","","11167","1756539997"
"Oulad Fares","Oulad Fares","35.5167","-5.9333","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12341","1504046403"
"Baraboo","Baraboo","43.4695","-89.7376","United States","US","USA","Wisconsin","","12446","1840002720"
"Kachnār","Kachnar","25.9699","84.3589","India","IN","IND","Bihār","","11123","1356111287"
"Mesker-Yurt","Mesker-Yurt","43.2514","45.9072","Russia","RU","RUS","Chechnya","","12224","1643795085"
"Susa","Susa","5.4528","-73.8140","Colombia","CO","COL","Cundinamarca","minor","12302","1170534475"
"Kálymnos","Kalymnos","36.9900","26.9850","Greece","GR","GRC","Nótio Aigaío","minor","12324","1300286595"
"Castelnaudary","Castelnaudary","43.3181","1.9539","France","FR","FRA","Occitanie","","12187","1250176126"
"Santa Mariana","Santa Mariana","-23.1500","-50.5500","Brazil","BR","BRA","Paraná","","12435","1076823727"
"Dharmastala","Dharmastala","12.9479","75.3807","India","IN","IND","Karnātaka","","12220","1356767274"
"Santañy","Santany","39.3542","3.1283","Spain","ES","ESP","Balearic Islands","","12342","1724113241"
"El Carmen","El Carmen","13.3500","-88.0000","El Salvador","SV","SLV","La Unión","","12324","1222743777"
"Razlog","Razlog","41.8833","23.4667","Bulgaria","BG","BGR","Blagoevgrad","","12385","1100974105"
"Teano","Teano","41.2500","14.0667","Italy","IT","ITA","Campania","","12303","1380589250"
"Cahokia","Cahokia","38.5650","-90.1793","United States","US","USA","Illinois","","12440","1840010781"
"Escanaba","Escanaba","45.7477","-87.0900","United States","US","USA","Michigan","","12439","1840003925"
"Neosho","Neosho","36.8437","-94.3758","United States","US","USA","Missouri","","12439","1840008793"
"Sher Muhammadpuram","Sher Muhammadpuram","18.2997","83.8331","India","IN","IND","Andhra Pradesh","","11756","1356075910"
"Quinta de Tilcoco","Quinta de Tilcoco","-34.3547","-70.9636","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","12379","1152072609"
"Mato Verde","Mato Verde","-15.3969","-42.8658","Brazil","BR","BRA","Minas Gerais","","12412","1076669795"
"Bécancour","Becancour","46.3333","-72.4333","Canada","CA","CAN","Quebec","","12438","1124242297"
"Nawalpur","Nawalpur","26.9366","84.2985","India","IN","IND","Bihār","","11121","1356052987"
"Asfarvarīn","Asfarvarin","35.9333","49.7469","Iran","IR","IRN","Qazvīn","","12371","1364597620"
"Enkesen","Enkesen","51.5006","7.9750","Germany","DE","DEU","North Rhine-Westphalia","","12197","1276726975"
"Terranuova Bracciolini","Terranuova Bracciolini","43.5531","11.5894","Italy","IT","ITA","Tuscany","","12292","1380695494"
"Iaciara","Iaciara","-14.0958","-46.6319","Brazil","BR","BRA","Goiás","","12427","1076012245"
"Palmer","Palmer","42.1888","-72.3112","United States","US","USA","Massachusetts","","12434","1840132584"
"Rehoboth","Rehoboth","41.8439","-71.2456","United States","US","USA","Massachusetts","","12434","1840053661"
"Srīrāmpuram","Srirampuram","10.4346","77.8075","India","IN","IND","Tamil Nādu","","11791","1356246673"
"São Luís do Curu","Sao Luis do Curu","-3.6700","-39.2428","Brazil","BR","BRA","Ceará","","12332","1076329932"
"Tring","Tring","51.7962","-0.6592","United Kingdom","GB","GBR","Hertfordshire","","12099","1826144643"
"Teotepeque","Teotepeque","13.5853","-89.5183","El Salvador","SV","SLV","La Libertad","","12320","1222208271"
"El Ayote","El Ayote","12.4972","-84.8181","Nicaragua","NI","NIC","Chontales","","12417","1558565622"
"Pipariya","Pipariya","25.2593","86.1149","India","IN","IND","Bihār","","11275","1356781644"
"Meru","Meru","24.0097","85.4673","India","IN","IND","Jhārkhand","","11282","1356819085"
"Maqu","Maqu","35.9451","106.9953","China","CN","CHN","Gansu","","12333","1156547110"
"Alwa Tirunagari","Alwa Tirunagari","8.6100","77.9400","India","IN","IND","Tamil Nādu","","11725","1356540796"
"Nagykáta","Nagykata","47.4120","19.7396","Hungary","HU","HUN","Pest","minor","12280","1348906968"
"Valentigney","Valentigney","47.4625","6.8322","France","FR","FRA","Bourgogne-Franche-Comté","","11272","1250305919"
"Obikiik","Obikiik","38.1586","68.6722","Tajikistan","TJ","TJK","Khatlon","minor","12300","1762953751"
"Cacequi","Cacequi","-29.8839","-54.8250","Brazil","BR","BRA","Rio Grande do Sul","","12423","1076502675"
"Mays Chapel","Mays Chapel","39.4343","-76.6516","United States","US","USA","Maryland","","12428","1840031492"
"Doi Lo","Doi Lo","18.4667","98.7833","Thailand","TH","THA","Chiang Mai","minor","12261","1764750189"
"Ambatomainty","Ambatomainty","-20.9000","46.4500","Madagascar","MG","MDG","Fianarantsoa","","12421","1450861894"
"Ghambiraopet","Ghambiraopet","18.3000","78.5833","India","IN","IND","Andhra Pradesh","","11545","1356200566"
"Shamshernagar","Shamshernagar","25.0862","84.4497","India","IN","IND","Bihār","","11890","1356617078"
"Solliès-Pont","Sollies-Pont","43.1900","6.0411","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11762","1250086768"
"Velair","Velair","18.0071","79.3272","India","IN","IND","Andhra Pradesh","","12121","1356155810"
"Pira","Pira","8.5000","1.7333","Benin","BJ","BEN","Collines","","12377","1204787777"
"Sagon","Sagon","7.1500","2.4167","Benin","BJ","BEN","Zou","","12160","1204489937"
"Sarābleh","Sarableh","33.7667","46.5664","Iran","IR","IRN","Īlām","minor","12393","1364153811"
"Issum","Issum","51.5389","6.4236","Germany","DE","DEU","North Rhine-Westphalia","","12201","1276091414"
"Bankya","Bankya","42.7000","23.1333","Bulgaria","BG","BGR","Sofia-Grad","","11358","1100031419"
"Saboyá","Saboya","5.7000","-73.7667","Colombia","CO","COL","Boyacá","minor","12372","1170473369"
"Chã da Alegria","Cha da Alegria","-8.0008","-35.2133","Brazil","BR","BRA","Pernambuco","","12404","1076360167"
"Josefina","Josefina","8.2144","123.5439","Philippines","PH","PHL","Zamboanga del Sur","","12205","1608897115"
"Veurne","Veurne","51.0722","2.6622","Belgium","BE","BEL","Flanders","minor","12295","1056727573"
"Taiynsha","Taiynsha","53.8478","69.7639","Kazakhstan","KZ","KAZ","","minor","12418","1398304928"
"Caapiranga","Caapiranga","-3.3167","-61.2000","Brazil","BR","BRA","Amazonas","","12420","1076620552"
"Quatipuru","Quatipuru","-0.9008","-47.0058","Brazil","BR","BRA","Pará","","12411","1076959149"
"Anndevarapeta","Anndevarapeta","17.0937","81.5799","India","IN","IND","Andhra Pradesh","","12093","1356764826"
"Saint Ives","Saint Ives","50.2110","-5.4800","United Kingdom","GB","GBR","Cornwall","","11226","1826132004"
"Valencia","Valencia","10.6500","-61.2000","Trinidad and Tobago","TT","TTO","Sangre Grande","","12327","1780842812"
"Harrison","Harrison","44.1935","-88.2941","United States","US","USA","Wisconsin","","12418","1840038103"
"Dingman","Dingman","41.3226","-74.9264","United States","US","USA","Pennsylvania","","12417","1840146119"
"Las Parejas","Las Parejas","-32.6833","-61.5333","Argentina","AR","ARG","Santa Fe","","12375","1032630210"
"Meerzorg","Meerzorg","5.8072","-55.1469","Suriname","SR","SUR","Commewijne","","12405","1740663491"
"Cookstown","Cookstown","54.6470","-6.7450","United Kingdom","GB","GBR","Mid-Ulster","","10646","1826277161"
"Moldova Nouă","Moldova Noua","44.7178","21.6639","Romania","RO","ROU","Caraş-Severin","","12350","1642091214"
"Chāltābāria","Chaltabaria","21.9996","88.5312","India","IN","IND","West Bengal","","11738","1356650026"
"Nevele","Nevele","51.0333","3.5500","Belgium","BE","BEL","Flanders","","12179","1056686535"
"Cuichapa","Cuichapa","18.7667","-96.8667","Mexico","MX","MEX","Veracruz","minor","12238","1484015677"
"Kingsburg","Kingsburg","36.5244","-119.5602","United States","US","USA","California","","12413","1840020325"
"Baozhong","Baozhong","23.6956","120.3117","Taiwan","TW","TWN","Yunlin","","12086","1158849280"
"Kegeyli Shahar","Kegeyli Shahar","42.7767","59.6078","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","12400","1860388394"
"Minooka","Minooka","41.4507","-88.2791","United States","US","USA","Illinois","","12412","1840011618"
"Hengshan","Hengshan","24.7100","121.1369","Taiwan","TW","TWN","Hsinchu","","12226","1158018380"
"Tucson Estates","Tucson Estates","32.1792","-111.1266","United States","US","USA","Arizona","","12411","1840027929"
"Juvignac","Juvignac","43.6131","3.8097","France","FR","FRA","Occitanie","","11363","1250046431"
"Budakalász","Budakalasz","47.6215","19.0460","Hungary","HU","HUN","Pest","","11640","1348378695"
"Lālsaraia","Lalsaraia","26.7388","84.6607","India","IN","IND","Bihār","","11073","1356210955"
"Pößneck","Possneck","50.7000","11.6000","Germany","DE","DEU","Thuringia","","11924","1276601734"
"Ambinanynony","Ambinanynony","-18.6000","49.1167","Madagascar","MG","MDG","Toamasina","","12392","1450742943"
"Kamlāpur","Kamlapur","17.5786","76.9887","India","IN","IND","Karnātaka","","11878","1356369488"
"Sūreshjān","Sureshjan","32.3156","50.6783","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","12308","1364345684"
"Błonie","Blonie","52.2000","20.6167","Poland","PL","POL","Mazowieckie","","12152","1616120917"
"Harrislee","Harrislee","54.7972","9.3764","Germany","DE","DEU","Schleswig-Holstein","","11779","1276177135"
"Higashiagatsuma","Higashiagatsuma","36.5714","138.8203","Japan","JP","JPN","Gunma","","12356","1392003413"
"Eraclea","Eraclea","45.5833","12.6833","Italy","IT","ITA","Veneto","","12276","1380571098"
"Dhakaich","Dhakaich","25.5835","84.1886","India","IN","IND","Bihār","","11213","1356358314"
"São Sebastião da Grama","Sao Sebastiao da Grama","-21.7108","-46.8208","Brazil","BR","BRA","São Paulo","","12355","1076446818"
"Khvalynsk","Khvalynsk","52.4833","48.1000","Russia","RU","RUS","Saratovskaya Oblast’","","12391","1643012410"
"Ilhota","Ilhota","-26.9000","-48.8269","Brazil","BR","BRA","Santa Catarina","","12355","1076725454"
"Lizzanello","Lizzanello","40.3051","18.2213","Italy","IT","ITA","Puglia","","11934","1380805598"
"Glyká Nerá","Glyka Nera","37.9917","23.8483","Greece","GR","GRC","Attikí","","11049","1300485873"
"Itaguara","Itaguara","-20.3919","-44.4878","Brazil","BR","BRA","Minas Gerais","","12372","1076647293"
"Pulakurti","Pulakurti","15.7502","77.7091","India","IN","IND","Andhra Pradesh","","12105","1356401958"
"Las Lomitas","Las Lomitas","-24.7072","-60.5944","Argentina","AR","ARG","Formosa","","12399","1032153825"
"Vedène","Vedene","43.9775","4.9031","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11383","1250510634"
"Vobkent Shahri","Vobkent Shahri","40.0333","64.5167","Uzbekistan","UZ","UZB","Buxoro","minor","12351","1860136901"
"Bad Fallingbostel","Bad Fallingbostel","52.8675","9.6967","Germany","DE","DEU","Lower Saxony","minor","12209","1276618111"
"Jadayāmpālaiyam","Jadayampalaiyam","11.2930","76.9495","India","IN","IND","Tamil Nādu","","11952","1356130975"
"McComb","McComb","31.2442","-90.4716","United States","US","USA","Mississippi","","12400","1840015877"
"Cruzeiro do Sul","Cruzeiro do Sul","-29.5128","-51.9850","Brazil","BR","BRA","Rio Grande do Sul","","12320","1076987096"
"Fojnica","Fojnica","43.9667","17.9000","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","12356","1070762647"
"Herrin","Herrin","37.7983","-89.0305","United States","US","USA","Illinois","","12398","1840007576"
"Gisors","Gisors","49.2806","1.7764","France","FR","FRA","Normandie","","11696","1250099391"
"Rankweil","Rankweil","47.2667","9.6500","Austria","AT","AUT","Vorarlberg","","11855","1040449499"
"Catarama","Catarama","-1.5700","-79.4700","Ecuador","EC","ECU","Los Ríos","","12320","1218740078"
"Yedtare","Yedtare","13.9246","74.6171","India","IN","IND","Karnātaka","","12104","1356023507"
"North St. Paul","North St. Paul","45.0137","-92.9995","United States","US","USA","Minnesota","","12395","1840007843"
"Māhta","Mahta","31.6647","75.2509","India","IN","IND","Punjab","","11617","1356322687"
"Tabontabon","Tabontabon","11.0333","124.9667","Philippines","PH","PHL","Leyte","","11902","1608562896"
"Prieska","Prieska","-29.6683","22.7439","South Africa","ZA","ZAF","Northern Cape","","11236","1710649877"
"Khonobod","Khonobod","40.2000","70.6000","Tajikistan","TJ","TJK","Sughd","","12159","1762540080"
"Monte Sant’Angelo","Monte Sant'Angelo","41.7000","15.9667","Italy","IT","ITA","Puglia","","12342","1380439679"
"São José do Campestre","Sao Jose do Campestre","-6.3158","-35.7139","Brazil","BR","BRA","Rio Grande do Norte","","12356","1076675633"
"East Renton Highlands","East Renton Highlands","47.4718","-122.0854","United States","US","USA","Washington","","12392","1840037866"
"Partāp Tānr","Partap Tanr","25.8897","85.2551","India","IN","IND","Bihār","","10615","1356462121"
"Huntington","Huntington","53.9926","-1.0430","United Kingdom","GB","GBR","York","","12108","1826116194"
"Parbata","Parbata","25.3195","86.7212","India","IN","IND","Bihār","","11181","1356258365"
"Bishunpur Hakīmābād","Bishunpur Hakimabad","25.8453","85.8432","India","IN","IND","Bihār","","10950","1356277644"
"Sânnicolau Mare","Sannicolau Mare","46.0636","20.6125","Romania","RO","ROU","Timiş","","12312","1642288572"
"Châteaubriant","Chateaubriant","47.7169","-1.3761","France","FR","FRA","Pays de la Loire","minor","12031","1250253884"
"Gistel","Gistel","51.1561","2.9672","Belgium","BE","BEL","Flanders","","12106","1056419947"
"Jhāua","Jhaua","25.7487","84.9240","India","IN","IND","Bihār","","10393","1356099578"
"Ludwigslust","Ludwigslust","53.3244","11.4972","Germany","DE","DEU","Mecklenburg-Western Pomerania","","12233","1276582763"
"Derby","Derby","41.3265","-73.0833","United States","US","USA","Connecticut","","12387","1840004847"
"Tüp","Tup","42.7300","78.3600","Kyrgyzstan","KG","KGZ","Ysyk-Köl","minor","12355","1417469504"
"Paxtaobod","Paxtaobod","40.3453","68.1925","Uzbekistan","UZ","UZB","Sirdaryo","","12200","1860473254"
"Governador Dix-Sept Rosado","Governador Dix-Sept Rosado","-5.4589","-37.5208","Brazil","BR","BRA","Rio Grande do Norte","","12374","1076290695"
"Cesson","Cesson","48.5658","2.6011","France","FR","FRA","Île-de-France","","10833","1250257228"
"Leeds","Leeds","33.5436","-86.5639","United States","US","USA","Alabama","","12385","1840005106"
"Oulad Amrane el Mekki","Oulad Amrane el Mekki","35.2167","-5.9667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12252","1504220156"
"Rinconada de Malloa","Rinconada de Malloa","-34.4464","-70.9458","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","12342","1152964612"
"Bāgewādi","Bagewadi","16.2900","74.6000","India","IN","IND","Mahārāshtra","","11939","1356091321"
"Haddonfield","Haddonfield","39.8955","-75.0346","United States","US","USA","New Jersey","","12383","1840016699"
"Upper","Upper","39.2563","-74.7270","United States","US","USA","New Jersey","","12383","1840081578"
"Sandy","Sandy","45.3988","-122.2697","United States","US","USA","Oregon","","12383","1840021217"
"Granarolo del l’Emilia","Granarolo del l'Emilia","44.5500","11.4500","Italy","IT","ITA","Emilia-Romagna","","12032","1380002344"
"Pantepec","Pantepec","17.1833","-93.0500","Mexico","MX","MEX","Chiapas","minor","12266","1484062153"
"Poquoson","Poquoson","37.1318","-76.3568","United States","US","USA","Virginia","","12382","1840003863"
"San Antonio","San Antonio","10.0000","-74.6667","Colombia","CO","COL","Magdalena","","12364","1170443543"
"Villa Literno","Villa Literno","41.0096","14.0741","Italy","IT","ITA","Campania","","12184","1380835473"
"Mangalkot","Mangalkot","23.5213","87.9119","India","IN","IND","West Bengal","","11537","1356908397"
"Gharbia","Gharbia","35.5153","-5.9306","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12274","1504656773"
"Klazienaveen","Klazienaveen","52.7333","6.9833","Netherlands","NL","NLD","Drenthe","","11980","1528158421"
"Nkheila","Nkheila","32.9572","-7.0731","Morocco","MA","MAR","Casablanca-Settat","","12306","1504638850"
"Jork","Jork","53.5344","9.6817","Germany","DE","DEU","Lower Saxony","","12183","1276599000"
"Tyngsborough","Tyngsborough","42.6662","-71.4290","United States","US","USA","Massachusetts","","12377","1840070201"
"Sala","Sala","59.9167","16.6000","Sweden","SE","SWE","Västmanland","minor","12289","1752888405"
"Ban","Ban","-8.2333","115.4833","Indonesia","ID","IDN","Bali","","11858","1360520678"
"Bueu","Bueu","42.3167","-8.7833","Spain","ES","ESP","Galicia","","11987","1724614511"
"Monteforte Irpino","Monteforte Irpino","40.8928","14.7194","Italy","IT","ITA","Campania","","11933","1380260210"
"Inverell","Inverell","-29.7667","151.1167","Australia","AU","AUS","New South Wales","","11660","1036652902"
"Barharia","Barharia","26.3191","84.4547","India","IN","IND","Bihār","","10606","1356090971"
"Maxhütte-Haidhof","Maxhutte-Haidhof","49.2000","12.1000","Germany","DE","DEU","Bavaria","","12027","1276703973"
"Arbeláez","Arbelaez","4.2725","-74.4151","Colombia","CO","COL","Cundinamarca","minor","12292","1170472184"
"Sabaur","Sabaur","25.2428","87.0446","India","IN","IND","Bihār","","12030","1356113035"
"G’oliblar Qishlog’i","G'oliblar Qishlog'i","40.4953","67.8778","Uzbekistan","UZ","UZB","Jizzax","minor","12300","1860811696"
"Gainza","Gainza","13.6167","123.1414","Philippines","PH","PHL","Camarines Sur","","11584","1608497467"
"Alto do Rodrigues","Alto do Rodrigues","-5.2878","-36.7619","Brazil","BR","BRA","Rio Grande do Norte","","12305","1076256231"
"Mixtla de Altamirano","Mixtla de Altamirano","18.6000","-97.0000","Mexico","MX","MEX","Veracruz","minor","11646","1484655126"
"Cardoso","Cardoso","-20.0819","-49.9139","Brazil","BR","BRA","São Paulo","","12349","1076910611"
"Doctor Phillips","Doctor Phillips","28.4474","-81.4914","United States","US","USA","Florida","","12368","1840013119"
"Siruvāchchūr","Siruvachchur","11.6380","78.7557","India","IN","IND","Tamil Nādu","","11976","1356246885"
"Tissa","Tissa","34.2833","-4.6667","Morocco","MA","MAR","Fès-Meknès","","11195","1504017756"
"Sarasota Springs","Sarasota Springs","27.3087","-82.4746","United States","US","USA","Florida","","12366","1840029072"
"Aurāhi","Aurahi","25.5709","86.9668","India","IN","IND","Bihār","","10846","1356916810"
"Robertsville","Robertsville","40.3395","-74.2939","United States","US","USA","New Jersey","","12365","1840026509"
"Occhiobello","Occhiobello","44.9216","11.5812","Italy","IT","ITA","Veneto","","11993","1380582807"
"Shamaldy-Say","Shamaldy-Say","41.1972","72.1803","Kyrgyzstan","KG","KGZ","Jalal-Abad","","12308","1417191406"
"‘Anadān","\`Anadan","36.2936","37.0444","Syria","SY","SYR","Ḩalab","","11918","1760993442"
"Bad Freienwalde","Bad Freienwalde","52.7856","14.0325","Germany","DE","DEU","Brandenburg","","12268","1276111750"
"Yakouren","Yakouren","36.7348","4.4386","Algeria","DZ","DZA","Tizi Ouzou","","12203","1012612535"
"Parora","Parora","25.8022","87.4032","India","IN","IND","Bihār","","11366","1356013365"
"Kladanj","Kladanj","44.2256","18.6925","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","12348","1070838044"
"Logan","Logan","40.5263","-78.4234","United States","US","USA","Pennsylvania","","12360","1840151322"
"Bind","Bind","25.3035","85.6869","India","IN","IND","Bihār","","11379","1356236664"
"Sremska Kamenica","Sremska Kamenica","45.2206","19.8392","Serbia","RS","SRB","","","11967","1688087765"
"Khem Karan","Khem Karan","31.1600","74.6600","India","IN","IND","Punjab","","11938","1356049560"
"Hnivan","Hnivan","49.0833","28.3500","Ukraine","UA","UKR","Vinnytska Oblast","","12314","1804579295"
"Shady Hills","Shady Hills","28.4042","-82.5468","United States","US","USA","Florida","","12359","1840029004"
"Makhmālpur","Makhmalpur","25.2870","87.5469","India","IN","IND","Jhārkhand","","11243","1356413405"
"Te Awamutu","Te Awamutu","-38.0167","175.3167","New Zealand","NZ","NZL","Waikato","","12195","1554001222"
"Morab","Morab","15.5833","75.1667","India","IN","IND","Karnātaka","","12151","1356443568"
"Chikni","Chikni","26.0075","86.7032","India","IN","IND","Bihār","","11193","1356259965"
"Bayanaūyl","Bayanauyl","50.7889","75.6956","Kazakhstan","KZ","KAZ","","minor","12354","1398025624"
"Vijayāpati","Vijayapati","8.1913","77.7476","India","IN","IND","Tamil Nādu","","11955","1356541356"
"Titisee-Neustadt","Titisee-Neustadt","47.9122","8.2147","Germany","DE","DEU","Baden-Württemberg","","12216","1276001947"
"Egelsbach","Egelsbach","49.9694","8.6667","Germany","DE","DEU","Hesse","","11583","1276317515"
"German Flatts","German Flatts","42.9868","-74.9804","United States","US","USA","New York","","12351","1840087468"
"Saint-Avé","Saint-Ave","47.6867","-2.7344","France","FR","FRA","Bretagne","","11895","1250587503"
"Martuni","Martuni","40.1400","45.3064","Armenia","AM","ARM","Geghark’unik’","","11987","1051260753"
"Ban Tap Tao","Ban Tap Tao","19.7280","100.2939","Thailand","TH","THA","Chiang Rai","","12252","1764322223"
"Sindalakkundu","Sindalakkundu","10.3665","77.9046","India","IN","IND","Tamil Nādu","","11707","1356250198"
"Ain Kansara","Ain Kansara","34.1500","-4.8314","Morocco","MA","MAR","Fès-Meknès","","12291","1504724133"
"Talen","Talen","23.5695","76.7282","India","IN","IND","Madhya Pradesh","","11997","1356971696"
"Soklogbo","Soklogbo","7.6937","2.2673","Benin","BJ","BEN","Collines","","12278","1204257791"
"Foča","Foca","43.5000","18.7833","Bosnia and Herzegovina","BA","BIH","Srpska, Republika","minor","12334","1070603568"
"Thogapalle","Thogapalle","17.2603","80.4786","India","IN","IND","Andhra Pradesh","","11802","1356639219"
"Saādatpur Aguāni","Saadatpur Aguani","25.2830","86.7412","India","IN","IND","Bihār","","11138","1356258378"
"Crestwood","Crestwood","38.5569","-90.3783","United States","US","USA","Missouri","","12347","1840007442"
"Hanūr","Hanur","12.0874","77.3021","India","IN","IND","Karnātaka","","12213","1356220990"
"Sīpālakottai","Sipalakottai","9.8493","77.4517","India","IN","IND","Tamil Nādu","","11767","1356243272"
"Karadge","Karadge","16.4200","74.5800","India","IN","IND","Mahārāshtra","","11812","1356099967"
"Kaurihār","Kaurihar","26.9650","84.8462","India","IN","IND","Bihār","","10810","1356863660"
"Barhi","Barhi","26.5714","86.0903","India","IN","IND","Bihār","","11497","1356593160"
"Malloussa","Malloussa","35.7333","-5.6333","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12250","1504867364"
"Mallikkundam","Mallikkundam","11.8715","77.9227","India","IN","IND","Tamil Nādu","","11684","1356169320"
"Sangalbahīta","Sangalbahita","25.3295","87.3018","India","IN","IND","Bihār","","10693","1356196800"
"Wołów","Wolow","51.3414","16.6283","Poland","PL","POL","Dolnośląskie","minor","12275","1616165743"
"Rāsingapuram","Rasingapuram","9.9448","77.3362","India","IN","IND","Tamil Nādu","","11973","1356251389"
"Palmares Paulista","Palmares Paulista","-21.0828","-48.8008","Brazil","BR","BRA","São Paulo","","12336","1076440891"
"Sengurichchi","Sengurichchi","10.3756","78.1658","India","IN","IND","Tamil Nādu","","11697","1356217443"
"Mutoko","Mutoko","-17.4000","32.2167","Zimbabwe","ZW","ZWE","Mashonaland East","","12336","1716834609"
"Jequeri","Jequeri","-20.4558","-42.6658","Brazil","BR","BRA","Minas Gerais","","12315","1076092039"
"Robinson","Robinson","31.4501","-97.1201","United States","US","USA","Texas","","12336","1840020813"
"Tega Cay","Tega Cay","35.0390","-81.0111","United States","US","USA","South Carolina","","12336","1840015488"
"Meitingen","Meitingen","48.5333","10.8333","Germany","DE","DEU","Bavaria","","11936","1276827144"
"Melle","Melle","51.0000","3.8000","Belgium","BE","BEL","Flanders","","11574","1056598788"
"Cypress Lake","Cypress Lake","26.5392","-81.8999","United States","US","USA","Florida","","12334","1840029032"
"Sonāda","Sonada","27.0000","88.1400","India","IN","IND","West Bengal","","11635","1356109145"
"Tomball","Tomball","30.0951","-95.6194","United States","US","USA","Texas","","12333","1840022203"
"Bukkapatnam","Bukkapatnam","14.1997","77.8012","India","IN","IND","Andhra Pradesh","","12196","1356673632"
"Sernovodsk","Sernovodsk","43.3117","45.1594","Russia","RU","RUS","Chechnya","","12221","1643394627"
"Nurhak","Nurhak","37.9658","37.4272","Turkey","TR","TUR","Kahramanmaraş","minor","12323","1792296176"
"Węgrów","Wegrow","52.4000","22.0167","Poland","PL","POL","Mazowieckie","minor","11993","1616038840"
"Grantsville","Grantsville","40.6148","-112.4777","United States","US","USA","Utah","","12331","1840020146"
"Hatillo de Loba","Hatillo de Loba","8.9586","-74.0808","Colombia","CO","COL","Bolívar","minor","12270","1170880028"
"North Castle","North Castle","41.1331","-73.6949","United States","US","USA","New York","","12330","1840087730"
"Matina","Matina","-13.9089","-42.8489","Brazil","BR","BRA","Bahia","","12314","1076781107"
"Farciennes","Farciennes","50.4313","4.5476","Belgium","BE","BEL","Wallonia","","11247","1056870201"
"Sedriano","Sedriano","45.4833","8.9667","Italy","IT","ITA","Lombardy","","12246","1380203469"
"Hagenow","Hagenow","53.4167","11.1833","Germany","DE","DEU","Mecklenburg-Western Pomerania","","12146","1276783883"
"Udawantnagar","Udawantnagar","25.5054","84.6216","India","IN","IND","Bihār","","11135","1356962333"
"La Palma","La Palma","14.3167","-89.1667","El Salvador","SV","SLV","Chalatenango","","12235","1222549274"
"Tittachcheri","Tittachcheri","10.8674","79.7893","India","IN","IND","Tamil Nādu","","11206","1356731513"
"Anamã","Anama","-3.5800","-61.4039","Brazil","BR","BRA","Amazonas","","12320","1076345871"
"Lake City","Lake City","30.1901","-82.6471","United States","US","USA","Florida","","12325","1840015029"
"Nordstemmen","Nordstemmen","52.1605","9.7839","Germany","DE","DEU","Lower Saxony","","12123","1276522767"
"Aubière","Aubiere","45.7508","3.1108","France","FR","FRA","Auvergne-Rhône-Alpes","","10904","1250586838"
"Mulug","Mulug","18.1910","79.9430","India","IN","IND","Andhra Pradesh","","12135","1356137687"
"Uren","Uren","57.4500","45.7833","Russia","RU","RUS","Nizhegorodskaya Oblast’","","12309","1643733031"
"Belūr","Belur","11.7075","78.4144","India","IN","IND","Tamil Nādu","","11895","1356003397"
"Fairfax Station","Fairfax Station","38.7942","-77.3358","United States","US","USA","Virginia","","12322","1840024566"
"Ban Huai So Nuea","Ban Huai So Nuea","20.0536","100.2828","Thailand","TH","THA","Chiang Rai","","12239","1764115664"
"Sant’Ambrogio di Valpolicella","Sant'Ambrogio di Valpolicella","45.5209","10.8362","Italy","IT","ITA","Veneto","","11818","1380918977"
"San Pedro La Laguna","San Pedro La Laguna","14.6918","-91.2730","Guatemala","GT","GTM","Sololá","minor","11828","1320860856"
"Laren","Laren","52.2500","5.2333","Netherlands","NL","NLD","Noord-Holland","minor","11400","1528663323"
"Spilimbergo","Spilimbergo","46.1281","12.8850","Italy","IT","ITA","Friuli Venezia Giulia","","12151","1380070167"
"Mirante da Serra","Mirante da Serra","-11.0297","-62.6750","Brazil","BR","BRA","Rondônia","","12308","1076768276"
"Scotts Valley","Scotts Valley","37.0555","-122.0118","United States","US","USA","California","","12317","1840021580"
"Bibbiena","Bibbiena","43.7000","11.8167","Italy","IT","ITA","Tuscany","","12177","1380834847"
"Bodaybo","Bodaybo","57.8667","114.2000","Russia","RU","RUS","Irkutskaya Oblast’","","12316","1643017251"
"Falmouth","Falmouth","43.7476","-70.2827","United States","US","USA","Maine","","12316","1840052817"
"Barberton","Barberton","-25.7861","31.0531","South Africa","ZA","ZAF","Mpumalanga","","11997","1710775369"
"Vellār","Vellar","11.8938","77.9698","India","IN","IND","Tamil Nādu","","11691","1356169162"
"Teulada","Teulada","38.7292","0.1019","Spain","ES","ESP","Valencia","","11944","1724756789"
"Khargrām","Khargram","24.0232","87.9878","India","IN","IND","West Bengal","","11209","1356376857"
"Qorao‘zak","Qorao\`zak","43.0275","60.0125","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","12300","1860367424"
"Chainpur","Chainpur","25.0345","83.5066","India","IN","IND","Bihār","","11852","1356854153"
"Gravenhurst","Gravenhurst","44.9167","-79.3667","Canada","CA","CAN","Ontario","","12311","1124842372"
"Gatumba","Gatumba","-3.3333","29.2500","Burundi","BI","BDI","Bujumbura Rural","","11700","1108258446"
"Sākib","Sakib","32.2854","35.8113","Jordan","JO","JOR","Jarash","","11586","1400579775"
"Torri di Quartesolo","Torri di Quartesolo","45.5167","11.6167","Italy","IT","ITA","Veneto","","11684","1380825558"
"Sālamedu","Salamedu","11.9088","79.4919","India","IN","IND","Tamil Nādu","","11377","1356204683"
"Baños","Banos","-2.9000","-79.0667","Ecuador","EC","ECU","Azuay","","12271","1218450248"
"Aj Jourf","Aj Jourf","31.4903","-4.4014","Morocco","MA","MAR","Drâa-Tafilalet","","12302","1504982069"
"Tadapurambākkam","Tadapurambakkam","13.3205","80.1959","India","IN","IND","Tamil Nādu","","11551","1356912309"
"Dalippur","Dalippur","25.4222","84.3933","India","IN","IND","Bihār","","11058","1356432759"
"Staryye Atagi","Staryye Atagi","43.1126","45.7339","Russia","RU","RUS","Chechnya","","12092","1643891367"
"Lehre","Lehre","52.3167","10.6667","Germany","DE","DEU","Lower Saxony","","12135","1276041656"
"Budha Thēh","Budha Theh","31.5177","75.3009","India","IN","IND","Punjab","","11524","1356629977"
"Capdepera","Capdepera","39.7000","3.4333","Spain","ES","ESP","Balearic Islands","","12081","1724014685"
"Darauli","Darauli","26.0781","84.1314","India","IN","IND","Bihār","","10554","1356110694"
"Concepción Batres","Concepcion Batres","13.3500","-88.3667","El Salvador","SV","SLV","Usulután","","12197","1222538524"
"Lahra Muhabbat","Lahra Muhabbat","30.2421","75.1666","India","IN","IND","Punjab","","11835","1356039698"
"Mandasa","Mandasa","19.0600","84.5800","India","IN","IND","Andhra Pradesh","","11918","1356702904"
"Gandara West","Gandara West","5.9500","80.5500","Sri Lanka","LK","LKA","Southern","","12254","1144177995"
"New Mills","New Mills","53.3670","-2.0070","United Kingdom","GB","GBR","Derbyshire","","12291","1826456632"
"Enns","Enns","48.2167","14.4750","Austria","AT","AUT","Oberösterreich","","11937","1040630607"
"Marumori","Marumori","37.9114","140.7654","Japan","JP","JPN","Miyagi","","12251","1392129975"
"Opmeer","Opmeer","52.7000","4.9500","Netherlands","NL","NLD","Noord-Holland","minor","12009","1528203393"
"Fakirtaki","Fakirtaki","22.3815","88.7901","India","IN","IND","West Bengal","","10361","1356913401"
"Agcogon","Agcogon","12.0667","121.9333","Philippines","PH","PHL","Romblon","","11759","1608922039"
"Córdoba","Cordoba","9.5867","-74.8272","Colombia","CO","COL","Bolívar","minor","12266","1170365164"
"Županja","Zupanja","45.0700","18.7000","Croatia","HR","HRV","Vukovarsko-Srijemska Županija","minor","12090","1191054836"
"Pargas","Pargas","60.3000","22.3000","Finland","FI","FIN","Varsinais-Suomi","minor","12266","1246990890"
"Bucheya","Bucheya","26.3421","84.6642","India","IN","IND","Bihār","","11184","1356455116"
"Tibbar","Tibbar","31.9697","75.4159","India","IN","IND","Punjab","","11692","1356090416"
"Laichingen","Laichingen","48.4897","9.6861","Germany","DE","DEU","Baden-Württemberg","","12118","1276291281"
"Weiz","Weiz","47.2189","15.6253","Austria","AT","AUT","Steiermark","minor","11627","1040504116"
"Sakawa","Sakawa","33.5008","133.2866","Japan","JP","JPN","Kōchi","","12170","1392787592"
"Gulbahor","Gulbahor","41.0747","69.0275","Uzbekistan","UZ","UZB","Toshkent","","12100","1860823540"
"Wells","Wells","51.2094","-2.6450","United Kingdom","GB","GBR","Somerset","","10536","1826235732"
"Kibungo","Kibungo","-2.1608","30.5442","Rwanda","RW","RWA","Eastern Province","","11537","1646746155"
"Tamarana","Tamarana","-23.7228","-51.0969","Brazil","BR","BRA","Paraná","","12262","1076234264"
"Saint-Saulve","Saint-Saulve","50.3697","3.5547","France","FR","FRA","Hauts-de-France","","11353","1250173526"
"Nīdāmangalam","Nidamangalam","10.7720","79.4167","India","IN","IND","Tamil Nādu","","11523","1356192736"
"Eklahra","Eklahra","22.2036","78.6893","India","IN","IND","Madhya Pradesh","","12140","1356854210"
"Ouénou","Ouenou","9.7870","2.6375","Benin","BJ","BEN","Borgou","","12245","1204883770"
"Lissegazoun","Lissegazoun","6.6167","2.0833","Benin","BJ","BEN","Atlantique","","11856","1204896606"
"Stradella","Stradella","45.0833","9.3000","Italy","IT","ITA","Lombardy","","11637","1380130500"
"Pāta Putrela","Pata Putrela","17.0173","80.7137","India","IN","IND","Andhra Pradesh","","11981","1356605136"
"Rānko","Ranko","25.5181","86.4899","India","IN","IND","Bihār","","10587","1356290069"
"Ramabitsa","Ramabitsa","-29.7625","27.6139","Lesotho","LS","LSO","Maseru","","12234","1426883500"
"Moss Point","Moss Point","30.4241","-88.5289","United States","US","USA","Mississippi","","12282","1840015016"
"Lagoa Dourada","Lagoa Dourada","-20.9139","-44.0778","Brazil","BR","BRA","Minas Gerais","","12256","1076252761"
"Grootegast","Grootegast","53.2167","6.2833","Netherlands","NL","NLD","Groningen","minor","12143","1528352436"
"Wasilków","Wasilkow","53.2050","23.2044","Poland","PL","POL","Podlaskie","","12147","1616267315"
"Marquetalia","Marquetalia","5.3333","-75.0000","Colombia","CO","COL","Caldas","minor","12146","1170955036"
"Webster","Webster","29.5317","-95.1188","United States","US","USA","Texas","","12280","1840022204"
"El Campo","El Campo","29.2000","-96.2723","United States","US","USA","Texas","","12280","1840020966"
"Weinfelden","Weinfelden","47.5698","9.1120","Switzerland","CH","CHE","Thurgau","minor","11534","1756205526"
"Tzintzuntzán","Tzintzuntzan","19.6283","-101.5789","Mexico","MX","MEX","Michoacán","minor","12259","1484799786"
"Guntupalle","Guntupalle","16.5681","80.5475","India","IN","IND","Andhra Pradesh","","11187","1356737697"
"Razua","Razua","22.0529","93.4086","Myanmar","MM","MMR","Chin State","","12265","1104486868"
"Zeuthen","Zeuthen","52.3667","13.6167","Germany","DE","DEU","Brandenburg","","11381","1276555681"
"Teus","Teus","25.2493","85.7141","India","IN","IND","Bihār","","10651","1356436360"
"Conneaut","Conneaut","41.9275","-80.5685","United States","US","USA","Ohio","","12276","1840003337"
"Beatrice","Beatrice","40.2736","-96.7455","United States","US","USA","Nebraska","","12276","1840001352"
"Rottofreno","Rottofreno","45.0579","9.5489","Italy","IT","ITA","Emilia-Romagna","","12220","1380313271"
"Lauffen am Neckar","Lauffen am Neckar","49.0833","9.1500","Germany","DE","DEU","Baden-Württemberg","","11755","1276145495"
"Kenduadīh","Kenduadih","23.7757","86.3761","India","IN","IND","Jhārkhand","","11033","1356281373"
"Prattipādu","Prattipadu","17.2333","82.2000","India","IN","IND","Andhra Pradesh","","11876","1356067201"
"Leingarten","Leingarten","49.1500","9.1167","Germany","DE","DEU","Baden-Württemberg","","11772","1276343480"
"Port Washington","Port Washington","43.3846","-87.8855","United States","US","USA","Wisconsin","","12273","1840002826"
"Pinneli","Pinneli","16.5689","79.8334","India","IN","IND","Andhra Pradesh","","12000","1356043180"
"Emboscada","Emboscada","-25.1233","-57.3553","Paraguay","PY","PRY","Cordillera","","12225","1600056650"
"Upper Uwchlan","Upper Uwchlan","40.0817","-75.7070","United States","US","USA","Pennsylvania","","12270","1840145646"
"Ambatomasina","Ambatomasina","-18.7333","47.4500","Madagascar","MG","MDG","Antananarivo","","11000","1450692474"
"Chennūr","Chennur","14.1554","79.8186","India","IN","IND","Andhra Pradesh","","11542","1356397910"
"Tortoreto","Tortoreto","42.8000","13.9167","Italy","IT","ITA","Abruzzo","","11757","1380008251"
"Arth","Arth","47.0644","8.5242","Switzerland","CH","CHE","Schwyz","","11983","1756840226"
"Antardipa","Antardipa","24.6442","87.9213","India","IN","IND","West Bengal","","10610","1356686856"
"Vegarai","Vegarai","11.0903","77.9679","India","IN","IND","Tamil Nādu","","11784","1356167524"
"Burladingen","Burladingen","48.2903","9.1094","Germany","DE","DEU","Baden-Württemberg","","12169","1276159850"
"Taurisano","Taurisano","39.9500","18.1667","Italy","IT","ITA","Puglia","","11770","1380474731"
"Antônio Cardoso","Antonio Cardoso","-12.4350","-39.1200","Brazil","BR","BRA","Bahia","","12225","1076384756"
"Tholey","Tholey","49.4833","7.0333","Germany","DE","DEU","Saarland","","12059","1276082414"
"Ocean Pines","Ocean Pines","38.3851","-75.1487","United States","US","USA","Maryland","","12266","1840006252"
"Sedro-Woolley","Sedro-Woolley","48.5112","-122.2321","United States","US","USA","Washington","","12266","1840021066"
"Essenbach","Essenbach","48.6167","12.2167","Germany","DE","DEU","Bavaria","","12120","1276545666"
"Villa Unión","Villa Union","-29.3000","-68.2000","Argentina","AR","ARG","La Rioja","minor","12263","1032705545"
"Maida Babhangāwān","Maida Babhangawan","25.4793","86.0669","India","IN","IND","Bihār","","10582","1356792222"
"Sathiāla","Sathiala","31.5833","75.2833","India","IN","IND","Punjab","","11486","1356397179"
"Tetagunta","Tetagunta","17.3140","82.4430","India","IN","IND","Andhra Pradesh","","11967","1356069173"
"Amarāpuuram","Amarapuuram","14.1333","76.9833","India","IN","IND","Andhra Pradesh","","11950","1356001353"
"Piripá","Piripa","-14.9400","-41.7200","Brazil","BR","BRA","Bahia","","12238","1076603542"
"Casièr","Casier","45.6500","12.3000","Italy","IT","ITA","Veneto","","11412","1380873159"
"Dharmasāgaram","Dharmasagaram","17.9933","79.4428","India","IN","IND","Andhra Pradesh","","11957","1356379504"
"Perth East","Perth East","43.4700","-80.9500","Canada","CA","CAN","Ontario","","12261","1124001760"
"Strzelin","Strzelin","50.7833","17.0667","Poland","PL","POL","Dolnośląskie","minor","12131","1616993710"
"Vecchiano","Vecchiano","43.7833","10.3833","Italy","IT","ITA","Tuscany","","12082","1380000131"
"Velddrif","Velddrif","-32.7833","18.1667","South Africa","ZA","ZAF","Western Cape","","11017","1710031295"
"Citlaltépec","Citlaltepec","21.3366","-97.8784","Mexico","MX","MEX","Veracruz","minor","12109","1484014771"
"Götzis","Gotzis","47.3342","9.6453","Austria","AT","AUT","Vorarlberg","","11473","1040768534"
"Bharwelī","Bharweli","21.8373","80.2271","India","IN","IND","Madhya Pradesh","","12103","1356701708"
"Belén","Belen","-27.6500","-67.0333","Argentina","AR","ARG","Catamarca","minor","12252","1032969950"
"Kampenhout","Kampenhout","50.9413","4.5496","Belgium","BE","BEL","Flanders","","11898","1056286425"
"Alucra","Alucra","40.3167","38.7667","Turkey","TR","TUR","Giresun","minor","12250","1792565216"
"Oberschleißheim","Oberschleissheim","48.2500","11.5667","Germany","DE","DEU","Bavaria","","11864","1276407892"
"Morūr","Morur","11.4221","77.8743","India","IN","IND","Tamil Nādu","","11585","1356166748"
"Botlikh","Botlikh","42.6650","46.2200","Russia","RU","RUS","Dagestan","","12159","1643458824"
"Jaguapitã","Jaguapita","-23.1128","-51.5319","Brazil","BR","BRA","Paraná","","12225","1076089933"
"Kathu","Kathu","-27.7000","23.0500","South Africa","ZA","ZAF","Northern Cape","","11510","1710811489"
"Bībīpet","Bibipet","18.2101","78.5221","India","IN","IND","Andhra Pradesh","","11840","1356383882"
"Palestina","Palestina","-20.3900","-49.4328","Brazil","BR","BRA","São Paulo","","12231","1076718506"
"Na Wa","Na Wa","17.4692","104.1020","Thailand","TH","THA","Nakhon Phanom","minor","12160","1764746729"
"Market Drayton","Market Drayton","52.9044","-2.4848","United Kingdom","GB","GBR","Staffordshire","","11773","1826166610"
"Darmahā","Darmaha","26.3663","84.9312","India","IN","IND","Bihār","","10802","1356018565"
"Sonbarsa","Sonbarsa","26.8474","85.5804","India","IN","IND","Bihār","","11283","1356526668"
"Khānsāhibpuram","Khansahibpuram","9.6304","77.6028","India","IN","IND","Tamil Nādu","","11774","1356216840"
"Phulgāchhi","Phulgachhi","26.3273","88.2792","India","IN","IND","Bihār","","11113","1356746186"
"Woodward","Woodward","36.4247","-99.4058","United States","US","USA","Oklahoma","","12246","1840021636"
"Boloso","Boloso","2.0333","15.2000","Congo (Brazzaville)","CG","COG","Sangha","","12244","1178777874"
"Penne","Penne","42.4500","13.9167","Italy","IT","ITA","Abruzzo","","12113","1380329622"
"Mirik","Mirik","26.8870","88.1870","India","IN","IND","West Bengal","","11513","1356796096"
"Vulcăneşti","Vulcanesti","45.6833","28.4028","Moldova","MD","MDA","Găgăuzia","","12185","1498275011"
"Atripalda","Atripalda","40.9167","14.8256","Italy","IT","ITA","Campania","","10968","1380784790"
"Saubara","Saubara","-12.7378","-38.7689","Brazil","BR","BRA","Bahia","","12238","1076713805"
"Torroella de Montgrí","Torroella de Montgri","42.0439","3.1286","Spain","ES","ESP","Catalonia","","12061","1724010202"
"Hamilton Square","Hamilton Square","40.2248","-74.6526","United States","US","USA","New Jersey","","12244","1840024324"
"Porto Valter","Porto Valter","-8.2689","-72.7439","Brazil","BR","BRA","Acre","","12241","1076950959"
"Miller Place","Miller Place","40.9374","-72.9864","United States","US","USA","New York","","12243","1840005046"
"Accokeek","Accokeek","38.6745","-77.0023","United States","US","USA","Maryland","","12241","1840005943"
"Lower Pottsgrove","Lower Pottsgrove","40.2537","-75.5975","United States","US","USA","Pennsylvania","","12239","1840142133"
"Sapna","Sapna","44.4917","19.0028","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","12136","1070454621"
"Dumri","Dumri","25.6243","84.1809","India","IN","IND","Bihār","","11047","1356057030"
"Ngou","Ngou","5.2000","10.3833","Cameroon","CM","CMR","Ouest","","12000","1120597405"
"Paulista","Paulista","-6.5939","-37.6239","Brazil","BR","BRA","Paraíba","","12216","1076002396"
"Presidencia de la Plaza","Presidencia de la Plaza","-26.9833","-59.8333","Argentina","AR","ARG","Chaco","minor","12231","1032479033"
"Fómeque","Fomeque","4.4847","-73.8936","Colombia","CO","COL","Cundinamarca","minor","12214","1170945629"
"Sokouhoué","Sokouhoue","6.9000","1.6667","Benin","BJ","BEN","Couffo","","11998","1204283045"
"Dăbuleni","Dabuleni","43.8011","24.0919","Romania","RO","ROU","Dolj","","12175","1642780769"
"Yakoma","Yakoma","4.0982","22.4432","Congo (Kinshasa)","CD","COD","Nord-Ubangi","","12210","1180632342"
"Laukāha","Laukaha","26.0336","86.6471","India","IN","IND","Bihār","","10439","1356259512"
"Santa Elena","Santa Elena","14.0833","-88.1167","Honduras","HN","HND","La Paz","","12162","1340947975"
"Aleksandrovsk","Aleksandrovsk","59.1667","57.6000","Russia","RU","RUS","Permskiy Kray","","12222","1643050793"
"Guaraniaçu","Guaraniacu","-25.1008","-52.8778","Brazil","BR","BRA","Paraná","","12217","1076212519"
"Madison","Madison","38.7581","-85.3973","United States","US","USA","Indiana","","12227","1840009728"
"Château-Gontier","Chateau-Gontier","47.8286","-0.7028","France","FR","FRA","Pays de la Loire","minor","11805","1250935471"
"Gopālapuram","Gopalapuram","17.1007","81.5406","India","IN","IND","Andhra Pradesh","","11573","1356393154"
"Diré","Dire","16.2667","-3.4000","Mali","ML","MLI","Tombouctou","minor","12092","1466571290"
"Kālipatnam","Kalipatnam","16.3904","81.5295","India","IN","IND","Andhra Pradesh","","11839","1356970385"
"Bampūr","Bampur","27.1944","60.4558","Iran","IR","IRN","Sīstān va Balūchestān","","12217","1364531120"
"Antônio Gonçalves","Antonio Goncalves","-10.5728","-40.2739","Brazil","BR","BRA","Bahia","","12187","1076966898"
"Pūvalūr","Puvalur","10.9003","78.8308","India","IN","IND","Tamil Nādu","","11755","1356205510"
"Benoy","Benoy","8.9908","16.3169","Chad","TD","TCD","Logone Occidental","","12097","1148969711"
"Korb","Korb","48.8417","9.3611","Germany","DE","DEU","Baden-Württemberg","","10942","1276683621"
"West Deer","West Deer","40.6351","-79.8693","United States","US","USA","Pennsylvania","","12223","1840147889"
"Parkes","Parkes","-33.1330","148.1760","Australia","AU","AUS","New South Wales","","11408","1036977270"
"Biknūr","Biknur","18.2150","78.4367","India","IN","IND","Andhra Pradesh","","11787","1356034364"
"New Kensington","New Kensington","40.5712","-79.7523","United States","US","USA","Pennsylvania","","12222","1840001151"
"Wolgast","Wolgast","54.0500","13.7667","Germany","DE","DEU","Mecklenburg-Western Pomerania","","12028","1276669762"
"Pasca","Pasca","4.3075","-74.3008","Colombia","CO","COL","Cundinamarca","minor","12175","1170537329"
"Lewisboro","Lewisboro","41.2697","-73.5828","United States","US","USA","New York","","12221","1840058242"
"Krasnyy Yar","Krasnyy Yar","46.5331","48.3456","Russia","RU","RUS","Astrakhanskaya Oblast’","","12214","1643330476"
"Mulungu do Morro","Mulungu do Morro","-11.9658","-41.6389","Brazil","BR","BRA","Bahia","","12200","1076271115"
"Nova Trento","Nova Trento","-27.2867","-48.9303","Brazil","BR","BRA","Santa Catarina","","12190","1076918111"
"Pettaivāyttalai","Pettaivayttalai","10.9014","78.4940","India","IN","IND","Tamil Nādu","","11571","1356226621"
"Abertillery","Abertillery","51.7300","-3.1300","United Kingdom","GB","GBR","Blaenau Gwent","","11601","1826675845"
"Prince Rupert","Prince Rupert","54.3122","-130.3271","Canada","CA","CAN","British Columbia","","12220","1124847707"
"Andrelândia","Andrelandia","-21.7400","-44.3089","Brazil","BR","BRA","Minas Gerais","","12206","1076721211"
"Effingham","Effingham","39.1205","-88.5509","United States","US","USA","Illinois","","12218","1840008545"
"Fourmies","Fourmies","50.0172","4.0533","France","FR","FRA","Hauts-de-France","","11714","1250539505"
"Ārutla","Arutla","17.1346","78.7574","India","IN","IND","Andhra Pradesh","","11997","1356994501"
"Gundrājukuppam","Gundrajukuppam","13.3406","79.5687","India","IN","IND","Andhra Pradesh","","11395","1356127506"
"Burnham","Burnham","51.5400","-0.6600","United Kingdom","GB","GBR","Buckinghamshire","","11630","1826725150"
"Jefferson Hills","Jefferson Hills","40.2926","-79.9329","United States","US","USA","Pennsylvania","","12215","1840001280"
"Santa Leopoldina","Santa Leopoldina","-20.1006","-40.5297","Brazil","BR","BRA","Espírito Santo","","12197","1076008776"
"Dinkelsbühl","Dinkelsbuhl","49.0708","10.3194","Germany","DE","DEU","Bavaria","","12053","1276514661"
"Ōsako","Osako","31.4292","131.0059","Japan","JP","JPN","Kagoshima","","12092","1392990377"
"Ivoamba","Ivoamba","-21.4000","47.1667","Madagascar","MG","MDG","Fianarantsoa","","12000","1450902002"
"Canóvanas","Canovanas","18.3693","-65.9009","Puerto Rico","PR","PRI","Puerto Rico","","12211","1630023717"
"Ielmo Marinho","Ielmo Marinho","-5.8239","-35.5528","Brazil","BR","BRA","Rio Grande do Norte","","12171","1076424391"
"Dendulūru","Denduluru","16.7606","81.1642","India","IN","IND","Andhra Pradesh","","11846","1356619160"
"Chinnatadāgam","Chinnatadagam","11.0816","76.8674","India","IN","IND","Tamil Nādu","","10924","1356150509"
"Rovinari","Rovinari","44.9125","23.1622","Romania","RO","ROU","Gorj","","11816","1642920797"
"Salgado de São Félix","Salgado de Sao Felix","-7.3569","-35.4408","Brazil","BR","BRA","Paraíba","","12144","1076169132"
"Saint-Gaudens","Saint-Gaudens","43.1081","0.7233","France","FR","FRA","Occitanie","minor","11773","1250649405"
"Mettlach","Mettlach","49.4917","6.5972","Germany","DE","DEU","Saarland","","12049","1276284656"
"Hārohalli","Harohalli","12.3204","76.7816","India","IN","IND","Karnātaka","","10412","1356800005"
"Lobería","Loberia","-38.1333","-58.7667","Argentina","AR","ARG","Buenos Aires","minor","12199","1032802362"
"Piprāhi","Piprahi","26.5871","86.3773","India","IN","IND","Bihār","","11509","1356086447"
"Puerto Nare","Puerto Nare","6.1917","-74.5867","Colombia","CO","COL","Antioquia","minor","12161","1170388042"
"Ebéjico","Ebejico","6.3333","-75.7667","Colombia","CO","COL","Antioquia","minor","12158","1170232093"
"Santa Cruz Michapa","Santa Cruz Michapa","13.7333","-88.9667","El Salvador","SV","SLV","Cuscatlán","","11790","1222002507"
"Portoferraio","Portoferraio","42.8167","10.3167","Italy","IT","ITA","Tuscany","","11955","1380962428"
"Unguía","Unguia","8.0500","-77.1000","Colombia","CO","COL","Chocó","minor","12192","1170526283"
"Saclepea","Saclepea","7.1167","-8.8333","Liberia","LR","LBR","Nimba","","12117","1430824666"
"Bellegarde-sur-Valserine","Bellegarde-sur-Valserine","46.1075","5.8258","France","FR","FRA","Auvergne-Rhône-Alpes","","11450","1250265949"
"Anjanazana","Anjanazana","-15.3833","49.7500","Madagascar","MG","MDG","Toamasina","","12000","1450456490"
"Pinheiro Machado","Pinheiro Machado","-31.5778","-53.3808","Brazil","BR","BRA","Rio Grande do Sul","","12195","1076070054"
"Jitwārpur Chauth","Jitwarpur Chauth","25.8499","85.8074","India","IN","IND","Bihār","","10829","1356211137"
"Santo Domingo","Santo Domingo","12.2631","-85.0822","Nicaragua","NI","NIC","Chontales","minor","12182","1558352804"
"Kalaidasht","Kalaidasht","38.6333","69.5000","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","12074","1762520076"
"Pacé","Pace","48.1478","-1.7739","France","FR","FRA","Bretagne","","11860","1250938407"
"Isola del Liri","Isola del Liri","41.6794","13.5728","Italy","IT","ITA","Lazio","","11482","1380391710"
"Suchanino","Suchanino","54.3566","18.6056","Poland","PL","POL","Pomorskie","","10392","1616723599"
"Cuers","Cuers","43.2375","6.0708","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11962","1250977777"
"Zhengdong","Zhengdong","22.4871","101.5081","China","CN","CHN","Yunnan","","12185","1156434738"
"Maromiandra","Maromiandra","-21.6833","47.5167","Madagascar","MG","MDG","Fianarantsoa","","12000","1450367644"
"Neuenrade","Neuenrade","51.2839","7.7800","Germany","DE","DEU","North Rhine-Westphalia","","11982","1276329110"
"Appingedam","Appingedam","53.3167","6.8667","Netherlands","NL","NLD","Groningen","minor","11721","1528708475"
"New Britain","New Britain","40.3084","-75.2070","United States","US","USA","Pennsylvania","","12197","1840001327"
"Ibiraci","Ibiraci","-20.4619","-47.1219","Brazil","BR","BRA","Minas Gerais","","12176","1076264003"
"Rocca Priora","Rocca Priora","41.7833","12.7667","Italy","IT","ITA","Lazio","","12060","1380371856"
"Richland","Richland","40.2842","-78.8449","United States","US","USA","Pennsylvania","","12195","1840143652"
"Lagoa do Ouro","Lagoa do Ouro","-9.1269","-36.4589","Brazil","BR","BRA","Pernambuco","","12132","1076612493"
"Zlatograd","Zlatograd","41.3833","25.1000","Bulgaria","BG","BGR","Smolyan","minor","12087","1100099994"
"Manchester","Manchester","37.4902","-77.5396","United States","US","USA","Virginia","","12192","1840024785"
"Pedda Adsarlapalli","Pedda Adsarlapalli","16.7086","79.0286","India","IN","IND","Andhra Pradesh","","11925","1356861679"
"Lonate Pozzolo","Lonate Pozzolo","45.6000","8.7500","Italy","IT","ITA","Lombardy","","11786","1380295978"
"Kornepādu","Kornepadu","16.2444","80.3857","India","IN","IND","Andhra Pradesh","","11758","1356305623"
"Saint-Pierre-du-Perray","Saint-Pierre-du-Perray","48.6131","2.4953","France","FR","FRA","Île-de-France","","11220","1250970756"
"As Sars","As Sars","36.0833","9.0167","Tunisia","TN","TUN","Kef","","12137","1788457626"
"Rijkevorsel","Rijkevorsel","51.3500","4.7667","Belgium","BE","BEL","Flanders","","11932","1056525868"
"Highlands","Highlands","41.3601","-74.0084","United States","US","USA","New York","","12187","1840087533"
"Brattleboro","Brattleboro","42.8619","-72.6145","United States","US","USA","Vermont","","12187","1840070391"
"Tiszakécske","Tiszakecske","46.9312","20.0951","Hungary","HU","HUN","Bács-Kiskun","minor","12096","1348645870"
"Coventry","Coventry","41.7828","-72.3394","United States","US","USA","Connecticut","","12186","1840034370"
"Tanakallu","Tanakallu","13.9198","78.1950","India","IN","IND","Andhra Pradesh","","11908","1356904942"
"Hātod","Hatod","22.7938","75.7393","India","IN","IND","Madhya Pradesh","","11916","1356126953"
"Villamartín","Villamartin","36.8667","-5.6500","Spain","ES","ESP","Andalusia","","12125","1724057832"
"Jerome","Jerome","42.7179","-114.5159","United States","US","USA","Idaho","","12182","1840018680"
"Arganil","Arganil","40.2180","-8.0540","Portugal","PT","PRT","Coimbra","minor","12145","1620980524"
"Fuensalida","Fuensalida","40.0500","-4.2000","Spain","ES","ESP","Castille-La Mancha","","12004","1724253725"
"Kozloduy","Kozloduy","43.7833","23.7333","Bulgaria","BG","BGR","Vratsa","minor","12067","1100662672"
"Ban Bo Luang","Ban Bo Luang","18.1476","98.3489","Thailand","TH","THA","Chiang Mai","","12143","1764468755"
"Ban Noen Phoem","Ban Noen Phoem","17.1167","100.8667","Thailand","TH","THA","Phitsanulok","","12136","1764544563"
"Mettingen","Mettingen","52.3167","7.7806","Germany","DE","DEU","North Rhine-Westphalia","","11883","1276290048"
"Ilmajoki","Ilmajoki","62.7333","22.5833","Finland","FI","FIN","Etelä-Pohjanmaa","minor","12159","1246721686"
"Eldorado","Eldorado","-23.7869","-54.2839","Brazil","BR","BRA","Mato Grosso do Sul","","12176","1076329907"
"Mālthone","Malthone","24.3055","78.5283","India","IN","IND","Madhya Pradesh","","11968","1356110848"
"Zwönitz","Zwonitz","50.6167","12.8000","Germany","DE","DEU","Saxony","","11993","1276062398"
"Betmangala","Betmangala","13.0085","78.3282","India","IN","IND","Karnātaka","","11606","1356016529"
"Alken","Alken","50.8761","5.3078","Belgium","BE","BEL","Flanders","","11757","1056681067"
"Bishunpur","Bishunpur","24.7631","85.6820","India","IN","IND","Bihār","","11095","1356244585"
"Bayyanagūdem","Bayyanagudem","17.1250","81.3628","India","IN","IND","Andhra Pradesh","","11666","1356678763"
"Unterföhring","Unterfohring","48.1917","11.6528","Germany","DE","DEU","Bavaria","","11296","1276741944"
"Vylgort","Vylgort","61.6275","50.7672","Russia","RU","RUS","Komi","","12171","1643864627"
"Hirao","Hirao","33.9379","132.0732","Japan","JP","JPN","Yamaguchi","","11831","1392671270"
"Pilis","Pilis","47.2844","19.5435","Hungary","HU","HUN","Pest","","11921","1348981551"
"Chebrolu","Chebrolu","16.1967","80.5250","India","IN","IND","Andhra Pradesh","","11626","1356096733"
"Panhar","Panhar","25.0936","85.2176","India","IN","IND","Bihār","","11128","1356244823"
"Chorleywood","Chorleywood","51.6500","-0.5100","United Kingdom","GB","GBR","Hertfordshire","","11286","1826183168"
"Nový Bor","Novy Bor","50.7577","14.5557","Czechia","CZ","CZE","Liberecký Kraj","","11582","1203651923"
"Qoubaiyat","Qoubaiyat","34.5683","36.2764","Lebanon","LB","LBN","Aakkâr","","12000","1422198461"
"Prévost","Prevost","45.8700","-74.0800","Canada","CA","CAN","Quebec","","12171","1124001584"
"Theux","Theux","50.5349","5.8134","Belgium","BE","BEL","Wallonia","","12025","1056495369"
"Hōdatsushimizu","Hodatsushimizu","36.8627","136.7976","Japan","JP","JPN","Ishikawa","","12061","1392999986"
"Honeygo","Honeygo","39.4055","-76.4282","United States","US","USA","Maryland","","12169","1840147786"
"Oyón","Oyon","-10.6692","-76.7728","Peru","PE","PER","Lima","","12150","1604018001"
"Little Bookham","Little Bookham","51.2804","-0.3897","United Kingdom","GB","GBR","Surrey","","11375","1826002700"
"Waikanae","Waikanae","-40.8750","175.0639","New Zealand","NZ","NZL","Wellington","","12100","1554624715"
"Liperi","Liperi","62.5333","29.3833","Finland","FI","FIN","Pohjois-Karjala","minor","12150","1246184654"
"Steha","Steha","35.3460","-4.9570","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","12034","1504350850"
"Antanamalaza","Antanamalaza","-19.4000","47.6333","Madagascar","MG","MDG","Antananarivo","","12000","1450027793"
"Vengikkal","Vengikkal","12.2642","79.0675","India","IN","IND","Tamil Nādu","","11472","1356908622"
"Havixbeck","Havixbeck","51.9778","7.4167","Germany","DE","DEU","North Rhine-Westphalia","","11940","1276193299"
"Moparipālaiyam","Moparipalaiyam","11.1332","77.1470","India","IN","IND","Tamil Nādu","","11526","1356160042"
"El Sobrante","El Sobrante","33.8724","-117.4624","United States","US","USA","California","","12163","1840075804"
"Sahoria Subhai","Sahoria Subhai","25.9028","87.0421","India","IN","IND","Bihār","","10878","1356278574"
"Malhador","Malhador","-10.6578","-37.3050","Brazil","BR","BRA","Sergipe","","12042","1076612733"
"Dora","Dora","22.1221","88.6143","India","IN","IND","West Bengal","","10275","1356328027"
"Guebwiller","Guebwiller","47.9075","7.2103","France","FR","FRA","Grand Est","","11022","1250994846"
"Tranent","Tranent","55.9450","-2.9540","United Kingdom","GB","GBR","East Lothian","","12140","1826125667"
"Mitchellville","Mitchellville","38.9358","-76.8146","United States","US","USA","Maryland","","12160","1840005951"
"Albox","Albox","37.3833","-2.1333","Spain","ES","ESP","Andalusia","","12087","1724868693"
"Roda","Roda","30.6820","75.0195","India","IN","IND","Punjab","","11784","1356068484"
"Allūr","Allur","14.6800","80.0600","India","IN","IND","Andhra Pradesh","","11768","1356190180"
"Sisia","Sisia","25.4539","87.4549","India","IN","IND","Bihār","","10925","1356050103"
"Faradābād","Faradabad","23.7445","90.8772","Bangladesh","BD","BGD","Chattogram","","10381","1050280343"
"Ganapavaram","Ganapavaram","16.7000","81.4667","India","IN","IND","Andhra Pradesh","","11749","1356250211"
"Nußloch","Nussloch","49.3236","8.6939","Germany","DE","DEU","Baden-Württemberg","","11325","1276967594"
"San José El Ídolo","San Jose El Idolo","14.4500","-91.4167","Guatemala","GT","GTM","Suchitepéquez","minor","12017","1320054644"
"Beryslav","Beryslav","46.8333","33.4167","Ukraine","UA","UKR","Khersonska Oblast","minor","12123","1804296510"
"Nova Gradiška","Nova Gradiska","45.2500","17.3833","Croatia","HR","HRV","Brodsko-Posavska Županija","minor","11821","1191025176"
"Iwanai","Iwanai","42.9798","140.5146","Japan","JP","JPN","Hokkaidō","","11982","1392003426"
"Planegg","Planegg","48.1047","11.4306","Germany","DE","DEU","Bavaria","","11126","1276760945"
"Portales","Portales","34.1754","-103.3565","United States","US","USA","New Mexico","","12151","1840020529"
"Pasłęk","Paslek","54.0500","19.6667","Poland","PL","POL","Warmińsko-Mazurskie","","12074","1616525306"
"São Francisco do Maranhão","Sao Francisco do Maranhao","-6.2508","-42.8569","Brazil","BR","BRA","Maranhão","","12146","1076582179"
"Nariman","Nariman","40.5972","72.7972","Kyrgyzstan","KG","KGZ","Osh","","12021","1417394679"
"Bad Vöslau","Bad Voslau","47.9669","16.2144","Austria","AT","AUT","Niederösterreich","","11844","1040153986"
"Eura","Eura","61.1333","22.0833","Finland","FI","FIN","Satakunta","minor","12128","1246784292"
"Schwieberdingen","Schwieberdingen","48.8778","9.0750","Germany","DE","DEU","Baden-Württemberg","","11383","1276325701"
"New Baltimore","New Baltimore","42.6904","-82.7398","United States","US","USA","Michigan","","12147","1840003090"
"Jītpur","Jitpur","26.8149","84.9578","India","IN","IND","Bihār","","10228","1356058799"
"Jericó","Jerico","5.7833","-75.7833","Colombia","CO","COL","Antioquia","minor","12103","1170373171"
"Jardim do Seridó","Jardim do Serido","-6.5839","-36.7739","Brazil","BR","BRA","Rio Grande do Norte","","12113","1076974092"
"Justo Daract","Justo Daract","-33.8667","-65.1667","Argentina","AR","ARG","San Luis","","12135","1032088229"
"Boskovice","Boskovice","49.4875","16.6600","Czechia","CZ","CZE","Jihomoravský Kraj","","11726","1203299524"
"Kőszeg","Koszeg","47.3819","16.5522","Hungary","HU","HUN","Vas","minor","11927","1348854812"
"Minden","Minden","32.6187","-93.2762","United States","US","USA","Louisiana","","12145","1840014864"
"Ajjanahalli","Ajjanahalli","12.0376","77.8138","India","IN","IND","Tamil Nādu","","11899","1356313296"
"Kotha Gurū","Kotha Guru","30.4419","75.0948","India","IN","IND","Punjab","","11767","1356858408"
"Wyndham","Wyndham","37.6924","-77.6123","United States","US","USA","Virginia","","12143","1840006396"
"Jacinto","Jacinto","-16.1439","-40.2928","Brazil","BR","BRA","Minas Gerais","","12134","1076711631"
"Kanhāipur","Kanhaipur","25.4542","85.8396","India","IN","IND","Bihār","","11274","1356509493"
"Sontha","Sontha","26.1861","87.7766","India","IN","IND","Bihār","","10917","1356102308"
"Bernissart","Bernissart","50.4833","3.6500","Belgium","BE","BEL","Wallonia","","11868","1056243222"
"Mayate","Mayate","32.2667","-7.5167","Morocco","MA","MAR","Marrakech-Safi","","12101","1504516626"
"Denkendorf","Denkendorf","48.6958","9.3175","Germany","DE","DEU","Baden-Württemberg","","11277","1276329387"
"Gulgam","Gulgam","34.5500","74.2173","India","IN","IND","Jammu and Kashmīr","","11556","1356407392"
"Basaithi","Basaithi","26.0284","87.3329","India","IN","IND","Bihār","","11076","1356200085"
"Khamānon Kalān","Khamanon Kalan","30.8200","76.3500","India","IN","IND","Punjab","","11671","1356562278"
"Corbas","Corbas","45.6681","4.9019","France","FR","FRA","Auvergne-Rhône-Alpes","","11196","1250001738"
"Tabapuã","Tabapua","-20.9639","-49.0319","Brazil","BR","BRA","São Paulo","","12103","1076931996"
"Sainte-Adèle","Sainte-Adele","45.9500","-74.1300","Canada","CA","CAN","Quebec","","12137","1124439200"
"Ampasimbe","Ampasimbe","-16.8167","49.0000","Madagascar","MG","MDG","Toamasina","","12122","1450702079"
"Capela do Alto Alegre","Capela do Alto Alegre","-11.6678","-39.8378","Brazil","BR","BRA","Bahia","","12118","1076582203"
"Halstead","Halstead","51.9451","0.6411","United Kingdom","GB","GBR","Essex","","11906","1826328876"
"Streator","Streator","41.1245","-88.8297","United States","US","USA","Illinois","","12135","1840009271"
"Nosiarina","Nosiarina","-14.2167","50.0500","Madagascar","MG","MDG","Antsiranana","","12000","1450445188"
"Malkā","Malka","32.6653","35.7333","Jordan","JO","JOR","Irbid","","11706","1400950108"
"Pulsano","Pulsano","40.3833","17.3500","Italy","IT","ITA","Puglia","","11504","1380854863"
"Sorombo","Sorombo","-22.1000","47.9167","Madagascar","MG","MDG","Fianarantsoa","","12000","1450860360"
"Edgewood","Edgewood","47.2309","-122.2832","United States","US","USA","Washington","","12132","1840019852"
"Babhnoul","Babhnoul","25.3202","84.2128","India","IN","IND","Bihār","","10746","1356089511"
"Berlaar","Berlaar","51.1167","4.6500","Belgium","BE","BEL","Flanders","","11656","1056259824"
"Tábua","Tabua","40.3603","-8.0292","Portugal","PT","PRT","Coimbra","minor","12071","1620014800"
"Islamey","Islamey","43.6756","43.4550","Russia","RU","RUS","Kabardino-Balkariya","","12001","1643756020"
"Lewisburg","Lewisburg","35.4510","-86.7901","United States","US","USA","Tennessee","","12131","1840014544"
"Annappes","Annappes","50.6269","3.1497","France","FR","FRA","Hauts-de-France","","11618","1250359981"
"Hagfors","Hagfors","60.0333","13.6500","Sweden","SE","SWE","Värmland","minor","12125","1752937511"
"Belampona","Belampona","-14.6833","49.5167","Madagascar","MG","MDG","Antsiranana","","11976","1450137921"
"Socuéllamos","Socuellamos","39.2933","-2.7942","Spain","ES","ESP","Castille-La Mancha","","12098","1724254841"
"Mostardas","Mostardas","-31.1069","-50.9208","Brazil","BR","BRA","Rio Grande do Sul","","12124","1076486273"
"Choctaw","Choctaw","35.4802","-97.2666","United States","US","USA","Oklahoma","","12130","1840019152"
"Bajiao","Bajiao","27.6573","108.1937","China","CN","CHN","Guizhou","","11745","1156097838"
"Cherlak","Cherlak","54.1605","74.8200","Russia","RU","RUS","Omskaya Oblast’","","12122","1643336296"
"Rosdorf","Rosdorf","51.5000","9.9000","Germany","DE","DEU","Lower Saxony","","11949","1276587321"
"Antaritarika","Antaritarika","-25.4000","45.7500","Madagascar","MG","MDG","Toliara","","12000","1450216620"
"Zetel","Zetel","53.4197","7.9742","Germany","DE","DEU","Lower Saxony","","11981","1276995932"
"Lakhipur","Lakhipur","26.3281","88.3863","India","IN","IND","West Bengal","","11383","1356040797"
"Bolaños de Calatrava","Bolanos de Calatrava","38.8831","-3.7167","Spain","ES","ESP","Castille-La Mancha","","11992","1724659404"
"Ichinomiya","Ichinomiya","35.3667","140.3667","Japan","JP","JPN","Chiba","","11622","1392597662"
"Ponte da Barca","Ponte da Barca","41.8000","-8.4167","Portugal","PT","PRT","Viana do Castelo","minor","12061","1620519225"
"Middleburg","Middleburg","30.0502","-81.9011","United States","US","USA","Florida","","12127","1840013998"
"Ambohimandroso","Ambohimandroso","-21.8833","46.9500","Madagascar","MG","MDG","Fianarantsoa","","12000","1450921754"
"Ambalavero","Ambalavero","-21.8000","47.7833","Madagascar","MG","MDG","Fianarantsoa","","12000","1450039308"
"Montrose","Montrose","56.7080","-2.4670","United Kingdom","GB","GBR","Angus","","12100","1826865974"
"Ban Pae","Ban Pae","18.2108","98.6164","Thailand","TH","THA","Chiang Mai","","12050","1764427283"
"Saint-Cyr-sur-Mer","Saint-Cyr-sur-Mer","43.1836","5.7086","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11580","1250003515"
"Perleberg","Perleberg","53.0667","11.8667","Germany","DE","DEU","Brandenburg","minor","12035","1276426476"
"Pedda Vegi","Pedda Vegi","16.8095","81.1068","India","IN","IND","Andhra Pradesh","","11846","1356351364"
"Bramhabarada","Bramhabarada","20.6683","86.0747","India","IN","IND","Odisha","","11239","1356971899"
"Stamboliyski","Stamboliyski","42.1333","24.5333","Bulgaria","BG","BGR","Plovdiv","","11327","1100303839"
"Sunkarevu","Sunkarevu","16.3904","81.8615","India","IN","IND","Andhra Pradesh","","11528","1356177873"
"Befody","Befody","-20.7667","47.9333","Madagascar","MG","MDG","Fianarantsoa","","12000","1450027552"
"Kōteshwar","Koteshwar","13.6070","74.7500","India","IN","IND","Karnātaka","","11827","1356687235"
"Archdale","Archdale","35.9032","-79.9591","United States","US","USA","North Carolina","","12117","1840013399"
"Palos Heights","Palos Heights","41.6637","-87.7958","United States","US","USA","Illinois","","12116","1840009181"
"Jhakhra","Jhakhra","25.7528","85.7474","India","IN","IND","Bihār","","10170","1356238710"
"Sogndal","Sogndal","61.2297","7.1006","Norway","NO","NOR","Vestland","minor","12097","1578132527"
"Ciudad-Rodrigo","Ciudad-Rodrigo","40.5969","-6.5392","Spain","ES","ESP","Castille-Leon","","12065","1724479453"
"Curití","Curiti","6.6667","-73.0000","Colombia","CO","COL","Santander","minor","12065","1170526729"
"Bicske","Bicske","47.4907","18.6363","Hungary","HU","HUN","Fejér","minor","11969","1348286686"
"Uzyn","Uzyn","49.8242","30.4425","Ukraine","UA","UKR","Kyivska Oblast","","12077","1804493270"
"Cernay","Cernay","47.8067","7.1758","France","FR","FRA","Grand Est","","11476","1250545081"
"Rudrāngi","Rudrangi","18.6262","78.7022","India","IN","IND","Andhra Pradesh","","11866","1356897262"
"Iwate","Iwate","39.9728","141.2122","Japan","JP","JPN","Iwate","","12078","1392003099"
"Lauria Inferiore","Lauria Inferiore","40.0500","15.8333","Italy","IT","ITA","Basilicata","","12092","1380797413"
"Son Servera","Son Servera","39.6208","3.3600","Spain","ES","ESP","Balearic Islands","","11835","1724006232"
"Auchel","Auchel","50.5083","2.4736","France","FR","FRA","Hauts-de-France","","10399","1250043382"
"Bømlo","Bomlo","59.7794","5.2183","Norway","NO","NOR","Vestland","","12061","1578231177"
"Cedarburg","Cedarburg","43.2990","-87.9887","United States","US","USA","Wisconsin","","12109","1840002825"
"Acandí","Acandi","8.5333","-77.2333","Colombia","CO","COL","Chocó","minor","12095","1170188476"
"Freeport","Freeport","10.4500","-61.4167","Trinidad and Tobago","TT","TTO","Couva/Tabaquite/Talparo","","11850","1780420196"
"Agadi","Agadi","14.8190","75.4690","India","IN","IND","Karnātaka","","11751","1356106376"
"Sassenage","Sassenage","45.2050","5.6650","France","FR","FRA","Auvergne-Rhône-Alpes","","11277","1250352112"
"Phước Long","Phuoc Long","9.4194","105.3842","Vietnam","VN","VNM","Bạc Liêu","","11957","1704723610"
"Union","Union","38.4399","-90.9927","United States","US","USA","Missouri","","12108","1840010769"
"Falimāri","Falimari","26.3856","89.8233","India","IN","IND","West Bengal","","11385","1356480147"
"Umm ar Rizam","Umm ar Rizam","32.5325","23.0053","Libya","LY","LBY","Darnah","","12098","1434030412"
"Palmácia","Palmacia","-4.1500","-38.8458","Brazil","BR","BRA","Ceará","","12005","1076617184"
"Caimito","Caimito","8.8333","-75.1667","Colombia","CO","COL","Sucre","minor","12077","1170914117"
"Wargal","Wargal","17.7751","78.6181","India","IN","IND","Andhra Pradesh","","11814","1356342591"
"Manorville","Manorville","40.8574","-72.7915","United States","US","USA","New York","","12106","1840005088"
"Blackfoot","Blackfoot","43.1940","-112.3454","United States","US","USA","Idaho","","12106","1840018650"
"San Carlos Yautepec","San Carlos Yautepec","16.5000","-96.1000","Mexico","MX","MEX","Oaxaca","minor","12101","1484274293"
"Arasūr","Arasur","11.0866","77.1146","India","IN","IND","Tamil Nādu","","11469","1356159493"
"Oiba","Oiba","6.2667","-73.3000","Colombia","CO","COL","Santander","minor","12063","1170706539"
"Carmiano","Carmiano","40.3458","18.0458","Italy","IT","ITA","Puglia","","12068","1380971729"
"Liuba","Liuba","38.1634","102.1493","China","CN","CHN","Gansu","","12069","1156132382"
"Gbanhi","Gbanhi","8.4497","2.4737","Benin","BJ","BEN","Collines","","12045","1204877914"
"Ubaporanga","Ubaporanga","-19.6350","-42.1058","Brazil","BR","BRA","Minas Gerais","","12040","1076516869"
"Kévé","Keve","6.4278","0.9245","Togo","TG","TGO","Maritime","","12000","1768316152"
"Omatjete","Omatjete","-21.0500","15.5000","Namibia","NA","NAM","Erongo","","12000","1516880666"
"Nová Dubnica","Nova Dubnica","48.9331","18.1439","Slovakia","SK","SVK","Nitra","","11134","1703517526"
"Margherita di Savoia","Margherita di Savoia","41.3667","16.1500","Italy","IT","ITA","Puglia","","11771","1380299777"
"Vosselaar","Vosselaar","51.3167","4.8833","Belgium","BE","BEL","Flanders","","11159","1056845357"
"Nonoai","Nonoai","-27.3619","-52.7708","Brazil","BR","BRA","Rio Grande do Sul","","12074","1076363344"
"Ankafina Tsarafidy","Ankafina Tsarafidy","-21.2000","47.2500","Madagascar","MG","MDG","Fianarantsoa","","12000","1450188416"
"Warka","Warka","51.7833","21.2000","Poland","PL","POL","Mazowieckie","","12003","1616212108"
"Kerāi","Kerai","25.7510","85.8586","India","IN","IND","Bihār","","10728","1356627543"
"Susegana","Susegana","45.8500","12.2500","Italy","IT","ITA","Veneto","","11831","1380261110"
"Madna","Madna","26.3963","86.3884","India","IN","IND","Bihār","","10923","1356326498"
"Glen Rock","Glen Rock","40.9601","-74.1250","United States","US","USA","New Jersey","","12098","1840003555"
"Rio do Pires","Rio do Pires","-13.1278","-42.2919","Brazil","BR","BRA","Bahia","","12084","1076703225"
"Oulad Daoud","Oulad Daoud","34.4058","-4.6939","Morocco","MA","MAR","Fès-Meknès","","11978","1504977021"
"Mādhopur Hazāri","Madhopur Hazari","26.2623","84.8995","India","IN","IND","Bihār","","10372","1356860498"
"Abhia","Abhia","25.3499","87.1427","India","IN","IND","Bihār","","11748","1356693138"
"Comarnic","Comarnic","45.2511","25.6353","Romania","RO","ROU","Prahova","","11970","1642476433"
"Tori-Cada","Tori-Cada","6.5833","2.2000","Benin","BJ","BEN","Atlantique","","11952","1204073775"
"Jacala","Jacala","21.0053","-99.1719","Mexico","MX","MEX","Hidalgo","","12057","1484527650"
"Priolo Gargallo","Priolo Gargallo","37.1667","15.1833","Italy","IT","ITA","Sicilia","","11883","1380949473"
"Mmadinare","Mmadinare","-21.8746","27.7496","Botswana","BW","BWA","Central","","12086","1072680317"
"Oqqo‘rg‘on","Oqqo\`rg\`on","40.8764","69.0453","Uzbekistan","UZ","UZB","Toshkent","","11900","1860153212"
"Seyyedān","Seyyedan","30.0042","53.0067","Iran","IR","IRN","Fārs","","12000","1364707291"
"Mataili Khemchand","Mataili Khemchand","25.5612","87.1685","India","IN","IND","Bihār","","10863","1356480907"
"Kentville","Kentville","45.0775","-64.4958","Canada","CA","CAN","Nova Scotia","","12088","1124530137"
"Ryūō","Ryuo","35.0608","136.1245","Japan","JP","JPN","Shiga","","11821","1392003376"
"Simplício Mendes","Simplicio Mendes","-7.8539","-41.9100","Brazil","BR","BRA","Piauí","","12077","1076013793"
"Baohe","Baohe","33.2033","106.9544","China","CN","CHN","Shaanxi","","11897","1156265906"
"Söderhamn","Soderhamn","61.3000","17.0833","Sweden","SE","SWE","Gävleborg","minor","11761","1752828589"
"Baxiangshan","Baxiangshan","23.7630","115.9626","China","CN","CHN","Guangdong","","12039","1156266764"
"Wichelen","Wichelen","51.0000","3.9667","Belgium","BE","BEL","Flanders","","11578","1056534647"
"Anorombato","Anorombato","-22.0167","47.7833","Madagascar","MG","MDG","Fianarantsoa","","12000","1450314536"
"Ampondra","Ampondra","-13.4167","49.9500","Madagascar","MG","MDG","Antsiranana","","12000","1450375253"
"Macedonia","Macedonia","41.3147","-81.4989","United States","US","USA","Ohio","","12083","1840000797"
"Góra Kalwaria","Gora Kalwaria","51.9733","21.2144","Poland","PL","POL","Mazowieckie","","11887","1616668959"
"Ried im Innkreis","Ried im Innkreis","48.2100","13.4894","Austria","AT","AUT","Oberösterreich","minor","11903","1040700214"
"Çüngüş","Cungus","38.2128","39.2869","Turkey","TR","TUR","Diyarbakır","minor","12061","1792972356"
"Gambettola","Gambettola","44.1167","12.3333","Italy","IT","ITA","Emilia-Romagna","","10704","1380865769"
"Edlapādu","Edlapadu","16.1686","80.2275","India","IN","IND","Andhra Pradesh","","10996","1356593881"
"Sam","Sam","11.0333","2.7333","Benin","BJ","BEN","Alibori","","12006","1204921865"
"Grafton","Grafton","43.3204","-87.9480","United States","US","USA","Wisconsin","","12081","1840002823"
"Sendrisoa","Sendrisoa","-22.0000","46.9500","Madagascar","MG","MDG","Fianarantsoa","","12000","1450761173"
"Panpuli","Panpuli","9.0214","77.2502","India","IN","IND","Tamil Nādu","","11289","1356212248"
"Gonzales","Gonzales","30.2132","-90.9234","United States","US","USA","Louisiana","","12080","1840013965"
"Atça","Atca","37.8833","28.2167","Turkey","TR","TUR","Aydın","minor","12000","1792372025"
"Chevigny-Saint-Sauveur","Chevigny-Saint-Sauveur","47.3017","5.1356","France","FR","FRA","Bourgogne-Franche-Comté","","11160","1250570439"
"Xincheng","Xincheng","36.0311","113.5580","China","CN","CHN","Shanxi","","11866","1156384249"
"Welver","Welver","51.6167","7.9583","Germany","DE","DEU","North Rhine-Westphalia","","11940","1276989258"
"Wrentham","Wrentham","42.0513","-71.3552","United States","US","USA","Massachusetts","","12077","1840053562"
"Anjahambe","Anjahambe","-17.3833","49.1333","Madagascar","MG","MDG","Toamasina","","12000","1450498223"
"Erfelek","Erfelek","41.8833","34.9167","Turkey","TR","TUR","Sinop","minor","12049","1792532648"
"Tummalacheruvu","Tummalacheruvu","16.5246","79.7988","India","IN","IND","Andhra Pradesh","","11588","1356979046"
"Wang Saphung","Wang Saphung","17.2995","101.7624","Thailand","TH","THA","Loei","minor","11992","1764106072"
"Pedappai","Pedappai","12.8854","80.0220","India","IN","IND","Tamil Nādu","","10936","1356124725"
"Bitetto","Bitetto","41.0333","16.7500","Italy","IT","ITA","Puglia","","12006","1380344722"
"Bahādarpur","Bahadarpur","21.2922","76.1806","India","IN","IND","Madhya Pradesh","","11571","1356149062"
"Cottonwood","Cottonwood","34.7195","-112.0016","United States","US","USA","Arizona","","12073","1840019168"
"Saint-Martin-Boulogne","Saint-Martin-Boulogne","50.7258","1.6322","France","FR","FRA","Hauts-de-France","","11226","1250702796"
"Al Majma‘ah","Al Majma\`ah","25.9039","45.3456","Saudi Arabia","SA","SAU","Ar Riyāḑ","","12065","1682506946"
"Snihurivka","Snihurivka","47.0708","32.7997","Ukraine","UA","UKR","Mykolaivska Oblast","minor","12045","1804969781"
"Tefam","Tefam","5.2667","10.4667","Cameroon","CM","CMR","Ouest","","12000","1120869867"
"Büdelsdorf","Budelsdorf","54.3167","9.6833","Germany","DE","DEU","Schleswig-Holstein","","10456","1276054085"
"Dang‘ara","Dang\`ara","40.5831","70.9100","Uzbekistan","UZ","UZB","Farg‘ona","minor","11500","1860878962"
"Flores de Goiás","Flores de Goias","-14.4489","-47.0500","Brazil","BR","BRA","Goiás","","12066","1076339053"
"Neustadt","Neustadt","51.0239","14.2167","Germany","DE","DEU","Saxony","","11926","1276897254"
"Jādopur Shukul","Jadopur Shukul","26.5250","84.4341","India","IN","IND","Bihār","","11254","1356833050"
"Novoīshīmskīy","Novoishimskiy","53.1981","66.7694","Kazakhstan","KZ","KAZ","","minor","12064","1398493177"
"Bhānuvalli","Bhanuvalli","14.4333","75.7667","India","IN","IND","Karnātaka","","11491","1356988746"
"Odatturai","Odatturai","11.4577","77.5150","India","IN","IND","Tamil Nādu","","11445","1356169775"
"Ronneby","Ronneby","56.2000","15.2833","Sweden","SE","SWE","Blekinge","minor","12029","1752180861"
"Oestrich-Winkel","Oestrich-Winkel","50.0000","8.0000","Germany","DE","DEU","Rhineland-Palatinate","","11869","1276526238"
"Goiatins","Goiatins","-7.7100","-47.3139","Brazil","BR","BRA","Tocantins","","12064","1076941056"
"Alfonsine","Alfonsine","44.5000","12.0500","Italy","IT","ITA","Emilia-Romagna","","11953","1380940299"
"Valley Falls","Valley Falls","41.9233","-71.3924","United States","US","USA","Rhode Island","","12064","1840003295"
"Fanambana","Fanambana","-13.5500","49.9833","Madagascar","MG","MDG","Antsiranana","","12000","1450134019"
"Ambohinamboarina","Ambohinamboarina","-21.0333","47.0500","Madagascar","MG","MDG","Fianarantsoa","","12000","1450981724"
"Souq Jamaa Fdalate","Souq Jamaa Fdalate","33.5911","-7.2792","Morocco","MA","MAR","Casablanca-Settat","","11966","1504280232"
"Villepreux","Villepreux","48.8300","2.0022","France","FR","FRA","Île-de-France","","11003","1250519010"
"Centralia","Centralia","38.5224","-89.1232","United States","US","USA","Illinois","","12061","1840007465"
"São Miguel das Matas","Sao Miguel das Matas","-13.0478","-39.4558","Brazil","BR","BRA","Bahia","","12009","1076012049"
"Mainvilliers","Mainvilliers","48.4531","1.4619","France","FR","FRA","Centre-Val de Loire","","11127","1250497934"
"Don Sak","Don Sak","9.3169","99.6944","Thailand","TH","THA","Surat Thani","minor","11934","1764466914"
"Tiruvādānai","Tiruvadanai","9.7841","78.9172","India","IN","IND","Tamil Nādu","","11750","1356227524"
"Mahazoarivo","Mahazoarivo","-21.3667","46.8167","Madagascar","MG","MDG","Fianarantsoa","","12000","1450209264"
"Alavus","Alavus","62.5917","23.6167","Finland","FI","FIN","Etelä-Pohjanmaa","minor","12044","1246444159"
"Fiume Veneto","Fiume Veneto","45.9333","12.7333","Italy","IT","ITA","Friuli Venezia Giulia","","11729","1380146382"
"Loano","Loano","44.1167","8.2500","Italy","IT","ITA","Liguria","","11224","1380950847"
"Garden City","Garden City","43.6526","-116.2743","United States","US","USA","Idaho","","12056","1840020045"
"Kissing","Kissing","48.3000","10.9833","Germany","DE","DEU","Bavaria","","11556","1276554705"
"Vaux-le-Pénil","Vaux-le-Penil","48.5264","2.6822","France","FR","FRA","Île-de-France","","11101","1250695346"
"Lajia","Lajia","34.6818","100.6386","China","CN","CHN","Qinghai","","12045","1156009995"
"Toropets","Toropets","56.5000","31.6500","Russia","RU","RUS","Tverskaya Oblast’","","12048","1643009626"
"Ipaumirim","Ipaumirim","-6.7900","-38.7189","Brazil","BR","BRA","Ceará","","12009","1076210747"
"Baitoa","Baitoa","19.3200","-70.7000","Dominican Republic","DO","DOM","Cibao Norte","","11778","1214626953"
"Dário Meira","Dario Meira","-14.4358","-39.9078","Brazil","BR","BRA","Bahia","","12022","1076782053"
"Kuangfu","Kuangfu","23.6351","121.4228","Taiwan","TW","TWN","Hualien","","11975","1158283390"
"Tlacolulan","Tlacolulan","19.6667","-97.0000","Mexico","MX","MEX","Veracruz","minor","11431","1484260116"
"Castelginest","Castelginest","43.6936","1.4328","France","FR","FRA","Occitanie","","10734","1250055280"
"Sewāi","Sewai","23.6175","85.6783","India","IN","IND","Jhārkhand","","11601","1356178881"
"Lapinig","Lapinig","12.3150","125.3020","Philippines","PH","PHL","Northern Samar","","11844","1608361312"
"Kabira","Kabira","25.6897","86.4852","India","IN","IND","Bihār","","10589","1356141047"
"Saharefo","Saharefo","-21.6667","47.6833","Madagascar","MG","MDG","Fianarantsoa","","12000","1450980044"
"Moreira Sales","Moreira Sales","-24.0619","-53.0069","Brazil","BR","BRA","Paraná","","12042","1076479147"
"Weeze","Weeze","51.6267","6.1967","Germany","DE","DEU","North Rhine-Westphalia","","11900","1276950417"
"Dąbrowa Tarnowska","Dabrowa Tarnowska","50.1667","20.9833","Poland","PL","POL","Małopolskie","minor","11549","1616908977"
"Béna","Bena","12.0804","-4.1890","Burkina Faso","BF","BFA","Boucle du Mouhoun","","11963","1854971765"
"Nedelišće","Nedelisce","46.3833","16.3833","Croatia","HR","HRV","Međimurska Županija","minor","11975","1191055882"
"Kountouri","Kountouri","10.4050","0.9425","Benin","BJ","BEN","Atacora","","11958","1204829344"
"Thiers","Thiers","45.8564","3.5475","France","FR","FRA","Auvergne-Rhône-Alpes","minor","11784","1250561791"
"Ghal Kalān","Ghal Kalan","30.8189","75.1063","India","IN","IND","Punjab","","11563","1356034842"
"Doranāla","Doranala","15.9076","79.0941","India","IN","IND","Andhra Pradesh","","11993","1356352522"
"Stony Brook","Stony Brook","40.9061","-73.1278","United States","US","USA","New York","","12048","1840005104"
"West Plains","West Plains","36.7377","-91.8680","United States","US","USA","Missouri","","12048","1840010887"
"Barai","Barai","26.3717","86.0134","India","IN","IND","Bihār","","10255","1356605319"
"Ialysós","Ialysos","36.4167","28.1500","Greece","GR","GRC","Nótio Aigaío","","11331","1300674720"
"São Pedro da Água Branca","Sao Pedro da Agua Branca","-5.0850","-48.4289","Brazil","BR","BRA","Tocantins","","12028","1076015674"
"Kājhi Hridenagar","Kajhi Hridenagar","25.9320","87.2179","India","IN","IND","Bihār","","10760","1356280293"
"Huité","Huite","14.9175","-89.7172","Guatemala","GT","GTM","Zacapa","minor","12021","1320201309"
"Wildau","Wildau","52.3167","13.6333","Germany","DE","DEU","Brandenburg","","10848","1276865619"
"Benisa","Benisa","38.7145","0.0527","Spain","ES","ESP","Valencia","","11871","1724749904"
"Hardia","Hardia","26.6184","84.8857","India","IN","IND","Bihār","","10890","1356109078"
"Beandrarezona","Beandrarezona","-14.4833","48.6833","Madagascar","MG","MDG","Mahajanga","","12000","1450161594"
"Whitburn","Whitburn","55.8621","-3.6872","United Kingdom","GB","GBR","West Lothian","","10860","1826366350"
"Beniaján","Beniajan","37.9833","-1.0667","Spain","ES","ESP","Murcia","","11233","1724448777"
"Darabani","Darabani","48.1864","26.5892","Romania","RO","ROU","Botoşani","","11948","1642289705"
"Bhangha","Bhangha","25.5780","87.2782","India","IN","IND","Bihār","","10966","1356388724"
"Jaitwār","Jaitwar","24.7320","80.8602","India","IN","IND","Madhya Pradesh","","11758","1356002068"
"Balua Rāmpur","Balua Rampur","26.7777","84.4280","India","IN","IND","Bihār","","10470","1356921488"
"Apen","Apen","53.2214","7.8097","Germany","DE","DEU","Lower Saxony","","11883","1276951356"
"Al Fayd","Al Fayd","30.6167","-8.2167","Morocco","MA","MAR","Souss-Massa","","12011","1504770478"
"Kinattukkadavu","Kinattukkadavu","10.8225","77.0196","India","IN","IND","Tamil Nādu","","11419","1356786175"
"Enniscorthy","Enniscorthy","52.5021","-6.5659","Ireland","IE","IRL","Wexford","","11381","1372938537"
"Aranda","Aranda","26.0850","84.3601","India","IN","IND","Bihār","","10604","1356705147"
"Bālupur","Balupur","25.2611","87.8947","India","IN","IND","West Bengal","","10808","1356051781"
"Ambaliha","Ambaliha","-14.4667","48.3000","Madagascar","MG","MDG","Mahajanga","","12000","1450636460"
"Villa Berthet","Villa Berthet","-27.2667","-60.4167","Argentina","AR","ARG","Chaco","minor","12029","1032895608"
"Villefranche-de-Rouergue","Villefranche-de-Rouergue","44.3525","2.0342","France","FR","FRA","Occitanie","minor","11781","1250663232"
"Weatherford","Weatherford","35.5380","-98.6853","United States","US","USA","Oklahoma","","12034","1840021725"
"Anjiamangirana I","Anjiamangirana I","-15.1667","47.7833","Madagascar","MG","MDG","Mahajanga","","12000","1450794562"
"Upper Montclair","Upper Montclair","40.8433","-74.2006","United States","US","USA","New Jersey","","12033","1840024255"
"Holalu","Holalu","15.0200","75.9500","India","IN","IND","Karnātaka","","11812","1356365780"
"Spearfish","Spearfish","44.4909","-103.8155","United States","US","USA","South Dakota","","12031","1840002374"
"Altstätten","Altstatten","47.3833","9.5500","Switzerland","CH","CHE","Sankt Gallen","minor","11730","1756529261"
"Boldājī","Boldaji","31.9383","51.0533","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","11980","1364062486"
"Farkhâna","Farkhana","35.2833","-3.0167","Morocco","MA","MAR","Oriental","","10994","1504047941"
"Villeneuve-Tolosane","Villeneuve-Tolosane","43.5236","1.3417","France","FR","FRA","Occitanie","","10050","1250556540"
"Comasagua","Comasagua","13.6333","-89.3833","El Salvador","SV","SLV","La Libertad","","11870","1222625314"
"Andonabe","Andonabe","-21.4667","47.9500","Madagascar","MG","MDG","Fianarantsoa","","12000","1450863479"
"Arkansas City","Arkansas City","37.0726","-97.0385","United States","US","USA","Kansas","","12028","1840001700"
"Maliāl","Malial","18.7000","78.9667","India","IN","IND","Andhra Pradesh","","11442","1356804328"
"Osterhofen","Osterhofen","48.7019","13.0200","Germany","DE","DEU","Bavaria","","11920","1276874172"
"Jīdigunta","Jidigunta","16.9098","81.8553","India","IN","IND","Andhra Pradesh","","11570","1356104777"
"Ambolomoty","Ambolomoty","-16.1667","46.7000","Madagascar","MG","MDG","Mahajanga","","12000","1450504467"
"Krosūru","Krosuru","16.5500","80.1333","India","IN","IND","Andhra Pradesh","","11549","1356828489"
"Benaguacil","Benaguacil","39.5933","-0.5864","Spain","ES","ESP","Valencia","","11568","1724868425"
"Abergele","Abergele","53.2800","-3.5800","United Kingdom","GB","GBR","Conwy","","10577","1826468444"
"Southwick","Southwick","54.9193","-1.4062","United Kingdom","GB","GBR","Sunderland","","10535","1826865049"
"Nong Bua","Nong Bua","15.8647","100.5858","Thailand","TH","THA","Nakhon Sawan","minor","11951","1764078338"
"Ḩukūmatī Gīzāb","Hukumati Gizab","33.3813","66.2655","Afghanistan","AF","AFG","Dāykundī","","12000","1004394458"
"Pornichet","Pornichet","47.2658","-2.3400","France","FR","FRA","Pays de la Loire","","11141","1250587730"
"Viera East","Viera East","28.2613","-80.7149","United States","US","USA","Florida","","12020","1840039118"
"Chinna Orampādu","Chinna Orampadu","14.0613","79.2671","India","IN","IND","Andhra Pradesh","","11899","1356523957"
"Cave","Cave","41.8167","12.9333","Italy","IT","ITA","Lazio","","11381","1380149501"
"Galvarino","Galvarino","-38.4000","-72.7833","Chile","CL","CHL","Araucanía","","11996","1152385949"
"Guimarães","Guimaraes","-2.1328","-44.6008","Brazil","BR","BRA","Maranhão","","11997","1076683401"
"Gladstone","Gladstone","45.3864","-122.5934","United States","US","USA","Oregon","","12017","1840019949"
"Buqkoosaar","Buqkoosaar","4.5108","44.8164","Somalia","SO","SOM","Hiiraan","","12000","1706633414"
"Tryavna","Tryavna","42.8669","25.5000","Bulgaria","BG","BGR","Gabrovo","","11978","1100037989"
"Totogalpa","Totogalpa","13.5631","-86.4917","Nicaragua","NI","NIC","Madriz","minor","11927","1558378832"
"Bara Belun","Bara Belun","23.4007","87.9733","India","IN","IND","West Bengal","","11261","1356028053"
"Urakawa","Urakawa","42.1684","142.7682","Japan","JP","JPN","Hokkaidō","","11998","1392001597"
"Washington","Washington","38.6586","-87.1591","United States","US","USA","Indiana","","12015","1840010735"
"Maromby","Maromby","-24.3500","46.5667","Madagascar","MG","MDG","Toliara","","12000","1450001423"
"Rājāram","Rajaram","18.9870","79.0844","India","IN","IND","Andhra Pradesh","","11750","1356976999"
"Bilovodsk","Bilovodsk","49.2076","39.5913","Ukraine","UA","UKR","Luhanska Oblast","minor","12000","1804703237"
"Qorashina","Qorashina","38.3394","66.5619","Uzbekistan","UZ","UZB","Qashqadaryo","minor","11900","1860549169"
"Bissorã","Bissora","12.0400","-15.4281","Guinea-Bissau","GW","GNB","Oio","","11964","1624953344"
"Ain Beida","Ain Beida","31.5850","-8.6080","Morocco","MA","MAR","Marrakech-Safi","","11978","1504673651"
"Borgaro Torinese","Borgaro Torinese","45.1500","7.6500","Italy","IT","ITA","Piedmont","","11931","1380596593"
"Bou Merdès","Bou Merdes","35.4500","10.7333","Tunisia","TN","TUN","Mahdia","","11829","1788022009"
"Bekapaika","Bekapaika","-16.7500","47.5667","Madagascar","MG","MDG","Mahajanga","","12000","1450291034"
"Andrembesoa","Andrembesoa","-20.1500","46.6500","Madagascar","MG","MDG","Antananarivo","","12000","1450749932"
"Betânia","Betania","-8.2767","-38.0339","Brazil","BR","BRA","Pernambuco","","12003","1076452583"
"Dakhrām","Dakhram","26.0542","86.1280","India","IN","IND","Bihār","","10672","1356004498"
"Riviera Beach","Riviera Beach","39.1623","-76.5257","United States","US","USA","Maryland","","12012","1840005916"
"Ghabāghib","Ghabaghib","33.1839","36.2264","Syria","SY","SYR","Dar‘ā","minor","11802","1760507267"
"Andranovao","Andranovao","-17.6167","44.0667","Madagascar","MG","MDG","Mahajanga","","12000","1450946654"
"Basibasy","Basibasy","-22.1667","43.6500","Madagascar","MG","MDG","Toliara","","12000","1450018880"
"Louvres","Louvres","49.0439","2.5053","France","FR","FRA","Île-de-France","","11037","1250936350"
"Beauharnois","Beauharnois","45.3200","-73.8700","Canada","CA","CAN","Quebec","","12011","1124880971"
"Les Îles-de-la-Madeleine","Les Iles-de-la-Madeleine","47.3833","-61.8667","Canada","CA","CAN","Quebec","","12010","1124000721"
"Coussé","Cousse","6.8500","2.1333","Benin","BJ","BEN","Atlantique","","11885","1204184442"
"Kasaji","Kasaji","-10.3662","23.4500","Congo (Kinshasa)","CD","COD","Lualaba","","11969","1180795640"
"Zlaté Moravce","Zlate Moravce","48.3783","18.3967","Slovakia","SK","SVK","Nitra","minor","11583","1703464744"
"Kibi","Kibi","6.1667","-0.5500","Ghana","GH","GHA","Eastern","","11677","1288567078"
"El Carmen","El Carmen","8.5128","-73.4508","Colombia","CO","COL","Norte de Santander","minor","12001","1170574816"
"Princetown","Princetown","5.9049","-57.1391","Guyana","GY","GUY","East Berbice-Corentyne","","12000","1328276701"
"Itondy","Itondy","-19.0500","45.3500","Madagascar","MG","MDG","Toliara","","12000","1450245222"
"Sassenburg","Sassenburg","52.5167","10.6333","Germany","DE","DEU","Lower Saxony","","11872","1276886023"
"Volosovo","Volosovo","59.4333","29.4833","Russia","RU","RUS","Leningradskaya Oblast’","minor","11987","1643898126"
"Waltikon","Waltikon","47.3667","8.5833","Switzerland","CH","CHE","Zürich","","10953","1756526339"
"Madina do Boé","Madina do Boe","11.7500","-14.2167","Guinea-Bissau","GW","GNB","Gabú","","12000","1624464668"
"Umm Badr","Umm Badr","14.2167","27.9333","Sudan","SD","SDN","North Kordofan","","12000","1729226320"
"Belp","Belp","46.8914","7.4972","Switzerland","CH","CHE","Bern","","11510","1756855405"
"Issoudun","Issoudun","46.9481","1.9933","France","FR","FRA","Centre-Val de Loire","minor","11690","1250313035"
"Poranga","Poranga","-4.7450","-40.9258","Brazil","BR","BRA","Ceará","","12001","1076916620"
"Largo","Largo","38.8800","-76.8289","United States","US","USA","Maryland","","12003","1840005960"
"Andramy","Andramy","-17.9635","44.7855","Madagascar","MG","MDG","Mahajanga","","12000","1450011952"
"Landsmeer","Landsmeer","52.4333","4.9167","Netherlands","NL","NLD","Noord-Holland","minor","11565","1528416718"
"Avesta","Avesta","60.1456","16.1683","Sweden","SE","SWE","Dalarna","minor","11949","1752442829"
"Krasnousol’skiy","Krasnousol'skiy","53.8947","56.4686","Russia","RU","RUS","Bashkortostan","","11991","1643830289"
"North Middleton","North Middleton","40.2462","-77.2168","United States","US","USA","Pennsylvania","","12002","1840144641"
"Nyírbátor","Nyirbator","47.8333","22.1333","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","11831","1348699897"
"Alegría","Alegria","13.5000","-88.4833","El Salvador","SV","SLV","Usulután","","11712","1222469913"
"Doesburg","Doesburg","52.0167","6.1333","Netherlands","NL","NLD","Gelderland","minor","11148","1528413725"
"Patu","Patu","-6.1100","-37.6369","Brazil","BR","BRA","Rio Grande do Norte","","11964","1076613644"
"Mikkelin Maalaiskunta","Mikkelin Maalaiskunta","61.6776","27.2021","Finland","FI","FIN","Etelä-Savo","","11988","1246254997"
"Sabnima","Sabnima","25.4583","85.5670","India","IN","IND","Bihār","","10607","1356509036"
"Naini","Naini","25.8320","84.7179","India","IN","IND","Bihār","","10494","1356070790"
"Casteldaccia","Casteldaccia","38.0500","13.5333","Italy","IT","ITA","Sicilia","","11655","1380308366"
"Sárbogárd","Sarbogard","46.8878","18.6193","Hungary","HU","HUN","Fejér","minor","11937","1348805330"
"El Arba Des Bir Lenni","El Arba Des Bir Lenni","34.3272","-4.2039","Morocco","MA","MAR","Fès-Meknès","","11866","1504816982"
"Hildburghausen","Hildburghausen","50.4167","10.7500","Germany","DE","DEU","Thuringia","minor","11836","1276098756"
"Matmata","Matmata","34.1000","-4.5500","Morocco","MA","MAR","Fès-Meknès","","11928","1504966128"
"Lichtenstein","Lichtenstein","50.7564","12.6317","Germany","DE","DEU","Saxony","","11285","1276704122"
"Sajószentpéter","Sajoszentpeter","48.2169","20.7183","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","","11679","1348595790"
"Longuenesse","Longuenesse","50.7356","2.2372","France","FR","FRA","Hauts-de-France","","10736","1250237552"
"Ambhua","Ambhua","24.5568","87.8651","India","IN","IND","West Bengal","","10477","1356444911"
"Rudra Nagar","Rudra Nagar","24.3841","87.8840","India","IN","IND","West Bengal","","10756","1356549473"
"Ullūr","Ullur","10.9706","79.4063","India","IN","IND","Tamil Nādu","","10270","1356172308"
"Mahmūda","Mahmuda","25.0531","85.2662","India","IN","IND","Bihār","","10948","1356967057"
"Guidel","Guidel","47.7906","-3.4886","France","FR","FRA","Bretagne","","11767","1250347046"
"Attippattu","Attippattu","13.2633","80.2911","India","IN","IND","Tamil Nādu","","11237","1356144266"
"Arakvāz-e Malekshāhī","Arakvaz-e Malekshahi","33.3828","46.5983","Iran","IR","IRN","Īlām","minor","11977","1364625820"
"Nueva Era","Nueva Era","17.9167","120.6667","Philippines","PH","PHL","Ilocos Norte","","11968","1608573219"
"Ichhāpur","Ichhapur","21.1551","76.1556","India","IN","IND","Madhya Pradesh","","11528","1356956102"
"Erbaocun","Erbaocun","42.9633","93.1714","China","CN","CHN","Xinjiang","","11972","1156254161"
"Geisenheim","Geisenheim","49.9844","7.9672","Germany","DE","DEU","Hesse","","11704","1276461244"
"Wallan","Wallan","-37.4167","144.9833","Australia","AU","AUS","Victoria","","11074","1036357414"
"Distracción","Distraccion","10.9000","-72.8833","Colombia","CO","COL","La Guajira","minor","11934","1170180285"
"Jamhor","Jamhor","24.8486","84.3258","India","IN","IND","Bihār","","11362","1356116292"
"Great Wyrley","Great Wyrley","52.6593","-2.0102","United Kingdom","GB","GBR","Staffordshire","","11060","1826969267"
"Diez","Diez","50.3708","8.0158","Germany","DE","DEU","Rhineland-Palatinate","","11092","1276836214"
"Kautālam","Kautalam","15.7710","77.1240","India","IN","IND","Andhra Pradesh","","11670","1356642546"
"Alijó","Alijo","41.2761","-7.4750","Portugal","PT","PRT","Vila Real","minor","11942","1620658703"
"Lower Gwynedd","Lower Gwynedd","40.1880","-75.2373","United States","US","USA","Pennsylvania","","11982","1840150802"
"Caldogno","Caldogno","45.6000","11.4944","Italy","IT","ITA","Veneto","","11272","1380104300"
"Çamaş","Camas","40.9131","37.5169","Turkey","TR","TUR","Ordu","minor","11851","1792164536"
"Cangas de Narcea","Cangas de Narcea","43.1714","-6.5389","Spain","ES","ESP","Asturias","","11966","1724285161"
"Martinsville","Martinsville","40.6030","-74.5751","United States","US","USA","New Jersey","","11980","1840024279"
"Sapahi","Sapahi","26.6517","84.8526","India","IN","IND","Bihār","","10721","1356152654"
"Santa Flavia","Santa Flavia","38.0833","13.5333","Italy","IT","ITA","Sicilia","","11212","1380531539"
"Paispamba","Paispamba","2.2500","-76.5833","Colombia","CO","COL","Cauca","minor","11958","1170817361"
"Naīgarhi","Naigarhi","24.7869","81.7787","India","IN","IND","Madhya Pradesh","","11572","1356996534"
"Hadim","Hadim","36.9883","32.4569","Turkey","TR","TUR","Konya","minor","11970","1792337779"
"Beladi","Beladi","13.1464","74.9399","India","IN","IND","Karnātaka","","11798","1356225777"
"Kargıpınar","Kargipinar","36.6667","34.4167","Turkey","TR","TUR","Mersin","","11923","1792234063"
"Chaumont-Gistoux","Chaumont-Gistoux","50.6839","4.6947","Belgium","BE","BEL","Wallonia","","11731","1056064038"
"Mykhailivka","Mykhailivka","47.2717","35.2248","Ukraine","UA","UKR","Zaporizka Oblast","minor","11949","1804473758"
"North Lebanon","North Lebanon","40.3668","-76.4215","United States","US","USA","Pennsylvania","","11974","1840143428"
"Janów Lubelski","Janow Lubelski","50.7167","22.4167","Poland","PL","POL","Lubelskie","minor","11879","1616411246"
"Jiajin","Jiajin","25.6743","108.4228","China","CN","CHN","Guizhou","","11954","1156611474"
"Gardone Val Trompia","Gardone Val Trompia","45.6833","10.1833","Italy","IT","ITA","Lombardy","","11538","1380641639"
"Kadalādi","Kadaladi","12.4040","78.9660","India","IN","IND","Tamil Nādu","","11602","1356005006"
"Şalpazarı","Salpazari","40.9422","39.1942","Turkey","TR","TUR","Trabzon","minor","11934","1792236277"
"Zārach","Zarach","31.9911","54.2322","Iran","IR","IRN","Yazd","","11691","1364790492"
"Aït I’yach","Ait I'yach","32.6908","-4.9292","Morocco","MA","MAR","Drâa-Tafilalet","","11946","1504002583"
"Tosashimizu","Tosashimizu","32.7833","132.9500","Japan","JP","JPN","Kōchi","","11925","1392003323"
"Kāranchedu","Karanchedu","15.8823","80.3170","India","IN","IND","Andhra Pradesh","","11667","1356913774"
"Raghunāthpur","Raghunathpur","26.6418","84.8938","India","IN","IND","Bihār","","10574","1356480521"
"Oswaldtwistle","Oswaldtwistle","53.7430","-2.3930","United Kingdom","GB","GBR","Lancashire","","11803","1826124855"
"Tectitán","Tectitan","15.3073","-92.0610","Guatemala","GT","GTM","Huehuetenango","minor","11928","1320428411"
"Serravalle Pistoiese","Serravalle Pistoiese","43.9000","10.8333","Italy","IT","ITA","Tuscany","","11689","1380007277"
"Zumbagua","Zumbagua","-0.9558","-78.9017","Ecuador","EC","ECU","Cotopaxi","","11895","1218423664"
"Tigzirt","Tigzirt","36.8931","4.1225","Algeria","DZ","DZA","Tizi Ouzou","","11962","1012150824"
"Santana do Matos","Santana do Matos","-5.9578","-36.6558","Brazil","BR","BRA","Rio Grande do Norte","","11956","1076298140"
"Levashi","Levashi","42.4333","47.3167","Russia","RU","RUS","Dagestan","","11862","1643989903"
"Somersworth","Somersworth","43.2534","-70.8856","United States","US","USA","New Hampshire","","11964","1840002810"
"São Sebastião de Lagoa de Roça","Sao Sebastiao de Lagoa de Roca","-7.0828","-35.8350","Brazil","BR","BRA","Paraíba","","11728","1076776639"
"Spencer","Spencer","42.2471","-71.9919","United States","US","USA","Massachusetts","","11963","1840053607"
"Sandy","Sandy","52.1310","-0.2970","United Kingdom","GB","GBR","Central Bedfordshire","","11657","1826630573"
"Sultanhanı","Sultanhani","38.2481","33.5465","Turkey","TR","TUR","Aksaray","minor","11885","1792616267"
"Cogolin","Cogolin","43.2525","6.5300","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11556","1250452505"
"Periya Soragai","Periya Soragai","11.7394","77.9170","India","IN","IND","Tamil Nādu","","11299","1356167576"
"Villebon-sur-Yvette","Villebon-sur-Yvette","48.7000","2.2278","France","FR","FRA","Île-de-France","","10546","1250107320"
"Bad Dürrenberg","Bad Durrenberg","51.2833","12.0667","Germany","DE","DEU","Saxony-Anhalt","","11643","1276847644"
"Meghraj","Meghraj","23.5000","73.5000","India","IN","IND","Gujarāt","","11363","1356158185"
"Groß-Enzersdorf","Gross-Enzersdorf","48.2000","16.5500","Austria","AT","AUT","Niederösterreich","","11817","1040341836"
"Popovača","Popovaca","45.5697","16.6250","Croatia","HR","HRV","Sisačko-Moslavačka Županija","minor","11905","1191042353"
"Manchester","Manchester","35.4630","-86.0774","United States","US","USA","Tennessee","","11956","1840015409"
"Onet Village","Onet Village","44.3656","2.5936","France","FR","FRA","Occitanie","","11665","1250700684"
"Vehkalahti","Vehkalahti","60.5756","27.1439","Finland","FI","FIN","Kymenlaakso","","11922","1246542463"
"Pratāparāmpuram","Prataparampuram","10.6741","79.8512","India","IN","IND","Tamil Nādu","","11406","1356184959"
"Stuarts Draft","Stuarts Draft","38.0188","-79.0354","United States","US","USA","Virginia","","11954","1840006247"
"Santoña","Santona","43.4414","-3.4575","Spain","ES","ESP","Cantabria","","11011","1724291294"
"Drolshagen","Drolshagen","51.0333","7.7667","Germany","DE","DEU","North Rhine-Westphalia","","11779","1276163887"
"Tizi Nisly","Tizi Nisly","32.4667","-5.7667","Morocco","MA","MAR","Béni Mellal-Khénifra","","11918","1504779871"
"Dranesville","Dranesville","38.9955","-77.3693","United States","US","USA","Virginia","","11951","1840024565"
"Zaoqiao","Zaoqiao","24.6500","120.8667","Taiwan","TW","TWN","Miaoli","","11707","1158591801"
"Arenzano","Arenzano","44.4042","8.6806","Italy","IT","ITA","Liguria","","11478","1380002792"
"Brejetuba","Brejetuba","-20.1458","-41.2900","Brazil","BR","BRA","Espírito Santo","","11915","1076480309"
"Fenton","Fenton","42.7994","-83.7144","United States","US","USA","Michigan","","11949","1840002948"
"Möhnesee","Mohnesee","51.4958","8.1306","Germany","DE","DEU","North Rhine-Westphalia","","11852","1276279418"
"Campobello di Mazara","Campobello di Mazara","37.6333","12.7500","Italy","IT","ITA","Sicilia","","11769","1380253064"
"Sidi Amer El Hadi","Sidi Amer El Hadi","34.7992","-5.8394","Morocco","MA","MAR","Rabat-Salé-Kénitra","","11814","1504103648"
"Saint-Jean-de-Védas","Saint-Jean-de-Vedas","43.5764","3.8239","France","FR","FRA","Occitanie","","11086","1250545862"
"Nāgalāpuram","Nagalapuram","13.3889","79.7967","India","IN","IND","Andhra Pradesh","","11166","1356970012"
"Dalmatovo","Dalmatovo","56.2667","62.9333","Russia","RU","RUS","Kurganskaya Oblast’","","11938","1643336308"
"Porto","Porto","-3.8928","-42.7100","Brazil","BR","BRA","Piauí","","11897","1076285535"
"Kunkalagunta","Kunkalagunta","16.2969","79.8866","India","IN","IND","Andhra Pradesh","","11637","1356256201"
"Gamharia","Gamharia","25.8973","86.6550","India","IN","IND","Bihār","","10826","1356236390"
"Hawaiian Paradise Park","Hawaiian Paradise Park","19.5828","-154.9695","United States","US","USA","Hawaii","","11942","1840029582"
"Camp Verde","Camp Verde","34.5690","-111.8560","United States","US","USA","Arizona","","11942","1840022752"
"Daroji","Daroji","15.0700","76.5500","India","IN","IND","Karnātaka","","11696","1356527899"
"Tirupporūr","Tirupporur","12.7259","80.1895","India","IN","IND","Tamil Nādu","","10954","1356665177"
"Mudgere","Mudgere","13.1300","75.6300","India","IN","IND","Karnātaka","","11831","1356875205"
"Doddappanāyakkanūr","Doddappanayakkanur","9.9810","77.7340","India","IN","IND","Tamil Nādu","","11520","1356209874"
"Guraahai","Guraahai","33.6449","74.2301","India","IN","IND","Jammu and Kashmīr","","11594","1356548124"
"Finale Ligure","Finale Ligure","44.1714","8.3444","Italy","IT","ITA","Liguria","","11613","1380697968"
"Mayūreswar","Mayureswar","23.9851","87.7728","India","IN","IND","West Bengal","","11142","1356705588"
"Nambour","Nambour","-26.6269","152.9591","Australia","AU","AUS","Queensland","","11187","1036804920"
"Wootton","Wootton","52.2007","-0.8857","United Kingdom","GB","GBR","Northamptonshire","","11180","1826816584"
"Mülheim-Kärlich","Mulheim-Karlich","50.3869","7.4953","Germany","DE","DEU","Rhineland-Palatinate","","11248","1276940149"
"Yangiqo‘rg‘on","Yangiqo\`rg\`on","41.1872","71.7333","Uzbekistan","UZ","UZB","Namangan","","11561","1860687497"
"Patori","Patori","25.9665","86.5471","India","IN","IND","Bihār","","10995","1356159847"
"Nārāyanavanam","Narayanavanam","13.4200","79.5800","India","IN","IND","Andhra Pradesh","","11253","1356847725"
"Pongode","Pongode","16.9246","79.7343","India","IN","IND","Andhra Pradesh","","11626","1356059962"
"Myjava","Myjava","48.7492","17.5644","Slovakia","SK","SVK","Nitra","minor","11708","1703306179"
"Bhado Khara","Bhado Khara","24.9567","85.5130","India","IN","IND","Bihār","","9944","1356013403"
"Mamqān","Mamqan","37.8431","45.9733","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","11892","1364475727"
"Clermont","Clermont","49.3789","2.4125","France","FR","FRA","Hauts-de-France","minor","10183","1250020067"
"Zriba-Village","Zriba-Village","36.3333","10.2500","Tunisia","TN","TUN","Nabeul","","11849","1788393853"
"Niles","Niles","41.8346","-86.2473","United States","US","USA","Michigan","","11929","1840003212"
"Nova Crixás","Nova Crixas","-14.0989","-50.3269","Brazil","BR","BRA","Goiás","","11927","1076651794"
"Bechloul","Bechloul","36.3167","4.0667","Algeria","DZ","DZA","Bouira","","11775","1012563877"
"Bhaurādah","Bhauradah","26.2520","87.8600","India","IN","IND","Bihār","","10769","1356396925"
"Halsūr","Halsur","17.8600","76.9500","India","IN","IND","Karnātaka","","11593","1356060970"
"Ikkādu","Ikkadu","13.1724","79.9358","India","IN","IND","Tamil Nādu","","11109","1356058677"
"Pozharan","Pozharan","42.3648","21.3372","Kosovo","XK","XKS","Viti","","11749","1901243584"
"Gudibanda","Gudibanda","13.6711","77.7042","India","IN","IND","Karnātaka","","11625","1356951451"
"San Michele al Tagliamento","San Michele al Tagliamento","45.7636","12.9953","Italy","IT","ITA","Veneto","","11822","1380617827"
"Neuville-en-Ferrain","Neuville-en-Ferrain","50.7467","3.1581","France","FR","FRA","Hauts-de-France","","10264","1250327204"
"Poselikha","Poselikha","51.9833","81.8333","Russia","RU","RUS","Altayskiy Kray","","11915","1643819418"
"La Homa","La Homa","26.2796","-98.3575","United States","US","USA","Texas","","11924","1840037093"
"Andhana","Andhana","25.2574","85.4548","India","IN","IND","Bihār","","10805","1356063339"
"Aheqi","Aheqi","40.9372","78.4543","China","CN","CHN","Xinjiang","minor","11910","1156243084"
"Bom Princípio","Bom Principio","-29.4889","-51.3528","Brazil","BR","BRA","Rio Grande do Sul","","11789","1076325363"
"Athol","Athol","42.5841","-72.2177","United States","US","USA","Massachusetts","","11922","1840053683"
"Castenedolo","Castenedolo","45.4704","10.2967","Italy","IT","ITA","Lombardy","","11482","1380758919"
"Salem","Salem","40.9049","-80.8492","United States","US","USA","Ohio","","11920","1840009374"
"Lachhmīpur","Lachhmipur","25.5248","87.3667","India","IN","IND","Bihār","","10688","1356083378"
"Yamamoto","Yamamoto","37.9627","140.8781","Japan","JP","JPN","Miyagi","","11736","1392003133"
"Amelia","Amelia","42.5500","12.4167","Italy","IT","ITA","Umbria","","11828","1380920464"
"Sixaola","Sixaola","9.5579","-82.6698","Costa Rica","CR","CRI","Limón","","11847","1188537249"
"Bokākhāt","Bokakhat","26.6402","93.6005","India","IN","IND","Assam","","11674","1356155935"
"Crowley","Crowley","30.2175","-92.3752","United States","US","USA","Louisiana","","11916","1840015041"
"Downham Market","Downham Market","52.6000","0.3900","United Kingdom","GB","GBR","Norfolk","","9994","1826885065"
"Mahazoarivo","Mahazoarivo","-20.3833","47.4333","Madagascar","MG","MDG","Fianarantsoa","","11869","1450097923"
"Miesbach","Miesbach","47.7833","11.8333","Germany","DE","DEU","Bavaria","minor","11562","1276609193"
"Maniago","Maniago","46.1667","12.7167","Italy","IT","ITA","Friuli Venezia Giulia","","11746","1380416559"
"Torton","Torton","52.4522","-2.1606","United Kingdom","GB","GBR","Worcestershire","","11841","1826331260"
"Wellington North","Wellington North","43.9000","-80.5700","Canada","CA","CAN","Ontario","","11914","1124001997"
"Nuvem","Nuvem","15.3174","73.9387","India","IN","IND","Goa","","10773","1356861846"
"St. Andrews","St. Andrews","50.2700","-96.9747","Canada","CA","CAN","Manitoba","","11913","1124001672"
"Bakwa","Bakwa","26.0601","86.4124","India","IN","IND","Bihār","","10446","1356464142"
"Tālsur","Talsur","25.3667","87.8512","India","IN","IND","West Bengal","","10680","1356114899"
"Somerville","Somerville","-38.2260","145.1770","Australia","AU","AUS","Victoria","","11336","1036275983"
"Burgthann","Burgthann","49.3563","11.3176","Germany","DE","DEU","Bavaria","","11614","1276619152"
"Kagamino","Kagamino","35.0918","133.9330","Japan","JP","JPN","Okayama","","11882","1392003494"
"Nieuwpoort","Nieuwpoort","51.1167","2.7500","Belgium","BE","BEL","Flanders","","11565","1056198212"
"Ploufragan","Ploufragan","48.4894","-2.7958","France","FR","FRA","Bretagne","","11489","1250978850"
"Péonga","Peonga","10.3333","3.2667","Benin","BJ","BEN","Borgou","","11843","1204235504"
"Talakād","Talakad","12.1887","77.0289","India","IN","IND","Karnātaka","","11671","1356235838"
"Lendinara","Lendinara","45.0850","11.6006","Italy","IT","ITA","Veneto","","11694","1380831674"
"Arkadak","Arkadak","51.9333","43.5000","Russia","RU","RUS","Saratovskaya Oblast’","","11895","1643300892"
"Langgöns","Langgons","50.5000","8.6667","Germany","DE","DEU","Hesse","","11683","1276380215"
"Loran","Loran","33.8346","74.3312","India","IN","IND","Jammu and Kashmīr","","11461","1356280369"
"Turvo","Turvo","-28.9258","-49.6789","Brazil","BR","BRA","Santa Catarina","","11854","1076843922"
"Ibirá","Ibira","-21.0800","-49.2408","Brazil","BR","BRA","São Paulo","","11861","1076713231"
"La Puebla del Río","La Puebla del Rio","37.2667","-6.0500","Spain","ES","ESP","Andalusia","","11873","1724618051"
"Ekerö","Ekero","59.2833","17.8000","Sweden","SE","SWE","Stockholm","minor","11524","1752521808"
"Punjai Kālāmangalam","Punjai Kalamangalam","11.2322","77.8236","India","IN","IND","Tamil Nādu","","10768","1356167863"
"Jamsher","Jamsher","31.2700","75.6417","India","IN","IND","Punjab","","11122","1356011416"
"Newport East","Newport East","41.5159","-71.2878","United States","US","USA","Rhode Island","","11903","1840073469"
"Rapho","Rapho","40.1576","-76.4580","United States","US","USA","Pennsylvania","","11902","1840142495"
"North Union","North Union","39.9101","-79.6733","United States","US","USA","Pennsylvania","","11902","1840149586"
"Nowe Miasto Lubawskie","Nowe Miasto Lubawskie","53.4256","19.5878","Poland","PL","POL","Warmińsko-Mazurskie","minor","10997","1616871039"
"Eranāpuram","Eranapuram","11.5581","77.9893","India","IN","IND","Tamil Nādu","","11242","1356169346"
"Höhenkirchen-Siegertsbrunn","Hohenkirchen-Siegertsbrunn","48.0167","11.7333","Germany","DE","DEU","Bavaria","","11166","1276421058"
"Carleton Place","Carleton Place","45.1333","-76.1333","Canada","CA","CAN","Ontario","","11901","1124676010"
"Podor","Podor","16.6167","-15.0333","Senegal","SN","SEN","Saint-Louis","","11869","1686277241"
"Nellipāka","Nellipaka","17.7679","80.8567","India","IN","IND","Andhra Pradesh","","11668","1356878549"
"Eijsden","Eijsden","50.7778","5.7083","Netherlands","NL","NLD","Limburg","minor","11565","1528761006"
"Mādhopur","Madhopur","25.3453","85.3925","India","IN","IND","Bihār","","10395","1356328255"
"Sainte-Savine","Sainte-Savine","48.2947","4.0489","France","FR","FRA","Grand Est","","10508","1250324256"
"Vendas Novas","Vendas Novas","38.6833","-8.4500","Portugal","PT","PRT","Évora","minor","11846","1620891430"
"Giffoni Valle Piana","Giffoni Valle Piana","40.7167","14.9333","Italy","IT","ITA","Campania","","11899","1380330872"
"Olivenza","Olivenza","38.6858","-7.1008","Spain","ES","ESP","Extremadura","","11871","1724189870"
"Randaberg","Randaberg","59.0017","5.6153","Norway","NO","NOR","Rogaland","minor","11454","1578935358"
"Kać","Kac","45.3000","19.9500","Serbia","RS","SRB","","","11740","1688942926"
"Kete Krachi","Kete Krachi","7.8000","-0.0167","Ghana","GH","GHA","Oti","","11788","1288185431"
"Ivybridge","Ivybridge","50.3890","-3.9210","United Kingdom","GB","GBR","Devon","","11851","1826566747"
"Rāibāri Mahuawa","Raibari Mahuawa","27.0980","84.2963","India","IN","IND","Bihār","","11411","1356246911"
"Gbéroubouè","Gberouboue","10.5333","2.7333","Benin","BJ","BEN","Borgou","","11846","1204150052"
"Palmilla","Palmilla","-34.6042","-71.3583","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","11844","1152157193"
"Rio Paranaíba","Rio Paranaiba","-19.1939","-46.2469","Brazil","BR","BRA","Minas Gerais","","11885","1076417535"
"Berilo","Berilo","-16.9519","-42.4658","Brazil","BR","BRA","Minas Gerais","","11872","1076515004"
"Crossville","Crossville","35.9526","-85.0295","United States","US","USA","Tennessee","","11892","1840014489"
"Pellezzano","Pellezzano","40.7333","14.7667","Italy","IT","ITA","Campania","","11101","1380010237"
"Lopon","Lopon","30.6715","75.3438","India","IN","IND","Punjab","","11406","1356789670"
"Ojuelos de Jalisco","Ojuelos de Jalisco","21.8642","-101.5933","Mexico","MX","MEX","Jalisco","minor","11881","1484896796"
"Jataìzinho","Jataizinho","-23.2539","-50.9800","Brazil","BR","BRA","Paraná","","11875","1076526726"
"Dammapeta","Dammapeta","17.2667","81.0167","India","IN","IND","Andhra Pradesh","","11629","1356795204"
"Dudley","Dudley","42.0550","-71.9352","United States","US","USA","Massachusetts","","11890","1840053586"
"Fateha","Fateha","25.6073","85.8669","India","IN","IND","Bihār","","9985","1356664955"
"Tiruvennanallūr","Tiruvennanallur","11.8589","79.3664","India","IN","IND","Tamil Nādu","","11328","1356327885"
"Kongupatti","Kongupatti","11.8593","78.0217","India","IN","IND","Tamil Nādu","","11023","1356245751"
"Green River","Green River","41.5127","-109.4710","United States","US","USA","Wyoming","","11887","1840020100"
"Guatajiagua","Guatajiagua","13.6667","-88.2000","El Salvador","SV","SLV","Morazán","","11721","1222986309"
"Castelló de Ampurias","Castello de Ampurias","42.2582","3.0747","Spain","ES","ESP","Catalonia","","11611","1724127097"
"Mutia","Mutia","8.4176","123.4771","Philippines","PH","PHL","Zamboanga del Norte","","11726","1608508785"
"Nallūr","Nallur","14.0871","75.8778","India","IN","IND","Karnātaka","","11617","1356073992"
"Macerata Campania","Macerata Campania","41.0667","14.2667","Italy","IT","ITA","Campania","","10507","1380490528"
"Ban Phan Don","Ban Phan Don","17.1290","102.9618","Thailand","TH","THA","Udon Thani","","11739","1764073724"
"Sartana","Sartana","47.1708","37.6954","Ukraine","UA","UKR","Donetska Oblast","","10350","1804984978"
"Novaya Lyalya","Novaya Lyalya","59.0667","60.6000","Russia","RU","RUS","Sverdlovskaya Oblast’","","11879","1643222721"
"Rangsdorf","Rangsdorf","52.2831","13.4331","Germany","DE","DEU","Brandenburg","","11540","1276090863"
"Ukrainsk","Ukrainsk","48.1000","37.3667","Ukraine","UA","UKR","Donetska Oblast","","11824","1804349001"
"Scottburgh","Scottburgh","-30.2833","30.7500","South Africa","ZA","ZAF","KwaZulu-Natal","","11403","1710536127"
"Kabīrpur","Kabirpur","26.2661","84.2050","India","IN","IND","Bihār","","10933","1356970212"
"Mazzarino","Mazzarino","37.3000","14.2000","Italy","IT","ITA","Sicilia","","11842","1380002407"
"Keolāri","Keolari","22.3697","79.9060","India","IN","IND","Madhya Pradesh","","11668","1356293927"
"Rosaryville","Rosaryville","38.7672","-76.8266","United States","US","USA","Maryland","","11881","1840005952"
"Toualet","Toualet","32.7333","-7.1833","Morocco","MA","MAR","Casablanca-Settat","","11815","1504141379"
"Itanhomi","Itanhomi","-19.1719","-41.8650","Brazil","BR","BRA","Minas Gerais","","11856","1076366095"
"Iacanga","Iacanga","-21.8900","-49.0247","Brazil","BR","BRA","São Paulo","","11858","1076775071"
"Andraitx","Andraitx","39.5746","2.4206","Spain","ES","ESP","Balearic Islands","","11735","1724876017"
"Agamé","Agame","6.7333","1.7667","Benin","BJ","BEN","Couffo","","11465","1204183664"
"Bopfingen","Bopfingen","48.8569","10.3522","Germany","DE","DEU","Baden-Württemberg","","11727","1276407317"
"Atmākūr","Atmakur","18.0712","79.7360","India","IN","IND","Andhra Pradesh","","11491","1356939903"
"Kushmanchi","Kushmanchi","17.2263","79.9668","India","IN","IND","Andhra Pradesh","","11563","1356597619"
"Zaragoza","Zaragoza","17.9487","-94.6436","Mexico","MX","MEX","Veracruz","minor","11354","1484089470"
"Owk","Owk","15.2167","78.1167","India","IN","IND","Andhra Pradesh","","11760","1356075190"
"Āzamnagar","Azamnagar","25.5456","87.8294","India","IN","IND","Bihār","","10317","1356646676"
"Rodenbach","Rodenbach","50.1500","9.0333","Germany","DE","DEU","Hesse","","11204","1276944139"
"Matino","Matino","40.0333","18.1333","Italy","IT","ITA","Puglia","","11444","1380053449"
"Rupenaguntla","Rupenaguntla","16.3043","79.9991","India","IN","IND","Andhra Pradesh","","11469","1356840259"
"Santa Cruz da Baixa Verde","Santa Cruz da Baixa Verde","-7.8208","-38.1528","Brazil","BR","BRA","Pernambuco","","11768","1076924268"
"Chintalavādi","Chintalavadi","10.9511","78.3158","India","IN","IND","Tamil Nādu","","11540","1356223692"
"Horodnia","Horodnia","51.8924","31.5954","Ukraine","UA","UKR","Chernihivska Oblast","","11852","1804000009"
"Abasolo","Abasolo","24.0559","-98.3733","Mexico","MX","MEX","Tamaulipas","minor","11862","1484491384"
"Ovidiopol","Ovidiopol","46.2667","30.4333","Ukraine","UA","UKR","Odeska Oblast","minor","11741","1804919289"
"Chauki Hasan Chauki Makhdum","Chauki Hasan Chauki Makhdum","26.2333","84.4964","India","IN","IND","Bihār","","10100","1356401229"
"Devikāpuram","Devikapuram","12.4744","79.3475","India","IN","IND","Tamil Nādu","","11499","1356422582"
"Vellavādanparappu","Vellavadanparappu","11.1854","77.8425","India","IN","IND","Tamil Nādu","","10732","1356167418"
"Kingston","Kingston","-42.9769","147.3083","Australia","AU","AUS","Tasmania","","10409","1036297213"
"Azcoitia","Azcoitia","43.1792","-2.3106","Spain","ES","ESP","Basque Country","","11657","1724640950"
"Alamedin","Alamedin","42.8900","74.6300","Kyrgyzstan","KG","KGZ","Chüy","","11773","1417392357"
"Broome","Broome","-17.9619","122.2361","Australia","AU","AUS","Western Australia","","11547","1036047905"
"Satoshō","Satosho","34.5138","133.5569","Japan","JP","JPN","Okayama","","10969","1392000313"
"Bugongi","Bugongi","-0.6356","30.2528","Uganda","UG","UGA","Sheema","","11547","1800892810"
"Ingurti","Ingurti","17.6695","79.8003","India","IN","IND","Andhra Pradesh","","11522","1356195310"
"Kurabalakota","Kurabalakota","13.6500","78.4833","India","IN","IND","Andhra Pradesh","","11583","1356135614"
"Gerstetten","Gerstetten","48.6225","10.0206","Germany","DE","DEU","Baden-Württemberg","","11737","1276822325"
"Coswig","Coswig","51.8833","12.4333","Germany","DE","DEU","Saxony-Anhalt","","11824","1276417362"
"Hàng Trạm","Hang Tram","20.3944","105.6222","Vietnam","VN","VNM","Hòa Bình","minor","11503","1704292651"
"Oberderdingen","Oberderdingen","49.0625","8.8019","Germany","DE","DEU","Baden-Württemberg","","11517","1276945487"
"Lābhgaon","Labhgaon","25.5062","86.3899","India","IN","IND","Bihār","","10165","1356158425"
"Baroni Khurd","Baroni Khurd","25.6852","78.3746","India","IN","IND","Madhya Pradesh","","11444","1356096106"
"Kuruman","Kuruman","-27.4597","23.4125","South Africa","ZA","ZAF","Northern Cape","","10006","1710332978"
"Aguasay","Aguasay","9.3203","-63.6411","Venezuela","VE","VEN","Monagas","minor","11856","1862003602"
"Carmo da Cachoeira","Carmo da Cachoeira","-21.4608","-45.2239","Brazil","BR","BRA","Minas Gerais","","11836","1076799128"
"Nandavaram","Nandavaram","16.0170","77.5310","India","IN","IND","Andhra Pradesh","","11651","1356500526"
"Shāhpur","Shahpur","24.0312","84.0543","India","IN","IND","Jhārkhand","","11429","1356517061"
"Rājānagaram","Rajanagaram","17.0833","81.9000","India","IN","IND","Andhra Pradesh","","10722","1356258636"
"Kuchinarai","Kuchinarai","16.5318","104.0440","Thailand","TH","THA","Kalasin","minor","11753","1764821226"
"Red Bank","Red Bank","35.1117","-85.2962","United States","US","USA","Tennessee","","11855","1840014571"
"Brookhaven","Brookhaven","31.5803","-90.4432","United States","US","USA","Mississippi","","11855","1840013862"
"Chikni","Chikni","26.0664","87.4406","India","IN","IND","Bihār","","10173","1356466319"
"Seringueiras","Seringueiras","-11.7981","-63.0311","Brazil","BR","BRA","Rondônia","","11851","1076017164"
"Whistler","Whistler","50.1208","-122.9544","Canada","CA","CAN","British Columbia","","11854","1124001562"
"Macajuba","Macajuba","-12.1358","-40.3600","Brazil","BR","BRA","Bahia","","11837","1076763287"
"Mương Theng","Muong Theng","21.3869","103.0156","Vietnam","VN","VNM","Điện Biên","","11740","1704017022"
"Chilonga","Chilonga","-12.0244","31.3364","Zambia","ZM","ZMB","Muchinga","","11816","1894544626"
"Narasāpuram","Narasapuram","17.1016","81.0765","India","IN","IND","Andhra Pradesh","","11663","1356951411"
"Labbaikkudikkādu","Labbaikkudikkadu","11.3922","79.0218","India","IN","IND","Tamil Nādu","","11538","1356210555"
"Yakushima","Yakushima","30.3903","130.6515","Japan","JP","JPN","Kagoshima","","11829","1392003222"
"Lebon Régis","Lebon Regis","-26.9289","-50.6950","Brazil","BR","BRA","Santa Catarina","","11838","1076117797"
"Recreo","Recreo","-29.2667","-65.0667","Argentina","AR","ARG","Catamarca","","11847","1032000017"
"Monschau","Monschau","50.5600","6.2564","Germany","DE","DEU","North Rhine-Westphalia","","11726","1276023228"
"Martinsville","Martinsville","39.4149","-86.4316","United States","US","USA","Indiana","","11848","1840008461"
"Worb","Worb","46.9306","7.5644","Switzerland","CH","CHE","Bern","","11311","1756060343"
"Perches","Perches","19.5167","-71.9167","Haiti","HT","HTI","Nord-Est","","11556","1332584583"
"Wolnzach","Wolnzach","48.6000","11.6167","Germany","DE","DEU","Bavaria","","11719","1276311526"
"Santuario","Santuario","5.0725","-75.9623","Colombia","CO","COL","Risaralda","minor","11787","1170439701"
"Holešov","Holesov","49.3333","17.5783","Czechia","CZ","CZE","Zlínský Kraj","","11509","1203380921"
"Pipra Naurangiā","Pipra Naurangia","26.8591","84.4328","India","IN","IND","Bihār","","10169","1356542141"
"Cricova","Cricova","47.1333","28.8667","Moldova","MD","MDA","Chişinău","","10669","1498628577"
"Hauzenberg","Hauzenberg","48.6500","13.6333","Germany","DE","DEU","Bavaria","","11703","1276386776"
"Ziyodin Shaharchasi","Ziyodin Shaharchasi","40.0342","65.6547","Uzbekistan","UZ","UZB","Samarqand","minor","11610","1860117701"
"Brighton","Brighton","44.1222","-77.7642","Canada","CA","CAN","Ontario","","11844","1124672085"
"Tolcayuca","Tolcayuca","19.9500","-98.9167","Mexico","MX","MEX","Morelos","minor","11746","1484403443"
"Ban Wiang Phan","Ban Wiang Phan","20.4128","99.8853","Thailand","TH","THA","Chiang Rai","","11569","1764466562"
"Bocaina","Bocaina","-22.1361","-48.5181","Brazil","BR","BRA","São Paulo","","11810","1076989834"
"Qâna","Qana","33.2092","35.2992","Lebanon","LB","LBN","Liban-Sud","","10000","1422539070"
"Khimlāsa","Khimlasa","24.2058","78.3639","India","IN","IND","Madhya Pradesh","","11585","1356152023"
"Casino","Casino","-28.8667","153.0500","Australia","AU","AUS","New South Wales","","10914","1036855900"
"Chhātāpur","Chhatapur","26.2197","87.0048","India","IN","IND","Bihār","","10805","1356478045"
"Bhanghi","Bhanghi","26.3612","87.1826","India","IN","IND","Bihār","","10773","1356501629"
"Olivença","Olivenca","-9.5186","-37.1906","Brazil","BR","BRA","Alagoas","","11772","1076408524"
"Monción","Moncion","19.4167","-71.1667","Dominican Republic","DO","DOM","Cibao Noroeste","","11753","1214972514"
"Medina Sidonia","Medina Sidonia","36.4667","-5.9167","Spain","ES","ESP","Andalusia","","11813","1724265557"
"Iskapālem","Iskapalem","14.5416","79.8925","India","IN","IND","Andhra Pradesh","","11272","1356927056"
"Roessleville","Roessleville","42.6969","-73.7964","United States","US","USA","New York","","11835","1840033946"
"Scorniceşti","Scornicesti","44.5700","24.5500","Romania","RO","ROU","Olt","","11766","1642000053"
"Puliyara","Puliyara","9.0041","77.1889","India","IN","IND","Tamil Nādu","","11043","1356459188"
"Kīlkottai","Kilkottai","10.2861","77.9160","India","IN","IND","Tamil Nādu","","11136","1356243881"
"Santa Ana","Santa Ana","14.0667","-87.9500","Honduras","HN","HND","La Paz","","11777","1340290490"
"Opatija","Opatija","45.3333","14.3000","Croatia","HR","HRV","Primorsko-Goranska Županija","minor","11659","1191982660"
"Nāranāpuram","Naranapuram","11.0254","77.2900","India","IN","IND","Tamil Nādu","","10772","1356165582"
"Gulfport","Gulfport","27.7463","-82.7099","United States","US","USA","Florida","","11830","1840014145"
"Paraíso do Norte","Paraiso do Norte","-23.2808","-52.6019","Brazil","BR","BRA","Paraná","","11772","1076269054"
"Dolo Bay","Dolo Bay","4.1833","42.0833","Ethiopia","ET","ETH","Sumalē","","11810","1231539120"
"Kadrābād","Kadrabad","25.5793","85.9475","India","IN","IND","Bihār","","9926","1356036353"
"Khandpara","Khandpara","20.2644","85.1767","India","IN","IND","Odisha","","11559","1356116319"
"Tirodi","Tirodi","21.6852","79.7191","India","IN","IND","Madhya Pradesh","","11680","1356870284"
"Mömbris","Mombris","50.0667","9.1667","Germany","DE","DEU","Bavaria","","11507","1276210948"
"Destrehan","Destrehan","29.9626","-90.3676","United States","US","USA","Louisiana","","11827","1840013110"
"Kummarapurugupālem","Kummarapurugupalem","16.3653","81.6192","India","IN","IND","Andhra Pradesh","","11206","1356135822"
"On Top of the World Designated Place","On Top of the World Designated Place","29.1058","-82.2866","United States","US","USA","Florida","","11826","1840142109"
"Kāla Diāra","Kala Diara","25.5092","85.4480","India","IN","IND","Bihār","","10655","1356586478"
"Moulay Driss Zerhoun","Moulay Driss Zerhoun","34.0542","-5.5272","Morocco","MA","MAR","Fès-Meknès","","11615","1504880171"
"Al Hāmah","Al Hamah","33.5581","36.2222","Syria","SY","SYR","Rīf Dimashq","","10045","1760765828"
"Hardās Bigha","Hardas Bigha","25.4994","85.3551","India","IN","IND","Bihār","","10086","1356661144"
"Senirkent","Senirkent","38.1081","30.5500","Turkey","TR","TUR","Isparta","minor","11811","1792298436"
"Neuhausen am Rheinfall","Neuhausen am Rheinfall","47.6833","8.6167","Switzerland","CH","CHE","Schaffhausen","","10510","1756815481"
"Septèmes-les-Vallons","Septemes-les-Vallons","43.3983","5.3658","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11196","1250705124"
"Lommedalen","Lommedalen","59.9500","10.4667","Norway","NO","NOR","Viken","","11200","1578002364"
"Chaplygin","Chaplygin","53.2333","39.9667","Russia","RU","RUS","Lipetskaya Oblast’","","11802","1643946136"
"Itaipé","Itaipe","-17.4019","-41.6689","Brazil","BR","BRA","Minas Gerais","","11798","1076171183"
"Xuân Trùng","Xuan Trung","21.0500","105.2833","Vietnam","VN","VNM","Phú Thọ","","11506","1704809056"
"Jalalaqsi","Jalalaqsi","3.4000","45.5833","Somalia","SO","SOM","Hiiraan","","11800","1706085316"
"Befandefa","Befandefa","-22.1333","43.3000","Madagascar","MG","MDG","Toliara","","11812","1450026685"
"Haradok","Haradok","55.4667","30.0000","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","11814","1112131300"
"Nandimandalam","Nandimandalam","14.4052","78.5246","India","IN","IND","Andhra Pradesh","","11711","1356639188"
"Sant’Ilario d’Enza","Sant'Ilario d'Enza","44.7667","10.4500","Italy","IT","ITA","Emilia-Romagna","","11263","1380666242"
"Überherrn","Uberherrn","49.2500","6.7000","Germany","DE","DEU","Saarland","","11487","1276864490"
"Richland","Richland","40.6440","-79.9579","United States","US","USA","Pennsylvania","","11818","1840141800"
"East Bethel","East Bethel","45.3557","-93.2038","United States","US","USA","Minnesota","","11818","1840007797"
"Raghunāthpur","Raghunathpur","26.3448","84.9211","India","IN","IND","Bihār","","10372","1356944072"
"Kaniyambādi","Kaniyambadi","12.8118","79.1340","India","IN","IND","Tamil Nādu","","11064","1356699961"
"Vilpatti","Vilpatti","10.2672","77.5014","India","IN","IND","Tamil Nādu","","11687","1356206036"
"Winfield","Winfield","37.2740","-96.9500","United States","US","USA","Kansas","","11817","1840001701"
"Umurlu","Umurlu","37.8500","27.9667","Turkey","TR","TUR","Aydın","minor","11368","1792889279"
"Santa Lucía","Santa Lucia","10.3167","-74.9500","Colombia","CO","COL","Atlántico","minor","11584","1170763002"
"Bīrpur","Birpur","26.5767","86.0379","India","IN","IND","Bihār","","10969","1356125816"
"Saverne","Saverne","48.7414","7.3619","France","FR","FRA","Grand Est","minor","11378","1250828818"
"Rewtith","Rewtith","26.2853","84.7155","India","IN","IND","Bihār","","10707","1356883616"
"Porto-Vecchio","Porto-Vecchio","41.5908","9.2797","France","FR","FRA","Corsica","","11748","1250709703"
"Shāhpur Undi","Shahpur Undi","25.6370","85.6013","India","IN","IND","Bihār","","10059","1356737209"
"Castano Primo","Castano Primo","45.5500","8.7667","Italy","IT","ITA","Lombardy","","11228","1380175230"
"Codigoro","Codigoro","44.8333","12.1167","Italy","IT","ITA","Emilia-Romagna","","11740","1380966782"
"Tifni","Tifni","31.6281","-6.9444","Morocco","MA","MAR","Béni Mellal-Khénifra","","11760","1504629455"
"Mareeba","Mareeba","-16.9833","145.4167","Australia","AU","AUS","Queensland","","11079","1036797275"
"Amarchinta","Amarchinta","16.3740","77.7729","India","IN","IND","Andhra Pradesh","","11225","1356070260"
"Tarrytown","Tarrytown","41.0647","-73.8672","United States","US","USA","New York","","11808","1840004945"
"Tādepalle","Tadepalle","16.8454","81.4966","India","IN","IND","Andhra Pradesh","","11189","1356030606"
"Campagnano di Roma","Campagnano di Roma","42.1333","12.3833","Italy","IT","ITA","Lazio","","11561","1380699714"
"Cisneros","Cisneros","6.5383","-75.0886","Colombia","CO","COL","Antioquia","minor","11555","1170710325"
"Weston","Weston","42.3589","-71.3001","United States","US","USA","Massachusetts","","11806","1840053494"
"Sarauni Kalān","Sarauni Kalan","25.7579","86.9392","India","IN","IND","Bihār","","11011","1356238177"
"Kadwa","Kadwa","25.0316","84.0924","India","IN","IND","Bihār","","10645","1356558904"
"Okhargara","Okhargara","24.2141","83.7856","India","IN","IND","Jhārkhand","","11142","1356228974"
"Comalapa","Comalapa","12.2842","-85.5106","Nicaragua","NI","NIC","Chontales","minor","11785","1558074780"
"Oignies","Oignies","50.4692","2.9936","France","FR","FRA","Hauts-de-France","","9993","1250022746"
"Oak Grove","Oak Grove","33.9780","-81.1468","United States","US","USA","South Carolina","","11803","1840135088"
"Baranivka","Baranivka","50.3000","27.6667","Ukraine","UA","UKR","Zhytomyrska Oblast","","11765","1804259330"
"Evanston","Evanston","41.2602","-110.9646","United States","US","USA","Wyoming","","11802","1840020122"
"Labin","Labin","45.0833","14.1167","Croatia","HR","HRV","Istarska Županija","minor","11642","1191000718"
"Venosa","Venosa","40.9618","15.8148","Italy","IT","ITA","Basilicata","","11732","1380807086"
"Scharbeutz","Scharbeutz","54.0214","10.7456","Germany","DE","DEU","Schleswig-Holstein","","11580","1276805857"
"Olaippatti","Olaippatti","11.7676","77.9927","India","IN","IND","Tamil Nādu","","11140","1356168343"
"Kantilo","Kantilo","20.3615","85.1921","India","IN","IND","Odisha","","11530","1356615489"
"Cafayate","Cafayate","-26.0833","-65.9667","Argentina","AR","ARG","Salta","minor","11785","1032961594"
"Zayukovo","Zayukovo","43.6119","43.3269","Russia","RU","RUS","Kabardino-Balkariya","","11717","1643785831"
"Rostam Kolā","Rostam Kola","36.6778","53.4286","Iran","IR","IRN","Māzandarān","","11686","1364863300"
"College","College","64.8694","-147.8216","United States","US","USA","Alaska","","11797","1840023462"
"San Pedro","San Pedro","17.9214","-87.9611","Belize","BZ","BLZ","Belize","","11767","1084929106"
"La Roche-sur-Foron","La Roche-sur-Foron","46.0669","6.3119","France","FR","FRA","Auvergne-Rhône-Alpes","","11175","1250816265"
"Jagannāthpur","Jagannathpur","25.6573","87.5886","India","IN","IND","Bihār","","10996","1356148899"
"Phon","Phon","15.8084","102.6018","Thailand","TH","THA","Khon Kaen","minor","11593","1764718749"
"Amelia","Amelia","39.0269","-84.2218","United States","US","USA","Ohio","","11795","1840010681"
"Endwell","Endwell","42.1184","-76.0219","United States","US","USA","New York","","11795","1840004657"
"Ngerengere","Ngerengere","-6.7500","38.1167","Tanzania","TZ","TZA","Morogoro","","11780","1834593902"
"Carnaubeira da Penha","Carnaubeira da Penha","-8.3219","-38.7439","Brazil","BR","BRA","Pernambuco","","11782","1076499584"
"Carmen","Carmen","9.2289","126.0167","Philippines","PH","PHL","Surigao del Sur","","11720","1608605257"
"Iguaraci","Iguaraci","-7.8350","-37.5150","Brazil","BR","BRA","Pernambuco","","11779","1076060839"
"Oxelösund","Oxelosund","58.6667","17.1167","Sweden","SE","SWE","Södermanland","minor","11488","1752816950"
"Kāri","Kari","24.8368","78.8665","India","IN","IND","Madhya Pradesh","","11469","1356249257"
"Guspini","Guspini","39.5333","8.6333","Italy","IT","ITA","Sardegna","","11725","1380078367"
"Half Moon Bay","Half Moon Bay","37.4685","-122.4382","United States","US","USA","California","","11792","1840020303"
"Pallipattu","Pallipattu","13.3361","79.4413","India","IN","IND","Andhra Pradesh","","11423","1356770479"
"Tegueste","Tegueste","28.5233","-16.3408","Spain","ES","ESP","Canary Islands","","11359","1724441974"
"Târgu Lăpuş","Targu Lapus","47.4525","23.8631","Romania","RO","ROU","Maramureş","","11744","1642400561"
"Pasadena Hills","Pasadena Hills","28.2881","-82.2380","United States","US","USA","Florida","","11788","1840039051"
"Pampas","Pampas","-12.3989","-74.8667","Peru","PE","PER","Huancavelica","minor","11566","1604111840"
"Tiny","Tiny","44.6833","-79.9500","Canada","CA","CAN","Ontario","","11787","1124000103"
"Lohiān","Lohian","31.3156","75.3339","India","IN","IND","Punjab","","11283","1356174516"
"Irupi","Irupi","-20.3450","-41.6408","Brazil","BR","BRA","Espírito Santo","","11723","1076291312"
"Pūngulam","Pungulam","12.5727","78.7530","India","IN","IND","Tamil Nādu","","11179","1356740281"
"Allonnes","Allonnes","47.9686","0.1606","France","FR","FRA","Pays de la Loire","","11167","1250722927"
"Kankaanpää","Kankaanpaa","61.8000","22.4000","Finland","FI","FIN","Satakunta","minor","11769","1246054276"
"Riolândia","Riolandia","-19.9900","-49.6808","Brazil","BR","BRA","São Paulo","","11766","1076627058"
"Trofarello","Trofarello","44.9833","7.7333","Italy","IT","ITA","Piedmont","","10901","1380590020"
"Cologno al Serio","Cologno al Serio","45.5833","9.7000","Italy","IT","ITA","Lombardy","","11180","1380174378"
"Liman","Liman","38.8733","48.8139","Azerbaijan","AZ","AZE","Lənkəran","","11500","1031959844"
"Jarābulus","Jarabulus","36.8175","38.0111","Syria","SY","SYR","Ḩalab","minor","11570","1760404681"
"Lom Sak","Lom Sak","16.7775","101.2468","Thailand","TH","THA","Phetchabun","minor","11685","1764798281"
"Fairview Shores","Fairview Shores","28.6021","-81.3958","United States","US","USA","Florida","","11782","1840013120"
"Nirpur","Nirpur","25.7192","87.1507","India","IN","IND","Bihār","","10859","1356909778"
"Sítio do Quinto","Sitio do Quinto","-10.3500","-38.2169","Brazil","BR","BRA","Bahia","","11767","1076768739"
"Moldava nad Bodvou","Moldava nad Bodvou","48.6064","21.0003","Slovakia","SK","SVK","Košice","","11260","1703800646"
"Ventersburg","Ventersburg","-28.0833","27.1333","South Africa","ZA","ZAF","Free State","","11260","1710599801"
"Los Alamitos","Los Alamitos","33.7971","-118.0592","United States","US","USA","California","","11780","1840020579"
"Darwa","Darwa","25.6690","85.6461","India","IN","IND","Bihār","","10177","1356614765"
"Imaculada","Imaculada","-7.3900","-37.5089","Brazil","BR","BRA","Paraíba","","11749","1076375786"
"Ringwood","Ringwood","41.1065","-74.2749","United States","US","USA","New Jersey","","11778","1840000835"
"Putnam Valley","Putnam Valley","41.3980","-73.8368","United States","US","USA","New York","","11778","1840058429"
"Gänserndorf","Ganserndorf","48.3406","16.7175","Austria","AT","AUT","Niederösterreich","minor","11404","1040375279"
"Picayune","Picayune","30.5322","-89.6724","United States","US","USA","Mississippi","","11777","1840015002"
"Courrières","Courrieres","50.4581","2.9472","France","FR","FRA","Hauts-de-France","","10554","1250097393"
"Jangy-Kyshtak","Jangy-Kyshtak","40.5500","72.7306","Kyrgyzstan","KG","KGZ","Osh","","11647","1417218150"
"Bilopillya","Bilopillya","51.1474","34.3025","Ukraine","UA","UKR","Sumska Oblast","","11743","1804185866"
"Lieksa","Lieksa","63.3167","30.0167","Finland","FI","FIN","Pohjois-Karjala","minor","11772","1246128959"
"Raesfeld","Raesfeld","51.7667","6.8333","Germany","DE","DEU","North Rhine-Westphalia","","11574","1276150873"
"Platteville","Platteville","42.7280","-90.4681","United States","US","USA","Wisconsin","","11774","1840002981"
"Elgóibar","Elgoibar","43.2142","-2.4169","Spain","ES","ESP","Basque Country","","11480","1724034254"
"Darnétal","Darnetal","49.4447","1.1511","France","FR","FRA","Normandie","","9788","1250534299"
"Ponte San Pietro","Ponte San Pietro","45.6978","9.5881","Italy","IT","ITA","Lombardy","","11502","1380849496"
"Kusugal","Kusugal","15.3667","75.2167","India","IN","IND","Karnātaka","","11548","1356568466"
"Ross on Wye","Ross on Wye","51.9140","-2.5870","United Kingdom","GB","GBR","Herefordshire","","10700","1826303681"
"Campo Alegre","Campo Alegre","-26.1928","-49.2658","Brazil","BR","BRA","Santa Catarina","","11748","1076640539"
"Óbidos","Obidos","39.3581","-9.1578","Portugal","PT","PRT","Leiria","minor","11689","1620276354"
"Vlašim","Vlasim","49.7064","14.8989","Czechia","CZ","CZE","Středočeský Kraj","","11496","1203658512"
"Étaples","Etaples","50.5178","1.6406","France","FR","FRA","Hauts-de-France","","10926","1250922142"
"Bullas","Bullas","38.0497","-1.6706","Spain","ES","ESP","Murcia","","11627","1724151285"
"Arataca","Arataca","-15.2628","-39.4139","Brazil","BR","BRA","Bahia","","11737","1076983532"
"Richmond","Richmond","29.5824","-95.7563","United States","US","USA","Texas","","11768","1840020947"
"Jacó","Jaco","9.6200","-84.6044","Costa Rica","CR","CRI","Puntarenas","","11685","1188795142"
"Lansing","Lansing","42.5667","-76.5316","United States","US","USA","New York","","11767","1840004544"
"Cisternino","Cisternino","40.7333","17.4333","Italy","IT","ITA","Puglia","","11553","1380418763"
"Ambatofisaka II","Ambatofisaka II","-20.0833","47.9000","Madagascar","MG","MDG","Toamasina","","11724","1450651839"
"Singhāna","Singhana","27.9800","75.8000","India","IN","IND","Rājasthān","","11379","1356079407"
"Pontiac","Pontiac","40.8894","-88.6409","United States","US","USA","Illinois","","11766","1840009352"
"Kundurpi","Kundurpi","14.2833","77.0333","India","IN","IND","Andhra Pradesh","","11516","1356209008"
"Brejinho","Brejinho","-6.1908","-35.3569","Brazil","BR","BRA","Rio Grande do Norte","","11577","1076511554"
"Ārambākkam","Arambakkam","13.5258","80.0710","India","IN","IND","Tamil Nādu","","11187","1356750119"
"Ghogaon","Ghogaon","21.9100","75.7000","India","IN","IND","Madhya Pradesh","","11392","1356902730"
"Zero Branco","Zero Branco","45.6000","12.1667","Italy","IT","ITA","Veneto","","11329","1380265651"
"Vasylkivka","Vasylkivka","48.2084","36.0253","Ukraine","UA","UKR","Dnipropetrovska Oblast","","11738","1804783509"
"Gardnerville Ranchos","Gardnerville Ranchos","38.8957","-119.7492","United States","US","USA","Nevada","","11761","1840033828"
"Srikrishnapur","Srikrishnapur","22.9717","88.0351","India","IN","IND","West Bengal","","10345","1356068749"
"Wetherby","Wetherby","53.9276","-1.3839","United Kingdom","GB","GBR","Leeds","","11712","1826431619"
"Sulebhāvi","Sulebhavi","15.8800","74.5600","India","IN","IND","Karnātaka","","10769","1356569298"
"Englefield Green","Englefield Green","51.4301","-0.5699","United Kingdom","GB","GBR","Surrey","","10607","1826887313"
"Hollabrunn","Hollabrunn","48.5667","16.0833","Austria","AT","AUT","Niederösterreich","minor","11681","1040573324"
"Góra","Gora","51.6667","16.5500","Poland","PL","POL","Dolnośląskie","minor","11678","1616282982"
"São João do Manhuaçu","Sao Joao do Manhuacu","-20.3939","-42.1508","Brazil","BR","BRA","Minas Gerais","","11674","1076278406"
"Stokke","Stokke","59.2400","10.2708","Norway","NO","NOR","Vestfold og Telemark","","11657","1578002148"
"Puente Nacional","Puente Nacional","5.8833","-73.6833","Colombia","CO","COL","Santander","minor","11718","1170000515"
"Dolores","Dolores","17.6490","120.7103","Philippines","PH","PHL","Abra","","11512","1608270517"
"Maghra","Maghra","25.1903","85.4921","India","IN","IND","Bihār","","10635","1356373720"
"Lwakhakha","Lwakhakha","0.7967","34.3781","Uganda","UG","UGA","Manafwa","","10700","1800393189"
"Krujë","Kruje","41.5000","19.8000","Albania","AL","ALB","Durrës","","11721","1008696208"
"Aesch","Aesch","47.4694","7.5942","Switzerland","CH","CHE","Basel-Landschaft","","10352","1756884116"
"Rājghāt Garail","Rajghat Garail","25.7618","87.2126","India","IN","IND","Bihār","","10827","1356754283"
"Pachauth","Pachauth","25.5760","86.8083","India","IN","IND","Bihār","","10556","1356289943"
"Sinzheim","Sinzheim","48.7619","8.1669","Germany","DE","DEU","Baden-Württemberg","","11351","1276428146"
"Padiham","Padiham","53.7970","-2.3110","United Kingdom","GB","GBR","Lancashire","","10098","1826532234"
"Bajestān","Bajestan","34.5164","58.1844","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","11741","1364026409"
"Ban Mae Kham Lang Wat","Ban Mae Kham Lang Wat","20.2225","99.8561","Thailand","TH","THA","Chiang Rai","","11569","1764260824"
"Lundazi","Lundazi","-12.3000","33.1833","Zambia","ZM","ZMB","Eastern","","11635","1894277247"
"Namli","Namli","23.4612","75.0604","India","IN","IND","Madhya Pradesh","","11198","1356483237"
"Wolmirstedt","Wolmirstedt","52.2519","11.6297","Germany","DE","DEU","Saxony-Anhalt","","11536","1276525433"
"Carbonera","Carbonera","45.6833","12.2833","Italy","IT","ITA","Veneto","","11183","1380542415"
"Ballston","Ballston","42.9542","-73.8794","United States","US","USA","New York","","11745","1840087194"
"Bordentown","Bordentown","40.1420","-74.7098","United States","US","USA","New Jersey","","11745","1840001476"
"Ambohimahasoa","Ambohimahasoa","-21.1064","47.2161","Madagascar","MG","MDG","Fianarantsoa","","11606","1450769257"
"Briançon","Briancon","44.8958","6.6350","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","11339","1250902899"
"Elk City","Elk City","35.3862","-99.4301","United States","US","USA","Oklahoma","","11743","1840020437"
"Kalakada","Kalakada","13.8167","78.8000","India","IN","IND","Andhra Pradesh","","11461","1356112947"
"Peiting","Peiting","47.8000","10.9333","Germany","DE","DEU","Bavaria","","11588","1276001531"
"River Forest","River Forest","41.8950","-87.8194","United States","US","USA","Illinois","","11742","1840011284"
"Bāra","Bara","25.9286","86.5237","India","IN","IND","Bihār","","10800","1356937398"
"Raubling","Raubling","47.7881","12.1047","Germany","DE","DEU","Bavaria","","11485","1276126414"
"Kopervik","Kopervik","59.2801","5.3016","Norway","NO","NOR","Rogaland","minor","11561","1578682439"
"Magdagachi","Magdagachi","53.4500","125.8000","Russia","RU","RUS","Amurskaya Oblast’","","11739","1643413401"
"Colares","Colares","-0.9369","-48.2819","Brazil","BR","BRA","Pará","","11721","1076075646"
"Saint-Cyprien","Saint-Cyprien","42.6181","3.0064","France","FR","FRA","Occitanie","","11040","1250667528"
"Goasi","Goasi","25.7643","87.4155","India","IN","IND","Bihār","","10744","1356928491"
"Trinidad","Trinidad","5.4089","-71.6622","Colombia","CO","COL","Casanare","minor","11734","1170027404"
"Vauvert","Vauvert","43.6933","4.2761","France","FR","FRA","Occitanie","","11633","1250131972"
"Isaszeg","Isaszeg","47.5333","19.4000","Hungary","HU","HUN","Pest","","11526","1348502220"
"Pimenteiras","Pimenteiras","-6.2450","-41.4189","Brazil","BR","BRA","Piauí","","11733","1076933547"
"Bonito de Santa Fé","Bonito de Santa Fe","-7.3128","-38.5150","Brazil","BR","BRA","Paraíba","","11684","1076000831"
"Zhetibay","Zhetibay","43.5942","52.0789","Kazakhstan","KZ","KAZ","","","11731","1398663278"
"Río Colorado","Rio Colorado","-38.9908","-64.0958","Argentina","AR","ARG","La Pampa","","11733","1032592194"
"Markham","Markham","41.6000","-87.6904","United States","US","USA","Illinois","","11733","1840008130"
"Nemyriv","Nemyriv","48.9794","28.8439","Ukraine","UA","UKR","Vinnytska Oblast","minor","11697","1804059627"
"Missões","Missoes","-14.8839","-44.0908","Brazil","BR","BRA","Minas Gerais","","11715","1076497793"
"Biri","Biri","12.6667","124.3833","Philippines","PH","PHL","Northern Samar","","11274","1608743251"
"Querência do Norte","Querencia do Norte","-23.0839","-53.4839","Brazil","BR","BRA","Paraná","","11729","1076782495"
"Velké Meziříčí","Velke Mezirici","49.3553","16.0123","Czechia","CZ","CZE","Vysočina","","11452","1203943102"
"Sonwān","Sonwan","25.6258","86.2161","India","IN","IND","Bihār","","10338","1356251122"
"Orchha","Orchha","25.3500","78.6400","India","IN","IND","Madhya Pradesh","","11511","1356593159"
"Deutschlandsberg","Deutschlandsberg","46.8161","15.2150","Austria","AT","AUT","Steiermark","minor","11663","1040677286"
"Harsum","Harsum","52.2054","9.9600","Germany","DE","DEU","Lower Saxony","","11496","1276328297"
"Gräfenhainichen","Grafenhainichen","51.7167","12.4333","Germany","DE","DEU","Saxony-Anhalt","","11654","1276450109"
"Sint Anthonis","Sint Anthonis","51.6258","5.8811","Netherlands","NL","NLD","Noord-Brabant","minor","11606","1528221229"
"Nagar Nahusa","Nagar Nahusa","25.3959","85.3431","India","IN","IND","Bihār","","10983","1356046214"
"Rengāli","Rengali","21.6460","84.0531","India","IN","IND","Odisha","","11460","1356978188"
"Salkhua","Salkhua","25.6677","86.6002","India","IN","IND","Bihār","","9757","1356763766"
"Matca","Matca","45.8500","27.5333","Romania","RO","ROU","Galaţi","","11605","1642744486"
"Leidschendam","Leidschendam","52.0833","4.4000","Netherlands","NL","NLD","Zuid-Holland","minor","10482","1528331726"
"Al M’aziz","Al M'aziz","33.6667","-6.3167","Morocco","MA","MAR","Rabat-Salé-Kénitra","","11502","1504103308"
"Yamkanmardi","Yamkanmardi","16.2900","74.6000","India","IN","IND","Mahārāshtra","","11279","1356290365"
"Poggio a Caiano","Poggio a Caiano","43.8167","11.0667","Italy","IT","ITA","Tuscany","","10048","1380864221"
"Telwa","Telwa","26.0877","86.5170","India","IN","IND","Bihār","","9931","1356257721"
"Vakhrusheve","Vakhrusheve","48.1667","38.8333","Ukraine","UA","UKR","Luhanska Oblast","","11421","1804986291"
"Varadarājampettai","Varadarajampettai","11.3553","79.4186","India","IN","IND","Tamil Nādu","","11319","1356171278"
"Elwood","Elwood","40.8462","-73.3389","United States","US","USA","New York","","11722","1840005008"
"Portomaggiore","Portomaggiore","44.7000","11.8000","Italy","IT","ITA","Emilia-Romagna","","11630","1380757237"
"Macusani","Macusani","-14.0692","-70.4311","Peru","PE","PER","Puno","","11707","1604921772"
"Walldürn","Walldurn","49.5831","9.3681","Germany","DE","DEU","Baden-Württemberg","","11612","1276425115"
"Satyavedu","Satyavedu","13.4370","79.9560","India","IN","IND","Andhra Pradesh","","11474","1356474467"
"Mierlo","Mierlo","51.4411","5.6194","Netherlands","NL","NLD","Noord-Brabant","","11105","1528873347"
"Hoeilaart","Hoeilaart","50.7667","4.4667","Belgium","BE","BEL","Flanders","","11172","1056289964"
"Pike Creek Valley","Pike Creek Valley","39.7294","-75.6989","United States","US","USA","Delaware","","11718","1840028614"
"Rāyavaram","Rayavaram","17.1830","81.7000","India","IN","IND","Andhra Pradesh","","11604","1356746535"
"Shanmukhasundarapuram","Shanmukhasundarapuram","10.0065","77.5982","India","IN","IND","Tamil Nādu","","11469","1356228754"
"Candelaria","Candelaria","10.4592","-74.8806","Colombia","CO","COL","Atlántico","minor","11635","1170881306"
"Musile di Piave","Musile di Piave","45.6178","12.5650","Italy","IT","ITA","Veneto","","11461","1380177737"
"Mountain Top","Mountain Top","41.1353","-75.9045","United States","US","USA","Pennsylvania","","11716","1840004911"
"Manabo","Manabo","17.4331","120.7048","Philippines","PH","PHL","Abra","","11611","1608286735"
"Devanakavundanūr","Devanakavundanur","11.5048","77.8383","India","IN","IND","Tamil Nādu","","11056","1356169719"
"Tamezmout","Tamezmout","30.8075","-6.1142","Morocco","MA","MAR","Drâa-Tafilalet","","11697","1504920011"
"Lamosina","Lamosina","-21.6400","46.8989","Madagascar","MG","MDG","Fianarantsoa","","11534","1450091422"
"Conceição do Castelo","Conceicao do Castelo","-20.3678","-41.2439","Brazil","BR","BRA","Espírito Santo","","11681","1076739978"
"Amherst","Amherst","42.8706","-71.6068","United States","US","USA","New Hampshire","","11712","1840054510"
"Durbuy","Durbuy","50.3522","5.4563","Belgium","BE","BEL","Wallonia","","11637","1056196256"
"Sääminki","Saaminki","61.8675","28.8786","Finland","FI","FIN","Etelä-Savo","","11710","1246566624"
"Pacoti","Pacoti","-4.2250","-38.9228","Brazil","BR","BRA","Ceará","","11607","1076844973"
"Isola della Scala","Isola della Scala","45.2692","11.0107","Italy","IT","ITA","Veneto","","11545","1380191231"
"Ghanpur","Ghanpur","17.4989","78.2225","India","IN","IND","Andhra Pradesh","","10890","1356275268"
"El Ançor","El Ancor","35.6833","-0.8667","Algeria","DZ","DZA","Oran","","11469","1012598945"
"Patera","Patera","23.9960","79.6837","India","IN","IND","Madhya Pradesh","","11546","1356602920"
"Mangalam","Mangalam","12.3298","79.1790","India","IN","IND","Tamil Nādu","","11014","1356257846"
"Plymouth","Plymouth","41.6642","-73.0263","United States","US","USA","Connecticut","","11705","1840004913"
"Yāllūru","Yalluru","15.3063","78.4559","India","IN","IND","Andhra Pradesh","","11390","1356167556"
"Kasempa","Kasempa","-13.4550","25.8350","Zambia","ZM","ZMB","North-Western","","11700","1894152710"
"Richfield","Richfield","43.2372","-88.2413","United States","US","USA","Wisconsin","","11704","1840002835"
"Lower Burrell","Lower Burrell","40.5818","-79.7141","United States","US","USA","Pennsylvania","","11703","1840001132"
"Ravanusa","Ravanusa","37.2678","13.9697","Italy","IT","ITA","Sicilia","","11471","1380591598"
"Masanasa","Masanasa","39.4083","-0.3989","Spain","ES","ESP","Valencia","","9929","1724442846"
"Bistān","Bistan","21.6979","75.6657","India","IN","IND","Madhya Pradesh","","10863","1356954569"
"Prymorsk","Prymorsk","46.7353","36.3473","Ukraine","UA","UKR","Zaporizka Oblast","minor","11679","1804278318"
"Isaka","Isaka","-21.1500","47.0000","Madagascar","MG","MDG","Fianarantsoa","","11642","1450450756"
"Altdorf","Altdorf","48.5667","12.1167","Germany","DE","DEU","Bavaria","","11215","1276813399"
"Santa Comba Dão","Santa Comba Dao","40.4000","-8.1167","Portugal","PT","PRT","Viseu","minor","11597","1620669474"
"Geisenfeld","Geisenfeld","48.6667","11.6000","Germany","DE","DEU","Bavaria","","11569","1276340970"
"Taormina","Taormina","37.8518","15.2857","Italy","IT","ITA","Sicilia","","10872","1380790975"
"Sirgora","Sirgora","22.2063","78.8832","India","IN","IND","Madhya Pradesh","","11194","1356927214"
"St. Peter","St. Peter","44.3295","-93.9658","United States","US","USA","Minnesota","","11699","1840008972"
"Kamiita","Kamiita","34.1213","134.4049","Japan","JP","JPN","Tokushima","","11370","1392003439"
"Motegi","Motegi","36.5321","140.1876","Japan","JP","JPN","Tochigi","","11631","1392187288"
"Sablan","Sablan","16.4967","120.4875","Philippines","PH","PHL","Benguet","","11588","1608270967"
"Norton","Norton","41.0294","-81.6461","United States","US","USA","Ohio","","11697","1840000800"
"Nossa Senhora dos Milagres","Nossa Senhora dos Milagres","-12.8700","-39.8589","Brazil","BR","BRA","Bahia","","11659","1076012226"
"Heerlerbaan","Heerlerbaan","50.8692","6.0103","Netherlands","NL","NLD","Limburg","","9872","1528640584"
"Stelle","Stelle","53.3667","10.1167","Germany","DE","DEU","Lower Saxony","","11402","1276799891"
"Dérassi","Derassi","10.1667","3.2667","Benin","BJ","BEN","Borgou","","11630","1204113697"
"South Yarmouth","South Yarmouth","41.6692","-70.2005","United States","US","USA","Massachusetts","","11696","1840004766"
"Somerset","Somerset","37.0834","-84.6109","United States","US","USA","Kentucky","","11696","1840015257"
"Seven Hills","Seven Hills","41.3803","-81.6736","United States","US","USA","Ohio","","11696","1840003411"
"Melsele","Melsele","51.2209","4.2815","Belgium","BE","BEL","Flanders","","11093","1056558146"
"Rakhwāri","Rakhwari","26.3603","86.2882","India","IN","IND","Bihār","","9996","1356451936"
"Campos del Puerto","Campos del Puerto","39.4306","3.0194","Spain","ES","ESP","Balearic Islands","","11618","1724079497"
"Somavārappatti","Somavarappatti","10.6779","77.2131","India","IN","IND","Tamil Nādu","","11275","1356170243"
"Ubaí","Ubai","-16.2850","-44.7778","Brazil","BR","BRA","Minas Gerais","","11681","1076943170"
"Mena","Mena","51.5167","32.2167","Ukraine","UA","UKR","Chernihivska Oblast","","11670","1804891578"
"Dolinsk","Dolinsk","47.3167","142.8000","Russia","RU","RUS","Sakhalinskaya Oblast’","","11685","1643206913"
"Gouvêa","Gouvea","-18.4539","-43.7408","Brazil","BR","BRA","Minas Gerais","","11681","1076858682"
"Laredo","Laredo","43.4144","-3.4100","Spain","ES","ESP","Cantabria","","10996","1724449153"
"Befotaka","Befotaka","-17.0839","48.9667","Madagascar","MG","MDG","Toamasina","","11666","1450744693"
"Oliva","Oliva","-32.0333","-63.5667","Argentina","AR","ARG","Córdoba","minor","11672","1032905249"
"Aniche","Aniche","50.3300","3.2511","France","FR","FRA","Hauts-de-France","","10146","1250709918"
"Billerbeck","Billerbeck","51.9792","7.2950","Germany","DE","DEU","North Rhine-Westphalia","","11566","1276001817"
"Jiwachhpur","Jiwachhpur","26.3120","87.0405","India","IN","IND","Bihār","","10624","1356420456"
"Mitai","Mitai","32.7117","131.3077","Japan","JP","JPN","Miyazaki","","11642","1392405708"
"Pāpireddippatti","Papireddippatti","11.9140","78.3675","India","IN","IND","Tamil Nādu","","11329","1356389803"
"Koori","Koori","37.8547","140.5207","Japan","JP","JPN","Fukushima","","11425","1392003319"
"Frenštát pod Radhoštěm","Frenstat pod Radhostem","49.5483","18.2108","Czechia","CZ","CZE","Moravskoslezský Kraj","","10766","1203465419"
"Minabe","Minabe","33.7725","135.3206","Japan","JP","JPN","Wakayama","","11594","1392208953"
"Bucyrus","Bucyrus","40.8054","-82.9719","United States","US","USA","Ohio","","11690","1840007170"
"Muzaffarnagar","Muzaffarnagar","29.4820","77.7000","India","IN","IND","Uttar Pradesh","","10793","1356592356"
"Sandy","Sandy","41.1447","-78.7295","United States","US","USA","Pennsylvania","","11689","1840005149"
"Gander","Gander","48.9569","-54.6089","Canada","CA","CAN","Newfoundland and Labrador","","11688","1124310517"
"East Glenville","East Glenville","42.8648","-73.9256","United States","US","USA","New York","","11687","1840004444"
"Bālakrishnanpatti","Balakrishnanpatti","11.2511","78.4736","India","IN","IND","Tamil Nādu","","11328","1356235291"
"Ovada","Ovada","44.6392","8.6464","Italy","IT","ITA","Piedmont","","11365","1380731668"
"Mūdashedde","Mudashedde","12.9300","74.8800","India","IN","IND","Karnātaka","","9790","1356646886"
"Hanover","Hanover","40.6668","-75.3979","United States","US","USA","Pennsylvania","","11685","1840151272"
"Chak Thāt","Chak That","25.7347","86.0340","India","IN","IND","Bihār","","9787","1356871109"
"Jigani","Jigani","12.7861","77.6385","India","IN","IND","Karnātaka","","10389","1356570809"
"Ostrhauderfehn","Ostrhauderfehn","53.1167","7.6167","Germany","DE","DEU","Lower Saxony","","11458","1276456715"
"Eningen unter Achalm","Eningen unter Achalm","48.4831","9.2522","Germany","DE","DEU","Baden-Württemberg","","11199","1276247117"
"Naryn","Naryn","41.1306","72.0806","Kyrgyzstan","KG","KGZ","Jalal-Abad","","11306","1417067867"
"Sunninghill","Sunninghill","51.4025","-0.6550","United Kingdom","GB","GBR","Windsor and Maidenhead","","11603","1826713508"
"Sultānpur","Sultanpur","23.1381","77.9340","India","IN","IND","Madhya Pradesh","","11507","1356465017"
"Āhiro","Ahiro","24.9192","87.1054","India","IN","IND","Bihār","","10223","1356496570"
"Caorle","Caorle","45.6000","12.8833","Italy","IT","ITA","Veneto","","11606","1380538746"
"Mendota Heights","Mendota Heights","44.8815","-93.1400","United States","US","USA","Minnesota","","11681","1840007855"
"Hinwil","Hinwil","47.3033","8.8444","Switzerland","CH","CHE","Zürich","minor","11179","1756628529"
"Schiller Park","Schiller Park","41.9586","-87.8693","United States","US","USA","Illinois","","11680","1840011320"
"Douglas","Douglas","31.5065","-82.8544","United States","US","USA","Georgia","","11680","1840014946"
"Santa Clara","Santa Clara","44.1154","-123.1344","United States","US","USA","Oregon","","11680","1840145606"
"Bytča","Bytca","49.2242","18.5642","Slovakia","SK","SVK","Žilina","minor","11414","1703630221"
"Chantilly","Chantilly","49.1869","2.4608","France","FR","FRA","Hauts-de-France","","11001","1250822296"
"Yirol","Yirol","6.5600","30.5100","South Sudan","SS","SSD","Lakes","","11650","1728880750"
"Tāzhakudi","Tazhakudi","8.2348","77.4533","India","IN","IND","Tamil Nādu","","11309","1356223880"
"Rājpur Kalān","Rajpur Kalan","25.6792","84.1259","India","IN","IND","Bihār","","10486","1356756195"
"Udachnyy","Udachnyy","66.4000","112.3167","Russia","RU","RUS","Sakha (Yakutiya)","","11676","1643138235"
"Guaiçara","Guaicara","-21.6219","-49.7986","Brazil","BR","BRA","São Paulo","","11633","1076186233"
"Parvatgiri","Parvatgiri","17.7417","79.7264","India","IN","IND","Andhra Pradesh","","11355","1356961771"
"Bad Nenndorf","Bad Nenndorf","52.3369","9.3786","Germany","DE","DEU","Lower Saxony","","11189","1276123356"
"Tulshia","Tulshia","26.3468","87.8411","India","IN","IND","Bihār","","10523","1356495785"
"Capela de Santana","Capela de Santana","-29.7000","-51.3250","Brazil","BR","BRA","Rio Grande do Sul","","11612","1076636417"
"Lawrenceburg","Lawrenceburg","38.0332","-84.9031","United States","US","USA","Kentucky","","11675","1840014321"
"Murapāka","Murapaka","18.2282","83.7926","India","IN","IND","Andhra Pradesh","","11134","1356220082"
"Yercaud","Yercaud","11.7794","78.2034","India","IN","IND","Tamil Nādu","","11550","1356029064"
"Shuinancun","Shuinancun","23.2995","112.3753","China","CN","CHN","Guangdong","","11598","1156552139"
"Myrtle Grove","Myrtle Grove","34.1230","-77.8834","United States","US","USA","North Carolina","","11672","1840013632"
"Pīr Bakrān","Pir Bakran","32.4689","51.5578","Iran","IR","IRN","Eşfahān","","10851","1364421592"
"La Calamine","La Calamine","50.7000","6.0000","Belgium","BE","BEL","Wallonia","","11061","1056326759"
"Pepperell","Pepperell","42.6713","-71.6043","United States","US","USA","Massachusetts","","11671","1840053483"
"Loutráki","Loutraki","37.9750","22.9767","Greece","GR","GRC","Pelopónnisos","minor","11564","1300702488"
"Golet","Golet","19.2390","79.3891","India","IN","IND","Andhra Pradesh","","11524","1356196346"
"Surinam","Surinam","-20.5139","57.5111","Mauritius","MU","MUS","Savanne","","10507","1480977904"
"Diao’ecun","Diao'ecun","40.7227","115.8201","China","CN","CHN","Hebei","","11631","1156445003"
"Xiaoba","Xiaoba","26.7217","106.9236","China","CN","CHN","Guizhou","","11560","1156220508"
"Tlachichilco","Tlachichilco","20.6217","-98.1994","Mexico","MX","MEX","Veracruz","minor","11630","1484876244"
"Lyuban","Lyuban","52.7819","28.0525","Belarus","BY","BLR","Minskaya Voblasts’","minor","11654","1112654360"
"Schwaigern","Schwaigern","49.1333","9.0500","Germany","DE","DEU","Baden-Württemberg","","11438","1276311065"
"De Pinte","De Pinte","51.0000","3.6500","Belgium","BE","BEL","Flanders","","11046","1056508352"
"Rosario de Mora","Rosario de Mora","13.5833","-89.2167","El Salvador","SV","SLV","San Salvador","","11377","1222795595"
"Port Morant","Port Morant","17.9000","-76.3167","Jamaica","JM","JAM","Saint Thomas","","11536","1388362870"
"Tārar","Tarar","25.1821","87.1671","India","IN","IND","Bihār","","10255","1356532159"
"Sidi Tabet","Sidi Tabet","36.9139","10.0361","Tunisia","TN","TUN","L’Ariana","","11385","1788986316"
"Yelandūr","Yelandur","12.0700","77.0300","India","IN","IND","Karnātaka","","11332","1356224174"
"Saint Helena Bay","Saint Helena Bay","-32.7583","18.0278","South Africa","ZA","ZAF","Western Cape","","11529","1710255294"
"Cocentaina","Cocentaina","38.7450","-0.4406","Spain","ES","ESP","Valencia","","11451","1724102234"
"Esperanza","Esperanza","22.4472","-80.0966","Cuba","CU","CUB","Villa Clara","","11555","1192015755"
"Vernouillet","Vernouillet","48.9722","1.9833","France","FR","FRA","Île-de-France","","10104","1250772966"
"Eureka","Eureka","38.5004","-90.6491","United States","US","USA","Missouri","","11663","1840008582"
"Huandacareo","Huandacareo","19.9906","-101.2750","Mexico","MX","MEX","Michoacán","minor","11592","1484155611"
"Alburquerque","Alburquerque","9.6104","123.9549","Philippines","PH","PHL","Bohol","","11246","1608280390"
"Halavāgalu","Halavagalu","14.7083","75.7753","India","IN","IND","Karnātaka","","11381","1356918529"
"Milattūr","Milattur","10.8576","79.2394","India","IN","IND","Tamil Nādu","","10695","1356174047"
"Jódar","Jodar","37.8333","-3.3500","Spain","ES","ESP","Andalusia","","11583","1724042149"
"Hallbergmoos","Hallbergmoos","48.3333","11.7500","Germany","DE","DEU","Bavaria","","11337","1276167916"
"Rudersberg","Rudersberg","48.8856","9.5281","Germany","DE","DEU","Baden-Württemberg","","11382","1276269287"
"Hidaka","Hidaka","42.4803","142.0743","Japan","JP","JPN","Hokkaidō","","11648","1392003301"
"Mestrino","Mestrino","45.4500","11.7667","Italy","IT","ITA","Veneto","","11502","1380345520"
"Rothesay","Rothesay","45.3831","-65.9969","Canada","CA","CAN","New Brunswick","","11659","1124211328"
"Mangabe","Mangabe","-16.9500","49.3333","Madagascar","MG","MDG","Toamasina","","11583","1450932004"
"Radnevo","Radnevo","42.3000","25.9333","Bulgaria","BG","BGR","Stara Zagora","","11622","1100743312"
"Elma","Elma","42.8231","-78.6371","United States","US","USA","New York","","11658","1840058079"
"Singapperumālkovil","Singapperumalkovil","12.7595","80.0075","India","IN","IND","Tamil Nādu","","10669","1356823994"
"Tonse West","Tonse West","13.3968","74.7069","India","IN","IND","Karnātaka","","11032","1356117843"
"Ionia","Ionia","42.9773","-85.0727","United States","US","USA","Michigan","","11655","1840003075"
"Fatehpur","Fatehpur","25.3032","87.8623","India","IN","IND","West Bengal","","10423","1356847421"
"Port Lavaca","Port Lavaca","28.6181","-96.6278","United States","US","USA","Texas","","11654","1840020996"
"Fortaleza dos Nogueiras","Fortaleza dos Nogueiras","-6.9639","-46.1769","Brazil","BR","BRA","Maranhão","","11646","1076248622"
"Kamānpur","Kamanpur","18.6667","79.5000","India","IN","IND","Andhra Pradesh","","11048","1356269111"
"Ban Mae Tuen","Ban Mae Tuen","18.0100","98.8809","Thailand","TH","THA","Lamphun","","11617","1764530285"
"Trecastagni","Trecastagni","37.6167","15.0833","Italy","IT","ITA","Sicilia","","11074","1380667975"
"Little Chute","Little Chute","44.2906","-88.3208","United States","US","USA","Wisconsin","","11652","1840002408"
"Poulsbo","Poulsbo","47.7417","-122.6407","United States","US","USA","Washington","","11652","1840019822"
"Kuřim","Kurim","49.2985","16.5315","Czechia","CZ","CZE","Jihomoravský Kraj","","11021","1203206546"
"Oulad Friha","Oulad Friha","32.6108","-7.6250","Morocco","MA","MAR","Casablanca-Settat","","11581","1504629949"
"Franklin","Franklin","39.5538","-84.2952","United States","US","USA","Ohio","","11650","1840008466"
"Bonate di Sopra","Bonate di Sopra","45.6833","9.5667","Italy","IT","ITA","Lombardy","","10020","1380392086"
"Center","Center","40.6483","-80.2977","United States","US","USA","Pennsylvania","","11649","1840144996"
"Lardero","Lardero","42.4261","-2.4614","Spain","ES","ESP","La Rioja","","11094","1724575965"
"Annoeullin","Annoeullin","50.5294","2.9328","France","FR","FRA","Hauts-de-France","","10486","1250709099"
"Sagada","Sagada","17.0842","120.9008","Philippines","PH","PHL","Mountain","","11510","1608945892"
"Drawsko Pomorskie","Drawsko Pomorskie","53.5333","15.8000","Poland","PL","POL","Zachodniopomorskie","minor","11597","1616969885"
"Mala Vyska","Mala Vyska","48.6500","31.6333","Ukraine","UA","UKR","Kirovohradska Oblast","minor","11614","1804230769"
"Auhar Sheikh","Auhar Sheikh","26.7216","84.5993","India","IN","IND","Bihār","","10310","1356974954"
"Liberty Lake","Liberty Lake","47.6686","-117.1036","United States","US","USA","Washington","","11647","1840037678"
"Mistelbach","Mistelbach","48.5667","16.5667","Austria","AT","AUT","Niederösterreich","minor","11559","1040942506"
"Tudela","Tudela","10.6360","124.4700","Philippines","PH","PHL","Cebu","","11304","1608811039"
"Sidi Moussa Ben Ali","Sidi Moussa Ben Ali","33.5594","-7.3817","Morocco","MA","MAR","Casablanca-Settat","","11445","1504600250"
"Ross-Bétio","Ross-Betio","16.2667","-16.1333","Senegal","SN","SEN","Saint-Louis","","11588","1686215885"
"Brock","Brock","44.3167","-79.0833","Canada","CA","CAN","Ontario","","11642","1124001106"
"Hipperholme","Hipperholme","53.7258","-1.8120","United Kingdom","GB","GBR","Calderdale","","11308","1826718988"
"Carver","Carver","41.8739","-70.7563","United States","US","USA","Massachusetts","","11641","1840053565"
"Kalmiuske","Kalmiuske","47.6667","38.0667","Ukraine","UA","UKR","Donetska Oblast","minor","11603","1804369480"
"Nzeto","Nzeto","-7.2290","12.8650","Angola","AO","AGO","Zaire","","11633","1024025450"
"Okpo","Okpo","18.1167","95.6667","Myanmar","MM","MMR","Bago","","11525","1104664446"
"Santa Cruz Itundujia","Santa Cruz Itundujia","16.8667","-97.6500","Mexico","MX","MEX","Oaxaca","","11616","1484802157"
"Jamaat Shaim","Jamaat Shaim","32.3500","-8.8500","Morocco","MA","MAR","Marrakech-Safi","","11251","1504695746"
"Sahuria","Sahuria","25.8197","86.6132","India","IN","IND","Bihār","","10519","1356521484"
"Ban Bang Toei","Ban Bang Toei","14.0656","100.5226","Thailand","TH","THA","Pathum Thani","","10164","1764390237"
"Videle","Videle","44.2833","25.5333","Romania","RO","ROU","Teleorman","","11508","1642959308"
"Anatolí","Anatoli","39.6386","20.8661","Greece","GR","GRC","Ípeiros","","9798","1300401701"
"Khair Khān","Khair Khan","26.2727","87.3037","India","IN","IND","Bihār","","10240","1356070540"
"Motīpur","Motipur","25.8159","86.0140","India","IN","IND","Bihār","","9736","1356349446"
"Dautphe","Dautphe","50.8583","8.5500","Germany","DE","DEU","Hesse","","11475","1276569570"
"Lenvik","Lenvik","69.3836","17.9675","Norway","NO","NOR","Troms og Finnmark","","11618","1578586323"
"Bellmawr","Bellmawr","39.8666","-75.0941","United States","US","USA","New Jersey","","11631","1840000731"
"Dumri","Dumri","25.5263","86.7346","India","IN","IND","Bihār","","10437","1356576647"
"Jaqueira","Jaqueira","-8.7269","-35.7928","Brazil","BR","BRA","Pernambuco","","11501","1076486587"
"Möhlin","Mohlin","47.5583","7.8458","Switzerland","CH","CHE","Aargau","","11042","1756997029"
"Quebrangulo","Quebrangulo","-9.3189","-36.4708","Brazil","BR","BRA","Alagoas","","11592","1076029239"
"Asbury Lake","Asbury Lake","30.0472","-81.7853","United States","US","USA","Florida","","11629","1840029019"
"Spring Lake","Spring Lake","35.1843","-78.9962","United States","US","USA","North Carolina","","11629","1840017875"
"Montegrotto Terme","Montegrotto Terme","45.3333","11.7833","Italy","IT","ITA","Veneto","","11448","1380955470"
"Santo Antônio do Jacinto","Santo Antonio do Jacinto","-16.5339","-40.1758","Brazil","BR","BRA","Minas Gerais","","11604","1076580986"
"Lunenburg","Lunenburg","42.5897","-71.7199","United States","US","USA","Massachusetts","","11627","1840053592"
"Dodvad","Dodvad","15.7900","74.7500","India","IN","IND","Karnātaka","","11241","1356071410"
"Chikkārampālaiyam","Chikkarampalaiyam","11.2416","76.9854","India","IN","IND","Tamil Nādu","","11177","1356996952"
"Calheta","Calheta","32.7258","-17.1658","Portugal","PT","PRT","Madeira","","11521","1620158427"
"Show Low","Show Low","34.2671","-110.0384","United States","US","USA","Arizona","","11623","1840021582"
"Winton","Winton","37.3854","-120.6173","United States","US","USA","California","","11623","1840018933"
"Warden","Warden","-27.8539","28.9667","South Africa","ZA","ZAF","Free State","","10977","1710026045"
"Camisano Vicentino","Camisano Vicentino","45.5167","11.7167","Italy","IT","ITA","Veneto","","11248","1380821535"
"Hlaingbwe","Hlaingbwe","17.1333","97.8333","Myanmar","MM","MMR","Kayin State","","11511","1104110875"
"Staden","Staden","50.9750","3.0147","Belgium","BE","BEL","Flanders","","11376","1056254090"
"Shāhzādpur","Shahzadpur","25.6541","86.8709","India","IN","IND","Bihār","","9839","1356823439"
"Oñate","Onate","43.0333","-2.4167","Spain","ES","ESP","Basque Country","","11515","1724579376"
"East Greenwich","East Greenwich","39.7903","-75.2396","United States","US","USA","New Jersey","","11621","1840081671"
"Schöningen","Schoningen","52.1333","10.9500","Germany","DE","DEU","Lower Saxony","","11306","1276898175"
"Chegem Vtoroy","Chegem Vtoroy","43.5667","43.5833","Russia","RU","RUS","Kabardino-Balkariya","","11575","1643003953"
"Fuente de Oro","Fuente de Oro","3.4667","-73.6167","Colombia","CO","COL","Meta","minor","11599","1170263395"
"Ulricehamn","Ulricehamn","57.7833","13.4167","Sweden","SE","SWE","Västra Götaland","minor","11410","1752323454"
"Sidi Kasem","Sidi Kasem","35.5339","-5.2183","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","11537","1504343854"
"Derdara","Derdara","35.1103","-5.2872","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","11547","1504274445"
"Qaşr-e Qand","Qasr-e Qand","26.2483","60.7525","Iran","IR","IRN","Sīstān va Balūchestān","","11605","1364737765"
"Gravelines","Gravelines","50.9864","2.1275","France","FR","FRA","Hauts-de-France","","11132","1250101998"
"James Island","James Island","32.7353","-79.9394","United States","US","USA","South Carolina","","11618","1840043446"
"La Unión","La Union","8.8606","-75.2806","Colombia","CO","COL","Sucre","minor","11566","1170400912"
"Lézignan-Corbières","Lezignan-Corbieres","43.2006","2.7578","France","FR","FRA","Occitanie","","11317","1250690602"
"Summerland","Summerland","49.6006","-119.6778","Canada","CA","CAN","British Columbia","","11615","1124400731"
"Den Chai","Den Chai","17.9835","100.0519","Thailand","TH","THA","Phrae","minor","11542","1764349674"
"Valpovo","Valpovo","45.6667","18.4167","Croatia","HR","HRV","Osječko-Baranjska Županija","minor","11563","1191247784"
"Ihumwa","Ihumwa","-6.1667","35.8833","Tanzania","TZ","TZA","Dodoma","","11490","1834975730"
"Big Lake","Big Lake","45.3417","-93.7434","United States","US","USA","Minnesota","","11614","1840006701"
"Rāmbilli","Rambilli","17.4644","82.9314","India","IN","IND","Andhra Pradesh","","10880","1356182492"
"Cadale","Cadale","2.7500","46.3167","Somalia","SO","SOM","Shabeellaha Dhexe","","11600","1706665542"
"Hisar","Hisar","26.5495","85.9406","India","IN","IND","Bihār","","10352","1356224432"
"Hanover","Hanover","43.7156","-72.1910","United States","US","USA","New Hampshire","","11612","1840054971"
"Sīrpanandal","Sirpanandal","11.9741","79.0428","India","IN","IND","Tamil Nādu","","11263","1356207460"
"Santa Lucía","Santa Lucia","-28.9833","-59.1000","Argentina","AR","ARG","Corrientes","minor","11589","1032860386"
"Selsey","Selsey","50.7350","-0.7898","United Kingdom","GB","GBR","West Sussex","","10737","1826710788"
"Krynica","Krynica","49.4117","20.9550","Poland","PL","POL","Małopolskie","","11361","1616638760"
"Batemans Bay","Batemans Bay","-35.7081","150.1744","Australia","AU","AUS","New South Wales","","11294","1036272805"
"Likhoslavl","Likhoslavl","57.1333","35.4833","Russia","RU","RUS","Tverskaya Oblast’","","11594","1643616895"
"Firminópolis","Firminopolis","-16.5819","-50.3050","Brazil","BR","BRA","Goiás","","11580","1076315971"
"Marktheidenfeld","Marktheidenfeld","49.8500","9.6000","Germany","DE","DEU","Bavaria","","11292","1276902218"
"Boscotrecase","Boscotrecase","40.7833","14.4667","Italy","IT","ITA","Campania","","10247","1380959996"
"Gelves","Gelves","37.3333","-6.0167","Spain","ES","ESP","Andalusia","","10317","1724803534"
"Kem","Kem","64.9500","34.5833","Russia","RU","RUS","Kareliya","","11604","1643304829"
"Ban Saeo","Ban Saeo","20.2158","100.2250","Thailand","TH","THA","Chiang Rai","","11444","1764365143"
"Anuppampattu","Anuppampattu","13.3018","80.2258","India","IN","IND","Tamil Nādu","","10850","1356833612"
"Nauheim","Nauheim","49.9447","8.4548","Germany","DE","DEU","Hesse","","10819","1276153324"
"Baghānt","Baghant","26.1748","86.1705","India","IN","IND","Bihār","","9889","1356697262"
"Bela","Bela","24.9689","84.9740","India","IN","IND","Bihār","","10028","1356422249"
"Shengping","Shengping","28.4865","98.9130","China","CN","CHN","Yunnan","minor","11595","1156854097"
"Ingelmunster","Ingelmunster","50.9208","3.2533","Belgium","BE","BEL","Flanders","","10928","1056487439"
"Hexham","Hexham","54.9710","-2.1010","United Kingdom","GB","GBR","Northumberland","","11139","1826406735"
"Kurgunta","Kurgunta","17.2000","77.3600","India","IN","IND","Karnātaka","","11337","1356004478"
"Mortād","Mortad","18.8167","78.4833","India","IN","IND","Andhra Pradesh","","11123","1356027669"
"Bāra Khurd","Bara Khurd","25.2599","85.4325","India","IN","IND","Bihār","","10059","1356160442"
"Okuizumo","Okuizumo","35.1973","133.0023","Japan","JP","JPN","Shimane","","11570","1392003330"
"Khorramābād","Khorramabad","36.7828","50.8722","Iran","IR","IRN","Māzandarān","","11542","1364053905"
"Uchchangidurgam","Uchchangidurgam","14.5614","76.0537","India","IN","IND","Karnātaka","","11373","1356742899"
"Sangrāmpur","Sangrampur","25.0711","86.1871","India","IN","IND","Bihār","","10379","1356807538"
"Temamatla","Temamatla","19.2028","-98.8700","Mexico","MX","MEX","México","minor","11206","1484069603"
"Svidník","Svidnik","49.3056","21.5678","Slovakia","SK","SVK","Prešov","minor","11096","1703982053"
"Barahbatta","Barahbatta","25.7727","85.7522","India","IN","IND","Bihār","","9653","1356546582"
"Yellayapālem","Yellayapalem","14.5378","79.9515","India","IN","IND","Andhra Pradesh","","10843","1356486387"
"Sursee","Sursee","47.1667","8.1167","Switzerland","CH","CHE","Luzern","minor","9900","1756349177"
"’Aïn Roua","'Ain Roua","36.3344","5.1806","Algeria","DZ","DZA","Sétif","","11499","1012529757"
"Dzouz","Dzouz","31.8900","-7.3200","Morocco","MA","MAR","Marrakech-Safi","","11501","1504484074"
"Thanh Xuân","Thanh Xuan","10.2308","106.3253","Vietnam","VN","VNM","Bến Tre","","11051","1704171355"
"Timahdit","Timahdit","33.2369","-5.0600","Morocco","MA","MAR","Fès-Meknès","","10945","1504460788"
"Săcueni","Sacueni","47.3525","22.0914","Romania","RO","ROU","Bihor","","11526","1642478221"
"De Panne","De Panne","51.1019","2.5917","Belgium","BE","BEL","Flanders","","11129","1056000077"
"Konanūr","Konanur","12.6333","76.0500","India","IN","IND","Karnātaka","","11284","1356335922"
"Padre Burgos","Padre Burgos","10.0333","125.0167","Philippines","PH","PHL","Southern Leyte","minor","11159","1608301402"
"Ghinda’e","Ghinda'e","15.4500","39.0833","Eritrea","ER","ERI","Semienawi K’eyyĭḥ Baḥri","","11560","1232629758"
"La Riche","La Riche","47.3892","0.6606","France","FR","FRA","Centre-Val de Loire","","10331","1250677508"
"Caraguatay","Caraguatay","-25.2333","-56.8167","Paraguay","PY","PRY","Cordillera","","11568","1600813357"
"Kuppachchipālaiyam","Kuppachchipalaiyam","11.0273","78.1131","India","IN","IND","Tamil Nādu","","10787","1356241675"
"Nāgojanahalli","Nagojanahalli","12.3570","78.2886","India","IN","IND","Tamil Nādu","","11099","1356597348"
"Tadley","Tadley","51.3506","-1.1376","United Kingdom","GB","GBR","Hampshire","","11473","1826538843"
"Gandhāri","Gandhari","18.3932","78.1174","India","IN","IND","Andhra Pradesh","","11432","1356140911"
"Mexico","Mexico","39.1625","-91.8712","United States","US","USA","Missouri","","11592","1840008515"
"Ouro Branco","Ouro Branco","-9.1667","-37.3567","Brazil","BR","BRA","Alagoas","","11535","1076943029"
"Bannewitz","Bannewitz","50.9931","13.7167","Germany","DE","DEU","Saxony","","11157","1276329981"
"Usuppūr","Usuppur","11.3815","79.6970","India","IN","IND","Tamil Nādu","","10616","1356241129"
"Siniscola","Siniscola","40.5743","9.6963","Italy","IT","ITA","Sardegna","","11531","1380249101"
"Muroto-misakicho","Muroto-misakicho","33.2900","134.1519","Japan","JP","JPN","Kōchi","","11543","1392826132"
"Odugattūr","Odugattur","12.7679","78.8830","India","IN","IND","Tamil Nādu","","10755","1356192754"
"Stollberg","Stollberg","50.7083","12.7783","Germany","DE","DEU","Saxony","","11303","1276429853"
"Emmaus","Emmaus","40.5352","-75.4978","United States","US","USA","Pennsylvania","","11589","1840001048"
"Rāmchandrapur","Ramchandrapur","22.9000","88.4800","India","IN","IND","West Bengal","","10312","1356118929"
"Damonojodi","Damonojodi","18.7632","82.9194","India","IN","IND","Odisha","","11187","1356446315"
"Epazoyucan","Epazoyucan","20.0177","-98.6361","Mexico","MX","MEX","Hidalgo","minor","11522","1484543443"
"Risch","Risch","47.1411","8.4314","Switzerland","CH","CHE","Zug","","10857","1756009086"
"Werther","Werther","52.0750","8.4125","Germany","DE","DEU","North Rhine-Westphalia","","11274","1276520918"
"Ātharga","Atharga","16.9875","75.8863","India","IN","IND","Karnātaka","","11365","1356320437"
"Bhagirathpur","Bhagirathpur","24.0912","88.4947","India","IN","IND","Bihār","","10125","1356789321"
"Sesto Calende","Sesto Calende","45.7333","8.6333","Italy","IT","ITA","Lombardy","","11141","1380964892"
"Cranleigh","Cranleigh","51.1363","-0.4784","United Kingdom","GB","GBR","Surrey","","11241","1826383822"
"Konakondla","Konakondla","15.1053","77.3640","India","IN","IND","Andhra Pradesh","","11382","1356280772"
"Rutesheim","Rutesheim","48.8097","8.9450","Germany","DE","DEU","Baden-Württemberg","","10916","1276637060"
"Huanian","Huanian","24.0781","102.2010","China","CN","CHN","Yunnan","","11538","1156810217"
"Simri","Simri","26.3825","86.1705","India","IN","IND","Bihār","","9983","1356089842"
"Santa Genoveva de Docordó","Santa Genoveva de Docordo","4.2586","-77.3675","Colombia","CO","COL","Chocó","minor","11579","1170336944"
"Mango","Mango","27.9914","-82.3070","United States","US","USA","Florida","","11582","1840014157"
"Val-des-Monts","Val-des-Monts","45.6500","-75.6667","Canada","CA","CAN","Quebec","","11582","1124001051"
"Kodmiāl","Kodmial","18.6333","78.9000","India","IN","IND","Andhra Pradesh","","10892","1356078012"
"Adendorf","Adendorf","53.2833","10.4500","Germany","DE","DEU","Lower Saxony","","10903","1276724557"
"Irmo","Irmo","34.1018","-81.1956","United States","US","USA","South Carolina","","11581","1840016723"
"Rounia","Rounia","25.5179","87.3999","India","IN","IND","Bihār","","10350","1356347491"
"Kuttappatti","Kuttappatti","11.7939","77.9225","India","IN","IND","Tamil Nādu","","10920","1356167967"
"Gympie","Gympie","-26.1900","152.6655","Australia","AU","AUS","Queensland","","10803","1036391013"
"Recke","Recke","52.3700","7.7189","Germany","DE","DEU","North Rhine-Westphalia","","11371","1276107083"
"Ézanville","Ezanville","49.0278","2.3608","France","FR","FRA","Île-de-France","","9719","1250436744"
"Khawaspur","Khawaspur","26.2331","84.6388","India","IN","IND","Bihār","","10355","1356544716"
"Vineyard","Vineyard","40.3059","-111.7545","United States","US","USA","Utah","","11579","1840022419"
"Comala","Comala","19.3208","-103.7603","Mexico","MX","MEX","Colima","minor","9649","1484287042"
"Pfastatt","Pfastatt","47.7689","7.3017","France","FR","FRA","Grand Est","","9723","1250577618"
"Leatherhead","Leatherhead","51.2950","-0.3290","United Kingdom","GB","GBR","Surrey","","11316","1826812300"
"Konganāpuram","Konganapuram","11.5710","77.9004","India","IN","IND","Tamil Nādu","","10670","1356191649"
"Schönaich","Schonaich","48.6569","9.0628","Germany","DE","DEU","Baden-Württemberg","","10813","1276014009"
"Mallagunta","Mallagunta","12.6343","78.5194","India","IN","IND","Tamil Nādu","","10969","1356120013"
"Trostberg an der Alz","Trostberg an der Alz","48.0167","12.5500","Germany","DE","DEU","Bavaria","","11355","1276546741"
"Sheron","Sheron","30.1582","75.7178","India","IN","IND","Punjab","","11132","1356755430"
"Koch","Koch","24.9259","84.7724","India","IN","IND","Bihār","","11035","1356971981"
"Bharhopur","Bharhopur","25.9488","84.5274","India","IN","IND","Bihār","","9903","1356544709"
"Elizabethtown","Elizabethtown","40.1533","-76.5990","United States","US","USA","Pennsylvania","","11574","1840003716"
"Patterson","Patterson","41.4849","-73.5921","United States","US","USA","New York","","11574","1840058386"
"Navarro","Navarro","-35.0167","-59.2667","Argentina","AR","ARG","Buenos Aires","minor","11562","1032226525"
"Greensburg","Greensburg","39.3518","-85.5028","United States","US","USA","Indiana","","11573","1840014567"
"Bek-Abad","Bek-Abad","40.8472","72.9750","Kyrgyzstan","KG","KGZ","Jalal-Abad","","11476","1417546505"
"Babhniyāwān","Babhniyawan","25.4943","84.4815","India","IN","IND","Bihār","","10325","1356981784"
"Riesi","Riesi","37.2833","14.0833","Italy","IT","ITA","Sicilia","","11402","1380200114"
"Tawnza","Tawnza","32.0944","-6.6025","Morocco","MA","MAR","Béni Mellal-Khénifra","","11488","1504959614"
"Masakkavundanchettipālaiyam","Masakkavundanchettipalaiyam","11.1473","77.0756","India","IN","IND","Tamil Nādu","","10961","1356160022"
"Bora","Bora","23.6585","76.8112","India","IN","IND","Madhya Pradesh","","11233","1356323207"
"Middle Valley","Middle Valley","35.1877","-85.1959","United States","US","USA","Tennessee","","11569","1840013450"
"Yuasa","Yuasa","34.0294","135.1903","Japan","JP","JPN","Wakayama","","11038","1392420914"
"Bouabout","Bouabout","31.2667","-9.1833","Morocco","MA","MAR","Marrakech-Safi","","11494","1504119259"
"Gustavsberg","Gustavsberg","59.3333","18.3833","Sweden","SE","SWE","Stockholm","minor","11333","1752098351"
"Taché","Tache","49.7081","-96.6736","Canada","CA","CAN","Manitoba","","11568","1124000169"
"Kaniyūr","Kaniyur","11.0930","77.1560","India","IN","IND","Tamil Nādu","","10930","1356159500"
"Luathaha","Luathaha","26.6316","84.9079","India","IN","IND","Bihār","","10223","1356996555"
"Chettimangurichchi","Chettimangurichchi","11.6393","77.8557","India","IN","IND","Tamil Nādu","","10659","1356168547"
"Phanat Nikhom","Phanat Nikhom","13.4458","101.1844","Thailand","TH","THA","Chon Buri","minor","11032","1764762615"
"Satuek","Satuek","15.3008","103.3013","Thailand","TH","THA","Buri Ram","minor","11444","1764714909"
"Grobbendonk","Grobbendonk","51.2000","4.7167","Belgium","BE","BEL","Flanders","","11172","1056834518"
"Puerto Suárez","Puerto Suarez","-18.9667","-57.7981","Bolivia","BO","BOL","Santa Cruz","","11564","1068099903"
"Severnyy","Severnyy","67.6083","64.1233","Russia","RU","RUS","Komi","","11562","1643427677"
"Itārhi","Itarhi","25.4832","84.0129","India","IN","IND","Bihār","","10669","1356098572"
"Angicos","Angicos","-5.6658","-36.6008","Brazil","BR","BRA","Rio Grande do Norte","","11549","1076687770"
"Salaya","Salaya","13.8023","100.3208","Thailand","TH","THA","Nakhon Pathom","minor","11180","1764189749"
"Barni","Barni","25.3484","85.0594","India","IN","IND","Bihār","","9914","1356558002"
"Shankarpalli","Shankarpalli","17.4523","78.1310","India","IN","IND","Andhra Pradesh","","11055","1356000190"
"Whitchurch","Whitchurch","51.4064","-2.5594","United Kingdom","GB","GBR","Bath and North East Somerset","","11061","1826527232"
"Pāta Ellamilli","Pata Ellamilli","16.8473","81.4884","India","IN","IND","Andhra Pradesh","","11081","1356871455"
"Maxéville","Maxeville","48.7114","6.1631","France","FR","FRA","Grand Est","","9818","1250407242"
"Estiva Gerbi","Estiva Gerbi","-22.2708","-46.9450","Brazil","BR","BRA","São Paulo","","11407","1076001518"
"Kovūrupalli","Kovurupalli","14.7772","79.9999","India","IN","IND","Andhra Pradesh","","11258","1356595540"
"Kirchhundem","Kirchhundem","51.1000","8.0833","Germany","DE","DEU","North Rhine-Westphalia","","11485","1276082488"
"Kadūr Sāhib","Kadur Sahib","31.4239","75.0978","India","IN","IND","Punjab","","11054","1356210448"
"Lantana","Lantana","33.0926","-97.1214","United States","US","USA","Texas","","11559","1840025053"
"Douar Trougout","Douar Trougout","35.1800","-3.7700","Morocco","MA","MAR","Oriental","","11458","1504618230"
"Chassieu","Chassieu","45.7444","4.9664","France","FR","FRA","Auvergne-Rhône-Alpes","","10638","1250855870"
"Melito di Porto Salvo","Melito di Porto Salvo","37.9167","15.7833","Italy","IT","ITA","Calabria","","11240","1380113594"
"Bad Schwalbach","Bad Schwalbach","50.1333","8.0667","Germany","DE","DEU","Hesse","minor","11277","1276875328"
"Ciechocinek","Ciechocinek","52.8833","18.7833","Poland","PL","POL","Kujawsko-Pomorskie","","10874","1616015629"
"Mogilno","Mogilno","52.6500","17.9500","Poland","PL","POL","Kujawsko-Pomorskie","minor","11453","1616055676"
"Ferreiros","Ferreiros","-7.4478","-35.2439","Brazil","BR","BRA","Pernambuco","","11430","1076407638"
"Wittingen","Wittingen","52.7167","10.7333","Germany","DE","DEU","Lower Saxony","","11503","1276184475"
"Valdivia","Valdivia","7.2890","-75.3980","Colombia","CO","COL","Antioquia","","11511","1170877215"
"Esil","Esil","51.9556","66.4042","Kazakhstan","KZ","KAZ","","minor","11551","1398546225"
"Tagami","Tagami","37.6988","139.0580","Japan","JP","JPN","Niigata","","11200","1392003408"
"Sevilla","Sevilla","9.7000","124.0500","Philippines","PH","PHL","Bohol","","11376","1608440527"
"Bārīgarh","Barigarh","25.2325","80.0347","India","IN","IND","Madhya Pradesh","","11324","1356681737"
"Narasingam","Narasingam","9.9658","78.1833","India","IN","IND","Tamil Nādu","","10189","1356232788"
"Oskaloosa","Oskaloosa","41.2922","-92.6404","United States","US","USA","Iowa","","11552","1840000650"
"Miami Shores","Miami Shores","25.8670","-80.1779","United States","US","USA","Florida","","11551","1840018334"
"Maurilândia","Maurilandia","-17.9708","-50.3389","Brazil","BR","BRA","Goiás","","11521","1076850976"
"Omaruru","Omaruru","-21.4333","15.9333","Namibia","NA","NAM","Erongo","","11547","1516735467"
"Nettanige","Nettanige","12.6101","75.1720","India","IN","IND","Kerala","","10858","1356102882"
"Kin","Kin","26.4562","127.9260","Japan","JP","JPN","Okinawa","","11252","1392554480"
"Rheinau","Rheinau","48.6678","7.9347","Germany","DE","DEU","Baden-Württemberg","","11395","1276702024"
"Kenzhe","Kenzhe","43.4911","43.5528","Russia","RU","RUS","Kabardino-Balkariya","","9669","1643184621"
"Riorges","Riorges","46.0428","4.0406","France","FR","FRA","Auvergne-Rhône-Alpes","","10848","1250569354"
"Cadolzburg","Cadolzburg","49.4500","10.8667","Germany","DE","DEU","Bavaria","","11298","1276504101"
"Malkanūr","Malkanur","18.0821","79.3628","India","IN","IND","Andhra Pradesh","","11247","1356942120"
"Diamond Springs","Diamond Springs","38.6920","-120.8391","United States","US","USA","California","","11545","1840018829"
"Pedro Afonso","Pedro Afonso","-8.9678","-48.1750","Brazil","BR","BRA","Tocantins","","11539","1076140514"
"Nyazepetrovsk","Nyazepetrovsk","56.0500","59.6000","Russia","RU","RUS","Chelyabinskaya Oblast’","","11539","1643877254"
"Kaboua","Kaboua","8.2500","2.6833","Benin","BJ","BEN","Collines","","11500","1204419841"
"Cholpon-Ata","Cholpon-Ata","42.6500","77.0833","Kyrgyzstan","KG","KGZ","Ysyk-Köl","minor","11520","1417433118"
"Wambrechies","Wambrechies","50.6853","3.0486","France","FR","FRA","Hauts-de-France","","10857","1250280781"
"Nembro","Nembro","45.7439","9.7594","Italy","IT","ITA","Lombardy","","11530","1380201801"
"Kamalāpuram","Kamalapuram","18.2925","80.4608","India","IN","IND","Andhra Pradesh","","11493","1356011816"
"Hasbergen","Hasbergen","52.2167","7.9167","Germany","DE","DEU","Lower Saxony","","11024","1276722564"
"Masinigudi","Masinigudi","11.5683","76.6409","India","IN","IND","Tamil Nādu","","11321","1356951404"
"Mutlūru","Mutluru","16.1500","80.4833","India","IN","IND","Andhra Pradesh","","10831","1356716108"
"Higashiizu","Higashiizu","34.7667","139.0333","Japan","JP","JPN","Shizuoka","","11389","1392002862"
"Alto Garças","Alto Garcas","-16.9439","-53.5278","Brazil","BR","BRA","Mato Grosso","","11532","1076413625"
"Wakefield","Wakefield","38.8230","-77.2406","United States","US","USA","Virginia","","11534","1840143806"
"Chintakunta","Chintakunta","14.6466","78.4696","India","IN","IND","Andhra Pradesh","","11239","1356352209"
"Sebastião Laranjeiras","Sebastiao Laranjeiras","-14.5728","-42.9400","Brazil","BR","BRA","Bahia","","11528","1076277213"
"Plankstadt","Plankstadt","49.3933","8.5942","Germany","DE","DEU","Baden-Württemberg","","10321","1276993241"
"Bochaha","Bochaha","25.5675","85.7393","India","IN","IND","Bihār","","9628","1356651748"
"Matulji","Matulji","45.3667","14.3167","Croatia","HR","HRV","Primorsko-Goranska Županija","minor","11246","1191059723"
"Bhattiprolu","Bhattiprolu","16.1000","80.7800","India","IN","IND","Andhra Pradesh","","11092","1356055862"
"Kulgo","Kulgo","24.0154","85.9743","India","IN","IND","Jhārkhand","","10839","1356259478"
"Buzhum","Buzhum","40.0100","70.8300","Kyrgyzstan","KG","KGZ","Batken","minor","11400","1417101582"
"Bondoufle","Bondoufle","48.6133","2.3806","France","FR","FRA","Île-de-France","","10046","1250320835"
"Bath","Bath","42.3219","-77.3083","United States","US","USA","New York","","11531","1840004559"
"Norfolk","Norfolk","42.1163","-71.3295","United States","US","USA","Massachusetts","","11531","1840053554"
"Sant Joan de Vilatorrada","Sant Joan de Vilatorrada","41.7456","1.8056","Spain","ES","ESP","Catalonia","","10871","1724978448"
"Sonbāri","Sonbari","24.2286","80.7902","India","IN","IND","Madhya Pradesh","","11143","1356391360"
"Utiel","Utiel","39.5672","-1.2067","Spain","ES","ESP","Valencia","","11482","1724584294"
"Kuriyama","Kuriyama","43.0563","141.7843","Japan","JP","JPN","Hokkaidō","","11474","1392201076"
"Castelvetro di Modena","Castelvetro di Modena","44.5000","10.9500","Italy","IT","ITA","Emilia-Romagna","","11303","1380197582"
"Grosse Pointe Park","Grosse Pointe Park","42.3794","-82.9287","United States","US","USA","Michigan","","11529","1840003978"
"Saint-Hilaire-de-Riez","Saint-Hilaire-de-Riez","46.7211","-1.9456","France","FR","FRA","Pays de la Loire","","11297","1250215793"
"Moyamba","Moyamba","8.1606","-12.4333","Sierra Leone","SL","SLE","Southern","","11485","1694534088"
"Villerupt","Villerupt","49.4697","5.9286","France","FR","FRA","Grand Est","","10003","1250714634"
"Box Elder","Box Elder","44.1120","-103.0818","United States","US","USA","South Dakota","","11527","1840002183"
"Chorrochó","Chorrocho","-8.9800","-39.0939","Brazil","BR","BRA","Bahia","","11522","1076318650"
"San Carlos de Guaroa","San Carlos de Guaroa","3.7111","-73.2425","Colombia","CO","COL","Meta","minor","11512","1170978795"
"Areal","Areal","-22.2308","-43.1058","Brazil","BR","BRA","Rio de Janeiro","","11423","1076449923"
"Morgan City","Morgan City","29.7041","-91.1920","United States","US","USA","Louisiana","","11525","1840015056"
"Fatehpur","Fatehpur","24.6297","85.2249","India","IN","IND","Bihār","","10431","1356495850"
"Pirangi","Pirangi","-21.0914","-48.6578","Brazil","BR","BRA","São Paulo","","11471","1076411878"
"Yuncos","Yuncos","40.0833","-3.8667","Spain","ES","ESP","Castille-La Mancha","","10813","1724313165"
"San Lázaro","San Lazaro","-22.1083","-57.9700","Paraguay","PY","PRY","Concepción","","11509","1600340714"
"Zvenigovo","Zvenigovo","55.9833","48.0167","Russia","RU","RUS","Mariy-El","","11504","1643775497"
"Ilicínia","Ilicinia","-20.9358","-45.8328","Brazil","BR","BRA","Minas Gerais","","11488","1076436204"
"Campo do Meio","Campo do Meio","-21.1069","-45.8300","Brazil","BR","BRA","Minas Gerais","","11476","1076591121"
"Montopoli in Val d’Arno","Montopoli in Val d'Arno","43.6667","10.7500","Italy","IT","ITA","Tuscany","","11149","1380810107"
"Panazol","Panazol","45.8389","1.3100","France","FR","FRA","Nouvelle-Aquitaine","","10974","1250504339"
"New Baltimore","New Baltimore","38.7495","-77.7151","United States","US","USA","Virginia","","11517","1840024594"
"Lokhvytsya","Lokhvytsya","50.3610","33.2652","Ukraine","UA","UKR","Poltavska Oblast","minor","11485","1804270399"
"Dhorgaon","Dhorgaon","25.9545","86.8933","India","IN","IND","Bihār","","9649","1356150069"
"Belém de Maria","Belem de Maria","-8.6139","-35.8089","Brazil","BR","BRA","Pernambuco","","11353","1076406232"
"Guayabal","Guayabal","4.9633","-74.9056","Colombia","CO","COL","Tolima","minor","11490","1170472298"
"Lantana","Lantana","26.5834","-80.0564","United States","US","USA","Florida","","11516","1840017259"
"Kapaa","Kapaa","22.0910","-159.3521","United States","US","USA","Hawaii","","11516","1840029499"
"Karlsdorf-Neuthard","Karlsdorf-Neuthard","49.1364","8.5439","Germany","DE","DEU","Baden-Württemberg","","10746","1276052458"
"Cherdakly","Cherdakly","54.3594","48.8450","Russia","RU","RUS","Ul’yanovskaya Oblast’","","11492","1643586210"
"Welzheim","Welzheim","48.8747","9.6344","Germany","DE","DEU","Baden-Württemberg","","11219","1276045821"
"Lakeville","Lakeville","41.8310","-70.9594","United States","US","USA","Massachusetts","","11514","1840053571"
"Myronivka","Myronivka","49.6500","30.9833","Ukraine","UA","UKR","Kyivska Oblast","minor","11478","1804440436"
"Târgu Ocna","Targu Ocna","46.2800","26.6200","Romania","RO","ROU","Bacău","","11300","1642346375"
"Rothenburg ob der Tauber","Rothenburg ob der Tauber","49.3833","10.1833","Germany","DE","DEU","Bavaria","","11243","1276993004"
"Coronel Dorrego","Coronel Dorrego","-38.7000","-61.2667","Argentina","AR","ARG","Buenos Aires","minor","11510","1032800825"
"Dobbs Ferry","Dobbs Ferry","41.0127","-73.8697","United States","US","USA","New York","","11511","1840004952"
"Holíč","Holic","48.8122","17.1606","Slovakia","SK","SVK","Trnava","","11214","1703056774"
"Newtown","Newtown","52.5132","-3.3141","United Kingdom","GB","GBR","Powys","","11357","1826506895"
"Māshyāl","Mashyal","17.3226","76.2407","India","IN","IND","Karnātaka","","11326","1356675033"
"Scalea","Scalea","39.8167","15.8000","Italy","IT","ITA","Calabria","","11022","1380684503"
"Uničov","Unicov","49.7709","17.1215","Czechia","CZ","CZE","Olomoucký Kraj","","11281","1203442454"
"Zierikzee","Zierikzee","51.6497","3.9164","Netherlands","NL","NLD","Zeeland","minor","11360","1528091477"
"Dammartin-en-Goële","Dammartin-en-Goele","49.0542","2.6814","France","FR","FRA","Île-de-France","","10355","1250805178"
"Balve","Balve","51.3333","7.8667","Germany","DE","DEU","North Rhine-Westphalia","","11361","1276429587"
"Sidéradougou","Sideradougou","10.6876","-4.2627","Burkina Faso","BF","BFA","Cascades","","11443","1854474871"
"Lençóis","Lencois","-12.5628","-41.3900","Brazil","BR","BRA","Bahia","","11499","1076448489"
"Beclean","Beclean","47.1797","24.1797","Romania","RO","ROU","Bistriţa-Năsăud","","11260","1642699805"
"Divinolândia","Divinolandia","-21.6614","-46.7392","Brazil","BR","BRA","São Paulo","","11492","1076108804"
"Yakymivka","Yakymivka","46.6977","35.1554","Ukraine","UA","UKR","Zaporizka Oblast","minor","11481","1804110060"
"Minnehaha","Minnehaha","45.6577","-122.6204","United States","US","USA","Washington","","11508","1840018518"
"Lurate Caccivio","Lurate Caccivio","45.7667","9.0000","Italy","IT","ITA","Lombardy","","9847","1380759371"
"Gohuma Bairia","Gohuma Bairia","26.3938","86.3976","India","IN","IND","Bihār","","10257","1356131175"
"Vermillion","Vermillion","42.7811","-96.9256","United States","US","USA","South Dakota","","11506","1840003082"
"Crikvenica","Crikvenica","45.1833","14.7000","Croatia","HR","HRV","Primorsko-Goranska Županija","minor","11122","1191344167"
"Lutry","Lutry","46.5000","6.6833","Switzerland","CH","CHE","Vaud","","10289","1756885956"
"Gig Harbor","Gig Harbor","47.3352","-122.5964","United States","US","USA","Washington","","11505","1840019855"
"Lovington","Lovington","32.9128","-103.3276","United States","US","USA","New Mexico","","11505","1840020617"
"Poldasht","Poldasht","39.3475","45.0678","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","minor","11472","1364811524"
"Leon Valley","Leon Valley","29.4954","-98.6143","United States","US","USA","Texas","","11503","1840020959"
"Burhia Dhanghatta","Burhia Dhanghatta","25.9004","87.2852","India","IN","IND","Bihār","","10457","1356640356"
"Sociedad","Sociedad","13.7000","-88.0167","El Salvador","SV","SLV","Morazán","","11406","1222392301"
"Māli","Mali","25.1269","84.7084","India","IN","IND","Bihār","","10268","1356018726"
"Paceco","Paceco","37.9833","12.5500","Italy","IT","ITA","Sicilia","","11307","1380125457"
"Lenzburg","Lenzburg","47.3833","8.1833","Switzerland","CH","CHE","Aargau","","10569","1756304845"
"Amnéville","Amneville","49.2608","6.1419","France","FR","FRA","Grand Est","","10505","1250776698"
"Buriti do Tocantins","Buriti do Tocantins","-5.3158","-48.2289","Brazil","BR","BRA","Tocantins","","11497","1076608745"
"Dalāwarpur","Dalawarpur","26.3466","84.9581","India","IN","IND","Bihār","","10054","1356235750"
"Mānikpur","Manikpur","25.3129","87.4388","India","IN","IND","Bihār","","10343","1356069750"
"Muquém de São Francisco","Muquem de Sao Francisco","-12.0650","-43.5489","Brazil","BR","BRA","Bahia","","11495","1076874616"
"Kīlrājakularāman","Kilrajakularaman","9.3969","77.6344","India","IN","IND","Tamil Nādu","","10755","1356210504"
"Cerese","Cerese","45.0500","10.7500","Italy","IT","ITA","Lombardy","","11141","1380568489"
"El Khemis des Beni Chegdal","El Khemis des Beni Chegdal","32.4441","-6.9551","Morocco","MA","MAR","Béni Mellal-Khénifra","","11444","1504549116"
"Er Regueb","Er Regueb","34.8667","9.7833","Tunisia","TN","TUN","Sidi Bouzid","","11430","1788507948"
"Tibau do Sul","Tibau do Sul","-6.1869","-35.0919","Brazil","BR","BRA","Rio Grande do Norte","","11385","1076052971"
"Jādupatti","Jadupatti","26.1186","86.3114","India","IN","IND","Bihār","","10161","1356698623"
"Konen Agrahār","Konen Agrahar","12.8574","77.6640","India","IN","IND","Karnātaka","","11038","1356849669"
"Aginskoye","Aginskoye","51.1031","114.5228","Russia","RU","RUS","Zabaykal’skiy Kray","","11491","1643514234"
"Baldock","Baldock","51.9900","-0.1900","United Kingdom","GB","GBR","Hertfordshire","","9900","1826427353"
"Ochsenfurt","Ochsenfurt","49.6500","10.0667","Germany","DE","DEU","Bavaria","","11319","1276225092"
"Lahfayr","Lahfayr","30.5700","-8.4878","Morocco","MA","MAR","Souss-Massa","","11374","1504992138"
"Ain Legdah","Ain Legdah","34.1667","-4.5000","Morocco","MA","MAR","Fès-Meknès","","11422","1504429679"
"Florennes","Florennes","50.2514","4.6044","Belgium","BE","BEL","Wallonia","","11410","1056332747"
"Waupun","Waupun","43.6314","-88.7381","United States","US","USA","Wisconsin","","11494","1840002600"
"Sonakhal","Sonakhal","22.2213","88.6939","India","IN","IND","West Bengal","","10582","1356587924"
"Medulla","Medulla","27.9570","-81.9866","United States","US","USA","Florida","","11493","1840014131"
"Domažlice","Domazlice","49.4406","12.9272","Czechia","CZ","CZE","Plzeňský Kraj","","11056","1203358449"
"Malapannanagudi","Malapannanagudi","15.2800","76.3700","India","IN","IND","Karnātaka","","10896","1356462481"
"Bom Retiro do Sul","Bom Retiro do Sul","-29.6089","-51.9428","Brazil","BR","BRA","Rio Grande do Sul","","11472","1076743801"
"Salisbury","Salisbury","40.0380","-75.9961","United States","US","USA","Pennsylvania","","11491","1840142490"
"Montmagny","Montmagny","46.9833","-70.5500","Canada","CA","CAN","Quebec","","11491","1124025705"
"Cornate d’Adda","Cornate d'Adda","45.6500","9.4667","Italy","IT","ITA","Lombardy","","10715","1380466691"
"Terku Valliyūr","Terku Valliyur","8.3570","77.6087","India","IN","IND","Tamil Nādu","","11090","1356205329"
"Kostinbrod","Kostinbrod","42.8167","23.2167","Bulgaria","BG","BGR","Sofia","","11432","1100892739"
"Amjhār","Amjhar","25.0641","84.5591","India","IN","IND","Bihār","","9820","1356386670"
"Ahlaf","Ahlaf","33.2833","-7.2000","Morocco","MA","MAR","Casablanca-Settat","","11451","1504239196"
"Klyetsk","Klyetsk","53.0636","26.6372","Belarus","BY","BLR","Minskaya Voblasts’","minor","11462","1112054647"
"Chkalovsk","Chkalovsk","56.7667","43.2333","Russia","RU","RUS","Nizhegorodskaya Oblast’","","11462","1643806509"
"Bátonyterenye","Batonyterenye","47.9906","19.8309","Hungary","HU","HUN","Nógrád","minor","11345","1348134134"
"Tinogasta","Tinogasta","-28.0667","-67.5667","Argentina","AR","ARG","Catamarca","minor","11485","1032163046"
"Yorktown","Yorktown","40.1830","-85.5123","United States","US","USA","Indiana","","11486","1840010463"
"Xambioá","Xambioa","-6.4108","-48.5358","Brazil","BR","BRA","Tocantins","","11484","1076712619"
"Varzobkala","Varzobkala","38.7667","68.8167","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","11438","1762216505"
"Jem’at Oulad ’Abbou","Jem'at Oulad 'Abbou","33.1156","-7.9333","Morocco","MA","MAR","Casablanca-Settat","","11299","1504650087"
"Fort Morgan","Fort Morgan","40.2537","-103.7903","United States","US","USA","Colorado","","11483","1840020175"
"Nekarikallu","Nekarikallu","16.3833","79.9500","India","IN","IND","Andhra Pradesh","","10778","1356834789"
"Avigliano","Avigliano","40.7314","15.7166","Italy","IT","ITA","Basilicata","","11350","1380120301"
"Loyalsock","Loyalsock","41.2743","-76.9839","United States","US","USA","Pennsylvania","","11482","1840152775"
"Farmington","Farmington","42.4614","-83.3784","United States","US","USA","Michigan","","11481","1840002441"
"Orono","Orono","44.8867","-68.7166","United States","US","USA","Maine","","11481","1840052945"
"Senador José Porfírio","Senador Jose Porfirio","-2.5908","-51.9539","Brazil","BR","BRA","Pará","","11480","1076927410"
"Antônio Carlos","Antonio Carlos","-21.3178","-43.7469","Brazil","BR","BRA","Minas Gerais","","11459","1076945729"
"Paduma","Paduma","26.5766","86.2394","India","IN","IND","Bihār","","10787","1356341482"
"Ortakent","Ortakent","37.1035","27.2926","Turkey","TR","TUR","Muğla","","11227","1792321067"
"Kuzuculu","Kuzuculu","36.8833","36.2333","Turkey","TR","TUR","Hatay","","11187","1792511209"
"Dinard","Dinard","48.6325","-2.0617","France","FR","FRA","Bretagne","","10181","1250886929"
"Tāmba","Tamba","17.0001","75.9934","India","IN","IND","Karnātaka","","11258","1356102514"
"Prien am Chiemsee","Prien am Chiemsee","47.8560","12.3462","Germany","DE","DEU","Bavaria","","10950","1276129063"
"Gayāspur","Gayaspur","25.9217","84.4399","India","IN","IND","Bihār","","10383","1356285960"
"Lajosmizse","Lajosmizse","47.0264","19.5578","Hungary","HU","HUN","Bács-Kiskun","","11408","1348320390"
"Ridgefield","Ridgefield","40.8313","-74.0147","United States","US","USA","New Jersey","","11477","1840000897"
"Naliya","Naliya","23.2611","68.8267","India","IN","IND","Gujarāt","","11415","1356368840"
"Gracemere","Gracemere","-23.4391","150.4558","Australia","AU","AUS","Queensland","","11315","1036779939"
"Sales Oliveira","Sales Oliveira","-20.7719","-47.8378","Brazil","BR","BRA","São Paulo","","11438","1076954861"
"Raghunāthpur","Raghunathpur","26.0019","84.2753","India","IN","IND","Bihār","","10107","1356103844"
"Rāmanāyakkanpālaiyam","Ramanayakkanpalaiyam","11.6291","78.5624","India","IN","IND","Tamil Nādu","","11082","1356242788"
"Ḑurumā","Duruma","24.6000","46.1333","Saudi Arabia","SA","SAU","Ar Riyāḑ","","11461","1682041945"
"Calatrava","Calatrava","12.6167","122.0708","Philippines","PH","PHL","Romblon","","11342","1608281259"
"Retie","Retie","51.2667","5.0667","Belgium","BE","BEL","Flanders","","11240","1056722823"
"Stafford","Stafford","41.9876","-72.3122","United States","US","USA","Connecticut","","11472","1840033668"
"Gerasdorf bei Wien","Gerasdorf bei Wien","48.2950","16.4675","Austria","AT","AUT","Niederösterreich","","11155","1040814671"
"Byerazino","Byerazino","53.8333","28.9833","Belarus","BY","BLR","Minskaya Voblasts’","","11462","1112685859"
"Herbolzheim","Herbolzheim","48.2219","7.7775","Germany","DE","DEU","Baden-Württemberg","","11156","1276610094"
"Abjīj","Abjij","29.2861","30.8152","Egypt","EG","EGY","Al Fayyūm","","10057","1818397315"
"Egersund","Egersund","58.4497","6.0087","Norway","NO","NOR","Rogaland","minor","11433","1578432421"
"Pueblo Viejo","Pueblo Viejo","18.4000","-70.7667","Dominican Republic","DO","DOM","Valdesia","","11235","1214567482"
"Kyjov","Kyjov","49.0102","17.1225","Czechia","CZ","CZE","Jihomoravský Kraj","","11105","1203980144"
"Chandreru","Chandreru","16.8200","81.9900","India","IN","IND","Andhra Pradesh","","10733","1356362084"
"Mancha Real","Mancha Real","37.7864","-3.6125","Spain","ES","ESP","Andalusia","","11350","1724902974"
"Ravutulapūdi","Ravutulapudi","17.3833","82.3833","India","IN","IND","Andhra Pradesh","","10784","1356451973"
"Māngobandar","Mangobandar","24.8162","86.2709","India","IN","IND","Bihār","","10801","1356256270"
"Kennebunk","Kennebunk","43.3972","-70.5707","United States","US","USA","Maine","","11466","1840052870"
"Guinagourou","Guinagourou","9.5667","2.9500","Benin","BJ","BEN","Borgou","","11411","1204101823"
"Ontario","Ontario","44.0259","-116.9760","United States","US","USA","Oregon","","11465","1840020002"
"New Garden","New Garden","39.8119","-75.7517","United States","US","USA","Pennsylvania","","11465","1840100548"
"Amdel","Amdel","31.5617","-8.8944","Morocco","MA","MAR","Marrakech-Safi","","11438","1504656724"
"Komorniki","Komorniki","52.2667","16.7000","Poland","PL","POL","Wielkopolskie","","10990","1616794356"
"Baragaon","Baragaon","25.1348","85.4320","India","IN","IND","Bihār","","9822","1356907977"
"Axixá","Axixa","-2.8369","-44.0528","Brazil","BR","BRA","Maranhão","","11407","1076829101"
"Hartsville","Hartsville","36.3921","-86.1568","United States","US","USA","Tennessee","","11463","1840073881"
"Great Harwood","Great Harwood","53.7860","-2.4080","United Kingdom","GB","GBR","Lancashire","","10890","1826598586"
"Piazzola sul Brenta","Piazzola sul Brenta","45.5333","11.7667","Italy","IT","ITA","Veneto","","11189","1380205333"
"Grafton","Grafton","-29.6833","152.9333","Australia","AU","AUS","New South Wales","","10408","1036615468"
"Dharmavaram","Dharmavaram","17.2100","82.3400","India","IN","IND","Andhra Pradesh","","10717","1356914986"
"Fulton","Fulton","43.3171","-76.4167","United States","US","USA","New York","","11461","1840000347"
"Hejiaji","Hejiaji","37.3539","109.8377","China","CN","CHN","Shaanxi","","11382","1156907125"
"Peddapādu","Peddapadu","16.6408","81.0322","India","IN","IND","Andhra Pradesh","","11081","1356520055"
"Lanham","Lanham","38.9620","-76.8421","United States","US","USA","Maryland","","11460","1840026687"
"Lawrenceburg","Lawrenceburg","35.2497","-87.3325","United States","US","USA","Tennessee","","11460","1840014568"
"Griswold","Griswold","41.5852","-71.9226","United States","US","USA","Connecticut","","11460","1840045021"
"Kanamadi","Kanamadi","16.8300","75.7300","India","IN","IND","Karnātaka","","11135","1356065416"
"Maltby","Maltby","47.8027","-122.1044","United States","US","USA","Washington","","11458","1840017326"
"Tinkoni","Tinkoni","26.8613","84.9647","India","IN","IND","Bihār","","10042","1356754289"
"Resende","Resende","41.1060","-7.9640","Portugal","PT","PRT","Viseu","minor","11364","1620858385"
"Oud-Heverlee","Oud-Heverlee","50.8333","4.6667","Belgium","BE","BEL","Flanders","","11099","1056031446"
"Écaussinnes-Lalaing","Ecaussinnes-Lalaing","50.5667","4.1833","Belgium","BE","BEL","Wallonia","","11135","1056355929"
"Saint-Junien","Saint-Junien","45.8872","0.9011","France","FR","FRA","Nouvelle-Aquitaine","","11257","1250815843"
"Būdili","Budili","13.9353","77.7355","India","IN","IND","Andhra Pradesh","","11200","1356792053"
"Pātrasāer","Patrasaer","23.1968","87.5272","India","IN","IND","West Bengal","","10844","1356961094"
"Nelson Bay","Nelson Bay","-32.7150","152.1511","Australia","AU","AUS","New South Wales","","11060","1036777604"
"Perali","Perali","15.8860","80.5470","India","IN","IND","Andhra Pradesh","","11035","1356580567"
"Moss Bluff","Moss Bluff","30.3039","-93.2051","United States","US","USA","Louisiana","","11453","1840013962"
"Mack","Mack","39.1492","-84.6794","United States","US","USA","Ohio","","11452","1840034079"
"Seneffe","Seneffe","50.5333","4.2500","Belgium","BE","BEL","Wallonia","","11272","1056829223"
"Roma","Roma","26.4166","-99.0061","United States","US","USA","Texas","","11451","1840021017"
"Tucson Mountains","Tucson Mountains","32.2822","-111.0773","United States","US","USA","Arizona","","11451","1840153143"
"Santa Rosa de Viterbo","Santa Rosa de Viterbo","5.8833","-72.9833","Colombia","CO","COL","Boyacá","minor","11329","1170798661"
"Venafro","Venafro","41.4844","14.0458","Italy","IT","ITA","Molise","","11209","1380227968"
"La Maddalena","La Maddalena","41.2167","9.4000","Italy","IT","ITA","Sardegna","","11233","1380613741"
"Fagundes","Fagundes","-7.3550","-35.7750","Brazil","BR","BRA","Paraíba","","11378","1076611174"
"Canton","Canton","44.5802","-75.1978","United States","US","USA","New York","","11448","1840004065"
"Douar Oulad Naoual","Douar Oulad Naoual","34.4936","-5.7108","Morocco","MA","MAR","Rabat-Salé-Kénitra","","11371","1504215350"
"Belén","Belen","-23.4660","-57.2620","Paraguay","PY","PRY","Concepción","","11359","1600551990"
"Laqraqra","Laqraqra","32.4333","-7.4333","Morocco","MA","MAR","Marrakech-Safi","","11419","1504203728"
"Botticino Sera","Botticino Sera","45.5333","10.2333","Italy","IT","ITA","Lombardy","","10857","1380220938"
"Eidsberg","Eidsberg","59.5369","11.3603","Norway","NO","NOR","Viken","","11396","1578040271"
"La Libertad","La Libertad","12.2156","-85.1647","Nicaragua","NI","NIC","Chontales","minor","11429","1558065930"
"Kalavapūdi","Kalavapudi","16.4623","81.3887","India","IN","IND","Andhra Pradesh","","10941","1356638870"
"Virton","Virton","49.5675","5.5325","Belgium","BE","BEL","Wallonia","minor","11323","1056402368"
"Kannūlu","Kannulu","12.8685","74.9025","India","IN","IND","Karnātaka","","9547","1356203518"
"Nikel","Nikel","69.4081","30.2206","Russia","RU","RUS","Murmanskaya Oblast’","","11437","1643124017"
"Port Salerno","Port Salerno","27.1461","-80.1894","United States","US","USA","Florida","","11441","1840014193"
"Nueva Helvecia","Nueva Helvecia","-34.2833","-57.2000","Uruguay","UY","URY","Colonia","","10630","1858393787"
"Körmend","Kormend","47.0110","16.6058","Hungary","HU","HUN","Vas","minor","11236","1348688701"
"Town and Country","Town and Country","38.6317","-90.4790","United States","US","USA","Missouri","","11440","1840010738"
"Lafrayta","Lafrayta","31.9167","-7.2500","Morocco","MA","MAR","Marrakech-Safi","","11298","1504011173"
"Czarnków","Czarnkow","52.9000","16.5667","Poland","PL","POL","Wielkopolskie","minor","10370","1616859392"
"Healdsburg","Healdsburg","38.6224","-122.8651","United States","US","USA","California","","11439","1840020242"
"Erin","Erin","43.7667","-80.0667","Canada","CA","CAN","Ontario","","11439","1124418313"
"Jimaní","Jimani","18.4900","-71.8500","Dominican Republic","DO","DOM","Enriquillo","minor","11414","1214388193"
"Palukudoddi","Palukudoddi","15.8610","77.7180","India","IN","IND","Andhra Pradesh","","11164","1356259461"
"Pedro de Toledo","Pedro de Toledo","-24.2750","-47.2328","Brazil","BR","BRA","São Paulo","","11421","1076725526"
"Gloucester City","Gloucester City","39.8924","-75.1172","United States","US","USA","New Jersey","","11438","1840003777"
"Aratuba","Aratuba","-4.4178","-39.0450","Brazil","BR","BRA","Ceará","","11358","1076986261"
"Oraviţa","Oravita","45.0403","21.6853","Romania","RO","ROU","Caraş-Severin","","11382","1642696824"
"Mărăşeşti","Marasesti","45.8800","27.2300","Romania","RO","ROU","Vrancea","","11314","1642459723"
"Hato Corozal","Hato Corozal","3.1833","-73.7500","Colombia","CO","COL","Meta","","11431","1170673872"
"Douar Sgarta","Douar Sgarta","32.1667","-7.6333","Morocco","MA","MAR","Marrakech-Safi","","11387","1504288503"
"San José de Guaribe","San Jose de Guaribe","9.7930","-65.8418","Venezuela","VE","VEN","Guárico","minor","11426","1862268251"
"Hejamādi","Hejamadi","13.1062","74.7839","India","IN","IND","Karnātaka","","10813","1356024511"
"Puerto Rico","Puerto Rico","2.9383","-73.2083","Colombia","CO","COL","Meta","minor","11433","1170252143"
"Valley Center","Valley Center","33.2330","-117.0158","United States","US","USA","California","","11435","1840019349"
"Zaō","Zao","38.0981","140.6587","Japan","JP","JPN","Miyagi","","11360","1392003405"
"Flawil","Flawil","47.4053","9.1977","Switzerland","CH","CHE","Sankt Gallen","","10520","1756417053"
"Boldeşti-Scăeni","Boldesti-Scaeni","45.0300","26.0300","Romania","RO","ROU","Prahova","","11137","1642726960"
"Barbosa Ferraz","Barbosa Ferraz","-24.0300","-52.0119","Brazil","BR","BRA","Paraná","","11426","1076936021"
"Dagbé","Dagbe","6.5667","2.6833","Benin","BJ","BEN","Ouémé","","9498","1204590696"
"Dhilwān Kalān","Dhilwan Kalan","30.5718","74.8655","India","IN","IND","Punjab","","10991","1356137623"
"Kall","Kall","50.5497","6.5497","Germany","DE","DEU","North Rhine-Westphalia","","11264","1276548947"
"Douchy-les-Mines","Douchy-les-Mines","50.3014","3.3933","France","FR","FRA","Hauts-de-France","","10311","1250347998"
"Barbadanes","Barbadanes","42.3003","-7.9064","Spain","ES","ESP","Galicia","","11061","1724717349"
"Hatwāns","Hatwans","22.7683","78.3464","India","IN","IND","Madhya Pradesh","","11102","1356215161"
"Solymár","Solymar","47.5910","18.9290","Hungary","HU","HUN","Pest","","10823","1348009573"
"Lamsabih","Lamsabih","32.2933","-8.6889","Morocco","MA","MAR","Marrakech-Safi","","11339","1504233689"
"Creazzo","Creazzo","45.5333","11.4833","Italy","IT","ITA","Veneto","","11373","1380029462"
"Telkap","Telkap","24.7048","84.0093","India","IN","IND","Bihār","","10787","1356077327"
"Gentio do Ouro","Gentio do Ouro","-11.4289","-42.5058","Brazil","BR","BRA","Bahia","","11423","1076957913"
"Pottireddippatti","Pottireddippatti","11.1659","78.2708","India","IN","IND","Tamil Nādu","","10945","1356231720"
"Jhundo","Jhundo","24.7756","86.2473","India","IN","IND","Bihār","","10760","1356249136"
"Sorsk","Sorsk","54.0333","90.2500","Russia","RU","RUS","Khakasiya","","11416","1643209786"
"Yuryuzan","Yuryuzan","54.8667","58.4333","Russia","RU","RUS","Chelyabinskaya Oblast’","","11415","1643397550"
"Kastav","Kastav","45.3750","14.3490","Croatia","HR","HRV","Primorsko-Goranska Županija","minor","10472","1191243923"
"Kochgāwān","Kochgawan","25.0431","85.6279","India","IN","IND","Bihār","","9567","1356173991"
"Teixeiras","Teixeiras","-20.6508","-42.8569","Brazil","BR","BRA","Minas Gerais","","11355","1076235802"
"Ghatāwān","Ghatawan","25.0968","83.7266","India","IN","IND","Bihār","","10072","1356933658"
"Gararu","Gararu","-9.9678","-37.0828","Brazil","BR","BRA","Sergipe","","11405","1076493938"
"Cazzago San Martino","Cazzago San Martino","45.5817","10.0258","Italy","IT","ITA","Lombardy","","10933","1380599278"
"Coveñas","Covenas","9.4167","-75.7000","Colombia","CO","COL","Sucre","minor","11270","1170707919"
"Galsi","Galsi","23.3427","87.6885","India","IN","IND","West Bengal","","10700","1356039133"
"Kawadgaon","Kawadgaon","17.9100","77.5300","India","IN","IND","Karnātaka","","11179","1356546199"
"Portes-lès-Valence","Portes-les-Valence","44.8733","4.8764","France","FR","FRA","Auvergne-Rhône-Alpes","","10680","1250226611"
"Abaiara","Abaiara","-7.3589","-39.0458","Brazil","BR","BRA","Ceará","","11357","1076855645"
"Waidhofen an der Ybbs","Waidhofen an der Ybbs","47.9596","14.7745","Austria","AT","AUT","Niederösterreich","","11333","1040615361"
"Musāpur","Musapur","25.6821","85.7346","India","IN","IND","Bihār","","9472","1356635347"
"Bridgeton","Bridgeton","38.7673","-90.4275","United States","US","USA","Missouri","","11417","1840006129"
"Khurān Milik","Khuran Milik","25.6000","86.8679","India","IN","IND","Bihār","","9956","1356693412"
"Alassio","Alassio","44.0000","8.1667","Italy","IT","ITA","Liguria","","10791","1380546015"
"Pāma","Pama","25.8122","86.8474","India","IN","IND","Bihār","","10622","1356228622"
"Sào Amaro das Brotas","Sao Amaro das Brotas","-10.7889","-37.0539","Brazil","BR","BRA","Sergipe","","11410","1076944361"
"Ghagga","Ghagga","30.0198","76.1041","India","IN","IND","Punjab","","10839","1356904166"
"Dūrpalli","Durpalli","18.5967","78.4517","India","IN","IND","Andhra Pradesh","","11132","1356026765"
"Vieux-Condé","Vieux-Conde","50.4594","3.5683","France","FR","FRA","Hauts-de-France","","10465","1250738917"
"Kattāri","Kattari","11.4593","77.7263","India","IN","IND","Tamil Nādu","","10751","1356168279"
"Lumaco","Lumaco","-38.1500","-72.9167","Chile","CL","CHL","Araucanía","","11405","1152525115"
"Tiruvalam","Tiruvalam","12.9825","79.2661","India","IN","IND","Tamil Nādu","","10475","1356990853"
"Zirə","Zira","40.3636","50.2903","Azerbaijan","AZ","AZE","Bakı","","11053","1031736351"
"Snovsk","Snovsk","51.8203","31.9508","Ukraine","UA","UKR","Chernihivska Oblast","","11390","1804059681"
"Guachetá","Guacheta","5.3856","-73.6856","Colombia","CO","COL","Cundinamarca","minor","11385","1170660676"
"Tiruppālaikudi","Tiruppalaikudi","9.5461","78.9172","India","IN","IND","Tamil Nādu","","10850","1356072980"
"Suhr","Suhr","47.3747","8.0806","Switzerland","CH","CHE","Aargau","","10424","1756546975"
"Charne","Charne","26.1185","87.0754","India","IN","IND","Bihār","","9777","1356661444"
"Lijiacha","Lijiacha","37.2467","109.4061","China","CN","CHN","Shaanxi","","11381","1156627939"
"Zambrano","Zambrano","9.7500","-74.8333","Colombia","CO","COL","Bolívar","minor","11367","1170000087"
"Pālakollu","Palakollu","16.5160","81.7230","India","IN","IND","Andhra Pradesh","","10848","1356365783"
"Sárospatak","Sarospatak","48.3190","21.5664","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","11291","1348369624"
"Alcântaras","Alcantaras","-3.5889","-40.5458","Brazil","BR","BRA","Ceará","","11321","1076451406"
"Heule","Heule","50.8333","3.2333","Belgium","BE","BEL","Flanders","","10503","1056934684"
"Lystrup","Lystrup","56.2353","10.2375","Denmark","DK","DNK","Midtjylland","","10436","1208253128"
"Lasht-e Neshā","Lasht-e Nesha","37.3661","49.8594","Iran","IR","IRN","Gīlān","","10539","1364211135"
"Yelpur","Yelpur","18.7651","78.3953","India","IN","IND","Andhra Pradesh","","10975","1356181058"
"Chekmagush","Chekmagush","55.1411","54.6500","Russia","RU","RUS","Bashkortostan","","11382","1643906704"
"Sidhap Kalān","Sidhap Kalan","26.5456","86.2346","India","IN","IND","Bihār","","10706","1356964546"
"Sussex","Sussex","43.1346","-88.2226","United States","US","USA","Wisconsin","","11398","1840003006"
"Babayevo","Babayevo","59.3833","35.9167","Russia","RU","RUS","Vologodskaya Oblast’","","11395","1643658178"
"Telsang","Telsang","16.7200","75.0600","India","IN","IND","Karnātaka","","11097","1356300287"
"Maheshrām","Maheshram","25.2874","87.4124","India","IN","IND","Bihār","","10241","1356216646"
"Cividale del Friuli","Cividale del Friuli","46.1000","13.4333","Italy","IT","ITA","Friuli Venezia Giulia","","11176","1380392084"
"Handewitt","Handewitt","54.7667","9.3167","Germany","DE","DEU","Schleswig-Holstein","","11250","1276919280"
"Grigny","Grigny","45.6083","4.7897","France","FR","FRA","Auvergne-Rhône-Alpes","","9706","1250633227"
"Dhanwār","Dhanwar","24.4107","85.9818","India","IN","IND","Jhārkhand","","10462","1356802246"
"Moorslede","Moorslede","50.8906","3.0628","Belgium","BE","BEL","Flanders","","11080","1056720608"
"Talaigua Nuevo","Talaigua Nuevo","9.3069","-74.5686","Colombia","CO","COL","Bolívar","minor","11350","1170791252"
"Kingsnorth","Kingsnorth","51.1178","0.8615","United Kingdom","GB","GBR","Kent","","11243","1826823710"
"Loreto","Loreto","-7.0839","-45.1408","Brazil","BR","BRA","Maranhão","","11390","1076606778"
"Stannington","Stannington","53.3960","-1.5360","United Kingdom","GB","GBR","Sheffield","","10747","1826474759"
"Athy","Athy","52.9920","-6.9870","Ireland","IE","IRL","Kildare","","9677","1372942058"
"Kuttattuppatti","Kuttattuppatti","10.3759","77.8972","India","IN","IND","Tamil Nādu","","10748","1356250206"
"Tilmi","Tilmi","31.8189","-5.7718","Morocco","MA","MAR","Drâa-Tafilalet","","11372","1504580420"
"Hillsborough","Hillsborough","37.5572","-122.3586","United States","US","USA","California","","11390","1840021554"
"Băcioi","Bacioi","46.9122","28.8839","Moldova","MD","MDA","Chişinău","","10175","1498931344"
"Kincardine","Kincardine","44.1667","-81.6333","Canada","CA","CAN","Ontario","","11389","1124781881"
"Lauingen","Lauingen","48.5667","10.4333","Germany","DE","DEU","Bavaria","","11137","1276983214"
"Karukkalvādi","Karukkalvadi","11.6714","78.0126","India","IN","IND","Tamil Nādu","","10523","1356228001"
"Drezna","Drezna","55.7453","38.8486","Russia","RU","RUS","Moskovskaya Oblast’","","11313","1643752314"
"Bitritto","Bitritto","41.0500","16.8333","Italy","IT","ITA","Puglia","","11277","1380815119"
"Lieshout","Lieshout","51.5194","5.5939","Netherlands","NL","NLD","Noord-Brabant","","10745","1528167902"
"Onoto","Onoto","9.5958","-65.1897","Venezuela","VE","VEN","Anzoátegui","minor","11378","1862162566"
"Khaira","Khaira","24.8727","86.2089","India","IN","IND","Bihār","","10071","1356889521"
"Kochkor","Kochkor","42.2158","75.7556","Kyrgyzstan","KG","KGZ","Naryn","minor","11373","1417678105"
"Saharbani","Saharbani","25.7201","86.4100","India","IN","IND","Bihār","","9954","1356460046"
"East Grand Rapids","East Grand Rapids","42.9464","-85.6088","United States","US","USA","Michigan","","11381","1840002927"
"Rio Novo do Sul","Rio Novo do Sul","-20.8628","-40.9358","Brazil","BR","BRA","Espírito Santo","","11325","1076704645"
"Elchūru","Elchuru","16.0813","79.9269","India","IN","IND","Andhra Pradesh","","11070","1356488805"
"Westerkappeln","Westerkappeln","52.3153","7.8806","Germany","DE","DEU","North Rhine-Westphalia","","11249","1276848242"
"Ichikai","Ichikai","36.5431","140.1022","Japan","JP","JPN","Tochigi","","11205","1392003212"
"Sankt Johann im Pongau","Sankt Johann im Pongau","47.3500","13.2000","Austria","AT","AUT","Salzburg","minor","11235","1040330095"
"El Ghourdane","El Ghourdane","32.3400","-8.7728","Morocco","MA","MAR","Marrakech-Safi","","11291","1504617504"
"Gornozavodsk","Gornozavodsk","58.3667","58.3333","Russia","RU","RUS","Permskiy Kray","","11375","1643388843"
"Canápolis","Canapolis","-18.7250","-49.2039","Brazil","BR","BRA","Minas Gerais","","11365","1076393756"
"Călan","Calan","45.7361","23.0086","Romania","RO","ROU","Hunedoara","","11279","1642642024"
"Kiwoko","Kiwoko","0.8442","32.3614","Uganda","UG","UGA","Nakaseke","","11013","1800103323"
"Commune Sidi Youssef Ben Ahmed","Commune Sidi Youssef Ben Ahmed","33.7861","-4.8152","Morocco","MA","MAR","Fès-Meknès","","11292","1504774244"
"Santiago del Teide","Santiago del Teide","28.2957","-16.8145","Spain","ES","ESP","Canary Islands","","11162","1724546976"
"Ekma","Ekma","26.0541","86.6121","India","IN","IND","Bihār","","9584","1356259501"
"Lang Suan","Lang Suan","9.9500","99.0667","Thailand","TH","THA","Chumphon","minor","11250","1764676997"
"Catskill","Catskill","42.2063","-73.9435","United States","US","USA","New York","","11374","1840004650"
"Humahuaca","Humahuaca","-23.2000","-65.3500","Argentina","AR","ARG","Jujuy","minor","11369","1032904210"
"Oxted","Oxted","51.2570","-0.0060","United Kingdom","GB","GBR","Surrey","","11314","1826650120"
"Passy","Passy","45.9236","6.6864","France","FR","FRA","Auvergne-Rhône-Alpes","","11233","1250030794"
"San José del Fragua","San Jose del Fragua","1.3286","-75.9728","Colombia","CO","COL","Caquetá","minor","11364","1170931653"
"Vila Franca do Campo","Vila Franca do Campo","37.7167","-25.4333","Portugal","PT","PRT","Azores","","11229","1620199147"
"Yangirabot","Yangirabot","40.0253","65.9608","Uzbekistan","UZ","UZB","Navoiy","minor","11364","1860058903"
"Woods Cross","Woods Cross","40.8731","-111.9170","United States","US","USA","Utah","","11372","1840021355"
"Caotan","Caotan","36.2501","105.1742","China","CN","CHN","Gansu","","11294","1156604804"
"Cepagatti","Cepagatti","42.3667","14.0667","Italy","IT","ITA","Abruzzo","","11014","1380321172"
"Thevūr","Thevur","11.5240","77.7539","India","IN","IND","Tamil Nādu","","10711","1356169701"
"Anguera","Anguera","-12.1508","-39.2458","Brazil","BR","BRA","Bahia","","11299","1076371301"
"Punjai Turaiyāmpālaiyam","Punjai Turaiyampalaiyam","11.5142","77.4234","India","IN","IND","Tamil Nādu","","10971","1356168380"
"Ravenna","Ravenna","41.1612","-81.2422","United States","US","USA","Ohio","","11369","1840009317"
"Nalgora","Nalgora","22.0346","88.4743","India","IN","IND","West Bengal","","10373","1356342288"
"Snyder","Snyder","32.7133","-100.9116","United States","US","USA","Texas","","11366","1840022059"
"Burrel","Burrel","41.6083","20.0111","Albania","AL","ALB","Dibër","","10862","1008883153"
"Gualcince","Gualcince","14.1167","-88.5500","Honduras","HN","HND","Lempira","","11295","1340233884"
"Passa e Fica","Passa e Fica","-6.4358","-35.6428","Brazil","BR","BRA","Rio Grande do Norte","","11100","1076772213"
"Kiáto","Kiato","38.0102","22.7493","Greece","GR","GRC","Pelopónnisos","minor","9812","1300543493"
"Herrsching am Ammersee","Herrsching am Ammersee","48.0000","11.1833","Germany","DE","DEU","Bavaria","","10843","1276350477"
"Ibicuitinga","Ibicuitinga","-4.9739","-38.6389","Brazil","BR","BRA","Ceará","","11335","1076229272"
"Arslanbob","Arslanbob","41.3333","72.9333","Kyrgyzstan","KG","KGZ","Jalal-Abad","","11291","1417741770"
"Sūlibele","Sulibele","13.1667","77.8000","India","IN","IND","Karnātaka","","10830","1356063861"
"Hanover","Hanover","41.2012","-75.9290","United States","US","USA","Pennsylvania","","11360","1840148628"
"Ouaklim Oukider","Ouaklim Oukider","31.4500","-5.5500","Morocco","MA","MAR","Drâa-Tafilalet","","11338","1504219729"
"Ipaporanga","Ipaporanga","-4.9000","-40.7589","Brazil","BR","BRA","Ceará","","11343","1076064095"
"Makoua","Makoua","-0.0047","15.6228","Congo (Brazzaville)","CG","COG","Cuvette","","11355","1178500492"
"Ratauli","Ratauli","26.1861","86.7104","India","IN","IND","Bihār","","10294","1356256011"
"Hormigueros","Hormigueros","18.1437","-67.1199","Puerto Rico","PR","PRI","Puerto Rico","","11358","1630023672"
"Sidi Abdellah Ben Taazizt","Sidi Abdellah Ben Taazizt","34.0019","-5.3695","Morocco","MA","MAR","Fès-Meknès","","11227","1504278658"
"Amatenango del Valle","Amatenango del Valle","16.5167","-92.4000","Mexico","MX","MEX","Chiapas","minor","11283","1484632903"
"Langarivo","Langarivo","-14.6000","50.0500","Madagascar","MG","MDG","Antsiranana","","11326","1450358888"
"Jeumont","Jeumont","50.2944","4.1014","France","FR","FRA","Hauts-de-France","","10344","1250978042"
"Sabana Yegua","Sabana Yegua","18.7200","-71.0200","Dominican Republic","DO","DOM","","","11300","1214338368"
"Tortolì","Tortoli","39.9333","9.6500","Italy","IT","ITA","Sardegna","minor","11081","1380704860"
"Bardipuram","Bardipuram","18.6431","78.1584","India","IN","IND","Andhra Pradesh","","10987","1356822105"
"Ostbevern","Ostbevern","52.0389","7.8458","Germany","DE","DEU","North Rhine-Westphalia","","11229","1276717272"
"Champua","Champua","22.0667","85.6658","India","IN","IND","Jhārkhand","","10967","1356535081"
"San Giustino","San Giustino","43.5500","12.1833","Italy","IT","ITA","Umbria","","11213","1380320414"
"Santa Juliana","Santa Juliana","-19.3089","-47.5242","Brazil","BR","BRA","Minas Gerais","","11337","1076023766"
"Yanahuanca","Yanahuanca","-10.4914","-76.5136","Peru","PE","PER","Pasco","","11333","1604618204"
"Rostraver","Rostraver","40.1690","-79.8087","United States","US","USA","Pennsylvania","","11352","1840144128"
"Barāhi","Barahi","25.9725","86.7452","India","IN","IND","Bihār","","10189","1356995331"
"Charouine","Charouine","29.0167","-0.2667","Algeria","DZ","DZA","Adrar","","11347","1012755999"
"Miechów","Miechow","50.3578","20.0325","Poland","PL","POL","Małopolskie","minor","11215","1616754821"
"Palmerston","Palmerston","53.3500","-6.3790","Ireland","IE","IRL","Fingal","","11130","1372809336"
"Bang Ban","Bang Ban","14.4247","100.4758","Thailand","TH","THA","Phra Nakhon Si Ayutthaya","minor","11092","1764275286"
"Tsqaltubo","Tsqaltubo","42.3264","42.6006","Georgia","GE","GEO","Imereti","","11281","1268187503"
"Usmate Velate","Usmate Velate","45.6500","9.3500","Italy","IT","ITA","Lombardy","","10293","1380244054"
"Yargatti","Yargatti","15.9667","75.0167","India","IN","IND","Karnātaka","","11095","1356949845"
"Aramari","Aramari","-12.0819","-38.4989","Brazil","BR","BRA","Bahia","","11314","1076419500"
"Ermenek","Ermenek","36.6389","32.8925","Turkey","TR","TUR","Karaman","minor","11332","1792667015"
"Touama","Touama","31.5339","-7.4872","Morocco","MA","MAR","Marrakech-Safi","","11243","1504529527"
"Gurwaliā Biswās","Gurwalia Biswas","26.8372","84.4687","India","IN","IND","Bihār","","9882","1356051308"
"Capitán Sarmiento","Capitan Sarmiento","-34.1667","-59.7833","Argentina","AR","ARG","Buenos Aires","minor","11316","1032299456"
"Lindon","Lindon","40.3414","-111.7187","United States","US","USA","Utah","","11346","1840020169"
"Floresta Azul","Floresta Azul","-14.8600","-39.6600","Brazil","BR","BRA","Bahia","","11313","1076945330"
"Le Luc","Le Luc","43.3947","6.3128","France","FR","FRA","Provence-Alpes-Côte d’Azur","","11094","1250398997"
"Campbellsville","Campbellsville","37.3445","-85.3511","United States","US","USA","Kentucky","","11345","1840013242"
"Besalampy","Besalampy","-16.7500","44.4833","Madagascar","MG","MDG","Mahajanga","","11331","1450740445"
"Montanhas","Montanhas","-6.4858","-35.2878","Brazil","BR","BRA","Rio Grande do Norte","","11208","1076024908"
"Eichenzell","Eichenzell","50.4934","9.6961","Germany","DE","DEU","Hesse","","11145","1276335851"
"Holbrook","Holbrook","42.1471","-71.0057","United States","US","USA","Massachusetts","","11344","1840053551"
"Woodmere","Woodmere","29.8493","-90.0751","United States","US","USA","Louisiana","","11343","1840031148"
"Sebba","Sebba","13.4333","0.5333","Burkina Faso","BF","BFA","Sahel","minor","11298","1854960093"
"Petal","Petal","31.3477","-89.2359","United States","US","USA","Mississippi","","11342","1840014968"
"Karebilachi","Karebilachi","14.1449","75.9032","India","IN","IND","Karnātaka","","11073","1356181309"
"Iuiú","Iuiu","-14.4139","-43.5539","Brazil","BR","BRA","Bahia","","11331","1076770021"
"La Chapelle-Saint-Mesmin","La Chapelle-Saint-Mesmin","47.8897","1.8397","France","FR","FRA","Centre-Val de Loire","","10205","1250533269"
"Nandasmo","Nandasmo","11.9247","-86.1197","Nicaragua","NI","NIC","Masaya","minor","10732","1558448256"
"Chirak","Chirak","30.7206","75.1718","India","IN","IND","Punjab","","10855","1356124447"
"El Quisco","El Quisco","-33.3913","-71.6929","Chile","CL","CHL","Valparaíso","","11329","1152280245"
"Tirumayam","Tirumayam","10.2449","78.7464","India","IN","IND","Tamil Nādu","","11022","1356872503"
"Tarare","Tarare","45.8961","4.4331","France","FR","FRA","Auvergne-Rhône-Alpes","","10587","1250412318"
"Konnūr","Konnur","15.8595","75.4955","India","IN","IND","Karnātaka","","11078","1356547916"
"Sankhavaram","Sankhavaram","17.2704","82.3445","India","IN","IND","Andhra Pradesh","","11039","1356086261"
"Rāmchandarpur","Ramchandarpur","25.2365","86.1459","India","IN","IND","Bihār","","10866","1356832174"
"Rumburk","Rumburk","50.9516","14.5571","Czechia","CZ","CZE","Ústecký Kraj","","10903","1203370008"
"Søgne","Sogne","58.0942","7.7725","Norway","NO","NOR","Agder","minor","11260","1578522567"
"Ichinohe","Ichinohe","40.2129","141.2954","Japan","JP","JPN","Iwate","","11296","1392741013"
"Ambotaka","Ambotaka","-21.7500","48.0500","Madagascar","MG","MDG","Fianarantsoa","","11000","1450933772"
"Allāhdurg","Allahdurg","17.9667","77.9167","India","IN","IND","Andhra Pradesh","","11018","1356302297"
"Acate","Acate","37.0339","14.4942","Italy","IT","ITA","Sicilia","","11325","1380824734"
"Paramoti","Paramoti","-4.0969","-39.2389","Brazil","BR","BRA","Ceará","","11308","1076701260"
"Mudki","Mudki","30.7800","74.8811","India","IN","IND","Punjab","","10853","1356356006"
"Okmulgee","Okmulgee","35.6134","-96.0068","United States","US","USA","Oklahoma","","11330","1840020400"
"Mülsen","Mulsen","50.7447","12.5747","Germany","DE","DEU","Saxony","","11113","1276902814"
"Chiyoda","Chiyoda","36.2178","139.4424","Japan","JP","JPN","Gunma","","10831","1392003197"
"Satai","Satai","24.7220","79.6310","India","IN","IND","Madhya Pradesh","","10949","1356090725"
"Balsa Nova","Balsa Nova","-25.5839","-49.6358","Brazil","BR","BRA","Paraná","","11300","1076602507"
"Cislago","Cislago","45.6500","8.9667","Italy","IT","ITA","Lombardy","","10394","1380157328"
"Krosno Odrzańskie","Krosno Odrzanskie","52.0333","15.1000","Poland","PL","POL","Lubuskie","minor","11238","1616603555"
"Kasba","Kasba","25.5856","88.1122","India","IN","IND","Bihār","","10067","1356834956"
"Shiloh","Shiloh","39.8159","-84.2317","United States","US","USA","Ohio","","11327","1840153071"
"Sant’Agata de’ Goti","Sant'Agata de' Goti","41.0893","14.4974","Italy","IT","ITA","Campania","","11151","1380379201"
"Lakeland Highlands","Lakeland Highlands","27.9572","-81.9496","United States","US","USA","Florida","","11326","1840029047"
"Ūttukkuli","Uttukkuli","11.1689","77.4543","India","IN","IND","Tamil Nādu","","10948","1356275517"
"Pālamedu","Palamedu","10.1050","78.1134","India","IN","IND","Tamil Nādu","","10801","1356644642"
"Vadamugam Vellodu","Vadamugam Vellodu","11.2366","77.6617","India","IN","IND","Tamil Nādu","","10945","1356165697"
"Talupula","Talupula","14.2500","78.2667","India","IN","IND","Andhra Pradesh","","11213","1356157946"
"Sendurai","Sendurai","11.2530","79.1720","India","IN","IND","Tamil Nādu","","10978","1356882211"
"Lansing","Lansing","39.2428","-94.8972","United States","US","USA","Kansas","","11321","1840001590"
"Chittārkottal","Chittarkottal","9.4276","78.9015","India","IN","IND","Mizoram","","10706","1356274194"
"Burtonwood","Burtonwood","53.4302","-2.6614","United Kingdom","GB","GBR","Warrington","","11265","1826767649"
"Parwāha","Parwaha","26.2336","87.2197","India","IN","IND","Bihār","","9925","1356823574"
"Ollerton","Ollerton","53.2000","-1.0200","United Kingdom","GB","GBR","Nottinghamshire","","11103","1826540290"
"Eisenberg","Eisenberg","50.9667","11.9000","Germany","DE","DEU","Thuringia","minor","10885","1276013201"
"Honmachi","Honmachi","43.9115","144.6708","Japan","JP","JPN","Hokkaidō","","11302","1392192562"
"Vargaūr","Vargaur","11.1452","78.3477","India","IN","IND","Tamil Nādu","","10837","1356234408"
"Bailin","Bailin","33.4850","105.0034","China","CN","CHN","Gansu","","11144","1156100956"
"Cocoa Beach","Cocoa Beach","28.3327","-80.6274","United States","US","USA","Florida","","11316","1840015093"
"Chantepie","Chantepie","48.0886","-1.6164","France","FR","FRA","Bretagne","","10458","1250992436"
"Golfito","Golfito","8.6526","-83.1733","Costa Rica","CR","CRI","Puntarenas","","11284","1188695145"
"Rolla","Rolla","13.8331","77.1000","India","IN","IND","Andhra Pradesh","","11020","1356812229"
"Honwāda","Honwada","16.8111","77.9199","India","IN","IND","Andhra Pradesh","","10981","1356601160"
"Bom Jesus","Bom Jesus","-28.6678","-50.4169","Brazil","BR","BRA","Rio Grande do Sul","","11309","1076493056"
"Abbeville","Abbeville","29.9751","-92.1265","United States","US","USA","Louisiana","","11313","1840014005"
"Gloucester Point","Gloucester Point","37.2767","-76.5043","United States","US","USA","Virginia","","11313","1840006399"
"Bollullos de la Mitación","Bollullos de la Mitacion","37.3333","-6.1333","Spain","ES","ESP","Andalusia","","11136","1724782674"
"Kpandae","Kpandae","8.4700","-0.0200","Ghana","GH","GHA","Northern","","11239","1288068686"
"Rejiche","Rejiche","35.4667","11.0333","Tunisia","TN","TUN","Mahdia","","10806","1788954885"
"Montlouis-sur-Loire","Montlouis-sur-Loire","47.3883","0.8272","France","FR","FRA","Centre-Val de Loire","","10868","1250536524"
"Illintsi","Illintsi","49.1000","29.2000","Ukraine","UA","UKR","Vinnytska Oblast","minor","11270","1804230677"
"Tyāmagondal","Tyamagondal","13.2137","77.3010","India","IN","IND","Karnātaka","","10805","1356743390"
"Enebakk","Enebakk","59.7639","11.1444","Norway","NO","NOR","Viken","","11249","1578782892"
"Inhassoro","Inhassoro","-21.5333","35.2000","Mozambique","MZ","MOZ","Inhambane","","11297","1508276563"
"Nhandeara","Nhandeara","-20.6939","-50.0378","Brazil","BR","BRA","São Paulo","","11303","1076817297"
"Mpraeso","Mpraeso","6.5800","-0.7297","Ghana","GH","GHA","Eastern","","11190","1288953417"
"Barahpur","Barahpur","25.4192","85.8762","India","IN","IND","Bihār","","9364","1356453869"
"Imbaú","Imbau","-24.4450","-50.7608","Brazil","BR","BRA","Paraná","","11274","1076254524"
"Le Pradet","Le Pradet","43.1056","6.0233","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10277","1250619066"
"Devgeri","Devgeri","14.8512","75.3705","India","IN","IND","Karnātaka","","10949","1356329624"
"Svitlodarsk","Svitlodarsk","48.4358","38.2164","Ukraine","UA","UKR","Donetska Oblast","","11281","1804819736"
"Chāoke","Chaoke","30.1847","75.3362","India","IN","IND","Punjab","","10929","1356377488"
"Billdal","Billdal","57.5833","11.9333","Sweden","SE","SWE","Västra Götaland","","10289","1752902367"
"Cristais","Cristais","-20.8758","-45.5189","Brazil","BR","BRA","Minas Gerais","","11286","1076250590"
"Getulina","Getulina","-21.7986","-49.9286","Brazil","BR","BRA","São Paulo","","11287","1076426209"
"Thouaré-sur-Loire","Thouare-sur-Loire","47.2689","-1.4383","France","FR","FRA","Pays de la Loire","","10482","1250011839"
"Antsambalahy","Antsambalahy","-14.7167","49.9167","Madagascar","MG","MDG","Antsiranana","","11284","1450620282"
"Kaset Wisai","Kaset Wisai","15.6556","103.5836","Thailand","TH","THA","Roi Et","minor","11217","1764985093"
"Hullahalli","Hullahalli","12.1000","76.5500","India","IN","IND","Karnātaka","","10926","1356006254"
"Tysvær","Tysvaer","59.3617","5.5428","Norway","NO","NOR","Rogaland","","11283","1578779090"
"Ban Kat","Ban Kat","18.1764","97.9339","Thailand","TH","THA","Mae Hong Son","","11283","1764182671"
"Pedersöre","Pedersore","63.6642","22.7006","Finland","FI","FIN","Pohjanmaa","","11084","1246231936"
"Shenfield","Shenfield","51.6297","0.3192","United Kingdom","GB","GBR","Essex","","10511","1826482164"
"Masdi","Masdi","25.2441","86.7233","India","IN","IND","Bihār","","10111","1356331489"
"Poção","Pocao","-8.1858","-36.7050","Brazil","BR","BRA","Pernambuco","","11242","1076278807"
"Wālūr","Walur","19.4872","76.5561","India","IN","IND","Mahārāshtra","","11008","1356087133"
"Castle Pines","Castle Pines","39.4625","-104.8706","United States","US","USA","Colorado","","11296","1840022456"
"Monmouth","Monmouth","51.8100","-2.7200","United Kingdom","GB","GBR","Monmouthshire","","10508","1826379429"
"Pattanam","Pattanam","11.4728","78.2109","India","IN","IND","Tamil Nādu","","10841","1356231085"
"Derecik","Derecik","37.0830","44.3110","Turkey","TR","TUR","Hakkâri","","11264","1792649716"
"Rosario","Rosario","12.5167","124.4167","Philippines","PH","PHL","Northern Samar","","10949","1608007576"
"Iazizatene","Iazizatene","35.2544","-3.1150","Morocco","MA","MAR","Oriental","","11131","1504122645"
"Spencer","Spencer","43.1468","-95.1534","United States","US","USA","Iowa","","11292","1840000381"
"Brooklyn","Brooklyn","41.4349","-81.7497","United States","US","USA","Ohio","","11291","1840000592"
"Cabanillas del Campo","Cabanillas del Campo","40.6383","-3.2353","Spain","ES","ESP","Castille-La Mancha","","10968","1724393524"
"Chota Mollakhāli","Chota Mollakhali","22.2177","88.8957","India","IN","IND","West Bengal","","10537","1356435959"
"Pareo","Pareo","25.5582","84.8017","India","IN","IND","Bihār","","9662","1356020298"
"Cabestany","Cabestany","42.6806","2.9428","France","FR","FRA","Occitanie","","10301","1250178816"
"El Refugio","El Refugio","13.9750","-89.7072","El Salvador","SV","SLV","Ahuachapán","","10348","1222114583"
"Las Charcas","Las Charcas","18.4500","-70.6200","Dominican Republic","DO","DOM","Valdesia","","11243","1214615572"
"Areiópolis","Areiopolis","-22.6681","-48.6650","Brazil","BR","BRA","São Paulo","","11158","1076875790"
"Sakkamapatti","Sakkamapatti","9.9250","77.6039","India","IN","IND","Tamil Nādu","","11039","1356212285"
"Boguchar","Boguchar","49.9500","40.5500","Russia","RU","RUS","Voronezhskaya Oblast’","","11270","1643779708"
"Progress","Progress","40.2901","-76.8394","United States","US","USA","Pennsylvania","","11286","1840035164"
"Uropá","Uropa","-11.1406","-62.3608","Brazil","BR","BRA","Rondônia","","11272","1076856574"
"Arvand Kenār","Arvand Kenar","29.9789","48.5206","Iran","IR","IRN","Khūzestān","","11173","1364710033"
"Arvand Kenār","Arvand Kenar","30.0617","48.4508","Iran","IR","IRN","Khūzestān","","11173","1364278589"
"Cleveland","Cleveland","33.7440","-90.7285","United States","US","USA","Mississippi","","11285","1840014748"
"Wanaque","Wanaque","41.0440","-74.2900","United States","US","USA","New Jersey","","11285","1840000840"
"Apt","Apt","43.8761","5.3964","France","FR","FRA","Provence-Alpes-Côte d’Azur","minor","11037","1250780194"
"Derbisek","Derbisek","41.5608","69.3378","Kazakhstan","KZ","KAZ","","","11234","1398930532"
"Iver","Iver","51.5210","-0.5070","United Kingdom","GB","GBR","Buckinghamshire","","11119","1826636637"
"Uraí","Urai","-23.1978","-50.7958","Brazil","BR","BRA","Paraná","","11273","1076009202"
"Aukštieji Paneriai","Aukstieji Paneriai","54.6000","25.2000","Lithuania","LT","LTU","Vilniaus Miestas","","11149","1440349529"
"Garrucha","Garrucha","37.1842","-1.8214","Spain","ES","ESP","Andalusia","","10027","1724316794"
"Piên","Pien","-26.0978","-49.4289","Brazil","BR","BRA","Paraná","","11236","1076583722"
"Sant’Antìoco","Sant'Antioco","39.0664","8.4545","Italy","IT","ITA","Sardegna","","11152","1380700326"
"Kleinblittersdorf","Kleinblittersdorf","49.1583","7.0381","Germany","DE","DEU","Saarland","","10885","1276026284"
"North Dundas","North Dundas","45.0833","-75.3500","Canada","CA","CAN","Ontario","","11278","1124000474"
"Arouca","Arouca","10.6333","-61.3333","Trinidad and Tobago","TT","TTO","Tunapuna/Piarco","","10869","1780178879"
"Meulebeke","Meulebeke","50.9497","3.2869","Belgium","BE","BEL","Flanders","","10906","1056545779"
"Itikalapalle","Itikalapalle","14.6013","77.6582","India","IN","IND","Andhra Pradesh","","9980","1356091263"
"Aulla","Aulla","44.2167","9.9667","Italy","IT","ITA","Tuscany","","11092","1380929075"
"Boekel","Boekel","51.6000","5.6667","Netherlands","NL","NLD","Noord-Brabant","minor","10959","1528471312"
"El Playón","El Playon","7.4767","-73.2081","Colombia","CO","COL","Santander","minor","11252","1170615390"
"Maraial","Maraial","-8.8028","-35.8289","Brazil","BR","BRA","Pernambuco","","11220","1076038461"
"Two Rivers","Two Rivers","44.1565","-87.5824","United States","US","USA","Wisconsin","","11276","1840002206"
"Enfida","Enfida","36.1353","10.3808","Tunisia","TN","TUN","Sousse","","11138","1788901656"
"Marcali","Marcali","46.5858","17.4128","Hungary","HU","HUN","Somogy","minor","11169","1348924700"
"São Domingos","Sao Domingos","-13.3978","-46.3178","Brazil","BR","BRA","Goiás","","11272","1076319800"
"Burley","Burley","42.5379","-113.7926","United States","US","USA","Idaho","","11274","1840018683"
"Edéia","Edeia","-17.3389","-49.9308","Brazil","BR","BRA","Goiás","","11266","1076066085"
"Križevci","Krizevci","46.0258","16.5425","Croatia","HR","HRV","Koprivničko-Križevačka Županija","minor","11231","1191282973"
"Belmonte Mezzagno","Belmonte Mezzagno","38.0500","13.3833","Italy","IT","ITA","Sicilia","","11239","1380633783"
"Lowes Island","Lowes Island","39.0471","-77.3524","United States","US","USA","Virginia","","11272","1840024504"
"Drochtersen","Drochtersen","53.7000","9.3833","Germany","DE","DEU","Lower Saxony","","11184","1276168894"
"Poko","Poko","3.1500","26.8833","Congo (Kinshasa)","CD","COD","Bas-Uélé","","11253","1180049667"
"Nieder-Olm","Nieder-Olm","49.9083","8.2028","Germany","DE","DEU","Rhineland-Palatinate","","10348","1276571549"
"Hamilton","Hamilton","39.9432","-77.7327","United States","US","USA","Pennsylvania","","11270","1840147456"
"Melres","Melres","41.0667","-8.4000","Portugal","PT","PRT","Porto","","10328","1620821477"
"Fiľakovo","Fil'akovo","48.2683","19.8247","Slovakia","SK","SVK","Banská Bystrica","","10654","1703493042"
"Sonseca","Sonseca","39.7000","-3.9500","Spain","ES","ESP","Castille-La Mancha","","11084","1724606218"
"Ganga Sāgar","Ganga Sagar","21.6571","88.0800","India","IN","IND","West Bengal","","10340","1356790764"
"Zaouiet Says","Zaouiet Says","32.7931","-8.6506","Morocco","MA","MAR","Casablanca-Settat","","11160","1504180910"
"Douar Ain Maatouf","Douar Ain Maatouf","34.4352","-4.5566","Morocco","MA","MAR","Fès-Meknès","","11165","1504798283"
"Tetyushi","Tetyushi","54.9333","48.8333","Russia","RU","RUS","Tatarstan","","11250","1643097545"
"Westwood","Westwood","40.9878","-74.0308","United States","US","USA","New Jersey","","11267","1840000923"
"Joquicingo","Joquicingo","19.0556","-99.5467","Mexico","MX","MEX","México","minor","11042","1484561507"
"Mineral de Angangueo","Mineral de Angangueo","19.6206","-100.2844","Mexico","MX","MEX","Michoacán","minor","11120","1484121857"
"Largs","Largs","55.7950","-4.8700","United Kingdom","GB","GBR","North Ayrshire","","11260","1826043639"
"Capilla del Monte","Capilla del Monte","-30.8500","-64.5167","Argentina","AR","ARG","Córdoba","","11181","1032258122"
"Pasaco","Pasaco","13.9789","-90.2063","Guatemala","GT","GTM","Jutiapa","minor","11229","1320928744"
"Santa Cruz","Santa Cruz","-0.5333","-90.3500","Ecuador","EC","ECU","Galápagos","","11262","1218773967"
"Kowdalli","Kowdalli","12.0670","77.4438","India","IN","IND","Karnātaka","","11131","1356546461"
"Edd","Edd","13.9333","41.7000","Eritrea","ER","ERI","Debubawi K’eyyĭḥ Baḥri","","11259","1232211688"
"Celebration","Celebration","28.3102","-81.5510","United States","US","USA","Florida","","11264","1840014138"
"Bānāvar","Banavar","13.4167","76.1667","India","IN","IND","Karnātaka","","10991","1356909293"
"Madhuban","Madhuban","25.8948","87.1093","India","IN","IND","Bihār","","9979","1356178356"
"Laurel","Laurel","27.1507","-82.4534","United States","US","USA","Florida","","11263","1840014184"
"Feldkirchen-Westerham","Feldkirchen-Westerham","47.9000","11.8500","Germany","DE","DEU","Bavaria","","11055","1276377703"
"Kamargani","Kamargani","22.5475","88.6606","India","IN","IND","West Bengal","","9555","1356656536"
"Errahalli","Errahalli","12.3911","78.2232","India","IN","IND","Tamil Nādu","","10768","1356634774"
"Monaragala","Monaragala","6.8726","81.3373","Sri Lanka","LK","LKA","Uva","","10123","1144735194"
"Drākshārāma","Draksharama","16.7928","82.0635","India","IN","IND","Andhra Pradesh","","9299","1356061765"
"Erraguntla","Erraguntla","15.2821","78.5106","India","IN","IND","Andhra Pradesh","","10890","1356020263"
"Barton upon Humber","Barton upon Humber","53.6833","-0.4500","United Kingdom","GB","GBR","North Lincolnshire","","11066","1826911147"
"Wellesley","Wellesley","43.5500","-80.7167","Canada","CA","CAN","Ontario","","11260","1124590159"
"Meiwa","Meiwa","36.2113","139.5342","Japan","JP","JPN","Gunma","","10714","1392003514"
"Lubawa","Lubawa","53.5000","19.7500","Poland","PL","POL","Warmińsko-Mazurskie","","10626","1616962128"
"Moyuta","Moyuta","14.0381","-90.0804","Guatemala","GT","GTM","Jutiapa","minor","11229","1320284268"
"Engen","Engen","47.8528","8.7714","Germany","DE","DEU","Baden-Württemberg","","11101","1276209924"
"Norwell","Norwell","42.1608","-70.8177","United States","US","USA","Massachusetts","","11258","1840053572"
"Estevan","Estevan","49.1392","-102.9861","Canada","CA","CAN","Saskatchewan","","11258","1124416742"
"Pūdimadaka","Pudimadaka","17.5000","83.0167","India","IN","IND","Andhra Pradesh","","9912","1356646092"
"Duraiswāmipuram","Duraiswamipuram","9.4233","77.7203","India","IN","IND","Tamil Nādu","","10488","1356212943"
"Raibhīr","Raibhir","25.9978","86.8781","India","IN","IND","Bihār","","10230","1356512738"
"Jeseník","Jesenik","50.2297","17.2047","Czechia","CZ","CZE","Olomoucký Kraj","","10977","1203267061"
"Bewdley","Bewdley","52.3758","-2.3150","United Kingdom","GB","GBR","Worcestershire","","9470","1826282948"
"Santa Clara La Laguna","Santa Clara La Laguna","14.7167","-91.3000","Guatemala","GT","GTM","Sololá","minor","10390","1320225556"
"Potukonda","Potukonda","17.2708","81.7540","India","IN","IND","Andhra Pradesh","","11169","1356128984"
"Santa Cruz Balanyá","Santa Cruz Balanya","14.6869","-90.9194","Guatemala","GT","GTM","Chimaltenango","minor","10981","1320621567"
"Quintanar de la Orden","Quintanar de la Orden","39.5906","-3.0428","Spain","ES","ESP","Castille-La Mancha","","11129","1724764204"
"Douar Jwalla","Douar Jwalla","31.8900","-7.4400","Morocco","MA","MAR","Marrakech-Safi","","11168","1504383482"
"Polistena","Polistena","38.4000","16.0667","Italy","IT","ITA","Calabria","","10373","1380105837"
"Groton","Groton","42.6137","-71.5614","United States","US","USA","Massachusetts","","11254","1840053636"
"Alpu","Alpu","39.7667","30.9500","Turkey","TR","TUR","Eskişehir","minor","11242","1792508454"
"Pottipuram","Pottipuram","9.9709","77.2137","India","IN","IND","Tamil Nādu","","10833","1356251391"
"Sierra Madre","Sierra Madre","34.1687","-118.0504","United States","US","USA","California","","11253","1840021867"
"Fort Meade","Fort Meade","39.1061","-76.7437","United States","US","USA","Maryland","","11253","1840073581"
"Beaumont-sur-Oise","Beaumont-sur-Oise","49.1425","2.2864","France","FR","FRA","Île-de-France","","9555","1250864687"
"Saddlebrooke","Saddlebrooke","32.5576","-110.8740","United States","US","USA","Arizona","","11252","1840075484"
"Štúrovo","Sturovo","47.7992","18.7181","Slovakia","SK","SVK","Nitra","","10524","1703382552"
"Lorch","Lorch","48.7983","9.6883","Germany","DE","DEU","Baden-Württemberg","","10929","1276178171"
"Nova Laranjeiras","Nova Laranjeiras","-25.3069","-52.5408","Brazil","BR","BRA","Paraná","","11241","1076553896"
"Glückstadt","Gluckstadt","53.7917","9.4219","Germany","DE","DEU","Schleswig-Holstein","","10779","1276320912"
"Kirchseeon","Kirchseeon","48.0731","11.8861","Germany","DE","DEU","Bavaria","","10655","1276337335"
"Milford","Milford","38.9091","-75.4227","United States","US","USA","Delaware","","11249","1840006069"
"North Saanich","North Saanich","48.6142","-123.4200","Canada","CA","CAN","British Columbia","","11249","1124000779"
"Kapasiāwān","Kapasiawan","25.2783","85.2869","India","IN","IND","Bihār","","9960","1356794248"
"Inhangapi","Inhangapi","-1.4300","-47.9169","Brazil","BR","BRA","Pará","","11224","1076688647"
"Linluo","Linluo","22.6506","120.5271","Taiwan","TW","TWN","Pingtung","","10586","1158226688"
"Otar","Otar","43.5375","75.2089","Kazakhstan","KZ","KAZ","","","11238","1398243169"
"Sokhodewara","Sokhodewara","24.8358","85.8725","India","IN","IND","Bihār","","10599","1356231172"
"Sanjiaocheng","Sanjiaocheng","36.8993","100.9872","China","CN","CHN","Qinghai","minor","11196","1156963615"
"Mishrikot","Mishrikot","15.2465","75.0567","India","IN","IND","Karnātaka","","10992","1356481655"
"Saltsjöbaden","Saltsjobaden","59.2861","18.2872","Sweden","SE","SWE","Stockholm","","9491","1752396058"
"Paināl","Painal","25.5900","84.9304","India","IN","IND","Bihār","","9979","1356753434"
"Sahidganj","Sahidganj","25.6627","87.0976","India","IN","IND","Bihār","","10094","1356212257"
"Friedrichsthal","Friedrichsthal","49.3256","7.0961","Germany","DE","DEU","Saarland","","10133","1276137098"
"Vire","Vire","48.8386","-0.8892","France","FR","FRA","Normandie","minor","10762","1250663430"
"Therwil","Therwil","47.4997","7.5500","Switzerland","CH","CHE","Basel-Landschaft","","9947","1756897872"
"Chicholi","Chicholi","22.0100","77.6600","India","IN","IND","Madhya Pradesh","","11167","1356100953"
"Quincy-sous-Sénart","Quincy-sous-Senart","48.6711","2.5339","France","FR","FRA","Île-de-France","","9426","1250864916"
"Korablino","Korablino","53.9167","40.0167","Russia","RU","RUS","Ryazanskaya Oblast’","","11220","1643006109"
"Thạnh Phú","Thanh Phu","9.9539","106.5069","Vietnam","VN","VNM","Bến Tre","minor","10331","1704975971"
"Kusumha","Kusumha","25.1859","85.9234","India","IN","IND","Bihār","","9725","1356358917"
"Los Almácigos","Los Almacigos","19.4083","-71.4417","Dominican Republic","DO","DOM","Cibao Noroeste","","11183","1214526672"
"Chamestān","Chamestan","36.4772","52.1167","Iran","IR","IRN","Māzandarān","","11194","1364361980"
"Xintianfeng","Xintianfeng","24.4575","115.5205","China","CN","CHN","Guangdong","","10974","1156028668"
"Lake Elmo","Lake Elmo","44.9944","-92.9031","United States","US","USA","Minnesota","","11237","1840007814"
"Goldach","Goldach","47.4831","9.4664","Switzerland","CH","CHE","Sankt Gallen","","9269","1756725037"
"Lavandevīl","Lavandevil","38.3089","48.8708","Iran","IR","IRN","Gīlān","","11235","1364485386"
"Bataiporã","Bataipora","-22.2950","-53.2708","Brazil","BR","BRA","Mato Grosso do Sul","","11228","1076881437"
"Banagi","Banagi","13.6100","75.5300","India","IN","IND","Karnātaka","","11137","1356507028"
"Thepaha Rāja Rām","Thepaha Raja Ram","26.2229","84.2523","India","IN","IND","Bihār","","9662","1356390012"
"Ibiraçu","Ibiracu","-19.8319","-40.3700","Brazil","BR","BRA","Espírito Santo","","11178","1076949374"
"Dois Riachos","Dois Riachos","-9.3928","-37.1008","Brazil","BR","BRA","Alagoas","","11214","1076407140"
"Cerreto Guidi","Cerreto Guidi","43.7667","10.8833","Italy","IT","ITA","Tuscany","","11010","1380624568"
"Gold Canyon","Gold Canyon","33.3639","-111.4230","United States","US","USA","Arizona","","11233","1840018032"
"Boguchany","Boguchany","58.3667","97.4500","Russia","RU","RUS","Krasnoyarskiy Kray","","11232","1643463760"
"Kāza","Kaza","16.3887","80.5412","India","IN","IND","Andhra Pradesh","","10146","1356130169"
"Kranenburg","Kranenburg","51.7897","6.0072","Germany","DE","DEU","North Rhine-Westphalia","","11087","1276141690"
"Kursavka","Kursavka","44.4500","42.5000","Russia","RU","RUS","Stavropol’skiy Kray","","11215","1643870461"
"Hudson","Hudson","28.3595","-82.6894","United States","US","USA","Florida","","11230","1840014115"
"Jalkaura","Jalkaura","25.5034","86.3671","India","IN","IND","Bihār","","9534","1356999738"
"Īmani","Imani","16.3302","80.6580","India","IN","IND","Andhra Pradesh","","10714","1356911251"
"Bayou Blue","Bayou Blue","29.6341","-90.6733","United States","US","USA","Louisiana","","11229","1840039382"
"Týrnavos","Tyrnavos","39.7333","22.2833","Greece","GR","GRC","Thessalía","minor","11069","1300449251"
"Nallamada","Nallamada","14.2164","77.9944","India","IN","IND","Andhra Pradesh","","10944","1356596502"
"Fino Mornasco","Fino Mornasco","45.7500","9.0333","Italy","IT","ITA","Lombardy","","9865","1380369319"
"Palsud","Palsud","21.8262","74.9691","India","IN","IND","Madhya Pradesh","","10950","1356106181"
"Lindome","Lindome","57.5667","12.1000","Sweden","SE","SWE","Västra Götaland","","11037","1752017510"
"Nueva Toltén","Nueva Tolten","-39.1786","-73.1656","Chile","CL","CHL","Araucanía","","11216","1152563189"
"Braunfels","Braunfels","50.5167","8.3833","Germany","DE","DEU","Hesse","","10994","1276721051"
"Moda","Moda","13.8246","77.4421","India","IN","IND","Andhra Pradesh","","10752","1356140958"
"Mapleton","Mapleton","40.1188","-111.5742","United States","US","USA","Utah","","11226","1840020170"
"Boujediane","Boujediane","35.1114","-5.7823","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","11166","1504110188"
"Vanj","Vanj","38.3731","71.4553","Tajikistan","TJ","TJK","Kŭhistoni Badakhshon","minor","11217","1762019950"
"Jardim Alegre","Jardim Alegre","-24.1789","-51.6919","Brazil","BR","BRA","Paraná","","11196","1076799071"
"Rāmabhadrapuram","Ramabhadrapuram","18.5000","83.2833","India","IN","IND","Andhra Pradesh","","10962","1356969255"
"Bagamanoc","Bagamanoc","13.9408","124.2888","Philippines","PH","PHL","Catanduanes","","11086","1608272701"
"Banta","Banta","24.1025","88.2864","India","IN","IND","West Bengal","","10400","1356001065"
"San José de Aerocuar","San Jose de Aerocuar","10.6027","-63.3273","Venezuela","VE","VEN","Sucre","minor","11162","1862054804"
"Plaine Magnien","Plaine Magnien","-20.4286","57.6531","Mauritius","MU","MUS","Grand Port","","10443","1480551714"
"Santa Croce Camerina","Santa Croce Camerina","36.8272","14.5239","Italy","IT","ITA","Sicilia","","10955","1380471228"
"Jalālpur","Jalalpur","26.0433","84.5041","India","IN","IND","Bihār","","9773","1356153368"
"Stonehaven","Stonehaven","56.9640","-2.2110","United Kingdom","GB","GBR","Aberdeenshire","","11170","1826190740"
"Chulym","Chulym","55.1167","80.9667","Russia","RU","RUS","Novosibirskaya Oblast’","","11216","1643503284"
"Aroāli","Aroali","23.9146","88.1464","India","IN","IND","West Bengal","","10115","1356479945"
"Dhamsāin","Dhamsain","26.0938","86.2541","India","IN","IND","Bihār","","9752","1356042628"
"Armstrong","Armstrong","-32.7833","-61.6000","Argentina","AR","ARG","Santa Fe","","11181","1032420901"
"Salcedo","Salcedo","17.1517","120.5361","Philippines","PH","PHL","Ilocos Sur","","11110","1608432119"
"Shahmīrzād","Shahmirzad","35.7728","53.3286","Iran","IR","IRN","Semnān","","11191","1364136501"
"Efatsy-Anandroza","Efatsy-Anandroza","-23.1167","47.6000","Madagascar","MG","MDG","Fianarantsoa","","11000","1450536515"
"Yakınca","Yakinca","38.3000","38.2500","Turkey","TR","TUR","Malatya","","10943","1792067051"
"Kamalāpuram","Kamalapuram","18.1600","79.5406","India","IN","IND","Andhra Pradesh","","10423","1356308072"
"Tinchlik","Tinchlik","40.4264","71.4956","Uzbekistan","UZ","UZB","Farg‘ona","","10644","1860903091"
"Shahrinav","Shahrinav","38.5667","68.3333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","11123","1762284925"
"Māruteru","Maruteru","16.6237","81.7394","India","IN","IND","Andhra Pradesh","","10346","1356126599"
"Iygli","Iygli","29.5001","-9.0501","Morocco","MA","MAR","Souss-Massa","","11197","1504318474"
"Ocean City","Ocean City","39.2681","-74.6020","United States","US","USA","New Jersey","","11211","1840001598"
"Balingoan","Balingoan","9.0000","124.8500","Philippines","PH","PHL","Misamis Oriental","","11020","1608600721"
"Staryya Darohi","Staryya Darohi","53.0394","28.2650","Belarus","BY","BLR","Minskaya Voblasts’","minor","11198","1112727949"
"São Félix do Araguaia","Sao Felix do Araguaia","-11.6169","-50.6689","Brazil","BR","BRA","Mato Grosso","","11209","1076329274"
"Lloró","Lloro","5.5000","-76.5333","Colombia","CO","COL","Chocó","minor","11197","1170030184"
"Hardia","Hardia","25.8657","86.2176","India","IN","IND","Bihār","","9650","1356326348"
"Ban Wang Daeng","Ban Wang Daeng","17.4790","100.1247","Thailand","TH","THA","Uttaradit","","11091","1764486186"
"Siktiāhi","Siktiahi","26.4812","86.4023","India","IN","IND","Bihār","","10352","1356146314"
"Santa Inês","Santa Ines","-13.2938","-39.8655","Brazil","BR","BRA","Bahia","","11177","1076978207"
"Anderson","Anderson","40.4497","-122.2950","United States","US","USA","California","","11208","1840018733"
"Sangam","Sangam","14.5956","79.7428","India","IN","IND","Andhra Pradesh","","10698","1356998102"
"Santa Ana Huista","Santa Ana Huista","15.6833","-91.8167","Guatemala","GT","GTM","Huehuetenango","minor","11134","1320972408"
"Kasavanampatti","Kasavanampatti","10.3692","77.8699","India","IN","IND","Tamil Nādu","","10565","1356250210"
"Karuvelampatti","Karuvelampatti","9.8448","78.0451","India","IN","IND","Tamil Nādu","","10739","1356238320"
"Nādendla","Nadendla","16.2186","80.1972","India","IN","IND","Andhra Pradesh","","10935","1356006446"
"Valkeala","Valkeala","60.9389","26.7972","Finland","FI","FIN","Kymenlaakso","","11195","1246894132"
"Woodbury","Woodbury","41.3284","-74.1004","United States","US","USA","New York","","11206","1840004883"
"Gottmadingen","Gottmadingen","47.7356","8.7767","Germany","DE","DEU","Baden-Württemberg","","10750","1276476754"
"Rychnov nad Kněžnou","Rychnov nad Kneznou","50.1629","16.2750","Czechia","CZ","CZE","Královéhradecký Kraj","","10899","1203123765"
"Magnolia","Magnolia","33.2775","-93.2261","United States","US","USA","Arkansas","","11205","1840015726"
"Kīlminnal","Kilminnal","12.9447","79.2514","India","IN","IND","Tamil Nādu","","10272","1356899164"
"Węgorzewo","Wegorzewo","54.2167","21.7500","Poland","PL","POL","Warmińsko-Mazurskie","minor","11144","1616206015"
"Kothri Kalān","Kothri Kalan","23.0722","76.8296","India","IN","IND","Madhya Pradesh","","10874","1356711900"
"Hernando","Hernando","-32.4167","-63.7333","Argentina","AR","ARG","Córdoba","","11182","1032830608"
"Pihuamo","Pihuamo","19.1889","-103.3778","Mexico","MX","MEX","Jalisco","minor","11192","1484848423"
"Bernardino de Campos","Bernardino de Campos","-23.0167","-49.4833","Brazil","BR","BRA","São Paulo","","11157","1076528578"
"Trofaiach","Trofaiach","47.4261","15.0067","Austria","AT","AUT","Steiermark","","11125","1040300354"
"Gengenbach","Gengenbach","48.4000","8.0167","Germany","DE","DEU","Baden-Württemberg","","11023","1276778224"
"São João de Ver","Sao Joao de Ver","40.9540","-8.5510","Portugal","PT","PRT","Aveiro","","10579","1620357395"
"Fīnch’a’ā","Finch'a'a","9.9000","37.4500","Ethiopia","ET","ETH","Oromīya","","11134","1231348007"
"Bobil","Bobil","25.6269","86.8069","India","IN","IND","Bihār","","9416","1356867658"
"Biganos","Biganos","44.6442","-0.9783","France","FR","FRA","Nouvelle-Aquitaine","","10990","1250481750"
"Rāmpur Kalān","Rampur Kalan","26.1649","77.4691","India","IN","IND","Madhya Pradesh","","10825","1356910989"
"Kazarman","Kazarman","41.4000","74.0500","Kyrgyzstan","KG","KGZ","Jalal-Abad","minor","11191","1417532863"
"Ambinanin’i Sakaleona","Ambinanin'i Sakaleona","-20.5333","48.5500","Madagascar","MG","MDG","Fianarantsoa","","11133","1450503143"
"Mhangura","Mhangura","-16.9000","30.1500","Zimbabwe","ZW","ZWE","Mashonaland West","","11175","1716361764"
"Ebreichsdorf","Ebreichsdorf","47.9611","16.4047","Austria","AT","AUT","Niederösterreich","","10942","1040140481"
"San Nicolas Buenos Aires","San Nicolas Buenos Aires","19.1433","-97.4767","Mexico","MX","MEX","Puebla","","9972","1484819352"
"Castalla","Castalla","38.5967","-0.6708","Spain","ES","ESP","Valencia","","11097","1724005276"
"East Windsor","East Windsor","41.9049","-72.5672","United States","US","USA","Connecticut","","11191","1840044807"
"Katālpur","Katalpur","26.2255","84.7608","India","IN","IND","Bihār","","10165","1356347340"
"Sušice","Susice","49.2312","13.5202","Czechia","CZ","CZE","Plzeňský Kraj","","10957","1203245330"
"Rianxo","Rianxo","42.6500","-8.8167","Spain","ES","ESP","Galicia","","11004","1724885529"
"Pipalrawān","Pipalrawan","23.1625","76.4704","India","IN","IND","Madhya Pradesh","","10769","1356390483"
"Takad Sahel","Takad Sahel","30.2500","-9.5500","Morocco","MA","MAR","Souss-Massa","","10968","1504426241"
"Bisaria","Bisaria","25.9789","87.1003","India","IN","IND","Bihār","","9558","1356909381"
"Tadhwa Nandpur","Tadhwa Nandpur","26.7556","84.4326","India","IN","IND","Bihār","","9617","1356156219"
"Oporapa","Oporapa","2.0500","-75.9667","Colombia","CO","COL","Huila","minor","11111","1170571598"
"Ilfracombe","Ilfracombe","51.2080","-4.1200","United Kingdom","GB","GBR","Devon","","11184","1826182553"
"Willistown","Willistown","40.0010","-75.4915","United States","US","USA","Pennsylvania","","11184","1840104145"
"Raipur Buzurg","Raipur Buzurg","25.7118","85.6956","India","IN","IND","Bihār","","9238","1356843973"
"Isselburg","Isselburg","51.8331","6.4667","Germany","DE","DEU","North Rhine-Westphalia","","10928","1276291183"
"Humpolec","Humpolec","49.5417","15.3572","Czechia","CZ","CZE","Vysočina","","10975","1203780100"
"Congaz","Congaz","46.1083","28.5972","Moldova","MD","MDA","Găgăuzia","","11123","1498367811"
"Nāthpur","Nathpur","26.3261","87.0916","India","IN","IND","Bihār","","10116","1356092223"
"Mānkur","Mankur","23.4312","87.5578","India","IN","IND","West Bengal","","10370","1356698226"
"Swarna","Swarna","15.8542","80.2858","India","IN","IND","Andhra Pradesh","","10928","1356082574"
"Cameri","Cameri","45.5000","8.6500","Italy","IT","ITA","Piedmont","","10907","1380438629"
"Kariat Ben Aouda","Kariat Ben Aouda","34.7667","-5.9500","Morocco","MA","MAR","Rabat-Salé-Kénitra","","11087","1504991922"
"Säter","Sater","60.3500","15.7500","Sweden","SE","SWE","Dalarna","minor","11161","1752106065"
"Sannieshof","Sannieshof","-26.5333","25.8000","South Africa","ZA","ZAF","North West","","11016","1710190393"
"Si Wilai","Si Wilai","18.1865","103.7408","Thailand","TH","THA","Bueng Kan","minor","11090","1764803853"
"Corleone","Corleone","37.8167","13.3000","Italy","IT","ITA","Sicilia","","11128","1380757468"
"Fagersta","Fagersta","60.0042","15.7933","Sweden","SE","SWE","Västmanland","minor","11130","1752230389"
"Uchagaon","Uchagaon","15.8800","74.5600","India","IN","IND","Karnātaka","","10185","1356005611"
"Ban Bo Phlap","Ban Bo Phlap","13.8439","100.0686","Thailand","TH","THA","Nakhon Pathom","","9281","1764002485"
"Ogose","Ogose","35.9645","139.2942","Japan","JP","JPN","Saitama","","10905","1392027157"
"Pādiyūr","Padiyur","10.4234","78.0254","India","IN","IND","Tamil Nādu","","10738","1356222294"
"Serris","Serris","48.8564","2.7861","France","FR","FRA","Île-de-France","","9494","1250685279"
"Barrington","Barrington","42.1515","-88.1281","United States","US","USA","Illinois","","11174","1840011244"
"Castel Mella","Castel Mella","45.5000","10.1500","Italy","IT","ITA","Lombardy","","11010","1380864073"
"Imerimandroso","Imerimandroso","-17.4333","48.5833","Madagascar","MG","MDG","Toamasina","","11000","1450128225"
"Xinyaoshang","Xinyaoshang","26.8350","106.8403","China","CN","CHN","Guizhou","","11010","1156601329"
"Pipraun","Pipraun","26.5990","85.9356","India","IN","IND","Bihār","","9913","1356858835"
"Potosí","Potosi","11.4942","-85.8556","Nicaragua","NI","NIC","Rivas","minor","11094","1558256706"
"Petua","Petua","22.4143","88.4489","India","IN","IND","West Bengal","","9596","1356023714"
"Suhāgi","Suhagi","23.2206","79.9569","India","IN","IND","Madhya Pradesh","","10751","1356418390"
"San Valentino Torio","San Valentino Torio","40.7911","14.6033","Italy","IT","ITA","Campania","","10937","1380213807"
"Simpelveld","Simpelveld","50.8333","5.9833","Netherlands","NL","NLD","Limburg","minor","10516","1528926079"
"Mahādeopur","Mahadeopur","18.7316","79.9837","India","IN","IND","Andhra Pradesh","","11109","1356631182"
"Mangalmé","Mangalme","12.3547","19.6139","Chad","TD","TCD","Guéra","","11155","1148616441"
"Miastko","Miastko","54.0167","16.9833","Poland","PL","POL","Pomorskie","","11123","1616803904"
"Smithfield","Smithfield","35.5133","-78.3495","United States","US","USA","North Carolina","","11168","1840017795"
"Chikha","Chikha","23.8898","93.5335","Myanmar","MM","MMR","Chin State","","11156","1104924225"
"Regen","Regen","48.9667","13.1333","Germany","DE","DEU","Bavaria","minor","11001","1276612143"
"Gokinepalle","Gokinepalle","16.9228","79.7828","India","IN","IND","Andhra Pradesh","","10836","1356874906"
"Pine Castle","Pine Castle","28.4651","-81.3741","United States","US","USA","Florida","","11167","1840014097"
"Burr Ridge","Burr Ridge","41.7485","-87.9200","United States","US","USA","Illinois","","11167","1840010168"
"Tanippādi","Tanippadi","12.1078","78.8340","India","IN","IND","Tamil Nādu","","10863","1356926881"
"Hagen im Bremischen","Hagen im Bremischen","53.3577","8.6456","Germany","DE","DEU","Lower Saxony","","11110","1276318696"
"Storm Lake","Storm Lake","42.6431","-95.1960","United States","US","USA","Iowa","","11166","1840000397"
"Urbana","Urbana","40.1085","-83.7541","United States","US","USA","Ohio","","11166","1840010488"
"Warren","Warren","41.7282","-71.2629","United States","US","USA","Rhode Island","","11166","1840066117"
"Zakamensk","Zakamensk","50.3833","103.3000","Russia","RU","RUS","Buryatiya","","11164","1643401848"
"Buxerolles","Buxerolles","46.5975","0.3492","France","FR","FRA","Nouvelle-Aquitaine","","10060","1250002676"
"La Escala","La Escala","42.1136","3.1350","Spain","ES","ESP","Catalonia","","10520","1724179196"
"Trevignano","Trevignano","45.7335","12.0940","Italy","IT","ITA","Veneto","","10759","1380274035"
"Inzago","Inzago","45.5333","9.4833","Italy","IT","ITA","Lombardy","","10949","1380055442"
"Taylorville","Taylorville","39.5328","-89.2804","United States","US","USA","Illinois","","11163","1840009547"
"Begogo","Begogo","-23.4833","46.7167","Madagascar","MG","MDG","Fianarantsoa","","11146","1450909068"
"Rājagiri","Rajagiri","10.9333","79.2333","India","IN","IND","Tamil Nādu","","10197","1356173611"
"Szprotawa","Szprotawa","51.5667","15.5333","Poland","PL","POL","Lubuskie","","11068","1616764705"
"Hârlău","Harlau","47.4278","26.9114","Romania","RO","ROU","Iaşi","","10905","1642634665"
"Waggaman","Waggaman","29.9373","-90.2354","United States","US","USA","Louisiana","","11161","1840013991"
"Byelaazyorsk","Byelaazyorsk","52.4500","25.1667","Belarus","BY","BLR","Brestskaya Voblasts’","","11115","1112431328"
"Héricourt","Hericourt","47.5775","6.7617","France","FR","FRA","Bourgogne-Franche-Comté","","10654","1250957628"
"Ichikawa","Ichikawa","34.9893","134.7633","Japan","JP","JPN","Hyōgo","","11023","1392003503"
"Barmstedt","Barmstedt","53.7833","9.7667","Germany","DE","DEU","Schleswig-Holstein","","10542","1276501444"
"Wells","Wells","43.3267","-70.6336","United States","US","USA","Maine","","11156","1840053052"
"Baley","Baley","51.6000","116.6333","Russia","RU","RUS","Zabaykal’skiy Kray","","11151","1643419393"
"Covington","Covington","30.4810","-90.1122","United States","US","USA","Louisiana","","11155","1840015019"
"Osterwieck","Osterwieck","51.9667","10.7167","Germany","DE","DEU","Saxony-Anhalt","","11103","1276571992"
"Ilāmi","Ilami","24.6776","87.8804","India","IN","IND","Jhārkhand","","9496","1356907870"
"Karadichittūr","Karadichittur","11.8289","78.8758","India","IN","IND","Tamil Nādu","","10641","1356245616"
"Betzdorf","Betzdorf","50.7856","7.8728","Germany","DE","DEU","Rhineland-Palatinate","","10141","1276302333"
"Chicureo Abajo","Chicureo Abajo","-33.2833","-70.6333","Chile","CL","CHL","Región Metropolitana","","10975","1152232267"
"Shepperton","Shepperton","51.3900","-0.4600","United Kingdom","GB","GBR","Surrey","","9753","1826632972"
"Barros Cassal","Barros Cassal","-29.0928","-52.5828","Brazil","BR","BRA","Rio Grande do Sul","","11133","1076089554"
"Marudūr","Marudur","11.2346","76.9064","India","IN","IND","Tamil Nādu","","10701","1356201258"
"Kerap","Kerap","24.8332","84.6140","India","IN","IND","Bihār","","9452","1356952946"
"Bairiyā","Bairiya","26.3392","84.8236","India","IN","IND","Bihār","","9424","1356025009"
"Yamanouchi","Yamanouchi","36.7446","138.4127","Japan","JP","JPN","Nagano","","11106","1392003097"
"Longtaixiang","Longtaixiang","34.5988","104.8963","China","CN","CHN","Gansu","","11063","1156139417"
"Şā’īn Qal‘eh","Sa'in Qal\`eh","36.3058","49.0739","Iran","IR","IRN","Zanjān","","11083","1364854942"
"Wisła","Wisla","49.6549","18.8595","Poland","PL","POL","Śląskie","","11048","1616358703"
"Besagarahalli","Besagarahalli","12.6333","77.0000","India","IN","IND","Karnātaka","","10669","1356153935"
"Bhadsara","Bhadsara","25.3696","84.8444","India","IN","IND","Bihār","","9884","1356255694"
"Hājīpur","Hajipur","25.2657","87.5019","India","IN","IND","Jhārkhand","","10030","1356428946"
"Māraneri","Maraneri","9.4333","77.7500","India","IN","IND","Tamil Nādu","","10376","1356214206"
"Barahra","Barahra","26.2356","86.5615","India","IN","IND","Bihār","","9877","1356259938"
"Urlāha","Urlaha","25.7440","87.0929","India","IN","IND","Bihār","","9745","1356063015"
"Oued Amlil","Oued Amlil","34.2000","-4.2833","Morocco","MA","MAR","Fès-Meknès","","10405","1504286809"
"Chandūr","Chandur","16.9800","79.0600","India","IN","IND","Andhra Pradesh","","10880","1356371611"
"Nakagawa","Nakagawa","33.9511","134.6622","Japan","JP","JPN","Tokushima","","10576","1392003509"
"Marilândia","Marilandia","-19.4128","-40.5419","Brazil","BR","BRA","Espírito Santo","","11107","1076343904"
"Belzig","Belzig","52.1422","12.5956","Germany","DE","DEU","Brandenburg","","11096","1276375390"
"Pandāravādai","Pandaravadai","10.9133","79.2419","India","IN","IND","Tamil Nādu","","10177","1356173614"
"Tha Chang","Tha Chang","9.2674","99.1922","Thailand","TH","THA","Surat Thani","minor","11108","1764489461"
"Bir Ghbalou","Bir Ghbalou","36.2642","3.5836","Algeria","DZ","DZA","Médéa","","11016","1012249358"
"Gucheng","Gucheng","34.4545","105.2064","China","CN","CHN","Gansu","","11096","1156039068"
"Al ‘Amādīyah","Al \`Amadiyah","37.0925","43.4872","Iraq","IQ","IRQ","Dahūk","minor","11000","1368510045"
"São Jerônimo da Serra","Sao Jeronimo da Serra","-23.7278","-50.7408","Brazil","BR","BRA","Paraná","","11128","1076606884"
"Kumbhāri","Kumbhari","21.2088","83.6467","India","IN","IND","Odisha","","10636","1356155567"
"Wulingshancun","Wulingshancun","40.4755","117.4888","China","CN","CHN","Hebei","","11091","1156559155"
"Nandayure","Nandayure","9.9014","-85.3036","Costa Rica","CR","CRI","Guanacaste","","11121","1188253070"
"Ekamba","Ekamba","25.9689","87.5741","India","IN","IND","Bihār","","10090","1356070773"
"Flöha","Floha","50.8558","13.0714","Germany","DE","DEU","Saxony","","10762","1276141987"
"Charlton Kings","Charlton Kings","51.8877","-2.0413","United Kingdom","GB","GBR","Gloucestershire","","10396","1826345759"
"Kirensk","Kirensk","57.7833","108.0833","Russia","RU","RUS","Irkutskaya Oblast’","","11139","1643494440"
"Manandroy","Manandroy","-21.1333","47.2667","Madagascar","MG","MDG","Fianarantsoa","","11000","1450182639"
"Vinaninkarena","Vinaninkarena","-19.9500","47.0500","Madagascar","MG","MDG","Antananarivo","","11000","1450932095"
"Chinna Annalūru","Chinna Annaluru","14.8913","79.6761","India","IN","IND","Andhra Pradesh","","11006","1356908442"
"Bertinoro","Bertinoro","44.1500","12.1333","Italy","IT","ITA","Emilia-Romagna","","10947","1380444887"
"Hikawadai","Hikawadai","32.5825","130.6737","Japan","JP","JPN","Kumamoto","","11038","1392660609"
"De Witt","De Witt","43.0300","-76.0819","United States","US","USA","New York","","11138","1840153011"
"Jyllinge","Jyllinge","55.7511","12.1064","Denmark","DK","DNK","Sjælland","","10207","1208809285"
"Tolna","Tolna","46.4236","18.7903","Hungary","HU","HUN","Tolna","minor","10987","1348510202"
"Westtown","Westtown","39.9417","-75.5565","United States","US","USA","Pennsylvania","","11137","1840035366"
"Wimauma","Wimauma","27.6964","-82.3034","United States","US","USA","Florida","","11137","1840014155"
"Leers","Leers","50.6817","3.2439","France","FR","FRA","Hauts-de-France","","9400","1250179174"
"Clayton","Clayton","37.9404","-121.9301","United States","US","USA","California","","11136","1840018901"
"Timonium","Timonium","39.4459","-76.6032","United States","US","USA","Maryland","","11136","1840026624"
"Shōō","Shoo","35.0418","134.1162","Japan","JP","JPN","Okayama","","10933","1392003081"
"Puerto Caicedo","Puerto Caicedo","0.6850","-76.6044","Colombia","CO","COL","Putumayo","minor","11122","1170335113"
"Ekchāri","Ekchari","25.2093","87.2159","India","IN","IND","Bihār","","9482","1356587398"
"Yairipok","Yairipok","24.6779","94.0477","India","IN","IND","Manipur","","10904","1356732628"
"Four Corners","Four Corners","29.6705","-95.6596","United States","US","USA","Texas","","11133","1840018265"
"Tepetlán","Tepetlan","19.6667","-96.8000","Mexico","MX","MEX","Veracruz","minor","9668","1484555085"
"Fronteiras","Fronteiras","-7.0878","-40.6158","Brazil","BR","BRA","Piauí","","11117","1076000188"
"Damme","Damme","51.2500","3.2833","Belgium","BE","BEL","Flanders","","11008","1056742472"
"Campolongo Maggiore","Campolongo Maggiore","45.3333","12.0500","Italy","IT","ITA","Veneto","","10678","1380044601"
"Wen’anyi","Wen'anyi","36.8658","110.0553","China","CN","CHN","Shaanxi","","11075","1156356046"
"Wangjiabian","Wangjiabian","38.2412","110.2353","China","CN","CHN","Shaanxi","","11050","1156399638"
"Semuto","Semuto","0.6200","32.3278","Uganda","UG","UGA","Nakaseke","","10935","1800177094"
"Middle Island","Middle Island","40.8857","-72.9454","United States","US","USA","New York","","11129","1840005045"
"Aduku","Aduku","2.0194","32.7200","Uganda","UG","UGA","Kwania","","10700","1800302255"
"Alella","Alella","41.4953","2.2958","Spain","ES","ESP","Catalonia","","10079","1724686640"
"Miānpur Dubauli","Mianpur Dubauli","26.7381","84.4661","India","IN","IND","Bihār","","9560","1356096686"
"Gachetá","Gacheta","4.8176","-73.6360","Colombia","CO","COL","Cundinamarca","minor","11086","1170649939"
"Sabana Grande","Sabana Grande","18.0832","-66.9645","Puerto Rico","PR","PRI","Puerto Rico","","11128","1630023550"
"East Hanover","East Hanover","40.8192","-74.3638","United States","US","USA","New Jersey","","11128","1840081722"
"Lloyd","Lloyd","41.7286","-73.9961","United States","US","USA","New York","","11128","1840058260"
"Tournon-sur-Rhône","Tournon-sur-Rhone","45.0672","4.8328","France","FR","FRA","Auvergne-Rhône-Alpes","minor","10622","1250710845"
"Kongaralli","Kongaralli","12.1500","77.1000","India","IN","IND","Karnātaka","","10994","1356390932"
"Ráckeve","Rackeve","47.1608","18.9456","Hungary","HU","HUN","Pest","minor","10956","1348331289"
"Arakeri","Arakeri","16.9181","75.6950","India","IN","IND","Karnātaka","","10802","1356430645"
"Agua de Dios","Agua de Dios","4.3781","-74.6739","Colombia","CO","COL","Cundinamarca","minor","10995","1170261758"
"El Águila","El Aguila","4.9167","-76.0833","Colombia","CO","COL","Valle del Cauca","minor","11069","1170584025"
"Mawu","Mawu","34.4310","104.9187","China","CN","CHN","Gansu","","11015","1156424951"
"Amjhera","Amjhera","22.5578","75.1185","India","IN","IND","Madhya Pradesh","","10863","1356061312"
"Weinböhla","Weinbohla","51.1667","13.5667","Germany","DE","DEU","Saxony","","10568","1276478682"
"Kumāravādi","Kumaravadi","10.5264","78.3118","India","IN","IND","Tamil Nādu","","10735","1356208650"
"Peru","Peru","40.7593","-86.0756","United States","US","USA","Indiana","","11123","1840009363"
"Itainópolis","Itainopolis","-7.4469","-41.4778","Brazil","BR","BRA","Piauí","","11109","1076648651"
"Fanlu","Fanlu","23.4494","120.6033","Taiwan","TW","TWN","Chiayi","","11029","1158910013"
"Parthenay","Parthenay","46.6486","-0.2469","France","FR","FRA","Nouvelle-Aquitaine","minor","10235","1250234426"
"Mātsavaram","Matsavaram","16.5270","79.8920","India","IN","IND","Andhra Pradesh","","10388","1356409760"
"Aliganj","Aliganj","24.9365","85.9250","India","IN","IND","Bihār","","10178","1356234041"
"Campinorte","Campinorte","-14.3139","-49.1519","Brazil","BR","BRA","Goiás","","11111","1076281992"
"Hammelburg","Hammelburg","50.1167","9.9000","Germany","DE","DEU","Bavaria","","11037","1276000245"
"Aydıncık","Aydincik","36.1417","33.3178","Turkey","TR","TUR","Mersin","minor","11088","1792846540"
"Kārīz","Kariz","34.8128","60.8242","Iran","IR","IRN","Khorāsān-e Raẕavī","","11102","1364756203"
"Sigatoka","Sigatoka","-18.1414","177.5069","Fiji","FJ","FJI","Nadroga and Navosa","","9622","1242813237"
"Gainrha","Gainrha","25.9916","87.4648","India","IN","IND","Bihār","","9270","1356080800"
"Tiri","Tiri","25.8871","86.6757","India","IN","IND","Bihār","","10002","1356927315"
"Union City","Union City","36.4267","-89.0474","United States","US","USA","Tennessee","","11119","1840015280"
"Appārāopeta","Apparaopeta","16.8987","81.5684","India","IN","IND","Andhra Pradesh","","10237","1356163304"
"Sosnivka","Sosnivka","50.2946","24.2525","Ukraine","UA","UKR","Lvivska Oblast","","11058","1804036545"
"Karapa","Karapa","16.9000","82.1667","India","IN","IND","Andhra Pradesh","","10086","1356018044"
"El Trébol","El Trebol","-32.1833","-61.7167","Argentina","AR","ARG","Santa Fe","","11086","1032100113"
"Río Cuarto","Rio Cuarto","10.4076","-84.2135","Costa Rica","CR","CRI","Alajuela","","11074","1188906648"
"Batesville","Batesville","35.7687","-91.6227","United States","US","USA","Arkansas","","11117","1840013398"
"Antanandava","Antanandava","-15.8500","48.8167","Madagascar","MG","MDG","Mahajanga","","11000","1450365453"
"Narot Mehra","Narot Mehra","32.2673","75.5647","India","IN","IND","Punjab","","10152","1356739104"
"Moita Bonita","Moita Bonita","-10.5778","-37.3428","Brazil","BR","BRA","Sergipe","","11001","1076243003"
"Niémasson","Niemasson","10.3078","2.0378","Benin","BJ","BEN","Atacora","","11069","1204862347"
"San Bernardo","San Bernardo","-27.2667","-60.7000","Argentina","AR","ARG","Chaco","minor","11101","1032602723"
"Calçado","Calcado","-8.7419","-36.3339","Brazil","BR","BRA","Pernambuco","","11018","1076615496"
"Akhnūr","Akhnur","32.8667","74.7333","India","IN","IND","Jammu and Kashmīr","","10770","1356433902"
"Maryānaj","Maryanaj","34.8311","48.4592","Iran","IR","IRN","Hamadān","","10848","1364941323"
"Myślibórz","Mysliborz","52.9333","14.8667","Poland","PL","POL","Zachodniopomorskie","minor","11049","1616407161"
"Pompton Lakes","Pompton Lakes","41.0024","-74.2859","United States","US","USA","New Jersey","","11113","1840000833"
"Santo André","Santo Andre","38.0500","-8.7500","Portugal","PT","PRT","Setúbal","","10647","1620996598"
"Montale","Montale","43.9333","11.0167","Italy","IT","ITA","Tuscany","","10777","1380772390"
"Alberique","Alberique","39.1167","-0.5211","Spain","ES","ESP","Valencia","","10710","1724900545"
"Kataha","Kataha","26.7159","84.9424","India","IN","IND","Bihār","","9643","1356221445"
"Bolkhov","Bolkhov","53.4500","36.0167","Russia","RU","RUS","Orlovskaya Oblast’","","11097","1643585692"
"Asahni","Asahni","26.0079","84.4799","India","IN","IND","Bihār","","9469","1356020119"
"Merriam","Merriam","39.0186","-94.6933","United States","US","USA","Kansas","","11110","1840003830"
"Lake Grove","Lake Grove","40.8586","-73.1168","United States","US","USA","New York","","11110","1840005121"
"Târgu Frumos","Targu Frumos","47.2097","27.0131","Romania","RO","ROU","Iaşi","","10475","1642141035"
"New Port Richey East","New Port Richey East","28.2605","-82.6931","United States","US","USA","Florida","","11109","1840073871"
"Mittahalli","Mittahalli","12.4210","78.1991","India","IN","IND","Tamil Nādu","","10615","1356680782"
"Griñón","Grinon","40.2167","-3.8500","Spain","ES","ESP","Madrid","","10491","1724085854"
"Rāmpur","Rampur","25.8864","86.9547","India","IN","IND","Bihār","","9723","1356702357"
"Dolhasca","Dolhasca","47.4303","26.6094","Romania","RO","ROU","Suceava","","11007","1642508577"
"Alipur","Alipur","18.8621","78.8754","India","IN","IND","Andhra Pradesh","","10813","1356133308"
"Fuller Heights","Fuller Heights","27.9227","-81.9978","United States","US","USA","Florida","","11106","1840025156"
"Mahīn","Mahin","34.2425","37.0589","Syria","SY","SYR","Ḩimş","minor","11064","1760830221"
"Miyato","Miyato","36.1772","139.1814","Japan","JP","JPN","Gunma","","10783","1392417076"
"Veselí nad Moravou","Veseli nad Moravou","48.9536","17.3765","Czechia","CZ","CZE","Jihomoravský Kraj","","10807","1203951522"
"Waasmunster","Waasmunster","51.1097","4.0847","Belgium","BE","BEL","Flanders","","10768","1056237284"
"Nazyvayevsk","Nazyvayevsk","55.5667","71.2667","Russia","RU","RUS","Omskaya Oblast’","","11101","1643639751"
"Soamanova","Soamanova","-23.2833","47.6000","Madagascar","MG","MDG","Fianarantsoa","","11000","1450833054"
"Parapuã","Parapua","-21.7681","-50.7717","Brazil","BR","BRA","São Paulo","","11098","1076691844"
"Panguipulli","Panguipulli","-39.6444","-72.3306","Chile","CL","CHL","Araucanía","","11091","1152158717"
"Mocharim","Mocharim","24.6794","84.9905","India","IN","IND","Bihār","","10059","1356221817"
"Herxheim","Herxheim","49.1469","8.2200","Germany","DE","DEU","Rhineland-Palatinate","","10732","1276512469"
"Vijes","Vijes","3.7000","-76.4500","Colombia","CO","COL","Valle del Cauca","minor","11010","1170640729"
"Singoli","Singoli","24.9667","75.3000","India","IN","IND","Madhya Pradesh","","10977","1356087335"
"Oregon","Oregon","42.9253","-89.3892","United States","US","USA","Wisconsin","","11100","1840002922"
"Janāpul","Janapul","22.8615","88.6868","India","IN","IND","West Bengal","","9515","1356020133"
"Fao Rai","Fao Rai","18.0175","103.3057","Thailand","TH","THA","Nong Khai","minor","10978","1764038877"
"Barhi","Barhi","26.4629","86.2771","India","IN","IND","Bihār","","10405","1356100468"
"Goldbach","Goldbach","49.9889","9.1864","Germany","DE","DEU","Bavaria","","10171","1276681530"
"Shiloh","Shiloh","39.9732","-76.7920","United States","US","USA","Pennsylvania","","11098","1840035195"
"Manaíra","Manaira","-7.7058","-38.1539","Brazil","BR","BRA","Paraíba","","11066","1076296025"
"Snodland","Snodland","51.3280","0.4467","United Kingdom","GB","GBR","Kent","","10211","1826888487"
"Guilherand","Guilherand","44.9344","4.8747","France","FR","FRA","Auvergne-Rhône-Alpes","","10760","1250707207"
"Kaunra","Kaunra","25.5231","84.5575","India","IN","IND","Bihār","","9906","1356037434"
"Gorha","Gorha","26.2267","86.9349","India","IN","IND","Bihār","","9815","1356053028"
"Dhilwan","Dhilwan","31.5143","75.3457","India","IN","IND","Punjab","","10524","1356478718"
"Pontchâteau","Pontchateau","47.4369","-2.0878","France","FR","FRA","Pays de la Loire","","10901","1250637765"
"Marly","Marly","49.0611","6.1497","France","FR","FRA","Grand Est","","10160","1250026815"
"Pallattūr","Pallattur","10.1461","78.8031","India","IN","IND","Tamil Nādu","","10536","1356048519"
"Vilyuysk","Vilyuysk","63.7500","121.6333","Russia","RU","RUS","Sakha (Yakutiya)","","11095","1643430483"
"Ujjini","Ujjini","14.9100","76.4000","India","IN","IND","Karnātaka","","10864","1356154319"
"Karīmpur","Karimpur","23.9667","88.6167","India","IN","IND","West Bengal","","9661","1356853498"
"Satellite Beach","Satellite Beach","28.1782","-80.6019","United States","US","USA","Florida","","11093","1840015961"
"Roscoe","Roscoe","42.4256","-89.0084","United States","US","USA","Illinois","","11092","1840011122"
"Shahmīrpet","Shahmirpet","17.5947","78.5749","India","IN","IND","Andhra Pradesh","","10255","1356385213"
"Belén de los Andaquíes","Belen de los Andaquies","1.4161","-75.8725","Colombia","CO","COL","Caquetá","minor","11081","1170848387"
"São José do Jacuípe","Sao Jose do Jacuipe","-11.4119","-39.8669","Brazil","BR","BRA","Bahia","","11061","1076174569"
"Mahālgaon","Mahalgaon","26.0466","87.5774","India","IN","IND","Bihār","","9357","1356636432"
"Columbia","Columbia","38.4581","-90.2156","United States","US","USA","Illinois","","11090","1840007502"
"Grand Gosier","Grand Gosier","18.1833","-71.9167","Haiti","HT","HTI","Sud-Est","","10852","1332068080"
"Narimanov","Narimanov","46.6833","47.8500","Russia","RU","RUS","Astrakhanskaya Oblast’","","11079","1643669598"
"Motta di Livenza","Motta di Livenza","45.7797","12.6086","Italy","IT","ITA","Veneto","","10801","1380977215"
"Ban Yang Hom","Ban Yang Hom","19.9222","100.3056","Thailand","TH","THA","Chiang Rai","","10939","1764266179"
"Milicz","Milicz","51.5333","17.2833","Poland","PL","POL","Dolnośląskie","minor","11025","1616770210"
"Khokri Kalān","Khokri Kalan","30.8456","75.3307","India","IN","IND","Punjab","","10600","1356060446"
"Greasley","Greasley","53.0200","-1.2700","United Kingdom","GB","GBR","Nottinghamshire","","11014","1826187766"
"São Ludgero","Sao Ludgero","-28.3258","-49.1767","Brazil","BR","BRA","Santa Catarina","","10993","1076888783"
"Szydłowiec","Szydlowiec","51.2333","20.8500","Poland","PL","POL","Mazowieckie","minor","10959","1616837510"
"Āwash","Awash","8.9944","40.1667","Ethiopia","ET","ETH","Āfar","","11053","1231768288"
"Jandaíra","Jandaira","-11.5639","-37.7839","Brazil","BR","BRA","Bahia","","11063","1076091666"
"Ānavatti","Anavatti","14.5645","75.1523","India","IN","IND","Karnātaka","","10781","1356071395"
"Andacollo","Andacollo","-30.2303","-71.0858","Chile","CL","CHL","Coquimbo","","11044","1152597504"
"Marreddipalli","Marreddipalli","17.7989","78.8050","India","IN","IND","Andhra Pradesh","","10787","1356691135"
"Mahād","Mahad","36.6472","43.4117","Iraq","IQ","IRQ","Nīnawá","","11000","1368572891"
"Altamira do Maranhão","Altamira do Maranhao","-4.1650","-45.4700","Brazil","BR","BRA","Maranhão","","11063","1076746584"
"Leicester","Leicester","42.2400","-71.9120","United States","US","USA","Massachusetts","","11077","1840053693"
"Side","Side","36.7667","31.3889","Turkey","TR","TUR","Antalya","","11000","1792879395"
"Avelino Lopes","Avelino Lopes","-10.1369","-43.9489","Brazil","BR","BRA","Piauí","","11067","1076060321"
"Fotsialanana","Fotsialanana","-16.9333","49.4833","Madagascar","MG","MDG","Toamasina","","11000","1450034980"
"Burgstädt","Burgstadt","50.9167","12.8167","Germany","DE","DEU","Saxony","","10672","1276198411"
"Mondeville","Mondeville","49.1739","-0.3211","France","FR","FRA","Normandie","","9973","1250124889"
"Nova Ubiratã","Nova Ubirata","-12.9908","-55.2550","Brazil","BR","BRA","Mato Grosso","","11074","1076543428"
"Brotas de Macaúbas","Brotas de Macaubas","-11.9989","-42.6258","Brazil","BR","BRA","Bahia","","11070","1076204887"
"Olbernhau","Olbernhau","50.6667","13.3333","Germany","DE","DEU","Saxony","","10991","1276224877"
"Pāraippatti","Paraippatti","10.3144","77.8601","India","IN","IND","Tamil Nādu","","10379","1356252319"
"San Carlos Centro","San Carlos Centro","-31.7333","-61.1000","Argentina","AR","ARG","Santa Fe","","11055","1032029022"
"Hulshout","Hulshout","51.0833","4.7833","Belgium","BE","BEL","Flanders","","10471","1056358506"
"Hoek van Holland","Hoek van Holland","51.9811","4.1286","Netherlands","NL","NLD","Zuid-Holland","","10340","1528209321"
"Truşeni","Truseni","47.0667","28.6833","Moldova","MD","MDA","Chişinău","","10380","1498415834"
"Struer","Struer","56.4856","8.5897","Denmark","DK","DNK","Midtjylland","minor","10375","1208269003"
"Sampona","Sampona","-25.1500","46.3167","Madagascar","MG","MDG","Toliara","","11000","1450436674"
"Lower Saucon","Lower Saucon","40.5881","-75.3188","United States","US","USA","Pennsylvania","","11071","1840142185"
"Canyon Lake","Canyon Lake","33.6885","-117.2621","United States","US","USA","California","","11071","1840019301"
"Amporoforo","Amporoforo","-22.4667","47.8000","Madagascar","MG","MDG","Fianarantsoa","","11000","1450014103"
"Cachoeira de Minas","Cachoeira de Minas","-22.3550","-45.7789","Brazil","BR","BRA","Minas Gerais","","11034","1076635151"
"Maryville","Maryville","40.3428","-94.8702","United States","US","USA","Missouri","","11070","1840008349"
"Dayr ‘Aţīyah","Dayr \`Atiyah","34.0833","36.7667","Syria","SY","SYR","Rīf Dimashq","minor","10984","1760656122"
"Dent","Dent","39.1915","-84.6601","United States","US","USA","Ohio","","11069","1840005880"
"Andranomeva","Andranomeva","-15.8000","47.7667","Madagascar","MG","MDG","Mahajanga","","11000","1450392051"
"Villa La Angostura","Villa La Angostura","-40.7625","-71.6463","Argentina","AR","ARG","Neuquén","minor","11063","1032303492"
"Baltāra","Baltara","25.5116","86.7232","India","IN","IND","Bihār","","9876","1356481249"
"Haspra","Haspra","44.4361","34.1106","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","10310","1804507625"
"Sturgis","Sturgis","41.7991","-85.4184","United States","US","USA","Michigan","","11067","1840003254"
"Borna","Borna","25.4373","86.6172","India","IN","IND","Bihār","","9321","1356258382"
"Manambolosy","Manambolosy","-16.0333","49.6667","Madagascar","MG","MDG","Toamasina","","11000","1450154800"
"Brunete","Brunete","40.4000","-3.9936","Spain","ES","ESP","Madrid","","10845","1724447167"
"Donzdorf","Donzdorf","48.6833","9.8167","Germany","DE","DEU","Baden-Württemberg","","10795","1276029380"
"Costeşti","Costesti","46.8678","28.7689","Moldova","MD","MDA","Ialoveni","","10907","1498958182"
"Onna","Onna","26.4833","127.8500","Japan","JP","JPN","Okinawa","","10852","1392551931"
"Beiuş","Beius","46.6500","22.3500","Romania","RO","ROU","Bihor","","10667","1642734530"
"Cidade Gaúcha","Cidade Gaucha","-23.3800","-52.9450","Brazil","BR","BRA","Paraná","","11062","1076061148"
"Bevata","Bevata","-23.2833","47.2833","Madagascar","MG","MDG","Fianarantsoa","","11000","1450498029"
"Gemona del Friuli","Gemona del Friuli","46.2833","13.1333","Italy","IT","ITA","Friuli Venezia Giulia","","10869","1380484180"
"Stropkov","Stropkov","49.2050","21.6514","Slovakia","SK","SVK","Prešov","minor","10654","1703240636"
"Antakotako","Antakotako","-15.3167","49.8000","Madagascar","MG","MDG","Toamasina","","11000","1450258709"
"Chornomorske","Chornomorske","45.5019","32.7025","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","11039","1804755639"
"Magny-le-Hongre","Magny-le-Hongre","48.8631","2.8136","France","FR","FRA","Île-de-France","","9107","1250354679"
"Amāyan","Amayan","26.3205","78.7581","India","IN","IND","Madhya Pradesh","","10639","1356274623"
"Obuse","Obuse","36.6975","138.3121","Japan","JP","JPN","Nagano","","10510","1392708424"
"Zumaia","Zumaia","43.2972","-2.2569","Spain","ES","ESP","Basque Country","","10153","1724000458"
"Waihee-Waiehu","Waihee-Waiehu","20.9188","-156.5063","United States","US","USA","Hawaii","","11059","1840037549"
"Elon","Elon","36.1016","-79.5086","United States","US","USA","North Carolina","","11059","1840016143"
"Mahazony","Mahazony","-21.9833","47.0167","Madagascar","MG","MDG","Fianarantsoa","","11000","1450926043"
"Ambinanindovoka","Ambinanindovoka","-21.9167","47.1000","Madagascar","MG","MDG","Fianarantsoa","","11000","1450885987"
"Antsambahara","Antsambahara","-14.5333","49.9500","Madagascar","MG","MDG","Antsiranana","","11000","1450544862"
"Sosnovka","Sosnovka","56.2500","51.3000","Russia","RU","RUS","Kirovskaya Oblast’","","11027","1643274224"
"Santana do Mundaú","Santana do Mundau","-9.1678","-36.2219","Brazil","BR","BRA","Alagoas","","11009","1076092281"
"Urdorf","Urdorf","47.3867","8.4278","Switzerland","CH","CHE","Zürich","","9768","1756382154"
"Kishunpur","Kishunpur","25.7947","86.8237","India","IN","IND","Bihār","","9963","1356145353"
"La Esperanza","La Esperanza","7.6392","-73.3358","Colombia","CO","COL","Norte de Santander","minor","11040","1170817366"
"Pūliguntā","Puligunta","12.4395","78.3625","India","IN","IND","Tamil Nādu","","10563","1356483360"
"Pingtouchuanxiang","Pingtouchuanxiang","35.8763","105.3241","China","CN","CHN","Gansu","","10978","1156361711"
"Grand Rapids","Grand Rapids","47.2380","-93.5327","United States","US","USA","Minnesota","","11056","1840007714"
"Tân Sơn","Tan Son","21.2600","106.2681","Vietnam","VN","VNM","Bắc Giang","","9966","1704224355"
"Young","Young","-34.3000","148.3000","Australia","AU","AUS","New South Wales","","10295","1036073044"
"Dek’emhāre","Dek'emhare","15.0667","39.0333","Eritrea","ER","ERI","Debub","","10959","1232763839"
"Kalafotsy","Kalafotsy","-22.2833","47.3167","Madagascar","MG","MDG","Fianarantsoa","","11000","1450967361"
"Acari","Acari","-6.4358","-36.6389","Brazil","BR","BRA","Rio Grande do Norte","","11035","1076782658"
"Torrejón de la Calzada","Torrejon de la Calzada","40.2000","-3.8000","Spain","ES","ESP","Madrid","","9947","1724980898"
"Malente","Malente","54.1667","10.5500","Germany","DE","DEU","Schleswig-Holstein","","10895","1276687583"
"Lavaur","Lavaur","43.6989","1.8189","France","FR","FRA","Occitanie","","10879","1250001442"
"Travilah","Travilah","39.0570","-77.2470","United States","US","USA","Maryland","","11052","1840005846"
"Coshocton","Coshocton","40.2618","-81.8480","United States","US","USA","Ohio","","11051","1840003692"
"Acarlar","Acarlar","37.8333","27.7667","Turkey","TR","TUR","Aydın","","10866","1792301446"
"Ponte Serrada","Ponte Serrada","-26.8719","-52.0158","Brazil","BR","BRA","Santa Catarina","","11031","1076102592"
"Antsoha","Antsoha","-15.7667","48.8500","Madagascar","MG","MDG","Mahajanga","","11000","1450852008"
"Marovantaza","Marovantaza","-15.3833","47.6667","Madagascar","MG","MDG","Mahajanga","","11000","1450960583"
"Fairview","Fairview","37.6758","-122.0473","United States","US","USA","California","","11050","1840028375"
"Rogoźno","Rogozno","52.7492","16.9997","Poland","PL","POL","Wielkopolskie","","10959","1616112506"
"Shalushka","Shalushka","43.5261","43.5594","Russia","RU","RUS","Kabardino-Balkariya","","11004","1643016324"
"Brumunddal","Brumunddal","60.8836","10.9449","Norway","NO","NOR","Innlandet","minor","11019","1578939363"
"Paranatama","Paranatama","-8.9208","-36.6581","Brazil","BR","BRA","Pernambuco","","11001","1076776179"
"Ixtacomitán","Ixtacomitan","17.4167","-93.1000","Mexico","MX","MEX","Chiapas","minor","10961","1484290089"
"Santa Teresinha (2)","Santa Teresinha (2)","-7.3778","-37.4800","Brazil","BR","BRA","Pernambuco","","10991","1076032164"
"Schkopau","Schkopau","51.3833","11.9667","Germany","DE","DEU","Saxony-Anhalt","","10937","1276553925"
"Pasrāha","Pasraha","25.3973","86.7034","India","IN","IND","Bihār","","9300","1356184280"
"Forest","Forest","37.3728","-79.2831","United States","US","USA","Virginia","","11045","1840006398"
"Schleusingen","Schleusingen","50.5167","10.7500","Germany","DE","DEU","Thuringia","","10960","1276153970"
"Saraiya","Saraiya","24.8012","84.0816","India","IN","IND","Bihār","","9060","1356667113"
"Stephanskirchen","Stephanskirchen","47.8500","12.1833","Germany","DE","DEU","Bavaria","","10643","1276424384"
"Borovsk","Borovsk","55.2000","36.5000","Russia","RU","RUS","Kaluzhskaya Oblast’","","10966","1643432341"
"La Palma","La Palma","5.3606","-74.3897","Colombia","CO","COL","Cundinamarca","minor","11012","1170347214"
"Igarapé Grande","Igarape Grande","-4.5850","-44.8528","Brazil","BR","BRA","Maranhão","","11041","1076258716"
"Pai Bigha","Pai Bigha","25.0511","84.9226","India","IN","IND","Bihār","","9695","1356830413"
"Methil","Methil","56.1844","-3.0223","United Kingdom","GB","GBR","Fife","","10800","1826468854"
"Sansa","Sansa","25.0291","84.4597","India","IN","IND","Bihār","","9148","1356447572"
"Morafeno","Morafeno","-19.0833","47.1000","Madagascar","MG","MDG","Antananarivo","","11000","1450482362"
"Sabalito","Sabalito","8.8828","-82.8314","Costa Rica","CR","CRI","Puntarenas","","10984","1188952077"
"Hakone","Hakone","35.1894","139.0247","Japan","JP","JPN","Kanagawa","","10925","1392115612"
"Op","Op","25.2092","85.3019","India","IN","IND","Bihār","","9160","1356084957"
"Céu Azul","Ceu Azul","-25.1469","-53.8489","Brazil","BR","BRA","Paraná","","11032","1076175311"
"Botuporã","Botupora","-13.3819","-42.5228","Brazil","BR","BRA","Bahia","","11021","1076965505"
"Jhitkahiyā","Jhitkahiya","26.7860","84.8858","India","IN","IND","Bihār","","9782","1356940760"
"Vera Cruz","Vera Cruz","-22.2200","-49.8189","Brazil","BR","BRA","São Paulo","","10997","1076644611"
"Gernsheim","Gernsheim","49.7500","8.4833","Germany","DE","DEU","Hesse","","10772","1276371631"
"Gleisdorf","Gleisdorf","47.1039","15.7083","Austria","AT","AUT","Steiermark","","10763","1040059456"
"Ambodimangavolo","Ambodimangavolo","-17.5167","48.9500","Madagascar","MG","MDG","Toamasina","","11000","1450962406"
"Jhundpura","Jhundpura","26.3473","77.5010","India","IN","IND","Madhya Pradesh","","10684","1356046936"
"Khāspur","Khaspur","25.6466","84.9936","India","IN","IND","Bihār","","9535","1356846338"
"Martinengo","Martinengo","45.5704","9.7674","Italy","IT","ITA","Lombardy","","10560","1380797479"
"Maroamalona","Maroamalona","-15.3000","48.6667","Madagascar","MG","MDG","Mahajanga","","11000","1450211662"
"Zasechnoye","Zasechnoye","53.1142","45.0601","Russia","RU","RUS","Penzenskaya Oblast’","","10906","1643364580"
"Bowral","Bowral","-34.4792","150.4181","Australia","AU","AUS","New South Wales","","10335","1036253752"
"Waldkirchen","Waldkirchen","48.7305","13.6011","Germany","DE","DEU","Bavaria","","10901","1276702006"
"Koronowo","Koronowo","53.3167","17.9333","Poland","PL","POL","Kujawsko-Pomorskie","","10974","1616300116"
"Al Atārib","Al Atarib","36.1389","36.8300","Syria","SY","SYR","Ḩalab","minor","10657","1760645780"
"Mossley","Mossley","53.5147","-2.0387","United Kingdom","GB","GBR","Tameside","","10921","1826750637"
"Pélissanne","Pelissanne","43.6314","5.1506","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10487","1250072197"
"Franklin Lakes","Franklin Lakes","41.0086","-74.2083","United States","US","USA","New Jersey","","11034","1840003553"
"Pragadavaram","Pragadavaram","17.0167","81.0167","India","IN","IND","Andhra Pradesh","","10755","1356533408"
"Lyndon","Lyndon","38.2645","-85.5891","United States","US","USA","Kentucky","","11033","1840015191"
"Ranomafana","Ranomafana","-24.5667","46.9667","Madagascar","MG","MDG","Toliara","","11000","1450249574"
"Ambodiampana","Ambodiampana","-16.8167","49.5667","Madagascar","MG","MDG","Toamasina","","11000","1450144383"
"Orlu","Orlu","5.7837","7.0333","Nigeria","NG","NGA","Imo","minor","9351","1566062510"
"Hulgūr","Hulgur","15.0833","75.2833","India","IN","IND","Karnātaka","","10681","1356389739"
"Analamitsivalana","Analamitsivalana","-20.3167","44.6833","Madagascar","MG","MDG","Toliara","","11000","1450477836"
"Gignac-la-Nerthe","Gignac-la-Nerthe","43.3932","5.2356","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9887","1250840711"
"Vellipālaiyam","Vellipalaiyam","11.3314","76.9813","India","IN","IND","Tamil Nādu","","10583","1356357021"
"Marotandrano","Marotandrano","-16.1667","48.8333","Madagascar","MG","MDG","Mahajanga","","11000","1450460115"
"Taiyūr","Taiyur","12.7833","80.1833","India","IN","IND","Tamil Nādu","","10043","1356294017"
"Mānrar","Manrar","25.8895","85.0643","India","IN","IND","Bihār","","9666","1356085194"
"Ambohidranandriana","Ambohidranandriana","-19.8833","47.1500","Madagascar","MG","MDG","Antananarivo","","11000","1450724401"
"Fairview","Fairview","42.0261","-80.2361","United States","US","USA","Pennsylvania","","11029","1840144222"
"Nowra","Nowra","-34.8808","150.6075","Australia","AU","AUS","New South Wales","","9193","1036984536"
"Abbigeri","Abbigeri","15.5862","75.7502","India","IN","IND","Karnātaka","","10801","1356709551"
"Panaon","Panaon","8.3667","123.8333","Philippines","PH","PHL","Misamis Occidental","","10797","1608096447"
"The Hills","The Hills","40.6561","-74.6215","United States","US","USA","New Jersey","","11027","1840151839"
"Tripurāntakam","Tripurantakam","16.0007","79.4563","India","IN","IND","Andhra Pradesh","","10392","1356024353"
"Antsahabe","Antsahabe","-14.8000","48.3833","Madagascar","MG","MDG","Mahajanga","","11000","1450288533"
"Marolinta","Marolinta","-25.1000","44.6167","Madagascar","MG","MDG","Toliara","","11000","1450336468"
"Androndrona Anava","Androndrona Anava","-15.7500","49.2000","Madagascar","MG","MDG","Toamasina","","11000","1450535448"
"Itzer","Itzer","32.8833","-5.0500","Morocco","MA","MAR","Drâa-Tafilalet","","10613","1504216124"
"Locate di Triulzi","Locate di Triulzi","45.3500","9.2167","Italy","IT","ITA","Lombardy","","10216","1380874886"
"Zhydachiv","Zhydachiv","49.3833","24.1333","Ukraine","UA","UKR","Lvivska Oblast","minor","10962","1804137545"
"Summerfield","Summerfield","36.1973","-79.8997","United States","US","USA","North Carolina","","11026","1840016139"
"Wyomissing","Wyomissing","40.3317","-75.9703","United States","US","USA","Pennsylvania","","11026","1840001196"
"Maroambihy","Maroambihy","-14.4667","49.7667","Madagascar","MG","MDG","Antsiranana","","11000","1450240187"
"Völkermarkt","Volkermarkt","46.6622","14.6344","Austria","AT","AUT","Kärnten","minor","10946","1040264683"
"Belvādi","Belvadi","15.7900","74.7500","India","IN","IND","Karnātaka","","10640","1356468260"
"Marinette","Marinette","45.0871","-87.6336","United States","US","USA","Wisconsin","","11025","1840003930"
"Tsararano","Tsararano","-16.1667","46.6667","Madagascar","MG","MDG","Mahajanga","","11000","1450799664"
"Rüthen","Ruthen","51.4933","8.4833","Germany","DE","DEU","North Rhine-Westphalia","","10957","1276776072"
"Montechiarugolo","Montechiarugolo","44.6934","10.4224","Italy","IT","ITA","Emilia-Romagna","","10976","1380440367"
"Qal‘at an Nakhl","Qal\`at an Nakhl","29.9000","33.7500","Egypt","EG","EGY","Shamāl Sīnā’","","11023","1818829303"
"Springdale","Springdale","39.2909","-84.4760","United States","US","USA","Ohio","","11024","1840001615"
"Tsinjomitondraka","Tsinjomitondraka","-15.6667","47.1333","Madagascar","MG","MDG","Mahajanga","","11000","1450831039"
"Cacaopera","Cacaopera","13.7667","-88.0833","El Salvador","SV","SLV","Morazán","","10943","1222915809"
"Jafra","Jafra","31.5145","-8.7555","Morocco","MA","MAR","Marrakech-Safi","","10896","1504134756"
"Grezzana","Grezzana","45.5167","11.0167","Italy","IT","ITA","Veneto","","10805","1380574933"
"Bernex","Bernex","46.1784","6.0684","Switzerland","CH","CHE","Genève","","10233","1756532909"
"Ambodimanary","Ambodimanary","-15.2167","48.0833","Madagascar","MG","MDG","Mahajanga","","11000","1450651140"
"Mulungu","Mulungu","-4.3058","-38.9958","Brazil","BR","BRA","Ceará","","10941","1076343616"
"Antsakanalabe","Antsakanalabe","-15.4167","48.2833","Madagascar","MG","MDG","Mahajanga","","11000","1450726016"
"Ugrinovci","Ugrinovci","44.8783","20.1869","Serbia","RS","SRB","Beograd","","10807","1688470425"
"Ruisui","Ruisui","23.4333","121.5000","Taiwan","TW","TWN","Hualien","","10941","1158003251"
"Racale","Racale","39.9667","18.1000","Italy","IT","ITA","Puglia","","11011","1380031078"
"Warman","Warman","52.3219","-106.5842","Canada","CA","CAN","Saskatchewan","","11020","1124688931"
"Munagapāka","Munagapaka","17.6390","82.9920","India","IN","IND","Andhra Pradesh","","10028","1356402432"
"Miandrarivo","Miandrarivo","-18.9167","45.9333","Madagascar","MG","MDG","Antananarivo","","11000","1450648621"
"Xudat","Xudat","41.6281","48.6828","Azerbaijan","AZ","AZE","Xaçmaz","","10894","1031218803"
"Karabash","Karabash","55.4833","60.2167","Russia","RU","RUS","Chelyabinskaya Oblast’","","10999","1643422680"
"Antanandava","Antanandava","-17.4833","48.6833","Madagascar","MG","MDG","Toamasina","","11013","1450418513"
"Drăgăneşti-Olt","Draganesti-Olt","44.1697","24.5300","Romania","RO","ROU","Olt","","10894","1642857216"
"Kiskunmajsa","Kiskunmajsa","46.4923","19.7368","Hungary","HU","HUN","Bács-Kiskun","minor","10968","1348210119"
"Soham","Soham","52.3338","0.3361","United Kingdom","GB","GBR","Cambridgeshire","","10860","1826606528"
"Celano","Celano","42.0864","13.5575","Italy","IT","ITA","Abruzzo","","10885","1380585394"
"Lyakhavichy","Lyakhavichy","53.0333","26.2667","Belarus","BY","BLR","Brestskaya Voblasts’","minor","10997","1112145874"
"Ankavandra","Ankavandra","-18.7722","45.2931","Madagascar","MG","MDG","Toliara","","11000","1450061634"
"Punacha","Punacha","12.9000","75.0300","India","IN","IND","Karnātaka","","10398","1356600392"
"Danau Kändimarg","Danau Kandimarg","33.5646","74.9754","India","IN","IND","Jammu and Kashmīr","","9402","1356920069"
"Puente de Piedra","Puente de Piedra","10.0298","-84.3332","Costa Rica","CR","CRI","Alajuela","","10556","1188813185"
"Adda-Douéni","Adda-Doueni","-12.3000","44.5000","Comoros","KM","COM","Anjouan","","10858","1174212129"
"Knowle","Knowle","52.3881","-1.7318","United Kingdom","GB","GBR","Solihull","","10678","1826087458"
"Belāo","Belao","25.1476","85.7377","India","IN","IND","Bihār","","10464","1356236821"
"Oak Ridge","Oak Ridge","41.0323","-74.4971","United States","US","USA","New Jersey","","11013","1840033370"
"Totowa","Totowa","40.9039","-74.2214","United States","US","USA","New Jersey","","11013","1840000839"
"Voloina","Voloina","-15.5667","49.6167","Madagascar","MG","MDG","Toamasina","","11000","1450914239"
"Akyaka","Akyaka","40.7444","43.6250","Turkey","TR","TUR","Kars","minor","10985","1792979414"
"Memphis","Memphis","27.5435","-82.5608","United States","US","USA","Florida","","11012","1840014172"
"Montecchio Emilia","Montecchio Emilia","44.6986","10.4486","Italy","IT","ITA","Emilia-Romagna","","10578","1380143176"
"Küçük Dalyan","Kucuk Dalyan","36.2167","36.1667","Turkey","TR","TUR","Hatay","","10354","1792948340"
"Kotharpettai","Kotharpettai","12.6780","78.6243","India","IN","IND","Tamil Nādu","","10404","1356016563"
"San Felice sul Panaro","San Felice sul Panaro","44.8393","11.1413","Italy","IT","ITA","Emilia-Romagna","","10802","1380210816"
"Bananal","Bananal","-22.6839","-44.3228","Brazil","BR","BRA","São Paulo","","10993","1076455705"
"Kalanivāsal","Kalanivasal","10.0700","78.7800","India","IN","IND","Tamil Nādu","","10451","1356207626"
"Westview","Westview","25.8825","-80.2415","United States","US","USA","Florida","","11010","1840029098"
"Tranomaro","Tranomaro","-24.6000","46.4667","Madagascar","MG","MDG","Toliara","","11000","1450585965"
"Altensteig","Altensteig","48.5864","8.6047","Germany","DE","DEU","Baden-Württemberg","","10806","1276878570"
"Angalakudūru Malepalle","Angalakuduru Malepalle","16.2392","80.6117","India","IN","IND","Andhra Pradesh","","9920","1356531518"
"Bithauli","Bithauli","26.0219","86.0793","India","IN","IND","Bihār","","9667","1356539912"
"Soaserana","Soaserana","-21.1167","44.2167","Madagascar","MG","MDG","Toliara","","11000","1450093331"
"Verkhniy Tagil","Verkhniy Tagil","57.3833","59.9500","Russia","RU","RUS","Sverdlovskaya Oblast’","","10962","1643392209"
"Rodeio","Rodeio","-26.9228","-49.3658","Brazil","BR","BRA","Santa Catarina","","10922","1076298618"
"Sângeorz-Băi","Sangeorz-Bai","47.3700","24.6800","Romania","RO","ROU","Bistriţa-Năsăud","","10931","1642207424"
"‘Utaybah","\`Utaybah","33.4861","36.6011","Syria","SY","SYR","Rīf Dimashq","","10548","1760277356"
"Wiener Neudorf","Wiener Neudorf","48.0856","16.3131","Austria","AT","AUT","Niederösterreich","","9433","1040799859"
"Texcatepec","Texcatepec","20.5833","-98.3667","Mexico","MX","MEX","Veracruz","minor","10934","1484575888"
"Ma’ai","Ma'ai","34.5937","102.4814","China","CN","CHN","Gansu","","10988","1156075634"
"Majhariyā","Majhariya","26.8696","84.8771","India","IN","IND","Bihār","","9275","1356069313"
"Harīpur","Haripur","26.2580","87.2420","India","IN","IND","Bihār","","9610","1356851777"
"Soyaló","Soyalo","16.9333","-92.9833","Mexico","MX","MEX","Chiapas","minor","10890","1484806087"
"Hemāvati","Hemavati","14.0232","76.9950","India","IN","IND","Andhra Pradesh","","10670","1356543424"
"Raceland","Raceland","29.7282","-90.6362","United States","US","USA","Louisiana","","11002","1840014027"
"La Tuque","La Tuque","48.0652","-74.0528","Canada","CA","CAN","Quebec","","11001.00","1124000430"
"Norwich","Norwich","42.9833","-80.6000","Canada","CA","CAN","Ontario","","11001","1124219807"
"Vadakādu","Vadakadu","10.3418","79.0609","India","IN","IND","Tamil Nādu","","10491","1356231267"
"Kondakomarla","Kondakomarla","14.0678","77.9561","India","IN","IND","Andhra Pradesh","","10784","1356709178"
"Bocaiúva do Sul","Bocaiuva do Sul","-25.2058","-49.1150","Brazil","BR","BRA","Paraná","","10987","1076459266"
"Terryville","Terryville","40.9093","-73.0486","United States","US","USA","New York","","10999","1840005105"
"Highland City","Highland City","27.9633","-81.8781","United States","US","USA","Florida","","10999","1840014122"
"Guéoul","Gueoul","15.4833","-16.3500","Senegal","SN","SEN","Louga","","10918","1686714378"
"Shagonar","Shagonar","51.5500","92.7667","Russia","RU","RUS","Tyva","","10995","1643785605"
"Chinnamandem","Chinnamandem","13.9419","78.6814","India","IN","IND","Andhra Pradesh","","10623","1356327094"
"Hosahalli","Hosahalli","15.3140","76.3151","India","IN","IND","Karnātaka","","10668","1356059145"
"Chenango","Chenango","42.1954","-75.8989","United States","US","USA","New York","","10997","1840087298"
"Khāpdeh","Khapdeh","26.2706","87.1993","India","IN","IND","Bihār","","9929","1356898004"
"Deokali","Deokali","25.9068","86.1111","India","IN","IND","Bihār","","9269","1356743912"
"Canandaigua","Canandaigua","42.8608","-77.3182","United States","US","USA","New York","","10994","1840000392"
"Goulds","Goulds","25.5614","-80.3880","United States","US","USA","Florida","","10994","1840014239"
"Phibun Mangsahan","Phibun Mangsahan","15.2482","105.2296","Thailand","TH","THA","Ubon Ratchathani","minor","10842","1764591980"
"Nariār","Nariar","25.8875","86.5632","India","IN","IND","Bihār","","9940","1356524403"
"Meaford","Meaford","44.5800","-80.7300","Canada","CA","CAN","Ontario","","10991","1124445257"
"Sankhavaram","Sankhavaram","15.0497","78.9836","India","IN","IND","Andhra Pradesh","","10924","1356014241"
"Ed Dâmoûr","Ed Damour","33.7333","35.4500","Lebanon","LB","LBN","Mont-Liban","","10000","1422987773"
"Wanderlândia","Wanderlandia","-6.8489","-47.9628","Brazil","BR","BRA","Tocantins","","10981","1076857031"
"Siswār","Siswar","26.4292","86.4586","India","IN","IND","Bihār","","10132","1356004530"
"Gülbaar","Gulbaar","40.4800","72.6500","Kyrgyzstan","KG","KGZ","Osh","","10718","1417638911"
"Bommārbettu","Bommarbettu","13.3390","74.8784","India","IN","IND","Karnātaka","","10365","1356233667"
"Khān Bebīn","Khan Bebin","37.0372","55.0503","Iran","IR","IRN","Golestān","","10878","1364836717"
"Diedorf","Diedorf","48.3500","10.7667","Germany","DE","DEU","Bavaria","","10667","1276299283"
"Nallippālaiyam","Nallippalaiyam","11.2388","78.1495","India","IN","IND","Tamil Nādu","","10508","1356251491"
"Estanzuela","Estanzuela","14.9979","-89.5705","Guatemala","GT","GTM","Zacapa","minor","10872","1320854533"
"Enumulapalle","Enumulapalle","14.1446","77.8166","India","IN","IND","Andhra Pradesh","","10709","1356156404"
"Monte San Pietro","Monte San Pietro","44.4578","11.1990","Italy","IT","ITA","Emilia-Romagna","","10960","1380640178"
"San Juanito de Escobedo","San Juanito de Escobedo","20.8000","-104.0000","Mexico","MX","MEX","Jalisco","minor","9433","1484130165"
"Höllviken","Hollviken","55.4167","12.9500","Sweden","SE","SWE","Skåne","","10607","1752012234"
"Süßen","Sussen","48.6797","9.7575","Germany","DE","DEU","Baden-Württemberg","","10192","1276876267"
"Mutukūru","Mutukuru","16.3829","79.4664","India","IN","IND","Andhra Pradesh","","10765","1356425661"
"Appleton","Appleton","53.3508","-2.5441","United Kingdom","GB","GBR","Warrington","","10265","1826486906"
"General Salgado","General Salgado","-20.6478","-50.3608","Brazil","BR","BRA","São Paulo","","10961","1076323931"
"Marck","Marck","50.9481","1.9503","France","FR","FRA","Hauts-de-France","","10649","1250431956"
"Wronki","Wronki","52.7000","16.3833","Poland","PL","POL","Wielkopolskie","","10918","1616756713"
"Palmares do Sul","Palmares do Sul","-30.2578","-50.5100","Brazil","BR","BRA","Rio Grande do Sul","","10969","1076619064"
"Paraparaumu Beach","Paraparaumu Beach","-40.8938","174.9794","New Zealand","NZ","NZL","Wellington","","9087","1554306968"
"El Tarra","El Tarra","8.5756","-73.0944","Colombia","CO","COL","Norte de Santander","minor","10957","1170113123"
"Bonthe","Bonthe","7.5264","-12.5050","Sierra Leone","SL","SLE","Southern","","10206","1694831211"
"Atlit","Atlit","32.6872","34.9383","Israel","IL","ISR","Haifa","","10639","1376755634"
"Nörvenich","Norvenich","50.8000","6.6500","Germany","DE","DEU","North Rhine-Westphalia","","10816","1276001252"
"Kattipūdi","Kattipudi","17.2500","82.3333","India","IN","IND","Andhra Pradesh","","10680","1356069667"
"Los Corrales de Buelna","Los Corrales de Buelna","43.2617","-4.0653","Spain","ES","ESP","Cantabria","","10742","1724471933"
"Prachatice","Prachatice","49.0130","13.9975","Czechia","CZ","CZE","Jihočeský Kraj","","10706","1203326615"
"Broomall","Broomall","39.9688","-75.3540","United States","US","USA","Pennsylvania","","10978","1840005549"
"Haigerloch","Haigerloch","48.3647","8.8050","Germany","DE","DEU","Baden-Württemberg","","10836","1276522466"
"Kalyānpur","Kalyanpur","26.4802","84.1789","India","IN","IND","Bihār","","9802","1356695468"
"Lomazzo","Lomazzo","45.7000","9.0333","Italy","IT","ITA","Lombardy","","9929","1380152646"
"Keila","Keila","59.3086","24.4225","Estonia","EE","EST","Keila","minor","10078","1233230839"
"Hagondange","Hagondange","49.2542","6.1681","France","FR","FRA","Grand Est","","9287","1250298487"
"Adjala-Tosorontio","Adjala-Tosorontio","44.1333","-79.9333","Canada","CA","CAN","Ontario","","10975","1124000498"
"Rokytne","Rokytne","49.6897","30.4751","Ukraine","UA","UKR","Kyivska Oblast","minor","10938","1804730774"
"Phrai Bueng","Phrai Bueng","14.7490","104.3574","Thailand","TH","THA","Si Sa Ket","minor","10864","1764845950"
"Capitão de Campos","Capitao de Campos","-4.4569","-41.9439","Brazil","BR","BRA","Piauí","","10953","1076683345"
"Collegedale","Collegedale","35.0526","-85.0488","United States","US","USA","Tennessee","","10973","1840014570"
"Daulatpur","Daulatpur","25.3682","87.8983","India","IN","IND","West Bengal","","9565","1356152465"
"Tarrá","Tarra","8.0475","-73.0836","Colombia","CO","COL","Norte de Santander","","10957","1170074509"
"Cáchira","Cachira","7.7500","-73.1667","Colombia","CO","COL","Norte de Santander","minor","10970","1170614751"
"Medesano","Medesano","44.7500","10.1333","Italy","IT","ITA","Emilia-Romagna","","10850","1380708551"
"Minamiise","Minamiise","34.3521","136.7036","Japan","JP","JPN","Mie","","10926","1392003545"
"Granby","Granby","41.9694","-72.8346","United States","US","USA","Connecticut","","10971","1840004831"
"Burlington","Burlington","42.6744","-88.2721","United States","US","USA","Wisconsin","","10971","1840002491"
"Gardere","Gardere","30.3582","-91.1346","United States","US","USA","Louisiana","","10971","1840013938"
"Saint-Laurent-de-la-Salanque","Saint-Laurent-de-la-Salanque","42.7736","2.9908","France","FR","FRA","Occitanie","","10158","1250558696"
"Sidi Namane","Sidi Namane","36.7581","3.9839","Algeria","DZ","DZA","Boumerdes","","10688","1012820383"
"Newcastle","Newcastle","35.2401","-97.5995","United States","US","USA","Oklahoma","","10970","1840020453"
"West Caldwell","West Caldwell","40.8488","-74.2971","United States","US","USA","New Jersey","","10970","1840131595"
"Dighāwāni","Dighawani","22.2061","78.8135","India","IN","IND","Madhya Pradesh","","10464","1356113199"
"Bog’ot","Bog'ot","41.3500","60.8167","Uzbekistan","UZ","UZB","Xorazm","minor","10700","1860558228"
"Yeşilli","Yesilli","37.3406","40.8258","Turkey","TR","TUR","Mardin","minor","10846","1792246773"
"Aylesford","Aylesford","51.3033","0.4796","United Kingdom","GB","GBR","Kent","","10660","1826024683"
"Wooburn","Wooburn","51.5810","-0.6910","United Kingdom","GB","GBR","Buckinghamshire","","10792","1826286228"
"Saray","Saray","40.5322","49.7164","Azerbaijan","AZ","AZE","Abşeron","","10693","1031418404"
"Bela","Bela","26.4989","86.3242","India","IN","IND","Bihār","","10273","1356171488"
"Karaağaç","Karaagac","41.3000","27.9500","Turkey","TR","TUR","Tekirdağ","","10601","1792311968"
"Matsuda-sōryō","Matsuda-soryo","35.3482","139.1394","Japan","JP","JPN","Kanagawa","","10682","1392523691"
"Punitaqui","Punitaqui","-30.9000","-71.2667","Chile","CL","CHL","Coquimbo","","10956","1152111575"
"Perumpāndi","Perumpandi","10.9808","79.3839","India","IN","IND","Tamil Nādu","","9241","1356172303"
"La Palma del Condado","La Palma del Condado","37.3842","-6.5517","Spain","ES","ESP","Andalusia","","10787","1724270094"
"Semri","Semri","22.6833","78.0833","India","IN","IND","Madhya Pradesh","","10686","1356570452"
"Neuhof","Neuhof","50.4333","9.6167","Germany","DE","DEU","Hesse","","10843","1276724879"
"Desborough","Desborough","52.4398","-0.8172","United Kingdom","GB","GBR","Northamptonshire","","10697","1826690378"
"West Point","West Point","41.1220","-112.0995","United States","US","USA","Utah","","10963","1840021354"
"Minamisanriku","Minamisanriku","38.6806","141.4624","Japan","JP","JPN","Miyagi","","10896","1392003103"
"Chambly","Chambly","49.1667","2.2481","France","FR","FRA","Hauts-de-France","","10174","1250064541"
"New Albany","New Albany","40.0809","-82.7848","United States","US","USA","Ohio","","10962","1840012407"
"Quimilí","Quimili","-27.6333","-62.4167","Argentina","AR","ARG","Santiago del Estero","minor","10959","1032532618"
"La Puebla de Cazalla","La Puebla de Cazalla","37.2222","-5.3125","Spain","ES","ESP","Andalusia","","10903","1724676829"
"Attleborough","Attleborough","52.5183","1.0192","United Kingdom","GB","GBR","Norfolk","","10482","1826007863"
"Sirugudi","Sirugudi","10.2627","78.3088","India","IN","IND","Tamil Nādu","","10635","1356228387"
"Hurzuf","Hurzuf","44.5528","34.2875","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","9117","1804682977"
"Neman","Neman","55.0333","22.0333","Russia","RU","RUS","Kaliningradskaya Oblast’","minor","10931","1643209452"
"Salò","Salo","45.6000","10.5333","Italy","IT","ITA","Lombardy","","10603","1380884084"
"Tömük","Tomuk","36.6667","34.3833","Turkey","TR","TUR","Mersin","","10907","1792103247"
"Canelli","Canelli","44.7208","8.2928","Italy","IT","ITA","Piedmont","","10430","1380411144"
"Duas Barras","Duas Barras","-22.0508","-42.5219","Brazil","BR","BRA","Rio de Janeiro","","10930","1076491298"
"Cristuru Secuiesc","Cristuru Secuiesc","46.2917","25.0353","Romania","RO","ROU","Harghita","","10796","1642875734"
"Linganore","Linganore","39.4127","-77.3014","United States","US","USA","Maryland","","10959","1840026627"
"Shamsābād","Shamsabad","32.2983","48.4289","Iran","IR","IRN","Khūzestān","","10858","1364349532"
"Shahar Telpa","Shahar Telpa","25.1330","84.6533","India","IN","IND","Bihār","","10298","1356545471"
"Pont-Saint-Esprit","Pont-Saint-Esprit","44.2564","4.6483","France","FR","FRA","Occitanie","","10397","1250487388"
"Carmo da Mata","Carmo da Mata","-20.5578","-44.8708","Brazil","BR","BRA","Minas Gerais","","10927","1076608875"
"Erstein","Erstein","48.4219","7.6611","France","FR","FRA","Grand Est","","10661","1250439074"
"Canton","Canton","32.5975","-90.0317","United States","US","USA","Mississippi","","10955","1840014872"
"Bischofswerda","Bischofswerda","51.1275","14.1797","Germany","DE","DEU","Saxony","","10723","1276164300"
"Inácio Martins","Inacio Martins","-25.5708","-51.0789","Brazil","BR","BRA","Paraná","","10943","1076765627"
"Ertvelde","Ertvelde","51.1783","3.7447","Belgium","BE","BEL","Flanders","","10503","1056825602"
"Ketugrām","Ketugram","23.6957","88.0414","India","IN","IND","West Bengal","","9990","1356142528"
"Metsamor","Metsamor","40.1428","44.1164","Armenia","AM","ARM","Armavir","","9870","1051651063"
"Velappādi","Velappadi","12.6499","79.3151","India","IN","IND","Tamil Nādu","","10395","1356965281"
"Zwettl","Zwettl","48.6033","15.1689","Austria","AT","AUT","Niederösterreich","minor","10908","1040676174"
"Bad Iburg","Bad Iburg","52.1592","8.0472","Germany","DE","DEU","Lower Saxony","","10661","1276794541"
"Koppāka","Koppaka","16.7494","81.0311","India","IN","IND","Andhra Pradesh","","10560","1356362428"
"Boizenburg","Boizenburg","53.3667","10.7167","Germany","DE","DEU","Mecklenburg-Western Pomerania","","10724","1276001527"
"Gerzat","Gerzat","45.8258","3.1447","France","FR","FRA","Auvergne-Rhône-Alpes","","10315","1250589751"
"Wijnegem","Wijnegem","51.2333","4.5333","Belgium","BE","BEL","Flanders","","9711","1056561249"
"Jerônimo Monteiro","Jeronimo Monteiro","-20.7889","-41.3950","Brazil","BR","BRA","Espírito Santo","","10879","1076433510"
"Garmeh","Garmeh","36.9869","56.2894","Iran","IR","IRN","Khorāsān-e Shomālī","minor","10933","1364001152"
"Fox Lake","Fox Lake","42.4239","-88.1844","United States","US","USA","Illinois","","10945","1840011168"
"Mandrem","Mandrem","15.6581","73.7131","India","IN","IND","","","10589","1356405754"
"Cerro Corá","Cerro Cora","-6.0458","-36.3458","Brazil","BR","BRA","Rio Grande do Norte","","10916","1076575951"
"Bedford Heights","Bedford Heights","41.4041","-81.5053","United States","US","USA","Ohio","","10942","1840003397"
"Hamilton Township","Hamilton Township","44.0540","-78.2164","Canada","CA","CAN","Ontario","","10942.00","1124000994"
"Burgkirchen an der Alz","Burgkirchen an der Alz","48.1667","12.7167","Germany","DE","DEU","Bavaria","","10710","1276642803"
"Rājiāna","Rajiana","30.6923","75.0902","India","IN","IND","Punjab","","10569","1356257199"
"Patlūr","Patlur","11.6000","77.6000","India","IN","IND","Tamil Nādu","","10616","1356163243"
"Bassian","Bassian","30.6559","75.5389","India","IN","IND","Punjab","","10360","1356005138"
"Lagoa do Mato","Lagoa do Mato","-6.0469","-43.5258","Brazil","BR","BRA","Maranhão","","10934","1076574192"
"Kandry","Kandry","54.5667","54.1167","Russia","RU","RUS","Bashkortostan","","10885","1643092340"
"Kanur","Kanur","17.0318","75.7022","India","IN","IND","Karnātaka","","10615","1356124284"
"Seridó","Serido","-6.9339","-36.4019","Brazil","BR","BRA","Paraíba","","10900","1076297099"
"Villers-la-Ville","Villers-la-Ville","50.5833","4.5333","Belgium","BE","BEL","Wallonia","","10713","1056078634"
"Rāmgarha","Ramgarha","25.7983","84.9592","India","IN","IND","Bihār","","8939","1356607631"
"Guantingzhan","Guantingzhan","40.2492","115.5780","China","CN","CHN","Hebei","","10863","1156272527"
"Sanjiangkou","Sanjiangkou","24.7579","104.5901","China","CN","CHN","Yunnan","","10860","1156944219"
"Gohi Bishunpur","Gohi Bishunpur","25.9275","85.8626","India","IN","IND","Bihār","","9034","1356334298"
"Cadelbosco di Sopra","Cadelbosco di Sopra","44.7667","10.6000","Italy","IT","ITA","Emilia-Romagna","","10691","1380403982"
"Mae Wang","Mae Wang","18.6567","98.6816","Thailand","TH","THA","Chiang Mai","minor","10879","1764671792"
"Villa Nougues","Villa Nougues","-26.8578","-65.3756","Argentina","AR","ARG","Tucumán","","10785","1032459875"
"Barberino di Mugello","Barberino di Mugello","44.0014","11.2392","Italy","IT","ITA","Tuscany","","10924","1380222882"
"Grand Haven","Grand Haven","43.0553","-86.2201","United States","US","USA","Michigan","","10935","1840002995"
"Gangaur","Gangaur","26.5666","85.8821","India","IN","IND","Bihār","","9803","1356943915"
"Ilvesheim","Ilvesheim","49.4725","8.5675","Germany","DE","DEU","Baden-Württemberg","","9346","1276902316"
"Kombai","Kombai","10.6143","78.1212","India","IN","IND","Tamil Nādu","","10581","1356215587"
"Massa Lombarda","Massa Lombarda","44.4500","11.8167","Italy","IT","ITA","Emilia-Romagna","","10646","1380884282"
"Kaufering","Kaufering","48.0833","10.8833","Germany","DE","DEU","Bavaria","","10355","1276831409"
"Khajuri","Khajuri","26.0630","87.1341","India","IN","IND","Bihār","","9303","1356042241"
"Resende Costa","Resende Costa","-20.9219","-44.2378","Brazil","BR","BRA","Minas Gerais","","10913","1076408656"
"Karath","Karath","25.2010","84.3672","India","IN","IND","Bihār","","9489","1356114380"
"Feyzin","Feyzin","45.6728","4.8589","France","FR","FRA","Auvergne-Rhône-Alpes","","9902","1250488339"
"Star","Star","43.7026","-116.4914","United States","US","USA","Idaho","","10929","1840021279"
"Cacimbinhas","Cacimbinhas","-9.4000","-36.9900","Brazil","BR","BRA","Alagoas","","10889","1076681192"
"Bargas","Bargas","39.9400","-4.0194","Spain","ES","ESP","Castille-La Mancha","","10807","1724989174"
"Casaluce","Casaluce","41.0000","14.2000","Italy","IT","ITA","Campania","","9893","1380325825"
"Adohoun","Adohoun","6.6333","1.6667","Benin","BJ","BEN","Mono","","10622","1204360441"
"Alto Paraguai","Alto Paraguai","-14.5139","-56.4828","Brazil","BR","BRA","Mato Grosso","","10921","1076220577"
"Montignoso","Montignoso","44.0167","10.1667","Italy","IT","ITA","Tuscany","","10311","1380952094"
"Figuig","Figuig","32.1167","-1.2269","Morocco","MA","MAR","Oriental","","10872","1504487887"
"Nanjundāpuram","Nanjundapuram","11.0857","76.8726","India","IN","IND","Tamil Nādu","","9643","1356151928"
"Madhuban Bediban","Madhuban Bediban","26.5062","84.9951","India","IN","IND","Bihār","","9490","1356297417"
"Lincolnton","Lincolnton","35.4748","-81.2386","United States","US","USA","North Carolina","","10926","1840015427"
"Villers-Cotterêts","Villers-Cotterets","49.2531","3.0903","France","FR","FRA","Hauts-de-France","","10676","1250546723"
"Muscoy","Muscoy","34.1552","-117.3477","United States","US","USA","California","","10925","1840028097"
"Easttown","Easttown","40.0281","-75.4403","United States","US","USA","Pennsylvania","","10924","1840150924"
"Kowary","Kowary","50.7917","15.8333","Poland","PL","POL","Dolnośląskie","","10650","1616926416"
"Pau Brasil","Pau Brasil","-15.4639","-39.6508","Brazil","BR","BRA","Bahia","","10905","1076169153"
"Bueno Brandão","Bueno Brandao","-22.4408","-46.3508","Brazil","BR","BRA","Minas Gerais","","10892","1076032928"
"San Gaspar Ixchil","San Gaspar Ixchil","15.3833","-91.7167","Guatemala","GT","GTM","Huehuetenango","minor","10581","1320445115"
"Pānrepatti","Panrepatti","25.5537","83.9775","India","IN","IND","Bihār","","10026","1356617256"
"Chencha","Chencha","6.2500","37.5667","Ethiopia","ET","ETH","YeDebub Bihēroch Bihēreseboch na Hizboch","","10488","1231948389"
"Kisújszállás","Kisujszallas","47.2169","20.7669","Hungary","HU","HUN","Jász-Nagykun-Szolnok","","10870","1348563920"
"Bogalusa","Bogalusa","30.7812","-89.8633","United States","US","USA","Louisiana","","10921","1840013905"
"Delhi","Delhi","37.4306","-120.7759","United States","US","USA","California","","10921","1840018930"
"Gok","Gok","27.1065","88.2459","India","IN","IND","West Bengal","","9100","1356966986"
"Berkley","Berkley","39.8045","-105.0281","United States","US","USA","Colorado","","10920","1840028561"
"Cold Springs","Cold Springs","39.6927","-119.9775","United States","US","USA","Nevada","","10920","1840033824"
"Chupaca","Chupaca","-12.0620","-75.2872","Peru","PE","PER","Junín","","9877","1604005892"
"Gudlavalleru","Gudlavalleru","16.3500","81.0500","India","IN","IND","Andhra Pradesh","","10491","1356111288"
"Kamen’-Rybolov","Kamen'-Rybolov","44.7667","132.0167","Russia","RU","RUS","Primorskiy Kray","","10909","1643554622"
"Nyakosoba","Nyakosoba","-29.5047","27.8692","Lesotho","LS","LSO","Maseru","","10893","1426143192"
"Itarana","Itarana","-19.8739","-40.8750","Brazil","BR","BRA","Espírito Santo","","10881","1076953622"
"Felton","Felton","51.5100","-2.5740","United Kingdom","GB","GBR","North Somerset","","10607","1826498722"
"Cassá de la Selva","Cassa de la Selva","41.8893","2.8742","Spain","ES","ESP","Catalonia","","10680","1724100920"
"Bobrovytsia","Bobrovytsia","50.7424","31.3860","Ukraine","UA","UKR","Chernihivska Oblast","","10894","1804897269"
"Fortuna","Fortuna","38.1789","-1.1233","Spain","ES","ESP","Murcia","","10843","1724296769"
"Narmeta","Narmeta","17.8861","79.1611","India","IN","IND","Andhra Pradesh","","10692","1356061754"
"Malibu","Malibu","34.0370","-118.7839","United States","US","USA","California","","10915","1840028310"
"Bobrynets","Bobrynets","48.0578","32.1581","Ukraine","UA","UKR","Kirovohradska Oblast","minor","10898","1804282287"
"Langenzenn","Langenzenn","49.4944","10.7947","Germany","DE","DEU","Bavaria","","10684","1276092377"
"Shevington","Shevington","53.5720","-2.6900","United Kingdom","GB","GBR","Wigan","","9517","1826958487"
"Jaladurgam","Jaladurgam","15.2840","77.9001","India","IN","IND","Andhra Pradesh","","10748","1356094746"
"Eski Yakkabog‘","Eski Yakkabog\`","38.9314","66.8389","Uzbekistan","UZ","UZB","Qashqadaryo","","10800","1860248125"
"Jordbro","Jordbro","59.1500","18.1333","Sweden","SE","SWE","Stockholm","","10291","1752201774"
"Barano d’Ischia","Barano d'Ischia","40.7167","13.9167","Italy","IT","ITA","Campania","","10001","1380481625"
"Kurdi","Kurdi","16.0511","77.2114","India","IN","IND","Karnātaka","","10694","1356608991"
"Petrovka","Petrovka","42.8389","74.0194","Kyrgyzstan","KG","KGZ","Chüy","","10879","1417874854"
"Chatham","Chatham","40.7274","-74.4289","United States","US","USA","New Jersey","","10913","1840000948"
"Hozin","Hozin","6.5333","2.5500","Benin","BJ","BEN","Ouémé","","10076","1204420035"
"Dhanur Kalyānwādi","Dhanur Kalyanwadi","17.7700","77.1400","India","IN","IND","Karnātaka","","10133","1356053071"
"Bodippatti","Bodippatti","10.5642","77.2336","India","IN","IND","Tamil Nādu","","10574","1356170909"
"Le Crès","Le Cres","43.6472","3.9392","France","FR","FRA","Occitanie","","9316","1250254998"
"Büyükyoncalı","Buyukyoncali","41.3833","27.9333","Turkey","TR","TUR","Tekirdağ","","10072","1792001288"
"Khlung","Khlung","12.4525","102.2267","Thailand","TH","THA","Chanthaburi","minor","10835","1764654331"
"Riverdale","Riverdale","41.6441","-87.6366","United States","US","USA","Illinois","","10909","1840011286"
"Worth","Worth","41.6877","-87.7916","United States","US","USA","Illinois","","10909","1840011318"
"Yungay","Yungay","-37.1194","-72.0189","Chile","CL","CHL","Ñuble","","10884","1152990591"
"Paittūr","Paittur","11.5356","78.5749","India","IN","IND","Tamil Nādu","","10517","1356244960"
"Enez","Enez","40.7333","26.0667","Turkey","TR","TUR","Edirne","minor","10886","1792411883"
"Kivistö","Kivisto","60.3236","24.8453","Finland","FI","FIN","Uusimaa","","10665","1246650293"
"Crestwood","Crestwood","41.6454","-87.7397","United States","US","USA","Illinois","","10908","1840011267"
"Gajiginhālu","Gajiginhalu","15.6969","76.6647","India","IN","IND","Karnātaka","","10568","1356818124"
"Braine-le-Château","Braine-le-Chateau","50.6833","4.2667","Belgium","BE","BEL","Wallonia","","10447","1056237513"
"São Bento do Sapucaí","Sao Bento do Sapucai","-22.6889","-45.7308","Brazil","BR","BRA","São Paulo","","10864","1076414119"
"San Maurizio Canavese","San Maurizio Canavese","45.2171","7.6305","Italy","IT","ITA","Piedmont","","10312","1380000031"
"Madathapatti","Madathapatti","9.1321","77.4245","India","IN","IND","Tamil Nādu","","10538","1356204860"
"Riedlingen","Riedlingen","48.1553","9.4728","Germany","DE","DEU","Baden-Württemberg","","10741","1276567884"
"Borgloon","Borgloon","50.8022","5.3433","Belgium","BE","BEL","Flanders","","10697","1056883817"
"Nova Vodolaha","Nova Vodolaha","49.7197","35.8772","Ukraine","UA","UKR","Kharkivska Oblast","","10881","1804657164"
"Meßstetten","Messstetten","48.1806","8.9625","Germany","DE","DEU","Baden-Württemberg","","10766","1276150050"
"Waterloo","Waterloo","38.3403","-90.1538","United States","US","USA","Illinois","","10906","1840010803"
"Ratnahalli","Ratnahalli","12.1922","76.6653","India","IN","IND","Karnātaka","","9584","1356157793"
"Setubinha","Setubinha","-17.6000","-42.1589","Brazil","BR","BRA","Minas Gerais","","10885","1076403367"
"Hānsa","Hansa","26.0911","87.3089","India","IN","IND","Bihār","","9841","1356711540"
"Sagarejo","Sagarejo","41.7333","45.3333","Georgia","GE","GEO","K’akheti","minor","10871","1268647899"
"Sultānpur","Sultanpur","29.1600","79.0600","India","IN","IND","Uttarākhand","","9881","1356063593"
"Suhiya","Suhiya","25.6482","84.3916","India","IN","IND","Bihār","","9898","1356624070"
"Mount Sinai","Mount Sinai","40.9372","-73.0179","United States","US","USA","New York","","10904","1840005049"
"Mount Kisco","Mount Kisco","41.2016","-73.7281","United States","US","USA","New York","","10904","1840004936"
"Yacopí","Yacopi","5.4667","-74.3333","Colombia","CO","COL","Cundinamarca","minor","10887","1170241710"
"Tettuppatti","Tettuppatti","10.3979","77.8225","India","IN","IND","Tamil Nādu","","10260","1356247265"
"Kulriān","Kulrian","29.7994","75.6827","India","IN","IND","Punjab","","10544","1356058460"
"Ramacca","Ramacca","37.3833","14.7000","Italy","IT","ITA","Sicilia","","10866","1380742630"
"Kenzingen","Kenzingen","48.1917","7.7683","Germany","DE","DEU","Baden-Württemberg","","10614","1276334266"
"Davos","Davos","46.8000","9.8333","Switzerland","CH","CHE","Graubünden","minor","10862","1756286446"
"Dourdan","Dourdan","48.5289","2.0108","France","FR","FRA","Île-de-France","","10559","1250037704"
"Asahi","Asahi","36.9462","137.5599","Japan","JP","JPN","Toyama","","10852","1392003329"
"Governador Lindenberg","Governador Lindenberg","-19.2519","-40.4608","Brazil","BR","BRA","Espírito Santo","","10869","1076956567"
"Ciudad Insurgentes","Ciudad Insurgentes","25.2617","-111.7744","Mexico","MX","MEX","Baja California Sur","","9133","1484354076"
"Bhawānandpur","Bhawanandpur","25.5400","86.1030","India","IN","IND","Bihār","","9603","1356038471"
"Munnūru","Munnuru","12.9000","75.0300","India","IN","IND","Karnātaka","","10281","1356001651"
"Pua","Pua","19.1799","100.9089","Thailand","TH","THA","Nan","minor","10831","1764017430"
"Cori","Cori","41.6445","12.9127","Italy","IT","ITA","Lazio","","10893","1380042566"
"Nivala","Nivala","63.9250","24.9750","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","10876","1246609695"
"Bofete","Bofete","-23.1022","-48.2578","Brazil","BR","BRA","São Paulo","","10879","1076000023"
"Tecoh","Tecoh","20.7419","-89.4744","Mexico","MX","MEX","Yucatán","","9134","1484904738"
"Aşağı Ayıblı","Asagi Ayibli","40.9368","45.8258","Azerbaijan","AZ","AZE","Tovuz","","10797","1031116600"
"Court-Saint-Étienne","Court-Saint-Etienne","50.6333","4.5667","Belgium","BE","BEL","Wallonia","","10500","1056682611"
"Grandview","Grandview","46.2443","-119.9092","United States","US","USA","Washington","","10894","1840019871"
"Yoko","Yoko","6.7000","2.6167","Benin","BJ","BEN","Plateau","","10543","1204983391"
"Jānpur","Janpur","25.2958","84.8789","India","IN","IND","Bihār","","9313","1356916147"
"Itapebi","Itapebi","-15.9508","-39.5339","Brazil","BR","BRA","Bahia","","10882","1076056200"
"Daganzo de Arriba","Daganzo de Arriba","40.5433","-3.4572","Spain","ES","ESP","Madrid","","10650","1724385787"
"Vikrutamāla","Vikrutamala","13.6206","79.5642","India","IN","IND","Andhra Pradesh","","10457","1356046673"
"San Roque","San Roque","-28.5667","-58.7167","Argentina","AR","ARG","Corrientes","minor","10885","1032090970"
"Mesyagutovo","Mesyagutovo","55.5322","58.2550","Russia","RU","RUS","Bashkortostan","","10883","1643865177"
"Lágos","Lagos","41.0667","25.0250","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","","10862","1300461284"
"Shāhpur Chaumukhi","Shahpur Chaumukhi","25.7683","86.9049","India","IN","IND","Bihār","","10098","1356362474"
"Humlebæk","Humlebaek","55.9611","12.5250","Denmark","DK","DNK","Hovedstaden","","9758","1208928560"
"Ambohidanerana","Ambohidanerana","-19.1833","46.7667","Madagascar","MG","MDG","Antananarivo","","10786","1450817535"
"Kotūr","Kotur","17.1447","78.2886","India","IN","IND","Andhra Pradesh","","10519","1356206598"
"Celina","Celina","40.5550","-84.5626","United States","US","USA","Ohio","","10890","1840007210"
"Rāmāreddi","Ramareddi","18.4110","78.3674","India","IN","IND","Andhra Pradesh","","10647","1356155190"
"Gudibanda","Gudibanda","13.9753","77.1046","India","IN","IND","Andhra Pradesh","","10643","1356759026"
"Estiva","Estiva","-22.4628","-46.0169","Brazil","BR","BRA","Minas Gerais","","10845","1076192640"
"Pithaura","Pithaura","26.2620","87.0983","India","IN","IND","Bihār","","9822","1356449995"
"Tekkāttūr","Tekkattur","10.2997","78.7884","India","IN","IND","Tamil Nādu","","10571","1356233814"
"Kūcheşfahān","Kuchesfahan","37.2783","49.7728","Iran","IR","IRN","Gīlān","","10026","1364862389"
"Yazoo City","Yazoo City","32.8619","-90.4075","United States","US","USA","Mississippi","","10887","1840015766"
"Haysville","Haysville","37.5648","-97.3527","United States","US","USA","Kansas","","10887","1840007568"
"Vashon","Vashon","47.4122","-122.4726","United States","US","USA","Washington","","10886","1840018414"
"Stenungsund","Stenungsund","58.0833","11.8167","Sweden","SE","SWE","Västra Götaland","minor","9987","1752961653"
"Plano","Plano","41.6757","-88.5293","United States","US","USA","Illinois","","10885","1840009258"
"Le Locle","Le Locle","47.0532","6.7482","Switzerland","CH","CHE","Neuchâtel","","10433","1756888589"
"Erlenbach am Main","Erlenbach am Main","49.8039","9.1639","Germany","DE","DEU","Bavaria","","10256","1276645888"
"Scartho","Scartho","53.5399","-0.0926","United Kingdom","GB","GBR","North East Lincolnshire","","10408","1826290531"
"Katra","Katra","32.9917","74.9319","India","IN","IND","Jammu and Kashmīr","","10669","1356716265"
"Mogotes","Mogotes","6.4833","-72.9667","Colombia","CO","COL","Santander","minor","10860","1170943912"
"Ricaurte","Ricaurte","4.2792","-74.7728","Colombia","CO","COL","Cundinamarca","minor","10788","1170544866"
"Alto Rio Doce","Alto Rio Doce","-21.0258","-43.4108","Brazil","BR","BRA","Minas Gerais","","10859","1076504433"
"Arapgir","Arapgir","39.0333","38.4833","Turkey","TR","TUR","Malatya","minor","10868","1792675221"
"Damascus","Damascus","45.4233","-122.4436","United States","US","USA","Oregon","","10878","1840018571"
"Sérékali","Serekali","9.9186","3.0434","Benin","BJ","BEN","Borgou","","10818","1204362974"
"Komarolu","Komarolu","15.2667","79.0000","India","IN","IND","Andhra Pradesh","","10746","1356768871"
"Valaparla","Valaparla","15.9167","80.0500","India","IN","IND","Andhra Pradesh","","10151","1356771123"
"Lollar","Lollar","50.6497","8.7044","Germany","DE","DEU","Hesse","","10395","1276402462"
"Amioûn","Amioun","34.3000","35.8000","Lebanon","LB","LBN","Liban-Nord","minor","10000","1422143071"
"Lincoln Park","Lincoln Park","40.9239","-74.3035","United States","US","USA","New Jersey","","10877","1840000953"
"Hariāna","Hariana","31.6351","75.8389","India","IN","IND","Punjab","","10319","1356249211"
"St. Clements","St. Clements","50.2689","-96.6742","Canada","CA","CAN","Manitoba","","10876","1124000566"
"Ambato","Ambato","-18.6000","47.5000","Madagascar","MG","MDG","Antananarivo","","10642","1450100718"
"Tallimarjon Shahri","Tallimarjon Shahri","38.2967","65.5533","Uzbekistan","UZ","UZB","Qashqadaryo","","10800","1860832901"
"Tokkavādi","Tokkavadi","11.3702","77.8412","India","IN","IND","Tamil Nādu","","9964","1356166662"
"Khorol","Khorol","44.4289","132.0742","Russia","RU","RUS","Primorskiy Kray","","10860","1643989491"
"Novgorodskoye","Novgorodskoye","48.3328","37.8369","Ukraine","UA","UKR","Donetska Oblast","","10227","1804602556"
"Taimali","Taimali","22.6167","121.0000","Taiwan","TW","TWN","Taitung","","10763","1158802426"
"Blackhawk","Blackhawk","37.8159","-121.9071","United States","US","USA","California","","10874","1840028358"
"Villiersdorp","Villiersdorp","-33.9833","19.2833","South Africa","ZA","ZAF","Western Cape","","10004","1710014567"
"Yedrāmi","Yedrami","16.8667","76.5333","India","IN","IND","Karnātaka","","10695","1356687431"
"Hambühren","Hambuhren","52.6333","9.9833","Germany","DE","DEU","Lower Saxony","","10683","1276102080"
"Waynesboro","Waynesboro","39.7524","-77.5822","United States","US","USA","Pennsylvania","","10871","1840001415"
"Saint-Amable","Saint-Amable","45.6500","-73.3000","Canada","CA","CAN","Quebec","","10870","1124000904"
"Weyburn","Weyburn","49.6611","-103.8525","Canada","CA","CAN","Saskatchewan","","10870","1124618383"
"Lauterach","Lauterach","47.4772","9.7314","Austria","AT","AUT","Vorarlberg","","10028","1040609359"
"Fife","Fife","47.2329","-122.3518","United States","US","USA","Washington","","10869","1840019853"
"Strasshof an der Nordbahn","Strasshof an der Nordbahn","48.3194","16.6475","Austria","AT","AUT","Niederösterreich","","10009","1040365922"
"Sorbhog","Sorbhog","26.5000","90.8700","India","IN","IND","Assam","","10146","1356381654"
"Triangle","Triangle","38.5483","-77.3195","United States","US","USA","Virginia","","10868","1840006105"
"Sam Ko","Sam Ko","14.6013","100.2602","Thailand","TH","THA","Ang Thong","minor","10696","1764322082"
"Itri","Itri","41.2833","13.5333","Italy","IT","ITA","Lazio","","10761","1380610336"
"Karayılan","Karayilan","36.7167","36.2333","Turkey","TR","TUR","Hatay","","10574","1792300170"
"Krzeszowice","Krzeszowice","50.1333","19.6333","Poland","PL","POL","Małopolskie","","10281","1616471634"
"Paraúna","Parauna","-16.9478","-50.4489","Brazil","BR","BRA","Goiás","","10863","1076649178"
"Tacima","Tacima","-6.4878","-35.6369","Brazil","BR","BRA","Paraíba","","10822","1076807008"
"Ripoll","Ripoll","42.2011","2.1903","Spain","ES","ESP","Catalonia","","10721","1724329202"
"Keza","Keza","-2.7540","30.6820","Tanzania","TZ","TZA","Kagera","","10810","1834729432"
"Ghattu","Ghattu","13.6602","78.3331","India","IN","IND","Andhra Pradesh","","10608","1356122664"
"San Pablo Huixtepec","San Pablo Huixtepec","16.8167","-96.7833","Mexico","MX","MEX","Oaxaca","minor","9313","1484156599"
"Fredericksburg","Fredericksburg","30.2660","-98.8751","United States","US","USA","Texas","","10864","1840020898"
"Freeport","Freeport","28.9453","-95.3601","United States","US","USA","Texas","","10864","1840020974"
"Doi Saket","Doi Saket","18.8667","99.1333","Thailand","TH","THA","Chiang Mai","minor","10750","1764197318"
"Gairtganj","Gairtganj","23.4102","78.2208","India","IN","IND","Madhya Pradesh","","10685","1356223571"
"Worsley","Worsley","53.5093","-2.3845","United Kingdom","GB","GBR","Salford","","10090","1826999294"
"Beachwood","Beachwood","39.9286","-74.2022","United States","US","USA","New Jersey","","10863","1840001483"
"Guaitarilla","Guaitarilla","1.1333","-77.5564","Colombia","CO","COL","Nariño","minor","10774","1170237698"
"Afonso Bezerra","Afonso Bezerra","-5.4978","-36.5058","Brazil","BR","BRA","Rio Grande do Norte","","10844","1076653188"
"Fürth","Furth","49.6502","8.7803","Germany","DE","DEU","Hesse","","10587","1276167147"
"Swanage","Swanage","50.6080","-1.9600","United Kingdom","GB","GBR","Dorset","","9601","1826896220"
"Garhi","Garhi","24.7904","86.0768","India","IN","IND","Bihār","","10197","1356108293"
"Kara-Tash","Kara-Tash","40.2167","72.6306","Kyrgyzstan","KG","KGZ","Osh","","10797","1417239790"
"Manville","Manville","40.5420","-74.5892","United States","US","USA","New Jersey","","10861","1840003610"
"Kharika","Kharika","25.7173","85.1256","India","IN","IND","Bihār","","9212","1356353027"
"Grigiškės","Grigiskes","54.6667","25.1000","Lithuania","LT","LTU","Vilniaus Miestas","","10815","1440322258"
"Mios","Mios","44.6050","-0.9369","France","FR","FRA","Nouvelle-Aquitaine","","10781","1250096586"
"Scherpenzeel","Scherpenzeel","52.0833","5.4667","Netherlands","NL","NLD","Gelderland","minor","10128","1528061473"
"Sarmiento","Sarmiento","-45.6000","-69.0833","Argentina","AR","ARG","Chubut","minor","10858","1032479340"
"Shāmpur","Shampur","23.4032","77.3465","India","IN","IND","Madhya Pradesh","","10571","1356540723"
"Troy","Troy","38.7268","-89.8977","United States","US","USA","Illinois","","10858","1840010709"
"Pocono","Pocono","41.0612","-75.3102","United States","US","USA","Pennsylvania","","10857","1840144156"
"Kākhandiki","Kakhandiki","16.6010","75.6405","India","IN","IND","Karnātaka","","10532","1356529394"
"Apía","Apia","5.1000","-75.9500","Colombia","CO","COL","Risaralda","minor","10707","1170159513"
"San Juan","San Juan","17.6834","120.7320","Philippines","PH","PHL","Abra","","10688","1608664813"
"Cantillana","Cantillana","37.6000","-5.8167","Spain","ES","ESP","Andalusia","","10755","1724661901"
"Key Largo","Key Largo","25.1224","-80.4120","United States","US","USA","Florida","","10854","1840014253"
"Cedro","Cedro","-7.7219","-39.2389","Brazil","BR","BRA","Pernambuco","","10778","1076317515"
"Suyo","Suyo","16.9839","120.5139","Philippines","PH","PHL","Ilocos Sur","","10766","1608201191"
"Tosagua","Tosagua","-0.7800","-80.2600","Ecuador","EC","ECU","Manabí","","10751","1218293218"
"Reguengos de Monsaraz","Reguengos de Monsaraz","38.4167","-7.5333","Portugal","PT","PRT","Évora","minor","10828","1620982468"
"Raeren","Raeren","50.6833","6.1167","Belgium","BE","BEL","Wallonia","","10707","1056287455"
"Ngorongoro","Ngorongoro","-3.2496","35.5200","Tanzania","TZ","TZA","Arusha","","10836","1834381549"
"Burkburnett","Burkburnett","34.0746","-98.5672","United States","US","USA","Texas","","10851","1840019278"
"Vembūr","Vembur","10.5893","78.0709","India","IN","IND","Tamil Nādu","","10500","1356220021"
"Dasai","Dasai","22.7200","75.1321","India","IN","IND","Madhya Pradesh","","10589","1356793288"
"Chesterfield","Chesterfield","53.2358","-1.4275","United Kingdom","GB","GBR","Derbyshire","","10688","1826756415"
"Laranja da Terra","Laranja da Terra","-19.8989","-41.0569","Brazil","BR","BRA","Espírito Santo","","10826","1076968120"
"Arāvelli","Aravelli","18.7190","79.0262","India","IN","IND","Andhra Pradesh","","10537","1356892681"
"Amaturá","Amatura","-3.3639","-68.1978","Brazil","BR","BRA","Amazonas","","10847","1076997362"
"Manevychi","Manevychi","51.2925","25.5503","Ukraine","UA","UKR","Volynska Oblast","","10825","1804717717"
"Nijoní","Nijoni","39.9667","68.9167","Tajikistan","TJ","TJK","Sughd","","10350","1762549983"
"Sharg‘un","Sharg\`un","38.4600","67.9669","Uzbekistan","UZ","UZB","Surxondaryo","","10716","1860470094"
"Icononzo","Icononzo","4.1833","-74.5333","Colombia","CO","COL","Tolima","minor","10801","1170002188"
"Kadanganeri","Kadanganeri","8.9200","77.5500","India","IN","IND","Tamil Nādu","","9882","1356206442"
"Ørsta","Orsta","62.2003","6.1322","Norway","NO","NOR","Møre og Romsdal","minor","10833","1578873602"
"Chuhr Chak","Chuhr Chak","30.7775","75.3628","India","IN","IND","Punjab","","10360","1356301323"
"Pencoed","Pencoed","51.5228","-3.5047","United Kingdom","GB","GBR","Bridgend","","9166","1826165735"
"Devi Hosūr","Devi Hosur","14.7858","75.3335","India","IN","IND","Karnātaka","","10488","1356002052"
"Nawāda","Nawada","25.5037","85.7325","India","IN","IND","Bihār","","8939","1356486934"
"Borshchiv","Borshchiv","48.8024","26.0400","Ukraine","UA","UKR","Ternopilska Oblast","","10803","1804384535"
"Gangāpur","Gangapur","25.8565","86.9496","India","IN","IND","Bihār","","9459","1356301139"
"Celldömölk","Celldomolk","47.2557","17.1491","Hungary","HU","HUN","Vas","minor","10646","1348592916"
"Capena","Capena","42.1403","12.5403","Italy","IT","ITA","Lazio","","10799","1380341916"
"Burgos","Burgos","18.5114","120.6444","Philippines","PH","PHL","Ilocos Norte","","10759","1608640535"
"Kanhai","Kanhai","25.9761","86.2898","India","IN","IND","Bihār","","9539","1356260723"
"South Union","South Union","39.8705","-79.7222","United States","US","USA","Pennsylvania","","10842","1840143167"
"St. Albans","St. Albans","38.3769","-81.8198","United States","US","USA","West Virginia","","10842","1840006201"
"Hrebinka","Hrebinka","50.1180","32.4396","Ukraine","UA","UKR","Poltavska Oblast","","10805","1804281882"
"Kitee","Kitee","62.1000","30.1333","Finland","FI","FIN","Pohjois-Karjala","minor","10832","1246458844"
"Puduppatti","Puduppatti","9.6171","77.6375","India","IN","IND","Tamil Nādu","","10368","1356217616"
"São Tomé","Sao Tome","-5.9728","-36.0750","Brazil","BR","BRA","Rio Grande do Norte","","10827","1076775707"
"Bestwig","Bestwig","51.3667","8.4000","Germany","DE","DEU","North Rhine-Westphalia","","10687","1276089671"
"Primeiro de Maio","Primeiro de Maio","-22.8508","-51.0278","Brazil","BR","BRA","Paraná","","10832","1076129700"
"Kuršėnai","Kursenai","55.9833","22.9167","Lithuania","LT","LTU","Šiauliai","","10829","1440741018"
"Akbarpur","Akbarpur","24.6281","83.9199","India","IN","IND","Jhārkhand","","10594","1356080609"
"Agouna","Agouna","7.5667","1.7000","Benin","BJ","BEN","Zou","","10763","1204331167"
"Yampil","Yampil","48.2450","28.2778","Ukraine","UA","UKR","Vinnytska Oblast","minor","10787","1804272878"
"Pattīswaram","Pattiswaram","10.9253","79.3424","India","IN","IND","Tamil Nādu","","9113","1356176191"
"Weingarten","Weingarten","49.0514","8.5306","Germany","DE","DEU","Baden-Württemberg","","10482","1276014784"
"Maur","Maur","47.3417","8.6703","Switzerland","CH","CHE","Zürich","","10148","1756776962"
"Gaohucun","Gaohucun","28.3368","120.2167","China","CN","CHN","Zhejiang","","10725","1156373640"
"Piraúba","Pirauba","-21.2758","-43.0258","Brazil","BR","BRA","Minas Gerais","","10759","1076245527"
"Baryshivka","Baryshivka","50.3703","31.3286","Ukraine","UA","UKR","Kyivska Oblast","","10783","1804157251"
"Uchti","Uchti","25.9197","86.2544","India","IN","IND","Bihār","","9108","1356260354"
"Laćarak","Lacarak","45.0000","19.5667","Serbia","RS","SRB","","","10638","1688011323"
"South Dundas","South Dundas","44.9167","-75.2667","Canada","CA","CAN","Ontario","","10833","1124001404"
"Sanson","Sanson","9.2833","2.4333","Benin","BJ","BEN","Borgou","","10784","1204972497"
"Emiliano Zapata","Emiliano Zapata","16.5000","-92.8667","Mexico","MX","MEX","Chiapas","","10783","1484357716"
"Yeghvard","Yeghvard","40.3217","44.4814","Armenia","AM","ARM","Kotayk’","","10656","1051779287"
"Chapeltique","Chapeltique","13.6333","-88.2667","El Salvador","SV","SLV","San Miguel","","10728","1222915994"
"Bārah","Barah","35.6830","36.5330","Syria","SY","SYR","Idlib","","10353","1760852702"
"Nova Floresta","Nova Floresta","-6.4550","-36.2028","Brazil","BR","BRA","Paraíba","","10650","1076323638"
"Wantage","Wantage","41.2431","-74.6258","United States","US","USA","New Jersey","","10831","1840081776"
"Xiaozui","Xiaozui","35.6912","108.0779","China","CN","CHN","Gansu","","10713","1156667721"
"Rincão","Rincao","-21.5869","-48.0708","Brazil","BR","BRA","São Paulo","","10796","1076750486"
"Fiuggi","Fiuggi","41.8000","13.2167","Italy","IT","ITA","Lazio","","10511","1380033712"
"Pipra","Pipra","26.3460","87.2916","India","IN","IND","Bihār","","9436","1356038332"
"Volda","Volda","62.1468","6.0680","Norway","NO","NOR","Møre og Romsdal","minor","10809","1578903172"
"Kaimūh","Kaimuh","33.7197","75.0811","India","IN","IND","Jammu and Kashmīr","","9839","1356552576"
"Munhall","Munhall","40.3935","-79.9005","United States","US","USA","Pennsylvania","","10827","1840001247"
"Diavatá","Diavata","40.6883","22.8583","Greece","GR","GRC","Kentrikí Makedonía","","9890","1300787037"
"Léognan","Leognan","44.7286","-0.6008","France","FR","FRA","Nouvelle-Aquitaine","","10571","1250609222"
"Itapitanga","Itapitanga","-14.4228","-39.5650","Brazil","BR","BRA","Bahia","","10800","1076215171"
"Jaipur","Jaipur","23.4313","86.1493","India","IN","IND","West Bengal","","10259","1356450301"
"Oppatavādi","Oppatavadi","12.5690","78.3992","India","IN","IND","Tamil Nādu","","10330","1356284295"
"Hombal","Hombal","15.5164","75.5556","India","IN","IND","Karnātaka","","10375","1356899456"
"Mallan","Mallan","30.4015","74.7365","India","IN","IND","Punjab","","10348","1356680446"
"Tivim","Tivim","15.6000","73.8000","India","IN","IND","Goa","","9716","1356138796"
"Aurahi","Aurahi","25.8263","87.1365","India","IN","IND","Bihār","","9422","1356997980"
"Dibrāghani","Dibraghani","25.8261","87.0664","India","IN","IND","Bihār","","9422","1356094419"
"Ousseltia","Ousseltia","35.8400","9.5800","Tunisia","TN","TUN","Kairouan","","10783","1788889773"
"East Cocalico","East Cocalico","40.2242","-76.1057","United States","US","USA","Pennsylvania","","10820","1840148813"
"Khrystynivka","Khrystynivka","48.8333","29.9667","Ukraine","UA","UKR","Cherkaska Oblast","","10762","1804529706"
"Sonoma","Sonoma","38.2902","-122.4598","United States","US","USA","California","","10819","1840021490"
"Euxton","Euxton","53.6620","-2.6740","United Kingdom","GB","GBR","Lancashire","","9993","1826461548"
"Asālem","Asalem","37.7306","48.9536","Iran","IR","IRN","Gīlān","","10720","1364809710"
"Braunsbedra","Braunsbedra","51.2833","11.9000","Germany","DE","DEU","Saxony-Anhalt","","10678","1276157165"
"Isnapuram","Isnapuram","17.5443","78.3486","India","IN","IND","Andhra Pradesh","","10000","1356470546"
"Pāikpāra","Paikpara","24.3149","87.8503","India","IN","IND","West Bengal","","9993","1356378672"
"Wang Sombun","Wang Sombun","13.3515","102.1833","Thailand","TH","THA","Sa Kaeo","minor","10747","1764786290"
"Randazzo","Randazzo","37.8833","14.9500","Italy","IT","ITA","Sicilia","","10763","1380459051"
"Nurmo","Nurmo","62.8278","22.9083","Finland","FI","FIN","Etelä-Pohjanmaa","","10785","1246405413"
"Saint-Gély-du-Fesc","Saint-Gely-du-Fesc","43.6922","3.8061","France","FR","FRA","Occitanie","","10197","1250953025"
"Chandera","Chandera","25.0829","78.9784","India","IN","IND","Madhya Pradesh","","10414","1356320894"
"Tamiang Layang","Tamiang Layang","-2.1160","115.1691","Indonesia","ID","IDN","Kalimantan Tengah","minor","10780","1360507252"
"Ogano","Ogano","36.0171","139.0086","Japan","JP","JPN","Saitama","","10751","1392226506"
"Dasaut","Dasaut","25.8997","86.0964","India","IN","IND","Bihār","","9088","1356175077"
"Abram","Abram","53.5080","-2.5880","United Kingdom","GB","GBR","Wigan","","9855","1826869753"
"Turmānīn","Turmanin","36.2333","36.8167","Syria","SY","SYR","Idlib","","10394","1760551418"
"Rush","Rush","53.5220","-6.0890","Ireland","IE","IRL","Fingal","","9231","1372361445"
"Al Karak","Al Karak","32.6872","36.3508","Syria","SY","SYR","Dar‘ā","","10510","1760662901"
"Kurman","Kurman","45.4978","34.2950","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","10766","1804051281"
"Simarbani","Simarbani","26.1953","87.1769","India","IN","IND","Bihār","","9418","1356741888"
"Tököl","Tokol","47.3203","18.9671","Hungary","HU","HUN","Pest","","10537","1348214866"
"Le Taillan-Médoc","Le Taillan-Medoc","44.9044","-0.6697","France","FR","FRA","Nouvelle-Aquitaine","","10141","1250201040"
"Imi Mokorn","Imi Mokorn","30.1675","-9.2322","Morocco","MA","MAR","Souss-Massa","","10748","1504588665"
"Jhonkar","Jhonkar","23.2361","76.1814","India","IN","IND","Madhya Pradesh","","10537","1356014159"
"Wald-Michelbach","Wald-Michelbach","49.5724","8.8243","Germany","DE","DEU","Hesse","","10664","1276767345"
"Montignies-le-Tilleul","Montignies-le-Tilleul","50.3833","4.3833","Belgium","BE","BEL","Wallonia","","10136","1056126803"
"Mangrāwān","Mangrawan","25.0929","84.3912","India","IN","IND","Bihār","","9500","1356315968"
"Kāmepalle","Kamepalle","16.5191","79.8350","India","IN","IND","Andhra Pradesh","","10319","1356603499"
"Huétor-Tájar","Huetor-Tajar","37.1947","-4.0464","Spain","ES","ESP","Andalusia","","10543","1724650449"
"Ararendá","Ararenda","-4.7528","-40.8328","Brazil","BR","BRA","Ceará","","10775","1076266994"
"Soquel","Soquel","36.9978","-121.9483","United States","US","USA","California","","10806","1840018957"
"Foum Jam’a","Foum Jam'a","31.9600","-6.9800","Morocco","MA","MAR","Béni Mellal-Khénifra","","9873","1504414947"
"Juripiranga","Juripiranga","-7.3728","-35.2378","Brazil","BR","BRA","Paraíba","","10669","1076780471"
"Lehman","Lehman","41.1518","-74.9924","United States","US","USA","Pennsylvania","","10804","1840147215"
"Fontaine-lès-Dijon","Fontaine-les-Dijon","47.3433","5.0192","France","FR","FRA","Bourgogne-Franche-Comté","","8844","1250626718"
"Shannon","Shannon","52.7137","-8.8686","Ireland","IE","IRL","Clare","","9222","1372328164"
"Antenetibe","Antenetibe","-18.7833","47.4167","Madagascar","MG","MDG","Antananarivo","","10536","1450141547"
"Wittelsheim","Wittelsheim","47.8053","7.2375","France","FR","FRA","Grand Est","","10362","1250294605"
"Tiszaföldvár","Tiszafoldvar","46.9739","20.2539","Hungary","HU","HUN","Jász-Nagykun-Szolnok","","10672","1348506959"
"Dáli","Dali","35.0211","33.4200","Cyprus","CY","CYP","Lefkosía","","10466","1196765328"
"Narkatpalli","Narkatpalli","17.2030","79.1950","India","IN","IND","Andhra Pradesh","","10394","1356046669"
"Villeneuve-lès-Maguelone","Villeneuve-les-Maguelone","43.5322","3.8608","France","FR","FRA","Occitanie","","10344","1250073253"
"Hochdorf","Hochdorf","47.1664","8.2889","Switzerland","CH","CHE","Luzern","","9844","1756537486"
"Uzda","Uzda","53.4661","27.2244","Belarus","BY","BLR","Minskaya Voblasts’","","10781","1112634742"
"Santo Domingo","Santo Domingo","6.4708","-75.1658","Colombia","CO","COL","Antioquia","minor","10759","1170000493"
"Gounarou","Gounarou","10.8667","2.8500","Benin","BJ","BEN","Alibori","","10767","1204739414"
"Sāngi","Sangi","26.3237","86.4481","India","IN","IND","Bihār","","9547","1356646132"
"Kolno","Kolno","53.4106","21.9339","Poland","PL","POL","Podlaskie","minor","10396","1616110330"
"Montgomery","Montgomery","39.2496","-84.3458","United States","US","USA","Ohio","","10796","1840008521"
"Douar Bouchfaa","Douar Bouchfaa","34.1014","-4.2939","Morocco","MA","MAR","Fès-Meknès","","10724","1504240184"
"Tarabha","Tarabha","20.7325","83.6744","India","IN","IND","Odisha","","10545","1356827749"
"Poiana Mare","Poiana Mare","43.9333","23.0833","Romania","RO","ROU","Dolj","","10740","1642956128"
"Tiszafüred","Tiszafured","47.6167","20.7667","Hungary","HU","HUN","Jász-Nagykun-Szolnok","minor","10745","1348745006"
"Əliabad","Aliabad","41.4783","46.6175","Azerbaijan","AZ","AZE","Zaqatala","","10700","1031807683"
"Guaraci","Guaraci","-20.4986","-48.9447","Brazil","BR","BRA","São Paulo","","10778","1076832083"
"Sisai","Sisai","23.1794","84.7582","India","IN","IND","Jhārkhand","","10437","1356065176"
"La Trinité","La Trinite","43.7408","7.3142","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10115","1250907387"
"Ban Ko","Ban Ko","13.6486","100.0063","Thailand","TH","THA","Ratchaburi","","10561","1764504275"
"Raitar","Raitar","25.0577","85.5366","India","IN","IND","Bihār","","9441","1356068979"
"Van Wert","Van Wert","40.8651","-84.5879","United States","US","USA","Ohio","","10793","1840010350"
"Baretha","Baretha","25.5258","87.4485","India","IN","IND","Bihār","","9562","1356014858"
"Zuchwil","Zuchwil","47.2056","7.5597","Switzerland","CH","CHE","Solothurn","","8875","1756623180"
"Gharyāla","Gharyala","31.2294","74.7352","India","IN","IND","Punjab","","10369","1356021460"
"Totolapan","Totolapan","18.9869","-98.9197","Mexico","MX","MEX","Morelos","minor","10790","1484963370"
"Madison Heights","Madison Heights","37.4487","-79.1057","United States","US","USA","Virginia","","10790","1840006372"
"Castleton","Castleton","53.5907","-2.1737","United Kingdom","GB","GBR","Rochdale","","10159","1826243181"
"Dumri","Dumri","26.8648","84.3767","India","IN","IND","Bihār","","9113","1356490756"
"Torpa","Torpa","22.9361","85.0889","India","IN","IND","Jhārkhand","","10538","1356194333"
"Mākhar","Makhar","25.7693","86.6025","India","IN","IND","Bihār","","9670","1356353555"
"Nkokonjeru","Nkokonjeru","0.2394","32.9231","Uganda","UG","UGA","Buikwe","","10100","1800002522"
"Morro Bay","Morro Bay","35.3682","-120.8482","United States","US","USA","California","","10785","1840020415"
"Joutseno","Joutseno","61.1230","28.5015","Finland","FI","FIN","Etelä-Karjala","","10750","1246814717"
"Antanandehibe","Antanandehibe","-19.6833","48.3333","Madagascar","MG","MDG","Toamasina","","10756","1450710342"
"Bāsht","Basht","30.3611","51.1572","Iran","IR","IRN","Kohgīlūyeh va Bowyer Aḩmad","minor","10764","1364527291"
"Oloron-Sainte-Marie","Oloron-Sainte-Marie","43.1942","-0.6067","France","FR","FRA","Nouvelle-Aquitaine","minor","10629","1250195231"
"Pompton Plains","Pompton Plains","40.9679","-74.3075","United States","US","USA","New Jersey","","10784","1840033387"
"Méry-sur-Oise","Mery-sur-Oise","49.0636","2.1864","France","FR","FRA","Île-de-France","","9900","1250888044"
"Itatuba","Itatuba","-7.3750","-35.6278","Brazil","BR","BRA","Paraíba","","10739","1076342881"
"Demmin","Demmin","53.9050","13.0439","Germany","DE","DEU","Mecklenburg-Western Pomerania","","10657","1276386093"
"Babhantoli","Babhantoli","26.2168","88.3249","India","IN","IND","Bihār","","9617","1356910526"
"Itacurubí de la Cordillera","Itacurubi de la Cordillera","-25.4500","-56.8500","Paraguay","PY","PRY","Cordillera","","10744","1600603490"
"Nāgambhotlapālem","Nagambhotlapalem","15.6804","79.8151","India","IN","IND","Andhra Pradesh","","10534","1356817715"
"Nowa Dęba","Nowa Deba","50.4333","21.7667","Poland","PL","POL","Podkarpackie","","10647","1616557246"
"Harpur Bhindi","Harpur Bhindi","25.7746","85.6563","India","IN","IND","Bihār","","9179","1356635928"
"Jaguari","Jaguari","-29.4969","-54.6900","Brazil","BR","BRA","Rio Grande do Sul","","10765","1076183625"
"Swan Hill","Swan Hill","-35.3333","143.5500","Australia","AU","AUS","Victoria","","9864","1036220501"
"Borim","Borim","15.3604","74.0004","India","IN","IND","Goa","","10124","1356387573"
"Honwād","Honwad","16.8100","75.4200","India","IN","IND","Karnātaka","","10455","1356720095"
"Bleicherode","Bleicherode","51.4167","10.5667","Germany","DE","DEU","Thuringia","","10419","1276520742"
"Choachí","Choachi","4.5297","-73.9236","Colombia","CO","COL","Cundinamarca","minor","10729","1170262604"
"Placerville","Placerville","38.7308","-120.7979","United States","US","USA","California","","10777","1840020238"
"Alcoa","Alcoa","35.8076","-83.9753","United States","US","USA","Tennessee","","10777","1840013404"
"Nāhargarh","Nahargarh","24.1692","75.2342","India","IN","IND","Madhya Pradesh","","10519","1356047975"
"Yeşilköy","Yesilkoy","36.8667","36.2000","Turkey","TR","TUR","Hatay","","10482","1792432408"
"La Londe-les-Maures","La Londe-les-Maures","43.1381","6.2344","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10641","1250847316"
"Astorga","Astorga","42.4589","-6.0633","Spain","ES","ESP","Castille-Leon","","10553","1724399849"
"Kings Mountain","Kings Mountain","35.2349","-81.3501","United States","US","USA","North Carolina","","10775","1840014552"
"Vigonovo","Vigonovo","45.3852","12.0074","Italy","IT","ITA","Veneto","","9998","1380294468"
"Takon","Takon","6.6500","2.6167","Benin","BJ","BEN","Plateau","","10424","1204057835"
"Qamīnis","Qaminis","31.6572","20.0144","Libya","LY","LBY","Banghāzī","","10713","1434752325"
"Tanant","Tanant","31.8667","-6.9500","Morocco","MA","MAR","Béni Mellal-Khénifra","","10706","1504879588"
"Ānandpur","Anandpur","25.6192","84.8673","India","IN","IND","Bihār","","9270","1356688474"
"Parmānpur","Parmanpur","26.1457","87.3122","India","IN","IND","Bihār","","9667","1356613429"
"Pasupatikovil","Pasupatikovil","10.8893","79.1795","India","IN","IND","Tamil Nādu","","9808","1356173632"
"Joaquim Távora","Joaquim Tavora","-23.4989","-49.9050","Brazil","BR","BRA","Paraná","","10736","1076518339"
"Urlaţi","Urlati","44.9911","26.2306","Romania","RO","ROU","Prahova","","10541","1642991505"
"Atchison","Atchison","39.5625","-95.1367","United States","US","USA","Kansas","","10773","1840001543"
"Ōtsuchi","Otsuchi","39.3582","141.8994","Japan","JP","JPN","Iwate","","10719","1392244776"
"Balderton","Balderton","53.0549","-0.7727","United Kingdom","GB","GBR","Nottinghamshire","","9757","1826134256"
"Gateway","Gateway","26.5793","-81.7446","United States","US","USA","Florida","","10772","1840014213"
"La Farlède","La Farlede","43.1678","6.0431","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9614","1250141356"
"Yuzhno-Sukhokumsk","Yuzhno-Sukhokumsk","44.6667","45.6500","Russia","RU","RUS","Dagestan","","10641","1643359037"
"Centenário do Sul","Centenario do Sul","-22.8208","-51.5950","Brazil","BR","BRA","Paraná","","10764","1076604544"
"Sabaneta de Yásica","Sabaneta de Yasica","19.6667","-70.3833","Dominican Republic","DO","DOM","Cibao Norte","","10545","1214622077"
"Galena Park","Galena Park","29.7452","-95.2333","United States","US","USA","Texas","","10770","1840020922"
"Birkenfeld","Birkenfeld","48.8697","8.6361","Germany","DE","DEU","Baden-Württemberg","","10238","1276313867"
"Râs el Metn","Ras el Metn","33.8500","35.6667","Lebanon","LB","LBN","Mont-Liban","","10000","1422014908"
"Wellington","Wellington","40.7000","-105.0054","United States","US","USA","Colorado","","10769","1840022411"
"Al Musayfirah","Al Musayfirah","32.6322","36.3386","Syria","SY","SYR","Dar‘ā","minor","10466","1760039988"
"Kauniainen","Kauniainen","60.2167","24.7333","Finland","FI","FIN","Uusimaa","minor","9624","1246749773"
"Kāmthi","Kamthi","14.5000","79.4167","India","IN","IND","Mahārāshtra","","10437","1356982851"
"Alto Parnaíba","Alto Parnaiba","-9.1108","-45.9300","Brazil","BR","BRA","Maranhão","","10766","1076860492"
"Guthrie","Guthrie","35.8424","-97.4366","United States","US","USA","Oklahoma","","10767","1840020390"
"Belsara","Belsara","26.0152","87.2023","India","IN","IND","Bihār","","9703","1356723054"
"Kelilalina","Kelilalina","-21.2833","47.5500","Madagascar","MG","MDG","Fianarantsoa","","10747","1450902894"
"Bhargaon","Bhargaon","26.0750","87.1483","India","IN","IND","Bihār","","9138","1356385391"
"Dumra","Dumra","25.5907","87.0614","India","IN","IND","Bihār","","9555","1356271326"
"Lerici","Lerici","44.0764","9.9111","Italy","IT","ITA","Liguria","","10133","1380014455"
"Felsberg","Felsberg","51.1333","9.4167","Germany","DE","DEU","Hesse","","10638","1276987745"
"Kūhbanān","Kuhbanan","31.4103","56.2825","Iran","IR","IRN","Kermān","minor","10761","1364489300"
"Tisma","Tisma","12.0831","-86.0167","Nicaragua","NI","NIC","Masaya","minor","10681","1558010790"
"Curimatá","Curimata","-10.0358","-44.3058","Brazil","BR","BRA","Piauí","","10761","1076271852"
"Arcola","Arcola","44.1167","9.9000","Italy","IT","ITA","Liguria","","10145","1380983568"
"Kopparam","Kopparam","16.0841","79.9874","India","IN","IND","Andhra Pradesh","","10453","1356666939"
"Coité do Nóia","Coite do Noia","-9.6319","-36.5789","Brazil","BR","BRA","Alagoas","","10643","1076884049"
"Iwai","Iwai","35.5833","134.3333","Japan","JP","JPN","Tottori","","10675","1392109528"
"Pintadas","Pintadas","-11.8128","-39.9089","Brazil","BR","BRA","Bahia","","10742","1076824576"
"Goianápolis","Goianapolis","-16.5108","-49.0239","Brazil","BR","BRA","Goiás","","10695","1076658634"
"Lakkireddipalle","Lakkireddipalle","14.1667","78.7000","India","IN","IND","Andhra Pradesh","","10303","1356608796"
"Köngen","Kongen","48.6819","9.3667","Germany","DE","DEU","Baden-Württemberg","","9975","1276029285"
"Lehigh","Lehigh","40.7678","-75.5394","United States","US","USA","Pennsylvania","","10760","1840142186"
"Nizza Monferrato","Nizza Monferrato","44.7747","8.3550","Italy","IT","ITA","Piedmont","","10313","1380659931"
"Bāsdeopur","Basdeopur","25.3909","86.6821","India","IN","IND","Bihār","","9014","1356257781"
"Periyanegamam","Periyanegamam","10.7432","77.1030","India","IN","IND","Tamil Nādu","","10141","1356203865"
"Ngaputaw","Ngaputaw","16.5378","94.6936","Myanmar","MM","MMR","Ayeyarwady","","10632","1104989522"
"Caernarfon","Caernarfon","53.1400","-4.2700","United Kingdom","GB","GBR","Gwynedd","","9615","1826011275"
"Taouloukoult","Taouloukoult","31.2167","-9.0833","Morocco","MA","MAR","Marrakech-Safi","","10682","1504583141"
"Andovoranto","Andovoranto","-18.9544","49.1094","Madagascar","MG","MDG","Toamasina","","10723","1450518984"
"L’Île-Perrot","L'Ile-Perrot","45.3833","-73.9500","Canada","CA","CAN","Quebec","","10756","1124063001"
"Notre-Dame-de-l'Île-Perrot","Notre-Dame-de-l'Ile-Perrot","45.3667","-73.9333","Canada","CA","CAN","Quebec","","10756","1124001191"
"Ban Lueak","Ban Lueak","13.7065","99.8902","Thailand","TH","THA","Ratchaburi","","10480","1764013692"
"Campo Largo","Campo Largo","-26.8000","-60.8333","Argentina","AR","ARG","Chaco","minor","10743","1032892043"
"Elattūr","Elattur","11.3866","77.3080","India","IN","IND","Tamil Nādu","","10134","1356163537"
"Momanpet","Momanpet","17.5175","77.8981","India","IN","IND","Andhra Pradesh","","10364","1356411596"
"Sūlagiri","Sulagiri","12.6645","78.0134","India","IN","IND","Tamil Nādu","","10121","1356425553"
"Antsahadinta","Antsahadinta","-19.0167","47.4583","Madagascar","MG","MDG","Antananarivo","","10434","1450776872"
"Tangerhütte","Tangerhutte","52.4333","11.8000","Germany","DE","DEU","Saxony-Anhalt","","10718","1276201487"
"Hasanpura","Hasanpura","26.0751","84.3618","India","IN","IND","Bihār","","9321","1356002753"
"Khāsbalanda","Khasbalanda","22.5881","88.6887","India","IN","IND","West Bengal","","9046","1356124124"
"Williams Lake","Williams Lake","52.1294","-122.1383","Canada","CA","CAN","British Columbia","","10753","1124821980"
"Nonea","Nonea","26.9746","84.8768","India","IN","IND","Bihār","","9217","1356116567"
"Bischofshofen","Bischofshofen","47.4172","13.2194","Austria","AT","AUT","Salzburg","","10540","1040799035"
"Estaimpuis","Estaimpuis","50.6756","3.2758","Belgium","BE","BEL","Wallonia","","10424","1056179340"
"Santa Bárbara","Santa Barbara","26.8133","-105.8203","Mexico","MX","MEX","Chihuahua","minor","10721","1484698134"
"Telkapalli","Telkapalli","16.4500","78.4667","India","IN","IND","Andhra Pradesh","","10384","1356020207"
"Arbaa Sahel","Arbaa Sahel","29.5993","-9.8770","Morocco","MA","MAR","Souss-Massa","","10705","1504199672"
"Santa Catarina Ayotzingo","Santa Catarina Ayotzingo","19.2647","-98.8975","Mexico","MX","MEX","México","","10702","1484300687"
"Böhl-Iggelheim","Bohl-Iggelheim","49.3714","8.3086","Germany","DE","DEU","Rhineland-Palatinate","","10433","1276858476"
"Nadezhda","Nadezhda","45.0448","42.1104","Russia","RU","RUS","Stavropol’skiy Kray","","10695","1643152948"
"Vanzago","Vanzago","45.5268","8.9947","Italy","IT","ITA","Lombardy","","9224","1380790144"
"Surazh","Surazh","53.0167","32.3833","Russia","RU","RUS","Bryanskaya Oblast’","","10728","1643268069"
"Svedala","Svedala","55.5000","13.2333","Sweden","SE","SWE","Skåne","minor","10627","1752069692"
"Seberi","Seberi","-27.4778","-53.4028","Brazil","BR","BRA","Rio Grande do Sul","","10713","1076857463"
"Aigle","Aigle","46.3167","6.9667","Switzerland","CH","CHE","Valais","","10131","1756593148"
"Qal‘eh Tall","Qal\`eh Tall","31.6325","49.8897","Iran","IR","IRN","Khūzestān","","10698","1364980286"
"São Luís do Paraitinga","Sao Luis do Paraitinga","-23.2219","-45.3100","Brazil","BR","BRA","São Paulo","","10731","1076106138"
"Alberobello","Alberobello","40.7833","17.2333","Italy","IT","ITA","Puglia","","10725","1380601560"
"Shŭrobod","Shurobod","37.8403","70.0419","Tajikistan","TJ","TJK","Khatlon","minor","10700","1762032087"
"Richmond Heights","Richmond Heights","41.5589","-81.5029","United States","US","USA","Ohio","","10748","1840003409"
"Oyim","Oyim","40.8242","72.7414","Uzbekistan","UZ","UZB","Andijon","","10000","1860819147"
"Coriano","Coriano","43.9667","12.6000","Italy","IT","ITA","Emilia-Romagna","","10522","1380789233"
"Kīramangalam","Kiramangalam","10.2859","79.0996","India","IN","IND","Tamil Nādu","","10237","1356232302"
"Leninskoe","Leninskoe","42.9806","74.6722","Kyrgyzstan","KG","KGZ","Chüy","","10653","1417027549"
"Eriyodu","Eriyodu","10.5172","78.0627","India","IN","IND","Tamil Nādu","","10395","1356036620"
"Opglabbeek","Opglabbeek","51.0500","5.5833","Belgium","BE","BEL","Flanders","","10332","1056213359"
"Brandon","Brandon","43.5928","-96.5799","United States","US","USA","South Dakota","","10745","1840002650"
"Darling","Darling","-33.3833","18.3833","South Africa","ZA","ZAF","Western Cape","","10420","1710629684"
"Chewara","Chewara","25.0756","85.9277","India","IN","IND","Bihār","","9432","1356441891"
"Itabirinha de Mantena","Itabirinha de Mantena","-18.5658","-41.2328","Brazil","BR","BRA","Minas Gerais","","10692","1076325783"
"North Branch","North Branch","45.5137","-92.9601","United States","US","USA","Minnesota","","10742","1840007790"
"Mount Evelyn","Mount Evelyn","-37.7830","145.3850","Australia","AU","AUS","Victoria","","9702","1036617292"
"Lamhadi","Lamhadi","32.3000","-9.0167","Morocco","MA","MAR","Marrakech-Safi","","10651","1504208121"
"Kaithāhi","Kaithahi","26.3488","86.1521","India","IN","IND","Bihār","","9142","1356387063"
"Orthez","Orthez","43.4881","-0.7708","France","FR","FRA","Nouvelle-Aquitaine","","10515","1250946287"
"Southwater","Southwater","51.0238","-0.3526","United Kingdom","GB","GBR","West Sussex","","10025","1826135276"
"Elliot Lake","Elliot Lake","46.3833","-82.6500","Canada","CA","CAN","Ontario","","10741","1124793448"
"Lichtenau","Lichtenau","51.6000","8.8833","Germany","DE","DEU","North Rhine-Westphalia","","10685","1276302835"
"Presidente Vargas","Presidente Vargas","-3.4069","-44.0239","Brazil","BR","BRA","Maranhão","","10717","1076559761"
"Sarmera","Sarmera","25.2564","85.7988","India","IN","IND","Bihār","","9571","1356914849"
"Praskoveya","Praskoveya","44.7444","44.2031","Russia","RU","RUS","Stavropol’skiy Kray","","10721","1643044558"
"Sāīnkhera","Sainkhera","22.9589","78.5782","India","IN","IND","Madhya Pradesh","","10397","1356167148"
"Darb-e Behesht","Darb-e Behesht","29.2417","57.3253","Iran","IR","IRN","Kermān","","10670","1364349317"
"Villahermosa","Villahermosa","5.0000","-75.1667","Colombia","CO","COL","Tolima","minor","10696","1170425263"
"Rāmpur Parhat","Rampur Parhat","25.5916","87.1622","India","IN","IND","Bihār","","9510","1356181732"
"Benalla","Benalla","-36.5519","145.9817","Australia","AU","AUS","Victoria","","10330","1036934047"
"Monmouth","Monmouth","44.8505","-123.2284","United States","US","USA","Oregon","","10735","1840019971"
"Tamanar","Tamanar","31.0000","-9.6667","Morocco","MA","MAR","Marrakech-Safi","","10584","1504600694"
"Dharir","Dharir","22.3337","88.7324","India","IN","IND","West Bengal","","9411","1356121393"
"Bourg-de-Péage","Bourg-de-Peage","45.0378","5.0500","France","FR","FRA","Auvergne-Rhône-Alpes","","10007","1250194846"
"Tarboro","Tarboro","35.9046","-77.5563","United States","US","USA","North Carolina","","10733","1840016177"
"Pleasant View","Pleasant View","41.3249","-112.0011","United States","US","USA","Utah","","10733","1840020137"
"Čáslav","Caslav","49.9117","15.3950","Czechia","CZ","CZE","Středočeský Kraj","","10350","1203177244"
"Yādavolu","Yadavolu","17.0619","81.4729","India","IN","IND","Andhra Pradesh","","10317","1356745282"
"São Brás de Alportel","Sao Bras de Alportel","37.1500","-7.8833","Portugal","PT","PRT","Faro","minor","10662","1620714794"
"Fô-Bouré","Fo-Boure","10.1167","2.4000","Benin","BJ","BEN","Borgou","","10677","1204807965"
"Branquinha","Branquinha","-9.2458","-36.0150","Brazil","BR","BRA","Alagoas","","10675","1076298127"
"Lohārda","Loharda","22.5918","76.5957","India","IN","IND","Madhya Pradesh","","10554","1356742603"
"Murtosa","Murtosa","40.7333","-8.6333","Portugal","PT","PRT","Aveiro","minor","10585","1620337032"
"Senjān","Senjan","34.0497","49.6203","Iran","IR","IRN","Markazī","","10592","1364156071"
"Ankarabato","Ankarabato","-16.3500","46.1833","Madagascar","MG","MDG","Mahajanga","","10723","1450143070"
"Jackson","Jackson","43.4720","-110.7746","United States","US","USA","Wyoming","","10728","1840021258"
"Ichnia","Ichnia","50.8500","32.4000","Ukraine","UA","UKR","Chernihivska Oblast","","10709","1804909893"
"Dießen am Ammersee","Diessen am Ammersee","47.9500","11.1000","Germany","DE","DEU","Bavaria","","10599","1276330622"
"Zavitinsk","Zavitinsk","50.1281","129.4433","Russia","RU","RUS","Amurskaya Oblast’","","10721","1643858622"
"Latteri","Latteri","12.9712","79.0685","India","IN","IND","Tamil Nādu","","9692","1356641236"
"Clute","Clute","29.0256","-95.3975","United States","US","USA","Texas","","10722","1840019669"
"Mangalpur Gudaria","Mangalpur Gudaria","26.6513","84.4832","India","IN","IND","Bihār","","8920","1356922197"
"San Ricardo","San Ricardo","9.9167","125.2833","Philippines","PH","PHL","Southern Leyte","","10500","1608184956"
"Ubalá","Ubala","4.7439","-73.5347","Colombia","CO","COL","Cundinamarca","minor","10718","1170000627"
"Harsola","Harsola","22.5694","75.8165","India","IN","IND","Madhya Pradesh","","10319","1356273769"
"Sivrice","Sivrice","38.4467","39.3117","Turkey","TR","TUR","Elazığ","minor","10710","1792530375"
"Lālmunia Munhāra","Lalmunia Munhara","26.5741","86.4012","India","IN","IND","Bihār","","10026","1356083821"
"Pānchi","Panchi","25.1123","85.6321","India","IN","IND","Bihār","","10170","1356134882"
"Abadou","Abadou","31.5797","-7.3122","Morocco","MA","MAR","Marrakech-Safi","","10602","1504229584"
"Miāni","Miani","31.7092","75.5661","India","IN","IND","Punjab","","10203","1356097508"
"Mudgee","Mudgee","-32.6125","149.5872","Australia","AU","AUS","New South Wales","","9830","1036213564"
"Bolivar","Bolivar","37.6059","-93.4175","United States","US","USA","Missouri","","10718","1840007578"
"Sukhsena","Sukhsena","25.6881","87.1194","India","IN","IND","Bihār","","9795","1356158778"
"Poirino","Poirino","44.9208","7.8469","Italy","IT","ITA","Piedmont","","10576","1380649865"
"Katigang","Katigang","25.4157","87.8245","India","IN","IND","West Bengal","","9484","1356850577"
"Parauli","Parauli","26.2227","84.7042","India","IN","IND","Bihār","","9556","1356192130"
"Kingsteignton","Kingsteignton","50.5458","-3.5962","United Kingdom","GB","GBR","Devon","","10600","1826679607"
"Kiełczów","Kielczow","51.1406","17.1783","Poland","PL","POL","Dolnośląskie","","10572","1616463576"
"Sidi Brahim","Sidi Brahim","35.2606","-0.5675","Algeria","DZ","DZA","Sidi Bel Abbès","","10371","1012460249"
"Rocafuerte","Rocafuerte","-0.9200","-80.4600","Ecuador","EC","ECU","Manabí","","10574","1218553035"
"Sütçüler","Sutculer","37.4944","30.9817","Turkey","TR","TUR","Isparta","minor","10707","1792320344"
"Bondues","Bondues","50.7017","3.0933","France","FR","FRA","Hauts-de-France","","9961","1250833550"
"Göynücek","Goynucek","40.3833","35.5167","Turkey","TR","TUR","Amasya","minor","10703","1792275285"
"Geneseo","Geneseo","42.8038","-77.7783","United States","US","USA","New York","","10712","1840004422"
"San Rafael Obrajuelo","San Rafael Obrajuelo","13.5000","-88.9333","El Salvador","SV","SLV","La Paz","","9820","1222700653"
"Nahulingo","Nahulingo","13.7000","-89.7167","El Salvador","SV","SLV","Sonsonate","","10417","1222747798"
"Ponnāda","Ponnada","18.2536","83.9012","India","IN","IND","Andhra Pradesh","","8974","1356433416"
"Bakhariā","Bakharia","26.7666","84.6619","India","IN","IND","Bihār","","9373","1356407056"
"Villa Purificación","Villa Purificacion","19.7858","-104.7078","Mexico","MX","MEX","Jalisco","minor","10704","1484448303"
"Urubici","Urubici","-28.0150","-49.5919","Brazil","BR","BRA","Santa Catarina","","10699","1076702704"
"Lake Morton-Berrydale","Lake Morton-Berrydale","47.3325","-122.1032","United States","US","USA","Washington","","10709","1840074253"
"Bedum","Bedum","53.3000","6.6000","Netherlands","NL","NLD","Groningen","minor","10475","1528797716"
"Corning","Corning","42.1470","-77.0561","United States","US","USA","New York","","10708","1840000443"
"Harpur","Harpur","25.6537","86.3288","India","IN","IND","Bihār","","9328","1356083055"
"Tājpur","Tajpur","25.9022","84.5055","India","IN","IND","Bihār","","9036","1356045708"
"Nakayama","Nakayama","38.3331","140.2831","Japan","JP","JPN","Yamagata","","10617","1392891031"
"Kanteru","Kanteru","16.3906","80.5425","India","IN","IND","Andhra Pradesh","","10148","1356720379"
"Salemi","Salemi","37.8167","12.8000","Italy","IT","ITA","Sicilia","","10647","1380693933"
"North Logan","North Logan","41.7759","-111.8066","United States","US","USA","Utah","","10705","1840020114"
"Veppattūr","Veppattur","11.0154","79.4344","India","IN","IND","Tamil Nādu","","9790","1356171527"
"Rombas","Rombas","49.2494","6.0947","France","FR","FRA","Grand Est","","9861","1250716486"
"Mantasoa","Mantasoa","-19.0167","47.8333","Madagascar","MG","MDG","Antananarivo","","10604","1450107085"
"Little Canada","Little Canada","45.0244","-93.0863","United States","US","USA","Minnesota","","10704","1840008936"
"Chavusy","Chavusy","53.8075","30.9714","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","10692","1112357951"
"Sebt Ait Ikkou","Sebt Ait Ikkou","33.6690","-6.1376","Morocco","MA","MAR","Rabat-Salé-Kénitra","","10676","1504653022"
"Máncora","Mancora","-4.1056","-81.0472","Peru","PE","PER","Piura","","10547","1604011559"
"Angwāli","Angwali","23.7317","85.9858","India","IN","IND","Jhārkhand","","10178","1356183404"
"Brejolândia","Brejolandia","-12.4828","-43.9658","Brazil","BR","BRA","Bahia","","10698","1076943254"
"Tárnok","Tarnok","47.3597","18.8586","Hungary","HU","HUN","Pest","","10267","1348320388"
"Bellefontaine Neighbors","Bellefontaine Neighbors","38.7529","-90.2280","United States","US","USA","Missouri","","10702","1840007449"
"Maynard","Maynard","42.4264","-71.4561","United States","US","USA","Massachusetts","","10702","1840053481"
"Wendeburg","Wendeburg","52.3167","10.4000","Germany","DE","DEU","Lower Saxony","","10526","1276401902"
"Kollankulam","Kollankulam","8.7964","77.5681","India","IN","IND","Tamil Nādu","","9284","1356208595"
"Taufkirchen","Taufkirchen","48.3439","12.1303","Germany","DE","DEU","Bavaria","","10550","1276593079"
"Sidi Bousber","Sidi Bousber","34.5667","-5.3667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10528","1504787633"
"DeForest","DeForest","43.2301","-89.3431","United States","US","USA","Wisconsin","","10700","1840037935"
"Bāgalūr","Bagalur","12.8333","77.8667","India","IN","IND","Tamil Nādu","","10066","1356736700"
"Ahogbeya","Ahogbeya","7.0333","1.9167","Benin","BJ","BEN","Couffo","","10330","1204793788"
"Silea","Silea","45.6547","12.2967","Italy","IT","ITA","Veneto","","10163","1380124192"
"Cantley","Cantley","45.5667","-75.7833","Canada","CA","CAN","Quebec","","10699","1124000263"
"Mehdīpur","Mehdipur","25.3902","86.7324","India","IN","IND","Bihār","","9489","1356257787"
"Zapotitlán","Zapotitlan","14.1674","-89.9096","Guatemala","GT","GTM","Jutiapa","minor","10573","1320939109"
"Torre Santa Susanna","Torre Santa Susanna","40.4667","17.7333","Italy","IT","ITA","Puglia","","10510","1380320981"
"Gravatal","Gravatal","-28.3308","-49.0350","Brazil","BR","BRA","Santa Catarina","","10635","1076028862"
"Jankampet","Jankampet","18.7067","78.0233","India","IN","IND","Andhra Pradesh","","10284","1356799113"
"Antsaravibe","Antsaravibe","-13.0500","49.0167","Madagascar","MG","MDG","Antsiranana","","10648","1450567658"
"College","College","40.8144","-77.8172","United States","US","USA","Pennsylvania","","10697","1840144819"
"Dinagat","Dinagat","9.9561","125.5933","Philippines","PH","PHL","Dinagat Islands","","10621","1608566761"
"Villa Jaragua","Villa Jaragua","18.4800","-71.5000","Dominican Republic","DO","DOM","Enriquillo","","10619","1214346241"
"Peralillo","Peralillo","-34.4875","-71.4917","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","10682","1152486574"
"Inkerman","Inkerman","44.6142","33.6083","Ukraine","UA","UKR","Sevastopol, Misto","","10196","1804447396"
"Newburn","Newburn","54.9830","-1.7430","United Kingdom","GB","GBR","Gateshead","","9536","1826270642"
"Hajeb el Aïoun","Hajeb el Aioun","35.3900","9.5400","Tunisia","TN","TUN","Kairouan","","10631","1788265599"
"Urcos","Urcos","-13.6861","-71.6228","Peru","PE","PER","Cusco","","10614","1604757460"
"Nova Olinda","Nova Olinda","-7.6319","-48.4228","Brazil","BR","BRA","Tocantins","","10686","1076539934"
"Nakonde","Nakonde","-9.3272","32.7583","Zambia","ZM","ZMB","Muchinga","","10652","1894139781"
"Jucati","Jucati","-8.7058","-36.4889","Brazil","BR","BRA","Pernambuco","","10604","1076315771"
"Trovagunta","Trovagunta","15.5509","80.0589","India","IN","IND","Andhra Pradesh","","9507","1356880800"
"Russellville","Russellville","34.5055","-87.7283","United States","US","USA","Alabama","","10691","1840004247"
"Nāgasamudram","Nagasamudram","15.0556","77.5004","India","IN","IND","Andhra Pradesh","","10214","1356469097"
"Kodūru","Koduru","13.8692","77.7298","India","IN","IND","Andhra Pradesh","","10402","1356970656"
"Tafalla","Tafalla","42.5132","-1.7047","Spain","ES","ESP","Navarre","","10582","1724582472"
"Badru Khān","Badru Khan","30.2523","75.7917","India","IN","IND","Punjab","","10245","1356086328"
"Dombāchcheri","Dombachcheri","9.9604","77.3909","India","IN","IND","Tamil Nādu","","10319","1356241696"
"Hämeenkyrö","Hameenkyro","61.6333","23.2000","Finland","FI","FIN","Pirkanmaa","minor","10667","1246915870"
"Chalco","Chalco","41.1817","-96.1353","United States","US","USA","Nebraska","","10688","1840005176"
"Milton","Milton","44.6429","-73.1538","United States","US","USA","Vermont","","10688","1840070912"
"Agdz","Agdz","30.6978","-6.4497","Morocco","MA","MAR","Drâa-Tafilalet","","10681","1504481123"
"Cowra","Cowra","-33.8183","148.6578","Australia","AU","AUS","New South Wales","","10063","1036204877"
"Pirpirituba","Pirpirituba","-6.7800","-35.4989","Brazil","BR","BRA","Paraíba","","10555","1076458711"
"Baetov","Baetov","41.2100","74.9000","Kyrgyzstan","KG","KGZ","Naryn","minor","10682","1417491170"
"Duga Resa","Duga Resa","45.4472","15.4958","Croatia","HR","HRV","Karlovačka Županija","minor","10583","1191462805"
"Lohariandava","Lohariandava","-18.7833","48.6750","Madagascar","MG","MDG","Toamasina","","10670","1450037075"
"Neuenhaus","Neuenhaus","52.5000","6.9667","Germany","DE","DEU","Lower Saxony","","10356","1276154780"
"Had Laaounate","Had Laaounate","32.6128","-8.2256","Morocco","MA","MAR","Casablanca-Settat","","10580","1504308918"
"Akim Swedru","Akim Swedru","5.8940","-1.0127","Ghana","GH","GHA","Eastern","","10379","1288676050"
"Dehqonobod","Dehqonobod","40.5314","69.0342","Uzbekistan","UZ","UZB","Sirdaryo","","10500","1860018395"
"Jhabrera","Jhabrera","29.8091","77.7734","India","IN","IND","Uttarākhand","","9378","1356341833"
"El Paraíso","El Paraiso","14.0833","-89.0833","El Salvador","SV","SLV","Chalatenango","","10483","1222422397"
"Anoviara","Anoviara","-14.7333","49.8333","Madagascar","MG","MDG","Antsiranana","","10667","1450027060"
"Bālia","Balia","24.2433","75.8061","India","IN","IND","Madhya Pradesh","","10437","1356059231"
"Wepangandla","Wepangandla","15.9359","77.8417","India","IN","IND","Andhra Pradesh","","10463","1356777487"
"Duque Bacelar","Duque Bacelar","-4.1558","-42.9439","Brazil","BR","BRA","Maranhão","","10649","1076190731"
"Itaueira","Itaueira","-7.6028","-43.0258","Brazil","BR","BRA","Piauí","","10678","1076966094"
"Lake Barcroft","Lake Barcroft","38.8514","-77.1579","United States","US","USA","Virginia","","10682","1840006033"
"Aver-o-Mar","Aver-o-Mar","41.4039","-8.7690","Portugal","PT","PRT","Porto","","8961","1620327215"
"Fondettes","Fondettes","47.4042","0.5989","France","FR","FRA","Centre-Val de Loire","","10357","1250497789"
"Betsiaka","Betsiaka","-13.1500","49.2333","Madagascar","MG","MDG","Antsiranana","","10661","1450122670"
"Hilter","Hilter","52.1357","8.1471","Germany","DE","DEU","Lower Saxony","","10480","1276002004"
"Orşova","Orsova","44.7253","22.3961","Romania","RO","ROU","Mehedinţi","","10441","1642923767"
"Peschiera del Garda","Peschiera del Garda","45.4386","10.6886","Italy","IT","ITA","Veneto","","10583","1380651599"
"Merchweiler","Merchweiler","49.3603","7.0600","Germany","DE","DEU","Saarland","","9905","1276171496"
"Szigetvár","Szigetvar","46.0475","17.7994","Hungary","HU","HUN","Baranya","minor","10421","1348527343"
"Jibou","Jibou","47.2667","23.2500","Romania","RO","ROU","Sălaj","","10407","1642643434"
"Togamalai","Togamalai","10.7251","78.4108","India","IN","IND","Tamil Nādu","","10156","1356051000"
"Markt Indersdorf","Markt Indersdorf","48.3667","11.3667","Germany","DE","DEU","Bavaria","","10523","1276148696"
"Vienna","Vienna","39.3240","-81.5383","United States","US","USA","West Virginia","","10676","1840005787"
"Amtar","Amtar","35.2385","-4.7943","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10574","1504617084"
"Almoloya","Almoloya","19.7000","-98.4000","Mexico","MX","MEX","Hidalgo","minor","10638","1484494575"
"Gangania","Gangania","25.2395","86.6536","India","IN","IND","Bihār","","9777","1356672685"
"Atessa","Atessa","42.0667","14.4500","Italy","IT","ITA","Abruzzo","","10580","1380170014"
"Kilibo","Kilibo","8.5717","2.6017","Benin","BJ","BEN","Collines","","10616","1204473850"
"Burgau","Burgau","48.4322","10.4069","Germany","DE","DEU","Bavaria","","10278","1276192712"
"Phulhara","Phulhara","25.8937","86.1300","India","IN","IND","Bihār","","8949","1356399438"
"Ghusiya","Ghusiya","25.1832","84.2486","India","IN","IND","Bihār","","9736","1356612578"
"Recco","Recco","44.3667","9.1500","Italy","IT","ITA","Liguria","","9683","1380351038"
"Peñarroya-Pueblonuevo","Penarroya-Pueblonuevo","38.3000","-5.2667","Spain","ES","ESP","Andalusia","","10508","1724568918"
"Canelinha","Canelinha","-27.2650","-48.7678","Brazil","BR","BRA","Santa Catarina","","10603","1076425933"
"Chuqung","Chuqung","33.3743","97.0637","China","CN","CHN","Qinghai","minor","10659","1156003913"
"Kolárovo","Kolarovo","47.9169","17.9956","Slovakia","SK","SVK","Nitra","","10572","1703541383"
"Ardrossan","Ardrossan","55.6432","-4.8097","United Kingdom","GB","GBR","North Ayrshire","","10670","1826874237"
"Raymond","Raymond","43.0322","-71.1994","United States","US","USA","New Hampshire","","10670","1840055480"
"Lonkly","Lonkly","7.1333","1.6833","Benin","BJ","BEN","Couffo","","10445","1204106600"
"Hetane","Hetane","32.8403","-6.8025","Morocco","MA","MAR","Béni Mellal-Khénifra","","10618","1504001674"
"Zawyat Ahançal","Zawyat Ahancal","31.8325","-6.1056","Morocco","MA","MAR","Béni Mellal-Khénifra","","10657","1504237708"
"Monroe","Monroe","42.6030","-89.6381","United States","US","USA","Wisconsin","","10668","1840002464"
"Roccapiemonte","Roccapiemonte","40.7617","14.6933","Italy","IT","ITA","Campania","","8977","1380643125"
"Santa Margarita de Mombúy","Santa Margarita de Mombuy","41.5756","1.6092","Spain","ES","ESP","Catalonia","","10294","1724000140"
"Rupāna","Rupana","30.4070","74.5276","India","IN","IND","Punjab","","10058","1356046572"
"Kiến Giang","Kien Giang","17.2250","106.7917","Vietnam","VN","VNM","Quảng Bình","minor","10558","1704732074"
"Novotroitske","Novotroitske","46.3544","34.3361","Ukraine","UA","UKR","Khersonska Oblast","minor","10647","1804849889"
"Ruppichteroth","Ruppichteroth","50.8456","7.4881","Germany","DE","DEU","North Rhine-Westphalia","","10496","1276384709"
"Lyons","Lyons","41.8119","-87.8191","United States","US","USA","Illinois","","10665","1840011292"
"Castelli Calepio","Castelli Calepio","45.6333","9.9000","Italy","IT","ITA","Lombardy","","10453","1380924390"
"Paimio","Paimio","60.4500","22.7000","Finland","FI","FIN","Varsinais-Suomi","minor","10620","1246967951"
"Nelson","Nelson","49.5000","-117.2833","Canada","CA","CAN","British Columbia","","10664","1124361295"
"Knin","Knin","44.0414","16.1986","Croatia","HR","HRV","Šibensko-Kninska Županija","minor","10633","1191313799"
"Bel Air","Bel Air","39.5348","-76.3460","United States","US","USA","Maryland","","10663","1840005668"
"Weilheim an der Teck","Weilheim an der Teck","48.6150","9.5386","Germany","DE","DEU","Baden-Württemberg","","10275","1276869346"
"Tubará","Tubara","10.8667","-74.9833","Colombia","CO","COL","Atlántico","minor","10602","1170951499"
"Rincon","Rincon","32.2947","-81.2353","United States","US","USA","Georgia","","10662","1840018091"
"Ribeirão Claro","Ribeirao Claro","-23.1939","-49.7578","Brazil","BR","BRA","Paraná","","10645","1076614940"
"Cunha Porã","Cunha Pora","-26.8939","-53.1678","Brazil","BR","BRA","Santa Catarina","","10613","1076606926"
"Jandiāla","Jandiala","31.2157","75.6218","India","IN","IND","Punjab","","10170","1356063934"
"Mel Seval","Mel Seval","8.6722","77.6147","India","IN","IND","Tamil Nādu","","10269","1356201476"
"Peddāpuram","Peddapuram","18.0289","79.7269","India","IN","IND","Andhra Pradesh","","10274","1356457856"
"Querfurt","Querfurt","51.3833","11.6000","Germany","DE","DEU","Saxony-Anhalt","","10593","1276096185"
"Pelāgor","Pelagor","25.5992","87.7214","India","IN","IND","Bihār","","9328","1356260894"
"Devanakonda","Devanakonda","15.5333","77.5500","India","IN","IND","Andhra Pradesh","","10493","1356362163"
"Faxinal dos Guedes","Faxinal dos Guedes","-26.8528","-52.2600","Brazil","BR","BRA","Santa Catarina","","10649","1076672125"
"Ringkøbing","Ringkobing","56.0897","8.2383","Denmark","DK","DNK","Midtjylland","minor","9985","1208388471"
"Bovingdon","Bovingdon","51.7231","-0.5367","United Kingdom","GB","GBR","Hertfordshire","","9000","1826320957"
"Scaggsville","Scaggsville","39.1416","-76.8843","United States","US","USA","Maryland","","10658","1840024483"
"Kuiyibagecun","Kuiyibagecun","38.0836","77.1529","China","CN","CHN","Xinjiang","","10553","1156342065"
"Perumuchchi","Perumuchchi","13.0560","79.6755","India","IN","IND","Tamil Nādu","","10104","1356086268"
"Baran","Baran","54.4833","30.3333","Belarus","BY","BLR","Vitsyebskaya Voblasts’","","10573","1112544646"
"Castrolibero","Castrolibero","39.3167","16.2000","Italy","IT","ITA","Calabria","","9809","1380182426"
"Tokigawa","Tokigawa","36.0086","139.2968","Japan","JP","JPN","Saitama","","10470","1392003242"
"Flămânzi","Flamanzi","47.5500","26.8667","Romania","RO","ROU","Botoşani","","10561","1642974440"
"Priol","Priol","15.4167","73.9833","India","IN","IND","Goa","","10000","1356025914"
"Cajobi","Cajobi","-20.8800","-48.8089","Brazil","BR","BRA","São Paulo","","10596","1076127516"
"Vysokovsk","Vysokovsk","56.3167","36.5500","Russia","RU","RUS","Moskovskaya Oblast’","","10586","1643011226"
"Corocoro","Corocoro","-17.1667","-68.4500","Bolivia","BO","BOL","La Paz","","10647","1068830457"
"Las Matas de Santa Cruz","Las Matas de Santa Cruz","19.6700","-71.5000","Dominican Republic","DO","DOM","Cibao Noroeste","","10559","1214200513"
"Lagunia Raghukanth","Lagunia Raghukanth","25.8195","85.7725","India","IN","IND","Bihār","","9284","1356140452"
"Mara Rosa","Mara Rosa","-14.0169","-49.1778","Brazil","BR","BRA","Goiás","","10649","1076150786"
"Sosenskiy","Sosenskiy","54.0500","35.9667","Russia","RU","RUS","Kaluzhskaya Oblast’","","10628","1643480430"
"İnebolu","Inebolu","41.9747","33.7608","Turkey","TR","TUR","Kastamonu","minor","10594","1792688271"
"Shirako","Shirako","35.4543","140.3742","Japan","JP","JPN","Chiba","","10280","1392003500"
"Escaudain","Escaudain","50.3344","3.3428","France","FR","FRA","Hauts-de-France","","9682","1250074443"
"Zdzieszowice","Zdzieszowice","50.4192","18.1236","Poland","PL","POL","Opolskie","","10380","1616530342"
"Morbach","Morbach","49.8167","7.1167","Germany","DE","DEU","Rhineland-Palatinate","","10565","1276004908"
"Králŭv Dvŭr","Kraluv Dvur","49.9499","14.0345","Czechia","CZ","CZE","Středočeský Kraj","","9995","1203703070"
"Gudofredo Viana","Gudofredo Viana","-1.4028","-45.7800","Brazil","BR","BRA","Maranhão","","10635","1076813399"
"Filipstad","Filipstad","59.7167","14.1667","Sweden","SE","SWE","Värmland","minor","10644","1752017138"
"Rettanai","Rettanai","12.1953","79.5526","India","IN","IND","Tamil Nādu","","10117","1356111969"
"Csömör","Csomor","47.5500","19.2333","Hungary","HU","HUN","Pest","","10200","1348004899"
"Castelletto sopra Ticino","Castelletto sopra Ticino","45.7167","8.6333","Italy","IT","ITA","Piedmont","","9969","1380975237"
"Marum","Marum","53.1500","6.2667","Netherlands","NL","NLD","Groningen","minor","10488","1528447211"
"Ratnagiri","Ratnagiri","13.8111","77.1301","India","IN","IND","Andhra Pradesh","","10326","1356497114"
"Māchalpur","Machalpur","24.1277","76.3167","India","IN","IND","Madhya Pradesh","","10408","1356642940"
"Altötting","Altotting","48.2267","12.6783","Germany","DE","DEU","Bavaria","minor","10119","1276161581"
"Ironton","Ironton","38.5323","-82.6779","United States","US","USA","Ohio","","10647","1840008607"
"Difficult Run","Difficult Run","38.9016","-77.3471","United States","US","USA","Virginia","","10647","1840153061"
"Trets","Trets","43.4469","5.6858","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10500","1250294248"
"Vittuone","Vittuone","45.4833","8.9500","Italy","IT","ITA","Lombardy","","9152","1380734235"
"Conselve","Conselve","45.2333","11.8667","Italy","IT","ITA","Veneto","","10224","1380837833"
"Pakka Kalān","Pakka Kalan","30.0323","74.8555","India","IN","IND","Punjab","","10013","1356256322"
"Scotchtown","Scotchtown","41.4765","-74.3668","United States","US","USA","New York","","10644","1840004862"
"Camden","Camden","33.5672","-92.8467","United States","US","USA","Arkansas","","10644","1840013736"
"Oţelu Roşu","Otelu Rosu","45.5186","22.3528","Romania","RO","ROU","Caraş-Severin","","10510","1642740234"
"Tadas","Tadas","15.1333","75.1167","India","IN","IND","Karnātaka","","10296","1356228981"
"Mouans-Sartoux","Mouans-Sartoux","43.6200","6.9719","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9911","1250387872"
"Adigappādi","Adigappadi","12.1459","78.0946","India","IN","IND","Tamil Nādu","","10111","1356733894"
"Abay","Abay","43.2092","76.7603","Kazakhstan","KZ","KAZ","","","10439","1398792328"
"Kottaipatti","Kottaipatti","10.1533","77.7315","India","IN","IND","Tamil Nādu","","9968","1356227800"
"Vidalia","Vidalia","32.2125","-82.4019","United States","US","USA","Georgia","","10641","1840015820"
"Potavaram","Potavaram","17.0194","81.4128","India","IN","IND","Andhra Pradesh","","10130","1356256779"
"Murata","Murata","38.1185","140.7224","Japan","JP","JPN","Miyagi","","10506","1392652565"
"Hussepur","Hussepur","26.1801","84.9408","India","IN","IND","Bihār","","9082","1356496458"
"Fenglin","Fenglin","23.7500","121.4333","Taiwan","TW","TWN","Hualien","","10552","1158018865"
"Sint-Lievens-Houtem","Sint-Lievens-Houtem","50.9167","3.8667","Belgium","BE","BEL","Flanders","","10255","1056997842"
"Grevesmühlen","Grevesmuhlen","53.8667","11.1667","Germany","DE","DEU","Mecklenburg-Western Pomerania","","10440","1276634445"
"Spa","Spa","50.4925","5.8642","Belgium","BE","BEL","Wallonia","","10378","1056521041"
"Arimalam","Arimalam","10.2550","78.8840","India","IN","IND","Tamil Nādu","","10321","1356117183"
"Urrugne","Urrugne","43.3622","-1.7000","France","FR","FRA","Nouvelle-Aquitaine","","10432","1250756774"
"Khovaling","Khovaling","38.3386","69.9728","Tajikistan","TJ","TJK","Khatlon","minor","10601","1762402281"
"Cortalim","Cortalim","15.3978","73.9106","India","IN","IND","Goa","","9200","1356736301"
"Pine Hill","Pine Hill","39.7879","-74.9857","United States","US","USA","New Jersey","","10637","1840001509"
"Aïn Zora","Ain Zora","34.6600","-3.5300","Morocco","MA","MAR","Oriental","","10601","1504442402"
"Harlākhi","Harlakhi","26.6353","85.9842","India","IN","IND","Bihār","","9427","1356150964"
"Küçükkuyu","Kucukkuyu","39.5500","26.6167","Turkey","TR","TUR","Çanakkale","minor","10604","1792964648"
"Madhura","Madhura","25.5418","87.5267","India","IN","IND","Bihār","","9518","1356057650"
"Chausa","Chausa","25.5149","83.8970","India","IN","IND","Bihār","","9738","1356021388"
"Madanpur","Madanpur","24.6554","84.5844","India","IN","IND","Bihār","","9674","1356209241"
"Bobenheim-Roxheim","Bobenheim-Roxheim","49.5839","8.3611","Germany","DE","DEU","Rhineland-Palatinate","","10154","1276712928"
"Pasil","Pasil","17.3894","121.1597","Philippines","PH","PHL","Kalinga","","10577","1608891863"
"Vairampatti","Vairampatti","10.5515","78.3022","India","IN","IND","Tamil Nādu","","10244","1356416006"
"Laheji","Laheji","26.0957","84.4009","India","IN","IND","Bihār","","9199","1356708403"
"Indalvai","Indalvai","18.5403","78.2248","India","IN","IND","Andhra Pradesh","","10263","1356876515"
"Lambton Shores","Lambton Shores","43.1833","-81.9000","Canada","CA","CAN","Ontario","","10631","1124001891"
"Santiago Suchilquitongo","Santiago Suchilquitongo","17.2500","-96.8833","Mexico","MX","MEX","Oaxaca","minor","10397","1484773312"
"Al Ghizlānīyah","Al Ghizlaniyah","33.3986","36.4531","Syria","SY","SYR","Rīf Dimashq","minor","10473","1760034578"
"Pola de Lena","Pola de Lena","43.1583","-5.8292","Spain","ES","ESP","Asturias","","10596","1724170982"
"Sugarmill Woods","Sugarmill Woods","28.7299","-82.5010","United States","US","USA","Florida","","10629","1840014063"
"Isola Vicentina","Isola Vicentina","45.6333","11.4500","Italy","IT","ITA","Veneto","","10242","1380131018"
"Ingeniero White","Ingeniero White","-38.7667","-62.2667","Argentina","AR","ARG","Buenos Aires","","10486","1032437098"
"Malvern","Malvern","34.3734","-92.8205","United States","US","USA","Arkansas","","10626","1840015563"
"Tarawān","Tarawan","24.7295","85.2529","India","IN","IND","Bihār","","9328","1356360532"
"Canteras","Canteras","37.6122","-1.0438","Spain","ES","ESP","Murcia","","10167","1724341004"
"Begijnendijk","Begijnendijk","51.0186","4.7850","Belgium","BE","BEL","Flanders","","10053","1056560278"
"Souq Sebt Says","Souq Sebt Says","32.7773","-8.6433","Morocco","MA","MAR","Casablanca-Settat","","10488","1504725186"
"Toundout","Toundout","31.2667","-6.5833","Morocco","MA","MAR","Drâa-Tafilalet","","10606","1504645333"
"Mokrisset","Mokrisset","34.9100","-5.3536","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10545","1504941621"
"Höchberg","Hochberg","49.7831","9.8817","Germany","DE","DEU","Bavaria","","9379","1276298566"
"Forest Acres","Forest Acres","34.0323","-80.9716","United States","US","USA","South Carolina","","10621","1840013653"
"Parczew","Parczew","51.6333","22.8667","Poland","PL","POL","Lubelskie","minor","10519","1616266918"
"Maevka","Maevka","42.9250","74.5694","Kyrgyzstan","KG","KGZ","Chüy","","10535","1417086380"
"Mussomeli","Mussomeli","37.5794","13.7525","Italy","IT","ITA","Sicilia","","10556","1380692742"
"Leninaul","Leninaul","43.0878","46.5631","Russia","RU","RUS","Dagestan","","10532","1643688109"
"Sālehpur","Salehpur","25.6119","87.2973","India","IN","IND","Bihār","","9546","1356242586"
"Riverton","Riverton","43.0421","-108.4144","United States","US","USA","Wyoming","","10619","1840020026"
"Motibennur","Motibennur","14.7150","75.4800","India","IN","IND","Karnātaka","","10310","1356357981"
"Vouzela","Vouzela","40.7167","-8.0833","Portugal","PT","PRT","Viseu","minor","10564","1620776313"
"Krishnamsettipalle","Krishnamsettipalle","15.3695","78.8840","India","IN","IND","Andhra Pradesh","","10492","1356808069"
"Gölpazarı","Golpazari","40.2847","30.3172","Turkey","TR","TUR","Bilecik","minor","10591","1792001027"
"Candelaria Loxicha","Candelaria Loxicha","15.9262","-96.4914","Mexico","MX","MEX","Oaxaca","minor","10497","1484628588"
"Ozieri","Ozieri","40.5849","9.0033","Italy","IT","ITA","Sardegna","","10575","1380060538"
"Bariariya Tola Rājpur","Bariariya Tola Rajpur","26.4567","84.7043","India","IN","IND","Bihār","","9159","1356051150"
"Ekangar Sarai","Ekangar Sarai","25.2234","85.2298","India","IN","IND","Bihār","","8735","1356331561"
"Monnickendam","Monnickendam","52.4667","5.0333","Netherlands","NL","NLD","Noord-Holland","minor","10316","1528187463"
"Chandankiāri","Chandankiari","23.5781","86.3598","India","IN","IND","West Bengal","","9760","1356084280"
"Pārtibanūr","Partibanur","9.5855","78.4545","India","IN","IND","Tamil Nādu","","10232","1356746876"
"Jablanica","Jablanica","43.6583","17.7583","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","10580","1070524039"
"Mold","Mold","53.1660","-3.1330","United Kingdom","GB","GBR","Flintshire","","10058","1826650301"
"Nuriston","Nuriston","38.4892","65.6464","Uzbekistan","UZ","UZB","Qashqadaryo","","10500","1860752520"
"Holmen","Holmen","43.9706","-91.2654","United States","US","USA","Wisconsin","","10613","1840002576"
"Prineville","Prineville","44.2985","-120.8607","United States","US","USA","Oregon","","10611","1840019999"
"Doraville","Doraville","33.9073","-84.2711","United States","US","USA","Georgia","","10611","1840014778"
"Tourza","Tourza","29.4778","-10.0158","Morocco","MA","MAR","Guelmim-Oued Noun","","10577","1504097455"
"Kimpese","Kimpese","-5.5631","14.4458","Congo (Kinshasa)","CD","COD","Kongo Central","","10578","1180273961"
"Vadakku Ariyanāyakipuram","Vadakku Ariyanayakipuram","8.7208","77.5450","India","IN","IND","Tamil Nādu","","10218","1356201450"
"Biskupiec","Biskupiec","53.8667","20.9667","Poland","PL","POL","Warmińsko-Mazurskie","","10539","1616213985"
"Aerzen","Aerzen","52.0496","9.2638","Germany","DE","DEU","Lower Saxony","","10510","1276826554"
"Peschanokopskoye","Peschanokopskoye","46.1958","41.0778","Russia","RU","RUS","Rostovskaya Oblast’","minor","10593","1643956352"
"Kamikawa","Kamikawa","35.0642","134.7397","Japan","JP","JPN","Hyōgo","","10557","1392003365"
"Khilok","Khilok","51.3500","110.4500","Russia","RU","RUS","Zabaykal’skiy Kray","","10607","1643397911"
"Schalksmühle","Schalksmuhle","51.2403","7.5333","Germany","DE","DEU","North Rhine-Westphalia","","10341","1276387569"
"Gommern","Gommern","52.0667","11.8333","Germany","DE","DEU","Saxony-Anhalt","","10543","1276399095"
"Tarazona de Aragón","Tarazona de Aragon","41.9044","-1.7225","Spain","ES","ESP","Aragon","","10565","1724796233"
"Monte San Juan","Monte San Juan","13.7667","-88.9500","El Salvador","SV","SLV","Cuscatlán","","10224","1222428135"
"Ludlow","Ludlow","52.3680","-2.7180","United Kingdom","GB","GBR","Shropshire","","10266","1826562344"
"Malaya Vishera","Malaya Vishera","58.8500","32.2333","Russia","RU","RUS","Novgorodskaya Oblast’","","10602","1643002504"
"Satwār","Satwar","26.2163","84.5120","India","IN","IND","Bihār","","8824","1356105027"
"Meerhout","Meerhout","51.1333","5.0833","Belgium","BE","BEL","Flanders","","10324","1056144149"
"Kambaliyampatti","Kambaliyampatti","10.3671","78.1233","India","IN","IND","Tamil Nādu","","9963","1356216667"
"Pāra","Para","23.5142","86.4980","India","IN","IND","West Bengal","","9697","1356401794"
"Sweetwater","Sweetwater","32.4692","-100.4092","United States","US","USA","Texas","","10605","1840022089"
"Cajapió","Cajapio","-2.9667","-44.8000","Brazil","BR","BRA","Rio Grande do Norte","","10593","1076410776"
"Bibbiano","Bibbiano","44.6667","10.4667","Italy","IT","ITA","Emilia-Romagna","","10241","1380476344"
"Sadovoye","Sadovoye","42.8528","74.1722","Kyrgyzstan","KG","KGZ","Chüy","","10545","1417165744"
"Yacuanquer","Yacuanquer","1.1167","-77.4000","Colombia","CO","COL","Nariño","minor","10579","1170229796"
"Pinto","Pinto","-36.7000","-71.9000","Chile","CL","CHL","Ñuble","","10595","1152171336"
"Hartford","Hartford","43.6644","-72.3865","United States","US","USA","Vermont","","10604","1840070579"
"Konkavāripalle","Konkavaripalle","13.7125","78.4861","India","IN","IND","Andhra Pradesh","","10342","1356227566"
"Aït Hani","Ait Hani","31.7786","-5.4555","Morocco","MA","MAR","Drâa-Tafilalet","","10587","1504963024"
"Pulpí","Pulpi","37.4019","-1.7508","Spain","ES","ESP","Andalusia","","10493","1724725882"
"Ghosrāwān","Ghosrawan","25.0910","85.5780","India","IN","IND","Bihār","","9427","1356492760"
"Alīpura","Alipura","25.1753","79.3356","India","IN","IND","Madhya Pradesh","","10292","1356128424"
"Tnine Sidi Lyamani","Tnine Sidi Lyamani","35.3700","-5.9700","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10365","1504028221"
"Mildenhall","Mildenhall","52.3446","0.5089","United Kingdom","GB","GBR","Suffolk","","10315","1826551750"
"Hanson","Hanson","42.0558","-70.8723","United States","US","USA","Massachusetts","","10601","1840053679"
"Selma","Selma","29.5866","-98.3132","United States","US","USA","Texas","","10601","1840022221"
"Santa Teresinha","Santa Teresinha","-12.7719","-39.5228","Brazil","BR","BRA","Bahia","","10586","1076693294"
"Garden City","Garden City","33.5926","-79.0071","United States","US","USA","South Carolina","","10600","1840013645"
"Ambohitromby","Ambohitromby","-18.4333","47.1500","Madagascar","MG","MDG","Antananarivo","","10560","1450098921"
"Ambolotarakely","Ambolotarakely","-18.2667","47.4000","Madagascar","MG","MDG","Antananarivo","","10560","1450049599"
"Rokunohe","Rokunohe","40.6095","141.3248","Japan","JP","JPN","Aomori","","10473","1392003347"
"Wustermark","Wustermark","52.5497","12.9497","Germany","DE","DEU","Brandenburg","","10401","1276932359"
"Jurbise","Jurbise","50.5333","3.9333","Belgium","BE","BEL","Wallonia","","10417","1056563634"
"Jiménez","Jimenez","10.1797","-83.7382","Costa Rica","CR","CRI","Limón","","10501","1188944993"
"Plombières","Plombieres","50.7333","5.9500","Belgium","BE","BEL","Wallonia","","10401","1056147295"
"Imst","Imst","47.2394","10.7381","Austria","AT","AUT","Tirol","minor","10504","1040504243"
"Pia","Pia","42.7447","2.9208","France","FR","FRA","Occitanie","","9849","1250196191"
"Walker Mill","Walker Mill","38.8758","-76.8854","United States","US","USA","Maryland","","10596","1840031499"
"Rignano Flaminio","Rignano Flaminio","42.2000","12.4833","Italy","IT","ITA","Lazio","","10328","1380240196"
"Songo","Songo","-7.3496","14.8500","Angola","AO","AGO","Uíge","","10579","1024923411"
"La Ferté-sous-Jouarre","La Ferte-sous-Jouarre","48.9492","3.1294","France","FR","FRA","Île-de-France","","9637","1250168754"
"Excelsior Springs","Excelsior Springs","39.3390","-94.2400","United States","US","USA","Missouri","","10595","1840008495"
"Lavínia","Lavinia","-21.1683","-51.0397","Brazil","BR","BRA","São Paulo","","10590","1076751331"
"Evander","Evander","-26.4719","29.1100","South Africa","ZA","ZAF","Mpumalanga","","10166","1710356044"
"Beverly Hills","Beverly Hills","42.5220","-83.2423","United States","US","USA","Michigan","","10593","1840011052"
"Kanyāna","Kanyana","12.9000","75.0300","India","IN","IND","Karnātaka","","9976","1356183073"
"Armanāz","Armanaz","36.0833","36.5000","Syria","SY","SYR","Idlib","minor","10296","1760438666"
"Villanueva de Arosa","Villanueva de Arosa","42.5628","-8.8278","Spain","ES","ESP","Galicia","","10306","1724595746"
"Boiling Springs","Boiling Springs","35.0450","-81.9779","United States","US","USA","South Carolina","","10591","1840013509"
"Wahlstedt","Wahlstedt","53.9500","10.2167","Germany","DE","DEU","Schleswig-Holstein","","9958","1276000715"
"Patsanda","Patsanda","24.8580","86.3008","India","IN","IND","Bihār","","9837","1356316787"
"Borgampād","Borgampad","17.6500","80.8667","India","IN","IND","Andhra Pradesh","","10235","1356917535"
"Strehaia","Strehaia","44.6222","23.1972","Romania","RO","ROU","Mehedinţi","","10506","1642350779"
"Suganwān","Suganwan","25.1176","85.0022","India","IN","IND","Bihār","","9073","1356060657"
"Atlapadu","Atlapadu","16.7833","81.9000","India","IN","IND","Andhra Pradesh","","9723","1356405036"
"Korosavāda","Korosavada","18.7256","84.0573","India","IN","IND","Andhra Pradesh","","10096","1356108143"
"Lejanías","Lejanias","3.5268","-74.0232","Colombia","CO","COL","Meta","minor","10576","1170478276"
"Čelić","Celic","44.7167","18.8167","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","10502","1070994771"
"Nanticoke","Nanticoke","41.2005","-76.0003","United States","US","USA","Pennsylvania","","10588","1840000761"
"Potunūru","Potunuru","16.7442","81.2206","India","IN","IND","Andhra Pradesh","","10224","1356131123"
"Ouando","Ouando","6.5542","2.6616","Benin","BJ","BEN","Ouémé","","8712","1204175156"
"Mfou","Mfou","3.9600","11.9300","Cameroon","CM","CMR","Centre","","10533","1120739101"
"Kursaha","Kursaha","25.5588","85.6875","India","IN","IND","Bihār","","8680","1356149795"
"Modisi","Modisi","0.4517","124.4367","Indonesia","ID","IDN","Sulawesi Utara","","10543","1360013481"
"Ban Ueam","Ban Ueam","18.4246","99.4434","Thailand","TH","THA","Lampang","","10401","1764673656"
"Ascope","Ascope","-7.7138","-79.1077","Peru","PE","PER","La Libertad","","10560","1604239326"
"Tiztoutine","Tiztoutine","34.9833","-3.1500","Morocco","MA","MAR","Oriental","","9788","1504907240"
"Basla","Basla","33.3833","73.3167","Pakistan","PK","PAK","Punjab","","10000","1586287583"
"Millington","Millington","35.3350","-89.8991","United States","US","USA","Tennessee","","10583","1840014593"
"Nossen","Nossen","51.0500","13.3000","Germany","DE","DEU","Saxony","","10498","1276123820"
"Bangshang","Bangshang","32.2575","108.1119","China","CN","CHN","Sichuan","","10561","1156017438"
"Barcs","Barcs","45.9601","17.4601","Hungary","HU","HUN","Somogy","minor","10501","1348676786"
"Scottdale","Scottdale","33.7950","-84.2634","United States","US","USA","Georgia","","10582","1840013700"
"Vejen","Vejen","55.4774","9.1379","Denmark","DK","DNK","Syddanmark","minor","10009","1208664706"
"Dumri","Dumri","25.8605","84.5512","India","IN","IND","Bihār","","8910","1356624511"
"Castelnovo ne’ Monti","Castelnovo ne' Monti","44.4333","10.4000","Italy","IT","ITA","Emilia-Romagna","","10473","1380855804"
"Agramonte","Agramonte","22.6761","-81.1156","Cuba","CU","CUB","Matanzas","","10502","1192726778"
"Mohanpur","Mohanpur","25.5507","85.3222","India","IN","IND","Bihār","","9410","1356777413"
"Sawla","Sawla","9.2833","-2.4167","Ghana","GH","GHA","Savannah","","10555","1288207822"
"Khagaur","Khagaur","25.1744","86.1021","India","IN","IND","Bihār","","8935","1356107760"
"São Tiago","Sao Tiago","-20.9128","-44.5089","Brazil","BR","BRA","Minas Gerais","","10561","1076477896"
"Bom Jesus da Serra","Bom Jesus da Serra","-14.3719","-40.5039","Brazil","BR","BRA","Bahia","","10554","1076590196"
"Doorn","Doorn","52.0333","5.3500","Netherlands","NL","NLD","Utrecht","minor","10124","1528210177"
"Timrå","Timra","62.4869","17.3258","Sweden","SE","SWE","Västernorrland","minor","10443","1752299610"
"Alcora","Alcora","40.0667","-0.2000","Spain","ES","ESP","Valencia","","10468","1724288303"
"Vadapalanji","Vadapalanji","9.9266","78.0096","India","IN","IND","Tamil Nādu","","8817","1356236912"
"Westphalia","Westphalia","38.8356","-76.8298","United States","US","USA","Maryland","","10578","1840024549"
"El Álamo","El Alamo","40.2306","-3.9944","Spain","ES","ESP","Madrid","","10123","1724385981"
"Ben N’Choud","Ben N'Choud","36.8624","3.8806","Algeria","DZ","DZA","Boumerdes","","9985","1012044380"
"Whitnash","Whitnash","52.2680","-1.5240","United Kingdom","GB","GBR","Warwickshire","","10489","1826439036"
"Montividiu","Montividiu","-17.4439","-51.1750","Brazil","BR","BRA","Goiás","","10572","1076252838"
"Naganuma","Naganuma","43.0102","141.6951","Japan","JP","JPN","Hokkaidō","","10515","1392210651"
"Mādhopur","Madhopur","26.7474","84.7054","India","IN","IND","Bihār","","9240","1356984737"
"Valpoy","Valpoy","15.5324","74.1367","India","IN","IND","Goa","","10450","1356065506"
"West Hanover","West Hanover","40.3635","-76.7468","United States","US","USA","Pennsylvania","","10577","1840150556"
"Rāmpur","Rampur","25.1826","86.1465","India","IN","IND","Bihār","","9612","1356817448"
"Ambahy","Ambahy","-20.7667","48.4833","Madagascar","MG","MDG","Fianarantsoa","","10469","1450922020"
"Forestville","Forestville","38.8518","-76.8708","United States","US","USA","Maryland","","10575","1840005942"
"Asārhi","Asarhi","25.2892","85.2189","India","IN","IND","Bihār","","9286","1356500098"
"La Bañeza","La Baneza","42.2975","-5.9017","Spain","ES","ESP","Castille-Leon","","10068","1724574131"
"Raymondville","Raymondville","26.4759","-97.7769","United States","US","USA","Texas","","10574","1840021035"
"Eugenópolis","Eugenopolis","-21.0989","-42.1869","Brazil","BR","BRA","Minas Gerais","","10540","1076538883"
"Sirsa","Sirsa","26.2667","84.7403","India","IN","IND","Bihār","","9548","1356551713"
"Lepākshi","Lepakshi","13.8032","77.6097","India","IN","IND","Andhra Pradesh","","10042","1356664957"
"Dallgow-Döberitz","Dallgow-Doberitz","52.5331","13.0500","Germany","DE","DEU","Brandenburg","","10415","1276604293"
"Petmanhalli","Petmanhalli","17.7688","77.5056","India","IN","IND","Andhra Pradesh","","10043","1356047444"
"River Grove","River Grove","41.9243","-87.8379","United States","US","USA","Illinois","","10571","1840011285"
"Madhopur","Madhopur","26.7333","85.0500","India","IN","IND","Bihār","","9527","1356036494"
"Hirehadagalli","Hirehadagalli","14.9267","75.8322","India","IN","IND","Karnātaka","","10350","1356923875"
"Santa Maria de Itabira","Santa Maria de Itabira","-19.4489","-43.1128","Brazil","BR","BRA","Minas Gerais","","10552","1076857591"
"Usmat Shaharchasi","Usmat Shaharchasi","39.7386","67.6433","Uzbekistan","UZ","UZB","Jizzax","minor","10500","1860775970"
"Ban Bang Phlap","Ban Bang Phlap","13.9241","100.4684","Thailand","TH","THA","Nonthaburi","","9434","1764411697"
"Umarizal","Umarizal","-5.9908","-37.8139","Brazil","BR","BRA","Rio Grande do Norte","","10520","1076582699"
"Lowell","Lowell","41.2917","-87.4195","United States","US","USA","Indiana","","10569","1840009260"
"Douglass","Douglass","40.3438","-75.5909","United States","US","USA","Pennsylvania","","10569","1840143118"
"Pebble Creek","Pebble Creek","28.1583","-82.3411","United States","US","USA","Florida","","10569","1840028995"
"Cottage Grove","Cottage Grove","43.7960","-123.0573","United States","US","USA","Oregon","","10569","1840018624"
"Rasebetsane","Rasebetsane","-29.8992","27.6628","Lesotho","LS","LSO","Mafeteng","","10519","1426189375"
"Tilougguit","Tilougguit","32.0333","-6.2000","Morocco","MA","MAR","Béni Mellal-Khénifra","","10544","1504513715"
"Kujri","Kujri","26.2392","87.6366","India","IN","IND","Bihār","","9505","1356149801"
"Bacliff","Bacliff","29.5085","-94.9888","United States","US","USA","Texas","","10567","1840018273"
"Nanzhou","Nanzhou","22.4789","120.5156","Taiwan","TW","TWN","Pingtung","","10038","1158433128"
"Hōki","Hoki","35.3852","133.4073","Japan","JP","JPN","Tottori","","10491","1392003272"
"Khesht","Khesht","29.5636","51.3369","Iran","IR","IRN","Fārs","","10508","1364677033"
"Borjomi","Borjomi","41.8389","43.3792","Georgia","GE","GEO","Samtskhe-Javakheti","","10546","1268485704"
"Kadama","Kadama","1.0167","33.8803","Uganda","UG","UGA","Kibuku","","10000","1800484552"
"Coleford","Coleford","51.7910","-2.6162","United Kingdom","GB","GBR","Gloucestershire","","9273","1826833780"
"Navabad","Navabad","38.5278","68.6750","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","10200","1762138739"
"Chitagá","Chitaga","7.1333","-72.6667","Colombia","CO","COL","Norte de Santander","minor","10554","1170356842"
"Rialma","Rialma","-15.3150","-49.5839","Brazil","BR","BRA","Goiás","","10523","1076379241"
"Borger","Borger","52.9236","6.7922","Netherlands","NL","NLD","Drenthe","","10469","1528847849"
"Augustdorf","Augustdorf","51.9094","8.7317","Germany","DE","DEU","North Rhine-Westphalia","","10317","1276232704"
"Rebola","Rebola","3.7192","8.8531","Equatorial Guinea","GQ","GNQ","Bioko Norte","","10000","1226355446"
"Santa Teresa di Riva","Santa Teresa di Riva","37.9400","15.3625","Italy","IT","ITA","Sicilia","","9403","1380661750"
"Luanco","Luanco","43.6100","-5.7900","Spain","ES","ESP","Asturias","","10433","1724203007"
"Gonghaur","Gonghaur","26.4346","86.2160","India","IN","IND","Bihār","","9444","1356557260"
"Vermilion","Vermilion","41.4103","-82.3214","United States","US","USA","Ohio","","10560","1840010234"
"Kendall Park","Kendall Park","40.4135","-74.5631","United States","US","USA","New Jersey","","10560","1840005419"
"Marotaolana","Marotaolana","-14.0167","48.6167","Madagascar","MG","MDG","Antsiranana","","10538","1450914081"
"Skilloúnta","Skillounta","37.6167","21.6333","Greece","GR","GRC","Dytikí Elláda","","10525","1300958571"
"Ickenham","Ickenham","51.5580","-0.4484","United Kingdom","GB","GBR","Hillingdon","","10387","1826334339"
"Pazaryeri","Pazaryeri","40.0000","29.9000","Turkey","TR","TUR","Bilecik","minor","10527","1792621607"
"Douar Mzoura","Douar Mzoura","34.3167","-5.9333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","10194","1504336708"
"Serra do Salitre","Serra do Salitre","-19.1108","-46.6900","Brazil","BR","BRA","Minas Gerais","","10549","1076188853"
"Rosario","Rosario","-34.3139","-57.3525","Uruguay","UY","URY","Colonia","","9311","1858160603"
"Dombasle-sur-Meurthe","Dombasle-sur-Meurthe","48.6250","6.3497","France","FR","FRA","Grand Est","","9694","1250827581"
"Kharahara","Kharahara","24.9142","87.0006","India","IN","IND","Bihār","","9291","1356834681"
"Sujāpur","Sujapur","25.4815","87.4435","India","IN","IND","Bihār","","9325","1356498115"
"Singanamane","Singanamane","13.7167","75.6333","India","IN","IND","Karnātaka","","10084","1356185657"
"Belgrade","Belgrade","45.7789","-111.1736","United States","US","USA","Montana","","10555","1840018508"
"Mallapuram","Mallapuram","9.8280","77.6730","India","IN","IND","Tamil Nādu","","10276","1356213661"
"Jesenice","Jesenice","49.9683","14.5136","Czechia","CZ","CZE","Středočeský Kraj","","9997","1203216192"
"Kandulāpuram","Kandulapuram","15.5976","79.1365","India","IN","IND","Andhra Pradesh","","10212","1356995894"
"Mahīnāthpur","Mahinathpur","26.6554","86.0674","India","IN","IND","Bihār","","9761","1356035553"
"Haripura","Haripura","34.0410","74.7923","India","IN","IND","Jammu and Kashmīr","","9088","1356077768"
"Talugai","Talugai","11.3752","78.4822","India","IN","IND","Tamil Nādu","","10096","1356257990"
"Altenholz","Altenholz","54.4000","10.1333","Germany","DE","DEU","Schleswig-Holstein","","10024","1276597876"
"Montalegre","Montalegre","41.8231","-7.7917","Portugal","PT","PRT","Vila Real","minor","10537","1620504484"
"Frontera","Frontera","-31.4278","-62.0619","Argentina","AR","ARG","Córdoba","","10520","1032791892"
"Bad Orb","Bad Orb","50.2167","9.3500","Germany","DE","DEU","Hesse","","10333","1276166672"
"Villacarrillo","Villacarrillo","38.1000","-3.0833","Spain","ES","ESP","Andalusia","","10545","1724531932"
"Paricônia","Pariconia","-9.2539","-38.0058","Brazil","BR","BRA","Alagoas","","10539","1076791574"
"Veitshöchheim","Veitshochheim","49.8328","9.8817","Germany","DE","DEU","Bavaria","","9652","1276315080"
"Bordj Okhriss","Bordj Okhriss","36.0833","3.9667","Algeria","DZ","DZA","Bouira","","10467","1012057188"
"Rupahi","Rupahi","26.4110","92.7400","India","IN","IND","Assam","","9307","1356845033"
"Bluffton","Bluffton","40.7424","-85.1730","United States","US","USA","Indiana","","10548","1840007178"
"Partanna","Partanna","37.7289","12.8894","Italy","IT","ITA","Sicilia","","10422","1380661937"
"Zhatay","Zhatay","62.1641","129.8431","Russia","RU","RUS","Sakha (Yakutiya)","","10089","1643098394"
"Novodnistrovsk","Novodnistrovsk","48.5778","27.4414","Ukraine","UA","UKR","Chernivetska Oblast","","10463","1804548880"
"Campi Salentina","Campi Salentina","40.4000","18.0167","Italy","IT","ITA","Puglia","","10322","1380290099"
"Mount Airy","Mount Airy","36.5083","-80.6155","United States","US","USA","North Carolina","","10547","1840014430"
"Sumner","Sumner","47.2189","-122.2338","United States","US","USA","Washington","","10545","1840021128"
"Torotoro","Torotoro","-18.1342","-65.7633","Bolivia","BO","BOL","Potosí","","10535","1068711275"
"Kamifurano","Kamifurano","43.4556","142.4672","Japan","JP","JPN","Hokkaidō","","10500","1392709435"
"Herīs","Heris","38.2467","47.1181","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","10515","1364732749"
"Blandford Forum","Blandford Forum","50.8560","-2.1650","United Kingdom","GB","GBR","Dorset","","10355","1826579765"
"Ban Thung Khao Phuang","Ban Thung Khao Phuang","19.5342","98.9621","Thailand","TH","THA","Chiang Mai","","10500","1764832106"
"Arceburgo","Arceburgo","-21.3639","-46.9400","Brazil","BR","BRA","Minas Gerais","","10478","1076719559"
"Galimuyod","Galimuyod","17.1833","120.4667","Philippines","PH","PHL","Ilocos Sur","","10244","1608931989"
"La Virgen","La Virgen","10.4312","-84.0772","Costa Rica","CR","CRI","Heredia","","10521","1188953313"
"Argelès-sur-Mer","Argeles-sur-Mer","42.5461","3.0239","France","FR","FRA","Occitanie","","10366","1250999270"
"Trescore Balneario","Trescore Balneario","45.7000","9.8500","Italy","IT","ITA","Lombardy","","9814","1380130245"
"Cutro","Cutro","39.0328","16.9825","Italy","IT","ITA","Calabria","","10462","1380662035"
"Zmeinogorsk","Zmeinogorsk","51.1667","82.2000","Russia","RU","RUS","Altayskiy Kray","","10533","1643913373"
"Reiskirchen","Reiskirchen","50.6000","8.8333","Germany","DE","DEU","Hesse","","10310","1276387894"
"Bad Lauterberg","Bad Lauterberg","51.6317","10.4706","Germany","DE","DEU","Lower Saxony","","10290","1276934554"
"Kilkunda","Kilkunda","11.2569","76.6697","India","IN","IND","Tamil Nādu","","10150","1356523989"
"Magalia","Magalia","39.8228","-121.6078","United States","US","USA","California","","10537","1840017530"
"Tepperumālnallūr","Tepperumalnallur","10.9694","79.4254","India","IN","IND","Tamil Nādu","","9480","1356173030"
"Litomyšl","Litomysl","49.8719","16.3106","Czechia","CZ","CZE","Pardubický Kraj","","10240","1203430500"
"Martin","Martin","36.3385","-88.8513","United States","US","USA","Tennessee","","10536","1840015282"
"Arraias","Arraias","-12.9308","-46.9378","Brazil","BR","BRA","Tocantins","","10534","1076635792"
"Altenberge","Altenberge","52.0458","7.4653","Germany","DE","DEU","North Rhine-Westphalia","","10371","1276349933"
"Koila Belwā","Koila Belwa","26.3753","84.9986","India","IN","IND","Bihār","","9090","1356744175"
"Miribel","Miribel","45.8244","4.9531","France","FR","FRA","Auvergne-Rhône-Alpes","","10122","1250853320"
"Dona Inês","Dona Ines","-6.6178","-35.6319","Brazil","BR","BRA","Paraíba","","10456","1076268862"
"Kihō","Kiho","33.7333","136.0000","Japan","JP","JPN","Mie","","10404","1392003381"
"Kibichūō","Kibichuo","34.8634","133.6935","Japan","JP","JPN","Okayama","","10495","1392003132"
"Wilkau-Haßlau","Wilkau-Hasslau","50.6667","12.5167","Germany","DE","DEU","Saxony","","9784","1276704667"
"Madhurāpur","Madhurapur","25.9456","85.9759","India","IN","IND","Bihār","","9015","1356863895"
"Karghar","Karghar","25.1267","83.9274","India","IN","IND","Bihār","","9835","1356462654"
"Sortland","Sortland","68.6982","15.4138","Norway","NO","NOR","Nordland","minor","10514","1578250336"
"Andonabe Atsimo","Andonabe Atsimo","-19.8833","48.2167","Madagascar","MG","MDG","Toamasina","","10503","1450506198"
"San Vicente de Castellet","San Vicente de Castellet","41.6655","1.8641","Spain","ES","ESP","Catalonia","","9949","1724504704"
"Mamdāpur","Mamdapur","16.1400","74.8100","India","IN","IND","Karnātaka","","10079","1356021243"
"Ibiassucê","Ibiassuce","-14.2589","-42.2569","Brazil","BR","BRA","Bahia","","10502","1076462026"
"Lighthouse Point","Lighthouse Point","26.2785","-80.0891","United States","US","USA","Florida","","10529","1840015998"
"Novi Banovci","Novi Banovci","44.9500","20.2833","Serbia","RS","SRB","","","9443","1688687724"
"Concordia Sagittaria","Concordia Sagittaria","45.7667","12.8500","Italy","IT","ITA","Veneto","","10373","1380975617"
"Perket","Perket","18.7942","78.3068","India","IN","IND","Andhra Pradesh","","9764","1356618521"
"Peravali","Peravali","15.2861","77.4997","India","IN","IND","Andhra Pradesh","","10363","1356970840"
"Arlesheim","Arlesheim","47.4922","7.6203","Switzerland","CH","CHE","Basel-Landschaft","","9202","1756790806"
"Avelgem","Avelgem","50.7753","3.4442","Belgium","BE","BEL","Flanders","","10065","1056175400"
"Bagnara Calabra","Bagnara Calabra","38.2833","15.8167","Italy","IT","ITA","Calabria","","10120","1380612708"
"Angostura","Angostura","6.8667","-75.3333","Colombia","CO","COL","Antioquia","minor","10500","1170569077"
"Sotkamo","Sotkamo","64.1333","28.3833","Finland","FI","FIN","Kainuu","minor","10523","1246600504"
"Alachua","Alachua","29.7779","-82.4832","United States","US","USA","Florida","","10527","1840014020"
"Mapleton","Mapleton","43.7358","-80.6681","Canada","CA","CAN","Ontario","","10527.00","1124000835"
"Baisa","Baisa","25.3552","86.7246","India","IN","IND","Bihār","","9317","1356257803"
"Dnestrovsc","Dnestrovsc","46.6167","29.9167","Moldova","MD","MDA","Stînga Nistrului","","10436","1498610361"
"Brahmānandapuram","Brahmanandapuram","16.9583","82.0056","India","IN","IND","Andhra Pradesh","","9974","1356128302"
"Māldah","Maldah","25.1740","85.7599","India","IN","IND","Bihār","","8920","1356022894"
"Soyaux","Soyaux","45.6403","0.1978","France","FR","FRA","Nouvelle-Aquitaine","","9761","1250934595"
"Bīrpur Bārāpatti Pindraun","Birpur Barapatti Pindraun","26.5475","86.3898","India","IN","IND","Bihār","","9831","1356692198"
"Sidi Abdallah","Sidi Abdallah","32.5783","-7.8108","Morocco","MA","MAR","Marrakech-Safi","","10481","1504890231"
"Fayzobod","Fayzobod","38.5500","69.3167","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","10400","1762452678"
"Shumanay","Shumanay","42.6333","58.9167","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","","10513","1860689478"
"Sangão","Sangao","-28.6378","-49.1289","Brazil","BR","BRA","Santa Catarina","","10400","1076740744"
"Reading","Reading","39.2242","-84.4333","United States","US","USA","Ohio","","10525","1840001611"
"Appenweier","Appenweier","48.5397","7.9800","Germany","DE","DEU","Baden-Württemberg","","10256","1276074686"
"Bāgh-e Bahādorān","Bagh-e Bahadoran","32.3772","51.1883","Iran","IR","IRN","Eşfahān","","10279","1364002073"
"Lake Hopatcong","Lake Hopatcong","40.9599","-74.6094","United States","US","USA","New Jersey","","10521","1840145177"
"Maxaranguape","Maxaranguape","-5.5158","-35.2619","Brazil","BR","BRA","Rio Grande do Norte","","10441","1076459990"
"Whitwick","Whitwick","52.7403","-1.3556","United Kingdom","GB","GBR","Leicestershire","","8612","1826669578"
"Acushnet","Acushnet","41.7138","-70.9012","United States","US","USA","Massachusetts","","10520","1840131572"
"El Chol","El Chol","14.9611","-90.4878","Guatemala","GT","GTM","Baja Verapaz","minor","10445","1320103256"
"Tonse East","Tonse East","13.3963","74.7217","India","IN","IND","Karnātaka","","9896","1356639374"
"Matias Olímpio","Matias Olimpio","-3.7158","-42.5558","Brazil","BR","BRA","Piauí","","10473","1076360856"
"Rhosllanerchrugog","Rhosllanerchrugog","53.0110","-3.0520","United Kingdom","GB","GBR","Wrexham","","9694","1826735616"
"Congonhal","Congonhal","-22.1528","-46.0389","Brazil","BR","BRA","Minas Gerais","","10468","1076374543"
"Albert","Albert","50.0019","2.6522","France","FR","FRA","Hauts-de-France","","9810","1250793960"
"Garešnica","Garesnica","45.5667","16.9333","Croatia","HR","HRV","Bjelovarsko-Bilogorska Županija","minor","10472","1191726833"
"Höchst im Odenwald","Hochst im Odenwald","49.7992","8.9942","Germany","DE","DEU","Hesse","","10185","1276437736"
"Dandkhora","Dandkhora","25.5729","87.6472","India","IN","IND","Bihār","","9651","1356461583"
"Khurmi","Khurmi","39.5167","67.5833","Tajikistan","TJ","TJK","Sughd","","10451","1762313500"
"Barāgaon","Baragaon","24.5682","79.0185","India","IN","IND","Madhya Pradesh","","10194","1356042472"
"Nānan","Nanan","25.0905","85.5031","India","IN","IND","Bihār","","8875","1356073589"
"Greentree","Greentree","39.8989","-74.9613","United States","US","USA","New Jersey","","10517","1840033472"
"Maduvanalli","Maduvanalli","12.1500","77.1000","India","IN","IND","Karnātaka","","10383","1356069320"
"Pérols","Perols","43.5650","3.9506","France","FR","FRA","Occitanie","","9016","1250071000"
"Jessup","Jessup","39.1488","-76.7772","United States","US","USA","Maryland","","10516","1840005919"
"Regidor","Regidor","8.6667","-73.8333","Colombia","CO","COL","Bolívar","minor","10489","1170335895"
"Vaals","Vaals","50.7667","6.0167","Netherlands","NL","NLD","Limburg","minor","10092","1528584480"
"San Giorgio del Sannio","San Giorgio del Sannio","41.0667","14.8500","Italy","IT","ITA","Campania","","10064","1380560872"
"Prakhon Chai","Prakhon Chai","14.6092","103.0818","Thailand","TH","THA","Buri Ram","minor","10404","1764447694"
"Sādiqpur Maraul","Sadiqpur Maraul","25.9966","85.5777","India","IN","IND","Bihār","","9418","1356773141"
"Valdobbiadene","Valdobbiadene","45.9000","11.9167","Italy","IT","ITA","Veneto","","10349","1380856258"
"Paravāda","Paravada","17.6283","83.0793","India","IN","IND","Andhra Pradesh","","9802","1356382983"
"Jettihalli","Jettihalli","12.0800","78.1193","India","IN","IND","Tamil Nādu","","9889","1356304777"
"Dinnington","Dinnington","53.3667","-1.2000","United Kingdom","GB","GBR","Rotherham","","9077","1826308364"
"Sinaia","Sinaia","45.3500","25.5514","Romania","RO","ROU","Prahova","","10410","1642021189"
"Siruvalūr","Siruvalur","11.3600","77.4575","India","IN","IND","Tamil Nādu","","9890","1356142549"
"Bolekhiv","Bolekhiv","49.0669","23.8514","Ukraine","UA","UKR","Ivano-Frankivska Oblast","","10476","1804748946"
"Susuz","Susuz","40.7800","43.1353","Turkey","TR","TUR","Kars","minor","10497","1792746871"
"Gaunivāripalle","Gaunivaripalle","13.9756","77.8768","India","IN","IND","Andhra Pradesh","","10272","1356605250"
"Schwaikheim","Schwaikheim","48.8714","9.3531","Germany","DE","DEU","Baden-Württemberg","","9481","1276194197"
"Phai Sali","Phai Sali","15.6000","100.6494","Thailand","TH","THA","Nakhon Sawan","minor","10450","1764690719"
"Pilar","Pilar","9.8639","126.1008","Philippines","PH","PHL","Surigao del Norte","","10374","1608555528"
"Pleasanton","Pleasanton","28.9642","-98.4957","United States","US","USA","Texas","","10508","1840020988"
"Virginópolis","Virginopolis","-18.8228","-42.7039","Brazil","BR","BRA","Minas Gerais","","10484","1076474874"
"Kennett","Kennett","36.2403","-90.0481","United States","US","USA","Missouri","","10507","1840008809"
"Bithlo","Bithlo","28.5644","-81.1073","United States","US","USA","Florida","","10507","1840014083"
"Likiškiai","Likiskiai","54.3950","23.9970","Lithuania","LT","LTU","Alytus","","10489","1440824158"
"Dar El Kebdani","Dar El Kebdani","35.1203","-3.3322","Morocco","MA","MAR","Oriental","","9911","1504332737"
"Altofonte","Altofonte","38.0500","13.3000","Italy","IT","ITA","Sicilia","","10218","1380191830"
"Kirlampūdi","Kirlampudi","17.1919","82.1825","India","IN","IND","Andhra Pradesh","","9609","1356313374"
"Najrīj","Najrij","30.9667","30.8667","Egypt","EG","EGY","Al Gharbīyah","","8656","1818420168"
"Old Saybrook","Old Saybrook","41.3017","-72.3857","United States","US","USA","Connecticut","","10506","1840045060"
"Mahārājapuram","Maharajapuram","9.6588","77.6631","India","IN","IND","Tamil Nādu","","10033","1356218426"
"Barnāon","Barnaon","25.4809","84.5250","India","IN","IND","Bihār","","9258","1356084376"
"Kirchzarten","Kirchzarten","47.9667","7.9500","Germany","DE","DEU","Baden-Württemberg","","10030","1276548951"
"Lāpangā","Lapanga","23.6333","85.3770","India","IN","IND","Jhārkhand","","9708","1356189882"
"Natuba","Natuba","-7.6408","-35.5500","Brazil","BR","BRA","Paraíba","","10450","1076608325"
"Maria Enzersdorf","Maria Enzersdorf","48.1000","16.2833","Austria","AT","AUT","Niederösterreich","","8829","1040889264"
"Saks","Saks","33.7118","-85.8536","United States","US","USA","Alabama","","10504","1840013708"
"Nova Europa","Nova Europa","-21.7783","-48.5608","Brazil","BR","BRA","São Paulo","","10439","1076407128"
"Inungūr","Inungur","10.8507","78.4889","India","IN","IND","Tamil Nādu","","9983","1356226645"
"Vaddādi","Vaddadi","17.8474","82.8729","India","IN","IND","Andhra Pradesh","","10113","1356586218"
"Ban Dan Na Kham","Ban Dan Na Kham","17.7167","100.1333","Thailand","TH","THA","Uttaradit","","10329","1764004017"
"Thilogne","Thilogne","15.9239","-13.6578","Senegal","SN","SEN","Matam","","10440","1686563185"
"Bom Repouso","Bom Repouso","-22.4708","-46.1450","Brazil","BR","BRA","Minas Gerais","","10457","1076352065"
"Port Wentworth","Port Wentworth","32.1951","-81.1991","United States","US","USA","Georgia","","10502","1840014915"
"Ayntap","Ayntap","40.0986","44.4681","Armenia","AM","ARM","Ararat","","9550","1051408099"
"Palestina","Palestina","1.7500","-76.0667","Colombia","CO","COL","Huila","minor","10454","1170209006"
"Jefferson","Jefferson","29.9609","-90.1554","United States","US","USA","Louisiana","","10500","1840013986"
"Fuveau","Fuveau","43.4522","5.5617","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10161","1250220268"
"Valley","Valley","32.8088","-85.1810","United States","US","USA","Alabama","","10498","1840015760"
"Fatehābād","Fatehabad","31.3811","75.0996","India","IN","IND","Punjab","","9992","1356555001"
"Lahstedt","Lahstedt","52.2500","10.2167","Germany","DE","DEU","Lower Saxony","","10262","1276001876"
"San Vendemiano","San Vendemiano","45.8914","12.3389","Italy","IT","ITA","Veneto","","9958","1380606267"
"Topoloveni","Topoloveni","44.8069","25.0839","Romania","RO","ROU","Argeş","","10219","1642642782"
"Newberry","Newberry","34.2813","-81.6010","United States","US","USA","South Carolina","","10496","1840014694"
"Bad Wildbad","Bad Wildbad","48.7503","8.5506","Germany","DE","DEU","Baden-Württemberg","","10397","1276260316"
"Florestópolis","Florestopolis","-22.8628","-51.3869","Brazil","BR","BRA","Paraná","","10453","1076726162"
"Praia do Carvoeiro","Praia do Carvoeiro","37.1200","-8.4600","Portugal","PT","PRT","Faro","","9987","1620666686"
"Villamarchante","Villamarchante","39.5678","-0.6225","Spain","ES","ESP","Valencia","","10348","1724153603"
"Sidi Boushab","Sidi Boushab","30.0740","-9.2790","Morocco","MA","MAR","Souss-Massa","","10438","1504333059"
"Oneida","Oneida","43.0769","-75.6622","United States","US","USA","New York","","10493","1840000384"
"Iramaia","Iramaia","-13.2858","-40.9508","Brazil","BR","BRA","Bahia","","10487","1076713749"
"Huittinen","Huittinen","61.1750","22.7000","Finland","FI","FIN","Satakunta","minor","10473","1246269907"
"Ivangorod","Ivangorod","59.3667","28.2167","Russia","RU","RUS","Leningradskaya Oblast’","","10453","1643468545"
"Langhirano","Langhirano","44.6167","10.2667","Italy","IT","ITA","Emilia-Romagna","","10346","1380734640"
"Española","Espanola","36.0044","-106.0686","United States","US","USA","New Mexico","","10492","1840020348"
"Lyngdal","Lyngdal","58.1333","7.0833","Norway","NO","NOR","Agder","minor","10480","1578630529"
"Rāparla","Raparla","15.9569","80.8703","India","IN","IND","Andhra Pradesh","","10131","1356450895"
"Kannamangalam","Kannamangalam","12.7499","79.1518","India","IN","IND","Tamil Nādu","","9657","1356039630"
"Lyuboml’","Lyuboml'","51.2158","24.0408","Ukraine","UA","UKR","Volynska Oblast","","10466","1804570446"
"Marlton","Marlton","39.9014","-74.9294","United States","US","USA","New Jersey","","10491","1840005522"
"Olëkminsk","Olekminsk","60.3667","120.4167","Russia","RU","RUS","Sakha (Yakutiya)","","10490","1643265838"
"Bērikai","Berikai","12.8056","77.9710","India","IN","IND","Tamil Nādu","","9855","1356651556"
"Heum","Heum","59.0955","10.1040","Norway","NO","NOR","Vestfold og Telemark","","10449","1578756875"
"Karahallı","Karahalli","38.3167","29.5167","Turkey","TR","TUR","Uşak","minor","10457","1792156765"
"Blackwells Mills","Blackwells Mills","40.4773","-74.5976","United States","US","USA","New Jersey","","10488","1840033455"
"Chākand","Chakand","24.8907","84.9761","India","IN","IND","Bihār","","8677","1356620332"
"Steinfeld","Steinfeld","52.6000","8.2167","Germany","DE","DEU","Lower Saxony","","10316","1276800312"
"Wildberg","Wildberg","48.6239","8.7472","Germany","DE","DEU","Baden-Württemberg","","10306","1276603722"
"Bellmead","Bellmead","31.6026","-97.0897","United States","US","USA","Texas","","10487","1840019531"
"Pitkyaranta","Pitkyaranta","61.5750","31.4778","Russia","RU","RUS","Kareliya","","10479","1643011862"
"Rishivandiyam","Rishivandiyam","11.8170","79.1000","India","IN","IND","Tamil Nādu","","10138","1356339006"
"Wittenbach","Wittenbach","47.4667","9.3795","Switzerland","CH","CHE","Sankt Gallen","","9691","1756489674"
"Forestville","Forestville","39.0711","-84.3389","United States","US","USA","Ohio","","10485","1840034110"
"North Bellport","North Bellport","40.7868","-72.9457","United States","US","USA","New York","","10484","1840005056"
"Douar Echbanat","Douar Echbanat","34.2167","-5.3500","Morocco","MA","MAR","Fès-Meknès","","10371","1504340049"
"Costeşti","Costesti","44.6697","24.8800","Romania","RO","ROU","Argeş","","10375","1642679241"
"Emsworth","Emsworth","50.8490","-0.9380","United Kingdom","GB","GBR","Hampshire","","9492","1826718241"
"Rāmpur Khajuriyā","Rampur Khajuriya","26.3923","84.7688","India","IN","IND","Bihār","","9024","1356219195"
"Gossau","Gossau","47.3081","8.7567","Switzerland","CH","CHE","Zürich","","9937","1756178316"
"Königsbach-Stein","Konigsbach-Stein","48.9664","8.6089","Germany","DE","DEU","Baden-Württemberg","","10179","1276050894"
"Corzuela","Corzuela","-26.9333","-60.9667","Argentina","AR","ARG","Chaco","minor","10470","1032587821"
"Etropole","Etropole","42.8333","24.0000","Bulgaria","BG","BGR","Sofia","","10394","1100554574"
"Karkamış","Karkamis","36.8340","37.9990","Turkey","TR","TUR","Gaziantep","minor","10436","1792055477"
"Colonia Nicolich","Colonia Nicolich","-34.8167","-56.0167","Uruguay","UY","URY","Canelones","","9624","1858537250"
"Nanfang","Nanfang","23.3568","115.5167","China","CN","CHN","Guangdong","","10180","1156039854"
"Georgian Bluffs","Georgian Bluffs","44.6500","-81.0333","Canada","CA","CAN","Ontario","","10479","1124001470"
"Bajwāra","Bajwara","31.5150","75.9494","India","IN","IND","Punjab","","9921","1356172127"
"Samalpur","Samalpur","25.1961","88.0419","India","IN","IND","West Bengal","","9250","1356286381"
"Luís Alves","Luis Alves","-26.7208","-48.9328","Brazil","BR","BRA","Santa Catarina","","10438","1076000985"
"Somireddipalle","Somireddipalle","14.8365","78.9062","India","IN","IND","Andhra Pradesh","","10268","1356074440"
"Pintuyan","Pintuyan","9.9500","125.2500","Philippines","PH","PHL","Southern Leyte","","10202","1608530375"
"Alcaudete","Alcaudete","37.5833","-4.1000","Spain","ES","ESP","Andalusia","","10434","1724011838"
"Klipphausen","Klipphausen","51.0833","13.5333","Germany","DE","DEU","Saxony","","10384","1276821311"
"Adyār","Adyar","12.8756","74.9232","India","IN","IND","Karnātaka","","8581","1356001747"
"Jomasho‘y","Jomasho\`y","40.8633","71.4597","Uzbekistan","UZ","UZB","Namangan","","10100","1860251898"
"Puerto Santander","Puerto Santander","8.3636","-72.4075","Colombia","CO","COL","Norte de Santander","minor","10249","1170636572"
"Jaltocan","Jaltocan","21.1333","-98.5383","Mexico","MX","MEX","Hidalgo","","10265","1484352270"
"Kangning","Kangning","38.0176","102.3520","China","CN","CHN","Gansu","","10300","1156030066"
"Minobu","Minobu","35.4675","138.4424","Japan","JP","JPN","Yamanashi","","10440","1392309481"
"Hachīrūd","Hachirud","36.6864","51.3447","Iran","IR","IRN","Māzandarān","","10398","1364613179"
"Olevsk","Olevsk","51.2278","27.6481","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","10457","1804287361"
"Aradeo","Aradeo","40.1333","18.1333","Italy","IT","ITA","Puglia","","9381","1380959582"
"Pembroke Dock","Pembroke Dock","51.6933","-4.9458","United Kingdom","GB","GBR","Pembrokeshire","","9753","1826264078"
"Altusried","Altusried","47.8000","10.2167","Germany","DE","DEU","Bavaria","","10361","1276723201"
"Aulendorf","Aulendorf","47.9542","9.6389","Germany","DE","DEU","Baden-Württemberg","","10277","1276668317"
"Essex Junction","Essex Junction","44.4902","-73.1141","United States","US","USA","Vermont","","10473","1840004083"
"Carmaux","Carmaux","44.0492","2.1581","France","FR","FRA","Occitanie","","9782","1250416433"
"Salanso","Salanso","12.1833","-4.0833","Burkina Faso","BF","BFA","Boucle du Mouhoun","minor","10385","1854892822"
"Minnāmpalli","Minnampalli","11.6758","78.2676","India","IN","IND","Tamil Nādu","","9918","1356225075"
"Niebüll","Niebull","54.7881","8.8296","Germany","DE","DEU","Schleswig-Holstein","","10139","1276213521"
"Somers Point","Somers Point","39.3167","-74.6066","United States","US","USA","New Jersey","","10470","1840001537"
"Annavāsal","Annavasal","10.4667","78.7000","India","IN","IND","Tamil Nādu","","10107","1356149826"
"Bujari","Bujari","-9.8308","-67.9519","Brazil","BR","BRA","Acre","","10420","1076224328"
"Campton Hills","Campton Hills","41.9498","-88.4166","United States","US","USA","Illinois","","10468","1840000495"
"Trumbull Center","Trumbull Center","41.2415","-73.1836","United States","US","USA","Connecticut","","10468","1840153084"
"Tomblaine","Tomblaine","48.6856","6.2117","France","FR","FRA","Grand Est","","8872","1250817352"
"Neuenhof","Neuenhof","47.4469","8.3292","Switzerland","CH","CHE","Aargau","","8824","1756512493"
"Frouzins","Frouzins","43.5161","1.3247","France","FR","FRA","Occitanie","","9292","1250825720"
"Koranampatti","Koranampatti","11.6085","77.9049","India","IN","IND","Tamil Nādu","","9559","1356168823"
"Bogen","Bogen","48.9167","12.6833","Germany","DE","DEU","Bavaria","","10263","1276422463"
"Corcuera","Corcuera","12.8000","122.0500","Philippines","PH","PHL","Romblon","","10112","1608239865"
"Roelofarendsveen","Roelofarendsveen","52.2000","4.6333","Netherlands","NL","NLD","Zuid-Holland","minor","10070","1528595898"
"Manakana","Manakana","-19.8167","48.3667","Madagascar","MG","MDG","Toamasina","","10437","1450745180"
"Irineópolis","Irineopolis","-26.2389","-50.8000","Brazil","BR","BRA","Santa Catarina","","10448","1076790066"
"Tangermünde","Tangermunde","52.5408","11.9689","Germany","DE","DEU","Saxony-Anhalt","","10350","1276714669"
"Fairmont","Fairmont","43.6441","-94.4621","United States","US","USA","Minnesota","","10465","1840007950"
"Shenjiaba","Shenjiaba","32.9441","108.6414","China","CN","CHN","Shaanxi","","10413","1156797657"
"Isua","Isua","25.2330","85.8430","India","IN","IND","Bihār","","9295","1356464933"
"Dobhāwān","Dobhawan","25.3987","85.7856","India","IN","IND","Bihār","","9595","1356424924"
"Türkan","Turkan","40.3639","50.2150","Azerbaijan","AZ","AZE","Bakı","","10108","1031514825"
"Takkali","Takkali","8.2461","77.3186","India","IN","IND","Tamil Nādu","","9336","1356217022"
"Sidi Bou Ali","Sidi Bou Ali","35.9561","10.4722","Tunisia","TN","TUN","Sousse","","10282","1788097463"
"Dharmavaram","Dharmavaram","18.2164","83.8856","India","IN","IND","Andhra Pradesh","","9919","1356129246"
"Le Mars","Le Mars","42.7810","-96.1733","United States","US","USA","Iowa","","10462","1840008022"
"Santiago","Santiago","-14.1892","-75.7126","Peru","PE","PER","Ica","","10449","1604889626"
"Felling","Felling","54.9500","-1.5640","United Kingdom","GB","GBR","Gateshead","","8908","1826214509"
"Mörlenbach","Morlenbach","49.5990","8.7369","Germany","DE","DEU","Hesse","","10091","1276069994"
"Saint-Grégoire","Saint-Gregoire","48.1511","-1.6861","France","FR","FRA","Bretagne","","9890","1250834963"
"Àrvorezinha","Arvorezinha","-28.8719","-52.1750","Brazil","BR","BRA","Rio Grande do Sul","","10423","1076700097"
"Kangaroo Flat","Kangaroo Flat","-36.7833","144.2330","Australia","AU","AUS","Victoria","","10394","1036769935"
"Haikoucun","Haikoucun","28.3237","120.0853","China","CN","CHN","Zhejiang","","10368","1156373607"
"Loudoun Valley Estates","Loudoun Valley Estates","38.9770","-77.5053","United States","US","USA","Virginia","","10460","1840041775"
"Sudogda","Sudogda","55.9500","40.8667","Russia","RU","RUS","Vladimirskaya Oblast’","","10442","1643470978"
"Neuenstadt am Kocher","Neuenstadt am Kocher","49.2333","9.3333","Germany","DE","DEU","Baden-Württemberg","","10211","1276757079"
"Kothi","Kothi","24.7526","80.7775","India","IN","IND","Madhya Pradesh","","10177","1356193547"
"Bogué","Bogue","16.5904","-14.2700","Mauritania","MR","MRT","Brakna","","10415","1478095096"
"Vellatūru","Vellaturu","16.1209","79.6508","India","IN","IND","Andhra Pradesh","","10347","1356282921"
"Isabela","Isabela","18.4991","-67.0220","Puerto Rico","PR","PRI","Puerto Rico","","10457","1630023674"
"Burtonsville","Burtonsville","39.1166","-76.9356","United States","US","USA","Maryland","","10457","1840005827"
"Rāoke Kalān","Raoke Kalan","30.8219","75.1828","India","IN","IND","Punjab","","9997","1356765621"
"Karor","Karor","25.6018","86.0930","India","IN","IND","Bihār","","8899","1356115524"
"Zuyevka","Zuyevka","58.4000","51.1500","Russia","RU","RUS","Kirovskaya Oblast’","","10447","1643984570"
"Sucupira do Norte","Sucupira do Norte","-6.4769","-44.1919","Brazil","BR","BRA","Maranhão","","10444","1076904924"
"Pôrto Firme","Porto Firme","-20.6728","-43.0839","Brazil","BR","BRA","Minas Gerais","","10417","1076023873"
"Sūndekuppam","Sundekuppam","12.4567","78.2125","India","IN","IND","Tamil Nādu","","9960","1356245943"
"Oudewater","Oudewater","52.0167","4.8667","Netherlands","NL","NLD","Utrecht","minor","10201","1528852092"
"Khiram","Khiram","33.7320","75.1600","India","IN","IND","Jammu and Kashmīr","","10307","1356818085"
"Brikama Ba","Brikama Ba","13.5333","-14.9333","The Gambia","GM","GMB","Central River","","10343","1270974319"
"Granbury","Granbury","32.4475","-97.7702","United States","US","USA","Texas","","10453","1840020749"
"Sylva","Sylva","57.3139","58.7889","Russia","RU","RUS","Sverdlovskaya Oblast’","","10448","1643485406"
"Trabia","Trabia","38.0000","13.6500","Italy","IT","ITA","Sicilia","","10430","1380880595"
"Brikcha","Brikcha","34.9667","-5.5833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10399","1504666840"
"Năsăud","Nasaud","47.2833","24.4067","Romania","RO","ROU","Bistriţa-Năsăud","","10215","1642277064"
"Kiso","Kiso","35.9363","137.7831","Japan","JP","JPN","Nagano","","10429","1392003119"
"Roche-la-Molière","Roche-la-Moliere","45.4339","4.3236","France","FR","FRA","Auvergne-Rhône-Alpes","","9884","1250206871"
"Alagarai","Alagarai","10.9826","78.3891","India","IN","IND","Tamil Nādu","","9781","1356223718"
"Mungod","Mungod","17.0667","79.0667","India","IN","IND","Andhra Pradesh","","10141","1356844377"
"Reddipalle","Reddipalle","14.1993","78.0102","India","IN","IND","Andhra Pradesh","","10266","1356116933"
"Cittanova","Cittanova","38.3500","16.0833","Italy","IT","ITA","Calabria","","10284","1380916081"
"Achchippatti","Achchippatti","10.6989","77.0150","India","IN","IND","Tamil Nādu","","9831","1356170278"
"Kourani","Kourani","-11.8511","43.4261","Comoros","KM","COM","Grande Comore","","10000","1174935601"
"Phon Charoen","Phon Charoen","18.0258","103.7094","Thailand","TH","THA","Bueng Kan","minor","10357","1764018147"
"Ivankiv","Ivankiv","50.9328","29.9047","Ukraine","UA","UKR","Kyivska Oblast","","10439","1804378280"
"Oulad Slim","Oulad Slim","32.7775","-7.7725","Morocco","MA","MAR","Casablanca-Settat","","10410","1504468299"
"Mawai","Mawai","24.8043","78.9388","India","IN","IND","Madhya Pradesh","","10124","1356692122"
"Muhammadganj","Muhammadganj","26.1506","87.0792","India","IN","IND","Bihār","","9412","1356077001"
"Kottapālem","Kottapalem","16.5787","79.8756","India","IN","IND","Andhra Pradesh","","10194","1356172054"
"Sovata","Sovata","46.5961","25.0744","Romania","RO","ROU","Mureş","","10385","1642965657"
"Erraballa","Erraballa","14.3971","78.1744","India","IN","IND","Andhra Pradesh","","9993","1356007553"
"Sidi Rahhal","Sidi Rahhal","31.6667","-7.4833","Morocco","MA","MAR","Marrakech-Safi","","9906","1504761731"
"Pierrelaye","Pierrelaye","49.0225","2.1503","France","FR","FRA","Île-de-France","","9423","1250307197"
"Dougba","Dougba","8.4497","2.4737","Benin","BJ","BEN","Collines","","10387","1204867484"
"Villasāgar","Villasagar","18.4736","78.9645","India","IN","IND","Andhra Pradesh","","10137","1356280098"
"Gages Lake","Gages Lake","42.3519","-87.9828","United States","US","USA","Illinois","","10445","1840004638"
"Conceição dos Ouros","Conceicao dos Ouros","-22.4128","-45.7978","Brazil","BR","BRA","Minas Gerais","","10388","1076803809"
"Fanjā’","Fanja'","23.4675","58.1061","Oman","OM","OMN","Ad Dākhilīyah","","10396","1512768698"
"Gauli Palāsiya","Gauli Palasiya","22.5323","75.7275","India","IN","IND","Madhya Pradesh","","10043","1356362157"
"Cunupia","Cunupia","10.5500","-61.3667","Trinidad and Tobago","TT","TTO","Chaguanas","","8768","1780256527"
"Warr Acres","Warr Acres","35.5285","-97.6182","United States","US","USA","Oklahoma","","10443","1840021743"
"Schwaig","Schwaig","49.4692","11.2008","Germany","DE","DEU","Bavaria","","8926","1276904945"
"Farmersville","Farmersville","36.3050","-119.2083","United States","US","USA","California","","10441","1840020366"
"Lachen","Lachen","47.1833","8.8500","Switzerland","CH","CHE","Schwyz","minor","8754","1756890037"
"Masindi Port","Masindi Port","1.6983","32.0778","Uganda","UG","UGA","Kiryandongo","","10400","1800188907"
"Māli","Mali","25.6549","86.7275","India","IN","IND","Bihār","","9019","1356048608"
"Kalanak","Kalanak","39.0833","70.5000","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","10411","1762067649"
"Santa Fé","Santa Fe","-23.0378","-51.8050","Brazil","BR","BRA","Paraná","","10432","1076690587"
"Fairview","Fairview","45.5469","-122.4390","United States","US","USA","Oregon","","10439","1840019938"
"Flat Rock","Flat Rock","42.0991","-83.2716","United States","US","USA","Michigan","","10439","1840003973"
"Srīrēmapuram","Sriremapuram","16.8600","81.9900","India","IN","IND","Andhra Pradesh","","9277","1356256475"
"Lake Monticello","Lake Monticello","37.9210","-78.3295","United States","US","USA","Virginia","","10438","1840006342"
"Semmarikulan","Semmarikulan","8.4815","77.9972","India","IN","IND","Tamil Nādu","","9733","1356215719"
"São Gonçalo do Pará","Sao Goncalo do Para","-19.9828","-44.8589","Brazil","BR","BRA","Minas Gerais","","10398","1076000814"
"Großburgwedel","Grossburgwedel","52.4933","9.8586","Germany","DE","DEU","Lower Saxony","","10306","1276104367"
"Edwards","Edwards","39.6215","-106.6184","United States","US","USA","Colorado","","10436","1840017535"
"Silvārpatti","Silvarpatti","10.4473","77.9086","India","IN","IND","Tamil Nādu","","9793","1356246139"
"Balikumbat","Balikumbat","5.8928","10.3597","Cameroon","CM","CMR","North-West","","10163","1120000865"
"Shirbadgi","Shirbadgi","16.0417","75.9433","India","IN","IND","Karnātaka","","10176","1356212437"
"Dihri","Dihri","25.3796","85.5376","India","IN","IND","Bihār","","9138","1356069789"
"Tansandra","Tansandra","13.0554","77.6342","India","IN","IND","Karnātaka","","9975","1356605610"
"Awfouss","Awfouss","31.6833","-4.1667","Morocco","MA","MAR","Drâa-Tafilalet","","10424","1504561866"
"Krasnoilsk","Krasnoilsk","48.0167","25.5833","Ukraine","UA","UKR","Chernivetska Oblast","","10351","1804367722"
"Quinto di Treviso","Quinto di Treviso","45.6500","12.1667","Italy","IT","ITA","Veneto","","9912","1380847513"
"Rāmannapeta","Ramannapeta","17.2833","79.1000","India","IN","IND","Andhra Pradesh","","10202","1356079915"
"Ridgefield","Ridgefield","45.8114","-122.7051","United States","US","USA","Washington","","10432","1840019909"
"Nevelsk","Nevelsk","46.6833","141.8667","Russia","RU","RUS","Sakhalinskaya Oblast’","","10421","1643719954"
"Bomporto","Bomporto","44.7333","11.0333","Italy","IT","ITA","Emilia-Romagna","","10170","1380316410"
"Coroneo","Coroneo","20.1333","-100.3333","Mexico","MX","MEX","Guanajuato","minor","10347","1484498281"
"Manalūrpettai","Manalurpettai","12.0079","79.0918","India","IN","IND","Tamil Nādu","","9769","1356810810"
"Ontario","Ontario","43.2408","-77.3140","United States","US","USA","New York","","10430","1840058362"
"Baraçlândia","Baraclandia","-7.2050","-47.7569","Brazil","BR","BRA","Tocantins","","10424","1076591909"
"Dan","Dan","7.3167","2.0667","Benin","BJ","BEN","Zou","","10356","1204916794"
"Kragerø","Kragero","58.8869","9.3469","Norway","NO","NOR","Vestfold og Telemark","minor","10416","1578172535"
"Prestonpans","Prestonpans","55.9597","-2.9610","United Kingdom","GB","GBR","East Lothian","","10410","1826462891"
"Heath","Heath","40.0241","-82.4413","United States","US","USA","Ohio","","10429","1840001418"
"Īdupugallu","Idupugallu","16.4609","80.7543","India","IN","IND","Andhra Pradesh","","8764","1356920399"
"Molango","Molango","20.7844","-98.7175","Mexico","MX","MEX","Hidalgo","","10385","1484340479"
"Myers Corner","Myers Corner","41.5864","-73.8793","United States","US","USA","New York","","10427","1840004742"
"Volpago del Montello","Volpago del Montello","45.7833","12.1167","Italy","IT","ITA","Veneto","","10199","1380093991"
"Modavāndisatyamangalam","Modavandisatyamangalam","11.2635","77.7285","India","IN","IND","Tamil Nādu","","9291","1356169600"
"Tiddas","Tiddas","33.5665","-6.2659","Morocco","MA","MAR","Rabat-Salé-Kénitra","","10047","1504837627"
"Bāghīn","Baghin","30.1811","56.8019","Iran","IR","IRN","Kermān","","10407","1364307046"
"Beni Oulid","Beni Oulid","34.5897","-4.4514","Morocco","MA","MAR","Fès-Meknès","","10324","1504501654"
"Danville","Danville","39.7584","-86.5019","United States","US","USA","Indiana","","10424","1840009539"
"Chinchinim","Chinchinim","15.2000","73.9700","India","IN","IND","Goa","","9283","1356510315"
"Roseira","Roseira","-22.8978","-45.3050","Brazil","BR","BRA","São Paulo","","10344","1076731583"
"Tha Muang","Tha Muang","13.9611","99.6411","Thailand","TH","THA","Kanchanaburi","minor","10219","1764391380"
"Scituate","Scituate","41.7926","-71.6203","United States","US","USA","Rhode Island","","10423","1840106234"
"Brumath","Brumath","48.7319","7.7083","France","FR","FRA","Grand Est","","10081","1250341449"
"Waimea","Waimea","20.0124","-155.6381","United States","US","USA","Hawaii","","10422","1840137122"
"Devarāpalle","Devarapalle","17.9886","82.9811","India","IN","IND","Andhra Pradesh","","9397","1356033644"
"Aydıncık","Aydincik","40.1167","35.2667","Turkey","TR","TUR","Yozgat","minor","10407","1792442482"
"Eerbeek","Eerbeek","52.1053","6.0633","Netherlands","NL","NLD","Gelderland","","9845","1528788806"
"Ban Klang","Ban Klang","18.5791","99.0687","Thailand","TH","THA","Lamphun","","10143","1764274690"
"Limoux","Limoux","43.0569","2.2186","France","FR","FRA","Occitanie","minor","10112","1250442739"
"Werlte","Werlte","52.8500","7.6833","Germany","DE","DEU","Lower Saxony","","10260","1276655654"
"Wabash","Wabash","40.8034","-85.8301","United States","US","USA","Indiana","","10420","1840010335"
"Agatogba","Agatogba","6.4000","1.9000","Benin","BJ","BEN","Mono","","9758","1204332185"
"Vedurupāvalūru","Vedurupavaluru","16.5519","80.7309","India","IN","IND","Andhra Pradesh","","9923","1356325276"
"Corral de Bustos","Corral de Bustos","-33.2833","-62.2000","Argentina","AR","ARG","Córdoba","","10407","1032324190"
"Agua Blanca Iturbide","Agua Blanca Iturbide","20.3500","-98.3500","Mexico","MX","MEX","Hidalgo","","10313","1484459690"
"Portet-sur-Garonne","Portet-sur-Garonne","43.5219","1.4061","France","FR","FRA","Occitanie","","9812","1250647294"
"Plaridel","Plaridel","13.9511","122.0203","Philippines","PH","PHL","Quezon","","10129","1608849599"
"Pirapetinga","Pirapetinga","-21.6558","-42.3458","Brazil","BR","BRA","Minas Gerais","","10364","1076957244"
"Basāha","Basaha","26.1329","86.7480","India","IN","IND","Bihār","","8907","1356260333"
"Nāgulapādu","Nagulapadu","16.3090","80.3710","India","IN","IND","Andhra Pradesh","","9820","1356015000"
"Machchand","Machchand","26.3241","79.0543","India","IN","IND","Madhya Pradesh","","10098","1356180663"
"Planura","Planura","-20.1378","-48.7019","Brazil","BR","BRA","Minas Gerais","","10384","1076475868"
"Merzenich","Merzenich","50.8262","6.5267","Germany","DE","DEU","North Rhine-Westphalia","","10149","1276724272"
"Waterford","Waterford","39.7415","-74.8207","United States","US","USA","New Jersey","","10416","1840081681"
"Rawdon","Rawdon","46.0500","-73.7167","Canada","CA","CAN","Quebec","","10416","1124084263"
"Pella","Pella","41.4052","-92.9189","United States","US","USA","Iowa","","10414","1840009293"
"Amurrio","Amurrio","43.0528","-3.0014","Spain","ES","ESP","Basque Country","","10307","1724144899"
"São José do Calçado","Sao Jose do Calcado","-21.0250","-41.6539","Brazil","BR","BRA","Espírito Santo","","10408","1076530906"
"Anröchte","Anrochte","51.5667","8.3333","Germany","DE","DEU","North Rhine-Westphalia","","10275","1276004303"
"Dhāntola","Dhantola","26.2016","88.1095","India","IN","IND","West Bengal","","9503","1356003782"
"Baldeogarh","Baldeogarh","24.7562","79.0672","India","IN","IND","Madhya Pradesh","","10033","1356979188"
"Plattekill","Plattekill","41.6478","-74.0718","United States","US","USA","New York","","10413","1840058407"
"Pūmalakkundu","Pumalakkundu","9.8871","77.4498","India","IN","IND","Tamil Nādu","","9373","1356243267"
"Halgūr","Halgur","12.2500","77.1300","India","IN","IND","Karnātaka","","10176","1356976826"
"Emsbüren","Emsburen","52.3925","7.2914","Germany","DE","DEU","Lower Saxony","","10338","1276520111"
"Lingampet","Lingampet","18.2383","78.1303","India","IN","IND","Andhra Pradesh","","9860","1356911464"
"Bordighera","Bordighera","43.7833","7.6667","Italy","IT","ITA","Liguria","","10412","1380382900"
"Hamilton","Hamilton","-37.7333","142.0167","Australia","AU","AUS","Victoria","","9974","1036699883"
"Garden Acres","Garden Acres","37.9637","-121.2296","United States","US","USA","California","","10411","1840028376"
"Campbellton","Campbellton","48.0050","-66.6731","Canada","CA","CAN","New Brunswick","","10411","1124336512"
"Chong-Aryk","Chong-Aryk","42.8139","74.5725","Kyrgyzstan","KG","KGZ","Bishkek","","10317","1417195703"
"Reddigūdem","Reddigudem","16.8939","80.6917","India","IN","IND","Andhra Pradesh","","9873","1356064520"
"Belaya Kholunitsa","Belaya Kholunitsa","58.8500","50.8667","Russia","RU","RUS","Kirovskaya Oblast’","","10406","1643961452"
"Vettaikkāraniruppu","Vettaikkaraniruppu","10.5739","79.8353","India","IN","IND","Tamil Nādu","","9861","1356184179"
"Lemmer","Lemmer","52.8437","5.7093","Netherlands","NL","NLD","Fryslân","minor","10225","1528838845"
"Fasintsara","Fasintsara","-20.7000","47.7000","Madagascar","MG","MDG","Fianarantsoa","","10377","1450084613"
"View Royal","View Royal","48.4517","-123.4339","Canada","CA","CAN","British Columbia","","10408","1124001985"
"Belle Chasse","Belle Chasse","29.8558","-90.0045","United States","US","USA","Louisiana","","10406","1840014028"
"Piraí do Norte","Pirai do Norte","-13.7619","-39.3789","Brazil","BR","BRA","Bahia","","10360","1076939449"
"Gilbués","Gilbues","-9.8319","-45.3439","Brazil","BR","BRA","Piauí","","10402","1076491367"
"Majhgawān","Majhgawan","23.4039","80.2098","India","IN","IND","Madhya Pradesh","","9857","1356093683"
"Leones","Leones","-32.6617","-62.2967","Argentina","AR","ARG","Córdoba","","10391","1032664852"
"Chiankī","Chianki","24.0051","84.1098","India","IN","IND","Jhārkhand","","9312","1356232319"
"Silverton","Silverton","45.0030","-122.7808","United States","US","USA","Oregon","","10402","1840021226"
"Galion","Galion","40.7385","-82.7792","United States","US","USA","Ohio","","10402","1840002752"
"Santa Catarina Masahuat","Santa Catarina Masahuat","13.7833","-89.7667","El Salvador","SV","SLV","Sonsonate","","10076","1222501280"
"Weil im Schönbuch","Weil im Schonbuch","48.6214","9.0611","Germany","DE","DEU","Baden-Württemberg","","10018","1276376071"
"Puduppattanam","Puduppattanam","10.7626","79.1616","India","IN","IND","Tamil Nādu","","9584","1356176214"
"Dāmu","Damu","26.5461","85.9820","India","IN","IND","Bihār","","9141","1356851512"
"Jogiāra","Jogiara","26.4083","85.7500","India","IN","IND","Bihār","","8861","1356068425"
"Terralba","Terralba","39.7197","8.6363","Italy","IT","ITA","Sardegna","","10196","1380583782"
"Jacinto Machado","Jacinto Machado","-28.9969","-49.7639","Brazil","BR","BRA","Santa Catarina","","10376","1076638099"
"Jaguaribara","Jaguaribara","-5.6578","-38.6200","Brazil","BR","BRA","Ceará","","10399","1076747362"
"Great Missenden","Great Missenden","51.7042","-0.7078","United Kingdom","GB","GBR","Buckinghamshire","","10138","1826171263"
"Palatka","Palatka","29.6493","-81.6705","United States","US","USA","Florida","","10399","1840015062"
"Shasta Lake","Shasta Lake","40.6790","-122.3775","United States","US","USA","California","","10399","1840021349"
"Suthālia","Suthalia","23.9955","77.1395","India","IN","IND","Madhya Pradesh","","10032","1356681390"
"Erravaram","Erravaram","17.5458","82.8433","India","IN","IND","Andhra Pradesh","","9754","1356358551"
"Somers","Somers","41.9949","-72.4530","United States","US","USA","Connecticut","","10398","1840033631"
"Mercês","Merces","-21.1939","-43.3408","Brazil","BR","BRA","Minas Gerais","","10368","1076984501"
"Huachipato","Huachipato","-36.7481","-73.0986","Chile","CL","CHL","Biobío","","9665","1152109016"
"Pavittiram","Pavittiram","11.1406","78.3631","India","IN","IND","Tamil Nādu","","9917","1356234407"
"Bad Ems","Bad Ems","50.3381","7.7106","Germany","DE","DEU","Rhineland-Palatinate","minor","9761","1276819164"
"Lajes","Lajes","-5.7000","-36.2450","Brazil","BR","BRA","Rio Grande do Norte","","10381","1076524454"
"Vūtukūru","Vutukuru","14.5778","80.1423","India","IN","IND","Andhra Pradesh","","10154","1356607430"
"Millstone","Millstone","40.2123","-74.4302","United States","US","USA","New Jersey","","10396","1840152826"
"Rangamāti","Rangamati","26.8040","89.4392","India","IN","IND","West Bengal","","9987","1356348693"
"Mahem","Mahem","15.5796","73.9260","India","IN","IND","Goa","","9958","1356236614"
"Sambhu Chak","Sambhu Chak","26.4081","84.9227","India","IN","IND","Bihār","","8948","1356450322"
"Kīlakkurichchi","Kilakkurichchi","10.7645","78.7406","India","IN","IND","Tamil Nādu","","8627","1356218691"
"Libanté","Libante","10.7936","3.5828","Benin","BJ","BEN","Alibori","","10365","1204606503"
"Kudūru","Kuduru","13.1081","77.1642","India","IN","IND","Karnātaka","","10101","1356441553"
"Puerto Quijarro","Puerto Quijarro","-17.7796","-57.7700","Bolivia","BO","BOL","Santa Cruz","","10392","1068582123"
"Simbach am Inn","Simbach am Inn","48.2667","13.0167","Germany","DE","DEU","Bavaria","","10111","1276572176"
"Pasewalk","Pasewalk","53.5000","14.0000","Germany","DE","DEU","Mecklenburg-Western Pomerania","","10213","1276152058"
"Dera Baba Nanak","Dera Baba Nanak","32.0321","75.0292","India","IN","IND","Punjab","","9892","1356706781"
"Ianca","Ianca","45.1350","27.4747","Romania","RO","ROU","Brăila","","10343","1642741254"
"Morsbach","Morsbach","50.8667","7.7167","Germany","DE","DEU","North Rhine-Westphalia","","10210","1276963409"
"Dholbāja","Dholbaja","26.2674","87.2761","India","IN","IND","Bihār","","8997","1356109429"
"Pararia","Pararia","25.6492","86.7501","India","IN","IND","Bihār","","8970","1356107753"
"Tavriisk","Tavriisk","46.7559","33.4247","Ukraine","UA","UKR","Khersonska Oblast","","10360","1804214941"
"Cholchol","Cholchol","-38.6000","-72.8333","Chile","CL","CHL","Araucanía","","10382","1152052150"
"Knezha","Knezha","43.5000","24.0833","Bulgaria","BG","BGR","Vratsa","minor","10348","1100486510"
"Felida","Felida","45.7138","-122.7103","United States","US","USA","Washington","","10389","1840037495"
"Iraiyūr","Iraiyur","11.7834","79.1970","India","IN","IND","Tamil Nādu","","9831","1356426034"
"Vaprio d’Adda","Vaprio d'Adda","45.5667","9.5333","Italy","IT","ITA","Lombardy","","9114","1380992694"
"Lipova","Lipova","46.0917","21.6917","Romania","RO","ROU","Arad","","10313","1642895162"
"Chavkandak","Chavkandak","39.9000","68.9333","Tajikistan","TJ","TJK","Sughd","","10200","1762806975"
"Bimun","Bimun","34.0588","74.7539","India","IN","IND","Jammu and Kashmīr","","9476","1356167264"
"Peixe","Peixe","-12.0250","-48.5389","Brazil","BR","BRA","Tocantins","","10384","1076553772"
"Barhauna","Barhauna","25.3040","85.4216","India","IN","IND","Bihār","","8887","1356135140"
"Ukkali","Ukkali","16.5900","75.9600","India","IN","IND","Karnātaka","","10173","1356760534"
"Alguazas","Alguazas","38.0514","-1.2414","Spain","ES","ESP","Murcia","","9965","1724241306"
"Terakanāmbi","Terakanambi","11.8000","76.6900","India","IN","IND","Karnātaka","","10214","1356466911"
"El Realejo","El Realejo","12.5428","-87.1650","Nicaragua","NI","NIC","Chinandega","minor","10300","1558231692"
"Keshwāri","Keshwari","24.1878","85.8411","India","IN","IND","Jhārkhand","","9801","1356254588"
"Úmbita","Umbita","5.2167","-73.4667","Colombia","CO","COL","Boyacá","minor","10314","1170372127"
"Bowen","Bowen","-20.0102","148.2416","Australia","AU","AUS","Queensland","","10377","1036979225"
"Kunnūr","Kunnur","9.5882","77.7006","India","IN","IND","Tamil Nādu","","9910","1356219148"
"Vālāntaravai","Valantaravai","9.3390","78.8871","India","IN","IND","Tamil Nādu","","9768","1356805252"
"Kapyl","Kapyl","53.1500","27.0917","Belarus","BY","BLR","Minskaya Voblasts’","minor","10367","1112976876"
"Sīlamalai","Silamalai","9.9621","77.3371","India","IN","IND","Tamil Nādu","","10013","1356251387"
"Pirojgarh","Pirojgarh","26.2769","86.4389","India","IN","IND","Bihār","","8613","1356427798"
"Kanakpur","Kanakpur","24.4976","88.0361","India","IN","IND","West Bengal","","8865","1356972843"
"Vatana","Vatana","-22.2167","47.7500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450089974"
"Jaqma","Jaqma","33.2920","-7.4400","Morocco","MA","MAR","Casablanca-Settat","","10306","1504002032"
"Bad Frankenhausen","Bad Frankenhausen","51.3558","11.1011","Germany","DE","DEU","Thuringia","","10230","1276187719"
"Badia Polesine","Badia Polesine","45.1000","11.5000","Italy","IT","ITA","Veneto","","10348","1380577968"
"Natonin","Natonin","17.1089","121.2797","Philippines","PH","PHL","Mountain","","10339","1608385034"
"Lima","Lima","-23.9000","-56.3333","Paraguay","PY","PRY","San Pedro","","10367","1600187731"
"Southborough","Southborough","42.3012","-71.5297","United States","US","USA","Massachusetts","","10380","1840053604"
"Tanichchiyam","Tanichchiyam","10.0412","78.0127","India","IN","IND","Tamil Nādu","","9857","1356215690"
"Pedda Kotayalanka","Pedda Kotayalanka","16.1567","80.8314","India","IN","IND","Andhra Pradesh","","9839","1356183491"
"Golakpur","Golakpur","25.1777","85.0728","India","IN","IND","Bihār","","8866","1356145038"
"Verucchio","Verucchio","43.9833","12.4215","Italy","IT","ITA","Emilia-Romagna","","10012","1380732031"
"Fervedouro","Fervedouro","-20.7258","-42.2789","Brazil","BR","BRA","Minas Gerais","","10349","1076636740"
"Qiushanxiang","Qiushanxiang","34.3562","104.8983","China","CN","CHN","Gansu","","10310","1156073223"
"Satghara","Satghara","26.3977","86.1678","India","IN","IND","Bihār","","9108","1356038394"
"Khariāl","Kharial","30.1056","75.8855","India","IN","IND","Punjab","","9839","1356897177"
"Köflach","Koflach","47.0639","15.0889","Austria","AT","AUT","Steiermark","","9888","1040578038"
"Cabañaquinta","Cabanaquinta","43.1000","-5.5833","Spain","ES","ESP","Asturias","","10346","1724996581"
"Frýdlant nad Ostravicí","Frydlant nad Ostravici","49.5928","18.3597","Czechia","CZ","CZE","Moravskoslezský Kraj","","9926","1203314077"
"Valaiyāmpattu","Valaiyampattu","12.6927","78.6347","India","IN","IND","Tamil Nādu","","9521","1356024243"
"Steinau an der Straße","Steinau an der Strasse","50.3167","9.4667","Germany","DE","DEU","Hesse","","10275","1276321802"
"Induno Olona","Induno Olona","45.8500","8.8333","Italy","IT","ITA","Lombardy","","10266","1380997586"
"Quétigny","Quetigny","47.3144","5.1061","France","FR","FRA","Bourgogne-Franche-Comté","","9261","1250263634"
"Robstown","Robstown","27.7940","-97.6692","United States","US","USA","Texas","","10372","1840021010"
"Mettuppālaiyam","Mettuppalaiyam","11.1747","78.4425","India","IN","IND","Tamil Nādu","","9966","1356154383"
"São Domingos","Sao Domingos","-10.7908","-37.5678","Brazil","BR","BRA","Sergipe","","10271","1076641511"
"Winchendon","Winchendon","42.6667","-72.0487","United States","US","USA","Massachusetts","","10371","1840053615"
"Corumbá de Goiás","Corumba de Goias","-15.9239","-48.8089","Brazil","BR","BRA","Goiás","","10361","1076265793"
"South Lebanon","South Lebanon","40.3058","-76.3708","United States","US","USA","Pennsylvania","","10370","1840149649"
"Kudelstaart","Kudelstaart","52.2339","4.7483","Netherlands","NL","NLD","Noord-Holland","","9250","1528545833"
"Werneck","Werneck","49.9833","10.1000","Germany","DE","DEU","Bavaria","","10231","1276199382"
"Essey-lès-Nancy","Essey-les-Nancy","48.7058","6.2222","France","FR","FRA","Grand Est","","8833","1250975890"
"María Pinto","Maria Pinto","-33.5167","-71.1167","Chile","CL","CHL","Región Metropolitana","","10343","1152556156"
"Chuhal","Chuhal","31.5950","75.9684","India","IN","IND","Punjab","","9811","1356942609"
"Pernes-les-Fontaines","Pernes-les-Fontaines","43.9978","5.0592","France","FR","FRA","Provence-Alpes-Côte d’Azur","","10170","1250545393"
"Biot","Biot","43.6286","7.0956","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9746","1250684857"
"Pont Sondé","Pont Sonde","19.1493","-72.6147","Haiti","HT","HTI","Artibonite","","10000","1332034051"
"Shamva","Shamva","-17.3167","31.5500","Zimbabwe","ZW","ZWE","Mashonaland Central","","10317","1716210091"
"Kemin","Kemin","42.7861","75.6917","Kyrgyzstan","KG","KGZ","Chüy","minor","10354","1417700356"
"San Diego Country Estates","San Diego Country Estates","33.0094","-116.7874","United States","US","USA","California","","10366","1840028398"
"Firou","Firou","10.9192","1.9386","Benin","BJ","BEN","Atacora","","10331","1204000006"
"Nanbu","Nanbu","35.3403","133.3267","Japan","JP","JPN","Tottori","","10275","1392002926"
"Głogów Małopolski","Glogow Malopolski","50.1667","21.9667","Poland","PL","POL","Podkarpackie","","10232","1616792516"
"Rājepur","Rajepur","25.5377","85.6228","India","IN","IND","Bihār","","9183","1356488145"
"Levanger","Levanger","63.7465","11.2987","Norway","NO","NOR","Trøndelag","minor","10333","1578010304"
"Førde","Forde","61.4522","5.8572","Norway","NO","NOR","Vestland","minor","10339","1578914241"
"Vaiano","Vaiano","43.9667","11.1167","Italy","IT","ITA","Tuscany","","10068","1380537845"
"Bethanie","Bethanie","-26.4833","17.1500","Namibia","NA","NAM","//Karas","","10363","1516358571"
"Blairgowrie","Blairgowrie","56.5916","-3.3405","United Kingdom","GB","GBR","Perth and Kinross","","9020","1826311764"
"Kondakindi Agrahāram","Kondakindi Agraharam","14.6790","77.7401","India","IN","IND","Andhra Pradesh","","10111","1356757655"
"Benedito Novo","Benedito Novo","-26.7828","-49.3639","Brazil","BR","BRA","Santa Catarina","","10336","1076784672"
"Letychiv","Letychiv","49.3833","27.6167","Ukraine","UA","UKR","Khmelnytska Oblast","","10335","1804357044"
"Datian","Datian","25.4379","87.8378","India","IN","IND","Bihār","","9102","1356104820"
"Rāmpatti","Rampatti","26.3333","86.1500","India","IN","IND","Bihār","","8763","1356001751"
"London","London","39.8935","-83.4375","United States","US","USA","Ohio","","10362","1840000674"
"Kut Chap","Kut Chap","17.4262","102.5646","Thailand","TH","THA","Udon Thani","minor","10259","1764618058"
"Sylva","Sylva","58.0333","56.7681","Russia","RU","RUS","Permskiy Kray","","10113","1643208268"
"Münchberg","Munchberg","50.2000","11.7667","Germany","DE","DEU","Bavaria","","10215","1276739492"
"Victoria","Victoria","44.8634","-93.6585","United States","US","USA","Minnesota","","10361","1840010007"
"Nideggen","Nideggen","50.7000","6.4833","Germany","DE","DEU","North Rhine-Westphalia","","10204","1276330889"
"Bernay","Bernay","49.0886","0.5983","France","FR","FRA","Normandie","minor","9951","1250424419"
"Bounaamane","Bounaamane","29.5283","-9.8044","Morocco","MA","MAR","Souss-Massa","","10310","1504463740"
"Kirkel","Kirkel","49.2833","7.2333","Germany","DE","DEU","Saarland","","10040","1276147467"
"Capriolo","Capriolo","45.6373","9.9336","Italy","IT","ITA","Lombardy","","9467","1380206582"
"San Simón","San Simon","13.8333","-88.2333","El Salvador","SV","SLV","Morazán","","10102","1222320015"
"Nallikodūr","Nallikodur","17.5811","79.7500","India","IN","IND","Andhra Pradesh","","10037","1356010568"
"Nordkirchen","Nordkirchen","51.7381","7.5256","Germany","DE","DEU","North Rhine-Westphalia","","10166","1276717351"
"Taksimo","Taksimo","56.3315","114.8900","Russia","RU","RUS","Buryatiya","","10359","1643663655"
"Hire Megalageri","Hire Megalageri","14.5440","75.9776","India","IN","IND","Karnātaka","","10131","1356078789"
"Alderwood Manor","Alderwood Manor","47.8146","-122.2672","United States","US","USA","Washington","","10359","1840017323"
"Banga","Banga","-8.7333","15.1667","Angola","AO","AGO","Cuanza-Norte","","10354","1024994846"
"Pālepalli","Palepalli","12.5667","78.3071","India","IN","IND","Tamil Nādu","","9865","1356078107"
"Oostzaan","Oostzaan","52.4333","4.8833","Netherlands","NL","NLD","Noord-Holland","minor","9757","1528095690"
"Neustadt","Neustadt","50.8500","9.1167","Germany","DE","DEU","Hesse","","10177","1276694590"
"Aydarken","Aydarken","39.9400","71.3300","Kyrgyzstan","KG","KGZ","Batken","","10331","1417865074"
"Kalgi","Kalgi","17.3500","77.1500","India","IN","IND","Karnātaka","","10078","1356359480"
"São Carlos","Sao Carlos","-27.0778","-53.0039","Brazil","BR","BRA","Santa Catarina","","10291","1076654871"
"Santa María de Palautordera","Santa Maria de Palautordera","41.6953","2.4458","Spain","ES","ESP","Catalonia","","9777","1724250145"
"Berwick","Berwick","41.0555","-76.2492","United States","US","USA","Pennsylvania","","10355","1840003484"
"Kiklah","Kiklah","32.0683","12.6944","Libya","LY","LBY","Al Jabal al Gharbī","","10350","1434206473"
"Bowdon","Bowdon","53.3760","-2.3660","United Kingdom","GB","GBR","Trafford","","9228","1826944016"
"Buckie","Buckie","57.6760","-2.9650","United Kingdom","GB","GBR","Moray","","8640","1826944488"
"Payerne","Payerne","46.8167","6.9333","Switzerland","CH","CHE","Vaud","","9943","1756860201"
"Padugaipattu","Padugaipattu","8.3642","77.9815","India","IN","IND","Tamil Nādu","","10084","1356226693"
"Venecia","Venecia","5.9167","-75.7500","Colombia","CO","COL","Antioquia","minor","10280","1170112749"
"Srīsailain","Srisailain","16.0833","78.8667","India","IN","IND","Andhra Pradesh","","10288","1356108184"
"Korolevo","Korolevo","48.1500","23.1333","Ukraine","UA","UKR","Zakarpatska Oblast","","10165","1804136569"
"Sobral de Monte Agraço","Sobral de Monte Agraco","39.0180","-9.1520","Portugal","PT","PRT","Lisboa","minor","10156","1620360918"
"Goito","Goito","45.2500","10.6667","Italy","IT","ITA","Lombardy","","10222","1380045082"
"Portage","Portage","43.5489","-89.4658","United States","US","USA","Wisconsin","","10351","1840002712"
"Murowana Goślina","Murowana Goslina","52.5667","17.0167","Poland","PL","POL","Wielkopolskie","","10247","1616479191"
"Monsenhor Gil","Monsenhor Gil","-5.5639","-42.6078","Brazil","BR","BRA","Piauí","","10333","1076756343"
"Nagyatád","Nagyatad","46.2227","17.3643","Hungary","HU","HUN","Somogy","minor","10212","1348804654"
"Panasapādu","Panasapadu","17.0185","82.2349","India","IN","IND","Andhra Pradesh","","8994","1356908004"
"Conceição da Aparecida","Conceicao da Aparecida","-21.0939","-46.2039","Brazil","BR","BRA","Minas Gerais","","10322","1076238333"
"El Jicaral","El Jicaral","12.7286","-86.3803","Nicaragua","NI","NIC","León","minor","10326","1558715100"
"Rancho Arriba","Rancho Arriba","18.7147","-70.4618","Dominican Republic","DO","DOM","Valdesia","","10299","1214914352"
"Kīl Vālūr","Kil Valur","10.7659","79.7419","India","IN","IND","Tamil Nādu","","9801","1356091652"
"Monte Alegre do Piauí","Monte Alegre do Piaui","-9.7539","-45.3039","Brazil","BR","BRA","Piauí","","10345","1076223084"
"Ottendorf-Okrilla","Ottendorf-Okrilla","51.1792","13.8261","Germany","DE","DEU","Saxony","","9965","1276340618"
"Exeter","Exeter","36.2941","-119.1459","United States","US","USA","California","","10349","1840020365"
"South Huntington","South Huntington","40.8225","-73.3921","United States","US","USA","New York","","10349","1840005100"
"Kazo","Kazo","-0.0528","30.7569","Uganda","UG","UGA","Kazo","","10200","1800531840"
"Roddam","Roddam","14.1000","77.4300","India","IN","IND","Andhra Pradesh","","10164","1356286713"
"Santa Isabel Ishuatán","Santa Isabel Ishuatan","13.6167","-89.5833","El Salvador","SV","SLV","Sonsonate","","10241","1222753045"
"Murfatlar","Murfatlar","44.1736","28.4083","Romania","RO","ROU","Constanţa","","10216","1642326198"
"Sandalpur","Sandalpur","25.5639","87.5082","India","IN","IND","Bihār","","9232","1356908319"
"Gagnef","Gagnef","60.6000","15.0833","Sweden","SE","SWE","Dalarna","minor","10328","1752571884"
"Iapu","Iapu","-19.4369","-42.2178","Brazil","BR","BRA","Minas Gerais","","10315","1076076296"
"Örkelljunga","Orkelljunga","56.2833","13.2833","Sweden","SE","SWE","Skåne","minor","10300","1752548206"
"Tiruchchuli","Tiruchchuli","9.5348","78.2008","India","IN","IND","Tamil Nādu","","10148","1356161208"
"Vorsma","Vorsma","55.9833","43.2500","Russia","RU","RUS","Nizhegorodskaya Oblast’","","10253","1643536345"
"Ksar Lmajaz","Ksar Lmajaz","35.8428","-5.5586","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10237","1504167905"
"Recreio","Recreio","-21.5250","-42.4689","Brazil","BR","BRA","Minas Gerais","","10299","1076403052"
"Jordânia","Jordania","-15.9000","-40.1778","Brazil","BR","BRA","Minas Gerais","","10324","1076746673"
"Teocuitatlán de Corona","Teocuitatlan de Corona","20.0918","-103.3785","Mexico","MX","MEX","Jalisco","minor","10317","1484087600"
"Kalakeri","Kalakeri","16.6667","76.3000","India","IN","IND","Karnātaka","","10113","1356168546"
"Buzdyak","Buzdyak","54.5711","54.5308","Russia","RU","RUS","Bashkortostan","","10323","1643716517"
"Normandia","Normandia","3.8808","-59.6228","Brazil","BR","BRA","Roraima","","10339","1076555089"
"Karattuppālaiyam","Karattuppalaiyam","11.4402","77.3575","India","IN","IND","Tamil Nādu","","9719","1356163759"
"Kalinagar","Kalinagar","22.4206","88.8655","India","IN","IND","West Bengal","","8679","1356171774"
"Fort Madison","Fort Madison","40.6207","-91.3509","United States","US","USA","Iowa","","10340","1840008321"
"Koila","Koila","25.4550","86.7710","India","IN","IND","Bihār","","8594","1356525976"
"Launaguet","Launaguet","43.6739","1.4569","France","FR","FRA","Occitanie","","9050","1250183524"
"Marpingen","Marpingen","49.4500","7.0500","Germany","DE","DEU","Saarland","","10086","1276606720"
"Weston","Weston","41.2284","-73.3726","United States","US","USA","Connecticut","","10339","1840035663"
"Paranã","Parana","-12.6150","-47.8828","Brazil","BR","BRA","Tocantins","","10338","1076862389"
"Aich","Aich","48.6228","9.2372","Germany","DE","DEU","Baden-Württemberg","","9919","1276245097"
"Dāra","Dara","26.1546","86.4483","India","IN","IND","Bihār","","8867","1356924332"
"Rouvroy","Rouvroy","50.3936","2.9039","France","FR","FRA","Hauts-de-France","","8945","1250914310"
"Ganapavaram","Ganapavaram","16.4314","80.0515","India","IN","IND","Andhra Pradesh","","10029","1356636651"
"Gislaved","Gislaved","57.3000","13.5333","Sweden","SE","SWE","Jönköping","minor","10231","1752639615"
"Cedral","Cedral","-2.0000","-44.5358","Brazil","BR","BRA","Maranhão","","10297","1076943907"
"Ben Nasseur","Ben Nasseur","33.1107","6.4410","Algeria","DZ","DZA","Ouargla","","10330","1012048018"
"Winterville","Winterville","35.5291","-77.4000","United States","US","USA","North Carolina","","10336","1840016279"
"Winchester","Winchester","41.9218","-73.1028","United States","US","USA","Connecticut","","10335","1840045107"
"Darsur","Darsur","25.9711","85.9148","India","IN","IND","Bihār","","8512","1356408750"
"Lenguazaque","Lenguazaque","5.3069","-73.7061","Colombia","CO","COL","Cundinamarca","minor","10268","1170060796"
"Engenheiro Caldas","Engenheiro Caldas","-19.2189","-42.0458","Brazil","BR","BRA","Minas Gerais","","10280","1076002103"
"Horti","Horti","17.1700","75.9600","India","IN","IND","Karnātaka","","10113","1356696783"
"Biandanshan","Biandanshan","26.0409","105.6351","China","CN","CHN","Guizhou","","10183","1156826930"
"Union Park","Union Park","28.5657","-81.2355","United States","US","USA","Florida","","10334","1840014090"
"Santa Maria Madalena","Santa Maria Madalena","-21.9550","-42.0078","Brazil","BR","BRA","Rio de Janeiro","","10321","1076086905"
"Roca Sales","Roca Sales","-29.2839","-51.8678","Brazil","BR","BRA","Rio Grande do Sul","","10284","1076512734"
"Fochville","Fochville","-26.4833","27.4833","South Africa","ZA","ZAF","North West","","9497","1710135294"
"Bilehra","Bilehra","23.6463","78.7311","India","IN","IND","Madhya Pradesh","","9894","1356328138"
"Arkadelphia","Arkadelphia","34.1253","-93.0729","United States","US","USA","Arkansas","","10332","1840013638"
"Paris","Paris","36.2933","-88.3065","United States","US","USA","Tennessee","","10332","1840014438"
"Tirumangalakkudi","Tirumangalakkudi","11.0214","79.4714","India","IN","IND","Tamil Nādu","","9494","1356171574"
"Presidente Kennedy","Presidente Kennedy","-21.0989","-41.0469","Brazil","BR","BRA","Espírito Santo","minor","10314","1076287940"
"Friedeburg","Friedeburg","53.4500","7.8333","Germany","DE","DEU","Lower Saxony","","10269","1276711617"
"Oliveira de Frades","Oliveira de Frades","40.7167","-8.1833","Portugal","PT","PRT","Viseu","minor","10261","1620849637"
"Volterra","Volterra","43.4000","10.8667","Italy","IT","ITA","Tuscany","","10290","1380640362"
"Crosia","Crosia","39.5667","16.7667","Italy","IT","ITA","Calabria","","9863","1380693919"
"Al Jazīrah al Ḩamrā’","Al Jazirah al Hamra'","25.7089","55.7972","United Arab Emirates","AE","ARE","Ra’s al Khaymah","","10190","1784137216"
"Lolokhur","Lolokhur","26.4136","87.4012","India","IN","IND","Bihār","","8968","1356577828"
"Nāvinipatti","Navinipatti","10.0439","78.3557","India","IN","IND","Tamil Nādu","","9901","1356203007"
"Aperibé","Aperibe","-21.6208","-42.1028","Brazil","BR","BRA","Rio de Janeiro","","10213","1076210248"
"Northampton","Northampton","40.6866","-75.4904","United States","US","USA","Pennsylvania","","10328","1840000975"
"Bieber","Bieber","50.6000","8.5833","Germany","DE","DEU","Hesse","","10098","1276631690"
"Bangaon","Bangaon","26.3081","87.8833","India","IN","IND","Bihār","","9170","1356045117"
"Bhatauliā","Bhataulia","26.4771","84.7789","India","IN","IND","Bihār","","8870","1356026708"
"Kottampatti","Kottampatti","10.6705","77.0194","India","IN","IND","Tamil Nādu","","9709","1356170390"
"London Colney","London Colney","51.7260","-0.3000","United Kingdom","GB","GBR","Hertfordshire","","9507","1826250958"
"Caparrapí","Caparrapi","5.3442","-74.4917","Colombia","CO","COL","Cundinamarca","minor","10301","1170076731"
"Kranuan","Kranuan","16.7081","103.0811","Thailand","TH","THA","Khon Kaen","minor","10169","1764256426"
"Dettingen an der Erms","Dettingen an der Erms","48.5300","9.3472","Germany","DE","DEU","Baden-Württemberg","","9712","1276179914"
"Vytegra","Vytegra","61.0000","36.4500","Russia","RU","RUS","Vologodskaya Oblast’","","10324","1643014673"
"Chavinda","Chavinda","20.0167","-102.4500","Mexico","MX","MEX","Michoacán","minor","10258","1484159886"
"Welkenraedt","Welkenraedt","50.6606","5.9714","Belgium","BE","BEL","Wallonia","","9920","1056282344"
"Aghbalou n’Kerdous","Aghbalou n'Kerdous","31.6767","-5.2976","Morocco","MA","MAR","Drâa-Tafilalet","","10313","1504201813"
"Lexington","Lexington","40.7779","-99.7461","United States","US","USA","Nebraska","","10325","1840008285"
"Rifle","Rifle","39.5362","-107.7729","United States","US","USA","Colorado","","10325","1840020185"
"Wood River","Wood River","38.8631","-90.0774","United States","US","USA","Illinois","","10325","1840010711"
"Dari","Dari","23.8283","85.2739","India","IN","IND","Jhārkhand","","9746","1356185846"
"Manglūr","Manglur","15.5177","76.1476","India","IN","IND","Karnātaka","","10123","1356135126"
"Pithiviers","Pithiviers","48.1719","2.2519","France","FR","FRA","Centre-Val de Loire","minor","9023","1250710723"
"Dahua","Dahua","24.7742","87.0325","India","IN","IND","Bihār","","9657","1356097095"
"Binéfar","Binefar","41.8500","0.3000","Spain","ES","ESP","Aragon","","9927","1724991956"
"Kafr Takhārīm","Kafr Takharim","36.1164","36.5147","Syria","SY","SYR","Idlib","minor","10084","1760371396"
"Hipparga","Hipparga","17.4568","76.3739","India","IN","IND","Karnātaka","","10117","1356087528"
"Ngọc Sơn","Ngoc Son","21.3500","106.0000","Vietnam","VN","VNM","Bắc Giang","","9412","1704988443"
"Pedras de Maria da Cruz","Pedras de Maria da Cruz","-15.6069","-44.3908","Brazil","BR","BRA","Minas Gerais","","10315","1076803883"
"Ulft","Ulft","51.8908","6.3797","Netherlands","NL","NLD","Gelderland","","10025","1528529682"
"Kampong Tunah Jambu","Kampong Tunah Jambu","4.9957","115.0019","Brunei","BN","BRN","Brunei dan Muara","","8809","1096755570"
"Clay","Clay","33.6951","-86.6090","United States","US","USA","Alabama","","10320","1840014790"
"Ballina","Ballina","-28.8333","153.5333","Australia","AU","AUS","New South Wales","","8655","1036040102"
"São Geraldo","Sao Geraldo","-20.9228","-42.8339","Brazil","BR","BRA","Minas Gerais","","10263","1076340337"
"Dāita","Daita","24.4548","87.8573","India","IN","IND","West Bengal","","9081","1356115411"
"Pineville","Pineville","35.0864","-80.8915","United States","US","USA","North Carolina","","10317","1840016354"
"Cadillac","Cadillac","44.2493","-85.4164","United States","US","USA","Michigan","","10317","1840002512"
"Cordele","Cordele","31.9563","-83.7694","United States","US","USA","Georgia","","10316","1840014934"
"Qanliko‘l","Qanliko\`l","42.8333","59.0000","Uzbekistan","UZ","UZB","Qoraqalpog‘iston","minor","10303","1860774875"
"Tyukalinsk","Tyukalinsk","55.8667","72.2000","Russia","RU","RUS","Omskaya Oblast’","","10311","1643338887"
"Grand-Couronne","Grand-Couronne","49.3575","1.0072","France","FR","FRA","Normandie","","9739","1250318831"
"Montemarciano","Montemarciano","43.6333","13.3167","Italy","IT","ITA","Marche","","9872","1380357484"
"Cumnock","Cumnock","55.4529","-4.2638","United Kingdom","GB","GBR","East Ayrshire","","8830","1826053994"
"Campo Redondo","Campo Redondo","-6.2428","-36.1828","Brazil","BR","BRA","Rio Grande do Norte","","10266","1076821934"
"Tanaina","Tanaina","61.6576","-149.4263","United States","US","USA","Alaska","","10314","1840023662"
"Coldstream","Coldstream","50.2200","-119.2481","Canada","CA","CAN","British Columbia","","10314","1124000216"
"Sirdala","Sirdala","24.6559","85.4092","India","IN","IND","Bihār","","9741","1356357119"
"Kurşunlu","Kursunlu","40.8333","33.2500","Turkey","TR","TUR","Çankırı","minor","10307","1792599229"
"Masquefa","Masquefa","41.5036","1.8136","Spain","ES","ESP","Catalonia","","9744","1724932244"
"Belagola","Belagola","13.3833","75.5833","India","IN","IND","Karnātaka","","10121","1356949795"
"Urucânia","Urucania","-20.3508","-42.7389","Brazil","BR","BRA","Minas Gerais","","10291","1076102437"
"Mandello del Lario","Mandello del Lario","45.9167","9.3167","Italy","IT","ITA","Lombardy","","10313","1380200850"
"Manteswar","Manteswar","23.4225","88.1075","India","IN","IND","West Bengal","","9331","1356795086"
"Bellamkonda","Bellamkonda","16.4923","80.0089","India","IN","IND","Andhra Pradesh","","10169","1356758094"
"Terra Alta","Terra Alta","-1.0378","-47.9078","Brazil","BR","BRA","Pará","","10262","1076764322"
"Chākicherla","Chakicherla","15.1127","80.0366","India","IN","IND","Andhra Pradesh","","9962","1356549672"
"Shankarampet","Shankarampet","18.0490","77.9143","India","IN","IND","Andhra Pradesh","","9897","1356385422"
"Santa Terezinha de Goiás","Santa Terezinha de Goias","-14.4378","-49.7058","Brazil","BR","BRA","Goiás","","10302","1076003561"
"Sgamna","Sgamna","32.7333","-7.2000","Morocco","MA","MAR","Casablanca-Settat","","10245","1504088334"
"Cermenate","Cermenate","45.7000","9.0833","Italy","IT","ITA","Lombardy","","9187","1380284601"
"Chester","Chester","44.6500","-64.3000","Canada","CA","CAN","Nova Scotia","","10310","1124772236"
"Vālavandānkottai","Valavandankottai","10.7667","78.8333","India","IN","IND","Tamil Nādu","","9199","1356213462"
"Āttūrkuppam","Atturkuppam","12.6000","78.5423","India","IN","IND","Tamil Nādu","","9702","1356958556"
"Argayash","Argayash","55.4889","60.8758","Russia","RU","RUS","Chelyabinskaya Oblast’","","10293","1643989915"
"Ellon","Ellon","57.3660","-2.0860","United Kingdom","GB","GBR","Aberdeenshire","","10200","1826126945"
"Telpur","Telpur","27.0548","84.3336","India","IN","IND","Bihār","","9930","1356766588"
"Santo Stefano di Magra","Santo Stefano di Magra","44.1625","9.9151","Italy","IT","ITA","Tuscany","","9613","1380931318"
"Zuidlaren","Zuidlaren","53.0942","6.6844","Netherlands","NL","NLD","Drenthe","","10080","1528131698"
"Queens","Queens","44.0333","-64.7167","Canada","CA","CAN","Nova Scotia","","10307","1124001652"
"Trajano de Morais","Trajano de Morais","-22.0628","-42.0658","Brazil","BR","BRA","Rio de Janeiro","","10289","1076642739"
"Bilenke","Bilenke","48.7664","37.6314","Ukraine","UA","UKR","Donetska Oblast","","9136","1804319022"
"Potengi","Potengi","-7.0908","-40.0269","Brazil","BR","BRA","Ceará","","10276","1076282197"
"Kambarka","Kambarka","56.2667","54.2167","Russia","RU","RUS","Udmurtiya","","10276","1643279946"
"Newport","Newport","44.6242","-124.0513","United States","US","USA","Oregon","","10305","1840019975"
"Southampton","Southampton","39.9137","-74.7171","United States","US","USA","New Jersey","","10305","1840081621"
"Banská Štiavnica","Banska Stiavnica","48.4581","18.8964","Slovakia","SK","SVK","Banská Bystrica","minor","10097","1703575986"
"Saint-Loubès","Saint-Loubes","44.9172","-0.4281","France","FR","FRA","Nouvelle-Aquitaine","","9909","1250074053"
"Rāyen","Rayen","29.5978","57.4386","Iran","IR","IRN","Kermān","","10286","1364046368"
"Soanpeta","Soanpeta","18.9586","78.3610","India","IN","IND","Andhra Pradesh","","9589","1356123372"
"Arlöv","Arlov","55.6333","13.0833","Sweden","SE","SWE","Skåne","minor","10284","1752897909"
"Rio dos Cedros","Rio dos Cedros","-26.7378","-49.2739","Brazil","BR","BRA","Santa Catarina","","10284","1076000091"
"Ajjipuram","Ajjipuram","12.1500","77.1000","India","IN","IND","Karnātaka","","10169","1356862548"
"Petrolina de Goiás","Petrolina de Goias","-16.0950","-49.3378","Brazil","BR","BRA","Goiás","","10283","1076646883"
"Novi di Modena","Novi di Modena","44.8934","10.9010","Italy","IT","ITA","Emilia-Romagna","","10107","1380996478"
"Gafour","Gafour","36.3400","9.3200","Tunisia","TN","TUN","Siliana","","10272","1788863450"
"Sermoneta","Sermoneta","41.5500","12.9833","Italy","IT","ITA","Lazio","","10077","1380567899"
"Higashikagura","Higashikagura","43.6966","142.4515","Japan","JP","JPN","Hokkaidō","","10152","1392003322"
"Międzychód","Miedzychod","52.6000","15.8833","Poland","PL","POL","Wielkopolskie","minor","10288","1616252444"
"Cêrro Grande","Cerro Grande","-30.5900","-51.7389","Brazil","BR","BRA","Rio Grande do Sul","","10268","1076627862"
"Pilar","Pilar","17.4168","120.5954","Philippines","PH","PHL","Abra","","10146","1608856140"
"Erdőkertes","Erdokertes","47.6667","19.3167","Hungary","HU","HUN","Pest","","8771","1348236024"
"Buenópolis","Buenopolis","-17.8742","-44.1767","Brazil","BR","BRA","Minas Gerais","","10292","1076259930"
"Dalgān","Dalgan","27.4753","59.4717","Iran","IR","IRN","Sīstān va Balūchestān","minor","10292","1364617875"
"Centralina","Centralina","-18.5839","-49.1989","Brazil","BR","BRA","Minas Gerais","","10266","1076688673"
"Roßtal","Rosstal","49.4000","10.8833","Germany","DE","DEU","Bavaria","","10070","1276297806"
"Lontras","Lontras","-27.1658","-49.5419","Brazil","BR","BRA","Santa Catarina","","10244","1076636048"
"Bonham","Bonham","33.5880","-96.1901","United States","US","USA","Texas","","10295","1840019332"
"Schönwalde-Siedlung","Schonwalde-Siedlung","52.6500","12.9833","Germany","DE","DEU","Brandenburg","","10190","1276477512"
"Zaozërnyy","Zaozernyy","55.9667","94.7000","Russia","RU","RUS","Krasnoyarskiy Kray","","10286","1643658238"
"Teixeira Soares","Teixeira Soares","-25.3678","-50.4608","Brazil","BR","BRA","Paraná","","10283","1076944440"
"Nehoiu","Nehoiu","45.3531","26.3050","Romania","RO","ROU","Buzău","","10211","1642541496"
"Velakkuttai","Velakkuttai","12.6519","78.6937","India","IN","IND","Tamil Nādu","","9687","1356982055"
"Worcester","Worcester","40.1899","-75.3522","United States","US","USA","Pennsylvania","","10294","1840035368"
"Tello","Tello","3.0667","-75.1333","Colombia","CO","COL","Huila","minor","10273","1170282620"
"Groaíras","Groairas","-3.9128","-40.3828","Brazil","BR","BRA","Ceará","","10228","1076421246"
"Villa Elisa","Villa Elisa","-32.1667","-58.4000","Argentina","AR","ARG","Entre Ríos","","10266","1032046223"
"Kāsarkod","Kasarkod","14.2500","74.4333","India","IN","IND","Karnātaka","","10037","1356421952"
"Hecelchakán","Hecelchakan","20.1667","-90.1333","Mexico","MX","MEX","Campeche","minor","10285","1484700920"
"Bertem","Bertem","50.8500","4.6167","Belgium","BE","BEL","Flanders","","9958","1056292340"
"Fairmount","Fairmount","43.0414","-76.2485","United States","US","USA","New York","","10292","1840000377"
"Tokunoshima","Tokunoshima","27.7266","129.0187","Japan","JP","JPN","Okinawa","","10194","1392999972"
"Laligam","Laligam","12.0500","78.1500","India","IN","IND","Tamil Nādu","","9667","1356119023"
"Chitrāda","Chitrada","17.0802","82.2469","India","IN","IND","Andhra Pradesh","","9122","1356022916"
"Jamhra","Jamhra","25.7777","86.7899","India","IN","IND","Bihār","","9196","1356352296"
"Khiriāwān","Khiriawan","25.1120","84.4017","India","IN","IND","Bihār","","8982","1356279097"
"Ambalavao","Ambalavao","-19.1000","47.5333","Madagascar","MG","MDG","Antananarivo","","10157","1450481528"
"Ban Bong Tai","Ban Bong Tai","17.4057","103.2992","Thailand","TH","THA","Sakon Nakhon","","10168","1764226625"
"Angelim","Angelim","-8.8833","-36.2833","Brazil","BR","BRA","Pernambuco","","10202","1076806495"
"Alvorada do Sul","Alvorada do Sul","-22.7800","-51.2308","Brazil","BR","BRA","Paraná","","10283","1076000190"
"Nieuw-Lekkerland","Nieuw-Lekkerland","51.8833","4.6833","Netherlands","NL","NLD","Zuid-Holland","","9541","1528878794"
"Shelton","Shelton","47.2186","-123.1121","United States","US","USA","Washington","","10288","1840021123"
"Fenggeling","Fenggeling","34.5312","106.4437","China","CN","CHN","Shaanxi","","10250","1156678772"
"San José de Feliciano","San Jose de Feliciano","-30.3833","-58.7500","Argentina","AR","ARG","Entre Ríos","minor","10282","1032667894"
"Fiumefreddo di Sicilia","Fiumefreddo di Sicilia","37.8000","15.2000","Italy","IT","ITA","Sicilia","","9505","1380692262"
"Bomareddipalli","Bomareddipalli","18.7044","79.1568","India","IN","IND","Andhra Pradesh","","9998","1356564798"
"Minamiaso","Minamiaso","32.8167","131.0333","Japan","JP","JPN","Kumamoto","","10212","1392003193"
"Corfe Mullen","Corfe Mullen","50.7701","-2.0175","United Kingdom","GB","GBR","Dorset","","10133","1826707592"
"Sanjāt","Sanjat","25.6046","86.0199","India","IN","IND","Bihār","","8739","1356204851"
"Jogaili","Jogaili","25.9096","87.4061","India","IN","IND","Bihār","","9188","1356256953"
"Bāsmanpur","Basmanpur","26.6433","84.9789","India","IN","IND","Bihār","","8817","1356825918"
"Miradouro","Miradouro","-20.8908","-42.3428","Brazil","BR","BRA","Minas Gerais","","10251","1076981625"
"Dhānga","Dhanga","26.4630","86.0452","India","IN","IND","Bihār","","9438","1356013044"
"Sant’Egidio alla Vibrata","Sant'Egidio alla Vibrata","42.8333","13.7167","Italy","IT","ITA","Abruzzo","","9753","1380419333"
"Chivhu","Chivhu","-19.0000","30.9000","Zimbabwe","ZW","ZWE","Mashonaland East","","10263","1716626042"
"Bhirua","Bhirua","25.8509","86.3319","India","IN","IND","Bihār","","8974","1356256650"
"Caetanópolis","Caetanopolis","-19.2950","-44.4189","Brazil","BR","BRA","Minas Gerais","","10218","1076848044"
"Ouistreham","Ouistreham","49.2792","-0.2583","France","FR","FRA","Normandie","","9344","1250205625"
"Enriquillo","Enriquillo","17.9000","-71.2333","Dominican Republic","DO","DOM","","","10254","1214173250"
"Sātulūru","Satuluru","16.2541","80.1214","India","IN","IND","Andhra Pradesh","","9749","1356993991"
"Pliezhausen","Pliezhausen","48.5586","9.2058","Germany","DE","DEU","Baden-Württemberg","","9721","1276097407"
"Ertil","Ertil","51.8500","40.8000","Russia","RU","RUS","Voronezhskaya Oblast’","","10265","1643566170"
"Pushpattūr","Pushpattur","10.5438","77.4100","India","IN","IND","Tamil Nādu","","9831","1356171131"
"Brewster","Brewster","41.7463","-70.0676","United States","US","USA","Massachusetts","","10282","1840053498"
"Hirni","Hirni","25.8323","86.2806","India","IN","IND","Bihār","","8767","1356254192"
"Mondaí","Mondai","-27.1028","-53.4019","Brazil","BR","BRA","Santa Catarina","","10231","1076954826"
"Advi Devalpalli","Advi Devalpalli","16.6631","79.5139","India","IN","IND","Andhra Pradesh","","10088","1356052912"
"Karariyā","Karariya","26.5249","84.7910","India","IN","IND","Bihār","","8846","1356285122"
"São Romão","Sao Romao","-16.3689","-45.0689","Brazil","BR","BRA","Minas Gerais","","10276","1076057326"
"Belpāra","Belpara","20.5889","82.9729","India","IN","IND","Odisha","","10016","1356356860"
"Kenafif","Kenafif","30.4167","-9.0333","Morocco","MA","MAR","Souss-Massa","","9936","1504378442"
"Paranacity","Paranacity","-22.9300","-52.1508","Brazil","BR","BRA","Paraná","","10250","1076613989"
"Parnaguá","Parnagua","-10.2269","-44.6389","Brazil","BR","BRA","Piauí","","10276","1076238143"
"Spout Springs","Spout Springs","35.2724","-79.0331","United States","US","USA","North Carolina","","10279","1840090627"
"Sapkyo","Sapkyo","36.6864","126.7367","South Korea","KR","KOR","Chungnam","","10075","1410000740"
"Guadalupe","Guadalupe","-6.7869","-43.5689","Brazil","BR","BRA","Piauí","","10268","1076819402"
"Waverly","Waverly","42.7250","-92.4708","United States","US","USA","Iowa","","10278","1840000404"
"Selkirk","Selkirk","50.1436","-96.8839","Canada","CA","CAN","Manitoba","","10278","1124499880"
"Saint-Félicien","Saint-Felicien","48.6500","-72.4500","Canada","CA","CAN","Quebec","","10278","1124555496"
"Primavera","Primavera","-0.9428","-47.1158","Brazil","BR","BRA","Pará","","10268","1076377239"
"Bierbeek","Bierbeek","50.8333","4.7667","Belgium","BE","BEL","Flanders","","10025","1056157543"
"Piranhas","Piranhas","-16.4269","-51.8219","Brazil","BR","BRA","Goiás","","10272","1076951314"
"Heubach","Heubach","48.7881","9.9333","Germany","DE","DEU","Baden-Württemberg","","9892","1276568606"
"Tūlin","Tulin","23.3700","85.9000","India","IN","IND","West Bengal","","9844","1356533903"
"Rewāhi","Rewahi","26.2698","87.2124","India","IN","IND","Bihār","","8882","1356134929"
"East Bradford","East Bradford","39.9590","-75.6469","United States","US","USA","Pennsylvania","","10275","1840149882"
"São Francisco","Sao Francisco","-5.1228","-47.3858","Brazil","BR","BRA","Maranhão","","10261","1076295743"
"Lillers","Lillers","50.5636","2.4819","France","FR","FRA","Hauts-de-France","","9910","1250000845"
"Burgum","Burgum","53.2000","6.0000","Netherlands","NL","NLD","Fryslân","minor","10058","1528529057"
"Tabubil","Tabubil","-5.2750","141.2264","Papua New Guinea","PG","PNG","Western","","10270","1598326031"
"Erada","Erada","-25.2833","45.9333","Madagascar","MG","MDG","Toliara","","10000","1450445001"
"Sihanamaro","Sihanamaro","-25.1833","45.7667","Madagascar","MG","MDG","Toliara","","10000","1450305004"
"Magstadt","Magstadt","48.7422","8.9650","Germany","DE","DEU","Baden-Württemberg","","9763","1276943465"
"Slatina","Slatina","45.7022","17.7011","Croatia","HR","HRV","Virovitičko-Podravska Županija","minor","10208","1191732993"
"Kodikkulam","Kodikkulam","9.9811","78.2121","India","IN","IND","Tamil Nādu","","8910","1356232809"
"Ghāriyah al Gharbīyah","Ghariyah al Gharbiyah","32.6828","36.2233","Syria","SY","SYR","Dar‘ā","","9784","1760612847"
"Amparihitsokatra","Amparihitsokatra","-17.5167","48.5667","Madagascar","MG","MDG","Toamasina","","10000","1450080667"
"Ennamangalam","Ennamangalam","11.6449","77.5930","India","IN","IND","Tamil Nādu","","9946","1356163377"
"Linthicum","Linthicum","39.2088","-76.6626","United States","US","USA","Maryland","","10270","1840005921"
"Jangalapalle","Jangalapalle","14.8844","79.9107","India","IN","IND","Andhra Pradesh","","10088","1356096265"
"Erenler","Erenler","38.8197","30.5406","Turkey","TR","TUR","Afyonkarahisar","","9973","1792934022"
"Vétraz-Monthoux","Vetraz-Monthoux","46.1742","6.2550","France","FR","FRA","Auvergne-Rhône-Alpes","","9003","1250512324"
"Iizuna","Iizuna","36.7547","138.2355","Japan","JP","JPN","Nagano","","10134","1392999975"
"Madridejos","Madridejos","39.4667","-3.5333","Spain","ES","ESP","Castille-La Mancha","","10230","1724797176"
"Sewa","Sewa","24.8753","86.3257","India","IN","IND","Bihār","","9185","1356258442"
"Carice","Carice","19.3833","-71.8333","Haiti","HT","HTI","Nord-Est","","10180","1332044879"
"Ommangi","Ommangi","17.2597","82.2236","India","IN","IND","Andhra Pradesh","","9870","1356850356"
"Manubolu","Manubolu","14.1833","79.8833","India","IN","IND","Andhra Pradesh","","9838","1356151260"
"Elkhorn","Elkhorn","42.6713","-88.5377","United States","US","USA","Wisconsin","","10267","1840002480"
"Kapuvár","Kapuvar","47.6000","17.0333","Hungary","HU","HUN","Győr-Moson-Sopron","minor","10161","1348249118"
"Mālingaon","Malingaon","26.5467","88.3337","India","IN","IND","Bihār","","9132","1356422505"
"Ban Pa Hung","Ban Pa Hung","19.5672","99.7013","Thailand","TH","THA","Chiang Rai","","10138","1764832108"
"Pachchāmpālaiyam","Pachchampalaiyam","11.5723","77.6096","India","IN","IND","Tamil Nādu","","9939","1356163575"
"Rockport","Rockport","28.0290","-97.0722","United States","US","USA","Texas","","10264","1840020999"
"Rum","Rum","47.2872","11.4581","Austria","AT","AUT","Tirol","","9190","1040726454"
"Busca","Busca","44.5167","7.4667","Italy","IT","ITA","Piedmont","","10110","1380319466"
"Bareh","Bareh","26.6786","78.1887","India","IN","IND","Madhya Pradesh","","9716","1356951273"
"Garden City","Garden City","32.0867","-81.1773","United States","US","USA","Georgia","","10263","1840013839"
"Hawkesbury","Hawkesbury","45.6000","-74.6000","Canada","CA","CAN","Ontario","","10263","1124065659"
"Steinen","Steinen","47.6453","7.7403","Germany","DE","DEU","Baden-Württemberg","","10049","1276597549"
"Soverato Marina","Soverato Marina","38.6833","16.5500","Italy","IT","ITA","Calabria","","9095","1380490328"
"Oster","Oster","50.9486","30.8811","Ukraine","UA","UKR","Chernihivska Oblast","","10245","1804570315"
"Adamankottai","Adamankottai","12.0742","78.1237","India","IN","IND","Tamil Nādu","","9638","1356256995"
"Amritpur","Amritpur","28.1167","81.6000","Nepal","NP","NPL","","","9680","1524026946"
"Bimāwān","Bimawan","25.5111","84.4946","India","IN","IND","Bihār","","9086","1356282931"
"Dhanauli","Dhanauli","26.0026","86.0251","India","IN","IND","Bihār","","8743","1356510835"
"Kenār","Kenar","25.2916","85.7416","India","IN","IND","Bihār","","9093","1356045259"
"Carroll","Carroll","42.0699","-94.8646","United States","US","USA","Iowa","","10261","1840000490"
"Vīrapāndiyanpattanam","Virapandiyanpattanam","8.5200","78.1181","India","IN","IND","Tamil Nādu","","9556","1356214150"
"São João d’Aliança","Sao Joao d'Alianca","-14.7058","-47.5250","Brazil","BR","BRA","Goiás","","10257","1076043167"
"Kearney","Kearney","39.3550","-94.3599","United States","US","USA","Missouri","","10260","1840008501"
"Halfway","Halfway","39.6162","-77.7703","United States","US","USA","Maryland","","10259","1840005606"
"Fontoura Xavier","Fontoura Xavier","-28.9828","-52.3458","Brazil","BR","BRA","Rio Grande do Sul","","10241","1076928068"
"Moimenta da Beira","Moimenta da Beira","40.9797","-7.6136","Portugal","PT","PRT","Viseu","minor","10212","1620112700"
"Shankarpur","Shankarpur","26.1821","87.1552","India","IN","IND","Bihār","","8849","1356422287"
"Birkenau","Birkenau","49.5607","8.7061","Germany","DE","DEU","Hesse","","9857","1276778911"
"Karajgi","Karajgi","14.8641","75.4535","India","IN","IND","Karnātaka","","9910","1356050075"
"Pires Ferreira","Pires Ferreira","-4.2469","-40.6450","Brazil","BR","BRA","Ceará","","10216","1076964579"
"Três Cachoeiras","Tres Cachoeiras","-29.4558","-49.9239","Brazil","BR","BRA","Rio Grande do Sul","","10217","1076760504"
"Jiji","Jiji","23.8300","120.7830","Taiwan","TW","TWN","Nantou","","10056","1158172698"
"Minatitlán","Minatitlan","19.3833","-104.0500","Mexico","MX","MEX","Colima","minor","10231","1484015579"
"Pabégou","Pabegou","9.8333","1.5492","Benin","BJ","BEN","Donga","","10156","1204757820"
"Saint-Jean-le-Blanc","Saint-Jean-le-Blanc","47.8919","1.9172","France","FR","FRA","Centre-Val de Loire","","9070","1250437162"
"Francisco Badaró","Francisco Badaro","-16.9928","-42.3519","Brazil","BR","BRA","Minas Gerais","","10248","1076185124"
"Lonquimay","Lonquimay","-38.4333","-71.2333","Chile","CL","CHL","Araucanía","","10251","1152602801"
"Bhāgsar","Bhagsar","30.4417","74.4023","India","IN","IND","Punjab","","9636","1356015846"
"Lanškroun","Lanskroun","49.9122","16.6120","Czechia","CZ","CZE","Pardubický Kraj","","9800","1203727130"
"Kāttāgaram","Kattagaram","12.4110","78.3153","India","IN","IND","Tamil Nādu","","9758","1356744302"
"Itapé","Itape","-14.8978","-39.4208","Brazil","BR","BRA","Bahia","","10228","1076267956"
"Lūgovoy","Lugovoy","42.9472","72.7644","Kazakhstan","KZ","KAZ","","","10242","1398256452"
"Meuselwitz","Meuselwitz","51.0500","12.3000","Germany","DE","DEU","Thuringia","","10065","1276499309"
"Ankalgi","Ankalgi","16.0333","74.7000","India","IN","IND","Karnātaka","","9798","1356759550"
"Tiana","Tiana","41.4831","2.2697","Spain","ES","ESP","Catalonia","","9110","1724733828"
"Alāwalpur","Alawalpur","31.4967","75.5309","India","IN","IND","Punjab","","9467","1356112709"
"Martinópole","Martinopole","-3.2258","-40.6969","Brazil","BR","BRA","Ceará","","10214","1076944697"
"Mucugê","Mucuge","-13.0050","-41.3708","Brazil","BR","BRA","Bahia","","10244","1076156452"
"Rangasamudram","Rangasamudram","14.9852","78.9765","India","IN","IND","Andhra Pradesh","","10049","1356170541"
"Rājhanpur","Rajhanpur","25.7885","86.4647","India","IN","IND","Bihār","","8627","1356260361"
"La’tamna","La'tamna","31.9100","-7.3400","Morocco","MA","MAR","Marrakech-Safi","","10063","1504778865"
"Ban Kang","Ban Kang","18.5444","98.8889","Thailand","TH","THA","Chiang Mai","","9676","1764006978"
"Elmas","Elmas","39.2667","9.0500","Italy","IT","ITA","Sardegna","","9546","1380338965"
"Were Īlu","Were Ilu","10.6000","39.4333","Ethiopia","ET","ETH","Āmara","","10062","1231595190"
"Kengarai","Kengarai","11.4144","76.9277","India","IN","IND","Tamil Nādu","","9985","1356649330"
"Marawī","Marawi","18.4833","31.8167","Sudan","SD","SDN","Northern","","10234","1729782394"
"Bisignano","Bisignano","39.5167","16.2833","Italy","IT","ITA","Calabria","","10128","1380425072"
"Ronda Alta","Ronda Alta","-27.7669","-52.8019","Brazil","BR","BRA","Rio Grande do Sul","","10221","1076110124"
"Divonne-les-Bains","Divonne-les-Bains","46.3567","6.1428","France","FR","FRA","Auvergne-Rhône-Alpes","","9951","1250225369"
"Palafolls","Palafolls","41.6692","2.7506","Spain","ES","ESP","Catalonia","","9661","1724813840"
"Columbia","Columbia","40.0347","-76.4944","United States","US","USA","Pennsylvania","","10243","1840003713"
"Fanzhao","Fanzhao","26.6615","108.3834","China","CN","CHN","Guizhou","","10165","1156002529"
"Bandixon","Bandixon","37.8614","67.3861","Uzbekistan","UZ","UZB","Surxondaryo","","10110","1860730095"
"Santa María","Santa Maria","2.9500","-75.6500","Colombia","CO","COL","Huila","","10215","1170000610"
"Salvatierra de Miño","Salvatierra de Mino","42.0833","-8.5000","Spain","ES","ESP","Galicia","","10079","1724003151"
"Chegūr","Chegur","17.1758","78.2298","India","IN","IND","Andhra Pradesh","","9870","1356982116"
"Andergrove","Andergrove","-21.0931","149.1860","Australia","AU","AUS","Queensland","","9419","1036907443"
"Caspe","Caspe","41.2333","-0.0333","Spain","ES","ESP","Aragon","","10220","1724962695"
"Nishi","Nishi","32.2011","130.8411","Japan","JP","JPN","Kumamoto","","10121","1392580790"
"Boriguma","Boriguma","19.0468","82.5532","India","IN","IND","Chhattīsgarh","","9844","1356116903"
"Senhora dos Remédios","Senhora dos Remedios","-21.0278","-43.5828","Brazil","BR","BRA","Minas Gerais","","10196","1076268032"
"Chaungtha","Chaungtha","16.9667","94.4500","Myanmar","MM","MMR","Ayeyarwady","","10181","1104467374"
"Radyvyliv","Radyvyliv","50.1286","25.2644","Ukraine","UA","UKR","Rivnenska Oblast","","10190","1804330249"
"Hadiāya","Hadiaya","30.3413","75.5117","India","IN","IND","Punjab","","9725","1356571025"
"Fountain Inn","Fountain Inn","34.6989","-82.2005","United States","US","USA","South Carolina","","10237","1840013500"
"Ittikelakunta","Ittikelakunta","16.6797","81.7094","India","IN","IND","Andhra Pradesh","","9568","1356617908"
"Beverly Hills","Beverly Hills","28.9176","-82.4542","United States","US","USA","Florida","","10236","1840014061"
"Viravāda","Viravada","17.1194","82.2110","India","IN","IND","Andhra Pradesh","","9068","1356071029"
"Charalá","Charala","6.2500","-73.0833","Colombia","CO","COL","Santander","minor","10211","1170539322"
"Luza","Luza","60.6500","47.2833","Russia","RU","RUS","Kirovskaya Oblast’","","10232","1643009448"
"Caracol","Caracol","-9.2789","-43.3300","Brazil","BR","BRA","Piauí","","10212","1076578304"
"Hosur","Hosur","15.8201","74.9301","India","IN","IND","Karnātaka","","9981","1356802606"
"Tanggemu Nongchang","Tanggemu Nongchang","36.0750","100.0937","China","CN","CHN","Qinghai","","10214","1156344992"
"Balangkayan","Balangkayan","11.4728","125.5108","Philippines","PH","PHL","Eastern Samar","","10185","1608965885"
"Xishrov","Xishrov","39.6383","66.8897","Uzbekistan","UZ","UZB","Samarqand","","10000","1860278182"
"Wingles","Wingles","50.4942","2.8553","France","FR","FRA","Hauts-de-France","","8756","1250695683"
"Cuorgnè","Cuorgne","45.3897","7.6498","Italy","IT","ITA","Piedmont","","9728","1380069740"
"Oppeano","Oppeano","45.3000","11.1833","Italy","IT","ITA","Veneto","","10017","1380024530"
"Kaimāti","Kaimati","26.0854","89.6150","India","IN","IND","Assam","","9882","1356917958"
"Pôrto Xavier","Porto Xavier","-27.9058","-55.1378","Brazil","BR","BRA","Rio Grande do Sul","","10194","1076184163"
"Sedico","Sedico","46.1167","12.1000","Italy","IT","ITA","Veneto","","10119","1380025341"
"Tarashcha","Tarashcha","49.5500","30.5000","Ukraine","UA","UKR","Kyivska Oblast","minor","10195","1804874474"
"Eumseong","Eumseong","36.9353","127.6897","South Korea","KR","KOR","Chungbuk","","10077","1410812829"
"Blumberg","Blumberg","47.8392","8.5342","Germany","DE","DEU","Baden-Württemberg","","10127","1276268293"
"Kanchanpur","Kanchanpur","24.6096","84.2361","India","IN","IND","Bihār","","9758","1356197058"
"Honwāda","Honwada","16.7333","77.9333","India","IN","IND","Andhra Pradesh","","9916","1356033791"
"Kamdoli","Kamdoli","15.2048","75.2549","India","IN","IND","Karnātaka","","9935","1356432660"
"Ekhari","Ekhari","26.5246","86.3071","India","IN","IND","Bihār","","9535","1356203834"
"Algarrobo","Algarrobo","-33.3911","-71.6928","Chile","CL","CHL","Valparaíso","","10217","1152829157"
"Makri","Makri","24.3860","83.5559","India","IN","IND","Jhārkhand","","9676","1356113647"
"Redentora","Redentora","-27.6639","-53.6378","Brazil","BR","BRA","Rio Grande do Sul","","10222","1076814918"
"Mariluz","Mariluz","-24.0019","-53.1458","Brazil","BR","BRA","Paraná","","10224","1076480986"
"Qovlar","Qovlar","40.9419","45.7358","Azerbaijan","AZ","AZE","Tovuz","","10130","1031341144"
"Roberval","Roberval","48.5200","-72.2300","Canada","CA","CAN","Quebec","","10227","1124395055"
"Cumaru","Cumaru","-8.0058","-35.6969","Brazil","BR","BRA","Pernambuco","","10192","1076365965"
"Dhauni","Dhauni","24.9901","87.0011","India","IN","IND","Bihār","","9015","1356362943"
"Kannāndahalli","Kannandahalli","12.4164","78.3803","India","IN","IND","Tamil Nādu","","9704","1356099078"
"Doiwāla","Doiwala","30.1760","78.1240","India","IN","IND","Uttarākhand","","8709","1356364330"
"Velpūru","Velpuru","16.1775","80.7425","India","IN","IND","Andhra Pradesh","","9796","1356294147"
"North Bend","North Bend","43.4075","-124.2364","United States","US","USA","Oregon","","10224","1840020057"
"Sniatyn","Sniatyn","48.4500","25.5667","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","9942","1804025263"
"Cypress Gardens","Cypress Gardens","28.0036","-81.6857","United States","US","USA","Florida","","10223","1840028660"
"Sainte-Agathe-des-Monts","Sainte-Agathe-des-Monts","46.0500","-74.2800","Canada","CA","CAN","Quebec","","10223","1124041166"
"Shyroke","Shyroke","47.6882","33.2654","Ukraine","UA","UKR","Dnipropetrovska Oblast","minor","10202","1804425378"
"Padbury","Padbury","51.9710","-0.9520","United Kingdom","GB","GBR","Buckinghamshire","","10127","1826308435"
"Borzna","Borzna","51.2539","32.4261","Ukraine","UA","UKR","Chernihivska Oblast","","10205","1804695729"
"San Miguel Sigüilá","San Miguel Siguila","14.9000","-91.6167","Guatemala","GT","GTM","Quetzaltenango","minor","9869","1320294122"
"Spelle","Spelle","52.3667","7.4667","Germany","DE","DEU","Lower Saxony","","9931","1276461336"
"Ammanford","Ammanford","51.8000","-3.9930","United Kingdom","GB","GBR","Carmarthenshire","","8610","1826559160"
"Jemaat Oulad Mhamed","Jemaat Oulad Mhamed","33.0938","-7.0519","Morocco","MA","MAR","Casablanca-Settat","","10187","1504791929"
"Hackettstown","Hackettstown","40.8540","-74.8257","United States","US","USA","New Jersey","","10221","1840003573"
"Ain Karma","Ain Karma","34.0071","-5.6182","Morocco","MA","MAR","Fès-Meknès","","9738","1504336348"
"Marāi Kalān","Marai Kalan","24.0211","85.3506","India","IN","IND","Jhārkhand","","9071","1356619726"
"Foz","Foz","43.5694","-7.2583","Spain","ES","ESP","Galicia","","10118","1724292165"
"Elambalūr","Elambalur","11.2669","78.8816","India","IN","IND","Tamil Nādu","","9719","1356251515"
"Jocoro","Jocoro","13.6167","-88.0167","El Salvador","SV","SLV","Morazán","","10060","1222901966"
"Calasparra","Calasparra","38.2306","-1.7000","Spain","ES","ESP","Murcia","","10163","1724622163"
"Bendrahallī","Bendrahalli","12.2642","78.3223","India","IN","IND","Tamil Nādu","","9639","1356005402"
"Syców","Sycow","51.3100","17.7236","Poland","PL","POL","Dolnośląskie","","10102","1616409792"
"Majhariyā Sheikh","Majhariya Sheikh","26.7356","84.6420","India","IN","IND","Bihār","","8879","1356452440"
"Msoga","Msoga","-6.5667","38.3000","Tanzania","TZ","TZA","Coast","","10144","1834863543"
"Teplodar","Teplodar","46.5036","30.3244","Ukraine","UA","UKR","Odeska Oblast","","10148","1804955869"
"Mangasamudram","Mangasamudram","13.2291","79.0909","India","IN","IND","Andhra Pradesh","","9146","1356354507"
"Mstsislaw","Mstsislaw","54.0167","31.7167","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","10201","1112491469"
"Eleşkirt","Eleskirt","39.7981","42.6744","Turkey","TR","TUR","Ağrı","minor","10191","1792340352"
"Highland","Highland","38.7602","-89.6812","United States","US","USA","Illinois","","10215","1840007425"
"North Dumfries","North Dumfries","43.3200","-80.3800","Canada","CA","CAN","Ontario","","10215","1124000802"
"Göytəpə","Goytapa","39.1167","48.5953","Azerbaijan","AZ","AZE","Cəlilabad","","10067","1031848361"
"Tefenni","Tefenni","37.3111","29.7744","Turkey","TR","TUR","Burdur","minor","10201","1792992048"
"Beli Manastir","Beli Manastir","45.7667","18.6000","Croatia","HR","HRV","Osječko-Baranjska Županija","minor","10068","1191568298"
"Mādarpākkam","Madarpakkam","13.4439","80.0018","India","IN","IND","Tamil Nādu","","9637","1356304896"
"Hooglede","Hooglede","50.9781","3.0817","Belgium","BE","BEL","Flanders","","9950","1056206386"
"San Marcos","San Marcos","9.6703","-84.0092","Costa Rica","CR","CRI","San José","","9993","1188432245"
"Carqueiranne","Carqueiranne","43.0950","6.0736","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9555","1250664228"
"Lohafary","Lohafary","-23.2500","47.4333","Madagascar","MG","MDG","Fianarantsoa","","10000","1450370124"
"Ammanabrolu","Ammanabrolu","15.5802","80.1488","India","IN","IND","Andhra Pradesh","","9938","1356545605"
"Medina","Medina","4.5092","-73.3494","Colombia","CO","COL","Cundinamarca","minor","10205","1170855873"
"Tutzing","Tutzing","47.9089","11.2814","Germany","DE","DEU","Bavaria","","9933","1276343820"
"Potosí","Potosi","0.8081","-77.5728","Colombia","CO","COL","Nariño","minor","10186","1170814336"
"Ankadindambo","Ankadindambo","-21.4667","47.1500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450657492"
"Houthulst","Houthulst","50.9783","2.9506","Belgium","BE","BEL","Flanders","","10032","1056273463"
"Rehburg-Loccum","Rehburg-Loccum","52.4508","9.2078","Germany","DE","DEU","Lower Saxony","","10110","1276006999"
"Lagoa da Confusão","Lagoa da Confusao","-10.7939","-49.6239","Brazil","BR","BRA","Tocantins","","10210","1076932290"
"Chestnut Ridge","Chestnut Ridge","41.0829","-74.0551","United States","US","USA","New York","","10211","1840004992"
"Smithville","Smithville","39.3919","-94.5747","United States","US","USA","Missouri","","10211","1840009605"
"Caudete","Caudete","38.7044","-0.9881","Spain","ES","ESP","Castille-La Mancha","","10135","1724551551"
"Shioya","Shioya","36.7776","139.8506","Japan","JP","JPN","Tochigi","","10152","1392003532"
"Governador Archer","Governador Archer","-5.0219","-44.2708","Brazil","BR","BRA","Maranhão","","10205","1076355007"
"Plymouth","Plymouth","41.3483","-86.3187","United States","US","USA","Indiana","","10209","1840014170"
"Alpine","Alpine","40.4629","-111.7724","United States","US","USA","Utah","","10209","1840018755"
"Mosquera","Mosquera","2.4903","-78.4953","Colombia","CO","COL","Nariño","minor","10203","1170583379"
"Ainring","Ainring","47.8156","12.9429","Germany","DE","DEU","Bavaria","","9908","1276077892"
"Peryavaram","Peryavaram","13.9317","79.6075","India","IN","IND","Andhra Pradesh","","9902","1356459400"
"Lovejoy","Lovejoy","33.4426","-84.3177","United States","US","USA","Georgia","","10208","1840015704"
"São João","Sao Joao","-25.8278","-52.7250","Brazil","BR","BRA","Paraná","","10181","1076110241"
"Rideau Lakes","Rideau Lakes","44.6667","-76.2167","Canada","CA","CAN","Ontario","","10207","1124000369"
"Tolmezzo","Tolmezzo","46.4000","13.0167","Italy","IT","ITA","Friuli Venezia Giulia","","10051","1380228864"
"Asola","Asola","45.2167","10.4167","Italy","IT","ITA","Lombardy","","10069","1380570260"
"Vernon","Vernon","34.1479","-99.3000","United States","US","USA","Texas","","10206","1840021912"
"Yelm","Yelm","46.9398","-122.6262","United States","US","USA","Washington","","10205","1840021146"
"Pechea","Pechea","45.6333","27.8000","Romania","RO","ROU","Galaţi","","10126","1642407304"
"Molsheim","Molsheim","48.5428","7.4922","France","FR","FRA","Grand Est","minor","9343","1250680488"
"Pleasant Hill","Pleasant Hill","41.5867","-93.4953","United States","US","USA","Iowa","","10203","1840009219"
"Castellamonte","Castellamonte","45.3820","7.7121","Italy","IT","ITA","Piedmont","","9946","1380202653"
"Nārāyanraopet","Narayanraopet","18.2114","78.7794","India","IN","IND","Andhra Pradesh","","9486","1356128419"
"Paris","Paris","38.2016","-84.2718","United States","US","USA","Kentucky","","10202","1840014315"
"Tipp City","Tipp City","39.9644","-84.1866","United States","US","USA","Ohio","","10202","1840010507"
"Comstock Park","Comstock Park","43.0438","-85.6778","United States","US","USA","Michigan","","10202","1840004307"
"Kushijima","Kushijima","32.7401","130.7573","Japan","JP","JPN","Kumamoto","","9624","1392625764"
"Olovo","Olovo","44.1275","18.5800","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","10175","1070868071"
"Mahabo-Mananivo","Mahabo-Mananivo","-23.1833","47.6833","Madagascar","MG","MDG","Fianarantsoa","","10000","1450871023"
"Jāmunia","Jamunia","25.3676","87.0477","India","IN","IND","Bihār","","9075","1356208773"
"Patūt","Patut","25.4658","84.8140","India","IN","IND","Bihār","","8999","1356012603"
"Msila","Msila","35.2000","-5.5667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10153","1504170765"
"Aleşd","Alesd","47.0572","22.3969","Romania","RO","ROU","Bihor","","10066","1642008624"
"Itamogi","Itamogi","-21.0778","-47.0478","Brazil","BR","BRA","Minas Gerais","","10157","1076902306"
"Sechelt","Sechelt","49.4742","-123.7542","Canada","CA","CAN","British Columbia","","10200","1124845591"
"Tiruvāduturai","Tiruvaduturai","11.0379","79.5215","India","IN","IND","Tamil Nādu","","9362","1356244061"
"Cetraro","Cetraro","39.5000","15.9500","Italy","IT","ITA","Calabria","","10048","1380260708"
"San Blas","San Blas","21.5397","-105.2856","Mexico","MX","MEX","Nayarit","minor","10187","1484142814"
"Gunbarrel","Gunbarrel","40.0632","-105.1713","United States","US","USA","Colorado","","10199","1840028424"
"São Pedro do Ivaí","Sao Pedro do Ivai","-23.8650","-51.8558","Brazil","BR","BRA","Paraná","","10167","1076420982"
"Yermolino","Yermolino","55.2000","36.6000","Russia","RU","RUS","Kaluzhskaya Oblast’","","10120","1643925447"
"Satravāda","Satravada","13.3201","79.5488","India","IN","IND","Andhra Pradesh","","9303","1356454910"
"Nong Ki","Nong Ki","14.6867","102.5325","Thailand","TH","THA","Buri Ram","minor","10089","1764002755"
"Kulundu","Kulundu","40.1069","69.6431","Kyrgyzstan","KG","KGZ","Batken","minor","10170","1417968397"
"Tovāla","Tovala","8.2482","77.5278","India","IN","IND","Tamil Nādu","","9828","1356172711"
"Tuminkatti","Tuminkatti","14.4131","75.6206","India","IN","IND","Karnātaka","","9841","1356375094"
"Versailles","Versailles","38.0486","-84.7258","United States","US","USA","Kentucky","","10196","1840015212"
"Fort Oglethorpe","Fort Oglethorpe","34.9318","-85.2460","United States","US","USA","Georgia","","10196","1840013554"
"Aïn Zohra","Ain Zohra","34.1000","-6.3500","Morocco","MA","MAR","Rabat-Salé-Kénitra","","10151","1504551981"
"Pullach im Isartal","Pullach im Isartal","48.0500","11.5167","Germany","DE","DEU","Bavaria","","8983","1276150760"
"Tissaf","Tissaf","33.4000","-3.5833","Morocco","MA","MAR","Fès-Meknès","","10191","1504419451"
"Auterive","Auterive","43.3503","1.4747","France","FR","FRA","Occitanie","","9923","1250506554"
"Itamukkala","Itamukkala","15.3731","80.1130","India","IN","IND","Andhra Pradesh","","9839","1356306377"
"Vigasio","Vigasio","45.3167","10.9333","Italy","IT","ITA","Veneto","","10134","1380927639"
"Kolnūr","Kolnur","18.5095","79.4260","India","IN","IND","Andhra Pradesh","","9754","1356969014"
"Valkurti","Valkurti","18.7171","79.3823","India","IN","IND","Andhra Pradesh","","9196","1356028912"
"Lint","Lint","51.1167","4.4833","Belgium","BE","BEL","Flanders","","8641","1056535709"
"Friendly","Friendly","38.7601","-76.9642","United States","US","USA","Maryland","","10192","1840005954"
"Mikhaylov","Mikhaylov","54.2333","39.0333","Russia","RU","RUS","Ryazanskaya Oblast’","","10174","1643366422"
"Aldenham","Aldenham","51.6723","-0.3546","United Kingdom","GB","GBR","Hertfordshire","","9942","1826963300"
"Basāpatna","Basapatna","15.4302","76.4869","India","IN","IND","Karnātaka","","9808","1356001903"
"Skidal’","Skidal'","53.5861","24.2519","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","","10168","1112017248"
"Cabriès","Cabries","43.4411","5.3797","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9918","1250068998"
"Beccles","Beccles","52.4580","1.5630","United Kingdom","GB","GBR","Suffolk","","10123","1826744549"
"Nandamūru","Nandamuru","16.6400","81.9800","India","IN","IND","Andhra Pradesh","","9566","1356386651"
"Kokiladānga","Kokiladanga","26.3452","89.5157","India","IN","IND","West Bengal","","9839","1356749040"
"Berthoud","Berthoud","40.3071","-105.0426","United States","US","USA","Colorado","","10188","1840020153"
"Sātgāchia","Satgachia","23.2641","88.1600","India","IN","IND","West Bengal","","8733","1356971977"
"Vysokyi","Vysokyi","49.8850","36.1111","Ukraine","UA","UKR","Kharkivska Oblast","","10045","1804461053"
"Cingoli","Cingoli","43.3667","13.2167","Italy","IT","ITA","Marche","","10119","1380504372"
"Cheat Lake","Cheat Lake","39.6670","-79.8564","United States","US","USA","West Virginia","","10187","1840005645"
"San Sebastiano al Vesuvio","San Sebastiano al Vesuvio","40.8333","14.3667","Italy","IT","ITA","Campania","","9057","1380421532"
"Venkatāpuram","Venkatapuram","18.2232","79.9516","India","IN","IND","Andhra Pradesh","","10186","1356520107"
"Harrison","Harrison","40.6374","-79.7173","United States","US","USA","Pennsylvania","","10186","1840147154"
"Za’roura","Za'roura","35.2167","-5.6667","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","10137","1504534822"
"Takahama","Takahama","35.4903","135.5510","Japan","JP","JPN","Fukui","","10047","1392343755"
"Tocina","Tocina","37.6000","-5.7333","Spain","ES","ESP","Andalusia","","9508","1724656816"
"Catanduvas","Catanduvas","-25.2028","-53.1569","Brazil","BR","BRA","Paraná","","10167","1076928587"
"Gobindpura","Gobindpura","30.2805","75.0187","India","IN","IND","Punjab","","9547","1356182104"
"Antanananivo","Antanananivo","-16.3000","49.7500","Madagascar","MG","MDG","Toamasina","","10066","1450237966"
"Chilpur","Chilpur","18.2388","79.4529","India","IN","IND","Andhra Pradesh","","9591","1356017885"
"Standish","Standish","43.7811","-70.5684","United States","US","USA","Maine","","10183","1840053009"
"Milford","Milford","40.4291","-75.4153","United States","US","USA","Pennsylvania","","10183","1840151115"
"Sundarapāndiyam","Sundarapandiyam","9.6127","77.6738","India","IN","IND","Tamil Nādu","","9709","1356218421"
"Swāmimalai","Swamimalai","10.9575","79.3293","India","IN","IND","Tamil Nādu","","9216","1356632235"
"Bāsudebpur","Basudebpur","21.8256","87.6190","India","IN","IND","West Bengal","","9129","1356095667"
"Pādarti","Padarti","15.4690","80.1830","India","IN","IND","Andhra Pradesh","","9826","1356468708"
"Alpena","Alpena","45.0740","-83.4402","United States","US","USA","Michigan","","10181","1840003947"
"Přelouč","Prelouc","50.0399","15.5604","Czechia","CZ","CZE","Pardubický Kraj","","9875","1203203039"
"Almoloya del Río","Almoloya del Rio","19.1586","-99.4886","Mexico","MX","MEX","Morelos","minor","8939","1484000708"
"Uchtepa Qishlog’i","Uchtepa Qishlog'i","40.2050","67.9011","Uzbekistan","UZ","UZB","Jizzax","minor","10110","1860078852"
"Kokoszki","Kokoszki","54.3541","18.4915","Poland","PL","POL","Pomorskie","","9693","1616899678"
"Toca","Toca","5.5667","-73.2000","Colombia","CO","COL","Boyacá","minor","10157","1170029150"
"Annan","Annan","54.9830","-3.2660","United Kingdom","GB","GBR","Dumfries and Galloway","","8780","1826837081"
"Kawara","Kawara","33.6680","130.8473","Japan","JP","JPN","Fukuoka","","9955","1392191099"
"Oelsnitz","Oelsnitz","50.4167","12.1667","Germany","DE","DEU","Saxony","","9993","1276364084"
"Jāvagallu","Javagallu","13.3000","76.0667","India","IN","IND","Karnātaka","","9905","1356176073"
"Tut","Tut","37.7967","37.9150","Turkey","TR","TUR","Adıyaman","minor","10161","1792287032"
"Elūrupādu","Elurupadu","16.5167","81.3500","India","IN","IND","Andhra Pradesh","","9423","1356364562"
"Fort William","Fort William","56.8198","-5.1052","United Kingdom","GB","GBR","Highland","","10175","1826742184"
"Bayyavaram","Bayyavaram","17.6638","82.9420","India","IN","IND","Andhra Pradesh","","8953","1356090254"
"Kirkland","Kirkland","43.0368","-75.3865","United States","US","USA","New York","","10177","1840058218"
"Pińczów","Pinczow","50.5333","20.5333","Poland","PL","POL","Świętokrzyskie","minor","10078","1616074605"
"Souama","Souama","36.6417","4.3416","Algeria","DZ","DZA","Tizi Ouzou","","9954","1012240654"
"Le Passage","Le Passage","44.2014","0.6033","France","FR","FRA","Nouvelle-Aquitaine","","9447","1250772474"
"Sleepy Hollow","Sleepy Hollow","41.0936","-73.8722","United States","US","USA","New York","","10176","1840004944"
"Gambissara","Gambissara","13.2333","-14.3167","The Gambia","GM","GMB","Upper River","","10102","1270580590"
"Caatiba","Caatiba","-14.9769","-40.4078","Brazil","BR","BRA","Bahia","","10166","1076562452"
"Pariyāri","Pariyari","25.2128","84.7829","India","IN","IND","Bihār","","8595","1356677833"
"Ugargol","Ugargol","15.7800","75.1200","India","IN","IND","Karnātaka","","9921","1356143234"
"Remada","Remada","32.3061","10.3822","Tunisia","TN","TUN","Tataouine","","10173","1788796526"
"Ippagūdem","Ippagudem","17.7668","79.3751","India","IN","IND","Andhra Pradesh","","9892","1356151123"
"Novo Horizonte","Novo Horizonte","-11.7100","-62.0000","Brazil","BR","BRA","Rondônia","","10161","1076386709"
"Mālior","Malior","25.3900","87.8473","India","IN","IND","West Bengal","","8941","1356136425"
"Shpola","Shpola","49.0333","31.4167","Ukraine","UA","UKR","Cherkaska Oblast","","10133","1804610656"
"Naters","Naters","46.3237","7.9886","Switzerland","CH","CHE","Valais","","10073","1756559781"
"Granada","Granada","6.1470","-75.1880","Colombia","CO","COL","Antioquia","minor","10117","1170246805"
"Covasna","Covasna","45.8492","26.1853","Romania","RO","ROU","Covasna","","10114","1642162134"
"Tellār","Tellar","12.4011","79.5530","India","IN","IND","Tamil Nādu","","9713","1356127154"
"Yatton","Yatton","51.3855","-2.8256","United Kingdom","GB","GBR","North Somerset","","9176","1826709629"
"Mazamet","Mazamet","43.4917","2.3733","France","FR","FRA","Occitanie","","10033","1250230750"
"Garliava","Garliava","54.8167","23.8667","Lithuania","LT","LTU","Kaunas","","10110","1440296799"
"Batán","Batan","-38.0078","-57.7097","Argentina","AR","ARG","Buenos Aires","","10152","1032360995"
"Muurame","Muurame","62.1292","25.6722","Finland","FI","FIN","Keski-Suomi","minor","10097","1246611594"
"Jamunāmukh","Jamunamukh","26.1015","92.7426","India","IN","IND","Assam","","9387","1356575843"
"Chada","Chada","17.4992","79.0756","India","IN","IND","Andhra Pradesh","","9984","1356051593"
"Woodburn","Woodburn","38.8503","-77.2322","United States","US","USA","Virginia","","10168","1840024584"
"San Pablo","San Pablo","-40.4000","-73.0167","Chile","CL","CHL","Los Lagos","","10162","1152590185"
"Moree","Moree","-29.4650","149.8344","Australia","AU","AUS","New South Wales","","9311","1036377829"
"Siechnice","Siechnice","51.0367","17.1525","Poland","PL","POL","Dolnośląskie","","9957","1616477794"
"Nové Město na Moravě","Nove Mesto na Morave","49.5615","16.0742","Czechia","CZ","CZE","Vysočina","","10006","1203071196"
"Visbek","Visbek","52.8333","8.3167","Germany","DE","DEU","Lower Saxony","","10047","1276431474"
"Cachoeira dos Índios","Cachoeira dos Indios","-6.9269","-38.6739","Brazil","BR","BRA","Paraíba","","10114","1076916983"
"Kingaroy","Kingaroy","-26.5408","151.8394","Australia","AU","AUS","Queensland","","10020","1036463308"
"Ech Chaïbat","Ech Chaibat","31.6000","-7.8500","Morocco","MA","MAR","Marrakech-Safi","","9877","1504590724"
"Thāthūpur","Thathupur","25.9731","86.0296","India","IN","IND","Bihār","","8648","1356619478"
"Astoria","Astoria","46.1856","-123.8053","United States","US","USA","Oregon","","10166","1840018504"
"Hillsdale","Hillsdale","41.0074","-74.0440","United States","US","USA","New Jersey","","10166","1840003560"
"Racconigi","Racconigi","44.7667","7.6833","Italy","IT","ITA","Piedmont","","9958","1380738920"
"Fouesnant","Fouesnant","47.8933","-4.0122","France","FR","FRA","Bretagne","","9864","1250999266"
"Mālipākar","Malipakar","25.3567","87.9005","India","IN","IND","West Bengal","","8807","1356087413"
"Erquelinnes","Erquelinnes","50.3101","4.1219","Belgium","BE","BEL","Wallonia","","9940","1056578378"
"Laakirchen","Laakirchen","47.9828","13.8242","Austria","AT","AUT","Oberösterreich","","9861","1040055619"
"Majhaulia","Majhaulia","25.9635","85.5738","India","IN","IND","Bihār","","9084","1356856387"
"Zumárraga","Zumarraga","43.0831","-2.3167","Spain","ES","ESP","Basque Country","","9668","1724224861"
"Willstätt","Willstatt","48.5417","7.8964","Germany","DE","DEU","Baden-Württemberg","","9985","1276429606"
"Canápolis","Canapolis","-13.0700","-44.2019","Brazil","BR","BRA","Bahia","","10142","1076032947"
"Calçoene","Calcoene","2.4978","-50.9489","Brazil","BR","BRA","Amapá","","10163","1076001919"
"Bucine","Bucine","43.4833","11.6167","Italy","IT","ITA","Tuscany","","10087","1380795711"
"Caém","Caem","-11.1000","-40.4333","Brazil","BR","BRA","Bahia","","10143","1076157076"
"Phak Hai","Phak Hai","14.4626","100.3667","Thailand","TH","THA","Phra Nakhon Si Ayutthaya","minor","9905","1764882344"
"Holsbeek","Holsbeek","50.9167","4.7667","Belgium","BE","BEL","Flanders","","9905","1056025323"
"Sung Noen","Sung Noen","14.8965","101.8167","Thailand","TH","THA","Nakhon Ratchasima","minor","10061","1764245412"
"Citrus Springs","Citrus Springs","28.9931","-82.4594","United States","US","USA","Florida","","10162","1840014062"
"Gangaura Behra","Gangaura Behra","25.9541","86.5482","India","IN","IND","Bihār","","9220","1356031692"
"Heeze","Heeze","51.3825","5.5683","Netherlands","NL","NLD","Noord-Brabant","minor","9945","1528009442"
"Byarozawka","Byarozawka","53.7167","25.5000","Belarus","BY","BLR","Hrodzyenskaya Voblasts’","","10081","1112003239"
"Embrach","Embrach","47.5103","8.5933","Switzerland","CH","CHE","Zürich","","9419","1756762127"
"Ban Bu Sung","Ban Bu Sung","14.9602","104.1657","Thailand","TH","THA","Si Sa Ket","","10063","1764702503"
"Kāndra","Kandra","22.8517","86.0519","India","IN","IND","Jhārkhand","","8990","1356076257"
"Avalūrpet","Avalurpet","12.3800","79.3465","India","IN","IND","Tamil Nādu","","9684","1356111278"
"Orange Lake","Orange Lake","41.5369","-74.1005","United States","US","USA","New York","","10159","1840004859"
"Gakuch","Gakuch","36.1736","73.7667","Pakistan","PK","PAK","Gilgit-Baltistan","minor","10142","1586006258"
"Pepinster","Pepinster","50.5667","5.8167","Belgium","BE","BEL","Wallonia","","9765","1056265480"
"Bāghduma","Baghduma","24.8223","87.0116","India","IN","IND","Bihār","","9493","1356895977"
"Mainaschaff","Mainaschaff","49.9833","9.0833","Germany","DE","DEU","Bavaria","","8936","1276443692"
"West Vero Corridor","West Vero Corridor","27.6363","-80.4844","United States","US","USA","Florida","","10158","1840073271"
"Kele","Kele","6.0833","38.0333","Ethiopia","ET","ETH","Oromīya","","10084","1231687475"
"Bad Breisig","Bad Breisig","50.5092","7.2964","Germany","DE","DEU","Rhineland-Palatinate","","9671","1276102640"
"Mangalam","Mangalam","11.6368","79.2806","India","IN","IND","Tamil Nādu","","9610","1356702114"
"Opochka","Opochka","56.7000","28.6667","Russia","RU","RUS","Pskovskaya Oblast’","minor","10148","1643125012"
"Meymand","Meymand","28.8678","52.7533","Iran","IR","IRN","Fārs","","10120","1364554931"
"Jucuruçu","Jucurucu","-16.8428","-40.1589","Brazil","BR","BRA","Bahia","","10148","1076806349"
"Siddarāmpuram","Siddarampuram","15.5300","76.6360","India","IN","IND","Karnātaka","","9815","1356124653"
"Gavirate","Gavirate","45.8500","8.7167","Italy","IT","ITA","Lombardy","","9374","1380270230"
"Banbhāg","Banbhag","25.7919","87.4140","India","IN","IND","Bihār","","9159","1356571162"
"Marlton","Marlton","38.7620","-76.7857","United States","US","USA","Maryland","","10153","1840005962"
"Ogdensburg","Ogdensburg","44.7088","-75.4717","United States","US","USA","New York","","10153","1840000297"
"Chunakhali","Chunakhali","22.3010","88.7951","India","IN","IND","West Bengal","","9241","1356843727"
"Sîngera","Singera","46.9139","28.9708","Moldova","MD","MDA","Chişinău","","9966","1498679513"
"La Misión","La Mision","21.1000","-99.1333","Mexico","MX","MEX","Hidalgo","","10096","1484006779"
"Loudonville","Loudonville","42.7068","-73.7665","United States","US","USA","New York","","10152","1840033911"
"Ozimek","Ozimek","50.6731","18.2131","Poland","PL","POL","Opolskie","","10000","1616756545"
"Liminka","Liminka","64.8083","25.4167","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","10133","1246654014"
"White City","White City","42.4316","-122.8322","United States","US","USA","Oregon","","10151","1840018673"
"Goldenstedt","Goldenstedt","52.7833","8.4167","Germany","DE","DEU","Lower Saxony","","10037","1276831361"
"Yaotsu","Yaotsu","35.4760","137.1416","Japan","JP","JPN","Gifu","","10070","1392537302"
"West Point","West Point","33.6064","-88.6572","United States","US","USA","Mississippi","","10148","1840015681"
"Choele Choel","Choele Choel","-39.2667","-65.6833","Argentina","AR","ARG","Río Negro","minor","10146","1032421959"
"Ānjukulippatti","Anjukulippatti","10.2444","78.0794","India","IN","IND","Tamil Nādu","","9505","1356225799"
"Kishanpūra Kalān","Kishanpura Kalan","30.9337","75.3499","India","IN","IND","Punjab","","9661","1356447892"
"Génova","Genova","1.6442","-77.0194","Colombia","CO","COL","Nariño","minor","10127","1170687201"
"Liberty","Liberty","41.8132","-74.7775","United States","US","USA","New York","","10146","1840004790"
"Atāri","Atari","31.6008","74.6056","India","IN","IND","Punjab","","9523","1356684445"
"Corte Madera","Corte Madera","37.9238","-122.5128","United States","US","USA","California","","10145","1840022519"
"Kodakkal","Kodakkal","13.0685","79.4149","India","IN","IND","Tamil Nādu","","9212","1356131481"
"Wald","Wald","47.2753","8.9144","Switzerland","CH","CHE","Zürich","","9758","1756156476"
"Davutlar","Davutlar","37.7333","27.3000","Turkey","TR","TUR","Aydın","minor","9645","1792954597"
"Reddippatti","Reddippatti","11.2100","78.2166","India","IN","IND","Tamil Nādu","","9662","1356252502"
"Bruino","Bruino","45.0167","7.4667","Italy","IT","ITA","Piedmont","","8598","1380671134"
"Huldenberg","Huldenberg","50.7833","4.5833","Belgium","BE","BEL","Flanders","","9892","1056380225"
"Rinópolis","Rinopolis","-21.7258","-50.7219","Brazil","BR","BRA","São Paulo","","10133","1076562968"
"Marondry","Marondry","-18.4167","47.0000","Madagascar","MG","MDG","Antananarivo","","10110","1450307187"
"Manvel","Manvel","29.4798","-95.3635","United States","US","USA","Texas","","10139","1840020977"
"Rokkasho","Rokkasho","40.9672","141.3744","Japan","JP","JPN","Aomori","","10099","1392003266"
"Elūrpatti","Elurpatti","11.0234","78.2844","India","IN","IND","Tamil Nādu","","9469","1356237496"
"Carrillos","Carrillos","10.0369","-84.2740","Costa Rica","CR","CRI","Alajuela","","9228","1188308366"
"Steinhaus","Steinhaus","47.1969","8.4861","Switzerland","CH","CHE","Luzern","","10025","1756020648"
"Nathāna","Nathana","30.3155","75.0931","India","IN","IND","Punjab","","9500","1356838340"
"Pyetrykaw","Pyetrykaw","52.1333","28.5000","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","10127","1112852097"
"Zavolzhsk","Zavolzhsk","57.4667","42.1500","Russia","RU","RUS","Ivanovskaya Oblast’","","10121","1643171028"
"Matadepera","Matadepera","41.6036","2.0244","Spain","ES","ESP","Catalonia","","9752","1724902635"
"Alhendín","Alhendin","37.1167","-3.6500","Spain","ES","ESP","Andalusia","","9941","1724096901"
"Santa Isabel Cholula","Santa Isabel Cholula","19.0000","-98.3667","Mexico","MX","MEX","Puebla","minor","9236","1484043974"
"Iwashita","Iwashita","32.6516","130.8114","Japan","JP","JPN","Kumamoto","","9963","1392528224"
"Serebryansk","Serebryansk","49.6819","83.2847","Kazakhstan","KZ","KAZ","","","10129","1398188434"
"Arrapalli","Arrapalli","18.0718","79.7386","India","IN","IND","Andhra Pradesh","","9546","1356870012"
"Schotten","Schotten","50.5000","9.1167","Germany","DE","DEU","Hesse","","10059","1276543859"
"Morrovalle","Morrovalle","43.3167","13.5833","Italy","IT","ITA","Marche","","10056","1380099676"
"Chamusca","Chamusca","39.3500","-8.4833","Portugal","PT","PRT","Santarém","minor","10120","1620501575"
"Mengibar","Mengibar","37.9683","-3.8089","Spain","ES","ESP","Andalusia","","9973","1724436567"
"Sugbongkogon","Sugbongkogon","8.9500","124.7833","Philippines","PH","PHL","Misamis Oriental","","9764","1608060841"
"Tolbazy","Tolbazy","54.0242","55.8825","Russia","RU","RUS","Bashkortostan","","10114","1643123156"
"Ūttumalai","Uttumalai","8.9916","77.5318","India","IN","IND","Tamil Nādu","","9612","1356320342"
"Ban Pong Yaeng Nai","Ban Pong Yaeng Nai","18.8833","98.8167","Thailand","TH","THA","Chiang Mai","","9922","1764702190"
"Indūrti","Indurti","18.2233","79.1355","India","IN","IND","Andhra Pradesh","","9816","1356073625"
"Canton","Canton","41.8600","-72.9083","United States","US","USA","Connecticut","","10131","1840034294"
"Ardal","Ardal","31.9989","50.6617","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","minor","10113","1364019952"
"Rāsak","Rasak","26.2361","61.3986","Iran","IR","IRN","Sīstān va Balūchestān","minor","10115","1364728682"
"Sofiivka","Sofiivka","48.2683","38.1847","Ukraine","UA","UKR","Donetska Oblast","","9597","1804009995"
"Oldenburg in Holstein","Oldenburg in Holstein","54.3000","10.8833","Germany","DE","DEU","Schleswig-Holstein","","9881","1276671809"
"Massi","Massi","9.9167","1.4667","Benin","BJ","BEN","Donga","","10030","1204807005"
"Progress Village","Progress Village","27.8831","-82.3593","United States","US","USA","Florida","","10129","1840028917"
"Touwu","Touwu","24.5833","120.8500","Taiwan","TW","TWN","Miaoli","","9941","1158275134"
"Hermantown","Hermantown","46.8057","-92.2407","United States","US","USA","Minnesota","","10128","1840006593"
"Sovicille","Sovicille","43.2833","11.2333","Italy","IT","ITA","Tuscany","","10057","1380780018"
"Kumçatı","Kumcati","37.4710","42.3170","Turkey","TR","TUR","Şırnak","","10094","1792767524"
"Kuroshio","Kuroshio","33.0167","133.0167","Japan","JP","JPN","Kōchi","","10073","1392003147"
"Rio do Fogo","Rio do Fogo","-5.2728","-35.3828","Brazil","BR","BRA","Rio Grande do Norte","","10059","1076926455"
"Alexandria","Alexandria","38.9621","-84.3859","United States","US","USA","Kentucky","","10125","1840013162"
"Shohimardon","Shohimardon","39.9833","71.8000","Uzbekistan","UZ","UZB","Farg‘ona","","10100","1860836599"
"Keuruu","Keuruu","62.2583","24.7083","Finland","FI","FIN","Keski-Suomi","minor","10117","1246102142"
"Hathiākān","Hathiakan","25.6060","84.9997","India","IN","IND","Bihār","","8619","1356891728"
"Mashhad Rīzeh","Mashhad Rizeh","34.7922","60.5050","Iran","IR","IRN","Khorāsān-e Raẕavī","","10105","1364459506"
"Namorona","Namorona","-21.6500","48.2000","Madagascar","MG","MDG","Fianarantsoa","","10046","1450195610"
"Großhansdorf","Grosshansdorf","53.6667","10.2667","Germany","DE","DEU","Schleswig-Holstein","","9292","1276549511"
"Ipupiara","Ipupiara","-11.8200","-42.6139","Brazil","BR","BRA","Bahia","","10113","1076106474"
"Água Branca","Agua Branca","-7.5119","-37.6408","Brazil","BR","BRA","Paraíba","","10083","1076930079"
"Tungāvi","Tungavi","10.6263","77.3294","India","IN","IND","Tamil Nādu","","9700","1356171107"
"Hull","Hull","42.2861","-70.8835","United States","US","USA","Massachusetts","","10120","1840053570"
"Le Beausset","Le Beausset","43.1983","5.8028","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9845","1250592010"
"Parūr","Parur","11.5793","79.2550","India","IN","IND","Tamil Nādu","","9572","1356162540"
"Satipo","Satipo","-11.2542","-74.6367","Peru","PE","PER","Junín","","10052","1604355080"
"Chansolme","Chansolme","19.8833","-72.8333","Haiti","HT","HTI","Nord-Ouest","","9561","1332378565"
"Rüdesheim am Rhein","Rudesheim am Rhein","49.9833","7.9306","Germany","DE","DEU","Hesse","","9922","1276263757"
"Judenburg","Judenburg","47.1725","14.6603","Austria","AT","AUT","Steiermark","minor","9960","1040723538"
"Cambridge","Cambridge","40.0221","-81.5869","United States","US","USA","Ohio","","10116","1840007259"
"Tuam","Tuam","53.5150","-8.8510","Ireland","IE","IRL","Galway","","8767","1372153412"
"Iretama","Iretama","-24.4239","-52.1058","Brazil","BR","BRA","Paraná","","10098","1076488338"
"Suttamalli","Suttamalli","8.6987","77.6323","India","IN","IND","Tamil Nādu","","8679","1356201488"
"Bālgudar","Balgudar","25.2000","86.0833","India","IN","IND","Bihār","","8708","1356146472"
"Murungattoluvu","Murungattoluvu","11.1651","77.6411","India","IN","IND","Tamil Nādu","","9738","1356164776"
"Dhanwāda","Dhanwada","16.6500","77.6667","India","IN","IND","Andhra Pradesh","","9743","1356703437"
"Lysá nad Labem","Lysa nad Labem","50.2015","14.8329","Czechia","CZ","CZE","Středočeský Kraj","","9825","1203091035"
"Bagnolo in Piano","Bagnolo in Piano","44.7667","10.6833","Italy","IT","ITA","Emilia-Romagna","","9752","1380848435"
"Sulzbach","Sulzbach","50.1331","8.5289","Germany","DE","DEU","Hesse","","8971","1276220992"
"Dumont","Dumont","-21.2364","-47.9733","Brazil","BR","BRA","São Paulo","","10023","1076221690"
"Red Hook","Red Hook","42.0188","-73.8788","United States","US","USA","New York","","10113","1840004752"
"Klötze","Klotze","52.6263","11.1616","Germany","DE","DEU","Saxony-Anhalt","","10077","1276729835"
"Orotina","Orotina","9.9024","-84.5250","Costa Rica","CR","CRI","Alajuela","","9664","1188644309"
"Kirchlinteln","Kirchlinteln","52.9428","9.3183","Germany","DE","DEU","Lower Saxony","","10054","1276083659"
"Saladoblanco","Saladoblanco","2.0167","-76.0500","Colombia","CO","COL","Huila","minor","10076","1170865938"
"Ōnan","Onan","34.8939","132.4378","Japan","JP","JPN","Shimane","","10086","1392003264"
"Coal","Coal","40.7873","-76.5493","United States","US","USA","Pennsylvania","","10110","1840150358"
"Zaggota","Zaggota","34.1667","-5.5333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","10032","1504666777"
"Boucau","Boucau","43.5236","-1.4867","France","FR","FRA","Nouvelle-Aquitaine","","8627","1250957654"
"North Glengarry","North Glengarry","45.3333","-74.7333","Canada","CA","CAN","Ontario","","10109","1124000836"
"Hosahalli","Hosahalli","14.6480","76.4672","India","IN","IND","Karnātaka","","9878","1356470279"
"Makaya","Makaya","25.2242","87.8640","India","IN","IND","West Bengal","","8877","1356764235"
"Ledegem","Ledegem","50.8531","3.1267","Belgium","BE","BEL","Flanders","","9716","1056674773"
"Digora","Digora","43.1581","44.1569","Russia","RU","RUS","North Ossetia","","10075","1643075544"
"Nisarpur","Nisarpur","22.1088","74.8078","India","IN","IND","Madhya Pradesh","","9826","1356162861"
"Fairfield","Fairfield","33.4747","-86.9194","United States","US","USA","Alabama","","10108","1840001883"
"Airway Heights","Airway Heights","47.6459","-117.5792","United States","US","USA","Washington","","10108","1840018399"
"Gambolò","Gambolo","45.2586","8.8647","Italy","IT","ITA","Lombardy","","9916","1380760813"
"Cavriago","Cavriago","44.7000","10.5333","Italy","IT","ITA","Emilia-Romagna","","9917","1380748689"
"Melendugno","Melendugno","40.2667","18.3333","Italy","IT","ITA","Puglia","","9999","1380529808"
"Sarız","Sariz","38.4792","36.5006","Turkey","TR","TUR","Kayseri","minor","10098","1792887641"
"Pavlikeni","Pavlikeni","43.2428","25.3217","Bulgaria","BG","BGR","Veliko Tarnovo","","10071","1100655871"
"Madera Acres","Madera Acres","37.0123","-120.0799","United States","US","USA","California","","10106","1840028386"
"Kotli Ablu","Kotli Ablu","30.3504","74.7868","India","IN","IND","Punjab","","9783","1356070767"
"Poienile de sub Munte","Poienile de sub Munte","47.8167","24.4333","Romania","RO","ROU","Maramureş","","10073","1642843793"
"Meldola","Meldola","44.1333","12.0500","Italy","IT","ITA","Emilia-Romagna","","9978","1380278885"
"Condé-sur-l’Escaut","Conde-sur-l'Escaut","50.4492","3.5906","France","FR","FRA","Hauts-de-France","","9586","1250188689"
"Maiquinique","Maiquinique","-15.6208","-40.2658","Brazil","BR","BRA","Bahia","","10082","1076435783"
"Dylym","Dylym","43.0710","46.6345","Russia","RU","RUS","Dagestan","","10014","1643971270"
"Parsād","Parsad","26.1586","86.3579","India","IN","IND","Bihār","","8630","1356321106"
"Aigali","Aigali","16.7200","75.0600","India","IN","IND","Karnātaka","","9801","1356470112"
"Macomer","Macomer","40.2667","8.7833","Italy","IT","ITA","Sardegna","","10019","1380523626"
"Roboré","Robore","-18.3333","-59.7500","Bolivia","BO","BOL","Santa Cruz","","10098","1068498462"
"Lescar","Lescar","43.3250","-0.4161","France","FR","FRA","Nouvelle-Aquitaine","","9735","1250321086"
"Heves","Heves","47.6000","20.2833","Hungary","HU","HUN","Heves","minor","9999","1348843058"
"Krivodanovka","Krivodanovka","55.0881","82.6551","Russia","RU","RUS","Novosibirskaya Oblast’","","10051","1643246656"
"Lenggries","Lenggries","47.6803","11.5739","Germany","DE","DEU","Bavaria","","10058","1276352109"
"Kamalasai","Kamalasai","16.3383","103.5756","Thailand","TH","THA","Kalasin","minor","9942","1764734520"
"Santa Teresa","Santa Teresa","-25.0519","-53.6328","Brazil","BR","BRA","Paraná","","10096","1076296224"
"Oberhausen-Rheinhausen","Oberhausen-Rheinhausen","49.2606","8.4850","Germany","DE","DEU","Baden-Württemberg","","9593","1276286737"
"Nogliki","Nogliki","51.8333","143.1667","Russia","RU","RUS","Sakhalinskaya Oblast’","","10098","1643874280"
"Kinhālu","Kinhalu","15.4431","76.1397","India","IN","IND","Karnātaka","","9802","1356502789"
"Nayāgaon","Nayagaon","24.5622","74.7760","India","IN","IND","Rājasthān","","9873","1356976251"
"Kurort Steinbach-Hallenberg","Kurort Steinbach-Hallenberg","50.7006","10.5667","Germany","DE","DEU","Thuringia","","9681","1276381862"
"Töging am Inn","Toging am Inn","48.2500","12.5667","Germany","DE","DEU","Bavaria","","9407","1276814406"
"Bela Vista de Minas","Bela Vista de Minas","-19.8300","-43.0908","Brazil","BR","BRA","Minas Gerais","","10004","1076180977"
"Tomboutou","Tomboutou","11.8550","3.2892","Benin","BJ","BEN","Atacora","","10026","1204547327"
"South Huron","South Huron","43.3200","-81.5000","Canada","CA","CAN","Ontario","","10096","1124000910"
"Terku Narippaiyūr","Terku Narippaiyur","9.1167","78.4188","India","IN","IND","Tamil Nādu","","9831","1356233103"
"Sakuho","Sakuho","36.1610","138.4834","Japan","JP","JPN","Nagano","","10042","1392003480"
"Cairo","Cairo","30.8790","-84.2050","United States","US","USA","Georgia","","10095","1840013900"
"Penn","Penn","40.1864","-76.3726","United States","US","USA","Pennsylvania","","10095","1840147756"
"Brembate","Brembate","45.6000","9.5500","Italy","IT","ITA","Lombardy","","8551","1380861163"
"Marieville","Marieville","45.4333","-73.1667","Canada","CA","CAN","Quebec","","10094","1124834229"
"Mengen","Mengen","48.0497","9.3300","Germany","DE","DEU","Baden-Württemberg","","9896","1276073963"
"Rainbow City","Rainbow City","33.9336","-86.0922","United States","US","USA","Alabama","","10093","1840014732"
"Beilngries","Beilngries","49.0333","11.4667","Germany","DE","DEU","Bavaria","","9993","1276091719"
"Crigglestone","Crigglestone","53.6440","-1.5226","United Kingdom","GB","GBR","Wakefield","","9271","1826293505"
"Mori","Mori","45.8500","10.9833","Italy","IT","ITA","Trentino-Alto Adige","","9846","1380763112"
"Wölfersheim","Wolfersheim","50.3975","8.8114","Germany","DE","DEU","Hesse","","9863","1276879373"
"Bilāspur","Bilaspur","30.3044","77.3028","India","IN","IND","Haryāna","","9620","1356893959"
"Bou Nouh","Bou Nouh","36.5000","3.9333","Algeria","DZ","DZA","Tizi Ouzou","","9731","1012718665"
"Kidira","Kidira","14.4167","-12.2500","Senegal","SN","SEN","Tambacounda","","10065","1686929871"
"Boaz","Boaz","34.1985","-86.1529","United States","US","USA","Alabama","","10091","1840013602"
"Snohomish","Snohomish","47.9276","-122.0969","United States","US","USA","Washington","","10091","1840021078"
"Cedartown","Cedartown","34.0223","-85.2479","United States","US","USA","Georgia","","10091","1840014752"
"Bamora","Bamora","24.0554","78.0893","India","IN","IND","Madhya Pradesh","","9785","1356480906"
"Hingyon","Hingyon","16.8522","121.0989","Philippines","PH","PHL","Ifugao","","9930","1608866748"
"Çeltik","Celtik","39.0244","31.7906","Turkey","TR","TUR","Konya","minor","10071","1792104083"
"Francisville","Francisville","39.1068","-84.7277","United States","US","USA","Kentucky","","10088","1840026679"
"Sinimbu","Sinimbu","-29.5389","-52.5219","Brazil","BR","BRA","Rio Grande do Sul","","10068","1076004049"
"Uppūr","Uppur","13.3945","74.7657","India","IN","IND","Karnātaka","","9464","1356256486"
"San Felice Circeo","San Felice Circeo","41.2353","13.0956","Italy","IT","ITA","Lazio","","10054","1380276515"
"Waynesville","Waynesville","35.4854","-82.9996","United States","US","USA","North Carolina","","10087","1840016297"
"Levski","Levski","43.3667","25.1333","Bulgaria","BG","BGR","Pleven","","10046","1100805179"
"Waldwick","Waldwick","41.0133","-74.1259","United States","US","USA","New Jersey","","10086","1840000921"
"Chavuttahalli","Chavuttahalli","12.4202","78.2352","India","IN","IND","Tamil Nādu","","9592","1356008957"
"Kösching","Kosching","48.8167","11.5000","Germany","DE","DEU","Bavaria","","9705","1276577514"
"Niederhasli","Niederhasli","47.4822","8.4861","Switzerland","CH","CHE","Zürich","","9264","1756015493"
"Makale","Makale","-3.1000","119.8500","Indonesia","ID","IDN","Sulawesi Selatan","minor","9960","1360576738"
"Lesquin","Lesquin","50.5897","3.1111","France","FR","FRA","Hauts-de-France","","9012","1250102506"
"Ramiriquí","Ramiriqui","5.4000","-73.3333","Colombia","CO","COL","Boyacá","minor","10015","1170346541"
"Hongliuwan","Hongliuwan","39.6348","94.3386","China","CN","CHN","Gansu","minor","10079","1156215172"
"Khathjari","Khathjari","24.7046","81.9891","India","IN","IND","Madhya Pradesh","","9793","1356555426"
"Arques","Arques","50.7356","2.3025","France","FR","FRA","Hauts-de-France","","9654","1250707175"
"Pasaul","Pasaul","26.2647","85.6756","India","IN","IND","Bihār","","8602","1356493261"
"Harewa","Harewa","25.6831","86.5909","India","IN","IND","Bihār","","8556","1356903372"
"Figeac","Figeac","44.6086","2.0317","France","FR","FRA","Occitanie","minor","9802","1250962434"
"Bīrpur","Birpur","32.6617","74.9518","India","IN","IND","Jammu and Kashmīr","","8832","1356100933"
"Chilakhāna","Chilakhana","26.2990","89.5892","India","IN","IND","West Bengal","","9263","1356065859"
"Quartz Hill","Quartz Hill","34.6527","-118.2163","United States","US","USA","California","","10079","1840019210"
"Dharāwat","Dharawat","25.0592","85.0420","India","IN","IND","Bihār","","8784","1356971216"
"Kümmersbruck","Kummersbruck","49.4167","11.8833","Germany","DE","DEU","Bavaria","","9870","1276949407"
"Bergambacht","Bergambacht","51.9333","4.7833","Netherlands","NL","NLD","Zuid-Holland","minor","9801","1528580595"
"Bhagatpur","Bhagatpur","25.4098","86.3076","India","IN","IND","Bihār","","8875","1356136971"
"La Leonesa","La Leonesa","-27.0500","-58.7167","Argentina","AR","ARG","Chaco","minor","10067","1032749991"
"Thogadūru","Thogaduru","12.0957","76.8078","India","IN","IND","Karnātaka","","9609","1356111905"
"Buenavista","Buenavista","9.3222","-74.9772","Colombia","CO","COL","Sucre","minor","10007","1170935421"
"Sannicandro di Bari","Sannicandro di Bari","41.0000","16.8000","Italy","IT","ITA","Puglia","","9902","1380017316"
"Rellivalasa","Rellivalasa","17.9108","83.4100","India","IN","IND","Andhra Pradesh","","9581","1356795224"
"San Agustín de las Juntas","San Agustin de las Juntas","17.0000","-96.7167","Mexico","MX","MEX","Oaxaca","","9342","1484607979"
"San Isidro","San Isidro","9.8000","124.3000","Philippines","PH","PHL","Bohol","","9909","1608028784"
"Tiruvengadam","Tiruvengadam","9.2586","77.6748","India","IN","IND","Tamil Nādu","","9705","1356289004"
"Bad König","Bad Konig","49.7500","9.0167","Germany","DE","DEU","Hesse","","9861","1276917706"
"Ikryanoye","Ikryanoye","46.0903","47.7306","Russia","RU","RUS","Astrakhanskaya Oblast’","","10036","1643424556"
"Sendamangalam","Sendamangalam","11.7431","79.3790","India","IN","IND","Tamil Nādu","","9513","1356159117"
"Kharagbani","Kharagbani","26.4852","86.3073","India","IN","IND","Bihār","","9377","1356020871"
"Harrisonville","Harrisonville","38.6530","-94.3467","United States","US","USA","Missouri","","10070","1840007464"
"Red Bank","Red Bank","33.9309","-81.2322","United States","US","USA","South Carolina","","10070","1840013658"
"Berd","Berd","40.8808","45.3917","Armenia","AM","ARM","Tavush","","9864","1051965968"
"Tsunō","Tsuno","32.2565","131.5597","Japan","JP","JPN","Miyazaki","","9972","1392643881"
"Bāg","Bag","22.3590","74.7905","India","IN","IND","Madhya Pradesh","","9787","1356915210"
"Hazle","Hazle","40.9558","-75.9998","United States","US","USA","Pennsylvania","","10069","1840148627"
"Ware","Ware","42.2806","-72.2843","United States","US","USA","Massachusetts","","10067","1840053468"
"Woodbury","Woodbury","39.8379","-75.1524","United States","US","USA","New Jersey","","10067","1840001527"
"Zoudjamé","Zoudjame","6.8167","1.8667","Benin","BJ","BEN","Couffo","","9673","1204268397"
"Aveley","Aveley","51.5018","0.2534","United Kingdom","GB","GBR","Thurrock","","9461","1826305482"
"Hayle","Hayle","50.1860","-5.4190","United Kingdom","GB","GBR","Cornwall","","9400","1826999750"
"Lovendegem","Lovendegem","51.1000","3.6000","Belgium","BE","BEL","Flanders","","9574","1056273817"
"Chegurumomadi","Chegurumomadi","18.2361","79.1974","India","IN","IND","Andhra Pradesh","","9750","1356510607"
"Taghbalt","Taghbalt","30.6200","-5.3500","Morocco","MA","MAR","Drâa-Tafilalet","","10054","1504290154"
"Portland","Portland","-38.3333","141.6000","Australia","AU","AUS","Victoria","","9712","1036439594"
"Sakhua","Sakhua","26.1734","86.7624","India","IN","IND","Bihār","","8553","1356124673"
"Maków Mazowiecki","Makow Mazowiecki","52.8667","21.1000","Poland","PL","POL","Mazowieckie","minor","9173","1616505631"
"Littleton","Littleton","42.5350","-71.4891","United States","US","USA","Massachusetts","","10063","1840070362"
"Pescaria Brava","Pescaria Brava","-28.3833","-48.8833","Brazil","BR","BRA","Santa Catarina","","9980","1076082344"
"Planalto","Planalto","-27.3289","-53.0589","Brazil","BR","BRA","Rio Grande do Sul","","10019","1076020835"
"Aire-sur-la-Lys","Aire-sur-la-Lys","50.6386","2.3967","France","FR","FRA","Hauts-de-France","","9772","1250126430"
"Banāso","Banaso","23.9884","85.7866","India","IN","IND","Jhārkhand","","9585","1356746877"
"Kosh-Agach","Kosh-Agach","49.9927","88.6760","Russia","RU","RUS","Altay","","10061","1643465238"
"Fairview","Fairview","33.1399","-96.6117","United States","US","USA","Texas","","10062","1840022021"
"Birch Bay","Birch Bay","48.9243","-122.7519","United States","US","USA","Washington","","10062","1840017289"
"Inverigo","Inverigo","45.7333","9.2167","Italy","IT","ITA","Lombardy","","9146","1380184866"
"North Versailles","North Versailles","40.3785","-79.8084","United States","US","USA","Pennsylvania","","10061","1840133154"
"Kalaīkunda","Kalaikunda","22.3392","87.2264","India","IN","IND","West Bengal","","9344","1356758403"
"Büyükorhan","Buyukorhan","39.7500","28.9167","Turkey","TR","TUR","Bursa","minor","10042","1792732842"
"Lambesc","Lambesc","43.6539","5.2619","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9909","1250689010"
"Haria","Haria","-3.5833","128.6167","Indonesia","ID","IDN","Maluku","","10000","1360043988"
"Molalla","Molalla","45.1502","-122.5845","United States","US","USA","Oregon","","10060","1840019953"
"DeRidder","DeRidder","30.8468","-93.2931","United States","US","USA","Louisiana","","10060","1840015013"
"Nūtakki","Nutakki","16.4139","80.6506","India","IN","IND","Andhra Pradesh","","9236","1356150751"
"Ivančice","Ivancice","49.1014","16.3775","Czechia","CZ","CZE","Jihomoravský Kraj","","9854","1203249406"
"Saint-Pierre-du-Mont","Saint-Pierre-du-Mont","43.8825","-0.5194","France","FR","FRA","Nouvelle-Aquitaine","","9689","1250835528"
"Struthers","Struthers","41.0510","-80.5920","United States","US","USA","Ohio","","10058","1840003562"
"Muskegon Heights","Muskegon Heights","43.2023","-86.2421","United States","US","USA","Michigan","","10058","1840002871"
"Paradarāmi","Paradarami","13.0833","78.9833","India","IN","IND","Andhra Pradesh","","9856","1356031235"
"Czersk Pomorski","Czersk Pomorski","53.7928","17.9739","Poland","PL","POL","Pomorskie","","9997","1616943877"
"Kargopol","Kargopol","61.5000","38.9333","Russia","RU","RUS","Arkhangel’skaya Oblast’","","10055","1643025947"
"Pachalum","Pachalum","14.9269","-90.6650","Guatemala","GT","GTM","Quiché","minor","9897","1320074787"
"Guaraciaba","Guaraciaba","-26.5989","-53.5178","Brazil","BR","BRA","Santa Catarina","","10026","1076248492"
"San Pancrazio Salentino","San Pancrazio Salentino","40.4167","17.8333","Italy","IT","ITA","Puglia","","9882","1380975050"
"Bhatkhori","Bhatkhori","25.9156","86.9072","India","IN","IND","Bihār","","8672","1356100865"
"Dabhaura","Dabhaura","25.1162","81.3044","India","IN","IND","Madhya Pradesh","","9544","1356152184"
"Garlasco","Garlasco","45.2000","8.9167","Italy","IT","ITA","Lombardy","","9805","1380573289"
"San Ignacio de Moxo","San Ignacio de Moxo","-14.9961","-65.6400","Bolivia","BO","BOL","El Beni","","10054","1068044782"
"Cody","Cody","44.5213","-109.0548","United States","US","USA","Wyoming","","10055","1840018595"
"Nandiyālam","Nandiyalam","12.9237","79.2635","India","IN","IND","Tamil Nādu","","9122","1356366942"
"La Plata","La Plata","38.5352","-76.9700","United States","US","USA","Maryland","","10054","1840006177"
"Ahmadpur","Ahmadpur","23.8301","87.6866","India","IN","IND","West Bengal","","9242","1356692177"
"Başmakçı","Basmakci","37.8833","30.0000","Turkey","TR","TUR","Afyonkarahisar","minor","10031","1792537292"
"Calimesa","Calimesa","33.9874","-117.0542","United States","US","USA","California","","10053","1840019300"
"Browns Mills","Browns Mills","39.9737","-74.5690","United States","US","USA","New Jersey","","10053","1840005517"
"Blain","Blain","47.4761","-1.7636","France","FR","FRA","Pays de la Loire","","9954","1250245531"
"Rödinghausen","Rodinghausen","52.2550","8.4817","Germany","DE","DEU","North Rhine-Westphalia","","9784","1276776306"
"Barwān","Barwan","23.9409","87.9350","India","IN","IND","West Bengal","","8974","1356955537"
"Pine Ridge","Pine Ridge","28.9330","-82.4761","United States","US","USA","Florida","","10051","1840028997"
"East Liverpool","East Liverpool","40.6333","-80.5677","United States","US","USA","Ohio","","10051","1840002783"
"Golden Hills","Golden Hills","35.1409","-118.4968","United States","US","USA","California","","10051","1840017819"
"Moba","Moba","-7.0398","29.7661","Congo (Kinshasa)","CD","COD","Tanganyika","","10006","1180776583"
"Tiruvambalapuram","Tiruvambalapuram","8.2514","77.7752","India","IN","IND","Tamil Nādu","","9650","1356221818"
"Worpswede","Worpswede","53.2222","8.9278","Germany","DE","DEU","Lower Saxony","","9919","1276953565"
"Lambertville","Lambertville","41.7484","-83.6244","United States","US","USA","Michigan","","10049","1840006576"
"Mānbāzār","Manbazar","23.0615","86.6642","India","IN","IND","West Bengal","","9521","1356784403"
"Simrol","Simrol","22.5387","75.9111","India","IN","IND","Madhya Pradesh","","9647","1356759752"
"’Aïn Leuh","'Ain Leuh","33.2833","-5.3833","Morocco","MA","MAR","Fès-Meknès","","9669","1504668626"
"Santamāgulūru","Santamaguluru","16.1303","79.9486","India","IN","IND","Andhra Pradesh","","9687","1356148353"
"Barga","Barga","44.0750","10.4817","Italy","IT","ITA","Tuscany","","9898","1380703342"
"Shyamnagar","Shyamnagar","22.9700","88.3800","India","IN","IND","West Bengal","","8601","1356030287"
"Lentvaris","Lentvaris","54.6500","25.0667","Lithuania","LT","LTU","Trakai","","10000","1440638790"
"Sankt Andrä","Sankt Andra","46.7667","14.8167","Austria","AT","AUT","Kärnten","","9957","1040740376"
"Frickenhausen","Frickenhausen","48.5928","9.3611","Germany","DE","DEU","Baden-Württemberg","","9232","1276458359"
"Vemulanarva","Vemulanarva","16.9660","80.2600","India","IN","IND","Andhra Pradesh","","9554","1356131446"
"Geylegphug","Geylegphug","26.8706","90.4856","Bhutan","BT","BTN","Sarpang","","9858","1064875901"
"Bo‘z","Bo\`z","40.6833","71.9167","Uzbekistan","UZ","UZB","Andijon","","9294","1860854695"
"Banino","Banino","54.3922","18.4062","Poland","PL","POL","Pomorskie","","9806","1616509986"
"Mayfield","Mayfield","36.7371","-88.6447","United States","US","USA","Kentucky","","10041","1840015268"
"Muddāda","Muddada","18.2385","83.8687","India","IN","IND","Andhra Pradesh","","9497","1356236109"
"Milton","Milton","30.6286","-87.0522","United States","US","USA","Florida","","10040","1840015006"
"Osthofen","Osthofen","49.7078","8.3289","Germany","DE","DEU","Rhineland-Palatinate","","9528","1276502148"
"Puszczykowo","Puszczykowo","52.2817","16.8542","Poland","PL","POL","Wielkopolskie","","9470","1616800720"
"Janhapāra","Janhapara","21.3390","83.7310","India","IN","IND","Odisha","","9042","1356557076"
"Būdalūr","Budalur","10.7861","78.9864","India","IN","IND","Tamil Nādu","","9221","1356841405"
"Piploda","Piploda","23.6073","74.9420","India","IN","IND","Madhya Pradesh","","9639","1356370346"
"Šurany","Surany","48.0872","18.1861","Slovakia","SK","SVK","Nitra","","9878","1703675308"
"Bou’nane","Bou'nane","32.0275","-3.0400","Morocco","MA","MAR","Oriental","","10035","1504957509"
"Vernal","Vernal","40.4517","-109.5378","United States","US","USA","Utah","","10037","1840021391"
"Nohfelden","Nohfelden","49.5867","7.1428","Germany","DE","DEU","Saarland","","9938","1276779252"
"Nallamadu","Nallamadu","16.8802","81.3549","India","IN","IND","Andhra Pradesh","","9774","1356005363"
"Pullūru","Pulluru","16.8301","80.6226","India","IN","IND","Andhra Pradesh","","9678","1356073321"
"San Pedro Huamelula","San Pedro Huamelula","16.0167","-95.6667","Mexico","MX","MEX","Oaxaca","minor","10014","1484802560"
"Sola","Sola","21.6744","-77.6806","Cuba","CU","CUB","Camagüey","","10000","1192592733"
"Santa María Ajoloapan","Santa Maria Ajoloapan","19.9692","-99.0353","Mexico","MX","MEX","Morelos","","9185","1484494406"
"Madhubani","Madhubani","27.0001","84.1040","India","IN","IND","Bihār","","9702","1356306845"
"Yorkshire","Yorkshire","38.7882","-77.4496","United States","US","USA","Virginia","","10033","1840006107"
"Tay","Tay","44.7167","-79.7667","Canada","CA","CAN","Ontario","","10033","1124001057"
"Santa Margherita Ligure","Santa Margherita Ligure","44.3333","9.2167","Italy","IT","ITA","Liguria","","9124","1380923965"
"Kalladai","Kalladai","10.7272","78.4634","India","IN","IND","Tamil Nādu","","9511","1356213371"
"Dubove","Dubove","48.1781","23.8863","Ukraine","UA","UKR","Zakarpatska Oblast","","9938","1804000226"
"Suaita","Suaita","6.1019","-73.4406","Colombia","CO","COL","Santander","minor","10006","1170125417"
"Punjai Lakkāpuram","Punjai Lakkapuram","11.3050","77.7554","India","IN","IND","Tamil Nādu","","8896","1356166984"
"Neglūr","Neglur","14.9000","75.6000","India","IN","IND","Karnātaka","","9674","1356059359"
"Pāchhāpur","Pachhapur","16.2900","74.6000","India","IN","IND","Karnātaka","","9585","1356066054"
"Coycoyan de las Flores","Coycoyan de las Flores","17.2713","-98.2741","Mexico","MX","MEX","Guerrero","","9936","1484976767"
"Sulęcin","Sulecin","52.4444","15.1111","Poland","PL","POL","Lubuskie","minor","9978","1616859082"
"Periyapuliyūr","Periyapuliyur","11.4296","77.6309","India","IN","IND","Tamil Nādu","","9704","1356167822"
"Huejuquilla el Alto","Huejuquilla el Alto","22.5333","-103.9167","Mexico","MX","MEX","Jalisco","minor","10015","1484316706"
"Valozhyn","Valozhyn","54.0833","26.5167","Belarus","BY","BLR","Minskaya Voblasts’","minor","10012","1112252303"
"Gudensberg","Gudensberg","51.1833","9.3667","Germany","DE","DEU","Hesse","","9815","1276969422"
"Khandāich","Khandaich","24.8511","86.2395","India","IN","IND","Bihār","","9292","1356610215"
"Monument","Monument","39.0736","-104.8467","United States","US","USA","Colorado","","10026","1840022474"
"San Elizario","San Elizario","31.5793","-106.2632","United States","US","USA","Texas","","10026","1840019523"
"Diabougou","Diabougou","14.5431","-12.2186","Senegal","SN","SEN","Tambacounda","","10000","1686174513"
"Volodarsk","Volodarsk","56.2167","43.1833","Russia","RU","RUS","Nizhegorodskaya Oblast’","","9972","1643851131"
"Urgnano","Urgnano","45.5972","9.6950","Italy","IT","ITA","Lombardy","","9908","1380476962"
"Gassino Torinese","Gassino Torinese","45.1271","7.8248","Italy","IT","ITA","Piedmont","","9559","1380213272"
"Howell","Howell","42.6078","-83.9339","United States","US","USA","Michigan","","10025","1840003100"
"Caraíbas","Caraibas","-14.6000","-41.3350","Brazil","BR","BRA","Bahia","","10016","1076820900"
"Bude","Bude","50.8240","-4.5420","United Kingdom","GB","GBR","Cornwall","","9222","1826412704"
"Moravská Třebová","Moravska Trebova","49.7580","16.6643","Czechia","CZ","CZE","Pardubický Kraj","","9795","1203455488"
"Wielsbeke","Wielsbeke","50.9089","3.3697","Belgium","BE","BEL","Flanders","","9584","1056411579"
"Koumpentoum","Koumpentoum","13.9833","-14.5667","Senegal","SN","SEN","Tambacounda","","10000","1686285510"
"Bikrampur Bānde","Bikrampur Bande","25.8415","85.7424","India","IN","IND","Bihār","","8653","1356133519"
"Umbrete","Umbrete","37.3667","-6.1500","Spain","ES","ESP","Andalusia","","9253","1724771107"
"Valea lui Mihai","Valea lui Mihai","47.5200","22.1300","Romania","RO","ROU","Bihor","","9902","1642733254"
"Ohrdruf","Ohrdruf","50.8281","10.7328","Germany","DE","DEU","Thuringia","","9784","1276929737"
"Galleh Dār","Galleh Dar","27.6594","52.6575","Iran","IR","IRN","Fārs","","9982","1364264493"
"Hickam Housing","Hickam Housing","21.3311","-157.9474","United States","US","USA","Hawaii","","10022","1840075009"
"Purkersdorf","Purkersdorf","48.2092","16.1792","Austria","AT","AUT","Niederösterreich","","9701","1040636796"
"Kami-kawabe","Kami-kawabe","35.4866","137.0707","Japan","JP","JPN","Gifu","","9784","1392777604"
"Ilsfeld","Ilsfeld","49.0500","9.2500","Germany","DE","DEU","Baden-Württemberg","","9657","1276001221"
"Kusmaul","Kusmaul","26.1911","87.1311","India","IN","IND","Bihār","","8953","1356166384"
"Puigcerdá","Puigcerda","42.4317","1.9283","Spain","ES","ESP","Catalonia","","9518","1724441850"
"Devmaudaldal","Devmaudaldal","24.6312","81.1594","India","IN","IND","Madhya Pradesh","","9496","1356070214"
"Kanangle","Kanangle","16.2900","74.6000","India","IN","IND","Karnātaka","","9575","1356211647"
"Flowood","Flowood","32.3359","-90.0802","United States","US","USA","Mississippi","","10019","1840013824"
"Taltal","Taltal","-25.4000","-70.4700","Chile","CL","CHL","Antofagasta","","10018","1152265576"
"Senanga","Senanga","-16.1167","23.2667","Zambia","ZM","ZMB","Western","","10005","1894742251"
"Dodarasinakere","Dodarasinakere","12.5083","77.0200","India","IN","IND","Karnātaka","","9541","1356082600"
"Grosse Pointe Farms","Grosse Pointe Farms","42.4068","-82.8993","United States","US","USA","Michigan","","10018","1840003977"
"Fredonia","Fredonia","42.4407","-79.3319","United States","US","USA","New York","","10018","1840004566"
"Vuktyl","Vuktyl","63.7000","57.3167","Russia","RU","RUS","Komi","","10017","1643046290"
"Maisenhausen","Maisenhausen","50.0172","8.9915","Germany","DE","DEU","Bavaria","","9488","1276399610"
"Glenwood Springs","Glenwood Springs","39.5455","-107.3347","United States","US","USA","Colorado","","10017","1840020184"
"Mont-Saint-Martin","Mont-Saint-Martin","49.5406","5.7794","France","FR","FRA","Grand Est","","8994","1250537534"
"Alajärvi","Alajarvi","63.0000","23.8167","Finland","FI","FIN","Etelä-Pohjanmaa","minor","10006","1246799974"
"Navani","Navani","11.3709","78.1380","India","IN","IND","Tamil Nādu","","9535","1356241227"
"Analalava","Analalava","-14.6333","47.7500","Madagascar","MG","MDG","Mahajanga","","10000","1450593312"
"Hakka","Hakka","25.9163","87.6474","India","IN","IND","Bihār","","8741","1356127903"
"Lakhna","Lakhna","25.8865","87.6001","India","IN","IND","Bihār","","9015","1356038890"
"Nogent-le-Rotrou","Nogent-le-Rotrou","48.3217","0.8217","France","FR","FRA","Centre-Val de Loire","minor","9610","1250164091"
"Rahta","Rahta","25.9213","86.9734","India","IN","IND","Bihār","","8745","1356881620"
"Kapelle-op-den-Bos","Kapelle-op-den-Bos","51.0167","4.3667","Belgium","BE","BEL","Flanders","","9396","1056754510"
"Bāba Bakāla","Baba Bakala","31.5500","75.2500","India","IN","IND","Punjab","","9234","1356244527"
"Şūfīān","Sufian","38.2722","45.9789","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","","9963","1364080315"
"El Cairo","El Cairo","4.7500","-76.2500","Colombia","CO","COL","Valle del Cauca","minor","9976","1170273914"
"Cabo Rojo","Cabo Rojo","18.0867","-67.1482","Puerto Rico","PR","PRI","Puerto Rico","","10011","1630023608"
"Palankottai","Palankottai","9.1356","77.6952","India","IN","IND","Tamil Nādu","","9558","1356208544"
"Menzel Kamel","Menzel Kamel","35.6333","10.6667","Tunisia","TN","TUN","Monastir","","9532","1788923582"
"Hiranai","Hiranai","40.9259","140.9559","Japan","JP","JPN","Aomori","","9964","1392363448"
"Jaisinghnagar","Jaisinghnagar","23.6260","78.5726","India","IN","IND","Madhya Pradesh","","9571","1356315402"
"Alkhan-Yurt","Alkhan-Yurt","43.2317","45.5722","Russia","RU","RUS","Chechnya","","9783","1643086504"
"Fuente Palmera","Fuente Palmera","37.7000","-5.1000","Spain","ES","ESP","Andalusia","","9879","1724922514"
"Vadavālam","Vadavalam","10.4286","78.8913","India","IN","IND","Tamil Nādu","","9201","1356239743"
"Paratdiha","Paratdiha","24.1638","86.3070","India","IN","IND","Jhārkhand","","8771","1356251932"
"Hoeselt","Hoeselt","50.8500","5.4833","Belgium","BE","BEL","Flanders","","9685","1056339623"
"Uchoa","Uchoa","-20.9528","-49.1750","Brazil","BR","BRA","São Paulo","","9968","1076170916"
"Saint-Doulchard","Saint-Doulchard","47.0997","2.3728","France","FR","FRA","Centre-Val de Loire","","9607","1250470159"
"Glocester","Glocester","41.8934","-71.6889","United States","US","USA","Rhode Island","","10007","1840106230"
"Muturkha","Muturkha","24.1044","86.2855","India","IN","IND","Jhārkhand","","8770","1356245363"
"Kanhauli","Kanhauli","26.4815","86.1876","India","IN","IND","Bihār","","8890","1356710094"
"Kittery","Kittery","43.0998","-70.7126","United States","US","USA","Maine","","10006","1840052873"
"Gandikunta","Gandikunta","16.1699","80.9443","India","IN","IND","Andhra Pradesh","","9248","1356404723"
"Aytré","Aytre","46.1342","-1.1144","France","FR","FRA","Nouvelle-Aquitaine","","9247","1250544423"
"Aizenay","Aizenay","46.7400","-1.6083","France","FR","FRA","Pays de la Loire","","9881","1250153620"
"Raipur","Raipur","25.4226","86.8498","India","IN","IND","Bihār","","9122","1356026079"
"Bānki","Banki","26.1497","86.3626","India","IN","IND","Bihār","","8531","1356082660"
"Pathāri","Pathari","23.9333","78.2167","India","IN","IND","Madhya Pradesh","","9671","1356396863"
"Port Townsend","Port Townsend","48.1220","-122.7877","United States","US","USA","Washington","","10002","1840019797"
"Warrenton","Warrenton","38.7176","-77.7976","United States","US","USA","Virginia","","10002","1840006058"
"Ghadāmis","Ghadamis","30.1333","9.5000","Libya","LY","LBY","Nālūt","","10000","1434013321"
"Saint-Amand-Montrond","Saint-Amand-Montrond","46.7228","2.5050","France","FR","FRA","Centre-Val de Loire","minor","9531","1250072832"
"Al Mazyūnah","Al Mazyunah","17.8486","52.6200","Oman","OM","OMN","Z̧ufār","minor","10000","1512004977"
"Gar","Gar","32.1166","80.0583","China","CN","CHN","Tibet","minor","10000","1156975633"
"Bad Liebenzell","Bad Liebenzell","48.7742","8.7314","Germany","DE","DEU","Baden-Württemberg","","9712","1276138487"
"Arauá","Araua","-11.2619","-37.6200","Brazil","BR","BRA","Sergipe","","9947","1076564107"
"Merville","Merville","50.6439","2.6403","France","FR","FRA","Hauts-de-France","","9641","1250352360"
"Kadimetla","Kadimetla","15.7376","77.5385","India","IN","IND","Andhra Pradesh","","9382","1356024464"
"Baker City","Baker City","44.7749","-117.8320","United States","US","USA","Oregon","","9998","1840018587"
"Gūdalūr","Gudalur","10.7830","78.4232","India","IN","IND","Tamil Nādu","","9476","1356219233"
"San Calixto","San Calixto","8.4000","-73.2167","Colombia","CO","COL","Norte de Santander","minor","9961","1170279369"
"Giesen","Giesen","52.2000","9.8833","Germany","DE","DEU","Lower Saxony","","9709","1276120261"
"Kinnelon","Kinnelon","40.9847","-74.3862","United States","US","USA","New Jersey","","9994","1840000952"
"Ratne","Ratne","51.6500","24.5333","Ukraine","UA","UKR","Volynska Oblast","","9955","1804150947"
"Gundi","Gundi","18.6237","79.0923","India","IN","IND","Andhra Pradesh","","9625","1356669358"
"Vardenik","Vardenik","40.1331","45.4367","Armenia","AM","ARM","Geghark’unik’","","9880","1051146271"
"Oudenburg","Oudenburg","51.1833","3.0000","Belgium","BE","BEL","Flanders","","9717","1056496818"
"Fernán-Núñez","Fernan-Nunez","37.6667","-4.7167","Spain","ES","ESP","Andalusia","","9667","1724966021"
"Lapinlahti","Lapinlahti","63.3667","27.3833","Finland","FI","FIN","Pohjois-Savo","minor","9982","1246095473"
"Pagidyāla","Pagidyala","15.9330","78.3330","India","IN","IND","Andhra Pradesh","","9579","1356120512"
"Hlinsko","Hlinsko","49.7622","15.9075","Czechia","CZ","CZE","Pardubický Kraj","","9602","1203108794"
"Lizzano","Lizzano","40.3919","17.4483","Italy","IT","ITA","Puglia","","9990","1380351979"
"Tarhjicht","Tarhjicht","29.0564","-9.4281","Morocco","MA","MAR","Guelmim-Oued Noun","","9988","1504845971"
"Ponneri","Ponneri","12.5972","78.5979","India","IN","IND","Tamil Nādu","","9401","1356042050"
"Velpūru","Velpuru","16.1496","79.8630","India","IN","IND","Andhra Pradesh","","9667","1356780033"
"Chinnakkavundanūr","Chinnakkavundanur","11.4559","77.8673","India","IN","IND","Tamil Nādu","","9329","1356166559"
"Oued Laou","Oued Laou","35.4500","-5.0833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9665","1504708598"
"Uniontown","Uniontown","39.8993","-79.7246","United States","US","USA","Pennsylvania","","9988","1840000671"
"Bundehra","Bundehra","25.3776","86.7376","India","IN","IND","Bihār","","8778","1356257789"
"Zafargarh","Zafargarh","17.7686","79.4859","India","IN","IND","Andhra Pradesh","","9698","1356679643"
"Ruffano","Ruffano","39.9833","18.2500","Italy","IT","ITA","Puglia","","9741","1380051043"
"Corozal","Corozal","18.3410","-66.3124","Puerto Rico","PR","PRI","Puerto Rico","","9986","1630035655"
"Turgutalp","Turgutalp","39.1833","27.5833","Turkey","TR","TUR","Manisa","","9838","1792833258"
"Vegachí","Vegachi","6.7731","-74.7994","Colombia","CO","COL","Antioquia","minor","9966","1170785667"
"Foix","Foix","42.9653","1.6069","France","FR","FRA","Occitanie","minor","9493","1250783682"
"Matias Cardoso","Matias Cardoso","-14.8550","-43.9219","Brazil","BR","BRA","Minas Gerais","","9979","1076573459"
"Magny-les-Hameaux","Magny-les-Hameaux","48.7239","2.0842","France","FR","FRA","Île-de-France","","9418","1250090597"
"Tadangam","Tadangam","12.1056","78.1226","India","IN","IND","Tamil Nādu","","9360","1356780956"
"Yenice","Yenice","36.9667","35.0500","Turkey","TR","TUR","Mersin","minor","8689","1792007114"
"Magnago","Magnago","45.5792","8.8025","Italy","IT","ITA","Lombardy","","9167","1380268353"
"Heilsbronn","Heilsbronn","49.3167","10.8000","Germany","DE","DEU","Bavaria","","9825","1276724619"
"Chilanga","Chilanga","13.7167","-88.1167","El Salvador","SV","SLV","Morazán","","9700","1222531910"
"Coronel Freitas","Coronel Freitas","-26.9089","-52.7028","Brazil","BR","BRA","Santa Catarina","","9940","1076871114"
"São Sebastião do Maranhão","Sao Sebastiao do Maranhao","-18.0839","-42.5708","Brazil","BR","BRA","Minas Gerais","","9963","1076095024"
"Clinton","Clinton","36.0981","-84.1283","United States","US","USA","Tennessee","","9982","1840014471"
"Cristino Castro","Cristino Castro","-8.8178","-44.2239","Brazil","BR","BRA","Piauí","","9981","1076946451"
"Campobello di Licata","Campobello di Licata","37.2500","13.9167","Italy","IT","ITA","Sicilia","","9860","1380686879"
"Somasamudram","Somasamudram","15.1500","76.9200","India","IN","IND","Karnātaka","","9381","1356292931"
"Sauk Village","Sauk Village","41.4906","-87.5706","United States","US","USA","Illinois","","9981","1840011290"
"Safford","Safford","32.8335","-109.6964","United States","US","USA","Arizona","","9981","1840021989"
"La Sierra","La Sierra","2.2500","-76.8333","Colombia","CO","COL","Cauca","minor","9935","1170193592"
"Boves","Boves","44.3333","7.5500","Italy","IT","ITA","Piedmont","","9788","1380001815"
"Greencastle","Greencastle","39.6432","-86.8418","United States","US","USA","Indiana","","9980","1840008439"
"Vlist","Vlist","51.9667","4.7667","Netherlands","NL","NLD","Zuid-Holland","","9806","1528665852"
"Teotitlán","Teotitlan","18.1333","-97.0833","Mexico","MX","MEX","Oaxaca","minor","9876","1484390294"
"Cape Canaveral","Cape Canaveral","28.3933","-80.6049","United States","US","USA","Florida","","9979","1840015091"
"Margny-lès-Compiègne","Margny-les-Compiegne","49.4261","2.8208","France","FR","FRA","Hauts-de-France","","8676","1250111388"
"Bastak","Bastak","27.1992","54.3667","Iran","IR","IRN","Hormozgān","minor","9959","1364136861"
"Keokuk","Keokuk","40.4095","-91.4031","United States","US","USA","Iowa","","9977","1840001036"
"Karankot","Karankot","17.2866","77.5644","India","IN","IND","Andhra Pradesh","","9764","1356640136"
"Gudimūlakhandrika","Gudimulakhandrika","16.4090","81.8030","India","IN","IND","Andhra Pradesh","","9222","1356158755"
"Älmhult","Almhult","56.5500","14.1333","Sweden","SE","SWE","Kronoberg","minor","8955","1752497359"
"Cedar Hills","Cedar Hills","40.4135","-111.7531","United States","US","USA","Utah","","9975","1840018754"
"DuPont","DuPont","47.1079","-122.6495","United States","US","USA","Washington","","9974","1840018457"
"Ii","Ii","65.3167","25.3722","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","9966","1246489328"
"Naqneh","Naqneh","31.9336","51.3306","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","9923","1364311780"
"Punnaikkāyal","Punnaikkayal","8.6322","78.1119","India","IN","IND","Tamil Nādu","","9267","1356214131"
"Chinnāmpālaiyam","Chinnampalaiyam","10.6604","77.0248","India","IN","IND","Tamil Nādu","","9353","1356170392"
"Budd Lake","Budd Lake","40.8733","-74.7375","United States","US","USA","New Jersey","","9971","1840005195"
"Boudinar","Boudinar","35.1500","-3.6333","Morocco","MA","MAR","Oriental","","9863","1504277185"
"Pasian di Prato","Pasian di Prato","46.0500","13.2000","Italy","IT","ITA","Friuli Venezia Giulia","","9363","1380848051"
"Ventania","Ventania","-24.2458","-50.2428","Brazil","BR","BRA","Paraná","","9957","1076340729"
"Budhma","Budhma","25.6451","86.8317","India","IN","IND","Bihār","","9176","1356004590"
"Consuegra","Consuegra","39.4619","-3.6064","Spain","ES","ESP","Castille-La Mancha","","9942","1724179582"
"Honge","Honge","15.8800","74.5600","India","IN","IND","Karnātaka","","8979","1356158563"
"Mīāndasht","Miandasht","33.0736","50.1647","Iran","IR","IRN","Eşfahān","","9933","1364540083"
"Pereshchepyne","Pereshchepyne","49.0179","35.3598","Ukraine","UA","UKR","Dnipropetrovska Oblast","","9931","1804752457"
"Daruvar","Daruvar","45.5929","17.2237","Croatia","HR","HRV","Bjelovarsko-Bilogorska Županija","minor","9815","1191459511"
"Saint-Lys","Saint-Lys","43.5142","1.1775","France","FR","FRA","Occitanie","","9521","1250561476"
"Immingham","Immingham","53.6139","-0.2183","United Kingdom","GB","GBR","North East Lincolnshire","","9642","1826538121"
"Wāngjing","Wangjing","24.3862","93.8604","India","IN","IND","Manipur","","9200","1356795986"
"Santa Cruz Atizapán","Santa Cruz Atizapan","19.1756","-99.4886","Mexico","MX","MEX","México","minor","8909","1484011710"
"Dongcha","Dongcha","34.3800","106.6623","China","CN","CHN","Gansu","","9920","1156014630"
"San Francisco la Unión","San Francisco la Union","14.9167","-91.5333","Guatemala","GT","GTM","Quetzaltenango","minor","9665","1320800465"
"Strzelce Krajeńskie","Strzelce Krajenskie","52.8756","15.5319","Poland","PL","POL","Lubuskie","minor","9912","1616150192"
"Imaruí","Imarui","-28.3408","-48.8200","Brazil","BR","BRA","Santa Catarina","","9948","1076901727"
"Liesveld","Liesveld","51.9156","4.8456","Netherlands","NL","NLD","Zuid-Holland","","9746","1528417932"
"Shāhganj","Shahganj","22.8467","77.7980","India","IN","IND","Madhya Pradesh","","9749","1356353440"
"Catunda","Catunda","-4.6478","-40.2008","Brazil","BR","BRA","Ceará","","9952","1076914207"
"Jaisinghnagar","Jaisinghnagar","23.6858","81.3909","India","IN","IND","Madhya Pradesh","","9758","1356232050"
"Airmont","Airmont","41.0992","-74.0989","United States","US","USA","New York","","9964","1840004991"
"Coapilla","Coapilla","17.1167","-93.1333","Mexico","MX","MEX","Chiapas","minor","9900","1484804028"
"Ḩās","Has","35.6169","36.5953","Syria","SY","SYR","Idlib","","9595","1760767618"
"Cuicatlan","Cuicatlan","17.8000","-96.9500","Mexico","MX","MEX","Oaxaca","","9945","1484474019"
"Itatiaiuçu","Itatiaiucu","-20.1969","-44.4208","Brazil","BR","BRA","Minas Gerais","","9928","1076406808"
"Ribadeo","Ribadeo","43.5336","-7.0403","Spain","ES","ESP","Galicia","","9871","1724577881"
"Ploërmel","Ploermel","47.9317","-2.3975","France","FR","FRA","Bretagne","","9791","1250219929"
"Dessel","Dessel","51.2333","5.1167","Belgium","BE","BEL","Flanders","","9605","1056333451"
"Coroaci","Coroaci","-18.6219","-42.2858","Brazil","BR","BRA","Minas Gerais","","9943","1076620759"
"Leppävirta","Leppavirta","62.4917","27.7875","Finland","FI","FIN","Pohjois-Savo","minor","9953","1246237547"
"Sorkheh","Sorkheh","35.4633","53.2139","Iran","IR","IRN","Semnān","minor","9951","1364888685"
"Mareno di Piave","Mareno di Piave","45.8409","12.3520","Italy","IT","ITA","Veneto","","9613","1380116236"
"Höhr-Grenzhausen","Hohr-Grenzhausen","50.4350","7.6711","Germany","DE","DEU","Rhineland-Palatinate","","9368","1276961599"
"Sande","Sande","59.5936","10.2076","Norway","NO","NOR","Vestfold og Telemark","","9904","1578002266"
"Gessate","Gessate","45.5500","9.4333","Italy","IT","ITA","Lombardy","","8821","1380428162"
"Genemuiden","Genemuiden","52.6244","6.0397","Netherlands","NL","NLD","Overijssel","","9700","1528251889"
"Maihma Sarja","Maihma Sarja","30.3110","74.8427","India","IN","IND","Punjab","","9319","1356846640"
"Novyye Atagi","Novyye Atagi","43.1328","45.7797","Russia","RU","RUS","Chechnya","","9738","1643006509"
"Tha Mai","Tha Mai","12.6196","102.0112","Thailand","TH","THA","Chanthaburi","minor","9843","1764588619"
"Sparta","Sparta","43.9377","-90.8131","United States","US","USA","Wisconsin","","9955","1840002565"
"Manduri","Manduri","-23.0033","-49.3219","Brazil","BR","BRA","São Paulo","","9910","1076633866"
"Chintapalle","Chintapalle","17.8667","82.3500","India","IN","IND","Andhra Pradesh","","9882","1356351597"
"Lenoir City","Lenoir City","35.8111","-84.2818","United States","US","USA","Tennessee","","9953","1840014514"
"Estavayer-le-Lac","Estavayer-le-Lac","46.8500","6.8472","Switzerland","CH","CHE","Fribourg","","9712","1756917471"
"Pinos Puente","Pinos Puente","37.2500","-3.7500","Spain","ES","ESP","Andalusia","","9853","1724568030"
"Māmidipalli","Mamidipalli","18.7772","78.2962","India","IN","IND","Andhra Pradesh","","9188","1356946410"
"Fair Oaks Ranch","Fair Oaks Ranch","29.7467","-98.6376","United States","US","USA","Texas","","9951","1840020939"
"Piedras Blancas","Piedras Blancas","43.5600","-5.9760","Spain","ES","ESP","Asturias","","9533","1724519867"
"Monteriggioni","Monteriggioni","43.4000","11.2167","Italy","IT","ITA","Tuscany","","9937","1380529718"
"Treillières","Treillieres","47.3308","-1.6267","France","FR","FRA","Pays de la Loire","","9617","1250987852"
"Terra de Areia","Terra de Areia","-29.5850","-50.0708","Brazil","BR","BRA","Rio Grande do Sul","","9878","1076030490"
"Holywell","Holywell","53.2740","-3.2230","United Kingdom","GB","GBR","Flintshire","","8886","1826527562"
"Wadgira","Wadgira","16.5858","77.0980","India","IN","IND","Karnātaka","","9700","1356118062"
"Kourimat","Kourimat","31.4500","-9.3167","Morocco","MA","MAR","Marrakech-Safi","","9897","1504079071"
"Mādhavaram","Madhavaram","16.8900","81.5937","India","IN","IND","Andhra Pradesh","","9001","1356100884"
"Néa Moudaniá","Nea Moudania","40.2386","23.2814","Greece","GR","GRC","Kentrikí Makedonía","minor","9342","1300681996"
"Bukowno","Bukowno","50.2681","19.4631","Poland","PL","POL","Małopolskie","","9791","1616483621"
"Großröhrsdorf","Grossrohrsdorf","51.1444","14.0167","Germany","DE","DEU","Saxony","","9708","1276821884"
"Colindres","Colindres","43.3967","-3.4483","Spain","ES","ESP","Cantabria","","8512","1724098136"
"Govindāpuram","Govindapuram","16.1548","80.1027","India","IN","IND","Andhra Pradesh","","9106","1356253682"
"Worth","Worth","51.1130","-0.1470","United Kingdom","GB","GBR","West Sussex","","9888","1826978438"
"Towcester","Towcester","52.1300","-0.9900","United Kingdom","GB","GBR","Northamptonshire","","9252","1826221011"
"Vidor","Vidor","30.1291","-93.9967","United States","US","USA","Texas","","9944","1840022193"
"Malhārgarh","Malhargarh","24.2829","74.9902","India","IN","IND","Madhya Pradesh","","9687","1356997775"
"Umburetama","Umburetama","-7.6958","-35.6639","Brazil","BR","BRA","Pernambuco","","9889","1076928372"
"Hamīra","Hamira","31.4589","75.4304","India","IN","IND","Punjab","","9370","1356235581"
"Bellavista","Bellavista","6.5236","-76.9744","Colombia","CO","COL","Chocó","minor","9941","1170717666"
"Reedsburg","Reedsburg","43.5348","-89.9965","United States","US","USA","Wisconsin","","9942","1840002727"
"Panjāb","Panjab","34.3833","67.0167","Afghanistan","AF","AFG","Bāmyān","minor","9900","1004837341"
"Mae Ai","Mae Ai","20.0296","99.2847","Thailand","TH","THA","Chiang Mai","minor","9699","1764592962"
"Conselice","Conselice","44.5167","11.8333","Italy","IT","ITA","Emilia-Romagna","","9779","1380930733"
"North Lakes","North Lakes","61.6191","-149.3110","United States","US","USA","Alaska","","9941","1840152941"
"Fairmount","Fairmount","39.7931","-105.1711","United States","US","USA","Colorado","","9939","1840038703"
"Outa Bouabane","Outa Bouabane","34.2606","-4.4139","Morocco","MA","MAR","Fès-Meknès","","9856","1504009188"
"Zell am See","Zell am See","47.3233","12.7981","Austria","AT","AUT","Salzburg","minor","9762","1040352901"
"Saint-Rémy-de-Provence","Saint-Remy-de-Provence","43.7894","4.8317","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9829","1250000185"
"Gunnedah","Gunnedah","-30.9817","150.2511","Australia","AU","AUS","New South Wales","","9726","1036500021"
"Jagannādapuram","Jagannadapuram","13.2745","80.1802","India","IN","IND","Tamil Nādu","","9183","1356034920"
"Pokrovske","Pokrovske","47.9853","36.2367","Ukraine","UA","UKR","Dnipropetrovska Oblast","","9910","1804930095"
"Majali","Majali","14.9000","74.1000","India","IN","IND","Karnātaka","","9588","1356105259"
"Ingré","Ingre","47.9206","1.8242","France","FR","FRA","Centre-Val de Loire","","9482","1250221426"
"Zaozërsk","Zaozersk","69.3978","32.4492","Russia","RU","RUS","Murmanskaya Oblast’","","9915","1643823652"
"Lagbé","Lagbe","6.6833","2.6833","Benin","BJ","BEN","Plateau","","9138","1204458286"
"Gidha","Gidha","26.0507","86.8176","India","IN","IND","Bihār","","9068","1356142284"
"Pangunattam","Pangunattam","12.1112","78.0123","India","IN","IND","Tamil Nādu","","9311","1356219631"
"Nambutalai","Nambutalai","9.7277","79.0071","India","IN","IND","Tamil Nādu","","9625","1356056741"
"Kostrzyń","Kostrzyn","52.3942","17.2222","Poland","PL","POL","Wielkopolskie","","9809","1616197568"
"Montescaglioso","Montescaglioso","40.5500","16.6667","Italy","IT","ITA","Basilicata","","9877","1380149213"
"Tafersit","Tafersit","35.0167","-3.5333","Morocco","MA","MAR","Oriental","","9133","1504168581"
"Keora","Keora","25.4374","85.0726","India","IN","IND","Bihār","","8850","1356600940"
"Katahra","Katahra","26.2235","87.0643","India","IN","IND","Bihār","","8865","1356759834"
"Deruta","Deruta","42.9833","12.4167","Italy","IT","ITA","Umbria","","9713","1380405039"
"Dināra","Dinara","25.4613","78.3205","India","IN","IND","Madhya Pradesh","","9674","1356253897"
"Caldas de Reyes","Caldas de Reyes","42.6028","-8.6383","Spain","ES","ESP","Galicia","","9788","1724509149"
"Rubim","Rubim","-16.3750","-40.5378","Brazil","BR","BRA","Minas Gerais","","9919","1076786475"
"Rurka Kalān","Rurka Kalan","31.0700","75.4100","India","IN","IND","Punjab","","9333","1356210729"
"Little River","Little River","33.8786","-78.6393","United States","US","USA","South Carolina","","9929","1840013648"
"Willoughby Hills","Willoughby Hills","41.5873","-81.4333","United States","US","USA","Ohio","","9929","1840000548"
"Bad Gandersheim","Bad Gandersheim","51.8719","10.0253","Germany","DE","DEU","Lower Saxony","","9823","1276778177"
"George Mason","George Mason","38.8356","-77.3186","United States","US","USA","Virginia","","9927","1840041768"
"Urzhum","Urzhum","57.1167","50.0000","Russia","RU","RUS","Kirovskaya Oblast’","","9919","1643578582"
"Kendallville","Kendallville","41.4441","-85.2578","United States","US","USA","Indiana","","9926","1840008208"
"Marysville","Marysville","42.9084","-82.4806","United States","US","USA","Michigan","","9925","1840003957"
"Byalynichy","Byalynichy","53.9956","29.7096","Belarus","BY","BLR","Mahilyowskaya Voblasts’","minor","9913","1112253671"
"Kattamūru","Kattamuru","17.0800","82.1300","India","IN","IND","Andhra Pradesh","","8980","1356540764"
"Castel Bolognese","Castel Bolognese","44.3167","11.8000","Italy","IT","ITA","Emilia-Romagna","","9628","1380286347"
"Mount Vista","Mount Vista","45.7373","-122.6316","United States","US","USA","Washington","","9924","1840037858"
"Jilotlán de los Dolores","Jilotlan de los Dolores","19.3719","-103.0193","Mexico","MX","MEX","Jalisco","minor","9917","1484298923"
"Beneditinos","Beneditinos","-5.4550","-42.3600","Brazil","BR","BRA","Piauí","","9911","1076594743"
"Vargem","Vargem","-22.8889","-46.4139","Brazil","BR","BRA","São Paulo","","9854","1076267922"
"Pórto Ráfti","Porto Rafti","37.8844","24.0125","Greece","GR","GRC","Attikí","","9686","1300931279"
"Hardiya","Hardiya","25.5301","84.4961","India","IN","IND","Bihār","","8766","1356944906"
"Brandis","Brandis","51.3347","12.6089","Germany","DE","DEU","Saxony","","9646","1276455051"
"Saarijärvi","Saarijarvi","62.7050","25.2583","Finland","FI","FIN","Keski-Suomi","minor","9915","1246361506"
"Waltenhofen","Waltenhofen","47.6667","10.3000","Germany","DE","DEU","Bavaria","","9759","1276889694"
"Saidia","Saidia","35.0850","-2.2392","Morocco","MA","MAR","Oriental","","8780","1504105811"
"Kaikaram","Kaikaram","16.8120","81.3660","India","IN","IND","Andhra Pradesh","","9532","1356164040"
"East Setauket","East Setauket","40.9210","-73.0942","United States","US","USA","New York","","9921","1840033876"
"Wapakoneta","Wapakoneta","40.5664","-84.1916","United States","US","USA","Ohio","","9921","1840010391"
"Bee Ridge","Bee Ridge","27.2855","-82.4731","United States","US","USA","Florida","","9921","1840028632"
"Cosne sur Loire","Cosne sur Loire","47.4103","2.9250","France","FR","FRA","Bourgogne-Franche-Comté","minor","9741","1250995203"
"Anantpur","Anantpur","16.8890","75.0146","India","IN","IND","Karnātaka","","9620","1356733791"
"Temiskaming Shores","Temiskaming Shores","47.5167","-79.6833","Canada","CA","CAN","Ontario","","9920","1124001880"
"Carregal do Sal","Carregal do Sal","40.4333","-8.0000","Portugal","PT","PRT","Viseu","","9835","1620089281"
"Ngaparou","Ngaparou","14.4631","-17.0581","Senegal","SN","SEN","Thiès","","9500","1686694843"
"East Rutherford","East Rutherford","40.8179","-74.0854","United States","US","USA","New Jersey","","9918","1840003542"
"Mount Holly","Mount Holly","39.9950","-74.7863","United States","US","USA","New Jersey","","9918","1840081635"
"Idumbāvanam","Idumbavanam","10.4224","79.6001","India","IN","IND","Tamil Nādu","","9449","1356173351"
"Altmünster","Altmunster","47.9000","13.7667","Austria","AT","AUT","Oberösterreich","","9793","1040325018"
"Coello","Coello","4.3333","-74.9167","Colombia","CO","COL","Tolima","minor","9887","1170249234"
"Juru","Juru","-7.5369","-37.8189","Brazil","BR","BRA","Paraíba","","9892","1076841131"
"Argelato","Argelato","44.5758","11.3486","Italy","IT","ITA","Emilia-Romagna","","9865","1380559231"
"Talachyn","Talachyn","54.4167","29.7000","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","9899","1112016670"
"Valtoha","Valtoha","31.2074","74.6423","India","IN","IND","Punjab","","9492","1356314548"
"Poggio Renatico","Poggio Renatico","44.7650","11.4833","Italy","IT","ITA","Emilia-Romagna","","9791","1380751237"
"Non Sung","Non Sung","15.1788","102.2514","Thailand","TH","THA","Nakhon Ratchasima","minor","9730","1764677081"
"Fällanden","Fallanden","47.3717","8.6400","Switzerland","CH","CHE","Zürich","","8575","1756176388"
"Tiptree","Tiptree","51.8100","0.7500","United Kingdom","GB","GBR","Essex","","9182","1826098302"
"Aranzazu","Aranzazu","5.3000","-75.4500","Colombia","CO","COL","Caldas","minor","9854","1170469075"
"Mirante","Mirante","-14.2419","-40.7728","Brazil","BR","BRA","Bahia","","9902","1076774465"
"Kudowa-Zdrój","Kudowa-Zdroj","50.4383","16.2394","Poland","PL","POL","Dolnośląskie","","9627","1616102407"
"San Pedro Ixtlahuaca","San Pedro Ixtlahuaca","17.0500","-96.8167","Mexico","MX","MEX","Oaxaca","","8561","1484203625"
"Ingichka","Ingichka","39.7389","65.9922","Uzbekistan","UZ","UZB","Samarqand","","9676","1860688085"
"Revel","Revel","43.4586","2.0042","France","FR","FRA","Occitanie","","9637","1250490543"
"Margarita","Margarita","9.0833","-74.2000","Colombia","CO","COL","Bolívar","minor","9876","1170553154"
"Rāmpura","Rampura","14.8810","76.7814","India","IN","IND","Karnātaka","","9703","1356066693"
"Rute","Rute","37.3167","-4.3667","Spain","ES","ESP","Andalusia","","9835","1724167634"
"Rye Brook","Rye Brook","41.0302","-73.6864","United States","US","USA","New York","","9909","1840004942"
"Punnavalli","Punnavalli","16.4062","80.4010","India","IN","IND","Andhra Pradesh","","9703","1356542997"
"Konidena","Konidena","16.0156","80.0570","India","IN","IND","Andhra Pradesh","","9650","1356752751"
"Colac","Colac","-38.3403","143.5847","Australia","AU","AUS","Victoria","","9048","1036633113"
"Mīnākshipuram","Minakshipuram","9.9908","77.3709","India","IN","IND","Tamil Nādu","","9539","1356241688"
"New Richmond","New Richmond","45.1250","-92.5377","United States","US","USA","Wisconsin","","9908","1840002138"
"Ellisville","Ellisville","38.5897","-90.5884","United States","US","USA","Missouri","","9908","1840008581"
"Agoué","Agoue","6.2500","1.6833","Benin","BJ","BEN","Mono","","9589","1204642749"
"Monticello Conte Otto","Monticello Conte Otto","45.6000","11.5833","Italy","IT","ITA","Veneto","","9026","1380040208"
"Brinkmann","Brinkmann","-30.8669","-62.0336","Argentina","AR","ARG","Córdoba","","9890","1032512387"
"Rautara","Rautara","25.6630","87.5378","India","IN","IND","Bihār","","8791","1356953319"
"Worsborough","Worsborough","53.5200","-1.4700","United Kingdom","GB","GBR","Barnsley","","9516","1826794690"
"Adiyakkamangalam","Adiyakkamangalam","10.7626","79.6775","India","IN","IND","Tamil Nādu","","8776","1356175056"
"Sasaima","Sasaima","4.9650","-74.4347","Colombia","CO","COL","Cundinamarca","minor","9807","1170341139"
"Rotonda","Rotonda","26.8844","-82.2791","United States","US","USA","Florida","","9906","1840014201"
"Queensferry","Queensferry","55.9900","-3.3980","United Kingdom","GB","GBR","Edinburgh, City of","","9350","1826335881"
"Csorna","Csorna","47.6167","17.2500","Hungary","HU","HUN","Győr-Moson-Sopron","minor","9799","1348687588"
"Trancoso","Trancoso","40.7833","-7.3500","Portugal","PT","PRT","Guarda","minor","9878","1620483334"
"Balatonalmádi","Balatonalmadi","47.0333","18.0167","Hungary","HU","HUN","Veszprém","minor","9710","1348907128"
"Redon","Redon","47.6514","-2.0847","France","FR","FRA","Bretagne","minor","9287","1250563349"
"Devanāngurichchi","Devanangurichchi","11.3895","77.8547","India","IN","IND","Tamil Nādu","","8991","1356166609"
"Jesup","Jesup","31.5992","-81.8895","United States","US","USA","Georgia","","9902","1840014943"
"Lago Ranco","Lago Ranco","-40.3167","-72.5000","Chile","CL","CHL","Los Ríos","","9896","1152909758"
"La Grange","La Grange","38.3987","-85.3750","United States","US","USA","Kentucky","","9901","1840014287"
"Nāgathān","Nagathan","16.8300","75.7300","India","IN","IND","Karnātaka","","9576","1356765093"
"Khutha Baijnāth","Khutha Baijnath","26.0268","87.1170","India","IN","IND","Bihār","","8520","1356618371"
"Alcorta","Alcorta","-33.5333","-61.1167","Argentina","AR","ARG","Santa Fe","","9870","1032368367"
"Dagarua","Dagarua","25.8000","87.6400","India","IN","IND","Bihār","","8643","1356757539"
"San Giovanni in Marignano","San Giovanni in Marignano","43.9393","12.7137","Italy","IT","ITA","Emilia-Romagna","","9455","1380499308"
"Tenente Ananias","Tenente Ananias","-6.4650","-38.1800","Brazil","BR","BRA","Rio Grande do Norte","","9883","1076521630"
"Tāmganj","Tamganj","26.2478","87.1569","India","IN","IND","Bihār","","8830","1356444706"
"Bisaul","Bisaul","26.6151","85.9930","India","IN","IND","Bihār","","8636","1356113142"
"Karczew","Karczew","52.0833","21.2500","Poland","PL","POL","Mazowieckie","","9566","1616830765"
"Åstorp","Astorp","56.1347","12.9472","Sweden","SE","SWE","Skåne","minor","9488","1752829425"
"Nong Wua So","Nong Wua So","17.2702","102.5985","Thailand","TH","THA","Udon Thani","minor","9816","1764122140"
"Qiziltepa","Qiziltepa","40.0361","64.8503","Uzbekistan","UZ","UZB","Navoiy","","9884","1860348538"
"Néa Artáki","Nea Artaki","38.5167","23.6333","Greece","GR","GRC","Stereá Elláda","","9489","1300651375"
"Bellinzago Novarese","Bellinzago Novarese","45.5833","8.6333","Italy","IT","ITA","Piedmont","","9646","1380568488"
"Jāwalgeri","Jawalgeri","15.8656","76.8156","India","IN","IND","Karnātaka","","9622","1356240955"
"Patchūr","Patchur","12.5949","78.4622","India","IN","IND","Tamil Nādu","","9303","1356028325"
"Resana","Resana","45.6333","11.9500","Italy","IT","ITA","Veneto","","9509","1380160177"
"Ashukino","Ashukino","56.1611","37.9464","Russia","RU","RUS","Moskovskaya Oblast’","","9544","1643004998"
"Chimay","Chimay","50.0500","4.3167","Belgium","BE","BEL","Wallonia","","9841","1056608598"
"Rothrist","Rothrist","47.3028","7.8833","Switzerland","CH","CHE","Aargau","","9121","1756245430"
"Mezőberény","Mezobereny","46.8167","21.0167","Hungary","HU","HUN","Békés","","9809","1348124079"
"Cachipay","Cachipay","4.7308","-74.4375","Colombia","CO","COL","Cundinamarca","minor","9833","1170432909"
"Chevy Chase","Chevy Chase","38.9944","-77.0738","United States","US","USA","Maryland","","9889","1840031323"
"Nadimpālem","Nadimpalem","16.2123","80.3097","India","IN","IND","Andhra Pradesh","","9484","1356512446"
"Siano","Siano","40.8025","14.6944","Italy","IT","ITA","Campania","","9883","1380722708"
"River Vale","River Vale","41.0138","-74.0080","United States","US","USA","New Jersey","","9888","1840081804"
"Uppalaguptam","Uppalaguptam","16.5667","82.1000","India","IN","IND","Andhra Pradesh","","9397","1356477382"
"Vaikuntam","Vaikuntam","11.5197","77.9398","India","IN","IND","Tamil Nādu","","9228","1356167064"
"Bjärred","Bjarred","55.7167","13.0167","Sweden","SE","SWE","Skåne","","9542","1752885490"
"Saidoke","Saidoke","30.5267","75.2867","India","IN","IND","Punjab","","9427","1356877324"
"Kushnarënkovo","Kushnarenkovo","55.1049","55.3479","Russia","RU","RUS","Bashkortostan","","9870","1643940498"
"Sundarsi","Sundarsi","23.2688","76.4399","India","IN","IND","Madhya Pradesh","","9471","1356021338"
"Gangādhar","Gangadhar","18.5883","79.0110","India","IN","IND","Andhra Pradesh","","9543","1356944244"
"Franklin","Franklin","36.7177","-86.5595","United States","US","USA","Kentucky","","9885","1840013290"
"Alukkuli","Alukkuli","11.4458","77.3574","India","IN","IND","Tamil Nādu","","9263","1356163498"
"Santa María Jacatepec","Santa Maria Jacatepec","17.8500","-96.2000","Mexico","MX","MEX","Oaxaca","minor","9474","1484347290"
"Mission","Mission","39.0270","-94.6568","United States","US","USA","Kansas","","9884","1840003831"
"Narikombu","Narikombu","12.9000","75.0300","India","IN","IND","Karnātaka","","9267","1356061069"
"Oakbrook","Oakbrook","38.9996","-84.6797","United States","US","USA","Kentucky","","9883","1840030814"
"Bockenem","Bockenem","52.0117","10.1319","Germany","DE","DEU","Lower Saxony","","9795","1276993037"
"Hinton","Hinton","53.4114","-117.5639","Canada","CA","CAN","Alberta","","9882","1124131074"
"Sānampūdi","Sanampudi","16.0860","79.8350","India","IN","IND","Andhra Pradesh","","9570","1356372869"
"Alaçatı","Alacati","38.2814","26.3742","Turkey","TR","TUR","İzmir","minor","9745","1792668742"
"Kondalahalli","Kondalahalli","14.7200","76.7600","India","IN","IND","Karnātaka","","9675","1356106236"
"Saint-Sauveur","Saint-Sauveur","45.9000","-74.1700","Canada","CA","CAN","Quebec","","9881","1124720935"
"Toudja","Toudja","36.7586","4.8933","Algeria","DZ","DZA","Bejaïa","","9827","1012114797"
"Powdersville","Powdersville","34.7826","-82.4959","United States","US","USA","South Carolina","","9880","1840013581"
"Zinkiv","Zinkiv","50.2081","34.3668","Ukraine","UA","UKR","Poltavska Oblast","","9856","1804890384"
"Luçon","Lucon","46.4547","-1.1658","France","FR","FRA","Pays de la Loire","","9576","1250492396"
"Bastrop","Bastrop","32.7748","-91.9078","United States","US","USA","Louisiana","","9879","1840013793"
"Quesnel","Quesnel","52.9784","-122.4927","Canada","CA","CAN","British Columbia","","9879","1124028015"
"Beuvry","Beuvry","50.5197","2.6794","France","FR","FRA","Hauts-de-France","","9331","1250048096"
"Torrinha","Torrinha","-22.4258","-48.1689","Brazil","BR","BRA","São Paulo","","9846","1076302345"
"Dodworth","Dodworth","53.5417","-1.5214","United Kingdom","GB","GBR","Barnsley","","9777","1826948211"
"San Bartolomé Milpas Altas","San Bartolome Milpas Altas","14.6046","-90.6800","Guatemala","GT","GTM","Sacatepéquez","minor","8642","1320802747"
"Muddanūru","Muddanuru","14.6667","78.4000","India","IN","IND","Andhra Pradesh","","9775","1356315811"
"Ruoqiang","Ruoqiang","39.0181","88.1681","China","CN","CHN","Xinjiang","minor","9872","1156754744"
"Moyogalpa","Moyogalpa","11.5403","-85.6969","Nicaragua","NI","NIC","Rivas","minor","9729","1558198586"
"Varna","Varna","53.3806","60.9803","Russia","RU","RUS","Chelyabinskaya Oblast’","","9869","1643475830"
"Baisuhalli","Baisuhalli","12.2064","78.1906","India","IN","IND","Tamil Nādu","","9365","1356586505"
"Qahjāvarestān","Qahjavarestan","32.7039","51.8381","Iran","IR","IRN","Eşfahān","","9712","1364849715"
"Norosí","Norosi","8.5261","-74.0378","Colombia","CO","COL","Bolívar","minor","9850","1170174177"
"Singhāna","Singhana","22.1902","74.9702","India","IN","IND","Madhya Pradesh","","9394","1356593603"
"Ferryhill","Ferryhill","54.6900","-1.5500","United Kingdom","GB","GBR","Durham","","8942","1826891568"
"Hawera","Hawera","-39.5933","174.2783","New Zealand","NZ","NZL","Taranaki","","9792","1554872573"
"Hobart","Hobart","44.4967","-88.1602","United States","US","USA","Wisconsin","","9872","1840038100"
"Dasso","Dasso","7.0167","2.4667","Benin","BJ","BEN","Zou","","9607","1204988587"
"Titu","Titu","44.6622","25.5736","Romania","RO","ROU","Dâmboviţa","","9658","1642607155"
"Pobiedziska","Pobiedziska","52.4833","17.4833","Poland","PL","POL","Wielkopolskie","","9755","1616215352"
"Baikunthapur","Baikunthapur","22.9117","87.9668","India","IN","IND","West Bengal","","8794","1356060972"
"Guateque","Guateque","5.0056","-73.4722","Colombia","CO","COL","Boyacá","minor","9603","1170912824"
"Pinjranwān","Pinjranwan","25.1702","84.8203","India","IN","IND","Bihār","","8635","1356587810"
"Serafimovskiy","Serafimovskiy","54.4333","53.8167","Russia","RU","RUS","Bashkortostan","","9813","1643861038"
"Flossmoor","Flossmoor","41.5391","-87.6858","United States","US","USA","Illinois","","9868","1840011274"
"Arden Hills","Arden Hills","45.0721","-93.1670","United States","US","USA","Minnesota","","9868","1840006752"
"Giardini","Giardini","37.8333","15.2833","Italy","IT","ITA","Sicilia","","9337","1380845340"
"Nāgasamudra","Nagasamudra","14.7200","76.7600","India","IN","IND","Karnātaka","","9661","1356868987"
"Manchenahalli","Manchenahalli","13.5007","77.6035","India","IN","IND","Karnātaka","","9510","1356126825"
"Villas","Villas","39.0157","-74.9350","United States","US","USA","New Jersey","","9866","1840005869"
"Hornsby Bend","Hornsby Bend","30.2388","-97.5899","United States","US","USA","Texas","","9866","1840027020"
"Kobeliaky","Kobeliaky","49.1474","34.1993","Ukraine","UA","UKR","Poltavska Oblast","minor","9841","1804297105"
"Gāndlapenta","Gandlapenta","14.0500","78.3089","India","IN","IND","Andhra Pradesh","","9611","1356354351"
"Pedavīdu","Pedavidu","16.8558","79.8937","India","IN","IND","Andhra Pradesh","","9628","1356846593"
"Bueng Khong Long","Bueng Khong Long","17.9667","104.0450","Thailand","TH","THA","Bueng Kan","minor","9777","1764051727"
"Berezivka","Berezivka","47.2039","30.9125","Ukraine","UA","UKR","Odeska Oblast","minor","9845","1804490359"
"Quilombo","Quilombo","-26.7258","-52.7208","Brazil","BR","BRA","Santa Catarina","","9829","1076105632"
"Beltangadi","Beltangadi","12.9795","75.2699","India","IN","IND","Karnātaka","","9642","1356104826"
"Sarapuí","Sarapui","-23.6408","-47.8250","Brazil","BR","BRA","São Paulo","","9836","1076402812"
"Havanūr","Havanur","14.8667","75.7000","India","IN","IND","Karnātaka","","9643","1356859648"
"Atoka","Atoka","35.4239","-89.7861","United States","US","USA","Tennessee","","9863","1840015416"
"Belomorsk","Belomorsk","64.5253","34.7658","Russia","RU","RUS","Kareliya","","9861","1643588400"
"Broni","Broni","45.0619","9.2611","Italy","IT","ITA","Lombardy","","9411","1380772984"
"Peru","Peru","41.3482","-89.1371","United States","US","USA","Illinois","","9862","1840009273"
"Ciudad de Loreto","Ciudad de Loreto","-28.3000","-64.2000","Argentina","AR","ARG","Santiago del Estero","","9854","1032623436"
"Makariv","Makariv","50.4648","29.8072","Ukraine","UA","UKR","Kyivska Oblast","minor","9836","1804794239"
"Piombino Dese","Piombino Dese","45.6067","11.9979","Italy","IT","ITA","Veneto","","9539","1380807342"
"Aulnoye-Aymeries","Aulnoye-Aymeries","50.2047","3.8356","France","FR","FRA","Hauts-de-France","","8840","1250886676"
"San Vicente","San Vicente","-1.9000","-79.2333","Ecuador","EC","ECU","Bolívar","","9819","1218567919"
"Croydon","Croydon","40.0911","-74.8975","United States","US","USA","Pennsylvania","","9860","1840005406"
"Renaico","Renaico","-37.6667","-72.5833","Chile","CL","CHL","Araucanía","","9850","1152989264"
"Jerissa","Jerissa","35.8500","8.6333","Tunisia","TN","TUN","Kef","","9807","1788448705"
"Litovel","Litovel","49.7012","17.0762","Czechia","CZ","CZE","Olomoucký Kraj","","9651","1203150242"
"Kambaneri Pudukkudi","Kambaneri Pudukkudi","9.0685","77.3795","India","IN","IND","Tamil Nādu","","9003","1356214247"
"Seysses","Seysses","43.4981","1.3125","France","FR","FRA","Occitanie","","9481","1250913509"
"Łobez","Lobez","53.6333","15.6167","Poland","PL","POL","Zachodniopomorskie","minor","9792","1616627277"
"Independent Hill","Independent Hill","38.6404","-77.4089","United States","US","USA","Virginia","","9855","1840026708"
"Elizabethtown-Kitley","Elizabethtown-Kitley","44.7000","-75.8833","Canada","CA","CAN","Ontario","","9854","1124001450"
"Sabana Larga","Sabana Larga","18.5850","-70.4982","Dominican Republic","DO","DOM","Valdesia","","9794","1214812327"
"Nersingen","Nersingen","48.4289","10.1219","Germany","DE","DEU","Bavaria","","9464","1276170388"
"Mora","Mora","39.6840","-3.7770","Spain","ES","ESP","Castille-La Mancha","","9796","1724961041"
"Pailón","Pailon","-17.6594","-62.7197","Bolivia","BO","BOL","Santa Cruz","","9850","1068158658"
"Mulungu","Mulungu","-7.0239","-35.4619","Brazil","BR","BRA","Paraíba","","9840","1076888806"
"Brandywine","Brandywine","38.6963","-76.8846","United States","US","USA","Maryland","","9852","1840005946"
"Winfield","Winfield","41.8787","-88.1507","United States","US","USA","Illinois","","9852","1840011410"
"Mosjøen","Mosjoen","65.8370","13.1913","Norway","NO","NOR","Nordland","minor","9843","1578980285"
"Photharam","Photharam","13.6918","99.8531","Thailand","TH","THA","Ratchaburi","minor","9575","1764429218"
"Vanukūru","Vanukuru","16.4406","80.7379","India","IN","IND","Andhra Pradesh","","8648","1356108010"
"Chom Thong","Chom Thong","18.4901","98.6747","Thailand","TH","THA","Chiang Mai","minor","9777","1764003802"
"Rio Vista","Rio Vista","38.1765","-121.7025","United States","US","USA","California","","9850","1840020254"
"Independence","Independence","44.8547","-123.1952","United States","US","USA","Oregon","","9850","1840018589"
"Plüderhausen","Pluderhausen","48.7950","9.6011","Germany","DE","DEU","Baden-Württemberg","","9487","1276728589"
"Gold","Gold","33.5874","74.1445","India","IN","IND","Jammu and Kashmīr","","9453","1356139561"
"Morinville","Morinville","53.8022","-113.6497","Canada","CA","CAN","Alberta","","9848","1124322535"
"El Roble","El Roble","9.1000","-75.2000","Colombia","CO","COL","Sucre","minor","9786","1170150718"
"Wendell","Wendell","35.7819","-78.3952","United States","US","USA","North Carolina","","9847","1840016200"
"The Pinery","The Pinery","39.4462","-104.7591","United States","US","USA","Colorado","","9847","1840028583"
"Breuillet","Breuillet","48.5661","2.1714","France","FR","FRA","Île-de-France","","8566","1250177137"
"Drezdenko","Drezdenko","52.8333","15.8333","Poland","PL","POL","Lubuskie","","9800","1616180565"
"Kondayampālaiyam","Kondayampalaiyam","11.5130","77.4371","India","IN","IND","Tamil Nādu","","9224","1356168513"
"Artigues-près-Bordeaux","Artigues-pres-Bordeaux","44.8606","-0.4936","France","FR","FRA","Nouvelle-Aquitaine","","8656","1250115283"
"Virālimalai","Viralimalai","10.6023","78.5471","India","IN","IND","Tamil Nādu","","9482","1356973066"
"Badagabettu","Badagabettu","13.3335","74.7999","India","IN","IND","Karnātaka","","9220","1356894701"
"Çıldır","Cildir","41.1289","43.1328","Turkey","TR","TUR","Ardahan","minor","9833","1792346814"
"Saint-Barthélemy-d’Anjou","Saint-Barthelemy-d'Anjou","47.4675","-0.4950","France","FR","FRA","Pays de la Loire","","9210","1250748844"
"Gangūru","Ganguru","16.4833","80.7333","India","IN","IND","Andhra Pradesh","","8768","1356095742"
"Ekalbehri","Ekalbehri","21.8933","78.8794","India","IN","IND","Madhya Pradesh","","9550","1356131164"
"Radekhiv","Radekhiv","50.2828","24.6375","Ukraine","UA","UKR","Lvivska Oblast","","9800","1804200206"
"Stansbury Park","Stansbury Park","40.6356","-112.3054","United States","US","USA","Utah","","9839","1840037176"
"Veauche","Veauche","45.5619","4.2781","France","FR","FRA","Auvergne-Rhône-Alpes","","8976","1250180182"
"Trebbin","Trebbin","52.2167","13.2000","Germany","DE","DEU","Brandenburg","","9760","1276041588"
"Jennings","Jennings","30.2233","-92.6582","United States","US","USA","Louisiana","","9836","1840015040"
"Santa Bárbara","Santa Barbara","9.6000","-63.6000","Venezuela","VE","VEN","Monagas","minor","9809","1862125040"
"Panganiban","Panganiban","13.9000","124.3000","Philippines","PH","PHL","Catanduanes","","9713","1608983202"
"Roztoky","Roztoky","50.1585","14.3977","Czechia","CZ","CZE","Středočeský Kraj","","8783","1203472886"
"Nurobod Shahri","Nurobod Shahri","39.6086","66.2867","Uzbekistan","UZ","UZB","Samarqand","minor","9600","1860030602"
"Kannāl","Kannal","18.6938","79.4288","India","IN","IND","Andhra Pradesh","","9027","1356661175"
"Ban Nong Tong","Ban Nong Tong","18.6115","98.9466","Thailand","TH","THA","Chiang Mai","","9512","1764899658"
"Zorneding","Zorneding","48.0833","11.8333","Germany","DE","DEU","Bavaria","","9436","1276678708"
"Rāmasingavaram","Ramasingavaram","16.8863","81.1083","India","IN","IND","Andhra Pradesh","","9543","1356301304"
"Schlitz","Schlitz","50.6667","9.5667","Germany","DE","DEU","Hesse","","9764","1276405370"
"Lāila","Laila","12.9900","75.2700","India","IN","IND","Karnātaka","","9609","1356185789"
"Manchi","Manchi","12.9000","75.0300","India","IN","IND","Karnātaka","","9214","1356491335"
"Nykøbing Mors","Nykobing Mors","56.7953","8.8592","Denmark","DK","DNK","Nordjylland","minor","9135","1208071660"
"Włoszczowa","Wloszczowa","50.8542","19.9669","Poland","PL","POL","Świętokrzyskie","minor","9750","1616505680"
"Mallappādi","Mallappadi","12.5273","78.3756","India","IN","IND","Tamil Nādu","","9335","1356402391"
"San Sebastián","San Sebastian","1.8439","-76.7717","Colombia","CO","COL","Cauca","minor","9806","1170001774"
"Raun","Raun","26.3431","78.9443","India","IN","IND","Madhya Pradesh","","9525","1356330202"
"Socotá","Socota","6.0500","-72.6333","Colombia","CO","COL","Boyacá","minor","9812","1170884046"
"Bishunpur","Bishunpur","25.5665","87.4435","India","IN","IND","Bihār","","8712","1356370801"
"Pulivalam","Pulivalam","10.7525","79.6352","India","IN","IND","Tamil Nādu","","8698","1356175059"
"Rasht","Rasht","39.0167","70.3833","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","minor","9800","1762486281"
"Couzeix","Couzeix","45.8761","1.2386","France","FR","FRA","Nouvelle-Aquitaine","","9518","1250333045"
"St. Augustine Shores","St. Augustine Shores","29.8039","-81.3086","United States","US","USA","Florida","","9828","1840013972"
"Haţeg","Hateg","45.6075","22.9500","Romania","RO","ROU","Hunedoara","","9685","1642953184"
"Murājpur","Murajpur","25.9506","86.4713","India","IN","IND","Bihār","","8682","1356259530"
"Støvring","Stovring","56.8867","9.8286","Denmark","DK","DNK","Nordjylland","minor","9089","1208267923"
"Kirangūr","Kirangur","12.4316","76.6993","India","IN","IND","Karnātaka","","9354","1356299382"
"Ulipuram","Ulipuram","11.4667","78.4667","India","IN","IND","Tamil Nādu","","9435","1356257972"
"Suntar","Suntar","62.1575","117.6442","Russia","RU","RUS","Sakha (Yakutiya)","","9826","1643961858"
"Plains","Plains","41.2657","-75.8131","United States","US","USA","Pennsylvania","","9826","1840025529"
"Guia Lopes da Laguna","Guia Lopes da Laguna","-21.4578","-56.1139","Brazil","BR","BRA","Mato Grosso do Sul","","9824","1076661198"
"Ninheira","Ninheira","-15.3208","-41.7539","Brazil","BR","BRA","Minas Gerais","","9815","1076790896"
"Osterburg","Osterburg","52.7833","11.7667","Germany","DE","DEU","Saxony-Anhalt","","9782","1276131088"
"Perumbālai","Perumbalai","11.9635","77.9375","India","IN","IND","Tamil Nādu","","9579","1356048447"
"Imām Şāḩib","Imam Sahib","37.1844","68.9144","Afghanistan","AF","AFG","Kunduz","minor","9691","1004051505"
"Halls","Halls","36.0817","-83.9344","United States","US","USA","Tennessee","","9822","1840153102"
"Yazıkonak","Yazikonak","38.6167","39.3000","Turkey","TR","TUR","Elazığ","","9606","1792695742"
"Madhubani","Madhubani","26.3272","87.0160","India","IN","IND","Bihār","","8785","1356429675"
"East Stroudsburg","East Stroudsburg","41.0023","-75.1779","United States","US","USA","Pennsylvania","","9820","1840003507"
"Puraini","Puraini","26.0607","87.0331","India","IN","IND","Bihār","","8793","1356977114"
"Gökçeada","Gokceada","40.1608","25.8444","Turkey","TR","TUR","Çanakkale","minor","9783","1792337209"
"Oulunsalo","Oulunsalo","64.9333","25.4167","Finland","FI","FIN","Pohjois-Pohjanmaa","","9771","1246091710"
"Uppugunduru","Uppugunduru","15.6730","80.1670","India","IN","IND","Andhra Pradesh","","9060","1356852744"
"Oosterwolde","Oosterwolde","52.9903","6.2914","Netherlands","NL","NLD","Fryslân","minor","9510","1528950290"
"Alcanar","Alcanar","40.5430","0.4808","Spain","ES","ESP","Catalonia","","9612","1724553541"
"General Alvear","General Alvear","-36.0333","-60.0167","Argentina","AR","ARG","Buenos Aires","minor","9812","1032075005"
"Udburu","Udburu","23.0333","85.3667","India","IN","IND","Jhārkhand","","9539","1356183257"
"Berchha","Berchha","23.2823","76.3199","India","IN","IND","Madhya Pradesh","","9543","1356175073"
"Sairé","Saire","-8.3278","-35.7058","Brazil","BR","BRA","Pernambuco","","9764","1076702989"
"Triuggio","Triuggio","45.6667","9.2667","Italy","IT","ITA","Lombardy","","8763","1380952473"
"Inole","Inole","17.8682","79.5545","India","IN","IND","Andhra Pradesh","","9461","1356119522"
"Almusafes","Almusafes","39.2903","-0.4147","Spain","ES","ESP","Valencia","","8984","1724698693"
"Pizarra","Pizarra","36.7667","-4.7000","Spain","ES","ESP","Andalusia","","9661","1724076004"
"Parol","Parol","32.3460","75.4344","India","IN","IND","","","9339","1356440961"
"Dolianova","Dolianova","39.3833","9.1833","Italy","IT","ITA","Sardegna","","9696","1380728725"
"Mozzate","Mozzate","45.6833","8.9500","Italy","IT","ITA","Lombardy","","8971","1380014514"
"Eisenberg","Eisenberg","49.5614","8.0725","Germany","DE","DEU","Rhineland-Palatinate","","9313","1276928244"
"Masku","Masku","60.5708","22.1000","Finland","FI","FIN","Varsinais-Suomi","minor","9706","1246315542"
"Gorom-Gorom","Gorom-Gorom","14.4500","-0.2333","Burkina Faso","BF","BFA","Sahel","minor","9752","1854609702"
"Dattapāra","Dattapara","22.8491","88.9023","India","IN","IND","West Bengal","","8532","1356531302"
"Bādanahatti","Badanahatti","15.3176","76.8258","India","IN","IND","Karnātaka","","9209","1356506691"
"Dorogobuzh","Dorogobuzh","54.9167","33.3000","Russia","RU","RUS","Smolenskaya Oblast’","minor","9793","1643314720"
"Brockworth","Brockworth","51.8500","-2.1500","United Kingdom","GB","GBR","Gloucestershire","","9422","1826331782"
"Totma","Totma","59.9667","42.7500","Russia","RU","RUS","Vologodskaya Oblast’","","9805","1643361608"
"Cobham","Cobham","51.3290","-0.4090","United Kingdom","GB","GBR","Surrey","","9739","1826478182"
"T’q’ibuli","T'q'ibuli","42.3503","42.9983","Georgia","GE","GEO","Imereti","minor","9770","1268479108"
"Løgten","Logten","56.1643","10.1857","Denmark","DK","DNK","Midtjylland","","8659","1208851373"
"Brownsville","Brownsville","35.5890","-89.2578","United States","US","USA","Tennessee","","9807","1840013413"
"Calca","Calca","-13.3230","-71.9560","Peru","PE","PER","Cusco","","9732","1604144884"
"Thīkri","Thikri","22.0634","75.4007","India","IN","IND","Madhya Pradesh","","9544","1356565145"
"Sorbolo","Sorbolo","44.8463","10.4486","Italy","IT","ITA","Emilia-Romagna","","9563","1380308347"
"Everswinkel","Everswinkel","51.9250","7.8478","Germany","DE","DEU","North Rhine-Westphalia","","9666","1276832374"
"Chiang Klang","Chiang Klang","19.2930","100.8739","Thailand","TH","THA","Nan","minor","9721","1764084763"
"Grey Highlands","Grey Highlands","44.3333","-80.5000","Canada","CA","CAN","Ontario","","9804","1124000119"
"São Gonçalo do Rio Abaixo","Sao Goncalo do Rio Abaixo","-19.8258","-43.3619","Brazil","BR","BRA","Minas Gerais","","9777","1076000815"
"Ban Krot","Ban Krot","14.3121","100.6005","Thailand","TH","THA","Phra Nakhon Si Ayutthaya","","9471","1764143463"
"Jujhārpur","Jujharpur","25.7514","87.9634","India","IN","IND","West Bengal","","8665","1356155162"
"Tišnov","Tisnov","49.3487","16.4244","Czechia","CZ","CZE","Jihomoravský Kraj","","9267","1203595572"
"Vettweiß","Vettweiss","50.7389","6.5972","Germany","DE","DEU","North Rhine-Westphalia","","9685","1276176554"
"Aldona","Aldona","15.5800","73.8700","India","IN","IND","Goa","","8696","1356974060"
"Nerinjippettai","Nerinjippettai","11.6543","77.7586","India","IN","IND","Tamil Nādu","","9141","1356538969"
"Basse-Goulaine","Basse-Goulaine","47.2153","-1.4656","France","FR","FRA","Pays de la Loire","","9136","1250129133"
"Ban Nam Dip Luang","Ban Nam Dip Luang","18.4500","98.8333","Thailand","TH","THA","Lamphun","","9592","1764671694"
"Wepener","Wepener","-29.7333","27.0333","South Africa","ZA","ZAF","Free State","","9553","1710058517"
"Harbatpur","Harbatpur","30.4500","77.7300","India","IN","IND","Uttarākhand","","9242","1356707000"
"Constantina","Constantina","-27.7350","-52.9919","Brazil","BR","BRA","Rio Grande do Sul","","9752","1076797268"
"Guntramsdorf","Guntramsdorf","48.0483","16.3150","Austria","AT","AUT","Niederösterreich","","9179","1040104912"
"Piru","Piru","25.1009","84.5583","India","IN","IND","Bihār","","9261","1356101561"
"Heiligenhafen","Heiligenhafen","54.3739","10.9797","Germany","DE","DEU","Schleswig-Holstein","","9283","1276378464"
"Thap Khlo","Thap Khlo","16.1600","100.5967","Thailand","TH","THA","Phichit","minor","9676","1764391912"
"Barracão","Barracao","-26.2539","-53.6328","Brazil","BR","BRA","Paraná","","9735","1076190818"
"Yekāmbarakuppam","Yekambarakuppam","13.3168","79.5678","India","IN","IND","Andhra Pradesh","","8972","1356908847"
"Leeton","Leeton","-34.5667","146.4000","Australia","AU","AUS","New South Wales","","8623","1036102538"
"Tadla","Tadla","32.4409","-8.3708","Morocco","MA","MAR","Marrakech-Safi","","9750","1504540569"
"Adjarra","Adjarra","6.5333","2.2667","Benin","BJ","BEN","Atlantique","","8651","1204162479"
"Wiang Sa","Wiang Sa","8.6364","99.3683","Thailand","TH","THA","Surat Thani","minor","9587","1764748345"
"Marshall","Marshall","40.6453","-80.1100","United States","US","USA","Pennsylvania","","9790","1840146796"
"Biei","Biei","43.5883","142.4669","Japan","JP","JPN","Hokkaidō","","9775","1392568507"
"Chikkāla","Chikkala","16.9698","81.5922","India","IN","IND","Andhra Pradesh","","9185","1356135021"
"Jambukuttaippatti","Jambukuttaippatti","12.3246","78.3443","India","IN","IND","Tamil Nādu","","9209","1356887511"
"Bhogāpuram","Bhogapuram","18.0667","83.5000","India","IN","IND","Andhra Pradesh","","9341","1356681280"
"Tesalia","Tesalia","2.4833","-75.7333","Colombia","CO","COL","Huila","minor","9767","1170389636"
"Nyurba","Nyurba","63.2833","118.3333","Russia","RU","RUS","Sakha (Yakutiya)","","9786","1643555326"
"Marlboro Village","Marlboro Village","38.8307","-76.7699","United States","US","USA","Maryland","","9786","1840039492"
"Highfields","Highfields","-27.4633","151.9458","Australia","AU","AUS","Queensland","","9474","1036399510"
"Otočac","Otocac","44.8667","15.2333","Croatia","HR","HRV","Ličko-Senjska Županija","minor","9778","1191233258"
"Ostercappeln","Ostercappeln","52.3500","8.2333","Germany","DE","DEU","Lower Saxony","","9688","1276960639"
"Saidābād","Saidabad","25.5489","85.3087","India","IN","IND","Bihār","","8614","1356316093"
"Alto Piquiri","Alto Piquiri","-24.0278","-53.4408","Brazil","BR","BRA","Paraná","","9778","1076224592"
"Matelica","Matelica","43.2566","13.0096","Italy","IT","ITA","Marche","","9665","1380559495"
"Salmānshahr","Salmanshahr","36.7092","51.1992","Iran","IR","IRN","Māzandarān","","9656","1364942661"
"Honganur","Honganur","12.6044","77.2175","India","IN","IND","Karnātaka","","9296","1356655134"
"Irungalūr","Irungalur","10.9399","78.7619","India","IN","IND","Tamil Nādu","","9201","1356202676"
"Belalcázar","Belalcazar","5.0000","-75.8167","Colombia","CO","COL","Caldas","minor","9690","1170179028"
"Hueyotlipan","Hueyotlipan","18.9000","-97.8500","Mexico","MX","MEX","Puebla","minor","8511","1484584311"
"Neshannock","Neshannock","41.0509","-80.3520","United States","US","USA","Pennsylvania","","9782","1840152742"
"Eksjö","Eksjo","57.6669","14.9703","Sweden","SE","SWE","Jönköping","minor","9701","1752063761"
"Palomares del Río","Palomares del Rio","37.3167","-6.0500","Spain","ES","ESP","Andalusia","","9083","1724980685"
"Flero","Flero","45.4835","10.1745","Italy","IT","ITA","Lombardy","","8879","1380991865"
"La Ravoire","La Ravoire","45.5569","5.9664","France","FR","FRA","Auvergne-Rhône-Alpes","","8530","1250789160"
"Carnaubais","Carnaubais","-5.3408","-36.8328","Brazil","BR","BRA","Rio Grande do Norte","","9762","1076068941"
"Barbana","Barbana","26.0719","87.2539","India","IN","IND","Bihār","","8715","1356735822"
"Aklim","Aklim","34.9167","-2.4333","Morocco","MA","MAR","Oriental","","9695","1504687369"
"Suzdal","Suzdal","56.4211","40.4489","Russia","RU","RUS","Vladimirskaya Oblast’","","9749","1643926701"
"Polorós","Poloros","13.8108","-87.8164","El Salvador","SV","SLV","La Unión","","9701","1222036180"
"Kemberg","Kemberg","51.7833","12.6333","Germany","DE","DEU","Saxony-Anhalt","","9737","1276029857"
"Alfaro","Alfaro","42.1783","-1.7492","Spain","ES","ESP","La Rioja","","9727","1724727631"
"Eschenbach","Eschenbach","47.2709","8.9700","Switzerland","CH","CHE","Sankt Gallen","","9602","1756626480"
"Mae Rim","Mae Rim","18.9163","98.9605","Thailand","TH","THA","Chiang Mai","minor","9567","1764340535"
"Puerto Tirol","Puerto Tirol","-27.3667","-59.0667","Argentina","AR","ARG","Chaco","minor","9763","1032979467"
"Sohta","Sohta","26.2149","87.0826","India","IN","IND","Bihār","","8709","1356652469"
"Caturama","Caturama","-13.3289","-42.2908","Brazil","BR","BRA","Bahia","","9762","1076059211"
"Hostotipaquillo","Hostotipaquillo","21.0603","-104.0509","Mexico","MX","MEX","Jalisco","minor","9761","1484387476"
"Pettāmpālaiyam","Pettampalaiyam","11.3499","77.5706","India","IN","IND","Tamil Nādu","","9398","1356169104"
"Scionzier","Scionzier","46.0572","6.5500","France","FR","FRA","Auvergne-Rhône-Alpes","","8932","1250000402"
"Zāhed Shahr","Zahed Shahr","28.7450","53.8033","Iran","IR","IRN","Fārs","","9719","1364093059"
"Colmenarejo","Colmenarejo","40.5608","-4.0169","Spain","ES","ESP","Madrid","","9473","1724492902"
"Tweed Heads","Tweed Heads","-28.1833","153.5500","Australia","AU","AUS","New South Wales","","8176","1036571941"
"Hattula","Hattula","61.0556","24.3708","Finland","FI","FIN","Kanta-Häme","","9747","1246551746"
"Nordwalde","Nordwalde","52.0833","7.4833","Germany","DE","DEU","North Rhine-Westphalia","","9584","1276365254"
"Santo Tomás de los Plátanos","Santo Tomas de los Platanos","19.1817","-100.2589","Mexico","MX","MEX","México","minor","9682","1484531949"
"Mońki","Monki","53.4000","22.8167","Poland","PL","POL","Podlaskie","minor","9675","1616329542"
"Kāliganj","Kaliganj","23.7348","88.2293","India","IN","IND","West Bengal","","8575","1356538008"
"Concordia","Concordia","10.2667","-74.8333","Colombia","CO","COL","Magdalena","minor","9681","1170555051"
"Studénka","Studenka","49.7234","18.0786","Czechia","CZ","CZE","Moravskoslezský Kraj","","9466","1203000978"
"Çamoluk","Camoluk","40.1333","38.7333","Turkey","TR","TUR","Giresun","minor","9759","1792490056"
"Quiculungo","Quiculungo","-8.5167","15.3167","Angola","AO","AGO","Cuanza-Norte","","9732","1024301079"
"Sarpamāri","Sarpamari","26.3841","89.6891","India","IN","IND","Assam","","9415","1356111665"
"Basavilbaso","Basavilbaso","-32.3667","-58.8833","Argentina","AR","ARG","Entre Ríos","","9742","1032605549"
"Vardhamānkota","Vardhamankota","17.3775","79.4410","India","IN","IND","Andhra Pradesh","","9548","1356388291"
"Galten","Galten","56.1533","9.9069","Denmark","DK","DNK","Midtjylland","","9004","1208075108"
"Alcarraz","Alcarraz","41.5638","0.5241","Spain","ES","ESP","Catalonia","","9678","1724729662"
"Joigny","Joigny","47.9822","3.3972","France","FR","FRA","Bourgogne-Franche-Comté","","9557","1250663202"
"Būdanūr","Budanur","12.5500","76.9582","India","IN","IND","Karnātaka","","9155","1356040950"
"Nirna","Nirna","17.7700","77.1400","India","IN","IND","Karnātaka","","9415","1356783830"
"Gobindpur","Gobindpur","24.7820","85.6943","India","IN","IND","Jhārkhand","","8682","1356052099"
"Hacarí","Hacari","8.3167","-73.1500","Colombia","CO","COL","Norte de Santander","minor","9745","1170058977"
"Loria","Loria","45.7333","11.8667","Italy","IT","ITA","Veneto","","9358","1380846175"
"Ócsa","Ocsa","47.2934","19.2260","Hungary","HU","HUN","Pest","","9642","1348733489"
"Plainville","Plainville","42.0141","-71.3364","United States","US","USA","Massachusetts","","9760","1840053555"
"Lādhuka","Ladhuka","30.5082","74.0905","India","IN","IND","Punjab","","9342","1356081594"
"Podu Iloaiei","Podu Iloaiei","47.2167","27.2667","Romania","RO","ROU","Iaşi","","9573","1642361317"
"Villarrubia de los Ojos","Villarrubia de los Ojos","39.2167","-3.6000","Spain","ES","ESP","Castille-La Mancha","","9722","1724057885"
"Mesetas","Mesetas","3.3781","-74.0447","Colombia","CO","COL","Meta","minor","9751","1170008691"
"Wysokie Mazowieckie","Wysokie Mazowieckie","52.9192","22.5144","Poland","PL","POL","Podlaskie","minor","9155","1616361738"
"La Matanza de Acentejo","La Matanza de Acentejo","28.4403","-16.4389","Spain","ES","ESP","Canary Islands","","9114","1724158737"
"Grimmen","Grimmen","54.1100","13.0414","Germany","DE","DEU","Mecklenburg-Western Pomerania","","9572","1276798826"
"Picnic Point","Picnic Point","47.8744","-122.3077","United States","US","USA","Washington","","9755","1840037897"
"Sabangan","Sabangan","17.0044","120.9233","Philippines","PH","PHL","Mountain","","9621","1608583625"
"Wagner","Wagner","-12.2869","-41.1678","Brazil","BR","BRA","Bahia","","9731","1076156238"
"Washington","Washington","35.5586","-77.0545","United States","US","USA","North Carolina","","9754","1840015401"
"Fateh Nangal","Fateh Nangal","31.9453","75.3191","India","IN","IND","Punjab","","9154","1356102497"
"El Haouaria","El Haouaria","37.0500","11.0167","Tunisia","TN","TUN","Nabeul","","9629","1788314375"
"Cardeal da Silva","Cardeal da Silva","-11.9419","-37.9489","Brazil","BR","BRA","Bahia","","9747","1076285544"
"Altlandsberg","Altlandsberg","52.5667","13.7331","Germany","DE","DEU","Brandenburg","","9662","1276344426"
"Glencoe","Glencoe","-46.1833","168.6833","New Zealand","NZ","NZL","Southland","","9750","1554979133"
"Aghbalou Aqourar","Aghbalou Aqourar","33.9341","-4.7381","Morocco","MA","MAR","Fès-Meknès","","9715","1504995287"
"Sarkad","Sarkad","46.7500","21.3833","Hungary","HU","HUN","Békés","minor","9677","1348531339"
"San Fausto de Campcentellas","San Fausto de Campcentellas","41.5061","2.2400","Spain","ES","ESP","Catalonia","","9064","1724140929"
"Puerto Nariño","Puerto Narino","-3.7733","-70.3819","Colombia","CO","COL","Amazonas","minor","9744","1170109460"
"Oberstdorf","Oberstdorf","47.4097","10.2792","Germany","DE","DEU","Bavaria","","9707","1276143452"
"Nueva Esparta","Nueva Esparta","13.7833","-87.8333","El Salvador","SV","SLV","La Unión","","9637","1222916015"
"Sidi Ahmed El Khadir","Sidi Ahmed El Khadir","32.5167","-7.3833","Morocco","MA","MAR","Casablanca-Settat","","9687","1504732408"
"Argostóli","Argostoli","38.1739","20.4883","Greece","GR","GRC","Ionía Nísia","minor","9748","1300532537"
"Saint-Vith","Saint-Vith","50.2833","6.1333","Belgium","BE","BEL","Wallonia","","9682","1056135574"
"Dushanovë","Dushanove","42.2347","20.7091","Kosovo","XK","XKS","Prizren","","9398","1901150015"
"Bijeraghogarh","Bijeraghogarh","23.9955","80.6066","India","IN","IND","Madhya Pradesh","","9449","1356705903"
"Tazarka","Tazarka","36.5500","10.8333","Tunisia","TN","TUN","Nabeul","","9388","1788779433"
"Felpham","Felpham","50.7905","-0.6539","United Kingdom","GB","GBR","West Sussex","","9746","1826012790"
"Douar Lehouifrat","Douar Lehouifrat","32.2800","-8.3100","Morocco","MA","MAR","Marrakech-Safi","","9705","1504757840"
"Schmitten","Schmitten","50.2697","8.4443","Germany","DE","DEU","Hesse","","9478","1276120658"
"Nashtīfān","Nashtifan","34.4344","60.1775","Iran","IR","IRN","Khorāsān-e Raẕavī","","9730","1364386254"
"Senmanat","Senmanat","41.6086","2.1358","Spain","ES","ESP","Catalonia","","9417","1724417480"
"San Juan del Puerto","San Juan del Puerto","37.3167","-6.8333","Spain","ES","ESP","Andalusia","","9532","1724625645"
"Fürstenau","Furstenau","52.5167","7.6667","Germany","DE","DEU","Lower Saxony","","9621","1276365707"
"Eidson Road","Eidson Road","28.6677","-100.4788","United States","US","USA","Texas","","9743","1840037044"
"Sint Willebrord","Sint Willebrord","51.5503","4.5917","Netherlands","NL","NLD","Noord-Brabant","","9390","1528360139"
"Léguevin","Leguevin","43.5989","1.2331","France","FR","FRA","Occitanie","","9359","1250110389"
"Kukraun","Kukraun","25.7647","87.1847","India","IN","IND","Bihār","","8818","1356674432"
"Ussel","Ussel","45.5481","2.3092","France","FR","FRA","Nouvelle-Aquitaine","minor","9555","1250254773"
"Tepe-Korgon","Tepe-Korgon","40.6000","72.4000","Kyrgyzstan","KG","KGZ","Osh","","9186","1417409618"
"Irigny","Irigny","45.6731","4.8225","France","FR","FRA","Auvergne-Rhône-Alpes","","8750","1250681463"
"Sivamalai","Sivamalai","11.0319","77.5384","India","IN","IND","Tamil Nādu","","9418","1356164580"
"Somvārpet","Somvarpet","12.5970","75.8496","India","IN","IND","Karnātaka","","9530","1356143374"
"College Place","College Place","46.0419","-118.3878","United States","US","USA","Washington","","9739","1840018490"
"San Pedro","San Pedro","-33.8944","-71.4558","Chile","CL","CHL","Región Metropolitana","","9726","1152749736"
"Unāo","Unao","25.5784","78.6006","India","IN","IND","Uttar Pradesh","","9320","1356915497"
"Vesele","Vesele","47.0189","34.9212","Ukraine","UA","UKR","Zaporizka Oblast","minor","9719","1804322503"
"Fuli","Fuli","23.1333","121.2833","Taiwan","TW","TWN","Hualien","","9681","1158634303"
"Nakao","Nakao","35.3307","139.2188","Japan","JP","JPN","Kanagawa","","9272","1392084974"
"Sevilla La Nueva","Sevilla La Nueva","40.3475","-4.0286","Spain","ES","ESP","Madrid","","9361","1724114495"
"Gorgāb","Gorgab","32.8658","51.5972","Iran","IR","IRN","Eşfahān","","9690","1364220852"
"Dāmargidda","Damargidda","16.8189","77.5031","India","IN","IND","Andhra Pradesh","","9197","1356865520"
"Aleksandrov Gay","Aleksandrov Gay","50.1333","48.5500","Russia","RU","RUS","Saratovskaya Oblast’","","9728","1643709614"
"Pingree Grove","Pingree Grove","42.0855","-88.4358","United States","US","USA","Illinois","","9734","1840011352"
"Corella","Corella","9.6833","123.9167","Philippines","PH","PHL","Bohol","minor","9479","1608691384"
"Punta del Este","Punta del Este","-34.9667","-54.9500","Uruguay","UY","URY","Maldonado","","9277","1858267433"
"Voitsberg","Voitsberg","47.0483","15.1503","Austria","AT","AUT","Steiermark","minor","9403","1040639919"
"Valsequillo de Gran Canaria","Valsequillo de Gran Canaria","27.9808","-15.4989","Spain","ES","ESP","Canary Islands","","9490","1724192728"
"Yalagüina","Yalaguina","13.4858","-86.4917","Nicaragua","NI","NIC","Madriz","minor","9597","1558031609"
"Zafferana Etnea","Zafferana Etnea","37.6833","15.1000","Italy","IT","ITA","Sicilia","","9607","1380481155"
"Tuta","Tuta","5.7000","-73.2333","Colombia","CO","COL","Boyacá","minor","9673","1170818814"
"Kadiyadda","Kadiyadda","16.8774","81.5149","India","IN","IND","Andhra Pradesh","","8850","1356333354"
"Pathrāha","Pathraha","26.4330","87.0743","India","IN","IND","Bihār","","8664","1356002589"
"Sekha","Sekha","30.3586","75.6440","India","IN","IND","Punjab","","9218","1356052793"
"Nolinsk","Nolinsk","57.5667","49.9333","Russia","RU","RUS","Kirovskaya Oblast’","","9722","1643468029"
"Ayas","Ayas","24.2508","87.7784","India","IN","IND","West Bengal","","8906","1356656921"
"Sunbury","Sunbury","40.8616","-76.7871","United States","US","USA","Pennsylvania","","9729","1840000851"
"Rājapūdi","Rajapudi","17.1830","82.0500","India","IN","IND","Andhra Pradesh","","9267","1356102630"
"Kātūria","Katuria","24.7476","86.7205","India","IN","IND","Bihār","","9336","1356471673"
"Huari","Huari","-9.3689","-77.2369","Peru","PE","PER","Ancash","","9700","1604048812"
"Monmouth Junction","Monmouth Junction","40.3754","-74.5582","United States","US","USA","New Jersey","","9728","1840005415"
"Loreto","Loreto","10.3586","125.5816","Philippines","PH","PHL","Dinagat Islands","","9690","1608087453"
"Harran","Harran","36.8708","39.0250","Turkey","TR","TUR","Şanlıurfa","minor","9653","1792829977"
"Nhân Trạch","Nhan Trach","19.0500","105.5500","Vietnam","VN","VNM","Nghệ An","","9028","1704211423"
"Tremonton","Tremonton","41.7187","-112.1890","United States","US","USA","Utah","","9727","1840021326"
"Garrison","Garrison","39.4023","-76.7514","United States","US","USA","Maryland","","9727","1840005691"
"Chandwārā","Chandwara","24.3930","85.4798","India","IN","IND","Jhārkhand","","9341","1356206597"
"Middleton","Middleton","42.6043","-71.0164","United States","US","USA","Massachusetts","","9726","1840053534"
"Alsbach-Hähnlein","Alsbach-Hahnlein","49.7413","8.6225","Germany","DE","DEU","Hesse","","9146","1276622371"
"Urangānpatti","Uranganpatti","9.9984","78.4297","India","IN","IND","Tamil Nādu","","9298","1356217336"
"Saint-Sulpice-la-Pointe","Saint-Sulpice-la-Pointe","43.7742","1.6864","France","FR","FRA","Occitanie","","9336","1250403331"
"Golbey","Golbey","48.1958","6.4372","France","FR","FRA","Grand Est","","8798","1250014779"
"Avon Park","Avon Park","27.5898","-81.5068","United States","US","USA","Florida","","9725","1840014166"
"Loudéac","Loudeac","48.1778","-2.7533","France","FR","FRA","Bretagne","","9605","1250528163"
"Majra","Majra","25.7131","87.3637","India","IN","IND","Bihār","","8730","1356734218"
"Sedona","Sedona","34.8581","-111.7941","United States","US","USA","Arizona","","9723","1840021584"
"Kommūru","Kommuru","16.0667","80.3667","India","IN","IND","Andhra Pradesh","","9444","1356214076"
"Idanha-a-Nova","Idanha-a-Nova","39.9167","-7.2333","Portugal","PT","PRT","Castelo Branco","minor","9716","1620152186"
"Halen","Halen","50.9481","5.1144","Belgium","BE","BEL","Flanders","","9461","1056456739"
"Paso Canoas","Paso Canoas","8.5333","-82.8333","Panama","PA","PAN","Chiriquí","","9543","1591880725"
"Spanish Fort","Spanish Fort","30.7254","-87.8597","United States","US","USA","Alabama","","9720","1840015879"
"Kottūr","Kottur","9.9038","77.4071","India","IN","IND","Tamil Nādu","","8679","1356242685"
"Shchuchye","Shchuchye","55.2167","62.7500","Russia","RU","RUS","Kurganskaya Oblast’","","9711","1643139853"
"Akabira","Akabira","43.5581","142.0442","Japan","JP","JPN","Hokkaidō","","9644","1392691701"
"Liteni","Liteni","47.5200","26.5319","Romania","RO","ROU","Suceava","","9596","1642459185"
"Imías","Imias","20.0767","-74.6519","Cuba","CU","CUB","Guantánamo","minor","9699","1192551385"
"Detroit Lakes","Detroit Lakes","46.8060","-95.8449","United States","US","USA","Minnesota","","9717","1840006632"
"Khorāgāchhi","Khoragachhi","26.3344","87.5411","India","IN","IND","Bihār","","8919","1356182138"
"Bni Boufrah","Bni Boufrah","35.1000","-4.3167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9653","1504305838"
"Nattakkādaiyūr","Nattakkadaiyur","11.0882","77.6637","India","IN","IND","Tamil Nādu","","9337","1356165327"
"Kasāp","Kasap","25.4702","84.6087","India","IN","IND","Bihār","","8525","1356048820"
"Pāppampatti","Pappampatti","10.4446","77.4048","India","IN","IND","Tamil Nādu","","9265","1356906205"
"Beckett Ridge","Beckett Ridge","39.3448","-84.4380","United States","US","USA","Ohio","","9716","1840034377"
"Sweet Home","Sweet Home","44.4023","-122.7028","United States","US","USA","Oregon","","9716","1840021253"
"Khem Karan Saray","Khem Karan Saray","25.1233","84.8117","India","IN","IND","Bihār","","8875","1356473041"
"Ghorbanki","Ghorbanki","26.5697","86.0484","India","IN","IND","Bihār","","8869","1356255864"
"Wehrheim","Wehrheim","50.3033","8.5710","Germany","DE","DEU","Hesse","","9468","1276437939"
"Arenys de Munt","Arenys de Munt","41.6128","2.5403","Spain","ES","ESP","Catalonia","","9278","1724256334"
"Chełmek","Chelmek","50.1167","19.2500","Poland","PL","POL","Małopolskie","","8669","1616126539"
"Lüchow","Luchow","52.9667","11.1500","Germany","DE","DEU","Lower Saxony","minor","9604","1276197749"
"Nové Město nad Metují","Nove Mesto nad Metuji","50.3446","16.1515","Czechia","CZ","CZE","Královéhradecký Kraj","","9317","1203259477"
"Bardmoor","Bardmoor","27.8574","-82.7534","United States","US","USA","Florida","","9711","1840038841"
"Berlin","Berlin","44.4869","-71.2599","United States","US","USA","New Hampshire","","9710","1840002100"
"Makaha","Makaha","21.4739","-158.2102","United States","US","USA","Hawaii","","9710","1840029523"
"White Horse","White Horse","40.1919","-74.7023","United States","US","USA","New Jersey","","9710","1840005475"
"Bisingen","Bisingen","48.3119","8.9178","Germany","DE","DEU","Baden-Württemberg","","9423","1276249860"
"Ehningen","Ehningen","48.6589","8.9403","Germany","DE","DEU","Baden-Württemberg","","9193","1276629801"
"El Espinar","El Espinar","40.7186","-4.2478","Spain","ES","ESP","Castille-Leon","","9662","1724085295"
"San Marzano di San Giuseppe","San Marzano di San Giuseppe","40.4500","17.5000","Italy","IT","ITA","Puglia","","9228","1380017701"
"Bohechío","Bohechio","18.7700","-70.9800","Dominican Republic","DO","DOM","El Valle","","9685","1214699842"
"Mentone","Mentone","34.0609","-117.1108","United States","US","USA","California","","9708","1840019109"
"Fort Salonga","Fort Salonga","40.9068","-73.2996","United States","US","USA","New York","","9708","1840005013"
"Sarauni","Sarauni","24.8613","85.8712","India","IN","IND","Bihār","","9061","1356231195"
"Fultondale","Fultondale","33.6174","-86.8014","United States","US","USA","Alabama","","9707","1840002481"
"Helena-West Helena","Helena-West Helena","34.5313","-90.6201","United States","US","USA","Arkansas","","9707","1840027858"
"Timmapuram","Timmapuram","17.1036","81.0781","India","IN","IND","Andhra Pradesh","","9516","1356174693"
"Muppālla","Muppalla","16.3198","80.0927","India","IN","IND","Andhra Pradesh","","9362","1356804176"
"Nowy Dwór Gdański","Nowy Dwor Gdanski","54.2167","19.1167","Poland","PL","POL","Pomorskie","minor","9618","1616276242"
"Stratford","Stratford","46.2167","-63.0893","Canada","CA","CAN","Prince Edward Island","","9706","1124001331"
"Lavis","Lavis","46.1333","11.1167","Italy","IT","ITA","Trentino-Alto Adige","","8969","1380719126"
"Ferros","Ferros","-19.2319","-43.0228","Brazil","BR","BRA","Minas Gerais","","9696","1076000745"
"Alamosa","Alamosa","37.4752","-105.8770","United States","US","USA","Colorado","","9704","1840018921"
"Foiano della Chiana","Foiano della Chiana","43.2500","11.8167","Italy","IT","ITA","Tuscany","","9470","1380908085"
"Khajuri","Khajuri","26.5216","84.3391","India","IN","IND","Bihār","","8527","1356382270"
"Xinpi","Xinpi","22.4880","120.5814","Taiwan","TW","TWN","Pingtung","","9540","1158537415"
"Māmā Khēl","Mama Khel","34.2500","70.0100","Afghanistan","AF","AFG","Nangarhār","minor","9536","1004178793"
"Akhuryan","Akhuryan","40.7814","43.8964","Armenia","AM","ARM","Shirak","","9668","1051730865"
"Cavriglia","Cavriglia","43.5167","11.4833","Italy","IT","ITA","Tuscany","","9543","1380422020"
"Maserada sul Piave","Maserada sul Piave","45.7500","12.3167","Italy","IT","ITA","Veneto","","9374","1380335265"
"Bluefield","Bluefield","37.2608","-81.2143","United States","US","USA","West Virginia","","9699","1840006401"
"Awans","Awans","50.6669","5.4633","Belgium","BE","BEL","Wallonia","","9353","1056534657"
"Barjhar","Barjhar","22.6012","74.2471","India","IN","IND","Madhya Pradesh","","9370","1356176667"
"Coweta","Coweta","35.9683","-95.6545","United States","US","USA","Oklahoma","","9696","1840019077"
"Fallston","Fallston","39.5332","-76.4452","United States","US","USA","Maryland","","9696","1840005662"
"Gokhulāpur","Gokhulapur","26.2479","87.1137","India","IN","IND","Bihār","","8628","1356020304"
"Murrells Inlet","Murrells Inlet","33.5560","-79.0594","United States","US","USA","South Carolina","","9695","1840013742"
"Silver City","Silver City","32.7783","-108.2699","United States","US","USA","New Mexico","","9694","1840023009"
"Alayor","Alayor","39.9342","4.1400","Spain","ES","ESP","Balearic Islands","","9606","1724740843"
"Cervelló","Cervello","41.3962","1.9589","Spain","ES","ESP","Catalonia","","9307","1724026410"
"Dobre Miasto","Dobre Miasto","53.9875","20.3958","Poland","PL","POL","Warmińsko-Mazurskie","","9629","1616619953"
"Valerik","Valerik","43.1797","45.4081","Russia","RU","RUS","Chechnya","","9584","1643829166"
"Kola","Kola","68.8833","33.0833","Russia","RU","RUS","Murmanskaya Oblast’","","9691","1643826849"
"Ulaş","Ulas","39.2725","36.7511","Turkey","TR","TUR","Sivas","minor","9682","1792065344"
"Uppinangadi","Uppinangadi","12.7700","75.2200","India","IN","IND","Karnātaka","","9467","1356871048"
"Solsona","Solsona","41.9944","1.5178","Spain","ES","ESP","Catalonia","","9175","1724790953"
"Kharī","Khari","33.3890","75.1514","India","IN","IND","Jammu and Kashmīr","","9414","1356026883"
"Hohenmölsen","Hohenmolsen","51.1564","12.0981","Germany","DE","DEU","Saxony-Anhalt","","9565","1276691866"
"Visselhövede","Visselhovede","52.9667","9.5833","Germany","DE","DEU","Lower Saxony","","9629","1276207705"
"Lindesberg","Lindesberg","59.5833","15.2500","Sweden","SE","SWE","Örebro","minor","9672","1752750854"
"Dassel","Dassel","51.8033","9.6903","Germany","DE","DEU","Lower Saxony","","9604","1276866794"
"Grado","Grado","43.3881","-6.0736","Spain","ES","ESP","Asturias","","9644","1724381438"
"Castelfranco di Sopra","Castelfranco di Sopra","43.6236","11.5583","Italy","IT","ITA","Tuscany","","9516","1380601175"
"Sellersburg","Sellersburg","38.4028","-85.7706","United States","US","USA","Indiana","","9686","1840010786"
"Pechory","Pechory","57.8167","27.6167","Russia","RU","RUS","Pskovskaya Oblast’","","9670","1643575087"
"Kuchai Kot","Kuchai Kot","26.5559","84.3292","India","IN","IND","Bihār","","8510","1356475129"
"Oil City","Oil City","41.4281","-79.7036","United States","US","USA","Pennsylvania","","9685","1840003426"
"Gopālpur","Gopalpur","19.2586","84.9052","India","IN","IND","Odisha","","8795","1356610209"
"Bāgchīni","Bagchini","26.4817","77.8185","India","IN","IND","Madhya Pradesh","","9357","1356421111"
"Salcea","Salcea","47.6500","26.3700","Romania","RO","ROU","Suceava","","9513","1642120043"
"Cébazat","Cebazat","45.8314","3.1000","France","FR","FRA","Auvergne-Rhône-Alpes","","8805","1250938793"
"Tlagasana","Tlagasana","-7.1786","109.5057","Indonesia","ID","IDN","Jawa Tengah","","8786","1360739732"
"Elne","Elne","42.6003","2.9711","France","FR","FRA","Occitanie","","9248","1250848284"
"Castelleone","Castelleone","45.2958","9.7609","Italy","IT","ITA","Lombardy","","9472","1380741213"
"Neu Bleckede","Neu Bleckede","53.3000","10.7333","Germany","DE","DEU","Lower Saxony","","9613","1276752770"
"Laubach","Laubach","50.5333","8.9900","Germany","DE","DEU","Hesse","","9583","1276831355"
"Sowān","Sowan","25.5540","84.2334","India","IN","IND","Bihār","","8785","1356734331"
"Jawāsa","Jawasa","26.6259","78.7418","India","IN","IND","Madhya Pradesh","","9416","1356986879"
"Sémbé","Sembe","1.6481","14.5806","Congo (Brazzaville)","CG","COG","Sangha","","9679","1178118997"
"Alfred and Plantagenet","Alfred and Plantagenet","45.5667","-74.9167","Canada","CA","CAN","Ontario","","9680","1124001813"
"Filadelfia","Filadelfia","5.3000","-75.6000","Colombia","CO","COL","Caldas","minor","9630","1170090455"
"Niederwerrn","Niederwerrn","50.0667","10.1831","Germany","DE","DEU","Bavaria","","8781","1276785718"
"Indianola","Indianola","33.4492","-90.6447","United States","US","USA","Mississippi","","9679","1840014774"
"Aranya Kalān","Aranya Kalan","23.2448","76.7435","India","IN","IND","Madhya Pradesh","","9236","1356667099"
"Cholavaram","Cholavaram","13.2389","80.1627","India","IN","IND","Tamil Nādu","","8923","1356762417"
"Ilsenburg","Ilsenburg","51.8667","10.6833","Germany","DE","DEU","Saxony-Anhalt","","9526","1276193551"
"Tsrār Sharīf","Tsrar Sharif","33.8632","74.7652","India","IN","IND","Jammu and Kashmīr","","8727","1356039784"
"Farsund","Farsund","58.0947","6.8044","Norway","NO","NOR","Agder","minor","9645","1578528519"
"Morrisville","Morrisville","40.2074","-74.7800","United States","US","USA","Pennsylvania","","9675","1840001326"
"Rāsol","Rasol","20.6298","85.3126","India","IN","IND","Odisha","","9427","1356654550"
"Vempatti","Vempatti","17.4488","79.5992","India","IN","IND","Andhra Pradesh","","9387","1356006195"
"Monte Porzio Catone","Monte Porzio Catone","41.8167","12.7167","Italy","IT","ITA","Lazio","","8718","1380672014"
"Segarai","Segarai","10.7300","79.5111","India","IN","IND","Tamil Nādu","","8908","1356190406"
"Porto Tolle","Porto Tolle","44.9500","12.3167","Italy","IT","ITA","Veneto","","9663","1380225713"
"Korwār","Korwar","16.9200","76.2400","India","IN","IND","Karnātaka","","9443","1356887057"
"Tzitzio","Tzitzio","19.4449","-100.9085","Mexico","MX","MEX","Michoacán","minor","8966","1484231858"
"Algūn","Algun","31.2795","74.6517","India","IN","IND","Punjab","","9250","1356212811"
"Kalvārpatti","Kalvarpatti","10.6250","77.9375","India","IN","IND","Tamil Nādu","","9320","1356215228"
"Mādepalli","Madepalli","12.5491","78.3400","India","IN","IND","Tamil Nādu","","9177","1356040409"
"Attnang-Puchheim","Attnang-Puchheim","48.0167","13.7167","Austria","AT","AUT","Oberösterreich","","8944","1040023749"
"La Victoria de Acentejo","La Victoria de Acentejo","28.4348","-16.4682","Spain","ES","ESP","Canary Islands","","9170","1724943642"
"Tandarāmpattu","Tandarampattu","12.1533","78.9473","India","IN","IND","Tamil Nādu","","9366","1356143548"
"Khodoriv","Khodoriv","49.4100","24.3094","Ukraine","UA","UKR","Lvivska Oblast","","9605","1804664914"
"Südlohn","Sudlohn","51.9436","6.8664","Germany","DE","DEU","North Rhine-Westphalia","","9461","1276001303"
"Andiyappanūr","Andiyappanur","12.5342","78.7024","India","IN","IND","Tamil Nādu","","9078","1356978385"
"Luís Gomes","Luis Gomes","-6.4139","-38.3889","Brazil","BR","BRA","Rio Grande do Norte","","9610","1076347577"
"Ấp Tân Ngãi","Ap Tan Ngai","10.2369","106.2878","Vietnam","VN","VNM","Bến Tre","","8887","1704372907"
"Country Club","Country Club","37.9687","-121.3408","United States","US","USA","California","","9667","1840028364"
"Trzebiatów","Trzebiatow","54.0625","15.2656","Poland","PL","POL","Zachodniopomorskie","","9591","1616792960"
"Krasnogvardeyskoye","Krasnogvardeyskoye","45.1278","39.5725","Russia","RU","RUS","Adygeya","minor","9617","1643716525"
"Thomaston","Thomaston","32.8907","-84.3272","United States","US","USA","Georgia","","9665","1840015776"
"Anjēhalli","Anjehalli","12.1395","77.9808","India","IN","IND","Tamil Nādu","","9420","1356853761"
"Kadanādu","Kadanadu","11.4607","76.7182","India","IN","IND","Tamil Nādu","","9447","1356014940"
"Cuéllar","Cuellar","41.4009","-4.3136","Spain","ES","ESP","Castille-Leon","","9620","1724802615"
"Santa Monica","Santa Monica","10.0200","126.0380","Philippines","PH","PHL","Surigao del Norte","","9423","1608035680"
"Douar Messassa","Douar Messassa","34.2803","-4.5336","Morocco","MA","MAR","Fès-Meknès","","9501","1504026758"
"White Marsh","White Marsh","39.3819","-76.4574","United States","US","USA","Maryland","","9663","1840005696"
"Rangāpuram","Rangapuram","15.4092","78.0746","India","IN","IND","Andhra Pradesh","","9375","1356146649"
"Tālakulam","Talakulam","8.1824","77.3161","India","IN","IND","Tamil Nādu","","8535","1356217034"
"Barpathār","Barpathar","26.2871","93.8884","India","IN","IND","Assam","","9344","1356132816"
"Armutlu","Armutlu","40.5194","28.8281","Turkey","TR","TUR","Yalova","minor","9625","1792285535"
"Olivares","Olivares","37.4167","-6.1500","Spain","ES","ESP","Andalusia","","9452","1724113808"
"Rockcreek","Rockcreek","45.5526","-122.8760","United States","US","USA","Oregon","","9659","1840034833"
"Kunnattūr","Kunnattur","11.2727","77.4107","India","IN","IND","Tamil Nādu","","9282","1356572788"
"Adelsdorf","Adelsdorf","49.7112","10.8935","Germany","DE","DEU","Bavaria","","9362","1276049542"
"Agareb","Agareb","34.7414","10.5280","Tunisia","TN","TUN","Sfax","","9610","1788531195"
"Kovilpatti","Kovilpatti","10.0283","77.5809","India","IN","IND","Tamil Nādu","","9407","1356226934"
"Lincoln City","Lincoln City","44.9751","-124.0072","United States","US","USA","Oregon","","9655","1840019974"
"Babhangaon","Babhangaon","25.7897","86.9767","India","IN","IND","Bihār","","8569","1356414402"
"Gamail","Gamail","25.7062","86.9914","India","IN","IND","Bihār","","8569","1356708272"
"Hârşova","Harsova","44.6833","27.9519","Romania","RO","ROU","Constanţa","","9642","1642407029"
"Fairfield","Fairfield","41.0064","-91.9667","United States","US","USA","Iowa","","9653","1840000887"
"Kankanālapalle","Kankanalapalle","15.9923","79.4848","India","IN","IND","Andhra Pradesh","","9438","1356869127"
"Parsons","Parsons","37.3405","-95.2958","United States","US","USA","Kansas","","9652","1840001712"
"Besozzo","Besozzo","45.8500","8.6667","Italy","IT","ITA","Lombardy","","9005","1380913044"
"Fauske","Fauske","67.2594","15.3933","Norway","NO","NOR","Nordland","minor","9640","1578904094"
"Prudente de Morais","Prudente de Morais","-19.4819","-44.1550","Brazil","BR","BRA","Minas Gerais","","9573","1076071573"
"Oulad Imloul","Oulad Imloul","32.0333","-7.7167","Morocco","MA","MAR","Marrakech-Safi","","9614","1504403330"
"Santa Ana","Santa Ana","9.2444","-64.6353","Venezuela","VE","VEN","Anzoátegui","minor","9636","1862862698"
"Tirúa","Tirua","-38.3414","-73.4911","Chile","CL","CHL","Biobío","","9644","1152214241"
"Lake Arrowhead","Lake Arrowhead","34.2531","-117.1944","United States","US","USA","California","","9647","1840017803"
"Pinhel","Pinhel","40.7833","-7.0667","Portugal","PT","PRT","Guarda","minor","9627","1620892355"
"Brand-Erbisdorf","Brand-Erbisdorf","50.8689","13.3219","Germany","DE","DEU","Saxony","","9452","1276212051"
"Traversetolo","Traversetolo","44.6399","10.3818","Italy","IT","ITA","Emilia-Romagna","","9474","1380746065"
"Maserà di Padova","Masera di Padova","45.3167","11.8667","Italy","IT","ITA","Veneto","","9127","1380237526"
"Hockley","Hockley","51.6014","0.6363","United Kingdom","GB","GBR","Essex","","9616","1826444113"
"Mont-Tremblant","Mont-Tremblant","46.1167","-74.6000","Canada","CA","CAN","Quebec","","9646","1124041173"
"Roncador","Roncador","-24.6028","-52.2750","Brazil","BR","BRA","Paraná","","9645","1076051192"
"Jagta","Jagta","26.1321","87.1911","India","IN","IND","Bihār","","8582","1356064701"
"Imielin","Imielin","50.1333","19.2167","Poland","PL","POL","Śląskie","","9313","1616783615"
"Mortágua","Mortagua","40.4333","-8.2333","Portugal","PT","PRT","Viseu","minor","9607","1620677707"
"Martensville","Martensville","52.2897","-106.6667","Canada","CA","CAN","Saskatchewan","","9645","1124000654"
"Sidi Bou Othmane","Sidi Bou Othmane","31.9033","-7.9422","Morocco","MA","MAR","Marrakech-Safi","","9181","1504562796"
"Kākarāti","Kakarati","24.5639","80.2659","India","IN","IND","Madhya Pradesh","","9377","1356202438"
"Durgi","Durgi","16.4242","79.4928","India","IN","IND","Andhra Pradesh","","9480","1356099514"
"Twist","Twist","52.6167","7.0333","Germany","DE","DEU","Lower Saxony","","9554","1276686407"
"Karuveppampatti","Karuveppampatti","11.3983","77.8746","India","IN","IND","Tamil Nādu","","8733","1356166740"
"Barleben","Barleben","52.2000","11.6333","Germany","DE","DEU","Saxony-Anhalt","","9330","1276521807"
"Kaglipur","Kaglipur","12.8006","77.5082","India","IN","IND","Karnātaka","","9117","1356636951"
"Canonsburg","Canonsburg","40.2643","-80.1867","United States","US","USA","Pennsylvania","","9643","1840003643"
"Runkel","Runkel","50.4053","8.1550","Germany","DE","DEU","Hesse","","9427","1276349515"
"Himmatpura","Himmatpura","30.5271","75.3627","India","IN","IND","Punjab","","9183","1356465663"
"Szubin","Szubin","53.0167","17.7500","Poland","PL","POL","Kujawsko-Pomorskie","","9563","1616354181"
"Eemnes","Eemnes","52.2500","5.2500","Netherlands","NL","NLD","Utrecht","minor","9362","1528049688"
"Zwiesel","Zwiesel","49.0167","13.2333","Germany","DE","DEU","Bavaria","","9421","1276620797"
"Stary Sącz","Stary Sacz","49.5625","20.6364","Poland","PL","POL","Małopolskie","","9056","1616399913"
"Pleasant Grove","Pleasant Grove","33.4940","-86.9782","United States","US","USA","Alabama","","9638","1840014793"
"Zadonsk","Zadonsk","52.4000","38.9333","Russia","RU","RUS","Lipetskaya Oblast’","","9614","1643560956"
"Chokkalingapuram","Chokkalingapuram","10.1930","78.3973","India","IN","IND","Tamil Nādu","","9209","1356208115"
"Striano","Striano","40.8167","14.5667","Italy","IT","ITA","Campania","","8522","1380162594"
"Morières-lès-Avignon","Morieres-les-Avignon","43.9417","4.9047","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8786","1250645579"
"Ban San Pong","Ban San Pong","18.9424","98.9439","Thailand","TH","THA","Chiang Mai","","9425","1764920268"
"Landivisiau","Landivisiau","48.5092","-4.0683","France","FR","FRA","Bretagne","","9151","1250404216"
"Onda","Onda","25.2452","85.6650","India","IN","IND","Bihār","","8651","1356073746"
"Izium","Izium","49.1958","37.2803","Ukraine","UA","UKR","Kharkivska Oblast","minor","9609","1804385952"
"Uracoa","Uracoa","8.9129","-62.3508","Venezuela","VE","VEN","Monagas","minor","9625","1862113376"
"Decatur","Decatur","40.8286","-84.9277","United States","US","USA","Indiana","","9630","1840007177"
"Lowell","Lowell","36.2561","-94.1532","United States","US","USA","Arkansas","","9630","1840015285"
"Clay Cross","Clay Cross","53.1637","-1.4128","United Kingdom","GB","GBR","Derbyshire","","9222","1826222339"
"Elhovo","Elhovo","42.1667","26.5667","Bulgaria","BG","BGR","Yambol","","9609","1100602957"
"Luckau","Luckau","51.8500","13.7167","Germany","DE","DEU","Brandenburg","","9582","1276191048"
"Oststeinbek","Oststeinbek","53.5442","10.1664","Germany","DE","DEU","Schleswig-Holstein","","8849","1276130515"
"Corona de Tucson","Corona de Tucson","31.9503","-110.7835","United States","US","USA","Arizona","","9627","1840018106"
"Kensington","Kensington","41.6284","-72.7686","United States","US","USA","Connecticut","","9626","1840000505"
"Sztum","Sztum","53.9217","19.0336","Poland","PL","POL","Pomorskie","minor","9515","1616277062"
"El Molar","El Molar","40.7336","-3.5814","Spain","ES","ESP","Madrid","","9437","1724048617"
"Gatteo","Gatteo","44.1000","12.3833","Italy","IT","ITA","Emilia-Romagna","","8988","1380526755"
"Itasca","Itasca","41.9772","-88.0183","United States","US","USA","Illinois","","9623","1840011400"
"Molagavalli","Molagavalli","15.3567","77.3301","India","IN","IND","Andhra Pradesh","","9475","1356061605"
"Santa Lucia di Piave","Santa Lucia di Piave","45.8500","12.2833","Italy","IT","ITA","Veneto","","9160","1380105478"
"Sarakkayhalli","Sarakkayhalli","12.2191","78.0480","India","IN","IND","Tamil Nādu","","9378","1356210112"
"Marathon","Marathon","24.7263","-81.0374","United States","US","USA","Florida","","9622","1840016010"
"Środa Śląska","Sroda Slaska","51.1500","16.5833","Poland","PL","POL","Dolnośląskie","minor","9525","1616482692"
"Mount Airy","Mount Airy","39.3742","-77.1534","United States","US","USA","Maryland","","9621","1840005703"
"Borogani","Borogani","46.3667","28.5167","Moldova","MD","MDA","Cantemir","","9562","1498462266"
"Vallet","Vallet","47.1617","-1.2669","France","FR","FRA","Pays de la Loire","","9460","1250544633"
"Apiúna","Apiuna","-27.0358","-49.3900","Brazil","BR","BRA","Santa Catarina","","9600","1076920853"
"Vranjska Banja","Vranjska Banja","42.5556","21.9917","Serbia","RS","SRB","","","9580","1688971515"
"Elsenfeld","Elsenfeld","49.8500","9.1667","Germany","DE","DEU","Bavaria","","9238","1276319881"
"Sierning","Sierning","48.0447","14.3100","Austria","AT","AUT","Oberösterreich","","9371","1040285076"
"Bikkatti","Bikkatti","11.3741","76.7513","India","IN","IND","Tamil Nādu","","8941","1356226000"
"Gorē","Gore","8.1490","35.5370","Ethiopia","ET","ETH","Oromīya","","9352","1231481918"
"Saint-Raymond","Saint-Raymond","46.9000","-71.8333","Canada","CA","CAN","Quebec","","9615","1124162305"
"Rāmachandrapuram","Ramachandrapuram","16.5689","81.6645","India","IN","IND","Andhra Pradesh","","8883","1356081546"
"Bellūdi","Belludi","14.4500","75.7833","India","IN","IND","Karnātaka","","9038","1356972018"
"Bilozerka","Bilozerka","46.6317","32.4452","Ukraine","UA","UKR","Khersonska Oblast","minor","9565","1804047570"
"Maḑāyā","Madaya","33.6878","36.1008","Syria","SY","SYR","Rīf Dimashq","minor","9371","1760921670"
"Ralla","Ralla","30.1200","75.4320","India","IN","IND","Punjab","","9311","1356522814"
"Levokumskoye","Levokumskoye","44.8228","44.6592","Russia","RU","RUS","Stavropol’skiy Kray","","9602","1643320533"
"Mittenwalde","Mittenwalde","52.2667","13.5333","Germany","DE","DEU","Brandenburg","","9515","1276476761"
"Santa María","Santa Maria","-31.2611","-64.4639","Argentina","AR","ARG","Córdoba","","9526","1032132957"
"Vanavāsi","Vanavasi","11.7523","77.8779","India","IN","IND","Tamil Nādu","","8949","1356168182"
"Kanavāypudūr","Kanavaypudur","11.9282","78.1832","India","IN","IND","Tamil Nādu","","8745","1356250315"
"Brugnera","Brugnera","45.9000","12.5333","Italy","IT","ITA","Friuli Venezia Giulia","","9290","1380420963"
"Chhapera","Chhapera","23.8948","76.4538","India","IN","IND","Madhya Pradesh","","9369","1356420556"
"Mizhhiria","Mizhhiria","48.5286","23.5019","Ukraine","UA","UKR","Zakarpatska Oblast","minor","9567","1804490087"
"Zschopau","Zschopau","50.7500","13.0667","Germany","DE","DEU","Saxony","","9214","1276308597"
"LaSalle","LaSalle","41.3589","-89.0737","United States","US","USA","Illinois","","9607","1840029891"
"Kungsängen","Kungsangen","59.4833","17.7500","Sweden","SE","SWE","Stockholm","minor","9382","1752617626"
"Brewer","Brewer","44.7835","-68.7352","United States","US","USA","Maine","","9606","1840000239"
"El Cacao","El Cacao","18.5264","-70.2954","Dominican Republic","DO","DOM","Valdesia","","9540","1214839567"
"Velyka Dymerka","Velyka Dymerka","50.5928","30.9103","Ukraine","UA","UKR","Kyivska Oblast","","9554","1804901044"
"North College Hill","North College Hill","39.2175","-84.5520","United States","US","USA","Ohio","","9605","1840001609"
"Rafelbuñol","Rafelbunol","39.5922","-0.3342","Spain","ES","ESP","Valencia","","9381","1724851676"
"Kanchanadit","Kanchanadit","9.1653","99.4706","Thailand","TH","THA","Surat Thani","minor","9492","1764472524"
"Marano Vicentino","Marano Vicentino","45.7000","11.4333","Italy","IT","ITA","Veneto","","9529","1380991447"
"Ban Wat Chan","Ban Wat Chan","16.8033","100.2351","Thailand","TH","THA","Phitsanulok","","8819","1764351898"
"Ichenhausen","Ichenhausen","48.3667","10.3167","Germany","DE","DEU","Bavaria","","9329","1276943409"
"Palmeira d’Oeste","Palmeira d'Oeste","-20.4158","-50.7619","Brazil","BR","BRA","São Paulo","","9596","1076247939"
"Eagle Point","Eagle Point","42.4677","-122.8016","United States","US","USA","Oregon","","9600","1840018676"
"Ubbergen","Ubbergen","51.8333","5.9167","Netherlands","NL","NLD","Gelderland","","9358","1528910216"
"Sarahs","Sarahs","36.5333","61.2167","Turkmenistan","TM","TKM","Ahal","","9585","1795405865"
"Voreppe","Voreppe","45.2978","5.6369","France","FR","FRA","Auvergne-Rhône-Alpes","","9275","1250168262"
"Sebnitz","Sebnitz","50.9667","14.2833","Germany","DE","DEU","Saxony","","9490","1276466123"
"Suan","Suan","10.3333","-74.8833","Colombia","CO","COL","Atlántico","minor","9344","1170318336"
"Petersberg","Petersberg","51.6000","11.9667","Germany","DE","DEU","Saxony-Anhalt","","9504","1276104833"
"Wertingen","Wertingen","48.5333","10.6667","Germany","DE","DEU","Bavaria","","9415","1276445466"
"La Reina","La Reina","14.1833","-89.1500","El Salvador","SV","SLV","Chalatenango","","9525","1222331926"
"Wābāgai","Wabagai","24.5309","93.9376","India","IN","IND","Manipur","","8895","1356756994"
"Irthlingborough","Irthlingborough","52.3240","-0.6140","United Kingdom","GB","GBR","Northamptonshire","","8900","1826205713"
"Parasbani","Parasbani","25.8249","87.2983","India","IN","IND","Bihār","","8672","1356008620"
"Bad Bevensen","Bad Bevensen","53.0792","10.5833","Germany","DE","DEU","Lower Saxony","","9400","1276837566"
"Uppalapādu","Uppalapadu","16.3862","79.9983","India","IN","IND","Andhra Pradesh","","9282","1356018956"
"Gūdalūr","Gudalur","10.7898","78.1285","India","IN","IND","Tamil Nādu","","9172","1356221989"
"Henderson","Henderson","-36.2833","-61.7167","Argentina","AR","ARG","Buenos Aires","minor","9585","1032715747"
"Belley","Belley","45.7592","5.6881","France","FR","FRA","Auvergne-Rhône-Alpes","minor","9182","1250947929"
"Kāyatha","Kayatha","23.2370","76.0189","India","IN","IND","Madhya Pradesh","","9321","1356089557"
"Čepin","Cepin","45.5167","18.5667","Croatia","HR","HRV","Osječko-Baranjska Županija","minor","9500","1191719855"
"Blachownia","Blachownia","50.7833","18.9667","Poland","PL","POL","Śląskie","","9334","1616282069"
"Obersiggenthal","Obersiggenthal","47.4869","8.2921","Switzerland","CH","CHE","Aargau","","8565","1756136415"
"Mokri","Mokri","25.0184","83.5633","India","IN","IND","Bihār","","9129","1356116664"
"Eunice","Eunice","30.4904","-92.4191","United States","US","USA","Louisiana","","9589","1840013927"
"Belmont","Belmont","39.0622","-77.4985","United States","US","USA","Virginia","","9589","1840024496"
"Odobeşti","Odobesti","45.7667","27.0667","Romania","RO","ROU","Vrancea","","9423","1642659916"
"Valréas","Valreas","44.3842","4.9903","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9426","1250026061"
"San Pedro Nonualco","San Pedro Nonualco","13.6000","-88.9333","El Salvador","SV","SLV","La Paz","","9252","1222378780"
"Buñol","Bunol","39.4194","-0.7906","Spain","ES","ESP","Valencia","","9503","1724376022"
"Sankt Johann in Tirol","Sankt Johann in Tirol","47.5225","12.4256","Austria","AT","AUT","Tirol","","9428","1040232004"
"Tatoufet","Tatoufet","35.0339","-5.7706","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9527","1504842271"
"Lototla","Lototla","20.8392","-98.7178","Mexico","MX","MEX","Hidalgo","","9541","1484853725"
"San Juan Bautista","San Juan Bautista","14.4167","-91.1833","Guatemala","GT","GTM","Suchitepéquez","minor","9404","1320577327"
"Bakaly","Bakaly","55.1789","53.8028","Russia","RU","RUS","Bashkortostan","","9568","1643000985"
"Nangal Lālchand","Nangal Lalchand","29.9200","75.4000","India","IN","IND","Punjab","","9280","1356848329"
"Manuel Urbano","Manuel Urbano","-8.8389","-69.2600","Brazil","BR","BRA","Acre","","9581","1076473892"
"Miajadas","Miajadas","39.1500","-6.0667","Spain","ES","ESP","Extremadura","","9503","1724704147"
"Vareš","Vares","44.1619","18.3269","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","9556","1070474354"
"Dahi","Dahi","22.1122","74.5879","India","IN","IND","Madhya Pradesh","","9288","1356491482"
"Paray-le-Monial","Paray-le-Monial","46.4511","4.1194","France","FR","FRA","Bourgogne-Franche-Comté","","9214","1250140703"
"Bezliudivka","Bezliudivka","49.8694","36.2719","Ukraine","UA","UKR","Kharkivska Oblast","","9436","1804332545"
"Aschheim","Aschheim","48.1733","11.7178","Germany","DE","DEU","Bavaria","","9249","1276119666"
"Rāni Sāgar","Rani Sagar","25.6079","84.3626","India","IN","IND","Bihār","","8572","1356090375"
"Sanganakallu","Sanganakallu","15.1847","76.9703","India","IN","IND","Karnātaka","","8978","1356704546"
"Reinfeld","Reinfeld","53.8333","10.4833","Germany","DE","DEU","Schleswig-Holstein","","9058","1276759795"
"Periyamuttūr","Periyamuttur","12.4630","78.1969","India","IN","IND","Tamil Nādu","","9084","1356106818"
"Ūnagatla","Unagatla","16.9611","81.6610","India","IN","IND","Andhra Pradesh","","8958","1356207891"
"Ingenbohl","Ingenbohl","47.0028","8.6117","Switzerland","CH","CHE","Schwyz","","8912","1756840092"
"La Grande-Motte","La Grande-Motte","43.5606","4.0850","France","FR","FRA","Occitanie","","8755","1250842934"
"Boddikūrapādu","Boddikurapadu","15.6591","79.7509","India","IN","IND","Andhra Pradesh","","9329","1356000066"
"Lwówek Śląski","Lwowek Slaski","51.1167","15.5833","Poland","PL","POL","Dolnośląskie","minor","9500","1616807220"
"Rodeo","Rodeo","38.0367","-122.2526","United States","US","USA","California","","9575","1840018900"
"Bni Gmil","Bni Gmil","35.0833","-4.3833","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9513","1504140120"
"Sarenja","Sarenja","25.4449","83.8942","India","IN","IND","Bihār","","8957","1356988432"
"Bolívar","Bolivar","5.9911","-73.7739","Colombia","CO","COL","Santander","minor","9567","1170731054"
"Obernkirchen","Obernkirchen","52.2664","9.1178","Germany","DE","DEU","Lower Saxony","","9288","1276033571"
"Ehringshausen","Ehringshausen","50.6000","8.3833","Germany","DE","DEU","Hesse","","9367","1276599931"
"Ingleside","Ingleside","27.8703","-97.2075","United States","US","USA","Texas","","9573","1840019709"
"Yamakita","Yamakita","35.3606","139.0838","Japan","JP","JPN","Kanagawa","","9530","1392889256"
"Girard","Girard","41.1665","-80.6963","United States","US","USA","Ohio","","9571","1840008239"
"Santa Rosa del Peñón","Santa Rosa del Penon","12.8003","-86.3689","Nicaragua","NI","NIC","León","minor","9529","1558392157"
"Ryki","Ryki","51.6333","21.9333","Poland","PL","POL","Lubelskie","minor","9229","1616774707"
"Schaafheim","Schaafheim","49.9242","9.0094","Germany","DE","DEU","Hesse","","9282","1276791845"
"Santiponce","Santiponce","37.4353","-6.0433","Spain","ES","ESP","Andalusia","","8507","1724904141"
"Guria","Guria","26.1633","86.9645","India","IN","IND","Bihār","","8535","1356800402"
"Zawyat Sidi Ben Hamdoun","Zawyat Sidi Ben Hamdoun","33.0450","-7.9290","Morocco","MA","MAR","Casablanca-Settat","","9521","1504593978"
"Kayyngdy","Kayyngdy","42.8300","73.6800","Kyrgyzstan","KG","KGZ","Chüy","minor","9561","1417817372"
"Ouroeste","Ouroeste","-20.0008","-50.3719","Brazil","BR","BRA","São Paulo","","9564","1076000763"
"Rāmgarh","Ramgarh","25.2889","83.6535","India","IN","IND","Bihār","","8824","1356978414"
"Carlton Colville","Carlton Colville","52.4540","1.6910","United Kingdom","GB","GBR","Suffolk","","8505","1826912261"
"Ālampur","Alampur","24.8721","83.8829","India","IN","IND","Bihār","","9067","1356005063"
"Steger","Steger","41.4723","-87.6176","United States","US","USA","Illinois","","9568","1840011325"
"Bay St. Louis","Bay St. Louis","30.3281","-89.3774","United States","US","USA","Mississippi","","9568","1840013951"
"Schübelbach","Schubelbach","47.1733","8.9264","Switzerland","CH","CHE","Schwyz","","9249","1756445670"
"Néa Michanióna","Nea Michaniona","40.4644","22.8606","Greece","GR","GRC","Kentrikí Makedonía","","8775","1300851407"
"Chinnāyagūdem","Chinnayagudem","17.0470","81.4810","India","IN","IND","Andhra Pradesh","","9150","1356490281"
"Catanduvas","Catanduvas","-27.0708","-51.6619","Brazil","BR","BRA","Santa Catarina","","9555","1076593724"
"Cervera","Cervera","41.6657","1.2710","Spain","ES","ESP","Catalonia","","9393","1724947654"
"Pararia","Pararia","26.3400","87.5896","India","IN","IND","Bihār","","8745","1356686524"
"Bendapūdi","Bendapudi","17.2673","82.3758","India","IN","IND","Andhra Pradesh","","9247","1356052121"
"Cunday","Cunday","4.0833","-74.6667","Colombia","CO","COL","Tolima","minor","9544","1170276420"
"La Paz","La Paz","-33.4667","-67.5500","Argentina","AR","ARG","Mendoza","minor","9560","1032294439"
"Sədərək","Sadarak","39.7175","44.8764","Azerbaijan","AZ","AZE","Sədərək","","9306","1031675404"
"Pudukkottai","Pudukkottai","10.6118","78.0505","India","IN","IND","Tamil Nādu","","9210","1356220004"
"Sosale","Sosale","12.2330","76.9170","India","IN","IND","Karnātaka","","9324","1356105503"
"Bhui","Bhui","25.0894","85.3888","India","IN","IND","Bihār","","8735","1356702860"
"Chaplynka","Chaplynka","46.3629","33.5302","Ukraine","UA","UKR","Khersonska Oblast","minor","9539","1804411904"
"Langnau","Langnau","46.9433","7.7853","Switzerland","CH","CHE","Bern","minor","9366","1756946550"
"Gurmaila","Gurmaila","25.4829","87.3490","India","IN","IND","Bihār","","8877","1356942831"
"Onchan","Onchan","54.1750","-4.4550","Isle Of Man","IM","IMN","","","9172","1833737971"
"Aksay","Aksay","43.3725","46.4450","Russia","RU","RUS","Dagestan","","9442","1643918387"
"Parempuyre","Parempuyre","44.9492","-0.6050","France","FR","FRA","Nouvelle-Aquitaine","","9136","1250645547"
"Brandon","Brandon","52.4474","0.6242","United Kingdom","GB","GBR","Suffolk","","9145","1826506200"
"Chita","Chita","6.1667","-72.4167","Colombia","CO","COL","Boyacá","minor","9542","1170485101"
"Mogadouro","Mogadouro","41.3333","-6.7167","Portugal","PT","PRT","Bragança","minor","9542","1620447198"
"Nittendorf","Nittendorf","49.0256","11.9589","Germany","DE","DEU","Bavaria","","9277","1276523264"
"Nepi","Nepi","42.2436","12.3464","Italy","IT","ITA","Lazio","","9549","1380219863"
"Rauco","Rauco","-34.9167","-71.3167","Chile","CL","CHL","Maule","","9543","1152798030"
"Ipuiúna","Ipuiuna","-22.0989","-46.1900","Brazil","BR","BRA","Minas Gerais","","9521","1076328220"
"Nalbach","Nalbach","49.3833","6.7833","Germany","DE","DEU","Saarland","","9145","1276451456"
"Lügde","Lugde","51.9500","9.2500","Germany","DE","DEU","North Rhine-Westphalia","","9448","1276075848"
"Bang Khla","Bang Khla","13.7268","101.2105","Thailand","TH","THA","Chachoengsao","minor","9311","1764157593"
"Castel Gandolfo","Castel Gandolfo","41.7469","12.6519","Italy","IT","ITA","Lazio","","8922","1380661217"
"Juprelle","Juprelle","50.7167","5.5333","Belgium","BE","BEL","Wallonia","","9288","1056730638"
"Penaballi","Penaballi","17.2103","80.6978","India","IN","IND","Andhra Pradesh","","8915","1356309393"
"Amherst","Amherst","45.8167","-64.2167","Canada","CA","CAN","Nova Scotia","","9550","1124895094"
"Pohrebyshche","Pohrebyshche","49.4869","29.2733","Ukraine","UA","UKR","Vinnytska Oblast","minor","9525","1804131540"
"Chahār Borj-e Qadīm","Chahar Borj-e Qadim","37.1231","45.9761","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","","9406","1364251992"
"Legnaro","Legnaro","45.3500","11.9667","Italy","IT","ITA","Veneto","","8948","1380237634"
"El Amim","El Amim","32.2067","-7.2629","Morocco","MA","MAR","Marrakech-Safi","","9454","1504410510"
"Ghālib Kalān","Ghalib Kalan","30.8372","75.3902","India","IN","IND","Punjab","","9059","1356382294"
"Bull Mountain","Bull Mountain","45.4125","-122.8320","United States","US","USA","Oregon","","9545","1840040416"
"Sankt Valentin","Sankt Valentin","48.1747","14.5333","Austria","AT","AUT","Niederösterreich","","9340","1040852174"
"Istrana","Istrana","45.6833","12.1000","Italy","IT","ITA","Veneto","","9197","1380678496"
"Fossombrone","Fossombrone","43.7000","12.8167","Italy","IT","ITA","Marche","","9454","1380453878"
"Mendicino","Mendicino","39.2628","16.1945","Italy","IT","ITA","Calabria","","9503","1380513380"
"Amityville","Amityville","40.6696","-73.4156","United States","US","USA","New York","","9542","1840005140"
"Ambatolahy","Ambatolahy","-19.7333","47.2833","Madagascar","MG","MDG","Antananarivo","","9000","1450305568"
"Neuried","Neuried","48.0933","11.4658","Germany","DE","DEU","Bavaria","","8643","1276429701"
"Iaras","Iaras","-22.8667","-49.1667","Brazil","BR","BRA","São Paulo","","9517","1076892374"
"Tadworth","Tadworth","51.2940","-0.2420","United Kingdom","GB","GBR","Surrey","","9522","1826571557"
"Águia Branca","Aguia Branca","-18.9828","-40.7400","Brazil","BR","BRA","Espírito Santo","","9519","1076311208"
"Ecatzingo","Ecatzingo","18.9500","-98.7500","Mexico","MX","MEX","México","minor","9369","1484003696"
"Rehau","Rehau","50.2500","12.0167","Germany","DE","DEU","Bavaria","","9424","1276647977"
"Sturgeon Bay","Sturgeon Bay","44.8228","-87.3660","United States","US","USA","Wisconsin","","9540","1840003935"
"Shanklin","Shanklin","50.6333","-1.1750","United Kingdom","GB","GBR","Isle of Wight","","9072","1826746532"
"Rāmapattanam","Ramapattanam","10.7106","76.9183","India","IN","IND","Tamil Nādu","","8921","1356162555"
"Morānha","Moranha","27.1874","94.9156","India","IN","IND","Assam","","9010","1356098720"
"Viagrande","Viagrande","37.6167","15.1000","Italy","IT","ITA","Sicilia","","8677","1380277178"
"Pokotylivka","Pokotylivka","49.9139","36.1750","Ukraine","UA","UKR","Kharkivska Oblast","","9394","1804790760"
"Burayevo","Burayevo","55.8425","55.4056","Russia","RU","RUS","Bashkortostan","","9522","1643852683"
"Eurajoki","Eurajoki","61.2000","21.7333","Finland","FI","FIN","Satakunta","minor","9521","1246054353"
"Aragona","Aragona","37.4019","13.6183","Italy","IT","ITA","Sicilia","","9409","1380327627"
"Morehead City","Morehead City","34.7308","-76.7388","United States","US","USA","North Carolina","","9535","1840016482"
"Rudraprayāg","Rudraprayag","30.2800","78.9800","India","IN","IND","Uttarākhand","","9313","1356644315"
"Modra","Modra","48.3317","17.3089","Slovakia","SK","SVK","Bratislava","","9346","1703770416"
"Sukkāmpatti","Sukkampatti","10.5267","78.1728","India","IN","IND","Tamil Nādu","","9183","1356224682"
"Pagqên","Pagqen","33.9739","99.9083","China","CN","CHN","Qinghai","minor","9530","1156663795"
"Hemau","Hemau","49.0519","11.7828","Germany","DE","DEU","Bavaria","","9456","1276739454"
"Bāgeshwar","Bageshwar","29.8380","79.7710","India","IN","IND","Uttarākhand","","9079","1356630152"
"Villa Castelli","Villa Castelli","40.5833","17.4833","Italy","IT","ITA","Puglia","","9269","1380015414"
"La Loggia","La Loggia","44.9577","7.6673","Italy","IT","ITA","Piedmont","","8841","1380737377"
"Ouédémè","Ouedeme","6.7000","1.6833","Benin","BJ","BEN","Couffo","","9099","1204584039"
"Hagaribommanahalli","Hagaribommanahalli","15.0400","76.2000","India","IN","IND","Karnātaka","","9295","1356395805"
"Carencro","Carencro","30.3126","-92.0388","United States","US","USA","Louisiana","","9531","1840015043"
"Bastrop","Bastrop","30.1113","-97.3176","United States","US","USA","Texas","","9531","1840019603"
"Succasunna","Succasunna","40.8530","-74.6568","United States","US","USA","New Jersey","","9530","1840024235"
"Hernando","Hernando","28.9451","-82.3781","United States","US","USA","Florida","","9530","1840014058"
"Waltham Cross","Waltham Cross","51.6860","-0.0331","United Kingdom","GB","GBR","Hertfordshire","","8577","1826629009"
"Whitestown","Whitestown","39.9706","-86.3612","United States","US","USA","Indiana","","9529","1840010514"
"Periyapōdu","Periyapodu","10.6099","76.8786","India","IN","IND","Tamil Nādu","","8910","1356162416"
"Orchies","Orchies","50.4747","3.2442","France","FR","FRA","Hauts-de-France","","8736","1250748615"
"Sogām","Sogam","34.5014","74.3823","India","IN","IND","Jammu and Kashmīr","","9094","1356526490"
"Gandhwāni","Gandhwani","22.3400","75.0048","India","IN","IND","Madhya Pradesh","","9220","1356357112"
"Varre-Sai","Varre-Sai","-20.9308","-41.8689","Brazil","BR","BRA","Rio de Janeiro","","9475","1076621254"
"Ganāram","Ganaram","18.5275","78.2450","India","IN","IND","Andhra Pradesh","","9156","1356054707"
"Ikeda","Ikeda","36.4212","137.8746","Japan","JP","JPN","Nagano","","9292","1392276481"
"Hostivice","Hostivice","50.0814","14.2525","Czechia","CZ","CZE","Středočeský Kraj","","8917","1203249506"
"Vallahbhāpuram","Vallahbhapuram","16.3528","80.7175","India","IN","IND","Andhra Pradesh","","9220","1356853559"
"Gomaringen","Gomaringen","48.4519","9.0997","Germany","DE","DEU","Baden-Württemberg","","9001","1276119838"
"Angichettippālaiyam","Angichettippalaiyam","11.7742","79.5255","India","IN","IND","Tamil Nādu","","8754","1356202958"
"San José Guayabal","San Jose Guayabal","13.8500","-89.1000","El Salvador","SV","SLV","Cuscatlán","","9300","1222743447"
"Bom Jesus","Bom Jesus","-5.9839","-35.5808","Brazil","BR","BRA","Rio Grande do Norte","","9440","1076559743"
"Zacualpan de Amilpas","Zacualpan de Amilpas","18.7836","-98.7594","Mexico","MX","MEX","Morelos","minor","9370","1484800291"
"Gonfreville-l’Orcher","Gonfreville-l'Orcher","49.5053","0.2331","France","FR","FRA","Normandie","","9162","1250002504"
"Tomah","Tomah","43.9879","-90.4999","United States","US","USA","Wisconsin","","9515","1840002566"
"Pasivedalajimma","Pasivedalajimma","17.0072","81.8292","India","IN","IND","Andhra Pradesh","","8751","1356370176"
"Miltenberg","Miltenberg","49.7039","9.2644","Germany","DE","DEU","Bavaria","minor","9359","1276744365"
"Halgeri","Halgeri","14.5551","75.6099","India","IN","IND","Karnātaka","","9158","1356572622"
"Rauenberg","Rauenberg","49.2678","8.7036","Germany","DE","DEU","Baden-Württemberg","","8728","1276997920"
"Saßnitz","Sassnitz","54.5164","13.6411","Germany","DE","DEU","Mecklenburg-Western Pomerania","","9320","1276897378"
"Monett","Monett","36.9218","-93.9278","United States","US","USA","Missouri","","9512","1840008797"
"Cherniakhiv","Cherniakhiv","50.4550","28.6653","Ukraine","UA","UKR","Zhytomyrska Oblast","","9479","1804141679"
"Chechen-Aul","Chechen-Aul","43.2000","45.7889","Russia","RU","RUS","Chechnya","","9300","1643795075"
"Cedar Hills","Cedar Hills","45.5047","-122.8053","United States","US","USA","Oregon","","9511","1840034716"
"The Village","The Village","35.5706","-97.5567","United States","US","USA","Oklahoma","","9510","1840021742"
"Aniskino","Aniskino","55.9417","38.1500","Russia","RU","RUS","Moskovskaya Oblast’","","9208","1643484680"
"Ja‘farīyeh","Ja\`fariyeh","34.7894","50.5747","Iran","IR","IRN","Qom","","9387","1364571429"
"Mosciano Sant’Angelo","Mosciano Sant'Angelo","42.7500","13.8833","Italy","IT","ITA","Abruzzo","","9316","1380020455"
"Claymont","Claymont","39.8032","-75.4607","United States","US","USA","Delaware","","9508","1840005570"
"Nyzhnohirskyi","Nyzhnohirskyi","45.4464","34.7344","Ukraine","UA","UKR","Krym, Avtonomna Respublika","minor","9460","1804665220"
"Nakoushi","Nakoushi","26.6825","127.9728","Japan","JP","JPN","Okinawa","","9275","1392839162"
"Cajvana","Cajvana","47.7044","25.9694","Romania","RO","ROU","Suceava","","9139","1642941621"
"Oakville","Oakville","41.5893","-73.0905","United States","US","USA","Connecticut","","9507","1840003260"
"Tambura","Tambura","5.5900","27.4700","South Sudan","SS","SSD","Western Equatoria","","9500","1728480135"
"Aleksandrovsk-Sakhalinskiy","Aleksandrovsk-Sakhalinskiy","50.9000","142.1500","Russia","RU","RUS","Sakhalinskaya Oblast’","","9504","1643873847"
"Triunfo","Triunfo","-6.5789","-38.5969","Brazil","BR","BRA","Paraíba","","9464","1076202678"
"Halge","Halge","15.8800","74.5600","India","IN","IND","Karnātaka","","8514","1356531325"
"Bohemia","Bohemia","40.7717","-73.1271","United States","US","USA","New York","","9504","1840005020"
"Wallerfangen","Wallerfangen","49.3278","6.7208","Germany","DE","DEU","Saarland","","9285","1276477962"
"Lake Mohawk","Lake Mohawk","41.0149","-74.6640","United States","US","USA","New Jersey","","9503","1840004955"
"Meadowbrook","Meadowbrook","33.3935","-86.7041","United States","US","USA","Alabama","","9503","1840027633"
"Pallippatti","Pallippatti","11.8722","77.8970","India","IN","IND","Tamil Nādu","","8842","1356169335"
"Lesnoy Gorodok","Lesnoy Gorodok","55.6417","37.2042","Russia","RU","RUS","Moskovskaya Oblast’","","9226","1643321355"
"Govindapalle","Govindapalle","15.3525","78.5224","India","IN","IND","Andhra Pradesh","","9130","1356092784"
"Sungal","Sungal","32.9392","74.6935","India","IN","IND","Jammu and Kashmīr","","9156","1356287787"
"Jūraqān","Juraqan","34.8850","48.5544","Iran","IR","IRN","Hamadān","","9234","1364272105"
"Savignano sul Panaro","Savignano sul Panaro","44.4833","11.0333","Italy","IT","ITA","Emilia-Romagna","","9142","1380928664"
"An Châu","An Chau","21.3333","106.8500","Vietnam","VN","VNM","Bắc Giang","minor","9416","1704328955"
"Nelali","Nelali","10.9282","77.4832","India","IN","IND","Tamil Nādu","","9177","1356958431"
"Lanta","Lanta","7.1000","1.8667","Benin","BJ","BEN","Couffo","","9129","1204662871"
"Andanappettai","Andanappettai","10.7498","79.8226","India","IN","IND","Tamil Nādu","","8519","1356182049"
"Adjido","Adjido","7.0333","1.9833","Benin","BJ","BEN","Zou","","9064","1204261939"
"Ananás","Ananas","-6.3658","-48.0728","Brazil","BR","BRA","Tocantins","","9492","1076131174"
"Greenwood","Greenwood","35.2134","-94.2408","United States","US","USA","Arkansas","","9498","1840013457"
"Louisville","Louisville","40.8370","-81.2643","United States","US","USA","Ohio","","9497","1840000964"
"Sauzal","Sauzal","28.4799","-16.4357","Spain","ES","ESP","Canary Islands","","9005","1724040486"
"Martano","Martano","40.2000","18.3000","Italy","IT","ITA","Puglia","","9087","1380927847"
"Carácuaro","Caracuaro","19.0167","-101.1260","Mexico","MX","MEX","Michoacán","","9485","1484202131"
"Rudnya","Rudnya","54.9500","31.1000","Russia","RU","RUS","Smolenskaya Oblast’","minor","9484","1643921469"
"Herenthout","Herenthout","51.1500","4.7667","Belgium","BE","BEL","Flanders","","9109","1056849733"
"Gaggiano","Gaggiano","45.4048","9.0349","Italy","IT","ITA","Lombardy","","9146","1380179034"
"North Gates","North Gates","43.1718","-77.7064","United States","US","USA","New York","","9494","1840024047"
"Middlesborough","Middlesborough","36.6127","-83.7227","United States","US","USA","Kentucky","","9493","1840036079"
"Binfield","Binfield","51.4320","-0.7920","United Kingdom","GB","GBR","Bracknell Forest","","8689","1826891094"
"Onnaing","Onnaing","50.3878","3.5981","France","FR","FRA","Hauts-de-France","","8813","1250631065"
"Iseo","Iseo","45.6586","10.0536","Italy","IT","ITA","Lombardy","","9168","1380575518"
"Périgny","Perigny","46.1528","-1.0964","France","FR","FRA","Nouvelle-Aquitaine","","8684","1250943859"
"Belvedere Marittimo","Belvedere Marittimo","39.6167","15.8667","Italy","IT","ITA","Calabria","","9239","1380966116"
"Elgin","Elgin","30.3526","-97.3883","United States","US","USA","Texas","","9488","1840020903"
"Ramara","Ramara","44.6333","-79.2167","Canada","CA","CAN","Ontario","","9488","1124000641"
"Aadorf","Aadorf","47.4939","8.8975","Switzerland","CH","CHE","Thurgau","","9036","1756022542"
"Lamarão","Lamarao","-11.7828","-38.9000","Brazil","BR","BRA","Bahia","","9442","1076962931"
"Dharhwa","Dharhwa","26.9104","84.2769","India","IN","IND","Bihār","","8689","1356892006"
"Burela de Cabo","Burela de Cabo","43.6500","-7.4000","Spain","ES","ESP","Galicia","","9430","1724895816"
"Matawan","Matawan","40.4127","-74.2365","United States","US","USA","New Jersey","","9486","1840003675"
"Suances","Suances","43.4333","-4.0500","Spain","ES","ESP","Cantabria","","9115","1724045126"
"Kadlabālu","Kadlabalu","15.0400","76.2000","India","IN","IND","Karnātaka","","9249","1356331923"
"Lititz","Lititz","40.1540","-76.3044","United States","US","USA","Pennsylvania","","9485","1840003719"
"Minano","Minano","36.0708","139.0988","Japan","JP","JPN","Saitama","","9338","1392100965"
"Síndos","Sindos","40.6706","22.8056","Greece","GR","GRC","Kentrikí Makedonía","minor","9289","1300637403"
"Planaltino","Planaltino","-13.2589","-40.3689","Brazil","BR","BRA","Bahia","","9473","1076715731"
"Granada","Granada","4.5186","-74.3514","Colombia","CO","COL","Cundinamarca","minor","9253","1170616970"
"Harvard","Harvard","42.4296","-88.6211","United States","US","USA","Illinois","","9482","1840006970"
"Esperantina","Esperantina","-5.3428","-48.5108","Brazil","BR","BRA","Tocantins","","9476","1076000810"
"Grinnell","Grinnell","41.7359","-92.7244","United States","US","USA","Iowa","","9481","1840000531"
"Soubakaniédougou","Soubakaniedougou","10.4824","-5.0098","Burkina Faso","BF","BFA","Cascades","","9423","1854966202"
"Yutsa","Yutsa","43.9625","42.9875","Russia","RU","RUS","Stavropol’skiy Kray","","9427","1643457131"
"Jājireddigūdem","Jajireddigudem","17.3278","79.5711","India","IN","IND","Andhra Pradesh","","9223","1356464422"
"Dhāmnod","Dhamnod","23.4421","74.9744","India","IN","IND","Madhya Pradesh","","9207","1356033430"
"Terra Roxa","Terra Roxa","-20.7889","-48.3300","Brazil","BR","BRA","São Paulo","","9437","1076064545"
"Santanópolis","Santanopolis","-12.0169","-38.8669","Brazil","BR","BRA","Bahia","","9442","1076762230"
"Castellabate","Castellabate","40.2789","14.9528","Italy","IT","ITA","Campania","","9233","1380791579"
"Conning Towers Nautilus Park","Conning Towers Nautilus Park","41.3850","-72.0684","United States","US","USA","Connecticut","","9479","1840073288"
"Itiki","Itiki","14.9510","76.0944","India","IN","IND","Karnātaka","","9258","1356122733"
"Susāri","Susari","22.1797","74.7577","India","IN","IND","Madhya Pradesh","","9196","1356185495"
"Huedin","Huedin","46.8700","23.0300","Romania","RO","ROU","Cluj","","9346","1642303721"
"Arenápolis","Arenapolis","-14.4500","-56.8458","Brazil","BR","BRA","Mato Grosso","","9455","1076938959"
"Parabita","Parabita","40.0500","18.1333","Italy","IT","ITA","Puglia","","9047","1380375061"
"Riachuelo","Riachuelo","-10.7278","-37.1869","Brazil","BR","BRA","Sergipe","","9355","1076898360"
"Hombrechtikon","Hombrechtikon","47.2500","8.7667","Switzerland","CH","CHE","Zürich","","8756","1756295495"
"Vallendar","Vallendar","50.3971","7.6220","Germany","DE","DEU","Rhineland-Palatinate","","8808","1276411152"
"Perwez","Perwez","50.6241","4.8150","Belgium","BE","BEL","Wallonia","","9291","1056663953"
"Zwenkau","Zwenkau","51.2175","12.3242","Germany","DE","DEU","Saxony","","9274","1276888789"
"Middletown","Middletown","38.2410","-85.5214","United States","US","USA","Kentucky","","9473","1840014295"
"Nieuwleusen","Nieuwleusen","52.5833","6.2833","Netherlands","NL","NLD","Overijssel","","9300","1528131301"
"Mhâjâr","Mhajar","35.1200","-3.4900","Morocco","MA","MAR","Oriental","","9386","1504595194"
"Bystrzyca Kłodzka","Bystrzyca Klodzka","50.3000","16.6500","Poland","PL","POL","Dolnośląskie","","9414","1616639168"
"Dielheim","Dielheim","49.2825","8.7347","Germany","DE","DEU","Baden-Württemberg","","9071","1276260465"
"Opalenica","Opalenica","52.3078","16.4136","Poland","PL","POL","Wielkopolskie","","9360","1616220242"
"Sidi El Hattab","Sidi El Hattab","32.2667","-7.2833","Morocco","MA","MAR","Marrakech-Safi","","9421","1504871106"
"Oakwood","Oakwood","39.7202","-84.1734","United States","US","USA","Ohio","","9470","1840003785"
"Villacañas","Villacanas","39.6333","-3.3333","Spain","ES","ESP","Castille-La Mancha","","9434","1724000261"
"Middletown","Middletown","40.2010","-76.7289","United States","US","USA","Pennsylvania","","9469","1840001291"
"Gooik","Gooik","50.8000","4.1167","Belgium","BE","BEL","Flanders","","9236","1056183534"
"Grossos","Grossos","-4.9800","-37.1550","Brazil","BR","BRA","Rio Grande do Norte","","9393","1076598247"
"Ablu","Ablu","30.3391","74.7879","India","IN","IND","Punjab","","8830","1356899653"
"Dāvulūru","Davuluru","16.2625","80.7436","India","IN","IND","Andhra Pradesh","","9092","1356080715"
"Dānesfahān","Danesfahan","35.8108","49.7422","Iran","IR","IRN","Qazvīn","","9434","1364115128"
"Leeds and the Thousand Islands","Leeds and the Thousand Islands","44.4500","-76.0800","Canada","CA","CAN","Ontario","","9465","1124000531"
"Filottrano","Filottrano","43.4333","13.3500","Italy","IT","ITA","Marche","","9332","1380885932"
"Várzea do Poço","Varzea do Poco","-11.5289","-40.3200","Brazil","BR","BRA","Bahia","","9416","1076941608"
"Bethel","Bethel","39.8458","-75.4891","United States","US","USA","Pennsylvania","","9462","1840150552"
"Carignan","Carignan","45.4500","-73.3000","Canada","CA","CAN","Quebec","","9462","1124001655"
"Brockton","Brockton","44.1667","-81.2167","Canada","CA","CAN","Ontario","","9461","1124000713"
"São Domingos","Sao Domingos","-26.5578","-52.5319","Brazil","BR","BRA","Santa Catarina","","9434","1076855948"
"East Brandywine","East Brandywine","40.0364","-75.7505","United States","US","USA","Pennsylvania","","9458","1840149883"
"Tārazu","Tarazu","34.2714","74.5009","India","IN","IND","Jammu and Kashmīr","","8619","1356858441"
"Vega Alta","Vega Alta","18.4151","-66.3211","Puerto Rico","PR","PRI","Puerto Rico","","9457","1630023587"
"Hartland","Hartland","43.1029","-88.3396","United States","US","USA","Wisconsin","","9457","1840003009"
"Fársala","Farsala","39.2833","22.3833","Greece","GR","GRC","Thessalía","minor","9298","1300870783"
"La Chapelle d’Armentières","La Chapelle d'Armentieres","50.6728","2.8950","France","FR","FRA","Hauts-de-France","","8627","1250111182"
"Budenheim","Budenheim","50.0167","8.1667","Germany","DE","DEU","Rhineland-Palatinate","","8641","1276397913"
"Wau","Wau","-7.3389","146.7167","Papua New Guinea","PG","PNG","Morobe","","9442","1598739902"
"Kirs","Kirs","59.3500","52.2500","Russia","RU","RUS","Kirovskaya Oblast’","","9453","1643457660"
"Missaglia","Missaglia","45.7000","9.3333","Italy","IT","ITA","Lombardy","","8700","1380332238"
"South Abington","South Abington","41.4900","-75.6891","United States","US","USA","Pennsylvania","","9455","1840146632"
"La Ferté-Bernard","La Ferte-Bernard","48.1867","0.6544","France","FR","FRA","Pays de la Loire","","8862","1250851632"
"Cambridge","Cambridge","45.5612","-93.2283","United States","US","USA","Minnesota","","9454","1840006692"
"La Mujer","La Mujer","36.7523","-2.6838","Spain","ES","ESP","Andalusia","","9086","1724347316"
"Almagro","Almagro","11.9108","124.2852","Philippines","PH","PHL","Samar","","9273","1608267193"
"Shira","Shira","54.4939","89.9628","Russia","RU","RUS","Khakasiya","","9448","1643191566"
"Capbreton","Capbreton","43.6419","-1.4322","France","FR","FRA","Nouvelle-Aquitaine","","9037","1250051541"
"San Francisco Libre","San Francisco Libre","12.5061","-86.2997","Nicaragua","NI","NIC","Managua","minor","9416","1558719164"
"Mohelnice","Mohelnice","49.7770","16.9195","Czechia","CZ","CZE","Olomoucký Kraj","","9255","1203067373"
"Mandalavādi","Mandalavadi","12.6073","78.6055","India","IN","IND","Tamil Nādu","","8851","1356759036"
"Hegde","Hegde","14.4667","74.4167","India","IN","IND","Karnātaka","","9125","1356565528"
"Dubrovytsya","Dubrovytsya","51.5667","26.5667","Ukraine","UA","UKR","Rivnenska Oblast","","9424","1804570134"
"Olho d’Água do Casado","Olho d'Agua do Casado","-9.5000","-37.8167","Brazil","BR","BRA","Alagoas","","9441","1076578484"
"Villa Cañás","Villa Canas","-34.0000","-61.6000","Argentina","AR","ARG","Santa Fe","","9433","1032652134"
"Cape Elizabeth","Cape Elizabeth","43.5891","-70.2380","United States","US","USA","Maine","","9448","1840052639"
"Midland","Midland","47.1734","-122.4120","United States","US","USA","Washington","","9448","1840018452"
"Asolo","Asolo","45.8000","11.9167","Italy","IT","ITA","Veneto","","9089","1380265519"
"Alawandi","Alawandi","15.2303","75.9801","India","IN","IND","Karnātaka","","9117","1356938330"
"Ban Tha Phra","Ban Tha Phra","16.3298","102.7998","Thailand","TH","THA","Khon Kaen","","9075","1764897483"
"Lillebonne","Lillebonne","49.5208","0.5375","France","FR","FRA","Normandie","","8845","1250846480"
"Great Dunmow","Great Dunmow","51.8730","0.3617","United Kingdom","GB","GBR","Essex","","8830","1826161396"
"Clarksville","Clarksville","35.4570","-93.4803","United States","US","USA","Arkansas","","9444","1840014533"
"Kodigenahalli","Kodigenahalli","13.7214","77.3863","India","IN","IND","Karnātaka","","9195","1356674382"
"Madanāncheri","Madanancheri","12.7034","78.5991","India","IN","IND","Tamil Nādu","","8836","1356297298"
"Harqalah","Harqalah","36.0333","10.5000","Tunisia","TN","TUN","Sousse","","9343","1788657831"
"Nāgāyalanka","Nagayalanka","15.9500","80.9167","India","IN","IND","Andhra Pradesh","","9321","1356059233"
"Donabagatta","Donabagatta","13.8951","75.7015","India","IN","IND","Karnātaka","","8970","1356007597"
"Yanchep","Yanchep","-31.5500","115.6340","Australia","AU","AUS","Western Australia","","8859","1036796651"
"Esanai","Esanai","11.3004","78.8314","India","IN","IND","Tamil Nādu","","8941","1356096306"
"Adolfo Gonzáles Chaves","Adolfo Gonzales Chaves","-38.0333","-60.0833","Argentina","AR","ARG","Buenos Aires","","9437","1032667376"
"Pandino","Pandino","45.4000","9.5500","Italy","IT","ITA","Lombardy","","9035","1380267199"
"Incline Village","Incline Village","39.2639","-119.9455","United States","US","USA","Nevada","","9440","1840033809"
"Sirāli","Sirali","22.1519","77.0617","India","IN","IND","Madhya Pradesh","","9212","1356410453"
"Niesky","Niesky","51.3000","14.8167","Germany","DE","DEU","Saxony","","9267","1276763097"
"Pryor Creek","Pryor Creek","36.2996","-95.3107","United States","US","USA","Oklahoma","","9439","1840037410"
"Lovosice","Lovosice","50.5151","14.0511","Czechia","CZ","CZE","Ústecký Kraj","","8715","1203138826"
"Raunds","Raunds","52.3450","-0.5370","United Kingdom","GB","GBR","Northamptonshire","","9379","1826048317"
"Braslaw","Braslaw","55.6391","27.0318","Belarus","BY","BLR","Vitsyebskaya Voblasts’","minor","9427","1112414592"
"Les Sorinières","Les Sorinieres","47.1483","-1.5294","France","FR","FRA","Pays de la Loire","","8765","1250275214"
"Borovskoy","Borovskoy","53.7964","64.1894","Kazakhstan","KZ","KAZ","","","9434","1398293067"
"Tissint","Tissint","29.9006","-7.3203","Morocco","MA","MAR","Souss-Massa","","9434","1504720693"
"Pizzo","Pizzo","38.7333","16.1667","Italy","IT","ITA","Calabria","","9329","1380718003"
"Bārun","Barun","24.8602","84.2232","India","IN","IND","Bihār","","8811","1356001279"
"Sīlaiyampatti","Silaiyampatti","9.8732","77.3927","India","IN","IND","Tamil Nādu","","8602","1356241713"
"Trebisacce","Trebisacce","39.8667","16.5333","Italy","IT","ITA","Calabria","","9095","1380416349"
"’s-Gravendeel","'s-Gravendeel","51.7833","4.6167","Netherlands","NL","NLD","Zuid-Holland","","9000","1528706910"
"Livron-sur-Drôme","Livron-sur-Drome","44.7728","4.8431","France","FR","FRA","Auvergne-Rhône-Alpes","","9202","1250746606"
"Kottapālem","Kottapalem","17.4360","82.4950","India","IN","IND","Andhra Pradesh","","9104","1356411863"
"Vochaïkó","Vochaiko","37.9333","22.8000","Greece","GR","GRC","Pelopónnisos","","9258","1300171316"
"Grigoriopol","Grigoriopol","47.1503","29.2925","Moldova","MD","MDA","Stînga Nistrului","","9381","1498783401"
"Cross Lanes","Cross Lanes","38.4351","-81.7707","United States","US","USA","West Virginia","","9434","1840006190"
"Kondūru","Konduru","16.6780","80.5730","India","IN","IND","Andhra Pradesh","","9148","1356404958"
"Maddūr","Maddur","16.8667","77.6167","India","IN","IND","Andhra Pradesh","","8896","1356331878"
"Ledbury","Ledbury","52.0339","-2.4235","United Kingdom","GB","GBR","Herefordshire","","9290","1826478885"
"Waiuku","Waiuku","-37.2490","174.7300","New Zealand","NZ","NZL","Auckland","","9171","1554334008"
"Teisendorf","Teisendorf","47.8500","12.8167","Germany","DE","DEU","Bavaria","","9325","1276609221"
"San Fructuoso de Bagés","San Fructuoso de Bages","41.7507","1.8727","Spain","ES","ESP","Catalonia","","9025","1724643327"
"Kurichchi","Kurichchi","11.7276","78.4102","India","IN","IND","Tamil Nādu","","8878","1356225104"
"Moḩammad Yār","Mohammad Yar","36.9831","45.5239","Iran","IR","IRN","Āz̄arbāyjān-e Gharbī","","9313","1364495024"
"Huinca Renancó","Huinca Renanco","-34.8333","-64.3833","Argentina","AR","ARG","Córdoba","","9426","1032637133"
"Mooresville","Mooresville","39.6022","-86.3681","United States","US","USA","Indiana","","9429","1840009668"
"Laufenburg (Baden)","Laufenburg (Baden)","47.5656","8.0647","Germany","DE","DEU","Baden-Württemberg","","9045","1276283641"
"Le Muy","Le Muy","43.4725","6.5664","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9288","1250812902"
"Turín","Turin","13.9667","-89.8167","El Salvador","SV","SLV","Ahuachapán","","8997","1222996582"
"Abra Pampa","Abra Pampa","-22.7167","-65.7000","Argentina","AR","ARG","Jujuy","minor","9425","1032997640"
"Teghra","Teghra","26.4929","86.3680","India","IN","IND","Bihār","","8605","1356440330"
"Wunsiedel","Wunsiedel","50.0167","12.0167","Germany","DE","DEU","Bavaria","minor","9259","1276110549"
"Alma","Alma","43.3799","-84.6556","United States","US","USA","Michigan","","9426","1840002879"
"Dunblane","Dunblane","56.1838","-3.9674","United Kingdom","GB","GBR","Stirling","","9410","1826445077"
"Bad Laer","Bad Laer","52.1031","8.0892","Germany","DE","DEU","Lower Saxony","","9228","1276926087"
"Catral","Catral","38.1594","-0.8050","Spain","ES","ESP","Valencia","","8976","1724794374"
"San Sebastián de la Gomera","San Sebastian de la Gomera","28.0922","-17.1100","Spain","ES","ESP","Canary Islands","","9342","1724034121"
"Castelli","Castelli","-25.9500","-60.6167","Argentina","AR","ARG","Chaco","minor","9421","1032193694"
"Country Club Estates","Country Club Estates","31.2113","-81.4622","United States","US","USA","Georgia","","9423","1840029452"
"Alfredo Wagner","Alfredo Wagner","-27.7000","-49.3339","Brazil","BR","BRA","Santa Catarina","","9410","1076900452"
"Santa Maria","Santa Maria","-24.9389","-51.8628","Brazil","BR","BRA","Paraná","","9410","1076001115"
"Būdamangalam","Budamangalam","12.3792","79.2308","India","IN","IND","Tamil Nādu","","8755","1356776067"
"Montes Altos","Montes Altos","-5.8308","-47.0669","Brazil","BR","BRA","Maranhão","","9413","1076887394"
"Orivesi","Orivesi","61.6750","24.3583","Finland","FI","FIN","Pirkanmaa","minor","9408","1246191762"
"Mudhol","Mudhol","15.6406","75.9531","India","IN","IND","Karnātaka","","9200","1356934677"
"Gammasa","Gammasa","31.4182","-8.3784","Morocco","MA","MAR","Marrakech-Safi","","9388","1504310079"
"Varzedo","Varzedo","-12.9708","-39.3939","Brazil","BR","BRA","Bahia","","9363","1076982829"
"Dehri","Dehri","25.4252","83.8201","India","IN","IND","Bihār","","8800","1356505368"
"Diamondhead","Diamondhead","30.3791","-89.3707","United States","US","USA","Mississippi","","9417","1840013106"
"Greenville","Greenville","40.9986","-73.8189","United States","US","USA","New York","","9417","1840133415"
"Havre","Havre","48.5427","-109.6804","United States","US","USA","Montana","","9417","1840019758"
"Cistérniga","Cisterniga","41.6167","-4.6833","Spain","ES","ESP","Castille-Leon","","9129","1724893689"
"Spáta","Spata","37.9667","23.9167","Greece","GR","GRC","Attikí","minor","9198","1300805467"
"Negrete","Negrete","-37.5833","-72.5333","Chile","CL","CHL","Biobío","","9394","1152930662"
"Krasnoslobodsk","Krasnoslobodsk","54.4333","43.8000","Russia","RU","RUS","Mordoviya","","9397","1643898157"
"San Ignacio","San Ignacio","9.7997","-84.1630","Costa Rica","CR","CRI","San José","","9016","1188539770"
"Bethalto","Bethalto","38.9015","-90.0466","United States","US","USA","Illinois","","9414","1840012789"
"Pūttai","Puttai","11.8957","78.8874","India","IN","IND","Tamil Nādu","","9065","1356245640"
"Sa Pa","Sa Pa","22.3356","103.8419","Vietnam","VN","VNM","Lào Cai","minor","9412","1704426002"
"Mutis","Mutis","6.2167","-77.4000","Colombia","CO","COL","Chocó","minor","9400","1170271802"
"Savenay","Savenay","47.3611","-1.9419","France","FR","FRA","Pays de la Loire","","9064","1250779421"
"Mendig","Mendig","50.3744","7.2808","Germany","DE","DEU","Rhineland-Palatinate","","9032","1276853202"
"Aniva","Aniva","46.7167","142.5167","Russia","RU","RUS","Sakhalinskaya Oblast’","","9405","1643419980"
"Amtala","Amtala","26.1000","91.3900","India","IN","IND","Assam","","8854","1356341147"
"Santa Comba","Santa Comba","43.0383","-8.8142","Spain","ES","ESP","Galicia","","9365","1724258894"
"Stevenston","Stevenston","55.6450","-4.7580","United Kingdom","GB","GBR","North Ayrshire","","9230","1826555115"
"Lidzbark","Lidzbark","53.2603","19.8211","Poland","PL","POL","Warmińsko-Mazurskie","","9351","1616879754"
"Sorala","Sorala","19.1492","84.7025","India","IN","IND","Odisha","","8811","1356669352"
"Gouvieux","Gouvieux","49.1878","2.4161","France","FR","FRA","Hauts-de-France","","9023","1250093809"
"Treia","Treia","43.3114","13.3131","Italy","IT","ITA","Marche","","9309","1380389131"
"Gangapatnam","Gangapatnam","14.5237","80.1521","India","IN","IND","Andhra Pradesh","","8867","1356991878"
"Duvvūru","Duvvuru","14.5507","79.8331","India","IN","IND","Andhra Pradesh","","8844","1356094344"
"Nārāyanpur","Narayanpur","17.8589","76.9733","India","IN","IND","Karnātaka","","9152","1356094667"
"Laghzawna","Laghzawna","33.1890","-7.6840","Morocco","MA","MAR","Casablanca-Settat","","9315","1504002065"
"Sandy Hook","Sandy Hook","41.4128","-73.2440","United States","US","USA","Connecticut","","9406","1840044711"
"Velampatti","Velampatti","10.2311","78.2294","India","IN","IND","Tamil Nādu","","9080","1356224792"
"Bridge City","Bridge City","30.0298","-93.8406","United States","US","USA","Texas","","9405","1840019609"
"Le Rheu","Le Rheu","48.1019","-1.7956","France","FR","FRA","Bretagne","","8932","1250291417"
"Nandnāwān","Nandnawan","25.0785","86.0960","India","IN","IND","Bihār","","8644","1356083295"
"Ameskroud","Ameskroud","30.5308","-9.3283","Morocco","MA","MAR","Souss-Massa","","9351","1504251726"
"Verkhneyarkeyevo","Verkhneyarkeyevo","55.4458","54.3168","Russia","RU","RUS","Bashkortostan","","9384","1643594058"
"Yenmangandla","Yenmangandla","16.8839","78.0332","India","IN","IND","Andhra Pradesh","","9130","1356105542"
"La Bruyère","La Bruyere","50.5000","4.8000","Belgium","BE","BEL","Wallonia","","9226","1056215360"
"Hallstadt","Hallstadt","49.9333","10.8833","Germany","DE","DEU","Bavaria","","8795","1276896189"
"Kandel","Kandel","49.0833","8.2000","Germany","DE","DEU","Rhineland-Palatinate","","9061","1276402753"
"Melpanaikkādu","Melpanaikkadu","10.2599","79.1169","India","IN","IND","Tamil Nādu","","8886","1356232290"
"Morubāgalu","Morubagalu","13.9702","77.0411","India","IN","IND","Andhra Pradesh","","9152","1356089324"
"Schlangen","Schlangen","51.8167","8.8331","Germany","DE","DEU","North Rhine-Westphalia","","9276","1276494835"
"Lower Swatara","Lower Swatara","40.2188","-76.7602","United States","US","USA","Pennsylvania","","9398","1840149542"
"Muro del Alcoy","Muro del Alcoy","38.7797","-0.4361","Spain","ES","ESP","Valencia","","9326","1724540936"
"Porcari","Porcari","43.8415","10.6163","Italy","IT","ITA","Tuscany","","8904","1380724366"
"Madeira","Madeira","39.1856","-84.3734","United States","US","USA","Ohio","","9397","1840001605"
"Helsinge","Helsinge","56.0222","12.1972","Denmark","DK","DNK","Hovedstaden","minor","8616","1208143944"
"Närpes","Narpes","62.4667","21.3333","Finland","FI","FIN","Pohjanmaa","minor","9387","1246805566"
"Sæby","Saeby","57.3294","10.5322","Denmark","DK","DNK","Nordjylland","","8841","1208138927"
"St. Stephens","St. Stephens","35.7642","-81.2746","United States","US","USA","North Carolina","","9396","1840033023"
"Biblis","Biblis","49.6841","8.4508","Germany","DE","DEU","Hesse","","9169","1276443433"
"Santa María de Cayón","Santa Maria de Cayon","43.3114","-3.8525","Spain","ES","ESP","Cantabria","","9205","1724357106"
"Lamorlaye","Lamorlaye","49.1550","2.4408","France","FR","FRA","Hauts-de-France","","8823","1250654836"
"Tysmenytsia","Tysmenytsia","48.9008","24.8492","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","9287","1804289789"
"Sibilia","Sibilia","15.0000","-91.6167","Guatemala","GT","GTM","Quetzaltenango","minor","9071","1320074499"
"Cavalcante","Cavalcante","-13.7978","-47.4578","Brazil","BR","BRA","Goiás","","9392","1076922025"
"Pfedelbach","Pfedelbach","49.1750","9.5056","Germany","DE","DEU","Baden-Württemberg","","9171","1276090389"
"Le Mont-sur-Lausanne","Le Mont-sur-Lausanne","46.5217","6.6269","Switzerland","CH","CHE","Vaud","","8523","1756684739"
"Tonneins","Tonneins","44.3897","0.3083","France","FR","FRA","Nouvelle-Aquitaine","","9130","1250168219"
"Séné","Sene","47.6197","-2.7372","France","FR","FRA","Bretagne","","8946","1250595127"
"Köse","Kose","40.2100","39.6511","Turkey","TR","TUR","Gümüşhane","minor","9387","1792284573"
"Kod","Kod","22.8850","75.1862","India","IN","IND","Madhya Pradesh","","9105","1356585675"
"Bovalino Marina","Bovalino Marina","38.1500","16.1667","Italy","IT","ITA","Calabria","","8898","1380976160"
"Columbia City","Columbia City","41.1612","-85.4855","United States","US","USA","Indiana","","9390","1840007140"
"Denham Springs","Denham Springs","30.4743","-90.9593","United States","US","USA","Louisiana","","9389","1840015027"
"Tāla","Tala","24.3735","81.1989","India","IN","IND","Madhya Pradesh","","9055","1356096235"
"Douar Ezzerarda","Douar Ezzerarda","34.7667","-5.8333","Morocco","MA","MAR","Rabat-Salé-Kénitra","","9249","1504537386"
"Sompting","Sompting","50.8303","-0.3395","United Kingdom","GB","GBR","West Sussex","","8561","1826847818"
"Chaponost","Chaponost","45.7103","4.7422","France","FR","FRA","Auvergne-Rhône-Alpes","","8846","1250597026"
"Gūgi","Gugi","16.7292","76.7469","India","IN","IND","Karnātaka","","9140","1356203125"
"Chesapeake Ranch Estates","Chesapeake Ranch Estates","38.3574","-76.4147","United States","US","USA","Maryland","","9387","1840026723"
"Laurentian Valley","Laurentian Valley","45.7681","-77.2239","Canada","CA","CAN","Ontario","","9387","1124000736"
"Sabiñánigo","Sabinanigo","42.5186","-0.3643","Spain","ES","ESP","Aragon","","9371","1724358375"
"Savādi","Savadi","15.6469","75.6758","India","IN","IND","Karnātaka","","9159","1356627378"
"Kolkwitz","Kolkwitz","51.7496","14.2457","Germany","DE","DEU","Brandenburg","","9297","1276591838"
"San Lorenzo","San Lorenzo","14.0333","-89.7833","El Salvador","SV","SLV","Ahuachapán","","9194","1222167385"
"Chinna Kalaiyamputtūr","Chinna Kalaiyamputtur","10.4714","77.4657","India","IN","IND","Tamil Nādu","","8933","1356172085"
"Countryside","Countryside","39.0518","-77.4124","United States","US","USA","Virginia","","9384","1840024500"
"University of Virginia","University of Virginia","38.0405","-78.5164","United States","US","USA","Virginia","","9384","1840140574"
"Coccaglio","Coccaglio","45.5633","9.9783","Italy","IT","ITA","Lombardy","","8664","1380308103"
"Curepto","Curepto","-35.0833","-72.0167","Chile","CL","CHL","Maule","","9380","1152721174"
"Hirehalli","Hirehalli","14.3200","76.6400","India","IN","IND","Karnātaka","","9181","1356830404"
"Lempdes","Lempdes","45.7711","3.1936","France","FR","FRA","Auvergne-Rhône-Alpes","","8677","1250163657"
"Pedda Muppāram","Pedda Mupparam","17.4792","79.6817","India","IN","IND","Andhra Pradesh","","9071","1356056457"
"Ifigha","Ifigha","36.6667","4.4167","Algeria","DZ","DZA","Tizi Ouzou","","9160","1012938624"
"Rāmpatti","Rampatti","26.0028","86.8014","India","IN","IND","Bihār","","8514","1356920465"
"Irshava","Irshava","48.3172","23.0375","Ukraine","UA","UKR","Zakarpatska Oblast","minor","9276","1804188148"
"Igaratá","Igarata","-23.2044","-46.1561","Brazil","BR","BRA","São Paulo","","9349","1076600029"
"Ouled Rached","Ouled Rached","36.2119","4.1106","Algeria","DZ","DZA","Bouira","","9311","1012727170"
"Rochelle","Rochelle","41.9197","-89.0629","United States","US","USA","Illinois","","9380","1840009179"
"Açucena","Acucena","-19.0728","-42.5458","Brazil","BR","BRA","Minas Gerais","","9368","1076910841"
"Borio","Borio","25.0344","87.5925","India","IN","IND","Jhārkhand","","8939","1356109772"
"West Glens Falls","West Glens Falls","43.3057","-73.6964","United States","US","USA","New York","","9379","1840004184"
"Navappatti","Navappatti","11.7393","77.7836","India","IN","IND","Tamil Nādu","","8718","1356168026"
"Neginhāl","Neginhal","15.7900","74.7500","India","IN","IND","Karnātaka","","8993","1356115803"
"Tettu","Tettu","13.6275","78.4322","India","IN","IND","Andhra Pradesh","","9121","1356846057"
"Dardilly","Dardilly","45.8056","4.7531","France","FR","FRA","Auvergne-Rhône-Alpes","","8752","1250475669"
"Avabodji","Avabodji","6.4533","2.5275","Benin","BJ","BEN","Ouémé","","8668","1204918574"
"Mīrjāveh","Mirjaveh","29.0147","61.4500","Iran","IR","IRN","Sīstān va Balūchestān","","9359","1364020318"
"Sherborne","Sherborne","50.9469","-2.5171","United Kingdom","GB","GBR","Dorset","","9350","1826383149"
"Kargat","Kargat","55.1956","80.2811","Russia","RU","RUS","Novosibirskaya Oblast’","","9370","1643914194"
"Currumbin","Currumbin","-28.1580","153.4690","Australia","AU","AUS","Queensland","","9121","1036194178"
"Le Petit-Couronne","Le Petit-Couronne","49.3856","1.0275","France","FR","FRA","Normandie","","8693","1250318903"
"Munagāla","Munagala","17.0500","79.8333","India","IN","IND","Andhra Pradesh","","8942","1356708472"
"East St. Paul","East St. Paul","49.9772","-97.0103","Canada","CA","CAN","Manitoba","","9372","1124000695"
"Edelény","Edeleny","48.2967","20.7442","Hungary","HU","HUN","Borsod-Abaúj-Zemplén","minor","9209","1348035547"
"Kurichedu","Kurichedu","15.9026","79.5773","India","IN","IND","Andhra Pradesh","","9027","1356156113"
"Folignano","Folignano","42.8167","13.6333","Italy","IT","ITA","Marche","","9182","1380634465"
"La Jigua","La Jigua","15.0333","-88.8000","Honduras","HN","HND","Copán","","9288","1340297828"
"La Fare-les-Oliviers","La Fare-les-Oliviers","43.5517","5.1947","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8744","1250000893"
"Belakvādi","Belakvadi","12.2550","77.1225","India","IN","IND","Karnātaka","","9151","1356649537"
"Gudivāda","Gudivada","17.3974","82.7908","India","IN","IND","Andhra Pradesh","","8791","1356132210"
"Slateng Dua","Slateng Dua","-8.1324","113.9836","Indonesia","ID","IDN","Jawa Timur","minor","8630","1360733120"
"Kruszwica","Kruszwica","52.6772","18.3292","Poland","PL","POL","Kujawsko-Pomorskie","","9288","1616885548"
"Riacho dos Machados","Riacho dos Machados","-16.0058","-43.0489","Brazil","BR","BRA","Minas Gerais","","9360","1076541307"
"Manhattan","Manhattan","41.4274","-87.9805","United States","US","USA","Illinois","","9366","1840011490"
"Risaralda","Risaralda","5.1667","-75.7500","Colombia","CO","COL","Caldas","minor","9254","1170718416"
"Mound","Mound","44.9328","-93.6591","United States","US","USA","Minnesota","","9365","1840007828"
"Newmarket","Newmarket","43.0691","-70.9516","United States","US","USA","New Hampshire","","9365","1840055265"
"Aidlingen","Aidlingen","48.6792","8.8969","Germany","DE","DEU","Baden-Württemberg","","9024","1276002867"
"Warren","Warren","41.8434","-79.1444","United States","US","USA","Pennsylvania","","9363","1840003334"
"Lubuagan","Lubuagan","17.3500","121.1833","Philippines","PH","PHL","Kalinga","","9323","1608300636"
"Siteía","Siteia","35.2000","26.1000","Greece","GR","GRC","Kríti","minor","9348","1300275780"
"Heath","Heath","32.8439","-96.4674","United States","US","USA","Texas","","9362","1840020716"
"Richterich","Richterich","50.8086","6.0625","Germany","DE","DEU","North Rhine-Westphalia","","8701","1276526025"
"Prospect","Prospect","41.4993","-72.9761","United States","US","USA","Connecticut","","9361","1840035549"
"Sangam","Sangam","17.8958","79.5708","India","IN","IND","Andhra Pradesh","","8990","1356956619"
"Hamsāvaram","Hamsavaram","17.2938","82.4942","India","IN","IND","Andhra Pradesh","","8828","1356376565"
"Hāthāpur","Hathapur","26.5609","86.0247","India","IN","IND","Bihār","","8514","1356721754"
"Tecklenburg","Tecklenburg","52.2194","7.8125","Germany","DE","DEU","North Rhine-Westphalia","","9229","1276770196"
"Mosrāh","Mosrah","18.6155","77.9718","India","IN","IND","Andhra Pradesh","","8873","1356348952"
"Tvrdošín","Tvrdosin","49.3343","19.5548","Slovakia","SK","SVK","Žilina","minor","9195","1703880169"
"Kibaya","Kibaya","-5.3014","36.5825","Tanzania","TZ","TZA","Manyara","","8759","1834442033"
"Bābai Kalān","Babai Kalan","22.8313","78.6774","India","IN","IND","Madhya Pradesh","","9016","1356961150"
"Dowlatābād","Dowlatabad","35.2822","59.5197","Iran","IR","IRN","Khorāsān-e Raẕavī","minor","9329","1364947262"
"Jori Kalān","Jori Kalan","24.2045","84.8770","India","IN","IND","Jhārkhand","","8877","1356087707"
"Pesca","Pesca","5.5833","-73.0500","Colombia","CO","COL","Boyacá","minor","9322","1170369834"
"Mahāgaon","Mahagaon","17.5211","76.9150","India","IN","IND","Karnātaka","","8825","1356693245"
"Kopong","Kopong","-24.4686","25.8922","Botswana","BW","BWA","South East","","9320","1072664885"
"Longvic","Longvic","47.2878","5.0636","France","FR","FRA","Bourgogne-Franche-Comté","","8545","1250533380"
"Ingleshwār","Ingleshwar","16.5900","75.9600","India","IN","IND","Karnātaka","","9142","1356013567"
"Moka","Moka","-20.2190","57.4960","Mauritius","MU","MUS","Moka","","8846","1480573073"
"Florence","Florence","43.9916","-124.1063","United States","US","USA","Oregon","","9353","1840020008"
"Nová Paka","Nova Paka","50.4945","15.5151","Czechia","CZ","CZE","Liberecký Kraj","","9044","1203255589"
"Monteforte d’Alpone","Monteforte d'Alpone","45.4167","11.2833","Italy","IT","ITA","Veneto","","8917","1380147370"
"Lorraine","Lorraine","45.6833","-73.7833","Canada","CA","CAN","Quebec","","9352","1124001859"
"Isbergues","Isbergues","50.6233","2.4567","France","FR","FRA","Hauts-de-France","","8745","1250390152"
"Green Cove Springs","Green Cove Springs","29.9904","-81.6807","United States","US","USA","Florida","","9351","1840013999"
"Verkhneuralsk","Verkhneuralsk","53.8833","59.2500","Russia","RU","RUS","Chelyabinskaya Oblast’","","9340","1643635523"
"Podenzano","Podenzano","44.9500","9.6833","Italy","IT","ITA","Emilia-Romagna","","9144","1380896533"
"Crestline","Crestline","34.2486","-117.2887","United States","US","USA","California","","9350","1840017796"
"Hirehāluhosahalli","Hirehaluhosahalli","15.0105","76.8360","India","IN","IND","Andhra Pradesh","","8996","1356305689"
"Haiku-Pauwela","Haiku-Pauwela","20.9156","-156.3022","United States","US","USA","Hawaii","","9347","1840074990"
"Ariranha","Ariranha","-21.1878","-48.7869","Brazil","BR","BRA","São Paulo","","9277","1076878901"
"Khotyn","Khotyn","48.5078","26.4860","Ukraine","UA","UKR","Chernivetska Oblast","minor","9262","1804000263"
"Nagykálló","Nagykallo","47.8833","21.8500","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","9214","1348262569"
"Riverside","Riverside","41.8310","-87.8169","United States","US","USA","Illinois","","9346","1840011287"
"Quirino","Quirino","17.1383","120.6764","Philippines","PH","PHL","Ilocos Sur","","9306","1608682755"
"Ipiranga do Piauí","Ipiranga do Piaui","-6.8278","-41.7408","Brazil","BR","BRA","Piauí","","9327","1076619598"
"Merrill","Merrill","45.1820","-89.6994","United States","US","USA","Wisconsin","","9344","1840001562"
"Ghāt Borūl","Ghat Borul","17.7700","77.1400","India","IN","IND","Karnātaka","","9067","1356067155"
"Segni","Segni","41.6833","13.0167","Italy","IT","ITA","Lazio","","9192","1380081575"
"Hillsborough","Hillsborough","36.0679","-79.0991","United States","US","USA","North Carolina","","9342","1840016151"
"Betania","Betania","5.7500","-75.9667","Colombia","CO","COL","Antioquia","minor","9286","1170782709"
"Nārsingi","Narsingi","18.0446","78.4254","India","IN","IND","Andhra Pradesh","","9014","1356838065"
"Werneuchen","Werneuchen","52.6333","13.7333","Germany","DE","DEU","Brandenburg","","9261","1276171183"
"Fort Stewart","Fort Stewart","31.8811","-81.6131","United States","US","USA","Georgia","","9340","1840073839"
"Grabels","Grabels","43.6481","3.8017","France","FR","FRA","Occitanie","","8798","1250310131"
"Kyritz","Kyritz","52.9500","12.4000","Germany","DE","DEU","Brandenburg","","9281","1276035237"
"Evergreen","Evergreen","39.6349","-105.3356","United States","US","USA","Colorado","","9338","1840017539"
"Willow Street","Willow Street","39.9810","-76.2706","United States","US","USA","Pennsylvania","","9338","1840005494"
"Vila Nova de Cerveira","Vila Nova de Cerveira","41.9667","-8.6833","Portugal","PT","PRT","Viana do Castelo","minor","9253","1620227274"
"Vendin-le-Vieil","Vendin-le-Vieil","50.4739","2.8661","France","FR","FRA","Hauts-de-France","","8543","1250343969"
"Gramsh","Gramsh","40.8667","20.1833","Albania","AL","ALB","Elbasan","","8440","1008632813"
"Telaprolu","Telaprolu","16.5860","80.8900","India","IN","IND","Andhra Pradesh","","8984","1356143966"
"Cherasco","Cherasco","44.6500","7.8667","Italy","IT","ITA","Piedmont","","9224","1380285683"
"Bojacá","Bojaca","4.7336","-74.3422","Colombia","CO","COL","Cundinamarca","minor","9256","1170070721"
"Sangaree","Sangaree","33.0327","-80.1253","United States","US","USA","South Carolina","","9336","1840035792"
"Ineu","Ineu","46.4258","21.8369","Romania","RO","ROU","Arad","","9260","1642186469"
"Koheda","Koheda","18.1709","79.0934","India","IN","IND","Andhra Pradesh","","9117","1356187490"
"Dodji-Bata","Dodji-Bata","6.6833","2.2833","Benin","BJ","BEN","Atlantique","","9119","1204452596"
"Nansio","Nansio","-2.1078","33.0864","Tanzania","TZ","TZA","Mara","","8644","1834001196"
"Porangaba","Porangaba","-23.1758","-48.1250","Brazil","BR","BRA","São Paulo","","9299","1076548805"
"Hohenhameln","Hohenhameln","52.2600","10.0664","Germany","DE","DEU","Lower Saxony","","9201","1276407587"
"Banārūyeh","Banaruyeh","28.0842","54.0483","Iran","IR","IRN","Fārs","","9318","1364350931"
"Ventnor City","Ventnor City","39.3457","-74.4860","United States","US","USA","New Jersey","","9333","1840001539"
"Yeldūrti","Yeldurti","17.9074","78.3721","India","IN","IND","Andhra Pradesh","","9090","1356605528"
"Kaleybar","Kaleybar","38.8664","47.0406","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","9324","1364048675"
"Lacchiarella","Lacchiarella","45.3250","9.1400","Italy","IT","ITA","Lombardy","","8959","1380890620"
"Sainte-Julienne","Sainte-Julienne","45.9700","-73.7200","Canada","CA","CAN","Quebec","","9331","1124086540"
"Binisalem","Binisalem","39.6831","2.8333","Spain","ES","ESP","Balearic Islands","","9027","1724936924"
"Rensselaer","Rensselaer","42.6465","-73.7328","United States","US","USA","New York","","9330","1840000394"
"Hyrum","Hyrum","41.6325","-111.8445","United States","US","USA","Utah","","9330","1840018699"
"Tirano","Tirano","46.2164","10.1689","Italy","IT","ITA","Lombardy","","9050","1380191925"
"Kot Umachigi","Kot Umachigi","15.5426","75.7710","India","IN","IND","Karnātaka","","9101","1356761018"
"Seydunganallūr","Seydunganallur","8.6624","77.8293","India","IN","IND","Tamil Nādu","","8983","1356481817"
"Masandra","Masandra","44.5167","34.1833","Ukraine","UA","UKR","Krym, Avtonomna Respublika","","8571","1804841893"
"Concepcion","Concepcion","8.4167","123.6000","Philippines","PH","PHL","Misamis Occidental","","9324","1608761536"
"Shimizu","Shimizu","43.0113","142.8846","Japan","JP","JPN","Hokkaidō","","9305","1392903163"
"Ecorse","Ecorse","42.2489","-83.1399","United States","US","USA","Michigan","","9328","1840003972"
"Blackfalds","Blackfalds","52.3833","-113.8000","Canada","CA","CAN","Alberta","","9328","1124056144"
"Adaklı","Adakli","39.2308","40.4781","Turkey","TR","TUR","Bingöl","minor","9315","1792013897"
"Tirumalaippatti","Tirumalaippatti","11.3353","78.2194","India","IN","IND","Tamil Nādu","","8845","1356233075"
"Yvoir","Yvoir","50.3333","4.8833","Belgium","BE","BEL","Wallonia","","9163","1056417528"
"Belousovo","Belousovo","55.0833","36.6667","Russia","RU","RUS","Kaluzhskaya Oblast’","","9288","1643537529"
"Reggiolo","Reggiolo","44.9167","10.8167","Italy","IT","ITA","Emilia-Romagna","","9110","1380617164"
"Velakalnattam","Velakalnattam","12.5505","78.4429","India","IN","IND","Tamil Nādu","","8735","1356155564"
"Altınyayla","Altinyayla","39.2725","36.7511","Turkey","TR","TUR","Sivas","minor","9309","1792888799"
"Hatti Mattūr","Hatti Mattur","14.9435","75.4239","India","IN","IND","Karnātaka","","9014","1356765054"
"Dores de Campos","Dores de Campos","-21.1089","-44.0228","Brazil","BR","BRA","Minas Gerais","","9299","1076670451"
"Nakhon Thai","Nakhon Thai","17.1011","100.8296","Thailand","TH","THA","Phitsanulok","minor","9278","1764255388"
"Manzanares el Real","Manzanares el Real","40.7272","-3.8611","Spain","ES","ESP","Madrid","","9247","1724862463"
"Borgo a Buggiano","Borgo a Buggiano","43.8833","10.7333","Italy","IT","ITA","Tuscany","","8772","1380767986"
"Landquart","Landquart","46.9688","9.5503","Switzerland","CH","CHE","Graubünden","","8849","1756905419"
"Sint-Martens-Lennik","Sint-Martens-Lennik","50.8000","4.1500","Belgium","BE","BEL","Flanders","","9024","1056514548"
"Caluco","Caluco","13.7167","-89.6500","El Salvador","SV","SLV","Sonsonate","","9139","1222488164"
"Skwierzyna","Skwierzyna","52.6000","15.5000","Poland","PL","POL","Lubuskie","","9272","1616286690"
"Kirrāyach","Kirrayach","26.6322","78.3423","India","IN","IND","Madhya Pradesh","","8768","1356816838"
"San Jorge","San Jorge","13.6833","-88.3833","El Salvador","SV","SLV","San Miguel","","9115","1222915945"
"Inveruno","Inveruno","45.5167","8.8500","Italy","IT","ITA","Lombardy","","8605","1380560727"
"Panjampatti","Panjampatti","10.3178","77.9263","India","IN","IND","Tamil Nādu","","8619","1356242537"
"Kūnimedu","Kunimedu","12.0885","79.8940","India","IN","IND","Tamil Nādu","","8779","1356763485"
"Kadganchi","Kadganchi","17.4437","76.6595","India","IN","IND","Karnātaka","","9106","1356650152"
"Khetko","Khetko","23.7554","85.9122","India","IN","IND","Jhārkhand","","8850","1356192985"
"Dhobipet","Dhobipet","17.4716","78.0870","India","IN","IND","Andhra Pradesh","","8803","1356278031"
"Miryal","Miryal","17.5661","78.8095","India","IN","IND","Andhra Pradesh","","9110","1356371147"
"Hualañe","Hualane","-34.9765","-71.8048","Chile","CL","CHL","Maule","","9303","1152879287"
"Mechanicsburg","Mechanicsburg","40.2115","-77.0060","United States","US","USA","Pennsylvania","","9310","1840001388"
"Lansdowne","Lansdowne","39.2365","-76.6659","United States","US","USA","Maryland","","9310","1840026625"
"Woodway","Woodway","31.4988","-97.2314","United States","US","USA","Texas","","9310","1840022142"
"Koshanam","Koshanam","11.3701","77.3523","India","IN","IND","Tamil Nādu","","8688","1356163322"
"Dāmal","Damal","12.8860","79.5922","India","IN","IND","Tamil Nādu","","8755","1356974481"
"Bad Liebenwerda","Bad Liebenwerda","51.5167","13.4000","Germany","DE","DEU","Brandenburg","","9242","1276044120"
"Mels","Mels","47.0497","9.4167","Switzerland","CH","CHE","Sankt Gallen","","9242","1756869318"
"Plymouth","Plymouth","42.3718","-83.4680","United States","US","USA","Michigan","","9308","1840003959"
"Mīlājerd","Milajerd","34.6219","49.2078","Iran","IR","IRN","Markazī","","9288","1364310563"
"Clermont-l’Hérault","Clermont-l'Herault","43.6272","3.4322","France","FR","FRA","Occitanie","","9029","1250938545"
"San José La Arada","San Jose La Arada","14.7167","-89.5833","Guatemala","GT","GTM","Chiquimula","minor","9249","1320756927"
"Cinderford","Cinderford","51.8225","-2.4989","United Kingdom","GB","GBR","Gloucestershire","","8777","1826291167"
"Igaratinga","Igaratinga","-19.9550","-44.7089","Brazil","BR","BRA","Minas Gerais","","9264","1076729313"
"Magdalena","Magdalena","-35.0833","-57.5167","Argentina","AR","ARG","Buenos Aires","minor","9294","1032068018"
"Paula Cândido","Paula Candido","-20.8739","-42.9800","Brazil","BR","BRA","Minas Gerais","","9271","1076748118"
"Sheffield","Sheffield","34.7570","-87.6977","United States","US","USA","Alabama","","9305","1840015537"
"Highland Park","Highland Park","42.4052","-83.0977","United States","US","USA","Michigan","","9304","1840003982"
"Terra Nova","Terra Nova","-8.2300","-39.3758","Brazil","BR","BRA","Pernambuco","","9278","1076265057"
"Nittenau","Nittenau","49.2000","12.2667","Germany","DE","DEU","Bavaria","","9210","1276358281"
"Capilla del Señor","Capilla del Senor","-34.2833","-59.1000","Argentina","AR","ARG","Buenos Aires","minor","9244","1032168682"
"Lo Miranda","Lo Miranda","-34.1900","-70.8900","Chile","CL","CHL","Libertador General Bernardo O’Higgins","","9000","1152468313"
"Teolo","Teolo","45.3500","11.6667","Italy","IT","ITA","Veneto","","9014","1380263826"
"Heusden","Heusden","51.0281","3.8000","Belgium","BE","BEL","Flanders","","8688","1056423197"
"Velykyi Bychkiv","Velykyi Bychkiv","47.9714","24.0047","Ukraine","UA","UKR","Zakarpatska Oblast","","9255","1804273540"
"Seddouk Oufella","Seddouk Oufella","36.6061","4.6389","Algeria","DZ","DZA","Bejaïa","","8931","1012348181"
"Veľké Kapušany","Vel'ke Kapusany","48.5503","22.0847","Slovakia","SK","SVK","Košice","","9004","1703807609"
"Springs","Springs","41.0212","-72.1584","United States","US","USA","New York","","9301","1840005102"
"Guryongpo","Guryongpo","35.9833","129.5500","South Korea","KR","KOR","Gyeongbuk","minor","9099","1410226868"
"Srīrāmapuram","Sriramapuram","16.4071","80.2971","India","IN","IND","Andhra Pradesh","","8766","1356321128"
"Stăuceni","Stauceni","47.0875","28.8703","Moldova","MD","MDA","Chişinău","","8694","1498710305"
"Kiskunlacháza","Kiskunlachaza","47.2000","19.0167","Hungary","HU","HUN","Pest","","9201","1348557191"
"Winchester","Winchester","35.1898","-86.1075","United States","US","USA","Tennessee","","9299","1840015466"
"Fairview","Fairview","35.9815","-87.1291","United States","US","USA","Tennessee","","9299","1840013379"
"Augusta","Augusta","37.6955","-96.9919","United States","US","USA","Kansas","","9299","1840001675"
"Nong Kung Si","Nong Kung Si","16.6500","103.3000","Thailand","TH","THA","Kalasin","minor","9213","1764488443"
"Mahomet","Mahomet","40.1888","-88.3901","United States","US","USA","Illinois","","9297","1840012232"
"Corgao","Corgao","15.7096","73.7497","India","IN","IND","Goa","","8941","1356471169"
"Hohenbrunn","Hohenbrunn","48.0500","11.7000","Germany","DE","DEU","Bavaria","","8823","1276417376"
"Akālgarh","Akalgarh","30.8019","75.8582","India","IN","IND","Punjab","","8715","1356193315"
"Zörbig","Zorbig","51.6167","12.1167","Germany","DE","DEU","Saxony-Anhalt","","9216","1276066607"
"Chennayyanakote","Chennayyanakote","12.2719","75.9559","India","IN","IND","Karnātaka","","9168","1356137725"
"Yellanda","Yellanda","17.7929","79.5912","India","IN","IND","Andhra Pradesh","","8941","1356837630"
"Kambūr","Kambur","10.1599","78.3264","India","IN","IND","Tamil Nādu","","8866","1356214522"
"Borgosatollo","Borgosatollo","45.4761","10.2400","Italy","IT","ITA","Lombardy","","9271","1380498677"
"Washington","Washington","40.9884","-74.0636","United States","US","USA","New Jersey","","9293","1840151551"
"Malahide","Malahide","42.7928","-80.9361","Canada","CA","CAN","Ontario","","9292","1124001777"
"Saūmalköl","Saumalkol","53.2914","68.1094","Kazakhstan","KZ","KAZ","","minor","9288","1398118891"
"Molbergen","Molbergen","52.8667","7.9333","Germany","DE","DEU","Lower Saxony","","9201","1276536842"
"Urbach","Urbach","48.8133","9.5789","Germany","DE","DEU","Baden-Württemberg","","8863","1276165057"
"Baláo","Balao","-2.9100","-79.8100","Ecuador","EC","ECU","Guayas","","9220","1218137948"
"Moman Barodiya","Moman Barodiya","23.6042","76.3364","India","IN","IND","Madhya Pradesh","","9018","1356782394"
"Didymóteicho","Didymoteicho","41.3500","26.5000","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","9263","1300999441"
"Babadag","Babadag","44.8981","28.7419","Romania","RO","ROU","Tulcea","","9213","1642622503"
"Tanakoub","Tanakoub","35.1091","-5.4577","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9236","1504108752"
"Monte Rico","Monte Rico","-24.4514","-65.1667","Argentina","AR","ARG","Jujuy","","9167","1032801235"
"Carignano","Carignano","44.9069","7.6732","Italy","IT","ITA","Piedmont","","9274","1380791175"
"Philippeville","Philippeville","50.2000","4.5500","Belgium","BE","BEL","Wallonia","minor","9228","1056548707"
"Antônio Dias","Antonio Dias","-19.6528","-42.8719","Brazil","BR","BRA","Minas Gerais","","9275","1076595263"
"Axixá do Tocantins","Axixa do Tocantins","-5.6169","-47.7689","Brazil","BR","BRA","Tocantins","","9275","1076964042"
"Gonzaga","Gonzaga","44.9500","10.8167","Italy","IT","ITA","Lombardy","","9103","1380111111"
"Hünenberg","Hunenberg","47.1761","8.4264","Switzerland","CH","CHE","Zug","","8807","1756393402"
"Kirchberg","Kirchberg","47.4000","9.0333","Switzerland","CH","CHE","Sankt Gallen","","9072","1756912325"
"Quakertown","Quakertown","40.4398","-75.3455","United States","US","USA","Pennsylvania","","9285","1840001310"
"Gerstungen","Gerstungen","50.9625","10.0597","Germany","DE","DEU","Thuringia","","9165","1276563247"
"Tlahuiltepa","Tlahuiltepa","20.9233","-98.9497","Mexico","MX","MEX","Hidalgo","","9264","1484500392"
"Suwannaphum","Suwannaphum","15.6078","103.8000","Thailand","TH","THA","Roi Et","minor","9183","1764109943"
"Piliscsaba","Piliscsaba","47.6167","18.8333","Hungary","HU","HUN","Pest","","8772","1348996792"
"Varatanapalli","Varatanapalli","12.5828","78.3020","India","IN","IND","Tamil Nādu","","8788","1356102036"
"Pedda Pendyāla","Pedda Pendyala","17.9230","79.4085","India","IN","IND","Andhra Pradesh","","8977","1356952562"
"Kotabommāli","Kotabommali","18.5333","84.1667","India","IN","IND","Andhra Pradesh","","8941","1356990932"
"Herculândia","Herculandia","-22.0036","-50.3853","Brazil","BR","BRA","São Paulo","","9278","1076531050"
"Burscough","Burscough","53.5960","-2.8430","United Kingdom","GB","GBR","Lancashire","","9182","1826272901"
"Juruaia","Juruaia","-21.2528","-46.5769","Brazil","BR","BRA","Minas Gerais","","9238","1076409302"
"Pozo Almonte","Pozo Almonte","-20.2667","-69.8000","Chile","CL","CHL","Tarapacá","minor","9277","1152924094"
"Riverdale","Riverdale","41.1732","-112.0023","United States","US","USA","Utah","","9279","1840020131"
"Gnarrenburg","Gnarrenburg","53.3864","9.0050","Germany","DE","DEU","Lower Saxony","","9204","1276077814"
"Momchilgrad","Momchilgrad","41.5333","25.4167","Bulgaria","BG","BGR","Kardzhali","","9235","1100063207"
"Guttikonda","Guttikonda","16.4300","79.8340","India","IN","IND","Andhra Pradesh","","8931","1356145475"
"Mallāram","Mallaram","18.7540","78.1120","India","IN","IND","Andhra Pradesh","","8867","1356328309"
"Aljustrel","Aljustrel","37.9167","-8.1667","Portugal","PT","PRT","Beja","minor","9257","1620079386"
"Sucha Beskidzka","Sucha Beskidzka","49.7403","19.5886","Poland","PL","POL","Małopolskie","minor","8951","1616035021"
"Torgelow","Torgelow","53.6167","14.0000","Germany","DE","DEU","Mecklenburg-Western Pomerania","","9153","1276888250"
"Zhur","Zhur","42.1639","20.6211","Kosovo","XK","XKS","Prizren","","8927","1901520274"
"Horodenka","Horodenka","48.6675","25.5003","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","9113","1804663549"
"Westerland","Westerland","54.9100","8.3075","Germany","DE","DEU","Schleswig-Holstein","","9032","1276492039"
"Kanavāypatti","Kanavaypatti","10.1857","77.7347","India","IN","IND","Tamil Nādu","","8602","1356228711"
"Chikkerūr","Chikkerur","14.5326","75.3084","India","IN","IND","Karnātaka","","9002","1356558029"
"Fuldabrück","Fuldabruck","51.2667","9.4833","Germany","DE","DEU","Hesse","","8781","1276251915"
"Sucre","Sucre","5.9230","-73.7950","Colombia","CO","COL","Santander","minor","9256","1170154930"
"Zavyalovo","Zavyalovo","56.7922","53.3756","Russia","RU","RUS","Udmurtiya","","9243","1643096121"
"Santa María del Tule","Santa Maria del Tule","17.0465","-96.6363","Mexico","MX","MEX","Oaxaca","minor","8918","1484488353"
"Oulad Cherif","Oulad Cherif","31.7667","-7.7833","Morocco","MA","MAR","Marrakech-Safi","","9237","1504980361"
"Vignate","Vignate","45.5000","9.3667","Italy","IT","ITA","Lombardy","","9269","1380732370"
"Tummapūdi","Tummapudi","16.3780","80.6231","India","IN","IND","Andhra Pradesh","","8755","1356714402"
"Roanoke","Roanoke","33.0144","-97.2276","United States","US","USA","Texas","","9270","1840020641"
"Kawara","Kawara","14.0706","5.6715","Niger","NE","NER","Tahoua","","9064","1562281421"
"Tillaivilāgam","Tillaivilagam","10.4127","79.5547","India","IN","IND","Tamil Nādu","","8801","1356172372"
"Chotěboř","Chotebor","49.7208","15.6703","Czechia","CZ","CZE","Vysočina","","9103","1203957935"
"Neustadt","Neustadt","50.7333","11.7500","Germany","DE","DEU","Thuringia","","9022","1276606241"
"Sidi Lahsene","Sidi Lahsene","34.0999","-2.6219","Morocco","MA","MAR","Oriental","","9259","1504193134"
"Chala","Chala","-15.8519","-74.2500","Peru","PE","PER","Arequipa","","9240","1604729325"
"Stansted Mountfitchet","Stansted Mountfitchet","51.8980","0.1980","United Kingdom","GB","GBR","Essex","","8621","1826626668"
"Guararé","Guarare","7.8200","-80.2700","Panama","PA","PAN","Los Santos","minor","9255","1591892939"
"Frodsham","Frodsham","53.2950","-2.7250","United Kingdom","GB","GBR","Cheshire West and Chester","","9077","1826347011"
"Salto Grande","Salto Grande","-22.8928","-49.9858","Brazil","BR","BRA","São Paulo","","9223","1076969780"
"Altenbeken","Altenbeken","51.7667","8.9333","Germany","DE","DEU","North Rhine-Westphalia","","9147","1276435516"
"Biberist","Biberist","47.1828","7.5586","Switzerland","CH","CHE","Solothurn","","8567","1756746716"
"Sugar Grove","Sugar Grove","41.7759","-88.4481","United States","US","USA","Illinois","","9266","1840011355"
"Valky","Valky","49.8386","35.6217","Ukraine","UA","UKR","Kharkivska Oblast","","9235","1804819263"
"Maddūr","Maddur","13.2397","79.5653","India","IN","IND","Tamil Nādu","","8630","1356134675"
"Reshetylivka","Reshetylivka","49.5636","34.0763","Ukraine","UA","UKR","Poltavska Oblast","minor","9240","1804143252"
"Paredes de Coura","Paredes de Coura","41.9127","-8.5622","Portugal","PT","PRT","Viana do Castelo","minor","9198","1620660116"
"Chettiyapatti","Chettiyapatti","10.4240","77.8228","India","IN","IND","Tamil Nādu","","8622","1356247270"
"Tumberi","Tumberi","12.6986","78.5856","India","IN","IND","Tamil Nādu","","8657","1356983406"
"Rio del Mar","Rio del Mar","36.9607","-121.8807","United States","US","USA","California","","9264","1840028179"
"Dodda Siddavvanahalli","Dodda Siddavvanahalli","14.2200","76.4000","India","IN","IND","Karnātaka","","8931","1356096422"
"Palocabildo","Palocabildo","5.1333","-75.0333","Colombia","CO","COL","Tolima","minor","9120","1170027378"
"Gródek Nad Dunajcem","Grodek Nad Dunajcem","49.7333","20.7167","Poland","PL","POL","Małopolskie","","9155","1616484679"
"Kaniwāra","Kaniwara","22.2145","79.7366","India","IN","IND","Madhya Pradesh","","9019","1356542351"
"Pokrovsk","Pokrovsk","61.4833","129.1500","Russia","RU","RUS","Sakha (Yakutiya)","","9256","1643183370"
"Troina","Troina","37.7833","14.6000","Italy","IT","ITA","Sicilia","","9202","1380108043"
"Guatapé","Guatape","6.2333","-75.1667","Colombia","CO","COL","Antioquia","minor","9121","1170993383"
"Dirusumarru","Dirusumarru","16.4722","81.5295","India","IN","IND","Andhra Pradesh","","8645","1356550619"
"Rājod","Rajod","22.9501","75.0640","India","IN","IND","Madhya Pradesh","","8995","1356176693"
"Malalbergo","Malalbergo","44.7167","11.5333","Italy","IT","ITA","Emilia-Romagna","","9087","1380289270"
"Nizhniye Sergi","Nizhniye Sergi","56.6667","59.3167","Russia","RU","RUS","Sverdlovskaya Oblast’","","9243","1643721871"
"Zabok","Zabok","46.0333","15.9167","Croatia","HR","HRV","Krapinsko-Zagorska Županija","minor","8994","1191500303"
"St. Francis","St. Francis","42.9716","-87.8730","United States","US","USA","Wisconsin","","9254","1840003049"
"Falan","Falan","5.1333","-74.9500","Colombia","CO","COL","Tolima","minor","9204","1170115815"
"Southside","Southside","33.9007","-86.0238","United States","US","USA","Alabama","","9253","1840015604"
"Chillicothe","Chillicothe","39.7953","-93.5499","United States","US","USA","Missouri","","9253","1840007302"
"Melgaço","Melgaco","42.1167","-8.2667","Portugal","PT","PRT","Viana do Castelo","minor","9213","1620521790"
"Nato","Nato","-22.3000","47.8167","Madagascar","MG","MDG","Fianarantsoa","","9000","1450079364"
"Kelso","Kelso","-33.4186","149.6056","Australia","AU","AUS","New South Wales","","8968","1036270756"
"Laurens","Laurens","34.5022","-82.0207","United States","US","USA","South Carolina","","9251","1840014668"
"Forestdale","Forestdale","33.5737","-86.9000","United States","US","USA","Alabama","","9251","1840013723"
"Dunn Loring","Dunn Loring","38.8945","-77.2315","United States","US","USA","Virginia","","9251","1840006017"
"Kalanchak","Kalanchak","46.2581","33.2843","Ukraine","UA","UKR","Khersonska Oblast","minor","9224","1804573757"
"Fort Polk South","Fort Polk South","31.0512","-93.2159","United States","US","USA","Louisiana","","9248","1840073837"
"Ashland","Ashland","39.8782","-75.0085","United States","US","USA","New Jersey","","9248","1840033467"
"Ardooie","Ardooie","50.9667","3.1833","Belgium","BE","BEL","Flanders","","8988","1056959747"
"Zūlakallu","Zulakallu","16.4442","79.7874","India","IN","IND","Andhra Pradesh","","8760","1356497434"
"Lanivo","Lanivo","-22.2833","47.8167","Madagascar","MG","MDG","Fianarantsoa","","9000","1450578324"
"Le Thor","Le Thor","43.9292","4.9944","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8996","1250024311"
"Salzhemmendorf","Salzhemmendorf","52.0667","9.5833","Germany","DE","DEU","Lower Saxony","","9150","1276619297"
"Montoro","Montoro","38.0167","-4.3833","Spain","ES","ESP","Andalusia","","9231","1724147056"
"Pong Nam Ron","Pong Nam Ron","12.9057","102.2663","Thailand","TH","THA","Chanthaburi","minor","9210","1764739470"
"Zoeterwoude","Zoeterwoude","52.1333","4.5000","Netherlands","NL","NLD","Zuid-Holland","minor","8843","1528774166"
"Ambara","Ambara","22.1892","78.6903","India","IN","IND","Madhya Pradesh","","9097","1356784325"
"Naduvalūr","Naduvalur","11.4800","78.6500","India","IN","IND","Tamil Nādu","","8904","1356244943"
"Colorno","Colorno","44.9333","10.3833","Italy","IT","ITA","Emilia-Romagna","","9056","1380485958"
"Hosūru","Hosuru","15.3797","77.4598","India","IN","IND","Andhra Pradesh","","8978","1356348864"
"Abaíra","Abaira","-13.2500","-41.6639","Brazil","BR","BRA","Bahia","","9226","1076572454"
"Obukhivka","Obukhivka","48.5442","34.8664","Ukraine","UA","UKR","Dnipropetrovska Oblast","","9175","1804260181"
"Sūrak","Surak","36.5950","53.2075","Iran","IR","IRN","Māzandarān","minor","9208","1364175111"
"Rosário do Catete","Rosario do Catete","-10.6958","-37.0308","Brazil","BR","BRA","Sergipe","","9221","1076520504"
"Carneiros","Carneiros","-9.4828","-37.3769","Brazil","BR","BRA","Alagoas","","9159","1076881848"
"Oborniki Śląskie","Oborniki Slaskie","51.2986","16.9017","Poland","PL","POL","Dolnośląskie","","9101","1616398472"
"Une","Une","4.4019","-74.0250","Colombia","CO","COL","Cundinamarca","minor","9196","1170857775"
"Gholia Kalān","Gholia Kalan","30.6726","75.1800","India","IN","IND","Punjab","","8788","1356960884"
"Waseca","Waseca","44.0827","-93.5029","United States","US","USA","Minnesota","","9237","1840010030"
"Aliquippa","Aliquippa","40.6155","-80.2547","United States","US","USA","Pennsylvania","","9237","1840001009"
"Ādamī Tulu","Adami Tulu","7.8667","38.7000","Ethiopia","ET","ETH","Oromīya","","9034","1231102446"
"Aslanapa","Aslanapa","39.2167","29.8667","Turkey","TR","TUR","Kütahya","minor","9224","1792565930"
"Bahābād","Bahabad","31.8719","56.0239","Iran","IR","IRN","Yazd","","9232","1364142040"
"Honganūr","Honganur","11.9400","76.9500","India","IN","IND","Karnātaka","","8919","1356028338"
"Ziębice","Ziebice","50.6000","17.0333","Poland","PL","POL","Dolnośląskie","","9154","1616161642"
"Dahbed","Dahbed","39.7636","66.9131","Uzbekistan","UZ","UZB","Samarqand","","9000","1860321921"
"Nangis","Nangis","48.5550","3.0147","France","FR","FRA","Île-de-France","","8867","1250569293"
"Haram","Haram","62.6665","6.1894","Norway","NO","NOR","Møre og Romsdal","","9200","1578868176"
"Tummalacheruvu","Tummalacheruvu","17.7667","80.8000","India","IN","IND","Andhra Pradesh","","8889","1356592663"
"Āgadāllanka","Agadallanka","16.7123","81.2732","India","IN","IND","Andhra Pradesh","","8870","1356076247"
"Yaxley","Yaxley","52.5200","-0.2600","United Kingdom","GB","GBR","Cambridgeshire","","9174","1826156250"
"Saint-Paul-Trois-Châteaux","Saint-Paul-Trois-Chateaux","44.3489","4.7686","France","FR","FRA","Auvergne-Rhône-Alpes","","8836","1250594301"
"Segorbe","Segorbe","39.8519","-0.4896","Spain","ES","ESP","Valencia","","9146","1724929897"
"Saint-Claude","Saint-Claude","46.3872","5.8633","France","FR","FRA","Bourgogne-Franche-Comté","minor","9103","1250259526"
"Potangal","Potangal","18.5661","77.7742","India","IN","IND","Andhra Pradesh","","8903","1356926912"
"Saint-Philbert-de-Grand-Lieu","Saint-Philbert-de-Grand-Lieu","47.0350","-1.6400","France","FR","FRA","Pays de la Loire","","9076","1250093900"
"Dharmājigūdem","Dharmajigudem","16.9000","81.0000","India","IN","IND","Andhra Pradesh","","8564","1356763327"
"Freystadt","Freystadt","49.2000","11.3167","Germany","DE","DEU","Bavaria","","9116","1276684749"
"Douar Snada","Douar Snada","35.0667","-4.2167","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9176","1504701788"
"Kovvali","Kovvali","16.7333","81.1667","India","IN","IND","Andhra Pradesh","","8925","1356481075"
"Berndorf","Berndorf","47.9428","16.1036","Austria","AT","AUT","Niederösterreich","","9064","1040660595"
"Soresina","Soresina","45.2865","9.8570","Italy","IT","ITA","Lombardy","","8915","1380963622"
"Shawano","Shawano","44.7748","-88.5843","United States","US","USA","Wisconsin","","9227","1840002266"
"Montagnana","Montagnana","45.2333","11.4658","Italy","IT","ITA","Veneto","","9026","1380865759"
"Luzzi","Luzzi","39.4500","16.2833","Italy","IT","ITA","Calabria","","9206","1380195756"
"Hirayama","Hirayama","33.6467","130.5001","Japan","JP","JPN","Fukuoka","","8986","1392375726"
"Furth im Wald","Furth im Wald","49.3097","12.8400","Germany","DE","DEU","Bavaria","","9093","1276212241"
"Balvādi","Balvadi","21.4412","75.2230","India","IN","IND","Madhya Pradesh","","8924","1356037786"
"Moss Vale","Moss Vale","-34.5500","150.3833","Australia","AU","AUS","New South Wales","","8579","1036872626"
"I-n-Amenas","I-n-Amenas","28.0500","9.5500","Algeria","DZ","DZA","Illizi","","9225","1012221429"
"Vannikkonendal","Vannikkonendal","8.9959","77.6245","India","IN","IND","Tamil Nādu","","8857","1356209506"
"Stryzhavka","Stryzhavka","49.3103","28.4808","Ukraine","UA","UKR","Vinnytska Oblast","","9137","1804715447"
"Pásztó","Paszto","47.9202","19.7144","Hungary","HU","HUN","Nógrád","minor","9099","1348953784"
"Charlotte","Charlotte","42.5662","-84.8304","United States","US","USA","Michigan","","9224","1840003107"
"Roquevaire","Roquevaire","43.3494","5.6047","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8858","1250940023"
"Almargem","Almargem","38.8475","-9.2714","Portugal","PT","PRT","Lisboa","","8983","1620407856"
"Baluntaicun","Baluntaicun","42.7594","86.3231","China","CN","CHN","Xinjiang","","9220","1156540777"
"Golbāf","Golbaf","29.8850","57.7306","Iran","IR","IRN","Kermān","","9205","1364424557"
"Oromocto","Oromocto","45.8488","-66.4788","Canada","CA","CAN","New Brunswick","","9223","1124928183"
"Bassenge","Bassenge","50.7586","5.6086","Belgium","BE","BEL","Wallonia","","8986","1056702333"
"Ponte Buggianese","Ponte Buggianese","43.8408","10.7475","Italy","IT","ITA","Tuscany","","8919","1380856163"
"Flanders","Flanders","40.8412","-74.7102","United States","US","USA","New Jersey","","9221","1840033277"
"Burlington","Burlington","48.4676","-122.3298","United States","US","USA","Washington","","9220","1840018367"
"Harleysville","Harleysville","40.2792","-75.3873","United States","US","USA","Pennsylvania","","9219","1840005463"
"Vadacheri","Vadacheri","10.7324","78.5261","India","IN","IND","Tamil Nādu","","8698","1356207199"
"Lakhanāpuram","Lakhanapuram","18.7504","83.5534","India","IN","IND","Andhra Pradesh","","8904","1356063145"
"Serramanna","Serramanna","39.4228","8.9217","Italy","IT","ITA","Sardegna","","9110","1380463301"
"Nort-sur-Erdre","Nort-sur-Erdre","47.4394","-1.4983","France","FR","FRA","Pays de la Loire","","9082","1250578955"
"Mejillones","Mejillones","-23.1000","-70.4500","Chile","CL","CHL","Antofagasta","","9218","1152559509"
"Peresecina","Peresecina","47.2500","28.7667","Moldova","MD","MDA","Orhei","","9122","1498929769"
"Tarcento","Tarcento","46.2167","13.2167","Italy","IT","ITA","Friuli Venezia Giulia","","8964","1380332869"
"Chtiba","Chtiba","32.2000","-7.3000","Morocco","MA","MAR","Marrakech-Safi","","9122","1504264885"
"Washington Terrace","Washington Terrace","41.1683","-111.9783","United States","US","USA","Utah","","9215","1840021343"
"Monserrat","Monserrat","39.3575","-0.6031","Spain","ES","ESP","Valencia","","9017","1724732008"
"Allāhpur","Allahpur","26.3434","77.8195","India","IN","IND","Madhya Pradesh","","8887","1356017810"
"Sholaqqorghan","Sholaqqorghan","43.7650","69.1758","Kazakhstan","KZ","KAZ","","","9213","1398413637"
"Frankfort Square","Frankfort Square","41.5219","-87.8031","United States","US","USA","Illinois","","9214","1840029897"
"Perondi","Perondi","40.7833","19.9333","Albania","AL","ALB","Berat","","9005","1008411335"
"Krompachy","Krompachy","48.9139","20.8739","Slovakia","SK","SVK","Košice","","8828","1703996106"
"Beauraing","Beauraing","50.1092","4.9567","Belgium","BE","BEL","Wallonia","","9160","1056162320"
"Chécy","Checy","47.8936","2.0269","France","FR","FRA","Centre-Val de Loire","","8652","1250650175"
"Wanaka","Wanaka","-44.7000","169.1500","New Zealand","NZ","NZL","Otago","","8900","1554152950"
"San Antonio","San Antonio","12.4140","124.2790","Philippines","PH","PHL","Northern Samar","","8882","1608468978"
"Dalavāypattanam","Dalavaypattanam","10.6747","77.4845","India","IN","IND","Tamil Nādu","","8945","1356427380"
"Notre-Dame-de-Gravenchon","Notre-Dame-de-Gravenchon","49.4892","0.5711","France","FR","FRA","Normandie","","8744","1250706311"
"Maisaram","Maisaram","17.1329","78.4367","India","IN","IND","Andhra Pradesh","","8795","1356959971"
"Padakanti","Padakanti","18.6942","78.2842","India","IN","IND","Andhra Pradesh","","8874","1356006022"
"Eduttavāynattam","Eduttavaynattam","11.8057","78.8270","India","IN","IND","Tamil Nādu","","8653","1356243981"
"Canal Winchester","Canal Winchester","39.8437","-82.8126","United States","US","USA","Ohio","","9209","1840010524"
"Grants","Grants","35.1538","-107.8335","United States","US","USA","New Mexico","","9208","1840020452"
"O'Hara","O'Hara","40.5092","-79.8949","United States","US","USA","Pennsylvania","","9208","1840146056"
"Bhelsi","Bhelsi","24.7866","79.1281","India","IN","IND","Madhya Pradesh","","8945","1356551393"
"Trittau","Trittau","53.6167","10.4000","Germany","DE","DEU","Schleswig-Holstein","","8896","1276001552"
"Thorigné-Fouillard","Thorigne-Fouillard","48.1597","-1.5797","France","FR","FRA","Bretagne","","8575","1250274664"
"Fredensborg","Fredensborg","55.9750","12.4056","Denmark","DK","DNK","Hovedstaden","","8965","1208706314"
"Kanchanpalli","Kanchanpalli","17.7427","79.3128","India","IN","IND","Andhra Pradesh","","8987","1356156655"
"Borgoricco","Borgoricco","45.5167","11.9667","Italy","IT","ITA","Veneto","","8774","1380419285"
"Nānguneri","Nanguneri","8.4906","77.6588","India","IN","IND","Tamil Nādu","","8920","1356265096"
"Sidi Ouassay","Sidi Ouassay","30.0500","-9.6833","Morocco","MA","MAR","Souss-Massa","","9087","1504252264"
"Bellerive-sur-Allier","Bellerive-sur-Allier","46.1164","3.4042","France","FR","FRA","Auvergne-Rhône-Alpes","","8741","1250273920"
"Oak Hills Place","Oak Hills Place","30.3690","-91.0887","United States","US","USA","Louisiana","","9201","1840031107"
"L’Isle-Jourdain","L'Isle-Jourdain","43.6136","1.0808","France","FR","FRA","Occitanie","","9072","1250347605"
"San Manuel Chaparrón","San Manuel Chaparron","14.5167","-89.7667","Guatemala","GT","GTM","Jalapa","minor","9126","1320449306"
"Yeşilyurt","Yesilyurt","40.0000","36.2200","Turkey","TR","TUR","Tokat","minor","9154","1792677832"
"Rockingham","Rockingham","34.9386","-79.7608","United States","US","USA","North Carolina","","9200","1840014617"
"Nellutla","Nellutla","17.7034","79.1956","India","IN","IND","Andhra Pradesh","","8842","1356421569"
"Unterägeri","Unterageri","47.1386","8.5844","Switzerland","CH","CHE","Zug","","8867","1756124106"
"Furtwangen im Schwarzwald","Furtwangen im Schwarzwald","48.0503","8.2092","Germany","DE","DEU","Baden-Württemberg","","9091","1276132141"
"Bir Tam Tam","Bir Tam Tam","33.9833","-4.6333","Morocco","MA","MAR","Fès-Meknès","","9141","1504669372"
"Glanmire","Glanmire","51.9167","-8.4000","Ireland","IE","IRL","Cork","","8924","1372070147"
"Copceac","Copceac","45.8500","28.6944","Moldova","MD","MDA","Găgăuzia","","9138","1498642964"
"Wenzenbach","Wenzenbach","49.0747","12.1944","Germany","DE","DEU","Bavaria","","8900","1276234994"
"Pepillo Salcedo","Pepillo Salcedo","19.7000","-71.7500","Dominican Republic","DO","DOM","Cibao Noroeste","","9136","1214570640"
"Rivesaltes","Rivesaltes","42.7689","2.8739","France","FR","FRA","Occitanie","","8888","1250513963"
"Santo Domingo Petapa","Santo Domingo Petapa","16.8167","-95.1333","Mexico","MX","MEX","Oaxaca","","9157","1484805593"
"Nettādahalli","Nettadahalli","12.1330","78.0671","India","IN","IND","Tamil Nādu","","8572","1356663511"
"Lorgues","Lorgues","43.4933","6.3611","France","FR","FRA","Provence-Alpes-Côte d’Azur","","9054","1250683494"
"Gudlūru","Gudluru","15.0729","79.9012","India","IN","IND","Andhra Pradesh","","8989","1356940042"
"Flowery Branch","Flowery Branch","34.1712","-83.9142","United States","US","USA","Georgia","","9194","1840013611"
"Gretz-Armainvilliers","Gretz-Armainvilliers","48.7411","2.7342","France","FR","FRA","Île-de-France","","8566","1250226833"
"Olesno","Olesno","50.8750","18.4167","Poland","PL","POL","Opolskie","minor","9122","1616142985"
"Pike Road","Pike Road","32.2939","-86.0900","United States","US","USA","Alabama","","9193","1840017000"
"Vinjam","Vinjam","13.2544","79.2630","India","IN","IND","Andhra Pradesh","","8837","1356157126"
"Elsfleth","Elsfleth","53.2333","8.4667","Germany","DE","DEU","Lower Saxony","","9113","1276056093"
"Egg","Egg","47.3019","8.6906","Switzerland","CH","CHE","Zürich","","8596","1756058713"
"Blackstone","Blackstone","42.0399","-71.5313","United States","US","USA","Massachusetts","","9188","1840053687"
"Richmond Heights","Richmond Heights","38.6309","-90.3332","United States","US","USA","Missouri","","9188","1840009769"
"Bandamūrlanka","Bandamurlanka","16.5170","81.9880","India","IN","IND","Andhra Pradesh","","8576","1356370202"
"Amapá","Amapa","2.0528","-50.7928","Brazil","BR","BRA","Amapá","","9187","1076834242"
"Tibaná","Tibana","5.3167","-73.4000","Colombia","CO","COL","Boyacá","minor","9186","1170436338"
"Plön","Plon","54.1622","10.4214","Germany","DE","DEU","Schleswig-Holstein","minor","8943","1276527623"
"Fujisawachō-niinuma","Fujisawacho-niinuma","38.8585","141.3493","Japan","JP","JPN","Iwate","","9112","1392660085"
"Yel’sk","Yel'sk","51.8167","29.1500","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","9175","1112554124"
"Oggiono","Oggiono","45.7833","9.3500","Italy","IT","ITA","Lombardy","","9075","1380872370"
"Vanduvāncheri","Vanduvancheri","10.4292","79.6498","India","IN","IND","Tamil Nādu","","8797","1356172390"
"Lunner","Lunner","60.2528","10.6672","Norway","NO","NOR","Viken","","9144","1578195993"
"Neuhaus am Rennweg","Neuhaus am Rennweg","50.5167","11.1500","Germany","DE","DEU","Thuringia","","9076","1276404975"
"Consacá","Consaca","1.2000","-77.4667","Colombia","CO","COL","Nariño","minor","9099","1170808724"
"Nagaoki","Nagaoki","32.9781","130.6057","Japan","JP","JPN","Kumamoto","","9092","1392700206"
"Olds","Olds","51.7928","-114.1067","Canada","CA","CAN","Alberta","","9184","1124330412"
"Jerez de los Caballeros","Jerez de los Caballeros","38.3203","-6.7714","Spain","ES","ESP","Extremadura","","9171","1724015123"
"Kalicherla","Kalicherla","13.8833","78.5333","India","IN","IND","Andhra Pradesh","","8999","1356149475"
"Piriápolis","Piriapolis","-34.9000","-55.2833","Uruguay","UY","URY","Maldonado","","8830","1858127354"
"Mandurah","Mandurah","-32.5289","115.7231","Australia","AU","AUS","Western Australia","","7837","1036773118"
"Villamediana de Iregua","Villamediana de Iregua","42.4264","-2.4181","Spain","ES","ESP","La Rioja","","8745","1724390571"
"Maidencreek","Maidencreek","40.4618","-75.8927","United States","US","USA","Pennsylvania","","9182","1840103346"
"Mwaline al Oued","Mwaline al Oued","33.4467","-7.3283","Morocco","MA","MAR","Casablanca-Settat","","9129","1504001943"
"Kilānkundal","Kilankundal","10.7543","77.7269","India","IN","IND","Tamil Nādu","","8915","1356228632"
"Tullukuttināyakkanūr","Tullukuttinayakkanur","9.8149","77.6808","India","IN","IND","Tamil Nādu","","8902","1356213650"
"Kożuchów","Kozuchow","51.7500","15.6000","Poland","PL","POL","Lubuskie","","9086","1616495836"
"Zarbdor Shaharchasi","Zarbdor Shaharchasi","40.0747","68.1683","Uzbekistan","UZ","UZB","Jizzax","minor","9110","1860740472"
"Zogno","Zogno","45.7939","9.6656","Italy","IT","ITA","Lombardy","","8926","1380207081"
"Waldheim","Waldheim","51.0667","13.0167","Germany","DE","DEU","Saxony","","8964","1276028317"
"Mustafābād","Mustafabad","18.2787","78.7108","India","IN","IND","Andhra Pradesh","","8665","1356783833"
"Aratuípe","Aratuipe","-13.0789","-39.0019","Brazil","BR","BRA","Bahia","","9127","1076544809"
"Willowbrook","Willowbrook","41.7641","-87.9454","United States","US","USA","Illinois","","9178","1840011409"
"Żychlin","Zychlin","52.2453","19.6236","Poland","PL","POL","Łódzkie","","9021","1616508648"
"Janglot","Janglot","32.4353","75.5231","India","IN","IND","Jammu and Kashmīr","","8704","1356066409"
"Horokhiv","Horokhiv","50.4994","24.7650","Ukraine","UA","UKR","Volynska Oblast","","9134","1804715823"
"Rompicherla","Rompicherla","13.7228","79.0542","India","IN","IND","Andhra Pradesh","","8679","1356077722"
"Modachchūr","Modachchur","11.4415","77.4288","India","IN","IND","Tamil Nādu","","8554","1356163956"
"Palm Beach","Palm Beach","26.6945","-80.0408","United States","US","USA","Florida","","9175","1840017267"
"Rāmnagar","Ramnagar","32.8073","75.3112","India","IN","IND","Jammu and Kashmīr","","8944","1356403730"
"Neuötting","Neuotting","48.2167","12.6833","Germany","DE","DEU","Bavaria","","8932","1276928616"
"Banak","Banak","27.8722","52.0289","Iran","IR","IRN","Būshehr","","8753","1364681484"
"Çardak","Cardak","37.8247","29.6760","Turkey","TR","TUR","Denizli","minor","9144","1792787864"
"Beecher","Beecher","43.0903","-83.7039","United States","US","USA","Michigan","","9171","1840004343"
"Benton Harbor","Benton Harbor","42.1159","-86.4488","United States","US","USA","Michigan","","9171","1840003215"
"Dörverden","Dorverden","52.8500","9.2333","Germany","DE","DEU","Lower Saxony","","9062","1276082481"
"Timmendorfer Strand","Timmendorfer Strand","53.9944","10.7825","Germany","DE","DEU","Schleswig-Holstein","","8742","1276402888"
"Channubanda","Channubanda","17.0331","80.8056","India","IN","IND","Andhra Pradesh","","8907","1356115239"
"Petilia Policastro","Petilia Policastro","39.1167","16.7833","Italy","IT","ITA","Calabria","","9073","1380377680"
"Bundāla","Bundala","31.1429","75.6615","India","IN","IND","Punjab","","8569","1356035953"
"Bridgeport","Bridgeport","39.3036","-80.2478","United States","US","USA","West Virginia","","9165","1840005766"
"Mikhaylovka","Mikhaylovka","43.9283","132.0061","Russia","RU","RUS","Primorskiy Kray","","9153","1643131545"
"Memmelsdorf","Memmelsdorf","49.9328","10.9533","Germany","DE","DEU","Bavaria","","8828","1276224213"
"Le Teil","Le Teil","44.5453","4.6822","France","FR","FRA","Auvergne-Rhône-Alpes","","8832","1250047702"
"Tulbagh","Tulbagh","-33.2850","19.1378","South Africa","ZA","ZAF","Western Cape","","8969","1710401390"
"Tournan-en-Brie","Tournan-en-Brie","48.7406","2.7681","France","FR","FRA","Île-de-France","","8613","1250506668"
"Ortenberg","Ortenberg","50.3558","9.0553","Germany","DE","DEU","Hesse","","9001","1276546212"
"Abalessa","Abalessa","22.8900","4.8472","Algeria","DZ","DZA","Tamanrasset","","9163","1012479386"
"Borgholzhausen","Borgholzhausen","52.1000","8.3000","Germany","DE","DEU","North Rhine-Westphalia","","9001","1276557759"
"Chalgeri","Chalgeri","14.5652","75.7166","India","IN","IND","Karnātaka","","8807","1356543168"
"Verdejante","Verdejante","-7.9256","-38.9717","Brazil","BR","BRA","Pernambuco","","9142","1076829229"
"Elze","Elze","52.1167","9.7333","Germany","DE","DEU","Lower Saxony","","8973","1276949287"
"Jainagar","Jainagar","24.3756","85.6443","India","IN","IND","Jhārkhand","","8602","1356093644"
"Aviano","Aviano","46.0667","12.5833","Italy","IT","ITA","Friuli Venezia Giulia","","9080","1380233722"
"Glens Falls North","Glens Falls North","43.3350","-73.6832","United States","US","USA","New York","","9160","1840073682"
"Rain","Rain","48.6833","10.9167","Germany","DE","DEU","Bavaria","","9042","1276518985"
"Coqueiral","Coqueiral","-21.1889","-45.4408","Brazil","BR","BRA","Minas Gerais","","9128","1076371955"
"Usakos","Usakos","-22.0000","15.6000","Namibia","NA","NAM","Erongo","","9147","1516430978"
"Vīrapperumānallūr","Virapperumanallur","11.7763","79.4466","India","IN","IND","Tamil Nādu","","8573","1356206669"
"Bingham","Bingham","52.9520","-0.9530","United Kingdom","GB","GBR","Nottinghamshire","","9131","1826632789"
"Monteroni d’Arbia","Monteroni d'Arbia","43.2333","11.4167","Italy","IT","ITA","Tuscany","","9070","1380362072"
"Carbonita","Carbonita","-17.5269","-43.0158","Brazil","BR","BRA","Minas Gerais","","9148","1076114573"
"Kandanāti","Kandanati","15.6997","77.4757","India","IN","IND","Andhra Pradesh","","8537","1356652397"
"Sângeorgiu de Mureş","Sangeorgiu de Mures","46.5500","24.4667","Romania","RO","ROU","Mureş","","8800","1642991936"
"Bruchhausen-Vilsen","Bruchhausen-Vilsen","52.8333","9.0000","Germany","DE","DEU","Lower Saxony","","9070","1276878870"
"Costa Volpino","Costa Volpino","45.8306","10.0992","Italy","IT","ITA","Lombardy","","9151","1380902378"
"Bishamagiri","Bishamagiri","19.3849","84.4715","India","IN","IND","Odisha","","8646","1356060078"
"Kara-Bak","Kara-Bak","40.1583","70.8167","Kyrgyzstan","KG","KGZ","Batken","minor","8923","1417932246"
"Salisbury","Salisbury","42.8465","-70.8616","United States","US","USA","Massachusetts","","9152","1840053539"
"Solita","Solita","0.8667","-75.6500","Colombia","CO","COL","Caquetá","minor","9139","1170851278"
"Castagneto Carducci","Castagneto Carducci","43.1667","10.6000","Italy","IT","ITA","Tuscany","","9088","1380067556"
"Ḩīsh","Hish","35.5464","36.6431","Syria","SY","SYR","Idlib","minor","8817","1760171241"
"Al Bardīyah","Al Bardiyah","31.7600","25.0750","Libya","LY","LBY","Al Buţnān","","9149","1434335416"
"Pā’īn Chāf","Pa'in Chaf","37.2294","50.2539","Iran","IR","IRN","Gīlān","","8840","1364982101"
"Valasa","Valasa","14.1632","76.9469","India","IN","IND","Andhra Pradesh","","8818","1356046436"
"Koppunur","Koppunur","16.4867","79.3318","India","IN","IND","Andhra Pradesh","","8698","1356572353"
"Carmen de Carupa","Carmen de Carupa","5.3503","-73.9053","Colombia","CO","COL","Cundinamarca","minor","9109","1170408211"
"Hundested","Hundested","55.9667","11.8500","Denmark","DK","DNK","Hovedstaden","","8588","1208594151"
"Boukhralfa","Boukhralfa","36.6144","5.0872","Algeria","DZ","DZA","Bejaïa","","8766","1012066745"
"Bichura","Bichura","50.5864","107.5975","Russia","RU","RUS","Buryatiya","","9145","1643333003"
"Gundumāl","Gundumal","16.8939","77.7112","India","IN","IND","Andhra Pradesh","","8734","1356416314"
"Pine Lake Park","Pine Lake Park","40.0017","-74.2595","United States","US","USA","New Jersey","","9148","1840024354"
"Villa Chalcatongo de Hidalgo","Villa Chalcatongo de Hidalgo","16.9929","-97.5498","Mexico","MX","MEX","Oaxaca","","9066","1484341732"
"Penugolanu","Penugolanu","16.9771","80.4369","India","IN","IND","Andhra Pradesh","","8797","1356797685"
"Kressbronn am Bodensee","Kressbronn am Bodensee","47.5958","9.6000","Germany","DE","DEU","Baden-Württemberg","","8720","1276612974"
"Loeches","Loeches","40.3833","-3.4000","Spain","ES","ESP","Madrid","","8944","1724742454"
"Duchcov","Duchcov","50.6039","13.7464","Czechia","CZ","CZE","Ústecký Kraj","","8589","1203260902"
"Pedda Nindrakolanu","Pedda Nindrakolanu","16.7342","81.3941","India","IN","IND","Andhra Pradesh","","8741","1356560375"
"Ranjal","Ranjal","18.7458","77.9483","India","IN","IND","Andhra Pradesh","","8802","1356139049"
"Dospat","Dospat","41.6500","24.1667","Bulgaria","BG","BGR","Smolyan","minor","9116","1100747470"
"San Zenón","San Zenon","9.2450","-74.4992","Colombia","CO","COL","Magdalena","minor","9107","1170977298"
"Tiburon","Tiburon","37.8854","-122.4637","United States","US","USA","California","","9146","1840022521"
"San Lorenzo de Descardazar","San Lorenzo de Descardazar","39.6090","3.2853","Spain","ES","ESP","Balearic Islands","","9035","1724819492"
"St. Anthony","St. Anthony","45.0278","-93.2174","United States","US","USA","Minnesota","","9145","1840008933"
"Palmeiras","Palmeiras","-12.5289","-41.5589","Brazil","BR","BRA","Bahia","","9130","1076798897"
"Trilj","Trilj","43.6167","16.7167","Croatia","HR","HRV","Splitsko-Dalmatinska Županija","minor","9109","1191317779"
"Crvenka","Crvenka","45.6583","19.4556","Serbia","RS","SRB","","","9001","1688952283"
"Panthersville","Panthersville","33.7059","-84.2763","United States","US","USA","Georgia","","9143","1840013705"
"Haddington","Haddington","55.9560","-2.7810","United Kingdom","GB","GBR","East Lothian","","9130","1826363081"
"Katoomba","Katoomba","-33.7150","150.3120","Australia","AU","AUS","New South Wales","","7964","1036674004"
"Urânia","Urania","-20.2458","-50.6428","Brazil","BR","BRA","São Paulo","","9135","1076428972"
"Llagostera","Llagostera","41.8292","2.8933","Spain","ES","ESP","Catalonia","","9024","1724471622"
"Chadan","Chadan","51.2833","91.5833","Russia","RU","RUS","Tyva","","9139","1643075116"
"Iijima","Iijima","35.6767","137.9198","Japan","JP","JPN","Nagano","","9038","1392103734"
"Kißlegg","Kisslegg","47.7900","9.8842","Germany","DE","DEU","Baden-Württemberg","","9043","1276219486"
"Ochsenhausen","Ochsenhausen","48.0722","9.9481","Germany","DE","DEU","Baden-Württemberg","","8991","1276059571"
"Estanzuelas","Estanzuelas","13.6500","-88.5000","El Salvador","SV","SLV","Usulután","","9015","1222473531"
"Kottadindulu","Kottadindulu","16.4830","81.8330","India","IN","IND","Andhra Pradesh","","8648","1356834952"
"East Grand Forks","East Grand Forks","47.9286","-97.0138","United States","US","USA","Minnesota","","9140","1840007700"
"Huron East","Huron East","43.6300","-81.2800","Canada","CA","CAN","Ontario","","9138","1124000724"
"Castelnuovo Berardenga","Castelnuovo Berardenga","43.3474","11.5044","Italy","IT","ITA","Tuscany","","9086","1380912826"
"Harahan","Harahan","29.9374","-90.2031","United States","US","USA","Louisiana","","9137","1840013997"
"Zeydābād","Zeydabad","29.6000","55.5369","Iran","IR","IRN","Kermān","","9112","1364403810"
"Campamento","Campamento","6.9789","-75.2964","Colombia","CO","COL","Antioquia","minor","9091","1170487323"
"Quéven","Queven","47.7886","-3.4153","France","FR","FRA","Bretagne","","8770","1250034076"
"Thāndewāla","Thandewala","30.4720","74.5898","India","IN","IND","Punjab","","8520","1356017680"
"Scenic Oaks","Scenic Oaks","29.7038","-98.6712","United States","US","USA","Texas","","9136","1840019650"
"Oberriet","Oberriet","47.3164","9.5664","Switzerland","CH","CHE","Sankt Gallen","","8878","1756217595"
"Keregodu","Keregodu","12.6333","76.9167","India","IN","IND","Karnātaka","","8528","1356059262"
"Oakengates","Oakengates","52.6950","-2.4510","United Kingdom","GB","GBR","Telford and Wrekin","","8517","1826612540"
"Burgos","Burgos","16.5250","120.4583","Philippines","PH","PHL","La Union","","9006","1608508445"
"Riverside","Riverside","41.0318","-73.5827","United States","US","USA","Connecticut","","9133","1840026286"
"Lichtervelde","Lichtervelde","51.0333","3.1333","Belgium","BE","BEL","Flanders","","8793","1056245942"
"Velyki Luchky","Velyki Luchky","48.4200","22.5611","Ukraine","UA","UKR","Zakarpatska Oblast","","9028","1804653748"
"Mühlhausen","Muhlhausen","49.2475","8.7239","Germany","DE","DEU","Baden-Württemberg","","8571","1276928543"
"Gtarna","Gtarna","32.9724","-7.9645","Morocco","MA","MAR","Casablanca-Settat","","9084","1504328347"
"Kot Fatah","Kot Fatah","30.1122","75.1120","India","IN","IND","Punjab","","8585","1356752477"
"Rio Acima","Rio Acima","-20.0878","-43.7889","Brazil","BR","BRA","Minas Gerais","","9090","1076873685"
"Iguidiy","Iguidiy","30.7467","-7.9258","Morocco","MA","MAR","Souss-Massa","","9100","1504330171"
"Kaeng Khro","Kaeng Khro","16.1086","102.2581","Thailand","TH","THA","Chaiyaphum","minor","9012","1764376495"
"Coronel Murta","Coronel Murta","-16.6189","-42.1819","Brazil","BR","BRA","Minas Gerais","","9117","1076166627"
"Smithville","Smithville","39.4929","-74.4801","United States","US","USA","New Jersey","","9127","1840024384"
"Lugoff","Lugoff","34.2113","-80.6973","United States","US","USA","South Carolina","","9127","1840013597"
"Nunihāt","Nunihat","24.4843","87.1323","India","IN","IND","Jhārkhand","","8573","1356456693"
"Thung Sai","Thung Sai","16.2955","99.8089","Thailand","TH","THA","Kamphaeng Phet","minor","8971","1764018177"
"Seini","Seini","47.7478","23.2853","Romania","RO","ROU","Maramureş","","8987","1642934911"
"Anísio de Abreu","Anisio de Abreu","-9.1889","-43.0458","Brazil","BR","BRA","Piauí","","9098","1076098947"
"Civitella in Val di Chiana","Civitella in Val di Chiana","43.4167","11.7167","Italy","IT","ITA","Tuscany","","9035","1380122075"
"Guardiagrele","Guardiagrele","42.2000","14.2167","Italy","IT","ITA","Abruzzo","","8966","1380924817"
"Kalas","Kalas","15.0981","75.4067","India","IN","IND","Karnātaka","","8844","1356171994"
"Garsekurti","Garsekurti","18.5083","78.9774","India","IN","IND","Andhra Pradesh","","8781","1356629147"
"Cogoleto","Cogoleto","44.3894","8.6461","Italy","IT","ITA","Liguria","","9123","1380362572"
"Oak Hills","Oak Hills","34.3910","-117.4123","United States","US","USA","California","","9123","1840024934"
"Silvārpatti","Silvarpatti","10.1180","77.6241","India","IN","IND","Tamil Nādu","","8515","1356227823"
"Kharsod B","Kharsod B","23.2225","75.3860","India","IN","IND","Madhya Pradesh","","8873","1356816289"
"Tarusa","Tarusa","54.7333","37.1833","Russia","RU","RUS","Kaluzhskaya Oblast’","","9101","1643162455"
"Embalse","Embalse","-32.1833","-64.4167","Argentina","AR","ARG","Córdoba","","9107","1032017911"
"Velden am Wörthersee","Velden am Worthersee","46.6125","14.0419","Austria","AT","AUT","Kärnten","","8952","1040535411"
"Sini","Sini","22.7933","85.9454","India","IN","IND","Jhārkhand","","8700","1356568375"
"Sirikonda","Sirikonda","17.1947","79.7775","India","IN","IND","Andhra Pradesh","","8861","1356130737"
"Neuenkirchen","Neuenkirchen","52.5167","8.0667","Germany","DE","DEU","Lower Saxony","","9021","1276221041"
"Doberlug-Kirchhain","Doberlug-Kirchhain","51.6167","13.5667","Germany","DE","DEU","Brandenburg","","9062","1276784712"
"Sauce","Sauce","-30.0667","-58.7667","Argentina","AR","ARG","Corrientes","minor","9115","1032154340"
"Dzhalka","Dzhalka","43.3186","45.9878","Russia","RU","RUS","Chechnya","","8972","1643147870"
"Bolokhovo","Bolokhovo","54.0833","37.8333","Russia","RU","RUS","Tul’skaya Oblast’","","9043","1643953673"
"Cutrofiano","Cutrofiano","40.1167","18.2000","Italy","IT","ITA","Puglia","","8958","1380279562"
"Terrace Heights","Terrace Heights","46.6045","-120.4393","United States","US","USA","Washington","","9114","1840018465"
"Entraigues-sur-la-Sorgue","Entraigues-sur-la-Sorgue","44.0031","4.9267","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8595","1250314782"
"Tiachiv","Tiachiv","48.0114","23.5722","Ukraine","UA","UKR","Zakarpatska Oblast","minor","9019","1804591278"
"Tineo","Tineo","43.3095","-6.4922","Spain","ES","ESP","Asturias","","9096","1724821871"
"Polakala","Polakala","13.3458","78.8167","India","IN","IND","Andhra Pradesh","","8878","1356950349"
"La Florida","La Florida","1.3017","-77.4106","Colombia","CO","COL","Nariño","minor","9047","1170744583"
"Tagapul-an","Tagapul-an","12.0500","124.1500","Philippines","PH","PHL","Samar","","8805","1608865320"
"Mnichovo Hradiště","Mnichovo Hradiste","50.5273","14.9714","Czechia","CZ","CZE","Středočeský Kraj","","8858","1203949277"
"Kodavatipūdi","Kodavatipudi","17.4887","82.6046","India","IN","IND","Andhra Pradesh","","8821","1356660322"
"Vergiate","Vergiate","45.7167","8.7000","Italy","IT","ITA","Lombardy","","8711","1380696567"
"Woodbury","Woodbury","40.8176","-73.4703","United States","US","USA","New York","","9111","1840150821"
"Nelkattumseval","Nelkattumseval","9.2400","77.5300","India","IN","IND","Tamil Nādu","","8693","1356204878"
"Bernolákovo","Bernolakovo","48.1992","17.3003","Slovakia","SK","SVK","Bratislava","","8801","1703400453"
"Navoloki","Navoloki","57.4667","41.9667","Russia","RU","RUS","Ivanovskaya Oblast’","","9096","1643201351"
"Farra di Soligo","Farra di Soligo","45.8833","12.1167","Italy","IT","ITA","Veneto","","8798","1380004491"
"Nimmekal","Nimmekal","17.2372","79.6633","India","IN","IND","Andhra Pradesh","","8844","1356072847"
"Paranaiguana","Paranaiguana","-18.9158","-50.6539","Brazil","BR","BRA","Goiás","","9100","1076018751"
"Pullalacheruvu","Pullalacheruvu","16.1584","79.4321","India","IN","IND","Andhra Pradesh","","8861","1356214296"
"Kratovo","Kratovo","55.5911","38.1803","Russia","RU","RUS","Moskovskaya Oblast’","","8904","1643005714"
"Somero","Somero","60.6333","23.5167","Finland","FI","FIN","Varsinais-Suomi","minor","9093","1246015002"
"Roccastrada","Roccastrada","43.0097","11.1683","Italy","IT","ITA","Tuscany","","9074","1380310398"
"Trissino","Trissino","45.5667","11.3667","Italy","IT","ITA","Veneto","","8709","1380795560"
"Tutrakan","Tutrakan","44.0500","26.6167","Bulgaria","BG","BGR","Silistra","minor","9076","1100290695"
"Big Rapids","Big Rapids","43.6992","-85.4806","United States","US","USA","Michigan","","9105","1840002678"
"Felino","Felino","44.6936","10.2420","Italy","IT","ITA","Emilia-Romagna","","8873","1380822010"
"Kosum Phisai","Kosum Phisai","16.2430","103.0627","Thailand","TH","THA","Maha Sarakham","minor","8984","1764287207"
"Kharovsk","Kharovsk","59.9500","40.2000","Russia","RU","RUS","Vologodskaya Oblast’","","9099","1643046840"
"Stará Turá","Stara Tura","48.7767","17.6961","Slovakia","SK","SVK","Nitra","","8932","1703420460"
"Akat Amnuai","Akat Amnuai","17.5898","103.9859","Thailand","TH","THA","Sakon Nakhon","minor","9013","1764592273"
"Deshnur","Deshnur","15.7900","74.7500","India","IN","IND","Karnātaka","","8717","1356890594"
"Bālumāth","Balumath","23.8298","84.7888","India","IN","IND","Jhārkhand","","8875","1356624677"
"Amingaon","Amingaon","26.2300","91.6700","India","IN","IND","Assam","","8595","1356156486"
"Aransas Pass","Aransas Pass","27.8876","-97.1136","United States","US","USA","Texas","","9101","1840019708"
"Waldenbuch","Waldenbuch","48.6372","9.1317","Germany","DE","DEU","Baden-Württemberg","","8717","1276938935"
"Waterford","Waterford","37.6429","-120.7553","United States","US","USA","California","","9100","1840021534"
"Fort Irwin","Fort Irwin","35.2477","-116.6834","United States","US","USA","California","","9100","1840025802"
"Herseh Chhīna","Herseh Chhina","31.7453","74.7830","India","IN","IND","Punjab","","8673","1356007687"
"Rathdrum","Rathdrum","47.7948","-116.8944","United States","US","USA","Idaho","","9099","1840019812"
"Osicala","Osicala","13.8000","-88.1500","El Salvador","SV","SLV","Morazán","","8909","1222316940"
"Igana","Igana","7.0333","2.7000","Benin","BJ","BEN","Plateau","","8735","1204917317"
"Rada Tilly","Rada Tilly","-45.9333","-67.5333","Argentina","AR","ARG","Chubut","","9098","1032850983"
"Wasilla","Wasilla","61.5770","-149.4660","United States","US","USA","Alaska","","9098","1840023371"
"Yakakent","Yakakent","41.6222","35.5314","Turkey","TR","TUR","Samsun","minor","8864","1792258127"
"Salem","Salem","40.0539","-111.6718","United States","US","USA","Utah","","9097","1840021395"
"Säffle","Saffle","59.1333","12.9333","Sweden","SE","SWE","Värmland","minor","8991","1752987175"
"Cheste","Cheste","39.4797","-0.6844","Spain","ES","ESP","Valencia","","8971","1724161865"
"Barhagarh","Barhagarh","19.5676","84.5842","India","IN","IND","Odisha","","8916","1356692725"
"Des Peres","Des Peres","38.5973","-90.4480","United States","US","USA","Missouri","","9096","1840007456"
"Cresskill","Cresskill","40.9405","-73.9596","United States","US","USA","New Jersey","","9096","1840003539"
"Lynwood","Lynwood","41.5235","-87.5510","United States","US","USA","Illinois","","9095","1840011291"
"Madnūr","Madnur","18.5000","77.6333","India","IN","IND","Andhra Pradesh","","8841","1356028856"
"Zalishchyky","Zalishchyky","48.6392","25.7374","Ukraine","UA","UKR","Ternopilska Oblast","minor","9021","1804632373"
"Pântano Grande","Pantano Grande","-30.1908","-52.3739","Brazil","BR","BRA","Rio Grande do Sul","","9083","1076467088"
"Epanomí","Epanomi","40.4261","22.9281","Greece","GR","GRC","Kentrikí Makedonía","","8979","1300802037"
"Pedrinhas","Pedrinhas","-11.1919","-37.6739","Brazil","BR","BRA","Sergipe","","8833","1076765344"
"Redlands","Redlands","39.0886","-108.6582","United States","US","USA","Colorado","","9092","1840018812"
"Calamar","Calamar","1.9206","-72.5542","Colombia","CO","COL","Guaviare","minor","9091","1170732993"
"Galbiate","Galbiate","45.8000","9.3833","Italy","IT","ITA","Lombardy","","8545","1380652447"
"Malangām","Malangam","34.4383","74.5575","India","IN","IND","Jammu and Kashmīr","","8847","1356687692"
"Middleton","Middleton","43.7113","-116.6157","United States","US","USA","Idaho","","9091","1840020040"
"Chiţcani","Chitcani","46.7833","29.6167","Moldova","MD","MDA","Stînga Nistrului","","9000","1498000217"
"Nāgaiyampatti","Nagaiyampatti","11.4639","78.4801","India","IN","IND","Tamil Nādu","","8729","1356257973"
"Portlethen","Portlethen","57.0610","-2.1300","United Kingdom","GB","GBR","Aberdeenshire","","9090","1826375407"
"Chinna Mupparam","Chinna Mupparam","17.6319","79.8411","India","IN","IND","Andhra Pradesh","","8767","1356152615"
"Breckerfeld","Breckerfeld","51.2667","7.4667","Germany","DE","DEU","North Rhine-Westphalia","","8938","1276888240"
"Åmål","Amal","59.0500","12.7000","Sweden","SE","SWE","Västra Götaland","minor","9065","1752187898"
"Shar","Shar","49.5858","81.0478","Kazakhstan","KZ","KAZ","","minor","9087","1398393299"
"La Celia","La Celia","5.0019","-76.0039","Colombia","CO","COL","Risaralda","minor","9000","1170761664"
"Tha Luang","Tha Luang","15.0697","101.1182","Thailand","TH","THA","Lop Buri","minor","9003","1764595647"
"Bad Schussenried","Bad Schussenried","48.0067","9.6586","Germany","DE","DEU","Baden-Württemberg","","8925","1276967245"
"Pāppākudi","Pappakudi","8.7520","77.5026","India","IN","IND","Tamil Nādu","","8694","1356206423"
"Bérégadougou","Beregadougou","10.7667","-4.7500","Burkina Faso","BF","BFA","Cascades","","9036","1854196124"
"Herzberg","Herzberg","51.6833","13.2333","Germany","DE","DEU","Brandenburg","minor","9027","1276357277"
"Deodora","Deodora","22.6149","80.3616","India","IN","IND","Madhya Pradesh","","8775","1356823134"
"Casca","Casca","-28.5608","-51.9778","Brazil","BR","BRA","Rio Grande do Sul","","9051","1076789569"
"Picture Rocks","Picture Rocks","32.3274","-111.2558","United States","US","USA","Arizona","","9084","1840019482"
"Nanzhuang","Nanzhuang","24.5699","121.0157","Taiwan","TW","TWN","Miaoli","","9029","1158569080"
"Silves","Silves","-2.8389","-58.2092","Brazil","BR","BRA","Amazonas","","9081","1076795743"
"Vista Alegre do Alto","Vista Alegre do Alto","-21.1708","-48.6289","Brazil","BR","BRA","São Paulo","","8989","1076556139"
"Harīke","Harike","31.1661","74.9417","India","IN","IND","Punjab","","8662","1356906122"
"Grenade","Grenade","43.7714","1.2931","France","FR","FRA","Occitanie","","8844","1250521887"
"Siklós","Siklos","45.8519","18.2986","Hungary","HU","HUN","Baranya","minor","8912","1348986521"
"San Francisco","San Francisco","14.1167","-88.3667","Honduras","HN","HND","Lempira","","9017","1340590673"
"Hāta","Hata","25.0460","83.4632","India","IN","IND","Bihār","","8598","1356689751"
"Livno","Livno","43.8269","17.0081","Bosnia and Herzegovina","BA","BIH","Bosnia and Herzegovina, Federation of","minor","9045","1070523528"
"Íquira","Iquira","2.6500","-75.6500","Colombia","CO","COL","Huila","minor","9064","1170529442"
"Elsmere","Elsmere","38.9948","-84.6017","United States","US","USA","Kentucky","","9080","1840013167"
"Kandanūr","Kandanur","10.1037","78.8254","India","IN","IND","Tamil Nādu","","8520","1356201349"
"Adigoppula","Adigoppula","16.4402","79.6235","India","IN","IND","Andhra Pradesh","","8816","1356850226"
"Vinhais","Vinhais","41.8167","-7.0000","Portugal","PT","PRT","Bragança","minor","9066","1620473528"
"Caputira","Caputira","-20.1719","-42.2708","Brazil","BR","BRA","Minas Gerais","","9030","1076678686"
"Sorisole","Sorisole","45.7375","9.6564","Italy","IT","ITA","Lombardy","","9042","1380705220"
"Lançon-Provence","Lancon-Provence","43.5925","5.1281","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8947","1250202221"
"Verkhnyaya Tura","Verkhnyaya Tura","58.3594","59.8175","Russia","RU","RUS","Sverdlovskaya Oblast’","","9039","1643668753"
"Dhībān","Dhiban","35.0025","40.5117","Syria","SY","SYR","Dayr az Zawr","minor","9000","1760211597"
"Khānāpur","Khanapur","17.9004","79.9277","India","IN","IND","Andhra Pradesh","","8787","1356287941"
"Chitvel","Chitvel","14.1667","79.3333","India","IN","IND","Andhra Pradesh","","8943","1356145461"
"Edgewater","Edgewater","38.9373","-76.5572","United States","US","USA","Maryland","","9075","1840024528"
"Muhos","Muhos","64.8000","26.0000","Finland","FI","FIN","Pohjois-Pohjanmaa","minor","9063","1246245450"
"Chrysoúpoli","Chrysoupoli","40.9833","24.7000","Greece","GR","GRC","Anatolikí Makedonía kai Thráki","minor","8885","1300208248"
"Waldfeucht","Waldfeucht","51.0667","5.9831","Germany","DE","DEU","North Rhine-Westphalia","","8784","1276350109"
"Saline","Saline","42.1740","-83.7780","United States","US","USA","Michigan","","9072","1840003171"
"Rafard","Rafard","-23.0117","-47.5269","Brazil","BR","BRA","São Paulo","","9004","1076001121"
"Pa Mok","Pa Mok","14.4921","100.4448","Thailand","TH","THA","Ang Thong","minor","8758","1764706108"
"Bellheim","Bellheim","49.1981","8.2792","Germany","DE","DEU","Rhineland-Palatinate","","8648","1276002204"
"Polička","Policka","49.7147","16.2655","Czechia","CZ","CZE","Pardubický Kraj","","8808","1203964642"
"Nierstein","Nierstein","49.8694","8.3375","Germany","DE","DEU","Rhineland-Palatinate","","8625","1276411938"
"Kings Grant","Kings Grant","34.2664","-77.8659","United States","US","USA","North Carolina","","9071","1840032902"
"Villa Aberastain","Villa Aberastain","-31.6500","-68.5833","Argentina","AR","ARG","San Juan","minor","8946","1032501472"
"Acobamba","Acobamba","-12.8431","-74.5694","Peru","PE","PER","Huancavelica","","8980","1604419379"
"Tilvalli","Tilvalli","14.6268","75.2310","India","IN","IND","Karnātaka","","8750","1356758412"
"Beaver Falls","Beaver Falls","40.7619","-80.3226","United States","US","USA","Pennsylvania","","9069","1840001013"
"White Meadow Lake","White Meadow Lake","40.9241","-74.5107","United States","US","USA","New Jersey","","9069","1840005198"
"Bni Sidel","Bni Sidel","35.1392","-3.1281","Morocco","MA","MAR","Oriental","","8930","1504766280"
"Perkasie","Perkasie","40.3720","-75.2920","United States","US","USA","Pennsylvania","","9068","1840001309"
"Fallon","Fallon","39.4737","-118.7779","United States","US","USA","Nevada","","9068","1840020191"
"Gisborne","Gisborne","-37.4900","144.5889","Australia","AU","AUS","Victoria","","8999","1036388639"
"Whippany","Whippany","40.8233","-74.4184","United States","US","USA","New Jersey","","9067","1840033441"
"Had Dra","Had Dra","31.5833","-9.5333","Morocco","MA","MAR","Marrakech-Safi","","8989","1504953934"
"Cherry Hinton","Cherry Hinton","52.1849","0.1760","United Kingdom","GB","GBR","Cambridgeshire","","8780","1826045496"
"Noventa Vicentina","Noventa Vicentina","45.2833","11.5333","Italy","IT","ITA","Veneto","","9015","1380296259"
"Borgo","Borgo","42.5539","9.4275","France","FR","FRA","Corsica","","8832","1250217723"
"Brownfield","Brownfield","33.1757","-102.2730","United States","US","USA","Texas","","9065","1840019404"
"Abcoude","Abcoude","52.2700","4.9700","Netherlands","NL","NLD","Utrecht","","8791","1528930356"
"Hiramandalam","Hiramandalam","18.6708","83.9505","India","IN","IND","Andhra Pradesh","","8756","1356683859"
"Wollert","Wollert","-37.5833","145.0333","Australia","AU","AUS","Victoria","","9060","1036068932"
"Buriti Alegre","Buriti Alegre","-18.1439","-49.0439","Brazil","BR","BRA","Goiás","","9054","1076584363"
"Flekkefjord","Flekkefjord","58.2969","6.6600","Norway","NO","NOR","Agder","minor","9048","1578438399"
"Dékanmé","Dekanme","7.1333","1.9667","Benin","BJ","BEN","Zou","","8581","1204694695"
"Großbeeren","Grossbeeren","52.3551","13.3079","Germany","DE","DEU","Brandenburg","","8891","1276213734"
"Sunset Hills","Sunset Hills","38.5310","-90.4087","United States","US","USA","Missouri","","9062","1840009742"
"Westampton","Westampton","40.0168","-74.8213","United States","US","USA","New Jersey","","9060","1840081634"
"Saudade","Saudade","-26.9239","-53.0028","Brazil","BR","BRA","Santa Catarina","","9016","1076589237"
"Bansko","Bansko","41.8385","23.4888","Bulgaria","BG","BGR","Blagoevgrad","","9032","1100442389"
"Lanark","Lanark","55.6749","-3.7770","United Kingdom","GB","GBR","South Lanarkshire","","9050","1826114395"
"Januário Cicco","Januario Cicco","-6.1578","-35.6008","Brazil","BR","BRA","Rio Grande do Norte","","9011","1076818819"
"Palazzolo Acreide","Palazzolo Acreide","37.0617","14.9028","Italy","IT","ITA","Sicilia","","8665","1380283839"
"Miramar Beach","Miramar Beach","30.3854","-86.3442","United States","US","USA","Florida","","9059","1840013925"
"Obernburg am Main","Obernburg am Main","49.8400","9.1414","Germany","DE","DEU","Bavaria","","8712","1276112630"
"Monistrol-sur-Loire","Monistrol-sur-Loire","45.2925","4.1722","France","FR","FRA","Auvergne-Rhône-Alpes","","8875","1250947426"
"Glenshaw","Glenshaw","40.5391","-79.9735","United States","US","USA","Pennsylvania","","9058","1840026474"
"Hirson","Hirson","49.9217","4.0839","France","FR","FRA","Hauts-de-France","","8800","1250646275"
"Subiaco","Subiaco","41.9333","13.1000","Italy","IT","ITA","Lazio","","8916","1380173016"
"Greenville","Greenville","43.1797","-85.2534","United States","US","USA","Michigan","","9057","1840002877"
"Bayabas","Bayabas","8.9678","126.2817","Philippines","PH","PHL","Surigao del Sur","","8979","1608996722"
"Ceprano","Ceprano","41.5500","13.5167","Italy","IT","ITA","Lazio","","8823","1380432885"
"Saint-Jean-d’Illac","Saint-Jean-d'Illac","44.8097","-0.7836","France","FR","FRA","Nouvelle-Aquitaine","","8980","1250364390"
"Laveno-Mombello","Laveno-Mombello","45.9089","8.6204","Italy","IT","ITA","Lombardy","","8684","1380373466"
"Thaon-les-Vosges","Thaon-les-Vosges","48.2505","6.4195","France","FR","FRA","Grand Est","","8748","1250742789"
"Linares","Linares","1.3500","-77.5333","Colombia","CO","COL","Nariño","minor","8974","1170176780"
"Northwest Harborcreek","Northwest Harborcreek","42.1494","-79.9945","United States","US","USA","Pennsylvania","","9052","1840035146"
"São Jorge d’Oeste","Sao Jorge d'Oeste","-25.7058","-52.9178","Brazil","BR","BRA","Paraná","","9028","1076759209"
"Amadalli","Amadalli","14.7667","74.2167","India","IN","IND","Karnātaka","","8702","1356075131"
"Crest","Crest","44.7283","5.0222","France","FR","FRA","Auvergne-Rhône-Alpes","","8680","1250484520"
"Angelópolis","Angelopolis","6.1333","-75.7000","Colombia","CO","COL","Antioquia","minor","8946","1170231958"
"Morlupo","Morlupo","42.1435","12.5047","Italy","IT","ITA","Lazio","","8689","1380074075"
"Beldibi","Beldibi","36.8667","28.2667","Turkey","TR","TUR","Muğla","","8959","1792141757"
"Çayırhan","Cayirhan","40.1000","31.6833","Turkey","TR","TUR","Ankara","minor","9039","1792606304"
"Little Falls","Little Falls","45.9862","-94.3586","United States","US","USA","Minnesota","","9049","1840008861"
"Davenport","Davenport","28.1587","-81.6083","United States","US","USA","Florida","","9049","1840015104"
"Bernalillo","Bernalillo","35.3127","-106.5537","United States","US","USA","New Mexico","","9049","1840020388"
"Newark","Newark","43.0418","-77.0930","United States","US","USA","New York","","9049","1840004292"
"Neves Paulista","Neves Paulista","-20.8458","-49.6300","Brazil","BR","BRA","São Paulo","","9008","1076295222"
"San Pablo Villa de Mitla","San Pablo Villa de Mitla","16.9170","-96.4000","Mexico","MX","MEX","Oaxaca","minor","7547","1484844758"
"Toccoa","Toccoa","34.5810","-83.3263","United States","US","USA","Georgia","","9048","1840015551"
"Zunilito","Zunilito","14.6167","-91.5167","Guatemala","GT","GTM","Suchitepéquez","minor","8888","1320537106"
"Māgam","Magam","34.4595","74.2306","India","IN","IND","Jammu and Kashmīr","","8614","1356045881"
"Santa Sylvina","Santa Sylvina","-27.7833","-61.1500","Argentina","AR","ARG","Chaco","minor","9040","1032777681"
"Dāla","Dala","30.7773","75.2494","India","IN","IND","Punjab","","8558","1356106491"
"Punnappatti","Punnappatti","10.2243","78.1982","India","IN","IND","Tamil Nādu","","8717","1356224793"
"Sai Ngam","Sai Ngam","16.4669","99.8902","Thailand","TH","THA","Kamphaeng Phet","minor","8923","1764631031"
"Kozova","Kozova","49.4318","25.1544","Ukraine","UA","UKR","Ternopilska Oblast","minor","8989","1804829184"
"Ballenstedt","Ballenstedt","51.7200","11.2375","Germany","DE","DEU","Saxony-Anhalt","","8940","1276375352"
"Dunavarsány","Dunavarsany","47.2781","19.0672","Hungary","HU","HUN","Pest","","8657","1348821728"
"Nuevo Paysandú","Nuevo Paysandu","-32.2667","-58.0667","Uruguay","UY","URY","Paysandú","","8578","1858171895"
"Hemmoor","Hemmoor","53.7025","9.1394","Germany","DE","DEU","Lower Saxony","","8843","1276546938"
"Absecon","Absecon","39.4229","-74.4944","United States","US","USA","New Jersey","","9039","1840003797"
"Ayr","Ayr","-19.5744","147.4066","Australia","AU","AUS","Queensland","","8738","1036228772"
"Karlıova","Karliova","39.2992","41.0142","Turkey","TR","TUR","Bingöl","minor","9016","1792947397"
"Stanley","Stanley","49.1331","-98.0656","Canada","CA","CAN","Manitoba","","9038.00","1124001503"
"Çayırlı","Cayirli","39.8056","40.0364","Turkey","TR","TUR","Erzincan","minor","9032","1792113760"
"Yapraklı","Yaprakli","40.7500","33.7667","Turkey","TR","TUR","Çankırı","minor","8981","1792364506"
"Khoni","Khoni","42.3244","42.4222","Georgia","GE","GEO","Imereti","","8987","1268451521"
"Bommagondanahalli","Bommagondanahalli","13.8984","77.0733","India","IN","IND","Andhra Pradesh","","8715","1356406639"
"San Giorgio di Piano","San Giorgio di Piano","44.6500","11.3833","Italy","IT","ITA","Emilia-Romagna","","8749","1380940208"
"Maravilha","Maravilha","-9.2358","-37.3500","Brazil","BR","BRA","Alagoas","","9004","1076641597"
"Delta","Delta","38.7560","-108.0772","United States","US","USA","Colorado","","9036","1840018820"
"Rhymney","Rhymney","51.7590","-3.2830","United Kingdom","GB","GBR","Caerphilly","","8845","1826457934"
"Uspenka","Uspenka","48.3939","39.1536","Ukraine","UA","UKR","Luhanska Oblast","","8974","1804689890"
"Bharno","Bharno","23.2204","84.8891","India","IN","IND","Jhārkhand","","8735","1356006721"
"Coffeyville","Coffeyville","37.0519","-95.6180","United States","US","USA","Kansas","","9032","1840001707"
"Sande","Sande","53.5022","8.0139","Germany","DE","DEU","Lower Saxony","","8835","1276159264"
"Albinea","Albinea","44.6167","10.6000","Italy","IT","ITA","Emilia-Romagna","","8830","1380433686"
"Coronel Du Graty","Coronel Du Graty","-27.6667","-60.9333","Argentina","AR","ARG","Chaco","","9015","1032616689"
"Abirāmam","Abiramam","9.4423","78.4399","India","IN","IND","Tamil Nādu","","8762","1356724493"
"Candeal","Candeal","-11.8078","-39.1189","Brazil","BR","BRA","Bahia","","9011","1076755311"
"Fitzgerald","Fitzgerald","31.7134","-83.2514","United States","US","USA","Georgia","","9028","1840013855"
"Fairless Hills","Fairless Hills","40.1783","-74.8524","United States","US","USA","Pennsylvania","","9028","1840001307"
"Wervershoof","Wervershoof","52.7300","5.1700","Netherlands","NL","NLD","Noord-Holland","","8746","1528048867"
"Phopnār Kalān","Phopnar Kalan","21.2365","76.3268","India","IN","IND","Madhya Pradesh","","8525","1356838414"
"Craig","Craig","40.5171","-107.5554","United States","US","USA","Colorado","","9026","1840018741"
"McFarland","McFarland","43.0203","-89.2828","United States","US","USA","Wisconsin","","9026","1840002914"
"Thap Than","Thap Than","15.4570","99.8959","Thailand","TH","THA","Uthai Thani","minor","8931","1764003260"
"Libonik","Libonik","40.7500","20.7167","Albania","AL","ALB","Korçë","","8922","1008483668"
"Berching","Berching","49.1000","11.4333","Germany","DE","DEU","Bavaria","","8957","1276699990"
"Sítio Novo de Goiás","Sitio Novo de Goias","-5.6008","-47.6378","Brazil","BR","BRA","Tocantins","","8997","1076199362"
"Bolnisi","Bolnisi","41.4500","44.5333","Georgia","GE","GEO","Kvemo Kartli","minor","8967","1268256910"
"Bargersville","Bargersville","39.5412","-86.2004","United States","US","USA","Indiana","","9024","1840009569"
"Cloverdale","Cloverdale","38.7962","-123.0152","United States","US","USA","California","","9023","1840018838"
"Mburucuyá","Mburucuya","-28.0500","-58.2333","Argentina","AR","ARG","Corrientes","minor","9012","1032397910"
"Devnya","Devnya","43.2167","27.5667","Bulgaria","BG","BGR","Varna","","8915","1100328780"
"Florstadt","Florstadt","50.3158","8.8631","Germany","DE","DEU","Hesse","","8798","1276644408"
"Pomichna","Pomichna","48.2500","31.4167","Ukraine","UA","UKR","Kirovohradska Oblast","","8994","1804146388"
"Bersenbrück","Bersenbruck","52.5333","7.9167","Germany","DE","DEU","Lower Saxony","","8810","1276969333"
"Nāttarasankottai","Nattarasankottai","9.8690","78.5531","India","IN","IND","Tamil Nādu","","8693","1356443818"
"Mānpur","Manpur","22.4315","75.6211","India","IN","IND","Madhya Pradesh","","8613","1356551649"
"Rāgampet","Ragampet","18.6383","79.1441","India","IN","IND","Andhra Pradesh","","8723","1356735496"
"Çatalağzı","Catalagzi","41.5000","31.8667","Turkey","TR","TUR","Zonguldak","","8678","1792852381"
"Mādāram","Madaram","19.1653","79.4097","India","IN","IND","Andhra Pradesh","","8832","1356050790"
"Hope","Hope","33.6682","-93.5895","United States","US","USA","Arkansas","","9010","1840014765"
"Koekelare","Koekelare","51.0906","2.9803","Belgium","BE","BEL","Flanders","","8784","1056902791"
"Auerbach","Auerbach","49.6833","11.6167","Germany","DE","DEU","Bavaria","","8880","1276188143"
"Hoyo de Manzanares","Hoyo de Manzanares","40.6333","-3.8833","Spain","ES","ESP","Madrid","","8811","1724993992"
"Kattirippulam","Kattirippulam","10.4640","79.7597","India","IN","IND","Tamil Nādu","","8627","1356200772"
"Buşteni","Busteni","45.4117","25.5372","Romania","RO","ROU","Prahova","","8894","1642809990"
"Kodaimangalam","Kodaimangalam","10.4733","77.5070","India","IN","IND","Tamil Nādu","","8554","1356210052"
"Puerto Octay","Puerto Octay","-40.9667","-72.9000","Chile","CL","CHL","Los Lagos","","8999","1152604525"
"Karkkila","Karkkila","60.5333","24.2167","Finland","FI","FIN","Uusimaa","minor","8969","1246261386"
"Engerwitzdorf","Engerwitzdorf","48.3397","14.4256","Austria","AT","AUT","Oberösterreich","","8790","1040013380"
"Sarlat-la-Canéda","Sarlat-la-Caneda","44.8900","1.2167","France","FR","FRA","Nouvelle-Aquitaine","minor","8816","1250580202"
"Volkach","Volkach","49.8667","10.2167","Germany","DE","DEU","Bavaria","","8857","1276344574"
"Natividade","Natividade","-11.7100","-47.7228","Brazil","BR","BRA","Tocantins","","9000","1076499835"
"Ḩalāwah","Halawah","32.3828","35.6619","Jordan","JO","JOR","‘Ajlūn","","8647","1400561057"
"Santa Venerina","Santa Venerina","37.6833","15.1333","Italy","IT","ITA","Sicilia","","8553","1380544316"
"Urdinarrain","Urdinarrain","-32.6856","-58.8867","Argentina","AR","ARG","Entre Ríos","","8986","1032326044"
"Arboledas","Arboledas","7.6667","-72.7500","Colombia","CO","COL","Norte de Santander","minor","8984","1170201750"
"San Francisco Ixhuatan","San Francisco Ixhuatan","16.3514","-94.4836","Mexico","MX","MEX","Oaxaca","","8980","1484223318"
"Czarna Białostocka","Czarna Bialostocka","53.3000","23.2833","Poland","PL","POL","Podlaskie","","8943","1616707256"
"Hailey","Hailey","43.5141","-114.3001","United States","US","USA","Idaho","","9001","1840020028"
"Yelnya","Yelnya","54.5833","33.1833","Russia","RU","RUS","Smolenskaya Oblast’","minor","8993","1643873845"
"Pāta Uppāl","Pata Uppal","18.1799","79.4762","India","IN","IND","Andhra Pradesh","","8547","1356122440"
"Bachchannapet","Bachchannapet","17.7883","79.0349","India","IN","IND","Andhra Pradesh","","8779","1356416630"
"Kālkuni","Kalkuni","14.6000","74.8333","India","IN","IND","Karnātaka","","8844","1356030366"
"Rondon","Rondon","-23.4108","-52.7608","Brazil","BR","BRA","Paraná","","8996","1076528861"
"Uricani","Uricani","45.3364","23.1525","Romania","RO","ROU","Hunedoara","","8972","1642297592"
"Marguerittes","Marguerittes","43.8600","4.4439","France","FR","FRA","Occitanie","","8656","1250000807"
"Mbamba Bay","Mbamba Bay","-11.2833","34.7717","Tanzania","TZ","TZA","Ruvuma","","8997","1834067294"
"Pyālakurti","Pyalakurti","15.7286","77.8168","India","IN","IND","Andhra Pradesh","","8680","1356432424"
"Olney","Olney","38.7285","-88.0839","United States","US","USA","Illinois","","8996","1840009783"
"Mount Pleasant","Mount Pleasant","40.9625","-91.5452","United States","US","USA","Iowa","","8996","1840000888"
"Iscuandé","Iscuande","2.4444","-77.9717","Colombia","CO","COL","Nariño","minor","8989","1170165783"
"Rolesville","Rolesville","35.9223","-78.4656","United States","US","USA","North Carolina","","8995","1840017759"
"Szentgotthárd","Szentgotthard","46.9488","16.2791","Hungary","HU","HUN","Vas","minor","8864","1348047105"
"Pillutla","Pillutla","16.5390","79.9044","India","IN","IND","Andhra Pradesh","","8722","1356943816"
"Chop","Chop","48.4333","22.2000","Ukraine","UA","UKR","Zakarpatska Oblast","","8897","1804775994"
"Foum Zguid","Foum Zguid","30.0833","-6.8667","Morocco","MA","MAR","Souss-Massa","","8986","1504624650"
"Dobříš","Dobris","49.7811","14.1672","Czechia","CZ","CZE","Středočeský Kraj","","8828","1203853361"
"Perkiomen","Perkiomen","40.2316","-75.4644","United States","US","USA","Pennsylvania","","8991","1840142126"
"Fully","Fully","46.1333","7.1167","Switzerland","CH","CHE","Valais","","8759","1756803962"
"Montalto di Castro","Montalto di Castro","42.3500","11.6000","Italy","IT","ITA","Lazio","","8978","1380380235"
"Murillo","Murillo","26.2642","-98.1233","United States","US","USA","Texas","","8989","1840037125"
"Ospina","Ospina","1.0581","-77.5664","Colombia","CO","COL","Nariño","minor","8834","1170871794"
"Domérat","Domerat","46.3603","2.5344","France","FR","FRA","Auvergne-Rhône-Alpes","","8742","1250017296"
"Tepechitlán","Tepechitlan","21.6667","-103.3333","Mexico","MX","MEX","Zacatecas","minor","8972","1484570912"
"Sępólno Krajeńskie","Sepolno Krajenskie","53.4500","17.5300","Poland","PL","POL","Kujawsko-Pomorskie","minor","8914","1616234057"
"Park Ridge","Park Ridge","41.0352","-74.0423","United States","US","USA","New Jersey","","8987","1840000917"
"Cameron","Cameron","39.7444","-94.2329","United States","US","USA","Missouri","","8987","1840007321"
"Puerto Lleras","Puerto Lleras","3.2694","-73.3750","Colombia","CO","COL","Meta","minor","8982","1170566483"
"Zawyat Sidi al Mekki","Zawyat Sidi al Mekki","33.2120","-7.7170","Morocco","MA","MAR","Casablanca-Settat","","8920","1504769333"
"Sheffield Lake","Sheffield Lake","41.4883","-82.0979","United States","US","USA","Ohio","","8985","1840000647"
"Fehrbellin","Fehrbellin","52.8144","12.7661","Germany","DE","DEU","Brandenburg","","8951","1276574282"
"Pulaski","Pulaski","37.0528","-80.7624","United States","US","USA","Virginia","","8984","1840006469"
"Divisópolis","Divisopolis","-15.7258","-41.0000","Brazil","BR","BRA","Minas Gerais","","8974","1076864347"
"Locogahoué","Locogahoue","6.8000","1.7830","Benin","BJ","BEN","Couffo","","8567","1204156851"
"Great Cornard","Great Cornard","52.0245","0.7497","United Kingdom","GB","GBR","Suffolk","","8908","1826607712"
"Breganze","Breganze","45.7000","11.5667","Italy","IT","ITA","Veneto","","8586","1380592094"
"Veľký Meder","Vel'ky Meder","47.8564","17.7706","Slovakia","SK","SVK","Trnava","","8828","1703165181"
"Psyzh","Psyzh","44.2333","42.0167","Russia","RU","RUS","Karachayevo-Cherkesiya","","8836","1643579081"
"Vijayapuri","Vijayapuri","11.2300","77.5000","India","IN","IND","Tamil Nādu","","8602","1356695115"
"Potiraguá","Potiragua","-15.5950","-39.8769","Brazil","BR","BRA","Bahia","","8969","1076725236"
"Huasco","Huasco","-28.4664","-71.2192","Chile","CL","CHL","Atacama","","8977","1152643675"
"Tuscumbia","Tuscumbia","34.7204","-87.7035","United States","US","USA","Alabama","","8977","1840005567"
"Florânia","Florania","-6.1269","-36.8178","Brazil","BR","BRA","Rio Grande do Norte","","8959","1076075803"
"Teranikallu","Teranikallu","15.6392","77.5303","India","IN","IND","Andhra Pradesh","","8743","1356806492"
"Siachoque","Siachoque","5.5000","-73.2333","Colombia","CO","COL","Boyacá","minor","8964","1170608833"
"Săbăoani","Sabaoani","47.0167","26.8500","Romania","RO","ROU","Neamţ","","8722","1642853233"
"Angallu","Angallu","13.6287","78.4857","India","IN","IND","Andhra Pradesh","","8715","1356129922"
"Garching an der Alz","Garching an der Alz","48.1167","12.5833","Germany","DE","DEU","Bavaria","","8642","1276874361"
"Monastyryshche","Monastyryshche","48.9900","29.8011","Ukraine","UA","UKR","Cherkaska Oblast","","8925","1804117787"
"Capim Branco","Capim Branco","-19.5489","-44.1169","Brazil","BR","BRA","Minas Gerais","","8881","1076851522"
"Nuquí","Nuqui","5.7167","-77.2667","Colombia","CO","COL","Chocó","minor","8958","1170020146"
"Yaguará","Yaguara","2.6661","-75.5186","Colombia","CO","COL","Huila","minor","8952","1170717025"
"Tena","Tena","4.6547","-74.3900","Colombia","CO","COL","Cundinamarca","minor","8941","1170594078"
"Galmaarden","Galmaarden","50.7500","3.9667","Belgium","BE","BEL","Flanders","","8725","1056556479"
"Choceň","Chocen","50.0017","16.2231","Czechia","CZ","CZE","Pardubický Kraj","","8584","1203284714"
"’s-Heerenberg","'s-Heerenberg","51.8764","6.2458","Netherlands","NL","NLD","Gelderland","","8635","1528329380"
"Kui Buri","Kui Buri","12.0702","99.8667","Thailand","TH","THA","Prachuap Khiri Khan","minor","8916","1764298038"
"André Fernandes","Andre Fernandes","-15.9658","-41.4978","Brazil","BR","BRA","Minas Gerais","","8959","1076103551"
"Morpará","Morpara","-11.5589","-43.2808","Brazil","BR","BRA","Bahia","","8967","1076260332"
"Maina","Maina","23.1712","76.6629","India","IN","IND","Madhya Pradesh","","8634","1356320143"
"Lake Park","Lake Park","26.7998","-80.0681","United States","US","USA","Florida","","8971","1840017258"
"Saint-Vallier","Saint-Vallier","46.6419","4.3711","France","FR","FRA","Bourgogne-Franche-Comté","","8616","1250395807"
"Woodfield","Woodfield","34.0587","-80.9309","United States","US","USA","South Carolina","","8970","1840035853"
"Bumbeşti-Jiu","Bumbesti-Jiu","45.1786","23.3814","Romania","RO","ROU","Gorj","","8932","1642000047"
"Bhalaiana","Bhalaiana","30.3290","74.7120","India","IN","IND","Punjab","","8646","1356612923"
"Fair Oaks","Fair Oaks","33.9193","-84.5445","United States","US","USA","Georgia","","8968","1840013091"
"Ulātu","Ulatu","23.2766","85.5012","India","IN","IND","Jhārkhand","","8628","1356177961"
"Povarovo","Povarovo","56.0767","37.0622","Russia","RU","RUS","Moskovskaya Oblast’","","8834","1643208974"
"Innsbrook","Innsbrook","37.6552","-77.5775","United States","US","USA","Virginia","","8966","1840042829"
"Huntertown","Huntertown","41.2185","-85.1705","United States","US","USA","Indiana","","8965","1840010297"
"Borskoye","Borskoye","53.0261","51.6983","Russia","RU","RUS","Samarskaya Oblast’","","8953","1643086374"
"Kambhampādu","Kambhampadu","16.9822","80.6567","India","IN","IND","Andhra Pradesh","","8681","1356648635"
"Vicopisano","Vicopisano","43.6991","10.5831","Italy","IT","ITA","Tuscany","","8642","1380232149"
"Minerbio","Minerbio","44.6175","11.4717","Italy","IT","ITA","Emilia-Romagna","","8760","1380077571"
"Emirgazi","Emirgazi","37.9022","33.8372","Turkey","TR","TUR","Konya","minor","8949","1792817663"
"St. Pete Beach","St. Pete Beach","27.7235","-82.7387","United States","US","USA","Florida","","8963","1840015976"
"Luzzara","Luzzara","44.9667","10.6833","Italy","IT","ITA","Emilia-Romagna","","8962","1380947969"
"Penetanguishene","Penetanguishene","44.7667","-79.9333","Canada","CA","CAN","Ontario","","8962","1124304117"
"Dashouping","Dashouping","23.6488","111.6929","China","CN","CHN","Guangdong","","8913","1156791993"
"Ala","Ala","45.7500","11.0000","Italy","IT","ITA","Trentino-Alto Adige","","8887","1380811104"
"Winslow","Winslow","35.0243","-110.7095","United States","US","USA","Arizona","","8961","1840021583"
"Skidaway Island","Skidaway Island","31.9372","-81.0449","United States","US","USA","Georgia","","8961","1840029458"
"Sukand","Sukand","26.6444","78.4752","India","IN","IND","Madhya Pradesh","","8550","1356041102"
"Kāoni","Kaoni","30.4000","74.6630","India","IN","IND","Punjab","","8638","1356577792"
"Schleiz","Schleiz","50.5833","11.8167","Germany","DE","DEU","Thuringia","minor","8854","1276386563"
"Pallappālaiyam","Pallappalaiyam","11.3891","77.6027","India","IN","IND","Tamil Nādu","","8586","1356168735"
"Mudhol","Mudhol","16.3500","75.2833","India","IN","IND","Karnātaka","","8642","1356197896"
"Hawthorn Woods","Hawthorn Woods","42.2313","-88.0624","United States","US","USA","Illinois","","8959","1840011154"
"Ranod","Ranod","25.0748","77.8761","India","IN","IND","Madhya Pradesh","","8746","1356300439"
"Sint-Job-in-’t-Goor","Sint-Job-in-'t-Goor","51.3000","4.5667","Belgium","BE","BEL","Flanders","","8647","1056888422"
"Velaux","Velaux","43.5225","5.2539","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8616","1250819555"
"Strijen","Strijen","51.7500","4.5500","Netherlands","NL","NLD","Zuid-Holland","minor","8793","1528436754"
"Tlumach","Tlumach","48.8669","25.0012","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","8886","1804009591"
"Itapiranga","Itapiranga","-2.7489","-58.0219","Brazil","BR","BRA","Amazonas","","8953","1076151617"
"Greenville","Greenville","41.8820","-71.5549","United States","US","USA","Rhode Island","","8955","1840003292"
"Naunhof","Naunhof","51.2778","12.5883","Germany","DE","DEU","Saxony","","8735","1276828471"
"Old Orchard Beach","Old Orchard Beach","43.5239","-70.3904","United States","US","USA","Maine","","8954","1840052942"
"Vīrapalle","Virapalle","14.1500","78.8667","India","IN","IND","Andhra Pradesh","","8720","1356638614"
"Bad Lauchstädt","Bad Lauchstadt","51.3667","11.8333","Germany","DE","DEU","Saxony-Anhalt","","8849","1276634742"
"Harrodsburg","Harrodsburg","37.7654","-84.8474","United States","US","USA","Kentucky","","8952","1840013222"
"Kusterdingen","Kusterdingen","48.5222","9.1208","Germany","DE","DEU","Baden-Württemberg","","8597","1276384055"
"Arizona City","Arizona City","32.7506","-111.6707","United States","US","USA","Arizona","","8950","1840018030"
"Sekimachi","Sekimachi","33.0598","130.5438","Japan","JP","JPN","Kumamoto","","8821","1392125733"
"Ladue","Ladue","38.6377","-90.3815","United States","US","USA","Missouri","","8949","1840008599"
"Sucre","Sucre","2.0333","-76.9167","Colombia","CO","COL","Cauca","minor","8880","1170700284"
"Eslohe","Eslohe","51.2500","8.1667","Germany","DE","DEU","North Rhine-Westphalia","","8870","1276472967"
"Hampstead","Hampstead","42.8821","-71.1710","United States","US","USA","New Hampshire","","8948","1840054954"
"Carmo do Rio Verde","Carmo do Rio Verde","-15.3539","-49.7078","Brazil","BR","BRA","Goiás","","8928","1076166085"
"Khaur","Khaur","32.8229","74.5112","India","IN","IND","Jammu and Kashmīr","","8603","1356051009"
"Raonta","Raonta","30.5619","75.1900","India","IN","IND","Punjab","","8558","1356145072"
"Argelia","Argelia","5.7425","-75.1464","Colombia","CO","COL","Antioquia","minor","8911","1170927955"
"Beni Hassane","Beni Hassane","35.5700","10.8200","Tunisia","TN","TUN","Monastir","","8801","1788945888"
"Shahr-e Pīr","Shahr-e Pir","28.3106","54.3347","Iran","IR","IRN","Fārs","","8927","1364616917"
"Groß Kreutz","Gross Kreutz","52.3997","12.7831","Germany","DE","DEU","Brandenburg","","8856","1276310156"
"Bargaon","Bargaon","23.1795","84.6991","India","IN","IND","Jhārkhand","","8587","1356194356"
"Peebles","Peebles","55.6519","-3.1889","United Kingdom","GB","GBR","Scottish Borders","","8940","1826563131"
"Florø","Floro","61.5996","5.0328","Norway","NO","NOR","Vestland","minor","8925","1578596650"
"Ahuimanu","Ahuimanu","21.4379","-157.8404","United States","US","USA","Hawaii","","8943","1840029468"
"Qualicum Beach","Qualicum Beach","49.3500","-124.4333","Canada","CA","CAN","British Columbia","","8943","1124822520"
"Almagro","Almagro","38.8878","-3.7122","Spain","ES","ESP","Castille-La Mancha","","8907","1724161420"
"Grünheide","Grunheide","52.4255","13.8228","Germany","DE","DEU","Brandenburg","","8872","1276449341"
"Tepetitlan","Tepetitlan","20.1842","-99.3808","Mexico","MX","MEX","Hidalgo","","8893","1484277299"
"Santa Rita de Caldas","Santa Rita de Caldas","-22.0289","-46.3369","Brazil","BR","BRA","Minas Gerais","","8924","1076413095"
"Hooper","Hooper","41.1599","-112.2871","United States","US","USA","Utah","","8941","1840020130"
"Dourado","Dourado","-22.1000","-48.3178","Brazil","BR","BRA","São Paulo","","8897","1076827239"
"Hanko","Hanko","59.8333","22.9667","Finland","FI","FIN","Uusimaa","minor","8864","1246686751"
"Frei Inocêncio","Frei Inocencio","-18.5450","-41.9219","Brazil","BR","BRA","Minas Gerais","","8920","1076063892"
"Nesārg","Nesarg","15.9069","74.7740","India","IN","IND","Karnātaka","","8553","1356772334"
"Nunchía","Nunchia","5.6333","-72.2000","Colombia","CO","COL","Casanare","minor","8928","1170389960"
"Sarzeau","Sarzeau","47.5272","-2.7697","France","FR","FRA","Bretagne","","8791","1250153772"
"Helotes","Helotes","29.5687","-98.6960","United States","US","USA","Texas","","8937","1840020957"
"Clusone","Clusone","45.8833","9.9500","Italy","IT","ITA","Lombardy","","8608","1380661227"
"Paulo de Faria","Paulo de Faria","-20.0308","-49.3828","Brazil","BR","BRA","São Paulo","","8924","1076000170"
"Iskourane","Iskourane","30.8433","-9.8186","Morocco","MA","MAR","Marrakech-Safi","","8866","1504820847"
"Bockhorn","Bockhorn","53.4000","8.0167","Germany","DE","DEU","Lower Saxony","","8821","1276050347"
"Deutsch-Wagram","Deutsch-Wagram","48.2994","16.5644","Austria","AT","AUT","Niederösterreich","","8651","1040363748"
"Mikhaylovsk","Mikhaylovsk","56.4500","59.1333","Russia","RU","RUS","Sverdlovskaya Oblast’","","8921","1643402294"
"Kulu","Kulu","39.0892","33.0806","Turkey","TR","TUR","Konya","minor","8905","1792739957"
"Qashyr","Qashyr","53.0804","76.0900","Kazakhstan","KZ","KAZ","","minor","8931","1398362078"
"Park Forest Village","Park Forest Village","40.7996","-77.9084","United States","US","USA","Pennsylvania","","8933","1840035143"
"Tonk Khurd","Tonk Khurd","23.0983","76.2155","India","IN","IND","Madhya Pradesh","","8696","1356098475"
"Meine","Meine","52.3833","10.5333","Germany","DE","DEU","Lower Saxony","","8706","1276819678"
"Eceabat","Eceabat","40.1839","26.3564","Turkey","TR","TUR","Çanakkale","minor","8912","1792350740"
"Nārona","Narona","17.5156","76.7182","India","IN","IND","Karnātaka","","8725","1356458639"
"Carmen de Apicalá","Carmen de Apicala","4.1500","-74.7333","Colombia","CO","COL","Tolima","minor","8880","1170771576"
"Taxkorgan","Taxkorgan","37.7728","75.2244","China","CN","CHN","Xinjiang","minor","8919","1156209546"
"San Sebastian","San Sebastian","11.7000","125.0167","Philippines","PH","PHL","Samar","","8704","1608756540"
"Nottampatti","Nottampatti","9.9772","77.7177","India","IN","IND","Tamil Nādu","","8506","1356214250"
"İliç","Ilic","39.4536","38.5633","Turkey","TR","TUR","Erzincan","minor","8922","1792262442"
"Cockermouth","Cockermouth","54.6613","-3.3620","United Kingdom","GB","GBR","Cumbria","","8761","1826229039"
"Lequile","Lequile","40.3000","18.1333","Italy","IT","ITA","Puglia","","8689","1380982000"
"Cumberland Hill","Cumberland Hill","41.9736","-71.4605","United States","US","USA","Rhode Island","","8925","1840003291"
"Mannegudam","Mannegudam","17.4601","79.9177","India","IN","IND","Andhra Pradesh","","8622","1356933226"
"San Giuseppe Iato","San Giuseppe Iato","37.9667","13.1833","Italy","IT","ITA","Sicilia","","8634","1380085697"
"Vidapanakallu","Vidapanakallu","15.0667","77.1833","India","IN","IND","Andhra Pradesh","","8763","1356021169"
"Florida","Florida","-36.8167","-72.6667","Chile","CL","CHL","Biobío","","8916","1152800195"
"González","Gonzalez","8.4000","-73.3333","Colombia","CO","COL","Cesar","minor","8859","1170832293"
"Ararat","Ararat","-37.2833","142.9167","Australia","AU","AUS","Victoria","","8297","1036674088"
"Achacachi","Achacachi","-16.0444","-68.6850","Bolivia","BO","BOL","La Paz","","8857","1068370700"
"Karuppūr","Karuppur","10.4918","78.3296","India","IN","IND","Tamil Nādu","","8533","1356229042"
"Orte","Orte","42.4603","12.3861","Italy","IT","ITA","Lazio","","8795","1380622402"
"Suoyarvi","Suoyarvi","62.0833","32.3500","Russia","RU","RUS","Kareliya","","8920","1643031731"
"Araújos","Araujos","-19.9478","-45.1658","Brazil","BR","BRA","Minas Gerais","","8885","1076956280"
"Nerk’in Getashen","Nerk'in Getashen","40.1467","45.2642","Armenia","AM","ARM","Geghark’unik’","","8553","1051445812"
"Westwood","Westwood","42.3031","-85.6286","United States","US","USA","Michigan","","8920","1840006978"
"Ilarionove","Ilarionove","48.4059","35.2734","Ukraine","UA","UKR","Dnipropetrovska Oblast","","8854","1804506768"
"Serafimovich","Serafimovich","49.5833","42.7333","Russia","RU","RUS","Volgogradskaya Oblast’","","8914","1643516718"
"Srīrangāpur","Srirangapur","16.1917","78.0507","India","IN","IND","Andhra Pradesh","","8589","1356864545"
"Hilzingen","Hilzingen","47.7653","8.7844","Germany","DE","DEU","Baden-Württemberg","","8754","1276881310"
"Edgewater Park","Edgewater Park","40.0540","-74.9117","United States","US","USA","New Jersey","","8919","1840081632"
"Metsemotlhaba","Metsemotlhaba","-24.5531","25.8039","Botswana","BW","BWA","Kweneng","","8884","1072683214"
"São Sebastião do Alto","Sao Sebastiao do Alto","-21.9569","-42.1350","Brazil","BR","BRA","Rio de Janeiro","","8895","1076072454"
"Schwarzenbruck","Schwarzenbruck","49.3500","11.2333","Germany","DE","DEU","Bavaria","","8532","1276492629"
"Angola","Angola","41.6433","-85.0050","United States","US","USA","Indiana","","8916","1840007084"
"Penig","Penig","50.9336","12.7058","Germany","DE","DEU","Saxony","","8780","1276085565"
"Navipet","Navipet","18.8022","77.9836","India","IN","IND","Andhra Pradesh","","8563","1356624612"
"Bemiss","Bemiss","30.9318","-83.2386","United States","US","USA","Georgia","","8915","1840029379"
"Edgemere","Edgemere","39.2273","-76.4590","United States","US","USA","Maryland","","8915","1840005674"
"Saint-Chamas","Saint-Chamas","43.5503","5.0347","France","FR","FRA","Provence-Alpes-Côte d’Azur","","8593","1250140013"
"Jagannāthpur","Jagannathpur","22.2211","85.6392","India","IN","IND","Jhārkhand","","8599","1356154042"
"Charters Towers","Charters Towers","-20.0765","146.2614","Australia","AU","AUS","Queensland","","8126","1036733088"
"Basrūr","Basrur","13.6308","74.7377","India","IN","IND","Karnātaka","","8622","1356088496"
"Malhada de Pedras","Malhada de Pedras","-14.3878","-41.8789","Brazil","BR","BRA","Bahia","","8896","1076894314"
"Topchikha","Topchikha","52.8211","83.1197","Russia","RU","RUS","Altayskiy Kray","","8906","1643939856"
"Silleda","Silleda","42.7000","-8.2333","Spain","ES","ESP","Galicia","","8860","1724280384"
"Baía da Traição","Baia da Traicao","-6.6878","-34.9358","Brazil","BR","BRA","Paraíba","","8826","1076067542"
"Camenca","Camenca","48.0167","28.7167","Moldova","MD","MDA","Stînga Nistrului","","8871","1498446889"
"Lapeer","Lapeer","43.0447","-83.3253","United States","US","USA","Michigan","","8911","1840002896"
"Vairichettipālaiyam","Vairichettipalaiyam","11.2872","78.4893","India","IN","IND","Tamil Nādu","","8552","1356258651"
"Chapel en le Frith","Chapel en le Frith","53.3220","-1.9170","United Kingdom","GB","GBR","Derbyshire","","8635","1826041922"
"Shelawādi","Shelawadi","15.5833","75.4667","India","IN","IND","Karnātaka","","8705","1356596367"
"Purcellville","Purcellville","39.1378","-77.7110","United States","US","USA","Virginia","","8910","1840005875"
"Kozlovka","Kozlovka","55.8500","48.2500","Russia","RU","RUS","Chuvashiya","","8866","1643561903"
"Pibrac","Pibrac","43.6169","1.2847","France","FR","FRA","Occitanie","","8578","1250211054"
"Fishersville","Fishersville","38.1050","-78.9826","United States","US","USA","Virginia","","8909","1840006240"
"Takua Pa","Takua Pa","8.8658","98.3413","Thailand","TH","THA","Phangnga","minor","8815","1764000562"
"Polegate","Polegate","50.8216","0.2442","United Kingdom","GB","GBR","East Sussex","","8586","1826857740"
"Makhambet","Makhambet","47.6667","51.5833","Kazakhstan","KZ","KAZ","","minor","8905","1398232608"
"Napoleon","Napoleon","41.3977","-84.1244","United States","US","USA","Ohio","","8907","1840008242"
"Wattwil","Wattwil","47.2957","9.0830","Switzerland","CH","CHE","Sankt Gallen","","8736","1756957329"
"San José de Gracia","San Jose de Gracia","22.1500","-102.4167","Mexico","MX","MEX","Aguascalientes","minor","8896","1484004442"
"Guntapalli","Guntapalli","14.7385","79.0400","India","IN","IND","Andhra Pradesh","","8677","1356594674"
"Nueva Guadalupe","Nueva Guadalupe","13.5333","-88.3500","El Salvador","SV","SLV","San Miguel","","8905","1222469128"
"Rāiparthi","Raiparthi","17.7042","79.6081","India","IN","IND","Andhra Pradesh","","8618","1356005960"
"Barth","Barth","54.3667","12.7167","Germany","DE","DEU","Mecklenburg-Western Pomerania","","8692","1276722582"
"Taftanāz","Taftanaz","35.9969","36.7867","Syria","SY","SYR","Idlib","minor","8540","1760676375"
"Halikko","Halikko","60.3972","23.0778","Finland","FI","FIN","Varsinais-Suomi","","8879","1246263188"
"Hale Dyāmavvanahalli","Hale Dyamavvanahalli","14.2682","76.4904","India","IN","IND","Karnātaka","","8573","1356119169"
"Yarmouth","Yarmouth","43.7978","-70.1719","United States","US","USA","Maine","","8903","1840053071"
"Holagondi","Holagondi","15.0200","75.9500","India","IN","IND","Karnātaka","","8682","1356622289"
"Alvarado","Alvarado","4.5667","-74.9500","Colombia","CO","COL","Tolima","minor","8873","1170398878"
"Obertraubling","Obertraubling","48.9658","12.1669","Germany","DE","DEU","Bavaria","","8557","1276906491"
"Caister-on-Sea","Caister-on-Sea","52.6510","1.7330","United Kingdom","GB","GBR","Norfolk","","8901","1826817641"
"Bāgalvād","Bagalvad","16.0528","76.8877","India","IN","IND","Karnātaka","","8682","1356983749"
"Hunduan","Hunduan","16.8333","121.0000","Philippines","PH","PHL","Ifugao","","8866","1608819248"
"Msemrir","Msemrir","31.7028","-5.8125","Morocco","MA","MAR","Drâa-Tafilalet","","8866","1504469179"
"Brejão","Brejao","-9.0300","-36.5689","Brazil","BR","BRA","Pernambuco","","8844","1076400977"
"Pudozh","Pudozh","61.8000","36.5167","Russia","RU","RUS","Kareliya","","8897","1643479302"
"Minervino Murge","Minervino Murge","41.1000","16.0833","Italy","IT","ITA","Puglia","","8864","1380851317"
"Hürtgenwald","Hurtgenwald","50.7172","6.3797","Germany","DE","DEU","North Rhine-Westphalia","","8798","1276899490"
"Summit","Summit","47.1694","-122.3628","United States","US","USA","Washington","","8897","1840018456"
"Ouriçangas","Ouricangas","-12.0169","-38.6169","Brazil","BR","BRA","Bahia","","8839","1076631656"
"Ait Ikkou","Ait Ikkou","33.5667","-5.6500","Morocco","MA","MAR","Fès-Meknès","","8865","1504000515"
"Highland Park","Highland Park","32.8311","-96.8012","United States","US","USA","Texas","","8895","1840022058"
"Shoshong","Shoshong","-23.0333","26.5167","Botswana","BW","BWA","Central","","8887","1072225967"
"Teruel","Teruel","2.7500","-75.5667","Colombia","CO","COL","Huila","minor","8876","1170243677"
"Santa María Xadani","Santa Maria Xadani","16.3667","-95.0167","Mexico","MX","MEX","Oaxaca","minor","8795","1484494009"
"Manteno","Manteno","41.2470","-87.8457","United States","US","USA","Illinois","","8893","1840011703"
"North Haledon","North Haledon","40.9628","-74.1844","United States","US","USA","New Jersey","","8893","1840000836"
"Hlevakha","Hlevakha","50.2604","30.3057","Ukraine","UA","UKR","Kyivska Oblast","","8849","1804118267"
"Belsh","Belsh","40.9833","19.8833","Albania","AL","ALB","Elbasan","","8781","1008080226"
"Pedda Penki","Pedda Penki","18.5853","83.5069","India","IN","IND","Andhra Pradesh","","8507","1356194038"
"Merrydale","Merrydale","30.4998","-91.1081","United States","US","USA","Louisiana","","8889","1840013940"
"San Martín de Valdeiglesias","San Martin de Valdeiglesias","40.3640","-4.4010","Spain","ES","ESP","Madrid","","8812","1724237441"
"Indian Harbour Beach","Indian Harbour Beach","28.1529","-80.5976","United States","US","USA","Florida","","8888","1840015096"
"Koror","Koror","7.3419","134.4792","Palau","PW","PLW","Koror","","8744","1585174728"
"Rodelas","Rodelas","-8.8508","-38.7558","Brazil","BR","BRA","Bahia","","8887","1076961333"
"Kamień Pomorski","Kamien Pomorski","53.9700","14.7725","Poland","PL","POL","Zachodniopomorskie","minor","8807","1616000112"
"Imilchil","Imilchil","32.1550","-5.6347","Morocco","MA","MAR","Drâa-Tafilalet","","8870","1504516929"
"Kolbuszowa","Kolbuszowa","50.2500","21.7667","Poland","PL","POL","Podkarpackie","minor","8732","1616946964"
"Belhatti","Belhatti","15.0818","75.6465","India","IN","IND","Karnātaka","","8656","1356178257"
"Bussy","Bussy","46.5500","6.5500","Switzerland","CH","CHE","Vaud","","8736","1756000766"
"Palhano","Palhano","-4.7450","-37.9589","Brazil","BR","BRA","Ceará","","8866","1076664168"
"Anaurilândia","Anaurilandia","-22.1878","-52.7178","Brazil","BR","BRA","Mato Grosso do Sul","","8885","1076668503"
"Gilgit","Gilgit","35.9208","74.3083","Pakistan","PK","PAK","Gilgit-Baltistan","minor","8851","1586284242"
"Seosaeng","Seosaeng","35.3536","129.3303","South Korea","KR","KOR","Ulsan","","8650","1410000467"
"Ak’ordat","Ak'ordat","15.5500","37.8833","Eritrea","ER","ERI","Gash-Barka","","8857","1232800240"
"Plymouth","Plymouth","43.7447","-87.9660","United States","US","USA","Wisconsin","","8883","1840002626"
"Audubon","Audubon","40.1304","-75.4280","United States","US","USA","Pennsylvania","","8883","1840034852"
"Vif","Vif","45.0553","5.6700","France","FR","FRA","Auvergne-Rhône-Alpes","","8580","1250941912"
"Nova Bassano","Nova Bassano","-28.7239","-51.7050","Brazil","BR","BRA","Rio Grande do Sul","","8840","1076902566"
"Vráble","Vrable","48.2408","18.3086","Slovakia","SK","SVK","Nitra","","8656","1703078244"
"Rignano sull’Arno","Rignano sull'Arno","43.7237","11.4507","Italy","IT","ITA","Tuscany","","8718","1380785951"
"San Martín Hidalgo","San Martin Hidalgo","20.4350","-103.9286","Mexico","MX","MEX","Jalisco","minor","8850","1484808441"
"Carlosama","Carlosama","0.8658","-77.7294","Colombia","CO","COL","Nariño","minor","8727","1170333785"
"Tādināda","Tadinada","16.5470","81.3219","India","IN","IND","Andhra Pradesh","","8548","1356802831"
"Dilāwarpur","Dilawarpur","19.0908","78.2275","India","IN","IND","Andhra Pradesh","","8657","1356799167"
"Zanica","Zanica","45.6394","9.6842","Italy","IT","ITA","Lombardy","","8744","1380768500"
"Guichen","Guichen","47.9675","-1.7950","France","FR","FRA","Bretagne","","8674","1250530075"
"Brandizzo","Brandizzo","45.1766","7.8380","Italy","IT","ITA","Piedmont","","8822","1380098781"
"Loenen","Loenen","52.2419","5.0275","Netherlands","NL","NLD","Utrecht","","8562","1528450020"
"Āmudālapalle","Amudalapalle","15.9301","80.6899","India","IN","IND","Andhra Pradesh","","8591","1356024163"
"Old Jefferson","Old Jefferson","30.3776","-91.0060","United States","US","USA","Louisiana","","8875","1840031108"
"Aït Ouaoumana","Ait Ouaoumana","32.7128","-5.8042","Morocco","MA","MAR","Béni Mellal-Khénifra","","8849","1504002343"
"Lāndupdīh","Landupdih","23.1478","85.7817","India","IN","IND","Jhārkhand","","8568","1356188355"
"Föritz","Foritz","50.3500","11.2417","Germany","DE","DEU","Thuringia","","8787","1276969681"
"Oak Grove","Oak Grove","45.3409","-93.3264","United States","US","USA","Minnesota","","8870","1840007799"
"Neya","Neya","58.3000","43.8667","Russia","RU","RUS","Kostromskaya Oblast’","","8865","1643055534"
"Maipú","Maipu","-36.8667","-57.8667","Argentina","AR","ARG","Buenos Aires","minor","8865","1032738375"
"Buda-Kashalyova","Buda-Kashalyova","52.7167","30.5667","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","8850","1112915706"
"Notre-Dame-des-Prairies","Notre-Dame-des-Prairies","46.0500","-73.4333","Canada","CA","CAN","Quebec","","8868","1124001393"
"Campogalliano","Campogalliano","44.6833","10.8500","Italy","IT","ITA","Emilia-Romagna","","8808","1380304896"
"Reddiyapatti","Reddiyapatti","10.1581","78.1901","India","IN","IND","Tamil Nādu","","8541","1356222877"
"Bouhlou","Bouhlou","34.1333","-4.4000","Morocco","MA","MAR","Fès-Meknès","","8748","1504965177"
"Weilmünster","Weilmunster","50.4333","8.3667","Germany","DE","DEU","Hesse","","8753","1276313563"
"Temperance","Temperance","41.7653","-83.5755","United States","US","USA","Michigan","","8866","1840006579"
"Erchie","Erchie","40.4333","17.7333","Italy","IT","ITA","Puglia","","8671","1380916744"
"West Perth","West Perth","43.4700","-81.2000","Canada","CA","CAN","Ontario","","8865","1124001056"
"Kuvshinovo","Kuvshinovo","57.0333","34.1833","Russia","RU","RUS","Tverskaya Oblast’","","8857","1643901783"
"Medikunda","Medikunda","15.9581","77.6095","India","IN","IND","Andhra Pradesh","","8520","1356989042"
"Ponta do Sol","Ponta do Sol","32.6806","-17.1042","Portugal","PT","PRT","Madeira","","8862","1620041092"
"Moe","Moe","-38.1722","146.2678","Australia","AU","AUS","Victoria","","8778","1036922580"
"Roetgen","Roetgen","50.6500","6.2000","Germany","DE","DEU","North Rhine-Westphalia","","8640","1276508343"
"Perungulam","Perungulam","8.6413","77.9985","India","IN","IND","Tamil Nādu","","8516","1356222825"
"Shiddāpūr","Shiddapur","13.6635","74.9121","India","IN","IND","Karnātaka","","8570","1356920600"
"Roxborough Park","Roxborough Park","39.4492","-105.0746","United States","US","USA","Colorado","","8861","1840028568"
"Entrerríos","Entrerrios","6.5667","-75.5167","Colombia","CO","COL","Antioquia","minor","8820","1170412234"
"Agadir Melloul","Agadir Melloul","30.2167","-7.8000","Morocco","MA","MAR","Souss-Massa","","8848","1504880181"
"Presque Isle","Presque Isle","46.6868","-67.9874","United States","US","USA","Maine","","8859","1840000146"
"Iaboutene","Iaboutene","35.0670","-3.9670","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","8715","1504286746"
"Bala Cynwyd","Bala Cynwyd","40.0116","-75.2283","United States","US","USA","Pennsylvania","","8858","1840153024"
"Coutras","Coutras","45.0408","-0.1289","France","FR","FRA","Nouvelle-Aquitaine","","8602","1250067889"
"Talwandi Chaudhriān","Talwandi Chaudhrian","31.3000","75.1764","India","IN","IND","Punjab","","8536","1356139244"
"Lajedo do Tabocal","Lajedo do Tabocal","-13.4750","-40.2239","Brazil","BR","BRA","Bahia","","8836","1076955276"
"São José da Bela Vista","Sao Jose da Bela Vista","-20.5928","-47.6400","Brazil","BR","BRA","São Paulo","","8823","1076871004"
"Pa Sang","Pa Sang","18.5261","98.9394","Thailand","TH","THA","Lamphun","minor","8645","1764346712"
"Taragi","Taragi","32.2640","130.9357","Japan","JP","JPN","Kumamoto","","8800","1392044742"
"Soeda","Soeda","33.5718","130.8540","Japan","JP","JPN","Fukuoka","","8786","1392649831"
"Jacareacanga","Jacareacanga","-6.2239","-57.7539","Brazil","BR","BRA","Pará","","8852","1076980493"
"Rice Lake","Rice Lake","45.4864","-91.7447","United States","US","USA","Wisconsin","","8851","1840002075"
"Maysville","Maysville","38.6455","-83.7911","United States","US","USA","Kentucky","","8851","1840015180"
"Cordeiros","Cordeiros","-15.0389","-41.9350","Brazil","BR","BRA","Bahia","","8834","1076456369"
"Cullinan","Cullinan","-25.6728","28.5208","South Africa","ZA","ZAF","Gauteng","","8693","1710780636"
"Rrëshen","Rreshen","41.7667","19.8833","Albania","AL","ALB","Lezhë","","8803","1008845269"
"Mangala","Mangala","11.9998","76.9594","India","IN","IND","Karnātaka","","8531","1356138601"
"Deniliquin","Deniliquin","-35.5331","144.9667","Australia","AU","AUS","New South Wales","","7862","1036325219"
"Jolfā","Jolfa","38.9308","45.6383","Iran","IR","IRN","Āz̄arbāyjān-e Sharqī","minor","8810","1364456740"
"Nagykovácsi","Nagykovacsi","47.5800","18.8800","Hungary","HU","HUN","Pest","","8536","1348566007"
"Tausa","Tausa","5.1964","-73.8875","Colombia","CO","COL","Cundinamarca","minor","8801","1170046469"
"Castelnuovo di Porto","Castelnuovo di Porto","42.1333","12.5000","Italy","IT","ITA","Lazio","","8564","1380815499"
"Clinton","Clinton","38.3716","-93.7679","United States","US","USA","Missouri","","8844","1840007492"
"Toppenish","Toppenish","46.3806","-120.3122","United States","US","USA","Washington","","8843","1840021151"
"West Donegal","West Donegal","40.1297","-76.6226","United States","US","USA","Pennsylvania","","8841","1840142496"
"Woodmoor","Woodmoor","39.1063","-104.8456","United States","US","USA","Colorado","","8840","1840028584"
"Efringen-Kirchen","Efringen-Kirchen","47.6556","7.5658","Germany","DE","DEU","Baden-Württemberg","","8642","1276766634"
"Amarzgane","Amarzgane","31.0500","-7.2167","Morocco","MA","MAR","Drâa-Tafilalet","","8820","1504948640"
"Großbottwar","Grossbottwar","49.0014","9.2931","Germany","DE","DEU","Baden-Württemberg","","8512","1276002930"
"Marilândia do Sul","Marilandia do Sul","-23.7450","-51.3078","Brazil","BR","BRA","Paraná","","8814","1076477142"
"Saint-Jean-de-Monts","Saint-Jean-de-Monts","46.7928","-2.0603","France","FR","FRA","Pays de la Loire","","8696","1250173177"
"Phimai","Phimai","15.2229","102.4940","Thailand","TH","THA","Nakhon Ratchasima","minor","8696","1764598578"
"Rudravaram","Rudravaram","15.2660","78.6280","India","IN","IND","Andhra Pradesh","","8740","1356670795"
"Pianella","Pianella","42.4000","14.0500","Italy","IT","ITA","Abruzzo","","8652","1380759537"
"Jeannette","Jeannette","40.3277","-79.6139","United States","US","USA","Pennsylvania","","8835","1840001141"
"North Londonderry","North Londonderry","40.3227","-76.5867","United States","US","USA","Pennsylvania","","8835","1840149647"
"Ban Ratchakrut","Ban Ratchakrut","9.7571","98.5915","Thailand","TH","THA","Ranong","","8569","1764197759"
"Shopokov","Shopokov","42.8400","74.3100","Kyrgyzstan","KG","KGZ","Chüy","","8749","1417801567"
"Minyar","Minyar","55.0667","57.5500","Russia","RU","RUS","Chelyabinskaya Oblast’","","8768","1643182106"
"Trujillo","Trujillo","39.4653","-5.8789","Spain","ES","ESP","Extremadura","","8821","1724377228"
"Lienen","Lienen","52.1461","7.9739","Germany","DE","DEU","North Rhine-Westphalia","","8715","1276837503"
"Bainbridge","Bainbridge","41.3855","-81.3478","United States","US","USA","Ohio","","8833","1840152715"
"Zavāreh","Zavareh","33.4489","52.4936","Iran","IR","IRN","Eşfahān","","8830","1364819342"
"Dornstadt","Dornstadt","48.4692","9.9417","Germany","DE","DEU","Baden-Württemberg","","8686","1276592459"
"Sonsbeck","Sonsbeck","51.6089","6.3769","Germany","DE","DEU","North Rhine-Westphalia","","8675","1276567959"
"Sopot","Sopot","42.6500","24.7500","Bulgaria","BG","BGR","Plovdiv","","8595","1100878976"
"Bálsamo","Balsamo","-20.7350","-49.5839","Brazil","BR","BRA","São Paulo","","8773","1076470597"
"Castelbuono","Castelbuono","37.9333","14.1000","Italy","IT","ITA","Sicilia","","8688","1380870849"
"Ōtaki","Otaki","35.2852","140.2454","Japan","JP","JPN","Chiba","","8762","1392151200"
"Fort Valley","Fort Valley","32.5520","-83.8817","United States","US","USA","Georgia","","8829","1840013819"
"Cavan Monaghan","Cavan Monaghan","44.2000","-78.4667","Canada","CA","CAN","Ontario","","8829","1124001281"
"El Marmouta","El Marmouta","32.0838","-7.4091","Morocco","MA","MAR","Marrakech-Safi","","8774","1504673974"
"Carbondale","Carbondale","41.5714","-75.5048","United States","US","USA","Pennsylvania","","8828","1840003376"
"Karis","Karis","60.0708","23.6625","Finland","FI","FIN","Uusimaa","","8787","1246155619"
"Kolumalapalle","Kolumalapalle","15.4774","78.1293","India","IN","IND","Andhra Pradesh","","8541","1356220684"
"Boville Ernica","Boville Ernica","41.6500","13.4667","Italy","IT","ITA","Lazio","","8525","1380451454"
"Bertrix","Bertrix","49.8542","5.2533","Belgium","BE","BEL","Wallonia","","8763","1056726462"
"Reyes","Reyes","-14.2958","-67.3353","Bolivia","BO","BOL","El Beni","","8824","1068609630"
"Gulf Hills","Gulf Hills","30.4367","-88.8150","United States","US","USA","Mississippi","","8825","1840013930"
"Mansfeld","Mansfeld","51.5942","11.4547","Germany","DE","DEU","Saxony-Anhalt","","8765","1276609161"
"Glencoe","Glencoe","42.1347","-87.7641","United States","US","USA","Illinois","","8824","1840011279"
"Benahavís","Benahavis","36.5190","-5.0454","Spain","ES","ESP","Andalusia","","8763","1724136228"
"Tordesillas","Tordesillas","41.5000","-5.0000","Spain","ES","ESP","Castille-Leon","","8762","1724737891"
"Anantasāgaram","Anantasagaram","14.5715","79.4075","India","IN","IND","Andhra Pradesh","","8588","1356087662"
"Kryzhopil","Kryzhopil","48.3842","28.8625","Ukraine","UA","UKR","Vinnytska Oblast","minor","8788","1804820411"
"Hosuru","Hosuru","13.7399","77.4312","India","IN","IND","Karnātaka","","8574","1356225740"
"Mürzzuschlag","Murzzuschlag","47.6075","15.6731","Austria","AT","AUT","Steiermark","","8654","1040974582"
"Yaragol","Yaragol","16.9047","77.0661","India","IN","IND","Karnātaka","","8554","1356567613"
"Pol-e Sefīd","Pol-e Sefid","36.1178","53.0553","Iran","IR","IRN","Māzandarān","minor","8794","1364370217"
"Serra Caiada","Serra Caiada","-6.1058","-35.7128","Brazil","BR","BRA","Rio Grande do Norte","","8768","1076713679"
"Nakaseke","Nakaseke","0.7300","32.4150","Uganda","UG","UGA","Nakaseke","","8600","1800304390"
"Guajeru","Guajeru","-14.5469","-41.9400","Brazil","BR","BRA","Bahia","","8805","1076659930"
"Yacimiento Río Turbio","Yacimiento Rio Turbio","-51.5333","-72.3000","Argentina","AR","ARG","Santa Cruz","","8814","1032704902"
"Clayton","Clayton","39.6627","-75.0782","United States","US","USA","New Jersey","","8818","1840001520"
"Beibu","Beibu","24.6639","121.0681","Taiwan","TW","TWN","Hsinchu","","8647","1158656385"
"Kappeln","Kappeln","54.6614","9.9311","Germany","DE","DEU","Schleswig-Holstein","","8619","1276715573"
"Tarīchar Kalān","Tarichar Kalan","25.4118","78.8900","India","IN","IND","Madhya Pradesh","","8502","1356090689"
"Locust Grove","Locust Grove","33.3446","-84.1071","United States","US","USA","Georgia","","8816","1840015705"
"Perryton","Perryton","36.3928","-100.7976","United States","US","USA","Texas","","8815","1840020379"
"G‘ozg‘on","G\`ozg\`on","40.5944","65.4964","Uzbekistan","UZ","UZB","Navoiy","","8800","1860129710"
"Caimanera","Caimanera","19.9947","-75.1600","Cuba","CU","CUB","Guantánamo","","8790","1192287141"
"Gorodoviki","Gorodoviki","46.1353","41.9656","Russia","RU","RUS","Kalmykiya","","8798","1643422244"
"Soltsy","Soltsy","58.1333","30.3167","Russia","RU","RUS","Novgorodskaya Oblast’","","8803","1643041908"
"Padmapuram","Padmapuram","19.2428","83.8181","India","IN","IND","Odisha","","8619","1356058311"
"Nanzhangcheng","Nanzhangcheng","37.9108","114.0749","China","CN","CHN","Hebei","","8707","1156201324"
"Bayport","Bayport","40.7461","-73.0546","United States","US","USA","New York","","8813","1840005018"
"Pitman","Pitman","39.7335","-75.1306","United States","US","USA","New Jersey","","8812","1840001521"
"Cedral","Cedral","-20.9028","-49.2678","Brazil","BR","BRA","São Paulo","","8767","1076000902"
"Meshkān","Meshkan","29.4769","54.3314","Iran","IR","IRN","Fārs","","8799","1364304938"
"Agnita","Agnita","45.9731","24.6172","Romania","RO","ROU","Sibiu","","8732","1642697925"
"Monmouth","Monmouth","40.9140","-90.6425","United States","US","USA","Illinois","","8810","1840008283"
"Lopatcong","Lopatcong","40.7091","-75.1551","United States","US","USA","New Jersey","","8810","1840081771"
"Yang Talat","Yang Talat","16.3997","103.3678","Thailand","TH","THA","Kalasin","minor","8666","1764746980"
"Nam Som","Nam Som","17.7694","102.1435","Thailand","TH","THA","Udon Thani","minor","8742","1764181212"
"Sidi Dahbi","Sidi Dahbi","33.0500","-7.1833","Morocco","MA","MAR","Casablanca-Settat","","8703","1504613432"
"Kuhmo","Kuhmo","64.1250","29.5167","Finland","FI","FIN","Kainuu","minor","8806","1246485252"
"Yellāreddi","Yellareddi","18.5239","78.5850","India","IN","IND","Andhra Pradesh","","8590","1356045904"
"Rock Falls","Rock Falls","41.7724","-89.6928","United States","US","USA","Illinois","","8807","1840009202"
"Tsallagundla","Tsallagundla","16.3522","79.9619","India","IN","IND","Andhra Pradesh","","8537","1356234699"
"Sidi el Mokhfi","Sidi el Mokhfi","34.6039","-4.7889","Morocco","MA","MAR","Fès-Meknès","","8705","1504651133"
"Heek","Heek","52.1167","7.0997","Germany","DE","DEU","North Rhine-Westphalia","","8681","1276008522"
"Hassi Berkane","Hassi Berkane","34.8333","-2.8667","Morocco","MA","MAR","Oriental","","8788","1504780736"
"Oakland","Oakland","35.2256","-89.5372","United States","US","USA","Tennessee","","8805","1840016407"
"Iles","Iles","0.9667","-77.5167","Colombia","CO","COL","Nariño","minor","8701","1170251090"
"Chintakommadinne","Chintakommadinne","14.4267","78.7618","India","IN","IND","Andhra Pradesh","","8661","1356452525"
"Arico el Nuevo","Arico el Nuevo","28.1904","-16.4977","Spain","ES","ESP","Canary Islands","","8754","1724911313"
"Mascoutah","Mascoutah","38.5192","-89.8045","United States","US","USA","Illinois","","8803","1840008631"
"Flieden","Flieden","50.4231","9.5658","Germany","DE","DEU","Hesse","","8629","1276338969"
"Vestignè","Vestigne","45.3833","7.9500","Italy","IT","ITA","Piedmont","","8796","1380591372"
"Aurisina","Aurisina","45.7333","13.7500","Italy","IT","ITA","Friuli Venezia Giulia","","8609","1380187314"
"Tiqqi","Tiqqi","29.8667","-9.0667","Morocco","MA","MAR","Souss-Massa","","8773","1504073397"
"Fairfield Glade","Fairfield Glade","36.0028","-84.8711","United States","US","USA","Tennessee","","8798","1840013067"
"Kukrahill","Kukrahill","12.2428","-83.7486","Nicaragua","NI","NIC","Costa Caribe Sur","minor","8790","1558733102"
"San Ignacio","San Ignacio","14.6500","-87.0333","Honduras","HN","HND","Francisco Morazán","","8796","1340809113"
"Vohburg an der Donau","Vohburg an der Donau","48.7667","11.6167","Germany","DE","DEU","Bavaria","","8605","1276723735"
"Arnprior","Arnprior","45.4333","-76.3500","Canada","CA","CAN","Ontario","","8795","1124700031"
"Conthey","Conthey","46.2167","7.3000","Switzerland","CH","CHE","Valais","","8691","1756401433"
"Savoy","Savoy","40.0600","-88.2552","United States","US","USA","Illinois","","8793","1840012236"
"Tudela de Duero","Tudela de Duero","41.5842","-4.5800","Spain","ES","ESP","Castille-Leon","","8648","1724835733"
"Thief River Falls","Thief River Falls","48.1108","-96.1778","United States","US","USA","Minnesota","","8791","1840008825"
"Mariinskiy Posad","Mariinskiy Posad","56.1000","47.7167","Russia","RU","RUS","Chuvashiya","","8755","1643337574"
"Bad Sooden-Allendorf","Bad Sooden-Allendorf","51.2833","9.9833","Germany","DE","DEU","Hesse","","8675","1276874812"
"Tirschenreuth","Tirschenreuth","49.8833","12.3333","Germany","DE","DEU","Bavaria","minor","8707","1276972262"
"Qorovul","Qorovul","41.5569","60.5914","Uzbekistan","UZ","UZB","Xorazm","minor","8520","1860937148"
"Belozërsk","Belozersk","60.0333","37.7667","Russia","RU","RUS","Vologodskaya Oblast’","","8786","1643349305"
"Zapatoca","Zapatoca","6.8167","-73.2667","Colombia","CO","COL","Santander","minor","8762","1170912223"
"Ivins","Ivins","37.1742","-113.6809","United States","US","USA","Utah","","8786","1840018937"
"Village St. George","Village St. George","30.3598","-91.0672","United States","US","USA","Louisiana","","8786","1840031109"
"Audenge","Audenge","44.6836","-1.0133","France","FR","FRA","Nouvelle-Aquitaine","","8680","1250207639"
"Iernut","Iernut","46.4536","24.2333","Romania","RO","ROU","Mureş","","8705","1642578622"
"Hūdem","Hudem","14.9100","76.4000","India","IN","IND","Karnātaka","","8553","1356079069"
"Habo","Habo","57.9167","14.0667","Sweden","SE","SWE","Jönköping","minor","8753","1752321919"
"Sodankylä","Sodankyla","67.4167","26.5833","Finland","FI","FIN","Lappi","minor","8782","1246413171"
"Orting","Orting","47.0967","-122.2112","United States","US","USA","Washington","","8781","1840019858"
"Merksplas","Merksplas","51.3667","4.8667","Belgium","BE","BEL","Flanders","","8588","1056591557"
"Candiota","Candiota","-31.5578","-53.6728","Brazil","BR","BRA","Rio Grande do Sul","","8771","1076794665"
"Smiths Falls","Smiths Falls","44.9000","-76.0167","Canada","CA","CAN","Ontario","","8780","1124233827"
"Stainz","Stainz","46.8942","15.2639","Austria","AT","AUT","Steiermark","","8686","1040766288"
"Nūlvi","Nulvi","15.2728","75.1673","India","IN","IND","Karnātaka","","8552","1356323163"
"Roznov","Roznov","46.8356","26.5117","Romania","RO","ROU","Neamţ","","8593","1642134720"
"Kulpsville","Kulpsville","40.2440","-75.3407","United States","US","USA","Pennsylvania","","8775","1840005465"
"Tanudan","Tanudan","17.2814","121.2303","Philippines","PH","PHL","Kalinga","","8746","1608424545"
"Āshtīān","Ashtian","34.5219","50.0061","Iran","IR","IRN","Markazī","minor","8763","1364425405"
"Miyada","Miyada","35.7689","137.9443","Japan","JP","JPN","Nagano","","8616","1392560952"
"Port Jervis","Port Jervis","41.3783","-74.6910","United States","US","USA","New York","","8772","1840000587"
"Monte San Savino","Monte San Savino","43.3333","11.7333","Italy","IT","ITA","Tuscany","","8675","1380861572"
"Azandarīān","Azandarian","34.5075","48.6892","Iran","IR","IRN","Hamadān","","8685","1364125839"
"Signal Mountain","Signal Mountain","35.1448","-85.3457","United States","US","USA","Tennessee","","8770","1840017859"
"Boonton","Boonton","40.9047","-74.4048","United States","US","USA","New Jersey","","8770","1840000946"
"Tleta Taghramt","Tleta Taghramt","35.7877","-5.4677","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","8706","1504015428"
"Tashir","Tashir","41.1244","44.2819","Armenia","AM","ARM","Lorri","","8700","1051680734"
"Lago Vista","Lago Vista","30.4519","-97.9908","United States","US","USA","Texas","","8769","1840020893"
"Mücheln","Mucheln","51.3000","11.8000","Germany","DE","DEU","Saxony-Anhalt","","8681","1276286774"
"Franklin","Franklin","43.4499","-71.6691","United States","US","USA","New Hampshire","","8766","1840002748"
"Mandalapalle","Mandalapalle","14.0209","77.7511","India","IN","IND","Andhra Pradesh","","8508","1356566230"
"Cape St. Claire","Cape St. Claire","39.0433","-76.4471","United States","US","USA","Maryland","","8762","1840005908"
"Hajdúdorog","Hajdudorog","47.8167","21.5000","Hungary","HU","HUN","Hajdú-Bihar","","8677","1348352479"
"Gigmoto","Gigmoto","13.7833","124.3833","Philippines","PH","PHL","Catanduanes","","8712","1608786417"
"Ustrzyki Dolne","Ustrzyki Dolne","49.4297","22.5867","Poland","PL","POL","Podkarpackie","minor","8717","1616817418"
"Bloomingdale","Bloomingdale","36.5793","-82.5096","United States","US","USA","Tennessee","","8759","1840013297"
"Großräschen","Grossraschen","51.5831","14.0000","Germany","DE","DEU","Brandenburg","","8655","1276514067"
"Calbe","Calbe","51.9033","11.7758","Germany","DE","DEU","Saxony-Anhalt","","8609","1276644969"
"Hausjärvi","Hausjarvi","60.7884","25.0247","Finland","FI","FIN","Kanta-Häme","","8729","1246683126"
"Tifra","Tifra","36.6664","4.6972","Algeria","DZ","DZA","Bejaïa","","8547","1012591585"
"Hainichen","Hainichen","50.9697","13.1253","Germany","DE","DEU","Saxony","","8588","1276053815"
"Perry Heights","Perry Heights","40.7977","-81.4680","United States","US","USA","Ohio","","8752","1840005201"
"Majhgawān","Majhgawan","24.8000","80.8000","India","IN","IND","Madhya Pradesh","","8622","1356094132"
"Jondor Shaharchasi","Jondor Shaharchasi","39.7333","64.1833","Uzbekistan","UZ","UZB","Buxoro","minor","8700","1860971461"
"Porkhov","Porkhov","57.7833","29.5667","Russia","RU","RUS","Pskovskaya Oblast’","","8743","1643988613"
"Valley Cottage","Valley Cottage","41.1162","-73.9433","United States","US","USA","New York","","8749","1840004971"
"Schuylkill","Schuylkill","40.1086","-75.4982","United States","US","USA","Pennsylvania","","8748","1840141676"
"Anthony","Anthony","32.0131","-106.5984","United States","US","USA","New Mexico","","8748","1840018049"
"Tuba City","Tuba City","36.1250","-111.2467","United States","US","USA","Arizona","","8748","1840018972"
"Skovorodino","Skovorodino","53.9833","123.9333","Russia","RU","RUS","Amurskaya Oblast’","","8746","1643357581"
"Roverbella","Roverbella","45.2667","10.7667","Italy","IT","ITA","Lombardy","","8610","1380072476"
"Chinaur","Chinaur","25.9467","78.1024","India","IN","IND","Madhya Pradesh","","8503","1356740443"
"Ibirapuã","Ibirapua","-17.6878","-40.1089","Brazil","BR","BRA","Bahia","","8735","1076018020"
"Junín","Junin","4.7903","-73.8136","Colombia","CO","COL","Cundinamarca","minor","8720","1170184431"
"Höshööt","Hoshoot","48.9408","89.1383","Mongolia","MN","MNG","Bayan-Ölgiy","minor","8744","1496001385"
"Santa Lúcia","Santa Lucia","-21.6850","-48.0839","Brazil","BR","BRA","São Paulo","","8687","1076930150"
"Ter Apel","Ter Apel","52.8756","7.0597","Netherlands","NL","NLD","Groningen","","8671","1528468512"
"Volchansk","Volchansk","59.9333","60.0833","Russia","RU","RUS","Sverdlovskaya Oblast’","","8722","1643386083"
"Vásárosnamény","Vasarosnameny","48.1267","22.3183","Hungary","HU","HUN","Szabolcs-Szatmár-Bereg","minor","8618","1348707721"
"Chachersk","Chachersk","52.9161","30.9161","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","8730","1112940969"
"Cortez","Cortez","37.3503","-108.5768","United States","US","USA","Colorado","","8742","1840018928"
"Gremyachinsk","Gremyachinsk","58.5667","57.8500","Russia","RU","RUS","Permskiy Kray","","8732","1643585361"
"Poteau","Poteau","35.0282","-94.6346","United States","US","USA","Oklahoma","","8741","1840020445"
"Lake Villa","Lake Villa","42.4184","-88.0836","United States","US","USA","Illinois","","8741","1840011159"
"Strzyżów","Strzyzow","49.8833","21.7833","Poland","PL","POL","Podkarpackie","minor","8588","1616515144"
"Štětí","Steti","50.4531","14.3743","Czechia","CZ","CZE","Ústecký Kraj","","8584","1203443601"
"North Madison","North Madison","41.8297","-81.0507","United States","US","USA","Ohio","","8739","1840004801"
"Amalou","Amalou","36.4778","4.6333","Algeria","DZ","DZA","Bejaïa","","8602","1012045463"
"Moranbah","Moranbah","-22.0016","148.0533","Australia","AU","AUS","Queensland","","8735","1036916453"
"Forbes","Forbes","-33.3817","148.0011","Australia","AU","AUS","New South Wales","","8432","1036523834"
"Chanute","Chanute","37.6695","-95.4621","United States","US","USA","Kansas","","8737","1840001694"
"Sokyriany","Sokyriany","48.4500","27.4167","Ukraine","UA","UKR","Chernivetska Oblast","minor","8652","1804903949"
"San Juan de Arama","San Juan de Arama","3.3736","-73.8767","Colombia","CO","COL","Meta","minor","8728","1170213121"
"Brooksville","Brooksville","28.5404","-82.3903","United States","US","USA","Florida","","8735","1840014112"
"Atmore","Atmore","31.0927","-87.4762","United States","US","USA","Alabama","","8734","1840013888"
"Seneca","Seneca","34.6818","-82.9600","United States","US","USA","South Carolina","","8734","1840015506"
"Ortaköy","Ortakoy","40.2830","35.2670","Turkey","TR","TUR","Çorum","minor","8696","1792266546"
"Collier","Collier","40.3991","-80.1322","United States","US","USA","Pennsylvania","","8733","1840142357"
"Rava-Rus’ka","Rava-Rus'ka","50.2500","23.6167","Ukraine","UA","UKR","Lvivska Oblast","","8647","1804385058"
"Baraolt","Baraolt","46.0750","25.6000","Romania","RO","ROU","Covasna","","8672","1642011195"
"Gangājalghāti","Gangajalghati","23.4200","87.1200","India","IN","IND","West Bengal","","8708","1356470216"
"Barntrup","Barntrup","51.9831","9.1167","Germany","DE","DEU","North Rhine-Westphalia","","8587","1276882548"
"Sanger","Sanger","33.3715","-97.1678","United States","US","USA","Texas","","8730","1840022004"
"Taft","Taft","35.1268","-119.4243","United States","US","USA","California","","8730","1840021736"
"Kościelisko","Koscielisko","49.2833","19.8833","Poland","PL","POL","Małopolskie","","8661","1616942761"
"Reichelsheim","Reichelsheim","49.7149","8.8396","Germany","DE","DEU","Hesse","","8581","1276383443"
"Ogulin","Ogulin","45.2669","15.2248","Croatia","HR","HRV","Karlovačka Županija","minor","8712","1191210263"
"Steinheim am Albuch","Steinheim am Albuch","48.6922","10.0642","Germany","DE","DEU","Baden-Württemberg","","8623","1276634395"
"Monforte del Cid","Monforte del Cid","38.3792","-0.7303","Spain","ES","ESP","Valencia","","8619","1724092008"
"Solotvyno","Solotvyno","47.9597","23.8669","Ukraine","UA","UKR","Zakarpatska Oblast","","8632","1804988039"
"Barro Alto","Barro Alto","-14.9683","-48.9200","Brazil","BR","BRA","Goiás","","8716","1076654663"
"Sanatoga","Sanatoga","40.2497","-75.5887","United States","US","USA","Pennsylvania","","8724","1840035186"
"Pont-Rouge","Pont-Rouge","46.7500","-71.7000","Canada","CA","CAN","Quebec","","8723","1124608325"
"Chamonix-Mont-Blanc","Chamonix-Mont-Blanc","45.9222","6.8689","France","FR","FRA","Auvergne-Rhône-Alpes","","8648","1250219507"
"San Isidro","San Isidro","9.9369","126.0886","Philippines","PH","PHL","Surigao del Norte","","8519","1608104521"
"Redlynch","Redlynch","-16.8833","145.7000","Australia","AU","AUS","Queensland","","8645","1036596124"
"Sarbīsheh","Sarbisheh","32.5756","59.7983","Iran","IR","IRN","Khorāsān-e Jonūbī","minor","8715","1364184817"
"Fürstenfeld","Furstenfeld","47.0500","16.0833","Austria","AT","AUT","Steiermark","","8549","1040284464"
"Szeghalom","Szeghalom","47.0272","21.1661","Hungary","HU","HUN","Békés","minor","8680","1348071592"
"Bath","Bath","43.9346","-69.8346","United States","US","USA","Maine","","8718","1840000332"
"Graham","Graham","33.1017","-98.5779","United States","US","USA","Texas","","8718","1840020668"
"Smižany","Smizany","48.9556","20.5292","Slovakia","SK","SVK","Košice","","8698","1703195495"
"Farob","Farob","39.2408","67.4700","Tajikistan","TJ","TJK","Sughd","","8650","1762563698"
"Catuípe","Catuipe","-28.2500","-54.0119","Brazil","BR","BRA","Rio Grande do Sul","","8701","1076092018"
"Le Poiré-sur-Vie","Le Poire-sur-Vie","46.7686","-1.5083","France","FR","FRA","Pays de la Loire","","8596","1250190722"
"Maryborough","Maryborough","-37.0500","143.7350","Australia","AU","AUS","Victoria","","7921","1036781601"
"Tăşnad","Tasnad","47.4772","22.5839","Romania","RO","ROU","Satu Mare","","8631","1642178252"
"Inékar","Inekar","15.9492","3.1592","Mali","ML","MLI","Gao","","8714","1466792792"
"Tlanalapa","Tlanalapa","19.8167","-98.6000","Mexico","MX","MEX","Hidalgo","minor","8662","1484762044"
"Itapeva","Itapeva","-22.7678","-46.2208","Brazil","BR","BRA","Minas Gerais","","8664","1076658092"
"Casorate Primo","Casorate Primo","45.3167","9.0167","Italy","IT","ITA","Lombardy","","8680","1380703756"
"Sredets","Sredets","42.3500","27.2000","Bulgaria","BG","BGR","Burgas","","8699","1100026609"
"Rakitovo","Rakitovo","41.9833","24.0833","Bulgaria","BG","BGR","Pazardzhik","minor","8651","1100370778"
"Gyümai","Gyumai","33.7560","99.6500","China","CN","CHN","Qinghai","","8706","1156886719"
"Aigues-Mortes","Aigues-Mortes","43.5667","4.1925","France","FR","FRA","Occitanie","","8560","1250061893"
"Park Hills","Park Hills","37.8211","-90.5050","United States","US","USA","Missouri","","8708","1840009857"
"Midutūru","Miduturu","15.7667","78.3000","India","IN","IND","Andhra Pradesh","","8562","1356686488"
"Clarendon Hills","Clarendon Hills","41.7981","-87.9568","United States","US","USA","Illinois","","8707","1840010170"
"Żuromin","Zuromin","53.0667","19.9000","Poland","PL","POL","Mazowieckie","minor","8592","1616921543"
"Piranguinho","Piranguinho","-22.4008","-45.5319","Brazil","BR","BRA","Minas Gerais","","8640","1076073450"
"Champlain","Champlain","45.5333","-74.6500","Canada","CA","CAN","Ontario","","8706","1124000537"
"Ulstein","Ulstein","62.3564","5.8539","Norway","NO","NOR","Møre og Romsdal","","8575","1578619519"
"Briceño","Briceno","7.1111","-75.5500","Colombia","CO","COL","Antioquia","minor","8673","1170830473"
"Chiusi","Chiusi","43.0167","11.9500","Italy","IT","ITA","Tuscany","","8558","1380833273"
"Spello","Spello","42.9889","12.6722","Italy","IT","ITA","Umbria","","8565","1380139636"
"Hernani","Hernani","11.3239","125.6181","Philippines","PH","PHL","Eastern Samar","","8531","1608420046"
"Booneville","Booneville","34.6643","-88.5684","United States","US","USA","Mississippi","","8702","1840013591"
"Orosi","Orosi","36.5433","-119.2914","United States","US","USA","California","","8701","1840019035"
"Borgentreich","Borgentreich","51.5667","9.2500","Germany","DE","DEU","North Rhine-Westphalia","","8638","1276811823"
"Hoquiam","Hoquiam","46.9863","-123.9022","United States","US","USA","Washington","","8700","1840019845"
"Ronciglione","Ronciglione","42.2894","12.2147","Italy","IT","ITA","Lazio","","8537","1380809605"
"Mrakovo","Mrakovo","52.7161","56.6244","Russia","RU","RUS","Bashkortostan","","8690","1643603881"
"Großenlüder","Grossenluder","50.5925","9.5423","Germany","DE","DEU","Hesse","","8583","1276765103"
"Coaticook","Coaticook","45.1333","-71.8000","Canada","CA","CAN","Quebec","","8698","1124454176"
"Tibro","Tibro","58.4167","14.1667","Sweden","SE","SWE","Västra Götaland","minor","8614","1752652082"
"São Miguel","Sao Miguel","-5.1250","-35.6389","Brazil","BR","BRA","Rio Grande do Norte","","8670","1076094659"
"Andriivka","Andriivka","49.5325","36.6219","Ukraine","UA","UKR","Kharkivska Oblast","","8654","1804348444"
"Marāveh Tappeh","Maraveh Tappeh","37.9042","55.9558","Iran","IR","IRN","Golestān","minor","8671","1364721495"
"San Gavino Monreale","San Gavino Monreale","39.5499","8.7916","Italy","IT","ITA","Sardegna","","8594","1380732961"
"Uglegorsk","Uglegorsk","49.0667","142.0333","Russia","RU","RUS","Sakhalinskaya Oblast’","","8687","1643572665"
"Santana do Manhuaçu","Santana do Manhuacu","-20.1078","-41.9250","Brazil","BR","BRA","Minas Gerais","","8667","1076618568"
"Poniatowa","Poniatowa","51.1833","22.0667","Poland","PL","POL","Lubelskie","","8517","1616798001"
"Quezalguaque","Quezalguaque","12.5078","-86.9033","Nicaragua","NI","NIC","León","minor","8591","1558204970"
"Wyoming","Wyoming","39.2297","-84.4816","United States","US","USA","Ohio","","8691","1840001619"
"Çamlıyayla","Camliyayla","37.1703","34.6083","Turkey","TR","TUR","Mersin","minor","8679","1792698507"
"Ueckermünde","Ueckermunde","53.7389","14.0444","Germany","DE","DEU","Mecklenburg-Western Pomerania","","8591","1276552736"
"Fair Lakes","Fair Lakes","38.8530","-77.3885","United States","US","USA","Virginia","","8689","1840041732"
"Reserve","Reserve","30.0741","-90.5557","United States","US","USA","Louisiana","","8688","1840013969"
"Gubden","Gubden","42.5658","47.5631","Russia","RU","RUS","Dagestan","","8627","1643957036"
"Lamesa","Lamesa","32.7333","-101.9541","United States","US","USA","Texas","","8685","1840020725"
"Tangará","Tangara","-27.1050","-51.2469","Brazil","BR","BRA","Santa Catarina","","8662","1076968461"
"Olmsted Falls","Olmsted Falls","41.3657","-81.9038","United States","US","USA","Ohio","","8684","1840003404"
"Gibsonville","Gibsonville","36.0993","-79.5415","United States","US","USA","North Carolina","","8684","1840016137"
"Plabennec","Plabennec","48.5019","-4.4261","France","FR","FRA","Bretagne","","8515","1250109399"
"Chyhyryn","Chyhyryn","49.0833","32.6667","Ukraine","UA","UKR","Cherkaska Oblast","minor","8658","1804035691"
"Villanueva de Córdoba","Villanueva de Cordoba","38.3167","-4.6167","Spain","ES","ESP","Andalusia","","8662","1724586987"
"Orange Park","Orange Park","30.1706","-81.7041","United States","US","USA","Florida","","8682","1840017206"
"Sollefteå","Solleftea","63.1667","17.2667","Sweden","SE","SWE","Västernorrland","minor","8643","1752942134"
"Perl","Perl","49.4667","6.3667","Germany","DE","DEU","Saarland","","8566","1276009165"
"El Espinal","El Espinal","16.4906","-95.0444","Mexico","MX","MEX","Oaxaca","minor","8575","1484603133"
"Chamba","Chamba","8.7000","-0.1333","Ghana","GH","GHA","Northern","","8600","1288296967"
"Cordisburgo","Cordisburgo","-19.1250","-44.3208","Brazil","BR","BRA","Minas Gerais","","8667","1076301990"
"Busk","Busk","49.9667","24.6167","Ukraine","UA","UKR","Lvivska Oblast","","8624","1804314944"
"Barvynkove","Barvynkove","48.9067","37.0131","Ukraine","UA","UKR","Kharkivska Oblast","","8660","1804415450"
"Nádudvar","Nadudvar","47.4167","21.1667","Hungary","HU","HUN","Hajdú-Bihar","","8636","1348692293"
"Pleasant Hill","Pleasant Hill","38.8059","-94.2652","United States","US","USA","Missouri","","8673","1840009786"
"Minto","Minto","43.9167","-80.8667","Canada","CA","CAN","Ontario","","8671","1124000198"
"East York","East York","39.9687","-76.6755","United States","US","USA","Pennsylvania","","8670","1840034943"
"Jalhay","Jalhay","50.5572","5.9642","Belgium","BE","BEL","Wallonia","","8590","1056772440"
"Kalaun","Kalaun","29.8300","80.5500","Nepal","NP","NPL","Sudūrpashchim","","8577","1524035219"
"Wiang Haeng","Wiang Haeng","19.5500","98.6500","Thailand","TH","THA","Chiang Mai","minor","8631","1764350186"
"Demirözü","Demirozu","40.1639","39.8925","Turkey","TR","TUR","Bayburt","minor","8657","1792751073"
"Ad Darbāsīyah","Ad Darbasiyah","37.0728","40.6519","Syria","SY","SYR","Al Ḩasakah","minor","8551","1760503745"
"Dunaföldvár","Dunafoldvar","46.8089","18.9180","Hungary","HU","HUN","Tolna","","8593","1348165944"
"Hollymead","Hollymead","38.1266","-78.4386","United States","US","USA","Virginia","","8668","1840024700"
"Bryan","Bryan","41.4706","-84.5483","United States","US","USA","Ohio","","8668","1840000573"
"Morden","Morden","49.1919","-98.1006","Canada","CA","CAN","Manitoba","","8668","1124327817"
"La Libertad","La Libertad","16.7804","-90.1200","Guatemala","GT","GTM","Petén","minor","8646","1320389989"
"Bicaz","Bicaz","46.9108","26.0911","Romania","RO","ROU","Neamţ","","8626","1642668954"
"Commerce","Commerce","33.2421","-95.8992","United States","US","USA","Texas","","8667","1840019394"
"Hakubachō","Hakubacho","36.6981","137.8619","Japan","JP","JPN","Nagano","","8621","1392038720"
"Covington","Covington","35.5660","-89.6482","United States","US","USA","Tennessee","","8666","1840014547"
"Wieruszów","Wieruszow","51.3000","18.1500","Poland","PL","POL","Łódzkie","minor","8514","1616576051"
"Volvera","Volvera","44.9500","7.5000","Italy","IT","ITA","Piedmont","","8639","1380144754"
"Ferreira do Zêzere","Ferreira do Zezere","39.6833","-8.2833","Portugal","PT","PRT","Santarém","minor","8619","1620885794"
"Unterwellenborn","Unterwellenborn","50.6586","11.4419","Germany","DE","DEU","Thuringia","","8513","1276207968"
"Reginópolis","Reginopolis","-21.8878","-49.2250","Brazil","BR","BRA","São Paulo","","8640","1076245325"
"Wesley Chapel","Wesley Chapel","34.9985","-80.6903","United States","US","USA","North Carolina","","8661","1840017881"
"Litchfield Beach","Litchfield Beach","33.4773","-79.1181","United States","US","USA","South Carolina","","8660","1840107237"
"Lakhzazra","Lakhzazra","33.0333","-7.0500","Morocco","MA","MAR","Casablanca-Settat","","8582","1504277663"
"Zuera","Zuera","41.8692","-0.7881","Spain","ES","ESP","Aragon","","8632","1724985911"
"Highland Heights","Highland Heights","41.5518","-81.4691","United States","US","USA","Ohio","","8658","1840000605"
"Oxford","Oxford","36.3155","-78.5848","United States","US","USA","North Carolina","","8658","1840014435"
"Bushtyno","Bushtyno","48.0503","23.4894","Ukraine","UA","UKR","Zakarpatska Oblast","","8562","1804233916"
"Dobanovci","Dobanovci","44.8333","20.2333","Serbia","RS","SRB","Beograd","","8503","1688744235"
"Sortino","Sortino","37.1667","15.0333","Italy","IT","ITA","Sicilia","","8561","1380166703"
"Ouro Verde","Ouro Verde","-21.4894","-51.7003","Brazil","BR","BRA","São Paulo","","8620","1076804048"
"Penkridge","Penkridge","52.7252","-2.1164","United Kingdom","GB","GBR","Staffordshire","","8526","1826440883"
"Virgínia","Virginia","-22.3328","-45.0919","Brazil","BR","BRA","Minas Gerais","","8623","1076524366"
"Dobrada","Dobrada","-21.5167","-48.3939","Brazil","BR","BRA","São Paulo","","8592","1076112653"
"Mauléon","Mauleon","46.9228","-0.7497","France","FR","FRA","Nouvelle-Aquitaine","","8578","1250619307"
"Long Hill","Long Hill","40.6838","-74.4878","United States","US","USA","New Jersey","","8649","1840081726"
"Tecumseh","Tecumseh","42.0066","-83.9450","United States","US","USA","Michigan","","8645","1840003228"
"Usiacurí","Usiacuri","10.7500","-74.9833","Colombia","CO","COL","Atlántico","minor","8561","1170893496"
"McKee City","McKee City","39.4465","-74.6445","United States","US","USA","New Jersey","","8644","1840081031"
"Lambarkiyine","Lambarkiyine","33.2000","-7.5000","Morocco","MA","MAR","Casablanca-Settat","","8559","1504527523"
"Andrushivka","Andrushivka","50.0167","29.0167","Ukraine","UA","UKR","Zhytomyrska Oblast","minor","8606","1804544646"
"Hillview","Hillview","38.0562","-85.6847","United States","US","USA","Kentucky","","8638","1840014325"
"Bösel","Bosel","53.0058","7.9542","Germany","DE","DEU","Lower Saxony","","8550","1276774199"
"Nefasīt","Nefasit","15.3333","39.0619","Eritrea","ER","ERI","Semienawi K’eyyĭḥ Baḥri","","8600","1232567577"
"Dorgali","Dorgali","40.3000","9.5833","Italy","IT","ITA","Sardegna","","8596","1380320991"
"São Sebastião da Amoreira","Sao Sebastiao da Amoreira","-23.4650","-50.7608","Brazil","BR","BRA","Paraná","","8626","1076059061"
"Nariño","Narino","5.6092","-75.1764","Colombia","CO","COL","Antioquia","minor","8603","1170449720"
"Arealva","Arealva","-22.0286","-48.9111","Brazil","BR","BRA","São Paulo","","8613","1076417809"
"Goundam","Goundam","16.4144","-3.6708","Mali","ML","MLI","Tombouctou","minor","8456","1466655191"
"Laishevo","Laishevo","55.4000","49.5667","Russia","RU","RUS","Tatarstan","","8604","1643137529"
"Takiéta","Takieta","13.6806","8.5292","Niger","NE","NER","Zinder","","8554","1562878014"
"McCordsville","McCordsville","39.8966","-85.9208","United States","US","USA","Indiana","","8629","1840009526"
"San Lorenzo","San Lorenzo","18.1875","-65.9680","Puerto Rico","PR","PRI","Puerto Rico","","8628","1630035578"
"Gonzales","Gonzales","36.5055","-121.4427","United States","US","USA","California","","8628","1840020355"
"Ustyuzhna","Ustyuzhna","58.8333","36.4333","Russia","RU","RUS","Vologodskaya Oblast’","","8622","1643553144"
"La Belleza","La Belleza","5.8614","-73.9683","Colombia","CO","COL","Santander","minor","8596","1170636722"
"Yasinia","Yasinia","48.2728","24.3747","Ukraine","UA","UKR","Zakarpatska Oblast","","8579","1804338366"
"Worplesdon","Worplesdon","51.2720","-0.6120","United Kingdom","GB","GBR","Surrey","","8529","1826340455"
"Meßkirch","Messkirch","47.9928","9.1125","Germany","DE","DEU","Baden-Württemberg","","8513","1276541566"
"Lützen","Lutzen","51.2597","12.1417","Germany","DE","DEU","Saxony-Anhalt","","8546","1276691830"
"Canford Cliffs","Canford Cliffs","50.7000","-1.9300","United Kingdom","GB","GBR","","","8620","1826721850"
"Gavorrano","Gavorrano","42.9250","10.9100","Italy","IT","ITA","Tuscany","","8567","1380086730"
"Micco","Micco","27.8683","-80.5100","United States","US","USA","Florida","","8618","1840014077"
"Fort Mitchell","Fort Mitchell","39.0460","-84.5562","United States","US","USA","Kentucky","","8618","1840013170"
"Ittiri","Ittiri","40.6000","8.5667","Italy","IT","ITA","Sardegna","","8541","1380429583"
"Oulad Khallouf","Oulad Khallouf","34.7167","-2.5833","Morocco","MA","MAR","Oriental","","8605","1504000678"
"Dunn","Dunn","35.3114","-78.6129","United States","US","USA","North Carolina","","8616","1840013434"
"Solosuchiapa","Solosuchiapa","17.4000","-93.0000","Mexico","MX","MEX","Chiapas","minor","8561","1484146814"
"Lipki","Lipki","53.9333","37.7000","Russia","RU","RUS","Tul’skaya Oblast’","","8541","1643393830"
"Verkhoturye","Verkhoturye","58.8667","60.8000","Russia","RU","RUS","Sverdlovskaya Oblast’","","8612","1643322280"
"Kosiv","Kosiv","48.3150","25.0953","Ukraine","UA","UKR","Ivano-Frankivska Oblast","minor","8522","1804417553"
"Ogden","Ogden","34.2656","-77.7966","United States","US","USA","North Carolina","","8613","1840013633"
"Clinton","Clinton","35.5069","-98.9706","United States","US","USA","Oklahoma","","8612","1840019108"
"Druid Hills","Druid Hills","33.7842","-84.3272","United States","US","USA","Georgia","","8611","1840013092"
"Takaharu","Takaharu","31.9284","131.0079","Japan","JP","JPN","Miyazaki","","8511","1392262891"
"Osternienburg","Osternienburg","51.8000","12.0167","Germany","DE","DEU","Saxony-Anhalt","","8549","1276698635"
"Mono","Mono","44.0167","-80.0667","Canada","CA","CAN","Ontario","","8609","1124001904"
"Baía Formosa","Baia Formosa","-6.3689","-35.0078","Brazil","BR","BRA","Rio Grande do Norte","","8573","1076300931"
"Francisco Santos","Francisco Santos","-6.9928","-41.1378","Brazil","BR","BRA","Piauí","","8592","1076258810"
"Kiuruvesi","Kiuruvesi","63.6500","26.6167","Finland","FI","FIN","Pohjois-Savo","minor","8600","1246969515"
"Muzo","Muzo","5.5313","-74.1073","Colombia","CO","COL","Boyacá","minor","8548","1170433861"
"Pratápolis","Pratapolis","-20.7450","-46.8608","Brazil","BR","BRA","Minas Gerais","","8566","1076155274"
"Na Yung","Na Yung","17.9142","102.2403","Thailand","TH","THA","Udon Thani","minor","8564","1764147446"
"Yalí","Yali","6.6767","-74.8411","Colombia","CO","COL","Antioquia","minor","8577","1170977762"
"Japurá","Japura","-23.4700","-52.5528","Brazil","BR","BRA","Paraná","","8549","1076082629"
"Itauçu","Itaucu","-16.2008","-49.6078","Brazil","BR","BRA","Goiás","","8575","1076365521"
"Ferrandina","Ferrandina","40.5000","16.4500","Italy","IT","ITA","Basilicata","","8593","1380483555"
"Riacho dos Cavalos","Riacho dos Cavalos","-6.4428","-37.6508","Brazil","BR","BRA","Paraíba","","8563","1076174255"
"Wharton","Wharton","29.3177","-96.1022","United States","US","USA","Texas","","8595","1840022229"
"Calvisano","Calvisano","45.3489","10.3458","Italy","IT","ITA","Lombardy","","8543","1380979757"
"Summit View","Summit View","47.1343","-122.3467","United States","US","USA","Washington","","8593","1840042106"
"Village Green-Green Ridge","Village Green-Green Ridge","39.8639","-75.4257","United States","US","USA","Pennsylvania","","8592","1840073727"
"Tanquinho","Tanquinho","-11.9789","-39.1039","Brazil","BR","BRA","Bahia","","8553","1076001279"
"Glenwood","Glenwood","41.5410","-87.6118","United States","US","USA","Illinois","","8590","1840011281"
"Jauru","Jauru","-15.3419","-58.8658","Brazil","BR","BRA","Mato Grosso","","8582","1076111334"
"Torre de Moncorvo","Torre de Moncorvo","41.2000","-7.1333","Portugal","PT","PRT","Bragança","minor","8572","1620001140"
"Mataraca","Mataraca","-6.6008","-35.0508","Brazil","BR","BRA","Paraíba","","8539","1076552609"
"San Fernando","San Fernando","13.6767","-86.3144","Nicaragua","NI","NIC","Nueva Segovia","minor","8549","1558217254"
"Independence","Independence","37.2119","-95.7327","United States","US","USA","Kansas","","8584","1840001708"
"Cagli","Cagli","43.5500","12.6500","Italy","IT","ITA","Marche","","8546","1380043671"
"Löwenberg","Lowenberg","52.8960","13.1546","Germany","DE","DEU","Brandenburg","","8548","1276763095"
"Kirk of Shotts","Kirk of Shotts","55.8230","-3.8040","United Kingdom","GB","GBR","North Lanarkshire","","8570","1826280322"
"Rio das Flores","Rio das Flores","-22.1678","-43.5858","Brazil","BR","BRA","Rio de Janeiro","","8561","1076984547"
"Kennedy","Kennedy","40.4768","-80.1028","United States","US","USA","Pennsylvania","","8578","1840146792"
"Verkhniy Mamon","Verkhniy Mamon","50.1678","40.3967","Russia","RU","RUS","Voronezhskaya Oblast’","","8561","1643662881"
"Tarrafas","Tarrafas","-6.6839","-39.7608","Brazil","BR","BRA","Ceará","","8573","1076530122"
"Itamari","Itamari","-13.7778","-39.6839","Brazil","BR","BRA","Bahia","","8514","1076597787"
"Meltham","Meltham","53.5920","-1.8500","United Kingdom","GB","GBR","Kirklees","","8534","1826260567"
"Pöytyä","Poytya","60.7167","22.6000","Finland","FI","FIN","Varsinais-Suomi","","8562","1246790283"
"Mahopac","Mahopac","41.3688","-73.7414","United States","US","USA","New York","","8573","1840004891"
"Closter","Closter","40.9733","-73.9604","United States","US","USA","New Jersey","","8573","1840003538"
"Jóia","Joia","-28.6469","-54.1219","Brazil","BR","BRA","Rio Grande do Sul","","8566","1076448822"
"Karmaskaly","Karmaskaly","54.3694","56.1778","Russia","RU","RUS","Bashkortostan","","8540","1643751700"
"Dayton","Dayton","30.0315","-94.9158","United States","US","USA","Texas","","8569","1840019599"
"Gresham Park","Gresham Park","33.7053","-84.3155","United States","US","USA","Georgia","","8569","1840029454"
"San Sebastián","San Sebastian","18.3356","-66.9948","Puerto Rico","PR","PRI","Puerto Rico","","8568","1630023635"
"Delavan","Delavan","42.6282","-88.6323","United States","US","USA","Wisconsin","","8568","1840002483"
"Monticello","Monticello","33.6257","-91.7934","United States","US","USA","Arkansas","","8567","1840014805"
"South Kensington","South Kensington","39.0188","-77.0785","United States","US","USA","Maryland","","8566","1840031497"
"Alfonso Castañeda","Alfonso Castaneda","15.7933","121.3025","Philippines","PH","PHL","Nueva Vizcaya","","8539","1608693079"
"Novoselitskoye","Novoselitskoye","44.7494","43.4386","Russia","RU","RUS","Stavropol’skiy Kray","","8544","1643931504"
"Monona","Monona","43.0540","-89.3334","United States","US","USA","Wisconsin","","8560","1840002920"
"Queimada Nova","Queimada Nova","-8.5789","-41.4189","Brazil","BR","BRA","Piauí","","8553","1076622675"
"Toro","Toro","41.5200","-5.3947","Spain","ES","ESP","Castille-Leon","","8532","1724525468"
"Iwaizumi","Iwaizumi","39.8431","141.7964","Japan","JP","JPN","Iwate","","8549","1392666013"
"Ağlasun","Aglasun","37.6494","30.5339","Turkey","TR","TUR","Burdur","minor","8537","1792900416"
"Pochinok","Pochinok","54.4000","32.4500","Russia","RU","RUS","Smolenskaya Oblast’","minor","8545","1643214179"
"Lake of the Woods","Lake of the Woods","38.3343","-77.7599","United States","US","USA","Virginia","","8557","1840042948"
"Lanco","Lanco","-39.4500","-72.7833","Chile","CL","CHL","Araucanía","","8521","1152703909"
"Paris","Paris","39.6148","-87.6903","United States","US","USA","Illinois","","8553","1840009542"
"Socorro","Socorro","34.0543","-106.9065","United States","US","USA","New Mexico","","8553","1840021898"
"Dubovskoye","Dubovskoye","47.4092","42.7575","Russia","RU","RUS","Rostovskaya Oblast’","minor","8544","1643298743"
"Krupki","Krupki","54.3167","29.1333","Belarus","BY","BLR","Minskaya Voblasts’","minor","8540","1112668114"
"Itaquara","Itaquara","-13.4508","-39.9408","Brazil","BR","BRA","Bahia","","8519","1076089860"
"Herencia","Herencia","39.3669","-3.3550","Spain","ES","ESP","Castille-La Mancha","","8510","1724808176"
"Pokrovka","Pokrovka","42.7500","71.6000","Kyrgyzstan","KG","KGZ","Talas","minor","8522","1417837155"
"Baxter","Baxter","46.3426","-94.2793","United States","US","USA","Minnesota","","8544","1840006643"
"Obluchye","Obluchye","49.0167","131.0500","Russia","RU","RUS","Yevreyskaya Avtonomnaya Oblast’","","8540","1643987123"
"Westwego","Westwego","29.9058","-90.1434","United States","US","USA","Louisiana","","8538","1840015937"
"Bee Cave","Bee Cave","30.3084","-97.9629","United States","US","USA","Texas","","8538","1840023142"
"El Dovio","El Dovio","4.5167","-76.2333","Colombia","CO","COL","Valle del Cauca","minor","8508","1170108297"
"Ladysmith","Ladysmith","48.9975","-123.8203","Canada","CA","CAN","British Columbia","","8537","1124872385"
"Sulakyurt","Sulakyurt","40.1575","33.7175","Turkey","TR","TUR","Kırıkkale","minor","8531","1792056675"
"Tuneiras do Oeste","Tuneiras do Oeste","-23.8708","-52.8758","Brazil","BR","BRA","Paraná","","8533","1076246454"
"Laitila","Laitila","60.8833","21.7000","Finland","FI","FIN","Varsinais-Suomi","minor","8520","1246064059"
"Aibonito","Aibonito","18.1398","-66.2659","Puerto Rico","PR","PRI","Puerto Rico","","8532","1630035609"
"Bridgewater","Bridgewater","44.3700","-64.5200","Canada","CA","CAN","Nova Scotia","","8532","1124736310"
"Santa Isabel do Ivaí","Santa Isabel do Ivai","-23.0053","-53.1878","Brazil","BR","BRA","Paraná","","8523","1076463310"
"Guaraçaí","Guaracai","-21.0283","-51.2067","Brazil","BR","BRA","São Paulo","","8525","1076853685"
"Nova Glória","Nova Gloria","-15.1428","-49.5708","Brazil","BR","BRA","Goiás","","8508","1076435381"
"Gering","Gering","41.8275","-103.6622","United States","US","USA","Nebraska","","8521","1840008139"
"Los Altos Hills","Los Altos Hills","37.3669","-122.1387","United States","US","USA","California","","8520","1840021574"
"Laanoussar","Laanoussar","33.6833","-4.8167","Morocco","MA","MAR","Fès-Meknès","","8501","1504953318"
"Sitka","Sitka","57.2401","-135.3152","United States","US","USA","Alaska","","8518","1840023319"
"Vyetka","Vyetka","52.5667","31.1833","Belarus","BY","BLR","Homyel’skaya Voblasts’","minor","8507","1112844638"
"Lycksele","Lycksele","64.6000","18.6667","Sweden","SE","SWE","Västerbotten","minor","8513","1752497836"
"Narrabri","Narrabri","-30.3317","149.7678","Australia","AU","AUS","New South Wales","","7606","1036714493"
"Center Line","Center Line","42.4805","-83.0274","United States","US","USA","Michigan","","8515","1840002419"
"Barre","Barre","44.1998","-72.5085","United States","US","USA","Vermont","","8514","1840002186"
"Lewistown","Lewistown","40.5964","-77.5730","United States","US","USA","Pennsylvania","","8513","1840003592"
"Smithfield","Smithfield","36.9755","-76.6152","United States","US","USA","Virginia","","8513","1840006487"
"Blanchard","Blanchard","35.1524","-97.6602","United States","US","USA","Oklahoma","","8511","1840019175"
"Richland Hills","Richland Hills","32.8095","-97.2273","United States","US","USA","Texas","","8505","1840020700"
"Hastings-on-Hudson","Hastings-on-Hudson","40.9904","-73.8799","United States","US","USA","New York","","8505","1840004932"
"Sallisaw","Sallisaw","35.4606","-94.8072","United States","US","USA","Oklahoma","","8503","1840021749"
"Bryans Road","Bryans Road","38.6145","-77.0851","United States","US","USA","Maryland","","8502","1840006172"
"Milton","Milton","47.2524","-122.3153","United States","US","USA","Washington","","8501","1840019857"
"Biryusinsk","Biryusinsk","55.9667","97.8333","Russia","RU","RUS","Irkutskaya Oblast’","","8497","1643008672"
"Namsos","Namsos","64.4656","11.4978","Norway","NO","NOR","Trøndelag","minor","8471","1578576567"
"Chapaev","Chapaev","50.2000","51.1667","Kazakhstan","KZ","KAZ","","","8476","1398386733"
"Orocué","Orocue","4.7942","-71.3400","Colombia","CO","COL","Casanare","minor","8460","1170535682"
"As Sallūm","As Sallum","31.5500","25.1600","Egypt","EG","EGY","Maţrūḩ","","8445","1818639105"
"Outjo","Outjo","-20.1089","16.1547","Namibia","NA","NAM","Kunene","","8445","1516062528"
"Būlaevo","Bulaevo","54.9056","70.4439","Kazakhstan","KZ","KAZ","","minor","8433","1398659796"
"Alvorada","Alvorada","-12.4800","-49.1250","Brazil","BR","BRA","Tocantins","","8374","1076121080"
"Moengo","Moengo","5.6167","-54.4000","Suriname","SR","SUR","Marowijne","","8252","1740230194"
"Zhänibek","Zhanibek","49.4167","46.8500","Kazakhstan","KZ","KAZ","","","8212","1398255562"
"Pampa del Infierno","Pampa del Infierno","-26.5167","-61.1667","Argentina","AR","ARG","Chaco","minor","8176","1032896072"
"Hammerfest","Hammerfest","70.6634","23.6821","Norway","NO","NOR","Troms og Finnmark","minor","8073","1578368356"
"Aguelhok","Aguelhok","19.4614","0.8589","Mali","ML","MLI","Kidal","","8080","1466967655"
"Heyin","Heyin","36.0451","101.4242","China","CN","CHN","Qinghai","minor","7642","1156514054"
"Qusmuryn","Qusmuryn","52.4580","64.6000","Kazakhstan","KZ","KAZ","","minor","8049","1398335766"
"Kibale","Kibale","0.7911","31.0750","Uganda","UG","UGA","Kibaale","","7600","1800354454"
"Dilolo","Dilolo","-10.6833","22.3333","Congo (Kinshasa)","CD","COD","Lualaba","","7854","1180154207"
"San Julián","San Julian","-49.3000","-67.7167","Argentina","AR","ARG","Santa Cruz","minor","7894","1032464702"
"Ertis","Ertis","53.3333","75.4572","Kazakhstan","KZ","KAZ","","minor","7772","1398172080"
"Kemijärvi","Kemijarvi","66.7167","27.4333","Finland","FI","FIN","Lappi","minor","7766","1246358285"
"Bairnsdale","Bairnsdale","-37.8333","147.6167","Australia","AU","AUS","Victoria","","7580","1036432237"
"Gaoual","Gaoual","11.7540","-13.2130","Guinea","GN","GIN","Boké","minor","7461","1324589428"
"Kapoeta","Kapoeta","4.7750","33.5900","South Sudan","SS","SSD","Eastern Equatoria","","7042","1728483282"
"Port Augusta","Port Augusta","-32.4925","137.7658","Australia","AU","AUS","South Australia","","6562","1036945368"
"Librazhd","Librazhd","41.1833","20.3167","Albania","AL","ALB","Elbasan","","6937","1008800835"
"Wick","Wick","58.4540","-3.0890","United Kingdom","GB","GBR","Highland","","7030","1826766399"
"Kiama","Kiama","-34.6708","150.8542","Australia","AU","AUS","New South Wales","","6881","1036754695"
"Coracora","Coracora","-15.0170","-73.7804","Peru","PE","PER","Ayacucho","","7420","1604800656"
"Thames","Thames","-37.1383","175.5375","New Zealand","NZ","NZL","Waikato","","7293","1554802053"
"Atherton","Atherton","-17.2658","145.4780","Australia","AU","AUS","Queensland","","7331","1036456869"
"Aiquile","Aiquile","-18.1667","-65.1667","Bolivia","BO","BOL","Cochabamba","","7381","1068121487"
"Rabaul","Rabaul","-4.2000","152.1833","Papua New Guinea","PG","PNG","East New Britain","","7024","1598973225"
"Seymour","Seymour","-37.0300","145.1300","Australia","AU","AUS","Victoria","","6327","1036919223"
"Vanrhynsdorp","Vanrhynsdorp","-31.6167","18.7167","South Africa","ZA","ZAF","Western Cape","","6272","1710187274"
"Port Saint John’s","Port Saint John's","-31.6288","29.5369","South Africa","ZA","ZAF","Eastern Cape","","6441","1710506018"
"Newman","Newman","-23.3539","119.7319","Australia","AU","AUS","Western Australia","","7238","1036587511"
"Tranqueras","Tranqueras","-31.1833","-55.7667","Uruguay","UY","URY","Rivera","","7235","1858727708"
"Kerikeri","Kerikeri","-35.2244","173.9514","New Zealand","NZ","NZL","Northland","","6960","1554530676"
"Cooma","Cooma","-36.2317","149.1178","Australia","AU","AUS","New South Wales","","6681","1036070931"
"Carnarvon","Carnarvon","-30.9667","22.1333","South Africa","ZA","ZAF","Northern Cape","","5785","1710331786"
"Tumut","Tumut","-35.3039","148.2233","Australia","AU","AUS","New South Wales","","6230","1036777587"
"Kieta","Kieta","-6.2158","155.6328","Papua New Guinea","PG","PNG","Bougainville","","6958","1598490894"
"Selfoss","Selfoss","63.9333","-21.0000","Iceland","IS","ISL","Sveitarfélagið Árborg","","6878","1352214400"
"Roma","Roma","-26.5733","148.7869","Australia","AU","AUS","Queensland","","6848","1036698836"
"Nata","Nata","-20.2106","26.1806","Botswana","BW","BWA","Central","","6802","1072462404"
"Yamba","Yamba","-29.4400","153.3594","Australia","AU","AUS","New South Wales","","6076","1036259324"
"Northam","Northam","-31.6531","116.6661","Australia","AU","AUS","Western Australia","","6548","1036868267"
"Charagua","Charagua","-19.7906","-63.1978","Bolivia","BO","BOL","Santa Cruz","","6780","1068232771"
"Kishkeneköl","Kishkenekol","53.6394","72.3439","Kazakhstan","KZ","KAZ","","minor","6779","1398061261"
"Rinconada","Rinconada","-22.4333","-66.1667","Argentina","AR","ARG","Jujuy","minor","6692","1032429190"
"Awjilah","Awjilah","29.1081","21.2869","Libya","LY","LBY","Al Wāḩāt","","6610","1434938770"
"Cliza","Cliza","-17.6000","-65.9333","Bolivia","BO","BOL","Cochabamba","","6534","1068759245"
"Stawell","Stawell","-37.0500","142.7667","Australia","AU","AUS","Victoria","","5736","1036073093"
"Yeppoon","Yeppoon","-23.1288","150.7444","Australia","AU","AUS","Queensland","","6334","1036180170"
"Makarov","Makarov","48.6333","142.8000","Russia","RU","RUS","Sakhalinskaya Oblast’","","6567","1643711002"
"Kaitaia","Kaitaia","-35.1125","173.2628","New Zealand","NZ","NZL","Northland","","5868","1554000934"
"Scone","Scone","-32.0483","150.8678","Australia","AU","AUS","New South Wales","","5624","1036918224"
"San Ramón","San Ramon","-13.2672","-64.6172","Bolivia","BO","BOL","El Beni","","6490","1068370064"
"Karasburg","Karasburg","-28.0167","18.7500","Namibia","NA","NAM","//Karas","","6054","1516005938"
"Dalaba","Dalaba","10.6560","-12.2720","Guinea","GN","GIN","Mamou","minor","6349","1324455027"
"Ingeniero Guillermo N. Juárez","Ingeniero Guillermo N. Juarez","-23.9000","-61.8500","Argentina","AR","ARG","Formosa","minor","6453","1032585569"
"Oficina María Elena","Oficina Maria Elena","-22.3451","-69.6615","Chile","CL","CHL","Antofagasta","","6457","1152876089"
"Zouar","Zouar","20.4500","16.5167","Chad","TD","TCD","Tibesti","","6432","1148690803"
"Melut","Melut","10.4404","32.2015","South Sudan","SS","SSD","Upper Nile","","6407","1728493346"
"Comandante Luis Piedra Buena","Comandante Luis Piedra Buena","-49.9830","-68.9100","Argentina","AR","ARG","Santa Cruz","","6405","1032946044"
"San Carlos","San Carlos","-17.4044","-63.7325","Bolivia","BO","BOL","Santa Cruz","","6353","1068028866"
"Goondiwindi","Goondiwindi","-28.5461","150.3097","Australia","AU","AUS","Queensland","","6355","1036452066"
"Verkhnevilyuysk","Verkhnevilyuysk","63.4506","120.2983","Russia","RU","RUS","Sakha (Yakutiya)","","6329","1643968444"
"Përmet","Permet","40.2333","20.3500","Albania","AL","ALB","Gjirokastër","","5945","1008604833"
"Cobram","Cobram","-35.9667","145.6500","Australia","AU","AUS","Victoria","","6014","1036438159"
"Queanbeyan","Queanbeyan","-35.3533","149.2342","Australia","AU","AUS","New South Wales","","6237","1036846465"
"Albury","Albury","-36.0806","146.9158","Australia","AU","AUS","New South Wales","","4804","1036076542"
"Ingeniero Jacobacci","Ingeniero Jacobacci","-41.3000","-69.5833","Argentina","AR","ARG","Río Negro","","6261","1032402027"
"Bir Anzarane","Bir Anzarane","23.8918","-14.5364","Morocco","MA","MAR","Dakhla-Oued Ed-Dahab","","6244","1504999993"
"Lithgow","Lithgow","-33.4833","150.1500","Australia","AU","AUS","New South Wales","","5201","1036591538"
"Gyangzê","Gyangze","28.9203","89.5996","China","CN","CHN","Tibet","minor","6000","1156755973"
"Richmond","Richmond","-33.5983","150.7511","Australia","AU","AUS","New South Wales","","5482","1036934091"
"Polýgyros","Polygyros","40.3783","23.4453","Greece","GR","GRC","Kentrikí Makedonía","minor","6121","1300297120"
"Veintiocho de Noviembre","Veintiocho de Noviembre","-51.6500","-72.3000","Argentina","AR","ARG","Santa Cruz","","6145","1032715340"
"Dinguiraye","Dinguiraye","11.2990","-10.7260","Guinea","GN","GIN","Faranah","minor","6062","1324546502"
"Biloela","Biloela","-24.4002","150.5133","Australia","AU","AUS","Queensland","","5758","1036014905"
"Chepes","Chepes","-31.3500","-66.6000","Argentina","AR","ARG","La Rioja","minor","6020","1032137147"
"Maltahöhe","Maltahohe","-24.8333","16.9833","Namibia","NA","NAM","Hardap","","6000","1516195038"
"Uncia","Uncia","-18.4681","-66.5647","Bolivia","BO","BOL","Potosí","","5709","1068104554"
"Chonchi","Chonchi","-42.6219","-73.7742","Chile","CL","CHL","Los Lagos","","5632","1152428145"
"Vadsø","Vadso","70.0803","29.7315","Norway","NO","NOR","Troms og Finnmark","minor","5642","1578863149"
"Beni Ounif","Beni Ounif","32.0500","-1.2500","Algeria","DZ","DZA","Béchar","","5628","1012327989"
"Mali","Mali","12.0840","-12.3010","Guinea","GN","GIN","Labé","minor","5479","1324065197"
"Desaguadero","Desaguadero","-16.5684","-69.0421","Peru","PE","PER","Puno","","5329","1604523021"
"Byron Bay","Byron Bay","-28.6483","153.6178","Australia","AU","AUS","New South Wales","","5521","1036606974"
"General Conesa","General Conesa","-40.1000","-64.4167","Argentina","AR","ARG","Río Negro","minor","5484","1032658385"
"San Antonio de los Cobres","San Antonio de los Cobres","-24.2178","-66.3200","Argentina","AR","ARG","Salta","minor","5482","1032191344"
"Singleton","Singleton","-32.5667","151.1697","Australia","AU","AUS","New South Wales","","5000","1036947514"
"Wonthaggi","Wonthaggi","-38.6056","145.5917","Australia","AU","AUS","Victoria","","4965","1036415078"
"Bajram Curri","Bajram Curri","42.3581","20.0758","Albania","AL","ALB","Kukës","","5340","1008251523"
"Bilibino","Bilibino","68.0500","166.4500","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","5319","1643015766"
"Kununurra","Kununurra","-15.7736","128.7386","Australia","AU","AUS","Western Australia","","5308","1036556231"
"Berri","Berri","-34.2833","140.6000","Australia","AU","AUS","South Australia","","4088","1036554717"
"Otavi","Otavi","-19.6500","17.3333","Namibia","NA","NAM","Otjozondjupa","","5242","1516874756"
"Jinzhong","Jinzhong","26.3504","103.4167","China","CN","CHN","Yunnan","minor","5170","1156252749"
"Mayumba","Mayumba","-3.4167","10.6500","Gabon","GA","GAB","Nyanga","","5208","1266317571"
"Victor Harbor","Victor Harbor","-35.5500","138.6167","Australia","AU","AUS","South Australia","","4233","1036536547"
"Lismore","Lismore","-28.8167","153.2833","Australia","AU","AUS","New South Wales","","3579","1036024174"
"Igarka","Igarka","67.4667","86.5833","Russia","RU","RUS","Krasnoyarskiy Kray","","4975","1643125542"
"Ingham","Ingham","-18.6508","146.1572","Australia","AU","AUS","Queensland","","4426","1036238542"
"Mitzic","Mitzic","0.7833","11.5667","Gabon","GA","GAB","Woleu-Ntem","","4926","1266866714"
"Turukhansk","Turukhansk","65.7931","87.9622","Russia","RU","RUS","Krasnoyarskiy Kray","","4774","1643037921"
"Susuman","Susuman","62.7833","148.1667","Russia","RU","RUS","Magadanskaya Oblast’","","4760","1643731360"
"Oranjemund","Oranjemund","-28.5517","16.4264","Namibia","NA","NAM","//Karas","","3900","1516991599"
"Bagdarin","Bagdarin","54.4444","113.5872","Russia","RU","RUS","Buryatiya","","4735","1643127681"
"Smithton","Smithton","-40.8440","145.1200","Australia","AU","AUS","Tasmania","","3881","1036207188"
"Svolvær","Svolvaer","68.2353","14.5636","Norway","NO","NOR","Nordland","minor","4686","1578516140"
"Westport","Westport","-41.7581","171.6022","New Zealand","NZ","NZL","West Coast","","4660","1554650685"
"Finnsnes","Finnsnes","69.2294","17.9811","Norway","NO","NOR","Troms og Finnmark","minor","4658","1578015471"
"Perito Moreno","Perito Moreno","-46.5886","-70.9242","Argentina","AR","ARG","Santa Cruz","minor","4617","1032891854"
"Narrogin","Narrogin","-32.9360","117.1780","Australia","AU","AUS","Western Australia","","4274","1036221961"
"Manjimup","Manjimup","-34.2411","116.1464","Australia","AU","AUS","Western Australia","","4349","1036022434"
"Camargo","Camargo","-20.6403","-65.2103","Bolivia","BO","BOL","Chuquisaca","","4502","1068344721"
"Gobernador Gregores","Gobernador Gregores","-48.7667","-70.2500","Argentina","AR","ARG","Santa Cruz","minor","4497","1032720049"
"Tepelenë","Tepelene","40.2967","20.0189","Albania","AL","ALB","Gjirokastër","","4342","1008683571"
"Pofadder","Pofadder","-29.1286","19.3947","South Africa","ZA","ZAF","Northern Cape","","4220","1710817164"
"Victorica","Victorica","-36.2167","-65.4500","Argentina","AR","ARG","La Pampa","minor","4458","1032392251"
"Manica","Manica","-18.9344","32.8756","Mozambique","MZ","MOZ","Manica","","4000","1508848017"
"Samaipata","Samaipata","-18.1794","-63.8756","Bolivia","BO","BOL","Santa Cruz","","4398","1068681645"
"Sokolo","Sokolo","14.7328","-6.1219","Mali","ML","MLI","Ségou","","4374","1466033359"
"Magdalena","Magdalena","-13.2606","-64.0528","Bolivia","BO","BOL","El Beni","","4379","1068699781"
"Merimbula","Merimbula","-36.8983","149.9011","Australia","AU","AUS","New South Wales","","3544","1036933197"
"Dehiba","Dehiba","32.0167","10.7000","Tunisia","TN","TUN","Tataouine","","4295","1788484869"
"Comandante Fontana","Comandante Fontana","-25.3333","-59.6833","Argentina","AR","ARG","Formosa","minor","4277","1032594164"
"La Paloma","La Paloma","-34.6500","-54.1667","Uruguay","UY","URY","Rocha","","3495","1858559267"
"Port Hedland","Port Hedland","-20.3100","118.6011","Australia","AU","AUS","Western Australia","","4180","1036509606"
"Apolo","Apolo","-14.7200","-68.5039","Bolivia","BO","BOL","La Paz","","4189","1068238601"
"Ersekë","Erseke","40.3333","20.6833","Albania","AL","ALB","Korçë","","3746","1008049831"
"Las Lajas","Las Lajas","-38.6000","-70.3000","Argentina","AR","ARG","Neuquén","minor","4078","1032092474"
"Çorovodë","Corovode","40.5000","20.2167","Albania","AL","ALB","Berat","","4051","1008566247"
"Pevek","Pevek","69.7000","170.2833","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","4053","1643597133"
"El Maitén","El Maiten","-42.0500","-71.1667","Argentina","AR","ARG","Buenos Aires, Ciudad Autónoma de","","4011","1032286116"
"Karmah an Nuzul","Karmah an Nuzul","19.6008","30.4097","Sudan","SD","SDN","Northern","","3928","1729918529"
"Nautla","Nautla","20.2167","-96.7833","Mexico","MX","MEX","Veracruz","minor","2890","1484174535"
"Sicasica","Sicasica","-17.3333","-67.7333","Bolivia","BO","BOL","La Paz","","3831","1068066667"
"Vergara","Vergara","-32.9500","-53.9333","Uruguay","UY","URY","Treinta y Tres","","3810","1858979569"
"Teseney","Teseney","15.1100","36.6575","Eritrea","ER","ERI","Gash-Barka","","3753","1232609825"
"Weipa","Weipa","-12.6300","141.8786","Australia","AU","AUS","Queensland","","3291","1036501067"
"Tirupati","Tirupati","13.6500","79.4167","India","IN","IND","Andhra Pradesh","","3165","1356362995"
"Pukë","Puke","42.0500","19.9000","Albania","AL","ALB","Shkodër","","3607","1008739218"
"Clare","Clare","-33.8333","138.6000","Australia","AU","AUS","South Australia","","3327","1036842122"
"Ulaan-Uul","Ulaan-Uul","44.3337","111.2333","Mongolia","MN","MNG","Dornogovĭ","minor","3726","1496264968"
"Proserpine","Proserpine","-20.4016","148.5808","Australia","AU","AUS","Queensland","","3562","1036150859"
"Wallaroo","Wallaroo","-33.9167","137.6167","Australia","AU","AUS","South Australia","","3481","1036743246"
"Katanning","Katanning","-33.6908","117.5553","Australia","AU","AUS","Western Australia","","3687","1036411379"
"Lavumisa","Lavumisa","-27.3167","31.9000","Swaziland","SZ","SWZ","Shiselweni","","3695","1748490870"
"Padilla","Padilla","-19.3000","-64.3000","Bolivia","BO","BOL","Chuquisaca","","3697","1068422501"
"Port Douglas","Port Douglas","-16.4834","145.4652","Australia","AU","AUS","Queensland","","3504","1036053723"
"Yomou","Yomou","7.5660","-9.2533","Guinea","GN","GIN","N’Zérékoré","minor","3614","1324839778"
"Tessalit","Tessalit","20.2011","1.0125","Mali","ML","MLI","Kidal","minor","3612","1466140377"
"Turangi","Turangi","-38.9889","175.8083","New Zealand","NZ","NZL","Waikato","","3320","1554981086"
"Baltasar Brum","Baltasar Brum","-30.7167","-57.3333","Uruguay","UY","URY","Artigas","","2531","1858039985"
"Kirkenes","Kirkenes","69.7269","30.0456","Norway","NO","NOR","Troms og Finnmark","minor","3531","1578657312"
"Srednekolymsk","Srednekolymsk","67.4667","153.7167","Russia","RU","RUS","Sakha (Yakutiya)","","3489","1643536960"
"Zhigansk","Zhigansk","66.7708","123.3710","Russia","RU","RUS","Sakha (Yakutiya)","","3399","1643742366"
"Trancas","Trancas","-26.2172","-65.2831","Argentina","AR","ARG","Tucumán","minor","3391","1032418123"
"Charleville","Charleville","-26.4016","146.2383","Australia","AU","AUS","Queensland","","3335","1036575498"
"Mopipi","Mopipi","-21.2019","24.8683","Botswana","BW","BWA","Central","","3301","1072326657"
"Mezen","Mezen","65.8333","44.2667","Russia","RU","RUS","Arkhangel’skaya Oblast’","","3267","1643928515"
"Rørvik","Rorvik","64.8619","11.2397","Norway","NO","NOR","Trøndelag","minor","3226","1578785320"
"Juradó","Jurado","7.1114","-77.7714","Colombia","CO","COL","Chocó","minor","3239","1170520604"
"Hokitika","Hokitika","-42.7156","170.9681","New Zealand","NZ","NZL","West Coast","","2892","1554205461"
"Mkokotoni","Mkokotoni","-5.8800","39.2731","Tanzania","TZ","TZA","Zanzibar North","minor","2572","1834206004"
"Teeli","Teeli","51.0086","90.2092","Russia","RU","RUS","Tyva","","3192","1643964699"
"Sinnamary","Sinnamary","5.3800","-52.9600","French Guiana","GF","GUF","","minor","3180","1254385870"
"Bordertown","Bordertown","-36.3118","140.7702","Australia","AU","AUS","South Australia","","2953","1036115980"
"Karungu","Karungu","-0.8496","34.1500","Kenya","KE","KEN","Migori","","2376","1404796412"
"Aiguá","Aigua","-34.2000","-54.7500","Uruguay","UY","URY","Maldonado","","2692","1858068520"
"Buur Gaabo","Buur Gaabo","-1.2175","41.8378","Somalia","SO","SOM","Jubbada Hoose","","3096","1706122850"
"Mangbwalu","Mangbwalu","1.9352","30.0462","Congo (Kinshasa)","CD","COD","Ituri","","2819","1180299514"
"Tom Price","Tom Price","-22.6939","117.7950","Australia","AU","AUS","Western Australia","","3005","1036464109"
"I-n-Amguel","I-n-Amguel","23.6936","5.1647","Algeria","DZ","DZA","Tamanrasset","","3030","1012162135"
"Esperance","Esperance","-33.8611","121.8919","Australia","AU","AUS","Western Australia","","2144","1036299314"
"Longreach","Longreach","-23.4422","144.2491","Australia","AU","AUS","Queensland","","2970","1036622618"
"Puerto Villamil","Puerto Villamil","-0.9568","-90.9672","Ecuador","EC","ECU","Galápagos","","2200","1218383536"
"Merredin","Merredin","-31.4820","118.2790","Australia","AU","AUS","Western Australia","","2850","1036578320"
"Urubamba","Urubamba","-13.3042","-72.1167","Peru","PE","PER","Cusco","","2700","1604753575"
"Donegal","Donegal","54.6540","-8.1100","Ireland","IE","IRL","Donegal","","2618","1372576120"
"Hlatikulu","Hlatikulu","-26.9667","31.3167","Swaziland","SZ","SWZ","Shiselweni","","2748","1748564468"
"Río Mayo","Rio Mayo","-45.6869","-70.2600","Argentina","AR","ARG","Chubut","","2791","1032155044"
"Cochrane","Cochrane","-47.2547","-72.5750","Chile","CL","CHL","Aysén","minor","2789","1152054657"
"Mount Barker","Mount Barker","-34.6300","117.6669","Australia","AU","AUS","Western Australia","","2741","1036651498"
"Saint-Georges","Saint-Georges","3.9105","-51.8100","French Guiana","GF","GUF","","minor","2742","1254726319"
"Cloncurry","Cloncurry","-20.7047","140.5052","Australia","AU","AUS","Queensland","","2719","1036672657"
"Scottsdale","Scottsdale","-41.1667","147.5167","Australia","AU","AUS","Tasmania","","2373","1036807079"
"Rodeo","Rodeo","-30.2164","-69.1394","Argentina","AR","ARG","San Juan","minor","2625","1032937217"
"Ísafjörður","Isafjordhur","66.0758","-23.1267","Iceland","IS","ISL","Ísafjarðarbær","","2620","1352563432"
"Bourke","Bourke","-30.1000","145.9333","Australia","AU","AUS","New South Wales","","1909","1036074667"
"Te Anau","Te Anau","-45.4167","167.7167","New Zealand","NZ","NZL","Southland","","2250","1554603580"
"Chumbicha","Chumbicha","-28.8667","-66.2333","Argentina","AR","ARG","Catamarca","minor","2572","1032994295"
"Exmouth","Exmouth","-21.9331","114.1281","Australia","AU","AUS","Western Australia","","2514","1036006022"
"Tasiilaq","Tasiilaq","65.6136","-37.6311","Greenland","GL","GRL","Sermersooq","","2018","1304230512"
"Nauta","Nauta","-4.5083","-73.5833","Peru","PE","PER","Loreto","","2500","1604159322"
"Severo-Kuril’sk","Severo-Kuril'sk","50.6667","156.1167","Russia","RU","RUS","Sakhalinskaya Oblast’","","2485","1643689971"
"Tarabuco","Tarabuco","-19.1667","-64.9167","Bolivia","BO","BOL","Chuquisaca","","2442","1068481915"
"Queenstown","Queenstown","-42.0667","145.5500","Australia","AU","AUS","Tasmania","","1790","1036082142"
"Baures","Baures","-13.6556","-63.6958","Bolivia","BO","BOL","El Beni","","2422","1068657733"
"Al ‘Alamayn","Al \`Alamayn","30.8333","28.9500","Egypt","EG","EGY","Maţrūḩ","","2413","1818237095"
"El Dorado","El Dorado","6.7167","-61.6167","Venezuela","VE","VEN","Bolívar","","2383","1862242782"
"Höfn","Hofn","64.2540","-15.2120","Iceland","IS","ISL","Sveitarfélagið Hornafjörður","","2389","1352857630"
"Jaqué","Jaque","7.5181","-78.1625","Panama","PA","PAN","Darién","minor","2386","1591579329"
"Boffa","Boffa","10.1850","-14.0430","Guinea","GN","GIN","Boké","minor","2332","1324801511"
"Katwe","Katwe","-0.1296","29.9200","Uganda","UG","UGA","Kasese","","1957","1800329579"
"Coroico","Coroico","-16.1833","-67.7333","Bolivia","BO","BOL","La Paz","","2319","1068106383"
"Egilsstaðir","Egilsstadhir","65.2833","-14.3833","Iceland","IS","ISL","Múlaþing","","2332","1352699507"
"Saskylakh","Saskylakh","71.9653","114.0922","Russia","RU","RUS","Sakha (Yakutiya)","","2317","1643818493"
"Lehututu","Lehututu","-23.9169","21.8264","Botswana","BW","BWA","Kgalagadi","","2284","1072398415"
"Sorata","Sorata","-15.7733","-68.6481","Bolivia","BO","BOL","La Paz","","2217","1068157921"
"Roura","Roura","4.7300","-52.3300","French Guiana","GF","GUF","","minor","2229","1254511393"
"Kaikoura","Kaikoura","-42.4000","173.6800","New Zealand","NZ","NZL","Canterbury","","2210","1554578431"
"José Batlle y Ordóñez","Jose Batlle y Ordonez","-33.4667","-55.1500","Uruguay","UY","URY","Lavalleja","","2203","1858852252"
"Tumby Bay","Tumby Bay","-34.3667","136.1000","Australia","AU","AUS","South Australia","","1631","1036529327"
"Alexander Bay","Alexander Bay","-28.6083","16.5033","South Africa","ZA","ZAF","Northern Cape","","1500","1710616152"
"Rockhampton","Rockhampton","-23.3781","150.5136","Australia","AU","AUS","Queensland","","1953","1036768986"
"Maitland","Maitland","-32.7167","151.5500","Australia","AU","AUS","New South Wales","","1885","1036034945"
"Penola","Penola","-37.3786","140.8362","Australia","AU","AUS","South Australia","","1592","1036121853"
"Borgarnes","Borgarnes","64.5333","-21.9167","Iceland","IS","ISL","Borgarbyggð","","1962","1352063842"
"Mazatán","Mazatan","29.0167","-110.1333","Mexico","MX","MEX","Sonora","minor","1058","1484350756"
"Huinan","Huinan","42.6229","126.2614","China","CN","CHN","Jilin","minor","","1156136342"
"Innisfail","Innisfail","-17.5238","146.0311","Australia","AU","AUS","Queensland","","1145","1036930229"
"Kalyān","Kalyan","19.2502","73.1602","India","IN","IND","Mahārāshtra","","","1356172246"
"Mysore","Mysore","12.3086","76.6531","India","IN","IND","Karnātaka","","","1356779323"
"Novyy Port","Novyy Port","67.6919","72.8964","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","1790","1643538343"
"Nokaneng","Nokaneng","-19.6639","22.1917","Botswana","BW","BWA","North West","","1763","1072707427"
"Barcaldine","Barcaldine","-23.5555","145.2888","Australia","AU","AUS","Queensland","","1422","1036284609"
"Kingston South East","Kingston South East","-36.8167","139.8500","Australia","AU","AUS","South Australia","","1393","1036261175"
"Gawler","Gawler","-34.5981","138.7450","Australia","AU","AUS","South Australia","","650","1036309493"
"Peterborough","Peterborough","-32.9667","138.8333","Australia","AU","AUS","South Australia","","1497","1036104272"
"Streaky Bay","Streaky Bay","-32.8000","134.2167","Australia","AU","AUS","South Australia","","1378","1036134944"
"Puerto Williams","Puerto Williams","-54.9333","-67.6167","Chile","CL","CHL","Magallanes y de la Antártica Chilena","minor","1646","1152455279"
"Cuevo","Cuevo","-20.4500","-63.5167","Bolivia","BO","BOL","Santa Cruz","","1637","1068137787"
"Alto Río Senguer","Alto Rio Senguer","-45.0167","-70.8167","Argentina","AR","ARG","Chubut","minor","1570","1032532362"
"Kalbarri","Kalbarri","-27.7100","114.1600","Australia","AU","AUS","Western Australia","","1557","1036429141"
"Artëmovsk","Artemovsk","54.3483","93.4356","Russia","RU","RUS","Krasnoyarskiy Kray","","1562","1643012116"
"Uummannaq","Uummannaq","70.6747","-52.1264","Greenland","GL","GRL","Avannaata","","1407","1304056798"
"Sierra Colorada","Sierra Colorada","-40.5833","-67.8000","Argentina","AR","ARG","Río Negro","minor","1542","1032417224"
"Iracoubo","Iracoubo","5.4804","-53.2200","French Guiana","GF","GUF","","minor","1536","1254480447"
"Ouyen","Ouyen","-35.0667","142.3170","Australia","AU","AUS","Victoria","","1191","1036764173"
"Halls Creek","Halls Creek","-18.2300","127.6700","Australia","AU","AUS","Western Australia","","1499","1036444511"
"Chibemba","Chibemba","-15.7355","13.8905","Angola","AO","AGO","Huíla","","1502","1024009942"
"Port Denison","Port Denison","-29.2750","114.9194","Australia","AU","AUS","Western Australia","","1410","1036873405"
"Wagin","Wagin","-33.3167","117.3500","Australia","AU","AUS","Western Australia","","1495","1036851354"
"Tajarhī","Tajarhi","24.2622","14.5603","Libya","LY","LBY","Murzuq","","1500","1434443909"
"Katherine","Katherine","-14.4667","132.2667","Australia","AU","AUS","Northern Territory","","1488","1036323110"
"Lokwabe","Lokwabe","-24.0961","21.7781","Botswana","BW","BWA","Kgalagadi","","1473","1072642304"
"Qasigiannguit","Qasigiannguit","68.8201","-51.1932","Greenland","GL","GRL","Qeqertalik","","1081","1304001772"
"Paamiut","Paamiut","61.9944","-49.6667","Greenland","GL","GRL","Sermersooq","","1429","1304323922"
"Tsau","Tsau","-20.1686","22.4558","Botswana","BW","BWA","North West","","1409","1072736607"
"Zaragoza","Zaragoza","13.5833","-89.2833","El Salvador","SV","SLV","La Libertad","","22525.0","1222511136"
"Santa Cruz de la Sierra","Santa Cruz de la Sierra","-17.7520","-63.2279","Bolivia","BO","BOL","Santa Cruz","","","1068005036"
"Tarutung","Tarutung","2.0167","98.9667","Indonesia","ID","IDN","Sumatera Utara","minor","1305","1360333240"
"Kazachye","Kazachye","70.7522","136.2340","Russia","RU","RUS","Sakha (Yakutiya)","","1367","1643011063"
"Nakhodka","Nakhodka","67.7206","77.6617","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","1365","1643884797"
"Hāthras","Hathras","27.3600","78.0300","India","IN","IND","Uttar Pradesh","","","1356072147"
"Port Pirie","Port Pirie","-33.1858","138.0169","Australia","AU","AUS","South Australia","","195","1036571606"
"Greytown","Greytown","10.9467","-83.7358","Nicaragua","NI","NIC","Río San Juan","minor","1307","1558125707"
"Sitalpur","Sitalpur","27.6300","80.7500","India","IN","IND","Uttar Pradesh","","","1356838415"
"Príncipe da Beira","Principe da Beira","-12.4167","-64.4166","Brazil","BR","BRA","Rondônia","","956","1076482734"
"Lavrentiya","Lavrentiya","65.5842","-170.9889","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","1242","1643889063"
"Meningie","Meningie","-35.6883","139.3380","Australia","AU","AUS","South Australia","","1118","1036445634"
"Hughenden","Hughenden","-20.8438","144.1986","Australia","AU","AUS","Queensland","","1136","1036738932"
"Ambāla","Ambala","30.3786","76.7725","India","IN","IND","Haryāna","","","1356947216"
"Verkhoyansk","Verkhoyansk","67.5500","133.3833","Russia","RU","RUS","Sakha (Yakutiya)","","1122","1643386560"
"Ciudad Arce","Ciudad Arce","13.8333","-89.4333","El Salvador","SV","SLV","La Libertad","","60314.0","1222728065"
"Cowell","Cowell","-33.6833","136.9167","Australia","AU","AUS","South Australia","","990","1036921929"
"Uad Damran","Uad Damran","27.4181","-12.9842","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","","1108","1504003880"
"Yulara","Yulara","-25.2406","130.9889","Australia","AU","AUS","Northern Territory","","1099","1036245584"
"Susques","Susques","-23.4167","-66.4833","Argentina","AR","ARG","Jujuy","minor","1093","1032742255"
"Upernavik","Upernavik","72.7869","-56.1472","Greenland","GL","GRL","Avannaata","","1092","1304750851"
"Chumikan","Chumikan","54.7000","135.2833","Russia","RU","RUS","Khabarovskiy Kray","","1091","1643639466"
"Yélimané","Yelimane","15.1180","-10.5720","Mali","ML","MLI","Kayes","minor","988","1466380722"
"Bicheno","Bicheno","-41.8667","148.2833","Australia","AU","AUS","Tasmania","","943","1036189642"
"Roebourne","Roebourne","-20.7667","117.1500","Australia","AU","AUS","Western Australia","","981","1036752479"
"Winton","Winton","-22.3913","143.0381","Australia","AU","AUS","Queensland","","875","1036031036"
"Oatlands","Oatlands","-42.3000","147.3706","Australia","AU","AUS","Tasmania","","683","1036926323"
"Kempsey","Kempsey","-31.0833","152.8333","Australia","AU","AUS","New South Wales","","474","1036897003"
"Gingin","Gingin","-31.3400","115.9100","Australia","AU","AUS","Western Australia","","852","1036686561"
"Godhavn","Godhavn","69.2472","-53.5333","Greenland","GL","GRL","Qeqertalik","","847","1304388071"
"Ayan","Ayan","56.4583","138.1681","Russia","RU","RUS","Khabarovskiy Kray","","920","1643586492"
"Wilcannia","Wilcannia","-31.5650","143.3678","Australia","AU","AUS","New South Wales","","745","1036134474"
"Harīpur","Haripur","31.5322","75.9172","India","IN","IND","Punjab","","347","1356141115"
"Nanyangcun","Nanyangcun","36.0819","114.3481","China","CN","CHN","Hebei","","","1156660821"
"Laverton","Laverton","-28.6280","122.4030","Australia","AU","AUS","Western Australia","","871","1036624366"
"Pīlibhīt","Pilibhit","28.6333","79.7667","India","IN","IND","Uttar Pradesh","","","1356147005"
"Onslow","Onslow","-21.6333","115.1167","Australia","AU","AUS","Western Australia","","857","1036255184"
"Dudhauni","Dudhauni","28.0300","79.0900","India","IN","IND","Uttar Pradesh","","","1356107951"
"Tamworth","Tamworth","-31.0833","150.9167","Australia","AU","AUS","New South Wales","","180","1036233388"
"Omolon","Omolon","65.2667","160.4667","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","785","1643719081"
"Leonora","Leonora","-28.8845","121.3308","Australia","AU","AUS","Western Australia","","781","1036140404"
"Wyndham","Wyndham","-15.4825","128.1230","Australia","AU","AUS","Western Australia","","780","1036474435"
"Linxi","Linxi","43.5171","118.0333","China","CN","CHN","Inner Mongolia","minor","679","1156608652"
"Comallo","Comallo","-41.0333","-70.2667","Argentina","AR","ARG","Río Negro","","741","1032806030"
"Eidsvold","Eidsvold","-25.3667","151.1333","Australia","AU","AUS","Queensland","","574","1036235197"
"Pannawonica","Pannawonica","-21.6350","116.3360","Australia","AU","AUS","Western Australia","","695","1036914573"
"Zhilinda","Zhilinda","70.1333","113.9833","Russia","RU","RUS","Sakha (Yakutiya)","","709","1643497497"
"Meekatharra","Meekatharra","-26.5931","118.4911","Australia","AU","AUS","Western Australia","","708","1036608457"
"Panying","Panying","33.0023","112.5281","China","CN","CHN","Henan","","","1156979429"
"Ubombo","Ubombo","-27.5667","32.0833","South Africa","ZA","ZAF","KwaZulu-Natal","","564","1710203500"
"Southern Cross","Southern Cross","-31.2500","119.3500","Australia","AU","AUS","Western Australia","","680","1036931490"
"Three Springs","Three Springs","-29.5333","115.7617","Australia","AU","AUS","Western Australia","","381","1036669084"
"Ituni","Ituni","5.5333","-58.2500","Guyana","GY","GUY","Upper Demerara-Berbice","","676","1328501744"
"Aguilares","Aguilares","13.9500","-89.1833","El Salvador","SV","SLV","San Salvador","","21267.0","1222041807"
"Kimba","Kimba","-33.1333","136.4170","Australia","AU","AUS","South Australia","","629","1036035341"
"Richmond","Richmond","-20.7305","143.1425","Australia","AU","AUS","Queensland","","648","1036156498"
"Qaanaaq","Qaanaaq","77.4667","-69.2306","Greenland","GL","GRL","Avannaata","","646","1304094556"
"Mālegaon","Malegaon","20.5500","74.5500","India","IN","IND","Mahārāshtra","","","1356113556"
"Uelen","Uelen","66.1594","-169.8092","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","619","1643387315"
"Theodore","Theodore","-24.9500","150.0833","Australia","AU","AUS","Queensland","","438","1036423364"
"Gastre","Gastre","-42.2667","-69.2167","Argentina","AR","ARG","Chubut","minor","602","1032815504"
"Candelaria","Candelaria","13.7500","-88.9500","El Salvador","SV","SLV","Cuscatlán","","10090.0","1222584902"
"Norseman","Norseman","-32.1961","121.7780","Australia","AU","AUS","Western Australia","","581","1036257263"
"Mikhalkino","Mikhalkino","69.4353","161.5510","Russia","RU","RUS","Sakha (Yakutiya)","","570","1643001530"
"Navsāri","Navsari","20.8504","72.9200","India","IN","IND","Gujarāt","","","1356120513"
"Telsen","Telsen","-42.3833","-66.9500","Argentina","AR","ARG","Chubut","minor","544","1032824407"
"San José Villanueva","San Jose Villanueva","13.5833","-89.2667","El Salvador","SV","SLV","La Libertad","","13576.0","1222497651"
"Karumba","Karumba","-17.4838","140.8397","Australia","AU","AUS","Queensland","","531","1036926106"
"Isemi-Ile","Isemi-Ile","7.9700","3.5900","Nigeria","NG","NGA","Oyo","","","1566369938"
"Morawa","Morawa","-29.2111","116.0090","Australia","AU","AUS","Western Australia","","532","1036194388"
"Tonk","Tonk","26.1505","75.7900","India","IN","IND","Rājasthān","","","1356991408"
"Andamooka","Andamooka","-30.4470","137.1660","Australia","AU","AUS","South Australia","","316","1036260794"
"Ravensthorpe","Ravensthorpe","-33.5831","120.0490","Australia","AU","AUS","Western Australia","","498","1036878618"
"Georgetown","Georgetown","-18.3000","143.5500","Australia","AU","AUS","Queensland","","348","1036412066"
"Chengde","Chengde","40.9604","117.9300","China","CN","CHN","Hebei","minor","","1156197800"
"Mount Magnet","Mount Magnet","-28.0600","117.8460","Australia","AU","AUS","Western Australia","","470","1036327720"
"Oymyakon","Oymyakon","63.4629","142.7870","Russia","RU","RUS","Sakha (Yakutiya)","","462","1643797797"
"Boulia","Boulia","-22.9000","139.9000","Australia","AU","AUS","Queensland","","301","1036512474"
"Porbandar","Porbandar","21.6425","69.6047","India","IN","IND","Gujarāt","","","1356634492"
"Espungabera","Espungabera","-20.4531","32.7725","Mozambique","MZ","MOZ","Manica","","393","1508256538"
"Halfmoon Bay","Halfmoon Bay","-46.9000","168.1333","New Zealand","NZ","NZL","Southland","","300","1554077152"
"Santa María","Santa Maria","13.3500","-88.4333","El Salvador","SV","SLV","Usulután","","10731.0","1222973586"
"San Ignacio","San Ignacio","14.3333","-89.1833","El Salvador","SV","SLV","Chalatenango","","8611.0","1222199797"
"Adelaide River","Adelaide River","-13.2381","131.1061","Australia","AU","AUS","Northern Territory","","353","1036136750"
"Kairaki","Kairaki","-43.3860","172.7030","New Zealand","NZ","NZL","Canterbury","","225","1554001309"
"Burketown","Burketown","-17.7167","139.5667","Australia","AU","AUS","Queensland","","238","1036956849"
"Scoresbysund","Scoresbysund","70.4853","-21.9667","Greenland","GL","GRL","Sermersooq","","345","1304036812"
"Progress","Progress","49.7504","129.6167","Russia","RU","RUS","Amurskaya Oblast’","","146","1643007077"
"Ivanhoe","Ivanhoe","-32.8983","144.3000","Australia","AU","AUS","New South Wales","","327","1036571526"
"Thargomindah","Thargomindah","-28.0000","143.8167","Australia","AU","AUS","Queensland","","270","1036793708"
"Pine Creek","Pine Creek","-13.8231","131.8330","Australia","AU","AUS","Northern Territory","","328","1036858004"
"Santa Elena","Santa Elena","13.3833","-88.4167","El Salvador","SV","SLV","Usulután","","17342.0","1222446102"
"Ikela","Ikela","-1.1833","23.2667","Congo (Kinshasa)","CD","COD","Tshuapa","","291","1180365335"
"Cazombo","Cazombo","-11.9058","22.9217","Angola","AO","AGO","Moxico","","298","1024334740"
"Shoyna","Shoyna","67.8778","44.1547","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","","300","1643012303"
"Enurmino","Enurmino","66.9500","-171.8167","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","297","1643693470"
"Timbedgha","Timbedgha","16.2447","-8.1675","Mauritania","MR","MRT","Hodh ech Chargui","","245","1478847634"
"Santa Rosa de Lima","Santa Rosa de Lima","13.6167","-87.9000","El Salvador","SV","SLV","La Unión","","27693.0","1222685662"
"Conchagua","Conchagua","13.3000","-87.8667","El Salvador","SV","SLV","La Unión","","37362.0","1222328489"
"El Tránsito","El Transito","13.3500","-88.3500","El Salvador","SV","SLV","San Miguel","","18363.0","1222690970"
"Camooweal","Camooweal","-19.9167","138.1170","Australia","AU","AUS","Queensland","","208","1036815011"
"Carnarvon","Carnarvon","-24.8672","113.6611","Australia","AU","AUS","Western Australia","","208","1036344209"
"Hubli","Hubli","15.3619","75.1231","India","IN","IND","Karnātaka","","","1356102365"
"Korf","Korf","60.3667","166.0167","Russia","RU","RUS","Kamchatskiy Kray","","175","1643630456"
"Birdsville","Birdsville","-25.8989","139.3520","Australia","AU","AUS","Queensland","","140","1036830335"
"Bedourie","Bedourie","-24.3500","139.4667","Australia","AU","AUS","Queensland","","122","1036680924"
"Mount Isa","Mount Isa","-20.7261","139.4938","Australia","AU","AUS","Queensland","","118","1036386100"
"Windorah","Windorah","-25.4206","142.6547","Australia","AU","AUS","Queensland","","115","1036272226"
"Punta Prieta","Punta Prieta","28.9289","-114.1556","Mexico","MX","MEX","Baja California","","112","1484583029"
"Sharbaqty","Sharbaqty","52.4800","78.1500","Kazakhstan","KZ","KAZ","","minor","107","1398705015"
"Chirilagua","Chirilagua","13.2167","-88.1386","El Salvador","SV","SLV","San Miguel","","19984.0","1222360893"
"Al ‘Uqaylah","Al \`Uqaylah","30.2558","19.1994","Libya","LY","LBY","Al Wāḩāt","","100","1434822941"
"Victoria","Victoria","13.9500","-88.6333","El Salvador","SV","SLV","Cabañas","","12626.0","1222943235"
"Pasaquina","Pasaquina","13.5844","-87.8411","El Salvador","SV","SLV","La Unión","","16375.0","1222645421"
"Sesori","Sesori","13.7167","-88.3667","El Salvador","SV","SLV","San Miguel","","10705.0","1222371980"
"Kovda","Kovda","66.6919","32.8708","Russia","RU","RUS","Murmanskaya Oblast’","","20","1643959579"
"Bhuj","Bhuj","23.2500","69.6700","India","IN","IND","Gujarāt","","","1356806356"
"Kingoonya","Kingoonya","-30.9000","135.3000","Australia","AU","AUS","South Australia","","13","1036942792"
"Tiyerbes","Tiyerbes","64.3728","120.5490","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643794725"
"Ust’-Nyukzha","Ust'-Nyukzha","56.5608","121.6031","Russia","RU","RUS","Amurskaya Oblast’","","10","1643347046"
"Chegga","Chegga","25.3733","-5.7873","Mauritania","MR","MRT","Tiris Zemmour","","10","1478632134"
"Ust’-Olenëk","Ust'-Olenek","72.9855","119.8214","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643349100"
"Olenëk","Olenek","68.5000","112.4667","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643535703"
"Ambarchik","Ambarchik","69.6167","162.2833","Russia","RU","RUS","Sakha (Yakutiya)","","4","1643739159"
"Logashkino","Logashkino","70.8536","153.8744","Russia","RU","RUS","Sakha (Yakutiya)","","0","1643050775"
"Charlotte Amalie","Charlotte Amalie","18.3420","-64.9331","U.S. Virgin Islands","VI","VIR","Virgin Islands","primary","","1850037473"
"Vohitrafeno","Vohitrafeno","-21.6667","47.2167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450638566"
"Vinanitelo","Vinanitelo","-21.7167","47.2667","Madagascar","MG","MDG","Fianarantsoa","","10000","1450114171"
"Bolsward","Bolsward","53.0667","5.5333","Netherlands","NL","NLD","Fryslân","","9974","1528220988"
"Gūlyam","Gulyam","15.3598","77.0670","India","IN","IND","Andhra Pradesh","","9981","1356714085"
"Antsoantany","Antsoantany","-19.7000","47.1667","Madagascar","MG","MDG","Antananarivo","","10000","1450425748"
"Miadanandriana","Miadanandriana","-19.0333","47.7833","Madagascar","MG","MDG","Antananarivo","","10000","1450763578"
"Mahabako","Mahabako","-21.7500","47.7167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450602617"
"Ambahatrazo","Ambahatrazo","-21.8667","47.9500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450118510"
"Puttige","Puttige","13.0751","74.9597","India","IN","IND","Karnātaka","","9946","1356940923"
"Vondrozo","Vondrozo","-22.8206","47.3217","Madagascar","MG","MDG","Fianarantsoa","","10000","1450832366"
"Ambatolava","Ambatolava","-23.5667","47.3000","Madagascar","MG","MDG","Fianarantsoa","","10000","1450777650"
"Isahara","Isahara","-23.7167","47.3500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450953236"
"Antaretra","Antaretra","-21.0667","47.8500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450713889"
"Tamponala","Tamponala","-19.3000","46.4167","Madagascar","MG","MDG","Antananarivo","","10000","1450730146"
"Ambodivoara","Ambodivoara","-14.4000","49.9500","Madagascar","MG","MDG","Antsiranana","","10000","1450201923"
"Morafeno","Morafeno","-21.2000","47.2833","Madagascar","MG","MDG","Fianarantsoa","","10000","1450789290"
"Závora","Zavora","-24.5167","35.2000","Mozambique","MZ","MOZ","Inhambane","","10000","1508814998"
"Erraguntlakota","Erraguntlakota","13.9622","79.2768","India","IN","IND","Andhra Pradesh","","9966","1356051675"
"Ambohitrambo","Ambohitrambo","-18.9167","47.1833","Madagascar","MG","MDG","Antananarivo","","10000","1450876774"
"Ankirondro","Ankirondro","-19.6333","44.6000","Madagascar","MG","MDG","Toliara","","10000","1450343397"
"Ambohimahazo","Ambohimahazo","-20.6667","47.0833","Madagascar","MG","MDG","Fianarantsoa","","10000","1450127672"
"Mataguá","Matagua","22.2370","-79.9992","Cuba","CU","CUB","Villa Clara","","10000","1192170152"
"Ţālkhvoncheh","Talkhvoncheh","32.2631","51.5622","Iran","IR","IRN","Eşfahān","","9924","1364903302"
"Andranambolava","Andranambolava","-20.9000","48.2667","Madagascar","MG","MDG","Fianarantsoa","","10000","1450035681"
"Marosakoa","Marosakoa","-16.2333","46.8333","Madagascar","MG","MDG","Mahajanga","","10000","1450329712"
"Belavabary","Belavabary","-18.8333","48.0000","Madagascar","MG","MDG","Toamasina","","10000","1450323665"
"Ankerana","Ankerana","-21.0333","47.1167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450047742"
"Sahatona-Tamboharivo","Sahatona-Tamboharivo","-20.9667","47.1000","Madagascar","MG","MDG","Fianarantsoa","","10000","1450124769"
"Ampitahana","Ampitahana","-21.1333","47.2167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450688601"
"Soatanana","Soatanana","-21.3833","46.9167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450865191"
"Ambararatabe","Ambararatabe","-18.9900","46.1900","Madagascar","MG","MDG","Antananarivo","","10000","1450180554"
"Aboso","Aboso","5.3633","-1.9478","Ghana","GH","GHA","Western","","9945","1288901641"
"Zoma-Bealoka","Zoma-Bealoka","-18.8500","47.0500","Madagascar","MG","MDG","Antananarivo","","10000","1450875060"
"Sahanivotry-Manandona","Sahanivotry-Manandona","-20.1167","47.0833","Madagascar","MG","MDG","Antananarivo","","10000","1450219332"
"Boanamary","Boanamary","-15.8333","46.3167","Madagascar","MG","MDG","Mahajanga","","10000","1450000246"
"Vinanitelo","Vinanitelo","-22.0167","47.7333","Madagascar","MG","MDG","Fianarantsoa","","10000","1450156601"
"Beanana","Beanana","-17.3667","48.4333","Madagascar","MG","MDG","Toamasina","","10000","1450709349"
"Ambodimadiro","Ambodimadiro","-14.6000","48.0833","Madagascar","MG","MDG","Mahajanga","","10000","1450696030"
"Ampasimazava","Ampasimazava","-17.6667","49.1333","Madagascar","MG","MDG","Toamasina","","10000","1450568333"
"Ifarantsa","Ifarantsa","-24.9333","46.8500","Madagascar","MG","MDG","Toliara","","10000","1450270852"
"Tanambao-Daoud","Tanambao-Daoud","-13.9833","50.1333","Madagascar","MG","MDG","Antsiranana","","10000","1450000216"
"Tujg","Tujg","32.0686","61.8058","Afghanistan","AF","AFG","Farāh","","10000","1004622853"
"Mora","Mora","14.3278","-4.6000","Mali","ML","MLI","Mopti","","10000","1466150735"
"Kalimala","Kalimala","18.0738","81.7495","India","IN","IND","Chhattīsgarh","","9926","1356918951"
"Ambatomivary","Ambatomivary","-23.8333","45.5667","Madagascar","MG","MDG","Toliara","","10000","1450847805"
"Vohitany","Vohitany","-24.1333","44.7667","Madagascar","MG","MDG","Toliara","","10000","1450985540"
"Thị Trấn Mậu A","Thi Tran Mau A","21.8781","104.6956","Vietnam","VN","VNM","Yên Bái","","9942","1704902932"
"Maroharatra","Maroharatra","-20.7333","47.7667","Madagascar","MG","MDG","Fianarantsoa","","10000","1450765892"
"Miarinarivo","Miarinarivo","-22.0833","47.0500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450277014"
"Ambatomifanongoa","Ambatomifanongoa","-20.2667","46.9167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450751314"
"Ambovonomby","Ambovonomby","-14.3667","49.0500","Madagascar","MG","MDG","Mahajanga","","10000","1450681597"
"Los Ríos","Los Rios","18.5219","-71.5900","Dominican Republic","DO","DOM","Enriquillo","","9963","1214434775"
"Daraina","Daraina","-13.2000","49.6667","Madagascar","MG","MDG","Antsiranana","","10000","1450505379"
"Ambohimiarivo","Ambohimiarivo","-19.8500","47.1500","Madagascar","MG","MDG","Antananarivo","","10000","1450510741"
"Dujiashigou","Dujiashigou","37.7691","110.0705","China","CN","CHN","Shaanxi","","9966","1156189243"
"Anosimparihy","Anosimparihy","-21.5000","47.9833","Madagascar","MG","MDG","Fianarantsoa","","10000","1450447380"
"Sandravinany","Sandravinany","-24.0333","47.4333","Madagascar","MG","MDG","Fianarantsoa","","10000","1450455975"
"Murgap","Murgap","37.4964","61.9739","Turkmenistan","TM","TKM","Mary","","10000","1795747825"
"Mazoe","Mazoe","-17.5167","30.9667","Zimbabwe","ZW","ZWE","Mashonaland Central","","9966","1716608716"
"Bunji","Bunji","35.6422","74.6336","Pakistan","PK","PAK","Gilgit-Baltistan","","10000","1586909748"
"Soavimbahoaka","Soavimbahoaka","-18.6833","46.1000","Madagascar","MG","MDG","Antananarivo","","10000","1450132575"
"Amparihy","Amparihy","-15.6667","47.1167","Madagascar","MG","MDG","Mahajanga","","10000","1450241299"
"Ambatolahy","Ambatolahy","-22.5333","46.0000","Madagascar","MG","MDG","Fianarantsoa","","10000","1450502699"
"Fanjakana","Fanjakana","-21.1833","46.8833","Madagascar","MG","MDG","Fianarantsoa","","10000","1450594461"
"Masiaboay","Masiaboay","-23.9000","44.4667","Madagascar","MG","MDG","Toliara","","10000","1450226801"
"Ambodisikidy","Ambodisikidy","-14.2333","48.4833","Madagascar","MG","MDG","Mahajanga","","10000","1450041005"
"Ebelo","Ebelo","-24.4833","46.0333","Madagascar","MG","MDG","Toliara","","10000","1450395346"
"Mboki","Mboki","5.3160","25.9571","Central African Republic","CF","CAF","Haut-Mbomou","","10000","1140277329"
"Chartoûn","Chartoun","33.7711","35.6858","Lebanon","LB","LBN","Mont-Liban","","10000","1422935329"
"Miary-Taheza","Miary-Taheza","-23.1333","44.6167","Madagascar","MG","MDG","Toliara","","10000","1450731758"
"Andranomenatsa","Andranomenatsa","-23.3167","44.5833","Madagascar","MG","MDG","Toliara","","10000","1450593069"
"Vohitsaoka","Vohitsaoka","-22.0333","46.7167","Madagascar","MG","MDG","Fianarantsoa","","10000","1450660197"
"Esira","Esira","-24.3333","46.7000","Madagascar","MG","MDG","Toliara","","10000","1450103451"
"Soahany","Soahany","-18.6667","44.2167","Madagascar","MG","MDG","Mahajanga","","10000","1450252746"
"Vodiriana","Vodiriana","-19.1667","48.5667","Madagascar","MG","MDG","Toamasina","","10000","1450696407"
"Bekodoka","Bekodoka","-16.9667","45.1000","Madagascar","MG","MDG","Mahajanga","","10000","1450176213"
"Ianapera","Ianapera","-23.6167","45.1333","Madagascar","MG","MDG","Toliara","","10000","1450881994"
"Jangany","Jangany","-22.8500","45.7833","Madagascar","MG","MDG","Toliara","","10000","1450631726"
"Namakadu","Namakadu","14.0060","80.1194","India","IN","IND","Andhra Pradesh","","9885","1356314478"
"Bedidy","Bedidy","-17.4667","48.0167","Madagascar","MG","MDG","Mahajanga","","10000","1450144441"
"Ambodimandresy","Ambodimandresy","-14.7833","48.0833","Madagascar","MG","MDG","Mahajanga","","10000","1450907900"
"Tsararano","Tsararano","-17.3333","46.3500","Madagascar","MG","MDG","Mahajanga","","10000","1450417123"
"Gadoon","Gadoon","5.6897","46.6847","Somalia","SO","SOM","Galguduud","","10000","1706085622"
"Beheloka","Beheloka","-23.9017","43.7635","Madagascar","MG","MDG","Toliara","","10000","1450950058"
"Ambalanjanakomby","Ambalanjanakomby","-16.7000","47.0833","Madagascar","MG","MDG","Mahajanga","","10000","1450821290"
"Mahabe","Mahabe","-17.0833","45.3333","Madagascar","MG","MDG","Mahajanga","","10000","1450085572"
"Andranopasy","Andranopasy","-21.2833","43.7333","Madagascar","MG","MDG","Toliara","","10000","1450818607"
"Tīgaon","Tigaon","21.6456","78.4576","India","IN","IND","Madhya Pradesh","","9847","1356180687"
"Anontsibe-Sakalava","Anontsibe-Sakalava","-21.3667","44.4667","Madagascar","MG","MDG","Toliara","","10000","1450810340"
"Katsepy","Katsepy","-15.7667","46.2333","Madagascar","MG","MDG","Mahajanga","","10000","1450252014"
"Tanamarina","Tanamarina","-21.5731","45.9400","Madagascar","MG","MDG","Fianarantsoa","","10000","1450528963"
"Amborompotsy","Amborompotsy","-20.6000","46.2500","Madagascar","MG","MDG","Fianarantsoa","","10000","1450347473"
"Betrandraka","Betrandraka","-17.0333","47.5500","Madagascar","MG","MDG","Mahajanga","","10000","1450299932"
"Puerto America","Puerto America","-11.5500","-68.0667","Bolivia","BO","BOL","Pando","","10000","1068285171"
"Sorab","Sorab","14.3814","75.0918","India","IN","IND","Karnātaka","","9803","1356932920"
"Lenīnskīy","Leninskiy","52.2528","76.7789","Kazakhstan","KZ","KAZ","","","9982","1398857054"
"Yueyaquan","Yueyaquan","40.1256","94.6632","China","CN","CHN","Gansu","","9977","1156428033"
"Williston","Williston","44.4345","-73.0888","United States","US","USA","Vermont","","9980","1840071854"
"Colts Neck","Colts Neck","40.2928","-74.1680","United States","US","USA","New Jersey","","9975","1840081833"
"Ban Non Sombun","Ban Non Sombun","18.2983","103.6528","Thailand","TH","THA","Bueng Kan","","9903","1764177972"
"P’yŏngch’ang","P'yongch'ang","37.3675","128.3958","South Korea","KR","KOR","Gangwon","","9940","1410797147"
"Pālkot","Palkot","22.8748","84.6433","India","IN","IND","Jhārkhand","","9804","1356093714"
"Birni Lafia","Birni Lafia","11.9783","3.2225","Benin","BJ","BEN","Alibori","","9946","1204630471"
"Ban Phan Chali","Ban Phan Chali","16.6333","100.4833","Thailand","TH","THA","Phitsanulok","","9873","1764623789"
"Narasimharājapura","Narasimharajapura","13.6108","75.5120","India","IN","IND","Karnātaka","","9852","1356403111"
"Kodivalasa","Kodivalasa","13.2547","79.4249","India","IN","IND","Tamil Nādu","","9830","1356546738"
"Ambinanintromby","Ambinanintromby","-21.6667","47.5167","Madagascar","MG","MDG","Fianarantsoa","","9748","1450658500"
"Gamba","Gamba","-2.7250","10.0167","Gabon","GA","GAB","Ogooué-Maritime","","9928","1266236988"
"Kouarfa","Kouarfa","10.4833","1.5167","Benin","BJ","BEN","Atacora","","9809","1204521211"
"Karimunjawa","Karimunjawa","-5.8192","110.4590","Indonesia","ID","IDN","Jawa Tengah","","9789","1360892611"
"Mae O","Mae O","19.6500","99.8333","Thailand","TH","THA","Chiang Rai","","9799","1764995328"
"Nödinge-Nol","Nodinge-Nol","57.9000","12.0667","Sweden","SE","SWE","Västra Götaland","","9822","1752000147"
"Penn Forest","Penn Forest","40.9571","-75.6313","United States","US","USA","Pennsylvania","","9921","1840143698"
"Mohlanapeng","Mohlanapeng","-29.6975","28.6947","Lesotho","LS","LSO","Thaba-Tseka","","9882","1426075936"
"Rangasamudram","Rangasamudram","13.7140","78.2645","India","IN","IND","Andhra Pradesh","","9727","1356087301"
"Imlil","Imlil","31.7567","-7.0106","Morocco","MA","MAR","Béni Mellal-Khénifra","","9796","1504125012"
"Ankazotsifantatra","Ankazotsifantatra","-19.9500","48.5500","Madagascar","MG","MDG","Toamasina","","9880","1450068487"
"Ambodiriana","Ambodiriana","-17.8861","49.1875","Madagascar","MG","MDG","Toamasina","","9861","1450447808"
"Krasnyy Yar","Krasnyy Yar","53.3239","69.2525","Kazakhstan","KZ","KAZ","","","9875","1398149745"
"Jixian","Jixian","35.7321","107.9731","China","CN","CHN","Gansu","","9822","1156259371"
"Vanipenta","Vanipenta","14.7906","78.7805","India","IN","IND","Andhra Pradesh","","9704","1356367532"
"Basso","Basso","10.5000","3.6833","Benin","BJ","BEN","Borgou","","9827","1204113994"
"Ban Pha Bong","Ban Pha Bong","19.2266","97.9707","Thailand","TH","THA","Mae Hong Son","","9874","1764208458"
"Sūknah","Suknah","29.0669","15.7847","Libya","LY","LBY","Al Jufrah","","9887","1434785614"
"Dulce Nombre de Jesús","Dulce Nombre de Jesus","10.0838","-83.9810","Costa Rica","CR","CRI","San José","","9744","1188000426"
"Djangoa","Djangoa","-13.7833","48.3167","Madagascar","MG","MDG","Antsiranana","","9800","1450339137"
"Alden","Alden","42.9114","-78.5211","United States","US","USA","New York","","9874","1840004394"
"Conway","Conway","44.0085","-71.0719","United States","US","USA","New Hampshire","","9870","1840054762"
"Pakhtaobod","Pakhtaobod","38.4667","68.1333","Tajikistan","TJ","TJK","Nohiyahoi Tobei Jumhurí","","9690","1762687670"
"Sirka","Sirka","9.5719","1.3183","Togo","TG","TGO","Kara","","9700","1768994330"
"Anjahamana","Anjahamana","-18.3667","48.9667","Madagascar","MG","MDG","Toamasina","","9846","1450772038"
"Pisac","Pisac","-13.4242","-71.8578","Peru","PE","PER","Cusco","","9796","1604788392"
"Oulad ’Azzouz","Oulad 'Azzouz","32.7693","-6.7543","Morocco","MA","MAR","Béni Mellal-Khénifra","","9815","1504559202"
"Ottappidāram","Ottappidaram","8.9127","78.0218","India","IN","IND","Tamil Nādu","","9674","1356118455"
"Piprai","Piprai","24.5097","77.9642","India","IN","IND","Madhya Pradesh","","9676","1356540061"
"Qarabalyq","Qarabalyq","53.7506","62.0502","Kazakhstan","KZ","KAZ","","","9838","1398254114"
"Fandrandava","Fandrandava","-21.5167","47.2500","Madagascar","MG","MDG","Fianarantsoa","","9742","1450327142"
"Ban Bueng Kok","Ban Bueng Kok","16.6833","100.0333","Thailand","TH","THA","Phitsanulok","","9688","1764708028"
"Philipstown","Philipstown","41.4189","-73.9152","United States","US","USA","New York","","9814","1840087800"
"Sävja","Savja","59.8167","17.7000","Sweden","SE","SWE","Uppsala","","9684","1752516953"
"Repatriación","Repatriacion","-25.5300","-55.9500","Paraguay","PY","PRY","Caaguazú","","9774","1600644492"
"Andrainjato","Andrainjato","-21.4667","47.0833","Madagascar","MG","MDG","Fianarantsoa","","9742","1450000119"
"San Fernando","San Fernando","9.2797","-74.5339","Colombia","CO","COL","Bolívar","","9776","1170368411"
"Ban Mae Sam Laep","Ban Mae Sam Laep","17.9750","97.7392","Thailand","TH","THA","Mae Hong Son","","9802","1764091055"
"Olmos","Olmos","-5.9855","-79.7569","Peru","PE","PER","Lambayeque","","9800","1604825576"
"Mohdra","Mohdra","24.1849","79.9577","India","IN","IND","Madhya Pradesh","","9687","1356912646"
"Sturbridge","Sturbridge","42.1076","-72.0904","United States","US","USA","Massachusetts","","9806","1840053606"
"Pindra","Pindra","24.9595","80.7879","India","IN","IND","Madhya Pradesh","","9675","1356005674"
"Mikun","Mikun","62.3667","50.0833","Russia","RU","RUS","Komi","","9796","1643910961"
"Pondalūru","Pondaluru","14.2535","79.2408","India","IN","IND","Andhra Pradesh","","9678","1356207096"
"Ban Mae Chedi","Ban Mae Chedi","19.1833","99.5167","Thailand","TH","THA","Chiang Rai","","9742","1764605248"
"Ban Dong Mada","Ban Dong Mada","19.7237","99.7153","Thailand","TH","THA","Chiang Rai","","9642","1764346183"
"Dangcheng","Dangcheng","39.5161","94.8728","China","CN","CHN","Gansu","","9792","1156309528"
"Bavānāt","Bavanat","30.4667","53.4500","Iran","IR","IRN","Fārs","","9776","1364321545"
"Southport","Southport","42.0400","-76.8775","United States","US","USA","New York","","9786","1840058508"
"Xiba","Xiba","40.1645","98.7521","China","CN","CHN","Gansu","","9753","1156484870"
"Woodbury","Woodbury","41.5615","-73.2070","United States","US","USA","Connecticut","","9762","1840045111"
"Seforong","Seforong","-30.1028","28.1864","Lesotho","LS","LSO","Quthing","","9720","1426821523"
"Tsaramasoandro","Tsaramasoandro","-17.9833","47.2000","Madagascar","MG","MDG","Antananarivo","","9747","1450116713"
"Shiyuan","Shiyuan","35.7991","102.8437","China","CN","CHN","Gansu","","9583","1156539699"
"Tālavādi","Talavadi","11.7780","77.0055","India","IN","IND","Tamil Nādu","","9581","1356280276"
"Ranomafana","Ranomafana","-21.2500","47.4500","Madagascar","MG","MDG","Fianarantsoa","","9705","1450503373"
"Ak-Suu","Ak-Suu","42.8000","74.1000","Kyrgyzstan","KG","KGZ","Chüy","","9677","1417410777"
"Chervyen","Chervyen","53.7078","28.4322","Belarus","BY","BLR","Minskaya Voblasts’","","9718","1112715216"
"Tayakou","Tayakou","10.5500","1.2000","Benin","BJ","BEN","Atacora","","9716","1204564962"
"Zengjiaba","Zengjiaba","32.1263","109.4692","China","CN","CHN","Shaanxi","","9708","1156810085"
"Pleasant Valley","Pleasant Valley","41.7697","-73.8049","United States","US","USA","New York","","9725","1840058409"
"Ban Lao Yao","Ban Lao Yao","18.3667","98.7833","Thailand","TH","THA","Lamphun","","9641","1764997700"
"Gonikoppal","Gonikoppal","12.1830","75.9294","India","IN","IND","Karnātaka","","9592","1356151416"
"Barwādih","Barwadih","23.8478","84.1105","India","IN","IND","Jhārkhand","","9527","1356067316"
"Mallampalli","Mallampalli","18.1105","79.8494","India","IN","IND","Andhra Pradesh","","9529","1356385749"
"Venecia","Venecia","10.3357","-84.2859","Costa Rica","CR","CRI","Alajuela","","9638","1188167757"
"Aqadyr","Aqadyr","48.2749","72.8599","Kazakhstan","KZ","KAZ","","","9710","1398472084"
"Tounfafi","Tounfafi","14.0464","5.9812","Niger","NE","NER","Tahoua","","9530","1562992849"
"Torihama","Torihama","35.6006","135.9406","Japan","JP","JPN","Fukui","","9643","1392000087"
"Datori","Datori","10.4017","0.7828","Benin","BJ","BEN","Atacora","","9615","1204437203"
"Pueblo Viejo","Pueblo Viejo","19.1781","-100.2856","Mexico","MX","MEX","México","","9682","1484057384"
"Canoas","Canoas","8.5333","-82.8333","Costa Rica","CR","CRI","Puntarenas","","9543","1188828441"
"Ādivāla","Adivala","13.9131","76.6619","India","IN","IND","Karnātaka","","9514","1356049786"
"Capitán Mauricio José Troche","Capitan Mauricio Jose Troche","-25.7500","-56.4333","Paraguay","PY","PRY","Guairá","","9539","1600161114"
"Aweitancun","Aweitancun","47.7251","88.0499","China","CN","CHN","Xinjiang","","9622","1156429262"
"Alberdi","Alberdi","-26.1900","-58.1300","Paraguay","PY","PRY","Ñeembucú","","9588","1600904463"
"Avsallar","Avsallar","36.5833","31.8833","Turkey","TR","TUR","Antalya","","9527","1792561921"
"Langar","Langar","39.4500","69.5500","Tajikistan","TJ","TJK","Sughd","","9666","1762492920"
"Maizal","Maizal","19.6500","-71.0300","Dominican Republic","DO","DOM","Cibao Noroeste","","9486","1214473100"
"Ramree","Ramree","19.0833","93.8667","Myanmar","MM","MMR","Rakhine State","","9581","1104079121"
"Marne","Marne","13.3284","75.0113","India","IN","IND","Karnātaka","","9467","1356788728"
"Gaada","Gaada","35.1594","-2.9892","Morocco","MA","MAR","Oriental","","9497","1504325635"
"Herkimer","Herkimer","43.0610","-74.9894","United States","US","USA","New York","","9635","1840004148"
"Ban Wiang Ka Long","Ban Wiang Ka Long","19.2332","99.5165","Thailand","TH","THA","Chiang Rai","","9572","1764433764"
"Ankadimanga","Ankadimanga","-18.9667","47.7500","Madagascar","MG","MDG","Antananarivo","","9526","1450905081"
"Xiada","Xiada","24.0391","113.4543","China","CN","CHN","Guangdong","","9560","1156159106"
"Naurhiya","Naurhiya","24.2069","82.5655","India","IN","IND","Madhya Pradesh","","9420","1356385624"
"Sultan-Yangiyurt","Sultan-Yangiyurt","43.2167","46.8667","Russia","RU","RUS","Dagestan","","9458","1643651411"
"Bou Zemou","Bou Zemou","32.1114","-5.5197","Morocco","MA","MAR","Drâa-Tafilalet","","9583","1504737844"
"Lisbon","Lisbon","44.0265","-70.0900","United States","US","USA","Maine","","9588","1840066320"
"South Strabane","South Strabane","40.1756","-80.1910","United States","US","USA","Pennsylvania","","9588","1840101300"
"Bougou","Bougou","9.4333","1.6167","Benin","BJ","BEN","Donga","","9505","1204336645"
"Åhus","Ahus","55.9167","14.2833","Sweden","SE","SWE","Skåne","","9423","1752365263"
"Iāwar","Iawar","23.0094","76.5007","India","IN","IND","Madhya Pradesh","","9414","1356135573"
"Vəndam","Vandam","40.9447","47.9411","Azerbaijan","AZ","AZE","Qəbələ","","9507","1031489928"
"Sonsoro","Sonsoro","11.0875","2.7597","Benin","BJ","BEN","Alibori","","9501","1204182952"
"Taisar","Taisar","22.4867","84.1804","India","IN","IND","Jhārkhand","","9394","1356204908"
"Dombarovskiy","Dombarovskiy","50.7550","59.5331","Russia","RU","RUS","Orenburgskaya Oblast’","","9556","1643794034"
"Fitampito","Fitampito","-20.9667","46.3167","Madagascar","MG","MDG","Fianarantsoa","","9546","1450283134"
"Jianshi","Jianshi","24.5761","121.3081","Taiwan","TW","TWN","Hsinchu","","9532","1158994660"
"Igrim","Igrim","63.1933","64.4194","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","9545","1643468393"
"Soldato-Aleksandrovskoye","Soldato-Aleksandrovskoye","44.2659","43.7562","Russia","RU","RUS","Stavropol’skiy Kray","","9516","1643261613"
"Falam","Falam","22.9136","93.6778","Myanmar","MM","MMR","Chin State","","9529","1104756468"
"Zhangping","Zhangping","37.6339","112.8775","China","CN","CHN","Shanxi","","9477","1156359646"
"Cusseta","Cusseta","32.3470","-84.7870","United States","US","USA","Georgia","","9533","1840029395"
"Kesli","Kesli","23.4183","78.8062","India","IN","IND","Madhya Pradesh","","9331","1356027856"
"Syurte","Syurte","48.5033","22.2319","Ukraine","UA","UKR","Zakarpatska Oblast","","9460","1804433014"
"Sokotindji","Sokotindji","10.8528","3.4297","Benin","BJ","BEN","Alibori","","9493","1204682883"
"Butler","Butler","41.0358","-75.9798","United States","US","USA","Pennsylvania","","9519","1840147559"
"Burlington","Burlington","41.7598","-72.9590","United States","US","USA","Connecticut","","9511","1840034217"
"Ukwā","Ukwa","21.9710","80.4662","India","IN","IND","Madhya Pradesh","","9385","1356422217"
"Benbutucun","Benbutucun","42.0263","86.6714","China","CN","CHN","Xinjiang","","9408","1156431293"
"Pedro Luro","Pedro Luro","-39.5000","-62.6833","Argentina","AR","ARG","Buenos Aires","","9494","1032549913"
"Nūlivedu","Nulivedu","14.1002","78.5548","India","IN","IND","Andhra Pradesh","","9331","1356110292"
"Esopus","Esopus","41.8425","-73.9936","United States","US","USA","New York","","9493","1840058084"
"Deh-e Shū","Deh-e Shu","30.4344","63.3183","Afghanistan","AF","AFG","Helmand","","9482","1004870289"
"Douar Oulad Bouziane","Douar Oulad Bouziane","34.2083","-5.0522","Morocco","MA","MAR","Fès-Meknès","","9372","1504500363"
"Berkine","Berkine","33.7665","-3.8438","Morocco","MA","MAR","Oriental","","9469","1504644082"
"Ban Sathan","Ban Sathan","18.2667","100.7167","Thailand","TH","THA","Nan","","9453","1764353853"
"Kanajanahalli","Kanajanahalli","14.1092","76.8381","India","IN","IND","Karnātaka","","9292","1356508823"
"Oppicherla","Oppicherla","16.4444","79.6967","India","IN","IND","Andhra Pradesh","","9266","1356781101"
"Alakamisy Anativato","Alakamisy Anativato","-19.8833","46.9000","Madagascar","MG","MDG","Antananarivo","","9407","1450915347"
"Bāyaram","Bayaram","18.0506","80.7375","India","IN","IND","Andhra Pradesh","","9354","1356232529"
"Portland","Portland","41.5988","-72.5890","United States","US","USA","Connecticut","","9459","1840004915"
"Jantho","Jantho","5.3000","95.6333","Indonesia","ID","IDN","Aceh","","9440","1360164924"
"Mariyādau","Mariyadau","24.2762","79.6559","India","IN","IND","Madhya Pradesh","","9303","1356281007"
"Karajgi","Karajgi","17.2827","76.2149","India","IN","IND","Karnātaka","","9251","1356534039"
"Topsham","Topsham","43.9614","-69.9587","United States","US","USA","Maine","","9431","1840053028"
"Ban Khi Lek","Ban Khi Lek","19.0625","98.9396","Thailand","TH","THA","Chiang Mai","","9334","1764319884"
"Timmāpuram","Timmapuram","15.4887","78.5744","India","IN","IND","Andhra Pradesh","","9315","1356064226"
"Katteragandla","Katteragandla","15.0091","78.9584","India","IN","IND","Andhra Pradesh","","9247","1356548910"
"Manchester","Manchester","42.9921","-77.1897","United States","US","USA","New York","","9392","1840004410"
"Shendē","Shende","10.6333","36.9333","Ethiopia","ET","ETH","Āmara","","9293","1231251281"
"Hastings","Hastings","43.3215","-76.1582","United States","US","USA","New York","","9387","1840058174"
"Anan’evo","Anan'evo","42.7300","77.6600","Kyrgyzstan","KG","KGZ","Ysyk-Köl","","9362","1417852557"
"Ghattupal","Ghattupal","17.0725","78.9239","India","IN","IND","Andhra Pradesh","","9213","1356584169"
"Anaconda","Anaconda","46.0608","-113.0678","United States","US","USA","Montana","","9380","1840074775"
"Mēga","Mega","4.0167","38.2500","Ethiopia","ET","ETH","Oromīya","","9370","1231972849"
"Ratangarh","Ratangarh","24.8167","75.1167","India","IN","IND","","","9245","1356035916"
"Moisei","Moisei","47.6561","24.5381","Romania","RO","ROU","Maramureş","","9264","1642386226"
"Newfane","Newfane","43.2818","-78.6932","United States","US","USA","New York","","9341","1840023982"
"Barei","Barei","9.6833","1.5667","Benin","BJ","BEN","Donga","","9259","1204494037"
"Āltūn Kawbrī","Altun Kawbri","35.7533","44.1436","Iraq","IQ","IRQ","Karkūk","","9275","1368208612"
"Ban Kham Pom","Ban Kham Pom","15.9653","105.2112","Thailand","TH","THA","Ubon Ratchathani","","9209","1764689114"
"Sutton","Sutton","42.1337","-71.7503","United States","US","USA","Massachusetts","","9334","1840053701"
"Xalqobod","Xalqobod","37.4597","66.9222","Uzbekistan","UZ","UZB","Surxondaryo","","9200","1860446608"
"Moore","Moore","40.7798","-75.4220","United States","US","USA","Pennsylvania","","9325","1840142184"
"Kondrukota","Kondrukota","17.1717","81.8317","India","IN","IND","Andhra Pradesh","","9228","1356149548"
"Boali","Boali","4.8000","18.1167","Central African Republic","CF","CAF","Ombella-Mpoko","","9314","1140101002"
"Bandio","Bandio","13.8888","1.0896","Niger","NE","NER","Tillabéri","","9237","1562066091"
"Douar Oulad Sidi Moussa","Douar Oulad Sidi Moussa","32.2726","-7.1987","Morocco","MA","MAR","Casablanca-Settat","","9260","1504471740"
"Helena Valley Southeast","Helena Valley Southeast","46.6219","-111.8973","United States","US","USA","Montana","","9316","1840074075"
"Douar El Mellaliyine","Douar El Mellaliyine","35.6264","-5.3414","Morocco","MA","MAR","Tanger-Tétouan-Al Hoceïma","","9177","1504244223"
"Padinska Skela","Padinska Skela","44.9500","20.4333","Serbia","RS","SRB","Beograd","","9263","1688172132"
"Chesterfield","Chesterfield","40.1166","-74.6459","United States","US","USA","New Jersey","","9302","1840081640"
"Analaroa","Analaroa","-18.4083","47.7083","Madagascar","MG","MDG","Antananarivo","","9282","1450569110"
"Rancho Mission Viejo","Rancho Mission Viejo","33.5140","-117.5618","United States","US","USA","California","","9298","1840147893"
"Southwick","Southwick","42.0544","-72.7785","United States","US","USA","Massachusetts","","9292","1840053452"
"Tasso","Tasso","9.6737","3.2333","Benin","BJ","BEN","Borgou","","9234","1204006461"
"Mikhaylovskoye","Mikhaylovskoye","43.0997","44.6317","Russia","RU","RUS","North Ossetia","","9217","1643841465"
"Xiangping","Xiangping","24.5892","112.1237","China","CN","CHN","Guangdong","","9169","1156364011"
"Prabhāt Pattan","Prabhat Pattan","21.6407","78.2722","India","IN","IND","Madhya Pradesh","","9109","1356201380"
"Walworth","Walworth","43.1633","-77.3131","United States","US","USA","New York","","9271","1840058571"
"Barrington","Barrington","43.2139","-71.0424","United States","US","USA","New Hampshire","","9270","1840018720"
"Talata-Angavo","Talata-Angavo","-18.2000","47.1000","Madagascar","MG","MDG","Antananarivo","","9240","1450226833"
"Veinticinco de Diciembre","Veinticinco de Diciembre","-24.7000","-56.5500","Paraguay","PY","PRY","San Pedro","","9147","1600631538"
"Tanamarina-Sakay","Tanamarina-Sakay","-21.4667","46.6167","Madagascar","MG","MDG","Fianarantsoa","","9235","1450821166"
"Monte Cristo","Monte Cristo","-31.3431","-63.9444","Argentina","AR","ARG","Córdoba","","9254","1032121160"
"Phagu","Phagu","26.8403","88.7682","India","IN","IND","West Bengal","","9115","1356774840"
"Càbras","Cabras","39.9333","8.5333","Italy","IT","ITA","Sardegna","","9165","1380750044"
"Putnam","Putnam","41.9093","-71.8711","United States","US","USA","Connecticut","","9250","1840035552"
"Qorovulbozor","Qorovulbozor","39.5000","64.8000","Uzbekistan","UZ","UZB","Buxoro","","9200","1860000615"
"Bīkē","Bike","9.5297","41.1969","Ethiopia","ET","ETH","Sumalē","","9197","1231520658"
"Macedon","Macedon","43.0792","-77.3095","United States","US","USA","New York","","9248","1840058275"
"Borja","Borja","-25.9528","-56.4972","Paraguay","PY","PRY","Guairá","","9222","1600620882"
"Romang","Romang","-29.5000","-59.7667","Argentina","AR","ARG","Santa Fe","","9237","1032388161"
"Ḩorr-e Rīāḩī","Horr-e Riahi","32.1447","48.3925","Iran","IR","IRN","Khūzestān","","9177","1364177562"
"Umreth","Umreth","22.1262","78.7149","India","IN","IND","Madhya Pradesh","","9088","1356063894"
"Intich’o","Intich'o","14.2667","39.1500","Ethiopia","ET","ETH","Tigray","","9048","1231591819"
"Leichi","Leichi","36.3351","110.2612","China","CN","CHN","Shaanxi","","9192","1156416351"
"La Cruz","La Cruz","11.0845","-85.6436","Costa Rica","CR","CRI","Guanacaste","","9195","1188840021"
"Jackson","Jackson","40.3774","-76.3142","United States","US","USA","Pennsylvania","","9218","1840143432"
"Ban Yaeng","Ban Yaeng","16.8833","100.8000","Thailand","TH","THA","Phitsanulok","","9170","1764144794"
"Ban Ngio Ngam","Ban Ngio Ngam","17.6671","100.1754","Thailand","TH","THA","Uttaradit","","9040","1764014160"
"Qarqaraly","Qarqaraly","49.4167","75.4167","Kazakhstan","KZ","KAZ","","","9212","1398407379"
"Thompson","Thompson","41.9798","-71.8735","United States","US","USA","Connecticut","","9213","1840035623"
"Podstepki","Podstepki","53.5151","49.1294","Russia","RU","RUS","Samarskaya Oblast’","","9186","1643224520"
"Crawford","Crawford","41.5685","-74.3169","United States","US","USA","New York","","9191","1840058024"
"Marale","Marale","14.9167","-87.0350","Honduras","HN","HND","Francisco Morazán","","9170","1340172200"
"As Sidrah","As Sidrah","30.6294","18.3503","Libya","LY","LBY","Surt","","9186","1434673703"
"Qahramon","Qahramon","40.3050","68.6017","Uzbekistan","UZ","UZB","Sirdaryo","","9000","1860810536"
"Settivāripalle","Settivaripalle","14.7543","78.7649","India","IN","IND","Andhra Pradesh","","8993","1356009049"
"Canela Baja","Canela Baja","-31.3989","-71.4561","Chile","CL","CHL","Coquimbo","","9182","1152763006"
"Chārakunda","Charakunda","16.6916","78.7054","India","IN","IND","Andhra Pradesh","","9039","1356583629"
"Jumlā","Jumla","29.2500","82.2167","Nepal","NP","NPL","","","9073","1524473131"
"Freetown","Freetown","41.7714","-71.0157","United States","US","USA","Massachusetts","","9165","1840070385"
"Kharsāwān","Kharsawan","22.7909","85.8310","India","IN","IND","Jhārkhand","","8965","1356797105"
"Morarano","Morarano","-19.4333","47.4833","Madagascar","MG","MDG","Antananarivo","","9000","1450779463"
"Aïn el Mediour","Ain el Mediour","30.4000","-8.8833","Morocco","MA","MAR","Souss-Massa","","9000","1504516108"
"Dulce Nombre de María","Dulce Nombre de Maria","14.1500","-89.0167","El Salvador","SV","SLV","Chalatenango","","8987","1222936599"
"Ban Wang Krachae","Ban Wang Krachae","14.2333","98.8667","Thailand","TH","THA","Kanchanaburi","","9134","1764682800"
"Usworth","Usworth","54.9400","-1.5500","United Kingdom","GB","GBR","Sunderland","","9100","1826648481"
"Ust’-Nera","Ust'-Nera","64.5666","143.2000","Russia","RU","RUS","Sakha (Yakutiya)","","9148","1643124244"
"Townsend","Townsend","42.6671","-71.7115","United States","US","USA","Massachusetts","","9144","1840053491"
"Dallas","Dallas","41.3608","-75.9656","United States","US","USA","Pennsylvania","","9140","1840000745"
"Dazhuangzi","Dazhuangzi","40.2321","99.0558","China","CN","CHN","Gansu","","9130","1156049332"
"Vinsady","Vinsady","44.0817","42.9606","Russia","RU","RUS","Stavropol’skiy Kray","","9079","1643350532"
"Argudan","Argudan","43.4200","43.9200","Russia","RU","RUS","Kabardino-Balkariya","","9010","1643014190"
"Gāzulapalle","Gazulapalle","15.4036","78.6176","India","IN","IND","Andhra Pradesh","","8957","1356859017"
"Morafeno","Morafeno","-14.4000","50.0333","Madagascar","MG","MDG","Antsiranana","","9000","1450000007"
"Ankarana-Miraihina","Ankarana-Miraihina","-23.0833","47.6333","Madagascar","MG","MDG","Fianarantsoa","","9000","1450677715"
"Venkatādripālem","Venkatadripalem","16.0506","79.2170","India","IN","IND","Andhra Pradesh","","9063","1356278957"
"Mendon","Mendon","42.9859","-77.5479","United States","US","USA","New York","","9126","1840058299"
"Sānchi","Sanchi","23.4865","77.7378","India","IN","IND","Madhya Pradesh","","8954","1356060833"
"Tuskegee","Tuskegee","32.4409","-85.7131","United States","US","USA","Alabama","","9125","1840004337"
"Hebron","Hebron","41.6593","-72.3905","United States","US","USA","Connecticut","","9122","1840035074"
"North Codorus","North Codorus","39.8646","-76.8189","United States","US","USA","Pennsylvania","","9119","1840147120"
"Ambodivoanio","Ambodivoanio","-16.1833","49.6667","Madagascar","MG","MDG","Toamasina","","9000","1450736682"
"Alakamisy-Ambohimahazo","Alakamisy-Ambohimahazo","-20.4000","47.3500","Madagascar","MG","MDG","Fianarantsoa","","9000","1450823290"
"Andilana Avaratra","Andilana Avaratra","-17.3500","48.5333","Madagascar","MG","MDG","Toamasina","","9042","1450556942"
"Nizhniy Odes","Nizhniy Odes","63.6500","54.8500","Russia","RU","RUS","Komi","","9111","1643059752"
"Ixtapa Zihuatanejo","Ixtapa Zihuatanejo","17.6367","-101.5514","Mexico","MX","MEX","Guerrero","","8992","1484980277"
"Bazimini","Bazimini","-12.1833","44.4500","Comoros","KM","COM","Anjouan","","8952","1174603336"
"Anteza","Anteza","-21.6667","47.8500","Madagascar","MG","MDG","Fianarantsoa","","9000","1450964830"
"Antanankambano","Antanankambano","-22.0500","47.4833","Madagascar","MG","MDG","Fianarantsoa","","9000","1450483821"
"Coatetelco","Coatetelco","18.7294","-99.3256","Mexico","MX","MEX","Morelos","","9094","1484016853"
"Mahamaibe","Mahamaibe","-21.7500","47.8833","Madagascar","MG","MDG","Fianarantsoa","","9000","1450492223"
"Vohilava","Vohilava","-21.7667","47.9167","Madagascar","MG","MDG","Fianarantsoa","","9000","1450720571"
"Peddannavāripalle","Peddannavaripalle","14.2535","78.3384","India","IN","IND","Andhra Pradesh","","8993","1356243327"
"Salobe","Salobe","-23.5333","44.7167","Madagascar","MG","MDG","Toliara","","9000","1450431518"
"Bemarivo","Bemarivo","-17.6333","44.7500","Madagascar","MG","MDG","Mahajanga","","9091","1450296334"
"Ampary","Ampary","-19.1833","46.5833","Madagascar","MG","MDG","Antananarivo","","9000","1450964299"
"Yangiobod","Yangiobod","41.1192","70.0942","Uzbekistan","UZ","UZB","Toshkent","","8900","1860896994"
"Springfield","Springfield","43.2907","-72.4809","United States","US","USA","Vermont","","9089","1840071330"
"Diabugu","Diabugu","13.3833","-14.4000","The Gambia","GM","GMB","Upper River","","9000","1270353608"
"Antsahavaribe","Antsahavaribe","-13.8500","49.8667","Madagascar","MG","MDG","Antsiranana","","9000","1450134835"
"Anjialava","Anjialava","-14.0667","49.7333","Madagascar","MG","MDG","Antsiranana","","9000","1450285791"
"Mitanty","Mitanty","-21.7333","48.0000","Madagascar","MG","MDG","Fianarantsoa","","9000","1450937842"
"Ranopiso","Ranopiso","-25.0500","46.6667","Madagascar","MG","MDG","Toliara","","9000","1450426168"
"Sarasambo","Sarasambo","-25.1000","46.7833","Madagascar","MG","MDG","Toliara","","9000","1450151247"
"Woodbridge","Woodbridge","41.3566","-73.0102","United States","US","USA","Connecticut","","9073","1840035674"
"Weare","Weare","43.0813","-71.7219","United States","US","USA","New Hampshire","","9072","1840055744"
"New Scotland","New Scotland","42.6047","-73.9413","United States","US","USA","New York","","9064","1840058347"
"Ban Nikhom Phatthana","Ban Nikhom Phatthana","16.7242","99.9697","Thailand","TH","THA","Phitsanulok","","8936","1764788584"
"Tsaratanana","Tsaratanana","-15.6500","47.8500","Madagascar","MG","MDG","Mahajanga","","9000","1450367041"
"Economy","Economy","40.6411","-80.1841","United States","US","USA","Pennsylvania","","9057","1840066074"
"Fierenana","Fierenana","-18.4833","48.4000","Madagascar","MG","MDG","Toamasina","","9000","1450717278"
"Kegen","Kegen","43.0197","79.2203","Kazakhstan","KZ","KAZ","","","9049","1398638482"
"Soamahamanina","Soamahamanina","-18.9833","47.0167","Madagascar","MG","MDG","Antananarivo","","9000","1450133053"
"Sulahpet","Sulahpet","17.4014","77.3472","India","IN","IND","Karnātaka","","8853","1356384643"
"Belinta","Belinta","-19.9500","45.1167","Madagascar","MG","MDG","Toliara","","9000","1450423565"
"Antsatramidola","Antsatramidola","-15.6333","48.9667","Madagascar","MG","MDG","Mahajanga","","9000","1450037709"
"Barskoon","Barskoon","42.1561","77.6039","Kyrgyzstan","KG","KGZ","Ysyk-Köl","","9040","1417800599"
"Ambalaromba","Ambalaromba","-14.6667","49.3333","Madagascar","MG","MDG","Mahajanga","","9000","1450599177"
"Mandīshah","Mandishah","28.3515","28.9317","Egypt","EG","EGY","Al Jīzah","","9016","1818119221"
"Ambariokorano","Ambariokorano","-15.8833","48.9667","Madagascar","MG","MDG","Mahajanga","","9000","1450736655"
"Ivandrika","Ivandrika","-22.8667","47.7333","Madagascar","MG","MDG","Fianarantsoa","","9000","1450375529"
"Iara","Iara","-23.0833","47.4333","Madagascar","MG","MDG","Fianarantsoa","","9000","1450682367"
"Ambodimahabibo","Ambodimahabibo","-15.7333","48.0833","Madagascar","MG","MDG","Mahajanga","","9000","1450192995"
"Vatananto","Vatananto","-23.6667","47.2500","Madagascar","MG","MDG","Fianarantsoa","","9000","1450501566"
"Ambinanin’ Andravory","Ambinanin' Andravory","-13.7667","49.7500","Madagascar","MG","MDG","Antsiranana","","9000","1450604445"
"Ambatoharanana","Ambatoharanana","-16.4333","49.5833","Madagascar","MG","MDG","Toamasina","","9000","1450166915"
"Soanierana","Soanierana","-25.0000","46.8833","Madagascar","MG","MDG","Toliara","","9000","1450823872"
"Benato-Toby","Benato-Toby","-23.0833","45.8667","Madagascar","MG","MDG","Toliara","","9000","1450266627"
"Bemaharivo","Bemaharivo","-16.1333","46.4000","Madagascar","MG","MDG","Mahajanga","","9000","1450110716"
"Ampasimpotsy-Gara","Ampasimpotsy-Gara","-18.9667","48.3500","Madagascar","MG","MDG","Toamasina","","9023","1450245275"
"Marovatolena","Marovatolena","-15.1833","47.6500","Madagascar","MG","MDG","Mahajanga","","9000","1450130189"
"Ambatoria","Ambatoria","-14.5000","48.9500","Madagascar","MG","MDG","Mahajanga","","9000","1450620872"
"West Manheim","West Manheim","39.7458","-76.9431","United States","US","USA","Pennsylvania","","9030","1840146058"
"Middlebury","Middlebury","44.0043","-73.1218","United States","US","USA","Vermont","","9029","1840070864"
"Maropaika","Maropaika","-22.7000","46.9833","Madagascar","MG","MDG","Fianarantsoa","","9000","1450971910"
"Sahatsiho-Ambohimanjaka","Sahatsiho-Ambohimanjaka","-20.2000","47.0333","Madagascar","MG","MDG","Fianarantsoa","","9000","1450389505"
"Ambarimaninga","Ambarimaninga","-16.5000","46.0000","Madagascar","MG","MDG","Mahajanga","","9000","1450371857"
"Akpassi","Akpassi","8.4500","1.8833","Benin","BJ","BEN","Collines","","8979","1204094346"
"Marotolana","Marotolana","-14.6500","48.9833","Madagascar","MG","MDG","Mahajanga","","9000","1450171691"
"Antaly","Antaly","-24.3833","44.4000","Madagascar","MG","MDG","Toliara","","9000","1450745641"
"Nanmucun","Nanmucun","25.1619","114.5559","China","CN","CHN","Guangdong","","8942","1156554178"
"Ban Noen Kum Nueng","Ban Noen Kum Nueng","16.5500","100.4167","Thailand","TH","THA","Phitsanulok","","8838","1764306020"
"Andribavontsona","Andribavontsona","-15.4000","47.5167","Madagascar","MG","MDG","Mahajanga","","9000","1450250205"
"Manampaneva","Manampaneva","-16.0500","48.6833","Madagascar","MG","MDG","Mahajanga","","9000","1450103713"
"Antambohobe","Antambohobe","-22.3000","46.7667","Madagascar","MG","MDG","Fianarantsoa","","9000","1450913351"
"Nosibe","Nosibe","-13.1500","49.8000","Madagascar","MG","MDG","Antsiranana","","9000","1450983657"
"Ankiliabo","Ankiliabo","-24.6833","44.7167","Madagascar","MG","MDG","Toliara","","9000","1450784803"
"Amboronabo","Amboronabo","-22.6667","44.4000","Madagascar","MG","MDG","Toliara","","9000","1450915976"
"Bekopaka","Bekopaka","-19.0167","44.8500","Madagascar","MG","MDG","Mahajanga","","9000","1450806419"
"Stillwater","Stillwater","42.9701","-73.6885","United States","US","USA","New York","","9017","1840004252"
"Mahabo","Mahabo","-24.2833","45.2153","Madagascar","MG","MDG","Toliara","","9000","1450198675"
"Beparasy","Beparasy","-19.1667","48.0333","Madagascar","MG","MDG","Toamasina","","9000","1450291288"
"Manevy","Manevy","-24.3833","46.8000","Madagascar","MG","MDG","Toliara","","9000","1450343153"
"Soamanonga","Soamanonga","-23.8667","44.7833","Madagascar","MG","MDG","Fianarantsoa","","9000","1450370442"
"Tsimafana","Tsimafana","-19.7167","44.5833","Madagascar","MG","MDG","Toliara","","9000","1450437916"
"Viale","Viale","-31.8667","-60.0167","Argentina","AR","ARG","Entre Ríos","","8939","1032929060"
"Soanenga","Soanenga","-16.5000","44.6000","Madagascar","MG","MDG","Mahajanga","","9000","1450386206"
"Chanco","Chanco","-36.2667","-72.7167","Chile","CL","CHL","Ñuble","","9003","1152038330"
"Ankirihitra","Ankirihitra","-16.7667","46.4500","Madagascar","MG","MDG","Mahajanga","","9000","1450483503"
"Antsaidoha-Bebao","Antsaidoha-Bebao","-17.3667","44.5500","Madagascar","MG","MDG","Mahajanga","","9000","1450780797"
"Tandrano","Tandrano","-22.0833","44.6333","Madagascar","MG","MDG","Toliara","","9000","1450417140"
"Ambalajia","Ambalajia","-17.4833","46.1167","Madagascar","MG","MDG","Mahajanga","","9000","1450290586"
"Antseza","Antseza","-16.2167","45.8667","Madagascar","MG","MDG","Mahajanga","","9000","1450090147"
"Befotaka","Befotaka","-20.8333","45.6667","Madagascar","MG","MDG","Toliara","","9000","1450620315"
"Manja","Manja","-21.4333","44.3333","Madagascar","MG","MDG","Toliara","","9000","1450949899"
"Jonnagiri","Jonnagiri","15.2303","77.5714","India","IN","IND","Andhra Pradesh","","8821","1356935484"
"Mānsong","Mansong","27.1662","88.5744","India","IN","IND","West Bengal","","8812","1356037058"
"Lieşti","Liesti","45.6193","27.5416","Romania","RO","ROU","Galaţi","","8902","1642207668"
"Rutland","Rutland","42.3848","-71.9673","United States","US","USA","Massachusetts","","8988","1840053603"
"Ladan Kara","Ladan Kara","40.9264","72.9569","Kyrgyzstan","KG","KGZ","Jalal-Abad","","8886","1417039175"
"Douglas","Douglas","42.0524","-71.7515","United States","US","USA","Massachusetts","","8975","1840053689"
"East Haddam","East Haddam","41.4798","-72.3944","United States","US","USA","Connecticut","","8973","1840073554"
"Miyār","Miyar","13.1945","75.0484","India","IN","IND","Karnātaka","","8789","1356849105"
"Ban Muang Kham","Ban Muang Kham","19.5008","99.7466","Thailand","TH","THA","Chiang Rai","","8837","1764185323"
"Bansang","Bansang","13.4333","-14.6500","The Gambia","GM","GMB","Central River","","8843","1270543142"
"Galela","Galela","1.8236","127.8431","Indonesia","ID","IDN","Maluku Utara","","8760","1360398495"
"Marcy","Marcy","43.1731","-75.2662","United States","US","USA","New York","","8949","1840058287"
"East Nottingham","East Nottingham","39.7621","-75.9705","United States","US","USA","Pennsylvania","","8942","1840150922"
"Kishtwār","Kishtwar","33.3135","75.7673","India","IN","IND","Jammu and Kashmīr","","8896","1356611269"
"Angor","Angor","37.4639","67.1500","Uzbekistan","UZ","UZB","Surxondaryo","","8801","1860931614"
"Upper Leacock","Upper Leacock","40.0801","-76.1854","United States","US","USA","Pennsylvania","","8930","1840142492"
"Daping","Daping","24.6501","112.1693","China","CN","CHN","Guangdong","","8753","1156348696"
"Australia","Australia","22.4995","-81.1359","Cuba","CU","CUB","Matanzas","","8850","1192581424"
"West Caln","West Caln","40.0237","-75.8866","United States","US","USA","Pennsylvania","","8928","1840145648"
"Seneca Falls","Seneca Falls","42.9136","-76.7904","United States","US","USA","New York","","8925","1840058484"
"Douar Ait Taleb","Douar Ait Taleb","32.3839","-8.1856","Morocco","MA","MAR","Marrakech-Safi","","8888","1504079042"
"Moulay Abdelkader","Moulay Abdelkader","34.6422","-5.7678","Morocco","MA","MAR","Rabat-Salé-Kénitra","","8871","1504784821"
"Toulou","Toulou","14.1688","5.1990","Niger","NE","NER","Tahoua","","8785","1562294550"
"El Valle","El Valle","18.9764","-69.3792","Dominican Republic","DO","DOM","Higuamo","","8855","1214732998"
"Iklod","Iklod","26.0222","77.3208","India","IN","IND","Rājasthān","","8803","1356011400"
"Francisco Caballero Álvarez","Francisco Caballero Alvarez","-24.1543","-54.6659","Paraguay","PY","PRY","Canindeyú","","8884","1600193449"
"Takouta","Takouta","10.3000","1.2000","Benin","BJ","BEN","Atacora","","8811","1204727932"
"Pendekallu","Pendekallu","15.3753","77.6242","India","IN","IND","Andhra Pradesh","","8727","1356373899"
"Jabera","Jabera","23.5582","79.6780","India","IN","IND","Madhya Pradesh","","8746","1356873086"
"Ban Si Don Chai","Ban Si Don Chai","20.1300","100.3614","Thailand","TH","THA","Chiang Rai","","8813","1764853542"
"Fort Knox","Fort Knox","37.8915","-85.9636","United States","US","USA","Kentucky","","8888","1840073833"
"Chīchkah","Chichkah","37.0706","69.2983","Afghanistan","AF","AFG","Kunduz","","8792","1004736488"
"Qızılhacılı","Qizilhacili","40.5808","46.8503","Azerbaijan","AZ","AZE","Goranboy","","8830","1031235526"
"Ban Pong Tao","Ban Pong Tao","18.8333","99.9500","Thailand","TH","THA","Lampang","","8846","1764488080"
"Mousoulou","Mousoulou","7.3899","1.9367","Benin","BJ","BEN","Zou","","8806","1204568655"
"Pardanjān","Pardanjan","32.2539","50.5975","Iran","IR","IRN","Chahār Maḩāl va Bakhtīārī","","8699","1364000864"
"Al Abraq","Al Abraq","32.7867","21.9978","Libya","LY","LBY","Al Jabal al Akhḑar","","8861","1434086011"
"Mālaimārpuram","Malaimarpuram","14.2096","79.3072","India","IN","IND","Andhra Pradesh","","8737","1356032853"
"Rocas de Santo Domingo","Rocas de Santo Domingo","-33.6358","-71.6281","Chile","CL","CHL","Valparaíso","","8860","1152451543"
"Amberomanga","Amberomanga","-19.2500","46.6167","Madagascar","MG","MDG","Antananarivo","","8772","1450006485"
"Mahela","Mahela","-19.4833","48.4833","Madagascar","MG","MDG","Toamasina","","8815","1450731467"
"Indūrti","Indurti","17.0055","78.9054","India","IN","IND","Andhra Pradesh","","8693","1356275022"
"Ban Sai Yoi","Ban Sai Yoi","16.4167","100.6000","Thailand","TH","THA","Phitsanulok","","8784","1764133988"
"Mirdoddi","Mirdoddi","18.0786","78.6770","India","IN","IND","Andhra Pradesh","","8692","1356309439"
"Lenox","Lenox","43.1113","-75.7582","United States","US","USA","New York","","8848","1840058237"
"Mansfield","Mansfield","40.0853","-74.7149","United States","US","USA","New Jersey","","8841","1840081639"
"Redding","Redding","41.3050","-73.3915","United States","US","USA","Connecticut","","8831","1840035553"
"Río Jiménez","Rio Jimenez","10.2556","-83.5629","Costa Rica","CR","CRI","Limón","","8742","1188994272"
"Pittsgrove","Pittsgrove","39.5404","-75.1291","United States","US","USA","New Jersey","","8817","1840131599"
"Falla","Falla","22.1704","-78.7872","Cuba","CU","CUB","Ciego de Ávila","","8789","1192599195"
"Buenavista","Buenavista","9.3333","-74.3000","Colombia","CO","COL","Magdalena","","8749","1170070362"
"Aşağı Quşçu","Asagi Quscu","40.9533","45.6792","Azerbaijan","AZ","AZE","Tovuz","","8702","1031427640"
"Pacuarito","Pacuarito","10.1093","-83.4091","Costa Rica","CR","CRI","Limón","","8756","1188497482"
"Salar","Salar","33.8789","75.2485","India","IN","IND","Jammu and Kashmīr","","8649","1356741008"
"Rudewa","Rudewa","-10.1008","34.6836","Tanzania","TZ","TZA","Njombe","","8747","1834986304"
"Upper Makefield","Upper Makefield","40.2941","-74.9250","United States","US","USA","Pennsylvania","","8785","1840150060"
"Nayāgaon","Nayagaon","24.8015","80.8962","India","IN","IND","Madhya Pradesh","","8636","1356096436"
"Hire Vadvatti","Hire Vadvatti","15.2235","75.7831","India","IN","IND","Karnātaka","","8618","1356087442"
"Marrupa","Marrupa","-13.1833","37.5000","Mozambique","MZ","MOZ","Niassa","","8762","1508890063"
"Hamlin","Hamlin","43.3213","-77.9135","United States","US","USA","New York","","8770","1840058157"
"Brighton","Brighton","40.7023","-80.3677","United States","US","USA","Pennsylvania","","8768","1840145004"
"Andalusia","Andalusia","31.3102","-86.4781","United States","US","USA","Alabama","","8764","1840004591"
"Segaon","Segaon","21.8585","75.3293","India","IN","IND","Madhya Pradesh","","8620","1356806925"
"Gidan Idèr","Gidan Ider","14.0131","5.3185","Niger","NE","NER","Tahoua","","8582","1562080119"
"Tilarán","Tilaran","10.4709","-84.9674","Costa Rica","CR","CRI","Guanacaste","","8677","1188878981"
"Sobhāpur","Sobhapur","22.7737","78.2638","India","IN","IND","Madhya Pradesh","","8639","1356036483"
"Sèmèrè","Semere","9.6268","1.4515","Benin","BJ","BEN","Donga","","8566","1204987964"
"Verkhniye Achaluki","Verkhniye Achaluki","43.3469","44.6975","Russia","RU","RUS","Ingushetiya","","8590","1643227547"
"Buved","Buved","37.5833","71.5167","Tajikistan","TJ","TJK","Kŭhistoni Badakhshon","","8723","1762903124"
"Ellicott","Ellicott","42.1330","-79.2360","United States","US","USA","New York","","8723","1840058075"
"Karavan","Karavan","40.2944","72.1861","Kyrgyzstan","KG","KGZ","Batken","","8657","1417081845"
"Marlborough","Marlborough","41.6337","-73.9904","United States","US","USA","New York","","8717","1840087655"
"Solebury","Solebury","40.3676","-75.0032","United States","US","USA","Pennsylvania","","8716","1840101286"
"Al Quway‘īyah","Al Quway\`iyah","24.0464","45.2656","Saudi Arabia","SA","SAU","Ar Riyāḑ","","8712","1682154232"
"Clanton","Clanton","32.8444","-86.6233","United States","US","USA","Alabama","","8715","1840006157"
"Kaldsletta","Kaldsletta","69.6956","18.8836","Norway","NO","NOR","Troms og Finnmark","","8681","1578543345"
"Poninguinim","Poninguinim","14.9679","74.0865","India","IN","IND","Goa","","8531","1356697603"
"Ankatafa","Ankatafa","-13.6167","48.3667","Madagascar","MG","MDG","Antsiranana","","8652","1450000267"
"Newstead","Newstead","43.0196","-78.5223","United States","US","USA","New York","","8681","1840087722"
"London Grove","London Grove","39.8327","-75.8155","United States","US","USA","Pennsylvania","","8673","1840151981"
"Peralta","Peralta","18.5167","-70.7833","Dominican Republic","DO","DOM","Valdesia","","8596","1214056600"
"Ban Charoen Mueang","Ban Charoen Mueang","19.6075","99.7547","Thailand","TH","THA","Chiang Rai","","8534","1764029573"
"Hamilton","Hamilton","40.9334","-75.2844","United States","US","USA","Pennsylvania","","8661","1840145220"
"Marginea","Marginea","47.8167","25.8333","Romania","RO","ROU","Suceava","","8552","1642632251"
"Tanmpègré","Tanmpegre","10.4800","1.4500","Benin","BJ","BEN","Atacora","","8560","1204794902"
"Chāpalamadugu","Chapalamadugu","16.0730","79.3775","India","IN","IND","Andhra Pradesh","","8560","1356147402"
"Munnelli","Munnelli","14.9200","78.9546","India","IN","IND","Andhra Pradesh","","8503","1356602386"
"Panfilovka","Panfilovka","42.7917","73.6778","Kyrgyzstan","KG","KGZ","Chüy","","8636","1417300619"
"Vostochnyy","Vostochnyy","39.9222","69.6500","Kyrgyzstan","KG","KGZ","Batken","","8611","1417634598"
"Nerubaiske","Nerubaiske","46.5467","30.6306","Ukraine","UA","UKR","Odeska Oblast","","8558","1804433440"
"Chartiers","Chartiers","40.2505","-80.2495","United States","US","USA","Pennsylvania","","8625","1840151797"
"Freeport","Freeport","43.8556","-70.1009","United States","US","USA","Maine","","8622","1840052826"
"Anjahamarina","Anjahamarina","-18.0167","49.2000","Madagascar","MG","MDG","Toamasina","","8564","1450675952"
"Carneys Point","Carneys Point","39.6967","-75.4475","United States","US","USA","New Jersey","","8613","1840081665"
"Jackson","Jackson","39.9057","-76.8796","United States","US","USA","Pennsylvania","","8601","1840151964"
"East Donegal","East Donegal","40.0823","-76.5631","United States","US","USA","Pennsylvania","","8597","1840150410"
"Windsor","Windsor","43.2405","-89.2948","United States","US","USA","Wisconsin","","8589","1840006867"
"Chermen","Chermen","43.1486","44.7069","Russia","RU","RUS","North Ossetia","","8508","1643226278"
"Vícam Pueblo","Vicam Pueblo","27.6422","-110.2897","Mexico","MX","MEX","Sonora","","8578","1484237374"
"South Londonderry","South Londonderry","40.2424","-76.5432","United States","US","USA","Pennsylvania","","8578","1840149648"
"Zhujiagua","Zhujiagua","38.2242","110.4032","China","CN","CHN","Shaanxi","","8507","1156520466"
"Tionk Essil","Tionk Essil","12.7856","-16.5217","Senegal","SN","SEN","Ziguinchor","","8507","1686842039"
"Corman Park No. 344","Corman Park No. 344","52.2291","-106.8002","Canada","CA","CAN","Saskatchewan","","8568.00","1124000077"
"Montague","Montague","42.5549","-72.5177","United States","US","USA","Massachusetts","","8565","1840053435"
"Barton","Barton","42.0812","-76.4982","United States","US","USA","New York","","8563","1840057223"
"Guntersville","Guntersville","34.3671","-86.2637","United States","US","USA","Alabama","","8559","1840006456"
"Hongtuliang","Hongtuliang","40.9986","114.1550","China","CN","CHN","Hebei","","8527","1156575450"
"Skowhegan","Skowhegan","44.7554","-69.6657","United States","US","USA","Maine","","8553","1840051814"
"Helena Valley West Central","Helena Valley West Central","46.6634","-112.0604","United States","US","USA","Montana","","8550","1840074081"
"Aqsū","Aqsu","52.4502","71.9597","Kazakhstan","KZ","KAZ","","","8543","1398268556"
"Olamzé","Olamze","2.2167","11.0833","Cameroon","CM","CMR","Sud","","8518","1120708879"
"Olovyannaya","Olovyannaya","50.9500","115.5666","Russia","RU","RUS","Zabaykal’skiy Kray","","8247","1643754076"
"Putina","Putina","-15.4700","-69.4300","Peru","PE","PER","Puno","","8118","1604856559"
"Oldeani","Oldeani","-3.3500","35.5500","Tanzania","TZ","TZA","Arusha","","8000","1834474820"
"Osakarovka","Osakarovka","50.5619","72.5681","Kazakhstan","KZ","KAZ","","","8046","1398243007"
"Kontcha","Kontcha","7.9667","12.2333","Cameroon","CM","CMR","Adamaoua","","8018","1120016766"
"Ciudad Cortés","Ciudad Cortes","9.0221","-83.5751","Costa Rica","CR","CRI","Puntarenas","","7969","1188296792"
"Ch’osan-ŭp","Ch'osan-up","40.8255","125.8008","North Korea","KP","PRK","Chagang","","7786","1408838898"
"Iqaluit","Iqaluit","63.7598","-68.5107","Canada","CA","CAN","Nunavut","admin","7740.00","1124379539"
"Kalabo","Kalabo","-14.9911","22.6789","Zambia","ZM","ZMB","Western","","7731","1894966417"
"Qazaly","Qazaly","45.7667","62.1000","Kazakhstan","KZ","KAZ","","","7686","1398983835"
"Bayghanīn","Bayghanin","48.6917","55.8740","Kazakhstan","KZ","KAZ","","","7657","1398205914"
"Neiafu","Neiafu","-18.6508","-173.9831","Tonga","TO","TON","Vava‘u","","7391","1776829745"
"Luân Châu","Luan Chau","21.7400","103.3430","Vietnam","VN","VNM","Điện Biên","","7335","1704201464"
"Bossembele","Bossembele","5.2667","17.6500","Central African Republic","CF","CAF","Ombella-Mpoko","","7287","1140955210"
"Bestöbe","Bestobe","52.4997","73.0997","Kazakhstan","KZ","KAZ","","","7189","1398545422"
"Tobyl","Tobyl","52.6980","62.5749","Kazakhstan","KZ","KAZ","","","7155","1398858827"
"Okondja","Okondja","-0.6519","13.6778","Gabon","GA","GAB","Haut-Ogooué","","7155","1266588926"
"Melekeok","Melekeok","7.5006","134.6241","Palau","PW","PLW","Melekeok","","7026","1585892017"
"Zambezi","Zambezi","-13.5500","23.1167","Zambia","ZM","ZMB","North-Western","","7074","1894660797"
"Nicoadala","Nicoadala","-17.6077","36.8197","Mozambique","MZ","MOZ","Zambézia","","6945","1508484170"
"Karibib","Karibib","-21.9381","15.8544","Namibia","NA","NAM","Erongo","","6898","1516472073"
"Zholymbet","Zholymbet","51.7502","71.7099","Kazakhstan","KZ","KAZ","","","6876","1398035914"
"Khandyga","Khandyga","62.6660","135.6000","Russia","RU","RUS","Sakha (Yakutiya)","","6796","1643340100"
"Ndendé","Ndende","-2.4014","11.3589","Gabon","GA","GAB","Ngounié","","6436","1266578957"
"San Matías","San Matias","-16.3611","-58.4053","Bolivia","BO","BOL","Santa Cruz","","6352","1068402765"
"Umba","Umba","66.6814","34.3455","Russia","RU","RUS","Murmanskaya Oblast’","","6128","1643774657"
"San Javier","San Javier","-16.2748","-62.5072","Bolivia","BO","BOL","Santa Cruz","","6048","1068099078"
"Tazovskiy","Tazovskiy","67.4833","78.7167","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","5981","1643167905"
"Mundybash","Mundybash","53.2333","87.3167","Russia","RU","RUS","Kemerovskaya Oblast’","","5870","1643009473"
"Aiyomojok","Aiyomojok","5.7504","8.9833","Cameroon","CM","CMR","South-West","","5798","1120658014"
"Piggs Peak","Piggs Peak","-25.9610","31.2470","Swaziland","SZ","SWZ","Hhohho","","5750","1748284923"
"Fdérik","Fderik","22.6783","-12.7114","Mauritania","MR","MRT","Tiris Zemmour","","5760","1478557883"
"Tiksi","Tiksi","71.6269","128.8350","Russia","RU","RUS","Sakha (Yakutiya)","","5700","1643870212"
"Vossevangen","Vossevangen","60.6300","6.4410","Norway","NO","NOR","Vestland","","5571","1578107030"
"Okhotsk","Okhotsk","59.3830","143.2170","Russia","RU","RUS","Khabarovskiy Kray","","5570","1643017106"
"Fort-Shevchenko","Fort-Shevchenko","44.5167","50.2667","Kazakhstan","KZ","KAZ","","","5559","1398201971"
"Witu","Witu","-2.3889","40.4378","Kenya","KE","KEN","Lamu","","5380","1404037095"
"Tura","Tura","64.2833","100.2167","Russia","RU","RUS","Krasnoyarskiy Kray","","5444","1643809018"
"San Quintín","San Quintin","30.4837","-115.9500","Mexico","MX","MEX","Baja California","","5433","1484693426"
"Ouadda","Ouadda","8.0667","22.4000","Central African Republic","CF","CAF","Haute-Kotto","","5434","1140640564"
"Hohenau","Hohenau","-27.0796","-55.7500","Paraguay","PY","PRY","Itapúa","","5306","1600972500"
"Torghay","Torghay","49.6260","63.4990","Kazakhstan","KZ","KAZ","","","5277","1398845535"
"Chernyshevskiy","Chernyshevskiy","63.0128","112.4714","Russia","RU","RUS","Sakha (Yakutiya)","","5137","1643326299"
"Villa del Rosario","Villa del Rosario","-24.4167","-57.1167","Paraguay","PY","PRY","San Pedro","","5123","1600049206"
"McMinns Lagoon","McMinns Lagoon","-12.5329","131.0500","Australia","AU","AUS","Northern Territory","","5025","1036673821"
"Qaşr al Farāfirah","Qasr al Farafirah","27.0583","27.9700","Egypt","EG","EGY","Al Wādī al Jadīd","","5000","1818000020"
"Puerto Casado","Puerto Casado","-22.2896","-57.9400","Paraguay","PY","PRY","Alto Paraguay","","5000","1600731282"
"Ust’-Kamchatsk","Ust'-Kamchatsk","56.2167","162.5500","Russia","RU","RUS","Kamchatskiy Kray","","4939","1643715088"
"Mékambo","Mekambo","1.0167","13.9333","Gabon","GA","GAB","Ogooué-Ivindo","","4891","1266553682"
"Betanzos","Betanzos","-19.5533","-65.4536","Bolivia","BO","BOL","Potosí","","4847","1068258629"
"Brownsweg","Brownsweg","5.0164","-55.1669","Suriname","SR","SUR","Brokopondo","","4793","1740310469"
"Bongandanga","Bongandanga","1.5100","21.0500","Congo (Kinshasa)","CD","COD","Mongala","","4625","1180746434"
"Sangar","Sangar","63.9241","127.4739","Russia","RU","RUS","Sakha (Yakutiya)","","4633","1643096707"
"Khatanga","Khatanga","71.9797","102.4728","Russia","RU","RUS","Krasnoyarskiy Kray","","4622","1643018667"
"Al Qaşr","Al Qasr","25.6959","28.8837","Egypt","EG","EGY","Al Wādī al Jadīd","","4474","1818618401"
"Saryshaghan","Saryshaghan","46.1167","73.6167","Kazakhstan","KZ","KAZ","","","4365","1398222668"
"Bekily","Bekily","-24.2162","45.3166","Madagascar","MG","MDG","Toliara","","4286","1450655091"
"Batagay","Batagay","67.6560","134.6350","Russia","RU","RUS","Sakha (Yakutiya)","","4266","1643604003"
"Omsukchan","Omsukchan","62.5333","155.8000","Russia","RU","RUS","Magadanskaya Oblast’","","4201","1643004933"
"Novyy Uoyan","Novyy Uoyan","56.1350","111.7339","Russia","RU","RUS","Buryatiya","","4184","1643043728"
"Quime","Quime","-16.9817","-67.2167","Bolivia","BO","BOL","La Paz","","4045","1068690050"
"Araouane","Araouane","18.9050","-3.5283","Mali","ML","MLI","Tombouctou","","4026","1466354924"
"P’ungsan","P'ungsan","40.8175","128.1553","North Korea","KP","PRK","Ryanggang","","3839","1408098792"
"Vitim","Vitim","59.4515","112.5578","Russia","RU","RUS","Sakha (Yakutiya)","","3843","1643662368"
"Palana","Palana","59.0840","159.9500","Russia","RU","RUS","Kamchatskiy Kray","","3671","1643584640"
"Cherskiy","Cherskiy","68.7501","161.3300","Russia","RU","RUS","Sakha (Yakutiya)","","3707","1643174283"
"Ceduna","Ceduna","-32.1167","133.6667","Australia","AU","AUS","South Australia","","3505","1036725988"
"Zyryanka","Zyryanka","65.7360","150.8900","Russia","RU","RUS","Sakha (Yakutiya)","","3627","1643201511"
"De-Kastri","De-Kastri","51.4666","140.7833","Russia","RU","RUS","Khabarovskiy Kray","","3615","1643988786"
"Villa Ygatimí","Villa Ygatimi","-24.0800","-55.5000","Paraguay","PY","PRY","Canindeyú","","3577","1600339943"
"Ligonha","Ligonha","-15.1757","37.7400","Mozambique","MZ","MOZ","Nampula","","3500","1508105140"
"Uspallata","Uspallata","-32.5667","-69.3167","Argentina","AR","ARG","Mendoza","","3439","1032547934"
"Darregueira","Darregueira","-37.6996","-63.1666","Argentina","AR","ARG","Buenos Aires","","3412","1032968004"
"Bukachacha","Bukachacha","52.9833","116.9166","Russia","RU","RUS","Zabaykal’skiy Kray","","3372","1643741623"
"Ugol’nyye Kopi","Ugol'nyye Kopi","64.7333","177.7000","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","3367","1643017360"
"Lukulu","Lukulu","-14.4083","23.2667","Zambia","ZM","ZMB","Western","","3349","1894281443"
"Krasnogorsk","Krasnogorsk","48.4172","142.0869","Russia","RU","RUS","Sakhalinskaya Oblast’","","3304","1643415310"
"Arroyos y Esteros","Arroyos y Esteros","-25.0500","-57.0900","Paraguay","PY","PRY","Cordillera","","3055","1600827428"
"Ust’-Maya","Ust'-Maya","60.4566","134.5433","Russia","RU","RUS","Sakha (Yakutiya)","","3062","1643532310"
"Abaí","Abai","-26.0296","-55.9400","Paraguay","PY","PRY","Caazapá","","3024","1600189926"
"Taoudenni","Taoudenni","22.6667","-3.9833","Mali","ML","MLI","Tombouctou","","3019","1466976172"
"San Lorenzo","San Lorenzo","-21.4167","-64.7494","Bolivia","BO","BOL","Tarija","","3000","1068000024"
"Saranpaul","Saranpaul","64.2600","60.9083","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","2985","1643184738"
"Villalonga","Villalonga","-39.8829","-62.5833","Argentina","AR","ARG","Buenos Aires","","2838","1032282782"
"Entre Ríos","Entre Rios","-21.5264","-64.1733","Bolivia","BO","BOL","Tarija","","2800","1068494403"
"Al Jaghbūb","Al Jaghbub","29.7425","24.5169","Libya","LY","LBY","Al Buţnān","","2768","1434128425"
"Bîr Mogreïn","Bir Mogrein","25.2167","-11.6167","Mauritania","MR","MRT","Tiris Zemmour","","2761","1478537499"
"Sauðárkrókur","Saudharkrokur","65.7461","-19.6394","Iceland","IS","ISL","Skagafjörður","","2682","1352929836"
"Provideniya","Provideniya","64.4235","-173.2258","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","2536","1643868459"
"Chokurdakh","Chokurdakh","70.6183","147.8946","Russia","RU","RUS","Sakha (Yakutiya)","","2506","1643382256"
"Marādah","Maradah","29.2333","19.2167","Libya","LY","LBY","Al Wāḩāt","","2500","1434000794"
"Mariscal José Félix Estigarribia","Mariscal Jose Felix Estigarribia","-22.0333","-60.6333","Paraguay","PY","PRY","Boquerón","","2500","1600781023"
"Sohano","Sohano","-5.4297","154.6711","Papua New Guinea","PG","PNG","Bougainville","","2338","1598474458"
"Ypejhú","Ypejhu","-23.9100","-55.4600","Paraguay","PY","PRY","Canindeyú","","2299","1600827924"
"Toltén","Tolten","-39.2166","-73.2123","Chile","CL","CHL","Araucanía","","2293","1152450943"
"Mwenga","Mwenga","-3.0382","28.4325","Congo (Kinshasa)","CD","COD","Sud-Kivu","","2216","1180606289"
"Egvekinot","Egvekinot","66.3221","-179.1837","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","2248","1643466779"
"El Manteco","El Manteco","7.3483","-62.5395","Venezuela","VE","VEN","Bolívar","","2215","1862113510"
"Pozo Colorado","Pozo Colorado","-23.4300","-58.8600","Paraguay","PY","PRY","Presidente Hayes","","2135","1600340660"
"Omboué","Omboue","-1.5667","9.2500","Gabon","GA","GAB","Ogooué-Maritime","","2057","1266708175"
"Konza","Konza","-1.7496","37.1200","Kenya","KE","KEN","Machakos","","2004","1404143289"
"Evensk","Evensk","61.9500","159.2333","Russia","RU","RUS","Magadanskaya Oblast’","","2024","1643589710"
"Altata","Altata","24.6333","-107.9167","Mexico","MX","MEX","Sinaloa","","2001","1484699069"
"Abunã","Abuna","-9.6954","-65.3597","Brazil","BR","BRA","Rondônia","","1929","1076632448"
"Taedong","Taedong","40.6171","125.4501","North Korea","KP","PRK","P’yŏngbuk","","1884","1408763732"
"Beringovskiy","Beringovskiy","63.0655","179.3067","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","1861","1643017874"
"Nasir","Nasir","8.6000","33.0667","South Sudan","SS","SSD","Upper Nile","","1741","1728210480"
"Capitán Pablo Lagerenza","Capitan Pablo Lagerenza","-19.9200","-60.7900","Paraguay","PY","PRY","Alto Paraguay","","1633","1600527430"
"Kipili","Kipili","-7.4329","30.6000","Tanzania","TZ","TZA","Rukwa","","1533","1834589411"
"Oktyabr’skiy","Oktyabr'skiy","52.6636","156.2387","Russia","RU","RUS","Kamchatskiy Kray","","1534","1643010084"
"Ust’-Kuyga","Ust'-Kuyga","70.0171","135.6000","Russia","RU","RUS","Sakha (Yakutiya)","","1517","1643953506"
"Eldikan","Eldikan","60.8000","135.1833","Russia","RU","RUS","Sakha (Yakutiya)","","1516","1643197422"
"Nyimba","Nyimba","-14.5495","30.8100","Zambia","ZM","ZMB","Eastern","","1336","1894088472"
"Fulacunda","Fulacunda","11.7730","-15.1950","Guinea-Bissau","GW","GNB","Quinara","","1311","1624165742"
"Lubutu","Lubutu","-0.7431","26.5833","Congo (Kinshasa)","CD","COD","Maniema","","1313","1180330836"
"Regedor Quissico","Regedor Quissico","-24.7257","34.7660","Mozambique","MZ","MOZ","Inhambane","","1210","1508214411"
"Bala Cangamba","Bala Cangamba","-13.6833","19.8667","Angola","AO","AGO","Moxico","","1307","1024668438"
"Villa Rumipal","Villa Rumipal","-32.1879","-64.4803","Argentina","AR","ARG","Córdoba","","1269","1032717709"
"Ñacunday","Nacunday","-26.0200","-54.7700","Paraguay","PY","PRY","Alto Paraná","","1250","1600303466"
"Ağdam","Agdam","40.9053","45.5564","Azerbaijan","AZ","AZE","Tovuz","","1155","1031834023"
"Villa Martín Colchak","Villa Martin Colchak","-20.7406","-67.6606","Bolivia","BO","BOL","Potosí","","1221","1068713966"
"Buluko","Buluko","-0.7570","28.5280","Congo (Kinshasa)","CD","COD","Nord-Kivu","","1192","1180000569"
"Çeleken","Celeken","39.4362","53.1226","Turkmenistan","TM","TKM","Balkan","","1206","1795554809"
"Puerto Acosta","Puerto Acosta","-15.5333","-69.2500","Bolivia","BO","BOL","La Paz","","1123","1068206449"
"Los Blancos","Los Blancos","-23.6000","-62.6000","Argentina","AR","ARG","Salta","","1145","1032336288"
"Mirbāţ","Mirbat","16.9886","54.6922","Oman","OM","OMN","Z̧ufār","","1120","1512455360"
"Dikson","Dikson","73.5070","80.5451","Russia","RU","RUS","Krasnoyarskiy Kray","","1113","1643554430"
"Klyuchi","Klyuchi","56.3167","160.8333","Russia","RU","RUS","Kamchatskiy Kray","","1089","1643863467"
"General Eugenio A. Garay","General Eugenio A. Garay","-20.5200","-62.2100","Paraguay","PY","PRY","Boquerón","","972","1600467772"
"Daraj","Daraj","30.1500","10.4500","Libya","LY","LBY","Nālūt","","931","1434822177"
"Luanza","Luanza","-8.6996","28.7000","Congo (Kinshasa)","CD","COD","Haut-Katanga","","861","1180293709"
"Hoskins","Hoskins","-5.4746","150.4100","Papua New Guinea","PG","PNG","West New Britain","","871","1598684520"
"Charaña","Charana","-17.6000","-69.4500","Bolivia","BO","BOL","La Paz","","817","1068774768"
"Muhembo","Muhembo","-18.2996","21.8000","Botswana","BW","BWA","North West","","757","1072113578"
"Dibaya","Dibaya","-6.5095","22.8700","Congo (Kinshasa)","CD","COD","Kasaï Central","","603","1180985189"
"Yerëma","Yerema","60.3808","107.7794","Russia","RU","RUS","Irkutskaya Oblast’","","745","1643177367"
"Satadougou","Satadougou","12.6170","-11.4066","Mali","ML","MLI","Kayes","","706","1466591334"
"Zhaltyr","Zhaltyr","51.6324","69.8328","Kazakhstan","KZ","KAZ","","","694","1398809487"
"Manily","Manily","62.4908","165.3298","Russia","RU","RUS","Kamchatskiy Kray","","694","1643235167"
"Calatrava","Calatrava","1.1164","9.4186","Equatorial Guinea","GQ","GNQ","Litoral","","628","1226322426"
"Massangena","Massangena","-21.5378","32.9564","Mozambique","MZ","MOZ","Gaza","","650","1508224482"
"Panda","Panda","-24.0629","34.7303","Mozambique","MZ","MOZ","Inhambane","","602","1508574997"
"Villa O’Higgins","Villa O'Higgins","-48.4683","-72.5600","Chile","CL","CHL","Aysén","","612","1152709181"
"Kullorsuaq","Kullorsuaq","74.5792","-57.2181","Greenland","GL","GRL","Avannaata","","453","1304004889"
"Quilpie","Quilpie","-26.6161","144.2675","Australia","AU","AUS","Queensland","","595","1036696232"
"Chiramba","Chiramba","-16.8921","34.6559","Mozambique","MZ","MOZ","Manica","","556","1508392455"
"Sabaya","Sabaya","-19.0147","-68.3708","Bolivia","BO","BOL","Oruro","","573","1068184161"
"Mereeg","Mereeg","3.7667","47.3000","Somalia","SO","SOM","Galguduud","","548","1706308443"
"Llica","Llica","-19.8500","-68.2500","Bolivia","BO","BOL","Potosí","","553","1068053822"
"Calenga","Calenga","-11.3196","16.2000","Angola","AO","AGO","Bié","","531","1024822780"
"Caluula","Caluula","11.9667","50.7500","Somalia","SO","SOM","Bari","","513","1706796061"
"Tournavista","Tournavista","-8.9322","-74.7052","Peru","PE","PER","Huánuco","","511","1604006133"
"Tchitado","Tchitado","-17.3167","13.9167","Angola","AO","AGO","Cunene","","500","1024158837"
"Yakossi","Yakossi","5.6170","23.3167","Central African Republic","CF","CAF","Mbomou","","500","1140246753"
"Puerto Pinasco","Puerto Pinasco","-22.7167","-57.8333","Paraguay","PY","PRY","Presidente Hayes","","500","1600670025"
"Tmassah","Tmassah","26.3667","15.8000","Libya","LY","LBY","Murzuq","","500","1434333715"
"Woomera","Woomera","-31.1496","136.8000","Australia","AU","AUS","South Australia","","450","1036392822"
"Sherlovaya Gora","Sherlovaya Gora","50.5306","116.3006","Russia","RU","RUS","Zabaykal’skiy Kray","","411","1643008235"
"Tsavo","Tsavo","-2.9828","38.4666","Kenya","KE","KEN","Taita/Taveta","","414","1404321644"
"Nizhneyansk","Nizhneyansk","71.4333","136.0666","Russia","RU","RUS","Sakha (Yakutiya)","","400","1643012685"
"Toconao","Toconao","-23.1903","-68.0050","Chile","CL","CHL","Antofagasta","","378","1152732862"
"Tasiusaq","Tasiusaq","73.3689","-56.0556","Greenland","GL","GRL","Avannaata","","252","1304103267"
"Burubaytal","Burubaytal","44.9350","74.0180","Kazakhstan","KZ","KAZ","","","336","1398655547"
"Kanyato","Kanyato","-4.4565","30.2614","Tanzania","TZ","TZA","Kigoma","","232","1834158031"
"Kulusuk","Kulusuk","65.5753","-37.1833","Greenland","GL","GRL","Sermersooq","","286","1304671891"
"Umm al ‘Abīd","Umm al \`Abid","27.5170","15.0333","Libya","LY","LBY","Wādī ash Shāţi’","","300","1434373121"
"Bugrino","Bugrino","68.7831","49.3036","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","","300","1643062803"
"Put’ Lenina","Put' Lenina","68.5166","107.8000","Russia","RU","RUS","Sakha (Yakutiya)","","298","1643749671"
"Yaupi","Yaupi","-2.8379","-77.9357","Ecuador","EC","ECU","Morona-Santiago","","293","1218516951"
"Amderma","Amderma","69.7631","61.6678","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","","282","1643719353"
"Kangersuatsiaq","Kangersuatsiaq","72.3797","-55.5500","Greenland","GL","GRL","Avannaata","","200","1304939198"
"Amau","Amau","-10.0426","148.5650","Papua New Guinea","PG","PNG","Central","","230","1598610032"
"Androka","Androka","-25.0219","44.0749","Madagascar","MG","MDG","Toliara","","174","1450495720"
"Lusanga","Lusanga","-5.5808","16.5143","Congo (Kinshasa)","CD","COD","Kwango","","177","1180423801"
"Kraulshavn","Kraulshavn","74.1111","-57.0611","Greenland","GL","GRL","Avannaata","","204","1304966853"
"Hurdiyo","Hurdiyo","10.5667","51.1333","Somalia","SO","SOM","Bari","","176","1706025904"
"Buton","Buton","4.2170","108.2000","Indonesia","ID","IDN","Kepulauan Riau","","140","1360984658"
"Narsarsuaq","Narsarsuaq","61.1458","-45.4347","Greenland","GL","GRL","Kujalleq","","145","1304138343"
"Bafwasende","Bafwasende","1.0103","27.1617","Congo (Kinshasa)","CD","COD","Tshopo","","149","1180841746"
"Ban Huai Hin","Ban Huai Hin","12.5697","99.9443","Thailand","TH","THA","Phetchaburi","","","1764975513"
"Bifoun","Bifoun","-0.3333","10.3833","Gabon","GA","GAB","Moyen-Ogooué","","134","1266655418"
"Il’pyrskiy","Il'pyrskiy","59.9600","164.2000","Russia","RU","RUS","Kamchatskiy Kray","","111","1643660854"
"Savissivik","Savissivik","76.0194","-65.1139","Greenland","GL","GRL","Avannaata","","66","1304246272"
"Cuya","Cuya","-19.1597","-70.1794","Chile","CL","CHL","Tarapacá","","20","1152944039"
"Gyda","Gyda","70.8814","78.4661","Russia","RU","RUS","Yamalo-Nenetskiy Avtonomnyy Okrug","","10","1643172565"
"Güeppí","Gueppi","-0.1166","-75.2300","Peru","PE","PER","Loreto","","10","1604411859"
"Chuquicamata","Chuquicamata","-22.3169","-68.9301","Chile","CL","CHL","Antofagasta","","0","1152468996"
"Puerto Heath","Puerto Heath","-12.5200","-68.6186","Bolivia","BO","BOL","La Paz","","10","1068996459"
"Yessey","Yessey","68.4652","102.1887","Russia","RU","RUS","Krasnoyarskiy Kray","","10","1643816547"
"Lemsid","Lemsid","26.5482","-13.8482","Morocco","MA","MAR","Laâyoune-Sakia El Hamra","","100","1504999975"
"Mukhomornoye","Mukhomornoye","66.4171","173.3333","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","100","1643631484"
"Vorontsovo","Vorontsovo","71.6983","83.5642","Russia","RU","RUS","Krasnoyarskiy Kray","","100","1643504976"
"Grytviken","Grytviken","-54.2806","-36.5080","South Georgia and South Sandwich Islands","GS","SGS","","","99","1239048837"
"Piso Firme","Piso Firme","-13.6830","-61.8666","Bolivia","BO","BOL","El Beni","","72","1068400874"
"Rocafuerte","Rocafuerte","-0.9329","-75.4000","Peru","PE","PER","Loreto","","40","1604951110"
"Peregrebnoye","Peregrebnoye","62.9670","65.0859","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","10","1643322655"
"Laryak","Laryak","61.1012","80.2514","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","10","1643861109"
"Lagunas","Lagunas","-20.9829","-69.6833","Chile","CL","CHL","Tarapacá","","10","1152360178"
"Andoas","Andoas","-2.9042","-76.4028","Peru","PE","PER","Loreto","","10","1604038416"
"Puca Urco","Puca Urco","-2.3328","-71.9167","Peru","PE","PER","Loreto","","10","1604085670"
"Zillah","Zillah","28.5489","17.5511","Libya","LY","LBY","Al Jufrah","","10","1434245699"
"Barnīs","Barnis","23.9460","35.4842","Egypt","EG","EGY","Al Baḩr al Aḩmar","","10","1818107956"
"Soldado Bartra","Soldado Bartra","-2.5161","-75.7666","Peru","PE","PER","Loreto","","10","1604302193"
"Ulkan","Ulkan","55.9004","107.7833","Russia","RU","RUS","Irkutskaya Oblast’","","10","1643020424"
"Strelka","Strelka","61.8670","152.2502","Russia","RU","RUS","Magadanskaya Oblast’","","10","1643297872"
"Bol’sheretsk","Bol'sheretsk","52.4390","156.3594","Russia","RU","RUS","Kamchatskiy Kray","","10","1643981807"
"Karamken","Karamken","60.2004","151.1666","Russia","RU","RUS","Magadanskaya Oblast’","","10","1643511192"
"Djado","Djado","21.0150","12.3075","Niger","NE","NER","Agadez","","10","1562138807"
"Siglan","Siglan","59.0337","152.4166","Russia","RU","RUS","Magadanskaya Oblast’","","10","1643408923"
"Omchak","Omchak","61.6333","147.9167","Russia","RU","RUS","Magadanskaya Oblast’","","10","1643827519"
"Shalaurova","Shalaurova","73.2204","143.1833","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643617349"
"Khorgo","Khorgo","73.4833","113.6300","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643550363"
"Komsa","Komsa","61.8680","89.2577","Russia","RU","RUS","Krasnoyarskiy Kray","","10","1643255641"
"Pakhachi","Pakhachi","60.5816","169.0500","Russia","RU","RUS","Kamchatskiy Kray","","10","1643406349"
"Indiga","Indiga","67.6898","49.0166","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","","10","1643522900"
"Chagda","Chagda","60.1000","133.9000","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643773533"
"Trofimovsk","Trofimovsk","72.5997","127.0337","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643214114"
"Tunguskhaya","Tunguskhaya","64.9004","125.2500","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643610062"
"Podkamennaya Tunguska","Podkamennaya Tunguska","61.5995","90.1236","Russia","RU","RUS","Krasnoyarskiy Kray","","10","1643764711"
"Varnek","Varnek","69.7153","60.0600","Russia","RU","RUS","Nenetskiy Avtonomnyy Okrug","","10","1643378778"
"Utkholok","Utkholok","57.5504","157.2333","Russia","RU","RUS","Kamchatskiy Kray","","10","1643251905"
"Matochkin Shar","Matochkin Shar","73.2700","56.4497","Russia","RU","RUS","Arkhangel’skaya Oblast’","","10","1643843865"
"Khakhar","Khakhar","57.6666","135.4300","Russia","RU","RUS","Khabarovskiy Kray","","10","1643315253"
"Menkerya","Menkerya","67.9886","123.3505","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643637503"
"Zvëzdnyy","Zvezdnyy","70.9333","-179.6000","Russia","RU","RUS","Chukotskiy Avtonomnyy Okrug","","10","1643877771"
"Starorybnoye","Starorybnoye","72.7666","104.8000","Russia","RU","RUS","Krasnoyarskiy Kray","","10","1643724242"
"Sagastyr","Sagastyr","73.3779","126.5924","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643535665"
"Zemlya Bunge","Zemlya Bunge","74.8983","142.1050","Russia","RU","RUS","Sakha (Yakutiya)","","10","1643720805"
"Agapa","Agapa","71.4504","89.2500","Russia","RU","RUS","Krasnoyarskiy Kray","","10","1643009087"
"Tukchi","Tukchi","57.3670","139.5000","Russia","RU","RUS","Khabarovskiy Kray","","10","1643472801"
"Numto","Numto","63.6667","71.3333","Russia","RU","RUS","Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra","","10","1643985006"
"Nord","Nord","81.7166","-17.8000","Greenland","GL","GRL","","","10","1304217709"
"Timmiarmiut","Timmiarmiut","62.5333","-42.2167","Greenland","GL","GRL","Kujalleq","","10","1304206491"
"San Rafael","San Rafael","-16.7795","-60.6799","Bolivia","BO","BOL","Santa Cruz","","","1068007388"
"Nordvik","Nordvik","74.0165","111.5100","Russia","RU","RUS","Krasnoyarskiy Kray","","0","1643587468"
`;

const citiesJson = {};

citiesData.split("\n").map((item) => {
    const splitted = item.split(",");
    const key = `${splitted[1]?.replaceAll('"', "")}${splitted[5]?.replaceAll('"', "")}`;
    citiesJson[key] = {
        name: splitted[1]?.replaceAll('"', ""),
        longitude: splitted[3]?.replaceAll('"', ""),
        latitude: splitted[2]?.replaceAll('"', ""),
        countryISO: splitted[5]?.replaceAll('"', ""),
    };
});

export default citiesJson;
