import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import { useEffect, useState } from "react";

const CategoryComparison = ({ catData, newArticles }) => {
    const [shownData, setShownData] = useState([]);

    useEffect(() => {
        if (catData) {
            setShownData([
                ...catData
                    .sort((prev, curr) => {
                        if (curr.category === "NA") {
                            return -1;
                        }
                        if (prev.category === "NA") {
                            return 1;
                        }
                        return curr.unique_users - prev.unique_users;
                    })
                    ?.slice(0, 3),
            ]);
        }
    }, [catData]);

    return (
        <div className="flex-1">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">Top Categories Comparison</div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex text-white border border-[#383838]">
                {shownData?.map((item, i) => (
                    <div
                        key={i}
                        className={
                            i === 1
                                ? "flex-1 px-5 py-6 text-2xl font-semibold leading-none capitalize border-r border-l border-r-[#383838] border-l-[#383838]"
                                : "flex-1 px-5 py-6 text-2xl font-semibold leading-none capitalize"
                        }
                    >
                        {item.category.replaceAll("_", " ")}
                    </div>
                ))}
                {/* <div className="flex-1 px-5 py-6 text-2xl font-semibold leading-none">Politics</div>
                <div className="flex-1 px-5 py-6 text-2xl font-semibold leading-none">
                    Sports
                </div>
                <div className="flex-1 px-5 py-6 text-2xl font-semibold leading-none">
                    Entertainment
                </div> */}
            </div>
            <div className="flex text-white border border-[#383838]">
                {shownData?.map((item, i) => (
                    <div
                        key={i}
                        className={
                            i === 1
                                ? "flex-1 p-5 font-semibold border-r border-l border-r-[#383838] border-l-[#383838]"
                                : "flex-1 p-5 font-semibold"
                        }
                    >
                        <div className="flex flex-col gap-8">
                            <div>
                                <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                    Total amount of users
                                </div>
                                <div className="text-2xl font-semibold leading-none">
                                    {item.unique_users.toLocaleString()}
                                </div>
                            </div>
                            <div>
                                <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                    New Articles Share
                                </div>
                                <div className="text-2xl font-semibold leading-none">
                                    {((item.new_articles / newArticles) * 100).toFixed(2)}%
                                </div>
                            </div>
                            <div>
                                <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                    Engagement Rate
                                </div>
                                <div className="text-2xl font-semibold leading-none">
                                    {((item.event_count / item.unique_users) * 100).toFixed(2)}%
                                </div>
                            </div>
                            <div>
                                <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                    Engagement Amount
                                </div>
                                <div className="text-2xl font-semibold leading-none">
                                    {item.event_count.toLocaleString()}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className="flex-1 p-5 font-semibold border-r border-l border-r-[#383838] border-l-[#383838] ">
                    <div className="flex flex-col gap-8">
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Total amount of users
                            </div>
                            <div className="text-2xl font-semibold leading-none text-[#92f6a8]">
                                56,548
                            </div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Category Rating
                            </div>
                            <div className="text-2xl font-semibold leading-none text-[#92f6a8]">
                                5
                            </div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Engagement Rate
                            </div>
                            <div className="text-2xl font-semibold leading-none">45%</div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Engagement Amount
                            </div>
                            <div className="text-2xl font-semibold leading-none text-[#92f6a8]">
                                454,543
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 p-5 font-semibold">
                    <div className="flex flex-col gap-8">
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Total amount of users
                            </div>
                            <div className="text-2xl font-semibold leading-none">24,697</div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Category Rating
                            </div>
                            <div className="text-2xl font-semibold leading-none">4.2</div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Engagement Rate
                            </div>
                            <div className="text-2xl font-semibold leading-none text-[#92f6a8]">
                                90%
                            </div>
                        </div>
                        <div>
                            <div className="text-sm font-medium text-[#999] mb-3 leading-none">
                                Engagement Amount
                            </div>
                            <div className="text-2xl font-semibold leading-none">268,785</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};
export default CategoryComparison;
