const ContentPerformance = ({ selectedArticle }) => {
    return (
        <div>
            {/* <div className="flex items-center gap-4">
                <div className="bg-[#141414] p-4 rounded text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">Performance shift %</div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">18%</div>
                </div>
                <div className="bg-[#141414] p-4 rounded text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">Performance peak time</div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">
                        3pm-4pm <span className="text-xl">(05/01/2024)</span>
                    </div>
                </div>
                <div className="bg-[#141414] p-4 rounded text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">Content quality scoring</div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">85</div>
                </div>
            </div> */}
            <div className="flex items-center mt-4">
                <div className="border-l border-l-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem] ">
                        Toxicity:{" "}
                    </div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">
                        {`${selectedArticle?.content_genome?.aspects?.toxic}`
                            ? selectedArticle?.content_genome?.aspects?.toxic
                            : ""}
                    </div>
                </div>
                <div className="border-b border-b-[#383838]  border-t border-t-[#383838]  border-l border-l-[#383838] border-r border-r-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                        Sentiment:{" "}
                    </div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">
                        {" "}
                        {selectedArticle?.content_genome?.aspects?.sentiment
                            ? selectedArticle?.content_genome?.aspects?.sentiment
                            : ""}
                    </div>
                </div>
                <div className="border-r border-r-[#383838] border-b border-b-[#383838]  border-t border-t-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                        Identity Mention:{" "}
                    </div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8 capitalize">
                        {selectedArticle?.content_genome?.aspects?.identity_mention
                            ? selectedArticle?.content_genome?.aspects?.identity_mention
                            : ""}
                    </div>
                </div>
            </div>{" "}
            <div className="flex items-center">
                <div className="border-l border-l-[#383838] border-b border-b-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                        Dimension:{" "}
                    </div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8 capitalize">
                        {selectedArticle?.content_genome?.aspects?.dimension
                            ? selectedArticle?.content_genome?.aspects?.dimension
                            : ""}
                    </div>
                </div>
                <div className="border-b border-b-[#383838] border-l border-l-[#383838] border-r border-r-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                        Aspect:{" "}
                    </div>
                    <div className="font-bold text-4xl font-[Poppins] mt-8">
                        {selectedArticle?.content_genome?.aspects?.aspect
                            ? selectedArticle?.content_genome?.aspects?.aspect
                            : ""}
                    </div>
                </div>
                <div className="border-r border-r-[#383838] border-b border-b-[#383838] p-4 h-40 flex flex-col justify-between text-white flex-1">
                    <div className="font-semibold text-[#7a7a7a] uppercase text-[0.8125rem]">
                        Label:{" "}
                    </div>
                    <div
                        className={
                            selectedArticle?.content_genome?.aspects?.label?.toLowerCase() ===
                            "neutral"
                                ? "font-bold text-4xl font-[Poppins] mt-8 bg-[#92F6A8] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                : selectedArticle?.content_genome?.aspects?.label?.toLowerCase() ===
                                  "slightly biased"
                                ? "font-bold text-4xl font-[Poppins] mt-8 bg-[#FF7E00] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                : selectedArticle?.content_genome?.aspects?.label?.toLowerCase() ===
                                  "highly biased"
                                ? "font-bold text-4xl font-[Poppins] mt-8 bg-[#f46a6a] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                : "font-bold text-4xl font-[Poppins] mt-8"
                        }
                    >
                        {selectedArticle?.content_genome?.aspects?.label
                            ? selectedArticle?.content_genome?.aspects?.label
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ContentPerformance;
