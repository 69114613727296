import { useEffect, useRef, useState } from "react";
import CalendarComponent from "./calendarcomp";
import CalendarIcon from "components/shared/icons/calendar";

const DatePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
    const [showCalendar, setShowCalendar] = useState(false);

    const containerRef = useRef(null);
    useEffect(() => {
        const clickOutside = (event) => {
            if (!containerRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={containerRef}
            className="bg-[#3f3f3f] gap-2 flex items-center justify-evenly rounded text-[#cacaca] relative"
        >
            <div className="text-2xl pl-3">
                <CalendarIcon />
            </div>
            <div
                onClick={() => {
                    setShowCalendar((prevState) => !prevState);
                }}
                className="flex items-center justify-evenly gap-4  pr-3 py-[0.875rem]"
            >
                <div className="font-semibold">{`${new Date(startDate).toLocaleDateString("en-UK", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                })} - ${new Date(endDate).toLocaleDateString("en-UK", {
                    day: "2-digit",
                    year: "numeric",
                    month: "short",
                })}`}</div>
            </div>
            {showCalendar && (
                <CalendarComponent
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setShowCalendar={setShowCalendar}
                />
            )}
        </div>
    );
};
export default DatePicker;
