import { Navigate } from "react-router-dom";
import MainHeader from "../shared/mainHeader";
import Article from "./article";
import MoreDetails from "./moreDetails";
import { useCallback, useEffect, useState } from "react";
import customFetch from "components/shared/customFetch";
import { articlePriceAPI, devServer } from "components/shared/utils";
import langCodes from "assests/languageCode";
import { ClickBaitCategory, RatingDef, RelevanceDef } from "assests/definitions";
import { Resizable } from "re-resizable";
import debounce from "lodash.debounce";

const Overview = () => {
    const [list, setList] = useState([]);
    const [pageno, setPageno] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [urlList, setUrlList] = useState([]);
    const [prices, setPrices] = useState({});
    const [selectedSimilar, setSelectedSimilar] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const [refreshList, setRefreshList] = useState(false);

    const [contentGenome, setContentGenome] = useState({
        URL: "",
        Title: "",
        Price: "",
        Author: "",
        Category: "",
        "Published at": "",
        Language: "",
        Rating: "",
        Relevance: "",
        Seasonality: "",
        Clickbait: "",
        "Synthetic text": "",
    });

    const [selected, setSelected] = useState("");
    const options = [
        "Similar Articles",
        "Content Performance",
        "User Engagement",
        "Content Metrics",
    ];

    const pageLength = 10;

    const getList = async (extend, customsearchKey, custompageno) => {
        try {
            const resp = await customFetch(
                searchKey || customsearchKey
                    ? `${devServer}/get-articles-for-publisher?page=${
                          custompageno > -1 ? custompageno : pageno
                      }&limit=${pageLength}&search=${
                          customsearchKey ? customsearchKey.trim() : searchKey.trim()
                      }`
                    : `${devServer}/get-articles-for-publisher?page=${
                          custompageno > -1 ? custompageno : pageno
                      }&limit=${pageLength}`,
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            const res = await resp.json();

            if (res.ok) {
                setList((prevState) => {
                    return extend ? [...prevState, ...res.articles] : [...res.articles];
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const FetchPrice = async () => {
        try {
            const response = await fetch(articlePriceAPI, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                // /make sure to serialize your JSON body
                body: JSON.stringify({
                    currency: "usd",
                    urls: [...urlList],
                }),
            });

            // Storing data in form of JSON
            let data = await response.json();
            setPrices(data);
        } catch (err) {
            console.log(err);
        }
    };

    const DebouncedHandleSearch = useCallback(
        debounce((customsearchKey, pageno) => {
            setList([]);
            getList(false, customsearchKey, pageno);
            setSelectedIndex(0);
        }, 500),
        [pageLength]
    );

    useEffect(() => {
        setPageno(0);
        if (searchKey === "") {
            setList([]);
            getList(false, "", 0);
            setSelectedIndex(0);
        } else {
            DebouncedHandleSearch(searchKey, 0);
        }
    }, [searchKey]);
    useEffect(() => {
        if (pageno > 0) {
            getList(true);
        } else if (refreshList) {
            getList(false);
            setRefreshList(false);
        }
    }, [pageno, refreshList]);

    useEffect(() => {
        setUrlList(() => {
            return list.map((item) => item.urls[0]);
        });
    }, [list]);

    useEffect(() => {
        if (!list[selectedIndex]) {
            setSelected("");
        }
    }, [list, selectedIndex]);

    useEffect(() => {
        FetchPrice();
    }, [urlList]);

    useEffect(() => {
        setContentGenome(() => {
            const a = list[selectedIndex];
            if (a) {
                return {
                    URL: a.urls[0],
                    Title: a.title,
                    Price: "QMV $1",
                    Author: a.author,
                    Category: a.category.replaceAll("_", " "),
                    "Published at": a.publishedAt,
                    Language: langCodes[a.language] ? langCodes[a.language] : a.language,
                    Rating: a.content_genome.rating,
                    Relevance: a.content_genome.relevancy,
                    Seasonality: "",
                    Clickbait: a.content_genome.clickbait,
                    ClickbaitCategory: a.content_genome.clickbait_category,
                    "Synthetic text": (
                        a.content_genome.machine_generated_probability * 100
                    ).toFixed(2),
                };
            } else {
                return {};
            }
        });
    }, [list, selectedIndex]);

    useEffect(() => {
        if (selected !== "Similar Articles") {
            setSelectedSimilar([]);
        }
    }, [selected]);

    const token = localStorage.getItem("accessToken");
    if (!token) return <Navigate to="/login" />;

    return (
        <div className="bg-black w-full h-full flex flex-col font-[Roboto]">
            <MainHeader />

            <div className="flex-1 flex">
                <Article
                    options={options}
                    selected={selected}
                    setSelected={setSelected}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    pageLength={pageLength}
                    pageno={pageno}
                    list={list}
                    setPageno={setPageno}
                    prices={prices}
                    searchKey={searchKey}
                    setSearchKey={setSearchKey}
                    selectedSimilar={selectedSimilar}
                    setRefreshList={setRefreshList}
                />
                <div className="bg-[#141414] p-6 w-[20vw] flex flex-col gap-4">
                    <div className="text-white font-semibold text-2xl">Content Genome</div>
                    <div className="flex-[1_1rem] overflow-auto flex flex-col gap-6">
                        <div className="text-white">
                            <div className="text-base font-medium text-[#7a7a7a]">URL:</div>
                            <a
                                href={contentGenome.URL}
                                target="_blank"
                                className="text-xl break-all text-[rgb(4,133,255)]"
                            >
                                {contentGenome.URL}
                            </a>
                        </div>
                        <div className="text-white">
                            <div className="text-base font-medium text-[#7a7a7a]">Title:</div>
                            <div className="text-xl">{contentGenome.Title}</div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">Price:</div>
                                <div className="text-xl">
                                    {prices[contentGenome.URL]
                                        ? `QMV $${prices[contentGenome.URL].toFixed(2)}`
                                        : ""}
                                </div>
                            </div>
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">Author:</div>
                                <div className="text-xl">{contentGenome.Author}</div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Category:
                                </div>
                                <div className="text-xl capitalize">{contentGenome.Category}</div>
                            </div>
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Published at:
                                </div>
                                <div className="text-xl">
                                    {contentGenome["Published at"]
                                        ? new Date(
                                              contentGenome["Published at"]
                                          ).toLocaleDateString()
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Language:
                                </div>
                                <div className="text-xl">{contentGenome.Language}</div>
                            </div>
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">Rating:</div>
                                <div
                                    className={
                                        contentGenome.Rating > 2
                                            ? "text-xl bg-[#92F6A8] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                            : contentGenome.Rating > 0
                                            ? "text-xl bg-[#FF7E00] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                            : contentGenome.Rating === 0
                                            ? "text-xl bg-[#f46a6a] w-max px-3 py-1 rounded-[999px] text-[#141414]"
                                            : "text-xl"
                                    }
                                >
                                    {RatingDef[contentGenome.Rating]}
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Relevance:
                                </div>
                                <div className="text-xl">
                                    {RelevanceDef[contentGenome.Relevance]}
                                </div>
                            </div>
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Seasonality:
                                </div>
                                <div className="text-xl">{contentGenome.Seasonality}</div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Clickbait:
                                </div>
                                <div className="text-xl">
                                    {contentGenome.Clickbait === 0
                                        ? "No"
                                        : ClickBaitCategory[contentGenome.ClickbaitCategory]}
                                </div>
                            </div>
                            <div className="text-white flex-1">
                                <div className="text-base font-medium text-[#7a7a7a]">
                                    Synthetic text:
                                </div>
                                <div className="text-xl">
                                    {contentGenome["Synthetic text"]
                                        ? `${contentGenome["Synthetic text"]}%`
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selected !== "" && (
                <Resizable
                    style={{ minHeight: "30%" }}
                    defaultSize={{ width: "100%", height: "50%" }}
                    // boundsByDirection={true}
                    // bounds={"parent"}
                    enable={{
                        top: true,
                        right: false,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    className="flex px-6 py-6 max-h-[68%] min-h-[40%] border border-[#383838]"
                >
                    <MoreDetails
                        options={options}
                        selected={selected}
                        setSelected={setSelected}
                        selectedArticle={list[selectedIndex]}
                        setSelectedSimilar={setSelectedSimilar}
                    />
                </Resizable>
            )}
        </div>
    );
};
export default Overview;
