const ChevronDown = ({ color }) => {
    let _color = localStorage.theme === "light" ? "#1D6BA6" : "#00FFFF";
    if (color) _color = color;
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.5 8.25L11 13.75L16.5 8.25"
                stroke={_color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ChevronDown;
