import React, { useEffect, useRef } from "react";
// import { Link } from "react-router-dom";

import Close from "components/shared/icons/Close";
import CheckCircle from "components/shared/icons/CheckCircle";

/**
 * Currency dropdown
 * @prop {Function} closeModal - Function to close modal
 */

const RegisterModal = ({ closeModal }) => {
    const modalRef = useRef();

    useEffect(() => {
        const clickOutside = (event) => {
            if (!modalRef.current.contains(event.target)) {
                closeModal();
            }
        };
        document.addEventListener("mousedown", clickOutside);
        return () => {
            document.removeEventListener("mousedown", clickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        closeModal();
        window.location.reload();
    };

    // useEffect(() => {
    //     if (timer === 0) {
    //         // navigate("/");
    //     }
    //     setInterval(() => {
    //         setTimer(timer - 1);
    //     }, 1000);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [timer]);

    return (
        <div
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                width: "100%",
                height: "100%",
                transform: " translate(-50%, -50%)",
                zIndex: 1000,
                overflowY: "auto",
                outline: "none",
                backgroundColor: "rgba(0,0,0,0.8)",
            }}
        >
            <div
                className=" rounded-2xl px-18 py-4"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "100%",
                    maxWidth: "616px",
                    transform: " translate(-50%, -50%)",
                    backgroundColor: "#101010",
                }}
                ref={modalRef}
            >
                <div className="px-16 pt-20 pb-4 relative text-center flex flex-col items-center">
                    <p
                        className="absolute right-4 top-4 cursor-pointer"
                        onClick={() => handleClose()}
                    >
                        <Close />
                    </p>

                    <CheckCircle />
                    <h1 className="font-bold text-3xl text-blue2 mb-9">
                        Welcome <br />
                        to Kunato
                    </h1>

                    <p className="text-sm font-normal text-gray-500 text-center mb-7">
                        Thank you for registering with Kunato. We are excited to have you onboard
                        with us. one of our represenatives will get in touch with you in the next
                        24-48 hours for further steps.
                    </p>

                    {/* <p className="text-sm font-normal text-gray-500 text-center mb-3">
                        If you have any Questions refer to{" "}
                        <Link to="/faq" className="text-blue2">
                            Help Center.
                        </Link>
                    </p> */}

                    {/* <p className="text-sm font-normal text-blue2 text-center mb-3">
                        You will be redirected back to home in {timer}
                    </p> */}
                </div>
            </div>
        </div>
    );
};

export default RegisterModal;
