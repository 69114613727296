import { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import InfoIcon from "components/shared/icons/InfoNew";
import ExpandIcon from "components/shared/icons/expandIcon";
import SettingsIcon from "components/shared/icons/settingsIcon";
import { chandlechartAPI } from "components/shared/utils";

const PPCOverTime = ({ topArticles }) => {
    const [options, setOptions] = useState({});
    const [ohlc, setOhlc] = useState([]);

    const getOHLC = async () => {
        try {
            const resp = await fetch(chandlechartAPI, {
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    currency: "usd",
                    url: topArticles[0]?.url,
                    duration: "lt",
                }),
                method: "POST",
            });
            const res = await resp.json();
            if (res.ok) {
                setOhlc(() => {
                    return res.data.map((item) => {
                        return [
                            item.time?.split("T")[0],
                            item.open,
                            item.close,
                            item.high,
                            item.low,
                        ];
                    });
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (topArticles?.length > 0) {
            getOHLC();
        }
    }, [topArticles]);
    useEffect(() => {
        setTimeout(() => {
            setOptions({
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: true,
                },

                xAxis: {
                    type: "category",
                    boundaryGap: ["5%", "5%"],

                    axisLabel: {
                        color: "#999",
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                },
                yAxis: {
                    boundaryGap: ["5%", "0%"],
                    // min: (value) => {
                    //     return Math.round(value.min * 0.85);
                    // },
                    max: 4,

                    type: "value",
                    splitLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.1)",
                        },
                        axisLabel: {
                            color: "#999",
                            showMinLabel: false,
                            formatter: (props) => {
                                console.log(props);
                                return "test";
                            },
                        },
                    },
                },
                graphic:
                    ohlc.length > 30
                        ? [
                              // Declare multiple graphic elements.

                              {
                                  type: "circle",
                                  // x: "50%", y: "50%"
                                  bottom: `${
                                      ((Math.max(
                                          ohlc[15][1],
                                          ohlc[15][2],
                                          ohlc[17][1],
                                          ohlc[17][2]
                                      ) +
                                          Math.min(
                                              ohlc[15][1],
                                              ohlc[15][2],
                                              ohlc[17][1],
                                              ohlc[17][2]
                                          )) /
                                          2 /
                                          4) *
                                          100 +
                                      5
                                  }%`,
                                  left: "52%",
                                  shape: {
                                      // cx: 50,
                                      // cy: 50,
                                      r: 3,
                                  },
                                  style: {
                                      fill: "#ccc",
                                  },
                              },
                              {
                                  type: "circle",
                                  // x: "50%", y: "50%"
                                  bottom: `${
                                      ((Math.max(
                                          ohlc[15][1],
                                          ohlc[15][2],
                                          ohlc[17][1],
                                          ohlc[17][2]
                                      ) +
                                          Math.min(
                                              ohlc[15][1],
                                              ohlc[15][2],
                                              ohlc[17][1],
                                              ohlc[17][2]
                                          )) /
                                          2 /
                                          4) *
                                          100 +
                                      5
                                  }%`,
                                  left: "53%",
                                  shape: {
                                      // cx: 50,
                                      // cy: 50,
                                      r: 3,
                                  },
                                  style: {
                                      fill: "#ccc",
                                  },
                              },
                              {
                                  type: "circle",
                                  // x: "50%", y: "50%"
                                  bottom: `${
                                      ((Math.max(
                                          ohlc[15][1],
                                          ohlc[15][2],
                                          ohlc[17][1],
                                          ohlc[17][2]
                                      ) +
                                          Math.min(
                                              ohlc[15][1],
                                              ohlc[15][2],
                                              ohlc[17][1],
                                              ohlc[17][2]
                                          )) /
                                          2 /
                                          4) *
                                          100 +
                                      5
                                  }%`,
                                  left: "54%",
                                  shape: {
                                      // cx: 50,
                                      // cy: 50,
                                      r: 3,
                                  },
                                  style: {
                                      fill: "#ccc",
                                  },
                              },
                          ]
                        : [],

                series: [
                    {
                        type: "candlestick",
                        // colorBy: "series",
                        barWidth: "20%",
                        barMaxWidth: 40,
                        x: "date",
                        y: ["open", "close", "highest", "lowest"],
                        itemStyle: {
                            color0: "#F46A6A",
                            color: "#92F6A8",
                            borderColor0: "#F46A6A",
                            borderColor: "#92F6A8",
                        },
                        zlevel: 3,
                    },
                    {
                        type: "line",
                        symbol: "none",
                        encode: {
                            x: "date",
                            y: ["open"],
                        },

                        lineStyle: { color: "#666" },
                    },
                ],
                dataset: {
                    source: ohlc,
                    dimensions: ["date", "open", "close", "highest", "lowest"],
                },
                // // color: ["#92f6a8", "#df8bcd", "#249bed"],
            });
        }, 150);
    }, [ohlc]);

    return (
        <div className="flex-1 flex flex-col p-8">
            <div className="flex items-center justify-between mb-8">
                <div className="flex items-center text-white flex items-center gap-2">
                    <div className=" text-lg font-medium ">
                        PPC Overtime{" "}
                        <span className="text-sm text-[#999]">(Top Performing Article)</span>
                    </div>
                    <div className="flex items-center justify-center text-xs text-[#999999]">
                        <InfoIcon />
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        {" "}
                        <ExpandIcon />{" "}
                    </button>
                    <button className="flex items-center justify-center text-2xl text-[#cacaca]">
                        <SettingsIcon />{" "}
                    </button>
                </div>
            </div>
            <div className="flex-1">
                {options && (
                    <ReactECharts
                        option={options}
                        style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
                <div className="flex items-center"></div>
            </div>
        </div>
    );
};
export default PPCOverTime;
