const CheckCircle = ({ width, height, color }) => {
    let _width = "65";
    let _height = "65";
    if (width) _width = width;
    if (height) _height = height;
    let _color = "#00FFFF";
    if (color) _color = color;
    return (
        <svg
            width={_width}
            height={_height}
            viewBox="0 0 65 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.5001 5.41699C17.5501 5.41699 5.41675 17.5503 5.41675 32.5003C5.41675 47.4503 17.5501 59.5837 32.5001 59.5837C47.4501 59.5837 59.5834 47.4503 59.5834 32.5003C59.5834 17.5503 47.4501 5.41699 32.5001 5.41699ZM32.5001 54.167C20.5563 54.167 10.8334 44.4441 10.8334 32.5003C10.8334 20.5566 20.5563 10.8337 32.5001 10.8337C44.4438 10.8337 54.1667 20.5566 54.1667 32.5003C54.1667 44.4441 44.4438 54.167 32.5001 54.167ZM44.9313 20.5295L27.0834 38.3774L20.0688 31.3899L16.2501 35.2087L27.0834 46.042L48.7501 24.3753L44.9313 20.5295Z"
                fill={_color}
            />
        </svg>
    );
};
export default CheckCircle;
