import { useEffect, useState } from "react";
import MainHeader from "../shared/mainHeader";
import DetailedSection from "./detailedAnalysis";
import TopOverview from "./topOverview/topOverview";
import { monitorAPI } from "components/shared/utils";
import customFetch from "components/shared/customFetch";
import { Navigate } from "react-router-dom";

const MonitorMetrics = () => {
    const [startDate, setStartDate] = useState(
        localStorage.getItem("startDate")
            ? parseInt(localStorage.getItem("startDate"))
            : new Date().setDate(new Date().getDate() - 8)
    );
    const [endDate, setEndDate] = useState(
        localStorage.getItem("endDate")
            ? parseInt(localStorage.getItem("endDate"))
            : new Date().setDate(new Date().getDate() - 1)
    );

    const [data, setData] = useState({});

    // Function to get data points for primary article
    const getData = async () => {
        const response = await customFetch(
            `${monitorAPI}/overview?startDate=${new Date(startDate).getFullYear()}-${
                new Date(startDate).getMonth() + 1 < 10
                    ? `0${new Date(startDate).getMonth() + 1}`
                    : new Date(startDate).getMonth() + 1
            }-${
                new Date(startDate).getDate() < 10
                    ? `0${new Date(startDate).getDate()}`
                    : new Date(startDate).getDate()
            }&endDate=${new Date(endDate).toISOString().split("T")[0]}`,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );
        // Storing data in form of JSON
        const data = await response.json();
        if (data.ok) {
            setData(data.data);

            // setLoading(false);
        }

        // setLoading(false);
    };
    useEffect(() => {
        localStorage.setItem("startDate", startDate);
    }, [startDate]);
    useEffect(() => {
        localStorage.setItem("endDate", endDate);
    }, [endDate]);
    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    const token = localStorage.getItem("accessToken");
    if (!token) return <Navigate to="/login" />;

    return (
        <div className="bg-black w-full min-h-full flex flex-col font-[Roboto]">
            <MainHeader />
            <TopOverview
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
                totalUsers={data?.dataForCurrentPeriod?.total_users}
                uniqueUsers={data?.dataForCurrentPeriod?.unique_clicks}
                topPerformingArticles={data?.dataForCurrentPeriod?.top_performing_content}
                totalClicks={data?.dataForCurrentPeriod?.total_clicks}
                difference={data?.differenceFromLastPeriod}
            />
            <DetailedSection data={data} difference={data?.differenceFromLastPeriod} />
        </div>
    );
};
export default MonitorMetrics;
