import { useEffect, useRef, useState } from "react";
import ReactECharts from "echarts-for-react";
import { chandlechartAPI, devServer } from "components/shared/utils";
import customFetch from "components/shared/customFetch";

const PPCsection = ({ selected, selectedArticle, selectedSimilar }) => {
    const [options, setOptions] = useState({});
    const [ohlc, setOhlc] = useState([]);
    const [data, setData] = useState([]);
    const [similarUrls, setSimilarUrls] = useState([]);
    const [similarPPCData, setSimilarPPCData] = useState({});
    const [reload, setReload] = useState(false);
    const chartsRef = useRef(null);
    const lineColors = ["#C9E7BC", "yellow", "orange"];

    const getMainPPC = async () => {
        try {
            const resp = await customFetch(`${devServer}/get-ppc`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    urls: [selectedArticle.urls[0]],
                }),
            });
            const res = await resp.json();

            if (res.ok) {
                setData(() => {
                    return res.data[Object.keys(res.data)[0]];
                });
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getSimilarPPC = async () => {
        try {
            const resp = await customFetch(`${devServer}/get-ppc`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    urls: [...similarUrls],
                }),
            });
            const res = await resp.json();

            if (res.ok) {
                setSimilarPPCData(() => {
                    return similarUrls.map((item) => res.data[item]);
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (selectedArticle?.urls.length > 0) {
            getMainPPC();
        } else {
            setOhlc([]);
        }
    }, [selectedArticle]);

    useEffect(() => {
        setSimilarUrls(() => {
            return selectedSimilar.map((item) => item.urls[0]);
        });
    }, [selectedSimilar]);

    useEffect(() => {
        if (similarUrls.length > 0) {
            getSimilarPPC();
        } else {
            setSimilarPPCData({});
        }
    }, [similarUrls]);

    useEffect(() => {
        if (data?.length > 0) {
            const temp = [];
            for (let i = 0; i < 30; i++) {
                let open;
                let close;
                let highest;
                let lowest;
                for (let j = 0; j < data.length / 30; j++) {
                    if (j === 0) {
                        open = data[i * parseInt(data.length / 30) + j].value;
                        close = data[i * parseInt(data.length / 30) + j].value;
                        highest = data[i * parseInt(data.length / 30) + j].value;
                        lowest = data[i * parseInt(data.length / 30) + j].value;
                    } else {
                        close = data[i * parseInt(data.length / 30) + j].value;
                        if (data[i * parseInt(data.length / 30) + j].value > highest) {
                            highest = data[i * parseInt(data.length / 30) + j].value;
                        }
                        if (data[i * parseInt(data.length / 30) + j].value < lowest) {
                            lowest = data[i * parseInt(data.length / 30) + j].value;
                        }
                    }
                }
                const baseDate = new Date(selectedArticle?.publishedAt);
                const row = [
                    baseDate.setSeconds(
                        baseDate.getSeconds() + data[i * parseInt(data.length / 30)].time
                    ),
                    (open / 91.11).toFixed(2),
                    (close / 91.11).toFixed(2),
                    (highest / 91.11).toFixed(2),
                    (lowest / 91.11).toFixed(2),
                ];
                temp.push(row);
            }
            setOhlc(temp);
        }
    }, [data]);
    useEffect(() => {
        setReload(true);
    }, [selectedArticle]);

    useEffect(() => {
        setOptions(() => {
            return {
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: true,
                },
                tooltip: {
                    trigger: "item",
                },
                xAxis: [
                    {
                        type: "time",
                        boundaryGap: ["5%", "5%"],

                        axisLabel: {
                            color: "#999",
                        },
                        axisLine: { show: false },
                        axisTick: { show: false },
                    },
                    ...Object.keys(similarPPCData).map((item, i) => {
                        return {
                            type: "time",
                            boundaryGap: ["5%", "5%"],
                            // data: similarPPCData[item].map((item) => item.time),
                            axisLine: { show: false },
                            axisTick: { show: false },
                            axisLabel: {
                                show: false,
                            },
                        };
                    }),
                ],
                yAxis: [
                    {
                        boundaryGap: ["5%", "0%"],
                        min: (value) => {
                            return Math.floor(value.min * 0.85);
                        },

                        type: "value",
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.1)",
                            },
                            axisLabel: {
                                color: "#999",
                                showMinLabel: false,
                            },
                        },
                    },
                    ...Object.keys(similarPPCData).map((item, i) => {
                        return {
                            type: "value",
                            boundaryGap: ["5%", "5%"],
                            // data: similarPPCData[item].map((item) => item.value),
                            axisLine: { show: false },
                            axisTick: { show: false },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                show: false,
                            },
                        };
                    }),
                ],

                series: [
                    {
                        type: "candlestick",
                        // colorBy: "series",
                        barWidth: "20%",
                        barMaxWidth: 40,
                        yAxisIndex: 0,
                        xAxisIndex: 0,
                        x: "date",
                        y: ["open", "close", "highest", "lowest"],
                        itemStyle: {
                            color0: "#F46A6A",
                            color: "#92F6A8",
                            borderColor0: "#F46A6A",
                            borderColor: "#92F6A8",
                        },
                        zlevel: 4,
                        tooltip: {
                            backgroundColor: "black",
                            borderColor: "#383838",
                            formatter: function (param) {
                                return `<div class="text-base text-white">${new Date(
                                    param.data[0]
                                ).toLocaleString()}</div>

                                    <div class="flex items-center justify-between text-base text-white"><div>Open</div><div>$${
                                        param.data[1]
                                    }</div></div>
                                     <div class="flex items-center justify-between text-base text-white"><div>Close</div><div>$${
                                         param.data[2]
                                     }</div></div>
                                     <div class="flex items-center justify-between text-base text-white"><div>High</div><div>$${
                                         param.data[3]
                                     }</div></div>
                                     <div class="flex items-center justify-between text-base text-white"><div>Low</div><div>$${
                                         param.data[4]
                                     }</div></div>
                                    `;
                            },
                        },
                    },
                    ...Object.keys(similarPPCData).map((item, i) => {
                        return {
                            type: "line",
                            data: similarPPCData[item].map((item) => [
                                item.time,
                                item.value / 91.11,
                            ]),

                            yAxisIndex: i + 1,
                            xAxisIndex: i + 1,
                            symbol: "circle",
                            symbolSize: 2,
                            zlevel: 1,
                            lineStyle: {
                                // color: lineColors[i],
                                opacity: selectedSimilar.length > i ? 0.75 : 0,
                            },
                            itemStyle: {
                                // color: lineColors[i],
                                opacity: selectedSimilar.length > i ? 0.75 : 0,
                            },
                            tooltip: {
                                backgroundColor: "black",
                                borderColor: "#383838",
                                formatter: function (param) {
                                    const shownDate = new Date(
                                        selectedSimilar[i]?._source?.publishedAt
                                    );
                                    shownDate.setSeconds(shownDate.getSeconds() + param.data[0]);

                                    return `<div class="text-base text-white">${shownDate?.toLocaleString()}</div>
    
                                        <div class="flex items-center text-white justify-between text-base"><div>Value</div><div>$${param.data[1].toFixed(
                                            2
                                        )}</div></div>
                                        `;
                                },
                            },
                        };
                    }),
                ],

                dataset: {
                    source: ohlc,
                    dimensions: ["date", "open", "close", "highest", "lowest"],
                },
                // // color: ["#92f6a8", "#df8bcd", "#249bed"],
            };
        });
    }, [ohlc, selected, data, similarPPCData, selectedSimilar]);
    useEffect(() => {
        if (reload) {
            setTimeout(() => {
                setReload(false);
            }, 100);
        }
    }, [reload]);

    return (
        <div className="flex-[3_10rem] w-11/12 overflow-auto flex flex-col gap-4 p-4">
            <div className="px-4 py-3 text-white flex flex-col gap-2  justify-center bg-[#141414] rounded ">
                <div className="text-2xl">
                    {selectedArticle?.title ? selectedArticle.title : ""}
                </div>
            </div>
            <div className="min-h-[30rem] flex-1 bg-[#141414] rounded gap-4 flex flex-col p-4">
                <div className="text-white font-bold text-2xl">PPC Overtime</div>
                <div className="flex-1">
                    {options && !reload && (
                        <ReactECharts
                            option={options}
                            ref={(e) => {
                                chartsRef.current = e;
                            }}
                            style={{
                                display: "block",
                                width: "100%",
                                height: "100%",
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
export default PPCsection;
