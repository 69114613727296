import React, { useEffect, useState } from "react";

import Header from "components/shared/Header/index";
import Footer from "components/shared/Footer";
import ArrowUp from "components/shared/icons/ArrowUpScroll";
import bg from "assests/images/bg2.png";
import { useLocation } from "react-router-dom";

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [showPaywindowVersion, setShowPaywindow] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (location.hash === "#paywindow") {
            setShowPaywindow(true);
        }
    }, []);

    return (
        <div
            className="flex flex-1 min-h-full w-full flex-col text-white relative"
            style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
            }}
        >
            <Header />

            <div className="flex flex-1 flex-col items-center my-16">
                <button
                    onClick={() => window.scrollTo(0, 0)}
                    className="fixed bottom-24 right-10 rounded-full bg-slate-50 z-[500]"
                >
                    <ArrowUp />
                </button>
                <h1 className="mb-2 text-2xl font-bold lg:text-5xl text-blue2">Privacy Policy</h1>
                <p className="mb-12 text-center text-sm text-gray-300 w-full max-w-505">
                    (Last Updated on 15/09/2023)
                </p>
                <div className="w-full text-base max-w-800 text-gray2 mb-14 px-4 lg:px-0">
                    {!showPaywindowVersion ? (
                        <div>
                            <p className="mb-4">
                                By using the KUNATO website, platform, mobile application, products,
                                and/or services (collectively, the “Services”), you consent to our
                                terms of service, as well as this privacy policy.
                            </p>
                            <p className="mb-4">
                                Your right to privacy and control over your personal information is
                                important to us at KUNATO. To ensure that you understand how we
                                collect and use personal data as part of the Services, please note
                                the information below.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Scope of this Policy</h6>
                                Any natural, juridical, or legal entity who has visited the KUNATO
                                platform through its website or mobile application or using its
                                products or services, has visited a third-party platform through its
                                website or mobile application using KUNATO’s products or services,
                                and/or who may or may not have further agreed to initiate the
                                process of opening an account with KUNATO or the third-party
                                platform shall come under the purview of the scope of this Privacy
                                Policy.
                            </p>
                            <p className="mb-4">
                                KUNATO shall at all times follow all such rules prescribed under the
                                rules & procedural guidelines set by the laws of the State of
                                Delaware, as well as by other countries such as India (Information
                                Technology Act, 2000), and/or any of its regulated
                                entities/intermediaries that KUNATO is a member of.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Age Declaration </h6>
                                You affirm that you are over the age of 18, as the platform,
                                product(s), or service(s) are not intended for children under 18. IF
                                YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, OR THE LEGAL AGE OF
                                MAJORITY WHERE YOU RESIDE IF THAT JURISDICTION HAS AN OLDER AGE OF
                                MAJORITY, THEN YOU AGREE TO REVIEW THESE TERMS WITH YOUR PARENT OR
                                GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN
                                UNDERSTAND AND AGREE TO THESE TERMS. YOU AGREE TO HAVE YOUR PARENT
                                OR GUARDIAN REVIEW AND ACCEPT THESE TERMS ON YOUR BEHALF. IF YOU ARE
                                A PARENT OR GUARDIAN AGREEING TO THE TERMS FOR THE BENEFIT OF A
                                CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR
                                THAT CHILD’S USE OF THE PLATFORM, PRODUCT(s), OR SERVICE(s),
                                INCLUDING ALL FINANCIAL CHARGES AND LEGAL LIABILITY THAT HE OR SHE
                                MAY INCUR.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Collection and use of your personal information{" "}
                                </h6>
                                KUNATO may or may not collect your Personal Information to provide
                                its services on any web-based platform or mobile application. While
                                opening an account with KUNATO, you may be asked for certain
                                Sensitive Personal Data or Information (as defined under applicable
                                laws the Information Technology Act, 2000).
                            </p>
                            <p className="mb-4">
                                KUNATO, may or may not, request sensitive information from You at
                                the time of account opening, which would include but not be limited
                                to, (i) setting a password; (ii) information such as name, contact
                                number, gender, date of birth, etc. All such information would be
                                for identity verification and background checking, personalizing
                                services offered to you, keeping you updated on our products and
                                services relevant to you, information on our services you’ve
                                subscribed to, and for any legal and regulatory / audit purposes.
                            </p>
                            <p className="mb-4">
                                Under Regulation 5 of the Information Technology (Reasonable
                                Security and Procedures and Sensitive Personal Data or Information)
                                Rules, 2011, KUNATO is obliged to provide every registered user of
                                KUNATO with a readable copy of the personal information you have
                                provided us.
                            </p>
                            <p className="mb-4">
                                We would save such information as required by the law, and as
                                directed by the regulators along with such intermediaries regulated
                                by them, to offer You our services, and for further regulatory,
                                audit, and business purposes. We collect Personal Information from
                                you only when you voluntarily use the services provided by KUNATO,
                                and/or enquire to begin an association with KUNATO, and/or complete
                                the process of opening an account with KUNATO.
                            </p>
                            <p className="mb-4">
                                Concerning security, we respect all information provided to us and
                                take all reasonable steps toward the protection of the same. We have
                                implemented technology and policies, intending to protect your
                                privacy from unauthorized access and improper use, and periodically
                                review the same. We maintain procedural safeguards to protect the
                                confidentiality and security of personally identifiable information
                                transmitted to us.
                            </p>

                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Information We Collect</h6>
                                We receive several types of information about you from various
                                sources, including
                                <br />
                                (A) Information and content that you give us;
                                <br />
                                (B) Automatically collected information;
                                <br />
                                (C) Demographic information or other aggregate information; and
                                <br />
                                (D) Information from other sources.
                            </p>
                            <p className="mb-4">Each is described in further detail below.</p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    A. Information and Content You Give Us
                                </h6>
                                We collect personal information that you knowingly choose to
                                disclose. This may include:
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(i) Personal Information:</span>{" "}
                                Personal information, such as your name, address, e-mail address,
                                username, password, and any other information you directly provide
                                us on or through our services. This includes information you provide
                                when you register or create an account or request customer service.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(ii) Email Correspondences:</span>{" "}
                                Records and copies of your email messages together with your email
                                address and our responses, if you choose to correspond with us
                                through email.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(iii) User Content:</span> Information
                                or content you submit to be published or displayed in public areas
                                of the services or transmitted to other users of the services or
                                third parties (collectively, "User Content"). Your User Content is
                                posted on and transmitted to others at your own risk. The Company
                                cannot control the actions of other users of the services with whom
                                you may choose to share your User Content. Therefore, we cannot and
                                do not guarantee that your User Content will not be viewed by
                                unauthorized persons.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(iv) Transaction Information:</span>{" "}
                                Information about any purchase or transactions made on the services.
                                This includes payment information, such as your credit or debit card
                                number and other card information; other account and authentication
                                information; and billing, shipping, and contact details.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    B. Information We Collect Automatically
                                </h6>
                                We may use a variety of technologies to collect certain information
                                about your equipment, browsing actions, and patterns whenever you
                                interact with the services, including:
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(i) Activity Information:</span>{" "}
                                Details of your visits to our services, including the types of
                                content you view or engage with; the features you use; the actions
                                you take; the people or accounts you interact with; the time,
                                frequency, and duration of your activities; and other information
                                about your use of and actions on the Services.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(ii) Equipment Information:</span>
                                Information about your computer and internet connection, including
                                your computer operating system, IP address, browser type, and
                                browser language.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold"> (iii) Location Information:</span>
                                Information about the location of your device, including GPS
                                location, for purposes of enhancing or facilitating the services.
                                For example, such information may be used to enable the
                                functionality or features of the services that provide you with
                                information about stores near you or enable you to remotely order
                                and pay for the services. We may also use information about the
                                location of the device you are using to help us understand how the
                                services and functionality are being used and to deliver more
                                relevant advertising.
                            </p>
                            <p className="mb-4">
                                The gathering of this information may be used for maintaining the
                                quality of the services we provide, as well as providing overall
                                general statistics related to the use of the services. The
                                technologies we use for this automatic data collection may include:
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold"> (i) Cookies:</span> A cookie is a
                                small data file stored on the hard drive of your computer either for
                                only the duration of your visit to a website ("session cookies") or
                                for a fixed period ("persistent cookies"). Cookies contain
                                information that can later be read by a web server. We may use
                                cookies to provide you with a more personal and interactive
                                experience with the Services.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(ii) Web Beacons:</span> Web beacons
                                are small files that are embedded in webpages, applications, and
                                emails (also known as "clear gifs", "pixel tags", "web bugs", and
                                "single-pixel gifs"). Web beacons allow the Company, for example, to
                                track who has visited those web pages or opened an email, to test
                                the effectiveness of our marketing, and for other related website
                                statistics.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(iii) JavaScripts:</span> JavaScripts
                                are code snippets embedded in various parts of websites and
                                applications that facilitate a variety of operations including
                                accelerating the refresh speed of certain functionality or
                                monitoring usage of various online components.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(iv) Entity Tags:</span> Entity Tags
                                are HTTP code mechanisms that allow portions of websites to be
                                stored or "cached" within your browser and validate these caches
                                when the website is opened, accelerating website performance since
                                the web server does not need to send a full response if the content
                                has not changed.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">(v) HTML5 Local Storage:</span> HTML5
                                local storage allows data from websites to be stored or "cached"
                                within your browser to store and retrieve data in HTML5 pages when
                                the website is revisited.
                            </p>
                            <p className="mb-4">
                                <span className=" font-bold">
                                    (vi) Resettable Device Identifiers:
                                </span>{" "}
                                Resettable device identifiers (also known as "advertising
                                identifiers") are similar to cookies and are found on many mobile
                                devices and tablets (for example, the "Identifier for Advertisers"
                                or "IDFA" on Apple iOS devices and the “Google Advertising ID” on
                                Android devices), and certain streaming media devices. Like cookies,
                                resettable device identifiers are used to make online advertising
                                more relevant.
                            </p>
                            <p className="mb-4">
                                This Policy does not cover the use of tracking technologies by third
                                parties. The services may contain links, content, advertising, or
                                references to other websites by third parties, including
                                advertisers, ad networks and servers, content providers, and
                                application providers. These third parties may use cookies or other
                                tracking technologies to collect information about you when you use
                                the services. The information they collect may be associated with
                                your personal information or they may collect information about your
                                online activities over time and across different websites. Please be
                                aware that we do not control these third parties tracking
                                technologies or when and how they may be used. Therefore, the
                                Company does not claim nor accept responsibility for any privacy
                                policies, practices, or procedures of any third party. We encourage
                                you to read the privacy statements and terms and conditions of
                                linked or referenced websites you enter. If you have any questions
                                about an ad or other targeted content, you should contact the
                                responsible provider directly.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    C. Demographic Information
                                </h6>
                                We may collect demographic, statistical, or other aggregate
                                information that is about you but individually does not identify
                                you. Some of this information may be derived from personal
                                information, but it is not personal information and cannot be tied
                                back to you. Examples of such aggregate information include gender,
                                age, race, household income, and political affiliation.
                            </p>
                            <p className="mb-1">
                                <h6 className="font-bold text-gray-300">
                                    How We Use Your Information
                                </h6>
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold">
                                    We may use the information we collect about you in a variety of
                                    ways, including to:
                                </h6>
                                <ol className=" list-decimal pl-10">
                                    <li>Provide the services and its content to you;</li>
                                    <li>
                                        Respond to comments and questions, and provide customer
                                        service;
                                    </li>
                                    <li>
                                        Fulfil any other purpose for which you provide such
                                        information;
                                    </li>
                                    <li>
                                        Communicate with you about your order, purchase, account, or
                                        subscription;
                                    </li>
                                    <li>
                                        Inform you about important changes to, or other news about,
                                        the services or any of its features or content;
                                    </li>
                                    <li>
                                        Operate, maintain, improve, personalize, and analyze the
                                        services;
                                    </li>
                                    <li>
                                        Monitor and analyze trends, usage, and activities for
                                        marketing or advertising purposes;
                                    </li>
                                    <li>
                                        Detect, prevent, or investigate security breaches, fraud,
                                        and other unauthorized or illegal activity;
                                    </li>
                                    <li>
                                        Maintain appropriate records for internal administrative
                                        purposes;
                                    </li>
                                    <li>
                                        Allow you to participate in interactive features on the
                                        services;
                                    </li>
                                    <li>
                                        Send promotional communications, such as information about
                                        features, newsletters, offers, promotions, contests, and
                                        events;
                                    </li>
                                    <li>
                                        Share information across the Company's products and devices
                                        to provide a more tailored and consistent experience on all
                                        Company products you use;
                                    </li>
                                    <li>
                                        Develop, test, and improve new products or services,
                                        including by conducting surveys and research, and testing
                                        and troubleshooting new products and features;
                                    </li>
                                    <li>
                                        Platform Analytics for fine-tuning the services as per the
                                        requirements of the users.
                                    </li>
                                </ol>
                            </p>
                            <p className="mb-1">
                                <h6 className="font-bold text-gray-300">
                                    How We Share Your Information
                                </h6>
                            </p>

                            <p className="mb-4">
                                We may disclose aggregated or anonymized information about our users
                                without any restrictions. We will not share the personal information
                                that we collect or you provide as described in this Policy except in
                                the following circumstances:
                            </p>
                            <p className="mb-4">
                                (A) With subsidiaries and affiliates for business purposes. To our
                                subsidiaries and affiliated companies for purposes of management and
                                analysis, decision-making, and other business purposes.
                            </p>
                            <p className="mb-4">
                                (B) When we work with service providers. To service providers,
                                contractors, and other third parties that provide us with support
                                services, such as credit card processing, website hosting, email and
                                postal delivery, location mapping, product and service delivery, or
                                analytics services, and who are bound by contractual obligations to
                                keep personal information confidential and use it only for the
                                purposes for which we disclose it to them.
                            </p>
                            <p className="mb-4">
                                (C) When we become involved in a sale or transfer of the Company. If
                                we become involved with a merger, divestiture, restructuring,
                                reorganization, dissolution, or other sale or transfer of some or
                                all of the Company's assets (whether as a going concern or as part
                                of bankruptcy, liquidation, or similar proceeding), to business
                                entities or people involved in the negotiation or transfer.
                            </p>
                            <p className="mb-4">
                                (D) When we are required by law. To comply with any court order,
                                law, or legal process, including responding to any government or
                                regulatory request.
                            </p>
                            <p className="mb-4">
                                (E) When we enforce our rights. To enforce or apply this Policy, our
                                Terms and Conditions, and other agreements, including for billing
                                and collection purposes.
                            </p>
                            <p className="mb-4">
                                (F) To help protect lawful interests. If we believe disclosure will
                                help us protect the rights, property, or safety of the Company, our
                                users, partners, agents, and others. This includes exchanging
                                information with other companies and organizations for fraud
                                protection, and spam and malware prevention.
                            </p>
                            <p className="mb-4">
                                (G) To fulfill the purpose for that information or with your
                                consent. To fulfill the purpose for which you provide the
                                information, for any other purpose disclosed by us when you provide
                                the information, or with your consent.
                            </p>
                            <p className="mb-4">
                                (H) When we work with marketing service providers. To marketing
                                service providers to assess, develop, and provide you with
                                promotions and special offers that may interest you, administer
                                contests, sweepstakes, and events, or for other promotional
                                purposes.
                            </p>
                            <p className="mb-4">
                                Information that you post on or through the public areas of the
                                services (e.g., chat rooms, bulletin boards, and discussion groups)
                                is generally accessible to, and may be collected and used by, others
                                and may result in unsolicited messages or other contacts from
                                others. Users of the services are encouraged to exercise caution
                                when providing personal information about themselves in public or
                                interactive areas
                            </p>

                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Mechanisms to Control Your Information.{" "}
                                </h6>
                            </p>

                            <p className="mb-4">
                                We strive to provide you with choices regarding the personal
                                information you provide to us. We have created mechanisms to provide
                                you with the following control over your information:
                            </p>

                            <p className="mb-1">
                                (i) Cookies & Other Tracking Technologies
                                <br />
                                You may be able to set your browser to reject cookies and certain
                                other technologies by adjusting the appropriate settings in your
                                browser. Each browser is different, but many common browsers have
                                preferences that may be adjusted to allow you to either accept or
                                reject cookies and certain other technologies before they are set or
                                installed or allow you to remove or reject the use or installation
                                of certain technologies altogether. We recommend that you refer to
                                the Help menu in your browser to learn how to modify your browser
                                settings. Please note that you cannot remove Flash cookies simply by
                                changing your browser settings. To learn how you can manage your
                                Flash cookie settings, visit the Flash player settings page on
                                Adobe's website. If you disable or refuse cookies, please note that
                                some parts of the Services may become inaccessible or may not
                                function properly.
                            </p>

                            <p className="mb-4">
                                (ii) Promotional Communications from the Company If you do not wish
                                to have your contact information used by the Company to promote our
                                own or third-party products or services, you can opt out by:
                                <br />
                                (i) informing us of your preference at the time you sign up for our
                                newsletter or complete any other form on or through the services
                                through which we collect your data;
                                <br />
                                (ii) modifying your user preferences in your account profile by
                                checking or unchecking the relevant boxes;
                                <br />
                                (iii) following the opt-out instructions in the promotional emails
                                we send you; or
                                <br />
                                (iv) sending us an email stating your request. Please note that if
                                you opt out of receiving promotional communications from us, we may
                                still send you transactional communications, including emails about
                                your account or purchases.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Accessing and Correcting Your Information
                                </h6>
                            </p>

                            <p className="mb-4">
                                You may send us an email to request access to, correct, or remove
                                any personal information that you have provided to us. You may also
                                access, correct, or remove your personal information by logging into
                                the Website and visiting your account profile page. We cannot delete
                                your personal information except by also deleting your account.
                            </p>
                            <p className="mb-4">
                                We may not accommodate a request to change information if we believe
                                the change would violate any law or legal requirement or cause the
                                information to be incorrect.{" "}
                            </p>
                            <p className="mb-4">
                                If you delete your User Content from the services or your account,
                                copies of your User Content may remain viewable in cached and
                                archived pages or might have been copied or stored by other users of
                                the services.
                            </p>

                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    How We Protect Your Information{" "}
                                </h6>
                            </p>
                            <p className="mb-4">
                                We take reasonable precautions to secure your personal information.
                                We have implemented technical, physical, and administrative security
                                measures designed to reduce the risk of loss, misuse, unauthorized
                                access, disclosure, or modification of your information. We have put
                                in place appropriate physical, electronic, and managerial procedures
                                to safeguard the information we collect. All information you provide
                                to us is stored on our secure servers behind firewalls. When you
                                transmit highly sensitive information (such as a credit card number)
                                through the services, we encrypt the transmission of that
                                information using Secure Sockets Layer (SSL) technology.
                            </p>
                            <p className="mb-4">
                                The safety and security of your information also depend on you.
                                Where we have given you (or where you have chosen) a password for
                                access to certain parts of the services, you are responsible for
                                keeping this password confidential. We ask you not to share your
                                password with anyone.
                            </p>
                            <p className="mb-4">
                                While we have employed security technologies and procedures to
                                assist in safeguarding your personal information, no system or
                                network can be guaranteed to be 100% secure. Unauthorized entry or
                                use, hardware or software failure, and other factors may compromise
                                the security of user information at any time. Any transmission of
                                personal information is at your own risk. We are not responsible for
                                the circumvention of any privacy settings or security measures
                                contained on the Website or any other services.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Terms of Use </h6>
                                If you choose to visit the services, your visit and any dispute over
                                privacy are subject to this Policy and our Terms and Conditions,
                                including limitations on damages, resolution of disputes, and
                                application of the law of the state of Delawar
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Data security </h6>
                                If you have created an account, access to this account is only
                                possible after entering your user ID and password, followed by
                                OTP(s) sent to your registered mobile number or email (optional).
                                You should always keep your access information confidential and
                                close the browser window when you stop communicating with us,
                                especially if you share your computer with others. In addition, we
                                use SSL (Secure Socket Layer) technology in connection with the
                                highest encryption level supported by your browser. In general, this
                                is 256-bit encryption. Firebase services encrypt data in transit
                                using HTTPS and are also encrypted in the database. Firebase
                                logically isolates customer data. In addition, we take appropriate
                                technical and organizational security measures to protect your data
                                against accidental or intentional manipulation, partial or total
                                loss, destruction, or unauthorized access by third parties. Our
                                security measures are continuously improved in line with
                                technological developments.
                            </p>
                            <p className="mb-4">
                                Although we take precautions intended to help protect the personal
                                information that we collect and process, no system or electronic
                                data transmission is completely secure. Any transmission of your
                                personal information is at your own risk, and we expect that you
                                will use appropriate security measures to protect your personal
                                information.
                            </p>
                            <p className="mb-4">
                                We may suspend your use of all or part of the services without
                                notice if we suspect or detect any breach of security. You
                                understand and agree that we may deliver electronic notifications
                                about breaches of security to the email address that you provided to
                                us.
                            </p>
                            <p className="mb-4">
                                If you create an account, you are responsible for maintaining the
                                security of and the information in your account, including your
                                password.
                            </p>
                            <p>
                                For further information on Privacy, you may contact us at:{" "}
                                <a
                                    href="mailto:contact@qx.live"
                                    className="text-blue2 cursor-pointer"
                                >
                                    support@kunato.io{" "}
                                </a>
                            </p>
                        </div>
                    ) : (
                        <div id="paywindow">
                            <h1 className="my-6 text-xl font-bold lg:text-5xl text-blue2">
                                Paywindow
                            </h1>
                            <p className="mb-4">
                                This Privacy Policy states the manner in which Kunato ("we", "us",
                                or "our") collects, processes, and uses your data. By accessing and
                                using our services and products, you ("user", "you") implicitly
                                agree to the procedures and conditions described herein.
                            </p>

                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Information Collection:</h6>
                                We collect names, email addresses and other personal data for
                                account creation and personalized services. For premium content
                                payments, we use third parties like Stripe and don't store your
                                financial information. We also gather data on content published, but
                                activities outside our platform aren't monitored.
                            </p>
                            <p className="mb-4">
                                We collect names, email addresses and other personal data for
                                account creation and personalized services. For premium content
                                payments, we use third parties like Stripe and don't store your
                                financial information. We also gather data on content published, but
                                activities outside our platform aren't monitored.
                            </p>

                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Purposes of Data Processing:
                                </h6>
                                We use collected data to improve our services, such as the PayWindow
                                feature. We also communicate important service updates to our users.
                                Sometimes, data is used to ensure security, prevent fraud, or meet
                                legal requirements
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Data Sharing and Disclosure:
                                </h6>
                                We don’t sell or trade your personal information. Occasionally, we
                                might share data with trusted partners for operational needs. If
                                legally required or to protect our rights, we might disclose certain
                                data
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Data Protection Measures:
                                </h6>
                                We prioritize data security and use advanced protection methods. We
                                keep data only as long as needed for operations, legal requirements,
                                or record-keeping.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    User Rights and Autonomy:
                                </h6>
                                You can access and edit your personal data. You can also set
                                communication preferences and opt out of specific messages. Account
                                deletion is possible but might be subject to regulatory rules.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Cookies:</h6>
                                We prioritize real-time content valuation and refrain from employing
                                invasive tracking tools or unnecessary cookies.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    External Sites and Third-Party Links:
                                </h6>
                                Our platform may feature links to third-party entities. As we don't
                                exercise control over their content or practices, we urge users to
                                peruse their respective policies prior to engagement.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Payments and Third-Party Processors:
                                </h6>
                                Financial operations are entrusted to third-party entities like
                                Stripe. Users are encouraged to familiarize themselves with these
                                entities' respective privacy protocols.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    AI-Driven Content Valuation:
                                </h6>
                                Our proprietary systems, such as QMV, deploy algorithms for content
                                valuation. While precision is sought, the possibility of
                                discrepancies cannot be wholly eliminated. Users are advised to
                                exercise prudence.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Intellectual Property Adherence:
                                </h6>
                                Users must ensure they don’t infringe upon third-party intellectual
                                property rights. Unauthorized usage may lead to legal ramifications.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Personal Information Responsibility:
                                </h6>
                                While we are committed to maintaining data privacy, users are
                                equally responsible for refraining from disseminating sensitive
                                information indiscriminately.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Service Availability and Continuity:
                                </h6>
                                : While we strive for uninterrupted service provision, periodic
                                downtimes, whether scheduled or unforeseen, might occur. We disclaim
                                liability for any consequential disruptions.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Policy Modifications:</h6>
                                We reserve the right to amend this Privacy Policy intermittently.
                                Continued use post modifications constitutes acceptance of said
                                changes.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">International Transfer:</h6>
                                Kunato may transfer your personal information to countries other
                                than the country in which it was collected. This may occur when, for
                                example, our servers are in a different country than you. By using
                                our website and providing us with your personal information, you
                                consent to the transfer of your information to other countries.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">DMCA Notice:</h6>
                                In alignment with the Digital Millennium Copyright Act of 1998 (the
                                “DMCA”), we have earmarked an agent for receiving notifications
                                concerning alleged copyright infringements. We are committed to
                                respecting copyright laws and adhere to the DMCA's notice and
                                takedown procedures. Address such concerns to our DMCA agent at{" "}
                                <a
                                    href="mailto:contact@qx.live"
                                    className="text-blue2 cursor-pointer"
                                >
                                    support@kunato.io{" "}
                                </a>
                                , marking the subject as “Notice of Infringement”.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Governing Law & Dispute Resolution:
                                </h6>
                                This Privacy Policy, its interpretation, execution, or any alleged
                                breach thereof, along with your interaction with the Kunato website,
                                is overseen by the laws of the State of New York, USA, without
                                invoking conflict of laws principles. Disputes or claims arising
                                from or in connection to this Privacy Policy will be exclusively
                                resolved in competent courts within the State of New York. Your
                                agreement to these terms implies an irrevocable consent to the
                                exclusive jurisdiction of these courts and a waiver to any objection
                                regarding venue or inconvenient forum.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">
                                    Modifications to Privacy Policy
                                </h6>
                                Kunato, at its discretion, retains the right to modify or replace
                                this Privacy Policy. The criteria for what is deemed a significant
                                modification lies solely with us. Users are advised to periodically
                                review this policy for any updates.
                            </p>
                            <p className="mb-4">
                                <h6 className="font-bold text-gray-300">Contact and Grievances:</h6>
                                For any inquiries, clarifications, or grievances about this Privacy
                                Policy, we encourage users to contact us at{" "}
                                <a
                                    href="mailto:contact@qx.live"
                                    className="text-blue2 cursor-pointer"
                                >
                                    support@kunato.io{" "}
                                </a>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Privacy;
