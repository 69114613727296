const CountriesCentroid = [
    {
        longitude: "-170.7007316174498",
        latitude: "-14.305711987770538",
        country: "American Samoa",
        iso: "AS",
    },
    {
        longitude: "166.63800339749642",
        latitude: "19.302045812215958",
        country: "United States Minor Outlying Islands",
        iso: "UM",
    },
    {
        longitude: "-159.78768870952257",
        latitude: "-21.222613253399842",
        country: "Cook Islands",
        iso: "CK",
    },
    {
        longitude: "-149.40041671099763",
        latitude: "-17.674684080120677",
        country: "French Polynesia",
        iso: "PF",
    },
    {
        longitude: "-169.86878106699083",
        latitude: "-19.05230921680393",
        country: "Niue",
        iso: "NU",
    },
    {
        longitude: "-128.3149848627581",
        latitude: "-24.366121747565458",
        country: "Pitcairn",
        iso: "PN",
    },
    {
        longitude: "-172.44107655740137",
        latitude: "-13.634252953274622",
        country: "Samoa",
        iso: "WS",
    },
    {
        longitude: "-171.85265950722743",
        latitude: "-9.195174767256544",
        country: "Tokelau",
        iso: "TK",
    },
    {
        longitude: "-175.20415878511247",
        latitude: "-21.15927212823853",
        country: "Tonga",
        iso: "TO",
    },
    {
        longitude: "-178.12735555777184",
        latitude: "-14.283442307826677",
        country: "Wallis and Futuna",
        iso: "WF",
    },
    {
        longitude: "-88.85911489238985",
        latitude: "13.758041517055418",
        country: "El Salvador",
        iso: "SV",
    },
    {
        longitude: "-90.31219349119617",
        latitude: "15.820878515352684",
        country: "Guatemala",
        iso: "GT",
    },
    {
        longitude: "-101.55399731630118",
        latitude: "23.87436068093592",
        country: "Mexico",
        iso: "MX",
    },
    {
        longitude: "-98.41680517868062",
        latitude: "57.550480044655636",
        country: "Canada",
        iso: "CA",
    },
    {
        longitude: "-64.53238503843076",
        latitude: "-35.697270518120085",
        country: "Argentina",
        iso: "AR",
    },
    {
        longitude: "-58.746646363799854",
        latitude: "-51.75901312766726",
        country: "Falkland Islands",
        iso: "FK",
    },
    {
        longitude: "-70.76863431739216",
        latitude: "-37.82938283049967",
        country: "Chile",
        iso: "CL",
    },
    {
        longitude: "-78.4630326109714",
        latitude: "-1.5642721388853116",
        country: "Ecuador",
        iso: "EC",
    },
    {
        longitude: "-74.11416196781884",
        latitude: "-8.522717984240291",
        country: "Peru",
        iso: "PE",
    },
    {
        longitude: "-64.45209597511206",
        latitude: "-16.7312488393574",
        country: "Bolivia",
        iso: "BO",
    },
    {
        longitude: "-54.355206608256424",
        latitude: "-11.524630416426652",
        country: "Brazil",
        iso: "BR",
    },
    {
        longitude: "-58.38906357428651",
        latitude: "-23.42190559259428",
        country: "Paraguay",
        iso: "PY",
    },
    {
        longitude: "-56.01919523458085",
        latitude: "-32.78195043831093",
        country: "Uruguay",
        iso: "UY",
    },
    {
        longitude: "-36.77509575898928",
        latitude: "-54.37666443862139",
        country: "South Georgia and South Sandwich Islands",
        iso: "GS",
    },
    {
        longitude: "-177.56451613408842",
        latitude: "-77.16987521415838",
        country: "Antarctica",
        iso: "AQ",
    },
    {
        longitude: "177.98144613732626",
        latitude: "-17.822470952336204",
        country: "Fiji",
        iso: "FJ",
    },
    {
        longitude: "-5.717391620813109",
        latitude: "-15.962963318340398",
        country: "Saint Helena",
        iso: "SH",
    },
    {
        longitude: "-63.06008343771806",
        latitude: "18.222874004219086",
        country: "Anguilla",
        iso: "AI",
    },
    {
        longitude: "-61.78530823226373",
        latitude: "17.07146759372967",
        country: "Antigua and Barbuda",
        iso: "AG",
    },
    {
        longitude: "-69.97564014284046",
        latitude: "12.515625722992898",
        country: "Aruba",
        iso: "AW",
    },
    {
        longitude: "-78.07275370060313",
        latitude: "24.72162633646784",
        country: "Bahamas",
        iso: "BS",
    },
    {
        longitude: "-59.557383949150285",
        latitude: "13.183219369337529",
        country: "Barbados",
        iso: "BB",
    },
    {
        longitude: "-88.68273510023441",
        latitude: "17.24252476647155",
        country: "Belize",
        iso: "BZ",
    },
    {
        longitude: "-64.7458500599169",
        latitude: "32.315067430740726",
        country: "Bermuda",
        iso: "BM",
    },
    {
        longitude: "-68.29350445958761",
        latitude: "12.180844982440338",
        country: "Bonaire",
        iso: "BQ",
    },
    {
        longitude: "-64.62406519257699",
        latitude: "18.42195819619707",
        country: "British Virgin Islands",
        iso: "VG",
    },
    {
        longitude: "-81.25203208977878",
        latitude: "19.311231805620288",
        country: "Cayman Islands",
        iso: "KY",
    },
    {
        longitude: "-72.6445066243485",
        latitude: "4.187753877352739",
        country: "Colombia",
        iso: "CO",
    },
    {
        longitude: "-84.14673625701816",
        latitude: "9.863467407406214",
        country: "Costa Rica",
        iso: "CR",
    },
    {
        longitude: "-79.69817857618705",
        latitude: "21.476176522869448",
        country: "Cuba",
        iso: "CU",
    },
    {
        longitude: "-68.96939768599042",
        latitude: "12.199996647939832",
        country: "Curacao",
        iso: "CW",
    },
    {
        longitude: "-61.360471946942994",
        latitude: "15.429269860940513",
        country: "Dominica",
        iso: "DM",
    },
    {
        longitude: "-70.43495198520012",
        latitude: "18.77954818522993",
        country: "Dominican Republic",
        iso: "DO",
    },
    {
        longitude: "-53.32232307156624",
        latitude: "3.857429742497078",
        country: "French Guiana",
        iso: "GF",
    },
    {
        longitude: "-61.67937937204098",
        latitude: "12.112926656338907",
        country: "Grenada",
        iso: "GD",
    },
    {
        longitude: "-61.54382262282755",
        latitude: "16.24420002705553",
        country: "Guadeloupe",
        iso: "GP",
    },
    {
        longitude: "-58.91352612754667",
        latitude: "4.68211981385056",
        country: "Guyana",
        iso: "GY",
    },
    {
        longitude: "-72.89291379842",
        latitude: "18.883520486983567",
        country: "Haiti",
        iso: "HT",
    },
    {
        longitude: "-86.49251679006962",
        latitude: "14.740370695750006",
        country: "Honduras",
        iso: "HN",
    },
    {
        longitude: "-77.30358894542778",
        latitude: "18.12207889341651",
        country: "Jamaica",
        iso: "JM",
    },
    {
        longitude: "-61.01432380875083",
        latitude: "14.642697353597645",
        country: "Martinique",
        iso: "MQ",
    },
    {
        longitude: "-62.18693281256255",
        latitude: "16.735363391460357",
        country: "Montserrat",
        iso: "MS",
    },
    {
        longitude: "-85.016088327669",
        latitude: "12.893566631930554",
        country: "Nicaragua",
        iso: "NI",
    },
    {
        longitude: "-80.14428761482796",
        latitude: "8.439536749576892",
        country: "Panama",
        iso: "PA",
    },
    {
        longitude: "-66.49425339593509",
        latitude: "18.216224086610914",
        country: "Puerto Rico",
        iso: "PR",
    },
    {
        longitude: "-63.23739481909494",
        latitude: "17.632512616389718",
        country: "Saba",
        iso: "BQ",
    },
    {
        longitude: "-62.83051610005156",
        latitude: "17.90561691241738",
        country: "Saint Barthelemy",
        iso: "BL",
    },
    {
        longitude: "-62.978230589445026",
        latitude: "17.4919042294197",
        country: "Saint Eustatius",
        iso: "BQ",
    },
    {
        longitude: "-62.74560385895787",
        latitude: "17.314736299587768",
        country: "Saint Kitts and Nevis",
        iso: "KN",
    },
    {
        longitude: "-60.9689510935251",
        latitude: "13.895749185129906",
        country: "Saint Lucia",
        iso: "LC",
    },
    {
        longitude: "-63.06678525361946",
        latitude: "18.078012113224464",
        country: "Saint Martin",
        iso: "MF",
    },
    {
        longitude: "-56.32465353437558",
        latitude: "46.95153913615198",
        country: "Saint Pierre and Miquelon",
        iso: "PM",
    },
    {
        longitude: "-61.193766354393034",
        latitude: "13.254808122970651",
        country: "Saint Vincent and the Grenadines",
        iso: "VC",
    },
    {
        longitude: "-63.06883135915303",
        latitude: "18.03942608463078",
        country: "Sint Maarten",
        iso: "SX",
    },
    {
        longitude: "-55.855514311561286",
        latitude: "4.098723595920171",
        country: "Suriname",
        iso: "SR",
    },
    {
        longitude: "-61.37236579976247",
        latitude: "10.415515638298093",
        country: "Trinidad and Tobago",
        iso: "TT",
    },
    {
        longitude: "-71.74058946811508",
        latitude: "21.799865427483745",
        country: "Turks and Caicos Islands",
        iso: "TC",
    },
    {
        longitude: "-64.76155341409797",
        latitude: "17.738009708772523",
        country: "US Virgin Islands",
        iso: "VI",
    },
    {
        longitude: "-66.36492135985132",
        latitude: "7.148324760507107",
        country: "Venezuela",
        iso: "VE",
    },
    {
        longitude: "-1.6932816211842325",
        latitude: "12.108709036312737",
        country: "Burkina Faso",
        iso: "BF",
    },
    {
        longitude: "-23.63401005900474",
        latitude: "15.076411518651643",
        country: "Cabo Verde",
        iso: "CV",
    },
    {
        longitude: "-5.571710194917734",
        latitude: "7.536779279421307",
        country: "Côte d'Ivoire",
        iso: "CI",
    },
    {
        longitude: "-15.383380385869662",
        latitude: "13.428617959189328",
        country: "Gambia",
        iso: "GM",
    },
    {
        longitude: "-1.219233362526581",
        latitude: "7.94530467243628",
        country: "Ghana",
        iso: "GH",
    },
    {
        longitude: "-5.345549484594358",
        latitude: "36.14022671336082",
        country: "Gibraltar",
        iso: "GI",
    },
    {
        longitude: "-10.986948848040218",
        latitude: "10.255986541378112",
        country: "Guinea",
        iso: "GN",
    },
    {
        longitude: "-14.980186756910847",
        latitude: "11.980075324820504",
        country: "Guinea-Bissau",
        iso: "GW",
    },
    {
        longitude: "-9.258988935497618",
        latitude: "6.52012979398834",
        country: "Liberia",
        iso: "LR",
    },
    {
        longitude: "-4.346399841781153",
        latitude: "17.168146208584837",
        country: "Mali",
        iso: "ML",
    },
    {
        longitude: "-10.495079045035716",
        latitude: "20.466731212820022",
        country: "Mauritania",
        iso: "MR",
    },
    {
        longitude: "-8.817212587250811",
        latitude: "28.687598134979325",
        country: "Morocco",
        iso: "MA",
    },
    {
        longitude: "-7.933662183874006",
        latitude: "39.67529214702138",
        country: "Portugal",
        iso: "PT",
    },
    {
        longitude: "-14.610875368352305",
        latitude: "14.228861491763402",
        country: "Senegal",
        iso: "SN",
    },
    {
        longitude: "-11.78656695731115",
        latitude: "8.561330384750587",
        country: "Sierra Leone",
        iso: "SL",
    },
    {
        longitude: "-42.07567788066985",
        latitude: "74.16847218965994",
        country: "Greenland",
        iso: "GL",
    },
    {
        longitude: "-2.576392582891568",
        latitude: "49.45870771378872",
        country: "Guernsey",
        iso: "GG",
    },
    {
        longitude: "-8.241128545554096",
        latitude: "53.30489539816495",
        country: "Ireland",
        iso: "IE",
    },
    {
        longitude: "-4.532995055468449",
        latitude: "54.22855301008011",
        country: "Isle of Man",
        iso: "IM",
    },
    {
        longitude: "-2.1291601162653575",
        latitude: "49.215396925724306",
        country: "Jersey",
        iso: "JE",
    },
    {
        longitude: "-2.852943909329258",
        latitude: "53.97844735080214",
        country: "United Kingdom",
        iso: "GB",
    },
    {
        longitude: "-19.05682967106099",
        latitude: "65.12360920205514",
        country: "Iceland",
        iso: "IS",
    },
    {
        longitude: "-6.9811060913122835",
        latitude: "62.130896281495346",
        country: "Faroe Islands",
        iso: "FO",
    },
    {
        longitude: "16.036378851505283",
        latitude: "78.57318936469626",
        country: "Svalbard",
        iso: "SJ",
    },
    {
        longitude: "3.411969465057627",
        latitude: "-54.42316679395248",
        country: "Bouvet Island",
        iso: "BV",
    },
    {
        longitude: "170.69035541428696",
        latitude: "-43.82765432544426",
        country: "New Zealand",
        iso: "NZ",
    },
    {
        longitude: "17.651768783079",
        latitude: "-12.167424062667942",
        country: "Angola",
        iso: "AO",
    },
    {
        longitude: "23.85779956995608",
        latitude: "-22.236609002062902",
        country: "Botswana",
        iso: "BW",
    },
    {
        longitude: "29.88518227845293",
        latitude: "-3.261251993278643",
        country: "Burundi",
        iso: "BI",
    },
    {
        longitude: "43.34826587429403",
        latitude: "-11.658861474508491",
        country: "Comoros",
        iso: "KM",
    },
    {
        longitude: "14.879732849491393",
        latitude: "-0.7294391595233845",
        country: "Congo",
        iso: "CG",
    },
    {
        longitude: "23.419827574282188",
        latitude: "-3.338629596207896",
        country: "Congo DRC",
        iso: "CD",
    },
    {
        longitude: "11.839410898545754",
        latitude: "-0.628448459921234",
        country: "Gabon",
        iso: "GA",
    },
    {
        longitude: "37.95309411262371",
        latitude: "0.6899182318376179",
        country: "Kenya",
        iso: "KE",
    },
    {
        longitude: "28.24475317864227",
        latitude: "-29.60168116924817",
        country: "Lesotho",
        iso: "LS",
    },
    {
        longitude: "34.23441182298881",
        latitude: "-13.128986464184024",
        country: "Malawi",
        iso: "MW",
    },
    {
        longitude: "42.74374761089645",
        latitude: "-17.06449193630804",
        country: "Juan De Nova Island",
        iso: "TF",
    },
    {
        longitude: "35.208577031290176",
        latitude: "-17.525230309488748",
        country: "Mozambique",
        iso: "MZ",
    },
    {
        longitude: "18.16451345845268",
        latitude: "-21.90858163281473",
        country: "Namibia",
        iso: "NA",
    },
    {
        longitude: "29.919439681764082",
        latitude: "-2.014687460047154",
        country: "Rwanda",
        iso: "RW",
    },
    {
        longitude: "6.606158796857607",
        latitude: "0.22744704294793774",
        country: "Sao Tome and Principe",
        iso: "ST",
    },
    {
        longitude: "24.75252746489084",
        latitude: "-28.55361930679731",
        country: "South Africa",
        iso: "ZA",
    },
    {
        longitude: "31.510685746082007",
        latitude: "-26.562540935608702",
        country: "Eswatini",
        iso: "SZ",
    },
    {
        longitude: "34.81832206060381",
        latitude: "-6.355794440041147",
        country: "Tanzania",
        iso: "TZ",
    },
    {
        longitude: "27.75521363430896",
        latitude: "-13.162832953186246",
        country: "Zambia",
        iso: "ZM",
    },
    {
        longitude: "29.717829640720844",
        latitude: "-18.92700121981475",
        country: "Zimbabwe",
        iso: "ZW",
    },
    {
        longitude: "72.43501618476016",
        latitude: "-7.323548444385743",
        country: "British Indian Ocean Territory",
        iso: "IO",
    },
    {
        longitude: "69.54686984724839",
        latitude: "-49.26329687105643",
        country: "French Southern Territories",
        iso: "TF",
    },
    {
        longitude: "73.49298560844045",
        latitude: "-53.084170035513736",
        country: "Heard Island and McDonald Islands",
        iso: "HM",
    },
    {
        longitude: "46.68493466832544",
        latitude: "-19.04163612493041",
        country: "Madagascar",
        iso: "MG",
    },
    {
        longitude: "57.56415671066546",
        latitude: "-20.28142317475198",
        country: "Mauritius",
        iso: "MU",
    },
    {
        longitude: "45.128142327031064",
        latitude: "-12.824468416301052",
        country: "Mayotte",
        iso: "YT",
    },
    {
        longitude: "47.290948081543384",
        latitude: "-11.566224871643417",
        country: "Glorioso Islands",
        iso: "TF",
    },
    {
        longitude: "55.54393506194689",
        latitude: "-21.119825460665105",
        country: "Réunion",
        iso: "RE",
    },
    {
        longitude: "55.47250789595527",
        latitude: "-4.660002318822744",
        country: "Seychelles",
        iso: "SC",
    },
    {
        longitude: "2.6558464719769135",
        latitude: "28.350969744889056",
        country: "Algeria",
        iso: "DZ",
    },
    {
        longitude: "2.305714528830206",
        latitude: "9.503013199615893",
        country: "Benin",
        iso: "BJ",
    },
    {
        longitude: "12.948474142398263",
        latitude: "6.294168487480992",
        country: "Cameroon",
        iso: "CM",
    },
    {
        longitude: "20.520743419397256",
        latitude: "6.331390033944319",
        country: "Central African Republic",
        iso: "CF",
    },
    {
        longitude: "18.427113900363025",
        latitude: "15.283493546654503",
        country: "Chad",
        iso: "TD",
    },
    {
        longitude: "10.425456672353823",
        latitude: "1.5954643936590733",
        country: "Equatorial Guinea",
        iso: "GQ",
    },
    {
        longitude: "-157.39024189323504",
        latitude: "1.8676673749241066",
        country: "Kiribati",
        iso: "KI",
    },
    {
        longitude: "17.91133392454237",
        latitude: "27.202915771690794",
        country: "Libya",
        iso: "LY",
    },
    {
        longitude: "14.441922442508494",
        latitude: "35.890522650899314",
        country: "Malta",
        iso: "MT",
    },
    {
        longitude: "8.86863247002646",
        latitude: "17.08105392407292",
        country: "Niger",
        iso: "NE",
    },
    {
        longitude: "8.147714845256194",
        latitude: "9.61029352034213",
        country: "Nigeria",
        iso: "NG",
    },
    {
        longitude: "0.8990857571109684",
        latitude: "8.660743037717811",
        country: "Togo",
        iso: "TG",
    },
    {
        longitude: "9.65587551697984",
        latitude: "34.08636179565723",
        country: "Tunisia",
        iso: "TN",
    },
    {
        longitude: "33.375346009199205",
        latitude: "35.11700416345239",
        country: "Cyprus",
        iso: "CY",
    },
    {
        longitude: "42.613496898789506",
        latitude: "11.750235727618804",
        country: "Djibouti",
        iso: "DJ",
    },
    {
        longitude: "30.240135435012338",
        latitude: "26.60517034450628",
        country: "Egypt",
        iso: "EG",
    },
    {
        longitude: "39.2672401449901",
        latitude: "15.005533147667684",
        country: "Eritrea",
        iso: "ER",
    },
    {
        longitude: "39.914902886544276",
        latitude: "8.729389557048396",
        country: "Ethiopia",
        iso: "ET",
    },
    {
        longitude: "23.110368936161876",
        latitude: "39.42012261727978",
        country: "Greece",
        iso: "GR",
    },
    {
        longitude: "43.832529181056884",
        latitude: "33.105075667527906",
        country: "Iraq",
        iso: "IQ",
    },
    {
        longitude: "35.027923472437024",
        latitude: "31.513542220043195",
        country: "Israel",
        iso: "IL",
    },
    {
        longitude: "36.95728884547246",
        latitude: "31.387064884449156",
        country: "Jordan",
        iso: "JO",
    },
    {
        longitude: "35.89651946324749",
        latitude: "33.91160170722086",
        country: "Lebanon",
        iso: "LB",
    },
    {
        longitude: "35.24251184154588",
        latitude: "31.930818736453883",
        country: "Palestinian Territory",
        iso: "PS",
    },
    {
        longitude: "30.3851856901788",
        latitude: "7.657782041763295",
        country: "South Sudan",
        iso: "SS",
    },
    {
        longitude: "29.951458283594064",
        latitude: "15.67060230984256",
        country: "Sudan",
        iso: "SD",
    },
    {
        longitude: "38.5117323139514",
        latitude: "35.09751106058316",
        country: "Syria",
        iso: "SY",
    },
    {
        longitude: "35.56886764076691",
        latitude: "38.93207363123396",
        country: "Turkey",
        iso: "TR",
    },
    {
        longitude: "32.34371768463123",
        latitude: "1.2821729218416205",
        country: "Uganda",
        iso: "UG",
    },
    {
        longitude: "1.5802243611232873",
        latitude: "42.54859834854764",
        country: "Andorra",
        iso: "AD",
    },
    {
        longitude: "-96.33161660829639",
        latitude: "38.8208089190304",
        country: "United States",
        iso: "US",
    },
    {
        longitude: "2.1940236627886227",
        latitude: "46.6423682169416",
        country: "France",
        iso: "FR",
    },
    {
        longitude: "9.547674672376376",
        latitude: "47.14627562133036",
        country: "Liechtenstein",
        iso: "LI",
    },
    {
        longitude: "7.412820873271196",
        latitude: "43.74798224995656",
        country: "Monaco",
        iso: "MC",
    },
    {
        longitude: "8.286928794895285",
        latitude: "46.73678128684938",
        country: "Switzerland",
        iso: "CH",
    },
    {
        longitude: "4.675010154696485",
        latitude: "50.6182138854095",
        country: "Belgium",
        iso: "BE",
    },
    {
        longitude: "10.426171427430804",
        latitude: "51.08304539800482",
        country: "Germany",
        iso: "DE",
    },
    {
        longitude: "6.103230338458876",
        latitude: "49.77523454542369",
        country: "Luxembourg",
        iso: "LU",
    },
    {
        longitude: "5.554136426128487",
        latitude: "52.134054128923886",
        country: "Netherlands",
        iso: "NL",
    },
    {
        longitude: "20.061082767269493",
        latitude: "41.14165894891656",
        country: "Albania",
        iso: "AL",
    },
    {
        longitude: "13.797778364631036",
        latitude: "47.631858269895794",
        country: "Austria",
        iso: "AT",
    },
    {
        longitude: "17.83467240787538",
        latitude: "44.14415356126429",
        country: "Bosnia and Herzegovina",
        iso: "BA",
    },
    {
        longitude: "16.625761129583374",
        latitude: "44.91192100856702",
        country: "Croatia",
        iso: "HR",
    },
    {
        longitude: "15.383273292023533",
        latitude: "49.74917370930982",
        country: "Czech Republic",
        iso: "CZ",
    },
    {
        longitude: "9.378670542409406",
        latitude: "56.00118817971057",
        country: "Denmark",
        iso: "DK",
    },
    {
        longitude: "19.39620048366142",
        latitude: "47.22527332486294",
        country: "Hungary",
        iso: "HU",
    },
    {
        longitude: "12.763657166123137",
        latitude: "42.98201127614267",
        country: "Italy",
        iso: "IT",
    },
    {
        longitude: "19.29505087156758",
        latitude: "42.73694835210454",
        country: "Montenegro",
        iso: "ME",
    },
    {
        longitude: "19.43573279234468",
        latitude: "52.06848055692473",
        country: "Poland",
        iso: "PL",
    },
    {
        longitude: "12.461278349581722",
        latitude: "43.942820729097896",
        country: "San Marino",
        iso: "SM",
    },
    {
        longitude: "20.85677444395745",
        latitude: "44.02679870131969",
        country: "Serbia",
        iso: "RS",
    },
    {
        longitude: "19.581015362490966",
        latitude: "48.69808390520484",
        country: "Slovakia",
        iso: "SK",
    },
    {
        longitude: "14.890636899973781",
        latitude: "46.13759229564504",
        country: "Slovenia",
        iso: "SI",
    },
    {
        longitude: "21.70998923872772",
        latitude: "41.59402890143112",
        country: "North Macedonia",
        iso: "MK",
    },
    {
        longitude: "12.451312917026133",
        latitude: "41.90402351316735",
        country: "Vatican City",
        iso: "VA",
    },
    {
        longitude: "16.670259272390894",
        latitude: "64.97775882947745",
        country: "Norway",
        iso: "NO",
    },
    {
        longitude: "17.062431988004956",
        latitude: "62.73420986108448",
        country: "Sweden",
        iso: "SE",
    },
    {
        longitude: "27.964252054715104",
        latitude: "53.46791374543163",
        country: "Belarus",
        iso: "BY",
    },
    {
        longitude: "25.251739122561908",
        latitude: "42.82043677302438",
        country: "Bulgaria",
        iso: "BG",
    },
    {
        longitude: "25.916870250633806",
        latitude: "58.648108311231034",
        country: "Estonia",
        iso: "EE",
    },
    {
        longitude: "25.65738433454702",
        latitude: "65.01578959749911",
        country: "Finland",
        iso: "FI",
    },
    {
        longitude: "43.378866534112234",
        latitude: "42.17986277737226",
        country: "Georgia",
        iso: "GE",
    },
    {
        longitude: "24.693671325654403",
        latitude: "56.813853047554154",
        country: "Latvia",
        iso: "LV",
    },
    {
        longitude: "23.946021605013534",
        latitude: "55.29437393417175",
        country: "Lithuania",
        iso: "LT",
    },
    {
        longitude: "28.391111865941348",
        latitude: "47.0725674580696",
        country: "Moldova",
        iso: "MD",
    },
    {
        longitude: "25.094158201563292",
        latitude: "45.82454894397586",
        country: "Romania",
        iso: "RO",
    },
    {
        longitude: "31.27377208442636",
        latitude: "48.657532515563794",
        country: "Ukraine",
        iso: "UA",
    },
    {
        longitude: "66.59216131095278",
        latitude: "34.13402601376932",
        country: "Afghanistan",
        iso: "AF",
    },
    {
        longitude: "50.540695402276775",
        latitude: "26.04798501537066",
        country: "Bahrain",
        iso: "BH",
    },
    {
        longitude: "81.17300408530181",
        latitude: "23.586300567746722",
        country: "India",
        iso: "IN",
    },
    {
        longitude: "54.237077001065444",
        latitude: "32.906023742890056",
        country: "Iran",
        iso: "IR",
    },
    {
        longitude: "47.56311109320184",
        latitude: "29.281360965443092",
        country: "Kuwait",
        iso: "KW",
    },
    {
        longitude: "73.10076245140479",
        latitude: "-0.6065577168009924",
        country: "Maldives",
        iso: "MV",
    },
    {
        longitude: "84.1338898313567",
        latitude: "28.300920699755657",
        country: "Nepal",
        iso: "NP",
    },
    {
        longitude: "55.841088119829",
        latitude: "20.7242833183209",
        country: "Oman",
        iso: "OM",
    },
    {
        longitude: "69.08835090769651",
        latitude: "30.116188371410882",
        country: "Pakistan",
        iso: "PK",
    },
    {
        longitude: "51.19794918743203",
        latitude: "25.318528486425386",
        country: "Qatar",
        iso: "QA",
    },
    {
        longitude: "44.600958178225596",
        latitude: "24.136038144757897",
        country: "Saudi Arabia",
        iso: "SA",
    },
    {
        longitude: "45.40037867243972",
        latitude: "6.524534573103924",
        country: "Somalia",
        iso: "SO",
    },
    {
        longitude: "80.66931169770622",
        latitude: "7.696630939329944",
        country: "Sri Lanka",
        iso: "LK",
    },
    {
        longitude: "70.94215281065698",
        latitude: "38.56933138382972",
        country: "Tajikistan",
        iso: "TJ",
    },
    {
        longitude: "58.4577357627054",
        latitude: "39.06069118001429",
        country: "Turkmenistan",
        iso: "TM",
    },
    {
        longitude: "54.27920525789581",
        latitude: "24.18250292309135",
        country: "United Arab Emirates",
        iso: "AE",
    },
    {
        longitude: "47.46815793206386",
        latitude: "16.001392616307445",
        country: "Yemen",
        iso: "YE",
    },
    {
        longitude: "45.05490831965259",
        latitude: "40.17841274230679",
        country: "Armenia",
        iso: "AM",
    },
    {
        longitude: "48.634592670644324",
        latitude: "40.3920509942049",
        country: "Azerbaijan",
        iso: "AZ",
    },
    {
        longitude: "66.3759193479301",
        latitude: "47.641465195176835",
        country: "Kazakhstan",
        iso: "KZ",
    },
    {
        longitude: "74.17532903468319",
        latitude: "41.35698905438358",
        country: "Kyrgyzstan",
        iso: "KG",
    },
    {
        longitude: "63.8548297593985",
        latitude: "41.4879065244633",
        country: "Uzbekistan",
        iso: "UZ",
    },
    {
        longitude: "105.70209512200549",
        latitude: "-10.446440802183416",
        country: "Christmas Island",
        iso: "CX",
    },
    {
        longitude: "96.83688767323002",
        latitude: "-12.171249450199545",
        country: "Cocos Islands",
        iso: "CC",
    },
    {
        longitude: "113.96538246847302",
        latitude: "0.15591979959037652",
        country: "Indonesia",
        iso: "ID",
    },
    {
        longitude: "125.95024049562659",
        latitude: "-8.809894630601962",
        country: "Timor-Leste",
        iso: "TL",
    },
    {
        longitude: "134.02277170916162",
        latitude: "-25.697337673983082",
        country: "Australia",
        iso: "AU",
    },
    {
        longitude: "166.92937633139178",
        latitude: "-0.5221021440668993",
        country: "Nauru",
        iso: "NR",
    },
    {
        longitude: "165.50767040438612",
        latitude: "-21.33003372660827",
        country: "New Caledonia",
        iso: "NC",
    },
    {
        longitude: "167.95259597483337",
        latitude: "-29.037654445046282",
        country: "Norfolk Island",
        iso: "NF",
    },
    {
        longitude: "144.8348942994695",
        latitude: "-7.156912819152135",
        country: "Papua New Guinea",
        iso: "PG",
    },
    {
        longitude: "160.16475795033884",
        latitude: "-9.613104734596515",
        country: "Solomon Islands",
        iso: "SB",
    },
    {
        longitude: "179.217833977593",
        latitude: "-8.514701506447222",
        country: "Tuvalu",
        iso: "TV",
    },
    {
        longitude: "166.84912735669738",
        latitude: "-15.189132121699332",
        country: "Vanuatu",
        iso: "VU",
    },
    {
        longitude: "105.03973078680701",
        latitude: "12.699186865507775",
        country: "Cambodia",
        iso: "KH",
    },
    {
        longitude: "103.76375899026448",
        latitude: "18.117282736873282",
        country: "Laos",
        iso: "LA",
    },
    {
        longitude: "114.63330303992869",
        latitude: "3.6716608556387857",
        country: "Malaysia",
        iso: "MY",
    },
    {
        longitude: "97.08892285397344",
        latitude: "19.901227931399873",
        country: "Myanmar",
        iso: "MM",
    },
    {
        longitude: "103.81025757634053",
        latitude: "1.3528251890006349",
        country: "Singapore",
        iso: "SG",
    },
    {
        longitude: "101.08675116214552",
        latitude: "13.66222784745538",
        country: "Thailand",
        iso: "TH",
    },
    {
        longitude: "105.91338832758704",
        latitude: "16.517347170839393",
        country: "Vietnam",
        iso: "VN",
    },
    {
        longitude: "90.43212562608613",
        latitude: "23.673728665121",
        country: "Bangladesh",
        iso: "BD",
    },
    {
        longitude: "90.46716647173861",
        latitude: "27.42163933959824",
        country: "Bhutan",
        iso: "BT",
    },
    {
        longitude: "104.69113855849604",
        latitude: "38.07325481105728",
        country: "China",
        iso: "CN",
    },
    {
        longitude: "114.6430958360464",
        latitude: "4.543205889917609",
        country: "Brunei Darussalam",
        iso: "BN",
    },
    {
        longitude: "121.82208941416745",
        latitude: "15.586542251094242",
        country: "Philippines",
        iso: "PH",
    },
    {
        longitude: "127.76224551357649",
        latitude: "36.402386712544114",
        country: "South Korea",
        iso: "KR",
    },
    {
        longitude: "103.3987360327455",
        latitude: "47.08644454604851",
        country: "Mongolia",
        iso: "MN",
    },
    {
        longitude: "127.3379805653744",
        latitude: "40.19198091470839",
        country: "North Korea",
        iso: "KP",
    },
    {
        longitude: "144.78024458298802",
        latitude: "13.445430479945276",
        country: "Guam",
        iso: "GU",
    },
    {
        longitude: "137.46934234351835",
        latitude: "36.76738832597829",
        country: "Japan",
        iso: "JP",
    },
    {
        longitude: "168.72016025351076",
        latitude: "7.307929900994344",
        country: "Marshall Islands",
        iso: "MH",
    },
    {
        longitude: "158.2291899444527",
        latitude: "6.8789448129255435",
        country: "Micronesia",
        iso: "FM",
    },
    {
        longitude: "145.74119737203134",
        latitude: "15.178063516432115",
        country: "Northern Mariana Islands",
        iso: "MP",
    },
    {
        longitude: "134.57965086721052",
        latitude: "7.534775852547396",
        country: "Palau",
        iso: "PW",
    },
    {
        longitude: "98.6704990698032",
        latitude: "59.039434214106194",
        country: "Russian Federation",
        iso: "RU",
    },
    {
        longitude: "-3.6516251409956983",
        latitude: "40.365008336683836",
        country: "Spain",
        iso: "ES",
    },
    {
        longitude: "-16.53799441021647",
        latitude: "28.297665106525546",
        country: "Canarias",
        iso: "ES",
    },
    {
        longitude: "24",
        latitude: "121",
        country: "Taiwan",
        iso: "TW",
    },
    // 23°58′25.9″N 120°58′55.2″E
];
export default CountriesCentroid;
