import TiltedArrow from "components/shared/icons/tiltedArrow";

const OverviewStats = ({
    totalUsers,
    uniqueUsers,
    topPerformingArticles,
    totalClicks,
    difference,
}) => {
    const shownArticles = topPerformingArticles?.slice(0, 3);
    return (
        <div className="border-b border-b-[#383838] flex flex-col lg:flex-row">
            <div className="flex-1 flex">
                <div className="px-5 lg:px-11 py-8 border-r border-r-[#383838] flex-1">
                    <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                        TOTAL NUMBER OF USERS
                    </div>
                    <div className="mt-[6.5625rem] text-white text-5xl font-[Poppins] font-semibold tracking-tight">
                        {totalUsers?.toLocaleString()}
                    </div>
                    {difference?.total_users && (
                        <div
                            className={
                                difference?.total_users > 0
                                    ? "mt-3 flex items-center gap-1 text-[#92f6a8]"
                                    : "mt-3 flex items-center gap-1 text-[#f46a6a]"
                            }
                        >
                            <div
                                className={
                                    difference?.total_users > 0
                                        ? "flex items-center justify-center"
                                        : "flex items-center justify-center rotate-90"
                                }
                            >
                                <TiltedArrow />
                            </div>
                            <div className="font-medium tracking-tight leading-5">
                                {difference?.total_users > 0 ? "+" : ""}
                                {Math.trunc(difference?.total_users)}% from last period
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-5 lg:px-11 py-8 border-r border-r-[#383838] flex-1">
                    <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                        UNIQUE NUMBER OF CONVERSIONS
                    </div>
                    <div className="mt-[6.5625rem] text-white text-5xl font-[Poppins] font-semibold tracking-tight">
                        {uniqueUsers?.toLocaleString()}
                    </div>
                    {difference?.unique_conversion && (
                        <div
                            className={
                                difference?.unique_conversion > 0
                                    ? "mt-3 flex items-center gap-1 text-[#92f6a8]"
                                    : "mt-3 flex items-center gap-1 text-[#f46a6a]"
                            }
                        >
                            <div
                                className={
                                    difference?.unique_conversion > 0
                                        ? "flex items-center justify-center"
                                        : "flex items-center justify-center rotate-90"
                                }
                            >
                                <TiltedArrow />
                            </div>
                            <div className="font-medium tracking-tight leading-5">
                                {difference?.unique_conversion > 0 ? "+" : ""}
                                {Math.trunc(difference?.unique_conversion)}% from last period
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-5 lg:px-11 py-8 border-r border-r-[#383838] flex-1 hidden lg:block">
                    <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                        AVG. ENGAGEMENT RATE
                    </div>
                    <div className="mt-[6.5625rem] text-white font-[Poppins] text-5xl font-semibold tracking-tight">
                        {((totalClicks / totalUsers) * 100)?.toFixed(2)}%
                    </div>
                    {difference?.total_users &&
                        difference?.total_clicks &&
                        totalUsers &&
                        totalClicks && (
                            <div
                                className={
                                    (totalClicks / totalUsers) *
                                        ((100 + difference?.total_clicks) /
                                            (100 + difference?.total_users)) -
                                        totalClicks / totalUsers >
                                    0
                                        ? "mt-3 flex items-center gap-1 text-[#92f6a8]"
                                        : "mt-3 flex items-center gap-1 text-[#f46a6a]"
                                }
                            >
                                <div
                                    className={
                                        (totalClicks / totalUsers) *
                                            ((100 + difference?.total_clicks) /
                                                (100 + difference?.total_users)) -
                                            totalClicks / totalUsers >
                                        0
                                            ? "flex items-center justify-center"
                                            : "flex items-center justify-center rotate-90"
                                    }
                                >
                                    <TiltedArrow />
                                </div>
                                <div className="font-medium tracking-tight leading-5">
                                    {(totalClicks / totalUsers) *
                                        ((100 + difference?.total_clicks) /
                                            (100 + difference?.total_users)) -
                                        totalClicks / totalUsers >
                                    0
                                        ? "+"
                                        : ""}
                                    {Math.trunc(
                                        ((totalClicks / totalUsers) *
                                            ((100 + difference?.total_clicks) /
                                                (100 + difference?.total_users)) -
                                            totalClicks / totalUsers) *
                                            100
                                    )}
                                    % from last period
                                </div>
                            </div>
                        )}
                </div>
            </div>
            <div className="flex-1 flex overflow-auto border-t border-t-[#383838] lg:border-t-0">
                <div className="flex-1 px-5 lg:px-11 py-8 flex flex-col">
                    <div className="text-[#7a7a7a] font-semibold text-[0.8125rem]">
                        MY TOP PERFORMING CONTENT
                    </div>
                    <div className=" flex-1 flex flex-col">
                        <div className="flex gap-4 items-center whitespace-nowrap overflow-auto bg-[rgba(255,255,255,0.05)] px-4 py-2 mt-6 text-[#7a7a7a]">
                            <div className="text-sm font-medium flex-[3]  lg:flex-[12]">Name</div>
                            <div className="text-sm font-medium flex-1 lg:flex-[4]">
                                Total Users
                            </div>
                            <div className="text-sm font-medium flex-1 lg:flex-[3]">
                                Total views
                            </div>
                        </div>
                        <div className="flex-1">
                            {shownArticles?.map((item, i) => (
                                <div
                                    key={i}
                                    className="flex items-center gap-4 px-4 py-2 text-white border-t border-t-[rgba(255,255,255,0.1)]"
                                >
                                    <div className="font-medium flex-[3] lg:flex-[12] overflow-hidden whitespace-nowrap text-ellipsis">
                                        {`${i + 1}. `}
                                        {item?.title
                                            ? item?.title?.substring(0, 60)
                                            : item?.url?.substring(0, 60)}
                                    </div>
                                    <div className="font-medium flex-1 lg:flex-[4]">
                                        {item?.unique_users?.toLocaleString()}
                                    </div>
                                    <div className="font-medium flex-1 lg:flex-[3]">
                                        {item?.pageviews?.toLocaleString()}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OverviewStats;
