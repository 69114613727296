const InfoIcon = () => {
    return (
        <svg height="1em" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_86_31273)">
                <circle cx="6.77658" cy="6" r="5.5" stroke="currentColor" />
                <circle cx="6.77658" cy="4" r="1" fill="currentColor" />
                <path d="M6.77658 6V9" stroke="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_86_31273">
                    <rect width="12" height="12" fill="white" transform="translate(0.776581)" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default InfoIcon;
